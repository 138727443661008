import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, Stack } from "@mui/material";
import { FiltersContainer } from "./CommonFiltersContainer";
import SectionItem from "./SectionItem";
import { FilterSectionItemType, mapRange } from "./utils";

type Props = {
  sections: FilterSectionItemType[];
};

export default function ModalContent({ sections }: Props) {
  return (
    <Box
      flex={1}
      overflow="auto"
      sx={{ overflowX: "hidden" }}
      onTouchMove={(e) => {
        e.stopPropagation();
      }}
    >
      <FadeUpWrapper delay={150}>
        <Stack gap="16px">
          {sections
            .filter((item) => !item.hidden)
            .map((item) => (
              <SectionItem
                key={item.title}
                startIcon={item.startIcon}
                title={item.title}
                description={item.description}
                endElement={item.endElement}
              >
                {item.children}
              </SectionItem>
            ))}
        </Stack>
      </FadeUpWrapper>
    </Box>
  );
}
