import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { showMessage } from "@common/Toast/ShowToast";

type BankAccountStatusDeclinePopupProps = {
  declineHandler: () => void;
  name: string;
};

const BankAccountStatusDeclinePopup = NiceModal.create(
  ({ declineHandler, name }: BankAccountStatusDeclinePopupProps) => {
    const { open, onClose, SlideProps, TransitionProps } = useNiceModal();
    const { isMobileView } = useCustomTheme();

    const onClickDecline = () => {
      if (declineHandler) {
        declineHandler();
        showMessage(
          "Info",
          <>
            Bank account status of{" "}
            <span style={{ fontWeight: "600" }}>“{name}”</span> has been
            declined.
          </>,
          !isMobileView,
          "Bank Account Status Declined",
        );
      }
      onClose();
    };

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          width: "563px",
          SlideProps,
          DialogProps: {
            PaperProps: {
              sx: {
                padding: "24px 16px 16px",
              },
            },
            TransitionProps,
          },
        }}
      >
        <ModalTitle title="Decline Bank Account" onClose={onClose} />

        <FadeUpWrapper
          delay={60}
          containerProps={{ sx: { marginBlock: "8px 20px" } }}
        >
          <Text
            lineHeight="16.8px"
            color={palette.neutral[600]}
            sx={{
              "& span": {
                fontWeight: 600,
              },
            }}
          >
            Are you sure that you want to decline <span>“{name}”</span> Bank
            Status?
          </Text>
        </FadeUpWrapper>
        <ModalActions
          animationDelay={80}
          containerProps={{
            sx: {
              paddingTop: "20px",
              ...(isMobileView && {
                flexDirection: "column-reverse",
              }),
            },
          }}
          secondaryAction={{
            label: "Cancel",
            onClick: onClose,
            sx: {
              fontSize: "18px",
              lineHeight: "21.6px",
              padding: "12px 24px",
            },
          }}
          primaryAction={{
            type: "button",
            onClick: onClickDecline,
            label: "Yes, Decline",
            sx: {
              fontSize: "18px",
              lineHeight: "21.6px",
              padding: "12px 24px",
            },
          }}
        />
      </ModalFactory>
    );
  },
);

export default BankAccountStatusDeclinePopup;
