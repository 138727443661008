import { Box, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { TSummary, TTrends } from "../data.types";
import { parseAmount } from "@utils/index";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Text } from "@common/Text";
import { IncrementArrow } from "@assets/analyticsIcons";
import { useAppSelector } from "@redux/hooks";
import { selectDateFilter } from "@redux/slices/analytics";
import { QKEY_ANALYTICS_CAMPAIGNS } from "@constants/queryKeys";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type StatsSummaryProps = {
  data: { summary: TSummary; trends: TTrends };
  isExpanded: boolean;
  isEmpty: boolean;
};

const StatsSummary = ({ data, isEmpty, isExpanded }: StatsSummaryProps) => {
  const width = isExpanded ? "50%" : "100%";
  const { isMobileView } = useCustomTheme();
  const { summary, trends } = data;

  const dateFilter = useAppSelector((state) =>
    selectDateFilter(state, QKEY_ANALYTICS_CAMPAIGNS),
  );

  const summaryItems = [
    {
      label: "Volume (USD)",
      value: parseAmount(isEmpty ? undefined : summary.volume),
    },
    {
      label: "Available Balance (USD)",
      value: parseAmount(isEmpty ? undefined : summary.availableBalance),
    },
    {
      label: "Approved",
      value: isEmpty ? 0 : summary.approved,
    },
    {
      label: "Refunds",
      value: isEmpty ? 0 : summary.refunds,
    },
    {
      label: "Chargebacks",
      value: isEmpty ? 0 : summary.chargebacks,
    },
  ];

  const hideIncrement =
    dateFilter?.type !== "months" && dateFilter?.type !== "days";

  const trendsItems = [
    {
      label: "Avg Transactions",
      value: trends.transactionsAverage.value,
      increment: trends.transactionsAverage.increment,
      parseIncrement: false,
      hideIncrement,
    },
    {
      label: "Avg Visitors",
      value: trends.visitorsAverage.value,
      increment: trends.visitorsAverage.increment,
      parseIncrement: false,
      hideIncrement,
    },
    {
      label: "Avg Conversion",
      value: `${trends.conversionAverage.value}%`,
      increment: trends.conversionAverage.increment,
      parseIncrement: false,
      hideIncrement,
    },
    {
      label: "Avg Amount (USD)",
      value: parseAmount(trends.amountAverage.value),
      increment: trends.amountAverage.increment,
      parseIncrement: true,
      hideIncrement,
    },
  ];

  return (
    <Container container>
      <Grid
        container
        sx={{
          width,
          ...(isExpanded && {
            paddingRight: "48px",
          }),
        }}
      >
        <FadeUpWrapper delay={250}>
          <SectionTitle title="Summary" />
          {summaryItems.map(({ label, value }, index) => {
            return (
              <Grid
                container
                key={label}
                sx={{
                  paddingBlock: "8px",
                  ...(index !== 0 && {
                    borderTop: `1px solid ${palette.liftedWhite[200]}`,
                  }),
                }}
              >
                <Grid item xs={7} sm={5}>
                  <Text
                    fontWeight="book"
                    lineHeight="16.8px"
                    color={palette.black[100]}
                  >
                    {label}
                  </Text>
                </Grid>
                <Grid item xs={5} sm={7}>
                  <Text lineHeight="16.8px" color={palette.black[300]}>
                    {value}
                  </Text>
                </Grid>
              </Grid>
            );
          })}
        </FadeUpWrapper>
      </Grid>
      <Grid
        container
        sx={{
          width,
        }}
      >
        {!isEmpty && (
          <FadeUpWrapper delay={300}>
            <SectionTitle title="Trends" />
            <Grid container rowGap="8px" alignItems="flex-start">
              {trendsItems.map(
                (
                  { label, value, increment, parseIncrement, hideIncrement },
                  index,
                ) => {
                  return (
                    <Grid
                      item
                      rowGap="8px"
                      xs={isMobileView ? 12 : 6}
                      key={label}
                    >
                      <Grid item xs={12}>
                        <Text
                          fontWeight="book"
                          lineHeight="16.8px"
                          color={palette.black[100]}
                        >
                          {label}
                        </Text>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        paddingRight={
                          !isMobileView && index % 2 === 0 ? "24px" : 0
                        }
                      >
                        <TrendsValue
                          value={value}
                          increment={increment}
                          parseIncrement={parseIncrement}
                          hideIncrement={hideIncrement}
                        />
                      </Grid>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </FadeUpWrapper>
        )}
      </Grid>
    </Container>
  );
};

const TrendsValue = ({
  value,
  increment,
  parseIncrement,
  hideIncrement,
}: {
  value: string | number;
  increment: number;
  parseIncrement: boolean;
  hideIncrement: boolean;
}) => {
  const absoluteIncrement = Math.abs(increment);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingTop="8px"
    >
      <Text
        fontSize="18px"
        lineHeight="21.6px"
        fontWeight="book"
        letterSpacing="-0.18px"
        color={palette.black[300]}
      >
        {value}
      </Text>
      {!hideIncrement && (
        <Text
          color={palette.black[300]}
          fontSize="12px"
          fontWeight="book"
          lineHeight="14.4px"
          display="inline-flex"
          alignItems="flex-start"
        >
          {parseIncrement ? parseAmount(absoluteIncrement) : absoluteIncrement}{" "}
          <Box
            component="span"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {increment < 0 ? (
              <IncrementArrow fill="#BB1111" rotate={-180} />
            ) : (
              <IncrementArrow />
            )}
          </Box>
        </Text>
      )}
    </Stack>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} paddingBottom="8px">
      <Text
        color={palette.black[100]}
        fontSize="18px"
        lineHeight="21.6px"
        fontWeight="book"
        letterSpacing="-0.18px"
      >
        {title}
      </Text>
    </Grid>
  );
};

const Container = styled(Grid)(() => ({
  padding: "24px 16px",
  rowGap: "24px",
  borderRadius: "12px",
  backgroundColor: palette.liftedWhite[100],
}));

export default StatsSummary;
