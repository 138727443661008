import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { Text } from "@common/Text";
import { Box, SxProps } from "@mui/material";
import { palette } from "@palette";
import { ReactNode, useCallback } from "react";

type Props = {
  body: string | ReactNode;
  actionId: string;
  isLoading: boolean;
  title: string;
  primaryActionLabel: string;
  isMobileView?: boolean;
  nextActionIsClose?: boolean;
  onClose: () => void;
  fireAction: (actionId: string) => void;
  shouldDisplaySecondaryButton: boolean;
};

const ModalContent = ({
  body,
  primaryActionLabel,
  isLoading,
  nextActionIsClose,
  fireAction,
  actionId,
  title,
  isMobileView,
  onClose,
  shouldDisplaySecondaryButton,
}: Props) => {
  const isButtonColored = useCallback(() => {
    switch (primaryActionLabel) {
      case "Delete":
      case "Activate":
      case "Reject":
      case "Confirm":
        return true;
      default:
        return false;
    }
  }, []);
  return (
    <>
      <ModalTitle
        padding={isMobileView ? "0px" : "24px 24px 0px 24px"}
        title={title}
        onClose={onClose}
      />
      <Box padding={isMobileView ? "0px" : "0px 24px"}>
        {typeof body === "string" ? (
          <Text
            lineHeight="16.8px"
            fontWeight="book"
            color={palette.gray[300]}
            mt={"8px"}
          >
            {body}
          </Text>
        ) : (
          body
        )}
      </Box>

      <ModalActions
        padding={isMobileView ? "16px 0px" : "20px 24px 16px"}
        containerProps={{
          ...(isMobileView && {
            sx: {
              justifyContent: "center",
              flexDirection: "column-reverse",
              gap: 0,
            },
          }),
        }}
        primaryAction={{
          label: primaryActionLabel,
          dataTestId: `${primaryActionLabel}-button-modal`,
          type: "button",
          onClick: nextActionIsClose ? onClose : () => fireAction(actionId),
          disabled: isLoading,
          sx: {
            ...(isMobileView && {
              width: "176px",
            }),
            ...(isButtonColored() && submitButtonStyle[primaryActionLabel]),
          },
        }}
        secondaryAction={{
          label: "Cancel",
          dataTestId: "cancel-button",
          onClick: onClose,
          hidden: !shouldDisplaySecondaryButton,
        }}
      />
    </>
  );
};

const submitButtonStyle: Record<string, SxProps> = {
  Delete: {
    backgroundColor: palette.filled.red,
    "&:hover": {
      backgroundColor: palette.filled.red,
    },
  },
  Reject: {
    backgroundColor: palette.filled.red,
    "&:hover": {
      backgroundColor: palette.filled.red,
    },
  },
  Activate: {
    backgroundColor: palette.filled.success,
    "&:hover": {
      backgroundColor: palette.filled.success,
    },
  },
  Confirm: {
    backgroundColor: palette.filled.success,
    "&:hover": {
      backgroundColor: palette.filled.success,
    },
  },
};

export default ModalContent;
