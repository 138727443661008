import { namespaces } from "../../../../resources/i18n.constants";

export const sweepstakesMinibuilder = {
  [namespaces.pages.sweepstakesMinibuilder]: {
    create_sweepstakes: "Create Sweepstakes",
    edit_sweepstakes: "Edit Sweepstakes",
    general: "General",
    tickets: "Tickets",
    // General --------------------------------------------------------------------------------
    title: "Title",
    end_date: "End Date",
    pick_a_date: "Pick a date",
    "Please enter a suitable title": "Please enter a suitable title",
    description: "Description",
    optional_but_recommended: "(Optional but recommended)",
    "What’s the purpose of this Sweepstake":
      "What’s the purpose of this Sweepstake?",
    browse_more_campaigns: "Browse More Campaigns",
    "If enabled, users will be able to explore other available campaigns of this Nonprofit":
      "If enabled, users will be able to explore other available campaigns of this Nonprofit.",
    featured_image: "Featured Image",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Recommended minimum size 2000x2000":
      "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Recommended minimum size 2000x2000",
    use_as_background_image: "Use as Background Image",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "If checked this image will be integrated in the background of the form, can be later changed",
    media_library: "Media Library",
    add_new_image: "Add New Image",
    no_images: "No images",
    // Tickets --------------------------------------------------------------------------------
    ticket: "ticket",
    thumbnail: "Thumbnail",
    add_ticket: "Add Ticket",
    ticket_name: "Ticket Name",
    previous_step: "Previous Step",
    enter_a_suitable_name: "Enter a suitable name",
    ticket_name_is_mandatory: "Ticket name is mandatory",
    amount: "Amount",
    must_be_above_one_dollar: "Must be above 1USD",
    "between 1.00 and 25,000.00": "Between 1.00 and 25,000.00",
    entries_per_ticket: "Entries per Ticket",
    at_least_one: "At least 1",
    should_be_at_least: "Should be at least 1",
    display_tickets_available: "Display Tickets Available",
    give_some_insight_about_this_ticket: "Give some insight about this Ticket",
    create_option: "Create Option",
    save_changes: "Save Changes",
    save_ticket: "Save Ticket",
    cancel: "Cancel",
    remove: "Remove",
    in_stock: "In Stock",
    properties: "Properties",
    "Multiple Winners (Each Ticket has a Prize)":
      "Multiple Winners (Each Ticket has a Prize)",
    if: "If",
    on: "ON",
    "each ticket will have a winner. Otherwise only one winner will be selected per Sweepstake.":
      "each ticket will have a winner. Otherwise only one winner will be selected per Sweepstake.",
    "Set a Limit for the Number of Entries that can be Purchased per Prize":
      "Set a Limit for the Number of Entries that can be Purchased per Prize",
    max_entries_allowed_per_prize: "Max Entries Allowed Per Prize",
    customer_pays_the_credit_card_fee: "Customer Pays the Credit Card Fee",
    "If toggled off, the Nonprofit covers the credit card fee.":
      "If toggled off, the Nonprofit covers the credit card fee.",
    "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee":
      "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee.",
    make_it_optional: "Make it Optional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "If checked customers will have the choice of not covering the Credit Card Fee",
    max_tickets_that_can_be_purchased_at_once:
      "Max Tickets That Can Be Purchased At Once",
    // Sweepstakes Successful
    sweepstakes_successfully_created: "Sweepstakes successfully created",
    "If you’re on a desktop or a laptop":
      "If you’re on a desktop or a laptop, you can continue editing in <span>the Advanced Builder</span>. The Advanced Builder offers powerful customization tools to help you take your campaign to the next level and ultimately increase conversion.",
    sweepstake_create_success_description: "Congratulations, your payment form has been created successfully and it's ready to accept payments, you can check its details and share it everywhere or browse more payment forms",
    open_the_advanced_builder: "Open the Advanced Builder",
    edit_in_advanced_builder: "Edit in Advanced Builder",
    done: "Done",
  },
};
