type Props = {
  width?: number;
  height?: number;
  stroke?: string;
  rotate?: number;
};

const ChevronRight = ({
  width = 32,
  height = 32,
  stroke = "#B8B8B8",
  rotate = 0,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M12 8L19.2929 15.2929C19.6834 15.6834 19.6834 16.3166 19.2929 16.7071L12 24"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronRight;
