import { Text } from "@common/Text";
import { palette } from "@palette";
import { OFACCheckStatusType } from "../hooks/useOFAC";

const OFACStatus = ({
  status,
  allowHover,
}: {
  status: OFACCheckStatusType | "non_resident";
  allowHover?: boolean;
}) => {
  return (
    <Text
      color={ofacTagMap[status].color}
      sx={{
        padding: "2px 16px",
        borderRadius: "16px",
        background: ofacTagMap[status].background,
        ...(allowHover && { cursor: "pointer" }),
      }}
    >
      {ofacTagMap[status].label}
    </Text>
  );
};

export const ofacTagMap = {
  clear: {
    color: palette.filled.success,
    background: palette.tag.success.bg,
    label: "Clear",
  },
  possible_match: {
    color: palette.tag.warning.hover,
    background: palette.tag.warning.bg,
    label: "Possible match",
  },
  confirmed_match: {
    color: palette.filled.red,
    background: palette.tag.error.bg,
    label: "Confirmed match",
  },
  manually_cleared: {
    color: palette.filled.success,
    background: palette.tag.success.bg,
    label: "Manually cleared",
  },
  non_resident: {
    color: palette.tag.blue.text,
    background: palette.tag.blue.bg,
    label: "Non-resident",
  },
};

export default OFACStatus;
