import { Stack, styled } from "@mui/material";
import { Box } from "@mui/material";
import { Image } from "@common/StyledImage/Image";
import { palette } from "@theme/colors";
import LongArrowLeft from "@assets/icons/RebrandedIcons/LongArrowLeft";
import FadeInWrapper from "@components/animation/FadeInWrapper";
import placeholder from "assets/images/Placeholder.png";

type Props = {
  image: string;
  onBack(): void;
  disabled?: boolean;
};
export const Pane1Minimised = ({ image, onBack, disabled }: Props) => {
  return (
    <FadeInWrapper delay={50}>
      <StyledBox data-testid="panel1-minimized-image" onClick={onBack}>
        <Image
          sx={{ width: "48px", height: "48px", opacity: disabled ? 0.5 : 1 }}
          src={image ? `${image}/medium` : placeholder}
        />
        <Box sx={{ opacity: disabled ? 0.5 : 1 }}>
          <LongArrowLeft />
        </Box>
      </StyledBox>
    </FadeInWrapper>
  );
};

const StyledBox = styled(Stack)(({ disabled }: { disabled?: boolean }) => ({
  position: "absolute",
  cursor: "pointer",
  borderRadius: "12px 0 0 12px",
  left: "-72px",
  top: "75px",
  boxShadow: "initial",
  backgroundColor: palette.neutral[5],
  gap: "8px",
  padding: "12px",
  alignItems: "center",
  pointerEvents: disabled ? "none" : "inherit",
  zIndex: -1,
  "&:hover": {
    backgroundColor: palette.neutral[10],
  },
}));
