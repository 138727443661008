type IconProps = {
  width?: string,
  height?: string,
  fill?: string,
}

const CheckRoundIcon = ({
  width = "24px",
  height = "24px",
  fill = "#FFF",
}: IconProps) => {
  return (
    <svg 
      width="32" 
      height="32" 
      viewBox="0 0 32 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28ZM22.1016 12.6402C22.4551 12.2159 22.3978 11.5853 21.9735 11.2318C21.5492 10.8782 20.9187 10.9355 20.5651 11.3598L15.3005 17.6774C14.9538 18.0934 14.7589 18.3238 14.6032 18.4653L14.5973 18.4707L14.5909 18.4659C14.4231 18.339 14.2081 18.1272 13.8252 17.7443L11.3738 15.2929C10.9833 14.9024 10.3501 14.9024 9.95956 15.2929C9.56904 15.6834 9.56904 16.3166 9.95956 16.7071L12.411 19.1585L12.4518 19.1993C12.7783 19.526 13.0924 19.8402 13.3846 20.0611C13.712 20.3087 14.1407 20.5415 14.6901 20.5165C15.2395 20.4916 15.6454 20.221 15.949 19.9448C16.22 19.6983 16.5044 19.3569 16.8 19.0021L16.8 19.0021L16.8369 18.9577L22.1016 12.6402Z" 
      fill={fill}
    />
    </svg>
  )
}

export default CheckRoundIcon;