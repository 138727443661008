import React from "react";

import { Grid, Stack } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type CampaignSearchProps = {
  title: string;
  searchBar: JSX.Element;
  filters?: React.ReactNode;
  watchList?: React.ReactNode;
  transparent?: boolean;
};

const CampaignSearch = ({
  searchBar,
  filters,
  watchList,
  transparent = true,
}: CampaignSearchProps) => {
  const { isDesktopView } = useCustomTheme();
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Stack direction="row" gap={2} alignItems="flex-end">
        {filters && <Grid>{filters}</Grid>}
        {isDesktopView && watchList}
      </Stack>
      {React.cloneElement(searchBar, {
        transparent: transparent,
        formSx: {
          "@media (max-width: 600px)": {
            width: "100%",
            "& > div": {
              width: "100%",
            },
          },
        },
      })}
    </Grid>
  );
};

export default CampaignSearch;
