import { customInstance } from "@services/api";
import {
  AccessPoliciesResponse,
  IUpdatedData,
  PermissionResponse,
} from "./types";
import { hashData } from "../utils";
import sha1 from "sha1";

const hashingFunction = (item: IUpdatedData) => item.id;

export const getAssignedPermissions = async (
  baseURL: string,
  searchQuery: string,
) => {
  let url = baseURL;

  if (!url.includes("?")) url += "?";

  url += `&filter=isAttached:true%3BisGlobal:false`;

  const data: AccessPoliciesResponse = await customInstance({
    url: encodeURIComponent(url),
    method: "GET",
  });

  if (!data?.data) {
    return {
      searchQuery,
      total: data?.total || 0,
      data: {},
    };
  }
  return {
    searchQuery,
    total: data.total,
    data: hashData(data.data.map(permissionParser), hashingFunction),
  };
};

export const getGlobalPermissions = async (
  baseURL: string,
  searchQuery: string,
) => {
  const data: AccessPoliciesResponse = await customInstance({
    url: encodeURIComponent(baseURL),
    method: "GET",
  });

  if (!data?.data || !data?.data?.length) {
    return {
      searchQuery,
      total: data.total,
      data: {},
    };
  }

  const mergedData = data.data.reduce(mergeDuplicates, {});

  return {
    searchQuery,
    total: Object.keys(mergedData).length,
    data: mergedData,
  };
};

const mergeDuplicates = (
  acc: Record<string, IUpdatedData>,
  permission: PermissionResponse,
) => {
  const hashedKey = sha1(permission.id);
  const parsedValue = permissionParser(permission);

  if (acc[hashedKey] && permission.isGlobal) return acc;
  if (acc[hashedKey] && !permission.isGlobal)
    return { ...acc, [hashedKey]: parsedValue };

  if (permission.isGlobal) {
    return {
      ...acc,
      [hashedKey]: {
        ...parsedValue,
        isAttached: false,
      },
    };
  }

  return {
    ...acc,
    [hashedKey]: {
      ...parsedValue,
    },
  };
};

const permissionParser = (permission: PermissionResponse): IUpdatedData => ({
  id: permission.id,
  group: permission.categoryName.toLowerCase(),
  permissionName: permission.name,
  description: permission?.description || "",
  actionName: permission.actionName,
  resourceName: permission.resourceName,
  isGlobal: permission.isGlobal,
  isAttached: permission.isAttached,
  isDeny: permission.isDeny,
});
