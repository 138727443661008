import { useEffect } from "react";

type Callback = () => void;

const useBackListener = (callback: Callback) => {
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      callback();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
};

export default useBackListener;
