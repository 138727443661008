import { useEffect } from "react";
import { Button } from "@common/Button";
import { RHFCustomEditor } from "@common/CustomEditor";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import { palette } from "@palette";
import { ContentState, EditorState } from "draft-js";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { AmountType } from "../FundraisersMinibuilder/types";
import { RHFSwitch } from "@common/Switch";
import { Collapse } from "@mui/material";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { Wrapper } from "./utils";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

interface EditAnyAmountProps {
  item: AmountType;
  onClose: (item?: AmountType) => void;
}

type FormInputs = {
  title: string;
  description: EditorState | null;
  min_max: {
    enabled: boolean;
    min: string;
    max: string;
  };
};

const getValueFromString = (value: string | number) =>
  typeof value === "string" ? parseInt(value.replaceAll(",", "")) : value;

const schema = Yup.object().shape({
  title: Yup.string().trim().required("This field is required"),
  description: Yup.mixed<EditorState>().nullable(),
  min_max: Yup.object().shape({
    enabled: Yup.boolean().test(
      "max-min-values-test",
      "Max value should be greater than Min value",
      function (value) {
        return (
          getValueFromString(this.parent.max) >
          getValueFromString(this.parent.min)
        );
      },
    ),
    min: Yup.string(),
    max: Yup.string(),
  }),
});

const defaultValues = {
  title: "",
  description: null,
  min_max: {
    enabled: false,
    min: "",
    max: "",
  },
};

const EditAnyAmount = ({ item, onClose }: EditAnyAmountProps) => {
  const modal = useModal();
  const open = modal.visible;

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    formState: { dirtyFields, isValid, isDirty },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (item) {
      reset({
        title: item.title,
        description:
          typeof item.description === "string"
            ? (EditorState.createWithContent(
                ContentState.createFromText(item.description),
              ) as EditorState)
            : (item.description as any),
        min_max: {
          enabled: item.min_max?.enabled || false,
          min: item.min_max?.min || "",
          max: item.min_max?.max || "",
        },
      });
    }
  }, [item]);

  const onEditorStateChange = (editorState: EditorState) => {
    if (editorState.getCurrentContent().getPlainText().length > MAX_TEXT_LENGTH)
      return;
    setValue("description", editorState, { shouldDirty: true });
  };

  const hideModal = () => {
    reset();
    modal.hide();
  };

  const handleCancel = () => {
    hideModal();
    onClose();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const amount: AmountType = {
      id: item.id,
      amount: "",
      isDefault: true,
      active: item ? item.active : true,
      title: dirtyFields.title ? data.title : item.title,
      description: dirtyFields.description
        ? data.description
          ? data.description
          : ""
        : item.description,
      min_max: {
        enabled: dirtyFields.min_max?.enabled
          ? data.min_max?.enabled || false
          : item.min_max?.enabled || false,
        min: dirtyFields.min_max?.min
          ? data.min_max?.min || ""
          : item.min_max?.min || "",
        max: dirtyFields.min_max?.max
          ? data.min_max?.max || ""
          : item.min_max?.max || "",
      },
    };
    reset();
    hideModal();
    onClose(amount);
  };

  const isUpdateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const canEdit = (!!item && isUpdateAllowed) || !item;

  return (
    <Wrapper open={open} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box
          component="form"
          id="edit-any-amount"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ padding: "16px 16px 6px" }}>
            <Text
              color={palette.black[100]}
              variant="headline"
              fontSize="24px"
              lineHeight="32.4px"
              fontWeight="book"
              sx={{ letterSpacing: "-0.24px" }}
            >
              Edit Any Amount
            </Text>
          </DialogTitle>
          <ContentContainer>
            <FadeUpWrapper delay={50}>
              <RHFInput
                name="title"
                label="Title"
                fullWidth
                disabled={!canEdit}
              />
            </FadeUpWrapper>
            <Stack direction="column" gap={1}>
              <FadeUpWrapper delay={100}>
                <RHFSwitch
                  size="small"
                  name="min_max.enabled"
                  disabled={!canEdit}
                />
                <Text
                  color={palette.black[100]}
                  variant="body"
                  fontWeight="book"
                  lineHeight="16.8px"
                >
                  Set min and max value
                </Text>
              </FadeUpWrapper>
              <FadeUpWrapper delay={150}>
                <Text
                  color={palette.gray[300]}
                  variant="body"
                  fontWeight="book"
                  lineHeight="16.8px"
                >
                  Minimum value can be helpful when dealing with additional
                  costs of running the payment form
                </Text>
              </FadeUpWrapper>
            </Stack>
            <Collapse
              in={values.min_max.enabled}
              orientation="vertical"
              unmountOnExit
            >
              <Stack direction="row" gap={1}>
                <RHFCustomAmountInput
                  name="min_max.min"
                  label="Min"
                  currency="usd"
                  disabled={!canEdit}
                  initialValue={item?.min_max?.min}
                />
                <RHFCustomAmountInput
                  name="min_max.max"
                  currency="usd"
                  label="Max"
                  disabled={!canEdit}
                  initialValue={item?.min_max?.max}
                />
              </Stack>
            </Collapse>
            <FadeUpWrapper delay={200}>
              <RHFCustomEditor
                name="description"
                readOnly={!canEdit}
                onEditorStateChange={onEditorStateChange}
                placeholder={"Description (optional)"}
              />
            </FadeUpWrapper>
          </ContentContainer>
          <ActionsContainer>
            <Button
              background="text"
              size="medium"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  flexGrow: 1,
                },
              })}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              background="primary"
              size="medium"
              form="edit-any-amount"
              disabled={!isValid || !isDirty}
              type="submit"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  flexGrow: 3,
                },
              })}
            >
              Save
            </Button>
          </ActionsContainer>
        </Box>
      </FormProvider>
    </Wrapper>
  );
};

export const EditAnyAmountModal = NiceModal.create(
  (props: EditAnyAmountProps) => {
    return <EditAnyAmount {...props} />;
  },
);

const ContentContainer = styled(DialogContent)(() => ({
  padding: "6px 16px 24px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));

const ActionsContainer = styled(DialogActions)(({ theme }) => ({
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "flex-end",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "stretch",
  },
}));

export default EditAnyAmount;
