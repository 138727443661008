import { TrashBin } from "@assets/icons/RebrandedIcons";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import {
  IStyleIconButtonProps,
  StyledIconButton,
} from "@common/IconButton/IconButton";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import { palette } from "@palette";

type StyledDeleteButton = Omit<IStyleIconButtonProps, "children"> & {
  fill?: string;
  disabled?: boolean;
};

export const StyledDeleteButton = ({
  fill = palette.error.hover,
  disabled = false,
  ...props
}: StyledDeleteButton) => {
  return (
    <>
      <CustomToolTip showToolTip={disabled} message={DELETE_DENY_MESSAGE}>
        <StyledIconButton
          data-testid="delete-button"
          disabled={disabled}
          sx={{
            height: "48px !important",
            width: "48px !important",
            padding: "0 !important",
            "&:hover path": {
              fill: palette.error.hover,
            },
            ...props,
          }}
        >
          <TrashBin width={24} height={24} fill={fill} />
        </StyledIconButton>
      </CustomToolTip>
    </>
  );
};
