import { Button } from "@common/Button";
import { BtnBGTypes } from "@common/Button/Button_V2";
import { FEATURE_DENY_MESSAGE } from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { PREVIEW_INVITATION_MODAL } from "modals/modal_names";

type PreviewInvitationButtonProps = {
  title: string;
  background?: BtnBGTypes;
  isLinkButton?: boolean;
  receiverName?: string;
  senderName?: string;
  senderLogo?: string;
  isSendingToProvider: boolean;
};

function PreviewInvitationButton({
  title,
  background = "secondary",
  isLinkButton,
  receiverName,
  senderName,
  senderLogo,
  isSendingToProvider,
}: PreviewInvitationButtonProps) {
  const { selectedUser } = useGetCurrentMerchantId();
  const { merchType, userRole } = selectedUser || {};
  const isDisabled =
    (merchType === "enterprise" || merchType === "provider") &&
    userRole === "member";

  const handleOnClick = () => {
    NiceModal.show(PREVIEW_INVITATION_MODAL, {
      receiverName,
      senderName,
      senderLogo,
      isSendingToProvider,
    });
  };

  return (
    <Button
      tooltipProps={{
        show: isDisabled,
        message: FEATURE_DENY_MESSAGE,
      }}
      background={background}
      size="medium"
      disabled={isDisabled}
      sx={(theme) => ({
        [theme.breakpoints.down("sm")]: {
          flexGrow: 3,
        },
        ...(isLinkButton && {
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            boxShadow: "none",
          },
        }),
      })}
      onClick={handleOnClick}
    >
      {title}
    </Button>
  );
}

export default PreviewInvitationButton;
