type Props = {
  color?: string;
  width?: string | number;
  height?: string | number;
};

const TabletDeviceIcon = ({
  color = "#B2B2B1",
  height = 21,
  width = 20,
}: Props) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4492 2.10156H6.54922C5.45968 2.10156 4.57422 2.98702 4.57422 4.07656V15.9266C4.57422 17.0161 5.45968 17.9016 6.54922 17.9016H14.4492C15.5388 17.9016 16.4242 17.0161 16.4242 15.9266V4.07656C16.4242 2.98702 15.5388 2.10156 14.4492 2.10156ZM11.8159 16.5849H9.18255V15.9266H11.8159V16.5849ZM15.2721 14.6099H5.7263V4.07656H15.2721V14.6099Z"
        fill={color}
      />
    </svg>
  );
};

export default TabletDeviceIcon;
