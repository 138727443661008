import NiceModal from "@ebay/nice-modal-react";
import Header from "./Header";
import { SidePanel } from "@containers/SidePanel";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useCampaignModal, { CAMPAIGN_PANEL_WIDTH } from "./useCampaignModal";
import { EditFundraiser } from "features/Minibuilders/FundraisersMinibuilder/EditFundraiserModal";
import Body from "./Body";
import { EditEvent } from "features/Minibuilders/EventsMinibuilder/EditEventModal";
import { EditMembership } from "features/Minibuilders/MembershipsMinibuilder/EditMembershipsModal";
import { EditInvoice } from "features/Minibuilders/InvoicesMinibuilder/EditInvoiceMinibuilder";
import { CreateSweepstakes } from "@components/Sweepstakes/MiniBuilder/CreateSweepstakesModal";
import { TransactionInfo } from "@components/ManageMoney/TransactionTable/TransactionInfoModal";
import { palette } from "@palette";
import LoadingPanelState from "./LoadingPanelState";
import { EditPaymentForm } from "features/Minibuilders/PaymentFormMinibuilder/EditPaymentFormModal";
import { useNavigate } from "react-router-dom";

type Props = {
  id: number;
  name?: string;
  isFirst: boolean;
  isLast: boolean;
  setSelectedRow: (newIdx: string | number) => void;
};

const secondPanel = {
  fundraiser: EditFundraiser,
  event: EditEvent,
  membership: EditMembership,
  invoice: EditInvoice,
  sweepstake: CreateSweepstakes,
  "payment-form": EditPaymentForm,
};

const CampaignDetailsModal = NiceModal.create(
  ({ id, isFirst, isLast, setSelectedRow, name }: Props) => {
    const {
      handleClose,
      isLoading,
      isFetching,
      modal,
      actions,
      handleNext,
      handlePrevious,
      handlePreview,
      handleExpand,
      doublePanel,
      setDoublePanel,
      campaign,
      imgUrl,
      handleEdit,
      handleShare,
      BodyData,
      transactionLoading,
      chooseTransaction,
      transactionData,
      selectedTransaction,
      hasEnded,
    } = useCampaignModal({ id, name, setSelectedRow });

    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const isResponsivePanel = useMediaQuery(
      theme.breakpoints.up(CAMPAIGN_PANEL_WIDTH * 2),
    );

    const editProductProps = {
      id: id.toString(),
      noModal: true,
      handleClose: () => setDoublePanel(false),
    };

    const transactionPanelProps = {
      noModal: true,
      data: transactionData,
      ...(!isResponsivePanel && {
        handleClose: () => setDoublePanel(false),
      }),
    };

    const handleAdvancedBuilderRedirection = () => {
      handleClose();
      navigate("/merchant/new-advanced-builder", {
        state: {
          productId: id,
          productTitle: BodyData?.name,
          headerImageUrl: BodyData?.usesBackgroundImage && imgUrl,
          description: BodyData?.description,
          campaign: BodyData?.typeName,
        },
      });
    };

    const Campaign = secondPanel[campaign];

    return (
      <SidePanel
        width={
          isResponsivePanel && doublePanel
            ? CAMPAIGN_PANEL_WIDTH * 2
            : CAMPAIGN_PANEL_WIDTH
        }
        modal={modal}
        onCloseDrawer={handleClose}
      >
        <Box sx={panelStyle}>
          <Box
            width={CAMPAIGN_PANEL_WIDTH}
            height="100vh"
            sx={{
              display: !isResponsivePanel && doublePanel ? "none" : "block",
            }}
          >
            {isLoading ? (
              <LoadingPanelState />
            ) : (
              <Stack
                className={classes.container}
                sx={{
                  overflow: "auto",
                  position: "relative",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Header
                  imageURL={imgUrl}
                  actions={actions}
                  nextPrevState={{ isFirst, isLast }}
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  onCloseDrawer={handleClose}
                  handlePreview={handlePreview}
                  handleExpand={handleExpand}
                />
                <Body
                  BodyData={BodyData}
                  handleShare={handleShare}
                  handleEdit={handleEdit}
                  handleExpand={handleExpand}
                  selectedTransaction={selectedTransaction}
                  isloading={transactionLoading}
                  isEditFormStyleDisabled={isFetching}
                  chooseTransaction={chooseTransaction}
                  hasEnded={hasEnded}
                  handleEditFromStyle={handleAdvancedBuilderRedirection}
                  isEvent={campaign === "event"}
                />
              </Stack>
            )}
          </Box>
          {doublePanel && (
            <Box sx={secondPanelStyle} width={CAMPAIGN_PANEL_WIDTH}>
              {transactionData ? (
                <TransactionInfo {...transactionPanelProps} />
              ) : (
                Campaign && <Campaign {...editProductProps} />
              )}
            </Box>
          )}
        </Box>
      </SidePanel>
    );
  },
);

const panelStyle = {
  background: palette.neutral[5],
  display: "flex",
  width: "100%",
};

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    alignItems: "baseline",
    overflow: "scroll",
  },
}));

const secondPanelStyle = {
  background: "rgba(251, 251, 251, 0.75)",
  boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(50px)",
  margin: "16px 8px 16px 0px",
  "@media(max-width: 1200px)": {
    margin: "16px 8px 16px 8px",
  },
  borderRadius: "16px",
  perspective: "1px",
  overflow: "hidden",
  height: "96vh",
};

export default CampaignDetailsModal;
