import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import { palette } from "@palette";

type RiskLevelTagProp = {
  isSuspended: boolean;
  levelColor: any;
  statusName: string | undefined;
  riskLevelLabel: string;
  hasBorder?: boolean;
  customStyle?: any;
  testId?: string;
};

export const RiskLevelTag = ({
  isSuspended,
  levelColor,
  statusName,
  riskLevelLabel,
  hasBorder = true,
  customStyle,
  testId,
}: RiskLevelTagProp) => {
  const { color, background } = levelColor || {};
  return (
    <RiskLevel
      data-testid={testId}
      sx={{
        color: isSuspended ? palette.tag.warning.hover : color,
        backgroundColor: isSuspended ? palette.tag.warning.bg : background,
        border: hasBorder
          ? `1px solid ${isSuspended ? palette.tag.warning.hover : color}`
          : "none",
        ...customStyle,
      }}
    >
      {isSuspended ? statusName : riskLevelLabel}
    </RiskLevel>
  );
};

const RiskLevel = styled(Box)(() => ({
  padding: "4px 16px",
  borderRadius: "100px",
  fontSize: 14,
  lineHeight: "120%",
  fontWeight: 400,
  display: "flex",
  justifyContent: "center",
  minWidth: "82px",
  maxWidth: "100px",
  textTransform: "capitalize",
}));
