import * as React from "react";
import Select, { SelectProps } from "./Select";
import { useFormContext, Controller } from "react-hook-form";
import { WithFetching } from "@containers/WithFetching";
import { CaretDown } from "@assets/icons";

const RHFSelect: React.FC<
  SelectProps & {
    name: string;
    isFetchindData?: boolean;
    isError?: boolean;
    errorsMessage?: string;
    focusViewColor?: string;
  }
> = ({
  name,
  helperText,
  withSearchBar,
  isFetchindData = false,
  isError = false,
  errorsMessage,
  ...props
}) => {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => (
        <WithFetching
          errorsMessage={errorsMessage}
          isFetching={isFetchindData}
          isError={isError}
        >
          <Select
            shouldDisplayIcon
            inputRef={ref}
            {...rest}
            error={!!error}
            helperText={helperText || error?.message}
            withSearchBar={withSearchBar}
            isOpen={setIsOpen}
            DropIcon={<CaretDown rotate={isOpen ? 180 : 0} />}
            {...props}
          />
        </WithFetching>
      )}
    />
  );
};

export default RHFSelect;
