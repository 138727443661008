import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const ChevronRightIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill="none"
      height={`${height}`}
      viewBox="0 0 20 20"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96967 5.53033C6.67678 5.23744 6.67678 4.76256 6.96967 4.46967C7.26256 4.17678 7.73744 4.17678 8.03033 4.46967L6.96967 5.53033ZM11.7929 9.29289L12.3232 8.76256L12.3232 8.76256L11.7929 9.29289ZM11.7929 10.7071L12.3232 11.2374H12.3232L11.7929 10.7071ZM8.03033 15.5303C7.73744 15.8232 7.26256 15.8232 6.96967 15.5303C6.67678 15.2374 6.67678 14.7626 6.96967 14.4697L8.03033 15.5303ZM8.03033 4.46967L12.3232 8.76256L11.2626 9.82322L6.96967 5.53033L8.03033 4.46967ZM12.3232 11.2374L8.03033 15.5303L6.96967 14.4697L11.2626 10.1768L12.3232 11.2374ZM12.3232 8.76256C13.0066 9.44598 13.0066 10.554 12.3232 11.2374L11.2626 10.1768C11.3602 10.0791 11.3602 9.92085 11.2626 9.82322L12.3232 8.76256Z"
        fill={fill}
      ></path>
    </svg>
  );
};
export default ChevronRightIcon;
