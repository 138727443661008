import { Editor, EditorProps } from "react-draft-wysiwyg";
import { styled, Box } from "@mui/material";

type ReadOnlyEditorProps = EditorProps & StyledEditorProps;

export function ReadOnlyEditor({
  isPaymentCard = false,
  scrollTop = 0,
  ...props
}: ReadOnlyEditorProps) {
  return (
    <StyledEditor
      isPaymentCard={isPaymentCard}
      scrollTop={scrollTop}
    >
      <Editor
        editorClassName="editorClassName"
        readOnly
        toolbarHidden
        {...props}
      />
    </StyledEditor>
  );
}

type StyledEditorProps = {
  isPaymentCard?: boolean;
  scrollTop?: number;
}

const StyledEditor = styled(Box, {
  shouldForwardProp: (prop) => 
    prop !== "isPaymentCard" &&
    prop !== "isProductInfoModal"
})(({ isPaymentCard, scrollTop }: StyledEditorProps) => ({
  "& .editorClassName": {
    overflow: "hidden",
    wordBreak: "break-word",
    position: "relative",

    ...(isPaymentCard && {
      "&::before": {
        content: '""',
        width: "100%",
        height: "102px",    
        position: "absolute",
        zIndex: 10,
        left: 0,
        top: 0,
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
    
        "@media (max-width: 600px)": {
          background: 
            "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 65%)",
        },
      },
    }),

    ...(scrollTop && {
      "&::before": {
        ...overlayStyle,
        display: scrollTop >= 160 ? "block" : "none",
        top: scrollTop - 184,
      },
    }),
  },
}));

const overlayStyle = {
  content: '""',
  width: "100%",
  height: "77px",    
  position: "absolute",
  left: 0,
  zIndex: 10,
  background:
    "linear-gradient(180deg, #FFFFFF 19.27%, rgba(255, 255, 255, 0) 100%)",
}