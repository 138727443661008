import { useQuery } from "react-query";
import { customInstance } from "@services/api";
import { TRiskLevelName } from "@common/Tag/RiskStatusChip";

type TRiskStatus = {
  id: number;
  label: string;
  value: TRiskLevelName;
};

const getRiskStatus = (category?: string) => {
  const url = category
    ? `merchant-risk-statuses?filter=category:"${category}"`
    : `merchant-risk-statuses`;
  return customInstance({
    url,
    method: "GET",
  });
};

export function useGetMerchantRiskStatus(category?: string) {
  const { data, isLoading } = useQuery<TRiskStatus[]>(
    ["merchant-risk-status", category],
    async () => {
      const data = await getRiskStatus(category);

      return (
        data?.data?.map(
          (status: any): TRiskStatus => ({
            id: status.id,
            label: status.displayName,
            value: status.name as TRiskLevelName,
          }),
        ) || []
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: category !== undefined,
    },
  );

  return { data, isLoading };
}
