import { Box, CircularProgress, CircularProgressProps, SxProps } from "@mui/material";

const LoadingSpinner = ({
  sx,
  color = "#8F8F8F",
  spinnerProps,
}: {
  color?: string;
  sx?: SxProps;
  spinnerProps?: CircularProgressProps;
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      ...(sx && sx)
    }}
  >
    <CircularProgress sx={{ color }} {...spinnerProps} />
  </Box>
);

export default LoadingSpinner;
