import { ToastContainer as ToastifyContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "@emotion/styled";

const ToastContainer = styled(ToastifyContainer)`
  width: auto;
  height: auto;
  .Toastify__toast {
    width: 473px;
    min-height: 70px;
    padding: 11px 16px 11px 8px;
    margin: 0 0 16px auto;
    border-radius: 8px;
    background-color: #292727;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
  .Toastify__toast-body > div {
    height: 100%;
  }
  @media (max-width: 600px) {
    .Toastify__toast {
      min-height: 48px;
      padding: 6px 16px 6px 8px;
      width: 360px;
    }
  }
`;

const Toast = () => {
  return (
    <ToastContainer
      limit={3}
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={true}
      closeButton={false}
      icon={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      draggableDirection="x"
      draggablePercent={20}
      pauseOnHover
    />
  );
};

export default Toast;
