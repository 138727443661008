import { isEqual } from "lodash";
import { useRef } from "react";

type TData<T> = Record<string, T>;

const useLogs = <T>() => {
  const changeLog = useRef<TData<T>>({});

  const updateChangeLogItem = (key: string, value: T) => {
    const currentLogs = changeLog.current;
    changeLog.current = {
      ...currentLogs,
      [key]: value,
    };
  };

  const updateChangeLog = (callback: (old: TData<T>) => TData<T>) => {
    const newData = callback(changeLog.current);
    changeLog.current = newData;
  };

  const resetChangeLog = () => {
    changeLog.current = {};
  };

  const hasChanged = (initialData: TData<T>) => {
    return Object.keys(changeLog.current).some(
      (key) => !isEqual(initialData[key], changeLog.current[key]),
    );
  };

  return {
    logs: changeLog.current,
    updateItem: updateChangeLogItem,
    updateAll: updateChangeLog,
    reset: resetChangeLog,
    hasChanged,
  };
};

export default useLogs;
