import { EmptyInfoIcon } from "@assets/icons";
import { Button } from "@common/Button";
import CustomPopover from "@common/Modal/Popover/CustomPopover";
import { Text } from "@common/Text";
import { useGetEnterpriseById } from "@hooks/acquirer-api/account/useGetEnterprises";
import { useGetCurrentMerchantId } from "@hooks/common";
import useCheckEnvironment from "@hooks/common/useCheckEnvironment";
import { Divider, ListItem, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useState } from "react";
import { brandingHeadings } from "..";
import { BrandingHeading, EmbeddedCode } from "./atoms";

export const enterpriseIframeText = `<iframe src='https://$[slug].givepayments.com/signup' frameBorder='no' scrolling='auto' height='800px' width='100%'></iframe>`;

const EmbedCodeSection = () => {
  const { isMobileView } = useCustomTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { getEnvironmentDomain } = useCheckEnvironment();

  const { merchantId } = useGetCurrentMerchantId();
  const { data } = useGetEnterpriseById(`${merchantId}`);
  const enterpriseSlug = data?.slug || "portal";

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const onClose = () => setAnchorEl(null);

  const iconSize = isMobileView ? 24 : 16;
  const environment = getEnvironmentDomain();
  const env = environment ? `.${environment}` : "";
  const slug = `${enterpriseSlug}${env}`;

  return (
    <Stack direction="column" gap="16px" alignItems="stretch">
      <BrandingHeading
        {...brandingHeadings["iframe"]}
        action={
          <InfoButton onClick={onClick} data-testid="embed-code-info-button">
            <EmptyInfoIcon width={iconSize} height={iconSize} />
          </InfoButton>
        }
      />
      <CustomPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        renderMobile={isMobileView}
        PopoverProps={{
          slotProps: {
            paper: {
              style: {
                maxWidth: "355px",
              },
            },
          },
        }}
      >
        <PopoverContent onClose={onClose} />
      </CustomPopover>
      <EmbeddedCode code={enterpriseIframeText.replace("$[slug]", slug)} />
    </Stack>
  );
};

const PopoverContent = ({ onClose }: { onClose: () => void }) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack
      direction="column"
      gap={isMobileView ? "16px" : "8px"}
      alignItems="stretch"
    >
      <Text
        color={palette.black[100]}
        fontSize={isMobileView ? "18px" : "12px"}
        lineHeight={isMobileView ? "21.6px" : "14.4px"}
        fontWeight="book"
      >
        How to use embedded code?
      </Text>
      {!isMobileView && <Divider color={palette.liftedWhite[100]} />}
      <List>
        {embeddingInfoSteps.map((el, index) => (
          <Item key={index}>{el}</Item>
        ))}
      </List>
      {isMobileView && (
        <Button
          background="primary"
          size="large"
          sx={{ alignSelf: "center", minWidth: "150px" }}
          onClick={onClose}
        >
          OK
        </Button>
      )}
    </Stack>
  );
};

export const embeddingInfoSteps = [
  "Copy provided embedded code.",
  "Navigate to your website or the platform where you want to embed the content.",
  "Paste the copied code into the HTML of your page at the desired location.",
  "Some attributes allow you to customize settings such as size or autoplay. Modify these settings within the iframe code if needed.",
  "Preview your webpage to ensure the embedded content looks and functions as expected. Once you're satisfied, publish the changes.",
];

const List = styled("ol")(({ theme }) => ({
  margin: 0,
  paddingLeft: "20px",

  [theme.breakpoints.down("sm")]: {
    paddingLeft: "24px",
  },
}));

const Item = styled(ListItem)(({ theme }) => ({
  display: "list-item",
  color: palette.gray[300],
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
  padding: 0,

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "16.8px",
  },
}));

const InfoButton = styled(Button)(({ theme }) => ({
  all: "unset",
  height: "16px",
  width: "16px",
  color: palette.accent[3],
  userSelect: "none",
  borderRadius: "4px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: palette.liftedWhite[100],
  },
  [theme.breakpoints.down("sm")]: {
    width: "24px",
    height: "24px",
  },
}));

export default EmbedCodeSection;
