import { Stack } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";
import { CloseIcon } from "@assets/icons";
import { Button } from "@common/Button";
import RiskMainInfos from "./RiskMainInfos";
import { IParsedData } from "../data.types";

const RiskModalHeader = ({
  data,
  onClose,
}: {
  data: IParsedData;
  onClose: () => void;
}) => {
  return (
    <HeaderContainer>
      <RiskMainInfos {...data.general} />
      <Button
        background="tertiary"
        onClick={onClose}
        sx={{ position: "absolute", right: -20 }}
        data-testid="close-risk-profile"
      >
        Close Risk Profile <CloseIcon stroke={palette.gray[300]} />
      </Button>
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 10px",
  position: "relative",
}));

export default RiskModalHeader;
