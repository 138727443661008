import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CheckboxIndeterminateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      htmlColor="transparent"
      {...props}
    >
      <g clipPath="url(#clip0_500_4508)">
        <rect width="24" height="24" rx="6" fill="#131212" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.53125 11.85C4.53125 11.2977 4.97897 10.85 5.53125 10.85H18.1313C18.6835 10.85 19.1313 11.2977 19.1313 11.85C19.1313 12.4023 18.6835 12.85 18.1313 12.85H5.53125C4.97897 12.85 4.53125 12.4023 4.53125 11.85Z"
          fill="#ECECE9"
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#131212" />
      <defs>
        <clipPath id="clip0_500_4508">
          <rect width="24" height="24" rx="6" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CheckboxIndeterminateIcon;
