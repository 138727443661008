import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import UploadIconThick from "@assets/rebrandIcons/UploadIconThick";
import { Text } from "../common/Text";
import { useFileUploadContext } from "./FileUploadContext";
import { UploadCard } from "./FileUploadSnackbar";
import { Button } from "../common/Button";
import { FileUploadStatus, SnackbarFile } from "./types";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";

export default function FileUploadMobileView() {
  const [openModal, setOpenModal] = useState(false);
  const { snackbarFiles, clearSnackbarFiles, isEveryFileUploaded } =
    useFileUploadContext();

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (snackbarFiles.length > 0 && isEveryFileUploaded && !openModal) {
        clearSnackbarFiles();
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [snackbarFiles, isEveryFileUploaded, openModal]);

  const fileUploadAverageProgress = useMemo(() => {
    const totalProgress = snackbarFiles.reduce((acc, curr) => {
      // If file is too large, it's progress shouldn't be accounted while calculating average progress
      if (curr.status !== FileUploadStatus.FILE_TOO_LARGE)
        return acc + curr.uploadProgress;
      return acc;
    }, 0);
    // Use the length of uploading files to calculate average progress
    const uploadingFiles = snackbarFiles.filter(
      (item) => item.status !== FileUploadStatus.FILE_TOO_LARGE,
    );
    return totalProgress / uploadingFiles.length || 0;
  }, [snackbarFiles]);

  const isUploadFailed = snackbarFiles.some(
    (file) =>
      file.status === FileUploadStatus.FAILED ||
      file.status === FileUploadStatus.FILE_NOT_SUPPORTED ||
      file.status === FileUploadStatus.FILE_TOO_LARGE,
  );

  if (snackbarFiles.length < 1) return null;
  return (
    <>
      <UploadStatusButton
        onClick={() => setOpenModal(true)}
        isUploadFailed={isUploadFailed}
        fileUploadAverageProgress={fileUploadAverageProgress}
      />
      <ModalFactory
        variant="dialog"
        renderMobile
        modalProps={{
          sx: { zIndex: 1300 },
          open: openModal,
          onClose: () => setOpenModal(false),
          DrawerProps: {
            dataTestId: "uploading-files-drawer",
          },
        }}
      >
        <Puller />
        <Text
          color="#575353"
          textAlign="center"
          fontWeight={"book"}
          fontSize="18px"
          pb={"20px"}
        >
          Uploads
        </Text>
        <Stack
          flex={1}
          gap={2}
          sx={{ overflowX: "scroll", px: "12px", pb: "90px", pt: "8px" }}
        >
          {snackbarFiles.map((file: SnackbarFile) => {
            return <UploadCard key={file.id} snackbarFile={file} />;
          })}
        </Stack>
        <Stack bottom="20px" position="absolute" pt="20px" width="100%">
          <Button
            onClick={() => setOpenModal(false)}
            sx={{ width: "170px", margin: "auto" }}
          >
            Close
          </Button>
        </Stack>
      </ModalFactory>
    </>
  );
}

const UploadStatusButton = ({
  onClick,
  isUploadFailed,
  fileUploadAverageProgress,
}: {
  onClick: () => void;
  isUploadFailed: boolean;
  fileUploadAverageProgress: number;
}) => {
  return (
    <Box
      onClick={onClick}
      position="fixed"
      bottom="60px"
      right="20px"
      zIndex={1301}
      data-testid="file-upload-floating-button"
    >
      <Box
        data-testid="file-upload-floating-button-data"
        sx={{
          height: "52px",
          width: "52px",
          backgroundColor: isUploadFailed ? "#F5E0DF" : "#E6EAF2",
          borderRadius: "26px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ position: "absolute", top: "-5px" }}>
          <CircularProgress
            size="62px"
            variant="determinate"
            value={isUploadFailed ? 0 : fileUploadAverageProgress}
            sx={{ color: "#6D9CF8", strokeLinecap: "round" }}
            data-testid="file-upload-floating-button-progress"
          />
        </Box>

        <UploadIconThick fill={isUploadFailed ? "#D80D0D" : "#6D9CF8"} />
      </Box>
    </Box>
  );
};

const Puller = styled(Box)(() => ({
  width: 52,
  height: 4,
  backgroundColor: "#FDFDFD",
  borderRadius: 3,
  position: "absolute",
  top: "-12px",
  zIndex: 1500,
  left: "calc(50% - 26px)",
}));
