import { useRef, useState } from "react";

const usePanelState = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [showLatest, setShowLatest] = useState<boolean>(false);
  const sectionsRef = useRef<HTMLParagraphElement[]>([]);

  const toggleLatest = (newVal?: boolean) =>
    setShowLatest(newVal !== undefined ? newVal : (prev) => !prev);

  const closeModal = () => setOpen(false);

  return {
    open,
    closeModal,
    setOpen,
    toggleLatest,
    showLatest,
    sectionsRef,
  };
};

export default usePanelState;
