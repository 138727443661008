import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { QuantityPaymentCard } from "@components/PaymentForm/PaymentCard";
import { palette } from "@palette";
import { SE_PublicFormType } from "@common/PublicForm/types";
import { AlertIcon } from "@assets/icons";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useAppSelector } from "@redux/hooks";
import { selectCart, setCartItem } from "@redux/slices/cart";
import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useDispatch } from "react-redux";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { useCheckTicketsThreshold } from "@common/PublicForm/hooks/useCheckTicketsThreshold";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";
import { EditorState } from "draft-js";

type TicketType = {
  id: string;
  name: string;
  amount: string;
  in_stock: string;
  active: boolean;
  description: {
    enabled: boolean;
    text: EditorState;
  };
  thumbnail?: string | File;
  display: boolean;
};

const EventsWrapper = ({
  isDesktop,
  eventHasEnded,
  data,
  handleChange,
  activeCards,
  setActiveCards,
}: any) => {
  return (
    <Stack
      padding="8px"
      direction="column"
      gap={2}
      alignItems="center"
      width="100%"
      sx={{
        ...(!isDesktop && {
          height: "calc(100% - 64px)",
        }),
      }}
    >
      <Stack
        direction="column"
        gap={2}
        alignItems="stretch"
        sx={{
          width: "100%",
        }}
      >
        <FadeUpWrapper delay={100}>
          <Text variant="h6" fontWeight="medium" lineHeight="22px">
            Choose your ticket
          </Text>
        </FadeUpWrapper>
        {eventHasEnded && (
          <FadeUpWrapper delay={150}>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              width="100%"
              sx={{
                boxShadow:
                  "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
              }}
            >
              <AlertIcon />
              The event has ended
            </Stack>
          </FadeUpWrapper>
        )}
        <Stack gap={2} direction="column" width="100%">
          {data?.tickets.list
            .filter((item: TicketType) => item.active)
            .map((ticket: TicketType, index: number) => {
              const price =
                ticket.amount === null || Number.isNaN(Number(ticket.amount))
                  ? 0
                  : parseFloat(ticket.amount);
              const quantity =
                ticket.in_stock === null ||
                Number.isNaN(Number(ticket.in_stock))
                  ? 0
                  : parseInt(ticket.in_stock);
              return (
                <ErrorCatcher errorID="QuantityPaymentCard" key={ticket.id}>
                  <FadeUpWrapper key={ticket.id} delay={100 + (index + 1) * 50}>
                    <QuantityPaymentCard
                      imageURL={ticket.thumbnail as string}
                      title={ticket.name}
                      name={ticket.name}
                      description={ticket.description.text}
                      price={price}
                      quantity={quantity}
                      id={Number(ticket.id)}
                      category="events"
                      maxPurchase={data.tickets.customer_pays_credit.maxTickets}
                      onChange={handleChange}
                      selected={activeCards.includes(ticket.id)}
                      shouldDisplayLeftTickets={ticket.display}
                      disabled={eventHasEnded}
                      setActiveCards={setActiveCards}
                    />
                  </FadeUpWrapper>
                </ErrorCatcher>
              );
            })}
        </Stack>
      </Stack>
    </Stack>
  );
};

const EventDetailsForm = ({
  data,
  isLoading,
  drawerOpen,
}: {
  data: SE_PublicFormType;
  isLoading?: boolean;
  drawerOpen?: Partial<{
    ID: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    renderCheckout: () => React.ReactNode;
  }>;
}) => {
  const { isDesktop } = getPublishedFormDeviceType();

  const { cartItems } = useAppSelector(selectCart);
  const { isTicketsThresholdReached } = useCheckTicketsThreshold(cartItems);
  const [activeCards, setActiveCards] = useState<number[]>([]);
  const dispatch = useDispatch();

  const handleChange = (id: number) => {
    if (isTicketsThresholdReached(data.tickets.customer_pays_credit.maxTickets))
      return;
    const selectedItemIds = cartItems.map((item) => item.productVariantID);
    const ticket = data?.tickets.list.find((s: any) => s.id === id);
    dispatch(
      setCartItem({
        name: ticket.name,
        productVariantID: +id,
        price: ticket.amount,
        recurringMax: null,
        quantity: 1,
      }),
    );
    setActiveCards((prev) => {
      // Ensure that the quantity of each card is greater than one
      const filteredPreviousList = prev.filter((id) =>
        selectedItemIds.includes(id),
      );
      return [...filteredPreviousList, id];
    });
  };

  const reset = () => {
    setActiveCards([]);
  };

  // on success payment, cartitems is set empty, so resets the selected ticket item also
  React.useEffect(() => {
    if (cartItems.length === 0) {
      reset();
    }
  }, [cartItems]);

  const eventHasEnded = React.useMemo(() => {
    const {
      date: { endDate, day, include_time },
    } = data.date_and_location;
    const currentTime = include_time
      ? Date.now()
      : new Date().setHours(0, 0, 0, 0);
    const eventHasEnded =
      (endDate && endDate.getTime() < currentTime) ||
      (day && day.getTime() < currentTime);

    return eventHasEnded;
  }, [data.date_and_location.date]);

  return (
    <>
      {!isDesktop ? (
        drawerOpen &&
        ComponentRegistry.show(drawerOpen.ID || "", {
          open: !!drawerOpen.open,
          setOpen: drawerOpen.setOpen,
          _renderComponent: () => (
            <>
              <EventsWrapper
                isDesktop={isDesktop}
                eventHasEnded={eventHasEnded}
                data={data}
                handleChange={handleChange}
                activeCards={activeCards}
                setActiveCards={setActiveCards}
              />
            </>
          ),
          _renderActions: () =>
            drawerOpen.renderCheckout && drawerOpen.renderCheckout(),
        })
      ) : (
        <EventsWrapper
          isDesktop={isDesktop}
          eventHasEnded={eventHasEnded}
          data={data}
          handleChange={handleChange}
          activeCards={activeCards}
          setActiveCards={setActiveCards}
        />
      )}
    </>
  );
};

const dateContainer = {
  border: "1px solid #DDDCE5",
  width: "100%",
  backgroundColor: "#FFF",
  boxShadow:
    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
  borderRadius: "5px",
  display: "flex",
  alignItems: "center",
  zIndex: 10,
  position: "absolute",
  top: 10,

  "@media (max-width: 600px)": {
    flexDirection: "column",
    top: 6,
  },
};

const viewMapButton = {
  color: palette.neutral[600],
  margin: "auto",

  "@media (max-width: 600px)": {
    background: "#EFF4F8",
    boxShadow:
      "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
    borderRadius: "6px",
    width: "100%",
  },
  "@media (hover: none)": {
    "&:hover": {
      background: "#EFF4F8",
      boxShadow:
        "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
    },
  },
};

const dateStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  minWidth: "50%",
  width: "50%",
  padding: "4px 32px",

  "@media (max-width: 600px)": {
    width: "100%",
  },
};

const locationStyles = {
  ...dateStyle,

  "@media (max-width: 600px)": {
    borderTop: `1px solid ${palette.neutral[100]}`,
    width: "100%",
  },
  "@media (min-width: 600px)": {
    borderLeft: `1px solid ${palette.neutral[100]}`,
  },
};

const Image = styled("img")({
  borderRadius: "5px",
  height: 241,
  width: 342,
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const MultiDateSpan = styled("span")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default EventDetailsForm;
