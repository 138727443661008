import {
  useRef,
  useEffect,
  useState,
  DependencyList,
  EffectCallback,
} from "react";

export const useStateEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    } else return effect();
  }, deps);
};

type ActionCallback = (data?: any) => Promise<any | void>;
type TriggerAction = (callback?: ActionCallback, data?: any) => void;

export const useAsyncAction = (): [boolean, TriggerAction] => {
  const [isLoading, setIsLoading] = useState(false);

  const triggerAction = (callback?: ActionCallback, data?: any) => {
    setIsLoading(true);
    if (callback) data ? callback(data) : callback();
  };

  useEffect(() => {
    if (isLoading)
      setTimeout(() => {
        setIsLoading(false);
      }, 600);
  }, [isLoading]);

  return [isLoading, triggerAction];
};
