import { Text } from "@common/Text";
import { useParams } from "react-router-dom";
import giveCorpLogo from "@assets/logos/Logo.png";
import { Stack, styled } from "@mui/material";
import { Button } from "@common/Button";
import Footer from "@pages/Login/Footer";
import { BaseLink } from "@common/Campaigns/BaseLink";

function EnterpriseRestoredPage() {
  const { enterpriseName } = useParams();
  return (
    <Page>
      <Container>
        <img
          src={giveCorpLogo}
          alt="give cort logo"
          width={111}
          height={40}
          style={{
            margin: "auto",
          }}
        />
        <Stack gap={1}>
          <Title>“{enterpriseName}”</Title>

          <Description>has been successfully restored!</Description>
        </Stack>
        <BaseLink
          link="/acquirer/providers"
          replace
          sx={{
            border: "none",
            marginTop: "20px",
          }}
        >
          <Button size="small" sx={{ width: "fit-content", paddingX: "18px" }}>
            Open Portal
          </Button>
        </BaseLink>
      </Container>
      <Footer hideLogo />
    </Page>
  );
}

export default EnterpriseRestoredPage;

const Container = styled(Stack)({
  margin: "auto",
  gap: "40px",
  alignItems: "center",
});

const Page = styled(Stack)({
  height: "100vh",
});
const Description = styled(Text)(({ theme }) => ({
  color: theme.palette.neutral["80"],
  textAlign: "center",
  fontSize: "24px",
  fontWeight: 350,
}));

const Title = styled(Description)(() => ({
  fontWeight: 500,
}));
