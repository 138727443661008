import Skeleton, { DoubledSkeleton } from "@components/animation/Skeleton";
import { Box, Divider, Stack } from "@mui/material";

const RiskActivitySkeleton = () => {
  return (
    <Stack spacing={3} padding="12px 16px" data-testid="risk-activity-skeleton">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        height="60px"
      >
        <DoubledSkeleton direction="row" height={30} width={100} spacing={2} />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Skeleton height={25} width={70} />
        <DoubledSkeleton direction="row" height={30} width={60} spacing={2} />
      </Box>
      <Stack spacing={1}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Stack
              key={index}
              spacing={3}
              bgcolor="#fff"
              p="24px 16px 32px 16px"
              borderRadius="16px"
              sx={{ boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)" }}
            >
              <Stack direction="row" spacing={2}>
                <Skeleton height={25} width={50} />
                <Skeleton height={25} width={90} />
                <Skeleton height={25} width={50} />
                <Box flexGrow={1} />
                <Skeleton height={25} width={50} />
              </Stack>
              <Skeleton height={35} width="100%" />
              <Divider />
              <Stack direction="row" spacing={2}>
                <Skeleton height={25} width={50} />
                <Skeleton height={25} width={90} />
                <Box flexGrow={1} />
                <Skeleton height={25} width={50} />
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default RiskActivitySkeleton;
