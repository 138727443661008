import Cookies from "js-cookie";
import qs from "query-string";

export const getSearchParams = (url: string) => qs.parse(new URL(url).search);
const getPathName = (url: string) => qs.parse(new URL(url).pathname);

/*
 Parse the search params to get rig of filters and keep the rest then stringify it
*/
const removedFilterKeyAndStringifySearchParams = (url: string) => {
  const searchParams = getSearchParams(url);

  return Object.keys(searchParams)
    .filter((x) => !x.includes("filter"))
    .reduce((acc, v) => {
      return `${acc}${acc.length ? "&" : ""}${v}=${searchParams[v]}`;
    }, "");
};

export const parsedQueryString = (url: string, output: string) => {
  const searchParams = getSearchParams(url);
  const pathname = getPathName(url);
  const withoutFilterSearchParams =
    removedFilterKeyAndStringifySearchParams(url);

  if (searchParams?.["?filter"]) {
    const currentStr = searchParams["?filter"];

    output = `${currentStr};${output}`;

    const f = Object.keys(pathname).find((x) => x.includes("products"));

    if (f) {
      const pathArr = f.split("/").filter(Boolean);

      if (pathArr.length > 1) {
        //if at some point are more than one paths, it means that we are not in the main product page and is very likely than we are inside the route of a single element where the second path is the id of the element
        return;
      }

      return `/${f.replaceAll("/", "")}?filter=${encodeURIComponent(
        output,
      )}&${withoutFilterSearchParams}`;
    }
  }
};

export const getGivecashToWebData = () => {
  const user = Cookies.get("user");
  const parsedUser = user && JSON.parse(user);

  let params = new URLSearchParams(document.location.search);
  const next = params.get("next");
  if (next) {
    params = new URLSearchParams(
      new URLSearchParams(next?.split("?")[1] || ""),
    );
  }
  const merchantId = parseInt(params.get("merchant") || "");
  const enterpriseId = parseInt(params.get("provider") || "");
  const acquirerId = parseInt(params.get("acquirer") || "");
  const merchantName = params.get("merchantName") || "";
  const redirectRoute = encodeURIComponent(
    document.location.pathname + document.location.search,
  );
  const fromMobile = merchantId && enterpriseId && acquirerId;

  let toPath = "";
  if (fromMobile) {
    const selectedAccount = parsedUser?.id;

    if (selectedAccount === merchantId) {
      toPath = `${parsedUser.role}/manage-money`;
    }
    if ([enterpriseId, acquirerId].includes(selectedAccount)) {
      toPath = `${
        parsedUser.role
      }/processing?merchant=${merchantId}&merchantName=${encodeURIComponent(
        merchantName,
      )}`;
    }
  }

  return {
    toPath,
    fromMobile,
    redirectRoute,
    merchantId,
    enterpriseId,
    acquirerId,
    merchantName,
  };
};
