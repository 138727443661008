import React from "react";

type ArrowUpChevronProps = {
  height?: number;
  width?: number;
  fill?: string;
  className?: string;
};

const ArrowUpChevron = ({
  fill = "#FFFFFD",
  width = 14,
  height = 9,
  className = "",
}: ArrowUpChevronProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6922 7.9417C13.6341 7.99981 13.5652 8.04591 13.4893 8.07737C13.4134 8.10882 13.3321 8.12501 13.25 8.12501C13.1678 8.12501 13.0865 8.10882 13.0106 8.07737C12.9348 8.04591 12.8658 7.99981 12.8078 7.9417L6.99998 2.13311L1.19217 7.9417C1.07489 8.05898 0.915834 8.12486 0.749981 8.12486C0.584129 8.12486 0.425069 8.05898 0.307794 7.9417C0.190518 7.82443 0.124634 7.66537 0.124634 7.49952C0.124634 7.33366 0.190518 7.1746 0.307794 7.05733L6.55779 0.807327C6.61584 0.749217 6.68477 0.703118 6.76064 0.671665C6.83652 0.640213 6.91785 0.624023 6.99998 0.624023C7.08212 0.624023 7.16344 0.640213 7.23932 0.671665C7.31519 0.703118 7.38412 0.749217 7.44217 0.807327L13.6922 7.05733C13.7503 7.11537 13.7964 7.1843 13.8278 7.26018C13.8593 7.33605 13.8755 7.41738 13.8755 7.49952C13.8755 7.58165 13.8593 7.66298 13.8278 7.73885C13.7964 7.81473 13.7503 7.88366 13.6922 7.9417Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowUpChevron;
