import SwipeableDrawerMobile, {
  SwipeableDrawerMobileProps,
} from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import AnimatedDialog, { BaseModalProps } from "../atoms/AnimatedDialog";
import { palette } from "@palette";
import { SxProps } from "@mui/material";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { SlideProps } from "@mui/material";

export type DialogVariantProps = {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  children: React.ReactNode;
  width?: number | string;
  showMobileView?: boolean;
  SlideProps?: Partial<SlideProps>;
  DialogProps?: Omit<BaseModalProps, "onClose" | "open">;
  DrawerProps?: Omit<
    SwipeableDrawerMobileProps,
    "onOpen" | "onClose" | "open"
  > & {
    dataTestId?: string;
  };
};

const DRAWER_DEFAULT_TOP = "96px";

const DialogVariant = ({
  open,
  onClose,
  children,
  width,
  sx,
  showMobileView = false,
  DialogProps,
  DrawerProps = {},
  SlideProps,
}: DialogVariantProps) => {
  const commonProps = {
    open,
    sx,
  };

  const { dataTestId, ...drawerProps } = DrawerProps;

  if (showMobileView) {
    return (
      <SwipeableDrawerMobile
        anchor="bottom"
        onOpen={() => undefined}
        {...commonProps}
        {...drawerProps}
        data-testid={dataTestId}
        SlideProps={SlideProps}
        onClose={() => !!onClose && onClose()}
        PaperProps={{
          ...drawerProps?.PaperProps,
          sx: {
            background: palette.neutral[5],
            position: "relative",
            maxHeight: `calc(100vh - ${
              typeof (sx as any)?.top === "number"
                ? (sx as any)?.top
                : DRAWER_DEFAULT_TOP
            })`,
            ...drawerProps?.PaperProps?.sx,
          },
        }}
        sx={{
          top: "auto",
          ...sx,
        }}
      >
        <ErrorCatcher errorID="swipeable-drawer">{children}</ErrorCatcher>
      </SwipeableDrawerMobile>
    );
  }

  return (
    <AnimatedDialog
      width={width}
      onClose={onClose}
      {...DialogProps}
      {...commonProps}
    >
      <ErrorCatcher errorID="animated-dialog">{children}</ErrorCatcher>
    </AnimatedDialog>
  );
};

export default DialogVariant;
