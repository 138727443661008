import { Text } from "@common/Text";
import { palette } from "@palette";
import { styled } from "@mui/material";
import { memo } from "react";
import { Tooltip } from "@common/Tooltip";
export type TChallengeTypeName =
  | "enhanced_due_diligence"
  | "customer_due_diligence";

type TRiskStatus = {
  challengeTypeName: TChallengeTypeName;
};
const ChallengeTypeChip = ({ challengeTypeName }: TRiskStatus) => {
  if (!challengeTypeName || !chipData[challengeTypeName]) return null;

  const data = chipData[challengeTypeName];
  return (
    <Tooltip
      maxWidth="278px"
      title={data.tooltipTitle}
      description={data.tooltipDescription}
      titleContainerSx={{
        alignSelf: "flex-start",
        fontSize: "12px",
        lineHeight: "14.2px",
        color: palette.neutral[5],
        marginBottom: "10px",
      }}
      descriptionSx={{
        fontSize: "12px",
        lineHeight: "14.2px",
        color: palette.neutral[20],
        textAlign: "left",
      }}
    >
      <StyledText>{data.label}</StyledText>
    </Tooltip>
  );
};

const StyledText = styled(Text)(() => ({
  fontSize: "14px",
  lineHeight: "16.8px",
  padding: "3px 16px",
  borderRadius: "16px",
  color: palette.neutral[80],
  backgroundColor: palette.neutral[10],
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content",
}));
type TValuesObject = {
  label: string;
  tooltipTitle: string;
  tooltipDescription: string;
};
const chipData: Record<TChallengeTypeName, TValuesObject> = {
  customer_due_diligence: {
    label: "CDD",
    tooltipTitle: "Customer Due Diligence (CDD)",
    tooltipDescription:
      "These are the system challenges necessary for underwriting and approving the merchant.",
  },
  enhanced_due_diligence: {
    label: "EDD",
    tooltipTitle: "Enhanced Due Diligence (EDD)",
    tooltipDescription: "These are the custom challenges from an underwriter.",
  },
};

export default memo(ChallengeTypeChip);
