import { styled, Stack } from "@mui/material";
import { IconButton } from "@common/IconButton";
import { palette } from "@palette";

const PanelContainer = styled(Stack)(({ theme }) => ({
  background: palette.neutral[5],
  width: "100%",
  height: "100%",
  alignItems: "stretch",
  flexDirection: "row",
  padding: "16px 24px",
  gap: "16px",

  [theme.breakpoints.up("sm")]: {
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarGutter: "stable both-edges",
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const TeamInfoContainer = styled(Stack)(({ theme }) => ({
  padding: "0 16px 24px",
  width: "40%",
  flexDirection: "column",
  gap: "32px",
  alignItems: "stretch",
  position: "relative",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    width: "100%",
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  boxShadow: "none",
  border: "none",
  background: "none",
  height: "24px !important",
  width: "24px !important",
  padding: "2px !important",
}));

export { PanelContainer, TeamInfoContainer, StyledIconButton };
