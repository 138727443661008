import { StackedSingleBar, TimeLineChart } from "@components/charts";
import { Box, Grid, Stack, styled } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { TData } from "@components/charts/StackedSingleBar/StackedSingleBar";
import { TimeLineChartData } from "@components/charts/LineCharts/types";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { AMOUNTS_REGION } from "@constants/constants";
import React from "react";
import { ChartsOverview } from "../data.types";
import { parseAmount } from "@utils/index";
import moment from "moment";
import TimeLineChartFilters from "./TimeLineChartFilters";
import { QKEY_ANALYTICS_RECURRING_CUSTOMERS } from "@constants/queryKeys";
import { useAppSelector } from "@redux/hooks";
import { selectDateFilter } from "@redux/slices/analytics";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type ExpandedPanelStatsProps = {
  paymentsData: TData;
  subscriptionCustomersData: TData;
  postedPaymentsData: TimeLineChartData[];
  recurringCustomersData: TimeLineChartData[];
  chartsOverview: ChartsOverview;
};

const ExpandedPanelStats = ({
  paymentsData,
  subscriptionCustomersData,
  postedPaymentsData,
  recurringCustomersData,
  chartsOverview,
}: ExpandedPanelStatsProps) => {
  const { isMobileView } = useCustomTheme();
  const containerSpacing = {
    rowSpacing: "32px",
    columnSpacing: isMobileView ? 0 : "64px",
  };
  const { postedPayments, recurringCustomers } = chartsOverview;

  const customersDateFilter = useAppSelector((state) =>
    selectDateFilter(state, QKEY_ANALYTICS_RECURRING_CUSTOMERS),
  );

  return (
    <Grid container {...containerSpacing} sx={{ paddingBottom: "32px" }}>
      <Grid item xs={12} sm={6}>
        <FadeUpWrapper delay={100}>
          <ChartLayout>
            <ChartTitle
              title="Settled Payments"
              increment={postedPayments.increment}
              subtitle={{
                value: postedPayments.total,
                text: `${postedPayments?.previousPeriod} previous period`,
              }}
            />
            <TimeLineChart
              data={postedPaymentsData}
              height={211}
              customTimeConfig={{ unit: "month" }}
              customOptions={{
                tooltip: {
                  callbacks: {
                    title: () => "",
                    label: (context: any) => {
                      const label = context?.label;
                      const title = moment(new Date(label)).format("MMM YYYY");
                      return `${title}: ${context?.formattedValue}`;
                    },
                  },
                },
              }}
            />
          </ChartLayout>
        </FadeUpWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FadeUpWrapper delay={isMobileView ? 150 : 100}>
          <StackedSingleBar title="Payments" data={paymentsData} />
        </FadeUpWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FadeUpWrapper delay={200}>
          <ChartLayout>
            <ChartTitle
              title="Recurring Customers"
              increment={recurringCustomers.increment}
              subtitle={{
                value: parseAmount(recurringCustomers.total),
                text: "Recurring Average Amount (USD)",
              }}
              actions={
                <TimeLineChartFilters
                  type="button"
                  queryKey={QKEY_ANALYTICS_RECURRING_CUSTOMERS}
                />
              }
            />
            <TimeLineChart
              data={recurringCustomersData}
              height={211}
              customOptions={{
                tooltip: {
                  displayColors: false,
                  callbacks: {
                    label: (context: any) => {
                      const index = context.dataIndex;
                      return recurringCustomersData.map((dataset) => {
                        return dataset.label + ": " + dataset.entries[index].y;
                      });
                    },
                  },
                },
              }}
              customTimeConfig={{
                displayFormats: {
                  day: customersDateFilter?.type === "days" ? "ddd" : "MMM DD",
                  month: "MMM",
                  year: "YYYY",
                },
              }}
            />
          </ChartLayout>
        </FadeUpWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FadeUpWrapper delay={isMobileView ? 250 : 200}>
          <StackedSingleBar
            title="Subscription Customers"
            data={subscriptionCustomersData}
          />
        </FadeUpWrapper>
      </Grid>
    </Grid>
  );
};

const ChartTitle = ({
  title,
  increment,
  actions,
  subtitle,
}: {
  title: string;
  increment: number;
  actions?: React.ReactNode;
  subtitle: { value: number | string; text: string };
}) => {
  const { isDesktopView } = useCustomTheme();
  return (
    <Stack direction="column" gap={1} alignItems="stretch">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box display="inline-flex" gap="8px" alignItems="center">
          <Text
            color={palette.black[100]}
            fontSize="18px"
            fontWeight="book"
            lineHeight="21.6px"
            letterSpacing="-0.18px"
          >
            {title}
          </Text>
          <LabelTag>
            {`${increment < 0 ? "-" : "+"}${parseIncrement(increment)}`} %
          </LabelTag>
        </Box>
        {isDesktopView && actions}
      </Stack>
      <Stack direction="row" gap="12px" alignItems="center">
        <Text
          color={palette.black[300]}
          fontSize="24px"
          fontWeight="book"
          lineHeight="32.4px"
          letterSpacing="-0.24px"
        >
          {subtitle.value}
        </Text>
        <Text
          color={palette.gray[300]}
          fontSize="14px"
          fontWeight="book"
          lineHeight="16.8px"
        >
          {subtitle.text}
        </Text>
      </Stack>
      {!isDesktopView && (
        <Box marginTop="8px" alignSelf="flex-end">
          {actions}
        </Box>
      )}
    </Stack>
  );
};

const parseIncrement = (increment: number) => {
  return Math.abs(increment).toLocaleString(AMOUNTS_REGION, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
};

const LabelTag = styled(Text)(() => ({
  padding: "2px 8px",
  borderRadius: "4px",
  border: `1px solid ${palette.filled.green}`,
  opacity: 0.8,
  background: "rgba(214, 252, 218, 0.50)",
  color: palette.filled.green,
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ChartLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack direction="column" alignItems="stretch" gap={2}>
      {children}
    </Stack>
  );
};

export default ExpandedPanelStats;
