import { PlaidIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { Box, BoxProps, Stack } from "@mui/material";
import { palette } from "@palette";

type Props = {
  name?: string;
  accountNumber?: number;
  logo?: string;
};

const OnboardingPlaidCard = ({
  name,
  accountNumber,
  logo,
  ...props
}: Props & BoxProps) => {
  return (
    <Box sx={containerStyle} {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PlaidIcon width={40} height={40} fill="#ffffff" />
        {name && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className="plaid-disconnect"
          >
            <Text fontWeight="book" color="#ffffff">
              Disconnect
            </Text>
          </Stack>
        )}
      </Box>
      <Text sx={nameStyle}>{name || "Quick link through Plaid"}</Text>
      <Text sx={descriptionStyle}>
        {accountNumber
          ? `Bank account ••••••${accountNumber}`
          : "Secure & Swift! Connect your account through Plaid for enhanced security and faster approval."}
      </Text>
    </Box>
  );
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "12px",
  padding: "16px",
  border: "1px solid rgba(228, 228, 228, 0.50)",
  background: palette.neutral[100],
  cursor: "pointer",
  "& .plaid-disconnect": {
    visibility: "hidden",
  },
  "&:hover": {
    "& .plaid-disconnect": {
      visibility: "visible",
    },
    boxShadow: "0px 8px 15px 0px rgba(2, 2, 2, 0.15)",
  },
};

const nameStyle = {
  fontSize: "18px",
  fontWeight: "400",
  color: "#FBFBFB",
};

const descriptionStyle = {
  fontSize: "14px",
  fontWeight: "300",
  color: "#FBFBFB",
};

export default OnboardingPlaidCard;
