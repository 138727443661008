import { Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Text } from "@common/Text";
import { palette } from "@palette";

const paperStyle = {
  padding: "12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  mb: "8px",
};

const nameStyle = {
  color: palette.neutral[800],
  fontSize: 16,
  fontWeight: "600",
  mb: "4px",
};

const dateStyle = {
  color: palette.neutral[600],
  fontSize: 14,
  fontWeight: "500",
};

const emailStyle = {
  color: palette.neutral[600],
  fontSize: 14,
  fontWeight: "400",
};

const productStyle = {
  color: palette.neutral[800],
  fontSize: 14,
  fontWeight: "600",
  mb: "4px",
};

const rightBoxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
};

type WinnerListItemProps = {
  name: string;
  date: string;
  email: string;
  product: string;
  id: string;
};

const WinnerListItem: React.FC<WinnerListItemProps> = ({
  name,
  date,
  email,
  product,
  id,
}: WinnerListItemProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Paper
      elevation={3}
      sx={{ ...paperStyle, ...(!isDesktop && { display: "block" }) }}
    >
      <Box sx={{ mb: isDesktop ? 0 : "12px" }}>
        <Text sx={nameStyle}>
          {name} <span style={dateStyle}>{date}</span>
        </Text>
        <Text style={emailStyle}>{email}</Text>
      </Box>
      <Box sx={{ ...(isDesktop && rightBoxStyle) }}>
        <Text style={productStyle}>{product}</Text>
        <Text style={emailStyle}>{id}</Text>
      </Box>
    </Paper>
  );
};

export default WinnerListItem;
