import { Components } from "@mui/material";
import { palette } from "@palette";

const getTextColor = (type: string) => {
  switch (type) {
    case "primary":
      return palette.primary[50];
    case "secondary":
      return palette.primary.main;
    case "tertiary":
      return palette.neutral[300];
    case "textBtn":
      return palette.neutral[800];
    default:
      return palette.primary[50];
  }
};

const getBgColor = (type: string) => {
  switch (type) {
    case "primary":
      return palette.common.white;
    case "secondary":
      return palette.primary[50];
    case "tertiary":
      return palette.common.white;
    case "textBtn":
      return "transparent";
    default:
      return palette.primary.main;
  }
};

const getHoverColor = (type: string) => {
  switch (type) {
    case "primary":
      return palette.primary[900];
    case "secondary":
      return palette.primary[100];
    case "tertiary":
      return palette.neutral[200];
    case "textBtn":
      return "transparent";
    default:
      return palette.primary[900];
  }
};

const getFocusColor = (type: string) => {
  switch (type) {
    case "primary":
      return "0px 0px 4px rgba(2, 2, 2, 0.25), 0px 0px 0px 3px #E6C1F7";
    case "secondary":
      return "0px 0px 4px rgba(2, 2, 2, 0.25), 0px 0px 0px 3px #E6C1F7";
    case "tertiary":
      return "0px 0px 4px rgba(2, 2, 2, 0.25), 0px 0px 0px 3px rgba(177, 175, 184, 0.25)";
    case "textBtn":
      return "transparent";
    default:
      return "0px 0px 4px rgba(2, 2, 2, 0.25), 0px 0px 0px 3px #E6C1F7";
  }
};

const getBorder = (type: string) => {
  switch (type) {
    case "primary":
      return `1px solid ${palette.primary.main}`;
    case "secondary":
      return `1px solid ${palette.primary[50]}`;
    case "tertiary":
      return `1px solid ${palette.neutral[200]}`;
    case "textBtn":
      return "none";
    default:
      return `1px solid ${palette.primary.main}`;
  }
};

const getDisabledBgColor = (type: string) => {
  switch (type) {
    case "primary":
      return palette.primary[100];
    case "secondary":
      return palette.primary[50];
    case "tertiary":
      return palette.neutral[100];
    case "textBtn":
      return "transparent";
    default:
      return palette.primary[100];
  }
};

const getDisabledColor = (type: string) => {
  switch (type) {
    case "primary":
      return palette.primary[300];
    case "secondary":
      return palette.primary[100];
    case "tertiary":
      return palette.neutral[400];
    case "textBtn":
      return palette.neutral[200];
    default:
      return palette.primary[50];
  }
};

const applyStyle = (variant: string) => {
  return {
    color: getTextColor(variant),
    background: getBgColor(variant),
    border: getBorder(variant),
    "&:hover": {
      borderColor: getHoverColor(variant),
      background: getHoverColor(variant),
    },
    "&:disabled": {
      borderColor: "transparent",
      color: getDisabledColor(variant),
      background: getDisabledBgColor(variant),
    },
    "&:focus": {
      boxShadow: getFocusColor(variant),
    },
  };
};

export const button: Components["MuiButton"] = {
  defaultProps: {
    size: "medium",
    variant: "primary",
    disableRipple: true,
    disableElevation: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: {
      lineHeight: "24px",
      fontWeight: 500,
      fontSize: "14px",
      borderRadius: "8px",
      padding: "8px 24px",
      gap: "8px",
      height: "44px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: palette.neutral[800],

      "&.MuiButton-sizeSmall": {
        padding: "4px 16px",
        fontWeight: 500,
        gap: "4px",
        height: "32px",
        fontSize: "12px",
        lineHeight: "18px",
      },

      "& .MuiButton-startIcon, .MuiButton-endIcon": {
        margin: 0,
      },

      // USING BUTTON FOR ICON BUTTTON
      "&.CustomIconButton": {
        height: "44px",
        width: "44px",
        minWidth: "0",
        padding: "8px 8px",
      },

      "&.CustomIconButton.MuiButton-sizeSmall": {
        height: "32px",
        width: "32px",
        padding: "4px 4px !important",
      },

      "&.CustomIconButton.MuiButton-sizeLarge": {
        height: "80px",
        width: "80px",
        padding: "11.46px 11.46px !important",
      },
    },
  },
  variants: [
    {
      props: { variant: "primary" },
      style: {
        border: "2px solid #f1f1f1",
        borderRadius: "6px",
        backgroundColor: palette.common.white,
        boxShadow:
          "2px 2px 4px rgba(114, 142, 171, 0.1), -2px -2px 20px #FFFFFF, 4px 4px 16px rgba(111, 140, 176, 0.41)",

        "&:hover": {
          backgroundColor: "#EFF4F8",
        },
        "&:disabled": {
          boxShadow:
            "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
          span: {
            opacity: 0.2,
          },
        },
      },
    },
    {
      props: { variant: "secondary" },
      style: applyStyle("secondary"),
    },

    {
      props: { variant: "tertiary" },
      style: applyStyle("tertiary"),
    },
    {
      props: { variant: "text" },
      style: {
        borderRadius: "6px",
        border: "2px solid transparent",

        "&:hover": {
          backgroundColor: "none",
          boxShadow: "none",
          background: "none",
        },
        "&:active": {
          border: "2px solid #f1f1f1",
          boxShadow:
            "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
        },
        "&:disabled": {
          boxShadow: "none",
          span: {
            opacity: 0.2,
          },
        },
      },
    },
  ],
};

export const buttonBase: Components["MuiButtonBase"] = {
  defaultProps: {
    disableRipple: true,
  },
};
