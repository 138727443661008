import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { Box, Divider, Stack } from "@mui/material";
import { Controller, FormProvider } from "react-hook-form";
import ActivityCard from "../RiskProfile/components/RiskProfileActivity/ActivityCard";
import { TRiskActivity } from "../RiskProfile/types";
import { RHFInput } from "@common/Input";
import { RadioGroup } from "@mui/material";
import { Radio } from "@common/Radio";
import { FormControlLabel } from "@common/FormControlLabel";
import { Text } from "@common/Text";
import { palette } from "@theme/colors";
import Skeleton from "@components/animation/Skeleton";
import { RHFCheckbox } from "@common/Checkbox";
import { useUpdateEscalation } from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useUpdateEscalation";
import { isOtherReason } from "../RiskProfile/utils";

interface IComponentProps {
  merchantId: number;
  data: TRiskActivity;
}

const ManageRiskTriggerModal = NiceModal.create(
  ({ merchantId, data }: IComponentProps) => {
    const modal = useModal();
    const { isMobileView } = useCustomTheme();
    const {
      onSave,
      isValid,
      isDirty,
      isLoading,
      methods,
      reasonsLoading,
      reasons,
    } = useUpdateEscalation({
      merchantId,
      activityData: data,
    });

    const reasonID = methods.watch("reasonID");

    const handleCancel = () => modal.remove();
    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 541,
        }}
      >
        <FormProvider {...methods}>
          <Stack
            component="form"
            id="manage-risk-trigger-form"
            data-testid="manage-risk-trigger-form"
            gap="16px"
            sx={{
              overflowY: "scroll",
              "-ms-overflow-style": "none",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            onSubmit={methods.handleSubmit(onSave)}
          >
            <Box padding="16px 16px 0 16px">
              <ModalTitle
                title="Manage Risk Trigger"
                description="Select a reason for the trigger activation and add comments. Mark as OK to close the trigger or save to keep it open. You can reinstate a closed trigger later."
                onClose={handleCancel}
              />
            </Box>
            <Stack
              px="16px"
              sx={{ overflowY: "scroll" }}
              gap="16px"
              divider={<Divider />}
            >
              <ActivityCard data={data} readOnly />
              <Stack gap={"12px"}>
                {reasonsLoading || !reasons ? (
                  Array(6)
                    .fill(null)
                    .map((item, i) => <Skeleton sx={{ my: "8px" }} key={i} />)
                ) : (
                  <>
                    <Text color={palette.neutral[80]}>
                      Reasons the risk trigger was activated:
                    </Text>
                    <Controller
                      control={methods.control}
                      name="reasonID"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Box>
                          <RadioGroup sx={{ gap: 2 }}>
                            {reasons.map((reason) => (
                              <FormControlLabel
                                key={reason.id}
                                control={<Radio />}
                                label={
                                  <Text pt={"4px"} color={palette.neutral[80]}>
                                    {reason.name}
                                  </Text>
                                }
                                value={reason.id}
                                onChange={onChange}
                                checked={Number(value) === reason.id}
                              />
                            ))}
                          </RadioGroup>
                          {error && (
                            <Text
                              pt="12px"
                              pl="12px"
                              color={palette.neutral[80]}
                            >
                              {error.message}
                            </Text>
                          )}
                        </Box>
                      )}
                    />
                    {isOtherReason(reasonID, reasons) && (
                      <RHFInput
                        name="customReasonText"
                        label="Reason"
                        fullWidth
                        sx={{ marginLeft: "32px", marginTop: "12px" }}
                      />
                    )}
                  </>
                )}
              </Stack>
              <Stack gap="12px">
                <RHFInput
                  name="notes"
                  label="Comment"
                  multiline
                  rows={2}
                  fullWidth
                />
                {data.isIgnored ? (
                  <RHFCheckbox
                    sx={{ paddingX: "4px" }}
                    name={"isReinstated"}
                    dataTestId="mark-activity-as-reinstated-checkbox"
                    label={"Reinstate this trigger"}
                  />
                ) : (
                  <RHFCheckbox
                    sx={{ paddingX: "4px" }}
                    name={"isIgnored"}
                    dataTestId="mark-activity-as-okay-checkbox"
                    label={"Mark this risk trigger as OK"}
                  />
                )}
              </Stack>
            </Stack>
            <ModalActions
              containerProps={{ sx: { m: "16px", gap: "16px" } }}
              secondaryAction={{
                label: "Cancel",
                onClick: handleCancel,
                disabled: isLoading,
              }}
              primaryAction={{
                label: "Save",
                disabled: isLoading || !isValid || !isDirty,
                type: "submit",
                component: "button",
                dataTestId: "save-manage-risk-trigger",
                form: "manage-risk-trigger-form",
              }}
            />
          </Stack>
        </FormProvider>
      </ModalFactory>
    );
  },
);

export default ManageRiskTriggerModal;
