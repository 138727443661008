import { SvgIconProps } from "@mui/material";

const ResyncIcon = ({
  height = 12,
  width = 14,
  fill = "#131212",
}: SvgIconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3538 11.6462C12.4002 11.6927 12.4371 11.7478 12.4623 11.8085C12.4874 11.8692 12.5004 11.9343 12.5004 12C12.5004 12.0657 12.4874 12.1308 12.4623 12.1915C12.4371 12.2522 12.4002 12.3073 12.3538 12.3538C12.2863 12.4206 10.6763 14 8 14C6.54 14 5.11688 13.4312 3.8575 12.3494L2.85375 13.3538C2.78382 13.4238 2.6947 13.4714 2.59765 13.4908C2.50061 13.5101 2.40002 13.5002 2.30861 13.4623C2.21719 13.4244 2.13908 13.3603 2.08414 13.278C2.0292 13.1957 1.99992 13.0989 2 13V10C2 9.86739 2.05268 9.74021 2.14645 9.64645C2.24022 9.55268 2.36739 9.5 2.5 9.5H5.5C5.59895 9.49992 5.69569 9.5292 5.77799 9.58414C5.86029 9.63908 5.92444 9.71719 5.96231 9.80861C6.00019 9.90002 6.01009 10.0006 5.99076 10.0977C5.97144 10.1947 5.92376 10.2838 5.85375 10.3538L4.5675 11.64C5.38 12.3219 6.55188 13 8 13C10.2669 13 11.6331 11.66 11.6462 11.6462C11.6927 11.5998 11.7478 11.5629 11.8085 11.5377C11.8692 11.5126 11.9343 11.4996 12 11.4996C12.0657 11.4996 12.1308 11.5126 12.1915 11.5377C12.2522 11.5629 12.3073 11.5998 12.3538 11.6462ZM13.6912 2.53813C13.5999 2.50023 13.4994 2.49027 13.4023 2.50952C13.3053 2.52876 13.2162 2.57635 13.1462 2.64625L12.1425 3.65062C10.8831 2.56875 9.46 2 8 2C5.32375 2 3.71375 3.57938 3.64625 3.64625C3.55243 3.74007 3.49972 3.86732 3.49972 4C3.49972 4.13268 3.55243 4.25993 3.64625 4.35375C3.74007 4.44757 3.86732 4.50028 4 4.50028C4.13268 4.50028 4.25993 4.44757 4.35375 4.35375C4.36688 4.34 5.73312 3 8 3C9.44812 3 10.62 3.67813 11.4325 4.36L10.1462 5.64625C10.0762 5.71618 10.0286 5.8053 10.0092 5.90235C9.98991 5.99939 9.99981 6.09998 10.0377 6.1914C10.0756 6.28281 10.1397 6.36092 10.222 6.41586C10.3043 6.4708 10.4011 6.50008 10.5 6.5H13.5C13.6326 6.5 13.7598 6.44732 13.8536 6.35355C13.9473 6.25979 14 6.13261 14 6V3C14 2.90111 13.9706 2.80445 13.9157 2.72223C13.8607 2.64002 13.7826 2.57595 13.6912 2.53813Z"
        fill="#6D9CF8"
      />
    </svg>
  );
};

export default ResyncIcon;
