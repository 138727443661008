import Box, { BoxProps } from "@mui/material/Box";
import {
  Tab,
  Tabs as MuiTabs,
  TabProps as MuiTabProps,
  TabsProps as MuiTabsProps,
} from "@mui/material";

type TabPanelProps = BoxProps & {
  index: number;
  value: number;
};

type TabsProps = MuiTabsProps & {
  right?: boolean;
  size?: "medium" | "large";
  gap?: string | number;
};

export default function Tabs(props: TabsProps) {
  return (
    <MuiTabs
      sx={{
        ...(props.right && {
          "& .MuiTabs-flexContainer": {
            justifyContent: "flex-end",
          },
        }),
        ...(props.gap && {
          "& .MuiTabs-flexContainer": {
            gap: props.gap,
          },
        }),
        ...(props.size === "large" && {
          "& .MuiTab-root": {
            height: 44,
          },
        }),
        ...props.sx,
      }}
      {...props}
    />
  );
}

export const ButtonTab = ({ className, ...props }: MuiTabProps) => {
  return <Tab className={`MuiButtonTab-root ${className}`} {...props} />;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ py: 2, ...sx }} {...other}>
          {children}
        </Box>
      )}
    </div>
  );
};
