import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectMasqueradeMode } from "@redux/slices/app";
import Cookies from "js-cookie";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getGivecashToWebData } from "@utils/queryString";
import { useEffect, useRef } from "react";
import { useAutoSwitchAccount } from "@components/Login/useAccountSelectionModal";
import { Box } from "@mui/material";
import { useGetAccounts } from "../services/api/onboarding/user";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { logoutAction } from "@redux/slices/auth/auth";

const PrivateRoutes = () => {
  const user = Cookies.get("user");
  const parsedUser = user && JSON.parse(user);
  const dispatch = useAppDispatch();

  const {
    toPath,
    fromMobile,
    redirectRoute,
    merchantId,
    enterpriseId,
    acquirerId,
  } = getGivecashToWebData();
  const toDoRedirectionRef = useRef<boolean>(
    Boolean(merchantId && enterpriseId && acquirerId),
  );
  const newAccToSetRef = useRef<any>(null);

  const { data: userAccounts, isLoading: accountsLoading } = useGetAccounts({
    enabled: toDoRedirectionRef.current,
  });
  useEffect(() => {
    if (parsedUser && fromMobile && userAccounts && !accountsLoading) {
      let acquirer, enterprise, merchant;
      for (const acc of userAccounts?.data || []) {
        if (acc.id === acquirerId) {
          acquirer = acc;
        }
        if (acc.id === enterpriseId) {
          enterprise = acc;
        }
        if (acc.id === merchantId) {
          merchant = acc;
        }
      }
      newAccToSetRef.current = acquirer || enterprise || merchant;

      if (toDoRedirectionRef.current) {
        // if selected account has access to givecash merchant
        if (newAccToSetRef.current?.id === parsedUser?.id) {
          toDoRedirectionRef.current = false;
        }

        /**
         * if selected account doesnt have access to givecash merchant
         * but if there is account in the list that has access to givecash mercht
         * then switch to that new account
         */
        if (
          newAccToSetRef.current &&
          newAccToSetRef.current?.id != parsedUser?.id
        ) {
          switchAccount({
            userParam: newAccToSetRef.current,
            onSuccess: () => {
              toDoRedirectionRef.current = false;
            },
          });
        }

        // if no account in list has access to merchant from givecash, logout
        if (!newAccToSetRef.current) {
          toDoRedirectionRef.current = false;
          dispatch(logoutAction());
          navigate(`/login?next=${redirectRoute}`);
        }
      }
    }
  }, [userAccounts, accountsLoading]);

  const navigate = useNavigate();
  const { switchAccount, isSwitching } = useAutoSwitchAccount();

  if (user) {
    if (fromMobile) {
      if (toDoRedirectionRef.current || isSwitching || accountsLoading)
        return (
          <Box>
            <LoadingSpinner />
          </Box>
        );
      return <Navigate to={toPath} replace />;
    } else {
      toDoRedirectionRef.current = false;
      return <RoleAuth role={parsedUser?.role} />;
    }
  } else {
    return (
      <Navigate
        to={`/login${fromMobile ? `?next=${redirectRoute}` : ""}`}
        replace
      />
    );
  }
};

const RoleAuth = ({ role }: { role: string }) => {
  const { pathname, search } = useLocation();
  const { name: isMasquerade, origin } = useAppSelector(selectMasqueradeMode);

  if (pathname.startsWith("/acquirer") && role !== "acquirer") {
    return <Navigate to={`${role}${search}`} replace />;
  } else if (
    pathname.startsWith("/enterprise") &&
    role !== "enterprise" &&
    !isMasquerade
  ) {
    return <Navigate to={`${origin || role}${search}`} replace />;
  } else if (
    pathname.startsWith("/merchant") &&
    role !== "merchant" &&
    !isMasquerade
  ) {
    return <Navigate to={`${origin || role}${search}`} replace />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoutes;
