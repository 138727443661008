import NiceModal from "@ebay/nice-modal-react";
import GearSixIcon from "@assets/rebrandIcons/GearSixIcon";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { Box } from "@mui/material";
import { Button } from "@common/Button";
import { useEnterpriseConfiguration } from "../hooks/useEnterpriseConfiguration";
import { Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { Text } from "@common/Text";
import { palette } from "@palette";
import {
  EnterpriseConfigurationGroups,
  EnterpriseConfigurationList,
} from "../constants/EnterpriseConfiguration.constants";
import EnterpriseConfigurationSwitch from "../components/EnterpriseConfigurationSwitch";

interface Props {
  enterpriseId: number;
  data: { [key: string]: boolean };
  onClose?: (data: { [key: string]: boolean }, id?: number) => void;
}

const EnterpriseConfigurationModal = NiceModal.create(
  ({ enterpriseId, data, onClose }: Props) => {
    const { open, handleCancel, methods, onSubmit, disabled } =
      useEnterpriseConfiguration({ onClose, enterpriseId, data });

    return (
      <EditMerchantBaseModal
        testId="provider-configuration-modal"
        title="Configuration"
        icon={<GearSixIcon />}
        open={open}
        handleCancel={handleCancel}
        mobileDrawerSx={{
          zIndex: 1201,
        }}
        actions={
          <Box margin="0 0 0 auto">
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="enterprise-configuration-form"
              disabled={disabled}
            >
              Save changes
            </Button>
          </Box>
        }
        sx={modalStyles}
      >
        <FormProvider {...methods}>
          <Stack
            component="form"
            id="enterprise-configuration-form"
            onSubmit={methods.handleSubmit(onSubmit)}
            spacing="20px"
            data-testid="provider-configuration-form"
          >
            {EnterpriseConfigurationGroups.map((group) => (
              <Stack spacing={2} key={group}>
                <Text color={palette.neutral[80]}>{group}</Text>
                {EnterpriseConfigurationList.filter(
                  (item) => item.group === group,
                ).map((item) => (
                  <EnterpriseConfigurationSwitch key={item.name} item={item} />
                ))}
              </Stack>
            ))}
          </Stack>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

const modalStyles = {
  "& .MuiPaper-root": {
    width: "640px !important",
    maxWidth: "640px !important",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

export default EnterpriseConfigurationModal;
