import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { RHFInput } from "@common/Input";
import { Button } from "@common/Button";
import { useEffect } from "react";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { MedalIcon } from "@assets/icons/RebrandedIcons";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast/ShowToast";

type FormInputs = {
  email: string;
};

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Enter account holder email"),
});

type Props = {
  data: {
    email: string;
  };
  ownerAccId: number;
  id: number;
};

const UpdatePrimaryAcccountHolderEmail = NiceModal.create(
  ({ data, ownerAccId, id }: Props) => {
    const modal = useModal();
    const open = modal.visible;
    const { isMobileView } = useCustomTheme();
    const queryClient = useQueryClient();
    const { isDesktopView } = useCustomTheme();

    const { mutate, isLoading } = useMutation((data: any) => {
      return customInstance({
        url: `/users/${ownerAccId}`,
        method: "PATCH",
        data,
      });
    });
    const methods = useForm<FormInputs>({
      resolver: yupResolver(schema),
      defaultValues: {
        email: data?.email,
      },
    });

    const {
      reset,
      formState: { isDirty },
    } = methods;

    useEffect(() => {
      reset({ email: data?.email });
    }, [data]);

    const handleCancel = () => {
      reset();
      modal.remove();
    };

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      mutate(
        { email: data.email },
        {
          onError: (err: any) => {
            const errMessage = err.response?.data?.input[0]?.message;
            console.log("ERROR", errMessage);
            if (errMessage == 'The provided "email" value is already taken.') {
              showMessage(
                "Error",
                "This email address is already in use, please try again with a another email",
                isDesktopView,
              );
              return;
            }
          },
          onSuccess: () => {
            // TODO: show correct message once it's provided
            showMessage("Info", "Validation email has been sent");
            queryClient.refetchQueries(["get-merchant-preview", id]);
            modal.hide();
          },
        },
      );
    };

    return (
      <EditMerchantBaseModal
        title="Primary Account Holder"
        description="An invitation email will be sent to complete the merchant
        creation process. They will have full control over the payment
        account, including the ability to add users, manage funds, and
        update settings."
        open={open}
        handleCancel={handleCancel}
        icon={<MedalIcon />}
        PaperProps={{
          style: {
            top: "25%",
          },
          sx: {
            "& .MuiDialogTitle-root + .MuiDialogContent-root": {
              paddingTop: "0 !important",
              paddingBottom: "0 !important",
            },
          },
        }}
        actionsSx={{
          padding: "24px",
        }}
        actions={
          <>
            <Button
              size="medium"
              background="tertiary"
              onClick={handleCancel}
              sx={{
                ...(isMobileView && {
                  width: "50%",
                }),
              }}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="edit-primary-account-holder"
              disabled={!isDirty || isLoading}
              sx={{
                marginLeft: "0px !important",
                ...(isMobileView && {
                  width: "50%",
                }),
              }}
            >
              Send
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-primary-account-holder"
            onSubmit={methods.handleSubmit(onSubmit)}
            sx={{ marginTop: 2 }}
          >
            <RHFInput
              name="email"
              label="Email"
              placeholder="Email"
              fullWidth
            />
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

export default UpdatePrimaryAcccountHolderEmail;
