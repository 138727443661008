import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";

export type SectionTitleProps = {
  Icon: React.ReactNode;
  title: string;
};

const SectionTitle = ({ Icon, title }: SectionTitleProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      padding="4px 0"
      flexGrow={1}
      alignItems="center"
    >
      {Icon}
      <Text color={palette.black[100]} lineHeight="16.8px">
        {title}
      </Text>
    </Stack>
  );
};

export default SectionTitle;
