import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { EDIT_BUSINESS_OWNER_MODAL } from "modals/modal_names";
import { useGetCurrentMerchantId } from "@hooks/common";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { Button } from "@common/Button";
import { SxProps } from "@mui/material";
import { TBusinessOwner } from "@common/BusinessOwners/types";
import { EditOwnerModalProps } from "@components/Merchants/MerchantPreview/modals/EditBusinessOwnerModal";
import React from "react";
import { isFunction } from "lodash";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { getIDFile } from "@components/Merchants/MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";

interface IUseBusinessOwner {
  stakeSum?: number;
  isApprovedLE?: boolean;
  showTag?: boolean;
  legalEntityID?: number;
  isController: boolean;
  hasMaxOwners: boolean;
  isSoleProprietorship?: boolean;
  merchantId?: number;
  onCloseModal?: (data?: any, id?: string) => void;
  isBPLinked?: boolean;
  businessType?: string;
  onCloseParentModal?: () => void;
  documents?: TMerchantDocument[];
  isMerchant?: boolean;
}

interface HandlerOptions {
  modalProps?: Partial<EditOwnerModalProps>;
}

interface AddOwnerButtonProps extends HandlerOptions {
  sx?: SxProps;
  disabled?: boolean;
}

const useBusinessOwner = ({
  stakeSum,
  isApprovedLE = false,
  showTag = false,
  legalEntityID,
  isController,
  hasMaxOwners,
  isSoleProprietorship,
  merchantId: customMerchant,
  onCloseModal,
  isBPLinked,
  businessType,
  onCloseParentModal,
  documents,
  isMerchant,
}: IUseBusinessOwner) => {
  const { isDesktopView } = useCustomTheme();
  const { merchantId } = useGetCurrentMerchantId();

  const addOwnerHandler = (options?: HandlerOptions) => {
    NiceModal.show(EDIT_BUSINESS_OWNER_MODAL, {
      ...options?.modalProps,
      totalOwnerships: stakeSum,
      merchantId: customMerchant || merchantId,
      legalEntityID,
      isIndividualSoleProprietorship: isSoleProprietorship,
      businessType,
      isMerchant,
    });
  };

  const isAddOwnerAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
    ),
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isAddOwnerDisabled =
    !isAddOwnerAllowed || !isController || hasMaxOwners || !legalEntityID;

  const editOwnerHandler = (
    event: React.MouseEvent<HTMLElement>,
    owner: TBusinessOwner,
    options?: HandlerOptions,
  ) => {
    event.stopPropagation();

    const data = {
      ...owner,
      address: {
        ...owner.businessAddress,
        state: owner.businessAddress?.province,
        line1: owner.businessAddress?.street,
      },
      isBusinessAddress: owner?.useBusinessAddress,
    };
    NiceModal.show(EDIT_BUSINESS_OWNER_MODAL, {
      data,
      ...options?.modalProps,
      isMerchant,
      legalEntityID,
      merchantId: customMerchant || merchantId,
      totalOwnerships: stakeSum,
      isApprovedLE,
      doHandleDelete: Boolean(legalEntityID),
      showTag,
      isBPLinked,
      permissionResource: composePermission(
        isMerchant ? RESOURCE_BASE.MERCHANT : RESOURCE_BASE.ENTERPRISE,
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.PRINCIPAL,
      ),
      isIndividualSoleProprietorship: isSoleProprietorship,
      businessType,
      onCloseParentModal: onCloseParentModal,
      idFile: owner.files?.allFiles[0] || getIDFile(owner.id, documents),
      ...(!legalEntityID && {
        onClose: (e: any) => {
          const updatedData = { ...data, ...e };
          isFunction(onCloseModal) &&
            onCloseModal(updatedData, updatedData?.id);
        },
        handleDelete: () => {
          isFunction(onCloseModal) && onCloseModal(null, data?.id?.toString());
        },
      }),
    });
  };

  return {
    editOwnerHandler,
    addOwnerHandler,
    isAddOwnerDisabled,
    AddOwnerButton: ({ sx, disabled, modalProps }: AddOwnerButtonProps) => (
      <Button
        size="medium"
        background={"secondary"}
        fullWidth={!isDesktopView}
        onClick={() => addOwnerHandler({ modalProps })}
        disabled={isAddOwnerDisabled || disabled}
        tooltipProps={{
          show: !isAddOwnerAllowed,
          message: CREATE_DENY_MESSAGE,
        }}
        data-testid="add-bo-button"
        sx={sx}
      >
        Add Business Owner
      </Button>
    ),
  };
};

export default useBusinessOwner;
