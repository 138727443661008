import Box from "@mui/material/Box";
// components
import { styled } from "@mui/material";
import { palette } from "@palette";
import { useGetStats } from "@services/api/products/transactions";
import { CircularProgress } from "@mui/material";
import { Text } from "@common/Text";
import { useMemo } from "react";
import Figure from "@common/Figure";
// utils

const mainContent = {
  gap: "16px",
  display: "flex",
  padding: "16px",
  flexDirection: "row",
  borderRadius: "8px",
  backgroundColor: palette.neutral[5],
  width: "100%",
  justifyContent: "flex-start",
  flexWrap: "wrap",
};

const ViewTransactionBanner = ({ merchId }: any) => {
  const { data, isLoading } = useGetStats(merchId);

  const parsedData = useMemo(() => {
    if (data?.data) {
      return data.data.map((it: any) => ({
        activeMerchants: {
          title: "Active merchants",
          value: it.activeMerchants,
          isAmount: false,
          percent: false,
        },
        approvedMerchants: {
          title: "Approved merchants",
          value: it.approvedMerchants,
          isAmount: false,
          percent: false,
        },
        balance: {
          title: "Processed",
          value: it.balance / 100,
          isAmount: true,
          percent: false,
        },
        pendingMerchants: {
          title: "Pending merchants",
          value: it.pendingMerchants,
          isAmount: false,
          percent: false,
        },
        totalMerchants: {
          title: "Total merchants",
          value: it.totalMerchants,
          isAmount: false,
          percent: false,
        },
        transactionsCount: {
          title: "Transactions",
          value: it.transactionsCount,
          isAmount: false,
          percent: false,
        },
        totalRaised: {
          title: "TotalRaised",
          value: it.totalRaised / 100,
          isAmount: true,
          percent: false,
        },
      }));
    }

    return [];
  }, [data]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return parsedData.map((it: any, idx: number) => (
    <Box sx={mainContent} key={idx}>
      {Object.values(it).map((x: any) => (
        <StyledRoot key={x.title}>
          <Figure value={x.value} percent={x.percent} isAmount={x.isAmount} />
          <TitleText variant="h3">
            {x.title}
            {x.isAmount && " (USD)"}
            {x.percent && " (%)"}
          </TitleText>
        </StyledRoot>
      ))}
    </Box>
  ));
};

const TitleText = styled(Text)(() => ({
  fontSize: 14,
  fontWeight: 300,
  lineHeight: "14.4px",
  color: palette.neutral[70],
  flexGrow: 0,
  whiteSpace: "nowrap",
}));

const StyledRoot = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  overflow: "hidden",
  width: "20%",
}));

export default ViewTransactionBanner;
