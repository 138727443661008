import { MoreIconHorizontal } from "@assets/rebrandIcons";
import {
  IStyleIconButtonProps,
  StyledIconButton,
} from "@common/IconButton/IconButton";
import { palette } from "@palette";

export const DotsMenuButton = ({
  onClick,
}: Omit<IStyleIconButtonProps, "children">) => {
  return (
    <StyledIconButton
      data-testid="more-icon-horizontal"
      sx={{
        height: "32px !important",
        width: "32px !important",
        padding: "0 !important",
        "&:hover path": {
          fill: `${palette.neutral.white} !important`,
        },
      }}
      onClick={onClick}
    >
      <MoreIconHorizontal fill={palette.neutral.white} />
    </StyledIconButton>
  );
};
