import { Text } from "@common/Text";
import { Stack, StackProps } from "@mui/material";
import { palette } from "@palette";
import { CaretDown } from "@phosphor-icons/react";

export type SponsorApprovalStatus =
  | "ready_for_review"
  | "pre-approval"
  | "auto-approval"
  | "pending"
  | "approved"
  | "declined";

type Props = {
  tag: SponsorApprovalStatus;
  isDropdown?: boolean;
};

const SponsorApprovalTag = ({
  tag,
  isDropdown,
  ...props
}: Props & StackProps) => {
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      direction="row"
      {...props}
      sx={{
        padding: "4px 12px",
        borderRadius: "100px",
        background: SponsorTagMap[tag].background,
        cursor: "default",
        ...(isDropdown && {
          cursor: "pointer",
        }),
        ...props?.sx,
      }}
    >
      <Text fontSize={12} color={SponsorTagMap[tag].color}>
        {SponsorTagMap[tag].text}
      </Text>
      {isDropdown && (
        <CaretDown height={16} width={16} fill={SponsorTagMap[tag].color} />
      )}
    </Stack>
  );
};

const SponsorTagMap = {
  ready_for_review: {
    text: "Ready for Review",
    color: palette.filled.orange,
    background: palette.tag.warning.bg,
  },
  "pre-approval": {
    text: "Pre-Approval",
    color: palette.neutral[80],
    background: palette.neutral[10],
  },
  "auto-approval": {
    text: "Auto-Approval",
    color: palette.tag.success.text,
    background: palette.tag.success.bg,
  },
  approved: {
    text: "Approved",
    color: palette.tag.success.text,
    background: palette.tag.success.bg,
  },
  declined: {
    text: "Declined",
    color: palette.tag.error.text,
    background: palette.tag.error.bg,
  },
  pending: {
    text: "Pending",
    color: palette.neutral[80],
    background: palette.neutral[10],
  },
};

export default SponsorApprovalTag;