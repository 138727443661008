import { Button } from "@common/Button";
import CopyButton from "@common/CopyButton";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Fragment, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  apiKey: string;
  isSandbox: boolean;
};

const ApiKeySuccessModal = NiceModal.create(({ apiKey, isSandbox }: Props) => {
  const modal = useModal();
  const { isMobileView } = useCustomTheme();
  const baseUrl = isSandbox
    ? process.env.REACT_APP_SANDBOX_API_URL + "/api"
    : process.env.REACT_APP_DEVELOPER_API_URL;
  const portalUrl = process.env.REACT_APP_SANDBOX_PORTAL_URL;

  const methods = useForm();

  const handleCloseModal = () => {
    modal.hide();
  };

  const nodeList = [
    {
      node: (
        <StyledInput>
          <RHFInput
            name="apiKey"
            data-testid={`success-api-key-${apiKey}`}
            label="Key"
            placeholder="Key"
            fullWidth
            endIcon={<CopyButton label="Key copied!"testId={`success-copy-${apiKey}`} text={apiKey} />}
            disabled
          />
        </StyledInput>
      ),
    },
    {
      node: (
        <StyledInput>
          <RHFInput
            name="baseUrl"
            label="Base URL"
            data-testid={`success-api-key-baseUrl`}
            placeholder="Base URL"
            endIcon={<CopyButton text={baseUrl!} />}
            disabled
            fullWidth
          />
        </StyledInput>
      ),
    },
    {
      node: (
        <StyledInput>
          <RHFInput
            name="portalUrl"
            label="Portal URL"
            placeholder="Portal URL"
            data-testid={`success-api-key-portalUrl`}
            endIcon={<CopyButton text={portalUrl!} />}
            disabled
            fullWidth
          />
        </StyledInput>
      ),
      hidden: !isSandbox,
    },
    {
      node: (
        <Stack direction="row" spacing={0.5} alignItems="center" ml={1}>
          <Text
            fontSize={12}
            fontWeight="book"
            color={palette.neutral[70]}
            data-testid={`success-api-key-documentation`}
          >
            For further guidance on how to get started, check out our
          </Text>
          <DocumentationLink
            // href={baseUrl}
            rel="noopener noreferrer"
            // target="_blank"
          >
            {isSandbox ? "Sandbox Documentation" : "Documentation"}
          </DocumentationLink>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    if (modal.visible) {
      methods.reset({ apiKey, baseUrl, portalUrl });
    }
  }, [modal.visible]);

  return (
    <EditMerchantBaseModal
      title="API Key Created Successfully"
      description="Your API key has been successfully generated"
      open={modal.visible}
      handleCancel={handleCloseModal}
      mobileDrawerSx={{
        ...(isMobileView && {
          "& .MuiDialogTitle-root": {
            padding: "0 0 16px 0",
          },
          "& .MuiDialogContent-root": {
            padding: 0,
          },
          "& .MuiDialogActions-root": {
            flexDirection: "column-reverse",
            gap: "8px",
          },
          "& .MuiPaper-root": {
            top: "50%",
          },
        }),
      }}
      actions={
        <Button
          size="large"
          background="primary"
          onClick={handleCloseModal}
          sx={{ padding: "12px 24px", fontSize: 18 }}
        >
          Close
        </Button>
      }
      sx={modalStyle}
    >
      <FormProvider {...methods}>
        <Stack spacing={1} component="form">
          {nodeList.map(({ node, hidden }, index) => (
            <Fragment key={index}>
              {!hidden && (
                <FadeUpWrapper delay={70 * (index + 1)}>{node}</FadeUpWrapper>
              )}
            </Fragment>
          ))}
        </Stack>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

const StyledInput = styled(Box)(() => ({
  "& div": {
    background: "inherit",
  },
  "& .MuiInputBase-root": {
    background: `${palette.neutral[10]} !important`,
  },
  "& input": {
    color: `${palette.neutral[90]} !important`,
    WebkitTextFillColor: `${palette.neutral[90]} !important`,
  },
}));

const DocumentationLink = styled("a")(() => ({
  color: palette.accent[3],
  fontSize: 12,
  fontWeight: 350,
}));

const modalStyle = {
  "& .MuiDialogContent-root": {
    padding: "8px 16px",
  },
  "& .MuiDialogTitle-root + .MuiDialogContent-root": {
    paddingTop: "8px !important",
  },
  "& .MuiPaper-root": {
    width: "576px !important",
    maxWidth: "576px !important",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

export default ApiKeySuccessModal;
