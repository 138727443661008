interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const HeadlineIcon = ({
  width = 33,
  height = 32,
  fill = "#F4F3F7",
  stroke = "#727179",
}: IconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill={fill} />
      <path
        d="M9.02051 23.3428V7.0625H12.4626V13.7797H19.4501V7.0625H22.8842V23.3428H19.4501V16.6176H12.4626V23.3428H9.02051Z"
        fill={stroke}
      />
    </svg>
  );
};

export default HeadlineIcon;
