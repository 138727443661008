import { RootState } from "@redux/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { convertDateFromAmericanToApiFormat } from "@utils/helpers";
import { fromUnixTime } from "date-fns";
import moment from "moment";

const addOneDay = (date: Date) => moment(date).add(1, "days").toDate();

const DEFAULT_START_DATE = fromUnixTime(1577880770); // 2020-01-01
const DEFAULT_END_DATE = addOneDay(new Date());

type TFilterType = "months" | "days" | "custom";

type TFilter = {
  type?: TFilterType;
  amount?: number;
  startDate?: Date;
  endDate?: Date;
};

interface AnalyticsState {
  queries: Record<
    string,
    {
      dateFilter: TFilter;
      queryFilter: {
        dateFilter: string;
      };
    }
  >;
}

const initialDateFilter = {
  type: undefined,
  amount: 0,
  startDate: DEFAULT_START_DATE,
  endDate: DEFAULT_END_DATE,
};

const initialState: AnalyticsState = {
  queries: {},
};

const formatDateForQuery = (date: Date) => {
  return moment(date).format("MM-DD-YYYY");
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    applyDateFilter: (
      state: AnalyticsState,
      action: PayloadAction<{ queryKey: string; filter: TFilter }>,
    ) => {
      const { queryKey, filter } = action.payload;
      const { type, amount, startDate, endDate } = filter;

      if (!type) {
        state.queries[queryKey] = {
          dateFilter: initialDateFilter,
          queryFilter: {
            dateFilter: "",
          },
        };
        return;
      }

      const currentDate = new Date();
      const formattedStartDate =
        type === "custom"
          ? startDate
            ? startDate
            : DEFAULT_START_DATE
          : moment(currentDate).subtract(amount, "days").toDate();
      const formattedEndDate =
        type === "custom" && endDate ? endDate : currentDate;

      const filterQuery = `start_date=${convertDateFromAmericanToApiFormat(
        formatDateForQuery(formattedStartDate),
      )}&end_date=${convertDateFromAmericanToApiFormat(
        formatDateForQuery(addOneDay(formattedEndDate)),
      )}`;

      state.queries[queryKey] = {
        dateFilter: {
          type,
          amount,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        queryFilter: {
          dateFilter: filterQuery,
        },
      };
    },
  },
});

export const { applyDateFilter } = analyticsSlice.actions;

export const selectDateFilter = (state: RootState, queryKey: string) =>
  state.analytics.queries[queryKey]?.dateFilter || undefined;
export const selectFilterQuery = (state: RootState, queryKey: string) =>
  state.analytics.queries[queryKey]?.queryFilter || "";

export default analyticsSlice.reducer;
