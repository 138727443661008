import React, { memo, useRef } from "react";
import { styled } from "@mui/styles";
import { palette } from "@palette";

type CustomDonationDigitInputProps = {
  min?: number;
  max?: number;
  amount: number | string;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: () => void
};

const CustomDonationDigitInput = ({
  amount,
  handleChange,
  handleKeyDown,
  handleBlur
}: CustomDonationDigitInputProps) => {
  const ref = useRef(null);
  return (
    <StyledInput
      autoFocus
      ref={ref}
      min={1}
      value={`${amount}`}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={() => handleBlur()}
      size={`${amount}`.length + 2}
      style={{
        padding: 0,
      }}
    />
  );
};

const StyledInput = styled("input")({
  color: palette.neutral[80],
  border: "none",
  outline: "none",
  width: "fit-content",
  fontSize: "32px",
  fontWeight: 350,
  fontFamily: "Give Whyte,sans-serif",
  margin: 0,
  padding: 0,
  background: "inherit",
});

export default memo(CustomDonationDigitInput);
