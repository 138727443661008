// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import {
  Box,
  DialogActions,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// components
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { BaseModal } from "@common/Modal";
// assets
import { CloseIcon } from "@assets/icons";
import { useSendReceipt } from "@hooks/common/useSendReceipt";
// components
import { palette } from "@palette";
import Popup from "@common/Popup/Popup";
import { Card } from "@components/ManageMoney/TransactionTable/transactions.types";

type IProps = {
  id: string;
  transactionID: string;
  emails: string[];
  closeAction?: () => void;
  customerID?: number;
  sourceAccount: {
    fullName: string;
    card: Card | undefined;
    email?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    phoneNumber?: string | undefined;
    name?: string | undefined;
    slug?: string | undefined;
    type?: string | undefined;
  };
};

const ProcessingSendReceipt = NiceModal.create(
  ({
    id,
    closeAction,
    transactionID,
    emails,
    sourceAccount,
    customerID,
  }: IProps) => {
    const modal = useModal();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const { isLoading, handleSubmit } = useSendReceipt({
      transactionID,
      customerID,
    });

    const handleSend = () => {
      handleSubmit({ recipients: [...emails] }, modal);
    };

    const handleClose = () => {
      modal.hide();
      if (closeAction) closeAction();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title="Send Receipt"
        onCancel={() => modal.hide()}
        onSubmit={handleSend}
        actionLabel="Send Receipt"
      >
        Please confirm you want to resend the email receipt to{" "}
        {sourceAccount.fullName} at {sourceAccount.email}
      </Popup>
    );
  },
);

const StyledModal = styled(BaseModal)(({ theme }) => ({
  [theme.breakpoints.down(800)]: {
    "& .MuiDialog-paper": {
      margin: "0 16px",
    },
  },

  [theme.breakpoints.down(601)]: {
    top: "50px",
    maxHeight: "90%",
    background: "#F9FCFF",
    "& .MuiDialog-paper": {
      borderRadius: "8px",
      height: "auto !important",
    },
  },
}));

const textStyle = {
  fontSize: 16,
  color: palette.neutral[600],
  "@media (max-width: 600px)": {
    textAlign: "center",
  },
};

const StyledButton = {
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  position: "absolute",
  top: 7,
  right: 6,
};

const StyledTitle = {
  fontSize: 20,
  fontWeight: "600",
  color: palette.neutral[800],
  my: 1,
  "@media (max-width: 600px)": {
    textAlign: "center",
  },
};

export default ProcessingSendReceipt;
