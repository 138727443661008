import { DeveloperIcon } from "@assets/icons";
import {
  CogSettingsIcon,
  JobsIcon,
  MerchantsIcon,
  TransfersIcon,
} from "@assets/rebrandIcons";
import { ENTERPRISE_PATHS } from "@routes/paths";

type Props = {
  isTransfersPageEnabled: boolean;
};

const getEnterpriseMenu = ({ isTransfersPageEnabled }: Props) => ({
  menu: [
    {
      name: "Processing",
      path: "/provider/processing",
      icon: <JobsIcon />,
      index: 1,
    },
    ...(isTransfersPageEnabled
      ? [
          {
            name: "Transfers",
            path: "/provider/transfers",
            icon: <TransfersIcon />,
            index: 2,
          },
        ]
      : []),
    {
      name: "Merchants",
      path: "/provider/merchants",
      icon: <MerchantsIcon />,
      index: 3,
    },
  ],
  footer: [
    ...(process.env.REACT_APP_ENVIRONMENT !== "production"
      ? [
          {
            name: "Developer API",
            path: `/provider/${ENTERPRISE_PATHS.DEVELOPER_API}`,
            icon: <DeveloperIcon />,
          },
        ]
      : []),
    {
      name: "Settings",
      path: "/provider/settings",
      icon: <CogSettingsIcon />,
    },
  ],
});

export { getEnterpriseMenu };
