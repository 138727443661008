import { TruncateText } from "@common/Text";
import { showMessage } from "@common/Toast";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { File, ArrowsCounterClockwise } from "@phosphor-icons/react";
import { getFilesFromEvent } from "react-dropzone-uploader";

const UploadedItem = ({
  fileName,
  actionHandler,
}: {
  fileName: string;
  actionHandler: (file: File) => void;
}) => {
  return (
    <ItemContainer>
      <File size={24} color={palette.gray[300]} />
      <TruncateText
        lineClamp={1}
        flexGrow={1}
        lineHeight="16.8px"
        color={palette.black[100]}
      >
        {fileName}
      </TruncateText>
      <label htmlFor="hidden-file-upload-input">
        <input
          style={{ display: "none" }}
          data-testid="hidden-file-upload-input"
          id="hidden-file-upload-input"
          type="file"
          accept="text/csv"
          multiple={false}
          onChange={(e) => {
            const chosenFiles = getFilesFromEvent(e);
            const validatedFiles = chosenFiles
              .filter(isFile)
              .filter((file) => file.name.length < 254);
            if (validatedFiles.length < chosenFiles.length)
              showMessage("Error", "File name too long");
            if (validatedFiles.length > 0) {
              actionHandler(validatedFiles[0]);
            }
            e.target.value = "";
          }}
        />
        <StyledButton>
          <ArrowsCounterClockwise size={16} color={palette.gray[300]} />
          Replace File
        </StyledButton>
      </label>
    </ItemContainer>
  );
};

const isFile = (file: File | DataTransferItem): file is File =>
  file.type.match("csv")?.index !== -1;

const StyledButton = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  cursor: "pointer",
  fontSize: "14px",
  lineHeight: "16.8px",
  fontWeight: 400,
  padding: "4px 8px",
  color: palette.gray[300],
}));

const ItemContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  padding: "16px",
  gap: "8px",
  borderRadius: "12px",
  boxShadow: "0px 3px 15px 0px #02020226",
  background: palette.common.white,
}));

export default UploadedItem;
