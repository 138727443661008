import { SvgIconProps } from "@mui/material";

const CheckedEmptyCircleIcon = ({
  height = 25,
  width = 25,
  fill = "#13A75A",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      data-testid="ready-for-verification-challenge"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.097 9.21937C17.1668 9.28903 17.2221 9.37175 17.2598 9.46279C17.2976 9.55384 17.317 9.65144 17.317 9.75C17.317 9.84856 17.2976 9.94616 17.2598 10.0372C17.2221 10.1283 17.1668 10.211 17.097 10.2806L11.847 15.5306C11.7774 15.6004 11.6947 15.6557 11.6036 15.6934C11.5126 15.7312 11.415 15.7506 11.3164 15.7506C11.2178 15.7506 11.1203 15.7312 11.0292 15.6934C10.9382 15.6557 10.8554 15.6004 10.7858 15.5306L8.53579 13.2806C8.39505 13.1399 8.31599 12.949 8.31599 12.75C8.31599 12.551 8.39505 12.3601 8.53579 12.2194C8.67652 12.0786 8.86739 11.9996 9.06641 11.9996C9.26543 11.9996 9.45631 12.0786 9.59704 12.2194L11.3164 13.9397L16.0358 9.21937C16.1054 9.14964 16.1882 9.09432 16.2792 9.05658C16.3703 9.01884 16.4678 8.99941 16.5664 8.99941C16.665 8.99941 16.7626 9.01884 16.8536 9.05658C16.9447 9.09432 17.0274 9.14964 17.097 9.21937ZM22.5664 12C22.5664 13.9284 21.9946 15.8134 20.9232 17.4168C19.8519 19.0202 18.3292 20.2699 16.5476 21.0078C14.766 21.7458 12.8056 21.9389 10.9143 21.5627C9.02297 21.1865 7.28568 20.2579 5.92212 18.8943C4.55856 17.5307 3.62996 15.7934 3.25375 13.9021C2.87755 12.0108 3.07063 10.0504 3.80859 8.26884C4.54654 6.48726 5.79622 4.96451 7.3996 3.89317C9.00298 2.82183 10.888 2.25 12.8164 2.25C15.4014 2.25273 17.8798 3.28084 19.7077 5.10872C21.5356 6.93661 22.5637 9.41498 22.5664 12ZM21.0664 12C21.0664 10.3683 20.5826 8.77325 19.676 7.41655C18.7695 6.05984 17.481 5.00242 15.9735 4.37799C14.4661 3.75357 12.8073 3.59019 11.2069 3.90852C9.60657 4.22685 8.13656 5.01259 6.98278 6.16637C5.829 7.32015 5.04326 8.79016 4.72493 10.3905C4.4066 11.9908 4.56998 13.6496 5.1944 15.1571C5.81883 16.6646 6.87625 17.9531 8.23296 18.8596C9.58966 19.7661 11.1847 20.25 12.8164 20.25C15.0037 20.2475 17.1007 19.3775 18.6473 17.8309C20.1939 16.2843 21.0639 14.1873 21.0664 12Z"
        fill="#13A75A"
      />
    </svg>
  );
};

export default CheckedEmptyCircleIcon;
