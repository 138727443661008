import { memo } from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
//assets
import Popup from "@common/Popup";
import { CustomTheme } from "@theme/v2/palette.interface";
import { RoundedCloseIcon } from "@assets/icons/RebrandedIcons";
import useCloseBuilder from "@pages/NewAdvancedBuilder/hooks/useCloseBuilder";

type Props = {
  theme: CustomTheme;
  handleRefetch: () => void;
};

const AdvancedBuilderErrorModal = NiceModal.create(
  ({ theme, handleRefetch }: Props) => {
    const modal = useModal();
    const { handleClose } = useCloseBuilder();

    const handleRefreshPage = () => {
      handleRefetch && handleRefetch();
      modal.hide();
    };

    const handleExit = () => {
      modal.hide();
      handleClose();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        customContainerStyle={{
          backgroundColor: theme.palette.primitive?.neutral[5],
          border: "none",
        }}
        title="Something went wrong!"
        titleSx={{
          fontSize: "24px",
          color: theme.palette.primitive?.neutral[100],
          lineHeight: "28px",
        }}
        textProps={{
          color: theme.palette.primitive?.neutral[70],
          fontSize: "14px",
          fontWeight: "regular",
          lineHeight: "20px",
          marginTop: "8px",
        }}
        cancelLabel="Exit Advanced Builder"
        cancelButtonProps={{
          size: "medium",
        }}
        cancelButtonSx={{
          color: theme.palette.primitive?.neutral[100],
        }}
        onCancel={handleExit}
        actionLabel="Refresh Page"
        submitButtonProps={{
          size: "medium",
        }}
        submitButtonSx={{
          color: theme.palette.primitive?.neutral[10],
          bgcolor: theme.palette.primitive?.neutral[100],
        }}
        onSubmit={handleRefreshPage}
        HeaderIcon={<RoundedCloseIcon />}
      >
        {`We're having trouble displaying the advanced builder, please refresh the page. If the problem still persists, we suggest you to exit advanced builder and try again later.`}
      </Popup>
    );
  },
);

export default memo(AdvancedBuilderErrorModal);
