// mui
import Stack from "@mui/material/Stack";
import {
  // FilterWithSwitch,
  FilterWithDate,
  FilterWithAmount,
  ClearFiltersButton,
} from "@common/Filter";
// redux
import { useAppSelector } from "@redux/hooks";
import {
  selectFilters,
  addDateFilter,
  disableDateFilter,
  addAmountFilter,
  disableAmountFilter,
  addRangeFilter,
  disableRangeFilter,
  clearFilters,
} from "@redux/slices/fundraisers";
import { listCreatedFilter } from "@components/Fundraisers/FundraisersList/FundraisersListFilters";
import { selectQueryFilters } from "@redux/slices/fundraisers";

const FundraisersListFilters = () => {
  const filters = useAppSelector(selectFilters);

  return (
    <Stack direction="row" py={1} flexWrap="wrap">
      <FilterWithDate
        title="created"
        options={listCreatedFilter}
        filters={filters}
        apply={addDateFilter}
        disable={disableDateFilter}
      />
      {/* <FilterWithAmount
        title="amount"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithRangeSlider
        title="conversion"
        filters={filters}
        apply={addRangeFilter}
        disable={disableRangeFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="purchases"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="visitors"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      /> */}

      <ClearFiltersButton
        clear={clearFilters}
        selector={selectQueryFilters}
      />
    </Stack>
  );
};

export default FundraisersListFilters;
