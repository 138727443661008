import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useDebouncedCallback } from "use-debounce";

export const useCachedList = <T>(queryKey: string) => {
  const client = useQueryClient();
  const { isMobileView } = useCustomTheme();

  const [allData, setAllData] = useState<T[]>([]);

  const updateData = () => {
    const caches = client.getQueryCache().findAll(queryKey);
    const newData = caches.reduce((acc, v) => {
      if (v?.state?.data) {
        const iter = (v.state.data as any)?.data ?? [];
        return [...acc, ...(iter as [])];
      }
      return acc;
    }, []);
    setAllData(newData);
  }

  // const debouncedUpdateData = useDebouncedCallback(() => {
  //   updateData();
  // }, 500);

  useEffect(() => {
    if (isMobileView) {
      const unsubscribe = client.getQueryCache().subscribe(updateData);

      return () => {
        unsubscribe();
      };
    }
  }, [client, isMobileView]);

  const invalidateCache = () => {
    client.removeQueries([queryKey]);
  };

  return { allData, invalidateCache };
};
