import { palette } from "@palette";
import moment from "moment";

const generalFont = {
  size: 12,
  weight: "normal",
  family: "Give Whyte, sans-serif",
  lineHeight: "14.4px",
};

const generateOptions = (isCustomer?: boolean) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        padding: {
          left: 12,
          right: 12,
          top: 9,
          bottom: 9,
        },
        titleColor(ctx: any, options: any) {
          return palette.gray[300];
        },
        backgroundColor: palette.black[300],
        titleFont: generalFont,
        bodyFont: generalFont,
        position: "nearest" as const,
        xAlign: "left" as const,
        yAlign: "center" as const,
        cornerRadius: 6,
        caretSize: 0,
        callbacks: {
          title(tooltipItem: any) {
            const label = tooltipItem[0]?.label;
            const title = isCustomer
              ? moment(new Date(label)).format("MMMM YYYY")
              : moment(new Date(label)).format("MMM DD");
            return title;
          },
          labelTextColor(tooltipItem: any) {
            return palette.liftedWhite[300];
          },
        },
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
        },
      },
      x: {
        type: "time" as const,
        position: "bottom" as const,
        ticks: {
          display: true,
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
  };
};

export default generateOptions;
