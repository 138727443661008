import { useModal } from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { EnterpriseConfigurationList } from "../constants/EnterpriseConfiguration.constants";
import { useMutation } from "react-query";
import { customInstance } from "@services/api";
import { isEmpty } from "lodash";
import { useGetEnterpriseConfigurationById } from "./useGetEnterpriseConfigurationById";

type Props = {
  data: { [key: string]: boolean };
  enterpriseId: number;
  onClose?: (data: { [key: string]: boolean }, id?: number) => void;
};

export const useEnterpriseConfiguration = ({
  onClose,
  enterpriseId,
  data,
}: Props) => {
  const modal = useModal();
  const open = modal.visible;

  const {
    data: enterpriseConfiguration,
    isLoading,
    refetch,
  } = useGetEnterpriseConfigurationById(enterpriseId);

  const updateEnterpriseConfigurationMutation = useMutation((data: any) => {
    return customInstance({
      url: `/enterprises/${enterpriseId}/configuration`,
      method: "PUT",
      data,
    });
  });

  const handleCancel = () => {
    modal.hide();
  };

  const methods = useForm({});

  const { reset } = methods;

  const onSubmit: SubmitHandler<{
    [key: string]: boolean;
  }> = async (data) => {
    if (onClose && !enterpriseId) {
      onClose(data);
      handleCancel();
      return;
    }

    if (!enterpriseConfiguration?.data) return;

    const updatedConfiguration = enterpriseConfiguration?.data?.map(
      (item: TEnterpriseConfiguration) => ({
        id: item.id,
        name: item.name,
        isActive: data[item.name],
      }),
    );

    updateEnterpriseConfigurationMutation.mutate(
      {
        configurations: updatedConfiguration,
      },
      {
        onSuccess: () => {
          refetch();
          handleCancel();
        },
      },
    );
  };

  useEffect(() => {
    const list: { [key: string]: boolean } = {};
    if (enterpriseId && enterpriseConfiguration?.data) {
      enterpriseConfiguration?.data?.forEach(
        (item: TEnterpriseConfiguration) => {
          list[item.name] = item.isActive;
        },
      );
    } else {
      EnterpriseConfigurationList.forEach((item) => {
        list[item.name] = item.isActive;
      });
    }

    reset(data && !isEmpty(data) ? data : list);
  }, [open, isLoading, enterpriseId]);

  return {
    open,
    handleCancel,
    methods,
    onSubmit,
    disabled: updateEnterpriseConfigurationMutation.isLoading,
  };
};

export type TEnterpriseConfiguration = {
  id?: number;
  name: string;
  displayName: string;
  description: string;
  group: string;
  isActive: boolean;
  configurationID?: number;
};
