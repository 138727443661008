import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";

import Popup from "@common/Popup";
import { palette } from "@palette";

type Props = {
  handleSubmit: () => void;
};
const DiscardChangeModal = NiceModal.create(
  ({ handleSubmit }: Props): JSX.Element => {
    const modal = useModal();

    const submit = () => {
      handleSubmit();
      modal.hide();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={"Cancel creation?"}
        cancelLabel="Go back"
        onCancel={() => modal.hide()}
        onSubmit={submit}
        actionLabel="Yes, cancel"
        titleProps={{
          fontSize: "18px",
          variant: "h1",
        }}
        titleSx={{
          fontSize: "18px !important",
        }}
        textProps={{
          color: palette.neutral[70],
          fontSize: 14,
          fontWeight: "book",
        }}
        submitButtonProps={{
          size: "medium",
        }}
        cancelButtonProps={{
          size: "medium",
        }}
      >
        Closing the side panel will result in losing all your current progress
      </Popup>
    );
  },
);

export default DiscardChangeModal;
