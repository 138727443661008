import React, { memo, useEffect } from "react";
import { Box } from "@mui/material";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { Input } from "@common/Input";

export type CustomAmountInputProps = {
  id?: string;
  min?: number;
  max?: number;
  bounded?: boolean;
  onBlur?: () => void;
  label?: string | React.ReactNode;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string | React.ReactNode;
  currency?: "usd";
  initialValue?: string;
  onChange?: (value: string) => void;
  isDirty?: boolean;
  value?: string;
};

const getValueFromString = (value: string) =>
  parseFloat(value.replaceAll(",", ""));

const CustomAmountInput = ({
  min = 1,
  max = 25000,
  onBlur,
  bounded = true,
  helperText,
  initialValue,
  onChange,
  isDirty,
  value,
  ...props
}: CustomAmountInputProps) => {
  useEffect(() => {
    if (initialValue) {
      if (onChange) onChange(initialValue);
    }
  }, []);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trueValue = getValueFromString(e.target.value);
    if (trueValue < min) {
      if (onChange) onChange(min + ".00");
    } else if (trueValue > max && bounded) {
      if (onChange) onChange(max + ".00");
    } else {
      if (onChange) onChange(e.target.value);
    }
  };

  const formatAmount = () => {
    if (onBlur) onBlur();
    if (value && !value?.includes(".")) if (onChange) onChange(value + ".00");
  };

  const isAllowed = (values: NumberFormatValues) => {
    if (!bounded) return true;
    const { value } = values;
    return +value >= 0 && +value <= 25000;
  };
  return (
    <Box sx={{ width: "100%" }}>
      <NumberFormat
        {...props}
        customInput={Input}
        value={value}
        thousandSeparator
        onBlur={formatAmount}
        allowNegative={false}
        decimalScale={2}
        isAllowed={isAllowed}
        fullWidth
        maxLength={2}
        {...(typeof helperText === "string" && { helperText: helperText })}
        onChange={handleAmountChange}
      />
      {typeof helperText !== "string" && helperText}
    </Box>
  );
};

export default memo(CustomAmountInput);
