import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Box, Popover, Stack, SxProps, TextField } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CloseButton } from "./atoms";

type Props = {
  anchorEl: HTMLDivElement;
  open: boolean;
  setAnchorEl: Dispatch<SetStateAction<HTMLDivElement | null>>;
  customStyle?: SxProps;
  handleUpdateFilter?: () => void;
  setCustomValues: (amount: string) => void;
  limits?: { min?: number; max?: number };
};

const CustomPopover = ({
  anchorEl,
  open,
  customStyle,
  setAnchorEl,
  handleUpdateFilter,
  setCustomValues,
  limits,
}: Props) => {
  const WIDTH = 460;
  const HEIGHT = 174;

  const [minInputValue, setMinInputValue] = useState<string>(
    limits?.min?.toString() || "",
  );
  const [maxInputValue, setMaxInputValue] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const getDisabled = () => {
    const limited = limits?.min ? parseInt(minInputValue) < limits?.min : false;

    if (maxInputValue && !minInputValue) return false;
    if (!maxInputValue && minInputValue && !limited) return false;

    if (
      !maxInputValue ||
      !minInputValue ||
      parseInt(maxInputValue) < parseInt(minInputValue) ||
      limited
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsButtonDisabled(getDisabled());
  }, [maxInputValue, minInputValue]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = () => {
    if (handleUpdateFilter) handleUpdateFilter();
    let query = `amount:>=${parseInt(minInputValue || "0") * 100}`;
    if (maxInputValue) query += `%3Bamount:<=${parseInt(maxInputValue) * 100}`;
    setCustomValues(query);
    handleClose();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      disableRestoreFocus
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: `${WIDTH}px`,
          height: `${HEIGHT}px`,
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          padding: "12px",
          overflow: "hidden",
          backgroundColor: "#FAFAFA",
          boxShadow: "0px 8px 25px 0px #00000026",
          borderRadius: "8px",
        },
        ...customStyle,
      }}
    >
      <form onSubmit={handleSubmit} style={{ height: "100%" }}>
        <Stack
          sx={{ height: "100%" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <CloseButton closeButtonSize={24} onClick={handleClose}>
            <X />
          </CloseButton>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <TextField
              InputProps={{
                type: "number",
                placeholder: "Min. Number",
                endAdornment: <Text color="#C1C1C1">USD</Text>,
                sx: { width: "214px", border: "solid 1px #e1e1e1" },
              }}
              inputProps={{
                style: { marginTop: 0 },
                onChange: (e) => {
                  setMinInputValue(e.currentTarget.value);
                },
              }}
              helperText={limits?.min ? `Min Amount: ${limits?.min}.00` : ""}
            />
            <TextField
              InputProps={{
                type: "number",
                placeholder: "Max. Number",
                endAdornment: <Text color="#C1C1C1">USD</Text>,
                sx: { width: "214px", border: "solid 1px #e1e1e1" },
              }}
              inputProps={{
                style: { marginTop: 0 },
                onChange: (e) => {
                  setMaxInputValue(e.currentTarget.value);
                },
              }}
            />
          </Stack>
          <Box width="100%">
            <Button
              type="submit"
              fullWidth={false}
              disabled={isButtonDisabled}
              sx={{
                padding: "12px 24px",
                margin: "0 auto",
              }}
            >
              <Text>Apply</Text>
            </Button>
          </Box>
        </Stack>
      </form>
    </Popover>
  );
};

export default CustomPopover;
