import { MinusIcon, PlusIcon } from "@assets/icons";
import { Text } from "@common/Text";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  styled,
  useTheme,
} from "@mui/material";
import { palette } from "@palette";
import { useState } from "react";
import { useQuery } from "react-query";
import { TChallengeTypeName } from "../ChallengeTypeChip";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { getUnderwritingTopicID } from "./hooks/useOpenChallengeConversation";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";

const ICON_SIZE = 20;

type Props = {
  challengeTypeName: TChallengeTypeName;
  message: React.ReactNode;
  threadId: number;
  merchantID: number;
};

const ChallengeBody = ({
  message,
  merchantID,
  challengeTypeName,
  threadId,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  const { data: topicData, isLoading } = useQuery(
    [`get-message-body-${threadId}`, threadId, merchantID, challengeTypeName],
    async () => {
      const { data } = await getGlobalTopic({ topicName: "underwriting" });
      const underwritingTopicID = getUnderwritingTopicID(
        challengeTypeName,
        data,
      );
      if (!underwritingTopicID) {
        return { message: "" };
      }

      const { data: _threads } = await customInstance({
        url: `/merchants/${merchantID}/topics/${underwritingTopicID}/threads?filter=id:${threadId}`,
        method: "GET",
      });

      if (_threads) {
        return { message: _threads[0]?.messages[0]?.body || "" };
      }
      return { message: "" };
    },
    {
      enabled:
        expanded &&
        !!threadId &&
        !!merchantID &&
        challengeTypeName !== "customer_due_diligence",
      onError(err: any) {
        showMessage(
          "Error",
          err?.response?.data?.message || "Unable to fetch message body",
        );
      },
    },
  );

  const ExpandIcon = expanded ? (
    <MinusIcon
      width={ICON_SIZE}
      height={ICON_SIZE}
      strokeWidth={1}
      stroke={theme.palette.neutral[90]}
    />
  ) : (
    <PlusIcon
      width={ICON_SIZE}
      height={ICON_SIZE}
      fill={theme.palette.neutral[80]}
    />
  );
  return (
    <StyledAccordion
      onChange={() => setExpanded((curr) => !curr)}
      disableGutters
    >
      <StyledAccordionSummary expandIcon={ExpandIcon}>
        <Text color={theme.palette.neutral[80]}>
          How to complete this challenge?
        </Text>
      </StyledAccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Skeleton
            sx={{
              height: "70px",
            }}
          />
        ) : (
          <Text
            sx={{
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
            color={theme.palette.neutral[80]}
          >
            {topicData?.message || message}
          </Text>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  borderRadius: `${theme.spacing(1)} !important`,
  backgroundColor: palette.background.newWhite,
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderRadius: `${theme.spacing(1)} !important`,
  "&:hover": {
    backgroundColor: theme.palette.neutral[10],
  },
}));

export default ChallengeBody;
