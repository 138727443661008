import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const MoreIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill="none"
      height={`${height}`}
      viewBox="0 0 21 20"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 6.25C11.5237 6.25 12.0833 5.69036 12.0833 5C12.0833 4.30964 11.5237 3.75 10.8333 3.75C10.143 3.75 9.58331 4.30964 9.58331 5C9.58331 5.69036 10.143 6.25 10.8333 6.25Z"
        fill="#A9AFBD"
      ></path>
      <path
        d="M10.8333 11.25C11.5237 11.25 12.0833 10.6904 12.0833 10C12.0833 9.30964 11.5237 8.75 10.8333 8.75C10.143 8.75 9.58331 9.30964 9.58331 10C9.58331 10.6904 10.143 11.25 10.8333 11.25Z"
        fill="#A9AFBD"
      ></path>
      <path
        d="M10.8333 15.8333C11.5237 15.8333 12.0833 15.2737 12.0833 14.5833C12.0833 13.893 11.5237 13.3333 10.8333 13.3333C10.143 13.3333 9.58331 13.893 9.58331 14.5833C9.58331 15.2737 10.143 15.8333 10.8333 15.8333Z"
        fill="#A9AFBD"
      ></path>
    </svg>
  );
};
export default MoreIcon;
