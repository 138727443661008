import { QKEY_ANALYTICS_RECURRING_CUSTOMERS } from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useAppSelector } from "@redux/hooks";
import { selectFilterQuery } from "@redux/slices/analytics";
import { getCustomersAnalytics } from "@services/api/analytics/customers";
import { getTransactionChart } from "@services/api/analytics/transactions";
import { getSettledPromise } from "@utils/index";
import { useQuery } from "react-query";

export const useGetRecurringCustomers = (isModalVisible: boolean) => {
  const { merchantId } = useGetCurrentMerchantId();

  const customersFilterQuery = useAppSelector((state) =>
    selectFilterQuery(state, QKEY_ANALYTICS_RECURRING_CUSTOMERS),
  );

  const { data } = useQuery(
    ["get-recurring-customers-chart", customersFilterQuery, merchantId],
    async () => {
      const customersFilter = customersFilterQuery.dateFilter;
      const [customersChart, customers] = await Promise.allSettled(
        [
          {
            filter: customersFilter,
            chartType: "recurring_customers",
          },
          {
            filter: customersFilter,
          },
        ].map((c, idx) => {
          return idx === 0 ? getTransactionChart(c) : getCustomersAnalytics(c);
        }),
      );
      const recurringsChart = getSettledPromise(customersChart)?.data || [];

      return {
        chart: customersFilter
          ? recurringsChart?.slice(0, recurringsChart.length - 1)
          : recurringsChart,
        customers: getSettledPromise(customers),
      };
    },
    { refetchOnWindowFocus: false, enabled: !!isModalVisible },
  );

  return { data };
};
