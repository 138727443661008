import { NoConnection } from "@assets/emptyStatesIcons";
import { Button } from "@common/Button";
import { BaseModal } from "@common/Modal";
import { Text } from "@common/Text";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { getUser } from "@services/api/onboarding/user";
import { useState } from "react";

const ConnectionErrorModal = NiceModal.create(() => {
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const modal = useModal();

  const handleClick = async () => {
    setIsConnecting(true);
    try {
      await getUser();
      setIsConnecting(false);
      modal.hide();
    } catch (error) {
      const t = setTimeout(() => {
        setIsConnecting(false);
        clearTimeout(t);
      }, 700);
    }
  };

  return (
    <BaseModal
      {...muiDialogV5(modal)}
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: palette.neutral.white,
          borderRadius: "16px",
          padding: "16px",
          userSelect: "none",
          height: "max-content",
          width: "80%",

          "@media (min-width: 600px)": {
            maxWidth: "300px",
          },
          "@media (max-width: 600px)": {
            top: "40%",
          },
        },
        "& .MuiDialogContent-root": {
          padding: 0,
        },
        "& .MuiDialogActions-root": {
          display: "none",
        },
      }}
      onClose={() => undefined}
    >
      <Stack direction="column" gap={6} alignItems="stretch">
        <Stack direction="column" gap={2} alignItems="center">
          <NoConnection width={74} height={74} />
          <Stack width="100%" direction="column" gap={1}>
            <Text
              color={palette.black[300]}
              variant="h6"
              lineHeight="21.6px"
              letterSpacing="-0.18px"
              align="center"
            >
              No connection
            </Text>
            <Text
              lineHeight="16.8px"
              fontWeight="book"
              color={palette.black[100]}
              align="center"
            >
              Please check your internet connection
            </Text>
          </Stack>
        </Stack>
        <Button
          background="primary"
          size="medium"
          onClick={handleClick}
          disabled={isConnecting}
          sx={{
            fontSize: "18px",
            textAlign: "center",
            "&:disabled": {
              "& span": {
                opacity: 0.9,
              },
            },
          }}
        >
          Reconnect{" "}
          {isConnecting && <Loader />}
        </Button>
      </Stack>
    </BaseModal>
  );
});

const Loader = () => {
  return (
      <LoadingSpinner
        sx={{ height: "18px", maxWidth: "20px" }}
        spinnerProps={{ size: "14px", thickness: 4 }}
        color={palette.gray[300]}
      />
  )
}

export default ConnectionErrorModal;
