import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useState } from "react";
import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import { Box } from "@mui/material";
import { typeList } from "@components/CreateCampaign/Steps/CreateCampaignType";
import PaymentTypeCard from "@components/CreateCampaign/components/PaymentTypeCard";
import { Grid } from "@mui/material";
import {
  CREATE_EVENT_MODAL,
  CREATE_FUNDRAISER_MODAL,
  CREATE_INVOICE_MODAL,
  CREATE_MEMBERSHIP_MODAL,
  CREATE_PAYMENT_FORM_MODAL,
  CREATE_SWEEPSTAKES_MODAL,
} from "modals/modal_names";
import { Text } from "@common/Text";
import { palette } from "@palette";

const CreatePaymentFormModal = NiceModal.create(() => {
  const [campaign, setCampaign] = useState<string>("");
  const handleSelectType = (name: string) => {
    setCampaign(name);
  };

  const modal = useModal();

  const onClose = () => {
    modal.hide();
  };

  const fn = (name: string) => {
    onClose();

    switch (name) {
      case "Event":
        return NiceModal.show(CREATE_EVENT_MODAL);
      case "Fundraiser":
        return NiceModal.show(CREATE_FUNDRAISER_MODAL);
      case "Membership":
        return NiceModal.show(CREATE_MEMBERSHIP_MODAL);
      case "Sweepstake":
        return NiceModal.show(CREATE_SWEEPSTAKES_MODAL);
      case "Invoice":
        return NiceModal.show(CREATE_INVOICE_MODAL);
      case "Standard":
        return NiceModal.show(CREATE_PAYMENT_FORM_MODAL);
      default:
        return NiceModal.show(CREATE_PAYMENT_FORM_MODAL);
    }
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      isPreBuildFormBuilder
      HeaderProps={{
        title: "Create Payment Form",
      }}
      primaryAction={{
        onClick: () => fn(campaign),
        disabled: false,
        label: "Next",
        form: "create-payment-form",
      }}
    >
      <Box component="form" id="create-payment-form">
        <Text
          sx={{
            color: palette.black[200],
            fontSize: "32px",
            fontWeight: "350",
            lineHeight: "120%",
            textAlign: "left",
            mb: 4,
          }}
        >
          What type of Payment Form are you creating?
        </Text>
        <Grid container spacing={1.2}>
          {typeList.map(
            (type, index) =>
              type.name !== "Product" && (
                <PaymentTypeCard
                  key={
                    type.name === "Standard"
                      ? "Standard Payment Form"
                      : type.name
                  }
                  handleSelectType={handleSelectType}
                  selected={campaign ? campaign === type.name : index === 0}
                  type={type}
                />
              ),
          )}
        </Grid>
      </Box>
    </ModalDrawer>
  );
});

export default CreatePaymentFormModal;
