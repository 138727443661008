import { BellIcon } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Box, BoxProps, styled } from "@mui/material";
import { palette } from "@palette";
import useCheckNewNotifications from "./useCheckNewNotifications";
import NotificationsCenter from "../NotificationsCenter/NotificationsCenter";
import { useState } from "react";

const NotificationsCenterButton = () => {
  const { hasNewAlerts, hasMandatoryAlerts } = useCheckNewNotifications();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const closeNotificationsCenter = () => setAnchorEl(null);

  const openNotificationsCenter = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (anchorEl) {
      closeNotificationsCenter();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <CustomButton onClick={openNotificationsCenter}>
        <>
          <BellIcon width={24} height={24} stroke={palette.black[100]} />
          <HiddenComponent hidden={!hasNewAlerts}>
            <CustomAlertDot hasMandatoryAlerts={hasMandatoryAlerts} />
          </HiddenComponent>
        </>
      </CustomButton>
      <NotificationsCenter
        anchorEl={anchorEl}
        onClose={closeNotificationsCenter}
      />
    </>
  );
};

export default NotificationsCenterButton;

const CustomAlertDot = styled(
  (props: BoxProps) => (
    <Box data-testid="alert-dot" component="span" {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "hasMandatoryAlerts",
  },
)<{ hasMandatoryAlerts: boolean }>(({ hasMandatoryAlerts }) => ({
  position: "absolute",
  width: "8px",
  height: "8px",
  borderRadius: "100px",
  left: "14px",
  top: 0,
  background: hasMandatoryAlerts ? palette.accent[4] : palette.accent[3],
}));

const CustomButton = styled(StyledIconButton)(() => ({
  padding: "0 !important",
  height: "32px !important",
  width: "32px !important",

  "& > span:first-of-type": {
    position: "relative",
  },

  "&:hover path": {
    fill: palette.liftedWhite[200],
  },
}));
