import {
  SxProps,
  TableCell,
  Theme,
  TableRow,
  TableHead,
  styled,
} from "@mui/material";
import { RefObject } from "react";

type Props = {
  columns: Array<{
    name: string;
    id: number;
    key: string;
    sx?: SxProps<Theme> | undefined;
  }>;
  conversationsBarRef: RefObject<HTMLTableSectionElement>;
};

const ConversationsTableHead = ({ columns, conversationsBarRef }: Props) => {
  return (
    <TableHead
      ref={conversationsBarRef}
      sx={{
        position: "relative",
        backgroundColor: "inherit",
        th: {
          backgroundColor: "inherit",
        },
      }}
    >
      <TableRow>
        {columns.map((column) => (
          <CustomTableCell key={column.id} sx={column.sx}>
            {column.name}
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ConversationsTableHead;

const CustomTableCell = styled(TableCell)(() => ({
  textAlign: "center",
  color: "#8F8F8F",
  fontWeight: 350,
  padding: "0px 8px 8px",
}));
