import { NiceModalHandler } from "@ebay/nice-modal-react";
import { Drawer, DrawerProps, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CSSProperties, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  onCloseDrawer: any;
  children: React.ReactNode;
  modal: NiceModalHandler<Record<string, unknown>>;
  width?: number | string | null;
  paperStyle?: CSSProperties | undefined;
}

export const SidePanel = ({
  modal,
  onCloseDrawer,
  children,
  width,
  paperStyle,
  ...props
}: Props & DrawerProps) => {
  const mountRef = useRef(false);
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (mountRef.current === false) {
      mountRef.current = true;
    } else {
      onCloseDrawer();
    }
  }, [location.pathname]);

  return (
    <Drawer
      data-testid="side-panel"
      onClose={onCloseDrawer}
      PaperProps={{
        style: {
          width: width ? width : isDesktop ? "620px" : "100%",
          boxShadow: isDesktop
            ? "-4px 0px 20px 0px rgba(0, 0, 0, 0.05)"
            : "none",
          background: "#FBFBFB",
          ...paperStyle,
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(53, 53, 53, 0.10)",
          },
        },
      }}
      anchor="right"
      open={modal.visible}
      {...props}
    >
      <Grid
        container
        flexDirection={"column"}
        style={{
          overflow: "hidden",
          height: "100%",
          flexWrap: "nowrap",
        }}
      >
        {children}
      </Grid>
    </Drawer>
  );
};
