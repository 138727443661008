import { TextField } from "@mui/material";
import { useState } from "react";
import { ModalActions } from "@common/Modal/ModalFactory/atoms";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import TriggerHistoryCard, {
  TriggerHistoryData,
} from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/TriggerHistoryCard";
import useUpdateRiskTrigger from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useUpdateRiskTrigger";

interface IComponentProps {
  onCancel: () => void;
  factorID: number;
  profileId: number;
  merchantId: number;
  isEnabled: boolean;
  triggerTitle: string;
  triggerDescription: string;
  triggerHistory: TriggerHistoryData;
}

const TriggerModalContent = ({
  onCancel,
  isEnabled,
  triggerHistory,
  factorID,
  profileId,
  merchantId,
  triggerTitle,
  triggerDescription,
}: IComponentProps) => {
  const [value, setValue] = useState("");
  const { handleUpdateTrigger, isLoading } = useUpdateRiskTrigger({
    factorID,
    profileId,
    merchantId,
    triggerTitle,
    triggerDescription,
  });

  const handleSaveChanges = () => {
    handleUpdateTrigger({
      reason: value,
      isEnabled: !isEnabled,
      onSuccessCb: onCancel,
    });
  };

  return (
    <>
      <FadeUpWrapper delay={200}>
        {triggerHistory.reason && (
          <TriggerHistoryCard
            {...triggerHistory}
            isCurrentlyEnabled={isEnabled}
          />
        )}
        <TextField
          label="Reason"
          fullWidth
          rows={6}
          multiline
          value={value}
          onChange={(e) => setValue(e.target.value)}
          InputProps={{ sx: { borderWidth: "1px !important" } }}
        />
      </FadeUpWrapper>

      <ModalActions
        primaryAction={{
          label: "Save Changes",
          sx: { fontSize: "18px" },
          disabled: !value || isLoading,
          isLoading: isLoading,
          onClick: handleSaveChanges,
        }}
        secondaryAction={{
          label: "Cancel",
          sx: { fontSize: "18px" },
          onClick: onCancel,
        }}
        animationDelay={300}
      />
    </>
  );
};

export default TriggerModalContent;
