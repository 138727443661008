import React from "react";
import { Portal, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Button } from "@common/Button";
import LoadingButton from "@common/Button/LoadingButton";

type Props = {
  id?: string | number;
  form: string;
  actionsPortal?: HTMLElement | null;
  direction?: "row" | "column";
  isSubmitDisabled?: boolean;
  isNotFullWidth?: boolean;
  isLoading?: boolean;
  isPrimaryActionDisabled?: boolean;
  isSecondaryActionDisabled?: boolean;
  isShowDiscard?: boolean;
  primaryBtnLabel?: React.ReactNode;
  discardBtnLabel?: React.ReactNode;
  handleDelete?: () => void;
  handleDiscard?: () => void;
};
function CampaignModalActions({
  id,
  form,
  actionsPortal = null,
  isSubmitDisabled,
  isNotFullWidth,
  isLoading,
  isPrimaryActionDisabled,
  isSecondaryActionDisabled,
  isShowDiscard,
  direction,
  primaryBtnLabel: initialPrimaryBtnLabel,
  discardBtnLabel,
  handleDelete,
  handleDiscard,
}: Props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const hasOtherButtons = Boolean(id) || isShowDiscard;
  const primaryBtnLabel = id ? "Save" : initialPrimaryBtnLabel || "Next step";

  const content = (
    <Stack
      gap={2}
      sx={{
        width: "100%",
        flexDirection: direction || "row",
        justifyContent: id ? "space-between" : "flex-end",

        "@media (max-width: 600px)": {
          flexDirection: direction || "column",
          alignItems: "stretch",
          width: "100%",
          gap: "4px",
        },
      }}
    >
      {hasOtherButtons && (
        <Stack
          flexGrow={1}
          direction={direction || isDesktop ? "row" : "column"}
          justifyContent="space-between"
        >
          {handleDelete ? (
            <Button
              onClick={handleDelete}
              background="tertiary"
              fullWidth={!isDesktop}
              size="medium"
              sx={{
                width: `${isNotFullWidth ? "max-content" : "auto"} !important`,
              }}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          {handleDiscard && (
            <Button
              background="secondary"
              size="medium"
              disabled={
                (isSecondaryActionDisabled ?? isPrimaryActionDisabled) ||
                isLoading
              }
              onClick={handleDiscard}
              sx={{
                width: `${isNotFullWidth ? "max-content" : "auto"} !important`,
              }}
              fullWidth={!isDesktop}
            >
              {discardBtnLabel ? discardBtnLabel : "Discard changes"}
            </Button>
          )}
        </Stack>
      )}

      <LoadingButton
        background="primary"
        type="submit"
        size="medium"
        form={form}
        disabled={isPrimaryActionDisabled || isLoading}
        fullWidth={!isDesktop}
        isLoading={isLoading}
      >
        {primaryBtnLabel}
      </LoadingButton>
    </Stack>
  );

  if (actionsPortal)
    return <Portal container={actionsPortal}>{content}</Portal>;
  return content;
}

export default CampaignModalActions;
