export const palette = {
  primary: {
    main: "#131212",
    background: "#F7F7F7",
    50: "#F9F5FF",
    100: "#EADDFD",
    200: "#E9AEFF",
    300: "#D198E8",
    400: "#BB83D1",
    500: "#A56EBB",
    600: "#915CA7",
    700: "#7A4790",
    800: "#65337B",
    900: "#4D1D63",
    A100: "string",
    A200: "string",
    A400: "string",
    A700: "string",
    A750: "#E0E9F6",
    A800: "#00000026",
    A850: "#767674",
  },
  secondary: {
    main: "#C9A364",
    50: "#FFEDAA",
    100: "#FFD795",
    200: "#E9C080",
    300: "#D1AB6B",
    400: "#BA9557",
    500: "#A48144",
    600: "#8D6D30",
    700: "#78591D",
    800: "#624709",
    900: "#4E3500",
    A100: "string",
    A200: "string",
    A400: "string",
    A700: "string",
  },
  neutral: {
    white: "#FFFFFF",
    white2: "#EFEFEF",
    black: "#201F21",
    main: "#734089",
    5: "#F8F8F6",
    10: "#ECECE9",
    20: "#E1E1DE",
    30: "#D1D1D0",
    40: "#C1C1C1",
    50: "#F4F3F7",
    60: "#A8A8A8",
    70: "#8F8F8F",
    80: "#575353",
    90: "#403D3D",
    100: "#292727",
    110: "#DDDCE5",
    120: "#D6D6D6",
    150: "#F6FBFF",
    200: "#C7C5CE",
    250: "#A9AFBD",
    300: "#B1AFB8",
    400: "#9C9AA3",
    500: "#87858D",
    600: "#727179",
    700: "#5F5D65",
    750: "#575353",
    800: "#4C4A52",
    850: "#403D3D",
    870: "#292727",
    900: "#201F21",
    950: "#131212",
    A100: "string",
    A200: "string",
    A400: "string",
    A700: "string",
  },
  gray: {
    main: "#C1C1C1",
    icon: "#343330",
    40: "#EBEBEB",
    50: "#dfdfdc",
    100: "#B8B8B8",
    200: "#A8A8A8",
    300: "#8F8F8F",
  },
  black: {
    main: "#131212",
    100: "#575353",
    200: "#403D3D",
    300: "#292727",
  },
  filled: {
    100: "#E8F7EF",
    green: "#088745",
    orange: "#FF8124",
    success: "#13A75A",
    red: "#D80D0D",
    grey: "#8F8F8F",
    lightGrey: "#DDDDDD",
    blue: "#003182",
  },
  givebox: {
    600: "#326EC5",
    200: "#9EC8FF",
  },
  liftedWhite: {
    main: "#F8F8F6",
    100: "#ECECE9",
    200: "#E1E1DE",
    300: "#D1D1D0",
  },
  charts: {
    purple: "#CD87E1",
    orange: "#FFC373",
    lightblue: "#7CC0F4",
    pink: "#FF8BA6",
  },
  accent: {
    1: "#80EAD7",
    2: "#45C4E1",
    3: "#6D9CF8",
    4: "#FF5924",
  },
  error: {
    main: "#B23C22",
    light: "#FEE4E2", // or background
    hover: "#BB1111",
    500: "#FF5924",
    600: "#D0481C",
  },
  warning: {
    main: "#F1AA3F",
    light: "#FFE8DA", // or background
    text: "#C3641D",
  },
  success: {
    main: "#1B9D4F",
    light: "#D6FCDA", // or background
  },
  info: {
    main: "#003182",
    light: "#E6EAF2", // or background
    hard: "#003182",
    soft: "#D3EAFF",
    dark: "#153263",
  },
  background: {
    dirtyWhite: "#F6F8FA",
    dimmedWhite: "#FBFBFB",
    whiteBlue: "#F1F5F9",
    glass: "rgba(241, 245, 249, 0.75)",
    newWhite: "#F7F7F7",
    bgWhite: "#FAFAFA",
  },
  gradient: {
    1: "linear-gradient(45deg, #21D4FD 14.64%, #914FFF 85.36%)",
    2: "linear-gradient(45deg, #914FFF 14.64%, #B98EFF 85.36%)",
    3: "linear-gradient(270deg, #50CAFF 0%, #0478FF 100%)",
    4: "linear-gradient(264.37deg, #80EAD7 6.98%, #45C4E1 93.86%)",
    5: "linear-gradient(45deg, #4BC0C8 25%, #C779D0 50%, #FEAC5E 75%)",
    6: "linear-gradient(270deg, #C9A364 -1.99%, #734089 100%)",
    7: "#AC64CB",
    8: "linear-gradient(45deg, #80EAD7 14.64%, #45C4E1 50%, #AA60CE 85.36%)",
    9: "linear-gradient(236.21deg, #FFAA6C 4.86%, #E04D79 103.96%)",
    10: "linear-gradient(224deg, #72ECF0 0%, #528DDF 100%)",
  },
  common: {
    black: "#000",
    white: "#fff",
  },
  tag: {
    success: {
      text: "#108548",
      bg: "#E6F3EC",
      hover: "#096F3A",
      light_hover: "#108548",
    },
    error: {
      text: "#941A1A",
      bg: "#F5E0DF",
      hover: "#BB1111",
    },
    warning: {
      bg: "#FFF2E9",
      light_hover: "#FFEDE0",
      hover: "#FF8124",
    },
    blue: {
      text: "#003182",
      bg: "#E6EAF2",
    },
  },
};
