import { TruncateText } from "@common/Text";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import {
  getType,
  textColor,
} from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { palette } from "@palette";
import React from "react";

export const TypeColumn = ({ row }: { row: TransactionTableRow }) => (
  <TruncateText
    fontWeight="regular"
    color={textColor(row, palette.neutral[800])}
    lineClamp={1}
    maxWidth="2OOpx"
  >
    {getType(row)}
  </TruncateText>
);
