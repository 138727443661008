import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "@components/ProfilePage/form.components";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { ArrowLeft } from "@assets/icons";
import { usePersonalInformationProvider } from "../Provider/PersonalInformationProvider";
import SelfieComponent from "@sections/VerifyAccountHolder_v2/components/SelfieComponent";
import { personalInfoEnum } from "../types";
import { useState, useEffect } from "react";
import { ConfirmationScreen } from "@sections/VerifyAccountHolder_v2/ConfirmationScreen";
import SelfiePreview from "./SelfiePreview";
import LoadingComponent from "./LoadingComponent";

function TakeSelfieTab() {
  const [completed, setCompleted] = useState(false);
  const [selfieUrl, setSelfieUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const {
    handleBack,
    enterpriseData,
    handleUpdateStatusValue,
    homepageReset,
    files,
  } = usePersonalInformationProvider();

  const updateProgressBar = handleUpdateStatusValue(
    personalInfoEnum.TAKE_A_SELFIE,
  );
  const selfieUploaded = enterpriseData?.owner?.selfieUploaded;

  useEffect(() => {
    if (selfieUploaded) {
      updateProgressBar(100);
    } else {
      updateProgressBar(0);
    }
  }, [selfieUploaded]);

  const dbSelfieURL = files?.data?.reduce(
    (latest: any, current: any) => {
      if (
        current.attTypeName === "account_owner_selfie" &&
        current.updatedAt > latest.updatedAt
      ) {
        return current;
      }
      return latest;
    },
    { updatedAt: 0 },
  )?.fileURL;

  return (
    <Box flexGrow={1} display="flex" height="100%">
      <ProfileSetupFormContainer>
        {isUploading ? (
          <LoadingComponent />
        ) : (
          <>
            {selfieUrl ? (
              <SelfiePreview
                selfieUrl={selfieUrl}
                onClick={() => {
                  setSelfieUrl(null);
                  setCompleted(false);
                }}
              />
            ) : (
              <>
                {completed ? (
                  <ConfirmationScreen />
                ) : (
                  <Stack direction="column" gap={4}>
                    <ProfileSetupFormTitle
                      title="Take a selfie"
                      description="A selfie is needed to confirm your ID"
                    />
                    <SelfieComponent
                      isSelfieStepActive
                      isLoggedInPAH
                      merchant={enterpriseData}
                      updateProgressBar={updateProgressBar}
                      completed={completed}
                      setCompleted={setCompleted}
                      setSelfieUrl={setSelfieUrl}
                      setIsUploading={setIsUploading}
                    />
                  </Stack>
                )}
              </>
            )}
          </>
        )}

        <ProfileSetupFormActions
          secondaryAction={{
            onClick: handleBack,
            children: (
              <Box
                component="span"
                color={palette.black[100]}
                display="inline-flex"
                alignItems="center"
                gap="4px"
              >
                <ArrowLeft height={20} width={20} fill={palette.black[100]} />{" "}
                Back
              </Box>
            ),
          }}
          primaryAction={{
            disabled: !enterpriseData?.owner?.selfieUploaded || isUploading,
            children: completed || selfieUrl ? "Done" : "Next",
            type: "button",
            onClick: completed
              ? homepageReset
              : selfieUrl
              ? () => {
                  setSelfieUrl(null);
                  setCompleted(true);
                }
              : () => setSelfieUrl(dbSelfieURL),
          }}
        />
      </ProfileSetupFormContainer>
    </Box>
  );
}

export default TakeSelfieTab;
