import { Image } from "@common/StyledImage/Image";
import { usePreviewImageContext } from "./PreviewImagesBody";
import { useEffect } from "react";
import NiceModal from "@ebay/nice-modal-react";
import MobileImagePreviewZoomModal from "./MobileImagePreviewZoomModal";

const PreviewedImageMobile = () => {
  const { src, setClickedImage, clickedImage, fileName } =
    usePreviewImageContext();

  useEffect(() => {
    if (clickedImage) {
      NiceModal.show(MobileImagePreviewZoomModal, {
        src: clickedImage,
        setClickedImage,
        fileName: fileName,
      });
    }
  }, [clickedImage]);

  return (
    <>
      <Image
        src={src}
        onClick={() => setClickedImage(src)}
        sx={{
          width: "100%",
          height: "380px",
          objectFit: "contain",
          backgroundColor: "#575353"
        }}
      />
    </>
  );
};

export default PreviewedImageMobile;
