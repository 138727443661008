import { showMessage } from "@common/Toast";
import { IParsedData } from "../data.types";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
type Props = {
  merchantId: number;
  data: IParsedData;
};

export const useUpdateBusinessProfileStatus = ({ merchantId, data }: Props) => {
  const queryClient = useQueryClient();
  const businessProfileId = data.businessProfile.id;
  const updateBusinessProfileStatusMutation = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}/legal-entities/${businessProfileId}/status`,
      method: "PATCH",
      data,
    });
    
  },
  {
    onSuccess: () => {
      queryClient.refetchQueries(["get-merchant-preview", merchantId]);
    },
  },
  );

  const handleUpdateStatus = async (
    status: string,
    reason?: string,
    shouldSendReason?: boolean,
  ) => {
    const payload = {
      status: status === "move_back_to_pending"? "pending": status,
      reason: reason,
      sendReason:shouldSendReason,
    };
    try {
    await updateBusinessProfileStatusMutation.mutateAsync(
      { ...payload },
    );
  }
  catch (error: any) {
    showMessage("Error", error?.response?.data?.message || error?.message);
  }
  };

  return { handleUpdateStatus };
};

