import * as React from "react";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// component
import { Text } from "@common/Text";
import { KeyVal } from "@common/KeyVal";
import { DropdownItem } from "@common/Select";

// assets
import { MoreIcon, RefundTopIcon } from "@assets/icons";
// utils
import { truncateStringLength } from "utils";
// types
import NiceModal from "@ebay/nice-modal-react";
import {
  MERCHANT_PORTAL_MODIFY_RECURRING_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
} from "modals/modal_names";
import { Item } from "./transactions.types";
import { palette } from "@palette";
import { HiddenComponent } from "containers/HiddenComponent";
import { shouldBeHidden } from "@constants/constants";
import { RecurringComponent } from "./RecurringComponent";
import { ActionsComponent } from "@common/Table/components/ActionsComponent";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  fontSize: 14,
  backgroundColor: "inherit",
  border: "none",
  "&:not(:last-child)": {
    borderBottom: `2px solid ${palette.neutral[10]}`,
  },
  "&:before": {
    display: "none",
  },
  "& sup": {
    fontSize: 9,
    lineHeight: "9px",
    marginLeft: "2px",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  padding: "0 0 0 4px",
  backgroundColor: "inherit",
  flexDirection: "row-reverse",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiPaper-root-MuiAccordion-root:not(:last-child)": {
    borderBottom: `1px solid ${palette.neutral[10]}`,
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2.5),
  },
  "& .MuiButton-root.CustomIconButton": {
    border: "none",
    boxShadow: "none",
    backgroundColor: "inherit",

    "&:active": {
      boxShadow:
        "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 4.5),
  backgroundColor: "inherit",
}));

export default function SubExpandedRow({
  data,
  sourceAccount,
  transactionID,
}: {
  data: Item;
  sourceAccount: any;
  transactionID: any;
}) {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem" }}
            onClick={() => setExpanded((prevState) => !prevState)}
          />
        }
        aria-controls="sub-expanded-row-content"
        id={data.product.name}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={6}
            onClick={() => setExpanded((prevState) => !prevState)}
          >
            <Text color="#4C4A52" fontWeight="semibold">
              {truncateStringLength(`${data.product.name ?? ""}`, 15)}
            </Text>
            <Box>
              {expanded
                ? `${(data as any).description ?? ""}`
                : truncateStringLength(
                    `${(data as any).description ?? ""}`,
                    35,
                  )}
            </Box>
          </Grid>
          <Grid item xs={2} visibility={expanded ? "hidden" : "visible"}>
            <Text textTransform="capitalize">{(data as any).recurrence}</Text>
          </Grid>
          <Grid item xs={2} visibility={expanded ? "hidden" : "visible"}>
            <Text>
              {((data.price / 100) * data.quantity).toFixed(2)}
              <sup>USD</sup>
            </Text>
          </Grid>

          <Grid item xs={2}>
            <ActionsRow
              id={data.id}
              orderRecurringItemID={data.orderRecurringItemID}
              disallowReversalRequests={data.disallowReversalRequests}
              sourceAccount={sourceAccount}
            />
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        {/* ---------- TYPE - RECURRENCE - NEXT TRANSACTION  ----------*/}
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <KeyVal keyName="Type" value={data.product.type} />
          </Grid>

          <Grid
            item
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <KeyVal
              keyName="Recurrence"
              value={
                <RecurringComponent
                  recurringCount={data.recurringCount}
                  recurringInterval={data.recurringInterval}
                  recurringMax={data.recurringMax}
                />
              }
            />
          </Grid>
        </Grid>

        <Grid mt={1.5} container rowSpacing={1} alignItems="center">
          {/* ---------- DONATED  ----------*/}
          <Grid item xs={6}>
            <KeyVal
              keyName="Item"
              value={
                <Text>
                  Enter any amount x Qty @ 64.00<sup>USD</sup> each
                </Text>
              }
            />
          </Grid>

          {/* ---------- DONATION - GIVEBOX FEE - VISA FEE - CHARGED  ----------*/}
          <Grid item container xs={6}>
            <Grid item xs={3}>
              <KeyVal
                keyName="Amount"
                value={
                  <Text>
                    {(data.quantity * (data.unitPrice / 100)).toFixed(2)}
                    <sup>USD</sup>
                  </Text>
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Text fontSize={12} color={theme.palette.neutral[600]}>
              *Customer Paid Fee
            </Text>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export const ActionsRow = ({
  orderRecurringItemID,
  disallowReversalRequests,
  id,
  sourceAccount,
  hideRefund,
  transactionStatus,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack
      gap={0.5}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
    >
      <HiddenComponent hidden={shouldBeHidden.refund}>
        <ActionsComponent
          disabled={hideRefund}
          onClick={() =>
            NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL, {
              sourceAccount,
              ids: {
                transactionItemIDs: [id],
              },
            })
          }
          title="Refund"
          icon={() => <RefundTopIcon size="20px" />}
        />
      </HiddenComponent>
      <ActionsComponent
        title="More"
        onClick={handleOpenMenu}
        icon={() => <MoreIcon size="20px" />}
      />

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 159,
          },
        }}
      >
        <HiddenComponent hidden={shouldBeHidden.refund || hideRefund}>
          <DropdownItem
            disabled={disallowReversalRequests}
            onClick={() => {
              NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL, {
                sourceAccount,
                ids: {
                  transactionItemIDs: [id],
                },
              });
              if (anchorEl) setAnchorEl(null);
            }}
          >
            <Text>Refund</Text>
          </DropdownItem>
        </HiddenComponent>
        <DropdownItem
          disabled={!orderRecurringItemID || transactionStatus === "Voided"}
          onClick={() => {
            NiceModal.show(MERCHANT_PORTAL_MODIFY_RECURRING_MODAL, {
              orderRecurringItemID: orderRecurringItemID,
              sourceAccount,
            });
            if (anchorEl) setAnchorEl(null);
          }}
        >
          <Text>Modify Recurring</Text>
        </DropdownItem>
      </Menu>
    </Stack>
  );
};
