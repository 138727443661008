import React from "react";
import { useTransactionsIOC } from "@components/ManageMoney/TransactionTable/hooks";
import { Grid, Stack } from "@mui/material";
import { MobileListWithStates } from "@common/MobileList";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { TableCard_V2 } from "@components/ManageMoney/TransactionTable/Mobile/TableCard";
import {
  LoadingSkeleton,
  PendingSwitch,
} from "@components/ManageMoney/TransactionTable/TransactionTable.atoms";
import { useGetAllTransactions } from "@services/api/products/transactions";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import { TransactionTableSearch } from "@components/ManageMoney/TransactionTable/TransactionTableSearch";
import DownloadReportAction from "@components/ManageMoney/TransactionTable/DownloadReportAction";
import { parseRow } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { MobilePurchaseItem_V2 } from "@common/Campaigns/ReportPage/MobilePurchaseItem";

export function MobileWraper({
  dataQueryKey,
  apiBuilder,
  isPurchase,
  rowParser,
}: any) {
  const {
    allRows,
    totalRows,
    page,
    isLoading,
    setPageDispatcher,
    loadingRef,
    setPage,
  } = useTransactionsIOC(
    dataQueryKey,
    apiBuilder ?? useGetAllTransactions(dataQueryKey),
    "",
    {
      isPurchase,
    },
  );

  const targetRef = useTargetRef({ page, totalRows, setPage, loadingRef });

  const rows = React.useMemo(() => {
    return allRows.map(rowParser ?? parseRow);
  }, [allRows]);

  return (
    <Grid paddingX={1}>
      <Stack gap={2}>
        <TransactionTableSearch queryKey={dataQueryKey} />
        {!isPurchase ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <PendingSwitch setPage={setPageDispatcher} />
            <DownloadReportAction />
          </Stack>
        ) : null}
      </Stack>
      <MobileListWithStates
        queryKey={dataQueryKey}
        length={allRows?.length}
        targetRef={targetRef}
        isLoading={isLoading}
        sx={{ marginTop: "10px" }}
        LoadingSkeleton={LoadingSkeleton}
      >
        {rows.map((item: TransactionTableRow, idx: number) => (
          <FadeUpWrapper delay={20 + idx * 20} key={item.id}>
            {isPurchase ? (
              <MobilePurchaseItem_V2 rowData={item} campaignType="fundraiser" />
            ) : (
              <TableCard_V2 rowData={item} />
            )}
          </FadeUpWrapper>
        ))}
      </MobileListWithStates>
    </Grid>
  );
}
