import React from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { BtnBGTypes, RebrandedButtonProps, StyledButton_V2 } from "./Button_V2";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

export type TooltipProps = {
  show: boolean;
  message: string;
  extraMessage?: string;
  maxWidth?: string;
};

export type ButtonProps = MuiButtonProps & {
  children?: React.ReactNode;
  middleIcon?: React.ReactNode;
  background?: BtnBGTypes;
  size?: "small" | "medium" | "large";
  tooltipProps?: TooltipProps;
  isEnterprise?: boolean;
};

const MiddleIcon = styled("span")({
  display: "inherit",
  marginLeft: "0px",
  marginRight: 0,
  textTransform: "capitalize",
});

export const Button: React.FC<ButtonProps> = ({
  children,
  middleIcon,
  tooltipProps,
  ...props
}) => {
  if (!tooltipProps) {
    return (
      <MuiButton {...props}>
        {children}
        {middleIcon && <MiddleIcon>{middleIcon}</MiddleIcon>}
      </MuiButton>
    );
  }
  return (
    <CustomToolTip
      showToolTip={tooltipProps.show}
      message={tooltipProps.message}
      extraMessage={tooltipProps.extraMessage}
      maxWidth={tooltipProps.maxWidth}
    >
      <MuiButton {...props}>
        {children}
        {middleIcon && <MiddleIcon>{middleIcon}</MiddleIcon>}
      </MuiButton>
    </CustomToolTip>
  );
};

export interface Button_V2Props extends RebrandedButtonProps {
  tooltipProps?: TooltipProps;
}

export const Button_V2: React.FC<Button_V2Props> = ({
  children,
  tooltipProps,
  ...props
}) => {
  if (!tooltipProps) {
    return <StyledButton_V2 {...props}>{children}</StyledButton_V2>;
  }
  return (
    <CustomToolTip
      showToolTip={tooltipProps.show}
      message={tooltipProps.message}
      extraMessage={tooltipProps.extraMessage}
      maxWidth={tooltipProps.maxWidth}
    >
      <StyledButton_V2 {...props}>{children}</StyledButton_V2>
    </CustomToolTip>
  );
};

export const CloseButton = styled(Button_V2)(() => ({
  padding: 0,
  minWidth: 0,
  height: 24,
}));
