import { TransactionTableTag } from "@common/Tag/TransactionTableTag";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import { getStatus } from "@components/ManageMoney/TransactionTable/transactions.helpers";

export const StatusColumn = ({ row }: { row: TransactionTableRow }) => (
  <TransactionTableTag
    type={getStatus(row).displayStatus.toLowerCase() as any}
    sx={{ margin: "0 auto" }}
  />
);
