import React from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";

type SSNInputProps = {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  customProps?: {
    maxLength: number;
    normalize: (value: string) => string | undefined;
  };
  focusViewColor?: string;
};

const SSNInput = ({
  disabled,
  label,
  name,
  placeholder,
  inputStyle = {},
  customProps,
  ...rest
}: SSNInputProps) => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\dX]/g, "");
    const cvLength = currentValue.length;

    if (cvLength < 4) return currentValue;
    if (cvLength < 6)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 5)}`;
    if (cvLength < 11)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        5,
      )}-${currentValue.slice(5, 11)}`;
  };

  React.useEffect(() => {
    setValue(
      name,
      customProps?.normalize
        ? customProps.normalize(watch(name))
        : normalizeInput(watch(name)),
    );
  }, [watch(name)]);

  return (
    <RHFInput
      disabled={disabled}
      name={name}
      label={label}
      placeholder={placeholder}
      fullWidth
      inputProps={{
        maxLength: customProps?.maxLength
          ? String(customProps.maxLength)
          : "11",
      }}
      sx={{
        letterSpacing: "4px",
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
        "& .MuiInputBase-root": {
          ...inputStyle,
        },
      }}
      {...rest}
    />
  );
};

export default SSNInput;
