export const SignupType = {
  GIVEPAYMENTS_WEBSITE: "givepayments_website", //for enterprise
  ENTERPRISE_WEBSITE: "enterprise_website", //if it is from enterprise website
  ACQUIRER_CREATED: "acquirer_created", //in side panel if the logged in is an acquirer
  ACQUIRER_IMPORTED: "acquirer_imported",
  ENTERPRISE_CREATED: "enterprise_created", //in side panel if the logged in is an enterprise
  ENTERPRISE_IMPORTED: "enterprise_imported",
  DEVELOPER_API: "developer_api",
};

export const isEnterpriseWebsite = () => {
  const pathname = window.location.href;
  const isEnterprise =
    !pathname.startsWith("https://portal.givepayments.") &&
    !pathname.startsWith("https://portal.staging.") &&
    !pathname.startsWith("https://portal.development.") &&
    !pathname.startsWith("https://local.");
  return isEnterprise;
};
