import { SyntheticEvent, useEffect, useState } from "react";
import { builder } from "../utils";
import { selectedItem } from "../signals";

type Props = {
  actionType: "Up" | "Down";
};

const useNodeRearrange = ({ actionType }: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const currentIndex = builder.findNodeIndex(selectedItem.value?.id);
  const childrenLength = builder.getChildrenLength() - 1;
  const lastIndex = actionType === "Up" ? 0 : childrenLength;

  useEffect(() => {
    if (currentIndex === lastIndex) setIsDisabled(true);
    else setIsDisabled(false);
  }, [currentIndex]);

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (currentIndex === lastIndex) {
      setIsDisabled(true);
    } else {
      builder.swapNodes(
        currentIndex,
        actionType === "Up" ? currentIndex - 1 : currentIndex + 1,
      );
    }
  };

  return {
    isDisabled,
    handleClick,
  };
};

export default useNodeRearrange;
