import { shouldBeHidden } from "@constants/constants";
import { IParsedData } from "../data.types";
import { useMemo } from "react";

const useAnalyticsDataParser = (data: any): IParsedData => {
  const stats = {
    totalCustomers: {
      label: "Total Customers",
      value: data?.data?.totalCustomers || 0,
    },
    recurringCustomer: {
      label: "Recurring Customers",
      value: data?.data?.recurringCustomers || 0,
    },
  };

  const locationStats = useMemo(() => {
    if (
      !data?.data?.top10Locations ||
      !data?.data?.top10Locations?.length ||
      shouldBeHidden.customersLocations
    )
      return [];
    let total = 0;
    const baseStats = data.data.top10Locations
      .filter((loc: any) => !!loc.state)
      .map((location: any) => {
        total += location.amountSpent;
        return {
          name: location.state,
          total: location.amountSpent,
        };
      });

    return baseStats.map((stat: { name: string; total: number }) => {
      return {
        ...stat,
        percentage: (stat.total * 100) / total,
      };
    });
  }, [data?.data?.top10Locations]);

  const customersChart = useMemo(
    () => recurrenceChartNormalizer(data?.chart),
    [data?.chart],
  );

  const geoMapChart = useMemo(() => {
    if (
      !data?.data?.customersPerState ||
      !data?.data?.customersPerState?.length
    )
      return [];
    return data.data.customersPerState
      .filter((state: any) => !!state.state)
      .map((state: any) => {
        return {
          name: state.state,
          value: state.totalCustomers,
        };
      });
  }, [data?.data?.customersPerState]);

  return {
    stats,
    locationStats,
    customersChart,
    geoMapChart,
  };
};

type TData = {
  newCustomers: number;
  date: Date;
  recurringCustomers: number;
  sumPurchases: number;
  avg_purchases: number;
};

const customersChartLabels = [
  "New Customers",
  "Recurring",
  "Amount (USD)",
  "Avg transaction (USD)",
];

const mainChartKeys: Record<string, string> = {
  "New Customers": "newCustomers",
  Recurring: "recurringCustomers",
  "Amount (USD)": "sumPurchases",
  "Avg transaction (USD)": "avg_purchases",
};

const recurrenceChartNormalizer = (data: TData[]) => {
  if (!data)
    return customersChartLabels.map((label) => ({ label, entries: [] }));

  return customersChartLabels.map((label) => ({
    label,
    entries: data.map((chartData: TData) => {
      const value =
        chartData[mainChartKeys[label] as keyof Omit<TData, "date">];
      return {
        x: chartData.date,
        y:
          label === "Avg transaction (USD)" || label === "Amount (USD)"
            ? value / 100
            : value,
      };
    }),
  }));
};

export default useAnalyticsDataParser;
