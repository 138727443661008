import Branding from "@components/EnterpriseSettings/Branding";
import WelcomePage from "@pages/Signup/WelcomePage";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ENTERPRISE_PATHS } from "./paths";

const MerchantsPage = lazy(() =>
  import("@pages/EnterprisePortal/Merchants/Merchants").then((module) => ({
    default: module.MerchantsPage,
  })),
);

const DeveloperApiPage = lazy(() =>
  import("@pages/EnterprisePortal/EnterpriseDeveloperApi").then((module) => ({
    default: module.DeveloperApi,
  })),
);

const EnterpriseProcessingPage = lazy(() =>
  import("@pages/EnterprisePortal/Processing").then((module) => ({
    default: module.EnterpriseProcessingPage,
  })),
);

const EnterpriseSettingsPage = lazy(() =>
  import("@pages/EnterprisePortal/Settings").then((module) => ({
    default: module.EnterpriseSettingsPage,
  })),
);

const TransactionDetails = lazy(() =>
  import("@components/Merchants/ViewTransaction/Mobile").then((module) => ({
    default: module.TransactionDetails,
  })),
);

const TransfersPage = lazy(() =>
  import("@pages/Transfers").then((module) => ({
    default: module.Transfers,
  })),
);

const ProcessingPaymentDetails = lazy(
  () =>
    import(
      "@components/Processing/TransactionsTable/Mobile/ProcessingPaymentDetails"
    ),
);

// SETTINGS --------------------------------------------------------------------------------------------------

const BusinessDetails = lazy(
  () => import("@components/Settings/BusinessDetails/BusinessDetails"),
);

const ManageTeam = lazy(() =>
  import("@components/Settings").then((module) => ({
    default: module.MemberListTable,
  })),
);

type Props = {
  queryParams: string;
  isTransfersPageEnabled: boolean;
};

export const getEnterpriseRoutes = ({
  isTransfersPageEnabled,
  queryParams,
}: Props) => {
  return [
    {
      element: (
        <Navigate to={`${ENTERPRISE_PATHS.WELCOME}${queryParams}`} replace />
      ),
      index: true,
    },
    {
      path: ENTERPRISE_PATHS.WELCOME,
      children: [
        {
          element: <WelcomePage />,
          index: true,
        },
      ],
    },
    {
      path: ENTERPRISE_PATHS.MERCHANTS,
      children: [
        { element: <MerchantsPage />, index: true },
        {
          path: ENTERPRISE_PATHS.TRANSACTIONS_ID,
          element: <TransactionDetails />,
        },
      ],
    },
    {
      path: ENTERPRISE_PATHS.PROCESSING,
      children: [
        { element: <EnterpriseProcessingPage />, index: true },
        {
          path: ENTERPRISE_PATHS.PROCESSING_ID,
          element: <ProcessingPaymentDetails />,
        },
      ],
    },
    ...(process.env.REACT_APP_ENVIRONMENT !== "production"
      ? [
          {
            path: ENTERPRISE_PATHS.DEVELOPER_API,
            children: [{ element: <DeveloperApiPage />, index: true }],
          },
        ]
      : []),
    {
      path: ENTERPRISE_PATHS.SETTINGS,
      element: <EnterpriseSettingsPage />,
      children: [
        {
          element: (
            <Navigate
              to={`${ENTERPRISE_PATHS.BUSINESS_DETAILS}${queryParams}`}
              replace
            />
          ),
          index: true,
        },
        {
          path: ENTERPRISE_PATHS.BUSINESS_DETAILS,
          element: <BusinessDetails />,
        },
        {
          path: ENTERPRISE_PATHS.MANAGE_TEAM,
          children: [
            {
              element: <ManageTeam />,
              index: true,
            },
          ],
        },
        {
          path: ENTERPRISE_PATHS.THEME_BRANDING,
          children: [
            {
              element: <Branding />,
              index: true,
            },
          ],
        },
      ],
    },
    ...(isTransfersPageEnabled
      ? [
          {
            path: ENTERPRISE_PATHS.TRANSFERS,
            element: <TransfersPage />,
          },
        ]
      : []),
  ];
};
