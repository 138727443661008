import { Stack } from "@mui/material";
import ProfileMenu from "@components/ProfileMenu/ProfileMenu";
import NotificationsCenterButton from "features/Notifications/AppBar/NotificationsCenterButtons";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { HiddenComponent } from "@containers/HiddenComponent";

function AppbarActions() {
  const { isMasqueradeMode } = useMasqueradeReducer();
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <HiddenComponent hidden={isMasqueradeMode}>
        <NotificationsCenterButton />
      </HiddenComponent>
      <ProfileMenu />
    </Stack>
  );
}

export default AppbarActions;
