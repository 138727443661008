import React from "react";
import { SortDownIcon, SortUpIcon } from "@assets/icons";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { palette } from "@palette";

interface Props {
  sortedCol: boolean;
  order: "asc" | "desc";
  color?: string;
  sortKey?: string;
}

const getColor = (sortedCol: boolean, order: Props["order"]) => {
  const unsorted = palette.neutral[60];
  const sorted = palette.neutral[90];

  return {
    asc: sortedCol && order === "asc" ? sorted : unsorted,
    desc: sortedCol && order === "desc" ? sorted : unsorted,
  };
};

export const SortComponent = ({ sortedCol, order, color, sortKey }: Props) => {
  const classes = useStyles();

  const sortColor = getColor(sortedCol, order);

  return (
    <Box className={classes.sortWrapper} data-testid={`table-sort-${sortKey}`}>
      <span>
        <SortUpIcon
          fill={color || sortColor.asc}
          stroke={color || sortColor.asc}
          size={"8px"}
        />
      </span>

      <span>
        <SortDownIcon
          fill={color || sortColor.desc}
          stroke={color || sortColor.desc}
          size={"8px"}
        />
      </span>
    </Box>
  );
};

const useStyles = makeStyles({
  sortWrapper: {
    cursor: "pointer",
    "& span": {
      display: "flex",
      "& :hover": {
        opacity: 0.98,
      },
    },
  },
});
