import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Text } from "@common/Text";
import { palette } from "@palette";

type Props = {
  messageType:
    | "sole_proprietor"
    | "controller"
    | "owner"
    | "non_resident_pah"
    | "non_resident_bo";
  containerStyle?: React.CSSProperties;
};

export const BusinessProfileDisclaimer = ({
  messageType,
  containerStyle,
}: Props) => {
  return (
    <Box
      bgcolor={palette.info.light}
      borderRadius="12px"
      padding="12px 16px"
      width="100%"
      sx={containerStyle}
    >
      <Grid display="flex" alignItems="flex-end" paddingBottom="8px">
        <InfoOutlined
          sx={{
            width: 24,
            height: 24,
            marginRight: "8px",
            fill: palette.filled.blue,
          }}
        />
        <Text color={palette.filled.blue} fontSize="18px" fontWeight="regular">
          {messages[messageType]?.title}
        </Text>
      </Grid>
      <Text color={palette.filled.blue} fontSize="14px" fontWeight="book">
        {messages[messageType]?.description}
      </Text>
    </Box>
  );
};

const messages = {
  sole_proprietor: {
    title: "Sole Proprietor",
    description: "Sole Proprietor businesses can only have one owner",
  },
  controller: {
    title: "Merchant linked to an existing business profile",
    description:
      "Ensure that when linking a merchant to an existing business profile, the primary account holder is not added as a business owner.",
  },
  owner: {
    title: "Info",
    description:
      "You can edit the business owner details on the Business Details step or in settings.",
  },
  non_resident_pah: {
    title: "Non-resident",
    description:
      "Primary Account Holder is a non-resident of the United States",
  },
  non_resident_bo: {
    title: "Non-resident",
    description: "Business Owner is a non-resident of the United States",
  },
};
