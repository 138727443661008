import { parsePhoneNumber } from "@components/Merchants/MerchantPreview/helpers/parsers";
import { format } from "date-fns";
import { refineBusinessOwnershipInOnboarding } from "@utils/validation.helpers";
import { businessOwnerParser, merchantClassificationParser } from "@components/Settings/BusinessDetails/components/MerchantInfoSection/utils/merchantInfoParser";

export const refineData = (data: any) => {
  const businessDetails = parseBusinessDetails(data?.legalEntity);
  const businessAddress = parseBusinessAddress(data?.legalEntity?.address);
  const businessOwners = parsePrincipals(data?.legalEntity?.principals);
  const merchantInfo = parseMerchantInfo(data?.merchant);
  const enterpriseInfo = parseEnterpriseInfo(data?.merchant);

  return {
    businessDetails,
    businessAddress,
    businessOwners,
    merchantInfo,
    enterpriseInfo,
  };
};

export type DynamicReturnType = ReturnType<typeof refineData>;

const parseEnterpriseInfo = (data: any) => {
  return {
    name: data?.name,
    servicePhoneNumber: (data?.servicePhoneNumber
      ? `+${parsePhoneNumber(data?.servicePhoneNumber)}`
      : "") as string,
    purpose: data?.description,
    websiteURL: stripHttps(data?.websiteURL),
    serviceCountriesOutUSCanada: data?.serviceCountriesOutUSCanada,
    countriesServicedOutside: data?.countriesServicedOutside,
    permalink: data?.slug,
    class: merchantClassificationParser(data),
    primaryAccountHolder: businessOwnerParser(data?.owner) //PAH data for default value on BO create when is sole proprietor
  };
};

const parseMerchantInfo = (data: any) => {
  return {
    averageTicketAmount: formatToStringValue(data?.averageTicketAmount),
    servicePhoneNumber: (data?.servicePhoneNumber
      ? `+${parsePhoneNumber(data?.servicePhoneNumber)}`
      : "") as string,
    annualCreditCardSalesVolume: formatToStringValue(
      data?.annualCreditCardSalesVolume,
    ),
    highTicketAmount: formatToStringValue(data?.highTicketAmount),
    description: data?.description,
    websiteURL: stripHttps(data?.websiteURL),
    serviceCountriesOutUSCanada: data?.serviceCountriesOutUSCanada,
    countriesServicedOutside: data?.countriesServicedOutside,
    parentSlug: data?.parentSlug,
    slug: data?.slug,
    name: data?.name,
    class: merchantClassificationParser(data),
    imageURL: data?.imageURL,
    billingDescriptor: data?.billingDescriptor
      ? data?.billingDescriptor
      : data?.name,
    billingDescriptorPrefix: data?.billingDescriptorPrefix,
  };
};

const parsePrincipals = (data: any): OnboardingPrincipal[] => {
  return data?.map((principal: any) => {
    const useBusinessAddress = principal?.useEntityGeoAddress;
    const ssn: string = principal?.taxIDNumberLast4
      ? `XXXXX${principal?.taxIDNumberLast4}`
      : "";
    return {
      firstName: (principal?.firstName || "") as string,
      lastName: (principal?.lastName || "") as string,
      isNotUSResident: !!principal?.citizenship,
      citizenship: principal?.citizenship || "",
      isNotResidentInCitizenshipCountry: !!principal?.countryOfResidence,
      countryOfResidence: principal?.countryOfResidence || "",
      email: (principal?.email || "") as string,
      ssn,
      DOB: (principal?.dateOfBirth
        ? format(new Date(+principal?.dateOfBirth * 1000), "MM/dd/yyyy")
        : "") as string,
      ownership: (principal?.ownershipPercentage || "") as string,
      country: (principal?.address?.country || "") as string,
      city: (principal?.address?.city || "") as string,
      street: (principal?.address?.line1 || "") as string,
      state: (principal?.address?.state || "") as string,
      zip: (principal?.address?.zip || "") as string,
      id: (principal?.id || "") as string,
      pepStatusName: (principal?.pepStatusName || "") as string,
      contactPhone: principal?.phoneNumber
        ? `+${parsePhoneNumber(principal.phoneNumber)}`
        : "",
      useBusinessAddress,
      files: {
        allFiles: [],
      },
    };
  });
};

const parseBusinessAddress = (data: any) => {
  return {
    country: (data?.country || "US") as string,
    address: (data?.line1 || "") as string,
    city: (data?.city || "") as string,
    state: (data?.state || "") as string,
    zip: (data?.zip || "") as string,
  };
};

const parseBusinessDetails = (data: any): Record<string, string> => {
  return {
    legalName: data?.name || "",
    DBA: data?.doingBusinessAs || "",
    tinType: data?.tinType || "ein",
    ssn: data?.tinType === "ssn" ? data?.taxIDNumber || "" : "",
    taxIDNumber: data?.tinType === "ein" ? data?.taxIDNumber || "" : "",
    businessOpenedAt: data?.businessOpenedAt
      ? format(new Date(+data?.businessOpenedAt * 1000), "MM/dd/yyyy")
      : "",
    businessType: data?.type?.name || "corporation",
    ownershipType: refineBusinessOwnershipInOnboarding(
      data?.type?.name || "corporation",
      data?.ownershipType?.toLowerCase(),
    ),
    phoneNumber: data?.phoneNumber
      ? `+${parsePhoneNumber(data.phoneNumber)}`
      : "",
    id: data?.id || "",
  };
};

const stripHttps = (url = ""): string => {
  const httpsPrefix = "https://";
  return url.replace(httpsPrefix, "");
};

export const formatToStringValue = (
  value: number | string | null | undefined,
) => {
  if (typeof value === "number") {
    return value / 100 + ".00";
  } else if (typeof value === "string") {
    return `${value.slice(0, -2)}.${value.slice(-2)}`;
  } else {
    ("0.00");
  }
};

export interface OnboardingPrincipal {
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  DOB: string;
  ownership: string;
  country: string;
  city: string;
  street: string;
  state: string;
  zip: string;
  id: string;
  pepStatusName: string;
  useBusinessAddress: boolean;
  isNotUSResident: boolean;
  citizenship: string;
  isNotResidentInCitizenshipCountry: boolean;
  countryOfResidence: string;
}
