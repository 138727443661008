import * as React from "react";
// import { palette } from "@palette";
import { RevenuesData } from "./data";
// @mui
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
// components
import { KeyVal } from "@common/KeyVal";
// import { Text } from "@common/Text";
// assets

// utils
import { toEnFormat } from "@utils/index";

const dummyInfo = {
  source: "New Donation Form",
  sourceDescription: "Website",
  fundraiser: "New Donation Form",
  revenueSource: "Givebox Public API",
  transaction: 7,
  url: "buddyteal.com/",
  date: "Jun 30 2022 10:23PM UCT",
  amount: 280,
  average: 40,
  VisitorsViews: 51,
  Visitors: 14,
};

type ExpandedRowProps = {
  rowData: RevenuesData;
  type: string;
  VisitorsViews: boolean;
  Visitors: boolean;
};

export const ExpandedRow: React.FC<ExpandedRowProps> = ({
  rowData,
  type,
  VisitorsViews = false,
  Visitors = false,
}) => {
  const data =
    rowData && rowData.details !== undefined ? rowData.details : dummyInfo;
  const classes = useStyles();

  return (
    <>
      <Box className={classes.box}>
        <Box sx={{ display: "flex", p: "16px" }}>
          <Box className={classes.compart1}>
            <Box>
              <KeyVal
                keyName={type}
                value={
                  type === "Membership" ? "General Membership" : data.fundraiser
                }
              />
            </Box>
            <Box>
              <KeyVal keyName={"Revenue Source "} value={data.revenueSource} />
            </Box>
            <Box>
              <KeyVal
                isAmount={true}
                keyName={"Amount"}
                value={toEnFormat(data.amount)}
              />
            </Box>
            <Box>
              <KeyVal keyName={"Transaction"} value={data.transaction} />
            </Box>

            <Box>
              {VisitorsViews && (
                <KeyVal keyName={"Visitor Views:"} value={data.VisitorsViews} />
              )}
            </Box>
          </Box>
          <Box className={classes.compart2}>
            <Box className={classes.contents}>
              <Box>
                <KeyVal keyName={"Date"} value={data.date} />
              </Box>
              <Box className={classes.spacedFlex}>
                <Box>
                  <KeyVal keyName={" URL"} value={data.url} />
                </Box>
              </Box>
              <Box>
                <KeyVal
                  isAmount={true}
                  keyName={" Average Transaction"}
                  value={toEnFormat(data.average)}
                />
              </Box>
              <Box>
                {Visitors && (
                  <KeyVal keyName={"Visitors"} value={data.Visitors} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  box: {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    padding: "24px 12px 16px 12px",
  },
  compart1: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  compart2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  head: {
    display: "flex",
    gap: "28px",
    alignItems: "center",
  },
  contents: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& >.MuiBox-root": {
      padding: "0 8px",
    },
  },
  spacedFlex: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiBox-root": {
      flex: 1,
    },
  },
  ps: {
    paddingTop: "20px !important",
  },
});
