import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { Fragment, memo } from "react";
import Figure from "@common/Figure";
import { TMerchantStats } from "@components/Merchants/MerchantPreview/data.types";

type Props = {
  data: TMerchantStats;
};

const EnterpriseStats = ({ data }: Props) => {
  return (
    <Stack direction="row" gap={3} alignItems="center" flexWrap="wrap">
      <KeyValue label={labels.totalRaised} value={data.totalRaised} isAmount />
      <KeyValue label={labels.transactions} value={data.transactions} />
      <KeyValue
        label={labels.totalMerchants}
        value={data.totalMerchants || 0}
      />
      <KeyValue
        label={labels.pendingMerchants}
        value={data.pendingMerchants || 0}
      />
    </Stack>
  );
};

type KeyValueProps = {
  label: string;
  value: string | number;
  isAmount?: boolean;
};

const KeyValue = ({ label, value, isAmount }: KeyValueProps) => (
  <Stack direction="column" gap="4px" alignItems="flex-start">
    <Text
      color={palette.black[100]}
      variant="h6"
      lineHeight="21.6px"
      fontWeight="book"
      letterSpacing="-0.18px"
    >
      <Figure
        isAmount={isAmount}
        fontSize={20}
        value={value}
        fontWeight="book"
      />
    </Text>
    <Text
      color={palette.gray[300]}
      variant="body"
      fontWeight="book"
      lineHeight="16.8px"
    >
      {label}
    </Text>
  </Stack>
);

const labels = {
  totalRaised: "Processed (USD)",
  transactions: "Transactions",
  totalMerchants: "Merchants",
  pendingMerchants: "Pending Merchants",
};

export default memo(EnterpriseStats);
