import React from "react";
import { EventsData } from "@components/Events/EventsList/data";
import CampaignCard from "@common/CampaignCard/CampaignCard";
import { formatDate } from "@utils/index";
import { palette } from "@palette";
import { parseAmount } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2/PurchasesGrid";
import { CommonProductProps } from "@common/Campaigns/campaigns.types";

const NewEventListItem = ({
  data,
  columns,
  openSidePanel,
  isSelected,
}: {
  isSelected?: boolean;
  data: EventsData;
} & CommonProductProps) => {
  const date = (
    <>
      <span>{formatDate(data.createdAt ?? 0)}</span>
    </>
  );

  const location = data.locationURL
    ? data.locationURL
    : (data.locationAddress &&
        !data.locationShortAddress &&
        `${data.locationAddress.line1} ${data.locationAddress.city}
          ${data.locationAddress.zip} ${data.locationAddress.state}
          ${data.locationAddress.country}`) ||
      (!data.locationURL && data.locationShortAddress);

  const others = [
    date,
    location,
    `${data.conversionRate}%`,
    parseAmount(data.averageTransactions / 100),
    data.totalTransactions,
    data.visitors,
  ];

  return (
    <CampaignCard
      imageURL={data.imageURL}
      name={data.name}
      id={data.id}
      others={others}
      columns={columns}
      sx={{
        borderTop: `1px solid ${palette.liftedWhite[300]}`,
      }}
      openSidePanel={openSidePanel}
      isSelected={isSelected}
    />
  );
};

export default NewEventListItem;
