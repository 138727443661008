import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";

import ModalContent from "@common/TableFilters/ModalContent";
import { useTransferFilter } from "../hooks/useTransferFilter";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";

const TransferFilterPanel = NiceModal.create(() => {
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  const { methods, onSubmit, actions, transferSections } = useTransferFilter();

  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose: onClose,
        SlideProps,
        SidePanelProps: {
          width: "500px",
          PaperProps: {
            TransitionProps,
          },
        },
        DrawerProps: {
          PaperProps: {
            TransitionProps,
          },
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          sx={{
            padding: "16px",
            gap: "24px",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ModalTitle
            title="Filter by"
            textStyles={{
              title: {
                fontSize: "18px",
                lineHeight: "21.6px",
                letterSpacing: "-0.18px",
              },
            }}
            closeButtonSize={24}
            onClose={onClose}
          />
          <ModalContent sections={transferSections} />
          <ModalActions animationDelay={250} {...actions} />
        </Box>
      </FormProvider>
    </ModalFactory>
  );
});

export default TransferFilterPanel;
