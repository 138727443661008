import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Text } from "@common/Text";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Stack } from "@mui/material";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { RHFSwitch } from "@common/Switch";
import {
  useListParentBankAccounts,
  useUpdateBankAccountSettings,
} from "../hooks/useMerchantBankAccounts";
import MerchantBankAccountItem from "../components/BankAccounts/MerchantBankAccountItem";
import { TMerchantBankAccountSettings } from "../data.types";
import { useEffect } from "react";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

type FormInputs = TMerchantBankAccountSettings;

interface ModalProps {
  merchantId: number;
  parentAccID: number;
  data: TMerchantBankAccountSettings;
}

const ManageBankAccountsModal = NiceModal.create(
  ({ parentAccID, merchantId, data }: ModalProps) => {
    const modal = useModal();
    const { bank_account_linking, manage_bank_account, money_transfers } =
      useEnterprisePermissions();
    const { bankAccountList } = useListParentBankAccounts(parentAccID, {
      enabled: bank_account_linking,
    });
    const { handleUpdateBankAccountSettings, isLoading } =
      useUpdateBankAccountSettings(merchantId);
    const { isMobileView } = useCustomTheme();
    const open = modal.visible;
    const schema = Yup.object().shape({
      allowBankAccounts: Yup.boolean(),
      allowTransfers: Yup.boolean(),
      isLinkAccount: Yup.boolean(),
    });

    const defaultValues = data;

    const methods = useForm<FormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      reset,
      watch,
      setValue,
      formState: { isDirty },
    } = methods;
    const values = watch();

    const handleCancel = () => {
      modal.hide();
    };

    useEffect(() => {
      if (open) {
        reset(data);
      }
    }, [open, parentAccID, merchantId]);

    const handleSwitchLink = (event: React.ChangeEvent<HTMLInputElement>) => {
      const linkedAccountID = event.target.checked
        ? data?.linkedAccountID || bankAccountList[0]?.id
        : null;

      setValue("linkedAccountID", linkedAccountID, {
        shouldDirty: true,
      });
      setValue("isLinkAccount", event.target.checked, { shouldDirty: true });
    };

    const handleCheckAccount = (id: number) => () => {
      setValue("linkedAccountID", id, {
        shouldDirty: true,
      });
    };

    const isChecked = (id: number) => values.linkedAccountID === id;

    const onSubmit: SubmitHandler<FormInputs> = (data) => {
      handleUpdateBankAccountSettings(data, () => {
        modal.hide();
        reset(data);
      });
    };

    const handleReset = () => {
      reset();
      modal.hide();
    };

    const actionStyle = {
      padding: "8px 24px",
      ...(isMobileView && {
        width: "50%",
      }),
    };

    const inputList = [
      {
        input: (
          <Stack direction="row" alignItems="center" gap={1}>
            <RHFSwitch name="allowBankAccounts" size="small" />
            <Label>Allow merchant to add bank accounts</Label>
          </Stack>
        ),
        hidden: !manage_bank_account,
      },
      {
        input: (
          <Stack direction="row" alignItems="center" gap={1}>
            <RHFSwitch name="allowTransfers" size="small" />
            <Label>Allow merchant to make money transfers</Label>
          </Stack>
        ),
        hidden: !money_transfers,
      },
      {
        input: (
          <Stack direction="row" alignItems="flex-start" gap={1}>
            <RHFSwitch
              name="isLinkAccount"
              size="small"
              onChange={handleSwitchLink}
            />
            <Stack spacing={1}>
              <Label>Link Provider bank accounts</Label>
              <Text
                variant="body"
                color={palette.gray[300]}
                lineHeight="16.8px"
                fontWeight="light"
              >
                Accounts owned by the Provider will also be available to this
                merchant
              </Text>
            </Stack>
          </Stack>
        ),
        hidden: bankAccountList?.length === 0 || !bank_account_linking,
      },
      {
        input: (
          <Stack spacing={1} sx={{ marginTop: "16px" }}>
            {bankAccountList?.map((bankAccount) => (
              <MerchantBankAccountItem
                key={bankAccount.id}
                handleCheckAccount={handleCheckAccount(bankAccount.id)}
                isChecked={isChecked(bankAccount.id)}
                {...bankAccount}
              />
            ))}
          </Stack>
        ),
        hidden: !values.isLinkAccount,
      },
    ];

    return (
      <EditMerchantBaseModal
        title="Bank Accounts"
        open={open}
        handleCancel={handleCancel}
        actions={
          <>
            <Button
              size="medium"
              background="tertiary"
              onClick={handleReset}
              disabled={!isDirty || isLoading}
              sx={actionStyle}
            >
              Discard changes
            </Button>
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="manage-bank-accounts-form"
              disabled={!isDirty || isLoading}
              sx={actionStyle}
            >
              Save
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          <Stack
            component="form"
            id="manage-bank-accounts-form"
            onSubmit={methods.handleSubmit(onSubmit)}
            spacing={2}
          >
            {inputList.map(({ input, hidden }, index) => (
              <Box key={index} sx={{ ...(hidden && { display: "none" }) }}>
                <FadeUpWrapper delay={100 * (index + 1)}>{input}</FadeUpWrapper>
              </Box>
            ))}
          </Stack>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

const Label = ({ children }: { children: React.ReactNode }) => (
  <Text
    variant="headline"
    fontSize="18px"
    lineHeight="21.6px"
    fontWeight="book"
    sx={{ letterSpacing: "-0.18px" }}
    color={palette.black[100]}
  >
    {children}
  </Text>
);

export default ManageBankAccountsModal;
