import React, { useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { useResetAction } from "@common/Error/ResetActionContext";
import { useErrorUtilities } from "@common/Error/useErrorUtilities";

type TCoordinates = {
  lat: number;
  lng: number;
};

type GoogleMapContainerProps = {
  address?: string;
  coordinates?: TCoordinates;
  viewMarker?: boolean;
  language?: string;
  region?: string;
  containerStyle?: React.CSSProperties;
  isLoaded: boolean;
  customOptions?: google.maps.MapOptions;
};

function GoogleMapContainer({
  address = "",
  coordinates,
  viewMarker = true,
  containerStyle,
  customOptions,
  isLoaded,
}: GoogleMapContainerProps) {
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const { registerResetAction } = useResetAction();
  const { callAll } = useErrorUtilities();
  const [markerPosition, setMarkerPosition] =
    React.useState<google.maps.LatLng | null>(null);
  const initialZoom = 16;

  const setMapCenter = React.useCallback(
    (map: google.maps.Map) => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results) {
          map.setCenter(results[0].geometry.location);
          viewMarker && setMarkerPosition(results[0].geometry.location);
        }
      });
    },
    [address],
  );

  const resetMap = () => setMap(null);
  const resetMarker = () => setMarkerPosition(null);

  useEffect(() => {
    const resetAction = () => callAll(resetMap, resetMarker);
    registerResetAction("GoogleMaps", resetAction);
  }, []);

  const onLoad = (map: google.maps.Map) => {
    if (address) setMapCenter(map);
    map.setZoom(initialZoom);
    setMap(map);
  };

  React.useEffect(() => {
    if (map && address !== "") {
      setMapCenter(map);
    }
  }, [address]);

  if (!isLoaded || (address === "" && !coordinates)) return <></>;

  return (
    <ErrorCatcher errorID="GoogleMap">
      <GoogleMap
        center={coordinates}
        mapContainerStyle={{
          width: "100%",
          height: "400px",
          borderRadius: "5px",
          ...containerStyle,
        }}
        options={{
          ...defaultOptions,
          ...customOptions,
        }}
        onLoad={onLoad}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </ErrorCatcher>
  );
}

const defaultOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  scaleControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    },
  ],
};

export default React.memo(GoogleMapContainer);
