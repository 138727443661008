import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, Stack } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

function Header({ handleClose }: { handleClose: () => void }) {
  const { isMobileView } = useCustomTheme();
  return (
    <Box>
      <FadeUpWrapper delay={50}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontWeight="regular" fontSize="18px" color="#403D3D">
            Action Required
          </Text>
          {!isMobileView && (
            <Box
              onClick={handleClose}
              component="button"
              sx={{ all: "unset", cursor: "pointer" }}
            >
              <X color="#8F8F8F" size={26} />
            </Box>
          )}
        </Stack>
      </FadeUpWrapper>

      <FadeUpWrapper delay={100}>
        <Text
          borderRadius="12px"
          mt="8px"
          fontSize="14px"
          fontWeight="book"
          color="#8F8F8F"
        >
          You have received this message because we require some additional
          information. Please refer to the message below.
        </Text>
      </FadeUpWrapper>
      <FadeUpWrapper delay={150}>
        <Text mt="16px" bgcolor="#FFF2E9" color="#C3641D" p="12px 16px">
          {noticeText}
        </Text>
      </FadeUpWrapper>
    </Box>
  );
}

export default Header;

const noticeText = ` Please note that you're limited to one reply, and you have the option to
        edit your message within 24 hours of sending it`;
