interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  stroke?: string;
  fill?: string;
}

const PlusIcon = ({
  width = 45,
  height = 45,
  stroke = "#B8B8B8",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6801 11.2859V34.0731M11.8281 22.6809H13.0943H14.3605M19.0626 22.6809H34.6158"
        stroke={stroke}
        strokeWidth="1.80851"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusIcon;
