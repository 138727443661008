import * as React from "react";
import { palette } from "@palette";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { showMessage } from "@common/Toast/ShowToast";
// assets
import { RefreshIcon } from "@assets/icons";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";

type IFormInputs = {
  recurrence: string;
  maxNumber: string;
};

const ProcessingMakeRecuring = NiceModal.create(() => {
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    modal.hide();
  };

  const schema = Yup.object({
    recurrence: Yup.string().required("This field is required"),
  });

  const defaultValues = {
    maxNumber: "",
    recurrence: "",
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue, watch } = methods;

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (isDesktop) {
      showMessage("Success", "", true, "Payment updated");
    } else {
      showMessage(
        "Success",
        <span style={{ fontSize: 16, fontWeight: "600" }}>
          Payment updated
        </span>,
        false,
      );
    }
    handleClose();
  };

  const normalizeNumberOfPayments = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");

    return currentValue;
  };

  React.useEffect(() => {
    setValue("maxNumber", normalizeNumberOfPayments(watch("maxNumber")));
  }, [watch("maxNumber")]);

  return (
    <Modal
      {...muiDialogV5(modal)}
      title="Make Recurring"
      onClose={handleClose}
      titleIcon={<RefreshIcon />}
      closeAfterTransition={true}
      actions={
        <>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            form="make-recurring"
            sx={{ color: palette.info.main }}
          >
            Confirm
          </Button>
        </>
      }
    >
      <Text variant="h5" fontWeight="semibold" color={palette.neutral[800]}>
        Antonio Silvestre, antonio@gmail.com, VISA 1111
      </Text>
      <Text fontSize={16} color={palette.neutral[600]} mt={1}>
        Choose recurrence and number of payments
      </Text>
      <FormProvider {...methods}>
        <Grid
          mt={2}
          mb={1}
          container
          spacing={2}
          component="form"
          id="make-recurring"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid item xs={12} sm={6}>
            <RHFSelect
              name="recurrence"
              placeholder="Select an option"
              label="Recurrence"
              options={[
                {
                  value: "Weekly",
                  label: "Weekly",
                },
                {
                  value: "Monthly",
                  label: "Monthly",
                },
                {
                  value: "Quarterly",
                  label: "Quarterly",
                },
                {
                  value: "Yearly",
                  label: "Yearly",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHFInput
              name="maxNumber"
              fullWidth
              placeholder="Leave blank for unlimited payments"
              label="Number of Payments"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
});

export default ProcessingMakeRecuring;
