import { PlusIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { SxProps } from "@mui/material";
import { Box } from "@mui/material";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useGetUserRole } from "@hooks/common/useGetUserRole";

const CreateTopicButton = ({
  handleOpenTopicModal,
  isInternalConversations,
  buttonText,
  sx,
  isEnterprise,
}: {
  handleOpenTopicModal: () => void;
  isInternalConversations?: boolean;
  sx?: SxProps;
  buttonText?: string;
  isEnterprise?: boolean;
}) => {
  const { canManageConversations } = useGetUserRole();

  const hiddenButton =
    buttonText != "Mark as solved" && !canManageConversations;

  /** Create topic button is hidden for new roles */
  if (hiddenButton) return null;

  return (
    <Box
      sx={{
        paddingBottom: 3,
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: `translateX(-50%)`,
        paddingTop: 3,
        margin: "0 auto",
        ...sx,
      }}
    >
      <Button
        onClick={handleOpenTopicModal}
        endIcon={
          isInternalConversations && (
            <PlusIcon width={18} height={18} fill="#fff" />
          )
        }
        size="medium"
      >
        <Text sx={{ fontWeight: 400 }}>
          {buttonText ||
            (isInternalConversations
              ? "New topic"
              : `Notify ${isEnterprise ? "Provider" : "Merchant"} `)}
        </Text>
      </Button>
    </Box>
  );
};

export default CreateTopicButton;
