import { useAccount } from "@hooks/common/useAccount";
import { useSecurityPassword } from "../modals/hooks/useSecurityPassword";

export const useProfileForms = (tab = "Account Details") => {
  const accountForm = useAccount();
  const securityForm = useSecurityPassword();

  const profileForms: any = {
    "Account Details": accountForm,
    Security: securityForm,
    Accessibility: accountForm,
  };

  return profileForms[tab] || {};
};
