import React from "react";
import { Text } from "@common/Text";
import { Box, Stack, BoxProps, styled } from "@mui/material";
import { palette } from "@palette";

export type TabListItem<T> = {
  name: T;
  LeftIcon?: JSX.Element;
  RightIcon?: JSX.Element;
  disabled?: boolean;
};

type TabListProps<T> = TabListItem<T> &
  BoxProps & {
    active: boolean;
  };

const TabBarItem = <T extends string>({
  name,
  active,
  LeftIcon,
  RightIcon,
  disabled = false,
  ...props
}: TabListProps<T>) => {
  const textColor = getTextColor(active, disabled);

  return (
    <StyledTabItem disabled={disabled} active={active} {...props}>
      <Stack direction="row" spacing={1} alignItems="center" height="32px">
        {LeftIcon &&
          React.cloneElement(LeftIcon, {
            color: textColor,
            path_fill: textColor,
            stroke: textColor,
            width: 24,
          })}
        <Text
          variant="headline"
          align="center"
          lineHeight="17px"
          color={textColor}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          {name}
        </Text>
        {RightIcon &&
          React.cloneElement(RightIcon, {
            color: textColor,
            path_fill: textColor,
            stroke: textColor,
            width: 24,
          })}
      </Stack>
    </StyledTabItem>
  );
};

const getTextColor = (active: boolean, disabled = false) => {
  if (disabled) return palette.liftedWhite[300];
  return active ? palette.black[200] : palette.gray[300];
};

const StyledTabItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled" && prop !== "active",
})(({ disabled, active }: { disabled: boolean; active: boolean }) => ({
  width: "100%",
  flexGrow: 1,
  padding: "8px",
  background: palette.liftedWhite.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  cursor: "default",
  "@media (max-width: 600px)": {
    height: "48px",
  },

  ...(disabled && {
    pointerEvents: 'none'
  }),

  ...(active && {
    background: palette.liftedWhite[200],
  }),

  ...(!disabled &&
    !active && {
      "&:hover": {
        backgroundColor: palette.liftedWhite[100],
        cursor: "pointer",
      },
    }),
}));

export default TabBarItem;
