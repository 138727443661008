// mui
import Stack from "@mui/material/Stack";
import {
  FilterWithSwitch,
  FilterWithAmount,
  ClearFiltersButton,
  FilterWithDate,
} from "@common/Filter";
// redux
import { useAppSelector } from "@redux/hooks";
import {
  addFilter,
  removeFilter,
  disableFilter,
  addAmountFilter,
  disableAmountFilter,
  selectFilters,
  addRangeFilter,
  disableRangeFilter,
  clearFilters,
  addDateFilter,
  disableDateFilter,
} from "@redux/slices/fundraisers";
import { HiddenComponent } from "containers/HiddenComponent";
import { listCreatedFilter } from "@components/Fundraisers/FundraisersList/FundraisersListFilters";
import { selectQueryFilters } from "@redux/slices/fundraisers";

const EventsListFilters = () => {
  const filters = useAppSelector(selectFilters);

  return (
    <Stack py={1} direction="row" flexWrap="wrap">
      <FilterWithDate
        title="created"
        options={listCreatedFilter}
        filters={filters}
        apply={addDateFilter}
        disable={disableDateFilter}
      />
      {/* <FilterWithAmount
        title="amount"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithRangeSlider
        title="conversion"
        filters={filters}
        apply={addRangeFilter}
        disable={disableRangeFilter}
      />
      <HiddenComponent>
        <FilterWithSwitch
          title="type"
          options={{
            Fundraiser: false,
            "Peer-2-Peer": false,
          }}
          filters={filters}
          apply={addFilter}
          remove={removeFilter}
          disable={disableFilter}
        />
      </HiddenComponent>
      <FilterWithSwitch
        title="status"
        options={{
          Coming: false,
          Ended: false,
        }}
        filters={filters}
        apply={addFilter}
        remove={removeFilter}
        disable={disableFilter}
      />

      <FilterWithAmount
        money={false}
        unit={<></>}
        title="transactions"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="visitors"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      /> */}

      <ClearFiltersButton
        clear={clearFilters}
        selector={selectQueryFilters}
      />
    </Stack>
  );
};

export default EventsListFilters;
