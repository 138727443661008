import { Signal, signal } from "@preact/signals-react";

export const colorParagraph = { name: "Paragraph", color: "#292727" };
export const colorHeading = { name: "Heading", color: "#111111" };

export const defaultHeadingStyle = {
  display: "block",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "40px",
  lineHeight: "100%",
  letterSpacing: "0px",
  overflowWrap: "anywhere",
  whiteSpace: "pre-wrap",
};

export const defaultDescriptionStyle = {
  display: "block",
  fontFamily: "Roboto",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "100%",
  letterSpacing: "0px",
  overflowWrap: "anywhere",
  whiteSpace: "pre-wrap",
};

export const defaultHeadingMetaAttributes = {
  "data-fontWeight": "Regular",
};

export const defaultDescriptionMetaAttributes = {
  "data-fontWeight": "Regular",
};

export const defaultHeadingTag = "h1";

export const defaultDescriptionTag = "p";

export const defaultHeadingsProps = {
  h1: {
    fontSize: 40,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h2: {
    fontSize: 34,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h3: {
    fontSize: 32,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h4: {
    fontSize: 24,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h5: {
    fontSize: 20,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h6: {
    fontSize: 18,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  body: {
    fontSize: 16,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorParagraph,
  },
};

export const defaultMobileHeadingsProps = {
  h1: {
    fontSize: 32,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h2: {
    fontSize: 30,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h3: {
    fontSize: 28,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h4: {
    fontSize: 24,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h5: {
    fontSize: 20,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  h6: {
    fontSize: 18,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorHeading,
  },
  body: {
    fontSize: 16,
    lineHeight: 100,
    letterSpacing: 0,
    fontFamily: {
      name: "Roboto",
      value: "Roboto, sans-serif",
    },
    fontWeight: {
      name: "Regular",
      value: 400,
    },
    textColor: colorParagraph,
  },
};

type keysType = ["h1", "h2", "h3", "h4", "h5", "h6", "body"];

export const headingKeys: keysType = Object.keys(
  defaultHeadingsProps,
) as keysType;

export type t = keyof typeof defaultHeadingsProps;

type ob = {
  [key in t]: {
    fontSize: Signal<number>;
    lineHeight: Signal<number>;
    letterSpacing: Signal<number>;
    fontFamily: Signal<{
      name: string;
      value: string;
    }>;
    fontWeight: Signal<{
      name: string;
      value: number;
    }>;
    textColor: Signal<{
      name: string;
      color: string;
    }>;
  };
};

const createHeadingSignal = (headingProps: any) => {
  return Object.entries(headingProps).reduce((acc, [key, v]: any) => {
    return {
      ...acc,
      [key]: Object.entries(v).reduce(
        (acc2, [k2, v2]) => ({ ...acc2, [k2]: signal(v2) }),
        {},
      ),
    };
  }, {} as ob);
};

export const headingsSignal = createHeadingSignal(defaultHeadingsProps);

export const mobileHeadingsSignal = createHeadingSignal(
  defaultMobileHeadingsProps,
);
