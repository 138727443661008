interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
}

export const GradientLockIcon = ({ width = 96, height = 96 }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 96 96"
      fill="none"
    >
      <path
        d="M48 52C46.7822 51.9858 45.5893 52.3449 44.5818 53.0292C43.5743 53.7135 42.8006 54.6899 42.3649 55.8272C41.9291 56.9645 41.8522 58.208 42.1446 59.3902C42.4369 60.5725 43.0845 61.6369 44 62.44V68C44 69.0609 44.4214 70.0783 45.1716 70.8284C45.9217 71.5786 46.9391 72 48 72C49.0609 72 50.0783 71.5786 50.8284 70.8284C51.5786 70.0783 52 69.0609 52 68V62.44C52.9155 61.6369 53.5631 60.5725 53.8554 59.3902C54.1478 58.208 54.0709 56.9645 53.6351 55.8272C53.1994 54.6899 52.4257 53.7135 51.4182 53.0292C50.4107 52.3449 49.2178 51.9858 48 52ZM68 36V28C68 22.6957 65.8929 17.6086 62.1421 13.8579C58.3914 10.1071 53.3043 8 48 8C42.6957 8 37.6086 10.1071 33.8579 13.8579C30.1071 17.6086 28 22.6957 28 28V36C24.8174 36 21.7652 37.2643 19.5147 39.5147C17.2643 41.7651 16 44.8174 16 48V76C16 79.1826 17.2643 82.2348 19.5147 84.4853C21.7652 86.7357 24.8174 88 28 88H68C71.1826 88 74.2348 86.7357 76.4853 84.4853C78.7357 82.2348 80 79.1826 80 76V48C80 44.8174 78.7357 41.7651 76.4853 39.5147C74.2348 37.2643 71.1826 36 68 36ZM36 28C36 24.8174 37.2643 21.7652 39.5147 19.5147C41.7652 17.2643 44.8174 16 48 16C51.1826 16 54.2348 17.2643 56.4853 19.5147C58.7357 21.7652 60 24.8174 60 28V36H36V28ZM72 76C72 77.0609 71.5786 78.0783 70.8284 78.8284C70.0783 79.5786 69.0609 80 68 80H28C26.9391 80 25.9217 79.5786 25.1716 78.8284C24.4214 78.0783 24 77.0609 24 76V48C24 46.9391 24.4214 45.9217 25.1716 45.1716C25.9217 44.4214 26.9391 44 28 44H68C69.0609 44 70.0783 44.4214 70.8284 45.1716C71.5786 45.9217 72 46.9391 72 48V76Z"
        fill="url(#paint0_linear_597_12982)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_597_12982"
          x1="48.048"
          y1="-43.4881"
          x2="113.965"
          y2="56.7087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB6DE0" />
          <stop offset="1" stopColor="#FFD2E2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
