import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type IProps = SvgIconProps & {
  width?: number | string;
  height?: number | string;
  fill?: string;
};

const DoubleDropDownIcon = ({
  width = 24,
  height = 24,
  fill = "#727179",
  ...rest
}: IProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      inheritViewBox
      htmlColor="transparent"
      xmlns="http://www.w3.org/2000/SvgIcon"
      {...rest}
    >
      <path
        d="M12.8921 7.22505L15.757 10.663C16.2998 11.3143 15.8366 12.3032 14.9888 12.3032L9.25889 12.3032C8.41106 12.3032 7.9479 11.3143 8.49067 10.663L11.3556 7.22505C11.7554 6.7453 12.4923 6.7453 12.8921 7.22505Z"
        fill={fill}
      />
      <path
        d="M11.3555 19.3814L8.49053 15.9434C7.94775 15.2921 8.41091 14.3033 9.25875 14.3033L14.9887 14.3033C15.8365 14.3033 16.2997 15.2921 15.7569 15.9434L12.8919 19.3814C12.4921 19.8611 11.7553 19.8612 11.3555 19.3814Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default DoubleDropDownIcon;
