type IconProps = {
  height?: number;
  width?: number;
  color?: string;
};

const CurrencyFeesIcon = ({
  width = 14,
  height = 2,
  color = "#9C9AA3",
}: IconProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2938 12.2734V16.9401C10.2938 18.0193 10.8919 19.0658 12.0298 19.8743C13.3828 20.8356 15.5589 21.4901 18.0218 21.4901C20.4847 21.4901 22.6609 20.8356 24.0138 19.8743C25.1517 19.0658 25.7498 18.0193 25.7498 16.9401V12.2734C25.7498 11.6936 25.2985 11.2234 24.7418 11.2234C24.1852 11.2234 23.7338 11.6936 23.7338 12.2734V16.9401C23.7338 17.4079 23.3709 17.7894 22.877 18.1394C21.7839 18.9164 20.0121 19.3901 18.0218 19.3901C16.0316 19.3901 14.2597 18.9164 13.1666 18.1394C12.6727 17.7894 12.3098 17.4079 12.3098 16.9401V12.2734C12.3098 11.6936 11.8585 11.2234 11.3018 11.2234C10.7452 11.2234 10.2938 11.6936 10.2938 12.2734Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2938 16.9401L10.2944 21.6068C10.2944 22.6859 10.8924 23.7324 12.0304 24.5409C13.3833 25.5023 15.5595 26.1568 18.0224 26.1568C20.4852 26.1568 22.6614 25.5023 24.0144 24.5409C25.1523 23.7324 25.7504 22.6859 25.7504 21.6068L25.7498 16.9401C25.7498 16.3603 25.299 15.8901 24.7424 15.8901C24.1857 15.8901 23.7338 16.3603 23.7338 16.9401L23.7344 21.6068C23.7344 22.0746 23.3715 22.4561 22.8776 22.8061C21.7844 23.5831 20.0126 24.0568 18.0224 24.0568C16.0321 24.0568 14.2603 23.5831 13.1672 22.8061C12.6732 22.4561 12.3104 22.0746 12.3104 21.6068L12.3098 16.9401C12.3098 16.3603 11.859 15.8901 11.3024 15.8901C10.7457 15.8901 10.2938 16.3603 10.2938 16.9401Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0229 7.72345C15.5611 7.72345 13.385 8.37795 12.032 9.33928C10.8941 10.1478 10.2938 11.1943 10.2938 12.2734C10.2938 13.3526 10.8941 14.3991 12.032 15.2076C13.385 16.1689 15.5611 16.8234 18.0229 16.8234C20.4847 16.8234 22.6608 16.1689 24.0138 15.2076C25.1517 14.3991 25.7498 13.3526 25.7498 12.2734C25.7498 11.1943 25.1517 10.1478 24.0138 9.33928C22.6608 8.37795 20.4847 7.72345 18.0229 7.72345ZM18.0229 9.82345C20.012 9.82345 21.7839 10.2983 22.8781 11.0741C23.372 11.4253 23.7338 11.8056 23.7338 12.2734C23.7338 12.7413 23.372 13.1216 22.8781 13.4728C21.7839 14.2486 20.012 14.7234 18.0229 14.7234C16.0338 14.7234 14.2619 14.2486 13.1677 13.4728C12.6738 13.1216 12.3098 12.7413 12.3098 12.2734C12.3098 11.8056 12.6738 11.4253 13.1677 11.0741C14.2619 10.2983 16.0338 9.82345 18.0229 9.82345Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33545 5.83345V10.5001C1.33545 11.3821 1.72633 12.2314 2.48569 12.9536C3.38281 13.8076 4.84777 14.5006 6.62969 14.8319C7.17737 14.9334 7.70041 14.5531 7.79897 13.9826C7.89641 13.4109 7.53017 12.8661 6.98249 12.7646C5.84681 12.5534 4.86681 12.1778 4.17577 11.6761C3.69977 11.3319 3.35145 10.9574 3.35145 10.5001V5.83345C3.35145 5.25361 2.90009 4.78345 2.34345 4.78345C1.78681 4.78345 1.33545 5.25361 1.33545 5.83345Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33545 10.5001V15.1668C1.33545 16.1526 1.82937 17.1058 2.78025 17.8793C3.90361 18.7939 5.72585 19.4788 7.86393 19.6654C8.41833 19.7133 8.90553 19.2839 8.95145 18.7064C8.99849 18.1289 8.58633 17.6203 8.03193 17.5724C6.35193 17.4266 4.90377 16.9424 4.02009 16.2238C3.63257 15.9088 3.35145 15.5693 3.35145 15.1668L3.35145 10.5001C3.35145 9.92028 2.90009 9.45011 2.34345 9.45011C1.78681 9.45011 1.33545 9.92028 1.33545 10.5001ZM8.01849 8.23795C6.34409 8.08978 4.89929 7.60561 4.01897 6.88811C3.63145 6.57311 3.35145 6.23478 3.35145 5.83345C3.35145 5.36561 3.71433 4.98528 4.20825 4.63411C5.30249 3.85828 7.07433 3.38345 9.06345 3.38345C11.0526 3.38345 12.8244 3.85828 13.9186 4.63411C14.4126 4.98528 14.7754 5.36561 14.7754 5.83345C14.7754 6.41328 15.2268 6.88345 15.7834 6.88345C16.3401 6.88345 16.7914 6.41328 16.7914 5.83345C16.7914 4.75428 16.1922 3.70778 15.0543 2.89928C13.7014 1.93795 11.5252 1.28345 9.06345 1.28345C6.60169 1.28345 4.42553 1.93795 3.07257 2.89928C1.93465 3.70778 1.33545 4.75428 1.33545 5.83345C1.33545 6.81811 1.82825 7.77011 2.77689 8.54245C3.89801 9.45595 5.71689 10.1419 7.84825 10.3298C8.40265 10.3788 8.89097 9.95061 8.93801 9.37311C8.98505 8.79561 8.57289 8.28695 8.01849 8.23795Z"
        fill={color}
      />
    </svg>
  );
};

export default CurrencyFeesIcon;
