type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const EditIcon = ({
  width = 24,
  height = 25,
  fill = "#8F8F8F",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2719 6.22883C14.8024 5.69839 15.5218 5.40039 16.2719 5.40039C17.834 5.40039 19.1004 6.66673 19.1004 8.22885C19.1004 8.979 18.8024 9.69843 18.272 10.2289L15.1204 13.3804C14.8861 13.6147 14.5062 13.6147 14.2719 13.3804C14.0376 13.1461 14.0376 12.7662 14.2719 12.5319L16.2731 10.5307L13.9701 8.22771L6.10039 16.0974V18.4004H8.40338L11.3792 15.4246C11.6135 15.1903 11.9934 15.1903 12.2277 15.4246C12.462 15.6589 12.462 16.0388 12.2277 16.2731L9.07617 19.4247C8.96365 19.5372 8.81104 19.6004 8.65191 19.6004H5.50039C5.16902 19.6004 4.90039 19.3318 4.90039 19.0004V15.8489C4.90039 15.6897 4.9636 15.5371 5.07613 15.4246L13.5458 6.95491L14.2719 6.22883ZM14.8186 7.37918L17.1216 9.68217L17.4234 9.38034C17.7288 9.07495 17.9004 8.66074 17.9004 8.22885C17.9004 7.32948 17.1713 6.60039 16.2719 6.60039C15.84 6.60039 15.4258 6.77196 15.1204 7.07735L14.8186 7.37918Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export default EditIcon;
