import { SvgIcon, SvgIconProps } from "@mui/material";

const SafariIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75Z"
        fill="url(#paint0_linear_2874_20979)"
      />
      <path
        d="M10 18.0937C14.4701 18.0937 18.0938 14.47 18.0938 9.99999C18.0938 5.52993 14.4701 1.90624 10 1.90624C5.52995 1.90624 1.90625 5.52993 1.90625 9.99999C1.90625 14.47 5.52995 18.0937 10 18.0937Z"
        fill="url(#paint1_radial_2874_20979)"
      />
      <path
        d="M10.0137 3.87502C9.95897 3.87502 9.91113 3.83402 9.91113 3.77933V2.35061C9.91113 2.29592 9.95897 2.25492 10.0137 2.25492C10.0683 2.25492 10.1162 2.29592 10.1162 2.35061V3.77933C10.1093 3.83402 10.0683 3.87502 10.0137 3.87502Z"
        fill="#F3F3F3"
      />
      <path
        d="M10.0137 3.88184C9.95214 3.88184 9.9043 3.834 9.9043 3.77931V2.3506C9.9043 2.29592 9.95214 2.24807 10.0137 2.24807C10.0752 2.24807 10.123 2.29592 10.123 2.3506V3.77931C10.123 3.834 10.0684 3.88184 10.0137 3.88184ZM10.0137 2.26169C9.96583 2.26169 9.91799 2.30276 9.91799 2.3506V3.77931C9.91799 3.82716 9.95898 3.86823 10.0137 3.86823C10.0615 3.86823 10.1093 3.82716 10.1093 3.77931V2.3506C10.1026 2.30276 10.0615 2.26169 10.0137 2.26169Z"
        fill="white"
      />
      <path
        d="M10.6972 3.10938C10.6426 3.10254 10.6016 3.06154 10.6016 3.00685L10.6494 2.37105C10.6562 2.31643 10.7041 2.27537 10.7588 2.28221C10.8135 2.28905 10.8545 2.33012 10.8545 2.38481L10.8066 3.02054C10.7998 3.07523 10.7519 3.11623 10.6972 3.10945V3.10938Z"
        fill="#F3F3F3"
      />
      <path
        d="M10.6973 3.11623C10.6357 3.10939 10.5947 3.06154 10.5947 3.00686L10.6426 2.37107C10.6494 2.31645 10.6973 2.26861 10.7588 2.27538C10.8203 2.28223 10.8613 2.33014 10.8613 2.38476L10.8135 3.02055C10.8066 3.08208 10.7588 3.12308 10.6973 3.11623ZM10.7588 2.28907C10.7109 2.28907 10.663 2.32329 10.663 2.37114L10.6152 3.00693C10.6152 3.0547 10.6494 3.09577 10.7041 3.10254C10.7519 3.10254 10.7998 3.06839 10.7998 3.02055L10.8476 2.38476C10.8476 2.33691 10.8066 2.29591 10.7587 2.28907H10.7588Z"
        fill="white"
      />
      <path
        d="M11.2099 3.99809C11.1551 3.9844 11.121 3.93656 11.1278 3.88187L11.4081 2.48045C11.4218 2.42583 11.4696 2.39161 11.5243 2.4053C11.579 2.41899 11.6131 2.46683 11.6063 2.52152L11.326 3.92294C11.3192 3.97071 11.2645 4.00493 11.2098 3.99809H11.2099Z"
        fill="#F3F3F3"
      />
      <path
        d="M11.2099 4.00489C11.1483 3.9912 11.1141 3.93651 11.121 3.88182L11.4012 2.48042C11.4149 2.4258 11.4696 2.38474 11.5311 2.39842C11.5926 2.41211 11.6268 2.4668 11.62 2.52149L11.3397 3.92289C11.326 3.97751 11.2713 4.01173 11.2099 4.00489ZM11.5311 2.41211C11.4832 2.40527 11.4354 2.43258 11.4217 2.48049L11.1415 3.88189C11.1347 3.92967 11.162 3.97751 11.2167 3.99127C11.2645 3.99804 11.3123 3.97066 11.326 3.92282L11.6063 2.52149C11.6131 2.4668 11.579 2.4258 11.5311 2.41211Z"
        fill="white"
      />
      <path
        d="M12.0302 3.38285C12.0172 3.37972 12.0049 3.37404 11.9941 3.36616C11.9832 3.35827 11.9741 3.34833 11.9671 3.3369C11.9601 3.32547 11.9554 3.31278 11.9534 3.29955C11.9513 3.28631 11.9519 3.27281 11.955 3.25979L12.1259 2.64461C12.1395 2.58992 12.1942 2.56254 12.2489 2.57623C12.3036 2.58992 12.3378 2.64461 12.3242 2.6993L12.1532 3.31455C12.1395 3.36916 12.0849 3.39654 12.0302 3.38285Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.0302 3.38967C11.9756 3.37599 11.9346 3.31445 11.9551 3.25984L12.126 2.64452C12.1396 2.58991 12.2011 2.55568 12.2558 2.56937C12.3105 2.58306 12.3515 2.64459 12.331 2.69921L12.1601 3.31452C12.1464 3.36914 12.0918 3.40336 12.0303 3.38967H12.0302ZM12.2558 2.58991C12.208 2.57622 12.1533 2.6036 12.1396 2.65144L11.9688 3.26661C11.9551 3.31445 11.9824 3.36237 12.0371 3.37599C12.0849 3.38967 12.1396 3.36237 12.1533 3.31452L12.3242 2.69921C12.331 2.65144 12.3037 2.6036 12.2558 2.58984V2.58991Z"
        fill="white"
      />
      <path
        d="M12.3652 4.34669C12.3105 4.32615 12.2901 4.26462 12.3105 4.21678L12.8574 2.89744C12.8778 2.8496 12.9394 2.82222 12.9872 2.8496C13.042 2.87006 13.0625 2.93167 13.042 2.97944L12.4951 4.29877C12.4746 4.34669 12.413 4.374 12.3653 4.34669H12.3652Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.3653 4.35361C12.339 4.3436 12.3178 4.32358 12.3062 4.29795C12.2947 4.27232 12.2938 4.24315 12.3037 4.21686L12.8506 2.8975C12.8711 2.84281 12.9327 2.82228 12.9942 2.84281C13.0205 2.85283 13.0417 2.87284 13.0532 2.89848C13.0648 2.92411 13.0657 2.95327 13.0557 2.97957L12.5089 4.29892C12.4815 4.35361 12.4199 4.38092 12.3653 4.35361ZM12.9874 2.8565C12.9395 2.83604 12.8848 2.8565 12.8643 2.90435L12.3174 4.2237C12.2969 4.27154 12.3243 4.31939 12.3721 4.33992C12.4199 4.36038 12.4746 4.33992 12.4952 4.29208L13.0421 2.97273C13.0557 2.93166 13.0352 2.87697 12.9874 2.8565Z"
        fill="white"
      />
      <path
        d="M13.2883 3.90923C13.2403 3.88185 13.2198 3.82716 13.2403 3.77932L13.5274 3.21199C13.5547 3.16408 13.6094 3.14355 13.6642 3.17093C13.712 3.1983 13.7325 3.25299 13.712 3.30076L13.4249 3.86816C13.3975 3.916 13.3429 3.93661 13.2882 3.90923H13.2883Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.288 3.91608C13.2332 3.8887 13.2128 3.82717 13.2401 3.77248L13.5272 3.20515C13.5545 3.15039 13.6161 3.12993 13.6707 3.15724C13.7254 3.18462 13.746 3.24615 13.7186 3.30083L13.4315 3.86817C13.4041 3.92286 13.3426 3.94339 13.2879 3.91601L13.288 3.91608ZM13.664 3.17093C13.6161 3.15046 13.5614 3.16408 13.5409 3.21192L13.2538 3.77933C13.2333 3.82033 13.2538 3.87501 13.2948 3.90239C13.3426 3.92286 13.3973 3.90924 13.4178 3.8614L13.705 3.29399C13.7254 3.25299 13.705 3.1983 13.664 3.17093Z"
        fill="white"
      />
      <path
        d="M13.4248 4.92094C13.3769 4.88678 13.3632 4.82525 13.3974 4.78425L14.1904 3.60158C14.2177 3.55374 14.2793 3.54689 14.3271 3.57427C14.375 3.60843 14.3886 3.66996 14.3544 3.71096L13.5614 4.89363C13.5341 4.94147 13.4726 4.95516 13.4248 4.92094Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.4251 4.92775C13.3772 4.8936 13.3635 4.82523 13.3909 4.77738L14.1839 3.59475C14.218 3.54691 14.2795 3.53322 14.3342 3.56744C14.3821 3.60159 14.3958 3.66997 14.3685 3.71781L13.5754 4.90045C13.5413 4.94829 13.4729 4.96191 13.4251 4.92775ZM14.3274 3.58113C14.2865 3.55375 14.2249 3.5606 14.1975 3.60159L13.4045 4.78423C13.3772 4.82516 13.3908 4.87991 13.4319 4.90722C13.4729 4.9346 13.5344 4.92775 13.5617 4.88676L14.3548 3.71097C14.3821 3.66997 14.3685 3.60844 14.3274 3.58113Z"
        fill="white"
      />
      <path
        d="M14.4226 4.66802C14.3817 4.6338 14.368 4.57226 14.4022 4.53126L14.7986 4.03223C14.8328 3.9913 14.8943 3.98439 14.9353 4.01861C14.9764 4.05277 14.99 4.1143 14.9558 4.1553L14.5594 4.65433C14.5252 4.69533 14.4637 4.70217 14.4227 4.66802H14.4226Z"
        fill="#F3F3F3"
      />
      <path
        d="M14.4159 4.67483C14.368 4.64061 14.3612 4.57223 14.3954 4.52438L14.7918 4.02541C14.826 3.97749 14.8944 3.97065 14.9422 4.01172C14.99 4.04587 14.9969 4.11425 14.9627 4.16209L14.5662 4.66114C14.5321 4.70899 14.4637 4.71583 14.4159 4.67483ZM14.9285 4.02534C14.8876 3.99118 14.8328 3.99803 14.7987 4.03903L14.4022 4.53807C14.3749 4.57907 14.3817 4.63376 14.4227 4.66792C14.4637 4.70214 14.5184 4.6953 14.5526 4.6543L14.949 4.15525C14.9764 4.11425 14.9695 4.05272 14.9286 4.02541L14.9285 4.02534Z"
        fill="white"
      />
      <path
        d="M14.3545 5.69339C14.3135 5.6524 14.3135 5.59087 14.3545 5.55664L15.3661 4.55182C15.4072 4.51082 15.4686 4.5176 15.5029 4.55182C15.537 4.58597 15.5439 4.65435 15.5029 4.6885L14.4981 5.69339C14.457 5.73439 14.3955 5.73439 14.3545 5.69339Z"
        fill="#F3F3F3"
      />
      <path
        d="M14.3477 5.70021C14.3279 5.68015 14.3169 5.65315 14.3169 5.62503C14.3169 5.5969 14.3279 5.5699 14.3477 5.54984L15.3594 4.54494C15.3794 4.5252 15.4064 4.51414 15.4346 4.51414C15.4627 4.51414 15.4898 4.5252 15.5098 4.54494C15.5295 4.565 15.5406 4.592 15.5406 4.62013C15.5406 4.64825 15.5295 4.67525 15.5098 4.69531L14.498 5.70014C14.478 5.71986 14.451 5.73091 14.4228 5.73091C14.3947 5.73091 14.3677 5.71986 14.3477 5.70014V5.70021ZM15.503 4.55178C15.4688 4.51756 15.4073 4.51756 15.3731 4.55178L14.3613 5.55662C14.3271 5.59084 14.3271 5.65237 14.3613 5.68652C14.3955 5.72075 14.457 5.72075 14.4912 5.68652L15.503 4.68169C15.5371 4.64747 15.5371 4.59278 15.503 4.55178Z"
        fill="white"
      />
      <path
        d="M15.3798 5.63868C15.3456 5.59768 15.3456 5.53615 15.3866 5.50193L15.872 5.09182C15.9129 5.05767 15.9745 5.06444 16.0087 5.10551C16.0428 5.1465 16.0428 5.20804 16.0019 5.24219L15.5165 5.65237C15.4755 5.68652 15.4139 5.67967 15.3798 5.63868Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.3732 5.64552C15.3322 5.59768 15.3391 5.5293 15.3801 5.49515L15.8654 5.08496C15.9064 5.04396 15.9748 5.05081 16.0158 5.09865C16.0568 5.14649 16.05 5.21487 16.009 5.24902L15.5236 5.65921C15.4827 5.70014 15.4142 5.69336 15.3733 5.64552H15.3732ZM16.0021 5.1055C15.9679 5.0645 15.9132 5.0645 15.8723 5.09181L15.3869 5.50199C15.3528 5.53615 15.3459 5.59083 15.3801 5.63183C15.4142 5.67283 15.469 5.67283 15.5099 5.64552L15.9953 5.23533C16.0363 5.20118 16.0363 5.14649 16.0021 5.1055Z"
        fill="white"
      />
      <path
        d="M15.1064 6.62308C15.0722 6.57524 15.0859 6.5137 15.1337 6.48632L16.3231 5.69338C16.371 5.666 16.4325 5.67969 16.4598 5.72076C16.494 5.76861 16.4803 5.83014 16.4325 5.85745L15.2431 6.65039C15.202 6.67777 15.1405 6.66408 15.1064 6.62308Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.0995 6.62308C15.0654 6.57523 15.0791 6.50686 15.1269 6.4727L16.3163 5.67971C16.3642 5.64555 16.4326 5.65924 16.4667 5.71393C16.5009 5.76177 16.4872 5.83015 16.4394 5.86423L15.25 6.6573C15.2021 6.69152 15.1338 6.67783 15.0995 6.62315V6.62308ZM16.453 5.72762C16.4257 5.68655 16.371 5.67286 16.33 5.70024L15.1405 6.49317C15.0996 6.52055 15.0858 6.57523 15.1201 6.62308C15.1474 6.66407 15.2021 6.67776 15.2431 6.65045L16.4326 5.85746C16.4736 5.82331 16.4804 5.76855 16.453 5.72762Z"
        fill="white"
      />
      <path
        d="M16.1253 6.76666C16.0979 6.71875 16.1116 6.65729 16.1594 6.62991L16.7132 6.31548C16.761 6.2881 16.8225 6.30864 16.8499 6.35641C16.8772 6.40432 16.8635 6.46578 16.8157 6.49316L16.262 6.80766C16.2141 6.83496 16.1526 6.8145 16.1252 6.76659L16.1253 6.76666Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.1184 6.77346C16.0911 6.71877 16.1048 6.65724 16.1526 6.62993L16.7063 6.31548C16.7542 6.2881 16.8226 6.30864 16.8499 6.35641C16.8772 6.41117 16.8636 6.47271 16.8157 6.50002L16.262 6.81453C16.2141 6.84184 16.1458 6.82131 16.1184 6.77346ZM16.843 6.36333C16.8157 6.31548 16.761 6.30179 16.72 6.3291L16.1663 6.64362C16.1253 6.67093 16.1116 6.72562 16.1321 6.76662C16.1594 6.81446 16.2141 6.82815 16.2551 6.80084L16.8089 6.48633C16.8499 6.46586 16.8636 6.41117 16.843 6.36333Z"
        fill="white"
      />
      <path
        d="M15.667 7.67582C15.6465 7.62113 15.667 7.56644 15.7216 7.54598L17.041 6.99911C17.0888 6.97858 17.1504 7.00589 17.1708 7.05373C17.1913 7.10842 17.1708 7.1631 17.1161 7.18357L15.7969 7.73043C15.749 7.75096 15.6875 7.73043 15.667 7.67582Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.6601 7.68258C15.6397 7.62796 15.6601 7.56643 15.7148 7.53906L17.0341 6.99221C17.0604 6.9823 17.0896 6.98321 17.1152 6.99474C17.1408 7.00627 17.1608 7.02749 17.1708 7.05374C17.1914 7.10842 17.1708 7.16995 17.1161 7.19726L15.7969 7.74411C15.7706 7.75404 15.7414 7.75315 15.7158 7.74163C15.6902 7.73011 15.6701 7.7089 15.6601 7.68265V7.68258ZM17.1572 7.06058C17.1367 7.01274 17.082 6.99221 17.041 7.00589L15.7217 7.55275C15.6738 7.57321 15.6533 7.62796 15.6738 7.6758C15.6943 7.72365 15.749 7.74418 15.79 7.73049L17.1093 7.18364C17.1572 7.16318 17.1777 7.10842 17.1572 7.06058Z"
        fill="white"
      />
      <path
        d="M16.6308 8.01764C16.6104 7.96289 16.6377 7.90827 16.6923 7.88773L17.2938 7.68945C17.3417 7.66898 17.4032 7.70314 17.4169 7.75098C17.4374 7.80567 17.4101 7.86035 17.3554 7.88089L16.7539 8.07918C16.706 8.09964 16.6513 8.07233 16.6308 8.01757V8.01764Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.6239 8.02441C16.6034 7.96972 16.6307 7.90819 16.6923 7.88773L17.2938 7.68943C17.3485 7.66897 17.41 7.70312 17.4305 7.75788C17.451 7.8125 17.4237 7.87404 17.3621 7.89457L16.7606 8.09286C16.706 8.10648 16.6444 8.0791 16.6239 8.02441ZM17.4168 7.76473C17.4031 7.71681 17.3485 7.68943 17.3006 7.70312L16.6991 7.90142C16.6513 7.91503 16.6307 7.96972 16.6444 8.01764C16.6581 8.06548 16.7128 8.09279 16.7606 8.0791L17.3621 7.88088C17.41 7.86042 17.4305 7.8125 17.4168 7.76466V7.76473Z"
        fill="white"
      />
      <path
        d="M16.0157 8.83107C16.002 8.77639 16.0362 8.7217 16.0909 8.71486L17.4922 8.44145C17.5468 8.42776 17.5947 8.46875 17.6083 8.52344C17.622 8.57812 17.5879 8.6328 17.5332 8.63965L16.1319 8.91306C16.0772 8.9199 16.0225 8.88575 16.0156 8.83107H16.0157Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.0017 8.83114C15.988 8.7696 16.029 8.71491 16.0837 8.70122L17.4851 8.42776C17.5398 8.41407 17.5945 8.45514 17.6081 8.51668C17.6218 8.57815 17.5808 8.63284 17.5261 8.64653L16.1247 8.91999C16.0701 8.93368 16.0153 8.89268 16.0017 8.83107V8.83114ZM17.5945 8.52345C17.5876 8.47561 17.5398 8.44145 17.4919 8.4483L16.0905 8.72176C16.0427 8.7286 16.0085 8.78322 16.0221 8.83114C16.029 8.87899 16.0768 8.91314 16.1247 8.9063L17.5261 8.63284C17.5739 8.61915 17.6081 8.5713 17.5945 8.52345Z"
        fill="white"
      />
      <path
        d="M16.8905 9.35745C16.8837 9.30275 16.9178 9.2549 16.9725 9.24806L17.6014 9.17283C17.6561 9.16605 17.704 9.20705 17.7108 9.26175C17.7176 9.31644 17.6834 9.36429 17.6288 9.37114L16.9998 9.4463C16.952 9.45315 16.8973 9.41214 16.8904 9.35745H16.8905Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.884 9.35743C16.8772 9.2959 16.9182 9.24122 16.9729 9.23437L17.6017 9.15923C17.6564 9.15238 17.7111 9.19338 17.7179 9.25491C17.7248 9.31644 17.6838 9.37112 17.6291 9.37796L17.0002 9.45311C16.9456 9.45995 16.8908 9.41896 16.884 9.35743ZM17.7111 9.26175C17.7043 9.21391 17.6565 9.17285 17.6086 9.17969L16.9797 9.25491C16.9319 9.26175 16.8977 9.30959 16.9045 9.35743C16.9114 9.40527 16.9592 9.44634 17.007 9.43949L17.6359 9.36428C17.6838 9.35743 17.7179 9.30959 17.7112 9.26175H17.7111Z"
        fill="white"
      />
      <path
        d="M16.125 10.0205C16.125 9.96585 16.1659 9.91801 16.2207 9.91801H17.6494C17.7041 9.91801 17.7451 9.96585 17.7451 10.0205C17.7451 10.0752 17.7041 10.1231 17.6494 10.1231H16.2207C16.166 10.1231 16.125 10.0752 16.125 10.0205Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.1182 10.0205C16.1182 9.95901 16.166 9.91117 16.2207 9.91117H17.6494C17.7041 9.91117 17.7519 9.95901 17.7519 10.0205C17.7519 10.0821 17.7041 10.1299 17.6494 10.1299H16.2207C16.166 10.1299 16.1182 10.0821 16.1182 10.0205ZM17.7383 10.0274C17.7383 9.97954 17.6973 9.9317 17.6493 9.9317H16.2208C16.1729 9.9317 16.1319 9.97263 16.1319 10.0274C16.1319 10.0752 16.1729 10.1231 16.2208 10.1231H17.6495C17.6973 10.1162 17.7383 10.0752 17.7383 10.0274H17.7383Z"
        fill="white"
      />
      <path
        d="M16.8839 10.7109C16.8906 10.6563 16.9317 10.6153 16.9864 10.6153L17.6221 10.6631C17.6767 10.6699 17.7177 10.7178 17.7109 10.7725C17.7041 10.8272 17.6631 10.8682 17.6084 10.8682L16.9727 10.8203C16.9249 10.8135 16.8838 10.7656 16.8838 10.7109H16.8839Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.877 10.711C16.8838 10.6494 16.9316 10.6084 16.9863 10.6084L17.6222 10.6563C17.6768 10.6631 17.7247 10.711 17.7178 10.7725C17.711 10.834 17.6631 10.875 17.6084 10.875L16.9726 10.8272C16.918 10.8203 16.877 10.7656 16.877 10.711ZM17.711 10.7725C17.7178 10.7246 17.6768 10.6768 17.629 10.6768L16.9931 10.629C16.9453 10.629 16.9043 10.6631 16.8974 10.7179C16.8906 10.7656 16.9316 10.8135 16.9795 10.8135L17.6153 10.8613C17.6631 10.8613 17.7042 10.8204 17.711 10.7725Z"
        fill="white"
      />
      <path
        d="M16.0019 11.21C16.0155 11.1553 16.0634 11.1211 16.1181 11.128L17.5194 11.4083C17.5741 11.4219 17.6083 11.4697 17.5947 11.5244C17.581 11.5791 17.5331 11.6133 17.4784 11.6065L16.077 11.3262C16.0292 11.3194 15.995 11.2647 16.0019 11.21Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.995 11.21C16.0087 11.1484 16.0634 11.1143 16.1181 11.1211L17.5195 11.4014C17.5742 11.415 17.6152 11.4697 17.6015 11.5312C17.5879 11.5927 17.5332 11.627 17.4785 11.6201L16.077 11.3399C16.0223 11.3262 15.9882 11.2715 15.995 11.21V11.21ZM17.5879 11.5312C17.5948 11.4834 17.5674 11.4355 17.5195 11.4219L16.118 11.1416C16.0702 11.1348 16.0223 11.1621 16.0086 11.2168C16.0019 11.2646 16.0292 11.3125 16.0771 11.3262L17.4785 11.6064C17.5332 11.6133 17.5742 11.5791 17.5879 11.5312Z"
        fill="white"
      />
      <path
        d="M16.6173 12.0303C16.6204 12.0173 16.6261 12.005 16.634 11.9942C16.6419 11.9834 16.6518 11.9742 16.6632 11.9672C16.6747 11.9602 16.6873 11.9556 16.7006 11.9535C16.7138 11.9514 16.7273 11.952 16.7403 11.9551L17.3555 12.126C17.4102 12.1397 17.4375 12.1944 17.4239 12.2491C17.4208 12.2621 17.4151 12.2744 17.4072 12.2852C17.3994 12.296 17.3894 12.3052 17.378 12.3122C17.3666 12.3192 17.3539 12.3238 17.3406 12.3259C17.3274 12.328 17.3139 12.3274 17.3009 12.3243L16.6856 12.1534C16.6309 12.1397 16.6036 12.085 16.6172 12.0303H16.6173Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.6104 12.0303C16.6241 11.9756 16.6856 11.9346 16.7403 11.9552L17.3555 12.126C17.4102 12.1396 17.4445 12.2012 17.4308 12.2559C17.4171 12.3105 17.3555 12.3515 17.3009 12.3311L16.6856 12.1602C16.6309 12.1465 16.5967 12.0918 16.6105 12.0304L16.6104 12.0303ZM17.4102 12.2559C17.4239 12.208 17.3965 12.1533 17.3487 12.1396L16.7335 11.9688C16.6856 11.9551 16.6378 11.9825 16.6241 12.0371C16.6105 12.085 16.6378 12.1396 16.6856 12.1533L17.3009 12.3242C17.3487 12.3311 17.3965 12.3038 17.4102 12.2559Z"
        fill="white"
      />
      <path
        d="M15.6533 12.3584C15.6738 12.3037 15.7354 12.2832 15.7832 12.3037L17.1025 12.8506C17.1504 12.871 17.1777 12.9326 17.1572 12.9804C17.1367 13.0352 17.0751 13.0556 17.0273 13.0352L15.708 12.4883C15.6533 12.4679 15.6328 12.4063 15.6533 12.3585V12.3584Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.6465 12.3516C15.6565 12.3253 15.6765 12.3041 15.7021 12.2925C15.7277 12.281 15.7569 12.2801 15.7832 12.29L17.1025 12.8369C17.1572 12.8575 17.1777 12.919 17.1572 12.9805C17.1472 13.0068 17.1272 13.028 17.1015 13.0396C17.0759 13.0511 17.0467 13.052 17.0204 13.0421L15.7011 12.4952C15.6465 12.4678 15.626 12.4063 15.6465 12.3516ZM17.1435 12.9737C17.164 12.9259 17.1435 12.8712 17.0957 12.8506L15.7764 12.3037C15.7285 12.2833 15.6807 12.3106 15.6601 12.3584C15.6397 12.4063 15.6601 12.461 15.708 12.4815L17.0273 13.0284C17.0751 13.0421 17.1298 13.0215 17.1435 12.9737Z"
        fill="white"
      />
      <path
        d="M16.0977 13.2813C16.1251 13.2334 16.1797 13.2129 16.2275 13.2334L16.7949 13.5206C16.8427 13.541 16.8632 13.6026 16.8359 13.6573C16.8085 13.7051 16.7539 13.7257 16.706 13.7051L16.1388 13.418C16.0909 13.3906 16.0703 13.3291 16.0977 13.2813Z"
        fill="#F3F3F3"
      />
      <path
        d="M16.0907 13.2744C16.118 13.2197 16.1796 13.1993 16.2343 13.2266L16.8016 13.5137C16.8563 13.5411 16.8768 13.6026 16.8495 13.6572C16.8221 13.7119 16.7605 13.7325 16.7059 13.7051L16.1386 13.418C16.0838 13.3976 16.0633 13.3291 16.0907 13.2744ZM16.829 13.6504C16.8495 13.6026 16.8358 13.5479 16.7879 13.5274L16.2206 13.2403C16.1795 13.2198 16.1249 13.2403 16.0975 13.2813C16.077 13.3291 16.0907 13.3838 16.1385 13.4043L16.7059 13.6915C16.7538 13.7119 16.8085 13.6983 16.829 13.6504Z"
        fill="white"
      />
      <path
        d="M15.0927 13.4112C15.1269 13.3633 15.1816 13.3496 15.2294 13.3838L16.4189 14.1768C16.4668 14.2041 16.4736 14.2657 16.4463 14.3135C16.4121 14.3614 16.3574 14.375 16.3095 14.3408L15.1201 13.5478C15.0722 13.5137 15.0585 13.4522 15.0927 13.4112Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.0858 13.4043C15.12 13.3565 15.1884 13.3428 15.2363 13.3702L16.4257 14.1631C16.4736 14.1972 16.4873 14.2588 16.4531 14.3134C16.4189 14.3614 16.3505 14.375 16.3027 14.3477L15.1132 13.5547C15.0585 13.5205 15.0517 13.4521 15.0858 13.4043ZM16.4326 14.3066C16.4599 14.2657 16.4531 14.2041 16.4121 14.1768L15.2226 13.3838C15.1816 13.3565 15.1269 13.3702 15.0995 13.4111C15.0722 13.4521 15.079 13.5137 15.12 13.541L16.3095 14.334C16.3505 14.3614 16.4052 14.3477 16.4326 14.3067V14.3066Z"
        fill="white"
      />
      <path
        d="M15.3456 14.4024C15.3798 14.3613 15.4413 14.3477 15.4823 14.3819L15.9814 14.7715C16.0224 14.8057 16.0292 14.8672 15.995 14.9083C15.9609 14.9492 15.8994 14.963 15.8583 14.9287L15.3592 14.5391C15.3183 14.5049 15.3114 14.4434 15.3456 14.4024Z"
        fill="#F3F3F3"
      />
      <path
        d="M15.3387 14.3955C15.3729 14.3477 15.4413 14.3408 15.4891 14.3751L15.9881 14.7647C16.036 14.7988 16.0428 14.8672 16.0086 14.915C15.9744 14.963 15.9061 14.9697 15.8583 14.9356L15.3592 14.5459C15.3114 14.5118 15.3046 14.4434 15.3387 14.3955ZM15.995 14.9083C16.0291 14.8672 16.0223 14.8125 15.9813 14.7784L15.4823 14.3887C15.4413 14.3614 15.3866 14.3682 15.3524 14.4092C15.3182 14.4502 15.3251 14.5049 15.3661 14.5391L15.8651 14.9287C15.9061 14.9561 15.9607 14.9492 15.9949 14.9083H15.995Z"
        fill="white"
      />
      <path
        d="M14.3274 14.334C14.3684 14.293 14.43 14.293 14.4641 14.334L15.4758 15.3388C15.5169 15.3799 15.5101 15.4414 15.4758 15.4756C15.4349 15.5166 15.3733 15.5166 15.3392 15.4756L14.3274 14.4708C14.2932 14.4365 14.2932 14.3682 14.3274 14.334Z"
        fill="#F3F3F3"
      />
      <path
        d="M14.3269 14.3272C14.347 14.3074 14.374 14.2964 14.4021 14.2964C14.4303 14.2964 14.4573 14.3074 14.4773 14.3272L15.4891 15.332C15.5088 15.352 15.5199 15.379 15.5199 15.4072C15.5199 15.4353 15.5088 15.4623 15.4891 15.4824C15.469 15.5021 15.442 15.5132 15.4139 15.5132C15.3858 15.5132 15.3588 15.5021 15.3387 15.4824L14.3269 14.4776C14.2791 14.4365 14.2791 14.3682 14.3269 14.3272ZM15.4754 15.4687C15.5096 15.4346 15.5096 15.373 15.4754 15.3389L14.4637 14.334C14.4295 14.2998 14.368 14.2998 14.3338 14.334C14.2996 14.3682 14.2996 14.4297 14.3338 14.4638L15.3455 15.4687C15.3797 15.5098 15.4345 15.5098 15.4754 15.4687Z"
        fill="white"
      />
      <path
        d="M14.3886 15.3525C14.4296 15.3184 14.4911 15.3184 14.5253 15.3594L14.9424 15.8379C14.9765 15.879 14.9697 15.9405 14.9286 15.9746C14.8877 16.0088 14.8261 16.0088 14.7919 15.9678L14.3749 15.4893C14.3407 15.4551 14.3476 15.3936 14.3886 15.3525Z"
        fill="#F3F3F3"
      />
      <path
        d="M14.3821 15.3525C14.43 15.3116 14.4915 15.3184 14.5324 15.3594L14.9495 15.8379C14.9905 15.8789 14.9836 15.9473 14.9358 15.9883C14.8879 16.0293 14.8264 16.0225 14.7854 15.9815L14.3684 15.503C14.3342 15.4551 14.3342 15.3868 14.382 15.3525H14.3821ZM14.929 15.9746C14.9699 15.9405 14.9699 15.8857 14.9426 15.8448L14.5256 15.3662C14.4914 15.3321 14.4367 15.3252 14.3957 15.3594C14.3547 15.3936 14.3547 15.4483 14.382 15.4892L14.7991 15.9678C14.8332 16.0088 14.888 16.0088 14.9289 15.9746H14.929Z"
        fill="white"
      />
      <path
        d="M13.4044 15.0928C13.4523 15.0586 13.5138 15.0723 13.5412 15.1202L14.3341 16.3027C14.3615 16.3506 14.3478 16.4121 14.3068 16.4395C14.2589 16.4736 14.1974 16.4599 14.17 16.4121L13.377 15.2295C13.3497 15.1817 13.3634 15.1201 13.4044 15.0928H13.4044Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.4043 15.0859C13.4522 15.0518 13.5205 15.0655 13.5547 15.1133L14.3476 16.2959C14.3818 16.3437 14.3681 16.412 14.3135 16.4462C14.2656 16.4804 14.1973 16.4667 14.163 16.4189L13.3702 15.2363C13.336 15.1817 13.3497 15.1202 13.4043 15.086V15.0859ZM14.3066 16.4326C14.3476 16.4052 14.3613 16.3505 14.3339 16.3095L13.5411 15.1269C13.5137 15.086 13.459 15.0791 13.4112 15.1065C13.3702 15.1338 13.3565 15.1885 13.3839 15.2295L14.1767 16.412C14.2041 16.4462 14.2656 16.4599 14.3066 16.4325L14.3066 16.4326Z"
        fill="white"
      />
      <path
        d="M13.2607 16.1045C13.3085 16.0771 13.3701 16.0908 13.3974 16.1387L13.7119 16.6924C13.7392 16.7403 13.7186 16.8018 13.6708 16.8291C13.623 16.8565 13.5614 16.8428 13.5341 16.7949L13.2197 16.2412C13.1992 16.1934 13.2128 16.1318 13.2606 16.1045L13.2607 16.1045Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.2607 16.0977C13.3154 16.0703 13.377 16.084 13.4043 16.1319L13.7187 16.6856C13.7461 16.7335 13.7324 16.8019 13.6778 16.8292C13.623 16.8566 13.5615 16.8429 13.5342 16.795L13.2197 16.2412C13.1924 16.1934 13.2061 16.1319 13.2607 16.0977H13.2607ZM13.6709 16.8224C13.7119 16.795 13.7324 16.7403 13.7051 16.6993L13.3907 16.1456C13.3633 16.1046 13.3086 16.0909 13.2676 16.1113C13.2265 16.1387 13.2061 16.1934 13.2334 16.2344L13.5479 16.7882C13.5683 16.8292 13.6299 16.8497 13.6709 16.8223V16.8224Z"
        fill="white"
      />
      <path
        d="M12.3516 15.6534C12.4063 15.6329 12.4609 15.6534 12.4815 15.7012L13.0352 17.0205C13.0557 17.0684 13.0284 17.1299 12.9805 17.1504C12.9327 17.1709 12.8711 17.1504 12.8507 17.1025L12.2969 15.7833C12.2764 15.7354 12.3037 15.6739 12.3516 15.6534Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.3515 15.6465C12.4062 15.626 12.4677 15.6465 12.4951 15.7012L13.0488 17.0205C13.0587 17.0468 13.0578 17.076 13.0462 17.1016C13.0347 17.1272 13.0135 17.1472 12.9872 17.1572C12.9326 17.1777 12.871 17.1572 12.8437 17.1025L12.29 15.7833C12.2695 15.7353 12.2969 15.6739 12.3515 15.6465ZM12.9736 17.1436C13.0214 17.1231 13.0419 17.0684 13.0214 17.0274L12.4677 15.708C12.4472 15.6602 12.3925 15.6397 12.3447 15.6602C12.2969 15.6807 12.2763 15.7353 12.2969 15.7764L12.8505 17.0958C12.8779 17.1436 12.9257 17.1641 12.9736 17.1436Z"
        fill="white"
      />
      <path
        d="M12.0165 16.624C12.0713 16.6036 12.1259 16.6309 12.1464 16.6856L12.3447 17.2871C12.3652 17.335 12.331 17.3965 12.2832 17.4102C12.2285 17.4307 12.1738 17.4034 12.1533 17.3487L11.955 16.7471C11.9346 16.6993 11.9619 16.6446 12.0166 16.624H12.0165Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.0099 16.6173C12.0362 16.6074 12.0653 16.6083 12.0909 16.6198C12.1165 16.6313 12.1366 16.6526 12.1466 16.6788L12.3449 17.2803C12.3653 17.335 12.3312 17.3965 12.2764 17.4171C12.2501 17.427 12.221 17.4261 12.1954 17.4145C12.1697 17.403 12.1497 17.3818 12.1397 17.3556L11.9415 16.754C11.9278 16.6993 11.9552 16.6378 12.0099 16.6173ZM12.2765 17.4033C12.3243 17.3897 12.3517 17.335 12.338 17.2872L12.1397 16.6856C12.1261 16.6378 12.0714 16.6173 12.0236 16.631C11.9756 16.6446 11.9483 16.6993 11.962 16.7472L12.1603 17.3487C12.1637 17.3603 12.1696 17.3711 12.1775 17.3802C12.1855 17.3894 12.1952 17.3968 12.2062 17.402C12.2171 17.4071 12.2291 17.4099 12.2412 17.4102C12.2533 17.4104 12.2653 17.4081 12.2765 17.4034V17.4033Z"
        fill="white"
      />
      <path
        d="M11.21 16.002C11.2646 15.9883 11.3193 16.0225 11.3261 16.0772L11.6133 17.4786C11.627 17.5332 11.5859 17.5812 11.5312 17.5948C11.4766 17.6085 11.4218 17.5743 11.415 17.5196L11.1278 16.1182C11.121 16.0703 11.1552 16.0156 11.2099 16.0019L11.21 16.002Z"
        fill="#F3F3F3"
      />
      <path
        d="M11.2101 15.995C11.2715 15.9814 11.3262 16.0224 11.3399 16.0771L11.627 17.4786C11.6407 17.5332 11.5996 17.5948 11.5449 17.6016C11.4834 17.6153 11.4287 17.5743 11.4151 17.5196L11.128 16.1181C11.1144 16.0634 11.1486 16.0087 11.21 15.9951L11.2101 15.995ZM11.5313 17.5879C11.5792 17.5812 11.6133 17.5332 11.6065 17.4785L11.3194 16.0771C11.3126 16.0292 11.2579 15.9951 11.21 16.0087C11.1622 16.0156 11.128 16.0634 11.1349 16.1181L11.422 17.5196C11.4356 17.5675 11.4834 17.5948 11.5313 17.588L11.5313 17.5879Z"
        fill="white"
      />
      <path
        d="M10.6907 16.8906C10.7453 16.8838 10.7932 16.918 10.8069 16.9727L10.8889 17.6015C10.8957 17.6562 10.8547 17.7041 10.8 17.7109C10.7453 17.7178 10.6975 17.6836 10.6838 17.6289L10.6018 17C10.595 16.9453 10.636 16.8975 10.6907 16.8906Z"
        fill="#F3F3F3"
      />
      <path
        d="M10.6902 16.8838C10.7518 16.877 10.8065 16.918 10.8133 16.9727L10.8954 17.6016C10.9022 17.6563 10.8612 17.711 10.7997 17.7178C10.7381 17.7247 10.6834 17.6837 10.6765 17.6289L10.5945 17C10.5876 16.9453 10.6287 16.8906 10.6902 16.8838ZM10.7928 17.7041C10.8407 17.6974 10.8818 17.6494 10.8749 17.6016L10.7928 16.9727C10.786 16.9249 10.7381 16.8906 10.6902 16.8975C10.6424 16.9043 10.6013 16.9522 10.6081 17L10.6902 17.629C10.6971 17.6768 10.7449 17.711 10.7928 17.7041Z"
        fill="white"
      />
      <path
        d="M9.98631 16.1249C10.041 16.1249 10.0888 16.166 10.0888 16.2207V17.6494C10.0888 17.7041 10.041 17.7451 9.98631 17.7451C9.93163 17.7451 9.88379 17.7041 9.88379 17.6494V16.2207C9.89063 16.166 9.93163 16.1249 9.98631 16.1249Z"
        fill="#F3F3F3"
      />
      <path
        d="M9.98632 16.1181C10.0479 16.1181 10.0957 16.1659 10.0957 16.2206V17.6493C10.0957 17.704 10.0479 17.7519 9.98632 17.7519C9.92479 17.7519 9.87695 17.704 9.87695 17.6493V16.2207C9.87695 16.166 9.93164 16.1181 9.98632 16.1181ZM9.98632 17.7382C10.0342 17.7382 10.082 17.6972 10.082 17.6493V16.2207C10.082 16.1728 10.041 16.1318 9.98632 16.1318C9.93848 16.1318 9.89064 16.1728 9.89064 16.2207V17.6493C9.89742 17.6972 9.93848 17.7382 9.98632 17.7382Z"
        fill="white"
      />
      <path
        d="M9.30272 16.8906C9.35741 16.8975 9.39841 16.9385 9.39841 16.9932L9.35057 17.6289C9.34379 17.6836 9.29588 17.7246 9.24119 17.7178C9.18651 17.7109 9.14551 17.6699 9.14551 17.6152L9.19335 16.9795C9.20019 16.9248 9.24804 16.8838 9.30272 16.8906Z"
        fill="#F3F3F3"
      />
      <path
        d="M9.30273 16.8838C9.36427 16.8906 9.40526 16.9385 9.40526 16.9932L9.35742 17.629C9.35058 17.6837 9.30273 17.7315 9.2412 17.7247C9.17974 17.7178 9.13867 17.67 9.13867 17.6153L9.18651 16.9795C9.19336 16.918 9.2412 16.877 9.30273 16.8838ZM9.2412 17.711C9.28904 17.711 9.33696 17.6768 9.33696 17.629L9.3848 16.9932C9.3848 16.9453 9.35058 16.9043 9.29589 16.8975C9.24805 16.8975 9.2002 16.9316 9.2002 16.9795L9.15236 17.6153C9.15236 17.6631 9.19336 17.7042 9.24127 17.711H9.2412Z"
        fill="white"
      />
      <path
        d="M8.78993 16.0019C8.84461 16.0155 8.87877 16.0634 8.87192 16.1181L8.59165 17.5196C8.57796 17.5743 8.53012 17.6085 8.47544 17.5948C8.42075 17.5812 8.3866 17.5333 8.39345 17.4785L8.67372 16.0771C8.68056 16.0292 8.73524 15.9951 8.78993 16.0019Z"
        fill="#F3F3F3"
      />
      <path
        d="M8.78996 15.995C8.85142 16.0087 8.88565 16.0635 8.8788 16.1181L8.59852 17.5195C8.58483 17.5742 8.53014 17.6152 8.46868 17.6015C8.40715 17.5879 8.37293 17.5332 8.37977 17.4785L8.66005 16.0771C8.67374 16.0224 8.72843 15.9882 8.78989 15.9951L8.78996 15.995ZM8.46868 17.5879C8.51652 17.5947 8.56437 17.5674 8.57806 17.5195L8.85827 16.1181C8.86511 16.0702 8.8378 16.0224 8.78312 16.0087C8.73527 16.0019 8.68743 16.0292 8.67374 16.0772L8.39346 17.4785C8.38661 17.5332 8.42077 17.5742 8.46868 17.5879Z"
        fill="white"
      />
      <path
        d="M7.96962 16.6173C8.02438 16.631 8.05853 16.6856 8.04484 16.7403L7.87393 17.3555C7.86031 17.4102 7.80563 17.4375 7.75094 17.4239C7.73791 17.4207 7.72563 17.4151 7.7148 17.4072C7.70397 17.3993 7.69479 17.3894 7.68781 17.378C7.68082 17.3665 7.67616 17.3538 7.67408 17.3406C7.67201 17.3274 7.67256 17.3138 7.67572 17.3008L7.84663 16.6856C7.86031 16.631 7.915 16.6037 7.96969 16.6173H7.96962Z"
        fill="#F3F3F3"
      />
      <path
        d="M7.96967 16.6105C8.02442 16.6241 8.06542 16.6856 8.04488 16.7403L7.87398 17.3556C7.86036 17.4103 7.79883 17.4445 7.74415 17.4307C7.68946 17.4171 7.64847 17.3556 7.66893 17.3009L7.83983 16.6856C7.85352 16.631 7.90821 16.5967 7.96967 16.6104V16.6105ZM7.74415 17.4103C7.79199 17.4239 7.84668 17.3966 7.86036 17.3487L8.0312 16.7335C8.04488 16.6856 8.01758 16.6378 7.96289 16.6241C7.91505 16.6104 7.86036 16.6378 7.84668 16.6856L7.67577 17.3009C7.66893 17.3487 7.69624 17.3966 7.74415 17.4103Z"
        fill="white"
      />
      <path
        d="M7.63484 15.6534C7.68952 15.6738 7.70999 15.7354 7.68952 15.7832L7.14266 17.1025C7.1222 17.1505 7.0606 17.1778 7.01283 17.1505C6.96491 17.1231 6.93754 17.0684 6.95807 17.0206L7.50493 15.7012C7.52539 15.6534 7.58699 15.626 7.63477 15.6534H7.63484Z"
        fill="#F3F3F3"
      />
      <path
        d="M7.63481 15.6465C7.66108 15.6565 7.68231 15.6765 7.69384 15.7022C7.70537 15.7278 7.70627 15.757 7.69634 15.7833L7.14946 17.1025C7.12892 17.1572 7.06739 17.1777 7.00586 17.1572C6.97961 17.1472 6.95839 17.1272 6.94686 17.1016C6.93533 17.0759 6.93442 17.0468 6.94432 17.0205L7.49121 15.7012C7.51859 15.6465 7.58012 15.6192 7.63481 15.6465ZM7.0127 17.1436C7.06055 17.164 7.11523 17.1436 7.13577 17.0957L7.68265 15.7764C7.70312 15.7286 7.67581 15.6807 7.62797 15.6602C7.58012 15.6397 7.52543 15.6602 7.5049 15.708L6.95801 17.0274C6.94432 17.0684 6.96486 17.123 7.0127 17.1436Z"
        fill="white"
      />
      <path
        d="M6.71205 16.0909C6.75989 16.1182 6.78035 16.1729 6.75989 16.2207L6.47277 16.788C6.44539 16.8359 6.3907 16.8564 6.33602 16.829C6.28817 16.8017 6.26771 16.7471 6.28817 16.6992L6.57536 16.132C6.60267 16.0841 6.65736 16.0636 6.71205 16.0909Z"
        fill="#F3F3F3"
      />
      <path
        d="M6.71226 16.084C6.76695 16.1113 6.78741 16.1729 6.7601 16.2275L6.47298 16.7949C6.4456 16.8496 6.38407 16.8701 6.32945 16.8427C6.27476 16.8154 6.25423 16.7538 6.28161 16.6992L6.56866 16.1319C6.59604 16.0772 6.65757 16.0567 6.71226 16.084ZM6.33629 16.8291C6.38414 16.8496 6.43882 16.8359 6.45929 16.7881L6.74641 16.2208C6.76687 16.1797 6.74641 16.125 6.70541 16.0977C6.65757 16.0772 6.60288 16.0909 6.58235 16.1387L6.29523 16.706C6.27476 16.7471 6.29523 16.8017 6.33629 16.8291V16.8291Z"
        fill="white"
      />
      <path
        d="M6.5751 15.0792C6.62295 15.1133 6.63664 15.1749 6.60248 15.2159L5.80949 16.3986C5.78218 16.4464 5.72058 16.4532 5.6728 16.4259C5.62496 16.3917 5.61127 16.3302 5.64543 16.2892L6.43842 15.1064C6.46573 15.0586 6.52726 15.0449 6.5751 15.0791V15.0792Z"
        fill="#F3F3F3"
      />
      <path
        d="M6.57499 15.0723C6.62283 15.1065 6.63652 15.1749 6.60914 15.2227L5.81618 16.4054C5.78203 16.4532 5.7205 16.4668 5.66581 16.4327C5.61796 16.3985 5.60427 16.3302 5.63158 16.2822L6.42461 15.0996C6.45876 15.0518 6.52714 15.0381 6.57499 15.0723ZM5.67265 16.419C5.71358 16.4463 5.77519 16.4395 5.8025 16.3985L6.59552 15.2159C6.62283 15.1749 6.60921 15.1202 6.56814 15.0928C6.52714 15.0655 6.46561 15.0723 6.4383 15.1133L5.64527 16.2891C5.61796 16.3302 5.63158 16.3916 5.67265 16.4189V16.419Z"
        fill="white"
      />
      <path
        d="M5.57692 15.3321C5.61799 15.3662 5.63161 15.4278 5.59746 15.4687L5.20096 15.9678C5.16681 16.0088 5.10528 16.0156 5.06428 15.9814C5.02328 15.9473 5.00959 15.8858 5.04381 15.8447L5.44024 15.3457C5.47439 15.3048 5.53592 15.2978 5.57692 15.3321Z"
        fill="#F3F3F3"
      />
      <path
        d="M5.58383 15.3252C5.63168 15.3594 5.63852 15.4278 5.6043 15.4756L5.20786 15.9747C5.17371 16.0225 5.10533 16.0293 5.05748 15.9883C5.00964 15.9541 5.0028 15.8858 5.03702 15.8379L5.43346 15.3389C5.46761 15.291 5.53599 15.2842 5.58383 15.3252ZM5.07117 15.9747C5.1121 16.0088 5.16686 16.002 5.20101 15.961L5.59752 15.4619C5.6249 15.421 5.61799 15.3662 5.57706 15.3321C5.53599 15.2978 5.4813 15.3047 5.44715 15.3458L5.05064 15.8447C5.02333 15.8858 5.03018 15.9473 5.0711 15.9747H5.07117Z"
        fill="white"
      />
      <path
        d="M5.64539 14.3067C5.68639 14.3476 5.68639 14.4092 5.64539 14.4434L4.63374 15.4482C4.59267 15.4893 4.53121 15.4824 4.49699 15.4482C4.46284 15.4141 4.45599 15.3457 4.49699 15.3115L5.5018 14.3067C5.54286 14.2656 5.60439 14.2656 5.64539 14.3067Z"
        fill="#F3F3F3"
      />
      <path
        d="M5.65229 14.2999C5.67198 14.3199 5.68302 14.3469 5.68302 14.375C5.68302 14.4031 5.67198 14.4301 5.65229 14.4502L4.64057 15.4552C4.62051 15.4749 4.59353 15.4859 4.56542 15.4859C4.53731 15.4859 4.51033 15.4749 4.49027 15.4552C4.47053 15.4351 4.45947 15.4081 4.45947 15.3799C4.45947 15.3518 4.47053 15.3248 4.49027 15.3047L5.50192 14.2999C5.52198 14.2801 5.54898 14.2691 5.57711 14.2691C5.60523 14.2691 5.63223 14.2801 5.65229 14.2999ZM4.49704 15.4483C4.5312 15.4824 4.59273 15.4824 4.62688 15.4483L5.6386 14.4433C5.67282 14.4092 5.67282 14.3476 5.6386 14.3135C5.60445 14.2793 5.54292 14.2793 5.50877 14.3135L4.49704 15.3183C4.46289 15.3525 4.46289 15.4073 4.49704 15.4482V15.4483Z"
        fill="white"
      />
      <path
        d="M4.62007 14.3614C4.65422 14.4023 4.65422 14.4639 4.61322 14.4981L4.12788 14.9083C4.08688 14.9425 4.02535 14.9356 3.99113 14.8946C3.95697 14.8536 3.95697 14.7921 3.99797 14.7579L4.48331 14.3477C4.52438 14.3135 4.58584 14.3203 4.62007 14.3614Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.62671 14.3545C4.6677 14.4024 4.66086 14.4707 4.61986 14.5049L4.13452 14.915C4.09352 14.9561 4.02514 14.9492 3.98414 14.9014C3.94307 14.8535 3.94992 14.7852 3.99092 14.7509L4.47633 14.3409C4.51726 14.2999 4.58571 14.3067 4.62664 14.3545L4.62671 14.3545ZM3.99776 14.8945C4.03199 14.9355 4.08667 14.9355 4.1276 14.9082L4.61302 14.4981C4.64717 14.4639 4.65402 14.4092 4.61986 14.3682C4.58564 14.3272 4.53095 14.3272 4.49002 14.3545L4.00461 14.7646C3.96361 14.7988 3.96361 14.8535 3.99776 14.8945V14.8945Z"
        fill="white"
      />
      <path
        d="M4.8935 13.3769C4.92765 13.4248 4.91396 13.4863 4.86612 13.5137L3.67669 14.3066C3.62885 14.334 3.56732 14.3203 3.54001 14.2793C3.50579 14.2314 3.51948 14.1699 3.56732 14.1425L4.75682 13.3496C4.79788 13.3223 4.85935 13.3359 4.89357 13.3769H4.8935Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.90031 13.3769C4.93447 13.4248 4.92078 13.4932 4.87294 13.5274L3.68353 14.3203C3.63568 14.3545 3.56731 14.3408 3.53316 14.2861C3.49893 14.2383 3.51269 14.1699 3.56046 14.1358L4.74994 13.3428C4.79779 13.3086 4.86609 13.3223 4.90024 13.3769H4.90031ZM3.54685 14.2725C3.57415 14.3135 3.62884 14.3271 3.66984 14.2998L4.85925 13.5068C4.90032 13.4795 4.91393 13.4249 4.87978 13.377C4.85247 13.3359 4.79779 13.3223 4.75672 13.3496L3.56731 14.1426C3.52631 14.1768 3.51947 14.2315 3.54685 14.2725Z"
        fill="white"
      />
      <path
        d="M3.87524 13.2334C3.90262 13.2813 3.88893 13.3428 3.84109 13.3702L3.28736 13.6846C3.23952 13.7119 3.17798 13.6914 3.15068 13.6436C3.1233 13.5957 3.13699 13.5342 3.18483 13.5068L3.73856 13.1924C3.7864 13.165 3.84793 13.1856 3.87531 13.2334H3.87524Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.88214 13.2266C3.90945 13.2813 3.89576 13.3428 3.84791 13.3702L3.2942 13.6846C3.24636 13.7119 3.17798 13.6914 3.15068 13.6436C3.1233 13.5889 3.13699 13.5274 3.18483 13.5L3.73854 13.1856C3.78638 13.1582 3.85476 13.1787 3.88207 13.2266H3.88214ZM3.15745 13.6367C3.18483 13.6846 3.23952 13.6982 3.28051 13.6709L3.83423 13.3565C3.87522 13.3291 3.88891 13.2744 3.86845 13.2334C3.84107 13.1856 3.78638 13.1719 3.74539 13.1993L3.19167 13.5137C3.15068 13.5341 3.13699 13.5889 3.15752 13.6367H3.15745Z"
        fill="white"
      />
      <path
        d="M4.33297 12.3243C4.35344 12.379 4.33297 12.4336 4.27828 12.4541L2.95894 13.001C2.9111 13.0215 2.84956 12.9942 2.8291 12.9463C2.80857 12.8916 2.8291 12.8369 2.88379 12.8165L4.20313 12.2696C4.25098 12.2491 4.31251 12.2696 4.33297 12.3243Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.33982 12.3174C4.36028 12.3721 4.33982 12.4337 4.28513 12.461L2.96578 13.0078C2.9395 13.0177 2.91035 13.0168 2.88473 13.0053C2.85912 12.9938 2.83911 12.9725 2.8291 12.9463C2.80857 12.8916 2.8291 12.8301 2.88379 12.8028L4.20313 12.2559C4.22942 12.246 4.25857 12.2469 4.28418 12.2584C4.3098 12.27 4.32981 12.2912 4.33982 12.3174ZM2.84272 12.9395C2.86325 12.9874 2.91794 13.0079 2.95894 12.9941L4.27828 12.4473C4.32613 12.4268 4.34666 12.3721 4.32613 12.3243C4.30566 12.2764 4.25098 12.2559 4.20991 12.2696L2.89056 12.8165C2.84272 12.8369 2.82226 12.8916 2.84272 12.9395V12.9395Z"
        fill="white"
      />
      <path
        d="M3.36913 11.9824C3.38959 12.0371 3.36228 12.0918 3.30759 12.1123L2.70603 12.3106C2.65818 12.331 2.59665 12.2969 2.58296 12.249C2.5625 12.1943 2.58981 12.1397 2.64449 12.1192L3.24606 11.9209C3.2939 11.9004 3.34859 11.9278 3.36913 11.9824Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.37583 11.9756C3.39636 12.0303 3.36905 12.0918 3.30752 12.1124L2.70597 12.3106C2.65128 12.3311 2.58975 12.2969 2.56921 12.2422C2.54875 12.1875 2.57606 12.126 2.63766 12.1055L3.23914 11.9072C3.29383 11.8936 3.35536 11.9209 3.3759 11.9757L3.37583 11.9756ZM2.58297 12.2355C2.59666 12.2833 2.65135 12.3107 2.69919 12.297L3.30075 12.0987C3.34859 12.0851 3.36912 12.0304 3.35543 11.9825C3.34174 11.9347 3.28706 11.9073 3.23921 11.921L2.63766 12.1193C2.58982 12.1398 2.56928 12.1876 2.58297 12.2355Z"
        fill="white"
      />
      <path
        d="M3.98447 11.169C3.99809 11.2237 3.96393 11.2783 3.90925 11.2852L2.50784 11.5586C2.45316 11.5723 2.40531 11.5312 2.39169 11.4766C2.37801 11.4219 2.41216 11.3672 2.46685 11.3603L3.86825 11.0869C3.92294 11.0801 3.97762 11.1143 3.9844 11.169H3.98447Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.99822 11.169C4.01191 11.2305 3.97091 11.2852 3.91622 11.2988L2.51481 11.5723C2.46012 11.586 2.40544 11.545 2.39182 11.4834C2.37813 11.4219 2.41913 11.3672 2.47381 11.3536L3.87522 11.0802C3.92984 11.0665 3.9846 11.1075 3.99822 11.169ZM2.40544 11.4767C2.41228 11.5244 2.46012 11.5586 2.50797 11.5518L3.90938 11.2784C3.95722 11.2715 3.99137 11.2168 3.97775 11.169C3.97091 11.1211 3.92307 11.0869 3.87515 11.0938L2.47381 11.3672C2.42597 11.3809 2.39182 11.4287 2.40544 11.4766V11.4767Z"
        fill="white"
      />
      <path
        d="M3.10927 10.6427C3.11604 10.6973 3.08189 10.7452 3.0272 10.7521L2.39833 10.8272C2.34357 10.8341 2.29573 10.7931 2.28896 10.7383C2.28211 10.6837 2.31626 10.6358 2.37095 10.6289L2.99989 10.5537C3.04774 10.5469 3.10242 10.5879 3.10927 10.6427Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.11649 10.6426C3.12326 10.7041 3.08226 10.7588 3.02758 10.7656L2.39871 10.8408C2.34395 10.8477 2.28933 10.8067 2.28249 10.7451C2.27564 10.6836 2.31664 10.6289 2.37133 10.6221L3.00027 10.5469C3.05489 10.54 3.10964 10.5811 3.11642 10.6426L3.11649 10.6426ZM2.28926 10.7383C2.2961 10.7861 2.34402 10.8272 2.39186 10.8203L3.02073 10.7451C3.06857 10.7383 3.1028 10.6905 3.09595 10.6426C3.08911 10.5947 3.04127 10.5537 2.99342 10.5606L2.36448 10.6358C2.31664 10.6426 2.28249 10.6905 2.28926 10.7383Z"
        fill="white"
      />
      <path
        d="M3.87489 9.97947C3.87489 10.0342 3.83397 10.0821 3.77921 10.0821H2.35057C2.29588 10.0821 2.25488 10.0342 2.25488 9.97954C2.25488 9.92486 2.29588 9.87695 2.35057 9.87695H3.77928C3.83397 9.87695 3.87503 9.92486 3.87503 9.97954L3.87489 9.97947Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.88183 9.97946C3.88183 10.0411 3.83391 10.0888 3.77923 10.0888H2.35058C2.29589 10.0888 2.24805 10.041 2.24805 9.97946C2.24805 9.918 2.29589 9.87009 2.35058 9.87009H3.7793C3.83398 9.87009 3.88183 9.918 3.88183 9.97946ZM2.26167 9.97268C2.26167 10.0205 2.30266 10.0684 2.35058 10.0684H3.77923C3.82707 10.0684 3.86814 10.0274 3.86814 9.97268C3.86814 9.92484 3.82707 9.87693 3.77923 9.87693H2.35058C2.30273 9.88378 2.26174 9.92484 2.26174 9.97268H2.26167Z"
        fill="white"
      />
      <path
        d="M3.11655 9.28906C3.10963 9.34374 3.06863 9.38474 3.01394 9.38474L2.37815 9.3369C2.32353 9.33005 2.28253 9.28221 2.28931 9.22752C2.29615 9.17284 2.33722 9.13184 2.39191 9.13184L3.02763 9.17968C3.07548 9.18653 3.11655 9.23437 3.11655 9.28906Z"
        fill="#E2E2E2"
      />
      <path
        d="M3.12316 9.28908C3.11632 9.35061 3.06848 9.39161 3.01379 9.39161L2.37801 9.34377C2.32339 9.33692 2.27555 9.28908 2.28239 9.22755C2.28917 9.16608 2.33708 9.12502 2.39177 9.12502L3.02748 9.17286C3.08217 9.1797 3.12316 9.23439 3.12316 9.28908ZM2.28917 9.22755C2.28239 9.27539 2.32339 9.3233 2.37123 9.3233L3.00702 9.37114C3.05479 9.37114 3.09586 9.33692 3.1027 9.28223C3.10955 9.23439 3.06848 9.18655 3.02063 9.18655L2.38485 9.1387C2.33708 9.1387 2.29601 9.1797 2.28917 9.22762V9.22755Z"
        fill="white"
      />
      <path
        d="M3.99814 8.79002C3.98446 8.8447 3.93661 8.87892 3.88192 8.87208L2.48058 8.58497C2.4259 8.57128 2.39167 8.52344 2.40536 8.46876C2.41905 8.41407 2.46689 8.37985 2.52158 8.38676L3.92299 8.66703C3.97084 8.68065 4.00499 8.73533 3.99814 8.79002Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.00504 8.79002C3.99135 8.85155 3.93667 8.88577 3.88198 8.87893L2.48065 8.59865C2.42596 8.58496 2.38496 8.53027 2.39865 8.46881C2.41227 8.40721 2.46696 8.37306 2.52165 8.3799L3.92305 8.66018C3.97774 8.67387 4.01189 8.72856 4.00504 8.79002ZM2.41227 8.46874C2.40543 8.51658 2.43274 8.56443 2.48065 8.57811L3.88205 8.85839C3.92989 8.86524 3.97774 8.83793 3.99143 8.78317C3.9982 8.73533 3.97089 8.68749 3.92298 8.6738L2.52172 8.39359C2.46703 8.38675 2.42603 8.4209 2.41234 8.46874H2.41227Z"
        fill="white"
      />
      <path
        d="M3.38292 7.9698C3.37978 7.98281 3.37411 7.99509 3.36622 8.00591C3.35834 8.01674 3.3484 8.0259 3.33697 8.03288C3.32554 8.03986 3.31284 8.04452 3.29961 8.04659C3.28638 8.04866 3.27287 8.0481 3.25986 8.04494L2.6446 7.87405C2.58991 7.86036 2.5626 7.80567 2.57622 7.75099C2.57936 7.73797 2.58503 7.7257 2.59292 7.71487C2.6008 7.70405 2.61074 7.69488 2.62217 7.68791C2.6336 7.68093 2.6463 7.67627 2.65953 7.6742C2.67276 7.67213 2.68627 7.67269 2.69928 7.67584L3.31454 7.84674C3.36923 7.86036 3.39654 7.91504 3.38292 7.96973V7.9698Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.38975 7.96978C3.37613 8.02439 3.3146 8.06546 3.25991 8.04493L2.64465 7.87403C2.58996 7.86034 2.55574 7.79881 2.56943 7.7442C2.58311 7.68945 2.64465 7.64845 2.69934 7.66898L3.3146 7.83988C3.36929 7.85357 3.40351 7.90825 3.38975 7.96971V7.96978ZM2.58996 7.74406C2.57627 7.79197 2.60365 7.84665 2.65149 7.86027L3.26676 8.03117C3.3146 8.04486 3.36244 8.01748 3.37613 7.9628C3.38975 7.91496 3.36244 7.86027 3.3146 7.84665L2.69934 7.67576C2.65149 7.66891 2.60365 7.69622 2.58996 7.74413V7.74406Z"
        fill="white"
      />
      <path
        d="M4.34675 7.64166C4.32629 7.69628 4.26468 7.71681 4.21691 7.69628L2.8975 7.14941C2.84965 7.12895 2.82234 7.06742 2.84281 7.01958C2.86334 6.96489 2.92487 6.94435 2.97272 6.96489L4.29206 7.51175C4.34675 7.53222 4.36728 7.59375 4.34675 7.64159V7.64166Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.35354 7.64844C4.34353 7.67471 4.32351 7.69593 4.29788 7.70747C4.27224 7.719 4.24308 7.7199 4.21678 7.70997L2.89743 7.16311C2.84274 7.14264 2.82228 7.08111 2.84274 7.01958C2.85275 6.99331 2.87277 6.97209 2.8984 6.96055C2.92404 6.94902 2.9532 6.94812 2.9795 6.95805L4.29885 7.50491C4.35354 7.53222 4.374 7.59382 4.35354 7.64844ZM2.85643 7.02643C2.83597 7.07427 2.85643 7.12895 2.90428 7.14942L4.22363 7.69628C4.27147 7.71682 4.31931 7.68944 4.33985 7.6416C4.36031 7.59375 4.33985 7.53907 4.29201 7.5186L2.97265 6.97174C2.92481 6.95805 2.87012 6.97858 2.85643 7.02643Z"
        fill="white"
      />
      <path
        d="M3.90237 6.71878C3.87506 6.76663 3.82037 6.78716 3.77253 6.76663L3.20512 6.47955C3.15728 6.45902 3.13674 6.39748 3.16412 6.34279C3.19143 6.29494 3.24612 6.27441 3.29396 6.29494L3.8613 6.58209C3.90921 6.6094 3.92975 6.671 3.90237 6.71878Z"
        fill="#F3F3F3"
      />
      <path
        d="M3.90952 6.72562C3.88214 6.78031 3.82061 6.80084 3.76593 6.77346L3.19859 6.48633C3.1439 6.45902 3.12337 6.39749 3.15075 6.3428C3.17806 6.28811 3.23966 6.26758 3.29428 6.29496L3.86161 6.58209C3.91637 6.60255 3.93683 6.671 3.90952 6.72562ZM3.17121 6.34965C3.15075 6.39749 3.16444 6.45218 3.21228 6.47271L3.77962 6.75985C3.82068 6.78031 3.87537 6.75985 3.90268 6.71878C3.92314 6.67093 3.90952 6.61624 3.86168 6.59571L3.28736 6.30872C3.24636 6.28825 3.19168 6.30187 3.17114 6.34972L3.17121 6.34965Z"
        fill="white"
      />
      <path
        d="M4.90708 6.58886C4.87293 6.63677 4.81824 6.65046 4.7704 6.61624L3.58771 5.83015C3.53994 5.80277 3.53309 5.74124 3.5604 5.6934C3.58778 5.64555 3.64931 5.63186 3.69716 5.66602L4.88662 6.45902C4.92762 6.4864 4.94131 6.54793 4.90708 6.58886Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.91396 6.59569C4.87981 6.6436 4.81143 6.65722 4.76366 6.62991L3.58101 5.83691C3.53317 5.80276 3.51948 5.74122 3.55363 5.68661C3.58785 5.63869 3.65623 5.625 3.70407 5.65238L4.88665 6.44532C4.94134 6.47954 4.94819 6.54785 4.91396 6.59569ZM3.56732 5.69338C3.54001 5.73438 3.54686 5.79591 3.58778 5.82322L4.77728 6.6163C4.81828 6.64367 4.87296 6.62999 4.90034 6.58899C4.92765 6.54792 4.92081 6.48639 4.87988 6.45908L3.69031 5.66607C3.64931 5.63869 3.59463 5.65238 3.56725 5.69338H3.56732Z"
        fill="white"
      />
      <path
        d="M4.65419 5.59767C4.62003 5.63866 4.5585 5.65235 4.5175 5.61813L4.01846 5.22855C3.97746 5.19433 3.97062 5.1328 4.00477 5.0918C4.03892 5.0508 4.10046 5.03712 4.14153 5.07134L4.6405 5.46091C4.68156 5.49514 4.68841 5.5566 4.65419 5.59767Z"
        fill="#F3F3F3"
      />
      <path
        d="M4.66098 5.60446C4.62682 5.65238 4.55845 5.65922 4.51061 5.625L4.01158 5.23542C3.96374 5.20126 3.9569 5.13289 3.99112 5.08512C4.02527 5.0372 4.09365 5.03036 4.14142 5.06458L4.64044 5.45416C4.68835 5.48839 4.6952 5.55669 4.66098 5.6046V5.60446ZM4.00474 5.09182C3.97059 5.13282 3.97743 5.1875 4.01843 5.22166L4.51745 5.61138C4.55845 5.63869 4.61313 5.63184 4.64729 5.59084C4.68151 5.54985 4.67467 5.49516 4.63367 5.46101L4.13465 5.07129C4.09365 5.04398 4.03889 5.05082 4.00481 5.09182H4.00474Z"
        fill="white"
      />
      <path
        d="M5.67269 5.66602C5.63176 5.70709 5.57016 5.70709 5.53601 5.66602L4.51752 4.66118C4.47652 4.62018 4.4833 4.55865 4.51752 4.52442C4.55852 4.48349 4.62006 4.48349 4.65421 4.52442L5.66599 5.52934C5.70699 5.56349 5.70699 5.63187 5.67283 5.6661L5.67269 5.66602Z"
        fill="#F3F3F3"
      />
      <path
        d="M5.6729 5.6729C5.65284 5.6926 5.62586 5.70363 5.59775 5.70363C5.56964 5.70363 5.54265 5.6926 5.5226 5.6729L4.51078 4.66793C4.49104 4.64787 4.47998 4.62085 4.47998 4.59271C4.47998 4.56457 4.49104 4.53755 4.51078 4.51749C4.53084 4.49777 4.55784 4.48672 4.58597 4.48672C4.61409 4.48672 4.64109 4.49777 4.66115 4.51749L5.6729 5.52239C5.72074 5.56339 5.72074 5.63176 5.6729 5.67276V5.6729ZM4.52461 4.53118C4.49039 4.5654 4.49039 4.62693 4.52461 4.66102L5.53629 5.66592C5.57051 5.70014 5.63197 5.70014 5.66612 5.66592C5.70035 5.63176 5.70035 5.57023 5.66612 5.53608L4.65445 4.5244C4.62029 4.49025 4.56554 4.49025 4.52461 4.53125V4.53118Z"
        fill="white"
      />
      <path
        d="M5.61125 4.64748C5.57025 4.6817 5.50872 4.6817 5.4745 4.64064L5.05747 4.16214C5.02339 4.12114 5.03016 4.05961 5.07123 4.02545C5.11216 3.99123 5.17376 3.99123 5.20791 4.03223L5.62494 4.5108C5.65909 4.54495 5.65225 4.60648 5.61125 4.64748Z"
        fill="#F3F3F3"
      />
      <path
        d="M5.61825 4.64749C5.57041 4.68849 5.50887 4.68171 5.46781 4.64064L5.05084 4.16214C5.00984 4.12114 5.01669 4.05277 5.06453 4.01177C5.11237 3.9707 5.1739 3.97754 5.2149 4.01861L5.63194 4.49704C5.66609 4.54496 5.66609 4.61334 5.61825 4.64749ZM5.07137 4.02546C5.03031 4.05961 5.03031 4.1143 5.05768 4.1553L5.47472 4.6338C5.50887 4.66802 5.56356 4.67487 5.60456 4.64064C5.64556 4.60649 5.64556 4.55173 5.61825 4.5108L5.20121 4.03223C5.16706 3.9913 5.1123 3.9913 5.07137 4.02546Z"
        fill="white"
      />
      <path
        d="M6.59579 4.9073C6.54795 4.94145 6.48642 4.92776 6.45904 4.87992L5.65927 3.69727C5.63189 3.64942 5.64558 3.58789 5.68657 3.56058C5.73442 3.52643 5.79595 3.54012 5.82333 3.58796L6.61625 4.77054C6.65048 4.81839 6.63679 4.87992 6.59579 4.90723V4.9073Z"
        fill="#F3F3F3"
      />
      <path
        d="M6.59573 4.91409C6.54789 4.94832 6.47952 4.93456 6.4453 4.88679L5.6524 3.70412C5.61825 3.65627 5.63194 3.58797 5.68655 3.55381C5.73439 3.51959 5.80277 3.53328 5.83699 3.58112L6.62988 4.76372C6.66404 4.81841 6.65035 4.87994 6.59573 4.91409ZM5.69333 3.56743C5.65233 3.59474 5.63864 3.64943 5.66602 3.6905L6.45891 4.8731C6.48629 4.91409 6.54098 4.92094 6.58875 4.89356C6.62981 4.86625 6.6435 4.81156 6.61612 4.77057L5.8233 3.59474C5.79592 3.55381 5.73439 3.54005 5.69347 3.56743H5.69333Z"
        fill="white"
      />
      <path
        d="M6.73927 3.89557C6.69143 3.92288 6.62991 3.90926 6.6026 3.86142L6.28811 3.3077C6.2608 3.25985 6.28127 3.19832 6.32918 3.17094C6.37702 3.14363 6.43854 3.15725 6.46585 3.20517L6.78027 3.75882C6.8008 3.80666 6.78711 3.86819 6.73934 3.89557H6.73927Z"
        fill="#F3F3F3"
      />
      <path
        d="M6.73925 3.90242C6.68456 3.92973 6.62303 3.91611 6.59572 3.8682L6.28122 3.31454C6.25391 3.26663 6.2676 3.19832 6.32229 3.17094C6.37698 3.14363 6.43851 3.15725 6.46582 3.20517L6.78025 3.75882C6.80763 3.80666 6.79394 3.8682 6.73932 3.90242H6.73925ZM6.32913 3.17779C6.28807 3.2051 6.2676 3.25978 6.29491 3.30085L6.60941 3.85458C6.63672 3.8955 6.69141 3.90926 6.73241 3.88873C6.77347 3.86142 6.79394 3.80666 6.76663 3.76567L6.45213 3.21194C6.43166 3.17101 6.37013 3.15041 6.32913 3.17779Z"
        fill="white"
      />
      <path
        d="M7.6484 4.3467C7.59379 4.36716 7.53903 4.3467 7.51857 4.29885L6.96488 2.97944C6.94441 2.9316 6.97172 2.87006 7.01956 2.84953C7.07425 2.82907 7.12893 2.84953 7.1494 2.89737L7.70309 4.21672C7.72362 4.26456 7.69632 4.32609 7.6484 4.34656V4.3467Z"
        fill="#F3F3F3"
      />
      <path
        d="M7.64844 4.3536C7.59382 4.37407 7.53229 4.3536 7.50491 4.29884L6.95118 2.97958C6.94125 2.95328 6.94215 2.92412 6.95369 2.89849C6.96522 2.87285 6.98645 2.85284 7.01272 2.84282C7.0674 2.82236 7.12894 2.84282 7.15632 2.89751L7.70997 4.21685C7.73051 4.26469 7.7032 4.32622 7.64851 4.3536H7.64844ZM7.02641 2.85651C6.97856 2.87698 6.95803 2.93174 6.97856 2.97273L7.53222 4.292C7.55268 4.33984 7.60737 4.36031 7.65521 4.33984C7.70306 4.31938 7.72366 4.26462 7.70306 4.22362L7.1494 2.90429C7.12202 2.85644 7.07418 2.83591 7.02634 2.85644L7.02641 2.85651Z"
        fill="white"
      />
      <path
        d="M7.98307 3.37605C7.92839 3.39652 7.87371 3.36914 7.85324 3.31452L7.65498 2.7129C7.63452 2.66505 7.66867 2.60352 7.7165 2.5899C7.76434 2.57621 7.82587 2.59675 7.84633 2.65144L8.0446 3.25299C8.06506 3.30083 8.03775 3.35552 7.98307 3.37598V3.37605Z"
        fill="#F3F3F3"
      />
      <path
        d="M7.9904 3.38284C7.96411 3.39275 7.93496 3.39184 7.90934 3.38031C7.88372 3.36878 7.86372 3.34756 7.85371 3.32131L7.65541 2.71975C7.63495 2.66506 7.6691 2.6036 7.72386 2.58307C7.75015 2.57316 7.7793 2.57407 7.80492 2.5856C7.83053 2.59714 7.85054 2.61835 7.86055 2.6446L8.05878 3.24616C8.07247 3.30085 8.04509 3.36238 7.9904 3.38284ZM7.72379 2.59676C7.67595 2.61038 7.64857 2.66506 7.66226 2.71291L7.86055 3.31454C7.87417 3.36238 7.92886 3.38284 7.97678 3.36916C8.02462 3.35554 8.05193 3.30085 8.03824 3.25301L7.84002 2.65137C7.83653 2.63977 7.83064 2.62903 7.82273 2.61985C7.81482 2.61067 7.80507 2.60326 7.7941 2.5981C7.78314 2.59295 7.77121 2.59015 7.7591 2.58991C7.74698 2.58966 7.73495 2.59197 7.72379 2.59669V2.59676Z"
        fill="white"
      />
      <path
        d="M8.78977 3.99806C8.73508 4.01174 8.6804 3.97759 8.67355 3.92283L8.38644 2.5215C8.37275 2.46681 8.41382 2.41897 8.46843 2.40528C8.52319 2.39159 8.5778 2.42575 8.58465 2.4805L8.87183 3.88191C8.87861 3.92968 8.84445 3.98437 8.78977 3.99806Z"
        fill="#F3F3F3"
      />
      <path
        d="M8.78981 4.00491C8.72828 4.0186 8.67359 3.97754 8.6599 3.92285L8.37277 2.52151C8.35908 2.46682 8.40015 2.40529 8.45484 2.39844C8.51637 2.38475 8.57106 2.42582 8.58468 2.48051L8.87188 3.88192C8.8855 3.93654 8.85134 3.9913 8.78981 4.00491ZM8.46853 2.41213C8.42061 2.41898 8.38646 2.46682 8.39331 2.52151L8.68043 3.92292C8.68728 3.97069 8.7419 4.00491 8.78981 3.99123C8.83765 3.98438 8.87181 3.93654 8.86496 3.88185L8.57783 2.48044C8.56421 2.43267 8.51637 2.40529 8.46846 2.41213H8.46853Z"
        fill="white"
      />
      <path
        d="M9.30979 3.1094C9.25511 3.11624 9.20727 3.08209 9.19358 3.0274L9.11159 2.39845C9.10474 2.34376 9.14574 2.29592 9.20049 2.28908C9.25511 2.28223 9.30295 2.31646 9.31664 2.37114L9.39863 3.00002C9.40547 3.05471 9.36448 3.10255 9.30979 3.1094Z"
        fill="#F3F3F3"
      />
      <path
        d="M9.3098 3.11622C9.24827 3.12307 9.19358 3.08207 9.18674 3.02731L9.10475 2.39844C9.0979 2.34375 9.1389 2.28906 9.20043 2.28222C9.26196 2.27544 9.31664 2.31644 9.32349 2.37113L9.40548 3C9.41232 3.05469 9.37133 3.10938 9.3098 3.11622ZM9.20727 2.29591C9.15943 2.30275 9.11837 2.35059 9.12521 2.39844L9.20727 3.02738C9.21412 3.07522 9.26196 3.10938 9.3098 3.10253C9.35764 3.09576 9.3987 3.04785 9.39186 3L9.3098 2.37113C9.30295 2.32328 9.25511 2.28906 9.20727 2.29591Z"
        fill="white"
      />
      <path
        d="M10.0479 18.0869C14.5179 18.0869 18.1416 14.4632 18.1416 9.99316C18.1416 5.52311 14.5179 1.89941 10.0479 1.89941C5.5778 1.89941 1.9541 5.52311 1.9541 9.99316C1.9541 14.4632 5.5778 18.0869 10.0479 18.0869Z"
        fill="url(#paint2_linear_2874_20979)"
        fillOpacity="0.2"
      />
      <path
        d="M15.6533 4.85938L9.12491 9.04301H9.11807V9.04978L9.11122 9.05663L5.03711 15.7764L11.0254 10.9571L11.0322 10.9503V10.9435L15.6533 4.85945L15.6533 4.85938Z"
        fill="black"
        fillOpacity="0.05"
      />
      <path
        d="M15.5303 4.46972L9.07715 9.05661L10.9912 10.957L15.5303 4.46972Z"
        fill="#CD151E"
      />
      <path
        d="M9.08398 9.04299L10.0479 9.99313L15.5302 4.46972L9.08398 9.04299Z"
        fill="#FA5153"
      />
      <path
        d="M9.08398 9.043L10.998 10.9433L4.54492 15.5303L9.08398 9.04293V9.043Z"
        fill="#ACACAC"
      />
      <path
        d="M4.54492 15.5303L10.0478 9.99315L9.08391 9.04293L4.54492 15.5303Z"
        fill="#EEEEEE"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2874_20979"
          x1="10"
          y1="18.75"
          x2="10"
          y2="1.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#DBDBDA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2874_20979"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.1372 8.22212) scale(9.87956)"
        >
          <stop stopColor="#2ABCE1" />
          <stop offset="0.11363" stopColor="#2ABBE1" />
          <stop offset="1" stopColor="#3375F8" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_2874_20979"
          x1="9.89407"
          y1="7.67884"
          x2="6.11639"
          y2="14.4564"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SafariIcon;
