import { Backdrop, List, SxProps, Theme } from "@mui/material";
import { Button } from "@common/Button";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import makeStyles from "@mui/styles/makeStyles";
import { BottomActions } from "./hooks/useMobileDrawerActions";
import MobileActionItem from "./MobileActionItem";
import { palette } from "@palette";
import { animated, useSpring } from "react-spring";
import { memo } from "react";

interface MobileDrawerActionsProps extends BottomActions {
  closeDrawer: () => void;
  openDrawer: () => void;
  isDrawerOpen: boolean;
  showButton?: boolean;
  buttonSx?: SxProps;
}

function MobileDrawerActions({
  label,
  closeDrawer,
  openDrawer,
  isDrawerOpen,
  items,
  showButton = true,
  buttonSx,
  ...rest
}: MobileDrawerActionsProps) {
  const { RootDrawer, buttonStyle } = useStyles();

  const handleOpenCloseDrawer = () => {
    if (isDrawerOpen) closeDrawer();
    else openDrawer();
  };

  const handleSelectItem = (onSelect: () => void, closeOnSelect = true) => {
    return () => {
      closeOnSelect && closeDrawer();
      onSelect?.();
    };
  };

  const visibleItems = items.filter((item) => !item?.hidden);
  const show = showButton && visibleItems.length > 0;

  const styles = useSpring({
    opacity: show ? 1 : 0,
    y: show ? -30 : 0,
    from: { opacity: 0, y: 0 },
    config: { duration: 500, easing: (t: any) => t * (2 - t) },
    delay: 1000,
  });

  const AnimatedButton = animated(Button);

  return (
    <>
      <SwipeableDrawerMobile
        anchor="bottom"
        onOpen={openDrawer}
        onClose={closeDrawer}
        open={isDrawerOpen}
        className={RootDrawer}
        {...rest}
      >
        <List disablePadding>
          {visibleItems.map(
            (
              { label, labelProps, closeOnSelect, onSelect, disabled, testId },
              index,
            ) => (
              <MobileActionItem
                key={index}
                onClick={handleSelectItem(onSelect, closeOnSelect)}
                disabled={disabled}
                data-testid={testId}
                {...labelProps}
              >
                {label}
              </MobileActionItem>
            ),
          )}
        </List>
      </SwipeableDrawerMobile>
      <Backdrop
        open={isDrawerOpen}
        sx={{
          backgroundColor: "rgba(53, 53, 53, 0.15)",
          backdropFilter: "blur(3px)",
          zIndex: 1,
        }}
      />
      {show && (
        <AnimatedButton
          style={styles}
          className={buttonStyle}
          onClick={handleOpenCloseDrawer}
          sx={{
            backgroundColor: palette.black[isDrawerOpen ? 100 : 300],
            ...buttonSx,
            position: "fixed",
          }}
          data-testid="animated-button"
        >
          {isDrawerOpen ? "Close" : label}
        </AnimatedButton>
      )}
    </>
  );
}

export default memo(MobileDrawerActions);

const useStyles = makeStyles((theme: Theme) => ({
  RootDrawer: {
    "& .MuiPaper-root": {
      margin: "auto",
      width: "90%",
      boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
      borderRadius: "16px",
      backgroundColor: theme.palette.background.dimmedWhite,
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
  buttonStyle: {
    minWidth: "171px",
    maxWidth: "max-content",
    margin: "auto",
    color: palette.background.dimmedWhite,
    zIndex: 2,
  },
}));
