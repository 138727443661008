import { memo } from "react";
import { Stack, SxProps } from "@mui/material";
import { Text } from "@common/Text";
import { getStatusMessage } from "@components/Settings/Business/Status/utils";
import { palette } from "@palette";
import { InfoIcon } from "@assets/icons";
import { styled } from "@mui/styles";

export type StatusTextProps = {
  data?: any;
  status?: string;
  updatedAt?: number | null;
  readyForVerificationAt?: number;
  updatedByName?: string | null;
  updatedByEmail?: string | null;
  statusText?: string;
  isLegalEntityStatus?: boolean;
  statusDisplayMessage?: string;
  sx?: SxProps;
};

const StatusText = ({
  statusText,
  isLegalEntityStatus = false,
  sx,
  ...props
}: StatusTextProps) => {
  const text = statusText
    ? statusText
    : getStatusMessage({ ...props, isLegalEntityStatus });
  return (
    <StyledContainer sx={sx}>
      <InfoIcon height={20} width={20} path_fill={palette.neutral[400]} />
      <Text
        fontSize={14}
        width="100%"
        variant="body"
        fontWeight="book"
        lineHeight="16.8px"
        color={palette.neutral[80]}
      >
        {text}
      </Text>
    </StyledContainer>
  );
};

const StyledContainer = styled(Stack)({
  gap: "16px",
  borderRadius: "8px",
  padding: "8px 16px",
  flexDirection: "row",
  backgroundColor: "white",
  alignItems: "center",
});
export default memo(StatusText);
