import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { IDropzoneProps } from "react-dropzone-uploader";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
// mui
import { useTheme, useMediaQuery } from "@mui/material";
// components
import { showMessage } from "@common/Toast/ShowToast";
// api
import { customInstance } from "@services/api";
import { useUploadCustomerImage } from "./useUploadCustomerImage";
import { useGetCustomerById } from "@services/api/customer";
// utils
import { getUnixTime, fromUnixTime, subYears } from "date-fns";
import { matchIsValidTel } from "mui-tel-input";
import { notSetButRequired } from "@hooks/helpers";
// hooks
import { CustomerData } from "./useCreateCustomer";
import { convertPhoneNumber, toUTCStartOfDay } from "@utils/date.helpers";
import { MERCHANT_VIEW_CUSTOMER } from "modals/modal_names";
import { useAppSelector } from "@redux/hooks";
import {
  MAXIMUM_REQUIRED_AGE,
  MINIMUM_REQUIRED_AGE,
} from "@constants/constants";
import { createPropertyOnChange } from "@utils/index";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectMasqueradeMode } from "@redux/slices/app";

export const useUpdateCustomerMutation = (
  merchantId: number,
  id: number,
  dirtyFields: any,
) => {
  return useMutation((data: any) => {
    const { country, city, state, zip, line1 } =
      (dirtyFields.address as {
        country?: boolean | undefined;
        city?: boolean | undefined;
        state?: boolean | undefined;
        zip?: boolean | undefined;
        line1?: boolean | undefined;
      }) || {};

    const address = {
      ...createPropertyOnChange(line1, "line1", data.address?.line1),
      ...createPropertyOnChange(country, "country", data.address?.country),
      ...createPropertyOnChange(city, "city", data.address?.city),
      ...createPropertyOnChange(zip, "zip", data.address?.zip),
      ...createPropertyOnChange(state, "state", data.address?.state),
    };

    const customData = {
      ...createPropertyOnChange(
        dirtyFields.firstName,
        "firstName",
        data.firstName,
      ),
      ...createPropertyOnChange(
        dirtyFields.lastName,
        "lastName",
        data.lastName,
      ),
      ...createPropertyOnChange(
        dirtyFields.dateOfBirth,
        "dateOfBirth",
        data.dateOfBirth,
      ),
      ...createPropertyOnChange(
        dirtyFields.avatarURL,
        "avatarURL",
        data.avatarURL,
      ),
      ...createPropertyOnChange(dirtyFields.email, "email", data.email),
      ...createPropertyOnChange(
        dirtyFields.employer,
        "employer",
        data.employer,
      ),
      ...createPropertyOnChange(dirtyFields.notes, "notes", data.notes),
      ...createPropertyOnChange(
        dirtyFields.occupation,
        "occupation",
        data.occupation,
      ),
      ...createPropertyOnChange(dirtyFields.address, "address", address),
      ...(dirtyFields.phone && { phoneNumber: data.phoneNumber }),
    };

    return customInstance({
      url: `merchants/${merchantId}/customers/${id}`,
      method: "PATCH",
      data: customData,
    });
  });
};

export const useViewCustomer = (id: number, overrideMerchantId?: number) => {
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [edit, setEdit] = React.useState(false);

  const selectedUser = useAppSelector(selectSelectedAccount);
  const { id: masqId, name: masqName } = useAppSelector(selectMasqueradeMode);
  const merchantId =
    masqName && typeof masqId === "number"
      ? masqId
      : overrideMerchantId || selectedUser?.id || 0;

  const { data, isLoading: customerDataIsLoading } = useGetCustomerById(
    id,
    overrideMerchantId,
  );

  const schema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required")
      .test({
        message: () => "Email already registered",
        test: async (value) => {
          if (value === data?.email) return true;
          if (value) {
            try {
              const response = await customInstance({
                url: `/merchants/${merchantId}/customers/email-check`,
                method: "POST",
                data: {
                  email: value,
                },
              });

              if (response.isAvailable) {
                return true;
              } else {
                return false;
              }
            } catch (error) {
              console.log(error);
            }
          }
          return false;
        },
      }),
    phone: Yup.string()
      .nullable()
      .when({
        is: (exists: string) => Boolean(exists && exists !== "+1"),
        then: (schema) =>
          schema.test({
            name: "is-valid-number",
            message: "Phone number already exists",
            test: async function (value) {
              if (
                !value ||
                value.length === 0 ||
                convertPhoneNumber(value) === data?.phoneNumber
              )
                return true;
              const isValid = matchIsValidTel(value as string);

              if (isValid) {
                try {
                  const response = await customInstance({
                    url: `/merchants/${merchantId}/customers/phonenumber-check`,
                    method: "POST",
                    data: {
                      phoneNumber: convertPhoneNumber(value),
                    },
                  });
                  if (response.isAvailable) {
                    return true;
                  } else {
                    return false;
                  }
                } catch (error) {
                  console.log(error);
                }
              }
              return this.createError({ message: "Insert valid phone number" });
            },
          }),
      }),
    dateOfBirth: Yup.date()
      .typeError("Please provide a valid date")
      .nullable()
      .min(subYears(new Date(), MAXIMUM_REQUIRED_AGE), "Must be 100 or less")
      .max(subYears(new Date(), MINIMUM_REQUIRED_AGE), `Must be 18 or older`),
    address: Yup.object().shape({
      state: Yup.string().nullable(),
      city: Yup.string().nullable(),
      line1: Yup.string().nullable(),
      zip: Yup.string()
        .nullable()
        .when({
          is: (exists: string) => !!exists,
          then: Yup.string().matches(
            /^[0-9]{5}(?:-?[0-9]{4})?$/,
            "Invalid ZIP format",
          ),
        }),
    }),

    occupation: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Please enter a valid occupation",
    ),
    employer: Yup.string().matches(
      /^[a-zA-Z\s]*$/,
      "Please enter a valid employer",
    ),
  });

  const queryClient = useQueryClient();
  const { handlePostImage } = useUploadCustomerImage();

  const handleClose = () => {
    setEdit(false);
    modal.hide();
  };

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: null,
    dateOfBirth: null,
    notes: "",
    employer: "",
    occupation: "",
    avatarURL: "",
  };

  const methods = useForm<CustomerData>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    reset,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const { mutate, isLoading } = useUpdateCustomerMutation(
    merchantId,
    id,
    dirtyFields,
  );

  const resetToDefault = () => {
    if (data) {
      const def = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: `+${data.phoneNumber || 1}`,
        dateOfBirth:
          typeof data.dateOfBirth === "number"
            ? fromUnixTime(data.dateOfBirth)
            : null,
        employer: data.employer,
        occupation: data.occupation,
        avatarURL: data.avatarURL,
        address: {
          country: data.address?.country,
          city: data.address?.city,
          state: data.address?.state,
          zip: data.address?.zip,
          line1: data.address?.line1,
        },
        notes: data.notes,
      };
      reset(def as any);
    }
  };

  React.useEffect(() => {
    resetToDefault();
  }, [data]);

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
    if (file) {
      setValue(
        "avatarURL",
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        { shouldDirty: true },
      );
    }
  };

  const onSubmit: SubmitHandler<CustomerData> = async (data) => {
    let url = "";
    if (typeof data.avatarURL === "string") {
      url = data.avatarURL;
    } else {
      url = await handlePostImage(data.avatarURL);
    }

    const _address = notSetButRequired(data.address);
    const updateData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber:
        data.phone && data.phone.length > 0
          ? convertPhoneNumber(data.phone)
          : null,
      dateOfBirth: !data.dateOfBirth
        ? null
        : typeof data.dateOfBirth === "number"
        ? data.dateOfBirth
        : getUnixTime(toUTCStartOfDay(data.dateOfBirth)),
      occupation: data.occupation,
      employer: data.employer,
      notes: data.notes,
      avatarURL: url ? url : null,
      address: _address
        ? {
            ...(_address as any),
            name: `${data.firstName} ${data.lastName}`,
            country: "US",
          }
        : null,
    };

    mutate(updateData, {
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        const errorInputs = error.response.data.input;
        const errorCode = error.response.data.code;

        if (errorCode === "invalid_input") {
          for (const err of errorInputs) {
            showMessage("Error", `${err.message}`, isDesktop, "", 6000);
          }
        } else {
          showMessage("Error", `${errorMessage}`);
        }
      },
      onSuccess: async (res: any) => {
        if (res.created !== null) {
          NiceModal.hide(MERCHANT_VIEW_CUSTOMER);
          showMessage(
            "Success",
            "Account information has been successfully updated",
            isDesktop,
          );
          reset(data);
          queryClient.refetchQueries("get-transaction-history");
          queryClient.refetchQueries("list-all-customers");
          queryClient.refetchQueries("customer");
        }
      },
    });
  };

  return {
    handleChangeStatus,
    onSubmit,
    handleClose,
    methods,
    isDisabled: isLoading,
    edit,
    setEdit,
    resetToDefault,
    data,
    customerDataIsLoading,
  };
};
