import ColorPicker from "@givecore/givesync-color-picker";
import { Box, Popover } from "@mui/material";
import { palette } from "@palette";
import { isMobileSafari } from "@utils/helpers";
import { debounce } from "lodash";
import { memo, MouseEvent } from "react";

type Props = {
  anchorEl: Element | null;
  handleClose: () => void;
  value?: string;
  onChange: (value: string) => void;
  hideOpacity?: boolean;
  hideGradientButton?: boolean;
};

const GradientPickerPopoverNew = ({
  anchorEl,
  handleClose,
  onChange,
  value,
  hideOpacity = true,
  hideGradientButton = false,
}: Props) => {
  return (
    <Popover
      id="colorPicker"
      open={Boolean(anchorEl)}
      //Set to null to fix safari mobile input issue
      anchorEl={isMobileSafari() ? null : anchorEl}
      onClose={handleClose}
    >
      <Box
        borderRadius="4px"
        bgcolor={palette.neutral.white}
        overflow="hidden"
        p="12px 8px"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <ColorPicker
          value={value}
          onChange={debounce(onChange, 20)}
          
          hidePresets
          hideOpacity={hideOpacity}
          hideGradientType
          hideGradientStop
          disableDarkMode
          hideGradientButton={hideGradientButton}
        />
      </Box>
    </Popover>
  );
};

export default memo(GradientPickerPopoverNew)