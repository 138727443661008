//all current challenge slugs that could exist in the app
export const challengeSlugs = {
  PRIMARY_1: "primary1",
  PRIMARY_3: "primary3",
  PRIMARY_4: "primary4",
  PRIMARY_5: "primary5",
  PRIMARY_6: "primary6",
  MERCHANT_INFO_1: "merchant_info1",
  MERCHANT_INFO_2: "merchant_info2",
  MERCHANT_INFO_3: "merchant_info3",
  MERCHANT_INFO_4: "merchant_info4",
  BUSINESS_PROFILE_1: "business_profile1",
  BUSINESS_PROFILE_3: "business_profile3",
  BUSINESS_OWNER_1: "business_owner1",
  GENERAL_WEB: "general_website1",
  GENERAL_MERCHANT_VERIFICATION: "general_mcc1",
  BANK_ACCOUNT_1: "bank_account1",
  BANK_ACCOUNT_2: "bank_account2",
  BANK_ACCOUNT_3: "bank_account3",
  BANK_ACCOUNT_4: "bank_account4",
};
