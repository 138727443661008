interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const FileDockIcon = ({
  width = 42,
  height = 42,
  fill = "none",
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_343_70230)">
        <path
          d="M23.0503 5.25H15.75C12.4502 5.25 10.8003 5.25 9.77513 6.27513C8.75 7.30025 8.75 8.95017 8.75 12.25V29.75C8.75 33.0498 8.75 34.6997 9.77513 35.7249C10.8003 36.75 12.4502 36.75 15.75 36.75H26.25C29.5498 36.75 31.1997 36.75 32.2249 35.7249C33.25 34.6997 33.25 33.0498 33.25 29.75V15.4497C33.25 14.7344 33.25 14.3768 33.1168 14.0552C32.9836 13.7336 32.7307 13.4807 32.2249 12.9749L25.5251 6.27513C25.0193 5.76932 24.7664 5.51642 24.4448 5.38321C24.1232 5.25 23.7656 5.25 23.0503 5.25Z"
          stroke={stroke}
          strokeWidth="2"
        />
        <path
          d="M15.75 22.75L26.25 22.75"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M15.75 29.75L22.75 29.75"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M22.75 5.25V12.25C22.75 13.8999 22.75 14.7249 23.2626 15.2374C23.7751 15.75 24.6001 15.75 26.25 15.75H33.25"
          stroke={stroke}
          strokeWidth="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_343_70230"
          x="0"
          y="0"
          width="42"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_343_70230"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FileDockIcon;
