import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  PEPResourcesIDs,
  TCheckDetails,
  UpdateStatusPayload,
  UpdtateStatusOptions,
} from "../types";
import { useUpdateCachedQuery } from "@utils/react.query";
import { useState } from "react";
import {
  getCheckDetails,
  updateStatus,
} from "@services/api/politicallyExposed/politicallyExposedActions";
import { createArrayFromObject, dummyObjects } from "../utils";

interface UseGetPepDetails extends PEPResourcesIDs {
  checkId: number;
}

const useGetPEPDetails = ({
  checkId,
  merchantId,
  selectedOwner,
  legalEntityID,
}: UseGetPepDetails) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();
  const { updateDataWithExceptions } =
    useUpdateCachedQuery("pep-checks-history");

  const { data, isLoading, isFetching } = useQuery(
    ["get-check-details", checkId, merchantId, selectedOwner, legalEntityID],
    async () => {
      const details = await getCheckDetails({
        checkId,
        merchantId,
        selectedOwner,
        legalEntityID,
      });

      const properties = details?.pepResult
        ? details?.pepResult?.map((obj: any) =>
            createArrayFromObject(obj?.properties),
          )
        : dummyObjects?.pepResult?.map((obj: any) =>
            createArrayFromObject(obj?.properties),
          );

      const res: TCheckDetails = {
        properties,
      };
      return res;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!checkId,
      retry: 0,
    },
  );
  const updateStatusMutation = useMutation(
    (data: UpdateStatusPayload) =>
      updateStatus({
        checkId,
        merchantId,
        selectedOwner,
        legalEntityID,
        data,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries("pep-checks-history");
        queryClient.invalidateQueries("get-merchant-preview");
      },
    },
  );
  const { mutate } = updateStatusMutation;

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateStatus = (newStatus: UpdtateStatusOptions) => {
    updateDataWithExceptions(
      (old = {}) => {
        if (!old || !old?.data || !Array?.isArray(old?.data)) return old;
        const item = old?.data.find((check: any) => check?.checkId === checkId);

        return {
          ...old,
          data: [...old.data, item],
        };
      },
      () => {
        handleCloseMenu();
        mutate({ pepStatus: newStatus });
      },
    );
  };

  return {
    data,
    isLoading: isFetching || isLoading,
    handleUpdateStatus,
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
  };
};

export default useGetPEPDetails;
