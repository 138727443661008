type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const StackedSquares = ({
  width = 25,
  height = 25,
  fill = "#403D3D",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.875 6.66406H4.375C4.17609 6.66406 3.98532 6.74308 3.84467 6.88373C3.70402 7.02438 3.625 7.21515 3.625 7.41406V20.9141C3.625 21.113 3.70402 21.3037 3.84467 21.4444C3.98532 21.585 4.17609 21.6641 4.375 21.6641H17.875C18.0739 21.6641 18.2647 21.585 18.4053 21.4444C18.546 21.3037 18.625 21.113 18.625 20.9141V7.41406C18.625 7.21515 18.546 7.02438 18.4053 6.88373C18.2647 6.74308 18.0739 6.66406 17.875 6.66406ZM17.125 20.1641H5.125V8.16406H17.125V20.1641ZM21.625 4.41406V17.9141C21.625 18.113 21.546 18.3037 21.4053 18.4444C21.2647 18.585 21.0739 18.6641 20.875 18.6641C20.6761 18.6641 20.4853 18.585 20.3447 18.4444C20.204 18.3037 20.125 18.113 20.125 17.9141V5.16406H7.375C7.17609 5.16406 6.98532 5.08504 6.84467 4.94439C6.70402 4.80374 6.625 4.61297 6.625 4.41406C6.625 4.21515 6.70402 4.02438 6.84467 3.88373C6.98532 3.74308 7.17609 3.66406 7.375 3.66406H20.875C21.0739 3.66406 21.2647 3.74308 21.4053 3.88373C21.546 4.02438 21.625 4.21515 21.625 4.41406Z"
        fill={fill}
      />
    </svg>
  );
};

export default StackedSquares;
