import { UseQueryOptions, useQuery } from "react-query";
import { customInstance } from ".";
import { ProductParams } from "./products/queryFactory";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import { useMemo } from "react";
import { updatePermissions } from "@redux/slices/app";
import { useGetCurrentMerchantId } from "@hooks/common";
import useFindMerchantStatsById from "@hooks/acquirer-api/merchants/stats/useFindMerchantStatsById";

export const getMerchants = ({
  queryString,
  page,
  sorting,
  searchQuery,
  filter,
}: ProductParams) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  const filterQuery = filter
    ? "filter=" + encodeURIComponent(`;${filter}`)
    : "filter=";
  const pageQuery = page ? `&page=${page}` : "";

  return customInstance({
    url: `/submerchants?${filterQuery}${queryString}&sort=${
      sorting || "-accID"
    }${search}${pageQuery}&max=${ROWS_PER_PAGE}`,
    method: "GET",
  });
};

export const baseGetMerchantStats = (
  id: string | number,
  isMerchant?: boolean,
) => {
  if (id == 0) return Promise.resolve(0);
  return customInstance({
    url: isMerchant
      ? `merchants/${id}/transactions/stats`
      : `/merchants/${id}/stats`,
    method: "GET",
  });
};

export const getMerchantLevels = (merchId: string) => {
  return baseGetMerchantStats(merchId as string);
};

export const useGetMerchants = (
  params: ProductParams,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
  filter?: string,
) => {
  const { merchantId } = useGetCurrentMerchantId();
  const dispatch = useAppDispatch();
  const { data: stats, isLoading: isLoadingStats } =
    useFindMerchantStatsById(merchantId);

  const { data, isLoading, error, isFetching, refetch, isPreviousData } =
    useQuery(
      [
        "list-acquirer-merchants",
        params.queryString,
        params.page,
        params.sorting,
        params.searchQuery,
        filter,
      ],
      async () => await getMerchants(params),
      {
        ...options,
        retry: 2,
        onError(err: any) {
          if (err.not_authorized) {
            dispatch(
              updatePermissions({
                get_merchants_list: true,
              }),
            );
          }
          if (options.onError) {
            options.onError(err);
          }
        },
      },
    );

  const statsData = {
    countApproved: stats?.countApproved || 0,
    countInoperative: stats?.countInoperative || 0,
    countUnderwriting: stats?.countUnderwriting || 0,
    countTotal: (stats?.countRisk || 0) + (stats?.countUnderwriting || 0),
    countAquirerUnderwriting: stats?.countAcquirerUnderwriting || 0,
    countRisk: stats?.countRisk || 0,
    countTotalAquirer:
      (stats?.countAcquirerUnderwriting || 0) + (stats?.sponsorCount || 0),
    sponsorCount: stats?.sponsorCount || 0,
  };

  const hasNoMerchants =
    stats?.countAcquirerUnderwriting === 0 &&
    stats?.countApproved === 0 &&
    stats?.sponsorCount === 0 &&
    stats?.countRisk === 0;

  return {
    data,
    stats: statsData,
    error,
    isLoading: isLoading || isLoadingStats,
    isFetching,
    refetch,
    isPreviousData,
    hasNoMerchants,
  };
};

interface IReturnLegalEntityTypes {
  data: LegalEntityType[];
  total: number;
}

export const useGetLegalEntitiesTypes = () => {
  const dispatch = useAppDispatch();
  const QKEY = "get-legal-entities-types";
  const searchQuery = useAppSelector((state) => selectQueryString(state, QKEY));

  let baseURL = "legal-entity-types";
  if (searchQuery) baseURL += `?q=${searchQuery}`;

  const getBusinessTypes = async () =>
    await customInstance({
      method: "GET",
      url: `/${baseURL}`,
    });

  const { data, ...rest } = useQuery<IReturnLegalEntityTypes>(
    [QKEY],
    async () => await getBusinessTypes(),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              get_legal_entities_types: true,
            }),
          );
        }
      },
    },
  );

  const filteredData = useMemo(() => {
    if (!data?.data) return [];
    if (!searchQuery) return data.data;
    return data.data.filter((item) =>
      item.name.includes(searchQuery.toLowerCase()),
    );
  }, [data, searchQuery]);
  return {
    data: {
      ...data,
      data: filteredData,
    },
    ...rest,
  };
};

type LegalEntityType = {
  createdAt: number;
  description: string;
  id: number;
  name: string;
  updatedAt: number;
};

export const getMerchantAcquirer = (id: number | string) => {
  return customInstance({
    url: `merchants/${id}/acquirer`,
    method: "GET",
  });
};

export const useGetMerchantAcquirer = (id: number | string, props?: any) => {
  const key = ["get-merchant-acquirer", id];
  return useQuery(
    key,
    async () => {
      return await getMerchantAcquirer(id);
    },
    {
      ...props,
      retry: 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 100000000,
      cacheTime: 300000,
      // enabled: false,
    },
  );
};
