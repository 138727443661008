// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const JobsIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="-3 -3 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.69541 0.400391C1.42683 0.400391 0.398438 1.42878 0.398438 2.69736V13.3034C0.398438 14.572 1.42683 15.6004 2.69541 15.6004H13.3015C14.57 15.6004 15.5984 14.572 15.5984 13.3034V2.69736C15.5984 1.42878 14.57 0.400391 13.3015 0.400391H11.1289C10.7975 0.400391 10.5289 0.66902 10.5289 1.00039C10.5289 1.33176 10.7975 1.60039 11.1289 1.60039H13.3015C13.9073 1.60039 14.3984 2.09152 14.3984 2.69736V13.3034C14.3984 13.9093 13.9073 14.4004 13.3015 14.4004H2.69541C2.08957 14.4004 1.59844 13.9093 1.59844 13.3034V2.69736C1.59844 2.09152 2.08957 1.60039 2.69541 1.60039H7.8052C8.13658 1.60039 8.4052 1.33176 8.4052 1.00039C8.4052 0.66902 8.13658 0.400391 7.8052 0.400391H2.69541ZM8.52116 4.60839C8.52116 4.27702 8.25253 4.00839 7.92116 4.00839C7.58979 4.00839 7.32116 4.27702 7.32116 4.60839V11.5801C7.32116 11.9115 7.58979 12.1801 7.92116 12.1801C8.25253 12.1801 8.52116 11.9115 8.52116 11.5801V4.60839ZM5.88612 7.06901C5.88612 6.73764 5.61749 6.46901 5.28612 6.46901C4.95475 6.46901 4.68612 6.73764 4.68612 7.06901V11.5801C4.68612 11.9115 4.95475 12.1801 5.28612 12.1801C5.61749 12.1801 5.88612 11.9115 5.88612 11.5801V7.06901ZM11.1562 8.70942C11.1562 8.37805 10.8876 8.10942 10.5562 8.10942C10.2248 8.10942 9.9562 8.37805 9.9562 8.70942V11.5801C9.9562 11.9115 10.2248 12.1801 10.5562 12.1801C10.8876 12.1801 11.1562 11.9115 11.1562 11.5801V8.70942Z"
          fill="url(#paint0_linear_1701_19206)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1701_19206"
            x1="15.5984"
            y1="0.400391"
            x2="0.788626"
            y2="15.9715"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="-3 -3 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.69541 0.400391C1.42683 0.400391 0.398438 1.42878 0.398438 2.69736V13.3034C0.398438 14.572 1.42683 15.6004 2.69541 15.6004H13.3015C14.57 15.6004 15.5984 14.572 15.5984 13.3034V2.69736C15.5984 1.42878 14.57 0.400391 13.3015 0.400391H11.1289C10.7975 0.400391 10.5289 0.66902 10.5289 1.00039C10.5289 1.33176 10.7975 1.60039 11.1289 1.60039H13.3015C13.9073 1.60039 14.3984 2.09152 14.3984 2.69736V13.3034C14.3984 13.9093 13.9073 14.4004 13.3015 14.4004H2.69541C2.08957 14.4004 1.59844 13.9093 1.59844 13.3034V2.69736C1.59844 2.09152 2.08957 1.60039 2.69541 1.60039H7.8052C8.13658 1.60039 8.4052 1.33176 8.4052 1.00039C8.4052 0.66902 8.13658 0.400391 7.8052 0.400391H2.69541ZM8.52116 4.60839C8.52116 4.27702 8.25253 4.00839 7.92116 4.00839C7.58979 4.00839 7.32116 4.27702 7.32116 4.60839V11.5801C7.32116 11.9115 7.58979 12.1801 7.92116 12.1801C8.25253 12.1801 8.52116 11.9115 8.52116 11.5801V4.60839ZM5.88612 7.06901C5.88612 6.73764 5.61749 6.46901 5.28612 6.46901C4.95475 6.46901 4.68612 6.73764 4.68612 7.06901V11.5801C4.68612 11.9115 4.95475 12.1801 5.28612 12.1801C5.61749 12.1801 5.88612 11.9115 5.88612 11.5801V7.06901ZM11.1562 8.70942C11.1562 8.37805 10.8876 8.10942 10.5562 8.10942C10.2248 8.10942 9.9562 8.37805 9.9562 8.70942V11.5801C9.9562 11.9115 10.2248 12.1801 10.5562 12.1801C10.8876 12.1801 11.1562 11.9115 11.1562 11.5801V8.70942Z"
        fill={fill}
      />
    </svg>
  );
};

export default JobsIcon;
