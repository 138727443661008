import { Stack, Box } from "@mui/material";
import ModalSectionTitle from "../atoms";
import { Checkbox, RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { ComingSoonTag } from "@common/Tag";
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type EventConfigurationProps = {
  title: string;
  titleSize?: "large" | "medium";
  isEdit?: boolean;
};

const EventConfiguration = ({
  title,
  titleSize = "large",
  isEdit = false,
}: EventConfigurationProps) => {
  return (
    <Stack direction="column" gap={isEdit ? 2 : 4} flexGrow={1}>
      <FadeUpWrapper delay={100}>
        <ModalSectionTitle title={title} titleSize={titleSize} />
      </FadeUpWrapper>
      <Stack direction="column" gap="24px">
        <FadeUpWrapper delay={200}>
          <Box sx={maxTicketsBoxStyle}>
            <Text fontWeight="book" color={palette.neutral[80]}>
              Max tickets that can be purchased at once
            </Text>
            <RHFCustomIntegerInput
              name="configuration.maxTickets"
              width="82px"
              min={1}
              max={99}
            />
          </Box>
        </FadeUpWrapper>
        <FadeUpWrapper delay={300}>
          <RHFCheckbox
            name="configuration.customer_pays_fees"
            label="Customer pays the credit card fee"
            sx={{
              "& .MuiButtonBase-root.MuiCheckbox-root": {
                height: 24,
              },
            }}
          />
        </FadeUpWrapper>
        <FadeUpWrapper delay={400}>
          <Stack direction="column">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <RHFCheckbox
                disabled
                name="configuration.browse_more"
                label="Browse more Payment forms"
                sx={{
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    height: 24,
                  },
                }}
              />
              <ComingSoonTag />
            </Stack>
            <Text
              color={palette.gray[300]}
              fontSize="16px"
              fontWeight="book"
              lineHeight="19.2px"
              ml={4}
            >
              Allow users to explore more of your published Payment forms.
            </Text>
          </Stack>
        </FadeUpWrapper>
      </Stack>
    </Stack>
  );
};

const maxTicketsBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  padding: "8px",
  alignItems: "center",
  background: palette.neutral[10],
  borderRadius: "8px",
};

export default EventConfiguration;
