import { getMerchantId } from "@utils/helpers";

const buildUrl = (
  url: string,
  parent: string,
  customParam?: string | number | null,
) => {
  if (!customParam) return url;

  return `/${parent}/${customParam}/${url}`;
};

const buildWithMerchantId = (
  url: string,
  parent: string,
  customId?: number | string,
) => {
  const merchant = getMerchantId();
  const id = typeof merchant === "number" ? merchant : merchant.id;
  return buildUrl(url, parent, customId ? customId : id);
};

export const buildAccountsUrl = (url: string) =>
  buildWithMerchantId(url, "accounts");

export const buildMerchantEndpoints = (url: string, id?: number | string) =>
  buildWithMerchantId(url, "merchants", id);
