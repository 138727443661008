import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// mui
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import theme from "./theme";
// app
import App from "./App";
// redux
import { ReactReduxProvider } from "./redux/Provider";
// localization
import { ResetActionProvider } from "@common/Error/ResetActionContext";
import { Toast } from "@common/Toast";
import CustomThemeProvider from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import MasqueradeSnackbar from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import { FileUploadProvider } from "@components/UploadFile/FileUploadContext";
import "assets/fonts/GiveWhyte/stylesheet.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "./localization/index";
import "./modals";
import FeatureFlagProvider from "FeatureFlags/FeatureFlagProvider";

// import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
      refetchOnWindowFocus: false,
    },
  },
});

const root = document.getElementById("root");
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ReactReduxProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ResetActionProvider>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <MasqueradeSnackbar />
              <CssBaseline />
              <CustomThemeProvider>
                <FileUploadProvider>
                  <FeatureFlagProvider>
                    <App />
                  </FeatureFlagProvider>
                </FileUploadProvider>
              </CustomThemeProvider>
              <Toast />
            </ResetActionProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ReactReduxProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
