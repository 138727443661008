import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  TBusinessOwner,
  TBusinessProfileInfo,
  TMerchantHeader,
  TPrimaryAccountHolder,
} from "../data.types";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { OFACPanel } from "../OFAC";

interface IProps {
  headerData: TMerchantHeader;
  merchantId: number;
  profileData: TBusinessProfileInfo;
  PAH: TPrimaryAccountHolder;
  businessOwners?: TBusinessOwner[];
  isEnterprisePanel?: boolean;
}

const OFACCheckModal = NiceModal.create(
  ({
    headerData,
    merchantId,
    profileData,
    businessOwners,
    PAH,
    isEnterprisePanel,
  }: IProps) => {
    const modal = useModal();
    const { isMobileView } = useCustomTheme();
    const { role } = useUserReducer();
    const isAcquirer = role === "acquirer";

    const handleCancel = () => {
      modal.hide();
    };

    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 570,
          DialogProps: {
            PaperProps: {
              sx: {
                height: "100%",
              },
            },
            contentContainerSx: { height: "100%" },
          },
        }}
      >
        <OFACPanel
          isModal
          isAcquirer={isAcquirer}
          headerData={{
            name: headerData?.name,
            imageURL: headerData?.imageURL,
          }}
          merchantId={merchantId}
          legalEntityId={profileData?.id}
          lastCheck={profileData?.ofac}
          toggleSecondModal={handleCancel}
          checksListHeight="calc(90vh - 500px)"
          businessOwners={businessOwners}
          PAH={PAH}
          isEnterprisePanel={isEnterprisePanel}
        />
      </ModalFactory>
    );
  },
);

export default OFACCheckModal;
