import { groupData } from "features/Permissions/Modal/utils";
import { IAssignmentFilter, TPermissionsData } from "../types";
import { dataReducerWithFilter } from "./permissionData.utils";

const defaultTabs = [
  {
    label: "Acquirer",
    hidden: false,
    disabled: true,
  },
  {
    label: "Provider",
    hidden: false,
    disabled: true,
  },
  {
    label: "Merchant",
    hidden: false,
    disabled: true,
  },
];

type TGroup = {
  [k: string]: string[];
};

export const generateDynamicTabs = (
  showLatest: boolean,
  dataGroups: TGroup,
  latestDataGroups: TGroup,
) => {
  let dynamicTabs: typeof defaultTabs = [];
  if (!showLatest && !Object.keys(dataGroups).length) {
    dynamicTabs = defaultTabs;
  } else {
    const groups = showLatest ? latestDataGroups : dataGroups;
    dynamicTabs = Object.keys(groups).map((g) => ({
      label: g,
      disabled: false,
      hidden: false,
    }));
  }
  return dynamicTabs.sort((a, b) => a.label.localeCompare(b.label));
};

export const updateDataHandler = (key: string, value: boolean, old: any) => {
  if (!old || !old[key])
    return {
      assigned: value || false,
      key,
      data: old,
    };

  const assigned = value;
  return {
    assigned,
    key,
    data: {
      ...old,
      [key]: {
        ...old[key],
        assigned,
      },
    },
  };
};

export const generateGroupedData = (
  filter: IAssignmentFilter,
  rawData?: TPermissionsData,
) => {
  const customData = dataReducerWithFilter(filter, rawData);
  const groups = groupData(customData);

  return {
    data: customData,
    groups,
  };
};
