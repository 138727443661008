import { ChangeItem } from "@services/api/changelog/changelog";
import { format } from "date-fns";
import { toCountryName } from "@hooks/helpers";
import {
  businessTypes,
  BusinessUnionTypes,
} from "@common/BusinessProfileInputs/BusinessTypeSelect";
import { betterPhoneNumber } from "@components/Merchants/MerchantPreview/hooks/useGetSectionItems";
import { toEnFormatWithDecimal } from "@utils/index";

export const createCustomAction = (data: ChangeItem) => {
  if (!data?.oldValue && data.newValue) return "add";
  else if (
    (data?.oldValue && !data.newValue) ||
    (!data?.oldValue && !data.newValue) ||
    (data.fieldName.includes("Phone") && data.newValue.toString().length === 1)
  )
    return "remove";
  else return "update";
};

export const formatFieldValue = (value: any, field: string) => {
  if (field.includes("date")) {
    return format(value * 1000, "MM/dd/yyyy");
  }
  if (
    field.includes("citizenship country") ||
    field.includes("country of residence")
  ) {
    return toCountryName(value);
  }
  if (field.includes("ticket amount") || field.includes("annual revenue")) {
    return `${toEnFormatWithDecimal((value || 0) / 100)} USD`;
  }
  if (["tax id"].includes(field)) {
    return `XXXXXX-${value?.slice(-4)}`;
  }
  if (field?.includes("account number")) {
    return !!value && `•••• ${value?.slice(-4)}`;
  }
  if (["business type"].includes(field)) {
    return businessTypes[value as BusinessUnionTypes];
  }
  if (["customer service", "phone number"].includes(field)) {
    return betterPhoneNumber(value);
  }
  if (["ownership"].includes(field)) {
    return `${value}%`;
  }

  return value;
};

export const formatFieldLabel = (field: string | number | undefined) => {
  if (typeof field === "number" || !field) return field;

  if (field.includes("tax id")) {
    return capitalizePartOfString(field, -2);
  }
  if (field.includes("customer service")) {
    return `${field} phone number`;
  }
  if (field.includes("doing business as (dba)")) {
    return capitalizePartOfString(field, -4);
  }
  if (field.includes("countries serviced outside the usa/canada")) {
    return "business service countries outside the USA/Canada";
  }

  return field;
};

export const capitalizePartOfString = (
  value: string,
  startingIndex: number,
) => {
  return (
    value.slice(0, startingIndex) + value.slice(startingIndex).toUpperCase()
  );
};
