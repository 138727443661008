import { useEffect } from "react";
import flagsmith from "flagsmith";
import { Text } from "@common/Text";
import { showMessage } from "@common/Toast";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { useResetApp } from "./common/useHandleLogout";

const SCHEDULER_TIME = 90000;

const storageDto = () => {
  const versionKey = "v";
  const isVersionUpdatedKey = "version_updated";

  const currentAppVersion = localStorage.getItem(versionKey);
  const isVersionUpdated = localStorage.getItem(isVersionUpdatedKey);

  return {
    versionKey,
    currentAppVersion,
    isVersionUpdated,
    updateVersion: (newVersion?: string) => {
      localStorage.setItem(versionKey, newVersion || "1.0");
    },
    updateIsVersionUpdated: (newValue: "true" | "false") => {
      localStorage.setItem(isVersionUpdatedKey, newValue);
    },
    removeIsVersionUpdated: () => {
      localStorage.removeItem(isVersionUpdatedKey);
    },
  };
};

export const useVersionUpdate = () => {
  const { clearLocalData, handleReset } = useResetApp();

  const {
    versionKey,
    currentAppVersion,
    isVersionUpdated,
    updateIsVersionUpdated,
    updateVersion,
    removeIsVersionUpdated,
  } = storageDto();

  const handleUpdate = (newVersion: string) => () => {
    clearLocalData();
    updateIsVersionUpdated("true");
    updateVersion(newVersion);
    window.history.pushState({}, "", "/login"); //change pathname without refreshing. Without this, for a brief the curent pathname is the previous one and is gona look like a glitch
    window.location.reload();
  };

  const pushMessage = (newVersion: string) => {
    showMessage(
      "Info",
      "New version is available, please refresh the page",
      true,
      "New version",
      false,
      {
        style: {
          width: "340px",
        },
      },
      {
        action: <Action handleUpdate={handleUpdate(newVersion)} />,
      },
    );
  };

  function checkForUpdates() {
    const newVersion = flagsmith.getValue("react_app_version");

    if (newVersion && currentAppVersion !== newVersion) {
      pushMessage(newVersion as string);
    }

    setTimeout(checkForUpdates, SCHEDULER_TIME);
  }

  function listenStorage(e: StorageEvent) {
    if (e.key === versionKey) {
      //here probabaly the user tampered with the localstorage manually
      pushMessage(flagsmith.getValue("react_app_version") as string);
    }
  }

  const onStart = async () => {
    await flagsmith.getFlags();

    if (isVersionUpdated === "true") {
      removeIsVersionUpdated();
      handleReset();
    }
    // appendVersionToScripts(process.env.REACT_APP_VERSION || "1.0");
    checkForUpdates();
  };

  useEffect(() => {
    onStart();

    flagsmith.startListening(SCHEDULER_TIME);
    window.addEventListener("storage", listenStorage);

    return () => {
      flagsmith.stopListening();
      window.removeEventListener("storage", listenStorage);
    };
  }, []);
};

function Action({ handleUpdate }: any) {
  return (
    <Button
      onClick={handleUpdate}
      sx={{
        marginTop: "auto",
        marginBottom: "auto",
        padding: "1px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      size="medium"
      background="text"
    >
      <Text fontSize="16px" color={palette.givebox[200]}>
        Refresh
      </Text>
    </Button>
  );
}

function appendVersionToScripts(version: string) {
  document.addEventListener("DOMContentLoaded", () => {
    const scripts = document.querySelectorAll("script[src]");

    scripts.forEach((script) => {
      let src = script.getAttribute("src");

      if (src) {
        // Check if src already has a query string
        if (src.includes("?")) {
          // If there is already a query string, append &version=version
          src += `&version=${version}`;
        } else {
          // Otherwise, append ?version=version
          src += `?version=${version}`;
        }

        script.setAttribute("src", src);
      }
    });
  });
}
