import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type IconProps = SvgIconProps & {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
};

const DownloadIcon = ({
  width = 20,
  height = 20,
  fill = "none",
  stroke = "#8F8F8F",
  ...rest
}: IconProps) => {
  return (
    <svg
      data-testid="download-icon"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.33338L9.88215 13.2155C9.94724 13.2806 10.0528 13.2806 10.1179 13.2155L15 8.33338M10 12.9167C10 9.66234 10 3.33337 10 3.33337M5 17.5L15 17.5"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DownloadIcon;
