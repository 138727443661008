import { Components } from "@mui/material";

export const menu: Components["MuiMenu"] = {
  styleOverrides: {
    root: {
      top: 10,
      "& .MuiPaper-root": {
        padding: "4px",
        borderRadius: "8px",
        background: "#F8F8F6",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
      },
      "& .MuiList-padding": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
};
