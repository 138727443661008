import { Avatar, Box, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";
import { QuestionMark } from "@phosphor-icons/react";
import { Tooltip } from "@common/Tooltip";

interface IComponentProps {
  withTooltip?: boolean;
  isEnabled: boolean;
  imageURL?: string;
  firstName?: string;
  lastName?: string;
  reason?: string;
}

const EnabledBy = ({
  withTooltip = true,
  isEnabled,
  imageURL,
  firstName = "",
  lastName = "",
  reason,
}: IComponentProps) => {
  if (!firstName && !lastName) return null;
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Text fontWeight="book" fontSize={12} color={palette.neutral["70"]}>
        {isEnabled ? "Enabled" : "Disabled"} by
      </Text>
      <Stack direction="row" gap={0.5} alignItems="center">
        <Avatar
          sx={{ width: 20, height: 20 }}
          src={imageURL ? `${imageURL}/thumb` : AvatarPlaceholder}
        />
        <Text
          fontWeight="book"
          fontSize={12}
          color={palette.neutral["80"]}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth={200}
        >
          {`${firstName} ${lastName}`}
        </Text>
        {withTooltip && reason && (
          <Tooltip title={reason}>
            <IconBox>
              <QuestionMark
                size={13}
                color={palette.neutral.white}
                style={{ marginLeft: 1 }}
              />
            </IconBox>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

const IconBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: palette.gray[100],
  borderRadius: "100%",
  width: 16,
  height: 16,
  cursor: "pointer",
});

export default EnabledBy;
