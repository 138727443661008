/* 
    This tree builder is not for building nodes.
    Is represeting the tagName in the widgets builder.
    Is like a blueprint for the actual node and later on onClick in WidgetItems.tsx we create the node
*/

type StyleParser = () => any;

interface TreeNode {
  id?: any;
  tag: string;
  styleParser: StyleParser;
  content?: string;
  metaAttributes?: any;
  cssProperties?: any;
  childrens: TreeNode[];
  isHead?: boolean;
}

interface NodeBlueprint {
  setRoot: ({ tag, styleParser }: any) => NodeBlueprint;
  addNode: (
    parentId: any,
    { id, tag, styleParser, content = "", metaAttributes, cssProperties }: any,
  ) => NodeBlueprint;
  getTree: () => any;
}

function treeNodeBuilder({
  tag,
  tabletTag,
  mobileTag,
  styleParser,
  content = "",
  childrens = [],
  id,
  bindings,
  metaAttributes,
  cssProperties,
  stateParser,
}: any) {
  return {
    tag,
    tabletTag,
    mobileTag,
    styleParser,
    content,
    childrens,
    id,
    bindings,
    metaAttributes,
    cssProperties,
    stateParser,
    blueprintID: id,
  };
}

export function treeBuilder(): NodeBlueprint {
  // id counting starts from 2. 1 is root node
  let root: TreeNode | null = null;

  const findNodeById = (node: any, id: any, parent = null) => {
    if (!node) return null;
    if (node.id === id) return { node, parent };
    for (const child of node.childrens) {
      const found = findNodeById(child, id, node) as any;
      if (found) return found;
    }
    return null;
  };

  const builderWidget = {
    setRoot({
      tag,
      bindings,
      styleParser,
      stateParser,
      tabletTag,
      mobileTag,
    }: any) {
      /*
        -we do not pass parentId because represents the parent id of the node blueprint
        -but here, after the node is created, we need to keep track of the root node ID which is 1
        -check widgetFactory.tsx
        */
      root = {
        isHead: true,
        ...treeNodeBuilder({
          tag,
          tabletTag,
          mobileTag,
          bindings,
          styleParser,
          stateParser,
          childrens: [],
          id: 2,
        }),
      };

      return this;
    },

    addNode(
      parentId: number,
      {
        id,
        tag,
        tabletTag,
        mobileTag,
        styleParser,
        stateParser,
        bindings,
        content = "",
        metaAttributes,
        cssProperties,
        childrens,
      }: any,
    ) {
      const newNode = treeNodeBuilder({
        tag,
        tabletTag: tabletTag,
        mobileTag: mobileTag,
        styleParser,
        content,
        childrens: childrens ?? [],
        id,
        parentId,
        bindings,
        metaAttributes,
        cssProperties,
        stateParser,
      });

      if (!root) {
        console.error("Root is not set. Please set the root first.");
      } else {
        const parentNodeData = findNodeById(root, parentId);
        if (parentNodeData) {
          parentNodeData.node.childrens.push(newNode);
        } else {
          console.error(`Parent node with id ${parentId} not found`);
        }
      }
      return this;
    },
    getTree() {
      return root;
    },
  };

  return builderWidget;
}
