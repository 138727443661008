import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

const MapMarker = () => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
    >
      <path
        d="M12.8008 6.19922C12.8008 9.51293 6.80078 15.7992 6.80078 15.7992C6.80078 15.7992 0.800781 9.51293 0.800781 6.19922C0.800781 2.88551 3.48707 0.199219 6.80078 0.199219C10.1145 0.199219 12.8008 2.88551 12.8008 6.19922Z"
        fill="url(#paint0_linear_1667_14347)"
      />
      <circle cx="6.8" cy="6.19844" r="1.8" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_1667_14347"
          x1="12.8008"
          y1="0.199219"
          x2="-2.08142"
          y2="12.2355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1} />
          <stop offset="1" stopColor={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MapMarker;
