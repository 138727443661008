import * as Yup from "yup";
import { AmountType, TPaymentTypes } from "./types";
import { ContentState, EditorState } from "draft-js";

const paymentTypes: TPaymentTypes[] = [
  "one_time",
  "monthly",
  "quarterly",
  "yearly",
];

export const PaymentFormModalSchema = Yup.object().shape({
  about: Yup.object().shape({
    title: Yup.string().trim().required("This field is required"),
    description: Yup.string(),
  }),
  style: Yup.object().shape({
    image: Yup.mixed().nullable(),
    useAsBackground: Yup.boolean(),
  }),
  payment_set_up: Yup.object().shape({
    amountsList: Yup.array<AmountType>().min(1),
  }),
  configuration: Yup.object().shape({
    customer_pays_fees: Yup.boolean(),
    browse_more: Yup.boolean(),
  }),
});

const anyAmountDescription = EditorState.createWithContent(
  ContentState.createFromText("Any payment amount is accepted."),
);

const initialAmounts = [
  {
    id: "1",
    active: true,
    title: "Any Amount",
    description: anyAmountDescription,
    amount: "",
    isDefault: true,
    min_max: {
      enabled: false,
      min: "1.00",
      max: "25,000.00",
    },
  },
];

export const PaymentFormModalDefaults = {
  about: {
    title: "",
    description: "",
  },
  displayItems: false,
  style: {
    image: null,
    useAsBackground: false,
  },
  payment_set_up: {
    payment_types: {
      default: "one_time" as TPaymentTypes,
      one_time: true,
      monthly: false,
      quarterly: false,
      yearly: false,
    },
    amountsList: initialAmounts,
  },
  configuration: {
    customer_pays_fees: false,
    browse_more: false,
  },
};
