type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const ArrowSquareIn = ({
  width = 19,
  height = 19,
  stroke = "#6D9CF8",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.50031 10.3594V14.8594C9.50031 15.0086 9.44105 15.1516 9.33556 15.2571C9.23007 15.3626 9.087 15.4219 8.93781 15.4219C8.78863 15.4219 8.64555 15.3626 8.54007 15.2571C8.43458 15.1516 8.37531 15.0086 8.37531 14.8594V11.7178L3.71078 16.3823C3.65852 16.4346 3.59648 16.4761 3.52819 16.5043C3.45991 16.5326 3.38672 16.5472 3.31281 16.5472C3.2389 16.5472 3.16572 16.5326 3.09743 16.5043C3.02915 16.4761 2.96711 16.4346 2.91484 16.3823C2.86258 16.3301 2.82113 16.268 2.79284 16.1998C2.76456 16.1315 2.75 16.0583 2.75 15.9844C2.75 15.9105 2.76456 15.8373 2.79284 15.769C2.82113 15.7007 2.86258 15.6387 2.91484 15.5864L7.57938 10.9219H4.43781C4.28863 10.9219 4.14555 10.8626 4.04007 10.7571C3.93458 10.6516 3.87531 10.5086 3.87531 10.3594C3.87531 10.2102 3.93458 10.0671 4.04007 9.96163C4.14555 9.85614 4.28863 9.79688 4.43781 9.79688H8.93781C9.087 9.79688 9.23007 9.85614 9.33556 9.96163C9.44105 10.0671 9.50031 10.2102 9.50031 10.3594ZM15.1253 3.04688H6.12531C5.82694 3.04688 5.5408 3.1654 5.32982 3.37638C5.11884 3.58736 5.00031 3.87351 5.00031 4.17188V7.54688C5.00031 7.69606 5.05958 7.83913 5.16507 7.94462C5.27055 8.05011 5.41363 8.10938 5.56281 8.10938C5.712 8.10938 5.85507 8.05011 5.96056 7.94462C6.06605 7.83913 6.12531 7.69606 6.12531 7.54688V4.17188H15.1253V13.1719H11.7503C11.6011 13.1719 11.4581 13.2311 11.3526 13.3366C11.2471 13.4421 11.1878 13.5852 11.1878 13.7344C11.1878 13.8836 11.2471 14.0266 11.3526 14.1321C11.4581 14.2376 11.6011 14.2969 11.7503 14.2969H15.1253C15.4237 14.2969 15.7098 14.1783 15.9208 13.9674C16.1318 13.7564 16.2503 13.4702 16.2503 13.1719V4.17188C16.2503 3.87351 16.1318 3.58736 15.9208 3.37638C15.7098 3.1654 15.4237 3.04688 15.1253 3.04688Z"
        fill={stroke}
      />
    </svg>
  );
};

export default ArrowSquareIn;
