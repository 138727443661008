import { useQuery } from "react-query";
import { customInstance } from "../../services/api";

export const useVerifyPasswordResetToken = ({ token }: { token: string }) => {
  const { isLoading, isError } = useQuery(
    ["verify-password-reset-token", token],
    {
      queryFn: async () => {
        return await customInstance({
          url: `password-resets/${token}`,
          method: "GET",
          withCredentials: false,
        });
      },
      retry(failureCount) {
        // retry twice
        return failureCount < 1;
      },
    },
  );
  return {
    isLoading,
    isError,
  };
};
