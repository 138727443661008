import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";
import XCircle from "@assets/icons/RebrandedIcons/XCircle";
import { Text } from "@common/Text";
import CheckCircle from "@assets/icons/RebrandedIcons/CheckCircle";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { Button } from "@common/Button";
import { ChevronRightIcon } from "@assets/icons";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
import { MERCHANT_AGREEMENT_UPDATE_MODAL } from "modals/modal_names";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";

const createContent = (template: string) => {
  const parts = template.split(/<strong>|<\/strong>/);

  return parts.map((part, index) => {
    if (index % 2 === 1) {
      // The parts that were inside <strong>
      return <strong key={index}>{part}</strong>;
    } else {
      // The other parts of the string
      return part;
    }
  });
};

interface Props {
  status: "signed" | "not_signed" | "pending";
  merchantId?: number;
  accountHolderNameFullName?: string;
  dateTime?: string;
  version?: string;
  lastVersion?: string;
  isLoading?: boolean;
  onCheckUpdates?: () => void;
  isEnterPrise?: boolean;
  signatureURL?: string;
}

type StringifyFun = (props?: any) => string;

type Data = {
  bgColor: string;
  headerColor: string;
  textColor: string;
  content: StringifyFun;
  icon: (props: any) => JSX.Element;
};

const data = {
  signed: {
    bgColor: "#E6F3EC",
    headerColor: "#13A75A",
    textColor: "#106D3D",
    icon: CheckCircle,
    content: ({ accountHolderNameFullName, dateTime, version }: any) =>
      `<strong>${accountHolderNameFullName}</strong> Agreed on <strong>${moment(
        dateTime,
      ).format("MM/DD/YYYY")}</strong> to Version <strong>${version}</strong>`,
  },
  not_signed: {
    bgColor: "#F5E0DF",
    headerColor: "#D80D0D",
    textColor: "#941A1A",
    icon: XCircle,
    content: () => "Agreement has not been signed yet.",
  },
  pending: {
    bgColor: "#FFF2E9",
    headerColor: "#FF8124",
    textColor: "#C3641D",
    icon: WarningIcon,
    content: ({
      accountHolderNameFullName,
      dateTime,
      version,
      lastVersion,
    }: any) =>
      `<strong>${accountHolderNameFullName}</strong> Agreed on <strong>${dateTime}</strong> to Version <strong>${lastVersion}</strong> but a new TOS version (<strong>${version}</strong>) is available`,
  },
};

export const Disclaimer = ({
  status,
  accountHolderNameFullName,
  dateTime,
  lastVersion,
  version,
  isLoading,
  merchantId,
  onCheckUpdates,
  isEnterPrise,
  signatureURL,
}: Props) => {
  if (isLoading)
    return (
      <Skeleton
        sx={{
          borderRadius: "4px",
        }}
        width="100%"
        variant="rounded"
        height="115px"
      />
    );

  return {
    signed: (
      <ContainerUI
        data={data[status]}
        accountHolderNameFullName={accountHolderNameFullName}
        dateTime={dateTime}
        version={version}
        isEnterPrise={isEnterPrise}
      />
    ),
    not_signed: <ContainerUI isEnterPrise={isEnterPrise} data={data[status]} />,
    pending: (
      <ContainerUI
        data={data[status]}
        accountHolderNameFullName={accountHolderNameFullName}
        dateTime={dateTime}
        version={version}
        lastVersion={lastVersion}
        status={status}
        merchantId={merchantId}
        onCheckUpdates={onCheckUpdates}
        isEnterPrise={isEnterPrise}
        signatureURL={signatureURL}
      />
    ),
  }[status];
};

const ContainerUI = ({
  data,
  accountHolderNameFullName,
  dateTime,
  lastVersion,
  version,
  status,
  merchantId,
  onCheckUpdates,
  isEnterPrise,
  signatureURL,
}: Partial<Props> & { data: Data }) => {
  const { isMasqueradeMode } = useMasqueradeReducer();
  const handleCheckUpdate = () => {
    if (onCheckUpdates) {
      onCheckUpdates();
      return;
    }

    NiceModal.show(MERCHANT_AGREEMENT_UPDATE_MODAL, {
      version: lastVersion,
      merchantId,
      signatureURL,
    });
  };

  return (
    <Box
      style={{
        borderRadius: "12px",
        padding: "12px 16px",
        marginBottom: "24px",
        backgroundColor: data.bgColor,
      }}
    >
      <Grid display="flex" alignItems="center" marginBottom="8px" gap={1}>
        <data.icon width="24px" height="24px" color={data.headerColor} />
        <Text
          fontSize={"18px"}
          fontWeight="regular"
          color={data.headerColor}
          lineHeight="120%"
        >
          {isEnterPrise ? "Provider" : "Merchant"} Agreement
        </Text>
      </Grid>
      <Text
        fontSize={"14px"}
        fontWeight="book"
        color={data.textColor}
        lineHeight="120%"
        style={{
          opacity: "0.8",
        }}
        data-testid="disclaimer-content"
      >
        {createContent(
          data.content({
            accountHolderNameFullName,
            dateTime,
            lastVersion,
            version,
          }),
        )}
      </Text>
      {status === "pending" && !isMasqueradeMode && (
        <Button
          background="tertiary"
          endIcon={<ChevronRightIcon fill={palette.warning.text} />}
          onClick={handleCheckUpdate}
          sx={{
            padding: 0,
            color: palette.warning.text,
            textDecoration: "underline",
            fontWeight: 400,
            marginLeft: "auto",
          }}
        >
          Check Update
        </Button>
      )}
    </Box>
  );
};
