type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
  path_fill?: string;
};

const CreditCardIcon = ({
  width = 24,
  height = 24,
  fill = "none",
  path_fill = "#734089",
  stroke = "#734089",
}: Props) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.12646 11.5H21.1265V15.5C21.1265 17.3856 21.1265 18.3284 20.5407 18.9142C19.9549 19.5 19.0121 19.5 17.1265 19.5H7.12646C5.24085 19.5 4.29804 19.5 3.71225 18.9142C3.12646 18.3284 3.12646 17.3856 3.12646 15.5V11.5Z"
        fill={path_fill}
        fillOpacity="0.75"
      ></path>
      <rect
        height="13"
        rx="2"
        stroke={stroke}
        strokeWidth="1.2"
        width="18"
        x="3.12646"
        y="6.5"
      ></rect>
      <path
        d="M7.12646 15.5H7.13646"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.2"
      ></path>
      <path
        d="M3.12646 11.5L21.1265 11.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="1.2"
      ></path>
    </svg>
  );
};

export default CreditCardIcon;
