import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import { IconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { MoreActionButton } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/MoreActionButton";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Grid, SxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import { useState } from "react";

type Props = {
  onCloseDrawer: () => void;
  setSelectedRow?: any;
  isFirst?: boolean;
  isLast?: boolean;
  actionsOnly?: boolean;
  actions: Array<{
    title: string;
    onClick: () => void;
    hidden: boolean;
    sx: SxProps;
  }>;
};

const DeveloperApiHeader = ({
  onCloseDrawer,
  setSelectedRow,
  isFirst,
  isLast,
  actions,
  actionsOnly,
}: Props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Grid container alignItems="center" gap="16px" mb={3} data-testid="developer-api-header">
        {!actionsOnly && (
          <>
            <IconButton
              onClick={onCloseDrawer}
              size="small"
              className={classes.iconBtn}
            >
              <CustomArrowRight />
            </IconButton>
            <div
              style={{
                width: 1,
                height: "12px",
                borderLeft: `1px solid ${palette.neutral[30]}`,
              }}
            />
            <IconButton
              size="small"
              className={`${classes.iconBtn} ${classes.iconBtnText}`}
              onClick={() => {
                setSelectedRow("prev");
              }}
              disabled={isFirst}
              data-testid="transaction-header-prev-button"
            >
              <ArrowUpward width="20px" height="20px" />
              <Text sx={{ paddingLeft: "8px" }}>Previous</Text>
            </IconButton>
            <IconButton
              size="small"
              className={`${classes.iconBtn} ${classes.iconBtnText}`}
              onClick={() => {
                setSelectedRow("next");
              }}
              disabled={isLast}
              data-testid="transaction-header-next-button"
            >
              <Text sx={{ paddingRight: "8px" }}>Next</Text>
              <ArrowDownward width="20px" height="20px" />
            </IconButton>
          </>
        )}
        {actions.length ? <MoreActionButton onClick={onOpenMenu} testId="more-actions-button" /> : null}
      </Grid>
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    "&:hover": {
      background: palette.neutral[20],
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
    },
  },
}));

export default DeveloperApiHeader;
