type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const CellSignalFullIcon = ({
  width = 25,
  height = 25,
  stroke = "#FF5924",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M20 4.24998V19.25C20 19.6478 19.8419 20.0293 19.5606 20.3106C19.2793 20.5919 18.8978 20.75 18.5 20.75H3.49998C3.20329 20.7498 2.91331 20.6617 2.66671 20.4968C2.42011 20.3318 2.22796 20.0975 2.11456 19.8233C2.00116 19.5491 1.97162 19.2475 2.02965 18.9566C2.08769 18.6656 2.2307 18.3984 2.4406 18.1887L17.4406 3.18873C17.6505 2.97935 17.9177 2.83687 18.2085 2.77926C18.4993 2.72166 18.8007 2.75152 19.0745 2.86507C19.3484 2.97862 19.5824 3.17077 19.7471 3.41726C19.9119 3.66375 19.9998 3.95352 20 4.24998Z"
        fill={stroke}
      />
    </svg>
  );
};
export default CellSignalFullIcon;
