import React from "react";
import { styled, Stack, useMediaQuery, useTheme, Box } from "@mui/material";
import PaymentCard, {
  PaymentCardProps,
  TSubscriptionPeriod,
} from "./PaymentCard";
import { palette } from "@palette";
import {
  SubtractMinusIcon,
  AddButtonIcon,
  VisualIndicator,
} from "@assets/icons";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  findCartItemQuantity,
  selectCart,
  setCartItem,
} from "@redux/slices/cart";
import { Text } from "@common/Text";

type QuantityPaymentCardProps = PaymentCardProps & {
  id: number;
  disabled?: boolean;
  category?: "events" | "membership" | "sweepstakes";
  maxPurchase?: number;
  recurringMax?: number | null;
  handleChange?: any;
  shouldDisplayLeftTickets?: boolean;
  setActiveCards?: React.Dispatch<React.SetStateAction<number[]>>;
};

const QuantityPaymentCard = ({
  quantity,
  disabled = false,
  category,
  id,
  maxPurchase = 5,
  activePaymentType,
  recurringMax,
  shouldDisplayLeftTickets = true,
  setActiveCards,
  ...props
}: QuantityPaymentCardProps) => {
  const dispatch = useAppDispatch();
  const savedQty = useAppSelector(findCartItemQuantity(+id));
  const { cartItems } = useAppSelector(selectCart);

  const [currentAmount, setCurrentAmount] = React.useState<number>(savedQty);
  const isButtonDisabled = disabled || !quantity || quantity < 0;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const purchaseCount = React.useMemo(
    () => cartItems.reduce((acc: number, item) => acc + item.quantity, 0),
    [cartItems],
  );

  const isOutOfStock = quantity === 0;

  function handleQuantityChange(
    event: React.MouseEvent<HTMLElement>,
    operation: string,
  ) {
    if (
      (currentAmount < 1 && operation === "subtract") ||
      disabled ||
      !props.price
    ) {
      return;
    }
    const item = {
      name: props.name || "",
      productVariantID: +id,
      price: props.price,
      recurringInterval: activePaymentType,
      recurringMax: recurringMax || null,
    };

    if (
      operation === "add" &&
      currentAmount < (quantity || 0) &&
      maxPurchase > purchaseCount
    ) {
      dispatch(
        setCartItem({
          ...item,
          quantity: currentAmount + 1,
        }),
      );
      setCurrentAmount((prevState) => prevState + 1);
    } else if (operation === "subtract") {
      if (currentAmount === 1) {
        setActiveCards &&
          setActiveCards((prev: number[]) =>
            prev.filter((item: number) => {
              return item !== id;
            }),
          );
      }
      dispatch(
        setCartItem({
          ...item,
          quantity: currentAmount - 1,
        }),
      );
      setCurrentAmount((prevState) => prevState - 1);
    }
  }

  React.useEffect(() => {
    setCurrentAmount(savedQty);
  }, [savedQty]);

  const backgroundColor = React.useMemo(() => {
    const qLeft = quantity! - currentAmount;
    switch (true) {
      case qLeft < 21 && qLeft > 0:
        return {
          backgroundColor: "#FFE8DA80",
          color: "#FF8124",
        };
      case qLeft < 1:
        return {
          backgroundColor: "#E1E1DE",
          color: "#575353",
        };
      default:
        return {
          backgroundColor: palette.success.light,
          color: palette.success.main,
        };
    }
  }, [currentAmount]);

  return (
    <PaymentCard
      selected={props.selected}
      disabled={disabled}
      quantity={quantity}
      category={category}
      onChange={props.handleChange}
      amount={currentAmount}
      ticketId={id}
      sx={{
        ...((isOutOfStock || disabled) && {
          opacity: 0.5,
          pointerEvents: "none",
        }),
      }}
      quantityLeftComponent={
        shouldDisplayLeftTickets &&
        !disabled && (
          <Text
            sx={{
              textAlign: "center",
              color: backgroundColor.color,
              backgroundColor: backgroundColor.backgroundColor,
              borderRadius: "4px",
              paddingInline: "12px",
              minWidth: "80px",
              marginY: isDesktop ? "initial" : 0,
            }}
            my={2}
            data-testid={`ticket-${id}-left-amount`}
          >
            {quantity! - currentAmount > 0
              ? quantity! - currentAmount + " left"
              : "Sold Out"}
          </Text>
        )
      }
      secondaryActions={
        isDesktop ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VisualIndicator width={24} height={24} />
          </Box>
        )
      }
      actions={
        <>
          <AdditionButton
            sx={{
              backgroundColor: "transparent",
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              return handleQuantityChange(event, "subtract");
            }}
            data-testid="quantity-payment-card-subtract-button"
            disabled={isButtonDisabled}
          >
            <SubtractMinusIcon
              width={isDesktop ? 24 : 24}
              height={isDesktop ? 24 : 24}
              stroke={disabled ? palette.neutral[200] : palette.neutral[400]}
            />
          </AdditionButton>
          <Box
            fontSize="14px"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: isDesktop ? "neutral.black" : "transparent",
              color: isDesktop ? "neutral.white" : palette.gray[100],
              width: "32px",
              height: "32px",
              userSelect: "none",
              ...(!isDesktop && {
                fontSize: "28px",
                textAlign: "center",
                lineHeight: "135%",
                letterSpacing: "-0.28px",
              }),
            }}
            data-testid="payment-card-current-amount"
          >
            {currentAmount}
          </Box>
          <AdditionButton
            sx={{
              backgroundColor: "transparent",
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              return handleQuantityChange(event, "add");
            }}
            data-testid="quantity-payment-card-add-button"
            disabled={isButtonDisabled}
          >
            <AddButtonIcon
              width={isDesktop ? 20 : 24}
              height={isDesktop ? 20 : 24}
              stroke={disabled ? palette.neutral[200] : palette.neutral[400]}
            />
          </AdditionButton>
        </>
      }
      {...props}
    />
  );
};

const AdditionButton = styled("button")(({ theme }) => ({
  background: "#FFF",
  border: "none",
  color: palette.neutral[400],
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50%",

  [theme.breakpoints.down("sm")]: {
    height: "100%",
  },

  "&:active": {
    background: palette.neutral[50],
  },
  "&:focus": {
    outline: "none",
  },
  "@media (hover:hover)": {
    "&:hover": {
      cursor: "pointer",
    },
  },
  "&:disabled": {
    cursor: "default",
    background: "#FFF",
    color: palette.neutral[400],
  },
}));

export default QuantityPaymentCard;
