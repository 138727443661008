import React from "react";
import Webcam from "react-webcam";

export const CameraFlow = ({
  data,
  webcamRef,
}: {
  data: string | null;
  webcamRef: any;
}) => {
  return (
    <>
      {data ? (
        <img
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "8px",
          }}
          src={data}
        />
      ) : (
        <WebcamCapture webcamRef={webcamRef} />
      )}
    </>
  );
};

export const WebcamCapture = ({ webcamRef, videoStyle }: any) => {
  return (
    <Webcam
      ref={webcamRef}
      autoFocus
      audio={false}
      videoConstraints={{
        width: 3000,
        height: 3000,
        facingMode: "user",
      }}
      screenshotQuality={1}
      screenshotFormat="image/jpeg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: "800%",
        height: "800%",
        objectFit: "cover",
        borderRadius: 8,
        ...videoStyle,
        transform: `scale(0.125)`,
        transformOrigin: "top left",
      }}
    />
  );
};
