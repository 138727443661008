import * as React from "react";
import { palette } from "@palette";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
// mui
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { RHFInput } from "@common/Input";
import { RHFSwitch } from "@common/Switch";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
// redux
import { useAppDispatch } from "@redux/hooks";
import { AnyAmountType, editAnyAmount } from "@redux/slices/fundraisers";
// localization
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { Button_V2 } from "@common/Button/Button";
import { isFunction } from "lodash";

type EditAnyAmountProps = {
  amountData: AnyAmountType | undefined;
  setIsEditAnyAmount: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: (data?: any) => void;
};

type IFormInputs = {
  title: string;
  description: string;
  min_max: {
    enabled: boolean;
    min: string;
    max: string;
  };
};

const getValueFromString = (value: string | number) =>
  typeof value === "string" ? parseInt(value.replaceAll(",", "")) : value;

const EditAnyAmount = ({
  setIsEditAnyAmount,
  amountData,
  setIsSubmitDisabled,
  handleSubmit,
}: EditAnyAmountProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation(namespaces.pages.fundraiserMinibuilder);

  const schema = Yup.object().shape({
    title: Yup.string().required("Title is mandatory"),
    min_max: Yup.object().shape({
      enabled: Yup.boolean().test(
        "max-min-values-test",
        "Max value should be greater than Min value",
        function (value) {
          return (
            getValueFromString(this.parent.max) >
            getValueFromString(this.parent.min)
          );
        },
      ),
      min: Yup.string(),
      max: Yup.string(),
    }),
  });

  const defaultValues = {
    ...amountData,
    title: amountData?.title ?? "",
    description:
      (typeof amountData?.description === "object"
        ? amountData?.description?.text
        : amountData?.description) ?? "",
    min_max: {
      enabled: amountData?.min_max.enabled ?? false,
      min: amountData?.min_max.min ?? "",
      max: amountData?.min_max.max ?? "",
    },
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    setError,
    formState: { errors },
  } = methods;
  const values = watch();

  const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
    const amount = {
      id: amountData?.id ?? "01",
      title: formData.title || "Any Amount",
      description: formData.description || t("thank_you_for_your_contribution"),
      amount: "any",
      active: amountData?.active ?? false,
      isDefault: true,
      min_max: {
        enabled: formData.min_max.enabled,
        min: formData.min_max.min,
        max: formData.min_max.max,
      },
    };

    dispatch(editAnyAmount(amount)), setIsEditAnyAmount(false);
    isFunction(setIsSubmitDisabled) && setIsSubmitDisabled(false);
  };

  React.useEffect(() => {
    setError("min_max.enabled", { message: "" });
  }, [values.min_max.max, values.min_max.min]);
  const isCustom = isFunction(handleSubmit);
  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(isCustom ? handleSubmit : onSubmit)}
      >
        <StyledContainer
          gap={2}
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          isCustom={isCustom}
        >
          <Box gridColumn="span 12">
            <RHFInput
              name="title"
              label={t("title")}
              fullWidth
              placeholder={t("Any Amount", { ns: namespaces.common })}
            />
          </Box>

          <Box gridColumn="span 12">
            <Stack
              direction={isCustom ? "column" : "row"}
              spacing={2}
              alignItems={isCustom ? "flex-start" : "center"}
              width="100%"
            >
              <RHFSwitch name="min_max.enabled" />
              <div>
                <Text variant="headline" color={palette.black[200]}>
                  {t("set_min_max_value")}
                </Text>
                <Text variant="caption" color={palette.filled.red}>
                  {errors?.min_max?.enabled?.message}
                </Text>
              </div>
            </Stack>

            <Collapse orientation="vertical" in={values.min_max.enabled}>
              <Box sx={minMaxContainerStyle({ isCustom })}>
                <Text
                  mt={2}
                  color={palette.neutral[600]}
                  sx={{ "span > span": { fontWeight: 500 } }}
                  {...(isCustom && {
                    align: "left",
                  })}
                >
                  <Markup content={t("set_min_max_value_text")} />
                </Text>

                <Box sx={minMaxBoxStyle({ isCustom })}>
                  <RHFCustomAmountInput
                    width="100%"
                    name="min_max.min"
                    label={t("min")}
                    initialValue={amountData?.min_max.min}
                    currency="usd"
                  />
                  <RHFCustomAmountInput
                    width="100%"
                    name="min_max.max"
                    label={t("max")}
                    initialValue={amountData?.min_max.max}
                    currency="usd"
                  />
                </Box>
              </Box>
            </Collapse>
          </Box>

          <Box gridColumn="span 12">
            <RHFInput
              name="description"
              label={t("description")}
              fullWidth
              placeholder="Any amount is highly appreciated, we are empowered by people like you."
              multiline
              rows={3}
            />
          </Box>
          <Box
            gridColumn="span 12"
            display="flex"
            flexDirection={isDesktop ? "row" : "column-reverse"}
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <Button_V2
              onClick={() => setIsEditAnyAmount(false)}
              background="tertiary"
            >
              {t("cancel")}
            </Button_V2>
            <Button_V2
              type="submit"
              fullWidth={!isDesktop}
              background="primary"
            >
              {t("save_changes")}
            </Button_V2>
          </Box>
        </StyledContainer>
      </Box>
    </FormProvider>
  );
};
interface StyledContainerProps extends BoxProps {
  isCustom: boolean;
}
const StyledContainer = styled(Box)<StyledContainerProps>(
  ({ theme, isCustom }: any) => ({
    padding: "16px",
    marginTop: "8px",
    borderRadius: "16px",
    border: isCustom
      ? `1px solid ${palette.gray[100]}`
      : `2px solid ${palette.neutral[950]}`,
    [theme.breakpoints.down("sm")]: {
      padding: "16px 8px",
    },
  }),
);

const minMaxContainerStyle = ({ isCustom }: any) => ({
  pl: isCustom ? 0 : 8,
  gap: "16px",
  display: "flex",
  flexDirection: "column",

  "@media (max-width: 600px)": {
    pl: 0,
  },
});

const minMaxBoxStyle = ({ isCustom }: any) => ({
  padding: "16px 8px",
  display: "flex",
  gap: "16px",
  borderRadius: "5px",
  border: isCustom
    ? `1px solid ${palette.gray[100]}`
    : `2px solid ${palette.neutral[950]}`,

  "@media (max-width: 600px)": {
    flexDirection: "column",
  },
});

export default EditAnyAmount;
