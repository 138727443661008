import React, { useCallback, useEffect, useState } from "react";
import { useFormData } from "../CreateCampaignFormProvider";
import { AmountType } from "@redux/slices/fundraisers";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";
import { isEmpty } from "lodash";
import usePaymentTypes from "./usePaymentTypes";

interface Props {
  handleUpdateStatusValue: (value: number) => void;
  backToWelcomePage?: () => void;
  campaignType?: string;
  handleNext: () => void;
}

export default function usePaymentSetup({
  handleUpdateStatusValue,
  backToWelcomePage,
  campaignType,
  handleNext,
}: Props) {
  const { handleNext: handleFinish, isLoading: isPaymentTypeLoading } =
    usePaymentTypes({
      handleUpdateStatusValue,
      backToWelcomePage,
    });

  const { formData, setFormValues } = useFormData();
  const [{ contextValues, ...state }, setState] = useState<stateType>({
    ...initialStates,
    contextValues: formData.paymentSetup ?? [],
  });
  const { handlePostImage } = useUploadVariantImage();
  useEffect(() => {
    if (contextValues.length > 0) return handleUpdateStatusValue(100);
    handleUpdateStatusValue(0);
  }, [contextValues.length]);

  const toggleIsOpen = useCallback(
    () =>
      setState((prev) => {
        const editingObject = prev.editingObject;
        const contextValues = [...prev.contextValues];
        editingObject && contextValues.push(editingObject);
        const newObject = {
          ...prev,
          contextValues: contextValues,
          editingObject: undefined,
          isOpen: !prev.isOpen,
        };

        setFormValues("paymentSetup", newObject.contextValues);
        return newObject;
      }),
    [],
  );

  const handleSubmit = useCallback(async (data: any) => {
    let newImgURL: string | null = null;
    try {
      if (typeof data?.thumbnail === "string" || !data?.thumbnail) {
        newImgURL = data.thumbnail;
      } else {
        newImgURL = await handlePostImage(data.thumbnail);
      }
    } catch (error) {
      newImgURL = null;
    }

    const customVariant = {
      ...data,
      active: true,
      thumbnail: newImgURL || null,
    };

    setState((prev: stateType) => {
      const foundIndex = prev.contextValues.findIndex(
        (obj) => obj.id === data.id,
      );
      const oldArray = prev.contextValues;
      const newObject = {
        ...prev,
        contextValues:
          foundIndex !== -1
            ? (oldArray[foundIndex] = data)
            : [
                ...oldArray,
                {
                  ...customVariant,
                  id: data.id ? data.id : Math.random().toString(),
                },
              ],
        isOpen: false,
        editingObject: undefined,
      };

      setFormValues("paymentSetup", newObject.contextValues);
      return newObject;
    });
  }, []);

  const deleteAction = (id: string) => {
    setState((prev: stateType) => {
      const newArr = prev.contextValues?.filter(
        (obj: AmountType) => obj.id !== id,
      );
      setFormValues("paymentSetup", newArr);

      return { ...prev, contextValues: newArr, isOpen: false };
    });
  };
  const onDragEnd = (array: AmountType[]) => {
    setState((prev) => {
      const newObject = {
        ...prev,
        contextValues: array,
      };
      setFormValues("paymentSetup", newObject.contextValues);
      return newObject;
    });
  };

  const handleEditing = (id: string) => {
    setState((prev) => {
      const objectToEdit = prev.contextValues.find((v) => v.id === id);

      const newObject = {
        ...prev,
        isOpen: true,
        editingObject: objectToEdit,
        contextValues: prev.contextValues.filter(
          (v) => v.id !== objectToEdit?.id,
        ),
      };

      return newObject;
    });
  };

  const onShowAmountItem = (id: string) => {
    setState((prev) => {
      const newItem = prev.contextValues.find((v) => v.id === id);
      newItem && (newItem.active = !newItem?.active);
      const newArr = newItem
        ? prev.contextValues.map((obj) => (obj.id === id ? newItem : obj))
        : prev.contextValues;

      const newObject = {
        ...prev,
        contextValues: newArr,
      };
      setFormValues("paymentSetup", newObject.contextValues);
      return newObject;
    });
  };

  const isAnyAmount = typeof state?.editingObject?.description === "string";

  return {
    onShowAmountItem,
    ...state,
    contextValues,
    toggleIsOpen,
    deleteAction,
    handleSubmit,
    onDragEnd,
    handleEditing,
    isAnyAmount,
    handleCorrectNext:
      campaignType && campaignType === "Standard" ? handleFinish : handleNext,
    isPaymentTypeLoading:
      campaignType && campaignType === "Standard"
        ? isPaymentTypeLoading
        : false,
  };
}

type stateType = {
  isOpen: boolean;
  isAddAmountHidden: boolean;
  isSubmitDisabled: boolean;
  isLoading: boolean;
  editingObject: undefined | AmountType;
  contextValues: AmountType[] | [];
};

const initialStates = {
  isOpen: false,
  isAddAmountHidden: true,
  isSubmitDisabled: false,
  isLoading: false,
  editingObject: undefined,
  contextValues: [],
};
