import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as Icon } from "../svgIcons/search.svg";

interface IProps {
  width: number;
  height?: number;
}

const SearchIcon = ({ width, height }: IProps) => {
  return (
    <SvgIcon
      component={Icon}
      inheritViewBox
      sx={{ width: width, height: height || 24 }}
    />
  );
};

export default SearchIcon;
