import React, { useRef, useState } from "react";
import { Chip, TextField, Autocomplete } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import Close from "@mui/icons-material/Close";
import { Text } from "@common/Text";
import { isArray } from "lodash";
import { palette } from "@palette";
import { Input } from "@common/Input";
import { Box } from "@mui/material";

interface MultipleInputsProps {
  name: string;
  multiple?: boolean;
  forceEnableButton?: (v: boolean) => void
}

const MultipleInputs: React.FC<MultipleInputsProps> = ({
  name,
  multiple = false,
  forceEnableButton,
}) => {
  const { control, setValue, getValues, trigger, clearErrors } =
    useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const getEmailsArray = () => {
    if (multiple) {
      const currentTags = getValues(name) || [];
      return [...currentTags, inputValue];
    } else {
      return [inputValue];
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const hasAtSign = value.indexOf("@") > -1;

    setInputValue(value);
    forceEnableButton && forceEnableButton(hasAtSign);
  };

  const createChip = () => {
    const emailsArray = getEmailsArray();
    setValue(name, emailsArray, { shouldDirty: true });
    trigger(name);
    setInputValue("");
  }
  const onKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    if ([" ", ",", "Enter"].includes(key)) {
      event.preventDefault();

     createChip();
    }
  };

  const focusOnInput = () => {
    inputRef?.current?.focus();
    setIsFocused(true);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const val =
          field?.value && field?.value?.length > 0 ? field?.value[0] : "";
        return (
          <>
            {multiple ? (
              <Autocomplete
                multiple
                disableClearable
                freeSolo
                limitTags={1}
                sx={inputStyles}
                options={[]}
                value={field.value || []}
                inputValue={inputValue}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") setInputValue("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="Enter email"
                    placeholder="Email"
                    onKeyDown={onKeydown}
                    onChange={handleInputChange}
                  />
                )}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                      style={{
                        color: "#13A75A",
                        backgroundColor: "#E6F3EC",
                        border: "none",
                        marginTop: 15,
                        borderRadius: "4px",
                        padding: "4px 8px",
                      }}
                      deleteIcon={
                        <Close
                          sx={{
                            fill: "#13A75A",
                            width: 17,
                            height: 17,
                          }}
                        />
                      }
                    />
                  ))
                }
              />
            ) : (
              <Box
                bgcolor="#FFFFFF"
                borderRadius="8px"
                border={`2px solid ${isFocused ?isArray(error) && error?.length > 0 ? "red": "#8F8F8F" : "#E1E1DE"}`}
                height="74px"
                p="12px"
                onClick={focusOnInput}
              >
                <Text
                  color="#8F8F8F"
                  fontWeight="regular"
                  fontSize="14px"
                  mb={0}
                  onClick={focusOnInput}
                >
                  Enter email
                </Text>
                {val ? (
                  <Box alignItems="center" display="flex">
                    <Box
                      borderRadius="4px"
                      bgcolor="#E6F3EC"
                      height="25px"
                      p="4px 8px"
                      display="flex"
                      alignItems="center"
                      gap="2px"
                    >
                      <Text
                        color="#13A75A"
                        fontSize="14px"
                        fontWeight="regular"
                      >
                        {val}{" "}
                      </Text>
                      <Close
                        sx={{
                          fill: "#13A75A",
                          width: 17,
                          height: 17,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setValue(name, []);
                          clearErrors(name);
                          trigger(name);
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Input
                    value={inputValue}
                    sx={{
                      "&  .MuiInputBase-root": {
                        height: "auto !important",
                        border: "none !important",
                        p: 0,
                      },
                    }}
                    inputRef={inputRef}
                    onChange={handleInputChange}
                    onKeyDown={onKeydown}
                    onBlurCapture={createChip}
                    placeholder="email"
                    fullWidth
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    disabled={false}
                  />
                )}
              </Box>
            )}

            {isArray(error) && error?.length > 0 && (
              <Text
                fontWeight="regular"
                paddingInline="10px"
                fontSize="12px"
                color={"red"}
                textAlign="left"
                marginTop={-1}
              >
                {isArray(error) && error[error?.length - 1]?.message}
              </Text>
            )}
          </>
        );
      }}
    />
  );
};

export default MultipleInputs;

const inputStyles = {
  ".MuiInputBase-root.MuiFilledInput-root::before, .MuiInputBase-root.MuiFilledInput-root::after, .MuiInputBase-root.MuiFilledInput-root::before":
    {
      borderBottom: "none",
    },
  "&:hover": {
    borderBottom: "none",
  },
  "& .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator, & .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator":
    {
      backgroundColor: "transparent",
    },
  ".MuiAutocomplete-clearIndicator": {
    backgroundColor: "transparent",
  },
};
