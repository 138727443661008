import { namespaces } from "../../../resources/i18n.constants";

export const customers = {
  [namespaces.pages.customers]: {
    customersList: "Customers List",
    search: "Search...",
    downloadReport: "Download Report",
    profile: "Profile",
    joined: "Joined",
    total: "Total",
    addCustomer: "Add Customer",
    customersInCRM: "Customers in CRM",
    importCustomers: "Import Customers",
    importContacts: "Import Contacts",
    recurringOnly: "Recurring Only",
    Understood: "Understood",
    AvgPerCustomer: "Avg Raised Per Customer",
    Customers: "Customers",
    recurringCustomers: "Recurring Customers ",
    repeatCustomers: "Repeat Customers ",
    transactionStatistics: "Transaction Statistics ",
    discard: "Discard ",
    customerInfo: "Customer Info",
    generalInfo: "General Info",
    Donation: "Donation",
    Invoices: "Invoices",
    Memberships: "Memberships",
    Sweepstakes: "Sweepstakes",
    Total: "Total",
    Back: "Back",
    firstName: "First Name",
    lastName: "Last Name",
    emailRequired: "Email (Required)",
    dateOfBirth: "Date of Birth",
    phoneNumber: "Phone Number",
    additionalDetails: "Additional Details",
    enterOccupation: "Enter Occupation",
    occupation: "Occupation",
    enterEmployer: "Enter Employer",
    address: "Address",
    employer: "Employer",
    city: "City",
    state: "State",
    zip: "ZIP",
    usefulNotes: "Useful notes...",
    fullName: "Full Name",
    email: "Email",
    phone: "Phone",
    customerSince: "Customer since",
    notes: "Notes",
    modifyRecurrence: "Modify Recurrence",
    type: "Type",
    street_address: "Street Address",
    charitableDonation: "Charitable donation",
    recurrence: "Recurrence",
    nextTransaction: "Next Transaction",
    donated: "Donated",
    donation: "Donation",
    giveboxFee: "Givebox Fee",
    visaFee: "Visa Fee",
    chardged: "charged",
    importCsv: "Import CSV",
    clickHere: "Click here",
    importFromGmail: "Import from Gmail",
    youCanAlsoImportFromGmail: "You can also import from Gmail",
  },
};
