import { useRef } from "react";
import { TAlertMethods } from "../types";

type PayloadAction = "subscribeList" | "unsubscribeList";
type LogsList = Record<PayloadAction, string[]>;
type AllLogs = Record<TAlertMethods, LogsList>;

const initialLoglist: LogsList = {
  subscribeList: [],
  unsubscribeList: [],
};

const inititialState = { email: initialLoglist, push: initialLoglist };

const useAlertsLogs = () => {
  const logs = useRef<AllLogs>(inititialState);

  const resetLogs = () => (logs.current = inititialState);

  const removeFromLogs = (id: string, method: TAlertMethods) => {
    const { subscribeList, unsubscribeList } = logs.current[method];
    logs.current = {
      ...logs.current,
      [method]: {
        subscribeList: subscribeList.filter((el) => el !== id),
        unsubscribeList: unsubscribeList.filter((el) => el !== id),
      },
    };
  };

  const addToList = (
    id: string,
    method: TAlertMethods,
    list: PayloadAction,
  ) => {
    const currentList = logs.current[method][list];
    const index = currentList.indexOf(id);

    if (index !== -1) return;

    logs.current = {
      ...logs.current,
      [method]: {
        ...logs.current[method],
        [list]: [...currentList, id],
      },
    };
  };

  const updateLog = (
    id: string,
    method: TAlertMethods,
    newValue?: boolean,
    originalValue?: boolean,
  ) => {
    if (!isBoolean(newValue) || !isBoolean(originalValue)) return;

    if (newValue === originalValue) {
      removeFromLogs(id, method);
    } else if (!originalValue && newValue) {
      addToList(id, method, "subscribeList");
    } else if (!newValue && originalValue) {
      addToList(id, method, "unsubscribeList");
    }
  };

  const logsBulkUpdate = (
    method: TAlertMethods,
    list: PayloadAction,
    ids: string[],
  ) => {
    logs.current = {
      ...logs.current,
      [method]: {
        ...logs.current[method],
        [list]: ids,
      },
    };
  };

  const generatePayload = () => {
    let payload = {};

    Object.keys(logs.current).forEach((method) => {
      const lists = logs.current[method as keyof AllLogs];
      const { subscribeList, unsubscribeList } = lists;
      if (subscribeList.length < 1 && unsubscribeList.length < 1) return;

      const key = method === "push" ? "pushNotification" : "email";

      payload = {
        ...payload,
        [key]: lists,
      };
    });

    return payload;
  };

  return {
    resetLogs,
    updateLog,
    generatePayload,
    logsBulkUpdate,
  };
};

export default useAlertsLogs;

const isBoolean = (value: any) => typeof value === "boolean";
