import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { buildAccountsUrl } from "@services/api/utils.api";
import { UploadProgressParams } from "@components/UploadFile/hooks/useUploadFiles";
import { useUploadSingleImage as useUploadSingleImageOld } from "@hooks/merchant-api/image-gallery/useUploadSingleImage";
import { useUploadSingleImage as useUploadSingleImageNew } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type Props = {
  list: {
    file: File;
    id: string;
  }[];
  onUploadProgress: (params: UploadProgressParams) => void;
  onUploadFinish(params: { identifiers: (number | string)[] }): void;
  onUploadFailed(params: { fileIds: string[] }): void;
};

export const useUploadCustomerFile = () => {
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUpload: handleUploadNew, isLoading: isLoadingNew } =
    useUploadSingleImageNew();
  const { handleUpload: handleUploadOld, isLoading: isLoadingOld } =
    useUploadSingleImageOld();
  const handleUpload = isFileUploadRefactorEnabled
    ? handleUploadNew
    : handleUploadOld;
  const isLoading = isFileUploadRefactorEnabled ? isLoadingNew : isLoadingOld;

  const queryClient = useQueryClient();

  const createImageMutation = useMutation(({ data, onUploadProgress }: any) => {
    return customInstance({
      url: buildAccountsUrl("media-items"),
      method: "POST",
      data,
      onUploadProgress,
    });
  });

  const handlePostImage = async ({
    list,
    onUploadFailed,
    onUploadFinish,
    onUploadProgress,
  }: Props) => {
    try {
      const tempUrl = await handleUpload(
        isFileUploadRefactorEnabled
          ? {
              file: list[0]?.file,
              formType: "customer_avatar",
            }
          : (list[0]?.file as any),
        isFileUploadRefactorEnabled ? "customer_avatar" : undefined,
      );

      if (!tempUrl) return "";

      const promise = await createImageMutation.mutateAsync(
        {
          data: { label: "main_pic", url: tempUrl },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            onUploadProgress({
              identifier: tempUrl,
              fileId: list?.[0].id,
              progress: (progressEvent.loaded * 100) / progressEvent.total,
            });
          },
        },
        {
          onSettled: () => {
            onUploadFinish({ identifiers: [tempUrl] });
          },
        },
      );

      await queryClient.refetchQueries("get-all-media-items");
      const url: string = promise.URL || "";
      return url;
    } catch (err) {
      onUploadFailed({ fileIds: [list?.[0]?.id] });
      return "";
    }
  };

  return { handlePostImage, isLoading };
};
