import { useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { throttle } from "lodash";
import { ROWS_PER_PAGE } from "./usePagination";

export const useObserveHTMLNode = (
  loadingRef: React.MutableRefObject<boolean>,
  onIntersect: any,
  throttleDuration = 1500,
) => {
  const handleIntersect = useCallback(
    throttle(() => {
      if (!loadingRef.current) {
        loadingRef.current = true;
        onIntersect();
      }
    }, throttleDuration),
    [onIntersect, throttleDuration],
  );

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      handleIntersect();
    }
  }, [inView]);

  return ref;
};

export const useTargetRef = ({ page, totalRows, setPage, loadingRef }: any) => {
  const targetRef = useObserveHTMLNode(loadingRef, () => {
    if (page < Math.ceil(totalRows / ROWS_PER_PAGE)) {
      setPage();
    }
  });

  return targetRef;
};
