import * as React from "react";
// @mui
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
// icons
import { AddCircleFilledIcon, SubtractIcon } from "@assets/rebrandIcons";
// localization
// import { useTranslation } from "react-i18next";
// import { namespaces } from "localization/resources/i18n.constants";

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme, active }) => ({
  height: 24,
  display: "inline-flex",
  // borderRadius: "4px",
  alignItems: "center",
  color: theme.palette.neutral[500],

  "&:hover": {
    color: theme.palette.neutral[800],
  },
  "&:not(:last-of-type)": {
    marginRight: "24px",
  },
  ...(active && {
    padding: "0px 12px 0px 8px",
    // borderLeft: `4px solid ${theme.palette.primary.main}`,
  }),
  svg: {
    marginRight: "-2px",
  },
}));

const btnStyle = {
  fontWeight: 350,
  fontSize: "14px",
  display: "flex",
  gap: "12px",
  cursor: "pointer",
  alignItems: "center",
  textTransform: "capitalize",
  color: `#8F8F8F`, // neutral[70]

  "&:first-of-type": {
    "&:hover": {
      color: `#575353`, // neutral[80],

      "svg > path": {
        fill: `#575353`, // neutral[80]
      },
    },
  },

  "&:last-of-type": {
    color: `#403D3D`, // neutral[90]
  },

  "& > sup": {
    fontSize: "8px",
  },
};

const FilterButton = ({
  onClick,
  title,
  onDisableFilter,
  active,
  children,
}: {
  onClick?: React.MouseEventHandler;
  title?: string;
  onDisableFilter?: React.MouseEventHandler;
  active: boolean;
  children?: React.ReactNode;
}) => {
  // const { t } = useTranslation(namespaces.common);

  return (
    <Container active={active}>
      <Box
        onClick={active ? onDisableFilter : onClick}
        sx={btnStyle}
        data-testid={`${title}-filter-button`}
      >
        {active ? <SubtractIcon width={15} /> : <AddCircleFilledIcon />}
        {/* {t(`filters.${title}`,{ ns: namespaces.common })} */}
        <Box component="span">{title}</Box>
      </Box>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ mx: 0.5, display: active ? "block" : "none" }}
      />
      <Box
        sx={{ ...btnStyle, display: active ? "block" : "hidden" }}
        onClick={onClick}
      >
        {active && children}
      </Box>
    </Container>
  );
};

export default FilterButton;
