import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const SettlementIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();

  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.10225 19.8977C1.20774 20.0032 1.35082 20.0625 1.5 20.0625H18.0586C18.3693 20.0625 18.6211 19.8106 18.6211 19.5C18.6211 19.1893 18.3693 18.9375 18.0586 18.9375H1.5C1.35082 18.9375 1.20774 18.9967 1.10225 19.1022C0.996763 19.2077 0.9375 19.3508 0.9375 19.5C0.9375 19.6492 0.996763 19.7922 1.10225 19.8977Z"
          fill="url(#paint0_linear_872_13904)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.6875 9.56248H2.25C2.12756 9.56239 2.00849 9.52235 1.91088 9.44844C1.81326 9.37454 1.74243 9.27079 1.70913 9.15297C1.67583 9.03515 1.68188 8.90967 1.72637 8.7956C1.77085 8.68153 1.85135 8.58509 1.95563 8.52092L11.7056 2.52092C11.7942 2.46653 11.8961 2.43774 12 2.43774C12.1039 2.43774 12.2058 2.46653 12.2944 2.52092L15.307 4.37484C15.5983 4.55411 15.6629 4.95005 15.4436 5.21259C15.2648 5.42665 14.956 5.47695 14.7185 5.33068L12 3.65623L4.2375 8.43748H19.7625L17.1692 6.84013C16.8778 6.66069 16.8132 6.2647 17.0323 6.00193C17.2112 5.78748 17.5204 5.73695 17.7582 5.8833L22.0444 8.52092C22.1487 8.58509 22.2291 8.68153 22.2736 8.7956C22.3181 8.90967 22.3242 9.03515 22.2909 9.15297C22.2576 9.27079 22.1867 9.37454 22.0891 9.44844C21.9915 9.52235 21.8724 9.56239 21.75 9.56248H19.3125V15.9375H21C21.1492 15.9375 21.2923 15.9967 21.3977 16.1022C21.5032 16.2077 21.5625 16.3508 21.5625 16.5C21.5625 16.6492 21.5032 16.7922 21.3977 16.8977C21.2923 17.0032 21.1492 17.0625 21 17.0625H3C2.85082 17.0625 2.70774 17.0032 2.60225 16.8977C2.49676 16.7922 2.4375 16.6492 2.4375 16.5C2.4375 16.3508 2.49676 16.2077 2.60225 16.1022C2.70774 15.9967 2.85082 15.9375 3 15.9375H4.6875V9.56248ZM9.1875 9.56248H5.8125V15.9375H9.1875V9.56248ZM13.6875 15.9375V9.56248H10.3125V15.9375H13.6875ZM14.8125 15.9375H18.1875V9.56248H14.8125V15.9375ZM22.8977 19.8977C23.0032 19.7922 23.0625 19.6492 23.0625 19.5C23.0625 19.3508 23.0032 19.2077 22.8977 19.1022C22.7923 18.9967 22.6492 18.9375 22.5 18.9375H17.5363C17.2401 18.9375 17 19.1776 17 19.4738C17 19.7989 17.2636 20.0625 17.5887 20.0625H22.5C22.6492 20.0625 22.7923 20.0032 22.8977 19.8977Z"
          fill="url(#paint1_linear_872_13904)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_872_13904"
            x1="23.0625"
            y1="2.43774"
            x2="6.51018"
            y2="24.2846"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_872_13904"
            x1="23.0625"
            y1="2.43774"
            x2="6.51018"
            y2="24.2846"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10225 19.8977C1.20774 20.0032 1.35082 20.0625 1.5 20.0625H18.0586C18.3693 20.0625 18.6211 19.8106 18.6211 19.5C18.6211 19.1893 18.3693 18.9375 18.0586 18.9375H1.5C1.35082 18.9375 1.20774 18.9967 1.10225 19.1022C0.996763 19.2077 0.9375 19.3508 0.9375 19.5C0.9375 19.6492 0.996763 19.7922 1.10225 19.8977Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6875 9.56248H2.25C2.12756 9.56239 2.00849 9.52235 1.91088 9.44844C1.81326 9.37454 1.74243 9.27079 1.70913 9.15297C1.67583 9.03515 1.68188 8.90967 1.72637 8.7956C1.77085 8.68153 1.85135 8.58509 1.95563 8.52092L11.7056 2.52092C11.7942 2.46653 11.8961 2.43774 12 2.43774C12.1039 2.43774 12.2058 2.46653 12.2944 2.52092L15.307 4.37484C15.5983 4.55411 15.6629 4.95005 15.4436 5.21259C15.2648 5.42665 14.956 5.47695 14.7185 5.33068L12 3.65623L4.2375 8.43748H19.7625L17.1692 6.84013C16.8778 6.66069 16.8132 6.2647 17.0323 6.00193C17.2112 5.78748 17.5204 5.73695 17.7582 5.8833L22.0444 8.52092C22.1487 8.58509 22.2291 8.68153 22.2736 8.7956C22.3181 8.90967 22.3242 9.03515 22.2909 9.15297C22.2576 9.27079 22.1867 9.37454 22.0891 9.44844C21.9915 9.52235 21.8724 9.56239 21.75 9.56248H19.3125V15.9375H21C21.1492 15.9375 21.2923 15.9967 21.3977 16.1022C21.5032 16.2077 21.5625 16.3508 21.5625 16.5C21.5625 16.6492 21.5032 16.7922 21.3977 16.8977C21.2923 17.0032 21.1492 17.0625 21 17.0625H3C2.85082 17.0625 2.70774 17.0032 2.60225 16.8977C2.49676 16.7922 2.4375 16.6492 2.4375 16.5C2.4375 16.3508 2.49676 16.2077 2.60225 16.1022C2.70774 15.9967 2.85082 15.9375 3 15.9375H4.6875V9.56248ZM9.1875 9.56248H5.8125V15.9375H9.1875V9.56248ZM13.6875 15.9375V9.56248H10.3125V15.9375H13.6875ZM14.8125 15.9375H18.1875V9.56248H14.8125V15.9375ZM22.8977 19.8977C23.0032 19.7922 23.0625 19.6492 23.0625 19.5C23.0625 19.3508 23.0032 19.2077 22.8977 19.1022C22.7923 18.9967 22.6492 18.9375 22.5 18.9375H17.5363C17.2401 18.9375 17 19.1776 17 19.4738C17 19.7989 17.2636 20.0625 17.5887 20.0625H22.5C22.6492 20.0625 22.7923 20.0032 22.8977 19.8977Z"
        fill={fill}
      />
    </svg>
  );
};

export default SettlementIcon;
