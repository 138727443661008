import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useMobileDrawerActions } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import NiceModal from "@ebay/nice-modal-react";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useMerchantsApiKeysActions } from "../hooks/useMerchantsApiKeysActions";
import DAPanelContent from "./DAPanelContent";
import DeveloperApiHeader from "./DeveloperApiHeader";

type Props = {
  rowData: any;
  setSelectedRow: any;
  isFirst?: boolean;
  isLast?: boolean;
};
const DeveloperApiPanel = NiceModal.create(
  ({ rowData, setSelectedRow, isFirst, isLast }: Props) => {
    const { isMobileView } = useCustomTheme();
    const { BottomActions } = useMobileDrawerActions();
    const { onClose, open } = useNiceModal();

    const onCloseDrawer = () => {
      onClose();
      if (setSelectedRow) setSelectedRow(-1);
    };

    const {
      openDeleteKeyModal,
      openRevokeKeyModal,
      openRollKeyModal,
      openActivateKeyModal,
    } = useMerchantsApiKeysActions({ rowData });

    const actions = [
      {
        title: "Activate",
        onClick: () => {
          onCloseDrawer();
          openActivateKeyModal();
        },
        hidden:
          !(rowData.statusName === "revoked") ||
          rowData.statusName === "rolled",
        sx: {
          padding: "4px 8px",
        },
      },
      {
        title: "Revoke",
        onClick: () => {
          onCloseDrawer();
          openRevokeKeyModal();
        },
        hidden:
          !(rowData.statusName === "active") || rowData.statusName === "rolled",
        sx: {
          padding: "4px 8px",
        },
      },
      {
        title: "Roll",
        onClick: () => {
          onCloseDrawer();
          openRollKeyModal();
        },
        hidden: rowData.statusName === "rolled",
        sx: {
          padding: "4px 8px",
        },
        disabled: rowData?.statusName === "revoked",
      },
      {
        title: "Delete",
        onClick: () => {
          onCloseDrawer();
          openDeleteKeyModal();
        },
        hidden: false,
        sx: {
          color: "rgba(148, 26, 26, 1)",
          padding: "4px 8px",
        },
        warn: true,
      },
    ];

    const mobileActions = actions.map(({ title, onClick, hidden }) => {
      return {
        onSelect: onClick,
        hidden,
        // disabled: disabled || false,
        label: title,
        labelProps:
          title === "Delete"
            ? {
                "data-testid": `${title}-action-item`,
                sx: {
                  color: palette.error.hover,
                },
              }
            : {
                "data-testid": `${title}-action-item`,
              },
      };
    });

    return (
      <ModalFactory
        variant="sidepanel"
        modalProps={{
          open,
          onClose: onCloseDrawer,
          SidePanelProps: {
            width: CAMPAIGN_PANEL_WIDTH,
          },
          DrawerProps: {
            PaperProps: {
              sx: {
                padding: "24px 16px",
              },
            },
          },
        }}
      >
        <Container
          isMobileView={isMobileView}
          position={isMobileView ? "relative" : "initial"}
        >
          {!isMobileView && (
            <DeveloperApiHeader
              onCloseDrawer={onCloseDrawer}
              isFirst={isFirst}
              isLast={isLast}
              setSelectedRow={setSelectedRow}
              actions={actions}
            />
          )}
          {isMobileView && (
            <BottomActions
              sx={{
                "& .MuiPaper-root": {
                  padding: 0,
                  bottom: "100px",
                },
              }}
              label={
                <HorizontalMoreIcon
                  height={41}
                  width={41}
                  fill={palette.background.dimmedWhite}
                />
              }
              data-testid="more-actions-button"
              items={mobileActions}
              buttonSx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
              }}
            />
          )}
          <DAPanelContent rowData={rowData} />
        </Container>
      </ModalFactory>
    );
  },
);

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})((props: { isMobileView: boolean }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: props.isMobileView ? "0px" : "8px 24px",
  height: "100%",
  position: "relative",
  overflow: 'auto'
}));

export default DeveloperApiPanel;
