import { useCallback, useMemo, useState } from "react";
import useDatasetsConfig from "./hooks/useDatasetsConfig";
import useTimeConfig from "./hooks/useTimeConfig";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Tick,
  ScriptableScaleContext,
  Color,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Stack } from "@mui/material";
import "chartjs-adapter-moment";
import MouseLinePlugin from "./plugins/MouseLinePlugin";
import { TRenderDataSets, TTimeConfig } from "@components/LineGraph/types";
import useGetOptions from "./utils/options";
import moment from "moment";
import { TimeLineChartData } from "./types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface TimeLineChartProps {
  data: TimeLineChartData[];
  renderDatasets?: TRenderDataSets;
  height?: number | string;
  customOptions?: any;
  customTimeConfig?: Partial<TTimeConfig>;
}

const TimeLineChart = ({
  data,
  renderDatasets,
  height = 200,
  customOptions,
  customTimeConfig,
}: TimeLineChartProps) => {
  const [hidden, setHidden] = useState<Record<number, boolean>>({});
  const { customData } = useDatasetsConfig(data);
  const { timeConfig } = useTimeConfig(data);
  const options = useGetOptions({
    scales: {
      x: {
        time: {
          ...timeConfig,
          ...customTimeConfig,
        },
        ticks: {
          ...((customTimeConfig?.displayFormats?.day === "ddd" ||
            timeConfig?.displayFormats?.day === "ddd") && {
            callback: (
              value: string | number,
              index: number,
              ticks: Tick[],
            ) => {
              const isToday = moment(value).isSame(new Date(), "day");
              return isToday ? "Today" : moment(value).format("ddd");
            },
            color: (context: ScriptableScaleContext): Color => {
              const value = context.tick.value;
              const isToday = moment(value).isSame(new Date(), "day");
              return isToday ? ("#326EC5" as Color) : ("#8F8F8F" as Color);
            },
          }),
        },
      },
    },
    ...customOptions,
  });

  const numberOfDatasets = customData.datasets.length;

  const toggleDataset = useCallback(
    (index: any) => {
      const hiddenDatesets = Object.values(hidden).filter((el) => !!el);
      if (hiddenDatesets.length === numberOfDatasets - 1 && !hidden[index])
        return;

      setHidden({
        ...hidden,
        [index]: !hidden[index],
      });
    },
    [hidden],
  );
  const datasets = useMemo(() => {
    if (!renderDatasets) return customData?.datasets || [];
    return (
      customData?.datasets?.map((dataset, i) => ({
        ...dataset,
        hidden: hidden[i],
      })) || []
    );
  }, [customData, hidden]);

  return (
    <>
      <Stack
        width="100%"
        height="max-content"
        direction="row"
        alignItems="center"
        justifyContent="stretch"
      >
        <Line
          height={height}
          options={options}
          data={{ ...data, datasets }}
          plugins={[MouseLinePlugin]}
        />
      </Stack>

      {renderDatasets && renderDatasets(datasets, toggleDataset)}
    </>
  );
};

export default TimeLineChart;
