type Props = {
  width?: string | number;
  height?: string | number;
  fill?: string;
};

const CreditCardIcon2 = ({ width = 22, height = 15, fill = "none" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 0H2C1.60218 0 1.22064 0.158035 0.93934 0.43934C0.658035 0.720644 0.5 1.10218 0.5 1.5V13.5C0.5 13.8978 0.658035 14.2794 0.93934 14.5607C1.22064 14.842 1.60218 15 2 15H20C20.3978 15 20.7794 14.842 21.0607 14.5607C21.342 14.2794 21.5 13.8978 21.5 13.5V1.5C21.5 1.10218 21.342 0.720644 21.0607 0.43934C20.7794 0.158035 20.3978 0 20 0ZM20 1.5V3.75H2V1.5H20ZM20 13.5H2V5.25H20V13.5ZM18.5 11.25C18.5 11.4489 18.421 11.6397 18.2803 11.7803C18.1397 11.921 17.9489 12 17.75 12H14.75C14.5511 12 14.3603 11.921 14.2197 11.7803C14.079 11.6397 14 11.4489 14 11.25C14 11.0511 14.079 10.8603 14.2197 10.7197C14.3603 10.579 14.5511 10.5 14.75 10.5H17.75C17.9489 10.5 18.1397 10.579 18.2803 10.7197C18.421 10.8603 18.5 11.0511 18.5 11.25ZM12.5 11.25C12.5 11.4489 12.421 11.6397 12.2803 11.7803C12.1397 11.921 11.9489 12 11.75 12H10.25C10.0511 12 9.86032 11.921 9.71967 11.7803C9.57902 11.6397 9.5 11.4489 9.5 11.25C9.5 11.0511 9.57902 10.8603 9.71967 10.7197C9.86032 10.579 10.0511 10.5 10.25 10.5H11.75C11.9489 10.5 12.1397 10.579 12.2803 10.7197C12.421 10.8603 12.5 11.0511 12.5 11.25Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default CreditCardIcon2;
