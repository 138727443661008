import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import * as React from "react";

type FormInput = {
  [key: string]: any;
};

export type MerchantAgreementType = {
  merchantAgreement: TMerchantAgreementPayload;
  termsAndConditionsAgreement: {
    agreementConfirmation: boolean | null;
  };
};

type Title = "merchantAgreement" | "termsOfService";

export type FormContextType = {
  formData: FormInput;
  setFormValues: (name: Title, values: FormInput) => void;
};

export const FormContext = React.createContext<FormContextType | null>(null);

const MerchantAgreementFormProvider = ({
  children,
  data,
}: {
  data?: MerchantAgreementType;
  children: React.ReactNode;
}) => {
  const [formData, setFormData] = React.useState<MerchantAgreementType>({
    merchantAgreement: data
      ? {
          ...data.merchantAgreement,
          msaPCICompliant: true,
          msaRefundPolicy:
            data?.merchantAgreement?.msaRefundPolicy || "30 Days of Purchase",
          msaPreviousTerminationAt:
            data?.merchantAgreement?.msaPreviousTerminationAt || null,
        }
      : {
          msaRefundPolicy: "30 Days of Purchase",
          msaPCICompliant: true,
          msaPreviousTermination: false,
          msaPreviousTerminationProcessorName: "",
          msaPreviousTerminationReason: "",
          msaPreviousTerminationAt: null,
          msaHadAgreed: true,
        },
    termsAndConditionsAgreement: {
      agreementConfirmation: true,
    },
  });

  const setFormValues = (name: Title, values: FormInput) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: {
        ...values,
      },
    }));
  };

  return (
    <FormContext.Provider value={{ formData, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
};

export default MerchantAgreementFormProvider;

export const useFormData = () =>
  React.useContext(FormContext) as FormContextType;
