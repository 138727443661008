import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";

export const useResyncMSP = (merchantId: number, legalEntityId?:number,) => {
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync: ResyncMSPAsync } = useMutation(() => {
    return customInstance({
      url: legalEntityId? `/merchants/${merchantId}/legal-entities/${legalEntityId}/msp` : `/merchants/${merchantId}/msp`,
      method: "PUT",
    });
  });

  const handleResyncMSP = async () => {
    setIsLoading(true);
    try {
      await ResyncMSPAsync();
      await queryClient.invalidateQueries("get-merchant-preview");
      queryClient.invalidateQueries("get-merchant-msp-status");
      showMessage("Info", "Synchronization complete");
    } catch (error) {
      showMessage("Error", "We have some issues with the synchronization.");
    } finally {
      setIsLoading(false);
    }
  };
  return { handleResyncMSP, isLoading };
};
