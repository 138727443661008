import { ChevronRight } from "@assets/icons/RebrandedIcons";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { Stack } from "@mui/material";
import { StyledIconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import { palette } from "@palette";

const MobileWrapper = ({
  onCancel,
  label,
  children,
}: {
  onCancel?: () => void;
  label: string;
  children: React.ReactNode;
}) => {
  const { open, onClose, TransitionProps } = useNiceModal();

  const handleCancel = () => {
    if (onCancel) onCancel();
    onClose();
  };

  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={open}
      onClose={handleCancel}
      PaperProps={{
        sx: {
          background: palette.neutral[5],
          position: "relative",
          maxHeight: `calc(100vh - 124px)`,
        },
        TransitionProps,
      }}
      sx={{
        top: "auto",
      }}
    >
      <ModalHeader onClick={handleCancel} label={label} />
      {children}
    </SwipeableDrawerMobile>
  );
};

export default MobileWrapper;

const ModalHeader = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" mb={2}>
      <StyledIconButton
        sx={{
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "4px",
          padding: "0 !important",
        }}
        onClick={onClick}
      >
        <ChevronRight
          rotate={180}
          width={28}
          height={28}
          stroke={palette.gray[300]}
        />
      </StyledIconButton>
      <Text
        color={palette.black[100]}
        variant="h6"
        lineHeight="21.6px"
        fontWeight="book"
        letterSpacing="-0.18px"
      >
        {label}
      </Text>
    </Stack>
  );
};
