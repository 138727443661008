import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
}

const NotebookIcon = ({ width = 100, height = 100 }: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.0938 44.25C71.0938 44.8716 70.8468 45.4677 70.4073 45.9073C69.9677 46.3468 69.3716 46.5938 68.75 46.5938H43.75C43.1284 46.5938 42.5323 46.3468 42.0927 45.9073C41.6532 45.4677 41.4062 44.8716 41.4062 44.25C41.4062 43.6284 41.6532 43.0323 42.0927 42.5927C42.5323 42.1532 43.1284 41.9062 43.75 41.9062H68.75C69.3716 41.9062 69.9677 42.1532 70.4073 42.5927C70.8468 43.0323 71.0938 43.6284 71.0938 44.25ZM68.75 54.4062H43.75C43.1284 54.4062 42.5323 54.6532 42.0927 55.0927C41.6532 55.5323 41.4062 56.1284 41.4062 56.75C41.4062 57.3716 41.6532 57.9677 42.0927 58.4073C42.5323 58.8468 43.1284 59.0938 43.75 59.0938H68.75C69.3716 59.0938 69.9677 58.8468 70.4073 58.4073C70.8468 57.9677 71.0938 57.3716 71.0938 56.75C71.0938 56.1284 70.8468 55.5323 70.4073 55.0927C69.9677 54.6532 69.3716 54.4062 68.75 54.4062ZM86.7188 19.25V81.75C86.7188 83.2004 86.1426 84.5914 85.117 85.617C84.0914 86.6426 82.7004 87.2188 81.25 87.2188H18.75C17.2996 87.2188 15.9086 86.6426 14.883 85.617C13.8574 84.5914 13.2812 83.2004 13.2812 81.75V19.25C13.2812 17.7996 13.8574 16.4086 14.883 15.383C15.9086 14.3574 17.2996 13.7813 18.75 13.7812H81.25C82.7004 13.7812 84.0914 14.3574 85.117 15.383C86.1426 16.4086 86.7188 17.7996 86.7188 19.25ZM18.75 82.5312H28.9062V18.4688H18.75C18.5428 18.4688 18.3441 18.5511 18.1976 18.6976C18.0511 18.8441 17.9688 19.0428 17.9688 19.25V81.75C17.9688 81.9572 18.0511 82.1559 18.1976 82.3024C18.3441 82.4489 18.5428 82.5312 18.75 82.5312ZM82.0312 19.25C82.0312 19.0428 81.9489 18.8441 81.8024 18.6976C81.6559 18.5511 81.4572 18.4688 81.25 18.4688H33.5938V82.5312H81.25C81.4572 82.5312 81.6559 82.4489 81.8024 82.3024C81.9489 82.1559 82.0312 81.9572 82.0312 81.75V19.25Z"
        fill="url(#paint0_linear_557_381)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_557_381"
          x1="86.7188"
          y1="13.7812"
          x2="15.1664"
          y2="89.0117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1} />
          <stop offset="1" stopColor={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotebookIcon;
