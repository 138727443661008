type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const CornersBorderIcon = ({
  width = 20,
  height = 20,
  stroke = "#575353",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58212 4.66602C5.07725 4.66602 4.66797 5.07529 4.66797 5.58016V7.34784C4.66797 7.62399 4.44411 7.84785 4.16797 7.84785C3.89183 7.84785 3.66797 7.62399 3.66797 7.34784V5.58016C3.66797 4.52301 4.52496 3.66602 5.58212 3.66602H7.3498C7.62594 3.66602 7.8498 3.88987 7.8498 4.16602C7.8498 4.44216 7.62594 4.66602 7.3498 4.66602H5.58212ZM12.1528 4.16602C12.1528 3.88987 12.3767 3.66602 12.6528 3.66602H14.4205C15.4777 3.66602 16.3347 4.52301 16.3347 5.58016V7.34784C16.3347 7.62399 16.1108 7.84785 15.8347 7.84785C15.5585 7.84785 15.3347 7.62399 15.3347 7.34784V5.58016C15.3347 5.0753 14.9254 4.66602 14.4205 4.66602H12.6528C12.3767 4.66602 12.1528 4.44216 12.1528 4.16602ZM4.16797 12.1509C4.44411 12.1509 4.66797 12.3747 4.66797 12.6509V14.4186C4.66797 14.9234 5.07725 15.3327 5.58212 15.3327H7.3498C7.62594 15.3327 7.8498 15.5566 7.8498 15.8327C7.8498 16.1089 7.62594 16.3327 7.3498 16.3327H5.58212C4.52496 16.3327 3.66797 15.4757 3.66797 14.4186V12.6509C3.66797 12.3747 3.89183 12.1509 4.16797 12.1509ZM15.8347 12.1509C16.1108 12.1509 16.3347 12.3747 16.3347 12.6509V14.4186C16.3347 15.4757 15.4777 16.3327 14.4205 16.3327H12.6528C12.3767 16.3327 12.1528 16.1089 12.1528 15.8327C12.1528 15.5566 12.3767 15.3327 12.6528 15.3327H14.4205C14.9254 15.3327 15.3347 14.9234 15.3347 14.4186V12.6509C15.3347 12.3747 15.5585 12.1509 15.8347 12.1509Z"
        fill={stroke}
      />
    </svg>
  );
};

export default CornersBorderIcon;
