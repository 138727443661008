import * as React from "react";
import { palette } from "@palette";
import isString from "lodash/isString";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";
// mui
import Box, { BoxProps } from "@mui/material/Box";
// components
import { Text } from "@common/Text";
import Layout from "./Layout";
// assets
import placeholder from "@assets/images/avatar-placeholder.png";
import ImagePlacholder from "@assets/images/Placeholder.png";

export type TImageSize = "small" | "medium" | "large" | "thumb" | "original";

export type UploadAvatarProps = BoxProps & {
  file?: any;
  avatarType?: "avatar" | "thumbnail";
  title?: string;
  titleComponent?: React.ReactNode;
  imageSize?: TImageSize;
  width?: string;
  height?: string;
  disabled?: boolean;
  onChangeStatus?: IDropzoneProps["onChangeStatus"];
  getUploadParams?: IDropzoneProps["getUploadParams"];
  getFilesFromEvent?: IDropzoneProps["getFilesFromEvent"];
};

export default function UploadAvatar({
  file,
  avatarType = "avatar",
  disabled,
  width = "80px",
  height = "80px",
  title = "Avatar",
  imageSize = "small",
  titleComponent,
  onChangeStatus,
  getUploadParams,
  getFilesFromEvent,
  ...boxProps
}: UploadAvatarProps) {
  const imageUrl = file
    ? isString(file)
      ? addSizeToImage(file, imageSize)
      : file.preview
    : avatarType === "avatar"
    ? placeholder
    : ImagePlacholder;

  return (
    <Box {...boxProps}>
      {titleComponent ? (
        <>{titleComponent}</>
      ) : (
        <Text fontWeight="medium" color={palette.neutral[800]}>
          {title}
        </Text>
      )}

      <Box
        sx={{
          marginTop: 1,
          width: width,
          height: height,
          borderRadius: avatarType === "avatar" ? "50%" : "8px",
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${imageUrl})`,
          overflow: "hidden",

          ...(!disabled && {
            "&:hover": {
              border: `2px solid ${palette.neutral[70]}`,
            },
          }),
        }}
      >
        <Dropzone
          maxFiles={1}
          accept="image/*"
          multiple={false}
          LayoutComponent={Layout}
          onChangeStatus={onChangeStatus}
          getUploadParams={getUploadParams}
          getFilesFromEvent={getFilesFromEvent}
          InputComponent={({ accept, onFiles, getFilesFromEvent }) => (
            <Box
              component="label"
              sx={{
                opacity: 0,
                width: "80px",
                height: "80px",
                ...(!disabled && {
                  cursor: "pointer",
                }),
              }}
            >
              <input
                type="file"
                id="upload"
                accept={accept}
                disabled={disabled}
                style={{ display: "none" }}
                onChange={async (e) => {
                  const chosenFiles = await getFilesFromEvent(e);
                  onFiles(chosenFiles);
                  e.target.value = "";
                }}
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}

export function addSizeToImage(url: string, size: TImageSize) {
  if (!url) return "";
  const urlIncludesSize = [
    "/small",
    "/large",
    "/medium",
    "/original",
    "/thumb",
  ].some((substring) => url.includes(substring));

  if (urlIncludesSize) return url;

  return url + "/" + size;
}
