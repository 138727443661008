import { styled } from "@mui/material";

export const Image = styled("img", {
  shouldForwardProp: (prop) => prop !== "isContained",
})(({ width = 108, height = 108 }) => ({
  borderRadius: "8px",
  height,
  width,
  objectFit: "cover",
  display: "block",
  maxWidth: "100%",
  zIndex: 1
}));
