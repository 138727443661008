import { namespaces } from "../../../../resources/i18n.constants";

export const invoiceMinibuilder = {
  [namespaces.pages.invoiceMinibuilder]: {
    create_invoice: "Crear Factura",
    general: "General",
    payment: "Pago",
    // General --------------------------------------------------------------------------------
    title: "Título",
    "Please enter a suitable title": "Por favor ingrese un título adecuado",
    description: "Descripción",
    optional_but_recommended: "(Opcional pero recomendado)",
    "What’s the purpose of this invoice":
      "¿Cuál es el propósito de esta factura?",
    browse_more_campaigns: "Explorar más campañas",
    "If enabled, users will be able to explore other published campaigns of this Organization.":
      "Si está habilitado, los usuarios podrán explorar otras campañas publicadas de esta organización.",
    featured_image: "Foto principal",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned.Minimum size recommended 2000x2000":
      "Esta imagen debe ser representativa de la campaña y se mostrará en la parte superior del formulario de pago, se puede reposicionar posteriormente. Tamaño mínimo recomendado 2000x2000",
    use_as_background_image: "Usar como imagen de fondo",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "Si está marcada, esta imagen se integrará en el fondo del formulario, se puede cambiar más tarde",
    media_library: "Mediateca",
    add_new_image: "Agregar nueva imagen",
    no_images: "Sin imágenes",
    // Payment --------------------------------------------------------------------------------
    amount: "Cantidad",
    add_amount: "Agregar Cantidad",
    thumbnail: "Miniatura",
    thank_you_for_your_payment: "Gracias por tu pago.",
    amount_title_is_mandatory: "El título de la cantidad es obligatorio",
    must_be_above_one_dollar: "Debe estar por encima de 1USD",
    "between 1.00 and 25,000.00": "Entre 1,00 y 25.000,00",
    should_be_at_least: "Debe ser al menos 1",
    describe_this_payment_option: "Describa esta opción de pago",

    create_option: "Crear opción",
    save_changes: "Guardar cambios",
    cancel: "Cancelar",
    remove: "Eliminar",

    set_min_max_value: "Establecer valor Mínimo y Máximo",
    minimum: "Mínimo",
    min: "Mín",
    max: "Máx",
    maximum: "Máximo",
    set_min_max_value_text:
      "El valor <span>Mínimo</span> puede ser útil cuando se trata de costos adicionales de ejecución de la campaña, mientras que se puede establecer un valor <span>máximo</span> por razones políticas.",

    payment_types: "Formas de pago",
    "Choose the payment types available to your customers":
      "Elija los tipos de pago disponibles para sus clientes",
    one_time: "Una Vez",
    "One-Time Payment, no recurring involved":
      "Pago único, no recurrente involucrado",
    monthly: "Mensual",
    "Payment will repeat every month from the date of payment":
      "El pago se repetirá todos los meses a partir de la fecha de pago",
    quarterly: "Trimestral",
    "Payment will repeat every 3 months from the date of payment":
      "El pago se repetirá cada 3 meses a partir de la fecha de pago",
    yearly: "Anual",
    default: "Por Defecto",

    if: "Si está",
    defaulted: "predeterminada",
    this_type_will_be_selected_by_default:
      "este tipo será seleccionado por defecto",

    properties: "Propiedades",
    customer_pays_the_credit_card_fee:
      "El cliente paga la tarifa de la tarjeta de crédito",
    "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee":
      "El cliente cubre el costo de la tarifa de la tarjeta de crédito a menos que se proporcione la opción que elija. Si se desactiva, la organización sin fines de lucro paga la tarifa de la tarjeta de crédito.",
    make_it_optional: "Hazlo opcional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "Si está marcado, los clientes tendrán la opción de no cubrir la tarifa de la tarjeta de crédito",
    // Invoice Successful
    invoice_successfully_created: "Factura creado con éxito 🎉",
    "If you’re on a desktop or a laptop":
      "Si está en un escritorio o una computadora portátil, puede continuar editando en <span>el Builder avanzado</span>. El Builder Advanced ofrece potentes herramientas de personalización para ayudarlo a llevar su campaña al siguiente nivel y, en última instancia, aumentar la conversión.",
    open_the_advanced_builder: "Abra el Generador avanzado",
    edit_in_advanced_builder: "Editar en Advanced Builder",
    done: "Hecho",
  },
};
