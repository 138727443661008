import { SvgIconProps } from "@mui/material";

const LogoutIconV3 = ({
 height=36,
 width=36,
 fill="#727179"
}:SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_4949_1896)">
        <path
          d="M3 18L2.21913 17.3753L1.71938 18L2.21913 18.6247L3 18ZM16.5 19C17.0523 19 17.5 18.5523 17.5 18C17.5 17.4477 17.0523 17 16.5 17V19ZM8.21913 9.8753L2.21913 17.3753L3.78087 18.6247L9.78087 11.1247L8.21913 9.8753ZM2.21913 18.6247L8.21913 26.1247L9.78087 24.8753L3.78087 17.3753L2.21913 18.6247ZM3 19H16.5V17H3V19Z"
          fill={fill}
        />
        <path
          d="M15 12.1979V9.38851C15 7.77017 15 6.961 15.474 6.4015C15.9479 5.84201 16.7461 5.70899 18.3424 5.44293L26.0136 4.1644C29.2567 3.62388 30.8782 3.35363 31.9391 4.25232C33 5.15102 33 6.79493 33 10.0828V25.9172C33 29.2051 33 30.849 31.9391 31.7477C30.8782 32.6464 29.2567 32.3761 26.0136 31.8356L18.3424 30.5571C16.7461 30.291 15.9479 30.158 15.474 29.5985C15 29.039 15 28.2298 15 26.6115V24.0989"
          stroke={fill}
          strokeWidth="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_4949_1896"
          x="0"
          y="0"
          width="36"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4949_1896"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LogoutIconV3;
