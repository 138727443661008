import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import { useAppDispatch } from "@redux/hooks";
import { setSelectedVariant } from "@redux/slices/checkout";
import { buildMerchantEndpoints } from "@services/api/utils.api";

export const useFindPaymentFormById = (
  id?: string,
  publicResource?: boolean,
) => {
  if (!id) return { data: undefined };

  const getPaymentForm = (id: string) => {
    const publicUrl = `products/${id}`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const getPaymentFormVariants = (id: string) => {
    const publicUrl = `products/${id}/variants?&sort=displayOrder`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const { data, error, isLoading } = useQuery(
    ["find-payment-form-by-id", id],
    async () => {
      const payment_form = await getPaymentForm(id);
      const variants = await getPaymentFormVariants(id);
      return { payment_form, variants };
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const dispatch = useAppDispatch();

  if (!data)
    return {
      error,
      isLoading,
      redirect: (error as any)?.response?.status === 404 ? true : false,
    };

  const customAmounts =
    data.variants?.data?.map((amount: any) => {
      if (
        (amount.minPrice && amount.maxPrice) ||
        amount.name === "Any Amount"
      ) {
        const minPrice = (amount.minPrice / 100).toFixed(2);
        const maxPrice = (amount.maxPrice / 100).toFixed(2);
        dispatch(
          setSelectedVariant({
            type: "payment-form",
            min: minPrice,
            max: maxPrice,
          }),
        );

        return {
          isDefault: true,
          id: amount.id,
          amount: (amount.price / 100).toFixed(2),
          title: amount.name,
          description: amount.description,
          min_max: {
            enabled: !amount.allowCustomPrice,
            min: minPrice,
            max: maxPrice,
          },
          ...(amount.inventory && { inventory: parseInt(amount.inventory), }),
          thumbnail: amount.imageURL ? amount.imageURL : "",
          active: amount.isEnabled,
          bundle: 1,
          display: amount.showAvailableVariants
        };
      }
      return {
        id: amount.id,
        title: amount.name,
        amount: (amount.price / 100).toFixed(2),
        description: {
          enabled: Boolean(amount.description),
          text: stringToEditorState(amount.description),
        },
        thumbnail: amount.imageURL ? amount.imageURL : "",
        ...(amount.inventory && { inventory: parseInt(amount.inventory), }),
        active: amount.isEnabled,
        bundle: 1,
        display: amount.showAvailableVariants
      };
    }) ?? [];

  const customPaymentTypes: any = {};

  data?.payment_form?.recurringIntervals?.forEach((item: string) => {
    if (item === "once") customPaymentTypes["one_time"] = true;
    customPaymentTypes[item] = true;
  });

  ["one_time", "monthly", "quarterly", "yearly"].forEach((item) => {
    if (!customPaymentTypes[item]) customPaymentTypes[item] = false;
  });

  customPaymentTypes["default"] =
    data?.payment_form?.defaultRecurringIntervalName === "once"
      ? "one_time"
      : data?.payment_form?.defaultRecurringIntervalName;

  const customData = {
    general: {
      title: data?.payment_form?.name || "",
      description: data?.payment_form?.description,
      browseMore: data?.payment_form?.canBrowseCampaigns || false,
      creatorName: data?.payment_form?.merchantName,
      creatorImage: data?.payment_form?.merchantImageURL,
      creatorDescription: data?.payment_form?.merchantDescription,
      featuredImage: {
        image: data?.payment_form?.imageURL || "",
        active: Boolean(data?.payment_form?.imageURL),
        useAsBackground: data?.payment_form?.usesBackgroundImage || false,
      },
    },
    payment: {
      payment_types: customPaymentTypes,
      customer_pays_credit: {
        active: data?.payment_form?.needsTax || false,
        optional: data?.payment_form?.allowFeeChoice || false,
      },
      amountsList: customAmounts || [],
    },
  };

  return {
    data: customData,
    isLoading: isLoading,
    redirect: (error as any)?.response?.status === 404 ? true : false,
  };
};
