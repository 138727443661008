import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import MultiLineInput from "@common/Input/MultiLineInput";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { RHFCheckbox } from "@common/Checkbox";
import { BusinessProfileStatusType } from "@common/Tag/BusinessProfileTag";
import { BtnBGTypes } from "@common/Button/Button_V2";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { CONFIRMATION_POP_UP } from "modals/modal_names";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useUpdateBusinessProfileStatus } from "@components/Merchants/MerchantPreview/hooks/useUpdateBusinessProfileStatus";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { checkPortals } from "@utils/routing";

type TReasonForm = {
  reason: string;
  shouldSendReason: boolean;
};
type TChangeProfileStatusModal = {
  status: ModalType;
  id: number;
  merchantName: string;
  data: IParsedData;
  isEnterprise: boolean;
};
const ChangeProfileStatusModal = NiceModal.create(
  ({
    status,
    id,
    merchantName,
    data,
    isEnterprise = false,
  }: TChangeProfileStatusModal) => {
    const modal = useModal();
    const open = modal.visible;
    const methods = useForm<TReasonForm>({});
    const { reason, shouldSendReason } = methods.watch();
    const { openConversationHandler } = useConversationsModal();
    const { handleUpdateStatus } = useUpdateBusinessProfileStatus({
      merchantId: id,
      data,
    });

    const onClose = () => {
      modal.hide();
    };
    const sanitizedReason = reason ? `\n ${reason} ` : " ";
    const modalValues = getModalValue({
      reason: sanitizedReason,
      status: status,
      isEnterprise: isEnterprise,
    });
    const sanitaizedStatus =
      status === "move_back_to_pending" ? "Pending" : status;
    const capitalizedStatus = capitalizeFirstLetter(sanitaizedStatus);
    const defaultMessage = `The ${
      isEnterprise ? "provider" : "merchant"
    } @[__"${merchantName}"__](user:__${merchantName}__) business status has been changed to #${capitalizedStatus} due to :${sanitizedReason}`;
    const submittedStatus =
      status === "move_back_to_pending" ? "pending" : status;
    const { isAcquirerPortal } = checkPortals();
    const onSubmit = () => {
      handleUpdateStatus(submittedStatus, reason, shouldSendReason);
      if (isAcquirerPortal) {
        NiceModal.show(CONFIRMATION_POP_UP, {
          variant: "open_conversation",
          onClick: () => {
            openConversationHandler({
              id,
              name: "Business Profile",
              paths: [],
              defaultMessage: defaultMessage,
              shouldHideMentionIcon: true,
            });
          },
        });
      }
      onClose();
    };
    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: open,
          onClose,
          DialogProps: {
            paperStyle: { padding: "24px 24px 16px " },
            TransitionProps: {
              onExited: () => {
                modal.resolveHide();
                !modal.keepMounted && modal.remove();
              },
            },
          },
        }}
      >
        <ModalTitle
          title={modalValues?.title}
          onClose={onClose}
          textStyles={{
            title: {
              fontSize: "18px",
              lineHeight: "21.6px",
              letterSpacing: "-0.18px",
            },
          }}
          containerProps={{ sx: { mb: "16px" } }}
          description={modalValues?.description}
        />
        <FormProvider {...methods}>
          <FadeUpWrapper delay={100}>
            <MultiLineInput
              name="reason"
              placeholder="Reason (optional)"
              rows={8}
            />
            <BottomContent
              reason={modalValues?.reason}
              isEnterprise={isEnterprise}
            />
            <RHFCheckbox
              data-testid="reason-checkbox"
              name="shouldSendReason"
              label={`Send the reason to the ${
                isEnterprise ? "provider" : "merchant"
              }`}
              labelProps={{
                color: palette.neutral[80],
                fontSize: 14,
              }}
              sx={{ pl: "5px" }}
            />
          </FadeUpWrapper>
        </FormProvider>
        <ModalActions
          animationDelay={100}
          secondaryAction={{
            onClick: onClose,
          }}
          primaryAction={{
            onClick: onSubmit,
            label: modalValues?.button?.text,
            form: "confirm-status-change",
            background: modalValues?.button?.background as any,
          }}
          containerProps={{ sx: { mt: "20px" } }}
        />
      </ModalFactory>
    );
  },
);

export default ChangeProfileStatusModal;

const BottomContent = ({
  reason,
  isEnterprise,
}: {
  reason: string;
  isEnterprise: boolean;
}) => {
  return (
    <>
      <Text
        sx={{
          mt: "24px",
          mb: "8px",
          fontSize: "14px",
          color: palette.neutral["80"],
          lineHeight: "16.8px",
          fontWeight: "350",
        }}
      >
        Default Message
      </Text>
      <Text
        sx={{
          fontSize: "14px",
          color: palette.neutral["70"],
          lineHeight: "16.8px",
          fontWeight: "350",
        }}
      >
        {`The default reason will be provided to the ${
          isEnterprise ? "provider" : "merchant"
        }, in addition to the
        specific reason you have inserted`}
      </Text>
      <Box
        sx={{
          mt: "12px",
          mb: "12px",
          maxHeight: "120px",
          overflowX: "hidden",
          borderRadius: "8px",
          border: `2px solid ${palette.neutral["10"]}`,
          color: palette.neutral["80"],
          backgroundColor: palette.neutral["5"],
          padding: "12px 16px",
        }}
      >
        <Text fontSize={"14px"}>{reason}</Text>
      </Box>
    </>
  );
};

export type ModalType = Extract<
  BusinessProfileStatusType,
  "move_back_to_pending" | "declined" | "suspended"
>;
type ValuesObject = {
  title: string;
  description: string;
  reason: string;
  button: { text: string; background: BtnBGTypes };
};

const getModalValue = ({
  reason,
  status,
  isEnterprise,
}: {
  reason: string;
  status: ModalType;
  isEnterprise: boolean;
}) => {
  const modalValues: Record<ModalType, ValuesObject> = {
    move_back_to_pending: {
      title: "Move back to pending for Business Profile",
      description: `This message will communicate the reasons behind the ${
        isEnterprise ? "provider's" : "merchant's"
      } business profile being moved to pending status, please provide clear instructions on the actions to take in order to be approved`,
      reason: `Your business profile is not approved by our underwriting team. ${reason} Please, contact our support team for any clarification you need.`,
      button: {
        text: "Confirm",
        background: "primary",
      },
    },
    declined: {
      title: "Decline Business Profile",
      description:
        "Are you sure you want to decline this business profile? Once declined, you will not be able to edit any information in the profile.",
      reason: `Your business profile is declined by our underwriting team. ${reason} Please, contact our support team for any clarification you need. `,
      button: {
        text: "Confirm Decline",
        background: "red",
      },
    },
    suspended: {
      title: "Suspend Business Profile",
      description: `Are you sure you want to suspend this business profile? Once suspended, the ${
        isEnterprise ? "provider" : "merchant"
      } will not be able to process and transfer money.`,
      reason: `Your business profile is suspended by our underwriting team. This temporarily restricts your ability to send or process money. ${reason} Please, contact our support team to resolve the issue. `,
      button: {
        text: "Confirm Suspend",
        background: "primary",
      },
    },
  };
  return modalValues[status];
};
