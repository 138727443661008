import {
  ACQUIRER_PATHS,
  ENTERPRISE_PATHS,
  MERCHANT_PATHS,
} from "@routes/paths";

export type RedirectURLKeys =
  | "settings"
  | "merchants"
  | "processing"
  | "verify_identity"
  | "providers"
  | "manage_team"
  | "disputes";
export type CurrentPortalKey = "merchant" | "provider" | "acquirer";
type TValues = Record<CurrentPortalKey, string>;

export const redirectPageUrls: Record<RedirectURLKeys, TValues> = {
  settings: {
    merchant: `settings/${MERCHANT_PATHS.BUSINESS}`,
    provider: `settings/${ENTERPRISE_PATHS.BUSINESS_DETAILS}`,
    acquirer: `settings/${ACQUIRER_PATHS.BUSINESS_DETAILS}`,
  },
  merchants: {
    merchant: "",
    provider: ENTERPRISE_PATHS.MERCHANTS,
    acquirer: ACQUIRER_PATHS.MERCHANTS,
  },
  providers: {
    merchant: "",
    provider: "",
    acquirer: ACQUIRER_PATHS.PROVIDERS,
  },
  processing: {
    merchant: MERCHANT_PATHS.MANAGE_MONEY,
    provider: ENTERPRISE_PATHS.PROCESSING,
    acquirer: ACQUIRER_PATHS.PROCESSING,
  },
  verify_identity: {
    merchant: MERCHANT_PATHS.WELCOME,
    provider: ENTERPRISE_PATHS.WELCOME,
    acquirer: "",
  },
  manage_team: {
    merchant: `settings/${MERCHANT_PATHS.MANAGE_TEAM}`,
    provider: `settings/${ENTERPRISE_PATHS.MANAGE_TEAM}`,
    acquirer: `settings/${ACQUIRER_PATHS.MANAGE_TEAM}`,
  },
  disputes: {
    acquirer: ACQUIRER_PATHS.DISPUTES, // For now disputes will be only available to acquirer
    provider: "",
    merchant: "",
  },
};

export const getIdFromTString = (text: string) => {
  const id = text.match(/\d+/);
  if (!id?.[0]) return null;

  const merchantId = parseInt(id[0]);
  if (Number.isNaN(merchantId)) return null;
  return merchantId;
};
