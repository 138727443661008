import { Stack } from "@mui/material";
import NotificationsCenterWrapper, {
  NotificationsCenterWrapperProps,
} from "./components/Wrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { MobileHeader, DesktopHeader } from "./components/Header.atoms";
import useNotificationsCenter from "./hooks/useNotificationsCenter";
import useNotificationsActions from "./hooks/useNotificationsActions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import {
  BodyWrapper,
  NotificationsBodyEmptyState,
  NotificationsItemSkeleton,
} from "./components/Body.atoms";
import NotificationsVirtualList from "./components/NotificationsVirtualList";
import { useEffect, useMemo, useRef } from "react";
import { TScrollToTop } from "./types";

type NotificationsCenterProps = Omit<
  NotificationsCenterWrapperProps,
  "children"
>;

const NotificationsCenter = (props: NotificationsCenterProps) => {
  const { onClose } = props;
  const { isMobileView } = useCustomTheme();
  const {
    data,
    isLoading,
    isEmpty,
    filters,
    showMarkAllAsRead,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    totalPages,
    selectedFilter,
  } = useNotificationsCenter(Boolean(props?.anchorEl));

  const { markAllAsRead, handleConfigurationClick } = useNotificationsActions();

  const handleConfigurationRedirect = () => {
    onClose();
    handleConfigurationClick();
  };

  const page = useRef(1);
  const scrollToTop = useRef<TScrollToTop>(null);

  useEffect(() => {
    if (scrollToTop.current) {
      scrollToTop.current(1);
    }
  }, [selectedFilter]);

  const onEndReached = async () => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage({
        pageParam: ++page.current,
      }).then(() => {
        if (scrollToTop.current) {
          scrollToTop.current((totalPages || 1) * 20);
        }
      });
    }
  };

  const flatList = useMemo(() => Object.values(data).flat(), [data]);
  const renderGroupLabels =
    data.mandatory.length > 0 && data.generic.length > 0;

  return (
    <NotificationsCenterWrapper {...props}>
      <Stack
        direction="column"
        alignItems="stretch"
        height="inherit"
        minHeight="inherit"
        maxHeight="inherit"
      >
        {isMobileView ? (
          <MobileHeader
            filters={filters}
            onClose={onClose}
            showMarkAllAsRead={showMarkAllAsRead}
            markAllAsRead={markAllAsRead}
            handleConfigurationClick={handleConfigurationRedirect}
          />
        ) : (
          <DesktopHeader
            filters={filters}
            showMarkAllAsRead={showMarkAllAsRead}
            markAllAsRead={markAllAsRead}
            handleConfigurationClick={handleConfigurationRedirect}
          />
        )}
        {isLoading ? (
          <BodyWrapper>
            {Array.from({ length: 6 }, (_, index) =>
              renderItem(index, index, <NotificationsItemSkeleton />),
            )}
          </BodyWrapper>
        ) : isEmpty ? (
          <BodyWrapper>
            <NotificationsBodyEmptyState />
          </BodyWrapper>
        ) : (
          <NotificationsVirtualList
            data={flatList}
            renderGroupLabels={renderGroupLabels}
            closeModal={onClose}
            isFetchingNextPage={isFetchingNextPage}
            onEndReached={onEndReached}
            scrollToTop={scrollToTop}
          />
        )}
      </Stack>
    </NotificationsCenterWrapper>
  );
};

export default NotificationsCenter;

type ItemRenderer = (
  index: number,
  key: string | number,
  element: JSX.Element,
) => JSX.Element;

const renderItem: ItemRenderer = (index, key, element) => (
  <FadeUpWrapper key={key} delay={50 + index * 50}>
    {element}
  </FadeUpWrapper>
);
