import React from "react";
import { palette } from "@palette";
import {
  AmountsListType,
  AnyAmountType,
  AmountType,
} from "@redux/slices/fundraisers";
// mui
import { Box, Stack } from "@mui/material";
// components
import { Text } from "@common/Text";
import CreateAmount from "./CreateAmount";
import EditAnyAmount from "./EditAnyAmount";
// dnd
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
// assets
import { EditIcon, TrashIcon } from "@assets/icons";
import { VerticalDragIcon } from "@assets/builderIcons";
// redux
import { useAppSelector } from "@redux/hooks";
import { selectAmounts } from "@redux/slices/fundraisers";
import { Switch_V2 } from "@common/Switch/Switch_V2";
import { isEmpty, isFunction } from "lodash";
import { CustomImage } from "./AmountItem";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";

type AmountItemMobileViewProps = {
  amountItem: AmountsListType;
  showAmountItem: (id: string) => void;
  removeAmountItem: (id: string) => void;
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  lastActive: string;
  handleEditing?: (id: string) => void;
  isAnyAmount?: boolean;
};

const AmountItemMobileView = ({
  amountItem,
  showAmountItem,
  removeAmountItem,
  lastActive,
  setIsSubmitDisabled,
  handleEditing,
  isAnyAmount,
}: AmountItemMobileViewProps) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const {
    id,
    title,
    amount,
    active,
    description,
    isDefault: fetchedIsDefault,
    thumbnail,
  } = amountItem as AmountType;
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const isDefault = fetchedIsDefault;
  const amountsList = useAppSelector(selectAmounts);

  const style = {
    zIndex: +isDragging,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const removeHandler = (id: string) => {
    if (lastActive) return;
    removeAmountItem(id);
  };
  const showImage = !!thumbnail;
  const imageSRC = addSizeToImage(thumbnail, "thumb");

  return (
    <div ref={setNodeRef} style={style}>
      {!isEdit && (
        <Box sx={containerStyle}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Switch_V2
              checked={amountsList.length === 1 && !active ? true : active}
              disabled={amountsList.length === 1 || lastActive === id}
              onChange={() => showAmountItem(id)}
            />
            {showImage && <CustomImage src={imageSRC} />}

            <div>
              <Text
                variant="h6"
                fontWeight="regular"
                color={palette.neutral[70]}
                align="left"
                sx={{
                  lineHeight: "24px",
                  wordBreak: "break-all",
                  marginBottom: "8px",
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                }}
              >
                {isDefault ? title : isAnyAmount ? "Any amount" : amount}{" "}
                {!isDefault && !isAnyAmount && <>USD</>}
              </Text>
              <Text
                align="left"
                color={palette.neutral[80]}
                variant="body"
                sx={{ wordBreak: "break-all", lineHeight: "17px" }}
              >
                {isDefault && typeof description === "string"
                  ? description
                  : title}
              </Text>
            </div>
            <Stack direction="row" alignItems="center" spacing={2}>
              {!isDefault && !isAnyAmount && (
                <Box
                  sx={{ mr: 1, opacity: lastActive ? 0.5 : 1 }}
                  onClick={() => removeHandler(id)}
                >
                  <TrashIcon stroke={palette.filled.red} />
                </Box>
              )}
              <Box
                onClick={() => {
                  isFunction(handleEditing)
                    ? handleEditing(id)
                    : setIsEdit(true);
                }}
              >
                <EditIcon stroke={palette.black[100]} />
              </Box>
              <Box {...attributes} {...listeners}>
                <VerticalDragIcon fill={palette.gray.main} />
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
      {isEdit && isDefault && (
        <EditAnyAmount
          setIsEditAnyAmount={setIsEdit}
          amountData={amountItem as AnyAmountType}
          setIsSubmitDisabled={setIsSubmitDisabled}
        />
      )}
      {isEdit && !isDefault && (
        <CreateAmount
          setIsCreateAmount={setIsEdit}
          initialObject={amountItem as AmountType}
          setIsSubmitDisabled={setIsSubmitDisabled}
          lastActive={Boolean(lastActive)}
        />
      )}
    </div>
  );
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "16px 8px",
  width: "100%",
  borderRadius: "8px",
  background: palette.neutral.white,
  border: `1px solid ${palette.liftedWhite[200]}`,
  justifyContent: "center",
};

export default AmountItemMobileView;
