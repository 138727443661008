import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { showMessage } from "@common/Toast";
import { updatePermissions } from "@redux/slices/app";
import { useAppDispatch } from "@redux/hooks";

type Status =
  | "clear"
  | "manually_cleared"
  | "possible_match"
  | "confirmed_match";

export type TOFACEndpointBuilder = {
  data: { status: Status };
  merchantId: number;
  legalEntityId?: number;
  ofacId: number;
};

export const lastCheckMapper = {
  possible_match: "Possible match",
  confirmed_match: "Confirmed match",
  clear: "Clear",
  manually_cleared: "Manually cleared",
};

export const useUpdateOFACCheck = ({
  tab,
  onSuccessCallback,
}: {
  tab: string;
  onSuccessCallback?: (data: any) => void;
}) => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  // {{URL}}/merchants/:MERCH_ID/legal-entities/:LEGAL_ID/ofac-checks/:OFAC_ID/status
  const updateOFACCheck = useMutation(
    ({ data, merchantId, legalEntityId, ofacId }: TOFACEndpointBuilder) => {
      return customInstance({
        url: legalEntityId
          ? `/merchants/${merchantId}/legal-entities/${legalEntityId}/ofac-checks/${ofacId}/status`
          : `/merchants/${merchantId}/ofac-checks/${ofacId}/status`,
        method: "PATCH",
        data,
      });
    },
  );

  const handleSubmit = ({
    data,
    merchantId,
    legalEntityId,
    ofacId,
  }: TOFACEndpointBuilder) => {
    updateOFACCheck.mutate(
      { data, merchantId, legalEntityId, ofacId },
      {
        onSuccess: (data) => {
          queryClient.refetchQueries("get-ofac-check-by-id");
          queryClient.refetchQueries(["get-merchant-preview", merchantId]);
          onSuccessCallback && onSuccessCallback(data);
        },
        onError: (error: any) => {
          showMessage(
            "Error",
            error?.message || error?.response?.status,
            true,
            "Something went wrong...",
          );
          if (error.not_authorized) {
            dispatch(
              updatePermissions({
                ofac_check: true,
              }),
            );
          }
        },
        onSettled: () => {
          queryClient.refetchQueries(["list-ofac-checks", merchantId, tab]);
          queryClient.invalidateQueries("get-merchant-preview");
        },
      },
    );
  };

  return {
    handleSubmit,
  };
};
