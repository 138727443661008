import { RHFCheckbox } from "@common/Checkbox";
import { RHFInput } from "@common/Input";
import { Stack } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { RenderNotificationForm } from "./types";
import { useEffect } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { checkPortals } from "@utils/routing";
import { HiddenComponent } from "@containers/HiddenComponent";

const GenericNotificationForm = ({
  onSubmit,
  setSubmitStatus,
}: RenderNotificationForm) => {
  const { isAcquirerPortal } = checkPortals();

  const methods = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      subject: "Provide missing data",
      message: "",
      addConversation: isAcquirerPortal,
    },
  });

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    setSubmitStatus({
      isDisabled: !isValid,
      message: !isValid ? "A message is required" : "",
    });
  }, [isValid]);

  const submitHandler: SubmitHandler<Inputs> = (data) => {
    setSubmitStatus({
      isDisabled: true,
      message: "",
    });
    onSubmit({
      subject: data.subject,
      message: data.message,
      openConversation: data.addConversation,
    });
  };

  return (
    <FormProvider {...methods}>
      <Stack
        direction="column"
        gap="12px"
        paddingInline="24px"
        component="form"
        id="notify-merchant-form"
        onSubmit={methods.handleSubmit(submitHandler)}
      >
        <FadeUpWrapper delay={40}>
          <RHFInput
            name="subject"
            fullWidth
            placeholder="Notification subject"
            label="Subject"
          />
        </FadeUpWrapper>
        <FadeUpWrapper delay={60}>
          <RHFInput
            name="message"
            fullWidth
            placeholder="Message for merchant"
            label="Message"
            multiline
            rows={5}
          />
        </FadeUpWrapper>
        <HiddenComponent hidden={!isAcquirerPortal}>
          <FadeUpWrapper delay={80}>
            <RHFCheckbox
              name="addConversation"
              label="Add a note in conversation"
              sx={{
                "& .MuiButtonBase-root.MuiCheckbox-root": {
                  height: 20,
                  "& > svg": {
                    height: 18,
                    width: 18,
                  },
                },
              }}
            />
          </FadeUpWrapper>
        </HiddenComponent>
      </Stack>
    </FormProvider>
  );
};

export default GenericNotificationForm;

type Inputs = {
  subject: string;
  message: string;
  addConversation: boolean;
};

const schema = Yup.object().shape({
  subject: Yup.string(),
  message: Yup.string().required("A message is required"),
  addConversation: Yup.boolean(),
});
