// mui
import Stack from "@mui/material/Stack";
import {
  FilterWithSwitch,
  FilterWithDate,
  FilterWithAmount,
  ClearFiltersButton,
} from "@common/Filter";
// redux
import { selectFilters as sweepstakesSelectFilters } from "@redux/slices/sweepstakes";
import { useAppSelector } from "@redux/hooks";
import {
  addDateFilter,
  disableDateFilter,
  selectFilters,
  clearFilters,
} from "@redux/slices/fundraisers";
import { listCreatedFilter } from "@components/Fundraisers/FundraisersList/FundraisersListFilters";
import { selectQueryFilters } from "@redux/slices/fundraisers";

const SweepstakesListFilters = ({ money }: any) => {
  const filters = useAppSelector(selectFilters);
  const sweepstakesFilters = useAppSelector(sweepstakesSelectFilters);

  return (
    <Stack py={1} direction={"row"} flexWrap={"wrap"}>
      <FilterWithDate
        title="created"
        options={listCreatedFilter}
        filters={filters}
        apply={addDateFilter}
        disable={disableDateFilter}
      />
      {/* <FilterWithAmount
        title="amount"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithRangeSlider
        title="conversion"
        filters={sweepstakesFilters}
        apply={addConversionRangeFilter}
        disable={disableConversionRangeFilter}
      />
      <FilterWithSwitch
        title="status"
        options={{
          Open: false,
          "Winner needs drawn": false,
          "Winner selected": false,
        }}
        filters={filters}
        apply={addFilter}
        remove={removeFilter}
        disable={disableFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="entries sold"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="transactions"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="visitors"
        options={[
          { value: "is between", label: "is between" },
          {
            value: "is equal to or greater than",
            label: "is equal to or greater than",
          },
          {
            value: "is equal to or less than",
            label: "is equal to or less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      /> */}

      <ClearFiltersButton
        clear={clearFilters}
        selector={selectQueryFilters}
      />
    </Stack>
  );
};

export default SweepstakesListFilters;
