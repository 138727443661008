type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const DeclinedIcon = ({
  width = 18,
  height = 18,
  stroke = "#575353",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 13.5L4.5 4.5M15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9Z"
        stroke={stroke}
        strokeWidth="1.6"
      />
    </svg>
  )
}

export default DeclinedIcon;
