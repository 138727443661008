import { palette } from "@palette";
// modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// form
import { FormProvider } from "react-hook-form";
// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { BirthDatePicker } from "@common/DatePickers";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import { ZipCodeInput } from "@common/AddressInputs";
import { NameInput } from "@common/BusinessProfileInputs";
// assets
import { InfoIcon } from "@assets/icons";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
// hooks
import { useCreateCustomer } from "@hooks/merchant-api/customers";
import CampaignModalActions from "@common/Modal/CampaignModalActions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

const AddCustomer = NiceModal.create(() => {
  const theme = useTheme();
  const modal = useModal();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation(namespaces.pages.customers);

  const handleClose = () => {
    reset();
    modal.hide();
  };

  const { methods, onSubmit, isLoading, isDisabled, handleChangeStatus } =
    useCreateCustomer();

  const { reset, watch, formState } = methods;
  const values = watch();

  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  return (
    <Modal
      title={t("addCustomer")}
      width="720px"
      {...muiDialogV5(modal)}
      onClose={handleClose}
      actions={
        <CampaignModalActions
          form="newCustomer"
          isLoading={isLoading}
          primaryBtnLabel={t("addCustomer")}
          isSubmitDisabled={!formState.isDirty}
          isPrimaryActionDisabled={!(values.email.length > 0) || isDisabled}
        />
      }
      sx={{
        "& .MuiDialogTitle-root": {
          background: palette.liftedWhite.main,
          padding: "12px 16px 8px",
          "& > button": {
            background: palette.liftedWhite.main,
          },
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          id="newCustomer"
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FadeUpWrapper delay={50}>
            <EmailInfoMessage />
          </FadeUpWrapper>

          <Stack gap={3} direction={isDesktop ? "row" : "column"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FadeUpWrapper
                  delay={100}
                  containerProps={{ sx: { width: "max-content" } }}
                >
                  <WithTooltipWrapper
                    hasTooltip={!isAddImageAllowed}
                    tooltipProps={{
                      show: !isAddImageAllowed,
                      message: UPLOAD_DENY_MESSAGE,
                    }}
                  >
                    <RHFUploadAvatar
                      name="avatarURL"
                      onChangeStatus={handleChangeStatus}
                      disabled={!isAddImageAllowed}
                      width="96px"
                      height="96px"
                      marginBottom={2}
                      marginTop={4}
                    />
                  </WithTooltipWrapper>
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={150}>
                  <NameInput
                    name="firstName"
                    placeholder={t("firstName")}
                    label={t("firstName")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={150}>
                  <NameInput
                    name="lastName"
                    placeholder={t("lastName")}
                    label={t("lastName")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={200}>
                  <RHFInput
                    name="email"
                    placeholder="example@gmail.com"
                    fullWidth
                    label={t("emailRequired")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={200}>
                  <BirthDatePicker
                    name="dateOfBirth"
                    placeholder="MM/DD/YYYY"
                    fullWidth
                    label={"Date Of Birth (Required)"}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={300}>
                  <RHFTelInput
                    fullWidth
                    name="phone"
                    label={t("phoneNumber")}
                  />
                </FadeUpWrapper>
              </Grid>
            </Grid>
          </Stack>

          <FadeUpWrapper delay={325}>
            <CustomerModalDivider />
          </FadeUpWrapper>

          <Box>
            <Grid
              mt={1}
              container
              spacing={2}
              direction="row"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={350}>
                  <RHFInput
                    name="occupation"
                    fullWidth
                    placeholder={t("enterOccupation")}
                    type="text"
                    label={t("Occupation")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FadeUpWrapper delay={300}>
                  <RHFInput
                    name="employer"
                    fullWidth
                    placeholder={t("enterEmployer")}
                    label={t("Employer")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12}>
                <FadeUpWrapper delay={350}>
                  <RHFInput
                    name="address.line1"
                    fullWidth
                    placeholder="St. Avenue 16"
                    type="text"
                    label={t("street_address")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FadeUpWrapper delay={350}>
                  <RHFInput
                    name="address.city"
                    fullWidth
                    placeholder="Albany"
                    type="text"
                    label={t("city")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FadeUpWrapper delay={400}>
                  <RHFInput
                    name="address.state"
                    fullWidth
                    placeholder="New York"
                    type="text"
                    label={t("state")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FadeUpWrapper delay={450}>
                  <ZipCodeInput
                    name="address.zip"
                    placeholder="XXXXX"
                    label={t("zip")}
                  />
                </FadeUpWrapper>
              </Grid>
              <Grid item xs={12}>
                <FadeUpWrapper delay={500}>
                  <RHFInput
                    name="notes"
                    fullWidth
                    placeholder={t("Write something...")}
                    type="text"
                    label="Notes"
                    multiline
                    rows={5}
                    inputProps={{
                      maxLength: "200",
                    }}
                    helperText={`${
                      200 - values.notes.length
                    } Characters remaining`}
                  />
                </FadeUpWrapper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormProvider>
    </Modal>
  );
});

export const CustomerModalDivider = styled(Box)(() => ({
  width: "100%",
  borderRadius: "10px",
  border: `1px solid ${palette.liftedWhite[200]}`,
  marginBlock: "32px 16px",
}));

export const EmailInfoMessage = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      width="100%"
      sx={{
        background: palette.neutral.white,
        padding: "8px",
        borderRadius: "8px",
      }}
    >
      <InfoIcon width={21} height={21} path_fill={palette.gray[100]} />
      <Text>
        The email address provided will be used as the customer&apos;s unique
        identifier in our system.
      </Text>
    </Stack>
  );
};

export default AddCustomer;
