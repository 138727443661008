import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";
import { palette } from "@palette";
import { Stack, SxProps } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Button } from "@common/Button";
import { Text } from "@common/Text";

type ApproveMerchantProps = {
  handleApprove: () => void;
  statusName: string;
  name: string;
};

const ApproveMerchantModal = NiceModal.create(
  ({ handleApprove, statusName, name }: ApproveMerchantProps) => {
    const isMIDIssue = statusName === "preapproved_mid_issue";
    const props = {
      modalTitle: isMIDIssue ? "Retry Approval" : "Approve Merchant",
      submitLabel: isMIDIssue ? "Continue" : "Approve",
      children: isMIDIssue ? (
        <Text fontWeight="book" color={palette.neutral[70]} lineHeight="16.8px">
          The MSP declined the initial application. Make sure all data is valid
          before retrying.
        </Text>
      ) : (
        <Text fontWeight="book" color={palette.neutral[70]} lineHeight="16.8px">
          Are you sure you want to approve{" "}
          <span style={{ color: palette.neutral[100] }}>{name}</span>? This
          action will finalize the approval status and cannot be undone.
        </Text>
      ),
    };
    return (
      <UnderwritingPopupBase
        handleSubmit={handleApprove}
        primaryButtonBackground={palette.filled.success}
        actionSx={{ fontSize: 18 }}
        {...props}
      />
    );
  },
);

interface UnderwritingPopupBaseProps {
  handleSubmit: () => void;
  submitLabel: string;
  modalTitle: string;
  primaryButtonBackground: string;
  children?: React.ReactNode;
  width?: string;
  actionSx?: SxProps;
}

export const UnderwritingPopupBase = ({
  handleSubmit,
  submitLabel,
  modalTitle,
  primaryButtonBackground,
  children,
  width = "549px",
  actionSx,
}: UnderwritingPopupBaseProps) => {
  const modal = useModal();
  const { isDesktopView } = useCustomTheme();

  const onCancel = () => {
    modal.hide();
  };

  const onSubmit = async () => {
    await handleSubmit();
    onCancel();
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      title={modalTitle}
      isClose
      onCancel={onCancel}
      width={width}
      titleSx={{
        fontSize: 18,
        fontWeight: 350,
        color: palette.neutral[80],
      }}
      actions={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isDesktopView ? "flex-end" : "stretch"}
          gap={isDesktopView ? 2 : 0}
          width="100%"
        >
          <Button
            background="tertiary"
            onClick={onCancel}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: palette.neutral[70],
              ...(actionSx && actionSx),
              ...(!isDesktopView && {
                flexGrow: 1,
              }),
            }}
          >
            Cancel
          </Button>
          <Button
            background="primary"
            onClick={onSubmit}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              padding: "12px 24px",
              background: primaryButtonBackground,
              "&:hover": {
                background: primaryButtonBackground,
              },
              ...(actionSx && actionSx),
              ...(!isDesktopView && {
                flexGrow: 1,
              }),
            }}
            data-testid="approve-merchant-modal-submit-button"
          >
            {submitLabel}
          </Button>
        </Stack>
      }
      sx={{
        ...(!isDesktopView && {
          "& .MuiDialog-paper": {
            backgroundColor: palette.neutral.white,
          },
        }),
        "& .MuiDialogContent-root": {
          padding: "16px !important",
        },
        "& .MuiDialogActions-root": {
          padding: "16px !important",
        },
      }}
    >
      {children}
    </Popup>
  );
};

export default ApproveMerchantModal;
