import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import Logo from "@assets/icons/RebrandedIcons/Logo";
import { LoginExternalLink } from "@components/Login";
import { palette } from "@palette";
import { ITextProps } from "@common/Text/Text";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  isSendingToProvider: boolean;
};

const ExternalLinks = () => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack direction="row" gap="4px" marginTop="16px">
      <LoginExternalLink underline={palette.black[100]} href="/privacy">
        <StyledText customFontSize={isMobileView ? "12px" : undefined}>
          Privacy Policy
        </StyledText>
      </LoginExternalLink>
      <Text>-</Text>
      <LoginExternalLink
        underline={palette.black[100]}
        href="mailto:support@givecorporation.com"
      >
        <StyledText customFontSize={isMobileView ? "12px" : undefined}>
          Support
        </StyledText>
      </LoginExternalLink>
    </Stack>
  );
};

const PreviewInvitationModalFooter = ({ isSendingToProvider }: Props) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack alignItems="center" gap={2}>
      {!isSendingToProvider && (
        <Stack direction="row">
          <StyledText
            customFontSize={isMobileView ? "12px" : undefined}
            color={palette.neutral[80]}
          >
            Powered by
          </StyledText>
          <Logo height={14} />
        </Stack>
      )}

      <Stack alignItems="center">
        <StyledText
          customFontSize={isMobileView ? "12px" : undefined}
          color={palette.neutral[80]}
        >
          © {new Date().getFullYear()} Give Corporation Inc.
        </StyledText>
        <StyledText
          customFontSize={isMobileView ? "12px" : undefined}
          color={palette.neutral[80]}
        >
          4343 N Scottsdale Road Suite #150 Scottsdale, AZ 85251
        </StyledText>
      </Stack>
      <ExternalLinks />
    </Stack>
  );
};

export default PreviewInvitationModalFooter;

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "customFontSize",
})<ITextProps & { color?: string; customFontSize?: string | number }>(
  ({ theme, color, customFontSize }) => ({
    fontSize: customFontSize || 14,
    fontWeight: 400,
    lineHeight: "18.9px",
    color: color || palette.black[100],

    [theme.breakpoints.down("sm")]: {
      lineHeight: "16.8px",
      fontWeight: 350,
    },
  }),
);
