type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

export const TrashIcon = ({
  width = 32,
  height = 32,
  fill = "#D80D0D",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2188 6.54297H22.2188V5.54297C22.2188 4.74732 21.9027 3.98426 21.3401 3.42165C20.7775 2.85904 20.0144 2.54297 19.2188 2.54297H13.2188C12.4231 2.54297 11.66 2.85904 11.0974 3.42165C10.5348 3.98426 10.2188 4.74732 10.2188 5.54297V6.54297H5.21875C4.95353 6.54297 4.69918 6.64833 4.51164 6.83586C4.32411 7.0234 4.21875 7.27775 4.21875 7.54297C4.21875 7.80819 4.32411 8.06254 4.51164 8.25008C4.69918 8.43761 4.95353 8.54297 5.21875 8.54297H6.21875V26.543C6.21875 27.0734 6.42946 27.5821 6.80454 27.9572C7.17961 28.3323 7.68832 28.543 8.21875 28.543H24.2188C24.7492 28.543 25.2579 28.3323 25.633 27.9572C26.008 27.5821 26.2188 27.0734 26.2188 26.543V8.54297H27.2188C27.484 8.54297 27.7383 8.43761 27.9259 8.25008C28.1134 8.06254 28.2188 7.80819 28.2188 7.54297C28.2188 7.27775 28.1134 7.0234 27.9259 6.83586C27.7383 6.64833 27.484 6.54297 27.2188 6.54297ZM12.2188 5.54297C12.2188 5.27775 12.3241 5.0234 12.5116 4.83586C12.6992 4.64833 12.9535 4.54297 13.2188 4.54297H19.2188C19.484 4.54297 19.7383 4.64833 19.9259 4.83586C20.1134 5.0234 20.2188 5.27775 20.2188 5.54297V6.54297H12.2188V5.54297ZM24.2188 26.543H8.21875V8.54297H24.2188V26.543ZM14.2188 13.543V21.543C14.2188 21.8082 14.1134 22.0625 13.9259 22.2501C13.7383 22.4376 13.484 22.543 13.2188 22.543C12.9535 22.543 12.6992 22.4376 12.5116 22.2501C12.3241 22.0625 12.2188 21.8082 12.2188 21.543V13.543C12.2188 13.2778 12.3241 13.0234 12.5116 12.8359C12.6992 12.6483 12.9535 12.543 13.2188 12.543C13.484 12.543 13.7383 12.6483 13.9259 12.8359C14.1134 13.0234 14.2188 13.2778 14.2188 13.543ZM20.2188 13.543V21.543C20.2188 21.8082 20.1134 22.0625 19.9259 22.2501C19.7383 22.4376 19.484 22.543 19.2188 22.543C18.9535 22.543 18.6992 22.4376 18.5116 22.2501C18.3241 22.0625 18.2188 21.8082 18.2188 21.543V13.543C18.2188 13.2778 18.3241 13.0234 18.5116 12.8359C18.6992 12.6483 18.9535 12.543 19.2188 12.543C19.484 12.543 19.7383 12.6483 19.9259 12.8359C20.1134 13.0234 20.2188 13.2778 20.2188 13.543Z"
        fill={fill}
      />
    </svg>
  );
};
