import { Button } from "@common/Button";
import { Box, Divider, Grid } from "@mui/material";
import { Modal } from "@common/Modal";
import React from "react";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { showMessage } from "@common/Toast/ShowToast";

type DrawWinnerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  isOneWinner: boolean;
  isTicketPurchasesApproved: boolean;
};

const bigTitleStyle = {
  color: palette.neutral[800],
  fontSize: "24px",
  fontWeight: 600,
  mb: "8px",
};

const descriptionStyle = {
  color: palette.neutral[600],
  fontSize: "16px",
  fontWeight: 400,
};

const boldTitleStyle = {
  color: palette.neutral[800],
  fontWeight: 600,
};

const captionStyle = {
  fontWeight: 400,
  fontSize: 14,
  color: palette.neutral[600],
  fontStyle: "italic",
};

const DrawWinner = ({
  open,
  setOpen,
  title,
  isOneWinner,
  isTicketPurchasesApproved,
}: DrawWinnerProps) => {
  const drawWinnerHandler = () => {
    setOpen(false);
    if (!isTicketPurchasesApproved) {
      showMessage(
        "Warning",
        "Please wait until all outstanding ticket purchases have been approved, this can take up to 24 hours from the last purchase.",
      );
    }
  };

  return (
    <Modal
      open={open}
      title={`Draw Winner${isOneWinner ? "" : "s"}`}
      onClose={() => setOpen(false)}
      actions={
        <>
          <Button variant="text" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={drawWinnerHandler}>
            Draw Winner{isOneWinner ? "" : "s"}
          </Button>
        </>
      }
    >
      <Text sx={bigTitleStyle}>Draw Confirmation</Text>
      <Text sx={descriptionStyle}>
        Please confirm you want to draw the winner of{" "}
        <span style={boldTitleStyle}>{title}</span> sweepstakes.
      </Text>
      <Divider sx={{ my: "16px" }} />
      {isOneWinner ? (
        <Text sx={captionStyle}>
          Only <span style={{ fontWeight: 500 }}>one winner</span> will be drawn
          for the sweepstakes. An email will be sent to all participants.
        </Text>
      ) : (
        <Text sx={captionStyle}>
          You have enabled multiple winners for this Sweepstakes. A winner will
          be drawn <span style={{ fontWeight: 500 }}>for each ticket</span> item
          created. An email will be sent to all participants.
        </Text>
      )}
    </Modal>
  );
};

export default DrawWinner;
