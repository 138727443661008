import WithEmptyStatesCampaigns from "@containers/WithEmptyStates";

const TYPE = "payment-form";
const PaymentFormsPage = ({
  shouldOpenModal,
}: {
  shouldOpenModal: boolean;
}) => (
  <WithEmptyStatesCampaigns type={TYPE} shouldOpenModal={shouldOpenModal} />
);

export default PaymentFormsPage;
