const options: any = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      padding: {
        left: 12,
        right: 12,
        top: 9,
        bottom: 9,
      },
      displayColors: false,
      caretSize: 0,
      cornerRadius: 6,
      titleColor(ctx: any, options: any) {
        return "#B8B8B8";
      },
      callbacks: {
        title: () => "Customers - Last 3 months",
        labelTextColor(tooltipItem: any) {
          return "#FFFFFF";
        },
      },
      titleFont: {
        size: 12,
        weight: "normal",
      },
      bodyFont: {
        size: 12,
        weight: "normal",
      },
    },
  },
  scales: {
    projection: {
      axis: "x" as const,
      projection: "albersUsa" as const,
    },
    color: {
      display: false,
      axis: "x" as const,
    },
  },
};

export default options;
