type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const CellSignalLowIcon = ({
  width = 24,
  height = 25,
  stroke = "#FFA724",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18.574 2.52124C18.2999 2.40754 17.9983 2.37768 17.7073 2.43542C17.4163 2.49315 17.1489 2.63591 16.939 2.84562L1.93899 17.8456C1.7293 18.0555 1.58653 18.3227 1.52874 18.6137C1.47095 18.9047 1.50073 19.2063 1.61432 19.4803C1.72791 19.7543 1.92021 19.9885 2.1669 20.1533C2.41359 20.3181 2.70359 20.406 3.00024 20.4059H18.0002C18.3981 20.4059 18.7796 20.2479 19.0609 19.9666C19.3422 19.6853 19.5002 19.3038 19.5002 18.9059V3.90593C19.5007 3.60925 19.4129 3.31914 19.248 3.07254C19.083 2.82595 18.8484 2.63403 18.574 2.52124ZM18.0002 18.9059H8.25024V13.6559L18.0002 3.90593V18.9059Z"
        fill={stroke}
      />
    </svg>
  );
};

export default CellSignalLowIcon;
