import bold from "@assets/svgIcons/editor-bold.svg";
import italic from "@assets/svgIcons/editor-italic.svg";
import underlined from "@assets/svgIcons/editor-underlined.svg";
import strikethrough from "@assets/svgIcons/editor-strikethrough.svg";
import left from "@assets/svgIcons/editor-left.svg";
import center from "@assets/svgIcons/editor-center.svg";
import right from "@assets/svgIcons/editor-right.svg";
import list from "@assets/svgIcons/editor-list.svg";
import link from "@assets/svgIcons/editor-link.svg";
import emoji from "@assets/svgIcons/editor-emoji.svg";
import image from "@assets/svgIcons/editor-image.svg";

export const toolbar = {
  inline: {
    bold: {
      icon: bold,
      className: "demo-option-custom",
    },
    italic: { icon: italic, className: "demo-option-custom" },
    underline: { icon: underlined, className: "demo-option-custom" },
    strikethrough: {
      icon: strikethrough,
      className: "demo-option-custom",
    },
    monospace: { className: "editor-tool-hidden" },
    superscript: { className: "editor-tool-hidden" },
    subscript: { className: "editor-tool-hidden" },
  },
  blockType: {
    className: "demo-option-custom-wide",
    dropdownClassName: "demo-dropdown-custom",
  },
  fontSize: { className: "demo-option-custom-medium" },
  list: {
    unordered: { icon: list, className: "demo-option-custom" },
    ordered: { className: "editor-tool-hidden" },
    indent: { className: "editor-tool-hidden" },
    outdent: { className: "editor-tool-hidden" },
  },
  textAlign: {
    left: { icon: left, className: "demo-option-custom" },
    center: { icon: center, className: "demo-option-custom" },
    right: { icon: right, className: "demo-option-custom" },
    justify: { className: "editor-tool-hidden" },
  },
  fontFamily: {
    className: "demo-option-custom-wide",
    dropdownClassName: "demo-dropdown-custom",
  },
  colorPicker: {
    className: "editor-tool-hidden",
    popupClassName: "demo-popup-custom",
  },
  link: {
    popupClassName: "demo-popup-custom",
    link: { icon: link, className: "demo-option-custom" },
    // unlink: { , className: 'demo-option-custom' },
  },
  emoji: {
    icon: emoji,
    className: "demo-option-custom",
    popupClassName: "demo-popup-custom",
  },
  // embedded: { className: 'demo-option-custom', popupClassName: 'demo-popup-custom' },
  image: {
    icon: image,
    className: "demo-option-custom",
    popupClassName: "demo-popup-custom",
  },
  // remove: { , className: 'demo-option-custom' },
  // history: {
  //   undo: { , className: 'demo-option-custom' },
  //   redo: { , className: 'demo-option-custom' },
  // },
};
