type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const ShieldWarningIcon = ({
  width = 19,
  height = 19,
  stroke = "#D80D0D",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M15.416 4.60022V8.73249C15.416 14.6514 10.402 16.6124 9.40148 16.945C9.28942 16.9837 9.16762 16.9837 9.05555 16.945C8.055 16.6138 3.04102 14.6549 3.04102 8.73389V4.60022C3.04102 4.45104 3.10028 4.30796 3.20577 4.20247C3.31126 4.09698 3.45433 4.03772 3.60352 4.03772H14.8535C15.0027 4.03772 15.1458 4.09698 15.2513 4.20247C15.3568 4.30796 15.416 4.45104 15.416 4.60022Z"
        fill={stroke}
      />
      <path
        d="M14.8535 3.47522H3.60352C3.30515 3.47522 3.019 3.59375 2.80802 3.80472C2.59704 4.0157 2.47852 4.30185 2.47852 4.60022V8.73249C2.47852 15.0339 7.80961 17.1236 8.87695 17.478C9.10484 17.556 9.35219 17.556 9.58008 17.478C10.6488 17.1229 15.9785 15.0332 15.9785 8.73178V4.60022C15.9785 4.30185 15.86 4.0157 15.649 3.80472C15.438 3.59375 15.1519 3.47522 14.8535 3.47522ZM14.8535 8.73389C14.8535 14.2478 10.189 16.09 9.22852 16.4106C8.27719 16.0942 3.60352 14.2534 3.60352 8.73389V4.60022H14.8535V8.73389ZM8.66602 10.2252V7.41272C8.66602 7.26354 8.72528 7.12046 8.83077 7.01497C8.93626 6.90948 9.07933 6.85022 9.22852 6.85022C9.3777 6.85022 9.52077 6.90948 9.62626 7.01497C9.73175 7.12046 9.79102 7.26354 9.79102 7.41272V10.2252C9.79102 10.3744 9.73175 10.5175 9.62626 10.623C9.52077 10.7285 9.3777 10.7877 9.22852 10.7877C9.07933 10.7877 8.93626 10.7285 8.83077 10.623C8.72528 10.5175 8.66602 10.3744 8.66602 10.2252ZM8.38477 12.7565C8.38477 12.5896 8.43425 12.4265 8.52696 12.2877C8.61968 12.149 8.75145 12.0408 8.90563 11.9769C9.0598 11.9131 9.22945 11.8964 9.39312 11.9289C9.55679 11.9615 9.70714 12.0418 9.82514 12.1598C9.94314 12.2778 10.0235 12.4282 10.0561 12.5919C10.0886 12.7555 10.0719 12.9252 10.008 13.0794C9.94418 13.2335 9.83603 13.3653 9.69728 13.458C9.55852 13.5507 9.39539 13.6002 9.22852 13.6002C9.00474 13.6002 8.79013 13.5113 8.63189 13.3531C8.47366 13.1949 8.38477 12.9802 8.38477 12.7565Z"
        fill={stroke}
      />
    </svg>
  );
};

export default ShieldWarningIcon;
