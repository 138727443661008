import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import SearchBar from "@common/SearchBar/SearchBar";
import { DropdownItem } from "@common/Select";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Popover } from "@mui/material";
import { SearchIcon } from "@assets/icons";
import Placeholder from "@assets/images/avatar-placeholder.png";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { Stack } from "@mui/material";
import {
  CustomerPeopleIcon,
  SearchIcon as MyCustomSearchIcon,
} from "@assets/rebrandIcons";
import MobileAssigneeItem from "./MobileAssigneeItem";
import AssignmentMenuItem from "./AssignmentMenuItem";

export type MenuItem = {
  disabled?: boolean;
  onSelect: (e: any) => void;
  label: string | React.ReactNode;
  roleName: string;
};

const HEIGHT = 232;
const WIDTH = 277;
const SEARCH_BAR_HEIGHT = 32;
interface ICustomAssignMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  statusName?: string;
  handleChange: (value: string | null) => void;
  assigneeId: number;
  data: any;
  isLoading: boolean;
  isFetching: boolean;
  searchQuery: string;

  serachQueryKey: string;
  emptyStateTitle: string;
  emptyStateSubtitle: string;

  currentUserId?: number;
  showRole?: boolean;
  searchBarHeight?: number;
  popoverHeight?: number;
}

function AssignMenu({
  anchorEl,
  handleClose,
  statusName,
  handleChange,
  assigneeId,

  data,
  isLoading,
  isFetching,
  searchQuery,

  serachQueryKey,
  emptyStateTitle,
  emptyStateSubtitle,
  currentUserId = 0,
  showRole = true,
  searchBarHeight = SEARCH_BAR_HEIGHT,
  popoverHeight = HEIGHT,
}: ICustomAssignMenuProps) {
  const [customStyle, setCustomStyle] = useState({});
  const { isMobileView } = useCustomTheme();
  const lastValidCustomStyle = useRef({
    top: 0,
    left: 0,
    position: "absolute",
  });
  const open = Boolean(anchorEl);
  const membersList = (Array.isArray(data) && data) || [];

  const options = useMemo(
    () =>
      generateOptions(
        membersList,
        handleChange,
        assigneeId,
        currentUserId,
        showRole,
      ),
    [
      membersList,
      handleChange,
      assigneeId,
      currentUserId,
      statusName,
      showRole,
    ],
  );

  useEffect(() => {
    if (anchorEl) {
      const rect = anchorEl.getBoundingClientRect();
      const willOverlap = rect.bottom + popoverHeight > window.innerHeight;
      const newCustomPositioning = {
        top: willOverlap
          ? rect.top - popoverHeight
          : rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        position: "absolute",
      };

      if (newCustomPositioning.top !== 0 || newCustomPositioning.left !== 0) {
        setCustomStyle(newCustomPositioning);

        lastValidCustomStyle.current = newCustomPositioning;
      } else {
        setCustomStyle(lastValidCustomStyle.current);
      }
    }
  }, [anchorEl]);

  const handlePopoverClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };
  const isEmpty =
    options?.length === 0 && !isLoading && !isFetching && searchQuery;
  const loading = isLoading || isFetching;
  const noMembersFound =
    options?.length === 0 && !searchQuery && !isLoading && !isFetching;

  return (
    <>
      {isMobileView ? (
        <SwipeableDrawerMobile
          anchor="bottom"
          onOpen={() => undefined}
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root.MuiDrawer-paper": {
              background: palette.neutral[5],
              top: "124px",
            },
          }}
        >
          <Stack direction="column" gap="24px">
            <Text
              color={palette.black[100]}
              variant="h6"
              lineHeight="21.6px"
              fontWeight="book"
              letterSpacing="-0.18px"
            >
              Change assignee
            </Text>
            <SearchBar isDebounceSearch queryKey={serachQueryKey} />
            {loading ? (
              <LoadingComponent />
            ) : (
              <>
                {isEmpty && !noMembersFound && <EmptyState />}
                {noMembersFound && !isEmpty && (
                  <EmptyState
                    title={emptyStateTitle}
                    subTitle={emptyStateSubtitle}
                    Icon={
                      <CustomerPeopleIcon width={48} gradient height={48} />
                    }
                  />
                )}
                {!noMembersFound &&
                  !isEmpty &&
                  membersList?.map(({ user }) => {
                    const val = user?.accID;
                    const name =
                      user?.firstName && user?.lastName
                        ? `${user?.firstName} ${user?.lastName}`
                        : user?.email;

                    return (
                      <MobileAssigneeItem
                        key={val}
                        name={name}
                        image={
                          user.imageURL ? `${user.imageURL}/thumb` : Placeholder
                        }
                        selected={val === assigneeId}
                        handleDeselect={() => handleChange("unassigned")}
                        onClick={() => handleChange(val)}
                      />
                    );
                  })}
              </>
            )}
          </Stack>
        </SwipeableDrawerMobile>
      ) : (
        <Popover
          id={options?.length?.toString()}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: `${WIDTH}px`,
              height: `${popoverHeight}px`,
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              overflow: "hidden",
              backgroundColor: "#FAFAFA",
              boxShadow: "0px 8px 25px 0px #00000026",
              borderRadius: "8px",
            },
            ...customStyle,
          }}
          anchorReference="none"
          onClick={handlePopoverClick}
        >
          <SearchBar
            isDebounceSearch
            inputSx={{
              borderRadius: "0px",
              border: "none",
              backgroundColor: "transparent",
              borderBottom: "1px solid #ECECE9",
              padding: "10px 12px ",
              "& input::placeholder": {
                color: "#8F8F8F",
                fontSize: "14px",
                fontWeight: 350,
              },
              gap: "4px",
              height: `${searchBarHeight}px`,
            }}
            startAdornment={<SearchIcon width={25} />}
            isExpandedByDefault
            queryKey={serachQueryKey}
          />
          <Box
            pb="20px"
            style={{
              maxHeight: popoverHeight - searchBarHeight - 8,
              overflow: "auto",
            }}
          >
            {loading ? (
              <LoadingComponent />
            ) : (
              <>
                {isEmpty && !noMembersFound && <EmptyState />}
                {noMembersFound && !isEmpty && (
                  <EmptyState
                    title={emptyStateTitle}
                    subTitle={emptyStateSubtitle}
                    Icon={
                      <CustomerPeopleIcon width={48} gradient height={48} />
                    }
                  />
                )}
                {!noMembersFound &&
                  !isEmpty &&
                  options?.map(({ label, onSelect, disabled }, index) => (
                    <DropdownItem
                      sx={itemStyles(disabled)}
                      key={index}
                      onClick={(e) => {
                        if (!disabled) {
                          onSelect();
                          handleClose();
                        }
                      }}
                    >
                      {typeof label === "string" ? (
                        <Text
                          fontSize={14}
                          lineHeight={"21.6px"}
                          color={
                            disabled ? palette.neutral[70] : palette.neutral[80]
                          }
                        >
                          {label}
                        </Text>
                      ) : (
                        label
                      )}
                    </DropdownItem>
                  ))}
              </>
            )}
          </Box>
        </Popover>
      )}
    </>
  );
}

export default memo(AssignMenu);

export const itemStyles = (disabled?: boolean) => {
  return {
    whiteSpace: "pre-wrap",
    padding: "4px",
    width: "100%",
    boxSizing: "border-box",
    ...(disabled && {
      cursor: "default",
      "&:hover": { backgroundColor: "transparent" },
    }),
  };
};

const generateOptions = (
  membersList: any[],
  handleChange: (value: string | null) => void,
  assigneeId: number,
  currentUserId: number,
  showRole?: boolean,
) => {
  return membersList?.map(({ user, roleDisplayName, roleName }: any) => {
    const name =
      user?.firstName && user?.lastName
        ? `${user?.firstName} ${user?.lastName}`
        : user?.email;

    return {
      value: user?.accID,
      onSelect: () => handleChange(user?.accID),
      imageUrl: user?.imageURL,
      userLabel: name,
      roleName,
      disabled: false,
      label: (
        <AssignmentMenuItem
          name={name}
          image={user.imageURL ? `${user.imageURL}/thumb` : Placeholder}
          selected={user?.accID === assigneeId}
          handleDeselect={() => handleChange("unassigned")}
          role={showRole ? roleDisplayName : undefined}
          isCurrentUser={user?.accID === currentUserId}
          isBigView={!showRole}
        />
      ),
    };
  });
};

export const EmptyState = ({
  title = "No results found",
  subTitle = "  Please try a different search term",
  Icon = <MyCustomSearchIcon width={48} isGradient height={48} />,
}) => {
  return (
    <Box
      flexDirection="column"
      width="100%"
      height="180px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      data-testid="emptyState_custom_menu"
    >
      {Icon}
      <Text fontWeight="light" fontSize="18px" color="#292727">
        {title}
      </Text>
      <Text
        textAlign="center"
        fontWeight="book"
        fontSize="14px"
        color="#8F8F8F"
        maxWidth="80%"
      >
        {subTitle}
      </Text>
    </Box>
  );
};

export const LoadingComponent = () => {
  const { isMobileView } = useCustomTheme();
  return (
    <Box data-testid="loading_custom_menu" px="8px">
      {Array(isMobileView ? 10 : 3)
        ?.fill(null)
        .map((c, idx) => (
          <Skeleton
            sx={{
              borderRadius: "4px",
              marginTop: "10px",
            }}
            key={idx}
            width="100%"
            variant="rounded"
            height="40px"
          />
        ))}
    </Box>
  );
};
