import { TruncateText } from "@common/Text";
import { Grid, Stack, StackProps, styled } from "@mui/material";

type Item = {
  key: string;
  value: string;
};

interface InfoDisplayProps extends StackProps {
  items: Item[];
}

function InfoDisplay({ items, ...rest }: InfoDisplayProps) {
  return (
    <Stack {...rest} gap="4px" mb={4}>
      {items.map(({ key, value }: any, index: number) => (
        <Grid
          container
          key={index}
          sx={{
            backgroundColor: "inherit",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          <Grid item md={12}>
            <StyledText lineClamp={1}>{key}</StyledText>
          </Grid>
          <Grid item md={12}>
            <StyledText isValue lineClamp={1}>
              {value}
            </StyledText>
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
}

export default InfoDisplay;

const StyledText = styled(TruncateText, {
  shouldForwardProp: (prop) => prop !== "isValue",
})<{ isValue?: boolean }>(({ theme, isValue }) => ({
  color: isValue ? theme.palette.neutral["600"] : theme.palette.neutral["400"],
  fontWeight: isValue ? 350 : 300,
  lineHeight: "16.8px",

  ...(isValue && {
    minHeight: "16.8px",
  }),
}));
