import * as React from "react";
import { palette } from "@palette";
import { useNavigate } from "react-router-dom";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { showMessage } from "@common/Toast/ShowToast";
// localization
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import Popup from "@common/Popup";
import { Text } from "@common/Text";

type PaymentFormSuccessModalProps = {
  name: string;
};

const PaymentFormSuccessModal = NiceModal.create(
  ({ name }: PaymentFormSuccessModalProps) => {
    const modal = useModal();
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const { t } = useTranslation(namespaces.pages.paymentFormMinibuilder);

    const handleClose = () => {
      modal.hide();
    };

    const handleDone = () => {
      handleClose();
      showMessage(
        "Success",
        <>
          Your new Payment Form{" "}
          <span style={{ fontWeight: "600" }}>“{name}”</span> has been
          successfully created and it’s ready to accept payments
        </>,
        isDesktop,
        "Payment Form Created",
      );
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={
          (
            <Text fontSize={20}>{t("paymentForm_successfully_created")}</Text>
          ) as any
        }
        onSubmit={handleDone}
        actionLabel={t("done")}
      >
        <Markup content={t("paymentForm_create_success_description")} />
      </Popup>
    );
  },
);

export default PaymentFormSuccessModal;
