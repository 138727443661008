import useImagePreviewer from "../hooks/useImagePreviewer";
import usePdfPreviewer from "../hooks/usePdfPreviewer";
import { IPreviewerProps } from "../types";
import FilePreviewLayout from "../layout/FilePreviewLayout";
import PreviewHeader from "../layout/PreviewHeader";
import { useEffect, useRef, useState } from "react";

const timeoutDuration = 2000;

export const useTrackMouseMovement = () => {
  const [showControls, toogleShowControls] = useState(true);

  const action = () => {
    toogleShowControls(false);
  };

  const mouseTimeout: any = useRef(null);

  const resetMouseTimeout = () => {
    clearTimeout(mouseTimeout.current);
    toogleShowControls(true);
    mouseTimeout.current = setTimeout(action, timeoutDuration);
  };

  useEffect(() => {
    document.addEventListener("mousemove", resetMouseTimeout);
    return () => {
      document.removeEventListener("mousemove", resetMouseTimeout);
    };
  }, []);

  return {
    showControls,
    init: () => {
      action();
      resetMouseTimeout();
    },
  };
};

const Previewer = ({
  navigation,
  toolbarActions,
  count,
  index,
  headerActions,
  disableBackdropClick,
  ...rest
}: IPreviewerProps) => {
  const { file, onClose } = rest;
  const { showControls, init } = useTrackMouseMovement();

  const {
    Previewer,
    headerActions: pdfHeaderActions,
    toolbar,
    isSearchPanelFocused,
    onZoom: onPdfZoom,
  } = usePdfPreviewer({
    showControls,
    ...rest,
  });
  const {
    Previewer: ImagePreviewer,
    toolbar: imageToolbar,
    slots: imageSlots,
    headerActions: imageHeaderActions,
    onZoom: onImageZoom,
  } = useImagePreviewer(rest);

  const isPdf = file?.type === "pdf";

  const mappedHeaderActions = headerActions?.map(
    (action) => !action.hidden && action.content,
  );

  useEffect(() => {
    init();
  }, []);

  return (
    <FilePreviewLayout
      onBackdropClick={disableBackdropClick ? undefined : () => onClose?.()}
      showControls={showControls || !!isSearchPanelFocused}
      onZoom={isPdf ? onPdfZoom : onImageZoom}
      header={
        <PreviewHeader
          onClose={onClose}
          title={file.name}
          actions={
            <>
              {isPdf ? pdfHeaderActions : imageHeaderActions}{" "}
              {mappedHeaderActions}
            </>
          }
        />
      }
      previewer={isPdf ? Previewer : ImagePreviewer}
      toolbar={
        isPdf
          ? { ...toolbar, secondary: toolbarActions }
          : { ...imageToolbar, secondary: toolbarActions }
      }
      navigation={navigation}
      slots={isPdf ? undefined : imageSlots}
      count={count}
      index={index}
    />
  );
};

export default Previewer;
