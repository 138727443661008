export const ROOT_NODE_ID = 1;

export const rootNodeStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  height: "100%",
  overflowY: "hidden",
  padding: "4px",
  width: "100%",
  position: "relative",
};
