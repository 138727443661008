import { Box, BoxProps, styled } from "@mui/material";

export interface BackgroundImageContainerProps extends BoxProps {
  imageUri: string;
}

const BackgroundImageContainer = styled<
  React.FC<BackgroundImageContainerProps>
>(Box, {
  shouldForwardProp: (prop) => prop !== "imageUri",
})(({ imageUri }) => ({
  background: `url("${imageUri}")`,
}));

export default BackgroundImageContainer;
