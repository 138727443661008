import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { Box, Stack, styled } from "@mui/material";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import React from "react";
import { RadioGroup } from "@mui/material";
import { Radio } from "@common/Radio";
import { FormControlLabel } from "@common/FormControlLabel";
import { Text } from "@common/Text";
import { palette } from "@theme/colors";
import * as Yup from "yup";

import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateUnderwriting } from "../hooks/useUnderwritingUpdate";
import { useQueryClient } from "react-query";
import { showMessage } from "@common/Toast";
import { MERCHANT_INITIAL_APPROVAL_MODAL } from "modals/modal_names";
import { QKEY_LIST_ACQUIRER_MERCHANTS, QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";

type InitialApprovalProps = {
  merchantId: number;
  defaultStatus?: string;
};

export type ApprovalTypeInputs = {
  status: string;
};

const schema = Yup.object().shape({
  status: Yup.string(),
});

const MerchantInitialApprovalModal = NiceModal.create(
  ({ merchantId, defaultStatus = "pre-approval" }: InitialApprovalProps) => {
    const modal = useModal();

    const { updateUnderwriting } = useUpdateUnderwriting(merchantId);
    const queryClient = useQueryClient();

    const methods = useForm<ApprovalTypeInputs>({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: {
        status: defaultStatus,
      },
      resolver: yupResolver(schema),
    });

    const onConfirm: SubmitHandler<ApprovalTypeInputs> = async (data) => {
      updateUnderwriting.mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries("get-merchant-msp-status");
          queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
          queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
          queryClient.refetchQueries(["get-merchant-preview", merchantId]);
          NiceModal.remove(MERCHANT_INITIAL_APPROVAL_MODAL);
        },
        onError: () => {
          queryClient.refetchQueries("get-merchant-preview");
          showMessage(
            "Error",
            "Merchant account approval failed. Ensure all required information is accurate and approved",
          );
        },
      });
    };

    const { isMobileView } = useCustomTheme();

    const handleCancel = () => modal.remove();

    const isValid = methods.formState.isValid;

    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 550,
        }}
      >
        <FormProvider {...methods}>
          <Stack
            component="form"
            id="approve-account-form"
            data-testid="approve-account-form"
            gap="24px"
            onSubmit={methods.handleSubmit(onConfirm)}
          >
            <Box padding="24px 24px 0 24px">
              <ModalTitle
                textStyles={{ title: { fontSize: "18px" } }}
                title="Approve Account"
                onClose={handleCancel}
              />
            </Box>
            <FadeUpWrapper delay={20}>
              <>
                <Stack px="24px" sx={{ overflowY: "scroll" }} gap="16px">
                  <Stack gap="12px">
                    <Controller
                      control={methods.control}
                      name="status"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Box>
                          <RadioGroup sx={{ gap: 2 }}>
                            {approvalTypes.map((option) => (
                              <StyledBox
                                key={option.title}
                                selected={option.value === value}
                              >
                                <FormControlLabel
                                  control={<Radio />}
                                  label={
                                    <Stack gap="4px">
                                      <Text color={palette.neutral[100]}>
                                        {option.title}
                                      </Text>
                                      <Text color={palette.gray[300]}>
                                        {option.subtitle}
                                      </Text>
                                    </Stack>
                                  }
                                  value={option.value}
                                  onChange={onChange}
                                  checked={value === option.value}
                                />
                              </StyledBox>
                            ))}
                          </RadioGroup>
                          {error && (
                            <Text
                              pt="12px"
                              pl="12px"
                              color={palette.neutral[80]}
                            >
                              {error.message}
                            </Text>
                          )}
                        </Box>
                      )}
                    />
                  </Stack>
                </Stack>
                <ModalActions
                  containerProps={{ sx: { m: "16px", gap: "16px" } }}
                  secondaryAction={{
                    label: "Cancel",
                    onClick: handleCancel,
                  }}
                  primaryAction={{
                    label: "Confirm",
                    disabled: !isValid,
                    type: "submit",
                    component: "button",
                    dataTestId: "confirm-account-appoval-button",
                    form: "approve-account-form",
                    sx: { backgroundColor: palette.filled.success },
                  }}
                />
              </>
            </FadeUpWrapper>
          </Stack>
        </FormProvider>
      </ModalFactory>
    );
  },
);

const StyledBox = styled(Box)(({ selected }: { selected: boolean }) => ({
  padding: "12px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: selected ? palette.accent[3] : "transparent",
}));

export default MerchantInitialApprovalModal;

export const approvalTypes = [
  {
    value: "auto-approval",
    title: "Auto-approval",
    subtitle:
      "Recommended for merchants categorized as 'Normal' risk. This allows a quicker and more efficient approval process for low-risk merchants.",
  },
  {
    value: "pre-approval",
    title: "Pre-approval",
    subtitle:
      "Recommended for merchants categorized as 'Restricted' or 'High' risk. This allows additional checks and balances before the final approval is granted.",
  },
];
