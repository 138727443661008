import { Text } from "@common/Text";
import { Box, Grid, Stack } from "@mui/material";
import { palette } from "@palette";
import { LabelValue, LabelValueArray, TPEPOwner } from "../types";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { styled } from "@mui/styles";
import CollapsableComponent from "@components/Merchants/CreateMerchantPanel/modals/CollapsableComponents";

function CustomCollapsible({
  data,
  selectedOwner,
}: {
  data?: LabelValueArray[];
  selectedOwner: TPEPOwner;
}) {
  const name = data && data?.find((item) => item?.label === "name")?.value;
  const userName = name && name !== "None" ? name : selectedOwner?.name;

  return (
    <Box mb="8px">
      <CollapsableComponent
        title={
          <Text color="#575353" fontWeight="regular" fontSize="14px">
            {userName}
          </Text>
        }
        sx={{
          "& .MuiAccordion-root": {
            borderRadius: "4px",
          },
          "& .MuiAccordionSummary-content": {
            padding: "0px",
          },

          "& .MuiStack-root": {
            margin: "0px",
          },
        }}
      >
        <Box borderRadius="8px" width="100%" bgcolor={palette.liftedWhite[100]}>
          {data?.map((item: any, idx: any) => (
            <FadeUpWrapper key={idx + item?.label} delay={100 + idx * 50}>
              <CustomText {...item} />
            </FadeUpWrapper>
          ))}
        </Box>
      </CollapsableComponent>
    </Box>
  );
}

export default CustomCollapsible;

const CustomText = ({ label, value }: LabelValue) => {
  return (
    <Grid px="12px" my="9px" container>
      <Grid xs={6} item>
        <Text fontWeight="book" fontSize="14px" color={palette.neutral[80]}>
          {label}:
        </Text>
      </Grid>
      <Grid xs={6} item>
        <Text
          fontWeight="book"
          style={{ overflowWrap: "break-word" }}
          fontSize="14px"
          color={palette.neutral[90]}
        >
          {value}
        </Text>
      </Grid>
    </Grid>
  );
};

const CustomStack = styled(Stack)(() => ({
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
}));
