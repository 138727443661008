import React, { FocusEvent, useState } from "react";
import ExpandedSearchBar from "@common/SearchBar/SearchBar";
import { IconButton, SxProps } from "@mui/material";
import { SearchIcon } from "@assets/rebrandIcons";
import { palette } from "@palette";
import { makeStyles } from "@mui/styles";
import { useAppDispatch } from "@redux/hooks";
import { updateSearchQuery } from "@redux/slices/fundraisers";
import { setSearchQueryByKey } from "@redux/slices/search";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ErrorCatcher from "@common/Error/ErrorCatcher";

type VoidFunc = () => void;

interface SearchBarProps {
  onChange?: (value: string) => void;
  queryKey?: string;
  transparent?: boolean;
  onMouseEnter?: VoidFunc | boolean;
  onMouseLeave?: VoidFunc | boolean;
  isExpandedByDefault?: boolean;
  formSx?: SxProps;
  initialValue?: string;
}

export const SearchBar = ({
  onChange,
  queryKey,
  transparent,
  isExpandedByDefault,
  formSx,
  initialValue,
}: SearchBarProps) => {
  const dispatch = useAppDispatch();
  const { isDesktopView } = useCustomTheme();

  const [expanded, setExpanded] = useState(false);
  const [queryString, setQueryString] = useState<string>("");

  const onBlur = (event: FocusEvent) => {
    if (!queryString) {
      setExpanded(false);
    }
  };

  const searchQueryDispatcher = (value: string) => {
    if (queryKey) {
      dispatch(
        setSearchQueryByKey({
          queryKey,
          params: {
            value,
          },
        }),
      );
    } else {
      // else we store the search query in the default state
      // (in the future we need to change the default state.
      // now it's in the fundraiser state should be in more generic state)
      dispatch(updateSearchQuery(value));
    }
  };

  React.useEffect(() => {
    return () => {
      setQueryString("");
      searchQueryDispatcher("");
    };
  }, []);

  if (!isDesktopView) {
    //latest requirement: it shoudl be opened by default on mobile
    return (
      <ExpandedSearchBar
        handleChange={onChange}
        queryKey={queryKey}
        transparent={transparent}
        initialValue={initialValue}
        formSx={{
          width: "100%",
          ...formSx,
        }}
      />
    );
  }

  return isExpandedByDefault || expanded ? (
    <ErrorCatcher errorID="ExpandedSearchBar">
      <ExpandedSearchBar
        IOCsetQueryString={setQueryString}
        IOCqueryString={queryString}
        onBlur={onBlur}
        handleChange={onChange}
        queryKey={queryKey}
        transparent={transparent}
        initialValue={initialValue}
        isExpandedByDefault={isExpandedByDefault}
        formSx={{
          width: isExpandedByDefault ? "100%" : "fit-content",
          ...formSx,
          height: "32px",
        }}
        collapse={() => {
          setExpanded(false);
        }}
        autoFocus
      />
    </ErrorCatcher>
  ) : (
    <ErrorCatcher errorID="DefaultSearchbar">
      <DefaultSearchbar onClick={() => setExpanded(true)} />
    </ErrorCatcher>
  );
};

export function DefaultSearchbar({
  onClick,
  onMouseEnter = () => {
    return;
  },
  disabled = false,
}: any) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.iconBtn}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
      disableRipple
      data-testid="default-searchbar-icon"
    >
      <SearchIcon
        width={24}
        height={24}
        stroke={disabled ? palette.liftedWhite[300] : palette.gray[300]}
      />
    </IconButton>
  );
}

const useStyles = makeStyles({
  iconBtn: {
    background: `${palette.background.dimmedWhite} !important`,
    boxShadow: "none !important",
    padding: "4px 12px",
    borderRadius: "90px",
    border: `1px solid ${palette.neutral[10]}`,
    userSelect: "none",
    height: "32px",

    "&:disabled": {
      background: palette.liftedWhite[100],
    },
  },
});
