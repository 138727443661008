import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import useDrawerReducer from "@hooks/Reducers/useDrawerReducer";
import { useGetCurrentMerchantId } from "@hooks/common";
import { CloseIcon } from "@assets/rebrandIcons";
import { StyledIconButton } from "@common/IconButton";
import { useMerchantIdentityNotification } from "../hooks/useMerchantIdentityNotification";
import FadeInWrapper from "@components/animation/FadeInWrapper";
import { Pils } from "./components/Pils";
import { useGetProfileStatus } from "@pages/Signup/useGetProfileStatus";
import { useDispatch } from "react-redux";
import { setOnboardingScreen } from "@redux/slices/app";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HomepageCardStatus,
  STATUS_ENUM,
} from "@components/ProfilePage/ProfileSetupHome/HomepageCard";
import { useRef, useState } from "react";
import { WithFetching } from "@containers/WithFetching";
import useOverflow from "../hooks/useOverflow";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { checkPortals } from "@utils/routing";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { palette } from "@palette";

export function MerchantStatus({
  listRef,
}: {
  listRef: React.RefObject<HTMLUListElement>;
}) {
  const { drawerOpen: desktopOpen } = useDrawerReducer();
  const { isLargeView } = useCustomTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const { handleCloseNotification } = useMerchantIdentityNotification();
  const { merchantId } = useGetCurrentMerchantId();
  const { isMerchantPortal } = checkPortals();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: merchant, isLoading } = useGetMerchantById({
    merchantId,
    enabled: isMerchantPortal && !!merchantId,
  });

  const {
    pahStatus,
    businessDetailsStatus,
    bankAccountStatus,
    agreementStatus,
    allLoaded,
  } = useGetProfileStatus(merchant);

  const { overflowed } = useOverflow(listRef);
  const setShowNextPage = (screen: "" | "profile" | "campaign") => {
    dispatch(setOnboardingScreen({ screen }));
  };

  const handleCardClick = () => {
    if (location.pathname !== "/merchant/welcome") {
      navigate("/merchant/welcome#onboarding");
    } else {
      setShowNextPage("profile");
    }
  };

  const onCloseVerifiedCard = (e: any) => {
    e.stopPropagation();
    handleCloseNotification(() => {
      manualyClosedCard.current = true;
    });
  };
  const statuses = [
    pahStatus,
    businessDetailsStatus,
    bankAccountStatus,
    agreementStatus,
  ];

  let titleStatus: HomepageCardStatus = STATUS_ENUM.INCOMPLETE;
  const areAllApproved = statuses.every(
    (status) => status === STATUS_ENUM.APPROVED,
  );

  if (areAllApproved) {
    titleStatus = STATUS_ENUM.UNDER_VERIFICATION;
  }

  if (areAllApproved && merchant.statusName === "approved") {
    titleStatus = STATUS_ENUM.APPROVED;
  }

  const progressStatus = statuses.filter(
    (status) => status === STATUS_ENUM.APPROVED,
  ).length;

  const card = cardVariants[titleStatus] || {};

  const manualyClosedCard = useRef(false);

  if (merchant?.profileCompletedReadAt !== null || isLoading) return null;
  if (manualyClosedCard?.current) return null;

  return (
    <Container
      clickable={true}
      isDrawerOpen={desktopOpen && isLargeView}
      onClick={handleCardClick}
      sx={{
        transition: "box-shadow 0.3s ease-in-out",
        boxShadow: overflowed
          ? "0px -4px 10px 0px rgba(0, 0, 0, 0.10)"
          : "0px 0px 0px 0px rgba(0, 0, 0, 0)",
      }}
    >
      {desktopOpen && isLargeView ? (
        <Box width="100%">
          <FadeInWrapper delay={150}>
            {titleStatus === STATUS_ENUM.APPROVED && (
              <StyledIconButton
                onClick={onCloseVerifiedCard}
                sx={{
                  position: "absolute",
                  display: "none",
                  top: "0px",
                  right: "0px",
                }}
                className="GC-close-btn"
              >
                <CloseIcon color="#131212" width={22} height={22} />
              </StyledIconButton>
            )}
            <Stack direction="row" justifyContent="space-between">
              <LabelTag status={titleStatus} mb={2}>
                {card.title}
              </LabelTag>
              {(titleStatus === STATUS_ENUM.INCOMPLETE ||
                titleStatus === STATUS_ENUM.UNDER_VERIFICATION) && (
                <Box
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setIsExpanded((prev) => !prev);
                  }}
                  data-testid="merchant-status-expand-button"
                >
                  {isExpanded ? (
                    <CaretUp size={20} color={palette.black[100]} />
                  ) : (
                    <CaretDown size={20} color={palette.black[100]} />
                  )}
                </Box>
              )}
            </Stack>
            {(isExpanded || titleStatus === STATUS_ENUM.APPROVED) && (
              <Text color="#8F8F8F" width="100%">
                {card.description}
              </Text>
            )}

            {titleStatus != STATUS_ENUM.APPROVED && (
              <Stack
                direction="row"
                alignItems="baseline"
                spacing={1}
                width="100%"
              >
                <Pils
                  statuses={{
                    pah: pahStatus === STATUS_ENUM.APPROVED,
                    business_details:
                      businessDetailsStatus === STATUS_ENUM.APPROVED,
                    bank_account: bankAccountStatus === STATUS_ENUM.APPROVED,
                    merchant_agreement:
                      agreementStatus === STATUS_ENUM.APPROVED,
                  }}
                  paddingTop={0}
                />
                <Progress progress={progressStatus} isExpanded={isExpanded} />
              </Stack>
            )}
          </FadeInWrapper>
        </Box>
      ) : (
        <WithFetching
          isFetching={!allLoaded}
          isError={false}
          customSpinner={<CircularProgress size={14} />}
        >
          <Stack spacing={2}>
            <MobileTag status={titleStatus} />
            {titleStatus != STATUS_ENUM.APPROVED && (
              <Progress progress={progressStatus} />
            )}
          </Stack>
        </WithFetching>
      )}
    </Container>
  );
}

function Progress({
  progress,
  isExpanded,
}: {
  progress: number;
  isExpanded?: boolean;
}) {
  return (
    <Text
      sx={{ paddingTop: isExpanded ? "16px" : "0px", letterSpacing: "0.84px" }}
      fontWeight="medium"
    >
      {progress}
      /4
    </Text>
  );
}

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isDrawerOpen" && prop !== "clickable",
})<{ isDrawerOpen: boolean; clickable?: boolean }>(
  ({ isDrawerOpen, clickable = true }) => ({
    position: "relative",
    border: "0.5px solid white",
    borderRadius: "12px",
    padding: "16px",
    margin: "auto",
    maxHeight: "200px",
    marginBottom: isDrawerOpen ? "16px" : "30px",
    width: isDrawerOpen ? "90%" : "70%",
    background: "linear-gradient(180deg, #F8F8F6 0%, #FFF 100%)",
    cursor: clickable ? "pointer" : "default",
    transition: "border 100ms ease",
    alignItems: "center",

    ...(clickable && {
      "&:hover": {
        background: "#F8F8F6",
        border: "0.5px solid #D1D1D0",
        ".GC-close-btn": {
          display: "block",
          cursor: "pointer",
        },
      },
    }),
  }),
);

const LabelTag = styled(Text, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: HomepageCardStatus }>(({ status }) => ({
  color: cardVariants[status].color,
  backgroundColor: cardVariants[status]?.backgroundColor,
  borderRadius: "16px",
  width: "fit-content",
  padding: "2px 16px",
}));

const MobileTag = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: HomepageCardStatus }>(({ status }) => ({
  borderRadius: "50%",
  backgroundColor: cardVariants[status].color,
  width: "22px",
  height: "22px",
}));

const cardVariants = {
  [STATUS_ENUM.INCOMPLETE]: {
    color: "#FF8124",
    backgroundColor: "#FFF2E9",
    title: "Account incomplete",
    description: "Provide required information to complete your profile.",
  },
  [STATUS_ENUM.UNDER_VERIFICATION]: {
    color: "#003182",
    backgroundColor: "#E6EAF2",
    title: "Under verification",
    description:
      "Your account is ready for approval and will soon receive a response",
  },
  [STATUS_ENUM.APPROVED]: {
    color: "#13A75A",
    backgroundColor: "#E6F3EC",
    title: "Account approved",
    description: "Your account is complete and approved!",
  },
};
