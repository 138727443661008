import ConversationsButton from "features/Minibuilders/Conversations/ConversationsButton";
import ChangelogButton from "./ChangelogButton";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetConversationsState,
  selectConversation,
  setModalOpenConversation,
} from "@redux/slices/conversations";
import { useChangelogModal } from "./hooks/useChanglelogModal";
import { checkPortals } from "@utils/routing";
import { Stack, styled } from "@mui/material";

interface Props {
  isLoadingConversation: boolean;
  isLoadingChangelog?: boolean;
  totalUnread: number;
  shouldShowChangelog?: boolean;
}

export default function DragModalHeader({
  isLoadingChangelog,
  isLoadingConversation,
  totalUnread,
  shouldShowChangelog,
}: Props) {
  const { isMobileView } = useCustomTheme();
  const { isAcquirerPortal } = checkPortals();
  const {
    modal: { isOpen: isConverstionsModalOpen },
  } = useAppSelector(selectConversation);
  const { isOpen: isOpenChangelog, handleOpenChangelogModal } =
    useChangelogModal();
  const dispatch = useAppDispatch();

  const handleOpenConversationsModal = (open?: boolean) => {
    dispatch(setModalOpenConversation(!!open));
    if (!open) {
      dispatch(resetConversationsState());
    } else {
      //we use timeout to make the transition smoother
      setTimeout(() => handleOpenChangelogModal(false), 300);
    }
  };
  //TODO: add logic to hide changelog button based on permission

  if (!isMobileView) return null;
  return (
    <Container direction="row" spacing="12px">
      {isAcquirerPortal && (
        <ConversationsButton
          isLoading={isLoadingConversation}
          isOpen={isConverstionsModalOpen}
          totalUnread={totalUnread}
          handleOpenConversationsModal={handleOpenConversationsModal}
          isOpenChangeLog={isOpenChangelog}
        />
      )}
      {shouldShowChangelog && (
        <ChangelogButton
          isLoading={Boolean(isLoadingChangelog)}
          handleOpenChangelogModal={(open) => {
            handleOpenChangelogModal(open);
            if (open)
              //we use timeout to make the transition smoother
              setTimeout(() => handleOpenConversationsModal(false), 300);
          }}
          isOpen={isOpenChangelog}
          isOpenConversations={isConverstionsModalOpen}
        />
      )}
    </Container>
  );
}

const Container = styled(Stack)(() => ({
  gap: "4px",
  position: "fixed",
  top: "calc(96px - 56px - 2px)",
  left: "10px",
  width: "200px",
}));
