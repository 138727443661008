import { Menu } from "@mui/material";
import NiceModal from "@ebay/nice-modal-react";
import {
  MERCHANT_PREVIEW_PANEL_MODAL,
  MERCHANT_VIEW_CUSTOMER,
  MERCHANT_VIEW_TRANSACTION_MODAL,
  PROCESSING_CANCEL_TRANSACTION_MODAL,
  PROCESSING_REFUND_TRANSACTION_MODAL,
  PROCESSING_SEND_RECEIPT_MODAL,
} from "modals/modal_names";
import { TransactionData } from "@components/ManageMoney/TransactionTable/data";
import { getSourceAccount } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithHideAnchorItem } from "@pages/AcquirerPortal/Processing/ProcessingMenu";
import { checkPortals } from "@utils/routing";

export const refundHandler = ({ sourceAccount, ids }: any) => {
  NiceModal.show(PROCESSING_REFUND_TRANSACTION_MODAL, { sourceAccount, ids });
};

export const sendReceiptHandler = (row?: Record<string, any>) => {
  NiceModal.show(PROCESSING_SEND_RECEIPT_MODAL, {
    transactionID: row?.id,
    emails: [row?.sourceAccount.user.email],
    sourceAccount: getSourceAccount(row as any),
    customerID: row?.sourceAccount.id,
  });
};

export const editMerchantHandler = (id?: string) => {
  NiceModal.show(MERCHANT_PREVIEW_PANEL_MODAL, { id });
};

const cancelHandler = (data: any) => {
  NiceModal.show(PROCESSING_CANCEL_TRANSACTION_MODAL, data);
};

export const viewMerchantTransactionsHandler = (row?: TransactionData) => {
  NiceModal.show(MERCHANT_VIEW_TRANSACTION_MODAL, { data: row });
};

const viewCustomerHandler = (customerId?: any, merchantId?: any) => {
  if (customerId)
    NiceModal.show(MERCHANT_VIEW_CUSTOMER, {
      id: customerId,
      merchantId,
    });
};

export function DMenu({ anchorEl, open, setAnchorEl, selectedRow }: any) {
  const { isMerchantPortal } = checkPortals();

  const isVoided = selectedRow?.displayStatus === "Voided";
  const isChargeback = selectedRow?.status === "Chargeback";
  const isRefund = selectedRow?.status === "Refunded";
  const isTransfer = selectedRow?.displayType === "Money Transfer";
  const isPendingTransfer =
    isTransfer && selectedRow?.displayStatus === "Requested";

  const isHideRefund =
    selectedRow?.hideRefund ||
    isVoided ||
    selectedRow.isHideRefund ||
    selectedRow?.displayStatus === "Sent" ||
    selectedRow?.displayStatus === "Refunded" ||
    isTransfer ||
    isChargeback;
  const isHideSendReceipt =
    isTransfer || isChargeback || isVoided || selectedRow?.hideSendReceipt;

  const isRefundAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
    operation: OPERATIONS.CREATE,
  });

  const isCancelTransferAllowed = useAccessControl({
    resource: RESOURCE_BASE.BANK_TRANSFER,
    operation: isMerchantPortal ? OPERATIONS.UPDATE : OPERATIONS.CANCEL,
    withPortal: true,
  });

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: "inherit",
        },
      }}
    >
      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={refundHandler}
        title="Refund"
        hidden={isHideRefund}
        disabled={!isRefundAllowed}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        tooltipProps={{
          show: !isRefundAllowed,
          message: FEATURE_DENY_MESSAGE,
        }}
        onClickPayload={{
          sourceAccount: selectedRow ? getSourceAccount(selectedRow) : {},
          ids: {
            transactionIDs: [selectedRow?.id],
          },
        }}
      />

      <WithHideAnchorItem
        hidden={isHideSendReceipt}
        selectedRow={selectedRow}
        onClick={() => sendReceiptHandler(selectedRow)}
        title="Send Receipt"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />

      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={editMerchantHandler}
        title="View Merchant"
        hidden={!isTransfer}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        closeCurrentModal
      />
      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={() =>
          viewCustomerHandler(
            selectedRow?.customer?.id,
            !isChargeback && !isRefund
              ? selectedRow?.destinationAccount?.id
              : selectedRow?.sourceAccount?.id,
          )
        }
        hidden={isTransfer}
        title="View customer"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        closeCurrentModal
      />
      <WithHideAnchorItem
        selectedRow={selectedRow}
        onClick={() =>
          cancelHandler({
            transactionId: selectedRow?.id,
            cancel: true,
            sourceAccount: getSourceAccount(selectedRow),
            customerID: selectedRow?.destinationAccount.id,
          })
        }
        title="Cancel Transfer"
        disabled={!isCancelTransferAllowed}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        hidden={!isPendingTransfer}
        tooltipProps={{
          show: !isCancelTransferAllowed,
          message: FEATURE_DENY_MESSAGE,
        }}
      />
    </Menu>
  );
}
