import React, { MutableRefObject, useEffect, useRef } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { TParsedNotification } from "../types";
import { NotificationsItemSkeleton, getMaxHeight } from "./Body.atoms";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import NotificationItem from "./NotificationItem";
import { TScrollToTop } from "../types";
import { Box } from "@mui/material";

interface Props {
  data: TParsedNotification[];
  renderGroupLabels: boolean;
  closeModal: VoidFunction;
  isFetchingNextPage: boolean;
  onEndReached: (props: any) => void;
  scrollToTop: MutableRefObject<TScrollToTop | null>;
}

const NotificationsVirtualList = ({
  data,
  renderGroupLabels,
  closeModal,
  isFetchingNextPage,
  onEndReached,
  scrollToTop,
}: Props) => {
  const ITEM_HIGHT = 80; //aprox hight of an item form the notification list
  const virtuoso = useRef<VirtuosoHandle | null>(null);
  useEffect(() => {
    if (virtuoso.current) {
      scrollToTop.current = (index = 0, align = "center") => {
        if (virtuoso.current) {
          virtuoso.current.scrollToIndex({
            index,
            align,
            behavior: "auto",
          });
        }
      };
    }
  }, []);
  const allItemHight = data.length * ITEM_HIGHT;
  const calculatedHight = () => {
    if (allItemHight < 412) return 412;
    else if (allItemHight > getMaxHeight()) return getMaxHeight();
    else return allItemHight;
  };

  return (
    <Virtuoso
      ref={virtuoso}
      style={{
        height: `${calculatedHight()}px`,
      }}
      data={data}
      totalCount={data.length}
      itemContent={(index, item) => {
        const isLast = index === data.length - 1;
        return (
          <NotificationItem
            {...item}
            renderLabel={renderGroupLabels}
            closeModal={closeModal}
            isLast={isLast}
          />
        );
      }}
      endReached={(props) => onEndReached(props)}
      components={{
        List: VirtualList,
        Footer: () =>
          isFetchingNextPage ? <NotificationsItemSkeleton /> : null,
      }}
    />
  );
};

export default NotificationsVirtualList;

const VirtualList = React.forwardRef<any>(
  ({ style, children }: any, listRef: any) => {
    return (
      <ErrorCatcher errorID="UnfoldListItems">
        <Box style={style} sx={{ overflow: "hidden" }} ref={listRef}>
          {children}
        </Box>
      </ErrorCatcher>
    );
  },
);

VirtualList.displayName = "VirtualList";
