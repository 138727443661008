import { CloseIcon } from "@assets/icons";
import DeleteIcon from "@assets/icons/RebrandedIcons/DeleteIcon";
import { Button } from "@common/Button";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import React from "react";

interface IMobileEditor {
  children: React.ReactNode;
  onCancel: VoidFunction;
  onSave: VoidFunction;
  onDelete: VoidFunction;
  canDelete: boolean;
}

const MobileEditor = ({
  children,
  onCancel,
  onSave,
  onDelete,
  canDelete,
}: IMobileEditor) => {
  const clickHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
    callback: VoidFunction,
  ) => {
    event.stopPropagation();
    callback();
  };

  const touchEvent = (event: React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Container
      onTouchStart={touchEvent}
      onTouchMove={touchEvent}
      onTouchEnd={touchEvent}
    >
      <Header>
        <CustomButton
          onClick={(e) => clickHandler(e, onCancel)}
          data-testid="close-button"
        >
          <CloseIcon width={32} height={32} stroke={palette.neutral.white} />
        </CustomButton>
        <Stack direction="row" gap="16px" alignItems="center">
          <HiddenComponent hidden={!canDelete}>
            <CustomButton
              onClick={(e) => clickHandler(e, onDelete)}
              data-testid="delete-button"
            >
              <DeleteIcon width="20" height="20" fill={palette.neutral.white} />
            </CustomButton>
          </HiddenComponent>
          <CustomButton
            withPadding
            onClick={(e) => clickHandler(e, onSave)}
            sx={{
              color: palette.accent[3],
            }}
          >
            Done
          </CustomButton>
        </Stack>
      </Header>
      <Stack
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        flexGrow={1}
      >
        {children}
      </Stack>
    </Container>
  );
};

const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "withPadding",
})<{ withPadding?: boolean }>(({ withPadding = false }) => ({
  height: "max-content",
  background: "inherit",
  padding: withPadding ? "4px 8px" : 0,
  borderRadius: "8px",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "16px",
}));

const Container = styled(Stack)(() => ({
  position: "fixed",
  overflow: "hidden",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "#353535",
  backdropFilter: "blur(10px)",
}));

const Header = styled(Stack)(() => ({
  padding: "8px 16px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  background: "rgba(41, 39, 39, 0.80)",
  backdropFilter: "blur(2px)",
}));

export default MobileEditor;
