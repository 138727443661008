import { DatePicker } from "@common/DatePickers";
import CustomDatePicker from "@common/DatePickers/CustomDatePicker";
import { RHFInput } from "@common/Input";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import { Grid, Stack, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import TitleComponent from "../TitleComponent";
import { memo } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

function SweepStackAbout({ title }: { title: string }) {
  const { watch, control } = useFormContext();
  const values = watch();

  return (
    <>
      <FadeUpWrapper delay={100}>
        <TitleComponent title={title} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={100}>
        <Stack direction="column" gap={3} flexGrow={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <RHFInput
                name="About.title"
                label="Title"
                placeholder="Enter a suitable title"
                inputProps={{ maxLength: 300 }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomDatePicker name="About.endDate" />
            </Grid>
            <Grid item xs={12}>
              <RHFInput
                name="About.description"
                label="Description (recommended)"
                placeholder="Tell use more"
                multiline
                rows={8}
                fullWidth
                inputProps={{
                  maxLength: `${MAX_TEXT_LENGTH}`,
                }}
                helperText={`${
                  MAX_TEXT_LENGTH - values?.About?.description?.length ?? 0
                }`}
              />
            </Grid>
          </Grid>
        </Stack>
      </FadeUpWrapper>
    </>
  );
}

export default memo(SweepStackAbout);
