import {
  QKEY_LIST_TEAM_MEMBERS,
  QKEY_LIST_TEAM_MEMBER_PERMISSIONS,
} from "@constants/queryKeys";
import { useModal } from "@ebay/nice-modal-react";
import { useQueryClient } from "react-query";

export const useTeamMember = () => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const handleClose = () => {
    [
      QKEY_LIST_TEAM_MEMBERS,
      "add-permission-modal-list",
      QKEY_LIST_TEAM_MEMBER_PERMISSIONS,
    ].forEach((q) => queryClient.invalidateQueries(q));
    modal.remove();
  };

  return { modal, handleClose };
};
