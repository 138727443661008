import { useEffect, useRef } from "react";
import { Chart as ChartJS } from "chart.js";
import {
  ChoroplethController,
  GeoFeature,
  ProjectionScale,
  ColorScale,
} from "chartjs-chart-geo";
import { Box, SxProps } from "@mui/material";
import { TGeoMapStats } from "@components/Analytics/SidePanels/CustomersSidePanel/data.types";
import useMapTopography from "./utils/useMapTopography";
import options from "./utils/options";
import MapsLoading from "@assets/rebrandIcons/MapsLoading";

ChartJS.register(ChoroplethController, GeoFeature, ProjectionScale, ColorScale);

const GeoMapChart = ({
  data,
  height = "424px",
  containerSx,
}: {
  data?: TGeoMapStats[];
  height?: string;
  containerSx?: SxProps;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { data: topography } = useMapTopography();

  useEffect(() => {
    let chart: ChartJS | null = null;
    const chartSetup = async () => {
      if (topography?.nation && topography?.states && canvasRef.current) {
        chart = new ChartJS(canvasRef.current, {
          type: "choropleth",
          data: {
            labels: topography.states.map((d: any) => d.properties.name),
            datasets: [
              {
                label: "States",
                outline: topography.nation,
                data: topography.states.map((d: any) => ({
                  feature: d,
                  value:
                    data?.find(({ name }) => name === d.properties.name)
                      ?.value || 0,
                })),
              },
            ],
          },
          options: options as any,
        });
      }
    };
    chartSetup();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, topography?.nation, topography?.states]);

  return (
    <Box height="max-content" width="100%" sx={containerSx}>
      {!data ? (
        <MapsLoading />
      ) : (
        <canvas id="geo-map-chart-canvas" ref={canvasRef} style={{ height }} />
      )}
    </Box>
  );
};

export default GeoMapChart;
