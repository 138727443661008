import { palette } from "@palette";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "56px 0px",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "40%",
  },
  label: {
    color: "#8F8F8F",
    fontSize: "14px",
    fontWeight: "normal",
  },
  value: {
    color: palette.neutral[90],
    fontSize: "14px",
    fontWeight: "normal",
  },
  valueContainer: {
    borderBottom: "2px dotted #292727",
    paddingBottom: "4px",
    display: "flex",
  },
  signature: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
});

type Props = {
  signedBy: string;
  signedAt: string;
  signatureURL?: string;
};

const PdfSignature = ({ signedAt, signedBy, signatureURL }: Props) => {
  const items = [
    {
      label: "Merchant Signature",
      value: signatureURL ? (
        <Image src={signatureURL} style={styles.signature} />
      ) : (
        ""
      ),
    },
    {
      label: "Date",
      value: signedAt,
    },
    {
      label: "Signed by",
      value: signedBy,
    },
  ];

  return (
    <View style={styles.container}>
      {items.map((item, index) => (
        <View
          key={index}
          style={{ ...styles.item, marginTop: index === 2 ? "24px" : 0 }}
        >
          <View style={styles.valueContainer}>
            {item.label === "Merchant Signature" ? (
              <View style={{ maxWidth: 120, maxHeight: 60 }}>{item.value}</View>
            ) : (
              <Text style={styles.value}>{item.value}</Text>
            )}
          </View>
          <Text style={styles.label}>{item.label}</Text>
        </View>
      ))}
    </View>
  );
};

export default PdfSignature;
