import { CARD_CONTENT_HEIGHT } from "@common/Campaigns/BaseCampaignCard";
import { ElementType, useEffect, useRef } from "react";
import { useTrail, animated, SpringValue } from "react-spring";

type TCustomAnimations = "waterfall" | "";

type ItemComponent<T> = (
  data: T,
  index: number,
  height: SpringValue<number>,
  springProps?: any,
) => JSX.Element;

type Props<T> = {
  items: T[];
  renderKey: (data: T, index: number) => React.Key | null | undefined;
  ItemComponent: ItemComponent<T>;
  config?: any;
  Wrapper?: ElementType;
  height?: number;
  animationName?: TCustomAnimations;
};

const UnfoldListItems = <T extends object | string>({
  items,
  renderKey,
  ItemComponent,
  config,
  Wrapper = animated.div,
  animationName = "",
  height = CARD_CONTENT_HEIGHT,
}: Props<T>) => {
  const defaultConfig = getDefaultConfig(animationName, height);
  const trail = useTrail(items?.length, config ? config : defaultConfig);
  const renderedItemsRef = useRef<Set<React.Key>>(new Set());
  useEffect(() => {
    // Add newly rendered items to the set
    items.forEach((item, index) => {
      const key = renderKey(item, index);
      if (key && !renderedItemsRef.current.has(key)) {
        renderedItemsRef.current.add(key);
      }
    });
  }, [items, renderKey]);
  return (
    <>
      {trail?.map(({ height, ...rest }, index) => {
        const data = items[index];
        const key = renderKey(data, index);
        const isRendered = renderedItemsRef.current.has(key || index);
        return (
          <Wrapper key={renderKey(data, index)} style={isRendered ? {} : rest}>
            {ItemComponent(data, index, height, rest)}
          </Wrapper>
        );
      })}
    </>
  );
};

const getDefaultConfig = (animationName: TCustomAnimations, height: number) => {
  const commonConfig = {
    opacity: 1,
    height,
    from: {
      opacity: 0,
      height: 0,
    },
  };

  switch (animationName) {
    case "waterfall":
      return {
        config: {
          mass: 1,
          tension: 1500,
          friction: 100,
        },
        x: 0,
        y: 0,
        ...commonConfig,
        transform: "scale(1)",
        from: {
          x: 10,
          y: 50,
          transform: "scale(0.8)",
          ...commonConfig.from,
        },
      };
    default:
      return {
        config: {
          mass: 1,
          tension: 1000,
          friction: 200,
        },
        ...commonConfig,
      };
  }
};

export default UnfoldListItems;
