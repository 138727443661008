import { showMessage } from "@common/Toast";
import NiceModal from "@ebay/nice-modal-react";
import { NOTIFY_MERCHANT_MODAL } from "modals/modal_names";
import { useUpdateChallenge } from "./useUpdateChallenge";

type Props = {
  challengeId: number;
  merchantId: number;
  merchantName: string;
  shouldDisplayNotifyModal: boolean;
  isEnterprise?: boolean;
  onClose?: () => void;
  challengeTypeName?: string;
};

export const useChallengeActions = ({
  challengeId,
  merchantId,
  merchantName,
  shouldDisplayNotifyModal,
  isEnterprise,
  onClose,
  challengeTypeName,
}: Props) => {
  const { handleUpdateChallengeStatus, handleNotifyMerchant, isLoading } =
    useUpdateChallenge({
      challengeId,
    });

  const rejectAction = async (cb?: any) => {
    handleUpdateChallengeStatus({
      merchantId,
      data: { status: "open", type: challengeTypeName },
      onClose: typeof cb === "function" ? cb : onClose,
    });
  };
  const confirmAction = () => {
    handleUpdateChallengeStatus({
      merchantId,
      data: { status: "closed", type: challengeTypeName },
      onClose,
    });
  };
  const notifyMerchant = () => {
    if (!shouldDisplayNotifyModal) {
      return showMessage(
        "Default",
        "Notifications are being sent to PAHs, make sure there is one assigned first",
        true,
        "Missing Primary Acc. Holder",
      );
    }

    NiceModal.show(NOTIFY_MERCHANT_MODAL, {
      handleNotify: (message: string) =>
        handleNotifyMerchant({ message }, merchantId),
      name: merchantName,
      isEnterprise,
    });
  };

  return {
    rejectAction,
    confirmAction,
    notifyMerchant,
    isDoingActions: isLoading,
  };
};
