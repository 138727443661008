import React from "react";
import { LockIcon } from "@assets/rebrandIcons";
import { Stack, StackProps } from "@mui/material";
import { Text } from "./Text";
import { palette } from "@palette";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";

interface Props {
  children: React.ReactElement | React.ReactElement[];
  isLoading?: boolean;
  message: string;
  notAuthorized: boolean;
  wrapperProps?: StackProps;
}

export const WithMissingPermissionModule = ({
  children,
  isLoading,
  message,
  notAuthorized,
  wrapperProps,
}: Props) => {
  // If the consumer is not handling the loading
  if (isLoading) {
    return (
      <LoadingSpinner
        sx={{
          height: "200px",
        }}
      />
    );
  }
  if (notAuthorized) {
    return (
      <Stack alignItems="center" marginTop="25%" {...wrapperProps}>
        <LockIcon width={33} height={33} />
        <Text color={palette.neutral[80]} fontSize="24px" fontWeight="regular">
          {message}
        </Text>
      </Stack>
    );
  }

  return <>{children}</>;
};
