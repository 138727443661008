import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { Badge, Box, styled } from "@mui/material";
import { palette } from "@palette";
import { Tooltip } from "@common/Tooltip";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { MATCHES, OFACTabType, TabStatuses } from "../helpers";

interface Props {
  activeTab: OFACTabType;
  setActiveTab: (tab: OFACTabType) => void;
  hasBusinessProfile?: boolean;
  hasPAH?: boolean;
  hasPAHData?: boolean;
  hasBusinessOwner?: boolean;
  tabStatuses?: TabStatuses;
}

export default function OFACTabs({
  activeTab,
  setActiveTab,
  hasBusinessProfile,
  hasPAH,
  hasBusinessOwner,
  tabStatuses,
  hasPAHData,
}: Props) {
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const tabs: {
    label: OFACTabType;
    hidden?: boolean;
    showBadge: boolean;
    disabled?: boolean;
  }[] = [
    {
      label: "Business Profile",
      disabled: !hasBusinessProfile,
      showBadge: MATCHES.includes(tabStatuses?.["Business Profile"] || "clear"),
    },
    ...(isNewOfacTabsEnabled
      ? [
          {
            label: "Primary Account Holder" as OFACTabType,
            showBadge: MATCHES.includes(
              tabStatuses?.["Primary Account Holder"] || "clear",
            ),
            disabled: !hasPAH || !hasPAHData,
            hidden: false,
          },
          {
            label: "Business Owner" as OFACTabType,
            showBadge: MATCHES.includes(
              tabStatuses?.["Business Owner"] || "clear",
            ),
            disabled: !hasBusinessOwner,
            hidden: false,
          },
        ]
      : []),
  ];

  return (
    <Box
      display="flex"
      borderBottom={`1px solid ${palette.neutral[20]}`}
      mt="16px"
    >
      {tabs.map(({ label, hidden, showBadge, disabled }) => {
        if (hidden) return null;
        const tooltipMessage =
          hasPAH && label === "Primary Account Holder" && !hasPAHData
            ? "Please fill the First and Last name of Primary Account Holder"
            : `Please add ${label} to enable the OFAC check`;
        return (
          <CustomBadge
            key={label}
            data-testid="OFAC-badge"
            variant="dot"
            invisible={!showBadge}
          >
            <Tooltip
              title={disabled ? tooltipMessage : null}
              placement="top"
              disableHoverListener={!disabled}
              titleSx={{
                fontSize: "12px",
                fontWeight: "book",
                color: palette.neutral[5],
              }}
            >
              <Tab
                data-testid="merchant-OFAC-tab-button"
                active={activeTab === label}
                onClick={() => setActiveTab(label)}
                disabled={disabled}
              >
                {label}
              </Tab>
            </Tooltip>
          </CustomBadge>
        );
      })}
    </Box>
  );
}

const Tab = styled(Text, { shouldForwardProp: (prop) => prop !== "active" })<
  ITextProps & { active: boolean; disabled?: boolean }
>(({ active, disabled }) => ({
  fontWeight: "350",
  color: palette.neutral[70],
  padding: "4px 16px",
  cursor: "pointer",
  ...(active && {
    color: palette.neutral[90],
    borderBottom: `2px solid ${palette.neutral[90]}`,
  }),
  ...(disabled && {
    opacity: "50%",
    pointerEvents: "none",
  }),
}));

const CustomBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    backgroundColor: palette.filled.orange,
    width: "14px",
    height: "14px",
    border: "2px solid #F7F7F7",
    borderRadius: "50%",
  },
}));
