type IconProps = {
  width?: number;
  height?: number;
  fill?: string;
};

const HorizontalMoreIcon = ({
  width = 14,
  height = 3,
  fill = "#8F8F8F"
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.45801 1.29169C3.45801 0.601331 2.89836 0.0416865 2.20801 0.0416865C1.51765 0.0416865 0.958008 0.601331 0.958008 1.29169C0.958008 1.98204 1.51765 2.54169 2.20801 2.54169C2.89836 2.54169 3.45801 1.98204 3.45801 1.29169Z"
        fill={fill}
      />
      <path
        d="M8.45801 1.29169C8.45801 0.60133 7.89836 0.0416862 7.20801 0.0416863C6.51765 0.0416863 5.95801 0.601331 5.95801 1.29169C5.95801 1.98204 6.51765 2.54169 7.20801 2.54169C7.89836 2.54169 8.45801 1.98204 8.45801 1.29169Z"
        fill={fill}
      />
      <path
        d="M13.0413 1.29169C13.0413 0.60133 12.4817 0.041686 11.7913 0.0416861C11.101 0.0416861 10.5413 0.60133 10.5413 1.29169C10.5413 1.98204 11.101 2.54169 11.7913 2.54169C12.4817 2.54169 13.0413 1.98204 13.0413 1.29169Z"
        fill={fill}
      />
    </svg>
  );
};

export default HorizontalMoreIcon;
