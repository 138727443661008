import { Box, Skeleton, styled } from "@mui/material";
import { animated, useInView, useSpring } from "react-spring";
import { isMobile } from "@utils/index";
import FadeDownWrapper from "@components/animation/FadeDownWrapper";

type Props = {
  loading: boolean;
  children: JSX.Element;
  height: number;
};

const ProductBannerSkeleton = ({ loading, children, height }: Props) => {
  const [ref, inView] = useInView({
    once: true,
  });

  const styles = useSpring({
    borderRadius: "24px",
    opacity: inView ? 1 : 0.3,
    boxShadow: inView
      ? "0px 0px 10px rgb(218, 218, 218)"
      : "0px 0px 0px rgba(0, 0, 0, 0)",
    config: { duration: 1000, mass: 1, stiffness: 28.8, damping: 12 },
  });

  if (loading)
    return (
      <StyledSkeleton
        variant="rounded"
        animation={false}
        width="100%"
        height={height}
      />
    );
  if (isMobile)
    return (
      <FadeDownWrapper
        delay={100}
        fromShadow="0px 0px 0px rgba(0, 0, 0, 0)"
        toShadow="0px 0px 10px rgb(218, 218, 218)"
        borderRadius="16px"
      >
        {children}
      </FadeDownWrapper>
    );
  return (
    <Box ref={ref}>
      <animated.div style={styles}>{children}</animated.div>
    </Box>
  );
};

const StyledSkeleton = styled(Skeleton)(() => ({
  background:
    "linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0.25) 100%)",
  opacity: 0.3,
  borderRadius: "24px",
}));

export default ProductBannerSkeleton;
