import React, { createContext, useContext } from "react";
import usePersonalInformation from "../hooks/usePersonalInformation";
import { Stack } from "@mui/material";
import KotoStepper from "@common/SignUp/KotoStepper";
import { personalInfoEnum } from "../types";

type PersonalInfo = ReturnType<typeof usePersonalInformation> & {
  homepageReset: () => void;
};
const Provider = createContext<PersonalInfo>({} as any);

function PersonalInformationProvider({
  children,
  homepageReset,
}: {
  children: React.ReactNode;
  homepageReset: () => void;
}) {
  const values = usePersonalInformation();
  const { personalInfoStatusBar, step, setStep, stepperRef, setIsLoaded } =
    values;
  return (
    <Provider.Provider value={{ ...values, homepageReset }}>
      <Stack
        direction="column"
        minHeight="100%"
        justifyContent="stretch"
        flexGrow={1}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <KotoStepper
            sx={{ flexGrow: 1 }}
            overriderActiveStep={Object.values(personalInfoStatusBar).findIndex(
              (x) => x.label === step,
            )}
            setStep={setStep}
            defaultStep={personalInfoEnum.PERSONAL_INFORMATION}
            customSteps={personalInfoStatusBar}
            ref={stepperRef}
            setIsLoaded={setIsLoaded}
          />
        </Stack>
        {children}
      </Stack>
    </Provider.Provider>
  );
}

export default PersonalInformationProvider;
export const usePersonalInformationProvider = () => useContext(Provider);
