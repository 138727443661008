import { namespaces } from "../../resources/i18n.constants";

export const common = {
  [namespaces.common]: {
    buttons: {
      ok: "Aceptar",
      cancel: "Cancelar",
      DownloadReport: "Descargar informe",
      more: "Más",
      DownloadReportAgain: "Descargar informe de nuevo",
      Done: "Hecho",
      clickHere: "haga clic aquí ",
      next: "Siguiente",
      Prev: "Anterior",
      UploadStatement: "Subir estado",
      ConfirmDeletion: "Confirmar eliminación",
      Edit: "Editar",
      Delete: "Eliminar",
      Back: "Atrás",
      Verify: "Verificar",
      Remove: "Eliminar",
      CreateOption: "Crear opción",
      PreviousStep: "Paso anterior",
      CreateFundraiser: "Crear recaudación de fondos",
      NextStep: "Siguiente paso",
      Payment: "Pago",
    },
    menu: {
      refundTransactions: "Transacciones de reembolso",
      sendReceipt: "Enviar recibo",
    },
    modals: {
      NewBankAccount: "Nueva cuenta bancaria",
      AverageApproval1: "El tiempo medio de aprobación es de 1 día",
      Connect_Manually: "Conectar Manualmente",
      AverageApproval3: "El tiempo medio de aprobación es de 3 días",
      ConnectWithPlaid: "Conectar con Plaid",
    },
    toast: {
      success: "Éxito",
      error: "Error",
      warning: "Advertencia",
      info: "Información",
      Report_has_been_successfully_downloaded:
        " El informe se ha descargado correctamente",
      Bank_Account_deleted_successfully:
        " Cuenta bancaria eliminada correctamente",
    },
    filters: {
      date: "Fecha",
      amount: "Cantidad",
      created: "Creado",
      status: "Estado",
      conversion: "Conversión",
      recurrence: "Recurrencia",
      type: "Tipo",
      transaction: "Transacción",
      transactions: "Transacciónes",
      search: "Buscar",
      isBefore: "Es antes",
      greaterThan: "Mayor que",
      lessThan: "Menos que",
      isAfter: "Es después",
      is: "Es",
      isNot: "No es",
      is_on_or_before: "está en o antes",
      is_on_or_after: "está en o después",
      isBetween: "Está entre",
      filterBy: "Filtrar por",
      apply: "Aplicar",
      joined: "Unido",
      total: "Total",
      quantity: "Cifra",
      inTheLast: "En el último",
      isEqualToOrGreaterThan: "Es igual o mayor que",
      isEqualToOrLessThan: "Es igual o menor que",
      source: "Fuente",
      reccuring_OneTime: "Recurrente & una vez",
      purchases: "Compras",
      visitors: "Visitantes",
      entries: "Entradas",
      "entries sold": "Entradas Vendidas",
      AllRecurring: "Todas las recurrentes",
      oneTime: "Una vez",
      daily: "Diario",
      weekly: "Semanal",
      monthly: "Mensual",
      yearly: "Anual",
      quartely: "Trimestral",
      CharitableDonations: "Donaciones caritativas",
      credits_Money_recived: "Créditos (dinero recibido)",
      debit_Money_transfered: "Débito  (dinero transferido)",
      giveboxPurshaes: "Compras de Givebox",
      Pending: "Pendiente",
      Approved: "Aprobado",
      Refunded: "Reembolsado",
      Chargeback: "Devolución de cargo",
      All: "Todo",
      Sales: "Ventas",
      days: "días",
    },
    transactionTable: {
      SendReceipt: "Enviar recibo",
      RefundTransaction: "Reembolsar transacción",
      ViewCustomer: "Ver cliente",
      MoreTransaction: "Más transacciones",
      Refund: "Reembolso",
    },
    "Any Amount": "Cualquier Cantidad",
    "Any payment amount is accepted.": "Se acepta cualquier cantidad de pago",
    transactions: " Transacciones",
    GeneralInfo: " Información general",
    Notes: " Notas",
    characters_remaining: "Caracteres restantes",
    advanced_builder: "Constructor Avanzado",
    stepper: {
      GeneralInformation: "Información general",
      Payement: "Pago",
    },
    delete: {
      delete: "Borrar",
      are_you_sure: "¿Estás segura de que quieres eliminar esto?",
      cancel: "Cancelar",
    },
  },
};
