import React, { useMemo, useState } from "react";
// mui
import { Box, Menu, Stack, styled, Avatar } from "@mui/material";
// components
import { TruncateText } from "@common/Text";
import { ArrowsIcon } from "@assets/rebrandIcons";
// redux
import { useAppSelector } from "@redux/hooks";
import { selectMasqueradeMode } from "@redux/slices/app";
// hooks
import { useAccountSelection } from "@components/Login/useAccountSelectionModal";
import { PopperChildren } from "./PopperChildren";
import { selectUser } from "@redux/slices/auth/auth";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useGetCurrentMerchantId } from "@hooks/common";
import { palette } from "@palette";

interface IDrawerProp {
  drawerOpen: boolean;
}

const DrawerProfile = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ drawerOpen }: IDrawerProp) => ({
  borderRadius: 8,
  display: "flex",
  alignItems: "center",

  "&:hover": {
    background: "#ECECE9", // neutral[10]
  },

  ...(!drawerOpen && {
    flexDirection: "column",
  }),
}));

const ProfileText = styled(TruncateText, {
  shouldForwardProp: (prop) => prop !== "drawerOpen" && prop !== "lineClamp",
})<
  {
    lineClamp: number;
  } & IDrawerProp
>(({ drawerOpen, lineClamp }) => ({
  lineClamp: 1,
  textTransform: "capitalize",
  display: "-webkit-box",
  WebkitLineClamp: lineClamp,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  position: "absolute",
  overflow: "hidden",
  opacity: 0,
  fontSize: 12,
  textAlign: "left",
  userSelect: "none",
  // transition: "opacity ease .3s",

  ...(drawerOpen && {
    marginLeft: "8px",
    opacity: 1,
    position: "static",
    transition: "opacity ease 3s",
  }),
}));

type DrawerProps = {
  drawerOpen: boolean;
};

export const DrawerAccountSelect = ({ drawerOpen }: DrawerProps) => {
  const { globalName } = useAppSelector(selectUser);
  const { name: masqName } = useAppSelector(selectMasqueradeMode);

  const { accountList, chooseAccount } = useAccountSelection();
  const { data: merchantData } = useGetMerchantById();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { merchantId, name, img, isControlMode } = useGetCurrentMerchantId();
  const avatarImage = img || merchantData?.imageURL + "/thumb";
  const merchantName =
    name || accountList.find((el) => el.id === merchantId)?.name || "";

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (accountList.length === 0 || isControlMode) return;
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const onPress = (e: React.MouseEvent<HTMLElement>, idx: number) => {
    chooseAccount(idx);
    handleClick(e);
  };

  return (
    <Box
      sx={{
        mt: "-2px",
        width: drawerOpen ? "auto" : "fit-content",
        alignSelf: drawerOpen ? "auto" : "center",
        overflow: "hidden",

        ...(accountList.length > 1 && {
          cursor: "pointer",
        }),
      }}
    >
      <DrawerProfile drawerOpen={drawerOpen} onClick={handleClick}>
        <Avatar
          src={avatarImage}
          style={{ borderRadius: 8 }}
          sx={{ height: 40, width: 40 }}
        />
        <Box>
          <ProfileText variant="caption" drawerOpen={drawerOpen} lineClamp={1}>
            {masqName || merchantName}
          </ProfileText>
          <ProfileText
            variant="caption"
            drawerOpen={drawerOpen}
            color={palette.gray[300]}
            lineClamp={1}
          >
            {`${globalName.firstName} ${globalName.lastName}`}
          </ProfileText>
        </Box>

        {accountList.length > 1 && !isControlMode && (
          <Stack
            mx="auto"
            {...(!drawerOpen && {
              mt: 0.5,
            })}
          >
            <ArrowsIcon />
          </Stack>
        )}
      </DrawerProfile>
      {/** ========================================== ACCOUNT MENU ================================== */}
      <Menu
        id="account-select-menu"
        aria-labelledby="account-select-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "0px 3px 15px rgba(2, 2, 2, 0.15)",

            ...(drawerOpen && {
              width: 241,
            }),
          },
        }}
      >
        <PopperChildren
          accountList={accountList}
          chooseAccount={onPress}
          drawerOpen={drawerOpen}
        />
      </Menu>
    </Box>
  );
};
