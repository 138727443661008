const LockIconV2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      data-testid="lock-icon-v2"
    >
      <path
        opacity="0.2"
        d="M17.3169 8.40894V17.1589C17.3169 17.3247 17.251 17.4837 17.1338 17.6009C17.0166 17.7181 16.8577 17.7839 16.6919 17.7839H4.19189C4.02613 17.7839 3.86716 17.7181 3.74995 17.6009C3.63274 17.4837 3.56689 17.3247 3.56689 17.1589V8.40894C3.56689 8.24318 3.63274 8.0842 3.74995 7.96699C3.86716 7.84978 4.02613 7.78394 4.19189 7.78394H16.6919C16.8577 7.78394 17.0166 7.84978 17.1338 7.96699C17.251 8.0842 17.3169 8.24318 17.3169 8.40894Z"
        fill="#8F8F8F"
      />
      <path
        d="M16.6919 7.15894H14.1919V5.28394C14.1919 4.28937 13.7968 3.33555 13.0935 2.63229C12.3903 1.92902 11.4365 1.53394 10.4419 1.53394C9.44733 1.53394 8.49351 1.92902 7.79024 2.63229C7.08698 3.33555 6.69189 4.28937 6.69189 5.28394V7.15894H4.19189C3.86037 7.15894 3.54243 7.29063 3.30801 7.52505C3.07359 7.75947 2.94189 8.07741 2.94189 8.40894V17.1589C2.94189 17.4905 3.07359 17.8084 3.30801 18.0428C3.54243 18.2772 3.86037 18.4089 4.19189 18.4089H16.6919C17.0234 18.4089 17.3414 18.2772 17.5758 18.0428C17.8102 17.8084 17.9419 17.4905 17.9419 17.1589V8.40894C17.9419 8.07741 17.8102 7.75947 17.5758 7.52505C17.3414 7.29063 17.0234 7.15894 16.6919 7.15894ZM7.94189 5.28394C7.94189 4.62089 8.20529 3.98501 8.67413 3.51617C9.14297 3.04733 9.77885 2.78394 10.4419 2.78394C11.1049 2.78394 11.7408 3.04733 12.2097 3.51617C12.6785 3.98501 12.9419 4.62089 12.9419 5.28394V7.15894H7.94189V5.28394ZM16.6919 17.1589H4.19189V8.40894H16.6919V17.1589Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default LockIconV2;
