interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const InputEyeIconNotVisible = ({
  width = 24,
  height = 24,
  fill = "none",
  path_fill = "#734089",
}: IconProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 5C7.05576 5 4.03109 9.23425 2.95554 11.1164C2.73488 11.5025 2.62456 11.6956 2.6367 11.9836C2.64885 12.2716 2.77857 12.4598 3.03799 12.8362C4.3182 14.6935 7.79389 19 12.5 19C17.2061 19 20.6818 14.6935 21.962 12.8362C22.2214 12.4598 22.3511 12.2716 22.3633 11.9836C22.3754 11.6956 22.2651 11.5025 22.0445 11.1164C20.9689 9.23425 17.9442 5 12.5 5Z"
        stroke={path_fill}
        strokeWidth="1.2"
      />
      <circle cx="12.5" cy="12" r="3" stroke="#734089" strokeWidth="1.2" />
      <path
        d="M3.5 20L21.5 3"
        stroke={path_fill}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default InputEyeIconNotVisible;
