import { Components } from "@mui/material";
import { palette } from "@palette";

export const cssBaseline: Components["MuiCssBaseline"] = {
  styleOverrides: {
    "*": {
      scrollbarWidth: "thin",
      scrollbarColor: `${palette.neutral["200"]} transparent`,
      "&::-webkit-scrollbar-track": {
        backgroundColor: palette.liftedWhite.main,
      },
      "&::-webkit-scrollbar": {
        width: 10,
        height: 10,
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        border: "2px solid #F9FCFF",
        borderOpacity: 0.3,
        borderRadius: 6,
        minWidth: 24,
        minHeight: 24,
        backgroundColor: palette.neutral["200"],
      },
      "&::-webkit-scrollbar-thumb:focus": {
        backgroundColor: palette.neutral["300"],
      },
      "&::-webkit-scrollbar-thumb:active": {
        backgroundColor: palette.neutral["300"],
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: palette.neutral["300"],
      },
      "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent",
      },
    },
    body: {
      backgroundColor: palette.primary.background,
      overscrollBehavior: "none",
    },
  },
};
