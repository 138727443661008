import Skeleton, { DoubledSkeleton } from "@components/animation/Skeleton";
import { Stack } from "@mui/material";

const MerchantAgreementSkeleton = () => {
  return (
    <Stack spacing={3} p="16px" data-testid="merchant-agreement-skeleton">
      <Skeleton width={150} />
      <Skeleton width="100%" height={150} />
      <DoubledSkeleton width="40%" frequency={4} />
      <Skeleton width={80} />
      <DoubledSkeleton frequency={4} direction="row" width={70} />
      <DoubledSkeleton frequency={2} />
      <DoubledSkeleton frequency={2} height={150} />
      <DoubledSkeleton frequency={2} direction="row" height={100} />
      <Skeleton width="50%" height={100} />
    </Stack>
  );
};

export default MerchantAgreementSkeleton;
