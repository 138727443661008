import React from "react";
// mui
import { Box, Grid, GridProps, Stack, styled } from "@mui/material";
// components
import { GraphIcon } from "@assets/analyticsIcons";
import { DiagonalIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { DECIMAL_SIZE_RATIO } from "@common/Figure";
import NewStat from "@common/NewStats/NewStat";
import { NewStatsTitle } from "@common/NewStats/NewStatsTitle";
import { StatProps } from "@common/NewStats/Stat";
import { StatsTitleProps } from "@common/NewStats/StatsTitle";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { isMobile } from "@utils/index";
import NewMobileStatCard from "./NewMobileStatCard";

export type StatCardProps = {
  title: string;
  mainStat: StatsTitleProps;
  stats: StatProps[];
  actions: React.ReactNode;
  onOpenAnalytics?: () => void;
  AnalyticsIcon?: JSX.Element;
  mainStatTitleProps?: any;
  statTextProps?: any;
  statValueProps?: any;
  labelProps?: any;
  color?: string;
};

const NewStatCard = (props: StatCardProps) => {
  const { title, mainStat, stats, actions, onOpenAnalytics, AnalyticsIcon } =
    props;

  if (!isMobile)
    return (
      <StyledRoot>
        <StyledContainer sx={{ alignItems: "start" }}>
          <Grid item>
            <StyledTitle>{title}</StyledTitle>
          </Grid>
          <Grid item>
            <StyledActionsContainer item>{actions}</StyledActionsContainer>
          </Grid>
        </StyledContainer>
        <StyledContainer isstats>
          <NewStatsTitle
            isAmount={mainStat.isAmount}
            label={mainStat.title}
            value={mainStat.value}
            labelStyle={balanceLabelStyle}
            figureStyle={balanceFigureStyle}
            ratio={mainStat?.ratio}
            ratioStyle={ratioFigureStyle}
            isMainTitle
          />
          <StyledStatsContainer>
            {stats.map(({ isAmount, title, value, percent, ratio }, index) => (
              <NewStat
                key={index}
                isAmount={isAmount}
                textProps={{
                  fontSize: "12px",
                  fontWeight: 357,
                  lineHeight: "14.4px",
                }}
                valueProps={statValueStyle}
                title={
                  title
                    ? title?.toString().charAt(0).toUpperCase() +
                      title?.toString().slice(1)
                    : ""
                }
                value={value}
                percent={percent}
                ratio={ratio}
                ratioStyle={statRatioStyle}
              />
            ))}
            {onOpenAnalytics && (
              <ViewAnalyticsLink
                onClick={onOpenAnalytics}
                Icon={AnalyticsIcon}
              />
            )}
          </StyledStatsContainer>
        </StyledContainer>
      </StyledRoot>
    );
  return <NewMobileStatCard {...props} />;
};

export const ViewAnalyticsLink = ({
  onClick,
  Icon = <GraphIcon />,
  labelProps,
  stroke,
}: {
  onClick?: () => void;
  Icon?: JSX.Element;
  labelProps?: any;
  stroke?: string;
}) => {
  return (
    <Stack
      direction="column"
      gap="4px"
      alignItems="stretch"
      justifyContent="flex-end"
      sx={(theme) => ({
        [theme.breakpoints.down("sm")]: {
          marginInline: "auto",
        },
      })}
    >
      {Icon}
      <ViewAnalyticsButton
        background="tertiary"
        onClick={onClick}
        {...labelProps}
      >
        View Analytics <DiagonalIcon stroke={stroke} />
      </ViewAnalyticsButton>
    </Stack>
  );
};

const ViewAnalyticsButton = styled(Button)(() => ({
  color: palette.black[300],
  textDecoration: "underline",
  minWidth: "100%",
  padding: 0,
  lineHeight: "16.8px",
  fontWeight: 350,
  height: "max-content",
  textAlign: "center",
  whiteSpace: "nowrap",
}));

export const StyledContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isstats",
})<GridProps & { isstats?: boolean }>(({ theme, isstats }) => ({
  gap: "16px",
  display: "flex",
  justifyContent: "space-between",
  ...(isstats && {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "32px",
    },
  }),
}));

const StyledRoot = styled(Box)(({ theme }) => ({
  maxHeight: "238px",
  gap: "56px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& button": {
    whiteSpace: "nowrap",
  },
  [theme.breakpoints.down("md")]: {
    gap: "32px",
  },
}));

const StyledActionsContainer = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledStatsContainer = styled(Grid)(({ theme }) => ({
  gap: "24px",
  display: "flex",
  [theme.breakpoints.down(860)]: {
    gap: "16px",
  },
}));

const StyledTitle = styled(Text)(({ theme }) => ({
  fontSize: "38px",
  fontWeight: 357,
  lineHeight: "45.6px",
  color: palette.black[100],
  [theme.breakpoints.down(740)]: {
    fontSize: "24px",
  },
}));

export const balanceFigureStyle = {
  fontSize: "38px",
  fontWeight: 271,
  lineHeight: "45.6px",
  "@media(max-width: 860px)": {
    fontSize: "32px",
    "& span": {
      fontSize: `${32 * DECIMAL_SIZE_RATIO}px !important`,
    },
  },
  "@media(max-width: 740px)": {
    fontSize: "24px",
    "& span": {
      fontSize: `${24 * DECIMAL_SIZE_RATIO}px !important`,
    },
  },
};

export const ratioFigureStyle = {
  fontSize: "24px",
  fontWeight: 271,
  lineHeight: "32.4px",
};

export const balanceLabelStyle = {
  textAlign: "left",
  whiteSpace: "nowrap",
  fontSize: "14px",
  fontWeight: 357,
  lineHeight: "16.8px",
};

export const statValueStyle = {
  sx: {
    fontSize: "24px",
    fontWeight: 357,
    lineHeight: "32.4px",
    "@media(max-width: 860px)": {
      "& span": {
        fontSize: `${24 * DECIMAL_SIZE_RATIO}px !important`,
      },
    },
    "@media(max-width: 740px)": {
      fontSize: "18px",
      "& span": {
        fontSize: `${18 * DECIMAL_SIZE_RATIO}px !important`,
      },
    },
  },
};

export const statRatioStyle = {
  fontSize: "18px",
  fontWeight: 357,
  lineHeight: "21.6px",
};

export default NewStatCard;
