import { Text, TruncateText } from "@common/Text";
import { Stack } from "@mui/material";
import { usePreviewImageContext } from "./PreviewImagesBody";
import { clamper } from "../utils"; 

const ImageCaption = () => {
  const { icon, fileName, title, isZooming } = usePreviewImageContext();
  const clampedName = clamper(fileName);
  return (
    <Stack
      position="absolute"
      direction="row"
      alignItems="center"
      id="preview-image-caption"
      justifyContent="space-between"
      sx={{
        bottom: "16px",
        left: "16px",
        right: "16px",
        background: "rgba(0, 0, 0, 0.80)",
        borderRadius: "6px",
        padding: "4px 8px",
        zIndex: 2,
        opacity: 1,
        transition: "opactiy 5s ease",
        ...(isZooming && {
          opacity: 0.3,
        }),
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {icon("#fff")}
        <Text color="#fff">{title}</Text>
      </Stack>
      <TruncateText lineClamp={1} color="#fff">
        {clampedName}
      </TruncateText>
    </Stack>
  );
};

export default ImageCaption;
