import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

type Props = {
  width?: number;
  height?: number;
};

const SearchIcon = ({ width = 100, height = 100 }: Props) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [col1, col2] = parseLinearGradientColor();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2935 23.335C34.6922 23.335 24.3521 33.0269 23.3245 45.3613C23.2099 46.7372 22.0015 47.7597 20.6256 47.6451C19.2497 47.5305 18.2272 46.3221 18.3418 44.9462C19.5834 30.042 32.07 18.335 47.2935 18.335C63.3391 18.335 76.3466 31.3424 76.3466 47.3879C76.3466 54.5519 73.7536 61.1104 69.4549 66.1758L80.9143 77.3808C81.9015 78.3461 81.9193 79.9289 80.954 80.9161C79.9887 81.9033 78.4059 81.9211 77.4187 80.9558L65.905 69.6977C60.8641 73.9075 54.3746 76.4408 47.2935 76.4408C36.2571 76.4408 26.6618 70.2861 21.7462 61.2336C21.0873 60.0203 21.5368 58.5025 22.7502 57.8436C23.9635 57.1848 25.4813 57.6343 26.1402 58.8476C30.2162 66.3539 38.1626 71.4407 47.2935 71.4407C53.8911 71.4407 59.8682 68.7845 64.2138 64.4832C68.6181 60.1237 71.3466 54.0744 71.3466 47.3879C71.3466 34.1038 60.5777 23.335 47.2935 23.335Z"
        fill="url(#paint0_linear_825_995)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_825_995"
          x1="81.6665"
          y1="18.335"
          x2="19.9589"
          y2="83.2147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={col1} />
          <stop offset="1" stopColor={col2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SearchIcon;
