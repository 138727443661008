import { FocusEvent, useEffect, useState } from "react";
import ExpandedSearchBar from "@common/SearchBar/SearchBar";
import { SxProps } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { DefaultSearchbar } from "./SearchBar";

interface SearchBarProps {
  onChange: (value: string) => void;
  value: string;
  isExpandedByDefault?: boolean;
  formSx?: SxProps;
}

const ControlledSearchBar = ({
  onChange,
  value,
  isExpandedByDefault,
  formSx,
}: SearchBarProps) => {
  const { isDesktopView } = useCustomTheme();
  const [expanded, setExpanded] = useState(false);

  const onBlur = (event: FocusEvent) => {
    if (!value) {
      setExpanded(false);
      onChange("");
    }
  };

  useEffect(() => {
    return () => {
      onChange("");
    };
  }, []);

  if (!isDesktopView) {
    return (
      <ExpandedSearchBar
        IOCsetQueryString={onChange}
        IOCqueryString={value}
        formSx={{
          width: "100%",
          ...formSx,
        }}
      />
    );
  }

  return expanded ? (
    <ErrorCatcher errorID="ExpandedSearchBar">
      <ExpandedSearchBar
        onBlur={onBlur}
        IOCsetQueryString={onChange}
        IOCqueryString={value}
        isExpandedByDefault={isExpandedByDefault}
        formSx={{
          width: isExpandedByDefault ? "100%" : "fit-content",
          ...formSx,
        }}
        collapse={() => {
          setExpanded(false);
        }}
        autoFocus
      />
    </ErrorCatcher>
  ) : (
    <ErrorCatcher errorID="DefaultSearchbar">
      <DefaultSearchbar onClick={() => setExpanded(true)} />
    </ErrorCatcher>
  );
};

export default ControlledSearchBar;
