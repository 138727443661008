import Box from "@mui/material/Box";
import { palette } from "@palette";

// import { useFormContext, Controller } from "react-hook-form";
import { Input } from "@common/Input";
import { Text } from "@common/Text";
import GradientPickerPopover from "@components/EnterpriseSettings/Branding/components/GradientPickerPopover";
import GradientPickerPopoverNew from "@components/EnterpriseSettings/Branding/components/GradientPickerPopoverNew";
import { Stack } from "@mui/material";
import { RGBAToHexA } from "@sections/Actions/Share/functions";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { memo, useCallback, useEffect, useState } from "react";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

export type ColorPickerProps = {
  fullWidth?: boolean;
  width?: string | number;
  size?: "small" | "medium";
  label?: string | JSX.Element;
  onChange: (color: string) => void;
  defaultValue: string;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  width,
  fullWidth,
  size = "medium",
  defaultValue,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>(defaultValue);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSetColor = useCallback((col: string) => {
    setValue(RGBAToHexA(col).toUpperCase());
  }, []);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <Box
      sx={{
        "& > div": {
          width: "100%",
        },

        "button.MuiButton-root": {
          boxShadow: "none",
          borderRadius: "50%",

          "& > div": {
            borderRadius: "50%",
            border: `1px solid ${palette.neutral[700]}`,
          },
        },

        ...(fullWidth && {
          "& .MuiFormControl-root.MuiTextField-root": {
            width: "100%",
          },
        }),

        ...(width && {
          "& .MuiFormControl-root.MuiTextField-root": {
            width: width,
          },
        }),

        "& .MuiInputBase-input": {
          marginTop: "0 !important",
        },
        "& .MuiInputBase-root": {
          padding: "15px 4px 15px 12px",
        },

        ...(size === "small" && {
          "& .MuiInputBase-root": {
            padding: "0 4px 0 12px",

            "& input": {
              padding: "4px 0",
            },
          },
        }),
      }}
    >
      {label && (
        <Text
          fontWeight="medium"
          sx={{ pb: size === "small" ? 0.5 : 1 }}
          color={palette.neutral[800]}
        >
          {label}
        </Text>
      )}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <Box
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            width: 24,
            minWidth: 24,
            height: 24,
            border: "solid .5px black",
            borderRadius: 24,
            cursor: "pointer",

            backgroundColor: value,
          }}
        ></Box>
        <Input
          fullWidth
          sx={{
            width: "100%",
          }}
          inputProps={{ maxLength: 9 }}
          type="text"
          value={value}
          name="text1"
          onChange={(e) => {
            setValue(e.target.value.toUpperCase());
          }}
        />
      </Stack>
      <FlaggedWrapper
        ActiveComponent={
          <GradientPickerPopoverNew
            anchorEl={anchorEl}
            onChange={handleSetColor}
            handleClose={handleClose}
            value={value}
            hideOpacity={false}
            hideGradientButton
          />
        }
        FallbackComponent={
          <GradientPickerPopover
            anchorEl={anchorEl}
            onChange={handleSetColor}
            handleClose={handleClose}
            value={value}
          />
        }
        name={FeatureFlagKeys.NEW_COLOR_PICKER_IMPLEMENTATION_FLAG_KEY}
      />
      {/* <MuiColorPicker {...rest} /> */}
    </Box>
  );
};

export default memo(ColorPicker);
