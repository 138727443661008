import { Box } from "@mui/material";

interface Props {
  targetProgress: number;
}

function CustomProgressbar({ targetProgress }: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "3px",
        borderRadius: "8px",
        display: "flex",
        overflow: "hidden",
        backgroundColor: "#ECECE9",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          width: `${targetProgress}%`,
          backgroundImage:
            "linear-gradient(223.56deg, #72ECF0 0%, #528DDF 100%)",
          transition: "width 0.5s ease-in-out",
        }}
      />
    </Box>
  );
}

export { CustomProgressbar };
