import NiceModal from "@ebay/nice-modal-react";
import SidePanelBody from "./SidePanelBody";
import AnalyticsSkeleton from "./components/AnalyticsSkeleton";
import useAnalyticsData from "./hooks/useAnalyticsData";
import SidePanelWrapper from "../SidePanelWrapper";

const CampaignSidePanel = NiceModal.create(
  ({ isEmpty = false }: { isEmpty?: boolean }) => {
    const { data, isLoading } = useAnalyticsData(isEmpty);
    return (
      <SidePanelWrapper
        data={data}
        isLoading={isLoading}
        PanelBody={SidePanelBody}
        LoadingPlaceholder={AnalyticsSkeleton}
        isEmpty={isEmpty}
      />
    );
  },
);

export default CampaignSidePanel;
