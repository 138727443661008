const CustomArrowRight = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
  rotate = 0,
}: {
  width?: number | string;
  height?: number | string;
  stroke?: string;
  rotate?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5122 5.18888C12.2794 4.95303 11.8995 4.95053 11.6636 5.1833C11.4278 5.41607 11.4253 5.79596 11.6581 6.03181L16.8636 11.3062L9.69985 11.3062C9.36848 11.3062 9.09985 11.5749 9.09985 11.9062C9.09985 12.2376 9.36848 12.5062 9.69985 12.5062L16.8906 12.5062L11.6524 17.9608C11.4228 18.1998 11.4305 18.5796 11.6695 18.8091C11.9085 19.0386 12.2883 19.031 12.5179 18.792L18.7104 12.3438C18.827 12.2343 18.8999 12.0788 18.8999 11.9062C18.8999 11.7308 18.8246 11.573 18.7045 11.4632L12.5122 5.18888ZM5.69961 11.3062C5.36824 11.3062 5.09961 11.5749 5.09961 11.9062C5.09961 12.2376 5.36824 12.5062 5.69961 12.5062H7.09985C7.43122 12.5062 7.69985 12.2376 7.69985 11.9062C7.69985 11.5749 7.43122 11.3062 7.09985 11.3062H5.69961Z"
        fill={stroke}
      />
    </svg>
  );
};

export default CustomArrowRight;
