import { useGetCurrentMerchantId } from "@hooks/common";
import { useEffect, useMemo, useState } from "react";
import {
  IUpdatedData,
  TPermissionQueryReturnedData,
} from "features/Permissions/Modal/api/types";
import {
  useGetData,
  useUpdatePermissions,
} from "features/Permissions/Modal/api";
import { useQueryClient } from "react-query";
import { QKEY_LIST_TEAM_MEMBER_PERMISSIONS } from "@constants/queryKeys";
import useDeletePermission from "./useDeletePermission";
import { ItemType } from "../components/FilterPermissionList";
import { showMessage } from "@common/Toast";
import { isEqual } from "lodash";
import { createPayload } from "../utils/permissionData.utils";
import useDataWithLogs from "./useDataWithLogs";
import { generateGroupedData } from "../utils/permissionPanel.utils";
import { IAssignmentFilter, IFilterAssigned } from "../types";

type TQueryOptions = Partial<{
  withPagination: boolean;
  assignedOnly: boolean;
  queryKey: string;
  enabled: boolean;
}>;

const initialFilter: IAssignmentFilter = { assigned: -1, custom: 0 };

const usePermissionData = (memberId: number, options?: TQueryOptions) => {
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();

  const {
    setDataFromAPI,
    clearLogs,
    resetHashedData,
    hashedData,
    latestAdded,
    handleChangePermissionStatus,
    logs,
    updateLatestAdded,
    updateAllHashedData,
    isMutated,
    setInitialData,
    initialData,
    updateAllLogs,
    removeItemFromLogs,
  } = useDataWithLogs();

  /*
    -1: none
    0: revoked
    1: assigned
  */
  const [filter, setFilter] = useState<IAssignmentFilter>(initialFilter);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const setData = (data: TPermissionQueryReturnedData) => {
    setDataFromAPI(data.data);
    if (!searchQuery) setInitialData(data.data);
  };

  const {
    data,
    isLoading: dataIsLoading,
    isFetching: dataIsFetching,
  } = useGetData({
    page: 1,
    memberId,
    accountId: merchantId,
    searchQuery,
    ...options,
    options: {
      enabled: options?.enabled,
    },
  });

  useEffect(() => {
    if (data && !dataIsLoading && !dataIsFetching) setData(data);
  }, [dataIsLoading, dataIsFetching, data]);

  const { mutateAsync } = useUpdatePermissions(merchantId, memberId);
  const { mutateAsync: deleteMutateAsync } = useDeletePermission(
    merchantId,
    memberId,
  );

  const resetStates = () => {
    setSearchQuery("");
    setFilter(initialFilter);
    clearLogs();
  };

  const clearChanges = () => {
    resetStates();
    resetHashedData(initialData.current);
  };

  const totalItems = Object.keys(hashedData || {}).length;

  const permissionsData = useMemo(
    () => generateGroupedData(filter, hashedData),
    [hashedData, Object.keys(latestAdded).length, searchQuery, filter],
  );

  const latestAddedData = useMemo(
    () =>
      generateGroupedData(
        filter,
        Object.entries(latestAdded).reduce((acc, [key, val]) => {
          if (!val.isAttached) return acc;
          if (!logs[key]) return { ...acc, [key]: val };

          return {
            ...acc,
            [key]: {
              ...val,
              isDeny: logs[key].isDeny,
            },
          };
        }, {}),
      ),
    [latestAdded, Object.keys(latestAdded).length, logs, filter],
  );

  const handleSetFilter = (e: ItemType) => {
    setFilter((current: IAssignmentFilter) => {
      const assigned: Record<string, IFilterAssigned> = {
        allow: current.assigned === 1 ? -1 : 1,
        deny: current.assigned === 0 ? -1 : 0,
      };

      return {
        ...current,
        assigned: assigned[e.value] !== undefined ? assigned[e.value] : -1,
      };
    });
  };

  const onDelete = async (permissionID: string, successMessage?: string) => {
    await deleteMutateAsync(permissionID, {
      onSuccess: () => {
        if (successMessage) showMessage("Success", successMessage);
      },
      onError: (err: any) => {
        showMessage("Error", err?.response?.data?.message);
      },
    });
  };

  const setPermissionStatus = (key: string, value: IUpdatedData) => {
    const updatedItem: IUpdatedData = {
      ...value,
      ...(options?.assignedOnly
        ? {
            isDeny: !value.isDeny,
          }
        : {
            isAttached: !value.isAttached,
          }),
    };

    const isUnchanged = isEqual(updatedItem, initialData.current[key]);
    handleChangePermissionStatus(key, updatedItem, isUnchanged);
  };

  const onSaveSidePanel = async () => {
    const assignmentsPayload = createPayload(latestAdded, "assignment");
    const effectsPayload = createPayload(logs, "effect");

    const onError = (err: any) => {
      showMessage("Error", err?.response?.data?.message);
    };

    const options = { onError };

    if (Object.keys(assignmentsPayload).length > 0) {
      await mutateAsync(assignmentsPayload, options);
    }

    if (Object.keys(effectsPayload).length > 0) {
      await mutateAsync(effectsPayload, options);
    }

    setInitialData(hashedData || {});
    resetStates();
    await queryClient.invalidateQueries(QKEY_LIST_TEAM_MEMBER_PERMISSIONS);
    showMessage("Success", "Changes saved");
  };

  return {
    hashedData,
    totalItems,
    searchQuery,
    setSearchQuery,
    isMutated,
    permissionsData,
    latestAddedData,
    dataIsLoading,
    dataIsFetching,
    handleSetFilter,
    onDelete,
    clearChanges,
    onSaveSidePanel,
    setPermissionStatus,
    logs,
    updateLatestAdded,
    updateAllHashedData,
    updateAllLogs,
    removeItemFromLogs,
  };
};

export default usePermissionData;
