export const bankList = [
  { name: "Bank of America", routingNumber: "026009593" },
  { name: "Chase Bank", routingNumber: "021000021" },
  { name: "Wells Fargo Bank", routingNumber: "121000248" },
  { name: "Citibank", routingNumber: "021000089" },
  { name: "Capital One", routingNumber: "031176110" },
  { name: "US Bank", routingNumber: "123000220" },
  { name: "TD Bank", routingNumber: "031101266" },
  { name: "PNC Bank", routingNumber: "043000096" },
  { name: "BB&T", routingNumber: "053101121" },
  { name: "SunTrust Bank", routingNumber: "061000104" },
];

export const findBankByRoutingNumber = (routingNumber: string) =>
  bankList.find(bank => bank.routingNumber === routingNumber)?.name || "";
