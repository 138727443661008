interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const InfoIcon = ({
  width = 32,
  height = 32,
  fill = "none",
  path_fill = "#734089",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.999 0.810059C5.98908 0.810059 0.307617 6.49152 0.307617 13.5002C0.307444 15.1669 0.635599 16.8173 1.27334 18.3572C1.91108 19.897 2.84592 21.2962 4.02446 22.4747C5.20301 23.6533 6.60217 24.5881 8.14204 25.2259C9.68191 25.8636 11.3323 26.1918 12.999 26.1916C20.0064 26.1916 25.6891 20.5101 25.6891 13.5002C25.6891 6.49152 20.0064 0.810059 12.999 0.810059ZM14.1835 5.39172C15.4207 5.39172 15.7843 6.1095 15.7843 6.93039C15.7843 7.95485 14.9647 8.90264 13.5648 8.90264C12.3936 8.90264 11.8358 8.3144 11.8702 7.34018C11.8702 6.51928 12.5562 5.39172 14.1835 5.39172ZM11.0136 21.101C10.1676 21.101 9.55024 20.5868 10.1411 18.333L11.1101 14.3343C11.2779 13.6945 11.3057 13.438 11.1101 13.438C10.8576 13.438 9.7591 13.8795 9.11138 14.3158L8.6897 13.6244C10.7452 11.9073 13.1088 10.9 14.12 10.9C14.966 10.9 15.1061 11.8994 14.6844 13.438L13.5741 17.6416C13.3771 18.3845 13.4617 18.641 13.6587 18.641C13.9125 18.641 14.7426 18.3343 15.5595 17.6905L16.0381 18.333C14.038 20.3317 11.8583 21.101 11.0136 21.101Z"
        fill={path_fill}
      />
    </svg>
  );
};

export default InfoIcon;
