import CheckIconColored from "@assets/icons/RebrandedIcons/CheckIconColored";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import MarkAllAsOkButton from "@components/Merchants/MerchantPreview/RiskProfile/components/RiskProfileActivity/MarkAllAsOkButton";
import { Box, Stack, styled } from "@mui/material";
import { memo, useMemo } from "react";
import { useRiskActivity } from "../../hooks/useRiskActivity";
import { TMerchantRiskProfile } from "../../types";
import RiskActivitySkeleton from "../skeletons/RiskActivitySkeleton";
import ActivityCard from "./ActivityCard";
import RiskActivityStatus from "./RiskActivityStatus";
import RiskProfileActivityHeader from "./RiskProfileActivityHeader";
import { HeaderNotifyButton } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/atoms";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import {
  setConversationTopic,
  setModalOpenConversation,
} from "@redux/slices/conversations";
import { useAppDispatch } from "@redux/hooks";

type Props = {
  data: TMerchantRiskProfile;
  profileId: number;
  merchantId: number;
};

const RiskProfileActivity = ({ data, profileId, merchantId }: Props) => {
  const { merchant_underwriting, risk_activity } = useEnterprisePermissions();
  const {
    data: riskActivities,
    isLoading,
    targetRef,
    tabs,
    activeTab,
    setActiveTab,
    isError,
    activeRisks,
  } = useRiskActivity(profileId, merchantId);
  const { isEnabledConversationActivity } = useGetFeatureFlagValues();
  const canShowMarkAll = activeRisks ? activeRisks.length > 1 : false;

  /** Escalation Ids are ids of risk activities that have not been marked as ok */
  const escalationIds = useMemo(
    () =>
      canShowMarkAll && activeRisks ? activeRisks.map(({ id }) => id) : [],
    [canShowMarkAll, activeRisks],
  );

  if (isLoading) return <RiskActivitySkeleton />;
  if (isError) return null; //TODO-To be handled with right screen

  const listLength = riskActivities?.length;
  const dispatch = useAppDispatch();
  const disableGenericNotify = false;
  const openNotifyMerchantModal = async () => {
    const { data: topics } = await getGlobalTopic({});

    const topic = topics?.find(
      (item: any) => item?.Name === "risk_monitor" && item?.Type === "activity",
    );
    const topicId = topic?.ID;
    if (!topicId) return;
    dispatch(setModalOpenConversation(true));
    dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        numberOfUnreadMessages: 0,
        queryObject: {
          id: topicId,
          name: `Notify Merchant `,
          tabs: "Activity",
          paths: [
            {
              isConversation: true,
              pathName: `Notify Merchant `,
              pathID: "new",
              avatars: [],
              hideInputs: ["module"],
              topicName: "general",
              moduleID: topicId,
            },
          ],
        },
      }),
    );
  };
  return (
    <StyledBox padding="0 16px 12px">
      <RiskProfileActivityHeader data={data ?? {}} />
      <FadeUpWrapper delay={150}>
        <RiskActivityStatus
          status={data?.riskStatus}
          profileId={profileId}
          merchantId={merchantId}
        />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt="33px"
          mb="25px"
        >
          <Stack direction="row" gap="20px" alignItems="center">
            {Object.values(tabs).map((tab, index) => (
              <TabText
                onClick={() => setActiveTab(tab)}
                active={activeTab === tab}
                key={index}
              >
                {tab}
              </TabText>
            ))}
          </Stack>

          <Stack flexDirection="row" alignItems="center">
            {canShowMarkAll && risk_activity && (
              <MarkAllAsOkButton
                merchantId={merchantId}
                profileId={profileId}
                escalationIds={escalationIds}
              />
            )}

            {merchant_underwriting && isEnabledConversationActivity && (
              <HeaderNotifyButton
                onClick={openNotifyMerchantModal}
                disabled={false}
                isEnterprise={false}
                tooltipProps={{
                  show: disableGenericNotify,
                  message: `The merchant does not have a Primary Account Holder`,
                }}
              />
            )}
          </Stack>
        </Stack>
      </FadeUpWrapper>
      {listLength > 0 ? (
        <>
          {riskActivities?.map((activity, index) => (
            <FadeUpWrapper
              key={index}
              delay={250 + 50 * (index + 1)}
              containerProps={{
                "data-testid": "merchant-risk-profile-activity-wrapper",
              }}
            >
              <ActivityCard
                data={activity}
                profileId={profileId}
                merchantId={merchantId}
                key={activity.factorID}
              />
            </FadeUpWrapper>
          ))}
          <div
            ref={targetRef}
            style={{
              width: 1,
              height: 22, // to be able to load next page
              paddingBottom: 5,
              visibility: "hidden",
            }}
          />
        </>
      ) : (
        <FadeUpWrapper delay={300}>
          <EmptyState />
        </FadeUpWrapper>
      )}
    </StyledBox>
  );
};

export default memo(RiskProfileActivity);

interface TabTextProps {
  active?: boolean;
}

const EmptyState = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="30%"
    mt="121px"
  >
    <CheckIconColored height={33} fill="#B8B8B8" width={56} />
    <Text color="#8F8F8F" fontSize="14px" fontWeight="book" mt="32px">
      No activity to check
    </Text>
  </Box>
);

const TabText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "active",
})<TabTextProps>(({ active }) => ({
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "400",
  padding: "4px 16px",
  borderRadius: "100px",
  ...(active
    ? {
        color: "#403D3D",
        backgroundColor: "#ECECE9",
      }
    : {
        color: "#8F8F8F",
      }),
}));

const StyledBox = styled(Box)({
  position: "relative",
  overflowY: "auto",
  overflowX: 'hidden',
  height: "calc(100% - 85px)",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
});
