import { useCallback, useEffect, useRef } from "react";

export const useGetOperatingSystem = () => {
  const operatingSystemTracker = useRef<
  "Windows Phone" | "Android" | "iOS" | undefined
>();
  const getOperatingSystem = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
  }, []);

  useEffect(() => {
    operatingSystemTracker.current = getOperatingSystem();
  }, []);

  return { getOperatingSystem, operatingSystemTracker };
};
