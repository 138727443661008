import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { useGetCurrentMerchantId } from "@hooks/common";
import { TRiskLevelName } from "@common/Tag/RiskStatusChip";

export const useListEnterpriseCategoryCodes = (parentId?: number) => {
  const { merchantId } = useGetCurrentMerchantId();
  const accountId = parentId || merchantId;

  const getEnterprise = (id: number) => {
    return customInstance({
      url: `merchants/${id}`,
      method: "GET",
    });
  };

  const { data, isLoading } = useQuery(
    ["list-enterprise-category-codes", accountId],
    async () => {
      const provider = await getEnterprise(accountId);
      return provider;
    },
    { refetchOnWindowFocus: false },
  );

  if (!data?.allowedCategoryCodes)
    return { categories: [], isLoading, slug: "" };

  const customCategories = data?.allowedCategoryCodes.map(
    ({ categoryCodes }: any) => ({
      id: categoryCodes.id,
      title: categoryCodes.title,
      code: categoryCodes.code,
      riskStatus: categoryCodes.merchantRiskStatus,
    }),
  );

  const {
    amexCreditCardFees,
    debitCardFees,
    creditCardFees,
    servicePhoneNumber,
  } = data;

  return {
    categories: customCategories as {
      title: string;
      id: string;
      code: string;
      riskStatus: TRiskLevelName;
    }[],
    slug: data.slug,
    feeConfig: data.feeConfig,
    amexCreditCardFees,
    debitCardFees,
    creditCardFees,
    isLoading,
    servicePhoneNumber,
  };
};
