import { CloseIcon } from "@assets/icons";
import { ArrowElbowDownLeft } from "@assets/rebrandIcons";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";

const websiteRegex = /^https:\/\/(www\.)?[a-zA-Z0-9]+(\.[a-zA-Z]{2,})+(\/.*)?$/;
const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const ipv6Regex = /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm;

const AllowedOriginsInput = () => {
  const { setValue, watch, setError } = useFormContext();
  const origins = watch("allowedOrigins");

  const handleAddOrigin = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if ((e.key === "Enter" || e.code === "Space") && !watch("origin")) return;

    if ([" ", "Enter"].includes(e.key)) {
      e.preventDefault();
      const value = watch("origin");
      if (origins?.includes(value)) {
        setError("origin", {
          message: "Already existing origin, Please provide a different one",
        });
        return;
      }

      if (!(websiteRegex.test(value) || ipv4Regex.test(value) || ipv6Regex.test(value))) {
        setError("origin", {
          message: "Please provide a valid Website or IP Address",
        });
        return;
      }

      const updatedOrigins = [value, ...origins];
      setValue("allowedOrigins", updatedOrigins);
      setValue("origin", "", { shouldValidate: true });
    }
  };

  const handleRemoveOrigin = (origin: string) => {
    const updatedOrigins = origins?.filter((o: string) => o !== origin);
    setValue("allowedOrigins", updatedOrigins);
  };

  return (
    <Stack spacing={0.5}>
      <RHFInput
        name="origin"
        label="Allowed origins (Optional)"
        placeholder="Allowed origins (Optional)"
        data-testid="api-key-allowed-origins-input"
        onKeyDown={handleAddOrigin}
        onKeyUp={handleAddOrigin}
        endIcon={
          <Stack direction="row" spacing={0.5}>
            <Text fontSize={12} color={palette.neutral[60]} fontWeight="book">
              Press
            </Text>
            <ArrowElbowDownLeft />
            <Text fontSize={12} color={palette.neutral[60]} fontWeight="book">
              to Add
            </Text>
          </Stack>
        }
        fullWidth
      />
      {origins && origins?.length > 0 && (
        <Stack direction="row" rowGap={0.5} columnGap={0.5} flexWrap="wrap">
          {origins?.map((origin: string) => (
            <OriginItem
              key={origin}
              text={origin}
              handleClose={() => handleRemoveOrigin(origin)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

type OriginItemProps = {
  text: string;
  handleClose: () => void;
};

const OriginItem = ({ text, handleClose }: OriginItemProps) => {
  return (
    <StyledOriginItem>
      <Text fontSize={12} color={palette.neutral[80]}>
        {text}
      </Text>
      <Box
        onClick={handleClose}
        display="inline-flex"
        sx={{ cursor: "pointer" }}
      >
        <CloseIcon height={15} width={15} stroke="#343330" />
      </Box>
    </StyledOriginItem>
  );
};

const StyledOriginItem = styled(Box)(() => ({
  display: "flex",
  gap: "4px",
  background: palette.neutral[10],
  borderRadius: "4px",
  padding: "2px 8px 2px 8px",
  alignItems: "center",
}));

export default AllowedOriginsInput;
