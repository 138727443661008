import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box } from "@mui/material";
import { StackedSquares } from "@assets/icons";
import { Tooltip } from "@common/Tooltip";
import { useCopyClipboard } from "@hooks/common/useCopyClipboard";
import { showMessage } from "@common/Toast";
import { useEffect, useState } from "react";

const Title = styled(Text)(() => ({
  fontSize: "18px",
  lineHeight: "21.6px",
  fontWeight: 350,
  letterSpacing: "-0.18px",
  color: palette.black[100],
}));

export const BrandingHeading = ({
  title,
  description,
  action,
}: {
  title: string;
  description: string;
  action?: React.ReactNode;
}) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack direction="column" gap="8px" alignItems="stretch">
      {!action ? (
        <Title>{title}</Title>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={isMobileView ? "space-between" : "flex-start"}
          gap="8px"
        >
          <Title>{title}</Title>
          {action}
        </Stack>
      )}
      <Text
        color={palette.gray[300]}
        fontSize="14px"
        fontWeight="book"
        lineHeight="16.8px"
      >
        {description}
      </Text>
    </Stack>
  );
};

type TAction = {
  onClick: () => void;
  disabled: boolean;
};

export const FormActions = ({
  primaryAction,
  secondaryAction,
}: {
  primaryAction: TAction;
  secondaryAction: TAction;
}) => {
  const { isMobileView } = useCustomTheme();
  return (
    <FadeUpWrapper delay={500}>
      <Stack
        direction={isMobileView ? "column-reverse" : "row"}
        alignItems="center"
        justifyContent={isMobileView ? "center" : "flex-end"}
        gap="20px"
      >
        <Button
          size={isMobileView ? "large" : "medium"}
          background="tertiary"
          onClick={secondaryAction.onClick}
          disabled={secondaryAction.disabled}
          sx={{ ...(isMobileView && { minWidth: "150px" }) }}
        >
          {isMobileView ? "Discard" : "Cancel"}
        </Button>
        <Button
          onClick={primaryAction.onClick}
          disabled={primaryAction.disabled}
          size={isMobileView ? "large" : "medium"}
          background="primary"
          sx={{ ...(isMobileView && { minWidth: "150px" }) }}
        >
          Save
        </Button>
      </Stack>
    </FadeUpWrapper>
  );
};

const CodeContainer = styled(Stack)(() => ({
  padding: "24px",
  borderRadius: "12px",
  backgroundColor: palette.liftedWhite[100],
  flexDirection: "row",
  gap: "8px",
  alignItems: "flex-start",
}));

const CopyButton = styled(Box)(() => ({
  width: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100px",
  "&:hover": {
    background: palette.liftedWhite[300],
    cursor: "pointer",
  },
}));

export const EmbeddedCode = ({ code }: { code: string }) => {
  const [copied, setCopied] = useState(false);
  const { copyToClipboard } = useCopyClipboard(() => undefined);

  useEffect(() => {
    if (!copied) return;

    const t = setTimeout(() => {
      setCopied(false);
    }, 5000);

    return () => clearTimeout(t);
  }, [copied]);
  return (
    <CodeContainer>
      <Text
        color={palette.black[200]}
        fontFamily="Oxygen Mono"
        fontSize="14px"
        lineHeight="16.8px"
        flexGrow={1}
        sx={{ wordBreak: "break-word" }}
      >
        {code}
      </Text>
      <Tooltip title={copied ? "Code copied" : "Copy Code"}>
        <CopyButton
          onClick={() => {
            copyToClipboard(code);
            setCopied(true);
          }}
          data-testid="copy-code-button"
        >
          <StackedSquares />
        </CopyButton>
      </Tooltip>
    </CodeContainer>
  );
};
