type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const EnvelopeIcon = ({
  width = 25,
  height = 25,
  stroke = "#8F8F8F",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M21.8203 9.26953L14.4562 14.5195H11.1844L3.82031 9.26953L12.8203 3.26953L21.8203 9.26953Z"
        fill={stroke}
      />
      <path
        d="M22.2366 8.64515L13.2366 2.64516C13.1133 2.56293 12.9685 2.51904 12.8203 2.51904C12.6722 2.51904 12.5273 2.56293 12.4041 2.64516L3.40406 8.64515C3.30132 8.7137 3.2171 8.80656 3.15888 8.91548C3.10065 9.02441 3.07023 9.14602 3.07031 9.26953V19.0195C3.07031 19.4174 3.22835 19.7989 3.50965 20.0802C3.79096 20.3615 4.17249 20.5195 4.57031 20.5195H21.0703C21.4681 20.5195 21.8497 20.3615 22.131 20.0802C22.4123 19.7989 22.5703 19.4174 22.5703 19.0195V9.26953C22.5704 9.14602 22.54 9.02441 22.4817 8.91548C22.4235 8.80656 22.3393 8.7137 22.2366 8.64515ZM12.8203 4.17047L20.4994 9.29016L14.2181 13.7695H11.4244L5.14313 9.29016L12.8203 4.17047ZM4.57031 19.0195V10.7255L10.7484 15.1317C10.8756 15.2226 11.0281 15.2714 11.1844 15.2714H14.4562C14.6126 15.2714 14.765 15.2226 14.8922 15.1317L21.0703 10.7255V19.0195H4.57031Z"
        fill={stroke}
      />
    </svg>
  );
};

export default EnvelopeIcon;
