import { useAppSelector } from "@redux/hooks";
import TransactionHeader from "./TransactionHeader";
import TransactionList from "./TransactionList";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import { selectFiltersObject } from "@redux/slices/dynamicFilterSlice";
import { useMemo } from "react";
import { formatQueryParamsList } from "@common/TableFilters/utils";

type Props = {
  profileId: number;
  merchantId: number;
};
function Transactions({ merchantId }: Props) {
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_RISK_MONITOR_TRANSACTIONS),
  );
  const formattedParams = useMemo(() => {
    const filter = formatQueryParamsList({
      emailStatus: filterObject?.emailStatus,
      riskLevel: filterObject?.riskLevel,
      createdAt: filterObject?.date,
      outFlow: filterObject?.outFlow?.value,
      inFlow: filterObject?.inFlow?.value,
      ...(filterObject?.isFalsePositive && {
        isFalsePositive: filterObject?.isFalsePositive,
      }),
      ...(filterObject?.isBlocked && { isBlocked: filterObject?.isBlocked }),
      txnDisplayStatus: filterObject?.txnDisplayStatus,
      ...(filterObject?.paymentMethod?.value && {
        cardInfoType: [filterObject?.paymentMethod],
      }),
    });

    return filter ? `${filter}` : "";
  }, [filterObject]);

  return (
    <>
      <TransactionHeader
        formattedParams={formattedParams}
        merchantId={merchantId}
      />
      <TransactionList
        formattedParams={formattedParams}
        merchantId={merchantId}
      />
    </>
  );
}

export default Transactions;
