import { Text } from "@common/Text";
import { Box, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";

type TStatItem = {
  label: string;
  value: string | number;
};

const StatCard = ({ items }: { items: TStatItem[] }) => {
  const firstRow = items.slice(0, 4);
  const secondRow = items.slice(4, items.length);
  return (
    <Wrapper>
      <StatCardRow items={firstRow} />
      {secondRow.length > 0 && (
        <>
          <Divider />
          <StatCardRow items={secondRow} />
        </>
      )}
    </Wrapper>
  );
};

const Divider = styled(Box)(() => ({
  height: "1px",
  borderRadius: "10px",
  background: palette.liftedWhite[100],
}));

const Wrapper = styled(Stack)(() => ({
  padding: "8px 16px",
  borderRadius: "4px",
  background: "#FBFBFB",
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
  flexDirection: "column",
  alignItems: "stretch",
  gap: "16px",
}));

const StatCardRow = ({ items }: { items: TStatItem[] }) => {
  return (
    <Grid container columnSpacing="8px">
      {items.map((item) => (
        <StatItem key={item.label} {...item} width={12 / items.length} />
      ))}
    </Grid>
  );
};

const StatItem = ({ label, value, width }: TStatItem & { width: number }) => {
  return (
    <Grid item xs={width}>
      <Stack direction="column" gap="2px">
        <Text
          color={palette.black[100]}
          fontSize="24px"
          fontWeight="book"
          lineHeight="32.4px"
          letterSpacing="-0.24px"
        >
          {value}
        </Text>
        <Text color={palette.gray[300]} fontWeight="book" lineHeight="16.8px">
          {label}
        </Text>
      </Stack>
    </Grid>
  );
};

export default StatCard;
