import React from "react";

function ArrowsCounterClockwise({
  width = 19,
  height = 19,
  fill = "#575353",
}: {
  width?: string | number;
  height?: string | number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00781 8.16992H3.63281C3.40904 8.16992 3.19442 8.08103 3.03619 7.92279C2.87796 7.76456 2.78906 7.54995 2.78906 7.32617V3.95117C2.78906 3.7274 2.87796 3.51278 3.03619 3.35455C3.19442 3.19632 3.40904 3.10742 3.63281 3.10742C3.85659 3.10742 4.0712 3.19632 4.22943 3.35455C4.38767 3.51278 4.47656 3.7274 4.47656 3.95117V4.9257C5.64727 3.77258 7.45008 2.54492 9.82031 2.54492C12.9464 2.54492 14.8378 4.40047 14.9173 4.47922C15.0737 4.6376 15.1612 4.85139 15.1607 5.074C15.1602 5.29662 15.0718 5.51002 14.9146 5.66771C14.7575 5.8254 14.5444 5.9146 14.3218 5.91588C14.0992 5.91716 13.8851 5.83041 13.7262 5.67453C13.6952 5.645 12.2109 4.23242 9.82031 4.23242C7.78125 4.23242 6.23859 5.47555 5.32031 6.48242H7.00781C7.23159 6.48242 7.4462 6.57132 7.60443 6.72955C7.76267 6.88778 7.85156 7.1024 7.85156 7.32617C7.85156 7.54995 7.76267 7.76456 7.60443 7.92279C7.4462 8.08103 7.23159 8.16992 7.00781 8.16992ZM16.0078 10.9824H12.6328C12.409 10.9824 12.1944 11.0713 12.0362 11.2296C11.878 11.3878 11.7891 11.6024 11.7891 11.8262C11.7891 12.0499 11.878 12.2646 12.0362 12.4228C12.1944 12.581 12.409 12.6699 12.6328 12.6699H14.3203C13.4062 13.6768 11.8594 14.9199 9.82031 14.9199C7.42969 14.9199 5.94539 13.5073 5.91445 13.4771C5.75552 13.3212 5.54142 13.2345 5.31881 13.2358C5.0962 13.237 4.88312 13.3262 4.72598 13.4839C4.56885 13.6416 4.4804 13.855 4.47991 14.0776C4.47942 14.3003 4.56692 14.514 4.72336 14.6724C4.80281 14.7519 6.69422 16.6074 9.82031 16.6074C12.1905 16.6074 13.9934 15.3798 15.1641 14.2266V15.2012C15.1641 15.4249 15.253 15.6396 15.4112 15.7978C15.5694 15.956 15.784 16.0449 16.0078 16.0449C16.2316 16.0449 16.4462 15.956 16.6044 15.7978C16.7627 15.6396 16.8516 15.4249 16.8516 15.2012V11.8262C16.8516 11.6024 16.7627 11.3878 16.6044 11.2296C16.4462 11.0713 16.2316 10.9824 16.0078 10.9824Z"
        fill={fill}
      />
    </svg>
  );
}

export default ArrowsCounterClockwise;
