import { customInstance } from "../index";
import { useQuery } from "react-query";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { ProductParams, useQueryFactory } from "./queryFactory";
import { buildMerchantEndpoints } from "../utils.api";

export const getMemberships = ({
  queryString,
  page,
  sorting,
  searchQuery,
}: ProductParams) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  return customInstance({
    url: buildMerchantEndpoints(
      `products?filter=typeName:"membership"${queryString}&sort=${
        sorting ?? "-id"
      }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    ),
    method: "GET",
  });
};

export const useGetMemberships = useQueryFactory("membership");

export const getProductById = (id: string) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}?filter=typeName:"membership"`),
    method: "GET",
  });
};

export const getMembershipVariants = (id: number) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}/variants`),
    method: "GET",
  });
};

export const useGetMembershipById = (id: string) => {
  return useQuery(
    ["get-membership-by-id", id],
    async () => {
      const data = await getProductById(id);
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false },
  );
};
