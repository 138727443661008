import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { BankItemHeader } from "@components/Merchants/MerchantPreview/components/BankAccounts/MerchantBankAccountItem";
import NiceModal from "@ebay/nice-modal-react";
import {
  DELETE_CONFIRMATION_MODAL,
  NEW_ACCOUNT_MODAL,
  UPLOAD_STATEMENT_MODAL,
} from "modals/modal_names";
import { useGetCurrentMerchantId } from "@hooks/common";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
} from "@constants/permissions";
import { composePermission } from "features/Permissions/AccessControl";
import { useAppSelector } from "@redux/hooks";
import { selectIsAllowedByResource } from "@redux/slices/accessControl/accessControl";
import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { palette } from "@palette";
import { Box } from "@mui/material";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import BankAccountDetails from "@components/Merchants/MerchantPreview/components/BankAccounts/BankAccountDetails";
import { DrawerBottomActions } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";
import { buildMerchantEndpoints } from "@services/api/utils.api";

const BankAccountDetailsModal = ({
  selectedAccount,
  open,
  onClose,
}: {
  selectedAccount?: IMerchantBankAccount;
  open: boolean;
  onClose: () => void;
}) => {
  const { merchantId } = useGetCurrentMerchantId();
  const { t } = useTranslation(namespaces.common);
  const id = selectedAccount?.id;

  const permission = composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.BANK_ACCOUNT,
  );
  const bankPermissions = useAppSelector((state) =>
    selectIsAllowedByResource(state, permission),
  );

  const isUpdateAllowed = bankPermissions.update === "allow";
  const isDeleteAllowed = bankPermissions.delete === "allow";

  const handleUploadStatement = () => {
    NiceModal.show(UPLOAD_STATEMENT_MODAL, { data: selectedAccount });
    onClose();
  };

  const handleEdit = () => {
    NiceModal.show(NEW_ACCOUNT_MODAL, {
      data: selectedAccount,
    });
    onClose();
  };

  const url = buildMerchantEndpoints(`bank-accounts/${id}`, merchantId);
  const handleDelete = () => {
    if (!id) return;
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "bank_account",
      itemName: selectedAccount?.name,
      url: url,
    });
  };

  const actions = [
    {
      label: t("buttons.UploadStatement", { ns: namespaces.common }),
      hidden: false,
      disabled: selectedAccount?.status !== "upload" || !isUpdateAllowed,
      onSelect: handleUploadStatement,
      tooltipProps: {
        show: !isUpdateAllowed,
        message: EDIT_DENY_MESSAGE,
      },
    },
    {
      label: "Edit",
      hidden: false,
      disabled: !isUpdateAllowed,
      onSelect: handleEdit,
      tooltipProps: {
        show: !isUpdateAllowed,
        message: EDIT_DENY_MESSAGE,
      },
    },
    {
      label: "Delete",
      hidden: false,
      disabled: !isDeleteAllowed,
      onSelect: handleDelete,
      labelProps: {
        sx: { color: palette.error.main },
      },
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  if (!selectedAccount) {
    onClose();
    return null;
  }

  return (
    <ModalFactory
      variant="dialog"
      renderMobile
      modalProps={{
        open,
        onClose,
      }}
    >
      <BankItemHeader
        sx={{ color: palette.neutral[80], mb: 3, fontSize: 18 }}
        {...selectedAccount}
      />
      <BankAccountDetails {...selectedAccount} />
      <Box height="150px" />

      <DrawerBottomActions
        sx={{
          "& .MuiPaper-root": {
            padding: 0,
            bottom: "100px",
          },
        }}
        label={
          <HorizontalMoreIcon
            height={41}
            width={41}
            fill={palette.background.dimmedWhite}
          />
        }
        items={actions}
        buttonSx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
    </ModalFactory>
  );
};

export default BankAccountDetailsModal;
