import { useEffect, useRef, useState } from "react";

const delay = 5000;
function useInternetStatus() {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }

    function offlineHandler() {
      timeoutRef.current = setTimeout(() => {
        setIsOnline(false);
        timeoutRef.current = null;
      }, delay);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    isOnline,
  };
}

export default useInternetStatus;
