// import React from "react";
import { palette } from "@palette";
import { useLocation, useNavigate } from "react-router-dom";
// mui
import { Box, Stack } from "@mui/material";
// components
import { Text } from "@common/Text";
import { showMessage } from "@common/Toast/ShowToast";
// redux
import { useAppDispatch } from "@redux/hooks";
import { setMasqueradeMode } from "@redux/slices/app";
// hooks
import { useMasquerade } from "@hooks/enterprise-api/merchants";

import { LogoutIconV3 } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { checkPortals } from "@utils/routing";

export const MASQUERADE_SNACKBAR_HEIGHT = 36;

const MasqueradeSnackbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const { deleteMasqueradeMutation } = useMasquerade();

  const {
    isMasqueradeMode,
    origin,
    name: merchantName,
  } = useMasqueradeReducer();
  const { isMerchantPortal, isEnterprisePortal } = checkPortals();

  const exitHandler = async () => {
    try {
      const historyControlMode: any[] | undefined =
        await deleteMasqueradeMutation.mutateAsync({});

      if (!historyControlMode?.[0]) return;
      const origin = historyControlMode[0].origin
      dispatch(
        setMasqueradeMode({
          id: "",
          name: "",
          origin,
          imgSrc: "",
          masqueradeUserRole: historyControlMode[0].role,
          historyControlMode: [],
        }),
      );
      navigate(origin);
    } catch (error: any) {
      const message = error.response?.data?.message;
      const errorCode = error.response?.data?.code;

      showMessage("Error", `${message}`);
      if (
        (errorCode === "resource_not_found" &&
          message === "Masquerade not in session.") ||
        (errorCode === "not_authorized" && message === "access not allowed")
      ) {
        dispatch(
          setMasqueradeMode({
            id: "",
            name: "",
            origin,
            imgSrc: "",
            masqueradeUserRole: "",
            historyControlMode: [],
          }),
        );
      }
    }
  };

  return (
    <>
      {isMasqueradeMode &&
        (isMerchantPortal || isEnterprisePortal || state?.preview) && (
          <Box sx={containerStyle} gap={5}>
            <Box sx={{ flex: 1, display: { xs: "none", sm: "block" } }}>
              <Box id="masquerade-portal" sx={{ marginLeft: "72px" }}></Box>
            </Box>

            <Stack
              direction="row"
              gap={{ xs: 1, sm: 3 }}
              alignItems="center"
              width="100%"
              sx={{
                margin: "0 auto",
                paddingX: { xs: 2.5, sm: 0 },
                justifyContent: { xs: "space-between", sm: "center" },
              }}
            >
              <Text sx={textStyle}>
                You are in control mode of <span>{merchantName}</span>
              </Text>

              <StyledIconButton
                onClick={exitHandler}
                disabled={deleteMasqueradeMutation.isLoading}
                startIcon={
                  <LogoutIconV3
                    height={20}
                    width={20}
                    fill={palette.neutral["70"]}
                  />
                }
              >
                <Text mt="1px" color={palette.neutral["70"]}>
                  Exit
                </Text>
              </StyledIconButton>
            </Stack>
          </Box>
        )}
    </>
  );
};

const containerStyle = {
  position: "fixed",
  left: "50%",
  height: `${MASQUERADE_SNACKBAR_HEIGHT}px`,
  transform: "translate(-50%, 0)",
  backgroundColor: palette.common.white,
  top: 0,
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&::after": {
    content: '""',
    flex: 1,
  },
};

const textStyle = {
  fontSize: "14px",
  color: palette.neutral["80"],
  lineHeight: "21.6px",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",

  "& span": {
    fontWeight: "600",
  },

  "@media (max-width: 600px)": {
    fontSize: 16,
    lineHeight: "22.4px",

    "& span": {
      fontWeight: "600",
    },
  },
};

export default MasqueradeSnackbar;
