import pl from "@assets/images/widgetImagePlaceholder.png";

const imageCardImageStyles = (state: any, cssProperties: any) => {
  return {
    borderColor: `${state.imageBorderColor.color}`,
    borderStyle: `${state.imageBorderStyle.value}`,
    borderRadius: `${state.imageBorderRadius}px`,
    borderWidth: `${state.imageBorderWidth}px`,
    opacity: state.opacity ? `${state.opacity / 100}` : "1",
    margin: "0",
    width: "100%",
    height: "300px",
    display: state.display,
    content: cssProperties?.imageSrc,
    objectFit: "cover",
    marginBottom: "24px",
  };
};

const imageCardTextStyles = (state: any, key: string) => {
  const colorStyles = state?.[key].textColor.color?.includes("linear")
    ? {
        backgroundImage: state?.[key].textColor.color,
        backgroundClip: "text",
        webkitTextFillColor: "transparent",
      }
    : {
        color: state?.[key].textColor.color,
        webkitTextFillColor: "unset",
      };
  return {
    fontFamily: state?.[key].fontFamily.value,
    fontWeight: state?.[key].fontWeight.value,
    color: state?.[key].textColor.color,
    fontSize: `${state?.[key].fontSize}px`,
    lineHeight: `${state?.[key].lineHeight}%`,
    letterSpacing: `${state?.[key].letterSpacing}px`,
    overflowWrap: "anywhere",
    marginTop: "8px",
    whiteSpace: "pre-wrap",
    ...colorStyles,
  };
};

export const imageCardImageBlueprint = (cssProperties?: any) => ({
  id: 15,
  bindings: {
    "style.content": "cssProperties.imageSrc",
    "style.borderRadius": "state.imageBorderRadius",
    "style.borderWidth": "state.imageBorderWidth",
    "style.borderStyle": "state.imageBorderStyle",
    "style.borderColor": "state.imageBorderColor",
    "metaAttributes.data-bgColorName": "state.imageBorderColor",
    "metaAttributes.borderStyle": "state.imageBorderStyle",
  },
  tag: "img",
  childrens: [],
  cssProperties,
  tabletCssProperties: { ...cssProperties },
  mobileCssProperties: { ...cssProperties },
  stateParser: ({
    state,
    cssProperties,
    tabletCssProperties,
    mobileCssProperties,
  }: any) => ({
    style: {
      ...imageCardImageStyles(state, cssProperties),
    },
    tabletStyle: {
      ...imageCardImageStyles(state, tabletCssProperties || cssProperties),
    },
    mobileStyle: {
      ...imageCardImageStyles(state, mobileCssProperties || cssProperties),
    },

    metaAttributes: {
      "data-bgColorName": state.imageBorderColor.name,
      "data-borderStyle": state.imageBorderStyle.label,
    },
    tabletMetaAttributes: {
      "data-bgColorName": state.imageBorderColor.name,
      "data-borderStyle": state.imageBorderStyle.label,
    },
    mobileMetaAttributes: {
      "data-bgColorName": state.imageBorderColor.name,
      "data-borderStyle": state.imageBorderStyle.label,
    },
    widgetType: "single_image",
  }),
});

export const imageCardHeadingBlueprint = (cssProperties?: any) => ({
  id: 16,
  tag: "h2",
  tabletTag: "h2",
  mobileTag: "h2",
  content: "Your subtitle here",
  childrens: [],
  cssProperties,
  tabletCssProperties: { ...cssProperties },
  mobileCssProperties: { ...cssProperties },
  bindings: {
    content: cssProperties
      ? "cssProperties.headingContent"
      : "state.heading.content",
    "$derived.style.marginTop": "state.heading.content",
    "style.fontFamily": "state.heading.fontFamily",
    "style.fontWeight": "state.heading.fontWeight",
    "style.color": "state.heading.textColor",
    "style.fontSize": "state.heading.fontSize",
    "style.lineHeight": "state.heading.lineHeight",
    "style.letterSpacing": "state.heading.letterSpacing",
    "metaAttributes.data-fontWeight": "state.heading.fontWeight.name",
    "metaAttributes.data-fontFamily": "state.heading.fontFamily.name",
    "metaAttributes.data-textColorName": "state.heading.textColor.name",
  },
  stateParser: ({
    state,
    cssProperties,
    tabletCssProperties,
    mobileCssProperties,
  }: any) => ({
    style: {
      ...imageCardTextStyles(state, "heading"),
      marginTop: state.heading?.content ? "8px" : "0px",
    },
    tabletStyle: {
      ...imageCardTextStyles(state, "heading"),
      marginTop: state.heading?.content ? "8px" : "0px",
    },
    mobileStyle: {
      ...imageCardTextStyles(state, "heading"),
      marginTop: state.heading?.content ? "8px" : "0px",
    },
    content: cssProperties?.headingContent ?? state.heading.content,
    tabletContent: tabletCssProperties?.headingContent ?? state.heading.content,
    mobileContent: mobileCssProperties?.headingContent ?? state.heading.content,
    metaAttributes: {
      "data-fontWeight": state.heading.fontWeight.name,
      "data-fontFamily": state.heading.fontFamily.name,
      "data-textColorName": state.heading.textColor.name,
    },
    tabletMetaAttributes: {
      "data-fontWeight": state.heading.fontWeight.name,
      "data-fontFamily": state.heading.fontFamily.name,
      "data-textColorName": state.heading.textColor.name,
    },
    mobileMetaAttributes: {
      "data-fontWeight": state.heading.fontWeight.name,
      "data-fontFamily": state.heading.fontFamily.name,
      "data-textColorName": state.heading.textColor.name,
    },
  }),
});

export const imageDescriptionBlueprint = (cssProperties?: any) => ({
  id: 17,
  tag: "p",
  tabletTag: "p",
  mobileTag: "p",
  content: "Your description here",
  childrens: [],
  cssProperties,
  tabletCssProperties: { ...cssProperties },
  mobileCssProperties: { ...cssProperties },
  bindings: {
    content: cssProperties
      ? "cssProperties.descriptionContent"
      : "state.description.content",
    "$derived.style.marginTop": "state.heading.content",
    "style.fontFamily": "state.description.fontFamily",
    "style.fontWeight": "state.description.fontWeight",
    "style.color": "state.description.textColor",
    "style.fontSize": "state.description.fontSize",
    "style.lineHeight": "state.description.lineHeight",
    "style.letterSpacing": "state.description.letterSpacing",
    "metaAttributes.data-fontWeight": "state.description.fontWeight.name",
    "metaAttributes.data-fontFamily": "state.description.fontFamily.name",
    "metaAttributes.data-textColorName": "state.description.textColor.name",
  },
  stateParser: ({
    state,
    cssProperties,
    tabletCssProperties,
    mobileCssProperties,
  }: any) => ({
    style: {
      ...imageCardTextStyles(state, "description"),
      marginTop: state.heading?.content ? "8px" : "0px",
    },
    tabletStyle: {
      ...imageCardTextStyles(state, "description"),
      marginTop: state.heading?.content ? "8px" : "0px",
    },
    mobileStyle: {
      ...imageCardTextStyles(state, "description"),
      marginTop: state.heading?.content ? "8px" : "0px",
    },
    content: cssProperties?.descriptionContent ?? state.description.content,
    tabletContent:
      tabletCssProperties?.descriptionContent ?? state.description.content,
    mobileContent:
      mobileCssProperties?.descriptionContent ?? state.description.content,
    metaAttributes: {
      "data-fontWeight": state.description.fontWeight.name,
      "data-fontFamily": state.description.fontFamily.name,
      "data-textColorName": state.description.textColor.name,
    },
    tabletMetaAttributes: {
      "data-fontWeight": state.description.fontWeight.name,
      "data-fontFamily": state.description.fontFamily.name,
      "data-textColorName": state.description.textColor.name,
    },
    mobileMetaAttributes: {
      "data-fontWeight": state.description.fontWeight.name,
      "data-fontFamily": state.description.fontFamily.name,
      "data-textColorName": state.description.textColor.name,
    },
  }),
});

export const cardItemsContainerBlueprint = () => ({
  id: 18,
  tag: "div",
  bindings: {
    "style.gridTemplateColumns": "state.gridTemplateColumns",
  },
  stateParser: ({ state }: any) => ({
    style: {
      display: "grid",
      gridTemplateColumns: `${
        state.gridTemplateColumns || "repeat(3, minmax(0, 1fr))"
      }`,
      gridColumnGap: "16px",
    },
    tabletStyle: {
      display: "grid",
      gridTemplateColumns: `${
        state.gridTemplateColumns || "repeat(1, minmax(0, 1fr))"
      }`,
      gridColumnGap: "16px",
    },
    mobileStyle: {
      display: "grid",
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      gridColumnGap: "16px",
    },
  }),
});

export const imageCardItemWrapper = (n: number, childrens: any[]) => ({
  id: 14,
  tag: "div",
  metaAttributes: {
    "data-imageTitle": `Image Card ${n}`,
  },
  styleParser: () => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
  }),
  childrens,
});

export const _disabled__imageCardImageBlueprint = (cssProperties?: any) => ({
  id: 15,
  tag: "div",
  bindings: {
    "style.backgroundImage": "cssProperties.imageSrc",
    "style.borderRadius": "state.imageBorderRadius",
    "style.borderWidth": "state.imageBorderWidth",
    "style.borderStyle": "state.imageBorderStyle",
    "style.borderColor": "state.imageBorderColor",
    "metaAttributes.data-bgColorName": "state.imageBorderColor",
    "metaAttributes.borderStyle": "state.imageBorderStyle",
  },
  childrens: [],
  cssProperties,
  stateParser: ({ state, cssProperties }: any) => ({
    style: {
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "300px",
      backgroundImage: cssProperties?.imageSrc ?? `url("${pl}")`,
      paddingLeft: "0",
      marginBottom: "16px",
      borderRadius: `${state.imageBorderRadius}px`,
      borderWidth: `${state.imageBorderWidth}px`,
      borderStyle: `${state.imageBorderStyle.value}`,
      borderColor: `${state.imageBorderColor.color}`,
    },
    metaAttributes: {
      "data-bgColorName": state.imageBorderColor.name,
      "data-borderStyle": state.imageBorderStyle.label,
    },
  }),
});
