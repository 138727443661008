import { showMessage } from "@common/Toast";
import { useGetCurrentMerchantId } from "@hooks/common";
import useSaveJson from "features/Permissions/Modal/api/useSaveJson";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { QKEY_LIST_TEAM_MEMBER_PERMISSIONS } from "@constants/queryKeys";

const useJSONEditor = (
  onAdd: (permissionIds: string[]) => void,
  memberId: number,
) => {
  const [value, setValue] = useState<string>("");
  const [disabled, setIsDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { merchantId } = useGetCurrentMerchantId();
  const client = useQueryClient();
  const { handleSendJson } = useSaveJson(merchantId, memberId);

  const onChange = (newValue: string) => setValue(newValue);

  const onValidation = (isValid: boolean) => {
    if (!isValid) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const onSubmit = async () => {
    if (!value) return;
    try {
      setIsLoading(true);
      const response = await handleSendJson(value);

      await client.invalidateQueries(QKEY_LIST_TEAM_MEMBER_PERMISSIONS);

      const ids = Array.isArray(response)
        ? response.map((res) => res.id as string)
        : [response.id as string];
      onAdd(ids);
    } catch (err) {
      showMessage("Error", "The JSON is not valid");
    }
    setIsLoading(false);
  };

  return {
    onChange,
    isDisabled: disabled || !value,
    onValidation,
    onSubmit,
    isLoading,
  };
};

export default useJSONEditor;
