import { namespaces } from "../../../resources/i18n.constants";

export const analytics = {
  [namespaces.pages.analytics]: {
    latestTransactions: "Latest Transactions",
    seeAll: "See All",
    Donations: "Donations",
    Events: "Events",
    Sweepstakes: "Sweepstakes",
    Memberships: "Memberships",
    Invoices: "Invoices",
    TotalContribution: "Total Contribution",
    Customers: "Customers",
    NoTransactionsYet: "No Transactions Yet",
    Analytics: "Analytics",
    Amount: "Amount",
    Visitors: "Visitors",
    Transactions: "Transactions",
    "Avg Amount": "Avg Amount",
    LaunchedOn: "Launched On",
    AllTime: "All Time",
    Daily: "Daily",
    Monthly: "Monthly",
    Raised: "Raised",
    Conversion: "Conversion",
    Average: "Average",
    TopCampaigns: "Top Campaigns",
    followTransaction:
      "Follow the transaction averages of last month or last day",
    avgTransaction: "Avg. Transaction",
    avgVisitiors: "Avg. Visitors",
    avgConversion: "Avg. Conversion",
    avgAmount: "Avg. Amount",
  },
};
