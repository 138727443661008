import React from "react";
import { useDroppable, UniqueIdentifier } from "@dnd-kit/core";

interface Props {
  children: React.ReactNode;
  id: UniqueIdentifier;
  data?: any;
}

export function Droppable({ children, id, data }: Props) {
  const { setNodeRef } = useDroppable({
    id,
    data,
  });

  if (!children) return null;

  return React.cloneElement(children as any, {
    ref: setNodeRef,
    "aria-label": "Droppable region",
  });
}
