import React from "react";
import { OwnershipTabsInput } from "@common/BusinessProfileInputs";
import { Box } from "@mui/material";
import { palette } from "@palette";

type Props = {
  isIndividualSoleProprietorship?: boolean;
  isFullyDisabled?: boolean;
  leType?: string;
};

export function OwnerShip({
  isIndividualSoleProprietorship,
  isFullyDisabled,
  leType,
}: Props) {
  return (
    <Box my={2} width="100%">
      <OwnershipTabsInput
        name="whenIsOwner.ownership"
        label="Custom percentage"
        placeholder="0-100"
        disabled={isIndividualSoleProprietorship}
        isFullyDisabled={isIndividualSoleProprietorship || isFullyDisabled}
        disableWithoutReset={isFullyDisabled}
        leType={leType}
        labelProps={{
          color: palette.neutral[80],
          fontSize: "18px",
          fontWeight: "regular",
          lineHeight: "120%",
          letterSpacing: "-0.18px",
        }}
      />
    </Box>
  );
}
