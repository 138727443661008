import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import {
  CREATE_SWEEPSTAKES_MODAL,
  DELETE_CONFIRMATION_MODAL,
  INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  SWEEPSTAKE_SUCCESS_MODAL,
} from "modals/modal_names";
import { showMessage } from "@common/Toast/ShowToast";
import { useMediaQuery, useTheme } from "@mui/material";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useFormData } from "@components/Sweepstakes/MiniBuilder/BuilderFormProvider";
import { setTicketList } from "@redux/slices/sweepstakes";
import { useAppDispatch } from "@redux/hooks";
import { editorStateToString } from "@utils/draft.editor.helpers";
import { formatVariantValue } from "@utils/index";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const toUnixDateFormat = (date: Date) => Math.floor(date.getTime() / 1000);

type useCreateSweepstakesProps = {
  id?: string;
};

export const useCreateSweepstakes = ({ id }: useCreateSweepstakesProps) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { formData } = useFormData();
  const submitForm = React.useRef<boolean>(false);
  const { isADBEnabled } = useGetFeatureFlagValues();
  const createProductMutation = useMutation((data: any) => {
    return customInstance({
      url: buildMerchantEndpoints(id ? `products/${id}` : "products"),
      method: id ? "PATCH" : "POST",
      data,
    });
  });

  const { isLoading, isSuccess } = createProductMutation;

  const url = buildMerchantEndpoints(`products/${id}`);
  const handleDelete = () => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "product",
      itemName: formData.general.title,
      productVariant: "sweepstake",
      url: url,
    });
    NiceModal.hide(CREATE_SWEEPSTAKES_MODAL);
  };

  React.useEffect(() => {
    if (submitForm.current) {
      onFormSubmit();
      submitForm.current = false;
    }
  }, [formData]);

  const getImageURL = (image: any) => {
    if (!image) return "";
    if (typeof image === "string") return image;
    return image?.URL;
  };

  const onFormSubmit = () => {
    if (formData.tickets.list.length < 1) {
      showMessage(
        "Error",
        "You need at least one ticket to create Sweepstakes",
        isDesktop,
        "Not enough tickets",
      );
      return;
    }

    const customTickets = formData.tickets.list.map(
      (ticket: any, index: number) => {
        return {
          name: ticket.name,
          price: formatVariantValue(ticket.amount),
          description: editorStateToString(ticket.description.text),
          imageURL: ticket.thumbnail || "",
          isEnabled: ticket.active,
          showAvailableVariants: ticket.display_tickets,
          inventory: parseInt(ticket.in_stock),
          bundle: 1,
          id: ticket.id,
          displayOrder: index,
        };
      },
    );

    const customData = {
      typeName: "sweepstake",
      name: formData.general.title,
      description: formData.general.description,
      canBrowseCampaigns: formData.general.browseMore,
      endsAt: toUnixDateFormat(new Date(formData.general.date)),
      imageURL: formData.general.featuredImage.active
        ? getImageURL(formData.general.featuredImage.image)
        : "",
      usesBackgroundImage: formData.general.featuredImage.useAsBackground,
      needsTax: formData.tickets.customer_pays_credit.active,
      allowFeeChoice: formData.tickets.customer_pays_credit.optional,
      maxPurchaseQuantity: +formData.tickets.max_tickets,
      allowMultipleWinners: formData.tickets.multiple_winners,
      maxEntriesPerPrize: formData.tickets.set_entries_limit.active
        ? +formData.tickets.set_entries_limit.max_entries
        : null,
      variants: customTickets,
      recurringIntervals: [
        {
          interval: "once",
        },
      ],
      defaultRecurringInterval: "once",
    };

    createProductMutation.mutate(customData, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data;
        console.log("errorMessage = ", errorMessage);
      },
      onSuccess: async (res: any) => {
        if (id) {
          const allVariants = await customInstance({
            url: buildMerchantEndpoints(`products/${id}/variants`),
            method: "GET",
          });
          for (const ticket of customTickets) {
            if (typeof ticket.id === "string") {
              await customInstance({
                url: buildMerchantEndpoints(`products/${id}/variants`),
                method: "POST",
                data: ticket,
              });
            } else {
              await customInstance({
                url: buildMerchantEndpoints(
                  `products/${id}/variants/${ticket.id}`,
                ),
                method: "PATCH",
                data: ticket,
              });
            }
          }
          if (customTickets.length < allVariants?.total) {
            allVariants?.data.forEach(async (variant: any) => {
              if (
                customTickets.findIndex((v: any) => v.id === variant.id) !== -1
              )
                return;
              await customInstance({
                url: buildMerchantEndpoints(
                  `products/${id}/variants/${variant.id}`,
                ),
                method: "DELETE",
              });
            });
          }
        }

        NiceModal.hide(CREATE_SWEEPSTAKES_MODAL);
        dispatch(setTicketList([]));

        if (id) {
          showMessage(
            "Info",
            <>
              Changes on{" "}
              <span style={{ fontWeight: "600" }}>
                “{formData.general.title}”
              </span>{" "}
              have been successfully saved
            </>,
            isDesktop,
            "Changes Saved",
          );
          queryClient.refetchQueries("find-sweepstake-by-id");
          queryClient.refetchQueries("get-sweepstake-by-id");
        } else {
          isADBEnabled
            ? NiceModal.show(INTRODUCE_ADVANCED_FORM_BUILDER_MODAL, {
                productId: res?.id,
                data: {
                  about: {
                    title: customData.name,
                    description: customData.description,
                  },
                  style: {
                    useAsBackground: customData.usesBackgroundImage,
                    image: {
                      URL: customData.imageURL,
                    },
                  },
                  campaign: customData.typeName,
                } as any,
              })
            : NiceModal.show(SWEEPSTAKE_SUCCESS_MODAL, {
                name: formData.general.title,
              });
        }
        queryClient.refetchQueries("list-all-sweepstakes");
      },
    });
  };

  return { handleDelete, submitForm, isDisabled: isLoading || isSuccess };
};
