import { useModal } from "@ebay/nice-modal-react";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { checkPortals } from "@utils/routing";
import {
  createBusinessProfileSchema,
  createPrimaryAccountHolderSchema,
  defaultValues,
  getCreateMerchantInfoSchema,
  invitePrimaryAccountHolderSchema,
} from "../schemas/CreateMerchantSchema";
import { TCreateMerchant } from "../types";
import {
  IMerchantBankAccount,
  TBusinessOwner,
} from "@components/Merchants/MerchantPreview/data.types";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { toUnixDateFormat } from "@utils/date.helpers";
import { findBankByRoutingNumber } from "@utils/bank_list";
import { renameBEBankAccountStatus } from "@components/Merchants/MerchantPreview/helpers/bankAccounts";
import { getMerchantErrorMessage, getMerchantErrorParam } from "../helpers";
import { parseAmountValue } from "@components/Merchants/CreateMerchant/utils/functions";
import { TMerchantAgreementPayload } from "./useAddMerchantAgreement";
import { createBusinessOwner } from "@services/api/business-owners";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { selectUser } from "@redux/slices/auth/auth";
import { SignupType } from "@hooks/onboarding/utils";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { removeSpecialChars } from "@utils/slug";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";
import { generateBillingDescriptor } from "@utils/helpers";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useUploadSingleImage as useUploadSingleImageOld } from "@hooks/merchant-api/image-gallery/useUploadSingleImage";
import { useUploadSingleImage as useUploadSingleImageNew } from "@hooks/upload-api/uploadHooks";

type payloadType = {
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  email: string;
  ownershipPercentage: number | null;
  dateOfBirth: number;
  useEntityGeoAddress?: boolean;
  identityProof?: string[];
  ein?: string;
  taxIDNumber?: string;
  address?: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
};

const isAddressObjectFilled = (obj: { [key: string]: string }) =>
  Object.values(obj).every(Boolean);
function updateElementById(
  array: any[],
  id: string | number,
  updatedData: any,
) {
  // Find the index of the element with the specified id
  const index = array.findIndex(
    (item) => item.id?.toString() === id?.toString(),
  );

  // Check if the element with the specified id was found
  if (index !== -1) {
    // Update the element with the new data
    array[index] = { ...array[index], ...updatedData };
  }
  return array;
}
export const useCreateMerchant = () => {
  const queryClient = useQueryClient();
  const modal = useModal();
  const { isEnterprisePortal } = checkPortals();
  const { merchantId } = useGetCurrentMerchantId();
  const { isDesktopView } = useCustomTheme();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  const { handleUpload: handleUploadNew, isLoading: imageLoadingNew } =
    useUploadSingleImageNew();
  const { handleUpload: handleUploadOld, isLoading: imageLoadingOld } =
    useUploadSingleImageOld();
  const handleUpload = isFileUploadRefactorEnabled
    ? handleUploadNew
    : handleUploadOld;
  const imageLoading = isFileUploadRefactorEnabled
    ? imageLoadingNew
    : imageLoadingOld;

  const auth = useSelector(selectUser);
  const { isAcquirerPortal } = checkPortals();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const merchantInfoSchema = getCreateMerchantInfoSchema(isAcquirerPortal);

  const { modify_merchant, manage_bank_account, money_transfers } =
    useEnterprisePermissions();
  const primaryAccountHolderSchema = !modify_merchant
    ? invitePrimaryAccountHolderSchema
    : createPrimaryAccountHolderSchema;

  const schema = Yup.object({
    merchantInfo: Yup.object(merchantInfoSchema),
    primaryAccountHolder: Yup.object(primaryAccountHolderSchema),
    businessProfile: Yup.object(createBusinessProfileSchema),
  });

  const methods = useForm<TCreateMerchant>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue, watch } = methods;
  const values = watch();

  const {
    handleUploadSignature: handleUploadSignatureNew,
    isLoading: isFileUploadLoadingNew,
  } = useAddSignatureNew();
  const {
    handleUploadSignature: handleUploadSignatureOld,
    isLoading: isFileUploadLoadingOld,
  } = useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const isFileUploadLoading = isFileUploadRefactorEnabled
    ? isFileUploadLoadingNew
    : isFileUploadLoadingOld;
  const { data: provider } = useGetMerchantById({
    merchantId: watch("merchantInfo.enterpriseID") || merchantId,
  });

  const handleCloseBusinessOwnerModal = (
    data?: TBusinessOwner,
    id?: string,
  ) => {
    if (id && isEmpty(data)) {
      // remove
      setValue(
        "businessOwners",
        values.businessOwners.filter(
          (owner) => owner.id?.toString() !== id?.toString(),
        ),
      );
      return;
    }
    if (!data) return;

    const existedOwner = values.businessOwners.find(
      (owner) => owner.id === data.id,
    );
    if (existedOwner) {
      // create
      const updatedOwners = values.businessOwners.map((owner) => {
        if (owner.id === data.id) return data;
        return owner;
      });
      setValue("businessOwners", updatedOwners);
    } else if (id) {
      setValue(
        "businessOwners",
        updateElementById(values.businessOwners, id, data),
      );
    } else if (!id && data) {
      setValue("businessOwners", [...values.businessOwners, data]);
    }
  };

  const handleCloseBankAccountModal = (
    data?: IMerchantBankAccount,
    id?: number,
  ) => {
    if (id) {
      // remove
      setValue(
        "bankAccounts",
        values.bankAccounts.filter((account) => account.id !== id),
      );
      return;
    }
    if (!data) return;

    const existedAccount = values.bankAccounts.find(
      (account) => account.id === data.id,
    );
    if (existedAccount) {
      // create
      const updatedAccounts = values.bankAccounts.map((account) => {
        if (account.id === data.id) return data;
        return account;
      });
      setValue("bankAccounts", updatedAccounts);
    } else {
      // edit
      setValue("bankAccounts", [...values.bankAccounts, data]);
    }
  };

  const handleCloseMerchantAgreement = (data: any) => {
    const { msaPreviousTerminationAt, ...rest } = data;
    const customData: TMerchantAgreementPayload = {
      msaRefundPolicy: "30 Days of Purchase",
      msaPCICompliant: true,
      msaPreviousTermination: false,
      msaPreviousTerminationProcessorName: "",
      msaPreviousTerminationReason: "",
      msaPreviousTerminationAt: msaPreviousTerminationAt
        ? toUnixDateFormat(new Date(msaPreviousTerminationAt))
        : Math.floor(new Date().getTime() / 1000),
      msaHadAgreed: false,
      ...rest,
    };
    setValue("merchantAgreement", customData);
  };

  const createMerchantMutation = useMutation((data: any) => {
    return customInstance({
      url: "merchants",
      method: "POST",
      data,
    });
  });

  const createPrincipalMutation = useMutation(
    ({ data, merchantId, legalEntityId }: any) =>
      createBusinessOwner(legalEntityId, data, merchantId),
  );

  const { isLoading } = createMerchantMutation;

  const onSubmit: SubmitHandler<TCreateMerchant> = async (data) => {
    setIsApiLoading(true);
    const legalEntityAddress = {
      line1: data?.businessProfile?.address?.address,
      city: data?.businessProfile?.address?.city,
      state: data?.businessProfile?.address?.state,
      zip: data?.businessProfile?.address?.zip,
      country: data?.businessProfile?.address?.country,
    };

    let image = null;
    if (data.merchantInfo.thumbnail) {
      image = await handleUpload(
        isFileUploadRefactorEnabled
          ? { file: data.merchantInfo.thumbnail }
          : (data.merchantInfo.thumbnail as any),
      );
    }

    if (!image) image = null;

    const customBusinessOwners = data.businessOwners
      .filter((owner) => !owner.linked)
      .map((owner) => {
        const baseObj = {
          firstName: owner.firstName,
          lastName: owner.lastName,
          phoneNumber: owner.phone
            ? owner.phone.slice(1)
            : data.businessProfile?.contactPhone?.slice(1) ?? null,
          email: owner.email,
          ownershipPercentage: owner.ownership ? +owner.ownership : null,
          dateOfBirth: toUnixDateFormat(new Date(owner.dob)),
          ...(!owner.isBusinessAddress &&
            owner.address && {
              address: {
                line1: owner.address.line1,
                line2: "",
                city: owner.address.city,
                state: owner.address.state,
                zip: owner.address.zip,
                country: owner.address.country,
              },
            }),
          idImageUrl: owner.idImageUrl,
          useEntityGeoAddress: owner.isBusinessAddress,
          identityProof: owner.identityProof || [],
          citizenship: owner.isNotUSResident ? owner.citizenship : "",
          countryOfResidence: owner.isNotResidentInCitizenshipCountry
            ? owner.countryOfResidence
            : "",
        } as payloadType;

        if (owner.identityProof?.length) {
          baseObj.identityProof = owner.identityProof;
        }

        if (owner.ein) {
          baseObj.ein = owner.ein;
        } else if (owner.ssn) {
          baseObj.taxIDNumber = owner.ssn;
        }

        return baseObj;
      });

    const customBankAccounts = data.bankAccounts.map((bankAccount) => ({
      name: bankAccount.name,
      type: bankAccount.type,
      routingNumber: bankAccount.routingNumber,
      number: bankAccount.accountNumber,
      notes: bankAccount.notes,
      status: renameBEBankAccountStatus(bankAccount.status),
      statements: bankAccount.statements,
      bankName: findBankByRoutingNumber(bankAccount.routingNumber),
      isDefault: bankAccount?.isDefault || false,
    }));
    const signatureFile = data?.merchantAgreement?.signatureFile;

    const customData = {
      signupType: isAcquirerPortal
        ? SignupType.ACQUIRER_CREATED
        : SignupType.ENTERPRISE_CREATED,
      type: "submerchant",
      averageTicketAmount:
        parseAmountValue(data?.merchantInfo?.averageTicketAmount) * 100,
      highTicketAmount:
        parseAmountValue(data.merchantInfo.highTicketAmount) * 100,
      annualCreditCardSalesVolume:
        parseAmountValue(data.merchantInfo.estimatedAnnualRevenue) * 100,
      serviceCountriesOutUSCanada:
        data.merchantInfo.serviceCountriesOutUSCanada,
      countriesServicedOutside: data.merchantInfo.countriesServicedOutside
        ? data.merchantInfo.countriesServicedOutside
        : null,
      businessPurpose: data.merchantInfo.businessPurpose,
      inviteOwner:
        data.primaryAccountHolder?.assignToMe ||
        data.primaryAccountHolder.invite ||
        auth.email === data.primaryAccountHolder.email ||
        !modify_merchant,
      categoryCodeID: +data.merchantInfo.category,
      parentAccID: +data.merchantInfo.enterpriseID || merchantId,
      name: data.merchantInfo.merchantName,
      slug: data.merchantInfo.merchantSlug
        ? data.merchantInfo.merchantSlug
        : removeSpecialChars(
            data?.merchantInfo?.merchantName,
            SLUG_MAX_CHARACTER_LENGTH,
          ),
      class: data.merchantInfo.classification
        ? data.merchantInfo.classification
        : undefined,
      classType: data.merchantInfo.classification
        ? "accept_payments"
        : undefined,
      description: btoa(
        unescape(encodeURIComponent(data.merchantInfo.description)),
      ),
      imageURL: image,
      ...(data.merchantInfo.websiteURL && {
        websiteURL: "https://" + data.merchantInfo.websiteURL,
      }),
      billingDescriptor: data.merchantInfo.billingDescriptor
        ? data.merchantInfo.billingDescriptor.toUpperCase()
        : generateBillingDescriptor(
            data?.merchantInfo?.merchantName,
          )?.toUpperCase(),
      ...(data.businessProfile.isLinkBusinessProfile &&
        data.businessProfile.linkedBusinessProfile && {
          legalEntityID: data.businessProfile.linkedBusinessProfile,
        }),
      ...(!data.businessProfile.isLinkBusinessProfile &&
        (data.businessProfile?.taxID || data?.businessProfile?.ssn) && {
          legalEntity: {
            hasAcceptedCreditCards: true,
            type: data.businessProfile.businessType,
            name: data.businessProfile.legalName,
            doingBusinessAs: data.businessProfile.DBA,
            taxIDNumber: data.businessProfile?.taxID || "",
            ssn: data?.businessProfile?.ssn || "",
            tinType: data.businessProfile.tinType,
            phoneNumber: data.businessProfile.contactPhone
              ? data.businessProfile.contactPhone.slice(1)
              : null,
            estMonthlyVolume: 0,
            allowMultipleMerchants: true,
            ownershipType: data.businessProfile.ownershipType,
            contact: null,
            ...(data.businessProfile.businessOpenedAt && {
              businessOpenedAt: toUnixDateFormat(
                new Date(data.businessProfile.businessOpenedAt),
              ),
            }),
            address: legalEntityAddress,
            principals: customBusinessOwners,
          },
        }),
      bankAccounts: customBankAccounts,
      ...((data.primaryAccountHolder.email ||
        data.primaryAccountHolder.assignToMe) && {
        owner: {
          email: data.primaryAccountHolder.assignToMe
            ? auth.email
            : data.primaryAccountHolder.email,
          password: "SADWQW#jojij545f", // TODO: remove password after fixing BE requirements
        },
      }),
      ...(isAddressObjectFilled(legalEntityAddress) && {
        address: legalEntityAddress,
      }),
      ...(modify_merchant && {
        servicePhoneNumber:
          data.merchantInfo.servicePhoneNumber ||
          provider?.servicePhoneNumber ||
          null,
      }),
      creditCardFees: 0,
      amexCreditCardFees: 0,
      debitCardFees: 0,
      allowAddingBankAccounts: manage_bank_account,
      submerchantAllowedToTransfer: money_transfers,
      msaRefundPolicy: data.merchantAgreement.msaRefundPolicy,
      msaPCICompliant: signatureFile
        ? true
        : data.merchantAgreement.msaPCICompliant,
      msaPreviousTermination: data.merchantAgreement.msaPreviousTermination,
      msaHadAgreed: signatureFile ? true : data.merchantAgreement.msaHadAgreed,

      ...(data.merchantAgreement.msaPreviousTermination && {
        msaPreviousTerminationProcessorName:
          data.merchantAgreement.msaPreviousTerminationProcessorName,
        msaPreviousTerminationReason:
          data.merchantAgreement.msaPreviousTerminationReason,
        msaPreviousTerminationAt: toUnixDateFormat(
          data.merchantAgreement.msaPreviousTerminationAt as Date,
        ),
      }),
    };

    createMerchantMutation.mutate(customData, {
      onError: (error: any) => {
        if (error?.response?.data.input) {
          const input = error?.response?.data.input[0];
          showMessage(
            "Error",
            getMerchantErrorMessage(
              input?.param,
              input?.code,
              input?.message,
            ) || `${getMerchantErrorParam(input?.param)} is not valid`,
          );
          return;
        }
        if (error?.response?.data?.message) {
          showMessage("Error", error?.response?.data?.message);
        }
      },
      onSuccess: async (createdMerchant) => {
        const signatureFile = data?.merchantAgreement?.signatureFile;
        if (signatureFile) {
          await handleUploadSignature(
            createdMerchant.accID,
            signatureFile,
            true,
            { msaHadAgreed: true },
          );
        }

        if (
          data.businessProfile.isLinkBusinessProfile &&
          data.businessProfile.linkedBusinessProfile &&
          customBusinessOwners?.length > 0
        ) {
          const reqs = customBusinessOwners.map((owner) =>
            createPrincipalMutation.mutateAsync({
              data: owner,
              merchantId: createdMerchant.accID,
              legalEntityId: data.businessProfile.linkedBusinessProfile,
            }),
          );
          await Promise.race(reqs).catch((error: any) => {
            showMessage(
              "Error",
              `Add principal: ${error?.response?.data?.message}`,
            );
          });
        }

        modal.hide();

        await queryClient.refetchQueries("list-all-merchants");
        await queryClient.refetchQueries("list-acquirer-merchants");
        await queryClient.refetchQueries("list-enterprise-merchants");
        queryClient.removeQueries("find-merchant-by-id");
        await queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
        await queryClient.refetchQueries(["list-business-owners", merchantId]);

        showMessage(
          "Success",
          <>
            Merchant{" "}
            <span style={{ fontWeight: "600" }}>
              “{data.merchantInfo.merchantName}”
            </span>{" "}
            has been successfully created
          </>,
          isDesktopView,
          "Merchant Created",
        );
      },
      onSettled: () => {
        setIsApiLoading(false);
      },
    });
  };

  useEffect(() => {
    if (modal.visible) {
      methods.reset(defaultValues);
    }
  }, [modal.visible]);

  useEffect(() => {
    if (isEnterprisePortal && modal.visible && provider) {
      setValue("merchantInfo.enterpriseID", provider.accID);
      setValue("merchantInfo.enterpriseSlug", provider.slug);
      setValue("merchantInfo.enterprisePhone", provider.servicePhoneNumber);
      if (provider?.allowedCategoryCodes?.length > 0) {
        setValue(
          "merchantInfo.category",
          provider.allowedCategoryCodes[0].categoryCodes.id,
        );
      }
    }
  }, [isEnterprisePortal, modal.visible, provider]);

  return {
    modal,
    handleClose: () => {
      methods?.reset(defaultValues);
      modal.hide();
    },
    methods,
    schema,
    onSubmit,
    handleCloseBusinessOwnerModal,
    handleCloseBankAccountModal,
    handleCloseMerchantAgreement,
    isLoading:
      isLoading ||
      imageLoading ||
      createPrincipalMutation.isLoading ||
      isFileUploadLoading ||
      createMerchantMutation?.isLoading ||
      isApiLoading,
  };
};
