import { Box, Stack, styled } from "@mui/material";
import {
  ChargedAmountCell,
  TCardInformationProps,
  TEmailInformationProps,
  TransactionDate,
  MerchantName,
  CardAndEmailInformation,
  RiskDeEscalation,
} from "./atoms";
import { TTransactionStatusData } from "features/TransactionsRiskProfile/data.types";
import { TransactionStatus } from "./TransactionStatus";

type TBase =
  | "charged"
  | "status"
  | "date"
  | "merchantName"
  | "cardAndEmail"
  | "riskDeEscalation";

type TransactionItemCellMap = {
  charged: number;
  status: TTransactionStatusData;
  date: Date;
  merchantName: string;
  cardAndEmail: {
    card: TCardInformationProps;
    email: TEmailInformationProps;
  };
  riskDeEscalation: {
    triggerReason: string;
    risklevel: number;
  };
};

export type TransactionItemCell = {
  [K in keyof TransactionItemCellMap]: {
    name: K;
    data: TransactionItemCellMap[K];
    customStyle?: any;
  };
}[keyof TransactionItemCellMap];

const Cell: Record<TBase, any> = {
  charged: ChargedAmountCell,
  status: TransactionStatus,
  date: TransactionDate,
  merchantName: MerchantName,
  cardAndEmail: CardAndEmailInformation,
  riskDeEscalation: RiskDeEscalation,
};

const getCellWidth = (name: TBase) => {
  switch (name) {
    case "date":
      return 10;
    case "cardAndEmail":
      return 34;
    case "merchantName":
      return 17.5;
    case "charged":
      return 15;
    case "status":
      return 17.5;
    case "riskDeEscalation":
      return 90;
    default:
      return 30;
  }
};

const TransactionListItem = ({
  id,
  merchantId,
  cells,
  isStack = false,
  onClick,
  isDeEscalation,
}: {
  id: string;
  merchantId: number;
  cells: TransactionItemCell[];
  isStack?: boolean;
  onClick?: () => void;
  isDeEscalation: boolean;
}) => {
  return (
    <Box position="relative" paddingBottom={isStack ? "20px" : 0}>
      <ItemWrapper
        isStack={isStack}
        isDeEscalation={isDeEscalation}
        onClick={onClick}
        data-testid="possible-deescalation-item"
      >
        {cells?.map(({ name, data, customStyle }) => {
          const Component = Cell[name];

          return (
            <Component
              key={name}
              data={data}
              width={getCellWidth(name)}
              merchantId={merchantId}
              id={id}
              customStyle={customStyle ? { ...customStyle } : undefined}
            />
          );
        })}
      </ItemWrapper>
      {isStack && (
        <>
          <StackLayer isFirst data-testid="stack-layer" />
          <StackLayer />
        </>
      )}
    </Box>
  );
};

const StackLayer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isFirst",
})<{ isFirst?: boolean }>(({ isFirst }) => ({
  width: isFirst ? "96%" : "92%",
  height: "20px",
  position: "absolute",
  left: isFirst ? "2%" : "4%",
  zIndex: isFirst ? 10 : 5,
  bottom: isFirst ? 10 : 0,
  borderRadius: "3.915px",
  background: "#FBFBFB",
  boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
}));

const ItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isStack",
})<{ isStack: boolean; isDeEscalation: boolean }>(
  ({ isStack, isDeEscalation }) => ({
    position: "relative",
    padding: "12px 24px",
    borderRadius: "4px",
    background: "#FBFBFB",
    boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",
    zIndex: 15,
    ...(isStack && {
      "&:hover": {
        cursor: "pointer",
      },
    }),
    ...(isDeEscalation && {
      background: "#6D9CF826",
      color: "#326EC5",
    }),
  }),
);

export default TransactionListItem;
