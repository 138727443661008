import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const PaperLightIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = "#A9AFBD";
  return (
    <svg
      fill="none"
      height={`${height}`}
      viewBox="0 0 24 25"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 3.48463V3.48463C19.6569 3.48463 21 4.82777 21 6.48463L21 8.62748C21 8.9616 21 9.12867 20.9234 9.25063C20.8834 9.31424 20.8296 9.36802 20.766 9.40798C20.644 9.48462 20.477 9.48462 20.1429 9.48462L15 9.48462M18 3.48463V3.48463C16.3431 3.48463 15 4.82777 15 6.48462L15 9.48462M18 3.48463L7 3.48463C5.11438 3.48463 4.17157 3.48463 3.58579 4.07041C3 4.6562 3 5.59901 3 7.48463L3 21.4846L6 20.4846L9 21.4846L12 20.4846L15 21.4846L15 9.48462"
        stroke={stroke}
        strokeWidth="2"
      ></path>
      <path
        d="M7 7.48462L11 7.48462"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2"
      ></path>
      <path
        d="M8 11.4846H7"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2"
      ></path>
      <path
        d="M7 15.4846L10 15.4846"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="2"
      ></path>
    </svg>
  );
};
export default PaperLightIcon;
