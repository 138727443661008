import useMasqueradeReducer from "../Reducers/useMasqueradeReducer";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { useAppSelector } from "@redux/hooks";

export const useGetUserRole = () => {
  const { isMasqueradeMode } = useMasqueradeReducer();

  const selectedAccount = useAppSelector(selectSelectedAccount);

  const userRole = isMasqueradeMode ? "owner" : selectedAccount?.userRole;

  const canManageConversations = ![
    "sales_associate",
    "customer_support",
    "sales_executive",
  ].includes(userRole || "");

  return {
    userRole,
    canManageConversations,
  };
};
