import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import {
  TFileAttachmentType,
  UploadProgressParams,
} from "./hooks/useUploadFiles";
import { QueryKey } from "react-query";

export enum FileUploadStatus {
  UPLOADED = "Uploaded!",
  IN_PROGRESS = "Uploading...",
  FILE_NOT_SUPPORTED = "File type not supported",
  FILE_TOO_LARGE = "File too large",
  FAILED = "Failed",
  DELETED = "Deleted",
}

export interface SnackbarFile {
  id: string;
  name: string;
  size: number;
  status: string;
  // This will contain the progress of request
  uploadProgress: number;
  identifier?: number | string; // file id in db or url of the file
  accountToRequest?: number; // account id to make api call to delete file after uploaded
  canBeDeleted: boolean;
  refetcherKeyOnDelete?: QueryKey;
}

export type TSnackbarFiles = {
  fileWithMeta: IFileWithMeta;
  status: StatusValue;
  allFiles: IFileWithMeta[];
  attachmentType?: TFileAttachmentType;
  label?: string;
  tag?: string;
  merchantId?: number;
  resourceID?: number;
  isCustomerUpload?: boolean;
  isSignatureUpload?: boolean;
  customFinish?: ({
    identifiers,
  }: {
    identifiers: (number | string)[];
  }) => void;
  customFailed?: ({ fileIds }: { fileIds: string[] }) => void;
  onSuccess?: () => void;
  refetcherKeyOnDelete?: QueryKey;
  isDeleteAllowed?: boolean;
};
