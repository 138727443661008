interface IconProps {
    height?: number;
    width?: number;
    fill?: string;
  }
  
  const CheckRequirementsIcon = ({
    width = 16,
    height = 13,
    fill ="#C7C5CE",
  }: IconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5ZM9.6276 12.8841L13.7943 7.88411L12.8724 7.11589L9.12635 11.6112L7.09093 9.57574L6.2424 10.4243L8.7424 12.9243L9.20699 13.3888L9.6276 12.8841Z" fill={fill}/>
        </svg>
        
    );
  };
  
  export default CheckRequirementsIcon;
  