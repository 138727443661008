import { SvgIconProps } from "@mui/material";

function CaretDown({
  width = 18,
  height = 18,
  fill = "#8F8F8F",
  rotate = 0,
}: SvgIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 10"
      fill="none"
      style={{
        transform: `rotate(${rotate}deg)`,
        transition: "transform 0.3s ease-in-out",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0306 2.03062L9.53055 9.53061C9.4609 9.60035 9.37818 9.65567 9.28713 9.69341C9.19608 9.73115 9.09849 9.75058 8.99993 9.75058C8.90137 9.75058 8.80377 9.73115 8.71272 9.69341C8.62168 9.65567 8.53896 9.60035 8.4693 9.53061L0.969304 2.03062C0.828573 1.88988 0.749512 1.69901 0.749512 1.49999C0.749512 1.30097 0.828573 1.11009 0.969304 0.969364C1.11003 0.828634 1.30091 0.749573 1.49993 0.749573C1.69895 0.749573 1.88982 0.828634 2.03055 0.969364L8.99993 7.93968L15.9693 0.969364C16.039 0.899682 16.1217 0.844406 16.2128 0.806695C16.3038 0.768983 16.4014 0.749573 16.4999 0.749573C16.5985 0.749573 16.6961 0.768983 16.7871 0.806695C16.8781 0.844406 16.9609 0.899682 17.0306 0.969364C17.1002 1.03905 17.1555 1.12177 17.1932 1.21282C17.2309 1.30386 17.2503 1.40144 17.2503 1.49999C17.2503 1.59854 17.2309 1.69612 17.1932 1.78716C17.1555 1.87821 17.1002 1.96093 17.0306 2.03062Z"
        fill={fill}
      />
    </svg>
  );
}

export default CaretDown;
