import { DetectiveIcon } from "@assets/icons/RebrandedIcons";
import CheckIconColored from "@assets/icons/RebrandedIcons/CheckIconColored";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { Siren } from "@phosphor-icons/react";
import { RiskStatusName } from "./RiskLevelColItem";
import { styled } from "@mui/styles";

export const RiskStatusIcons: any = {
  routine_monitoring: ({ color = palette.neutral[70] }) => (
    <CheckIconColored fill={color} />
  ),
  alerted: ({ color = palette.neutral[70] }) => (
    <Siren color={color} size={20} weight="bold" />
  ),
  active_monitoring: ({ color = palette.neutral[70] }) => (
    <DetectiveIcon color={color} />
  ),
};

const RiskStatusIconsTextColors: any = {
  routine_monitoring: {
    text: palette.filled.success,
    background: palette.tag.success.bg,
  },
  alerted: { text: palette.warning.text, background: palette.tag.warning.bg },
  active_monitoring: {
    text: palette.tag.error.text,
    background: palette.tag.error.bg,
  },
};

type RiskStatusIconTextProp = {
  riskStatusName: string;
  isOnlyIcon?: boolean;
};

const RiskStatusIconText = ({
  riskStatusName,
  isOnlyIcon = true,
}: RiskStatusIconTextProp) => {
  if (isOnlyIcon) {
    return <>{RiskStatusIcons[riskStatusName]({})}</>;
  }

  return (
    <RiskStatusIconTextContainer
      background={RiskStatusIconsTextColors[riskStatusName].background}
      textColor={RiskStatusIconsTextColors[riskStatusName].text}
    >
      {RiskStatusIcons[riskStatusName]({
        color: RiskStatusIconsTextColors[riskStatusName].text,
      })}
      {RiskStatusName[riskStatusName]}
    </RiskStatusIconTextContainer>
  );
};

const RiskStatusIconTextContainer = styled(Stack)(
  ({ background, textColor }: any) => ({
    padding: "2px 16px",
    borderRadius: "100px",
    fontSize: 14,
    lineHeight: "120%",
    fontWeight: 400,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    textTransform: "capitalize",
    gap: "8px",
    backgroundColor: background,
    color: textColor,
    maxHeight: "22px",
  }),
);

export default RiskStatusIconText;
