type Props = {
  color?: string;
  width?: string | number;
  height?: string | number;
};

const MobileDeviceIcon = ({
  color = "#B2B2B1",
  width = 21,
  height = 20,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.093 13.5763L10.968 13.4763C10.9215 13.4448 10.8711 13.4195 10.818 13.4013L10.668 13.3346C10.5328 13.3064 10.3928 13.3121 10.2604 13.3514C10.128 13.3907 10.0075 13.4622 9.90964 13.5596C9.83603 13.6406 9.77684 13.7336 9.73464 13.8346C9.67157 13.9865 9.65483 14.1536 9.68652 14.3149C9.7182 14.4762 9.79691 14.6246 9.91272 14.7413C10.0285 14.858 10.1763 14.9378 10.3374 14.9707C10.4984 15.0037 10.6657 14.9882 10.818 14.9263C10.9175 14.878 11.0101 14.8163 11.093 14.743C11.2086 14.6258 11.2869 14.477 11.3181 14.3153C11.3492 14.1537 11.3318 13.9864 11.268 13.8346C11.2264 13.7383 11.167 13.6506 11.093 13.5763ZM13.8346 1.66797H7.16797C6.50493 1.66797 5.86904 1.93136 5.4002 2.4002C4.93136 2.86904 4.66797 3.50493 4.66797 4.16797V15.8346C4.66797 16.4977 4.93136 17.1336 5.4002 17.6024C5.86904 18.0712 6.50493 18.3346 7.16797 18.3346H13.8346C14.4977 18.3346 15.1336 18.0712 15.6024 17.6024C16.0712 17.1336 16.3346 16.4977 16.3346 15.8346V4.16797C16.3346 3.50493 16.0712 2.86904 15.6024 2.4002C15.1336 1.93136 14.4977 1.66797 13.8346 1.66797ZM14.668 15.8346C14.668 16.0556 14.5802 16.2676 14.4239 16.4239C14.2676 16.5802 14.0557 16.668 13.8346 16.668H7.16797C6.94696 16.668 6.73499 16.5802 6.57871 16.4239C6.42243 16.2676 6.33464 16.0556 6.33464 15.8346V4.16797C6.33464 3.94695 6.42243 3.73499 6.57871 3.57871C6.73499 3.42243 6.94696 3.33464 7.16797 3.33464H13.8346C14.0557 3.33464 14.2676 3.42243 14.4239 3.57871C14.5802 3.73499 14.668 3.94695 14.668 4.16797V15.8346Z"
        fill={color}
      />
    </svg>
  );
};

export default MobileDeviceIcon;
