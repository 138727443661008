import { namespaces } from "../../../resources/i18n.constants";

export const paymentForms = {
  [namespaces.pages.paymentForms]: {
    paymentFormsTotal: "Payment Form Total",
    eventsTotal: "Events Total",
    events: "Events",
    visitors: "Visitors",
    conversion: "Conversion",
    Average: "Average",
    createPaymentForms: "Create Payment Form",
    search: "Search...",
    paymentForm: "Payment Form",
    paymentForms: "Payment Forms",
    amount: "Amount",
    average: "Average",
    transactions: "Transactions",
    ticketsSold: "Tickets Sold",
  },
};