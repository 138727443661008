import { showMessage } from "@common/Toast";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { ReactElement, ReactNode, createContext, useContext } from "react";
import { TFlagSmithState, useGetFlags } from "./useGetFlags";

type TFeatureFlagContext = {
  flags: Map<string, boolean>;
  flagsmithState: TFlagSmithState | undefined;
  isLoading: boolean;
};

export const FeatureFlagContext = createContext<TFeatureFlagContext>({
  flags: new Map(),
  flagsmithState: undefined,
  isLoading: true,
});

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);

const FeatureFlagProvider = ({ children }: { children: ReactElement }) => {
  const { flags, isLoading, flagsmithState } = useGetFlags();

  const onError = ({ message }: { message: string }) => {
    showMessage("Error", message);
  };
  return (
    <FeatureFlagContext.Provider
      value={{
        flags,
        flagsmithState,
        isLoading,
      }}
    >
      <FlagsmithProvider
        options={{
          environmentID: process.env.REACT_APP_ENVIRONMENT_ID_FLAG!,
          // enableLogs: true,
          api: process.env.REACT_APP_GIVE_FLAGS_API,
          onError: onError,
        }}
        // we can do this because flagsmith is probably a singleton
        flagsmith={flagsmith}
      >
        {children}
      </FlagsmithProvider>
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
