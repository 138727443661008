import { cloneDeep, mergeWith } from "lodash";
import { selectedDeviceType, selectedItem } from "./signals";
import {
  getDeviceTypeAttributesToUpdate,
  getDeviceTypePropertyName,
} from "./utils/helpers";

export const nodeDeviceTypePropertiesMap = new Map();

export const updateNodeDeviceTypeProperties = (
  key: string,
  newValue: any,
  applyHierarchy = true,
) => {
  const id = selectedItem.value.id;
  const deviceType = selectedDeviceType.value;
  const currentValue = nodeDeviceTypePropertiesMap.get(id);

  const createUpdate = (keys: string[]) => {
    return keys.reduce((acc: any, k: any) => {
      if (typeof newValue === "string") {
        acc[k] = newValue;
      } else {
        if (currentValue[k] !== undefined) {
          acc[k] = mergeWith(cloneDeep(currentValue[k]), { ...newValue });
        }
      }
      return acc;
    }, {});
  };

  const updateKeys = getDeviceTypeAttributesToUpdate(key)[deviceType];
  /* In general, when we update element on one device type, hierarchy is applied,
     but in some cases (for example show/hide on specific device type)
     hiearchy is not applied and only properties for that specific device type are updated
  */
  const specificDeviceTypeAttribute = [getDeviceTypePropertyName(key)];

  nodeDeviceTypePropertiesMap.set(id, {
    ...currentValue,
    ...createUpdate(applyHierarchy ? updateKeys : specificDeviceTypeAttribute),
  });
};

export const syncNodeAttributes = (id: number, newValues: any) => {
  const currentValue = nodeDeviceTypePropertiesMap.get(id);
  const {
    cssProperties,
    tabletCssProperties,
    mobileCssProperties,
    metaAttributes,
    tabletMetaAttributes,
    mobileMetaAttributes,
  } = newValues;

  nodeDeviceTypePropertiesMap.set(id, {
    ...currentValue,
    cssProperties: cssProperties,
    tabletCssProperties,
    mobileCssProperties,
    metaAttributes,
    tabletMetaAttributes,
    mobileMetaAttributes,
  });
};

export const getDeviceTypeProperties = (id: string) => {
  const deviceTypeMapValues = nodeDeviceTypePropertiesMap.get(id);

  if (!deviceTypeMapValues) return;

  const stateValue = deviceTypeMapValues?.[getDeviceTypePropertyName("state")];

  if (stateValue) {
    return { state: stateValue };
  }

  return {
    cssProperties:
      deviceTypeMapValues[getDeviceTypePropertyName("cssProperties")],
    metaAttributes:
      deviceTypeMapValues[getDeviceTypePropertyName("metaAttributes")],
    tagName: deviceTypeMapValues[getDeviceTypePropertyName("tagName")],
    content: deviceTypeMapValues[getDeviceTypePropertyName("content")],
    href: deviceTypeMapValues[getDeviceTypePropertyName("href")],
  };
};

export const saveNodeDeviceTypeProperties = (
  id: number,
  cssProperties: any,
  metaAttributes: any,
  tagName: string,
  content: string,
  tabletMetaAttributes?: any,
  mobileMetaAttributes?: any,
) => {
  // This is added so during clone data-hidden metaAttribute is not copied
  const { "data-hidden": x, ...rest } = metaAttributes || {};
  nodeDeviceTypePropertiesMap.set(id, {
    cssProperties: cssProperties,
    tabletCssProperties: { ...cssProperties },
    mobileCssProperties: { ...cssProperties },
    metaAttributes,
    tabletMetaAttributes: { ...(tabletMetaAttributes || rest) },
    mobileMetaAttributes: { ...(mobileMetaAttributes || rest) },
    tagName,
    tabletTagName: tagName,
    mobileTagName: tagName,
    content,
    tabletContent: content,
    mobileContent: content,
  });
};

export const savePublishedNodeDeviceTypeProperties = (
  id: number,
  cssProperties: any,
  tabletCssProperties: any,
  mobileCssProperties: any,
  metaAttributes: any,
  tabletMetaAttributes: any,
  mobileMetaAttributes: any,
  tagName: string,
  tabletTagName: any,
  mobileTagName: any,
  content: string,
  tabletContent: any,
  mobileContent: any,
  href?: any,
  tabletHref?: any,
  mobileHref?: any,
) => {
  nodeDeviceTypePropertiesMap.set(id, {
    cssProperties: cssProperties,
    tabletCssProperties: tabletCssProperties,
    mobileCssProperties: mobileCssProperties,
    metaAttributes,
    tabletMetaAttributes: tabletMetaAttributes,
    mobileMetaAttributes: mobileMetaAttributes,
    tagName,
    tabletTagName: tabletTagName,
    mobileTagName: mobileTagName,
    content,
    tabletContent: tabletContent,
    mobileContent: mobileContent,
    href,
    tabletHref,
    mobileHref,
  });
};

export const saveCompoundNodeDeviceTypeState = (
  id: number | string,
  state: any,
) => {
  const deviceTypeStateName = getDeviceTypePropertyName("state");
  nodeDeviceTypePropertiesMap.set(id, {
    ...nodeDeviceTypePropertiesMap.get(id),
    [deviceTypeStateName]: state,
  });
};
