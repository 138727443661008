import { Button } from "@common/Button";
import { styled, Stack, Box } from "@mui/material";
import { palette } from "@palette";

export const PanelContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  height: "100%",
  background: "inherit",
  overflow: "hidden",
  position: "relative",
}));

export const HeaderContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  position: "sticky",
  gap: "12px",
  top: 0,
  [theme.breakpoints.down("sm")]: {
    position: "relative",
  },
}));

export const AdvancedSettingsButton = styled(Button)(() => ({
  padding: "4px 8px",
  fontWeight: 400,
  lineHeight: "16.8px",
  color: palette.black[100],
  textDecoration: "underline",
  borderRadius: "32px",
  height: "fit-content",
  width: "100%",
  "&:hover": {
    backgroundColor: palette.neutral[10],
  },
}));

export const WhiteContainer = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "16px",
  padding: "16px",
  backgroundColor: palette.neutral.white,
  borderRadius: "8px",
}));

export const TertiaryButton = styled(Button)(() => ({
  color: palette.gray[300],
  fontWeight: 400,
  height: "fit-content",
  lineHeight: "16.8px",
  padding: "4px 8px",
  borderRadius: "8px",
  "&:hover": {
    textDecoration: "none",
    backgroundColor: palette.neutral[10],
  },
}));

export const ModalContent = styled(Stack)(() => ({
  flexDirection: "column",
  flexGrow: 1,
  paddingBlock: "16px",
  overflowY: "scroll",
  overflowX: "hidden",
}))