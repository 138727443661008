import { CaretDown } from "@assets/icons";
import { DropdownItem } from "@common/Select";
import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { Menu, Stack } from "@mui/material";
import { OFAC_CONFIRM_MATCH_MODAL } from "modals/modal_names";
import { useMemo, useState } from "react";
import { OFACCheckType } from "../hooks/useOFAC";
import { TOFACEndpointBuilder } from "../hooks/useUpdateOFACCheck";
import { TOFACStatus, TOFACStatusImproved } from "./OFACDetails";
import { palette } from "@palette";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { OFACTabType } from "../helpers";

type Props = {
  ofacStatus: TOFACStatusImproved;
  merchantId: number;
  legalEntityId: number;
  currentCheck: OFACCheckType;
  handleSubmit: ({
    data,
    merchantId,
    legalEntityId,
    ofacId,
  }: TOFACEndpointBuilder) => void;
  activeTab: OFACTabType;
  isEnterprisePanel: boolean;
};

const OFACDetailsMenu = ({
  ofacStatus,
  merchantId,
  handleSubmit,
  legalEntityId,
  currentCheck,
  activeTab,
  isEnterprisePanel,
}: Props) => {
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const urlBuilderObj = {
    merchantId,
    legalEntityId: isNewOfacTabsEnabled ? undefined : legalEntityId,
    ofacId: currentCheck?.ID,
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mapper = useMemo(
    () => ({
      "Possible match": {
        color: "#FF8124",
        backgroundColor: "#FFF2E9",
        fn: () => {
          handleSubmit({
            ...urlBuilderObj,
            data: { status: "possible_match" },
          }),
            handleClose();
        },
      },
      "Change to Clear Match": {
        color: "#13A75A",
        backgroundColor: "#E6F3EC",
        fn: () => {
          handleSubmit({
            ...urlBuilderObj,
            data: { status: "manually_cleared" },
          }),
            handleClose();
        },
      },
      "Change to Confirmed Match": {
        color: "#D80D0D",
        backgroundColor: "#F5E0DF",
        fn: () => {
          NiceModal.show(OFAC_CONFIRM_MATCH_MODAL, {
            merchantId,
            isEnterprisePanel: isEnterprisePanel,
            action: () =>
              handleSubmit({
                ...urlBuilderObj,
                data: { status: "confirmed_match" },
              }),
            activeTab,
          }),
            handleClose();
        },
      },
      "Confirmed match": {
        color: "#D80D0D",
        backgroundColor: "#F5E0DF",
      },
      "Manually cleared": {
        color: "#13A75A",
        backgroundColor: "#E6F3EC",
      },
      Clear: {
        color: "#13A75A",
        backgroundColor: "#E6F3EC",
      },
    }),
    [],
  );

  const handleDropdownItemClick = (option: TOFACStatus) => {
    const { fn } = mapper[option as TOFACStatus];
    fn();
  };

  const options = Object.keys(mapper);

  const filteredArray = useMemo(
    () =>
      options.filter((option) => {
        if (ofacStatus === "Confirmed match") {
          return (
            option !== ofacStatus &&
            option !== "Change to Confirmed Match" &&
            option !== "Possible match" &&
            option !== "Manually cleared" &&
            option !== "Clear"
          );
        } else if (ofacStatus === "Manually cleared") {
          return (
            option !== ofacStatus &&
            option !== "Change to Clear Match" &&
            option !== "Confirmed match" &&
            option !== "Possible match" &&
            option !== "Clear"
          );
        } else if (ofacStatus === "Clear") {
          return (
            option !== ofacStatus &&
            option !== "Change to Clear Match" &&
            option !== "Confirmed match" &&
            option !== "Possible match" &&
            option !== "Manually cleared"
          );
        } else {
          return (
            option !== ofacStatus &&
            option !== "Confirmed match" &&
            option !== "Manually cleared" &&
            option !== "Clear"
          );
        }
      }),
    [ofacStatus],
  );
  return (
    <Stack direction="row" spacing="8px" alignItems="center">
      {activeTab === "Business Owner" && currentCheck.resourceFullName && (
        <Text color={palette.neutral[80]} fontSize="14px">
          {currentCheck.resourceFullName}
        </Text>
      )}
      <Stack
        direction="row"
        width="fit-content"
        alignItems="center"
        onClick={handleClick}
        gap={1}
        sx={{
          borderRadius: "16px",
          cursor: "pointer",
          color: mapper[ofacStatus as TOFACStatus]?.color,
          backgroundColor: mapper[ofacStatus as TOFACStatus]?.backgroundColor,
          padding: "2px 12px 2px 16px",
        }}
        data-testid="OFAC-Details-Menu"
      >
        <Text>{ofacStatus}</Text>
        <CaretDown
          fill={mapper[ofacStatus as TOFACStatus]?.color}
          width={12}
          height={12}
        />
      </Stack>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "max-content",
          },
        }}
      >
        {filteredArray.map((option: string, index) => (
          <DropdownItem
            onClick={() => handleDropdownItemClick(option as TOFACStatus)}
            key={index}
            data-testid="OFAC-Dropdown-Item"
          >
            <Text color={mapper[option as TOFACStatus]?.color}>{option}</Text>
          </DropdownItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default OFACDetailsMenu;
