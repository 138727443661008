import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useMutation } from "react-query";

export const useCreateBankAccountMutation = (bankAccountId?: number) => {
  return useMutation((data: any) => {
    return customInstance({
      url: buildMerchantEndpoints(
        bankAccountId ? `bank-accounts/${bankAccountId}` : `bank-accounts`,
      ),
      method: bankAccountId ? "PATCH" : "POST",
      data,
    });
  });
};
