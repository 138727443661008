import React from "react";
import NiceModal from "@ebay/nice-modal-react";
import { Stack, useTheme, useMediaQuery, Box, BadgeProps } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { PUBLIC_FORM_PRODUCT_INFO_MODAL } from "modals/modal_names";
import { EditorState } from "draft-js";
import { Button } from "@common/Button";
import { Image } from "@common/StyledImage/Image";
import { CustomAmountInputType } from "@common/PublicForm/types";
import PaymentCardTitle from "./PaymentCardTitle";
import {
  CardContainer,
  CustomBadge,
  SecondaryActionContainer,
} from "./PaymentCard.styles";
import { buttonLabelsMap } from "@common/PublicForm/CheckoutWrapper";

export type TSubscriptionPeriod =
  | "One-Time"
  | "Monthly"
  | "Quarterly"
  | "Yearly";

type TPaymentTypes = {
  checked: boolean;
  isDefault: boolean;
  title: TSubscriptionPeriod;
};

export type PaymentCardProps = {
  actions?: React.ReactNode;
  secondaryActions?: React.ReactNode;
  title: string;
  price?: number;
  imageURL?: string;
  description?: string | EditorState;
  quantity?: number;
  selected?: boolean;
  badgeContent?: number;
  disabled?: boolean;
  paymentTypes?: TPaymentTypes[];
  activePaymentType?: TSubscriptionPeriod;
  onChagePaymentType?: (value: TSubscriptionPeriod) => void;
  onChange?: any;
  name?: string;
  category?: "events" | "membership" | "sweepstakes";
  columns?: any;
  renderOptions?: any;
  amount?: number;
  quantityLeftComponent?: React.ReactNode;
  isDefault?: boolean;
  formattedDate?: any;
  ticketId?: number;
  customStyle?: any;
  typeName?: keyof typeof buttonLabelsMap;
};

const PaymentCard = ({
  actions,
  amount,
  imageURL,
  title,
  description,
  quantity,
  price,
  selected,
  badgeContent,
  disabled,
  paymentTypes,
  activePaymentType,
  onChange,
  onChagePaymentType,
  name,
  category,
  columns,
  renderOptions,
  quantityLeftComponent,
  isDefault,
  max,
  min,
  customDonation,
  handleAdd,
  handleSetValue,
  handleSubtract,
  formattedDate,
  secondaryActions,
  ticketId,
  customStyle,
  typeName,
  ...rest
}: PaymentCardProps & Partial<CustomAmountInputType> & BadgeProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const hasDescription =
    typeof description === "string"
      ? !!description
      : description?.getCurrentContent().hasText();
  
  function handleCardClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    if (!description || disabled) return;
    NiceModal.show(PUBLIC_FORM_PRODUCT_INFO_MODAL, {
      title,
      imageURL,
      renderDescription: () => description,
      price,
    });
  }

  React.useEffect(() => {
    const defaultOption = paymentTypes?.find(
      (option) => option.isDefault === true,
    );

    if (defaultOption && onChagePaymentType)
      onChagePaymentType(defaultOption.title);
  }, []);
  return (
    <CustomBadge
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      badgeContent={badgeContent}
      {...rest}
    >
      <CardContainer
        disabled={disabled}
        selected={selected}
        hasCategory={Boolean(category)}
        flexGrow={1}
        onClick={() => onChange && onChange(ticketId)}
        data-testid={`payment-card-${ticketId}`}
        sx={{
          ...customStyle?.customHoverStyle,
        }}
      >
        <Stack
          alignItems="stretch"
          direction={customStyle?.flexDirection || "column"}
          gap={1.5}
          width="100%"
        >
          {imageURL && (
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              flexGrow={customStyle?.flexGrow === 0 ? 0 : 1}
            >
              <Image
                style={{ opacity: disabled ? 0.5 : 1, borderRadius: "12px" }}
                alt={title}
                src={`${imageURL}/original`}
                width="64px"
                height="64px"
              />
              {selected && (
                <Stack direction="column" gap={1}>
                  <SecondaryActionContainer
                    sx={{
                      ...(!category && {
                        justifyContent: "flex-end",
                      }),
                    }}
                  >
                    {secondaryActions || actions}
                  </SecondaryActionContainer>
                  {isDesktop && quantityLeftComponent && (
                    <Box minWidth="80px">{quantityLeftComponent}</Box>
                  )}
                </Stack>
              )}
              {!selected && quantityLeftComponent && (
                <Box minWidth="80px">{quantityLeftComponent}</Box>
              )}
            </Stack>
          )}
          <PaymentCardTitle
            title={title}
            quantity={quantity}
            price={price}
            selected={selected}
            disabled={disabled}
            isDefault={isDefault}
            imageURL={imageURL}
            min={min}
            max={max}
            handleAdd={handleAdd}
            handleSetValue={handleSetValue}
            handleSubtract={handleSubtract}
            customDonation={customDonation}
            customStyle={customStyle}
            quantityLeftComponent={
              !imageURL && (
                <Stack direction="column" gap={1}>
                  {selected && (
                    <SecondaryActionContainer
                      sx={{
                        ...(!category && {
                          justifyContent: "flex-end",
                        }),
                      }}
                    >
                      {secondaryActions || actions}
                    </SecondaryActionContainer>
                  )}
                  {isDesktop
                    ? quantityLeftComponent
                    : !selected && quantityLeftComponent}
                </Stack>
              )
            }
          />
        </Stack>
        <MoreInformationButton
          isVisible={isDesktop && hasDescription && Boolean(price)}
          onClick={handleCardClick}
        />
        {!isDefault && renderOptions && selected && (
          <Stack
            direction={isDesktop ? "row" : "column"}
            gap={2}
            mt={2}
            width={isDesktop ? "auto" : "100%"}
            alignItems="flex-end"
          >
            {columns?.map((column: any, index: number) => (
              <ColumnItem
                key={index}
                isDesktop={isDesktop}
                column={column}
                index={index}
                formattedDate={formattedDate}
                renderOptions={renderOptions}
                typeName={typeName}
              />
            ))}
          </Stack>
        )}

        {!isDesktop && selected && category && (
          <Stack gap={1} sx={{ margin: "16px auto" }}>
            <SecondaryActionContainer>{actions}</SecondaryActionContainer>
            <Box minWidth="80px">
              <Stack>{quantityLeftComponent && quantityLeftComponent}</Stack>
            </Box>
          </Stack>
        )}
        {!isDesktop && (
          <Box minWidth="80px" margin="auto">
            <MoreInformationButton
              isVisible={hasDescription && Boolean(price)}
              onClick={handleCardClick}
            />
          </Box>
        )}
      </CardContainer>
    </CustomBadge>
  );
};

type ColumnItemProps = {
  isDesktop: boolean;
  column: any;
  index: number;
  formattedDate: any;
  renderOptions: (arg: any) => React.ReactNode;
  typeName?: keyof typeof buttonLabelsMap;
};

const ColumnItem = ({
  isDesktop,
  column,
  index,
  renderOptions,
  typeName,
}: ColumnItemProps) => {
  if (!isDesktop && column === "Until") return null;

  const columnWidth = isDesktop ? "125px" : "100%";

  return (
    <Box key={index} width={columnWidth}>
      {typeName === "fundraiser" && (
        <Text color={palette.neutral[600]}>{column}</Text>
      )}
      <Box
        display="flex"
        sx={{
          minWidth: "100%",
          width: "100%",
          "& > *": {
            flexGrow: 1,
          },
        }}
      >
        {renderOptions(column)}
      </Box>
    </Box>
  );
};

const MoreInformationButton = ({
  onClick,
  isVisible = false,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isVisible?: boolean;
}) => {
  if (!isVisible) return null;

  return (
    <Button
      background="tertiary"
      sx={{ padding: "16px 0px 0px 0px", height: "auto" }}
      onClick={onClick}
    >
      More Information
    </Button>
  );
};

export default PaymentCard;
