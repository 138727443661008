import { RHFSelect } from "@common/Select";
import { useListEnterpriseCategoryCodes } from "@hooks/enterprise-api/merchants/useListEnterpriseCategoryCodes";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

type CategoriesSelectProps = {
  label?: string;
  name: string;
  placeholder?: string;
  providerId?: number;
  isAcquirer?: boolean;
  disabled?: boolean;
};

const CategoriesSelect = ({
  name,
  placeholder,
  label,
  providerId,
  isAcquirer,
  disabled,
}: CategoriesSelectProps) => {
  const { setValue } = useFormContext();
  const {
    categories: fetchedCategories,
    slug,
    feeConfig,
    amexCreditCardFees,
    debitCardFees,
    creditCardFees,
  } = useListEnterpriseCategoryCodes(providerId);

  React.useEffect(() => {
    if (!providerId || !isAcquirer) return;

    setValue("merchant_info.parent_slug", slug);
    setValue("merchant_info.fees", {
      amexCreditCardFee:
        feeConfig?.amexCreditCardFee.baselinePercent + amexCreditCardFees,
      debitCardFee: feeConfig?.debitCardFee.baselinePercent + debitCardFees,
      creditCardFee: feeConfig?.creditCardFee.baselinePercent + creditCardFees,
    });
  }, [providerId]);

  React.useEffect(() => {
    if (providerId || isAcquirer) return;
    setValue("merchant_info.parent_slug", slug);
  }, [slug]);

  const categoriesOptions = useMemo(() => {
    return fetchedCategories.map((category: any) => ({
      value: category.id,
      label: `${category.code} - ${category.title}`,
    }));
  }, [fetchedCategories]);

  return (
    <RHFSelect
      label={label}
      name={name}
      placeholder={placeholder}
      options={categoriesOptions}
      disabled={(isAcquirer && !providerId) || disabled}
    />
  );
};

export default CategoriesSelect;
