import { SvgIconProps } from "@mui/material";

const NewHorizentalMoreIcon = ({
  height = 20,
  width = 14,
  fill = "#8F8F8F",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 1.17157 0.671573 0.5 1.5 0.5C2.32843 0.5 3 1.17157 3 2C3 2.82843 2.32843 3.5 1.5 3.5C0.671573 3.5 0 2.82843 0 2Z"
        fill={fill}
      />
      <path
        d="M5 2C5 1.17157 5.67157 0.5 6.5 0.5C7.32843 0.5 8 1.17157 8 2C8 2.82843 7.32843 3.5 6.5 3.5C5.67157 3.5 5 2.82843 5 2Z"
        fill={fill}
      />
      <path
        d="M10 2C10 1.17157 10.6716 0.5 11.5 0.5C12.3284 0.5 13 1.17157 13 2C13 2.82843 12.3284 3.5 11.5 3.5C10.6716 3.5 10 2.82843 10 2Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewHorizentalMoreIcon;
