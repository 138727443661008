import { customInstance } from "../index";
import { useQuery } from "react-query";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";

interface Params {
  id: number;
  queryString?: string;
  sorting?: string;
  page?: number;
  searchQuery?: string;
  filter?: string;
  enabled?: boolean;
}

export const getChangelog = ({
  id,
  queryString = "",
  page,
  sorting,
  searchQuery,
  filter,
}: Params) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  const filterQuery = filter
    ? "filter=" + encodeURIComponent(`;${filter}`)
    : "filter=";
  const pageQuery = page ? `&page=${page}` : "";

  return customInstance({
    url: `/merchants/${id}/changelogs?${filterQuery}${queryString}&sort=${
      sorting || "-changelogMerchAccID"
    }${search}${pageQuery}&max=${ROWS_PER_PAGE}`,
    method: "GET",
  });
};

export const useGetChangelog = (props: Params) => {
  const {
    id,
    queryString,
    searchQuery,
    filter,
    page,
    sorting,
    enabled = true,
  } = props;
  return useQuery(
    ["get-changelog", id, queryString, searchQuery, filter, page, sorting],
    async () => {
      const data = await getChangelog({
        id,
        queryString,
        searchQuery,
        filter,
        page,
        sorting,
      });
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false, enabled: enabled },
  );
};

export type OperationType = "insert" | "update" | "upload";

export type ChangelogData = {
  userAccID: number;
  userAccFullName: string;
  email: string;
  avatarImageURL: string;
  changeDate: number;
  resourceTypeDisplayName: GroupType;
  fieldChanges: ChangeItem[];
  changelogMerchAccID: number;
  resourceName: string;
};

export type ChangeItem = {
  operation: OperationType;
  fieldName: string;
  oldValue?: string | number;
  newValue: string | number;
};

export type GroupType = //TODO: update with correct types after BE is ready

    | "Merchant Info"
    | "Business Profile"
    | "Primary Account Holder"
    | "Bank Account"
    | "Business Owner"
    | "Documents";
