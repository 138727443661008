import { checkPortals } from "@utils/routing";
import { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { useModal } from "@ebay/nice-modal-react";

const useSidePanel = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const modal = useModal();

  const theme = useTheme();
  const hasResponsiveViewport = useMediaQuery(
    theme.breakpoints.up(CAMPAIGN_PANEL_WIDTH * 2),
  );
  const { isManageMoney } = checkPortals();

  const handleClose = () => {
    setIsExpanded((prev) => {
      if (!prev) {
        modal.hide();
      }
      return false;
    });
  };

  const handleExpandPanel = () => {
    setIsExpanded((prev) => !prev);
  }

  return {
    canBeExpanded: isManageMoney && hasResponsiveViewport,
    isExpanded,
    modal,
    handleClose,
    handleExpandPanel,
  };
};

export default useSidePanel;
