import { showMessage } from "@common/Toast";
import { QKEY_MERCHANTS_API_KEYS } from "@constants/queryKeys";
import NiceModal from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { upperFirst } from "lodash";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import GeneralModal, { RolledKeyModalBody } from "../components/GeneralModal";

const bodies: Record<string, string> = {
  revoke:
    "Are you sure you want to revoke this API key? Revoking the key will disable access to the associated resources.",
  activate: "Are you sure you want to activate this API key?",
  roll: "Are you sure you want to roll this API key? Rolling the key will generate a new unique API key string, invalidating the current key.",
  delete:
    "Are you sure you want to delete this API key? Deleting the key will permanently remove it from the system, and it cannot be undone. Any associated resources relying on this key will lose access.",
};

const actionMapper: Record<string, string> = {
  roll: "rolled",
  delete: "deleted",
  activate: "active",
  revoke: "revoked",
};

export const useActions = ({
  keyId,
  onClose,
}: {
  keyId: string;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const [nextActionIsClose, setNextActionIsClose] = useState<boolean>(false);

  const updateKey = useMutation((data: any) => {
    const isDelete = data?.status === "deleted";
    const method = isDelete ? "DELETE" : "PATCH";
    const url = `/merchants/${merchantId}/api-keys/${keyId}${
      isDelete ? "" : "/status"
    }`;

    return customInstance({
      url,
      method,
      data,
    });
  });

  const fireAction = (actionId: string) => {
    updateKey.mutate(
      { status: actionMapper[actionId] },
      {
        onSuccess: (res) => {
          queryClient.refetchQueries([QKEY_MERCHANTS_API_KEYS]);
          onClose();
          if (actionId === "roll") {
            NiceModal.show(GeneralModal, {
              title: "API Key Rolled Successfully",
              body: <RolledKeyModalBody res={res} />,
              primaryActionLabel: "Close",
              shouldDisplaySecondaryButton: false,
            });

            setNextActionIsClose(true);
          }
        },
        onError: (e) => {
          showMessage("Error", (e as Error).message);
        },
      },
    );
  };

  return {
    fireAction,
    nextActionIsClose,
    isLoading: updateKey.isLoading,
    isSuccess: updateKey.isSuccess,
  };
};

const createModalOpener =
  (
    actionId: string,
    {
      apiKeyName,
      keyId,
    }: {
      apiKeyName: string;
      keyId: string;
    },
  ) =>
  () => {
    if (!keyId || !apiKeyName) return;
    NiceModal.show(GeneralModal, {
      title: `${upperFirst(actionId)} ${apiKeyName}`,
      body: bodies[actionId],
      primaryActionLabel: upperFirst(actionId),
      actionId,
      keyId,
    });
  };

export const useMerchantsApiKeysActions = ({ rowData }: { rowData: any }) => {
  const keyId = rowData?.id;
  const apiKeyName = rowData?.name;

  const openRevokeKeyModal = createModalOpener("revoke", {
    apiKeyName,
    keyId,
  });
  const openDeleteKeyModal = createModalOpener("delete", {
    apiKeyName,
    keyId,
  });
  const openRollKeyModal = createModalOpener("roll", {
    keyId,
    apiKeyName,
  });
  const openActivateKeyModal = createModalOpener("activate", {
    keyId,
    apiKeyName,
  });

  return {
    openRevokeKeyModal,
    openDeleteKeyModal,
    openRollKeyModal,
    openActivateKeyModal,
  };
};
