import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";

const FilterMenu = styled((props: MenuProps) => (
  <Menu
    BackdropProps={{
      invisible: false,
      sx: {
        backgroundColor: "rgba(53, 53, 53, 0.1)",
      },
    }}
    MenuListProps={{
      "aria-labelledby": "filter",
      role: "filter",
    }}
    {...props}
  />
))(({ theme }) => ({
  top: 0,
  left: "-12px",

  "& .MuiPaper-root": {
    width: 282,
    padding: "24px 8px",
    borderRadius: "16px",
    margin: theme.spacing(1, 0, 0, -1),
    background: `#F8F8F6`, // neutral[9]
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  },
  "& .MuiList-root": {
    padding: 0,
  },
  "& .MuiMenuItem-root": {
    padding: "0",
    "&:not(:last-of-type)": {
      paddingBottom: "3px",
    },
    "&:hover": {
      color: "initial",
      background: "none",
    },
    "&.Mui-selected": {
      color: "initial",
      background: "none",
    },
  },
}));

export default FilterMenu;
