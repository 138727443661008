import { Box, BoxProps } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";

import { ILayoutProps } from "react-dropzone-uploader";
import ImagePlaceholder from "@assets/images/avatar-placeholder.png";
import VariantImagePlaceholder from "@assets/svgIcons/image.svg";
import { IFileWithMeta } from "react-dropzone-uploader";
import { StatusValue } from "react-dropzone-uploader";
import { useAppDispatch } from "@redux/hooks";
import { palette } from "@palette";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { isImage } from "@hooks/merchant-api/image-gallery/useUploadImage";
import { showMessage } from "@common/Toast";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type TImageSize = "small" | "medium" | "large" | "thumb" | "original";

export type UploadAvatarProps = BoxProps & {
  file?: any;
  avatarType?: "avatar" | "thumbnail";
  isMyAccountImage?: boolean;
  isProductVariant?: boolean;
  title?: string;
  titleComponent?: React.ReactNode;
  imageSize?: TImageSize;
  width?: string | number;
  margin?: string | number;
  borderRadius?: string | number;
  height?: string | number;
  disabled?: boolean;
  defaultImage?: string;
  uploadStatus?: "pending" | "success" | "error" | "idle";
  customPlaceholder?: string;
  hiddenWhenEmpty?: boolean;
  onChangeStatus?: IDropzoneProps["onChangeStatus"];
  getUploadParams?: IDropzoneProps["getUploadParams"];
  getFilesFromEvent?: IDropzoneProps["getFilesFromEvent"];
  actionToDispatch?: (args: any) => {
    payload: any;
    type: any;
  };
};

const Layout = ({
  input,
  dropzoneProps,
  fileUrl,
}: ILayoutProps & { fileUrl: string }) => {
  return (
    <Box
      {...dropzoneProps}
      style={{
        ...dropzoneProps.style,
        background: `url(${fileUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {input}
    </Box>
  );
};
// TODO: refactor to use react-dropzone instead of react-dropzone-uploader if no issues are found in "UploadFileNew" compoennt
const UploadAvatar_V2 = ({
  file,
  avatarType = "avatar",
  isProductVariant,
  isMyAccountImage = false,
  disabled,
  width = "80px",
  height = "80px",
  title = "Avatar",
  imageSize = "small",
  borderRadius = "50%",
  margin = "initial",
  titleComponent,
  uploadStatus,
  defaultImage,
  onChangeStatus,
  getUploadParams,
  getFilesFromEvent,
  actionToDispatch,
  customPlaceholder,
  hiddenWhenEmpty,
  ...boxProps
}: UploadAvatarProps) => {
  const ref = useRef<any>(null);
  const defaultPlaceholder =
    isProductVariant || avatarType === "thumbnail"
      ? VariantImagePlaceholder
      : ImagePlaceholder;
  const [fileUrl, setFileUrl] = useState<string>(
    customPlaceholder || defaultPlaceholder,
  );
  const dispatch = useAppDispatch();

  const dispatchAction = useCallback(
    (args: any) => actionToDispatch && dispatch(actionToDispatch(args)),
    [actionToDispatch],
  );
  const { isDropzoneImageFileValid } = useIsValidFile();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  useEffect(() => {
    const imageUrl =
      typeof file === "string" && file.length > 0
        ? file + "/original"
        : file?.preview;

    if ((defaultImage && !file) || (defaultImage && isMyAccountImage)) {
      setFileUrl(defaultImage);
    } else if (file && fileUrl === defaultPlaceholder && imageUrl) {
      setFileUrl(imageUrl);
      dispatchAction({ img: imageUrl });
    }
  }, [file]);

  const handleChangeStatus = (
    fileWithMeta: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[],
  ) => {
    const { meta, file } = fileWithMeta;
    if (["preparing", "removed"].includes(status)) return;
    if (
      isFileUploadRefactorEnabled &&
      !isDropzoneImageFileValid(fileWithMeta, status)
    ) {
      return;
    }

    const isImg = isImage(file);

    if (!isImg && !isFileUploadRefactorEnabled) {
      showMessage(
        "Error",
        "Only png, jpeg and jpg extensions are allowed",
        true,
        "",
        false,
      );
      return;
    }

    if (meta.previewUrl) {
      setFileUrl(meta.previewUrl);

      onChangeStatus && onChangeStatus(fileWithMeta, status, allFiles);
      dispatchAction({ img: meta.previewUrl });
    }
  };

  return (
    <Box {...boxProps}>
      <Box
        sx={{
          width: width,
          height: height,
          margin,
          border: "4px solid transparent",
          "&:hover": !disabled
            ? {
                borderColor:
                  avatarType === "avatar"
                    ? palette.gray[100]
                    : palette.neutral[70],
                borderRadius: avatarType === "avatar" ? "50%" : "12px",
              }
            : undefined,
        }}
      >
        <Dropzone
          disabled={disabled}
          ref={ref}
          styles={{
            dropzone: {
              overflow: "hidden",
              minHeight: "auto",
              height: "100%",
              border: 0,
              borderRadius: avatarType === "avatar" ? "50%" : "8px",
              borderColor:
                uploadStatus === "success"
                  ? "green"
                  : uploadStatus === "error"
                  ? "red"
                  : "transparent",
              opacity:
                fileUrl === defaultPlaceholder && hiddenWhenEmpty ? 0 : 1,
            },
          }}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          multiple={false}
          canCancel={false}
          accept={ACCEPTED_IMAGE_FORMATS}
          getFilesFromEvent={getFilesFromEvent}
          LayoutComponent={(props) => <Layout {...props} fileUrl={fileUrl} />}
          InputComponent={({ accept, onFiles, getFilesFromEvent }) => (
            <Box
              component="label"
              sx={{
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <input
                disabled={disabled}
                type="file"
                id="upload"
                accept={accept}
                style={{ display: "none" }}
                onChange={async (e) => {
                  const chosenFiles = await getFilesFromEvent(e);
                  onFiles(chosenFiles);
                  (e.target as any).value = null;
                }}
                data-testid="image-upload-input"
              />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default UploadAvatar_V2;
