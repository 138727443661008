import { Controller, useFormContext } from "react-hook-form";
import AsyncAutoComplete, { AsyncAutoCompleteProps } from "./AsyncAutoComplete";

function RHFAsyncAutoComplete({
  name,
  onNewItemSelected,
  ...props
}: {
  name: string;
  onNewItemSelected?(val: any): void;
} & AsyncAutoCompleteProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, onChange, ...rest },
        fieldState: { error },
      }) => (
        <AsyncAutoComplete
          inputRef={ref}
          {...rest}
          {...props}
          handleChange={(newValue: any) => {
            onChange(newValue.id);
            onNewItemSelected && onNewItemSelected(newValue);
          }}
        />
      )}
    />
  );
}

export default RHFAsyncAutoComplete;
