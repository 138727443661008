import { RHFCheckbox } from "@common/Checkbox";
import { MerchantSectionContainer } from "@components/Merchants/MerchantPreview/components/atoms";
import { Grid } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { TCreatePrimaryAccountHolder } from "@components/Merchants/CreateMerchantPanel/types";
import NiceModal from "@ebay/nice-modal-react";
import { CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL } from "modals/modal_names";
import { useFormContext } from "react-hook-form";
import PreviewInvitationButton from "@components/Merchants/Common/PreviewInvitationButton/PreviewInvitationButton";

function EnterprisePrimaryAccountHolder() {
  const { watch, setValue } = useFormContext();
  const values = watch();

  const handleClosePrimaryAccountHolder = (
    data: TCreatePrimaryAccountHolder,
  ) => {
    setValue("primary_account_holder", data);
  };

  const editHandler = () => {
    NiceModal.show(CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL, {
      onClose: handleClosePrimaryAccountHolder,
      data: values.primary_account_holder,
      isEnterprise: true,
    });
  };

  if (!values.primary_account_holder?.email) return null;

  const { enterprise_name } = values.enterprise_info || {};

  return (
    <MerchantSectionContainer
      sectionTitle="Primary Account Holder"
      editHandler={editHandler}
    >
      <Grid container rowSpacing={0.5} columnSpacing={2}>
        <Grid item xs={12} sm={4.5}>
          <Text fontWeight="light" color={palette.neutral[80]}>
            Email
          </Text>
        </Grid>
        <Grid item xs={12} sm={7.5}>
          <Text fontWeight="book" color={palette.neutral[80]}>
            {values.primary_account_holder?.email}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <RHFCheckbox
            name="primary_account_holder.invite"
            label="Invite upon creation"
          />
        </Grid>
        <Grid item xs={12} display="flex">
          <PreviewInvitationButton
            title="Preview Invitation"
            receiverName={enterprise_name}
            isSendingToProvider={true}
          />
        </Grid>
      </Grid>
    </MerchantSectionContainer>
  );
}

export default EnterprisePrimaryAccountHolder;
