import {
  CheckedEmptyCircleIcon,
  CheckedFilledCircleIcon,
  ConversationsChallengesIcon,
  NewMessageIcon,
  RejectedChallengeCustomIcon,
} from "@assets/icons";
import { Text } from "@common/Text";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Box, Stack } from "@mui/material";
import ImagesStacker from "features/Minibuilders/Conversations/utils/ImagesStacker";
import { ReactNode, useCallback } from "react";
import { TChallenge } from "../../hooks/useListChallenges";
import {
  TChallengeStatus,
  challengesWithCustomRejectedIcon,
} from "./ChallengeLayout";
import { useOpenChallengeConversation } from "./hooks/useOpenChallengeConversation";
import ChallengeTypeChip from "../ChallengeTypeChip";
import { palette } from "@palette";
import { CircleDashed, Warning } from "@phosphor-icons/react";

export type ExtendedTChallengeStatus = TChallengeStatus | "rejected";
type Props = Pick<
  TChallenge,
  | "hasUnreadMessages"
  | "totalUsersCommentedInThread"
  | "userAvatarsInThread"
  | "challengeTypeName"
> & {
  challengeStatus: ExtendedTChallengeStatus;
  displayName: string;
  isAcquirerPortal: boolean;
  challengeId: number | undefined;
  threadId: number;
  challengeSlug: string;
};

export const IconStatusMapper: Record<ExtendedTChallengeStatus, ReactNode> = {
  open: (
    <CircleDashed
      data-testid="open-challenge-icon"
      color="#575353"
      width={24}
      height={24}
    />
  ),
  ready_for_verification: <CheckedEmptyCircleIcon width={24} height={24} />,
  closed: <CheckedFilledCircleIcon width={24} height={24} />,
  rejected: <RejectedChallengeCustomIcon width={24} height={24} />,
  create: <Warning weight="fill" color="#FF8124" width={24} height={24} />,
};

const ChallengeHeader = ({
  challengeStatus,
  displayName,
  challengeId,
  challengeSlug,
  isAcquirerPortal,
  hasUnreadMessages,
  totalUsersCommentedInThread,
  userAvatarsInThread,
  challengeTypeName,
  threadId,
}: Props) => {
  const uniqueAvatars = userAvatarsInThread?.filter(
    (avatar: string, index: number) =>
      userAvatarsInThread.indexOf(avatar) === index,
  );
  const { handleSubmit } = useOpenChallengeConversation({
    challengeId,
    threadId,
    challengeTypeName,
  });

  const mapIconToStatus = useCallback(
    (challengeStatus: ExtendedTChallengeStatus): ReactNode => {
      return IconStatusMapper[challengeStatus];
    },
    [challengeStatus],
  );

  const onClick = async () => {
    handleSubmit({ hasUnreadMessages, displayName });
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack direction={"row"} gap={2} alignItems="center">
          {challengesWithCustomRejectedIcon.includes(challengeSlug) &&
          challengeStatus === "open"
            ? mapIconToStatus("rejected")
            : challengeStatus !== "rejected"
            ? mapIconToStatus(challengeStatus)
            : null}
          <ChallengeTypeChip challengeTypeName={challengeTypeName} />
          <Text
            fontSize="16px"
            lineHeight="16px"
            color={
              challengeStatus !== "closed"
                ? palette.neutral[100]
                : palette.filled.success
            }
            data-testid="challenge-type"
          >
            {displayName}
          </Text>
        </Stack>
        <HiddenComponent hidden={!isAcquirerPortal}>
          <Stack
            direction="row"
            sx={{ position: "relative" }}
            data-testid="Conversation-Component"
          >
            {totalUsersCommentedInThread > 0 && (
              <ImagesStacker
                data={uniqueAvatars.slice(0, 4)}
                condition={uniqueAvatars.length > 4}
                hasBorderedImages
                imageWidth={24}
                renderAdditional={() => (
                  <Box
                    sx={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginLeft: "-5px", // Adjust the overlap
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#403D3D",
                      border: "2px solid white",
                      color: "#FFF",
                    }}
                  >
                    <Text fontSize={12} sx={{ fontWeight: 700 }}>
                      {totalUsersCommentedInThread - 4}
                    </Text>
                  </Box>
                )}
              />
            )}

            <Box
              onClick={onClick}
              sx={{ cursor: "pointer" }}
              data-testid="Conversation-Icon"
            >
              <ConversationsChallengesIcon />
              {hasUnreadMessages && (
                <Box
                  sx={{ position: "absolute", top: "-12px", right: "-4px" }}
                  data-testid="New-Message-Icon"
                >
                  <NewMessageIcon />
                </Box>
              )}
            </Box>
          </Stack>
        </HiddenComponent>
      </Stack>
    </>
  );
};

export default ChallengeHeader;
