import React from "react";
import { country_dial_codes } from "@utils/country_dial_codes";

const useCountryCodes = () => {

  const selectCountriesList = React.useMemo(() => {
    return country_dial_codes.map((country) => ({
      label: country.name,
      value: country.code,
    }))
  }, []);

  return { selectCountriesList };
}

export default useCountryCodes;
