import { ChevronRight, PlusIcon } from "@assets/icons/RebrandedIcons";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { useRef } from "react";
import ImageItem, { ArrowButton, ImageUpload } from "./imageGallery.atoms";
import Dropzone, { ILayoutProps } from "react-dropzone-uploader";
import { SwipeDirections, useSwipeable } from "react-swipeable";
import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  ImageList,
  styled,
  Button as MuiButton,
  Skeleton,
} from "@mui/material";
import { palette } from "@palette";
import RESOURCE_BASE, {
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { useDropzoneUpload as useUploadImageNew } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useUploadImage as useUploadImageOld } from "@hooks/merchant-api/image-gallery/useUploadImage";
import { useGetAllImages } from "@hooks/merchant-api/image-gallery/useGetAllImages";
import {
  useAccessControl,
  WithAccessControl,
} from "features/Permissions/AccessControl";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

const ImageGallery = ({
  handleSelect,
  selectedImage,
}: {
  handleSelect: (item: any) => void;
  selectedImage: any;
}) => {
  const imgRef = useRef<any>();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  const {
    isUploading: isUploadingOld,
    handleDragDropChange: handleDragDropChangeOld,
    handleImageChange: handleImageChangeOld,
  } = useUploadImageOld();
  const {
    isUploading: isUploadingNew,
    handleDragDropChange: handleDragDropChangeNew,
    handleImageChange: handleImageChangeNew,
  } = useUploadImageNew();
  const isUploading = isFileUploadRefactorEnabled
    ? isUploadingNew
    : isUploadingOld;
  const handleDragDropChange = isFileUploadRefactorEnabled
    ? handleDragDropChangeNew
    : handleDragDropChangeOld;
  const handleImageChange = isFileUploadRefactorEnabled
    ? handleImageChangeNew
    : handleImageChangeOld;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const ITEMS_PER_PAGE = 11;

  const {
    images,
    page,
    isLoading,
    totalRows,
    handlePageChange,
    setPageOnSwipe,
  } = useGetAllImages(ITEMS_PER_PAGE, true);

  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const openFilePicker = () => {
    if (imgRef.current) imgRef.current.click();
  };

  const getSelectedImage = (item: any) => {
    if (typeof selectedImage === "string" && selectedImage === item.URL)
      return true;
    if (typeof selectedImage !== "string" && selectedImage?.id === item.id)
      return true;
    return false;
  };

  const handleNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    handlePageChange(event, page + 1);
  };

  const handlePrevPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    handlePageChange(event, page - 1);
  };

  const lastPage = Math.ceil(totalRows / ITEMS_PER_PAGE);

  const onEnd = (swipeDirection: SwipeDirections) => {
    if (swipeDirection === "Left" && page < lastPage)
      setPageOnSwipe((prev) => prev + 1);

    if (swipeDirection === "Right" && page > 1)
      setPageOnSwipe((prev) => prev - 1);
  };

  const handlersBox = useSwipeable({
    onSwiped: ({ dir, event }) => {
      // NOTE: this stops the propagation of the event
      // from reaching the document swipe listeners
      event.stopPropagation();

      onEnd(dir);
    },
  });

  const itemImageHeight = isDesktop ? 120 : 103;
  const imageColumns = isDesktop ? 4 : 3;
  const gap = isDesktop ? 8 : 10;

  const isNot1stPage = page > 1;
  const isNotLastPage = page < lastPage;
  return (
    <Box paddingTop="24px" marginTop={0}>
      <Stack direction="row" width="100%" {...handlersBox}>
        <>
          {isDesktop &&
            (isLoading ? (
              <Stack
                minHeight="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Skeleton variant="rounded" width={32} height={48} />
              </Stack>
            ) : (
              <ArrowButton
                align="left"
                isDisabled={!isNot1stPage}
                onClick={handlePrevPage}
              >
                <ChevronRight
                  stroke={isNot1stPage ? "#575353" : "#B8B8B8"}
                  rotate={-180}
                />
              </ArrowButton>
            ))}
          <Dropzone
            onChangeStatus={handleDragDropChange}
            disabled={isUploading}
            InputComponent={() => (
              <input
                multiple
                type="file"
                accept={ACCEPTED_IMAGE_FORMATS}
                ref={imgRef}
                onChange={handleImageChange}
                disabled={!isAddImageAllowed}
                hidden
                data-testid="image-upload-input"
              />
            )}
            LayoutComponent={({ input, dropzoneProps }: ILayoutProps) => (
              <WithAccessControl
                resource={RESOURCE_BASE.MEDIA_ITEM}
                operation={OPERATIONS.LIST}
                withPortal
                render="message"
              >
                <DropzoneArea {...dropzoneProps}>
                  <ImageList
                    gap={gap}
                    rowHeight={itemImageHeight}
                    cols={imageColumns}
                    sx={{ maxWidth: "500px", zIndex: 10 }}
                  >
                    <WithTooltipWrapper
                      hasTooltip={!isAddImageAllowed}
                      tooltipProps={{
                        show: !isAddImageAllowed,
                        message: UPLOAD_DENY_MESSAGE,
                      }}
                    >
                      <ImageUpload
                        disabled={isUploading || !isAddImageAllowed}
                        onClick={openFilePicker}
                      >
                        {input}
                        <PlusIcon />
                      </ImageUpload>
                    </WithTooltipWrapper>
                    {isLoading
                      ? Array.from({ length: ITEMS_PER_PAGE }, (_, i) => (
                          <Skeleton
                            key={i}
                            variant="rounded"
                            width={isDesktop ? 110 : 103}
                            height={isDesktop ? 110 : 103}
                          />
                        ))
                      : images?.map((item: any) => {
                          // server is taking some time(around 10-30s) to scale image to small size(so fetching  /small gives error)
                          // so for recently uploaded image -> show original one
                          const isUploadedRecently =
                            item.createdAt + 30 > new Date().getTime() / 1000;
                          const imageLink = addSizeToImage(
                            item.URL,
                            isUploadedRecently ? "original" : "small",
                          );
                          return (
                            <ImageItem
                              img={imageLink}
                              title={item.title}
                              key={item.id}
                              selected={getSelectedImage(item)}
                              handleSelectImage={() => handleSelect(item)}
                            />
                          );
                        })}
                  </ImageList>
                </DropzoneArea>
              </WithAccessControl>
            )}
          />
          {isDesktop &&
            (isLoading ? (
              <Stack
                minHeight="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Skeleton variant="rounded" width={32} height={48} />
              </Stack>
            ) : (
              <ArrowButton isDisabled={!isNotLastPage} onClick={handleNextPage}>
                <ChevronRight stroke={isNotLastPage ? "#575353" : "#B8B8B8"} />
              </ArrowButton>
            ))}
        </>
      </Stack>
      {isLoading ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          {Array.from({ length: 4 }, (_, i) => (
            <Skeleton key={i} variant="circular" width={12} height={12} />
          ))}
        </Stack>
      ) : (
        totalRows > ITEMS_PER_PAGE && (
          <MobileStepper
            activeStep={page - 1}
            steps={lastPage}
            onStepClick={(step) => setPageOnSwipe(step + 1)}
          />
        )
      )}
    </Box>
  );
};

interface MobileStepperProps {
  activeStep: number;
  steps: number;
  onStepClick?: (step: number) => void;
}

const DropzoneArea = styled(Box)(() => ({
  width: "100%",
  border: "none",
  overflow: "hidden",
}));

const MobileStepper = ({
  activeStep,
  steps,
  onStepClick,
}: MobileStepperProps) => {
  const stepsArray = Array.from(Array(steps).keys());
  const isExceedingLength = steps > 16;
  const start = 0;
  const end = 16;

  const array = isExceedingLength ? stepsArray.slice(start, end) : stepsArray;

  return (
    <StepsContainer>
      {array.map((step) => (
        <Step
          key={step}
          onClick={() => {
            if (onStepClick) onStepClick(step);
          }}
          active={activeStep === step}
          isLastInView={isExceedingLength && step === end - 1}
        />
      ))}
    </StepsContainer>
  );
};

const StepsContainer = styled(Stack)(() => ({
  background: "inherit",
  justifyContent: "center",
  flexDirection: "row",
  gap: "8px",
}));

const Step = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "isLastInView",
})<{
  active?: boolean;
  isLastInView?: boolean;
  isFirstInView?: boolean;
}>(({ active, isLastInView }) => ({
  backgroundColor: palette.liftedWhite[200],
  padding: 0,
  margin: 0,
  boxShadow: "none",
  border: "none",
  width: "12px",
  minWidth: "12px",
  height: "12px",
  borderRadius: "10px",
  position: "relative",

  ...(!active && {
    "&:hover": {
      backgroundColor: palette.gray[100],
      cursor: "pointer",
    },
  }),
  ...(active && {
    backgroundColor: palette.black[100],
    "&: hover": {
      cursor: "default",
      backgroundColor: palette.black[100],
    },
  }),
  ...(isLastInView && {
    "&::after": {
      content: `""`,
      background:
        "linear-gradient(270deg, #F8F8F6 9.38%, rgba(248, 248, 246, 0.00) 100%)",
      position: "absolute",
      right: 0,
      width: "76px",
      height: "25px",
    },
  }),
}));

export default ImageGallery;
