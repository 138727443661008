import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fundraisersData } from "@components/Fundraisers/FundraisersList/data";
import {
  isBefore,
  isAfter,
  isEqual,
  format,
  differenceInDays,
  subDays,
  sub,
} from "date-fns";
import { EditorState } from "draft-js";
import { findIndex, initial } from "lodash";
import { toUnixDateFormat } from "@utils/date.helpers";
import {
  replaceArrayTypeValues,
  replaceValues,
  withAppendedKey,
} from "@services/filtering";
import { AmountKeys } from "@services/filtering/filtering.types";
import { filteringConst } from "@services/filtering/filtering.constants";

type FundraisersData = {
  id: string | number;
  date: string;
  time: string;
  title: string;
  imageUrl: string;
  type: string;
  amount: number;
  conversion: string;
  average: number;
  transactions: number;
  visitors: number;
  status: string;
};

export type AnyAmountType = {
  id: string;
  title: string;
  active: boolean;
  amount: string;
  description?:
    | string
    | {
        enabled: false;
        text: string;
      };
  min_max: {
    enabled: boolean;
    min: string;
    max: string;
  };
};

export type AmountType = {
  id: string;
  title: string;
  amount: string;
  active: boolean;
  thumbnail?: string | File | any;
  description?: {
    enabled: boolean;
    text: EditorState;
  };
  min_max?: {
    enabled: boolean;
    min: string;
    max: string;
  };
  isDefault?: boolean;
};

export type AmountsListType = AnyAmountType | AmountType;
export type QueryType = {
  type: string;
  conversion: string;
  amount: string;
  purchases: string; // Remove when Invoices slice is created
  "entries sold": string; // for sweepstakes, every entries state will be removed
  transactions: string;
  visitors: string;
  created: string;
  status: string;
};
export interface FundraisersState {
  sortByKey: any;
  query: QueryType;
  sorting: string;
  searchQuery: string;
  names: {
    type: string[];
    conversion: number[];
    amount: (string | number)[];
    purchases: (string | number)[]; // Remove when Invoices slice is created
    "entries sold": (string | number)[]; // for sweepstakes, every entries state will be removed
    transactions: (string | number)[];
    visitors: (string | number)[];
    created: (string | number)[];
    status: string[];
  };
  filteredFundraisersList: FundraisersData[];
  createFundraiser: {
    success: boolean;
  };
  minibuilder: {
    amounts: {
      globalAmountId: string;
      list: AmountsListType[];
    };
  };
  watchListFilter: string;
}

const initialState: FundraisersState = {
  sorting: "-createdAt",
  searchQuery: "",
  sortByKey: {},
  query: {
    type: "",
    amount: "",
    conversion: "",
    transactions: "",
    "entries sold": "", // for sweepstakes, every entries state will be removed
    purchases: "",
    visitors: "",
    created: "",
    status: "",
  },
  names: {
    type: [],
    amount: [],
    conversion: [],
    transactions: [],
    "entries sold": [], // for sweepstakes, every entries state will be removed
    purchases: [],
    visitors: [],
    created: [],
    status: [],
  },
  filteredFundraisersList: [],
  createFundraiser: {
    success: false,
  },
  minibuilder: {
    amounts: {
      globalAmountId: "2", // '1' is reserved by "Any Amount"
      list: [],
    },
  },
  watchListFilter: "",
};

type SwitchAndSelect = {
  type: "type" | "status";
  value: string;
};

type Date = {
  modifier: string;
  count: number;
  dayMonthYear: string;
  startDate: any;
  endDate: any;
  date: any;
};

type Amount = {
  title: "amount" | "transactions" | "visitors";
  values: {
    modifier: string;
    amount: number;
    amountOne: number;
    amountTwo: number;
  };
};

const fundraisersSlice = createSlice({
  name: "fundraisers",
  initialState,
  reducers: {
    updateSorting: (state: FundraisersState, action: PayloadAction<string>) => {
      state.sorting = action.payload;
    },
    setDynamicSortByKey: (
      state: FundraisersState,
      action: PayloadAction<{ key: string; params: string }>,
    ) => {
      state.sortByKey[action.payload?.key] = action.payload?.params;
    },
    updateSearchQuery: (
      state: FundraisersState,
      action: PayloadAction<string>,
    ) => {
      state.searchQuery = action.payload;
    },
    addFilter: (
      state: FundraisersState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { filteredFundraisersList } = state;
      const { value, type } = action.payload;
      const tableValuesIds = new Set(
        filteredFundraisersList.map((data) => data.id),
      );
      const newValues = fundraisersData.filter((row) => {
        return row[type].toLowerCase().includes(value.toLowerCase());
      });

      state.query[type] = replaceArrayTypeValues(
        filteringConst[type as "type"].key,
        ...state.names[type].concat(value),
      );

      state.names[type] = [...state.names[type], value];
      state.filteredFundraisersList = [
        ...state.filteredFundraisersList,
        ...newValues,
      ];

      // Remove duplicates
      const filtered = newValues.filter((item) => !tableValuesIds.has(item.id));
      state.filteredFundraisersList = [...filteredFundraisersList, ...filtered];
    },
    removeFilter: (
      state: FundraisersState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.names[type] = state.names[type].filter((val) => val !== value);
      state.query[type] = replaceArrayTypeValues(
        filteringConst[type as "type"].key,
        ...state.names[type],
      );
      state.filteredFundraisersList = state.filteredFundraisersList.filter(
        (row) => {
          return row[type].toLowerCase() !== value.toLowerCase();
        },
      );
    },
    disableFilter: (
      state: FundraisersState,
      action: PayloadAction<{
        type: "type";
      }>,
    ) => {
      state.names[action.payload.type] = [];
      state.filteredFundraisersList = [];
    },
    addDateFilter: (state: FundraisersState, action: PayloadAction<Date>) => {
      const { modifier, date, count, dayMonthYear, startDate, endDate } =
        action.payload;
      let newValues: FundraisersData[] = [];

      if (modifier === "is on or after" || modifier === "is on or before") {
        if (modifier === "is on or after") {
          newValues = fundraisersData.filter(
            (row) =>
              isEqual(new Date(row.date), new Date(date)) ||
              isAfter(new Date(row.date), new Date(date)),
          );
        } else {
          newValues = fundraisersData.filter(
            (row) =>
              isEqual(new Date(row.date), new Date(date)) ||
              isBefore(new Date(row.date), new Date(date)),
          );
        }
        state.names.created = [modifier, format(date, "dd/MM/yy")];
        state.query.created = replaceValues(
          withAppendedKey.created[modifier],
          toUnixDateFormat(new Date(date)),
        );
      } else if (modifier === "is between") {
        newValues = fundraisersData.filter(
          (row) =>
            isAfter(new Date(row.date), new Date(startDate)) &&
            isBefore(new Date(row.date), new Date(endDate)),
        );
        state.names.created = [
          format(startDate, "dd/MM/yy"),
          "-",
          format(endDate, "dd/MM/yy"),
        ];
        state.query.created = replaceValues(
          withAppendedKey.created[modifier],
          toUnixDateFormat(new Date(startDate)),
          toUnixDateFormat(new Date(endDate)),
        );
      } else if (modifier === "in the last") {
        newValues = fundraisersData.filter(
          (row) => differenceInDays(new Date(), new Date(row.date)) <= count,
        );
        state.names.created = ["last", count, dayMonthYear];
        state.query.created = replaceValues(
          withAppendedKey.created[modifier],
          toUnixDateFormat(sub(new Date(), { [dayMonthYear]: count })),
        );
      }
      state.filteredFundraisersList = newValues;
    },
    disableDateFilter: (state: FundraisersState) => {
      state.names.created = [];
      state.filteredFundraisersList = [];
      state.query.created = "";
    },
    addAmountFilter: (
      state: FundraisersState,
      action: PayloadAction<Amount>,
    ) => {
      let newValues: FundraisersData[] = [];
      const { modifier, amount, amountOne, amountTwo } = action.payload.values;
      state.names[action.payload.title] = [amountOne, amountTwo];
      if (modifier.includes("greater than") || modifier.includes("less than")) {
        if (modifier.includes("greater than")) {
          newValues = fundraisersData.filter((row) => row.amount >= amount);
        } else {
          newValues = fundraisersData.filter((row) => row.amount <= amount);
        }
        state.names[action.payload.title] = [modifier, amount];
        state.query[action.payload.title] = replaceValues(
          withAppendedKey.amount[modifier as AmountKeys],
          amount,
        );
      } else {
        newValues = fundraisersData.filter(
          (row) => row.amount > amountOne && row.amount < amountTwo,
        );
        state.names[action.payload.title] = [amountOne, amountTwo];

        state.query[action.payload.title] = replaceValues(
          withAppendedKey.amount[modifier as AmountKeys],
          amountOne,
          amountTwo,
        );
      }
      state.filteredFundraisersList = newValues;
    },
    disableAmountFilter: (
      state: FundraisersState,
      action: PayloadAction<"amount" | "transactions" | "visitors">,
    ) => {
      state.names[action.payload] = [];
      state.filteredFundraisersList = [];
      state.query.amount = "";
    },
    clearFilters: (state: FundraisersState) => {
      state.query = initialState.query;
      state.names = initialState.names;
    },
    addRangeFilter: (
      state: FundraisersState,
      action: PayloadAction<number[]>,
    ) => {
      const range = action.payload;
      state.names.conversion = range;
      state.query.conversion = replaceValues(
        withAppendedKey.conversion.template,
        ...range,
      );

      state.filteredFundraisersList = new Array(1).fill(0);
    },
    disableRangeFilter: (state: FundraisersState) => {
      state.names.conversion = [];
      state.filteredFundraisersList = [];
      state.query.conversion = "";
    },
    // --------------------------------- MINI BUILDER -------------------------------
    addAmount: (state: FundraisersState, action: PayloadAction<AmountType>) => {
      state.minibuilder.amounts.list = [
        ...state.minibuilder.amounts.list,
        action.payload,
      ];
    },
    editAmount: (
      state: FundraisersState,
      action: PayloadAction<AmountType>,
    ) => {
      const isAmountExist = (newName: string, id: string) => {
        return state.minibuilder.amounts.list.some(
          (item) => item.title === newName && id !== item.id,
        );
      };
      if (isAmountExist(action.payload.title, action.payload.id)) return;
      const updatedAmounts = [...state.minibuilder.amounts.list].map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            title: action.payload.title,
            thumbnail: action.payload.thumbnail,
            description: action.payload.description,
            amount: action.payload.amount,
            active: action.payload.active,
            isDefault: action.payload.isDefault || false,
            ...(action.payload.min_max && {
              min_max: action.payload.min_max,
            }),
          };
        }
        return item;
      });
      state.minibuilder.amounts.list = [...updatedAmounts];
    },
    editAnyAmount: (
      state: FundraisersState,
      action: PayloadAction<AnyAmountType>,
    ) => {
      const index = findIndex(state.minibuilder.amounts.list, {
        id: action.payload.id,
      });

      if (index > -1) {
        state.minibuilder.amounts.list[index] = action.payload;
      }
    },
    removeAmount: (
      state: FundraisersState,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.minibuilder.amounts.list = state.minibuilder.amounts.list.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    showAmount: (
      state: FundraisersState,
      action: PayloadAction<{ id: string }>,
    ) => {
      const index = findIndex(state.minibuilder.amounts.list, {
        id: action.payload.id,
      });

      if (index > -1) {
        state.minibuilder.amounts.list[index] = {
          ...state.minibuilder.amounts.list[index],
          active: !state.minibuilder.amounts.list[index].active,
        };
      }
    },
    setAmountList: (
      state: FundraisersState,
      action: PayloadAction<AmountsListType[]>,
    ) => {
      state.minibuilder.amounts.list = action.payload;
    },
    setIncrementGlobalFundraiserAmountId: (state: FundraisersState) => {
      state.minibuilder.amounts.globalAmountId = (
        parseInt(state.minibuilder.amounts.globalAmountId) + 1
      ).toString();
    },
    setWatchlistFilters: (
      state: FundraisersState,
      action: PayloadAction<string>,
    ) => {
      state.watchListFilter = action.payload;
    },
  },
});

export const {
  addFilter,
  removeFilter,
  disableFilter,
  addDateFilter,
  disableDateFilter,
  addAmountFilter,
  disableAmountFilter,
  addRangeFilter,
  disableRangeFilter,
  addAmount,
  showAmount,
  removeAmount,
  setAmountList,
  editAmount,
  editAnyAmount,
  clearFilters,
  updateSorting,
  updateSearchQuery,
  setIncrementGlobalFundraiserAmountId,
  setWatchlistFilters,
  setDynamicSortByKey,
} = fundraisersSlice.actions;

export const selectFilters = (state: RootState) => state.fundraisers.names;
export const selectQueryFilters = (state: RootState) => state.fundraisers.query;
export const selectFundraisersList = (state: RootState) =>
  state.fundraisers.filteredFundraisersList;
export const selectCreateFundraiser = (state: RootState) =>
  state.fundraisers.createFundraiser;
export const selectAmounts = (state: RootState) =>
  state.fundraisers.minibuilder.amounts.list;
export const sorting = (state: RootState) => state.fundraisers.sorting;

export const sortingKey = (state: RootState, reduxKey: string) =>
  state.fundraisers.sortByKey?.[reduxKey] || "";
export const watchListfilter = (state: RootState) =>
  state.fundraisers.watchListFilter;
export const searchQuery = (state: RootState) => state.fundraisers.searchQuery;
export const selectGlobalFundraiserAmountID = (state: RootState) =>
  state.fundraisers.minibuilder.amounts.globalAmountId;
export default fundraisersSlice.reducer;
