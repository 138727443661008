import { PaymentInformationCard } from "@components/PaymentForm";
import bannerImage from "@assets/images/main-canvas-img.png";
import SweepstakesDetailsForm from "./SweepstakesDetailsForm";
import PublicFormFooter from "@components/PaymentForm/PublicFormFooter";
import PublicForm from "@common/PublicForm/PublicForm";
import { useFindSweepstakeById } from "@hooks/merchant-api/sweepstakes";
import { useParams } from "react-router-dom";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { SweepstakeData } from "../MiniBuilder/BuilderFormProvider";
import { NotFoundResourcePage } from "@common/404";
import { useAppDispatch } from "@redux/hooks";
import { useEffect, useState } from "react";
import { setCurrentCampaign } from "@redux/slices/checkout";
import { PublicPeekMode } from "@common/PeekMode/types";
import CountdownContainer from "./CoundownContainer";
import { MOBILE_PUBLIC_FORM_TICKETS } from "@constants/componentRegistryConstants";
import CheckoutWrapper from "@common/PublicForm/CheckoutWrapper";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";

const SweepstakesPublicForm = ({
  previewId,
  title,
  isPeekMode,
}: PublicPeekMode) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { id } = useParams();
  const { data, isLoading, redirect } = useFindSweepstakeById(
    previewId || id,
    true,
  );
  const dispatch = useAppDispatch();
  const [sweepstakesEnded, setSweepstakesEnded] = useState(false);
  const { isDesktop } = getPublishedFormDeviceType();
  useEffect(() => {
    if (data) dispatch(setCurrentCampaign({ title: data.general.title }));
  }, [data]);

  if (isLoading) return <LoadingSpinner />;

  if (redirect) {
    return <NotFoundResourcePage />;
  }

  if (!data) {
    return null;
  }

  const authorInfos = {
    name: data.general.creatorName,
    avatar: data.general.creatorImage,
    description: data.general.creatorDescription,
  };

  return (
    <>
      <PublicForm
        type="Sweepstake"
        useAsBackground={data.general.featuredImage.useAsBackground || false}
        bannerImage={
          data.general.featuredImage.image
            ? data?.general.featuredImage.image + "/original"
            : ""
        }
        formattedProductName={data.general.title}
        campaignTitle={
          data.general.title.charAt(0).toUpperCase() +
          data.general.title.slice(1)
        }
        campaignDescription={data?.general.description}
        isPeekMode={isPeekMode}
        authorInfos={authorInfos}
        productId={id || previewId}
        accID={data?.accID}
        renderSidebar={() => (
          <>
            <SweepstakesDetailsForm
              data={data as SweepstakeData}
              isLoading={isLoading}
              drawerOpen={{
                ...(!isDesktop && {
                  ID: MOBILE_PUBLIC_FORM_TICKETS,
                  open: drawerOpen,
                  setOpen: setDrawerOpen,
                  renderCheckout: () => (
                    <PaymentInformationCard
                      destinationAccountMerchantName={authorInfos.name}
                      isPeekMode={isPeekMode}
                      setDrawerOpen={setDrawerOpen}
                    />
                  ),
                }),
              }}
            />
            <CheckoutWrapper
              authorInfos={authorInfos}
              isDesktop={isDesktop}
              isPeekMode={isPeekMode}
              setDrawerOpen={setDrawerOpen}
              formOf="sweepstake"
            />
          </>
        )}
        renderFooter={() => <PublicFormFooter isPeekMode={isPeekMode} />}
      />
    </>
  );
};

export default SweepstakesPublicForm;
