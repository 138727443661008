import { isFunction } from "lodash";
import { useState } from "react";

type TData<T> = Record<string, T>;

const useHashedData = <T>() => {
  const [data, setData] = useState<TData<T> | undefined>(undefined);

  const updateAll = (callback: (oldData?: TData<T>) => TData<T>) =>
    setData(callback);

  const updateEntry = (key: string, newValue: ((prevItem: T) => T) | T) =>
    setData((prev) => {
      if (!prev) return prev;

      const updatedItem = isFunction(newValue) ? newValue(prev[key]) : newValue;
      return {
        ...data,
        [key]: updatedItem,
      };
    });

  const reset = (newData?: TData<T>) => setData(newData);

  return {
    data,
    updateAll,
    updateEntry,
    reset,
  };
};

export default useHashedData;
