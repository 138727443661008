type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const DiagonalIcon = ({
  height = 20,
  width = 20,
  stroke = "#292727",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69446 14.1124C6.4992 14.3077 6.18262 14.3077 5.98736 14.1124C5.7921 13.9172 5.7921 13.6006 5.98736 13.4053L6.81246 12.5802C7.00772 12.385 7.3243 12.385 7.51957 12.5802C7.71483 12.7755 7.71483 13.0921 7.51957 13.2873L6.69446 14.1124ZM9.05163 11.7553C8.85637 11.9505 8.53979 11.9505 8.34452 11.7553C8.14926 11.56 8.14926 11.2434 8.34452 11.0482L12.5819 6.8108L6.28109 6.68339C6.005 6.6778 5.78572 6.44946 5.7913 6.17338C5.79688 5.89729 6.02522 5.67801 6.30131 5.68359L13.7528 5.83428C13.885 5.83088 14.0183 5.87964 14.1192 5.98056C14.2219 6.08318 14.2705 6.21931 14.2653 6.35372L14.3136 13.7026C14.3154 13.9788 14.0931 14.2041 13.8169 14.2059C13.5408 14.2077 13.3155 13.9854 13.3136 13.7092L13.273 7.53386L9.05163 11.7553Z"
        fill={stroke}
      />
    </svg>
  );
};

export default DiagonalIcon;
