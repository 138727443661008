import NiceModal from "@ebay/nice-modal-react";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import EditEnterpriseMCCNew from "@components/AcquirerEnterprises/EnterprisePreview/modals/EditEnterpriseMCCNew";
import EditEnterpriseMCCOld from "@components/AcquirerEnterprises/EnterprisePreview/modals/EditEnterpriseMCCOld";

interface ModalProps {
  id: number;
  categories: TCategoryCode[];
  parentCategories: TCategoryCode[];
  onClose?: (data: any) => void;
}

const EditEnterpriseMCC = NiceModal.create(
  ({ id, categories, parentCategories, onClose }: ModalProps) => {
    const { isNewMCCSelectionImplementation } = useGetFeatureFlagValues();
    if (isNewMCCSelectionImplementation) {
      return (
        <EditEnterpriseMCCNew
          {...{ id, categories, parentCategories, onClose }}
        />
      );
    }
    return (
      <EditEnterpriseMCCOld
        {...{ id, categories, parentCategories, onClose }}
      />
    );
  },
);

export default EditEnterpriseMCC;
