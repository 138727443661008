import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetSorting,
  selectSorting,
  setSorting,
  toggleOrder,
} from "@redux/slices/sort";
import { useEffect } from "react";

type SortingProps = {
  tableName: string;
  reset?: "unmount";
};

const useSorting = ({ tableName, reset = "unmount" }: SortingProps) => {
  const dispatch = useAppDispatch();
  const { attribute, order } = useAppSelector((state) =>
    selectSorting(state, tableName),
  );

  useEffect(() => {
    return () => {
      if (reset === "unmount") {
        dispatch(resetSorting(tableName));
      }
    };
  }, []);

  const toggleSorting = (newAttribute?: string) => {
    if (newAttribute && newAttribute !== attribute) {
      dispatch(
        setSorting({
          tableName,
          params: {
            attribute: newAttribute,
            order: "asc",
          },
        }),
      );
    } else {
      dispatch(toggleOrder(tableName));
    }
  };

  return {
    attribute,
    order,
    toggleSorting,
  };
};

export default useSorting;
