import { parseAmount } from "@utils/index";
import { TRiskProfileStats } from "../data.types";

export const statsParser = (data: any): TRiskProfileStats => ({
  transactions: [
    {
      label: "Transactions",
      value: data?.stats?.numTransactions,
    },
    {
      label: "Declined",
      value: data?.stats?.numTransactionsDeclined,
    },
    {
      label: "Errors",
      value: data?.stats?.numTransactionsFailed,
    },
    {
      label: "Unique Pay Methods",
      value: data?.stats?.numPaymethods,
    },
    {
      label: "Min amount (USD)",
      value: parseAmount(
        data?.stats?.minTransactionCost
          ? data?.stats?.minTransactionCost / 100
          : 0,
      ),
    },
    {
      label: "Max amount (USD)",
      value: parseAmount(
        data?.stats?.maxTransactionCost
          ? data?.stats?.maxTransactionCost / 100
          : 0,
      ),
    },
    {
      label: "Average (USD)",
      value: parseAmount(
        data?.stats?.avgTransactionCost
          ? data?.stats?.avgTransactionCost / 100
          : 0,
      ),
    },
  ],
  merchants: [
    {
      label: "Unique merchants",
      value: data?.stats?.numMerchants,
    },
    {
      label: "Unique Authenticated Users",
      value: data?.stats?.numUsers,
    },
    {
      label: "Unique Customers",
      value: data?.stats?.numCustomers,
    },
  ],
  emails: [
    {
      label: "Unique emails",
      value: data?.stats?.numEmails,
    },
    {
      label: "Valid emails",
      value: data?.stats?.numEmailsValid,
    },
    {
      label: "Rejected emails",
      value: data?.stats?.numEmailsRejected,
    },
  ],
});
