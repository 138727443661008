import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useFormData } from "../CreateCampaignFormProvider";
import { isEmpty, isEqual, isFunction } from "lodash";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";

interface Props {
  handleUpdateStatusValue: (value: number) => void;
  disabled: boolean;
}

export default function useCampaignDetails({ handleUpdateStatusValue }: Props) {
  const { formData, setFormValues } = useFormData();
  const [loading, setLoading] = useState<boolean>(false);
  const valueRef = useRef<any>(null);
  const isSweepstakeForm = formData.campaignType.type === "Sweepstake";

  const defaultValues = formData.campaignDetails;
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });

  const schema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string(),
    image: Yup.object(),
    isBackgroud: Yup.boolean(),
    browse_more: Yup.boolean(),
    featuredImage: Yup.boolean(),
    ...(isSweepstakeForm && { endDate: Yup.date().required() }),
  });

  const methods = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    formState: { dirtyFields },
  } = methods;

  const values = watch();
  const { handlePostImage } = useUploadVariantImage();

  const saveValues = async (cb?: () => void) => {
    setLoading(true);
    const newImgURL =
      values?.image instanceof File
        ? await handlePostImage(values?.image)
        : values?.image;
    setFormValues("campaignDetails", {
      ...values,
      ...(!isEmpty(newImgURL) && {
        image: newImgURL,
      }),
    });
    setLoading(false);
    isFunction(cb) && cb();
  };

  useEffect(() => {
    if (!isEqual(values, valueRef.current)) {
      valueRef.current = values;

      (async () => {
        const count = await calculatePercentageNested(schema, values);
        handleUpdateStatusValue(count);
        saveValues();
      })();
    }
  }, [values, dirtyFields]);

  return {
    watch,
    setValue,
    methods,
    values,
    saveValues,
    loading,
    isSweepstakeForm,
  };
}
