import { styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";

const DescriptionText = ({ value }: { value: string }) => {
  return <StyledText color={palette.neutral[80]}>{value}</StyledText>;
};

const StyledText = styled(Text)({
  padding: "12px 16px",
  backgroundColor: palette.primary.background,
  borderRadius: "8px",
});

export default DescriptionText;
