import { composePermission } from "features/Permissions/AccessControl/utils";
import { IAccessControlState } from "./types";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

const permissions = [
  // acquirer permissions - GET, POST, PATCH and DELETE - all portals
  RESOURCE_BASE.ACQUIRER,
  // merchants permissions - GET, POST, PATCH and DELETE - all portals
  RESOURCE_BASE.MERCHANT,
  // enterprises permissions - GET, POST, PATCH and DELETE - all portals
  RESOURCE_BASE.ENTERPRISE,
  // team member permissions- acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.MEMBER),
  // team member permissions- enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.MEMBER),
  // team member permissions- merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.MEMBER),
  //invite team member permission- acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.INVITE),
  //invite team member permission- enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.INVITE),
  //invite team member permission- merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.INVITE),
  // control mode permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.ACCESS_CONTROL),
  // control mode permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.ACCESS_CONTROL),
  // customers permissions - GET, POST, PATCH and DELETE - all portals
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.CUSTOMER),
  // user permission - all portals
  RESOURCE_BASE.USER,
  // transfer money permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.BANK_TRANSFER),
  // transfer money permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.BANK_TRANSFER),
  // transfer money permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.BANK_TRANSFER),
  // files permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.FILES),
  // files permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.FILES),
  // files permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.FILES),
  // bank account permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.BANK_ACCOUNT),
  // bank account permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.BANK_ACCOUNT),
  // bank account permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.BANK_ACCOUNT),
  // legal entity permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.LEGAL_ENTITY),
  // legal entity permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.LEGAL_ENTITY),
  // legal entity permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.LEGAL_ENTITY),
  // business owner permission - acquirer portal
  composePermission(
    RESOURCE_BASE.ACQUIRER,
    RESOURCE_BASE.LEGAL_ENTITY,
    RESOURCE_BASE.PRINCIPAL,
  ),
  // business owner permission - enterprise portal
  composePermission(
    RESOURCE_BASE.ENTERPRISE,
    RESOURCE_BASE.LEGAL_ENTITY,
    RESOURCE_BASE.PRINCIPAL,
  ),
  // business owner permission - merchant portal
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.LEGAL_ENTITY,
    RESOURCE_BASE.PRINCIPAL,
  ),
  // media-item permission - merchant portal
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.PRODUCT,
    RESOURCE_BASE.MEDIA_ITEM,
  ),
  // add/edit/delete product - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.PRODUCT),
  // add/edit/delete product - merchant portal
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.PRODUCT,
    RESOURCE_BASE.AMOUNT,
  ),
  // view/update underwriting-checklist - merchant portal
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.UNDERWRITING_CHECKLIST,
  ),
  // GET, POST, PATCH -underwriting/comments - merchant portal
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.UNDERWRITING_COMMENTS,
  ),
  // view snapshot permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.SNAPSHOT),
  // transaction stats permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.TRANSACTION_STATS),
  // transaction stats permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.TRANSACTION_STATS),
  // transaction stats permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.TRANSACTION_STATS),
  // transaction permission - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.TRANSACTION),
  // transaction permission - enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.TRANSACTION),
  // transaction permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.TRANSACTION),
  // refund permission
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
  // send receipt permission
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.RECEIPT),
  // refund permission
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.CHARGEBACK),
  // send receipt permission
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.CHARGEBACK_REVERSAL),
  // OFAC permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.OFAC),
  // view snapshot permission - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.SNAPSHOT),
  //update underwriting - merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.UNDERWRITING),
  // category-code -acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.CATEGORY_CODE),
  // category-code -enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.CATEGORY_CODE),
  // category-code -merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.CATEGORY_CODE),
  // ip profile - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.IPPROFILE),
  // ip profile transactions - acquirer portal
  composePermission(
    RESOURCE_BASE.ACQUIRER,
    RESOURCE_BASE.IPPROFILE_TRANSACTION,
  ),
  // ip profile escalations - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.IPPROFILE_ESCALATION),
  // block transactions - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.TRANSACTION_BLOCK),
  // unblock transactions - acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.TRANSACTION_UNBLOCK),
  // ip profile - enteprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.IPPROFILE),
  // ip profile transactions - enteprise portal
  composePermission(
    RESOURCE_BASE.ENTERPRISE,
    RESOURCE_BASE.IPPROFILE_TRANSACTION,
  ),
  // ip profile escalations - enteprise portal
  composePermission(
    RESOURCE_BASE.ENTERPRISE,
    RESOURCE_BASE.IPPROFILE_ESCALATION,
  ),
  // block transactions - enteprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.TRANSACTION_BLOCK),
  // unblock transactions - enteprise portal
  composePermission(
    RESOURCE_BASE.ENTERPRISE,
    RESOURCE_BASE.TRANSACTION_UNBLOCK,
  ),
  // msp -acquirer portal
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.MSP),
  // msp -enterprise portal
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.MSP),
  // msp -merchant portal
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.MSP),
  // permissions - acquirer portal
  composePermission(
    RESOURCE_BASE.ACQUIRER,
    RESOURCE_BASE.MEMBER,
    RESOURCE_BASE.ACCESS_POLICIES,
  ),
  // permissions - enterprise portal
  composePermission(
    RESOURCE_BASE.ENTERPRISE,
    RESOURCE_BASE.MEMBER,
    RESOURCE_BASE.ACCESS_POLICIES,
  ),
  // permissions - merchant portal
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.MEMBER,
    RESOURCE_BASE.ACCESS_POLICIES,
  ),
  // pep check
  composePermission(
    RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.LEGAL_ENTITY,
    RESOURCE_BASE.PRINCIPAL,
    RESOURCE_BASE.PEP,
  ),
  // custom enterprise domain
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.CUSTOM_DOMAIN),

  // custom enterprise configuration list
  composePermission(RESOURCE_BASE.ACQUIRER, RESOURCE_BASE.CONFIGURATION),

  // custom enterprise challenges
  composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.CHALLENGE),

  // custom merchant challenges
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.CHALLENGE),

  //merchnt risk factor check
  composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.RISK_PROFILE_FACTOR),
];

export const authorizations = permissions.reduce(
  (acc, v) => ({
    ...acc,
    [v]: {
      [OPERATIONS.READ]: "allow",
      [OPERATIONS.LIST]: "allow",
      [OPERATIONS.CREATE]: "allow",
      [OPERATIONS.UPDATE]: "allow",
      [OPERATIONS.DELETE]: "allow",
      [OPERATIONS.EXPORT]: "allow",
      [OPERATIONS.SUBMIT]: "allow",
      [OPERATIONS.CANCEL]: "allow",
    },
  }),
  {},
);

const initialState: IAccessControlState = {
  authorizations,
};

export default initialState;
