export const removeSpecialChars = (str: string, number?: number) => {

  if (!str) return ""
  const sanitizedString = str.replace(/[^a-zA-Z0-9- ]/g, "");
  const slug = sanitizedString.replaceAll(/\s+/g, "-");

  const cleanSlug = slug.replace(/[-.]+/g, (match) => {
    return match.length === 1 ? match : "";
  });
  let lowercaseSlug = cleanSlug.toLowerCase();

  // If the string starts or ends with a special character, remove it
  lowercaseSlug = lowercaseSlug.replace(/^[^a-zA-Z0-9]|[^a-zA-Z0-9]$/g, "");

  // If number is defined, truncate the string
  if (number) {
    lowercaseSlug = lowercaseSlug.substring(0, number);
  }

  return lowercaseSlug;
};

export const getFullApiURL = (endpoint: string) => {
  return `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}`;
};

//Get the width of the string based on string and font
export function getStringWidth(text?: string, font?: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context && text) {
    context.font = font || "10px Give Whyte";
    return context.measureText(text).width;
  } else return 0;
}
