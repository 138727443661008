import { palette } from "@palette";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Text } from "@common/Text";
import { isFunction } from "lodash";
import { ITextProps } from "@common/Text/Text";

export type TagType =
  | "approved"
  | "refunded"
  | "chargeback"
  | "pending"
  | "sending"
  | "settled"
  | "failed"
  | "error"
  | "successful"
  | "sent"
  | "canceled"
  | "refund pending"
  | "declined"
  | "voided"
  | "authorized"
  | "chargeback reversal"
  | "requested"
  | "ready to process";

const getColorsMap = (type: TagType, filled: boolean) => {
  const colorsMap = {
    approved: filled ? palette.warning.light : palette.tag.success.hover,
    successful: filled ? palette.warning.light : palette.tag.success.hover,
    "chargeback reversal": filled
      ? palette.warning.light
      : palette.tag.success.hover,
    refunded: filled ? palette.warning.light : palette.tag.warning.hover,
    canceled: filled ? palette.warning.light : palette.neutral[80],
    chargeback: filled ? palette.error.light : palette.tag.error.hover,
    failed: filled ? palette.error.light : palette.tag.error.hover,
    declined: filled ? palette.error.light : palette.tag.error.hover,
    error: filled ? palette.error.light : palette.tag.error.hover,
    pending: filled ? palette.error.light : palette.neutral[80],
    voided: filled ? palette.error.light : palette.neutral[80],
    authorized: filled ? palette.error.light : palette.neutral[80],
    settled: filled ? palette.error.light : palette.tag.success.hover,
    sent: filled ? palette.error.light : palette.tag.success.hover,
    sending: filled ? palette.error.light : palette.tag.success.hover,
    "refund pending": filled ? palette.error.light : palette.neutral[80],
    requested: filled ? palette.error.light : palette.neutral[80],
    "ready to process": filled ? palette.warning.light : palette.tag.warning.hover,
  };

  return colorsMap[type];
};

interface TransactionTableTagProps extends BoxProps {
  type: TagType;
  filled?: boolean;
  renderComponent?: (color: string) => JSX.Element;
  isTextCenter?: boolean;
  active?: boolean;
  isOriginal?: boolean;
  textProps?: ITextProps;
  textColor?: string;
  bgColor?: string;
}

export const TransactionTableTag = ({
  type,
  filled = false,
  renderComponent,
  isTextCenter = true,
  sx,
  active = true,
  isOriginal = false,
  textProps,
  textColor,
  bgColor,
  ...rest
}: TransactionTableTagProps) => {
  const color = getColorsMap(type, filled);
  const classes = useStyles();

  return (
    <Box
      className={`${classes.wrapper} `}
      sx={{
        backgroundColor: active ? (bgColor ? bgColor : bgMap[type]) : "#EBEBEB",
        cursor: active ? "default" : "pointer",
        transition: "all 1s ease-in-out",
        ...sx,
      }}
      {...rest}
      data-testid={`transaction-tab-button-${type}`}
    >
      <Text
        color={active ? (textColor ? textColor : color) : palette.black[100]}
        variant="body"
        textTransform="capitalize"
        width="100%"
        textAlign={isTextCenter ? "center" : "left"}
        fontSize="12px"
        {...textProps}
        data-testid={`transaction-tab-${type}`}
      >
        {type}
        {isOriginal ? " (Original Transaction)" : ""}
      </Text>
      {isFunction(renderComponent) &&
        renderComponent(
          active ? (textColor ? textColor : color) : palette.black[100],
        )}
    </Box>
  );
};

const useStyles = makeStyles({
  wrapper: {
    gap: "2px",
    display: "flex",
    borderRadius: "4px",
    width: "100%",
    alignItems: "center",
    padding: "2px 8px",
    maxWidth: "160px",
    userSelect: "none",
  },
});

const backgrounds = {
  green: palette.tag.success.bg,
  orange: palette.tag.warning.bg,
  red: palette.tag.error.bg,
  gray: palette.neutral[10],
};
const { green, orange, red, gray } = backgrounds;

const bgMap: Record<TagType, string> = {
  approved: green,
  successful: green,
  "chargeback reversal": green,
  settled: green,
  sent: green,
  sending: green,

  refunded: orange,
  canceled: gray,
  "ready to process": orange,

  declined: red,
  chargeback: red,
  failed: red,
  error: red,

  pending: gray,
  voided: gray,
  authorized: gray,
  "refund pending": gray,
  requested: gray,
};
