import { customInstance } from "@services/api";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { showMessage } from "@common/Toast";
import NiceModal from "@ebay/nice-modal-react";
import { TFundraiserModalInputs } from "./FundraisersMinibuilder/types";
import {
  CREATE_EVENT_SUCCESS_MODAL,
  CREATE_INVOICE_SUCCESS_MODAL,
  EDIT_EVENT_MODAL,
  CREATE_MEMBERSHIP_SUCCESS_MODAL,
  EDIT_FUNDRAISER_MODAL,
  EDIT_INVOICE_MODAL,
  FUNDRAISER_SUCCESS_MODAL,
  EDIT_MEMBERSHIP_MODAL,
  EDIT_PAYMENT_FORM_MODAL,
  INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  PAYMENT_FORM_SUCCESS_MODAL,
} from "modals/modal_names";
import { useMediaQuery, useTheme } from "@mui/material";
import { TEventModalInputs } from "./EventsMinibuilder/types";
import { formatDataToSubmit } from "./helpers";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { TCampaignType } from "./types";

export type TSwipeDirection = "left" | "right";

interface IHorizontalSwipe {
  minSwipeDistance?: number;
  onEnd?: (swipeDirection: TSwipeDirection) => void;
}

export const useHorizontalSwipe = ({
  minSwipeDistance = 50,
  onEnd,
}: IHorizontalSwipe) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const onTouchStart = (event: any) => {
    setTouchEnd(null);
    setTouchStart(event.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (onEnd && isLeftSwipe) onEnd("left");
    if (onEnd && isRightSwipe) onEnd("right");
    setTouchStart(null);
    setTouchStart(null);
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export const useCreateProduct = (
  campaignType: TCampaignType,
  options?: { id?: string; noModal?: boolean },
) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { isADBEnabled } = useGetFeatureFlagValues();
  const createProductMutation = useMutation(
    (data: any) => {
      return customInstance({
        url: buildMerchantEndpoints(
          options?.id ? `products/${options.id}` : "products",
        ),
        method: options?.id ? "PATCH" : "POST",
        data,
      });
    },
    { retry: 1 },
  );

  const { isLoading, isSuccess, reset } = createProductMutation;

  const getCampaignModal = (): { edit: string; success: string } => {
    switch (campaignType) {
      case "fundraiser":
        return {
          edit: EDIT_FUNDRAISER_MODAL,
          success: isADBEnabled
            ? INTRODUCE_ADVANCED_FORM_BUILDER_MODAL
            : FUNDRAISER_SUCCESS_MODAL,
        };
      case "invoice":
        return {
          edit: EDIT_INVOICE_MODAL,
          success: isADBEnabled
            ? INTRODUCE_ADVANCED_FORM_BUILDER_MODAL
            : CREATE_INVOICE_SUCCESS_MODAL,
        };
      case "event":
        return {
          edit: EDIT_EVENT_MODAL,
          success: isADBEnabled
            ? INTRODUCE_ADVANCED_FORM_BUILDER_MODAL
            : CREATE_EVENT_SUCCESS_MODAL,
        };
      case "membership":
        return {
          edit: EDIT_MEMBERSHIP_MODAL,
          success: isADBEnabled
            ? INTRODUCE_ADVANCED_FORM_BUILDER_MODAL
            : CREATE_MEMBERSHIP_SUCCESS_MODAL,
        };
      case "payment-form":
        return {
          edit: EDIT_PAYMENT_FORM_MODAL,
          success: isADBEnabled
            ? INTRODUCE_ADVANCED_FORM_BUILDER_MODAL
            : PAYMENT_FORM_SUCCESS_MODAL,
        };
      default:
        return {
          edit: EDIT_FUNDRAISER_MODAL,
          success: isADBEnabled
            ? INTRODUCE_ADVANCED_FORM_BUILDER_MODAL
            : FUNDRAISER_SUCCESS_MODAL,
        };
    }
  };

  const submitProduct = (
    data: TFundraiserModalInputs | TEventModalInputs,
    action?: () => void,
  ) => {
    const refetchProductHandler = (productId: number) => {
      if (options?.id) {
        if (!options?.noModal) {
          NiceModal.hide(getCampaignModal()?.edit);
        }
        showMessage(
          "Info",
          `Changes on ${data.about.title} have been successfully saved`,
          isDesktop,
          "Changes Saved",
        );
        queryClient.removeQueries(`find-${campaignType}-by-id`);
        queryClient.refetchQueries(`get-${campaignType}-by-id`);
        queryClient.refetchQueries("get-campaign");

        if (campaignType === "event") {
          queryClient.refetchQueries("event-variants");
          queryClient.refetchQueries(["specific-product-inventory", productId]);
        }
      } else {
        if (!options?.noModal) {
          NiceModal.show(getCampaignModal()?.success, {
            data: {
              ...data,
              campaign:
                campaignType === "payment-form" ? "standard" : campaignType,
            },
            productId: productId,
          });
        }
        queryClient.refetchQueries(`get-campaign-stats`);
      }
      queryClient.refetchQueries(`list-all-${campaignType}s`);
      if (location.pathname === "/merchant/payment-forms") {
        queryClient.refetchQueries("list-all-payment-forms");
      }
      reset();
    };

    const customData = formatDataToSubmit(campaignType, data);

    createProductMutation.mutate(customData, {
      onError: (error: any) => {
        if (error.response?.data?.code !== "not_authorized") {
          const errorMessage = error.response?.data;
          showMessage("Error", errorMessage as string);
        }
      },
      onSuccess: async (res: any) => {
        if (options?.id) {
          const id = options.id;

          const allVariants = await customInstance({
            url: buildMerchantEndpoints(`products/${id}/variants`),
            method: "GET",
          });

          if (customData?.variants) {
            const customVariants = customData.variants;

            for (const amount of customData.variants) {
              if (typeof amount.id === "string") {
                await customInstance({
                  url: buildMerchantEndpoints(`products/${id}/variants`),
                  method: "POST",
                  data: amount,
                });
              } else {
                await customInstance({
                  url: buildMerchantEndpoints(
                    `products/${id}/variants/${amount.id}`,
                  ),
                  method: "PATCH",
                  data: amount,
                });
              }
            }
            allVariants?.data.forEach(async (variant: any) => {
              if (
                customVariants.findIndex((v: any) => v.id === variant.id) !== -1
              )
                return;
              await customInstance({
                url: buildMerchantEndpoints(
                  `products/${id}/variants/${variant.id}`,
                ),
                method: "DELETE",
              });
            });
          }
        }
        refetchProductHandler(res?.id);
        if (action) action();
      },
    });
  };

  return { submitProduct, isDisabled: isLoading || isSuccess };
};
