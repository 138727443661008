import React, { useCallback, useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import KotoStepper from "@common/SignUp/KotoStepper";
import CreateCampaignType from "./Steps/CreateCampaignType";
import AddDateAndLocation from "./Steps/AddDateAndLocation";
import { START_CAMPAIGN_BACKGROUND_VIDEO } from "@constants/constants";
import CreateCampaignFormProvider, {
  useFormData,
} from "./CreateCampaignFormProvider";
import CampaignDetails from "./components/CampaignDetails";
import PaymentSetup from "./components/PaymentSetup";
import PaymentTypes from "./components/PaymentTypes";

export enum CreateCampaignEnums {
  CAMPAIGN_TYPE = "Campaign type",
  DETAILS = "Campaign details",
  DATE_LOCATION = "Date & location",
  SETUP = "Payment set up",
  PAYMENT_TYPES = "Payment types",
}

type Props = {
  backToWelcomePage: () => void;
};

const stepperInit = [
  {
    label: CreateCampaignEnums.CAMPAIGN_TYPE,
    barValue: 0,
  },
  {
    label: CreateCampaignEnums.DETAILS,
    barValue: 0,
  },
  {
    label: CreateCampaignEnums.DATE_LOCATION,
    barValue: 0,
  },
  {
    label: CreateCampaignEnums.SETUP,
    barValue: 0,
  },
  {
    label: CreateCampaignEnums.PAYMENT_TYPES,
    barValue: 0,
  },
];

const CreateCampaignContent = ({ backToWelcomePage }: Props) => {
  const stepperRef = React.useRef(null);
  const { formData } = useFormData();
  const isEventForm = formData.campaignType.type === "Event";
  const isStandardForm = formData.campaignType.type === "Standard";
  const [isStepperLoaded, setIsStepperLoaded] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<CreateCampaignEnums>(
    CreateCampaignEnums.CAMPAIGN_TYPE,
  );
  const [campaignBarStatusBar, setCampaignBarStatusBar] =
    React.useState(stepperInit);

  useEffect(() => {
    setCampaignBarStatusBar(
      stepperInit.map((step) => {
        switch (step.label) {
          case CreateCampaignEnums.CAMPAIGN_TYPE:
            return {
              ...step,
              barValue: 100,
            };
          case CreateCampaignEnums.DATE_LOCATION:
            return {
              ...step,
              hidden: !isEventForm,
            };
          case CreateCampaignEnums.PAYMENT_TYPES:
            return {
              ...step,
              hidden: isStandardForm,
            };
          default:
            return step;
        }
      }),
    );
  }, [isEventForm, isStandardForm]);

  const handleUpdateStatusValue = useCallback(
    (label: string) => (value: number) => {
      setCampaignBarStatusBar((prev) => {
        let oldArr = prev;
        const index = oldArr.findIndex((obj) => obj.label === label);

        if (index !== -1) {
          oldArr = [
            ...oldArr.slice(0, index),
            { ...oldArr[index], barValue: value },
            ...oldArr.slice(index + 1),
          ];
        }
        return oldArr;
      });
    },
    [],
  );

  return (
    <Stack direction="row" gap={5} justifyContent="flex-start" height="inherit">
      <Box sx={bgStyle}>
        <video autoPlay muted loop>
          <source src={START_CAMPAIGN_BACKGROUND_VIDEO} type="video/mp4" />
        </video>
      </Box>
      <Box sx={containerStyle}>
        <KotoStepper
          sx={{ width: "100%" }}
          setStep={setStep}
          defaultStep={CreateCampaignEnums.CAMPAIGN_TYPE}
          customSteps={campaignBarStatusBar}
          ref={stepperRef}
          setIsLoaded={setIsStepperLoaded}
        />
        <Box minHeight="100%" sx={formContainerStyle}>
          <Text sx={titleStyle}>{getStepTitle(step)}</Text>
          {step === CreateCampaignEnums.CAMPAIGN_TYPE && (
            <CreateCampaignType
              handleNext={(stepperRef.current as any)?.handleNext}
              handleUpdateStatusValue={handleUpdateStatusValue(
                CreateCampaignEnums.CAMPAIGN_TYPE,
              )}
              campaignBarStatusBar={campaignBarStatusBar[0].barValue}
              backToWelcomePage={backToWelcomePage}
            />
          )}

          {step === CreateCampaignEnums.DETAILS && (
            <CampaignDetails
              handleNext={(stepperRef.current as any)?.handleNext}
              handleBack={(stepperRef.current as any)?.handleBack}
              handleUpdateStatusValue={handleUpdateStatusValue(
                CreateCampaignEnums.DETAILS,
              )}
              disabled={campaignBarStatusBar[1].barValue < 100}
            />
          )}
          {step === CreateCampaignEnums.DATE_LOCATION && isEventForm && (
            <AddDateAndLocation
              handleNext={(stepperRef.current as any)?.handleNext}
              handleBack={(stepperRef.current as any)?.handleBack}
              handleUpdateStatusValue={handleUpdateStatusValue(
                CreateCampaignEnums.DATE_LOCATION,
              )}
              disabled={campaignBarStatusBar[2].barValue < 100}
            />
          )}
          {step === CreateCampaignEnums.SETUP && (
            <PaymentSetup
              handleNext={(stepperRef.current as any)?.handleNext}
              handleBack={(stepperRef.current as any)?.handleBack}
              handleUpdateStatusValue={handleUpdateStatusValue(
                CreateCampaignEnums.SETUP,
              )}
              campaignType={formData.campaignType?.type}
              disabled={campaignBarStatusBar[3].barValue < 100}
              backToWelcomePage={backToWelcomePage}
            />
          )}
          {step === CreateCampaignEnums.PAYMENT_TYPES && !isStandardForm && (
            <PaymentTypes
              handleBack={(stepperRef.current as any)?.handleBack}
              handleUpdateStatusValue={handleUpdateStatusValue(
                CreateCampaignEnums.PAYMENT_TYPES,
              )}
              disabled={campaignBarStatusBar[4].barValue < 100}
              backToWelcomePage={backToWelcomePage}
            />
          )}
        </Box>
      </Box>
    </Stack>
  );
};

const CreateCompaign = (props: Props) => {
  return (
    <CreateCampaignFormProvider>
      <CreateCampaignContent {...props} />
    </CreateCampaignFormProvider>
  );
};
export default CreateCompaign;

const bgStyle = {
  height: "inherit",
  padding: "22px 29px",
  textAlign: "start",
  position: "relative",
  top: 0,
  maxWidth: '35%',

  "& video": {
    borderRadius: "20px",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "left",
  },

  "@media(max-width: 900px)": {
    display: "none",
  },
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
};

const titleStyle = {
  color: palette.black[200],
  fontSize: "46px",
  fontWeight: "350",
  lineHeight: "120%",
  textAlign: "left",
  mt: "52px",
  mb: 4,

  "@media(max-width: 600px)": {
    fontSize: "32px",
  },
};

const formContainerStyle = {
  maxWidth: "564px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
};

const getStepTitle = (step: CreateCampaignEnums) => {
  switch (step) {
    case CreateCampaignEnums.CAMPAIGN_TYPE:
      return "What type of campaign are you starting?";
    case CreateCampaignEnums.DETAILS:
      return "Tell us more about your campaign";
    case CreateCampaignEnums.SETUP:
      return "Set up a range of payment choices";
    case CreateCampaignEnums.PAYMENT_TYPES:
      return "Select and configure your payments";
  }
};
