interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
  datatestID?: string;
}

const BellIcon = ({
  width = 20,
  height = 20,
  stroke = "#A9AFBD",
  datatestID = "bell-icon",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      data-testid={datatestID}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_239_127749)">
        <path
          d="M5.3732 6.64118C5.63516 4.2836 7.62791 2.5 10 2.5V2.5C12.3721 2.5 14.3648 4.2836 14.6268 6.64118L14.8366 8.5297C14.9443 9.4985 15.2596 10.4327 15.7611 11.2685L16.2428 12.0714C16.5455 12.5759 16.6969 12.8281 16.7246 13.0315C16.7854 13.4772 16.5412 13.9087 16.1277 14.0858C15.939 14.1667 15.6448 14.1667 15.0565 14.1667H4.94353C4.35515 14.1667 4.06097 14.1667 3.87233 14.0858C3.45885 13.9087 3.21456 13.4772 3.27537 13.0315C3.30311 12.8281 3.45447 12.5759 3.75718 12.0714L4.23888 11.2685C4.74039 10.4327 5.05572 9.4985 5.16337 8.5297L5.3732 6.64118Z"
          stroke={stroke}
          strokeWidth="1.2"
        />
        <path
          d="M7.58519 14.7206C7.72762 15.5179 8.04149 16.2225 8.4781 16.7251C8.91471 17.2276 9.44966 17.5 10 17.5C10.5503 17.5 11.0853 17.2276 11.5219 16.7251C11.9585 16.2225 12.2724 15.5179 12.4148 14.7206"
          stroke={stroke}
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_239_127749"
          x="0"
          y="0"
          width={width}
          height={height}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_239_127749"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BellIcon;
