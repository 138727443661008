import AnimatedDialog, { BaseModalProps } from "../atoms/AnimatedDialog";
import { SxProps, styled } from "@mui/material";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { palette } from "@palette";

export type PopupVariantProps = {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  children: React.ReactNode;
  width?: number | string;
  showMobileView?: boolean;
  PopupProps?: Omit<BaseModalProps, "onClose" | "open">;
};

const PopupVariant = ({
  onClose,
  children,
  width,
  showMobileView = false,
  PopupProps,
  ...rest
}: PopupVariantProps) => {
  return (
    <StyledPopup
      width={showMobileView ? "90vw" : width || "500px"}
      onClose={onClose}
      {...PopupProps}
      {...rest}
    >
      <ErrorCatcher errorID="animated-dialog">{children}</ErrorCatcher>
    </StyledPopup>
  );
};

const StyledPopup = styled(AnimatedDialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    background: palette.background.dimmedWhite,
    boxShadow: "0px 60px 180px 0px rgba(0, 0, 0, 0.15)",
    backdropFilter: "20px",
    overflow: "hidden",
    height: "fit-content",
    maxHeight: "76%",
  },
}));

export default PopupVariant;
