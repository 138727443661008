import { SelectorOption } from "@common/TableFilters/utils";

export type TransferFilterFormFields = "enterpriseName" | "merchantName";

export type TransferStatus =
  | "pending"
  | "sending"
  | "sent"
  | "error"
  | "declined"
  | "cancelled"
  | "return"
  | "rejection error"
  | "rejected"
  | "chargeback"
  | "chargeback reversal"
  | "created"
  | "canceled"
  | "ready to process"
  | "retun error"
  | "returning"
  | "returned";
export type Amount = "0" | "1 - 5000" | "> 5000" | "custom";

export const transferFilterDefaultValues = {
  enterpriseName: [],
  merchantName: [],
  transferStatus: [],
  amount: [],
  custom: "",
};

export type TransferFormValuesType = {
  enterpriseName: SelectorOption[];
  merchantName: SelectorOption[];
  transferStatus: Array<TransferStatus>;
  amount: Array<Amount>;
  custom: string;
};

export type TransferTableRow = {
  charged: number;
  cost: number;
  endingBalance: number;
  balanceAdjustment: number;
  createdAt: number;
  displayStatus: string;
  fees: number;
  linkedAccID: number;
  merchAccName: string;
  objID: string;
  parentAccName: string;
  processingSatetName: string;
  recipientAccountBankName: string;
  recipientAccountName: string;
  recipientAccountNumberLast4: string;
  recipientAccountRoutingNumber: string;
  transactionTypeName: string;
  errorReason: string;
  declinedReason: string;
  canceledReason: string;
  updatedAt: number;
  parentAccID: number;
  merchAccID: number;
};
