import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { DateRange } from "react-date-range";

export const ArrowButton = styled(Box)(() => ({
  userSelect: "none",
  width: "24px",
  height: "24px",
  cursor: "pointer",
}));

export const NewStyledDateRange = styled(DateRange)(({ theme }) => ({
  "& .rdrDayDisabled ": {
    color: "#8F8F8F",
    backgroundColor: "#ffffff",
  },
  "& .rdrWeekDay": {
    color: palette.black[200],
    fontSize: "12px",
    fontWeight: 350,
    lineHeight: "14.4px",
  },
  "& .rdrDayNumber": {
    color: palette.black[300],
    fontSize: "14px",
    fontWeight: 350,
    padding: "8px 6px",
    lineHeight: "16.8px",
  },
  "& .rdrStartEdge": {
    borderRadius: "20px 0px 0px 20px",
    border: `1px solid ${palette.black[200]}`,
    background: palette.black[200],
    color: palette.neutral.white,
  },
  "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span ":
    {
      color: palette.black[200],
    },
  "& .rdrEndEdge": {
    borderRadius: "0px 20px 20px 0px",
    border: `1px solid ${palette.black[200]}`,
    background: palette.black[200],
    color: palette.neutral.white,
  },
  "& .rdrStartEdge.rdrEndEdge": {
    borderRadius: "20px",
  },
  "& .rdrDayToday > span > span::after": {
    display: "none",
  },
  "& .rdrMonth": {
    width: "224px",
    maxWidth: "224px",
    paddingBottom: 0,
  },
  "& .rdrMonthName": {
    display: "none",
    textAlign: "center",
    fontSize: "14px",
    color: "#403D3D",
    fontWeight: 350,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  "& .rdrMonthsHorizontal": {
    justifyContent: "space-between",
    gap: "32px",
  },
  "& .rdrMonthsVertical": {
    alignItems: "center",
    gap: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .rdrMonth": {
      width: "100%",
      maxWidth: "100%",
      paddingBottom: 0,
    },
    "& .rdrInfiniteMonths": {
      height: "440px !important",
    },
    "& .rdrMonthAndYearPickers": {
      color: palette.black[200],
      fontSize: "14px",
      fontWeight: 350,
      lineHeight: "16.8px",
    },
    "& .rdrMonthAndYearWrapper": {
      padding: 0,
      paddingBottom: "16px",
      height: "max-content !important",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));

export const StyledDateRange = styled(DateRange)(({ theme }) => ({
  "& .rdrWeekDay": {
    color: palette.black[200],
    fontSize: "12px",
    fontWeight: 350,
    lineHeight: "14.4px",
  },
  "& .rdrDayNumber": {
    color: palette.black[300],
    fontSize: "14px",
    fontWeight: 350,
    padding: "8px 6px",
    lineHeight: "16.8px",
  },
  "& .rdrStartEdge": {
    borderRadius: "20px 0px 0px 20px",
    border: `1px solid ${palette.black[200]}`,
    background: palette.black[200],
    color: palette.neutral.white,
  },
  "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span ":
    {
      color: palette.black[200],
    },
  "& .rdrEndEdge": {
    borderRadius: "0px 20px 20px 0px",
    border: `1px solid ${palette.black[200]}`,
    background: palette.black[200],
    color: palette.neutral.white,
  },
  "& .rdrStartEdge.rdrEndEdge": {
    borderRadius: "20px",
  },
  "& .rdrDayToday > span > span::after": {
    display: "none",
  },
  "& .rdrMonth": {
    width: "224px",
    maxWidth: "224px",
    paddingBottom: 0,
  },
  "& .rdrMonthName": {
    display: "none",
    textAlign: "center",
    fontSize: "14px",
    color: "#403D3D",
    fontWeight: 350,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  "& .rdrMonthsHorizontal": {
    justifyContent: "space-between",
    gap: "32px",
  },
  "& .rdrMonthsVertical": {
    alignItems: "center",
    gap: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .rdrMonth": {
      width: "100%",
      maxWidth: "100%",
      paddingBottom: 0,
    },
    "& .rdrInfiniteMonths": {
      height: "440px !important",
    },
    "& .rdrMonthAndYearPickers": {
      color: palette.black[200],
      fontSize: "14px",
      fontWeight: 350,
      lineHeight: "16.8px",
    },
    "& .rdrMonthAndYearWrapper": {
      padding: 0,
      paddingBottom: "16px",
      height: "max-content !important",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));
