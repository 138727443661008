import { startCase } from "lodash";
import { formatDistanceToNow } from "date-fns";
import {
  RiskTriggerDataFormatted,
  RiskTriggerResponseData,
} from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useRiskTriggersV2";

export const riskActivitiesParser = (data: any[]) => {
  return data?.map((activity: any) => ({
    ...activity,
    merchantFactorName: replaceKeywords(startCase(activity.merchantFactorName)),
    createdAt: activity.createdAt
      ? formatDistanceToNow(new Date(activity.createdAt * 1000), {
          addSuffix: true,
        })
      : "",
  }));
};

export const riskTriggersParser = (data: RiskTriggerResponseData[]) => {
  if (!data) return {};

  const triggersCache = data.reduce((result, item) => {
    const category = item.category;

    if (!result[category]) {
      result[category] = [];
    }

    result[category].push({
      factorID: item.factorID,
      isEnabled: item.isEnabled,
      description: item.description,
      riskPoints: item.riskPoints,
      name: item.name,
      threadID: item.threadID,
      enabledByUserAvatarImageURL: item?.enabledByUserAvatarImageURL ?? "",
      enabledByUserFirstName: item?.enabledByUserFirstName ?? "",
      enabledByUserLastName: item?.enabledByUserLastName ?? "",
      hasUnreadMessages: item?.hasUnreadMessages,
      customReason: item?.customReason,
      updatedAt: item.updatedAt,
      disabledByUserAvatarImageURL: item?.disabledByUserAvatarImageURL ?? "",
      disabledByUserFirstName: item?.disabledByUserFirstName ?? "",
      disabledByUserLastName: item?.disabledByUserLastName ?? "",
    });
    return result;
  }, {} as RiskTriggerDataFormatted);

  return triggersCache;
};

export function replaceKeywords(input: string): string {
  const replacements: { [key: string]: string } = {
    Vol: "Volume",
    Txn: "Transaction",
    Avg: "Average",
  };

  const pattern = new RegExp(Object.keys(replacements).join("|"), "gi");
  const result = input.replace(pattern, (match) => replacements[match]);

  return result;
}
