import {
  ButtonIcon,
  DividerIcon,
  HeadlineIcon,
  ImageGalleryIcon,
  ImageIcon,
  RichTextIcon,
  TimeProgressIcon,
  VideoIcon,
  WebShareSocial,
} from "@assets/builderIcons";
import {
  ImageAndTextIcon,
  ImageCardIcon,
  IntroIcon,
} from "@assets/newBuilderIcons";
import { useAppTheme } from "@theme/v2/Provider";
import { CustomTheme } from "@theme/v2/palette.interface";

const injectTheme = (Component: any) => {
  const WrappedComponent = (props: any) => {
    const theme = useAppTheme();

    return <Component {...props} theme={theme} />;
  };

  WrappedComponent.displayName = `injectTheme(${getDisplayName(Component)})`;
  return WrappedComponent;
};

// Helper function to get a component's display name
function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export const icons = {
  image_text: ImageAndTextIcon,
  divider: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <DividerIcon
        background={theme.palette.primitive?.neutral[10]}
        fill={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  heading: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <HeadlineIcon
        fill={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  single_image: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <ImageIcon
        fill={theme.palette.primitive?.neutral[10]}
        path_fill={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  button: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <ButtonIcon
        background={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  image_card: ImageCardIcon,
  intro: IntroIcon,
  rich_text: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <RichTextIcon
        background={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  image_gallery: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <ImageGalleryIcon
        background={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  single_video: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <VideoIcon
        background={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  social: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <WebShareSocial
        background={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
  goal: injectTheme(({ theme }: { theme: CustomTheme }) => {
    return (
      <TimeProgressIcon
        fill={theme.palette.primitive?.neutral[10]}
        stroke={theme.palette.primitive?.neutral[70]}
      />
    );
  }),
};
