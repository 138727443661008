import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import { Box, Stack } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TFundraiserModalInputs } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FundraiserModalSchema as schema,
  FundraiserModalDefaults as defaultValues,
} from "./utils";
import { useFindFundraiserById } from "@hooks/merchant-api/fundraisers";
import FundraisersAbout from "../FundraisersAbout";
import { useEffect } from "react";
import FundraisersPayment from "../FundraiserPayment";
import StyleSection from "../StyleSection";
import ConfigurationSection from "../ConfigurationSection";
import { useCreateProduct } from "../hooks";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import { useQueryClient } from "react-query";
import EditProductSkeleton from "../EditProductSkeleton";

type EditFundraiserModalProps = {
  id?: string;
  handleClose?: () => void;
  noModal?: boolean;
};

export const EditFundraiser = ({
  id,
  handleClose,
  noModal,
}: EditFundraiserModalProps) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindFundraiserById(id);
  const { submitProduct, isDisabled } = useCreateProduct("fundraiser", {
    id,
    noModal,
  });

  const methods = useForm<TFundraiserModalInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (data && !isLoading) {
      const formattedList = data.payment.amountsList.map((amount: any) => {
        if (amount.min_max) {
          return {
            ...amount,
            description: stringToEditorState(amount.description),
          };
        }
        return {
          ...amount,
          description: amount.description?.text || "",
        };
      });
      reset({
        about: {
          title: data.general.title,
          description: data.general.description,
        },
        style: {
          image: data.general.featuredImage.image,
          useAsBackground: data.general.featuredImage.useAsBackground,
        },
        payment_set_up: {
          payment_types: data.payment.payment_types,
          amountsList: formattedList,
        },
        configuration: {
          customer_pays_fees: data.payment.customer_pays_credit.active,
        },
      });
    }
  }, [isLoading]);

  const onClose = () => {
    reset();
    if (handleClose) {
      handleClose();
      queryClient.removeQueries("find-fundraiser-by-id");
    }
  };

  const onSubmit: SubmitHandler<TFundraiserModalInputs> = async (data) => {
    submitProduct(data, onClose);
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      HeaderProps={{
        title: "Edit",
      }}
      primaryAction={{
        label: "Save changes",
        type: "submit",
        disabled: !isDirty || isDisabled,
        form: "edit-fundraiser",
      }}
      isBody={noModal}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          display="flex"
          id="edit-fundraiser"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <EditProductSkeleton />
          ) : (
            <Stack direction="column" gap={4} flexGrow={1}>
              <FundraisersAbout
                title="About"
                titleSize="medium"
                placeHolderText="What’s the purpose of this fundraiser?"
                isEdit
              />
              <StyleSection title="Customize" titleSize="medium" isEdit />
              <FundraisersPayment
                title="Payment set up"
                titleSize="medium"
                isEdit
                campaignType={data?.general?.typeName}
              />
              <ConfigurationSection
                title="Configuration"
                titleSize="medium"
                isEdit
              />
            </Stack>
          )}
        </Box>
      </FormProvider>
    </ModalDrawer>
  );
};

const EditFundraiserModal = NiceModal.create(
  ({ id }: EditFundraiserModalProps) => {
    const modal = useModal();

    const handleClose = () => {
      modal.hide();
    };

    return <EditFundraiser id={id} handleClose={handleClose} />;
  },
);

export default EditFundraiserModal;
