const CheckboxIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_500_4422)">
        <rect x="2" y="2" width="24" height="24" rx="6" fill="white" />
        <path
          d="M11.6429 20L7 14.8667M22 9C18.7483 12.4535 16.6451 14.6873 13.8437 17.6625"
          stroke="C1C1C1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="7"
        stroke="#C1C1C1"
        strokeWidth="2"
      />
      <defs>
        <clipPath id="clip0_500_4422">
          <rect x="2" y="2" width="24" height="24" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckboxIcon;
