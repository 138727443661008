import { CheckedFilledCircleIcon } from "@assets/icons";
import { Box, Divider, Stack, styled, SxProps } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import InfoFillIcon from "@assets/icons/InfoFillIcon";
import { CircleDashed } from "@phosphor-icons/react";
import { Button } from "@common/Button";
import { Tooltip } from "@common/Tooltip";

const DisputeCircleDashedIcon = () => {
  return (
    <Box
      width={24}
      height={24}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bgcolor={palette.filled.orange}
    >
      <CircleDashed size={12} color={palette.neutral.white} />
    </Box>
  );
};

const ToolTipText = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Text
      color={palette.neutral[40]}
      fontSize={12}
      fontWeight="book"
      lineHeight="14.4px"
    >
      {title}: <span style={{ color: palette.neutral[5] }}>{description}</span>
    </Text>
  );
};

//Todo: should be updated during api integration
interface IDisputeHistoryCardProps {
  date: string;
  hasEvidence?: boolean;
  status: "done" | "ongoing";
  data: {
    title: string;
    action: string;
    description: string;
  };
  isLast?: boolean;
}

const DisputeCaseHistoryCard = ({
  date,
  hasEvidence,
  status,
  data,
  isLast,
}: IDisputeHistoryCardProps) => {
  const statusIcons = {
    done: <CheckedFilledCircleIcon width={24} height={24} />,
    ongoing: <DisputeCircleDashedIcon />,
  };
  return (
    <Stack gap={1} data-testid="dispute-history-card">
      <Text color={palette.neutral[70]} lineHeight="16.8px">
        {date}
      </Text>
      <StyledCardContainer>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            {statusIcons[status]}
            <Stack spacing={0.5}>
              <Text color={palette.neutral[70]} lineHeight="16.8px">
                {data.title}
              </Text>
              <Text fontSize={16} color={palette.neutral[80]} lineHeight="16px">
                {data.action}
              </Text>
              <Text color={palette.neutral[80]} lineHeight="16.8px">
                {data.description}
              </Text>
            </Stack>
          </Stack>
          <Tooltip
            placement="top"
            title={<ToolTipText title="Case ID" description="{id}" />}
            description={
              <ToolTipText title="Reason Code" description="{reason_code}" />
            }
            toolTipSx={toolTipSx}
          >
            <IconWrapper data-testid="info-icon">
              <InfoFillIcon width={20} height={20} fill="#A8A8A8" />
            </IconWrapper>
          </Tooltip>
        </Stack>
        {hasEvidence && (
          <>
            <Divider color={palette.neutral[30]} />
            <StyledButton size="small" background="tertiary">
              <Text fontWeight="book" color={palette.neutral[80]}>
                View Evidence
              </Text>
            </StyledButton>
          </>
        )}
      </StyledCardContainer>
      {!isLast && <StyledVerticalLine />}
    </Stack>
  );
};

const StyledCardContainer = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: palette.neutral[10],
  gap: theme.spacing(2),
}));

const StyledButton = styled(Button)({
  width: "fit-content",
  height: "fit-content",
  padding: 0,
});

const StyledVerticalLine = styled(Box)(({ theme }) => ({
  height: 28,
  width: 1,
  backgroundColor: palette.gray[100],
  marginLeft: theme.spacing(2),
}));

const IconWrapper = styled(Box)({
  "&:hover ": {
    cursor: "pointer",
  },
});

const toolTipSx: SxProps = {
  right: "8px",
  top: "8px",
  borderRadius: "8px",
  alignItems: "flex-start",
  paddingTop: "4px",
  paddingBottom: "4px",
  backgroundColor: palette.neutral[80],
};

export default DisputeCaseHistoryCard;
