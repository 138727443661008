import { SwipeableDrawer, SwipeableDrawerProps, styled } from "@mui/material";

export interface SwipeableDrawerMobileProps extends SwipeableDrawerProps {
  title?: string;
}

const SwipeableDrawerMobile = styled<React.FC<SwipeableDrawerMobileProps>>(
  ({ PaperProps, ...props }) => (
    <SwipeableDrawer
      PaperProps={{
        ...PaperProps,
        sx: {
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          padding: "32px 12px 16px",
          ...PaperProps?.sx,
        },
      }}
      {...props}
    ></SwipeableDrawer>
  ),
)({});

export default SwipeableDrawerMobile;
