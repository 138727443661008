import useGetColumns from "../../hooks/useGetColumns";
import { VirtualTransactionsTable } from "@components/VirtualList";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import { memo } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { TRANSACTION_RISK_MODAL } from "modals/modal_names";
import useAccessControl from "features/Permissions/AccessControl/useAccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

function TransactionList({
  merchantId,
  formattedParams,
}: {
  merchantId: number;
  formattedParams?: string;
}) {
  const { columns } = useGetColumns();
  const canViewIP = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE,
    operation: OPERATIONS.READ,
    withPortal: true,
  });

  const canViewTransactionList = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE_TRANSACTION,
    operation: OPERATIONS.READ,
    withPortal: true,
  });

  const clickRow = (data: any) => {
    if ((canViewIP || canViewTransactionList) && data?.row?.riskObjID) {
      NiceModal.show(TRANSACTION_RISK_MODAL, {
        ipProfileID: data?.row?.riskObjID,
      });
    }
  };

  const customParser = (data: any[]) => data;
  return (
    <VirtualTransactionsTable
      overrideColumns={columns}
      queryKey={QKEY_RISK_MONITOR_TRANSACTIONS}
      sortReduxKey={QKEY_RISK_MONITOR_TRANSACTIONS}
      queryPath={`transactions-minimal`}
      merchantId={merchantId}
      showActionHeader={false}
      sortKeyValue="-date"
      handleOnClick={clickRow}
      isBorderBottom
      emptyState="riskMonitorTransactions"
      customParser={customParser}
      style={{
        maxHeight: "80%",
      }}
      gridProps={{
        p: "0px 16px",
      }}
      {...(formattedParams && {
        filter: formattedParams,
      })}
    />
  );
}

export default memo(TransactionList);
