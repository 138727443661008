import { customInstance } from "@services/api";
import { checkPortals } from "@utils/routing";
import { useQuery } from "react-query";
import { QKEY_LIST_ENTERPRISES } from "@constants/queryKeys";

interface Props {
  page?: number;
  max?: number;
  sort?: string;
  searchText?: string;
}

export const useListAllEnterprises = ({
  page = 0,
  max = 20,
  sort = "name",
  searchText = "",
}: Props) => {
  const { isAcquirerPortal } = checkPortals();
  const params = new URLSearchParams(
    JSON.parse(
      JSON.stringify({
        page: `${page}`,
        max: `${max}`,
        sort: sort,
        q: searchText || undefined,
      }),
    ) as Record<string, string>,
  );

  const searchString = params.toString();

  const getEnterprises = () => {
    return customInstance({
      url: `/enterprises?${searchString}`,
      method: "GET",
    });
  };

  const { data, isLoading, error } = useQuery(
    [QKEY_LIST_ENTERPRISES, page, searchText],
    async () => {
      const providers = await getEnterprises();
      return providers;
    },
    { refetchOnWindowFocus: false, enabled: isAcquirerPortal, cacheTime: 0 },
  );

  return {
    data,
    isLoading,
    error,
  };
};
