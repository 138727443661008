import { ICreateEnterpriseSchema } from "./types";

export const defaultValues: ICreateEnterpriseSchema = {
  agreement: undefined,
  enterprise_info: {
    enterpriseID: "",
    enterprise_name: "",
    avatar_url: null,
    enterprise_slug: "",
    category: "",
    description: "",
    website_url: "",
    servicedPhoneNumber: "",
    enterprisePhone: "",
    billingDescriptor: "",
    hasServiceCountries: false,
    servicedCountries: "",
    enterprise_classification: "",
    classification_description: "",
  },
  primary_account_holder: {
    firstName: "",
    lastName: "",
    email: "",
    contact_phone: "",
    invite: false,
  },
  businessProfile: {
    linkedBusinessProfile: 0,
    isLinkBusinessProfile: false,
    address: {
      country: "US",
      address: "",
      city: "",
      state: "",
      zip: "",
    },
    businessType: "corporation",
    ownershipType: "public",
    legalName: "",
    DBA: "",
    taxID: "",
    tinType: null,
    ssn: "",
    contactPhone: "",
    businessOpenedAt: "",
  },
  businessOwners: [],

  configuration: {
    enterprise_fees: {
      credit_card_fee: "0",
      amex_credit_card_fee: "0",
      debit_card_fee: "0",
    },
  },
  bankAccounts: [],

  documents: {
    list: [],
  },
  categories: [],
  permissions: {},
};
