import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TImportedRow, TInviteElement, TInvitesMap } from "../types";
import NiceModal from "@ebay/nice-modal-react";
import { v4 as uuidv4 } from "uuid";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { getSelectedKeys, invitesSorter } from "../utils";
import {
  DELETE_CONFIRMATION_MODAL,
  EDIT_INVITE_MODAL,
  SEND_INVITATIONS_MODAL,
} from "modals/modal_names";
import { BulkInviteActions } from "../components/panel.atoms";
import useSorting from "@hooks/Reducers/useSorting";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  saveInvitationsDraft,
  selectInvitationsDraft,
} from "@redux/slices/enterprise/merchants";
import { useGetCurrentMerchantId } from "@hooks/common";

type TModifyHandler = (key: string, value: TInviteElement) => void;
type TValuesCount = Record<string, string[]>;

const useBulkInvite = (initialProviderId: number) => {
  const mapRef = useRef<TInvitesMap>(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [providerId, setProviderId] = useState(initialProviderId);
  const [invitesList, setInvitesList] = useState(() =>
    Array.from(mapRef.current.entries()),
  );

  const totalSelected = useRef<number>(0);
  const areAllSelected = totalSelected.current === mapRef.current.size;

  const dispatch = useAppDispatch();
  const { onClose } = useNiceModal();
  const { isMobileView } = useCustomTheme();
  const { merchantId } = useGetCurrentMerchantId();

  const savedInvitesDraft = useAppSelector((state) =>
    selectInvitationsDraft(state, merchantId),
  );
  const { attribute, order, toggleSorting } = useSorting({
    tableName: "merchant-invite",
  });

  const generateHash = () => {
    let key = uuidv4();
    // reinforcement to avoid collision
    if (mapRef.current.get(key)) key = uuidv4();
    return key;
  };

  // count items with the same name to check for duplicates
  // if valuesCount[merchantName].length === 1 the value is unique
  const valuesCount = useMemo(
    () =>
      invitesList.reduce((acc: TValuesCount, [key, value]) => {
        const keyValue = value.merchantName;
        if (!keyValue) return acc;
        if (!acc[keyValue]) {
          acc[keyValue] = [];
        }
        acc[keyValue].push(key);
        return acc;
      }, {}),
    [invitesList],
  );
  const entryIsUnique = (merchantName: string) =>
    !!merchantName && valuesCount[merchantName]?.length === 1;

  const checkIsUnique = (newName: string, originalName?: string) => {
    if (originalName && newName === originalName) {
      return valuesCount[newName]?.length < 2;
    } else if (originalName) {
      const total = valuesCount[newName];
      return !total || total?.length < 1;
    } else {
      return !valuesCount[newName];
    }
  };

  const updateList = useCallback(() => {
    const list = Array.from(mapRef.current.entries());
    const newList = attribute
      ? list.sort(invitesSorter(attribute, order))
      : list;

    setInvitesList(newList);
  }, [attribute, order]);

  useEffect(() => {
    updateList();
  }, [attribute, order]);

  const mapInit = async (callback: () => void) => {
    setIsLoading(true);
    // handle initialization asynchronously
    return new Promise((resolve) => {
      callback();
      updateList();
      resolve(undefined);
    }).finally(() => setIsLoading(false));
  };

  const importedRowsInit = (initialEntries: TImportedRow[]) => {
    mapInit(() => {
      const hashedData: [string, TInviteElement][] = initialEntries.map(
        (entry) => {
          const key = uuidv4();
          const entryValue: TInviteElement = {
            ...entry,
            checked: true,
          };
          return [key, entryValue];
        },
      );
      mapRef.current = new Map(hashedData);
      totalSelected.current = mapRef.current.size;
    });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (savedInvitesDraft.length > 0) {
      setIsLoading(true);
      // delay init to handle animations
      timeout = setTimeout(() => {
        mapInit(() => {
          mapRef.current = new Map(savedInvitesDraft);
          totalSelected.current = savedInvitesDraft.reduce(
            (acc, [key, value]) => {
              if (value.checked) return (acc += 1);
              return acc;
            },
            0,
          );
        });
      }, 400);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
      dispatch(
        saveInvitationsDraft({
          merchantId,
          entries: Array.from(mapRef.current.entries()),
        }),
      );
    };
  }, []);

  const setInvite: TModifyHandler = (key, value) => {
    mapRef.current.set(key, value);
    updateList();
  };

  const addInvite = (value: TInviteElement) => {
    const key = generateHash();
    // TODO modal to add invites (part of MMM002)
    // NiceModal.show(ADD_INVITE_MODAL, {
    //   checkIsUnique,
    //   handleSubmit: setInvite(key, value),
    // });
  };

  const confirmRemoval = (cb: VoidFunction, totalItems?: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "merchantInvitation",
      itemName: totalItems,
      deleteHandler: cb,
    });
  };

  const editInvite: TModifyHandler = (key, currentValue) => {
    NiceModal.show(EDIT_INVITE_MODAL, {
      merchantName: currentValue.merchantName,
      pahEmail: currentValue.pahEmail,
      checkIsUnique,
      handleDelete: () => confirmRemoval(() => removeInvite(key)),
      handleSubmit: (newValue: Partial<TInviteElement>) =>
        setInvite(key, {
          ...currentValue,
          ...newValue,
        }),
    });
  };

  const toggleSelectItem = (key: string) => {
    const prevItem = mapRef.current.get(key);
    if (!prevItem) return;
    setInvite(key, { ...prevItem, checked: !prevItem.checked });

    if (prevItem.checked) {
      totalSelected.current -= 1;
    } else {
      totalSelected.current += 1;
    }
  };

  const toggleSelectAll = () => {
    Array.from(mapRef.current.keys()).forEach((key) => {
      const prevItem = mapRef.current.get(key);
      if (prevItem) {
        mapRef.current.set(key, { ...prevItem, checked: !areAllSelected });
      }
    });
    totalSelected.current = !areAllSelected ? mapRef.current.size : 0;
    updateList();
  };

  const removeInvite = (key: "all" | string | string[]) => {
    if (key === "all") {
      // remove all
      mapRef.current = new Map();
      totalSelected.current = 0;
    } else if (Array.isArray(key)) {
      // remove bulk
      key.forEach((k) => mapRef.current.delete(k));
      totalSelected.current -= key.length;
    } else {
      // remove single item
      mapRef.current.delete(key);
      totalSelected.current -= 1;
    }
    updateList();
  };

  const deleteSelected = () => {
    if (totalSelected.current === 0) return;

    if (totalSelected.current === mapRef.current.size) {
      removeInvite("all");
    } else if (totalSelected.current === 1) {
      const itemKey = getSelectedKeys(mapRef.current, "single");
      !!itemKey && removeInvite(itemKey);
    } else {
      const itemKeys = getSelectedKeys(mapRef.current, "bulk");
      !!itemKeys && itemKeys.length > 0 && removeInvite(itemKeys);
    }
  };

  const handleSendInvitations = () => {
    // TODO API integration
    // send only invites that have no issue
    onClose();
  };

  const hasSelectedItems = totalSelected.current > 0;
  const actions: BulkInviteActions[] = [
    {
      label: "Delete Selected",
      onSelect: () =>
        confirmRemoval(
          deleteSelected,
          areAllSelected ? "all" : `${totalSelected.current}`,
        ),
      hidden: !hasSelectedItems,
      disabled: false,
      labelProps: {
        sx: {
          color: palette.filled.red,
        },
      },
    },
    {
      label: "Cancel",
      onSelect: onClose,
      hidden: isMobileView,
      disabled: false,
    },
    {
      label: "Send Invitations",
      onSelect: () => {
        NiceModal.show(SEND_INVITATIONS_MODAL, {
          total: totalSelected.current,
          handleSubmit: handleSendInvitations,
        });
      },
      hidden: false,
      disabled: !hasSelectedItems || !providerId,
      tooltipProps: {
        show: !hasSelectedItems || !providerId,
        message: !providerId
          ? "Provider is missing"
          : "Select the merchants to whom you want to send an invitation",
      },
    },
  ];

  return {
    actions,
    data: invitesList,
    areAllSelected,
    isLoading,
    importedRowsInit,
    toggleSelectItem,
    toggleSelectAll,
    addInvite,
    editInvite,
    entryIsUnique,
    providerId,
    setProviderId,
    sorting: {
      attribute,
      order,
      toggleSorting,
    },
  };
};

export default useBulkInvite;
