import { Badge, Box, Stack, StackProps, styled } from "@mui/material";
import { Image } from "@common/StyledImage/Image";
import { ActionsComponent } from "@common/Table/components/ActionsComponent";
import { TruncateText } from "@common/Text";
import { StarFilledIcon } from "@assets/rebrandIcons";
import { palette } from "@palette";
import placeholder from "assets/images/Placeholder.png";
import { NewStarIcon } from "@assets/icons";
import { stopEventPropagation } from "@utils/helpers";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { BadgeProps } from "@mui/material";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { selectMerchantStatusName } from "@redux/slices/enterprise/merchants";
import { useAppSelector } from "@redux/hooks";
import { useBulkMarkMerchants } from "../hooks/useBulkMarkMerchants";
import { Checkbox } from "@common/Checkbox";
import { useGetFeatureFlagValues } from "../../../FeatureFlags/useGetFeatureFlagValues";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import useCheckSponsor from "@hooks/common/useCheckSponsor";

const TOOLTIP_DELAY = 1000;

interface MerchantMainInfoProps extends StackProps {
  row: any;
  removeFromWatchlistHandler: VoidFunction;
  addToWatchlistHandler: VoidFunction;
  isAcquirerPortal?: boolean;
}

function MerchantMainInfo({
  row,
  removeFromWatchlistHandler,
  addToWatchlistHandler,
  isAcquirerPortal = true,
  ...rest
}: MerchantMainInfoProps) {
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { isMobileView } = useCustomTheme();
  const statusName = useAppSelector(selectMerchantStatusName);

  const { isSponsorBulkApprovalEnabled } = useGetFeatureFlagValues();

  const { checkIfMerchantMarked, toggleMark, checkIfMerchantDisabled } =
    useBulkMarkMerchants();

  const tooltipProps = {
    enterDelay: TOOLTIP_DELAY,
    enterNextDelay: TOOLTIP_DELAY,
    disableHoverListener: !isUpdateUnderwriterAllowed,
  };
  const { hasSponsorAccess } = useCheckSponsor();

  const watchlistActionProps = row.watchlist
    ? {
        fullWidth: true,
        title: "Remove from watchlist",
        onClick: stopEventPropagation(removeFromWatchlistHandler),
        icon: () => <StarFilledIcon />,
        tootipProps: tooltipProps,
        disabled: !isUpdateUnderwriterAllowed,
      }
    : {
        title: "Add to watchlist",
        onClick: stopEventPropagation(addToWatchlistHandler),
        icon: () => <NewStarIcon />,
        tootipProps: tooltipProps,
        disabled: !isUpdateUnderwriterAllowed,
      };

  const merchantImage = row?.imageURL ? `${row.imageURL}/thumb` : placeholder;

  const isSponsorTable =
    statusName === StatusNames.aquirerSponsor && isSponsorBulkApprovalEnabled;

  return (
    <Wrapper {...rest}>
      {isSponsorTable ? (
        <CustomToolTip
          message={ACTION_DENY_MESSAGE}
          showToolTip={!hasSponsorAccess}
        >
          <Checkbox
            sx={{
              pl: "8px",
              "& .MuiCheckbox-root": {
                pointerEvents: "auto",
              },
            }}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              toggleMark({ id: row.accID, mark: e.target.checked });
            }}
            disabled={checkIfMerchantDisabled(row.statusName)}
            checked={checkIfMerchantMarked(row)}
            data-testid="table-row-checkbox"
          />
        </CustomToolTip>
      ) : (
        <ActionsComponent {...watchlistActionProps} />
      )}
      <MerchantImage
        src={merchantImage}
        unread={
          (row?.hasUnreadMessages || row?.hasUnrepliedActivityMessages) &&
          !isMobileView &&
          isAcquirerPortal
        }
        isOrange={row?.hasUnrepliedActivityMessages}
      />
      <Box>
        <TruncateText
          color={palette.black[200]}
          lineHeight="16.8px"
          lineClamp={1}
          sx={{ wordBreak: "break-all" }}
        >
          {row.name}
        </TruncateText>
        {isAcquirerPortal && (
          <TruncateText
            fontSize={12}
            color={palette.neutral[70]}
            fontWeight="book"
            lineClamp={1}
            onHoverShowAll
          >
            {row.parentName}
          </TruncateText>
        )}
      </Box>
    </Wrapper>
  );
}

type MerchantImageProps = {
  src: string;
  unread?: boolean;
  height?: number;
  width?: number;
  isOrange?: boolean;
};

export const MerchantImage = ({
  src,
  unread,
  height = 40,
  width = 40,
  isOrange,
}: MerchantImageProps) => {
  return (
    <CustomBadge isOrange={isOrange} variant="dot" invisible={!unread}>
      <Image
        height={height}
        width={width}
        sx={{
          borderRadius: "3px",
        }}
        src={src}
      />
    </CustomBadge>
  );
};

interface CustomBadgeProps extends BadgeProps {
  isOrange?: boolean;
}

const CustomBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "isOrange",
})<CustomBadgeProps>(({ isOrange }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: isOrange ? "#FF8124" : "#6D9CF8",
    width: "14px",
    height: "14px",
    border: "2px solid #F7F7F7",
    borderRadius: "50%",
    zIndex: 1,
  },
}));

const Wrapper = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  width: "100%",
  minWidth: "100px",
}));

export default MerchantMainInfo;
