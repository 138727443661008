import * as React from "react";
// mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
// components
import { Text } from "@common/Text";
// assets
import { ArrowBackIcon, ArrowForwardIcon } from "@assets/icons";

// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { palette } from "@palette";
interface IPaginationProps {
  page?: number;
  pages: number;
  onChange?: any;
}

const styledContainer = {
  display: "flex",
  gap: "13px",
  alignItems: "center",
  justifyContent: "center",
};

const styledSelectedItem = {
  color: palette.neutral[70],
  // borderRadius: "50%",
  background: "none",
  "&.Mui-selected": {
    padding: "4px 8px",
    color: palette.neutral[80],
    background: "none",
  },
  "&.MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {},
};

const ArrowBack = () => {
  const { t } = useTranslation(namespaces.common);
  return (
    <Box sx={styledContainer}>
      <ArrowBackIcon />
      <Text>{t("buttons.Prev")}</Text>
    </Box>
  );
};

const ArrowForward = () => {
  const { t } = useTranslation(namespaces.common);
  return (
    <Box sx={styledContainer}>
      <Text>{t("buttons.next")}</Text>
      <ArrowForwardIcon width={15} height={12} />
    </Box>
  );
};

const CustomPagination: React.FC<IPaginationProps> = ({
  page = 1,
  pages,
  onChange,
}) => {
  return (
    <Stack spacing={2}>
      <Pagination
        shape="circular"
        count={pages}
        page={page}
        renderItem={(item) => {
          return (
            <PaginationItem
              components={{
                previous: ArrowBack,
                next: ArrowForward,
              }}
              {...item}
              sx={styledSelectedItem}
            />
          );
        }}
        onChange={onChange}
      />
    </Stack>
  );
};

export default CustomPagination;
