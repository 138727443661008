import { Image } from "@common/StyledImage/Image";
import { Text, TruncateText } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";

import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { GoogleMaps } from "@services/google-api";
import { GoodClockIcon } from "@assets/icons";
import { AuthorInfos, EventLocation, Props } from "./types";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setCartItems } from "@redux/slices/cart";
import { Button } from "@common/Button";
import { MerchantInfoIcon } from "@assets/Merchanticons";
import { useLocation, useNavigate } from "react-router-dom";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectMasqueradeMode } from "@redux/slices/app";
import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import Drawer from "@common/MobilePaymentForm/Drawer";
import { MOBILE_PUBLIC_FORM_TICKETS } from "@constants/componentRegistryConstants";
import { createPortal } from "react-dom";
import { detectMobile } from "@utils/index";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Node } from "@pages/NewAdvancedBuilder/components/Builder";
import { structuredTreeSignal } from "@pages/NewAdvancedBuilder/signals";
import { isEmpty } from "lodash";
import { useGetBuilderData } from "@pages/NewAdvancedBuilder/api/builderApi";
import { build } from "@pages/NewAdvancedBuilder/hooks/useGetBuilder";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { useSignals } from "@preact/signals-react/runtime";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";

ComponentRegistry.registerLazy(MOBILE_PUBLIC_FORM_TICKETS, () => Drawer);

const defaultValues = {
  title: "",
  image: "",
  creator: "",
  description: "",
};

const Delayed = ({ children, waitBeforeShow = 0 }: Props) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

const DescriptionImage = ({
  children,
  isPeekMode = false,
}: Partial<Props> & { isPeekMode: boolean | undefined }) => {
  return isPeekMode ? <Delayed>{children}</Delayed> : children;
};
const CampaignInfo = ({
  renderTimer,
  formattedProductName,
  campaignTitle,
  bannerImage,
  campaignDescription,
  location,
  hasMap,
  type,
  authorInfos,
  isPeekMode,
  ...props
}: {
  renderTimer?: () => ReactNode;
  formattedProductName: string;
  campaignTitle: string;
  bannerImage?: string;
  campaignDescription: string;
  isDesktop: boolean;
  location: EventLocation;
  hasMap: boolean;
  authorInfos: Partial<AuthorInfos>;
  type: string;
  isPeekMode: boolean | undefined;
}) => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setCartItems([]));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...(props.isDesktop && {
          width: "60%",
          borderRight: ".5px solid #D1D1D0",
        }),
      }}
    >
      <Box
        sx={{
          backgroundColor: "#f8f8f6",
          padding: props.isDesktop ? "0px 16px" : "40px  24px",
        }}
      >
        <FadeUpWrapper delay={50}>{renderTimer && renderTimer()}</FadeUpWrapper>
        <FadeUpWrapper delay={100}>
          <TruncateText
            lineClamp={2}
            variant="headline"
            fontSize={props.isDesktop ? "44px" : "24px"}
            lineHeight="45.6px"
            sx={{
              fontWeight: 300,
              color: "#131212",
            }}
            data-testid="public-form-title"
          >
            {campaignTitle}
          </TruncateText>
        </FadeUpWrapper>
        {bannerImage && !props.isDesktop && (
          <DescriptionImage isPeekMode={isPeekMode}>
            <Image
              src={bannerImage}
              width="100%"
              height="128px"
              style={{
                objectFit: "cover",
                width: "100%",
                borderRadius: 0,
              }}
            />
          </DescriptionImage>
        )}
        <FadeUpWrapper delay={150}>
          <Box
            sx={{
              display: "flex",
              gap: "4px",
            }}
          >
            <TruncateText
              lineClamp={2}
              variant="caption"
              fontWeight="medium"
              color="#575353"
            >
              {type}
            </TruncateText>
            <Text variant="caption" color="#575353">
              by {authorInfos.name}
            </Text>
          </Box>
        </FadeUpWrapper>
        {location && (
          <FadeUpWrapper delay={200}>
            <Stack gap={1} sx={{ marginTop: "24px" }}>
              <Text
                variant="body"
                color={palette.neutral[800]}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",

                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <Box
                  component="span"
                  minWidth={20}
                  display="flex"
                  alignItems="center"
                >
                  <GoodClockIcon />
                </Box>
                {location.date.day ? (
                  location.date.createDate(
                    location.date.day,
                    location.date.eventTime,
                  )
                ) : (
                  <Text>
                    {location.date.createDate(
                      location.date.eventStartDate,
                      location.date.eventTime,
                    )}{" "}
                    to{" "}
                    {location.date.createDate(
                      location.date.eventEndDate,
                      location.date.eventTime,
                    )}
                  </Text>
                )}
              </Text>

              <Text
                variant="body"
                color={palette.neutral[800]}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <Box
                  component="span"
                  minWidth={20}
                  display="flex"
                  alignItems="center"
                >
                  {location.eventIcon()}
                </Box>
                <span style={{ textAlign: "center" }}>
                  {location.eventURL()}
                </span>
              </Text>
            </Stack>
          </FadeUpWrapper>
        )}
        <Stack mt="24px" mb="0px">
          <FadeUpWrapper delay={200}>
            <Text
              data-testid="public-form-description"
              sx={{
                fontWeight: 400,
                lineHeight: "135%",
                fontSize: "18px",
                letterSpacing: "-0.01em",
                overflowWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {campaignDescription}
            </Text>
          </FadeUpWrapper>
          {((bannerImage && !location) || (bannerImage && hasMap)) &&
            props.isDesktop && (
              <FadeUpWrapper delay={250}>
                <DescriptionImage isPeekMode={isPeekMode}>
                  <Image
                    src={bannerImage}
                    width="100%"
                    height="auto"
                    style={{
                      objectFit: "cover",
                      maxHeight: "400px",
                      marginTop: "24px",
                      borderRadius: "12px",
                    }}
                  />
                </DescriptionImage>
              </FadeUpWrapper>
            )}
          {location && (
            <FadeUpWrapper delay={300}>
              <Stack width="100%" gap={1}>
                {bannerImage && !hasMap && props.isDesktop && (
                  <DescriptionImage isPeekMode={isPeekMode}>
                    <Image
                      src={bannerImage}
                      width="100%"
                      height="auto"
                      style={{
                        objectFit: "cover",
                        maxHeight: "500px",
                        marginTop: "24px",
                      }}
                    />
                  </DescriptionImage>
                )}

                {hasMap && (
                  <GoogleMaps
                    address={location.currentLocation}
                    isLoaded={location.isLoaded}
                    containerStyle={{
                      height: "300px",
                      borderRadius: "16px",
                      marginTop: "24px",
                    }}
                  />
                )}
              </Stack>
            </FadeUpWrapper>
          )}
          {(authorInfos.avatar || authorInfos.description) && (
            <Stack
              gap={2}
              // maxWidth="75%"
              padding={4}
              bgcolor="#FFF"
              borderRadius="12px"
              marginTop="48px"
            >
              {authorInfos.avatar && (
                <FadeUpWrapper delay={350}>
                  <Image
                    src={authorInfos.avatar + "/thumb"}
                    width="64px"
                    height="64px"
                    style={{ borderRadius: "12px" }}
                  />
                </FadeUpWrapper>
              )}
              {authorInfos.description ? (
                <FadeUpWrapper delay={400}>
                  <Text fontSize="14px" sx={{ fontWeight: 300 }}>
                    {authorInfos.description || ""}
                  </Text>
                </FadeUpWrapper>
              ) : (
                <></>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const MasqueradeWrapper = ({
  isMasquerade,
  children,
}: {
  isMasquerade: boolean;
  children: any;
}) => {
  const portalElement = document.getElementById(
    "masquerade-portal",
  ) as HTMLDivElement;

  if (isMasquerade && portalElement) {
    return createPortal(children, portalElement);
  } else {
    return children;
  }
};

const PublicForm = ({
  renderTimer,
  renderSidebar,
  renderFooter,
  bannerImage,
  campaignTitle,
  formattedProductName,
  campaignDescription,
  useAsBackground,
  location,
  hasMap,
  authorInfos,
  type,
  isPeekMode,
  drawerOpen,
  accID,
  productId,
}: {
  renderSidebar: () => ReactNode;
  renderFooter: () => ReactNode;
  renderTimer?: () => ReactNode;
  isPeekMode?: boolean;
  bannerImage?: string;
  campaignTitle: string;
  formattedProductName: string;
  campaignDescription?: string;
  paymentCardsNumber?: number;
  useAsBackground: boolean;
  location?: EventLocation;
  hasMap?: boolean;
  authorInfos: Partial<AuthorInfos>;
  type: string;
  drawerOpen?: {
    ID: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  };
  accID: number;
  productId?: string;
}): JSX.Element => {
  useSignals();
  const { isDesktop } = getPublishedFormDeviceType();
  const navigate = useNavigate();
  const shouldDisplayHeaderImage =
    bannerImage && useAsBackground && !isPeekMode;
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const id = selectedAccount?.id;

  const { isLoading: isADBPaymentFormLoading, data } = useGetBuilderData(
    accID,
    Number(productId),
    {
      onSuccess(data: any) {
        if (data?.Snapshot) {
          build(data.Snapshot?.HTML, campaignTitle, campaignDescription);
        }
      },
    },
  );
  const isPublished = Boolean(data?.Snapshot);
  const { name: isMasquerade } = useAppSelector(selectMasqueradeMode);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const _location = useLocation();

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = (event: any) => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const currentScrollPos = window.scrollY;
      setIsVisible(prevScrollPos > currentScrollPos || scrollTop === 0);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const isWidget = urlParams.get("widget");

  const isMobileDevice = detectMobile();
  const isWidgetOnDesktop = isWidget && !isMobileDevice;

  useEffect(() => {
    if (isWidgetOnDesktop) {
      window.document.body.style.background = "transparent";
    }
  }, []);

  if (
    isADBPaymentFormLoading ||
    (isPublished && isEmpty(structuredTreeSignal.value))
  )
    return <LoadingSpinner />;

  return (
    <Box
      sx={{
        background: palette.neutral[5],
        ...((!bannerImage || !useAsBackground) && {
          maxWidth: "100%",
          overflow: "hidden",
        }),
      }}
    >
      {drawerOpen &&
        ComponentRegistry.show(drawerOpen.ID, {
          open: !!drawerOpen.open,
          setOpen: drawerOpen.setOpen,
        })}

      {id && _location.key !== "default" && !isPeekMode && isDesktop && (
        <MasqueradeWrapper isMasquerade={!!isMasquerade}>
          <Stack
            direction="row"
            className={isVisible || isMasquerade ? "visible" : "hidden"}
            gap={2}
            alignItems="center"
            sx={{
              padding: isMasquerade ? 0 : "12px 64px",
              backgroundColor: isMasquerade ? "transparent" : "#D1D1D099",
              left: 72,
              top: 0,
              zIndex: 1,
              position: "sticky",
              transition: "transform 0.3s ease-out",

              "&.hidden": {
                transform: "translateY(-100%)",
              },
            }}
          >
            <Button
              sx={{
                padding: "8px 24px",
                whiteSpace: "nowrap",
              }}
              size="small"
              startIcon={<MerchantInfoIcon stroke={palette.neutral.white} />}
              onClick={() => {
                if (_location.key !== "default") {
                  navigate(-1);
                }
              }}
              disabled={_location.key === "default"}
              data-testid="back-to-portal-button"
            >
              Back to portal
            </Button>
            {/* <Text color={palette.neutral.white}>{authorInfos.name}</Text> */}
          </Stack>
        </MasqueradeWrapper>
      )}
      {bannerImage && useAsBackground && !isPeekMode && (
        <img
          src={bannerImage}
          width="100%"
          height="310px"
          style={{ objectFit: "cover", zIndex: 999 }}
        />
      )}
      <Box
        sx={{
          ...bgStyle,
          ...(!isDesktop && {
            paddingBlock: "0px",
          }),
          display: "flex",
          flexDirection: "column",
          ...(bannerImage &&
            useAsBackground && {
              "&:before": {
                content: '""',
                position: isPeekMode ? "absolute" : "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: isPeekMode ? 0 : -1,
                width: isPeekMode ? "100%" : "100vw",
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${bannerImage})`,
                filter: "blur(8px)",
                opacity: isWidgetOnDesktop ? 0.5 : 1,
              },
            }),
        }}
      >
        <Box
          sx={{
            ...(isDesktop ? containerStyle : mobileContainerStyle),
            width: isWidgetOnDesktop ? "100%" : "100%",
            ...(isPeekMode && { zIndex: 1 }),
            ...(!isDesktop && {
              flexWrap: "wrap",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }),
            ...(shouldDisplayHeaderImage && {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }),
          }}
        >
          {!isEmpty(structuredTreeSignal.value) && isPublished ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                paddingX: "16px",
                zIndex: 2,
              }}
            >
              <Node node={structuredTreeSignal.value} isPublished />
              {!isMobileDevice && (
                <Box
                  sx={{
                    width: "1px",
                    height: "341px",
                    backgroundColor: "#D1D1D0",
                    marginLeft: "16px",
                  }}
                />
              )}
            </Box>
          ) : (
            <CampaignInfo
              renderTimer={renderTimer}
              isDesktop={isDesktop}
              formattedProductName={formattedProductName}
              campaignTitle={campaignTitle}
              bannerImage={bannerImage}
              isPeekMode={isPeekMode}
              campaignDescription={
                campaignDescription || defaultValues.description
              }
              location={location!}
              hasMap={!!hasMap}
              authorInfos={authorInfos}
              type={type}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...(isDesktop && {
                width: "40%",
                gap: "16px",
              }),
              ...(!isDesktop && {
                marginTop: "16px",
                width: "100%",
              }),
              zIndex: 3,
            }}
          >
            <Box width="100%" px={isDesktop ? "16px" : undefined}>
              {renderSidebar()}
            </Box>
          </Box>
        </Box>
        <Box
          flexGrow={1}
          sx={{
            backgroundColor: palette.neutral[5],
            maxWidth: "1232px",
            width: "100%",
            margin: "0 auto",
            zIndex: 1,
            height: "auto",
          }}
        />
        {renderFooter()}
        {!isDesktop && <Box height="70px" />}
      </Box>
    </Box>
  );
};

const bgStyle = {
  minHeight: "100vh",
  position: "relative",
};

const containerStyle = {
  background: palette.neutral[5],
  borderRadius: "32px 32px 0px 0px",
  maxWidth: "1232px",
  padding: "8px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "start",
  paddingBlock: "50px 16px",
};
const mobileContainerStyle = {
  background: palette.neutral[5],
  margin: "0 auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "start",
  paddingBlock: "40px 16px",
};

export default PublicForm;
