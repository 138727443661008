import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { Text } from "@common/Text";
import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import { Box, styled } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import Header from "./Header";
import DottedPercentageBar from "./DottedPercentageBar";
import ChallengesList from "./Challenges/ChallengesList";
import { IParsedData } from "../data.types";
import { UnderwritingOverview } from "./UnderwritingOverview";
import { palette } from "@palette";
import { UnderwritingActions } from "./UnderwritingActions";
import {
  EnterpriseActionsHistory,
  MerchantActionsHistory,
} from "../sections/StatusReview/components/ActionsHistory";

interface Props {
  handleClosePanel: () => void;
  id: number;
  merchantData: IParsedData;
  headerData: {
    name: any;
    imageURL: any;
  };
  isAcquirer: boolean;
  isEnterprise?: boolean;
}

const ManageUnderwritingChallenges = ({
  headerData,
  id,
  merchantData,
  handleClosePanel,
  isEnterprise,
  ...rest
}: Props) => {
  const hasNotMerchantPermission = useAppSelector(
    (state) => state.app?.permissions?.["get-merchant-by-id"],
  );

  const isMerchantStatusNameCanonic = [
    "declined",
    "suspended",
    "approved",
    "pre-approval",
    "auto-approval",
  ].includes(merchantData.status.statusName);

  const isEnterpriseStatusNameCanonic = [
    "declined",
    "suspended",
    "approved",
  ].includes(merchantData.status.statusName);

  const showUnderwritingActions = isEnterprise
    ? !isEnterpriseStatusNameCanonic
    : !isMerchantStatusNameCanonic;

  return (
    <WithMissingPermissionModule
      message="Manage underwriting hidden"
      notAuthorized={hasNotMerchantPermission}
    >
      <Wrapper
        width={CAMPAIGN_PANEL_WIDTH - 30 + "px"}
        component="main"
        data-testid="double-panel-underwriting-challenge"
      >
        <Header
          firstPanelShowing
          toggleSecondModal={handleClosePanel}
          headerData={headerData}
          page={"default"}
        >
          <Text
            sx={{ fontSize: "18px", fontWeight: 400, letterSpacing: "-0.18px" }}
          >
            Underwriting
          </Text>
        </Header>
        <Box my={1}>
          <Text fontSize="14px" sx={{ fontWeight: 400, color: "#8F8F8F" }}>
            Complete challenges to raise the completion percentage
          </Text>
        </Box>
        <Box>
          <DottedPercentageBar
            percentageValue={
              merchantData.merchantInfo.underwritingScorePercentage
            }
            isApproved={merchantData.status.statusName === "approved"}
          />
        </Box>
        <Box paddingY={2}>
          <UnderwritingOverview
            isApprovedOwner={merchantData.status.approvedOwner}
            isApprovedBankAccount={merchantData.status.hasApprovedBankAccount}
            isApprovedBusinessProfile={
              merchantData.status.hasApprovedLegalEntity
            }
            isAgreementSigned={merchantData.status.approvedAgreement}
          />
        </Box>
        {showUnderwritingActions && (
          <Box
            paddingY={2}
            sx={{
              background: palette.neutral.white2,
              padding: "16px 24px",
              borderRadius: "16px",
            }}
          >
            <UnderwritingActions
              isEnterprise={isEnterprise}
              status={merchantData.status}
              underwritingScorePercentage={
                merchantData.merchantInfo.underwritingScorePercentage
              }
              name={headerData.name}
            />
          </Box>
        )}
        {!isEnterprise && isMerchantStatusNameCanonic && (
          <MerchantActionsHistory data={merchantData.status} />
        )}

        {isEnterprise && isEnterpriseStatusNameCanonic && (
          <EnterpriseActionsHistory data={merchantData.status} />
        )}

        <ChallengesList
          id={id}
          merchantData={merchantData}
          isAcquirerPortal={rest.isAcquirer}
          isEnterprise={Boolean(isEnterprise)}
        />
      </Wrapper>
    </WithMissingPermissionModule>
  );
};

export const Wrapper = styled(Box)(() => ({
  backgroundColor: "#FAFAFA",
  flex: 1,
  height: "100%",
  borderRadius: "16px",
  padding: "12px 16px",
  boxShadow: "0px 4px 15px 0px #0000000D",
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */,
}));

export default ManageUnderwritingChallenges;
