import { memo } from "react";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";
import { Stack, StackProps } from "@mui/material";
import { palette } from "@palette";
import PreviewInvitationModalCard from "./components/PreviewInvitationModalCard";
import PreviewInvitationModalFooter from "./components/PreviewInvitationModalFooter";
import { styled } from "@mui/material";
import PreviewInvitationModalHeader from "./components/PreviewInvitationModalHeader";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import PreviewInvitationModalMobileDrawer from "./components/PreviewInvitationModalMobileDrawer";

type Props = {
  receiverName?: string;
  senderName?: string;
  senderLogo?: string;
  isSendingToProvider: boolean;
};

const PreviewInvitationModal = NiceModal.create(
  ({ receiverName, senderName, senderLogo, isSendingToProvider }: Props) => {
    const { isMobileView } = useCustomTheme();
    const modal = useModal();

    const receiverCorrectName = receiverName
      ? receiverName
      : isSendingToProvider
      ? "{provider_name}"
      : "{merchant_name}";
    const senderCorrectName = isSendingToProvider
      ? "Give Team"
      : senderName
      ? senderName
      : "{Provider name}";

    if (isMobileView) {
      return (
        <PreviewInvitationModalMobileDrawer
          receiverName={receiverCorrectName}
          senderName={senderCorrectName}
          senderLogo={senderLogo}
          isSendingToProvider={isSendingToProvider}
        />
      );
    }

    return (
      <Popup
        {...muiDialogV5(modal)}
        title="Preview Invitation"
        hasCustomPadding
        containerCustomWidth="900px"
        containerCustomHeight="100%"
        customContainerStyle={{
          backgroundColor: palette.background.dimmedWhite,
        }}
        titleSx={{
          fontWeight: "350",
          fontSize: "18px",
          lineHeight: "21.6px",
        }}
        textProps={{
          fontSize: "14px",
          fontWeight: "regular",
          lineHeight: "20px",
          marginTop: "8px",
        }}
        textContainerProps={{
          height: "100%",
        }}
        isClose
        cancelLabel="Close"
        cancelButtonProps={{
          size: "medium",
          background: "primary",
        }}
        onCancel={() => modal.hide()}
        isNotTextChild
      >
        <PreviewInvitationModalContainer data-testid="preview-invitation-modal-container">
          <PreviewInvitationModalHeader
            senderName={senderCorrectName}
            senderLogo={senderLogo}
            isSendingToProvider={isSendingToProvider}
          />
          <PreviewInvitationModalCard
            receiverName={receiverCorrectName}
            senderName={senderCorrectName}
          />
          <PreviewInvitationModalFooter
            isSendingToProvider={isSendingToProvider}
          />
        </PreviewInvitationModalContainer>
      </Popup>
    );
  },
);

export default memo(PreviewInvitationModal);

const PreviewInvitationModalContainer = styled(Stack)<StackProps>(() => ({
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "40px",
  backgroundColor: palette.background.newWhite,
  padding: "24px 40px",
  borderRadius: "12px",
  overflowY: "auto",
  height: "100%",
}));
