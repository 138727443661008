import * as React from "react";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
// mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Table_V2 as Table, TableColumnType } from "@common/Table";
import { DropdownItem } from "@common/Select";
import { DOWNLOAD_REPORT_MODAL } from "modals/modal_names";
import { HiddenComponent } from "containers/HiddenComponent";
import { useGetTransactionsByProduct } from "@services/api/products/transactions";
import { useTransactionsIOC } from "@components/ManageMoney/TransactionTable/hooks";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import {
  Customer,
  Item,
} from "@components/ManageMoney/TransactionTable/transactions.types";
import { SourceAccount } from "@components/ManageMoney/TransactionTable/transactions.types";
import { Method } from "@components/ManageMoney/TransactionTable/transactions.types";
import { useParsedData } from "@common/Campaigns/hooks";
import { useReportMenuActions, MenuActionType } from "@common/Campaigns/hooks";
import { CampaignSearch } from "@common/CampaignSearch";
import { DonationsFilterBar } from "./DonationsFilterBar";
import { ExpandedRow_V2 } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2";
import { RecurringComponent_V2 } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2/RecurringComponent";
import { QKEY_DONATION_TRANSACTIONS } from "@constants/queryKeys";
import { toEnFormat } from "@utils/index";
import { MobilePurchaseItem_V2 } from "@common/Campaigns/ReportPage/MobilePurchaseItem";
import { SearchBar } from "@common/SearchBar_V2";
import { useOnOpenSidePanel } from "@common/Table/hooks";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import { TransactionTableTag } from "@common/Tag/TransactionTableTag";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import { getStatus } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import ListWrapperWithStates from "../../../containers/ListWrapperWithStates";
import { MobileListWithStates } from "@common/MobileList";
import DonationSkeletons from "@components/CustomSkeletons/DonationSkeletons";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type DonationsTableProps = {
  title?: string;
  id?: string;
};

export type PurchaseItem = Item & {
  displayStatus: string;
  email: string;
  fees: number;
  processed: string;
  revenue: string;
  cardholderName: string;
  sorceAccountFullName: string;
  sourceAccount: SourceAccount;
  sourceMethod: Method;
  purchases: Item[];
  transactionCreatedAt: string;
  charged: number;
  transactionID: string;
  totalAmount: number;
  transactionCustomerId: number;
  passFees: boolean;
  transactionProcessingState: string;
  transactionReversalState: string;
  orderRecurringItemID: string;
  customer: Customer;
  cost: number;
  hideRefund?: boolean;
  transactionDisplayStatus: string;
};

export default function FundraisersDonationsTable({
  title,
  id,
}: DonationsTableProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  if (!id) return <Text>Impossible</Text>;

  const {
    allRows,
    totalRows,
    page,
    handlePageChange,
    isFetching,
    invalidateCache,
    setPageDispatcher,
    setPage,
    loadingRef,
  } = useTransactionsIOC(
    QKEY_DONATION_TRANSACTIONS,
    useGetTransactionsByProduct(id, QKEY_DONATION_TRANSACTIONS),
    "",
    {
      isPurchase: true,
    },
  );

  React.useEffect(() => {
    return () => {
      invalidateCache();
    };
  }, []);

  const data = useParsedData(allRows);

  const getColumns = () => {
    const columns: TableColumnType[] = [
      {
        key: "id",
        sortable: true,
        title: "Donation",
        hasExpandButton: true,
        renderColumn: (row: PurchaseItem) => (
          <Box>
            <Text fontWeight="medium" color={palette.neutral[800]}>
              {row.cardholderName}
            </Text>
          </Box>
        ),
      },
      {
        key: "createdAt",
        sortable: true,
        title: "Date and Time",
        renderColumn: (row: PurchaseItem) => (
          <Box>
            <Text fontWeight="medium" color={palette.neutral[800]}>
              {row.createdAt}
            </Text>
            {/* <Text color={palette.neutral[800]}>{row.createdAt}</Text> */}
          </Box>
        ),
      },
      {
        key: "transactionProcessingState",
        sortable: false,
        title: "Status",
        columnType: "status",
        renderColumn: (row: TransactionTableRow) => (
          <TransactionTableTag
            type={getStatus(row).displayStatus.toLowerCase() as any}
          />
        ),
      },
      {
        key: "revenue",
        sortable: true,
        title: "Revenue Source",
        renderColumn: (row: PurchaseItem) => (
          <Box className={`hide-when-expand-${row.product?.id}`}>
            <Text fontWeight="medium" color={palette.neutral[800]}>
              {row.revenue}
            </Text>
          </Box>
        ),
      },
      {
        key: "recurringInterval",
        sortable: true,
        title: "Recurrence",
        renderColumn: (row: PurchaseItem) => (
          <Box
            sx={{ sup: { fontSize: 9, lineHeight: "13px" } }}
            className={`hide-when-expand-${row.id}`}
          >
            <Text
              fontWeight="medium"
              color={palette.neutral[800]}
              textTransform="capitalize"
            >
              {row.recurringInterval}
            </Text>
            {/* {row.transactions.refunded === true && (
              <Stack gap={0.5} direction="row" alignItems="center">
                <Text fontWeight="medium" color={"#FF9336"}>
                  {row.transactions.refunded && <i>REFUNDED</i>}
                </Text>
              </Stack>
            )} */}
          </Box>
        ),
      },
      {
        key: "transactionCost",
        sortable: true,
        title: "Amount",
        renderColumn: (row: PurchaseItem) => (
          <Box
            sx={{ sup: { fontSize: 9, lineHeight: "13px" } }}
            className={`hide-when-expand-${row.id}`}
          >
            <Text fontWeight="medium" color={palette.neutral[800]}>
              {toEnFormat(row.totalAmount)}
            </Text>
          </Box>
        ),
      },
    ];

    return columns;
  };

  const columns = getColumns();

  const { onOpenSidePanel } = useOnOpenSidePanel({
    DMenu,
    isLoading: isFetching,
    page,
    setPageDispatcher,
    compaignType: "fundraiser",
  });

  const targetRef = useTargetRef({ page, totalRows, setPage, loadingRef });

  return (
    <ListWrapperWithStates
      section="purchase"
      isEmpty={(!data || data.length === 0) && !isFetching}
    >
      {!isDesktop && (
        <CampaignSearch
          title={title || "Donations"}
          searchBar={<SearchBar queryKey={QKEY_DONATION_TRANSACTIONS} />}
        />
      )}
      {isDesktop ? (
        <Table
          onOpenSidePanel={onOpenSidePanel}
          selectable
          data={data}
          columns={columns}
          renderExpandedRow={ExpandedRow}
          totalRecords={totalRows}
          withPagination={totalRows > ROWS_PER_PAGE}
          page={page}
          handlePageChange={handlePageChange}
          isLoading={isFetching}
          tableTitle="Donations"
          queryKey={QKEY_DONATION_TRANSACTIONS}
          filters={<DonationsFilterBar />}
          withBackground={false}
        />
      ) : (
        <MobileListWithStates
          queryKey={QKEY_DONATION_TRANSACTIONS}
          length={allRows?.length}
          targetRef={targetRef}
          isLoading={isFetching}
          sx={{ mt: "24px", gap: "10px" }}
          LoadingSkeleton={DonationSkeletons}
        >
          {data.map((item: any, idx: number) => (
            <FadeUpWrapper key={item.id} delay={50 + idx * 50}>
              <MobilePurchaseItem_V2 rowData={item} campaignType="fundraiser" />
            </FadeUpWrapper>
          ))}
        </MobileListWithStates>
      )}
    </ListWrapperWithStates>
  );
}

export function ExpandedRow({ rowData, open }: any) {
  return (
    <ExpandedRow_V2
      rowData={rowData}
      open={open}
      chargedColumns={{
        fees: { key: "fees" },
        cost: { key: "price" },
        charged: { key: "itemCharged" },
      }}
      leftColData={{
        fullName: {
          title: "Full Name",
          value: rowData.sorceAccountFullName,
        },
        email: {
          title: "Email",
          value: rowData.email,
        },
        thxID: {
          title: "Transaction ID",
          value: rowData.transactionID,
        },
        cardholderName: {
          title: "Name on Card",
          value: rowData.cardholderName,
        },

        recurring: {
          title: "Recurrence",
          value: (
            <RecurringComponent_V2
              key={rowData.id}
              recurringCount={rowData.recurringCount}
              recurringInterval={rowData.recurringInterval}
              recurringMax={rowData.recurringMax}
            />
          ),
        },
      }}
    />
  );
}

export function DMenu({ open, anchorEl, setAnchorEl, selectedRow }: any) {
  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    NiceModal.show(DOWNLOAD_REPORT_MODAL, {
      title: "Donations",
    });
  };

  const handleOpenActionModal = (modalName: string, args?: any) => {
    NiceModal.show(modalName, args ?? {});
  };

  const { menuActions } = useReportMenuActions(
    selectedRow,
    handleOpenActionModal,
    "fundraiser",
  );

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: 159,
        },
      }}
    >
      {menuActions.map((action: MenuActionType) => {
        if (action.hidden) {
          return (
            <HiddenComponent hidden={true} key={action.title}>
              <DropdownItem onClick={action.onClick}>
                {action.title}
              </DropdownItem>
            </HiddenComponent>
          );
        }
        return (
          <DropdownItem onClick={action.onClick} key={action.title}>
            {action.title}
          </DropdownItem>
        );
      })}
    </Menu>
  );
}
