import { TypographyOptions } from "@mui/material/styles/createTypography";

export const getCustomTypographyStyle = (typography: TypographyOptions) =>
  [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "body",
    "button",
    "buttonSmall",
    "caption",
    "smallest",
    "headline",
    "headline",
    "link",
  ].map((variant) => ({
    props: (props: any) => props.variant === variant,
    style: {
      ...typography[variant as keyof TypographyOptions],
    },
  }));
