import { parseStyleAttrToCssProp } from "../components/SidePanelContent/SingleImageWidget/helpers";
import { icons } from "../consts/icons";
import { widgets } from "../consts/widgets";
import { DEFAULT_BORDER_COLOR } from "../signals";
import {
  getButtonWidgetDeviceTypeCssProperties,
  getDividerDeviceTypeCssProperties,
  getHeadingDeviceTypeCssProperties,
  getSingleImageDeviceTypeCssProperties,
} from "./helpers";

export const createBaseWidget = ({ nodeId, widgetType, Tag }: any) => {
  return {
    id: nodeId,
    icon: icons[widgetType as "heading"] as any,
    tabs: widgets[widgetType as "heading"]?.tabs,
    title: widgets[widgetType as "heading"]?.title,
    tagName: Tag,

    widgetType,
  };
};

// Function to extend base object for specific widget types
function extendWidget(baseObject: any, extraProperties: any) {
  return {
    ...baseObject,
    ...extraProperties,
  };
}

// Compose function to create a specific widget
export function composeWidget(baseObject: any, extraProperties: any) {
  return extendWidget(baseObject, extraProperties);
}

const headingWidget = (
  tag: any,
  style: any,
  metaAttributes: any,
  content: string,
) => {
  //we need to check the thrutiness of metaAttributes because there are updates where si set to null like on updating the content
  return {
    content,
    cssProperties: getHeadingDeviceTypeCssProperties(
      tag,
      style,
      metaAttributes,
    ),
  };
};

const buttonWidget = (
  style: any,
  metaAttributes: any,
  content: string,
  href: string,
) => {
  return {
    content,
    href,
    cssProperties: getButtonWidgetDeviceTypeCssProperties(
      style,
      metaAttributes,
    ),
  };
};

const dividerWidget = (style: any, metaAttributes: any) => {
  return {
    cssProperties: getDividerDeviceTypeCssProperties(style, metaAttributes),
  };
};

/* This pattern matches the image url to the url of cdn (dev,stg,prod) where images are stored.*/
const pattern =
  /url\((https:\/\/cdn(?:\.development\.|\.staging\.|\.)givepayments\.com\/[^)]+)/;

const singleImageWidget = (style: any, metaAttributes: any) => {
  //we need to check the thrutiness of metaAttributes because it might not be set.By default in the builder is nto set, therefore is undefined
  if (!style?.content) return;

  const matches = style.content.match(pattern);
  const baseCssProperties = getSingleImageDeviceTypeCssProperties(
    style,
    metaAttributes,
  );
  if (matches) {
    const extractedUrl = matches[1];
    return {
      cssProperties: {
        imgSrc: extractedUrl.replace("/large", ""),
        ...baseCssProperties,
      },
    };
  } else {
    return {
      cssProperties: baseCssProperties,
    };
  }
};

export const widgetsBuilderFromNode = {
  heading: headingWidget,
  button: buttonWidget,
  divider: dividerWidget,
  single_image: singleImageWidget,
  image_text: parseStyleAttrToCssProp,
  image_card: (value: string, stateAttr: any, metaAttributes: any) => {
    switch (stateAttr) {
      case "fontFamily": {
        return metaAttributes?.["data-fontFamily"]
          ? {
              name: metaAttributes?.["data-fontFamily"],
              value: value,
            }
          : { name: "Roboto", value: "Roboto" };
      }
      case "fontWeight":
        return metaAttributes?.["data-fontWeight"]
          ? {
              name: metaAttributes?.["data-fontWeight"],
              value: value,
            }
          : { value: "400", name: "Regular" };
      case "fontSize":
      case "imageBorderRadius":
      case "imageBorderWidth":
      case "letterSpacing":
        return Number(value.replace("px", ""));
      case "lineHeight":
        return Number(value.replace("%", ""));
      case "textColor":
        return metaAttributes?.["data-textColorName"]
          ? {
              name: metaAttributes?.["data-textColorName"],
              color: value,
            }
          : {
              name: "Black",
              color: "#000",
            };
      case "imageBorderColor": {
        return metaAttributes?.["data-bgColorName"]
          ? {
              name: metaAttributes?.["data-bgColorName"],
              color: value,
            }
          : {
              name: value,
              color: value,
            };
      }

      case "imageBorderStyle":
        return metaAttributes?.["data-borderStyle"]
          ? {
              label: metaAttributes?.["data-borderStyle"],
              value: value,
            }
          : {
              label: "None",
              value: "none",
            };
      default:
        return value;
    }
  },
  image_gallery: (value: string, stateAttr: any, metaAttributes: any) => {
    switch (stateAttr) {
      case "imageBorderRadius":
      case "imageBorderWidth":
      case "gap":
        return Number(value.replace("px", ""));
      case "hasImage":
        return metaAttributes?.["data-hasImage"] || "false";
      case "imageBorderColor": {
        return metaAttributes?.["data-bgColorName"]
          ? {
              name: metaAttributes?.["data-bgColorName"],
              color: value,
            }
          : DEFAULT_BORDER_COLOR;
      }
      case "imageBorderStyle":
        return metaAttributes?.["data-borderStyle"]
          ? {
              label: metaAttributes?.["data-borderStyle"],
              value: value,
            }
          : {
              label: "None",
              value: "none",
            };
      default:
        return value;
    }
  },
};

export const compounds = {};
