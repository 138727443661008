import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import { customInstance } from "@services/api";
import { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { riskActivitiesParser } from "../helpers/parsers";
import { TRiskActivity } from "../types";
import getMerchantRiskProfile from "@components/Merchants/MerchantPreview/RiskProfile/hooks/getMerchantRiskProfileQuery";

type TActiveRiskResponse = {
  total: number;
  data: TRiskActivity[];
};

const getActiveRisks = (profileId: number, merchantId: number) => {
  return customInstance({
    url: `/merchants/${merchantId}/risk/merchant-profiles/${profileId}/escalations?filter=isIgnored:false`,
    method: "GET",
  });
};

export const useRiskActivity = (profileId: number, merchantId: number) => {
  const [page, setPage] = useState(1);
  const [activityList, setActivityList] = useState<TRiskActivity[]>([]);
  const [activeTab, setActiveTab] = useState(tabs.ALL);
  const loadingRef = useRef<boolean>(false);

  const { isLoading: isActiveRisksLoading, data: activeRisks } =
    useQuery<TActiveRiskResponse>(
      ["active-risk-activities", profileId, merchantId],
      async () => {
        return await getActiveRisks(profileId, merchantId);
      },
      {
        enabled: profileId !== undefined,
        refetchOnWindowFocus: false,
      },
    );

  const { data, isLoading, isFetching, isError } = useQuery(
    ["risk-activities", profileId, merchantId, page],
    async () => {
      return await getMerchantRiskProfile(profileId, page, merchantId);
    },
    {
      enabled: profileId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        const activitiesCache: { [key: number]: TRiskActivity } = [
          ...activityList,
          ...((res.data as TRiskActivity[]) || []),
        ].reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});

        setActivityList(Object.values(activitiesCache));
        loadingRef.current = false;
      },
    },
  );

  const targetRef = useTargetRef({
    page,
    totalRows: data?.total,
    setPage: () => setPage((prev) => prev + 1),
    loadingRef,
  });

  const activities = useMemo(() => {
    const list = riskActivitiesParser(activityList) as TRiskActivity[];
    const sortedList = list.sort((a, b) => b.id - a.id);
    if (activeTab === "Active")
      return sortedList.filter((activity) => !activity.isIgnored);
    return sortedList;
  }, [activeTab, activityList, data]);

  const isAllRiskActivityLoading =
    isLoading || (!activities.length && isFetching);

  return {
    total: data?.total,
    data: activities,
    isLoading:
      (isAllRiskActivityLoading || isActiveRisksLoading) && !loadingRef.current,
    isError,
    targetRef,
    tabs,
    activeTab,
    setActiveTab,
    activeRisks: activeRisks?.data,
  };
};

const tabs = {
  ALL: "All",
  ACTIVE: "Active",
};
