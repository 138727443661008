import { useState } from "react";

export type TOFACTabs = "default" | "Check";

export const useSetPage = () => {
  const [page, setPage] = useState<TOFACTabs>("default");

  const setCurrentPage = (section: TOFACTabs) => setPage(section);

  return {
    page,
    setOfac: () => setCurrentPage("Check"),
    setDefault: () => setCurrentPage("default"),
  };
};
