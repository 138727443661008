// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { Box, useTheme, useMediaQuery, Stack, styled } from "@mui/material";
// components
import { Text } from "@common/Text";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import ThankYouIcon from "@assets/images/rafiki.svg";
import Modal from "@common/Modal/Modal";
import Figure from "@common/Figure";
import { Button } from "@common/Button";
import { selectThankYouSettings } from "@redux/slices/checkout";

type ModalProps = {
  name: string;
  email: string;
  amount: number | string;
  cardType: string;
  destinationAccountMerchantName?: string;
};

const ThankYouModal = NiceModal.create(
  ({ email, amount, cardType, destinationAccountMerchantName }: ModalProps) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const modal = useModal();
    const ThankYouSettings = useAppSelector(selectThankYouSettings);

    const handleClose = () => {
      modal.hide();
      if (ThankYouSettings.redirectUsers.redirectURL !== "") {
        window.location.href = ThankYouSettings.redirectUsers.redirectURL;
      }
    };

    return (
      <StyledModal
        {...muiDialogV5(modal)}
        titleComponent={
          <Text
            fontSize={28}
            sx={{
              color: palette.neutral.black,
              fontWeight: 350,
              lineHeight: "initial",
            }}
          >
            {isDesktop ? "Payment Successful" : "Success"}
          </Text>
        }
        width={isDesktop ? "648px" : "100%"}
        sx={modalStyles}
        onClose={handleClose}
        actions={<Button onClick={handleClose}>Close</Button>}
        data-testid="thank-you-modal"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Stack>
            <StyledUl>
              <li style={itemStyles} data-testid="thank-you-modal-reciept">
                A receipt has been sent to{" "}
                <span style={{ color: "#8F8F8F" }}>{email}</span>
              </li>
              <li
                style={itemStyles}
                data-testid="thank-you-modal-charged-amount"
              >
                Your {cardType} card has been charged in the amount of &#36;{" "}
                <Box display="inline-block">
                  <Figure
                    isAmount
                    value={+amount}
                    fontSize={14}
                    fontWeight="regular"
                    lineHeight="16.8px"
                    display="inline"
                    color="#8F8F8F"
                  />
                </Box>{" "}
                USD
              </li>
              <li style={itemStyles}>
                The charge will show up on your bank statement with the
                description GBX
                <span style={{ color: "#8F8F8F" }}>
                  <sup style={{ lineHeight: 0, verticalAlign: "baseline" }}>
                    *
                  </sup>
                  {destinationAccountMerchantName}
                </span>
              </li>
            </StyledUl>
          </Stack>
          {ThankYouSettings.displayIllustration && (
            <img src={ThankYouIcon} alt="Thank you" />
          )}
        </Box>
      </StyledModal>
    );
  },
);

const itemStyles = {
  color: palette.neutral.black,
  fontSize: "16px",
};

const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "0",
  },
  "& .MuiDialogTitle-root + .MuiDialogContent-root": {
    paddingTop: "0 !important",
  },
  "& .MuiDialog-paper": {
    width: "780px",
  },

  [theme.breakpoints.down(950)]: {
    "& .MuiDialog-paper": {
      // margin: "0 16px",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .MuiDialog-paper": {
      margin: "0",
    },
    "& .MuiDialogActions-root": {
      flexDirection: "column",
    },
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiDialog-paper": {
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paper": {
      width: " calc( 100% - 20px ) ",
      height: "auto",
      bottom: "30px",
      top: "auto",
    },
    "& .MuiDialogActions-root": {
      flexDirection: "column",
    },
  },
}));

const StyledUl = styled("ul")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "white",
  color: palette.neutral[600],
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "16.8px",
  borderRadius: "8px",
  padding: "12px 0px",
  overflow: "hidden",
  paddingInlineStart: "24px",
  margin: "0px 8px",
}));

const modalStyles = {
  "& .MuiDialog-paper": {
    padding: "16px",
    borderRadius: "16px",
    boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(20px)",
    background: palette.neutral.white,
    position: "absolute",
    height: "auto",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "flex-start",
  },
  "& .MuiDialogTitle-root": {
    background: palette.neutral.white,
    "& > button": {
      background: palette.neutral.white,
    },
  },
};

export default ThankYouModal;
