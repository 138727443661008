import { Box, ButtonBase, Stack, styled } from "@mui/material";
import { ConversationsIconV2 } from "@assets/icons";
import { palette } from "@palette";

const TriggersConversationButton = ({
  onClick,
  hasUnreadMessages,
}: {
  onClick?(): void;
  hasUnreadMessages?: boolean;
}) => {
  return (
    <ButtonBase disableRipple onClick={onClick}>
      <Stack alignItems="center" position="relative">
        {hasUnreadMessages && <BlueDot data-testid="unread-message-dot" />}
        <ConversationsIconV2 />
      </Stack>
    </ButtonBase>
  );
};

const BlueDot = styled(Box)({
  width: 8,
  height: 8,
  borderRadius: "100%",
  backgroundColor: palette.accent["3"],
  position: "absolute",
  right: -4,
  top: -4,
});

export default TriggersConversationButton;
