import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { customInstance } from "@services/api";
import { IMeta } from "react-dropzone-uploader";
import { useMutation, useQueryClient } from "react-query";

const useUpdateMerchant = () => {
  return useMutation(({ merchantId, signatureURL, ...rest }: any) => {
    return customInstance({
      url: `/merchants/${merchantId}`,
      method: "PATCH",
      data: {
        signatureURL,
        ...rest,
      },
    });
  });
};

export const useAddSignature = () => {
  const { mutateAsync, isLoading } = useUpdateMerchant();
  const { populateSnackbarFiles } = useFileUploadContext();
  const queryClient = useQueryClient();

  const handleUploadSignature = async (
    merchantId: number,
    signature?: File | any | null,
    updateMerchant = true,
    payload?: any,
  ) => {
    if (!signature) return "";

    let signatureURL = signature;
    if (
      signature instanceof Object ||
      ("file" in signature && "meta" in signature)
    ) {
      const fileToUpload = generateFileWithMeta(signature);
      const signatureURLArr = await populateSnackbarFiles(
        {
          fileWithMeta: fileToUpload,
          status: "done",
          allFiles: [fileToUpload],
          isSignatureUpload: true,
        },
        ACCEPTED_IMAGE_FORMATS,
      );
      if (signatureURLArr === "upload_failed") return "";
      signatureURL = signatureURLArr?.[0];
    }

    if (!signatureURL || typeof signatureURL !== "string") return "";

    if (updateMerchant) {
      try {
        await mutateAsync({ signatureURL, merchantId, ...payload });
        queryClient.refetchQueries("get-merchant-preview");
      } catch (err) {
        return "";
      }
    }

    return signatureURL;
  };

  return { handleUploadSignature, isLoading };
};

const generateFileWithMeta = (file: any) => {
  const dateNow = new Date();
  const ISODate = dateNow.toISOString();

  const fileObject = file?.file || file;

  const meta: IMeta = {
    status: "done",
    type: fileObject?.type,
    id: Math.random().toString(),
    size: fileObject?.size,
    name: fileObject?.name,
    uploadedDate: ISODate,
    percent: 100,
    lastModifiedDate: ISODate,
  };

  const fileToUpload: any = {
    file: fileObject,
    meta,
  };

  return fileToUpload;
};
