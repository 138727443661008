import { palette } from "@palette";
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { useAddToCart } from "@hooks/merchant-api/cart";
import { useAppSelector } from "@redux/hooks";
import { selectCartTotal } from "@redux/slices/cart";
import { PublicPeekMode } from "@common/PeekMode/types";
import { Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import { Box } from "@mui/material";
import { ArrowBackIcon, LogoMedium } from "@assets/icons";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import Payment from "@sections/Checkout/Payment/Payment";


function PaymentCard({
  destinationAccountMerchantName,
  isPeekMode,
  setDrawerOpen,
}: {
  destinationAccountMerchantName: string;
  setDrawerOpen: Dispatch<SetStateAction<boolean>> | undefined;
} & Partial<PublicPeekMode>) {
  const [open, setOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const { addToCartHandler, isLoading, isDesktop } = useAddToCart({
    destinationAccountMerchantName,
  });
  const total = useAppSelector(selectCartTotal);

  const handleDrawers = () => {
    setOpen(false);
    setDrawerOpen?.(true);
  };

  return (
    <Stack
      alignItems="center"
      gap={2}
      sx={{
        backgroundColor: isDesktop || isPeekMode ? "transparent" : "#fff",
        padding: "16px 16px 0px",
      }}
    >
      <FadeUpWrapper delay={250}>
        <Button
          fullWidth
          onClick={async () => {
            await addToCartHandler();
            setDrawerOpen && setDrawerOpen(false);
            setOpen(true);
          }}
          disabled={isPeekMode || isLoading || !total || total === 0}
          data-testid="checkout-button"
        >
          Checkout
        </Button>
      </FadeUpWrapper>
      {isPeekMode && (
        <FadeUpWrapper delay={300}>
          <Text color={palette.neutral[70]}>
            Checkout is not available in peek mode
          </Text>
        </FadeUpWrapper>
      )}

      {!isDesktop &&
        ComponentRegistry.show("choose-tickets", {
          open,
          setOpen,
          _renderFixedHeader: () => (
            <Text
              onClick={handleDrawers}
              sx={{
                fontSize: 18,
                color: "#8F8F8F",
                cursor: "pointer",
                paddingTop: "32px",
                paddingLeft: "16px",
                paddingBottom: "8px",
              }}
            >
              <ArrowBackIcon /> Back
            </Text>
          ),
          _renderComponent: () => (
            <Payment
              destinationAccountMerchantName={destinationAccountMerchantName}
              closeCheckoutDrawer={() => setOpen(false)}
              isMobileOpen={open}
              setIsDisabled={setIsDisabled}
            />
          ),
          _renderActions: () => (
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                textAlign: "center",
                padding: "16px 20px",
                zIndex: 1,
              }}
            >
              <Button
                fullWidth={!isDesktop}
                type="submit"
                size="medium"
                background="primary"
                form="credit-card-payment"
                disabled={isDisabled}
                sx={{
                  marginBottom: "16px",
                }}
              >
                Complete payment
              </Button>
              <Text color="#8F8F8F">Powered by</Text>
              <LogoMedium />
            </Box>
          ),
        })}
    </Stack>
  );
}

export default PaymentCard;
