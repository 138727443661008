import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { progressPercentageValue } from "@utils/index";
import { Text } from "@common/Text";
import OpenEndedInterval from "@components/Customers/Table/OpenEndedInterval";
import { palette } from "@palette";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Progress = styled(LinearProgress)(({ theme }) => ({
  width: 120,
  height: "8px",
  borderRadius: "32px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: palette.neutral[20],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "32px",
    backgroundColor: palette.neutral[100],
  },
}));

interface Props {
  recurringMax: number | null;
  recurringCount: number;
  recurringInterval: string;
  wrapperStyle?: any;
}

export const RecurringComponent_V2 = ({
  recurringMax,
  recurringCount,
  recurringInterval,
  wrapperStyle = {},
}: Props) => {
  const progressValue = recurringMax
    ? progressPercentageValue(recurringCount, recurringMax)
    : 0;

  const theme = useTheme();

  const t = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      alignItems="center"
      flexDirection={t ? "column" : "row"}
      style={wrapperStyle}
    >
      <Grid
        item
        xs={6}
        xl={recurringInterval === "once" ? 12 : 3}
        paddingRight={2}
      >
        <Text textTransform="capitalize">
          {recurringInterval === "once" ? "One Time" : recurringInterval}
        </Text>
      </Grid>
      {recurringMax === null && recurringInterval !== "once" && (
        <OpenEndedInterval />
      )}
      {recurringInterval !== "once" && recurringMax !== null && (
        <>
          <Grid item xs={12} xl={6}>
            <Progress variant="determinate" value={progressValue} />
          </Grid>
          <Grid item xs={12} xl={3}>
            <Text>
              {recurringCount} out of {recurringMax}
            </Text>
          </Grid>
        </>
      )}
    </Grid>
  );
};
