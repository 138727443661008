import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { EditButton, EditMerchantSection, TListItem } from "./atoms";
import SendInvitation from "./SendInvitation";
import { IParsedData } from "../data.types";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { ConversationsIconNotFilled, MoreIcon } from "@assets/icons";
import { StyledIconButton } from "./MerchantPreviewBusinessProfileStyle";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import usePrimaryAccountHolder from "../hooks/usePrimaryAccountHolder";
import NewTag, {
  BusinessProfileStatusType,
} from "@common/Tag/BusinessProfileTag";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import EditIconNew from "@assets/icons/EditIconNew";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";

interface Props {
  editHandler?: (() => void) | undefined;
  items: TListItem[];
  id: number;
  data: IParsedData;
  pendingEmail: string | undefined;
  isAcquirer?: boolean;
  isEnterprise?: boolean;
}

function MerchantPrimaryAccountHolder(props: Props) {
  const { editHandler, items, id, data, pendingEmail, isAcquirer } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const opened = Boolean(anchorEl);

  const { openConversationHandler } = useConversationsModal();
  const { actions } = usePrimaryAccountHolder({
    primaryAccountHolder: data?.primaryAccountHolder,
    id: id,
    editHandler,
  });

  const { isMobileView } = useCustomTheme();
  const { merchant_underwriting } = useEnterprisePermissions();

  let extraProps = {};
  if (isMobileView) {
    extraProps = {
      containerSx: {
        gap: 0,
        "> .MuiStack-root": { justifyContent: "space-between" },
      },
      SubTitleSection: isMobileView ? (
        <Box display="flex" pb={3}>
          <NewTag statusCode={data?.primaryAccountHolder?.owner?.statusName} />
        </Box>
      ) : null,
    };
  }

  const isAnyActionEnabled = actions.some((action) => !action.hidden);

  return (
    <Stack spacing={2} data-testid="pah-section-merchant-sidepanel">
      <EditMerchantSection
        sectionTitle="Primary Account Holder"
        editHandler={editHandler}
        items={items}
        {...extraProps}
        customHeader={
          <Box
            ml={1.5}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {!isMobileView && (
              <NewTag
                statusCode={
                  data?.primaryAccountHolder?.owner
                    ?.statusName as BusinessProfileStatusType
                }
              />
            )}
            <Box flexGrow={1} />
            {merchant_underwriting && isAcquirer && (
              <EditButton
                data-testid={`conversation-PAH-button`}
                onClick={() =>
                  openConversationHandler({
                    id: 0,
                    name: "Primary Account Holder",
                    paths: [],
                  })
                }
              >
                <ConversationsIconNotFilled section="primary-account-holder" />
              </EditButton>
            )}

            <CustomToolTip
              showToolTip={!editHandler}
              message={EDIT_DENY_MESSAGE}
            >
              <EditButton
                disabled={!editHandler}
                data-testid={`edit-PAH-button`}
                onClick={editHandler}
              >
                <EditIconNew />
              </EditButton>
            </CustomToolTip>
            {isAnyActionEnabled && (
              <StyledIconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                size="small"
                sx={{
                  ...((opened || isMobileView) && { visibility: "visible" }),
                }}
                data-testid={`change-status-PAH-button`}
              >
                <MoreIcon size="24px" />
              </StyledIconButton>
            )}
          </Box>
        }
      />
      <SendInvitation
        pendingEmail={pendingEmail}
        data={data?.invitation}
        id={id}
        merchantName={data?.header?.name}
        providerName={data?.merchantInfo?.enterprise}
        isEnterprise={props.isEnterprise}
        provideImageUrl={data?.merchantInfo?.enterpriseImageUrl}
      />
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        actions={actions}
      />
    </Stack>
  );
}

export default MerchantPrimaryAccountHolder;
