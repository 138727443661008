import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import PepStatusChip from "./PepStatusChip";
import { THistoryCheck } from "../types";
import { formatFromUnixWithFormat } from "@utils/index";

interface Props {
  check: THistoryCheck;
  onClick?: (check: THistoryCheck) => void;
}
function PepCard({ check, onClick }: Props) {
  const clickHandler = () => {
    if (onClick) onClick(check);
  };

  const formattedDate = check?.date
    ? formatFromUnixWithFormat(check?.date, "DD MMMM YYYY")
    : "";

  const isDisabled =
    ["clear", "pending"]?.includes(check?.status) && !check?.manualPepStatus;
  return (
    <>
      <Container
        data-testid="pep_list_card"
        mb="8px"
        onClick={() => {
          !isDisabled && clickHandler();
        }}
        isDisabled={isDisabled}
      >
        <Box alignItems="center" justifyContent="space-between" display="flex">
          <Text
            color={isDisabled ? palette.neutral[60] : palette.neutral[80]}
            fontSize="14px"
            fontWeight="regular"
          >
            {formattedDate}
          </Text>
          <PepStatusChip
            ownerPepStatus={check?.status}
            manualPepStatus={check?.manualPepStatus}
            isSidePanel
          />
        </Box>
      </Container>
    </>
  );
}

export default PepCard;

const Container = styled(Box)(({ isDisabled }: { isDisabled: boolean }) => ({
  padding: "8px 12px",
  borderRadius: "4px",
  background: palette.neutral.white,
  cursor: "pointer",
  ...(isDisabled && {
    cursor: "default",
    background: palette.neutral[10],
  }),
}));
