import { showMessage } from "@common/Toast";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_MERCHANTS,
} from "@constants/queryKeys";
import { customInstance } from "@services/api";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

type TStatuses = "open" | "ready_for_verification" | "closed";

type TUpdateChallenge = {
  merchantId: number;
  challengeId: number;
  data: { status: TStatuses } | { message?: string };
  isNotificationAction?: boolean;
};

const buildUrlAndMethod = (isNotificationAction: boolean) => {
  const action = isNotificationAction ? "notify" : "status";
  const method = isNotificationAction ? "POST" : "PATCH";
  return { action, method };
};

const updateChallengeFn = async ({
  merchantId,
  challengeId,
  data,
  isNotificationAction,
}: TUpdateChallenge) => {
  const { action, method } = buildUrlAndMethod(isNotificationAction || false);
  return await customInstance({
    url: `/merchants/${merchantId}/underwriting-challenges/${challengeId}/${action}`,
    method,
    data,
  });
};

export const useUpdateChallenge = ({
  challengeId,
}: {
  challengeId: number;
}) => {
  const queryClient = useQueryClient();
  const updateChallenge = useMutation({
    mutationFn: updateChallengeFn,
    mutationKey: ["update-challenge"],
  });

  const handleUpdateChallengeStatus = ({
    merchantId,
    data,
    onClose,
  }: {
    merchantId: number;
    data: { status: TStatuses; type?: string };
    onClose?: () => void;
  }) => {
    return updateChallenge.mutate(
      { challengeId, data, merchantId },
      {
        onSuccess: async () => {
          if (data.status === "closed") {
            showMessage("Success", "Challenge completed", false);
            queryClient.refetchQueries(["get-merchant-preview", merchantId]);
          }
          onClose && onClose();
          queryClient.refetchQueries(QKEY_LIST_MERCHANTS);
          queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
          queryClient.refetchQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
        },
        onSettled: () => {
          if (data.status === "open") {
            queryClient.refetchQueries(["get-challenges", merchantId]);
            queryClient.refetchQueries("get-challenges-stats");
          }
        },
      },
    );
  };

  useEffect(() => {
    if (updateChallenge.isSuccess) {
      queryClient.refetchQueries("get-challenges");
    }
  }, [updateChallenge.isSuccess]);

  const handleNotifyMerchant = (
    data: { message?: string },
    merchantId: number,
  ) => {
    updateChallenge.mutate({
      isNotificationAction: true,
      challengeId,
      merchantId,
      data,
    });
  };

  return {
    handleUpdateChallengeStatus,
    handleNotifyMerchant,
    isLoading: updateChallenge.isLoading,
  };
};
