import NiceModal from "@ebay/nice-modal-react";
import { Text } from "@common/Text";
import { FormProvider } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { RHFSelect } from "@common/Select";
import { RHFInput } from "@common/Input";
import { UploadFile } from "@components/UploadFile";
import useCreateMerchantBankAccount from "../hooks/useCreateMerchantBankAccount";
import { IMerchantBankAccount } from "../data.types";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { BankIcon } from "@assets/icons/RebrandedIcons";
import BusinessOwnerUploadList from "../components/BusinessOwners/BusinessOwnerUploadList";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { useGetBankFiles } from "@hooks/merchant-api/BankAccounts/useGetBankFiles";
import { BankProviderText } from "@components/BankAccounts";
import { AcceptAllowedGeneralDocumentsTypes } from "@hooks/upload-api/uploadHooks";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

interface ModalProps {
  merchantId: number;
  bankAccountId?: number;
  data: IMerchantBankAccount;
  onClose?: (data: IMerchantBankAccount, id?: number) => void;
  isDefault?: boolean;
  isEnterprise?: boolean;
}

const EditBankAccountModal = NiceModal.create(
  ({
    data,
    bankAccountId,
    merchantId,
    onClose,
    isDefault,
    isEnterprise,
  }: ModalProps) => {
    const {
      open,
      methods,
      handleCancel,
      onSubmit,
      handleChangeStatus,
      attemptUpload,
      isLoading,
      uploadList,
      loadingFile,
      deleteDocumentNew,
      deleteDocumentOld,
      isUploadAllowed,
    } = useCreateMerchantBankAccount({
      data,
      bankAccountId,
      merchantId,
      onClose,
      isDefault,
    });

    const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

    const { data: bankAcFiles, isLoading: isGettingBankFiles } =
      useGetBankFiles({
        bankAccountId: data?.id?.toString(),
        enabled: !!data?.id && !!merchantId,
        merchantId,
      });

    const isUpdateAllowed = useAccessControl({
      resource: composePermission(
        isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.BANK_ACCOUNT,
      ),
      operation: OPERATIONS.UPDATE,
    });

    const {
      formState: { isValid },
      watch,
    } = methods;

    const values = watch();

    const actionStyle = {
      padding: "8px 24px",
    };

    const isFieldDisabled = !isUpdateAllowed && !!bankAccountId;

    const isBankAccountApproved = data?.id ? data.status === "approved" : false;

    const inputList = [
      {
        md: 6,
        input: (
          <RHFSelect
            name="type"
            placeholder="Select an account"
            label="Account type"
            disabled={Boolean(isBankAccountApproved) || isFieldDisabled}
            options={[
              {
                value: "checking",
                label: "Checking",
              },
              {
                value: "savings",
                label: "Savings",
              },
            ]}
          />
        ),
      },
      {
        md: 6,
        input: (
          <RHFInput
            name="name"
            fullWidth
            placeholder="Name on account"
            type="text"
            label="Name on account"
            disabled={Boolean(isBankAccountApproved) || isFieldDisabled}
          />
        ),
      },
      {
        md: 6,
        input: (
          <>
            <RHFInput
              name="routingNumber"
              fullWidth
              placeholder="Enter Routing Number"
              type="text"
              label="Routing number"
              inputProps={{ maxLength: 9 }}
              disabled={isFieldDisabled}
            />
            <BankProviderText routingNumber={values.routingNumber} />
          </>
        ),
      },
      {
        md: 6,
        input: (
          <RHFInput
            name="accountNumber"
            fullWidth
            placeholder="Enter Account Number"
            type="text"
            label="Account number"
            disabled={Boolean(isBankAccountApproved) || isFieldDisabled}
          />
        ),
      },
      { input: <Notes />, mt: 1 },
      {
        input: (
          <>
            <Text
              fontSize={18}
              variant="headline"
              color={palette.neutral[70]}
              mt={0.5}
              mb={1.5}
            >
              Bank account documents
            </Text>
            <Text
              variant="headline"
              fontWeight="book"
              lineHeight="16.8px"
              color={palette.neutral[70]}
            >
              Please upload a non blurry photo of the latest bank statement of
              your bank account. The name on the account, account number and
              address must be clearly displayed and match the information below.
              <br /> Scanned copies will not be accepted.
            </Text>
            {/** -------- Upload Icon -------- */}
            <Box
              mt={2.5}
              sx={{
                "& .dzu-dropzone": {
                  minWidth: "0 !important",
                },
              }}
            >
              <FlaggedWrapper
                ActiveComponent={
                  <UploadFileNew
                    uploadFunction={attemptUpload}
                    accept={AcceptAllowedGeneralDocumentsTypes}
                    maxFiles={5}
                    multiple
                    disabled={!isUploadAllowed || isFieldDisabled}
                  />
                }
                FallbackComponent={
                  <UploadFile
                    disabled={!isUploadAllowed || isFieldDisabled}
                    backgroundColor="white"
                    onChangeStatus={handleChangeStatus}
                    width="100%"
                  />
                }
                name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
              />
            </Box>
          </>
        ),
        hidden: isBankAccountApproved,
      },
      {
        input: (
          <BusinessOwnerUploadList
            uploadList={uploadList}
            merchantID={merchantId}
            deleteDocument={
              isFileUploadRefactorEnabled
                ? deleteDocumentNew
                : deleteDocumentOld
            }
            loadingFile={loadingFile}
            tag="Bank Account Document"
          />
        ),
        hidden: false,
      },
      {
        input: (
          <>
            <BusinessOwnerUploadList
              uploadList={bankAcFiles?.data || []}
              merchantID={merchantId}
              deleteDocument={() => void 0}
              loadingFile={loadingFile}
              tag="Bank Account Document"
            />
          </>
        ),
        hidden: isBankAccountApproved,
      },
    ];

    return (
      <EditMerchantBaseModal
        title={`${bankAccountId ? "Edit" : "Add"} Bank Account`}
        icon={<BankIcon />}
        open={open}
        handleCancel={handleCancel}
        mobileDrawerSx={{
          zIndex: 1201,
        }}
        actions={
          <Box margin="0 auto">
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="edit-bank-account-form"
              disabled={isLoading}
              sx={actionStyle}
            >
              {bankAccountId ? "Save changes" : "Add Bank Account"}
            </Button>
          </Box>
        }
        sx={{
          "& .MuiPaper-root": {
            width: "640px !important",
            maxWidth: "640px !important",
          },
        }}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-bank-account-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Grid
              container
              spacing={1.5}
              direction="row"
              alignItems="flex-start"
              sx={{ overflow: "hidden" }}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {inputList.map(({ input, hidden, mt, md }, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  md={md || 12}
                  mt={mt || 0}
                  sx={{ ...(hidden && { display: "none" }) }}
                >
                  <CustomToolTip
                    showToolTip={Boolean(bankAccountId) && !isUpdateAllowed}
                    message={EDIT_DENY_MESSAGE}
                  >
                    <FadeUpWrapper delay={80 * (index + 1)}>
                      {input}
                    </FadeUpWrapper>
                  </CustomToolTip>
                </Grid>
              ))}
            </Grid>
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

const Notes = () => (
  <RHFInput
    name="notes"
    fullWidth
    placeholder="Enter notes about account..."
    type="text"
    label="Notes (Optional)"
    multiline
    rows={5}
  />
);

export default EditBankAccountModal;
