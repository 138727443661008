import { palette } from "@palette";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Fragment } from "react";
import { CheckboxNodeType, staticPdfPart } from "./pdfStaticSections";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "18px 20px",
  },
  header: {
    padding: "6px 8px",
    border: `1px solid ${palette.black[100]}`,
    backgroundColor: palette.neutral[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    color: "#FFF",
    letterSpacing: "-0.14px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  tosHeadline: {
    color: palette.neutral[100],
    fontWeight: "normal",
    margin: "8px",
    textAlign: "center",
  },
  versionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    margin: "0 auto",
    padding: "2px 8px",
  },
  versionLabel: {
    fontSize: "10px",
    color: palette.neutral[70],
  },
  versionValue: {
    fontSize: "10px",
    fontWeight: "normal",
    color: palette.neutral[100],
  },
  sectionStack: {
    display: "flex",
    flexDirection: "column",
    margin: "0 8px",
    textAlign: "justify",
  },
  description: {
    fontSize: "8px",
    fontWeight: "normal",
    color: palette.neutral[80],
    letterSpacing: "-0.08px",
    margin: "4px 0",
    lineHeight: "120%",
  },
  headline: {
    fontSize: "12px",
    color: palette.neutral[100],
    lineHeight: "120%",
    letterSpacing: "-0.12px",
    margin: "20px 0 8px 0",
  },
  subtitle: {
    fontSize: "8px",
    color: palette.neutral[80],
    fontWeight: "medium",
    lineHeight: "120%",
    letterSpacing: "-0.08px",
    margin: "20px 0 8px 0",
  },
  checkbox: {
    display: "flex",
    flexDirection: "row",
    gap: 4,
    alignItems: "center",
  },
  propertyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  property: {
    color: palette.neutral[80],
    fontSize: "8px",
    fontWeight: "normal",
    width: "50%",
  },
});

type Props = {
  version?: string;
};

const DocumentStaticPart = ({ version }: Props) => {
  return (
    <>
      <View style={styles.header}>
        <Text style={styles.headerText}>Merchant service agreement</Text>
      </View>
      <View>
        <Text style={styles.tosHeadline}>
          Give Corporation Terms of Service - United States
        </Text>
      </View>
      <View style={styles.versionContainer}>
        <Text style={styles.versionLabel}>Version:</Text>
        <Text style={styles.versionValue}>{version}</Text>
      </View>
      <View style={styles.versionContainer}>
        <Text style={styles.versionLabel}>Last Updated:</Text>
        <Text style={styles.versionValue}>June 10, 2023</Text>
      </View>
      <View style={styles.sectionStack}>
        {staticPdfPart.map((section, index) => (
          <Fragment key={index}>
            {section.type === "properties" ? (
              <View style={styles.propertyContainer}>
                {(section?.node as PropertiesType)?.map((property, index) => (
                  <View
                    key={index}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Text
                      style={{
                        ...styles.property,
                        fontWeight: property?.bold ? "medium" : "normal",
                      }}
                    >
                      {property.label}
                    </Text>
                    <Text
                      style={{
                        ...styles.property,
                        fontWeight: property?.bold ? "medium" : "normal",
                      }}
                    >
                      {property.value}
                    </Text>
                  </View>
                ))}
              </View>
            ) : section.type === "checkbox" ? (
              <View style={styles.checkbox}>
                {(section.node as CheckboxNodeType).checkboxIcon}
                <Text style={sectionStyle.description}>
                  {(section.node as CheckboxNodeType).label as string}
                </Text>
              </View>
            ) : (
              <Text style={sectionStyle[section.type]}>
                {section.node as string}
              </Text>
            )}
          </Fragment>
        ))}
      </View>
    </>
  );
};

const sectionStyle = {
  title: styles.headline,
  description: styles.description,
  subtitle: styles.subtitle,
  checkbox: styles.checkbox,
};

type PropertiesType = {
  label: string;
  value: string;
  bold?: boolean;
}[];

export default DocumentStaticPart;
