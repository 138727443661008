import { useState } from "react";
import { MoreActionButton } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/MoreActionButton";
import { Box, IconButton, Stack } from "@mui/material";
import { palette } from "@palette";
import { makeStyles } from "@mui/styles";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { Button } from "@common/Button";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { MerchantPreviewHeaderAction } from "@components/Merchants/MerchantPreview/hooks/useMerchantPreview";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { TPEPOwner } from "@components/Merchants/MerchantPreview/PEP/types";
import { shouldBeHidden } from "@constants/constants";
import NextPrevButtons from "@components/AcquirerEnterprises/EnterprisePreview/components/NextPrevButtons";
import Xicon from "@assets/icons/Xicon";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";

export type EditEnterpriseHeaderHandlers = {
  handlePrevious: () => void;
  handleNext: () => void;
  handleEnterpriseConfiguration: () => void;
  handleControlMode: () => void;
  handleClose: () => void;
  handleOpenOFACPanel: () => void;
  handleManageUnderwriting: () => void;
  handleOpenPep: () => void;
  selectPEPOwner?: (data: TPEPOwner) => void;
};

interface Props {
  actions: MerchantPreviewHeaderAction[];
  isControlModeAllowed: boolean;
  handlers: EditEnterpriseHeaderHandlers;
  nextPrevState: {
    isFirst: boolean;
    isLast: boolean;
  };
}

const EnterprisePreviewHeader = ({
  actions,
  handlers,
  isControlModeAllowed,
  nextPrevState,
}: Props) => {
  const { handleControlMode, handleClose, handleEnterpriseConfiguration } =
    handlers;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles();
  const { isDesktopView } = useCustomTheme();

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const isConfigurationAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.ACQUIRER,
      RESOURCE_BASE.CONFIGURATION,
    ),
    operation: OPERATIONS.LIST,
  });

  return (
    <>
      <Stack
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack alignItems="center" flexDirection="row" gap={1.5}>
          <NextPrevButtons
            nextPrevState={nextPrevState}
            handleNext={handlers.handleNext}
            handlePrev={handlers.handlePrevious}
          />
        </Stack>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-end"
          gap={1.5}
        >
          {!shouldBeHidden.enterpriseConfiguration && (
            <Button
              size="medium"
              background="tertiary"
              fullWidth={!isDesktopView}
              sx={buttonStyle}
              onClick={handleEnterpriseConfiguration}
              disabled={!isConfigurationAllowed}
              tooltipProps={{
                message: FEATURE_DENY_MESSAGE,
                show: !isConfigurationAllowed,
              }}
            >
              Configuration
            </Button>
          )}
          <Button
            size="medium"
            background="tertiary"
            fullWidth={!isDesktopView}
            sx={buttonStyle}
            onClick={handleControlMode} //INSIDE SIDE PANEL CONTROL MODE
            disabled={!isControlModeAllowed}
            tooltipProps={{
              show: !isControlModeAllowed,
              message: FEATURE_DENY_MESSAGE,
            }}
          >
            Control mode
          </Button>
          {actions.filter((x) => !x.hidden).length ? (
            <MoreActionButton onClick={onOpenMenu} />
          ) : null}
          <Stack direction="row" gap={2} alignItems="center">
            <Box className={`${classes.verticalDivider}`} />
            <IconButton onClick={handleClose} className={classes.iconBtn}>
              <Xicon width={15} height={15} stroke={palette.gray[300]} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
        sx={{
          "& .MuiPaper-root": {
            width: 236,
          },
        }}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: "8px 24px",
    height: "100%",
    alignItems: "baseline",
  },
  verticalDivider: {
    width: 1,
    height: "22px",
    borderLeft: `1px solid ${palette.neutral[30]}`,
  },
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    padding: "5px 5px",
    height: "max-content",
    "&:hover": {
      background: palette.neutral[20],
      boxShadow: "none",
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:focus": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:active": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    color: palette.neutral[80],
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
      boxShadow: "none",
    },
  },
}));

const buttonStyle = {
  whiteSpace: "nowrap",
  display: "inline-flex",
  alignItems: "center",
  color: palette.black[100],
  lineHeight: "21px",
  padding: "4px 8px",
  fontWeight: "400",
};

export default EnterprisePreviewHeader;
