import { SvgIconProps } from "@mui/material";

const NewPenIcon = ({
  height = 20,
  width = 20,
  fill = "#8F8F8F",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8929 4.77435C12.3349 4.33232 12.9344 4.08398 13.5596 4.08398C14.8613 4.08398 15.9166 5.13927 15.9166 6.44104C15.9166 7.06617 15.6683 7.6657 15.2263 8.10773L12.6 10.734C12.4047 10.9293 12.0881 10.9293 11.8929 10.734C11.6976 10.5387 11.6976 10.2222 11.8929 10.0269L13.5605 8.35925L11.6414 6.44009L5.08326 12.9982V14.9174H7.00242L9.48224 12.4375C9.6775 12.2423 9.99409 12.2423 10.1893 12.4375C10.3846 12.6328 10.3846 12.9494 10.1893 13.1446L7.56308 15.7709C7.46931 15.8647 7.34213 15.9174 7.20952 15.9174H4.58325C4.30711 15.9174 4.08325 15.6935 4.08325 15.4174V12.7911C4.08325 12.6585 4.13593 12.5313 4.2297 12.4375L11.2878 5.37942L11.8929 4.77435ZM12.3485 5.73298L14.2676 7.65214L14.5191 7.40062C14.7736 7.14612 14.9166 6.80095 14.9166 6.44104C14.9166 5.69156 14.309 5.08399 13.5596 5.08399C13.1997 5.08399 12.8545 5.22696 12.6 5.48146L12.3485 5.73298Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewPenIcon;
