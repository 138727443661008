import * as React from "react";
// mui
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
// components
import TickerItem from "./TickerItem";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PageVisibility from "react-page-visibility";
import Marquee from "react-fast-marquee";
import { useAppSelector } from "@redux/hooks";
import { selectTicker } from "@redux/slices/app";
import { Collapse } from "@mui/material";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const data = [
  {
    coast: 9.0,
    fullName: "John Appleseed",
    enterprise: "Green Peace",
    days: 10,
  },
  {
    coast: 1011212.12,
    fullName: "Lindsey Calzoni",
    enterprise: "Service Dogs",
    days: 12,
  },
  {
    coast: 12122.43,
    fullName: "Alfredo Saris",
    enterprise: "Green Peace",
    days: 10,
  },
  {
    coast: 1000.0,
    fullName: "Kaiya Donin",
    enterprise: "Provider 3",
    days: 10,
  },
  {
    coast: 9456.0,
    fullName: "Randy Lipshutz",
    enterprise: "Provider 1",
    days: 10,
  },
  {
    coast: 1011212.12,
    fullName: "Lindsey Calzoni",
    enterprise: "Service Dogs",
    days: 12,
  },
  {
    coast: 12122.43,
    fullName: "Alfredo Saris",
    enterprise: "Green Peace",
    days: 10,
  },
  {
    coast: 9456.0,
    fullName: "Randy Lipshutz",
    enterprise: "Provider 1",
    days: 10,
  },
  {
    coast: 1011212.12,
    fullName: "Lindsey Calzoni",
    enterprise: "Service Dogs",
    days: 12,
  },
  {
    coast: 12122.43,
    fullName: "Alfredo Saris",
    enterprise: "Green Peace",
    days: 10,
  },
];

const useStyles = makeStyles((theme) => ({
  gradientBox: {
    position: "relative",
    overflow: "hidden",
    padding: "16px",
  },
  gradientEdge: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "200px",
    zIndex: 1,
  },
  leftEdge: {
    left: 0,
    background: "linear-gradient(to right, #F8F8F6, transparent)",
  },
  rightEdge: {
    right: 0,
    background: "linear-gradient(to left, #F8F8F6, transparent)",
  },
  bottomEdge: {
    bottom: 0,
    width: "100%",
    background: "linear-gradient(to top, red, transparent)",
  },
  borderBottom: {
    position: "absolute",
    width: "100%",
    height: "1px",
    left: "7px",
    right: "7px",
    bottom: 2,
    background: "#C1C1C1",
    borderRadius: "10px",
  },
}));

export const Ticker_ = () => {
  const [pageIsVisible, setPageIsVisible] = React.useState(true);
  const { pathname } = useLocation();
  const classes = useStyles();
  const isTicker = useAppSelector(selectTicker);

  const handleVisibilityChange = (isVisible: boolean) => {
    setPageIsVisible(isVisible);
  };
  return (
    <>
      <Collapse in={isTicker}>
        <Box className={classes.gradientBox}>
          <PageVisibility onChange={handleVisibilityChange}>
            {pageIsVisible && (
              <Marquee speed={70} gradient={false}>
                {data.map((item, index) => (
                  <TickerItem
                    key={index}
                    coast={item.coast}
                    fullName={
                      pathname.startsWith("/provider")
                        ? item.enterprise
                        : item.fullName
                    }
                    days={item.days}
                  />
                ))}
              </Marquee>
            )}
          </PageVisibility>
          <Box className={`${classes.gradientEdge} ${classes.leftEdge}`} />
          <Box className={`${classes.gradientEdge} ${classes.rightEdge}`} />
          <Box className={`${classes.borderBottom} `} />
        </Box>
      </Collapse>
      {!isTicker && (
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              height: "35px",
            },
          })}
        />
      )}
    </>
  );
};

export const Ticker = () => {
  return null;
};
