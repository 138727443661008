import React from "react";
import { Text } from "@common/Text";
import { palette } from "@palette";
import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";
import useCheckPAH from "@hooks/common/useCheckPAH";
import { Stack } from "@mui/material";

export const NonPahDisclaimer = ({ step }: { step: string }) => {
  const { isLoggedInPAH } = useCheckPAH();
  if (isLoggedInPAH || step === "selfie") return null;
  if (step === "selfie") return null;
  return (
    <Stack
      bgcolor={palette.tag.warning.bg}
      borderRadius="12px"
      padding="12px 16px"
      spacing={1}
      mt={3}
    >
      <Stack direction="row" spacing={1}>
        <WarningIcon />
        <Text
          color={palette.filled.orange}
          fontSize="18px"
          fontWeight="regular"
        >
          Attention
        </Text>
      </Stack>
      <Text color={palette.warning.text} fontSize="14px" fontWeight="book">
        These details can only be filled and edited by the primary account
        holder.
      </Text>
    </Stack>
  );
};
