import React from "react";
import { Box, Collapse, Stack, Grid, Skeleton } from "@mui/material";
import OnboardingPlaidCard from "./OnboardingPlaidCard";
import { Button } from "@common/Button";
import CreateOnboardingBankAccount from "./CreateOnboardingBankAccount";
import { FormProvider } from "react-hook-form";
import OnboardingBankStatement from "./OnboardingBankStatement";
import useAddOnboardingBankAccounts from "@hooks/onboarding/useAddOnboardingBankAccounts";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";

type Props = {
  backLink: () => void;
  merchantId: number;
  firstAccount: any;
};

const BankAccountSetup = ({ backLink, merchantId, firstAccount }: Props) => {
  const {
    methods,
    onSubmit,
    isDisabled,
    plaidHandler,
    plaidData,
    bankFiles,
    bankFilesLoading,
    isCreatingAccount,
  } = useAddOnboardingBankAccounts(merchantId, backLink, firstAccount);
  const [isManual, setIsManual] = React.useState(!!firstAccount);

  const content = [
    {
      node: isCreatingAccount ? (
        <LoadingPlaidCard />
      ) : (
        <OnboardingPlaidCard
          name={plaidData?.name || null}
          accountNumber={plaidData?.numberLast4 || null}
          onClick={plaidHandler}
        />
      ),
      hidden: isManual,
    },
    {
      node: <OnboardingBankStatement />,
      hidden: !plaidData,
    },
    {
      node: (
        <Box>
          <Collapse orientation="vertical" in={!isManual}>
            <Button
              background="tertiary"
              sx={{ margin: "0 auto" }}
              onClick={() => setIsManual(true)}
              data-testid="link-manually"
            >
              or link manually
            </Button>
          </Collapse>
          <Collapse orientation="vertical" in={isManual} unmountOnExit>
            <CreateOnboardingBankAccount
              bankFiles={bankFiles}
              bankFilesLoading={bankFilesLoading}
              firstAccount={firstAccount}
            />
          </Collapse>
        </Box>
      ),
      hidden: !!plaidData,
    },
  ];

  return (
    <Stack
      direction="column"
      justifyContent="stretch"
      height="inherit"
      flexGrow={1}
    >
      <FormProvider {...methods}>
        <Box
          id="onboarding-bank-accounts"
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
          flexGrow={1}
          display="flex"
        >
          <ProfileSetupFormContainer sx={{ paddingTop: 0 }}>
            <Stack direction="column" gap={4} height="min-content">
              <ProfileSetupFormTitle title="Connect a bank account" />
              <Grid container spacing="12px" overflow="hidden">
                {gridItemsRenderer(content)}
              </Grid>
            </Stack>

            <ProfileSetupFormActions
              secondaryAction={{
                onClick: backLink,
              }}
              primaryAction={{
                disabled: isDisabled,
                form: "onboarding-bank-accounts",
                children: "Add Bank Account",
              }}
            />
          </ProfileSetupFormContainer>
        </Box>
      </FormProvider>
    </Stack>
  );
};

const LoadingPlaidCard = () => {
  return (
    <Skeleton width="100%" sx={{ borderRadius: "12px", height: "200px" }} />
  );
};

export default BankAccountSetup;
