import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Popup from "@common/Popup";
import { Button } from "@common/Button";
import { ReactNode } from "react";
import { isFunction } from "lodash";

type Props = {
  fieldsList: { field: string; missing: boolean }[];
  title?: string;
  description?: string;
  actions?: (cb: () => void) => ReactNode;
  width?: number;
  hasCustomPadding?: boolean;
};

const BusinessProfileMissingFieldsPopup = NiceModal.create(
  ({
    fieldsList,
    title,
    description,
    actions,
    width = 500,
    hasCustomPadding = false,
  }: Props) => {
    const modal = useModal();

    const handleClose = () => {
      modal.hide();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={title || "Incomplete profile"}
        data-testid="missing-fields-popup"
        width={`${width}px`}
        onCancel={handleClose}
        hasCustomPadding={hasCustomPadding}
        titleSx={{
          fontSize: 18,
          fontWeight: 350,
          color: palette.neutral[80],
        }}
        isClose
        actions={
          <>
            {isFunction(actions) ? (
              actions(handleClose)
            ) : (
              <Button background="primary" size="medium" onClick={handleClose}>
                Okay
              </Button>
            )}
          </>
        }
      >
        <Text
          lineHeight="16.8px"
          fontWeight="book"
          color={palette.neutral[70]}
          mb={1}
          fontSize="14px"
        >
          {description ||
            `The following details are missing for the business profile to be
          approved:`}
        </Text>
        {fieldsList?.map(({ field, missing }) => (
          <ListStyle key={field} sx={{ ...(!missing && { display: "none" }) }}>
            {field}
          </ListStyle>
        ))}
      </Popup>
    );
  },
);

const ListStyle = styled("li")(() => ({
  textAlign: "left",
  color: palette.neutral[70],
  fontSize: "14px",
  fontWeight: 350,
  marginLeft: "4px",
}));

export default BusinessProfileMissingFieldsPopup;
