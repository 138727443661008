import { animated, useTransition } from "react-spring";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { styled } from "@mui/material";

type SlideTransitionProps = TransitionProps & {
  children: React.ReactElement<any, any>;
};

const Container = styled("div")(() => ({
  height: "100%",
  width: "100%",
}));
const SlidingContainer = animated(Container);

const SlideTransition = React.forwardRef<HTMLDivElement, SlideTransitionProps>(
  (props, ref) => {
    const { children } = props;

    const transitions = useTransition(true, {
      from: { transform: "translateY(200px)", opacity: 0 },
      enter: { transform: "translate(0px)", opacity: 1 },
      leave: { transform: "translateY(200px)", opacity: 0 },
      config: { duration: 300, easing: (t: any) => t * (2 - t) },
    });

    return transitions((style) => (
      <SlidingContainer ref={ref} style={style}>
        {children}
      </SlidingContainer>
    ));
  },
);
SlideTransition.displayName = "SlideTransition";

export default SlideTransition;
