import { FileUploadIcon } from "@assets/icons";
import { TrashIcon } from "@assets/icons/RebrandedIcons/TrashIcon";
import { Text, TruncateText } from "@common/Text";
import { Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { FileUploadStatus, SnackbarFile } from "./types";
import KotoLinearProgress from "@common/SignUp/LinearProgress";
import { bytesToSize } from "@utils/index";
import { Box } from "@mui/material";
import { useFileUploadContext } from "./FileUploadContext";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";

type Props = {
  progressVisibleOnCollapse?: boolean;
  style?: {
    [key: string]: string | number;
  };
  snackbarFile: SnackbarFile;
  isDraggingEnabled?: boolean;
  isOverlay?: boolean;
  dragX?: number;
};

const disabledIconStatus = [
  FileUploadStatus.DELETED,
  FileUploadStatus.FILE_TOO_LARGE,
  FileUploadStatus.FAILED,
  FileUploadStatus.FILE_NOT_SUPPORTED,
];

const OPACITY_THRESHOLDS = {
  HIGH: 30,
  MEDIUM: 60,
  LOW: 90,
};

const getOpacity = (transformX: number) => {
  if (transformX < 0) {
    if (transformX > -OPACITY_THRESHOLDS.HIGH) return 0.8;
    else if (transformX > -OPACITY_THRESHOLDS.MEDIUM) return 0.5;
    else if (transformX > -OPACITY_THRESHOLDS.LOW) return 0.3;
    else return 0;
  } else if (transformX > 0) {
    if (transformX < OPACITY_THRESHOLDS.HIGH) return 0.8;
    else if (transformX < OPACITY_THRESHOLDS.MEDIUM) return 0.5;
    else if (transformX < OPACITY_THRESHOLDS.LOW) return 0.3;
    else return 0;
  }
};

export const UploadCard = ({
  snackbarFile: {
    name,
    status,
    uploadProgress,
    size,
    identifier,
    accountToRequest,
    canBeDeleted,
  },
  dragX = 0,
  style,
  isOverlay = false,
  progressVisibleOnCollapse = true,
  isDraggingEnabled = true,
}: Props) => {
  const { onDeleteFile } = useFileUploadContext();
  const [isHovered, setIsHovered] = useState(false);
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useDraggable({
      id: identifier || name || "draggable",
      disabled: !isDraggingEnabled,
    });
  const statusColor = useMemo(
    () =>
      status === FileUploadStatus.UPLOADED
        ? "filled.success"
        : status === FileUploadStatus.IN_PROGRESS
        ? "gray.300"
        : "neutral.80",
    [status],
  );

  const dragStyle = {
    transition: "opacity 0.2s ease-in-out",
    opacity: dragX ? getOpacity(dragX) : 1,
  };

  const lastDotIndex = name.lastIndexOf(".");

  const file = name.substring(0, lastDotIndex);
  const fileType = name.substring(lastDotIndex);
  const { sizeString } = bytesToSize(size);

  const enableDelete =
    canBeDeleted && status === FileUploadStatus.UPLOADED && isHovered;

  const showSize =
    status === FileUploadStatus.FILE_TOO_LARGE ||
    (status === FileUploadStatus.UPLOADED && (!enableDelete || !isHovered));

  return (
    <Stack
      ref={setNodeRef}
      sx={{
        padding: "4px 16px",
        boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
        height: "60px",
        minHeight: "60px",
        borderRadius: "8px",
        backgroundColor: "#FFF",
        justifyContent: "center",
        cursor: isDraggingEnabled ? "pointer" : "default",
        display: "flex",
        gap: "8px",
        ...style,
        ...(isOverlay && dragStyle),
        ...(!isOverlay && isDragging && { opacity: 0 }), //in order to leave the space open, we just hide the element
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid="file-upload-card"
      {...attributes}
      {...listeners}
    >
      <Stack direction="row" spacing={1.5} alignItems="center">
        <FileUploadIcon
          disabled={disabledIconStatus.includes(status as FileUploadStatus)}
        />
        <Stack direction="column" flex={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" width="217px">
              <TruncateText color="neutral.80" maxWidth="180px" lineClamp={1}>
                {file}
              </TruncateText>
              <Text color="neutral.80">{fileType}</Text>
            </Stack>
            {showSize && (
              <Text color="gray.300" variant="caption" justifySelf="flex-end">
                {sizeString}
              </Text>
            )}
          </Stack>
          <Text
            color={statusColor}
            variant="caption"
            data-testid={status.toLowerCase().replace(/\W+/g, "")}
          >
            {status}
          </Text>
        </Stack>
        {enableDelete && (
          <Box
            onClick={() =>
              onDeleteFile({
                identifier: identifier as string,
                accountToRequest: accountToRequest as number,
              })
            }
            sx={{ cursor: "pointer" }}
            data-testid="file-upload-card-delete-button"
          >
            <TrashIcon width={24} height={24} />
          </Box>
        )}
      </Stack>
      {progressVisibleOnCollapse && status === FileUploadStatus.IN_PROGRESS && (
        <KotoLinearProgress
          isActive
          sx={{
            height: "4px",
            position: "auto",
            ".MuiLinearProgress-bar": {
              backgroundColor: "neutral.black",
              borderRadius: "8px !important",
            },
          }}
          value={uploadProgress}
        />
      )}
    </Stack>
  );
};
