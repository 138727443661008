import { customInstance } from "@services/api";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from "react-query";

import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { buildMerchantEndpoints } from "../utils.api";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";

export const getBankAccounts = ({
  sortQuery,
  page,
  merchantId,
}: {
  sortQuery?: string;
  page: number;
  merchantId: number;
}) => {
  return customInstance({
    url: `merchants/${merchantId}/bank-accounts?&sort=${
      sortQuery ? sortQuery : "-createdAt"
    }&page=${page}&max=${ROWS_PER_PAGE}`,
    method: "GET",
  });
};

export const useGetBankAccounts = (
  {
    page,
    sorting,
    merchantId,
  }: { page: number; sorting: string; merchantId: number },
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const dispatch = useAppDispatch();

  const { data: merchantData } = useGetMerchantById({
    merchantId,
    enabled: !!merchantId,
  });

  return useQuery(
    ["list-all-bank-accounts", sorting, page],
    async () => {
      const data = await getBankAccounts({
        sortQuery: sorting,
        page,
        merchantId,
      });
      if (merchantData?.linkedBankAccount) {
        return {
          data: [
            { ...merchantData.linkedBankAccount, isLinked: true },
            ...(data.data ? data.data : []),
          ],
          total: data.total + 1,
          isAllowedAddAccounts:
            merchantData.type === "enterprise"
              ? true
              : merchantData.allowAddingBankAccounts,
        };
      }
      return {
        data: data.data,
        total: data.total,
        isAllowedAddAccounts:
          merchantData.type === "enterprise"
            ? true
            : merchantData.allowAddingBankAccounts,
      };
    },
    {
      ...options,
      enabled: Boolean(merchantData?.accID) && options?.enabled,
      retry: 2,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              bank_accounts_list: true,
            }),
          );
        }
      },
    },
  );
};

export const useRequestPlaidToken = (enabled: boolean) => {
  return useQuery(
    "request_plaid_link_token",
    async () => {
      return customInstance({
        url: buildMerchantEndpoints(`plaid/link-token`),
        method: "POST",
      });
    },
    { retry: false, enabled },
  );
};

export const useAccessPlaidLinkToken = () => {
  return useQuery(
    "access_plaid_link_token",
    async () => {
      return customInstance({
        url: buildMerchantEndpoints(`plaid/access-token`),
        method: "POST",
      });
    },
    {
      enabled: false,
    },
  );
};

export const useCreateBankAccountWithPlaid = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return customInstance({
        url: buildMerchantEndpoints(`bank-accounts`),
        method: "POST",
        data,
      });
    },
    onError(error) {
      console.log("ERR", error);
    },
    onSuccess() {
      queryClient.invalidateQueries("list-all-bank-accounts");
    },
  });
};
