// mui
// import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  FilterWithSwitch,
  FilterWithDate,
  FilterWithAmount,
  ClearFiltersButton,
} from "@common/Filter";
// redux
import { useAppSelector } from "@redux/hooks";
import {
  addFilter,
  selectFilters,
  removeFilter,
  disableFilter,
  addDateFilter,
  disableDateFilter,
  addAmountFilter,
  disableAmountFilter,
  addRangeFilter,
  disableRangeFilter,
  clearFilters,
} from "@redux/slices/fundraisers";
// components
import { HiddenComponent } from "containers/HiddenComponent";
import { selectQueryFilters } from "@redux/slices/fundraisers";

export const listCreatedFilter = [
  { value: "in the last", label: "in the last" },
  { value: "is between", label: "between" },
  { value: "is on or before", label: "on or before" },
  { value: "is on or after", label: "on or after" },
];

const FundraisersListFilters = () => {
  const filters = useAppSelector(selectFilters);

  return (
    <Stack py={1} direction="row" flexWrap="wrap">
      <FilterWithDate
        title="created"
        options={listCreatedFilter}
        filters={filters}
        apply={addDateFilter}
        disable={disableDateFilter}
      />
      {/* <FilterWithAmount
        title="amount"
        options={[
          { value: "is between", label: "Between" },
          {
            value: "is equal to or greater than",
            label: "More than",
          },
          {
            value: "is equal to or less than",
            label: "Less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithRangeSlider
        title="conversion"
        filters={filters}
        apply={addRangeFilter}
        disable={disableRangeFilter}
      />
      <HiddenComponent>
        <FilterWithSwitch
          title="type"
          options={{
            Fundraiser: false,
            "Peer-2-Peer": false,
          }}
          filters={filters}
          apply={addFilter}
          remove={removeFilter}
          disable={disableFilter}
        />
      </HiddenComponent>
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="transactions"
        options={[
          { value: "is between", label: "Between" },
          {
            value: "is equal to or greater than",
            label: "More than",
          },
          {
            value: "is equal to or less than",
            label: "Less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      />
      <FilterWithAmount
        money={false}
        unit={<></>}
        title="visitors"
        options={[
          { value: "is between", label: "Between" },
          {
            value: "is equal to or greater than",
            label: "More than",
          },
          {
            value: "is equal to or less than",
            label: "Less than",
          },
        ]}
        filters={filters}
        apply={addAmountFilter}
        disable={disableAmountFilter}
      /> */}

      <ClearFiltersButton
        clear={clearFilters}
        selector={selectQueryFilters}
      />
    </Stack>
  );
};

export default FundraisersListFilters;
