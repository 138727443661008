import { useEffect, useState } from "react";
import { highlightBackground } from "../utils";

const useHighlightWord = (highlights: NodeListOf<HTMLElement> | null) => {
  const [highlightIndex, setHighlightIndex] = useState<number>(0);

  const highlightNewIndex = (prevIndex: number, newIndex: number) => {
    highlightBackground(highlights?.[prevIndex], { reset: true });
    setHighlightIndex(newIndex);

    if (!highlights?.[newIndex]) return;
    highlightBackground(highlights[newIndex]);
    highlights[newIndex].scrollIntoView();
  };

  useEffect(() => {
    highlightNewIndex(highlightIndex, 0);
  }, [highlights]);

  const jumpHighlightTo = (nextMatch: "prev" | "next") => {
    const totalElements = highlights?.length || 0;
    if (!highlights || !totalElements) return;

    let newIndex = 0;
    if (nextMatch === "next" && highlightIndex < totalElements - 1) {
      newIndex = highlightIndex + 1;
    } else if (nextMatch === "prev" && highlightIndex > 0) {
      newIndex = highlightIndex - 1;
    } else if (nextMatch === "prev") {
      newIndex = totalElements - 1;
    }
    highlightNewIndex(highlightIndex, newIndex);
  };

  const onPrev = () => jumpHighlightTo("prev");
  const onNext = () => jumpHighlightTo("next");

  return {
    highlightIndex,
    onPrev,
    onNext,
  };
};

export default useHighlightWord;
