type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const CrossInCircleIcon = ({
  width = 14,
  height = 2,
  stroke = "#C7C5CE",
}: IconProps) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_300_28321)">
        <path
          d="M10 17.834C9.01509 17.834 8.03982 17.64 7.12987 17.2631C6.21993 16.8862 5.39314 16.3337 4.6967 15.6373C4.00026 14.9408 3.44781 14.1141 3.0709 13.2041C2.69399 12.2942 2.5 11.3189 2.5 10.334C2.5 9.34907 2.69399 8.3738 3.0709 7.46386C3.44781 6.55392 4.00026 5.72712 4.6967 5.03068C5.39314 4.33424 6.21993 3.7818 7.12988 3.40489C8.03982 3.02798 9.01509 2.83398 10 2.83398C10.9849 2.83398 11.9602 3.02798 12.8701 3.40489C13.7801 3.7818 14.6069 4.33425 15.3033 5.03068C15.9997 5.72712 16.5522 6.55392 16.9291 7.46386C17.306 8.3738 17.5 9.34907 17.5 10.334C17.5 11.3189 17.306 12.2942 16.9291 13.2041C16.5522 14.1141 15.9997 14.9408 15.3033 15.6373C14.6069 16.3337 13.7801 16.8862 12.8701 17.2631C11.9602 17.64 10.9849 17.834 10 17.834L10 17.834Z"
          stroke="#D92D20"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M7.5 7.83398L12.5 12.834"
          stroke="#D92D20"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M12.5 7.83398L7.5 12.834"
          stroke="#D92D20"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_300_28321"
          x="0"
          y="0.333984"
          width="20"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_300_28321"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CrossInCircleIcon;
