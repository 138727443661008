import { MedalIcon } from "@assets/icons/RebrandedIcons";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import PreviewInvitationButton from "@components/Merchants/Common/PreviewInvitationButton/PreviewInvitationButton";
import { useGetCurrentMerchantId } from "@hooks/common";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";

const EnterprisePAHSection = () => {
  const { watch } = useFormContext();
  const values = watch();
  const { name, img } = useGetCurrentMerchantId();
  const { merchantName } = values.merchantInfo || {};

  return (
    <FadeUpWrapper delay={100}>
      <Container
        id="edit-primary-account-holder"
        data-testid="primary-account-holder-no-toggles"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <MedalIcon />
          <Text fontWeight="book" fontSize="18px">
            Primary account holder
          </Text>
        </Stack>
        <Text color={palette.neutral[70]}>
          An invitation email will be sent to complete the merchant creation
          process. They will have full control over the payment account,
          including the ability to add users, manage funds, and update settings.
        </Text>

        <RHFInput
          name="primaryAccountHolder.email"
          label="Email"
          placeholder="Email"
          fullWidth
        />
        <Box display="flex">
          <PreviewInvitationButton
            title="Preview invitation"
            isSendingToProvider={false}
            receiverName={merchantName}
            senderName={name}
            senderLogo={img}
          />
        </Box>
      </Container>
    </FadeUpWrapper>
  );
};

export default EnterprisePAHSection;

const Container = styled(Box)(() => ({
  display: "flex",
  padding: "24px 16px",
  flexDirection: "column",
  gap: "24px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.background.dimmedWhite,
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05)",

  "& .dzu-dropzone": {
    borderRadius: "8px !important",
  },
}));
