import { showMessage } from "@common/Toast";
import { useQueryClient } from "react-query";

type TUpdateHandler = (oldData: any) => any;

const defaultErrorHandler = (
  message = "Whoops.. an error occured. Please try again",
) => showMessage("Error", message);

export const useUpdateCachedQuery = (queryKey: string) => {
  const queryClient = useQueryClient();
  const previousData = queryClient.getQueriesData(queryKey);

  const updateData = (updateHandler: TUpdateHandler) => {
    queryClient.setQueriesData(queryKey, updateHandler);
  };

  const revertChange = () => {
    previousData.forEach(([queryKey, queryData]) =>
      queryClient.setQueriesData(queryKey, queryData),
    );
  };

  const updateDataWithExceptions = async (
    updateHandler: TUpdateHandler,
    execute: () => void,
    onError = defaultErrorHandler,
  ) => {
    try {
      await queryClient.cancelQueries(queryKey);
      updateData(updateHandler);
      execute();
    } catch (error: any) {
      const message = error?.message || error?.response?.message;

      revertChange();
      onError(message);
    }
  };

  return { updateData, revertChange, updateDataWithExceptions };
};
