import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import React from "react";

type Props = {
  width?: number;
  height?: number;
  isDarkMode?: boolean;
  color1?: string;
  color2?: string;
};

const GraphIcon = ({
  width = 120,
  height = 46,
  isDarkMode,
  color1: c1,
  color2: c2,
}: Props) => {
  const isMode = isDarkMode !== undefined;
  const mode = isDarkMode ? "dark" : "light";
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor(isMode ? mode : undefined);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 46"
      fill="none"
    >
      <defs>
        <linearGradient
          id={`customGradient-${isDarkMode}`}
          x1="73"
          y1="46"
          x2="73"
          y2="11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor={c1 || color1} />
          <stop offset="100%" stopColor={c2 || color2} />
        </linearGradient>
        <clipPath id="clip0">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>

      <g clipPath="url(#clip0)">
        <path
          opacity="0.07"
          d="M7.25503 37.4274L4.42106 38.139C4.17355 38.2012 4 38.4237 4 38.6789V47.4434C4 47.7508 4.24921 48 4.55662 48H116.443C116.751 48 117 47.7508 117 47.4434V8.649C117 8.17256 116.44 7.91633 116.08 8.22764L108.667 14.6258C108.575 14.7053 108.459 14.7524 108.338 14.76L101.645 15.1801C101.526 15.1876 101.413 15.2327 101.322 15.3088L97.3375 18.6441C97.2637 18.7058 97.2072 18.7855 97.1735 18.8756L94.477 26.0694C94.4275 26.2014 94.3298 26.3097 94.2035 26.3725L86.5928 30.1546C86.2595 30.3202 85.8607 30.1185 85.7967 29.7519L83.1184 14.4103C83.1062 14.3407 83.0809 14.274 83.0438 14.2139L79.1734 7.93754C79.0049 7.66429 78.6408 7.58984 78.3786 7.77503L73.5154 11.2094C73.3319 11.3389 73.0886 11.3455 72.8984 11.2261L69.5408 9.11849C69.2464 8.93366 68.8564 9.06099 68.7278 9.38397L66.238 15.6354C66.1818 15.7767 66.0702 15.8888 65.9292 15.9457L61.4184 17.766C61.2824 17.8208 61.1314 17.8204 60.9964 17.7632C59.5192 17.1381 56.8009 15.9202 56.2625 15.4132C55.74 14.9213 54.3721 12.8751 53.6107 11.6934C53.5028 11.5261 53.3149 11.4288 53.1161 11.4377L48.7148 11.635C48.5728 11.6414 48.4338 11.5932 48.3262 11.5003L45.8072 9.32613C45.7173 9.2485 45.6545 9.14413 45.6282 9.02828L44.5885 4.45975C44.474 3.95676 43.7961 3.86661 43.5541 4.32222L42.1375 6.98997L34.7649 21.0843C34.5784 21.4407 34.0869 21.4877 33.8363 21.1731L26.9767 12.5612C26.7739 12.3066 26.3968 12.2796 26.1598 12.5028L23.9607 14.5734C23.8428 14.6844 23.6823 14.7386 23.5212 14.7217L19.8139 14.3338C19.6399 14.3156 19.4674 14.3803 19.3483 14.5085L14.6646 19.5486C14.6178 19.599 14.5807 19.6577 14.5552 19.7216L7.63659 37.0935C7.57043 37.2597 7.42847 37.3839 7.25503 37.4274Z"
          fill={`url(#customGradient-${isDarkMode})`}
        />
        <path
          d="M4 37L7.25588 36.2113C7.42889 36.1694 7.57141 36.0473 7.63934 35.8828L14.5534 19.1354C14.5801 19.0709 14.6186 19.0119 14.667 18.9616L19.3498 14.1005C19.4683 13.9775 19.6367 13.9157 19.8066 13.9328L23.5283 14.3084C23.6853 14.3243 23.8417 14.2728 23.9585 14.1667L26.168 12.1596C26.4032 11.946 26.7692 11.9723 26.9715 12.2173L33.8435 20.5399C34.0947 20.8442 34.5739 20.7977 34.762 20.4509L42.1375 6.84906L43.5589 4.26681C43.8046 3.82041 44.4713 3.91147 44.5883 4.40743L45.626 8.80645C45.6538 8.92391 45.7189 9.02918 45.8116 9.1064L48.3284 11.202C48.4348 11.2906 48.5704 11.3363 48.7087 11.3303L53.1227 11.1394C53.318 11.131 53.5028 11.225 53.6114 11.3875C54.373 12.5277 55.7402 14.5004 56.2625 14.9748C56.8016 15.4645 59.5271 16.6422 61.0028 17.2444C61.134 17.298 61.2798 17.2984 61.4119 17.247L65.9282 15.4888C66.0699 15.4337 66.1828 15.323 66.2408 15.1826L68.7273 9.15964C68.8575 8.84425 69.2383 8.71918 69.5301 8.89592L72.9064 10.9405C73.0929 11.0534 73.328 11.0471 73.5081 10.9244L78.3888 7.59942C78.6481 7.42275 79.0022 7.49525 79.1712 7.75964L83.0408 13.8131C83.0799 13.8742 83.1066 13.9424 83.1195 14.0138L85.795 28.7973C85.8604 29.1589 86.252 29.359 86.5833 29.2001L94.2015 25.548C94.329 25.4869 94.4285 25.3795 94.4797 25.2477L97.1712 18.3207C97.2065 18.2301 97.2648 18.1503 97.3404 18.0892L101.324 14.8728C101.414 14.8001 101.525 14.7572 101.64 14.7503L108.343 14.3444C108.461 14.3372 108.574 14.2924 108.665 14.2165L117 7.27673"
          stroke={`url(#customGradient-${isDarkMode})`}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default GraphIcon;
