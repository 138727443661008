import React, { useMemo } from "react";
// mui
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Modal } from "@common/Modal";
import { Text, TruncateText } from "@common/Text";
// assets
import { MoneyIcon } from "@assets/icons";
import { useLocation } from "react-router-dom";
import MobileTransaction from "./MobileTransaction/MobileTransaction";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { useGetSingleTransaction } from "@services/api/products/transactions";
import { formatDate } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { PurchaseItem } from "../FundraisersDonationsTable";
import { ExpandedRow_V2 } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2";
import {
  sourceAccountParser,
  sourceMethodParser,
} from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { Stack } from "@mui/material";
import { capitalizeFirstLetter } from "@common/Table/helpers";

const CompoundLoading = ({ children, isFetching, hideLoader }: any) => {
  if (isFetching) {
    if (hideLoader) {
      return null;
    } else {
      return <LoadingSpinner />;
    }
  }

  return children;
};

// all sizes aggregated, should return 11
const purchasesColumns = [
  { title: "Purchased items", key: "purchasedItems", size: 1.5 },
  { title: "Type", key: "type", size: 1.5 },
  { title: "Recurrence", key: "recurringCount", size: 4 },
  { title: "Item", key: "item", size: 2 },
  { title: "Amount (USD)", key: "price", size: 2 },
];

const ViewTransaction = NiceModal.create(({ id }: { id: string }) => {
  const modal = useModal();
  const location = useLocation();
  const isMembership = location.pathname.includes("memberships");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { data, isFetching, isError } = useGetSingleTransaction(id, {
    refetchOnWindowFocus: false,
  });

  const transaction = useMemo(() => {
    if (data) {
      const name =
        data.sourceAccount.type === "merchant" ? "name" : "firstName";

      return {
        sorceAccountFullName: `${capitalizeFirstLetter(
          data.sourceAccount[data.sourceAccount.type][name],
        )} ${capitalizeFirstLetter(
          data.sourceAccount[data.sourceAccount.type]?.lastName,
        )}`,
        transactionID: data.id,
        email: data.customer.email,
        transactionCreatedAt: formatDate(data.createdAt * 1000),
        cardholderName: "",
        displayStatus: data.displayStatus,
        revenue: "Website",
        charged: data.charged / 100,
        fees: data.fees / 100,
        purchases: data.items,
        cost: data.cost / 100,
        sourceAccount: {
          id: data.sourceAccount.id,
          type: data.sourceAccount.type,
          user: sourceAccountParser(data),
        },
        sourceMethod: {
          id: data.sourceMethod.id,
          kind: data.sourceMethod.kind,
          type: data.sourceMethod.type,
          method: sourceMethodParser(data),
        },
        customer: data.customer,
        items: data.items,
        hideRefund: Boolean(data.reversalState),
      } as any;
    }

    return {} as PurchaseItem;
  }, [data]);

  const sourceAccount = useMemo(() => {
    if (transaction.sourceAccount) {
      return {
        fullName: `${capitalizeFirstLetter(
          transaction.sourceAccount.user.firstName,
        )} ${capitalizeFirstLetter(transaction.sourceAccount.user.lastName)}`,
        email: transaction.sourceAccount.user.email,
        card: {
          brand: transaction.sourceMethod.method?.brand,
          numberLast4: transaction.sourceMethod.method?.numberLast4,
        },
      };
    }

    return {};
  }, [transaction]);

  const leftColData = useMemo(() => {
    if (transaction.sourceMethod) {
      const isCard = transaction.sourceMethod?.type === "card";

      return {
        createdAt: {
          title: "Processed",
          value: transaction.transactionCreatedAt,
        },
        customerName: {
          title: "Customer Name",
          value: transaction.sorceAccountFullName,
        },
        cardholderName: {
          title: isCard ? "Name on Card" : "Bank Account Name",
          value: isCard
            ? transaction.sourceMethod.method.cardholderName
            : transaction.sourceMethod.method.name,
        },
        card: {
          title: isCard ? "Card" : "Bank Account",
          value: (
            <Stack
              gap={4}
              direction="row"
              alignItems="center"
              paddingBottom={1}
            >
              <Text>
                xxxx xxxx xxxx {transaction.sourceMethod.method.numberLast4}
              </Text>
              <Text color={palette.neutral[800]} textTransform="capitalize">
                {isCard
                  ? transaction.sourceMethod.method.brand
                  : transaction.sourceMethod.method.bankName}
              </Text>
            </Stack>
          ),
        },
        status: {
          title: "Status",
          value: transaction.displayStatus,
        },
        email: {
          title: "Email",
          value: transaction.email,
        },
      };
    }
    return {};
  }, [transaction]);

  return (
    <Modal
      {...muiDialogV5(modal)}
      backgroundColor="rgb(248, 248, 246)"
      sx={{
        "& .MuiPaper-root": {
          width: "960px",
        },
        "& .MuiDialogTitle-root": {
          background: palette.neutral[5],
        },
        "& .CustomIconButton": {
          background: "transparent",
        },
        "@media (max-width: 600px)": {
          "& .MuiPaper-root": {
            maxWidth: "100%",
          },
        },
      }}
      data-testId="detailed-transaction-modal"
      titleComponent={
        <Box sx={{ maxWidth: "100%" }}>
          <CompoundLoading isFetching={isFetching} hideLoader>
            <TruncateText
              lineClamp={1}
              variant="h5"
              fontWeight="medium"
              color={theme.palette.neutral[600]}
              data-testid="detailed-transaction-account-full-name"
            >
              {transaction.sorceAccountFullName}
            </TruncateText>
            {!isMembership && (
              <TruncateText lineClamp={1} variant="headline" color="#A9AFBD">
                {transaction.transactionID}
              </TruncateText>
            )}
          </CompoundLoading>
        </Box>
      }
      titleIcon={<MoneyIcon width={42} height={40} stroke="#A9AFBD" />}
      onClose={() => modal.hide()}
    >
      <CompoundLoading isFetching={isFetching}>
        {isDesktop ? (
          <ExpandedRow_V2
            rowData={transaction}
            open={false}
            sourceAccount={sourceAccount}
            leftColData={leftColData}
            purchasesColumns={purchasesColumns}
          />
        ) : (
          <MobileTransaction />
        )}
      </CompoundLoading>
    </Modal>
  );
});

export default ViewTransaction;
