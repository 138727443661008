import { ReactElement } from "react";
import { useFeatureFlagContext } from "./FeatureFlagProvider";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";

type TProp = {
  children: ReactElement;
};

const FFLoadingStateWrapper = ({ children }: TProp) => {
  const { isLoading } = useFeatureFlagContext();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return children;
};

export default FFLoadingStateWrapper;
