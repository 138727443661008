import {
  HeartIcon,
  CalendarIcon,
  FileLightIcon,
  LockIcon,
  HourGlassIcon,
  JobsIcon,
  CustomerPeopleIcon,
  CogSettingsIcon,
  SheetIcon,
} from "@assets/rebrandIcons";
import DashboardIcon from "@assets/rebrandIcons/DashboardIcon";
import { PlusIcon } from "@assets/icons/RebrandedIcons";

/****
 * suggestion as a standard for menu configs :
 * __title__ key name used to render a Text.
 ****/

const menuList = {
  menu: [
    {
      name: "Dashboard",
      path: "/merchant/dashboard",
      icon: <DashboardIcon isMenu />,
      index: 0,
    },
    {
      name: "Manage money",
      path: "/merchant/manage-money",
      icon: <JobsIcon isMenu />,
      index: 1,
    },
    {
      name: "Customers",
      path: "/merchant/customers",
      icon: <CustomerPeopleIcon isMenu />,
      index: 2,
    },
    {
      name: "Payment Forms",
      path: "/merchant/payment-forms",
      icon: <SheetIcon isMenu />,
      // TODO: add translation key instead of hard coded text
      text: "Payment Forms",
      index: 3,
    },
    {
      name: "__title__",
      icon: <SheetIcon isMenu />,
      path: "",
      // TODO: add translation key instead of hard coded text
      text: "",
      index: 4,
    },
    {
      name: "Fundraisers",
      path: "/merchant/fundraisers",
      icon: <HeartIcon isMenu />,
      index: 5,
    },
    {
      name: "Events",
      path: "/merchant/events",
      icon: <CalendarIcon isMenu />,
      index: 6,
    },
    {
      name: "Invoices",
      path: "/merchant/invoices",
      icon: <FileLightIcon isMenu />,
      index: 7,
    },
    {
      name: "Memberships",
      path: "/merchant/memberships",
      icon: <LockIcon isMenu />,
      index: 8,
    },
    {
      name: "Sweepstakes",
      path: "/merchant/sweepstakes",
      icon: <HourGlassIcon isMenu />,
      index: 9,
    },
        {
      name: "Pre-Built Forms",
      path: "/merchant/payment-forms",
      icon: <PlusIcon />,
      text: "Pre-Built Forms",
      index: 10,
      isPreBuildFormsBuilder: true,
    },
  ],
  footer: [
    {
      name: "Settings",
      path: "/merchant/settings",
      icon: <CogSettingsIcon />,
    },
  ],
};

export { menuList };
