import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { Button } from "@common/Button";
import { useEffect, useMemo } from "react";
import { getFeesValidationSchema } from "@utils/validation.helpers";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { TMerchantFees } from "@components/Merchants/MerchantPreview/data.types";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { calculateEnterpriseFeePerTransaction } from "../components/EnterpriseFees";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { FeeInput } from "@components/Merchants/MerchantPreview/modals/EditFees";

type FormInputs = {
  credit_card_fee: string;
  amex_credit_card_fee: string;
  debit_card_fee: string;
};

interface ModalProps {
  id: number;
  data: TMerchantFees;
  parentFees: TMerchantFees;
}

const EditEnterpriseFees = NiceModal.create(
  ({ id, data, parentFees }: ModalProps) => {
    const modal = useModal();
    const open = modal.visible;
    const { isMobileView } = useCustomTheme();

    const isEditAllowed = useAccessControl({
      resource: RESOURCE_BASE.ENTERPRISE,
      operation: OPERATIONS.UPDATE,
    });

    const { handleSubmit, isLoading } = useUpdateMerchantInfo(id);

    const schema = getFeesValidationSchema(-100, 1000);

    const defaultValues = {
      credit_card_fee: "",
      amex_credit_card_fee: "",
      debit_card_fee: "",
    };

    const methods = useForm<FormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      reset,
      watch,
      formState: { isDirty },
    } = methods;
    const values = watch();

    useEffect(() => {
      reset({
        credit_card_fee: data.creditCardFee.value.toString(),
        amex_credit_card_fee: data.amexCreditCardFee.value.toString(),
        debit_card_fee: data.debitCardFee.value.toString(),
      });
    }, [id, data]);

    const handleCancel = () => {
      reset();
      modal.hide();
    };

    const onSubmit: SubmitHandler<FormInputs> = (data: any) => {
      handleSubmit("fees", data, handleCancel);
    };

    const creditCardFeePerTransaction = useMemo(() => {
      return calculateEnterpriseFeePerTransaction({
        value: +values.credit_card_fee,
        baselineFixed: parentFees.creditCardFee.baselineFixed,
        baselinePercent: parentFees.creditCardFee.baselinePercent,
        adjustmentFixed: parentFees.creditCardFee.adjustmentFixed,
        adjustmentPercent: parentFees.creditCardFee.adjustmentPercent,
      });
    }, [values.credit_card_fee, parentFees]);

    const debitCardFeePerTransaction = useMemo(() => {
      return calculateEnterpriseFeePerTransaction({
        value: +values.debit_card_fee,
        baselineFixed: parentFees.debitCardFee.baselineFixed,
        baselinePercent: parentFees.debitCardFee.baselinePercent,
        adjustmentFixed: parentFees.debitCardFee.adjustmentFixed,
        adjustmentPercent: parentFees.debitCardFee.adjustmentPercent,
      });
    }, [values.debit_card_fee, parentFees]);

    const amexCreditCardFeePerTransaction = useMemo(() => {
      return calculateEnterpriseFeePerTransaction({
        value: +values.amex_credit_card_fee,
        baselineFixed: parentFees.amexCreditCardFee.baselineFixed,
        baselinePercent: parentFees.amexCreditCardFee.baselinePercent,
        adjustmentFixed: parentFees.amexCreditCardFee.adjustmentFixed,
        adjustmentPercent: parentFees.amexCreditCardFee.adjustmentPercent,
      });
    }, [values.amex_credit_card_fee, parentFees]);

    const inputList = [
      {
        input: (
          <FeeInput
            label="Credit Card Fee"
            name="credit_card_fee"
            feePerTransaction={creditCardFeePerTransaction}
            disabled={!isEditAllowed}
          />
        ),
      },
      {
        input: (
          <FeeInput
            label="Debit Card Fee"
            name="debit_card_fee"
            feePerTransaction={debitCardFeePerTransaction}
            disabled={!isEditAllowed}
          />
        ),
      },
      {
        input: (
          <FeeInput
            label="AMEX Credit Card Fee"
            name="amex_credit_card_fee"
            feePerTransaction={amexCreditCardFeePerTransaction}
            disabled={!isEditAllowed}
          />
        ),
      },
    ];

    return (
      <EditMerchantBaseModal
        title="Edit Provider Fees"
        open={open}
        handleCancel={handleCancel}
        actionsSx={{
          justifyContent: "flex-end",
        }}
        mobileDrawerSx={{
          "& .MuiPaper-root": {
            top: "22%",
            flexGrow: 1,
          },
        }}
        actions={
          <>
            <Button
              size="medium"
              background="tertiary"
              onClick={handleCancel}
              disabled={isLoading}
              sx={{ padding: "8px 24px", whiteSpace: "nowrap" }}
              fullWidth={isMobileView}
            >
              {isMobileView ? "Discard changes" : "Cancel"}
            </Button>
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="edit-fees"
              disabled={!isDirty || isLoading}
              sx={{ padding: "8px 24px" }}
              fullWidth={isMobileView}
            >
              {isMobileView ? "Save" : "Save Changes"}
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-fees"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              {inputList.map(({ input }, index) => (
                <Grid key={index} item xs={12}>
                  <FadeUpWrapper delay={100 * (index + 1)}>
                    {input}
                  </FadeUpWrapper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

export default EditEnterpriseFees;
