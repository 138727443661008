import NiceModal from "@ebay/nice-modal-react";
import { useState } from "react";
import AvatarImagePreview from "./components/AvatarImagePreview";
import { isFunction } from "lodash";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Stack } from "@mui/material";
import HeaderTitleComponent from "./components/HeaderTitleComponent";
import ImageSection from "./components/ImageSection";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";
import { AcceptAllowedImagesTypes } from "@hooks/upload-api/uploadHooks";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const editPropfileSubTitle =
  "Image size is advised to be within the range of up to 5 MB";

export type TEditAvatarModalActions = {
  onUpload?: (file: File) => void;
  onDelete?: () => void;
  canUpload?: boolean;
  canDelete: boolean;
};

interface EditAvatarModalProps {
  title?: string;
  defaultImageURL?: string;
  rounded?: boolean;
  subTitle: string;
  actions: TEditAvatarModalActions;
  onLocalUpload?: (file: File) => boolean;
}

export const EditAvatarModal = NiceModal.create(
  (props: EditAvatarModalProps) => {
    const {
      title = "Profile Image",
      defaultImageURL,
      rounded = false,
      subTitle,
      actions,
      onLocalUpload = () => false,
    } = props;
    const { onUpload, onDelete, canUpload, canDelete } = actions;

    const { isMobileView } = useCustomTheme();
    const { open, onClose } = useNiceModal();
    const { isValidFile } = useIsValidFile();
    const [image, setImage] = useState(defaultImageURL);
    const [isEditorVisible, setIsEditorVisible] = useState(false);
    const handleCancel = () => {
      setImage(defaultImageURL);
      onClose();
      setIsEditorVisible(false);
    };
    const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
    const handleResetImage = () => {
      setImage(defaultImageURL);
      setIsEditorVisible(false);
    };

    const handleDelete = () =>
      NiceModal.show(DELETE_CONFIRMATION_MODAL, {
        variant: "image",
        itemName: title,
        deleteHandler: () => {
          setImage("");
          handleCancel();
          if (onDelete) onDelete();
        },
      });

    const hasPickedAFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = e.target.files?.[0];
      const allowedTypes = Object.keys(AcceptAllowedImagesTypes);
      if (!file) return;

      if (!isFileUploadRefactorEnabled) {
        const allowedExtensions = ["jpg", "jpeg", "png"];
        const fileExtension = file.name?.split(".")?.pop()?.toLowerCase() || "";
        if (!allowedExtensions.includes(fileExtension)) return;
        const stopUpload = onLocalUpload(file);
        if (stopUpload) return;
      } else if (!isValidFile({ file, allowedTypes })) {
        return;
      }
      setIsEditorVisible(true);
      setImage(URL.createObjectURL(file));
    };

    const handleUpdateImage = async (url: HTMLCanvasElement) => {
      setIsEditorVisible(false);
      setImage(url.toDataURL());

      url.toBlob(async (blob) => {
        if (blob === null) return;
        const fileName = "image.jpeg";
        const mimeType = "image/jpeg";
        const file = new File([blob], fileName, { type: mimeType });
        isFunction(onUpload) && onUpload(file);
        onClose();
      });
    };

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose: handleCancel,
          DialogProps: {
            width: isEditorVisible ? "732px" : "448px",
          },
        }}
      >
        <Stack padding="24px" direction="column" alignItems="stretch">
          <HeaderTitleComponent
            title={title}
            description={isEditorVisible ? undefined : editPropfileSubTitle}
            onClick={handleCancel}
            isMobile={isMobileView}
          />
          {isEditorVisible ? (
            <ImageSection
              image={image}
              handleResetImage={handleResetImage}
              handleUpdateImage={handleUpdateImage}
              handleDelete={handleDelete}
              canDelete={canDelete}
            />
          ) : (
            <AvatarImagePreview
              image={defaultImageURL}
              rounded={rounded}
              subTitle={subTitle}
              hasPickedAFile={hasPickedAFile}
              handleDelete={handleDelete}
              canUpload={canUpload}
              canDelete={canDelete}
            />
          )}
        </Stack>
      </ModalFactory>
    );
  },
);
