import { ConversationsEmptyStateIcons } from "@assets/icons";
import { Box } from "@mui/material";
import Comment from "./Comment";
import { Dispatch, SetStateAction } from "react";
import ConversationsEmptyState from "../Modal/ConversationsEmptyState";
import {
  TMentionedUser,
  ThreadInfos,
  getThreadInfos,
} from "../utils/functions";
import ComponentWithAnimation from "./ComponentWithAnimation";
import ThreadSkeleton from "./ThreadSkeleton";
import { isEmpty } from "lodash";
import { TThreadInfo, OriginalThreat } from "../types";

type Props = {
  setMentionedUser: Dispatch<SetStateAction<TMentionedUser>>;
  topicId: number | undefined;
  parentThreadName: string;
  merchantId: number;
  hasUnreadMessages: boolean;
  isMobileView: boolean;
  threads: TThreadInfo[];
  isLoading: boolean;
  isRefetching: boolean;
  data: any;
  isExpanded: boolean;
};

type TListThreadProps = {
  threads: TThreadInfo[];
  isRendered?: boolean;
  topicId: number | undefined;
  parentThreadName: string;
  setMentionedUser: Dispatch<SetStateAction<TMentionedUser>>;
  isMobileView: boolean;
  hasUnreadMessages: boolean;
  merchantId: number;
  originalThread?: OriginalThreat;
};

const ListThreads = ({
  threads,
  topicId,
  parentThreadName,
  setMentionedUser,
}: TListThreadProps) => {
  return (
    <Box sx={{ paddingBottom: "20px" }}>
      {threads.length > 0 &&
        threads.map((thread, index) => {
          if (!thread) return;
          const { subjectAccName, authorFirstName, authorLastName } =
            thread?.originalThread || {};
          const { comment, commentCreatedAt, threadUpdatedAt, userAvatars } =
            getThreadInfos(thread) as ThreadInfos;
          const customBody = `Conversation started with ${subjectAccName} underwriting by ${authorFirstName} ${authorLastName} `;
          return (
            comment && (
              <ComponentWithAnimation
                id={comment.id}
                key={comment.id}
                shouldAnimate={false}
                index={index}
              >
                <Comment
                  index={index}
                  parentThreadAuthorID={thread.authorAccID}
                  isError={thread.isError}
                  parentThreadId={thread.id}
                  isSending={thread.isSending}
                  topicId={topicId}
                  parentThreadName={parentThreadName}
                  userAvatars={userAvatars}
                  numberOfReplies={thread.messageCount - 1}
                  lastUpdatedAt={threadUpdatedAt}
                  setMentionedUser={setMentionedUser}
                  {...comment}
                  {...(!isEmpty(thread?.originalThread) && {
                    body: customBody,
                  })}
                  createdAt={commentCreatedAt}
                />
              </ComponentWithAnimation>
            )
          );
        })}
    </Box>
  );
};

const ChatMainContent = ({
  setMentionedUser,
  topicId,
  parentThreadName,
  merchantId,
  hasUnreadMessages,
  isMobileView,
  threads,
  isLoading,
  isRefetching,
  ...rest
}: Props) => {
  if (isLoading || (isRefetching && threads.length === 0) || !topicId)
    return <ThreadSkeleton length={rest.isExpanded ? 7 : 3} />;

  return (
    <>
      {!rest.data?.data ||
      (threads.length === 1 && threads[0].messages.length === 0) ? (
        <ConversationsEmptyState
          icon={<ConversationsEmptyStateIcons />}
          message="No messages yet..."
          isThread
        />
      ) : (
        <>
          <ListThreads
            threads={threads}
            parentThreadName={parentThreadName}
            isMobileView={isMobileView}
            hasUnreadMessages={hasUnreadMessages}
            merchantId={merchantId}
            setMentionedUser={setMentionedUser}
            topicId={topicId}
          />
        </>
      )}
    </>
  );
};

export default ChatMainContent;
