import React from "react";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Stack, SxProps, styled } from "@mui/material";
import { palette } from "@palette";
import { ButtonProps } from "@common/Button/Button";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ITextProps } from "@common/Text/Text";

type TTitle = {
  main: string | React.ReactNode;
  secondary?: string;
};

type TSize = "large" | "medium";

export type TEmptyStateAction = ButtonProps & {
  label: string | React.ReactNode;
};

export interface IEmptyStateProps {
  title: TTitle;
  action?: TEmptyStateAction;
  sx?: SxProps;
  size?: TSize;
  Icon?: JSX.Element;
  backgroundSrc?: string;
  height?: string;
  customSize?: TextPropsEntry;
}

const EmptyState = ({
  title,
  action,
  sx,
  size = "medium",
  Icon,
  backgroundSrc,
  height,
  customSize,
}: IEmptyStateProps) => {
  const { textProps: defaultTextProps } = useGetParams(size);
  const textProps = customSize || defaultTextProps;
  return (
    <Container sx={sx} size={size} height={height} data-testid="empty-state">
      {backgroundSrc && (
        <>
          <div className="video-overlay" />
          <video autoPlay muted loop playsInline>
            <source src={backgroundSrc} type="video/mp4" />
          </video>
        </>
      )}
      {size === "medium" && Icon}
      <Stack direction="column" alignItems="center" width="100%" zIndex={2}>
        {size === "large" && Icon}
        <Text
          fontWeight="light"
          color={palette.black[300]}
          align="center"
          {...textProps.main}
        >
          {title.main}
        </Text>
        <Text
          fontWeight="book"
          color={palette.neutral[70]}
          align="center"
          {...textProps.secondary}
        >
          {title.secondary}
        </Text>
      </Stack>
      {typeof action?.label === "string" ? (
        <Button
          background="primary"
          size="medium"
          sx={{
            fontSize: "18px",
            padding: "12px 24px",
            zIndex: 2,
            fontWeight: "normal",
          }}
          {...action}
        >
          {action.label}
        </Button>
      ) : (
        action?.label || <></>
      )}
    </Container>
  );
};

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: TSize }>(({ theme, size }) => ({
  flexDirection: "column",
  gap: size === "medium" ? "24px" : "32px",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  userSelect: "none",
  paddingBlock: size === "large" ? "220px 180px" : "100px",

  "& > video": {
    width: "100%",
    height: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    objectFit: "contain",
    zIndex: 0,
  },
  "& > .video-overlay": {
    background:
      "radial-gradient(40% 40% at 50% 50%, rgba(251, 251, 251, 0.30) 0%, #F7F7F7 100%)",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },

  [theme.breakpoints.down("sm")]: {
    paddingBlock: "50px",
    paddingInline: "16px",
    ...(size === "large" && {
      gap: "64px",
    }),
    "& > .video-overlay": {
      height: "340px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background:
        "radial-gradient(35% 35% at 50% 50%, rgba(251, 251, 251, 0.10) 0%, #F7F7F7 100%)",
    },
  },
}));

type TText = "main" | "secondary";
type TextPropsEntry = Record<TText, ITextProps>;

const useGetParams = (size: TSize, customSize?: TextPropsEntry) => {
  const { isMobileView } = useCustomTheme();

  const textProps: Record<TSize, TextPropsEntry> = {
    large: {
      main: {
        fontSize: isMobileView ? "24px" : "56px",
        lineHeight: isMobileView ? "32.4px" : "67.2px",
        letterSpacing: isMobileView ? "-0.24px" : "-1.68px",
        padding: isMobileView ? "24px 0px 12px" : "8px 0px",
      },
      secondary: {
        fontSize: isMobileView ? "14px" : "18px",
        lineHeight: isMobileView ? "16.8px" : "21.6px",
        letterSpacing: isMobileView ? undefined : "-0.18px",
      },
    },
    medium: {
      main: {
        fontSize: isMobileView ? "18px" : "24px",
        lineHeight: isMobileView ? "21.6px" : "32.4px",
        letterSpacing: isMobileView ? "-0.18px" : "-0.24px",
      },
      secondary: {
        fontSize: "14px",
        lineHeight: "16.8px",
        fontWeight: "book",
      },
    },
  };

  return {
    textProps: textProps[size],
  };
};

export default React.memo(EmptyState);
