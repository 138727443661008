import {
  AcceptAllowedImagesTypes,
  fiveMB,
} from "@hooks/upload-api/uploadHooks";
import { useUploadProgress } from "@redux/slices/uploadProgressSlice";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";

export function useIsValidFile() {
  const { setUploadProgress } = useUploadProgress();

  function isValidFile({
    file,
    allowedTypes,
    maxSizeInBytes = fiveMB,
  }: {
    file: File;
    allowedTypes: string[];
    maxSizeInBytes?: number;
  }) {
    // check size
    if (file.size >= maxSizeInBytes) {
      setUploadProgress({
        key: Math.random().toString(36).substring(7),
        data: {
          fileName: file.name,
          size: file.size,
          tooLarge: true,
        },
      });
      return false;
    }
    // check type
    if (file && file instanceof File) {
      const fileType = file.type;
      if (allowedTypes.includes(fileType)) {
        return true;
      } else {
        setUploadProgress({
          key: Math.random().toString(36).substring(7),
          data: {
            fileName: file.name,
            size: file.size,
            unsuported: true,
          },
        });
        return false;
      }
    }
  }

  const isDropzoneImageFileValid = (
    fileWithMeta: IFileWithMeta,
    status: StatusValue,
  ) => {
    const { file, remove } = fileWithMeta;
    if (["preparing", "removed"].includes(status)) return;
    const allowedTypes = Object.keys(AcceptAllowedImagesTypes);
    const isValid = isValidFile({ file, allowedTypes, maxSizeInBytes: fiveMB });
    if (!isValid) {
      remove && remove();
      return false;
    }
    return true;
  };
  return { isValidFile, isDropzoneImageFileValid };
}
