import React from "react";
import { GradientLockIcon } from "@assets/icons/GradientLock";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";

export const MissingPermissionContent = ({
  handleClose,
}: {
  handleClose?: () => void;
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.header}>
        {handleClose ? (
          <Button
            background="tertiary"
            className={classes.cancelBtn}
            onClick={handleClose}
          >
            Close
          </Button>
        ) : null}
      </Grid>
      <Stack className={classes.contentContainer}>
        <GradientLockIcon />
        <Text className={classes.title}>Access denied</Text>
        <Text className={classes.contentText}>
          It looks like you do not have permission to access this feature.
        </Text>
        <Text className={classes.contentText}>
          Please reach out to your administrator for assistance. Thank you!
        </Text>
      </Stack>
    </Grid>
  );
};

const useStyles = makeStyles({
  header: {
    width: "100%",
    display: "flex",
  },
  cancelBtn: {
    marginLeft: "auto",
    padding: 8,
    color: palette.neutral[80],
  },
  title: {
    fontSize: "24px",
    fontWight: 500,
    color: palette.neutral[80],
    paddingTop: "24px",
    paddingBottom: "8px",
  },
  contentContainer: {
    width: "100%",
    alignItems: "center",
  },
  contentText: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "120%",
    color: palette.neutral[80],
    textAlign: "center",
  },
});
