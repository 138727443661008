type TMultipleBySameName = Record<string, any>;

export type TQueryParams = Record<
  string,
  string | number | null | TMultipleBySameName
>;

const reduceMultipleBySameKey = (obj: TMultipleBySameName) => {
  return Object.entries(obj).reduce((acc, [key, value], index) => {
    const separator = index ? ";" : "";
    return acc + separator + `${key}:${value}`;
  }, "");
};

const reduceQueryParams = (params: TQueryParams) => {
  return Object.entries(params).reduce((acc, [key, values], index) => {
    const paramValue =
      typeof values !== "object" || !values
        ? values
        : reduceMultipleBySameKey(values);
    const separator = index ? "&" : "";
    return acc + separator + `${key}=${encodeURIComponent(`${paramValue}`)}`;
  }, "");
};

export const queryGenerator = (baseURL: string, params?: TQueryParams) => {
  const formattedParams = params ? "?" + reduceQueryParams(params) : "";
  const url = baseURL + formattedParams;
  return url;
};
