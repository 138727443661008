import {
  AcceptAllowedImagesTypes,
  useUploadSingleImage,
} from "@hooks/upload-api/uploadHooks";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";

import { updateMerchant } from "@services/api/upload/uploadEndpoints";
import { useMutation, useQueryClient } from "react-query";

const useUpdateMerchant = () => {
  return useMutation(({ merchantId, signatureURL, ...rest }: any) => {
    return updateMerchant({
      merchantId,
      signatureURL,
      ...rest,
    });
  });
};

export const useAddSignature = () => {
  const { mutateAsync, isLoading: isLoadingUpdateMerchant } =
    useUpdateMerchant();
  const queryClient = useQueryClient();
  const { handleUpload } = useUploadSingleImage();
  const { isValidFile } = useIsValidFile();

  const handleUploadSignature = async (
    merchantId: number,
    signature: { file: File } | File | any,
    updateMerchant = true,
    payload?: any,
  ) => {
    if (!signature) return "";
    let file;
    if (signature instanceof File) {
      file = signature;
    }
    if (signature.file) {
      file = signature.file;
    }

    if (
      !isValidFile({
        file,
        allowedTypes: Object.keys(AcceptAllowedImagesTypes),
      })
    ) {
      return "";
    }
    const url = await handleUpload({ file, formType: "customer_avatar" });
    if (updateMerchant && url) {
      try {
        await mutateAsync({ signatureURL: url, merchantId, ...payload });
        queryClient.refetchQueries("get-merchant-preview");
      } catch (err) {
        return "";
      }
    }

    return url;
  };

  return { handleUploadSignature, isLoading: isLoadingUpdateMerchant };
};
