import React from "react";
import { styled } from "@mui/material";
import { parse } from "libphonenumber-js";

type FlagSize = "small" | "medium" | "large";

const formattedSizes: Record<FlagSize, number> = {
  small: 40,
  medium: 80,
  large: 160,
};

type ContryFlagBaseProps = {
  size?: FlagSize;
  width?: number;
  height?: number;
  isoCode?: string;
};

type CountryFlagProps = ContryFlagBaseProps & {
  phoneNumber: string;
};

// docs https://flagpedia.net/download/api
const CountryFlag = ({ phoneNumber, ...props }: CountryFlagProps) => {
  if (!phoneNumber) return <></>;

  const phone = parse(phoneNumber);
  const isoCode = phone.country?.toLowerCase();

  return <CountryFlagBase isoCode={isoCode} {...props} />;
};

export const CountryFlagBase = ({
  size = "small",
  width = 24,
  height = 24,
  isoCode,
}: ContryFlagBaseProps) => {
  const src = isoCode
    ? `${process.env.REACT_APP_ASSETS_CDN_HOST}/flags/w${formattedSizes[size]}/${isoCode}.png`
    : "";
  return isoCode ? (
    <Image width={width} height={height} src={src} loading="lazy" />
  ) : (
    <></>
  );
};

const Image = styled("img")(() => ({
  borderRadius: "50%",
}));

export default React.memo(CountryFlag);
