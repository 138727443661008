import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { GridIcon } from "@assets/icons/RebrandedIcons";
import { CloseIcon } from "@assets/rebrandIcons";
import { IconButton } from "@common/IconButton";

export type HeaderProps = {
  title?: string;
  Icon?: JSX.Element;
};

const ModalDrawerHeader = ({
  title,
  Icon = <GridIcon />,
  onModalClose,
}: HeaderProps & { onModalClose?: () => void }) => {

  return (
    <HeaderWrapper>
      {Icon}
      <Text
        variant="body"
        lineHeight="16.8px"
        color={palette.gray[300]}
        flexGrow={1}
      >
        {title}
      </Text>
      {onModalClose && (
        <IconButton
          onClick={onModalClose}
          variant="text"
        >
          <CloseIcon width={24} height={24} />
        </IconButton>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  padding: "16px 16px 8px 16px",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  background: "rgba(251, 251, 251, 0.80)",
  boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(20px)",
  marginBottom: "12px",
}));

export default ModalDrawerHeader;
