import { showMessage } from "@common/Toast";
import { QKEY_LIST_TEAM_MEMBER_PERMISSIONS } from "@constants/queryKeys";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { MemberRoleName } from "@customTypes/team.member";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const useChangeRole = (
  role: string,
  member_id: number,
  updateRole: (newRole: MemberRoleName) => void,
) => {
  const [title, setTitle] = useState<MemberRoleName>("member");
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();

  const { mutateAsync } = useMutation(async (newRole: string) => {
    const updateData = {
      memberRole: newRole,
    };
    return customInstance({
      url: `/accounts/${merchantId}/members/${member_id}`,
      method: "PATCH",
      data: updateData,
    });
  });

  const onSubmit = async (newRole: MemberRoleName) => {
    if (newRole === role) return;

    try {
      await mutateAsync(newRole);
      updateRole(newRole);

      await queryClient.invalidateQueries(QKEY_LIST_TEAM_MEMBER_PERMISSIONS);
      queryClient.invalidateQueries("add-permission-modal-list");

      showMessage("Success", "Role Updated");
    } catch (error) {
      showMessage("Error", "An error occured");
    }
  };

  return { title, setTitle, onSubmit };
};

export default useChangeRole;

type TUserRole = {
  label: string;
  value: MemberRoleName;
  disabled?: boolean;
};
