import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import { Box } from "@mui/material";
import useNewSweepStack, { STEPS_OBJ, btnLabel } from "./hook/useNewSweepStack";
import { FormProvider } from "react-hook-form";
import NiceModal from "@ebay/nice-modal-react";
import EditProductSkeleton from "features/Minibuilders/EditProductSkeleton";
import { CreateSweepstakeModalProps } from "./types";

export const CreateSweepstakes = ({
  id,
  handleClose,
  noModal,
}: CreateSweepstakeModalProps) => {
  const {
    onClose,
    steps,
    step,
    setCurrentStep,
    methods,
    isDisabled,
    isLoading,
    UISteps,
    handleSubmit,
    data,
  } = useNewSweepStack({ id, noModal, handleClose });

  return (
    <ModalDrawer
      onModalClose={onClose}
      setCurrentStep={setCurrentStep}
      steps={id ? undefined : steps}
      currentStep={step}
      HeaderProps={{
        title: id ? "Edit" : "Create Sweepstake",
      }}
      primaryAction={{
        onClick: handleSubmit,
        disabled: isDisabled,
        label: id ? btnLabel.SAVE_CHANGES : "Next",
        type: undefined,
        form: "create-Sweepstake",
        key: step,
      }}
      isBody={noModal}
    >
      <FormProvider {...methods}>
        {id ? (
          <>
            {isLoading ? (
              <EditProductSkeleton />
            ) : (
              <Box display="flex" flexDirection="column" gap={2}>
                {UISteps[STEPS_OBJ.ABOUT]}
                {UISteps[STEPS_OBJ.STYLE]}
                {UISteps[STEPS_OBJ.PAYMENT_SETUP]}
                {UISteps[STEPS_OBJ.CONFIRMATION]}
              </Box>
            )}
          </>
        ) : (
          UISteps[step]
        )}
      </FormProvider>
    </ModalDrawer>
  );
};

const CreateSweepstakesModal = NiceModal.create(
  ({ id }: CreateSweepstakeModalProps) => {
    return <CreateSweepstakes id={id} />;
  },
);

export default CreateSweepstakesModal;
