import { DownloadIcon, TrashIcon } from "@assets/icons";
import EyeIcon from "@assets/icons/InputEyeIcon";
import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { PAHMapper } from "./PAHMapper";
import { TMerchantDocument } from "../../data.types";
import { TOwnerFile } from "./types";
import { MouseEvent } from "react";
import { palette } from "@theme/colors";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";

type Props = {
  type: TOwnerFile;
  downloadHandler: (
    documentData?: TMerchantDocument | undefined,
  ) => Promise<void>;
  deleteDocument?: () => void;
  previewDocument: () => void;
  disabled?: {
    download?: boolean;
    delete?: boolean;
    preview?: boolean;
  };
};

const PAHUploaderActions = ({
  type,
  downloadHandler,
  deleteDocument,
  previewDocument,
  disabled,
}: Props) => {
  const actionToDo = (
    e: MouseEvent<HTMLDivElement>,
    action: () => void,
    disabled = false,
  ) => {
    e.stopPropagation();
    if (disabled) return;
    action();
  };
  return (
    <>
      <Stack
        className="pah-uploader-actions"
        sx={{
          opacity: 0,
          backgroundColor: "#fff",
          position: "absolute",
          top: 8,
          right: 8,
          padding: "4px 8px",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        }}
        direction="row"
        gap={0.5}
        alignItems="center"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              ...(!disabled?.preview && {
                backgroundColor: palette.gray[50],
                borderRadius: "4px",
              }),
            },
          }}
          data-testid="preview-id-file-btn"
          onClick={(e) => actionToDo(e, previewDocument, disabled?.preview)}
        >
          <EyeIcon
            path_fill={disabled?.preview ? palette.neutral[40] : "#8F8F8F"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",

            "&:hover": {
              ...(!disabled?.download && {
                backgroundColor: palette.gray[50],
                borderRadius: "4px",
              }),
            },
          }}
          data-testid="download-id-file-btn"
          onClick={(e) => {
            actionToDo(e, downloadHandler, disabled?.download);
          }}
        >
          <DownloadIcon
            stroke={disabled?.download ? palette.neutral[40] : undefined}
          />
        </Box>
        {deleteDocument && (
          <WithTooltipWrapper
            hasTooltip={Boolean(disabled?.delete)}
            tooltipProps={{
              show: Boolean(disabled?.delete),
              message: DELETE_DENY_MESSAGE,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  ...(!disabled?.delete && {
                    backgroundColor: palette.tag.error["bg"],
                    borderRadius: "4px",
                  }),
                },
              }}
              data-testid="delete-id-file-btn"
              onClick={(e) => {
                if (disabled?.delete) {
                  e.stopPropagation();
                  return;
                }
                actionToDo(e, deleteDocument, disabled?.delete);
              }}
            >
              <TrashIcon
                stroke={
                  disabled?.delete ? palette.neutral[40] : palette.filled.red
                }
              />
            </Box>
          </WithTooltipWrapper>
        )}
      </Stack>
      <Stack
        direction="row"
        position="absolute"
        alignItems="center"
        className="pah-uploader-actions"
        gap={1}
        sx={{
          opacity: 0,
          bottom: 8,
          left: 8,
        }}
      >
        <Text fontSize={12}>{PAHMapper[type].icon("#fff")}</Text>
        <Text fontSize={12} color="#fff">
          {PAHMapper[type].title}
        </Text>
      </Stack>
    </>
  );
};

export default PAHUploaderActions;
