import { palette } from "@palette";
import { TimeLineChartData } from "../types";
import { useMemo } from "react";

const useDatasetsConfig = (data: TimeLineChartData[], isSameDate?: boolean) => {
  const customData = useMemo(() => {
    const totalCharts = data.length;
    return {
      datasets: dataConfig.datasets
        .slice(0, totalCharts)
        .map((dataset, index) => {
          const { label, entries, hidden } = data[index];
          const dataLength = isSameDate ? 1 : data[0].entries.length;
          return {
            ...dataset,
            label,
            data: isSameDate ? [entries[0]] : entries,
            hidden: hidden || false,
            pointRadius: dataLength > 1 ? 0 : 5,
          };
        }),
    };
  }, [data]);

  return { customData };
};

const dataConfig = {
  datasets: [
    {
      label: "",
      data: [],
      pointBorderColor: palette.charts.lightblue,
      borderColor: palette.charts.lightblue,
      fill: false,
      pointHoverRadius: 5,
      lineTension: 0.1,
    },
    {
      label: "",
      data: [],
      borderColor: palette.charts.orange,
      pointBorderColor: palette.charts.orange,
      fill: false,
      pointHoverRadius: 5,
      lineTension: 0.1,
    },
    {
      label: "",
      data: [],
      borderColor: palette.charts.pink,
      pointBorderColor: palette.charts.pink,
      fill: false,
      pointHoverRadius: 5,
      lineTension: 0.1,
    },
    {
      label: "",
      data: [],
      borderColor: palette.charts.purple,
      pointBorderColor: palette.charts.purple,
      fill: false,
      pointHoverRadius: 5,
      lineTension: 0.1,
    },
  ],
};

export default useDatasetsConfig;
