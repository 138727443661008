import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import {
  DateType,
  LocationType,
} from "features/Minibuilders/EventsMinibuilder/types";
import { useQuery } from "react-query";

export const useFindEventById = (id?: string, publicResource?: boolean) => {
  if (!id) return { data: undefined };

  const getEvent = (id: string) => {
    const publicUrl = `products/${id}?filter=typeName:"event"`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const getEventVariants = (id: string) => {
    const publicUrl = `products/${id}/variants?&sort=displayOrder`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const { data, error, isLoading } = useQuery(
    ["find-event-by-id", id],
    async () => {
      const event = await getEvent(id);
      const variants = await getEventVariants(id);
      return { event, variants };
    },
    { cacheTime: 0, refetchOnWindowFocus: false },
  );

  if (!data)
    return {
      error,
      isLoading,
      redirect: (error as any)?.response?.status === 404 ? true : false,
    };

  const customTickets =
    data.variants?.data?.map((ticket: any) => {
      return {
        id: ticket.id,
        name: ticket.name,
        amount: (ticket.price / 100).toFixed(2),
        description: {
          enabled: Boolean(ticket.description),
          text: stringToEditorState(ticket.description),
        },
        thumbnail: ticket.imageURL ? ticket.imageURL : "",
        active: ticket.isEnabled,
        in_stock: ticket.inventory,
        display: ticket.showAvailableVariants,
        bundle: 1,
      };
    }) ?? [];

  const customData = {
    general: {
      title: data?.event?.name || "",
      description: data?.event?.description || "",
      creatorName: data?.event?.merchantName,
      creatorImage: data?.event?.merchantImageURL,
      creatorDescription: data?.event?.merchantDescription,
      browseMore: data?.event?.canBrowseCampaigns || false,
      featuredImage: {
        image: data?.event?.imageURL || "",
        active: Boolean(data?.event?.imageURL),
        useAsBackground: data?.event?.usesBackgroundImage || false,
      },
    },
    date_and_location: {
      date: {
        type: data?.event?.endsAt ? "period" : ("one day" as DateType),
        day: data?.event?.endsAt
          ? null
          : new Date(data?.event?.startsAt * 1000),
        include_time: data?.event?.includeTime,
        startDate: data?.event?.endsAt
          ? new Date(data?.event?.startsAt * 1000)
          : null,
        endDate: data?.event?.endsAt
          ? new Date(data?.event?.endsAt * 1000)
          : null,
      },
      location: {
        showMap: data?.event?.showMap || false,
        type: data?.event?.locationURL
          ? "online"
          : ("in person" as LocationType),
        location: data?.event?.locationShortAddress || "",
        country: data?.event?.locationAddress?.country || "US",
        address: data?.event?.locationAddress?.line1 || "",
        state: data?.event?.locationAddress?.state || "",
        city: data?.event?.locationAddress?.city || "",
        zip: data?.event?.locationAddress?.zip || "",
        event_url: data?.event?.locationURL || "",
        manual: Boolean(
          !data?.event?.locationShortAddress &&
            data?.event?.locationAddress?.line1,
        ),
      },
    },
    tickets: {
      customer_pays_credit: {
        active: data?.event?.needsTax || false,
        optional: data?.event?.allowFeeChoice || false,
        maxTickets: data?.event?.maxPurchaseQuantity || 5,
      },
      list: customTickets || [],
    },
    accID: data?.event?.accID,
  };

  return {
    data: customData,
    isLoading: isLoading,
    redirect: (error as any)?.response?.status === 404 ? true : false,
  };
};
