import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

type Props = {
  width?: number;
  height?: number;
};

const EmptyChartIcon = ({ width = 24, height = 24 }: Props) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [col1, col2] = parseLinearGradientColor();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9091 21H6.49091C5.26898 21 4.65801 21 4.19129 20.7622C3.78076 20.553 3.44698 20.2192 3.2378 19.8087C3 19.342 3 18.731 3 17.5091V3M3.41379 16.9655L9.54546 10.6364L14.4545 13.9091L16.0909 12M21 6.27273L18.8165 8.95797"
        stroke="url(#paint0_linear_1500_112598)"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1500_112598"
          x1="21"
          y1="3"
          x2="3.46207"
          y2="21.4395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={col1} />
          <stop offset="1" stopColor={col2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EmptyChartIcon;
