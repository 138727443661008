import {
  IStyleIconButtonProps,
  StyledIconButton,
} from "@common/IconButton/IconButton";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { MagnifyingGlass } from "@assets/icons";
import { palette } from "@palette";

type SearcButtonProps = Omit<IStyleIconButtonProps, "children"> & {
  isSearch?: boolean;
  toggleSearch: (open: boolean) => void;
};

const SearchButton = ({ isSearch = false, toggleSearch }: SearcButtonProps) => {
  const { isDesktopView } = useCustomTheme();
  const iconSize = {
    container: isDesktopView ? 24 : 32,
    svg: isDesktopView ? 20 : 32,
  };

  const color = isSearch
    ? palette.accent[3]
    : isDesktopView
    ? palette.black[100]
    : palette.neutral.white;
  const { container, svg } = iconSize;
  return (
    <StyledIconButton
      data-testid="search-button"
      sx={{
        height: `${container}px !important`,
        width: `${container}px !important`,
        padding: "0 !important",
        "&:hover path": {
          fill: `${color} !important`,
        },
      }}
      onClick={() => toggleSearch(!isSearch)}
    >
      <MagnifyingGlass width={svg} height={svg} fill={color} />
    </StyledIconButton>
  );
};

export default SearchButton;
