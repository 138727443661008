import React, { useState } from "react";
import { Box, Card, Stack } from "@mui/material";
import { Text, TruncateText } from "@common/Text";
import {
  EditIcon,
  VerifiedIcon,
  NewCheckedIcon,
  NewEditIcon,
} from "@assets/icons";
import TimeHourglass from "@assets/icons/TimeHourglassIcon";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
import NewPendingIcon from "@assets/icons/NewPendingIcon";
import { CHANGE_EMAIL_MODAL } from "modals/modal_names";

export enum EmailStatus {
  pending = "Pending",
  Verified = "Verified",
  Change = "Change",
}

export type BEStatus = "pending" | "verified" | "rejected";

interface BEStatusObject {
  [key: string]: BEStatus;
}

export const BEStatus: BEStatusObject = {
  pending: "pending",
  verified: "verified",
  rejected: "rejected",
};

interface EmailCardProps {
  email: string;
  status: { status: BEStatus };
  disabled?: boolean;
}

export const getStatus = (
  status: BEStatus,
): { text: EmailStatus; icon?: React.ReactNode } => {
  switch (status) {
    case BEStatus.pending:
      return { text: EmailStatus.pending, icon: <NewPendingIcon /> };
    case BEStatus.rejected:
      return { text: EmailStatus.Change };
    case BEStatus.valid:
      return { text: EmailStatus.Verified, icon: <NewCheckedIcon /> };
    default:
      return { text: EmailStatus.pending, icon: <NewPendingIcon /> };
  }
};

const ICONS = {
  [EmailStatus.pending]: <TimeHourglass />,
  [EmailStatus.Verified]: <VerifiedIcon />,
  [EmailStatus.Change]: <EditIcon />,
};

const NewEmailCard: React.FC<EmailCardProps> = ({
  email,
  status,
  disabled = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();

  const { text, icon: statusIcon } = getStatus(status.status);
  const icon = statusIcon || ICONS[text];

  const handleClick = () => {
    if (!disabled) NiceModal.show(CHANGE_EMAIL_MODAL);
  };

  return (
    <Card
      className={classes.card}
      style={{ width: "100%", pointerEvents: disabled ? "none" : "auto" }}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <Box className={classes.cardContent}>
        <Box className={classes.emailBox}>
          <Text className={classes.title}>Email</Text>
          {isHovered && !disabled && (
            <Stack
              onClick={handleClick}
              sx={{
                "&:hover p": {
                  textDecoration: "underline",
                  color: "#292727",
                },
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              direction="row"
              alignItems="center"
            >
              <Box className={classes.statusIconBox}>
                <NewEditIcon width={20} height={20} />
              </Box>
              <Text color={palette.neutral[600]}>Change Email</Text>
            </Stack>
          )}
        </Box>
        <Stack direction="row" alignItems="flex-start" gap={1}>
          <TruncateText lineClamp={1} className={classes.emailText}>
            {email}
          </TruncateText>
          <Stack direction="row" alignItems="center">
            <Box className={classes.statusIconBox}>{icon}</Box>
            <Text
              fontSize="12px"
              mt={"2px"}
              color={
                text === EmailStatus.Verified ? palette.success.main : "#575353"
              }
              onClick={handleClick}
            >
              {text}
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  card: {
    width: "460px",
    boxShadow: "none",
    backgroundColor: "transparent",

    "&:hover": {
      borderColor: palette.neutral[30],
      "& $statusBox": {
        borderRadius: "32px",
        cursor: "pointer",
      },
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 350,
    lineHeight: "24px",
    color: "#575353",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  emailBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  emailText: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "20px",
    color: palette.neutral[80],
  },
  statusBox: {
    display: "flex",
    padding: "0px 8px",
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    height: "24px",
    minWidth: "max-content",
    borderRadius: "32px",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
  },
  statusIconBox: {
    marginRight: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default NewEmailCard;
