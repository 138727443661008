import { CircularProgress, Box } from "@mui/material";
import { Text } from "@common/Text";

const LoadingComponent = () => {
  return (
    <Box
      height="100%"
      justifyContent="center"
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <CircularProgress
        sx={{
          color: "#6D9CF8",
        }}
      />
      <Box mt="28px">
        <Text
          fontWeight="regular"
          fontSize="18px"
          color="#403D3D"
          mb="8px"
          textAlign="center"
        >
          Selfie is uploading
        </Text>
        <Text
          fontWeight="regular"
          fontSize="14px"
          color="#575353"
          textAlign="center"
        >
          Please wait while your selfie is uploading
        </Text>
      </Box>
    </Box>
  );
};

export default LoadingComponent;
