import { NewEnterpriseIcon } from "@assets/icons";
import { RHFInput, RHFTelInput } from "@common/Input";
import { Text } from "@common/Text";
import { Box, Grid, Stack, styled, TextField } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { removeSpecialChars } from "@utils/slug";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";
import { DOMAIN_NAME, SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";
import { RHFCheckbox } from "@common/Checkbox";
import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import { RHFSelect } from "@common/Select";
import {
  TClassificationsAPIResponse,
  useBusinessClassification,
} from "@components/Signup/Forms/hooks/useBusinessClassification";
import { HiddenComponent } from "@containers/HiddenComponent";
import CreateFormAvatarUpload from "@components/AvatarUpload/CreateFormAvatarUpload";

const CreateEnterpriseGeneralForm = () => {
  const { t } = useTranslation(namespaces.pages.enterprise.createMerchant);

  const { watch, setValue } = useFormContext();
  const values = watch();

  const handleChangeBillingDescriptor = (descriptor: string) => {
    const updatedDescriptor = descriptor
      .replace(/[^a-zA-Z0-9]/g, "")
      ?.slice(0, 21);
    setValue("enterprise_info.billingDescriptor", updatedDescriptor, {
      shouldDirty: true,
    });
  };

  const handleChangeSlug = (slug: string) => {
    const updatedSlug = removeSpecialChars(slug, SLUG_MAX_CHARACTER_LENGTH);
    setValue("enterprise_info.enterprise_slug", updatedSlug, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    handleChangeBillingDescriptor(values.enterprise_info.enterprise_name);
    handleChangeSlug(values.enterprise_info.enterprise_name);
  }, [values.enterprise_info.enterprise_name]);

  const classificationsParser = (res: TClassificationsAPIResponse) =>
    res?.data?.map(({ name, displayName }) => ({
      value: name,
      label: `${displayName}`,
    }));

  const { data: classificationOptions } = useBusinessClassification(
    "manage_submerchants",
    classificationsParser,
  );

  const enterpriseGridItems = [
    {
      node: (
        <RHFInput
          fullWidth
          label="Provider name"
          name="enterprise_info.enterprise_name"
          placeholder={t("enterprise_name_placeholder")}
        />
      ),
      hidden: false,
    },
    {
      node: (
        <Text color={palette.neutral[70]} fontWeight="book">
          Permalink
        </Text>
      ),
      sm: 3,
      hidden: false,
    },
    {
      sm: 6,
      node: (
        <EditableTextContainer data-testid="provider-panel-permalink-text">
          https://
          <EditableText
            text={values.enterprise_info.enterprise_slug}
            onChange={(e) => handleChangeSlug(e.target.value)}
            onSubmit={() => {
              if (!values.enterprise_info.enterprise_name) {
                handleChangeSlug(values.enterprise_info.enterprise_name);
                return;
              }
            }}
          />
          .{DOMAIN_NAME}
        </EditableTextContainer>
      ),
      hidden: false,
    },
    {
      node: (
        <RHFSelect
          label="Classification"
          name="enterprise_info.enterprise_classification"
          options={classificationOptions}
        />
      ),
      sm: 12,
      hidden: false,
    },
    {
      node: (
        <RHFInput
          name="enterprise_info.classification_description"
          label={"Describe your business"}
          multiline
          rows={6}
          fullWidth
        />
      ),
      hidden: values.enterprise_info.enterprise_classification !== "other",
    },
    {
      node: (
        <RHFTelInput
          label={`Merchants Service Phone Number`}
          name="enterprise_info.servicedPhoneNumber"
          fullWidth
        />
      ),
      hidden: false,
    },
    {
      node: (
        <WebsiteInput
          name="enterprise_info.website_url"
          label={"Website URL"}
          placeholder="example.com"
        />
      ),
      hidden: false,
    },
    {
      node: (
        <RHFInput
          name="enterprise_info.description"
          label="Purpose or Mission of Provider"
          placeholder="Purpose or Mission of Provider"
          multiline
          rows={6}
          fullWidth
          inputProps={{
            maxLength: "600",
          }}
          helperText={600 - values.enterprise_info.description?.length}
        />
      ),
      hidden: false,
    },
    {
      node: (
        <Stack gap={1}>
          <RHFCheckbox
            name="enterprise_info.hasServiceCountries"
            label="My Business services countries outside of the USA/Canada."
            sx={{
              pl: 0.5,
            }}
            data-testid="enterprise-has-service-countries"
          />
          {values.enterprise_info?.hasServiceCountries && (
            <RHFInput
              name="enterprise_info.servicedCountries"
              label="Countries Serviced Outside USA/Canada"
              placeholder="Please enter the countries separated by commas"
              multiline
              rows={4}
              fullWidth
            />
          )}
        </Stack>
      ),
      hidden: false,
    },
  ];

  return (
    <Container>
      <FadeUpWrapper delay={50}>
        <Stack direction="row" spacing={1} alignItems="center">
          <NewEnterpriseIcon />
          <Text color={palette.neutral[80]} fontSize={18}>
            General
          </Text>
        </Stack>
      </FadeUpWrapper>

      <FadeUpWrapper delay={100}>
        <CreateFormAvatarUpload fieldName="enterprise_info.avatar_url" />
      </FadeUpWrapper>

      <Grid container spacing={2}>
        {enterpriseGridItems.map(({ node, sm, hidden }, index) => (
          <HiddenComponent hidden={hidden} key={index}>
            <Grid key={index} item xs={12} sm={sm || 12}>
              <FadeUpWrapper delay={100 + 50 * (index + 1)}>
                {node}
              </FadeUpWrapper>
            </Grid>
          </HiddenComponent>
        ))}
      </Grid>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: "flex",
  padding: "24px 16px",
  flexDirection: "column",
  gap: "24px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.background.dimmedWhite,
  boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.05)",

  "& .dzu-dropzone": {
    borderRadius: "8px !important",
  },
}));

const EditableTextContainer = styled(Text)(() => ({
  color: palette.neutral[70],
  "&:hover": {
    "& span": {
      color: palette.neutral[80],
    },
  },
}));

type EditableTextProps = {
  text: string | React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: () => void;
};

const EditableText = ({ text, onChange, onSubmit }: EditableTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const onEdit = () => setIsFocused(true);

  const onBlur = () => {
    setIsFocused(false);
    if (onSubmit) onSubmit();
  };

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isFocused]);

  if (!isFocused)
    return (
      <span onClick={onEdit} ref={spanRef}>
        {text}
      </span>
    );

  return (
    <Input
      value={text}
      onChange={onChange}
      inputRef={inputRef}
      onBlur={onBlur}
      sx={{ width: spanRef.current?.offsetWidth || 130 }}
    />
  );
};

const Input = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    border: "none",
    padding: 0,

    "& input": {
      color: palette.black[100],
      fontSize: "12px",
      lineHeight: "14.4px",
      fontWeight: 350,
      height: "15px",
      margin: 0,
      backgroundColor: "rgba(21, 134, 255, 0.1)",
    },
  },
  "& .MuiInputBase-root.Mui-disabled": {
    boxShadow: "none",
    border: "none",

    "& input": {
      color: palette.black[100],
      fontSize: "12px",
      lineHeight: "14.4px",
      fontWeight: 350,
      height: "15px",
      margin: 0,
      backgroundColor: "rgba(21, 134, 255, 0.1)",
      WebkitTextFillColor: palette.black[100],
    },
  },
}));

export default CreateEnterpriseGeneralForm;
