import { TickIcon } from "@assets/icons";
import { Image } from "@common/StyledImage/Image";
import { TruncateText } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import React from "react";

type MobileAssigneeItemProps = {
  name: string;
  image: string;
  selected: boolean;
  handleDeselect: () => void;
  onClick: () => void;
}

const MobileAssigneeItem = ({
  name,
  image,
  selected,
  handleDeselect,
  onClick,
}: MobileAssigneeItemProps) => {

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (selected) {
      handleDeselect();
    } else {
      onClick();
    }
  }

  return (
    <Container onClick={handleClick}>
      <Image width={32} height={32} src={image} alt={name} />
      <TruncateText
        lineClamp={1}
        color={palette.black[100]}
        lineHeight="16.8px"
        fontWeight="book"
        flexGrow={1}
      >
        {name}
      </TruncateText>
      {selected && (
        <Stack width={32} height={32} alignSelf="flex-end" alignItems="center">
          <TickIcon stroke={palette.black[100]} width={28} height={28} />
        </Stack>
      )}
    </Container>
  )
}

const Container = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "stretch",
  alignItems: "center",
  gap: "2px",
  padding: "12px 8px",
  borderRadius: "4px",
  background: "inherit",

  "&:active": {
    background: palette.liftedWhite[100],
  },
}))

export default MobileAssigneeItem;
