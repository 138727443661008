import { SvgIconProps } from "@mui/material";

const ArrowVertical = ({
  height = 24,
  width = 24,
  fill = "#575353",
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5306 18.219C15.6003 18.2886 15.6556 18.3714 15.6933 18.4624C15.7311 18.5535 15.7505 18.6511 15.7505 18.7496C15.7505 18.8482 15.7311 18.9458 15.6933 19.0368C15.6556 19.1279 15.6003 19.2106 15.5306 19.2802L12.5306 22.2802C12.4609 22.35 12.3782 22.4053 12.2871 22.443C12.1961 22.4808 12.0985 22.5002 11.9999 22.5002C11.9014 22.5002 11.8038 22.4808 11.7127 22.443C11.6217 22.4053 11.539 22.35 11.4693 22.2802L8.4693 19.2802C8.32857 19.1395 8.24951 18.9486 8.24951 18.7496C8.24951 18.5506 8.32857 18.3597 8.4693 18.219C8.61003 18.0783 8.80091 17.9992 8.99993 17.9992C9.19895 17.9992 9.38982 18.0783 9.53055 18.219L11.2499 19.9393V4.05993L9.53055 5.78024C9.38982 5.92097 9.19895 6.00003 8.99993 6.00003C8.80091 6.00003 8.61003 5.92097 8.4693 5.78024C8.32857 5.63951 8.24951 5.44864 8.24951 5.24961C8.24951 5.05059 8.32857 4.85972 8.4693 4.71899L11.4693 1.71899C11.539 1.64926 11.6217 1.59394 11.7127 1.55619C11.8038 1.51845 11.9014 1.49902 11.9999 1.49902C12.0985 1.49902 12.1961 1.51845 12.2871 1.55619C12.3782 1.59394 12.4609 1.64926 12.5306 1.71899L15.5306 4.71899C15.6713 4.85972 15.7503 5.05059 15.7503 5.24961C15.7503 5.44864 15.6713 5.63951 15.5306 5.78024C15.3898 5.92097 15.199 6.00003 14.9999 6.00003C14.8009 6.00003 14.61 5.92097 14.4693 5.78024L12.7499 4.05993V19.9393L14.4693 18.219C14.539 18.1493 14.6217 18.0939 14.7127 18.0562C14.8038 18.0184 14.9014 17.999 14.9999 17.999C15.0985 17.999 15.1961 18.0184 15.2871 18.0562C15.3782 18.0939 15.4609 18.1493 15.5306 18.219Z"
        fill="#575353"
      />
    </svg>
  );
};

export default ArrowVertical;
