import NoResultsState from "@common/EmptyState/NoResultsState";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Box, SxProps } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import { isMobile } from "@utils/index";
import React, { memo } from "react";

type MobileListWithStatesProps = {
  queryKey: string;
  length?: number;
  targetRef: any;
  children: React.ReactNode;
  isLoading: boolean;
  sx?: SxProps;
  LoadingSkeleton?: React.ComponentType;
};

const MobileListWithStates = ({
  queryKey,
  length,
  targetRef,
  children,
  isLoading,
  sx,
  LoadingSkeleton,
}: MobileListWithStatesProps) => {
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, queryKey),
  );

  if (!isLoading && !length) {
    return <NoResultsState searchQuery={searchQuery} />;
  }
  const isCustomLoadingComponent = !!LoadingSkeleton;

  return (
    <>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        sx={{
          paddingBottom: "24px",
          ...sx,
        }}
      >
        {children}
        {isLoading && (
          <>
            {isCustomLoadingComponent ? (
              <>
                {Array(7)
                  .fill(null)
                  .map((c, idx) => (
                    <LoadingSkeleton key={idx} />
                  ))}
              </>
            ) : (
              <LoadingSpinner sx={{ height: "100px" }} />
            )}
          </>
        )}
      </Box>
      {length && isMobile ? (
        <div
          ref={targetRef}
          style={{
            width: 1,
            height: 1,
            paddingBottom: 48,
            visibility: "hidden",
          }}
        ></div>
      ) : null}
    </>
  );
};

export default memo(MobileListWithStates);
