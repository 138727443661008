import { Box, Divider, Slider, Stack } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { Text } from "@common/Text";
import DeleteIcon from "@assets/icons/RebrandedIcons/DeleteIcon";
import { HiddenComponent } from "@containers/HiddenComponent";

type ImageEditorToolbarProps = {
  zoom: number;
  minZoom: number;
  maxZoom: number;
  handleRotate: () => void;
  handleZoom: (isAdd?: boolean) => void;
  handleChangeSlider: (event: Event, zoom: number | number[]) => void;
  handleDelete: () => void;
  canDelete: boolean;
};

const ImageEditorToolbar = ({
  zoom,
  minZoom,
  maxZoom,
  handleRotate,
  handleZoom,
  handleChangeSlider,
  handleDelete,
  canDelete,
}: ImageEditorToolbarProps) => {
  const isMaxZoom = zoom >= maxZoom;
  const isMinZoom = zoom <= minZoom;
  return (
    <CustomizeButtonsContainer>
      <Stack gap="8px" alignItems="center" flexDirection="row">
        <CustomButton disabled={isMinZoom} onClick={() => handleZoom()}>
          <RemoveOutlinedIcon
            sx={{
              fill: isMinZoom ? palette.neutral[70] : palette.neutral[90],
            }}
            fontSize="medium"
          />
        </CustomButton>
        <CustomSlider
          value={zoom}
          min={minZoom}
          max={maxZoom}
          sx={{
            width: "111px",
          }}
          step={0.1}
          aria-label="Zoom"
          onChange={handleChangeSlider}
        />
        <CustomButton disabled={isMaxZoom} onClick={() => handleZoom(true)}>
          <AddOutlinedIcon
            sx={{
              fill: isMaxZoom ? palette.neutral[70] : palette.neutral[90],
            }}
            fontSize="medium"
          />
        </CustomButton>
      </Stack>
      <Text
        mx="4px"
        color={palette.black[100]}
        fontWeight="book"
        fontSize="14px"
        minWidth="40px"
        sx={{ userSelect: "none" }}
      >
        {zoom}%
      </Text>
      <CustomDivider orientation="vertical" />
      <CustomButton onClick={() => handleRotate()}>
        <CachedOutlinedIcon
          sx={{
            fill: palette.neutral[90],
          }}
          fontSize="medium"
        />
      </CustomButton>
      <HiddenComponent hidden={!canDelete}>
        <>
          <CustomDivider orientation="vertical" />
          <CustomButton
            disabled={!canDelete}
            onClick={handleDelete}
            data-testid="delete-image-button"
          >
            <DeleteIcon width="23px" fill={palette?.filled.red} height="23px" />
          </CustomButton>
        </>
      </HiddenComponent>
    </CustomizeButtonsContainer>
  );
};

export default ImageEditorToolbar;

const CustomizeButtonsContainer = styled(Box)(() => ({
  width: "auto",
  boxShadow: "0px 3px 15px 0px #02020226",
  padding: "4px 12px",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  gap: "16px",
  paddingTop: "8px",
}));

const CustomButton = styled("button")(({ disabled }) => ({
  all: "unset",
  cursor: disabled ? "default" : "pointer",
  borderRadius: "100px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: palette.liftedWhite[200],
  },
}));

const CustomDivider = styled(Divider)(() => ({
  bgcolor: palette.liftedWhite[200],
  height: "24px",
  width: "1px",
}));

const CustomSlider = styled(Slider)({
  color: palette.primary.main,
  height: 8,
  margin: "0 8px",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: palette.neutral.white,
    border: "0.75px solid rgba(2, 2, 2, 0.7)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },

  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
