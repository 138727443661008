const DashedLine = ({ small = false, width }: { small?: boolean, width?: number | string }) => {
  if (small) {
    return (
      <svg
        width="342"
        height="2"
        viewBox="0 0 342 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 1H342" stroke="#DDDCE5" strokeDasharray="11 11" />
      </svg>
    );
  }
  return (
    <svg
      width={width || "864"}
      height="1"
      viewBox="0 0 864 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0.5H864" stroke="#DDDCE5" strokeDasharray="11 11" />
    </svg>
  );
};

export default DashedLine;
