import React from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AmountText, BaseText } from "./atoms";
import { palette } from "@palette";

const getCostLabel = (status: string, recipient: string) => {
  switch (status) {
    case "Pending":
      return `${recipient} will receive (USD)`;
    case "Requested":
      return `${recipient} will receive (USD)`;
    case "Sending":
      return `${recipient} is  receiving (USD)`;
    case "Ready to Process":
      return `${recipient} is  receiving (USD)`;
    case "Sent":
      return `${recipient} recieved (USD)`;
    case "Error":
      return `Failed Amount (USD)`;
    case "Canceled":
      return `Canceled Amount (USD)`;
    default:
      return `Amount (USD)`;
  }
};

export function ChargedColumnGrid({
  fees,
  cost,
  charged,
  styleProperties,
  feePaidByCustomer,
  status,
  recipient,
  displayType,
}: {
  status: string;

  fees: number;
  cost: number;
  styleProperties: any;
  feePaidByCustomer: boolean;
  recipient: string;
  charged: number;
  displayType: string;
}) {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const isTransfer = displayType === "Money Transfer";

  return (
    <Stack
      width={styleProperties.isSmallView ? "100%" : "50%"}
      direction="row"
      justifyContent={styleProperties.isSmallView ? "flex-start" : "flex-end"}
      // paddingRight={`${styleProperties.paddingRight}px`}
      paddingTop={styleProperties.isSmallView ? 4 : 0}
    >
      <Stack
        direction="column"
        gap={0.5}
        alignItems="flex-start"
        // width={`calc(${styleProperties.columnWidth}px ${
        //   isXl ? "- 12px" : "+ 0px"
        // })`}
        paddingBottom="20px"
        sx={{ position: "relative", minWidth: "200px" }}
      >
        <Stack width="100%" direction="row" justifyContent="space-between">
          <BaseText
            flex={1}
            value={
              isTransfer
                ? getCostLabel(status, recipient)
                : "Amount (USD)"
            }
          />
          <AmountText value={cost} />
        </Stack>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <BaseText flex={1} value="Fee (USD)" />
          <AmountText value={`${fees}*`} />
        </Stack>
        <div
          style={{
            height: 2,
            width: "100%",
            borderBottom: `2px solid ${palette.neutral[20]}`,
            marginBottom: 10,
          }}
        />
        <Stack width="100%" direction="row" justifyContent="space-between">
          <BaseText
            flex={1}
            color={palette.neutral[800]}
            value={isTransfer ? "Money Transfer (USD)" : "Charged (USD)"}
          />
          <AmountText value={charged} />
        </Stack>
        <Box
          sx={{
            width: "max-content",
            pt: 2,
            alignSelf: "flex-end",
          }}
        >
          <BaseText
            color={palette.neutral[800]}
            value={
              feePaidByCustomer
                ? "* Customer paid fee"
                : "* Fee deducted from Available Balance"
            }
          />
        </Box>
      </Stack>

      {/* <Stack
        direction="column"
        gap={0.5}
        paddingRight={isXl ? "16px" : "32px"}
      ></Stack> */}
    </Stack>
  );
}
