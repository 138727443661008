import { Components } from "@mui/material";

export const text: Components["MuiTypography"] = {
  defaultProps: {
    variantMapping: {
      body: "p",
      headline: "p",
      button: "p",
      caption: "p",
    },
    variant: "body",
  },
};
