import { namespaces } from "../../../resources/i18n.constants";

export const manage_money = {
  [namespaces.pages.manageMoney]: {
    manageMoney: "Gestionar dinero",
    bankAccounts: "Cuentas bancarias",
    availableBalance: "Saldo disponible",
    totalBalance: "Saldo total",
    LifetimeRaised: "Recaudado en la vida",
    ApprovedTransactions: "Transacciones aprobadas",
    refundedTransactions: "Transacciones reembolsadas",
    chargebackTransactions: "Transacciones de devolución de cargo",
    transferMoney: "Transferir dinero",
    BankAccount: "Cuenta bancaria 654658",
    Details: "Detalles",
    TransactionsReport: "Informe de transacciones",
    download_Transactions_report_msg:
      "Estás a punto de descargar el informe de transacciones",
    download_Transactions_report_msg2:
      "Si tiene un problema para descargar su archivo o no lo puede encontrar en la carpeta",
    click_here: "haga clic aquí",
    AvailableBalance: "Saldo disponible",
    TransferMoney: "Transferir dinero",
    blockingDownload_msg:
      "Estás a punto de descargar el informe de transacciones. Por favor, espere mientras se genera el informe.",
    try_different_browser_msg:
      " Si continúa teniendo problemas, intente con un navegador diferente. Si no realiza la descarga en ningún navegador, envíe un correo electrónico a support@givebox.com para obtener más ayuda",
    showing: "Mostrando",
    records_of: "registros de",
    transactions: " Transacciones",
    clickHere: "haga clic aquí ",
    PleaseVerifyYourIdentityToTransferMoney:
      "Verifique su identidad para transferir dinero",
    YourGivebox_account_is_protected_with_two_factor_authentication:
      "Su cuenta de Givebox está protegida con autenticación de dos factores",
    PleaseChoose_msg:
      "Elija si desea recibir el código de verificación por correo electrónico o mensaje de texto. Utilizará este código para verificar su identidad en la siguiente pantalla",
    SendCodeViaEmailTo: "Enviar código por correo electrónico a",
    SendCodeViaTextMessageTo: " Enviar código por mensaje de texto a",
    I_already_have_a_code: "Ya tengo un código",
    SendVerificationCode: "Enviar código de verificación",
    PleaseEnterVerificationCode: "Ingrese el código de verificación",
    IdentityVerification: "Verificación de identidad",
    Verification_code_was_sent_to: "El código de verificación se envió a",
    CheckYourPhone:
      "Verifique su teléfono en busca de un mensaje de texto con la verificación de Givebox código. Ingrese el código de verificación del mensaje de texto en la entrada campo de abajo.",
    searchRequestMessage:
      "Busque en su correo electrónico el asunto Código de verificación de Givebox. Copie y pegue el código de verificación del correo electrónico e introdúzcalo en el campo de entrada a continuación.",
    VerificationCode_is_valid_for_up_to_60_minutes:
      "El código de verificación es válido durante 60 minutos .",
    Request_a_new_verification_code:
      "Solicitar un nuevo código de verificación",
  },
};
