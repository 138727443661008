import { TextareaAutosize } from "@mui/material";

type Props = {
  response: Record<string, any>;
};

const JSONViewer = ({ response }: Props) => {
  const computedResponse = JSON.stringify(response, undefined, 2);

  return (
    <TextareaAutosize
      readOnly
      value={computedResponse}
      data-testid="OFAC-JSON-Response"
    />
  );
};

export default JSONViewer;
