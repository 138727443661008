import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

const drawerBleeding = 0;

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  window?: () => Window;
  _renderFixedHeader?: () => React.ReactNode;
  _renderComponent?: () => React.ReactNode;
  _renderActions?: () => React.ReactNode;
}

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const Drawer = (props: Props) => {
  const {
    window,
    open: _open,
    _renderComponent,
    _renderActions,
    _renderFixedHeader,
  } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(_open);
  }, [_open]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    props.setOpen(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root
      sx={{
        borderRadius: "24px",
      }}
    >
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(90% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        PaperProps={{
          sx: {
            borderRadius: "24px 24px 0 0",
            backgroundColor: _renderFixedHeader ? "#F6F6F4" : undefined,
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(3px)",
              backgroundColor: "rgba(53, 53, 53, 0.15)",
            },
          },
        }}
      >
        {_renderFixedHeader && _renderFixedHeader()}
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            zIndex: 1,
            right: 0,
            left: 0,
          }}
        >
          <Puller />
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            paddingTop: _renderFixedHeader ? 2 : 4,
            paddingBottom: 4,
            height: "100%",
            overflow: "auto",
            borderRadius: "24px 24px 0px 0px",
            backgroundColor: "#F6F6F4",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&::-webkit-scrollbar-track": {
              display: "none",
            },
          }}
        >
          {_renderComponent && _renderComponent()}
        </StyledBox>
        {_renderActions && _renderActions()}
      </SwipeableDrawer>
    </Root>
  );
};

export default Drawer;
