import { StyledIconButton } from "@common/IconButton";
import { makeStyles } from "@mui/styles";
import { Badge, BadgeProps, Box, Theme, styled } from "@mui/material";
import { ConversationsIcon } from "@assets/icons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { animated, useTransition } from "react-spring";
import { HiddenComponent } from "@containers/HiddenComponent";

type Props = {
  isLoading: boolean;
  handleOpenConversationsModal: (open?: boolean | undefined) => void;
  isOpen?: boolean;
  isOpenChangeLog?: boolean;
  totalUnread: number;
  isReplyFromMerchant?: boolean;
};

const ConversationsButton = ({
  isLoading,
  handleOpenConversationsModal,
  isOpen = false,
  isOpenChangeLog = false,
  isReplyFromMerchant = false,
  ...rest
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const styles = useStyles({
    isMobileView,
    isOpen: isOpen || isOpenChangeLog,
  });

  const transitions = useTransition(Boolean(isOpen && !isMobileView), {
    from: { transform: "translateY(100px)", opacity: 0 },
    enter: { transform: "translateY(0px)", opacity: 1 },
    leave: { transform: "translateY(100px)", opacity: 0 },
    config: { duration: 300, easing: (t: any) => t * (2 - t) },
  });

  const AnimatedButton = animated(StyledIconButton);

  return transitions((style, isModalVisible) => (
    <HiddenComponent hidden={isModalVisible}>
      <AnimatedButton
        style={style}
        id="conversationButton"
        className={styles.buttonStyles}
        onClick={() =>
          handleOpenConversationsModal(!isMobileView ? true : !isOpen)
        }
        disabled={isLoading}
        sx={{
          position: "relative",
          borderRadius: "50%",
        }}
        data-testid="conversation-button"
      >
        <Box className={styles.containerStyles}>
          <CustomBadge
            color="primary"
            badgeContent={rest.totalUnread || undefined}
            isReplyFromMerchant={isReplyFromMerchant}
            data-testid="unread-conversation-badge"
          >
            <ConversationsIcon />
          </CustomBadge>
        </Box>
      </AnimatedButton>
    </HiddenComponent>
  ));
};

export default ConversationsButton;

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyles: ({
    isMobileView,
    isOpen,
  }: {
    isMobileView: boolean;
    isOpen: boolean;
  }) => {
    return {
      position: isMobileView ? (isOpen ? "relative" : "fixed") : "absolute",
      bottom: isMobileView ? (isOpen ? "initial" : "30px") : "25px",
      left: isMobileView ? (isOpen ? "0px" : "initial") : "-70px",
      right: isMobileView ? "20px" : "70px",
      ...(isMobileView && { top: isOpen ? "0px" : "initial" }),
      boxShadow: isMobileView
        ? "0px 2.625px 13.125px 0px rgba(2, 2, 2, 0.15)"
        : "initial",
    };
  },

  containerStyles: {
    borderRadius: "50%",
    padding: "11px",
    backgroundColor: theme.palette.neutral.white,
  },
}));

interface CustomBadgeProps extends BadgeProps {
  isReplyFromMerchant: boolean;
}

const CustomBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "isReplyFromMerchant",
})<CustomBadgeProps>(({ isReplyFromMerchant }) => ({
  "& .MuiBadge-badge": {
    right: "-3px",
    top: "-3px",

    ...(isReplyFromMerchant && {
      color: "#FFFFFF",
      backgroundColor: "#FF8124",
    }),
  },
}));
