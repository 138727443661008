import { SvgIconProps } from "@mui/material";

const ReplyIcon = ({
  width = 20,
  height = 20,
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9422 12.3172L14.1922 16.0672C14.0749 16.1845 13.9159 16.2503 13.75 16.2503C13.5841 16.2503 13.4251 16.1845 13.3078 16.0672C13.1905 15.9499 13.1247 15.7909 13.1247 15.625C13.1247 15.4591 13.1905 15.3001 13.3078 15.1828L15.9914 12.5H10C7.84581 12.4977 5.78051 11.641 4.25727 10.1177C2.73403 8.59449 1.87727 6.52919 1.875 4.375C1.875 4.20924 1.94085 4.05027 2.05806 3.93306C2.17527 3.81585 2.33424 3.75 2.5 3.75C2.66576 3.75 2.82473 3.81585 2.94194 3.93306C3.05915 4.05027 3.125 4.20924 3.125 4.375C3.12707 6.19773 3.85206 7.94521 5.14092 9.23408C6.42979 10.5229 8.17727 11.2479 10 11.25H15.9914L13.3078 8.56719C13.1905 8.44991 13.1247 8.29085 13.1247 8.125C13.1247 7.95915 13.1905 7.80009 13.3078 7.68281C13.4251 7.56554 13.5841 7.49965 13.75 7.49965C13.9159 7.49965 14.0749 7.56554 14.1922 7.68281L17.9422 11.4328C18.0003 11.4909 18.0464 11.5598 18.0779 11.6357C18.1093 11.7115 18.1255 11.7929 18.1255 11.875C18.1255 11.9571 18.1093 12.0385 18.0779 12.1143C18.0464 12.1902 18.0003 12.2591 17.9422 12.3172Z"
        fill="#343330"
      />
    </svg>
  );
};

export default ReplyIcon;
