import React from "react";

const ArrowForwardDown = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53033 13.4697C6.23744 13.1768 5.76256 13.1768 5.46967 13.4697C5.17678 13.7626 5.17678 14.2374 5.46967 14.5303L6.53033 13.4697ZM11.9301 19.9301L11.3997 20.4604L11.3997 20.4604L11.9301 19.9301ZM12.0699 19.9301L11.5396 19.3997L11.5396 19.3998L12.0699 19.9301ZM18.5303 14.5303C18.8232 14.2374 18.8232 13.7626 18.5303 13.4697C18.2374 13.1768 17.7626 13.1768 17.4697 13.4697L18.5303 14.5303ZM11.25 19.0639C11.25 19.4781 11.5858 19.8139 12 19.8139C12.4142 19.8139 12.75 19.4781 12.75 19.0639L11.25 19.0639ZM12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5L12.75 5ZM5.46967 14.5303L11.3997 20.4604L12.4604 19.3997L6.53033 13.4697L5.46967 14.5303ZM12.6003 20.4604L18.5303 14.5303L17.4697 13.4697L11.5396 19.3997L12.6003 20.4604ZM12.75 19.0639L12.75 5L11.25 5L11.25 19.0639L12.75 19.0639ZM11.3997 20.4604C11.7317 20.7924 12.2683 20.7924 12.6003 20.4604L11.5396 19.3998C11.7934 19.1459 12.2066 19.1459 12.4604 19.3997L11.3997 20.4604Z"
        fill="#4C4A52"
      />
    </svg>
  );
};

export default ArrowForwardDown;
