import { APIResponseAlert } from "../types";

export const getTitleAndSubTitle = (
  obj: APIResponseAlert,
  receiver: string,
) => {
  const isEnterprise = receiver === "enterprise";

  switch (obj.name) {
    case "chargeback":
      return {
        title: "Chargeback alert",
        subTitle:
          "You will receive a notification when transferring money to bank accounts.",
      };
    case "chargeback_reversed":
      return {
        title: obj.displayName,
        subTitle:
          "You will be notified when a chargeback has been reversed during money transfers from bank accounts.",
      };

    case "new_member":
      return {
        title: obj.displayName,
        subTitle: "Receive notifications when a new member joins.",
      };
    case "transfer":
      return {
        title: "Money transfers",
        subTitle: "Receive notifications for all money transfer activities.",
      };
    case "daily_transaction_summary":
      return {
        title: "Daily report",
        subTitle:
          "Receive detailed daily reports on the performance of your payment forms.",
      };
    case "monthly_transaction_summary":
      return {
        title: "Monthly report",
        subTitle:
          "Receive detailed monthly reports on the performance of your payment forms.",
      };
    case "transaction":
      return {
        title: "Transactions",
        subTitle: "You'll be notified on new transactions",
      };
    case "high_transaction":
      return {
        title: obj.displayName,
        subTitle: "You'll be notified on high transactions",
      };
    case "refund":
      return {
        title: obj.displayName,
        subTitle: "Get notified when a refund is processed.",
      };
    case "new_bank_account":
      return {
        title: obj.displayName,
        subTitle: "Get notified when a new bank account is added.",
      };
    case "new_sweepstakes_winner":
      return {
        title: "New sweepstake",
        subTitle:
          "You will receive an notification for each new sweepstakes winner",
      };
    case "first_transaction_alert":
      return {
        title: obj.displayName,
        subTitle:
          "You will receive an notification when you get your first transaction",
      };
    case "new_enterprise":
      return {
        title: "Create Provider",
        subTitle: "Get notified upon the creation of a new provider.",
      };
    case "new_merchant":
      return {
        title: "Create Merchant",
        subTitle: "Get notified upon the creation of a new merchant.",
      };
    case "merchant_mid_issue":
      return {
        title: "MID issue",
        subTitle: isEnterprise
          ? "Receive notifications for every new MID issue."
          : "Receive notifications for every new MID (Merchant Identification Number) issue.",
      };
    case "merchant_levels":
      return {
        title: "Merchant level 2-4",
        subTitle:
          "You will receive notifications for every for any new issues related to Merchant Level 2-4",
      };
    case "merchant_declined":
      return {
        title: "Merchant declined",
        subTitle: "You will receive notifications for every merchant declined",
      };
    case "merchant_suspended":
      return {
        title: "Merchant suspended",
        subTitle: "You will receive notifications when merchant is suspended",
      };
    case "ofac_match":
      return {
        title: "OFAC Match",
        subTitle:
          "Receive immediate notifications for any matches found with the OFAC sanctions list.",
      };
    case "negative_balance":
      return {
        title: "Negative balance",
        subTitle:
          "Receive immediate notifications for any negative balance occurrences in your account.",
      };
    case "mrp_level_eq_1":
      return {
        title: "Merchant Risk Profile Medium Level",
        subTitle:
          "Receive notifications when any merchant reaches a Medium Risk Level.",
        highlight: "Medium Level",
      };
    case "mrp_level_gt_1":
      return {
        title: "Merchant Risk Profile High Level and above ",
        subTitle:
          "Receive daily notifications for each merchant with a Risk Level of High or above.",
        highlight: "High or above.",
      };

    case "merchant_owner_underwriting_approval":
      return {
        title: "Merchant Owner Underwriting Approval",
        subTitle:
          "Get notified when a merchant owner's underwriting is approved.",
      };
    case "merchant_underwriting_approval":
      return {
        title: "Merchant Underwriting Approval",
        subTitle: "Get notified when a merchant's underwriting is approved.",
      };
    case "convo_mention_alert":
      return {
        title: "Conversations Mention Alert",
        subTitle: "Receive alerts when you are mentioned in conversations.",
      };

    default:
      return {
        title: obj.displayName,
        subTitle: obj.displayName,
      };
  }
};
