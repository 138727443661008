import * as React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Input } from "@common/Input";
import { RHFInputProps } from "@common/Input/Input";

export const RHFStreetAddress: React.FC<RHFInputProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
        <Input
          inputRef={ref}
          {...rest}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...props}
          error={!!error}
          helperText={
            error
              ? error.message
              : isFocused
              ? "PO Boxes or PMBs are not allowed"
              : undefined
          }
        />
      )}
    />
  );
};
