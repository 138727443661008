import RepliesChat from "../Replies/RepliesChat";

const ReplySection = ({
  isMobileView,
  data,
}: {
  isMobileView: boolean;
  data: any;
}) => {
  return (
    <>
      <RepliesChat data={data} isMobileView={isMobileView} />
    </>
  );
};

export default ReplySection;
