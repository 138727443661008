import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { RadioFormSection } from "./atoms";
import { TSelectedColumns } from "../hooks/useColumnSelection";
import { useFormContext } from "react-hook-form";
import { Colum_types, TExportTable } from "../types";
import { getMonthRange } from "../utils";

const ExportColumnsSection = ({
  initialColumns,
  visibleColumns,
  monthlyColumns,
  column_type,
  onChangeCallBack,
  showMonthlyReport = false,
  initialTimezone,
}: {
  initialColumns: TSelectedColumns;
  visibleColumns: string[];
  monthlyColumns: string[];
  column_type: Colum_types;
  onChangeCallBack: (type: string) => void;
  showMonthlyReport: boolean;
  initialTimezone: string;
}) => {
  const { setValue } = useFormContext<TExportTable>();

  const onSelectAll = () => {
    let newArray: string[] = [];
    Object.values(initialColumns).forEach(
      (values) => (newArray = [...newArray, ...values]),
    );
    setValue("selectedColumns", newArray);
  };

  const onSelectVisible = () => {
    setValue("selectedColumns", visibleColumns);
  };
  const { startDate, endDate } = getMonthRange("current");
  const onSelectMonthly = () => {
    setValue("selectedColumns", monthlyColumns);

    //set the dateRange to current month first and last day what is the default value when clicked
    //set the acc, initial timezone
    setValue("customDateRange", {
      timezone: initialTimezone,
      startDate,
      endDate,
    });
  };
  const isOnlyMerchant = ["merchant"]?.includes(column_type);
  const isSettlement = column_type === "settlement";
  const columnsIncluded = [
    {
      title: "All columns",
      helper: "Entire columns including the hidden ones will be exported",
      value: "all",
      onChange: onSelectAll,
    },
    {
      title: "Only columns in the view",
      helper: "Visible columns within the table will be exported",
      value: "visible",
      onChange: onSelectVisible,
      isVisible: !isSettlement,
    },
    {
      title: "Processing Monthly Report",
      helper: "",
      value: "processing_monthly",
      onChange: onSelectMonthly,
      isVisible: isOnlyMerchant && showMonthlyReport,
    },
    {
      title: "DDA/Maintenance Monthly Changes Report",
      helper: "",
      value: "maintenance_monthly",
      onChange: onSelectVisible,
      isVisible: false, // it's RPT004, we hide until the implementation
    },
    {
      title: "Custom",
      helper: "Selection of specific columns to be exported",
      value: "custom",
    },
  ];

  return (
    <FadeUpWrapper delay={150}>
      <RadioFormSection
        title="Columns included in the export"
        name="columnsIncluded"
        radios={columnsIncluded}
        onChangeCallBack={onChangeCallBack}
      />
    </FadeUpWrapper>
  );
};

export default ExportColumnsSection;
