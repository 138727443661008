type Props = {
  width?: string | number;
  height?: string | number;
  color?: string;
};

const FoladerDuplicate = ({ color = "#915CA7" }: Props) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8335 7.83325C9.8335 6.89044 9.8335 6.41904 10.1264 6.12615C10.4193 5.83325 10.8907 5.83325 11.8335 5.83325H14.4308C15.0322 5.83325 15.3329 5.83325 15.5745 5.9826C15.8162 6.13195 15.9507 6.40091 16.2196 6.93882L17.4168 9.33325H21.8335C22.7763 9.33325 23.2477 9.33325 23.5406 9.62615C23.8335 9.91904 23.8335 10.3904 23.8335 11.3333V15.4999C23.8335 16.4427 23.8335 16.9141 23.5406 17.207C23.2477 17.4999 22.7763 17.4999 21.8335 17.4999H11.8335C10.8907 17.4999 10.4193 17.4999 10.1264 17.207C9.8335 16.9141 9.8335 16.4427 9.8335 15.4999V7.83325Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M20.3332 17.4999V20.5666C20.3332 21.1266 20.3332 21.4067 20.2242 21.6206C20.1283 21.8087 19.9753 21.9617 19.7872 22.0576C19.5733 22.1666 19.2932 22.1666 18.7332 22.1666H6.76651C6.20645 22.1666 5.92643 22.1666 5.71251 22.0576C5.52435 21.9617 5.37137 21.8087 5.2755 21.6206C5.1665 21.4067 5.1665 21.1266 5.1665 20.5666V10.9333C5.1665 10.3732 5.1665 10.0932 5.2755 9.87926C5.37137 9.6911 5.52435 9.53812 5.71251 9.44225C5.92643 9.33325 6.20645 9.33325 6.7665 9.33325H9.83317"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default FoladerDuplicate;
