import React from "react";

type ArrowDownChevronProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

const ArrowDownChevron = ({
  color = "#A9AFBD",
  width = 12,
  height = 7,
  className,
}: ArrowDownChevronProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1.5L6.70711 5.79289C6.31658 6.18342 5.68342 6.18342 5.29289 5.79289L1 1.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownChevron;
