import { EnterpriseIcon } from "@assets/emptyStatesIcons";
import {
  CogSettingsIcon,
  DisputesIcon,
  JobsIcon,
  MerchantsIcon,
  SheetIcon,
  TransfersIcon,
} from "@assets/rebrandIcons";
import SettlementIcon from "@assets/rebrandIcons/SettlementIcon";
import { shouldBeHidden } from "@constants/constants";

type Props = {
  isTransfersPageEnabled: boolean;
  isDisputesPageEnabled: boolean;
};

const getAcquirerMenu = ({
  isTransfersPageEnabled,
  isDisputesPageEnabled,
}: Props) => ({
  menu: [
    // {
    //   name: "analytics",
    //   path: "/acquirer/analytics",
    //   icon: <StatsAnalyticsIcon />,
    //   index: 1,
    // },
    {
      name: "Processing",
      path: "/acquirer/processing",
      icon: <JobsIcon />,
      index: 1,
    },
    ...(shouldBeHidden.settlement
      ? []
      : [
          {
            name: "Settlement",
            path: "/acquirer/settlements",
            icon: <SettlementIcon />,
            index: 2,
          },
        ]),
    ...(isDisputesPageEnabled
      ? [
          {
            name: "Disputes",
            path: "/acquirer/disputes",
            icon: <DisputesIcon />,
            index: 3,
          },
        ]
      : []),
    ...(isTransfersPageEnabled
      ? [
          {
            name: "Transfers",
            path: "/acquirer/transfers",
            icon: <TransfersIcon />,
            index: 4,
          },
        ]
      : []),
    {
      name: "__title__",
      icon: <SheetIcon isMenu />,
      path: "",
      text: "",
      index: 5,
    },
    {
      name: "Providers",
      path: "/acquirer/providers",
      icon: <EnterpriseIcon />,
      index: 6,
    },
    {
      name: "Merchants",
      path: "/acquirer/merchants",
      icon: <MerchantsIcon />,
      index: 7,
    },
  ],
  footer: [
    {
      name: "Settings",
      path: "/acquirer/settings",
      icon: <CogSettingsIcon />,
    },
  ],
});

export { getAcquirerMenu };
