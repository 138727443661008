import { Box, BoxProps, Grid, styled } from "@mui/material";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { palette } from "@palette";
import { Text, TruncateText } from "@common/Text";
import { PlusIcon, TrashBin } from "@assets/icons/RebrandedIcons";

type MerchantCategoryItemProps = {
  added?: boolean;
  isSelected?: boolean;
  isOnlyRemaining?: boolean;
  onClick?: () => void;
} & Omit<TCategoryCode, "id"> &
  BoxProps;

const CategoryItem = ({
  code,
  title,
  added,
  isSelected,
  onClick,
  isOnlyRemaining,
  ...props
}: MerchantCategoryItemProps) => {
  return (
    <StyledMerchantCategoryItem
      {...props}
      isSelected={isSelected}
      isOnlyRemaining={isOnlyRemaining}
      my={0.5}
      onClick={isOnlyRemaining ? () => null : onClick} // cannot remove only remaining MCC
      data-testid={`mcc-${added ? "selected" : "selectable"}-item-${code}`}
    >
      <CategoryCode isSelected={isSelected}>{code}</CategoryCode>
      <TruncateText
        lineClamp={1}
        sx={{ wordBreak: "break-all", userSelect: "none" }}
        fontWeight="book"
        color={isSelected ? palette.neutral[60] : palette.neutral[80]}
        flexGrow={1}
      >
        {title}
      </TruncateText>
      <Box display="none" className="mcc-trail-icon">
        {added || isSelected ? (
          isOnlyRemaining ? (
            <></>
          ) : (
            <TrashBin height={20} width={20} />
          )
        ) : (
          <PlusIcon height={20} width={20} />
        )}
      </Box>
    </StyledMerchantCategoryItem>
  );
};

const StyledMerchantCategoryItem = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isSelected" && prop !== "isOnlyRemaining",
})<BoxProps & { isSelected?: boolean; isOnlyRemaining?: boolean }>(
  ({ isSelected, isOnlyRemaining }) => ({
    display: "flex",
    gap: "8px",
    alignItems: "center",
    padding: "4px",
    borderRadius: "4px",
    cursor: isOnlyRemaining ? "not-allowed" : "pointer",
    background: isSelected ? palette.neutral[10] : "transparent",
    "&:hover": {
      background: isSelected ? palette.neutral[10] : palette.neutral[5],
      "& .mcc-trail-icon": {
        display: "block",
        position: "relative",
        top: "2px",
      },
    },
  }),
);

const CategoryCode = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ isSelected }) => ({
  padding: "4px",
  borderRadius: "4px",
  background: "#EBEBEB",
  fontSize: 12,
  fontWeight: 350,
  color: isSelected ? palette.neutral[60] : palette.neutral[80],
  userSelect: "none",
}));

export default CategoryItem;
