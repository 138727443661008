export const renameBEBankAccountStatus = (status: string) => {
  switch (status) {
    case "upload":
      return "pending";
    case "pending":
      return "pending_review";
    default:
      return status;
  }
};
