import React from "react";
interface Props {
  width?: number;
  height?: number;
  fill?: string;
}

function CheckIconColored({
  width = 21,
  height = 15,
  fill = "#13A75A",
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7969 2.44734L7.79687 14.4473C7.69236 14.5522 7.56816 14.6354 7.43142 14.6922C7.29467 14.749 7.14806 14.7782 7 14.7782C6.85193 14.7782 6.70532 14.749 6.56858 14.6922C6.43183 14.6354 6.30764 14.5522 6.20312 14.4473L0.953123 9.19734C0.848476 9.0927 0.765466 8.96846 0.708831 8.83173C0.652196 8.695 0.623047 8.54846 0.623047 8.40047C0.623047 8.25247 0.652196 8.10593 0.708831 7.9692C0.765465 7.83247 0.848476 7.70824 0.953123 7.60359C1.05777 7.49895 1.182 7.41593 1.31873 7.3593C1.45546 7.30267 1.60201 7.27352 1.75 7.27352C1.89799 7.27352 2.04454 7.30267 2.18126 7.3593C2.31799 7.41593 2.44223 7.49895 2.54687 7.60359L7.00094 12.0577L18.205 0.855467C18.4163 0.644122 18.703 0.525391 19.0019 0.525391C19.3008 0.525391 19.5874 0.644122 19.7987 0.855467C20.0101 1.06681 20.1288 1.35346 20.1288 1.65234C20.1288 1.95123 20.0101 2.23787 19.7987 2.44922L19.7969 2.44734Z"
        fill={fill}
      />
    </svg>
  );
}

export default CheckIconColored;
