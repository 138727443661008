import { UploadProgressParams } from "@components/UploadFile/hooks/useUploadFiles";
import { useUploadSingleImage as useUploadSingleImageNew } from "@hooks/upload-api/uploadHooks";
import { useUploadSingleImage as useUploadSingleImageOld } from "@hooks/merchant-api/image-gallery/useUploadSingleImage";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type Props = {
  list: {
    file: File;
    id: string;
  }[];
  onUploadProgress: (params: UploadProgressParams) => void;
  onUploadFinish(params: { identifiers: (number | string)[] }): void;
  onUploadFailed(params: { fileIds: string[] }): void;
};

export const useUploadSignature = () => {
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  const { handleUpload: handleUploadNew, isLoading: isLoadingNew } =
    useUploadSingleImageNew();
  const { handleUpload: handleUploadOld, isLoading: isLoadingOld } =
    useUploadSingleImageOld();
  const handleUpload = isFileUploadRefactorEnabled
    ? handleUploadNew
    : handleUploadOld;
  const isLoading = isFileUploadRefactorEnabled ? isLoadingNew : isLoadingOld;
  const handleUploadSignature = async ({
    list,
    onUploadFailed,
    onUploadFinish,
    onUploadProgress,
  }: Props) => {
    try {
      const uploadProgressCb = (progressEvent: ProgressEvent) => {
        onUploadProgress({
          identifier: list?.[0].id,
          fileId: list?.[0].id,
          progress: (progressEvent.loaded * 100) / progressEvent.total,
        });
      };

      const tempUrl = await handleUpload(
        isFileUploadRefactorEnabled
          ? {
              file: list[0]?.file,
              formType: "customer_avatar",
            }
          : (list[0]?.file as any),
        isFileUploadRefactorEnabled ? "customer_avatar" : undefined,
        isFileUploadRefactorEnabled ? uploadProgressCb : undefined,
      );

      if (!tempUrl) return "";

      onUploadFinish({ identifiers: [list?.[0].id] });
      return tempUrl;
    } catch (err) {
      onUploadFailed({ fileIds: [list?.[0]?.id] });
      return "";
    }
  };

  return { handleUploadSignature, isLoading };
};
