import { QKEY_LIST_PAYMENT_FORMS } from "@constants/queryKeys";
import { useCachedList } from "@hooks/common/useCachedList";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useStateEffect } from "@hooks/customReactCore";
import { useAppSelector } from "@redux/hooks";
import {
  selectQueryFilters,
  sorting as sortingReducer,
} from "@redux/slices/fundraisers";
import { selectQueryString } from "@redux/slices/search";
import { useQueryFactory } from "@services/api/products/queryFactory";
import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { encodedQueryFilterMap } from "@services/filtering";
import { detectMobile } from "@utils/index";
import { useMemo, useRef } from "react";
import { useQuery } from "react-query";

export const useGetPaymentForms = useQueryFactory("payment-form");

const useListPaymentForms = () => {
  const queryFilters = useAppSelector(selectQueryFilters);
  const sorting = useAppSelector(sortingReducer);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_PAYMENT_FORMS),
  );

  const { allData, invalidateCache } = useCachedList(QKEY_LIST_PAYMENT_FORMS);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const loadingRef = useRef<boolean>(false);

  const { page, setPage } = usePagination(0, queryFilter.products);
  const queryString = queryFilter.products ? `%3B${queryFilter.products}` : "";

  const { data, isError, isLoading, error } = useGetPaymentForms(
    {
      queryString,
      page,
      sorting,
      searchQuery,
    },
    {
      refetchOnWindowFocus: false,

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useStateEffect(() => {
    if (detectMobile()) invalidateCache();
    setPage(1);
  }, [sorting, searchQuery, queryString]);

  const usedData = detectMobile() ? allData : data?.data ?? [];

  return {
    isError,
    page,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: usedData,
    handlePageChange,
    totalRows: data?.total ?? 0,
    setPage: () => setPage((current) => current + 1),
    setPageDispatcher: setPage,
    allRows: usedData,
    loadingRef,
    isLoading,
    error,
    state: {
      isEmpty: !queryString && !searchQuery && data?.total === 0,
      isError,
    },
  };
};

export const getPaymentFormsById = (id: string) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}?filter=typeName:"standard"`),
    method: "GET",
  });
};

export const useGetPaymentFormById = (id: string) => {
  return useQuery(
    ["get-payment-form-by-id", id],
    async () => {
      const data = await getPaymentFormsById(id);
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false },
  );
};

export default useListPaymentForms;
