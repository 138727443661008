import { SvgIconProps } from "@mui/material";

const RoundedCloseIcon = ({
  height = 48,
  width = 49,
  fill = "#662C29",
  stroke = "#FF6565",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="48" height="48" rx="24" fill={fill} />
      <path
        d="M31.2806 30.7198C31.3502 30.7895 31.4055 30.8722 31.4432 30.9632C31.4809 31.0543 31.5003 31.1519 31.5003 31.2504C31.5003 31.349 31.4809 31.4465 31.4432 31.5376C31.4055 31.6286 31.3502 31.7114 31.2806 31.781C31.2109 31.8507 31.1281 31.906 31.0371 31.9437C30.9461 31.9814 30.8485 32.0008 30.7499 32.0008C30.6514 32.0008 30.5538 31.9814 30.4628 31.9437C30.3717 31.906 30.289 31.8507 30.2193 31.781L23.9999 25.5607L17.7806 31.781C17.6398 31.9218 17.449 32.0008 17.2499 32.0008C17.0509 32.0008 16.86 31.9218 16.7193 31.781C16.5786 31.6403 16.4995 31.4494 16.4995 31.2504C16.4995 31.0514 16.5786 30.8605 16.7193 30.7198L22.9396 24.5004L16.7193 18.281C16.5786 18.1403 16.4995 17.9494 16.4995 17.7504C16.4995 17.5514 16.5786 17.3605 16.7193 17.2198C16.86 17.0791 17.0509 17 17.2499 17C17.449 17 17.6398 17.0791 17.7806 17.2198L23.9999 23.4401L30.2193 17.2198C30.36 17.0791 30.5509 17 30.7499 17C30.949 17 31.1398 17.0791 31.2806 17.2198C31.4213 17.3605 31.5003 17.5514 31.5003 17.7504C31.5003 17.9494 31.4213 18.1403 31.2806 18.281L25.0602 24.5004L31.2806 30.7198Z"
        fill={stroke}
      />
    </svg>
  );
};

export default RoundedCloseIcon;
