import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, Grid, Stack, styled } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { RHFCheckbox } from "@common/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import MessageSection from "./MessageSection";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  setConversationTopic,
} from "@redux/slices/conversations";
import CreateTopicButton from "./Sections/CreateTopicButton";
import { customInstance } from "@services/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useGetCurrentMerchantId } from "@hooks/common";
import { Message, Thread } from "./types";
import { isEmpty } from "lodash";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  getGlobalTopic,
  getGlobalTopicLabel,
} from "../hooks/useConversationsModal";
import { challengesWithCustomRejectedIcon } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/ChallengeLayout";
import { ReactNode, useCallback } from "react";
import {
  ExtendedTChallengeStatus,
  IconStatusMapper,
} from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/ChallengeHeader";
import { showMessage } from "@common/Toast";
import useMarkAsSolved from "../hooks/useMarkAsSolved";
import { useUpdateChallenge } from "@components/Merchants/MerchantPreview/hooks/useUpdateChallenge";
import { useGetUserRole } from "@hooks/common/useGetUserRole";

const defaultValues = {
  subject: "",
  message: "",
  module: "",
  isInternalNote: true,
};

type IFormInputs = {
  subject: string;
  message: string;
  module: string;
  isInternalNote: boolean;
};

function UnderwritingThreads({
  isEnterprise = false,
  merchantID,
  thread,
  topicData,
  merchantName,
  handleOpenManageUnderwriting,
}: {
  isEnterprise?: boolean;
  merchantID: number;
  thread: Thread | undefined;
  topicData: any;
  merchantName?: string;
  handleOpenManageUnderwriting?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { queryObject } = useAppSelector(selectConversationTopic);
  const isRiskMonitor = thread?.topicName === "risk_monitor";

  const { canManageConversations } = useGetUserRole();

  const { merchantId: loggedMID } = useGetCurrentMerchantId();
  const pathThread = queryObject?.paths?.find((item) => item.isConversation);
  const isThreadSelected = !isEmpty(thread);

  const { handleUpdateChallengeStatus } = useUpdateChallenge({
    challengeId: queryObject?.challengeId || 0,
  });

  const isThreadClosed = [
    thread?.challenge?.statusName,
    thread?.statusName,
  ].includes("closed");
  const queryClient = useQueryClient();
  const isReadyForVerification = isRiskMonitor
    ? thread?.statusName === "open" && thread?.didSubjectAccReply === true
    : thread?.challenge?.statusName === "ready_for_verification";
  const hideInputs = pathThread?.hideInputs || [];
  const isRejection = pathThread?.isRejection || false;
  const filteredInputs = isThreadSelected ? ["subject", "module"] : [];
  const isThreadClosedRiskMonitor = isThreadClosed && isRiskMonitor;

  const rejectCustomChallenge = () =>
    handleUpdateChallengeStatus({
      merchantId: queryObject?.merchantId || 0,
      data: { status: "open", type: "enhanced_due_diligence" },
    });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(generateSchema([...hideInputs, ...filteredInputs])),
    defaultValues: defaultValues,
  });

  const {
    formState: { isValid },
  } = methods;

  const values = methods.watch();

  const nodeList = [
    {
      node: (
        <RHFSelect
          label="Choose Module"
          name="module"
          options={getModules(topicData)}
        />
      ),
      hidden:
        isThreadSelected ||
        hideInputs.includes("module") ||
        isRejection ||
        isThreadClosedRiskMonitor,
    },
    {
      node: (
        <RHFInput
          name="subject"
          fullWidth
          placeholder="Enter Subject"
          type="text"
          label="Subject"
        />
      ),
      hidden:
        isThreadSelected ||
        hideInputs.includes("subject") ||
        isRejection ||
        isThreadClosedRiskMonitor,
    },
    {
      node: (
        <RHFInput
          name="message"
          label="Message"
          placeholder="Message"
          multiline
          rows={6}
          fullWidth
        />
      ),
      hidden:
        !canManageConversations ||
        hideInputs.includes("message") ||
        isThreadClosedRiskMonitor,
    },
  ];

  const id = pathThread?.pathID;
  const threadId = id && id !== "new" ? id : null;
  const {
    data,
    isLoading: isGettingMessages,
    refetch,
  } = useQuery(
    ["get-thread-messages", threadId],
    async () => {
      const data = await customInstance({
        url: `/threads/${threadId}/messages?sort=-createdAt`,
      });

      await customInstance({
        url: `/merchants/${merchantID}/messages/read`,
        method: "POST",
        data: {
          threadID: threadId,
        },
      });

      return data;
    },
    {
      enabled: !!threadId,
    },
  );

  const messages: Message[] | undefined = data?.data;

  const isMessageResponse = !isEmpty(messages) && !isGettingMessages;
  const topic2Name = pathThread?.topicName || "";
  const { mutate, isLoading } = useMutation({
    mutationFn: async (payload: any) => {
      const { data: topics } = await getGlobalTopic({});

      const findTopic = (name: string, type: string) =>
        topics?.find(
          (item: any) =>
            [item?.name, item?.Name]?.includes(name) &&
            [item?.typeName, item?.Type]?.includes(type),
        );

      const internalTopic = findTopic("underwriting", "internal");
      const activityTopic = findTopic("underwriting", "activity");
      const riskMonitorInternal = findTopic("risk_activity", "internal");

      const customPayload = {
        ...payload,
        topicID: Number(
          payload?.module || activityTopic?.id || activityTopic?.ID,
        ),
      };

      const postActivity = await customInstance({
        url: threadId
          ? `/merchants/${loggedMID}/threads/${threadId}/messages`
          : `/merchants/${loggedMID}/threads`,
        method: "POST",
        data: { ...customPayload, title: payload?.subject },
      });

      const isRiskMonitor = postActivity?.topicName === "risk_monitor";

      if (payload?.isInternalNote) {
        const internalNoteTopicID = isRiskMonitor
          ? riskMonitorInternal?.ID
          : internalTopic?.ID;

        await customInstance({
          url: `/merchants/${loggedMID}/threads`,
          method: "POST",
          data: {
            ...customPayload,
            topicID: internalNoteTopicID,
            originalThreadID: threadId || postActivity?.id || postActivity?.ID,
          },
        });
      }

      return postActivity;
    },
    onError(error: any) {
      if (error?.response?.data?.message?.includes("already exists")) {
        return methods.setError("subject", {
          type: "manual",
          message: error?.response?.data?.message,
        });
      }
      showMessage(
        "Error",
        error?.response?.data?.message || "Something went wrong",
      );
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("fetch-activity-list");
      queryClient.refetchQueries("get-challenges");

      if (isRejection) {
        rejectCustomChallenge();
      }

      if (!threadId) {
        const currentPath = queryObject?.paths?.filter(
          (item: any) => !item.isConversation,
        );

        const paths = [
          ...currentPath,
          {
            pathID: data.topicID,
            pathName: getGlobalTopicLabel(data?.topicName) || data?.topicName,
            avatars: data?.authorAvatarImageURL
              ? [data?.authorAvatarImageURL]
              : [],
          },
          {
            avatars: [],
            isConversation: true,
            pathName: data?.title,
            pathID: data?.id,
            hideInputs: ["module", "subject"],
            topicName: topic2Name,
          },
        ];

        dispatch(
          setConversationTopic({
            isOpen: true,
            isOpenedFromSidePanel: false,
            queryObject: {
              ...queryObject,
              id: data?.topicID,
              name: merchantName || queryObject?.name || "",
              paths: paths,
            },
          }),
        );
      } else {
        refetch();
      }

      methods.reset();
    },
  });

  const handleSubmit: SubmitHandler<IFormInputs> = async (data) => {
    //pathThread
    const payload = {
      ...data,
      module: pathThread?.moduleID || data?.module,
      subject: data?.subject,
      subjectAccID: merchantID,
      topicID: Number(data?.module),
      body: data?.message,
      message: {
        body: data?.message,
        subjectAccID: merchantID,
      },
    };

    if (isThreadClosed) {
      showMessage(
        "Error",
        "Not allowed to reply to the same message more than once",
      );
      methods.reset();
    } else {
      mutate(payload);
    }
  };
  const onSuccess = (data: any) => {
    const paths = queryObject?.paths;
    const pathIndex = paths?.findIndex((item) => item.isConversation);
    const newPaths = [...paths];

    if (pathIndex !== -1) {
      newPaths[pathIndex] = {
        ...newPaths[pathIndex],
        hideInputs: ["module", "subject", "message"],
      };
    } else {
      newPaths.push({
        hideInputs: ["module", "subject", "message"],
        isConversation: true,
        pathName: "",
        avatars: [],
      });
    }
    dispatch(
      setConversationTopic({
        queryObject: {
          ...queryObject,
          paths,
        },
      }),
    );
    queryClient.invalidateQueries("fetch-activity-list");
    queryClient.refetchQueries("get-challenges");
  };

  const { isMobileView } = useCustomTheme();
  const challengeId = thread?.challenge?.id;

  const { mutate: mutateMarkSolved } = useMarkAsSolved({
    merchantId: isRiskMonitor ? thread?.ownerAccID : merchantID,
    isRiskMonitor,
    options: { onSuccess },
  });
  const showNoticeCard = (isRiskMonitor && isThreadClosed) || !isRiskMonitor;
  return (
    <FormProvider {...methods}>
      <Stack
        height="100%"
        justifyContent="space-between"
        flexDirection="column"
        component="form"
        overflow="hidden"
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Box
          style={{
            overflowY: "auto",
            maxHeight: isMobileView ? "100%" : "92%",
            overflowX: "hidden",
          }}
        >
          {showNoticeCard && (
            <FadeUpWrapper delay={50}>
              <NoticeCard
                thread={thread}
                isEnterprise={isEnterprise}
                isRejection={isRejection}
                isThreadClosedRiskMonitor={isThreadClosedRiskMonitor}
                handleOpenManageUnderwriting={handleOpenManageUnderwriting}
              />
            </FadeUpWrapper>
          )}

          {isRejection && (
            <Box p="16px">
              <Text fontWeight="book" fontSize="14px" color="#575353">
                Default Reason
              </Text>
              <Text mt="8px" fontWeight="book" fontSize="14px" color="#8F8F8F">
                The default reason can be provided to the merchant, in addition
                to the specific message you have inserted
              </Text>
              <Box mt="12px" borderRadius="8px" p="12px 16px" bgcolor="#ECECE9">
                <Text fontWeight="book" color="#575353" fontSize="14px">
                  {messages?.[0]?.body}
                </Text>
                <Text
                  mt="16px"
                  fontWeight="book"
                  color="#575353"
                  fontSize="14px"
                >
                  {values?.message}
                </Text>
              </Box>
            </Box>
          )}

          <Box p="16px">
            <Grid container spacing={2}>
              {nodeList.map(({ node, hidden }, index) => {
                if (hidden) return;
                return (
                  <Grid key={index} item xs={12}>
                    <FadeUpWrapper delay={100 + 50 * (index + 1)}>
                      {node}
                    </FadeUpWrapper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {isMessageResponse && (
            <>
              {messages?.map((message, idx) => (
                <FadeUpWrapper key={message?.id} delay={100 + 50 * (idx + 1)}>
                  <MessageSection
                    isEnterprise={isEnterprise}
                    merchantID={merchantID}
                    message={message}
                    showDivider={
                      !(
                        idx === 0 &&
                        (isThreadClosedRiskMonitor || !canManageConversations)
                      )
                    }
                  />
                </FadeUpWrapper>
              ))}
            </>
          )}
        </Box>
        {thread?.didSubjectAccReply &&
          isReadyForVerification &&
          !isRejection && (
            <CreateTopicButton
              handleOpenTopicModal={() =>
                mutateMarkSolved(isRiskMonitor ? thread?.id : challengeId || 0)
              }
              sx={{
                bottom: 40,
              }}
              buttonText="Mark as solved"
            />
          )}
        {!isThreadClosedRiskMonitor && canManageConversations && (
          <Stack
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            p="8px 16px"
            boxShadow="0px -2px 10px 0px rgba(76, 76, 76, 0.1)"
            bgcolor="#ffffff"
          >
            <RHFCheckbox
              dataTestId="internal_note_check_box"
              name="isInternalNote"
              label="Internal Note"
            />
            <CustomButton
              disabled={!isValid || isLoading}
              background="tertiary"
              variant="text"
              type="submit"
              data-testid="post-conversation-button"
            >
              Post
            </CustomButton>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
}

export default UnderwritingThreads;

const NoticeCard = ({
  thread,
  handleOpenManageUnderwriting,
  isEnterprise,
  isRejection = false,
  isThreadClosedRiskMonitor,
}: {
  thread: Thread | undefined;
  handleOpenManageUnderwriting?: () => void;
  isEnterprise: boolean;
  isRejection?: boolean;
  isThreadClosedRiskMonitor?: boolean;
}) => {
  const isThreadSelected = !isEmpty(thread) && !isRejection;

  const color = !isThreadSelected ? "#FFF2E9" : "#E6EAF2";
  const challengeStatus = thread?.challenge?.statusName;

  const text = isThreadClosedRiskMonitor
    ? "This topic is closed. Please start a new topic to notify the merchant."
    : isThreadSelected
    ? thread?.title
    : `Please note: You are messaging the  ${
        isEnterprise ? "Provider" : "merchant"
      } `;

  const mapIconToStatus = useCallback(
    (challengeStatus: ExtendedTChallengeStatus): ReactNode => {
      return IconStatusMapper[challengeStatus];
    },
    [challengeStatus],
  );

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      bgcolor={color}
      justifyContent="space-between"
    >
      <Stack
        gap="8px"
        alignItems="center"
        flexDirection="row"
        width="100%"
        p="8px 16px"
      >
        {!isThreadSelected
          ? mapIconToStatus("create")
          : challengesWithCustomRejectedIcon.includes(
              thread?.challenge?.slug as any,
            ) && challengeStatus === "open"
          ? mapIconToStatus("rejected")
          : challengeStatus !== "rejected"
          ? mapIconToStatus(challengeStatus as any)
          : null}

        <Text color="#575353" fontWeight="regular" fontSize="14px">
          {text}
        </Text>
      </Stack>
      {isThreadSelected && !isThreadClosedRiskMonitor && (
        <Stack
          sx={{
            cursor: "pointer",
          }}
          pr="25px"
          gap="3px"
          alignItems="center"
          flexDirection="row"
          onClick={handleOpenManageUnderwriting}
        >
          <Text fontWeight="regular" color="#575353" fontSize="14px">
            Open
          </Text>
          <Box sx={{ display: "inline-block", transform: "rotate(-45deg)" }}>
            <ArrowRight color="#575353" size={25} />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

const getModules = (data: any) => {
  return data?.map((item: any) => ({
    label: item?.displayName,
    value: item?.id,
  }));
};
const generateYup = ({
  key,
  optionalFields,
  max,
}: {
  key: string;
  optionalFields: string[];
  max: number;
}) =>
  optionalFields.includes(key)
    ? Yup.string()?.max(max)
    : Yup.string().required(`${key} is required`)?.max(max);
const generateSchema = (optionalFields: string[] = []) => {
  return Yup.object().shape({
    subject: generateYup({ key: "subject", optionalFields, max: 24 }),
    message: generateYup({ key: "message", optionalFields, max: 500 }),
    module: generateYup({ key: "module", optionalFields, max: 30 }),
    isInternalNote: Yup.boolean(),
  });
};

const CustomButton = styled(Button)(() => ({
  color: "#9EC8FF",
}));
