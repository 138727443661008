import { SvgIconProps } from "@mui/material";

const RearrangeIcon = ({
  width = 8,
  height = 10,
  fill = "#B2B2B1",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 1.55228 0.447715 2 1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1ZM6 1C6 1.55228 6.44772 2 7 2C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1ZM7 6C6.44772 6 6 5.55228 6 5C6 4.44772 6.44772 4 7 4C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6ZM0 5C0 5.55228 0.447715 6 1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5ZM7 10C6.44772 10 6 9.55228 6 9C6 8.44771 6.44772 8 7 8C7.55228 8 8 8.44771 8 9C8 9.55229 7.55228 10 7 10ZM0 9C0 9.55228 0.447715 10 1 10C1.55228 10 2 9.55229 2 9C2 8.44771 1.55228 8 1 8C0.447715 8 0 8.44771 0 9Z"
        fill={fill}
      />
    </svg>
  );
};

export default RearrangeIcon;
