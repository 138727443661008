import { TPatchPayload } from "@services/api/accessControl/accessControl";
import { IAssignmentFilter, TPermissionsData } from "../types";

type TReduceDataFn = (
  filter: IAssignmentFilter,
  data?: TPermissionsData,
) => TPermissionsData;

export const dataReducerWithFilter: TReduceDataFn = (filter, data) => {
  if (!data) return {};

  return Object.entries(data).reduce((acc, [key, values]) => {
    if (
      (filter.assigned === 1 && values.isDeny) ||
      (filter.assigned === 0 && !values.isDeny)
    ) {
      return acc;
    }

    return {
      ...acc,
      [key]: values,
    };
  }, {});
};

type Assignment = "allow" | "detach";
type Effect = "allow" | "deny";

type PayloadType = {
  assignment: Assignment[];
  effect: Effect[];
};

const actionValues: PayloadType = {
  assignment: ["allow", "detach"],
  effect: ["allow", "deny"],
};

type TCreatePayload = (
  data: TPermissionsData,
  payloadType: keyof PayloadType,
) => TPatchPayload;

export const createPayload: TCreatePayload = (data, payloadType) => {
  const payload = {} as TPatchPayload;
  const actions = actionValues[payloadType];

  Object.values(data).forEach((value) => {
    const attribute =
      payloadType === "assignment" ? !value.isAttached : value.isDeny;
    const action = attribute ? actions[1] : actions[0];
    const id = value.id;
    const prevValues = payload[action] || [];

    payload[action] = [...prevValues, id];
  });

  return payload;
};
