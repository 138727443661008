import React from "react";
import { palette } from "@palette";
// mui
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

type ExpandedRowContainerProps = {
  children: React.ReactNode;
  pinkBorder?: boolean;
};

export const ExpandedRowContainer: React.FC<ExpandedRowContainerProps> = ({
  children,
  pinkBorder,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={
        !pinkBorder ? classes.expandedContainer : classes.pinkExpandedContainer
      }
    >
      <Box className={!pinkBorder ? classes.main : classes.pinkBorderMain}>
        {children}
      </Box>
      {!pinkBorder && (
        <Box
          sx={{ height: "24px", borderRadius: "0 0 8px 8px", border: "none" }}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  expandedContainer: {
    display: "block",
    position: "relative",
    backgroundColor: palette.neutral[5],
    borderRadius: "0 0 8px 8px",
    zIndex: "0",
  },
  pinkExpandedContainer: {
    display: "block",
    borderRadius: "inherit",
    position: "relative",
    backgroundColor: "#rgba(0, 0, 0, 0.87);",
    zIndex: "0",
    "&::after": {
      content: "''",
      pointerEvents: "none",
      display: "block",
      height: "100%",
      position: "absolute",
      borderRadius: "inherit",
      top: "0",
      zIndex: 1,
      width: "100%",
    },
  },
  main: {
    margin: "0 16px 0",
    background: "inherit",
    position: "relative",
  },
  pinkBorderMain: {
    margin: "0px 0px 0px 0px",
    backgroundColor: "#fff",
    position: "relative",
    boxShadow: "none",
    borderRadius: "0px 0px 8px 8px",
    border: "none",
    borderTop: "none",
  },
  gradientDrop: {
    pointerEvents: "none",
    content: "''",
    display: "block",
    height: "calc( 100% - 24px + 6px )",
    borderRadius: "0 0 8px 8px",
    background:
      "linear-gradient(104.33deg, rgba(129, 68, 154, 0) 25.61%, #81449A 54.5%, #E6B96F 82.41%)",
    backdropFilter: "blur(18px)",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
    position: "absolute",
    top: "0",
    left: "36px",
    zIndex: "-1",
    width: "calc( 100% - 32px - 40px)",
  },
});
