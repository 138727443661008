import { palette } from "@palette";
import { IParsedData } from "../data.types";
import { BusinessProfileStatusType } from "@common/Tag/BusinessProfileTag";
import { isEmpty } from "lodash";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

type StatusObject = { text: string; color: string; backgroundColor: string };
type StatusType = Extract<
  BusinessProfileStatusType,
  "declined" | "suspended" | "pending" | "approved"
>;
export type ActionType =
  | Extract<BusinessProfileStatusType, "declined" | "suspended" | "approved">
  | "move_back_to_pending";

export const useBusinessProfileActions = (data: IParsedData) => {
  const status = data?.businessProfile.statusName;
  const {
    statusName,
    declinedAt,
    suspendedAt,
    declinedByName,
    suspendedByName,
    approvedAt,
    approvedByName,
    movedPendingAt,
    movedPendingByName,
    reason,
  } = data.businessProfile;
  const { merchant_underwriting } = useEnterprisePermissions();
  //in mobile view show tooltip with message instead of info message for approved and pending status
  const isPending =
    status === "pending" ||
    status === "pending_review" ||
    status === "pending_review_issue";
  const isApproved = status === "approved";
  const isDeclined = status === "declined";
  const isSuspended = status === "suspended";
  const isReadyForVerification = status === "ready_for_verification";
  const showToolTip =
    (isApproved && !isEmpty(approvedAt) && !isEmpty(approvedByName)) ||
    (status === "pending" && isEmpty(movedPendingAt));

  const getMessageMetaData = () => {
    if (
      statusName === "pending_review" ||
      statusName === "ready_for_verification" ||
      statusName === "move_back_to_pending" ||
      statusName === "declined_by_msp" ||
      statusName === "pending_review_issue"
    )
      return;
    const sanitizedReason = reason ? `, reason: "${reason}"` : "";
    const BusinessProfileMessage: Record<StatusType, StatusObject> = {
      pending: {
        text:
          movedPendingAt && movedPendingByName
            ? `Moved to pending on ${movedPendingAt} by ${movedPendingByName}${sanitizedReason}`
            : "Merchants need to provide all the information to be ready for verification",
        color: palette.warning["text"],
        backgroundColor: palette.tag.warning["bg"],
      },
      approved: {
        text:
          approvedAt && approvedByName
            ? `Approved on ${approvedAt} by ${approvedByName}`
            : "",
        color: palette.warning["text"],
        backgroundColor: palette.tag.warning["bg"],
      },
      declined: {
        text:
          declinedAt && declinedByName
            ? `Declined on ${declinedAt} by ${declinedByName}${sanitizedReason}`
            : "",
        color: palette.tag.error["text"],
        backgroundColor: palette.tag.error["bg"],
      },
      suspended: {
        text:
          suspendedAt && suspendedByName
            ? `Suspended on ${suspendedAt} by ${suspendedByName}${sanitizedReason}`
            : "",
        color: palette.warning["text"],
        backgroundColor: palette.tag.warning["bg"],
      },
    };
    return BusinessProfileMessage[statusName];
  };
  const message = getMessageMetaData();

  const isHidden = ({ actionItem }: { actionItem: ActionType }) => {
    const checkIsHidden: Record<ActionType, boolean> = {
      move_back_to_pending: isPending || isApproved || isSuspended,
      approved: isApproved || isDeclined,
      declined: isDeclined || isApproved || isSuspended,
      suspended:
        isPending || isDeclined || isSuspended || isReadyForVerification,
    };
    return checkIsHidden[actionItem] || !merchant_underwriting;
  };
  return { message, showToolTip, isHidden, isPending };
};
