import sha1 from "sha1";
import { IUpdatedData, PermisionHashMap } from "./api/types";

type IFilterAssigned = -1 | 0 | 1 | 2;
type IFilter = {
  assigned: IFilterAssigned;
  custom: 0 | 1;
};

/*
Copy the assigned value from second PermisionHashMap into first PermisionHashMap
*/
export const copyData = (
  obj1: PermisionHashMap,
  obj2: PermisionHashMap,
  fieldKey: string,
) => {
  return Object.entries(obj1).reduce((acc, v) => {
    const [permissionKey, permissionValue] = v;

    return {
      ...acc,
      [permissionKey]: {
        ...permissionValue,
        [fieldKey]: !obj2?.[permissionKey]?.isDeny ?? !permissionValue.isDeny,
      },
    };
  }, {});
};

export const mergeData = (
  obj1: PermisionHashMap,
  obj2: { [key: string]: boolean },
) => {
  return Object.entries(obj1).reduce((acc, v) => {
    const [permissionKey, permissionValue] = v;

    return {
      ...acc,
      [permissionKey]: {
        ...permissionValue,
        assigned: obj2[permissionKey] ?? !permissionValue.isDeny,
      },
    };
  }, {});
};

/*
Update the field passed as input with the value laso from input into a PermisionHashMap
*/
export function updatePermissionObject(
  obj: PermisionHashMap,
  field: [key: string, value: any],
) {
  const [key, value] = field;

  return Object.entries(obj).reduce((acc, v) => {
    const [permissionKey, permissionValue] = v;

    return {
      ...acc,
      [permissionKey]: {
        ...permissionValue,
        [key]: value,
      },
    };
  }, {});
}

const defaultHashFn = (v: IUpdatedData): string =>
  `${v.id}-${v.permissionName.replace(/ /g, "")}`;

export const hashData = (
  data: IUpdatedData[],
  hashFn = defaultHashFn,
): PermisionHashMap => {
  return data.reduce((acc, v) => {
    const compoundKey = hashFn(v);
    return {
      ...acc,
      [sha1(compoundKey)]: v,
    };
  }, {} as PermisionHashMap);
};

export const groupData = (hashes: PermisionHashMap) => {
  if (!hashes) return {};

  const uniqueGroups = Array.from(
    new Set(Object.values(hashes).map((x) => x.group)),
  );
  const groupedData: { [k: string]: string[] } = uniqueGroups.reduce(
    (acc, v) => ({ ...acc, [v]: [] }),
    {},
  );

  Object.entries(hashes).forEach(([k, v]) => {
    const g = v.group;

    groupedData[g].push(k);
  });

  return groupedData;
};

export const filterViewedItems = (item: IUpdatedData, filter: IFilter) => {
  if (filter.assigned === -1 && filter.custom === 0) {
    return true;
  }
  if (filter.assigned !== -1 && filter.custom === 1) {
    return (
      !item.isDeny === Boolean(filter.assigned) &&
      (item as any)?.custom === Boolean(filter.custom)
    );
  }
  if (filter.assigned !== -1) {
    return !item.isDeny === Boolean(filter.assigned);
  }
  if (filter.custom === 1) {
    return (item as any)?.custom === Boolean(filter.custom);
  }
};
