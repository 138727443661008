import { TAdditionalDetails } from "../data.types";

export const additionalDetailsParser = (data: any): TAdditionalDetails => [
  {
    label: "Country",
    value: data?.ipInfo?.country || "",
  },
  {
    label: "Country Code",
    value: data?.ipInfo?.country_code || "",
  },
  {
    label: "Continent Code",
    value: data?.ipInfo?.continent_code || "",
  },
  {
    label: "Currency Code",
    value: data?.ipInfo?.currency?.code || "",
  },
  {
    label: "City",
    value: data?.ipInfo?.city || "",
  },
  {
    label: "State (Region)",
    value: data?.ipInfo?.region || "",
  },
  {
    label: "Region Code",
    value: data?.ipInfo?.region_code || "",
  },
  {
    label: "Country Code",
    value: data?.ipInfo?.country_code || "",
  },
  {
    label: "Provider Type",
    value: data?.ipInfo?.connection?.org || "",
  },
  {
    label: "Provider Domain",
    value: data?.ipInfo?.connection?.org || "",
  },
  {
    label: "is Hosting",
    value: data?.ipInfo?.security?.hosting ? "Yes" : "No",
  },
  {
    label: "is ISP",
    value: data?.ipInfo?.connection?.isp ? "Yes" : "No",
  },
  {
    label: "is VPN",
    value: data?.ipInfo?.security?.vpn ? "Yes" : "No",
  },
];
