import { Text, TruncateText } from "@common/Text";
import { Stack, TableBody } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import ImagesStacker from "features/Minibuilders/Conversations/utils/ImagesStacker";
import TotalUnread from "./TotalUnread";
import { TConversationTopic } from "features/Minibuilders/Conversations/hooks/useListTopics";
import { ReactNode } from "react";
import { ChatsCircle, TelegramLogo } from "@phosphor-icons/react";
import { getGlobalTopicLabel } from "features/Minibuilders/Conversations/hooks/useConversationsModal";

type Props = {
  isMobileView: boolean;
  handleClick: (item: any, index: number) => void;
  data: TConversationTopic[];
  renderOnCondition?: () => ReactNode;
  condition?: boolean;
  isInternal?: boolean;
  hasUnreadActivity?: boolean;
};

const ConversationsTableBody = ({
  isMobileView,
  data,
  handleClick,
  renderOnCondition,
  condition,
  isInternal,
  hasUnreadActivity,
}: Props) => {
  if (condition && renderOnCondition) return <>{renderOnCondition()}</>;
  const ICON = isInternal ? ChatsCircle : TelegramLogo;
  return (
    <TableBody>
      {data?.map((item, index) => (
        <TableRow
          key={item.id}
          onClick={(e) => handleClick(item, index)}
          sx={{
            cursor: "pointer",
            maxHeight: "48px",
            padding: "8px",
          }}
          data-testid={`conversation-${item.name}-row`}
        >
          <TableCell sx={{ padding: "8px" }}>
            <Stack gap={1.5} direction="row" alignItems="center">
              <ICON size={18} weight="fill" color="#8F8F8F" />
              <TruncateText
                fontSize="14px"
                fontWeight="book"
                color="#403D3D"
                lineClamp={1}
              >
                {getGlobalTopicLabel(item.name) || item.displayName}
              </TruncateText>
              {item.totalUnread > 0 && (
                <TotalUnread
                  hasUnreadActivity={hasUnreadActivity}
                  item={item}
                />
              )}
            </Stack>
          </TableCell>
          <TableCell sx={{ padding: "8px" }}>
            <ImagesStacker
              data={item.userAvatars}
              imageWidth={isMobileView ? 24 : 20}
              condition={
                item?.userAvatars?.length === 4 && item?.totalUsers > 4
              }
              renderAdditional={
                item.totalUnread ? () => <TotalUnread item={item} /> : undefined
              }
            />
          </TableCell>
          {!isMobileView && (
            <>
              <TableCell
                sx={{ textAlign: "center", padding: "12px" }}
                data-testid={`conversation-${item.displayName}-total-messages`}
              >
                {item.totalMessages}
              </TableCell>
              <TableCell
                sx={{ padding: "8px", textAlign: "center", fontSize: "14px" }}
              >
                {item.lastActivity ? (
                  formatDistanceToNowStrict(fromUnixTime(item.lastActivity), {
                    addSuffix: true,
                  })
                ) : (
                  <Text
                    textAlign="center"
                    data-testid={`conversation-${item.displayName}-no-activity`}
                  >
                    -
                  </Text>
                )}
              </TableCell>
            </>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default ConversationsTableBody;
