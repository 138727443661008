import React, { useMemo } from "react";
import { SxProps } from "@mui/material";
import { palette } from "@palette";
import {
  IMerchantBankAccount,
  TMerchantBankAccountSettings,
} from "../data.types";
import { merchantBankAccountsParser } from "../helpers/parsers";
import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import NiceModal from "@ebay/nice-modal-react";
import {
  BANK_ACCOUNT_STATUS_APPROVAL_MODAL,
  BANK_ACCOUNT_STATUS_DECLINE_MODAL,
  DELETE_CONFIRMATION_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  MANAGE_BANK_ACCOUNTS_MODAL,
} from "modals/modal_names";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { showMessage } from "@common/Toast";
import { checkPortals } from "@utils/routing";

export const useMerchantBankAccounts = (
  bankAccounts: IMerchantBankAccount[],
  merchantId?: number,
  parentAccID?: number,
  bankAccountSettings?: TMerchantBankAccountSettings,
  onCloseModal?: (data?: IMerchantBankAccount, id?: number) => void,
  isEnterprise?: boolean,
) => {
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRowID, setSelectedRowID] = React.useState<number | null>(null);
  const { isAcquirerEnterprises } = checkPortals();
  const resource = composePermission(
    isAcquirerEnterprises ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
    RESOURCE_BASE.BANK_ACCOUNT,
  );

  const isUpdateAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.UPDATE,
  });

  const isDeleteAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.DELETE,
  });

  const isAddAllowed = useAccessControl({
    resource,
    operation: OPERATIONS.CREATE,
  });

  const { merchant_underwriting } = useEnterprisePermissions();

  const bankAccount = useMemo(
    () => bankAccounts?.find((b) => b.id === selectedRowID),
    [selectedRowID, bankAccounts],
  );

  const updateBankAccountHandler = async (
    id: number,
    status: "approved" | "declined" | "update_requested",
  ) => {
    try {
      const res = await customInstance({
        url: buildMerchantEndpoints(`bank-accounts/${id}/status`, merchantId),
        method: "PATCH",
        data: { status },
      });

      if (res?.id) {
        queryClient.refetchQueries("get-merchant-preview");
        queryClient.invalidateQueries("get-merchant-msp-status");
      }
    } catch (err: any) {
      showMessage("Error", err?.message);
    }
  };

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setSelectedRowID(id);
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRequestUpdate = () => {
    if (!selectedRowID) return;
    updateBankAccountHandler(selectedRowID, "update_requested");
  };

  const handleApprove = () => {
    if (!selectedRowID) return;
    NiceModal.show(BANK_ACCOUNT_STATUS_APPROVAL_MODAL, {
      approveHandler: () => updateBankAccountHandler(selectedRowID, "approved"),
      name: bankAccount?.name,
    });
  };

  const handleDecline = () => {
    if (!selectedRowID) return;
    NiceModal.show(BANK_ACCOUNT_STATUS_DECLINE_MODAL, {
      declineHandler: () => updateBankAccountHandler(selectedRowID, "declined"),
      name: bankAccount?.name,
    });
  };

  const handleEdit = () => {
    NiceModal.show(EDIT_BANK_ACCOUNT_MODAL, {
      merchantId,
      data: bankAccount,
      bankAccountId: selectedRowID,
      onClose: onCloseModal,
    });
  };

  const manageBankAccountsHandler = () => {
    NiceModal.show(MANAGE_BANK_ACCOUNTS_MODAL, {
      parentAccID,
      merchantId,
      data: bankAccountSettings,
    });
  };

  const handleDelete = () => {
    if (!selectedRowID) return;
    const url = buildMerchantEndpoints(
      `bank-accounts/${selectedRowID}`,
      merchantId,
    );
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "bank_account",
      itemName: bankAccount?.name,
      url: url,
    });
  };

  const handleAddBankAccount = () => {
    if (!isAddAllowed) return;

    NiceModal.show(EDIT_BANK_ACCOUNT_MODAL, {
      merchantId,
      onClose: onCloseModal,
      isDefault: bankAccounts.length === 0,
    });
  };

  const actions: {
    title: string;
    hidden: boolean;
    onClick: () => void;
    disabled?: boolean;
    sx?: SxProps;
    tooltipProps?: { show: boolean; message: string };
  }[] = [
    {
      title: "Request an update",
      hidden: !merchantId || !merchant_underwriting,
      disabled: bankAccount?.status === "upload" || !isUpdateAllowed,
      onClick: handleRequestUpdate,
      tooltipProps: {
        show: !isUpdateAllowed,
        message: EDIT_DENY_MESSAGE,
      },
    },
    {
      title: "Approve",
      hidden: !merchantId || !merchant_underwriting,
      disabled: bankAccount?.status === "approved" || !isUpdateAllowed,
      onClick: handleApprove,
      tooltipProps: {
        show: !isUpdateAllowed,
        message: EDIT_DENY_MESSAGE,
      },
    },
    {
      title: "Decline",
      hidden: !merchantId || !merchant_underwriting,
      disabled:
        bankAccount?.status === "approved" ||
        bankAccount?.status === "declined" ||
        !isUpdateAllowed,
      onClick: handleDecline,
      tooltipProps: {
        show: !isUpdateAllowed,
        message: EDIT_DENY_MESSAGE,
      },
    },
    {
      title: "Edit",
      hidden: false,
      disabled: !isUpdateAllowed,
      onClick: handleEdit,
      tooltipProps: {
        show: !isUpdateAllowed,
        message: EDIT_DENY_MESSAGE,
      },
    },
    {
      title: "Delete",
      hidden: false,
      disabled: !isDeleteAllowed,
      onClick: handleDelete,
      sx: { color: palette.error.main },
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  return {
    anchorEl,
    onOpenMenu,
    onCloseMenu,
    actions,
    manageBankAccountsHandler,
    handleAddBankAccount,
    isAddBankAccountAllowed: isAddAllowed,
  };
};

export const useListParentBankAccounts = (
  parentAccID: number,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const getMerchantBankAccounts = (id: number) => {
    return customInstance({
      url: `/merchants/${id}/bank-accounts`,
      method: "GET",
    });
  };

  const { data, isLoading } = useQuery(
    ["get-parent-bank-accounts", parentAccID],
    async () => {
      const bankAccounts = await getMerchantBankAccounts(parentAccID);

      return {
        bankAccounts: bankAccounts.data || [],
      };
    },
    { refetchOnWindowFocus: false, ...options },
  );

  const bankAccountList = merchantBankAccountsParser(data?.bankAccounts, true);

  return { bankAccountList, isLoading };
};

export const useUpdateBankAccountSettings = (merchantId: number) => {
  const queryClient = useQueryClient();
  const { money_transfers, bank_account_linking, manage_bank_account } =
    useEnterprisePermissions();

  const updateMerchantMutation = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}`,
      method: "PATCH",
      data,
    });
  });

  const { isLoading } = updateMerchantMutation;

  const handleUpdateBankAccountSettings = (
    data: any,
    onSuccess?: () => void,
  ) => {
    const customData = {
      ...(manage_bank_account && {
        allowAddingBankAccounts: data.allowBankAccounts,
      }),
      ...(bank_account_linking && {
        linkedBankAccountID: data.linkedAccountID,
      }),
      ...(money_transfers && {
        submerchantAllowedToTransfer: data.allowTransfers,
      }),
    };

    updateMerchantMutation.mutate(customData, {
      onSuccess: (res: any) => {
        queryClient.refetchQueries("get-merchant-preview");
        if (onSuccess) onSuccess();
      },
      onError: (err: any) => {
        const message =
          err?.response?.data?.input[0].message ||
          err?.response?.data?.message ||
          err?.message;
        if (message) showMessage("Error", message);
      },
    });
  };

  return { handleUpdateBankAccountSettings, isLoading };
};
