const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const NODE_ENV = process.env.NODE_ENV;

const useCheckEnvironment = () => {
  const isStaging = ENVIRONMENT === "staging";
  const isDevelopment = ENVIRONMENT === "development";
  const isProduction = ENVIRONMENT === "production";
  const isLocal = NODE_ENV === "development";
  const isTestEnv = NODE_ENV === "test";

  const getEnvironmentDomain = () => {
    if (isTestEnv) {
      return "";
    } else if (isProduction) {
      return "";
    } else if (isLocal) {
      return `local.${ENVIRONMENT}`;
    } else {
      return ENVIRONMENT;
    }
  };
  const subdomain = isLocal ? "local." : "portal.";
  const domain = isProduction
    ? ""
    : isDevelopment
    ? "development."
    : "staging.";
  const port = isLocal ? ":3000" : "";
  const platformBaseUrl = `https://${subdomain}${domain}givepayments.com${port}`;

  return {
    isStaging,
    isDevelopment,
    isProduction,
    isLocal,
    getEnvironmentDomain,
    platformBaseUrl,
    domain
  };
};

export default useCheckEnvironment;
