import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { deleteDocument, downloadDocument, updateFile } from "./utils";
import useDocumentPreview from "./useDocumentPreview";
import { useCallback } from "react";
import { QueryKey, useQueryClient } from "react-query";
import { TDocument, TFileNavigation } from "@common/FilePreview/types";
import useFilePermissions from "./useFilePermissions";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const useDocumentHandlers = (
  merchantID: number,
  list?: TMerchantDocument[],
  isEnterprise = false,
  refetcher?: QueryKey,
) => {
  const { isUpdateAllowed, isDeleteAllowed } = useFilePermissions(isEnterprise);
  const queryClient = useQueryClient();

  const { isDesktopView } = useCustomTheme();

  const handleDownload = useCallback((file: TDocument | null) => {
    if (!file) return;
    downloadDocument({ fileName: file.name, fileURL: file.URL }, isDesktopView);
  }, []);

  const handleDelete = useCallback(
    (file: TDocument | null, navigation?: TFileNavigation) => {
      if (!file) return;

      const refetchQuery = () => {
        refetcher && queryClient.refetchQueries(refetcher);
        queryClient.refetchQueries("get-merchant-preview");
        queryClient.refetchQueries("get identification files");

        if (!navigation?.showNext && !navigation?.showPrev) {
          return navigation?.handleClose && navigation.handleClose();
        }

        if (navigation?.showNext) {
          navigation.changeFile("next", file);
        } else {
          navigation?.changeFile("prev", file);
        }
      };

      deleteDocument(merchantID, { fileName: file.name, id: file.id }, () =>
        refetchQuery(),
      );
    },
    [],
  );
  const { handlePreview } = useDocumentPreview({
    list,
    merchantID,
    handlers: { handleDownload, handleDelete },
    isEnterprise,
  });

  const deleteDocumentHandler = (
    selectedDocument?: TMerchantDocument,
    onEnd?: () => void,
  ) => {
    if (!selectedDocument) return;
    deleteDocument(
      merchantID,
      { fileName: selectedDocument?.fileName || "", id: selectedDocument.id },
      onEnd,
    );
  };

  const editDocumentHandler = async (
    merchantID: number,
    documentID: number,
    data: any,
  ) => {
    if (!documentID) return;
    updateFile(merchantID, documentID, data);
  };

  const downloadSingleDocsHandler = async (
    documentData?: TMerchantDocument,
  ) => {
    if (!documentData) return;
    downloadDocument(
      {
        fileName: documentData?.fileName || "undefined",
        fileURL: documentData.fileURL,
      },
      isDesktopView,
    );
  };

  return {
    previewHandler: handlePreview,
    downloadHandler: downloadSingleDocsHandler,
    deleteHandler: deleteDocumentHandler,
    editHandler: editDocumentHandler,
    isDeleteAllowed,
    isUpdateAllowed,
  };
};

export default useDocumentHandlers;
