import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Grid } from "@mui/material";
import React from "react";

type NodeItem = {
  node: React.ReactNode;
  sm?: number;
  md?: number;
  hidden?: boolean;
  delay?: (index: number) => number;
  hideTooltip?: boolean;
};

export const gridItemsRenderer = (
  items: NodeItem[],
  tooltipProps?: { show: boolean; message: string },
) => {
  return items.map(
    ({ node, hidden, sm, md, delay, hideTooltip = false }, index) => (
      <HiddenComponent key={index} hidden={hidden || false}>
        <Grid item xs={12} sm={sm || 12} md={md || sm || 12}>
          <WithTooltipWrapper
            hasTooltip={!!tooltipProps && !hideTooltip}
            tooltipProps={tooltipProps}
          >
            <FadeUpWrapper delay={delay ? delay(index) : defaultDelay(index)}>
              {node}
            </FadeUpWrapper>
          </WithTooltipWrapper>
        </Grid>
      </HiddenComponent>
    ),
  );
};

const defaultDelay = (index: number) => 50 + index * 50;
