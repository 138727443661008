import React from "react";
import { Outlet } from "react-router-dom";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";


const SuspenseLayout = () => {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <Outlet />
    </React.Suspense>
  )
}

export default SuspenseLayout;
