import { Text } from "@common/Text";
import { BoxProps } from "@mui/material";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { OFACCheckType } from "../hooks/useOFAC";
import { useCallback, useState } from "react";
import PreviousCheckItem from "./PreviousCheckItem";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { OFACTabType } from "../helpers";

type Props = {
  checks: OFACCheckType[];
  listHeight: string;
  activeTab: OFACTabType;
};

const PreviousChecks = ({
  checks,
  listHeight = "calc(100vh - 480px)",
  activeTab,
}: Props) => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = useCallback((e: any) => {
    const { scrollTop } = e.currentTarget;
    setShadow(scrollTop > 0);
  }, []);

  const isEmpty = checks.length < 1;

  return (
    <>
      <ChecksTitleContainer shadow={shadow}>
        <Text color={palette.neutral[80]} fontWeight="book" fontSize="18px">
          Previous checks
        </Text>
      </ChecksTitleContainer>
      <Box
        sx={{
          overflowY: isEmpty ? "hidden" : "auto",
          overflowX: "hidden",
          position: "relative",
        }}
        onScroll={handleScroll}
      >
        {isEmpty ? (
          <FadeUpWrapper delay={250}>
            <Stack height={listHeight} justifyContent="center">
              <Text
                color={palette.neutral[80]}
                fontSize="14px"
                textAlign="center"
              >
                No data to show
              </Text>
            </Stack>
          </FadeUpWrapper>
        ) : (
          <FadeUpWrapper delay={250}>
            <Stack spacing={0.5} data-testid="Scroll-container">
              {checks?.map((check) => (
                <PreviousCheckItem
                  key={check.ID}
                  check={check}
                  isOwner={activeTab === "Business Owner"}
                />
              ))}
            </Stack>
          </FadeUpWrapper>
        )}
      </Box>
    </>
  );
};

const ChecksTitleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "shadow",
})<BoxProps & { shadow: boolean }>(({ shadow }) => ({
  padding: "16px 0px",
  boxShadow: shadow ? "0px 3px 15px 0px rgba(2, 2, 2, 0.15)" : "none",
  clipPath: "inset(0px 0px -15px 0px)",
  transition: "box-shadow 150ms ease-in",
  background: "rgba(251, 251, 251, 0.90)",
  borderTop: `1px solid ${palette.neutral[20]}`,
  marginTop: "24px",
}));

export default PreviousChecks;
