import { namespaces } from "../../../resources/i18n.constants";

export const manage_money = {
  [namespaces.pages.manageMoney]: {
    manageMoney: "Manage Money",
    bankAccounts: "Bank Accounts",
    availableBalance: "Available Balance",
    totalBalance: "Total balance",
    LifetimeRaised: "Lifetime Raised",
    ApprovedTransactions: "Approved Transactions",
    refundedTransactions: "Refunded Transactions",
    chargebackTransactions: "Chargeback Transactions",
    transferMoney: "Transfer Money",
    BankAccount: "Bank Account",
    transactions: "Transactions",
    DownloadReport: "Download Report",
    TransferMoney: "Transfer Money",
    AvailableBalance: "Available Balance",
    download_Transactions_report_msg:
      "You are about to download the transactions report",
    download_Transactions_report_msg2:
      " If you have a problem downloading your file or can't find it in the folder",
    blockingDownload_msg:
      "Your browser may be blocking the download. Click a link below to see how to allow downloads from a specific browser.",
    try_different_browser_msg:
      "If you continue to have issues, please try a different browser. If you can't download in any browser please email support@givebox.com for further help.",
    showing: "Showing",
    records_of: "records of",
    clickHere: "click here",
    VerifyYourIdentity: "Verify Your Identity",
    PleaseVerifyYourIdentityToTransferMoney:
      "Please Verify Your Identity To Transfer Money",
    YourGivebox_account_is_protected_with_two_factor_authentication:
      `Your GiveCorp account is protected with two-factor authentication.\nA verification code will be sent to your email address.\nYou will use this code to verify your identity on the next screen.`,
    PleaseChoose_msg:
      " Please choose whether to receive verification code by email or text message. You will use this code to verify your identity on the next screen.",
    SendCodeViaEmailTo: "Send code via email to",
    SendCodeViaTextMessageTo: "Send Code via Text Message t",
    I_already_have_a_code: "I already have a code",
    SendVerificationCode: "Send verification code",
    PleaseEnterVerificationCode: "Please enter verification code",
    IdentityVerification: "Identity verification",
    Verification_code_was_sent_to: "Verification code was sent to:",
    CheckYourPhone:
      "  Please check your phone for a text message with the Givebox verification code. Enter the verification code from the text message into the input field below.",
    searchRequestMessage:
      `Please search your email for the subject "Givebox Verification Code". Copy and paste the verification code from the email and enter into the input field below.`,
    VerificationCode_is_valid_for_up_to_60_minutes:
      "Verification code is valid for up to 60 minutes.",
    Request_a_new_verification_code: "Request a new verification code",
  },
};
