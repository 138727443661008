import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { isDefined } from "@utils/helpers";
import { checkPortals } from "@utils/routing";
import Cookies from "js-cookie";
import { useQuery } from "react-query";

interface DisputeStats {
  chargebackVolume: number;
  chargebackVolumeRatio: number;
  chargebackCount: number;
  chargebackCountRatio: number;
  unsettledCount: number;
  friendlyCount: number;
  friendlyRate: number;
  fraudCount: number;
  fraudRate: number;
  winCount: number;
  winRate: number;
  disputeCount: number;
  openCount: number;
}

export const useGetDisputesStats = () => {
  const user = Cookies.get("user");
  const parsedUser = user && JSON.parse(user);
  const { merchantId } = useGetCurrentMerchantId();
  const { isAcquirerPortal } = checkPortals();
  const { data, isLoading } = useQuery<DisputeStats>({
    queryKey: "get-disputes-stats",
    queryFn: async () => {
      return await customInstance({
        url: `/disputes/stats`,
        method: "GET",
      });
    },
    enabled:
      isDefined(merchantId) &&
      isAcquirerPortal &&
      parsedUser?.role === "acquirer",
  });

  return {
    data,
    isLoading,
  };
};
