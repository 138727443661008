import { palette } from "@palette";

const KotoChevronDown = ({
  width = 11,
  height = 7,
  fill=palette.neutral.black
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 7"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.11426 1L5.64054 5.74182L6.82592 4.5M10.1668 1L8.49637 2.75"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KotoChevronDown;
