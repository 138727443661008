import { showMessage } from "@common/Toast";
import { useModal } from "@ebay/nice-modal-react";
import { useAppDispatch } from "@redux/hooks";
import { logoutAction } from "@redux/slices/auth/auth";
import { resetConfigurationToDefault } from "@redux/slices/enterprise/enterpriseSettings";
import { customInstance } from "@services/api";
import Cookies from "js-cookie";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export const useHandleLogout = () => {
  const { mutateAsync, ...rest } = useLogout();
  const { handleReset } = useResetApp();
  const modal = useModal();

  const handleSubmit = async () => {
    try {
      await mutateAsync();
      modal.hide();
      handleReset();
    } catch (error) {
      showMessage("Error", "Error when trying to log out. Try again Later");
    }
  };

  return {
    submitLogout: handleSubmit,
    ...rest,
  };
};

export const useResetApp = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const clearLocalData = () => {
    localStorage.removeItem("selected-account");
    localStorage.removeItem("masquerade-mode");
    Cookies.remove("user");
    Cookies.remove("transfer-verification");
  };

  const handleReset = () => {
    clearLocalData();
    dispatch(logoutAction());
    dispatch(resetConfigurationToDefault());
    queryClient.removeQueries();
    navigate("/login");
  };

  return {
    handleReset,
    clearLocalData: () => {
      clearLocalData();
      dispatch(resetConfigurationToDefault());
    },
  };
};

const _logout = () => {
  return customInstance({
    url: `/signout`,
    method: "POST",
  });
};

export const useLogout = () => {
  return useMutation(_logout);
};
