import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import NiceModal from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useGenerateApiKey } from "../hooks/useGenerateApiKey";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import AllowedOriginsInput from "../components/AllowedOriginsInput";
import ApiKeyEnvironmentInput from "../components/ApiKeyEnvironmentInput";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const GenerateApiKeyModal = NiceModal.create(() => {
  const { open, handleCloseModal, methods, onSubmit, isLoading } =
    useGenerateApiKey();
  const { isMobileView } = useCustomTheme();
  const { formState: { isValid, },watch,  } = methods;
  const originInput = watch("origin")

  const inputList = [
    {
      input: (
        <RHFInput
          name="name"
          label="API Key Name"
          placeholder="API Key Name"
          data-testid="api-key-name-input"
          helperText="A unique name for your API key to help you identify its purpose"
          fullWidth
          />
        ),
      },
      {
        input: (
          <RHFInput
          name="description"
          label="Description (Optional)"
          placeholder="Description (Optional)"
          multiline
          data-testid="api-key-description-input"
          rows={8}
          fullWidth
          inputProps={{
            maxLength: `${MAX_TEXT_LENGTH}`,
          }}
          />
        ),
      },
      {
        input: <AllowedOriginsInput />,
      },
      {
        input: <ApiKeyEnvironmentInput />,
      },
    ];
    
    
  return (
    <EditMerchantBaseModal
      title="Generate API Key"
      description="Generate a new API key by providing the necessary information below"
      open={open}
      handleCancel={handleCloseModal}
      mobileDrawerSx={{
        ...(isMobileView && {
          "& .MuiDialogTitle-root": {
            padding: "0 0 16px 0",
          },
          "& .MuiDialogContent-root": {
            padding: 0,
          },
          "& .MuiDialogActions-root": {
            flexDirection: "column-reverse",
            gap: "8px",
          },
        }),
      }}
      actions={
        <>
          <Button
            size="large"
            background="tertiary"
            sx={{ padding: "12px 24px", fontSize: 18 }}
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            {isMobileView ? "Close" : "Cancel"}
          </Button>
          <Button
            size="large"
            background="primary"
            type="submit"
            form="generate-api-key-form"
            sx={{ padding: "12px 24px", fontSize: 18 }}
            disabled={isLoading || !isValid || !!originInput}
          >
            Generate
          </Button>
        </>
      }
      sx={modalStyle}
    >
      <FormProvider {...methods}>
        <Stack
          spacing={2}
          component="form"
          id="generate-api-key-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {inputList.map(({ input }, index) => (
            <FadeUpWrapper delay={70 * (index + 1)} key={index}>
              {input}
            </FadeUpWrapper>
          ))}
        </Stack>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

const modalStyle = {
  "& .MuiDialogContent-root": {
    padding: "8px 16px",
  },
  "& .MuiDialogTitle-root + .MuiDialogContent-root": {
    paddingTop: "8px !important",
  },
  "& .MuiPaper-root": {
    width: "576px !important",
    maxWidth: "576px !important",
  },
};

export default GenerateApiKeyModal;
