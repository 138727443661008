import { useMemo } from "react";
import { useDropdownMenu } from "@common/Menu/hooks/useDropdownMenu";
import ProfileDetails from "./components/ProfileDetails";
import ProfilePicture from "@components/CustomBreadcrumbsPage/ components/ProfilePicture";
import ProfileLogout from "./components/ProfileLogout";
import { DarkModeIcon, NewEditIcon } from "@assets/icons";
import { palette } from "@palette";
import { MenuItem } from "@common/Menu/NewDropdownMenu";
import { Stack } from "@mui/material";
import { Switch_V2 } from "@common/Switch";
import NiceModal from "@ebay/nice-modal-react";
import { PROFILE_MODAL } from "modals/modal_names";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

function ProfileMenu() {
  const { DropdownMenu, openMenu } = useDropdownMenu();
  const { isMobileView } = useCustomTheme();

  const openProfileModal = () => {
    NiceModal.show(PROFILE_MODAL);
  };

  const handleOpenProfileMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (isMobileView) {
      openProfileModal();
      return;
    }
    openMenu(e);
  };

  const actions = useMemo<MenuItem[]>(
    () => [
      {
        label: "Edit Profile",
        icon: <NewEditIcon />,
        onSelect: openProfileModal,
      },
      // {
      //   label: "Dark Mode",
      //   icon: <DarkModeIcon />,
      //   onSelect: (e: any) => null,
      //   closeOnSelect: false,
      //   customRender: (element) => (
      //     <Stack
      //       direction="row"
      //       alignItems="center"
      //       justifyContent="space-between"
      //       width="100%"
      //     >
      //       <Stack
      //         direction="row"
      //         alignItems="flex-end"
      //         justifyContent="flex-start"
      //         gap={1}
      //       >
      //         {element}
      //       </Stack>
      //       <Switch_V2 size="small" containerProps={{ gap: "0" }} />
      //     </Stack>
      //   ),
      // },
    ],
    [],
  );
  return (
    <>
      <ProfilePicture onClick={handleOpenProfileMenu} />
      <DropdownMenu
        items={actions}
        header={<ProfileDetails />}
        footer={<ProfileLogout />}
        isSwipeableDrawerMobileView={false}
        sxSelectItem={selectItemStyle}
        customWidth="220px"
        padding="16px 12px 8px 16px"
        MenuOptions={{
          sx: {
            "& .MuiButtonBase-root": {
              marginTop: '8px !important'
            }
          }
        }}
      />
    </>
  );
}

export default ProfileMenu;

const selectItemStyle = {
  justifyContent: "flex-start",
  alignItems: "flex-end",
  gap: "8px",
  padding: "8px 8px",
  borderRadius: "8px",
  "&:hover": { backgroundColor: palette.neutral[10] },
};
