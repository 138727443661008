import SwipeableDrawerMobile, {
  SwipeableDrawerMobileProps,
} from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { palette } from "@palette";
import { SidePanel, SidePanelProps } from "../atoms/SidePanel";
import { SlideProps, SxProps } from "@mui/material";
import React from "react";
import ErrorCatcher from "@common/Error/ErrorCatcher";

export type SidePanelVariantProps = {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  children: React.ReactNode;
  width?: number | string;
  showMobileView?: boolean;
  SlideProps?: Partial<SlideProps>;
  SidePanelProps?: Omit<
    SidePanelProps,
    "onCloseDrawer" | "onClose" | "children"
  >;
  DrawerProps?: Omit<SwipeableDrawerMobileProps, "onOpen" | "onClose"> & {
    dataTestId?: string;
  };
};

const DRAWER_DEFAULT_TOP = "96px";

const SidePanelVariant = ({
  open,
  onClose,
  sx,
  children,
  width,
  showMobileView = false,
  SidePanelProps,
  DrawerProps = {},
  SlideProps,
}: SidePanelVariantProps) => {
  const commonProps = {
    open,
    sx,
  };

  const { dataTestId, ...drawerProps } = DrawerProps;

  if (showMobileView) {
    return (
      <SwipeableDrawerMobile
        anchor="bottom"
        onOpen={() => undefined}
        {...drawerProps}
        {...commonProps}
        data-testid={dataTestId}
        onClose={() => !!onClose && onClose()}
        SlideProps={SlideProps}
        PaperProps={{
          ...drawerProps?.PaperProps,
          sx: {
            background: palette.neutral[5],
            position: "relative",
            maxHeight: `calc(100vh - ${
              typeof (sx as any)?.top === "number"
                ? (sx as any)?.top
                : DRAWER_DEFAULT_TOP
            })`,
            ...drawerProps?.PaperProps?.sx,
          },
        }}
        sx={{
          top: "auto",
          ...sx,
        }}
      >
        <ErrorCatcher errorID="swipeable-drawer">{children}</ErrorCatcher>
      </SwipeableDrawerMobile>
    );
  }

  return (
    <SidePanel
      width={width}
      onCloseDrawer={onClose}
      {...commonProps}
      {...SidePanelProps}
      SlideProps={SlideProps}
    >
      <ErrorCatcher errorID="sidepanel-base">{children}</ErrorCatcher>
    </SidePanel>
  );
};

export default SidePanelVariant;
