interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  stroke?: string;
}

const EnterpriseIcon = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
  gradient,
}: IconProps) => {
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 15.2466V18.7851C19 19.4601 18.2747 20 17.3682 20H14.1175V16.4421H9.89547V20H6.63182C5.72525 20 5 19.4601 5 18.7851V7.21488C5 6.53994 5.72525 6 6.63182 6H17.3682C18.2747 6 19 6.53994 19 7.21488V12.6818"
          stroke="url(#paint0_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 15V11"
          stroke="url(#paint1_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9V8"
          stroke="url(#paint2_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 15V11"
          stroke="url(#paint3_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 9V8"
          stroke="url(#paint4_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 8V12"
          stroke="url(#paint5_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 14V15"
          stroke="url(#paint6_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.22156 3H14.7665C15.4491 3 16 3.47586 16 4.06552V6H8V4.06552C8 3.47586 8.5509 3 9.23354 3H9.22156Z"
          stroke="url(#paint7_linear_651_89341)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_651_89341"
            x1="20.3382"
            y1="9.69811"
            x2="4.34172"
            y2="20.4008"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_651_89341"
            x1="10.0956"
            y1="12.0566"
            x2="8.48651"
            y2="12.3257"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_651_89341"
            x1="10.0956"
            y1="8.26415"
            x2="8.95298"
            y2="9.02863"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_651_89341"
            x1="16.0956"
            y1="12.0566"
            x2="14.4865"
            y2="12.3257"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_651_89341"
            x1="16.0956"
            y1="8.26415"
            x2="14.953"
            y2="9.02863"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_651_89341"
            x1="13.0956"
            y1="9.0566"
            x2="11.4865"
            y2="9.32575"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_651_89341"
            x1="13.0956"
            y1="14.2642"
            x2="11.953"
            y2="15.0286"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_651_89341"
            x1="16.7647"
            y1="3.79245"
            x2="13.6015"
            y2="9.43625"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 15.2466V18.7851C19 19.4601 18.2747 20 17.3682 20H14.1175V16.4421H9.89547V20H6.63182C5.72525 20 5 19.4601 5 18.7851V7.21488C5 6.53994 5.72525 6 6.63182 6H17.3682C18.2747 6 19 6.53994 19 7.21488V12.6818"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 15V11"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9V8"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15V11"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9V8"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8V12"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14V15"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22156 3H14.7665C15.4491 3 16 3.47586 16 4.06552V6H8V4.06552C8 3.47586 8.5509 3 9.23354 3H9.22156Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnterpriseIcon;
