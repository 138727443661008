import { QueryType } from "@redux/slices/fundraisers";
import {
  AND_OPERATOR,
  filteringConst,
  OR_OPERATOR,
  resources,
} from "./filtering.constants";
import { replaceSemicolonWithURLEncoded } from "./filtering.string";
import { InternalObject, KeyAttribute, ResourcesType } from "./filtering.types";
import { MerchantsQueryType } from "@redux/types/enterprise/merchants";
import { CustomersQueryType } from "@redux/types/customers";

function appendKey(
  obj: InternalObject,
  keyValueMap: KeyAttribute,
  operator: typeof AND_OPERATOR | typeof OR_OPERATOR,
) {
  const attrs = Object.entries(obj).reduce((acc, [key, value]) => {
    let nn = "";
    if (["typeID", "statusID"].includes(keyValueMap)) {
      return acc;
    }
    if (["key", "operator"].includes(key)) {
      nn = value;
    } else {
      nn = value
        .split(operator)
        .map((x: string) => `${keyValueMap}:${x}`)
        .join(operator);
    }
    return {
      ...acc,
      [key]: nn,
    };
  }, {});

  return attrs;
}

export const withAppendedKey = Object.entries(filteringConst).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]: appendKey(value, value.key, (value as any).operator),
    };
  },
  {} as typeof filteringConst,
);

export function replaceValues(originalString: string, ...values: any[]) {
  let replacedString = originalString;
  values.forEach((value, index) => {
    const placeholder = `{$${index + 1}}`;
    replacedString = replacedString.replace(placeholder, value);
  });
  return replacedString;
}

export function replaceArrayTypeValues(
  keyValueMap: KeyAttribute,
  ...values: any[]
) {
  return values.map((x) => `${keyValueMap}:${x}`).join(OR_OPERATOR);
}

const encodedQueryFilter = (queryFilters: any) => {
  const str = Object.entries(queryFilters)
    .filter((x) => x[1])
    .map(([, v]) => `${v}`)
    .join("%3B")
    .trim();

  return replaceSemicolonWithURLEncoded(`${str.trim()}`);
};

type QueryInputFiltersType =
  | QueryType
  | MerchantsQueryType
  | CustomersQueryType;

function filterByResource(
  inputObj: QueryInputFiltersType,
  resourceKey: keyof typeof resources,
) {
  const outputObj = {} as any;
  for (const key in inputObj) {
    if (resources[resourceKey].has(key)) {
      outputObj[key] = inputObj[key as keyof QueryInputFiltersType];
    }
  }
  return outputObj;
}

/*
    As our application currently stores all filter types within a single Redux object, 
    it's crucial to separate these filters based on their respective resources. 
    Not all filters should be applied to a single resource, so we've implemented a solution to split the filters according to the resources defined in filtering.constants.
*/
export const encodedQueryFilterMap = (
  queryFilters: QueryInputFiltersType,
): ResourcesType => {
  const resourcesKeys: any[] = Object.keys(resources);

  return resourcesKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: encodedQueryFilter(filterByResource(queryFilters, key)),
    };
  }, {});
};
