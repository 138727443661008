import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { customInstance } from "@services/api";

const getMerchantRiskProfile = (
  profileId: number,
  page: number,
  merchantId: number,
) => {
  return customInstance({
    url: `/merchants/${merchantId}/risk/merchant-profiles/${profileId}/escalations?page=${page}&max=${ROWS_PER_PAGE}&sort=id`,
    method: "GET",
  });
};

export default getMerchantRiskProfile;
