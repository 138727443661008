import { SvgIconProps } from "@mui/material";

const BankIcon = (props: SvgIconProps) => {
  const { width = "24", height = "24", fill = "#575353" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.2" d="M21.75 9H2.25L12 3L21.75 9Z" fill={fill} />
      <path
        d="M2.25 9.74953H4.5V15.7495H3C2.80109 15.7495 2.61032 15.8285 2.46967 15.9692C2.32902 16.1099 2.25 16.3006 2.25 16.4995C2.25 16.6984 2.32902 16.8892 2.46967 17.0299C2.61032 17.1705 2.80109 17.2495 3 17.2495H21C21.1989 17.2495 21.3897 17.1705 21.5303 17.0299C21.671 16.8892 21.75 16.6984 21.75 16.4995C21.75 16.3006 21.671 16.1099 21.5303 15.9692C21.3897 15.8285 21.1989 15.7495 21 15.7495H19.5V9.74953H21.75C21.9132 9.74937 22.0719 9.69599 22.202 9.59748C22.3321 9.49898 22.4265 9.36073 22.4709 9.20371C22.5153 9.04669 22.5073 8.87946 22.4481 8.72739C22.3889 8.57533 22.2817 8.44673 22.1428 8.3611L12.3928 2.3611C12.2747 2.28846 12.1387 2.25 12 2.25C11.8613 2.25 11.7253 2.28846 11.6072 2.3611L1.85719 8.3611C1.71828 8.44673 1.61108 8.57533 1.55187 8.72739C1.49266 8.87946 1.48466 9.04669 1.52908 9.20371C1.57351 9.36073 1.66793 9.49898 1.79803 9.59748C1.92814 9.69599 2.08681 9.74937 2.25 9.74953ZM6 9.74953H9V15.7495H6V9.74953ZM13.5 9.74953V15.7495H10.5V9.74953H13.5ZM18 15.7495H15V9.74953H18V15.7495ZM12 3.87985L19.1006 8.24953H4.89937L12 3.87985ZM23.25 19.4995C23.25 19.6984 23.171 19.8892 23.0303 20.0299C22.8897 20.1705 22.6989 20.2495 22.5 20.2495H1.5C1.30109 20.2495 1.11032 20.1705 0.96967 20.0299C0.829018 19.8892 0.75 19.6984 0.75 19.4995C0.75 19.3006 0.829018 19.1099 0.96967 18.9692C1.11032 18.8286 1.30109 18.7495 1.5 18.7495H22.5C22.6989 18.7495 22.8897 18.8286 23.0303 18.9692C23.171 19.1099 23.25 19.3006 23.25 19.4995Z"
        fill={fill}
      />
    </svg>
  );
};

export default BankIcon;
