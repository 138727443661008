import { rgbToHex } from "@mui/material";
import React from "react";

interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
}

const TrashIcon = ({
  width = 20,
  height = 20,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.78906 13.167L8.78906 10.667"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M12.1211 13.167L12.1211 10.667"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M2.95508 6.50049H17.9551V6.50049C17.4904 6.50049 17.2581 6.50049 17.0649 6.53892C16.2715 6.69673 15.6513 7.31693 15.4935 8.11031C15.4551 8.30351 15.4551 8.53583 15.4551 9.00049V13.3338C15.4551 15.2194 15.4551 16.1622 14.8693 16.748C14.2835 17.3338 13.3407 17.3338 11.4551 17.3338H9.45508C7.56946 17.3338 6.62665 17.3338 6.04086 16.748C5.45508 16.1622 5.45508 15.2194 5.45508 13.3338V9.00049C5.45508 8.53583 5.45508 8.30351 5.41665 8.11031C5.25884 7.31693 4.63864 6.69673 3.84526 6.53892C3.65206 6.50049 3.41973 6.50049 2.95508 6.50049V6.50049Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M8.84455 3.47582C8.93951 3.38722 9.14875 3.30893 9.43982 3.2531C9.7309 3.19726 10.0875 3.16699 10.4544 3.16699C10.8213 3.16699 11.178 3.19726 11.469 3.25309C11.7601 3.30893 11.9693 3.38722 12.0643 3.47582"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TrashIcon;
