import * as React from "react";

type FormInput = {
  [key: string]: any;
};

export type TransferMoneyDataType = {
  identityVerification: {
    method: "email" | "sms" | null;
    email: string;
    phone: string;
    subject: string;
    expiration: string;
    code: string;
  };
  transferMoney: {
    account: {
      id: number;
      name: string;
      bank?: string;
    };
    amount: number;
    fees: number;
  };
};

type Title = "identityVerification" | "transferMoney";

export type FormContextType = {
  formData: FormInput;
  setFormValues: (name: Title, values: FormInput) => void;
};

export const FormContext = React.createContext<FormContextType | null>(null);

const TransferMoneyFormProvider = ({
  children,
  data,
}: {
  data?: TransferMoneyDataType;
  children: React.ReactNode;
}) => {
  const [formData, setFormData] = React.useState<TransferMoneyDataType>({
    identityVerification: data
      ? data.identityVerification
      : {
          method: "email",
          email: "dragxxxxxxxxxxxxxxxxxxxxxxxmics.com",
          phone: "(xxx) xxx-9739",
          subject: "Givebox verification",
          expiration: "",
          code: "",
        },
    transferMoney: data
      ? data.transferMoney
      : {
          account: {
            id: 0,
            name: "",
            bank: "",
          },
          amount: 0,
          fees: 0
        },
  });

  const setFormValues = (name: Title, values: FormInput) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: {
        ...values,
      },
    }));
  };

  return (
    <FormContext.Provider value={{ formData, setFormValues }}>
      {children}
    </FormContext.Provider>
  );
};

export default TransferMoneyFormProvider;

export const useFormData = () =>
  React.useContext(FormContext) as FormContextType;
