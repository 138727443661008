import { getLegalEntityByID } from "@services/api/businessProfile";
import { useQuery } from "react-query";

const useFindBusinessProfileById = ({
  id,
  merchantId,
}: {
  id?: number;
  merchantId?: number;
}) => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["find-profile-by-id", id, merchantId],
    async () => {
      if (!id || !merchantId) return;
      const data = await getLegalEntityByID(id, merchantId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id && !!merchantId,
    },
  );

  return { data, isLoading, isError, refetch };
};

export default useFindBusinessProfileById;
