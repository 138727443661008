import * as React from "react";
import { useNavigate } from "react-router-dom";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { useMediaQuery, useTheme } from "@mui/material";
// components
import { showMessage } from "@common/Toast/ShowToast";
// assets
// localization
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import Popup from "@common/Popup";
import { TFundraiserModalInputs } from "../FundraisersMinibuilder/types";
import { TEventModalInputs } from "./types";

type EventSuccessModalProps = {
  data: TFundraiserModalInputs | TEventModalInputs;
};

const EventSuccessModal = NiceModal.create(
  ({ data }: EventSuccessModalProps) => {
    const modal = useModal();
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const handleClose = () => {
      modal.hide();
    };

    const openFundraisersHandler = () => {
      handleClose();
      navigate("/merchant/fundraisers/advanced-builder");
    };

    const handleDone = () => {
      modal.hide();
      showMessage(
        "Success",
        <>
          Your new Event{" "}
          <span style={{ fontWeight: "600" }}>“{data.about.title}”</span> has
          been successfully created and it’s ready to sell tickets.
        </>,
        isDesktop,
        "Event Created",
      );
    };

    const { t } = useTranslation(namespaces.pages.eventsMinibuilder);

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={t("event_successfully_created")}
        onSubmit={handleDone}
        actionLabel={t("done")}
        data-testid="event-success-modal"
      >
        <Markup content={t("event_create_success_description")} />
      </Popup>
    );
  },
);

export default EventSuccessModal;
