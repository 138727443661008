type Props = {
  width?: number;
  height?: number;
  stroke?: string;
  rotate?: number;
};

const ConversationsCaretLeft = ({
  width = 10,
  height = 18,
  stroke = "#B8B8B8",
  rotate = 0,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5306 15.9693C9.60029 16.039 9.65556 16.1217 9.69327 16.2128C9.73099 16.3038 9.7504 16.4014 9.7504 16.4999C9.7504 16.5985 9.73099 16.6961 9.69327 16.7871C9.65556 16.8781 9.60029 16.9609 9.5306 17.0306C9.46092 17.1002 9.3782 17.1555 9.28715 17.1932C9.19611 17.2309 9.09853 17.2503 8.99998 17.2503C8.90143 17.2503 8.80385 17.2309 8.71281 17.1932C8.62176 17.1555 8.53904 17.1002 8.46935 17.0306L0.969354 9.53055C0.899622 9.4609 0.844303 9.37818 0.80656 9.28713C0.768817 9.19608 0.74939 9.09849 0.74939 8.99993C0.74939 8.90137 0.768817 8.80377 0.80656 8.71272C0.844303 8.62168 0.899622 8.53896 0.969354 8.4693L8.46935 0.969304C8.61008 0.828573 8.80096 0.749512 8.99998 0.749512C9.199 0.749512 9.38987 0.828573 9.5306 0.969304C9.67134 1.11003 9.7504 1.30091 9.7504 1.49993C9.7504 1.69895 9.67134 1.88982 9.5306 2.03055L2.56029 8.99993L9.5306 15.9693Z"
        fill="#343330"
      />
    </svg>
  );
};

export default ConversationsCaretLeft;
