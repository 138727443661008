// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const LockIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.40036C10.07 2.40036 8.40002 3.82815 8.40002 5.71313V8.40036H6.93106C5.48547 8.40036 4.40002 9.66139 4.40002 11.1056V16.8951C4.40002 18.3393 5.48547 19.6004 6.93106 19.6004H17.069C18.5146 19.6004 19.6 18.3393 19.6 16.8951V15.8011C19.6 15.4697 19.3314 15.2011 19 15.2011C18.6687 15.2011 18.4 15.4697 18.4 15.8011V16.8951C18.4 17.7763 17.7564 18.4004 17.069 18.4004H6.93106C6.24369 18.4004 5.60002 17.7763 5.60002 16.8951V11.1056C5.60002 10.2244 6.24369 9.60036 6.93106 9.60036H8.99926C8.99951 9.60036 8.99977 9.60036 9.00002 9.60036C9.00028 9.60036 9.00053 9.60036 9.00079 9.60036H10.9835H14.9993C14.9995 9.60036 14.9998 9.60036 15 9.60036C15.0003 9.60036 15.0005 9.60036 15.0008 9.60036H17.069C17.7564 9.60036 18.4 10.2244 18.4 11.1056V11.8167C18.4 12.1481 18.6687 12.4167 19 12.4167C19.3314 12.4167 19.6 12.1481 19.6 11.8167V11.1056C19.6 9.66139 18.5146 8.40036 17.069 8.40036H15.6V5.71313C15.6 3.82815 13.93 2.40036 12 2.40036ZM14.4 8.40036V5.71313C14.4 4.60166 13.3837 3.60036 12 3.60036C10.6163 3.60036 9.60002 4.60166 9.60002 5.71313V8.40036H10.9835H14.4ZM12.6 13.2109C12.6 12.8795 12.3314 12.6109 12 12.6109C11.6687 12.6109 11.4 12.8795 11.4 13.2109V14.7898C11.4 15.1212 11.6687 15.3898 12 15.3898C12.3314 15.3898 12.6 15.1212 12.6 14.7898V13.2109Z"
          fill="url(#paint0_linear_304_1558)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_304_1558"
            x1="19.6296"
            y1="6.07135"
            x2="-2.36808"
            y2="18.8324"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.40036C10.07 2.40036 8.40002 3.82815 8.40002 5.71313V8.40036H6.93106C5.48547 8.40036 4.40002 9.66139 4.40002 11.1056V16.8951C4.40002 18.3393 5.48547 19.6004 6.93106 19.6004H17.069C18.5146 19.6004 19.6 18.3393 19.6 16.8951V15.8011C19.6 15.4697 19.3314 15.2011 19 15.2011C18.6687 15.2011 18.4 15.4697 18.4 15.8011V16.8951C18.4 17.7763 17.7564 18.4004 17.069 18.4004H6.93106C6.24369 18.4004 5.60002 17.7763 5.60002 16.8951V11.1056C5.60002 10.2244 6.24369 9.60036 6.93106 9.60036H8.99926C8.99951 9.60036 8.99977 9.60036 9.00002 9.60036C9.00028 9.60036 9.00053 9.60036 9.00079 9.60036H10.9835H14.9993C14.9995 9.60036 14.9998 9.60036 15 9.60036C15.0003 9.60036 15.0005 9.60036 15.0008 9.60036H17.069C17.7564 9.60036 18.4 10.2244 18.4 11.1056V11.8167C18.4 12.1481 18.6687 12.4167 19 12.4167C19.3314 12.4167 19.6 12.1481 19.6 11.8167V11.1056C19.6 9.66139 18.5146 8.40036 17.069 8.40036H15.6V5.71313C15.6 3.82815 13.93 2.40036 12 2.40036ZM14.4 8.40036V5.71313C14.4 4.60166 13.3837 3.60036 12 3.60036C10.6163 3.60036 9.60002 4.60166 9.60002 5.71313V8.40036H10.9835H14.4ZM12.6 13.2109C12.6 12.8795 12.3314 12.6109 12 12.6109C11.6687 12.6109 11.4 12.8795 11.4 13.2109V14.7898C11.4 15.1212 11.6687 15.3898 12 15.3898C12.3314 15.3898 12.6 15.1212 12.6 14.7898V13.2109Z"
        fill={fill}
      />
    </svg>
  );
};

export default LockIcon;
