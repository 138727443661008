import { RootState } from "@redux/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const last24Hours = moment(new Date()).subtract(24, "hours").unix();
const last30days = moment(new Date())
  .subtract(30, "days")
  .startOf("day")
  .unix();

type TTypeFilter = "all" | "escalations";
type TTimeFilter = "day" | "month";

interface TransactionRiskProfile {
  filters: {
    type: TTypeFilter;
    time: TTimeFilter;
  };
  queries: {
    type: string;
    time: string;
  };
}

const initialState: TransactionRiskProfile = {
  filters: {
    type: "all",
    time: "month",
  },
  queries: {
    type: "",
    time: `createdAt:>d${last30days}`,
  },
};

const transactionRiskProfile = createSlice({
  name: "transaction-risk-profile",
  initialState,
  reducers: {
    setTypeFilter: (
      state: TransactionRiskProfile,
      action: PayloadAction<TTypeFilter>,
    ) => {
      const type = action.payload;
      state.filters.type = type;
    },
    setTimeFilter: (
      state: TransactionRiskProfile,
      action: PayloadAction<TTimeFilter>,
    ) => {
      const time = action.payload;
      state.filters.time = time;
      state.queries.time = `createdAt:>d${
        time === "day" ? last24Hours : last30days
      }`;
    },
    reset: () => initialState,
  },
});

export const { setTypeFilter, setTimeFilter, reset } =
  transactionRiskProfile.actions;

export const selectTypeFilter = (state: RootState) =>
  state.transactionRiskProfile.filters.type;
export const selectTimeFilter = (state: RootState) =>
  state.transactionRiskProfile.filters.time;
export const selectTypeFilterQuery = (state: RootState) =>
  state.transactionRiskProfile.queries.type;
export const selectTimeFilterQuery = (state: RootState) =>
  state.transactionRiskProfile.queries.time;

export default transactionRiskProfile.reducer;
