import React from "react";
import CampaignCard from "@common/CampaignCard/CampaignCard";
import { SweepstakesData } from "@components/Sweepstakes/SweepstakesList/data";
import { formatDate } from "@utils/index";
import { palette } from "@palette";
import { parseAmount } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2/PurchasesGrid";
import { CommonProductProps } from "@common/Campaigns/campaigns.types";

const NewSweepstakesListItem = ({
  data,
  columns,
  openSidePanel,
  isSelected,
}: {
  data: SweepstakesData;
} & CommonProductProps) => {
  const others = [
    formatDate(data?.createdAt),
    parseAmount((data?.sumTransactions || 0) / 100),
    `${data.conversionRate}%`,
    parseAmount(data.averageTransactions / 100),
    data.totalTransactions,
    data.visitors,
  ];
  return (
    <CampaignCard
      columns={columns}
      imageURL={data.imageURL}
      name={data.name}
      id={data.id}
      others={others}
      sx={{
        borderTop: `1px solid ${palette.liftedWhite[300]}`,
      }}
      openSidePanel={openSidePanel}
      isSelected={isSelected}
    />
  );
};

export default NewSweepstakesListItem;
