import React, { useState, useEffect } from "react";

const useOverflow = (containerRef: React.RefObject<HTMLElement>) => {
  const [overflowed, setOverflowed] = useState(false);

  useEffect(() => {
    const updateOverflowState = () => {
      if (containerRef.current) {
        const hasOverflow =
          containerRef.current.scrollHeight > containerRef.current.clientHeight;
        setOverflowed(hasOverflow);
      }
    };
    updateOverflowState();

    const resizeObserver = new ResizeObserver(updateOverflowState);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return {
    overflowed,
  };
};

export default useOverflow;
