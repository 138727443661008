// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NameInput } from "@common/BusinessProfileInputs";
import { useTheme } from "@mui/material/styles";

import { BirthDatePicker } from "@common/DatePickers";
import { RHFInput, RHFTelInput } from "@common/Input";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
// hooks
import { useAccount } from "hooks/common/useAccount";

import { BEStatus } from "../components/NewEmailCard";
// redux
import { useAppSelector } from "@redux/hooks";
import { useGetUser } from "@services/api/onboarding/user";
import { selectUser } from "@redux/slices/auth/auth";

import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import MyAccountLoadingSkeleton from "@components/Settings/MyAccount/MyAccountLoadingSkeleton";
import NewEmailCard from "../components/NewEmailCard";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import AvatarUpload from "@components/AvatarUpload/AvatarUpload";
import avatarImage from "@assets/images/AvatarReb.png";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

export const ProfileAccountDetails = () => {
  const { isMobileView } = useCustomTheme();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation(namespaces.pages.settings);

  const { handleChangeStatus, removeImage } = useAccount();

  const { img, globalName } = useAppSelector(selectUser);

  const { data, isLoading } = useGetUser();

  const isOFACMatch = data?.ofacCheckStatusName === "confirmed_match";

  const nodeList = [
    {
      node: (
        <NewEmailCard
          email={data?.email}
          status={{ status: BEStatus.valid }}
          disabled={isOFACMatch}
        />
      ),
      hidden: data?.pendingEmail,
      sm: 12,
      md: 12,
      lg: 12,
    },
    {
      node: (
        <NewEmailCard
          email={data?.pendingEmail}
          status={{ status: BEStatus.pending }}
          disabled={isOFACMatch}
        />
      ),
      hidden: !data?.pendingEmail,
      sm: 12,
      md: 12,
      lg: 12,
    },
    {
      node: <></>,
      hidden: data?.pendingEmail,
      sm: 12,
      md: 12,
      lg: 12,
    },
    {
      node: (
        <NameInput
          name="firstName"
          placeholder="Enter First Name"
          label={t("firstName")}
          disabled={isOFACMatch}
        />
      ),
      sm: 12,
      md: 12,
      lg: 12,
      hasTooltip: true,
    },
    {
      node: (
        <NameInput
          name="lastName"
          placeholder="Enter Last Name"
          label={t("lastName")}
          disabled={isOFACMatch}
        />
      ),
      sm: 12,
      md: 12,
      lg: 12,
      hasTooltip: true,
    },
    {
      node: (
        <BirthDatePicker
          name="date"
          placeholder="MM/DD/YYYY"
          fullWidth
          label={t("dateOfBirth")}
          disabled={isOFACMatch}
        />
      ),
      sm: 12,
      md: 12,
      lg: 12,
      hasTooltip: true,
    },
    {
      node: (
        <RHFTelInput
          name="phone"
          fullWidth
          label={t("phoneNumber")}
          disabled={isOFACMatch}
        />
      ),
      sm: 12,
      md: 12,
      lg: 12,
      hasTooltip: true,
    },
    {
      node: (
        <NameInput
          name="occupation"
          placeholder="Add Job Title"
          label={"Job Title"}
          disabled={isOFACMatch}
        />
      ),
      sm: 12,
      md: 12,
      lg: 12,
      hasTooltip: true,
    },
    {
      node: (
        <RHFInput
          name="employer"
          fullWidth
          type="text"
          placeholder="Add Employer"
          label={t("employer")}
          disabled={isOFACMatch}
        />
      ),
      sm: 12,
      md: 12,
      lg: 12,
      hasTooltip: true,
    },
  ];

  const tooltipMessage = "This edit is blocked by a confirmed match on OFAC";

  if (isLoading)
    return (
      <Box sx={{ width: "100%" }}>
        <MyAccountLoadingSkeleton />
      </Box>
    );

  return (
    <Box sx={{ ...container, ...(!isDesktop && { padding: "3px 8px" }) }}>
      <FadeUpWrapper delay={100}>
        <AvatarUpload
          rounded
          size={isMobileView ? 80 : 56}
          defaultImageURL={img}
          onUpload={(file: File) => handleChangeStatus({ file })}
          onDelete={removeImage}
          subTitle={`${globalName?.firstName} ${globalName?.lastName}`}
          placeholder={avatarImage}
          canUpload
          canDelete
          disabled={isOFACMatch}
          tooltipMessage={tooltipMessage}
        />
      </FadeUpWrapper>
      <Grid mt={0.5} container rowSpacing={2} columnSpacing={3}>
        {nodeList.map(({ node, hidden, lg, md, sm, hasTooltip }, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={sm || 6}
            md={md || sm || 6}
            lg={lg || md || sm || 6}
            sx={{ ...(hidden && { display: "none" }) }}
          >
            <WithTooltipWrapper
              hasTooltip={!!hasTooltip}
              tooltipProps={{
                show: isOFACMatch,
                message: tooltipMessage,
              }}
            >
              <FadeUpWrapper delay={100 + 50 * (index + 1)}>
                {node}
              </FadeUpWrapper>
            </WithTooltipWrapper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const container = {
  width: "100%",
  padding: "8px 16px",
};
