const statsKeys = [
  "totalPurchases",
  "totalProducts",
  "visitors",
  "conversionRate",
  "averagePurchases",
  "sumGenerated",
];

type TParserReturned = Record<(typeof statsKeys)[number], number>;

export const statsParser = (data?: any): TParserReturned => {
  return statsKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: data?.[key] || 0,
    };
  }, {});
};

export const mergeStats = (data: TParserReturned[]) => {
  const result: TParserReturned = {};

  data.forEach((indexedData) => {
    Object.entries(indexedData).forEach(([key, value]) => {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    });
  });
  return result;
};
