import { OFACCheckStatusType } from "./hooks/useOFAC";

export const MATCHES = ["confirmed_match", "possible_match"];

export type OFACTabType =
  | "Business Profile"
  | "Business Owner"
  | "Primary Account Holder";

export type TabStatuses = {
  [key in OFACTabType]: OFACCheckStatusType;
};
