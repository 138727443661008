import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { IconButton } from "@common/IconButton";
import { ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";

export const MoreActionButton = ({
  onClick,
  testId,
  ...props
}: { onClick: any; testId?: string } & ButtonProps) => {
  const classes = useStyles();

  return (
    <IconButton
      size="small"
      className={classes.iconBtn}
      style={{
        marginLeft: "auto",
      }}
      onClick={onClick}
      {...props}
      data-testid={testId || "more-action-button"}
    >
      <HorizontalMoreIcon width={20} height={20} fill={palette.black[100]} />
    </IconButton>
  );
};

const useStyles = makeStyles({
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    "&:hover": {
      background: palette.neutral[20],
    },
  },
});
