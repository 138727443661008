import { Text } from "@common/Text";
import { Skeleton, Stack, Divider, styled } from "@mui/material";

const DividerText = styled(Text)(({ theme }) => ({
  color: theme.palette.neutral["70"],
  fontSize: "18px",
  fontWeight: 350,
  lineHeight: "21.6px",
  letterSpacing: "-0.18px",
}));

const SubTitle = styled(Text)(({ theme }) => ({
  color: theme.palette.neutral["70"],
  fontWeight: 350,
  lineHeight: "16.8px",
}));

type TSize = string | number;

const CommonSkeleton = ({
  height,
  width,
}: {
  height?: TSize;
  width?: TSize;
}) => <Skeleton height={height} width={width} sx={{ borderRadius: "8px" }} />;

const SecondarySkeleton = () => (
  <>
    <Divider />
    <Stack direction="column" gap="8px" alignItems="stretch">
      <CommonSkeleton height={32} />
      <CommonSkeleton height={72} />
    </Stack>
  </>
);

const LoadingSkeleton = () => {
  return (
    <Stack direction="column" gap="32px" alignItems="stretch">
      <Skeleton
        variant="circular"
        width={94}
        height={94}
        sx={{ alignSelf: "center" }}
      />
      <Stack direction="column" gap="8px" alignItems="stretch">
        {Array.from({ length: 3 }, (_, i) => (
          <CommonSkeleton key={i} height={32} />
        ))}
      </Stack>
      {Array.from({ length: 2 }, (_, i) => (
        <SecondarySkeleton key={i} />
      ))}
    </Stack>
  );
};

export { DividerText, SubTitle, LoadingSkeleton };
