import React, { useState } from "react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { HiddenComponent } from "@containers/HiddenComponent";

interface TabsProps {
  sectionsRef: React.RefObject<(HTMLDivElement | null)[]>;
  tabs: { label: string; hidden: boolean; disabled: boolean }[];
}

const PermissionTabs = ({ tabs, sectionsRef }: TabsProps) => {
  const [tab, setTab] = useState<number>(0);

  const labels = {
    acquirer: "Acquirer ",
    enterprise: "Provider",
    submerchant: "Merchant",
    merchant: "Merchant",
    user: "User",
  };

  const handleClick = (index: number, disabled: boolean) => {
    if (disabled) return;
    setTab(index);
    sectionsRef.current?.[index]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <TabsContainer direction="row">
      {tabs.map(({ label, hidden, disabled }, index) => (
        <HiddenComponent key={label} hidden={hidden}>
          <TabItem
            data-testid={`permissions-list-${label}-tab`}
            selected={tab === index}
            disabled={disabled}
            onClick={() => handleClick(index, disabled)}
          >
            {labels[label as keyof typeof labels]}
          </TabItem>
        </HiddenComponent>
      ))}
    </TabsContainer>
  );
};

const TabsContainer = styled(Stack)(() => ({
  padding: "4px 16px 0px 16px",
  borderBottom: `1px solid ${palette.liftedWhite[200]}`,
}));

const TabItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "disabled",
})<{ selected: boolean; disabled: boolean }>(({ selected, disabled }) => ({
  padding: "4px 16px",
  color: palette.gray[300],
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  background: "inherit",
  border: "none",
  boxShadow: "none",
  textTransform: "capitalize",
  textDecoration: "none",
  cursor: "pointer",
  userSelect: "none",

  ...(selected &&
    !disabled && {
      borderBottom: `2px solid ${palette.black[300]}`,
      color: palette.black[300],
      cursor: "default",
    }),
}));

export default PermissionTabs;
