import { Grid } from "@mui/material";
import { AmountText, BaseText } from "../ExpandedRow_V2/atoms";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Separator } from "./Separator";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { parseAmount } from "@utils/index";

type Props = {
  cost: number; //without fees
  charged: number; // with fees
  fees: number;
  isChargeback?: boolean;
  isTransfer?: boolean;
  chargebackFee?: number;
  isMobile?: boolean;
  recipient?: string;
  status?: string;
  passFees?: boolean;
  isRefund?: boolean;
  resetAnimation?: boolean;
  balanceAdjustment: number;
};

export const InformationsAmountComponent = ({
  cost,
  charged,
  fees,
  isChargeback,
  isTransfer,
  chargebackFee = 15,
  isMobile,
  recipient,
  status,
  passFees,
  isRefund,
  resetAnimation,
  balanceAdjustment,
}: Props) => {
  const feePaidByCustomer = !passFees;

  const chargedBack = feePaidByCustomer ? parseAmount(cost + fees) : cost;
  const totalText = isTransfer
    ? "Money Transfer (USD)"
    : getTotalText(status) + " (USD)";

  const titleGridSizes = {
    xs: 12,
    md: isTransfer ? 5 : 4,
    lg: isTransfer ? 5 : 4,
  };

  const valueGridSizes = {
    xs: 12,
    md: isTransfer ? 7 : 8,
    lg: isTransfer ? 7 : 8,
  };

  const isChargebackReversal = status === "Chargeback Reversal";

  return (
    <>
      <Grid
        display="flex"
        item
        key="amount"
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "baseline" : "center"}
      >
        <Grid
          item
          {...titleGridSizes}
          display="flex"
          alignItems="center"
          width="100%"
        >
          <FadeUpWrapper delay={250} reset={resetAnimation}>
            <BaseText
              value={
                isTransfer ? getCostLabel(status, recipient) : "Amount (USD)"
              }
              width="90%"
              sx={{
                wordBreak: "keep-all",
              }}
            />
          </FadeUpWrapper>
        </Grid>
        <Grid item {...valueGridSizes} width="100%">
          <FadeUpWrapper delay={250} reset={resetAnimation}>
            <AmountText
              injectedStyle={{ textAlign: "left" }}
              key="amount"
              testId="amount-text"
              value={cost}
            />
          </FadeUpWrapper>
        </Grid>
      </Grid>
      <Grid display="flex" flexDirection="column" item key="fees">
        <Grid
          item
          xs={12}
          display="flex"
          alignItems={isMobile ? "baseline" : "center"}
          flexDirection={isMobile ? "column" : "row"}
        >
          <Grid
            item
            {...titleGridSizes}
            display="flex"
            alignItems="center"
            width="100%"
          >
            <FadeUpWrapper delay={270} reset={resetAnimation}>
              <BaseText value="Fees (USD)" width={150} />
            </FadeUpWrapper>
          </Grid>
          <Grid item {...valueGridSizes} width="100%">
            <FadeUpWrapper delay={270} reset={resetAnimation}>
              <AmountText
                injectedStyle={{ textAlign: "left" }}
                key="fees_amount"
                testId="fees-amount-text"
                value={fees}
              />
            </FadeUpWrapper>
          </Grid>
        </Grid>

        {passFees !== undefined &&
          !isChargeback &&
          !isChargebackReversal &&
          !isTransfer && (
            <FadeUpWrapper delay={290} reset={resetAnimation}>
              <CaptionText>
                {feePaidByCustomer
                  ? "Customer paid fee"
                  : "Deducted from merchant's available balance"}
              </CaptionText>
            </FadeUpWrapper>
          )}
        {isChargeback && (
          <FadeUpWrapper delay={290} reset={resetAnimation}>
            <CaptionText>
              Already deducted from merchant&apos;s balance
            </CaptionText>
          </FadeUpWrapper>
        )}
        {isChargebackReversal && (
          <FadeUpWrapper delay={290} reset={resetAnimation}>
            <CaptionText>Added back to merchant&apos;s balance</CaptionText>
          </FadeUpWrapper>
        )}
      </Grid>
      <FadeUpWrapper delay={290} reset={resetAnimation}>
        <>
          {!isTransfer && (
            <Grid
              display="flex"
              item
              key="total_cost"
              alignItems={isMobile ? "baseline" : "center"}
              flexDirection={isMobile ? "column" : "row"}
            >
              <Grid
                item
                {...titleGridSizes}
                display="flex"
                alignItems="center"
                width="100%"
              >
                <BaseText value={totalText} width={150} />
              </Grid>
              <Grid item {...valueGridSizes} width="100%">
                <AmountText
                  injectedStyle={{ textAlign: "left" }}
                  key="total_cost_amount"
                  value={isChargeback ? chargedBack : charged}
                />
              </Grid>
            </Grid>
          )}
        </>
      </FadeUpWrapper>

      {isChargeback && (
        <>
          <FadeUpWrapper delay={300} reset={resetAnimation}>
            <Separator marginBottom={0} />
          </FadeUpWrapper>
          <FadeUpWrapper delay={300} reset={resetAnimation}>
            <Grid
              display="flex"
              item
              key="chargeback_fees_amount"
              alignItems={isMobile ? "baseline" : "center"}
              flexDirection={isMobile ? "column" : "row"}
            >
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                display="flex"
                alignItems="center"
              >
                <BaseText
                  sx={{
                    color:
                      balanceAdjustment > 0
                        ? palette.tag.success.hover
                        : "error.hover",
                  }}
                  value="Chargeback Fee (USD)"
                  width={150}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={4}>
                <AmountText
                  injectedStyle={{
                    textAlign: "left",
                    color:
                      balanceAdjustment > 0
                        ? palette.tag.success.hover
                        : palette.error.hover,
                    width: "100%",
                  }}
                  key="chargeback-fees"
                  value={chargebackFee}
                />
              </Grid>
            </Grid>
          </FadeUpWrapper>
        </>
      )}
    </>
  );
};

const CaptionText = ({ children }: { children: React.ReactNode }) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Text
      variant="caption"
      lineHeight="14.4px"
      fontWeight="book"
      maxWidth={!isMobileView ? "33%" : "100%"}
      color={palette.black[100]}
    >
      {children}
    </Text>
  );
};

const getTotalText = (status?: string) => {
  switch (status) {
    case "Refunded":
      return "Refunded";
    case "Chargeback":
      return "Charged back";
    case "Chargeback Reversal":
      return "Reversed";
    default:
      return "Charged";
  }
};

const getCostLabel = (status?: string, recipient?: string) => {
  switch (status) {
    case "Pending":
      return `${recipient} will receive (USD)`;
    case "Requested":
      return `${recipient} will receive (USD)`;
    case "Sending":
      return `${recipient} will receive (USD)`;
    case "Ready to Process":
      return `${recipient} will receive (USD)`;
    case "Sent":
      return `${recipient} received (USD)`;
    case "Failed":
      return `Transfer (USD)`;
    case "Canceled":
      return `Transfer (USD)`;
    default:
      return `Amount (USD)`;
  }
};
