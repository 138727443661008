import { SelectorOption } from "@common/TableFilters/utils";
import { useMemo } from "react";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useForm, useWatch } from "react-hook-form";
import { DateValue } from "../filter-components/DateSelector";

export type FilterValuesType = {
  user: SelectorOption[];
  category: SelectorOption[];
  date: DateValue | undefined;
};

const FILTER_DEFAULT_VALUES = {
  user: [],
  category: [],
  date: undefined,
};

const generateFilterQuery = (key: string, list: SelectorOption[]) => {
  return list.reduce((acc, { value }) => {
    const formattedValue = `${key}:"${value}"`;
    return acc.length === 0 ? formattedValue : `(${acc},${formattedValue})`;
  }, "");
};

const useChangelogFilters = () => {
  const filtersForm = useForm<FilterValuesType>({
    mode: "onChange",
    defaultValues: FILTER_DEFAULT_VALUES,
  });
  const filters = useWatch<FilterValuesType>({ control: filtersForm.control });

  const filtersQuery = useMemo(() => {
    let query = "";

    if (filters?.category && filters.category?.length > 0) {
      query = generateFilterQuery(
        "resourceTypeDisplayName",
        filters.category as SelectorOption[],
      );
    }

    if (filters?.user && filters.user?.length > 0) {
      const prefixedValue = query.length > 0 ? `${query};` : query;
      query = `${prefixedValue}${generateFilterQuery(
        "userAccID",
        filters.user as SelectorOption[],
      )}`;
    }

    if (filters?.date) {
      const prefixedValue = query.length > 0 ? `${query};` : query;
      const startDate = toUnixDateFormat(
        new Date(filters.date.startDate || ""),
      );
      const endDate = toUnixDateFormat(
        new Date(filters.date.endDate?.setHours(23, 59, 59, 0) || ""),
      );
      query = `${prefixedValue}(changeDate:>=d${startDate};changeDate:<=d${endDate})`;
    }

    return query;
  }, [filters]);

  const resetFilters = () => {
    if (filtersQuery) {
      filtersForm.reset();
    }
  };

  return {
    filtersQuery,
    filtersForm,
    resetFilters,
  };
};

export default useChangelogFilters;
