import NiceModal from "@ebay/nice-modal-react";
import SidePanelWrapper from "../SidePanelWrapper";
import useAnalyticsData from "./hooks/useAnalyticsData";
import SidePanelBody from "./SidePanelBody";
import LoadingSkeleton from "./components/LoadingSkeleton";

const CustomersSidePanel = NiceModal.create(
  ({ isEmpty = false }: { isEmpty?: boolean }) => {
    const { data, isLoading } = useAnalyticsData(isEmpty);
    return (
      <SidePanelWrapper
        data={data}
        isLoading={isLoading}
        PanelBody={SidePanelBody}
        LoadingPlaceholder={LoadingSkeleton}
        isEmpty={isEmpty}
      />
    );
  },
);

export default CustomersSidePanel;
