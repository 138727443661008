import { StyledIconButton } from "@common/IconButton";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Text, TruncateText } from "@common/Text";
import { Stack, styled, Skeleton } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { UploadSimple, X } from "@phosphor-icons/react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Button } from "@common/Button";
import {
  DrawerBottomActions,
  MobileActionItemProps,
} from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import { BtnBGTypes } from "@common/Button/Button_V2";
import { HiddenComponent } from "@containers/HiddenComponent";
import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { TruncateTextProps } from "@common/Text/TruncateText";

export const BulkInviteHeader = () => {
  const { onClose } = useNiceModal();
  const { isDesktopView } = useCustomTheme();
  return (
    <FadeUpWrapper delay={70}>
      <Stack
        data-testid="bulk-invite-header"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: isDesktopView ? "16px 16px 12px 16px" : "0 0 24px",
        }}
      >
        <Text
          color={palette.black[100]}
          fontSize="18px"
          fontWeight={isDesktopView ? "regular" : "book"}
          lineHeight="21.6px"
          letterSpacing="-0.01em"
        >
          Invite Merchants
        </Text>
        <StyledIconButton
          sx={{
            height: "22px !important",
            width: "22px !important",
          }}
          onClick={onClose}
        >
          <X size={20} color={palette.black[100]} />
        </StyledIconButton>
      </Stack>
    </FadeUpWrapper>
  );
};

export const BulkInviteContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "paddingReduction",
})<{ paddingReduction: boolean }>(({ theme, paddingReduction }) => ({
  flexGrow: 1,
  flexDirection: "column",
  position: "relative",
  padding: "32px 16px 16px 16px",

  ...(paddingReduction && {
    paddingTop: "16px",
  }),

  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

export const BulkUploadHeader = ({
  total,
  handleUpload,
}: {
  total: number;
  handleUpload: VoidFunction;
}) => {
  return (
    <FadeUpWrapper delay={70}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text color={palette.black[100]} lineHeight="16.8px">
          {total} Merchants
        </Text>
        <Button
          background="text"
          onClick={handleUpload}
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16.8px",
            padding: "4px 8px",
            color: palette.black[100],
            height: "fit-content",
          }}
        >
          <UploadSimple size={20} color={palette.black[100]} /> Upload File
        </Button>
      </Stack>
    </FadeUpWrapper>
  );
};

export type BulkInviteActions = MobileActionItemProps & {
  tooltipProps?: { show: boolean; message: string };
};

const buttonRenderer = (action: BulkInviteActions, background: BtnBGTypes) => {
  const { hidden, label, onSelect, disabled, labelProps, tooltipProps } =
    action;
  return (
    <HiddenComponent hidden={hidden}>
      <Button
        background={background}
        onClick={onSelect}
        disabled={disabled}
        tooltipProps={tooltipProps}
        sx={{
          ...labelProps?.sx,
          padding: "12px 24px",
          lineHeight: "21.6px",
          fontWeight: 400,
        }}
      >
        {label}
      </Button>
    </HiddenComponent>
  );
};

interface IActions {
  actions: BulkInviteActions[];
}

const DesktopActions = ({ actions }: IActions) => {
  return (
    <FadeUpWrapper delay={150}>
      <Stack direction="row" alignItems="center" paddingTop="16px">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          flexGrow={1}
        >
          {buttonRenderer(actions[0], "text")}
        </Stack>
        <Stack direction="row" gap="24px" alignItems="center">
          {buttonRenderer(actions[1], "text")}
          {buttonRenderer(actions[2], "primary")}
        </Stack>
      </Stack>
    </FadeUpWrapper>
  );
};

const MobileActionButton = ({ actions }: IActions) => {
  return (
    <DrawerBottomActions
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          bottom: "100px",
        },
      }}
      label={
        <HorizontalMoreIcon
          height={25}
          width={25}
          fill={palette.neutral.white}
        />
      }
      items={actions}
      buttonSx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    />
  );
};

export const BulkInviteActions = ({ actions }: IActions) => {
  const { isDesktopView } = useCustomTheme();
  if (isDesktopView) {
    return <DesktopActions actions={actions} />;
  }
  return <MobileActionButton actions={actions.reverse()} />;
};

export const InviteListContainer = styled(Stack)(() => ({
  flexGrow: 1,
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "flex-start",
  overflowX: "hidden",
}));

export const TextItem = styled(
  (props: Omit<TruncateTextProps, "lineClamp">) => (
    <TruncateText lineClamp={1} {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "isCaption",
  },
)<{ isCaption?: boolean }>(({ isCaption = false }) => ({
  display: "inline-flex",
  gap: "4px",
  alignItems: "center",
  color: palette.black[200],
  lineHeight: "18px",
  minHeight: "18px",
  paddingLeft: "8px",

  ...(isCaption && {
    color: palette.gray[300],
    fontSize: "12px",
    fontWeight: 350,
  }),
}));

const LoadingSkeleton = () => {
  const { isDesktopView } = useCustomTheme();
  return (
    <Stack
      padding="8px"
      flexDirection="row"
      alignItems="center"
      gap="16px"
      marginRight="10px"
    >
      <Skeleton variant="text" width="20px" height="20px" />
      <Skeleton
        variant="text"
        height="14px"
        sx={{ flexGrow: 1, flexBasis: 0 }}
      />
      {isDesktopView && (
        <Skeleton
          variant="text"
          height="14px"
          sx={{ flexGrow: 1, flexBasis: 0 }}
        />
      )}
    </Stack>
  );
};

export const InvitesLoadingState = () => {
  return (
    <Stack flexDirection="column" alignItems="stretch">
      {Array.from({ length: 6 }, (_, i) => (
        <LoadingSkeleton key={i} data-testid="invite-list-skeleton" />
      ))}
    </Stack>
  );
};
