import { customInstance } from "@services/api";
import {
  getTransactionChart,
  transactionsChartNormalizer,
} from "@services/api/analytics/transactions";
import moment from "moment";
import { useMemo } from "react";
import { useQuery } from "react-query";

const today = new Date();
const startDate = moment(today).subtract(1, "months").format("YYYY-MM-DD");
const endDate = moment(today).format("YYYY-MM-DD");
const filter = `start_date=${startDate}&end_date=${endDate}`;

export const useGetCampaignById = ({
  id,
  campaign,
  enabled,
}: {
  id?: number;
  campaign: string;
  enabled?: boolean;
}) => {
  const { data: Data, ...rest } = useQuery(
    ["get-campaign", id, campaign],
    async () => {
      const data = await customInstance({
        url: `products/${id}`,
      });
      const stats = id
        ? await getTransactionChart({ productId: id, filter })
        : undefined;

      return { data, stats: stats.data };
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
  const data: DATA = Data?.data;
  const stats = useMemo(
    () => transactionsChartNormalizer(false, Data?.stats),
    [Data?.stats],
  );
  return { data, stats, ...rest };
};

export type DATA = {
  id: number;
  typeID: number;
  typeName: string;
  name: string;
  description: string;
  accID: number;
  merchantName: string;
  merchantImageURL: string;
  merchantDescription: string;
  merchTypeName: string;
  merchCanProcessMoney: boolean;
  needsTax: boolean;
  allowFeeChoice: boolean;
  needsShipping: boolean;
  imageURL: string;
  usesBackgroundImage: boolean;
  canBrowseCampaigns: boolean;
  maxPurchaseQuantity: number | null;
  maxEntriesPerPrize: number | null;
  startsAt: number | null;
  endsAt: number | null;
  showMap: boolean | null;
  includeTime: boolean | null;
  allowMultipleWinners: boolean | null;
  locationURL: string | null;
  locationShortAddress: string | null;
  locationAddress: string | null;
  variants: any[] | null; // Adjust the type accordingly when you have more information
  recurringIntervals: string[];
  defaultRecurringIntervalName: string;
  publishedStatus: string;
  totalTransactions: number;
  sumTransactions: number;
  averageTransactions: number;
  visitors: number;
  conversionRate: number;
  createdAt: number;
  updatedAt: number;
  deletedAt: number | null;
};
