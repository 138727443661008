import { AssigneesType, useGetUnderwriters } from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";

import Selector from "./Selector";
import { FormFields, SelectorOption } from "./utils";
import { useMemo, useState } from "react";

interface IAssigneesSelector {
  id: number;
  handleMultiSelect: (fieldName: FormFields, value: SelectorOption) => void;
  selectedValues?: SelectorOption[];
  type?: AssigneesType;
}

const AssigneesSelector = ({
  id,
  handleMultiSelect,
  selectedValues,
  type,
}: IAssigneesSelector) => {
  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetUnderwriters({
    merchantId: id,
    type,
  });

  const options: SelectorOption[] = useMemo(() => {
    if (!data) return [];
    return data?.map(
      ({
        user: {
          firstName = "",
          lastName = "",
          accID = "",
          imageURL = "",
          email = "",
        } = {},
      }: any) => {
        const name = firstName && lastName ? `${firstName} ${lastName}` : email;
        return {
          label: name,
          value: email,
          id: accID,
          imageURL: imageURL,
        };
      },
    );
  }, [data]);

  const filteredOptions = options.filter((item) =>
    item?.label?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <Selector
      options={filteredOptions}
      loading={isLoading}
      value={selectedValues}
      handleChange={(val) =>
        handleMultiSelect("underwriterEmail", val as SelectorOption)
      }
      handleSearch={setSearchValue}
      handleDeselect={(val) => handleMultiSelect("underwriterEmail", val)}
      multiSelect
    />
  );
};

export default AssigneesSelector;
