import { SvgIconProps } from "@mui/material";

const NewLogoutIcon = ({
  height = 20,
  width = 20,
  fill = "#BB1111",
}: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6001 12.0004C19.6001 11.8361 19.5328 11.6791 19.4138 11.5658L16.5173 8.80824C16.2773 8.57975 15.8975 8.58908 15.669 8.82909C15.4405 9.06909 15.4498 9.44887 15.6898 9.67736L17.4997 11.4004L10.7932 11.4004C10.4618 11.4004 10.1932 11.669 10.1932 12.0004C10.1932 12.3317 10.4618 12.6004 10.7932 12.6004L17.4997 12.6004L15.6898 14.3234C15.4498 14.5519 15.4405 14.9317 15.669 15.1717C15.8975 15.4117 16.2773 15.421 16.5173 15.1925L19.4138 12.4349C19.5328 12.3217 19.6001 12.1646 19.6001 12.0004ZM11.876 19.0004C11.876 18.669 11.6073 18.4004 11.276 18.4004L6.93113 18.4004C6.12083 18.4004 5.6001 17.8387 5.6001 17.3034C5.6001 16.972 5.33147 16.7034 5.0001 16.7034C4.66873 16.7034 4.4001 16.972 4.4001 17.3034C4.4001 18.6425 5.60847 19.6004 6.93113 19.6004L11.276 19.6004C11.6073 19.6004 11.876 19.3317 11.876 19.0004ZM11.876 5.00037C11.876 4.669 11.6073 4.40037 11.276 4.40037L6.93113 4.40037C5.60847 4.40037 4.4001 5.35827 4.4001 6.69734L4.4001 14.5392C4.4001 14.8706 4.66873 15.1392 5.0001 15.1392C5.33147 15.1392 5.6001 14.8706 5.6001 14.5392L5.6001 6.69734C5.6001 6.162 6.12083 5.60037 6.93113 5.60037L11.276 5.60037C11.6073 5.60037 11.876 5.33174 11.876 5.00037Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewLogoutIcon;
