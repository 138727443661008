import React from "react";
/* eslint-disable react/no-unescaped-entities */
// import * as React from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// components
import { BaseModal } from "@common/Modal";
import { Button } from "@common/Button";
// assets
import { TermsOfService as TermsOfServiceContent } from "@pages/TermsOfService";
import { Box } from "@mui/material";
import { palette } from "@palette";
import theme from "@theme/index";

type Props = {
  agree?: () => void;
};

const TermsOfService = NiceModal.create(({ agree }: Props) => {
  const modal = useModal();
  const [isBottom, setIsBottom] = React.useState(false);
  const [scrollDirection, setScrollDirection] = React.useState("");
  const prevScrollY = React.useRef(0);
  const divRef = React.useRef<any>(null);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const divElement = divRef.current;

    const currentScrollY = divElement.scrollTop;

    if (currentScrollY > prevScrollY.current) {
      setScrollDirection("down");
    } else if (currentScrollY < prevScrollY.current) {
      setScrollDirection("up");
    }

    prevScrollY.current = currentScrollY;

    const scrollThreshold = 100;
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
      e.currentTarget.clientHeight + scrollThreshold;
    setIsBottom((prev) => (prev ? prev : bottom));
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setScrollDirection("");
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [scrollDirection]);

  const agreeHandler = () => {
    modal.hide();
    if (agree) agree();
  };

  return (
    <BaseModal
      {...muiDialogV5(modal)}
      onClose={() => modal.hide()}
      sx={modalStyles}
    >
      {scrollDirection === "up" && (
        <Box
          sx={{
            ...modalLinearBackground,
            top: 0,
            height: "180px",
            transform: "rotate(180deg)",
          }}
        />
      )}
      <Box ref={divRef} sx={contentStyle} onScroll={handleScroll}>
        <TermsOfServiceContent />
      </Box>
      {isBottom && (
        <Box display="flex" justifyContent="flex-end" sx={{ zIndex: 3 }}>
          <Button background="primary" size="small" onClick={agreeHandler}>
            I agree
          </Button>
        </Box>
      )}
      {scrollDirection === "down" && (
        <Box
          sx={{
            ...modalLinearBackground,
            bottom: 0,
          }}
        />
      )}
    </BaseModal>
  );
});

const modalStyles = {
  "& .MuiDialog-paper": {
    width: "780px",
    borderRadius: "16px",
    padding: "64px 26px 28px 26px",
    background: "rgba(255, 255, 255, 0.50)",
    boxShadow:
      "0px 60px 80px 0px rgba(0, 0, 0, 0.05), 0px 5px 10px 0px rgba(0, 0, 0, 0.05)",
    backdropFilter: "blur(15px)",
    position: "relative",
    [theme.breakpoints.down("new_sm")]: {
      width: "98%",
      height: "90%",
      margin: "auto",
    },
  },
};

const contentStyle = {
  overflow: "scroll",
  position: "relative",
  "& > div": {
    background: "inherit",
    padding: "0 5px",
    overflowX: "hidden",
  },
  "& header": {
    display: "none",
  },
  "& h2": {
    fontWeight: 400,
    fontSize: "18px",
    textAlign: "left",
    margin: 0,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: palette.gray[300],
    border: "0.5px solid #fff",
  },
};

const modalLinearBackground = {
  height: "120px",
  width: "100%",
  background:
    "linear-gradient(360deg, #FDFDFD 0%, rgba(255, 255, 255, 0.00) 74.48%)",
  position: "fixed",
  left: 0,
};

export default TermsOfService;
