import { Stack, styled } from "@mui/material";
import React from "react";
import UserFilter from "./filter-components/UserFilter";
import CategoryFilter from "./filter-components/CategoryFilter";
import DateSelector from "./filter-components/DateSelector";

interface IChangelogFiltersProps {
  merchantId: number;
}

const ChangelogFilters = ({ merchantId }: IChangelogFiltersProps) => {
  return (
    <Container direction="row" gap={1} pb={2}>
      <UserFilter merchantId={merchantId} />
      <CategoryFilter merchantId={merchantId} />
      <DateSelector />
    </Container>
  );
};

export default ChangelogFilters;

const Container = styled(Stack)({
  overflowX: "scroll",
  scrollbarWidth: "none",
  width: "100%",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingBottom: "32px",
});
