import NiceModal from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useTransferActions } from "./hooks/useTransferActions";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { FormProvider, useForm } from "react-hook-form";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import MultiLineInput from "@common/Input/MultiLineInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type CancelProps = {
  transactionId: string;
  customerID: number;
};

type FormFields = {
  reason: string;
};

const defaultValues = { reason: "" };

const CancelTransferModal = NiceModal.create(
  ({ transactionId, customerID }: CancelProps) => {
    const { open, onClose, TransitionProps } = useNiceModal();
    const { handleCancelTransfer, isLoading } = useTransferActions({
      customerID,
      transactionId,
    });

    const schema = Yup.object().shape({
      reason: Yup.string().required("Name is required").trim(),
    });

    const methods = useForm<FormFields>({
      resolver: yupResolver(schema),
      defaultValues,
    });
    const {
      watch,
      formState: { isValid },
    } = methods;
    const { reason } = watch();

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: open,
          onClose,
          DialogProps: {
            paperStyle: { padding: "24px 24px 16px " },
            TransitionProps,
          },
        }}
      >
        <ModalTitle
          title="Transfer Cancellation"
          onClose={onClose}
          textStyles={{
            title: {
              fontSize: "18px",
              lineHeight: "21.6px",
              letterSpacing: "-0.18px",
            },
            description: {
              lineHeight: "16.8px",
            },
          }}
          containerProps={{ sx: { mb: "16px" } }}
          description="Are you sure you want to cancel this transfer? This action cannot be reversed. Please confirm your decision to proceed with cancellation."
        />
        <FormProvider {...methods}>
          <FadeUpWrapper delay={100}>
            <MultiLineInput
              name="reason"
              label="Cancellation Reason"
              placeholder="Cancellation Reason"
              rows={8}
            />
          </FadeUpWrapper>
        </FormProvider>
        <ModalActions
          animationDelay={100}
          secondaryAction={{
            label: "Discard",
            onClick: onClose,
            disabled: isLoading,
          }}
          primaryAction={{
            type: "button",
            onClick: () => handleCancelTransfer(reason),
            form: "cancel-transfer",
            background: "primary",
            disabled: !isValid || isLoading,
            label: "Cancel Transfer",
          }}
          containerProps={{ sx: { mt: "20px" } }}
        />
      </ModalFactory>
    );
  },
);

export default CancelTransferModal;
