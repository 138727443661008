import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { palette } from "@palette";

type PurchaseKeyValueProps = {
  title: string;
  value: React.ReactNode;
  highlight?: boolean;
  children?: React.ReactNode;
  bg?: string;
};

const PurchaseKeyValue = ({
  title,
  value,
  highlight,
  children,
  bg,
}: PurchaseKeyValueProps) => {
  return (
    <Box
      sx={{
        ...boxStyle,
        backgroundColor: highlight ? palette.neutral[150] : bg ? bg : "#ffffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text color={palette.neutral[600]}>{title}</Text>
        {typeof value === "string" || typeof value === "number" ? (
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {value}
          </Text>
        ) : (
          <>{value}</>
        )}
      </Box>
      {children && children}
    </Box>
  );
};

const boxStyle = {
  px: "8px",
  py: "12px",
  borderRadius: "8px",
};

export default PurchaseKeyValue;
