import { CartItemType } from "@redux/slices/cart";
import { useCallback } from "react";

export const useCheckTicketsThreshold = (cartItems: CartItemType[]) => {
  const getInitialQuantityValue = useCallback(() => {
    if (!cartItems) return 0;
    return cartItems.reduce((acc, obj) => acc + obj.quantity, 0);
  }, [cartItems]);

  const isTicketsThresholdReached = (apiMaxSubs: number | string) => {
    const _quantity = getInitialQuantityValue();
    if (_quantity) {
      const maxSubs =
        typeof apiMaxSubs === "number"
          ? apiMaxSubs
          : !Number.isNaN(apiMaxSubs) && parseInt(apiMaxSubs);
      if (maxSubs && _quantity >= maxSubs) return true;
    }
    return false;
  };

  return {
    isTicketsThresholdReached,
  };
};
