import SwipeableDrawerMobile, {
  SwipeableDrawerMobileProps,
} from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";

interface MobileBottomPanelProps
  extends Omit<SwipeableDrawerMobileProps, "onOpen" | "onClose"> {
  onCloseDrawer: () => void;
  isModalVisble: boolean;
}

const DisputePreviewMobilePanel = ({
  children,
  onCloseDrawer,
  isModalVisble,
  ...props
}: MobileBottomPanelProps) => {
  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={isModalVisble}
      onClose={onCloseDrawer}
      PaperProps={{
        sx: { overflow: "hidden", padding: 0 },
      }}
      sx={{
        "& .MuiPaper-root": {
          top: "93px",
        },
      }}
      {...props}
    >
      <div
        data-testid="mobile-dispute-panel-container"
        style={{ overflow: "scroll", padding: "24px 16px 16px" }}
      >
        {children}
      </div>
    </SwipeableDrawerMobile>
  );
};

export default DisputePreviewMobilePanel;
