import { BaseLink } from "@common/Campaigns/BaseLink";
import {
  styled,
  BreadcrumbsProps,
  Breadcrumbs as BreadcrumbsMui,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type BreadcrumbsElementProps = {
  pageName?: string;
  path: string;
};

type renderComponentProps<T> = {
  indexes: number[];
  component: React.FC<T>;
};

interface StyledBreadcrumbsProps<T extends BreadcrumbsElementProps>
  extends BreadcrumbsProps {
  paths: T[];
  breadcrumbsElements: renderComponentProps<T>[];
  FallbackComponent?: React.FC<T>;
  isAlwaysReturnFallback?: boolean;
  maxElements: number;
}

const CustomBreadcrumbs = <T extends BreadcrumbsElementProps>({
  paths,
  breadcrumbsElements,
  FallbackComponent,
  isAlwaysReturnFallback = false,
  maxElements,
  ...rest
}: StyledBreadcrumbsProps<T>) => {
  const styles = useStyles();
  const allIndexes = breadcrumbsElements.reduce(
    (acc, { indexes }: renderComponentProps<T>) => acc.concat(indexes),
    new Array<number>(),
  );

  const maxPaths = paths.slice(0, maxElements);

  const shouldRenderFallback = (index: number) =>
    isAlwaysReturnFallback || !allIndexes.includes(index);

  const linkWrapper = (
    currentIndex: number,
    path: string,
    component: JSX.Element,
  ) => {
    const isLastItem = maxPaths.length - 1 === currentIndex;

    if (!isLastItem) {
      return (
        <BaseLink
          data-testid={`${path}-base-link`}
          key={currentIndex}
          link={path}
          className={styles.LinkStyles}
        >
          {component}
        </BaseLink>
      );
    } else return component;
  };

  return (
    <StyledBreadcrumbs itemScope {...rest}>
      {maxPaths.map((props, index) => {
        const { path } = props;

        if (shouldRenderFallback(index) && FallbackComponent)
          return linkWrapper(
            index,
            path,
            <FallbackComponent key={index} {...props} />,
          );

        const Component = breadcrumbsElements.find(({ indexes }) =>
          indexes.includes(index),
        )?.component;

        if (Component)
          return linkWrapper(index, path, <Component key={index} {...props} />);
      })}
    </StyledBreadcrumbs>
  );
};

export default CustomBreadcrumbs;

const StyledBreadcrumbs = styled(BreadcrumbsMui)(({ theme }) => ({
  marginTop: "3px",
  [".MuiBreadcrumbs-ol"]: {
    flexWrap: "nowrap",
  },
  [".MuiBreadcrumbs-separator"]: {
    fontSize: "20px",
    color: theme.palette.neutral["70"],
    fontWeight: 100,
    margin: "0 15px",
    marginTop: "2px",
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  LinkStyles: {
    "&:hover .MuiTypography-root": {
      color: theme.palette.neutral[100],
    },
  },
}));
