import { SvgIconProps } from "@mui/material";

const NewEditIcon = ({
  height = 24,
  width = 24,
  fill = "#131212",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2714 5.72843C14.8019 5.19799 15.5213 4.89999 16.2714 4.89999C17.8336 4.89999 19.0999 6.16634 19.0999 7.72845C19.0999 8.47861 18.8019 9.19803 18.2715 9.72847L15.12 12.88C14.8856 13.1143 14.5057 13.1143 14.2714 12.88C14.0371 12.6457 14.0371 12.2658 14.2714 12.0315L16.2726 10.0303L13.9696 7.72731L6.0999 15.597V17.9H8.40289L11.3787 14.9242C11.613 14.6899 11.9929 14.6899 12.2272 14.9242C12.4615 15.1585 12.4615 15.5384 12.2272 15.7727L9.07568 18.9243C8.96316 19.0368 8.81055 19.1 8.65142 19.1H5.4999C5.16853 19.1 4.8999 18.8314 4.8999 18.5V15.3485C4.8999 15.1894 4.96312 15.0367 5.07564 14.9242L13.5453 6.45452L14.2714 5.72843ZM14.8181 6.87878L17.1211 9.18177L17.4229 8.87995C17.7283 8.57455 17.8999 8.16035 17.8999 7.72845C17.8999 6.82908 17.1708 6.09999 16.2714 6.09999C15.8396 6.09999 15.4253 6.27156 15.12 6.57696L14.8181 6.87878Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewEditIcon;
