import { Box, Divider, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { RiskProfileLevel } from "features/TransactionsRiskProfile/components";
import DisputeAssignmentSelect from "@components/Disputes/DisputesList/components/DisputeAssignmentSelect";
import { Fragment } from "react";
import { Warning } from "@phosphor-icons/react";

const MOBILE_WARNING = `We're sorry, but submitting evidence and responding to 
  disputes is currently only available on the desktop version of our platform.`;

const DisputeCaseSummary = ({
  isMobileView = false,
}: {
  isMobileView?: boolean;
}) => {
  const summarySections = {
    firstSection: [
      {
        title: "Merchant",
        value: (
          <Stack>
            <Text fontWeight="book" color={palette.neutral[90]}>
              Luxury Bags Co
            </Text>
            <Text fontWeight="book" color={palette.neutral[70]}>
              421432131Ah-e11
            </Text>
          </Stack>
        ),
      },
      {
        title: "Assignee",
        value: (
          <>
            <DisputeAssignmentSelect
              showIcon
              data={
                {
                  id: "1",
                  defaultAssignee: {
                    assigneeId: 2,
                    assigneeName: "Johnny Doe",
                    assigneeImageURL:
                      "https://cdn.development.givepayments.com/customer-avatar/e0d6cafa-3f00-4659-8fb5-e90b8d6dc1c2/cac55c6e-34f7-4221-be56-a302fcd8dc79",
                    assigneeEmail: "under@email.com",
                  },
                } as any
              }
            />
          </>
        ),
      },
    ],
    secondSection: [
      { title: "Reason", value: "Product not Received" },
      {
        title: "Action Required",
        value: "Submit Evidence",
      },
      { title: "Deadline", value: "Apr.08, 2024" },
    ],
    thirdSection: [
      { title: "Transaction ID", value: "GS_TXN_CwF3RKljw84iJliSfff1QL" },
      { title: "Customer", value: "Ralph Edwards" },
      { title: "Name on card", value: "Ralph Edwards" },
      { title: "Card type", value: "Mastercard" },
      { title: "Card number", value: "•••• 0005" },
      { title: "Email", value: "ralph.edwards@gmail.com" },
    ],
    fourthSection: [
      { title: "Amount (USD)", value: "200.00" },
      { title: "Fees (USD)", value: "22.09" },
    ],
  };

  const textColor = palette.tag.warning.hover;
  const Section = isMobileView ? StackSection : GridSection;
  return (
    <Stack spacing="20px">
      {isMobileView && (
        <>
          <WarningContainer>
            <Stack direction="row" spacing="8px" alignItems="center">
              {<Warning size={24} weight="duotone" color={textColor} />}
              <Text
                sx={{
                  fontSize: 18,
                  lineHeight: "21.6px",
                }}
                color={textColor}
              >
                Not Available on Mobile
              </Text>
            </Stack>
            <Stack spacing={2}>
              <Text
                fontSize="14px"
                lineHeight="16.8px"
                color={palette.warning.text}
                fontWeight="book"
                sx={{ wordBreak: "break-word" }}
              >
                {MOBILE_WARNING}
              </Text>
            </Stack>
          </WarningContainer>
          <ContainerDivider />
        </>
      )}
      <StyledContainer>
        <Stack
          direction={isMobileView ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMobileView ? "flex-start" : "center"}
          spacing={isMobileView ? "8px" : 0}
        >
          <Text fontSize={24} color={palette.neutral[80]} fontWeight="book" lineHeight="32.4px">
            Dispute Case Summary
          </Text>
          <RiskProfileLevel level={0} onClick={() => null} />
        </Stack>
        <ContainerDivider />
        <Section section={summarySections.firstSection} />
        <ContainerDivider />
        <Section section={summarySections.secondSection} />
        <ContainerDivider />
        <Section section={summarySections.thirdSection} />
        <ContainerDivider />
        <Box>
          <Section section={summarySections.fourthSection} />
          <Text fontSize={12} fontWeight="book" color={palette.neutral[80]}>
            Deducted from merchant’s available balance / Customer paid fee
          </Text>
        </Box>
      </StyledContainer>
    </Stack>
  );
};

const GridSection = ({
  section,
}: {
  section: { title: string; value: string | JSX.Element }[];
}) => {
  return (
    <Grid container spacing={1}>
      {section.map(({ title, value }, i) => (
        <Fragment key={i}>
          <Grid item xs={4}>
            <GridText text={title} />
          </Grid>
          <Grid item xs={8}>
            {typeof value === "string" ? (
              <GridText text={value} isValue />
            ) : (
              value
            )}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};

const StackSection = ({
  section,
}: {
  section: { title: string; value: string | JSX.Element }[];
}) => {
  return (
    <Stack spacing={2} direction="column">
      {section.map(({ title, value }, i) => (
        <Stack spacing="2px" direction="column" key={i}>
          <GridText text={title} />
          {typeof value === "string" ? (
            <GridText text={value} isValue />
          ) : (
            value
          )}
        </Stack>
      ))}
    </Stack>
  );
};
const ContainerDivider = () => <Divider color={palette.neutral[20]} />;

const GridText = ({ text, isValue }: { text: string; isValue?: boolean }) => (
  <Text color={palette.neutral[isValue ? 80 : 70]} fontWeight="book" lineHeight="16.8px">
    {text}
  </Text>
);

const StyledContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: palette.neutral[10],
  borderRadius: theme.spacing(1.5),
  gap: theme.spacing(2),
}));

const WarningContainer = styled(Box)(() => ({
  display: "flex",
  padding: " 12px 16px",
  flexDirection: "column",
  gap: "8px",
  borderRadius: "12px",
  background: "#FFF2E9",
}));

export default DisputeCaseSummary;
