import { NewMobileDownloadIcon } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import { Stack } from "@mui/material";
import React from "react";
import { useDownloadReport } from "./hooks";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
import { DOWNLOAD_REPORT_MODAL_MOBILE } from "modals/modal_names";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { FEATURE_DENY_MESSAGE } from "@constants/permissions";

function DownloadReportAction() {
  const { disableDownload, downloadReport, denyDownload } = useDownloadReport();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    NiceModal.show(DOWNLOAD_REPORT_MODAL_MOBILE, {
      title: "Download transaction report?",
      confirmTitle: "Yes, download",
      cancelTitle: "Cancel",
      handleConfirm: downloadReport,
    });
  };

  return (
    <Stack direction="row" alignItems="center">
      <CustomToolTip showToolTip={denyDownload} message={FEATURE_DENY_MESSAGE}>
        <StyledIconButton
          onClick={handleOpen}
          disabled={disableDownload || denyDownload}
          sx={{
            maxHeight: "32px",
            maxWidth: "32px",
            padding: "0 !important",
            borderRadius: "4px",
            "&:active": {
              backgroundColor: palette.liftedWhite[200],
            },
            "&:hover path": {
              fill: "transparent",
            },
          }}
          data-testid="mobile-download-report-button"
        >
          <NewMobileDownloadIcon />
        </StyledIconButton>
      </CustomToolTip>
    </Stack>
  );
}

export default React.memo(DownloadReportAction);
