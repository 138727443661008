import React from "react";

function SquareExclamationIcon({
  width = "22",
  fill = "#575353",
  height = "22",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0622 9.9425L12.0575 0.936876C11.7765 0.657499 11.3963 0.500687 11 0.500687C10.6038 0.500687 10.2236 0.657499 9.94254 0.936876L0.942537 9.9425C0.66316 10.2235 0.506348 10.6037 0.506348 11C0.506348 11.3963 0.66316 11.7765 0.942537 12.0575L9.94722 21.0631C10.2283 21.3425 10.6084 21.4993 11.0047 21.4993C11.401 21.4993 11.7812 21.3425 12.0622 21.0631L21.0669 12.0575C21.3463 11.7765 21.5031 11.3963 21.5031 11C21.5031 10.6037 21.3463 10.2235 21.0669 9.9425H21.0622ZM10.25 6.5C10.25 6.30109 10.3291 6.11032 10.4697 5.96967C10.6104 5.82902 10.8011 5.75 11 5.75C11.1989 5.75 11.3897 5.82902 11.5304 5.96967C11.671 6.11032 11.75 6.30109 11.75 6.5V11.75C11.75 11.9489 11.671 12.1397 11.5304 12.2803C11.3897 12.421 11.1989 12.5 11 12.5C10.8011 12.5 10.6104 12.421 10.4697 12.2803C10.3291 12.1397 10.25 11.9489 10.25 11.75V6.5ZM11 16.25C10.7775 16.25 10.56 16.184 10.375 16.0604C10.19 15.9368 10.0458 15.7611 9.96067 15.5555C9.87552 15.35 9.85324 15.1238 9.89665 14.9055C9.94006 14.6873 10.0472 14.4868 10.2045 14.3295C10.3619 14.1722 10.5623 14.065 10.7806 14.0216C10.9988 13.9782 11.225 14.0005 11.4306 14.0856C11.6361 14.1708 11.8118 14.315 11.9354 14.5C12.0591 14.685 12.125 14.9025 12.125 15.125C12.125 15.4234 12.0065 15.7095 11.7955 15.9205C11.5846 16.1315 11.2984 16.25 11 16.25Z"
        fill={fill}
      />
    </svg>
  );
}

export default SquareExclamationIcon;
