import { Text } from "@common/Text";
import MarkAllAsOkModal, {
  MarkAllAsOkModalProps,
} from "@components/Merchants/MerchantPreview/modals/MarkAllAsOkModal";
import NiceModal from "@ebay/nice-modal-react";
import { ButtonBase, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Check } from "@phosphor-icons/react";

const color = palette.neutral[80];

const MarkAllAsOkButton = ({
  merchantId,
  profileId,
  escalationIds,
}: MarkAllAsOkModalProps) => {
  const handleOpenModal = () => {
    NiceModal.show(MarkAllAsOkModal, { merchantId, profileId, escalationIds });
  };

  return (
    <ButtonBase data-testid="risk-mark-all-as-ok-btn" onClick={handleOpenModal}>
      <Stack direction="row" gap={0.5} paddingRight={1} alignItems="center">
        <Check color={color} size={20} />
        <StyledText color={color} lineHeight="16.8px">
          Mark all as OK
        </StyledText>
      </Stack>
    </ButtonBase>
  );
};

const StyledText = styled(Text)({
  "&:hover": {
    textDecoration: "underline",
  },
});

export default MarkAllAsOkButton;
