import React from "react";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Text } from "@common/Text";

interface Props {
  children: React.ReactElement | React.ReactElement[];
  isError: boolean;
  isFetching: boolean;
  errorsMessage?: string;
  customSpinner?: React.ReactElement;
}

export const WithFetching = ({
  children,
  isFetching,
  isError,
  errorsMessage,
  customSpinner,
}: Props) => {
  if (isFetching) {
    if (customSpinner) {
      return customSpinner;
    }
    return (
      <LoadingSpinner
        sx={{
          height: 60,
        }}
      />
    );
  }

  if (isError) {
    return (
      <Text textAlign="center" padding={3}>
        {errorsMessage ?? `Something went wrong`}
      </Text>
    );
  }

  return <>{children}</>;
};
