import { Box, Divider, Stack } from "@mui/material";
import DraggableModal from "@common/DraggableModal/DraggableModal";
import LoadingState from "./LoadingState";
import { isEmpty } from "lodash";
import EmptyState from "./EmptyState";
import LogItem from "./LogItem";
import DragModalHeader from "./DragModalHeader";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ChangelogFilters from "./ChangelogFilters";
import { ChangelogData } from "@services/api/changelog/changelog";
import { palette } from "@palette";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Label } from "./LogItem.styles";
import { useChangelogModal } from "./hooks/useChanglelogModal";

interface IChangelogModal {
  headerActions: {
    handleOpenChangelogModal: (open?: boolean) => void;
  };
  isLoading: boolean;
  data?: ChangelogData[];
  isMerchantApproved: boolean;
  isLoadingConversation: boolean;
  totalUnread: number;
  merchantId: number;
  handleLoadMore: () => void;
  isMoreData?: boolean;
  isFiltersApplied?: boolean;
  showMoreDataAvailable?: boolean;
}

const ChangelogModal = ({
  headerActions,
  isLoading,
  isMerchantApproved,
  data,
  isLoadingConversation,
  totalUnread,
  handleLoadMore,
  merchantId,
  isMoreData = true,
  isFiltersApplied,
  showMoreDataAvailable,
}: IChangelogModal) => {
  const { handleOpenChangelogModal } = headerActions;
  const { isOpen } = useChangelogModal();

  const isEmptyData = isEmpty(data);
  const { isMobileView } = useCustomTheme();
  const loadmoreText = isMoreData
    ? "Load previous changes"
    : "No previous changes found.";

  const hideFilters = isEmptyData && (isLoading || !isFiltersApplied);

  return (
    <DraggableModal
      open={isOpen}
      headerElements={
        <DragModalHeader
          isLoadingChangelog={isLoading}
          isLoadingConversation={isLoadingConversation}
          totalUnread={totalUnread}
          shouldShowChangelog
        />
      }
      headerActions={{
        handleOpenModal: handleOpenChangelogModal,
      }}
      idBase="changelog"
      baseHeaderProps={{
        title: "Changelog",
        isMobileView: isMobileView,
      }}
      contentContainerSx={{
        "& .MuiDialogContent-root, &.MuiDialogContent-root": {
          padding: "24px 18px 24px",
          overflowX: "hidden",
        },
      }}
      subHeader={
        !hideFilters ? <ChangelogFilters merchantId={merchantId} /> : undefined
      }
    >
      <>
        <Box>{isLoading && isEmptyData && <LoadingState />}</Box>
        {isEmptyData || !isMerchantApproved ? (
          <EmptyState
            text={
              !isMerchantApproved
                ? "Changelog is only available after the merchant sponsor approval"
                : "There are no updates to display at the moment"
            }
          />
        ) : (
          <Box>
            {data?.map((item, index) => (
              <>
                <LogItem
                  key={index}
                  date={item?.changeDate}
                  name={item?.userAccFullName}
                  group={item?.resourceTypeDisplayName}
                  resourceName={item?.resourceName}
                  item={item?.fieldChanges}
                  imageUrl={item?.avatarImageURL}
                  email={item?.email}
                />
                {data?.length - 1 > index && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "28px",
                      width: "12px",
                      marginTop: "6px",
                      marginBottom: "8px",
                    }}
                  />
                )}
              </>
            ))}
            {showMoreDataAvailable && (
              <Stack
                direction="row"
                width="100%"
                justifyContent="center"
                alignItems="center"
                gap="4px"
                mt="12px"
                height="18px"
              >
                <Label
                  onClick={handleLoadMore}
                  sx={
                    !isLoading && isMoreData
                      ? {
                          "&:hover": {
                            color: palette.neutral[80],
                            textDecoration: "underline",
                            cursor: "pointer",
                          },
                        }
                      : { color: palette.neutral[70] }
                  }
                >
                  {isLoading ? "Loading" : loadmoreText}
                </Label>
                {isLoading && (
                  <LoadingSpinner
                    sx={{ height: "16px" }}
                    spinnerProps={{ size: 16 }}
                  />
                )}
              </Stack>
            )}
          </Box>
        )}
      </>
    </DraggableModal>
  );
};

export default ChangelogModal;
