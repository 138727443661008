interface IconProps {
    height?: number;
    width?: number;
    fill?: string;
  }
  
  const VerifiedIcon = ({ width, height, fill = "none" }: IconProps) => {
    return (
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9L5 12L14 1" stroke="#4BB543" strokeWidth="1.2"/>
        </svg>
        
        
    );
  };
  
  export default VerifiedIcon;
  