import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

type Props = {
  email: string;
};

const PasswordExpiredModal = NiceModal.create(({ email }: Props) => {
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleModalClose = async () => {
    modal.hide();
    await customInstance({
      url: "password-resets",
      method: "POST",
      data: { email },
    });
    showMessage("Info", email, isDesktop, "Password reset link sent to");
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      width="528px"
      title="Please update your password"
      onSubmit={handleModalClose}
      onCancel={() => modal.hide()}
      actionLabel="Change password"
      isCancel={false}
    >
      We are asking users to set a new password every 90 days. Periodic password
      changes protect you against cybercriminals and brute-force attacks.
    </Popup>
  );
});

export default PasswordExpiredModal;
