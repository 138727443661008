import { TypographyOptions } from "@mui/material/styles/createTypography";

const FONT_PRIMARY = "Give Whyte, sans-serif";

export const typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  h1: {
    fontSize: "40px",
    lineHeight: "48px",
    textDecoration: "none",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -1.32,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: "none",
  },
  h2: {
    fontSize: "34px",
    lineHeight: "40.8px",
    textDecoration: "none",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -1.08,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: "none",
  },
  h3: {
    fontSize: "32px",
    lineHeight: "38.4px",
    textDecoration: "none",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -0.84,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: "none",
  },
  h4: {
    fontSize: "24px",
    lineHeight: "28.8px",
    textDecoration: "none",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: -0.24,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: "none",
  },
  h5: {
    fontSize: "20px",
    lineHeight: "24px",
    textDecoration: "none",
    fontWeight: 400,
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: 0,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    textCase: "none",
  },
  h6: {
    fontSize: "18px",
    lineHeight: "21.6px",
  },
  button: {
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "none",
  },
  buttonSmall: {
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
  },
  body: {
    fontSize: "16px",
    lineHeight: "19.2px",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  captionSmall: {
    fontSize: "10px",
    lineHeight: "14px",
    textTransform: "none",
  },
  smallest: {
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "none",
  },
  headline: {
    fontSize: "16px",
    lineHeight: "22.4px",
  },
  link: {
    fontSize: "14px",
    lineHeight: "21px",
    justifyItems: "baseline",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
};
