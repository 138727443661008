import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { ReactNode } from "react";

const GeneralModalMobileWrapper = ({
    open,
    onClose,
    children,
  }: {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
  }) => {
    return (
      <SwipeableDrawerMobile
        anchor="bottom"
        onOpen={() => undefined}
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiPaper-root.MuiDrawer-paper": {
            background: "#fff",
            padding: "24px 16px 0px 16px",
          },
        }}
      >
        {children}
      </SwipeableDrawerMobile>
    );
  };

  export default GeneralModalMobileWrapper