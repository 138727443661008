import { palette } from "@palette";
import React, { useCallback, useEffect, useRef, useState } from "react";

type TMousePosition = {
  x: number;
  y: number;
};

type DrawingEvent =
  | React.MouseEvent<HTMLCanvasElement>
  | React.TouchEvent<HTMLCanvasElement>;

const useDrawOnCanvas = ({
  height,
  width,
  onSave,
}: {
  height: number;
  width: number;
  onSave?: (canvas: HTMLCanvasElement) => void;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = width * 2;
    canvas.height = height * 2;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.style.background = "#fff";
    canvas.style.borderRadius = "12px";

    const context = canvas.getContext("2d");
    if (!context) return;
    context.scale(2, 2);
    context.lineCap = "round";
    context.strokeStyle = palette.neutral[80];
    context.lineWidth = 3;
    contextRef.current = context;
  }, []);

  const onDrawStart = useCallback((event: DrawingEvent) => {
    const coordinates = getCoordinates(event);
    if (coordinates) {
      setIsDrawing(true);
      contextRef.current?.beginPath();
      contextRef.current?.moveTo(coordinates.x, coordinates.y);
    }
  }, []);

  const onDrawEnd = useCallback(() => {
    if (!canvasRef.current || !contextRef.current) return;

    contextRef.current.closePath();
    if (onSave) onSave(canvasRef.current);
    setIsDrawing(false);
  }, []);

  const onDraw = useCallback(
    (event: DrawingEvent) => {
      if (!isDrawing) return;

      const newPosition = getCoordinates(event);
      if (!newPosition) return;
      drawLine(newPosition);
    },
    [isDrawing],
  );

  const getCoordinates = (event: any) => {
    if (!canvasRef.current) return null;

    if (["mousedown", "mouseup", "mousemove"].includes(event.type)) {
      const { offsetX, offsetY } = event.nativeEvent;
      return {
        x: offsetX,
        y: offsetY,
      };
    }
    event.stopPropagation();
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      x: event.touches[0].pageX - rect.x,
      y: event.touches[0].pageY - rect.y,
    };
  };

  const drawLine = (newPosition: TMousePosition) => {
    if (!canvasRef.current || !contextRef.current) return;

    contextRef.current?.lineTo(newPosition.x, newPosition.y);
    contextRef.current?.stroke();
  };

  const clear = () => contextRef.current?.clearRect(0, 0, width, height);

  return {
    canvasRef,
    onDrawStart,
    onDraw,
    onDrawEnd,
    isDrawing,
    clear,
  };
};

export default useDrawOnCanvas;
