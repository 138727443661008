import { customInstance } from "@services/api";
import { useQuery } from "react-query";

export type CartDataType = {
  id: number;
  title: string;
  amount: number;
  quantity: number;
  recurringIntervalName: string;
  recurringFrequency: number;
  imageUrl?: string;
  campaign:string;
};

export const useGetCart = () => {
  const getCart = () => {
    return customInstance({
      method: "GET",
      url: `/cart`,
    });
  };

  const { data, isLoading, isError, refetch } = useQuery("get-cart", async () => {
    const data = await getCart();
    const customCartItems = data?.items?.map((item: any) => ({
      id: item.id,
      title: item.productVariantName,
      amount: item.unitPrice / 100,
      quantity: item.quantity,
      recurringIntervalName: item.recurringIntervalName,
      recurringFrequency: item.recurringFrequency,
      imageUrl: item.imageURL,
      campaign: item.productType.charAt(0).toUpperCase() + item.productType.slice(1)
    }))

    return { ...data, items: customCartItems || [] };
  }, { cacheTime: 0 });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
