// rhf
import { useFormContext, Controller } from "react-hook-form";
// mui
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
// draftjs
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { EditorState, DraftHandleValue } from "draft-js";
import { MAX_TEXT_LENGTH, ORANGE_TEXT_THRESHOLD } from "@constants/constants";
import { toolbar } from "./toolbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { getToolbarOptionsStyle } from "./utils";

type DraftJSEditorProps = {
  handleBeforeInput?: (
    chars: string,
    editorState: EditorState,
    eventTimeStamp: number,
  ) => DraftHandleValue;
};

export default function RHFCustomEditor({
  name,
  isOnboarding,
  ...props
}: EditorProps &
  DraftJSEditorProps & {
    name: string;
    isOnboarding?: boolean;
  }) {
  const { control } = useFormContext();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [focused, setFocused] = useState(false);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onEditorStateChange = (editorState: any) => {
          field.onChange(editorState);
        };

        const handleBeforeInput = (input: string, editorState: EditorState) => {
          if (
            editorState?.getCurrentContent().getPlainText().length >
            MAX_TEXT_LENGTH - 1
          ) {
            return "handled";
          } else {
            return "not-handled";
          }
        };

        const remaining = field.value
          ? MAX_TEXT_LENGTH -
            field?.value?.getCurrentContent()?.getPlainText("\u0001").length
          : MAX_TEXT_LENGTH;
        let remainingColor = palette.neutral[600];
        if (remaining <= ORANGE_TEXT_THRESHOLD) remainingColor = "orange";
        if (remaining <= 0) remainingColor = "red";

        return (
          <>
            <StyledEditor
              isDesktop={isDesktop}
              focused={focused}
              isOnboarding={isOnboarding}
            >
              <Editor
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                placeholder=""
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                handlePastedText={(val) => {
                  const textLength = field.value
                    .getCurrentContent()
                    .getPlainText().length;
                  return val.length + textLength > MAX_TEXT_LENGTH;
                }}
                handleBeforeInput={handleBeforeInput}
                editorState={field.value}
                onEditorStateChange={onEditorStateChange}
                toolbar={toolbar}
                {...props}
              />
            </StyledEditor>
            <Text
              ml={1}
              variant="caption"
              color={remainingColor}
              mt={0.5}
              data-testid="remaining-characters-text"
            >
              {remaining < 0 ? 0 : remaining}
            </Text>
          </>
        );
      }}
    />
  );
}

export function RHFAdvancedCustomEditor({
  name,
  ...props
}: EditorProps & DraftJSEditorProps & { name: string }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <StyledEditor>
            <Editor
              handlePastedText={(val) => {
                const textLength = field.value
                  .getCurrentContent()
                  .getPlainText().length;
                return val.length + textLength > MAX_TEXT_LENGTH;
              }}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={field.value}
              {...props}
            />
            <Text variant="caption" color={palette.neutral[600]} mt={0.5}>
              {field.value === ""
                ? MAX_TEXT_LENGTH
                : MAX_TEXT_LENGTH -
                  field.value.getCurrentContent().getPlainText("").length}{" "}
              Characters remaining
            </Text>
          </StyledEditor>
        );
      }}
    />
  );
}

export function CustomEditor({ ...props }: EditorProps) {
  return (
    <StyledEditor>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        {...props}
      />
    </StyledEditor>
  );
}

const StyledEditor = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isDesktop" && prop !== "focused" && prop !== "isOnboarding",
})<{
  isDesktop?: boolean;
  focused?: boolean;
  isOnboarding?: boolean;
}>(({ isDesktop, focused, isOnboarding }) => ({
  border: focused ? "2px solid #8F8F8F" : "2px solid #E1E1DE",
  borderRadius: "8px",
  padding: "16px 8px",
  backgroundColor: "#ffffff",

  "& .public-DraftEditorPlaceholder-hasFocus": {},

  "& .editorClassName": editorContentStyle,
  "& .toolbarClassName": editorToolbarStyle({ isDesktop, isOnboarding }),

  ...getToolbarOptionsStyle({
    variant: isOnboarding ? "onboarding" : "default",
  }),

  "& .public-DraftStyleDefault-block": {
    margin: "0px",
  },
  "& h1": {
    lineHeight: "30px",
  },
  "& .rdw-fontfamily-dropdown": {
    ...fontFamilyDropdownStyle,
    width: "120px",
  },
  "& .rdw-fontsize-dropdown": fontFamilyDropdownStyle,
  "& .rdw-block-dropdown": {
    ...fontFamilyDropdownStyle,
    width: "90px",
    marginLeft: 0,
  },
  "& .editor-tool-hidden": { display: "none" },
  "& .rdw-dropdown-wrapper": {
    height: "24px",
    margin: "0 2px",
  },
  "& .rdw-list-wrapper div:nth-of-type(3)": {
    display: "none",
  },
  "& .rdw-list-wrapper div:nth-of-type(4)": {
    display: "none",
  },
  "& .rdw-text-align-wrapper div:nth-of-type(4)": {
    display: "none",
  },
  "& .rdw-colorpicker-wrapper": {
    order: "4",
  },
  "& .rdw-embedded-wrapper": {
    display: "none",
  },
  "& .rdw-remove-wrapper": {
    display: "none",
  },
  "& .rdw-link-wrapper div:nth-of-type(2)": {
    display: "none",
  },
  "& .rdw-fontfamily-wrapper": {
    flexGrow: 1,
    marginRight: "2px",
    maxWidth: "70px",
  },
  "& .rdw-option-wrapper:active": {
    boxShadow: "none",
  },
  "& .rdw-option-wrapper:hover": {
    boxShadow: "none",
  },
  "& .rdw-dropdown-wrapper:active": {
    boxShadow: "none",
  },
  "& .rdw-dropdown-wrapper:hover": {
    boxShadow: "none",
  },
  "& .rdw-emoji-modal": {
    transform: "translateX(-180px)",

    "@media (max-width: 550px)": {
      transform: "translateX(-150px)",
    },
    "@media (max-width: 455px)": {
      transform: "translateX(-20px)",
    },
  },
  "& .rdw-link-modal": {
    transform: "translateX(-170px)",
  },
  "& .rdw-colorpicker-modal": {
    transform: "translateX(-70px)",
  },
  "& .rdw-image-modal": {
    transform: "translateX(-180px)",
  },
  "@media (max-width: 570px)": {
    "& .rdw-image-modal": {
      transform: "translateX(-200px)",
    },
  },
  "@media (max-width: 474px)": {
    "& .rdw-image-modal": {
      transform: "translateX(-30px)",
    },
  },
  "@media (max-width: 430px)": {
    "& .rdw-colorpicker-modal": {
      transform: "translateX(-150px)",
    },
    "& .rdw-image-modal": {
      transform: "translateX(0)",
    },
  },
  "@media (max-width: 384px)": {
    "& .rdw-inline-wrapper div:nth-of-type(7)": {
      display: "none",
    },
    "& .rdw-link-modal": {
      transform: "translateX(-170px)",
    },
  },
}));

const editorContentStyle = {
  minHeight: "142px",
  height: "auto",
  paddingTop: "12px",
  lineHeight: "25px",

  "@media (max-width: 600px)": {
    maxHeight: "auto",
    height: "auto",
    minHeight: "142px",
  },

  ".DraftEditor-root": {
    height: "auto",
  },

  ".public-DraftEditor-content": {
    border: "solid thin white",
  },

  "& .public-DraftEditorPlaceholder-root": {
    fontFamily: "Give Whyte",
    opacity: 0.5,
    color: palette.gray[300],
  },
};

const editorToolbarStyle = ({
  isDesktop,
  isOnboarding,
}: {
  isDesktop?: boolean;
  isOnboarding?: boolean;
}) => ({
  marginBottom: 0,
  padding: 0,
  width: isDesktop ? (isOnboarding ? "476px" : "590px") : "auto",
  border: "none",
  display: "flex",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  alignItems: "flex-start",

  "& .rdw-history-wrapper": {
    display: "none",
  },
  "& .rdw-option-active": {
    boxShadow: "none",
    borderColor: "#8f8f8f99",
  },
});

const fontFamilyDropdownStyle = {
  width: "40px",
  borderRadius: "6px",
  "& a": {
    fontSize: "12px",
    lineHeight: "16.2px",
    fontWeight: 400,
    color: palette.black[100],
  },
  "& li": {
    fontSize: "12px",
    lineHeight: "16.2px",
    fontWeight: 400,
    color: palette.black[100],
  },
};
