import { showMessage } from "@common/Toast";
import {
  QKEY_ALL_NOTIFICATIONS,
  QKEY_UNREAD_NOTIFICATIONS,
  QKEY_CHECK_NEW_NOTIFICATIONS,
} from "@constants/queryKeys";
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { TNotificationIds, markAsRead } from "@services/api/notifications/user";
import { PROFILE_MODAL } from "modals/modal_names";
import { useMutation, useQueryClient } from "react-query";
import useRedirect from "@hooks/common/router/useRedirect";
import { selectUser } from "@redux/slices/auth/auth";

const infiniteQueries = [QKEY_UNREAD_NOTIFICATIONS, QKEY_ALL_NOTIFICATIONS];

const useNotificationsActions = () => {
  const selectedUser = useAppSelector(selectSelectedAccount);
  const user = useAppSelector(selectUser);
  const merchantId = selectedUser?.id || 0;
  const userId = user?.userAccID || 0;

  const queryClient = useQueryClient();

  const refetchQueries = () => {
    infiniteQueries.forEach((key) => {
      queryClient.setQueryData(key, (oldData: any) => {
        if (!oldData) return undefined;

        return {
          pages: [
            {
              data: [],
              nextCursor: null,
              total: 0,
            },
          ],
          pageParams: [undefined],
        };
      });
    });
    infiniteQueries.forEach((q) => queryClient.invalidateQueries(q));
    queryClient.refetchQueries(QKEY_CHECK_NEW_NOTIFICATIONS);
  };

  const { redirectToModal } = useRedirect();

  const markAsReadMutation = useMutation(
    (notificationsIds: TNotificationIds) => {
      return markAsRead(merchantId, userId, notificationsIds);
    },
  );

  const markAllAsRead = () => {
    if (!userId || !merchantId) return;

    markAsReadMutation.mutate(["*"], {
      onSuccess: () => {
        refetchQueries();
      },
      onError: () => {
        showMessage("Error", "Whoops... an error occured");
      },
    });
  };

  const markNotificationAsRead = async (id: number) => {
    if (!userId || !merchantId) return;

    await markAsReadMutation.mutateAsync([id]);
    [...infiniteQueries, QKEY_CHECK_NEW_NOTIFICATIONS].forEach((key) =>
      queryClient.refetchQueries(key),
    );
  };

  const handleConfigurationClick = () =>
    redirectToModal(PROFILE_MODAL, {
      initialTab: "Notifications",
    });

  return {
    markAllAsRead,
    markNotificationAsRead,
    handleConfigurationClick,
  };
};

export default useNotificationsActions;
