import { Stack } from "@mui/material";
import { TPreviewModalProps } from "./PrimaryAccountHolder/PAHUploaderPreviewModal";
import { Box } from "@mui/material";
import ImageCaption from "./ImageCaption";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import PreviewedImageMobile from "./PreviewdImageMobile";
import PreviewedImageDesktop from "./PreviewedImageDesktop";

type OmittableTypes =
  | "isZooming"
  | "setIsZooming"
  | "setClickedImage"
  | "clickedImage";

export type TPreviewImageContextType = {
  src: string;
  fileName: string | undefined;
  icon: (fill?: string) => ReactNode;
  type: string;
  title: string;
  isZooming: boolean;
  clickedImage: string;
  setIsZooming: Dispatch<SetStateAction<boolean>>;
  setClickedImage: Dispatch<SetStateAction<string>>;
};

export const PreviewImageContext = createContext<
  TPreviewImageContextType | undefined
>(undefined);
export const usePreviewImageContext = () =>
  useContext(PreviewImageContext) as TPreviewImageContextType;

const ImageSection = ({
  src,
  fileName,
  icon,
  type,
  title,
  children,
}: Omit<TPreviewImageContextType, OmittableTypes> & {
  children: ReactNode;
}) => {
  const [isZooming, setIsZooming] = useState<boolean>(false);
  const [clickedImage, setClickedImage] = useState<string>("");

  return (
    <PreviewImageContext.Provider
      value={{
        src,
        fileName,
        icon,
        type,
        title,
        isZooming,
        setIsZooming,
        setClickedImage,
        clickedImage,
      }}
    >
      <Box position="relative" flex={1}>
        {children}
      </Box>
    </PreviewImageContext.Provider>
  );
};

const PreviewImagesBody = ({
  filesData,
  isMobileView,
}: Pick<TPreviewModalProps, "filesData"> & { isMobileView: boolean }) => {
  return (
    <Stack direction={isMobileView ? "column" : "row"} gap={2} mt={2}>
      {filesData.map(({ src, ...rest }, index) => {
        if (!src) return null;
        return (
          <ImageSection key={index} src={src} {...rest}>
            {isMobileView && <ImageSection.PreviewedImageMobile />}
            {!isMobileView && <ImageSection.PreviewedImageDesktop />}
            <ImageSection.ImageCaption />
          </ImageSection>
        );
      })}
    </Stack>
  );
};

ImageSection.PreviewedImageMobile = PreviewedImageMobile;
ImageSection.PreviewedImageDesktop = PreviewedImageDesktop;
ImageSection.ImageCaption = ImageCaption;

export default PreviewImagesBody;
