import { showMessage } from "@common/Toast";
import { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { customInstance } from "@services/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import {
  IReason,
  useGetManageRiskReasons,
} from "@components/Merchants/MerchantPreview/hooks/useGetManageRiskReasons";
import { TRiskActivity } from "../types";
import * as Yup from "yup";
import { isOtherReason } from "../utils";
import { useEffect } from "react";
import { format } from "date-fns";
import { TThreadInfo } from "features/Minibuilders/Conversations/types";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { createThread } from "@components/Merchants/MerchantPreview/hooks/useRiskMarkAllAsOk";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import useGetGlobalTopic from "features/Minibuilders/Conversations/hooks/useGetGlobalTopic";
import { checkPortals } from "@utils/routing";

export type ManageRiskFormInputs = {
  reasonID: string;
  customReasonText: string;
  notes: string;
  isIgnored: boolean;
  isReinstated: boolean;
};

const DEFAULT_VALUES = {
  reasonID: "",
  customReasonText: "",
  notes: "",
  isIgnored: false,
  isReinstated: false,
};

const getSchema = (reasons?: IReason[]) =>
  Yup.object().shape({
    reasonID: Yup.string()
      .required("Select a reason")
      .test("reason", "Select a reason", (value) => !!value?.trim()),
    customReasonText: Yup.string().when("reasonID", {
      is: (value: string) => isOtherReason(value, reasons),
      then: Yup.string().required("This field is required"),
    }),
    notes: Yup.string()
      .required("Comment is required")
      .test("comment", "Comment is required", (value) => !!value?.trim()),
    isIgnored: Yup.boolean(),
    isReinstated: Yup.boolean(),
  });

export const useUpdateEscalation = ({
  activityData,
  merchantId,
}: {
  merchantId: number;
  activityData: TRiskActivity;
}) => {
  const { isAcquirerPortal } = checkPortals();
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const { topic: riskActivityTopic, isLoading: riskActivityTopicLoading } =
    useGetGlobalTopic("risk_activity");

  const modal = useModal();
  const QueryClient = useQueryClient();

  const { data: reasons, isLoading: reasonsLoading } = useGetManageRiskReasons({
    riskFactorID: activityData.factorID,
  });

  const { openConversationHandler } = useConversationsModal();

  const methods = useForm<ManageRiskFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(getSchema(reasons)),
  });

  useEffect(() => {
    methods.reset({
      ...DEFAULT_VALUES,
      notes: activityData.notes,
      reasonID: activityData.reasonID ? `${activityData.reasonID}` : "",
      customReasonText: activityData.customReasonText,
    });
  }, [activityData]);

  const updateEscalation = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}/risk/merchant-profiles/${activityData.profileID}/escalations/${activityData.id}`,
      method: "PATCH",
      data,
    });
  });

  const createNewConversationThread = useMutation(
    async (data: any) => await createThread(globalMerchantId, data),
  );

  const onSave: SubmitHandler<ManageRiskFormInputs> = (data) => {
    const { isIgnored, isReinstated, customReasonText, reasonID, notes } = data;

    /**
     * FE uses two different fields isIgnored or isReinstated but payload to send to BE is handled only by one field
     * ie, if isIgnored is true => send isIgnored:true to BE AND if isReinstated is true=> send isIgnored:false to BE
     * else dont send anything to BE
     *  */

    const isIgnoredPayload: { isIgnored?: boolean } = {};
    if (isIgnored) {
      isIgnoredPayload.isIgnored = true;
    }
    if (isReinstated) {
      isIgnoredPayload.isIgnored = false;
    }

    const payload = {
      reasonID: Number(reasonID),
      notes,
      ...(customReasonText && { customReasonText }),
      ...isIgnoredPayload,
    };
    updateEscalation.mutate(payload, {
      onError: (err: any) => {
        showMessage("Error", err?.response?.data?.message);
      },
      onSuccess: async () => {
        if (isAcquirerPortal) {
          const formattedDate = format(new Date(), "MM/dd/yyyy 'at' HH:mm");
          const selectedReason =
            reasons && reasons.find((reason) => reason.id === Number(reasonID));
          const hashtag = activityData?.merchantFactorName?.replaceAll(" ", "");

          let threadText = "";
          if (isIgnored) {
            threadText = `\nI marked as OK on ${formattedDate}`;
          }
          if (isReinstated) {
            threadText = `\nI reinstated this trigger on ${formattedDate}`;
          }

          const reasonText =
            selectedReason &&
            (selectedReason.name === "Other (Please add a reason)"
              ? customReasonText
              : selectedReason.name);

          if (!riskActivityTopic) return;

          await createNewConversationThread.mutateAsync(
            {
              subjectAccID: merchantId,
              escalationIds: [activityData.id],
              TopicDisplayName: "Risk Activity",
              topicID: riskActivityTopic.ID,
              message: {
                body: `#[__${hashtag}__](hashtag:__${hashtag}__)${threadText}\nReasons: ${reasonText}\nComment: ${notes}`,
                mentions: [],
              },
            },
            {
              onSuccess: (data: TThreadInfo) => {
                openConversationHandler({
                  id: data.topicID,
                  name: "Risk Activity",
                  paths: [],
                  forceTopicOpen: true,
                });

                QueryClient.refetchQueries({
                  queryKey: ["get-conversation-topics", merchantId],
                });
                QueryClient.refetchQueries([
                  "get-conversation-messages",
                  data.topicID,
                ]);
              },
            },
          );
        }

        QueryClient.refetchQueries({
          queryKey: [
            "active-risk-activities",
            activityData.profileID,
            merchantId,
          ],
        });
        QueryClient.refetchQueries("risk-activities");
        QueryClient.refetchQueries("get-merchant-preview");
        QueryClient.invalidateQueries("get-merchant-risk-profile");
        modal.remove();
      },
    });
  };

  return {
    onSave,
    methods,
    reasonsLoading,
    reasons,
    isLoading:
      updateEscalation.isLoading ||
      reasonsLoading ||
      riskActivityTopicLoading ||
      createNewConversationThread.isLoading,
    isValid: methods.formState.isValid,
    isDirty: methods.formState.isDirty,
  };
};
