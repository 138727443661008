import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import {
  nonResidentInputsSchema,
  ssnSchema,
  streetAddressValidator,
} from "@utils/validation.helpers";

const currentYear = new Date().getFullYear();
const minYear = currentYear - 100;
const maxYear = currentYear + 100;

const baseAddressSchema = Yup.object({
  country: Yup.string()
    .matches(/^[A-Z].+$/, "Country is required")
    .required("Required"),
  line1: streetAddressValidator(true, "Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string()
    .matches(/^[0-9]{5}(?:-?[0-9]{4})?$/, "Invalid ZIP format")
    .required("Zip code is required"),
});

export const nestedSchema = Yup.object({
  address: baseAddressSchema,
  tinType: Yup.mixed<"ssn" | "ein">().oneOf(["ssn", "ein"]),

  ssn: Yup.string().when("tinType", {
    is: "ssn",
    then: ssnSchema("ssn"),
  }),
  ein: Yup.string().when("tinType", {
    is: "ein",
    then: ssnSchema("ein"),
  }),
  ownership: Yup.string().required("Ownership is required"),
});

export const schema = Yup.object().shape({
  base: Yup.object({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    dob: Yup.date()
      .nullable()
      .typeError("Please provide a valid date")
      .test("is-valid", "Please provide a valid date", function (value) {
        if (!value) return true;
        const year = value.getFullYear();
        const month = value.getMonth();
        const day = value.getDate();
        const isValidYear = year >= minYear && year <= maxYear;
        const isNotZeroStart = year.toString().charAt(0) !== "0"; // Check if year doesn't start with 0
        return (
          isValidYear &&
          isNotZeroStart &&
          month === value.getMonth() &&
          day === value.getDate()
        );
      }),
    phoneNumber: Yup.string()
      .nullable()
      .when({
        is: (exists: string) => !!exists,
        then: (schema) =>
          schema.test(
            "is-valid-number",
            "Please enter a valid phone number",
            function (value) {
              const phoneNumber = value as string;
              if (phoneNumber === "+1") return true; // valid if empty number
              return matchIsValidTel(phoneNumber);
            },
          ),
      }),
    isManager: Yup.boolean(),
    ...nonResidentInputsSchema,
  }),
  isOwner: Yup.boolean(),
  whenIsOwner: Yup.object().when("isOwner", {
    is: true,
    then: nestedSchema,
  }),
});
