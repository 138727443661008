export const useUndeletableWord = (
  element: HTMLTextAreaElement,
  word: string | undefined,
) => {
  const autoFocus = Boolean(word);

  const keyDownExtender = (event: any) => {
    const prevLength = element.selectionEnd;
    if (
      word &&
      (event.key === "Backspace" || event.key === "Delete") &&
      prevLength <= word.trim()?.length
    ) {
      event.preventDefault();
    }

    if (word && element.selectionStart < word.length) {
      element.selectionStart = word.length;
    }
  };

  return {
    keyDownExtender,
    autoFocus,
  };
};
