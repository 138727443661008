import { InfoRegularIcon } from "@assets/icons";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { Text } from "@common/Text";
import { EDIT_DENY_MESSAGE, OPERATIONS } from "@constants/permissions";
import { Stack } from "@mui/material";
import { format } from "date-fns";
import { WithAccessControl } from "features/Permissions/AccessControl";
import { useGetOfacCheckById } from "../hooks/useOFAC";
import {
  lastCheckMapper,
  useUpdateOFACCheck,
} from "../hooks/useUpdateOFACCheck";
import JSONViewer from "./JSONViewer";
import OFACDetailsMenu from "./OFACDetailsMenu";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { BusinessProfileDisclaimer } from "@sections/VerifyAccountHolder_v2/components/BusinessProfileDisclaimer";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { OFACTabType } from "../helpers";
import { isEmpty } from "lodash";

export type TOFACStatus =
  | "Possible match"
  | "Change to Clear Match"
  | "Change to Confirmed Match";

export type TOFACStatusImproved =
  | "Manually cleared"
  | "Confirmed match"
  | "Clear"
  | TOFACStatus;

type Props = {
  merchantId: number;
  legalEntityId: number;
  currentCheckId: number;
  composed: string;
  isEditAllowed: boolean;
  activeTab: OFACTabType;
  tabCategory?: string;
  onUpdateSuccess?: (data: any) => void;
  isEnterprisePanel: boolean;
};

const OFACDetails = ({
  merchantId,
  legalEntityId,
  currentCheckId,
  isEditAllowed,
  composed,
  activeTab,
  tabCategory = "",
  onUpdateSuccess,
  isEnterprisePanel,
}: Props) => {
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { data: currentCheck, isLoading } = useGetOfacCheckById(
    merchantId,
    currentCheckId,
    isNewOfacTabsEnabled ? undefined : legalEntityId,
  );

  const statusName = currentCheck?.statusName as keyof typeof lastCheckMapper;
  const ofacStatus = lastCheckMapper[statusName] as TOFACStatusImproved;
  const time = formatDate(currentCheck?.updatedAt || 0);

  const { handleSubmit } = useUpdateOFACCheck({
    tab: tabCategory,
    onSuccessCallback: onUpdateSuccess,
  });
  const isNRA =
    currentCheck?.resourceCitizenship &&
    currentCheck.resourceCitizenship !== "US";

  if (isLoading) return <LoadingSpinner />;

  return (
    <Stack gap={4} height="100%" mt={4}>
      <WithAccessControl resource={composed} operation={OPERATIONS.UPDATE}>
        <Stack gap={2}>
          <CustomToolTip
            showToolTip={!isEditAllowed}
            message={EDIT_DENY_MESSAGE}
          >
            <OFACDetailsMenu
              ofacStatus={ofacStatus}
              handleSubmit={handleSubmit}
              merchantId={merchantId}
              currentCheck={currentCheck}
              legalEntityId={currentCheck.resourceID}
              activeTab={activeTab}
              isEnterprisePanel={isEnterprisePanel}
            />
          </CustomToolTip>
          {ofacStatus === "Possible match" ||
            (ofacStatus === "Clear" && <Text>{time}</Text>)}
          {ofacStatus === "Confirmed match" && (
            <Text>Confirmed match on {time}</Text>
          )}
          {ofacStatus === "Manually cleared" && (
            <Text>Confirmed on {time}</Text>
          )}
        </Stack>
        {isNRA && (
          <BusinessProfileDisclaimer
            messageType={
              activeTab === "Primary Account Holder"
                ? "non_resident_pah"
                : "non_resident_bo"
            }
            containerStyle={{ backgroundColor: "rgba(230, 234, 242, 0.50)" }}
          />
        )}

        <Stack
          gap={1}
          sx={{
            backgroundColor: "rgba(230, 234, 242, 0.50)",
            borderRadius: "12px",
            padding: "12px 16px",
          }}
        >
          <InfoRegularIcon overrideFill fill="#003182" />
          <Text color="#003182">
            Initial check ran on {formatDate(currentCheck.createdAt)}
          </Text>
        </Stack>
      </WithAccessControl>
      {ofacStatus !== "Clear" && !isEmpty(currentCheck.matchList) && (
        <Stack
          height="100%"
          sx={{
            "& textarea": {
              overflow: "scroll !important",
              height: "100% !important",
              color: "#575353",
              resize: "none",
              fontSize: "14px",
              fontWeight: "400",
              outline: "none",
              padding: "16px 12px",
              border: 0,
              borderRadius: "8px",
              boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
            },
          }}
        >
          <WithAccessControl operation={OPERATIONS.READ} resource={composed}>
            <JSONViewer response={currentCheck.matchList} />
          </WithAccessControl>
        </Stack>
      )}
    </Stack>
  );
};

const formatDate = (date: number) => {
  if (!date || isNaN(date)) return 0;
  return format(new Date(date * 1000), "MMMM d, yyyy, hh:mm:ss a 'UTC'");
};

export default OFACDetails;
