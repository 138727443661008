import { Menu, styled } from "@mui/material";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { applyDateFilter, selectDateFilter } from "@redux/slices/analytics";
import { useEffect, useState } from "react";
import { ButtonDateFilter, RadioDateFilters } from "./filters.atoms";
import DateRangePickerModal from "./DateRangePickerModal";
import moment from "moment";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";

export const formatDateCustomRadio = (date?: Date | string) => {
  const nDate = typeof date === "string" ? new Date(date) : date;
  return moment(nDate).format("MM/DD/YYYY");
};

export type Filter = {
  type: string | undefined;
  title: string;
  amount?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

type TFilterType = "radio" | "button";

const TimeLineChartFilters = ({
  queryKey,
  type,
}: {
  queryKey: string;
  type: TFilterType;
}) => {
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const dateFilter = useAppSelector((state) =>
    selectDateFilter(state, queryKey),
  );

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  const filters: Filter[] = [
    {
      title: "All time",
      type: undefined,
    },
    {
      title: "Last 7d",
      type: "days",
      amount: 6,
    },
    {
      title: "Last 30d",
      type: "months",
      amount: 29,
    },
    {
      title:
        dateFilter?.type === "custom"
          ? `${formatDateCustomRadio(
              dateFilter?.startDate,
            )} - ${formatDateCustomRadio(dateFilter?.endDate)}`
          : "Custom date",
      type: "custom",
      onClick: handleOpenMenu,
    },
  ];

  const setFilter = (index: number) => {
    setSelectedFilter(index);
    const { type, amount } = filters[index];
    if (type === "custom") return;
    dispatch(
      applyDateFilter({ queryKey, filter: { type: type as any, amount } }),
    );
  };

  useEffect(() => {
    const index = filters.findIndex((filter: Filter) => {
      return filter.type === dateFilter?.type;
    });

    if (index === -1) {
      setSelectedFilter(0);
    } else {
      setSelectedFilter(index);
    }
    return () => setSelectedFilter(0);
  }, []);

  const filterProps = {
    filters,
    setFilter,
    selectedFilter,
  };

  const UIComponent = {
    radio: <RadioDateFilters {...filterProps} />,
    button: <ButtonDateFilter {...filterProps} />,
  };

  const rangeSelectorStartDate =
    dateFilter?.type === "custom" && dateFilter?.startDate
      ? toUnixDateFormat(dateFilter.startDate)
      : undefined;

  const rangeSelectorEndDate =
    dateFilter?.type === "custom" && dateFilter?.endDate
      ? toUnixDateFormat(dateFilter.endDate)
      : undefined;

  return (
    <>
      {UIComponent[type]}
      <Wrapper open={open} onClose={handleClose} anchorEl={anchorEl}>
        <DateRangePickerModal
          queryKey={queryKey}
          onClose={handleClose}
          startDate={rangeSelectorStartDate}
          endDate={rangeSelectorEndDate}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = ({
  open,
  anchorEl,
  onClose,
  children,
}: {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  children?: React.ReactNode;
}) => {
  const { isMobileView } = useCustomTheme();

  if (isMobileView) {
    return (
      <SwipeableDrawerMobile
        open={open}
        anchor={"bottom"}
        onClose={onClose}
        onOpen={() => undefined}
        disableRestoreFocus
        sx={{
          "& .MuiPaper-root": {
            top: "auto",
          },
        }}
      >
        {children}
      </SwipeableDrawerMobile>
    );
  }

  return (
    <CustomMenu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {children}
    </CustomMenu>
  );
};

const CustomMenu = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    padding: "24px",
    backgroundColor: palette.neutral.white,
  },
}));

export default TimeLineChartFilters;
