import { DownloadIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { KeyValue } from "@components/Merchants/MerchantPreview/components/atoms";
import {
  IParsedData,
  SignatureTagType,
  TMerchantInfo,
  TPrimaryAccountHolder,
} from "@components/Merchants/MerchantPreview/data.types";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  MERCHANT_AGREEMENT_UPDATE_MODAL,
  SIGN_AGREEMENT_MODAL,
} from "modals/modal_names";
import MerchantAgreementPDF from "./pdf";
import { memo, useEffect, useState } from "react";
import useAgreementSnapshot from "@components/Merchants/MerchantPreview/MerchantAgreement/AgreementSnapshot/hooks/useAgreementSnapshot";
import { Tooltip } from "@common/Tooltip";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { formatFromUnixWithFormat } from "@utils/index";
import { checkPortals } from "@utils/routing";
import { useAsyncAction } from "@hooks/customReactCore";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { useQueryClient } from "react-query";

type TData = {
  merchantInfo: TMerchantInfo;
  primaryAccountHolder: TPrimaryAccountHolder;
  merchantAgreement: TMerchantAgreementPayload;
  status: {
    statusName: string;
  };
};

export type SignAgreementSectionProps = {
  data?: TData;
  status?: SignatureTagType;
  canSign?: boolean;
  isOnboarding?: boolean;
  addSignature?: (file: File) => void;
  merchantId?: number;
  isSettings?: boolean;
  disabled?: boolean;
  file?: File | string | any;
  showTOS?: boolean;
  isEnterprise?: boolean;
};

const SignAgreementSection = ({
  data,
  status,
  canSign,
  isOnboarding,
  addSignature,
  merchantId,
  isSettings,
  disabled,
  file,
  showTOS = false,
  isEnterprise,
}: SignAgreementSectionProps) => {
  const [signature, setSignature] = useState<File | any | null>(null);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUploadSignature: handleUploadSignatureNew } =
    useAddSignatureNew();
  const { handleUploadSignature: handleUploadSignatureOld } =
    useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const { handleSubmit } = useUpdateMerchantInfo(merchantId || 0);
  const [isLoading, triggerAction] = useAsyncAction();

  const { agreement_signing } = useEnterprisePermissions();
  const { isEnterprisePortal } = checkPortals();

  const shouldFetchSnapshot =
    data?.status?.statusName === "approved" &&
    data?.merchantAgreement?.msaHadAgreed &&
    !isOnboarding;
  const queryClient = useQueryClient();

  const { data: snapshot, isLoading: isSnapshotLoading } = useAgreementSnapshot(
    {
      merchantId: merchantId || 0,
      enabled: shouldFetchSnapshot,
    },
  );

  const isUpdateAllowed = useAccessControl({
    resource: isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const snapshotData =
    data?.status?.statusName === "approved" ? snapshot : data;

  const handleSignature = async (file: File) => {
    setSignature(file);
    if (addSignature) {
      addSignature(file);
      return;
    }

    if (merchantId) {
      const signatureURL = await handleUploadSignature(merchantId, file, false);
      if (!signatureURL) return;

      await handleSubmit("merchant_agreement", {
        msaHadAgreed: true,
        msaPCICompliant: true,
        msaRefundPolicy:
          data?.merchantAgreement?.msaRefundPolicy || "30 Days of Purchase",
        signatureURL,
      });
      await queryClient.refetchQueries("get_agreement_snapshot");
    }
  };

  const uploadSignature = (file: File) => triggerAction(handleSignature, file);

  const hasPermissionsToSign =
    isEnterprisePortal && !isSettings && !isOnboarding
      ? agreement_signing
      : true;
  const isSigningDisabled =
    !canSign || disabled || !hasPermissionsToSign || isLoading;

  const handleSignAgreement = () => {
    if (isSigningDisabled || !isUpdateAllowed) return;

    const modal = showTOS
      ? MERCHANT_AGREEMENT_UPDATE_MODAL
      : SIGN_AGREEMENT_MODAL;

    NiceModal.show(modal, {
      addSignature: uploadSignature,
    });
  };

  const signatureDetails = [
    {
      keyName: "Signed by",
      value: data?.merchantAgreement?.signedBy,
    },
    {
      keyName: "Signed at",
      value: data?.merchantAgreement?.msaLastAcceptedAt
        ? formatFromUnixWithFormat(
            data.merchantAgreement.msaLastAcceptedAt,
            "MM/D/YYYY h:mm A",
          )
        : "",
    },
    ...(isOnboarding
      ? []
      : [
          {
            keyName: "Version",
            value: data?.merchantAgreement?.msaLastAcceptedVersion,
          },
        ]),
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingPdf(false);
      /* The reason for this 750ms delay is that, Previous Termination Disclosure is animated and if we start generating PDF before animation finishes,
                                                   animation will lag since PDF generation takes a lot of resources */
    }, 750);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  let signatureView;

  if (data?.merchantAgreement?.signatureURL) {
    signatureView = (
      <Image
        src={addSizeToImage(data?.merchantAgreement?.signatureURL, "medium")}
        onError={({ currentTarget }: any) => {
          currentTarget.onerror = null;
          if (data?.merchantAgreement?.signatureURL) {
            currentTarget.src = addSizeToImage(
              data?.merchantAgreement?.signatureURL,
              "original",
            );
          }
        }}
        data-testid="agreement-signature-url"
      />
    );
  }
  if (signature || file) {
    signatureView = (
      <Image
        src={URL.createObjectURL(
          signature?.file || signature || file?.file || file,
        )}
      />
    );
  }

  if (!data?.merchantAgreement?.msaHadAgreed) {
    const statusDescription =
      isOnboarding || isEnterprise
        ? "Please tick the agreement confirmation box before signing the agreement."
        : "Please sign the agreement by drawing or uploading your digital signature, or by automatically generating it from your name.";
    return (
      <Stack direction="row" spacing={3} mt={3} mb={0.5} flexGrow={1}>
        <SignAgreementStatus
          status={status}
          description={statusDescription}
          isOnboarding={isOnboarding}
        />
        <Tooltip
          title={
            !isUpdateAllowed
              ? EDIT_DENY_MESSAGE
              : "Only the Primary Account Holder can sign the agreement"
          }
          disableHoverListener={canSign || !disabled || isUpdateAllowed}
        >
          <LoadedSignature
            isSigningDisabled={isSigningDisabled || !isUpdateAllowed}
            onClick={handleSignAgreement}
            data-testid="sign-agreement-button"
          >
            {canSign && !signature && !file && (
              <Text
                color={disabled ? palette.neutral[40] : palette.neutral[80]}
              >
                Sign Agreement
              </Text>
            )}
            {canSign && (signature || file) && (
              <Image
                src={URL.createObjectURL(
                  signature?.file || signature || file?.file || file,
                )}
              />
            )}
          </LoadedSignature>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderTop: `1px solid ${palette.neutral[20]}`,
        pt: "12px",
        mt: 3,
      }}
    >
      <Stack spacing={1.5} flexGrow={1}>
        {signatureDetails.map((item) => (
          <KeyValue key={item.keyName} {...item} />
        ))}

        {!isOnboarding &&
          snapshotData?.merchantInfo?.merchantName &&
          !loadingPdf && (
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{ cursor: isSnapshotLoading ? "default" : "pointer" }}
            >
              <PDFDownloadLink
                document={
                  <MerchantAgreementPDF data={snapshotData as IParsedData} />
                }
                onClick={(e: React.MouseEvent) =>
                  isSnapshotLoading && e.preventDefault()
                }
                fileName="merchant_agreement.pdf"
                style={{
                  color: palette.accent[3],
                  textDecoration: "none",
                  opacity: isSnapshotLoading ? 0.5 : 1,
                }}
              >
                Download {isEnterprise ? "Provider" : "Merchant"} Application
              </PDFDownloadLink>
            </Stack>
          )}
      </Stack>
      <WithTooltipWrapper
        hasTooltip={!isUpdateAllowed}
        tooltipProps={{ message: EDIT_DENY_MESSAGE, show: !isUpdateAllowed }}
      >
        <LoadedSignature
          isSigningDisabled={isSigningDisabled || !isUpdateAllowed}
          onClick={handleSignAgreement}
        >
          {signatureView}
        </LoadedSignature>
      </WithTooltipWrapper>
    </Box>
  );
};

const SignAgreementStatus = ({
  status,
  description,
  isOnboarding,
}: {
  status?: SignatureTagType;
  description: React.ReactNode;
  isOnboarding?: boolean;
}) => {
  return (
    <Stack gap={1} direction="column" alignItems="stretch" flexGrow={1}>
      <Text
        fontWeight="book"
        color={palette.neutral[80]}
        lineHeight="16.8px"
        display="inline-flex"
        alignItems="center"
        gap="12px"
      >
        Agreement Signature
        {status && !isOnboarding && (
          <Tag status={status}>{tagMap[status].label}</Tag>
        )}
      </Text>
      <Text
        fontWeight="book"
        color={palette.neutral[70]}
        data-testid="sign-agreement-text"
        lineHeight="16.8px"
      >
        {description}
      </Text>
    </Stack>
  );
};

type TagValues = {
  label: string;
  color: string;
  background: string;
};

export const tagMap: Record<SignatureTagType, TagValues> = {
  pending: {
    label: "Pending",
    color: palette.tag.warning.hover,
    background: palette.tag.warning.bg,
  },
  signed: {
    label: "Signed",
    color: palette.tag.success.hover,
    background: palette.tag.success.bg,
  },
  not_signed: {
    label: "Not Signed",
    color: palette.tag.error.hover,
    background: palette.tag.error.bg,
  },
};

const LoadedSignature = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSigningDisabled",
})<{ isSigningDisabled?: boolean }>(({ isSigningDisabled = true }) => ({
  cursor: isSigningDisabled ? "default" : "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "132px",
  height: "108px",
  padding: "12px",
  borderRadius: "6px",
  border: `1px solid ${
    isSigningDisabled ? palette.neutral[10] : palette.neutral[70]
  } !important`,
  background: palette.neutral[5],
  ...(!isSigningDisabled && {
    "&:hover": {
      background: palette.neutral[10],
      border: `1px solid ${palette.neutral[80]} !important`,
    },
  }),
}));

const Tag = styled("span", {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: SignatureTagType }>(({ status }) => ({
  padding: "2px 16px",
  borderRadius: "16px",
  fontWeight: 400,
  color: tagMap[status].color,
  background: tagMap[status].background,
}));

const Image = styled("img")(() => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
}));

export default memo(SignAgreementSection);
