import { KeyVal } from "@common/KeyVal";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";

function MobileTransactionAmountsCard({ amount }: { amount?: boolean }) {
  return (
    <Grid
      container
      rowGap={2}
      sx={{
        background: "#FFFFFF",
        boxShadow:
          "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
        borderRadius: "4px",
      }}
    >
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName={amount ? "Amount" : "Donation"}
          value={
            <Box component="span" display="inline-flex" gap={0.5}>
              <Text fontWeight="medium">100.19</Text>
              <Box component="sup" sx={supStyle}>
                USD
              </Box>
            </Box>
          }
          mobileView={true}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Givebox Fee"
          value={
            <Box component="span" display="inline-flex" gap={0.5}>
              <Text fontWeight="medium">100.19</Text>
              <Box component="sup" sx={supStyle}>
                USD
              </Box>
            </Box>
          }
          mobileView={true}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Visa Fee"
          value={
            <Box component="span" display="inline-flex" gap={0.5}>
              <Text fontWeight="medium">*3.00</Text>
              <Box component="sup" sx={supStyle}>
                USD
              </Box>
            </Box>
          }
          mobileView={true}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Charged"
          value={
            <Box component="span" display="inline-flex" gap={0.5}>
              <Text fontWeight="medium">103.19</Text>
              <Box component="sup" sx={supStyle}>
                USD
              </Box>
            </Box>
          }
          mobileView={true}
        />
      </Grid>
    </Grid>
  );
}

const supStyle = {
  verticalAlign: "4px",
  fontSize: "10px",
}

export default MobileTransactionAmountsCard;
