import { useMemo } from "react";
import { getAccountStatus, statusOverviewList } from "../utils/status";
import { TStatusReview } from "@components/Merchants/MerchantPreview/data.types";

type Props = {
  data: TStatusReview;
  isEnterprise?: boolean;
};

export const useComponentVisibilityOnStatus = ({
  data,
  isEnterprise,
}: Props) => {
  const statusListItems = statusOverviewList(
    {
      primaryAccount: data?.approvedOwner,
      businessProfile: data.hasApprovedLegalEntity,
      bankAccount: data.hasApprovedBankAccount,
      merchantAgreement: data?.approvedAgreement,
      score: data?.underwritingScorePercentage || 0,
    },
    isEnterprise,
  );

  const { isReadyToApprove } = useMemo(() => {
    let isReadyToApprove = true;

    if (data.statusName !== "approved") {
      statusListItems.forEach((status) => {
        if (!status.approved) {
          isReadyToApprove = false;
        }
      });
    }

    return { isReadyToApprove };
  }, [statusListItems]);

  const status = getAccountStatus(isReadyToApprove, data.statusName);
  const isIncomplete = data.statusName !== "declined" && !isReadyToApprove;

  return {
    isUnderwritingReportVisible:
      (isReadyToApprove || isIncomplete || data.statusName === "declined") &&
      data.statusName !== "approved" &&
      data.statusName !== "suspended",
    isRiskSummaryVisible:
      data.statusName === "approved" || data.statusName === "suspended",
    isStatusOverviewVisible:
      data.statusName === "approved" || data.statusName === "suspended",
    status: status,
  };
};
