import { FormProvider, UseFormReturn, useForm } from "react-hook-form";
import { Button } from "@common/Button";
import { Box, Popover, Stack } from "@mui/material";
import { BirthDatePicker } from "@common/DatePickers";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { CloseIcon } from "@assets/icons";
import { styled } from "@mui/material";
import { StyledIconButton } from "@common/IconButton";
import { CustomDateRange } from "../types";
import TimeZoneSelector from "@common/TimeZoneSelector/TimeZoneSelector";
import useExportDateRange, { TForm } from "../hooks/useExportDateRange";

type TExportDateRangePicker = {
  setCustomRange: ({ timezone, startDate, endDate }: CustomDateRange) => void;
  anchorEl: HTMLInputElement | null;
  setAnchorEl: (v: null) => void;
  initialTimezone: string;
};

export const ExportDateRangePickerPopUp = ({
  setCustomRange,
  anchorEl,
  setAnchorEl,
  initialTimezone,
}: TExportDateRangePicker) => {
  const { isMobileView } = useCustomTheme();

  const { setFormattedDateRange, methods, values } = useExportDateRange({
    setCustomRange,
    initialTimezone,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSetRange = () => {
    setFormattedDateRange();
    handleClose();
  };
  return isMobileView ? (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root.MuiDrawer-paper": {
          background: "#fff",
          padding: "24px 24px 16px ",
        },
      }}
    >
      <RangePicker
        methods={methods}
        values={values}
        handleClose={handleClose}
        handleSetRange={handleSetRange}
      />
    </SwipeableDrawerMobile>
  ) : (
    <Popover
      id="anchorEl"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={{
        paper: {
          style: {
            borderRadius: "8px",
            boxShadow: "0px 3px 15px 0px #02020226",
            width: "305px",
            padding: "24px 24px 16px ",
          },
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <RangePicker
        methods={methods}
        values={values}
        handleClose={handleClose}
        handleSetRange={handleSetRange}
      />
    </Popover>
  );
};

const RangePicker = ({
  methods,
  values,
  handleClose,
  handleSetRange,
}: {
  methods: UseFormReturn<TForm, any, undefined>;
  values: TForm;
  handleClose: () => void;
  handleSetRange: () => void;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      sx={{ bgColor: "red" }}
    >
      <CloseButton
        onClick={handleClose}
        sx={{
          borderRadius: "4px",
          padding: "0 !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        <CloseIcon width={24} height={24} />
      </CloseButton>
      <FormProvider {...methods}>
        <Stack
          gap="8px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <TimeZoneSelector width={308} />
          <BirthDatePicker
            name="startDate"
            label="Start"
            maxDate={values.endDate ? new Date(values.endDate) : new Date()}
            openPickerOnFocus
            adornmentPosition="end"
          />
          <BirthDatePicker
            name="endDate"
            label="End"
            minDate={values.startDate ? new Date(values.startDate) : null}
            maxDate={new Date()}
            openPickerOnFocus
            adornmentPosition="end"
          />
          <Button
            size="medium"
            sx={{
              maxWidth: "95px",
              fontWeight: 400,
              height: "fit-content",
              fontSize: "18px",
              lineHeight: "21.6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: "12px, 24px",
            }}
            background="primary"
            type="button"
            onClick={() => handleSetRange()}
          >
            Apply
          </Button>
        </Stack>
      </FormProvider>
    </Box>
  );
};

const CloseButton = styled(StyledIconButton)({
  borderRadius: "4px",
  padding: "0 !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
