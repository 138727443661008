import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { SidePanel } from "@containers/SidePanel";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import Header from "./Header";
import { Box, Divider, Stack, styled } from "@mui/material";
import MessageSection, {
  MessageSkeleton,
} from "features/Minibuilders/Conversations/Modal/MessageSection";
import { FormProvider, useForm } from "react-hook-form";
import MultiLineInput from "@common/Input/MultiLineInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FloatingSendButton from "./FloatingSendButton";
import useFetchNotificationMessages, {
  FormFieldsNotification,
} from "./useFetchNotificationMessages";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { UploadFile } from "@components/UploadFile";
import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";
import { isEnterpriseWebsite } from "@hooks/onboarding/utils";
import { IDropzoneProps } from "react-dropzone-uploader";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { DocumentListItem } from "./DocumentListItem";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useEffect, useRef, useState } from "react";
import { Text } from "@common/Text";

const defaultValues = {
  merchant_message: "",
  selectedId: undefined,
  files: [],
};
const NotificationModal = NiceModal.create(
  ({ notificationMessageID }: { notificationMessageID: number }) => {
    const modal = useModal();
    const containerRef = useRef<HTMLDivElement>(null);
    const [showAll, setShowAll] = useState(false);

    const { merchantId } = useGetCurrentMerchantId();
    const { isMobileView } = useCustomTheme();
    const {
      populateSnackbarFiles,
      snackbarFiles,
      isLoading: isUploadLoading,
    } = useFileUploadContext();

    const {
      messageListResponse: { data: messageList = [], isLoading },
      updatePostMutation: { mutate },
    } = useFetchNotificationMessages({
      notificationMessageID,
    });
    const isEnterprise = isEnterpriseWebsite();
    const isUploadAllowed = useGetUploadFilePermission(isEnterprise);

    const schema = Yup.object().shape({
      reason: Yup.string().nullable(),
      selectedId: Yup.number().nullable(),
    });

    const methods = useForm<FormFieldsNotification>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      watch,
      setValue,
      getValues,
      formState: { isDirty },
    } = methods;
    const values = watch();

    const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
      fileWithMeta,
      status,
      allFiles,
    ) => {
      populateSnackbarFiles({
        fileWithMeta,
        status,
        allFiles,
        attachmentType: "conversation_message",
        isDeleteAllowed: false,
        onSuccess: () => {
          fileWithMeta.remove();
        },
      });
    };
    useEffect(() => {
      if (snackbarFiles.length > 0) {
        let updatedFiles = getValues("files") || [];
        snackbarFiles.forEach((file) => {
          if (file.status === "Uploaded!") {
            updatedFiles = [...updatedFiles, file];
          }
        });
        setValue("files", updatedFiles);
      }
    }, [snackbarFiles]);

    const handleSubmit = (data: FormFieldsNotification) => {
      mutate(data);
      methods?.reset();
    };
    const handleClose = () => {
      methods?.reset(defaultValues);
      setShowAll(false);
      modal.hide();
    };
    const handleEdit = ({ message, id }: { message: string; id: number }) => {
      setValue("merchant_message", message, { shouldDirty: true });
      setValue("selectedId", id, { shouldDirty: true });
    };

    const handleDeleteFile = (fileName: string) => {
      const updatedFiles = values.files.filter(
        (file: any) => file.name !== fileName,
      );

      setValue("files", updatedFiles);
    };

    const totalMessages = messageList.length;
    const lastMessageIsReply =
      totalMessages > 0 && messageList[0].accID === merchantId;

    const disabledAction = isUploadLoading || isLoading;

    const shownMessages = showAll ? totalMessages : Math.min(totalMessages, 3);
    const renderedMessages = messageList
      .filter((item) => item?.id !== values?.selectedId)
      .slice(0, shownMessages);

    const hasOverflowingChildren = totalMessages > 3 && !showAll;

    const body = (
      <Box
        p={`16px ${isMobileView ? "12px" : "16px"}`}
        gap="18px"
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
      >
        <Header handleClose={handleClose} />
        {isLoading ? (
          <MessageSkeleton count={2} />
        ) : (
          <Stack ref={containerRef} position="relative">
            {renderedMessages.map((message, index) => {
              const timeExpired = timeHasExpired(message?.createdAt);
              const isReply = message?.accID === merchantId;
              const canEdit = isReply && !timeExpired && index === 0;
              const showDivider = index === 0 ? false : true;

              return (
                <FadeUpWrapper key={message?.id} delay={100 + index * 50}>
                  <MessageSection
                    stack={{
                      px: "0",
                      mt: showDivider ? 0 : "24px",
                    }}
                    merchantID={10}
                    message={message}
                    showDivider={showDivider}
                    onHandleEdit={canEdit ? handleEdit : undefined}
                  />
                </FadeUpWrapper>
              );
            })}
            {hasOverflowingChildren && (
              <SeeAllButton>
                <Text
                  color={palette.accent[3]}
                  fontSize="14px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowAll(true)}
                >
                  See all messages
                </Text>
              </SeeAllButton>
            )}
          </Stack>
        )}
        {(!lastMessageIsReply || values?.selectedId) && !isLoading && (
          <>
            <Divider sx={{ mb: "18px" }} />
            <FormProvider {...methods}>
              <Box
                component="form"
                onSubmit={methods.handleSubmit(handleSubmit)}
              >
                <FadeUpWrapper delay={200}>
                  <MultiLineInput
                    name="merchant_message"
                    placeholder="Message"
                    rows={8}
                  />
                </FadeUpWrapper>
                <FadeUpWrapper delay={250}>
                  <FloatingSendButton
                    testId={"submit-button"}
                    hidden={!isDirty}
                    type="submit"
                    disabled={disabledAction}
                    label={values?.selectedId ? "Save Changes" : "Send"}
                    showIcon={!values?.selectedId}
                  />
                </FadeUpWrapper>
              </Box>
            </FormProvider>
            <FadeUpWrapper delay={300}>
              <UploadFile
                backgroundColor="white"
                disabled={!isUploadAllowed}
                onChangeStatus={handleChangeStatus}
                width="100%"
                customText="1200 x 1200 (1:1) recommended, up to 5MB each."
              />
              {values?.files?.map((document: any, idx: any) => {
                const lastDotIndex = document.name.lastIndexOf(".");
                const fileName = document.name.substring(0, lastDotIndex);
                return (
                  <DocumentListItem
                    key={idx}
                    fileName={fileName}
                    fileSize={document.size}
                    onDelete={() => handleDeleteFile(document.name)}
                  />
                );
              })}
            </FadeUpWrapper>
          </>
        )}
      </Box>
    );

    return (
      <>
        {isMobileView ? (
          <SwipeableDrawerMobile
            anchor="bottom"
            onOpen={() => undefined}
            open={modal.visible}
            onClose={handleClose}
            sx={{
              "& .MuiPaper-root": {
                background: palette.neutral[5],
                top: "96px",
              },
            }}
          >
            <ErrorCatcher errorID="notification">{body}</ErrorCatcher>
          </SwipeableDrawerMobile>
        ) : (
          <SidePanel
            modal={modal}
            width="500px"
            onCloseDrawer={() => null}
            onClose={handleClose}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "-8px 0px 50px 0px rgba(0, 0, 0, 0.15) !important",
              },
            }}
          >
            {body}
          </SidePanel>
        )}
      </>
    );
  },
);
export default NotificationModal;

const SeeAllButton = styled(Box)({
  position: "absolute",
  bottom: "0px",
  right: "0px",
  left: "0px",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  background:
    "linear-gradient(180deg, rgba(248, 248, 246, 0) 0%, rgba(248, 248, 246, 1) 100%)",
});

const twentyFourHoursInSeconds = 24 * 60 * 60;

const timeHasExpired = (
  createdAt = 0,
  defaultTimeout = twentyFourHoursInSeconds,
) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const differenceInSeconds = currentTimestamp - createdAt;
  return differenceInSeconds >= defaultTimeout;
};
