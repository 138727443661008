import { UseQueryOptions, useQuery } from "react-query";
import { customInstance } from "@services/api";
import { QKEY_GET_ENTERPRISE_CONFIGURATIONS_BY_ID } from "@constants/queryKeys";

export const useGetEnterpriseConfigurationById = (
  enterpriseId: number,
  options?: Omit<UseQueryOptions<any, any, any, any>, "queryKey" | "queryFn">,
) => {
  return useQuery(
    [QKEY_GET_ENTERPRISE_CONFIGURATIONS_BY_ID, enterpriseId],
    async () =>
      await customInstance({
        url: `/enterprises/${enterpriseId}/configuration`,
        method: "GET",
      }),
    { enabled: Boolean(enterpriseId), ...options },
  );
};
