import { styled } from "@mui/material/styles";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { Text } from "@common/Text";
import { palette } from "@palette";

export type SelectItemProps = MenuItemProps & {
  helpertext?: string;
  error?: boolean;
  warn?: boolean;
};
export const SelectItem = styled(
  // Spelt "helpertext" no camel case because of <li> warning
  (props: SelectItemProps) => (
    <MenuItem {...props}>
      {props.children}
      {props.helpertext && (
        <Text sx={{ color: "#8F8F8F", fontWeight: 300 }}>
          {props.helpertext}
        </Text>
      )}
    </MenuItem>
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== "helpertext" && prop !== "error" && prop !== "warn",
  },
)(({ theme, error, hidden, warn }) => ({
  minHeight: "auto",
  fontSize: "14px",
  padding: "4px 8px",
  borderRadius: "4px",
  border: "1px solid transparent",
  display: hidden ? "none" : "block",

  [theme.breakpoints.down("sm")]: {
    "& .MuiTypography-root ": {
      lineHeight: "30px",
    },
  },

  "& > #select-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > svg": {
      display: "inline",
    },
  },

  "&:not(:last-of-type)": {
    marginBottom: "2px",
  },

  "&:hover": {
    color: "initial",
    background: `#FFFFFF`,

    ...(error && {
      border: "1px solid transparent",
      background: `${theme.palette.error.light}`,
    }),
    ...(warn && {
      color: `${palette.tag.error.text}`,
      background: `${palette.tag.error.bg}`,
    }),
  },
  "&.Mui-selected": {
    color: "initial",
    background: "none !important",

    "&:hover": {
      color: "initial",
      background: `#FFFFFF`,
    },
  },
}));
