const AmexCardIcon = ({width, height}: {width?: number | string, height?: number | string}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.484375"
        width="25.7759"
        height="17.931"
        rx="2.24138"
        fill="#1F72CD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.03685 6.35046L2.66016 11.7647H5.50539L5.85812 10.9014H6.66437L7.0171 11.7647H10.1489V11.1058L10.428 11.7647H12.048L12.327 11.0919V11.7647H18.8403L19.6323 10.9239L20.3739 11.7647L23.7193 11.7717L21.3351 9.0727L23.7193 6.35046H20.4258L19.6548 7.17576L18.9366 6.35046H11.851L11.2425 7.74792L10.6198 6.35046H7.78054V6.98691L7.46468 6.35046H5.03685ZM5.58764 7.1193H6.97453L8.55099 10.7907V7.1193H10.0703L11.2879 9.75169L12.4101 7.1193H13.9218V11.0044H13.002L12.9945 7.96005L11.6534 11.0044H10.8306L9.48205 7.96005V11.0044H7.58974L7.23099 10.1334H5.29282L4.93481 11.0036H3.92093L5.58764 7.1193ZM18.5025 7.1193H14.7623V11.0021H18.4446L19.6315 9.71526L20.7755 11.0021H21.9713L20.2332 9.07194L21.9713 7.1193H20.8273L19.6465 8.39134L18.5025 7.1193ZM6.26241 7.77663L5.62386 9.32822H6.90022L6.26241 7.77663ZM15.686 8.63291V7.92369V7.92301H18.0198L19.0381 9.05723L17.9747 10.1977H15.686V9.42339H17.7264V8.63291H15.686Z"
        fill="white"
      />
    </svg>
  );
};

export default AmexCardIcon;
