import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const RefundTopIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill="none"
      height={`${height}`}
      viewBox="0 0 21 20"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1363 8.03031C11.4292 8.3232 11.9041 8.3232 12.197 8.03031C12.4898 7.73742 12.4898 7.26254 12.197 6.96965L11.1363 8.03031ZM7.64138 3.47473L8.17171 2.9444L8.17171 2.9444L7.64138 3.47473ZM7.35854 3.47473L6.82821 2.9444L6.82821 2.9444L7.35854 3.47473ZM2.80296 6.96965C2.51007 7.26254 2.51007 7.73742 2.80296 8.03031C3.09585 8.3232 3.57073 8.3232 3.86362 8.03031L2.80296 6.96965ZM17.4166 7.49998C17.4166 7.08577 17.0808 6.74998 16.6666 6.74998C16.2524 6.74998 15.9166 7.08577 15.9166 7.49998L17.4166 7.49998ZM8.24996 3.74998C8.24996 3.33577 7.91417 2.99998 7.49996 2.99998C7.08575 2.99998 6.74996 3.33577 6.74996 3.74998L8.24996 3.74998ZM12.197 6.96965L8.17171 2.9444L7.11105 4.00506L11.1363 8.03031L12.197 6.96965ZM6.82821 2.9444L2.80296 6.96965L3.86362 8.03031L7.88887 4.00506L6.82821 2.9444ZM17.4166 12.0833L17.4166 7.49998L15.9166 7.49998L15.9166 12.0833L17.4166 12.0833ZM6.74996 3.74998L6.74996 12.0833L8.24996 12.0833L8.24996 3.74998L6.74996 3.74998ZM12.0833 17.4166C15.0288 17.4166 17.4166 15.0288 17.4166 12.0833L15.9166 12.0833C15.9166 14.2004 14.2004 15.9166 12.0833 15.9166L12.0833 17.4166ZM12.0833 15.9166C9.9662 15.9166 8.24996 14.2004 8.24996 12.0833L6.74996 12.0833C6.74996 15.0288 9.13777 17.4166 12.0833 17.4166L12.0833 15.9166ZM8.17171 2.9444C7.80071 2.5734 7.1992 2.57341 6.82821 2.9444L7.88887 4.00506C7.67408 4.21985 7.32584 4.21985 7.11105 4.00506L8.17171 2.9444Z"
        fill="#A9AFBD"
      ></path>
    </svg>
  );
};
export default RefundTopIcon;
