type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const ProhibitIcon = ({
  width = 19,
  height = 19,
  stroke = "#941A1A",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22852 2.06897C7.72662 2.06897 6.25844 2.51434 5.00966 3.34875C3.76087 4.18316 2.78756 5.36914 2.21281 6.75672C1.63806 8.14429 1.48767 9.67114 1.78068 11.1442C2.07369 12.6172 2.79692 13.9703 3.85893 15.0323C4.92093 16.0943 6.27401 16.8176 7.74705 17.1106C9.2201 17.4036 10.7469 17.2532 12.1345 16.6784C13.5221 16.1037 14.7081 15.1304 15.5425 13.8816C16.3769 12.6328 16.8223 11.1646 16.8223 9.66272C16.82 7.64942 16.0193 5.71921 14.5956 4.29559C13.172 2.87197 11.2418 2.0712 9.22852 2.06897ZM15.1348 9.66272C15.136 10.9377 14.7226 12.1785 13.957 13.198L5.69321 4.9342C6.57096 4.27833 7.61386 3.87956 8.70528 3.78249C9.7967 3.68542 10.8936 3.89387 11.8733 4.38454C12.8531 4.87521 13.677 5.62874 14.253 6.56088C14.8289 7.49301 15.1342 8.56699 15.1348 9.66272ZM3.32227 9.66272C3.32105 8.38774 3.73441 7.14693 4.5 6.12741L12.7638 14.3912C11.8861 15.0471 10.8432 15.4459 9.75176 15.5429C8.66034 15.64 7.56343 15.4316 6.5837 14.9409C5.60397 14.4502 4.78006 13.6967 4.20408 12.7646C3.62811 11.8324 3.32279 10.7584 3.32227 9.66272Z"
        fill={stroke}
      />
    </svg>
  );
};

export default ProhibitIcon;
