import React from "react";
import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import { useAppSelector } from "@redux/hooks";
import { MissingPermissionWrapperProps } from "../product.types";
import { ConnectionErrorState } from "@common/EmptyState";

export const ProductListNotAuthorizedWrapper = ({
  children,
  type,
  isError = false,
  isLoading = false,
}: MissingPermissionWrapperProps) => {
  const hasNotProductListPermission = useAppSelector(
    (state) => state.app.permissions?.[`campaign_${type}`],
  );

  if (isError && !hasNotProductListPermission && !isLoading)
    return <ConnectionErrorState />;

  return (
    <WithMissingPermissionModule
      message="Purchases hidden"
      notAuthorized={hasNotProductListPermission}
    >
      {children}
    </WithMissingPermissionModule>
  );
};
