import { RootState } from "@redux/store";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

type TGlobal = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

export type Role = "provider" | "merchant" | "acquirer" | "";

export type TAuthUser = {
  userAccID: number;
  id: number;
  img: string;
  name: string;
  email: string;
  role: Role;
  globalName: TGlobal;
  currency: string;
  language: string;
  timezone: string;
};

type InitialState = {
  isAuthenticated: boolean;
  user: TAuthUser;
  passwordStatus: string;
};

const initialState: InitialState = {
  isAuthenticated: false,
  user: {
    userAccID: 0,
    id: 0,
    img: "",
    name: "",
    email: "",
    role: "",
    globalName: {
      phoneNumber: "",
      firstName: "",
      lastName: "",
    },
    currency: "",
    language: "",
    timezone: "",
  },
  passwordStatus: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updatePartialUser: (
      state: InitialState,
      action: PayloadAction<Partial<TAuthUser>>,
    ) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    login: (state: InitialState, action: PayloadAction<TAuthUser>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setPasswordStatus: (state: InitialState, action: PayloadAction<string>) => {
      state.passwordStatus = action.payload;
    },
  },
});

export const selectUser = (state: RootState) => state.auth.user;
export const selectPasswordStatus = (state: RootState) =>
  state.auth.passwordStatus;
export const selectAuth = (state: RootState) => state.auth.isAuthenticated;
export const { login, setPasswordStatus, updatePartialUser } =
  authSlice.actions;

export const logoutAction = createAction("LOGOUT");

export default authSlice.reducer;

export const generateMockedAuthState = (
  customState?: Partial<InitialState>,
) => {
  return {
    ...initialState,
    ...customState,
    user: {
      ...initialState.user,
      ...customState?.user,
    },
  };
};
