import { customInstance } from "@services/api";
import { useQuery } from "react-query";

export const useGetMerchantBankAccount = (merchantId: number) =>
  useQuery(
    ["get-merchants-bank-accounts"],
    async () =>
      await customInstance({
        url: `merchants/${merchantId}/bank-accounts?filter=status:"approved"`,
        method: "GET",
      }),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  );
