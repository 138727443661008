const KotoBackArrow = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
}: {
  width?: number;
  height?: number;
  stroke?: string;
}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.33333 17L4 12M4 12H20M4 12L6 10M9.33333 7L8 8.25"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default KotoBackArrow;
