import useHashedData from "@hooks/common/data-management/useHashedData";
import useLogs from "@hooks/common/data-management/useLogs";
import {
  IUpdatedData,
  PermisionHashMap,
} from "features/Permissions/Modal/api/types";
import { useRef } from "react";
import { TPermissionsData } from "../types";

const useDataWithLogs = () => {
  const initialData = useRef<TPermissionsData>({});

  const {
    logs,
    updateItem: updateLogsItem,
    updateAll: updateAllLogs,
    reset,
    hasChanged,
  } = useLogs<IUpdatedData>();
  const {
    logs: latestAdded,
    updateAll: updateLatestAdded,
    reset: resetLatestAdded,
  } = useLogs<IUpdatedData>();

  const {
    data: hashedData,
    updateAll: updateAllHashedData,
    updateEntry: updateHashedDataItem,
    reset: resetHashedData,
  } = useHashedData<IUpdatedData>();

  const setDataFromAPI = (APIData: PermisionHashMap) => {
    const syncedData = {
      ...(Object.keys(APIData) && APIData),
      ...(Object.keys(latestAdded) && latestAdded),
      ...(Object.keys(logs) && logs),
    };
    resetHashedData(syncedData);
  };

  const clearLogs = () => {
    reset();
    resetLatestAdded();
  };

  const removeItemFromLogs = (key: string) =>
    updateAllLogs((old) => {
      const newData = old;
      delete newData[key];
      return newData;
    });

  const handleChangePermissionStatus = (
    key: string,
    updatedItem: IUpdatedData,
    isUnchanged: boolean,
  ) => {
    updateHashedDataItem(key, updatedItem);

    if (isUnchanged) {
      removeItemFromLogs(key);
    } else {
      updateLogsItem(key, updatedItem);
    }
  };

  const setInitialData = (data: TPermissionsData) =>
    (initialData.current = data);

  const effectsChanged = hasChanged(initialData.current);
  const listChanged = Object.keys(latestAdded).length > 0;
  const isMutated = effectsChanged || listChanged;

  return {
    setDataFromAPI,
    clearLogs,
    resetHashedData,
    hashedData,
    latestAdded,
    logs,
    handleChangePermissionStatus,
    updateLatestAdded,
    updateAllHashedData,
    isMutated,
    setInitialData,
    initialData,
    updateAllLogs,
    removeItemFromLogs,
  };
};

export default useDataWithLogs;
