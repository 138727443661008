import Box, { BoxProps } from "@mui/material/Box";

type IconProps = BoxProps & {
  height?: number;
  width?: number;
  fill?: string;
};

const DrawerMenuIcon = ({
  width = 16,
  height = 11,
  fill = "#C1C1C1",
  ...rest
}: IconProps) => {
  return (
    <Box
      width={width}
      height={height}
      viewBox="0 0 16 11"
      component="svg"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9375 1.23438C0.9375 0.958233 1.16136 0.734375 1.4375 0.734375H14.5625C14.8386 0.734375 15.0625 0.958233 15.0625 1.23438C15.0625 1.51052 14.8386 1.73438 14.5625 1.73438H1.4375C1.16136 1.73438 0.9375 1.51052 0.9375 1.23438ZM0.9375 9.67188C0.9375 9.39573 1.16136 9.17188 1.4375 9.17188H8.3125C8.58864 9.17188 8.8125 9.39573 8.8125 9.67188C8.8125 9.94802 8.58864 10.1719 8.3125 10.1719H1.4375C1.16136 10.1719 0.9375 9.94802 0.9375 9.67188ZM12.0625 9.17188C11.7864 9.17188 11.5625 9.39573 11.5625 9.67188C11.5625 9.94802 11.7864 10.1719 12.0625 10.1719L14.5625 10.1719C14.8386 10.1719 15.0625 9.94802 15.0625 9.67188C15.0625 9.39573 14.8386 9.17188 14.5625 9.17188L12.0625 9.17188Z"
        fill={fill}
      />
      <path
        d="M12.0625 9.17188V8.67188V9.17188ZM12.0625 10.1719V9.67188V10.1719ZM14.5625 10.1719V10.6719V10.1719ZM14.5625 9.17188V9.67188V9.17188ZM1.4375 0.234375C0.885215 0.234375 0.4375 0.68209 0.4375 1.23438H1.4375V0.234375ZM14.5625 0.234375H1.4375V1.23438H14.5625V0.234375ZM15.5625 1.23438C15.5625 0.68209 15.1148 0.234375 14.5625 0.234375V1.23438H15.5625ZM14.5625 2.23438C15.1148 2.23438 15.5625 1.78666 15.5625 1.23438H14.5625V2.23438ZM1.4375 2.23438H14.5625V1.23438H1.4375V2.23438ZM0.4375 1.23438C0.4375 1.78666 0.885215 2.23438 1.4375 2.23438V1.23438H0.4375ZM1.4375 8.67188C0.885215 8.67188 0.4375 9.11959 0.4375 9.67188H1.4375V8.67188ZM8.3125 8.67188H1.4375V9.67188H8.3125V8.67188ZM9.3125 9.67188C9.3125 9.11959 8.86479 8.67188 8.3125 8.67188V9.67188H9.3125ZM8.3125 10.6719C8.86479 10.6719 9.3125 10.2242 9.3125 9.67188H8.3125V10.6719ZM1.4375 10.6719H8.3125V9.67188H1.4375V10.6719ZM0.4375 9.67188C0.4375 10.2242 0.885215 10.6719 1.4375 10.6719V9.67188H0.4375ZM12.0625 9.67188V8.67188C11.5102 8.67188 11.0625 9.11959 11.0625 9.67188H12.0625ZM12.0625 9.67188H11.0625C11.0625 10.2242 11.5102 10.6719 12.0625 10.6719V9.67188ZM14.5625 9.67188L12.0625 9.67188V10.6719L14.5625 10.6719V9.67188ZM14.5625 9.67188V10.6719C15.1148 10.6719 15.5625 10.2242 15.5625 9.67188H14.5625ZM14.5625 9.67188H15.5625C15.5625 9.11959 15.1148 8.67188 14.5625 8.67188V9.67188ZM12.0625 9.67188L14.5625 9.67188V8.67188L12.0625 8.67188V9.67188Z"
        fill={fill}
      />
    </Box>
  );
};

export default DrawerMenuIcon;
