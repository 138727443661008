import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation } from "react-query";

const markAsSolved = async ({
  merchantId,
  id,
}: {
  merchantId: number;
  id: number;
}) => {
  return customInstance({
    url: `/merchants/${merchantId}/underwriting-challenges/${id}/status`,
    method: "PATCH",
    data: {
      status: "closed",
      type: "enhanced_due_diligence",
    },
  });
};

const markAsSolvedRiskMonitor = async ({
  merchantId,
  id,
}: {
  merchantId: number;
  id: number;
}) => {
  return customInstance({
    url: `/merchants/${merchantId}/threads/${id}/status`,
    method: "PATCH",
    data: {
      statusName: "closed",
    },
  });
};

type TOptions = { onSuccess?: (data: any) => void };

const useMarkAsSolved = ({
  merchantId,
  isRiskMonitor,
  options,
}: {
  merchantId: number;
  isRiskMonitor: boolean;
  options?: TOptions;
}) => {
  const markAsSolvedMutation = useMutation(
    (id: number) => {
      return isRiskMonitor
        ? markAsSolvedRiskMonitor({ merchantId, id })
        : markAsSolved({ merchantId, id });
    },
    {
      onError: (err: unknown) => {
        const axiosError = err as any;
        const errorMessage = axiosError?.response?.data?.message;
        showMessage("Error", errorMessage || "Unable to change assignee");
      },
      onSuccess: (data) => {
        options?.onSuccess && options.onSuccess(data);
      },
    },
  );

  return markAsSolvedMutation;
};

export default useMarkAsSolved;
