import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { createThread } from "@components/Merchants/MerchantPreview/hooks/useRiskMarkAllAsOk";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { TThreadInfo } from "features/Minibuilders/Conversations/types";
import useGetGlobalTopic from "features/Minibuilders/Conversations/hooks/useGetGlobalTopic";
import { checkPortals } from "@utils/routing";

interface IHookProps {
  profileId: number;
  merchantId: number;
  factorID: number;
  triggerTitle: string;
  triggerDescription: string;
}

const useUpdateRiskTrigger = ({
  merchantId,
  profileId,
  factorID,
  triggerTitle,
  triggerDescription,
}: IHookProps) => {
  const { isAcquirerPortal } = checkPortals();
  const queryClient = useQueryClient();
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const { openConversationHandler } = useConversationsModal();
  const { topic } = useGetGlobalTopic("risk_activity");

  const globalTopicId = topic ? topic.ID : null;

  const triggersMutation = useMutation(({ payload, factorID }: any) => {
    return customInstance({
      url: `/merchants/${merchantId}/risk/merchant-profiles/${profileId}/factors/${factorID}`,
      method: "PATCH",
      data: payload,
    });
  });

  const conversationMutation = useMutation(
    async (data: any) => await createThread(globalMerchantId, data),
  );

  const handeThreadCreation = async (message: string) => {
    if (!globalTopicId) return;
    await conversationMutation.mutateAsync(
      {
        subjectAccID: merchantId,
        TopicDisplayName: "Risk Activity",
        topicID: globalTopicId,
        message: {
          body: message,
          mentions: [],
        },
        factorID,
      },
      {
        onSuccess: async (data: TThreadInfo) => {
          queryClient.refetchQueries({
            queryKey: ["risk-triggers", profileId, merchantId],
          });
          queryClient.refetchQueries({
            queryKey: ["get-conversation-topics", merchantId],
          });
          queryClient.refetchQueries([
            "get-conversation-messages",
            data.topicID,
          ]);
          openConversationHandler({
            id: data.topicID,
            name: "Risk Activity",
            paths: [],
            forceTopicOpen: true,
          });
        },
      },
    );
  };

  const handleUpdateTrigger = ({
    reason,
    isEnabled,
    onSuccessCb,
  }: {
    reason: string;
    isEnabled: boolean;
    onSuccessCb: () => void;
  }) => {
    triggersMutation.mutate(
      {
        payload: { isEnabled, customReason: reason },
        factorID,
      },
      {
        onError: (error: any) => {
          if (error?.response?.data?.message) {
            showMessage("Error", error?.response?.data?.message, true);
          }
        },
        onSuccess: async () => {
          if (isAcquirerPortal) {
            const noSpaceTitle = triggerTitle.replace(/\s/g, "");
            const description = `Trigger ${
              isEnabled ? "enabled" : "disabled"
            }: ${triggerDescription}`;
            const threadMessage = `#[__${noSpaceTitle}__](hashtag:__${noSpaceTitle}__)\n${description}\nReason: ${reason}`;
            await handeThreadCreation(threadMessage);
          } else {
            queryClient.refetchQueries({
              queryKey: ["risk-triggers", profileId, merchantId],
            });
          }
          onSuccessCb();
        },
      },
    );
  };

  return {
    handleUpdateTrigger,
    isLoading: triggersMutation.isLoading || conversationMutation.isLoading,
  };
};

export default useUpdateRiskTrigger;
