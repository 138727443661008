import { FontKeys } from "../components/FontFamilySelector";
import {
  alignments,
  mapValueToNameKey,
} from "../components/SidePanelContent/SingleImageWidget/SingleImageWidgetStyle/consts";
import {
  alignmentTypes,
  borderStyleValueType,
} from "../components/SidePanelContent/SingleImageWidget/SingleImageWidgetStyle/types";
import { parseImageStyleAttrToCssProp } from "../components/SidePanelContent/SingleImageWidget/helpers";
import { weights } from "../consts/fontWeights";
import { colorParagraph, headingsSignal, t } from "../consts/headings";
import {
  DEFAULT_BORDER_COLOR,
  DeviceType,
  globalButtonStyles,
  isPreviewMode,
  selectedDeviceType,
  selectedItem,
} from "../signals";
import { useMediaQuery } from "@mui/material";
import { updateNodeDeviceTypeProperties } from "../NodeDeviceTypePropertiesMap";

export const getPublishedFormDeviceType = () => {
  const isTablet = useMediaQuery(`(max-width:920px)`);
  const isMobile = useMediaQuery(`(max-width: 520px)`);

  const isDesktop = useMediaQuery(`(min-width:920px)`);

  return {
    isDesktop,
    isTablet,
    isMobile,
  };
};

export const getPublishedNodeAttributes = (
  desktopValue: any,
  tabletValue: any,
  mobileValue: any,
) => {
  // In preview mode we are using this function, so in that case we shouldn't show elements based on screen size
  if (isPreviewMode.value) {
    return getDeviceTypeAttribute(desktopValue, tabletValue, mobileValue);
  }

  const { isMobile, isTablet } = getPublishedFormDeviceType();

  if (isMobile) {
    return mobileValue;
  }

  if (isTablet) {
    return tabletValue;
  }

  return desktopValue;
};

export const deviceSpecificWidgets = new Set([
  "heading",
  "divider",
  "single_image",
  "button",
]);

export const getFontWeightSelectedItem = (
  fontWeight: any,
  fontFamily: any,
  selectedIndex: number,
) => {
  if (!fontFamily) {
    return weights.Roboto;
  }
  const weight = fontWeight?.name
    ? {
        label: fontWeight?.name,
        value: fontWeight?.value,
      }
    : {
        label: weights[fontFamily?.name as FontKeys][selectedIndex]?.label,
        value: weights[fontFamily?.name as FontKeys][selectedIndex]?.value,
      };

  return `${weight.value} - ${weight.label}`;
};

export const getDeviceTypeAttributesToUpdate = (key: string) => {
  const capitalizedKey = key[0].toUpperCase() + key.slice(1);
  const updateMapping = {
    [DeviceType.DESKTOP]: [
      key,
      `tablet${capitalizedKey}`,
      `mobile${capitalizedKey}`,
    ],
    [DeviceType.TABLET]: [`tablet${capitalizedKey}`, `mobile${capitalizedKey}`],
    [DeviceType.MOBILE]: [`mobile${capitalizedKey}`],
  };

  return updateMapping;
};

export const getDeviceTypePropertyName = (key: string) => {
  const capitalizedKey = key[0].toUpperCase() + key.slice(1);

  const mapping = {
    [DeviceType.DESKTOP]: key,
    [DeviceType.TABLET]: `tablet${capitalizedKey}`,
    [DeviceType.MOBILE]: `mobile${capitalizedKey}`,
  };

  return mapping[selectedDeviceType.value];
};

export const getDeviceSpecificKey = (key: string, type: DeviceType) => {
  const capitalizedKey = key[0].toUpperCase() + key.slice(1);

  const mapping = {
    [DeviceType.DESKTOP]: key,
    [DeviceType.TABLET]: `tablet${capitalizedKey}`,
    [DeviceType.MOBILE]: `mobile${capitalizedKey}`,
  };

  return mapping[type];
};

export const getDeviceTypeAttribute = (
  desktopValue: any,
  tabletValue: any,
  mobileValue: any,
) => {
  const mapping = {
    [DeviceType.DESKTOP]: desktopValue,
    [DeviceType.TABLET]: tabletValue,
    [DeviceType.MOBILE]: mobileValue,
  };

  return mapping[selectedDeviceType.value] ?? desktopValue;
};

export const updateDeviceSelectedItem = (key: string, newValue: any) => {
  const deviceType = selectedDeviceType.value;
  const selectedItemValue = selectedItem.value;

  const createUpdate = (keys: string[]) => {
    return keys.reduce((acc: any, k: any) => {
      acc[k] =
        typeof newValue === "string"
          ? newValue
          : { ...selectedItemValue[k], ...newValue };
      return acc;
    }, {});
  };

  const updateKeys = getDeviceTypeAttributesToUpdate(key);

  return createUpdate(updateKeys[deviceType]);
};

export const getDividerDeviceTypeCssProperties = (
  style: any,
  metaAttributes: any,
) => {
  const borderType = style?.borderTop?.split(" ")[0];

  if (borderType === "none") {
    return {
      marginTop:
        style?.marginTop && metaAttributes?.["data-sizeName"]
          ? {
              name: metaAttributes["data-sizeName"],
              value: Number(style.marginTop.replace("px", "")),
            }
          : {
              name: "Small",
              value: 6,
            },
      thickness: 0,
      type: {
        value: borderType,
      },
      bgColor: {
        name: "No Color",
        color: "",
      },
    };
  }

  return {
    marginTop:
      style?.marginTop && metaAttributes?.["data-sizeName"]
        ? {
            name: metaAttributes?.["data-sizeName"],
            value: Number(style.marginTop.replace("px", "")),
          }
        : {
            name: "Small",
            value: 6,
          },
    thickness: Number(style?.borderTop?.split(" ")[0].replace("px", "")),
    type: {
      value: style?.borderTop?.split(" ")[1],
    },
    bgColor: metaAttributes?.["data-bgColorName"]
      ? {
          name: metaAttributes?.["data-bgColorName"],
          color: style?.borderTop?.split(" ")[2],
        }
      : colorParagraph,
  };
};

export const getSingleImageDeviceTypeCssProperties = (
  style: any,
  metaAttributes: any,
) => {
  const cssProperties = {
    imageType: parseImageStyleAttrToCssProp(style.objectFit),
    alignment: metaAttributes?.["data-imageAlignment"]
      ? {
          value:
            alignments[
              metaAttributes?.["data-imageAlignment"] as alignmentTypes
            ]?.value,
          name: alignments[
            metaAttributes?.["data-imageAlignment"] as alignmentTypes
          ]?.label,
        }
      : {
          ...alignments.center,
        },
    maxHeight: style.maxHeight
      ? (Number(style.maxHeight.replace("px", "")) / 300) * 100
      : 100,
    opacity: style.opacity ? Number(style.opacity) * 100 : 100,
    radius: style.borderRadius
      ? Number(style.borderRadius.replace("px", ""))
      : 0,
    borderWidth: style.borderWidth
      ? Number(style.borderWidth.replace("px", ""))
      : 0,
    borderColor: metaAttributes?.["data-bgColorName"]
      ? {
          name: metaAttributes?.["data-bgColorName"],
          color: style.borderColor,
        }
      : DEFAULT_BORDER_COLOR,
    borderStyle: (function () {
      const value: borderStyleValueType = style.borderStyle ?? "none";
      return {
        value: value,
        name: mapValueToNameKey[value],
      };
    })(),
  };

  return cssProperties;
};

export const getButtonWidgetDeviceTypeCssProperties = (
  style: any,
  metaAttributes: any,
) => {
  return {
    alignSelf: style.alignSelf,
    borderRadius: style.borderRadius,
    padding: style.padding,
    fontSize: style?.fontSize,
    textAlign: style?.textAlign,
    textDecoration: style?.textDecoration,
    textColor: metaAttributes?.["data-textColorName"]
      ? {
          name: metaAttributes?.["data-textColorName"],
          color: style?.color,
        }
      : globalButtonStyles.textColor.peek(),
    bgColor: metaAttributes?.["data-bgColorName"]
      ? {
          name: metaAttributes?.["data-bgColorName"],
          color:
            style?.backgroundColor && style?.backgroundColor !== "initial"
              ? style?.backgroundColor
              : style?.backgroundImage,
        }
      : globalButtonStyles.backgroundColor.peek(),
  };
};

export const getHeadingDeviceTypeCssProperties = (
  tag: any,
  style: any,
  metaAttributes: any,
) => {
  const tt = tag === "p" ? "body" : (tag as t);

  const gs = headingsSignal[tt];
  return {
    fontFamily: metaAttributes?.["data-fontFamily"]
      ? {
          name: metaAttributes?.["data-fontFamily"],
          value: style.fontFamily,
        }
      : gs.fontFamily.peek(),
    fontWeight: metaAttributes?.["data-fontWeight"]
      ? {
          name: metaAttributes?.["data-fontWeight"],
          value: style?.fontWeight ?? "400",
        }
      : gs.fontWeight.peek(),
    fontSize: style?.fontSize
      ? Number(style.fontSize.replace("px", ""))
      : gs.fontSize.peek(),
    lineHeight: style?.lineHeight
      ? Number(style.lineHeight.replace("%", ""))
      : gs.lineHeight.peek(),
    letterSpacing: style?.letterSpacing
      ? Number(style.letterSpacing.replace("px", ""))
      : gs.letterSpacing.peek(),
    textColor: metaAttributes?.["data-textColorName"]
      ? {
          name: metaAttributes?.["data-textColorName"],
          color:
            style?.backgroundImage && style.backgroundImage !== "initial"
              ? style?.backgroundImage
              : style?.color,
        }
      : gs.textColor.peek(),
    textAlign: style?.textAlign,
  };
};
function removeEmptyAttr(obj: any) {
  const copy: any = {};
  for (const key in obj) {
    if (obj[key]?.length) {
      copy[key] = obj[key];
    }
  }

  return copy;
}

function deepMerge(target: any, source: any) {
  const output = { ...target };
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      output[key] = deepMerge(target[key], source[key]);
    } else {
      output[key] = source[key];
    }
  }

  return output;
}

//Thinking if to enforce updates list
//Updates is necesary if we need to get the updated valued that are wrapped by another object,
//like on image_card, heading and descript styles
export function updateSelectedItem(newProperties: any, updates?: string[]) {
  if (selectedItem.value.state) {
    selectedItem.value = {
      ...selectedItem.value,
      state: deepMerge(selectedItem.value.state, newProperties),
      updates: updates ?? Object.keys(newProperties),
    };

    updateNodeDeviceTypeProperties("state", newProperties);
  }
}

export const isValidNumberValue = (val: string) => /^\d*$/.test(val);
