import { palette } from "@palette";

const MouseLinePlugin = {
  id: "mouseline",
  afterDatasetDraw: (chart: any) => {
    if (chart.tooltip?._active?.length) {
      const x = chart.tooltip._active[0].element.x;
      const yAxis = chart.scales.y;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 1, chart.chartArea.height);
      gradient.addColorStop(0, palette.neutral.white);
      gradient.addColorStop(0.4, palette.gray[300]);
      gradient.addColorStop(1, palette.neutral.white);
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = gradient;
      ctx.stroke();
      ctx.restore();
    }
  }
};

export default MouseLinePlugin;
