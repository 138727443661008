/*
 `navigator.clipboard.writeText()` works directly in all modern browsers as of 2020.
*/

import { showMessage } from "@common/Toast/ShowToast";

const onSuccessHandler = () => {
  showMessage("Info", "Link copied to clipboard");
};

export const useCopyClipboard = (onSuccess = onSuccessHandler) => {
  return {
    copyToClipboard: (textToCopy: string) => {
      navigator.clipboard.writeText(textToCopy);
      onSuccess();
    },
  };
};
