import { TruncateText } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { palette } from "@palette";
import { parseAmount } from "@utils/index";

export const BaseText = ({
  value,
  showCurrency,
  testId,
  ...rest
}: Partial<ITextProps> & {
  value: string;
  showCurrency?: boolean;
  testId?: string;
}) => {
  return (
    <TruncateText
      lineClamp={2}
      color={palette.neutral[70]}
      data-testid={testId || "base-text"}
      {...rest}
    >
      {value}
      {showCurrency ? " (USD)" : ""}
    </TruncateText>
  );
};

export const AmountText = ({
  value,
  injectedStyle = {},
  showCurrency,
  testId,
}: {
  value: number | string;
  injectedStyle?: any;
  showCurrency?: boolean;
  testId?: string;
}) => {
  const finalValue = typeof value === "string" ? value : parseAmount(value);
  return (
    <BaseText
      value={finalValue}
      color={palette.neutral[800]}
      showCurrency={showCurrency}
      style={{
        width: "25%",
        textAlign: "right",
        ...injectedStyle,
      }}
      testId={testId}
    />
  );
};
