import { Text, TruncateText } from "@common/Text";
import { IParsedData } from "./data.types";
import { palette } from "@palette";
import { Stack, styled } from "@mui/material";
import Section from "./components/Section";
import TopLocationsEmptyState from "./components/TopLocationsEmptyState";
import LocationStat from "./components/LocationStat";
import { VerticalBarChart } from "@components/charts";
import { AMOUNTS_REGION, shouldBeHidden } from "@constants/constants";
import GeoMapChart from "@components/charts/GeoMapChart/GeoMapChart";
import EmptyChart from "@components/charts/EmptyStates/EmptyChart";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

interface SidePanelBodyProps {
  isExpanded: boolean;
  data: IParsedData;
  isEmpty: boolean;
}

const SidePanelBody = ({ data, isEmpty }: SidePanelBodyProps) => {
  const { locationStats, stats, customersChart, geoMapChart } = data;
  return (
    <>
      <FadeUpWrapper delay={100}>
        <Text
          color={palette.black[100]}
          fontSize="32px"
          fontWeight="book"
          lineHeight="38px"
          letterSpacing="-0.32px"
        >
          Analytics
        </Text>
      </FadeUpWrapper>
      <Stack direction="column" gap={2} alignItems="stretch">
        <FadeUpWrapper delay={150}>
          <Text
            color={palette.black[100]}
            fontSize="18px"
            fontWeight="book"
            lineHeight="21.6px"
            letterSpacing="-0.18px"
            marginBottom="4px"
          >
            Total Customers
          </Text>
          <Stack direction="row" gap={1} alignItems="flex-start" flexGrow={1}>
            {Object.values(stats).map(({ label, value }) => {
              return <StatCard label={label} key={label} value={value} />;
            })}
          </Stack>
        </FadeUpWrapper>
        <FadeUpWrapper delay={200}>
          {isEmpty ? (
            <EmptyChart />
          ) : (
            <VerticalBarChart isCustomer height={220} data={customersChart} />
          )}
        </FadeUpWrapper>
      </Stack>
      {!shouldBeHidden.customersLocations && (
        <Stack direction="column" gap="24px" alignItems="stretch" flexGrow={1}>
          <FadeUpWrapper delay={250}>
            <Section title="Customers">
              <GeoMapChart
                data={geoMapChart}
                containerSx={{
                  backgroundColor: palette.liftedWhite[100],
                  borderRadius: "12px",
                }}
              />
            </Section>
          </FadeUpWrapper>
          <FadeUpWrapper delay={300}>
            <Section title="Top 10 Locations" containerSx={{ flexGrow: 1 }}>
              <Stack
                direction="column"
                gap="12px"
                alignItems="stretch"
                flexGrow={1}
              >
                {locationStats.length === 0 ? (
                  <TopLocationsEmptyState />
                ) : (
                  locationStats.map(({ total, name, percentage }) => {
                    return (
                      <LocationStat
                        key={name}
                        total={total}
                        name={name}
                        percentage={percentage}
                      />
                    );
                  })
                )}
              </Stack>
            </Section>
          </FadeUpWrapper>
        </Stack>
      )}
    </>
  );
};

const StatCard = ({ label, value }: { label: string; value: number }) => {
  return (
    <StatCardContainer>
      <Text
        color={palette.gray[300]}
        fontSize="14px"
        fontWeight="book"
        lineHeight="16.8px"
      >
        {label}
      </Text>
      <TruncateText
        lineClamp={1}
        color={palette.black[300]}
        fontSize="18px"
        fontWeight="book"
        lineHeight="21.6px"
        letterSpacing="-0.18px"
      >
        {value.toLocaleString(AMOUNTS_REGION)}
      </TruncateText>
    </StatCardContainer>
  );
};

const StatCardContainer = styled(Stack)(() => ({
  padding: "12px",
  borderRadius: "8px",
  border: `1px solid ${palette.liftedWhite[100]}`,
  backgroundColor: palette.neutral.white,
  flexDirection: "column",
  gap: "4px",
  alignItems: "stretch",
  flexGrow: 1,
  userSelect: "none",
}));

export default SidePanelBody;
