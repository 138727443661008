import * as React from "react";
import { palette } from "@palette";
import { Modal } from "@common/Modal";
import { RevenuesTable } from "./RevenuesTable";
import { IconButton } from "@common/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// assets
import { FundsIcon, SortIcon } from "@assets/icons";
import { Stack } from "@mui/material";
import MobileRevenueCard from "./MobileRevenueCard";
// data
import { tableData, RevenuesData } from "./data";
import MobileRevenueDetails from "./MobileRevenueDetails";
import MobileRevenueSortMenu from "./MobileRevenueSortMenu";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";

type RevenuesProps = {
  type: string;
  VisitorsViews: boolean;
  Visitors: boolean;
};

type MobileCardsProps = {
  anchorEl: HTMLElement | null;
  isSwitchDetails: boolean;
  type: string;
  detailsData: RevenuesData | null;
  setDetailsData: React.Dispatch<React.SetStateAction<RevenuesData | null>>;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setSelectedSortIcon: React.Dispatch<React.SetStateAction<JSX.Element>>;
  setIsSwitchDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileCards = ({
  anchorEl,
  isSwitchDetails,
  type,
  detailsData,
  setAnchorEl,
  setSelectedSortIcon,
  setIsSwitchDetails,
  setDetailsData,
}: MobileCardsProps) => {
  return (
    <>
      <MobileRevenueSortMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setSelectedSortIcon={setSelectedSortIcon}
      />
      {isSwitchDetails ? (
        <MobileRevenueDetails
          setIsSwitchDetails={setIsSwitchDetails}
          data={detailsData}
          title={
            type === "Membership" ? "General Membership" : "New Donation Form"
          }
          type={type}
        />
      ) : (
        <Stack spacing={1}>
          {tableData.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                setIsSwitchDetails(!isSwitchDetails);
                setDetailsData(item);
              }}
            >
              <MobileRevenueCard type={type} data={item} />
            </div>
          ))}
        </Stack>
      )}
    </>
  );
};

const Revenues = NiceModal.create(
  ({ type, VisitorsViews, Visitors }: RevenuesProps) => {
    const modal = useModal();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [selectedSortIcon, setSelectedSortIcon] = React.useState(
      <SortIcon />,
    );
    const [isSwitchDetails, setIsSwitchDetails] = React.useState(false);
    const [detailsData, setDetailsData] = React.useState<RevenuesData | null>(
      null,
    );

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseModal = () => {
      modal.hide();
    };

    return (
      <Modal
        {...muiDialogV5(modal)}
        width="780px"
        title="Revenue Sources"
        titleIcon={
          <FundsIcon stroke={palette.neutral[600]} width={42} height={40} />
        }
        onClose={handleCloseModal}
        sortIconButton={
          !isDesktop && !isSwitchDetails && (
            <IconButton aria-label="sort" size="small" onClick={handleOpenMenu}>
              {selectedSortIcon}
            </IconButton>
          )
        }
      >
        {isDesktop ? (
          <RevenuesTable
            type={type}
            VisitorsViews={VisitorsViews}
            Visitors={Visitors}
          />
        ) : (
          <MobileCards
            anchorEl={anchorEl}
            isSwitchDetails={isSwitchDetails}
            type={type}
            detailsData={detailsData}
            setAnchorEl={setAnchorEl}
            setDetailsData={setDetailsData}
            setIsSwitchDetails={setIsSwitchDetails}
            setSelectedSortIcon={setSelectedSortIcon}
          />
        )}
      </Modal>
    );
  },
);

export default Revenues;
