import { useFileUploadContext } from "../FileUploadContext";
import { FileUploadStatus } from "../types";

const useLocalUpload = () => {
  const { setSnackbarFiles, onUploadProgress } = useFileUploadContext();

  const uploadFile = (file: File) => {
    const customFile = fileParser(file);
    setSnackbarFiles([customFile]);

    // Simulate a local upload with a promise
    const uploadPromise = new Promise((resolve) => {
      const totalSteps = 100;
      let currentStep = 0;

      const interval = setInterval(() => {
        currentStep += 2;
        onUploadProgress({ fileId: file.name, progress: currentStep });

        if (currentStep >= totalSteps) {
          clearInterval(interval);
          resolve(undefined);
        }
      }, 50);
    });

    uploadPromise;
  };

  const updateCustomFile = (status: FileUploadStatus, fileId: string) => {
    setSnackbarFiles((prev) =>
      prev.map((f) => {
        if (f.id === fileId) {
          return {
            ...f,
            status,
          };
        }
        return f;
      }),
    );
  };

  const onUploadSuccess = (fileId: string) =>
    updateCustomFile(FileUploadStatus.UPLOADED, fileId);

  const onUploadError = (fileId: string) =>
    updateCustomFile(FileUploadStatus.FAILED, fileId);

  return {
    uploadFile,
    onUploadSuccess,
    onUploadError,
  };
};

export default useLocalUpload;

const fileParser = (file: File) => ({
  id: file.name,
  name: file.name,
  size: file.size,
  status: "Uploading...",
  uploadProgress: 0,
  canBeDeleted: false,
});
