import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { MSP, TStatusReview } from "../../data.types";
import { useStatusReview } from "../../hooks/useStatusReview";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useResyncMSP } from "../../hooks/useResyncMSP";
import SuspendedOFACWarning from "./components/SuspendedOFACWarning";
import AbleToComponent from "./components/AbleToComponent";
import {
  EnterpriseActionsHistory,
  MerchantActionsHistory,
} from "./components/ActionsHistory";
import SuspendResumeButton from "./components/SuspendResumeButton.mobile";

type Props = {
  data: TStatusReview;
  mspStatus: MSP;
  isEnterprise?: boolean;
};

const StatusReviewActions = ({ data, mspStatus, isEnterprise }: Props) => {
  const {
    handleChangeProcessing,
    handleChangeTransfer,
    handleSuspend,
    methods,
    isMerchantApproved,
    isSupspenLoading,
  } = useStatusReview({ data });
  const { watch } = methods;
  const values = watch();
  const { isMobileView } = useCustomTheme();
  const { isLoading, handleResyncMSP } = useResyncMSP(data.accID);

  const isStatusNameCanonic = ["declined", "suspended", "approved"].includes(
    data.statusName,
  );

  const isApproved = data.statusName === "approved";
  const isSuspended = data.statusName === "suspended";

  return (
    <Container>
      <SuspendedOFACWarning isConfirmedMatch={data.isOFACConfirmedMatch} />
      <Stack
        spacing={3}
        direction={
          isMobileView && !isStatusNameCanonic ? "column-reverse" : "column"
        }
        paddingTop={
          isStatusNameCanonic ? "16px" : isMobileView ? "24px" : "32px"
        }
      >
        {isEnterprise ? (
          <EnterpriseActionsHistory data={data} />
        ) : (
          <MerchantActionsHistory data={data} />
        )}
        {!isEnterprise && (
          <AbleToComponent
            handleChangeProcessing={handleChangeProcessing}
            handleChangeTransfer={handleChangeTransfer}
            processValue={values.process}
            transferValue={values.transfer}
            isApproved={isApproved}
            handleResyncMSP={handleResyncMSP}
            isSuspended={isSuspended}
            isMerchantApproved={isMerchantApproved}
            allowTransfers={data.allowedToTransfer}
            isLoading={isLoading}
            lastMSPSyncAt={mspStatus.lastMSPSyncAt}
            canSyncMSPData={mspStatus.canSyncMSPData}
          />
        )}
      </Stack>
      <SuspendResumeButton
        statusName={data.statusName}
        onClick={handleSuspend}
        loading={isSupspenLoading}
      />
    </Container>
  );
};

const Container = styled(Box)({
  display: "flex",
  padding: "0 16px 16px 16px",
  flexDirection: "column",
  background: palette.neutral["white"],
  borderRadius: "0 0 12px 12px",
  position: "relative",
});

export default StatusReviewActions;
