import React from "react";

type Props = {
  height?: string | number;
  width?: string | number;
  color?: string;
}

const UploadIcon = ({ color = "#8F8F8F", height = 25, width = 25 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7881 15.8309V22.8309C24.7881 23.3613 24.5774 23.87 24.2023 24.2451C23.8272 24.6201 23.3185 24.8309 22.7881 24.8309H2.78809C2.25765 24.8309 1.74894 24.6201 1.37387 24.2451C0.9988 23.87 0.788086 23.3613 0.788086 22.8309V15.8309C0.788086 15.5656 0.893443 15.3113 1.08098 15.1238C1.26852 14.9362 1.52287 14.8309 1.78809 14.8309C2.0533 14.8309 2.30766 14.9362 2.49519 15.1238C2.68273 15.3113 2.78809 15.5656 2.78809 15.8309V22.8309H22.7881V15.8309C22.7881 15.5656 22.8934 15.3113 23.081 15.1238C23.2685 14.9362 23.5229 14.8309 23.7881 14.8309C24.0533 14.8309 24.3077 14.9362 24.4952 15.1238C24.6827 15.3113 24.7881 15.5656 24.7881 15.8309ZM8.49559 7.53837L11.7881 4.24461V15.8309C11.7881 16.0961 11.8934 16.3504 12.081 16.538C12.2685 16.7255 12.5229 16.8309 12.7881 16.8309C13.0533 16.8309 13.3077 16.7255 13.4952 16.538C13.6827 16.3504 13.7881 16.0961 13.7881 15.8309V4.24461L17.0806 7.53837C17.2682 7.72601 17.5227 7.83142 17.7881 7.83142C18.0534 7.83142 18.3079 7.72601 18.4956 7.53837C18.6832 7.35072 18.7886 7.09623 18.7886 6.83086C18.7886 6.5655 18.6832 6.31101 18.4956 6.12336L13.4956 1.12336C13.4027 1.03039 13.2924 0.956629 13.171 0.906305C13.0496 0.855981 12.9195 0.830078 12.7881 0.830078C12.6567 0.830078 12.5265 0.855981 12.4051 0.906305C12.2837 0.956629 12.1735 1.03039 12.0806 1.12336L7.08059 6.12336C6.89294 6.31101 6.78753 6.5655 6.78753 6.83086C6.78753 7.09623 6.89294 7.35072 7.08059 7.53837C7.26823 7.72601 7.52272 7.83142 7.78809 7.83142C8.05345 7.83142 8.30795 7.72601 8.49559 7.53837Z"
        fill={color}
      />
    </svg>
  );
};

export default UploadIcon;
