const DocumentationIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99979 6.5V10.5C5.99979 10.6326 5.94711 10.7598 5.85334 10.8536C5.75957 10.9473 5.6324 11 5.49979 11C5.36718 11 5.24 10.9473 5.14624 10.8536C5.05247 10.7598 4.99979 10.6326 4.99979 10.5V7.7075L0.85354 11.8538C0.807085 11.9002 0.751934 11.9371 0.691238 11.9622C0.630541 11.9873 0.565487 12.0003 0.49979 12.0003C0.434092 12.0003 0.369038 11.9873 0.308342 11.9622C0.247645 11.9371 0.192495 11.9002 0.14604 11.8538C0.0995846 11.8073 0.0627345 11.7521 0.0375931 11.6914C0.0124518 11.6308 -0.000488281 11.5657 -0.000488281 11.5C-0.000488281 11.4343 0.0124518 11.3692 0.0375931 11.3086C0.0627345 11.2479 0.0995846 11.1927 0.14604 11.1462L4.29229 7H1.49979C1.36718 7 1.24 6.94732 1.14624 6.85355C1.05247 6.75979 0.99979 6.63261 0.99979 6.5C0.99979 6.36739 1.05247 6.24021 1.14624 6.14645C1.24 6.05268 1.36718 6 1.49979 6H5.49979C5.6324 6 5.75957 6.05268 5.85334 6.14645C5.94711 6.24021 5.99979 6.36739 5.99979 6.5ZM10.9998 0H2.99979C2.73457 0 2.48022 0.105357 2.29268 0.292893C2.10515 0.48043 1.99979 0.734784 1.99979 1V4C1.99979 4.13261 2.05247 4.25979 2.14624 4.35355C2.24 4.44732 2.36718 4.5 2.49979 4.5C2.6324 4.5 2.75957 4.44732 2.85334 4.35355C2.94711 4.25979 2.99979 4.13261 2.99979 4V1H10.9998V9H7.99979C7.86718 9 7.74 9.05268 7.64624 9.14645C7.55247 9.24021 7.49979 9.36739 7.49979 9.5C7.49979 9.63261 7.55247 9.75979 7.64624 9.85355C7.74 9.94732 7.86718 10 7.99979 10H10.9998C11.265 10 11.5194 9.89464 11.7069 9.70711C11.8944 9.51957 11.9998 9.26522 11.9998 9V1C11.9998 0.734784 11.8944 0.48043 11.7069 0.292893C11.5194 0.105357 11.265 0 10.9998 0Z"
        fill="#343330"
      />
    </svg>
  );
};


export default DocumentationIcon