type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const GridIcon = ({
  width = 32,
  height = 32,
  fill = "#8F8F8F",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M6.66667 22.6667C5.2 22.6667 4 23.8667 4 25.3333C4 26.8 5.2 28 6.66667 28C8.13333 28 9.33333 26.8 9.33333 25.3333C9.33333 23.8667 8.13333 22.6667 6.66667 22.6667ZM16 9.33333C17.4667 9.33333 18.6667 8.13333 18.6667 6.66667C18.6667 5.2 17.4667 4 16 4C14.5333 4 13.3333 5.2 13.3333 6.66667C13.3333 8.13333 14.5333 9.33333 16 9.33333ZM6.66667 13.3333C5.2 13.3333 4 14.5333 4 16C4 17.4667 5.2 18.6667 6.66667 18.6667C8.13333 18.6667 9.33333 17.4667 9.33333 16C9.33333 14.5333 8.13333 13.3333 6.66667 13.3333ZM16 13.3333C14.5333 13.3333 13.3333 14.5333 13.3333 16C13.3333 17.4667 14.5333 18.6667 16 18.6667C17.4667 18.6667 18.6667 17.4667 18.6667 16C18.6667 14.5333 17.4667 13.3333 16 13.3333ZM16 22.6667C14.5333 22.6667 13.3333 23.8667 13.3333 25.3333C13.3333 26.8 14.5333 28 16 28C17.4667 28 18.6667 26.8 18.6667 25.3333C18.6667 23.8667 17.4667 22.6667 16 22.6667ZM6.66667 4C5.2 4 4 5.2 4 6.66667C4 8.13333 5.2 9.33333 6.66667 9.33333C8.13333 9.33333 9.33333 8.13333 9.33333 6.66667C9.33333 5.2 8.13333 4 6.66667 4Z"
      fill={fill}
    />
    <path
      d="M24.6667 22.6667C23.2 22.6667 22 23.8667 22 25.3333C22 26.8 23.2 28 24.6667 28C26.1333 28 27.3333 26.8 27.3333 25.3333C27.3333 23.8667 26.1333 22.6667 24.6667 22.6667ZM24.6667 13.3333C23.2 13.3333 22 14.5333 22 16C22 17.4667 23.2 18.6667 24.6667 18.6667C26.1333 18.6667 27.3333 17.4667 27.3333 16C27.3333 14.5333 26.1333 13.3333 24.6667 13.3333ZM24.6667 4C23.2 4 22 5.2 22 6.66667C22 8.13333 23.2 9.33333 24.6667 9.33333C26.1333 9.33333 27.3333 8.13333 27.3333 6.66667C27.3333 5.2 26.1333 4 24.6667 4Z"
      fill={fill}
    />
    </svg>
  )
}

export default GridIcon;
