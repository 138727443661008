import { TagType } from "@common/Tag/TransactionTableTag";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { showMessage } from "@common/Toast";
import { getStatus, getTransaction } from "./utils";
import { checkPortals } from "@utils/routing";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

export const useTransactionHistory = (
  id: string,
  isHistoryEnabled: boolean,
  updatedData?: any,
  isModalVisible?: boolean,
) => {
  const [tabs, setTabs] = useState<TagType[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { isTransfersPage } = checkPortals();

  const isAllowedViewTransaction = useAccessControl({
    resource: RESOURCE_BASE.TRANSACTION,
    operation: OPERATIONS.READ,
    withPortal: true,
  });

  const processData = (thxData: any, initial = false) => {
    const { displayStatus, processingState, originalTransactionID } = thxData;
    const normalizedStatus = getStatus(displayStatus, processingState);

    if (initial) {
      setTabs([normalizedStatus as TagType]);
    } else {
      setTabs((prev) => [...prev, normalizedStatus as TagType]);
    }
    return originalTransactionID;
  };

  const getHistory = async (previousThxId: string): Promise<any[]> => {
    const thxData = await getTransaction(previousThxId);
    const prevThxID = processData(thxData);

    if (prevThxID) {
      const prevHistory = await getHistory(prevThxID);
      return [thxData, ...prevHistory];
    }
    return [thxData];
  };

  const { data, error, isLoading } = useQuery(
    ["get-transaction-history", id, updatedData],
    async () => {
      setCurrentTab(0);
      const initialThx = await getTransaction(id, isTransfersPage);
      if (isTransfersPage) {
        const normalizedStatus = getStatus(
          initialThx?.displayStatus,
          initialThx?.processingState,
        );
        setTabs([normalizedStatus as TagType]);
        return [initialThx];
      }

      const prevThxID = processData(initialThx, true);

      if (prevThxID) {
        const history = await getHistory(prevThxID);
        return [initialThx, ...history];
      }
      return [initialThx];
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!id && isModalVisible && isAllowedViewTransaction,
    },
  );

  useEffect(() => {
    if (error)
      showMessage(
        "Error",
        "Whoops.. an error occurred while fetching the data",
      );
  }, [error]);

  const displayedData = data
    ? {
        ...data[currentTab],
        createdAt: data[data.length - 1]?.createdAt,
        updatedAt: data[currentTab]?.createdAt,
        sorceAccountFullName: data[data.length - 1]?.customerName,
      }
    : undefined;

  return {
    displayedData,
    isLoading,
    tabs,
    currentTab,
    setCurrentTab,
    tabsData: data,
  };
};

export type useTransactionHistoryReturnType = ReturnType<
  typeof useTransactionHistory
>;
