import { Text } from "@common/Text";
import { Box, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { EditIcon } from "@assets/icons/RebrandedIcons";
import NiceModal from "@ebay/nice-modal-react";
import { EDIT_ENTERPRISE_FEES_MODAL } from "modals/modal_names";
import {
  TFeeObject,
  TMerchantFees,
} from "@components/Merchants/MerchantPreview/data.types";
import { EditButton } from "@components/Merchants/MerchantPreview/components/atoms";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ConversationsIconNotFilled } from "@assets/icons";

export const calculateEnterpriseFeePerTransaction = (fee: TFeeObject) => {
  const val = (fee.adjustmentPercent + fee.value) / 100;
  return `${val}% + $${fee.adjustmentFixed / 100}`;
};

type Props = {
  id: number;
  data: TMerchantFees;
  parentFees: TMerchantFees;
};

const EnterpriseFees = ({ id, data, parentFees }: Props) => {
  const { isMobileView } = useCustomTheme();
  const { openConversationHandler } = useConversationsModal();
  const { merchant_underwriting } = useEnterprisePermissions();

  const onClick = () =>
    openConversationHandler({
      id: 0,
      name: "Provider Fees",
      paths: [],
    });
  const fees = [
    {
      label: "Credit Card Fee",
      fee: data.creditCardFee.value,
      feePerTransaction: calculateEnterpriseFeePerTransaction({
        ...parentFees.creditCardFee,
        value: data.creditCardFee.value,
      }),
    },
    {
      label: "Debit Card Fee",
      fee: data.debitCardFee.value,
      feePerTransaction: calculateEnterpriseFeePerTransaction({
        ...parentFees.debitCardFee,
        value: data.debitCardFee.value,
      }),
    },
    {
      label: "AMEX Credit Card Fee",
      fee: data.amexCreditCardFee.value,
      feePerTransaction: calculateEnterpriseFeePerTransaction({
        ...parentFees.amexCreditCardFee,
        value: data.amexCreditCardFee.value,
      }),
    },
  ];

  const editHandler = () =>
    NiceModal.show(EDIT_ENTERPRISE_FEES_MODAL, { id, data, parentFees });

  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Stack
        direction="row"
        spacing={isMobileView ? 2 : 1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          color={palette.gray[300]}
          variant="h6"
          lineHeight="21.6px"
          letterSpacing="-0.18px"
        >
          Provider Fees
        </Text>
        {merchant_underwriting && (
          <EditButton onClick={onClick}>
            <ConversationsIconNotFilled section="bank-accounts" />
          </EditButton>
        )}
      </Stack>
      <Container>
        <Grid container rowSpacing="24px" columnSpacing="12px">
          {fees.map((fee) => (
            <Grid item xs={12} sm={6} key={fee.label}>
              <FeeCard {...fee} />
            </Grid>
          ))}
        </Grid>
        <Box position="absolute" top="16px" right="16px">
          <EditButton onClick={editHandler}>
            <EditIcon />
          </EditButton>
        </Box>
      </Container>
    </Stack>
  );
};

type TFeeCardProps = {
  feePerTransaction: string;
  fee: number;
  label: string;
};

const FeeCard = ({ feePerTransaction, fee, label }: TFeeCardProps) => {
  return (
    <Stack direction="column" gap="12px" width="100%">
      <Stack direction="column" gap="4px">
        <Text color={palette.black[100]} lineHeight="16.8px" fontWeight="book">
          {label}
        </Text>
        <Stack direction="row" gap="4px" alignItems="center">
          <Text
            color={palette.black[100]}
            variant="h6"
            lineHeight="21.6px"
            letterSpacing="-0.18px"
            fontWeight="book"
          >
            {fee}
          </Text>
          <Text
            color={palette.gray[200]}
            fontWeight="light"
            lineHeight="16.8px"
          >
            BPS
          </Text>
        </Stack>
      </Stack>
      <Text
        color={palette.gray[300]}
        variant="caption"
        fontWeight="book"
        lineHeight="14.4px"
      >
        {feePerTransaction} / fee per transaction
      </Text>
    </Stack>
  );
};

const Container = styled(Stack)(() => ({
  flexDirection: "row",
  gap: "4px",
  alignItems: "flex-start",
  borderRadius: "8px",
  background: palette.neutral.white,
  padding: "16px",
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
  paddinRight: "24px",
  position: "relative",

  "&:hover": {
    "& .MuiButtonBase-root.MuiButton-root": {
      visibility: "visible",
    },
  },
}));

export default EnterpriseFees;
