import React, { createContext, useContext } from "react";
import useThemeManager from "../hooks/useThemeManager";

type ThemeTypes = ReturnType<typeof useThemeManager>;
const CustomThemeContext = createContext<ThemeTypes>({} as any);

function CustomThemeProvider({ children }: { children: React.ReactNode }) {
  const data = useThemeManager();
  return (
    <CustomThemeContext.Provider value={data}>
      {children}
    </CustomThemeContext.Provider>
  );
}

export default CustomThemeProvider;

export const useThemeContext = () => useContext(CustomThemeContext);
