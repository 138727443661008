import {
  ChangelogData,
  useGetChangelog,
} from "@services/api/changelog/changelog";
import { useEffect, useMemo, useRef, useState } from "react";
import useChangelogFilters from "./useChangelogFilters";

export const useChangelogData = ({
  id,
  enabled = true,
  isOpen,
}: {
  id: number;
  enabled?: boolean;
  isOpen: boolean;
}) => {
  const [data, setData] = useState<ChangelogData[]>([]);
  const [page, setPage] = useState(1);
  const { filtersQuery, filtersForm, resetFilters } = useChangelogFilters();

  const {
    data: pageData,
    isLoading,
    isFetching,
    refetch,
  } = useGetChangelog({
    id,
    page,
    filter: filtersQuery,
    sorting: "-changeDate",
    enabled,
  });
  const searchQueryRef = useRef(false);
  const pageRef = useRef(1);
  const showMoreDataAvailable = useMemo(() => {
    return data.length > 0 && data.length < pageData?.total;
  }, [data, pageData?.total]);

  useEffect(() => {
    if (!isOpen) {
      setPage(1);
      setData([]);
      resetFilters();
    }
    if (isOpen && data.length === 0 && enabled) refetch();
  }, [isOpen]);

  useEffect(() => {
    if (data.length > 0) {
      setData([]);
      setPage(1);
      searchQueryRef.current = true;
    }
  }, [filtersQuery]);

  useEffect(() => {
    if (pageData?.data && !isFetching) {
      if (searchQueryRef.current) {
        setData([...(pageData?.data || [])]);
        searchQueryRef.current = false;
      } else {
        if (data.length === 0 && pageData?.data) {
          setData(pageData.data);
        } else if (data.length > 0) {
          //if the page was not updated it means query was invalidated
          if (pageRef.current !== page) {
            setData((prev) => [...prev, ...pageData.data]);
            pageRef.current = page;
          } else {
            setData(pageData.data);
          }
        }
      }
    }
  }, [pageData, isFetching]);

  const handleLoadMore = () => {
    if (isLoading || pageData?.data === null) return;
    setPage((prev) => prev + 1);
  };

  return {
    handleLoadMore,
    data,
    isLoading: isLoading || (data.length === 0 && isFetching),
    isMoreData: pageData?.data !== null,
    showMoreDataAvailable,
    filtersForm,
    isFiltersApplied: !!filtersQuery,
  };
};
