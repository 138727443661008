import * as React from "react";
// mui
import theme from "@theme/index";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText";
// components
import { Text } from "@common/Text";
// form
import { useFormContext, Controller } from "react-hook-form";
import { Switch_V2 } from "./Switch_V2";

export type SwitchProps = MuiSwitchProps & {
  label?: React.ReactNode;
  helperText?: string;
};

const focusedStyles: SxProps<Theme> = {
  "&  .MuiSwitch-track::before": {
    content: '""',
    width: "calc(100%)",
    height: "calc(100%)",
    borderRadius: 26 / 2,
    backgroundColor: "transparent",
    position: "absolute",
    opacity: 1,
    transition: theme.transitions.create(["boxShadow"], {
      duration: 500,
    }),
    boxShadow: " 0px 0px 4px rgba(2, 2, 2, 0.25), 0px 0px 0px 3px #E6C1F7",
  },
};

const HelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== "size",
})<FormHelperTextProps & { size?: string }>(({ theme, size }) => ({
  fontSize: 14,
  marginTop: 0,
  fontWeight: 400,
  linHeight: "14px",
  textTransform: "none",
  color: theme.palette.neutral[500],
  marginLeft: size === "small" ? "26px" : theme.spacing(7.5),

  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    marginTop: "8px",
  },
}));

export const Switch = styled((props: SwitchProps) => {
  const [focused, setFocused] = React.useState(false);

  const onFocus = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    props.onFocus && props.onFocus(e);
    setFocused(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    props.onBlur && props.onBlur(e);
    setFocused(false);
  };

  return (
    <MuiSwitch
      onFocus={onFocus}
      onBlur={onBlur}
      disableRipple
      sx={focused ? focusedStyles : {}}
      {...props}
    />
  );
})(({ theme }) => ({
  width: 48,
  height: 26,
  padding: 0,
  overflow: "visible",

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: "#fff",

      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.neutral[50],
        opacity: 1,
        border: 0,
        "&::after": {
          backgroundColor: theme.palette.primary.main,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        // backgroundColor: theme.palette.neutral[100],
        boxShadow: "none",
        // opacity: 0.5,
        opacity: 1,
        "&::after": {
          backgroundColor: theme.palette.neutral[300],
        },
      },
    },

    // When hovering is unchecked
    "&:hover + .MuiSwitch-track::after": {
      backgroundColor: theme.palette.primary[100],
    },

    // When hovering is checked
    "&.Mui-checked:hover + .MuiSwitch-track::after": {
      backgroundColor: theme.palette.primary[500],
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      boxShadow: "none",
      opacity: 1,
      backgroundColor: theme.palette.neutral[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      // opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      boxShadow: "none",
      backgroundColor: theme.palette.neutral[300],
      opacity: 1,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0px 2px 4px rgba(16, 24, 40, 0.15)",
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },

  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.neutral[50],
    boxShadow: "inset 0px 1px 2px rgba(90, 101, 113, 0.25)",
    opacity: 1,

    "&::after": {
      content: '""',
      top: "5px",
      left: "5px",
      width: "calc(100% - 10px)",
      height: "calc(100% - 10px)",
      borderRadius: 26 / 2,
      backgroundColor: "transparent",
      position: "absolute",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function RHFSwitch({
  label,
  helperText,
  name,
  ...props
}: SwitchProps & { name: string }) {
  const { control } = useFormContext();

  return (
    <Box
      sx={{
        verticalAlign: "top",
        display: helperText ? "inline-block" : "inline",
      }}
    >
      <FormControlLabel
        label={
          label && typeof label === "string" ? (
            <Text pl={1.5} variant="headline">
              {label}
            </Text>
          ) : (
            <>{label}</>
          )
        }
        control={
          <Controller
            name={name}
            control={control}
            render={({ field: { ref, value, onBlur, ...rest } }) => (
              <>
                <Switch_V2
                  inputRef={ref}
                  {...rest}
                  checked={Boolean(value)}
                  {...props}
                />
              </>
            )}
          />
        }
        sx={{
          marginLeft: 0,
          alignItems: "flex-start",

          ...(!label && {
            marginRight: 0,
          }),
        }}
      />
      {helperText && <HelperText size={props.size}>{helperText}</HelperText>}
    </Box>
  );
}
