const CVCIcon = ({
  height = 25,
  width = 34,
}: {
  height?: number | string;
  width?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.209 0.5L2.79104 0.500011C2.05081 0.500011 1.3409 0.80234 0.817478 1.34049C0.294056 1.87864 0 2.60852 0 3.36958V4.3261L34 4.32609V3.36957C34 2.60851 33.7059 1.87862 33.1825 1.34048C32.6591 0.802328 31.9492 0.5 31.209 0.5ZM0 21.6304C0 22.3915 0.294056 23.1214 0.817478 23.6595C1.3409 24.1977 2.05081 24.5 2.79104 24.5L31.209 24.5C31.9492 24.5 32.6591 24.1977 33.1825 23.6595C33.7059 23.1214 34 22.3915 34 21.6304V6.23913L0 6.23914V21.6304ZM4.65174 16.8478H8.37313C8.61988 16.8478 8.85651 16.9486 9.03099 17.128C9.20546 17.3074 9.30348 17.5507 9.30348 17.8043C9.30348 18.058 9.20546 18.3013 9.03099 18.4807C8.85651 18.6601 8.61988 18.7609 8.37313 18.7609H4.65174C4.405 18.7609 4.16836 18.6601 3.99389 18.4807C3.81941 18.3013 3.72139 18.058 3.72139 17.8043C3.72139 17.5507 3.81941 17.3074 3.99389 17.128C4.16836 16.9486 4.405 16.8478 4.65174 16.8478Z"
        fill="#D1D1D0"
      />
      <path
        d="M26.3708 16.7073C26.3059 16.6832 26.2367 16.6719 26.1673 16.6742C26.098 16.6765 26.0297 16.6923 25.9665 16.7207C25.9033 16.7491 25.8464 16.7895 25.7991 16.8396C25.7517 16.8898 25.7148 16.9487 25.6905 17.0129L24.6018 19.883L23.513 17.0145C23.464 16.8849 23.365 16.7798 23.2376 16.7224C23.1102 16.665 22.965 16.66 22.8338 16.7084C22.7027 16.7568 22.5964 16.8547 22.5383 16.9806C22.4802 17.1065 22.475 17.25 22.524 17.3796L24.1073 21.5519C24.1449 21.6515 24.2124 21.7374 24.3008 21.7981C24.3892 21.8587 24.4942 21.8912 24.6018 21.8912C24.7094 21.8912 24.8144 21.8587 24.9028 21.7981C24.9912 21.7374 25.0587 21.6515 25.0963 21.5519L26.6796 17.3796C26.704 17.3154 26.7154 17.2471 26.7132 17.1786C26.7109 17.11 26.6949 17.0426 26.6663 16.9802C26.6376 16.9177 26.5967 16.8615 26.546 16.8147C26.4953 16.7679 26.4358 16.7314 26.3708 16.7073ZM28.2628 17.0129C28.2386 16.9488 28.2017 16.8899 28.1545 16.8398C28.1072 16.7897 28.0504 16.7493 27.9874 16.7209C27.9243 16.6924 27.8562 16.6766 27.7869 16.6742C27.7176 16.6717 27.6485 16.6828 27.5836 16.7068C27.5187 16.7308 27.4591 16.7672 27.4084 16.8139C27.3577 16.8606 27.3168 16.9167 27.288 16.979C27.2593 17.0413 27.2432 17.1087 27.2408 17.1771C27.2383 17.2456 27.2495 17.3138 27.2738 17.378L28.8571 21.5503C28.8947 21.6499 28.9622 21.7358 29.0505 21.7965C29.1389 21.8571 29.244 21.8897 29.3516 21.8897C29.4592 21.8897 29.5642 21.8571 29.6526 21.7965C29.741 21.7358 29.8085 21.6499 29.8461 21.5503L31.4293 17.378C31.4536 17.3138 31.4648 17.2456 31.4624 17.1771C31.4599 17.1087 31.4439 17.0413 31.4151 16.979C31.3863 16.9167 31.3454 16.8606 31.2947 16.8139C31.244 16.7672 31.1845 16.7308 31.1195 16.7068C31.0546 16.6828 30.9855 16.6717 30.9162 16.6742C30.847 16.6766 30.7788 16.6924 30.7158 16.7209C30.6527 16.7493 30.5959 16.7897 30.5487 16.8398C30.5014 16.8899 30.4646 16.9488 30.4403 17.0129L29.3516 19.883L28.2628 17.0129Z"
        fill="#8F8F8F"
      />
      <path
        d="M20.3797 17.7176C20.6392 17.7176 20.8897 17.8121 21.0832 17.983C21.1344 18.0311 21.1948 18.0684 21.2609 18.093C21.3269 18.1175 21.3973 18.1287 21.4679 18.1259C21.5384 18.1231 21.6076 18.1063 21.6715 18.0766C21.7353 18.0468 21.7925 18.0047 21.8396 17.9528C21.8867 17.9008 21.9228 17.8401 21.9457 17.7741C21.9686 17.7081 21.9779 17.6383 21.973 17.5687C21.9681 17.4991 21.9492 17.4312 21.9173 17.369C21.8853 17.3068 21.8411 17.2515 21.7872 17.2065C21.4835 16.938 21.1077 16.7621 20.7051 16.7C20.3025 16.638 19.8903 16.6924 19.5183 16.8567C19.1462 17.021 18.8303 17.2882 18.6086 17.6261C18.3868 17.9639 18.2688 18.358 18.2687 18.7606V19.8037C18.2688 20.2064 18.3868 20.6004 18.6086 20.9383C18.8303 21.2761 19.1462 21.5434 19.5183 21.7077C19.8903 21.872 20.3025 21.9264 20.7051 21.8643C21.1077 21.8023 21.4835 21.6264 21.7872 21.3579C21.8411 21.3129 21.8853 21.2576 21.9173 21.1954C21.9492 21.1332 21.9681 21.0653 21.973 20.9957C21.9779 20.9261 21.9686 20.8562 21.9457 20.7903C21.9228 20.7243 21.8867 20.6635 21.8396 20.6116C21.7925 20.5596 21.7353 20.5175 21.6715 20.4878C21.6076 20.4581 21.5384 20.4413 21.4679 20.4385C21.3973 20.4356 21.3269 20.4468 21.2609 20.4714C21.1948 20.4959 21.1344 20.5333 21.0832 20.5813C20.9313 20.7155 20.7434 20.8034 20.542 20.8344C20.3407 20.8653 20.1347 20.8381 19.9487 20.7559C19.7627 20.6736 19.6048 20.54 19.494 20.371C19.3831 20.2021 19.3242 20.0051 19.3242 19.8037V18.7606C19.3242 18.484 19.4354 18.2187 19.6333 18.0231C19.8313 17.8275 20.0998 17.7176 20.3797 17.7176Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default CVCIcon;
