import { useQuery } from "react-query";
import { customInstance } from "@services/api";
import { TClassification } from "@components/Merchants/MerchantPreview/data.types";

const getClassifications = (filter?: string) => {
  const url = filter
    ? `merchant-classes?filter=typeName:"${filter}"`
    : `merchant-classes`;
  return customInstance({
    url,
    method: "GET",
  });
};

export type TClassificationsAPIResponse = {
  total: number;
  data: TClassification[];
};

export function useBusinessClassification(
  organizationType: string,
  parser?: (data: TClassificationsAPIResponse) => any,
) {
  const { data, isLoading } = useQuery(
    ["organization-classifications-list", organizationType],
    async () => {
      const data = await getClassifications(organizationType);
      if (!data?.data) return { total: 0, data: [] };

      return parser ? parser(data) : data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading };
}
