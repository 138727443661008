import React from "react";

const MasterCardIcon = ({width, height}: {width?: number | string, height?: number | string}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.742188"
        width="25.7759"
        height="17.931"
        rx="3.36207"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5781 12.574C12.6935 13.3194 11.5462 13.7693 10.2926 13.7693C7.4947 13.7693 5.22656 11.5282 5.22656 8.76356C5.22656 5.99896 7.4947 3.75781 10.2926 3.75781C11.5462 3.75781 12.6935 4.20775 13.5781 4.9531C14.4626 4.20775 15.6099 3.75781 16.8635 3.75781C19.6614 3.75781 21.9295 5.99896 21.9295 8.76356C21.9295 11.5282 19.6614 13.7693 16.8635 13.7693C15.6099 13.7693 14.4626 13.3194 13.5781 12.574Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5781 12.5731C14.6671 11.655 15.3576 10.2889 15.3576 8.76356C15.3576 7.23818 14.6671 5.87216 13.5781 4.95402C14.4628 4.20812 15.6105 3.75781 16.8647 3.75781C19.6626 3.75781 21.9307 5.99896 21.9307 8.76356C21.9307 11.5282 19.6626 13.7693 16.8647 13.7693C15.6105 13.7693 14.4628 13.319 13.5781 12.5731Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5735 12.574C14.6632 11.6559 15.3541 10.2895 15.3541 8.76358C15.3541 7.2377 14.6632 5.87128 13.5735 4.95312C12.4839 5.87128 11.793 7.2377 11.793 8.76358C11.793 10.2895 12.4839 11.6559 13.5735 12.574Z"
        fill="#FF5E00"
      />
    </svg>
  );
};

export default MasterCardIcon;
