import * as React from "react";
import { palette } from "@palette";
import { Controller, useFormContext } from "react-hook-form";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { RHFCheckbox } from "@common/Checkbox";
import { DatePicker } from "@common/DatePickers";
import { GoogleMaps, GooglePlacesAutocomplete } from "@services/google-api";
// hooks
import useLoadGoogleScript from "@hooks/google-api/useLoadGoogleScript";
import { getUSNames } from "@utils/country_dial_codes";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { ZipCodeInput } from "@common/AddressInputs";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { DateType, LocationType } from "./types";
import { ChevronRight } from "@assets/icons/RebrandedIcons";
import useCountryCodes from "@hooks/common/useCountryCodes";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import ErrorCatcher from "@common/Error/ErrorCatcher";

const EventDateLocation = ({
  isModalMounted,
}: {
  isModalMounted?: boolean;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { isLoaded } = useLoadGoogleScript();

  const { selectCountriesList } = useCountryCodes();
  const showStateFieldCountry = "US";
  const { t } = useTranslation(namespaces.pages.eventsMinibuilder);

  const {
    watch,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const values = watch();

  const handleAddress = () => {
    clearErrors("location.location");
    setValue(
      "date_and_location.location.manual",
      !values.date_and_location.location.manual,
    );
  };

  const handleLocationChange = (value: string) => {
    if (values.date_and_location.location.location === value) return;
    setValue("date_and_location.location.location", value, {
      shouldDirty: true,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={1}>
        <FadeUpWrapper delay={100}>
          <Stack
            mb={3}
            gap={2}
            direction={isDesktop ? "row" : "column"}
            alignItems={isDesktop ? "center" : "normal"}
            justifyContent={isDesktop ? "space-between" : "flex-start"}
          >
            <Text fontSize={32} fontWeight="book" color={palette.neutral[80]}>
              When?
            </Text>

            <Stack direction="row" alignItems="center" sx={buttonsStyle}>
              <ButtonSwitch
                title="one day"
                label={t("one_day")}
                currentTitle={values.date_and_location.date.type}
                isDesktop={isDesktop}
                onClick={() =>
                  setValue("date_and_location.date.type", "one day")
                }
              />
              <ButtonSwitch
                title="period"
                label={t("period")}
                currentTitle={values.date_and_location.date.type}
                isDesktop={isDesktop}
                onClick={() =>
                  setValue("date_and_location.date.type", "period")
                }
              />
            </Stack>
          </Stack>
        </FadeUpWrapper>
        <FadeUpWrapper delay={200}>
          {values.date_and_location.date.type === "one day" ? (
            <Controller
              name="date_and_location.date.day"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <DatePicker
                  {...rest}
                  minDate={new Date().setDate(new Date().getDate() + 1)}
                  inputRef={ref}
                  inputFormat="MM/dd/yyyy"
                  inputFormatTime="MM/dd/yyyy 'at' hh:mm a"
                  time={values.date_and_location.date.include_time}
                  adornmentPosition="end"
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      inputRef={ref}
                      {...params}
                      error={!!error}
                      label={t("when_is_the_event")}
                      helperText={error?.message}
                      fullWidth
                      inputProps={{
                        ...inputProps,
                        placeholder: t("choose_a_date"),
                      }}
                    />
                  )}
                />
              )}
            />
          ) : (
            <Stack gap={2} direction={isDesktop ? "row" : "column"}>
              <Box width="100%">
                <Controller
                  name="date_and_location.date.startDate"
                  control={control}
                  rules={{
                    required: t("start_date_is_required"),
                  }}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      {...rest}
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      time={values.date_and_location.date.include_time}
                      inputFormatTime="MM/dd/yyyy 'at' hh:mm a"
                      inputFormat="MM/dd/yyyy"
                      adornmentPosition="end"
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          name="date_and_location.date.startDate"
                          inputRef={ref}
                          {...params}
                          fullWidth
                          error={!!error}
                          label={t("when_the_event_starts")}
                          helperText={error?.message}
                          inputProps={{
                            ...inputProps,
                            placeholder: t("select_a_date"),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box width="100%">
                <Controller
                  name="date_and_location.date.endDate"
                  control={control}
                  rules={{}}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      {...rest}
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      time={values.date_and_location.date.include_time}
                      inputFormat="MM/dd/yyyy"
                      inputFormatTime="MM/dd/yyyy 'at' hh:mm a"
                      adornmentPosition="end"
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          name="date_and_location.date.endDate"
                          inputRef={ref}
                          {...params}
                          fullWidth
                          label={t("when_the_event_ends")}
                          error={!!error}
                          helperText={error?.message}
                          inputProps={{
                            ...inputProps,
                            placeholder: t("select_a_date"),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Box>
            </Stack>
          )}
          <RHFCheckbox
            name="date_and_location.date.include_time"
            label={t("include_time")}
            dataTestId="include-time"
          />
        </FadeUpWrapper>
      </Stack>

      <FadeUpWrapper delay={250}>
        <Box my={2} width="100%" borderBottom="1px solid #DDDCE5" />
      </FadeUpWrapper>

      {/** --------------------------- LOCATION --------------------------- */}
      <Stack>
        <FadeUpWrapper delay={300}>
          <Stack
            mb={3}
            gap={2}
            direction={isDesktop ? "row" : "column"}
            alignItems={isDesktop ? "center" : "normal"}
            justifyContent={isDesktop ? "space-between" : "flex-start"}
          >
            <Text fontSize={32} fontWeight="book" color={palette.neutral[80]}>
              Where?
            </Text>
            <Stack direction="row" alignItems="center" sx={buttonsStyle}>
              <ButtonSwitch
                title="in person"
                label={t("in_person")}
                currentTitle={values.date_and_location.location.type}
                isDesktop={isDesktop}
                onClick={() =>
                  setValue("date_and_location.location.type", "in person")
                }
              />
              <ButtonSwitch
                title="online"
                label={t("online")}
                currentTitle={values.date_and_location.location.type}
                isDesktop={isDesktop}
                onClick={() =>
                  setValue("date_and_location.location.type", "online")
                }
              />
            </Stack>
          </Stack>
        </FadeUpWrapper>

        <FadeUpWrapper delay={400}>
          {values.date_and_location.location.type === "in person" && (
            <ErrorCatcher errorID="GooglePlacesAutocomplete">
            <GooglePlacesAutocomplete
              disabled={values.date_and_location.location.manual}
              label={t("where_is_the_event")}
              initialValue={values.date_and_location.location.location}
              onValueChange={handleLocationChange}
              reset={isModalMounted}
              isLoaded={isLoaded}
              inputProps={{
                placeholder: "Type event location",
                error: Boolean(
                  (errors?.date_and_location as any)?.location?.location,
                ),
                helperText: (errors?.date_and_location as any)?.location
                  ?.location?.message,
              }}
              sx={{
                p: 0,
                mb: 1.5,
                width: "100%",
                "& .MuiInputBase-root": {
                  padding: "7.5px 12px",
                },
              }}
            />
            </ErrorCatcher>
          )}

          {values.date_and_location.location.type === "online" && (
            <RHFInput
              name="date_and_location.location.event_url"
              label={"Enter URL"}
              placeholder={t("enter_a_url")}
              fullWidth
              sx={{ mb: 1.5 }}
            />
          )}
        </FadeUpWrapper>
      </Stack>

      <FadeUpWrapper delay={400}>
        {values.date_and_location.location.type === "in person" &&
          !values.date_and_location.location.manual && (
            <Stack direction="column" gap={2}>
              {!isLoaded ? (
                <LoadingSpinner />
              ) : (
                <ErrorCatcher errorID="GoogleMaps">
                  <GoogleMaps
                    address={values.date_and_location.location.location}
                    isLoaded={isLoaded}
                  />
                </ErrorCatcher>
              )}
            </Stack>
          )}
      </FadeUpWrapper>

      {/** --------------------------- ENTER ADDRESS MANUALLY --------------------------- */}
      <FadeUpWrapper delay={500}>
        {values.date_and_location.location.type === "in person" && (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleAddress}
            mt={2}
            gap={1}
            sx={{
              cursor: "pointer",
              svg: {
                transition: "all ease .5s",
              },
            }}
          >
            <Text
              fontWeight="book"
              whiteSpace="nowrap"
              color={palette.black[100]}
            >
              {t("enter_address_manually")}
            </Text>
            <ChevronRight
              width={24}
              height={24}
              rotate={values.date_and_location.location.manual ? -90 : 90}
            />
          </Stack>
        )}

        {values.date_and_location.location.manual &&
          values.date_and_location.location.type === "in person" && (
            <Grid mt={1} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RHFSelect
                  name="date_and_location.location.country"
                  label="Country"
                  placeholder="Select a country"
                  options={selectCountriesList}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <RHFInput
                  name="date_and_location.location.address"
                  label={t("address")}
                  placeholder={t("type_address")}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <RHFInput
                  name="date_and_location.location.city"
                  label={t("city")}
                  placeholder={t("type_city")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {values.date_and_location.location.country ===
                showStateFieldCountry ? (
                  <RHFSelect
                    name="date_and_location.location.state"
                    label={t("state")}
                    placeholder={t("select_state")}
                    fullWidth
                    options={getUSNames().map((state) => ({
                      value: state,
                      label: state,
                    }))}
                  />
                ) : (
                  <RHFInput
                    name="date_and_location.location.state"
                    label="Province"
                    placeholder="Type in your Province"
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <ZipCodeInput
                  name="date_and_location.location.zip"
                  label={t("zip")}
                  placeholder={t("type_zip")}
                />
              </Grid>
            </Grid>
          )}
      </FadeUpWrapper>
    </Box>
  );
};

const buttonsStyle = {
  borderRadius: "8px",
  border: `1px solid ${palette.neutral[20]}`,
  padding: "4px",
  marginInline: "16px",
  background: "#FFF",
};

const ButtonSwitch = ({
  title,
  label,
  currentTitle,
  isDesktop,
  onClick,
}: {
  title: LocationType | DateType;
  label: LocationType | DateType;
  currentTitle: LocationType | DateType;
  isDesktop: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <Box
      sx={{
        width: 144,
        height: 36,
        fontSize: 16,
        fontWeight: 400,
        textAlign: "center",
        padding: "10px 4px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        textTransform: "capitalize",

        "&:first-of-type": {
          borderRadius: "8px",
        },

        ...(title === currentTitle && {
          color: palette.neutral.white,
          background: palette.black.main,
        }),

        ...(title !== currentTitle && {
          "&:hover": {
            cursor: "pointer",
          },
        }),

        ...(!isDesktop && {
          width: "100%",
        }),
      }}
      onClick={onClick}
    >
      {label}
    </Box>
  );
};

export default React.memo(EventDateLocation);
