import SectionBarItem, { SectionBarListItem } from "./SectionBarItem";
import { Box } from "@mui/material";
import React from "react";

type SectionBarProps<T> = {
  selectedTab: T;
  setTab: React.Dispatch<React.SetStateAction<T>>;
  sectionList: SectionBarListItem<T>[];
};

const SectionBar = <T extends string> ({ 
  selectedTab,
  setTab,
  sectionList,
}: SectionBarProps<T>) => {

  const handleClickItem = (tab: SectionBarListItem<T>) => {
    if (tab.disabled) return;
    setTab(tab.title);
  }

  return (
    <Box sx={containerStyle}>
      {sectionList.map((item: SectionBarListItem<T>, index: number) => (
        <SectionBarItem
          key={index}
          active={item.title === selectedTab}
          {...item}
          onClick={() => handleClickItem(item)}
        />
      ))}
    </Box>
  );
};

const containerStyle = {
  display: "flex",
  gap: "8px",
  mb: 3
};

export default SectionBar;
