import { Navigate } from "react-router-dom";
import { AUTH_PATHS } from "./paths";
import Login from "@pages/Login/Login";
import ResetPassword from "@pages/Login/ForgotPassword";
import EmailConfirmation from "@pages/EmailConfirmation";
import Signup from "@pages/Signup/Signup";
import { SessionExpired } from "@components/Login/SessionExpired";

const authRoutes = [
  {
    path: "/",
    children: [
      {
        element: <Navigate to={`/${AUTH_PATHS.LOGIN}`} replace />,
        index: true,
      },
      {
        path: AUTH_PATHS.LOGIN,
        element: <Login />,
      },
      {
        path: AUTH_PATHS.SIGNUP,
        element: <Signup />,
      },
      {
        path: AUTH_PATHS.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      // {
      //   path: AUTH_PATHS.SUCCESFUL_CHANGE_PASSWORD,
      //   element: <SuccessfullyChanged />,
      // },
      {
        path: AUTH_PATHS.VALIDATE_ACCOUNT,
        element: <EmailConfirmation />,
      },
      {
        path: AUTH_PATHS.SESSION_EXPIRED,
        element: <SessionExpired />,
      },
    ],
  },
];

export default authRoutes;
