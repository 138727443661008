import { ArrowCounterClockwiseIcon, RefreshIcon } from "@assets/icons";

type ErrorFallbackProps<T extends Error = Error> = {
  error: T;
  resetErrorBoundary: () => void;
} & {
  customMessage: string;
  shouldRetry: boolean;
};

const ErrorFallback = ({
  error,
  resetErrorBoundary,
  customMessage,
  shouldRetry,
}: ErrorFallbackProps) => {
  return (
    <div
      role="alert"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 12,
      }}
    >
      <p style={{ color: "inherit" }}>{customMessage || error.message}</p>
      {shouldRetry && (
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={resetErrorBoundary}
        >
          <ArrowCounterClockwiseIcon height={14} width={14} />
        </div>
      )}
    </div>
  );
};

export default ErrorFallback;
