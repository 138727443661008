import {
  DialogContent,
  DialogProps,
  SxProps,
  Theme,
  styled,
  Dialog,
} from "@mui/material";
import Draggable, {
  ControlPosition,
  DraggableEventHandler,
  DraggableProps,
} from "react-draggable";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import React, { useState } from "react";
import Header, { HeaderProps } from "./Header";
import SlideTransition from "@components/animation/SlideTransition";

const MODAL_WIDTH = "500px";
const MODAL_SHIFT = "25px";

type Props = {
  headerActions: {
    handleOpenModal: (open?: boolean) => void;
    onExpand?: (open?: boolean) => void;
  };
  children?: React.ReactElement;
  idBase?: string;
  draggableProps?: Partial<DraggableProps>;
  baseModalProps?: Partial<DialogProps>;
  headerElements?: React.ReactElement;
  subHeader?: React.ReactElement;
  baseHeaderProps: Partial<HeaderProps>;
  Content?: React.ReactElement;
  contentContainerSx?: SxProps<Theme>;
  open: boolean;
};

const DraggableModal = ({
  headerActions,
  children,
  idBase,
  draggableProps,
  baseModalProps,
  headerElements,
  baseHeaderProps,
  Content,
  contentContainerSx = {},
  subHeader,
  open,
}: Props) => {
  const { handleOpenModal, onExpand } = headerActions;
  const { isMobileView } = useCustomTheme();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [dragPosition, setDragPosition] = React.useState<ControlPosition>({
    x: 0,
    y: 0,
  });

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick") {
      if (isMobileView) {
        return handleOpenModal(false);
      }
      return;
    }
  };

  const resetVerticalPosition = () => {
    setDragPosition((prev) => ({
      ...prev,
      y: 0,
    }));
  };

  const handleExpandModal = () => {
    setIsExpanded((v) => {
      onExpand && onExpand(!v);
      if (!v) resetVerticalPosition();
      return !v;
    });
  };

  const handleDragStop: DraggableEventHandler = (e, data) => {
    setDragPosition({ x: data.x, y: data.y });
  };

  return (
    <Draggable
      handle="#handle"
      bounds="body"
      onStop={handleDragStop}
      position={dragPosition}
      {...draggableProps}
    >
      <StyledModal
        hideBackdrop={!isMobileView}
        disableEnforceFocus
        id={`${idBase}-modal`}
        open={open}
        onClose={handleClose}
        data-testid={`${idBase}-modal`}
        isMobileView={isMobileView}
        isExpanded={isExpanded}
        {...baseModalProps}
        TransitionComponent={SlideTransition}
      >
        <>
          {headerElements}
          <Header
            {...baseHeaderProps}
            handleExpandModal={handleExpandModal}
            handleOpenModal={handleOpenModal}
            title={baseHeaderProps.title || ""}
            testIdBase={idBase}
            imageSx={{ paddingLeft: "8px" }}
            isMobileView={isMobileView}
          />
          {subHeader}
          {Content ? (
            Content
          ) : (
            <DialogContent
              sx={{
                position: "relative",
                "& .MuiDialogContent-root, &.MuiDialogContent-root": {
                  paddingLeft: "8px !important",
                  paddingRight: "8px !important",
                },
                ...contentContainerSx,
              }}
            >
              {children}
            </DialogContent>
          )}
        </>
      </StyledModal>
    </Draggable>
  );
};

export default DraggableModal;

const StyledModal = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "isMobileView" && prop !== "isExpanded",
})<{
  isMobileView: boolean;
  isExpanded: boolean;
}>(({ isMobileView, isExpanded }) => ({
  position: "fixed",

  ...(isMobileView && {
    boxShadow: "0px 60px 180px 0px rgba(0, 0, 0, 0.15)",
  }),

  ...(!isMobileView && {
    width: MODAL_WIDTH,
    left: MODAL_SHIFT,
    borderRadius: "8px",
    top: !isExpanded ? `calc(100% - (${MODAL_WIDTH} + ${MODAL_SHIFT}))` : 0,
    maxHeight: !isExpanded ? MODAL_WIDTH : "100%",
    ...(isExpanded && {
      height: "calc(100% - 50px)",
      bottom: MODAL_SHIFT,
      top: "25px",
      ".MuiDialog-container.MuiDialog-scrollPaper": {
        height: "100%",
      },
      ".MuiPaper-root.MuiDialog-paper.MuiPaper-elevation.MuiPaper-rounded": {
        height: "100%",
        top: 0,
      },
    }),
    ...(!isExpanded && {
      ".MuiPaper-root.MuiDialog-paper.MuiPaper-elevation.MuiPaper-rounded": {
        top: 0,
        bottom: MODAL_SHIFT,
        maxHeight: "100%",
      },
    }),
  }),

  "& .MuiDialog-paper": {
    borderRadius: "16px",
    width: "500px",
    maxWidth: "500px",

    ...(isExpanded && {
      maxHeight: "100%",
      top: MODAL_SHIFT,
      bottom: MODAL_SHIFT,
      height: `calc(100% - (${MODAL_SHIFT} * 2))`,
    }),
  },
  ".MuiPaper-root": {
    ...(isMobileView && {
      width: "calc(100% - 16px)",
      height: "calc(100% - 116px)",
      bottom: "20px",
      borderRadius: "4px",
      top: "96px",
      maxHeight: "100%",
    }),
    ...(!isMobileView && {
      height: !isExpanded ? MODAL_WIDTH : "100%",
      border: `1px solid rgb(209, 209, 208)`,
      background: "rgba(255, 255, 255, 0.75)",
      boxShadow: "0px 12px 60px 0px rgba(0, 0, 0, 0.15)",
      backdropFilter: " blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
    }),
  },
}));
