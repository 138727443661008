import { Badge, Stack } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";

export const CustomBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    color: "#FFF",
    backgroundColor: palette.neutral.black,
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    left: "7px",
    top: "4px",
  },
  width: "100%",
}));

type CardContainerProps = {
  disabled?: boolean;
  selected?: boolean;
  hasCategory?: boolean;
};

export const CardContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "hasCategory"
})<CardContainerProps>(
  ({ theme, disabled, selected, hasCategory }) => ({
    background: "transparent",
    alignItems: "start",
    borderRadius: "12px",
    position: "relative",
    padding: "16px",
    width: "100%",

    border:
      selected && !hasCategory
        ? `1px solid ${palette.neutral.black}`
        : "1px solid #D1D1D0",
    ":hover": {
      cursor: selected ? "default" : "pointer",
      background: palette.neutral.white,
    },
    ...(selected && {
      backgroundColor: palette.neutral.white,
    }),
    ...(!disabled && {
      "&:hover": {
        boxShadow:
          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      },
    }),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  }),
);

export const SecondaryActionContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  animation: "inAnimation 250ms ease-in",
  "@keyframes inAnimation": {
    "0%": {
      opacity: 0,
      visibility: "hidden",
    },
    "100%": {
      opacity: 1,
      visibility: "visible",
    },
  },
}))