import { Stack } from "@mui/material";
import { palette } from "@palette";
import { ChangeItem, GroupType } from "@services/api/changelog/changelog";
import placeholder from "assets/images/Placeholder.png";
import {
  AvatarImage,
  ChipLabel,
  Container,
  Label,
  LabelValue,
} from "./LogItem.styles";
import { useMemo } from "react";
import moment from "moment";
import {
  createCustomAction,
  formatFieldLabel,
  formatFieldValue,
} from "./utils/logItemUtils";

interface Props {
  date: number;
  group: GroupType;
  name: string;
  imageUrl?: string;
  item: ChangeItem[];
  resourceName?: string;
  email?: string;
}

interface MappedItems extends ChangeItem {
  additionalField?: string | number;
  additionalTo?: string | number;
  additionalFrom?: string | number;
}

export default function LogItem({
  date,
  group,
  name,
  item,
  imageUrl,
  resourceName,
  email,
}: Props) {
  const createdBank =
    group === "Bank Account" &&
    createCustomAction(item[0]) === "add" &&
    item[0]?.fieldName !== "Document";
  const createdBusinessOwner =
    group === "Business Owner" && createCustomAction(item[0]) === "add";

  const usedItems: MappedItems[] = useMemo(() => {
    if (createdBank) {
      const bankItemsSorted = item.sort((a, b) => {
        if (a.fieldName < b.fieldName) {
          return -1;
        }
        if (a.fieldName > b.fieldName) {
          return 1;
        }
        return 0;
      });
      const used = [
        {
          fieldName: bankItemsSorted[1].fieldName,
          newValue: bankItemsSorted[1].newValue,
          oldValue: bankItemsSorted[1].oldValue,
          additionalField: bankItemsSorted[0].fieldName,
          additionalTo: bankItemsSorted[0].newValue,
          operation: bankItemsSorted[0].operation,
        },
        ...bankItemsSorted.slice(2).map((ix) => {
          return { ...ix, additionalField: bankItemsSorted[0].newValue };
        }),
      ];
      return used;
    } else if (createdBusinessOwner) {
      const fullnameField = item.find((xi) => xi.fieldName === "Full Name");
      const filteredItems = item
        .filter((xb) => xb.fieldName !== "Full Name")
        .map((xb) => {
          return {
            ...xb,
            additionalField: fullnameField?.newValue,
          };
        });
      const used = fullnameField
        ? [
            {
              newValue: fullnameField.newValue,
              operation: fullnameField.operation,
              fieldName: "",
              additionalField: "",
            },
          ]
        : filteredItems;
      return used;
    } else return item;
  }, [item]);

  return (
    <>
      <Stack direction="row" alignItems="center" gap="8px">
        <Label sx={{ lineHeight: "16.8px", color: palette.neutral[70] }}>
          {moment.unix(date).format("DD MMM YYYY, HH:mm")}
        </Label>
        <ChipLabel>{group}</ChipLabel>
      </Stack>
      <Container>
        <Stack direction="row" gap="12px" alignItems="center">
          <AvatarImage src={imageUrl ? `${imageUrl}/thumb` : placeholder} />
          <Label
            sx={{
              color: palette.neutral[90],
              maxLines: 2,
              lineHeight: "16.8px",
            }}
          >
            {name?.trim() ? name : email}
          </Label>
        </Stack>
        {usedItems.map((change) => {
          const action: Action = createCustomAction(change);
          const fieldName = change.fieldName?.toLowerCase();
          return buildDescription({
            action: action,
            to: formatFieldValue(change.newValue, fieldName),
            from: formatFieldValue(change.oldValue, fieldName),
            group: group,
            field: fieldName,
            additionalField: change?.additionalField ?? resourceName,
            additionalTo: change?.additionalTo,
          });
        })}
      </Container>
    </>
  );
}

type Action = "update" | "add" | "remove";

interface BuildDescriptionArgs {
  from?: string | number;
  to?: string | number;
  field: string | number;
  additionalField?: string | number;
  additionalTo?: string | number;
  group: GroupType;
  action: Action;
}

const buildDescription = ({
  from,
  to,
  group,
  field,
  action,
  additionalField,
  additionalTo,
}: BuildDescriptionArgs) => {
  switch (group) {
    case "Business Profile":
    case "Primary Account Holder":
    case "Merchant Info": {
      if (action === "update") {
        return (
          <ChangedLabel from={from} to={to} field={formatFieldLabel(field)} />
        );
      } else {
        const useFrom = action !== "add";
        return (
          <AddOrRemoveLabel
            to={useFrom ? from : to}
            field={field}
            switchOrder={group === "Business Profile"}
            remove={action !== "add"}
          />
        );
      }
    }
    case "Bank Account": {
      if (action === "update") {
        //update a field of an existing bank account
        return (
          <ChangedLabel
            from={from}
            to={to}
            field={additionalField ? `${additionalField} ${field}` : field}
          />
        );
      } else if (action === "add" && additionalTo) {
        //creation of a bank account with 'name' and 'field'
        return (
          <Label>
            Added bank account with {additionalField}{" "}
            <LabelValue>{additionalTo}</LabelValue> and {field}{" "}
            <LabelValue>{to}</LabelValue>
          </Label>
        );
      } else if (action === "add" && !additionalTo && field !== "document") {
        //addition of a filed that wa previously empty to an existing bank account
        return (
          <Label>
            Added {additionalField} {field} <LabelValue>{to}</LabelValue>
          </Label>
        );
      } else if (action === "remove" && !to) {
        //complete removal of bank account with 'name' and 'field'
        return (
          <Label>
            Removed bank account with account name{" "}
            <LabelValue>{additionalField}</LabelValue> and number
            <LabelValue>{from}</LabelValue>
          </Label>
        );
      } else if (action === "remove") {
        //deletion of data drom one field of a bank account
        return (
          <Label>
            Removed {additionalField} {field} <LabelValue>{to}</LabelValue>
          </Label>
        );
      } else {
        //document upload for bank account
        return (
          <Label>
            Added <LabelValue>{to}</LabelValue> {field} in the bank account
            section
          </Label>
        );
      }
    }
    case "Business Owner": {
      //addition of business owner
      if (action === "add") {
        const startText = additionalField
          ? `Added ${additionalField} ${field} `
          : "Added ";
        return (
          <Label>
            {startText}
            <LabelValue>{to}</LabelValue> in business owner
          </Label>
        );
      } else if (action === "remove" && !to)
        //deletion of business owner
        return (
          <Label>
            Removed <LabelValue>{additionalField}</LabelValue> in business owner
          </Label>
        );
      else if (action === "update") {
        //update of a business owner
        return (
          <ChangedLabel
            field={
              !additionalField || field === "full name"
                ? field
                : `${additionalField} ${field}`
            }
            to={to}
            from={from}
          />
        );
      } else {
        //addition or removal of data from business owner field
        const isRemove = action === "remove";
        return (
          <AddOrRemoveLabel
            field={`${additionalField} ${field}`}
            remove={isRemove}
            switchOrder
            to={isRemove ? from : to}
          />
        );
      }
    }
    case "Documents": {
      const addOrDelete = action === "remove" ? "Deleted" : "Added";
      const value = action === "remove" ? from : to;
      const customSx = {
        sx: {
          wordBreak: "break-all",
        },
      };
      return (
        <Label {...customSx}>
          {addOrDelete} document with filename
          <LabelValue {...customSx}>{value}</LabelValue>
        </Label>
      );
    }
    default:
      return <></>;
  }
};

const ChangedLabel = ({ from, to, field }: Partial<BuildDescriptionArgs>) => (
  <Label>
    Changed {formatFieldLabel(field)} from <LabelValue>{from}</LabelValue> to{" "}
    <LabelValue to>{to}</LabelValue>
  </Label>
);

const AddOrRemoveLabel = ({
  to,
  field,
  switchOrder,
  remove,
}: Partial<BuildDescriptionArgs> & {
  switchOrder?: boolean;
  remove?: boolean;
}) => {
  const addOrRemove = remove ? "Removed" : "Added";
  if (switchOrder) {
    return (
      <Label>
        {addOrRemove} <LabelValue>{to}</LabelValue> in {formatFieldLabel(field)}
      </Label>
    );
  } else
    return (
      <Label>
        {addOrRemove} {formatFieldLabel(field)}{" "}
        {!!to && <LabelValue>{to}</LabelValue>}
      </Label>
    );
};
