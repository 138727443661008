import { EditIcon } from "@assets/icons/RebrandedIcons";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { EDIT_AVATAR_MODAL } from "modals/modal_names";
import ImagePlaceholder from "@assets/images/avatar-placeholder.png";
import { TEditAvatarModalActions } from "./modal/EditAvatarModal";
import { IMAGE_UPLOAD_SIZE_LIMIT } from "@constants/constants";
import { showMessage } from "@common/Toast";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { FileUploadStatus } from "@components/UploadFile/types";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

interface Props extends Partial<TEditAvatarModalActions> {
  placeholder?: string;
  defaultImageURL?: string;
  size?: number;
  rounded?: boolean;
  subTitle?: string;
  disabled?: boolean;
  tooltipMessage?: string;
}

function AvatarUpload({
  placeholder = ImagePlaceholder,
  defaultImageURL,
  size = 48,
  rounded,
  subTitle,
  onUpload,
  onDelete,
  canUpload,
  canDelete = true,
  disabled = false,
  tooltipMessage = "",
}: Props) {
  const actions = {
    onUpload,
    onDelete,
    canUpload,
    canDelete: canDelete && !!defaultImageURL,
  };

  const { setSnackbarFiles } = useFileUploadContext();

  const currentImage = defaultImageURL || placeholder;
  const checkMaxSize = (file: File) => {
    const exceedsSize = file.size > IMAGE_UPLOAD_SIZE_LIMIT; //for upload Avatar we accept files <5mb
    const exceedsTitleLength = file.name.length > 254;
    if (exceedsSize) {
      setSnackbarFiles([
        {
          id: `${Date.now()}-${file.name}`,
          name: file.name,
          size: file.size,
          status: FileUploadStatus.FILE_TOO_LARGE,
          uploadProgress: 0,
          canBeDeleted: false,
        },
      ]);
    }
    if (exceedsTitleLength) showMessage("Error", "File name is too long");
    return exceedsSize || exceedsTitleLength;
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    NiceModal.show(EDIT_AVATAR_MODAL, {
      defaultImageURL: currentImage,
      rounded,
      subTitle,
      actions,
      onLocalUpload: checkMaxSize,
    });
  };

  return (
    <WithTooltipWrapper
      hasTooltip={!!tooltipMessage}
      tooltipProps={{
        show: disabled,
        message: tooltipMessage,
        placement: "left",
      }}
    >
      <Main
        size={size}
        rounded={rounded}
        data-testid="form-avatar-upload-box"
        disabled={disabled}
      >
        <AvatarImage src={currentImage} alt="avatar_image" />
        <Overlay onClick={handleClick} rounded={rounded} disabled={disabled}>
          <EditIconButton role="button">
            <EditIcon width={18} height={18} fill={palette.neutral.white} />
          </EditIconButton>
        </Overlay>
      </Main>
    </WithTooltipWrapper>
  );
}

export default AvatarUpload;

const AvatarImage = styled("img")(() => ({
  height: "100%",
  width: "100%",
  display: "block",
  borderRadius: "inherit",
}));

const Main = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "size" && prop !== "rounded" && prop !== "disabled",
})(
  (props: {
    size: number;
    rounded: boolean | undefined;
    disabled: boolean;
  }) => ({
    overflow: "hidden",
    position: "relative",
    transition: "border-radius .2s ease",
    height: `${props.size}px`,
    width: `${props.size}px`,
    borderRadius: props.rounded ? "50%" : "8px",
    ...(!props.disabled && {
      "&:hover": {
        "& > div": {
          opacity: 1,
          borderColor: palette.gray[100],
        },
      },
    }),
  }),
);

const EditIconButton = styled(Stack)(() => ({
  borderRadius: "50%",
  backgroundColor: " #050505cc",
  height: "32px",
  width: "32px",
  alignItems: "center",
  justifyContent: "center",
}));

const Overlay = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "rounded" && prop !== "disabled",
})((props: { rounded: boolean | undefined; disabled: boolean }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  transition: "opacity 0.2s ease",
  border: "4px solid transparent",
  cursor: "pointer",
  overflow: "hidden",
  borderRadius: props.rounded ? "50%" : "8px",
  pointerEvents: props.disabled ? "none" : "auto",
}));
