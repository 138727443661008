import { showMessage } from "@common/Toast";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";

export function useMerchantIdentityNotification() {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();

  const { mutateAsync: readNotifiacationAsync, isLoading } = useMutation(
    (data: any) => {
      return customInstance({
        url: `/merchants/${merchantId}/profile/read-completation`,
        method: "PUT",
      });
    },
  );

  const handleCloseNotification = async (cb?: () => void) => {
    try {
      await readNotifiacationAsync({});

      await queryClient.invalidateQueries(QKEY_GET_MERCHANT_BY_ID);

      if (cb) {
        cb();
      }
    } catch (error) {
      showMessage("Error", "Sorry something went wrong");
    }
  };

  return { handleCloseNotification, isLoading };
}
