type IconProps = {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
};

const FundsIcon = ({
  width = 36,
  height = 37,
  fill = "none",
  stroke = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2061_5208)">
        <path
          d="M10.6186 11.517C14.6957 11.517 18.0008 9.86445 18.0008 7.82589C18.0008 5.78734 14.6957 4.13477 10.6186 4.13477C6.54148 4.13477 3.23633 5.78734 3.23633 7.82589C3.23633 9.86445 6.54148 11.517 10.6186 11.517Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23633 7.82422C3.23633 7.82422 3.23633 10.9533 3.23633 12.9918C3.23633 15.0303 6.54147 16.6829 10.6186 16.6829C14.6957 16.6829 18.0008 15.0303 18.0008 12.9918C18.0008 11.7844 18.0008 7.82422 18.0008 7.82422"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23633 12.9922C3.23633 12.9922 3.23633 16.1212 3.23633 18.1598C3.23633 20.1983 6.54147 21.8509 10.6186 21.8509C14.6957 21.8509 18.0008 20.1983 18.0008 18.1598C18.0008 16.9523 18.0008 12.9922 18.0008 12.9922"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23633 18.1602C3.23633 18.1602 3.23633 21.2892 3.23633 23.3277C3.23633 25.3663 6.54147 27.0189 10.6186 27.0189C14.6957 27.0189 18.0008 25.3663 18.0008 23.3277C18.0008 22.1203 18.0008 18.1602 18.0008 18.1602"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23633 23.3281C3.23633 23.3281 3.23633 26.4572 3.23633 28.4957C3.23633 30.5342 6.54147 32.1868 10.6186 32.1868C14.6957 32.1868 18.0008 30.5342 18.0008 28.4957C18.0008 27.2883 18.0008 23.3281 18.0008 23.3281"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3823 21.851C29.4594 21.851 32.7645 20.1984 32.7645 18.1599C32.7645 16.1213 29.4594 14.4688 25.3823 14.4688C21.3051 14.4688 18 16.1213 18 18.1599C18 20.1984 21.3051 21.851 25.3823 21.851Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 18.1602C18 18.1602 18 21.2892 18 23.3277C18 25.3663 21.3052 27.0189 25.3823 27.0189C29.4593 27.0189 32.7645 25.3663 32.7645 23.3277C32.7645 22.1203 32.7645 18.1602 32.7645 18.1602"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 23.3281C18 23.3281 18 26.4572 18 28.4957C18 30.5342 21.3052 32.1868 25.3823 32.1868C29.4593 32.1868 32.7645 30.5342 32.7645 28.4957C32.7645 27.2883 32.7645 23.3281 32.7645 23.3281"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2061_5208"
          x="0"
          y="0.160156"
          width="36"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2061_5208"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FundsIcon;
