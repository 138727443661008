import { namespaces } from "../../../../resources/i18n.constants";

export const createMerchant = {
  [namespaces.pages.enterprise.createMerchant]: {
    title: "Crear comerciante",
    merchant_information: "Información del comerciante",
    merchant_name: "Nombre del comerciante",
    merchant_name_placeholder: "Ingrese el nombre del comerciante",
    merchant_name_error: "El comerciante debe tener un nombre",
    taxID: "ID de impuestos federales",
    taxID_placeholder: "XX-XXXXXXX",
    taxID_error: "Se requiere identificación fiscal federal",
    category: "Categoría",
    merchant_category_code: "Código de categoría de comerciante",
    estimated_annual_revenue: "Ingresos anuales estimados",
    average_ticket_amount: "Importe medio de la entrada (USD)",
    high_ticket_amount: "Importe alto del billete (USD)",
    category_placeholder: "Elija la categoría de comerciante",
    "Nonprofit Purpose and Countries Serviced": "Propósito sin fines de lucro y países atendidos",
    "Enter a short description about the purpose or mission of your Nonprofit and countries serviced.": "Ingrese una breve descripción sobre el propósito o la misión de su organización sin fines de lucro y los países atendidos.",
    "Countries Serviced Outside USA/Canada": "Países con servicio fuera de EE. UU./Canadá",
    "Please enter the countries Nonprofit services that are outside the USA/Canada.": "Ingrese los países Servicios sin fines de lucro que se encuentran fuera de EE. UU./Canadá.",
    owner_information: "Información del propietario",
    firstName: "Nombre de pila",
    firstName_placeholder: "Ingrese el nombre",
    firstName_error: "Se requiere el nombre del propietario",
    lastName: "Apellido",
    lastName_placeholder: "Introduzca el apellido",
    lastName_error: "Se requiere el apellido del propietario",
    email: "Correo electrónico",
    email_placeholder: "email@example.com",
    email_error: "Se requiere correo electrónico del propietario",
    email_error2: "No es un formato válido",
    password: "Contraseña",
    password_placeholder: "Agregar contraseña",
    enterprise_information: "Información de la empresa",
    enterprise_name_placeholder: "Introduzca el nombre de la empresa",
    enterprise_name: "Nombre de la empresa",
    permalink:"Permalink"
  },
};
