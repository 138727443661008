import React from "react";
import EmptyState from "./EmptyState";
import { NoConnection } from "@assets/emptyStatesIcons";

const ConnectionErrorState = () => {
  return (
    <EmptyState
      Icon={<NoConnection />}
      title={{
        main: "Technical Difficulties",
        secondary: "There seems to be a temporary network issue. We're working on resolving it.",
      }}
      action={{
        label: "Reload page",
        onClick: () => window.location.reload(),
      }}
    />
  )
}

export default React.memo(ConnectionErrorState);
