import { Skeleton, Stack } from "@mui/material";

const PermissionsPanelSkeleton = () => {
  return (
    <>
      <Header />
      <Body />
    </>
  );
};

const Header = () => (
  <Stack
    direction="row"
    gap="18px"
    sx={{ paddingTop: "50px", paddingInline: "16px" }}
  >
    {Array.from({ length: 4 }, (_, i) => (
      <BaseSkeleton key={i} width="15%" />
    ))}
  </Stack>
);

const Body = () => (
  <Stack padding="16px" direction="column" gap="24px" alignItems="stretch">
    <Stack direction="row" justifyContent="space-between" padding="12px 0">
      <BaseSkeleton width="45%" />
      <BaseSkeleton width="15%" />
    </Stack>
    {Array.from({ length: 3 }, (_, i) => (
      <SectionSkeleton key={i} />
    ))}
  </Stack>
);

export const SectionSkeleton = () => (
  <>
    <BaseSkeleton width="25%" align="center" />
    <Stack direction="column" gap="8px">
      {Array.from({ length: 3 }, (_, i) => (
        <SectionItem key={i} />
      ))}
    </Stack>
  </>
);

const SectionItem = () => (
  <Skeleton
    variant="rectangular"
    height="48px"
    width="100%"
    sx={{ borderRadius: "8px" }}
  />
);

const BaseSkeleton = ({
  width,
  height = "16px",
  align,
}: {
  width: number | string;
  height?: number | string;
  align?: string;
}) => (
  <Skeleton
    variant="rectangular"
    height={height}
    width={width}
    sx={{ borderRadius: "12px", alignSelf: align }}
  />
);

export default PermissionsPanelSkeleton;
