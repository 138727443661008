import React from "react";
import { NotAuthorizedWrapper } from "@containers/NotAuthorizedWrapper";
import { useAppSelector } from "@redux/hooks";
import { MissingPermissionWrapperProps } from "../product.types";

export const ProductScreenNotAuthorizedWrapper = ({
  children,
  type,
}: MissingPermissionWrapperProps) => {
  const hasNotProductListPermission = useAppSelector(
    (state) => state.app.permissions?.[`campaign_${type}`],
  );

  const hasNotProductStatsPermission = useAppSelector(
    (state) => state.app.permissions?.product_stats,
  );

  return (
    <NotAuthorizedWrapper
      notAuthorized={
        hasNotProductListPermission && hasNotProductStatsPermission
      }
    >
      {children}
    </NotAuthorizedWrapper>
  );
};
