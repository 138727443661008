import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Grid, Stack, styled } from "@mui/material";
import { Button } from "@common/Button";
import { useEffect, useMemo } from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { palette } from "@palette";
import Input from "@mui/material/Input";
import { SearchIcon } from "@assets/rebrandIcons";
import MCCColumn from "../components/EditEnterpriseMCCColumn";

type FormInputs = {
  categories: TCategoryCode[];
  search: string;
};

interface ModalProps {
  id: number;
  categories: TCategoryCode[];
  parentCategories: TCategoryCode[];
  onClose?: (data: any) => void;
}

const EditEnterpriseMCCOld = ({
  id,
  categories,
  parentCategories,
  onClose,
}: ModalProps) => {
  const { isMobileView } = useCustomTheme();
  const modal = useModal();
  const open = modal.visible;

  const { handleSubmit, isLoading } = useUpdateMerchantInfo(id);

  const defaultValues = {
    categories: [],
    search: "",
  };

  const methods = useForm<FormInputs>({
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = methods;
  const values = watch();

  useEffect(() => {
    reset({
      categories,
      search: "",
    });
  }, [id, categories]);

  const handleCancel = () => {
    modal.hide();
  };

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const categories = data.categories.map((category) => category.id);
    if (onClose) {
      onClose(data.categories);
      handleCancel();
    } else {
      handleSubmit("categories", { categories: categories }, handleCancel);
    }
  };

  const onAddCategory = (category: TCategoryCode) => {
    const vals = values?.categories || [];
    const categories = [...vals, category];
    setValue("categories", categories, { shouldDirty: true });
  };

  const onRemoveCategory = (id: number | string) => {
    const categories = values.categories.filter(
      (category) => category.id !== id,
    );
    setValue("categories", categories, { shouldDirty: true });
  };

  const isAddedCategory = (id: number | string) => {
    const category = values?.categories?.find(
      (category) => category?.id === id,
    );
    return Boolean(category);
  };

  const filteredParentCategories = useMemo(() => {
    return parentCategories.filter((category) => {
      return (
        category.code.includes(values.search) ||
        category.title.toLowerCase().includes(values.search.toLowerCase()) ||
        values.search === ""
      );
    });
  }, [values.search, parentCategories]);

  const isDisabledCategory = values?.categories?.length === 1;

  return (
    <EditMerchantBaseModal
      title="Add Merchant Category Codes (MCC)"
      description="Choose the categories this Provider can assign to its merchants"
      open={open}
      handleCancel={handleCancel}
      sx={{
        "& .MuiPaper-root": {
          width: "928px !important",
          maxWidth: "928px !important",
        },
      }}
      actions={
        <>
          <Button
            size="medium"
            background="tertiary"
            onClick={handleCancel}
            disabled={isLoading}
            sx={{
              padding: "8px 24px",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Cancel
          </Button>
          <Button
            size="medium"
            background="primary"
            type="submit"
            form="edit-merchant-info-form"
            disabled={!isDirty || isLoading}
            sx={{
              padding: "8px 24px",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <Stack
          component="form"
          id="edit-merchant-info-form"
          onSubmit={methods.handleSubmit(onSubmit)}
          spacing={2}
        >
          <MCCSearchInput
            value={values.search}
            onChange={(e) => setValue("search", e.target.value)}
          />
          <Grid container spacing={1}>
            <MCCColumn
              list={filteredParentCategories}
              onAddCategory={onAddCategory}
              isAddedCategory={isAddedCategory}
              search={values.search}
            />
            <MCCColumn
              list={values.categories}
              onRemoveCategory={onRemoveCategory}
              isAddedCategory={isAddedCategory}
              isDisabledCategory={isDisabledCategory}
            />
          </Grid>
        </Stack>
      </FormProvider>
    </EditMerchantBaseModal>
  );
};

type MCCSearchInputProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const MCCSearchInput = ({ value, onChange }: MCCSearchInputProps) => {
  return (
    <StyledInput
      disableUnderline
      startAdornment={
        <SearchIcon width={24} height={24} stroke={palette.gray[200]} />
      }
      onChange={onChange}
      value={value}
    />
  );
};

const StyledInput = styled(Input)(({ theme }) => ({
  background: palette.background.dimmedWhite,
  border: `1px solid ${palette.neutral[30]}`,
  borderRadius: "90px",
  width: "250px",
  padding: "4px 12px",

  "& input": {
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 350,
    color: palette.black[300],
    padding: 0,
    height: "24px",
    marginInline: "4px",
  },
  "& input::-webkit-input-placeholder": {
    color: palette.gray[100],
  },
  "& input:placeholder-shown ~ div": {
    display: "none",
  },
  "&:has(input:focus)": {
    boxSizing: "border-box",
    outline: `none`,
  },
  "&:has(.Mui-disabled)": {
    background: palette.neutral[10],
    border: "none",
    outline: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "& input": {
      fontSize: "16px",
      lineHeight: "19.2px",
      height: "32px",
    },
  },
}));

export default EditEnterpriseMCCOld;
