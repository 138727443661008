import React from "react";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { Stack, SxProps } from "@mui/material";

const Section = ({
  title,
  children,
  containerSx,
}: {
  title: string;
  children?: React.ReactNode;
  containerSx?: SxProps;
}) => {
  return (
    <Stack direction="column" gap={2} alignItems="stretch" sx={containerSx}>
      <Text
        color={palette.black[100]}
        fontSize="18px"
        fontWeight="book"
        lineHeight="21.6px"
        letterSpacing="-0.18px"
      >
        {title}
      </Text>
      {children}
    </Stack>
  );
};

export default Section;
