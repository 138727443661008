interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
}

const EditIcon = ({
  width = 21,
  height = 20,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2504_14573)">
        <path
          d="M5.65709 16.3588L5.6571 16.3588L5.68977 16.3507L5.68978 16.3507L7.64456 15.862C7.66179 15.8577 7.67915 15.8534 7.69663 15.849C7.91069 15.796 8.14085 15.7389 8.35105 15.6199C8.56125 15.5009 8.7286 15.3329 8.88423 15.1766C8.89694 15.1639 8.90956 15.1512 8.92213 15.1386L14.7804 9.28033L14.8043 9.25642C15.038 9.02277 15.2525 8.8083 15.4116 8.61226C15.5844 8.39936 15.7465 8.14439 15.8068 7.81742C15.8455 7.60758 15.8455 7.39242 15.8068 7.18258C15.7465 6.85561 15.5844 6.60064 15.4116 6.38774C15.2525 6.19171 15.038 5.97725 14.8044 5.74361L14.7804 5.71967L14.2501 6.25L14.7804 5.71967L14.7565 5.69574C14.5228 5.46205 14.3084 5.24755 14.1123 5.08847C13.8994 4.91572 13.6445 4.75355 13.3175 4.69324C13.1077 4.65454 12.8925 4.65454 12.6827 4.69324C12.3557 4.75355 12.1007 4.91572 11.8878 5.08847C11.6918 5.24755 11.4773 5.46205 11.2437 5.69574L11.2198 5.71967L5.36147 11.578C5.3489 11.5905 5.33623 11.6031 5.32348 11.6158C5.16722 11.7715 4.99921 11.9388 4.8802 12.149L5.53063 12.5173L4.8802 12.149C4.76118 12.3592 4.70413 12.5894 4.65106 12.8035C4.64673 12.8209 4.64243 12.8383 4.63812 12.8555L4.14125 14.843C4.13864 14.8534 4.13596 14.8641 4.13324 14.8749C4.09541 15.0257 4.04904 15.2106 4.0334 15.3705C4.01572 15.5512 4.01414 15.9078 4.30323 16.1968C4.59233 16.4859 4.94883 16.4844 5.12954 16.4667C5.28949 16.451 5.47433 16.4047 5.62512 16.3668C5.63596 16.3641 5.64662 16.3614 5.65709 16.3588Z"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M10.9167 6.24999L13.4167 4.58333L15.9167 7.08333L14.2501 9.58333L10.9167 6.24999Z"
          fill={stroke}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2504_14573"
          x="0.5"
          y="0"
          width="20"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2504_14573"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default EditIcon;
