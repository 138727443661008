import React from "react";

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
  onClick?: (e: React.MouseEvent) => void;
};
const CloseIcon = ({
  width = 18,
  height = 18,
  color = "#575353",
  onClick,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M4.55849 13.3991C4.41204 13.2527 4.41204 13.0152 4.55849 12.8688L8.44847 8.97881L4.51608 5.04642C4.36964 4.89998 4.36964 4.66254 4.51608 4.51609C4.66253 4.36965 4.89997 4.36965 5.04641 4.51609L8.9788 8.44848L12.9112 4.51609C13.0576 4.36965 13.2951 4.36965 13.4415 4.51609C13.588 4.66254 13.588 4.89998 13.4415 5.04642L9.34803 9.13991C9.32973 9.1855 9.30211 9.22823 9.26516 9.26517C9.22822 9.30211 9.1855 9.32974 9.1399 9.34804L5.08882 13.3991C4.94237 13.5456 4.70493 13.5456 4.55849 13.3991Z"
        fill={color}
      />
      <path
        d="M11.8081 11.2778C11.6617 11.1314 11.4242 11.1314 11.2778 11.2778C11.1313 11.4242 11.1313 11.6617 11.2778 11.8081L12.8688 13.3991C13.0152 13.5456 13.2527 13.5456 13.3991 13.3991C13.5456 13.2527 13.5456 13.0152 13.3991 12.8688L11.8081 11.2778Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
