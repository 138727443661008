import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import { ListItemButton, ListItemButtonProps } from "@mui/material";

interface DrawerListItemLinkProps extends ListItemButtonProps {
  to: string;
  active?: boolean;
  index?: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
}

const DrawerListItemLink = (props: DrawerListItemLinkProps) => {
  type LinkProps = Omit<NavLinkProps, "to">;

  const Link = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef<HTMLAnchorElement, LinkProps>((navLinkProps, ref) => {
        const { className: previousClasses, ...rest } = navLinkProps;
        const elementClasses = previousClasses?.toString() ?? "";
        return (
          <NavLink
            {...rest}
            ref={ref}
            to={props.to}
            end
            className={elementClasses}
          />
        );
      }),
    [props.to],
  );

  return (
    <ListItemButton
      sx={{
        ...props.sx,
      }}
      component={Link}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </ListItemButton>
  );
};

export default DrawerListItemLink;
