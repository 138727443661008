import { TDocument } from "@common/FilePreview/types";
import { Text } from "@common/Text";
import { useDocumentPreview } from "@hooks/common/documents";
import { downloadDocument } from "@hooks/common/documents/utils";
import { Box, Divider, Skeleton, Stack, StackProps } from "@mui/material";
import { PencilSimple } from "@phosphor-icons/react";
import { Message } from "./types";
import moment from "moment";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";
import { IconButton } from "@common/IconButton";
import { isFunction } from "lodash";
import { DocumentListItem } from "features/Notifications/NotificationsCenter/modals/DocumentListItem";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { palette } from "@palette";

interface Props {
  message: Message;
  isEnterprise?: boolean;
  merchantID: number;
  stack?: StackProps;
  showDivider?: boolean;
  onHandleEdit?: (data: { message: string; id: number }) => void;
}

function MessageSection({
  message,
  isEnterprise,
  merchantID,
  stack,
  showDivider = true,
  onHandleEdit,
}: Props) {
  const {
    authorFirstName,
    authorLastName,
    authorAvatarImageURL,
    body,
    createdAt,
    attachments,
    contentLastEditedAt,
  } = message;
  const documents =
    attachments?.map((attachment: any, idx: any) => ({
      id: idx,
      fileURL: attachment?.fileURL,
      fileName: attachment?.fileName,
      fileSize: attachment?.fileSize,
      fileType: attachment?.fileType,
      tag: "",
    })) || [];

  const handleDownload = (file: TDocument | null) => {
    if (!file?.URL) return;
    downloadDocument({ fileName: file.name, fileURL: file.URL });
  };

  const { handlePreview } = useDocumentPreview({
    list: documents,
    merchantID,
    handlers: { handleDownload },
    isEnterprise,
  });
  const showPreview = (document: TMerchantDocument) => {
    if (!document) return;
    handlePreview(document);
  };
  const isEdited = Boolean(contentLastEditedAt);
  const showEdited = isFunction(onHandleEdit) && isEdited;
  return (
    <Stack
      px="16px"
      my="4px"
      {...stack}
      sx={{
        "&:hover .icon-button": {
          display: "flex",
        },
      }}
    >
      {showDivider && (
        <Divider
          sx={{
            width: "1px",
            height: "28px",
            bgcolor: "#B8B8B8",
            ml: "10px",
            mb: "8px",
          }}
        />
      )}

      <Text mb="8px" fontWeight="book" fontSize="12px" color="#8F8F8F">
        {moment.unix(createdAt).format("DD MMM YYYY, HH:mm")}
      </Text>
      <Stack
        p="12px 16px"
        borderRadius="8px"
        bgcolor="#FFFFFF"
        direction="column"
        gap="12px"
        alignItems="stretch"
      >
        <Box alignItems="center" justifyContent="space-between" display="flex">
          <Stack mb="12px" alignItems="center" gap="8px" flexDirection="row">
            <Box
              width="32px"
              height="32px"
              borderRadius="50%"
              src={
                authorAvatarImageURL
                  ? authorAvatarImageURL + "/small"
                  : AvatarPlaceholder
              }
              component="img"
            />
            <Text fontWeight="book" fontSize="14px" color="#403D3D">
              {`${authorFirstName} ${authorLastName}`}
            </Text>
          </Stack>
          {isFunction(onHandleEdit) && !isEdited && (
            <IconButton
              className="icon-button"
              sx={{
                boxShadow: "none",
                border: "none",
                borderRadius: "50%",
                height: "28px",
                width: "28px",
                display: "none",
              }}
              onClick={() =>
                onHandleEdit({
                  message: body,
                  id: message?.id,
                })
              }
            >
              <PencilSimple size={20} />
            </IconButton>
          )}
        </Box>
        <BodyText body={body} showEdited={showEdited} />
        {attachments &&
          documents.map((document: any, idx: any) => (
            <DocumentListItem
              key={idx}
              document={document}
              fileName={document.fileName}
              fileSize={document.fileSize}
              onPreview={showPreview}
            />
          ))}
      </Stack>
    </Stack>
  );
}

export default MessageSection;

const BodyText = ({
  body,
  showEdited,
}: {
  body: string;
  showEdited: boolean;
}) => {
  const editedText = showEdited ? "(Edited)" : null;
  return (
    <Text
      fontSize="14px"
      fontWeight="book"
      color="#575353"
      whiteSpace="pre-wrap"
      sx={{ overflowWrap: "break-word" }}
    >
      {body} <span style={{ color: palette.neutral[60] }}>{editedText}</span>
    </Text>
  );
};

export const MessageSkeleton = ({ count }: { count: number }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <Stack
          data-testid="message-loading-container"
          key={index}
          px="16px"
          mt="10px"
          mb="30px"
          gap="10px"
        >
          <Skeleton
            variant="rectangular"
            width={75}
            height={12}
            sx={{ fontSize: "12px", mb: "8px", borderRadius: "8px" }}
          />
          <Stack direction="row" gap={2} alignItems="center" paddingLeft="15px">
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton
              variant="rectangular"
              width={100}
              height={18}
              sx={{ fontSize: "14px", borderRadius: "8px" }}
            />
          </Stack>
          <Skeleton
            variant="rectangular"
            height={70}
            sx={{ fontSize: "14px", borderRadius: "8px" }}
          />
        </Stack>
      ))}
    </>
  );
};
