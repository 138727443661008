import React from "react";
import CampaignCard from "@common/CampaignCard/CampaignCard";
import { formatDate } from "@utils/index";
import { palette } from "@palette";
import { parseAmount } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2/PurchasesGrid";
import { CommonProductProps } from "@common/Campaigns/campaigns.types";

const NewFundraiserListItem = ({
  data,
  hiddenCols,
  columns,
  openSidePanel,
  isSelected,
  height,
}: {
  hiddenCols?: number[];
  data: any;
  isSelected: boolean;
} & CommonProductProps) => {
  const others = [
    formatDate(data.createdAt),
    parseAmount(data.sumTransactions / 100),
    `${data.conversionRate}%`,
    parseAmount(data.averageTransactions / 100),
    data.totalTransactions,
    data.visitors,
  ];

  return (
    <CampaignCard
      imageURL={data.imageURL}
      name={data.name}
      id={data.id}
      columns={columns}
      sx={{
        borderTop: `1px solid ${palette.neutral[10]}`,
      }}
      others={
        hiddenCols ? others.filter((_, i) => !hiddenCols.includes(i)) : others
      }
      openSidePanel={openSidePanel}
      isSelected={isSelected}
    />
  );
};

export default NewFundraiserListItem;
