import { useGetCurrentMerchantId } from "@hooks/common";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";

const useCheckPAH = (enabled = true) => {
  const { merchantId } = useGetCurrentMerchantId();
  const { userAccID } = useUserReducer();
  const queryEnabled = enabled && Boolean(merchantId);
  const { data: ownerData, isLoading } = useQuery(
    ["check-owner-member", merchantId],
    async () => {
      return await customInstance({
        url: `/accounts/${merchantId}/members?filter=roleName:"owner"`,
        method: "GET",
      });
    },
    {
      staleTime: 10000,
      refetchOnWindowFocus: false,
      enabled: queryEnabled,
    },
  );

  const owner = ownerData?.data ? ownerData?.data[0] : null;

  const isLoggedInPAH = owner?.user?.accID === userAccID;

  return { owner, isLoading, isLoggedInPAH };
};

export default useCheckPAH;
