import UnfoldListItems from "@components/animation/UnfoldListItems";
import CommentLayout from "./CommentLayout";
import { Skeleton } from "@mui/material";

const ThreadSkeleton = ({length = 3}: {length?: number}) => {
  const skeletonItems = Array.from({ length }, (_, i) => ({
    id: `skeleton-${i}`,
  }));
  return (
    <UnfoldListItems
      items={skeletonItems}
      renderKey={(row, idx) => row.id}
      height={60}
      animationName="waterfall"
      ItemComponent={(row, idx, height) => (
          <CommentLayout
            sx={{
              width: "100%",
            }}
            action={
              <Skeleton
                variant="rectangular"
                width={200}
                height={32}
                sx={{ fontSize: "14px", borderRadius: "8px" }}
              />
            }
            message={
              <Skeleton variant="rectangular" width={260} height={21} sx={{borderRadius: "8px"}} />
            }
            name={
              <Skeleton
                variant="rectangular"
                width={200}
                height={21}
                sx={{ fontSize: "14px", borderRadius: "8px" }}
              />
            }
            image={<Skeleton variant="circular" width={24} height={24} />}
          />
      )}
    />
  );
};

export default ThreadSkeleton;
