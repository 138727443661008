import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { useEffect, useRef } from "react";
import { cloneDeep, isEqual } from "lodash";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import {
  TMerchantInfo,
  TPrimaryAccountHolder,
} from "@components/Merchants/MerchantPreview/data.types";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { useQueryClient } from "react-query";
import { showMessage } from "@common/Toast";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useAsyncAction } from "@hooks/customReactCore";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
type FormInputs = {
  signature: File | string;
  hasAgreed: boolean;
};

type TData = {
  merchantInfo: TMerchantInfo;
  primaryAccountHolder: TPrimaryAccountHolder;
  merchantAgreement: TMerchantAgreementPayload;
  status: {
    statusName: string;
  };
};

const useEnterpriseAgreement = (
  handleUpdateStatusValue: (v: number) => void,
  data: TData,
  backLink: () => void,
) => {
  const merchantId = data.merchantInfo.merchantID;
  const { handleSubmit, isLoading: isLoadingInfo } =
    useUpdateMerchantInfo(merchantId);
  const [isLoading, triggerAction] = useAsyncAction();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  const {
    handleUploadSignature: handleUploadSignatureNew,
    isLoading: isFileUploadLoadingNew,
  } = useAddSignatureNew();
  const {
    handleUploadSignature: handleUploadSignatureOld,
    isLoading: isFileUploadLoadingOld,
  } = useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const isLoadingSignature = isFileUploadRefactorEnabled
    ? isFileUploadLoadingNew
    : isFileUploadLoadingOld;

  const queryClient = useQueryClient();

  const defaultValues = {
    hasAgreed: false,
    signature: "",
  };

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    reset,
    formState: { dirtyFields },
  } = methods;
  const values = watch();

  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const savedData = useRef<FormInputs>();

  const calcPercentage = async () => {
    savedData.current = cloneDeep(values);
    const value = await calculatePercentageNested(schema, values);
    handleUpdateStatusValue(value);
  };

  useEffect(() => {
    if (!isEqual(values, savedData.current)) calcPercentage();
  }, [values]);

  useEffect(() => {
    if (data) {
      reset({
        ...defaultValues,
        hasAgreed: !!data.merchantAgreement.signatureURL,
        signature: data.merchantAgreement.signatureURL,
      });
    }
  }, [data]);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (!values.signature) {
      showMessage("Error", "Please sign the agreement");
      return;
    }
    if (!dirtyFields.signature) {
      backLink();
      return;
    }

    const signatureURL = await handleUploadSignature(
      merchantId,
      values.signature,
      false,
    );
    const payload = {
      msaHadAgreed: data.hasAgreed,
      signatureURL,
    };

    handleSubmit("merchant_agreement", payload, () => {
      backLink();
      queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
    });
  };

  const isSubmitDisabled =
    (!dirtyFields.signature && !validateSignature(values.signature)) ||
    !values?.hasAgreed ||
    isLoadingInfo ||
    isLoadingSignature ||
    isLoading;

  const handleTriggerSubmit = (data: any) =>
    triggerAction(onSubmit as any, data);

  return {
    methods,
    onSubmit: handleTriggerSubmit,
    isDisabled: isSubmitDisabled,
  };
};

export default useEnterpriseAgreement;

const schema = Yup.object().shape({
  signature: Yup.mixed<File | string>().test(
    "is-valid-signature",
    "The signature is not valid",
    (value) => validateSignature(value),
  ),
  hasAgreed: Yup.boolean()
    .required("Agreement is required")
    .oneOf([true], "Should Agree to proceed"),
});

export const validateSignature = (signature?: File | string) =>
  (typeof signature !== "string" && !!signature?.type) || !!signature;
