import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import { BASE_URL } from "./api.constant";

export const getCSRFtoken = () => {
  const token = document.head.querySelector('meta[name="csrf-token"]');
  if (token) {
    return token?.getAttribute("content") as string;
  } else return "";
};
const csrf = getCSRFtoken();

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    ...(csrf && {
      "X-CSRF-Token": csrf,
    }),
  },
});

// Custom SNACKBAR FOR NOTIFY MERCHANT GB-7582
const regexPattern = /^\/merchants\/\d+\/underwriting-challenges\/\d+\/notify$/;

export let isRedirecting = false;
let cancelTokenSource: CancelTokenSource | null = null;

export const customInstanceFactory = (axiosBuilder: AxiosInstance) => {
  // Add a request interceptor to cancel requests if already redirecting
  axiosBuilder.interceptors.request.use(
    (config) => {
      if (isRedirecting) {
        // Cancel the request if already redirecting
        return Promise.reject(new axios.Cancel("Redirecting to login"));
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  // Add an interceptor to set the session token on all subsequent requests
  if (axiosBuilder?.interceptors) {
    axiosBuilder.interceptors.response.use(
      (response: AxiosResponse<any, any>) => {
        return response;
      },
      (error: AxiosError<any, any>) => {
        const { status, data, config } = error.response || {};

        if (
          status === 403 &&
          data?.code === "not_authorized" &&
          error.config?.method !== "get" &&
          Cookies.get("user")
        ) {
          NiceModal.show(MISSING_PERMISSION_POP_UP);
          return Promise.reject(error);
        }

        if (
          status === 403 &&
          (data?.code === "not_authorized" || data?.code === "access_denied") &&
          error.config?.method === "get"
        ) {
          return Promise.reject({
            not_authorized: true,
          });
        }

        if (status === 400 && !regexPattern.test(config?.url as string)) {
          showMessage("Error", data.message);
        }
        if (
          status === 401 &&
          data?.code === "not_authenticated" &&
          window.location.pathname.replace("/", "") !== "signup" //during signup we have onboarding wich is depending on severals api calls which, for know reasons might fail with 401
        ) {
          if (!isRedirecting) {
            isRedirecting = true;
            cancelTokenSource = axios.CancelToken.source();

            Cookies.remove("transfer-verification");
            Cookies.remove("user");
            // queryClient.removeQueries();
            setTimeout(() => {
              window.location.href = "/session-expired";
            }, 100);
          } else {
            return Promise.reject(new axios.Cancel("Redirecting to login"));
          }
        }
        return Promise.reject(error);
      },
    );
  }

  return function <T>(config: AxiosRequestConfig): Promise<T> {
    const promise: any = axiosBuilder({
      ...config,
    }).then(({ data }) => data);

    promise.catch((error: any) => {
      console.debug("API error:", error);
    });

    return promise;
  };
};

import { showMessage } from "@common/Toast";
import NiceModal from "@ebay/nice-modal-react";
import { parsedQueryString } from "@utils/queryString";
import axios from "axios";
import Cookies from "js-cookie";
import { MISSING_PERMISSION_POP_UP } from "modals/modal_names";

export const customInstance = (args: AxiosRequestConfig): Promise<any> => {
  const Cookie_Session = Cookies.get("user");
  const localMasquerade = localStorage.getItem("masquerade-mode");
  const masquerade = localMasquerade !== null && JSON.parse(localMasquerade);

  let str = "";
  try {
    if (Cookie_Session) {
      const user = JSON.parse(Cookie_Session);

      str = masquerade.name ? `accID:${masquerade.id}` : `accID:${user.id}`;
    }
  } catch (err) {
    console.log("cookie is either undefined or cannot be parsed");
  }

  const newUrl = parsedQueryString(`${BASE_URL}${args.url}`, str);

  if (newUrl && args.url) {
    return customInstanceFactory(axiosInstance)({
      ...args,
      url: newUrl,
    });
  }

  return customInstanceFactory(axiosInstance)(args);
};
