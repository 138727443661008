import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { RectShape } from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder/lib";
import { BACKGROUND_VIDEO_PRIMARY } from "@constants/constants";
import { CustomBackgroundVideo } from "@components/Product/NewProductBanner/NewProductBanner";
import { Grid } from "@mui/material";
import { MissingPermissionContent } from "features/Permissions/PopUp/MissingPermissionContent";
import { palette } from "@palette";

export const NotAuthorizedWrapper = ({ children, notAuthorized }: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(updatePermissions({ reset: true }));
    };
  }, []);

  if (notAuthorized) {
    return <NotAuthorizedMessage />;
  }

  return children;
};

const awesomePlaceholder = (
  <div className="my-awesome-placeholder">
    {[6, 5, 4, 3, 2, 1].map((n) => (
      <RectShape
        key={n}
        color={`rgba(165,165,165,${0.03 * n})`}
        style={{
          width: "100%",
          height: 80,
          marginBottom: 20,
          borderRadius: "16px",
        }}
      />
    ))}
  </div>
);

const PlaceHolder = () => {
  return (
    <ReactPlaceholder
      showLoadingAnimation={false}
      ready={false}
      customPlaceholder={awesomePlaceholder}
    >
      <div />
    </ReactPlaceholder>
  );
};

export const NotAuthorizedMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.blurredContainer}>
      <div className={classes.bgText}>
        <CustomBackgroundVideo
          videoProps={{
            className: classes.videoStyles,
          }}
          videoSrc={BACKGROUND_VIDEO_PRIMARY}
          className={classes.bgVideo}
        >
          <div style={{ width: "100%", height: 200 }} />
        </CustomBackgroundVideo>
        <Grid paddingTop={4}>
          <PlaceHolder />
        </Grid>
      </div>
      <div className={classes.contentContainer}>
        <MissingPermissionContent />
      </div>
    </div>
  );
};

export const NotAuthorizedBanner = () => {
  const classes = useStyles();
  return (
    <CustomBackgroundVideo
      videoProps={{
        className: classes.videoStyles,
      }}
      videoSrc={BACKGROUND_VIDEO_PRIMARY}
      className={classes.bgVideo}
    >
      <MissingPermissionContent />
    </CustomBackgroundVideo>
  );
};

const useStyles = makeStyles((theme: any) => ({
  blurredContainer: {
    width: "100%",
    height: "90%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "20px",
      left: 0,
    },
  },
  blurOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  bgText: {
    width: "60%",
    height: "95%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "black",
    fontSize: "24px",
    userSelect: "none",
    boxShadow: `-1px 2px 38px 10px ${palette.primary.background}`,
  },
  contentContainer: {
    position: "absolute",
    top: "25%",
    zIndex: 1,
    background: "rgba(255, 255, 255, 0.80)",
    boxShadow: "0px 60px 180px 0px rgba(0, 0, 0, 0.15)",
    borderRadius: 16,
    padding: "48px 16px",
    border: " 2px solid var(--popup-border, #FFF)",
    backdropFilter: "blur(20px)",
  },
  videoStyles: {
    opacity: 0.2,
    objectPosition: "50% 90%",
  },
  bgVideo: {
    borderRadius: "16px",
    boxShadow:
      "0px 20px 40px 0px rgba(0, 0, 0, 0.05), 0px 2px 3px 0px rgba(0, 0, 0, 0.10)",
  },
}));
