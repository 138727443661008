import React from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";

const NameOnCardInput = () => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    // const currentValue = value.replace(/^[a-zA-Z ]*$/g, "");
    const currentValue = value
      .replace(/[~`!@#$%^&()_={}[\]:;,.<>+?-]+|\d+|^\s+$/g, "")
      .replace(/\s+/gi, " ");

    return currentValue;
  };

  React.useEffect(() => {
    setValue("nameOnCard", normalizeInput(watch("nameOnCard")));
  }, [watch("nameOnCard")]);

  return (
    <RHFInput
      name="nameOnCard"
      label="Full name on card"
      fullWidth
      placeholder="Full name on card"
      shouldCapitalizeEachWord
    />
  );
};

export default NameOnCardInput;
