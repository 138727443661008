export const getCardColumns = (
  duration: string,
  subDuration: string,
  noRecurring: boolean,
) => {
  if (noRecurring) return [];
  if (duration === "One-Time") return ["You'll contribute"];

  const columns = ["You'll contribute", "Duration"];
  if (subDuration?.includes("Every")) return columns;
  return [...columns, "Until"];
};
