import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { useUploadSingleImage as useUploadSingleImageOld } from "../image-gallery/useUploadSingleImage";
import { AxiosError } from "axios";
import { buildAccountsUrl } from "@services/api/utils.api";
import { useUploadSingleImage as useUploadSingleImageNew } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export const useUploadCustomerImage = () => {
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUpload: handleUploadNew, isLoading: isLoadingNew } =
    useUploadSingleImageNew();
  const { handleUpload: handleUploadOld, isLoading: isLoadingOld } =
    useUploadSingleImageOld();
  const handleUpload = isFileUploadRefactorEnabled
    ? handleUploadNew
    : handleUploadOld;
  const isLoading = isFileUploadRefactorEnabled ? isLoadingNew : isLoadingOld;
  const queryClient = useQueryClient();

  const createImageMutation = useMutation((data: any) => {
    return customInstance({
      url: buildAccountsUrl("media-items"),
      method: "POST",
      data,
    });
  });
  const handlePostImage = async (file: File) => {
    const tempUrl = await handleUpload(
      isFileUploadRefactorEnabled
        ? { file, formType: "customer_avatar" }
        : (file as any),
      isFileUploadRefactorEnabled ? "customer_avatar" : undefined,
    );

    if (!tempUrl) return "";

    const promise = await createImageMutation.mutateAsync(
      {
        label: "main_pic",
        url: tempUrl,
      },
      {
        onError: (error: unknown) => {
          const axiosError = error as AxiosError;
          const errorMessage = axiosError.response?.data;
          console.log("errorMessage = ", axiosError);
        },
      },
    );

    await queryClient.refetchQueries("get-all-media-items");
    return promise.URL;
  };

  return { handlePostImage, isLoading };
};
