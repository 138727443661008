interface Props {
  width?: number | string;
  height?: number | string;
  fill?: string;
}
export default function FilterFunnelIcon({
  width = 20,
  height = 20,
  fill = "#A8A8A8",
}: Props) {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0156 3.86955C17.9194 3.64729 17.76 3.45822 17.5572 3.32584C17.3544 3.19347 17.1172 3.12363 16.875 3.12502H3.12498C2.88305 3.1255 2.64646 3.19617 2.4439 3.32846C2.24135 3.46076 2.08154 3.64899 1.98386 3.87032C1.88618 4.09165 1.85483 4.33658 1.8936 4.57538C1.93238 4.81418 2.03963 5.03659 2.20232 5.21565L2.20857 5.22268L7.49998 10.8727V16.875C7.49993 17.1013 7.56127 17.3233 7.67747 17.5174C7.79368 17.7115 7.96038 17.8704 8.15981 17.9772C8.35924 18.084 8.58392 18.1347 8.8099 18.1239C9.03587 18.113 9.25466 18.0411 9.44295 17.9156L11.9429 16.2485C12.1143 16.1343 12.2548 15.9796 12.352 15.798C12.4492 15.6165 12.5 15.4137 12.5 15.2078V10.8727L17.7922 5.22268L17.7984 5.21565C17.9628 5.03741 18.0711 4.8147 18.1097 4.57531C18.1484 4.33592 18.1156 4.09045 18.0156 3.86955ZM11.589 10.0219C11.3719 10.252 11.2507 10.5563 11.25 10.8727V15.2078L8.74998 16.875V10.8727C8.75071 10.5551 8.62996 10.2494 8.41248 10.018L3.12498 4.37502H16.875L11.589 10.0219Z"
        fill={`${fill}`}
      />
    </svg>
  );
}
