import { ArrowForwardIcon, ConversationsIconNotFilled } from "@assets/icons";
import { LockIconV2 as LockIcon } from "@assets/icons/RebrandedIcons";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { BoxProps, Stack } from "@mui/material";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { TOFACLastCheckType } from "../../data.types";
import OFACStatus from "./OFACStatus";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { SyntheticEvent } from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { checkPortals } from "@utils/routing";
import { OFACCheckStatusType } from "../hooks/useOFAC";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { OFACTabType } from "../helpers";

type Props = {
  id: number;
  disabled: boolean;
  onOpen: (open?: boolean, tab?: OFACTabType) => void;
  opened: boolean;
  lastCheckBO?: TOFACLastCheckType;
  lastCheckBP?: TOFACLastCheckType;
  lastCheckPAH?: TOFACLastCheckType;
  isAcquirer?: boolean;
};

const OFACPreviewSection = ({
  disabled,
  onOpen,
  opened,
  lastCheckBO,
  lastCheckBP,
  lastCheckPAH,
  isAcquirer,
}: Props) => {
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { openConversationHandler } = useConversationsModal();
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isMobileView } = useCustomTheme();
  const { isAcquirerEnterprises } = checkPortals();
  const onConvClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    openConversationHandler({ id: 0, name: "OFAC Check", paths: [] });
  };

  const composed = isNewOfacTabsEnabled
    ? composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.OFAC,
      )
    : composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.OFAC,
      );

  const isViewReadOfacAllowed = useAccessControl({
    resource: composed,
    operation: OPERATIONS.READ,
  });

  const parsedMatchesArray: {
    type: OFACTabType;
    match?: OFACCheckStatusType;
  }[] = [
    { type: "Business Profile", match: lastCheckBP?.lastCheckStatusName },
    ...(isNewOfacTabsEnabled
      ? [
          {
            type: "Primary Account Holder" as OFACTabType,
            match: lastCheckPAH?.lastCheckStatusName,
          },
          {
            type: "Business Owner" as OFACTabType,
            match: lastCheckBO?.lastCheckStatusName,
          },
        ]
      : []),
  ];

  const confirmedMatch = parsedMatchesArray.find(
    (item) => item.match === "confirmed_match",
  );
  const possibleMatch = parsedMatchesArray.find(
    (item) => item.match === "possible_match",
  );
  const matchToShow = confirmedMatch || possibleMatch;

  const handleOpen = () => {
    onOpen(true, matchToShow?.type);
  };

  const disableMessage =
    "Please create a Business Profile or add a Primary Account Holder with both first and last name provided";

  return (
    <Container
      disabled={disabled || !isViewReadOfacAllowed}
      opened={opened}
      onClick={handleOpen}
      data-testid="OFAC-container"
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text fontSize={18} color={palette.neutral[80]}>
          OFAC
        </Text>
        {disabled || !isViewReadOfacAllowed ? (
          <LockIcon />
        ) : (
          <Stack direction="row" spacing={1} alignItems="center">
            {matchToShow?.match && <OFACStatus status={matchToShow.match} />}
            {merchant_underwriting && isAcquirer && (
              <CircularButton className="circular-button" onClick={onConvClick}>
                <ConversationsIconNotFilled section="ofac" />
              </CircularButton>
            )}
            <Button
              background="tertiary"
              endIcon={<ArrowForwardIcon />}
              sx={{ padding: "4px 8px", height: "28px", width: "76px" }}
            >
              Open
            </Button>
          </Stack>
        )}
      </Stack>

      {!isMobileView && (disabled || !isViewReadOfacAllowed) && (
        <Text color={palette.gray[100]} mt={1}>
          {!isViewReadOfacAllowed ? FEATURE_DENY_MESSAGE : disableMessage}
        </Text>
      )}

      {isMobileView && (
        <Text color={palette.neutral[70]} fontWeight="book" mt={1}>
          Please switch to desktop view to access the OFAC section
        </Text>
      )}
    </Container>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "opened",
})<BoxProps & { disabled: boolean; opened: boolean }>(
  ({ disabled, opened }) => ({
    padding: "16px",
    borderRadius: "16px",
    background: palette.background.dimmedWhite,
    boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
    cursor: "default",
    ...(disabled && {
      pointerEvents: "none",
      opacity: 0.5,
    }),
    ...(!disabled &&
      !opened && {
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
          "& .circular-button": {
            display: "flex",
          },
        },
      }),
    ...(opened && {
      boxShadow: "none",
      opacity: 0.5,
      pointerEvents: "none",
    }),
  }),
);

const CircularButton = styled(Box)(({ theme }) => {
  return {
    display: "none",
    cursor: "pointer",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    padding: "4px",
    borderRadius: "50%",
    "&:hover": {
      opacity: 1,
      backgroundColor: theme.palette.neutral[10],
    },
  };
});

export default OFACPreviewSection;
