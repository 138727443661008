import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { showMessage } from "@common/Toast/ShowToast";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import SweepStackAbout from "../components/TabsPages/SweepStackAbout";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { cloneDeep, debounce, isEqual } from "lodash";
import { useFindSweepstakeById } from "@hooks/merchant-api/sweepstakes";
import { CreateSweepstakeModalProps } from "../types";
import SweepStackStylePage from "../components/TabsPages/SweepStackStylePage";
import SweepStackPaymentSetup from "../components/TabsPages/SweepStackPaymentSetup";
import SweepStackConfiguration from "../components/TabsPages/SweepStackConfiguration";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { toUnixDateFormat } from "@utils/date.helpers";
import { getImageURL } from "@utils/getInage";
import { editorStateToString } from "@utils/draft.editor.helpers";
import {
  INTRODUCE_ADVANCED_FORM_BUILDER_MODAL,
  SWEEPSTAKE_SUCCESS_MODAL,
} from "modals/modal_names";
import { useMediaQuery, useTheme } from "@mui/material";
import { formatVariantValue } from "@utils/index";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type TStepsLabel = "About" | "Style" | "Payment set up" | "Configuration";
export default function useNewSweepStack({
  id,
  noModal,
  handleClose,
}: CreateSweepstakeModalProps) {
  const { data, isLoading } = useFindSweepstakeById(id);

  const initialStatus: {
    step: TStepsLabel;
    enabledSteps: TStepsLabel[];
    steps: {
      label: TStepsLabel;
      barValue: number;
    }[];
  } = {
    step: STEPS_OBJ.ABOUT,
    enabledSteps: [],
    steps: [
      {
        label: STEPS_OBJ.ABOUT,
        barValue: 0,
      },
      {
        label: STEPS_OBJ.STYLE,
        barValue: 0,
      },
      {
        label: STEPS_OBJ.PAYMENT_SETUP,
        barValue: 0,
      },
      {
        label: STEPS_OBJ.CONFIRMATION,
        barValue: 0,
      },
    ],
  };
  const queryClient = useQueryClient();
  const [{ steps, step, enabledSteps }, setStatusBar] = useState(initialStatus);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const valuesRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const apiValueRef = useRef<any>(null);
  const activeStepRef = useRef<TStepsLabel>("About");
  const modal = useModal();
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const { isADBEnabled } = useGetFeatureFlagValues();

  const UISteps = useMemo(() => {
    return {
      [STEPS_OBJ.ABOUT]: <SweepStackAbout title={titles[STEPS_OBJ.ABOUT]} />,
      [STEPS_OBJ.STYLE]: (
        <SweepStackStylePage title={titles[STEPS_OBJ.STYLE]} />
      ),
      [STEPS_OBJ.PAYMENT_SETUP]: (
        <SweepStackPaymentSetup title={titles[STEPS_OBJ.PAYMENT_SETUP]} />
      ),
      [STEPS_OBJ.CONFIRMATION]: (
        <SweepStackConfiguration title={titles[STEPS_OBJ.CONFIRMATION]} />
      ),
    };
  }, []);

  const createProductMutation = useMutation((data: any) => {
    return customInstance({
      url: buildMerchantEndpoints(id ? `products/${id}` : "products"),
      method: id ? "PATCH" : "POST",
      data,
    });
  });

  const { isLoading: apiLoading } = createProductMutation;
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    formState: { isDirty, isValid },
  } = methods;
  const values = watch();

  const setApiValues = () => {
    if (data && id && !isLoading)
      reset({
        [STEPS_OBJ.ABOUT]: {
          title: data?.general?.title,
          description: data?.general?.description,
          endDate: data?.general?.date,
        },
        [STEPS_OBJ.STYLE]: {
          useAsBackground: data?.general?.featuredImage?.useAsBackground,
          image: {
            URL: data?.general?.featuredImage?.image,
          },
        },
        [STEPS_OBJ.PAYMENT_SETUP]: data?.tickets?.list,
        [STEPS_OBJ.CONFIRMATION]: {
          set_entries_limit: data?.tickets?.set_entries_limit,
          multiple_winners: data?.tickets?.multiple_winners,
          max_tickets: data?.tickets?.max_tickets,
          customer_pays_credit: data?.tickets?.customer_pays_credit.active,
          browse_more: data.general.browseMore,
        },
      });
  };

  useEffect(() => {
    setApiValues();
  }, [isLoading, modal?.visible]);

  const debouncedCalculate = useRef(
    debounce(async ({ schema, values }) => {
      const count = Math.abs(
        await calculatePercentageNested(schema, values, activeStepRef.current),
      );

      setStatusBar((prev) => {
        const array = prev.steps;
        const indexToUpdate = array.findIndex(
          (item) => item.label === prev.step,
        );
        array[indexToUpdate] = { ...array[indexToUpdate], barValue: count };
        const enabledSteps = prev?.enabledSteps;
        const addedIndex = enabledSteps.indexOf(prev.step);
        if (addedIndex !== -1 && count < 100) {
          // if we have it in array and count is less than 100 remove its from array
          enabledSteps.splice(addedIndex, 1);
        } else if (addedIndex === -1 && count >= 100) {
          // if we dont have it in array and count is more than 100 add it to array
          enabledSteps.push(prev.step);
        }

        return {
          ...prev,
          steps: array,
          enabledSteps,
        };
      });
    }, 300),
  ).current;

  useEffect(() => {
    const fieldsToWatch = [STEPS_OBJ.ABOUT, STEPS_OBJ.PAYMENT_SETUP];
    const hasFieldChanged = fieldsToWatch.some((field) => {
      const prevValue = valuesRef.current?.[field];
      const currentValue = values?.[field];
      return !isEqual(prevValue, currentValue);
    });

    if (hasFieldChanged) {
      valuesRef.current = cloneDeep(values);
      debouncedCalculate({ schema, values });
    }
  }, [values]);

  const onClose = () => {
    setStatusBar(initialStatus);
    reset(defaultValues);
    apiValueRef.current = null;
    valuesRef.current = null;
    if (!noModal) modal.hide();
    if (handleClose) {
      handleClose();
      queryClient.removeQueries("find-sweepstake-by-id");
    }
  };

  const setCurrentStep = (activeStep: TStepsLabel) =>
    setStatusBar((prev: any) => {
      const enabled = prev.enabledSteps ?? [];
      const isNecesaryStep =
        (activeStep === STEPS_OBJ.STYLE ||
          activeStep === STEPS_OBJ.CONFIRMATION) &&
        !enabled?.includes(activeStep);

      const array = cloneDeep(prev.steps);
      const indexToUpdate = array.findIndex(
        (item: any) => item.label === activeStep,
      );
      array[indexToUpdate] = { ...array[indexToUpdate], barValue: 100 };
      isNecesaryStep && enabled.push(activeStep);
      activeStepRef.current = activeStep;
      return {
        ...prev,
        step: activeStep,
        enabledSteps: enabled,
        steps: isNecesaryStep ? array : prev.steps,
      };
    });

  const handleSubmit = useCallback(async () => {
    step === "Configuration" && setIsSubmitDisabled(true);
    const allValuesPresent = Object.values(STEPS_OBJ).every((value) =>
      enabledSteps?.includes(value),
    );

    const conditionForSteps =
      (allValuesPresent && step === STEPS_OBJ.CONFIRMATION) || id;
    if (!conditionForSteps) return;

    const customTickets = values[STEPS_OBJ.PAYMENT_SETUP].map(
      (ticket: any, index: number) => {
        return {
          name: ticket.name,
          price: formatVariantValue(ticket.amount),
          description: editorStateToString(ticket.description.text),
          imageURL: ticket.thumbnail || "",
          isEnabled: ticket.active,
          showAvailableVariants: ticket.display_tickets,
          inventory: parseInt(ticket.in_stock),
          bundle: 1,
          id: ticket.id,
          displayOrder: index,
          recurringIntervals: [{ interval: "once" }],
        };
      },
    );

    const customData = {
      typeName: "sweepstake",
      name: values[STEPS_OBJ.ABOUT].title,
      description: values[STEPS_OBJ.ABOUT].description,
      canBrowseCampaigns: values[STEPS_OBJ.CONFIRMATION].browse_more,
      endsAt: toUnixDateFormat(new Date(values[STEPS_OBJ.ABOUT].endDate)),
      imageURL: getImageURL(values[STEPS_OBJ.STYLE].image),
      usesBackgroundImage: values[STEPS_OBJ.STYLE].useAsBackground,
      needsTax: values[STEPS_OBJ.CONFIRMATION].customer_pays_credit,
      allowFeeChoice:
        values[STEPS_OBJ.CONFIRMATION].customer_pays_credit?.optional,
      maxPurchaseQuantity: +values[STEPS_OBJ.CONFIRMATION].max_tickets,
      allowMultipleWinners: values[STEPS_OBJ.CONFIRMATION].multiple_winners,
      maxEntriesPerPrize: values[STEPS_OBJ.CONFIRMATION].set_entries_limit
        .active
        ? +values[STEPS_OBJ.CONFIRMATION].set_entries_limit.max_entries
        : null,

      variants: customTickets,
      recurringIntervals: [
        {
          interval: "once",
        },
      ],
      defaultRecurringInterval: "once",
    };

    createProductMutation.mutate(customData, {
      onSuccess: async (res: any) => {
        if (id) {
          const allVariants = await customInstance({
            url: buildMerchantEndpoints(`products/${id}/variants`),
            method: "GET",
          });

          if (customData?.variants) {
            const customVariants = customData.variants;

            for (const amount of customData.variants) {
              if (typeof amount.id === "string") {
                await customInstance({
                  url: buildMerchantEndpoints(`products/${id}/variants`),
                  method: "POST",
                  data: amount,
                });
              } else {
                await customInstance({
                  url: buildMerchantEndpoints(
                    `products/${id}/variants/${amount.id}`,
                  ),
                  method: "PATCH",
                  data: amount,
                });
              }
            }
            if (customVariants.length < allVariants?.total) {
              allVariants?.data.forEach(async (variant: any) => {
                if (
                  customVariants.findIndex((v: any) => v.id === variant.id) !==
                  -1
                )
                  return;
                await customInstance({
                  url: buildMerchantEndpoints(
                    `products/${id}/variants/${variant.id}`,
                  ),
                  method: "DELETE",
                });
              });
            }
          }

          showMessage(
            "Info",
            <>
              Changes on{" "}
              <span style={{ fontWeight: "600" }}>“{customData.name}”</span>{" "}
              have been successfully saved
            </>,
            isDesktop,
            "Changes Saved",
          );
          queryClient.removeQueries("find-sweepstake-by-id");
          queryClient.refetchQueries("get-sweepstake-by-id");
          queryClient.refetchQueries("get-campaign");
        } else {
          isADBEnabled
            ? NiceModal.show(INTRODUCE_ADVANCED_FORM_BUILDER_MODAL, {
                productId: res?.id,
                data: {
                  about: {
                    title: customData.name,
                    description: customData.description,
                  },
                  style: {
                    useAsBackground: customData.usesBackgroundImage,
                    image: {
                      URL: customData.imageURL,
                    },
                  },
                  campaign: customData.typeName,
                } as any,
              })
            : NiceModal.show(SWEEPSTAKE_SUCCESS_MODAL, {
                name: customData.name,
              });

          queryClient.refetchQueries(`get-campaign-stats`);
        }
        queryClient.refetchQueries("list-all-sweepstakes");
        onClose();
      },
      onSettled: () => {
        setIsSubmitDisabled(false);
      },
    });
  }, [id, values, enabledSteps, step]);

  const enabled =
    isDirty || !isEqual(data?.tickets?.list, values[STEPS_OBJ.PAYMENT_SETUP]);

  return {
    data,
    isLoading,
    onClose,
    steps,
    step,
    setCurrentStep,
    methods,
    isDisabled: id
      ? !enabled || apiLoading || !isValid || isSubmitDisabled
      : !enabledSteps?.includes(step) || !isDirty || isSubmitDisabled,
    handleSubmit,
    apiLoading,
    UISteps,
  };
}

export const STEPS_OBJ = {
  ABOUT: "About",
  STYLE: "Style",
  PAYMENT_SETUP: "Payment set up",
  CONFIRMATION: "Configuration",
} as const;

export const btnLabel = {
  SAVE_CHANGES: "Save Changes",
} as const;

export const titles = {
  [STEPS_OBJ.ABOUT]: "Tell us more about your Sweepstake",
  [STEPS_OBJ.STYLE]: "Make it unique",
  [STEPS_OBJ.PAYMENT_SETUP]: "Set up a range of payment choices",
  [STEPS_OBJ.CONFIRMATION]: "Configuration",
};

const defaultValues = {
  [STEPS_OBJ.ABOUT]: {
    title: "",
    description: "",
    endDate: null,
  },
  [STEPS_OBJ.STYLE]: {
    useAsBackground: false,
    image: {},
  },
  [STEPS_OBJ.PAYMENT_SETUP]: [],
  [STEPS_OBJ.CONFIRMATION]: {
    set_entries_limit: {
      active: false,
      max_entries: 0,
    },
    multiple_winners: false,
    max_tickets: 5,
    browse_more: false,
    customer_pays_credit: false,
  },
};

const schema = Yup.object().shape({
  [STEPS_OBJ.ABOUT]: Yup.object().shape({
    title: Yup.string().trim().required("This field is required"),
    description: Yup.string(),
    endDate: Yup.date()
      .typeError("please provide a valid date")
      .min(new Date(), "Date must be in the future")
      .required("This field is required"),
  }),
  [STEPS_OBJ.STYLE]: Yup.object().shape({
    image: Yup.object(),
    useAsBackground: Yup.boolean(),
  }),
  [STEPS_OBJ.PAYMENT_SETUP]: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().trim().required(),
        amount: Yup.string().trim().required(),
        description: Yup.object().shape({
          enabled: Yup.boolean(),
          text: Yup.mixed<any>().nullable(),
        }),
        thumbnail: Yup.mixed<File | string>(),
        active: Yup.boolean(),
        in_stock: Yup.number().required(),
        display_tickets: Yup.boolean(),
        bundle: Yup.number(),
      }),
    )
    .min(1),
  [STEPS_OBJ.CONFIRMATION]: Yup.object().shape({
    set_entries_limit: Yup.object().shape({
      active: Yup.boolean(),
      max_entries: Yup.number(),
    }),
    multiple_winners: Yup.boolean(),
    customer_pays_credit: Yup.boolean(),
    max_tickets: Yup.number(),
    browse_more: Yup.boolean(),
  }),
});
