import { palette } from "@palette";
import { KeyVal } from "@common/KeyVal";
import { Box, Grid } from "@mui/material";
import { Text } from "@common/Text";
import { TransactionData } from "@components/ManageMoney/TransactionTable/data";
import { DashedLine } from "@assets/icons";

type PaymentDetailsProps = {
  rowData: TransactionData;
  iconButton?: boolean;
};

function MobileTransactionPersonalInfo({ rowData }: PaymentDetailsProps) {
  return (
    <Grid
      container
      sx={{
        "& > :nth-of-type(odd)": {
          backgroundColor: "#F6FBFF",
        },
      }}
    >
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Full Name"
          value={rowData.title}
          mobileView={true}
        />
      </Grid>

      <Grid item xs={12}>
        <KeyVal
          sx={{
            color: palette.neutral[600],
            borderBottom: "2px dashed #DDDCE5",
          }}
          keyName="Email"
          value={rowData.email}
          mobileView={true}
        />
      </Grid>

      <Grid item xs={12}>
        <div style={{ textAlign: "center" }}>
          <DashedLine small />
        </div>
      </Grid>

      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Name on card"
          value={rowData.title}
          mobileView={true}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyVal
          keyName="Transaction ID"
          value={rowData.paymentDetails.merchantTaxId}
          mobileView={true}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyVal
          keyName="Processed"
          value={rowData.paymentDetails.processedDate}
          mobileView={true}
        />
      </Grid>
      {/* --------- Status ---------- */}
      <Grid item xs={12}>
        <KeyVal
          keyName="Status"
          mobileView={true}
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text fontWeight="medium" textAlign="right">
                Settled Visa
              </Text>
              <Text fontWeight="medium">xxxx xxxx xxxx 1111</Text>
            </Box>
          }
        />
      </Grid>
      {/* --------- Revenue Sources --------- */}
      <Grid item xs={12}>
        <KeyVal
          keyName="Revenue Sources"
          mobileView={true}
          value={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "flex-end",
              }}
            >
              <Text fontWeight="medium" textAlign="right">
                {rowData.paymentDetails.revenueSource}
              </Text>
              <Text fontWeight="medium" color={palette.primary.main}>
                buddyteal.com
              </Text>
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
}

const dashedLineStyle = {
  border: `1px dashed ${palette.neutral[100]}`,
  margin: "8px 0",
};

export default MobileTransactionPersonalInfo;
