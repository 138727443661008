import { Components } from "@mui/material";
// import { palette } from "../colors";

export const formControlLabel: Components["MuiFormControlLabel"] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      "& .Mui-disabled,.MuiFormControlLabel-label": {
        // color: `${palette.common.black} !important`,
        // backgroundColor: "green",
        fontSize: "16px",
        lineHeight: "140%",
        '&[type*="radio"]:hover ~ &': {
          backgroundColor: "initial",
        },
      },
    },
  },
};
