import { TDocument, TFileNavigation } from "@common/FilePreview/types";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import { FILE_PREVIEW_MODAL } from "modals/modal_names";
import useFilePermissions from "./useFilePermissions";
import { FilePreviewActions } from "@common/FilePreview/FilePreview";
import { StyledDownloadButton } from "@common/FilePreview/components/CustomDownloadButton";
import { StyledDeleteButton } from "@common/FilePreview/components/StyledDeleteButton";
import NiceModal from "@ebay/nice-modal-react";
import { checkPortals } from "@utils/routing";
import { TToolbarAction } from "@common/FilePreview/layout/types";
import { ActionButton } from "@components/Merchants/MerchantPreview/manageUnderwriting/Challenges/ChallengeActions";

type THandler = (file: TDocument | null, navigation?: TFileNavigation) => void;
interface IUseDocumentPreview {
  list?: TMerchantDocument[];
  merchantID: number;
  isEnterprise?: boolean;
  handlers: {
    handleDownload: THandler;
    handleDelete?: THandler;
    handleApprove?: () => void;
    handleReject?: () => void;
  };
  isChallange?: boolean;
  challengeDisabled?: boolean;
}

const useDocumentPreview = ({
  list,
  handlers,
  merchantID,
  isEnterprise,
  isChallange,
  challengeDisabled,
}: IUseDocumentPreview) => {
  const { handleDelete, handleDownload, handleApprove, handleReject } =
    handlers;
  const { isDeleteAllowed } = useFilePermissions();

  const { isAcquirerPortal } = checkPortals();

  const previewActions: FilePreviewActions[] = [
    {
      label: "download",
      content: <StyledDownloadButton />,
      onClick: (file) => handleDownload(file),
    },
    {
      label: "delete",
      content: <StyledDeleteButton disabled={!isDeleteAllowed} />,
      onClick: (file, handleCloseDrawer, navigation) => {
        handleDelete && handleDelete(file, navigation);
        handleCloseDrawer && handleCloseDrawer();
      },
      disabled: !isDeleteAllowed,
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
      hidden: !isAcquirerPortal || isChallange || !handlers.handleDelete,
    },
  ];

  const previewHeaderActions: Partial<TToolbarAction>[] = [
    {
      ...(handleReject && {
        label: "reject",
        content: (
          <ActionButton
            key="Reject"
            label="Reject"
            onClick={handleReject}
            disabled={challengeDisabled}
            sx={{
              textDecoration: "none",
            }}
          />
        ),
        hidden: !isChallange,
      }),
    },
    {
      ...(handleApprove && {
        label: "approve",
        content: (
          <ActionButton
            key="approve"
            label="Approve"
            onClick={handleApprove}
            background="primary"
            disabled={challengeDisabled}
            sx={{
              backgroundColor: "#13A75A",
              ":disabled": {
                ":hover": { backgroundColor: "#D1D1D0" },
              },
            }}
          />
        ),
        hidden: !isChallange,
      }),
    },
  ];

  const handlePreview = (
    row?: TMerchantDocument,
    customList?: TMerchantDocument[],
    arg?: any,
  ) => {
    if (!row) return;
    const file = fileFormatter(row);
    NiceModal.show(FILE_PREVIEW_MODAL, {
      file: file,
      list: customList?.map(fileFormatter) || list?.map(fileFormatter) || [],
      toolbarActions: previewActions,
      headerActions: previewHeaderActions,
      merchantID,
      merchantType: isEnterprise ? "enteprise" : "merchant",
      disableBackdropClick: false,
      ...arg,
    });
  };

  return {
    handlePreview,
  };
};

const fileFormatter = (file: TMerchantDocument): TDocument => {
  return {
    id: file.id,
    name: file.fileName,
    URL: file.fileURL,
    type: file.fileType,
    tag: file.tag,
  };
};

export default useDocumentPreview;
