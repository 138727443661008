import { namespaces } from "../../../../resources/i18n.constants";

export const membershipsMinibuilder = {
  [namespaces.pages.membershipsMinibuilder]: {
    create_membership: "Create Membership",
    edit_membership: "Edit Membership",
    // Stepper
    general: "General",
    subscriptions: "Subscriptions",
    // Step 1
    title: "Title",
    title_required: "Your campaign needs a title",
    "Please enter a suitable title": "Please enter a suitable title",
    "Your campaign needs a title": "Your campaign needs a title",
    description: "Description",
    optional_but_recommended: "(Optional but recommended)",
    "What’s the purpose of this membership":
      "What’s the purpose of this membership?",
    characters_remaining: "Characters remaining",
    browse_more_campaigns: "Browse More Campaigns",
    "If enabled, users will be able to explore other published campaigns of this Nonprofit.":
      "If enabled, users will be able to explore other published campaigns of this Nonprofit.",
    featured_image: "Featured Image",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000":
      "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000",
    use_as_background_image: "Use as Background Image",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "If checked this image will be integrated in the background of the form, can be later changed",
    media_library: "Media Library",
    add_new_image: "Add New Image",
    no_images: "No images",
    open_adv_builder: "Open the Advanced Builder",
    save_changes: "Save Changes",
    // Subscriptions ------------------------------------------------------------------ --------------
    thumbnail: "Thumbnail",
    display_subscriptions_available: "Display Subscriptions Available",
    add_subscription: "Add Subscription",
    not_enough_subscriptions: "Not enough subscriptions",
    at_least_one_subscription:
      "You need at least one subscription to create a Membership",
    changes_saved: "Changes Saved",
    successfully_saved:
      "Changes on “New membership.” have been successfully saved",
    subscription_name: "Subscription Name",
    enter_a_suitable_name: "Enter suitable name",
    ticket_name_is_mandatory: "Ticket name is mandatory",
    amount: "Amount",
    must_be_above_one_dollar: "Must be above 1USD",
    between: "Between",
    and: "and",
    in_stock: "In Stock",
    at_least_one: "At least 1",
    should_be_at_least: "Should be at least 1",
    "Give some insight about this Subscription":
      "Give some insight about this Subscription",
    create_option: "Create Option",
    cancel: "Cancel",
    remove: "Remove",
    payment_types: "Payment Types",
    "Choose the payment types available to your customers.":
      "Choose the payment types available to your customers.",
    "If defaulted,": "If defaulted,",
    "the option will be selected at first when users visit the campaign.":
      "the option will be selected at first when users visit the campaign.",
    "One-Time": "One-Time",
    Monthly: "Monthly",
    Quarterly: "Quarterly",
    Yearly: "Yearly",
    "Make default": "Make default",
    available: "Available",
    max_subscriptions_that_can_be_purchased_at_once:
      "Max Subscriptions That Can Be Purchased At Once",

    properties: "Properties",
    customer_pays_the_credit_card_fee: "Customer Pays the Credit Card Fee",
    "If toggled off, the Nonprofit covers the credit card fee.":
      "If toggled off, the Nonprofit covers the credit card fee.",
    make_it_optional: "Make it Optional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "If checked customers will have the choice of not covering the Credit Card Fee",
    // Membership Successful
    membership_successfully_created: "Membership successfully created",
    "If you’re on a desktop or a laptop":
      "If you’re on a desktop or a laptop, you can continue editing in <span>the Advanced Builder</span>. The Advanced Builder offers powerful customization tools to help you take your campaign to the next level and ultimately increase conversion.",
    membership_create_success_description: "Congratulations, your payment form has been created successfully and it's ready to accept payments, you can check its details and share it everywhere or browse more payment forms",
    open_the_advanced_builder: "Open the Advanced Builder",
    done: "Done",
    membership_created: "Membership Created",
    "Your new Membership “New membership” has been successfully created and it’s ready to sell subscriptions.":
      "Your new Membership “New membership” has been successfully created and it’s ready to sell subscriptions.",
  },
};
