import { TableBody as MuiTableBody, styled } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import { TableRow } from "../TableRow";
import NoResultsState from "@common/EmptyState/NoResultsState";
import {
  RenderExpandedRow,
  TableColumnTypeCustom,
  TableData,
} from "../helpers";
import { palette } from "@palette";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import { animated } from "react-spring";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import TableRowSkeleton from "@common/Skeleton/TableRowSkeleton";
import { memo, useEffect } from "react";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import ListWrapperWithStates from "@containers/ListWrapperWithStates";
import { TListWrapperSection } from "@containers/types";

type TableBodyProps = {
  isLoading?: boolean;
  queryKey?: string;
  data: TableData[];
  columns: TableColumnTypeCustom[];
  renderExpandedRow?: RenderExpandedRow;
  rowHeight?: string | number;
  expandable?: boolean | ((row: any) => boolean);
  small?: boolean;
  iconButton?: boolean;
  rowRenderKey?: (row: any) => any;
  selectable?: boolean;
  BodyRowStyle?: React.CSSProperties;
  selectedRowIdx: any;
  itemsPerPage?: number;
  onClickRow: (itemRow: any, idx: number) => void;
  isFetching?: boolean;
  page?: number;
  emptyState?: TListWrapperSection;
};

const TableBody = ({
  isLoading,
  queryKey,
  data,
  rowHeight,
  rowRenderKey,
  BodyRowStyle,
  onClickRow,
  selectedRowIdx,
  columns,
  itemsPerPage = ROWS_PER_PAGE,
  isFetching,
  emptyState,
  ...props
}: TableBodyProps) => {
  const searchQuery = useAppSelector((state) =>
    queryKey ? selectQueryString(state, queryKey) : undefined,
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [props.page]);

  const skeletonItems = Array.from({ length: itemsPerPage }, (_, i) => ({
    id: `skeleton-${i}`,
  }));
  const itemHeight =
    rowHeight && typeof rowHeight === "number" ? rowHeight : 60;

  if (data?.length === 0 && !isLoading && !isFetching) {
    return (
      <StyledTableBody>
        <tr>
          <td colSpan={columns.length}>
            <ListWrapperWithStates
              section={emptyState}
              isEmpty={emptyState && !searchQuery}
              action={null}
            >
              <NoResultsState searchQuery={searchQuery} />
            </ListWrapperWithStates>
          </td>
        </tr>
      </StyledTableBody>
    );
  }

  if (isLoading || (isFetching && data?.length === 0)) {
    return (
      <UnfoldListItems
        items={skeletonItems}
        renderKey={(row) => row.id}
        height={itemHeight}
        Wrapper={animated.tbody}
        ItemComponent={(row, idx, height) => (
          <TableRowSkeleton
            key={row.id}
            height={height}
            rowHeight={itemHeight}
            columns={columns}
          />
        )}
      />
    );
  }

  return (
    <ErrorCatcher errorID="UnfoldListItems">
      <UnfoldListItems
        key={props?.page}
        items={data}
        renderKey={(row) =>
          `row-${rowRenderKey ? rowRenderKey(row) : row.id ?? row.accID}`
        }
        height={itemHeight}
        animationName="waterfall"
        ItemComponent={(row, idx) => {
          return (
            <TableRow
              isPending={row.displayStatus === "Pending"}
              height={rowHeight}
              rowData={row}
              columns={columns}
              pinkRowBorder={`1px solid ${palette.neutral[20]}`}
              isSelected={idx === selectedRowIdx}
              onOpenSidePanel={() => onClickRow(row, idx)}
              sx={BodyRowStyle}
              {...props}
            />
          );
        }}
        Wrapper={animated.tbody}
      />
    </ErrorCatcher>
  );
};

const StyledTableBody = styled(MuiTableBody)(() => ({
  position: "relative",
  zIndex: "1",
  flexGrow: 1,
}));

export default memo(TableBody);
