interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  color?: string;
}
  const Checkicon = ({ width, height, fill = "none", color = "#039855" }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 57 57"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 56.625C44.033 56.625 56.625 44.033 56.625 28.5C56.625 12.967 44.033 0.375 28.5 0.375C12.967 0.375 0.375 12.967 0.375 28.5C0.375 44.033 12.967 56.625 28.5 56.625ZM42.3444 20.2453C42.9631 19.5028 42.8628 18.3993 42.1203 17.7806C41.3778 17.1619 40.2743 17.2622 39.6556 18.0047L25.4327 35.0722C25.3689 35.1488 25.3109 35.2183 25.2574 35.2821C25.2306 35.2555 25.2027 35.2277 25.1737 35.1987C25.1388 35.164 25.1023 35.1274 25.0638 35.089L17.2374 27.2626C16.554 26.5791 15.446 26.5791 14.7626 27.2626C14.0791 27.946 14.0791 29.054 14.7626 29.7374L22.589 37.5638L22.6451 37.62C22.9567 37.9319 23.3052 38.2808 23.6406 38.5344C24.0364 38.8337 24.6362 39.1769 25.4324 39.1408C26.2287 39.1046 26.7949 38.7085 27.162 38.3746C27.473 38.0918 27.7885 37.7127 28.0706 37.374L28.0706 37.3739L28.1214 37.3129L42.3444 20.2453Z"
        fill={color}
      />
    </svg>
  );
};

export default Checkicon;
