import * as React from "react";
import { palette } from "@palette";
// mui
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import {
  Box,
  TableCell,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableHead,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
// components
import { SelectTableRow } from "./SelectTableRow";
import Pagination from "../pagination";
import { Text } from "@common/Text";
import { DropdownItem } from "@common/Select";
// assets
import { DownIcon, SortDownIcon, SortUpIcon, UpIcon } from "@assets/icons";
// helpers
import {
  TableColumnType,
  TableProps,
  TableData,
  TableOrder,
  getComparator,
  SortLabelAsc,
  SortLabelDsc,
} from "./helpers";
// redux
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setSelected, selectTableSelected } from "@redux/slices/app";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";

export type SelectTableProps = TableProps & {
  checkboxName: string;
};

export const SelectTable: React.FC<SelectTableProps> = ({
  columns,
  small = false,
  data,
  maxHeight,
  rowHeight,
  checkboxName,
  totalRecords,
  withPagination,

  ...props
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectTable = useAppSelector(selectTableSelected);
  const selected = selectTable[checkboxName];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortKey, setSortKey] = React.useState<null | string>(null);
  const [columnLabel, setColumnLabel] = React.useState<undefined | string>();
  const [order, setOrder] = React.useState<TableOrder>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof TableData>("id");
  const open = Boolean(anchorEl);

  const handleOpenMenu =
    (column: TableColumnType) => (event: React.MouseEvent<HTMLElement>) => {
      setSortKey(column.key);
      setColumnLabel(column?.title?.toLowerCase());
      setAnchorEl(event.currentTarget.parentElement);
    };

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<HTMLElement>) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n[checkboxName]);
      dispatch(setSelected({ name: checkboxName, values: newSelected }));
      return;
    }
    dispatch(setSelected({ name: checkboxName, values: [] }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    dispatch(setSelected({ name: checkboxName, values: newSelected }));
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const boxStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderImageSource:
      "linear-gradient(267.62deg, rgba(214, 217, 243, 0.46) 8.2%, rgba(255, 255, 255, 0.46) 82.68%)",
    "& th": {
      padding: "8px 16px",
      borderBottom: "none",
      "&:first-of-type": {
        paddingLeft: "4px",
      },
      "&:last-of-type": {
        paddingRight: 0,
      },
    },
    boxShadow: withPagination
      ? "2px 2px 4px rgba(114, 142, 171, 0.1), -6px -6px 20px #FFFFFF, 4px 4px 20px rgba(200, 200, 200, 0.41)"
      : "none",
    background: "#fff",
    ...(!small && {
      padding: "0 8px 8px",
      borderRadius: "12px",
    }),
  };

  const containerStyle = {
    height: "auto",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "::-webkit-scrollbar-track": {
      background: (theme: Theme) => theme.palette.common.white,
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
    },
    ...(small && {
      mb: 2.5,
      padding: "0 4px 4px",
    }),
    ...(maxHeight && {
      maxHeight: maxHeight,
    }),
  };

  return (
    <Box sx={boxStyle}>
      <TableContainer sx={containerStyle}>
        <MuiTable sx={{ width: "100%", ...props.sx }} {...props}>
          <TableHead sx={{ width: "100%" }}>
            <MuiTableRow
              sx={{
                width: "100%",
                background: "#fff",
                backgroundColor: "white",
              }}
            >
              {columns.map((column, index) => {
                if (column.title) {
                  return (
                    <TableCell
                      key={`head-${column.key}`}
                      sortDirection={orderBy === column.key ? order : false}
                      sx={{ background: "#fff" }}
                    >
                      <Box className={classes.tableHead}>
                        <Stack direction="row" alignItems="center">
                          {index === 0 && (
                            <Checkbox
                              indeterminate={
                                selected.length > 0 &&
                                selected.length < data.length
                              }
                              checked={
                                data.length > 0 &&
                                selected.length === data.length
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all rows",
                              }}
                            />
                          )}
                          <Text variant="headline" color={palette.neutral[800]}>
                            {column.title}
                          </Text>
                        </Stack>
                        {column.sortable && (
                          <>
                            <Box
                              className={classes.sortWrapper}
                              onClick={handleOpenMenu(column)}
                            >
                              <span>
                                <SortUpIcon
                                  fill={palette.neutral[400]}
                                  stroke={palette.neutral[400]}
                                  size={"8px"}
                                />
                              </span>
                              <span>
                                <SortDownIcon
                                  fill={palette.neutral[400]}
                                  stroke={palette.neutral[400]}
                                  size={"8px"}
                                />
                              </span>
                            </Box>

                            <Menu
                              open={open}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              sx={{
                                top: 20,
                                "& .MuiPaper-root": {
                                  width: "212px",
                                  height: "auto",
                                },
                              }}
                            >
                              <DropdownItem>
                                <TableSortLabel
                                  hideSortIcon
                                  direction="asc"
                                  onClick={createSortHandler(sortKey)}
                                >
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "flex",
                                      gap: "9px",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span style={{ marginRight: "2px" }}>
                                      <DownIcon />
                                    </span>

                                    <Text color={palette.neutral[800]}>
                                      {SortLabelAsc(columnLabel)}
                                    </Text>
                                  </Box>
                                </TableSortLabel>
                              </DropdownItem>
                              <DropdownItem>
                                <TableSortLabel
                                  hideSortIcon
                                  direction="desc"
                                  onClick={createSortHandler(sortKey)}
                                >
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "flex",
                                      gap: "9px",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span style={{ marginRight: "2px" }}>
                                      <UpIcon />
                                    </span>
                                    <Text color={palette.neutral[800]}>
                                      {SortLabelDsc(columnLabel)}
                                    </Text>
                                  </Box>
                                </TableSortLabel>
                              </DropdownItem>
                            </Menu>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  );
                }
              })}
              {selected.length > 0 && (
                <TableCell>
                  <Box width="fit-content" marginLeft="auto">
                    <Text color={palette.neutral[800]}>
                      {selected.length}{" "}
                      {selected.length > 1 ? "Customers" : "Customer"} selected
                    </Text>
                  </Box>
                </TableCell>
              )}
            </MuiTableRow>
          </TableHead>

          <TableBody className={classes.tableBody}>
            {data
              .slice()
              .sort(getComparator(order, orderBy))
              .map((row) => {
                const isItemSelected = isSelected(row[checkboxName]);

                return (
                  <SelectTableRow
                    onClick={(event) => handleClick(event, row[checkboxName])}
                    height={rowHeight}
                    columns={columns}
                    rowData={row}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    key={`row-${row.id}`}
                  />
                );
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {withPagination && (
        <Pagination
          numberOfPages={Math.ceil(
            (totalRecords || data.length) / ROWS_PER_PAGE,
          )}
          totalRecords={totalRecords || data.length}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  tableBody: {
    position: "relative",
    borderRadius: "8px",
    boxShadow: `0 0 0 1px ${palette.neutral[100]}`,
    zIndex: "1",

    // for giving shadow to tbody as borderShadow is used for border
    "&::after": {
      content: '""',
      width: "100%",
      height: "100%",
      top: "0",
      borderRadius: "8px",
      position: "absolute",
      overflow: "hidden",
      zIndex: "-1",
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },

    "& tr:not(:first-child)": {
      borderTop: `1px solid ${palette.neutral[100]}`,
    },
    "& tr:first-child td:first-child": {
      borderTopLeftRadius: "8px",
    },
    "& tr:first-child td:last-child": {
      borderTopRightRadius: "8px",
    },
    "& tr:last-child td:first-child": {
      borderBottomLeftRadius: "8px",
    },
    "& tr:last-child td:last-child": {
      borderBottomRightRadius: "8px",
    },
  },
  tableHead: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
  },
  sortWrapper: {
    cursor: "pointer",
    "& span": {
      display: "flex",
      "& :hover": {
        opacity: 0.98,
      },
    },
  },
});
