import { IdentificationCard } from "@assets/icons/RebrandedIcons";
import UserFocus from "@assets/icons/RebrandedIcons/UserFocus";
import { Text } from "@common/Text";
import { Grid } from "@mui/material";
import { palette } from "@palette";
import React from "react";

export const ConfirmationScreen = () => {
  return (
    <Grid width="70%" margin="auto">
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingBottom={2}
      >
        <IdentificationCard
          width={33}
          height={33}
          fill={palette.success.main}
        />
        <UserFocus width={33} height={33} fill={palette.success.main} />
      </Grid>
      <Text
        color={palette.success.main}
        fontSize={18}
        fontWeight="regular"
        lineHeight="120%"
        textAlign="center"
        marginBottom={2}
      >
        ID and Selfie successfully uploaded
      </Text>
      <Text
        fontWeight="regular"
        lineHeight="120%"
        textAlign="center"
        color={palette.neutral[80]}
      >
        We are verifying your identity. This could take some time and you will
        be notified when verified.
      </Text>
    </Grid>
  );
};
