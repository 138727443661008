import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const SendInvitationsBase = ({
  total,
  handleSubmit,
}: {
  total: number;
  handleSubmit: VoidFunction;
}) => {
  const { open, onClose, SlideProps, TransitionProps } = useNiceModal();
  const { isMobileView } = useCustomTheme();
  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose,
        width: "400px",
        SlideProps,
        DialogProps: {
          PaperProps: {
            sx: {
              padding: "24px 24px 16px",
            },
          },
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        title="Send Invitations"
        description={`You are about to send ${total} invitations. Please confirm to continue.`}
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
            letterSpacing: "-0.01em",
          },
        }}
        closeButtonSize={22}
        onClose={onClose}
      />
      <ModalActions
        animationDelay={80}
        containerProps={{
          sx: {
            paddingTop: "20px",
            ...(isMobileView && {
              flexDirection: "column-reverse",
            }),
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: onClose,
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
        primaryAction={{
          type: "button",
          onClick: handleSubmit,
          label: "Confirm",
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
      />
    </ModalFactory>
  );
};

const SendInvitationsModal = NiceModal.create(SendInvitationsBase);
export default SendInvitationsModal;
