import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import ReactPlaceholder from "react-placeholder";
import { RoundShape } from "react-placeholder/lib/placeholders";
import { Box } from "@mui/material";

const WIDTH = 48;
const HEIGHT = 48;

export const useEnterpriseLogo = () => {
  const pathname = window.location.href;
  const isEnabledFetching =
    !pathname.startsWith("https://local.") &&
    !pathname.startsWith("https://portal.");

  const { data, isFetching, isError } = useQuery(
    "get_enterpise_public_info",
    async () => {
      const data = customInstance({
        url: "/merchants-public",
        method: "GET",
      });

      return data;
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      enabled: isEnabledFetching,
    },
  );

  return {
    url: data?.imageURL,
    isFetching,
    isError,
    landscapeImageURL: data?.landscapeImageURL,
  };
};

const mapPos = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};
type posType = "left" | "center" | "right";

const awesomePlaceholder = (position: posType) => (
  <div
    className="my-awesome-placeholder"
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: mapPos[position],
    }}
  >
    <RoundShape
      style={{
        width: WIDTH,
        height: HEIGHT,
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      color="white"
    />
  </div>
);

export const MainLogo = ({
  defaultLogo,
  position = "left",
}: {
  defaultLogo: React.ReactElement;
  position?: posType;
}) => {
  const { landscapeImageURL, url, isError, isFetching } = useEnterpriseLogo();
  const URL = landscapeImageURL || url;
  if (isError) {
    return defaultLogo;
  }
  return (
    <ReactPlaceholder
      showLoadingAnimation
      ready={!isFetching}
      customPlaceholder={awesomePlaceholder(position)}
    >
      {URL ? (
        <Box
          width={landscapeImageURL ? 80 : WIDTH}
          height={landscapeImageURL ? 48 : HEIGHT}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 8,
              objectFit: "contain",
            }}
            src={`${URL}/thumb`}
            data-testid="main-logo"
          />
        </Box>
      ) : (
        defaultLogo
      )}
    </ReactPlaceholder>
  );
};
