import { signal } from "@preact/signals-react";
import {
  defaultHeadingsProps,
  defaultMobileHeadingsProps,
  headingsSignal,
  mobileHeadingsSignal,
  t,
} from "../consts/headings";
import { cloneDeep } from "lodash";

// Initial state
const INITIAL_STRUCTURED_TREE: any = {};
const INITIAL_SELECTED_ITEM: any = null;
const INITIAL_PREVIEW_MODE = false;
const INITIAL_GLOBAL_COLORS = {
  GENERAL: {} as any,
  CUSTOM: {} as any,
};
const INITIAL_DRAFT_CREATED = false;
const INITIAL_PUBLISH_STATE = false;
const INITIAL_GLOBAL_TEXT_STYLES = {
  fontFamily: { name: "Roboto", value: "Roboto, sans-serif" },
  fontWeight: { name: "Regular", value: 400 },
  fontSize: 20,
  lineHeight: 100,
  letterSpacing: 0,
  textColor: null as any,
};
const INITIAL_LAST_HEADING_TYPE_SELECTED = "h2";
const INITIAL_LAST_ITEM_SELECTED: string | null = null;

export const DEFAULT_BTN_STYLES = {
  padding: "8px 16px",
  fontSize: "14px",
  borderRadius: "40px",
  backgroundColor: { name: "Primary", color: "#3D50FF" },
  textColor: { name: "", color: "" },
};

export const DEFAULT_BORDER_COLOR = {
  name: "Primary",
  color: "#3D50FF",
};

export enum DeviceType {
  DESKTOP = "Desktop",
  TABLET = "Tablet",
  MOBILE = "Mobile",
}

export const ADB_LAYOUT_MAX_WIDTH = {
  [DeviceType.DESKTOP]: "none",
  [DeviceType.TABLET]: "768px",
  [DeviceType.MOBILE]: "390px",
};
// Setting Initial States
export const structuredTreeSignal = signal(INITIAL_STRUCTURED_TREE);

export const loadingImagesSignal = signal<(string | number)[]>([]);

export const selectedItem = signal<any>(INITIAL_SELECTED_ITEM);

export const isPreviewMode = signal<boolean>(INITIAL_PREVIEW_MODE);

export const globalColors = {
  general: signal<any>(INITIAL_GLOBAL_COLORS.GENERAL),
  custom: signal<any>(INITIAL_GLOBAL_COLORS.CUSTOM),
};

export const isDraftCreated = signal<boolean>(INITIAL_DRAFT_CREATED);
export const isDraftEnabled = signal<boolean>(true);

export const isPublished = signal<boolean>(INITIAL_PUBLISH_STATE);
export const isPublishEnabled = signal<boolean>(true);

export const formUpdatedAt = signal<number>(0);

export const selectedDeviceType = signal<DeviceType>(DeviceType.DESKTOP);

export const isWidgetDragging = signal<boolean>(false);

export const isDraggablePositionCaptured = signal<boolean>(false);
export const capturedDraggablePosition = signal<{ x: number; y: number }>({
  x: 0,
  y: 0,
});
export const intersectionRatio = signal<number>(1);
export const shouldAutoScroll = signal<boolean>(true);
export const scrollableContainerRect = signal<any>(null);

export const globalTextStyles = {
  fontFamily: signal(INITIAL_GLOBAL_TEXT_STYLES.fontFamily),
  fontWeight: signal(INITIAL_GLOBAL_TEXT_STYLES.fontWeight),
  fontSize: signal(INITIAL_GLOBAL_TEXT_STYLES.fontSize),
  lineHeight: signal(INITIAL_GLOBAL_TEXT_STYLES.lineHeight),
  letterSpacing: signal(INITIAL_GLOBAL_TEXT_STYLES.letterSpacing),
  textColor: signal<any>(INITIAL_GLOBAL_TEXT_STYLES.textColor),
};

export const lastHeadingTypeSelected = signal(
  INITIAL_LAST_HEADING_TYPE_SELECTED,
);

export const lastMobileHeadingTypeSelected = signal<string>(
  INITIAL_LAST_HEADING_TYPE_SELECTED,
);

export const lastItemSelected = signal<string | null>(
  INITIAL_LAST_ITEM_SELECTED,
);

export const globalButtonStyles = {
  padding: signal(DEFAULT_BTN_STYLES.padding),
  fontSize: signal(DEFAULT_BTN_STYLES.fontSize),
  borderRadius: signal(DEFAULT_BTN_STYLES.borderRadius),
  backgroundColor: signal(DEFAULT_BTN_STYLES.backgroundColor),
  textColor: signal(DEFAULT_BTN_STYLES.textColor),
};

export const isBuilderError = signal<boolean>(false);

// Signal state reset functionality
export const resetGlobalButtonStyle = (buttonsData?: any) => {
  if (buttonsData) {
    globalButtonStyles.padding = cloneDeep(buttonsData.padding);
    globalButtonStyles.fontSize = cloneDeep(buttonsData.fontSize);
    globalButtonStyles.textColor = cloneDeep(buttonsData.textColor);
    globalButtonStyles.backgroundColor = cloneDeep(buttonsData.backgroundColor);
    globalButtonStyles.borderRadius = cloneDeep(buttonsData.borderRadius);
  } else {
    globalButtonStyles.padding.value = DEFAULT_BTN_STYLES.padding;
    globalButtonStyles.fontSize.value = DEFAULT_BTN_STYLES.fontSize;
    globalButtonStyles.textColor.value = DEFAULT_BTN_STYLES.textColor;
    globalButtonStyles.backgroundColor.value =
      DEFAULT_BTN_STYLES.backgroundColor;
    globalButtonStyles.borderRadius.value = DEFAULT_BTN_STYLES.borderRadius;
  }
};

export const resetGlobalTypographyStyle = (
  signal: any,
  defaultTypographyProps: any,
  headingsData?: any,
) => {
  lastHeadingTypeSelected.value = "h1";
  Object.entries(signal).forEach(([key, value]) => {
    Object.entries(value as any).forEach(([propKey]) => {
      const headingKey = key as t;
      const propertyKey = propKey as keyof (typeof signal)[t];
      if (headingsData) {
        (signal as any)[key] = cloneDeep(headingsData[key]);
      } else {
        (signal as any)[key][propKey].value =
          defaultTypographyProps[headingKey][propertyKey];
      }
    });
  });
};

export const changeTypographyColorValues = (
  colorName: string,
  color: any,
  newName?: string,
) => {
  const updateHeadingSignalColors = (signal: any) => {
    Object.entries(signal).forEach(([_, value]: any) => {
      if (value.textColor?.value?.name === colorName) {
        value.textColor.value = {
          name: newName || colorName,
          color,
        };
      }
    });
  };
  updateHeadingSignalColors(headingsSignal);
  updateHeadingSignalColors(mobileHeadingsSignal);
};

export const resetGlobalTypographyToDefaultColor = (colorName: string) => {
  const updateHeadingSignalColors = (signal: any, isMobile: boolean) => {
    Object.entries(signal).forEach(([key, value]: any) => {
      if (value.textColor?.value?.name === colorName) {
        const val: any = isMobile
          ? defaultMobileHeadingsProps
          : defaultHeadingsProps;
        value.textColor.value = val[key].textColor;
      }
    });
  };
  updateHeadingSignalColors(headingsSignal, false);
  updateHeadingSignalColors(mobileHeadingsSignal, true);
};

export const resetGlobalButtonToDefaultColors = (colorName: string) => {
  if (globalButtonStyles.backgroundColor.value?.name === colorName) {
    globalButtonStyles.backgroundColor.value =
      DEFAULT_BTN_STYLES.backgroundColor;
  }
  if (globalButtonStyles.textColor.value?.name === colorName) {
    globalButtonStyles.textColor.value = DEFAULT_BTN_STYLES.textColor;
  }
};

export const changeButtonGlobalValues = (
  colorName: string,
  color: any,
  newName?: string,
) => {
  if (globalButtonStyles.backgroundColor.value?.name === colorName) {
    globalButtonStyles.backgroundColor.value = {
      name: newName || colorName,
      color,
    };
  }
  if (globalButtonStyles.textColor.value?.name === colorName) {
    globalButtonStyles.textColor.value = { name: newName || colorName, color };
  }
};

export const resetAllSignals = () => {
  setTimeout(() => {
    structuredTreeSignal.value = INITIAL_STRUCTURED_TREE;
    selectedItem.value = INITIAL_SELECTED_ITEM;
    isPreviewMode.value = INITIAL_PREVIEW_MODE;

    globalColors.general.value = INITIAL_GLOBAL_COLORS.GENERAL;
    globalColors.custom.value = INITIAL_GLOBAL_COLORS.CUSTOM;

    isDraftCreated.value = INITIAL_DRAFT_CREATED;
    isPublished.value = INITIAL_PUBLISH_STATE;
    isDraftEnabled.value = true;
    isPublishEnabled.value = true;
    formUpdatedAt.value = 0;

    globalTextStyles.fontFamily.value = INITIAL_GLOBAL_TEXT_STYLES.fontFamily;
    globalTextStyles.fontWeight.value = INITIAL_GLOBAL_TEXT_STYLES.fontWeight;
    globalTextStyles.fontSize.value = INITIAL_GLOBAL_TEXT_STYLES.fontSize;
    globalTextStyles.lineHeight.value = INITIAL_GLOBAL_TEXT_STYLES.lineHeight;
    globalTextStyles.letterSpacing.value =
      INITIAL_GLOBAL_TEXT_STYLES.letterSpacing;
    globalTextStyles.textColor.value = INITIAL_GLOBAL_TEXT_STYLES.textColor;

    lastHeadingTypeSelected.value = INITIAL_LAST_HEADING_TYPE_SELECTED;
    lastItemSelected.value = INITIAL_LAST_ITEM_SELECTED;

    resetGlobalButtonStyle();

    resetGlobalTypographyStyle(headingsSignal, defaultHeadingsProps);
    resetGlobalTypographyStyle(
      mobileHeadingsSignal,
      defaultMobileHeadingsProps,
    );

    isBuilderError.value = false;

    isWidgetDragging.value = false;
    isDraggablePositionCaptured.value = false;
    capturedDraggablePosition.value = { x: 0, y: 0 };
    intersectionRatio.value = 1;
    shouldAutoScroll.value = true;
    scrollableContainerRect.value = null;
  }, 10);
};
