import React from "react";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
// mui
import { Menu, Paper, Collapse, MenuItem, Stack, Box } from "@mui/material";
// components
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import DonationKeyValue from "./DonationKeyValue";
import PurchaseItemCardHeader from "./PurchaseItemCardHeader";
import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { HiddenComponent } from "containers/HiddenComponent";
import { PurchaseItem } from "@components/DonationList/Table/FundraisersDonationsTable";
import useReportMenuActions, {
  MenuActionType,
  CampaignType,
} from "../hooks/useReportMenuActions";
import { Grid } from "@mui/material";
import { TransactionTableTag } from "@common/Tag/TransactionTableTag";
import { MobileTransactionCardWrapper } from "@containers/MobileTransactionCardWrapper";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";
import { DMenu } from "@components/DonationList/Table/FundraisersDonationsTable";
import { getStatus } from "@components/ManageMoney/TransactionTable/transactions.helpers";

type MobilePurchaseItemProps = {
  rowData: PurchaseItem;
  campaignType: CampaignType;
};

export const MobilePurchaseItem_V2 = ({
  rowData,
  campaignType,
}: {
  rowData: any;
  campaignType: CampaignType;
}) => {
  const isPending = rowData.transactionProcessingState === "ending";

  return (
    <MobileTransactionCardWrapper
      onClick={() => {
        NiceModal.show(TRANSACTION_INFO_MODAL, {
          data: rowData,
          DMenu,
          campaignType,
        });
      }}
    >
      <Grid sx={{ opacity: isPending ? 0.5 : 1 }}>
        <Text variant="body" color={palette.neutral[80]} paddingBottom={1}>
          {rowData.createdAt}
        </Text>
        <Box sx={{ display: "flex" }} gap={1}>
          <Text variant="body">{rowData.product?.name}</Text>
          <Text
            variant="body"
            color={palette.neutral[80]}
            textTransform="capitalize"
          >
            x{rowData.quantity}
          </Text>
        </Box>
      </Grid>
      <Grid>
        <TransactionTableTag
          type={getStatus(rowData).displayStatus.toLowerCase() as any}
        />
      </Grid>
    </MobileTransactionCardWrapper>
  );
};

const MobilePurchaseItem = ({
  rowData,
  campaignType,
}: MobilePurchaseItemProps) => {
  const [openRow, setOpenRow] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleOpenActionModal = (modalName: string, args?: any) => {
    NiceModal.show(modalName, args ?? {});
    setAnchorEl(null);
  };

  const { menuActions } = useReportMenuActions(
    rowData,
    handleOpenActionModal,
    campaignType,
  );

  const recurringInterval =
    rowData.recurringInterval === "once"
      ? "One-Time"
      : rowData.recurringInterval.charAt(0).toUpperCase() +
        rowData.recurringInterval.slice(1);

  return (
    <Paper sx={cardStyle}>
      <Collapse in={!openRow}>
        <PurchaseItemCardHeader
          name={rowData.product?.name}
          transactionID={rowData.transactionID}
          revenue={rowData.revenue}
          price={rowData.price}
          recurringInterval={recurringInterval}
          onClick={() => setOpenRow(!openRow)}
        />
      </Collapse>

      <Collapse in={openRow}>
        <PurchaseItemCardHeader
          name={rowData.product?.name}
          transactionID={rowData.transactionID}
          revenue={rowData.revenue}
          price={rowData.price}
          recurringInterval={recurringInterval}
          onClick={() => setOpenRow(!openRow)}
        />

        <Stack direction="column" sx={{ px: "8px", mt: "20px" }}>
          <Button
            background="secondary"
            endIcon={<HorizontalMoreIcon width={15} height={15} />}
            fullWidth
            sx={{ mb: "16px" }}
            onClick={handleOpenMenu}
          >
            Actions
          </Button>

          <DonationKeyValue title="Full Name" value={rowData.product?.name} />
          <DonationKeyValue title="Email" value={rowData.email} highlight />
          <DonationKeyValue
            title="Transaction ID"
            value={rowData.transactionID}
          />
          <DonationKeyValue
            title="Total (USD)"
            value={rowData.totalAmount}
            isAmount
            highlight
          />
          <DonationKeyValue title="Processed" value={rowData.createdAt} />
          <DonationKeyValue
            title="Name on Card"
            value={rowData.product?.name}
            highlight
          />
          <DonationKeyValue title="Revenue Source" value={rowData.revenue} />
          <DonationKeyValue
            title="Type"
            value={"Sales Transaction"}
            highlight
          />
          <DonationKeyValue title="Recurrence" value={recurringInterval} />
          <DonationKeyValue title="Next Transaction" value={"Next"} highlight />
          <DonationKeyValue
            title="Donated"
            value={rowData.price * rowData.quantity}
            isAmount
          />

          <Stack
            direction="column"
            sx={{
              backgroundColor: palette.liftedWhite.main,
              borderRadius: "8px",
            }}
          >
            <DonationKeyValue
              title="Amount (USD)"
              value={rowData.price}
              isAmount
            />
            <DonationKeyValue
              title="*Visa Fee (USD)"
              value={rowData.fees}
              isAmount
            />
            <DonationKeyValue
              title="Charged (USD)"
              value={rowData.price}
              isAmount
            />
          </Stack>
          <Text
            color={palette.black[100]}
            variant="body"
            lineHeight="17px"
            fontWeight="book"
            sx={{ textAlign: "center", margin: "16px 0" }}
          >
            *Visa fee deducted from donation
          </Text>
        </Stack>
      </Collapse>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            background: "#FAFAFA",
            boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
          },
        }}
      >
        {menuActions.map((action: MenuActionType) => {
          if (action.hidden) {
            return (
              <HiddenComponent key={action.title}>
                <MenuItem onClick={action.onClick} sx={menuItemStyle}>
                  {action.title}
                </MenuItem>
              </HiddenComponent>
            );
          }
          return (
            <MenuItem
              key={action.title}
              onClick={action.onClick}
              sx={menuItemStyle}
            >
              {action.title}
            </MenuItem>
          );
        })}
      </Menu>
    </Paper>
  );
};

const menuItemStyle = {
  padding: "4px 8px",
  height: "40px",
};

const cardStyle = {
  width: "100%",
  padding: 0,
  borderRadius: "8px",
  backgroundColor: "#FFF",
  border: "2px solid #D3EAFF",
  boxShadow: "none",
};

export default MobilePurchaseItem;
