import { Button } from "@common/Button";
import { Button_V2Props } from "@common/Button/Button";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { Stack, StackProps, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  receiverName: string;
  senderName: string;
};

const PreviewInvitationModalCard = ({ receiverName, senderName }: Props) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Container isMobileView={isMobileView}>
      <CustomText fontSize="32px">Hello {receiverName}</CustomText>
      <CustomText>
        You have been invited to join {receiverName} as an owner. To get
        started, simply click the button below to complete your merchant
        profile:
      </CustomText>
      <InviteButton>Accept the invite</InviteButton>
      <CustomText>
        By joining {receiverName}, you will gain access to a platform where you
        can manage your merchant information and connect with our provider
        business profile.
      </CustomText>
      <CustomText>
        Thank you, <br /> {senderName}
      </CustomText>
    </Container>
  );
};

export default PreviewInvitationModalCard;

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMobileView",
})<StackProps & { isMobileView: boolean }>(({ isMobileView }) => ({
  gap: "24px",
  backgroundColor: palette.neutral.white,
  borderRadius: "50px",
  padding: isMobileView ? "40px 24px" : "56px",
  boxShadow:
    "0 20px 40px 0 rgba(0, 0, 0, 0.05),0 2px 3px 0 rgba(0, 0, 0, 0.1);",
}));

const InviteButton = styled(Button)<Button_V2Props>(() => ({
  fontWeight: "400",
  fontSize: "18px",
  pointerEvents: "none",
  width: "186px",
  margin: "0 auto",
  textWrap: "nowrap",
  background: "primary",
}));

const CustomText = styled(Text)<ITextProps & { fontSize?: string }>(
  ({ fontSize }) => ({
    fontSize: fontSize || "16px",
    fontWeight: "400",
    color: palette.neutral[100],
    lineHeight: "100%",
  }),
);
