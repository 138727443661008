import React, { useEffect, useMemo, useState } from "react";
import Chip, { ChipOption } from "./Chip";
import CustomDateRangePicker from "@pages/Dashboard/components/CustomRangePicker";
import { useFormContext } from "react-hook-form";
import { toUnixDateFormat } from "@utils/date.helpers";
import { generateCustomLabel, getDateValue } from "./utils";
import moment from "moment";

interface Props {
  dateName: string;
  selectedDate?: any;
  rangeSaveCallback: (value: {
    startDate?: Date;
    endDate?: Date;
    label?: string;
    val?: string | number | undefined | ChipOption;
    dateName: string;
  }) => void;
}

function ChipsContainer({ dateName, selectedDate, rangeSaveCallback }: Props) {
  const [rangePickerAnchorEl, setRangePickerAnchorEl] = useState(null);
  const selectedDateValues = selectedDate?.[dateName] || selectedDate;

  const [selectedValue, setSelectedValue] = useState<
    | {
        startDate?: Date;
        endDate?: Date;
        label?: string | number;
        val?: string | number | undefined | ChipOption;
      }
    | undefined
  >(selectedDateValues);
  useEffect(() => {
    setSelectedValue(selectedDateValues);
  }, [selectedDateValues]);
  const { setValue, watch } = useFormContext();
  const values = watch();
  const customInitialRange = {
    startDate: selectedValue?.startDate
      ? toUnixDateFormat(selectedValue.startDate)
      : undefined,
    endDate: selectedValue?.endDate
      ? toUnixDateFormat(selectedValue.endDate)
      : undefined,
  };

  const handleSelect = (event: React.MouseEvent<any>) =>
    setRangePickerAnchorEl(event.currentTarget);

  const handleSave = () => {
    if (selectedValue) {
      setValue(
        dateName,
        getDateValue({
          modifier: "is between",
          range: selectedValue,
          prefix: dateName,
        }),
      );
      rangeSaveCallback({
        ...selectedValue,
        label: "Custom",
        dateName,
        val: `${selectedValue?.startDate} ${selectedValue?.endDate}`,
      });
    }

    setRangePickerAnchorEl(null);
  };

  const handleSelectChip = (
    val?: string | number | undefined | ChipOption,
    label?: string,
  ) => {
    const isValue = !!val;
    rangeSaveCallback({
      startDate: undefined,
      endDate: undefined,
      label: isValue ? label : undefined,
      val: val,
      dateName,
    });
    isValue && setSelectedValue(undefined);
    setValue(dateName, val || "");
  };
  const signupDateOptions = useMemo(
    () => getSignupDateOptions(dateName),
    [dateName],
  );
  const customLabel = useMemo(() => {
    if (!rangePickerAnchorEl) {
      return generateCustomLabel({
        endDate: selectedValue?.endDate,
        startDate: selectedValue?.startDate,
      });
    }
  }, [rangePickerAnchorEl, selectedValue]);

  const isThisSetSelected =
    !!values[dateName] &&
    signupDateOptions?.some((obj) => obj.value === values[dateName]);

  return (
    <>
      {signupDateOptions.map((option) => {
        const isCustom =
          option?.value === "custom" && customLabel !== undefined;

        const refinedOption = isCustom
          ? {
              ...option,
              label: `Custom${customLabel}`,
            }
          : option;

        return (
          <Chip
            key={refinedOption.value}
            item={refinedOption}
            onSelect={(e) => handleSelectChip(e, option?.label)}
            selected={
              isCustom
                ? !!customLabel
                : isThisSetSelected || selectedValue?.startDate
                ? option.value === values[dateName]
                : selectedValue?.label === option?.label && !!selectedValue?.val
            }
            {...(isCustom && {
              onClick: handleSelect,
            })}
            deleteCallback={() => {
              setSelectedValue(
                isCustom && selectedDateValues?.startDate
                  ? undefined
                  : selectedDateValues?.startDate
                  ? selectedDateValues
                  : selectedDateValues?.label === option?.label
                  ? undefined
                  : selectedDateValues,
              );
              setValue(dateName, "");
              rangeSaveCallback({
                startDate: undefined,
                endDate: undefined,
                dateName,
                label: undefined,
                val: undefined,
              });
            }}
          />
        );
      })}

      <CustomDateRangePicker
        customInitialRange={customInitialRange}
        setRangePickerAnchorEl={setRangePickerAnchorEl}
        rangePickerAnchorEl={rangePickerAnchorEl}
        isNewStyle
        handleSave={handleSave}
        onSelection={(e: { start: string; end: string }) => {
          setSelectedValue({
            startDate: moment(e.start, "MM-DD-YYYY").toDate(),
            endDate: moment(e.end, "MM-DD-YYYY").toDate(),
          });
        }}
        onCloseCallback={() => setSelectedValue(undefined)}
      />
    </>
  );
}

export default ChipsContainer;

const getSignupDateOptions = (prefix: string): ChipOption[] => [
  {
    label: "Today",
    value: getDateValue({ modifier: "on", date: new Date(), prefix }),
  },
  {
    label: "Last 7 days",
    value: getDateValue({ modifier: "in the last", days: 7, prefix }),
  },
  {
    label: "Last 30 days",
    value: getDateValue({ modifier: "in the last", days: 30, prefix }),
  },
  {
    label: "Custom",
    value: "custom",
  },
];
