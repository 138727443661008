import React from "react";
import { palette } from "@palette";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
} from "@mui/material";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";

export type RadioProps = MuiRadioProps & {
  size?: "small" | "medium";
  title?: string;
  helper?: string;
  titleProps?: ITextProps;
  radioIconSize?: string;
};

export const Radio: React.FC<RadioProps> = ({
  size,
  title,
  helper,
  titleProps,
  radioIconSize,
  ...rest
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <MuiRadio
        sx={{
          padding: 0,
        }}
        disableRipple
        checkedIcon={
          <BpCheckedIcon className="RadioIcon" radioIconSize={radioIconSize} />
        }
        icon={<BpIcon className="RadioIcon" radioIconSize={radioIconSize} />}
        size={size}
        {...rest}
      />
      <Stack direction="column" gap="2px" justifyContent="flex-start">
        <Text
          fontSize="16px"
          lineHeight="19px"
          letterSpacing="-0.01em"
          fontWeight="regular"
          color={palette.black[200]}
          {...titleProps}
        >
          {title}
        </Text>
        <Text
          fontSize="14px"
          lineHeight="17px"
          letterSpacing="-0.01em"
          fontWeight="regular"
          color={palette.gray[300]}
        >
          {helper}
        </Text>
      </Stack>
    </Stack>
  );
};

const BpIcon = styled("span")(
  ({ radioIconSize }: { radioIconSize?: string }) => ({
    border: `2px solid ${palette.liftedWhite[300]}`,
    position: "relative",
    borderRadius: "50%",
    height: radioIconSize || "24px",
    width: radioIconSize || "24px",
    backgroundColor: palette.liftedWhite.main,
    ".Mui-focusVisible &": {
      outline: "none",
    },
    "input:hover ~ &": {
      backgroundColor: palette.neutral.white,
      border: `2px solid ${palette.gray[300]}`,
    },
    "input:active ~ &": {
      backgroundColor: palette.neutral.white,
      border: `2px solid ${palette.gray[300]}`,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      border: "none",
      backgroundColor: palette.gray[100],

      "&:before": {
        backgroundColor: palette.liftedWhite[300],
      },
    },
  }),
);

const BpCheckedIcon = styled(BpIcon)({
  "&:before": {
    position: "absolute",
    content: '""',
    width: "14px",
    top: "50%",
    left: "50%",
    height: "14px",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.black[200],
  },
});
