import RESOURCE_BASE from "@constants/permissions";
import { useAppSelector } from "@redux/hooks";
import { selectIsAllowed } from "@redux/slices/accessControl/accessControl";
import { TAccessControlOperations } from "@redux/slices/accessControl/types";
import { checkPortals } from "@utils/routing";
import { composePermission } from "./utils";

export type TUseAccessControl = {
  withPortal?: boolean;
  operation: TAccessControlOperations;
  resource: string;
};

const useAccessControl = ({
  resource,
  operation,
  withPortal = false,
}: TUseAccessControl) => {
  const formattedResource = withPortal
    ? getPermissionByPortal(resource)
    : resource;

  const isAllowed = useAppSelector((state) =>
    selectIsAllowed(state, { resource: formattedResource, operation }),
  );

  return isAllowed;
};

const getPermissionByPortal = (resource: string) => {
  const { isAcquirerPortal, isEnterprisePortal } = checkPortals();

  const PORTAL = isAcquirerPortal
    ? RESOURCE_BASE.ACQUIRER
    : isEnterprisePortal
    ? RESOURCE_BASE.ENTERPRISE
    : RESOURCE_BASE.MERCHANT;

  return composePermission(PORTAL, resource);
};

export default useAccessControl;
