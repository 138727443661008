import UploadedFileCard from "@components/Disputes/RespondToDispute/components/UploadedFileCard";
import { useWatch } from "react-hook-form";
import { Stack } from "@mui/material";
import { RespondToDisputeFormType } from "../types";

type FilesType = RespondToDisputeFormType["caseFiles"];
const UploadedFiles = () => {
  const files: FilesType = useWatch({ name: "caseFiles" });

  return (
    <Stack gap={1}>
      {files.map((value, index) => (
        <UploadedFileCard
          key={`uploaded-file-${index}`}
          index={index}
          {...value}
        />
      ))}
    </Stack>
  );
};

export default UploadedFiles;
