import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import { Text } from "@common/Text";
import { MoreActionButton } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/MoreActionButton";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward"
import {
  Box,
  Grid,
  IconButton,
  Stack,
  StackProps,
  useMediaQuery,
} from "@mui/material";
import { palette } from "@palette";
import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { Button } from "@common/Button";
import { NewEyeIcon } from "@assets/icons";
import ExpandIcon from "@assets/icons/ExpandIcon";
import { useTheme, styled } from "@mui/material/styles";
import placeholder from "@assets/images/Placeholder.png";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { MerchantPreviewHeaderAction } from "@components/Merchants/MerchantPreview/hooks/useMerchantPreview";

interface Props {
  onCloseDrawer: () => void;
  actions: MerchantPreviewHeaderAction[];
  handlePrevious: () => void;
  handleNext: () => void;
  handlePreview: () => void;
  handleExpand: () => void;
  nextPrevState: {
    isLast: boolean;
    isFirst: boolean;
  };
  imageURL?: string;
}

function Header({
  onCloseDrawer,
  actions,
  handlePrevious,
  handleNext,
  handlePreview,
  handleExpand,
  nextPrevState,
  imageURL,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Stack
        flexDirection="row"
        width="100%"
        padding="1px 24px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container alignItems="center" gap="16px">
          <IconButton
            onClick={onCloseDrawer}
            size="small"
            className={classes.iconBtn}
          >
            <CustomArrowRight />
          </IconButton>
          <div
            style={{
              width: 1,
              height: "12px",
              borderLeft: `1px solid ${palette.neutral[30]}`,
            }}
          />
          <Button
            size="small"
            className={`${classes.iconBtn} ${classes.iconBtnText}`}
            onClick={handlePrevious}
            startIcon={<ArrowUpward width="20px" height="20px" />}
            disabled={nextPrevState.isFirst}
          >
            <Text sx={{ paddingLeft: "8px" }}>Previous</Text>
          </Button>
          <Button
            size="small"
            className={`${classes.iconBtn} ${classes.iconBtnText}`}
            onClick={handleNext}
            endIcon={<ArrowDownward width="20px" height="20px" />}
            disabled={nextPrevState.isLast}
          >
            <Text sx={{ paddingRight: "8px" }}>Next</Text>
          </Button>
        </Grid>
        <Stack
          gap={0}
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Button
            size={"medium"}
            endIcon={<NewEyeIcon />}
            background="tertiary"
            fullWidth={!isDesktop}
            onClick={handlePreview}
            sx={{
              marginX: 0,
              paddingX: 0,
            }}
            data-testid="campaign-header-preview-button"
          >
            Preview
          </Button>
          <Button
            size={"medium"}
            endIcon={<ExpandIcon />}
            background="tertiary"
            fullWidth={!isDesktop}
            onClick={handleExpand}
            data-testid="campaign-header-expand-button"
          >
            Expand
          </Button>
          {actions.filter((x) => !x.hidden).length ? (
            <MoreActionButton onClick={onOpenMenu} />
          ) : null}
        </Stack>
      </Stack>
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
      />

      <Box paddingTop="10px" width="100%">
        <FadeUpWrapper delay={80}>
          {imageURL && (
            <Box
              position="relative"
              component="div"
              paddingRight="24px"
              paddingLeft="24px"
              width="100%"
              zIndex={1}
            >
              <Box
                onClick={handlePreview}
                component="img"
                sx={imageStyle}
                alt="Campaign image"
                src={imageURL + "/original"}
              />
              <BackgroundImageContainer backgroundSrc={imageURL} />
            </Box>
          )}
        </FadeUpWrapper>
      </Box>
    </>
  );
}

export default memo(Header);

const useStyles = makeStyles((theme: any) => ({
  bodyWrapper: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
    },
  },
  tagWrapper: {
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      display: "flex",
      width: "100%",
    },
  },
  container: {
    padding: "8px 24px",
    height: "100%",
    alignItems: "baseline",
  },
  overviewWrapper: {
    width: "inherit",
    background: palette.neutral[10],
    padding: "8px 16px",
    borderRadius: "12px",
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  overviewHeader: {
    fontSize: "24px",
    fontWeight: "350",
    lineHeight: "135%",
    letterSpacing: "-0.24px",
  },
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    "&:hover": {
      background: palette.neutral[20],
      boxShadow: "none",
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    color: palette.neutral[80],
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
      boxShadow: "none",
    },
  },
}));

interface BackgroundImageProps extends StackProps {
  backgroundSrc: string;
}
const BOX_BLURED_IMAGE_HEIGHT = "200px";

const BackgroundImageContainer = styled<React.FC<BackgroundImageProps>>(Stack, {
  shouldForwardProp: (prop: any) => prop !== "backgroundSrc",
})(({ backgroundSrc }: any) => ({
  position: "absolute",
  inset: 0,
  height: BOX_BLURED_IMAGE_HEIGHT,
  background: `url('${backgroundSrc}/small')`,
  filter: "blur(40px)",
  paddingTop: "30px",
  opacity: "50%",
  zIndex: -1,
}));

const imageStyle = {
  height: "190px",
  width: "100%",
  maxHeight: "190px",
  borderRadius: "8px",
  objectFit: "cover",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
  },
};
