import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";

type Props = {
  statusName: string;
  onClick: () => void;
  loading: boolean;
};

const SuspendResumeButton = (props: Props) => {
  const { statusName, onClick, loading } = props;
  const { isMobileView } = useCustomTheme();
  const isSuspended = statusName === "suspended";
  const title = isSuspended ? "Resume" : "Suspend Account";

  if (["approved", "suspended"].includes(statusName) && isMobileView) {
    return (
      <Container>
        <SuspendResumeButtonStyled onClick={onClick} isSuspended={isSuspended}>
          {title}
        </SuspendResumeButtonStyled>
        <Stack width={"20px"}>
          {loading && (
            <LoadingSpinner
              sx={{ height: "18px", maxWidth: "20px" }}
              spinnerProps={{ size: "14px", thickness: 4 }}
              color={isSuspended ? palette.neutral[80] : palette.tag.error.text}
            />
          )}
        </Stack>
      </Container>
    );
  } else return null;
};

const SuspendResumeButtonStyled = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isSuspended",
})((props: { isSuspended: boolean }) => ({
  fontSize: "18px",
  textAlign: "center",
  color: props.isSuspended ? palette.neutral[80] : palette.tag.error.text,
  textDecoration: "underline",
  paddingRight: "8px",
}));
const Container = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  paddingTop: "24px",
  justifyContent: "center",
}));

export default SuspendResumeButton;
