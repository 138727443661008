import { CaretDown } from "@assets/icons";
import MultipleInputs from "@common/Autocomplete/MultipleInputs";
import { Button } from "@common/Button";
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { ParsedRole } from "@services/api/accounts";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { MemberRoleName } from "@customTypes/team.member";
import { getIndefiniteArticle } from "@utils/index";
import { useInviteTeamMember } from "../hooks/useInviteTeamMember";

type ModalProps = {
  label: string;
  roleName: MemberRoleName;
};

const AddMemberModal = NiceModal.create(({ label, roleName }: ModalProps) => {
  const modal = useModal();
  const onCancel = () => modal.hide();
  const { handleInvite, isLoading } = useInviteTeamMember({});

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      emailList: [],
    },
    mode: "onChange",
    reValidateMode: "onBlur",
  });
  const {
    formState: { isValid, errors },
  } = methods;

  const handleSubmit = () => {
    if (!errors.emailList?.length) {
      handleInvite(roleName)(methods?.watch(), modal.hide);
    }
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      onCancel={onCancel}
      width="570px"
      onSubmit={handleSubmit}
      hideSecondaryAction={false}
      title={`Add ${getIndefiniteArticle(label)} ${label}`}
      actionLabel="Invite to team"
      displayCloseIcon
      isSubmitDisabled={!isValid || isLoading}
      cancelButtonProps={{ size: "medium" }}
      submitButtonProps={{ size: "medium" }}
      isNotTextChild
      sx={{
        "& .MuiDialogContent-root": {
          padding: "16px",
          marginBottom: "24px",
        },
        "& .MuiDialogActions-root": {
          padding: "12px 16px",
        },
        "& .MuiDialog-paper": {
          background: "#FBFBFB",
          backdropFilter: "none",
        },
      }}
      titleSx={{
        fontWeight: "book",
        fontSize: "18px",
        color: "#575353",
      }}
    >
      <FormProvider {...methods}>
        <Text mb="24px" fontWeight="regular" fontSize="16px" color="#8F8F8F">
          {subTitle}
        </Text>
        <MultipleInputs name="emailList" />
      </FormProvider>
    </Popup>
  );
});

export default AddMemberModal;

type DrawerProps = {
  handleClose: () => void;
  filtered: ParsedRole[] | undefined;
};

export const DrawerContent = ({ filtered, handleClose }: DrawerProps) => {
  const [drawerText, setDrawerText] = useState<MemberRoleName | null>(null);
  const { handleInvite, isLoading } = useInviteTeamMember({ handleClose });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    formState: { isValid },
  } = methods;

  return (
    <FormProvider {...methods}>
      <>
        {drawerText === null ? (
          <>
            <FadeUpWrapper delay={50}>
              <Text fontWeight="book" fontSize="18px" color="#575353">
                Add team member
              </Text>
            </FadeUpWrapper>

            <Box mt="16px">
              {filtered?.map(({ label, value }, idx) => (
                <FadeUpWrapper key={label} delay={50 + idx * 50}>
                  <Box
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                    bgcolor="#FFFFFF"
                    borderRadius="8px"
                    p="15px 8px"
                    mt="8px"
                    onClick={() => setDrawerText(value)}
                  >
                    <Text>{label}</Text>
                    <Box
                      sx={{
                        transform: "rotate(270deg)",
                      }}
                    >
                      <CaretDown
                        style={{ fill: "#8F8F8F" }}
                        height={16}
                        width={16}
                      />
                    </Box>
                  </Box>
                </FadeUpWrapper>
              ))}
            </Box>
          </>
        ) : (
          <form
            style={{
              minWidth: "100%",
              minHeight: "100%",
            }}
            onSubmit={methods.handleSubmit((data) =>
              handleInvite(drawerText)(data),
            )}
          >
            <Box
              justifyContent="space-between"
              flexDirection="column"
              display="flex"
              height="100%"
              width="100%"
            >
              <div>
                <FadeUpWrapper delay={50}>
                  <Stack gap="8px" alignItems="center" flexDirection="row">
                    <Box
                      sx={{
                        transform: "rotate(90deg)",
                      }}
                      onClick={() => setDrawerText(null)}
                    >
                      <CaretDown height={20} width={20} />
                    </Box>
                    <Text fontWeight="book" fontSize="18px" color="#575353">
                      Add {drawerText}
                    </Text>
                  </Stack>
                </FadeUpWrapper>
                <FadeUpWrapper delay={70}>
                  <Text
                    mb="24px"
                    fontSize="14px"
                    fontWeight="book"
                    color="#8F8F8F"
                    mt="21px"
                  >
                    {subTitle}
                  </Text>
                </FadeUpWrapper>
                <FadeUpWrapper delay={100}>
                  <MultipleInputs name="emailList" />
                </FadeUpWrapper>
              </div>

              <Button
                sx={{
                  maxWidth: "200px",
                  alignSelf: "center",
                  marginTop: "10px",
                }}
                disabled={!isValid || isLoading}
                type="submit"
              >
                Invite to team
              </Button>
            </Box>
          </form>
        )}
      </>
    </FormProvider>
  );
};

const schema = yup.object().shape({
  emailList: yup
    .array()
    .of(
      yup
        .string()
        .email("Invalid email address in the list")
        .required("Email is required"),
    )
    .min(1, "At least one email is required")
    .required("emailList is required"),
});

const subTitle = `You can change this member's role from the team settings`;
