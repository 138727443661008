import { Text } from "@common/Text";
import { Checkbox, CheckboxProps, Stack } from "@mui/material";
import { palette } from "@palette";
import React from "react";

export type ICustomCheckProps = {
  handleChange: () => void;
  disabled?: boolean;
  value?: boolean;
  label?: string | React.ReactNode;
  labelAlign?: "left" | "right";
  dataTestId?: string;
} & CheckboxProps;

function CustomCheck({
  handleChange,
  disabled,
  value,
  indeterminate,
  label,
  labelAlign = "right",
  dataTestId,
  ...props
}: ICustomCheckProps) {
  const CustomCheckbox = (
    <Checkbox
      inputProps={
        {
          role: "checkbox",
          "data-testid": dataTestId,
        } as React.InputHTMLAttributes<HTMLInputElement> & {
          "data-testid": string;
        }
      }
      indeterminate={indeterminate}
      checked={value}
      color="secondary"
      disabled={disabled}
      onClick={handleChange}
      sx={{
        "& svg": {
          width: "20px",
          height: "20px",
        },
        padding: 0,
        input: {
          visibility: "hidden",
          width: "auto",
          height: "auto",
          padding: 0,
        },
        "input:checked + svg": {
          borderRadius: "6px",
          backgroundColor: palette.neutral[950],
        },
      }}
      {...props}
    />
  );

  const Label = (
    <Text
      color={palette.neutral[70]}
      fontWeight="book"
      fontSize="14px"
      lineHeight="16.8px"
      display="flex"
      alignItems="center"
      gap="4px"
    >
      {label}
    </Text>
  );

  if (!label) return CustomCheckbox;

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {labelAlign === "left" && Label}
      {CustomCheckbox}
      {labelAlign === "right" && Label}
    </Stack>
  );
}

export default CustomCheck;
