interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const CheckIconInCircle = ({ width, height, fill = "none" }: IconProps) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_300_28280)">
        <circle
          cx="10"
          cy="10.334"
          r="7.5"
          stroke="#734089"
          strokeWidth="1.2"
        />
        <path
          d="M6.66659 10.334L9.16659 12.834L13.3333 7.83398"
          stroke="#734089"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_300_28280"
          x="0"
          y="0.333984"
          width="20"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_300_28280"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CheckIconInCircle;
