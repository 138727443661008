import { memo, useMemo } from "react";
import { Box } from "@mui/system";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { useStatusReview } from "../../hooks/useStatusReview";
import { FormProvider } from "react-hook-form";
import { MSP, TStatusReview } from "../../data.types";
import StatusReviewActions from "./StatusReviewActions";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { getAccountStatus, statusOverviewList } from "./utils/status";
import SuspendButton from "./components/SuspendButton";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { Container, HeaderText } from "./components/StatusReviewAtoms";

type Props = {
  data: TStatusReview;
  mspStatus: MSP;
  isEnterprise?: boolean;
};

const StatusReview = ({ data, mspStatus, isEnterprise }: Props) => {
  const { methods, handleSuspend } = useStatusReview({ data });
  const { isMobileView } = useCustomTheme();
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const { merchant_underwriting_statuses } = useEnterprisePermissions();

  const statusListItems = statusOverviewList(
    {
      primaryAccount: data?.approvedOwner,
      businessProfile: data.hasApprovedLegalEntity,
      bankAccount: data.hasApprovedBankAccount,
      merchantAgreement: data?.approvedAgreement,
      score: data?.underwritingScorePercentage || 0,
    },
    isEnterprise,
  );

  const { isReadyToApprove } = useMemo(() => {
    let isReadyToApprove = true;

    if (data.statusName !== "approved") {
      statusListItems.forEach((status) => {
        if (!status.approved) {
          isReadyToApprove = false;
        }
      });
    }

    return { isReadyToApprove };
  }, [statusListItems]);

  const status = getAccountStatus(isReadyToApprove, data.statusName);

  return (
    <Box
      sx={{
        "&:hover .suspend-button": {
          visibility: "visible",
        },
      }}
    >
      <FormProvider {...methods}>
        <Container
          component="form"
          isMobile={isMobileView}
          sx={{
            padding: "16px 16px 0 16px",
          }}
        >
          <HeaderSectionContainer isMobile={isMobileView}>
            <HeaderText isMobile={isMobileView}>
              Account Status Overview
            </HeaderText>
            <Stack direction="row" spacing={1} alignItems="center">
              {merchant_underwriting_statuses && (
                <SuspendButton
                  statusName={data.statusName}
                  disabled={!isUpdateUnderwriterAllowed}
                  onClick={handleSuspend}
                />
              )}
              <Text
                color={status?.color}
                sx={{
                  textAlign: "center",
                  borderRadius: "16px",
                  backgroundColor: status?.bg_color,
                  padding: "2px 16px 2px 16px",
                }}
              >
                {status?.label}
              </Text>
            </Stack>
          </HeaderSectionContainer>
        </Container>
        <StatusReviewActions
          data={data}
          mspStatus={mspStatus}
          isEnterprise={isEnterprise}
        />
      </FormProvider>
    </Box>
  );
};

const HeaderSectionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean }) =>
  props.isMobile
    ? {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
      }
    : { display: "flex", justifyContent: "space-between" },
);

export default memo(StatusReview);
