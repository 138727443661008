import { Box, styled } from "@mui/material";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { useMemo } from "react";
import { TOSWrapper } from "@components/ProfilePage/EnterpriseAgreement/SignTOS";
import { TermsOfService } from "@pages/TermsOfService";
import moment from "moment";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { FormProvider, useForm } from "react-hook-form";
import SignAgreementSection from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/SignAgreementSection";
import { Disclaimer } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Disclaimer";
import MerchantAgreementHeader from "@components/Merchants/MerchantPreview/MerchantAgreement/MerchantAgreementHeader";
import { showMessage } from "@common/Toast";
import { useQueryClient } from "react-query";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useAsyncAction } from "@hooks/customReactCore";
import { useScrollCheck } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useScrollCheck";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

interface Props {
  handleClosePanel: () => void;
  data?: any;
}

const EnterpriseAgreement = ({ handleClosePanel, data }: Props) => {
  const queryClient = useQueryClient();

  const defaultHasAgreed =
    data?.merchantAgreement.tcVersion ===
      data?.merchantAgreement.msaLastAcceptedVersion &&
    !!data?.merchantAgreement?.msaHadAgreed;

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      hasAgreed: defaultHasAgreed,
    },
  });
  const merchantID = data?.merchantInfo?.merchantID;
  const { handleSubmit } = useUpdateMerchantInfo(merchantID);
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { handleUploadSignature: handleUploadSignatureNew } =
    useAddSignatureNew();
  const { handleUploadSignature: handleUploadSignatureOld } =
    useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const hasAgreed = methods?.watch()?.hasAgreed;
  const [isLoading, triggerAction] = useAsyncAction();
  const { CheckboxWithTooltip, handleScroll } =
    useScrollCheck(defaultHasAgreed);

  const handleSignature = async (data: File) => {
    if (!hasAgreed)
      return showMessage("Error", "Agreement Confirmation not accepted");
    const signatureURL = await handleUploadSignature(merchantID, data, false);
    const payload = {
      msaHadAgreed: hasAgreed,
      signatureURL,
    };
    handleSubmit("merchant_agreement", payload, () => {
      queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantID]);
    });
  };

  const addSignature = (data: File) => triggerAction(handleSignature, data);

  const status = useMemo(() => {
    if (data?.merchantAgreement) {
      const isCorrectVersion =
        data?.merchantAgreement?.msaLastAcceptedVersion ===
        data?.merchantAgreement?.tcVersion;

      if (data?.merchantAgreement?.msaHadAgreed && isCorrectVersion)
        return "signed";
      if (
        (!data?.merchantAgreement?.msaHadAgreed && isCorrectVersion) ||
        !data?.merchantAgreement?.msaLastAcceptedVersion
      )
        return "not_signed";

      return "pending";
    }
    return "not_signed";
  }, [data?.merchantAgreement, data?.merchantInfo?.merchantID]);

  return (
    <FormProvider {...methods}>
      <Wrapper
        width={CAMPAIGN_PANEL_WIDTH - 30 + "px"}
        component="main"
        data-testid="double-panel-agreement-challenge"
      >
        <MerchantAgreementHeader isEnterPrise onClose={handleClosePanel} />

        <Disclaimer
          status={status}
          isEnterPrise
          accountHolderNameFullName={`${data?.primaryAccountHolder?.firstName} ${data?.primaryAccountHolder?.lastName}`}
          dateTime={
            data?.merchantAgreement?.msaLastAcceptedAt
              ? moment
                  .unix(data?.merchantAgreement?.msaLastAcceptedAt)
                  .format("MM-DD-YYYY HH:mm:ss")
              : ""
          }
          lastVersion={`${data?.merchantAgreement?.msaLastAcceptedVersion}`}
          version={`${data?.merchantAgreement?.tcVersion}`}
          merchantId={data?.merchantInfo?.merchantID}
          signatureURL={data?.merchantAgreement?.signatureURL}
        />
        <TOSWrapper onScroll={handleScroll}>
          <TermsOfService
            merchantAgreementVersion={{
              version: data?.merchantAgreement.tcVersion || "",
              lastUpdated: "June 10,2023",
            }}
            isEnterPrise={true}
          />
        </TOSWrapper>
        <CheckboxWithTooltip name="hasAgreed" />
        <SignAgreementSection
          data={data}
          canSign
          addSignature={addSignature}
          status={status}
          isEnterprise
          disabled={!hasAgreed || isLoading}
        />
      </Wrapper>
    </FormProvider>
  );
};

export default EnterpriseAgreement;

export const Wrapper = styled(Box)(() => ({
  backgroundColor: "#FAFAFA",
  flex: 1,
  height: "100%",
  borderRadius: "16px",
  padding: "12px 16px",
  boxShadow: "0px 4px 15px 0px #0000000D",
  overflowX: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none" /* IE and Edge */,
  scrollbarWidth: "none" /* Firefox */,
}));
