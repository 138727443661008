
const SortIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2022_40341)">
        <path
          d="M4.16602 5.8335H15.8327"
          stroke="#A9AFBD"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M4.16602 10H12.4993"
          stroke="#A9AFBD"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M4.16602 14.1665H9.16602"
          stroke="#A9AFBD"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2022_40341"
          x="0"
          y="0"
          width="20"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2022_40341"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SortIcon;
