import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import { AlertDot } from "@components/ManageMoney/TransactionTable/TransactionTable.atoms";
import { Box } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectQueryFilters } from "@redux/slices/transactions";

export const AlertComp = ({ row }: { row: TransactionTableRow }) => {
  const queryFilters = useAppSelector(selectQueryFilters);
  if (
    queryFilters.blocked.includes("isBlocked:true") &&
    row.firstCheckedAt === null
  )
    return (
      <Box>
        <AlertDot component={undefined} data-testid="unchecked-row-alert" />
      </Box>
    );
  else return null;
};
