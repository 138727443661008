import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetThreads,
  selectReplies,
  setConversationTopic,
  setConversationsGeneralSearchQueryString,
  setReplies,
} from "@redux/slices/conversations";
import { Dispatch, SetStateAction } from "react";
import { TQueryObject } from "../types";

const setConversationTopicAction = (id?: number, name = "", paths: [] = []) => {
  return setConversationTopic({
    isOpen: false,
    queryObject: { id, name, paths },
    isOpenedFromSidePanel: true,
  });
};

const resetState = (
  dispatch: Dispatch<any>,
  setShowSearchInput: Dispatch<SetStateAction<boolean>>,
) => {
  setShowSearchInput(false);
  dispatch(
    setConversationsGeneralSearchQueryString({
      queryKey: "",
      queryString: "",
    }),
  );
};

export const useConversationsHeader = ({
  queryObject,
  setShowSearchInput,
}: {
  queryObject?: TQueryObject;
  setShowSearchInput: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isRepliesOpen } = useAppSelector(selectReplies);
  const dispatch = useAppDispatch();

  const toggleReplies = (isOpen: boolean) =>
    dispatch(setReplies({ isRepliesOpen: isOpen }));

  const handleClick = () => {
    if (isRepliesOpen) {
      toggleReplies(false);
    } else {
      dispatch(setConversationTopicAction());
      dispatch(resetThreads());
    }
    resetState(dispatch, setShowSearchInput);
    const activeBar = document.querySelector(".conversationsBarActive");
    if (activeBar) {
      activeBar.classList.remove("conversationsBarActive");
    }
  };

  const clearQuery = () => {
    dispatch(
      setConversationsGeneralSearchQueryString({
        queryKey: "",
        queryString: "",
      }),
    );
  };

  const handleBreadCrumbClick = () => {
    if (isRepliesOpen) {
      toggleReplies(false);
    }

    const pathsArray = queryObject?.paths || [];
    const pathsCopy = [...pathsArray];
    const lastPath = pathsCopy.pop();
    const isActivityTab = queryObject?.tabs === "Activity";
    dispatch(
      setConversationTopic({
        queryObject: {
          id: queryObject?.id,
          name: queryObject?.name || "",
          paths: lastPath && isActivityTab ? pathsCopy : [],
        },
        ...(isActivityTab && {
          isOpen: pathsCopy?.length > 0,
        }),
      }),
    );
    setShowSearchInput(false);
    clearQuery();
  };

  return { handleBreadCrumbClick, handleClick, isRepliesOpen, clearQuery };
};
