interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const ImageIcon = ({
  width = 33,
  height = 32,
  fill = "#F4F3F7",
  path_fill = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.25" width="32" height="32" rx="4" fill={fill} />
      <path
        d="M29.5831 19.6031V21.4478C29.5831 21.754 29.5698 22.0628 29.5431 22.3689C29.169 26.581 26.2431 29.3333 22.0214 29.3333H10.4783C8.34066 29.3333 6.53705 28.6396 5.24113 27.3817C4.73345 26.9152 4.30593 26.3671 3.97192 25.7388C4.41281 25.2028 4.90713 24.6159 5.38809 24.0278C6.20305 23.0519 6.9913 22.0882 7.48562 21.4612C8.22042 20.5522 10.1576 18.1594 12.843 19.2823C13.3908 19.5096 13.8717 19.8304 14.3126 20.1111C15.3948 20.8329 15.849 21.0468 16.6105 20.6324C17.4522 20.1779 18 19.2823 18.5745 18.3466C18.8817 17.8507 19.189 17.3708 19.523 16.9296C20.9793 15.0315 23.2238 14.5235 25.0942 15.6464C26.0294 16.2078 26.831 16.9163 27.5791 17.6368C27.7395 17.7972 27.8998 17.9456 28.0467 18.0926C28.2471 18.2931 28.9151 18.9615 29.5831 19.6031Z"
        fill={path_fill}
      />
      <path
        opacity="0.4"
        d="M22.0349 2.66666H10.4784C5.94936 2.66666 2.91663 5.83475 2.91663 10.5521V21.448C2.91663 23.0908 3.29071 24.5492 3.97207 25.7389C4.41295 25.2029 4.90727 24.6161 5.38824 24.0266C6.2032 23.0521 6.99144 22.0883 7.48576 21.4613C8.22057 20.5524 10.1578 18.1596 12.8431 19.2824C13.3909 19.5097 13.8719 19.8305 14.3128 20.1112C15.3949 20.8331 15.8492 21.047 16.6107 20.6312C17.4524 20.1781 18.0001 19.2824 18.5746 18.3454C18.8819 17.8508 19.1892 17.3709 19.5232 16.9298C20.9794 15.0316 23.2239 14.5236 25.0943 15.6465C26.0295 16.2079 26.8311 16.9164 27.5793 17.6369C27.7396 17.7973 27.8999 17.9457 28.0469 18.0927C28.2473 18.2919 28.9153 18.9603 29.5833 19.6033V10.5521C29.5833 5.83475 26.5506 2.66666 22.0349 2.66666Z"
        fill={path_fill}
      />
      <path
        d="M15.5225 11.7289C15.5225 13.607 13.958 15.171 12.081 15.171C10.2052 15.171 8.64075 13.607 8.64075 11.7289C8.64075 9.85208 10.2052 8.28674 12.081 8.28674C13.958 8.28674 15.5225 9.85208 15.5225 11.7289Z"
        fill={path_fill}
      />
    </svg>
  );
};

export default ImageIcon;
