import { Text } from "@common/Text";
import { UploadFile } from "@components/UploadFile";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { TMerchantDocument } from "../data.types";
import { ConversationsIconNotFilled } from "@assets/icons";
import { useQueryClient } from "react-query";
import { useCallback } from "react";
import { checkPortals } from "@utils/routing";
import { EditButton } from "./atoms";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { useGetUploadFilePermission } from "@hooks/merchant-api/manage-money/useUploadBankAccountDocument";
import { DocumentItem } from "./DocumentItem";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import {
  AcceptAllowedGeneralDocumentsTypes,
  useUploadFiles,
} from "@hooks/upload-api/uploadHooks";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { ACTION_DENY_MESSAGE } from "@constants/permissions";

const DocumentsSection = ({
  id,
  data,
  isAcquirer,
  isEnterprise = false,
}: {
  id: number;
  data: TMerchantDocument[];
  isAcquirer?: boolean;
  isEnterprise?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { isAcquirerPortal, isEnterprisePortal } = checkPortals();
  const { populateSnackbarFiles } = useFileUploadContext();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const isUploadAllowed = useGetUploadFilePermission(isEnterprise);
  const tag = `${
    isAcquirerPortal ? "Acquirer" : isEnterprisePortal ? "Provider" : "Merchant"
  } upload`;
  const { handleUpload } = useUploadFiles();

  const attemptUpload = async (file: File) => {
    await handleUpload({
      list: [{ file }],
      merchantId: id,
      resourceID: id,
      attachmentType: "underwriting_profile",
      label: "",
      tag,
      refetcherKey: ["get-merchant-preview"],
    });
    queryClient.invalidateQueries("get-merchant-msp-status");
    queryClient.refetchQueries("get-merchant-preview");
  };

  const { merchant_underwriting } = useEnterprisePermissions();
  const { openConversationHandler } = useConversationsModal();
  const sectionTitle = "Documents";

  const handleChangeStatus = useCallback(
    (
      fileWithMeta: IFileWithMeta,
      status: StatusValue,
      allFiles: IFileWithMeta[],
    ) => {
      populateSnackbarFiles({
        fileWithMeta,
        status,
        allFiles,
        attachmentType: "underwriting_profile",
        label: "",
        tag,
        merchantId: id,
        refetcherKeyOnDelete: "get-merchant-preview",
        resourceID: id,
        onSuccess: () => {
          fileWithMeta.remove();
          queryClient.invalidateQueries("get-merchant-msp-status");
          queryClient.refetchQueries("get-merchant-preview");
        },
      });
    },
    [queryClient, id, tag],
  );
  const onClick = () =>
    openConversationHandler({ name: sectionTitle, id: 0, paths: [] });
  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Text
          color={palette.gray[300]}
          variant="h6"
          lineHeight="32px"
          letterSpacing="-0.18px"
        >
          {sectionTitle}
        </Text>
        {merchant_underwriting && isAcquirer && (
          <EditButton onClick={onClick}>
            <ConversationsIconNotFilled section="documents" />
          </EditButton>
        )}
      </Stack>
      <WithTooltipWrapper
        hasTooltip={!isUploadAllowed}
        tooltipProps={{
          show: !isUploadAllowed,
          message: ACTION_DENY_MESSAGE,
        }}
      >
        <FlaggedWrapper
          ActiveComponent={
            <UploadFileNew
              disabled={!isUploadAllowed}
              uploadFunction={attemptUpload}
              accept={AcceptAllowedGeneralDocumentsTypes}
              maxFiles={5}
              multiple
            />
          }
          FallbackComponent={
            <UploadFile
              backgroundColor="white"
              disabled={!isUploadAllowed}
              onChangeStatus={handleChangeStatus}
              width="100%"
            />
          }
          name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
        />
      </WithTooltipWrapper>
      <Stack direction="column" gap={1}>
        {data.map((document) => (
          <DocumentItem
            key={document.id}
            merchantID={id}
            {...document}
            list={data}
            tag={document.tag || TagByAttachment[document.attTypeName]}
            isEnterprise={isEnterprise}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default DocumentsSection;

const TagByAttachment: any = {
  bank_account: "bank account document",
};
