import React from "react";
import { styled, Box, BoxProps } from "@mui/material";

interface BackgroundVideoWrapperProps extends BoxProps {
  videoSrc: string;
  videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
  sourceProps?: React.VideoHTMLAttributes<HTMLSourceElement>;
}

const BackgroundVideoWrapper = styled<React.FC<BackgroundVideoWrapperProps>>(
  ({ children, videoSrc, videoProps, sourceProps, ...rest }) => (
    <Box {...rest}>
      <StyledVideo autoPlay muted loop playsInline {...videoProps}>
        <source src={videoSrc} type="video/mp4" {...sourceProps} />
      </StyledVideo>
      <Box zIndex={1} position="relative">
        {children}
      </Box>
    </Box>
  ),
)(() => ({
  position: "relative",
  overflow: "hidden",
}));
export default BackgroundVideoWrapper;

const StyledVideo = styled("video")(() => ({
  overflow: "hidden",
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  top: 0,
  left: 0,
  zIndex: 0,
}));
