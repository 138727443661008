import { useCallback, useRef, useState } from "react";

type TStatusBar = { label: string; barValue: number };

const useBusinessProfileSteps = (steps: TStatusBar[], defaultStep: string) => {
  const [step, setStep] = useState(defaultStep);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const stepperRef = useRef<any>(null);

  const [statusBar, setStatusBar] = useState<TStatusBar[]>(steps);

  const handleNext = () => stepperRef.current?.handleNext();
  const handleBack = () => stepperRef.current?.handleBack();

  const handleUpdateStatusValue = useCallback(
    (label: string) => (value: number) => {
      setStatusBar((prev) =>
        prev.map((s) => {
          if (s.label !== label) return s;
          return {
            ...s,
            barValue: value,
          };
        }),
      );
    },
    [],
  );

  const stepperConfig = {
    setStep,
    defaultStep,
    customSteps: statusBar,
    ref: stepperRef,
    setIsLoaded,
    overriderActiveStep: Object.values(statusBar).findIndex(
      (x) => x.label === step,
    ),
  };

  return {
    stepperConfig,
    handleUpdateStatusValue,
    statusBar,
    setStatusBar,
    step,
    setStep,
    isLoaded,
    handleBack,
    handleNext,
  };
};

export default useBusinessProfileSteps;
