import { Drawer, styled } from "@mui/material";
import { palette } from "@palette";
import DrawerBody from "./ModalDrawerBody";
import { useModal } from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ModalDrawerProps } from "./types";

const Body = (props: ModalDrawerProps) => (
  <DrawerBody {...props}>{props.children}</DrawerBody>
);

const ModalDrawer = (props: ModalDrawerProps) => {
  const modal = useModal();
  const { isDesktopView } = useCustomTheme();

  if (props.isBody) return <Body {...props} />;

  return (
    <DrawerContainer
      anchor="right"
      open={modal.visible}
      onClose={props.steps ? undefined : props.onModalClose}
      PaperProps={{
        style: {
          width: isDesktopView ? "600px" : "100%",
          background: palette.liftedWhite.main,
          boxShadow: isDesktopView
            ? "0px 10px 25px 0px rgba(103, 103, 103, 0.15)"
            : "none",
        },
      }}
      ModalProps={{
        onBackdropClick: props.onModalClose,
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(53, 53, 53, 0.10)",
          },
        },
      }}
    >
      <Body {...props} />
    </DrawerContainer>
  );
};

const DrawerContainer = styled(Drawer)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "16px",
}));

export default ModalDrawer;
