import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { format } from "date-fns";
import { TThreadInfo } from "features/Minibuilders/Conversations/types";
import {
  MarkAllAsOkModalFooterProps,
  SaveValuesType,
} from "@components/Merchants/MerchantPreview/components/MarkAllAsOkModalFooter";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import useGetGlobalTopic from "features/Minibuilders/Conversations/hooks/useGetGlobalTopic";
import { checkPortals } from "@utils/routing";

export const createThread = async (globalMerchantId: number, data: any) => {
  return await customInstance({
    url: `/merchants/${globalMerchantId}/threads`,
    method: "POST",
    data,
  });
};

const useRiskMarkAllAsOk = ({
  merchantId,
  profileId,
  onCancel,
  escalationIds,
}: MarkAllAsOkModalFooterProps) => {
  const { isAcquirerPortal } = checkPortals();
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const queryClient = useQueryClient();
  const { topic } = useGetGlobalTopic("risk_activity");

  const globalTopicId = topic ? topic.ID : null;

  const createNewConversationThread = useMutation(
    async (data: any) => await createThread(globalMerchantId, data),
  );

  const markAllAsOkMutation = useMutation(
    (data: { reason: string; notes: string }) => {
      return customInstance({
        url: `/merchants/${merchantId}/risk/merchant-profiles/${profileId}/escalations/set-all-as-ok`,
        method: "PATCH",
        data,
      });
    },
  );

  const isLoading =
    createNewConversationThread.isLoading || markAllAsOkMutation.isLoading;
  const { openConversationHandler } = useConversationsModal();

  const handleThreadCreation = async ({
    reason,
    description,
  }: SaveValuesType) => {
    if (!globalTopicId) return;
    const now = new Date();
    const formattedDate = format(now, "MM/dd/yyyy 'at' HH:mm");

    await createNewConversationThread.mutateAsync(
      {
        subjectAccID: merchantId,
        TopicDisplayName: "Risk Activity",
        message: {
          body: `I marked all triggers as OK on ${formattedDate}\nReason: ${reason}\nComment: ${description}`,
          mentions: [],
        },
        topicID: globalTopicId,
        escalationIDs: escalationIds,
      },
      {
        onSuccess: async (data: TThreadInfo) => {
          openConversationHandler({
            id: data.topicID,
            name: "Risk Activity",
            paths: [],
            forceTopicOpen: true,
          });
          queryClient.refetchQueries({
            queryKey: ["get-conversation-topics", merchantId],
          });
          queryClient.refetchQueries([
            "get-conversation-messages",
            data.topicID,
          ]);
        },
      },
    );
  };

  const handleSave = (data: SaveValuesType) => {
    markAllAsOkMutation.mutate(
      { reason: data.reason, notes: data.description },
      {
        onSuccess: async () => {
          queryClient.refetchQueries({
            queryKey: ["risk-activities", profileId],
          });
          queryClient.refetchQueries({
            queryKey: ["active-risk-activities", profileId, merchantId],
          });
          queryClient.invalidateQueries("get-merchant-risk-profile");
          queryClient.refetchQueries("get-merchant-preview");
          // conversation is allowed only in acquirer portal
          if (isAcquirerPortal) {
            await handleThreadCreation(data);
          }
          onCancel();
        },
      },
    );
  };

  return { handleSave, isLoading };
};

export default useRiskMarkAllAsOk;
