import ImagePlaceholder from "@assets/svgIcons/storefront-placeholder.svg";
import AvatarUpload from "@components/AvatarUpload/AvatarUpload";
import { useFormContext, useWatch } from "react-hook-form";

interface IComponentProps {
  fieldName: string;
}

const CreateFormAvatarUpload = ({ fieldName }: IComponentProps) => {
  const value = useWatch({ name: fieldName });
  const { setValue } = useFormContext();

  const handleChangeStatusV2 = (file: File) => {
    if (file) {
      setValue(
        fieldName,
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        { shouldDirty: true },
      );
    }
  };

  const handleDeleteImage = () => {
    return setValue(fieldName, null, { shouldValidate: true });
  };
  return (
    <AvatarUpload
      size={92}
      defaultImageURL={value ? URL.createObjectURL(value) : undefined}
      placeholder={ImagePlaceholder}
      onDelete={handleDeleteImage}
      onUpload={handleChangeStatusV2}
      canUpload
      canDelete
    />
  );
};

export default CreateFormAvatarUpload;
