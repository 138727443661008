type Props = {
  width?: number;
  height?: number;
  fill?: string;
  rotate?: number;
};

const IncrementArrow = ({
  width = 16,
  height = 16,
  fill = "#13A75A",
  rotate = 0,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45716 12.1966C8.45716 12.4176 8.27807 12.5966 8.05716 12.5966C7.83625 12.5966 7.65716 12.4176 7.65716 12.1966V11.2632C7.65716 11.0422 7.83625 10.8632 8.05716 10.8632C8.27807 10.8632 8.45716 11.0422 8.45716 11.2632V12.1966ZM8.45716 9.52982C8.45716 9.75073 8.27807 9.92982 8.05716 9.92982C7.83625 9.92982 7.65716 9.75073 7.65716 9.52982L7.65716 4.7358L4.02082 8.22799C3.86148 8.38101 3.60827 8.37588 3.45525 8.21655C3.30223 8.05721 3.30735 7.804 3.46668 7.65098L7.76711 3.52104C7.83999 3.44432 7.94299 3.39648 8.05716 3.39648C8.17326 3.39648 8.27781 3.44595 8.35088 3.52495L12.5354 7.65479C12.6926 7.80997 12.6943 8.06323 12.5391 8.22046C12.3839 8.3777 12.1307 8.37936 11.9734 8.22418L8.45716 4.75385V9.52982Z"
        fill={fill}
      />
    </svg>
  );
};

export default IncrementArrow;

