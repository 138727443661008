import { namespaces } from "../../../resources/i18n.constants";

export const events = {
  [namespaces.pages.events]: {
    eventsTotal: "Events Total",
    events: "Events",
    visitors: "Visitors",
    conversion: "Conversion",
    Average: "Average",
    createEvent: "Create an Event",
    search: "Search...",
    fundraiser: "Fundraiser",
    amount: "Amount",
    average: "Average",
    transactions: "Transactions",
    ticketsSold: "Tickets Sold",
  },
};
