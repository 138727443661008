// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const CustomerPeopleIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.45445 5.60036C8.26285 5.60036 7.29687 6.56634 7.29687 7.75794C7.29687 8.94953 8.26285 9.91551 9.45445 9.91551C10.646 9.91551 11.612 8.94953 11.612 7.75794C11.612 6.56634 10.646 5.60036 9.45445 5.60036ZM6.09687 7.75794C6.09687 5.9036 7.60011 4.40036 9.45445 4.40036C11.3088 4.40036 12.812 5.9036 12.812 7.75794C12.812 9.61227 11.3088 11.1155 9.45445 11.1155C7.60011 11.1155 6.09687 9.61227 6.09687 7.75794ZM13.9454 5.00036C13.9454 4.66899 14.214 4.40036 14.5454 4.40036C16.3997 4.40036 17.9029 5.9036 17.9029 7.75794C17.9029 9.61227 16.3997 11.1155 14.5454 11.1155C14.214 11.1155 13.9454 10.8469 13.9454 10.5155C13.9454 10.1841 14.214 9.91551 14.5454 9.91551C15.737 9.91551 16.7029 8.94953 16.7029 7.75794C16.7029 6.56634 15.737 5.60036 14.5454 5.60036C14.214 5.60036 13.9454 5.33173 13.9454 5.00036ZM9.17263 13.4852C9.17263 13.1538 9.44126 12.8852 9.77263 12.8852C12.3885 12.8852 14.509 15.0058 14.509 17.6216C14.509 18.7144 13.6231 19.6004 12.5302 19.6004H6.37869C5.28584 19.6004 4.3999 18.7144 4.3999 17.6216C4.3999 16.2499 4.98376 15.0137 5.91475 14.1495C6.15761 13.9241 6.53725 13.9382 6.76269 14.1811C6.98812 14.424 6.97399 14.8036 6.73112 15.029C6.03451 15.6756 5.5999 16.5974 5.5999 17.6216C5.5999 18.0517 5.94858 18.4004 6.37869 18.4004H12.5302C12.9603 18.4004 13.309 18.0517 13.309 17.6216C13.309 15.6685 11.7257 14.0852 9.77263 14.0852C9.44126 14.0852 9.17263 13.8166 9.17263 13.4852ZM14.1575 13.4852C14.1575 13.1538 14.4261 12.8852 14.7575 12.8852H14.8635C17.4794 12.8852 19.5999 15.0058 19.5999 17.6216C19.5999 18.7144 18.714 19.6004 17.6211 19.6004H16.2423C15.911 19.6004 15.6423 19.3317 15.6423 19.0004C15.6423 18.669 15.911 18.4004 16.2423 18.4004H17.6211C18.0512 18.4004 18.3999 18.0517 18.3999 17.6216C18.3999 15.6685 16.8166 14.0852 14.8635 14.0852H14.7575C14.4261 14.0852 14.1575 13.8166 14.1575 13.4852Z"
          fill={`url(#paint0_linear_304_1616${width})`}
        />
        <defs>
          <linearGradient
            id={`paint0_linear_304_1616${width}`}
            x1="17.5294"
            y1="4.22641"
            x2="-0.752316"
            y2="16.4581"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45445 5.60036C8.26285 5.60036 7.29687 6.56634 7.29687 7.75794C7.29687 8.94953 8.26285 9.91551 9.45445 9.91551C10.646 9.91551 11.612 8.94953 11.612 7.75794C11.612 6.56634 10.646 5.60036 9.45445 5.60036ZM6.09687 7.75794C6.09687 5.9036 7.60011 4.40036 9.45445 4.40036C11.3088 4.40036 12.812 5.9036 12.812 7.75794C12.812 9.61227 11.3088 11.1155 9.45445 11.1155C7.60011 11.1155 6.09687 9.61227 6.09687 7.75794ZM13.9454 5.00036C13.9454 4.66899 14.214 4.40036 14.5454 4.40036C16.3997 4.40036 17.9029 5.9036 17.9029 7.75794C17.9029 9.61227 16.3997 11.1155 14.5454 11.1155C14.214 11.1155 13.9454 10.8469 13.9454 10.5155C13.9454 10.1841 14.214 9.91551 14.5454 9.91551C15.737 9.91551 16.7029 8.94953 16.7029 7.75794C16.7029 6.56634 15.737 5.60036 14.5454 5.60036C14.214 5.60036 13.9454 5.33173 13.9454 5.00036ZM9.17263 13.4852C9.17263 13.1538 9.44126 12.8852 9.77263 12.8852C12.3885 12.8852 14.509 15.0058 14.509 17.6216C14.509 18.7144 13.6231 19.6004 12.5302 19.6004H6.37869C5.28584 19.6004 4.3999 18.7144 4.3999 17.6216C4.3999 16.2499 4.98376 15.0137 5.91475 14.1495C6.15761 13.9241 6.53725 13.9382 6.76269 14.1811C6.98812 14.424 6.97399 14.8036 6.73112 15.029C6.03451 15.6756 5.5999 16.5974 5.5999 17.6216C5.5999 18.0517 5.94858 18.4004 6.37869 18.4004H12.5302C12.9603 18.4004 13.309 18.0517 13.309 17.6216C13.309 15.6685 11.7257 14.0852 9.77263 14.0852C9.44126 14.0852 9.17263 13.8166 9.17263 13.4852ZM14.1575 13.4852C14.1575 13.1538 14.4261 12.8852 14.7575 12.8852H14.8635C17.4794 12.8852 19.5999 15.0058 19.5999 17.6216C19.5999 18.7144 18.714 19.6004 17.6211 19.6004H16.2423C15.911 19.6004 15.6423 19.3317 15.6423 19.0004C15.6423 18.669 15.911 18.4004 16.2423 18.4004H17.6211C18.0512 18.4004 18.3999 18.0517 18.3999 17.6216C18.3999 15.6685 16.8166 14.0852 14.8635 14.0852H14.7575C14.4261 14.0852 14.1575 13.8166 14.1575 13.4852Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomerPeopleIcon;
