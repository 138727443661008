import { Popover } from "@mui/material";
import { useCallback } from "react";
import moment from "moment";
import RangePicker from "@components/Analytics/SidePanels/CampaignSidePanel/components/RangePicker";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";

interface Props {
  setRangePickerAnchorEl: any;
  rangePickerAnchorEl: any;
  customInitialRange: { startDate?: number; endDate?: number };
  onSelection: ({ start, end }: { start: string; end: string }) => void;
  isNewStyle?: boolean;
  handleSave?: () => void;
  onCloseCallback?: () => void;
  ignoreMobileView?: boolean;
}
export default function CustomDateRangePicker({
  customInitialRange,
  onSelection,
  setRangePickerAnchorEl,
  rangePickerAnchorEl,
  ignoreMobileView = false,
  isNewStyle = false,
  handleSave,
  onCloseCallback,
}: Props) {
  const handleSelect = useCallback((startDate: Date, endDate: Date) => {
    onSelection({
      start: moment(startDate).format("MM-DD-YYYY"),
      end: moment(endDate).format("MM-DD-YYYY"),
    });
  }, []);
  const handleClose = () => {
    setRangePickerAnchorEl(null);
    onCloseCallback && onCloseCallback();
  };
  const { isMobileView } = useCustomTheme();

  const rangePicker = (
    <RangePicker
      onSelectionChange={handleSelect}
      initialRange={customInitialRange}
      isNewStyle={isNewStyle}
      handleClose={handleClose}
      handleSave={handleSave}
    />
  );
  return isMobileView && !ignoreMobileView ? (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={Boolean(rangePickerAnchorEl)}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root.MuiDrawer-paper": {
          background: "#fff",
        },
      }}
    >
      {rangePicker}
    </SwipeableDrawerMobile>
  ) : (
    <Popover
      id="anchorEl"
      open={Boolean(rangePickerAnchorEl)}
      anchorEl={rangePickerAnchorEl}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "8px",
          boxShadow: "0px 3px 15px 0px #02020226",
          width: isNewStyle && isMobileView ? "100%" : undefined,
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {rangePicker}
    </Popover>
  );
}
