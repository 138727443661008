import Skeleton from "@components/animation/Skeleton";
import { Box, Divider, Stack } from "@mui/material";

const RiskTriggersSkeleton = () => {
  return (
    <Stack spacing={2} mt={2} padding="12px 16px">
      <Skeleton height={25} width="70%" />
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <Stack spacing={3} key={index}>
            <Skeleton height={25} width="50px" />
            <Stack spacing={2}>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Stack spacing={1} key={index} width="100%">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Skeleton height={25} width="70%" />
                      <Skeleton height={20} width="50px" />
                    </Box>
                    <Skeleton height={25} width="70px" />
                    {index !== 4 && <Divider />}
                  </Stack>
                ))}
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default RiskTriggersSkeleton;
