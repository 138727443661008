import { namespaces } from "../../../resources/i18n.constants";

export const settings = {
  [namespaces.pages.settings]: {
    settings: "Settings",
    myAccount: "My Account",
    purchases: "Purchases",
    manageTeam: "Manage Team",
    mediaLibrary: "Media Library",
    ManageAlerts: "Manage Alerts",
    tracking: "Tracking",
    security: "Security",
    developer: "Developer",
    clientDetails: "Client Details",
    avatar: "Avatar",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phoneNumber: "Phone Number",
    dateOfBirth: "Date of Birth",
    employer: "Employer",
    occupation: "Occupation",
    accessibility: "Accessibility",
    currency: "Currency",
    timezone: "Timezone",
    language: "Language",
    spanish: "Spanish",
    english: "English",
    discardChanges: "Discard Changes",
    discard: "Discard",
    saveChanges: "Save Changes",
    accountDetails: "Account Details",
  },
};
