import { useEffect, useState } from "react";
import { useGetGlobalMerchantCategories } from "./useGlobalMerchantCategories";

export type MCCType = {
  isChecked: boolean;
  id: number;
  issuer: string;
  code: string;
  title: string;
  titleAlt: string;
  description: string;
  examples: string;
  createdAt: number;
  updatedAt: number;
  riskLevelName: string;
};

interface Props {
  all?: boolean;
}
const useListCategories = ({ all = false }: Props = {}) => {
  const [data, setData] = useState<MCCType[]>([]);
  const [page, setPage] = useState(1);
  const {
    data: pageData,
    isLoading,
    isError,
  } = useGetGlobalMerchantCategories({ page, all });

  const BOTTOM_OFFSET_PX = 4;

  useEffect(() => {
    if (pageData?.data) {
      if (data.length === 0 && pageData?.data) {
        setData(pageData.data);
      } else if (data.length > 0) {
        setData((prev) => [...prev, ...pageData.data]);
      }
    }
  }, [pageData]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isLoading) return;
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const isNearBottom =
      scrollHeight - (scrollTop + clientHeight) <= BOTTOM_OFFSET_PX;

    if (isNearBottom && pageData?.total > data.length) {
      setPage((prev) => prev + 1);
    }
  };

  return {
    data,
    isLoading,
    isError,
    handleScroll,
  };
};

export default useListCategories;
