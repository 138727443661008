import { DesktopTowerIcon, ShieldStarIcon } from "@assets/rebrandIcons";
import { CountryFlagBase } from "@common/CountryFlag";
import { TruncateText, Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";
import React from "react";
import { TRiskProfileGeneralInfo } from "../data.types";
import moment from "moment";

const RiskMainInfos = ({
  countryCodeISO2,
  countryName,
  ipAddress,
  hasIPProbeData,
  updatedAt,
  isVPN,
}: TRiskProfileGeneralInfo) => {
  const items = [
    {
      visible: !!countryName,
      Icon: <CountryFlagBase isoCode={countryCodeISO2} />,
      children: <StyledText lineClamp={1}>{countryName}</StyledText>,
    },
    {
      visible: !!ipAddress,
      Icon: <DesktopTowerIcon />,
      children: <StyledText lineClamp={1}>{ipAddress}</StyledText>,
    },
    {
      visible: hasIPProbeData,
      Icon: <ShieldStarIcon />,
      children: <ProbeResult isVPN={isVPN} updatedAt={updatedAt} />,
    },
  ];
  return (
    <Container>
      {items.map((item, idx) => (
        <Item key={idx} {...item} />
      ))}
    </Container>
  );
};

const ProbeResult = ({
  isVPN,
  updatedAt,
}: {
  isVPN: boolean;
  updatedAt: number;
}) => {
  const text = isVPN ? "VPN DETECTED" : "NO VPN DETECTED";
  return (
    <Stack direction="column" alignItems="flex-start">
      <Text
        color={isVPN ? palette.black[100] : "#13A75A"}
        fontSize="14px"
        lineHeight="16.8px"
        sx={{ whiteSpace: "nowrap" }}
      >
        {text}
      </Text>
      <Text
        color={palette.gray[300]}
        fontSize="14px"
        fontWeight="book"
        lineHeight="16.8px"
        sx={{ whiteSpace: "nowrap" }}
      >
        IP Probe ran on{" "}
        <Box component="span" sx={{ color: palette.black[100] }}>
          {moment(new Date(updatedAt * 1000)).format("MMM. DD YYYY")}
        </Box>
      </Text>
    </Stack>
  );
};

const StyledText = styled(TruncateText)(() => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16.8px",
  color: palette.black[100],
}));

const Item = ({
  Icon,
  children,
  visible,
}: {
  Icon: React.ReactNode;
  children: React.ReactNode;
  visible: boolean;
}) => {
  if (!visible) return null;
  return (
    <Stack
      direction="row"
      gap="8px"
      alignItems="center"
      justifyContent="center"
      minWidth="190px"
    >
      {Icon}
      {children}
    </Stack>
  );
};

const Container = styled(Stack)(() => ({
  marginInline: "auto",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px",
  width: "min-content",
}));

export default RiskMainInfos;
