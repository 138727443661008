import { encodedQueryFilterMap } from "@services/filtering";
import {
  selectMerchantStatusName,
  selectQueryFilters,
} from "@redux/slices/enterprise/merchants";
import { useAppSelector } from "@redux/hooks";
import { selectedQueryString } from "@redux/slices/tableFilters";
import { selectQueryFilters as selectQueryFiltersEnt } from "@redux/slices/acquirer/enterprises";
import { useMemo } from "react";

const DEFAULT_VALUES = { isMerchant: true, applyAdvancedQuery: true };

type ParamType = {
  isMerchant?: boolean;
  applyAdvancedQuery?: boolean;
};
export default function useQueryString({
  isMerchant = true,
  applyAdvancedQuery = true,
}: ParamType = DEFAULT_VALUES) {
  const queryFilters = isMerchant
    ? useAppSelector(selectQueryFilters)
    : useAppSelector(selectQueryFiltersEnt);
  const statusName = useAppSelector(selectMerchantStatusName);

  const advancedQueryString = useAppSelector(selectedQueryString);

  let queryString = "";

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  if (queryFilter.products) {
    if (statusName) {
      queryString = `%3B${queryFilter.products}%3B${statusName}`;
    } else {
      queryString = `%3B${queryFilter.products}`;
    }
  } else if (statusName) {
    queryString =
      advancedQueryString && applyAdvancedQuery
        ? `%3B${statusName}%3B${advancedQueryString}`
        : `%3B${statusName}`;
  } else if (advancedQueryString && applyAdvancedQuery) {
    queryString = `%3B${advancedQueryString}`;
  }

  return queryString;
}
