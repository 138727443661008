import { useEffect } from "react";
import { palette } from "@palette";

// nice-modal
import NiceModal, { muiDialogV5 } from "@ebay/nice-modal-react";
// components
import { Text, TruncateText } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
import { Avatar, Box, Stack, styled } from "@mui/material";
import { ArrowForwardIcon, CheckMarkIcon } from "@assets/icons";
import { useAccountSelectionModal } from "./useAccountSelectionModal";
import { buttonTheme } from "@common/Button/newButtonTheme";

const AccountSelectionModal = NiceModal.create(
  ({
    userAccID,
    preSelectedAccount,
  }: {
    userAccID: number;
    preSelectedAccount: any;
  }) => {
    const {
      modal,
      accountList,
      handleModalClose,
      continueHandler,
      chooseAccount,
      selectedAccountId,
      isLoadingAccounts,
    } = useAccountSelectionModal({ userAccID });

    useEffect(() => {
      if (accountList?.length > 0 && !isLoadingAccounts) {
        // if coming from mobile and has preselected account -> select it by default
        if (preSelectedAccount) {
          const selectedAccountIndex = accountList.findIndex(
            (acc) => acc.id === preSelectedAccount?.id,
          );
          chooseAccount(selectedAccountIndex);
        } else {
          chooseAccount(0);
        }
      }
    }, [accountList?.length]);

    return (
      <StyledModal
        {...muiDialogV5(modal)}
        title="Account Selection"
        onClose={handleModalClose}
        modalType="selection"
        sx={{
          "& .MuiDialogContent-root": {
            background: "white",
          },
          "& .MuiDialogActions-root": {
            background: palette.neutral[5],
          },
        }}
        actions={
          <Button
            disabled={!selectedAccountId}
            background="primary"
            onClick={continueHandler}
            endIcon={
              <ArrowForwardIcon stroke={buttonTheme.colors.primary.text} />
            }
          >
            Continue
          </Button>
        }
      >
        <Text
          variant="h5"
          fontWeight="book"
          fontSize={28}
          lineHeight="33.3px"
          color={palette.neutral[70]}
        >
          Account Selection
        </Text>

        <Stack spacing={2} mt={3}>
          {accountList?.map((account, index) => {
            return (
              <StyledAccountItem
                data-testid="account_selection_modal_login"
                key={index}
                isselected={account.selected}
                onClick={() => {
                  !preSelectedAccount && chooseAccount(index);
                }}
              >
                <Avatar src={account.img + "/thumb"} sx={avatarStyle} />
                <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
                  <TruncateText
                    lineClamp={1}
                    sx={{
                      ...titleStyle,
                      color: account.selected ? "white" : palette.neutral[80],
                    }}
                  >
                    {account.name}
                  </TruncateText>
                  <Text color={palette.neutral[400]} textTransform="capitalize">
                    {account.merchType}
                  </Text>
                </Stack>
                <Box
                  sx={{ visibility: "hidden" }}
                  className="account-item-checkmark"
                >
                  <CheckMarkIcon
                    fill={account.selected ? palette.neutral.white : "none"}
                  />
                </Box>
              </StyledAccountItem>
            );
          })}
        </Stack>
      </StyledModal>
    );
  },
);

const topBoxStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const titleStyle = {
  fontSize: 16,
  color: palette.neutral[80],
  fontWeight: "500",
};

const descriptionStyle = {
  fontSize: 24,
  color: palette.neutral[80],
};

export const StyledAccountItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isselected",
})(({ isselected }: { isselected: boolean }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px 16px",
  gap: "16px",
  cursor: "pointer",
  borderRadius: "8px",
  ...(isselected && {
    background: "#292727",
    "& .account-item-checkmark": {
      visibility: "visible",
    },
  }),
  ...(!isselected && {
    background: "#FFFFFF",
    "&:hover": {
      outline: "2px solid #C1C1C1",
      "& .account-item-checkmark": {
        visibility: "visible",
      },
    },
  }),
}));

const avatarStyle = {
  height: "64px",
  width: "64px",
};

const continueStyle = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: palette.info.main,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "500px",
  },
  [theme.breakpoints.down(600)]: {
    "& .MuiDialog-paper": {
      width: "100%",
    },
  },
}));

export default AccountSelectionModal;
