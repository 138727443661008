import { SvgIconProps } from "@mui/material";

const PlusIconV2 = ({
  width = 15,
  height = 14,
  fill = "#FFFFFD",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 7C14.25 7.14918 14.1907 7.29226 14.0852 7.39775C13.9798 7.50324 13.8367 7.5625 13.6875 7.5625H8.0625V13.1875C8.0625 13.3367 8.00324 13.4798 7.89775 13.5852C7.79226 13.6907 7.64918 13.75 7.5 13.75C7.35082 13.75 7.20774 13.6907 7.10225 13.5852C6.99676 13.4798 6.9375 13.3367 6.9375 13.1875V7.5625H1.3125C1.16332 7.5625 1.02024 7.50324 0.914753 7.39775C0.809263 7.29226 0.75 7.14918 0.75 7C0.75 6.85082 0.809263 6.70774 0.914753 6.60225C1.02024 6.49676 1.16332 6.4375 1.3125 6.4375H6.9375V0.8125C6.9375 0.663316 6.99676 0.520242 7.10225 0.414753C7.20774 0.309263 7.35082 0.25 7.5 0.25C7.64918 0.25 7.79226 0.309263 7.89775 0.414753C8.00324 0.520242 8.0625 0.663316 8.0625 0.8125V6.4375H13.6875C13.8367 6.4375 13.9798 6.49676 14.0852 6.60225C14.1907 6.70774 14.25 6.85082 14.25 7Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIconV2;
