import Figure from "@common/Figure";
import { StyledMainLinearColorText, Text } from "@common/Text";
import { Container, Box, styled } from "@mui/material";
import Footer from "@pages/Login/Footer";
import { palette } from "@palette";

export const NotFoundResourcePage = () => {
  return (
    <>
      <MainContainer>
        <StyledMainLinearColorText
          variant={"h3"}
          sx={{
            fontSize: "96px",
            lineHeight: "96px",
            fontWeight: "light",
            marginBottom: "10px",
          }}
        >
          Oops.
        </StyledMainLinearColorText>
        <StyledText>
          Please try again later, this content is currently not available.
        </StyledText>
      </MainContainer>
      <Box
        style={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          padding: "16px 32px",
        }}
      >
        <Footer />
      </Box>
    </>
  );
};

const MainContainer = styled(Container)(({ theme }) => ({
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "20%",
  [theme.breakpoints.down("sm")]: {
    marginTop: "25%",
  },
}));

const StyledText = styled(Text)(({ theme }) => ({
  lineHeight: "120%",
  color: palette.neutral[70],
  fontSize: "24px",
  fontWeight: "regular",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
