import { SvgIconProps } from "@mui/material";

const CollapseBarIcon = ({
  width = 33,
  height = 33,
  fill = "#E6E6E3",
  fillOpacity = "0",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.5"
        width={Number(width) - 1}
        height={Number(height) - 1}
        rx="8"
        fill="white"
        fillOpacity={fillOpacity}
        style={{
          transition: "fill-opacity 0.2s",
        }}
      />
      <path
        d="M23.125 9.625H9.375C9.04348 9.625 8.72554 9.7567 8.49112 9.99112C8.2567 10.2255 8.125 10.5435 8.125 10.875V22.125C8.125 22.4565 8.2567 22.7745 8.49112 23.0089C8.72554 23.2433 9.04348 23.375 9.375 23.375H23.125C23.4565 23.375 23.7745 23.2433 24.0089 23.0089C24.2433 22.7745 24.375 22.4565 24.375 22.125V10.875C24.375 10.5435 24.2433 10.2255 24.0089 9.99112C23.7745 9.7567 23.4565 9.625 23.125 9.625ZM9.375 18.375H10.625C10.7908 18.375 10.9497 18.3092 11.0669 18.1919C11.1842 18.0747 11.25 17.9158 11.25 17.75C11.25 17.5842 11.1842 17.4253 11.0669 17.3081C10.9497 17.1908 10.7908 17.125 10.625 17.125H9.375V15.875H10.625C10.7908 15.875 10.9497 15.8092 11.0669 15.6919C11.1842 15.5747 11.25 15.4158 11.25 15.25C11.25 15.0842 11.1842 14.9253 11.0669 14.8081C10.9497 14.6908 10.7908 14.625 10.625 14.625H9.375V13.375H10.625C10.7908 13.375 10.9497 13.3092 11.0669 13.1919C11.1842 13.0747 11.25 12.9158 11.25 12.75C11.25 12.5842 11.1842 12.4253 11.0669 12.3081C10.9497 12.1908 10.7908 12.125 10.625 12.125H9.375V10.875H12.5V22.125H9.375V18.375ZM23.125 22.125H13.75V10.875H23.125V22.125Z"
        fill={fill}
      />
    </svg>
  );
};

export default CollapseBarIcon;
