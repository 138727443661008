import { VIDEO_BASE_URL } from "@constants/constants";
import { isMobile } from ".";

export const buildAssetsUrl = (
  domain: string,
  path: string,
  fileType: string,
  shoudlRenderMobile: boolean,
  mobilePattern: string,
) => {
  const extension =
    shoudlRenderMobile && isMobile
      ? `${mobilePattern}.${fileType}`
      : `.${fileType}`;
  return `${domain}/${path}${extension}`;
};

export const buildCompaignAndBannerVideo = (path: string) => {
  return buildAssetsUrl(VIDEO_BASE_URL, path, "mp4", true, "-mobile");
};

export const dataURLtoFile = async (
  base64String: string,
  fileName: string,
): Promise<File> => {
  const pureBase64String = base64String.split(",")[1];
  const binaryString = atob(pureBase64String);
  const arr = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
  const blob = new Blob([arr], { type: "image/png" });
  return new File([blob], fileName, { type: "image/png" });
};

export const fileToBase64 = (file: File) =>
  new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const urlToBase64 = async (
  url?: string,
  name?: string,
  fileType?: string,
) => {
  if (!url) return undefined;
  const response = await fetch(url);
  if (!response.ok) return undefined;
  const data = await response.blob();
  const file = new File([data], name || "image", {
    type: fileType || data?.type,
  });
  const base64 = await fileToBase64(file);
  return base64;
};
