import Stack from "@mui/material/Stack";
import { useAppDispatch } from "@redux/hooks";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useAppSelector } from "@redux/hooks";
import { RootState } from "@redux/store";

const btnStyle = {
  gap: 0.5,
  ml: "auto",
  fontSize: 14,
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "center",
  color: "#575353",
};

interface ClearFiltersButtonProps {
  clear: ActionCreatorWithoutPayload<string>;
  selector: (state: RootState) => any;
}

const ClearFiltersButton = ({
  clear,
  selector,
}: ClearFiltersButtonProps) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selector);

  const empt = [filters]
    .map((x: any) => Object.values(x).every((x) => !x))
    .every((x: any) => x);

  if (empt) return null;

  return (
    <Stack sx={btnStyle} onClick={() => dispatch(clear())}>
      Clear Filters
    </Stack>
  );
};

export default ClearFiltersButton;
