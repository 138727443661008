import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Changelog {
  modal: {
    isOpen: boolean;
    isExpanded: boolean;
  };
}

const initialState: Changelog = {
  modal: {
    isOpen: false,
    isExpanded: false,
  },
};

const ChangelogSlice = createSlice({
  name: "changelog",
  initialState,
  reducers: {
    setModalOpenChangelog: (
      state: Changelog,
      action: PayloadAction<boolean>,
    ) => {
      state.modal.isOpen = action.payload;
    },
    resetChangelogState: (state: Changelog) => {
      state.modal = {
        isOpen: false,
        isExpanded: false,
      };
    },
  },
});

export const { setModalOpenChangelog, resetChangelogState } =
  ChangelogSlice.actions;
export const selectChangelog = (state: RootState) => state.changelog;
export default ChangelogSlice.reducer;
