import React, { useEffect } from "react";
import {
  SxProps,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Text, TruncateText } from "@common/Text";
import { palette } from "@palette";
import { Box } from "@mui/material";
import { TruncateTextProps } from "@common/Text/TruncateText";

export type TooltipProps = MuiTooltipProps & {
  renderIcon?: () => React.ReactNode;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  titleSx?: SxProps;
  fullWidth?: boolean;
  bgColor?: string;
  lineClamp?: TruncateTextProps["lineClamp"];
  parentStyle?: SxProps;
  maxWidth?: string;
  descriptionSx?: SxProps;
  titleContainerSx?: SxProps;
  toolTipSx?: SxProps;
};

const TooltipIcon = styled("span")({
  "& *": {
    fill: palette.common.white,
  },
});

export const Tooltip = styled(
  ({
    renderIcon,
    title,
    description,
    disableHoverListener,
    titleSx,
    fullWidth,
    lineClamp,
    parentStyle,
    descriptionSx,
    titleContainerSx,
    ...props
  }: TooltipProps) => {
    const [open, setOpen] = React.useState(false);
    const [tooltipDisabled, setTooltipDisabled] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    const hoverHandler = (e: any) => {
      if (open) return;
      if (tooltipDisabled) return;
      handleTooltipOpen();
    };

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div style={{ width: fullWidth ? "100%" : "initial" }}>
          <MuiTooltip
            classes={{ popper: props.className }}
            enterDelay={0}
            leaveTouchDelay={3500}
            onClose={handleTooltipClose}
            open={!disableHoverListener ? open : false}
            title={
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    ...(titleContainerSx && titleContainerSx),
                  }}
                >
                  {renderIcon && <TooltipIcon>{renderIcon()}</TooltipIcon>}
                  {title && (
                    <TruncateText
                      lineClamp={lineClamp || 5}
                      sx={{
                        textAlign: "center",
                        fontSize: "14px",
                        ...(titleSx && titleSx),
                      }}
                      variant="caption"
                    >
                      {title}
                    </TruncateText>
                  )}
                </Box>
                {description && (
                  <Text
                    variant="captionSmall"
                    color="#E1E1DE"
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      ...(descriptionSx && descriptionSx),
                    }}
                  >
                    {description}
                  </Text>
                )}
              </>
            }
            {...props}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...parentStyle,
              }}
              onClick={disableHoverListener ? undefined : handleTooltipOpen}
              onMouseOver={disableHoverListener ? undefined : hoverHandler}
            >
              {React.cloneElement(props.children, {
                onOpening: (event: "toggle_tooltip", data?: any) => {
                  if (event === "toggle_tooltip") {
                    if (data) {
                      setOpen(false);
                      setTooltipDisabled(true);
                    } else {
                      setTooltipDisabled(false);
                    }
                  }
                },
              })}
            </Box>
          </MuiTooltip>
        </div>
      </ClickAwayListener>
    );
  },
  {
    shouldForwardProp: (prop) =>
      prop !== "bgColor" && prop !== "maxWidth" && prop !== "toolTipSx",
  },
)<{
  bgColor?: string;
  maxWidth?: string;
  toolTipSx?: SxProps;
}>(({ theme, bgColor, maxWidth, toolTipSx }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    backgroundColor: bgColor || "#403D3D",
    color: theme.palette.common.white,
    maxWidth: maxWidth || 220,
    fontSize: "12px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    position: "relative",
    textAlign: "center",
    top: "-5px",
    ...toolTipSx,
  },
}));
