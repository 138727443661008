import { TagType } from "@common/Tag";

export type RevenuesData = {
  id: string;
  source: string;
  sourceDescription:string;
  amount: number;
  average:number
  createdAt?: string;
  details: {
    fundraiser: string;
    revenueSource:string;
    transaction: number;
    url:string;
    date: string;
    amount: number,
    average:number,
    VisitorsViews: number;
    Visitors : number;
  };
 
};

export const tableData = [
  {
    id: "1",
    source: "New Donation Form",
    sourceDescription:"Givebox public API",
    amount: 230,
    average:87,
    details: {
        fundraiser: "New Donation Form",
        revenueSource:"Givebox Public API",
        transaction: 7,
        url:"buddyteal.com/",
        date: "Jun 30 2022 10:23PM UCT",
        amount: 280,
        average:40,
        VisitorsViews: 230,
        Visitors: 87,
    },
  },
  {
    id: "2",
    source: "New Donation Form",
    sourceDescription:"Website",
    amount: 280,
    average:40,
    details: {
        fundraiser: "New Donation Form",
        revenueSource:"Givebox Public API",
        transaction: 7,
        url:"buddyteal.com/",
        date: "Jun 30 2022 10:23PM UCT",
        amount: 280,
        average:40,
        VisitorsViews: 230,
        Visitors: 87,
    },
    
  },
  {
    id: "3",
    source: "New Donation Form",
    sourceDescription:"Website",
    amount: 280,
    average:40,
    details: {
        fundraiser: "New Donation Form",
        revenueSource:"Givebox Public API",
        transaction: 7,
        url:"buddyteal.com/",
        date: "Jun 30 2022 10:23PM UCT",
        amount: 280,
        average:40,
        VisitorsViews: 230,
        Visitors: 87,
    },
    
  },
  {
    id: "4",
    source: "New Donation Form",
    sourceDescription:"Website",
    amount: 280,
    average:40,
    details: {
        fundraiser: "New Donation Form",
        revenueSource:"Givebox Public API",
        transaction: 7,
        url:"buddyteal.com/",
        date: "Jun 30 2022 10:23PM UCT",
        amount: 280,
        average:40,
        VisitorsViews: 230,
        Visitors: 87,
    },
   
  },
  {
    id: "5",
    source: "New Donation Form",
    sourceDescription:"Website",
    amount: 280,
    average:40,
    details: {
        fundraiser: "New Donation Form",
        revenueSource:"Givebox Public API",
        transaction: 7,
        url:"buddyteal.com/",
        date: "Jun 30 2022 10:23PM UCT",
        amount: 280,
        average:40,
        VisitorsViews: 230,
        Visitors: 87,
    },
   
  },
  
];
