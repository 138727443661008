const size = {
    size: {
      small: {
        width: 36,
        height: 20,
        knob: {
          width: 20,
          height: 16,
        },
      },
      medium: {
        width: 56,
        height: 28,
        knob: {
          width: 30,
          height: 24,
        },
      },
    },
  };
  
  const states = {
    disabled: {
      track: {
        color: "#E1E1Df",
      },
      knob: {
        color: "#F8F8F6",
      },
    },
    hover: { borderColor: "#8F8F8F", borderWidth: 2 },
  };
  
  const switched = {
    switched: {
        transition: 300,
        knob: {
            color: "#fff",
        },
      on: {
        background: "#131212",
      },
      off: {
        background: "#D1D1D0",
      },
    },
  };
  
  export const switchTheme = {
    transition: 500,
    ...size,
    ...states,
    ...switched,
  };