import pl from "@assets/images/imageGalleryWidgetPlaceholder.png";
import { DEFAULT_BORDER_COLOR } from "@pages/NewAdvancedBuilder/signals";

const imageGalleryRootStyles = (state: any) => {
  return {
    display: "grid",
    width: "100%",
    gap: `${state.gap}px`,
    content: state?.hasImage === "false" ? `url("${pl}")` : undefined,
    height: state?.hasImage === "false" ? "300px" : "auto",
  };
};

const imageGalleryImageStyles = (state: any, cssProperties?: any) => {
  /* If user tries to add image during edit (before editting images didn't exist) in state we won't have these values,
     so we need to provide default
  */
  return {
    borderColor: `${
      state.imageBorderColor?.color || DEFAULT_BORDER_COLOR.color
    }`,
    borderStyle: `${state.imageBorderStyle?.value || "none"}`,
    borderRadius: `${state.imageBorderRadius || 0}px`,
    borderWidth: `${state.imageBorderWidth || 0}px`,
    margin: "0",
    width: "100%",
    aspectRatio: state.aspectRatio || "1/1",
    content: cssProperties?.imageSrc,
    objectFit: "cover",
  };
};

export const imageGalleryRootBlueprint = () => {
  return {
    id: 21,
    tag: "div",
    childrens: [],
    bindings: {
      "style.gridTemplateColumns": "state.gridTemplateColumns",
      "style.gap": "state.gap",
      "metaAttributes.data-hasImage": "state.hasImage",
    },
    metaAttributes: {
      "data-hasImage": "false",
    },
    stateParser: ({ state }: any) => ({
      style: {
        ...imageGalleryRootStyles(state),
        gridTemplateColumns: `${
          state.gridTemplateColumns || "repeat(2, minmax(0, 1fr))"
        }`,
      },
      tabletStyle: {
        ...imageGalleryRootStyles(state),
        gridTemplateColumns: `${
          state.gridTemplateColumns || "repeat(2, minmax(0, 1fr))"
        }`,
      },
      mobileStyle: {
        ...imageGalleryRootStyles(state),
        gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      },
    }),
  };
};

export const imageGalleryImageBlueprint = (
  cssProperties: any,
  title: string,
) => {
  return {
    id: 22,
    bindings: {
      "style.content": "cssProperties.imageSrc",
      "style.borderRadius": "state.imageBorderRadius",
      "style.borderWidth": "state.imageBorderWidth",
      "style.borderStyle": "state.imageBorderStyle",
      "style.borderColor": "state.imageBorderColor",
      "style.aspectRatio": "state.aspectRatio",
      "metaAttributes.data-bgColorName": "state.imageBorderColor",
      "metaAttributes.borderStyle": "state.imageBorderStyle",
    },

    tag: "img",
    tabletTag: "img",
    mobileTag: "img",
    childrens: [],
    cssProperties,
    tabletCssProperties: { ...cssProperties },
    mobileCssProperties: { ...cssProperties },
    stateParser: ({
      state,
      cssProperties,
      tabletCssProperties,
      mobileCssProperties,
    }: any) => ({
      style: {
        ...imageGalleryImageStyles(state, cssProperties),
      },
      tabletStyle: {
        ...imageGalleryImageStyles(state, tabletCssProperties || cssProperties),
      },
      mobileStyle: {
        ...imageGalleryImageStyles(state, mobileCssProperties || cssProperties),
      },
      /* If user tries to add image during edit (before editting images didn't exist) in state we won't have these values,
         so we need to provide default
      */
      metaAttributes: {
        "data-imageTitle": title,
        "data-bgColorName":
          state.imageBorderColor?.name || DEFAULT_BORDER_COLOR.name,
        "data-borderStyle": state.imageBorderStyle?.label || "None",
      },
      tabletMetaAttributes: {
        "data-imageTitle": title,
        "data-bgColorName":
          state.imageBorderColor?.name || DEFAULT_BORDER_COLOR.name,
        "data-borderStyle": state.imageBorderStyle?.label || "None",
      },
      mobileMetaAttributes: {
        "data-imageTitle": title,
        "data-bgColorName":
          state.imageBorderColor?.name || DEFAULT_BORDER_COLOR.name,
        "data-borderStyle": state.imageBorderStyle?.label || "None",
      },

      widgetType: "single_image",
    }),
  };
};
