import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";

type TVariant = "mark_as_fraud" | "unmark_as_fraud";

type TProps = {
  variant: TVariant;
  disputeId: number;
};

const congif = {
  mark_as_fraud: {
    title: "Mark as fraud",
    description: (
      <>
        Are you sure you want to mark this transaction as fraud?
        <br />
        The risk level will rise to 3. You can reverse this action later if
        needed.
      </>
    ),
    modalWidth: 494,
  },
  unmark_as_fraud: {
    title: "Unmark as fraud",
    description: `Are you sure you want to unmark this case as fraud? 
    This will lower the risk level back to its previous state.`,
    modalWidth: 454,
  },
};

const DisputeFraud = NiceModal.create(({ variant, disputeId }: TProps) => {
  const modal = useModal();

  const handleCancel = () => modal.hide();

  const handleChange = () => {
    console.log({ variant, disputeId });
  };

  const settings = congif[variant];

  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open: modal.visible,
        onClose: handleCancel,
        width: settings.modalWidth,
      }}
    >
      <ModalTitle
        title={settings.title}
        description={settings.description}
        padding="24px 24px 0"
        onClose={handleCancel}
        textStyles={{
          title: {
            fontSize: 18,
          },
          description: {
            fontWeight: 350,
          },
        }}
      />

      <ModalActions
        animationDelay={100}
        padding="20px 24px 16px"
        secondaryAction={{
          label: "Cancel",
          onClick: handleCancel,
          sx: { fontSize: 18 },
        }}
        primaryAction={{
          type: "button",
          label: settings.title,
          onClick: handleChange,
          background: "primary",
          isLoading: false,
          sx: { fontSize: 18, padding: "12px 24px" },
        }}
      />
    </ModalFactory>
  );
});

export default DisputeFraud;
