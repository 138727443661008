import React from "react";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Figure from "@common/Figure";

export type StatsTitleProps = {
  title?: string | React.ReactNode;
  value: string;
  isAmount?: boolean;
  fontSize?: number;
  align?: "right" | "left" | "center" | "inherit" | "justify";
  isMainTitle?: boolean;
  alignFigure?: "right" | "left";
  ratio?: number | string | undefined;
  ratioFontSize?: number | string | undefined;
};

export const StatsTitle = ({
  title,
  value,
  isAmount = true,
  fontSize = 96,
  align = "right",
  isMainTitle,
  alignFigure = "right",
}: StatsTitleProps) => {
  const alignItems = () => {
    switch (alignFigure) {
      case "right":
        return "flex-end";
      case "left":
        return "flex-start";
      default:
        return undefined;
    }
  };

  return (
    <Stack direction="column" alignItems={alignItems}>
      <Figure
        sup={isMainTitle}
        value={value}
        fontSize={fontSize}
        color={palette.gradient[10]}
        variant={"h3"}
        fontWeight={"light"}
        lineHeight="normal"
        isAmount={isAmount}
      />
      <TitleText
        variant="h5"
        align={align}
        width="100%"
        data-testid={`stats-title-${title}`}
      >
        {title}
        {isAmount && <span> (USD)</span>}
      </TitleText>
    </Stack>
  );
};

const TitleText = styled(Text)(() => ({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "120%",
  color: palette.black[100],
}));

export default StatsTitle;
