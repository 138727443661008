import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import useUserReducer from "@hooks/Reducers/useUserReducer";

export enum Role {
  ACQUIRER = "acquirer",
  ENTERPRISE = "provider",
  MERCHANT = "merchant",
}

export const useUser = () => {
  const { role } = useUserReducer();
  const { masqueradeUserRole, isMasqueradeMode } = useMasqueradeReducer();
  const currentUserRole = isMasqueradeMode ? masqueradeUserRole : role;

  return {
    isAcquirer: currentUserRole === Role.ACQUIRER,
    isEnterprise: currentUserRole === Role.ENTERPRISE,
    isMerchant: currentUserRole === Role.MERCHANT,
    userRole: currentUserRole as Role,
  };
};
