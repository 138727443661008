import * as React from "react";
import { palette } from "@palette";
// @mui
import { makeStyles } from "@mui/styles";
import { Box, Grid, Stack } from "@mui/material";
// components
import { KeyVal } from "@common/KeyVal";
import { Text } from "@common/Text";
// assets
import { InfoIcon } from "@assets/icons";
import { getStatusMessage } from "@components/BankAccounts/utils";
import { Button } from "@common/Button";
import { ExternalLinkIcon } from "@assets/rebrandIcons";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";

type ExpandedRowProps = {
  rowData: IMerchantBankAccount;
  pinkBorder?: boolean;
};

export const ExpandedRow: React.FC<ExpandedRowProps> = ({ rowData }) => {
  const classes = useStyles();

  const handleViewPlaidInfo = () => {
    if (!rowData?.authPlaidInfo) return;
    const blob = new Blob([rowData?.authPlaidInfo], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Box className={classes.box}>
      <Stack p={1} gap={2} mb={1} direction="row" alignItems="center">
        <InfoIcon height={20} width={20} path_fill={palette.gray[100]} />
        <Text
          fontSize={14}
          variant="body"
          fontWeight="light"
          lineHeight="16.8px"
          color={palette.neutral[80]}
        >
          {getStatusMessage({ data: rowData })}
        </Text>
      </Stack>

      {rowData?.authPlaidInfo && (
        <Box display="flex" alignItems="center" sx={{ marginY: "18.5px" }}>
          <Text fontSize={16} fontWeight="light" color={palette.neutral[80]}>
            Linked through Plaid
          </Text>
          <Text
            fontWeight="book"
            color={palette.neutral[80]}
            sx={{ ml: 1, mr: 3 }}
          >
            {rowData?.created}
          </Text>
          <Button
            background="tertiary"
            size="small"
            endIcon={<ExternalLinkIcon />}
            onClick={handleViewPlaidInfo}
          >
            <Text>View Plaid info</Text>
          </Button>
        </Box>
      )}

      <Grid container spacing={2}>
        {!rowData?.authPlaidInfo && (
          <Grid item xs={12} sm={6}>
            <KeyVal
              keyName="Created"
              value={rowData?.created}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <KeyVal
            align={rowData?.authPlaidInfo ? "left" : "right"}
            keyName="Name on account"
            value={
              <Text sx={nameStyle} data-testid="name-on-account">
                {rowData?.name}
              </Text>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyVal
            align={rowData?.authPlaidInfo ? "right" : "left"}
            keyName="Account type"
            value={
              <Text
                sx={nameStyle}
                textTransform="capitalize"
                color={palette.neutral[800]}
                data-testid="account-type"
              >
                {rowData?.type}
              </Text>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyVal
            align={rowData?.authPlaidInfo ? "left" : "right"}
            keyName="Routing number"
            value={rowData?.routingNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyVal
            align={rowData?.authPlaidInfo ? "right" : "left"}
            keyName="Account number"
            value={`********${rowData?.accountNumber?.slice(-4)}`}
          />
        </Grid>
        {rowData?.bankName && (
          <Grid item xs={12} sm={6}>
            <KeyVal
              align={rowData?.authPlaidInfo ? "left" : "right"}
              keyName="Bank name"
              value={rowData?.bankName}
            />
          </Grid>
        )}
        {rowData?.notes && (
          <Grid
            maxWidth="100%"
            overflow="hidden"
            item
            xs={12}
            sm={rowData?.bankName ? 12 : 6}
          >
            <KeyVal
              align={rowData?.authPlaidInfo ? "left" : "right"}
              keyName="Notes"
              value={rowData?.notes}
              keyProps={{
                align: rowData?.bankName ? "center" : "right",
              }}
              valProps={{
                align: rowData?.bankName ? "center" : "right",
                textTransform: undefined,
                sx: {
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles({
  box: {
    display: "flex",
    // gap: "12px",
    flexDirection: "column",
    padding: "16px",
    background: "white",
    borderRadius: 12,
    "& .keyVal-key": {
      fontSize: 14,
      lineHeight: "16.8px",
      color: palette.neutral[70],
      fontWeight: 350,
    },
    "& .keyVal-value": {
      fontSize: 14,
      lineHeight: "16.8px",
      color: palette.neutral[80],
      fontWeight: 350,
    },
  },
  compart1: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },

  compart2: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  head: {
    display: "flex",
    gap: "28px",
    alignItems: "center",
  },
  contents: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& >.MuiBox-root": {
      padding: "0 8px",
    },
  },
  spacedFlex: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiBox-root": {
      flex: 1,
    },
  },
  ps: {
    paddingTop: "20px !important",
  },
});

const nameStyle = {
  fontWeight: 350,
  maxWidth: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
