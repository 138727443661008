import React from "react";
//mui
import { Box, Stack, styled } from "@mui/material";
//components
import { Text } from "@common/Text";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";

const NoAccountModal = NiceModal.create(() => {
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
  };

  return (
    <StyledModal
      {...muiDialogV5(modal)}
      title="Account Selection"
      onClose={handleClose}
      modalType="selection"
    >
      <StyledStack>
        <Text
          variant="h5"
          fontWeight="book"
          fontSize={28}
          lineHeight="34px"
          color={palette.neutral[70]}
        >
          You don&apos;t have access to any merchant accounts
        </Text>
        <Box display="flex" justifyContent="flex-end">
          <Button background="primary" onClick={handleClose}>
            Understood
          </Button>
        </Box>
      </StyledStack>
    </StyledModal>
  );
});

const StyledStack = styled(Stack)(() => ({
  gap: "32px",
  display: "flex",
  background: "transparent",
  flexDirection: "column",
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "500px",
    display: "flex",
    flexDirection: "column",
    borderRadius: 16,
    border: "2px solid #FFFFFF",
    background: "rgba(255, 255, 255, 0.4)",
    boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(20px)",
  },
  [theme.breakpoints.down(600)]: {
    "& .MuiDialog-paper": {
      width: "100%",
    },
  },
}));

export default NoAccountModal;
