import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import NiceModal from "@ebay/nice-modal-react";
import GenericNotificationForm from "./GenericNotificationForm";
import { useState } from "react";
import {
  TCustomSubmitHandler,
  TNotificationType,
  TNotifyMerchantContent,
  TSubmitStatus,
  TFormSubmitHandler,
} from "./types";
import { showMessage } from "@common/Toast";
import {
  GenericNotificationPayload,
  notifyMerchant,
} from "@services/api/notifications/merchant";
import { useMutation } from "react-query";
import {
  getGlobalTopic,
  useConversationsModal,
} from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";

type NotifyMerchantProps = {
  notificationType?: TNotificationType;
  merchantName: string;
  merchantId: number;
  isEnterprise?: boolean;
  customSubmit?: TCustomSubmitHandler;
};

const Content: Record<TNotificationType, TNotifyMerchantContent> = {
  generic: GenericNotificationForm,
};

const NotifyMerchantBase = ({
  notificationType = "generic",
  merchantName,
  merchantId,
  isEnterprise,
  customSubmit,
}: NotifyMerchantProps) => {
  const [submitStatus, setSubmitStatus] = useState<TSubmitStatus>({
    isDisabled: true,
    message: "A message is required",
  });

  const { open, onClose, TransitionProps } = useNiceModal();
  const { openConversationHandler } = useConversationsModal();

  const Form = Content[notificationType];

  const notifyMerchantMutation = useMutation(
    (data: GenericNotificationPayload) => {
      return notifyMerchant(merchantId, data);
    },
    {
      onSuccess: () => {
        showMessage(
          "Success",
          "",
          true,
          `Notification was successfully sent to the “${merchantName}”`,
        );
      },
      onError: () => {
        showMessage(
          "Warning",
          "",
          true,
          `An error occured while sending the notification`,
        );
      },
    },
  );

  const onSubmit: TFormSubmitHandler = async (data) => {
    const { data: topic } = await getGlobalTopic({ topicName: "underwriting" });
    const underwritingTopicID = topic?.[0]?.ID || 0;
    if (!underwritingTopicID) return;

    if (customSubmit) {
      customSubmit(data);
    } else {
      notifyMerchantMutation.mutate({
        subject: data?.subject || "",
        message: data?.message,
      });
    }

    if (data.openConversation) {
      openConversationHandler({
        id: underwritingTopicID,
        name: "Underwriting",
        forceTopicOpen: true,
        paths: [],
        defaultMessage: `#BusinessProfile merchant was notified due to :\n${data.message}`,
      });
    }
    onClose();
  };

  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose,
        DialogProps: {
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        padding="24px 24px 20px"
        title={isEnterprise ? "Notify Provider" : "Notify Merchant"}
        onClose={onClose}
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
            letterSpacing: "-0.18px",
          },
        }}
      />
      <Form onSubmit={onSubmit} setSubmitStatus={setSubmitStatus} />
      <ModalActions
        padding="24px 24px 20px"
        animationDelay={100}
        secondaryAction={{
          onClick: onClose,
        }}
        primaryAction={{
          label: "Send",
          form: "notify-merchant-form",
          disabled: submitStatus.isDisabled || notifyMerchantMutation.isLoading,
          tooltipProps: {
            show: submitStatus.isDisabled && !!submitStatus.message,
            message: submitStatus.message,
          },
        }}
      />
    </ModalFactory>
  );
};

const NotifyMerchantV2 = NiceModal.create(NotifyMerchantBase);

export default NotifyMerchantV2;
