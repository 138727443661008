import { IColumn, TColumnType } from "@common/Campaigns/campaigns.types";
import { Stack } from "@mui/material";
import { SpringValue, animated } from "react-spring";
import { TextSkeleton, ListItemTitle } from "./atoms";

type ListItemSkeletonProps = {
  height: SpringValue<number>;
  columns: IColumn[];
  rowHeight: number;
  listSkeletons?: any;
};

const ListItemSkeleton = ({
  height,
  columns,
  rowHeight,
  listSkeletons,
}: ListItemSkeletonProps) => {
  const l = listSkeletons ?? ListSkeletons;

  return (
    <animated.div style={{ height }}>
      <Stack
        direction="row"
        height={rowHeight}
        alignItems="stretch"
        padding="0px 4px"
        data-testid="skeleton-item"
      >
        {columns.map((column, index) => {
          const { columnWidth, type } = column || {};
          
          return (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="stretch"
              width={
                typeof columnWidth === "number"
                  ? `${columnWidth * 100}%`
                  : "25%"
              }
              gap={1}
              sx={{
                paddingInline: index !== 0 ? "8px" : 0,
              }}
            >
              {l[type || "text"]}
            </Stack>
          );
        })}
      </Stack>
    </animated.div>
  );
};

const ListSkeletons: Record<TColumnType, JSX.Element> = {
  text: <TextSkeleton />,
  title: <ListItemTitle />,
};

export default ListItemSkeleton;
