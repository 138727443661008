import { UploadCloudIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { styled } from "@mui/material";

type TUploadProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const Input = styled("input")({
  display: "none",
});

const UploadButton = (props: TUploadProps) => {
  const { onChange, disabled } = props;

  return (
    <label htmlFor="contained-button-file">
      <Input
        accept="image/jpeg, image/png"
        type="file"
        disabled={disabled}
        onChange={(e) => {
          if (disabled) return;
          onChange(e);
        }}
        id="contained-button-file"
        data-testid="contained-button-file"
      />
      <Button component="span">
        <UploadCloudIcon />
        Upload image
      </Button>
    </label>
  );
};

export default UploadButton;
