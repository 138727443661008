import React from "react";

const StarFilledIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45314 6.02296C9.10864 4.3888 9.43639 3.57172 10 3.57172C10.5636 3.57172 10.8914 4.3888 11.5469 6.02297L11.5774 6.09906C11.9477 7.02228 12.1329 7.4839 12.5102 7.76447C12.8876 8.04504 13.383 8.08941 14.3738 8.17814L14.5529 8.19418C16.1744 8.3394 16.9852 8.41201 17.1586 8.92783C17.3321 9.44365 16.73 9.99144 15.5258 11.087L15.1239 11.4527C14.5143 12.0073 14.2095 12.2846 14.0675 12.648C14.041 12.7158 14.0189 12.7852 14.0015 12.8559C13.9081 13.2348 13.9974 13.6371 14.1759 14.4416L14.2315 14.6921C14.5595 16.1707 14.7236 16.91 14.4372 17.2289C14.3301 17.348 14.191 17.4338 14.0365 17.476C13.623 17.5888 13.036 17.1105 11.8618 16.1537C11.0909 15.5255 10.7054 15.2114 10.2628 15.1407C10.0887 15.1129 9.9113 15.1129 9.73721 15.1407C9.29462 15.2114 8.90914 15.5255 8.13816 16.1537C6.96403 17.1105 6.37697 17.5888 5.96347 17.476C5.80895 17.4338 5.66986 17.348 5.56284 17.2289C5.27644 16.91 5.44047 16.1707 5.76853 14.6921L5.8241 14.4416C6.00261 13.6371 6.09186 13.2348 5.99848 12.8559C5.98106 12.7852 5.95903 12.7158 5.93253 12.648C5.79047 12.2846 5.48567 12.0073 4.87609 11.4527L4.47419 11.087C3.26998 9.99144 2.66788 9.44365 2.84136 8.92783C3.01484 8.41201 3.8256 8.3394 5.44711 8.19418L5.62623 8.17814C6.61699 8.08941 7.11237 8.04504 7.48975 7.76447C7.86712 7.4839 8.05229 7.02228 8.42261 6.09906L8.45314 6.02296Z"
        fill="#403D3D"
        stroke="#403D3D"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default StarFilledIcon;
