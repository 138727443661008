import { Paper, PaperProps } from "@mui/material";
import {
  Stack,
  SwipeableDrawer,
  SwipeableDrawerProps,
  styled,
} from "@mui/material";
import { palette } from "@palette";
import { forwardRef, useRef, useState } from "react";
import Draggable, { ControlPosition, DraggableBounds } from "react-draggable";

const DetailsModal = ({
  isExpanded,
  children,
  closeCallback,
  ...props
}: {
  children: React.ReactNode;
  isExpanded: boolean;
  closeCallback: (secondaryAction?: () => void) => void;
} & SwipeableDrawerProps) => {
  const [dragPosition, setDragPosition] = useState<ControlPosition | undefined>(
    undefined,
  );
  const initialClientY = useRef<number>(0);

  const resetPosition = () => setDragPosition({ x: 0, y: 0 });

  const onDrag = (event: any) => {
    if (!initialClientY.current) {
      initialClientY.current = event.clientY;
      return;
    }
  };

  const onStop = (event: any) => {
    if (
      event.clientY >
      (window.innerHeight - initialClientY.current) / 2 + initialClientY.current
    ) {
      closeCallback(resetPosition);
    } else {
      resetPosition();
    }
  };

  return (
    <SwipeableDrawer
      sx={{
        position: "relative",
        "& .MuiBackdrop-root": {
          ...(!isExpanded && {
            display: "none",
          }),
        },
      }}
      PaperProps={{
        component: CustomPaper,
        onDrag,
        onStop,
        dragPosition,
        bounds: {
          top: 0,
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(53, 53, 53, 0.10)",
          },
        },
      }}
      anchor="bottom"
      data-testid="details-modal"
      {...props}
    >
      <Stack direction="column" alignItems="stretch" position="relative">
        {children}
      </Stack>
    </SwipeableDrawer>
  );
};

type CustomPaperProps = PaperProps & {
  onDrag: (event: any) => void;
  onStop: (event: any) => void;
  dragPosition?: { x: 0; y: 0 };
  bounds?: DraggableBounds;
};

const CustomPaper = forwardRef<HTMLDivElement, CustomPaperProps>(
  ({ onDrag, onStop, dragPosition, bounds, ...props }, ref) => {
    return (
      <Draggable
        axis="y"
        handle=".handle-details-modal-drag"
        onDrag={onDrag}
        onStop={onStop}
        position={dragPosition}
        bounds={bounds}
      >
        <PaperBase ref={ref} {...props} />
      </Draggable>
    );
  },
);
CustomPaper.displayName = "CustomPaper";

const PaperBase = styled(Paper)(() => ({
  width: "100%",
  padding: "8px",
  borderRadius: "16px 16px 0px 0px",
  backgroundColor: `${palette.liftedWhite.main} !important`,
  boxShadow: "0px -4px 30px 0px rgba(159, 159, 159, 0.25)",
  marginTop: "80px",
}));

export default DetailsModal;
