import { Grid, Stack, SxProps } from "@mui/material";
import CustomCheck, { ICustomCheckProps } from "./CustomCheck";
import React from "react";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

export type TNotificationCheckbox = ICustomCheckProps & {
  channel: "email" | "push";
};

type Props = {
  showBorder?: boolean;
  isTabBar?: boolean;
  delay?: number;
  mainContent: React.ReactNode;
  checkboxes?: TNotificationCheckbox[];
  containerSx?: SxProps;
};

const NotificationGridLayout = ({
  showBorder = true,
  isTabBar = false,
  mainContent,
  checkboxes = [],
  delay = 50,
  containerSx,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const list = checkboxes.filter((c) => !c.hidden);
  const totalCheckboxes = list.length;

  const renderCheckbox = (
    { channel, ...props }: TNotificationCheckbox,
    index: number,
  ) => {
    const size = isMobileView ? 3.5 : 2;
    const alignment = isMobileView ? "flex-start" : "flex-end";
    return (
      <Grid item xs={size} key={`${channel}-${index}`}>
        <Stack direction="row" justifyContent={alignment}>
          <CustomCheck {...props} />
        </Stack>
      </Grid>
    );
  };

  const mainContentSize =
    checkboxes.length > 0 && !isMobileView ? 12 - totalCheckboxes * 2 : 12;

  const getPadding = () => {
    if (isTabBar) return isMobileView ? 0 : "12px 0";
    return isMobileView ? "16px 0" : "8px 0";
  };

  return (
    <FadeUpWrapper delay={delay}>
      <Grid
        container
        sx={{
          padding: getPadding(),
          ...(showBorder && {
            borderBottom: `1px solid ${palette.liftedWhite[200]}`,
          }),
          ...containerSx,
        }}
      >
        <Grid item xs={mainContentSize}>
          {mainContent}
        </Grid>
        {isMobileView ? (
          <Grid
            container
            justifyContent="flex-start"
            sx={{ marginTop: "16px" }}
          >
            {totalCheckboxes > 0 && list.map(renderCheckbox)}
          </Grid>
        ) : (
          totalCheckboxes > 0 && list.map(renderCheckbox)
        )}
      </Grid>
    </FadeUpWrapper>
  );
};

export default NotificationGridLayout;
