import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { useGetCurrentMerchantId } from "@hooks/common";
import { showMessage } from "@common/Toast";
import { generatePayload } from "../helpers/generatePayload";
import {
  generatePrincipalPayload,
  onPrincipalError,
} from "../utils/principal.utils";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";

export type FormSectionName =
  | "businessDetails"
  | "businessAddress"
  | "businessOwners"
  | "merchantInfo"
  | "enterpriseInfo";

type Props = {
  legalEntityId?: number;
};

type TOptions = {
  handleNext?: () => void;
  makeApiCall?: boolean;
  dirtyFields?: Record<string, boolean | undefined>;
  onSuccess?: () => void;
};

export type TSetFormValues = (
  name: FormSectionName,
  values: any,
  options?: TOptions,
) => void;

function useBusinessDetailsData({ legalEntityId }: Props) {
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();

  const refetchLegalEntity = () =>
    queryClient.refetchQueries([
      "find-profile-by-id",
      legalEntityId,
      merchantId,
    ]);

  const refetchMerchant = () =>
    queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);

  const submitStep: TSetFormValues = async (name, values, options) => {
    const isBusinessSection = ["businessAddress", "businessDetails"].includes(
      name,
    );

    const mutation = isBusinessSection
      ? createBusinessProfileMutation
      : updateMerchantMutation;

    let payload = generatePayload(name, values, options?.dirtyFields);
    if (name === "businessAddress") {
      payload = {
        address: payload,
      };
      await updateMerchantMutation.mutateAsync(payload, { onError });
    }

    mutation.mutate(payload, {
      onSuccess: () => {
        if (isBusinessSection) refetchLegalEntity();
        refetchMerchant();

        options?.handleNext && options.handleNext();
      },
      onError,
    });
  };

  const submitPrincipal = (values: any, options?: TOptions) => {
    const payload = generatePrincipalPayload(values);

    createBusinessOwnerMutation.mutate(payload, {
      onSuccess: () => {
        const message = values?.id
          ? "Business owner updated successfully"
          : "Business owner created successfully";

        showMessage("Success", message);

        refetchLegalEntity();

        options?.onSuccess && options.onSuccess();
        options?.handleNext && options.handleNext();
      },
      onError: onPrincipalError,
    });
  };

  const setFormValues: TSetFormValues = (name, values, options) => {
    if (name === "businessOwners") {
      submitPrincipal(values, options);
    } else {
      submitStep(name, values, options);
    }
  };

  const createBusinessOwnerMutation = useMutation((data: any) => {
    return customInstance({
      url: data?.id
        ? `/merchants/${merchantId}/legal-entities/${legalEntityId}/principals/${data?.id}`
        : `/merchants/${merchantId}/legal-entities/${legalEntityId}/principals`,
      method: data?.id ? "PATCH" : "POST",
      data,
    });
  });

  const createBusinessProfileMutation = useMutation((data: any) => {
    return customInstance({
      url: legalEntityId
        ? `/merchants/${merchantId}/legal-entities/${legalEntityId}`
        : `/merchants/${merchantId}/legal-entities`,
      method: legalEntityId ? "PATCH" : "POST",
      data,
    });
  });

  const updateMerchantMutation = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}`,
      method: "PATCH",
      data,
    });
  });

  return {
    setFormValues,
    isLoading:
      createBusinessProfileMutation.isLoading ||
      updateMerchantMutation.isLoading ||
      createBusinessOwnerMutation.isLoading,
  };
}

export default useBusinessDetailsData;

const onError = (error: any) => {
  showMessage("Error", getAPIError(error));
};

const getAPIError = (error: any) => {
  const inputError = error?.response?.data?.input?.[0] || null;

  if (inputError?.param === "taxIDNumber" && inputError?.code === "duplicate") {
    return `The provided "EIN" value is already taken.`;
  }

  return inputError?.message || error?.response?.data?.message;
};
