import React from "react";
import { Grid } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { FieldRecord } from "../../types";

interface Field {
  value?: string;
  size?: number;
}

interface SectionProps {
  fields: FieldRecord | FieldRecord[];
}

const MerchantExpandableSectionPartial: React.FC<SectionProps> = ({
  fields,
}) => (
  <>
    {
      Object.entries(fields).map(
        ([key, { value, size }]: [string, Field], index: number) => (
          <React.Fragment key={key}>
            <Grid
              item
              xs={size || 6}
              sx={{
                backgroundColor:
                  index & 1 ? palette.background.dimmedWhite : "#FFF",
                padding: "4px",
              }}
            >
              <Text color={palette.neutral[70]} sx={{ fontWeight: 350 }}>
                {key}
              </Text>
            </Grid>
            <Grid
              item
              xs={size || 6}
              sx={{
                backgroundColor:
                  index & 1 ? palette.background.dimmedWhite : "#FFF",
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
                padding: "4px",
              }}
            >
              <Text
                sx={{ fontWeight: 350, color: palette.neutral[80] }}
                data-testid={`merchant-agreement-${value}-value`}
              >
                {value}
              </Text>
            </Grid>
          </React.Fragment>
        ),
      )
    }
  </>
);

export default MerchantExpandableSectionPartial;
