import { RHFCheckbox } from "@common/Checkbox";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import RHFDateInput from "@components/Analytics/SidePanels/CampaignSidePanel/components/RHFDateInput";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useLocation } from "react-router-dom";

type Props = {
  isEditForm?: boolean;
  merchantId?: number;
};

function PreviousTermination({ isEditForm, merchantId }: Props) {
  const { watch } = useFormContext();
  const { handleSubmit } = useUpdateMerchantInfo(merchantId || 0);
  const values = watch();
  const location = useLocation();
  const isOnboarding = location.pathname.startsWith("/merchant/welcome");

  const onFormChange = () => {
    if (!isEditForm || isOnboarding) return;

    const prevTermination = values?.msaPreviousTermination;
    const isValidForm = prevTermination
      ? values.msaPreviousTerminationProcessorName &&
        values.msaPreviousTerminationAt &&
        values.msaPreviousTerminationReason
      : true;
    if (!isValidForm) return;

    handleSubmit("merchant_agreement", {
      msaPreviousTermination: prevTermination,
      msaPreviousTerminationProcessorName:
        values.msaPreviousTerminationProcessorName,
      msaPreviousTerminationReason: values.msaPreviousTerminationReason,
      msaPreviousTerminationAt: values.msaPreviousTerminationAt
        ? toUnixDateFormat(new Date(values.msaPreviousTerminationAt))
        : null,
    });
  };

  const content = [
    {
      Component: (
        <Stack
          sx={{
            "& .MuiFormControlLabel-root": {
              alignItems: "start",
            },
          }}
          direction="column"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <RHFCheckbox
              dataTestId="previousTermination_checkbox"
              name="msaPreviousTermination"
              label="Previous Termination Disclosure (Only Check if Applicable)"
              sx={{
                "& .MuiButtonBase-root.MuiCheckbox-root": {
                  height: 24,
                },
              }}
              labelProps={{
                color: "#575353",
                fontSize: 14,
                fontWeight: "book",
              }}
              disabled={isEditForm}
            />
          </Stack>
          <Text color="#8F8F8F" fontSize="14px" fontWeight="book" ml={4}>
            I hereby disclose that my previous merchant account(s) have been
            terminated or are currently subject to termination proceedings. I
            understand that providing false information or failing to disclose a
            prior account termination can result in the rejection of this
            application or subsequent termination of any account granted based
            on this application.
          </Text>
        </Stack>
      ),
    },
    {
      Component: (
        <RHFInput
          name="msaPreviousTerminationProcessorName"
          label="Name of Previous Processor"
          placeholder="Name of Previous Processor"
          fullWidth
          onBlur={() => onFormChange()}
        />
      ),
      hidden: !values.msaPreviousTermination,
    },
    {
      Component: (
        <RHFDateInput
          name="msaPreviousTerminationAt"
          label="Date of Termination"
          maxDate={new Date()}
          onBlur={() => onFormChange()}
        />
      ),
      hidden: !values.msaPreviousTermination,
    },
    {
      Component: (
        <RHFInput
          name="msaPreviousTerminationReason"
          label="Reason for Termination"
          placeholder="Reason for Termination"
          multiline
          rows={3}
          fullWidth
          inputProps={{
            maxLength: "256",
          }}
          helperText={`${
            values.msaPreviousTerminationReason?.length || 0
          }/${256} Characters`}
          onBlur={() => onFormChange()}
        />
      ),
      hidden: !values.msaPreviousTermination,
    },
  ];

  return (
    <>
      {content.map((item, idx) => {
        if (item.hidden) return null;
        return (
          <FadeUpWrapper delay={100 + idx * 50} key={idx}>
            <Box my="8px">{item.Component}</Box>
          </FadeUpWrapper>
        );
      })}
    </>
  );
}

export default PreviousTermination;
