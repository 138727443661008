import OpenEndedInterval from "@components/Customers/Table/OpenEndedInterval";
import { progressPercentageValue } from "@utils/index";
import React from "react";
import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { palette } from "@palette";

const Progress = styled(LinearProgress)(({ theme }) => ({
  width: 120,
  height: "8px",
  borderRadius: "32px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: palette.neutral[20],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "32px",
    backgroundColor: palette.neutral[100],
  },
}));

interface Props {
  recurringMax: number | null;
  recurringCount: number;
  recurringInterval: string;
}

export const RecurringComponent = ({
  recurringMax,
  recurringCount,
  recurringInterval,
}: Props) => {
  const progressValue = recurringMax
    ? progressPercentageValue(recurringCount, recurringMax)
    : 0;

  return (
    <Stack gap={1.5} direction="row" alignItems="center">
      <Text textTransform="capitalize">
        {recurringInterval === "once" ? "One Time" : recurringInterval}
      </Text>
      {recurringMax === null && recurringInterval !== "once" && (
        <OpenEndedInterval />
      )}
      {recurringInterval !== "once" && recurringMax !== null && (
        <>
          <Box>
            <Progress variant="determinate" value={progressValue} />
          </Box>
          <Text>
            {recurringCount} out of {recurringMax}
          </Text>
        </>
      )}
    </Stack>
  );
};


