import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import CustomPopover from "@common/Modal/Popover/CustomPopover";
import React from "react";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { APP_BAR_HEIGHT } from "@constants/constants";

export type NotificationsCenterWrapperProps = {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  children: React.ReactNode;
};

const NotificationsCenterWrapper = ({
  anchorEl,
  onClose,
  children,
}: NotificationsCenterWrapperProps) => {
  const { isMobileView } = useCustomTheme();

  if (isMobileView) {
    return (
      <Box
        sx={{
          display: anchorEl ? "block" : "none",
          width: "100vw",
          height: "100vh",
          zIndex: 1500,
          backgroundColor: palette.background.dimmedWhite,
          position: "fixed",
          overflow: "hidden",
          top: 0,
          left: 0,
        }}
      >
        {children}
      </Box>
    );
  }

  const windowHeight = window.innerHeight - APP_BAR_HEIGHT;
  return (
    <CustomPopover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PopoverProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
      PopoverSx={{
        width: "460px",
        minHeight: "452px",
        maxHeight: `${Math.min(windowHeight, 824)}px`,
        padding: "4px 0 0",
        overflow: "hidden",
      }}
    >
      {children}
    </CustomPopover>
  );
};

export default NotificationsCenterWrapper;
