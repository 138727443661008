import {
  MAXIMUM_REQUIRED_AGE,
  MINIMUM_REQUIRED_AGE,
} from "@constants/constants";
import { subYears } from "date-fns";
import { matchIsValidTel } from "mui-tel-input";
import * as Yup from "yup";

export const feeMustBeLessThanMax = (max: number, value?: string): boolean => {
  if (!value) return false;
  const numberInput = parseInt(value);
  if (Number.isNaN(numberInput)) return false;

  return numberInput <= max;
};

export const feeMustBeMoreThanMin = (min: number, value?: string): boolean => {
  if (!value) return false;
  const numberInput = parseInt(value);
  if (Number.isNaN(numberInput)) return false;

  return numberInput >= min;
};

export const getFeesValidationSchema = (min: number, max: number) =>
  Yup.object().shape({
    credit_card_fee: Yup.string().when({
      is: (exists: string) => Boolean(exists),
      then: (schema) =>
        schema
          .test({
            message: `Percent Fee cannot be greather than ${max}`,
            test: (value) => feeMustBeLessThanMax(max, value),
          })
          .test({
            message: `Percent Fee must be greater than ${min}`,
            test: (value) => feeMustBeMoreThanMin(min, value),
          }),
    }),
    amex_credit_card_fee: Yup.string().when({
      is: (exists: string) => Boolean(exists),
      then: (schema) =>
        schema
          .test({
            message: `Percent Fee cannot be greather than ${max}`,
            test: (value) => feeMustBeLessThanMax(max, value),
          })
          .test({
            message: `Percent Fee must be greater than ${min}`,
            test: (value) => feeMustBeMoreThanMin(min, value),
          }),
    }),
    debit_card_fee: Yup.string().when({
      is: (exists: string) => Boolean(exists),
      then: (schema) =>
        schema
          .test({
            message: `Percent Fee cannot be greather than ${max}`,
            test: (value) => feeMustBeLessThanMax(max, value),
          })
          .test({
            message: `Percent Fee must be greater than ${min}`,
            test: (value) => feeMustBeMoreThanMin(min, value),
          }),
    }),
  });

export const getAnnualRevenueSchema = () =>
  Yup.string().when({
    is: (exists: string) => !!exists,
    then: (schema) =>
      schema.test({
        message: "Must be greater than 1.00",
        test: (value) => {
          if (value === undefined) return false;
          const numberInput = parseFloat(value);
          return numberInput >= 1;
        },
      }),
  });

const urlValidationRegex =
  /^((https?|ftp):)?\/\/(([a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF%!$&'()*+,;=:]+)@)?(((\d{1,3}\.){3}\d{1,3})|\[(\d{1,3}\.){3}\d{1,3}\]|([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(-[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*\.)+[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,})(:\d{2,5})?(\/[a-z\d\-._~%!$&'()*+,;=:@\/]*)?(\?[a-z\d\-._~%!$&'()*+,;=:@\/?]*)?(#[a-z\d\-._~%!$&'()*+,;=:@\/?]*)?$/i; //eslint-disable-line

export const urlSchema = ({
  isRequired = false,
  requiredMessage = "Wesbite URL is required",
  validURLMessage = "Please enter a valid URL",
}: {
  isRequired?: boolean;
  requiredMessage?: string;
  validURLMessage?: string;
}) => {
  const baseSchema = Yup.string().when({
    is: (exists: string) => !!exists,
    then: (schema) =>
      schema.test("is-valid-url", validURLMessage, (value) =>
        urlValidationRegex.test("https://" + value),
      ),
  });
  return isRequired ? baseSchema.required(requiredMessage ?? "") : baseSchema;
};

export const addressBaseSchema = () =>
  Yup.object().shape({
    country: Yup.string()
      .required("Required")
      .matches(/^[A-Z].+$/, "Country is required"),
    line1: streetAddressValidator(true, "Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("Province is required"),
    zip: Yup.string()
      .matches(/^[0-9]{5}(?:-?[0-9]{4})?$/, "Invalid ZIP format")
      .required("Zip code is required"),
  });

export const nonResidentInputsSchema = {
  isNotUSResident: Yup.boolean(),
  citizenship: Yup.string().when("isNotUSResident", {
    is: true,
    then: Yup.string().required("Country of Citizenship is required"),
  }),
  isNotResidentInCitizenshipCountry: Yup.boolean(),
  countryOfResidence: Yup.string()
    .when("isNotResidentInCitizenshipCountry", {
      is: true,
      then: Yup.string().required("Country of Residence is required"),
    })
    .test(
      "is-same-country",
      "Please select a different country",
      (value, ctx) => {
        return value ? ctx.parent.citizenship != value : true;
      },
    ),
};

export const addressSchema = (conditionalFieldName: string) =>
  Yup.object()
    .shape({
      country: Yup.string(),
      line1: Yup.string(),
      city: Yup.string().max(
        20,
        "City can not contain more than 20 characters",
      ),
      state: Yup.string(),
      zip: Yup.string(),
    })
    .when(conditionalFieldName, {
      is: false,
      then: addressBaseSchema(),
    });

export const streetAddressValidator = (required?: boolean, msg?: string) => {
  const req = Yup.string().required(msg || "All address fields are required");
  return (required ? req : Yup.string()).test(
    "test-valid-address",
    "PO Boxes or PMBs are not allowed",
    (value) => {
      const regex =
        /((pob)|(p\.*o\.*\s*box)|(post\s*office\s*box)|p\.*o\.*b\.*|box|p\.*m\.*b\.*)\s*(#|;|:)*( d+|\d+)/gi;
      return !regex.test(value as string);
    },
  );
};

export const phoneSchema = () =>
  Yup.string().when({
    is: (exists: string) => !!exists && exists !== "+1",
    then: (schema) =>
      schema.test(
        "is-valid-number",
        "Please enter a valid phone number",
        function (value) {
          const phoneNumber = value as string;
          return matchIsValidTel(phoneNumber);
        },
      ),
  });

export const dateOfBirthSchema = () =>
  Yup.string()
    .typeError("Date of birth is required")
    .required("Date of birth is required")
    .when({
      is: (exists: string) => !!exists,
      then: () =>
        Yup.date()
          .typeError("please provide a valid date")
          .required("Date of Birth is required")
          .min(
            subYears(new Date(), MAXIMUM_REQUIRED_AGE),
            "Must be 100 or less",
          )
          .max(
            subYears(new Date(), MINIMUM_REQUIRED_AGE),
            `Must be 18 or older`,
          ),
    });

export const ssnSchema = (type: "ssn" | "ein") => {
  const message = type === "ssn" ? "SSN" : "Tax ID";
  return Yup.string()
    .required(`${message} is required`)
    .test("valid-ssn-ein", `Please enter a valid ${message}`, function (value) {
      if (!value) return false;
      return value.replace(/(\s|-)/g, "").length === 9;
    });
};

const publicBusinessTypes = ["tax_exempt_organization", "government_agency"];

export const refineLEOwnership = (
  businessType: string,
  ownershipType?: string,
) => {
  if (businessType === "individual_sole_proprietorship") return "private";
  if (publicBusinessTypes.includes(businessType)) return "public";

  return ownershipType || "public";
};

export const refineBusinessOwnershipInOnboarding = (
  businessType: string,
  ownershipType?: string,
) => {
  if (publicBusinessTypes.includes(businessType)) return "public";

  return ownershipType || "public";
};
