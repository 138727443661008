import { Text } from "@common/Text";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";

const ListEmptyState = ({ timeFrame }: { timeFrame: string }) => {
  const sizes = [80, 100, 130, 106, 100, 120];
  return (
    <>
      <Wrapper>
        {sizes.map((size, idx) => (
          <Element key={idx} width={`${size}px`} />
        ))}
      </Wrapper>
      <Text
        color={palette.gray[300]}
        fontWeight="book"
        lineHeight="16.8px"
        alignSelf="center"
      >
        No activity in the last {timeFrame}...
      </Text>
    </>
  );
};

const Element = styled(Box)(() => ({
  borderRadius: "2px",
  background: "#E2E2E2",
  height: "12.3px",
}));

const Wrapper = styled(Stack)(() => ({
  border: "2px solid #EDEDEC",
  borderRadius: "4px",
  opacity: 0.5,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
}));

export default ListEmptyState;
