import { Box } from "@mui/material";

const boxStyle = {
  width: "100%",
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  gap: "16px",
  borderImageSource:
    "linear-gradient(267.62deg, rgba(214, 217, 243, 0.46) 8.2%, rgba(255, 255, 255, 0.46) 82.68%)",
  "& th": {
    borderBottom: "none",
    padding: "8px 16px",
  },
  paddingTop: "24px",
  paddingBottom: "24px",
  paddingRight: "8px",
  paddingLeft: "8px",
};

const CampaignsKotoWrapper = ({ children }: any) => {
  return <Box sx={{ ...boxStyle }}>{children}</Box>;
};

export default CampaignsKotoWrapper;
