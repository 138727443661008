import React from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";

type ZipCodeInputProps = {
  isRequired?: boolean;
};

const ZipCodeInput = ({ isRequired }: ZipCodeInputProps) => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");

    return currentValue;
  };

  React.useEffect(() => {
    setValue("zipCode", normalizeInput(watch("zipCode")));
  }, [watch("zipCode")]);

  return (
    <RHFInput
      name="zipCode"
      label="Zip Code"
      fullWidth
      placeholder="•••••"
      sx={{
        letterSpacing: "6px",
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
      }}
      inputProps={{
        maxLength: "5",
      }}
    />
  );
};

export default ZipCodeInput;
