import Skeleton, { DoubledSkeleton } from "@components/animation/Skeleton";
import { Stack } from "@mui/material";

const AgreementSnapshotSkeleton = () => {
  return (
    <Stack spacing={1} data-testid="agreement-snapshop-skeleton">
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <Stack key={index} spacing={2}>
            <Skeleton height={25} width={150} />
            <DoubledSkeleton frequency={6} width="100%" />
          </Stack>
        ))}
    </Stack>
  );
};

export default AgreementSnapshotSkeleton;
