import { Stack } from "@mui/material";
import { SelectorOption } from "@common/TableFilters/utils";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Selector from "@common/TableFilters/Selector";
import React, { useState } from "react";
import SelectedTickIcon from "./SelectedTickIcon";
import FilterItem from "./FilterItem";
import useListCategories from "../hooks/useListCategories";
import { useFormContext, useWatch } from "react-hook-form";
import { FilterValuesType } from "../hooks/useChangelogFilters";
import ApplyButton from "./ApplyButton";

interface ICategoryFilterProps {
  merchantId: number;
}

const CategoryFilter = ({ merchantId }: ICategoryFilterProps) => {
  const { setValue } = useFormContext<FilterValuesType>();
  const savedValues: SelectorOption[] = useWatch({ name: "category" });
  const [values, setValues] = useState<SelectorOption[]>(savedValues);

  const { data, handleSearch } = useListCategories({
    merchantId,
  });

  const title =
    savedValues.length > 0 ? `Category (${savedValues.length})` : "Category";

  const handleChange = (val: any) => {
    const selectedValue = val as SelectorOption;
    const valueExists = values.find((curr) => curr.id === selectedValue.id);
    setValues(
      valueExists
        ? values.filter((curr) => curr.id !== selectedValue.id)
        : [...values, selectedValue],
    );
  };

  const handleReset = (event: React.MouseEvent<any, MouseEvent>) => {
    event.stopPropagation();
    setValues([]);
    setValue("category", []);
  };

  return (
    <Selector
      handleSearch={handleSearch}
      handleChange={handleChange}
      options={data}
      value={values}
      multiSelect
      renderCustomElement={({ selected, item }) => {
        return (
          <Stack direction="row" padding={1} gap={1}>
            <Text color={palette.black["100"]} fontWeight="book">
              {item.label}
            </Text>
            {selected && <SelectedTickIcon width={16} height={16} />}
          </Stack>
        );
      }}
      renderCustomParent={({ onClick }) => (
        <FilterItem
          isSelected={savedValues.length > 0}
          title={title}
          onClick={onClick}
          onCancel={handleReset}
        />
      )}
      ignoreMobileView
      renderFooter={({ onClose }) => (
        <ApplyButton onClose={onClose} filterType="category" values={values} />
      )}
    />
  );
};

export default CategoryFilter;
