import { TInviteElement, TInvitesMap } from "../types";
import { COLUMN_NAMES } from "../constants";
import { SortingOrder } from "@redux/types/sort";

export const getSelectedKeys = (map: TInvitesMap, mode: "single" | "bulk") => {
  const bulkKeys: string[] = [];
  for (const [key, value] of Array.from(map.entries())) {
    if (!value.checked) continue;

    if (mode === "single") {
      return key;
    } else {
      bulkKeys.push(key);
    }
  }
  return bulkKeys;
};

const compare = (a: string, b: string, order: SortingOrder) => {
  if (a > b) {
    return order === "asc" ? 1 : -1;
  } else if (a < b) {
    return order === "asc" ? -1 : 1;
  } else {
    return 0;
  }
};

type Element = [string, TInviteElement];

type InvitesSorter = (
  attribute: (typeof COLUMN_NAMES)[number],
  order: SortingOrder,
) => (a: Element, b: Element) => 1 | -1 | 0;

export const invitesSorter: InvitesSorter = (attribute, order) => (a, b) => {
  const isEmail = attribute === COLUMN_NAMES[0];
  const first = isEmail ? a[1].pahEmail : a[1].merchantName;
  const second = isEmail ? b[1].pahEmail : b[1].merchantName;
  return compare(first.toLowerCase(), second.toLowerCase(), order);
};
