import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type IconProps = SvgIconProps & {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
};

const AddCircleFilledIcon = ({
  width = 18,
  height = 18,
  fill = "none",
  path_fill = "#8F8F8F",
  ...rest
}: IconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      fontSize="inherit"
      htmlColor="transparent"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM9 14.6C8.66863 14.6 8.4 14.3314 8.4 14V9.6H4C3.66863 9.6 3.4 9.33137 3.4 9C3.4 8.66863 3.66863 8.4 4 8.4H8.4V4C8.4 3.66863 8.66863 3.4 9 3.4C9.33137 3.4 9.6 3.66863 9.6 4V8.4H14C14.3314 8.4 14.6 8.66863 14.6 9C14.6 9.33137 14.3314 9.6 14 9.6H9.6V14C9.6 14.3314 9.33137 14.6 9 14.6Z"
        fill="#8F8F8F"
      />
    </SvgIcon>
  );
};

export default AddCircleFilledIcon;
