import { Text } from "@common/Text";
import { CustomProgressbar } from "@components/CustomProgressbar";
import { AMOUNTS_REGION } from "@constants/constants";
import { Stack } from "@mui/material";
import { palette } from "@palette";

const LocationStat = ({
  total,
  percentage,
  name,
}: {
  total: number;
  percentage: number;
  name: string;
}) => {
  return (
    <Stack direction="column" gap="8px" alignItems="stretch">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Text color={palette.black[100]} fontWeight="book" lineHeight="16.8px">
          {name}
        </Text>
        <Text
          color={palette.gray[300]}
          fontWeight="book"
          fontSize="12px"
          lineHeight="14.4px"
        >
          {total.toLocaleString(AMOUNTS_REGION)}
        </Text>
      </Stack>
      <CustomProgressbar targetProgress={percentage} />
    </Stack>
  );
};

export default LocationStat;
