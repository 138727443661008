interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const ArrowsIcon = ({
  width = 18,
  height = 18,
  fill = "#A8A8A8",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.225 10.725L9 13.95L5.775 10.725C5.475 10.425 5.025 10.425 4.725 10.725C4.425 11.025 4.425 11.475 4.725 11.775L8.475 15.525C8.625 15.675 8.775 15.75 9 15.75C9.225 15.75 9.375 15.675 9.525 15.525L13.275 11.775C13.575 11.475 13.575 11.025 13.275 10.725C12.975 10.425 12.525 10.425 12.225 10.725ZM5.775 7.275L9 4.05L12.225 7.275C12.375 7.425 12.525 7.5 12.75 7.5C12.975 7.5 13.125 7.425 13.275 7.275C13.575 6.975 13.575 6.525 13.275 6.225L9.525 2.475C9.225 2.175 8.775 2.175 8.475 2.475L4.725 6.225C4.425 6.525 4.425 6.975 4.725 7.275C5.025 7.575 5.475 7.575 5.775 7.275Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowsIcon;
