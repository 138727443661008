export const namespaces = {
  pages: {
    hello: "pages.hello",
    manageMoney: "pages.manageMoney",
    customers: "pages.customers",
    events: "pages.events",
    fundraisers: "pages.fundraisers",
    paymentForms: "pages.paymentForms",
    analytics: "pages.analytics",
    settings: "pages.settings",
    sweepstakes: "pages.sweepstakes",
    fundraiserMinibuilder: "pages.fundraiserMinibuilder",
    paymentFormMinibuilder: "pages.paymentFormMinibuilder",
    eventsMinibuilder: "pages.eventsMinibuilder",
    membershipsMinibuilder: "pages.membershipsMinibuilder",
    invoiceMinibuilder: "pages.invoiceMinibuilder",
    sweepstakesMinibuilder: "pages.sweepstakesMinibuilder",
    enterprise: {
      createMerchant: "pages.enterprise.createMerchant",
      manageUnderwriting: "pages.enterprise.manageUnderwriting",
      processing: "pages.enterprise.processing",
    },
  },
  common: "common",
};

export const languages = {
  es: "es",
  en: "en",
};
