import React from "react";

import { CREATE_MEMBERSHIP_MODAL } from "modals/modal_names";
import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";

const NewMembershipsBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  return (
    <NewProductBanner
      title={"Memberships"}
      createModal={CREATE_MEMBERSHIP_MODAL}
      analyticsType="memberships"
      background={backgroundUrl}
      createCampaignTitle="Create a Membership"
    />
  );
};

export default NewMembershipsBanner;
