import { StyledIconButton } from "@common/IconButton";
import { TextField, Stack, styled } from "@mui/material";
import { palette } from "@palette";

const MobileInput = styled(TextField)(() => ({
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    padding: "4px 0",
    border: "none",
    backgroundColor: "inherit",
    flexGrow: 1,

    "& input": {
      marginTop: 0,
      color: palette.neutral.white,

      "&::placeholder": {
        color: palette.gray.main,
      },
    },
  },

  "& label[shrink=true]": {
    display: "none",
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  position: "fixed",
  top: 20,
  right: 0,
  zIndex: 1050,
  padding: "46px 24px 16px",
  flexDirection: "row",
  gap: "24px",
  alignItems: "center",
  borderRadius: "0px 0px 0px 24px",
  backgroundColor: "rgba(236, 236, 233, 0.90)",

  "& .MuiTextField-root": {
    width: "270px",
    "& .MuiInputAdornment-positionEnd": {
      width: "35px",
    },
  },

  [theme.breakpoints.down("sm")]: {
    left: 0,
    top: 48,
    padding: "14px 16px",
    gap: "8px",
    justifyContent: "space-between",
    backgroundColor: "rgba(41, 39, 39, 0.80)",
    backdropFilter: "blur(2px)",
  },
}));

const SearchUtilButton = styled(StyledIconButton, {
  shouldForwardProp: (prop) => prop !== "hoverColor",
})<{ hoverColor: string }>(({ hoverColor }) => ({
  width: "24px !important",
  height: "24px !important",
  padding: "0 !important",
  "&:hover path": {
    fill: hoverColor,
  },
}));

const TextSpan = styled("span", {
  shouldForwardProp: (prop) => prop !== "isCurrent",
})<{ isCurrent?: boolean }>(({ theme, isCurrent = false }) => ({
  minWidth: "24px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16.8px",
  textAlign: "right",
  color: isCurrent ? palette.black[100] : palette.gray[100],
  [theme.breakpoints.down("sm")]: {
    color: isCurrent ? palette.neutral.white : palette.gray[100],
  },
}));

const HighlightTracker = ({
  visible,
  current,
  total,
}: {
  visible: boolean;
  current: number;
  total: number;
}) => {
  if (!visible) return <></>;
  return (
    <>
      <TextSpan isCurrent data-testid="highlight-counter">{current}</TextSpan>
      <TextSpan data-testid="highlight-total">/{total}</TextSpan>
    </>
  );
};

export { MobileInput, Container, SearchUtilButton, HighlightTracker };
