import React from "react";
//mui
import { Box, styled } from "@mui/material";
//components
import LoginContainer from "@pages/Login/LoginContainer";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { RHFInput } from "@common/Input";
//form
import { FormProvider } from "react-hook-form";
import { Button } from "@common/Button";
import { isEqual } from "lodash";
import { useForgotPassword } from "@hooks/auth-api";
import { ArrowBackIcon, CheckboxMark } from "@assets/icons";
import { AUTH_PATHS } from "@routes/paths";
import { LoginCustomLink } from "@components/Login";

const ForgotPassword = () => {
  const {
    methods,
    onSubmit,
    exist,
    error,
    previousEmail,
    isLoading,
    isEmailValid,
  } = useForgotPassword();

  const { watch } = methods;
  const disabled =
    isLoading ||
    !watch("email") ||
    isEqual(previousEmail, watch("email")) ||
    !isEmailValid(watch("email"));

  return (
    <LoginContainer hideFooterLogo>
      <Box>
        <Text
          variant="h5"
          fontWeight="light"
          lineHeight={"46.3px"}
          fontSize={32}
          color={palette.neutral[80]}
        >
          Forgot Password?
        </Text>
        <Text
          variant="body"
          fontWeight="light"
          fontSize={18}
          lineHeight={"21px"}
          color={palette.neutral[80]}
        >
          Enter your email address below and we&apos;ll send you a password
          reset link.
        </Text>
      </Box>
      {exist && (
        <SuccessBox>
          <CheckboxMark
            fill={palette.filled.green}
            stroke={palette.success.light}
          />
          <Text color={palette.success.main}>{`Password reset link sent`}</Text>
        </SuccessBox>
      )}
      <FormProvider {...methods}>
        <StyledForm component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <RHFInput
            name="email"
            fullWidth
            type="text"
            placeholder="Enter your email address"
            label="Email"
            error={error}
            helperText={
              error
                ? "The email you entered isn’t connected to an account."
                : null
            }
          />

          <StyledRowBox>
            <LoginCustomLink
              underlineColor={palette.neutral[70]}
              to={`/${AUTH_PATHS.LOGIN}`}
              data-testid="forgot-password-go-back-link"
            >
              <StyledRowBox>
                <ArrowBackIcon />
                <Text color={palette.neutral[70]} fontSize={16}>
                  Go back
                </Text>
              </StyledRowBox>
            </LoginCustomLink>
            <Button variant="secondary" disabled={disabled} type="submit">
              Reset Password
            </Button>
          </StyledRowBox>
        </StyledForm>
      </FormProvider>
    </LoginContainer>
  );
};

const StyledForm = styled(Box)(() => ({
  gap: "24px",
  display: "flex",
  flexDirection: "column",
}));

const StyledRowBox = styled(Box)(() => ({
  gap: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const SuccessBox = styled(Box)({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  padding: "10px 16px",
  borderRadius: "8px",
  backgroundColor: palette.success.light,
});

export default ForgotPassword;
