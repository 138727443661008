import * as React from "react";
import { palette } from "@palette";
import { TransactionData } from "./data";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
// components
import { Text } from "@common/Text";
import { KeyVal } from "@common/KeyVal";
import SubExpandedRow from "./SubExpandedRow";
// assets
import { CreditCardIcon, ServerIcon } from "@assets/icons";
import { Divider } from "@mui/material";

type ExpandedRowProps = {
  rowData: TransactionData;
};


const backgroundGradient = {
  position: "absolute",
  background:
    "linear-gradient(104.33deg, rgba(129, 68, 154, 0) 25.61%, #81449A 54.5%, #E6B96F 82.41%)",
  borderRadius: "12px",
  top: "30px",
  height: "calc( 100% - 37px )",
  width: "calc(100% + -47px)",
};

const mainContent = {
  gap: "16px",
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  backgroundColor: "rgba(246, 250, 255, 0.8)",
  position: "relative",
  background: "rgba(246, 250, 255, 0.9)",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(9px)",
  borderRadius: "0px 0px 8px 8px"
};


export const ExpandedRow: React.FC<ExpandedRowProps> = ({ rowData }) => {
  const theme = useTheme();
  const style = {
    "& sup": {
      fontSize: 9,
      lineHeight: "9px",
      marginLeft: "2px",
    },
  };
  return (
    <Box 
      p={theme.spacing(3, 1.5, 2)}
      position="relative"
    >
      <Box sx={backgroundGradient} />
      <Box sx={mainContent}>
      <Box display="flex" gap={3} sx={style}>
        <Box gap={1.5} display="flex" flexDirection="column">
          <Stack gap={0.5} direction="row" alignItems="center">
            <ServerIcon fill="transparent" />
            <Text
              color={palette.primary.main}
              variant="headline"
              fontWeight="medium"
            >
              Payment Details
            </Text>
          </Stack>
          {/** ============== GRID [xs={6} 2 items per row (12 column grid) | column & row spacing {2} ] ============= */}
          <Grid container spacing={2}>
          {/* Organization details  */}
          {/** ================= Organization ================= */}
          <Grid item xs={6}>
              <KeyVal
                textVariant
                keyName="Organization"
                value={rowData.OrganizationName}
              />
            </Grid>
            {/** ================= OrganizationID ================= */}
            <Grid item xs={6}>
              <KeyVal textVariant keyName="OrganizationID" value={rowData.OrganizationID} />
            </Grid>
            {/* dashed divider  */}
            <Grid item xs={12}>
              <Divider  sx={{borderStyle:'dashed'}} />
            </Grid>
            {/** ================= NAME ON CARD ================= */}
            <Grid item xs={6}>
              <KeyVal
                textVariant={true}
                keyName="Name on Card"
                value={rowData.paymentDetails.nameOnCard}
              />
            </Grid>
            {/** ================= EMAIL ================= */}
            <Grid item xs={6}>
              <KeyVal textVariant keyName="Email" value={rowData.email} />
            </Grid>
            {/** ================= MERCH ORDER ================= */}
            <Grid item xs={6}>
              <KeyVal
                textVariant
                keyName="Transaction ID"
                value={rowData.paymentDetails.merchantTaxId}
              />
            </Grid>
           
           
            {/** ================= STATUS ================= */}
            <Grid item xs={6}>
              <KeyVal
                keyName="Status"
                value={
                  <Stack gap={4} direction="row" alignItems="center">
                    <Text>
                      {rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)}
                    </Text>
                    <Text>xxxx xxxx xxxx 1111</Text>
                    <Text color={palette.primary.main}>Visa</Text>
                  </Stack>
                }
              />
            </Grid>
            {/** ================= REVENUE SOURCE ================= */}
            <Grid item xs={6}>
              <KeyVal
                textVariant={true}
                keyName="Revenue Source"
                value={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text>{rowData.source}</Text>
                    <Text color={palette.primary.main}>
                      {rowData.paymentDetails.revenueSource}
                    </Text>
                  </Stack>
                }
              />
            </Grid>
            {/** ================= GRID [ xs={3} 4 items per row, Grid item and also container ] ================= */}
            <Grid item xs={6} container columnSpacing={1}>
              {/** ================= TOTAL ================= */}
              <Grid item xs={3}>
                <KeyVal
                  textVariant
                  keyName="Total"
                  value={
                    <Text>
                      {rowData.paymentDetails.donation} <sup>USD</sup>
                    </Text>
                  }
                />
              </Grid>
              {/** ================= GIVEBOX FEE ================= */}
              <Grid item xs={3}>
                <KeyVal
                  textVariant
                  keyName="Givebox Fee"
                  value={
                    <Text>
                      {rowData.paymentDetails.giveboxFee} <sup>USD</sup>
                    </Text>
                  }
                />
              </Grid>
              {/** =================  VISA FEE ================= */}
              <Grid item xs={3}>
                <KeyVal
                  textVariant
                  keyName="Visa Fee"
                  value={
                    <Text>
                      *{rowData.paymentDetails.visaFee} <sup>USD</sup>
                    </Text>
                  }
                />
              </Grid>
              {/** ================= CHARGED ================= */}
              <Grid item xs={3}>
                <KeyVal
                  textVariant
                  keyName="Charged"
                  value={
                    <Text>
                      {rowData.paymentDetails.charged} <sup>USD</sup>
                    </Text>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Text
                  color={palette.neutral[600]}
                  fontWeight="regular"
                  variant="caption"
                  sx={{ marginTop: "4px" }}
                >*Visa fee deducted from Donation</Text>
              </Grid>
            </Grid>
          </Grid>
          {/** ------------------------ TRANSACTION STATISTICS ------------------------ */}
          <Stack gap={0.5} direction="row">
            <CreditCardIcon fill="transparent" />
            <Text
              color={palette.primary.main}
              variant="headline"
              fontWeight="medium"
            >
              Purchases
            </Text>
          </Stack>
        </Box>
      </Box>
      {/** ------------------------ RECURRING PURCHASES ------------------------ */}
      {rowData.recurringPurchases && (
        <Box>
          {rowData.recurringPurchases.map((data) => (
            <SubExpandedRow data={data} key={data.id} />
          ))}
        </Box>
      )}
      </Box>
    </Box>
  );
};
