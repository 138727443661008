export const AND_OPERATOR = ";";
export const OR_OPERATOR = ",";

export const resources = {
  products: new Set(["created"]),
  transactions: new Set([
    "created",
    "amount",
    "conversion",
    "purchases",
    "visitors",
    "entries sold",
    "status",
    "transactions",
    "blocked",
    "quarantine",
  ]),
  customers: new Set(["joined", "recurrence"]),
};

export const filteringConst = {
  created: {
    key: "createdAt",
    "in the last": ">=d{$1}",
    "is between": `>=d{$1}${AND_OPERATOR}<=d{$2}`,
    "is on or before": "<=d{$1}",
    "is on or after": ">=d{$1}",
    operator: AND_OPERATOR,
  },
  amount: {
    key: "amount",
    "is between": ">{$1};<{$2}",
    "is equal to or greater than": ">{$1}",
    "is equal to or less than": "<{$1}",
    operator: AND_OPERATOR,
  },
  "entries sold": {
    key: "entries_sold", //TODO: check the name of the attribute in DB
    "is between": `>{$1}${AND_OPERATOR}<{$2}`,
    "is equal to or greater than": ">={$1}",
    "is equal to or less than": "<={$1}",
  },
  conversion: {
    key: "conversion",
    template: `>={$1}${AND_OPERATOR}<={$2}`,
    operator: AND_OPERATOR,
  },
  purchases: {
    key: "purchases",
    "is between": `>{$1}${AND_OPERATOR}<{$2}`,
    "is equal to or greater than": ">={$1}",
    "is equal to or less than": "<={$1}",
    operator: AND_OPERATOR,
  },
  visitors: {
    key: "visitors",
    "is between": `>{$1}${AND_OPERATOR}<{$2}`,
    "is equal to or greater than": ">={$1}",
    "is equal to or less than": "<={$1}",
    operator: AND_OPERATOR,
  },
  transactions: {
    key: "transactions",
    "is between": `>{$1}${AND_OPERATOR}<{$2}`,
    "is equal to or greater than": ">={$1}",
    "is equal to or less than": "<={$1}",
    operator: AND_OPERATOR,
  },
  type: {
    key: "typeID",
    default: [],
    operator: OR_OPERATOR,
  },
  status: {
    key: "statusID",
    default: [],
    operator: OR_OPERATOR,
  },
  recurrence: {
    key: "recurringCustomer",
    default: "{$1}",
    operator: AND_OPERATOR,
  },
  joined: {
    key: "createdAt",
    "in the last": ">=d{$1}",
    "is between": `>=d{$1}${AND_OPERATOR}<=d{$2}`,
    "is on or before": "<=d{$1}",
    "is on or after": ">=d{$1}",
    operator: AND_OPERATOR,
  },
  blocked: {
    key: "isBlocked",
    default: "{$1}",
    operator: AND_OPERATOR,
  },
  quarantine: {
    key: "isQuarantined",
    default: "{$1}",
    operator: AND_OPERATOR,
  },
  totalProcessed: {
    key: "totalProcessed",
    "is between": ">{$1};<{$2}",
    "greater than": ">{$1}",
    "is equal to or greater than": ">={$1}",
    "is equal to or less than": "<={$1}",
    operator: AND_OPERATOR,
  },
} as const;
