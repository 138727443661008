type IconProps = {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
};

const SubtractMinusIcon = ({
  height = 4,
  width = 27,
  fill = "none",
  stroke = "#9C9AA3",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53135 11.75H6.03115C5.69978 11.75 5.43115 12.0186 5.43115 12.35C5.43115 12.6814 5.69978 12.95 6.03115 12.95H7.53135V11.75ZM8.73135 12.95H18.6312C18.9625 12.95 19.2312 12.6814 19.2312 12.35C19.2312 12.0186 18.9625 11.75 18.6312 11.75H8.73135V12.95Z"
        fill={stroke}
      />
    </svg>
  );
};

export default SubtractMinusIcon;
