import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import {
  GroupTab,
  Overlay,
  PermissionListWrapper,
} from "./PermissionsList.atoms";
import { Stack } from "@mui/material";
import NoResultsState from "@common/EmptyState/NoResultsState";
import React, { useRef } from "react";
import { TPermissionGroups, TPermissionsData } from "../../types";
import { SectionSkeleton } from "./PermissionsPanelSkeleton";

type TData = {
  data: TPermissionsData;
  groups: TPermissionGroups;
};

type TSetPermissionStatus = (
  key: string,
  value: TPermissionsData[string],
) => void;

type TDeleteHandler = (permissionKey: string, permissionName: string) => void;

interface Props {
  data: TData;
  setPermissionStatus: TSetPermissionStatus;
  handleDelete: TDeleteHandler;
  searchQuery: string;
  isLoading: boolean;
  sectionsRef: React.RefObject<(HTMLParagraphElement | null)[]>;
}

const PermissionsList = ({
  data,
  isLoading,
  setPermissionStatus,
  handleDelete,
  searchQuery,
  sectionsRef,
}: Props) => {
  const keys = Object.keys(data.groups).sort((a, b) => a.localeCompare(b));
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    if (!listWrapperRef.current || !overlayRef.current) return;
    const isVisible = listWrapperRef.current.scrollTop > 40;
    if (isVisible) {
      overlayRef.current.style.visibility = "visible";
    } else {
      overlayRef.current.style.visibility = "hidden";
    }
  };

  return (
    <Stack
      position="relative"
      flexGrow={1}
      overflow="hidden"
      direction="column"
    >
      <Overlay ref={overlayRef} />
      <PermissionListWrapper ref={listWrapperRef} onScroll={handleScroll}>
        {isLoading ? (
          Array.from({ length: 3 }, (_, i) => <SectionSkeleton key={i} />)
        ) : !keys.length ? (
          <NoResultsState
            searchQuery={searchQuery}
            sx={{ marginBlock: "auto" }}
          />
        ) : (
          keys.map((x, index) => (
            <FadeUpWrapper key={x} delay={350 + index * 50}>
              <GroupTab
                ref={(el) => {
                  if (!sectionsRef.current) return;
                  sectionsRef.current[index] = el;
                }}
                groupName={x}
                uniqueGroups={data.groups}
                hashes={data.data}
                onDelete={handleDelete}
                onClick={setPermissionStatus}
              />
            </FadeUpWrapper>
          ))
        )}
      </PermissionListWrapper>
    </Stack>
  );
};

export default React.memo(PermissionsList);
