import { Components } from "@mui/material";

export const dialog: Components["MuiDialog"] = {
  styleOverrides: {
    root: {
      "*": {
        "&::-webkit-scrollbar": {
          width: 8,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "inherit",
        },
        "&::-webkit-scrollbar-thumb": {
          border: "2px solid #F9FCFF",
          backgroundColor: "#DDDCE5",
        },
      },
      "& .MuiDialog-container": {
        alignItems: "flex-start",
      },
      "& .MuiDialog-paper": {
        margin: 0,
        top: "50px",
        maxHeight: "90%",
        borderRadius: "8px",
        background: "#FFFFFF",
        boxShadow:
          "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",

        "@media (max-width: 600px)": {
          top: 0,
          height: "100%",
          borderRadius: 0,
          maxHeight: "100%",
        },
      },
      "& .MuiDialogContent-root": {
        padding: "24px 16px",
        // borderBottom: "1px solid #DDDCE5",
      },
      "& .MuiDialogTitle-root + .MuiDialogContent-root": {
        paddingTop: "24px !important",
      },
      "& .MuiDialogActions-root": {
        backgroundColor: "#FFFFFF",
        gap: "16px",

        "@media (max-width: 600px)": {
          flexDirection: "column-reverse",
          justifyContent: "center",

          "& > :not(:first-of-type)": {
            marginLeft: 0,
          },

          "& .MuiButton-root": {
            width: "100%",
          },
        },
      },
    },
  },
};
