import { customInstance } from "@services/api";
import axios from "axios";
import { debounce, isEmpty } from "lodash";
import { useState } from "react";

export type TFileAttachmentType =
  | "bank_account"
  | "legal_principal"
  | "account_member"
  | "underwriting_profile"
  | "account_owner"
  | "account_owner_selfie"
  | "conversation_message";

export type UploadProgressParams = {
  fileId: string;
  progress: number;
  identifier?: number | string; // can be id or url
};

export type TUploadFilePayload = {
  attachmentType?: TFileAttachmentType;
  label?: string;
  tag?: string;
  list: { file: File; id: string }[];
  merchantId: number;
  resourceID: number;
  isCustomerUpload?: boolean;
  isSignatureUpload?: boolean;
  onSuccess?: () => void;
  clearSnackbarFiles?: () => void;
  onUploadProgress(params: UploadProgressParams): void;
  onUploadFinish(params: { identifiers: (number | string)[] }): void;
  onUploadFailed(params: { fileIds: string[] }): void;
};

export type TUploadedFile = { id: number; isUploaded: true };

export type HandleUploadReturnType = "upload_failed" | TUploadedFile[];

export const useUploadFiles = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (
    payload: TUploadFilePayload,
    challengeSlugParam?: string,
  ): Promise<HandleUploadReturnType> => {
    if (isEmpty(payload.list)) return [];
    setIsLoading(true);

    const customDocumentList = payload.list.map((item) => {
      return {
        attachmentType: payload.attachmentType,
        fileName: item.file.name,
        label: payload.label,
        tag: payload.tag,
        resourceID: payload.resourceID,
      };
    });
    try {
      const res = await customInstance({
        url: `accounts/${payload.merchantId}/files`,
        method: "POST",
        data: { list: customDocumentList },
        params: { challenge_slug: challengeSlugParam },
      });

      if (!res?.total || res?.total < 1) return "upload_failed";
      for (const [index, document] of res.data.entries()) {
        await axios.put(document.fileURL, payload.list[index].file, {
          onUploadProgress: debounce((progressEvent: ProgressEvent) => {
            payload.onUploadProgress({
              fileId: payload.list[index].id,
              progress: (progressEvent.loaded * 100) / progressEvent.total,
              identifier: document.id,
            });
          }, 100) as any,
        });
      }

      const confirmedUploadedList: TUploadedFile[] = res.data.map(
        (item: any): TUploadedFile => ({
          id: item.id,
          isUploaded: true,
        }),
      );
      await customInstance({
        url: `accounts/${payload.merchantId}/files/confirm`,
        method: "POST",
        data: { list: confirmedUploadedList },
      });

      payload.onUploadFinish({
        identifiers: confirmedUploadedList.map((item: any) => item.id),
      });

      if (payload.onSuccess) {
        payload.onSuccess();
      }

      setIsLoading(false);
      return confirmedUploadedList;
    } catch (e) {
      payload.onUploadFailed({
        fileIds: payload.list.map((item) => item.id),
      });
      setIsLoading(false);
      return "upload_failed";
    }
  };

  return { handleUpload, isLoading };
};
