import { TColumnNames, TColumnTranslations } from "../types";

type TSectionNames =
  | "merchantInfo"
  | "riskMonitor"
  | "primaryAccountHolder"
  | "businessOwner"
  | "businessProfile"
  | "salesInfo";
// | "riskTriggers";

export const MERCHANT_COLUMNS: TColumnNames<TSectionNames> = {
  titles: {
    merchantInfo: "Merchant Info",
    salesInfo: "Sales Info",
    // riskTriggers: "Risk Triggers",
    riskMonitor: "Risk Monitor",
    primaryAccountHolder: "Primary Account Holder",
    businessOwner: "Business Owner",
    businessProfile: "Business Profile",
  },
  visible: {
    portfolio: [
      "Merchant Name",
      "Signup Date",
      "Risk Category",
      "Lifetime Volume",
      "Status",
    ],
    underwriting: [
      "Merchant Name",
      "Signup Date",
      "Risk Category",
      "Assignee",
      "Underwriting Score",
      "Status",
    ],
    risk: [
      "Merchant Name",
      "Assignee",
      "Risk Category",
      "Last Activity Date",
      "Last Escalation Date",
      "Risk Level",
    ],
    default: [],
  },
  monthly: [
    "Merchant ID",
    "Merchant Name",
    "Legal Name",
    "Sponsor Status",
    "MCC",
    "Sponsor Approval Date",
    "Sales Volume",
    "# Sales",
    "Avg Sales",
    "Refunds Volume",
    "# Refunds",
    "Refunds Amount %",
    "# Refunds %",
    "Chargebacks Volume",
    "# Chargebacks",
    "Chargebacks Amount %",
    "# Chargebacks %",
    "Industry",
  ],
  merchantInfo: [
    "Internal ID",
    "Merchant ID",
    "Merchant Onboarded Date",
    "Merchant Name",
    "Parent Provider Name",
    "Sponsor Status",
    "Business Classification",
    "Status",
    "MCC",
    "Sponsor Approval Date",
    "Signup Date",
    "Signup Type",
    "Assignee",
    "Website URL",
    "Contact Phone",
    "Billing Descriptor",
    "Average Ticket Amount",
    "High Ticket Amount",
    "Estimated Annual Revenue",
    "Countries Serviced Outside USA/Canada",
    "Lifetime Volume",
    "Can Transfer Money",
    "Can Process Money",
    "Underwriting Score",
  ],
  salesInfo: [
    "Sales Volume",
    "# Sales",
    "Avg Sales",
    "Refunds Volume",
    "# Refunds",
    "Refunds Amount %",
    "# Refunds %",
    "Chargebacks Volume",
    "# Chargebacks",
    "Chargebacks Amount %",
    "# Chargebacks %",
    "Industry",
  ],
  // riskTriggers: [
  //   "Change Type",
  //   "Field Changed",
  //   "Previous Value",
  //   "New Value",
  //   "Modified At",
  //   "Reviewed By",
  //   "Reviewer Comments",
  // ],
  riskMonitor: [
    "Risk Level",
    "Risk Points",
    "Risk Category",
    "Last Activity Date",
    "Last Escalation Date",
  ],
  primaryAccountHolder: [
    "Primary Account Holder First Name",
    "Primary Account Holder Last Name",
    "Primary Account Holder Email",
    "Primary Account Holder Contact Phone",
    "Primary Account Holder Date of Birth",
  ],
  businessOwner: [
    "Business Owner First Name",
    "Business Owner Last Name",
    "Business Owner Email",
    "Business Owner Contact Phone",
    "Business Owner Tax ID",
    "Business Owner Date of Birth",
    "Business Owner Street Address",
    "Business Owner City",
    "Business Owner State",
    "Business Owner Zip",
    "Business Owner Country",
    "Business Owner Ownership %",
  ],
  businessProfile: [
    "Legal Name",
    "Business Tax ID",
    "DBA",
    "Type of Business",
    "Ownership Type",
    "Age of Business",
    "Business Street Address",
    "Business City",
    "Business State",
    "Business Country",
    "Business Zip",
  ],
};

export const MERCHANT_COLUMN_TRANSLATION: TColumnTranslations = {
  "Merchant Onboarded Date": "Merchant Onboarded Date",
  "Internal ID": "Internal ID",
  "Merchant ID": "Merchant ID",
  MCC: "MCC",
  "Legal Name": "Legal Name",
  DBA: "Doing Business As (DBA)",
  "Tax ID": "Tax ID",
  "Type of Business": "Type of Business",
  "Ownership Type": "Ownership Type",
  "Age of Business": "Age Of Business",
  "Business Street Address": "Street Address",
  "Business City": "City",
  "Business State": "State",
  "Business Zip": "ZIP",
  "Business Country": "Country",
  "Website URL": "Website URL",
  "Contact Phone": "Contact Phone",
  "Billing Descriptor": "Billing Descriptor",
  "Average Ticket Amount": "Average Ticket Amount",
  "High Ticket Amount": "High Ticket Amount",
  "Estimated Annual Revenue": "Estimated Annual Revenue",
  "Countries Serviced Outside USA/Canada":
    "Countries Serviced Outside USA and Canada",
  "Primary Account Holder First Name": "First Name",
  "Primary Account Holder Last Name": "Last Name",
  "Primary Account Holder Email": "Email",
  "Primary Account Holder Contact Phone": "Contact Phone",
  "Primary Account Holder Date of Birth": "Date of Birth",
  "Primary Account Holder Ownership %": "Primary Account Holder Ownership",
  "Business Owner First Name": "First Name",
  "Business Owner Last Name": "Last Name",
  "Business Owner Email": "Email",
  "Business Owner Contact Phone": "Contact Phone",
  "Business Owner Tax ID": "Tax ID",
  "Business Owner Date of Birth": "Date of Birth",
  "Business Owner Street Address": "Street Address",
  "Business Owner City": "City",
  "Business Owner State": "State",
  "Business Owner Zip": "ZIP",
  "Business Owner Country": "Country",
  "Business Owner Ownership %": "Ownership (%)",
  Status: "Status",
  "Underwriting Score": "Underwriting Score",
  "Can Transfer Money": "Can Transfer Money",
  "Can Process Money": "Can Process Money",
  "Parent Provider Name": "Parent Provider Name",
  "# Sales": "#Sales",
  "Avg Sales": "Average Sale",
  "# Refunds": "#Refunds",
  "Refunds Amount %": "Refunds Amount%",
  "# Refunds %": "Refunds %",
  "Chargebacks Volume": "Chargeback Volume",
  "# Chargebacks": "#Chargbacks",
  "Chargebacks Amount %": "#Chargeback Amount",
  "# Chargebacks %": "Chargback %",
  "Refunds Volume": "Refund Volume",
  "Business Tax ID": "Tax ID",
};
