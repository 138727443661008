import { useQueryClient, useMutation } from "react-query";
import { AxiosError } from "axios";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { useGetCurrentMerchantId } from "@hooks/common";

type useDeleteCustomerProps = {
  id?: number;
  name: string;
  deleteAction: () => void;
}

export const useDeleteCustomer = ({ id, name, deleteAction }: useDeleteCustomerProps) => {
  const { merchantId } = useGetCurrentMerchantId();
  const queryclient = useQueryClient();

  const deleteCustomerMutation = useMutation(() => {
    return customInstance({
      url: `/merchants/${merchantId}/customers/${id}`,
      method: "DELETE",
    });
  })

  const { isLoading, isSuccess } = deleteCustomerMutation;

  const deleteHandler = async () => {
    if (!id || !merchantId) return;
    
    deleteCustomerMutation.mutate(undefined, {
        onError: (error: unknown) => {
          const axiosError = error as AxiosError;
          const errorMessage = axiosError.response;
          console.log("errorMessage = ", errorMessage);
        },
        onSuccess: async (res: any) => {
          showMessage(
            "Success",
            <>
              Customer <span style={{ fontWeight: 600 }}>“{name}”</span> has been
              deleted successfully
            </>,
          );
          deleteAction();
          queryclient.refetchQueries("list-all-customers");
        },
      },
    );
  };

  return { deleteHandler, isDisabled: isLoading || isSuccess };
}
