import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { UnderwritingPopupBase } from "./ApproveMerchantPopup";

type SuspendMerchantProps = {
  handleSuspend: () => void;
};

const SuspendMerchantModal = NiceModal.create(
  ({ handleSuspend }: SuspendMerchantProps) => {
    return (
      <UnderwritingPopupBase
        modalTitle="Suspend account?"
        submitLabel="Temporarily Suspend"
        handleSubmit={handleSuspend}
        primaryButtonBackground={palette.filled.red}
      >
        <Text fontSize={14} color={palette.neutral[80]} mt={2}>
          Suspending an account will block its{" "}
          <span style={{ fontWeight: "700" }}>processing</span> and ability to{" "}
          <span style={{ fontWeight: "700" }}>send money.</span>
        </Text>
      </UnderwritingPopupBase>
    );
  },
);

export default SuspendMerchantModal;
