import { showMessage } from "@common/Toast";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { customInstance } from "@services/api";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

const getOfacCategories = () => {
  return customInstance({
    url: "/changelog-categories",
    method: "GET",
  });
};

export const useOFACCategories = () => {
  const { data, error, isLoading } = useQuery(
    ["list-ofac-categories"],
    async () => {
      const data = await getOfacCategories();
      return data;
    },
  );

  return { error, isLoading, data };
};

const getMerchantOFAC = (
  merchantId: number,
  type: string,
  legalEntityId?: number,
) => {
  // We will pass legalEntityId if new ofac implementation is not disabled
  const url = legalEntityId
    ? `/merchants/${merchantId}/legal-entities/${legalEntityId}/ofac-checks?sort=-createdAt`
    : `/merchants/${merchantId}/ofac-checks?filter=resourceTypeName:"${type}"&sort=-createdAt`;
  return customInstance({
    url: url,
    method: "GET",
  });
};

export const useOFACChecks = (
  merchantId: number,
  type: string,
  legalEntityId?: number,
  queryOptions?: Omit<
    UseQueryOptions<any, unknown, any, (string | number)[]>,
    "queryKey" | "queryFn"
  >,
) => {
  const { data, error, isLoading } = useQuery(
    ["list-ofac-checks", merchantId, type, legalEntityId || ""],
    async () => {
      const ofac = await getMerchantOFAC(merchantId, type, legalEntityId);
      return ofac?.data || [];
    },
    { enabled: Boolean(merchantId && legalEntityId), ...queryOptions },
  );

  return { error, isLoading, data: data as OFACCheckType[] };
};

export const useRunOFAC = (
  merchantId: number,
  isEnterprise: boolean,
  legalEntityId?: number,
) => {
  const { data: categories } = useOFACCategories();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const hasNotOFACPermission = useAppSelector(
    (state) => state.app.permissions?.["ofac_check"],
  );

  const composed = isNewOfacTabsEnabled
    ? composePermission(
        isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.OFAC,
      )
    : composePermission(
        isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.OFAC,
      );
  const isListOfacAllowed = useAccessControl({
    resource: composed,
    operation: OPERATIONS.LIST,
  });
  const runOFACMutation = useMutation(
    ({
      resourceTypeName,
      resourceID,
    }: {
      resourceTypeName: string;
      resourceID: number;
    }) => {
      return customInstance({
        // We will pass legalEntityId if new ofac implementation is not disabled

        url: legalEntityId
          ? `/merchants/${merchantId}/legal-entities/${legalEntityId}/ofac-checks`
          : `/merchants/${merchantId}/resources/${resourceTypeName}/ofac-checks/${resourceID}`,
        method: "POST",
        data: {},
      });
    },
  );

  const runOFACHandler = async (activeTab: string, resourceID: number) => {
    const category = categories?.data?.find(
      (category: any) => category.DisplayName === activeTab,
    );

    runOFACMutation.mutate(
      {
        resourceTypeName: category?.id,
        resourceID: resourceID,
      },
      {
        onError: (error: any) => {
          showMessage(
            "Error",
            error?.message || error?.response?.status,
            true,
            "Something went wrong...",
          );
          if (error.not_authorized) {
            dispatch(
              updatePermissions({
                ofac_check: true,
              }),
            );
          }
        },
        onSettled: () => {
          if (isListOfacAllowed) {
            queryClient.invalidateQueries("list-ofac-checks");
          }
          queryClient.invalidateQueries("get-merchant-preview");
        },
      },
    );
  };

  return {
    runOFACHandler,
    isLoading: runOFACMutation.isLoading,
    hasNotOFACPermission,
  };
};

export type OFACCheckType = {
  ID: number;
  legalEntityID: number;
  statusName: OFACCheckStatusType;
  matchList: any[];
  possibleMatchAt: number | null;
  createdAt: number;
  updatedAt: number;
  resourceID: number;
  resourceFullName?: string;
  resourceCitizenship?: string;
  resourceCountryOfResidence?: string;
  principalOwnershipPercentage?: number;
};

export type OFACCheckStatusType =
  | "clear"
  | "manually_cleared"
  | "possible_match"
  | "confirmed_match";

const getOfacCheckById = (
  merchantId: number,
  checkId: number,
  legalEntityId?: number,
) =>
  customInstance({
    url: legalEntityId
      ? `/merchants/${merchantId}/legal-entities/${legalEntityId}/ofac-checks/${checkId}`
      : `/merchants/${merchantId}/ofac-checks/${checkId}`,
    method: "GET",
  });

export const useGetOfacCheckById = (
  merchantId: number,
  checkId: number,
  legalEntityId?: number,
) => {
  const { data, isLoading } = useQuery(
    ["get-ofac-check-by-id", merchantId, checkId],
    async () => await getOfacCheckById(merchantId, checkId, legalEntityId),
    {
      enabled: Boolean(merchantId) && Boolean(checkId),
    },
  );

  return {
    data,
    isLoading,
  };
};
