import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import { ssnSchema, urlSchema } from "@utils/validation.helpers";
type TinType = "ssn" | "ein";

export const getEntrepriseCreateSchema = () => {
  const enterprise_info = {
    enterpriseID: Yup.string(),
    enterprise_name: Yup.string()
      .required("Name is required")
      .matches(
        /^[a-zA-Z0-9,.'\s]+$/,
        " provider name should not contain special characters",
      ),
    enterprise_slug: Yup.string().required("Permalink can’t be empty"),
    avatar_url: Yup.mixed<File | null>(),
    description: Yup.string(),
    category: Yup.string(),
    enterprise_classification: Yup.string().required(
      "Please specify the classification",
    ),
    servicedPhoneNumber: Yup.string()
      .required("Please enter a valid phone number")
      .when({
        is: (exists: string) => !!exists,
        then: (schema) =>
          schema.test(
            "is-valid-number",
            "Please enter a valid phone number",
            function (value) {
              const phoneNumber = value as string;
              return matchIsValidTel(phoneNumber);
            },
          ),
      }),
    website_url: urlSchema({ isRequired: true }),
    hasServiceCountries: Yup.boolean(),
    servicedCountries: Yup.string().when("hasServiceCountries", {
      is: true,
      then: Yup.string().required("At least one serviced country is required"),
    }),
  };

  const businessProfile = {
    isLinkBusinessProfile: Yup.boolean(),
    linkedBusinessProfile: Yup.number(),
    businessType: Yup.string(),
    ownershipType: Yup.string(),
    legalName: Yup.string(),
    DBA: Yup.string(),
    tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein", null]),
    ssn: Yup.string().when("tinType", {
      is: "ssn",
      then: ssnSchema("ssn"),
    }),
    taxID: Yup.string().when("tinType", {
      is: "ein",
      then: Yup.string()
        .required(`EIN is required`)
        .test("valid-ein", `Please enter a valid EIN`, function (value) {
          if (!value) return false;
          return value.replace(/(\s|-)/g, "").length === 9;
        }),
    }),
    businessOpenedAt: Yup.string(),
    contactPhone: Yup.string(),
    address: Yup.object({
      country: Yup.string(),
      city: Yup.string(),
      address: Yup.string(),
      state: Yup.string(),
      zip: Yup.string(),
    }),
  };

  const primary_account_holder = {
    email: Yup.string(),
  };

  const businessOwners = {
    files: Yup.array().nullable(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    ownership: Yup.string(),
    ssn: Yup.string(),
    dob: Yup.date(),
    phone: Yup.string(),
    isBusinessAddress: Yup.boolean(),
    address: Yup.object().shape({
      country: Yup.string(),
      line1: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      zip: Yup.string(),
    }),
  };

  const schema = Yup.object({
    enterprise_info: Yup.object(enterprise_info),
    primary_account_holder: Yup.object(primary_account_holder),
    businessProfile: Yup.object(businessProfile),
    businessOwners: Yup.array().of(Yup.object(businessOwners)),
  });

  return schema;
};
