import { MerchantType } from "@customTypes/merchant.preview.types";
import ChangelogButton from "./ChangelogButton";
import ChangelogModal from "./ChangelogModal";
import { useChangelogModal } from "./hooks/useChanglelogModal";
import { useListTopics } from "features/Minibuilders/Conversations/hooks/useListTopics";
import { calculateTotalUnreadSum } from "features/Minibuilders/Conversations/utils/functions";
import { useChangelogData } from "./hooks/useChangelogData";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { FormProvider } from "react-hook-form";

const Changelog = ({
  isMerchantApproved,
  isLoadingConversation,
  merchant,
}: MerchantType & {
  isLoading: boolean;
  isMerchantApproved: boolean;
  isLoadingConversation: boolean;
}) => {
  const { isMobileView } = useCustomTheme();
  const { isOpen, handleOpenChangelogModal } = useChangelogModal();
  const {
    data,
    isLoading,
    handleLoadMore,
    isMoreData,
    filtersForm,
    isFiltersApplied,
    showMoreDataAvailable,
  } = useChangelogData({
    id: merchant?.accID,
    enabled: isMerchantApproved,
    isOpen,
  });

  const { data: topicData, isLoading: isTopicDataLoading } = useListTopics({
    merchantId: merchant?.accID,
    enabled: isMobileView,
  });

  const { totalUnread } = calculateTotalUnreadSum(topicData?.data);

  if (isOpen) {
    return (
      <FormProvider {...filtersForm}>
        <ChangelogModal
          data={data}
          isLoading={isLoading}
          headerActions={{
            handleOpenChangelogModal,
          }}
          isMerchantApproved={isMerchantApproved}
          isLoadingConversation={isLoadingConversation || isTopicDataLoading}
          totalUnread={totalUnread}
          handleLoadMore={handleLoadMore}
          merchantId={merchant?.accID}
          isMoreData={isMoreData}
          isFiltersApplied={isFiltersApplied}
          showMoreDataAvailable={showMoreDataAvailable}
        />
      </FormProvider>
    );
  }
  return (
    <ChangelogButton handleOpenChangelogModal={handleOpenChangelogModal} />
  );
};

export default Changelog;
