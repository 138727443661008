import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { palette } from "@palette";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box } from "@mui/material";
import { useHandleLogout } from "@hooks/common/useHandleLogout";

const TransactionNotFoundPopUp = NiceModal.create(() => {
  const { open, onClose, TransitionProps } = useNiceModal();
  const { submitLogout, isLoading } = useHandleLogout();

  const onClickHandler = () => {
    submitLogout();
  };

  return (
    <ModalFactory
      variant="popup"
      modalProps={{
        open,
        onClose,
        PopupProps: {
          width: "576px",
          paperStyle: { padding: "24px 24px 16px " },
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        title="This transfer belongs to another merchant."
        closeButtonSize={22}
        onClose={onClose}
      />
      <FadeUpWrapper delay={100}>
        <TextBox>
          <StyledText>
            You are currently logged in to a different merchant account. To view
            the details of the transfer, please log out and log in to the
            correct merchant account.
          </StyledText>
          <StyledText>
            You can also log in to the provider or acquirer account responsible
            for this merchant.
          </StyledText>
        </TextBox>
      </FadeUpWrapper>
      <ModalActions
        containerProps={{ sx: { mt: "40px", gap: "16px" } }}
        primaryAction={{
          label: "Log out",
          background: "primary",
          type: "button",
          onClick: onClickHandler,
          disabled: isLoading,
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: onClose,
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
      />
    </ModalFactory>
  );
});

export default TransactionNotFoundPopUp;

const TextBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "8px",
});

const StyledText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontFamily: "Give Whyte",
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: 0,
  marginBottom: "0px",
}));
