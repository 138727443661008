import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { palette } from "@palette";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { BtnBGTypes } from "@common/Button/Button_V2";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useDeleteConfirmationModal, {
  TModalVariant,
} from "./useDeleteConfirmationModal";
import { useDeleteMutation } from "./useDeleteMutation";
import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export type TDeleteModal = {
  variant: TModalVariant;
  deleteHandler?: () => void;
  itemName?: string;
  url?: string;
  onSuccess?: (res: any) => void;
  onError?: (error: any) => void;
  onSettled?: () => void;
  refetchQuerieKeys: string[];
  invalidateQuerieKeys: string[];
  productVariant?: CampaignType;
};

type TForm = {
  enterpriseName: string;
};

const DeleteConfirmationModal = NiceModal.create(
  ({
    variant,
    deleteHandler,
    itemName,
    url,
    onSuccess,
    onError,
    onSettled,
    refetchQuerieKeys = [],
    invalidateQuerieKeys = [],
    productVariant,
  }: TDeleteModal) => {
    const { open, onClose, TransitionProps } = useNiceModal();

    const schema = Yup.object({
      enterpriseName: Yup.string(),
    });

    const methods = useForm<TForm>({
      resolver: yupResolver(schema),
      defaultValues: { enterpriseName: "" },
    });
    const { watch } = methods;
    const values = watch();
    const isEnterpriseMatching =
      variant === "provider" && values.enterpriseName !== itemName;

    const { modalValues } = useDeleteConfirmationModal({
      variant,
      itemName,
      productVariant,
    });
    const { handleDelete, mutation } = useDeleteMutation({
      url: url,
      onSuccess: (res: any) => {
        if (onSuccess) onSuccess(res);
        if (modalValues.onSuccess) modalValues.onSuccess();
      },
      onError: (res: any) => {
        if (onError) onError(res);
      },
      onSettled,
      refetchQuerieKeys: refetchQuerieKeys.concat(
        modalValues?.refetchQuerieKeys,
      ),
      invalidateQuerieKeys: invalidateQuerieKeys.concat(
        modalValues?.invalidateQuerieKeys,
      ),
    });
    const isLoading = mutation.isLoading;
    const isDisabeled = isLoading || isEnterpriseMatching;

    const onClick = () => {
      if (deleteHandler) {
        deleteHandler();
      } else {
        handleDelete();
      }
      onClose();
    };
    return (
      <ModalFactory
        variant="popup"
        modalProps={{
          open,
          onClose,
          PopupProps: {
            width: "420px",
            paperStyle: { padding: "24px 24px 16px " },
            TransitionProps,
          },
        }}
      >
        <FormProvider {...methods}>
          <ModalTitle
            title={modalValues?.title}
            closeButtonSize={22}
            onClose={onClose}
          />
          <FadeUpWrapper delay={100}>
            <StyledText>{modalValues?.description}</StyledText>
          </FadeUpWrapper>
          <ModalActions
            containerProps={{ sx: { mt: "20px", gap: "16px" } }}
            primaryAction={{
              label: modalValues?.button.text,
              background: "red" as BtnBGTypes,
              type: "button",
              onClick: onClick,
              disabled: isDisabeled,
              dataTestId: "delete-action",
            }}
            secondaryAction={{
              label: "Cancel",
              onClick: onClose,
              disabled: isLoading,
              dataTestId: "cancel-action",
            }}
          />
        </FormProvider>
      </ModalFactory>
    );
  },
);

export default DeleteConfirmationModal;

const StyledText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontFamily: "Give Whyte",
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: 0,
  marginTop: "8px",
  marginBottom: "0px",
  "& span": { color: palette.neutral[80] },
}));
