import {
  formatDate,
  sourceMethodParser,
} from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { TransactionProduct } from "@components/ManageMoney/TransactionTable/transactions.types";
import { useMemo } from "react";

export const useParsedData = (allRows: any) => {
  const data = useMemo(() => {
    return allRows.map((thx: TransactionProduct) => ({
      id: thx.id,
      product: thx.product,
      charged: thx.transactionCharged / 100,
      displayStatus: thx.transactionDisplayStatus,
      createdAtTimestamp: thx.createdAt,
      createdAt: formatDate(thx.createdAt * 1000),
      updatedAt: thx.updatedAt,
      sourceAccount: {
        user: {
          ...thx.transactionCustomer,
        },
      },
      sourceMethod: {
        method: sourceMethodParser({
          sourceMethod: thx.transactionPaymentData,
        } as any),
      },
      email: thx.transactionCustomer.email,
      fees: thx.transactionFees / 100,
      processed: formatDate(thx.createdAt * 1000),
      revenue: "Website",
      transactionId: thx.TransactionObjID,
      cardholderName: thx.transactionPaymentData.card?.cardholderName,
      sorceAccountFullName: `${thx.transactionCustomer.firstName} ${thx.transactionCustomer.lastName}`,
      recurringInterval: thx.recurringInterval,
      recurringMax: thx.recurringMax,
      recurringCount: thx.recurringCount,
      unitPrice: thx.unitPrice / 100,
      itemCharged: thx.unitPrice / 100 + thx.transactionFees / 100,
      quantity: thx.quantity,
      totalAmount: thx.transactionCost / 100,
      transactionCost: thx.transactionCost / 100,
      transactionID: thx.TransactionObjID,
      price: thx.price / 100,
      passFees: thx.transactionPassFees,
      transactionCustomerId: thx.transactionCustomer.id,
      transactionProcessingState: thx.transactionProcessingState,
      orderRecurringItemID: thx.orderRecurringItemID,
      transactionCharged: (thx.transactionCharged ?? 0) / 100,
      hideRefund: thx.disallowReversalRequests,
      customer: thx.transactionCustomer,
    }));
  }, [allRows]);

  return data;
};
