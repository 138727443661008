import useAnalyticsDataParser from "../helpers/parsers";
import { useQuery } from "react-query";
import { getCustomersAnalytics } from "@services/api/analytics/customers";
import { useGetCurrentMerchantId } from "@hooks/common";
import { getTransactionChart } from "@services/api/analytics/transactions";

const useAnalyticsData = (isEmpty: boolean) => {
  const { merchantId } = useGetCurrentMerchantId();

  const { data, isLoading } = useQuery(
    ["customers-side-panel-analytics", merchantId],
    async () => {
      const data = await getCustomersAnalytics();
      const chart = await getTransactionChart({
        chartType: "customers_and_transactions",
      });

      return { data, chart: chart?.data || [] };
    },
    { refetchOnWindowFocus: false, enabled: !isEmpty },
  );

  const customData = useAnalyticsDataParser(data);

  return {
    data: customData,
    isLoading,
  };
};

export default useAnalyticsData;
