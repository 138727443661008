import {
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import NiceModal from "@ebay/nice-modal-react";
import {
  DELETE_CONFIRMATION_MODAL,
  MINIBUILDER_CREATE_SWEEPSTAKE_AMOUNT,
} from "modals/modal_names";
import { useFormContext } from "react-hook-form";
import { useState, useEffect } from "react";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

const valueKey = "Payment set up";
// TODO to fix the any's

export default function usePaymentSetup() {
  const [lastActive, setLastActive] = useState<string>("");
  const { setValue, watch } = useFormContext();
  const list = watch()[valueKey];
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    const activeList = list.filter((v: any) => v.active);

    if (activeList.length === 1) {
      setLastActive(activeList[0].id);
    } else {
      setLastActive("");
    }
  }, [list]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = list.findIndex((x: any) => x.id === active.id);
      const newIndex = list.findIndex((x: any) => x.id === over?.id);
      const newList = arrayMove(list, oldIndex, newIndex);
      setValue(valueKey, newList, { shouldDirty: true });
    }
  };

  const handleShowAmountItem = (id: string) => {
    const newList = list?.map((element: any) =>
      element.id === id
        ? Object.assign({}, element, { active: !element.active })
        : element,
    );
    setValue(valueKey, newList, { shouldDirty: true });
  };

  const handleRemoveAmountItem = (id: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "amount",
      deleteHandler: () => {
        const newList = list.filter((item: any) => item.id !== id);
        setValue(valueKey, newList, { shouldDirty: true });
      },
    });
  };

  const hasUniqueTitle = (name: string, id?: string) => {
    const isNotUnique = list.some(
      (amount: any) => id !== amount.id && amount.name === name,
    );
    return !isNotUnique;
  };

  const onCloseModal = (item?: any) => {
    if (!item) return;
    const amount = list.findIndex((element: any) => element.id === item.id);
    let newList = list;
    if (amount !== -1) {
      newList = list.map((element: any) =>
        element.id === item.id ? item : element,
      );
    } else {
      newList.push(item);
    }
    setValue(valueKey, newList, { shouldDirty: true });
  };

  const createAmount = () => {
    NiceModal.show(MINIBUILDER_CREATE_SWEEPSTAKE_AMOUNT, {
      onClose: onCloseModal,
      onDelete: handleRemoveAmountItem,
      hasUniqueTitle,
    });
  };

  const editAmount = (id: string, isLastActive?: boolean) => {
    const amount = list.find((element: any) => element.id === id);
    if (!amount) return;
    NiceModal.show(MINIBUILDER_CREATE_SWEEPSTAKE_AMOUNT, {
      onClose: onCloseModal,
      item: amount,
      ...(!isLastActive && {
        onDelete: handleRemoveAmountItem,
      }),
      hasUniqueTitle,
    });
  };

  const isCreateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.CREATE,
  });

  return {
    sensors,
    handleDragEnd,
    handleShowAmountItem,
    list,
    createAmount,
    editAmount,
    lastActive,
    isCreateAllowed,
  };
}
