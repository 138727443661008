import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import SubSectionLayout from "./DetailsPanel/SubsectionLayout";
import CardListItem from "./DetailsPanel/CardListItem";
import UserListItem from "./DetailsPanel/UserListItem";
import { TDetailsPanel } from "../data.types";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import StatCard from "./DetailsPanel/StatCard";
import {
  BasketIcon,
  CardholderIcon,
  EnvelopeIcon,
  StoreFrontIcon,
  UserCircleIcon,
} from "@assets/transactionsRiskProfileIcons";

const DetailsPanelBody = ({ data }: { data: TDetailsPanel }) => {
  const { cardsList, usersList, stats } = data;

  const sectionOne = [
    {
      Icon: <CardholderIcon />,
      title: "Payment Cards",
      ui: (
        <UnfoldListItems
          items={cardsList}
          renderKey={(row, index) => `${row.last4} - ${index}`}
          ItemComponent={(row) => <CardListItem {...row} />}
        />
      ),
    },
    {
      Icon: <UserCircleIcon />,
      title: "User Accounts",
      ui: (
        <UnfoldListItems
          items={usersList}
          renderKey={(row) => row.email}
          ItemComponent={(row) => <UserListItem {...row} />}
        />
      ),
    },
  ];

  const sectionTwo = [
    {
      Icon: <BasketIcon />,
      title: "Transaction Stats",
      ui: <StatCard items={stats.transactions} />,
    },
    {
      Icon: <StoreFrontIcon />,
      title: "Merchant Visits",
      ui: <StatCard items={stats.merchants} />,
    },
    {
      Icon: <EnvelopeIcon />,
      title: "Emails",
      ui: <StatCard items={stats.emails} />,
    },
  ];
  return (
    <Stack direction="row" justifyContent="stretch">
      <Section
        sx={{
          borderRight: `1px solid ${palette.liftedWhite[200]}`,
          paddingRight: "24px",
        }}
      >
        {sectionOne.map(({ ui, ...rest }, index) => (
          <SubSectionLayout
            key={rest.title}
            {...rest}
            delay={(index + 2) * 100}
          >
            {ui}
          </SubSectionLayout>
        ))}
      </Section>
      <Section sx={{ paddingLeft: "24px" }}>
        {sectionTwo.map(({ ui, ...rest }, index) => (
          <SubSectionLayout key={rest.title} {...rest} delay={index * 50 + 200}>
            {ui}
          </SubSectionLayout>
        ))}
      </Section>
    </Stack>
  );
};

const Section = styled(Stack)(() => ({
  flexDirection: "column",
  width: "50%",
  padding: "16px 8px",
  gap: "16px",
  alignItems: "stretch",
}));

export default DetailsPanelBody;
