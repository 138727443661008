import Circle from "@mui/icons-material/Circle";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";

const Container = styled(Stack)(({ theme }) => ({
  background: "rgba(53, 53, 53, 0.30)",
  backdropFilter: "blur(3px)",
  width: "100vw",
  height: "100vh",
  maxWidth: "100vw",
  maxHeight: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
  overflow: "hidden",
  flexDirection: "column",
  alignItems: "stretch",

  [theme.breakpoints.down("sm")]: {
    backgroundColor: palette.black[300],
    paddingInline: "16px",
    height: "-webkit-fill-available",
    WebkitOverflowScrolling: "touch",
  },
}));

const ContentWrapper = styled(Box)(() => ({
  width: "max-content",
  marginInline: "auto",
  height: "100%",
  minWidth: "20%",
  maxWidth: "100%",
  overflowY: "scroll",
  touchAction: "none",
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserDrag: "none",
}));

const Backdrop = styled(Box)(() => ({
  flexGrow: 1,
  overflow: "hidden",
}));

const Header = styled(Box)(({ theme }) => ({
  background: "rgba(236, 236, 233)",
  backdropFilter: "blur(2px)",
  padding: "10px 24px",
  zIndex: 1100,
  position: "relative",

  [theme.breakpoints.down("sm")]: {
    background: "rgba(41, 39, 39, 0.80)",
    backdropFilter: "blur(2px)",
    padding: "8px 16px",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
}));

const FloatingToolbar = styled(Stack)(() => ({
  position: "fixed",
  minHeight: "50px",
  width: "auto",
  right: 0,
  left: 0,
  bottom: 70,
  zIndex: 1100,
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  justifyContent: "center",
}));

const NavDot = styled(Circle)(({ selected }: { selected: boolean }) => ({
  width: "8px",
  height: "8px",
  color: palette.background.dimmedWhite,
  opacity: selected ? 1 : 0.5,
}));

export { Container, ContentWrapper, Backdrop, Header, FloatingToolbar, NavDot };
