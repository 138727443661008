import {
  PEPResourcesIDs,
  UpdateStatusArgs,
} from "@components/Merchants/MerchantPreview/PEP/types";
import { customInstance } from "..";

export const updateStatus = ({
  checkId,
  merchantId,
  selectedOwner,
  legalEntityID,
  data,
}: UpdateStatusArgs) =>
  customInstance({
    url: `/merchants/${merchantId}/legal-entities/${legalEntityID}/principals/${selectedOwner?.id}/pep-checks/${checkId}/status`,
    method: "PATCH",
    data,
  });

export const checkPEPMutation = ({
  merchantId,
  selectedOwner,
  legalEntityID,
}: PEPResourcesIDs) =>
  customInstance({
    url: `/merchants/${merchantId}/legal-entities/${legalEntityID}/principals/${selectedOwner?.id}/pep-checks`,
    method: "POST",
  });

export const getCheckHistory = ({
  merchantId,
  selectedOwner,
  legalEntityID,
}: PEPResourcesIDs) =>
  customInstance({
    url: `/merchants/${merchantId}/legal-entities/${legalEntityID}/principals/${selectedOwner?.id}/pep-checks`,
    method: "GET",
  });

export const getCheckDetails = ({
  checkId,
  merchantId,
  selectedOwner,
  legalEntityID,
}: PEPResourcesIDs) =>
  customInstance({
    url: `/merchants/${merchantId}/legal-entities/${legalEntityID}/principals/${selectedOwner?.id}/pep-checks/${checkId}`,
    method: "GET",
  });
