import { Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { InfinityIcon } from "@assets/builderIcons";

const OpenEndedInterval = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        padding: "0px 16px",
        background: palette.info.soft,
        borderRadius: "16px",
      }}
    >
      <Text
        fontStyle="italic"
        fontSize="14px"
        lineHeight="17px"
        color={palette.filled.blue}
      >
        open&#8209;ended
      </Text>
      <InfinityIcon color={palette.filled.blue} />
    </Stack>
  )
}

export default OpenEndedInterval;
