import { useCallback, useState } from "react";
import { durations } from "@constants/constants";
import { parseRelativeTimeString } from "@utils/date.helpers";
import { sanitizer } from "@utils/index";

type TAddToCartHandler = (item: {
  recurringInterval: string;
  recurringMax: number | null;
}) => void;

export const usePaymentFormUtils = ({
  defaultMonthlyRecurringMax,
}: {
  defaultMonthlyRecurringMax?: number | null;
}) => {
  const [duration, setDuration] = useState<string>("One-Time");
  const [subDuration, setSubDuration] = useState<string>("Lifetime");

  const handleChangeDuration = (
    duration: string,
    addToCartHandler: TAddToCartHandler,
  ) => {
    setDuration(duration);
    switch (duration) {
      case "One-Time":
        setSubDuration("Lifetime");
        addToCartHandler({ recurringInterval: "One-Time", recurringMax: null });
        break;
      case "Monthly":
        setSubDuration("Every Month");
        addToCartHandler({
          recurringInterval: "Monthly",
          recurringMax:
            defaultMonthlyRecurringMax !== undefined
              ? defaultMonthlyRecurringMax
              : 3,
        });
        break;
      case "Quarterly":
        setSubDuration("Every Quarter");
        addToCartHandler({ recurringInterval: "Quarterly", recurringMax: 1 });
        break;
      case "Yearly":
        setSubDuration("Every Year");
        addToCartHandler({ recurringInterval: "Yearly", recurringMax: 3 });
        break;
    }
  };

  const getRecurringMax = useCallback(
    (max?: string) => {
      const recurringMax = max || subDuration;
      if (recurringMax.includes("Every")) return null;
      switch (duration) {
        case "One-Time":
          return null;
        case "Monthly":
        case "Yearly":
          return +recurringMax.substring(0, 2);
        case "Quarterly":
          return +recurringMax.substring(0, 2) / 3;
      }
      return null;
    },
    [duration, subDuration],
  );

  const handleChangeSubDuration = (
    subDuration: string,
    addToCartHandler: TAddToCartHandler,
  ) => {
    addToCartHandler({
      recurringInterval: duration,
      recurringMax: getRecurringMax(subDuration),
    });
    setSubDuration(subDuration);
  };

  const renderOptionsHelper = (column: string, paymentTypes: any) => {
    const arr =
      column !== "Duration"
        ? paymentTypes
        : durations[duration ? duration : paymentTypes[0].title];

    const _type = sanitizer(arr[0].title || arr[0]);
    const { formattedDate } = parseRelativeTimeString(subDuration);

    const filteredOptions = arr
      .filter((type: any) => type.checked !== false)
      .map((type: string) => sanitizer(type));

    return {
      arr: column !== "Duration" ? filteredOptions : arr,
      formattedDate,
      _type,
    };
  };

  return {
    duration,
    setDuration,
    subDuration,
    setSubDuration,
    handleChangeDuration,
    getRecurringMax,
    handleChangeSubDuration,
    renderOptionsHelper,
  };
};
