import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

type Props = {
  width?: number;
  height?: number;
};

const TeamMembersIcon = ({ width = 100, height = 100 }: Props) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 21.25C39.5422 21.25 30.3858 26.8321 25.3518 35.1912C22.7482 39.5146 21.25 44.5785 21.25 50C21.25 55.4215 22.7482 60.4854 25.3518 64.8088C30.3858 73.1679 39.5422 78.75 50 78.75C51.3807 78.75 52.5 79.8693 52.5 81.25C52.5 82.6307 51.3807 83.75 50 83.75C37.717 83.75 26.9694 77.1869 21.0685 67.3882C18.0092 62.308 16.25 56.3556 16.25 50C16.25 43.6444 18.0092 37.692 21.0685 32.6118C26.9694 22.8131 37.717 16.25 50 16.25C55.5196 16.25 60.7366 17.5769 65.3425 19.9314C66.5719 20.5599 67.059 22.066 66.4306 23.2954C65.8021 24.5248 64.296 25.0119 63.0666 24.3835C59.149 22.3808 54.7102 21.25 50 21.25ZM75.5001 31.7599C76.6829 31.0476 78.2192 31.429 78.9315 32.6118C81.9908 37.692 83.75 43.6444 83.75 50V57.5758C83.75 63.6634 78.8149 68.5985 72.7273 68.5985C68.6567 68.5985 65.1014 66.392 63.1923 63.1098C59.8235 66.4997 55.1568 68.5985 50 68.5985C39.7283 68.5985 31.4015 60.2717 31.4015 50C31.4015 39.7283 39.7283 31.4015 50 31.4015C60.2717 31.4015 68.5985 39.7283 68.5985 50C68.5985 52.917 67.927 55.6771 66.7301 58.1342C67.0118 61.1986 69.5893 63.5985 72.7273 63.5985C76.0535 63.5985 78.75 60.902 78.75 57.5758V50C78.75 44.5785 77.2518 39.5146 74.6482 35.1912C73.9359 34.0084 74.3173 32.4722 75.5001 31.7599ZM62.0551 56.2983C63.0409 54.4153 63.5985 52.2728 63.5985 50C63.5985 42.4898 57.5102 36.4015 50 36.4015C42.4898 36.4015 36.4015 42.4898 36.4015 50C36.4015 57.5102 42.4898 63.5985 50 63.5985C55.155 63.5985 59.6401 60.7301 61.9461 56.5022C61.9794 56.4324 62.0157 56.3644 62.0551 56.2983Z"
        fill="url(#paint0_linear_825_582)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_825_582"
          x1="83.75"
          y1="16.25"
          x2="17.9827"
          y2="85.398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color1} />
          <stop offset="1" stopColor={color2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TeamMembersIcon;
