import React, { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import Footer from "@pages/Login/Footer";
import { palette } from "@palette";
import SignupPersonalDetails from "./Forms/SignupPersonalDetails";
import SignupOrganizationDetails from "./Forms/SignupOrganizationDetails";
import SignupWelcome from "./Forms/SignupWelcome";
import SignupFormProvider from "./Forms/SignupFormProvider";
import KotoStepper from "@common/SignUp/KotoStepper";
import { BACKGROUND_VIDEO_PRIMARY } from "@constants/constants";
import BusinessType from "./Forms/components/BusinessType";
import BusinessClassifications from "./Forms/components/BusinessClassifications";
import { MainLogo } from "@hooks/common/useEnterpriseLogo";
import Logo from "@assets/icons/RebrandedIcons/Logo";
import { isEnterpriseWebsite } from "@hooks/onboarding/utils";
import { useSearchParams } from "react-router-dom";

export type SignupStepType = (typeof SignupEnum)[keyof typeof SignupEnum];

const SignupContainer = () => {
  const [searchParams] = useSearchParams();
  const classificationParam = searchParams.get("classification");
  const isParams = Boolean(classificationParam);

  const isEnterprise = isEnterpriseWebsite();

  const [isStart, setIsStart] = React.useState(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const stepperRef = useRef(null);
  const [step, setStep] = React.useState<SignupStepType>(
    SignupEnum.BUSINESS_CLASSIFICATION,
  );

  useEffect(() => {
    if (isParams) {
      setIsStart(false);
      setStep(SignupEnum.PERSONAL_DETAILS);
      (stepperRef.current as any).handleNext();
      handleUpdateStatusValue(SignupEnum.BUSINESS_CLASSIFICATION)(100);
    }
  }, [isParams]);

  const isStartAndNotEnterprise = isStart && !isEnterprise;

  const [signupStatusBar, setSignupStatusBar] =
    React.useState(initialBarsValue);

  const handleResetStatusBars = () => {
    setSignupStatusBar(initialBarsValue);
  };

  const handleUpdateStatusValue = React.useCallback(
    (label: string) => (value: number) => {
      setSignupStatusBar((prev) => {
        let oldArr = prev;
        const index = oldArr.findIndex((obj) => obj.label === label);

        if (index !== -1) {
          oldArr = [
            ...oldArr.slice(0, index),
            { ...oldArr[index], barValue: value },
            ...oldArr.slice(index + 1),
          ];
        }
        return oldArr;
      });
    },
    [],
  );

  return (
    <Box sx={containerStyle}>
      <video autoPlay muted loop>
        <source src={BACKGROUND_VIDEO_PRIMARY} type="video/mp4" />
      </video>

      <SignupFormProvider>
        <Stack direction="row" sx={navbarStyle} justifyContent="space-between">
          <MainLogo defaultLogo={<Logo />} />

          <KotoStepper
            sx={{
              width: "70%",
            }}
            disableWithoutStyle
            disableLastClick
            automateLast
            setStep={setStep}
            defaultStep={SignupEnum.PERSONAL_DETAILS}
            customSteps={signupStatusBar}
            ref={stepperRef}
            setIsLoaded={setIsLoaded}
          />
        </Stack>
        <div>
          {isStartAndNotEnterprise && !isParams && (
            <BusinessType setIsStart={() => setIsStart(false)} />
          )}
          <>
            {!isStartAndNotEnterprise &&
              !isParams &&
              isLoaded &&
              step === SignupEnum.BUSINESS_CLASSIFICATION && (
                <BusinessClassifications
                  handleUpdateStatusValue={handleUpdateStatusValue(
                    SignupEnum.BUSINESS_CLASSIFICATION,
                  )}
                  handleNext={(stepperRef.current as any).handleNext}
                  setIsStart={() => setIsStart(true)}
                  handleResetStatusbars={handleResetStatusBars}
                  isFirstPage={isEnterprise}
                />
              )}
            {!isStartAndNotEnterprise &&
              isLoaded &&
              step === SignupEnum.PERSONAL_DETAILS && (
                <SignupPersonalDetails
                  handleUpdateStatusValue={handleUpdateStatusValue(
                    SignupEnum.PERSONAL_DETAILS,
                  )}
                  personalDetailsStatusBar={signupStatusBar[1].barValue}
                  handleNext={(stepperRef.current as any).handleNext}
                  handleBack={(stepperRef.current as any).handleBack}
                  isFirstPage={isParams}
                />
              )}
            {!isStartAndNotEnterprise &&
              isLoaded &&
              step === SignupEnum.ORGANIZATION_DETAILS && (
                <SignupOrganizationDetails
                  handleNext={(stepperRef.current as any).handleNext}
                  handleBack={(stepperRef.current as any).handleBack}
                  handleUpdateStatusValue={handleUpdateStatusValue(
                    SignupEnum.ORGANIZATION_DETAILS,
                  )}
                  organizationDetailsStatusBar={signupStatusBar[2].barValue}
                />
              )}
            {!isStartAndNotEnterprise && isLoaded && step === "Welcome" && (
              <SignupWelcome />
            )}
          </>
        </div>
      </SignupFormProvider>

      <Footer color={palette.neutral[80]} />
    </Box>
  );
};

enum SignupEnum {
  BUSSINESS_TYPE = "How do you classify your business?",
  BUSINESS_CLASSIFICATION = "Business Classification",
  PERSONAL_DETAILS = "Personal Details",
  ORGANIZATION_DETAILS = "Business Details",
  WELCOME = "Welcome",
}

const initialBarsValue = [
  {
    label: SignupEnum.BUSINESS_CLASSIFICATION,
    barValue: 0,
  },
  {
    label: SignupEnum.PERSONAL_DETAILS,
    barValue: 0,
  },
  {
    label: SignupEnum.ORGANIZATION_DETAILS,
    barValue: 0,
  },
  {
    label: SignupEnum.WELCOME,
    barValue: 0,
  },
];

const containerStyle = {
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px 32px",
  position: "relative",
  background:
    "linear-gradient(358deg, rgba(241, 241, 241, 0.70) 0%, rgba(241, 241, 241, 0.00) 100%)",
  overflow: "hidden",

  "& video": {
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    top: 0,
    left: 0,
    zIndex: -1,
  },

  "@media (max-width: 600px)": {
    padding: "47px 12px 24px 12px",
  },
};

const navbarStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    gap: "28px",
  },
};

export default SignupContainer;
