import { MasqueradeModeType } from "@redux/slices/app";
import React from "react";

const getMasqueradeData = () => {
  const item = localStorage.getItem("masquerade-mode");
  if (!item) return undefined;
  return JSON.parse(item);
}

const useLocalMasquerade = () => {
  const [data, setData] = React.useState<MasqueradeModeType|undefined>(undefined);

  React.useEffect(() => {
    function handleStorageChange() {
      setData(getMasqueradeData);
    }

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  return data;
}

export default useLocalMasquerade;
