type IconProps = {
  width?: number,
  height?: number,
  fill?: string,
}

const PinIcon = ({
  width = 20,
  height = 20,
  fill = "#9C9AA3",
}: IconProps) => {
  return (
    <svg 
      width={width} 
      height={height}  
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M10.3989 16.4677L10.119 15.937L10.119 15.937L10.3989 16.4677ZM9.59981 16.4677L9.87973 15.937L9.87973 15.937L9.59981 16.4677ZM15.2327 9.16658C15.2327 11.0064 14.3121 12.5161 13.1797 13.6781C12.0473 14.8401 10.7499 15.6043 10.119 15.937L10.6788 16.9984C11.3643 16.6369 12.7837 15.8039 14.0391 14.5156C15.2945 13.2274 16.4327 11.4336 16.4327 9.16658H15.2327ZM9.99935 3.93325C12.8896 3.93325 15.2327 6.27629 15.2327 9.16658H16.4327C16.4327 5.61355 13.5524 2.73325 9.99935 2.73325V3.93325ZM4.76602 9.16658C4.76602 6.27629 7.10906 3.93325 9.99935 3.93325V2.73325C6.44632 2.73325 3.56602 5.61355 3.56602 9.16658H4.76602ZM9.87973 15.937C9.24885 15.6043 7.95136 14.8401 6.81898 13.6781C5.68655 12.5161 4.76602 11.0064 4.76602 9.16658H3.56602C3.56602 11.4336 4.70419 13.2274 5.95957 14.5156C7.21498 15.8039 8.6344 16.6369 9.3199 16.9984L9.87973 15.937ZM10.119 15.937C10.0422 15.9775 9.95651 15.9775 9.87973 15.937L9.3199 16.9984C9.74703 17.2237 10.2517 17.2237 10.6788 16.9984L10.119 15.937ZM11.8993 9.16659C11.8993 10.2159 11.0487 11.0666 9.99935 11.0666V12.2666C11.7114 12.2666 13.0993 10.8787 13.0993 9.16659H11.8993ZM9.99935 7.26659C11.0487 7.26659 11.8993 8.11724 11.8993 9.16659H13.0993C13.0993 7.4545 11.7114 6.06659 9.99935 6.06659V7.26659ZM8.09935 9.16659C8.09935 8.11724 8.95001 7.26659 9.99935 7.26659V6.06659C8.28727 6.06659 6.89935 7.4545 6.89935 9.16659H8.09935ZM9.99935 11.0666C8.95001 11.0666 8.09935 10.2159 8.09935 9.16659H6.89935C6.89935 10.8787 8.28727 12.2666 9.99935 12.2666V11.0666Z" 
        fill={fill}
      />
    </svg>
  )
}

export default PinIcon;
