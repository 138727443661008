import { APITransactionsGroup, IParsedData } from "../data.types";
import { useMemo } from "react";
import { additionalDetailsParser } from "./additionalDetailsParser";
import { statsParser } from "./statsParser";
import { groupTransactions } from "./transactions.helpers";
import { useAppSelector } from "@redux/hooks";
import { selectTypeFilter } from "@redux/slices/acquirer/transactionRiskProfile";

const useDataParser = ({
  data,
  transactions,
}: {
  data: any;
  transactions: any;
}): IParsedData => {
  const typeFilter = useAppSelector(selectTypeFilter);
  const level = data?.riskLevel || 0;

  const nextDeescalationAt = data?.lastEscalation?.nextDeescalationAt;

  const general = {
    countryCodeISO2: data?.ipInfo?.country_code?.toLowerCase() || "",
    countryName: data?.ipInfo?.country || "",
    ipAddress: data?.ipAddress || "",
    hasIPProbeData: !!data?.ipInfo?.security,
    updatedAt: data?.updatedAt || 0,
    isVPN:
      data?.ipInfo?.security?.anonymous ||
      data?.ipInfo?.security?.hosting,
  };

  const geomap = {
    latitude: data?.ipInfo?.latitude,
    longitude: data?.ipInfo?.longitude,
    timezone: `${data?.ipInfo?.timezone?.id} (${data?.ipInfo?.timezone?.abbr})`,
  };

  const cardsList = useMemo(() => {
    if (!data?.stats?.cards?.length) return [];
    return data.stats.cards.map((card: any) => ({
      cardBrand: card.brand,
      last4: card.last4,
      isDebit: card.isDebit,
      charged: card.sum / 100,
    }));
  }, [data?.stats?.cards]);

  const usersList = useMemo(() => {
    if (!data?.stats?.users?.length) return [];
    return data.stats.users.map((user: any) => ({
      imageURL: user.imageURL,
      userName: `${user.firstName} ${user.lastName}`,
      email: user.email,
    }));
  }, [data?.stats?.users]);

  const stats = statsParser(data);
  const additionalDetails = additionalDetailsParser(data);

  const transactionsList = useMemo(() => {
    if (!transactions?.data?.length) return [];
    const filteredData =
      typeFilter === "all"
        ? transactions.data
        : filterDataByEscalation(transactions.data);
    return groupTransactions(filteredData);
  }, [transactions?.data, typeFilter]);

  return {
    level,
    general,
    geomap,
    detailsPanel: {
      cardsList,
      usersList,
      stats,
    },
    additionalDetails,
    transactions: transactionsList,
    nextDeescalationAt,
  };
};

const filterDataByEscalation = (data: APITransactionsGroup[]) =>
  data
    .filter((thxGroup) =>
      thxGroup.transactions.some((thx) => !!thx?.eventEscalation),
    )
    .map((thxGroup) => ({
      ...thxGroup,
      transactions: thxGroup.transactions.filter(
        (thx) => !!thx?.eventEscalation,
      ),
    }));

export default useDataParser;
