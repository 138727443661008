const EnvelopeSimpleIcon = ({
  width = 21,
  height = 20,
  stroke = "#403D3D",
  datatestID = "envelope-simple-icon",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      data-testid={datatestID}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M18.0986 4.375L10.5986 11.25L3.09863 4.375H18.0986Z"
        fill={stroke}
      />
      <path
        d="M18.0986 3.75H3.09863C2.93287 3.75 2.7739 3.81585 2.65669 3.93306C2.53948 4.05027 2.47363 4.20924 2.47363 4.375V15C2.47363 15.3315 2.60533 15.6495 2.83975 15.8839C3.07417 16.1183 3.39211 16.25 3.72363 16.25H17.4736C17.8052 16.25 18.1231 16.1183 18.3575 15.8839C18.5919 15.6495 18.7236 15.3315 18.7236 15V4.375C18.7236 4.20924 18.6578 4.05027 18.5406 3.93306C18.4234 3.81585 18.2644 3.75 18.0986 3.75ZM16.4916 5L10.5986 10.4023L4.70566 5H16.4916ZM17.4736 15H3.72363V5.79609L10.176 11.7109C10.2913 11.8168 10.4421 11.8755 10.5986 11.8755C10.7552 11.8755 10.906 11.8168 11.0213 11.7109L17.4736 5.79609V15Z"
        fill={stroke}
      />
    </svg>
  );
};

export default EnvelopeSimpleIcon;
