import React from "react";
import EmptyState from "./EmptyState";
import { useNavigate } from "react-router-dom";
import { Text } from "@common/Text";
import { palette } from "@palette";

const NotFoundPageState = () => {
  const navigate = useNavigate();
  return (
    <EmptyState
      Icon={
        <Text
          fontSize="156px"
          fontWeight="light"
          lineHeight="140.4px"
          letterSpacing="-4.68px"
          gradient={palette.gradient[10]}
        >
          404
        </Text>
      }
      title={{
        main: "This page doesn’t exist",
        secondary: "Sorry, the page you are looking for doesn’t exist or has been removed",
      }}
      action={{
        label: "Go to homepage",
        onClick: () => navigate("/login"),
      }}
      sx={{
        height: "100vh",
      }}
    />
  )
}

export default React.memo(NotFoundPageState);
