import moment from "moment";
import {
  ParsedTransaction,
  FormattedListItem,
  APITransactionsGroup,
} from "../data.types";

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

export const groupTransactions = (
  array: APITransactionsGroup[],
): FormattedListItem[] => {
  return array.map((group) => {
    const groupDate = moment(new Date(group.groupPeriod));
    let date = "";

    if (groupDate.isSame(today, "day")) {
      date = "Today";
    } else if (groupDate.isSame(yesterday, "day")) {
      date = "Yesterday";
    } else {
      date = groupDate.format("MMM. DD YYYY");
    }

    const groupsList: ParsedTransaction[][] = [];

    // group transaction in stacks by minute
    for (let i = 0; i < group.transactions.length; i++) {
      const transaction = group.transactions[i];
      const parsedTransaction = parseTransaction(transaction);

      if (groupsList.length === 0) {
        groupsList.push([parsedTransaction]);
        continue;
      }
      stackTransactionByMinute(groupsList, parsedTransaction);
    }

    return {
      label: date,
      list: groupsList,
    };
  });
};

const stackTransactionByMinute = (
  newList: ParsedTransaction[][],
  transaction: ParsedTransaction,
) => {
  const lastGroupByMinuteIndex = newList.length - 1;
  const lastGroupByMinute = newList[lastGroupByMinuteIndex];

  // check if the transaction date is within a minute from the first
  // transaction in the last stack (since the array it's ordered by creation date)
  const lastElementDate = lastGroupByMinute[0].date;
  const isWithinOneMinute =
    transaction.date <= lastElementDate &&
    transaction.date >= lastElementDate - 60;
  // check if the first transaction of the array are both with escalation or both
  // without because we don't stack different types
  const bothWithEscalation =
    !!transaction.escalation.createdAt &&
    !!lastGroupByMinute[0].escalation.createdAt;
  const bothWithoutEscalation =
    !transaction.escalation.createdAt &&
    !lastGroupByMinute[0].escalation.createdAt;
  const isSameType = bothWithEscalation || bothWithoutEscalation;

  if (isWithinOneMinute && isSameType) {
    // if within a minute and of same type, we push the transaction in the last stack
    const newEl = [...lastGroupByMinute, transaction];
    newList[lastGroupByMinuteIndex] = newEl;
  } else {
    // if not we add a new stack
    newList.push([transaction]);
  }
};

const parseTransaction = (thx: any): ParsedTransaction => ({
  id: thx.id,
  merchantId: thx.merchantAccID,
  merchantName: thx.merchantName,
  charged: thx.charged / 100,
  status: getStatus(
    thx.isBlocked,
    thx.isFalsePositive,
    thx?.isQuarantined || false,
    thx.processingStateName,
    thx.displayStatus,
  ),
  displayStatus: thx.displayStatus,
  card: {
    cardBrand: thx.binInfo.cardBrand,
    last4: thx.paymethodLast4,
    cardHolder: thx.cardholderName,
  },
  email: {
    isValid: thx.customerUserEmailStatus === "valid",
    email: thx.customerUserEmail,
  },
  date: thx.createdAt,
  escalation: {
    createdAt: thx.eventEscalation?.createdAt || 0,
    triggerPoints: thx.eventEscalation?.riskPoints || 0,
    triggerReason: thx.eventEscalation?.triggerReason || "",
    riskLevel: thx.ipProfileSnapshot?.riskLevel || 0,
    trigger: thx.eventEscalation?.trigger || "",
    triggerType: thx.eventEscalation?.triggerType || "",
  },
});

const getStatus = (
  isBlocked: boolean,
  isFalsePositive: boolean,
  isQuarantine: boolean,
  processingStateName: string,
  displayStatus: string,
) => {
  if (isFalsePositive) {
    return "false_positive";
  } else if (isBlocked) {
    return "blocked";
  } else if (processingStateName === "declined") {
    return processingStateName;
  } else if (processingStateName === "failed") {
    return processingStateName;
  } else if (processingStateName === "voided") {
    return "voided";
  } else if (isQuarantine) {
    return "quarantined";
  } else if (
    processingStateName === "approved" &&
    displayStatus === "Pending"
  ) {
    return "pending";
  } else {
    return processingStateName;
  }
};
