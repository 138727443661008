import * as React from "react";
import { palette } from "@palette";
// assets
import {
  SortIcon,
  SortUpIconV2,
  SortDownIconV2,
  ArrowForwardUp,
  ArrowForwardDown,
  CheckMarkIcon,
} from "@assets/icons";
import { DropdownItem } from "@common/Select";
import { Text } from "@common/Text";
import { Menu, Stack, Box } from "@mui/material";
import { Button } from "@common/Button";

type MobileRevenueSortMenuProps = {
  setSelectedSortIcon: React.Dispatch<React.SetStateAction<JSX.Element>>;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
};

const MobileRevenueSortMenu = ({
  setSelectedSortIcon,
  anchorEl,
  setAnchorEl,
}: MobileRevenueSortMenuProps) => {
  const [sortList, setSortList] = React.useState(initialSortList);
  const openMenu = Boolean(anchorEl);

  const checkSortType = (type: string, subType: string): void => {
    const updatedSortList = sortList.map((item) => {
      if (item.type === type) {
        if (subType === "up") {
          !item.subType.up === true
            ? setSelectedSortIcon(<SortUpIconV2 />)
            : setSelectedSortIcon(<SortIcon />);
          return {
            ...item,
            subType: { up: !item.subType.up, down: false },
          };
        }
        !item.subType.down === true
          ? setSelectedSortIcon(<SortDownIconV2 />)
          : setSelectedSortIcon(<SortIcon />);
        return {
          ...item,
          subType: { down: !item.subType.down, up: false },
        };
      }
      return {
        ...item,
        subType: { down: false, up: false },
      };
    });

    setSortList(updatedSortList);
  };

  const clearFilters = () => {
    setSortList(initialSortList);
    setSelectedSortIcon(<SortIcon />);
  };

  return (
    <Menu
      open={openMenu}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: 172,
        },
      }}
    >
      {sortList.map((item) => (
        <Box key={item.type}>
          <DropdownItem
            disabled
            sx={{
              "&.Mui-disabled": {
                opacity: 1,
              },
            }}
          >
            <Text sx={sortTypeStyle}>{item.type}</Text>
          </DropdownItem>
          <DropdownItem onClick={() => checkSortType(item.type, "up")}>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              height="100%"
            >
              <ArrowForwardUp />
              <Text
                sx={{
                  flexGrow: 1,
                  fontWeight: item.subType.up ? "500" : "400",
                }}
              >
                Low to high
              </Text>
              {item.subType.up && <CheckMarkIcon fill="#4C4A52" />}
            </Stack>
          </DropdownItem>
          <DropdownItem onClick={() => checkSortType(item.type, "down")}>
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              height="100%"
            >
              <ArrowForwardDown />
              <Text
                sx={{
                  flexGrow: 1,
                  fontWeight: item.subType.down ? "500" : "400",
                }}
              >
                High to low
              </Text>
              {item.subType.down && <CheckMarkIcon fill="#4C4A52" />}
            </Stack>
          </DropdownItem>
        </Box>
      ))}
      <Button
        variant="text"
        fullWidth
        sx={{ fontWeight: 400, color: palette.neutral[600] }}
        onClick={clearFilters}
      >
        Clear Filters
      </Button>
    </Menu>
  );
};

const initialSortList = [
  { type: "Source", subType: { up: false, down: false } },
  { type: "Amount", subType: { up: false, down: false } },
  { type: "Average", subType: { up: false, down: false } },
];

const sortTypeStyle = {
  color: palette.primary[400],
  fontSize: 12,
  fontWeight: "400",
};

export default MobileRevenueSortMenu;
