import React, { useMemo, useRef } from "react";
import { useGetMemberships } from "@services/api/products/membership";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { detectMobile } from "@utils/index";
import { useCachedList } from "@hooks/common/useCachedList";
import { useAppSelector } from "@redux/hooks";
import { selectQueryFilters } from "@redux/slices/fundraisers";
import { encodedQueryFilterMap } from "@services/filtering";
import { sorting as sortingReducer } from "@redux/slices/fundraisers";
import { selectQueryString } from "@redux/slices/search";
import { QKEY_LIST_MEMBERSHIPS } from "@constants/queryKeys";
import { useStateEffect } from "@hooks/customReactCore";

const useMembership = () => {
  const queryFilters = useAppSelector(selectQueryFilters);
  const sorting = useAppSelector(sortingReducer);
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_MEMBERSHIPS),
  );

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const { page, setPage } = usePagination(0, queryFilter.products);

  const loadingRef = useRef<boolean>(false);

  const { allData, invalidateCache } = useCachedList(QKEY_LIST_MEMBERSHIPS);

  const queryString = queryFilter.products ? `%3B${queryFilter.products}` : "";

  const { data, isLoading, isError } = useGetMemberships(
    {
      queryString,
      page,
      sorting,
      searchQuery,
    },
    {
      refetchOnWindowFocus: false,

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useStateEffect(() => {
    if (detectMobile()) invalidateCache();
    setPage(1);
  }, [sorting, searchQuery, queryString]);

  const usedData = detectMobile() ? allData : data?.data ?? [];

  return {
    data,
    isLoading,
    isError,
    page,
    setPage: () => setPage((current) => current + 1),
    setPageDispatcher: setPage,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: usedData,
    allRows: usedData,
    handlePageChange,
    totalRows: data?.total ?? 0,
    loadingRef,
    state: {
      isEmpty: !queryString && !searchQuery && data?.total === 0,
      isError,
    },
  };
};

export default useMembership;
