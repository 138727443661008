import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";

export const ChipLabel = styled(Text)(() => ({
  fontSize: "12px",
  color: palette.info.main,
  lineHeight: "14.4px",
  fontWeight: "350",
  padding: "4px 16px 4px 16px",
  backgroundColor: palette.info.light,
  width: "fit-content",
  borderRadius: "100px",
}));

export const Label = styled(Text)(() => ({
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "400",
  color: palette.neutral[80],
  width: "fit-content",
}));

export const Container = styled(Stack)(() => ({
  backgroundColor: palette.neutral.white,
  padding: "12px 16px 12px 16px",
  gap: "12px",
  borderRadius: "8px",
  width: "100%",
  marginTop: "8px",
}));

export const LabelValue = styled(Label, {
  shouldForwardProp: (prop) => prop !== "to",
})<{ to?: boolean }>(({ to }) => ({
  padding: "0px 8px",
  backgroundColor: to ? palette.neutral[90] : palette.neutral[10],
  color: to ? palette.neutral.white : palette.neutral[80],
  borderRadius: "4px",
  width: "fit-content",
  display: "inline-flex",
  wordBreak: "keep-all",
  lineHeight: "16.8px",
}));

export const AvatarImage = styled("img")(() => ({
  height: "32px",
  width: "32px",
  borderRadius: "32px",
}));
