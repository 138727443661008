import React from "react";
import { customInstance } from "@services/api";
import axios from "axios";

export const useUploadSingleImage = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpload = async (
    file: File,
    formType = "customer_avatar",
    onUploadProgress?: any,
  ) => {
    setIsLoading(true);
    const res = await customInstance({
      url: "s3/upload-form",
      method: "POST",
      data: {
        formType,
      },
    });

    await axios.post(
      res.action,
      {
        key: res.key,
        acl: res.acl,
        "x-amz-date": res["x-amz-date"],
        "x-amz-credential": res["x-amz-credential"],
        "x-amz-algorithm": res["x-amz-algorithm"],
        policy: res.policy,
        "Content-Type": `image/${file?.name?.split(".")?.pop()}`,
        "X-Amz-Signature": res["x-amz-signature"],
        file,
      },
      {
        onUploadProgress,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    setIsLoading(false);
    return `${res.action}/${res.key}`;
  };

  return { handleUpload, isLoading };
};
