import { UseQueryOptions, useQuery } from "react-query";
import { customInstance } from "./index";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { Customer } from "@customTypes/customer.types";
import { useGetCurrentMerchantId } from "@hooks/common";
import { ProductParams } from "./products/queryFactory";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { MemberRoleName } from "@customTypes/team.member";

export const getCustomers = ({
  queryString,
  page,
  sorting,
  searchQuery,
  merchID,
}: ProductParams & { merchID: number }) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  const filterQuery = queryString ? `filter=${queryString}` : "";

  return customInstance({
    url: `/merchants/${merchID}/customers?${filterQuery}&sort=${
      sorting || "-id"
    }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    method: "GET",
  });
};

export const getCustomerById = (id: number, merchID: number) => {
  return customInstance({
    url: `/merchants/${merchID}/customers/${id}`,
    method: "GET",
  });
};

export const useGetCustomers = (
  params: ProductParams,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const dispatch = useAppDispatch();
  const { merchantId } = useGetCurrentMerchantId();

  return useQuery(
    [
      "list-all-customers",
      params.sorting,
      params.page,
      params.searchQuery,
      params.queryString,
      merchantId,
    ],
    async () => {
      const data = await getCustomers({
        merchID: merchantId,
        ...params,
      });
      return data;
    },
    {
      ...options,
      retry: 2,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              customers_list: true,
            }),
          );
        }
      },
    },
  );
};

export const useGetCustomerById = (id: number, overrideMerchantId?: number) => {
  const dispatch = useAppDispatch();
  const { merchantId } = useGetCurrentMerchantId();

  const { data, isLoading, isRefetching } = useQuery<Customer>(
    ["customer", id],
    async () => {
      const data = await getCustomerById(id, overrideMerchantId ?? merchantId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              view_customer: true,
            }),
          );
        }
      },
    },
  );

  return {
    data,
    isLoading: isLoading || isRefetching,
  };
};

export interface InviteType {
  //title: string;
  InviteMember?: boolean;
  MemberRole: MemberRoleName;
  Emails: string[];
}
export const inviteMembers = (id: number, data: InviteType) => {
  return customInstance({
    url: `/accounts/${id}/members-invites`,
    method: "POST",
    data,
  });
};

export interface CreateMemberTypes {
  email: string;
  memberRole: MemberRoleName;
  merchantId: string | number;
}

export const createTeamMember = ({
  email,
  memberRole,
  merchantId,
}: CreateMemberTypes) => {
  return customInstance({
    url: `/accounts/${merchantId}/members`,
    method: "POST",
    data: {
      email: email,
      memberRole: memberRole,
      inviteMember: true,
    },
  });
};
