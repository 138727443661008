import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { QKEY_GET_CHANGELOG_CATEGORIES } from "@constants/queryKeys";
import { useMemo, useState } from "react";

type CategoryType = {
  id: number;
  name: string;
  DisplayName: string;
  accID: number;
};

type QueryReturnType = {
  total: number;
  data: CategoryType[] | null;
};

const useListCategories = ({ merchantId }: { merchantId: number }) => {
  const [searchValue, setSearchValue] = useState("");

  const getChangelogCategories = () => {
    return customInstance({
      url: `/merchants/${merchantId}/changelogs/categories`,
      method: "GET",
    });
  };

  const { data, isLoading } = useQuery<QueryReturnType>(
    [QKEY_GET_CHANGELOG_CATEGORIES],
    async () => {
      return await getChangelogCategories();
    },
    {
      enabled: !!merchantId,
      refetchOnWindowFocus: false,
    },
  );
  const formattedData = useMemo(() => {
    return (
      data?.data?.map((category: CategoryType) => ({
        label: category.DisplayName,
        value: category.DisplayName,
        id: category.id,
      })) ?? []
    );
  }, [data]);
  const filteredData = useMemo(
    () =>
      formattedData.filter((item) =>
        item?.label?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue, formattedData],
  );

  return { data: filteredData, isLoading, handleSearch: setSearchValue };
};

export default useListCategories;
