import React from "react";

interface IconProps {
  height?: number;
  width?: number;
  color?: string;
}

const Icon = ({ color = "#A9AFBD", height = 24, width = 24 }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.273 5a2.455 2.455 0 100 4.91 2.455 2.455 0 000-4.91zM5.818 7.455a3.455 3.455 0 116.91 0 3.455 3.455 0 01-6.91 0zm8.41-2.955a.5.5 0 01.5-.5 3.455 3.455 0 010 6.91.5.5 0 110-1 2.455 2.455 0 000-4.91.5.5 0 01-.5-.5zm-5.114 9.09a.5.5 0 01.5-.5 4.932 4.932 0 014.931 4.933A1.977 1.977 0 0112.569 20h-6.59A1.977 1.977 0 014 18.023a4.92 4.92 0 011.577-3.616.5.5 0 11.68.733A3.92 3.92 0 005 18.023c0 .54.438.977.977.977h6.591c.54 0 .978-.438.978-.977a3.932 3.932 0 00-3.932-3.932.5.5 0 01-.5-.5zm5.34 0a.5.5 0 01.5-.5h.114A4.932 4.932 0 0120 18.024 1.977 1.977 0 0118.023 20h-1.477a.5.5 0 110-1h1.477c.54 0 .977-.438.977-.977a3.932 3.932 0 00-3.932-3.932h-.114a.5.5 0 01-.5-.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Icon;
