import { ExportIcon } from "@assets/rebrandIcons";
import { Button } from "@common/Button";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { EXPORT_VIEW_PANEL } from "modals/modal_names";
import FilterButton from "@common/TableFilters/FilterButton";
import { Switch_V2 as Switch } from "@common/Switch";
import { useAppSelector } from "@redux/hooks";
import {
  setWatchlistFilters,
  watchListfilter as watchListFilterReducer,
} from "@redux/slices/fundraisers";
import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import {
  selectMerchantStatusName,
  setMerchantStatusName,
} from "@redux/slices/enterprise/merchants";
import { Text } from "@common/Text";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { StatusNames } from "@components/Merchants/MerchantList/MerchantStatusFilters";
import { ExportRecordsTotal } from "@common/Table/helpers";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

type Props = {
  totalRows?: ExportRecordsTotal | number;
  hideTableView?: boolean;
};

const ExportFilterButtons = ({
  totalRows = 0,
  hideTableView = false,
}: Props) => {
  const filteredWatchlist = useAppSelector(watchListFilterReducer);
  const dispatch = useDispatch();
  const { isDesktopView } = useCustomTheme();
  const statusName = useAppSelector(selectMerchantStatusName);

  const handleWatchlistFilter = useCallback(() => {
    if (filteredWatchlist) {
      dispatch(setWatchlistFilters(""));
    } else {
      dispatch(setMerchantStatusName(""));
      dispatch(setWatchlistFilters("watchlist:true"));
    }
  }, [filteredWatchlist]);

  const currentTab = useMemo(() => {
    const underwritingStatus = !hideTableView
      ? StatusNames.aquirerUnderwriting
      : StatusNames.enterpriseUnderwriting;
    const riskOrApprovedStatus = !hideTableView
      ? StatusNames.aquirerRisk
      : StatusNames.enterpriseApproved;

    switch (statusName) {
      case underwritingStatus:
        return "underwriting";
      case riskOrApprovedStatus:
        return "risk";
      default:
        return "portfolio";
    }
  }, [statusName]);

  const handleOpenPanel = () => {
    NiceModal.show(EXPORT_VIEW_PANEL, {
      totalRows:
        typeof totalRows === "number" ? totalRows : totalRows[currentTab],
      tab: currentTab,
    });
  };

  const isExportAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.LIST,
  });

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      {!isDesktopView && hideTableView && (
        <WatchlistSwitchContainer
          isFilteredWatchlist={filteredWatchlist !== ""}
        >
          <Switch
            data-testid="mobile-watchlist-only-switch"
            checked={filteredWatchlist !== ""}
            size="small"
            onChange={handleWatchlistFilter}
          />
          <WatchText>Watchlist only</WatchText>
        </WatchlistSwitchContainer>
      )}
      {!hideTableView && <FilterButton />}
      <StyledButton
        background="secondary"
        startIcon={<ExportIcon />}
        onClick={handleOpenPanel}
        disabled={!isExportAllowed}
        tooltipProps={{
          message: ACTION_DENY_MESSAGE,
          show: !isExportAllowed,
        }}
      >
        Export
      </StyledButton>
    </Stack>
  );
};

const StyledButton = styled(Button)(() => ({
  padding: "0 12px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.background.dimmedWhite,
  color: palette.neutral[70],
  fontWeight: 350,
  height: "32px",
  lineHeight: "16.8px",
  gap: "4px",
  "&:hover": {
    background: palette.background.dimmedWhite,
  },
}));

const WatchlistSwitchContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isFilteredWatchlist",
})<{ isFilteredWatchlist: boolean }>(({ isFilteredWatchlist }) => ({
  ...(isFilteredWatchlist && {
    "& .MuiSwitch-track": {
      backgroundColor: `${palette.filled.success} !important`,
    },
  }),
  alignItems: "center",
  height: "32px",
  display: "flex",
  flexDirection: "row",
  gap: "8px",
}));

const WatchText = styled(Text)(() => ({
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  color: palette.neutral[80],
}));

export default ExportFilterButtons;
