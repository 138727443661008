import { namespaces } from "../../../../resources/i18n.constants";

export const processing = {
  [namespaces.pages.enterprise.processing]: {
    t_processing: "Processing", //TODO: translate
    t_processed: "Processed", // TODO: translate
    t_available_balance: "Saldo disponible",
    t_approved_transactions: "Transacciones aprobadas",
    t_refunded_transactions: "Transacciones reembolsadas",
    t_chargeback_transactions: "Transacciones de devolución de cargo",
    t_transfer_money: "Transferir dinero",
  },
};
