import { BeforeMount, Editor, OnMount } from "@monaco-editor/react";
import { palette } from "@palette";
import { useRef, useState } from "react";
import codeValidator, { TValidator } from "./validators";
import { EditorContainer, ErrorMessage } from "./atoms";
import { BoxProps } from "@mui/material";
import ErrorCatcher from "@common/Error/ErrorCatcher";

interface ICodeEditorProps {
  defaultValue: string;
  customTheme?: any;
  customOptions?: any;
  language?: string;
  setValue: (newValue: string) => void;
  onValidation?: (isValid: boolean) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  customValidator?: TValidator;
  containerProps?: BoxProps;
}

const CodeEditor = ({
  defaultValue,
  customTheme = defaultTheme,
  language = "JSON",
  setValue,
  onValidation,
  onBlur,
  onFocus,
  customOptions,
  customValidator,
  containerProps,
}: ICodeEditorProps) => {
  const [error, setError] = useState<string>("");
  const editorRef = useRef<any>(null);

  const handleEditorBeforeMount: BeforeMount = (monaco: any) => {
    monaco.editor.defineTheme("custom-theme", customTheme);
  };

  function retrieveEditorValue() {
    if (!editorRef.current) return "";
    return editorRef.current.getValue();
  }

  const onValidationError = (err?: Error) => {
    if (!err) setError("");
    if (err?.message) setError(err.message);
  };

  const saveValue = () => {
    const value = retrieveEditorValue();

    if (value === "") {
      setError("");
      setValue("");
      return;
    }
    const isValid = customValidator
      ? customValidator(value, onValidationError)
      : codeValidator(language, value, onValidationError);

    if (onValidation) onValidation(isValid);
    setValue(value);
  };

  const handleEditorDidMount: OnMount = (editor, monaco) => {
    editorRef.current = editor;
    editorRef.current.onDidBlurEditorWidget(() => {
      saveValue();
      if (onBlur) onBlur();
    });
    editorRef.current.onDidFocusEditorWidget(() => {
      if (onFocus) onFocus();
    });
    monaco.editor.remeasureFonts();
  };

  return (
    <ErrorCatcher errorID="Code editor">
      <EditorContainer {...containerProps}>
        <Editor
          height="100%"
          defaultLanguage={language}
          value={defaultValue}
          theme="custom-theme"
          onMount={handleEditorDidMount}
          beforeMount={handleEditorBeforeMount}
          loading={<></>}
          options={{
            ...defaultOptions,
            ...customOptions,
          }}
        />
        {error && <ErrorMessage error={error} />}
      </EditorContainer>
    </ErrorCatcher>
  );
};

const defaultOptions = {
  fontSize: 14,
  fontFamily: "Oxygen Mono",
  fontWeight: 400,
  lineNumbers: "off",
  minimap: { enabled: false },
  glyphMargin: false,
  folding: false,
  lineNumbersMinChars: 0,
  lineDecorationsWidth: 0,
  quickSuggestions: false,
  overviewRulerLanes: 0,
  wordWrap: "on",
};

const defaultTheme = {
  base: "vs-dark",
  inherit: true,
  rules: [],
  colors: {
    "editor.background": palette.black[300],
    "editor.foreground": palette.liftedWhite[100],
  },
};

export default CodeEditor;
