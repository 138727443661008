import React from "react";

const UploadIconThick = ({ fill = "#6D9CF8" }: { fill?: string }) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8479 19.5002V26.5002C28.8479 27.1632 28.5845 27.7991 28.1157 28.268C27.6468 28.7368 27.0109 29.0002 26.3479 29.0002H6.3479C5.68486 29.0002 5.04897 28.7368 4.58013 28.268C4.11129 27.7991 3.8479 27.1632 3.8479 26.5002V19.5002C3.8479 19.1024 4.00594 18.7209 4.28724 18.4395C4.56854 18.1582 4.95008 18.0002 5.3479 18.0002C5.74573 18.0002 6.12726 18.1582 6.40856 18.4395C6.68987 18.7209 6.8479 19.1024 6.8479 19.5002V26.0002H25.8479V19.5002C25.8479 19.1024 26.0059 18.7209 26.2872 18.4395C26.5685 18.1582 26.9501 18.0002 27.3479 18.0002C27.7457 18.0002 28.1273 18.1582 28.4086 18.4395C28.6899 18.7209 28.8479 19.1024 28.8479 19.5002ZM12.4092 11.5615L14.8479 9.12521V19.5002C14.8479 19.898 15.0059 20.2796 15.2872 20.5609C15.5685 20.8422 15.9501 21.0002 16.3479 21.0002C16.7457 21.0002 17.1273 20.8422 17.4086 20.5609C17.6899 20.2796 17.8479 19.898 17.8479 19.5002V9.12521L20.2866 11.5652C20.5684 11.847 20.9506 12.0053 21.3491 12.0053C21.7477 12.0053 22.1299 11.847 22.4116 11.5652C22.6934 11.2834 22.8518 10.9012 22.8518 10.5027C22.8518 10.1042 22.6934 9.722 22.4116 9.44021L17.4116 4.44021C17.2723 4.30037 17.1067 4.18941 16.9244 4.1137C16.7421 4.03799 16.5466 3.99902 16.3491 3.99902C16.1517 3.99902 15.9563 4.03799 15.7739 4.1137C15.5916 4.18941 15.426 4.30037 15.2867 4.44021L10.2867 9.44021C10.1471 9.57974 10.0364 9.74538 9.96093 9.92768C9.88541 10.11 9.84655 10.3054 9.84655 10.5027C9.84655 10.9012 10.0049 11.2834 10.2867 11.5652C10.5684 11.847 10.9506 12.0053 11.3492 12.0053C11.7477 12.0053 12.1299 11.847 12.4117 11.5652L12.4092 11.5615Z"
        fill={fill}
      />
    </svg>
  );
};

export default UploadIconThick;
