import React from "react";
import { useAppSelector } from "@redux/hooks";
import { selectMasqueradeMode } from "@redux/slices/app";
import { useLocation, useNavigate } from "react-router-dom";

const useMasqueradeRedirect = () => {
  const { name, origin } = useAppSelector(selectMasqueradeMode);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let redirectPath = "";
  const isEnterprisePortal =
    origin === "/acquirer/providers" && pathname.includes("/provider/");

  if (origin === "/acquirer/providers") {
    redirectPath = "/provider/processing";
  } else {
    redirectPath = "/merchant/manage-money";
  }

  React.useEffect(() => {
    if (name && !isEnterprisePortal) {
      navigate(redirectPath);
    }
  }, []);
};

export default useMasqueradeRedirect;
