import { Theme, CSSObject } from "@mui/material/styles";
import { DRAWER_OPEN_WIDTH, DRAWER_CLOSED_WIDTH } from "constants/constants";

export const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_OPEN_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const closedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_CLOSED_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const drawerOpenedMixin = (theme: Theme): CSSObject => ({
  width: `${DRAWER_OPEN_WIDTH}px`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  [theme.breakpoints.up("md")]: {
    width: `${DRAWER_OPEN_WIDTH}px`,
  },
});

export const drawerClosedMixin = (theme: Theme): CSSObject => ({
  width: `${DRAWER_CLOSED_WIDTH}px`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [theme.breakpoints.up("md")]: {
    width: `${DRAWER_CLOSED_WIDTH}px`,
  },
});

export const drawerButtonOpenedMixin = (theme: Theme): CSSObject => ({
  transform: "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
});
