import { useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleLibraries: Libraries = ["places"];

function useLoadGoogleScript() {
  const language = "US";
  const region = "en-US";

  const { isLoaded } = useJsApiLoader({
    id: "google-maps",
    region,
    language,
    libraries: googleLibraries,
    googleMapsApiKey: GOOGLE_MAPS_API_KEY || "not_loaded",
  })

  return { isLoaded };
}

export default useLoadGoogleScript;
