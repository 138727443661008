import * as React from "react";
import { Collapse, Divider } from "@mui/material";
import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// components
import { Text } from "@common/Text";
import { DropdownItem } from "@common/Select";
import { IconButton } from "@common/IconButton";
import {
  MakeRecurring,
  ModifyRecurring,
  Refund,
  SendReceipt,
} from "@components/ManageMoney/TransactionTable/TableActions";
// assets
import { ArrowDropdownIcon, MoreIcon } from "@assets/icons";
import MobileTransactionExtraPurchaseInfo from "./MobileTransactionExtraPurchaseInfo";
import MobileTransactionAmountsCard from "./MobileTransactionAmountsCard";
import { Button } from "@common/Button";

type PurchaseProps = {
  description: string;
  title: string;
  type: string;
  recurrence: string;
  donationAmount: number;
  actionsHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, recurrence: string) => void;
};

const MobileTransactionPurchaseCard = ({
  description,
  title,
  type,
  recurrence,
  donationAmount,
  actionsHandler
}: PurchaseProps) => {
  const [openRow, setOpenRow] = React.useState(false);

  return (
    <div>
      <Collapse in={!openRow}>
        <Box sx={StyledCard}>
          <Box display="flex" justifyContent="space-between">
            <Box onClick={() => setOpenRow(prevState => !prevState)}>
              <Text variant="body" color={palette.neutral[600]}>
                {description}
              </Text>
              <Text
                variant="headline"
                color={palette.neutral[800]}
                fontWeight="medium"
                pb="4px"
              >
                {title}
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => setOpenRow(prevState => !prevState)}
          >
            <Box>
              <Text
                variant="headline"
                color={palette.neutral[800]}
                fontWeight="medium"
              >
                {type}
              </Text>
              <Text
                variant="body"
                textTransform="capitalize"
                color={palette.neutral[600]}
              >
                {recurrence}
              </Text>
            </Box>
            <Text
              variant="body"
              color={palette.neutral[800]}
              fontWeight="medium"
            >
              {donationAmount}{" "}
              <Box component="sup" fontSize="9px" lineHeight="9px">
                USD
              </Box>
            </Text>
          </Box>
        </Box>
      </Collapse>
      <Collapse in={openRow}>
        <Box sx={StyledCard}>
          <Box display="flex" justifyContent="space-between">
            <Box onClick={() => setOpenRow(prevState => !prevState)}>
              <Text variant="body" color={palette.neutral[600]}>
                {description}
              </Text>
              <Text
                variant="headline"
                color={palette.neutral[800]}
                fontWeight="medium"
                pb="4px"
              >
                {title}
              </Text>
            </Box>
          </Box>
          <Divider sx={dividerStyle} />
          <Button
            variant="primary"
            endIcon={<MoreIcon />}
            fullWidth
            sx={{ mb: "16px" }}
            onClick={(e) => actionsHandler(e, recurrence)}
          >
            Actions
          </Button>
          <MobileTransactionExtraPurchaseInfo
            Type="Charitable donation"
            Recurrence={recurrence}
          />
          <MobileTransactionAmountsCard amount />
          <Text
            fontSize={12}
            textAlign="center"
            color={palette.neutral[600]}
            mb={"10px"}
          >
            *Visa fee deducted from donation
          </Text>
        </Box>
      </Collapse>
    </div>
  );
};

const StyledCard = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#FFFFFF",
  border: "2px solid #EADDFD",
  boxShadow:
    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  borderRadius: "8px",
  padding: "12px 8px",
};

const dividerStyle = {
  my: "12px",
  width: "calc(100% + 16px)",
  transform: "translateX(-8px)",
};


export default MobileTransactionPurchaseCard;
