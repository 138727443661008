interface IProps {
  height?: number;
  width?: number;
  fill?: string;
  fillOpacity?: string;
}
const DropdownIcon = ({
  height = 24,
  width = 18,
  fill = "#4C4A52",
  fillOpacity = "1",
}: IProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 41 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.6211 1.49405C36.7271 0.387991 38.5204 0.387991 39.6265 1.49405C40.7325 2.60011 40.7325 4.39339 39.6265 5.49945L35.6211 1.49405ZM20.6303 20.4902L22.633 22.4929L20.6303 24.4956L18.6276 22.4929L20.6303 20.4902ZM1.63412 5.49946C0.528056 4.39339 0.528056 2.60012 1.63412 1.49405C2.74018 0.387992 4.53346 0.387992 5.63952 1.49405L1.63412 5.49946ZM39.6265 5.49945L22.633 22.4929L18.6276 18.4875L35.6211 1.49405L39.6265 5.49945ZM18.6276 22.4929L1.63412 5.49946L5.63952 1.49405L22.633 18.4875L18.6276 22.4929Z"
      fill={fill}
      fillOpacity={fillOpacity}
    />
  </svg>
);
export default DropdownIcon;
