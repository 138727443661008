import { Box, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Grid from "@mui/material/Grid";
import NewStatsTitle from "@common/NewStats/NewStatsTitle";
import { StatCardProps, ViewAnalyticsLink } from "./NewStatCard";
import { parseAmount } from "@utils/index";

const NewMobileStatCard = ({
  mainStat,
  stats,
  actions,
  onOpenAnalytics,
  AnalyticsIcon,
  mainStatTitleProps,
  statTextProps,
  statValueProps,
  labelProps,
  color,
}: StatCardProps) => {
  return (
    <Stack gap={2} alignItems="stretch">
      <StyledContainer>
        <NewStatsTitle
          margin="auto"
          value={mainStat.value}
          isAmount={mainStat.isAmount}
          fontSize={32}
          labelStyle={{ fontSize: 24 }}
          isMainTitle
          color={color}
          currencyVisible={false}
        ></NewStatsTitle>
        <MainStatTitle {...mainStatTitleProps}>
          {mainStat.title}
          {mainStat.isAmount && <span> (USD)</span>}
        </MainStatTitle>
      </StyledContainer>
      {onOpenAnalytics && (
        <ViewAnalyticsLink
          labelProps={labelProps}
          onClick={onOpenAnalytics}
          Icon={AnalyticsIcon}
        />
      )}
      <Stack spacing={1}>
        {stats.map(({ isAmount, title, value, percent }, index) => {
          return (
            <Grid
              key={index}
              container
              spacing={1}
              justifyContent="space-between"
              sx={{ m: 0, pr: 1 }}
            >
              <StatText {...statTextProps}>
                {title}
                {percent && ` (\u0025)`}
                {isAmount && " (USD)"}
              </StatText>
              <StatValue {...statValueProps}>
                {isAmount || percent ? parseAmount(value) : value}
              </StatValue>
            </Grid>
          );
        })}
      </Stack>
      {actions}
    </Stack>
  );
};

export default NewMobileStatCard;

const StatText = styled(Text)(() => ({
  fontSize: 14,
  fontWeight: 350,
  lineHeight: "16.8px",
  color: palette.neutral[70],
}));

const StatValue = styled(Text)(() => ({
  fontSize: 18,
  fontWeight: 300,
  lineHeight: "16.8px",
  color: palette.neutral[80],
}));

const MainStatTitle = styled(Text)(() => ({
  fontSize: 18,
  fontWeight: 400,
  lineHeight: "16.8px",
  textAlign: "center",
  color: palette.neutral[80],
}));

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
