import { globalColors } from "../signals";

export const getColorsMap = (colorsList: any[]) => {
  return colorsList?.reduce((acc: any, v: any) => {
    return { ...acc, [v.name]: v };
  }, {});
};

export const useColors = ({ withGradients }: { withGradients: boolean }) => {
  const isTest = true;
  const generalColorsMap = globalColors.general.peek();
  const customColorsMap = globalColors.custom.peek();

  const filteredGeneralColorsMap = withGradients
    ? generalColorsMap
    : Object.fromEntries(
        Object.entries(generalColorsMap).filter(
          ([key, value]: [key: any, value: any]) => value.hexes.length <= 1,
        ),
      );

  const filteredCustomColorsMap = withGradients
    ? customColorsMap
    : Object.fromEntries(
        Object.entries(customColorsMap).filter(
          ([key, value]: [key: any, value: any]) => value.hexes.length <= 1,
        ),
      );

  if (isTest) {
    return {
      colors: {
        ...filteredGeneralColorsMap,
        ...filteredCustomColorsMap,
      },
      colorsGroupedLists: new Map([
        ["general", Object.values(globalColors.general.value)],
        [
          "custom",
          Object.values(globalColors.custom.value).sort(
            (a: any, b: any) => a.addedAt - b.addedAt,
          ),
        ],
      ]),
      groups: ["General Colors", "Custom Colors"],
      groupedColorsKeys: {
        "General Colors": Object.keys(filteredGeneralColorsMap),
        "Custom Colors": Object.keys(filteredCustomColorsMap),
      },
    };
  }

  return {
    colors: { generalColorsMap: {}, customColorsMap: {} },
    groups: ["General Colors", "Custom Colors"],
    groupedColorsKeys: {
      "General Colors": [],
      "Custom Colors": [],
    },
    c: {
      general: [],
      custom: [],
    },
  };
};
