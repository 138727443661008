import UnfoldListItems from "@components/animation/UnfoldListItems";
import { Box } from "@mui/material";
import { Skeleton, Stack } from "@mui/material";

const ChallengeActionsSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Skeleton
        variant="rectangular"
        width={100}
        height={32}
        sx={{ fontSize: "14px", borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangular"
        width={100}
        height={32}
        sx={{ fontSize: "14px", borderRadius: "8px" }}
      />
    </Stack>
  );
};

const ChallengeHeaderSkeleton = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Stack direction={"row"} gap={2} alignItems="center">
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton
          variant="rectangular"
          width={200}
          height={21}
          sx={{ fontSize: "14px", borderRadius: "8px" }}
        />

        <Skeleton
          variant="rectangular"
          width={50}
          height={21}
          sx={{ fontSize: "14px", borderRadius: "8px" }}
        />
      </Stack>
    </Stack>
  );
};

const ChallengeBodySkeleton = () => {
  return (
    <Box
      sx={{
        padding: "8px 16px",
        backgroundColor: "#F7F7F7",
        borderRadius: "8px",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={60}
        sx={{ borderRadius: "8px" }}
      />
    </Box>
  );
};

const ChallengeLayoutSkeleton = ({length = 3}: {length?: number}) => {
  const skeletonItems = Array.from({ length }, (_, i) => ({
    id: `skeleton-${i}`,
  }));

  return (
    <UnfoldListItems
      items={skeletonItems}
      renderKey={(row, idx) => row.id}
      height={60}
      animationName="waterfall"
      ItemComponent={(row, idx, height) => (
        <Stack
          direction="column"
          data-testid={`challenge-skeleton-${idx}`}
          sx={{
            padding: "8px 0px",
          }}
          gap={1.5}
        >
          <Stack
            alignItems="flex-start"
            gap={1}
            sx={{
              maxWidth: "100%",
              padding: "16px",
              backgroundColor: "#FFF",
              boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
              borderRadius: "16px",
            }}
          >
            <ChallengeHeaderSkeleton />

            <Stack paddingLeft={5} sx={{ gap: "16px", width: "100%" }}>
              <ChallengeBodySkeleton />
              <ChallengeActionsSkeleton />
            </Stack>
          </Stack>
        </Stack>
      )}
    />
  );
};

export default ChallengeLayoutSkeleton;
