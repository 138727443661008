import React from "react";
import { MoreActionButton } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/MoreActionButton";
import { Grid, IconButton, Stack } from "@mui/material";
import { palette } from "@palette";
import { makeStyles } from "@mui/styles";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { Button } from "@common/Button";
import { Box } from "@mui/material";
import { MerchantPreviewHeaderAction } from "./hooks/useMerchantPreview";
import { FEATURE_DENY_MESSAGE } from "@constants/permissions";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import Xicon from "@assets/icons/Xicon";
import { EditMerchantHeaderHandlers } from "./helpers/types";

interface Props {
  nextPrevState: {
    isLast: boolean;
    isFirst: boolean;
  };
  actions: MerchantPreviewHeaderAction[];
  handlers: EditMerchantHeaderHandlers;
  hasAlert: boolean;
  isControlModeAllowed: boolean;
  isRiskMonitorAllowed: boolean;
}

const MerchantPreviewHeader = ({
  nextPrevState,
  actions,
  handlers,
  hasAlert,
  isControlModeAllowed,
  isRiskMonitorAllowed,
}: Props) => {
  const {
    handleControlMode,
    handleClose,
    handleNext,
    handlePrevious,
    displayRowHandlers,
    handleOpenRiskProfile,
  } = handlers;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { risk_monitoring, control_mode } = useEnterprisePermissions();

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const ButtonComponent = {
    prev: (
      <Button
        className={`${classes.iconBtn} ${classes.iconBtnText}`}
        onClick={handlePrevious}
        disabled={nextPrevState.isFirst}
      >
        Previous
      </Button>
    ),
    next: (
      <Button
        className={`${classes.iconBtn} ${classes.iconBtnText}`}
        onClick={handleNext}
        disabled={nextPrevState.isLast}
      >
        Next
      </Button>
    ),
    riskMonitor: (
      <>
        {risk_monitoring && isRiskMonitorAllowed && (
          <WithAlertWrapper active={hasAlert}>
            <Button
              className={`${classes.iconBtn} ${classes.iconBtnText}`}
              onClick={handleOpenRiskProfile}
              data-testid="risk-monitor-button"
            >
              Risk Monitor
            </Button>
          </WithAlertWrapper>
        )}
      </>
    ),
    controlMode: (
      <Button
        className={`${classes.iconBtn} ${classes.iconBtnText}`}
        onClick={handleControlMode}
        disabled={!isControlModeAllowed}
        data-testid="control-mode-button"
        tooltipProps={{
          show: !isControlModeAllowed,
          message: FEATURE_DENY_MESSAGE,
        }}
      >
        Control mode
      </Button>
    ),
  };

  return (
    <>
      <Stack className={`${classes.container}`}>
        <Grid container alignItems="center" gap="8px">
          {displayRowHandlers && (
            <>
              {ButtonComponent["prev"]}
              {ButtonComponent["next"]}
            </>
          )}
        </Grid>
        <Stack className={`${classes.mainActionsContainer}`}>
          {ButtonComponent["riskMonitor"]}
          {control_mode && ButtonComponent["controlMode"]}
          {actions.filter((x) => !x.hidden).length && (
            <MoreActionButton onClick={onOpenMenu} />
          )}
          <Stack direction="row" gap="16px">
            <Box className={`${classes.verticalDivider}`} />
            <IconButton
              data-testid="close-panel-button"
              onClick={handleClose}
              className={classes.iconBtn}
            >
              <Xicon width={15} height={15} stroke={palette.gray[300]} />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
        sx={{
          "& .MuiPaper-root": {
            width: 236,
          },
        }}
      />
    </>
  );
};

const WithAlertWrapper = ({
  active,
  children,
}: {
  active: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {active && (
        <Box
          sx={{
            background: "#FF5924",
            borderRadius: "50%",
            width: "8px",
            height: "8px",
          }}
        />
      )}
      {children}
    </Stack>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  verticalDivider: {
    width: 1,
    height: "22px",
    borderLeft: `1px solid ${palette.neutral[30]}`,
  },
  mainActionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
  },
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    padding: "5px 5px",
    height: "max-content",
    "&:hover": {
      background: palette.neutral[20],
      boxShadow: "none",
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:focus": {
      background: "transparent",
      boxShadow: "none",
    },
    "&:active": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    color: palette.neutral[80],
    fontWeight: 400,
    lineHeight: "16.8px",
    whiteSpace: "nowrap",
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
      boxShadow: "none",
    },
  },
}));

export default MerchantPreviewHeader;
