import * as React from "react";
import { CardType } from "./components/PaymentTypeCard";

type FormInput = {
  [key: string]: any;
};

type DateAndLocationType = {
  location: any;
  date: {
    type: any;
  };
};

type PaymentStatusType = {
  title: string;
  amount: string;
  thumbnail: string;
  description:
    | {
        enabled: boolean;
        text: string;
      }
    | string;
  active: boolean;
  id?: string;
  min_max?: {
    enabled: boolean;
    min: string;
    max: string;
  };
};
export type MerchantDataType = {
  campaignType: {
    type: string;
  };
  campaignDetails: {
    title: string;
    description: string;
    featuredImage: boolean;
    isBackgroud: boolean;
    browse_more: boolean;
    startDate: string;
    endDate: string;
  };
  date_and_location: DateAndLocationType;
  paymentSetup: PaymentStatusType[];
  paymentTypes: {
    selectedArray: CardType[];
    isCreditCardFee: boolean;
    isOptional: boolean;
  };
};

type Title =
  | "campaignType"
  | "campaignDetails"
  | "paymentSetup"
  | "paymentTypes"
  | "date_and_location";

export type FormContextType = {
  formData: FormInput;
  setFormValues: (name: Title, values: FormInput) => void;
  initialPaymentSetup?: PaymentStatusType[];
  resetFormValues: () => void;
};

const campaignDetailsStartValues = {
  title: "",
  description: "",
  featuredImage: true,
  isBackgroud: false,
  browse_more: false,
  endDate: "",
  startDate: "",
};

const dateAndLocation = {
  date: {
    type: "one day",
    day: null,
    include_time: false,
    startDate: null,
    endDate: null,
  },
  location: {
    showMap: false,
    type: "in person",
    location: "",
    manual: false,
    country: "US",
    address: "",
    state: "",
    city: "",
    zip: "",
    event_url: "",
  },
};

const initialPaymentSetup = [
  {
    title: "Any amount",
    amount: "",
    thumbnail: "",
    description: "",
    active: true,
    id: "01",
    isDefault: true,
    min_max: {
      enabled: false,
      min: "1.00",
      max: "25,000.00",
    },
  },
];

export const FormContext = React.createContext<FormContextType | null>(null);

const CreateCampaignFormProvider = ({
  children,
  data,
}: {
  data?: MerchantDataType;
  children: React.ReactNode;
}) => {
  const initFormData = {
    ...(data ? data : defaultValues),
    date_and_location: dateAndLocation,
  };
  const [formData, setFormData] = React.useState<MerchantDataType>(initFormData);

  const resetFormValues = () => setFormData(initFormData);

  const setFormValues = (name: Title, values: FormInput) => {
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: Array.isArray(values) ? [...values] : { ...values },
    }));
  };

  const provider = {
    resetFormValues,
    formData,
    setFormValues,
    initialPaymentSetup,
  };

  return (
    <FormContext.Provider value={provider}>
      {children}
    </FormContext.Provider>
  );
};

export default CreateCampaignFormProvider;

export const useFormData = () =>
  React.useContext(FormContext) as FormContextType;

const defaultValues = {
  campaignType: {
    type: "",
  },
  campaignDetails: campaignDetailsStartValues,
  date_and_location: dateAndLocation,
  paymentSetup: [],
  paymentTypes: {
    selectedArray: [],
    isCreditCardFee: false,
    isOptional: false,
  },
};