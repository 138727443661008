import useCountryCodes from "@hooks/common/useCountryCodes";
import { RHFCheckbox } from "@common/Checkbox";
import { useFormContext } from "react-hook-form";
import { palette } from "@theme/colors";
import { RHFSelect } from "@common/Select";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

type Props = {
  inputsDisabled?: boolean;
  namePrefix?: string;
  isBusinessOwner?: boolean;
  hasTooltip?: boolean;
  show?: boolean;
  tooltipMessage?: string;
};

export const NonResidentInputs = ({
  inputsDisabled = false,
  namePrefix,
  isBusinessOwner,
  hasTooltip = false,
  show = false,
  tooltipMessage = "",
}: Props) => {
  const { selectCountriesList } = useCountryCodes();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const vals = watch();
  const { isNotUSResident, isNotResidentInCitizenshipCountry } = namePrefix
    ? vals[namePrefix]
    : vals;

  const errorObj = namePrefix ? errors[namePrefix] : errors;
  const countryOfResidenceError = (errorObj as any)?.countryOfResidence;

  const getName = (name: string) => {
    return namePrefix ? `${namePrefix}.${name}` : name;
  };

  useEffect(() => {
    if (!isNotUSResident) {
      setValue(getName("citizenship"), "", { shouldDirty: true });
      setValue(getName("isNotResidentInCitizenshipCountry"), false, {
        shouldDirty: true,
      });
      setValue(getName("countryOfResidence"), "", {
        shouldDirty: true,
      });
    }
  }, [isNotUSResident]);

  useEffect(() => {
    if (!isNotResidentInCitizenshipCountry) {
      setValue(getName("countryOfResidence"), "", { shouldDirty: true });
    }
  }, [isNotResidentInCitizenshipCountry]);

  const isNotUSResidentLabel = isBusinessOwner
    ? "Non-resident of the United States"
    : "I am a non-resident of the United States";
  const residenceDifferentLabel = isBusinessOwner
    ? "Resident of a different country than citizenship"
    : "I am a resident of a country different from my country of citizenship";

  const inputs = [
    {
      component: (
        <RHFCheckbox
          disabledStyles={{
            stroke: palette.neutral[40],
            "stroke-width": 1.6,
          }}
          name={getName("isNotUSResident")}
          dataTestId="is_us_non_resident"
          label={isNotUSResidentLabel}
          disabled={inputsDisabled}
        />
      ),
    },
    {
      component: (
        <RHFSelect
          name={getName("citizenship")}
          label="Country of Citizenship"
          placeholder="Country of Citizenship"
          options={selectCountriesList}
          helperText={"Please Select Your Country of Citizenship"}
          disabled={inputsDisabled}
        />
      ),
      hidden: !isNotUSResident,
    },
    {
      component: (
        <RHFCheckbox
          name={getName("isNotResidentInCitizenshipCountry")}
          dataTestId="is_residence_different"
          label={residenceDifferentLabel}
          disabled={inputsDisabled}
        />
      ),
      hidden: !isNotUSResident,
    },
    {
      component: (
        <RHFSelect
          name={getName("countryOfResidence")}
          label="Country of Residence"
          placeholder="Country of Residence"
          options={selectCountriesList}
          helperText={
            countryOfResidenceError
              ? countryOfResidenceError?.message
              : "Please Select Your Country of Residence"
          }
          disabled={inputsDisabled}
        />
      ),
      hidden: !isNotUSResident || !isNotResidentInCitizenshipCountry,
    },
  ];

  return (
    <>
      {inputs
        .filter((x) => !x.hidden)
        .map(({ component }, index) => (
          <Grid
            sx={{
              "& .MuiTypography-body": {
                paddingY: "5px",
              },
            }}
            key={`key-${index}`}
            item
            xs={12}
          >
            <WithTooltipWrapper
              hasTooltip={!!hasTooltip}
              tooltipProps={{
                show: show,
                message: tooltipMessage,
              }}
            >
              {component}
            </WithTooltipWrapper>
          </Grid>
        ))}
    </>
  );
};
