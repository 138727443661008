import { Stack } from "@mui/material";
import { getAmountFormatAndStyle } from "../transactions.helpers";
import { TransactionTableRow } from "../TransactionTable";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { toEnFormat } from "@utils/index";

type Props = {
  row: TransactionTableRow;
  isEndingBalance?: boolean;
};

function BalanceCell({ row, isEndingBalance = false }: Props) {
  const { amount, style } = getAmountFormatAndStyle(row);
  const isGreyEndingBalance =
    row?.displayStatus === "Pending" ||
    row?.displayStatus === "Voided" ||
    row?.displayStatus === "Authorized";

  return (
    <Stack alignItems="flex-end" marginLeft="auto" maxWidth="200px">
      <Text fontWeight="regular" width="fit-content" {...style}>
        {amount}
      </Text>
      {isEndingBalance && (
        <Text
          fontSize={12}
          color={
            isGreyEndingBalance ? palette.gray[200] : palette.neutral["70"]
          }
        >
          {toEnFormat(row.endingBalance)}
        </Text>
      )}
    </Stack>
  );
}

export default BalanceCell;
