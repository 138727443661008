import { useStateEffect } from "@hooks/customReactCore";
import { Drawer, DrawerProps, Grid } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { CSSProperties } from "react";
import { useLocation } from "react-router-dom";

export interface SidePanelProps extends DrawerProps {
  onCloseDrawer?: () => void;
  children: React.ReactNode;
  width?: number | string | null;
  paperStyle?: CSSProperties | undefined;
}

export const SidePanel = ({
  onCloseDrawer,
  children,
  width,
  paperStyle,
  ...props
}: SidePanelProps) => {
  const { isDesktopView } = useCustomTheme();
  const location = useLocation();

  useStateEffect(() => {
    if (onCloseDrawer) onCloseDrawer();
  }, [location.pathname]);

  return (
    <Drawer
      {...props}
      SlideProps={props?.SlideProps as any}
      PaperProps={{
        ...props?.PaperProps,

        style: {
          width: width ? width : isDesktopView ? "620px" : "100%",
          boxShadow: isDesktopView
            ? "-4px 0px 20px 0px rgba(0, 0, 0, 0.05)"
            : "none",
          background: palette.background.dimmedWhite,
          overflow: "visible",
          ...paperStyle,
          ...props?.PaperProps?.style,
        },
      }}
      slotProps={{
        ...props?.slotProps,
        backdrop: {
          sx: {
            background: "rgba(53, 53, 53, 0.10)",
          },
          ...props?.slotProps?.backdrop,
        },
      }}
      anchor="right"
      onClose={onCloseDrawer}
    >
      <Grid
        container
        flexDirection={"column"}
        style={{
          overflow: "hidden",
          height: "100%",
          flexWrap: "nowrap",
        }}
      >
        {children}
      </Grid>
    </Drawer>
  );
};
