import { CloseButton } from "@common/Button/Button";
import { Text } from "@common/Text";
import { CloseIcon } from "@assets/rebrandIcons";
import { palette } from "@palette";
import { Box, SxProps, styled } from "@mui/material";
import { Stack } from "@mui/material";

type THeaderTitleComponent = {
  title: string | React.ReactNode;
  description?: string;
  onClick: () => void;
  isMobile: boolean;
  sx?: SxProps;
};

const HeaderTitleComponent = (props: THeaderTitleComponent) => {
  const { title, onClick, description, isMobile } = props;
  return (
    <HeaderSection
      sx={{
        ...props.sx,
      }}
    >
      <Stack
        sx={{
          ...(isMobile && {
            width: "100%",
          }),
        }}
      >
        {typeof title === "string" ? (
          <Text color={palette.neutral[750]} fontSize="18px">
            {title}
          </Text>
        ) : (
          title
        )}
        <Text fontSize="14px" color={palette?.neutral[70]} paddingTop={"8px"}>
          {description}
        </Text>
      </Stack>
      {!isMobile && (
        <CloseButton
          style={{ alignSelf: "flex-start" }}
          background="tertiary"
          onClick={onClick}
          data-testid="x-close-btn"
        >
          <CloseIcon height={24} width={24} color={palette.black.main} />
        </CloseButton>
      )}
    </HeaderSection>
  );
};

const HeaderSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export default HeaderTitleComponent;
