type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const CardholderIcon = ({
  width = 25,
  height = 25,
  stroke = "#8F8F8F",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M21.3203 7.01953V11.5195H15.9944C15.8216 11.5197 15.6543 11.5795 15.5205 11.6888C15.3868 11.7982 15.2949 11.9503 15.2603 12.1195C15.1226 12.7977 14.7547 13.4074 14.2189 13.8454C13.6831 14.2833 13.0123 14.5225 12.3203 14.5225C11.6283 14.5225 10.9576 14.2833 10.4218 13.8454C9.88595 13.4074 9.51802 12.7977 9.38031 12.1195C9.34577 11.9503 9.25386 11.7982 9.12011 11.6888C8.98637 11.5795 8.81899 11.5197 8.64625 11.5195H3.32031V7.01953C3.32031 6.62171 3.47835 6.24018 3.75965 5.95887C4.04096 5.67757 4.42249 5.51953 4.82031 5.51953H19.8203C20.2181 5.51953 20.5997 5.67757 20.881 5.95887C21.1623 6.24018 21.3203 6.62171 21.3203 7.01953Z"
        fill={stroke}
      />
      <path
        d="M19.8203 4.76953H4.82031C4.22358 4.76953 3.65128 5.00658 3.22932 5.42854C2.80737 5.8505 2.57031 6.42279 2.57031 7.01953V17.5195C2.57031 18.1163 2.80737 18.6886 3.22932 19.1105C3.65128 19.5325 4.22358 19.7695 4.82031 19.7695H19.8203C20.417 19.7695 20.9893 19.5325 21.4113 19.1105C21.8333 18.6886 22.0703 18.1163 22.0703 17.5195V7.01953C22.0703 6.42279 21.8333 5.8505 21.4113 5.42854C20.9893 5.00658 20.417 4.76953 19.8203 4.76953ZM4.07031 9.26953H20.5703V10.7695H15.9944C15.6486 10.7697 15.3135 10.8894 15.0458 11.1082C14.7781 11.327 14.5942 11.6316 14.5253 11.9705C14.4202 12.4771 14.1438 12.932 13.7425 13.2586C13.3412 13.5853 12.8396 13.7636 12.3222 13.7636C11.8048 13.7636 11.3032 13.5853 10.9019 13.2586C10.5006 12.932 10.2241 12.4771 10.1191 11.9705C10.05 11.631 9.86557 11.3259 9.5971 11.107C9.32863 10.8881 8.99266 10.7689 8.64625 10.7695H4.07031V9.26953ZM4.82031 6.26953H19.8203C20.0192 6.26953 20.21 6.34855 20.3506 6.4892C20.4913 6.62985 20.5703 6.82062 20.5703 7.01953V7.76953H4.07031V7.01953C4.07031 6.82062 4.14933 6.62985 4.28998 6.4892C4.43063 6.34855 4.6214 6.26953 4.82031 6.26953ZM19.8203 18.2695H4.82031C4.6214 18.2695 4.43063 18.1905 4.28998 18.0499C4.14933 17.9092 4.07031 17.7184 4.07031 17.5195V12.2695H8.64531C8.8179 13.1167 9.27794 13.8783 9.94756 14.4253C10.6172 14.9722 11.4552 15.271 12.3198 15.271C13.1845 15.271 14.0225 14.9722 14.6921 14.4253C15.3617 13.8783 15.8218 13.1167 15.9944 12.2695H20.5703V17.5195C20.5703 17.7184 20.4913 17.9092 20.3506 18.0499C20.21 18.1905 20.0192 18.2695 19.8203 18.2695Z"
        fill={stroke}
      />
    </svg>
  );
};

export default CardholderIcon;
