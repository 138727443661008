import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Stack } from "@mui/material";
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import TriggerModalContent from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/TriggerModalContent";
import { TriggerHistoryData } from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/TriggerHistoryCard";

interface IComponentProps {
  isEnabled: boolean;
  factorID: number;
  profileId: number;
  merchantId: number;
  triggerTitle: string;
  triggerDescription: string;
  history: TriggerHistoryData;
}

const ToggleRiskTriggerModal = NiceModal.create(
  ({
    isEnabled,
    factorID,
    profileId,
    merchantId,
    history,
    triggerTitle,
    triggerDescription,
  }: IComponentProps) => {
    const titleMode = isEnabled ? "Disable" : "Enable";
    const modal = useModal();
    const { isMobileView } = useCustomTheme();

    const handleCancel = () => modal.hide();
    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 541,
        }}
      >
        <Stack p={3} gap="20px">
          <ModalTitle
            title={`${titleMode} Risk Trigger`}
            description="Please provide a reason for adjusting this trigger. This information is required and will be added to the conversation log"
            onClose={handleCancel}
          />
          <TriggerModalContent
            isEnabled={isEnabled}
            onCancel={handleCancel}
            factorID={factorID}
            profileId={profileId}
            merchantId={merchantId}
            triggerHistory={history}
            triggerTitle={triggerTitle}
            triggerDescription={triggerDescription}
          />
        </Stack>
      </ModalFactory>
    );
  },
);

export default ToggleRiskTriggerModal;
