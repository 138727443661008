import Skeleton from "@components/animation/Skeleton";
import { Stack } from "@mui/material";

export default function LoadingState({ length = 5 }: { length?: number }) {
  const items = Array.from({ length }, () => null);
  return (
    <Stack gap="16px">
      {items.map((_item, index) => (
        <Stack gap="8px" key={index}>
          <Skeleton width={130} borderRadius="17.5px" />
          <Skeleton height={81} borderRadius="8px" />
        </Stack>
      ))}
    </Stack>
  );
}
