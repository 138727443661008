import { BaseModal } from "@common/Modal";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Grid,
} from "@mui/material";
import { palette } from "@palette";
import { StyledIconButton } from "@common/IconButton";
import { CloseIcon } from "@assets/rebrandIcons";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { Button } from "@common/Button";
import ProfileModalSidebar from "./ProfileModalSidebar";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ProfileMobileDrawer from "./ProfileMobileDrawer";
import { useEffect, useMemo, useState } from "react";
import { ProfileAccountDetails } from "../Tabs/ProfileAccountDetails";
import { FormProvider } from "react-hook-form";
import { useProfileForms } from "../hooks/useProfileForms";

import SecurityTab from "./Tabs/SecurityTab";
import NotificationTab from "./Tabs/NotificationTab";
import AccessibilityTab from "./Tabs/AccessibilityTab";
import LoadingButton from "@common/Button/LoadingButton";
import { ProfileModalPageProps, TProfileTab } from "./types";

const ProfilePage = {
  "Account Details": {
    component: ProfileAccountDetails,
  },
  Security: {
    component: SecurityTab,
  },
  Accessibility: {
    component: AccessibilityTab,
  },
  Notifications: {
    component: (props: ProfileModalPageProps) => <NotificationTab {...props} />,
  },
};

type ModalProps = { initialTab?: TProfileTab };

const ProfileModal = NiceModal.create(({ initialTab }: ModalProps) => {
  const modal = useModal();
  const { isMobileView } = useCustomTheme();
  const [sidebarList, setSidebarList] = useState(initialSidebarList);
  const [isDisabled, setDisabled] = useState(true);

  const activeTab = useMemo(
    () => sidebarList.find((item) => item.active)?.tab || "Account Details",
    [sidebarList],
  );
  const {
    onSubmit,
    methods = {},
    isLoading,
    isDirty,
  } = useProfileForms(activeTab);

  const handleClose = () => modal.hide();

  const handleSelectTab = (tab: TProfileTab) => {
    const updatedTabs = sidebarList.map((item) => {
      if (item.tab === tab)
        return {
          ...item,
          active: true,
        };
      return { ...item, active: false };
    });

    setSidebarList(updatedTabs);
  };

  useEffect(() => {
    if (initialTab && modal.visible) handleSelectTab(initialTab);
  }, [modal.visible]);

  const ActivePage = ProfilePage[activeTab];

  if (isMobileView) {
    return (
      <ProfileMobileDrawer
        sidebarList={sidebarList}
        ProfilePage={ProfilePage}
        setDisabled={setDisabled}
        isDisabled={isDisabled}
        initialTab={initialTab}
      />
    );
  }

  return (
    <StyledModal
      {...muiDialogV5(modal)}
      onClose={handleClose}
      PaperProps={{
        style: {
          background: "rgba(251, 251, 251, 0.8)",
          boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "12px",
          width: "800px",
          maxWidth: "800px",
        },
        sx: {
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
          "& .MuiDialogTitle-root + .MuiDialogContent-root": {
            paddingTop: "0 !important",
          },
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          component={activeTab === "Notifications" ? "div" : "form"}
          onSubmit={
            activeTab === "Notifications"
              ? null
              : methods?.handleSubmit?.(onSubmit)
          }
        >
          <Grid container>
            <Grid item xs={3.5}>
              <ProfileModalSidebar
                sidebarList={sidebarList}
                handleSelectTab={handleSelectTab}
              />
            </Grid>
            <Grid item xs={8.5}>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                sx={{ background: palette.neutral[5] }}
              >
                <ModalTitleContainer>
                  <Box component="span" flexGrow={1}>
                    {activeTab}
                  </Box>
                  <CloseButton onClick={handleClose}>
                    <CloseIcon width={24} height={24} />
                  </CloseButton>
                </ModalTitleContainer>
                <ContentContainer>
                  <ActivePage.component setDisabled={setDisabled} />
                </ContentContainer>
                {(isDirty ||
                  (!isDisabled && activeTab === "Notifications")) && (
                  <ModalActionsContainer>
                    <Button
                      size="medium"
                      background="tertiary"
                      onClick={handleClose}
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      isLoading={isLoading}
                      disabled={
                        isLoading || (activeTab === "Security" && isDisabled)
                      }
                      size="medium"
                      background="primary"
                      form={
                        activeTab === "Notifications"
                          ? "profile-form"
                          : undefined
                      }
                      type="submit"
                    >
                      {isMobileView ? "Save" : "Update"}
                    </LoadingButton>
                  </ModalActionsContainer>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </StyledModal>
  );
});

const initialSidebarList = [
  { tab: "Account Details", active: true },
  { tab: "Security", active: false },
  { tab: "Accessibility", active: false },
  { tab: "Notifications", active: false },
] as { tab: TProfileTab; active: boolean }[];

const StyledModal = styled(BaseModal)(() => ({
  ".MuiDialogActions-root": {
    backgroundColor: "#FBFBFB",
  },

  "@media (max-width: 600px)": {
    "& .MuiDialog-paper": {
      top: "50px !important",
      height: "90%",
      borderRadius: "8px",
      maxHeight: "90%",
      padding: "0 5px",
    },
  },
}));

const CloseButton = styled(StyledIconButton)(() => ({
  maxWidth: "24px",
  maxHeight: "24px",
  borderRadius: "4px",
  padding: "0 !important",
}));

const ModalTitleContainer = styled(DialogTitle)(() => ({
  padding: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: palette.black[100],
  fontSize: "20px",
  lineHeight: "27px",
  fontWeight: 350,
}));

export const ContentContainer = styled(DialogContent)(() => ({
  padding: "0 16px 8px !important",
  flexGrow: 1,
  "& > div": {
    height: "65vh",
    maxHeight: "65vh",
    overflowY: "auto",
  },
}));

const ModalActionsContainer = styled(DialogActions)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "24px",
  background: "transparent",
  padding: "12px 16px",
}));

export default ProfileModal;
