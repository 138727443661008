interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
}

const ArrowForwardIcon = ({
  width = 18,
  height = 14,
  stroke = "#4C4A52",
}: IconProps) => {
  return (
    <svg
      data-testid="right-arrow-icon"
      width={width}
      height={height}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1L16.5 7L10.5 13M1.5 7C6.24739 7 9.73702 7 15.5 7"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowForwardIcon;
