import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetAllTeamMembers } from "@services/api/manage-team";

export const useGetConversationsMentionsSuggestions = () => {
  const { merchantId } = useGetCurrentMerchantId();
  const { data, error, isLoading } = useGetAllTeamMembers({memberStatus: "joined"}, merchantId);

  return {
    data,
    error,
    isLoading,
  };
};
