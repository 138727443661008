const KeyboardArrowRight = ({
  width = 9,
  height = 16,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3335 1.3335L8.00016 8.00016L6.25425 9.74608M1.3335 14.6668L3.79387 12.2065"
        stroke="#8F8F8F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KeyboardArrowRight;
