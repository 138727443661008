import React, { useState } from "react";
import SelfieComponent, { SelfieProps } from "./components/SelfieComponent";
import LoadingComponent from "@components/ProfilePage/PersonalInformation/components/LoadingComponent";
import SelfiePreview from "@components/ProfilePage/PersonalInformation/components/SelfiePreview";
import { Box, Stack } from "@mui/material";
import { useGetUploadedSelfie } from "@sections/VerifyAccountHolder_v2/hooks/useCamera";
import { ProfileSetupFormActions } from "@components/ProfilePage/form.components";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

function Selfie(props: SelfieProps) {
  const [selfieUrl, setSelfieUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { uploadedSelfie } = useGetUploadedSelfie();
  const { isMobileView } = useCustomTheme();

  const dbSelfieURL = uploadedSelfie?.fileURL;
  return (
    <div
      style={
        isMobileView
          ? {
              display: "flex",
              flexDirection: "column",
              height: props?.completed || selfieUrl ? "100%" : "106%",
            }
          : {}
      }
    >
      {isUploading ? (
        <Box
          justifyContent="center"
          display="flex"
          height="400px"
          width="100%"
          alignItems="center"
        >
          <LoadingComponent />
        </Box>
      ) : (
        <Box sx={{ ...(isMobileView && { flex: 1 }) }}>
          {selfieUrl ? (
            <SelfiePreview
              selfieUrl={selfieUrl}
              onClick={() => {
                setSelfieUrl(null);
                setIsUploading(false);
                props?.setCompleted && props?.setCompleted(false);
              }}
            />
          ) : (
            <>
              <SelfieComponent
                {...props}
                setIsUploading={setIsUploading}
                setSelfieUrl={setSelfieUrl}
              />
            </>
          )}
        </Box>
      )}
      <Stack
        {...(isMobileView && { display: "flex", justifyContent: "flex-end" })}
      >
        <ProfileSetupFormActions
          secondaryAction={
            isMobileView
              ? {
                  onClick: selfieUrl ? () => setSelfieUrl(null) : props?.onBack,
                }
              : {
                  children: <></>,
                  sx: {
                    visible: "none",
                  },
                }
          }
          primaryAction={{
            disabled: isUploading || !dbSelfieURL,
            children: props?.completed || selfieUrl ? "Done" : "Next",
            type: "button",
            onClick: props?.completed
              ? () => props?.handleReset && props?.handleReset()
              : selfieUrl
              ? () => {
                  setSelfieUrl(null);
                  props?.setCompleted && props?.setCompleted(true);
                }
              : () => setSelfieUrl(dbSelfieURL),
          }}
        />
      </Stack>
    </div>
  );
}

export default Selfie;
