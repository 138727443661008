import { Box } from "@mui/material";
import React, { memo } from "react";
import { animated, useInView, useSpring } from "react-spring";

const FadeDownWrapper = ({
  children,
  delay = 1000,
  fromShadow,
  toShadow,
  borderRadius,
}: {
  children: React.ReactNode;
  delay?: number;
  fromShadow?: string;
  toShadow?: string;
  borderRadius?: string;
}) => {
  const [ref, inView] = useInView({ once: true });

  const styles = useSpring({
    width: "100%",
    opacity: inView ? 1 : 0,
    y: inView ? 0 : -50,
    ...(toShadow &&
      fromShadow && {
        boxShadow: inView ? toShadow : fromShadow,
      }),
    borderRadius,
    from: { opacity: 0, y: -50, boxShadow: fromShadow },
    config: { duration: 500, easing: (t: any) => t * (2 - t) },
    delay,
  });

  return (
    <Box ref={ref}>
      <animated.div style={styles}>{children}</animated.div>
    </Box>
  );
};

export default memo(FadeDownWrapper);
