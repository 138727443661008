import { shouldBeHidden } from "@constants/constants";
import { getTitleAndSubTitle } from ".";
import {
  APIResponseAlert,
  TGroupDataGenerator,
  TGroupedAlerts,
  BooleanObject,
  TParsedAlert,
} from "../types";

export const groupAlerts: TGroupDataGenerator = (data) => {
  let groupedData: any = {};

  data.forEach((alert) => {
    const alertId = alert.id;

    alert.receiverList.forEach((receiver) => {
      if (receiver === "submerchant" && alert.displayName === "Transaction")
        return;

      if (!groupedData[receiver]?.[alertId]) {
        const parsedAlert = alertParser(alert, receiver);
        groupedData = {
          ...groupedData,
          [receiver]: {
            ...groupedData[receiver],
            [alertId]: parsedAlert,
          },
        };
        return;
      }

      const savedAlert = groupedData[receiver][alertId];
      const method = alert.assignedMethods?.[0];
      if (method) {
        const isAssigned = Object.assign(savedAlert.isAssigned, {
          [method]: alert.isAssigned,
        });
        groupedData = {
          ...groupedData,
          [receiver]: {
            ...groupedData[receiver],
            [alertId]: {
              ...savedAlert,
              isAssigned,
            },
          },
        };
      }
    });
  }, {} as TGroupedAlerts);

  return groupedData;
};

const alertParser = (
  alert: APIResponseAlert,
  receiver: string,
): TParsedAlert => {
  const texts = getTitleAndSubTitle(alert, receiver);
  const isAssigned = getIsAssigned(alert);
  const disabled = getIsDisabled(alert);
  return {
    id: alert.id,
    name: alert.name,
    title: texts.title,
    subTitle: texts.subTitle,
    highlight: texts?.highlight || "",
    hidden: {
      email: false,
      push: shouldBeHidden.pushNotificationSettings,
    },
    disabled,
    isAssigned,
  };
};

type THelper = (alert: APIResponseAlert) => BooleanObject;

const getIsDisabled: THelper = (alert) => {
  const initial = {
    email: true,
    push: true,
  };
  const enabledMethods = alert.methods.reduce(
    (acc, key) => ({
      ...acc,
      [key]: false,
    }),
    {},
  );
  return Object.assign(initial, enabledMethods);
};

const getIsAssigned: THelper = (alert) => {
  const method = alert.assignedMethods?.[0];
  const initial = {
    email: false,
    push: false,
  };
  return Object.assign(initial, { [method]: alert.isAssigned });
};
