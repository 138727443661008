import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { useUploadImageToS3 } from "@hooks/upload-api/uploadHooks";
import { updatePartialUser } from "@redux/slices/auth/auth";
import { useGetUser } from "@services/api/onboarding/user";
import { updateUser } from "@services/api/upload/uploadEndpoints";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

export const useChangeProfileAvatar = () => {
  const { mutateAsync } = useUploadImageToS3();
  const { data: userData } = useGetUser();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleChangeStatus = async ({ file }: { file: File }) => {
    const responses = await mutateAsync({
      file: file,
      formType: "customer_avatar",
    });

    const url = responses ? responses.response.URL : null;

    updateUserImage(url);
  };
  const updateUserData = useMutation((data: any) => {
    return updateUser({
      accID: userData.accID,
      data,
    });
  });

  function updateUserImage(url: string | null) {
    const imageURL = { imageURL: url };
    updateUserData.mutate(imageURL, {
      onSuccess: (res: any) => {
        const user = queryClient.getQueryData("user");
        if (user) {
          queryClient.setQueryData(
            "user",
            Object.assign(user as object, imageURL),
          );
        }
        dispatch(
          updatePartialUser({
            img: addSizeToImage(url || "", "thumb"),
          }),
        );
      },
    });
  }

  return {
    handleChangeStatus,
    updateUserImage,
  };
};
