import * as React from "react";
import "react-dropzone-uploader/dist/styles.css";
import { Accept, ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import { IStyleCustomization } from "react-dropzone-uploader";
import { fiveMB } from "@hooks/upload-api/uploadHooks";
import { useUploadProgress } from "@redux/slices/uploadProgressSlice";
import InputNew from "@components/UploadFile/Rebranded/InputNew";
import { bytesToSize } from "@utils/index";
import { getRandomNumber } from "@utils/helpers";

const defaultSupportedFormatText = ".pdf, .png, .jpg, .jpeg, .webp";

type UploadFileProps = {
  uploadFunction: (file: File) => void;
  styles?: IStyleCustomization<React.CSSProperties>;
  disabled?: boolean;
  maxFiles?: number;
  multiple?: boolean;
  accept: Accept;
  maxSizeInBytes?: number;
  customText?: string;
  supportedFormatText?: string;
};

export const UploadFileNew = React.memo(function UploadFile({
  uploadFunction,
  styles = {
    dropzone: {},
  },
  disabled = false,
  maxFiles,
  multiple,
  accept,
  maxSizeInBytes = fiveMB,
  customText: customizedTextProp,
  supportedFormatText = defaultSupportedFormatText,
}: UploadFileProps) {
  const { setUploadProgress } = useUploadProgress();

  const showErrorSnackbar = (
    file: FileRejection,
    error: "tooManyFiles" | "tooLarge" | "unsuported",
  ) => {
    setUploadProgress({
      key: `${getRandomNumber(1000000, 100000000)}`,
      data: {
        fileName: file.file.name,
        ...(error !== "tooManyFiles" && { size: file.file.size }),
        [error]: true,
      },
    });
  };
  const onDrop = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const hasTooManyFilesError = rejectedFiles.some((file) =>
      file.errors.some((error) => error.code === ErrorCode.TooManyFiles),
    );
    if (hasTooManyFilesError) {
      showErrorSnackbar(rejectedFiles[0], "tooManyFiles");
      return;
    }

    rejectedFiles.forEach((file) => {
      if (file.errors[0].code === ErrorCode.FileTooLarge) {
        showErrorSnackbar(file, "tooLarge");
      } else if (file.errors[0].code === ErrorCode.FileInvalidType) {
        showErrorSnackbar(file, "unsuported");
      }
    });
    acceptedFiles.forEach((file) => {
      uploadFunction(file);
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    multiple,
    maxFiles,
    accept,
    noClick: true,
    maxSize: maxSizeInBytes,
  });
  const { dropzone } = styles;
  const dropZoneStyles = useDropZoneStyles({
    isDragActive,
  });

  let customText = `Up to ${
    bytesToSize(maxSizeInBytes).sizeString
  } each (${supportedFormatText})`;

  if (maxFiles) {
    customText = `Max. ${maxFiles} file${maxFiles > 1 ? "s" : ""}, up to ${
      bytesToSize(maxSizeInBytes).sizeString
    } each (${supportedFormatText})`;
  }

  return (
    <div
      {...getRootProps({})}
      style={{
        height: 200,
        width: "100%",
        minWidth: 330,
        borderRadius: 12,
        margin: 0,
        justifyContent: "center",
        cursor: "pointer",
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        ...dropzone,
        ...(disabled && {
          cursor: "not-allowed",
          pointerEvents: "none",
          opacity: 0.7,
        }),
      }}
      className={dropZoneStyles.dropzone}
      aria-disabled={disabled}
    >
      <InputNew
        {...getInputProps()}
        customText={customizedTextProp || customText || ""}
      />
    </div>
  );
});
const useDropZoneStyles = makeStyles(() => ({
  dropzone: {
    border: ({ isDragActive }: any) =>
      isDragActive
        ? `2px solid ${palette.neutral[60]}`
        : `2px dashed ${palette.neutral[60]}`,
    backgroundColor: "white",
    "&:hover": {
      border: `2px solid ${palette.neutral[60]}`,
      backgroundColor: "white",
    },
  },
}));
