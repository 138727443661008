import React from "react";
import axios from "axios";
import { customInstance } from "@services/api";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { isEmpty } from "lodash";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

export const useGetUploadFilePermission = (isEnterprise = false) => {
  return useAccessControl({
    resource: composePermission(
      isEnterprise ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.FILES,
    ),
    operation: OPERATIONS.CREATE,
  });
};

export const useUploadBankAccountDocument = (merchantId?: number) => {
  const { name } = useAppSelector(selectUser);
  const [isLoading, setIsLoading] = React.useState(false);
  const isUploadAllowed = useGetUploadFilePermission();

  const handleUpload = async (
    bankAccountId: number,
    list: File[],
    merchantName?: string,
  ) => {
    if (list.length === 0 || !merchantId) return;
    setIsLoading(true);

    const customDocumentList = list.map((item) => ({
      attachmentType: "bank_account",
      fileName: item.name,
      label: `${merchantName || name} bank account document`,
      tag: "bank account document",
      resourceID: bankAccountId,
    }));

    const res = await customInstance({
      url: `accounts/${merchantId}/files`,
      method: "POST",
      data: { list: customDocumentList },
    });

    if (!res?.total || res?.total < 1) return;

    for (const [index, document] of res.data.entries()) {
      await axios.put(document.fileURL, list[index]);
    }

    const confirmedUploadedList = res.data.map((item: any) => ({
      id: item.id,
      isUploaded: true,
    }));

    await customInstance({
      url: `accounts/${merchantId}/files/confirm`,
      method: "POST",
      data: { list: confirmedUploadedList },
    });

    setIsLoading(false);
  };

  return { handleUpload, isLoading, isUploadAllowed };
};

export const useUploadDocument = (
  merchantId: number,
  payload: any,
  onEndUpload?: () => void,
) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const isUploadAllowed = useGetUploadFilePermission();

  const handleUpload = async (list: File[], resourceID?: number) => {
    if (isEmpty(list)) return;
    setIsLoading(true);

    const customDocumentList = list.map((item) => ({
      attachmentType: payload.attachmentType,
      fileName: item.name,
      label: payload.label,
      tag: payload.tag,
      resourceID: resourceID ? resourceID : payload.resourceID,
    }));

    const res = await customInstance({
      url: `accounts/${merchantId}/files`,
      method: "POST",
      data: { list: customDocumentList },
    });

    if (!res?.total || res?.total < 1) return;

    for (const [index, document] of res.data.entries()) {
      await axios.put(document.fileURL, list[index]);
    }

    const confirmedUploadedList = res.data.map((item: any) => ({
      id: item.id,
      isUploaded: true,
    }));

    await customInstance({
      url: `accounts/${merchantId}/files/confirm`,
      method: "POST",
      data: { list: confirmedUploadedList },
    });

    setIsLoading(false);
    if (onEndUpload) onEndUpload();
  };

  return { handleUpload, isLoading, isUploadAllowed };
};

export const usePostUploadDocument = (payload: any) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpload = async (list: File[]) => {
    if (list.length === 0) return;
    setIsLoading(true);

    const customDocumentList = list.map((item) => ({
      attachmentType: payload.attachmentType,
      fileName: item.name,
    }));

    const res = await Promise.all(
      customDocumentList.map((x) =>
        customInstance({
          url: `s3/presign-url`,
          method: "POST",
          data: x,
        }),
      ),
    );

    // await customInstance({
    //   url: `accounts/${merchantId}/files/confirm`,
    //   method: "POST",
    //   data: { list: confirmedUploadedList },
    // });

    setIsLoading(false);

    return res;
  };

  return { handleUpload, isLoading };
};
