import ExportFilterButtons from "@common/ExportView/components/ExportFilterButtons";
import { SearchBar } from "@common/SearchBar_V2";
import {
  DownloadReportButton,
  PendingSwitch,
  TableFilters,
} from "@components/ManageMoney/TransactionTable/TransactionTable.atoms";
import { useDownloadReport } from "@components/ManageMoney/TransactionTable/hooks";
import { Grid, Stack } from "@mui/material";

export function MainActionsHeader({ queryKey, filters }: any) {
  const tableTitle = "Transactions";
  const { disableDownload, downloadReport, denyDownload } =
    useDownloadReport();

  if (!Array.isArray(filters) && filters?.type === "REACT_COMPONENT") {
    return (
      <Grid container alignItems="center">
        {filters.jsx}
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{
            marginLeft: "auto",
          }}
        >
          <SearchBar queryKey={queryKey} />
        </Stack>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center">
      {filters ? <TableFilters filters={filters} /> : <PendingSwitch />}
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          marginLeft: "auto",
        }}
      >
        <SearchBar queryKey={queryKey} />
        {tableTitle && ["Merchants", "Merchants list"].includes(tableTitle) && (
          <ExportFilterButtons totalRows={0} />
        )}
        <DownloadReportButton
          disabled={disableDownload}
          denyDownload={denyDownload}
          onClick={downloadReport}
        />
      </Stack>
    </Grid>
  );
}
