import { Text } from "@common/Text";
import { Box, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { EditButton } from "./atoms";
import { EditIcon } from "@assets/icons/RebrandedIcons";
import { TMerchantFees, TFeeObject } from "../data.types";
import NiceModal from "@ebay/nice-modal-react";
import { EDIT_MERCHANT_FEES_MODAL } from "modals/modal_names";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { ConversationsIconNotFilled } from "@assets/icons";

export const calculateFeePerTransaction = (fee: TFeeObject) => {
  if (!fee) return "";
  const val = (fee?.baselinePercent + fee?.value) / 100;
  return `${val}% + $${fee?.baselineFixed / 100}`;
};

const MerchantFees = ({
  id,
  data,
  isAcquirer,
}: {
  id: number;
  data: TMerchantFees;
  isAcquirer?: boolean;
}) => {
  const fees = [
    {
      label: "Credit Card Fee",
      fee: data.creditCardFee.value,
      feePerTransaction: calculateFeePerTransaction(data.creditCardFee),
    },
    {
      label: "Debit Card Fee",
      fee: data.debitCardFee.value,
      feePerTransaction: calculateFeePerTransaction(data.debitCardFee),
    },
    {
      label: "AMEX Credit Card Fee",
      fee: data.amexCreditCardFee.value,
      feePerTransaction: calculateFeePerTransaction(data.amexCreditCardFee),
    },
  ];

  const { openConversationHandler } = useConversationsModal();

  const editHandler = () =>
    NiceModal.show(EDIT_MERCHANT_FEES_MODAL, { id, data });

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    openConversationHandler({ id, name: "Merchant Fees", paths: [] });
  };

  return (
    <Stack
      gap={2}
      sx={{
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Text
          color={palette.gray[300]}
          variant="h6"
          lineHeight="21.6px"
          letterSpacing="-0.18px"
        >
          Merchant Fees
        </Text>
        <Box
          ml={1.5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            {isAcquirer && (
              <EditButton onClick={onClick}>
                <ConversationsIconNotFilled section="business-profile-(legal-entity)" />
              </EditButton>
            )}
          </Stack>
        </Box>
      </Stack>
      <Container>
        <Grid container rowSpacing="24px" columnSpacing="12px">
          {fees.map((fee) => (
            <Grid item xs={12} sm={6} key={fee.label}>
              <FeeCard {...fee} />
            </Grid>
          ))}
        </Grid>
        <Box position="absolute" top="16px" right="16px">
          <EditButton onClick={editHandler}>
            <EditIcon />
          </EditButton>
        </Box>
      </Container>
    </Stack>
  );
};

type TFeeCardProps = {
  feePerTransaction: string;
  fee: number;
  label: string;
};

const FeeCard = ({ feePerTransaction, fee, label }: TFeeCardProps) => {
  return (
    <Stack direction="column" gap="12px" width="100%">
      <Stack direction="column" gap="4px">
        <Text color={palette.black[100]} lineHeight="16.8px" fontWeight="book">
          {label}
        </Text>
        <Stack direction="row" gap="4px" alignItems="center">
          <Text
            color={palette.black[100]}
            variant="h6"
            lineHeight="21.6px"
            letterSpacing="-0.18px"
            fontWeight="book"
          >
            {fee}
          </Text>
          <Text
            color={palette.gray[200]}
            fontWeight="light"
            lineHeight="16.8px"
          >
            BPS
          </Text>
        </Stack>
      </Stack>
      <Text
        color={palette.gray[300]}
        variant="caption"
        fontWeight="book"
        lineHeight="14.4px"
      >
        {feePerTransaction} / fee per transaction
      </Text>
    </Stack>
  );
};

const Container = styled(Stack)(() => ({
  flexDirection: "row",
  gap: "4px",
  alignItems: "flex-start",
  borderRadius: "8px",
  background: palette.neutral.white,
  padding: "16px",
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
  paddinRight: "24px",
  position: "relative",

  "&:hover": {
    "& .MuiButtonBase-root.MuiButton-root": {
      visibility: "visible",
    },
  },
}));

export default MerchantFees;
