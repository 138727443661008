import { namespaces } from "../../../../resources/i18n.constants";

export const eventsMinibuilder = {
  [namespaces.pages.eventsMinibuilder]: {
    create_events: "Create Event",
    general: "General",
    date_and_location: "Date & Location",
    tickets: "Tickets",
    // General --------------------------------------------------------------------------------
    title: "Title",
    "Please enter a suitable title": "Please enter a suitable title",
    description: "Description",
    optional_but_recommended: "(Optional but recommended)",
    "What’s the purpose of this Event": "What’s the purpose of this Event?",
    browse_more_campaigns: "Browse More Campaigns",
    "If enabled, users will be able to explore other available campaigns of this Nonprofit":
      "If enabled, users will be able to explore other available campaigns of this Nonprofit",
    featured_image: "Featured Image",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Recommended minimum size 2000x2000":
      "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Recommended minimum size 2000x2000",
    use_as_background_image: "Use as Background Image",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "If checked this image will be integrated in the background of the form, can be later changed",
    media_library: "Media Library",
    add_new_image: "Add New Image",
    no_images: "No images",
    // Date & Location --------------------------------------------------------------------------------
    date: "Date",
    one_day: "one day",
    period: "period",
    when_is_the_event: "When is the Event?",
    choose_a_date: "Choose a date",
    when_the_event_starts: "When the Event starts?",
    when_the_event_ends: "When the Event ends?",
    select_a_date: "Select a date",
    include_time: "Include Time",
    location: "Location",
    in_person: "in person",
    online: "online",
    where_is_the_event: "Where is the Event?",
    type_a_location: "Type event location",
    enter_a_url: "Enter a URL",
    "show user a link to view map": "Show User a Link to View Map",
    "if enabled, users will be able to see a link to view map":
      "If enabled, users will be able to see a link to view map.",
    enter_address_manually: "Enter Address Manually",
    address: "Address",
    type_address: "Type address",
    city: "City",
    type_city: "Type City",
    state: "State",
    select_state: "Select State",
    zip: "ZIP",
    type_zip: "Type Zip",
    previous_step: "Previous Step",
    // Tickets --------------------------------------------------------------------------------
    ticket: "ticket",
    thumbnail: "Thumbnail",
    add_ticket: "Add Ticket",
    ticket_name: "Ticket Name",
    enter_a_suitable_name: "Enter suitable name",
    ticket_name_is_mandatory: "Ticket name is mandatory",
    amount: "Amount",
    must_be_above_one_dollar: "Must be above 1USD",
    "between 1.00 and 25,000.00": "Between 1.00 and 25,000.00",
    in_stock: "In Stock",
    at_least_one: "At least 1",
    should_be_at_least: "Should be at least 1",
    display_tickets_available: "Display Tickets Available",
    give_some_insight_about_this_ticket: "Give some insight about this Ticket",
    create_option: "Create Option",
    save_changes: "Save Changes",
    cancel: "Cancel",
    remove: "Remove",

    properties: "Properties",
    customer_pays_the_credit_card_fee: "Customer Pays the Credit Card Fee",
    "If toggled off, the Nonprofit covers the credit card fee.":
      "If toggled off, the Nonprofit covers the credit card fee.",
    "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee":
      "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee.",
    make_it_optional: "Make it Optional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "If checked customers will have the choice of not covering the Credit Card Fee",
    max_tickets_that_can_be_purchased_at_once:
      "Max Tickets That Can Be Purchased At Once",
    create_event: "Create Event",
    // Event Successful
    event_successfully_created: "Event successfully created",
    "If you’re on a desktop or a laptop":
      "If you’re on a desktop or a laptop, you can continue editing in <span>the Advanced Builder</span>. The Advanced Builder offers powerful customization tools to help you take your campaign to the next level and ultimately increase conversion.",
    event_create_success_description: "Congratulations, your payment form has been created successfully and it's ready to accept payments, you can check its details and share it everywhere or browse more payment forms",
    open_the_advanced_builder: "Open the Advanced Builder",
    edit_in_advanced_builder: "Edit in Advanced Builder",
    done: "Done",
  },
};
