import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";

type DisabledMoneyTransferWarningProps = {
  isApproved: boolean;
  allowedToTransfer: boolean;
};

const DisabledMoneyTransferWarning = (
  props: DisabledMoneyTransferWarningProps,
) => {
  const { isApproved, allowedToTransfer } = props;
  if (isApproved && !allowedToTransfer) {
    return (
      <TransferWarning>
        <WarningIcon height={24} width={24} />
        <Text color={palette.warning.text}>
          {`Merchant members can't initiate a transfer as money transfers are
          disabled from the bank account section.`}
        </Text>
      </TransferWarning>
    );
  } else return null;
};

const TransferWarning = styled(Box)(() => ({
  display: "flex",
  padding: "12px 16px",
  flexDirection: "column",
  gap: "12px",
  borderRadius: "12px",
  background: palette.tag.warning.bg,
}));

export default DisabledMoneyTransferWarning;
