// MerchantInfoSection.tsx
import React from "react";
import { Divider, Grid } from "@mui/material";
import { Text } from "@common/Text";
import { FieldRecord } from "../../types";
import MerchantExpandableSectionPartial from "./MerchantExpandableSectionPartial";
import { palette } from "@palette";

interface Field {
  value?: string;
  size?: number;
}

interface SectionProps {
  sectionKey: string;
  fields: FieldRecord | FieldRecord[];
}

const MerchantExpandableSection: React.FC<SectionProps> = ({
  sectionKey,
  fields,
}) => (
  <Grid item xs={12}>
    <Text
      component="h5"
      fontSize={14}
      sx={{ fontWeight: 350, lineHeight: "120%" }}
    >
      {sectionKey}
    </Text>
    <Grid
      container
      columnSpacing={2}
      rowSpacing={1}
      padding="4px 8px"
      sx={{ marginTop: 0 }}
    >
      {
        Array.isArray(fields)
        ?
        fields.map((subFields, index) => (
          <React.Fragment key={index}>
            <MerchantExpandableSectionPartial fields={subFields} />
            {(index !== fields.length-1) && <Divider sx={{ width: "100%", borderColor: palette.neutral[10], my: 1 }} />}
          </React.Fragment>
        ))
        :
        <MerchantExpandableSectionPartial fields={fields} />
      }
    </Grid>
  </Grid>
);

export default MerchantExpandableSection;
