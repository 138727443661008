import { palette } from "@palette";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";

export type BankAccountTagType = "approved" | "pending" | "upload" | "declined";

const getColorsMap = (type: BankAccountTagType, filled: boolean) => {
  const colorsMap = {
    approved: filled ? palette.warning.light : palette.tag.success.hover,
    upload: filled ? palette.warning.light : palette.tag.warning.hover,
    declined: filled ? palette.error.light : palette.tag.error.hover,
    pending: filled ? palette.error.light : palette.neutral[80],
  };

  return colorsMap[type];
};

interface Props extends BoxProps {
  type: BankAccountTagType;
  filled?: boolean;
  isTextCenter?: boolean;
  active?: boolean;
  textProps?: ITextProps;
}

export const BankAccountTag = ({
  type,
  filled = false,
  isTextCenter = true,
  sx,
  active = true,
  textProps,
  ...rest
}: Props) => {
  const color = getColorsMap(type, filled);
  const classes = useStyles();

  return (
    <Box
      className={`${classes.wrapper} `}
      sx={{
        backgroundColor: active ? bgMap[type] : "#EBEBEB",
        cursor: active ? "default" : "pointer",
        ...sx,
      }}
      {...rest}
    >
      <Text
        color={active ? color : palette.black[100]}
        variant="body"
        textTransform="capitalize"
        width="100%"
        textAlign={isTextCenter ? "center" : "left"}
        fontSize="12px"
        {...textProps}
      >
        {type}
      </Text>
    </Box>
  );
};

const useStyles = makeStyles({
  wrapper: {
    gap: "2px",
    display: "flex",
    borderRadius: "4px",
    alignItems: "center",
    padding: "2px 8px",
    userSelect: "none",
  },
});

const bgMap = {
  approved: { backgroundColor: palette.tag.success.bg },
  upload: { backgroundColor: palette.tag.warning.bg },
  declined: { backgroundColor: palette.tag.error.bg },
  pending: { backgroundColor: "#EBEBEB" },
};
