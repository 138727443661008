import Xicon from "@assets/icons/Xicon";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import SuspendButton from "./SuspendButton";
import { TStatusReview } from "../../data.types";
import { useAppDispatch } from "@redux/hooks";
import { isFunction } from "lodash";
import { resetFilterByKey } from "@redux/slices/dynamicFilterSlice";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export const RiskProfileTabs = {
  ACTIVITY: "Activity" as RiskProfileTabsType,
  TRIGGERS: "Triggers" as RiskProfileTabsType,
  TRANSACTIONS: "Transactions" as RiskProfileTabsType,
};

export type RiskProfileTabsType = "Activity" | "Triggers" | "Transactions";

interface Props {
  handleClosePanel: () => void;
  activeTab: RiskProfileTabsType;
  setActiveTab: React.Dispatch<React.SetStateAction<RiskProfileTabsType>>;
  merchantStatus?: TStatusReview;
}

const RiskProfileHeader = ({
  handleClosePanel,
  activeTab,
  setActiveTab,
  merchantStatus,
}: Props) => {
  const { isRiskMonitorTransactionsEnabled } = useGetFeatureFlagValues();
  const { merchant_triggers } = useEnterprisePermissions();
  const tabs = [
    {
      label: "Activity",
    },
    {
      label: "Triggers",
      hidden: !merchant_triggers,
    },
    {
      label: "Transactions",
      hidden: !isRiskMonitorTransactionsEnabled,
    },
  ];
  const dispatch = useAppDispatch();
  const handleClick = () => {
    isFunction(handleClosePanel) && handleClosePanel();
    dispatch(
      resetFilterByKey({
        filterKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      }),
    );
  };

  return (
    <Stack padding="12px 16px 0" spacing={1.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontSize={18} color={palette.neutral[90]}>
          Risk Monitor
        </Text>

        <Stack direction="row" gap={3} alignItems="center">
          <SuspendButton status={merchantStatus} />
          <button
            data-testid="merchant-risk-profile-close-btn"
            style={{
              all: "unset",
              cursor: "pointer",
              marginTop: "4px",
            }}
            onClick={handleClick}
          >
            <Xicon />
          </button>
        </Stack>
      </Box>
      <Box display="flex" borderBottom={`1px solid ${palette.neutral[20]}`}>
        {tabs.map(({ label, hidden }) => {
          if (hidden) return null;
          return (
            <Tab
              data-testid="merchant-underwrting-tab-button"
              key={label}
              active={activeTab === label}
              onClick={() => setActiveTab(label as RiskProfileTabsType)}
            >
              {label}
            </Tab>
          );
        })}
      </Box>
    </Stack>
  );
};

const Tab = styled(Text, { shouldForwardProp: (prop) => prop !== "active" })<
  ITextProps & { active: boolean }
>(({ active }) => ({
  fontWeight: "350",
  color: palette.neutral[70],
  padding: "4px 16px",
  cursor: "pointer",
  ...(active && {
    color: palette.neutral[90],
    borderBottom: `2px solid ${palette.neutral[90]}`,
  }),
}));

export default RiskProfileHeader;
