import { useEffect, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import FundraisersAbout from "../FundraisersAbout";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TFundraiserModalInputs } from "../FundraisersMinibuilder/types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FundraiserModalSchema as schema,
  FundraiserModalDefaults as defaultValues,
} from "../FundraisersMinibuilder/utils";
import { Box } from "@mui/material";
import FundraisersPayment from "../FundraiserPayment";
import StyleSection from "../StyleSection";
import ConfigurationSection from "../ConfigurationSection";
import { useCreateProduct } from "../hooks";

type TStepsLabel = "About" | "Style" | "Payment set up" | "Configuration";

const CreateInvoiceModal = NiceModal.create(() => {
  const [step, setStep] = useState<TStepsLabel>("About");
  const { submitProduct } = useCreateProduct("invoice");

  const [aboutStatusBar, setAboutStatusBar] = useState(0);
  const [styleStatusBar, setStyleStatusBar] = useState(0);
  const [paymentStatusBar, setPaymentStatusBar] = useState(0);
  const [configurationStatusBar, setConfigurationStatusBar] = useState(0);

  const modal = useModal();

  const methods = useForm<TFundraiserModalInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    reset,
    watch,
    trigger,
    formState: { dirtyFields, isValid },
  } = methods;
  const values = watch();

  useEffect(() => {
    setAboutStatusBar(() =>
      dirtyFields?.about?.title && values.about.title?.trim() ? 100 : 0,
    );
  }, [values, dirtyFields]);

  useEffect(() => {
    if (step === "Style") setStyleStatusBar(100);
    if (step === "Payment set up") setPaymentStatusBar(100);
    if (step === "Configuration") setConfigurationStatusBar(100);
  }, [step]);

  const resetModal = () => {
    reset();
    setStep("About");
    setAboutStatusBar(0);
    setStyleStatusBar(0);
    setPaymentStatusBar(0);
    setConfigurationStatusBar(0);
  };

  const onClose = () => {
    modal.hide();
    resetModal();
  };

  const steps = [
    {
      label: "About",
      barValue: aboutStatusBar,
    },
    {
      label: "Style",
      barValue: styleStatusBar,
    },
    {
      label: "Payment set up",
      barValue: paymentStatusBar,
    },
    {
      label: "Configuration",
      barValue: configurationStatusBar,
    },
  ];

  const validateCurrentStep = () => {
    trigger("about.title");
  };

  const UISteps = {
    About: (
      <FundraisersAbout
        title="Tell us more about your Invoice"
        placeHolderText="What’s the purpose of this invoice?"
      />
    ),
    Style: <StyleSection title="Make it unique" />,
    "Payment set up": (
      <FundraisersPayment title="Set up a range of payment choices" />
    ),
    Configuration: <ConfigurationSection title="Configuration" />,
  };

  const onSubmit: SubmitHandler<TFundraiserModalInputs> = async (data) => {
    submitProduct(data);
    onClose();
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      setCurrentStep={setStep}
      steps={steps}
      currentStep={step}
      HeaderProps={{
        title: "Create Invoice",
      }}
      primaryAction={{
        label: "Next",
        onClick: validateCurrentStep,
        disabled: !isValid,
        type: step === "Configuration" ? "submit" : undefined,
        form: "create-invoice",
        key: step,
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          display="flex"
          id="create-invoice"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {UISteps[step]}
        </Box>
      </FormProvider>
    </ModalDrawer>
  );
});

export default CreateInvoiceModal;
