import React from "react";

type ExpandRightIconProps = {
  color?: string;
  height?: string | number;
  width?: string | number;
};

const ExpandRightIcon = ({
  color = "#915CA7",
  height = 32,
  width = 32,
}: ExpandRightIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8L19.2929 15.2929C19.6834 15.6834 19.6834 16.3166 19.2929 16.7071L12 24"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExpandRightIcon;
