import React from "react";

function FileUploadIcon({ disabled = false }: { disabled?: boolean }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={disabled ? "0.3" : "1"}>
        <path
          opacity="0.2"
          d="M21.8896 5.5V16.9431L18.1659 13.2194C18.0253 13.0788 17.8346 12.9999 17.6357 12.9999C17.4369 12.9999 17.2462 13.0788 17.1056 13.2194L14.7 15.625L10.0453 10.9694C9.97562 10.8996 9.8929 10.8443 9.80185 10.8066C9.7108 10.7688 9.61321 10.7494 9.51465 10.7494C9.41609 10.7494 9.31849 10.7688 9.22744 10.8066C9.13639 10.8443 9.05368 10.8996 8.98402 10.9694L3.88965 16.0647V5.5C3.88965 5.30109 3.96867 5.11032 4.10932 4.96967C4.24997 4.82902 4.44074 4.75 4.63965 4.75H21.1396C21.3386 4.75 21.5293 4.82902 21.67 4.96967C21.8106 5.11032 21.8896 5.30109 21.8896 5.5Z"
          fill="#8F8F8F"
        />
        <path
          d="M21.1396 4H4.63965C4.24182 4 3.86029 4.15804 3.57899 4.43934C3.29768 4.72064 3.13965 5.10218 3.13965 5.5V19C3.13965 19.3978 3.29768 19.7794 3.57899 20.0607C3.86029 20.342 4.24182 20.5 4.63965 20.5H21.1396C21.5375 20.5 21.919 20.342 22.2003 20.0607C22.4816 19.7794 22.6396 19.3978 22.6396 19V5.5C22.6396 5.10218 22.4816 4.72064 22.2003 4.43934C21.919 4.15804 21.5375 4 21.1396 4ZM21.1396 5.5V15.1328L18.6956 12.6897C18.5563 12.5504 18.3909 12.4398 18.2089 12.3644C18.0269 12.289 17.8318 12.2502 17.6348 12.2502C17.4378 12.2502 17.2427 12.289 17.0607 12.3644C16.8787 12.4398 16.7133 12.5504 16.574 12.6897L14.699 14.5647L10.574 10.4397C10.2927 10.1586 9.91137 10.0007 9.51371 10.0007C9.11606 10.0007 8.73467 10.1586 8.4534 10.4397L4.63965 14.2534V5.5H21.1396ZM4.63965 16.375L9.51465 11.5L17.0146 19H4.63965V16.375ZM21.1396 19H19.1362L15.7612 15.625L17.6362 13.75L21.1396 17.2544V19ZM14.3896 9.625C14.3896 9.4025 14.4556 9.18499 14.5792 8.99998C14.7029 8.81498 14.8786 8.67078 15.0841 8.58564C15.2897 8.50049 15.5159 8.47821 15.7341 8.52162C15.9524 8.56502 16.1528 8.67217 16.3101 8.8295C16.4675 8.98684 16.5746 9.18729 16.618 9.40552C16.6614 9.62375 16.6392 9.84995 16.554 10.0555C16.4689 10.2611 16.3247 10.4368 16.1397 10.5604C15.9547 10.684 15.7372 10.75 15.5146 10.75C15.2163 10.75 14.9301 10.6315 14.7192 10.4205C14.5082 10.2095 14.3896 9.92337 14.3896 9.625Z"
          fill="#8F8F8F"
        />
      </g>
    </svg>
  );
}

export default FileUploadIcon;
