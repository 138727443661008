// useErrorEmitter.ts
import { useEffect, useState } from "react";

export type ErrorListener<T> = (payload: T) => void;

interface Subscription {
  eventName: string;
  listener: ErrorListener<any>;
}

export class GiveErrorEmitter {
  private subscriptions: Subscription[] = [];
  private static instance: GiveErrorEmitter | null = null;

  private constructor() {
    this.subscriptions = [];
  }

  static getInstance(): GiveErrorEmitter {
    if (!GiveErrorEmitter.instance) {
      GiveErrorEmitter.instance = new GiveErrorEmitter();
      GiveErrorEmitter.instance.subscriptions = [];
    }
    return GiveErrorEmitter.instance;
  }

  subscribe(eventName: string, listener: ErrorListener<any>): () => void {
    const subscription: Subscription = { eventName, listener };
    this.subscriptions.push(subscription);

    return () => {
      this.subscriptions = this.subscriptions.filter((s) => s !== subscription);
    };
  }

  castError(eventName: string, payload: any): void {
    const relevantListeners = this.subscriptions.filter(
      (s) => s.eventName === eventName,
    );
    relevantListeners.forEach((subscription) => subscription.listener(payload));
    
  }

  clearListeners(eventName?: string): void {
    if (eventName) {
      this.subscriptions = this.subscriptions.filter(
        (s) => s.eventName !== eventName,
      );
    } else {
      this.subscriptions = [];
    }
  }
}

export const ErrorEmitter = GiveErrorEmitter.getInstance();
