import { Divider, DividerProps, StackProps } from "@mui/material";
import { HorizontalStack } from "@components/commons";
import { ITextProps, Text } from "@common/Text/Text";

interface TextDividerProps extends ITextProps {
  dividerProps?: DividerProps;
  wrapperProps?: StackProps;
}

function TextDivider({
  dividerProps,
  wrapperProps,
  ...rest
}: TextDividerProps) {
  return (
    <HorizontalStack {...wrapperProps}>
      <Text {...rest} />
      <Divider sx={{ flex: 1 }} {...dividerProps} />
    </HorizontalStack>
  );
}

export default TextDivider;
