import React from "react";

export const addGradientToIcon = (
  gradientId: string,
  startColor: string,
  endColor: string,
  Icon: JSX.Element,
) => {
  const color = `url(#${gradientId})`;
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id={gradientId} x1="100%" y1="100%" x2="0%" y2="0%">
          <stop stopColor={startColor} offset="0%" />
          <stop stopColor={endColor} offset="100%" />
        </linearGradient>
      </svg>
      {React.cloneElement(Icon, { color })}
    </>
  );
};
