const KeyboardArrowUp = ({
  width,
  height,
}: {
  width?: number | string;
  height?: number | string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6665 5.3335L7.99984 12.0002L6.25392 10.2542M1.33317 5.3335L3.79355 7.79387"
        stroke="#8F8F8F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KeyboardArrowUp;
