import { WarningPlaceholderBase } from "../components/WarningPlaceholdersSection";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { TMSPStatus } from "../data.types";

type Props = {
  data: TMSPStatus[];
};

const MSPWarning = ({ data }: Props) => {
  return (
    <WarningPlaceholderBase
      title="Message from MSP"
      message="The MSP has identified missing information in the merchant application:"
    >
      <Text color={palette.warning.text} fontWeight="book">
        {generateMissingInfoMessage(data)}
      </Text>
    </WarningPlaceholderBase>
  );
};

const getMSPErrorName = (error: string) => {
  switch (error) {
    case "merchant servicePhoneNumber":
      return "Service Phone Number";
    case "merchant websiteURL":
      return "Website URL";
    case "merchant categoryCode":
      return "Category Code";
    case "merchant address line1":
      return "Line 1";
    case "merchant address city":
      return "City";
    case "merchant address state":
      return "State";
    case "merchant address zip":
      return "Zip";
    case "merchant address country":
      return "Country";
    case "merchant bankAccount number":
      return "Bank Account Number";
    case "merchant bankAccount routingNumber":
      return "Routing Number";
    case "legalEntity":
      return "Legal Entity";
    case "legalEntity name":
      return "Legal Entity";
    case "legalEntity type":
      return "Legal Entity";
    case "legalEntity taxIDNumber":
      return "Legal Entity";
    default:
      return "";
  }
};

function generateMissingInfoMessage(errors: TMSPStatus[]) {
  const missingFields: string[] = [];
  const addressFields: string[] = [];
  for (const error of errors) {
    if (
      error?.field?.includes("principal") ||
      error?.field?.includes("legalEntity address")
    )
      continue;
    const errName = getMSPErrorName(error.field);

    if (error?.field.includes("address")) {
      addressFields.push(errName);
    } else {
      if (!missingFields.includes(errName)) {
        missingFields.push(errName);
      } else continue;
    }
  }

  const addressMessage =
    addressFields.length > 0 ? `(${addressFields.join(", ")})` : "";

  if (addressMessage) {
    missingFields.push(`Address ${addressMessage}`);
  }

  return missingFields.join(", ");
}

export default MSPWarning;
