import { Box, BoxProps, SxProps } from "@mui/material";
import React, { CSSProperties, memo } from "react";
import { animated, useInView, useSpring } from "react-spring";

type FadeUpProps = {
  children: React.ReactNode;
  delay?: number;
  containerProps?: any;
  reset?: boolean;
  width?: string | number;
  customStyle?: CSSProperties;
};

const FadeUpWrapper = ({
  children,
  delay = 1000,
  containerProps,
  reset = false,
  width = "100%",
  customStyle,
}: FadeUpProps) => {
  const [ref, inView] = useInView({ once: true });

  const styles = useSpring({
    width: "100%",
    opacity: inView ? 1 : 0,
    y: inView ? 0 : 50,
    x: inView ? 0 : 10,
    from: { opacity: 0, y: 50 },
    config: { duration: 500, easing: (t: any) => t * (2 - t) },
    delay: Math.min(delay, 1000),
    reset,
    ...customStyle,
  });

  return (
    <Box width={width} ref={ref} {...containerProps}>
      <animated.div style={styles}>{children}</animated.div>
    </Box>
  );
};
export default memo(FadeUpWrapper);
