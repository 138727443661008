import React, { useEffect, useRef, useState } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { Row } from "./Row";
import { VirtualList } from "./List";
import { LoadingMoreSkeleton } from "./LoadingMoreSkeleton";
import { VirtualListLayoutWrapper } from "../wrappers/VlLayoutWrapper";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

export const VirtualizedList = (props: any) => {
  const {
    data,
    columns,
    isFetchingNextPage,
    onEndReached,
    scrollToTop,
    clickRow,
    selectedRowIdx,
    customScrollParent,
    gridProps,
    isBorderBottom = false,
    style,
  } = props;
  const [isScrolling, setIsScrolling] = useState(false);
  const virtuoso = useRef<VirtuosoHandle | null>(null);

  const idx = useRef(-1);

  useEffect(() => {
    if (virtuoso.current) {
      scrollToTop.current = (
        index = 0,
        align = "center" as "center" | "start" | "end",
      ) => {
        if (virtuoso.current) {
          idx.current = -1;
          virtuoso.current.scrollToIndex({
            index,
            align,
            behavior: "auto",
          });
        }
      };
    }
  }, []);

  const onClickRow = (row: any, index: number) => {
    if (typeof clickRow !== "function") return;

    clickRow({
      index,
      row,
    });
  };

  return (
    <Virtuoso
      ref={virtuoso}
      {...(customScrollParent && { customScrollParent: customScrollParent })}
      fixedItemHeight={60}
      style={{
        height: "100%",
        ...style,
      }}
      context={{ isScrolling }}
      isScrolling={setIsScrolling}
      data={data}
      itemContent={(index, item, { isScrolling }) => {
        return (
          <WrappedRow
            idx={idx}
            id={item?.id}
            isSelected={selectedRowIdx === index}
            onClickRow={() => onClickRow(item, index)}
          >
            <Row
              isBorderBottom={isBorderBottom}
              item={item}
              columns={columns}
              isScrolling={isScrolling}
            />
          </WrappedRow>
        );
      }}
      endReached={(props) => {
        idx.current = -1;
        onEndReached(props);
      }}
      components={{
        List: (e) => <VirtualList {...e} gridProps={gridProps} />,
        Footer: () =>
          isFetchingNextPage ? (
            <VirtualListLayoutWrapper show>
              <LoadingMoreSkeleton columns={columns} />
            </VirtualListLayoutWrapper>
          ) : null,
      }}
    />
  );
};

const selectedStyle = {
  background: "white",
};

const animationStyle = {
  transform: "scale(1)",
  y: 0,
  opacity: 1,

  from: {
    opacity: 0,
    y: 50,

    transform: "scale(0.8)",
  },
} as any;

function RowWrapper({ children, isSelected, onClick }: any) {
  return (
    <div
      onClick={onClick}
      style={isSelected ? selectedStyle : {}}
      data-testid="row-wrapper"
    >
      {children}
    </div>
  );
}

function AnimatedRowWrapper({ children, idx }: any) {
  return (
    <FadeUpWrapper
      customStyle={{
        ...animationStyle,
        transform: `translateX(0)`,
        from: {
          opacity: Math.min(1, 1 / (idx + 1)),
          y: (idx + 2) * 8,
          transform: `translateX(50px)`,
        },
      }}
      delay={idx * 50}
      reset
    >
      {children}
    </FadeUpWrapper>
  );
}

function WrappedRow({ children, delay, id, isSelected, onClickRow, idx }: any) {
  useEffect(() => {
    if (!items.has(id)) {
      items.add(id);
    }
  }, []);

  if (items.has(id)) {
    return (
      <RowWrapper onClick={onClickRow} isSelected={isSelected}>
        {children}
      </RowWrapper>
    );
  } else {
    return (
      <RowWrapper onClick={onClickRow} isSelected={isSelected}>
        <AnimatedRowWrapper delay={delay} idx={++idx.current}>
          {children}
        </AnimatedRowWrapper>
      </RowWrapper>
    );
  }
}

export const items = new Set();
