import React from "react";
// utils
import { sortBy } from "lodash";
import { palette } from "@palette";
import { toast } from "react-toastify";
// mui
import {
  Box,
  Stack,
  Avatar,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// components
import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { ToastMessage } from "@common/Toast/ToastMessage";
import { TParsedAccount } from "@components/Login/useAccountSelectionModal";
// assets
import { ArrowsIcon } from "@assets/rebrandIcons";
import { SwitchAccountToastIcon } from "@assets/icons/SwitchAccountToastIcon";
// redux
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";

type ClickEventType = React.MouseEvent<HTMLElement>;

interface Props {
  accountList: TParsedAccount[];
  chooseAccount(e: ClickEventType, index: number): void;
  drawerOpen?: boolean;
}

export const PopperChildren = ({
  accountList,
  chooseAccount,
  drawerOpen,
}: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const selectedAccount = useAppSelector(selectSelectedAccount);

  const onChangeAccount = React.useCallback(
    (e: ClickEventType, index: number, accountName: string) => {
      const accName = accountName?.replace(/^./, accountName[0].toUpperCase());
      chooseAccount(e, index);
      toast.info(
        <ToastMessage
          message={`Switched to ${accName}`}
          type="Info"
          description={""}
          icon={<SwitchAccountToastIcon fill={palette.neutral.white} />}
        />,
        { autoClose: 2000 },
      );
    },
    [chooseAccount],
  );

  const sortedAccountList = sortBy(accountList, [
    (account) => account.id === selectedAccount?.id,
  ]);

  return (
    <>
      {sortedAccountList?.map((account, index) => {
        const switchIndex = accountList.findIndex((a) => a.id === account.id);

        return (
          <Tooltip
            key={account.id}
            placement="right"
            title={account.name}
            lineClamp={1}
            disableHoverListener={(drawerOpen && isDesktop) || !isDesktop}
          >
            <div>
              <MenuAccountItem
                drawerOpen={drawerOpen}
                isSelected={index === sortedAccountList.length - 1}
                onClick={(e) => {
                  onChangeAccount(e, switchIndex, account.merchType);
                }}
              >
                {index === sortedAccountList.length - 1 && (
                  <Stack
                    mr="2px"
                    sx={{
                      ...(drawerOpen && { position: "absolute", left: 0 }),
                    }}
                  >
                    <ArrowsIcon />
                  </Stack>
                )}

                <Avatar
                  src={addSizeToImage(account.img, "thumb")}
                  sx={{ height: 32, width: 32, borderRadius: "4px" }}
                />

                <Box>
                  <ProfileText
                    variant="caption"
                    fontWeight="regular"
                    drawerOpen={drawerOpen}
                  >
                    {account.name}
                  </ProfileText>
                  <ProfileText
                    variant="caption"
                    fontWeight="regular"
                    drawerOpen={drawerOpen}
                  >
                    {account.merchType}
                  </ProfileText>
                </Box>
              </MenuAccountItem>
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};

interface IDrawerProp {
  drawerOpen?: boolean;
}

const MenuAccountItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerOpen" && prop !== "isSelected",
})(({ drawerOpen, isSelected }: IDrawerProp & { isSelected: boolean }) => ({
  borderRadius: 8,
  display: "flex",
  padding: drawerOpen ? 8 : 4,
  marginLeft: drawerOpen ? 16 : 0,
  alignItems: "center",

  "&:hover": {
    cursor: "pointer",
    background: "#ECECE9", // neutral[10]
  },

  ...(!drawerOpen && {
    gap: "4px",
    flexDirection: "column-reverse",
  }),

  ...(isSelected && {
    pointerEvents: "none",
  }),

  ...(drawerOpen && {
    width: 213,
  }),
}));

const ProfileText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ drawerOpen }: IDrawerProp) => ({
  textTransform: "capitalize",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  position: "absolute",
  overflow: "hidden",
  opacity: 0,
  fontSize: 12,
  textAlign: "left",
  userSelect: "none",
  // transition: "opacity ease .3s",

  ...(drawerOpen && {
    marginLeft: "8px",
    opacity: 1,
    position: "static",
    transition: "opacity ease 3s",
    width: "100%",
  }),
}));
