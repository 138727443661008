import { palette } from "@palette";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { useModal } from "@ebay/nice-modal-react";
import PreviewInvitationModalHeader from "./PreviewInvitationModalHeader";
import PreviewInvitationModalCard from "./PreviewInvitationModalCard";
import PreviewInvitationModalFooter from "./PreviewInvitationModalFooter";
import { Box, Stack, StackProps } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { styled } from "@mui/material";

type Props = {
  receiverName: string;
  senderName: string;
  senderLogo?: string;
  isSendingToProvider: boolean;
};

const PreviewInvitationModalMobileDrawer = ({
  receiverName,
  senderName,
  senderLogo,
  isSendingToProvider,
}: Props) => {
  const modal = useModal();

  const handleModalClose = () => {
    modal.remove();
  };

  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={modal.visible}
      onClose={handleModalClose}
      PaperProps={{
        sx: {
          background: palette.background.dimmedWhite,
          top: "96px",
          position: "relative",
          height: "90%",
          padding: "24px 0",
          paddingBottom: "60px",
          overflow: "hidden",
        },
      }}
    >
      <Box height="100%">
        <PreviewInvitationModalTitle>
          <Box>Preview Invitation</Box>
          <X width={20} height={20} onClick={handleModalClose} />
        </PreviewInvitationModalTitle>
        <PreviewInvitationModalContainer>
          <PreviewInvitationModalHeader
            senderName={senderName}
            senderLogo={senderLogo}
            isSendingToProvider={isSendingToProvider}
          />
          <PreviewInvitationModalCard
            receiverName={receiverName}
            senderName={senderName}
          />
          <PreviewInvitationModalFooter
            isSendingToProvider={isSendingToProvider}
          />
        </PreviewInvitationModalContainer>
      </Box>
    </SwipeableDrawerMobile>
  );
};

export default PreviewInvitationModalMobileDrawer;

const PreviewInvitationModalContainer = styled(Stack)<StackProps>(() => ({
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "24px",
  backgroundColor: palette.background.newWhite,
  height: "100%",
  padding: "0 16px 24px 16px",
  overflowY: "auto",
}));

const PreviewInvitationModalTitle = styled(Stack)<StackProps>(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px 16px 16px",
  flexDirection: "row",
}));
