import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "@components/ProfilePage/form.components";
import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { ArrowLeft } from "@assets/icons";
import { UploadFile } from "@components/UploadFile";
import { palette } from "@palette";
import { useMemo, useRef } from "react";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useGetCurrentMerchantId } from "@hooks/common";
import { IDropzoneProps } from "react-dropzone-uploader";
import { usePersonalInformationProvider } from "../Provider/PersonalInformationProvider";
import { personalInfoEnum } from "../types";
import { DocumentItem } from "@components/Merchants/MerchantPreview/components/DocumentItem";
import { isEmpty } from "lodash";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { AcceptAllowedImagesTypes } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

function UploadIdTab() {
  const {
    onHandleSubmit,
    files,
    handleBack,
    handleUpdateStatusValue,
    handleNext,
  } = usePersonalInformationProvider();

  const hasFiles = useRef<boolean>(false);
  const { merchantId } = useGetCurrentMerchantId();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const ownerFiles = useMemo(
    () =>
      files?.data?.filter((doc: any) => doc?.attTypeName === "account_owner") ||
      [],
    [files],
  ) as TMerchantDocument[];

  const handlUpload = (file: File) => {
    if (ownerFiles?.length > 0) {
      return;
    }
    (async () => {
      await onHandleSubmit(personalInfoEnum.UPLOAD_YOUR_ID, file);
    })();
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
    allFiles,
  ) => {
    (async () => {
      if (ownerFiles.length > 0) return; // files max: 1
      hasFiles.current =
        [...allFiles].filter((x) => x.meta.status !== "removed").length > 0;

      const res: any = await onHandleSubmit(
        personalInfoEnum.UPLOAD_YOUR_ID,
        allFiles,
      );
      const isFailed = res === "upload_failed";
      const isDeleteLocal = isFailed || !isEmpty(res);
      isDeleteLocal && allFiles?.forEach((f) => f?.remove());
    })();
  };

  useEffect(() => {
    const updateValue = handleUpdateStatusValue(
      personalInfoEnum.UPLOAD_YOUR_ID,
    );
    if (ownerFiles?.length > 0) {
      updateValue(100);
    } else {
      updateValue(0);
    }
  }, [ownerFiles]);

  return (
    <Box flexGrow={1} display="flex" height="100%">
      <ProfileSetupFormContainer>
        <Stack direction="column" gap={4}>
          <ProfileSetupFormTitle
            title="Upload your ID"
            description="Upload an identification document such as a driver license, passport, or government issued ID card."
          />
          <FlaggedWrapper
            ActiveComponent={
              <UploadFileNew
                uploadFunction={handlUpload}
                accept={AcceptAllowedImagesTypes}
                maxFiles={1}
                supportedFormatText=".png, .jpg,.jpeg"
                styles={{
                  dropzone: {
                    flexDirection: "column-reverse",
                  },
                }}
              />
            }
            FallbackComponent={
              <UploadFile
                maxFiles={1}
                multiple={false}
                disabled={ownerFiles?.length > 0}
                backgroundColor="white"
                onChangeStatus={handleChangeStatus}
                width="100%"
                supportedFormatText=".png, .jpg,.jpeg"
                styles={{
                  dropzone: {
                    flexDirection: "column-reverse",
                  },
                }}
              />
            }
            name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
          />

          <Stack direction="column" gap={1}>
            {ownerFiles.map((document) => (
              <DocumentItem
                key={document.id}
                merchantID={merchantId}
                {...document}
                list={ownerFiles}
                hiddenActionProps={{ hidden: false }}
                showPreviewIcon
                showDetails={false}
                showActionButtonMobile
                ItemContainerProps={{
                  sx: {
                    p: "12px 8px",
                    borderRadius: "4px",
                  },
                }}
              />
            ))}
          </Stack>
        </Stack>
        <ProfileSetupFormActions
          secondaryAction={{
            onClick: handleBack,
            children: (
              <Box
                component="span"
                color={palette.black[100]}
                display="inline-flex"
                alignItems="center"
                gap="4px"
              >
                <ArrowLeft height={20} width={20} fill={palette.black[100]} />{" "}
                Back
              </Box>
            ),
          }}
          primaryAction={{
            disabled: ownerFiles?.length === 0,
            children: "Next",
            type: "button",
            onClick: handleNext,
          }}
        />
      </ProfileSetupFormContainer>
    </Box>
  );
}

export default UploadIdTab;
