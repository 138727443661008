import { RootState } from "@redux/store";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ThankYouCustomization } from "@sections/Checkout/ThankYouSettings";
import { ContentState, EditorState } from "draft-js";

type CheckoutState = {
  checkoutCardType: string;
  isRecurring: boolean;
  selectedVariant: VariantType;
  currentCampaign: {
    title: string;
  };
  thankYouPage: ThankYouCustomization;
  saveChangesEnabled: boolean;
};

type VariantType = {
  fundraiser: Record<string, any>;
  invoice: Record<string, any>;
};

const initialState: CheckoutState = {
  checkoutCardType: "",
  isRecurring: false,
  selectedVariant: {
    fundraiser: {
      min: 10,
      max: 25000,
    },
    invoice: {
      min: 10,
      max: 25000,
    },
  },
  currentCampaign: {
    title: "",
  },
  thankYouPage: {
    customMessage: {
      text: EditorState.createWithContent(
        ContentState.createFromText(
          "We wanted to take a moment to express our heartfelt gratitude for your generous contribution to. Your support is vital to our mission, and we are deeply grateful for your commitment. We will make sure that your contribution is used wisely and effectively to achieve our goals. Please know that your generosity is deeply appreciated.",
        ),
      ),
    },
    redirectUsers: {
      active: false,
      redirectURL: "",
    },
    displayIllustration: false,
  },
  saveChangesEnabled: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutCardType: (
      state: CheckoutState,
      action: PayloadAction<string>,
    ) => {
      state.checkoutCardType = action.payload;
    },
    setCurrentCampaign: (
      state: CheckoutState,
      action: PayloadAction<Partial<{ title: string }>>,
    ) => {
      state.currentCampaign = {
        ...state.currentCampaign,
        ...action.payload,
      };
    },
    setIsPaymentRecurring: (
      state: CheckoutState,
      action: PayloadAction<boolean>,
    ) => {
      state.isRecurring = action.payload;
    },
    setSelectedVariant: (state: CheckoutState, action: PayloadAction<any>) => {
      (state.selectedVariant as any)[action.payload.type] = action.payload;
    },
    setThankYouSettings: (
      state: CheckoutState,
      action: PayloadAction<ThankYouCustomization>,
    ) => {
      state.thankYouPage = action.payload;
    },
    switchSaveChangesEnabled: (
      state: CheckoutState,
      action: PayloadAction<boolean>,
    ) => {
      state.saveChangesEnabled = action.payload;
    },
  },
});

export const selectCardType = (state: RootState) =>
  state.checkout.checkoutCardType;
export const selectIsPaymentRecurring = (state: RootState) =>
  state.checkout.isRecurring;

export const selectVariant = (state: RootState) =>
  state.checkout.selectedVariant;

export const selectCurrentCampaign = (state: RootState) =>
  state.checkout.currentCampaign;
export const selectThankYouSettings = (state: RootState) =>
  state.checkout.thankYouPage;

export const {
  setCheckoutCardType,
  setIsPaymentRecurring,
  setSelectedVariant,
  setCurrentCampaign,
  setThankYouSettings,
  switchSaveChangesEnabled,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
