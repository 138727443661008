import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const SortDownIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill={`${fill}`}
      height={`${height}`}
      viewBox="0 0 9 6"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.85805 5.51604L0.993089 2.07809C0.450317 1.42676 0.913473 0.437903 1.76131 0.437903L7.49123 0.437904C8.33907 0.437904 8.80222 1.42676 8.25945 2.07809L5.39449 5.51604C4.9947 5.99579 4.25784 5.99579 3.85805 5.51604Z"
        fill={`${fill}`}
        stroke={`${stroke}`}
      ></path>
    </svg>
  );
};
export default SortDownIcon;
