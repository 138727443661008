import {
  CloudArrowUpIcon,
  PenSimpleIcon,
  SignatureIcon,
} from "@assets/rebrandIcons";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Text } from "@common/Text";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import NiceModal from "@ebay/nice-modal-react";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import {
  GENERATE_SIGNATURE_MODAL,
  MANUAL_SIGN_AGREEMENT_MODAL,
  UPLOAD_SIGNATURE_MODAL,
} from "modals/modal_names";

type Props = {
  addSignature: (file: File) => Promise<void>;
};

const SignAgreementModal = NiceModal.create(({ addSignature }: Props) => {
  const { open, onClose } = useNiceModal();

  const handleChoose = (modalName?: string) => {
    if (!modalName) return;

    onClose();
    NiceModal.show(modalName, { addSignature });
  };

  return (
    <EditMerchantBaseModal
      title="Sign Agreement"
      description="We need your signature to finalize the agreement."
      open={open}
      width="730px"
      handleCancel={onClose}
      sx={{
        "& .MuiPaper-root": {
          width: "730px !important",
          maxWidth: "730px !important",
        },
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Container>
        {signatureOptions.map((option) => (
          <Option
            key={option.label}
            onClick={() => handleChoose(option.modal)}
            data-testid={`signature-option-${option.label}`}
          >
            {option.icon}
            <Text fontWeight="book" color={palette.neutral[80]} mt={1.5} my={1}>
              {option.label}
            </Text>
            <Text
              fontSize={12}
              fontWeight="book"
              color={palette.neutral[70]}
              textAlign="center"
            >
              {option.description}
            </Text>
          </Option>
        ))}
      </Container>
    </EditMerchantBaseModal>
  );
});

const signatureOptions = [
  {
    icon: <CloudArrowUpIcon />,
    label: "Upload Signature",
    description:
      "Please upload a clear and legible image of your signature for verification purposes.",
    modal: UPLOAD_SIGNATURE_MODAL,
  },
  {
    icon: <SignatureIcon />,
    label: "Sign",
    description:
      "Use the paint tool on your trackpad or mouse to digitally replicate your signature for submission.",
    modal: MANUAL_SIGN_AGREEMENT_MODAL,
  },
  {
    icon: <PenSimpleIcon />,
    label: "Generate",
    description:
      "Generate a digital signature by entering your name and utilizing the automated signature creation feature.",
    modal: GENERATE_SIGNATURE_MODAL,
  },
];

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "16px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Option = styled(Box)(() => ({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  background: "#fff",
  boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
  height: "240px",
  flex: 1,
  cursor: "pointer",
}));

export default SignAgreementModal;
