import { Grid, Stack } from "@mui/material";
import { palette } from "@palette";
import React from "react";
import { BaseText } from "./atoms";

export function LeftColumnGrid({
  leftColData,
}: {
  leftColData: {
    [key: string]: {
      title: string;
      value: string | JSX.Element;
    };
  };
}) {
  return (
    <Stack gap={1} width="50%" pr={3}>
      {Object.values(leftColData).map((x) => (
        <Grid display="flex" alignItems="center" item key={x.title}>
          <Grid item xs={12} md={3} display="flex" alignItems="center">
            <BaseText value={x.title} width={150} />
          </Grid>
          <Grid item xs={12} md={9} lg={6}>
            {typeof x.value === "object" && x.value !== null ? (
              x.value
            ) : (
              <BaseText
                sx={{
                  ...(x.title === "Transaction ID"
                    ? { "wordBreak": "break-all" }
                    : {}),
                }}
                key={x.title}
                value={x.value}
                color={palette.neutral[800]}
              />
            )}
          </Grid>
        </Grid>
      ))}
    </Stack>
  );
}
