import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Image } from "@common/StyledImage/Image";
import HeaderTitleComponent from "@components/AvatarUpload/modal/components/HeaderTitleComponent";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dispatch, SetStateAction } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { MobileHeader } from "./PrimaryAccountHolder/PAHUploaderPreviewModal";
import { CaretLeft } from "@assets/icons";
import { clamper } from "../utils";

const MobileImagePreviewZoomModal = NiceModal.create(
  ({
    src,
    fileName,
    setClickedImage,
  }: {
    src: string;
    fileName: string;
    setClickedImage: Dispatch<SetStateAction<string>>;
  }) => {
    const { TransitionProps, onClose } = useNiceModal();
    const modal = useModal();
    const customOnClose = () => {
      modal.remove();
      setClickedImage("");
    };

    const clampedName = clamper(fileName);

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: true,
          onClose,
          DialogProps: {
            width: "100%",
            TransitionProps,
          },
          sx: {
            top: 1,
          },
          DrawerProps: {
            PaperProps: {
              sx: {
                height: "100%",
                borderRadius: 0,
                padding: 0,
              },
            },
          },
        }}
      >
        <HeaderTitleComponent
          isMobile
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            background: "rgba(41, 39, 39, 0.80)",
          }}
          onClick={customOnClose}
          title={
            <MobileHeader
              sx={{
                padding: "16px",
              }}
              onClose={customOnClose}
              text={clampedName}
              icon={<CaretLeft />}
            />
          }
        />
        <TransformWrapper
          disablePadding
          onInit={(e) => e.zoomIn()}
          doubleClick={{ disabled: true }}
          onPinchingStop={(e) => {
            e.resetTransform();
          }}
        >
          <PinchableImageComponent src={src} />
        </TransformWrapper>
      </ModalFactory>
    );
  },
);

const PinchableImageComponent = ({ src }: { src: string }) => {
  return (
    <TransformComponent
      wrapperStyle={{ height: "100%", width: "100%" }}
      contentStyle={{
        height: "100%",
        width: "100%",
      }}
    >
      <Image
        src={src}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          objectFit: "contain",
          backgroundColor: "rgba(41, 39, 39, 1)",
        }}
      />
    </TransformComponent>
  );
};

export default MobileImagePreviewZoomModal;
