import { useEffect } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useErrorUtilities } from "./useErrorUtilities";
import { useResetAction } from "./ResetActionContext";
import ErrorFallback from "./ErrorFallback";

type ErrorBoundaryProps<T> = {
  resetKeys?: T[];
  children: any;
  shouldLogError?: boolean;
  errorID: string;
  actionOnCrash?: () => void;
  customMessage?: string;
  shouldRetry?: boolean;
};

const ErrorCatcher = <T,>({
  shouldLogError = false,
  resetKeys = [],
  children,
  errorID,
  actionOnCrash,
  customMessage = "Something went wrong",
  shouldRetry = false,
}: ErrorBoundaryProps<T>) => {
  const { logError } = useErrorUtilities();
  const { getResetAction } = useResetAction();

  useEffect(() => {
    actionOnCrash && actionOnCrash();
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={(fallbackProps: FallbackProps) => (
        <ErrorFallback
          {...fallbackProps}
          customMessage={customMessage}
          shouldRetry={shouldRetry}
        />
      )}
      onError={shouldLogError ? logError : undefined}
      onReset={() => {
        const resetAction = getResetAction(errorID);
        if (resetAction) {
          resetAction();
        }
      }}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorCatcher;
