import { useAppSelector } from "@redux/hooks";
import { selectConversationTopic } from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { ApiResponse } from "../Modal/types";
import { useMemo } from "react";

export default function useGetActivityList({
  merchantId,
}: {
  merchantId?: number;
}) {
  const { queryObject } = useAppSelector(selectConversationTopic);
  const selectedThreadPathConversation = queryObject?.paths?.find(
    (item) => item?.isConversation,
  );
  const { data, ...rest } = useQuery(
    ["fetch-activity-list", queryObject?.id, merchantId, queryObject?.paths],
    async () => {
      const response: ApiResponse = await customInstance({
        url: `/merchants/${merchantId}/topics/${queryObject?.id}/threads?sort=-createdAt`,
      });

      return response;
    },
    {
      enabled: !!queryObject?.id && !!merchantId,
      refetchOnMount: true,
    },
  );

  const selectedThread = useMemo(() => {
    const found = data?.data?.find(
      (item) => item.id === selectedThreadPathConversation?.pathID,
    );
    return found;
  }, [selectedThreadPathConversation?.pathID, data?.data]);
  return {
    data,
    selectedThread,
    selectedThreadPathConversation,
    ...rest,
  };
}
