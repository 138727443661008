import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { checkPortals } from "@utils/routing";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";

const useUnderwriterPermissions = () => {
  const { isAcquirerEnterprises } = checkPortals();

  const isUpdateUnderwriterAllowed = useAccessControl({
    resource: composePermission(
      isAcquirerEnterprises ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.UNDERWRITING,
    ),
    operation: OPERATIONS.UPDATE,
  });

  return { isUpdateUnderwriterAllowed };
};

export default useUnderwriterPermissions;
