interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const RefreshIcon = ({
  width = 24,
  height = 24,
  fill = "#727179",
  stroke = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_1747_61373)">
        <path
          d="M21.7071 23.4298C22.0976 23.0392 22.0976 22.4061 21.7071 22.0155C21.3166 21.625 20.6834 21.625 20.2929 22.0155L21.7071 23.4298ZM15.0707 28.6519L14.3636 27.9448L14.3636 27.9448L15.0707 28.6519ZM15.0707 28.7934L14.3636 29.5005L14.3636 29.5005L15.0707 28.7934ZM20.2929 35.4298C20.6834 35.8203 21.3166 35.8203 21.7071 35.4298C22.0976 35.0392 22.0976 34.4061 21.7071 34.0155L20.2929 35.4298ZM20.2929 22.0155L14.3636 27.9448L15.7778 29.3591L21.7071 23.4298L20.2929 22.0155ZM14.3636 29.5005L20.2929 35.4298L21.7071 34.0155L15.7778 28.0863L14.3636 29.5005ZM14.3636 27.9448C13.934 28.3744 13.934 29.0709 14.3636 29.5005L15.7778 28.0863C16.1293 28.4377 16.1293 29.0076 15.7778 29.3591L14.3636 27.9448Z"
          fill={fill}
        />
        <path
          d="M27.0933 12.9727C28.0707 14.6656 28.5561 16.5978 28.4948 18.5516C28.4336 20.5055 27.8281 22.4035 26.7466 24.0319C25.6651 25.6603 24.1504 26.9544 22.3732 27.7686C20.596 28.5828 18.6268 28.8847 16.6873 28.6403"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14.2929 13.0155C13.9024 13.4061 13.9024 14.0392 14.2929 14.4298C14.6834 14.8203 15.3166 14.8203 15.7071 14.4298L14.2929 13.0155ZM20.9293 7.79337L21.6364 8.50047L21.6364 8.50047L20.9293 7.79337ZM20.9293 7.65195L21.6364 6.94484L21.6364 6.94484L20.9293 7.65195ZM15.7071 1.01555C15.3166 0.625025 14.6834 0.625025 14.2929 1.01555C13.9024 1.40607 13.9024 2.03924 14.2929 2.42976L15.7071 1.01555ZM15.7071 14.4298L21.6364 8.50047L20.2222 7.08626L14.2929 13.0155L15.7071 14.4298ZM21.6364 6.94484L15.7071 1.01555L14.2929 2.42976L20.2222 8.35905L21.6364 6.94484ZM21.6364 8.50047C22.066 8.0709 22.066 7.37442 21.6364 6.94484L20.2222 8.35905C19.8707 8.00758 19.8707 7.43773 20.2222 7.08626L21.6364 8.50047Z"
          fill={fill}
        />
        <path
          d="M8.90673 23.4727C7.91513 21.7552 7.43021 19.7919 7.5081 17.8103C7.58599 15.8286 8.22351 13.9095 9.34685 12.2751C10.4702 10.6408 12.0334 9.35794 13.8556 8.57516C15.6778 7.79239 17.6844 7.54167 19.6432 7.85203"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1747_61373"
          x="0"
          y="0.222656"
          width="36"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.10026 0 0 0 0 0.159687 0 0 0 0 0.3125 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1747_61373"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default RefreshIcon;
