import { palette } from "@palette";

export const userStyle = {
  fontSize: "14px",
  fontWeight: "400",
  color: palette.neutral[80],
  wordBreak: "break-all",
};

export const ownershipStyle = {
  fontSize: "12px",
  fontWeight: "350",
  color: palette.neutral[60],
};
