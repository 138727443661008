import CustomDateRangePicker from "@pages/Dashboard/components/CustomRangePicker";
import FilterItem from "./FilterItem";
import { MouseEventHandler, useState } from "react";
import moment from "moment";
import { generateCustomLabel } from "@common/TableFilters/utils";
import { useFormContext, useWatch } from "react-hook-form";
import { FilterValuesType } from "../hooks/useChangelogFilters";

export type DateValue = {
  startDate?: Date;
  endDate?: Date;
};

export default function DateSelector() {
  const { setValue } = useFormContext<FilterValuesType>();
  const savedValue: DateValue = useWatch({ name: "date" });
  const [rangePickerAnchorEl, setRangePickerAnchorEl] = useState(null);

  const title = savedValue
    ? generateCustomLabel({
        endDate: savedValue?.endDate,
        startDate: savedValue?.startDate,
        showColon: false,
      })
    : "Date";
  const [selectedValue, setSelectedValue] = useState<DateValue | undefined>(
    savedValue,
  );
  const customInitialRange = {
    startDate: undefined,
    endDate: undefined,
  };
  const handleClick = (event: React.MouseEvent<any>) =>
    setRangePickerAnchorEl(event.currentTarget);

  const handleSave = () => {
    if (selectedValue) {
      setValue("date", selectedValue);
    }
    setRangePickerAnchorEl(null);
  };

  const handleCancel: MouseEventHandler = (e) => {
    e.stopPropagation();
    setValue("date", undefined);
  };

  return (
    <>
      <FilterItem
        isSelected={title !== "Date"}
        title={title}
        onClick={handleClick}
        onCancel={handleCancel}
      />
      <CustomDateRangePicker
        customInitialRange={customInitialRange}
        setRangePickerAnchorEl={setRangePickerAnchorEl}
        rangePickerAnchorEl={rangePickerAnchorEl}
        isNewStyle
        handleSave={handleSave}
        onSelection={(e: { start: string; end: string }) => {
          setSelectedValue({
            startDate: moment(e.start, "MM-DD-YYYY").toDate(),
            endDate: moment(e.end, "MM-DD-YYYY").toDate(),
          });
        }}
        onCloseCallback={() => setSelectedValue(undefined)}
        ignoreMobileView
      />
    </>
  );
}
