import * as React from "react";
interface Props {
  height?: number;
  width?: number;
  path_fill?: string;
  stroke?: string;
  style?: React.CSSProperties;
}

const ImageCardIcon = ({ width = 32, height = 32, style }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect width="32" height="32" rx="4" fill="#292928" />
      <rect x="4" y="4" width="24" height="11" rx="1" fill="#B2B2B1" />
      <rect
        opacity="0.6"
        x="4"
        y="18"
        width="24"
        height="2"
        rx="1"
        fill="#B2B2B1"
      />
      <rect
        opacity="0.6"
        x="4"
        y="22"
        width="24"
        height="2"
        rx="1"
        fill="#B2B2B1"
      />
      <rect
        opacity="0.6"
        x="4"
        y="26"
        width="17"
        height="2"
        rx="1"
        fill="#B2B2B1"
      />
    </svg>
  );
};

export default ImageCardIcon;
