import * as React from "react";
import { palette } from "@palette";
import { IDropzoneProps } from "react-dropzone-uploader";
import { ContentState, EditorState } from "draft-js";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectAmounts,
  addAmount,
  AmountType,
  removeAmount,
  editAmount,
  setIncrementGlobalFundraiserAmountId,
  selectGlobalFundraiserAmountID,
} from "@redux/slices/fundraisers";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";
import { showMessage } from "@common/Toast";
import CreateAmountForm from "./components/CreateAmountForm";
import { isFunction } from "lodash";

type IFormInputs = {
  title: string;
  amount: string;
  active: boolean;
  thumbnail: string | File;
  description: {
    enabled: boolean;
    text: EditorState;
  };
};

type CreateAmountProps = {
  setIsCreateAmount: React.Dispatch<React.SetStateAction<boolean>>;
  initialObject?: AmountType;
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  lastActive?: boolean;
};

const CreateAmount = ({
  setIsCreateAmount,
  initialObject,
  setIsSubmitDisabled,
  lastActive,
}: CreateAmountProps) => {
  const dispatch = useAppDispatch();
  const amountList = useAppSelector(selectAmounts);
  const [isLoading, setIsLoading] = React.useState(false);
  const { handlePostImage } = useUploadVariantImage();
  const { t } = useTranslation(namespaces.pages.fundraiserMinibuilder);
  const amountID = useAppSelector(selectGlobalFundraiserAmountID);

  const isAmountExist = (amount: string) => {
    return amountList.some((item) => item.title === amount);
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    if (
      amountList.some(
        (amount) =>
          initialObject?.id !== amount.id && amount.title === data.title,
      )
    ) {
      return showMessage(
        "Error",
        "An element with the same name already exists",
      );
    }
    setIsLoading(true);
    let url;
    if (typeof data.thumbnail === "string") {
      url = data.thumbnail;
    } else {
      url = await handlePostImage(data.thumbnail);
    }

    const amount = {
      id: amountID,
      title:
        data.title === "" ? t("thank_you_for_your_contribution") : data.title,
      amount: data.amount,
      thumbnail: url,
      active: true,
      description: {
        enabled: data.description.enabled,
        text: data.description.text,
      },
    };

    if (initialObject) {
      dispatch(
        editAmount({
          ...amount,
          id: initialObject.id,
          active: initialObject.active,
        }),
      );
      setIsCreateAmount(false);
      isFunction(setIsSubmitDisabled) && setIsSubmitDisabled(false);
      return;
    }
    if (isAmountExist(data.title)) return;
    dispatch(addAmount(amount));
    setIsLoading(false);
    setIsCreateAmount(false);
    isFunction(setIsSubmitDisabled) && setIsSubmitDisabled(false);
    dispatch(setIncrementGlobalFundraiserAmountId());
  };
  const deleteAction = (id: string) => dispatch(removeAmount({ id }));
  return (
    <CreateAmountForm
      onSubmit={onSubmit}
      initialObject={initialObject}
      lastActive={lastActive}
      deleteAction={deleteAction}
      amountList={amountList}
      isLoading={isLoading}
      toggleModal={() => setIsCreateAmount(false)}
    />
  );
};

export default CreateAmount;
