import { Stack, styled } from "@mui/material";
import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Button } from "@common/Button";

const RiskProfileAlert = ({ onOpen }: { onOpen: () => void }) => {
  const { isDesktopView } = useCustomTheme();
  return (
    <Wrapper>
      <WarningIcon height={18} width={18} />
      <Text lineHeight="16.8px" color={palette.warning.text} flexGrow={1}>
        Attention required on Risk Profile
      </Text>
      {isDesktopView && (
        <CustomButton background="tertiary" onClick={onOpen}>
          Open
        </CustomButton>
      )}
    </Wrapper>
  );
};

const CustomButton = styled(Button)(() => ({
  color: palette.filled.orange,
  background: "inherit",
  padding: 0,
  fontSize: "14px",
  lineHeight: "16.8px",
  fontWeight: 350,
  height: "max-content",
}));

const Wrapper = styled(Stack)(() => ({
  borderRadius: "8px",
  padding: "8px 12px",
  border: "1px solid rgba(255, 129, 36, 0.50)",
  background: "#FFE7D6",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  userSelect: "none",
}));

export default RiskProfileAlert;
