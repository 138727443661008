interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const InfoFillIcon = ({
  width = 38,
  height = 38,
  fill = "#003182",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8675 0.666504C9.19001 0.666504 0.53418 9.32233 0.53418 19.9998C0.53418 30.6773 9.19001 39.3332 19.8675 39.3332C30.545 39.3332 39.2008 30.6773 39.2008 19.9998C39.2008 9.32233 30.545 0.666504 19.8675 0.666504ZM21.4786 9.93039C21.4786 8.81815 20.577 7.9165 19.4647 7.9165C18.3525 7.9165 17.4508 8.81815 17.4508 9.93039V10.4668C17.4508 11.579 18.3525 12.4807 19.4647 12.4807C20.577 12.4807 21.4786 11.579 21.4786 10.4668V9.93039ZM21.4786 18.702C21.4786 17.5898 20.577 16.6881 19.4647 16.6881C18.3525 16.6881 17.4508 17.5898 17.4508 18.702V30.3378C17.4508 31.45 18.3525 32.3517 19.4647 32.3517C20.577 32.3517 21.4786 31.45 21.4786 30.3378L21.4786 18.702Z"
        fill={fill}
      />
    </svg>
  );
};

export default InfoFillIcon;
