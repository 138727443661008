import { Stack } from "@mui/material";
import DisputeCaseHistoryCard from "@components/Disputes/DisputePreview/components/DisputeCaseHistoryCard";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useState } from "react";
import { Button } from "@common/Button";

const HIDDEN_HISTORY_HEIGHT = 318;
const SHOW_ALL_HISTORY_HEIGHT = 211;

const HISTORY_MOCK_DATA = [
  {
    date: "12 Mar 2024, 09:45",
    status: "ongoing",
    data: {
      title: "Issuing Bank",
      action: "Evidence Submitted",
      description: "Transaction Details Uploaded",
    },
  },
  {
    date: "10 Mar 2024, 09:45",
    hasEvidence: true,
    status: "done",
    data: {
      title: "Merchant (You)",
      action: "Evidence Submitted",
      description: "Transaction Details Uploaded",
    },
  },
  {
    date: "05 Mar 2024, 09:45",
    status: "done",
    data: {
      title: "Issuing Bank",
      action: "Chargeback Initiated",
      description: "Unauthorized Transaction",
    },
  },
];

const mockStatus = "won";

const DisputeCaseHistory = () => {
  const canHideHistory = ["won", "lost", "escalated", "closed"].includes(
    mockStatus,
  );
  const [isHidden, setIsHidden] = useState(canHideHistory);

  const data = isHidden ? HISTORY_MOCK_DATA.slice(0, 2) : HISTORY_MOCK_DATA;

  return (
    <Stack
      gap={1}
      sx={{
        overflow: "hidden",
        height: isHidden ? HIDDEN_HISTORY_HEIGHT : "auto",
        position: "relative",
      }}
    >
      {data.map((value, index) => (
        <FadeUpWrapper key={`history-card-${index}`} delay={(index + 1) * 150}>
          <DisputeCaseHistoryCard
            {...value}
            status={value.status as "done" | "ongoing"}
            isLast={index === data.length - 1}
          />
        </FadeUpWrapper>
      ))}

      {isHidden && (
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            background:
              "linear-gradient(180deg, rgba(248, 248, 246, 0) 0%, rgba(248, 248, 246, 1) 100%)",
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: SHOW_ALL_HISTORY_HEIGHT,
          }}
        >
          <Button
            size="medium"
            sx={{ marginBottom: "20px" }}
            onClick={() => setIsHidden(false)}
          >
            Show all History
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default DisputeCaseHistory;
