import React from "react";
import axios from "axios";
import { customInstance } from "@services/api";
import { debounce } from "lodash";
import {
  TFileAttachmentType,
  UploadProgressParams,
} from "@components/UploadFile/hooks/useUploadFiles";

type handleUploadProps = {
  list: { file: File; id: string }[];
  onUploadProgress: (params: UploadProgressParams) => void;
  onUploadFinish(params: { identifiers: (number | string)[] }): void;
  onUploadFailed(params: { fileIds: string[] }): void;
  attachmentType?: TFileAttachmentType;
};

export type HandlePresignedUploadReturnType = string[] | "upload_failed";

export const useUploadPresignedDocument = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpload = ({
    list,
    onUploadProgress,
    onUploadFailed,
    onUploadFinish,
    attachmentType = "bank_account",
  }: handleUploadProps) => {
    const customDocumentList: string[] = [];
    setIsLoading(true);
    const isConversation = attachmentType === "conversation_message";
    return new Promise<HandlePresignedUploadReturnType>(function (
      resolve,
      reject,
    ) {
      if (list.length === 0) return resolve([]);

      Promise.all(
        list.map((document) =>
          customInstance({
            url: `s3/presign-url`,
            method: "POST",
            data: {
              attachmentType: attachmentType,
              fileName: document.file.name,
            },
          }),
        ),
      )
        .then((res) => {
          return Promise.all(
            res.map((item, index) => {
              customDocumentList.push(
                isConversation ? item.presignedURL : item.URL,
              );
              return axios.put(item.presignedURL, list[index].file, {
                onUploadProgress: debounce((progressEvent: ProgressEvent) => {
                  onUploadProgress({
                    identifier: isConversation ? item.presignedURL : item.URL,
                    fileId: list[index].id,
                    progress:
                      (progressEvent.loaded * 100) / progressEvent.total,
                  });
                }, 100) as any,
              });
            }),
          );
        })
        .then(() => {
          onUploadFinish({ identifiers: customDocumentList });
          resolve(customDocumentList);
        })
        .catch(() => {
          onUploadFailed({ fileIds: list.map((file) => file.id) });
          resolve("upload_failed");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return { handleUpload, isLoading };
};
