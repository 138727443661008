import React, { useMemo, useRef, useState } from "react";
import { useGetBankAccounts } from "@services/api/products/manage-money";
import { TagType } from "@common/Tag";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { useCachedList } from "@hooks/common/useCachedList";
import { detectMobile } from "@utils/index";
import { useGetCurrentMerchantId } from "@hooks/common";
import { merchantBankAccountsParser } from "@components/Merchants/MerchantPreview/helpers/parsers";
import { useAppSelector } from "@redux/hooks";
import { sorting as sortingReducer } from "@redux/slices/fundraisers";
import { useAccessControl } from "features/Permissions/AccessControl";

import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

export type BankAccount = {
  id: string;
  name: string;
  bank: string;
  accountName: string;
  status: TagType;
  statusDescription: string;
  bankName: string;
  isDefault: boolean;
  nickname: string;
  notes: string;
  numberLast4: string;
  routingNumber: string;
  type: string;
  authPlaidInfo: string;
  createdWithPlaid?: boolean;
  isLinked?: boolean;
  enterprise?: boolean;
  approvedByID: number;
  approvedByName: string;
  approvedByEmail: string;
  approvedAt: number | null;
  declinedByID: number;
  declinedByName: string;
  declinedByEmail: string;
  declinedAt: number | null;
  createdAt: number;
  updatedAt: number;
  deletedAt: number | null;
};

function useListBankAccounts() {
  const loadingRef = useRef<boolean>(false);
  const { merchantId } = useGetCurrentMerchantId();
  const canCreateBA = useAccessControl({
    resource: RESOURCE_BASE.BANK_ACCOUNT,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  /*
        Some lists are inside a modal, therefore, is very likely the the modal
        is inside a page that also has a list. Because the sort is handle only once
        in redux, we want to avoid rerenders in the main page when a sort is 
        applyed to the secondary list
      */
  const { page, setPage } = usePagination(0, "");
  const sorting = useAppSelector(sortingReducer);

  const { data, isLoading, refetch, isRefetching } = useGetBankAccounts(
    { page, sorting, merchantId },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(merchantId),

      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const { allData } = useCachedList("list-all-bank-accounts");
  const usedData = detectMobile() ? allData : data?.data ?? [];

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const formatedRows = useMemo(
    () => (usedData.length ? merchantBankAccountsParser(usedData) : []),
    [usedData],
  );

  return {
    allRows: formatedRows,
    currentPageRows: formatedRows,
    handlePageChange,
    totalRows: data?.total ?? 0,
    page,
    setPage: () => setPage((current) => current + 1),
    loadingRef,
    rowsPerPage: ROWS_PER_PAGE,
    isLoading:
      isLoading ||
      isRefetching ||
      (formatedRows.length === 0 && data?.total > 0),
    sorting,
    refetch,
    isAllowedAddAccounts: data?.isAllowedAddAccounts && canCreateBA,
  };
}

export const getRenamedBankAccountStatus = (status: string) => {
  switch (status) {
    case "update_requested":
    case "pending":
      return "upload";
    case "pending_review":
      return "pending review";
    default:
      return status;
  }
};

export default useListBankAccounts;
