import * as React from "react";
import { palette } from "@palette";
import { useNavigate } from "react-router-dom";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { showMessage } from "@common/Toast/ShowToast";
// localization
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import Popup from "@common/Popup";

type SweepstakesSuccessModalProps = {
  name?: string;
};

const SweepstakesSuccessModal = NiceModal.create(
  ({ name }: SweepstakesSuccessModalProps) => {
    const modal = useModal();
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const { t } = useTranslation(namespaces.pages.sweepstakesMinibuilder);

    const handleClose = () => {
      modal.hide();
    };

    const openFundraisersHandler = () => {
      handleClose();
      navigate("/sweepstakes/advanced-builder");
    };

    const handleDone = () => {
      handleClose();
      showMessage(
        "Success",
        <>
          Your new Sweepstake{" "}
          <span style={{ fontWeight: "600" }}>“{name}”</span> has been
          successfully created and it’s ready to sell tickets.
        </>,
        isDesktop,
        "Sweepstake Created",
      );
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title="Sweepstake successfully created"
        onSubmit={handleDone}
        actionLabel={t("done")}
        data-testid="sweepstake_success_modal"
      >
        <Markup content={t("sweepstake_create_success_description")} />
      </Popup>
    );
  },
);

export default SweepstakesSuccessModal;
