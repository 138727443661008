import React from "react";

function ArrowUp({ fill = "#131212" }: any) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33903 0.246071C8.56751 0.0351639 8.91969 0.0351639 9.14818 0.246071L11.9876 2.86698C12.2296 3.09042 12.2447 3.46779 12.0213 3.70986C11.7978 3.95192 11.4205 3.96702 11.1784 3.74358L9.34009 2.04671L9.34009 9.85755C9.34009 10.187 9.07303 10.454 8.7436 10.454C8.41418 10.454 8.14712 10.187 8.14712 9.85755L8.14712 2.04671L6.3088 3.74358C6.06673 3.96702 5.68936 3.95192 5.46592 3.70986C5.24248 3.46779 5.25757 3.09042 5.49964 2.86698L8.33903 0.246071ZM1.53594 10.5715C1.86536 10.5715 2.13242 10.8386 2.13242 11.168L2.13242 13.3521C2.13242 13.9876 2.64765 14.5029 3.28325 14.5029H10.1117C10.4411 14.5029 10.7082 14.7699 10.7082 15.0994C10.7082 15.4288 10.4411 15.6958 10.1117 15.6958H3.28325C1.98882 15.6958 0.939453 14.6465 0.939453 13.3521L0.939453 11.168C0.939453 10.8386 1.20651 10.5715 1.53594 10.5715ZM15.9513 10.5715C16.2807 10.5715 16.5478 10.8386 16.5478 11.168L16.5478 13.3521C16.5478 13.9737 16.305 14.5399 15.9102 14.959C15.4835 15.4119 14.8764 15.6958 14.204 15.6958C13.8745 15.6958 13.6075 15.4288 13.6075 15.0994C13.6075 14.7699 13.8745 14.5029 14.204 14.5029C14.5342 14.5029 14.8312 14.3645 15.0419 14.1409C15.2364 13.9344 15.3548 13.6577 15.3548 13.3521L15.3548 11.168C15.3548 10.8386 15.6218 10.5715 15.9513 10.5715Z"
        fill={fill}
      />
    </svg>
  );
}

export default ArrowUp;
