import { billingDescriptorSchema } from "@components/ProfilePage/BusinessProfileSetup/MerchantInfo";
import { urlSchema } from "@utils/validation.helpers";
import { matchIsValidTel } from "mui-tel-input";
import * as Yup from "yup";

/*
  TO-DO: make it only once
  Almost similar with createMerchantSchema
  src/components/Merchants/CreateMerchantPanel/schemas/CreateMerchantSchema.ts
*/
const validator = (inputName = "Average Ticket Amount") => Yup.string();

export const getEditMerchantInfoSchema = (isAcquirerPortal: boolean) =>
  Yup.object().shape({
    averageTicketAmount: Yup.string(),
    highTicketAmount: Yup.string(),
    estimatedAnnualRevenue: Yup.string(),
    merchantName: Yup.string()
      .required("")
      .required("Name is required")
      .matches(
        /^(?=(.*[a-zA-Z]){3}).*$/,
        "Please provide a valid name with at least 3 letters",
      )
      .max(21, "Merchant Name can not contain more than 21 characters")
      .trim(),
    category: Yup.string().required("Choosing a category is required"),
    enterprise: Yup.number()
      .nullable()
      .when("category", {
        is: () => isAcquirerPortal,
        then: () =>
          Yup.number()
            .typeError("Please specify the provider")
            .required("Please specify the provider"),
      }),
    billingDescriptor: billingDescriptorSchema,
    merchantSlug: Yup.string().required("Permalink can’t be empty"),
    servicePhoneNumber: Yup.string().when({
      is: (exists: string) => !!exists,
      then: (schema) =>
        schema.test(
          "is-valid-number",
          "Please enter a valid phone number",
          function (value) {
            const phoneNumber = value as string;
            return matchIsValidTel(phoneNumber);
          },
        ),
    }),
    websiteURL: urlSchema({
      isRequired: false,
    }),
    isOutsideUSA: Yup.boolean(),
    countriesOutside: Yup.string()
      .nullable()
      .when("isOutsideUSA", {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .required(
              "Please enter the countries serviced outside of USA/Canada.",
            ),
      }),
    description: Yup.string().trim(),
    classification: Yup.string().nullable(),
    merchantRiskStatus: Yup.string().required(
      "Choosing a Merchant risk category is required",
    ),
  });
