import { IParsedData } from "../data.types";
import { useMemo, useRef } from "react";
import {
  settledTransactionsNormalizer,
  transactionsChartNormalizer,
} from "@services/api/analytics/transactions";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { prevYear } from "../hooks/useGetSettledPayments";
import { selectDateFilter } from "@redux/slices/analytics";
import { useAppSelector } from "@redux/hooks";
import { QKEY_ANALYTICS_CAMPAIGNS } from "@constants/queryKeys";

const subscriptionLabels = ["Monthly", "Quarterly", "Yearly"];

const useAnalyticsDataParser = (data: any): IParsedData => {
  const dateFilter = useAppSelector((state) =>
    selectDateFilter(state, QKEY_ANALYTICS_CAMPAIGNS),
  );

  const postedPaymentsOverview = useRef({
    total: 0,
    increment: 0,
    previousPeriod: 0,
  });

  const summary = {
    volume: data?.data?.sumPurchases / 100 || 0,
    availableBalance: data?.data?.sumGenerated / 100 || 0,
    approved: data?.data?.totalApprovedPurchases || 0,
    refunds: data?.data?.totalApprovedRefunds || 0,
    chargebacks: data?.data?.totalApprovedChargebacks || 0,
  };

  const trends = {
    transactionsAverage: {
      value: data?.data?.averageTotalPurchases || 0,
      increment: data?.data?.totalPurchasesRate || 0,
    },
    conversionAverage: {
      value: data?.data?.conversionRate || 0,
      increment: data?.data?.conversionRateProgression || 0,
    },
    visitorsAverage: {
      value: data?.data?.averageVisitors || 0,
      increment: data?.data?.visitorsRate || 0,
    },
    amountAverage: {
      value: data?.data?.averagePurchases / 100 || 0,
      increment: data?.data?.averagePurchasesRate || 0,
    },
  };

  const paymentsData = [
    {
      label: "Settled (USD)",
      value: data?.data?.sumApprovedPurchases / 100 || 0,
      isAmount: true,
    },
    {
      label: "Refunded (USD)",
      value: data?.data?.sumApprovedRefunds / 100 || 0,
      isAmount: true,
    },
    {
      label: "Chargeback Reversal (USD)",
      value: data?.data?.sumApprovedChargebackReversals / 100 || 0,
      isAmount: true,
    },
    {
      label: "Chargebacks (USD)",
      value: data?.data?.sumApprovedChargebacks / 100 || 0,
      isAmount: true,
    },
  ];

  const subscriptionCustomersData = useMemo(() => {
    if (!data?.customers?.recurringIntervalsCustomers)
      return subscriptionLabels.map((label) => ({ label, value: 0 }));

    return data.customers.recurringIntervalsCustomers.map((interval: any) => ({
      label: capitalizeFirstLetter(interval.intervalName),
      value: interval.totalCustomers,
    }));
  }, [data?.customers?.recurringIntervalsCustomers]);

  const postedPaymentsData = useMemo(() => {
    const currentYear = settledTransactionsNormalizer(
      data?.chart?.currentPostedChart,
      new Date().getFullYear(),
    );
    const previousYear = settledTransactionsNormalizer(
      data?.chart?.previousPostedChart,
      prevYear,
      true,
    );
    const current = currentYear.entries.reduce((acc, v) => {
      if (v.y > 0) return acc + v.y;
      return acc;
    }, 0);
    const prev = previousYear.entries.reduce((acc, v) => {
      if (v.y > 0) return acc + v.y;
      return acc;
    }, 0);
    postedPaymentsOverview.current.total = current;
    postedPaymentsOverview.current.previousPeriod = prev;
    const increment =
      prev === 0 ? (current === 0 ? 0 : 100) : (current * 100) / prev;
    postedPaymentsOverview.current.increment = increment;

    return [currentYear, previousYear];
  }, [data?.chart?.currentPostedChart, data?.chart?.previousPostedChart]);

  const recurringCustomersData = useMemo(
    () => recurrenceChartNormalizer(data?.chart?.recurrenceChart),
    [data?.chart?.recurrenceChart],
  );

  const generalData = useMemo(
    () =>
      transactionsChartNormalizer(!dateFilter?.type, data?.chart?.mainChart),
    [data?.chart?.mainChart],
  );

  const chartsOverview = {
    postedPayments: postedPaymentsOverview.current,
    recurringCustomers: {
      total: data?.recurringCustomers?.avgRecurringPurchases || 0,
      increment: data?.recurringCustomers?.RecurringCustomersRate || 0,
    },
  };

  return {
    summary,
    trends,
    chartsStats: {
      generalData,
      paymentsData,
      subscriptionCustomersData,
      postedPaymentsData,
      recurringCustomersData,
    },
    chartsOverview,
  };
};

type TData = {
  accID: number;
  date: Date;
  averagePerCustomer: number;
  recurringCustomers: number;
};

const mainChartKeys: Record<string, string> = {
  "Recurring Customers": "recurringCustomers",
  "Average amount (USD)": "averagePerCustomer",
};

const labels = ["Recurring Customers", "Average amount (USD)"];

const recurrenceChartNormalizer = (data: TData[]) => {
  if (!data) return labels.map((label) => ({ label, entries: [] }));

  return labels.map((label) => ({
    label,
    hidden: label === "Average amount (USD)",
    entries: data.map((chartData: TData) => {
      const value =
        chartData[mainChartKeys[label] as keyof Omit<TData, "date" | "accID">];
      return {
        x: chartData.date,
        y: label === "Average amount (USD)" ? value / 100 : value,
      };
    }),
  }));
};

export default useAnalyticsDataParser;
