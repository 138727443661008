import { useQuery } from "react-query";
import { customInstance } from "@services/api";

export type IReason = {
  id: number;
  name: string;
  triggerFactor: string;
  triggerFactorID: number;
};

export const useGetManageRiskReasons = ({
  riskFactorID,
}: {
  riskFactorID: number;
}) => {
  return useQuery<IReason[]>(
    ["get-manage-risk-reasons", riskFactorID],
    async () => {
      return await customInstance({
        method: "GET",
        url: `/risk/merchant-factors/${riskFactorID}/reasons
`,
      });
    },
  );
};
