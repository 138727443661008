import React from "react";
import { useAppDispatch } from "@redux/hooks";
import Cookies from "js-cookie";
import { useMutation } from "react-query";
import { customInstance } from "services/api";
import {
  login,
  Role,
  TAuthUser,
  updatePartialUser,
} from "@redux/slices/auth/auth";
import addDays from "date-fns/addDays";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import {
  ACCOUNT_SELECTION_MODAL,
  NO_ACCOUNT_MODAL,
  PASSWORD_EXPIRED_MODAL,
} from "modals/modal_names";
import { setSelectedAccount } from "@redux/slices/auth/accounts";
import { usePutAccount } from "@services/api/onboarding/user";
import { getGivecashToWebData } from "@utils/queryString";
import { TAccounts } from "@customTypes/accounts.types";

export const getUserRole = (role: string) => {
  if (role === "submerchant") return "merchant";
  if (role === "enterprise") return "provider";
  return (role || "merchant") as Role;
};

type IFormInputs = {
  email: string;
  password: string;
  remember: boolean;
  termsConditions: boolean;
};

type LoginConfigs = {
  accounts: TAccounts<"merchant">[];
  user: TAuthUser;
  userId: number;
  preSelectedAccount?: TAccounts<"merchant">;
  nextRoute: string;
};

export const useLogin = () => {
  const isMount = React.useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = React.useState("");
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const { mutate } = usePutAccount();
  const [isDifferentAccount, setIsDifferentAccount] = React.useState(false);

  const loginConfigs = React.useRef<LoginConfigs>();

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Please enter a valid email"),
    password: Yup.string().required("Password is required"),
    remember: Yup.boolean(),
    termsConditions: Yup.bool()
      .required("Agree to terms and conditions is required")
      .oneOf([true], "Agree to terms and conditions is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
    termsConditions: true,
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { watch, setValue } = methods;

  const signinMutation = useMutation((data: any) => {
    return customInstance({
      url: "/signin",
      method: "POST",
      withCredentials: true,
      data,
    });
  });

  const togglePasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLogin = ({
    accounts,
    user,
    userId,
    preSelectedAccount,
    nextRoute,
  }: LoginConfigs) => {
    const redirectParam = new URLSearchParams(window.location.search).get(
      "redirect",
    );

    if (accounts?.length > 1) {
      NiceModal.show(ACCOUNT_SELECTION_MODAL, {
        userAccID: userId,
        preSelectedAccount,
      });
    } else if (accounts?.length === 1) {
      const merchant = {
        id: accounts[0].id,
        userAccID: accounts[0].userAccID,
        userRole: accounts[0].userRole,
        name: accounts[0].name,
        userEmail: user.email,
        merchType: accounts[0].merchType,
        img: accounts[0].avatarURL,
      };
      const updatedUser = {
        ...user,
        id: merchant.id,
        name: merchant.name,
      };

      dispatch(setSelectedAccount(merchant));
      mutate(merchant.id);
      Cookies.set("user", JSON.stringify(updatedUser), { expires: 30 });

      dispatch(login(updatedUser));
      // if coming from mobile and has redirect route
      if (redirectParam) {
        navigate(redirectParam);
      } else {
        nextRoute ? navigate(nextRoute) : navigate(`/${user.role}`);
      }
    } else {
      Cookies.set("user", JSON.stringify(user), { expires: 30 });
      dispatch(login(user));

      if (redirectParam) {
        navigate(redirectParam);
      } else {
        navigate(`/${user.role}`);
      }
    }
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    if (!data.email || !data.password || signinMutation.isLoading) return;

    const customData = {
      email: data.email,
      password: data.password,
      hasAcceptedTC: data.termsConditions,
    };

    signinMutation.mutate(customData, {
      onError: (error: any) => {
        const errorMessage = error.response.data.message;
        if (errorMessage === "The provided password has expired.") {
          NiceModal.show(PASSWORD_EXPIRED_MODAL, { email: data.email });
          return;
        }
        setShowAlert("Incorrect Email or Password");
      },
      onSuccess: (res: any) => {
        if (
          res.user.passwordLastChangedAt &&
          addDays(new Date(res.user.passwordLastChangedAt * 1000), 90) <
            new Date()
        ) {
          NiceModal.show(PASSWORD_EXPIRED_MODAL, { email: data.email });
          return;
        }
        const user: TAuthUser = {
          id: res.user.accID,
          name: res.user.firstName,
          email: res.user.email,
          userAccID: res.user.accID,
          role: getUserRole(res.accessAccMerchType),
          img: res.user.imageURL,
          globalName: {
            firstName: res.user.firstName,
            lastName: res.user.lastName,
            phoneNumber: res.user.phoneNumber,
          },
          currency: res?.user.currency,
          language: res?.user.language,
          timezone: res?.user.timezone,
        };

        if (res.accessAccType === "merchant") {
          dispatch(
            updatePartialUser({
              userAccID: user.userAccID,
              globalName: user.globalName,
              img: res.user.imageURL,
            }),
          );
          const users: TAccounts<"merchant">[] = (res?.accounts || []).filter(
            (item: TAccounts) => item.type !== "user",
          );

          if (users.length === 0) {
            setShowAlert("Incorrect Email or Password");
            return;
          }
          let preSelectedAccount;
          const {
            acquirerId,
            enterpriseId,
            merchantId,
            fromMobile,
            merchantName,
          } = getGivecashToWebData();
          if (fromMobile) {
            let acquirer, enterprise, merchant;
            for (const acc of users || []) {
              if (acc.id === acquirerId) {
                acquirer = acc;
              }
              if (acc.id === enterpriseId) {
                enterprise = acc;
              }
              if (acc.id === merchantId) {
                merchant = acc;
              }
            }
            preSelectedAccount = acquirer || enterprise || merchant;
          }
          let nextRoute = "";
          if (preSelectedAccount?.merchType === "submerchant") {
            nextRoute = `/merchant/manage-money`;
          }

          if (
            preSelectedAccount?.merchType &&
            ["enterprise", "acquirer"].includes(preSelectedAccount?.merchType)
          ) {
            nextRoute = `/${
              preSelectedAccount.merchType === "enterprise"
                ? "provider"
                : preSelectedAccount.merchType
            }/processing?merchantName=${encodeURIComponent(merchantName)}`;
          }

          const handleLoginArgs: LoginConfigs = {
            accounts: users,
            userId: res.user.accID,
            user,
            nextRoute,
            preSelectedAccount,
          };

          // if selected merchant doesnt belong to the credentials entered
          if (fromMobile && !nextRoute) {
            setIsDifferentAccount(true);
            loginConfigs.current = handleLoginArgs;
            return;
          }
          handleLogin(handleLoginArgs);
        } else {
          NiceModal.show(NO_ACCOUNT_MODAL);
        }
      },
    });
  };

  const onContinueAnyway = () => {
    loginConfigs.current && handleLogin(loginConfigs.current);
  };

  const onSwitchAccount = () => {
    setIsDifferentAccount(false);
    methods.setValue("email", "");
    methods.setValue("password", "");
  };

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
      return;
    }
    if (showAlert) {
      setShowAlert("");
    }
  }, [watch("email"), watch("password")]);

  React.useEffect(() => {
    if (location.state) {
      setValue("email", (location.state as any)?.email);
      setShowAlert((location.state as any)?.alert);
      isMount.current = false;
    }
  }, []);

  return {
    isLoading: signinMutation.isLoading,
    showAlert,
    location,
    methods,
    isPasswordVisible,
    onSubmit,
    setShowAlert,
    togglePasswordVisible,
    isDifferentAccount,
    onSwitchAccount,
    onContinueAnyway,
  };
};
