import { Text } from "@common/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { TransactionFilterBase } from "@components/ManageMoney/TransactionTable/TransactionTable.atoms";
import { MerchantStatsType } from "@hooks/enterprise-api/account/useGetMerchants";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectMerchantStatusName,
  setMerchantStatusName,
} from "@redux/slices/enterprise/merchants";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useEffect } from "react";
import { checkPortals } from "@utils/routing";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type Props = {
  stats: MerchantStatsType;
  hideTableView?: boolean;
  isEnterPrise?: boolean;
};

const MerchantStatusFilters = ({
  stats,
  hideTableView = false,
  isEnterPrise = false,
}: Props) => {
  const { levelFilters } = useLevelsFilters({
    stats,
    hideTableView,
    isEnterPrise,
  });
  const { isDesktopView } = useCustomTheme();

  return (
    <Stack
      direction="row"
      spacing={isDesktopView ? 2 : 0}
      sx={{
        width: isDesktopView ? "100%" : "93%",
        borderBottom: hideTableView ? "none" : "1px solid",
        overflow: "auto",
        scrollbarWidth: 'none',
        borderBottomColor: palette.neutral[20],
        ...(!isDesktopView && {
          marginX: "12px",
        }),
      }}
    >
      {levelFilters.map(({ label, onClick, selected, stats, hidden }) => {
        if (hidden) return;
        if (!hideTableView)
          return (
            <LevelContainer
              key={label}
              onClick={onClick}
              selected={selected}
              data-testid="level-filter"
            >
              <LevelLabel selected={selected}>{label}</LevelLabel>
              <LevelStats>{stats}</LevelStats>
            </LevelContainer>
          );
        else
          return (
            <TransactionFilterBase
              key={label}
              color={palette.black[200]}
              selected={selected}
              onClick={onClick}
            >
              {`${label} (${stats})`}
            </TransactionFilterBase>
          );
      })}
    </Stack>
  );
};

const useLevelsFilters = ({ stats, hideTableView, isEnterPrise }: Props) => {
  const statusName = useAppSelector(selectMerchantStatusName);
  const { isSponsorApprovalEnabled } = useGetFeatureFlagValues();

  const dispatch = useAppDispatch();
  const { isEnterprisePortal } = checkPortals();

  const {
    risk_monitoring,
    merchant_triggers,
    risk_activity,
    merchant_underwriting,
    merchant_underwriting_statuses,
  } = useEnterprisePermissions();

  useEffect(() => {
    return () => {
      dispatch(setMerchantStatusName(""));
    };
  }, []);

  const underwritingStatus =
    !hideTableView && !isEnterPrise
      ? StatusNames.aquirerUnderwriting
      : StatusNames.enterpriseUnderwriting;
  const riskOrApprovedStatus = !hideTableView
    ? StatusNames.aquirerRisk
    : StatusNames.enterpriseApproved;

  const sponsorStatus = StatusNames.aquirerSponsor;

  const enterpriseLevels = [
    {
      label: `Inoperative`,
      stats: stats?.countInoperative,
      onClick: () =>
        dispatch(setMerchantStatusName(StatusNames.enterpriseInoperative)),
      selected: statusName === StatusNames.enterpriseInoperative,
      hidden: false,
    },
  ];

  const levelFilters = [
    {
      label: !hideTableView ? `Portfolio` : `All`,
      stats: !hideTableView ? stats?.countTotalAquirer : stats?.countTotal,
      onClick: () => dispatch(setMerchantStatusName("")),
      selected: statusName === "",
      hidden: false,
    },
    {
      label: `Underwriting`,
      stats: !hideTableView
        ? stats?.countAquirerUnderwriting
        : stats.countUnderwriting,
      onClick: () => dispatch(setMerchantStatusName(underwritingStatus)),
      selected: statusName === underwritingStatus,
      hidden: !(merchant_underwriting || merchant_underwriting_statuses),
    },
    {
      label: "Sponsor",
      stats: stats?.sponsorCount,
      onClick: () => dispatch(setMerchantStatusName(sponsorStatus)),
      selected: statusName === sponsorStatus,
      hidden: isEnterPrise || isEnterprisePortal || !isSponsorApprovalEnabled,
    },
    {
      label: !hideTableView ? `Risk` : "Approved",
      stats: !hideTableView ? stats?.countRisk : stats.countApproved,
      onClick: () => dispatch(setMerchantStatusName(riskOrApprovedStatus)),
      selected: statusName === riskOrApprovedStatus,
      hidden:
        !(risk_monitoring || merchant_triggers || risk_activity) ||
        isEnterPrise,
    },
  ];

  return {
    levelFilters: !hideTableView
      ? levelFilters
      : [...levelFilters, ...enterpriseLevels],
  };
};

export const StatusNames = {
  aquirerUnderwriting: `(statusDisplayName:"Suspended",statusDisplayName:"Declined", statusDisplayName:"Pre Approved", statusDisplayName:"Ready For Approval")`,
  aquirerRisk: `(statusDisplayName:"Approved", statusDisplayName:"Suspended")`,
  aquirerSponsor: `(lastInternalApprovalTypeDisplayName:"Auto-approval", lastInternalApprovalTypeDisplayName:"Pre-approval")`,
  enterpriseUnderwriting: `(statusDisplayName:"Pre Approved", statusDisplayName:"Ready For Approval")`,
  enterpriseApproved: `statusDisplayName:"Approved"`,
  enterpriseInoperative: `(statusDisplayName:"Suspended",statusDisplayName:"Declined")`,
};

const LevelLabel = styled(Text, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean; color?: string }>(({ selected }) => ({
  color: selected ? palette.black[100] : palette.gray[300],
  lineHeight: "16.8px",
  userSelect: "none",
  gap: "4px",
  "&:hover": {
    color: palette.black[100],
  },
}));

const LevelStats = styled(Text)(() => ({
  color: palette.neutral.white,
  lineHeight: "13.2px",
  padding: "0px 4px",
  userSelect: "none",
  fontSize: "11px",
  fontWeight: 400,
  backgroundColor: palette.accent[3],
  borderRadius: "100px",
}));

const LevelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ selected, theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  borderBottom: selected ? "2px solid" : "none",
  borderBottomColor: palette.neutral[100],
  ...(!selected && {
    cursor: "pointer",
  }),
  padding: "8px 12px",
  gap: "4px",
  flex: "initial",
  [theme.breakpoints.down("sm")]: {
    flex: 1,
  },
}));

export default MerchantStatusFilters;
