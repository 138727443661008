import { showMessage } from "@common/Toast";
import { topojson } from "chartjs-chart-geo";
import { useEffect } from "react";
import { useQuery } from "react-query";

const useMapTopography = () => {
  const { data, error } = useQuery(
    "get-map-topography",
    async () => {
      const response = await fetch("https://unpkg.com/us-atlas/states-10m.json");
      const data = await response.json();
      const nation = (topojson.feature(data, data.objects.nation) as any)
        .features[0];
      const states = (topojson.feature(data, data.objects.states) as any)
        .features;
      return { nation, states };
    },
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (error) {
      showMessage("Error", "Whoops... an error occured while loading the map");
    }
  }, [error]);

  return { data };
};

export default useMapTopography;
