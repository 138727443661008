import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Divider, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { RHFInput } from "@common/Input";
import { Button } from "@common/Button";
import { useEffect } from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { MedalIcon } from "@assets/icons/RebrandedIcons";
import { palette } from "@palette";
import { RHFCheckbox } from "@common/Checkbox";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { AssignToMe } from "./components/AssignToMe";
import { Text } from "@common/Text";

type FormInputs = {
  email: string;
  invite: boolean;
  assignToMe: boolean;
};

type Props = {
  data: FormInputs;
  onClose?: (data: FormInputs) => void;
  merchantId?: number;
  isEdit?: boolean;
  isEnterprise?: boolean;
};

const CreatePrimaryAccountHolder = NiceModal.create(
  ({ data, merchantId, onClose, isEdit, isEnterprise }: Props) => {
    const modal = useModal();
    const open = modal.visible;
    const { isMobileView } = useCustomTheme();
    const { handleSubmit, isLoading } = useUpdateMerchantInfo(
      merchantId || 0,
      undefined,
      isEdit,
    );

    const schema = Yup.object({
      email: Yup.string()
        .email("Email not valid")
        .when("assignToMe", {
          is: (value: boolean) => !value,
          then: (schema) => schema.required("Enter account holder email"),
        }),
      invite: Yup.boolean(),
      assignToMe: Yup.boolean(),
    });

    const defaultValues = {
      email: "",
      invite: false,
      assignToMe: false,
    };

    const methods = useForm<FormInputs>({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      reset,
      formState: { isValid, isDirty },
      watch,
      clearErrors,
    } = methods;
    const values = watch();

    useEffect(() => {
      clearErrors("email");
    }, [data.assignToMe]);

    useEffect(() => {
      reset({
        invite: data.invite,
        email: data.email,
        assignToMe: data.assignToMe,
      });
    }, [data, modal.visible]);

    const handleCancel = () => {
      reset();
      modal.hide();
    };

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      if (merchantId) {
        handleSubmit("primary_account_holder", data);
      }
      if (onClose) onClose(data);
      modal.hide();
    };

    return (
      <EditMerchantBaseModal
        title="Primary Account Holder"
        description="The Primary Account Holder has full control over the payment account, including the ability to add users, manage funds, and update settings."
        open={open}
        handleCancel={handleCancel}
        icon={<MedalIcon color={palette.neutral[70]} />}
        actions={
          <Box margin={isMobileView ? "0 auto" : "initial"}>
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="edit-primary-account-holder"
              disabled={!isValid || isLoading || (isEdit && !isDirty)}
              sx={{
                padding: "8px 24px",
              }}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
          </Box>
        }
        PaperProps={{
          style: {
            background: "#FBFBFB",
            borderRadius: "12px",
            maxWidth: "656px",
            maxHeight: "400px",
            top: "25%",
          },
          sx: {
            "& .MuiDialogTitle-root + .MuiDialogContent-root": {
              paddingTop: "0 !important",
              paddingBottom: "0 !important",
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&::-webkit-scrollbar-track": {
              display: "none",
            },
            "& button": {
              width: "200px",
            },
          },
        }}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-primary-account-holder"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {!isEnterprise && (
              <>
                <AssignToMe />
                <Divider sx={{ marginY: 1.5 }}>
                  <Text fontWeight="book" color={palette.neutral[80]}>
                    or
                  </Text>
                </Divider>
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RHFInput
                  name="email"
                  label="Email"
                  placeholder="Email"
                  fullWidth
                  disabled={Boolean(values.assignToMe)}
                />
              </Grid>
              {!isEdit && (
                <Grid item xs={12}>
                  <RHFCheckbox
                    name="invite"
                    label="Invite upon creation"
                    disabled={Boolean(values.assignToMe)}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

export default CreatePrimaryAccountHolder;
