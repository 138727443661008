import { customInstance } from "@services/api";
import { useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { merchantDataParser } from "../helpers/parsers";
import { updatePermissions } from "@redux/slices/app";
import { useAppDispatch } from "@redux/hooks";
import { useGetCurrentMerchantId } from "@hooks/common";
import { getSettledPromise } from "@utils/index";
import { TMerchantDoublePanel } from "./useMerchantPreview";

type Props = {
  id: number;
  isEnterprise?: boolean;
  doublePanel?: TMerchantDoublePanel;
};

const setPermissionError = (
  promise: PromiseSettledResult<any>,
  permissionKey: string,
  dispatch: any,
) => {
  if (promise?.status === "rejected" && promise?.reason?.not_authorized) {
    dispatch(updatePermissions({ [permissionKey]: true }));
  }
};

export const useMerchantDataPreview = ({
  id,
  isEnterprise,
  doublePanel,
}: Props) => {
  const dispatch = useAppDispatch();
  const { merchantId: accountID } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["get-merchant-preview", id],
    async () => {
      let merchantUrls = [
        `merchants/${id}`,
        `/merchants/${id}/bank-accounts`,
        `/accounts/${id}/files?sort=-updatedAt&filter=isUploaded:true`,
        `/merchants/${id}/acquirer`,
      ];

      if (isEnterprise) {
        merchantUrls = [...merchantUrls, `/enterprises?filter=accID:${id}`];
      } else {
        merchantUrls = [...merchantUrls, `/merchants/${id}/stats`];
      }

      if (isEnterprise) {
        merchantUrls = [...merchantUrls, `merchants/${accountID}`];
      }

      const merchantResponses = await Promise.allSettled(
        merchantUrls.map((url) => customInstance({ url })),
      );

      const [
        merchant,
        bankAccounts,
        documents,
        aquirerTcVersion,
        stats,
        parent,
      ] = merchantResponses.map((res) => getSettledPromise(res));

      let legalEntityUrls: string[] = [];

      if (merchant?.legalEntityID) {
        legalEntityUrls = [
          `merchants/${id}/legal-entities/${merchant?.legalEntityID}`,
        ];
      }
      if (merchant?.approvedBy) {
        legalEntityUrls = [
          ...legalEntityUrls,
          `/users/${merchant?.approvedBy}`,
        ];
      }
      const userLegalEntityResponses = await Promise.allSettled(
        legalEntityUrls.map((url) => customInstance({ url })),
      );

      const [legalEntity, approver] = userLegalEntityResponses.map((res) =>
        getSettledPromise(res),
      );

      const permissionErrors = [
        { permissionKey: "merchant_data", promise: merchantResponses[0] },
        { permissionKey: "merchant_stats", promise: merchantResponses[1] },
        { permissionKey: "bank_accounts_list", promise: merchantResponses[2] },
        { permissionKey: "list-documents", promise: merchantResponses[3] },
      ];

      permissionErrors.forEach((error) => {
        setPermissionError(error.promise, error.permissionKey, dispatch);
      });

      return {
        merchant,
        aquirerTcVersion: aquirerTcVersion?.msaVersion,
        legalEntity,
        bankAccounts: bankAccounts?.data || [],
        parent,
        stats: isEnterprise ? stats?.data?.[0] : stats,
        documents: documents?.data || [],
        principals: legalEntity?.principals || [],
        approver,
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
      onSuccess: () => {
        queryClient.invalidateQueries("get-changelog");
        if (doublePanel === "manageUnderwriting")
          queryClient.invalidateQueries("get-challenges");
        queryClient.invalidateQueries("get-challenges-stats");
      },
    },
  );

  const isMSPRequestEnabled =
    data?.merchant?.processorName &&
    (data?.merchant?.processorName === "rs2_ches" ? data?.merchant?.processorSupportStatusName === "enabled" : true);

  const { data: mspData, isLoading: mspStatusisLoading } = useQuery(
    ["get-merchant-msp-status", id, isMSPRequestEnabled],
    async () =>
      await customInstance({
        url: `/merchants/${id}/msp/is-ready`,
        method: "GET",
      }),
    { enabled: Boolean(id && isMSPRequestEnabled) },
  );

  const linkedBankAccount = data?.merchant?.linkedBankAccount
    ? [{ ...data?.merchant.linkedBankAccount, enterprise: true }]
    : [];

  const customData = useMemo(
    () =>
      merchantDataParser(
        {
          ...data,
          mspStatus: mspData,
          bankAccounts: [...(data?.bankAccounts || []), ...linkedBankAccount],
        },
        accountID,
      ),
    [id, data, mspData],
  );

  return {
    data: customData,
    merchant: data?.merchant,
    isLoading: isLoading || mspStatusisLoading,
  };
};
