import Xicon from "@assets/icons/Xicon";
import { Box, Stack } from "@mui/material";
import { BreadCrumbText } from "../../manageUnderwriting/Header";

type Props = {
  onClose: () => void;
  handleManageMerchantAgreement: () => void;
};

const SnapshotAgreementHeader = ({
  handleManageMerchantAgreement,
  onClose,
}: Props) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        data-testid="merchant-application-header-container"
      >
        <BreadCrumbText
          isNext
          text="Merchant Application"
          active={false}
          onClick={handleManageMerchantAgreement}
        />
        <BreadCrumbText active text="Snapshot" />
      </Stack>
      <button
        style={{
          all: "unset",
          cursor: "pointer",
        }}
        onClick={onClose}
        data-testid="snapshot-agreement-header-close-button"
      >
        <Xicon />
      </button>
    </Box>
  );
};

export default SnapshotAgreementHeader;
