type IconProps = {
  height?: number;
  width?: number;
  fill?: string;
  color?: string;
};

const DownIcon = ({ width = 24, height = 24, fill = "none", color }: IconProps) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32493 7.97994C1.12967 7.78468 0.813084 7.78468 0.617821 7.97994C0.422559 8.1752 0.422559 8.49179 0.617821 8.68705L1.32493 7.97994ZM5.90138 13.2635L5.54782 13.6171L5.54782 13.6171L5.90138 13.2635ZM6.04137 13.2635L5.68782 12.9099L5.68782 12.9099L6.04137 13.2635ZM11.3249 8.68705C11.5202 8.49179 11.5202 8.1752 11.3249 7.97994C11.1297 7.78468 10.8131 7.78468 10.6178 7.97994L11.3249 8.68705ZM5.47137 12.5534C5.47137 12.8296 5.69523 13.0534 5.97137 13.0534C6.24752 13.0534 6.47137 12.8296 6.47137 12.5534L5.47137 12.5534ZM6.47138 0.833494C6.47138 0.557352 6.24752 0.333494 5.97138 0.333494C5.69523 0.333494 5.47138 0.557352 5.47138 0.833494L6.47138 0.833494ZM0.617821 8.68705L5.54782 13.6171L6.25493 12.9099L1.32493 7.97994L0.617821 8.68705ZM6.39492 13.6171L11.3249 8.68705L10.6178 7.97994L5.68782 12.9099L6.39492 13.6171ZM6.47137 12.5534L6.47138 0.833494L5.47138 0.833494L5.47137 12.5534L6.47137 12.5534ZM5.54782 13.6171C5.78214 13.8514 6.16061 13.8514 6.39493 13.6171L5.68782 12.9099C5.84403 12.7537 6.09872 12.7537 6.25493 12.9099L5.54782 13.6171Z"
        fill={color || "#4C4A52"}
      />
    </svg>
  );
};

export default DownIcon;
