import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useFileUploadContext } from "./FileUploadContext";
import { UploadCard } from "./FileUploadSnackbar";
import { FileUploadStatus, SnackbarFile } from "./types";
import {
  DndContext,
  DragMoveEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";

//when released under this value, it will be deleted
const DRAG_TRESHOLD = 140;

const animatedStyle = {
  animation: "outAnimation 270ms ease-out",
  opacity: 0.1,
  "@keyframes outAnimation": {
    "0%": {
      transform: "translateX(0)",
      opacity: 1,
    },
    "100%": {
      transform: `translateX(${-DRAG_TRESHOLD}px)`,
      opacity: 0.1,
    },
  },
};

const bounceAnimation = {
  animation: "bounceInRight 0.75s ease",
  "@keyframes bounceInRight": {
    "0%": {
      opacity: 0,
      transform: "translateX(-100%)",
    },
    "25%": {
      transform: "translateX(12px)",
    },
    "50%": {
      transform: "translateX(-8px)",
    },
    "75%": {
      transform: "translateX(3px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
};

enum AnimationEnum {
  FADE,
  BOUNCE,
}

export default function FileUploadDesktopView() {
  const { snackbarFiles, setSnackbarFiles, isEveryFileUploaded } =
    useFileUploadContext();
  const [expanded, setExpanded] = React.useState(false);
  const [draggedItem, setDraggedItem] = React.useState<SnackbarFile | null>(
    null,
  );
  const [currentAnimation, setCurrentAnimation] =
    React.useState<AnimationEnum | null>(AnimationEnum.BOUNCE);
  const [dragX, setDragX] = useState<number>(0); //DragOverlay does not have it's own transform, we need the actual item
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const sensors = useSensors(mouseSensor, touchSensor);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>();

  const handleDragEnd = useCallback(
    (event: DragMoveEvent) => {
      if (event.delta.x < -DRAG_TRESHOLD || event.delta.x > DRAG_TRESHOLD) {
        setSnackbarFiles((prev) =>
          prev.filter((item) => item.identifier !== event.active.id && item.name !== event.active.id),
        );
      }
      setDraggedItem(null);
      setDragX(0);
    },
    [snackbarFiles],
  );

  const handleDragStart = (event: DragStartEvent) => {
    const item = snackbarFiles.find(
      (item) => item.identifier === event.active.id || item.name === event.active.id,
    );
    if (item) setDraggedItem(item);
  };

  const handleDragMove = (event: DragMoveEvent) => {
    //we will use this for the opacity effect
    setDragX(event.delta.x);
  };

  const handleMouseEnter = () => {
    closeTimeoutRef.current && clearTimeout(closeTimeoutRef.current);
    !currentAnimation && setExpanded(true);
  };

  const handleMouseLeave = () => {
    !currentAnimation && !draggedItem && setExpanded(false);
  };

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (
        snackbarFiles.length > 0 &&
        isEveryFileUploaded &&
        !expanded &&
        !draggedItem
      ) {
        setCurrentAnimation(AnimationEnum.FADE);
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [snackbarFiles, isEveryFileUploaded, expanded]);

  useEffect(() => {
    const closeTimeout = setTimeout(() => {
      if (expanded && !draggedItem) {
        setExpanded(false);
      }
    }, 3000);
    closeTimeoutRef.current = closeTimeout;
    
    return () => {
      clearTimeout(closeTimeout);
    }
  }, [draggedItem]);

  const handleAnimationEnd = () => {
    if (currentAnimation === AnimationEnum.BOUNCE) {
      setCurrentAnimation(null);
      return;
    }
    setSnackbarFiles([]);
    setCurrentAnimation(AnimationEnum.BOUNCE);
  };

  const animStyle = useMemo(() => {
    switch (currentAnimation) {
      case AnimationEnum.BOUNCE:
        return bounceAnimation;
      case AnimationEnum.FADE:
        return animatedStyle;
      default:
        return {};
    }
  }, [currentAnimation]);

  if (snackbarFiles.length < 1) return null;

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragMove={handleDragMove}
      sensors={sensors}
    >
      <Box
        sx={{
          position: "fixed",
          zIndex: 1500,
          left: "80px",
          bottom: "0px",
          width: "400px",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid="snackbar-desktop-container"
      >
        {expanded || !!draggedItem ? ( //to avoid glitches, it's better to keep list expanded while dragging
          <Stack
            direction="column-reverse"
            alignItems="center"
            spacing={1}
            sx={{
              maxHeight: "100vh",
              overflowY: "auto",
              paddingTop: "30px",
              paddingBottom: "30px",
              paddingLeft: "15px",
              paddingRight: "55px",
              overflowX: "hidden",
            }}
            data-testid="snackbar-desktop-expanded-container"
          >
            {snackbarFiles.map((file: SnackbarFile) => {
              return (
                <UploadCard
                  key={file.id}
                  snackbarFile={file}
                  style={{
                    width: "100%",
                  }}
                  isDraggingEnabled={
                    file.status !== FileUploadStatus.IN_PROGRESS
                  }
                />
              );
            })}
          </Stack>
        ) : (
          <Stack
            direction="column-reverse"
            alignItems="center"
            spacing={-5.5}
            sx={{
              paddingBottom: "30px",
              paddingLeft: "15px",
              paddingRight: "55px",
              ...animStyle,
            }}
            data-testid="snackbar-desktop-collapsed-container"
            onAnimationEnd={handleAnimationEnd}
          >
            {snackbarFiles
              .slice(0, 3)
              .map((file: SnackbarFile, index: number) => {
                return (
                  <UploadCard
                    snackbarFile={file}
                    key={file.id}
                    progressVisibleOnCollapse={index === 0}
                    style={{
                      // In mockups, when stack is collapsed each next item is approximately 16px smaller than previous
                      width: `calc(100% - ${index * 16}px)`,
                    }}
                  />
                );
              })}
          </Stack>
        )}
      </Box>
      <DragOverlay zIndex={1501} modifiers={[restrictToHorizontalAxis]}>
        {draggedItem ? (
          <UploadCard
            key={draggedItem.id}
            snackbarFile={draggedItem}
            style={{
              width: "100%",
            }}
            dragX={dragX}
            isDraggingEnabled
            isOverlay
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}
