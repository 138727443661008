import { Stack } from "@mui/material";
import { Text } from "@common/Text";
import MerchantAgreementIcon from "@assets/icons/MerchantAgreementIcon";
import { ITextProps } from "@common/Text/Text";
import moment from "moment";
import { palette } from "@palette";
import { ChevronRightIcon, ConversationsIconNotFilled } from "@assets/icons";
import { TMerchantAgreementPayload } from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { useMemo } from "react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { EditButton } from "@components/Merchants/MerchantPreview/components/atoms";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";

export const getAgreementStatus = (merchantData: any) => {
  const isCorrectVersion =
    merchantData.msaLastAcceptedVersion === merchantData.tcVersion;

  const localFile =
    merchantData?.signatureFile?.file || merchantData?.signatureFile;

  const isLocalSigned =
    localFile !== null &&
    typeof localFile !== "undefined" &&
    localFile instanceof File;

  if ((merchantData.msaHadAgreed && isCorrectVersion) || isLocalSigned)
    return "Signed";

  if (
    (!merchantData.msaHadAgreed && isCorrectVersion) ||
    !merchantData.msaLastAcceptedVersion
  )
    return "Not Signed";

  return "Pending";
};

type Props = {
  data: TMerchantAgreementPayload;
  handleOpenMerchantAgreement: () => void;
  isAcquirer?: boolean;
  id?: number;
  isEnterprise?: boolean;
};

function MerchantAgreement({
  id,
  data,
  isEnterprise,
  handleOpenMerchantAgreement,
  isAcquirer,
}: Props) {
  const { isMobileView } = useCustomTheme();

  const localFile = data?.signatureFile?.file || data?.signatureFile;
  const { data: APIData } = useGetMerchantById();

  const { openConversationHandler } = useConversationsModal();

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const name = isEnterprise ? "Provider Agreement" : "Merchant Agreement";
    openConversationHandler({ id, name, paths: [] });
  };

  const isLocalSigned =
    localFile !== null &&
    typeof localFile !== "undefined" &&
    localFile instanceof File;

  const status = getAgreementStatus(data);

  const msaVersion = APIData?.msaVersion || APIData?.aquirerTcVersion;

  const msaLastAcceptedVersion = isLocalSigned
    ? msaVersion
    : data?.msaLastAcceptedVersion;

    
  const tcVersion = isLocalSigned ? msaVersion : data?.tcVersion;
  const signedDate = isLocalSigned
    ? moment().format("MM/DD/YYYY")
    : data?.msaLastAcceptedAt
    ? moment.unix(data?.msaLastAcceptedAt).format("MM/DD/YYYY")
    : "";

  const generateText = {
    Signed: `${signedDate} v.${msaLastAcceptedVersion || ""}`,
    "Not Signed": `Version ${tcVersion || ""}`,
    Pending: `New Version ${tcVersion || ""}`,
  };

  return (
    <Stack
      data-testid="merchant_agreement_section"
      onClick={handleOpenMerchantAgreement}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="16px"
      borderRadius="8px"
      sx={({ palette }) => ({
        backgroundColor: palette.neutral.white,
        cursor: "pointer",
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      })}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <MerchantAgreementIcon />
        <Stack spacing={0.5} alignItems="center">
          <Text
            color={({ palette }) => palette.neutral["70"]}
            fontWeight="regular"
          >
            Agreement
          </Text>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1}>
        {isAcquirer && id && (
          <EditButton onClick={onClick}>
            <ConversationsIconNotFilled section="business-profile-(legal-entity)" />
          </EditButton>
        )}
        <Text fontSize={12} color={({ palette }) => palette.neutral["70"]}>
          {generateText[status]}
        </Text>
        <CustomTag label={status} />
        {!isMobileView && <ChevronRightIcon />}
      </Stack>
    </Stack>
  );
}

export default MerchantAgreement;

interface CustomTagProps extends ITextProps {
  label: "Signed" | "Not Signed" | "Pending";
}

const CustomTag = ({ label }: CustomTagProps) => {
  const textStyle = {
    Signed: {
      color: palette.success.main,
      backgroundColor: palette.tag.success.bg,
    },
    "Not Signed": {
      color: palette.error.main,
      backgroundColor: palette.tag.error.bg,
    },
    Pending: {
      color: palette.warning.main,
      backgroundColor: palette.tag.warning.bg,
    },
  };

  return (
    <Text
      paddingX="8px"
      fontSize="12px"
      borderRadius="4px"
      sx={textStyle[label]}
    >
      {label}
    </Text>
  );
};
