import React, { useState } from "react";
import NewDropdownMenu, { NewDropdownMenuProps } from "../NewDropdownMenu";

export const useDropdownMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return {
    DropdownMenu: ({
      items,
      ...rest
    }: Omit<NewDropdownMenuProps, "anchorEl" | "setAnchorEl">) => (
      <NewDropdownMenu
        items={items}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        {...rest}
      />
    ),
    openMenu,
    closeMenu,
    anchorEl
  };
};
