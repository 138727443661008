import { Stack } from "@mui/material";
import { ModalActions } from "@common/Modal/ModalFactory/atoms";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import useRiskMarkAllAsOk from "@components/Merchants/MerchantPreview/hooks/useRiskMarkAllAsOk";
import { MarkAllAsOkModalProps } from "@components/Merchants/MerchantPreview/modals/MarkAllAsOkModal";

export interface MarkAllAsOkModalFooterProps extends MarkAllAsOkModalProps {
  onCancel: () => void;
}

export type SaveValuesType = {
  reason: string;
  description: string;
};

const MarkAllAsOkModalFooter = ({
  onCancel,
  merchantId,
  profileId,
  escalationIds,
}: MarkAllAsOkModalFooterProps) => {
  const { handleSubmit, reset } = useFormContext<SaveValuesType>();

  const { handleSave, isLoading } = useRiskMarkAllAsOk({
    onCancel,
    merchantId,
    profileId,
    escalationIds,
  });

  useEffect(() => {
    return () => reset();
  }, []);

  return (
    <Stack direction="row">
      <ModalActions
        primaryAction={{
          dataTestId: "modal-mark-all-as-ok-button",
          label: "Mark all as OK",
          sx: { fontSize: "18px" },
          disabled: isLoading,
          isLoading,
          onClick: () => {
            handleSubmit(handleSave)();
          },
        }}
        secondaryAction={{
          label: "Cancel",
          sx: { fontSize: "18px" },
          onClick: onCancel,
        }}
        animationDelay={300}
      />
    </Stack>
  );
};

export default MarkAllAsOkModalFooter;
