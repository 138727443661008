import React from "react";
import { Box, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Figure, { Delayed } from "@common/Figure";
import { ITextProps } from "@common/Text/Text";

export type valuePropsType = ITextProps & {
  sup?: boolean;
  color?: string;
  fontSize?: number | string;
};

export type StatProps = {
  title: string | React.ReactNode;
  value: string | number;
  isAmount?: boolean;
  percent?: boolean;
  valueProps?: valuePropsType;
  textProps?: ITextProps;
  isTotal?: boolean;
  fontSize?: number | string;
  ratio?: number | string | undefined;
  ratioStyle?: any;
};

const NewStat = ({
  title,
  value,
  isAmount,
  percent,
  valueProps,
  textProps,
  ratio,
  ratioStyle,
}: StatProps) => {
  return (
    <StyledRoot className="banner-stat-key-value">
      <Delayed>
        <Figure
          sup
          value={value}
          percent={percent}
          fontSize={valueProps?.fontSize || 32}
          color={valueProps?.color}
          lineHeight={"43.2px"}
          isAmount={isAmount}
          alignSelf="flex-end"
          className="banner-stat-value"
          ratio={ratio}
          ratioStyle={ratioStyle}
          {...valueProps}
        />

        <TitleText
          variant="smallest"
          color={palette.black[100]}
          sx={{ ...textProps }}
        >
          {title}
          {isAmount && " (USD)"}
          {percent && " (%)"}
        </TitleText>
      </Delayed>
    </StyledRoot>
  );
};

const StyledRoot = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  alignSelf: "flex-end",
  alignItems: "flex-end",
  gap: 1,
}));

const TitleText = styled(Text)(() => ({
  fontWeight: 400,
  flexGrow: 0,
  width: "fit-content",
}));

export default NewStat;
