import { SvgIconProps } from "@mui/material";

const RightChevronIcon = ({
  width = 9,
  height = 14,
  fill = "#B2B2B1",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.1922 7.44254L1.9422 13.6925C1.88413 13.7506 1.81519 13.7967 1.73932 13.8281C1.66345 13.8595 1.58213 13.8757 1.50001 13.8757C1.41789 13.8757 1.33657 13.8595 1.2607 13.8281C1.18483 13.7967 1.11589 13.7506 1.05782 13.6925C0.999755 13.6345 0.953692 13.5655 0.922266 13.4897C0.890839 13.4138 0.874664 13.3325 0.874664 13.2503C0.874664 13.1682 0.890839 13.0869 0.922266 13.011C0.953692 12.9352 0.999755 12.8662 1.05782 12.8082L6.86642 7.00035L1.05782 1.19253C0.940549 1.07526 0.874664 0.9162 0.874664 0.750347C0.874664 0.584495 0.940549 0.425435 1.05782 0.30816C1.1751 0.190885 1.33416 0.125 1.50001 0.125C1.66586 0.125 1.82492 0.190885 1.9422 0.30816L8.1922 6.55816C8.25031 6.61621 8.29641 6.68514 8.32786 6.76101C8.35931 6.83688 8.3755 6.91821 8.3755 7.00035C8.3755 7.08248 8.35931 7.16381 8.32786 7.23969C8.29641 7.31556 8.25031 7.38449 8.1922 7.44254Z"
        fill={fill}
      />
    </svg>
  );
};

export default RightChevronIcon;
