import UnfoldListItems from "@components/animation/UnfoldListItems";
import { Stack, styled, Box, Typography } from "@mui/material";
import { palette } from "@palette";
import PermissionsListItem from "./PermissionsListItem";
import { forwardRef } from "react";
import { TPermissionGroups, TPermissionsData } from "../../types";

interface Props {
  groupName: string;
  uniqueGroups: TPermissionGroups;
  hashes: TPermissionsData;
  onClick: (key: string, value: TPermissionsData[string]) => void;
  onDelete: (permissionKey: string, permissionName: string) => void;
}

export const GroupTab = forwardRef<HTMLParagraphElement, Props>(
  ({ groupName, uniqueGroups, hashes, onClick, onDelete }, ref) => {
    const items = uniqueGroups[groupName];
    const labels = {
      acquirer: "Acquirer ",
      enterprise: "Provider",
      submerchant: "Merchant",
      merchant: "Merchant",
      user: "User",
    };

    return (
      <Stack
        direction="column"
        gap="12px"
        alignItems="stretch"
        paddingBottom="48px"
      >
        <Tag ref={ref}>{labels[groupName as keyof typeof labels]}</Tag>
        <Stack direction="column" alignItems="stretch">
          <UnfoldListItems
            items={items}
            renderKey={(itemHash) => hashes[itemHash].id}
            config={{
              config: {
                mass: 1,
                tension: 1500,
                friction: 100,
              },
              opacity: 1,
              height: 50,
              from: {
                opacity: 0,
                height: 0,
              },
            }}
            ItemComponent={(itemHash, idx) => {
              const { id, isDeny, ...rest } = hashes[itemHash];
              return (
                <PermissionsListItem
                  {...rest}
                  assigned={!isDeny}
                  onClick={() => onClick(itemHash, hashes[itemHash])}
                  isLast={idx === items.length - 1}
                  onDelete={() => onDelete(itemHash, rest.permissionName)}
                />
              );
            }}
          />
        </Stack>
      </Stack>
    );
  },
);
GroupTab.displayName = "GroupTab";

const Tag = styled(Typography)(() => ({
  padding: "2px 24px",
  borderRadius: "100px",
  userSelect: "none",
  background: palette.liftedWhite[100],
  color: palette.black[100],
  lineHeight: "16.8px",
  marginInline: "auto",
  textTransform: "capitalize",
  zIndex: 6,
}));

export const Overlay = styled(Box)(() => ({
  position: "absolute",
  zIndex: 5,
  right: 0,
  top: -24,
  left: 0,
  transition: "visibility 0.1s ease-in",
  height: "80px",
  visibility: "hidden",
  background:
    "linear-gradient(180deg, #FAFAFA 25.38%, rgba(250, 250, 250, 0.00) 100%)",
}));

export const PermissionListWrapper = styled(Stack)(() => ({
  flexDirection: "column",
  alignItems: "stretch",
  gap: "24px",
  padding: "16px",
  overflowY: "scroll",
  flexGrow: 1,
}));
