import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Button } from "@common/Button";
import { styled } from "@mui/material";

type Props = {
  statusName: string;
  disabled?: boolean;
  onClick: () => void;
};

const SuspendButton = (props: Props) => {
  const { statusName, disabled, onClick } = props;
  const { isMobileView } = useCustomTheme();
  const title = statusName === "suspended" ? "Resume" : "Suspend";

  if (["approved", "suspended"].includes(statusName) && !isMobileView) {
    return (
      <SuspendButtonStyled
        className="suspend-button"
        disabled={disabled}
        background="text"
        onClick={onClick}
      >
        {title}
      </SuspendButtonStyled>
    );
  } else return null;
};

const SuspendButtonStyled = styled(Button)(() => ({
  height: "18px !important",
  padding: "0 16px",
  visibility: "hidden",
  "&:hover": {
    background: "none",
  },
}));

export default SuspendButton;
