import { UseInfiniteQueryOptions, useInfiniteQuery } from "react-query";
import { queryGenerator } from "@utils/generators/queryGenerator";
import { customInstance } from "@services/api";

type TParamValueLiteral = string | number;
type TParamValueComposed = TParamValueLiteral | null | Record<string, any>;

type TQueryParams = {
  sort?: string;
  filter?: TParamValueComposed;
  q?: string;
  max?: TParamValueLiteral;
};

type TConfig = {
  queryKey: string;
  baseURL: string;
};

type TQueryOptions = Omit<
  UseInfiniteQueryOptions<any, unknown, any, any, any>,
  "queryKey" | "queryFn"
>;

const DEFAULT_PAGE_ELEMENT_NUMBER = 20;

const useGetInfiniteList = (
  queryParams: TQueryParams,
  config: TConfig,
  queryOptions?: TQueryOptions,
) => {
  const { queryKey, baseURL } = config;

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    remove,
    refetch,
  } = useInfiniteQuery(queryKey, queryBuilder(baseURL, queryParams), {
    ...queryOptions,
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextCursor;
    },
  });

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    remove,
    refetch,
  };
};

export default useGetInfiniteList;

const getElementsPerPage = (max?: TParamValueLiteral) => {
  if (!max) return DEFAULT_PAGE_ELEMENT_NUMBER;
  try {
    const parsedMax = typeof max === "number" ? max : parseInt(max);
    return Number.isNaN(parsedMax) ? DEFAULT_PAGE_ELEMENT_NUMBER : parsedMax;
  } catch (err) {
    return DEFAULT_PAGE_ELEMENT_NUMBER;
  }
};

const queryBuilder =
  (baseURL: string, queryParams: TQueryParams) =>
    async ({ pageParam = 1 }) => {
      const elementsPerPage = getElementsPerPage(queryParams?.max);
      const paramsWithDefaults = {
        ...queryParams,
        max: elementsPerPage,
        page: pageParam,
      };
      const encodedURL = queryGenerator(baseURL, paramsWithDefaults);

      const data = await customInstance({
        url: encodedURL,
        method: "GET",
      });

      const numberOfPages = Math.ceil(Number(data.total ?? 0) / elementsPerPage);

      return {
        data: data?.data || [],
        nextCursor: numberOfPages >= pageParam + 1 ? pageParam + 1 : null,
        total: data?.total || 0,
      };
    };
