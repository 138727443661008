import { Box, Skeleton, Stack } from "@mui/material";

const LoadingPanelState = () => {
  return (
    <Stack
      direction="column"
      alignItems="stretch"
      gap={4}
      sx={{ padding: "20px 16px" }}
    >
      <Skeleton variant="rounded" height={190} sx={{ borderRadius: "8px" }} />
      <Stack direction="column" gap={1}>
        <Skeleton
          variant="rounded"
          height={16}
          width="60%"
          sx={{ borderRadius: "100px" }}
        />
        <Skeleton
          variant="rounded"
          height={16}
          width="30%"
          sx={{ borderRadius: "100px" }}
        />
        <Skeleton
          variant="rounded"
          height={9}
          width="10%"
          sx={{ borderRadius: "100px" }}
        />
      </Stack>
      <Skeleton variant="rounded" height={90} sx={{ borderRadius: "8px" }} />
      <Skeleton variant="rounded" height={90} sx={{ borderRadius: "8px" }} />
      <Stack direction="column" gap={4} alignItems="stretch">
        {Array.from({ length: 7 }, (_, i) => (
          <Skeleton height={1} key={i} />
        ))}
      </Stack>
      <Stack direction="column" gap={2} alignItems="stretch">
        <Skeleton
          variant="rounded"
          height={16}
          width="30%"
          sx={{ borderRadius: "100px" }}
        />
        <Box>
          {Array.from({ length: 6 }, (_, i) => (
            <Purchase key={i} />
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

const Purchase = () => {
  return (
    <Box sx={{ paddingBlock: "12px" }}>
      <Stack
        marginBottom="8px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Skeleton
          variant="rounded"
          height={9}
          width="40%"
          sx={{ borderRadius: "100px" }}
        />
        <Skeleton
          variant="rounded"
          height={9}
          width="10%"
          sx={{ borderRadius: "100px" }}
        />
      </Stack>
      <Skeleton
        variant="rounded"
        height={9}
        width="20%"
        sx={{ borderRadius: "100px" }}
      />
    </Box>
  );
};

export default LoadingPanelState;
