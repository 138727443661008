import { useForm } from "react-hook-form";
import { toUnixDateFormat } from "@utils/date.helpers";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { CustomDateRange } from "../types";

export type TForm = {
  timezone: string;
  startDate: Date | null;
  endDate: Date | null;
};

type Props = {
  setCustomRange: ({ timezone, startDate, endDate }: CustomDateRange) => void;
  initialTimezone: string;
};

const useExportDateRange = ({ setCustomRange, initialTimezone }: Props) => {
  const defaultValues = {
    startDate: null,
    endDate: null,
    timezone: initialTimezone,
  };

  const methods = useForm<TForm>({
    mode: "onChange",

    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { watch } = methods;
  const values = watch();

  const setFormattedDateRange = () => {
    if (values.startDate && values.endDate) {
      const formatedStartDate = toUnixDateFormat(values.startDate);
      const formatedEndDate = toUnixDateFormat(values.endDate);
      setCustomRange({
        timezone: values.timezone,
        startDate: formatedStartDate,
        endDate: formatedEndDate,
      });
    }
  };

  return {
    setFormattedDateRange,
    methods,
    values,
  };
};

export default useExportDateRange;

const schema = Yup.object({
  startDate: Yup.date()
    .transform((value) => {
      return value ? moment(value).toDate() : value;
    })
    .typeError("Enter valid date")
    .required("This field is required")
    .max(new Date(), "Future date not allowed")
    .min(
      moment().subtract(500, "years").toDate(),
      "Date is beyond acceptable range",
    ),
  endDate: Yup.date()
    .transform((value) => {
      return value ? moment(value).toDate() : value;
    })
    .typeError("Enter valid date")
    .required("This field is required")
    .max(new Date(), "Future date not allowed")
    .min(Yup.ref("startDate"), "End date must be after start date"),
  timezone: Yup.string().required("This field is required"),
});
