import { useNavigate } from "react-router-dom";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { showMessage } from "@common/Toast/ShowToast";
// localization
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Stack } from "@mui/material";
import { palette } from "@palette";

type IntroduceAdvancedFormBuilderModalProps = {
  data: any;
  productId: number;
};

const IntroduceAdvancedFormBuilderModal = NiceModal.create(
  ({ data, productId }: IntroduceAdvancedFormBuilderModalProps) => {
    const modal = useModal();
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const { t } = useTranslation(namespaces.pages.paymentFormMinibuilder);
    const { isMobileView } = useCustomTheme();

    const handleClose = () => {
      modal.hide();
    };

    const handleNotNow = () => {
      handleClose();
      showMessage(
        "Success",
        <>
          Your new Payment Form{" "}
          <span style={{ fontWeight: "600" }}>“{data.about.title}”</span> has
          been successfully created and it’s ready to accept payments
        </>,
        isDesktop,
        "Payment Form Created",
      );
    };

    const handleTryNow = () => {
      handleClose();
      navigate("/merchant/new-advanced-builder", {
        state: {
          productId: productId,
          productTitle: data?.about?.title,
          headerImageUrl:
            data?.style?.useAsBackground && data?.style?.image?.URL,
          description: data?.about?.description,
          campaign: data?.campaign,
        },
      });
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={
          (
            <Text fontSize={isDesktop ? 20 : 18} fontWeight="book">
              {t("intoduce_advanced_form_builder")}
            </Text>
          ) as any
        }
        onSubmit={handleTryNow}
        onCancel={handleNotNow}
        actionLabel={isDesktop ? t("open_advanced_builder") : undefined}
        submitButtonSx={{
          fontSize: "18px",
          fontWeight: "400",
          padding: "12px 24px",
          height: "46px",
        }}
        cancelLabel={isDesktop ? t("skip") : t("close")}
        cancelButtonSx={
          isDesktop ? desktopCancelButtonProps : mobileCancelButtonProps
        }
        isClose={true}
        isSubmitDisabled={isMobileView}
        data-testid="advanced-form-builder-modal"
        cancelType={isDesktop ? "tertiary" : "primary"}
        modalHeaderCustomStyles={{
          padding: "24px 24px 0px 24px",
        }}
        modalActionsCustomStyles={{
          padding: "20px 24px 16px 24px",
        }}
      >
        <Stack spacing={1}>
          <Text color={palette.gray[300]}>
            <Markup content={t("intoduce_advanced_form_builder_description")} />
          </Text>
          {!isDesktop && (
            <Text color={palette.gray[300]}>
              <Markup content={t("switch_to_desktop")} />
            </Text>
          )}
        </Stack>
      </Popup>
    );
  },
);

const mobileCancelButtonProps = {
  fontSize: "14px",
  fontWeight: "400",
  padding: "8px 24px",
};

const desktopCancelButtonProps = {
  fontSize: "18px",
  fontWeight: "400",
  height: "auto",
};

export default IntroduceAdvancedFormBuilderModal;
