import { useEffect } from "react";
import useAnalyticsDataParser from "../helpers/parsers";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { applyDateFilter, selectFilterQuery } from "@redux/slices/analytics";
import { QKEY_ANALYTICS_CAMPAIGNS } from "@constants/queryKeys";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getTransactionChart,
  getTransactionStats,
} from "@services/api/analytics/transactions";
import { getCampaignStats } from "@services/api/analytics/campaigns";
import { getCustomersAnalytics } from "@services/api/analytics/customers";
import { useModal } from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetSettledPayments } from "./useGetSettledPayments";
import { useGetRecurringCustomers } from "./useGetRecurringCustomers";

const useAnalyticsData = (isEmpty: boolean) => {
  const dispatch = useAppDispatch();
  const modal = useModal();
  const { merchantId } = useGetCurrentMerchantId();
  const filterQuery = useAppSelector((state) =>
    selectFilterQuery(state, QKEY_ANALYTICS_CAMPAIGNS),
  );
  const location = useLocation();
  const section = location.pathname.replace("/merchant/", "");

  const { data, isLoading } = useQuery(
    [QKEY_ANALYTICS_CAMPAIGNS, filterQuery, section, merchantId],
    async () => {
      const filter = filterQuery.dateFilter;
      const data = section.includes("manage-money")
        ? await getTransactionStats({ filter })
        : await getCampaignStats({
            filter,
            productTypeName: section,
          });
      const mainChart = await getTransactionChart({
        filter,
        productType: section,
      });
      const chart = filter
        ? mainChart?.data?.slice(0, mainChart?.data?.length - 1)
        : mainChart?.data;
      const customers = await getCustomersAnalytics({
        filter,
      });
      return {
        data,
        customers,
        chart: {
          mainChart: chart || [],
        },
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!modal.visible && !isEmpty,
      retry: 2,
    },
  );

  const { data: postedPayments } = useGetSettledPayments(modal.visible);
  const { data: recurringCustomers } = useGetRecurringCustomers(modal.visible);

  useEffect(() => {
    if (!modal.visible) {
      dispatch(
        applyDateFilter({
          queryKey: QKEY_ANALYTICS_CAMPAIGNS,
          filter: { type: undefined, amount: 0 },
        }),
      );
    }
  }, [modal.visible]);

  const customData = useAnalyticsDataParser({
    ...data,
    recurringCustomers: recurringCustomers?.customers,
    chart: {
      ...data?.chart,
      recurrenceChart: recurringCustomers?.chart,
      ...postedPayments,
    },
  });

  return {
    data: customData,
    isLoading,
  };
};

export default useAnalyticsData;
