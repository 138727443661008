import { SubPathNameType } from "@utils/routing";
import { Image } from "@common/StyledImage/Image";
import { TruncateText } from "@common/Text";
import { Stack, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useBreadcrumbs } from "./hooks/useBreadcrumbs";
import placeholder from "assets/images/Placeholder.png";
import { isMobile } from "@utils/index";

const BreadcrumbsProductItem = (props: SubPathNameType) => {
  const { selectedProduct } = useBreadcrumbs();
  const classes = useStyles();

  if (!selectedProduct.product_id) return null;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {!isMobile && (
        <Image
          height={26}
          width={26}
          sx={{
            borderRadius: "3px",
          }}
          src={
            selectedProduct.imgSrc
              ? `${selectedProduct.imgSrc}/thumb`
              : placeholder
          }
        />
      )}
      <TruncateText lineClamp={1} className={classes.textStyle}>
        {selectedProduct.title}
      </TruncateText>
    </Stack>
  );
};

export default BreadcrumbsProductItem;

const useStyles = makeStyles((theme: Theme) => ({
  textStyle: {
    marginTop: "2px",
    color: theme.palette.neutral["80"],
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
}));
