import * as Yup from "yup";
import {
  dateOfBirthSchema,
  nonResidentInputsSchema,
  streetAddressValidator,
} from "@utils/validation.helpers";
import { matchIsValidTel } from "mui-tel-input";

const addressSchema = (name: string) =>
  Yup.string().when("useBusinessAddress", {
    is: false,
    then: Yup.string().required(`${name} is required`),
  });

export const getBusinessOwnerSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
    ssn: Yup.string()
      .min(11, "please provide a valid SSN number")
      .required("Please provide a valid SSN number"),
    DOB: dateOfBirthSchema(),
    ownership: Yup.number()
      .required("Ownership is required")
      .min(0, "Ownership must be at least 0%")
      .max(100, "Ownership must be at most 100%"),
    useBusinessAddress: Yup.boolean(),
    country: Yup.string().when("useBusinessAddress", {
      is: false,
      then: Yup.string()
        .required("Country is required")
        .matches(/^[A-Z].+$/, "Country is required"),
    }),
    street: Yup.string().when("useBusinessAddress", {
      is: false,
      then: streetAddressValidator(true, "Street Address is required"),
    }),
    contactPhone: Yup.string()
      .required("Contact Phone is required")
      .when({
        is: (exists: string) => !!exists && exists !== "+1",
        then: (schema) =>
          schema.test(
            "is-valid-number",
            "Please enter a valid phone number",
            function (value) {
              const phoneNumber = value as string;
              return matchIsValidTel(phoneNumber);
            },
          ),
      }),
    city: addressSchema("City"),
    zip: Yup.string().when("useBusinessAddress", {
      is: false,
      then: Yup.string()
        .matches(/^[0-9]{5}(?:-?[0-9]{4})?$/, "Invalid ZIP format")
        .required("Zip Code is required"),
    }),
    state: addressSchema("State"),
    ...nonResidentInputsSchema,
      files: Yup.object().shape({
        fileWithMeta: Yup.object().unknown(true),
        status: Yup.string(),
        allFiles: Yup.array()
          .of(Yup.object())
          .min(1, "Business Owner ID is required"),
    }),
  });
