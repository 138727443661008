import React, { useEffect } from "react";
import { customInstance } from "@services/api";
import { useQuery, useQueryClient } from "react-query";
import { ROWS_PER_PAGE, usePagination } from "@hooks/common/usePagination";
import { detectMobile } from "@utils/index";
import { useCachedList } from "@hooks/common/useCachedList";
import { buildAccountsUrl } from "@services/api/utils.api";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

export const useGetAllImages = (
  elementsPerPage = ROWS_PER_PAGE,
  invalidateOnPageChange = false,
) => {
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const { page, setPage } = usePagination(0, "");
  const startIndex = (page - 1) * elementsPerPage;

  const isListAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const { allData, invalidateCache } = useCachedList("get-all-media-items");
  const loadingRef = React.useRef<boolean>(false);
  const { data, error, isLoading } = useQuery(
    ["get-all-media-items", page, elementsPerPage],
    async () => {
      const data = await getAllImages();
      return data;
    },
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: isListAllowed,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              all_media_items: true,
            }),
          );
        }
      },
      onSuccess(_data) {
        setTimeout(() => {
          loadingRef.current = false;
        }, 700);
      },
    },
  );

  const usedData = detectMobile() ? allData : data?.data ?? [];

  const getAllImages = () => {
    let queryUrl = "media-items";
    if (elementsPerPage) {
      queryUrl = `media-items?max=${elementsPerPage}&after=${startIndex}&sort=-id&page=${page}`;
    }

    return customInstance({
      url: buildAccountsUrl(queryUrl),
      method: "GET",
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | any,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (invalidateOnPageChange) {
      invalidateCache();
    }
  }, [page]);

  const setNextPage = () => setPage((current) => current + 1);
  const setPrevPage = () => setPage((current) => current - 1);

  return {
    error,
    images: usedData,
    isLoading,
    page,
    totalRows: data?.total ?? 0,
    handlePageChange,
    setPage: setNextPage,
    setNextPage,
    setPrevPage,
    setPageOnSwipe: setPage,
    currentPageRows: usedData,
    loadingRef,

    allDataDesktop: () => {
      //TODO: requires some work to build a commen factory with the current mobile implementation
      const caches = client.getQueryCache().findAll("get-all-media-items");

      return caches.reduce((acc, v) => {
        if (v?.state?.data) {
          const items = (v.state.data as any)?.data ?? [];
          const uniqueMediaItems = items?.filter((item: any) => {
            // Check if item.id already exists in accumulator
            return !acc?.some((accItem: any) => accItem.id === item.id);
          });
          return [...acc, ...(uniqueMediaItems as [])];
        }
        return acc;
      }, []);
    },
  };
};
