import React from "react";
import { CREATE_EVENT_MODAL } from "../../modals/modal_names";

import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";
import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";

const NewEventsBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  const { t } = useTranslation(namespaces.pages.events);

  return (
    <NewProductBanner
      title={t("Events")}
      createModal={CREATE_EVENT_MODAL}
      background={backgroundUrl}
      createCampaignTitle={t("createEvent")}
      analyticsType="events"
    />
  );
};

export default NewEventsBanner;
