import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import { UnderwritingPopupBase } from "./ApproveMerchantPopup";
import { Text } from "@common/Text";

type ApproveMerchantProps = {
  handleDecline: () => void;
  name: string;
};

const DeclineMerchantModal = NiceModal.create(
  ({ handleDecline, name }: ApproveMerchantProps) => {
    const props = {
      modalTitle: "Decline account?",
      submitLabel: "Yes, decline account",
      children: (
        <Text color={palette.black[100]} lineHeight="16.8px">
          Are you sure you want to decline{" "}
          <span style={{ color: palette.neutral[100] }}>{name}</span>? This
          action will finalize the approval status and cannot be undone.
        </Text>
      ),
    };
    return (
      <UnderwritingPopupBase
        width="380px"
        handleSubmit={handleDecline}
        primaryButtonBackground={palette.filled.red}
        {...props}
      />
    );
  },
);

export default DeclineMerchantModal;
