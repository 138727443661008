import { ClockCounterwiseIcon } from "@assets/icons/RebrandedIcons";
import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { palette } from "@palette";

export default function EmptyState({ text }: { text: string }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      gap="8px"
    >
      <ClockCounterwiseIcon
        fill={palette.neutral[30]}
        width="130"
        height="130"
      />
      <Text
        width="320px"
        color={palette.neutral[70]}
        fontSize="18px"
        lineHeight="21.6px"
        textAlign="center"
      >
        {text}
      </Text>
    </Box>
  );
}
