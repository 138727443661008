import { SvgIconProps } from "@mui/material";

const NewSigninIcon = ({
  width = 32,
  height = 32,
  fill = "#8F8F8F",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0672 10.9422L7.94219 14.0672C7.88412 14.1253 7.81518 14.1713 7.73931 14.2027C7.66344 14.2342 7.58212 14.2503 7.5 14.2503C7.41788 14.2503 7.33656 14.2342 7.26069 14.2027C7.18482 14.1713 7.11588 14.1253 7.05781 14.0672C6.99974 14.0091 6.95368 13.9402 6.92225 13.8643C6.89083 13.7884 6.87465 13.7071 6.87465 13.625C6.87465 13.5429 6.89083 13.4616 6.92225 13.3857C6.95368 13.3098 6.99974 13.2409 7.05781 13.1828L9.11641 11.125H1.875C1.70924 11.125 1.55027 11.0592 1.43306 10.9419C1.31585 10.8247 1.25 10.6658 1.25 10.5C1.25 10.3342 1.31585 10.1753 1.43306 10.0581C1.55027 9.94085 1.70924 9.875 1.875 9.875H9.11641L7.05781 7.81719C6.94054 7.69991 6.87465 7.54085 6.87465 7.375C6.87465 7.20915 6.94054 7.05009 7.05781 6.93281C7.17509 6.81554 7.33415 6.74965 7.5 6.74965C7.66585 6.74965 7.82491 6.81554 7.94219 6.93281L11.0672 10.0578C11.1253 10.1159 11.1714 10.1848 11.2029 10.2607C11.2343 10.3365 11.2505 10.4179 11.2505 10.5C11.2505 10.5821 11.2343 10.6635 11.2029 10.7393C11.1714 10.8152 11.1253 10.8841 11.0672 10.9422ZM15 3H10.625C10.4592 3 10.3003 3.06585 10.1831 3.18306C10.0658 3.30027 10 3.45924 10 3.625C10 3.79076 10.0658 3.94973 10.1831 4.06694C10.3003 4.18415 10.4592 4.25 10.625 4.25H15V16.75H10.625C10.4592 16.75 10.3003 16.8158 10.1831 16.9331C10.0658 17.0503 10 17.2092 10 17.375C10 17.5408 10.0658 17.6997 10.1831 17.8169C10.3003 17.9342 10.4592 18 10.625 18H15C15.3315 18 15.6495 17.8683 15.8839 17.6339C16.1183 17.3995 16.25 17.0815 16.25 16.75V4.25C16.25 3.91848 16.1183 3.60054 15.8839 3.36612C15.6495 3.1317 15.3315 3 15 3Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewSigninIcon;
