import { RHFSwitch } from "@common/Switch";
import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { Box } from "@mui/material";
import { palette } from "@palette";
import React from "react";

type CustomSwitchProps = {
  name: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  tooltip?: string;
};

const StatusReviewSwitch = (props: CustomSwitchProps) => {
  if (!props.tooltip) return <CustomSwitch {...props} />;

  return (
    <Tooltip title={props.tooltip}>
      <CustomSwitch {...props} />
    </Tooltip>
  );
};

const CustomSwitch = ({
  name,
  onChange,
  value,
  disabled,
}: CustomSwitchProps) => {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      gap="8px"
      sx={{
        ...(value && {
          "& .MuiSwitch-track": {
            backgroundColor: `${palette.filled.success} !important`,
          },
        }),
      }}
    >
      <RHFSwitch
        name={name}
        size="small"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      />
      <Text
        fontWeight="book"
        minWidth="23px"
        color={value ? palette.success.main : palette.neutral[80]}
      >
        {value ? "Yes" : "No"}
      </Text>
    </Box>
  );
};

export default StatusReviewSwitch;
