import React from "react";
// form
import { showMessage } from "@common/Toast/ShowToast";
import { isValidEmailRegex } from "@constants/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMasquerade } from "@hooks/enterprise-api/merchants";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { customInstance } from "../../services/api";

type IFormInputs = {
  email: string;
};

export const useForgotPassword = () => {
  const [exist, setExist] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [previousEmail, setPreviousEmail] = React.useState("");
  const { clearMasquerade } = useMasquerade();

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required")
      .test(
        "is-valid-email",
        "Please enter a valid email",
        (value: string | undefined) => {
          if (!value) {
            return true; // allow empty values
          }
          const regex = isValidEmailRegex; // eslint-disable-line
          return regex.test(value);
        },
      ),
  });

  const defaultValues = {
    email: "",
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch } = methods;

  const resetPasswordMutation = useMutation((data: { email: string }) => {
    return customInstance({
      url: "password-resets",
      method: "POST",
      data: { email: data.email },
    });
  });

  const isEmailValid = (email: string) => {
    const regex = isValidEmailRegex;
    return regex.test(email);
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    clearMasquerade()
    setPreviousEmail(data.email);
    resetPasswordMutation.mutate(
      { email: data.email },
      {
        onError: (error: any) => {
          setExist(false);
          setError(true);
        },
        onSuccess: () => {
          setExist(true);
          showMessage("Success", "Check your email to reset password");
        },
      },
    );
  };

  React.useEffect(() => {
    if (error) setError(false);
    if (exist) setExist(false);
  }, [watch("email")]);

  return {
    methods,
    onSubmit,
    exist,
    error,
    previousEmail,
    isEmailValid,
    isLoading: resetPasswordMutation.isLoading,
  };
};
