import { customInstance } from "@services/api";
import { useMutation } from "react-query";

export const useDeleteAccountFile = () => {
  const mutation = useMutation(
    ({ id, fileID }: { id: string; fileID: string }) =>
      deleteAccountFile({ id, fileID }),
  );
  return mutation;
};

const deleteAccountFile = ({ id, fileID }: { id: string; fileID: string }) =>
  customInstance({
    url: `/accounts/${id}/files/${fileID}`,
    method: "DELETE",
  });
