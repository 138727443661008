import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";
import { FilterSectionItemType } from "./utils";

export default function SectionItem({
  title,
  description,
  startIcon,
  endElement,
  children,
  containerStyle = {},
}: FilterSectionItemType) {
  return (
    <Container direction="column" sx={containerStyle}>
      <Stack direction="row" spacing={1}>
        {!!startIcon && startIcon}
        <Stack flex={1} direction="column" gap="4px">
          <StyledText>{title}</StyledText>
          <StyledText isDescription>{description}</StyledText>
        </Stack>
        {!!endElement && endElement}
      </Stack>
      {!!children && <Stack flex={1}>{children}</Stack>}
    </Container>
  );
}

export const Container = styled(Stack)(() => ({
  gap: "8px",
  padding: "16px",
  backgroundColor: palette.neutral.white,
  borderRadius: "8px",
}));

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isDescription",
})<{ isDescription?: boolean }>(({ isDescription }) => ({
  fontSize: "14px",
  lineHeight: "16.8px",
  color: isDescription ? palette.neutral[70] : palette.neutral[80],
}));
