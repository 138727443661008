import React from "react";
import { ArrowLeft } from "@assets/icons";
import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import PurchaseKeyValue from "@components/Memberships/MembershipsReport/Table/Mobile/PurchaseKeyValue";
import { RevenuesData } from "./data";

type MobileRevenueDetailsProps = {
  setIsSwitchDetails: React.Dispatch<React.SetStateAction<boolean>>;
  data: RevenuesData | null;
  title: string;
  type: string
};

const MobileRevenueDetails = ({
  setIsSwitchDetails,
  data,
  title,
  type
}: MobileRevenueDetailsProps) => {
  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mb: "24px" }}
        onClick={() => setIsSwitchDetails(prev => !prev)}
      >
        <ArrowLeft height={20} width={20} />
        <Text color={palette.neutral[600]} fontWeight="medium">
          Back
        </Text>
      </Stack>

      <PurchaseKeyValue
        title={type}
        value={title}
        highlight
      />
      <PurchaseKeyValue
        title="Date"
        value={data?.details?.date}
      />
      <PurchaseKeyValue
        title="Revenue Source"
        value={data?.details?.revenueSource}
        highlight
      >
        <Box mt="8px" display="flex" justifyContent="flex-end">
            <Text
              color={palette.primary.main}
              fontWeight="medium">{data?.details?.url}
            </Text>
        </Box>
      </PurchaseKeyValue>
      <PurchaseKeyValue 
        title="Amount" 
        value={
          <Text color={palette.neutral[800]} fontWeight="medium">
            {data?.details?.amount} <sup style={supStyle}>USD</sup>
          </Text>
        }
      />
      <PurchaseKeyValue
        title="Avg Transaction"
        value={
          <Text color={palette.neutral[800]} fontWeight="medium">
            {data?.details?.average} <sup style={supStyle}>USD</sup>
          </Text>
        }
        highlight
      />
      <PurchaseKeyValue title="Transactions" value={data?.details?.transaction} />
      <PurchaseKeyValue title="Visitors" value={data?.details?.Visitors} highlight />
      <PurchaseKeyValue title="Visitors Views" value={data?.details?.VisitorsViews} />
    </div>
  );
};

const supStyle = {
  fontSize: 11,
  fontWeight: 400,
}

export default MobileRevenueDetails;
