import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionData } from "@components/ManageMoney/TransactionTable/data";
import { format, sub, subDays } from "date-fns";
import { replaceValues, withAppendedKey } from "@services/filtering";
import { toUnixDateFormat } from "@utils/date.helpers";

export type TransactionsQueryType = {
  created: string;
  customerID: string;
  blocked: string;
  quarantine: string;
};

export interface TransactionsState {
  names: {
    type: string[];
    status: string[];
    amount: (string | number)[];
    entries: (string | number)[]; // for sweepstakes, every entries state will be removed
    quantity: (string | number)[]; // for sweepstakes, every entries state will be removed
    recurrence: string[];
    transaction: string[];
    date: (string | number)[];
    source: string[];
  };
  filteredTableValues: TransactionData[];
  query: TransactionsQueryType;
  filter: {
    type: string;
    value: string;
  }[];
  hasUnseenBlockedTransactions: boolean;
}

const queryInitialState = {
  created: "",
  customerID: "",
  blocked: "",
  quarantine: "",
};

const initialState: TransactionsState = {
  names: {
    type: [],
    status: [],
    amount: [],
    entries: [], // for sweepstakes, every entries state will be removed
    quantity: [], // for sweepstakes, every entries state will be removed
    recurrence: [],
    transaction: [],
    date: [],
    source: [],
  },
  filteredTableValues: [],
  query: queryInitialState,
  filter: [],
  hasUnseenBlockedTransactions: false,
};

type SwitchAndSelect = {
  type: "type" | "status" | "transaction" | "recurrence" | "source";
  value: string;
};

type Date = {
  modifier: string;
  count: number;
  dayMonthYear: string;
  startDate: any;
  endDate: any;
  date: any;
};

type Amount = {
  title: "amount" | "entries";
  values: {
    modifier: string;
    amount: number;
    amountOne: number;
    amountTwo: number;
  };
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    addFilter: (
      state: TransactionsState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.names[type] = [...state.names[type], value];
    },
    removeFilter: (
      state: TransactionsState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.names[type] = state.names[type].filter((val) => val !== value);
    },
    disableFilter: (
      state: TransactionsState,
      action: PayloadAction<{
        type: "type" | "status" | "transaction" | "recurrence" | "status";
      }>,
    ) => {
      state.names[action.payload.type] = [];
    },
    addDateFilter: (state: TransactionsState, action: PayloadAction<Date>) => {
      const { modifier, date, count, dayMonthYear, startDate, endDate } =
        action.payload;

      if (modifier === "is on or after" || modifier === "is on or before") {
        state.names.date = [modifier, format(date, "dd/MM/yy")];
        state.query.created = replaceValues(
          withAppendedKey.created[modifier],
          toUnixDateFormat(new Date(date)),
        );
      } else if (modifier === "is between") {
        state.names.date = [
          format(startDate, "dd/MM/yy"),
          "-",
          format(endDate, "dd/MM/yy"),
        ];
        state.query.created = replaceValues(
          withAppendedKey.created[modifier],
          toUnixDateFormat(new Date(startDate)),
          toUnixDateFormat(new Date(endDate)),
        );
      } else if (modifier === "in the last") {
        state.names.date = ["last", count, dayMonthYear];
        state.query.created = replaceValues(
          withAppendedKey.created[modifier],
          toUnixDateFormat(sub(new Date(), { [dayMonthYear]: count })),
        );
      }
    },
    disableDateFilter: (state: TransactionsState) => {
      state.names.date = [];
      state.query.created = "";
    },
    clearFilters: (state: TransactionsState) => {
      state.names = initialState.names;
      state.query = queryInitialState;
      state.filter = [];
    },
    addCustomerIdFilter: (
      state: TransactionsState,
      action: PayloadAction<number>,
    ) => {
      state.query.customerID = `${action.payload}`;
    },
    disableCustomerIdFilter: (state: TransactionsState) => {
      state.query.customerID = "";
    },
    addAmountFilter: (
      state: TransactionsState,
      action: PayloadAction<Amount>,
    ) => {
      const { modifier, amount, amountOne, amountTwo } = action.payload.values;
      state.names[action.payload.title] = [amountOne, amountTwo];
      if (
        modifier === "is equal to or greater than" ||
        modifier === "is equal to or less than"
      ) {
        state.names[action.payload.title] = [modifier, amount];
      } else {
        state.names[action.payload.title] = [amountOne, amountTwo];
      }
    },
    disableAmountFilter: (
      state: TransactionsState,
      action: PayloadAction<"amount" | "entries">,
    ) => {
      state.names[action.payload] = [];
    },
    addStatusFilter: (
      state: TransactionsState,
      action: PayloadAction<string>,
    ) => {
      if (state.filter.find((item) => item.type === "displayStatus" && item.value === action.payload)) return;
      state.filter = [...state.filter, {
        type: "displayStatus",
        value: action.payload,
      }];
    },
    resetStatusFilter: (state: TransactionsState) => {
      state.filter = [];
    },
    addBlockedFilter: (state: TransactionsState) => {
      state.query.blocked =
        replaceValues(withAppendedKey.blocked.default, "true") +
        `;isFalsePositive:false`;
    },
    addQuarantineFilter: (state: TransactionsState) => {
      state.query.quarantine = replaceValues(
        withAppendedKey.quarantine.default,
        "true",
      );
    },
    setHasUnseenBlockedTransactions: (
      state: TransactionsState,
      action: PayloadAction<boolean>,
    ) => {
      state.hasUnseenBlockedTransactions = action.payload;
    },
  },
});

export const {
  addFilter,
  removeFilter,
  disableFilter,
  addDateFilter,
  disableDateFilter,
  addAmountFilter,
  disableAmountFilter,
  addCustomerIdFilter,
  disableCustomerIdFilter,
  clearFilters,
  addStatusFilter,
  resetStatusFilter,
  addBlockedFilter,
  addQuarantineFilter,
  setHasUnseenBlockedTransactions,
} = transactionsSlice.actions;
export const selectQueryFilters = (state: RootState) =>
  state.transactions.query;
export const selectFilters = (state: RootState) => state.transactions.names;
export const selectTableValues = (state: RootState) =>
  state.transactions.filteredTableValues;
export const selectTableFilter = (state: RootState) =>
  state.transactions.filter;
export const selectHasUnseenBlockedTransactions = (state: RootState) =>
  state.transactions.hasUnseenBlockedTransactions;

export default transactionsSlice.reducer;
