import { SvgIconProps } from "@mui/material";
import React from "react";

const ExpandIcon = ({
  height = 14,
  width = 14,
  fill = "#575353",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58077 1.66663C2.07591 1.66663 1.66663 2.07591 1.66663 2.58077V4.34846C1.66663 4.6246 1.44277 4.84846 1.16663 4.84846C0.890484 4.84846 0.666626 4.6246 0.666626 4.34846V2.58077C0.666626 1.52362 1.52362 0.666626 2.58077 0.666626H4.34846C4.6246 0.666626 4.84846 0.890484 4.84846 1.16663C4.84846 1.44277 4.6246 1.66663 4.34846 1.66663H2.58077ZM9.1515 1.16663C9.1515 0.890484 9.37536 0.666626 9.6515 0.666626H11.4192C12.4763 0.666626 13.3333 1.52362 13.3333 2.58077V4.34846C13.3333 4.6246 13.1095 4.84846 12.8333 4.84846C12.5572 4.84846 12.3333 4.6246 12.3333 4.34846V2.58077C12.3333 2.07591 11.9241 1.66663 11.4192 1.66663H9.6515C9.37536 1.66663 9.1515 1.44277 9.1515 1.16663ZM1.16663 9.1515C1.44277 9.1515 1.66663 9.37536 1.66663 9.6515V11.4192C1.66663 11.9241 2.07591 12.3333 2.58077 12.3333H4.34846C4.6246 12.3333 4.84846 12.5572 4.84846 12.8333C4.84846 13.1095 4.6246 13.3333 4.34846 13.3333H2.58077C1.52362 13.3333 0.666626 12.4763 0.666626 11.4192V9.6515C0.666626 9.37536 0.890484 9.1515 1.16663 9.1515ZM12.8333 9.1515C13.1095 9.1515 13.3333 9.37536 13.3333 9.6515V11.4192C13.3333 12.4763 12.4763 13.3333 11.4192 13.3333H9.6515C9.37536 13.3333 9.1515 13.1095 9.1515 12.8333C9.1515 12.5572 9.37536 12.3333 9.6515 12.3333H11.4192C11.9241 12.3333 12.3333 11.9241 12.3333 11.4192V9.6515C12.3333 9.37536 12.5572 9.1515 12.8333 9.1515Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExpandIcon;
