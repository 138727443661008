import { Radio } from "@common/Radio";
import { Text } from "@common/Text";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";
import { WhiteContainer } from "./styles";
import { MouseEventHandler } from "react";

type TRadio = {
  title: string;
  helper: string;
  value: string;
  disabled?: boolean;
  onChange?: MouseEventHandler<HTMLButtonElement>;
  isVisible?: boolean;
};

type SectionProps = {
  title: string;
  name: string;
  radios: TRadio[];
  onChangeCallBack?: (type: string) => void;
};

export const RadioFormSection = ({
  title,
  radios,
  name,
  onChangeCallBack,
}: SectionProps) => {
  const { watch, setValue } = useFormContext();
  const handleChange = (type: string) => {
    setValue(name, type, { shouldDirty: true, shouldValidate: true });
    onChangeCallBack && onChangeCallBack(type);
  };

  return (
    <Stack spacing={1}>
      <Text lineHeight="25px" fontWeight="book" color={palette.neutral[80]}>
        {title}
      </Text>
      <WhiteContainer>
        {radios.map(
          (
            { disabled, title, helper, value, onChange, isVisible = true },
            index,
          ) => {
            if (!isVisible) return null;
            return (
              <CustomRadio key={index} disabled={disabled || false}>
                <Radio
                  title={title}
                  helper={helper}
                  checked={watch(name) === value}
                  onClick={(e) => {
                    handleChange(value);
                    if (onChange) onChange(e);
                  }}
                  disabled={disabled || false}
                  data-testid={`export-radio-${title}`}
                />
              </CustomRadio>
            );
          },
        )}
      </WhiteContainer>
    </Stack>
  );
};

const CustomRadio = styled(Box)<BoxProps & { disabled?: boolean }>(
  ({ disabled }) => ({
    "& .MuiStack-root p:first-of-type": {
      color: disabled ? palette.neutral[70] : palette.neutral[80],
      fontWeight: 350,
      fontSize: 14,
      lineHeight: "16.8px",
    },
    "& .MuiStack-root p:last-child": {
      color: palette.neutral[70],
      fontWeight: 350,
      fontSize: 12,
      lineHeight: "14.4px",
    },
  }),
);
