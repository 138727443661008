import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { BoxProps, SxProps } from "@mui/system";
import { palette } from "@palette";
import { Tooltip } from "@common/Tooltip";
import { tooltipClasses } from "@mui/material";

type TabProps = {
  value: string;
  onClick: (value: string) => void;
  selected?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  flex?: number;
  tooltip?: string;
  sx?: SxProps;
  dataTestId?: string;
};

const OwnershipTab = ({
  selected = false,
  disabled = false,
  value,
  onClick,
  children,
  flex,
  sx,
  tooltip,
  dataTestId,
}: TabProps) => {
  const handleClick = () => {
    if (disabled || selected) return;
    onClick(value);
  };

  return (
    <Box sx={{ flex: flex || 1, ...sx }}>
      <Tooltip
        disableFocusListener={!tooltip}
        disableHoverListener={!tooltip}
        disableTouchListener={!tooltip}
        title={tooltip}
        placement="top"
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            top: "10px",
            boxShadow: 0,
          },
        }}
      >
        <TabBase
          type="button"
          selected={selected}
          disabled={disabled}
          onClick={handleClick}
          data-testid={dataTestId}
        >
          {children}
        </TabBase>
      </Tooltip>
    </Box>
  );
};

const TabBase = styled("button", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<BoxProps & { selected?: boolean; disabled?: boolean }>(
  ({ selected, disabled }) => ({
    all: "unset",
    display: "flex",
    minWidth: "80px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    cursor: disabled || selected ? "default" : "pointer",
    fontSize: 14,
    color: palette.neutral[80],
    lineHeight: "120%",
    userSelect: "none",
    opacity: disabled && !selected ? 0.5 : 1,
    width: "100%",

    ...(selected
      ? {
          border: `1px solid ${palette.liftedWhite[100]}`,
          background: palette.liftedWhite[100],
        }
      : {
          border: `1px solid ${palette.neutral[10]}`,
          background: palette.neutral[5],
          "&:hover": {
            border: !disabled
              ? `1px solid ${palette.neutral[40]}`
              : `1px solid ${palette.liftedWhite[100]}`,
          },
        }),
  }),
);

export default OwnershipTab;
