import { InfoIcon, TrashIcon } from "@assets/icons";
import { DoneCheckIcon } from "@assets/icons/RebrandedIcons";
import { ProhibitIcon } from "@assets/transactionsRiskProfileIcons";
import { Button } from "@common/Button";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { TruncateText } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { styled, Stack } from "@mui/material";
import { palette } from "@palette";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";

interface PermissionsListItemProps {
  isLast: boolean;
  onClick: () => void;
  assigned: boolean;
  permissionName: string;
  description: string;
  onDelete: () => void;
}

const PermissionsListItem = ({
  isLast,
  onClick,
  assigned,
  permissionName,
  description,
  onDelete,
}: PermissionsListItemProps) => {
  const isEditAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MEMBER,
      RESOURCE_BASE.ACCESS_POLICIES,
    ),
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const buttons = [
    {
      type: "allow" as TButton,
      active: assigned,
      onClick: onClick,
    },
    {
      type: "deny" as TButton,
      active: !assigned,
      onClick: onClick,
    },
  ];

  const deleteHandler = () => {
    if (!isEditAllowed) return;
    onDelete();
  };

  return (
    <Stack
      padding="8px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        ...(!isLast && {
          borderBottom: `1px solid ${palette.liftedWhite[100]}`,
        }),
        "&:hover": {
          backgroundColor: palette.neutral.white,
          ...(isEditAllowed && {
            "& .info-icon-description > svg > path": {
              fill: palette.black[200],
            },
            "& .list-item-delete": {
              display: "block",
            },
          }),
        },
      }}
    >
      <Title name={permissionName} description={description} />
      <Stack direction="row" gap="8px" alignItems="center">
        <WithTooltipWrapper
          hasTooltip
          tooltipProps={{
            show: !isEditAllowed,
            message: DELETE_DENY_MESSAGE,
          }}
        >
          <Stack
            className="list-item-delete"
            onClick={deleteHandler}
            sx={{
              display: "none",
              ...(isEditAllowed && { cursor: "pointer" }),
            }}
            direction="row"
            alignItems="center"
          >
            <TrashIcon width={16} height={16} stroke={palette.gray[300]} />
          </Stack>
        </WithTooltipWrapper>
        <WithTooltipWrapper
          hasTooltip
          tooltipProps={{
            show: !isEditAllowed,
            message: EDIT_DENY_MESSAGE,
          }}
        >
          <Stack direction="row">
            {buttons.map((button) => (
              <AssignButton
                {...button}
                key={button.type}
                disabled={!isEditAllowed}
              />
            ))}
          </Stack>
        </WithTooltipWrapper>
      </Stack>
    </Stack>
  );
};

type TButton = "allow" | "deny";

const buttonContent: Record<TButton, { icon: JSX.Element; text: string }> = {
  allow: {
    icon: <DoneCheckIcon width={16} height={16} stroke="#106D3D" />,
    text: "Allow",
  },
  deny: {
    icon: <ProhibitIcon width={16} height={16} />,
    text: "Deny",
  },
};

type TAssignButtonProps = {
  type: TButton;
  active: boolean;
  onClick: () => void;
  disabled: boolean;
};

const AssignButton = ({ type, active, ...rest }: TAssignButtonProps) => {
  return (
    <AssignButtonWrapper
      {...rest}
      left={type === "allow"}
      allow={type === "allow" && active}
      deny={type === "deny" && active}
      background="tertiary"
    >
      {active && buttonContent[type].icon}
      {buttonContent[type].text}
    </AssignButtonWrapper>
  );
};

const AssignButtonWrapper = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "allow" && prop !== "deny" && prop !== "left",
})<{ allow?: boolean; deny?: boolean; left?: boolean }>(
  ({ allow = false, deny = false, left = false }) => ({
    borderRadius: left ? "4px 0px 0px 4px" : "0px 4px 4px 0px",
    border: `1px solid ${palette.liftedWhite[100]}`,
    padding: "6px 8px",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 350,
    gap: "4px",
    color: palette.black[100],
    background: palette.liftedWhite.main,
    height: "max-content",
    "&:hover": {
      textDecoration: "none !important",
      background: `${palette.liftedWhite.main}90`,
    },

    ...(allow && {
      background: palette.tag.success.bg,
      color: "#106D3D",

      "&:hover": {
        background: `${palette.tag.success.bg}90`,
      },
    }),
    ...(deny && {
      background: palette.tag.error.bg,
      color: "#941A1A",
      "&:hover": {
        background: `${palette.tag.error.bg}90`,
      },
    }),
    ...(left
      ? {
          borderRight: "none",
        }
      : {
          borderLeft: "none",
        }),
  }),
);

const Title = ({
  name,
  description,
}: {
  name: string;
  description: string;
}) => {
  return (
    <Stack direction="row" gap="4px" alignItems="center">
      <TruncateText
        lineClamp={1}
        color={palette.black[100]}
        lineHeight="16.8px"
      >
        {name}
      </TruncateText>
      {description && (
        <Tooltip title={description} className="info-icon-description">
          <InfoIcon width={16} height={16} path_fill={palette.gray[300]} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default PermissionsListItem;
