import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

const SHORT_COOLDOWN = 300000; //5 minutes
export const LONG_COOLDOWN_MESSAGE = "Resend invitation limit was reached";

const getTimeRemaining = (cooldownEndsAt: number | undefined) => {
  if (!cooldownEndsAt) return 0;

  return new Date(Number(`${cooldownEndsAt}000`)).getTime() - Date.now();
};

const checkIfSendNewEmail = (
  sentEmailCount: number,
  cooldownEndsAt: number,
) => {
  if (
    sentEmailCount === 5 &&
    new Date(Number(`${cooldownEndsAt}000`)).getTime() - Date.now() < 0
  ) {
    return true;
  } else {
    return false;
  }
};

type TProps = {
  cooldownEndsAt: number | undefined;
  sentEmailCount?: number;
  inviteStatus: string;
};

export const useResendButtonState = ({
  cooldownEndsAt = 0,
  sentEmailCount = 0,
  inviteStatus,
}: TProps) => {
  const queryClient = useQueryClient();
  const [isDisableResend, setIsDisableResend] = useState(false);
  const [countdown, setCountdown] = useState<number>(0);
  const intervalId = useRef<any>(null);
  const remainingTimeRef = useRef<number>(0);
  const modifiedInviteStatus = isDisableResend ? "invited" : inviteStatus;
  const shouldSendNewEmail = checkIfSendNewEmail(
    sentEmailCount,
    cooldownEndsAt,
  );

  const startCountDown = (remainingTime: number) => {
    remainingTimeRef.current = remainingTime;
    setCountdown(remainingTimeRef.current);
    intervalId.current = setInterval(() => {
      remainingTimeRef.current -= 1000;
      setCountdown(remainingTimeRef.current);

      if (remainingTimeRef.current <= 0) {
        clearInterval(intervalId.current);
        setIsDisableResend(false);
        queryClient.invalidateQueries("get-merchant-preview");
        queryClient.invalidateQueries("get-merchant-msp-status");
      }
    }, 1000);
  };

  useEffect(() => {
    const timeRemaining = getTimeRemaining(cooldownEndsAt);

    if (timeRemaining > 0) {
      setIsDisableResend(true);
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      startCountDown(timeRemaining);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [cooldownEndsAt]);

  return {
    isDisableResend,
    setIsDisableResend,
    countdown,
    remainingTimeRef,
    isLongCooldownActive: remainingTimeRef.current > SHORT_COOLDOWN,
    inviteButtonText:
      shouldSendNewEmail || modifiedInviteStatus !== "invited"
        ? "Send"
        : "Resend",
    shouldSendNewEmail,
  };
};
