import * as React from "react";
import { palette } from "@palette";
import { useNavigate } from "react-router";
// mui
import Box from "@mui/material/Box";
// import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
// components
import { Text } from "@common/Text";
// utils
import { parseAmount, toEnFormat } from "utils";
// assets
import { MoreIcon } from "@assets/icons";
// data
import { TransactionData } from "@components/ManageMoney/TransactionTable/data";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";

type TableCardProps = {
  rowData: TransactionData;
  closeModal: () => void;
};

const Container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "12px 8px",
  gap: "8px",
  width: "100%",
  height: "122px",
  borderRadius: "8px",
  background: "#FFFFFF",
  boxShadow:
    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
};

const TableCard: React.FC<TableCardProps> = ({ rowData, closeModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(namespaces.common);

  return (
    <Box sx={Container}>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box
          onClick={() => {
            closeModal();
            navigate(`/provider/merchants/transactions/${rowData.id}`, {
              state: { rowData },
            });
          }}
        >
          <Text variant="body" color={palette.neutral[600]}>
            {rowData.time}
          </Text>
          <Text
            variant="headline"
            color={palette.neutral[800]}
            fontWeight="medium"
          >
            {rowData.title}
          </Text>
        </Box>
      </Box>

      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        onClick={() => {
          closeModal();
          navigate(`/provider/merchants/transactions/${rowData.id}`, {
            state: { rowData },
          });
        }}
      >
        <Text
          variant="headline"
          color={palette.neutral[800]}
          fontWeight="medium"
        >
          {rowData.type}
        </Text>
        <Stack>
          <Text
            variant="headline"
            color={palette.neutral[800]}
            fontWeight="medium"
          >
            {parseAmount(rowData.amount)}
          </Text>

          <Text variant="body" color={palette.neutral[600]}>
            {parseAmount(rowData.balance)}
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default TableCard;
