type IconProps = {
    height?: number;
    width?: number;
    fill?: string;
    stroke?: string;
    path_fill?: string;
    color?: string;
  };
  
  const UpIcon = ({
    width = 24,
    height = 24,
    fill = "none",
    path_fill = "#4C4A52",
    color
  }: IconProps) => {
    return (
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.39564 6.42475C1.16132 6.65907 0.781425 6.65907 0.547111 6.42475C0.312796 6.19044 0.312796 5.81054 0.547111 5.57622L1.39564 6.42475ZM5.90066 1.0712L5.4764 0.646935L5.4764 0.646934L5.90066 1.0712ZM6.04208 1.0712L6.46635 0.646934L6.46635 0.646935L6.04208 1.0712ZM11.3956 5.57622C11.63 5.81054 11.63 6.19044 11.3956 6.42475C11.1613 6.65907 10.7814 6.65907 10.5471 6.42475L11.3956 5.57622ZM5.37137 2.07704C5.37137 1.74566 5.64 1.47704 5.97137 1.47704C6.30274 1.47704 6.57137 1.74566 6.57137 2.07704L5.37137 2.07704ZM6.57137 13.5005C6.57137 13.8319 6.30275 14.1005 5.97137 14.1005C5.64 14.1005 5.37137 13.8319 5.37137 13.5005L6.57137 13.5005ZM0.547111 5.57622L5.4764 0.646935L6.32493 1.49546L1.39564 6.42475L0.547111 5.57622ZM6.46635 0.646935L11.3956 5.57622L10.5471 6.42475L5.61782 1.49546L6.46635 0.646935ZM6.57137 2.07704L6.57137 13.5005L5.37137 13.5005L5.37137 2.07704L6.57137 2.07704ZM5.4764 0.646934C5.74977 0.373569 6.19298 0.373567 6.46635 0.646934L5.61782 1.49546C5.81308 1.69073 6.12967 1.69072 6.32493 1.49546L5.4764 0.646934Z" fill={color || "#4C4A52"}/>
        </svg>
        
        
    );
  };
  
  export default UpIcon;
  