import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import { palette } from "@palette";
import { styled, useTheme } from "@mui/material/styles";
import React, { memo, useEffect } from "react";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFInput } from "@common/Input";
import { Stack } from "@mui/system";
import { RHFSwitch } from "@common/Switch";
import { Collapse } from "@mui/material";
import { RHFCustomEditor } from "@common/CustomEditor";
import { Button_V2 } from "@common/Button/Button";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { EditorState, ContentState } from "draft-js";
import { AmountType, AmountsListType } from "@redux/slices/fundraisers";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { IDropzoneProps } from "react-dropzone-uploader";
import NiceModal from "@ebay/nice-modal-react";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";
import { showMessage } from "@common/Toast";

interface Props {
  initialObject?: AmountType;
  onSubmit: (data: IFormInputs) => void;
  lastActive?: boolean;
  deleteAction: (action: string) => void;
  amountList: AmountsListType[];
  isLoading: boolean;
  toggleModal: () => void;
  isNewStyle?: boolean;
  isOnboarding?: boolean;
}
export type IFormInputs = {
  id?: string;
  title: string;
  amount: string;
  active: boolean;
  thumbnail: string | File | any;
  description: {
    enabled: boolean;
    text: EditorState;
  };
};

function CreateAmountForm({
  initialObject,
  amountList,
  onSubmit,
  lastActive,
  deleteAction,
  isLoading,
  toggleModal,
  isNewStyle,
  isOnboarding,
}: Props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation(namespaces.pages.fundraiserMinibuilder);
  const schema = Yup.object().shape({
    title: Yup.string().required("title is required"),
    amount: Yup.string().required("Must be above 1 USD"),
  });

  const defaultValues = {
    ...initialObject,
    title: initialObject ? initialObject.title : "",
    amount: initialObject ? initialObject.amount : "",
    thumbnail: initialObject ? initialObject.thumbnail : "",
    description: {
      enabled: initialObject ? initialObject.description?.enabled : false,
      text: initialObject?.description?.text,
    },
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, setValue, reset } = methods;
  const values = watch();

  const onEditorStateChange = (editorState: EditorState) => {
    setValue("description.text", editorState);
  };
  const handleCancel = () => {
    reset(defaultValues);
    toggleModal();
  };

  const handleRemoveAmountItem = (id: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "amount",
      deleteHandler: () => deleteAction(id),
    });
  };
  useEffect(() => {
    reset(defaultValues);
  }, [initialObject]);
  useEffect(() => {
    if (!values.description.enabled)
      setValue(
        "description.text",
        EditorState.createWithContent(ContentState.createFromText("")),
      );
  }, [watch("description.enabled")]);

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
    if (file) {
      setValue(
        "thumbnail",
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
    }
  };
  const SubmitHandler = (data: any) => {
    if (
      amountList.some(
        (amount) =>
          initialObject?.id !== amount.id && amount.title === data.title,
      )
    ) {
      return showMessage(
        "Error",
        "An element with the same name already exists",
      );
    }
    onSubmit(data);
    reset(defaultValues);
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(SubmitHandler)}>
        <StyledContainer
          display="grid"
          gap={2}
          gridTemplateColumns="repeat(12, 1fr)"
          isEdit={Boolean(initialObject?.id)}
          isNewStyle={isNewStyle}
        >
          <Box gridColumn={"span 12"}>
            <RHFUploadAvatar
              avatarType="thumbnail"
              name="thumbnail"
              title={t("thumbnail")}
              onChangeStatus={handleChangeStatus}
              isProductVariant
              height="115px"
              width="115px"
              key={Date.now()} // Needs to be investigated a bit better
            />
          </Box>

          <Box gridColumn={isDesktop ? "span 6" : "span 12"}>
            <RHFCustomAmountInput
              name="amount"
              currency="usd"
              initialValue={
                initialObject?.id === "1" ? "" : initialObject?.amount
              }
            />
          </Box>

          <Box gridColumn="span 12">
            <RHFInput
              name="title"
              label="Title"
              placeholder={t("thank_you_for_your_contribution")}
              fullWidth
            />
          </Box>

          <Box
            gridColumn="span 12"
            sx={{
              "@media (min-width: 600px)": {
                "& .rdw-emoji-modal": {
                  transform: `translateX(-10px) !important`,
                },
                "& .rdw-link-modal": {
                  transform: `translateX(-10px) !important`,
                },
                "& .rdw-colorpicker-modal": {
                  transform: `translateX(-120px) !important`,
                },
              },
            }}
          >
            <Stack mb={1} gap={2} direction="row" alignItems="center">
              <RHFSwitch name="description.enabled" label={t("description")} />
            </Stack>
            <Collapse orientation="vertical" in={values.description.enabled}>
              <RHFCustomEditor
                name="description.text"
                onEditorStateChange={onEditorStateChange}
                placeholder={t(
                  "give_some_insight_on_how_this_contribution_will_be_used",
                )}
                isOnboarding={isOnboarding}
              />
            </Collapse>
          </Box>
          <Box
            gridColumn="span 12"
            display="flex"
            flexDirection={isDesktop ? "row" : "column-reverse"}
            justifyContent={initialObject?.id ? "space-between" : "flex-end"}
            alignItems="center"
            width="100%"
            gap={2}
          >
            {initialObject?.id && initialObject?.id !== "1" && (
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
              >
                <Button_V2
                  background={
                    !isDesktop && initialObject?.id ? "secondary" : "tertiary"
                  }
                  fullWidth={!isDesktop}
                  disabled={lastActive}
                  onClick={() => handleRemoveAmountItem(initialObject?.id)}
                >
                  Delete
                </Button_V2>
              </Box>
            )}
            <Box
              display="flex"
              gap={2}
              flexDirection={isDesktop ? "row" : "column-reverse"}
              alignItems="center"
              sx={{
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              <Button_V2
                onClick={handleCancel}
                background={
                  isDesktop && initialObject?.id ? "secondary" : "tertiary"
                }
              >
                {t("cancel")}
              </Button_V2>
              <Button_V2
                type="submit"
                fullWidth={!isDesktop}
                disabled={isLoading}
                background="primary"
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : initialObject?.id ? (
                  t("save_changes")
                ) : (
                  "Create Option"
                )}
              </Button_V2>
            </Box>
          </Box>
        </StyledContainer>
      </Box>
    </FormProvider>
  );
}

type StyledContainerProps = {
  isEdit: boolean;
  isNewStyle?: boolean;
};

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isEdit" && prop !== "isNewStyle",
})(({ isEdit, isNewStyle }: StyledContainerProps) => ({
  padding: "16px",
  marginTop: "8px",
  borderRadius: "16px",
  border: isEdit
    ? `2px solid ${palette.neutral[950]}`
    : `2px dashed ${palette.liftedWhite[100]}`,
  ...(isNewStyle && {
    border: `1px solid ${palette.liftedWhite[100]}`,
  }),
}));

export default memo(CreateAmountForm);
