// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  stroke?: string;
}

const MerchantsIcon = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1855 11.0847V12.7963"
          stroke="url(#paint0_linear_658_7738)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1853 15.8627V17.9954C19.1853 19.103 18.2882 20 17.1807 20H14.2974V13.8856H9.83059L9.79398 20H6.8283C5.72075 20 4.82373 19.103 4.82373 17.9954V11.0847"
          stroke="url(#paint1_linear_658_7738)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.08466 11.4233H6.25171C5.00686 11.4233 4 10.4165 4 9.17162L4.54005 5.49199C4.66819 4.64073 5.40046 4.00916 6.26087 4.00916H9.32722V9.18077C9.32722 10.4256 8.32036 11.4325 7.07551 11.4325L7.08466 11.4233Z"
          stroke="url(#paint2_linear_658_7738)"
          strokeMiterlimit="10"
        />
        <path
          d="M9.33643 4H14.6728V9.17162C14.6728 10.4165 13.6659 11.4233 12.4211 11.4233H11.5881C10.3433 11.4233 9.33643 10.4165 9.33643 9.17162V4Z"
          stroke="url(#paint3_linear_658_7738)"
          strokeMiterlimit="10"
        />
        <path
          d="M16.9153 11.4233H17.7482C18.9931 11.4233 20 10.4165 20 9.17162L19.4599 5.48284C19.3318 4.63158 18.5995 4 17.7391 4H14.6636V9.17162C14.6636 10.4165 15.6704 11.4233 16.9153 11.4233Z"
          stroke="url(#paint4_linear_658_7738)"
          strokeMiterlimit="10"
        />
        <defs>
          <linearGradient
            id="paint0_linear_658_7738"
            x1="17.2809"
            y1="8.53686"
            x2="15.846"
            y2="9.09772"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_658_7738"
            x1="17.5581"
            y1="10.4397"
            x2="6.56849"
            y2="22.2841"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_658_7738"
            x1="6.83645"
            y1="2.97004"
            x2="-0.324442"
            y2="6.40829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_658_7738"
            x1="12.1829"
            y1="2.96088"
            x2="5.01433"
            y2="6.40874"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint4_linear_658_7738"
            x1="17.51"
            y1="2.96088"
            x2="10.3415"
            y2="6.40874"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1855 11.0847V12.7963"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1853 15.8627V17.9954C19.1853 19.103 18.2882 20 17.1807 20H14.2974V13.8856H9.83059L9.79398 20H6.8283C5.72075 20 4.82373 19.103 4.82373 17.9954V11.0847"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08466 11.4233H6.25171C5.00686 11.4233 4 10.4165 4 9.17162L4.54005 5.49199C4.66819 4.64073 5.40046 4.00916 6.26087 4.00916H9.32722V9.18077C9.32722 10.4256 8.32036 11.4325 7.07551 11.4325L7.08466 11.4233Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M9.33643 4H14.6728V9.17162C14.6728 10.4165 13.6659 11.4233 12.4211 11.4233H11.5881C10.3433 11.4233 9.33643 10.4165 9.33643 9.17162V4Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M16.9153 11.4233H17.7482C18.9931 11.4233 20 10.4165 20 9.17162L19.4599 5.48284C19.3318 4.63158 18.5995 4 17.7391 4H14.6636V9.17162C14.6636 10.4165 15.6704 11.4233 16.9153 11.4233Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default MerchantsIcon;
