import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean; padding?: string }) => ({
  width: "100%",
  padding: props.padding ?? `24px 24px ${props.isMobile ? "24" : "40"}px 24px`,
  display: "flex",
  flexDirection: "column",
  gap: `${props.isMobile ? "0" : "16"}px `,
  backgroundColor: palette.neutral.white,
  borderRadius: "12px 12px 0 0",
  "&:hover .suspend-button": {
    visibility: "visible",
  },
}));

export const HeaderText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean }) =>
  props.isMobile
    ? {
        fontSize: "18px",
        color: palette.neutral[70],
        alignSelf: "flex-start",
      }
    : {
        fontSize: "18px",
        color: palette.neutral[70],
      },
);
