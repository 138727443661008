import { Text } from "@common/Text";
import { Avatar, Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Tooltip } from "@common/Tooltip";
import { CloseIcon } from "@assets/rebrandIcons";
import { IconButton } from "@common/IconButton";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { DotsMenuIcon } from "@assets/icons";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";

type Props = {
  handleClose: () => void;
};

const CreateMerchantHeader = ({ handleClose }: Props) => {
  const { isMobileView } = useCustomTheme();

  if (isMobileView)
    return (
      <Container>
        <Stack direction="row" spacing={1} alignItems="center">
          <DotsMenuIcon fill={palette.neutral[70]} />
          <Text
            variant="caption"
            color={palette.neutral[80]}
            onClick={handleClose}
          >
            Merchants
          </Text>
          <Text color={palette.gray.main}>/</Text>
          <Text variant="caption" color={palette.neutral[80]}>
            Create Merchant
          </Text>
        </Stack>

        <Avatar
          src={AvatarPlaceholder}
          alt="Name"
          sx={{ height: 32, width: 32 }}
        />
      </Container>
    );

  return (
    <Container>
      <Text fontSize={20} fontWeight="book" color={palette.neutral[80]}>
        Create Merchant
      </Text>
      <Tooltip title="Close">
        <StyledIconButton aria-label="close" onClick={handleClose} size="small">
          <CloseIcon width={24} height={24} color={palette.neutral[70]} />
        </StyledIconButton>
      </Tooltip>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  padding: "8px 16px",
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(20px)",
  alignItems: "center",
  background: "rgba(251, 251, 251, 0.75)",
  minHeight: "50px",
  position: "sticky",
  top: 0,
}));

const StyledIconButton = styled(IconButton)(() => ({
  boxShadow: "none",
  border: "none",
  background: "none",
}));

export default CreateMerchantHeader;
