import { palette } from "@palette";
// @mui
import Box from "@mui/material/Box";
// components
import { Text } from "@common/Text";
import { Modal, ModalTitle } from "@common/Modal";
import { UploadFile } from "@components/UploadFile";
// assets
import { ArrowBackIcon } from "@assets/icons";
import { IDropzoneProps, StatusValue } from "react-dropzone-uploader";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import CampaignModalActions from "@common/Modal/CampaignModalActions";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { useQueryClient } from "react-query";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { UploadFileDocumentPreviewItem } from "@components/UploadFile/Rebranded/UploadFileDocumentPreviewItem";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import {
  AcceptAllowedGeneralDocumentsTypes,
  useUploadFiles,
} from "@hooks/upload-api/uploadHooks";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";
type UploadStatementProps = {
  data: IMerchantBankAccount;
  merchantId?: number;
};

type IFormInputsNew = {
  files: {
    allFiles: {
      file: File;
    }[];
  };
};
type IFormInputs = {
  files: {
    fileWithMeta: any | null;
    status: StatusValue;
    allFiles: any[];
  };
};
const UploadStatement = NiceModal.create(
  ({ data, merchantId }: UploadStatementProps) => {
    const modal = useModal();
    const queryClient = useQueryClient();
    const { merchantId: currentMerchantID } = useGetCurrentMerchantId();
    const { handleUpload } = useUploadFiles();
    const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
    const { populateSnackbarFiles, isUploadAllowed, isLoading } =
      useFileUploadContext();

    const defaultValues = {
      files: {
        fileWithMeta: null,
        status: "started" as const,
        allFiles: [],
      },
    };

    const schema = Yup.object({});

    const methods = useForm<IFormInputsNew | IFormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const { watch, setValue } = methods;
    const values = watch();

    const onSubmit: SubmitHandler<IFormInputsNew | IFormInputs> = async (
      submittedData,
    ) => {
      let uploadRes;
      if (submittedData.files?.allFiles?.length > 0) {
        if (isFileUploadRefactorEnabled) {
          uploadRes = await handleUpload({
            list: submittedData.files?.allFiles.map((item, index) => ({
              file: item.file,
              id: index,
            })),
            merchantId: merchantId || currentMerchantID,
            resourceID: +data.id,
            attachmentType: "bank_account",
            label: "bank account document",
            tag: "bank account document",
          });
        } else {
          uploadRes = await populateSnackbarFiles({
            fileWithMeta:
              (submittedData as IFormInputs).files?.fileWithMeta ||
              submittedData.files?.allFiles[0],
            status: (submittedData as IFormInputs).files?.status,
            allFiles: (submittedData as IFormInputs).files?.allFiles,
            attachmentType: "bank_account",
            label: "bank account document",
            tag: "bank account document",
            merchantId: merchantId || currentMerchantID,
            resourceID: +data.id,
          });
        }
      }
      if (uploadRes === "upload_failed") return;

      await customInstance({
        url: buildMerchantEndpoints(`bank-accounts/${data.id}`),
        method: "PATCH",
        data: { status: "pending_review" },
      });
      queryClient.refetchQueries("list-all-bank-accounts");
      modal.hide();
    };
    const deleteFileHandlerNew = (id: string | number) => {
      setValue("files", {
        allFiles: values.files.allFiles.filter((_, i) => i !== id),
      } as any);
    };
    const deleteFileHandlerOld = (id: string | number) => {
      const newIndex = values.files.allFiles.findIndex(
        (item) => item.meta.id === id,
      );
      values.files.allFiles[newIndex]?.remove();
      setValue("files", {
        allFiles: values.files.allFiles.filter((item) => item.meta.id !== id),
      } as any);
    };
    const handleSelect = (file: File) => {
      setValue(
        "files",
        {
          allFiles: [...values.files.allFiles, { file }],
        } as any,
        { shouldDirty: true },
      );
    };
    const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
      fileWithMeta,
      status,
      allFiles,
    ) => {
      setValue(
        "files",
        {
          fileWithMeta,
          status,
          allFiles,
        } as any,
        { shouldDirty: true },
      );
    };

    return (
      <Modal
        {...muiDialogV5(modal)}
        width="600px"
        sx={{
          "& .MuiDialogContent-root": {
            paddingTop: "0px !important",
            marginTop: "0px !important",
          },
        }}
        backgroundColor={palette.liftedWhite.main}
        headerComponent={
          <ModalTitle
            backgroundColor={palette.liftedWhite.main}
            title="Upload bank statement"
            subTitle={`"${data.name || ""}" ${data.bankName || ""}`}
            onClose={() => modal.hide()}
          />
        }
        onClose={() => modal.hide()}
        actions={
          <CampaignModalActions
            isShowDiscard
            isNotFullWidth
            direction="row"
            form="upload-statement"
            primaryBtnLabel={"Upload"}
            handleDiscard={() => modal.hide()}
            isSecondaryActionDisabled={false}
            isPrimaryActionDisabled={
              values.files.allFiles.length === 0 || isLoading
            }
            discardBtnLabel={
              <Box gap={1.5} display="flex" alignItems="center">
                <ArrowBackIcon />
                <Text color={palette.neutral[600]}>Back to bank accounts</Text>
              </Box>
            }
          />
        }
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="upload-statement"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FadeUpWrapper delay={80}>
              <Text
                variant={"body"}
                fontSize={14}
                fontWeight={"book"}
                lineHeight={"16.8px"}
                color={palette.neutral[600]}
                gutterBottom
              >
                Please upload a non blurry photo of the latest bank statement of
                your bank account. The name on the account, account number and
                address must be clearly displayed and match the information
                below. Scanned copies will not be accepted.
              </Text>
            </FadeUpWrapper>
            {/** -------- Upload Icon -------- */}
            <FadeUpWrapper delay={200}>
              <Box mb={1} mt={2.5}>
                <FlaggedWrapper
                  ActiveComponent={
                    <UploadFileNew
                      uploadFunction={handleSelect}
                      disabled={!isUploadAllowed}
                      accept={AcceptAllowedGeneralDocumentsTypes}
                    />
                  }
                  FallbackComponent={
                    <UploadFile
                      disabled={!isUploadAllowed}
                      backgroundColor="white"
                      onChangeStatus={handleChangeStatus}
                      width="100%"
                    />
                  }
                  name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
                />
              </Box>
            </FadeUpWrapper>
            <FadeUpWrapper delay={250}>
              {isFileUploadRefactorEnabled
                ? values.files.allFiles.map((file, index) => {
                    return (
                      <UploadFileDocumentPreviewItem
                        key={index}
                        fileName={file.file.name}
                        id={index}
                        deleteDocument={deleteFileHandlerNew}
                        fileURL={URL.createObjectURL(file.file)}
                      />
                    );
                  })
                : values.files.allFiles.map(({ meta }) => {
                    return (
                      <UploadFileDocumentPreviewItem
                        key={meta.id}
                        fileName={meta.name}
                        id={meta.id}
                        deleteDocument={deleteFileHandlerOld}
                        fileURL={meta.previewUrl}
                      />
                    );
                  })}
            </FadeUpWrapper>
          </Box>
        </FormProvider>
      </Modal>
    );
  },
);

export default UploadStatement;
