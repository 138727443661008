import React from "react";
import { useTheme } from "@mui/material/styles";
import { Stack, useMediaQuery } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { PurchasesGrid, column } from "./PurchasesGrid";
import { TransactionTableRow } from "../TransactionTable";
import { LeftColumnGrid } from "./LeftColumnGrid";
import { ChargedColumnGrid } from "./ChargedColumnGrid";

type ExpandedRowProps = {
  rowData: TransactionTableRow & any;
  open?: boolean;
  sourceAccount?: any;
  leftColData?: any;
  chargedColumns?: {
    fees: { key: string };
    cost: { key: string };
    charged: { key: string };
  };
  purchasesColumns?: column[];
};

export const TransactionTableExpandedRow: React.FC<ExpandedRowProps> = ({
  rowData,
  open,
}) => {
  const sourceAccount = React.useMemo(
    () => ({
      fullName: `${rowData.sourceAccount.user?.firstName} ${rowData.sourceAccount.user?.lastName}`,
      email: rowData.sourceAccount.user?.email,
      card: {
        brand: rowData.sourceMethod.method?.brand,
        numberLast4: rowData.sourceMethod.method?.numberLast4,
      },
    }),
    [rowData],
  );

  const isTransfer = rowData.displayType === "Money Transfer";

  const leftColData = {
    customerName: {
      title: "Customer Name",
      value: rowData.customerName,
    },
    thxId: {
      title: "Transaction ID",
      value: rowData.paymentDetails?.merchantTaxId ?? rowData.id,
    },
    cardholderName: {
      title: "Name on Card",
      value: rowData.sourceMethod.method.cardholderName,
    },
    card: {
      title: "Card",
      value: (
        <Stack gap={4} direction="row" alignItems="center">
          <Text>xxxx xxxx xxxx {rowData.sourceMethod.method.numberLast4}</Text>
          <Text color={palette.neutral[800]} textTransform="capitalize">
            {rowData.sourceMethod.method.brand}
          </Text>
        </Stack>
      ),
    },
    email: {
      title: "Email",
      value: rowData.customer?.email || "",
    },
  };

  const transferLeftColData = {
    thxId: {
      title: "Transaction ID",
      value: rowData.paymentDetails?.merchantTaxId ?? rowData.id,
    },
    recipient: {
      title: "Recipient",
      value: rowData?.destinationAccount?.merchant?.name,
    },
    accountNo: {
      title: "Account Number",
      value: (
        <Stack gap={4} direction="row" alignItems="center">
          <Text>
            xxxxxx{rowData?.destinationMethod?.bankAccount?.numberLast4}
          </Text>
        </Stack>
      ),
    },
    routingNumber: {
      title: "Routing Number",
      value: rowData?.destinationMethod?.bankAccount?.routingNumber,
    },
  };

  return (
    <ExpandedRow_V2
      rowData={rowData}
      open={open}
      sourceAccount={sourceAccount}
      leftColData={isTransfer ? transferLeftColData : leftColData}
    />
  );
};

const defaultChargedColumns = {
  fees: { key: "fees" },
  cost: { key: "cost" },
  charged: { key: "charged" },
};

export const ExpandedRow_V2: React.FC<ExpandedRowProps> = ({
  rowData,
  open,
  sourceAccount,
  leftColData,
  chargedColumns = defaultChargedColumns,
  purchasesColumns,
}) => {
  const theme = useTheme();

  const t = useMediaQuery(theme.breakpoints.down(1110));

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [styleProperties, setStyleProperties] = React.useState({
    paddingRight: 0,
    columnWidth: 150,
    isSmallView: t,
  });

  const handleAlignment = (width: number) => {
    const parentWidth = width + 32;
    const paddingRight = Math.round((0.8 / 8) * parentWidth) - 28;
    const columnWidth = (1 / 8) * parentWidth;
    setStyleProperties({
      paddingRight,
      columnWidth: t ? 200 : columnWidth,
      isSmallView: t,
    });
  };

  React.useEffect(() => {
    if (containerRef.current) {
      handleAlignment(containerRef.current.offsetWidth);
    }
  }, [open, t]);

  const amountCols = Object.entries(chargedColumns).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: Number.isNaN(Number(rowData[v.key]))
        ? rowData[v.key]
        : Number(rowData[v.key]),
    }),
    { fees: 0, cost: 0, charged: 0 },
  );

  const feePaidByCustomer = !rowData?.passFees;

  const isTransfer = rowData.displayType === "Money Transfer";
  return (
    <Stack
      display="flex"
      flexDirection={t ? "column" : "row"}
      flexWrap="wrap"
      padding={theme.spacing(3, 1.5, 2)}
      ref={containerRef}
    >
      <LeftColumnGrid leftColData={leftColData} />
      <ChargedColumnGrid
        {...amountCols}
        styleProperties={styleProperties}
        feePaidByCustomer={feePaidByCustomer}
        status={rowData.status}
        recipient={rowData?.destinationAccount?.merchant?.name}
        displayType={rowData?.displayType}
      />

      {!isTransfer && rowData.items && (
        <PurchasesGrid
          items={rowData.items}
          sourceAccount={sourceAccount}
          purchasesColumns={purchasesColumns}
          transactionStatus={rowData.displayStatus}
        />
      )}
    </Stack>
  );
};
