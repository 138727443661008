import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { palette } from "@palette";
import React from "react";
import { BulkInviteHeader } from "./components/panel.atoms";
import BulkInviteContent from "./components/BulkInviteContent";
import ErrorCatcher from "@common/Error/ErrorCatcher";

const BulkInvite = () => {
  return (
    <Wrapper>
      <BulkInviteHeader />
      <ErrorCatcher errorID="bulk-invite-panel">
        <BulkInviteContent />
      </ErrorCatcher>
    </Wrapper>
  );
};

const BulkInvitePanel = NiceModal.create(BulkInvite);
export default BulkInvitePanel;

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { open, onClose, SlideProps } = useNiceModal();
  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose,
        SlideProps,
        width: "600px",
        SidePanelProps: {
          PaperProps: {
            sx: {
              background: `${palette.neutral[5]} !important`,
            },
          },
        },
        DrawerProps: {
          PaperProps: {
            sx: {
              padding: "24px 12px 12px",
              height: "calc(100vh - 48px)",
              maxHeight: "calc(100vh - 48px)",
              background: `${palette.neutral[5]} !important`,
            },
          },
        },
      }}
    >
      {children}
    </ModalFactory>
  );
};
