import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { Stack, SwipeableDrawerProps } from "@mui/material";
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { BaseLink, BaseLinkProps } from "@common/Campaigns/BaseLink";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export interface BottomConfirmationMobileProps
  extends Omit<SwipeableDrawerProps, "onOpen"> {
  title?: string;
  cancelTitle?: string;
  confirmTitle?: string;
  confirmActionLink?: BaseLinkProps;
  customBottomActions?: JSX.Element;
  handleCancel?: VoidFunction;
  handleConfirm?: VoidFunction;
}

function BottomConfirmationMobile({
  title,
  cancelTitle,
  confirmTitle,
  customBottomActions,
  confirmActionLink,
  handleCancel,
  handleConfirm,
}: BottomConfirmationMobileProps) {
  const modal = useModal();
  const isModalVisible = modal.visible;
  const handleClose = () => {
    modal.hide();
  }

  const onCancel = () => {
    handleCancel?.();
    handleClose();
  };

  const onConfirm = () => {
    !confirmActionLink && handleConfirm?.();
    handleClose();
  };

  const confirmButton = (
    <Button onClick={onConfirm} sx={{ flex: 1 }} fullWidth size="medium">
      {confirmTitle}
    </Button>
  );
  const linkWrapper = confirmActionLink ? (
    <BaseLink
      onClick={handleClose}
      flex={1}
      sx={{ borderBottom: "none" }}
      {...confirmActionLink}
    >
      {confirmButton}
    </BaseLink>
  ) : (
    confirmButton
  );

  return (
    <SwipeableDrawerMobile
      open={isModalVisible}
      anchor="bottom"
      onOpen={() => ""}
      onClose={handleClose}
    >
      <Stack mt={2} padding="10px 10px" gap={5}>
        <Text
          color={({ palette }) => palette.neutral["80"]}
          textAlign="center"
          fontSize={24}
          lineHeight="24px"
        >
          {title}
        </Text>
        {customBottomActions || (
          <Stack direction="row" alignItems="baseline">
            <Button background="tertiary" onClick={onCancel}>
              {cancelTitle}
            </Button>
            {linkWrapper}
          </Stack>
        )}
      </Stack>
    </SwipeableDrawerMobile>
  );
}

export const DownloadReportActionModalMobile = NiceModal.create(BottomConfirmationMobile);
