import { Components } from "@mui/material";

export const tabs: Components["MuiTabs"] = {
  styleOverrides: {
    root: {
      minHeight: 36,
    },
    indicator: {
      display: "none",
    },
  },
};

export const tab: Components["MuiTab"] = {
  styleOverrides: {
    root: {
      minWidth: 121,
      minHeight: 36,
      padding: "10px 16px",
      background: "#ffffff",
      boxShadow:
        "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",

      "&.Mui-selected": {
        background: "#F9FAFB",
        boxShadow: "inset 0px 2px 5px rgba(90, 101, 113, 0.4)",
      },

      "&:first-of-type": {
        borderRadius: "8px 0px 0px 8px",
      },
      "&:last-of-type": {
        borderRadius: "0px 8px 8px 0px",
      },

      // Button Tab
      "&.MuiButtonTab-root": {
        border: "2px solid #dddce5",
        borderRadius: 8,
        height: 50,
        padding: "16px",
        boxShadow: "none",
        backgroundColor: "#fff",

        "&.Mui-selected": {
          background: "#F9F5FF",
          border: "3px solid #915CA7",

          svg: {
            "& > path": {
              fill: "#734089",
            },
            // ShareIcon
            g: {
              path: {
                stroke: "#734089",
              },
            },
          },
        },
      },
    },
    labelIcon: ({ ownerState }) => ({
      ...(ownerState.iconPosition === "start" && {
        svg: {
          marginRight: "8px",
        },
      }),
      ...(ownerState.iconPosition === "end" && {
        svg: {
          marginLeft: "8px",
        },
      }),
    }),
  },
};
