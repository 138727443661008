import SignAgreementSection, {
  SignAgreementSectionProps,
} from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/SignAgreementSection";
import { useWatch } from "react-hook-form";

interface IComponentProps extends SignAgreementSectionProps {
  parentCheckBoxFieldKey: string;
}

const MerchantAgreementSignSection = ({
  parentCheckBoxFieldKey,
  ...rest
}: IComponentProps) => {
  const isPSIChecked: boolean = useWatch({ name: parentCheckBoxFieldKey });
  const [isPreviousTerminationChecked, ...mandatoryFields]: [
    boolean,
    string,
    Date,
    string,
  ] = useWatch({
    name: [
      "msaPreviousTermination",
      "msaPreviousTerminationProcessorName",
      "msaPreviousTerminationAt",
      "msaPreviousTerminationReason",
    ],
  });

  const isSecondaryCheckboxValid = isPreviousTerminationChecked
    ? mandatoryFields.every((val) => !!val)
    : true;

  return (
    <SignAgreementSection
      {...rest}
      canSign={isPSIChecked && isSecondaryCheckboxValid}
    />
  );
};
export default MerchantAgreementSignSection;
