const ChromeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 1.25241C10.0002 1.25241 15.1578 1.02179 17.8922 6.18745H9.56133C9.56133 6.18745 7.98913 6.13696 6.64611 8.03748C6.26031 8.83518 5.84563 9.65689 6.31099 11.2763C5.64061 10.1446 2.75195 5.13273 2.75195 5.13273C2.75195 5.13273 4.78945 1.45662 10.0001 1.25241H10.0002Z"
        fill="#EF3F36"
      />
      <path
        d="M17.6245 14.366C17.6245 14.366 15.2446 18.9334 9.39014 18.7076C10.1135 17.4606 13.5567 11.5189 13.5567 11.5189C13.5567 11.5189 14.3886 10.1878 13.4072 8.07832C12.9081 7.34552 12.3993 6.57908 10.7597 6.17057C12.0787 6.15863 17.8777 6.17057 17.8777 6.17057C17.8777 6.17057 20.0502 9.76739 17.6245 14.366Z"
        fill="#FCD900"
      />
      <path
        d="M2.41195 14.4021C2.41195 14.4021 -0.368242 10.0653 2.75672 5.12547C3.47771 6.37247 6.92095 12.3142 6.92095 12.3142C6.92095 12.3142 7.66364 13.6982 9.98565 13.9047C10.8706 13.8399 11.7917 13.7846 12.9685 12.5761C12.3197 13.7198 9.40929 18.7173 9.40929 18.7173C9.40929 18.7173 5.19446 18.7942 2.41188 14.4021H2.41195Z"
        fill="#61BC5B"
      />
      <path
        d="M9.3877 18.7509L10.5595 13.8783C10.5595 13.8783 11.8472 13.7774 12.9275 12.5977C12.2571 13.7727 9.3877 18.7509 9.3877 18.7509Z"
        fill="#5AB055"
      />
      <path
        d="M6.07471 10.0556C6.07471 7.90773 7.8229 6.1658 9.97852 6.1658C12.1341 6.1658 13.8823 7.90773 13.8823 10.0556C13.8823 12.2036 12.1341 13.9455 9.97852 13.9455C7.8229 13.9431 6.07471 12.2036 6.07471 10.0556Z"
        fill="white"
      />
      <path
        d="M6.72803 10.0557C6.72803 8.26815 8.18205 6.81693 9.97846 6.81693C11.7724 6.81693 13.2288 8.26577 13.2288 10.0557C13.2288 11.8433 11.7749 13.2945 9.97846 13.2945C8.18445 13.2945 6.72803 11.8433 6.72803 10.0557Z"
        fill="url(#paint0_linear_2874_21120)"
      />
      <path
        d="M17.8753 6.17303L13.0504 7.5834C13.0504 7.5834 12.3222 6.51897 10.7573 6.17303C12.1149 6.1658 17.8753 6.17303 17.8753 6.17303Z"
        fill="#EACA05"
      />
      <path
        d="M6.21697 11.0985C5.53933 9.92843 2.75195 5.13272 2.75195 5.13272L6.32543 8.65501C6.32543 8.65501 5.95888 9.40706 6.09638 10.4834L6.2169 11.0985H6.21697Z"
        fill="#DF3A32"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2874_21120"
          x1="9.97829"
          y1="6.86266"
          x2="9.97829"
          y2="13.0995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86BBE5" />
          <stop offset="1" stopColor="#1072BA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default ChromeIcon;
