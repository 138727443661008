import {
  AmexCardIcon,
  DiscoverCardIcon,
  MasterCardIcon,
  VisaCardIcon,
} from "@assets/builderIcons";
import { VisualIndicator } from "@assets/icons";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { BrandsType } from "./MerchantAgreement/Form";

type Props = {
  cardName: BrandsType;
};

const iconMapper: Record<string, any> = {
  MasterCard: <MasterCardIcon width={22.4} height={16} />,
  Visa: <VisaCardIcon width={22.4} height={16} />,
  Discover: <DiscoverCardIcon width={22.4} height={16} />,
  AMEX: <AmexCardIcon width={22.4} height={16} />,
};

const CardType = ({ cardName }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        padding: "2px 14px",
        backgroundColor: "#ECECE9",
        borderRadius: "100px",
        gap: "6px",
        cursor: "default",
      }}
    >
      {iconMapper[cardName]}
      <Text fontSize={14}>{cardName}</Text>
      <VisualIndicator width={16} height={16} />
    </Stack>
  );
};

export default CardType;
