import * as React from "react";
import { palette } from "@palette";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "@mui/material";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
// assets
import {
  FileDockIcon,
  ChromeIcon,
  SafariIcon,
  FirefoxIcon,
} from "@assets/icons";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { showMessage } from "@common/Toast/ShowToast";

type IProps = {
  title: string;
  closeAction?: () => void;
  handleDownload  :() => void ;
};

const DownloadReport = NiceModal.create(({ title, closeAction ,handleDownload}: IProps) => {
  const theme = useTheme();
  const modal = useModal();
  const [choose, setChoose] = React.useState(false);
  const { t } = useTranslation(namespaces.pages.manageMoney);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    modal.hide();
    if (closeAction) closeAction();
  };

  const mockSuccessGeneration = () => {
    showMessage("Success", "Merchants.csv downloaded successfully");
    modal.hide();
    if (closeAction) closeAction();
  }

  const downloadLinks = {
    safari: "https://www.idownloadblog.com/2020/01/22/always-allow-downloads-safari-mac/",
    chrome: "https://techwiser.com/stop-google-chrome-from-blocking-downloads/",
    firefox: "https://support.mozilla.org/en-US/kb/cant-download-or-save-files",
  }

  return (
    <Modal
      {...muiDialogV5(modal)}
      title={`${title} Report`}
      titleIcon={<FileDockIcon />}
      onClose={handleClose}
      actions={
        <>
          <Button variant="text" onClick={() => handleClose()}>
            {t("buttons.cancel", { ns: namespaces.common })}
          </Button>
          <Button variant="primary" onClick={handleDownload || mockSuccessGeneration}>
            {t("buttons.DownloadReport", { ns: namespaces.common })}
          </Button>
        </>
      }
    >
      {choose ? (
        <>
          {/** --------------------- CHOOSE BROWSWER ------------------ */}
          <Stack gap={2}>
            <Text
              variant="h5"
              textAlign="center"
              fontWeight="semibold"
              color={palette.neutral[800]}
            >
              {t("blockingDownload_msg")}
            </Text>
            {/* ------------ Button Icons ------------ */}
            <Stack
              gap={1.5}
              justifyContent="center"
              direction={isDesktop ? "row" : "column"}
            >
              <Link
                href={downloadLinks.safari}
                underline="none"
                target="_blank"
              >
                <Button
                  variant="primary"
                  fullWidth={!isDesktop}
                  endIcon={<SafariIcon />}
                  size={isDesktop ? "small" : "medium"}
                >
                  Safari
                </Button>
              </Link>
              <Link 
                href={downloadLinks.chrome}
                underline="none"
                target="_blank"
              >
                <Button
                  variant="primary"
                  fullWidth={!isDesktop}
                  endIcon={<ChromeIcon />}
                  size={isDesktop ? "small" : "medium"}
                >
                  Chrome
                </Button>
              </Link>
              <Link
                href={downloadLinks.firefox}
                underline="none"
                target="_blank"
              >
                <Button
                  variant="primary"
                  fullWidth={!isDesktop}
                  endIcon={<FirefoxIcon />}
                  size={isDesktop ? "small" : "medium"}
                >
                  Firefox
                </Button>
              </Link>
            </Stack>

            <Text
              variant="body"
              textAlign="center"
              color={palette.neutral[600]}
            >
              {t("try_different_browser_msg")}
            </Text>
          </Stack>
        </>
      ) : (
        <>
          <Text
            variant="h5"
            fontWeight="semibold"
            color={palette.neutral[800]}
            gutterBottom
          >
            {t("download_Transactions_report_msg")}
          </Text>

          <Text
            variant="body"
            mt={1}
            fontWeight="medium"
            color={palette.neutral[600]}
          >
            {t("download_Transactions_report_msg2")}
            <Box
              component="span"
              fontWeight="semibold"
              sx={{ cursor: "pointer" }}
              color={palette.primary.main}
              onClick={() => setChoose(true)}
            >
              {" "}
              {t("clickHere")}{" "}
            </Box>
          </Text>
        </>
      )}
    </Modal>
  );
});

export default DownloadReport;
