import { TFundraiserModalInputs } from "./FundraisersMinibuilder/types";
import { TEventModalInputs } from "./EventsMinibuilder/types";
import {
  SubscriptionType,
  TFundraiserModalInputs as TMembershiptModalInputs,
} from "./MembershipsMinibuilder/types";
import { TCampaignType } from "./types";
import { formatVariantValue } from "@utils/index";
import { editorStateToString } from "@utils/draft.editor.helpers";
import { EditorState } from "draft-js";
import { toUnixDateFormat } from "@utils/date.helpers";
import { TPaymentFormModalInputs } from "./PaymentFormMinibuilder/types";

export const formatDataToSubmit = (campaignType: TCampaignType, data: any) => {
  const getImageURL = (image: any) => {
    if (!image) return "";
    if (typeof image === "string") return image;
    return image?.URL;
  };

  const convertDescription = (
    description?: EditorState | string | (() => string | EditorState),
  ): string => {
    if (!description) {
      return "";
    } else if (typeof description === "string") {
      return description;
    } else if (typeof description === "function") {
      const callbackDescription = description();
      return convertDescription(callbackDescription);
    } else {
      return editorStateToString(description);
    }
  };

  if (campaignType === "fundraiser" || campaignType === "invoice") {
    return formatFundraiserData(data, {
      convertDescription,
      getImageURL,
      campaignType,
    });
  } else if (campaignType === "membership") {
    return formatMembershipData(data, {
      convertDescription,
      getImageURL,
      campaignType,
    });
  } else if (campaignType === "event") {
    return formatEventData(data, {
      convertDescription,
      getImageURL,
      campaignType,
    });
  } else if (campaignType === "payment-form") {
    return formatPaymentFormData(data, {
      convertDescription,
      getImageURL,
      campaignType,
    });
  }
};

type TParams = {
  convertDescription: (description?: EditorState | string) => string;
  getImageURL: (image: any) => string;
  campaignType: TCampaignType;
};

// format fundraisers data to submit
const formatFundraiserData = (
  data: TFundraiserModalInputs,
  params: TParams,
) => {
  const { convertDescription, getImageURL, campaignType } = params;

  const customPaymentTypes = Object.entries(data.payment_set_up.payment_types)
    .map((item) => {
      const [key, val] = item;
      if (key === "one_time" && val) return { interval: "once" };
      if (key !== "default" && val) return { interval: key };
      return;
    })
    .filter((v) => v);

  const customAmounts = data.payment_set_up.amountsList.map(
    (amount: any, index: number) => {
      if (amount.min_max) {
        return {
          name: amount.title,
          description: convertDescription(amount.description),
          amount: formatVariantValue(amount.amount),
          allowCustomPrice: !amount.min_max.enabled,
          minPrice: formatVariantValue(amount.min_max.min),
          maxPrice: formatVariantValue(amount.min_max.max),
          imageURL: amount.thumbnail || "",
          isEnabled: amount.active,
          bundle: 1,
          id: amount.id,
          displayOrder: index,
          recurringIntervals: [{ interval: "once" }],
        };
      }
      return {
        name: amount.title,
        price: formatVariantValue(amount.amount),
        description: convertDescription(amount.description),
        imageURL: amount.thumbnail || "",
        isEnabled: amount.active,
        bundle: 1,
        id: amount.id,
        displayOrder: index,
        recurringIntervals: [{ interval: "once" }],
      };
    },
  );

  const customData = {
    typeName: campaignType === "payment-form" ? "standard" : campaignType,
    name: data.about.title,
    description: data.about.description,
    canBrowseCampaigns: data.configuration.browse_more,
    imageURL: getImageURL(data.style.image),
    usesBackgroundImage: data.style.useAsBackground,
    needsTax: data.configuration.customer_pays_fees,
    allowFeeChoice: false,
    variants: customAmounts,
    recurringIntervals: customPaymentTypes,
    defaultRecurringInterval:
      data.payment_set_up.payment_types.default === "one_time"
        ? "once"
        : data.payment_set_up.payment_types.default,
  };

  return customData;
};

const formatPaymentFormData = (
  data: TPaymentFormModalInputs,
  params: TParams,
) => {
  const { convertDescription, getImageURL, campaignType } = params;

  const customAmounts = data.payment_set_up.amountsList.map(
    (amount: any, index: number) => {
      if (amount.min_max) {
        return {
          name: amount.title,
          description: convertDescription(amount.description),
          amount: formatVariantValue(amount.amount),
          allowCustomPrice: !amount.min_max.enabled,
          minPrice: formatVariantValue(amount.min_max.min),
          maxPrice: formatVariantValue(amount.min_max.max),
          imageURL: amount.thumbnail || "",
          isEnabled: amount.active,
          inventory: isNaN(parseInt(amount.in_stock))
            ? null
            : parseInt(amount.in_stock),
          bundle: 1,
          id: amount.id,
          displayOrder: index,
          showAvailableVariants: amount.display,
          recurringIntervals: [{ interval: "once" }],
        };
      }
      return {
        name: amount.title,
        price: formatVariantValue(amount.amount),
        description: convertDescription(amount.description),
        imageURL: amount.thumbnail || "",
        isEnabled: amount.active,
        bundle: 1,
        inventory: isNaN(parseInt(amount.in_stock))
          ? null
          : parseInt(amount.in_stock),
        id: amount.id,
        displayOrder: index,
        showAvailableVariants: amount.display,
        recurringIntervals: [{ interval: "once" }],
      };
    },
  );

  const customData = {
    typeName: "standard",
    name: data.about.title,
    description: data.about.description,
    canBrowseCampaigns: data.configuration.browse_more,
    imageURL: getImageURL(data.style.image),
    usesBackgroundImage: data.style.useAsBackground,
    needsTax: data.configuration.customer_pays_fees,
    allowFeeChoice: false,
    variants: customAmounts,
    recurringIntervals: [{ interval: "once" }],
  };

  return customData;
};
// format membership data to submit
const formatMembershipData = (
  data: TMembershiptModalInputs,
  params: TParams,
) => {
  const { convertDescription, getImageURL, campaignType } = params;

  const findDefaultRecurringInterval = (subscription: SubscriptionType) => {
    const t = subscription.paymentTypes.find((t: any) => t.isDefault);
    return t?.title === "One-Time" ? "once" : t?.title.toLowerCase();
  };

  const getRecurringIntervals = (subscription: any) => {
    const subscriptions = subscription.paymentTypes.filter(
      (t: any) => t.checked,
    );
    return subscriptions.map((t: any) => ({
      interval: t.title === "One-Time" ? "once" : t.title.toLowerCase(),
    }));
  };

  const customSubscriptions = data.payment_set_up.subscriptionsList.map(
    (subscription: SubscriptionType, index: number) => {
      return {
        name: subscription.title,
        price: formatVariantValue(subscription.amount),
        description: convertDescription(subscription.description),
        imageURL: subscription.thumbnail || "",
        isEnabled: subscription.active,
        showAvailableVariants: subscription.display,
        inventory: parseInt(subscription.in_stock),
        bundle: 1,
        recurringIntervals: getRecurringIntervals(subscription),
        defaultRecurringInterval: findDefaultRecurringInterval(subscription),
        id: subscription.id,
        displayOrder: index,
      };
    },
  );

  let maxSubscriptions = parseInt(data.configuration.max_subscriptions);
  if (Number.isNaN(maxSubscriptions)) {
    maxSubscriptions = 1;
  }

  const customData = {
    typeName: campaignType,
    name: data.about.title,
    description: data.about.description,
    canBrowseCampaigns: data.configuration.browse_more,
    imageURL: getImageURL(data.style.image),
    usesBackgroundImage: data.style.useAsBackground,
    needsTax: data.configuration.customer_pays_fees,
    allowFeeChoice: false,
    variants: customSubscriptions,
    maxPurchaseQuantity: maxSubscriptions,
    recurringIntervals: [
      {
        interval: "once",
      },
    ],
    defaultRecurringInterval: "once",
  };

  return customData;
};

const formatEventData = (data: TEventModalInputs, params: TParams) => {
  const { convertDescription, getImageURL, campaignType } = params;

  const customAmounts = data.payment_set_up.amountsList.map(
    (ticket: any, index: number) => {
      return {
        name: ticket.title,
        price: formatVariantValue(ticket.amount),
        description: convertDescription(ticket.description),
        imageURL: ticket.thumbnail || "",
        isEnabled: ticket.active,
        showAvailableVariants: ticket.display,
        inventory: parseInt(ticket.in_stock),
        bundle: 1,
        id: ticket.id,
        displayOrder: index,
        recurringIntervals: [{ interval: "once" }],
      };
    },
  );

  const isManualAddress = (data as TEventModalInputs).date_and_location.location
    .manual;
  const customEventData = {
    maxPurchaseQuantity: +(data as TEventModalInputs).configuration.maxTickets,
    includeTime: (data as TEventModalInputs).date_and_location.date
      .include_time,
    startsAt: toUnixDateFormat(
      new Date(
        (data as TEventModalInputs).date_and_location.date.type === "period"
          ? ((data as TEventModalInputs).date_and_location.date
              .startDate as Date)
          : ((data as TEventModalInputs).date_and_location.date.day as Date),
      ),
    ),
    endsAt:
      (data as TEventModalInputs).date_and_location.date.type === "period"
        ? toUnixDateFormat(
            (data as TEventModalInputs).date_and_location.date.endDate as Date,
          )
        : null,
    showMap: (data as TEventModalInputs).date_and_location.location.showMap,
    ...(isManualAddress && {
      locationAddress: {
        name: "address",
        line1: data.date_and_location.location.address,
        city: data.date_and_location.location.city,
        state: data.date_and_location.location.state,
        zip: data.date_and_location.location.zip,
        country: data.date_and_location.location.country,
      },
    }),
    locationShortAddress:
      (data as TEventModalInputs).date_and_location.location.type ===
        "in person" && !isManualAddress
        ? (data as TEventModalInputs).date_and_location.location.location
        : null,
    locationURL:
      (data as TEventModalInputs).date_and_location.location.type === "online"
        ? (data as TEventModalInputs).date_and_location.location.event_url
        : null,
  };

  const customData = {
    typeName: campaignType,
    name: data.about.title,
    description: data.about.description,
    canBrowseCampaigns: data.configuration.browse_more,
    imageURL: getImageURL(data.style.image),
    usesBackgroundImage: data.style.useAsBackground,
    needsTax: data.configuration.customer_pays_fees,
    allowFeeChoice: false,
    variants: customAmounts,
    recurringIntervals: [
      {
        interval: "once",
      },
    ],
    defaultRecurringInterval: "once",

    ...customEventData,
  };

  return customData;
};
