import React, { useEffect, useMemo, useRef } from "react";
import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { UploadFile } from "@components/UploadFile";
import { IDropzoneProps, IFileWithMeta } from "react-dropzone-uploader";
import { palette } from "@palette";
import { UploadFilesProps } from "./types";
import { useGetIdentificationFiles } from "./hooks/useCamera";
import { useGetCurrentMerchantId } from "@hooks/common";
import { UploadFileDocumentPreviewItem } from "@components/UploadFile/Rebranded/UploadFileDocumentPreviewItem";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useDocumentHandlers } from "@hooks/common/documents";
import { useQueryClient } from "react-query";
import useCheckPAH from "@hooks/common/useCheckPAH";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { AcceptAllowedImagesTypes } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

export function VerifyUpload({
  updateProgressBar,
  submitData,
  data,
}: UploadFilesProps) {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { deleteHandler } = useDocumentHandlers(merchantId);
  const hasFiles = useRef<boolean>(false);
  const { data: files } = useGetIdentificationFiles();
  const { isLoggedInPAH } = useCheckPAH();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const ownerFiles = useMemo(
    () =>
      files?.data?.filter((doc: any) => doc?.attTypeName === "account_owner") ||
      [],
    [files],
  ) as TMerchantDocument[];

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
    allFiles,
  ) => {
    (async () => {
      if (ownerFiles.length > 0) return; // files max: 1
      hasFiles.current =
        [...allFiles].filter((x) => x.meta.status !== "removed").length > 0;

      if (status === "removed") {
        const newData =
          (data?.allFiles as IFileWithMeta[])?.filter(
            (x) => x.file.name !== fileWithMeta.file.name,
          ) ?? null;
        if (newData?.length === 0) {
          updateProgressBar(0);
        }
        submitData({ allFiles: newData, status: "removed" });
        return;
      }
      updateProgressBar(99);
      if (data === null) {
        submitData({ allFiles, status });

        return;
      }

      if (data?.allFiles && data?.allFiles?.length > 1) return;

      if (status === "done" || status === "error_file_size") {
        const existingFile = (data?.allFiles as IFileWithMeta[])?.find(
          (f) => f.file.name === fileWithMeta.file.name,
        );
        if (existingFile || (data.allFiles as IFileWithMeta[])?.length >= 3)
          return;

        if (!existingFile) {
          submitData({ allFiles, status });
        }
      }
    })();
  };
  const handleUpload = (file: File) => {
    if (ownerFiles.length > 0) return; // files max: 1
    updateProgressBar(99);
    const allFiles = [file];
    submitData({ allFiles, status: "done" });
  };

  const deleteFileHandlerNew = (index: string | number) => {
    submitData({
      allFiles: data?.allFiles?.filter((_, i) => i !== index) as any[],
      status: data?.status,
    });
    updateProgressBar(0);
  };
  const deleteFileHandlerOld = (id: string | number) => {
    const newIndex = data?.allFiles?.findIndex(
      (item: any) => item.meta.id === id,
    );
    if (data?.allFiles && newIndex !== undefined) {
      data?.allFiles[newIndex]?.remove();
    }
    submitData({
      allFiles: data?.allFiles?.filter(
        (item: any) => item.meta.id !== id,
      ) as any[],
      status: data?.status,
    });
  };
  const deleteOwnerFile = (doc: TMerchantDocument) => {
    deleteHandler(doc, () =>
      queryClient.refetchQueries(["get identification files", merchantId]),
    );
  };

  const isUploaded = files?.data?.some(
    (obj: any) => obj?.attTypeName === "account_owner",
  );

  useEffect(() => {
    const isCompleted = data && data?.allFiles && data?.allFiles.length > 0;
    updateProgressBar(isUploaded || isCompleted ? 100 : 0);
  }, [isUploaded, data]);
  return (
    <>
      <Text fontSize="18px" mt={2} mb={1.5} color={palette.neutral[750]}>
        Identity Verification
      </Text>
      <Box mt={2.5}>
        <FlaggedWrapper
          ActiveComponent={
            <UploadFileNew
              uploadFunction={handleUpload}
              accept={AcceptAllowedImagesTypes}
              maxFiles={1}
              disabled={!isLoggedInPAH}
              supportedFormatText=".png, .jpg,.jpeg"
              styles={{
                dropzone: {
                  flexDirection: "column-reverse",
                },
              }}
            />
          }
          FallbackComponent={
            <UploadFile
              maxFiles={1}
              multiple={false}
              disabled={!isLoggedInPAH}
              initialFiles={
                hasFiles.current ? [] : (data?.allFiles as File[]) ?? []
              }
              backgroundColor="white"
              onChangeStatus={handleChangeStatus}
              width="100%"
              supportedFormatText=".png, .jpg,.jpeg"
              styles={{
                dropzone: {
                  flexDirection: "column-reverse",
                },
              }}
            />
          }
          name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
        />
      </Box>
      <Stack direction="column" gap={1}>
        <FlaggedWrapper
          ActiveComponent={() => (
            <>
              {data?.allFiles?.map((file: File, i) => {
                return (
                  <UploadFileDocumentPreviewItem
                    key={i}
                    fileName={file.name}
                    id={i}
                    deleteDocument={deleteFileHandlerNew}
                    fileURL={URL.createObjectURL(file)}
                  />
                );
              })}
            </>
          )}
          FallbackComponent={() => (
            <>
              {data?.allFiles?.map(({ meta }) => {
                return (
                  <UploadFileDocumentPreviewItem
                    key={meta.id}
                    fileName={meta.name}
                    id={meta.id}
                    deleteDocument={deleteFileHandlerOld}
                    fileURL={meta.previewUrl}
                  />
                );
              })}
            </>
          )}
          name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
        />
        {ownerFiles
          .filter((doc) => doc?.attTypeName === "account_owner")
          ?.map((doc) => (
            <UploadFileDocumentPreviewItem
              key={doc.id}
              fileName={doc.fileName}
              id={doc.id.toString()}
              deleteDocument={() => deleteOwnerFile(doc)}
              fileURL={doc.fileURL}
            />
          ))}
      </Stack>
    </>
  );
}
