import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup/Popup";
import { palette } from "@palette";
import { MissingPermissionContent } from "./MissingPermissionContent";

export const MissingPermissionPopUp = NiceModal.create(() => {
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      sx={{
        ".MuiPaper-root": {
          backgroundColor: palette.neutral.white,
          padding: 0,
        },
        ".MuiDialogContent-root": {
          padding: "8px",
          paddingBottom: "48px",
        },
        ".MuiDialogActions-root": {
          paddingTop: 0,
        },
      }}
      actionLabel="Okay"
      onSubmit={handleClose}
    >
      <MissingPermissionContent handleClose={handleClose} />
    </Popup>
  );
});
