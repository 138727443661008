import { Text } from "@common/Text";
import { Box, Collapse } from "@mui/material";
import { memo, useMemo } from "react";
import { Button } from "@common/Button";
import { palette } from "@palette";
import BottomButtons from "./BottomButtons";
import usePaymentSetup from "../hooks/usePaymentSetup";
import CreateAmountForm from "@components/Fundraisers/FundraiserMiniBuilder/Payment/AmountsSection/components/CreateAmountForm";
import AmountListDnD from "@components/Fundraisers/FundraiserMiniBuilder/Payment/AmountsSection/components/AmountListDnD";
import EditAnyAmount from "@components/Fundraisers/FundraiserMiniBuilder/Payment/AmountsSection/EditAnyAmount";
import { AnyAmountType } from "@redux/slices/fundraisers";

interface Props {
  handleBack: () => void;
  handleNext: () => void;
  handleUpdateStatusValue: (value: number) => void;
  disabled: boolean;
  campaignType: string;
  backToWelcomePage?: () => void;
}

function PaymentSetup({
  handleBack,
  handleNext,
  handleUpdateStatusValue,
  disabled,
  campaignType,
  backToWelcomePage,
}: Props) {
  const {
    isOpen,
    contextValues,
    isLoading,
    toggleIsOpen,
    deleteAction,
    handleSubmit,
    onDragEnd,
    handleEditing,
    editingObject,
    onShowAmountItem,
    isAnyAmount,
    handleCorrectNext,
    isPaymentTypeLoading,
  } = usePaymentSetup({
    handleUpdateStatusValue,
    backToWelcomePage,
    campaignType,
    handleNext,
  });

  const isStandardForm = campaignType === "Standard";

  const addLabel = useMemo(() => {
    switch (campaignType) {
      case "Fundraiser":
      case "Invoice":
        return "Add Amount";

      case "Event":
      case "Sweepstakes":
        return "Add Ticket";

      default:
        return "Add Subscription";
    }
  }, [campaignType]);

  const description = `Provide preset amounts for customers to pick.${
    ["Fundraiser", "Invoice", "Standard"].includes(campaignType)
      ? " Users can also enter a custom amount."
      : ""
  }`;

  return (
    <>
      <Box>
        <Text fontSize={14} color={"#292727"} align="left">
          Payment amounts
        </Text>
        <Text fontSize={14} color={"#575353"} align="left">
          {description}
        </Text>
        <Collapse orientation="vertical" in={isOpen}>
          {isAnyAmount ? (
            <EditAnyAmount
              setIsEditAnyAmount={toggleIsOpen}
              amountData={editingObject as AnyAmountType}
              handleSubmit={handleSubmit}
            />
          ) : (
            <CreateAmountForm
              amountList={contextValues}
              deleteAction={deleteAction}
              isLoading={isLoading}
              onSubmit={handleSubmit}
              initialObject={editingObject}
              toggleModal={toggleIsOpen}
              isNewStyle
              isOnboarding
            />
          )}
        </Collapse>
        <AmountListDnD
          handleEditing={handleEditing}
          amountsList={contextValues}
          deleteAction={deleteAction}
          onDragEnd={onDragEnd}
          onShowAmountItem={onShowAmountItem}
        />
        <Collapse orientation="vertical" in={!isOpen}>
          <Button fullWidth sx={addAmountBtnStyle} onClick={toggleIsOpen}>
            {addLabel} &#43;
          </Button>
        </Collapse>
      </Box>

      <BottomButtons
        nextText={isStandardForm ? "Finish" : undefined}
        goBack={() => {
          handleBack();
        }}
        goNext={() => {
          handleCorrectNext();
        }}
        disabled={disabled}
        isLoading={isPaymentTypeLoading}
      />
    </>
  );
}

export default memo(PaymentSetup);

const addAmountBtnStyle = {
  color: palette.neutral[70],
  border: "1px dashed #DDDCE5",
  borderRadius: "8px",
  background: "none",
  boxShadow: "none",
  marginTop: 2,
  fontSize: "14px",

  "&:hover": {
    color: "white",
    boxShadow: "none",
    border: "none",
  },
};
