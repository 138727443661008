const KotoLeftArrow = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.48756 0.188912C6.72032 -0.0469391 7.10022 -0.0494376 7.33607 0.183331C7.57192 0.416099 7.57442 0.79599 7.34165 1.03184L2.13614 6.30627H9.3001C9.63147 6.30627 9.9001 6.5749 9.9001 6.90627C9.9001 7.23765 9.63147 7.50627 9.3001 7.50627H2.10907L7.34735 12.9608C7.57688 13.1998 7.5692 13.5796 7.33019 13.8091C7.09119 14.0387 6.71137 14.031 6.48184 13.792L0.286935 7.34136C0.171851 7.23204 0.100098 7.07754 0.100098 6.90627C0.100098 6.73212 0.174294 6.5753 0.292801 6.46569L6.48756 0.188912ZM13.3003 6.30627C13.6317 6.30627 13.9003 6.5749 13.9003 6.90627C13.9003 7.23765 13.6317 7.50627 13.3003 7.50627H11.9001C11.5687 7.50627 11.3001 7.23765 11.3001 6.90627C11.3001 6.5749 11.5687 6.30627 11.9001 6.30627H13.3003Z"
        fill="#131212"
      />
    </svg>
  );
};
export default KotoLeftArrow;
