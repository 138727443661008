import React from "react";
import { Grid } from "@mui/material";
import ListItemSkeleton from "@common/Skeleton/ListItemSkeleton";
import { TableSkeletons } from "@common/Skeleton/TableRowSkeleton";

export const LoadingMoreSkeleton = ({ columns }: any) => {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {Array.from(Array(8).keys()).map((x) => (
        <ListItemSkeleton
          key={`skeleton-item-${x}`}
          listSkeletons={TableSkeletons}
          height={56 as any}
          rowHeight={40}
          columns={columns}
        />
      ))}
    </Grid>
  );
};
