import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
// components
import { Text } from "@common/Text";

export type StatusType =
  | "approved"
  | "pending review"
  | "processing approved"
  | "declined";

export type StatusProps = {
  state: StatusType;
};

export default function Status({ state }: StatusProps) {
  const color = colorsMap[state];
  const background = backgroundMap[state];

  return (
    <Box
      sx={{
        fontSize: "14px",
        padding: "4px 16px",
        borderRadius: "32px",
        whiteSpace: "nowrap",
        backgroundColor: background,
      }}
    >
      <Text color={color} textTransform="capitalize">
        {state}
      </Text>
    </Box>
  );
}

const colorsMap = {
  "pending review": palette.neutral[600],
  approved: palette.success.main,
  declined: palette.error.main,
  "processing approved": palette.info.main,
};

const backgroundMap = {
  "pending review": palette.neutral[50],
  approved: palette.success.light,
  "processing approved": "#F8EFFF",
  declined: palette.error.light,
};
