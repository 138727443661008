import useFindBusinessProfileById from "@hooks/enterprise-api/merchants/useFindBusinessProfileById";
import { shouldBeHidden } from "@constants/constants";
import { STATUS_ENUM } from "@components/ProfilePage/ProfileSetupHome/HomepageCard";
import { useGetBankAccounts } from "@services/api/products/manage-money";

export const useGetProfileStatus = (merchant: any) => {
  const { data: legalEntity, isLoading } = useFindBusinessProfileById({
    id: merchant?.legalEntityID,
    merchantId: merchant?.accID,
  });

  const { data: bankAcountData, isLoading: bankAccountsLoading } =
    useGetBankAccounts(
      { sorting: "createdAt", merchantId: merchant?.accID, page: 1 },
      {
        refetchOnWindowFocus: false,
        enabled: Boolean(merchant?.accID),
      },
    );

  const hasPrincipal =
    !!legalEntity &&
    ((legalEntity?.principals && legalEntity?.canEdit) ||
      !legalEntity?.canEdit);

  const isLEApproved = legalEntity?.statusName === "approved";

  const isLEUnderVerification =
    !!legalEntity && legalEntity?.statusName === "ready_for_verification";

  const isBankAccountUnderVerification = bankAcountData?.total > 0;
  const isApprovedPAH = merchant?.owner?.statusName === "approved";

  const isBusinessInfoCompleted =
    merchant?.highTicketAmount >= 0 &&
    merchant?.averageTicketAmount >= 0 &&
    merchant?.servicePhoneNumber &&
    merchant?.annualCreditCardSalesVolume >= 0 &&
    merchant?.websiteURL &&
    (!merchant?.serviceCountriesOutUSCanada ||
      (merchant?.serviceCountriesOutUSCanada &&
        merchant?.countriesServicedOutside.length > 0));

  const isBusinessApproved = isLEApproved && isBusinessInfoCompleted;
  const isBusinessUnderVerification =
    isBusinessInfoCompleted && isLEUnderVerification;

  const isBankAccountApproved = bankAcountData?.data?.some(
    (ac: any) => ac.status === "approved",
  );

  const isMSACompleted =
    (merchant?.msaHadAgreed &&
      merchant?.msaLastAcceptedVersion === merchant?.aquirerTcVersion) ||
    shouldBeHidden.merchantAgreement;

  const pahUnderVerification =
    merchant?.owner?.statusName === "ready_for_verification";

  const pahStatus = isApprovedPAH
    ? STATUS_ENUM.APPROVED
    : pahUnderVerification
    ? STATUS_ENUM.UNDER_VERIFICATION
    : STATUS_ENUM.INCOMPLETE;

  const businessDetailsStatus = isBusinessApproved
    ? STATUS_ENUM.APPROVED
    : isBusinessUnderVerification
    ? STATUS_ENUM.UNDER_VERIFICATION
    : STATUS_ENUM.INCOMPLETE;

  const bankAccountStatus = isBankAccountApproved
    ? STATUS_ENUM.APPROVED
    : isBankAccountUnderVerification
    ? STATUS_ENUM.UNDER_VERIFICATION
    : STATUS_ENUM.INCOMPLETE;

  const agreementStatus = isMSACompleted
    ? STATUS_ENUM.APPROVED
    : STATUS_ENUM.INCOMPLETE;

  const isCompletedProfile =
    pahStatus != STATUS_ENUM.INCOMPLETE &&
    businessDetailsStatus != STATUS_ENUM.INCOMPLETE &&
    bankAccountStatus != STATUS_ENUM.INCOMPLETE &&
    agreementStatus != STATUS_ENUM.INCOMPLETE;

  return {
    allLoaded: !isLoading && !bankAccountsLoading,
    hasPrincipal,
    isBusinessDetailsCompleted: businessDetailsStatus != STATUS_ENUM.INCOMPLETE,
    isCompletedProfile: isCompletedProfile,
    isMSACompleted,
    bankAccounts: {
      data: bankAcountData?.total,
      rows: bankAcountData?.data,
      isLoading: bankAccountsLoading,
    },
    legalEntity: {
      data: legalEntity,
      isLoading,
    },
    pahStatus,
    agreementStatus,
    bankAccountStatus,
    businessDetailsStatus,
  };
};
