import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";

export interface TransactionsState {
  selectedMerchant: Record<string, MerchantData | null>;
  selectedDate: Record<string, string>;
}

const initialState: TransactionsState = {
  selectedMerchant: {},
  selectedDate: {},
};

const merchantFiltersSlice = createSlice({
  name: "merchantFilters",
  initialState,
  reducers: {
    setSelectedMerchant: (
      state: TransactionsState,
      action: PayloadAction<{ queryKey: string; value: MerchantData | null }>,
    ) => {
      const { queryKey, value } = action.payload;

      state.selectedMerchant[queryKey] = value;
    },
    setSelectedDate: (
      state: TransactionsState,
      action: PayloadAction<{ queryKey: string; value: string }>,
    ) => {
      const { queryKey, value } = action.payload;

      state.selectedDate[queryKey] = value;
    },
  },
});

export const { setSelectedMerchant, setSelectedDate } =
  merchantFiltersSlice.actions;

export const selectSelectedMerchant = (state: RootState, queryKey: string) =>
  state.merchantFilters?.selectedMerchant?.[queryKey];

export const selectDateFilter = (state: RootState, queryKey: string) =>
  state.merchantFilters?.selectedDate?.[queryKey];

export default merchantFiltersSlice.reducer;
