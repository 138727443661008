import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import React from "react";
import StatusReviewSwitch from "../StatusReviewSwitch";
import { Text } from "@common/Text";
import moment from "moment";
import { StyledIconButton } from "@common/IconButton";
import ResyncIcon from "@assets/icons/ResyncIcon";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import DisabledMoneyTransferWarning from "./DisabledMoneyTransferWarning";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  handleChangeProcessing: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTransfer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  processValue: boolean;
  transferValue: boolean;
  isApproved: boolean;
  lastMSPSyncAt: string | null;
  handleResyncMSP: () => void;
  isSuspended: boolean;
  isMerchantApproved: boolean;
  allowTransfers: boolean;
  isLoading: boolean;
  canSyncMSPData: boolean;
};

const AbleToComponent = (props: Props) => {
  const {
    handleChangeProcessing,
    handleChangeTransfer,
    processValue,
    transferValue,
    isApproved,
    lastMSPSyncAt,
    handleResyncMSP,
    isSuspended,
    isMerchantApproved,
    allowTransfers,
    isLoading,
    canSyncMSPData,
  } = props;
  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();
  const isUpdateMspAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.MSP),
    operation: OPERATIONS.SUBMIT,
  });
  const { isMobileView } = useCustomTheme();

  const tooltipBodyMessage = isSuspended
    ? "Account is suspended"
    : !isMerchantApproved
    ? "Merchant is not approved yet"
    : "";

  const ableToProcessDisbaled =
    isSuspended || !isMerchantApproved || !isUpdateUnderwriterAllowed;

  const ableToTransferDisabled =
    isSuspended ||
    !isMerchantApproved ||
    !isUpdateUnderwriterAllowed ||
    !allowTransfers;

  const resyncDisabled = isLoading || !canSyncMSPData || !isUpdateMspAllowed;

  const lastSyncDate =
    lastMSPSyncAt &&
    moment.unix(Number(lastMSPSyncAt)).format("MM/DD/YYYY - HH:mm");

  const approvedStatusActions = [
    {
      label: "Able to process",
      value: (
        <StatusReviewSwitch
          name="process"
          onChange={handleChangeProcessing}
          value={processValue}
          disabled={ableToProcessDisbaled}
          tooltip={tooltipBodyMessage}
        />
      ),
    },
    {
      label: "Able to transfer",
      value: (
        <StatusReviewSwitch
          name="transfer"
          onChange={handleChangeTransfer}
          value={transferValue}
          disabled={ableToTransferDisabled}
          tooltip={tooltipBodyMessage}
        />
      ),
    },
  ];
  return (
    <SwitchesContainer>
      {approvedStatusActions.map((action) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          key={action.label}
        >
          <Text color={palette.neutral[80]}>{action.label}</Text>
          {action.value}
        </Box>
      ))}
      {isApproved && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <LastSyncText isMobile={isMobileView}>
            <Text fontSize={12} color="neutral.70">
              Last Sync:
            </Text>
            <Text fontSize={12} color="neutral.70" data-testid="last-sync-date">
              {lastSyncDate}
            </Text>
          </LastSyncText>

          <ResyncButton
            startIcon={<ResyncIcon />}
            onClick={handleResyncMSP}
            disabled={resyncDisabled}
            data-testid="resync-msp-button"
          >
            <Text color="accent.3">Re-sync MSP</Text>
          </ResyncButton>
        </Stack>
      )}
      <DisabledMoneyTransferWarning
        isApproved={isApproved}
        allowedToTransfer={allowTransfers}
      />
    </SwitchesContainer>
  );
};

const ResyncButton = styled(StyledIconButton)(() => ({
  width: "fit-content !important",
  height: "20px !important",
  padding: "0 !important",
  "&:hover path": {
    fill: `${palette.accent[3]} !important`,
  },
}));

const SwitchesContainer = styled(Box)(() => ({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  justifyContent: "center",
  gap: "16px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[20]}`,
  background: palette.background.newWhite,
}));

const LastSyncText = styled(Stack, {
  shouldForwardProp: (prop: string) => prop !== "isMobile",
})(({ isMobile }: { isMobile: boolean }) => ({
  flexDirection: isMobile ? "column" : "row",
  gap: isMobile ? "0" : "4px",
}));

export default AbleToComponent;
