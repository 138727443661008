import { Text } from "@common/Text";
import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { StyledIconButton } from "../TeamPanelBody.styles";
import { ActionButton, ActionsContainer } from "./AddPermissionModal.styles";
import { CloseIcon } from "@assets/rebrandIcons";
import { palette } from "@palette";
import { CodeEditor } from "@common/CustomEditor";
import useJSONEditor from "../../hooks/useJSONEditor";

interface JSONModalContent {
  onClose: () => void;
  onCancel: () => void;
  onAdd: (permissionIds: string[]) => void;
  memberId: number;
}

const JSONModalContent = ({ onClose, onCancel, onAdd, memberId }: JSONModalContent) => {
  const { onChange, isDisabled, isLoading, onSubmit, onValidation } =
    useJSONEditor(onAdd, memberId);

  return (
    <>
      <ContentWrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title>Custom Permission</Title>
          <CloseButton onClose={onClose} disabled={isLoading} />
        </Stack>
        <Stack flexGrow={1}>
          <CodeEditor
            defaultValue=""
            setValue={onChange}
            customTheme={editorTheme}
            onValidation={onValidation}
            containerProps={{
              sx: {
                backgroundColor: palette.liftedWhite[100],
                marginBottom: "16px",
              },
            }}
            customOptions={customOptions}
          />
        </Stack>
        <ActionsContainer sx={{ padding: 0, paddingTop: "12px" }}>
          <ActionButton
            background="tertiary"
            size="small"
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </ActionButton>
          <ActionButton
            background="primary"
            size="small"
            onClick={onSubmit}
            disabled={isDisabled || isLoading}
          >
            {isLoading ? <LoadingIcon /> : "Add"}
          </ActionButton>
        </ActionsContainer>
      </ContentWrapper>
    </>
  );
};

const customOptions = {
  renderLineHighlight: "none",
  scrollbar: {
    verticalScrollbarSize: 4,
  },
};

const editorTheme = {
  base: "vs",
  inherit: true,
  rules: [],
  colors: {
    "editor.background": palette.liftedWhite[100],
    "editor.foreground": palette.black[200],
    // "editor.selectionBackground": palette.gray[300],
    "editorCursor.foreground": palette.black[200],
    "scrollbar.shadow": palette.liftedWhite[100],
  },
};

const LoadingIcon = () => {
  return (
    <Box sx={{ maxHeight: "20px" }}>
      <CircularProgress
        size={20}
        sx={{
          color: palette.neutral.black,
        }}
      />
    </Box>
  );
};

const CloseButton = ({
  onClose,
  disabled,
}: {
  onClose: () => void;
  disabled: boolean;
}) => (
  <StyledIconButton onClick={onClose} disabled={disabled}>
    <CloseIcon width={24} height={24} color={palette.black.main} />
  </StyledIconButton>
);

const ContentWrapper = styled(Stack)(() => ({
  flexDirection: "column",
  gap: "24px",
  padding: "16px",
  alignItems: "stretch",
  flexGrow: 1,
}));

const Title = styled(Text)(() => ({
  color: palette.black[100],
  fontSize: "24px",
  fontWeight: 350,
  lineHeight: "32.4px",
  letterSpacing: "-0.24px",
}));

export default JSONModalContent;
