import { Select } from "@common/Select";
import { Text } from "@common/Text";
import { RadioPaymentCard } from "@components/PaymentForm/PaymentCard";
import { Stack } from "@mui/material";
import { getCardColumns } from "@utils/publicForms";
import CardOptions from "./CardOptions";
import { useState } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { buttonLabelsMap } from "./CheckoutWrapper";

export const renderOptions = ({
  column,
  availablePaymentMethods,
  renderOptionsHelper,
  setFormattedDate,
  isDesktop,
  addToCartHandler,
  duration,
  subDuration,
  handleChangeDuration,
  handleChangeSubDuration,
}: any) => {
  if (!availablePaymentMethods || !column) {
    return null;
  }

  const { _type, arr, formattedDate } = renderOptionsHelper(
    column,
    availablePaymentMethods,
  );

  if (!isDesktop) {
    setFormattedDate(formattedDate);
  }

  if (column === "Until" && isDesktop) {
    return <Text sx={{ paddingBlock: "7.5px 7.5px" }}>{formattedDate}</Text>;
  }

  return (
    <CardOptions
      addToCartHandler={addToCartHandler}
      arr={arr}
      column={column}
      duration={duration}
      handleChangeDuration={handleChangeDuration}
      handleChangeSubDuration={handleChangeSubDuration}
      isDesktop={isDesktop}
      subDuration={subDuration}
      formattedDate={formattedDate}
    />
  );
};

const RadioPaymentCardWrapper = ({
  data,
  checkedVariant,
  duration,
  subDuration,
  campaign,
  handleCheckboxChange,
  handleSetValue,
  customDonation,
  availablePaymentMethods,
  palette,
  renderOptionsHelper,
  handleChangeDuration,
  handleChangeSubDuration,
  addToCartHandler,
  isDesktop,
  customStyle,
}: any) => {
  const [formattedDate, setFormattedDate] = useState<any>();

  const typeName = data?.general?.typeName as keyof typeof buttonLabelsMap;
  const noRecurring =
    availablePaymentMethods?.length === 1 &&
    availablePaymentMethods?.[0] === "one_time";

  return (
    <Stack
      direction="column"
      sx={{
        ...(!isDesktop && {
          height: "calc(100% - 64px)",
        }),
      }}
      gap={2}
      alignItems="center"
      width="100%"
    >
      <Stack
        direction="column"
        gap={2}
        alignItems="stretch"
        sx={donationListContainer(customStyle?.containerBorder)}
      >
        <Stack gap={2} direction="column" width="100%">
          <FadeUpWrapper delay={100}>
            <Stack
              direction={isDesktop ? "row" : "column"}
              alignItems={isDesktop ? "center" : "flex-start"}
              gap={2}
              justifyContent={isDesktop ? "space-between" : "center"}
            >
              <Text
                color="#292727"
                fontWeight="medium"
                variant="h6"
                lineHeight="22px"
                fontSize="18px"
              >
                {buttonLabelsMap[typeName]}
              </Text>
            </Stack>
          </FadeUpWrapper>
          {data?.payment.amountsList.map((option: any, index: number) => {
            if (!option.active) return null;

            const stringId = option.id.toString();
            const description = option.isDefault
              ? option.description
              : option.description?.text;
            const price = parseFloat(option.amount);
            return (
              <FadeUpWrapper key={option.id} delay={100 + (index + 1) * 50}>
                <RadioPaymentCard
                  name={stringId}
                  ticketId={stringId}
                  numberID={option.id}
                  description={description}
                  imageURL={option.thumbnail}
                  title={option.title}
                  price={price !== 0 ? price : undefined}
                  isChecked={stringId === checkedVariant}
                  onChange={handleCheckboxChange}
                  selected={stringId === checkedVariant}
                  formattedDate={formattedDate}
                  columns={getCardColumns(
                    duration,
                    subDuration,
                    noRecurring,
                  )}
                  min={stringId === checkedVariant ? campaign.min : null}
                  max={stringId === checkedVariant ? campaign.max : null}
                  handleSetValue={handleSetValue}
                  customDonation={customDonation}
                  quantity={option.quantity}
                  display={option.display}
                  isDesktop={isDesktop}
                  customStyle={customStyle}
                  typeName={typeName}
                  renderOptions={(column: string) =>
                    renderOptions({
                      column,
                      availablePaymentMethods,
                      renderOptionsHelper,
                      setFormattedDate,
                      isDesktop,
                      addToCartHandler,
                      duration,
                      subDuration,
                      handleChangeDuration,
                      handleChangeSubDuration,
                    })
                  }
                />
              </FadeUpWrapper>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

const donationListContainer = (containerBorder: string) => ({
  border: containerBorder || "1px solid #F4F3F7",
  borderRadius: "8px",
  width: "100%",
  backgroundColor: "transparent",
});

export default RadioPaymentCardWrapper;
