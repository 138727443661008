/* IMAGE WITH TEXT WIDGET */
export const IMAGE_WITH_TEXT_ROOT = "image_with_text_root";
export const IMAGE_WITH_TEXT_IMAGE_BLUEPRINT =
  "image_with_text_image_blueprint";
export const IMAGE_WITH_TEXT_CONTENT_WRAPPER_BLUEPRINT =
  "image_with_text_content_wrapper_blueprint";
export const IMAGE_WITH_TEXT_HEADING_BLUEPRINT =
  "image_with_text_heading_blueprint";
export const IMAGE_WITH_TEXT_DESCRIPTION_BLUEPRINT =
  "image_with_text_description_blueprint";
export const IMAGE_WITH_TEXT_IMAGE_CONTAINER_BLUEPRINT =
  "image_with_text_image_container_blueprint";
/* IMAGE CARD WIDGET */
export const IMAGE_CARD_CARD_ITEM_BLUEPRINT = "image_card_card_item_blueprint";
export const IMAGE_CARD_IMAGE_BLUEPRINT = "image_card_image_blueprint";
export const IMAGE_CARD_HEADING_BLUEPRINT = "image_card_heading_blueprint";
export const IMAGE_CARD_DESCRIPTION_BLUEPRINT =
  "image_card_description_blueprint";
export const IMAGE_CARD_CARD_IMAGE_CONTAINER =
  "image_card_card_image_container";

/* IMAGE GALLERY WIDGET */
export const IMAGE_GALLERY_ROOT_BLUEPRINT = "image_gallery_container_blueprint";
export const IMAGE_GALLERY_IMAGE_BLUEPRINT = "image_gallery_image_blueprint";

export const encodedBlueprintId = {
  // image_with_text
  2: IMAGE_WITH_TEXT_ROOT,
  10: IMAGE_WITH_TEXT_IMAGE_BLUEPRINT,
  11: IMAGE_WITH_TEXT_CONTENT_WRAPPER_BLUEPRINT,
  12: IMAGE_WITH_TEXT_HEADING_BLUEPRINT,
  13: IMAGE_WITH_TEXT_DESCRIPTION_BLUEPRINT,
  100: IMAGE_WITH_TEXT_IMAGE_CONTAINER_BLUEPRINT,
  //image_card
  14: IMAGE_CARD_CARD_ITEM_BLUEPRINT,
  15: IMAGE_CARD_IMAGE_BLUEPRINT,
  16: IMAGE_CARD_HEADING_BLUEPRINT,
  17: IMAGE_CARD_DESCRIPTION_BLUEPRINT,
  18: IMAGE_CARD_CARD_IMAGE_CONTAINER,
  // image_gallery
  21: IMAGE_GALLERY_ROOT_BLUEPRINT,
  22: IMAGE_GALLERY_IMAGE_BLUEPRINT,
};

export type encodedBlueprintIdType = keyof typeof encodedBlueprintId;
