import { useEffect } from "react";
import Cookies from "js-cookie";
import { useAppDispatch } from "@redux/hooks";
import { setSelectedAccount } from "@redux/slices/auth/accounts";
import { login } from "@redux/slices/auth/auth";
import { useGetUserPermissions } from "features/Permissions/AccessControl";
import { useResetApp } from "./common/useHandleLogout";
import { getAccounts } from "@services/api/onboarding/user";
import { TAccounts } from "@customTypes/accounts.types";
import { accountsParser } from "@components/Login/useAccountSelectionModal";
import { showMessage } from "@common/Toast";

const useInitialLogin = () => {
  const dispatch = useAppDispatch();
  const { handleReset } = useResetApp();

  const onError = () => {
    showMessage("Error", "Whoops.. an error occured. Please try again");
    handleReset();
  };

  useGetUserPermissions();

  const selectedAccount = localStorage.getItem("selected-account");

  const dispatchLogin = (user: any) =>
    dispatch(
      login({
        ...user,
        globalName: {
          firstName: "",
          lastName: "",
          phoneNumber: "",
        },
      }),
    );

  useEffect(() => {
    //we need to fetch the accounts data inside useEffect to avoid problems with dependencies
    //we need to fetch all accounts bc BE does not allow to fetch single by id
    const handleFetchAccountsData = async () => {
      try {
        const user = Cookies.get("user");
        if (user) {
          const userOBJ = JSON.parse(user);
          dispatchLogin(userOBJ);
          if (selectedAccount) {
            const response = await getAccounts();
            const data = response.data;
            const usedAccount = data.find(
              (acc: TAccounts) => Number(acc.id) === Number(selectedAccount),
            );
            if (!usedAccount) {
              onError();
            }
            //account parser accepts and returns array
            const parsedAccount =
              usedAccount && accountsParser([usedAccount], userOBJ.email);
            parsedAccount && dispatch(setSelectedAccount(parsedAccount[0]));
          }
        }
      } catch (error) {
        onError();
      }
    };
    handleFetchAccountsData();
  }, []);
};

export default useInitialLogin;
