import { Skeleton, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { NotificationIcon } from "@assets/emptyStatesIcons";
import { APP_BAR_HEIGHT } from "@constants/constants";

export const NotificationGroupLabel = styled(Text)(({ theme }) => ({
  margin: "12px 16px 8px",
  padding: "2px 8px",
  borderRadius: "4px",
  backgroundColor: palette.liftedWhite[100],
  color: palette.black[100],
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14.4px",
  width: "fit-content",
  userSelect: "none",

  [theme.breakpoints.down("sm")]: {
    margin: "24px 16px 8px",
  },
}));

export const NotificationsBodyEmptyState = () => {
  return (
    <Stack
      direction="column"
      gap={1}
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
    >
      <NotificationIcon />
      <Text
        color={palette.gray[300]}
        fontWeight="book"
        fontSize="18px"
        lineHeight="21.6px"
        sx={{
          letterSpacing: "-0.01em",
        }}
      >
        You are up to date! No new notifications.
      </Text>
    </Stack>
  );
};

export const NotificationsItemSkeleton = () => {
  return (
    <Stack
      direction="row"
      padding="12px 16px"
      gap="6px"
      borderBottom={`1px solid ${palette.liftedWhite[100]}`}
      data-testid="notification-skeleton"
    >
      <Skeleton variant="rounded" width={36} height={36} />
      <Stack
        flexGrow={1}
        direction="column"
        alignItems="stretch"
        gap="6px"
        justifyContent="flex-start"
      >
        {Array.from({ length: 2 }, (_, i) => (
          <TextSkeleton key={i} />
        ))}
      </Stack>
    </Stack>
  );
};

const TextSkeleton = () => <Skeleton variant="rounded" height={14} />;

export const getMaxHeight = () => {
  const windowHeight = window.innerHeight - APP_BAR_HEIGHT;
  const HEADER_HIGHT = 40;

  return Math.min(windowHeight, 824) - HEADER_HIGHT;
};

export const BodyWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "flex-start",
  flexGrow: 1,
  overflow: "hidden",
  scrollbarColor: `${palette.liftedWhite[200]} transparent`,

  [theme.breakpoints.up("sm")]: {
    minHeight: "412px",
    maxHeight: `${getMaxHeight()}px`,
  },
}));
