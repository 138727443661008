import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import Papa from "papaparse";
import React from "react";
import { COLUMN_NAMES, EXAMPLE_CSV_ROWS } from "../constants";

const BulkImportContent = ({ children }: { children: React.ReactNode }) => {
  const onClick = () => {
    const href = generateTemplate();
    const a = document.createElement("a");
    a.setAttribute("href", href);
    a.setAttribute("download", "merchants_template.csv");
    a.click();
  };

  return (
    <FadeUpWrapper delay={50}>
      <Stack
        direction="column"
        gap="20px"
        alignItems="stretch"
        padding="24px 0 20px"
      >
        <Text
          color={palette.black[100]}
          fontWeight="book"
          lineHeight="16.8px"
          sx={{
            "& span": {
              color: palette.accent[3],
              textDecoration: "underline",
              cursor: "pointer",
            },
          }}
        >
          Download a <span onClick={onClick}>sample CSV template</span> to see
          an example of the format required.
        </Text>
      </Stack>

      {children}
    </FadeUpWrapper>
  );
};

const generateTemplate = () => {
  const csv = Papa.unparse({
    fields: COLUMN_NAMES,
    data: EXAMPLE_CSV_ROWS,
  });
  return "data:text/csv;charset=utf-8," + encodeURI(csv);
};

export default BulkImportContent;
