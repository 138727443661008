import { useEffect } from "react";
import { useFormData } from "../CreateCampaignFormProvider";
import { paymentTypesArray } from "../components/PaymentTypes";
import { TPaymentTypes } from "../types";
import { CardType } from "../components/PaymentTypeCard";
import { getImageURL } from "@utils/getInage";
import { isFunction } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { AxiosError } from "axios";
import { editorStateToString } from "@utils/draft.editor.helpers";
import { useGetCurrentMerchantId } from "@hooks/common";
import { formatVariantValue } from "@utils/index";
import { TEventModalInputs } from "features/Minibuilders/EventsMinibuilder/types";
import { toUnixDateFormat } from "@utils/date.helpers";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";

interface Props {
  handleUpdateStatusValue: (value: number) => void;
  backToWelcomePage?: () => void;
}
export default function usePaymentTypes({
  handleUpdateStatusValue,
  backToWelcomePage,
}: Props) {
  const { formData, setFormValues } = useFormData();
  const paymentTypes = formData.paymentTypes;
  const selectedArray = paymentTypes.selectedArray;
  const isCreditCardFee = paymentTypes.isCreditCardFee;
  const isOptional = paymentTypes.isOptional;
  const { merchantId } = useGetCurrentMerchantId();
  const queryClient = useQueryClient();

  const createCampaign = useMutation(
    (data: any) => {
      return customInstance({
        url: `/merchants/${merchantId}/products`,
        method: "POST",
        data,
      });
    },
    { mutationKey: "create-campaign" },
  );
  const { isLoading } = createCampaign;

  useEffect(() => {
    if (selectedArray.length > 0) return handleUpdateStatusValue(100);
    handleUpdateStatusValue(0);
  }, [selectedArray.length]);

  const handleSelect = (name: TPaymentTypes) => {
    setFormValues(
      "paymentTypes",
      (() => {
        const newArray = selectedArray;
        const isPresent = newArray.some((data: CardType) => data.name === name);

        return {
          ...paymentTypes,
          selectedArray: isPresent
            ? newArray.filter((data: CardType) => data.name !== name)
            : [
                ...newArray,
                paymentTypesArray.find((data) => data.name === name),
              ],
        };
      })(),
    );
  };

  const isSweepstakeProduct =
    formData?.campaignType?.type?.toLocaleLowerCase() === "sweepstake";
  const isEventProduct =
    formData?.campaignType?.type?.toLocaleLowerCase() === "event";

  const handleNext = async () => {
    const isManualAddress = (formData as TEventModalInputs).date_and_location
      ?.location?.manual;
    const payload = {
      typeName: formData?.campaignType?.type?.toLocaleLowerCase(),
      name: formData?.campaignDetails?.title,
      needsTax: formData?.paymentTypes?.isOptional,
      needsShipping: false,
      imageURL: getImageURL(formData?.campaignDetails?.image),
      description: formData?.campaignDetails?.description,
      usesBackgroundImage: formData?.campaignDetails?.isBackgroud,
      canBrowseCampaigns: formData?.campaignDetails?.browse_more,
      recurringIntervals: formData?.paymentTypes?.selectedArray?.map(
        (data: any) => ({
          interval: data?.BE_label ?? data?.name,
        }),
      ),
      allowFeeChoice: formData?.paymentTypes?.isCreditCardFee,
      ...(isSweepstakeProduct && {
        endsDate: toUnixDateFormat(
          new Date(formData?.campaignDetails?.endDate),
        ),
      }),
      variants: formData?.paymentSetup?.map((data: any) => {
        return {
          name: data?.title,
          price: data?.amount ? formatVariantValue(data?.amount) : null,
          allowCustomPrice: !data?.min_max?.enabled ?? null,
          description:
            typeof data?.description === "string"
              ? data?.description
              : editorStateToString(data?.description?.text),
          minPrice: data?.min_max?.min
            ? formatVariantValue(data.min_max.min)
            : null,
          maxPrice: data?.min_max?.max
            ? formatVariantValue(data.min_max.max)
            : null,
          imageURL: data?.thumbnail,
          isEnabled: data?.active,
          bundle: 1,
        };
      }),
      //event locations and time payload
      ...(isEventProduct && {
        includeTime: (formData as TEventModalInputs)?.date_and_location.date
          .include_time,
        startsAt: toUnixDateFormat(
          new Date(
            (formData as TEventModalInputs)?.date_and_location.date.type ===
            "period"
              ? ((formData as TEventModalInputs)?.date_and_location.date
                  .startDate as Date)
              : ((formData as TEventModalInputs)?.date_and_location.date
                  .day as Date),
          ),
        ),
        endsAt:
          (formData as TEventModalInputs)?.date_and_location.date.type ===
          "period"
            ? toUnixDateFormat(
                (formData as TEventModalInputs)?.date_and_location.date
                  .endDate as Date,
              )
            : null,
        showMap: (formData as TEventModalInputs)?.date_and_location.location
          .showMap,
        ...(isManualAddress && {
          locationAddress: {
            name: "address",
            line1: formData.date_and_location.location.address,
            city: formData.date_and_location.location.city,
            state: formData.date_and_location.location.state,
            zip: formData.date_and_location.location.zip,
            country: formData.date_and_location.location.country,
          },
        }),
        locationShortAddress:
          (formData as TEventModalInputs).date_and_location.location.type ===
            "in person" && !isManualAddress
            ? (formData as TEventModalInputs).date_and_location.location
                .location
            : null,
        locationURL:
          (formData as TEventModalInputs).date_and_location.location.type ===
          "online"
            ? (formData as TEventModalInputs).date_and_location.location
                .event_url
            : null,
      }),
    };

    createCampaign.mutate(payload, {
      onSuccess: async (response) => {
        isFunction(backToWelcomePage) && backToWelcomePage();
        queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
      },
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data;
        console.log("errorMessage = ", errorMessage);
      },
    });
  };
  const toggleOptional = () => {
    setFormValues(
      "paymentTypes",
      (() => {
        return { ...paymentTypes, isOptional: !paymentTypes?.isOptional };
      })(),
    );
  };
  const toggleIsCreditCardFee = () => {
    setFormValues(
      "paymentTypes",
      (() => {
        const newOBJ = {
          ...paymentTypes,
          isCreditCardFee: !paymentTypes?.isCreditCardFee,
        };
        return newOBJ;
      })(),
    );
  };
  return {
    selectedArray,
    handleSelect,
    handleNext,
    toggleOptional,
    toggleIsCreditCardFee,
    isCreditCardFee,
    isOptional,
    isLoading,
  };
}
