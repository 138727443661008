import { SvgIconProps } from "@mui/material";

const ImageThumbnailIcon = ({
  width = 18,
  height = 14,
  fill = "#E6E6E3",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.875 0.125H2.125C1.79348 0.125 1.47554 0.256696 1.24112 0.491116C1.0067 0.725537 0.875 1.04348 0.875 1.375V12.625C0.875 12.9565 1.0067 13.2745 1.24112 13.5089C1.47554 13.7433 1.79348 13.875 2.125 13.875H15.875C16.2065 13.875 16.5245 13.7433 16.7589 13.5089C16.9933 13.2745 17.125 12.9565 17.125 12.625V1.375C17.125 1.04348 16.9933 0.725537 16.7589 0.491116C16.5245 0.256696 16.2065 0.125 15.875 0.125ZM15.875 1.375V9.40234L13.8383 7.36641C13.7222 7.2503 13.5844 7.1582 13.4327 7.09537C13.281 7.03253 13.1185 7.00019 12.9543 7.00019C12.7901 7.00019 12.6276 7.03253 12.4759 7.09537C12.3242 7.1582 12.1864 7.2503 12.0703 7.36641L10.5078 8.92891L7.07031 5.49141C6.83592 5.25716 6.5181 5.12558 6.18672 5.12558C5.85534 5.12558 5.53752 5.25716 5.30312 5.49141L2.125 8.66953V1.375H15.875ZM2.125 10.4375L6.1875 6.375L12.4375 12.625H2.125V10.4375ZM15.875 12.625H14.2055L11.393 9.8125L12.9555 8.25L15.875 11.1703V12.625ZM10.25 4.8125C10.25 4.62708 10.305 4.44582 10.408 4.29165C10.511 4.13748 10.6574 4.01732 10.8287 3.94636C11 3.87541 11.1885 3.85684 11.3704 3.89301C11.5523 3.92919 11.7193 4.01848 11.8504 4.14959C11.9815 4.2807 12.0708 4.44775 12.107 4.6296C12.1432 4.81146 12.1246 4.99996 12.0536 5.17127C11.9827 5.34257 11.8625 5.48899 11.7083 5.592C11.5542 5.69502 11.3729 5.75 11.1875 5.75C10.9389 5.75 10.7004 5.65123 10.5246 5.47541C10.3488 5.2996 10.25 5.06114 10.25 4.8125Z"
        fill={fill}
      />
    </svg>
  );
};

export default ImageThumbnailIcon;
