import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import {
  IAccessControlState,
  TAccessControlResource,
  TIsAllowedSelectorOptions,
  TUserPermissions,
} from "./types";
import { RootState } from "@redux/store";
import { OPERATIONS } from "@constants/permissions";

const accessControlSlice = createSlice({
  name: "accessControl",
  initialState,
  reducers: {
    initAccessControl: (
      state: IAccessControlState,
      action: PayloadAction<TUserPermissions>,
    ) => {
      state.authorizations = action.payload;
    },
  },
});

export const { initAccessControl } = accessControlSlice.actions;

export const selectIsAllowed = (
  state: RootState,
  permission: TIsAllowedSelectorOptions,
) => {
  const { resource, operation } = permission;
  const action = state.accessControl.authorizations[resource]?.[operation];
  return action === "allow";
};
export const selectIsAllowedByResource = (
  state: RootState,
  resource: TAccessControlResource,
) => {
  const actions = state.accessControl.authorizations[resource];
  if (actions) return actions;

  return {
    [OPERATIONS.READ]: "deny",
    [OPERATIONS.LIST]: "deny",
    [OPERATIONS.CREATE]: "deny",
    [OPERATIONS.UPDATE]: "deny",
    [OPERATIONS.DELETE]: "deny",
    [OPERATIONS.EXPORT]: "deny",
    [OPERATIONS.SUBMIT]: "deny",
    [OPERATIONS.CANCEL]: "deny",
    [OPERATIONS.LINK]: "deny",
    [OPERATIONS.UNLINK]: "deny",
    [OPERATIONS.MANAGE]: "deny",
  };
};

export default accessControlSlice.reducer;
