import { namespaces } from "../../resources/i18n.constants";

export const common = {
  [namespaces.common]: {
    buttons: {
      ok: "Ok",
      cancel: "Cancel",
      DownloadReport: "Download Report",
      more: "More",
      DownloadReportAgain: "Download report again",
      Done: "Done",
      clickHere: "click here",
      next: "Next",
      Prev: "Prev",
      UploadStatement: "Upload Statement",
      ConfirmDeletion: "Confirm Deletion",
      Edit: "Edit",
      Delete: "Delete",
      Back: "Back",
      Verify: "Verify",
      Remove: "Remove",
      CreateOption: "Create Option",
      PreviousStep: "Previous Step",
      CreateFundraiser: "Create Fundraiser",
      NextStep: "Next Step",
      Payment: "Payment",
    },
    menu: {
      refundTransactions: "Refund Transactions",
      sendReceipt: "Send Receipt",
      viewCustomer: "View Customer",
      moveTransaction: "Move Transaction",
    },
    modals: {
      NewBankAccount: "New Bank Account",
      LinkBankAccount: "Link Bank Account",
      AverageApproval1: " Average approval time is 1 day",
      Connect_Manually: "Connect Manually",
      AverageApproval3: "Average  approval time is 3 days",
      ConnectWithPlaid: "Connect with plaid",
    },
    toast: {
      success: "Success",
      error: "Error",
      warning: "Warning",
      info: "Info",
      Report_has_been_successfully_downloaded:
        " Report has been successfully downloaded",
      Bank_Account_deleted_successfully: "Bank Account deleted successfully",
      Team_Member_deleted_successfully: "Team Member deleted successfully",
    },
    filters: {
      date: "Date",
      created: "Created",
      amount: "Amount",
      status: "Status",
      recurrence: "Recurrence",
      type: "Type",
      conversion: "Conversion",
      transaction: "Transaction",
      transactions: "Transactions",
      "date-and-time": "Date and Time",
      search: "Search",
      isBefore: "IsBefore",
      greaterThan: "greater than",
      lessThan: "less than",
      isAfter: "is After",
      is: "is",
      isNot: "is not",
      is_on_or_before: "is on or before",
      is_on_or_after: "is on or after",
      isBetween: "is between",
      filterBy: "Filter By",
      apply: "Apply",
      visitors: "Visitors",
      entries: "Entries",
      "entries sold": "Entries Sold",
      inTheLast: "in the last",
      isEqualToOrGreaterThan: "is equal to or greater than",
      isEqualToOrLessThan: "is equal to or less than",
      purchases: "Purchases",
      source: "Source",
      joined: "Joined",
      total: "Total",
      quantity: "Quantity",
      reccuring_OneTime: "Recurring & One Time",
      AllRecurring: "All Recurring",
      oneTime: "One Time",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",
      quartely: "Quartely",
      CharitableDonations: "Charitable Donations",
      credits_Money_recived: "Credits (Money received)",
      debit_Money_transfered: "Debit (Money transfered)",
      giveboxPurshaes: "Givebox Purshaes",
      Pending: "Pending",
      Approved: "Approved",
      Refunded: "Refunded",
      Chargeback: "Chargeback",
      All: "All",
      Sales: "Sales",
      days: "days",
    },
    transactionTable: {
      SendReceipt: "Resend receipt",
      RefundTransaction: "Refund transaction",
      ViewCustomer: "View customer",
      MoveTransaction: "Move Transaction",
      Refund: "Refund transaction",
      Account: "Account",
      Status: "Status",
    },
    "Any Amount": "Any Amount",
    "Any payment amount is accepted.": "Any payment amount is accepted",
    transactions: "Transactions",
    GeneralInfo: "General Info",
    Notes: "Notes",
    characters_remaining: "Characters remaining",
    advanced_builder: "Advanced Builder",
    stepper: {
      GeneralInformation: "General Information",
      Payement: "Payment",
    },
    delete: {
      delete: "Delete",
      are_you_sure: "Are you sure that you want to delete this",
      cancel: "Cancel",
    },
  },
};
