import { CloseIcon } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import { TruncateText } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import React from "react";

const PreviewHeader = ({
  onClose,
  title,
  actions,
}: {
  onClose?: () => void;
  title: string;
  actions?: React.ReactNode;
}) => {
  const { isDesktopView } = useCustomTheme();
  const color = isDesktopView ? palette.black[100] : palette.neutral.white;
  const iconSize = {
    container: isDesktopView ? 24 : 32,
    svg: isDesktopView ? 20 : 32,
  };
  const { container, svg } = iconSize;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyItems="stretch"
      onClick={(event) => event.stopPropagation()}
      data-testid="preview-header"
    >
      {onClose && (
        <StyledIconButton
          sx={{
            height: `${container}px !important`,
            width: `${container}px !important`,
            padding: "0 !important",
          }}
          data-testid="close-preview-button"
          onClick={onClose}
        >
          <CloseIcon width={svg} height={svg} stroke={color} />
        </StyledIconButton>
      )}
      <TruncateText
        lineClamp={1}
        color={color}
        fontWeight="book"
        lineHeight="16.8px"
        align="center"
        flexGrow={1}
        data-testid="preview-title"
      >
        {title}
      </TruncateText>
      <Stack direction="row" alignItems="center" spacing="16px">
        {actions}
      </Stack>
    </Stack>
  );
};

export default PreviewHeader;
