import { useEffect, useRef, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import FundraisersAbout from "../FundraisersAbout";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TFundraiserModalInputs } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FundraiserModalSchema as schema,
  FundraiserModalDefaults as defaultValues,
} from "./utils";
import { Box } from "@mui/material";
import MembershipsPayment from "./MembershipsPayment";
import StyleSection from "../StyleSection";
import { useCreateProduct } from "../hooks";
import MembershipsConfiguration from "./MembershipsConfiguration";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { cloneDeep, isEqual } from "lodash";

const STEPS_OBJ = {
  ABOUT: "About",
  STYLE: "Style",
  PAYMENT_SETUP: "Payment set up",
  CONFIGURATION: "Configuration",
} as const;

type TStepsLabel = "About" | "Style" | "Payment set up" | "Configuration";

const getSchemaObject = (step: TStepsLabel) => {
  switch (step) {
    case "About":
      return "about";
    case "Style":
      return "style";
    case "Payment set up":
      return "payment_set_up";
    case "Configuration":
      return "configuration";
  }
};

const initialSteps = [
  {
    label: STEPS_OBJ.ABOUT,
    barValue: 0,
  },
  {
    label: STEPS_OBJ.STYLE,
    barValue: 0,
  },
  {
    label: STEPS_OBJ.PAYMENT_SETUP,
    barValue: 0,
  },
  {
    label: STEPS_OBJ.CONFIGURATION,
    barValue: 0,
  },
];

const initialStatus: {
  step: TStepsLabel;
  enabledSteps: TStepsLabel[];
  steps: {
    label: TStepsLabel;
    barValue: number;
  }[];
} = {
  step: STEPS_OBJ.ABOUT,
  enabledSteps: [],
  steps: initialSteps,
};

const CreateMembershipsModal = NiceModal.create(() => {
  const { submitProduct } = useCreateProduct("membership");
  const valuesRef = useRef<any>(null);
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });

  const [{ steps, step, enabledSteps }, setStatusBar] = useState(initialStatus);

  const modal = useModal();

  const methods = useForm<TFundraiserModalInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { reset, watch, trigger } = methods;
  const values = watch();

  useEffect(() => {
    if (step === STEPS_OBJ.STYLE || step === STEPS_OBJ.CONFIGURATION) {
      setStatusBar((prev) => {
        const array = prev.steps;
        const indexToUpdate = array.findIndex(
          (item) => item.label === prev.step,
        );
        array[indexToUpdate] = { ...array[indexToUpdate], barValue: 100 };
        enabledSteps.push(prev.step);

        return {
          ...prev,
          steps: array,
          enabledSteps,
        };
      });
    }
  }, [step]);

  useEffect(() => {
    if (isEqual(valuesRef.current, values)) return;
    valuesRef.current = cloneDeep(values);
    (async () => {
      const count =
        step === STEPS_OBJ.STYLE
          ? 100
          : await calculatePercentageNested(
              schema,
              values,
              getSchemaObject(step),
            );

      setStatusBar((prev) => {
        const array = prev.steps;
        const indexToUpdate = array.findIndex(
          (item) => item.label === prev.step,
        );
        array[indexToUpdate] = { ...array[indexToUpdate], barValue: count };
        const enabledSteps = prev?.enabledSteps;
        const addedIndex = enabledSteps.indexOf(prev.step);
        if (addedIndex !== -1 && count < 100) {
          enabledSteps.splice(addedIndex, 1);
        } else if (addedIndex === -1 && count >= 100) {
          enabledSteps.push(prev.step);
        }

        return {
          ...prev,
          steps: array,
          enabledSteps,
        };
      });
    })();
  }, [values, step]);

  const resetModal = () => {
    setStatusBar({
      ...initialStatus,
      steps: initialSteps.map((step) => ({ ...step, barValue: 0 })),
      enabledSteps: [],
    });
    reset();
    valuesRef.current = null;
  };

  const onClose = () => {
    modal.hide();
    resetModal();
  };

  const validateCurrentStep = () => {
    trigger("about.title");
  };

  const UISteps = {
    [STEPS_OBJ.ABOUT]: (
      <FundraisersAbout
        title="Tell us more about your Membership"
        placeHolderText="What’s the purpose of this membership?"
        fontSize="32px"
        mobileFontSize="24px"
      />
    ),
    [STEPS_OBJ.STYLE]: <StyleSection title="Make it unique" />,
    [STEPS_OBJ.PAYMENT_SETUP]: (
      <MembershipsPayment title="Set up Subscriptions" />
    ),
    [STEPS_OBJ.CONFIGURATION]: (
      <MembershipsConfiguration title="Configuration" />
    ),
  };

  const setCurrentStep = (activeStep: TStepsLabel) =>
    setStatusBar((prev) => ({
      ...prev,
      step: activeStep,
    }));

  const onSubmit: SubmitHandler<TFundraiserModalInputs> = async (data) => {
    submitProduct(data as any);
    onClose();
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      setCurrentStep={setCurrentStep}
      steps={steps}
      currentStep={step}
      HeaderProps={{
        title: "Create Membership",
      }}
      primaryAction={{
        onClick: validateCurrentStep,
        disabled: !enabledSteps.includes(step),
        label: "Next",
        type: step === "Configuration" ? "submit" : undefined,
        form: "create-membership",
        key: step,
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          display="flex"
          id="create-membership"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {UISteps[step]}
        </Box>
      </FormProvider>
    </ModalDrawer>
  );
});

export default CreateMembershipsModal;
