import { CaretDown } from "@assets/icons";
import { Text } from "@common/Text";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Grid,
  Stack,
  SxProps,
  styled,
} from "@mui/material";
import { palette } from "@palette";
import { ReactNode } from "react";

type Props = {
  children: ReactNode | JSX.Element;
  icon?: ReactNode;
  title: string | ReactNode;
  sx?: SxProps;
  withExpandIcon?: boolean;
};

const CollapsableComponent = ({
  children,
  icon,
  title,
  sx,
  withExpandIcon = true,
}: Props) => {
  return (
    <Stack position="relative" sx={sx}>
      <CustomAccordion slotProps={{ transition: { unmountOnExit: true } }}>
        <CustomSummary withExpandIcon={withExpandIcon}>
          {typeof title === "string" ? (
            <Stack direction="row" sx={{ gap: "8px" }} alignItems="center">
              {icon}
              <Text
                fontSize="18px"
                color="#575353"
                sx={{
                  lineHeight: "120%",
                }}
              >
                {title}
              </Text>
            </Stack>
          ) : (
            <>{title}</>
          )}
        </CustomSummary>
        <CustomDetails>
          <Grid
            container
            rowSpacing="24px"
            sx={{
              marginTop: 0,
              "&> div:first-of-type": {
                padding: 0,
              },
            }}
          >
            {children}
          </Grid>
        </CustomDetails>
      </CustomAccordion>
    </Stack>
  );
};

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion elevation={0} disableGutters {...props} />
))(() => ({
  boxShadow: `1px 1px 15px 0px rgba(161, 175, 180, 0.10)`,
  border: "1px solid transparent",
  borderRadius: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  position: "initial"
}));

const CustomSummary = styled(
  ({
    withExpandIcon,
    ...props
  }: AccordionSummaryProps & { withExpandIcon?: boolean }) => (
    <AccordionSummary
      expandIcon={withExpandIcon ? <CaretDown /> : null}
      {...props}
    />
  ),
)(({ withExpandIcon }) => ({
  backgroundColor: palette.neutral.white,
  flexDirection: "row",
  "& .MuiAccordionSummary-content": {
    margin: "0 !important",
    paddingBlock: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  ...(!withExpandIcon && {
    "& .MuiAccordionSummary-expandIconWrapper": {
      display: "none",
    },
  }),
}));

const CustomDetails = styled(AccordionDetails)(() => ({
  padding: "16px 16px 8px",
}));

export default CollapsableComponent;
