import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { TMerchantStats } from "../data.types";
import { Fragment, memo } from "react";
import Figure from "@common/Figure";

type Props = {
  data: TMerchantStats;
};

const MerchantStats = ({ data }: Props) => {
  if (!data) return null;

  return (
    <Stack direction="row" gap={3} alignItems="center" flexWrap="wrap">
      {Object.keys(labels).map((key) => {
        const value = data[key as "balance" | "totalRaised" | "transactions"];
        return (
          <Stack key={key} direction="column" gap="4px" alignItems="flex-start">
            <Text
              color={palette.black[100]}
              variant="h6"
              lineHeight="21.6px"
              fontWeight="book"
              letterSpacing="-0.18px"
            >
              <Figure
                isAmount={key !== "transactions"}
                fontSize={20}
                value={value}
              />
            </Text>
            <Text
              color={palette.gray[300]}
              variant="body"
              fontWeight="book"
              lineHeight="16.8px"
            >
              {labels[key as keyof typeof labels]}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

const labels = {
  totalRaised: "Lifetime (USD)",
  balance: "Balance (USD)",
  transactions: "Transactions",
};

export default memo(MerchantStats);
