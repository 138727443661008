import { useAppSelector, useAppDispatch } from "@redux/hooks";
import {
  Product,
  SelectedProduct,
  initialProductState,
  selectProduct,
  setNewSelectedProduct,
} from "@redux/slices/products";

const useProductReducer = () => {
  const dispatch = useAppDispatch();
  const product: Product = useAppSelector(selectProduct);

  const setSelectedProduct = (_selectProduct: SelectedProduct) => {
    dispatch(setNewSelectedProduct(_selectProduct));
  };

  const resetSelectedProduct = () => {
    dispatch(setNewSelectedProduct(initialProductState.selectedProduct));
  };

  return {
    product,
    setSelectedProduct,
    resetSelectedProduct,
  };
};

export default useProductReducer;
