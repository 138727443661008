import { useQuery } from "react-query";
import { customInstance } from "@services/api";
import { useGetCurrentMerchantId } from "@hooks/common";

export const getLastBulkApprovalTask = ({
  accountId,
}: {
  accountId: number;
}) => {
  return customInstance({
    url: `/accounts/${accountId}/background-tasks?filter=%3B(name:"account_bulk_approve_task",name:"account_bulk_decline_task")%3B(status:"running")`,
    method: "GET",
  });
};

export const useGetBackgroundTaskStatus = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  const { merchantId } = useGetCurrentMerchantId();
  return useQuery(
    ["get-last-background-task", merchantId],
    () => {
      return getLastBulkApprovalTask({ accountId: merchantId });
    },
    { enabled, staleTime: Infinity, cacheTime: Infinity },
  );
};
