import { UseQueryOptions, useMutation, useQuery } from "react-query";
import { TPermissionQueryReturnedData } from "./types";
import { AxiosError } from "axios";
import { QKEY_LIST_TEAM_MEMBER_PERMISSIONS } from "@constants/queryKeys";
import { updateUserPermissions } from "@services/api/accessControl/accessControl";
import {
  getAssignedPermissions,
  getGlobalPermissions,
} from "./getPermissionData";

export const ITEMS_PER_PAGE = 15;

export const useUpdatePermissions = (accountId: number, memberId: number) => {
  const request = async (data: any) => {
    return updateUserPermissions(accountId, memberId, data);
  };
  return useMutation(request);
};

interface IUseGetData {
  page: number;
  memberId: number;
  accountId: number;
  searchQuery?: string;
  withPagination?: boolean;
  assignedOnly?: boolean;
  queryKey?: string;
  options?: Omit<UseQueryOptions<any, any, any, any>, "queryKey" | "queryFn">;
}

export const useGetData = ({
  page,
  accountId,
  memberId,
  searchQuery,
  options,
  withPagination = true,
  assignedOnly = false,
  queryKey = QKEY_LIST_TEAM_MEMBER_PERMISSIONS,
}: IUseGetData) => {
  const { data, error, isLoading, isFetching, status } = useQuery<
    TPermissionQueryReturnedData,
    AxiosError
  >(
    [queryKey, accountId, memberId, page, searchQuery],
    async () => {
      let baseURL = `/accounts/${accountId}/members/${memberId}/permissions`;

      if (withPagination || searchQuery) baseURL += "?";

      if (withPagination) {
        baseURL += `&page=${page}&max=${ITEMS_PER_PAGE}`;
      }
      if (searchQuery) {
        baseURL += `&q='${searchQuery}'`;
      }

      return assignedOnly
        ? getAssignedPermissions(baseURL, searchQuery || "")
        : getGlobalPermissions(baseURL, searchQuery || "");
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      ...options,
    },
  );

  return { data, error, isLoading, isFetching, status };
};
