import { Stack, Box } from "@mui/material";
import { DividerText } from "./components";
import { SubTitle } from "./components";
import { Button } from "@common/Button";
import { MemberData } from "@customTypes/team.member";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import NiceModal from "@ebay/nice-modal-react";
import {
  DELETE_CONFIRMATION_MODAL,
  TEAM_MEMBER_PANEL_MODAL,
} from "modals/modal_names";

type DeleteMemeberProps = {
  rowData: MemberData;
  isCurrentUser: boolean;
  isDeleteAllowed: boolean;
};

function DeleteMember({
  rowData,
  isCurrentUser,
  isDeleteAllowed,
}: DeleteMemeberProps) {
  const isDisabled =
    rowData.roleName === "owner" || isCurrentUser || !isDeleteAllowed;

  const handleDelete = () => {
    if (rowData) {
      const url = `/accounts/${rowData?.accID}/members/${rowData?.user.accID}`;
      NiceModal.show(DELETE_CONFIRMATION_MODAL, {
        variant: "team_member",
        itemName:
          rowData.user.firstName && rowData.user.lastName
            ? `${rowData?.user.firstName} ${rowData?.user.lastName}`
            : rowData?.user.email,
        url: url,
        onSuccess: () => NiceModal.hide(TEAM_MEMBER_PANEL_MODAL),
      });
    }
  };

  return (
    <>
      <Stack direction="column" gap={2} alignItems="stretch">
        <Box>
          <DividerText mb={1}>Remove member</DividerText>
          <SubTitle>
            Take into consideration that removing a member is a one-way action
          </SubTitle>
        </Box>
        <Button
          background="secondary"
          size="medium"
          sx={{
            width: "fit-content",
            paddingY: "2px",
            fontWeight: 400,
            fontSize: "14px",
          }}
          disabled={isDisabled}
          tooltipProps={{
            show: !isDeleteAllowed,
            message: DELETE_DENY_MESSAGE,
          }}
          onClick={() => handleDelete()}
        >
          Remove Member
        </Button>
      </Stack>
    </>
  );
}

export default DeleteMember;
