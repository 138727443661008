type IconProps = {
  width?: number;
  height?: number;
  stroke?: string;
}

const MerchantInfoIcon = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1294_144689)">
        <path 
          d="M19.1855 11.0847V12.7964" 
          stroke={stroke}
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
        <path 
          d="M19.1853 15.8627V17.9955C19.1853 19.103 18.2882 20 17.1807 20H14.2974V13.8856H9.83059L9.79398 20H6.8283C5.72075 20 4.82373 19.103 4.82373 17.9955V11.0847"
          stroke={stroke}
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
        <path 
          d="M7.08466 11.4235H6.25171C5.00686 11.4235 4 10.4166 4 9.17174L4.54005 5.49211C4.66819 4.64086 5.40046 4.00928 6.26087 4.00928H9.32722V9.1809C9.32722 10.4257 8.32036 11.4326 7.07551 11.4326L7.08466 11.4235Z" 
          stroke={stroke}
          strokeMiterlimit="10"
        />
        <path 
          d="M9.33643 4H14.6728V9.17162C14.6728 10.4165 13.6659 11.4233 12.4211 11.4233H11.5881C10.3433 11.4233 9.33643 10.4165 9.33643 9.17162V4Z" 
          stroke={stroke}
          strokeMiterlimit="10"
        />
        <path 
          d="M16.9153 11.4233H17.7482C18.9931 11.4233 20 10.4165 20 9.17162L19.4599 5.48284C19.3318 4.63158 18.5995 4 17.7391 4H14.6636V9.17162C14.6636 10.4165 15.6704 11.4233 16.9153 11.4233Z" 
          stroke={stroke}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
      <clipPath id="clip0_1294_144689">
        <rect width={width} height={height} fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default MerchantInfoIcon;
