import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import KotoStepper from "@common/SignUp/KotoStepper";
import { Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import MerchantAgreementFormProvider from "./MerchantAgreementFormProvider";
import AddMerchantAgreement from "./AddMerchantAgreement";
import AddTermsAndConditions from "./AddTermsAndConditions";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useMerchantDataPreview } from "@components/Merchants/MerchantPreview/hooks/useMerchantDataPreview";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";

type Props = {
  backLink: () => void;
};

type StepType = "Merchant Agreement" | "Terms of Service";

const getStepTitle = (step: StepType) => {
  switch (step) {
    case "Merchant Agreement":
      return "Merchant Agreement";
    case "Terms of Service":
      return "";
  }
};

const getStepDescription = (step: StepType) => {
  switch (step) {
    case "Merchant Agreement":
      return "";
    case "Terms of Service":
      return "";
  }
};

const MerchantAgreementSetup = ({ backLink }: Props) => {
  const [step, setStep] = useState<StepType>(
    MerchantAgreementEnum.MERCHANT_AGREEMENT,
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const stepperRef = useRef(null);
  const { merchantId } = useGetCurrentMerchantId();
  const { data: merchantData, isLoading: isLoadingFetch } =
    useMerchantDataPreview({
      id: merchantId,
    });

  const [statusBar, setStatusBar] = React.useState([
    {
      label: MerchantAgreementEnum.MERCHANT_AGREEMENT,
      barValue: 100,
    },
    {
      label: MerchantAgreementEnum.TERMS_OF_SERVICE,
      barValue: 0,
    },
  ]);

  const handleUpdateStatusValue = React.useCallback(
    (label: string) => (value: number) => {
      setStatusBar((prev) => {
        let oldArr = prev;
        const index = oldArr.findIndex((obj) => obj.label === label);

        if (index !== -1) {
          oldArr = [
            ...oldArr.slice(0, index),
            { ...oldArr[index], barValue: value },
            ...oldArr.slice(index + 1),
          ];
        }
        return oldArr;
      });
    },
    [],
  );

  if (isLoadingFetch) return <LoadingSpinner />;

  return (
    <MerchantAgreementFormProvider
      data={{
        merchantAgreement: {
          ...merchantData.merchantAgreement,
          msaPreviousTerminationAt:
            typeof merchantData.merchantAgreement.msaPreviousTerminationAt ===
              "number"
              ? new Date(
                merchantData.merchantAgreement.msaPreviousTerminationAt *
                1000,
              )
              : null,
        },
        termsAndConditionsAgreement: {
          agreementConfirmation: null,
        },
      }}
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        height="20px"
        maxHeight="100%"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <KotoStepper
            sx={{ flexGrow: 1 }}
            setStep={setStep}
            defaultStep={MerchantAgreementEnum.MERCHANT_AGREEMENT}
            customSteps={statusBar}
            ref={stepperRef}
            setIsLoaded={setIsLoaded}
            disableLastClick={statusBar[0].barValue === 0}
            isClickable={statusBar[0].barValue === 0}
          />
        </Stack>

        <Box
          sx={{
            ...containerStyle,
            ...(step === MerchantAgreementEnum.TERMS_OF_SERVICE && {
              margin: "0 auto",
            }),
          }}
        >
          <Text sx={titleStyle}>{getStepTitle(step)}</Text>
          <Text sx={descriptionStyle}>{getStepDescription(step)}</Text>

          {step === MerchantAgreementEnum.MERCHANT_AGREEMENT && (
            <AddMerchantAgreement
              handleNext={(stepperRef.current as any)?.handleNext}
              handleBack={backLink}
              merchantData={merchantData}
              setStatusBar={setStatusBar}
            />
          )}
          {step === MerchantAgreementEnum.TERMS_OF_SERVICE && (
            <AddTermsAndConditions
              handleBack={(stepperRef.current as any)?.handleBack}
              handleUpdateStatusValue={handleUpdateStatusValue(
                MerchantAgreementEnum.TERMS_OF_SERVICE,
              )}
              goToWelcome={backLink}
              merchantData={merchantData}
            />
          )}
        </Box>
      </Box>
    </MerchantAgreementFormProvider>
  );
};

const containerStyle = {
  maxWidth: "564px",
  mt: 4,
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
};

const titleStyle = {
  fontSize: 56,
  fontWeight: "350",
  color: palette.neutral[90],
  lineHeight: "120%",
  mb: 1,
};

const descriptionStyle = {
  fontWeight: "300",
  color: palette.neutral[70],
  mb: 4,
};

export enum MerchantAgreementEnum {
  MERCHANT_AGREEMENT = "Merchant Agreement",
  TERMS_OF_SERVICE = "Terms of Service",
}

export default MerchantAgreementSetup;
