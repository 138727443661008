import { Text } from "@common/Text";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { parseAmount } from "@utils/index";
import { checkPortals } from "@utils/routing";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { memo } from "react";

type TTransactionStatus =
  | "Pending"
  | "Error"
  | "Canceled"
  | "Declined"
  | "Authorized"
  | "Voided"
  | "Settled"
  | "Refund Pending"
  | "Refund Error"
  | "Refund Declined"
  | "Refunded"
  | "Chargeback"
  | "Chargeback Reversal"
  | "Sending"
  | "Sent";

interface OverviewFooterProps {
  balanceAdjustment: number;
  endingBalance: number;
  transactionStatus: TTransactionStatus;
  isTransfer?: boolean;
  transactionType: string;
  processingState: string;
  isCanceledTransfer?: boolean;
}

const OverviewFooter = ({
  balanceAdjustment,
  endingBalance,
  transactionStatus,
  isTransfer = false,
  transactionType,
  processingState,
  isCanceledTransfer,
}: OverviewFooterProps) => {
  const { isAvailableBalanceHidden } = useGetFeatureFlagValues();

  const hideEndingBalance =
    isAvailableBalanceHidden ||
    (processingState !== "settled" &&
      transactionType !== "refund" &&
      !isTransfer) ||
    isCanceledTransfer;

  const { color, operator, text } = getAdjustmentParams(
    balanceAdjustment,
    transactionStatus,
    isTransfer,
  );
  const { isMobileView } = useCustomTheme();

  return (
    <Container>
      <Box>
        <Text
          fontSize={38}
          color={color}
          fontWeight="book"
          lineHeight="16.8px"
          mb={1}
          align={isMobileView ? "center" : "left"}
        >
          {`${operator}${parseAmount(Math.abs(balanceAdjustment))}`}
        </Text>

        <Text
          color={color}
          variant="body"
          lineHeight="16.8px"
          align={isMobileView ? "center" : "left"}
        >
          {text}
        </Text>
      </Box>

      {!hideEndingBalance && (
        <Box>
          <Text
            variant="h6"
            lineHeight="21.6px"
            sx={{ letterSpacing: "-0.18px" }}
            align={isMobileView ? "center" : "right"}
            color={palette.gray[300]}
          >
            {parseAmount(endingBalance)}
          </Text>

          <Text
            color={palette.gray[300]}
            variant="body"
            lineHeight="16.8px"
            align={isMobileView ? "center" : "right"}
          >
            Ending balance (USD)
          </Text>
        </Box>
      )}
    </Container>
  );
};

const getAdjustmentParams = (
  balanceAdjustment: number,
  status?: TTransactionStatus,
  isTransfer?: boolean,
) => {
  const { isAcquirerPortal, isTransfersPage } = checkPortals();

  const isSettled = status === "Settled";
  const isChargeback = status === "Chargeback";
  const isChargebackReversal = status === "Chargeback Reversal";
  const isRefund = status === "Refunded";
  const isVoided = status === "Voided";
  const isPending = status === "Pending";

  let color = palette.gray[300];
  if (
    isSettled ||
    ((isChargebackReversal || isChargeback) && balanceAdjustment > 0)
  ) {
    color = palette.tag.success.hover;
  } else if (
    isRefund ||
    ((isChargebackReversal || isChargeback) && balanceAdjustment < 0) ||
    isTransfer
  ) {
    color = palette.black[100];
  }

  let operator = "";
  if (!(isTransfersPage && isTransfer) && balanceAdjustment > 0) {
    operator = "+";
  } else if (balanceAdjustment < 0) {
    operator = "-";
  }

  let text = "Added to balance (USD)";
  if (isVoided) {
    text = "Voided (USD)";
  } else if (isTransfersPage && isTransfer) {
    text = "Transfer Amount (USD)";
  } else if (
    isTransfer &&
    (status === "Error" ||
      status === "Canceled" ||
      (isPending && isAcquirerPortal))
  ) {
    text = "No changes to balance (USD)";
  } else if (balanceAdjustment < 0) {
    text = "Subtracted from balance (USD)";
  } else if (balanceAdjustment > 0 && !isSettled) {
    text = "To be added to balance (USD)";
  }

  return {
    color,
    operator,
    text,
  };
};

const Container = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  paddingTop: "16px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "32px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default memo(OverviewFooter);
