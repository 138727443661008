// This helper function checks if an object (whose at least one of its properties is required by BE) has one of the properties set.
// If one of the property is set but the others are not, then it sets the non-set properties to null.

import { country_dial_codes } from "../utils/country_dial_codes";

// Until BE makes modifications
export const notSetButRequired = (value: unknown): unknown => {
  if (typeof value === "string") {
    return value.trim() === "" ? null : value;
  }
  if (typeof value === "object" && value !== null) {
    const nonNullKeys = Object.keys(value).filter((key) => (value as any)[key] !== undefined);
    if (nonNullKeys.length > 0) {
      const emptyProps = Object.keys(value).reduce<Record<string, any>>(
        (acc, key) => {
          const val = (value as any)[key];
          if (typeof val === "string" && val.trim() === "") {
            return { ...acc, [key]: null };
          }
          if (val === undefined) {
            return { ...acc, [key]: null };
          }
          // Check nested objects
          if (typeof val === "object" && val !== null) {
            const nestedEmpty = notSetButRequired(val);
            if (nestedEmpty === null) {
              return acc;
            }
            return { ...acc, [key]: nestedEmpty };
          }
          return acc;
        },
        {},
      );
      const merged = { ...value, ...emptyProps };
      if (Object.values(merged).every((val) => val === null)) {
        return null;
      }
      return merged;
    }
  }
  return null;
};

// For Yup validation (if any of the address fields is filled, makes all the others required)
export const isAnyTruthy = (...values: string[]) => values.some(Boolean);

// US -> United States
export const toCountryName = (code?: string): string => {
  if (!code) return "";
  return (
    country_dial_codes.find((country) => code === country.code)?.name || ""
  );
};
