import React from "react";
import { Stack, StackProps, SxProps, Theme, styled } from "@mui/material";
import { Text } from "@common/Text";
import Figure from "@common/Figure";
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

export interface StatsTitleProps extends StackProps {
  label?: string | React.ReactNode;
  value: string;
  isAmount?: boolean;
  currencyVisible?: boolean;
  fontSize?: number;
  align?: "right" | "left" | "center" | "inherit" | "justify";
  isMainTitle?: boolean;
  labelStyle?: SxProps<Theme>;
  figureStyle?: SxProps<Theme>;
  ratio?: number | string | undefined;
  ratioStyle?: any;
}

export const NewStatsTitle = ({
  label,
  value,
  isAmount = true,
  currencyVisible = true,
  fontSize = 48,
  align = "right",
  isMainTitle,
  labelStyle,
  color,
  figureStyle,
  ratio,
  ratioStyle,
  ...rest
}: StatsTitleProps) => {
  const { activeGradientColor, isDarkMode } = useThemeContext();
  const col =
    color ||
    (isDarkMode ? activeGradientColor?.dark : activeGradientColor?.light);

  return (
    <Stack direction="column" gap={1} {...rest}>
      <Figure
        sup={isMainTitle}
        value={value}
        fontSize={fontSize}
        color={col as string}
        variant={"h3"}
        fontWeight={"light"}
        lineHeight="normal"
        isAmount={isAmount}
        sx={figureStyle}
        ratio={ratio}
        ratioStyle={ratioStyle}
      />
      <TitleText variant="h6" align={align} width="100%" sx={labelStyle}>
        {label}
        {currencyVisible && isAmount && <span> (USD)</span>}
      </TitleText>
    </Stack>
  );
};

const TitleText = styled(Text)(() => ({
  fontWeight: 350,
  lineHeight: "120%",
}));

export default NewStatsTitle;
