type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const CellSignalMediumIcon = ({
  width = 24,
  height = 25,
  stroke = "#FF8124",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18.574 2.8653C18.2999 2.7516 17.9983 2.72173 17.7073 2.77947C17.4163 2.83721 17.1489 2.97996 16.939 3.18967L1.93899 18.1897C1.7293 18.3995 1.58653 18.6668 1.52874 18.9578C1.47095 19.2487 1.50073 19.5503 1.61432 19.8244C1.72791 20.0984 1.92021 20.3326 2.1669 20.4974C2.41359 20.6621 2.70359 20.75 3.00024 20.75H18.0002C18.3981 20.75 18.7796 20.5919 19.0609 20.3106C19.3422 20.0293 19.5002 19.6478 19.5002 19.25V4.24998C19.5007 3.95331 19.4129 3.66319 19.248 3.4166C19.083 3.17 18.8484 2.97809 18.574 2.8653ZM18.0002 19.25H12.0002V10.25L18.0002 4.24998V19.25Z"
        fill={stroke}
      />
    </svg>
  );
};

export default CellSignalMediumIcon;
