import { useEffect, useRef, useState } from "react";
import { SelectOptionProps } from "./types";
import debounce from "lodash.debounce";

type Args = {
  initialOptions?: SelectOptionProps[];
  customSearch?: (newValue: string) => void;
};

const useSearchbar = ({ initialOptions = [], customSearch }: Args) => {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState<SelectOptionProps[]>([]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      if (customSearch) {
        customSearch(value);
      } else {
        setOptions(filterArray(initialOptions, value));
      }
    }, 200),
  );

  const resetOptions = () => setOptions(initialOptions);

  const onSearch = (newValue: string) => {
    setQuery(newValue);
    if (newValue) {
      debouncedSearch.current(newValue);
    } else {
      resetOptions();
    }
  };

  useEffect(() => {
    return () => {
      debouncedSearch.current.cancel();
    };
  }, []);

  return {
    query,
    options,
    onSearch,
    resetOptions,
  };
};

export default useSearchbar;

const filterArray = (array: SelectOptionProps[], query: string) => {
  const lowerCaseQuery = query.toLowerCase();
  return array?.filter((x) => {
    if (x.label?.toString().toLowerCase().includes(lowerCaseQuery)) {
      return true;
    } else if (typeof x?.hidden === "boolean") {
      return x.hidden;
    } else {
      return false;
    }
  });
};
