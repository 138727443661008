import { useInfiniteQuery } from "react-query";
import { queryFunctionBuilder } from "../api";

export const useGetInfiniteTransactions = (queryParams: any, config: any) => {
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    remove,
  } = useInfiniteQuery(config.queryKey, queryFunctionBuilder(queryParams), {
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextCursor;
    },
    ...(typeof config.enabled === "boolean" && {
      enabled: config.enabled,
    }),
    refetchOnMount: false,
  });

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    remove,
  };
};
export type useGetInfiniteTransactionsReturnType = ReturnType<
  typeof useGetInfiniteTransactions
>;
