import { Text } from "@common/Text";
import { Stack, StackProps } from "@mui/material";
import { CloseIcon } from "@assets/rebrandIcons";
import { palette } from "@palette";
import { Tooltip } from "@common/Tooltip";

type Props = {
  title: string;
  onClose: () => void;
};

const TransferModalHeader = ({
  onClose,
  title,
  ...props
}: Props & StackProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      {...props}
    >
      <Text
        fontSize={24}
        color={palette.neutral[70]}
        fontWeight="book"
        data-testid="transfer-modal-header-title"
      >
        {title}
      </Text>
      <Tooltip title="Close">
        <button onClick={onClose} style={closeButtonStyle}>
          <CloseIcon height={32} width={32} />
        </button>
      </Tooltip>
    </Stack>
  );
};

const closeButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
};

export default TransferModalHeader;
