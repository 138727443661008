import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { showMessage } from "@common/Toast";

type TMutation = {
  url?: string;
  onSuccess?: (res: any) => void;
  onError?: (error: any) => void;
  onSettled?: () => void;
  refetchQuerieKeys?: string[];
  invalidateQuerieKeys?: string[];
};
export const useDeleteMutation = ({
  url,
  onSuccess,
  onError,
  onSettled,
  refetchQuerieKeys,
  invalidateQuerieKeys,
}: TMutation) => {
  const queryClient = useQueryClient();
  const refetchQueries = () => {
    refetchQuerieKeys?.map((key) => queryClient.refetchQueries(key));
  };
  const invalidateQueries = () => {
    invalidateQuerieKeys?.map((key) => queryClient.invalidateQueries(key));
  };
  const deleteMutation = useMutation(
    async () => {
      return customInstance({
        url: url,
        method: "DELETE",
      });
    },
    {
      onSuccess: (res) => {
        refetchQuerieKeys?.length && refetchQueries();
        invalidateQuerieKeys?.length && invalidateQueries();
        onSuccess && onSuccess(res);
      },
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        showMessage("Error", axiosError.message, true);
        onError && onError(axiosError);
      },
      onSettled: () => {
        onSettled && onSettled();
      },
    },
  );

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  return { handleDelete, mutation: deleteMutation };
};
