interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const MoneyIcon = ({
  width = 24,
  height = 24,
  fill = "none",
  stroke = "#4C4A52",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="6"
        width="18"
        height="12"
        rx="2"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M6 9H8"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16 15H18"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="12" cy="12" r="2.25" stroke={stroke} strokeWidth="1.5" />
    </svg>
  );
};

export default MoneyIcon;
