import Popup from "@common/Popup";
import { Text } from "@common/Text";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { TOFACStatusImproved } from "./OFACDetails";
import { Dispatch, SetStateAction } from "react";
import { palette } from "@palette";
import { OFACTabType } from "../helpers";

const OFACConfirmMatchModal = NiceModal.create(
  ({
    merchantId,
    action,
    activeTab,
    isEnterprisePanel,
  }: {
    merchantId: number;
    action: Dispatch<SetStateAction<TOFACStatusImproved>>;
    activeTab: OFACTabType;
    isEnterprisePanel: boolean;
  }) => {
    const modal = useModal();
    const handleModalClose = () => {
      modal.hide();
    };

    const confirmMatchHandler = () => {
      action("Confirmed match");
      handleModalClose();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={(<Text fontSize="18px">Confirm match</Text>) as any}
        isClose
        onCancel={handleModalClose}
        isLoading={false}
        titleProps={{
          fontSize: 18,
          fontWeight: "book",
          color: palette.neutral[80],
        }}
        sx={{
          "& .MuiDialog-paper": {
            backdropFilter: "none",
            backgroundColor: palette.background.dimmedWhite,
          },
        }}
        textProps={{
          marginTop: "16px",
          color: palette.neutral[70],
          fontSize: 14,
          lineHeight: "16.8px",
        }}
        cancelButtonProps={{
          size: "medium",
          style: {
            lineHeight: "21.6px",
            fontSize: "18px",
            paddingRight: "8px",
            fontWeight: "normal",
          },
        }}
        submitButtonProps={{
          size: "medium",
          background: "red",
          style: {
            lineHeight: "21.6px",
            fontSize: "18px",
            width: "fit-content",
            fontWeight: "normal",
          },
        }}
        isSubmitDisabled={false}
        onSubmit={confirmMatchHandler}
        actionLabel={"Confirm"}
      >
        Confirming a match will suspend this{" "}
        {isEnterprisePanel ? "provider" : "merchant"}’s activity as well as
        prevent any changes to the {activeTab}.
      </Popup>
    );
  },
);

export default OFACConfirmMatchModal;
