import React from "react";
import { Text } from "@common/Text";
import { Box, BoxProps, styled } from "@mui/material";
import { palette } from "@palette";

export type SectionBarListItem<T> = {
  title: T;
  Icon?: JSX.Element;
  disabled?: boolean;
}

type SectionBarItemProps<T> = SectionBarListItem<T> & BoxProps & {
  active: boolean;
}

const SectionBarItem = <T extends string> ({
  title,
  active,
  Icon,
  disabled = false,
  ...props
}: SectionBarItemProps<T>) => {

  const getTextColor = () => {
    if (disabled) return "#D1D1D0";
    return active ? "#403D3D" : "#8F8F8F";
  }

  const textColor = getTextColor();

  return (
    <StyledTabItem 
      disabled={disabled}
      active={active}
      {...props}
    >
      {Icon && React.cloneElement(Icon, {
        color: textColor,
        path_fill: textColor,
        stroke: textColor,
        width: 28,
        height: 28,
      })}
      <Text 
        sx={{
          ...titleStyle, 
          color: textColor
        }}
      >
        {title}
      </Text>
    </StyledTabItem>
  );
};

const titleStyle = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  whiteSpace: "nowrap",
  fontFamily: "Give Whyte",

  "@media (max-width: 750px)": {
    display: "none"
  }
};

const StyledTabItem = styled(Box, {
  shouldForwardProp: (prop) => 
    prop !== "disabled" &&
    prop !== "active"
})(({ disabled, active }: { disabled: boolean, active: boolean }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: "4px 8px",
  gap: "8px",
  borderRadius: "8px",
  cursor: "default",
  backgroundColor: "inherit",

  ...(!disabled && !active && {
    "&:hover": {
      background: "#F8F8F6",
      cursor: "pointer",
    },
  }),
  ...(active && {
    backgroundColor: "#E1E1DE",
  }),

}));

export default SectionBarItem;
