import { useEffect } from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { CVCIcon } from "@assets/icons";

interface CVVInputProps {
  CVVLimit?: number;
}

const CvvInput = ({ CVVLimit }: CVVInputProps) => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");

    return currentValue;
  };

  useEffect(() => {
    setValue("cvv", normalizeInput(watch("cvv")));
  }, [watch("cvv")]);

  return (
    <RHFInput
      name="cvv"
      label="CVC"
      fullWidth
      placeholder={"•".repeat(CVVLimit || 3)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ alignItems: "center" }}>
            <CVCIcon width={34} height={24} />
          </InputAdornment>
        ),
      }}
      sx={{
        letterSpacing: "6px",
        "::placeholder": {
          marginLeft: "35px",
        },
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
      }}
      inputProps={{
        maxLength: CVVLimit || 3,
      }}
    />
  );
};

export default CvvInput;
