import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UnreadConversationSignProp {
  isTriggerRefetch: boolean;
}

const initialState: UnreadConversationSignProp = {
  isTriggerRefetch: false,
};

const UnreadConversationSignSlice = createSlice({
  name: "unreadConversationSign",
  initialState,
  reducers: {
    setUnreadTrigger: (
      state: UnreadConversationSignProp,
      action: PayloadAction<boolean>,
    ) => {
      state.isTriggerRefetch = action.payload;
    },
  },
});

export const { setUnreadTrigger } = UnreadConversationSignSlice.actions;
export const selectTriggerState = (state: RootState) =>
  state.unreadConversationSign;

export default UnreadConversationSignSlice.reducer;
