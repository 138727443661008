import { Button } from "@common/Button";
import { Box, Grid } from "@mui/material";
import { Modal } from "@common/Modal";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import WinnerListItem from "./WinnerListItem";

type ViewWinnerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
};

const ViewWinner = ({ open, setOpen, title }: ViewWinnerProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Modal
      open={open}
      title={`Winner${winners.length > 1 ? "s" : ""} Draw Result`}
      onClose={() => setOpen(false)}
      actions={<Button onClick={() => setOpen(false)}>Close</Button>}
    >
      <Text
        sx={{
          color: palette.neutral[800],
          fontSize: isDesktop ? "24px" : "20px",
          fontWeight: 600,
          mb: "8px",
        }}
      >
        Winner{winners.length > 1 && "s"} of {title}
      </Text>
      <Text
        sx={{
          color: palette.neutral[600],
          fontSize: "14px",
          fontWeight: 400,
          mb: "24px",
        }}
      >
        An email was sent to all the participants.
      </Text>

      {winners.map(winner => (
        <WinnerListItem {...winner} key={winner.id} />
      ))}
    </Modal>
  );
};

const winners = [
  {
    name: "Ump Ump1331",
    date: "Sep 2nd, 2020",
    email: "ump_ump1331@buddyteal.com",
    product: "iPhone 14 Pro 512GB",
    id: "28fa325e-ead7-4f95-9aaa-7779ae8f1780",
  },
  {
    name: "Ump Ump1331",
    date: "Sep 2nd, 2020",
    email: "ump_ump1331@buddyteal.com",
    product: "Apple Watch 7",
    id: "28fa325e-ead7-4f95-9aaa-7779ae8f1782",
  },
  {
    name: "Ump Ump1331",
    date: "Sep 2nd, 2020",
    email: "ump_ump1331@buddyteal.com",
    product: "Apple Air Tag",
    id: "28fa325e-ead7-4f95-9aaa-7779ae8f1783",
  },
];

export default ViewWinner;
