import { palette } from "@palette";

interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
}

const TimeProgressIcon = ({
  width = 25,
  height = 24,
  stroke = palette.neutral[70],
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.13604 5.63604C4.6637 7.10837 3.74743 9.04567 3.54334 11.1178C3.33925 13.19 3.85997 15.2688 5.01677 17.0001C6.17358 18.7314 7.8949 20.008 9.88744 20.6125C11.88 21.2169 14.0204 21.1117 15.9441 20.3149C17.8678 19.5181 19.4557 18.0789 20.4373 16.2426C21.4188 14.4062 21.7333 12.2864 21.3271 10.2442C20.9209 8.202 19.8191 6.36384 18.2095 5.04291C16.6 3.72197 14.5822 3 12.5 3"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.5 12L6.5 6"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5 3V5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.5 12L19.5 12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5 19V21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.5 12L3.5 12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TimeProgressIcon;
