import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import TriggerCard from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/TriggerCard";
import { useRiskTriggersV2 } from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useRiskTriggersV2";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { startCase } from "lodash";
import { TRiskTFactor } from "../types";
import RiskTriggersSkeleton from "./skeletons/RiskTriggersSkeleton";

type Props = {
  profileId: number;
  merchantId: number;
};

const RiskProfileTriggers = ({ profileId, merchantId }: Props) => {
  const { data, isLoading } = useRiskTriggersV2(profileId, merchantId);

  if (isLoading) return <RiskTriggersSkeleton />;

  return (
    <Container>
      <FadeUpWrapper delay={100}>
        <Text fontWeight="book" color={palette.neutral[80]} my={2}>
          Manage triggers independently from the global preferences.
        </Text>
      </FadeUpWrapper>
      <Stack spacing={3} mb={2} overflow="hidden">
        {Object.entries(data).map(([key, value], index) => (
          <CategorySection
            key={key}
            category={key}
            factors={value}
            profileId={profileId}
            merchantId={merchantId}
            delay={
              index > 1
                ? (Object.entries(data)[index - 1][1] as TRiskTFactor[]).length
                : 200
            }
          />
        ))}
      </Stack>
    </Container>
  );
};

type CategorySectionProps = {
  category: string;
  factors: TRiskTFactor[];
  delay: number;
  profileId: number;
  merchantId: number;
};

const CategorySection = ({
  category,
  factors,
  delay,
  profileId,
  merchantId,
}: CategorySectionProps) => {
  return (
    <Stack spacing={2} p="8px 0">
      <FadeUpWrapper delay={delay}>
        <Text fontWeight="book" color={palette.neutral[90]}>
          {startCase(category)}
        </Text>
      </FadeUpWrapper>
      <Stack gap="12px">
        {factors.map((factor, index) => (
          <FadeUpWrapper key={factor.factorID} delay={delay + (index + 1) * 50}>
            <TriggerCard
              profileId={profileId}
              merchantId={merchantId}
              {...factor}
            />
          </FadeUpWrapper>
        ))}
      </Stack>
    </Stack>
  );
};

const Container = styled(Box)({
  position: "relative",
  overflowY: "auto",
  padding: "0 16px 72px",
  height: "100%",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
});

// const CreateButton = styled(Button)(() => ({
//   margin: "0 auto",
//   width: 200,
//   position: "sticky",
//   bottom: 30,
//   zIndex: 99,
// }));

export default RiskProfileTriggers;
