import { useState } from "react";
import { OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";

const usePages = () => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pagesRendered, setPagesRendered] = useState<number>(0);

  const onDocumentLoadSuccess: OnDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    setNumPages(numPages);
    setPagesRendered(0);
  };

  const pagesRenderedPlusOne = Math.min(pagesRendered + 1, numPages);

  const resetPages = () => {
    setNumPages(0);
    setPageNumber(1);
    setPagesRendered(0);
  };

  return {
    numPages,
    pageNumber,
    setPageNumber,
    onDocumentLoadSuccess,
    setPagesRendered,
    pagesRendered,
    pagesRenderedPlusOne,
    resetPages,
  };
};

export default usePages;
