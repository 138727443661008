import { customInstance } from "..";

export type TBlockTransaction = {
  merchantId: number, 
  id: string, 
  reason?:string, 
  canNotifyMerchant?:boolean, 
  skipRefund?:boolean
}
export const blockTransaction = ({merchantId, id, reason, canNotifyMerchant, skipRefund}:TBlockTransaction) =>
  customInstance({
    url: `/merchants/${merchantId}/transactions/${id}/block`,
    method: "POST",
    data: {
      blockReason: reason? reason : "",
      ...(canNotifyMerchant !== undefined && {canNotifyMerchant: canNotifyMerchant}),
      ...(skipRefund !== undefined && {skipRefund:skipRefund})
    },
  });

export const voidTransaction = (id: string) =>
  customInstance({
    url: `/refunds`,
    method: "POST",
    data: {
      reason: "",
      transactionIDs: [id],
      transactionItemIDs: null,
    },
  });

export const unblockTransaction = (merchantId: number, id: string) =>
  customInstance({
    url: `/merchants/${merchantId}/transactions/${id}/block`,
    method: "PATCH",
    data: {
      isFalsePositive: true,
      falsePositiveReason: "",
    },
  });
