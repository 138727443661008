import { customInstance } from "@services/api";
import { useState } from "react";

const getCardNumber = ({ cardNumber }: { cardNumber: string }) => {
  return customInstance({
    method: "POST",
    url: "/cart/payment-method",
    data: {
      number: cardNumber.replace(/\s/g, ""),
    },
  });
};

export const useGetCardInfos = () => {
  const [cardObject, setCardObject] = useState<Record<any, any>>();
  const [isError, setIsError] = useState<boolean>(false);

  const handleCardNumberCheck = async (cardNumber: string, setError: any) => {
    setIsError(false);
    try {
      const cardObj = await getCardNumber({ cardNumber });

      setCardObject(cardObj);
      if (!cardObj?.binInfo.isValid) {
        setError("cardNumber", {
          message: "Please enter a valid card number",
        });
      }
    } catch {
      setError("cardNumber", { message: "Please enter a valid card number" });
    }
  };
  return { handleCardNumberCheck, cardObject, isError, setIsError };
};
