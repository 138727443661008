import TitleComponent from "../TitleComponent";
import {
  AddAmountButton,
  AmountItem,
  PaymentStepSection,
} from "features/Minibuilders/recurrence.atoms";
import { palette } from "@palette";
import { PlusIcon } from "@assets/icons/RebrandedIcons";
import { DndContext, closestCorners } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { Stack } from "@mui/material";
import usePaymentSetup from "../../hook/usePaymentSetup";
import { memo } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { CREATE_DENY_MESSAGE } from "@constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

function SweepStackPaymentSetup({ title }: { title: string }) {
  const {
    sensors,
    handleDragEnd,
    handleShowAmountItem,
    list,
    createAmount,
    editAmount,
    lastActive,
    isCreateAllowed,
  } = usePaymentSetup();

  return (
    <>
      <FadeUpWrapper delay={100}>
        <TitleComponent title={title} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <PaymentStepSection
          title="Payment amounts"
          description="Provide preset amounts for customers to pick."
        >
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToParentElement]}
            >
              <SortableContext
                items={list}
                strategy={verticalListSortingStrategy}
              >
                <Stack gap={1}>
                  {list?.map((item: any) => {
                    const { id, active } = item;
                    const isDisabled = list.length === 1 || lastActive === id;

                    return (
                      <AmountItem
                        key={id}
                        amountItem={item}
                        showAmountItem={handleShowAmountItem}
                        isDisabled={isDisabled}
                        checked={list.length === 1 && !active ? true : active}
                        isDraggable={list.length > 1}
                        onClick={() => editAmount(id, isDisabled)}
                        isSweepstake
                      />
                    );
                  })}
                  <CustomToolTip
                    showToolTip={!isCreateAllowed}
                    message={CREATE_DENY_MESSAGE}
                  >
                    <AddAmountButton
                      onClick={createAmount}
                      disabled={!isCreateAllowed}
                      data-testid="sweepstake-add-ticket-button"
                    >
                      Add ticket
                      <PlusIcon
                        stroke={palette.gray[300]}
                        height={20}
                        width={20}
                      />
                    </AddAmountButton>
                  </CustomToolTip>
                </Stack>
              </SortableContext>
            </DndContext>
          </>
        </PaymentStepSection>
      </FadeUpWrapper>
    </>
  );
}

export default memo(SweepStackPaymentSetup);
