import { customInstance } from "@services/api";
import { checkPortals } from "@utils/routing";
import { useQuery } from "react-query";

export const useCheckEnterprises = () => {
  const { isAcquirerPortal } = checkPortals();

  const getEnterprises = () => {
    return customInstance({
      url: `/enterprises?&max=1`,
      method: "GET",
    });
  };

  const { data, isLoading, ...rest } = useQuery(
    "check-list-enterprises",
    async () => {
      const providers = await getEnterprises();
      return providers;
    },
    { refetchOnWindowFocus: false, enabled: isAcquirerPortal },
  );

  return {
    total: data?.total || 0,
    isLoading,
    ...rest,
  };
};
