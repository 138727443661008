// import * as React from "react";
import { palette } from "@palette";
// modal
import NiceModal, { muiDialogV5 } from "@ebay/nice-modal-react";
// form
// components
import { Modal } from "@common/Modal";
// hooks
import { useEditCustomer } from "@hooks/merchant-api/customers";
import CampaignModalActions from "@common/Modal/CampaignModalActions";
import CustomerFormProvider from "@hooks/merchant-api/customers/CustomerFormProvider";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import EditCustomer from "./EditCustomer";
import { Box } from "@mui/material";

const EditCustomerWrapper = NiceModal.create(({ id }: { id: number }) => {
  const editCustomerInfo = useEditCustomer(id);

  const {
    methods,
    onSubmit,
    isDisabled,
    isLoading,
    handleChangeStatus,
    modal,
    isCustomerLoading,
    data,
    isDesktop,
    handleClose,
  } = editCustomerInfo;

  const { reset, formState } = methods;

  return (
    <Modal
      title="Edit Customer"
      width="720px"
      {...muiDialogV5(modal)}
      onClose={handleClose}
      actions={
        <CampaignModalActions
          id={`${id}`}
          form="newCustomer"
          isLoading={isLoading}
          isSubmitDisabled={!formState.isDirty}
          isPrimaryActionDisabled={!formState.isDirty || isDisabled}
          handleDiscard={() => reset()}
        />
      }
      data-testid="edit-customer-modal"
      sx={{
        "& .MuiDialogTitle-root": {
          background: palette.liftedWhite.main,
          padding: "12px 16px 8px",
          "& > button": {
            background: palette.liftedWhite.main,
          },
        },
      }}
    >
      {isCustomerLoading ? (
        <Box minWidth="100%">
          <LoadingSpinner />
        </Box>
      ) : (
        <CustomerFormProvider data={data}>
          <EditCustomer
            methods={methods}
            isDesktop={isDesktop}
            handleChangeStatus={handleChangeStatus}
            onSubmit={onSubmit}
          />
        </CustomerFormProvider>
      )}
    </Modal>
  );
});

export default EditCustomerWrapper;
