import { memo } from "react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { parseAmount } from "@utils/index";

export type TData = { label: string; value: number; isAmount?: boolean }[];

interface StackedSingleBarProps {
  data: TData;
  title: string;
}

const StackedSingleBar = ({ data, title }: StackedSingleBarProps) => {
  const slicedData = data.slice(0, 4);
  const total = slicedData.reduce((acc, v) => {
    return acc + v.value;
  }, 0);
  const colors = Object.values(palette.charts);

  return (
    <Stack flexGrow={1} direction="column" gap="24px" alignItems="stretch">
      <Text
        color={palette.black[100]}
        fontSize="18px"
        fontWeight="book"
        lineHeight="21.6px"
        letterSpacing="-0.18px"
      >
        {title}
      </Text>
      <StackedBar data={slicedData} total={total} colors={colors} />
      {slicedData.map(({ label, value, isAmount }, index) => {
        return (
          <Label
            key={label}
            label={label}
            value={value}
            color={colors[index]}
            isAmount={isAmount || false}
          />
        );
      })}
    </Stack>
  );
};

const StackedBar = ({
  data,
  total,
  colors,
}: {
  data: TData;
  total: number;
  colors: string[];
}) => {
  return (
    <StyledBar>
      {data.map((dataEntry, idx) => {
        return (
          <Box
            key={dataEntry.label}
            sx={{
              backgroundColor: colors[idx],
              width: `${Math.round((dataEntry.value * 100) / total)}%`,
            }}
          />
        );
      })}
    </StyledBar>
  );
};

const Label = ({
  label,
  value,
  color,
  isAmount,
}: {
  label: string;
  value: number;
  color: string;
  isAmount: boolean;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap="8px" alignItems="center">
        <Box
          width="20px"
          height="20px"
          sx={{ borderRadius: "4px", backgroundColor: color }}
        />
        <Text color={palette.black[100]} fontWeight="book" lineHeight="16.8px">
          {label}
        </Text>
      </Stack>
      <Text color={palette.black[300]} lineHeight="16.8px">
        {isAmount ? parseAmount(value) : value}
      </Text>
    </Stack>
  );
};

const StyledBar = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "stretch",
  height: "27px",
  borderRadius: "8px",
  flexGrow: 1,
  overflow: "hidden",
}));

export default memo(StackedSingleBar);
