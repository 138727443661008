import { Link, LinkProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { palette } from "@palette";

export interface BaseLinkProps extends LinkProps {
  link: string;
  replace?: boolean;
}

export const BaseLink = ({
  children,
  link,
  sx,
  replace,
  ...rest
}: BaseLinkProps) => {
  return (
    <Link
      sx={{
        borderBottom: `1px solid ${palette.neutral[10]}`,
        padding: "8px",
        paddingTop: 0,
        ...sx,
      }}
      component={RouterLink}
      underline="none"
      color="inherit"
      to={link}
      replace={replace}
      {...rest}
    >
      {children}
    </Link>
  );
};
