import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { palette } from "@palette";
import { Controller, useFormContext } from "react-hook-form";

const dialogSx = {
  "& .MuiDialog-container": {
    alignItems: "center",
  },
  "& .MuiDialogContent-root": {
    padding: 0,
    borderBottom: "none",

    ".MuiPickersToolbar-root.MuiDatePickerToolbar-root > .MuiGrid-root.MuiGrid-container":
    {
      alignItems: "flex-start",
    },
  },
  "& .MuiDialog-paper": {
    background: "#FFFFFF",
    top: "initial",
    height: "auto",
    borderRadius: "4.5px",
    maxHeight: "calc(100% - 64px)",
  },
  "& .MuiDialogActions-root": {
    backgroundColor: "#FFFFFF",
    flexDirection: "row",
    justifyContent: "flex-end",

    "& > :not(:first-of-type)": {
      marginLeft: 0,
    },
    "& .MuiButton-root": {
      width: "auto",
    },
    "& .MuiButton-root.MuiButton-primary": {
      border: "none",
      color: "#1976d2",
      boxShadow: "none",
      textTransform: "uppercase",
      backgroundColor: "transparent",

      "&:hover": {
        boxShadow: "none",
        background: "none",
        backgroundColor: "none",
      },
    },
  },
  "& .PrivatePickersYear-yearButton.Mui-selected": {
    backgroundColor: `${palette.black.main} !important`,
    "&:hover": {
      backgroundColor: palette.black.main,
      opacity: 0.8,
    },
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: `${palette.black.main} !important`,
    "&:hover": {
      backgroundColor: palette.black.main,
      opacity: 0.8,
    },
  },
};

type Props = {
  name: string;
  label: string;
  maxDate: any;
  minDate?: any;
  disabled?: boolean;
  onBlur?: () => void;
};

const RHFDateInput = ({
  name,
  label,
  maxDate,
  minDate,
  disabled,
  onBlur,
}: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        return (
          <MobileDatePicker
            {...rest}
            maxDate={maxDate}
            minDate={minDate}
            inputRef={ref}
            inputFormat="MM/dd/yyyy"
            DialogProps={{
              sx: dialogSx,
            }}
            disabled={disabled}
            disableHighlightToday
            renderInput={({ inputProps, ...params }) => (
              <TextField
                inputRef={ref}
                {...params}
                error={!!error}
                label={label}
                helperText={error?.message}
                fullWidth
                disabled={disabled}
                onBlur={onBlur}
                inputProps={{
                  ...inputProps,
                  placeholder: label,
                }}
              />
            )}
          />
        );
      }}
    />
  );
};

export default RHFDateInput;
