interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const TimeProgressIcon = ({
  width = 33,
  height = 32,
  fill = "#F4F3F7",
  stroke = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="32" height="32" rx="4" fill={fill} />
      <path
        opacity="0.5"
        d="M16.75 4.3C16.75 4.15788 16.75 4.08681 16.7953 4.04266C16.8405 3.99851 16.9104 4.00026 17.05 4.00375C19.5842 4.06709 22.0363 4.93169 24.052 6.47738C26.1472 8.08399 27.6541 10.3367 28.3391 12.8866C29.0241 15.4365 28.849 18.1411 27.841 20.5813C26.833 23.0216 25.0484 25.0613 22.7636 26.3845C20.4787 27.7076 17.8213 28.2402 15.2031 27.8999C12.5849 27.5595 10.152 26.3652 8.28135 24.5019C6.41074 22.6386 5.20687 20.2104 4.85627 17.5935C4.51896 15.0758 4.98956 12.5188 6.196 10.2892C6.26248 10.1664 6.29572 10.1049 6.35654 10.0876C6.41736 10.0704 6.479 10.1057 6.60227 10.1765L14.1719 14.5205C14.2912 14.5889 14.3508 14.6232 14.3688 14.6775C14.3869 14.7318 14.3571 14.8005 14.2977 14.9378C14.1054 15.3818 14.0364 15.8716 14.1012 16.3549C14.1793 16.9377 14.4474 17.4785 14.864 17.8934C15.2806 18.3084 15.8224 18.5744 16.4055 18.6502C16.9886 18.726 17.5804 18.6074 18.0893 18.3127C18.5981 18.018 18.9956 17.5638 19.2201 17.0203C19.4445 16.4768 19.4835 15.8745 19.331 15.3066C19.1784 14.7388 18.8428 14.237 18.3762 13.8792C17.9893 13.5825 17.5302 13.3985 17.0494 13.3443C16.9007 13.3276 16.8263 13.3192 16.7882 13.2765C16.75 13.2338 16.75 13.165 16.75 13.0275V4.3Z"
        fill={stroke}
      />
      <path
        d="M12.2334 23.8231C12.1622 23.9464 12.1266 24.008 12.1441 24.0691C12.1615 24.1302 12.2229 24.1631 12.3456 24.2287C13.5175 24.856 14.8109 25.2263 16.1396 25.3134C17.5682 25.407 18.9991 25.1708 20.3218 24.6229C21.6444 24.075 22.8233 23.2303 23.7672 22.1539C24.7111 21.0776 25.3948 19.7985 25.7653 18.4157C26.1359 17.0328 26.1833 15.5833 25.904 14.1792C25.6247 12.7751 25.0262 11.454 24.1547 10.3182C23.2832 9.18247 22.162 8.2624 20.8781 7.62921C19.6839 7.04029 18.3785 6.71422 17.05 6.67151C16.9109 6.66704 16.8413 6.6648 16.7957 6.70901C16.75 6.75321 16.75 6.82437 16.75 6.96669V13.0365C16.75 13.174 16.75 13.2427 16.7882 13.2854C16.8263 13.3281 16.9007 13.3365 17.0494 13.3533C17.3542 13.3878 17.6516 13.4748 17.9281 13.6112C18.2945 13.7919 18.6145 14.0544 18.8632 14.3786C19.1119 14.7027 19.2827 15.0797 19.3624 15.4804C19.4421 15.8811 19.4286 16.2948 19.3228 16.6894C19.2171 17.084 19.022 17.4491 18.7526 17.7562C18.4832 18.0634 18.1468 18.3045 17.7693 18.4608C17.3919 18.6172 16.9835 18.6846 16.5758 18.6579C16.2682 18.6377 15.9672 18.5644 15.686 18.4418C15.5487 18.382 15.4801 18.3521 15.4258 18.37C15.3714 18.3879 15.337 18.4474 15.2683 18.5665L12.2334 23.8231Z"
        fill={stroke}
      />
    </svg>
  );
};

export default TimeProgressIcon;
