import { useAppSelector } from "@redux/hooks";
import { TNotificationsFilter } from "../types";
import { useMemo } from "react";
import { TGroupedList, groupData } from "../utils";
import {
  QKEY_UNREAD_NOTIFICATIONS,
  QKEY_ALL_NOTIFICATIONS,
} from "@constants/queryKeys";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectUser } from "@redux/slices/auth/auth";
import useGetInfiniteList from "@hooks/common/useGetInfiniteList";
import { useUpdateSponsorTable } from "./useUpdateSponsorTable";

type ListState = {
  isLoading: boolean;
  isEmpty: boolean;
  total: number;
  data: TGroupedList;
  hasUnreadMandatory?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: any;
  isFetchingNextPage: boolean;
  totalPages: number;
};

const useGetNotificationsList = (disabled: boolean) => {
  const selectedUser = useAppSelector(selectSelectedAccount);
  const user = useAppSelector(selectUser);
  const merchantId = selectedUser?.id || 0;
  const userId = user?.userAccID || 0;

  const baseURL = `/accounts/${merchantId}/members/${userId}/push-notifications`;

  const {
    data: unreadNotifications,
    isLoading: isLoadingUnread,
    fetchNextPage: fetchNextPageUnread,
    hasNextPage: hasNextPageUnread,
    isFetchingNextPage: isFetchingNextPageUnread,
  } = useGetInfiniteList(
    {
      max: 20,
      sort: `alertType,-createdAt`,
      filter: {
        readAt: null,
      },
    },
    {
      queryKey: QKEY_UNREAD_NOTIFICATIONS,
      baseURL,
    },
    {
      enabled: !!userId && !!merchantId && !disabled,
    },
  );

  const {
    data: allNotifications,
    isLoading: isLoadingAll,
    fetchNextPage: fetchNextPageAll,
    hasNextPage: hasNextPageAll,
    isFetchingNextPage: isFetchingNextPageAll,
  } = useGetInfiniteList(
    {
      max: 20,
      sort: `alertType,-createdAt`,
    },
    {
      queryKey: QKEY_ALL_NOTIFICATIONS,
      baseURL,
    },
    {
      enabled: !!userId && !!merchantId && !disabled,
    },
  );

  const unreadList = useMemo(() => {
    const list =
      unreadNotifications?.pages?.map((row: any) => row.data).flat() ?? [];
    const groupedData = groupData(list);
    const unreadMandatoryList = groupedData.mandatory.filter(
      (a) => !a.firstCheckedAt,
    );
    return {
      list: groupedData,
      total: unreadNotifications?.pages[0]?.total ?? 0,
      hasUnreadMandatory: unreadMandatoryList.length > 0,
    };
  }, [unreadNotifications]);

  useUpdateSponsorTable({ unreadNotifications });

  const allList = useMemo(() => {
    const list =
      allNotifications?.pages?.map((row: any) => row.data).flat() ?? [];
    const groupedList = groupData(list);
    return {
      list: groupedList,
      total: groupedList.mandatory.length + groupedList.generic.length,
    };
  }, [allNotifications]);

  const listState: Record<TNotificationsFilter, ListState> = {
    unread: {
      isLoading: isLoadingUnread,
      isEmpty: unreadList.total === 0,
      total: unreadList.total || 0,
      data: unreadList.list,
      hasUnreadMandatory: unreadList.hasUnreadMandatory,
      hasNextPage: hasNextPageUnread,
      fetchNextPage: fetchNextPageUnread,
      isFetchingNextPage: isFetchingNextPageUnread,
      totalPages: unreadNotifications?.pages?.length || 0,
    },
    all: {
      isLoading: isLoadingAll,
      isEmpty: allList.total === 0,
      total: allList.total || 0,
      data: allList.list,
      hasNextPage: hasNextPageAll,
      fetchNextPage: fetchNextPageAll,
      isFetchingNextPage: isFetchingNextPageAll,
      totalPages: allNotifications?.pages?.length || 0,
    },
  };

  return {
    listState,
  };
};

export default useGetNotificationsList;
