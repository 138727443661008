type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const ListNotesIcon = ({
  width = 24,
  height = 24,
  stroke = "#575353",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="list-notes">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.4001 17.3034C4.4001 18.572 5.42849 19.6004 6.69707 19.6004L17.3031 19.6004C18.5717 19.6004 19.6001 18.572 19.6001 17.3034L19.6001 6.69736C19.6001 5.42878 18.5717 4.40039 17.3031 4.40039L6.69707 4.40039C5.42849 4.40039 4.4001 5.42878 4.4001 6.69736L4.4001 8.86997C4.4001 9.20134 4.66873 9.46997 5.0001 9.46997C5.33147 9.46997 5.6001 9.20134 5.6001 8.86997L5.6001 6.69736C5.6001 6.09152 6.09123 5.60039 6.69707 5.60039L17.3031 5.60039C17.909 5.60039 18.4001 6.09152 18.4001 6.69736L18.4001 17.3034C18.4001 17.9093 17.909 18.4004 17.3031 18.4004L6.69707 18.4004C6.09123 18.4004 5.6001 17.9093 5.6001 17.3034L5.6001 12.1936C5.6001 11.8623 5.33147 11.5936 5.0001 11.5936C4.66873 11.5936 4.4001 11.8623 4.4001 12.1936L4.4001 17.3034ZM15.4863 12.6004C15.8176 12.6004 16.0863 12.3318 16.0863 12.0004C16.0863 11.6691 15.8176 11.4004 15.4863 11.4004L8.5145 11.4004C8.18313 11.4004 7.9145 11.6691 7.9145 12.0004C7.9145 12.3318 8.18313 12.6004 8.5145 12.6004L15.4863 12.6004ZM13.0113 9.10043C13.3427 9.10043 13.6113 8.8318 13.6113 8.50043C13.6113 8.16906 13.3427 7.90043 13.0113 7.90043L8.50018 7.90043C8.16881 7.90043 7.90018 8.16906 7.90018 8.50043C7.90018 8.8318 8.16881 9.10043 8.50018 9.10043L13.0113 9.10043ZM11.3852 16.1004C11.7166 16.1004 11.9852 15.8318 11.9852 15.5004C11.9852 15.1691 11.7166 14.9004 11.3852 14.9004L8.5145 14.9004C8.18313 14.9004 7.9145 15.1691 7.9145 15.5004C7.9145 15.8318 8.18313 16.1004 8.5145 16.1004L11.3852 16.1004Z" 
          fill={stroke}
        />
      </g>
    </svg>
  )
}

export default ListNotesIcon;
