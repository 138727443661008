import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { palette } from "@palette";

export const CustomToolTip = ({
  children,
  showToolTip,
  message = "The edit is blocked by a confirmed match on PEP",
  fullWidth,
  customStyle,
  extraMessage,
  maxWidth,
  placement,
}: {
  showToolTip: boolean;
  children: any;
  message?: string;
  fullWidth?: boolean;
  customStyle?: any;
  extraMessage?: string;
  maxWidth?: string;
  placement?: "bottom" | "left" | "top" | "right";
}) => (
  <Tooltip
    disableFocusListener={!showToolTip}
    disableHoverListener={!showToolTip}
    disableTouchListener={!showToolTip}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    placement={placement}
    title={
      <Text
        lineHeight="120%"
        fontSize="12px"
        fontWeight="book"
        textAlign={customStyle?.textAlign || "left"}
        color={palette.neutral[5]}
        data-testid="custom-tooltip-text"
      >
        {message}
        {extraMessage}
      </Text>
    }
    parentStyle={{
      display: customStyle?.display || "block",
    }}
    data-testid="custom-tooltip"
  >
    {children}
  </Tooltip>
);
