import { showMessage } from "@common/Toast/ShowToast";
import { customInstance } from "@services/api";
import { buildAccountsUrl } from "@services/api/utils.api";
import axios from "axios";
import React from "react";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { useQueryClient } from "react-query";

export function getImageExtension(file: File) {
  const splitted = file.name.split(".");

  if (splitted.length) {
    const ext = splitted.pop()?.toLowerCase();

    return ext;
  }
}

export function isImage(file: File) {
  const extension = getImageExtension(file);

  if (extension) {
    return ["png", "jpeg", "jpg"].includes(extension);
  }
}

export const useUploadImage = () => {
  const queryClient = useQueryClient();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const handleUpload = async (file: File, formType = "merchant_image") => {
    try {
      if (!isImage(file)) {
        showMessage(
          "Error",
          "Only png, jpeg and jpg extensions are allowed",
          true,
          "",
          false,
        );
        return;
      }
      setIsUploading(true);
      const res = await customInstance({
        url: "s3/upload-form",
        method: "POST",
        data: {
          formType,
        },
      });

      await axios.post(
        res.action,
        {
          key: res.key,
          acl: res.acl,
          "x-amz-date": res["x-amz-date"],
          "x-amz-credential": res["x-amz-credential"],
          "x-amz-algorithm": res["x-amz-algorithm"],
          policy: res.policy,
          "Content-Type": `image/${file?.name?.split(".")?.pop()}`,
          "X-Amz-Signature": res["x-amz-signature"],
          file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      await customInstance({
        url: buildAccountsUrl("media-items"),
        method: "POST",
        data: {
          label: "main_pic",
          url: `${res.action}/${res.key}`,
        },
      });

      queryClient.refetchQueries("get-all-media-items");
      showMessage("Success", "Image has been successfully added");
      setIsUploading(false);
    } catch (err) {
      setIsUploading(false);
    }
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        handleUpload(file[i]);
      }

      event.target.value = "";
    }
  };

  const handleDragDropChange = (
    metaFile: IFileWithMeta,
    status: StatusValue,
  ) => {
    if (status === "done") handleUpload(metaFile.file);
  };
  return {
    handleUpload,
    isUploading,
    handleImageChange,
    handleDragDropChange,
  };
};
