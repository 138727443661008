type WarningIconProps = {
  height?: number;
  width?: number;
  fill?: string;
};

const WarningIcon = ({
  height = 32,
  width = 32,
  fill = "#FF9336",
}: WarningIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0727 5.8245C18.7638 5.27044 18.3166 4.80976 17.7767 4.48929C17.2368 4.16882 16.6233 4 15.9987 4C15.3741 4 14.7606 4.16882 14.2207 4.48929C13.6807 4.80976 13.2336 5.27044 12.9247 5.8245L3.61503 22.7528C3.3194 23.284 3.16657 23.8855 3.17202 24.4964C3.17746 25.1073 3.34098 25.7059 3.64602 26.2316C3.95554 26.7739 4.40002 27.2231 4.93441 27.5336C5.46879 27.8441 6.07411 28.005 6.68903 27.9999H25.3084C25.9233 28.005 26.5286 27.8441 27.063 27.5336C27.5974 27.2231 28.0418 26.7739 28.3514 26.2316C28.6564 25.7059 28.8199 25.1073 28.8254 24.4964C28.8308 23.8855 28.678 23.284 28.3824 22.7528L19.0727 5.8245ZM15.9987 23.6364C15.7873 23.6364 15.5806 23.5724 15.4049 23.4525C15.2291 23.3327 15.0921 23.1623 15.0112 22.9629C14.9303 22.7636 14.9091 22.5443 14.9504 22.3327C14.9916 22.1211 15.0934 21.9267 15.2429 21.7741C15.3924 21.6216 15.5828 21.5177 15.7902 21.4756C15.9975 21.4335 16.2124 21.4551 16.4077 21.5376C16.603 21.6202 16.77 21.76 16.8874 21.9394C17.0049 22.1188 17.0675 22.3297 17.0675 22.5455C17.0675 22.8348 16.9549 23.1123 16.7545 23.3169C16.554 23.5214 16.2822 23.6364 15.9987 23.6364ZM17.0675 18.182C17.0675 18.4713 16.9549 18.7488 16.7545 18.9533C16.554 19.1579 16.2822 19.2729 15.9987 19.2729C15.7152 19.2729 15.4434 19.1579 15.2429 18.9533C15.0425 18.7488 14.9298 18.4713 14.9298 18.182V11.6367C14.9298 11.3474 15.0425 11.0699 15.2429 10.8653C15.4434 10.6608 15.7152 10.5458 15.9987 10.5458C16.2822 10.5458 16.554 10.6608 16.7545 10.8653C16.9549 11.0699 17.0675 11.3474 17.0675 11.6367V18.182Z"
        fill={fill}
      />
    </svg>
  );
};

export default WarningIcon;
