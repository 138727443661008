import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import { useAppDispatch } from "@redux/hooks";
import { setSelectedVariant } from "@redux/slices/checkout";
import { buildMerchantEndpoints } from "@services/api/utils.api";

export const useFindFundraiserById = (
  id?: string,
  publicResource?: boolean,
) => {
  if (!id) return { data: undefined };

  const getFundraiser = (id: string) => {
    const publicUrl = `products/${id}?filter=typeName:"fundraiser"`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const getFundraiserVariants = (id: string) => {
    const publicUrl = `products/${id}/variants?&sort=displayOrder`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const { data, error, isLoading } = useQuery(
    ["find-fundraiser-by-id", id],
    async () => {
      const fundraiser = await getFundraiser(id);
      const variants = await getFundraiserVariants(id);
      return { fundraiser, variants };
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const dispatch = useAppDispatch();

  if (!data)
    return {
      error,
      isLoading,
      redirect: (error as any)?.response?.status === 404 ? true : false,
    };

  const customAmounts =
    data.variants?.data?.map((amount: any) => {
      if (
        (amount.minPrice && amount.maxPrice) ||
        amount.name === "Any Amount"
      ) {
        const minPrice = (amount.minPrice / 100).toFixed(2);
        const maxPrice = (amount.maxPrice / 100).toFixed(2);
        dispatch(
          setSelectedVariant({
            type: "fundraiser",
            min: minPrice,
            max: maxPrice,
          }),
        );

        return {
          isDefault: true,
          id: amount.id,
          amount: (amount.price / 100).toFixed(2),
          title: amount.name,
          description: amount.description,
          min_max: {
            enabled: !amount.allowCustomPrice,
            min: minPrice,
            max: maxPrice,
          },
          thumbnail: amount.imageURL ? amount.imageURL : "",
          active: amount.isEnabled,
          bundle: 1,
          quantity: amount.inventory,
          display: amount.showAvailableVariants,
        };
      }
      return {
        id: amount.id,
        title: amount.name,
        amount: (amount.price / 100).toFixed(2),
        description: {
          enabled: Boolean(amount.description),
          text: stringToEditorState(amount.description),
        },
        thumbnail: amount.imageURL ? amount.imageURL : "",
        active: amount.isEnabled,
        bundle: 1,
        quantity: amount.inventory,
        display: amount.showAvailableVariants,
      };
    }) ?? [];

  const customPaymentTypes: any = {};

  // "default" should be the first property in customPaymentTypes object.
  customPaymentTypes["default"] =
    data?.fundraiser.defaultRecurringIntervalName === "once"
      ? "one_time"
      : data?.fundraiser.defaultRecurringIntervalName;

  data?.fundraiser?.recurringIntervals?.forEach((item: string) => {
    if (item === "once") {
      customPaymentTypes["one_time"] = true;
    } else {
      customPaymentTypes[item] = true;
    }
  });

  ["one_time", "monthly", "quarterly", "yearly"].forEach((item) => {
    if (!customPaymentTypes[item]) customPaymentTypes[item] = false;
  });

  const customData = {
    general: {
      title: data?.fundraiser?.name || "",
      description: data?.fundraiser?.description,
      browseMore: data?.fundraiser?.canBrowseCampaigns || false,
      creatorName: data?.fundraiser?.merchantName,
      creatorImage: data?.fundraiser?.merchantImageURL,
      creatorDescription: data?.fundraiser?.merchantDescription,
      featuredImage: {
        image: data?.fundraiser?.imageURL || "",
        active: Boolean(data?.fundraiser?.imageURL),
        useAsBackground: data?.fundraiser?.usesBackgroundImage || false,
      },
      typeName: data?.fundraiser?.typeName,
    },
    payment: {
      payment_types: customPaymentTypes,
      customer_pays_credit: {
        active: data?.fundraiser?.needsTax || false,
        optional: data?.fundraiser?.allowFeeChoice || false,
      },
      amountsList: customAmounts || [],
    },
    accID: data?.fundraiser?.accID,
  };

  return {
    data: customData,
    isLoading: isLoading,
    redirect: (error as any)?.response?.status === 404 ? true : false,
  };
};
