interface IconProps {
  height?: number;
  width?: number;
  stroke?: string;
}

const EditIconV2 = ({
  width = 20,
  height = 20,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.7594 5.73209L14.268 2.24146C14.1519 2.12536 14.0141 2.03326 13.8624 1.97042C13.7107 1.90759 13.5482 1.87524 13.384 1.87524C13.2198 1.87524 13.0572 1.90759 12.9056 1.97042C12.7539 2.03326 12.6161 2.12536 12.5 2.24146L2.86641 11.8751C2.74983 11.9907 2.65741 12.1284 2.59451 12.2801C2.5316 12.4318 2.49948 12.5944 2.50001 12.7586V16.2501C2.50001 16.5816 2.6317 16.8995 2.86612 17.1339C3.10054 17.3684 3.41849 17.5001 3.75001 17.5001H16.875C17.0408 17.5001 17.1997 17.4342 17.3169 17.317C17.4342 17.1998 17.5 17.0408 17.5 16.8751C17.5 16.7093 17.4342 16.5503 17.3169 16.4331C17.1997 16.3159 17.0408 16.2501 16.875 16.2501H9.00938L17.7594 7.50006C17.8755 7.38398 17.9676 7.24617 18.0304 7.09449C18.0933 6.94282 18.1256 6.78025 18.1256 6.61607C18.1256 6.45189 18.0933 6.28933 18.0304 6.13765C17.9676 5.98597 17.8755 5.84816 17.7594 5.73209ZM7.24141 16.2501H3.75001V12.7586L10.625 5.88365L14.1164 9.37506L7.24141 16.2501ZM15 8.49146L11.5094 5.00006L13.3844 3.12506L16.875 6.61646L15 8.49146Z"
        fill={stroke}
      />
    </svg>
  );
};

export default EditIconV2;
