import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";

const ExpiredPaymentFormModal = NiceModal.create(() => {
  const modal = useModal();

  const handleModalClose = () => {
    modal.hide();
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      title="Attention!"
      onSubmit={handleModalClose}
      actionLabel="Okay"
    >
      Our online campaign has expired, but you can still access and view its content.
    </Popup>
  );
});

export default ExpiredPaymentFormModal;