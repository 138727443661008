import styled from "@emotion/styled";
import GiveLogo from "@assets/images/GiveLogo.png";
import { memo } from "react";
import { palette } from "@palette";
import { Stack } from "@mui/material";
import { TosWrapper } from "./TosWrapper";
import { RHFCheckbox } from "@components/common/Checkbox";

type Props = {
  isEnterPrise?: boolean;
  merchantAgreementVersion?: {
    version: string;
    lastUpdated: string;
  };
};

const TermsOfService = ({ merchantAgreementVersion, isEnterPrise }: Props) => {
  const isGeneric = !merchantAgreementVersion;
  return (
    <TosWrapper>
      <Header>
        <img src={GiveLogo} alt="Give Logo" style={{ height: "100%" }} />
      </Header>
      <main>
        <Title>Give Corporation Terms of Service - United States</Title>
        {merchantAgreementVersion && (
          <>
            <Stack flexDirection="row" justifyContent="center">
              <SubTitle>Version:</SubTitle>
              <SubTitle style={{ fontWeight: "normal" }}>
                {merchantAgreementVersion?.version}
              </SubTitle>
            </Stack>
            <Stack flexDirection="row" justifyContent="center">
              <SubTitle>Last updated:</SubTitle>
              <SubTitle style={{ fontWeight: "normal" }}>
                {merchantAgreementVersion.lastUpdated}
              </SubTitle>
            </Stack>
          </>
        )}

        <Paragraph>
          These Terms of Service are a legal agreement (this
          &ldquo;Agreement&ldquo;) between you (&ldquo;user,&ldquo;
          &ldquo;you&ldquo; or &ldquo;your&ldquo;) and Give Corporation, Inc.
          referenced collectively as (&ldquo;Give Corporation,&ldquo;
          &ldquo;Give Corporation&ldquo;, &ldquo;we,&ldquo; &ldquo;our&ldquo; or
          &ldquo;us&ldquo;). A Sponsor is an acquirer that contracts with a
          payment facilitator. As used in this Agreement, &ldquo;Service&ldquo;
          refers to Give Corporation&lsquo;s payment processing services, as
          well as our website, any software, programs, documentation, tools,
          hardware, internet-based services, components, and any updates
          (including software maintenance, service information, help content,
          bug fixes or maintenance releases) thereto provided to you by Give
          Corporation, directly or indirectly. To use the Service, you must
          agree to all the terms of this Agreement.
        </Paragraph>

        <Paragraph>
          Give Corporation offers the Service exclusively through integrations
          with third-party websites (&ldquo;Platforms&ldquo;). The Platform
          through which you access the Service might have its own terms of
          service and privacy policy that apply to you. This Agreement
          incorporates by reference all policies, notices, and other content
          that appear on our website at www.Give Corporation.com as well as
          policies, notices, and other content concerning the Service that
          appear on the Platform&apos;s website (collectively, the
          &ldquo;Website&ldquo;).
        </Paragraph>

        <Heading>
          1. Merchant Relationship with Give Corporation and Others
        </Heading>
        {isEnterPrise ? (
          <Paragraph>{`Give Corporation offers the Service exclusively through integrations with third-party websites (“Platforms“). The Platform through which you access the Service might have its own terms of service and privacy policy that apply to you. This Agreement incorporates by reference all policies, notices, and other content that appear on our website at www.Give Corporation.com as well as policies, notices, and other content concerning the Service that appear on the Platform's website (collectively, the “Website“).`}</Paragraph>
        ) : (
          <Paragraph>
            Give Corporation provides the Service to users
            (&ldquo;Merchants&ldquo;) to facilitate receipt by Merchants of
            payments by card and bank transfer. These transactions are between
            Merchants and persons who pay them (&ldquo;Purchasers&ldquo;).
            Neither Give Corporation, nor any bank, is a party to these
            transactions. For payments by card, Give Corporation initiates the
            payment process by providing information to a processor that sends
            it to a bank that is a member of Visa U.S.A., Inc., Visa
            International (&ldquo;Visa&ldquo;), MasterCard International
            Incorporated (&ldquo;MasterCard&ldquo;), American Express Travel
            Related Services Company Inc. (&ldquo;American Express&ldquo;), or
            other payment card networks, association, or companies
            (collectively, the &ldquo;Networks&ldquo;). The processor and
            Sponsor bank are referred to collectively as the &ldquo;Bank&ldquo;.
            The Bank is obligated to pay Merchants under both (a) the provisions
            of its agreement with Give Corporation, and (b) the by-laws,
            operating regulations and all other rules, policies and procedures
            of the Networks as in effect from time to time (the &ldquo;Operating
            Regulations&ldquo;), that make the Bank responsible for settling
            with you as a Merchant. For payments by bank transfer, Give
            Corporation initiates the payment process by providing information
            to the Bank through the National Automated Clearing House (“ACH”).
            Merchants must register with Give Corporation. To register, a
            Merchant provides information, including email address and a
            self-selected password, in order to create an account
            (&ldquo;Account&ldquo;). You are responsible for maintaining the
            secrecy and security of your Account access credentials and for any
            use of or action taken under them. Give Corporation asks Merchants
            for additional information, such as street address, telephone
            number, tax identification number (such as Social Security Number),
            and date of birth. You agree to provide supplemental documentation
            upon request (including but not limited to: articles of
            incorporation, passports, driver&apos;s license or a business
            license). You authorize Give Corporation, directly or through third
            parties, to make inquiries or verify that this information is
            accurate (for example, through social media or third party
            databases). You specifically authorize Give Corporation to request a
            consumer report that contains your name and address. You must
            provide accurate and complete information to Give Corporation.
            Specifically, when you register for Give Corporation, you must
            demonstrate that you can receive email at the email address you
            provide. If you do not confirm your email address, then (a) fourteen
            (14) days after you accept your first payment, you will not be able
            to accept additional payments, and (b) thirty (30) days after you
            accept your first payment, the Bank will refund to your payers all
            of the payments you have accepted. In addition, in order to settle
            the payments you have accepted to your bank account or other
            instrument, you must provide to Give Corporation identity and
            settlement information (such as your bank account information). If
            you do not provide identity and settlement information, then (a)
            thirty (30) days after you accept your first payment, you will not
            be able to accept additional payments, and (b) unless you provide
            identity and settlement information promptly, the Bank will refund
            to your payers all of the payments you have accepted. Finally, if at
            any time Give Corporation is unable to verify that the identity
            information you provided is correct and up-to-date, then, unless you
            provide verifiable information promptly, (a) Give Corporation will
            disable your Account so that you cannot accept additional payments,
            and (b) the Bank will refund to your payers all of the payments that
            you have accepted but not settled. Neither the Bank, Give
            Corporation nor Platform will have any liability to you for your
            inability to accept payments or for refunds pursuant to this
            paragraph. Give Corporation allows individuals, businesses, and
            non-profit organizations to register for Give Corporation if they
            are located in one of the 50 United States or the District of
            Columbia. Residents of Puerto Rico, U.S. territories (such as Guam),
            and U.S. military bases are not supported. A merchant must be either
            a United States citizen, a legal permanent resident of the United
            States, or a United States business or nonprofit organization having
            a physical presence in the United States and authorized to conduct
            business by the state in which it operates. A user who opens an
            Account must be eighteen (18) years of age or older. You may open an
            Account for a business or nonprofit organization only if it is
            legitimate and you have the authority to enter into this Agreement
            on its behalf. Your acceptance of this Agreement constitutes
            acceptance by the business or nonprofit organization. Each Account
            must be linked to a verified U.S. bank account.
          </Paragraph>
        )}

        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          In order to use Give Corporation as a Merchant, you must register with
          accurate and complete information. You must be at least 18 years old
          and based in the US.
        </Paragraph>

        <Paragraph>
          <strong>Processor and Sponsor Information</strong>
          <br />
          <br />
          The Sponsor Bank is Chesapeake Bank 97 N. Main Street Kilmarnock, VA
          22482 Phone: 866-941-9640 Email: ISO@ches.bank
          <br />
          <br />
          The processor is RS2 Software INC. 4643 S Ulster St Ste 1285 Denver,
          CO, 80237-3090 United States Phone: 866-791-5950 Email:
          rs2usa-ops@rs2.com
        </Paragraph>

        <Heading>2. Purchasers (Customers, Donors, etc.)</Heading>

        <Paragraph>
          Purchasers may register with Give Corporation. Whether or not
          Purchasers register, however, their use of the Service is governed by
          the terms of this Agreement. In particular, Section 9 of this
          Agreement applies to Purchasers paying by bank transfer.
        </Paragraph>
        <Paragraph>
          The Merchant (You) are responsible for the actions of your Purchasers.
          Any fraudulent, criminal, or financially irresponsible behavior and
          activity conducted by Purchasers linked to your Give Corporation
          Merchant Account is ultimately your responsibility. Any fines, legal
          action, and/or financial ramifications that occur due to your
          Purchaser&apos;s activity, the Merchant on record (you) will be held
          fully accountable.
        </Paragraph>
        <Paragraph>
          The burden and financial responsibility of refunds, chargebacks,
          returns, bank fraud, and check fraud are solely upon the Merchant.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Purchasers don&apos;t have to register with Give Corporation, but they
          have to comply with this Agreement
        </Paragraph>
        <Paragraph>
          Give Corporation recommends that you conduct a thorough KYC (Know Your
          Customer) as you will be held responsible for any activity. Give
          Corporation is not responsible for ANY transactions and is required by
          law to cooperate with the relevant authorities when questionable
          activity occurs.
        </Paragraph>

        <Heading>3. API Developer Relationship with Give Corporation</Heading>
        <Paragraph>
          Give Corporation offers an application programming interface
          (&ldquo;API&ldquo;) to retrieve information from or submit requests to
          Give Corporation. Developers who use the Give Corporation API, the
          applications that they develop, and the users that these applications
          serve, are subject to the terms of this Agreement.
        </Paragraph>
        <Paragraph>
          Give Corporation grants you a limited, worldwide, royalty-free,
          non-assignable, non-sublicenseable and non-exclusive license to use
          the API solely to develop applications to make use of Give Corporation
          services. You may not use the API for any purpose not expressly
          permitted by this license. You may not copy (except for backup
          purposes, and with all titles, trademark, copyright, and other
          proprietary and restricted rights notices), modify, adapt,
          redistribute, decompile, reverse engineer, disassemble, or create
          derivative works of the API or any part of the API. The API may be
          licensed under an open source software license; use, reproduction and
          distribution of the API are governed by the terms of that open source
          software license. Give Corporation owns all legal rights, title and
          interest in and to the API, including any Intellectual Property Rights
          that subsist in the API. “Intellectual Property Rights” means any and
          all rights under patent law, copyright law, trade secret law,
          trademark law, and any and all other proprietary rights. Give
          Corporation reserves all rights not expressly granted to you.
        </Paragraph>
        <Paragraph>
          As a developer, you must receive permission from and open Give
          Corporation Accounts for individual Merchants. You may not use the
          Give Corporation API to facilitate use of a Give Corporation Account
          to process payments for goods and services provided by anyone other
          than the Merchant who owns the Give Corporation Account. In the event
          a Merchant receives a chargeback for a payment facilitated by your
          API, Give Corporation will collect or attempt to collect those funds
          from the Merchant in accordance with the &ldquo;Chargebacks&ldquo;
          section below. In some cases (such as where you violate this Agreement
          or cause Merchants who use your API to violate this Agreement), you
          may also be held liable for chargebacks associated with payments
          facilitated through your API or SDK application. If your
          implementation does not flag &ldquo;Virtual Terminal&ldquo;
          transactions correctly, Give Corporation may hold you liable for the
          difference between the &ldquo;Fees&ldquo; (defined in Section 5 below)
          actually charged to Merchants and the Fees that would have been
          charged if you had flagged &ldquo;Virtual Terminal&ldquo; transactions
          correctly. You may charge a fee in addition to Give Corporation&apos;s
          processing fees for the value-add services that you provide
          (&ldquo;App Fee&ldquo;). Your App Fee will be sent to you, and the
          payment (net of Give Corporation&apos;s fees and your App Fee) will be
          sent to the Merchant for whom you are facilitating the payment.
        </Paragraph>
        <Paragraph>
          Give Corporation will issue you an access token for each user of your
          API who creates a Give Corporation Account. You agree that access
          tokens are the property of Give Corporation, and that misuse of access
          tokens by you or your users could cause substantial loss and damage to
          Give Corporation. If your API uses Give Corporation&apos;s
          Tokenization API to facilitate payments for Merchants, the credit card
          tokens will be associated with the API or SDK application, not the
          individual Merchant. Consequently, as the developer of the API, you
          will be responsible for complying with the Payment Card Industry Data
          Security Standard (&ldquo;PCI DSS&ldquo;) with respect to the primary
          account number and other protected information you collect from users
          of the API. You will implement appropriate measures to protect the
          security of the access tokens and credit card tokens, and you will
          notify Give Corporation promptly if you suspect they have been
          misappropriated or misused.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Developers can use the Give Corporation API to integrate their
          applications with the Give Corporation Service, subject to important
          conditions.
        </Paragraph>

        <Heading>
          4. Limitations on Give Corporation&apos;s and Others&apos;
          Responsibility
        </Heading>
        <Paragraph>
          Purchasers may register with Give Corporation. Whether or not
          Purchasers register, however, their use of the Service is governed by
          the terms of this Agreement. In particular, Section 9 of this
          Agreement applies to Purchasers paying by bank transfer.
        </Paragraph>
        <Paragraph>
          Neither Give Corporation nor any other third party makes any
          representations or guarantees regarding Merchants or Purchasers
          utilizing the Service. Use of our Service in no way represents any
          endorsement by Give Corporation or any Network, of a user&apos;s
          existence, legitimacy, ability, policies, practices, or beliefs. Give
          Corporation does not have control of, or liability for, goods or
          services that are paid for with the Service. Merchant acknowledges and
          agrees that receipt of Purchaser information via the Service does not
          indicate that the Purchaser&apos;s payment instrument has sufficient
          available funds, that a transaction will be authorized or processed,
          or that the transaction will not later result in a chargeback or
          reversal.
        </Paragraph>

        <Paragraph>
          A charitable organization may use Give Corporation to accept payments
          as a Merchant. Not all charitable organizations are tax-exempt, and
          not all contributions to charitable organizations are tax-deductible.
          Charitable organizations are responsible for correctly classifying
          themselves and their transactions, issuing any required reports and
          receipts, and making any required tax or other filings. Contributors
          are responsible for verifying the status of organizations to which
          they donate and reporting their donations correctly for tax and other
          purposes. Give Corporation specifically disclaims any liability in
          this regard.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Neither Give Corporation nor any other third party is responsible for
          the behavior of its users. It&#39;s up to you to decide whether you
          want to do business with a Merchant, Purchaser, or charitable
          organization using Give Corporation.
        </Paragraph>
        <Heading>5. Our Fees</Heading>
        <Paragraph>
          Give Corporation charges transaction processing fees to Merchants
          using the Service. Give Corporation also may charge Merchants the
          following fees for exceptions processing: $15.00 per chargeback (in
          addition to the amount of the chargeback); $15.00 per ACH return (in
          addition to the amount of the return); $25.00 research fee (if an
          Account is deemed abandoned under Section 23 below). These foregoing
          Give Corporation fees and App Fees, if applicable, are netted against
          other funds due to Merchant or debited from the Merchant&#39;s bank
          account or other payment instrument associated with the Merchant&#39;s
          Account. The Merchant agrees to pay the fees for the Service that are
          posted from time to time by Give Corporation or by the Platform (the
          &quot;Fees&quot;). In general, Fees posted by Give Corporation and the
          Platform are cumulative; however, in case of inconsistency, the Fees
          posted by the Platform apply.
        </Paragraph>
        <Paragraph>
          Merchants may increase their prices to include the cost of the Fees
          and disclose these increases to their Purchasers as a &quot;Service
          Fee&quot;. Merchants may only do this in compliance with the Operating
          Regulations.
        </Paragraph>
        <Paragraph>
          Subject to the terms of this Agreement, we and the Platform reserve
          the right to change our Fees. By continuing to use the Service, you
          consent to the change in Fees. To withdraw your consent, you must
          close your Account.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;charges fees to use our Service. We reserve the
          right to change our fees at any time.
        </Paragraph>
        <Heading>6. E-Sign Disclosure and Consent Electronic Delivery</Heading>
        <Paragraph>
          By accepting this Agreement, you agree and consent to receive
          electronically all communications, agreements, documents, notices and
          disclosures (collectively, &quot;Communications&quot;) that we provide
          in connection with your Give Corporation Account and your use of the
          Service. Communications include but are not limited to:
        </Paragraph>
        <OList>
          <ListElement>
            Agreements and policies, such as this Agreement and our Privacy
            Policy, including updates thereto;
          </ListElement>
          <ListElement>Annual disclosures;</ListElement>
          <ListElement>Transaction receipts or confirmations;</ListElement>
          <ListElement>
            Communication in relation to delinquent accounts (which may also be
            by phone, and may be made by Give Corporation or by anyone on its
            behalf, including a third party collection agent);
          </ListElement>
          <ListElement>Account statements and history;</ListElement>
          <ListElement>Federal and state tax statements.</ListElement>
        </OList>
        <Paragraph>
          We will provide these Communications to you by emailing them to you at
          the primary email address listed in your Give Corporation Account
          registration, by emailing you a link or instructions how to access
          them on a website, or (if permitted by law) by posting them on the
          Website. Communications are considered received by you within 24 hours
          of the time they are emailed to you or posted to the Website. You
          further agree that your electronic signature has the same effect as
          your manual, physical signature.
        </Paragraph>
        <SubHeading>Hardware and Software Requirements</SubHeading>
        <Paragraph>
          In order to access and retain electronic Communications, you will need
          the following computer hardware and software:
        </Paragraph>
        <OList>
          <ListElement>A computer with an Internet connection;</ListElement>
          <ListElement>
            Minimum recommended browser standards are Microsoft Internet
            Explorer version 9.0 and above (see{" "}
            <a href="https://www.google.com/url?q=http://www.microsoft.com/ie&sa=D&source=editors&ust=1686838707673301&usg=AOvVaw1bfUrBaHYCLuHnVV7XdPny">
              http://www.microsoft.com/ie
            </a>
            &nbsp;for current version), Mozilla Firefox current version (see{" "}
            <a href="https://www.google.com/url?q=http://www.mozilla.com/&sa=D&source=editors&ust=1686838707673654&usg=AOvVaw3fzpZVrfWyDNHSJgSmBAR4">
              http://www.mozilla.com
            </a>
            &nbsp;for current version), Apple Safari current version (see{" "}
            <a href="https://www.google.com/url?q=http://www.apple.com/safari&;sa=D&;source=editors&;ust=1686838707673897&;usg=AOvVaw1i5RTiYhef4p8OfY5KaUfj">
              http://www.apple.com/safari
            </a>
            &nbsp;for current version), or Chrome current version (see{" "}
            <a href="https://www.google.com/url?q=https://www.google.com/chrome&;sa=D&;source=editors&;ust=1686838707674196&;usg=AOvVaw3ug42A0y7dqZ3KXQt-lCz5">
              http://www.google.com/chrome
            </a>
            &nbsp;for current version). The browser must have cookies enabled.
          </ListElement>
          <ListElement>
            Adobe Acrobat Reader version 8.0 and above to open documents in PDF
            format;
          </ListElement>
          <ListElement>
            A valid email address (your primary email address registered with
            Give Corporation); and
          </ListElement>
          <ListElement>
            Sufficient storage space to save past Communications or an installed
            printer to print them.
          </ListElement>
        </OList>
        <Paragraph>
          By giving your consent, you are confirming that you have access to the
          necessary equipment and are able to receive, open, and print or
          download a copy of any Communications for your records. It is
          important for you to retain copies of Communications because they may
          not be accessible in your Give Corporation Account at a later date.
        </Paragraph>
        <SubHeading>How to Withdraw Your Consent</SubHeading>
        <Paragraph>
          You may withdraw your consent to receive Communications electronically
          by contacting us through Customer Support, at (800) 913-0163 or
          help@givecorporation.com, or by writing to us at &quot;Give
          Corporation, Inc., 4343 N Scottsdale Road Suite #150 Scottsdale, AZ
          85251.&quot; If you withdraw your consent to receive Communications
          electronically, Give Corporation may deny your registration for an
          Account, restrict or close your Account, or charge you additional fees
          for paper copies. close your Account, or charge you additional fees
          for paper copies.
        </Paragraph>
        <Paragraph>
          After you consent to receive Communications electronically, you may
          withdraw your consent to receive IRS Form 1099-K electronically by
          contacting us as described above. You will continue to receive all
          your other Communications electronically, but we will send your Form
          1099-Ks to you by U.S. mail.
        </Paragraph>
        <SubHeading>
          Requesting Paper Copies of Electronic Communications
        </SubHeading>
        <Paragraph>
          If, after you consent to receive Communications electronically, you
          would like a paper copy of a Communication we previously sent you, you
          may request a copy within 180 days of the date we provided the
          Communication to you by contacting us as described above. We will send
          your paper copy to you by U.S. mail. In order for us to send you paper
          copies, you must have a current street address on file in your Give
          Corporation Account. You understand and agree that Give Corporation
          may charge you an exception fee for each paper copy of a
          Communication. Give Corporation will not charge a fee if you request a
          Form 1099-K in paper form.
        </Paragraph>
        <SubHeading>Updating Your Contact Information</SubHeading>
        <Paragraph>
          It is your responsibility to keep your primary email address up to
          date. You understand and agree that if Give Corporation sends you an
          electronic Communication but you do not receive it because your
          primary email address on file is incorrect, out of date, blocked by
          your service provider, or you are otherwise unable to receive
          electronic Communications, Give Corporation will be deemed to have
          provided the Communication to you. Please note that if you use a spam
          filter that blocks or re-routes emails from senders not listed in your
          email address book, you must add Give Corporation to your email
          address book so that you will be able to receive the Communications we
          send to you.
        </Paragraph>
        <Paragraph>
          You can update your primary email address or street address at any
          time by logging into your Give Corporation Account, selecting &quot;My
          Profile&quot; from the dropdown menu that appears when you click the
          down arrow next to your name at the top right of the screen, and
          entering your new primary email address. If your email address becomes
          invalid such that electronic Communications sent to you by Give
          Corporation are returned, Give Corporation may close your account, and
          you will not be able to transact any activity using your Give
          Corporation Account until we receive a valid, functioning primary
          email address from you.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;will communicate with you electronically, unless
          you opt out. Be sure to keep the email address in your Give
          Corporation Account current.
        </Paragraph>
        <Heading>7. Prohibited Activities</Heading>
        <Paragraph>
          By registering for Give Corporation as a Merchant, you also confirm
          that you will not accept payments or use the Service in connection
          with the activities, items or services set forth below. Please contact
          activity-inquiry@ Give Corporation.com if you have questions about
          whether these categories apply to you.
        </Paragraph>
        <Table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                  paddingBottom: "10px",
                }}
              >
                <strong>Category</strong>
              </th>
              <th
                style={{
                  textAlign: "left",
                  paddingLeft: "10px",
                  paddingBottom: "10px",
                }}
              >
                <strong>Activity</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <TData>Adult</TData>
              <TData>Adult sites, content, sexual services</TData>
            </tr>
            <tr>
              <TData>Aggregation</TData>
              <TData>Payment facilitator to other merchants</TData>
            </tr>
            <tr>
              <TData>Auctions</TData>
              <TData>Internet auction, bidding fee auction</TData>
            </tr>
            <tr>
              <TData>Cash, stored value, virtual currency</TData>
              <TData>
                Cash or cash equivalent, purchase of gold, silver, platinum,
                palladium, Digital Wallet, stored value, prepaid companies,
                prepaid phone cards or Virtual currency or credits that can be
              </TData>
            </tr>
            <tr>
              <TData>Debt</TData>
              <TData>
                Bail bond services or bankruptcy lawyers, Credit counseling or
                repair services; credit protection or identity theft Damages,
                losses, penalties, or fines of any kind; alimony, child support,
                or High interest rate non-bank consumer lending, including
                payday lending and Loan payments transacted on a credit
              </TData>
            </tr>
            <tr>
              <TData>Drug</TData>
              <TData>
                Drugs or drug paraphernalia, such as hookahs Marijuana
                dispensaries and related products or services, Personal
                enhancement products or nutraceuticals - vitamins,
                Pharmaceuticals, internet pharmacies
              </TData>
            </tr>
            <tr>
              <TData>Financial services</TData>
              <TData>
                Banks, credit unions, savings and loan associates, unit trusts,
                mutual Currency exchanges or dealers Money transfer, wire
                transfers, money
              </TData>
            </tr>
            <tr>
              <TData>Gambling, lottery</TData>
              <TData>Gambling or betting, including lottery</TData>
            </tr>
            <tr>
              <TData>High Risk</TData>
              <TData>
                Airline, hotel, rental, or other miles or points, Animals and
                regulated items such as animal felts, Astrology and related
                prediction or forecasting services, psychic services Career
                placement or advice center merchants, Cyberlockers, file
                sharing, file storage, Delayed delivery merchants where the good
                or service is not shipped, Militia, armed groups, or armed
                gangs, Money back guarantees exceeding 30 days, Motor vehicle
                sales, Online help for classes, homework or assignments, Online
                personal computer technical support, Pawn shop, Political
                organizations, Products or services identified by government
                agencies to have a high risk, Social media activity, Shipping or
                forwarding brokers
              </TData>
            </tr>
            <tr>
              <TData>Illegal</TData>
              <TData>
                Counterfeit or possibly counterfeit goods, or products that
                infringe on Deceptive, unfair, or predatory practices, Hate,
                violence, racial intolerance, terrorism, the financial
                exploitation of Unlawful activities, illegal substances
              </TData>
            </tr>
            <tr>
              <TData>Investment, real estate</TData>
              <TData>
                Commodity trading or security trading; equities (including
                stocks, Crowdsourced fundraising for stock or equity, Distressed
                property sales and marketing; real estate filling Goods or
                services to be delivered more than four (4) months in the
                Timeshares, timeshare resales
              </TData>
            </tr>
            <tr>
              <TData>Marketing</TData>
              <TData>
                Buyers clubs, membership clubs Direct marketing - inbound
                telemarketing, negative option, Discount coupon merchants or
                online sites Discount medical or dental plans, including
                discount insurance Door to door sales Infomercial merchants Lead
                generation businesses Marketing activities involving &quot;pay
                only for shipping&quot; and/or &quot;free trial&quot;
                Multi-level marketing businesses, pyramid sales Outbound
                telemarketers and telecom merchants Rebate or upsell merchants
              </TData>
            </tr>
            <tr>
              <TData>Regulated</TData>
              <TData>
                Age restricted products or services, such as alcohol Firearms,
                including ammunition, and other weapons Medical devices Tobacco,
                cigarettes, e-cigarettes
              </TData>
            </tr>
            <tr>
              <TData>Telecomm</TData>
              <TData>
                Telecommunications, including wireless, cable, satellite,
                wireline etc.
              </TData>
            </tr>
            <tr>
              <TData>Travel</TData>
              <TData>
                Airlines, including charter air carriers, and cruise lines
                Travel agencies or tour operators Travel industry, including car
                rental
              </TData>
            </tr>
          </tbody>
        </Table>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You can&#39;t use Give Corporation for anything illegal,
          inappropriate, or risky. Just be honest and responsible, comply with
          the laws that apply to you, and we&#39;ll get along just fine. Failure
          to do so will result in deactivation of account and notification of
          the proper authorities.
        </Paragraph>
        <SubHeading>In addition, you may not use the Service for:</SubHeading>
        <UList>
          <ListElement>
            Impersonating any person or entity or falsely claiming an
            affiliation with any person or entity;
          </ListElement>
          <ListElement>
            Collecting, or attempting to collect, personal information about
            users or third parties without their consent, or using such
            information except as necessary to use the Service;
          </ListElement>
          <ListElement>
            Defaming, harassing, abusing, threatening, or defrauding others;
          </ListElement>
          <ListElement>
            Posting, transmitting, or distributing content that is false,
            misleading, unlawful, obscene, indecent, lewd, pornographic,
            hateful, abusive, inflammatory, or that violates the rights of
            others (including rights of publicity or privacy);
          </ListElement>
          <ListElement>
            Damaging, disabling, overburdening, or impairing Give Corporation,
            including without limitation, using the Service in an automated
            manner;
          </ListElement>
          <ListElement>
            Interfering with another user&#39;s enjoyment of the Service, by any
            means, including by uploading or otherwise disseminating viruses,
            adware, spyware, worms or other malicious code;
          </ListElement>
          <ListElement>
            Creating an Account that is linked to another Account that has
            engaged in any of the foregoing activities. Give Corporation may use
            evidence other than your Account information to determine whether
            you control an Account in someone else&#39;s name, including but not
            limited to Internet Protocol addresses, common business names, phone
            numbers, and mailing addresses
          </ListElement>
        </UList>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          If Give Corporation determines that you have received funds resulting
          from fraud or a prohibited activity, those funds may be frozen,
          returned to the Purchaser, or seized. In addition, if we reasonably
          suspect that your Account has been used for an unauthorized, illegal,
          or criminal purpose, you give us express authorization to share
          information about you, your Account, your access to the Give
          Corporation services, and any of your transactions with law
          enforcement.
        </Paragraph>
        <Heading>
          8. Give Corporation Purchaser &amp; Merchant Responsibility
        </Heading>
        <Paragraph>
          Give Corporation provides hosting and data processing services for
          Merchants. Give Corporation is a Payment Service Provider
          (&quot;PSP&quot;), not a bank, money transmitter, or Money Services
          Business (&quot;MSB&quot;), and we do not offer banking or MSB
          services as defined by the United States Department of Treasury. As a
          PSP, Give Corporation collects, analyzes and relays information
          generated in connection with payments between Purchasers and
          Merchants. You authorize Give Corporation to provide this information
          to the Bank in order for the Bank to facilitate payments from
          Purchasers to Merchants through the Networks, or the ACH, as
          applicable. As a result, the Bank - and not Give Corporation -
          actually conducts the settlement of card transactions and ACH
          transactions to Merchants. Give Corporation does not at any point
          hold, own or control funds in connection with the Services, nor does
          Give Corporation transmit money or monetary value. In connection with
          the Services, Give Corporation does not actually or constructively
          receive, take possession of or hold any money or monetary value for
          transmission, and does not advertise, solicit or hold itself out as
          receiving money for transmission. The Bank is the party with sole
          responsibility for conducting the settlement of funds between
          Purchasers and Merchants. In order to act as a PSP, Give Corporation
          must enter into agreements with the Networks, processors and the Bank.
          You are not a third-party beneficiary of these agreements. Each of the
          Networks is a third-party beneficiary of this Agreement and has
          beneficiary rights, but not obligations, and may enforce this
          Agreement against you. Some of these third parties may require a
          direct agreement with you. If you are required to enter into such an
          agreement and decline to do so, we may suspend or terminate your
          Account. You acknowledge that, if you receive payments from American
          Express exceeding the threshold specified by American Express, you
          will be converted to a direct card acceptance relationship with
          American Express and, upon conversion, you will be bound by the
          then-current American Express Card acceptance agreement and American
          Express will set the discount and other fees payable by you for
          American Express Card acceptance. You agree to be bound by the
          Merchant Services Agreement set forth in Exhibit A to this Agreement
          if it applies to you. The Merchant Services Agreement applies to you
          if, for example, you receive payments from any Network exceeding the
          threshold specified by that network. By agreement to this Agreement
          (by &quot;click through&quot; or otherwise), you also agree to the
          terms and conditions of the Merchant Services Agreement, which
          constitutes a legal binding contract between you, on the one hand, and
          the Sponsor and its designated Sponsor Bank, on the other hand,
          effective as of the date you receive the specified amount of payments
          from the Network. Furthermore, you must abide by the applicable
          Operating Regulations and ACH Rules. For example, where you accept
          payment cards on your website, you will display each card&rsquo;s logo
          with equal size and prominence, and you shall not display a preference
          for, or discriminate against, one card brand over another. If you are
          using the Give Corporation subscriptions feature, you agree that it is
          your responsibility to comply with applicable laws, including the
          Electronic Funds Transfer Act (Regulation E), including by capturing
          your customers&rsquo; agreement to be billed on a recurring basis. You
          may not resell any hardware provided to you by Give Corporation or a
          third party for use with the Service.
        </Paragraph>
        <Paragraph>
          Give Corporation is not responsible for any fraudulent or criminal
          activity involving your merchant account. Should you and/or any of
          your verified admins (team members you have invited into your
          account), and/or purchasers (customers, donors, etc.) be found liable
          for ANY AND ALL activity that violates ANY AND ALL of the Give
          Corporation Terms and/or the mismanagement of funds; Give Corporation
          will notify all relevant parties (Banking entities, Card Issuers, IRS,
          Federal Government, Collection Agencies, Local Law Enforcement, Give
          Corporation Customers, etc.) of your behavior, and Give Corporation
          will seek full restitution and recommend maximum legal punishment.
          Processing fraud, bank fraud, and check fraud all have fines of up to
          $1,000,000 and up to 20 years in prison.
        </Paragraph>
        <Paragraph>
          Notwithstanding Give Corporation&rsquo;s assistance in understanding
          the Operating Regulations, and ACH Rules, you expressly acknowledge
          and agree that you are assuming the risk of compliance with all
          provisions of the Operating Regulations, and ACH Rules, regardless of
          whether you have possession of those provisions. The Networks make
          excerpts of their respective Operating Regulations available on their
          websites (including{" "}
          <a href="https://www.google.com/url?q=http://usa.visa.com/&;sa=D&;source=editors&;ust=1686838707691365&;usg=AOvVaw0266Kw0yIckHm95cWv1EXK">
            usa.visa.com
          </a>
          ,
          <a href="https://www.google.com/url?q=http://www.mastercard.com/&;sa=D&;source=editors&;ust=1686838707691787&;usg=AOvVaw3rKnXPNV4mWgHRI1UOismd">
            www.mastercard.com
          </a>
          ,
          <a href="https://www.google.com/url?q=http://www.americanexpress.com/&;sa=D&;source=editors&;ust=1686838707692070&;usg=AOvVaw09HoGaAQfLG5Pmt0fHU8Z6">
            www.americanexpress.com
          </a>
          &nbsp;and{" "}
          <a href="https://www.google.com/url?q=http://www.discover.com/&;sa=D&;source=editors&;ust=1686838707692285&;usg=AOvVaw12RzJS3PkdPX3YD5k8we8W">
            www.discover.com
          </a>
          ). Exhibit B to this Agreement sets forth certain specific
          requirements of the American Express Merchant Regulations, US. We can
          provide you with excerpted provisions of the ACH Rules upon your
          request.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;is a payment service provider, not a money
          services business. This means Give Corporation never has any control
          or ownership over your money. We may require you to enter into a
          separate agreement with one or more of our partners. You&#39;re also
          required to abide by the rules of the payment card associations.
          <br />
          <br />
          You will get in a lot of trouble if you violate the terms and services
          agreement. And Give Corporation is not responsible for you or your
          customers&#39; actions.
        </Paragraph>
        <Heading>9. Your ACH Payment Authorization</Heading>
        <Paragraph>
          If you are a Merchant or if you are a Purchaser paying by bank
          transfer, you authorize the Bank to initiate electronic ACH entries to
          each bank account that you set up on the Website and to initiate
          adjustments for any transactions credited or debited in error. You
          agree to be bound by the ACH Rules, and you agree that all ACH
          transactions that you initiate will comply with all applicable laws.
          Your authorization will remain in full force and effect until you
          notify us that you revoke it by contacting Customer Support at
          help@givecorporation.com or by closing your Account. You understand
          that Give Corporation requires a reasonable time to act on your
          revocation, not to exceed five (5) business days. If you are a
          Merchant, you authorize the Bank to hold, receive, disburse and settle
          funds on your behalf. Your authorization permits the Bank to generate
          a paper draft or electronic funds transfer to process each payment
          transaction that you authorize. Subject to this Agreement, you also
          authorize the Bank to debit or credit any payment card or other
          payment method we accept that you link to your Account, to initiate
          adjustments for any transactions credited or debited in error, as well
          as for chargebacks, reversals, or claims in accordance with this
          Agreement and the Operating Regulations. If you are a Purchaser paying
          by credit card and your credit card account number changes or your
          credit card expiration date changes, we may acquire that information
          from our financial services partner and update your Account.
        </Paragraph>
        <Paragraph>
          When you set up a bank account on the Website and input your online
          banking credentials for instant verification, you are granting Give
          Corporation (acting on your behalf) the right, power and authority to
          access and transmit your personal and financial information (such as
          from third party banks) as reasonably necessary to provide the ACH
          authorization services described at plaid.com. Additional terms
          applicable to your use of the Give Corporation services provided
          through Give Corporation are set forth at
          https://www.plaid.com/legal/longtail/ #long-tail (specifically,
          Section 13 (&ldquo;Developer Applicatione Minimum End User
          Terms),&rdquo; of Exhibit B, &ldquo;Additional Terms and Conditions
          for Intuit Developer Services,&rdquo; and Schedule B (,
          &ldquo;Developer Application Minimum End User License Terms),&rdquo;
          of Exhibit B, &ldquo;Customer Account Data API&rdquo;).
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          By accepting the terms of this Agreement, you authorize us to provide
          payment instructions to the Bank, and for the Bank to process payments
          on your behalf.
        </Paragraph>
        <Heading>10. Accepted Forms Of Payment</Heading>
        <Paragraph>
          The Service supports most domestic credit, debit, prepaid or gift
          cards with a Visa, MasterCard, American Express or Discover logo. In
          addition, the Service supports most international cards with these
          logos. We may add or remove support for certain payment cards at any
          time without prior notice. We may elect only to process cards that
          receive an authorization from the applicable issuer. You agree to
          accept all of the cards issued by Networks that the Service supports
          in accordance with the terms of this Agreement. The Service also
          supports payments via ACH from U.S.-based Purchasers with a U.S. bank
          account to U.S.-based Merchants, if supported by the Platform. We may
          conduct verification checks on Purchasers to ensure account ownership
          and sufficient balance, and we may refuse to process payments from
          Purchasers at our discretion. The Service supports payments via Apple
          Pay to Merchants in the U.S., if supported by the Platform. The Apple
          Pay Web Merchant Terms and Conditions apply.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          We support most U.S. credit cards and U.S. bank accounts.
        </Paragraph>
        <Heading>11. Underwriting and Sharing Information</Heading>
        <Paragraph>
          We may share some or all of the information about you and your
          transactions with our processor, the Networks, and our other partners
          (and their respective affiliates, agents, subcontractors, and
          employees), who may use this information to perform their obligations
          under their agreements with Give Corporation, to operate and promote
          their respective networks, to perform analytics and create reports, to
          prevent fraud, and for any other lawful purpose. American Express and
          other Networks may use your name, address, and website address (URL)
          in any media form from time to time. At any time, Give Corporation,
          its processor or its other partners may conclude that you will not be
          permitted to use Give Corporation.
        </Paragraph>
        <Paragraph>
          You agree that Give Corporation is permitted to contact and share
          information about you and your Account with banks and other financial
          institutions. This includes sharing information (a) about your
          transactions for regulatory or compliance purposes, (b) for use in
          connection with the management and maintenance of the Service, (c) to
          create and update their customer records about you and to assist them
          in better serving you, and (d) to conduct Give Corporation&rsquo;s
          risk management process.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;may request and share information about you with
          our processor, banks and other financial institutions, and government
          agencies.
        </Paragraph>
        <Heading>12. Transactions</Heading>
        <Paragraph>
          Transactions are indicated as Pending, Complete, Failed or Canceled.
        </Paragraph>
        <Paragraph>
          &quot;Pending&quot; indicates that a transaction is currently
          undergoing review by the Bank and may not be completed or settled, at
          the Bank&#39;s sole discretion. &quot;Pending&quot; may also indicate
          that the transaction is awaiting account confirmation or settlement
          from a third party.
        </Paragraph>
        <Paragraph>
          &quot;Completed&quot; indicates that a transaction has cleared and the
          funds are available in the Merchant&#39;s Account. If Give Corporation
          determines that the transaction is related to a restricted or
          prohibited business or activity or that the transaction is the result
          of fraudulent activity, Give Corporation may reverse or refund the
          transaction at any time.
        </Paragraph>
        <Paragraph>
          &quot;Canceled&quot; indicates that a transaction has been stopped or
          reversed. This could be due to inaccurate or incomplete information
          about your Account, our inability to validate the legitimacy of a
          payment, Purchaser, or Merchant, or another proprietary reason. You
          may contact Give Corporation Support for more information. Although
          payments are cancelled, the removal of an authorization on a Purchaser
          credit card or a return of funds to a Purchaser bank account may not
          be immediate, and Give Corporation cannot guarantee availability
          within a specific timeframe.
        </Paragraph>
        <Paragraph>
          &quot;Failed&quot; indicates that a transaction could not be processed
          due to inaccurate Account information, insufficient funds, or another
          transaction-related reason.
        </Paragraph>
        <Paragraph>
          Upon the release of transaction information by Give Corporation, a
          Purchaser will be debited or charged by the Bank. The Merchant agrees
          that the Purchaser&#39;s obligation to the Merchant is treated as paid
          at the time of (i) the release of this transaction information when a
          transaction enters the &quot;Completed&quot; state; and (ii) the
          related initiation of processing by the Bank. After the initiation of
          processing by the Bank, Merchant agrees not to attempt to collect or
          otherwise seek payment from the Purchaser, because Merchant agrees
          Purchaser&#39;s obligation to the Merchant has been conclusively
          discharged. Merchant agrees that the Purchaser is a third-party
          beneficiary of the preceding two sentences. Transactions may stay in a
          &quot;Pending&quot; state before &quot;Completed&quot; if Give
          Corporation is reviewing a transaction for risk purposes. Transactions
          in this state should not be treated as paid until they are
          &quot;Completed&quot;.
        </Paragraph>
        <Paragraph>
          Transactions may be disputed at any time up to 90 days from the date
          of transaction, regardless of state, by the Purchaser. Disputes
          resolved in favor of the Purchaser may result in reversal of the
          disputed transaction, regardless of state.
        </Paragraph>
        <Paragraph>
          Give Corporation reserves the right to limit or restrict transaction
          size or volume at any time. If you would like to increase your limits,
          please contact Customer Support. Upon receiving this request, Give
          Corporation will conduct a review of your Account, and decide whether
          to lift or remove these limits. Give Corporation will consider a
          variety of factors in making this decision and will make this
          determination at its sole discretion.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;payment transactions have various states. They
          can be reversed or cancelled at any time due to fraud or chargebacks.
          Give Corporation may impose transaction limits at any time.
        </Paragraph>
        <Heading>13. Payouts and Withdrawals</Heading>
        <Paragraph>
          <strong>Direct Deposit Account</strong>
          <br />
          To receive funds, you will be required to provide a standard bank
          account with a recognized, U.S.-based financial institution. To enable
          your bank account to receive funds disbursements you will be required
          to verify your bank account with a voided check and/or bank statement.
          Give Corporation will validate the authenticity of the submitted
          documents with the issuing financial institution and/or use
          third-party service providers to verify bank account information.
          <br />
          <br />
          Payout availability depends on several factors, such as holidays,
          volume, and the risks involved. When you start processing live
          payments from your customers with Give Corporation, you won&rsquo;t
          receive your first payout until a minimum, 7&ndash;14 days after
          receiving your first successful payment.
          <br />
          <br />
          The first payout usually takes a little longer to establish the
          security factors regarding your account. Processing subsequent payouts
          then happen according to your desired money transfer scheduling.
          <br />
        </Paragraph>
        <Paragraph>
          <strong>Changing Your Direct Deposit Account</strong>
          <br />
          To change your direct deposit account you must submit a new bank
          account request, following the same procedure as stated above.
        </Paragraph>
        <Paragraph>
          <strong>
            Funds Disbursement
            <br />
          </strong>
          The Bank will disburse funds to the Merchant&#39;s bank upon the
          Merchants request ad-hoc settlement at any time, up to 4 times per
          month (unless Merchant initiates In-Platform Commerce to increase
          withdrawals), by ACH transfer. The Merchant may be subject to any
          &quot;Reserve&quot; imposed under Section 14 below. If the Bank cannot
          transfer the funds to the Merchant&#39;s bank account (due to
          inaccurate or obsolete bank account information entered by the
          Merchant, or for any other reason), Give Corporation may refund the
          funds to the Purchaser or Donor or escheat them pursuant to Section 23
          below. Neither the Bank, Give Corporation, nor the Purchaser will have
          any liability to Merchant for funds so refunded or escheated.
        </Paragraph>
        <Paragraph>
          Settlements to a bank account may be limited or delayed based on your
          perceived risk and history with Give Corporation. If you would like to
          request an increase to your settlement limit, please contact Give
          Corporation Support{" "}
          <a href="mailto:support@givecorporation.com">
            support@givecorporation.com
          </a>
          . Upon receiving this request, Give Corporation will conduct a review
          of your account. Give Corporation will consider a variety of factors
          in making this decision and will make this determination at its sole
          discretion.
        </Paragraph>
        <Paragraph>
          Should Give Corporation need to conduct an investigation or resolve
          any pending dispute related to your Account, the Bank may defer payout
          or restrict access to your funds for the entire time it takes us to do
          so. The Bank may also defer payout or restrict access to your funds as
          required by law or court order, or if otherwise requested by law
          enforcement or governmental entity.
        </Paragraph>
        <Paragraph>
          Furthermore, if Give Corporation or the Bank suspects future
          chargebacks or disputes as a result of transactions to your Account,
          the Bank may defer payout and/or restrict access to your funds until
          Give Corporation or the Bank reasonably believes, in their sole
          discretion, that the risk of receiving a chargeback or dispute has
          passed.
        </Paragraph>
        <Paragraph>
          All settlements to Merchants are subject to review for risk and
          compliance purposes and can be delayed or postponed at Give
          Corporation&rsquo;s sole discretion.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;will send you your money as soon as possible,
          upon request, but a certain amount of your funds may be subject to an
          additional hold period depending on your perceived risk and
          transaction history. You can request that these limitations be
          reviewed at any time by contacting Customer Support.
        </Paragraph>
        <Heading>14. Reserve</Heading>
        <Paragraph>
          At any time and from time to time, the Bank may temporarily suspend or
          delay payments to you and/or designate an amount of funds that the
          Bank must maintain in your Account (&quot;Reserve&quot;) to secure the
          performance of your payment obligations under this Agreement. We may
          require a Reserve for any reason, including high chargeback risk or
          indications of performance problems related to your use of the
          Service.
        </Paragraph>
        <Paragraph>
          The Reserve will be in an amount as reasonably determined by us to
          cover anticipated chargebacks, returns, unshipped merchandise and/or
          unfulfilled services or credit risk based on your processing history
          or such amount designated by our processor. The Reserve may be raised,
          reduced or removed at any time by Give Corporation, in its sole
          discretion, based on your payment history, a credit review, the amount
          of any arbitration award or court judgment against you in Give
          Corporation&rsquo;s favor, or otherwise as Give Corporation or its
          processor or the Bank may determine or require. If you do not have
          sufficient funds in your Reserve, the Bank may fund the Reserve from
          any funding source associated with your Account, or from any other
          Account under your control or any funding source associated with such
          other Account, including but not limited to any funds (a) credited to
          your Account, (b) due to you under this Agreement, or (c) available in
          your bank account, or other payment instrument registered with us.
        </Paragraph>
        <Paragraph>
          You grant us a security interest in and lien on any and all funds held
          in any Reserve, and also authorize us to make any withdrawals or
          debits from the Reserve, without prior notice to you, to collect
          amounts that you owe us under this Agreement, including without
          limitation for any reversals of deposits or transfers made to your
          Account. You will execute any additional documentation required for us
          to perfect our security interest in any funds in the Reserve. This
          security interest survives for as long as we hold funds in your
          Reserve; however, it does not apply to any funds for which the grant
          of a security interest would be prohibited by law. You irrevocably
          assign to us all rights and legal interests to any interest or other
          earnings that accrue or are attributable to your Reserve.
        </Paragraph>
        <Paragraph>
          At no time, for any reason, is your Give Corporation Merchant account
          balance allowed to be negative. Should your account be negative for
          greater than a 24 hour period, your ability to process, manage, and
          transfer funds may be suspended. An investigation may commence to
          determine whether bank fraud has been committed.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          We may require a Reserve to ensure that you can cover chargebacks,
          returns, reversals, and other obligations under this Agreement.
          <br />
          <br />A negative balance may be considered bank fraud. If your account
          goes into a negative balance please contact{" "}
          <a href="mailto:support@givecorporation.com">
            support@givecorporation.com
          </a>
          &nbsp;immediately to seek resolution.
        </Paragraph>
        <Heading>15. Accounts</Heading>
        <Paragraph>
          You are not required to have a credit in your Account in order to use
          the Service. When you have a credit, your funds will be co-mingled and
          held by the Bank with other Merchants&#39; funds in one or more pooled
          accounts at the Bank that are established in the Bank&#39;s name for
          the benefit of you and other users. The Bank has sole discretion over
          the establishment and maintenance of any pooled account. Give
          Corporation has no ownership or control, and no right, title or
          interest in any account in which Merchant funds are held.
        </Paragraph>
        <Paragraph>
          The Bank will hold any funds associated with your Account in an
          account separate from any account used for Give Corporation&rsquo;s
          corporate funds. Give Corporation will not and cannot use your funds
          for our corporate purposes (including the granting of any security or
          similar interest), will not voluntarily make funds available to our
          creditors in the event of bankruptcy or for any other purpose, and
          will not knowingly permit our creditors to attach the funds. You will
          not receive interest or any other earnings on any funds that the Bank
          holds for you. As consideration for using the Service, you irrevocably
          assign to us or the Bank all rights and legal interests to any
          interest and/or other earnings or benefits that may accrue or are
          attributable to the Bank holding your funds in a pooled account.
        </Paragraph>
        <Paragraph>
          If your Account is negative for an extended period of time (as defined
          by Give Corporation in its sole discretion), the Bank may close your
          Account and we may pursue legal action or other collection efforts.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Funds held by the Bank on your behalf are protected prior to the Bank
          transferring the funds to your bank account. At no point does Give
          Corporation own your funds.
        </Paragraph>
        <Heading>16. Cardholder Data Security</Heading>
        <Paragraph>
          &quot;Cardholder Data&quot; is information associated with a payment
          card, such as account number, expiration date, and CVV2. Give
          Corporation is a validated PCI Level 1 Service Provider and so is
          qualified to handle Cardholder Data in connection with the Service.
          Give Corporation will maintain all applicable PCI DSS requirements to
          the extent Give Corporation possesses or otherwise stores, processes,
          or transmits cardholder data on your behalf, or to the extent Give
          Corporation could impact the security of your cardholder data
          environment.
        </Paragraph>
        <Paragraph>
          If you handle, transmit, or store any Cardholder Data in connection
          with your use of the Give Corporation Service or the Give Corporation
          API, you agree to comply at all times with the Payment Card Industry
          Data Security Standards (&quot;PCI DSS&quot;). Further, you agree to
          certify such compliance and provide documentation in accordance with
          Operating Regulations, or when asked by Give Corporation to do so. You
          also agree that you will use only PCI compliant service providers in
          connection with the storage, processing, or transmission of Cardholder
          Data. You will remove Cardholder Data from your systems, and any other
          place where you store it, as soon as practicable and in no event more
          than 24 hours after you receive an authorization decision.
        </Paragraph>
        <Paragraph>
          You are fully responsible for the security of data (including but not
          limited to Cardholder Data) on your website or otherwise in your
          possession or control. You agree to comply with all applicable laws,
          Operating Regulations, and rules in connection with your collection,
          security and dissemination of any personal, financial, or transaction
          information.
        </Paragraph>
        <Paragraph>
          Unless you receive the express consent of your customer, you may not
          retain, track, monitor, store, disclose or otherwise use data (e.g. to
          send any marketing or promotional materials to Purchaser) except for
          the transaction for which it was given and any post-transaction
          activities in connection with such immediate transaction (e.g.
          chargeback).
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          In most cases, we take care of PCI Compliance for you. If, however,
          you handle or transmit Cardholder Data, you are required to be PCI
          compliant.
        </Paragraph>
        <Heading>17. Taxes</Heading>
        <Paragraph>
          You are responsible for determining any and all taxes assessed,
          incurred, or required to be collected, paid, or withheld, in
          connection with your use of the Service. You are solely responsible
          for collecting, withholding, reporting and remitting any taxes to the
          appropriate tax authority. Give Corporation is not obligated to, and
          will not, determine whether taxes apply, or calculate, collect, report
          or remit any taxes to any tax authority arising from your use of the
          Service.
        </Paragraph>
        <Paragraph>
          Give Corporation or the Bank may have tax reporting responsibilities
          in connection with the Service. For example, Give Corporation or the
          Bank will report to the Internal Revenue Service (&quot;IRS&quot;) on
          Form 1099-K as required by law, your name, address, Tax Identification
          Number (such as Employment Identification Number or Social Security
          Number), the total dollar amount of the payments you receive in a
          calendar year, and the total dollar amount of the payments you receive
          for each month in a calendar year.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You are responsible for collecting and paying your taxes, not Give
          Corporation.
        </Paragraph>
        <Heading>18. Customer Service Provided by Merchants</Heading>
        <Paragraph>
          You are solely responsible for all customer service issues relating to
          your goods or services, including pricing, order fulfillment, order
          cancellation by you or the customer, returns, refunds and adjustments,
          rebates, functionality and warranty, technical support and feedback
          concerning experiences with your personnel, policies or processes. In
          performing customer service, you will always present yourself as a
          separate entity from Give Corporation. You will cooperate with Give
          Corporation and your API application to assure that Purchasers have
          access to clear customer service information, including an active
          customer service email address and telephone number.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You are solely responsible for all customer service issues.
        </Paragraph>
        <Heading>19. Refunds and Returns Provided by Merchants</Heading>
        <Paragraph>
          You agree to process returns of, and provide refunds and adjustments
          for goods or services through your Give Corporation Account in
          accordance with this Agreement and the Operating Regulations and ACH
          Rules. The Operating Regulations require that you will (a) maintain a
          fair return, cancellation or adjustment policy; (b) disclose your
          return or cancellation policy to Purchasers at the time of purchase,
          (c) not give cash refunds to a Purchaser in connection with a payment
          card sale, unless required by law, and (d) not accept cash or any
          other item of value for preparing a payment card sale refund. Your
          refund policies must be the same for all payment methods. If your
          Purchaser is dissatisfied with your refund policy, the Purchaser may
          chargeback the payment. You may not bill or collect from any Purchaser
          for any purchase or payment by means of a card unless the Purchaser
          has exercised chargeback, you have fully paid for the charge, and you
          otherwise have the right to do so.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You&#39;re responsible for disclosing your refund policy and providing
          refunds for goods or services purchased through Give Corporation.
        </Paragraph>
        <Heading>
          20. Merchant&#39;s Financial Responsibility and Liability for
          Chargebacks, Refunds, and Returns
        </Heading>
        <Paragraph>
          The amount of a payment may be charged back to you if (a) it is
          disputed by a Purchaser, (b) it is reversed for any reason, (c) it was
          not authorized or we have any reason to believe that the transaction
          was not authorized, or (d) it is unlawful, suspicious, or in violation
          of the terms of this Agreement. You are responsible for all
          chargebacks, whether or not the chargeback complies with the Operating
          Regulations.
        </Paragraph>
        <Paragraph>
          By law, your purchasers (Donors, Customers, etc.) have 180 days to
          request refunds and returns from your merchant account. Also, the
          banking policy states that your purchasers have 180 days to issue a
          chargeback. You are responsible for ensuring that you have monies
          available if your Purchaser requests a refund or return or issues a
          chargeback.
          <br />
          <br />
          If you have withdrawn the monies from your Give Corporation merchant
          account, Give Corporation will attempt to recoup the funds from your
          connected commercial bank account. If the funds cannot be recouped
          within ten business days, you may be held liable for bank fraud.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You are liable for any chargebacks, refunds, and/or returns resulting
          in a negative balance.
          <br />
          <br />
          Please keep a reserve in your Give Corporation available balance in
          case your purchasers want it back. It&#39;s their legal right to ask
          for a refund. Having a negative balance may be viewed as bank fraud
          and instigate a criminal investigation.
        </Paragraph>
        <Heading>21. How Give Corporation Handles Chargebacks</Heading>
        <Paragraph>
          You owe us and will immediately pay us the amount of any chargeback
          and any associated Fees, fines, or penalties assessed by the Bank, our
          processor or the Networks. If you do not have sufficient funds in your
          Account, we will have the remedies set forth in &quot;Our Set-off and
          Collection Rights&quot; below. If you have pending chargebacks, the
          Bank may delay payouts to you.
        </Paragraph>
        <Paragraph>
          Further, if we reasonably believe that a chargeback is likely with
          respect to any transaction, the Bank may withhold the amount of the
          potential chargeback from payments otherwise due to you under this
          Agreement until such time that: (a) a chargeback is assessed due to a
          Purchaser&#39;s complaint, in which case the Bank will retain and
          refund the funds; (b) the period of time under applicable law or
          regulation by which the Purchaser may dispute that the transaction has
          expired; or (c) we determine that a chargeback on the transaction will
          not occur.
        </Paragraph>
        <Paragraph>
          If we determine that you are incurring an excessive amount of
          Chargebacks, Give Corporation or the Bank may establish controls or
          conditions governing your Account, including without limitation, by
          (a) assessing additional Fees, (b) creating a Reserve in an amount
          reasonably determined by us to cover anticipated chargebacks and
          related fees, (c) delaying payouts, and (d) terminating or suspending
          the Service or closing your Account.
        </Paragraph>
        <Paragraph>
          You agree to assist us when requested, at your expense, to investigate
          any of your transactions processed through the Service. To that end,
          you permit us to share information about a chargeback with the
          Purchaser, the Purchaser&#39;s financial institution, and your
          financial institution in order to investigate and/or mediate a
          chargeback. We will request necessary information from you to contest
          the chargeback. If the chargeback is contested successfully, we will
          release the reserved funds to you. If a chargeback dispute is not
          resolved in your favor by the Networks or issuing bank or you choose
          not to contest the chargeback, we may recover the chargeback amount
          and any associated fees as described in this Agreement. You
          acknowledge that your failure to assist us in a timely manner when
          investigating a transaction, including providing necessary
          documentation within seven (7) days of our request, may result in an
          irreversible chargeback. We reserve the right, upon notice to you, to
          charge a fee for mediating and/or investigating chargeback disputes.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          If you receive a chargeback, we&#39;ll debit your Account or bank
          account for the amount of the chargeback plus any applicable fees. We
          may also place a Reserve on your Account to fund potential future
          chargebacks associated with your Account.
        </Paragraph>
        <Heading>22. Our Set-off and Collection Rights</Heading>
        <Paragraph>
          To the extent permitted by law, the Bank may set off any obligation
          you owe us under this Agreement (including chargebacks) against any
          credit in your Account or against any amounts due to you. All Fees are
          deducted first from the transferred or collected funds and thereafter
          from your Account. If you do not have sufficient funds, the Bank may
          collect from any funding source associated with your Account, or from
          any other Account under your control, or from any funding source
          associated with such other Account, including but not limited to any
          funds (a) deposited by you, (b) due to you under this Agreement, or
          (c) available in your bank account, or other payment instrument
          registered with the Bank. Your failure to pay in full amounts that you
          owe us on demand will be a breach of this Agreement. You will be
          liable for our costs associated with collection in addition to the
          amount owed, including without limitation attorneys&#39; fees and
          expenses, collection agency fees, and interest at the lesser of one-
          and-one-half percent (1-1/2%) per month or the highest rate permitted
          by law. In its discretion, Give Corporation may make appropriate
          reports to credit reporting agencies and law enforcement authorities,
          and cooperate with them in any resulting investigation or prosecution.
          You hereby expressly agree that all communication in relation to
          delinquent accounts will be made by electronic mail or by phone, as
          provided by you to Give Corporation. Such communication may be made by
          Give Corporation or by anyone on its behalf, including but not limited
          to a third party collection agent.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          If you owe us money, the Bank may deduct that money from your incoming
          payments or debit your bank account or credit card on file.
        </Paragraph>
        <Heading>23. Dormant Accounts</Heading>
        <Paragraph>
          If there is no activity in your Account (such as a login or
          disbursement) for the period of time set forth in the applicable
          unclaimed property laws, and you have a balance, we may notify you by
          sending an email to your registered email address. We may also notify
          you by U.S. mail. We will give you the option of keeping your Account
          open, withdrawing the funds, or requesting a check. If you do not
          respond to our notice within the time period specified in the notice,
          we may close your Account and escheat your funds (less a processing
          fee) in accordance with applicable law.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          If there is no activity in your Give Corporation Account for a long
          time, we will notify you of your options.
        </Paragraph>
        <Heading>24. Receipts and Account Reconciliation</Heading>
        <Paragraph>
          When you receive a payment, we will update your Account and provide a
          transaction confirmation on our Website. This confirmation will serve
          as your receipt. Summaries of your Account activity are available on
          our Website. You should make archival copies of your Give Corporation
          Account data regularly. Except as required by law, you are solely
          responsible for (a) compiling and retaining permanent records of all
          transactions and other data, and (b) reconciling all transaction
          information that is associated with your Give Corporation Account. If
          you believe that there is an error or unauthorized transaction
          activity associated with your Give Corporation Account, please contact
          Customer Support immediately.
        </Paragraph>
        <Paragraph>
          If you make a subscription payment, you will receive an email
          confirmation with a link that enables you to terminate future
          payments, if you wish. Alternatively, if you have an Account, you may
          log in at any time to terminate subscription payments.
        </Paragraph>
        <Paragraph>
          We will attempt to correct processing errors that we discover by
          instructing the Bank to debit or credit your Account. Give Corporation
          will only correct transactions that are processed incorrectly if and
          when you notify us of such an error in a timely fashion.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You can see your full transaction history by logging into Give
          Corporation at any time. If you notice an error, you must notify us
          within 30 days, or you waive your right to have that error rectified.
        </Paragraph>
        <Heading>25. Privacy</Heading>
        <Paragraph>
          Your privacy is very important to us. By accepting this Agreement, you
          confirm that you have read, understood and accepted our{" "}
          <a href="https://www.google.com/url?q=https://givebox.com/privacy&;sa=D&;source=editors&;ust=1686838707701418&;usg=AOvVaw1T5MSOYvFgXr61priRWsJM">
            Privacy Policy
          </a>
          .
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          By accepting this Agreement, you agree to Give Corporation&#39;s{" "}
          <a href="https://www.google.com/url?q=https://givebox.com/privacy&;sa=D&;source=editors&;ust=1686838707701971&;usg=AOvVaw0zItgElvEXw1Ief14oXTC2">
            Privacy Policy
          </a>
          .
        </Paragraph>
        <Heading>26. Security</Heading>
        <Paragraph>
          We have implemented technical and organizational measures designed to
          secure your personal information from accidental loss and from
          unauthorized access, use, alteration or disclosure. However, we cannot
          guarantee that unauthorized third parties will never be able to defeat
          those measures or use your personal information for improper purposes.
          You acknowledge that you provide your personal information at your own
          risk. If you discover a security-related issue, you agree to inform us
          of the issue immediately by contacting the Give Corporation Security
          Team at{" "}
          <a href="mailto:security@givecorporation.com">
            security@givecorporation.com
          </a>
          . You also agree not to disclose the issue until Give Corporation has
          addressed it.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          We take great measures to protect your information. However,
          that&#39;s not always possible. You acknowledge that you provide
          personal information at your own risk.
        </Paragraph>
        <Heading>27. Termination</Heading>
        <Paragraph>
          If your Give Corporation Account is terminated for any reason or no
          reason, you agree: (a) to continue to be bound by this Agreement, (b)
          to immediately stop using the Service and to remove all card logos
          from your website and wherever else they are displayed, (c) that the
          license provided under this Agreement shall end, (d) that we reserve
          the right (but have no obligation) to delete all of your information
          and Account data stored on our servers, and (e) that Give Corporation
          shall not be liable to you or any third party for termination of
          access to the Service, deletion of your information or Account data,
          or export of your information or Account data.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You should understand the consequences of termination of your Give
          Corporation Account.
        </Paragraph>
        <Heading>28. Your Right to Terminate</Heading>
        <Paragraph>
          You may terminate this Agreement by closing your Give Corporation
          Account at any time. When you close your Give Corporation Account, any
          pending transactions will be cancelled. Any funds that the Bank
          holding in custody for you at the time of closure, less any applicable
          Fees, will be paid out to you according to your payout schedule,
          assuming all payout-related authentication requirements have been
          fulfilled (for example, you may not close your Give Corporation
          Account as a means of evading your payout schedule). If an
          investigation is pending at the time you close your Give Corporation
          Account, the Bank may hold your funds as described herein. If you are
          later determined to be entitled to some or all of the funds in
          dispute, the Bank will release those funds to you.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          If you close your Give Corporation Account, the Bank will send you
          your money, but it may be subject to limitations or hold periods to
          ensure that you can fulfill your obligations.
        </Paragraph>
        <Heading>29. Our Right to Terminate</Heading>
        <Paragraph>
          We may terminate this Agreement and close your Give Corporation
          Account for any reason or no reason at any time upon notice to you. We
          may also suspend the Service and instruct the Bank to suspend access
          to your Give Corporation Account (including the funds in your Give
          Corporation Account) if you (a) have violated the terms of the Give
          Corporation&#39;s policies or this Agreement, (b) pose an unacceptable
          credit or fraud risk to us, or (c) provide any false, incomplete,
          inaccurate, or misleading information or otherwise engage in
          fraudulent or illegal conduct.
        </Paragraph>
        <Paragraph>
          In addition to the above, we reserve the right to terminate your
          account and withhold all funds if you do not respond within 30 days to
          any Give Corporation request for information regarding any of the
          types of requests listed below and/or any other request for
          information in regards to potential financial risk, security breach or
          otherwise to Give Corporation and/or any of Give Corporation&#39;s
          affiliated partners.
        </Paragraph>
        <UList>
          <ListElement>KYC (Know Your Customer)</ListElement>
          <ListElement>Underwriting</ListElement>
          <ListElement>Risk</ListElement>
          <ListElement>Compliance</ListElement>
          <ListElement>Identity</ListElement>
          <ListElement>Security</ListElement>
          <ListElement>Fraud</ListElement>
          <ListElement>AML (Anti-Money Laundering)</ListElement>
          <ListElement>OFAC list</ListElement>
          <ListElement>Business/Legal Entity</ListElement>
          <ListElement>Owner/Principal Information</ListElement>
          <ListElement>Bank Account</ListElement>
          <ListElement>IRS/Taxes</ListElement>
          <ListElement>Terms</ListElement>
        </UList>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          We may terminate this Agreement and close your Account for any reason
          or no reason at any time, but we&#39;ll always keep you informed. If
          you do not respond to a Give Corporation request within 30 days we may
          terminate your account and withhold all funds.
        </Paragraph>
        <Heading>30. Effect of Termination</Heading>
        <Paragraph>
          We will not be liable to you for compensation, reimbursement, or
          damages in connection with any termination or suspension of the
          Service. Any termination of this Agreement does not relieve you of any
          obligations to pay any Fees or costs accrued prior to the termination
          and any other amounts owed by you to us as provided in this Agreement.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Termination of this Agreement does not relieve you of any obligations
          to pay any amounts that you owe.
        </Paragraph>
        <Heading>31. Your License</Heading>
        <Paragraph>
          Give Corporation grants you a personal, limited, non-exclusive,
          revocable, non-transferable license, without the right to sublicense,
          to electronically access and use the Service solely to accept and
          receive payments and to manage the funds you so receive. You will be
          entitled to download updates to the Service, subject to any additional
          terms made known to you at that time, when Give Corporation makes
          these updates available.
        </Paragraph>
        <Paragraph>
          You may not, nor may you permit any third party to, do any of the
          following: (i) access or monitor any material or information on any
          Give Corporation system using any manual process or robot, spider,
          scraper, or other automated means unless you have separately executed
          a written agreement with Give Corporation referencing this section
          that expressly grants you an exception to this prohibition; (ii) copy,
          reproduce, alter, modify, create derivative works, publicly display,
          republish, upload, post, transmit, resell or distribute in any way
          material or information from Give Corporation; (iii) permit any third
          party to use and benefit from the Service via a rental, lease,
          timesharing, service bureau or other arrangement; (iv) transfer any
          rights granted to you under this Agreement; (v) violate the
          restrictions in any robot exclusion headers on the Service, work
          around, bypass, or circumvent any of the technical limitations of the
          Service, use any tool to enable features or functionalities that are
          otherwise disabled in the Service, or decompile, disassemble or
          otherwise reverse engineer the Service, except to the extent that such
          restriction is expressly prohibited by law; (vi) perform or attempt to
          perform any actions that would interfere with the proper working of
          the Service, prevent access to or use of the Service by our other
          users, or impose an unreasonable or disproportionately large load on
          our infrastructure; or (vii) otherwise use the Service except as
          expressly allowed under this section. You may not use the Service on a
          mobile device that is &quot;jail broken&quot; or otherwise modified
          contrary to the manufacturer&#39;s software or hardware guidelines.
          Your use of the Service may be subject to the terms of your agreements
          with your mobile device manufacturer and your carrier.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;grants you a license to electronically access
          and use the Service solely to accept and receive payments and to
          manage the funds you receive. We want you to enjoy this Service, but
          there are a few things you can&#39;t do or ask others to do.
        </Paragraph>
        <Heading>
          32. Give Corporation&#39;s Intellectual Property Rights
        </Heading>
        <Paragraph>
          The Service is licensed and not sold. Give Corporation reserves all
          rights not expressly granted to you in this Agreement. The Service is
          protected by copyright, trade secret and other intellectual property
          laws. Give Corporation owns the title, copyright and other worldwide
          intellectual property rights in the Service and all copies of the
          Service. This Agreement does not grant you any rights to Give
          Corporation&#39;s trademarks or service marks, nor may you remove,
          obscure, or alter any of Give Corporation&#39;s trademarks or service
          marks included in the Service.
        </Paragraph>
        <Paragraph>
          You may choose to, or we may invite you to, submit comments or ideas
          about the Service, including without limitation about how to improve
          the Service or our products (&quot;Feedback&quot;). By submitting
          Feedback, you agree that your disclosure is gratuitous, unsolicited
          and without restriction and will not place Give Corporation under any
          fiduciary or other obligation, and that we are free to use the
          Feedback without any additional compensation to you, and/or to
          disclose the Feedback on a non-confidential basis or otherwise to
          anyone. You further acknowledge that, by acceptance of your
          submission, Give Corporation does not waive any rights to use similar
          or related ideas previously known to Give Corporation, or developed by
          its employees, or obtained from sources other than you.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;owns all intellectual property rights in the
          Service, including any comments or ideas submitted by you.
        </Paragraph>
        <Heading>33. Indemnity</Heading>
        <Paragraph>
          You will indemnify, defend and hold us and our processors and partners
          harmless (and our and their respective employees, directors, agents,
          affiliates and representatives) from and against any and all claims,
          costs, losses, damages, judgments, tax assessments, penalties,
          interest, and expenses (including without limitation reasonable
          attorneys&#39; fees) arising out of any claim, action, audit,
          investigation, inquiry, or other proceeding instituted by a third
          party person or entity that arises out of or relates to: (a) any
          actual or alleged breach of your representations, warranties, or
          obligations set forth in this Agreement, including without limitation
          any violation of our policies or the Operating Regulations; (b) your
          wrongful or improper use of the Service; (c) any transaction submitted
          by you through the Service (including without limitation the accuracy
          of any product information that you provide or any claim or dispute
          arising out of products or services offered or sold by you); (d) your
          violation of any third-party right, including without limitation any
          right of privacy, publicity rights or intellectual property rights;
          (e) your violation of any law, rule or regulation of the United States
          or any other country; (f) any other party&#39;s access and/or use of
          the Service with your unique username, password or other appropriate
          security code.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You agree to pay if your actions cause someone to make a claim against
          us.
        </Paragraph>
        <Heading>34. Representations and Warranties</Heading>
        <Paragraph>
          You represent and warrant to us that: (a) you are eligible to register
          and use the Service and have the right, power, and ability to enter
          into and perform under this Agreement; (b) the name identified by you
          when you registered is your name or business name under which you sell
          goods and services; (c) any sales transaction submitted by you will
          represent a bona fide sale by you; (d) any sales transactions
          submitted by you will accurately describe the goods and/or services
          sold and delivered to a purchaser; (e) you will fulfill all of your
          obligations to each Purchaser for which you submit a transaction and
          will resolve any consumer dispute or complaint directly with the
          Purchaser; (f) you and all transactions initiated by you will comply
          with all federal, state, and local laws, rules, and regulations
          applicable to your business, including any applicable tax laws and
          regulations; (g) except in the ordinary course of business, no
          transaction submitted by you through the Service will represent a sale
          to any principal, partner, proprietor, or owner of your entity; (h)
          you will not use the Service, directly or indirectly, for any
          fraudulent undertaking or in any manner so as to interfere with the
          use of the Service; (i) your use of the Service will be in compliance
          with this Agreement.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You promise us that the information you provide is accurate and that
          you won&#39;t misuse the Service.
        </Paragraph>
        <Heading>35. Disclaimer of Warranties by Give Corporation</Heading>
        <Paragraph>
          THE SERVICE IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
          AVAILABLE&quot; BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED
          WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING,
          BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, OR NON- INFRINGEMENT. NO ADVICE OR INFORMATION,
          WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Give Corporation OR
          THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED
          HEREIN. WITHOUT LIMITING THE FOREGOING, Give Corporation, ITS
          PROCESSORS, ITS PROVIDERS, ITS LICENSORS AND THE BANK (AND THEIR
          RESPECTIVE SUBSIDIARIES, AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES)
          DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT
          THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE;
          THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </Paragraph>
        <Paragraph>
          Give Corporation DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH THE SERVICE OR ANY HYPERLINKED WEBSITE OR SERVICE,
          OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND Give Corporation
          WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN
          YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        </Paragraph>
        <Paragraph>
          All third party hardware and other products included or sold with the
          Service are provided solely according to the warranty and other terms
          specified by the manufacturer, who is solely responsible for service
          and support for its product. For service, support, or warranty
          assistance, you should contact the manufacturer or distributor
          directly. Give Corporation MAKES NO WARRANTIES, EXPRESS OR IMPLIED,
          WITH RESPECT TO SUCH THIRD PARTY PRODUCTS, AND EXPRESSLY DISCLAIMS ANY
          WARRANTY OR CONDITION OF MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS
          FOR A PARTICULAR PURPOSE.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You agree to use the Service at your own risk.
        </Paragraph>
        <Heading>36. Limitation of Liabilities and Damages</Heading>
        <Paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          Give Corporation, ITS PROCESSORS, SUPPLIERS, LICENSORS, NETWORKS, OR
          THE BANK (OR THEIR RESPECTIVE AFFILIATES, AGENTS, DIRECTORS AND
          EMPLOYEES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT
          LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
          INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, INABILITY TO USE, OR
          UNAVAILABILITY OF THE SERVICE. UNDER NO CIRCUMSTANCES WILL Give
          Corporation BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING
          FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE
          SERVICE OR YOUR Give Corporation ACCOUNT OR THE INFORMATION CONTAINED
          THEREIN. IN NO EVENT WILL Give Corporation BE LIABLE FOR ANY
          INCIDENTAL, CONSEQUENTIAL, OR COVER DAMAGES ARISING OUT OF YOUR USE OF
          OR INABILITY TO USE THIRD PARTY PRODUCTS OR ANY AMOUNT IN EXCESS OF
          THE AMOUNT PAID BY YOU FOR THE PRODUCT THAT GIVES RISE TO ANY CLAIM.
        </Paragraph>
        <Paragraph>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Give Corporation,
          ITS PROCESSORS, THE NETWORKS, AND THE BANK (AND THEIR RESPECTIVE
          AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES) ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
          WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SERVICE; (III)
          ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
          ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SERVICE, OR ANY DELAY IN
          PERFORMING OUR OBLIGATIONS UNDER THIS AGREEMENT, REGARDLESS OF WHETHER
          THE FAILURE OR DELAY IS CAUSED BY AN EVENT OR CONDITION BEYOND OUR
          CONTROL; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
          TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY; (VI) ANY
          ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED
          AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
          OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (VII) USER
          CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD
          PARTY. IN NO EVENT SHALL Give Corporation, ITS PROCESSORS, AGENTS,
          SUPPLIERS, LICENSORS, NETWORKS, OR THE BANK (OR THEIR RESPECTIVE
          AFFILIATES, AGENTS, DIRECTORS, AND EMPLOYEES) BE LIABLE TO YOU FOR ANY
          CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR
          COSTS IN AN AMOUNT EXCEEDING THE AMOUNT OF FEES EARNED BY US IN
          CONNECTION WITH YOUR USE OF THE SERVICE DURING THE THREE (3) MONTH
          PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR
          LIABILITY.
        </Paragraph>
        <Paragraph>
          THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
          LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR
          ANY OTHER BASIS, EVEN IF Give Corporation HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY
          SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
          JURISDICTION.
        </Paragraph>
        <Paragraph>
          The Service is controlled and operated from facilities in the United
          States. Give Corporation makes no representations that the Service is
          appropriate or available for use in other locations. Those who access
          or use the Service from other jurisdictions do so at their own
          volition and are entirely responsible for compliance with all
          applicable United States and local laws and regulations, including but
          not limited to export and import regulations. You may not use the
          Service if you are a resident of a country embargoed by the United
          States, or are a foreign person or entity blocked or denied by the
          United States government. Unless otherwise explicitly stated, all
          materials found on the Service are solely directed to individuals,
          companies, or other entities located in the United States.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          There are limitations to our liability. This section is written in all
          caps for a reason. Read it thoroughly.
        </Paragraph>
        <Heading>37. Disputes</Heading>
        <Paragraph>
          If a dispute of any kind arises, we want to understand and address
          your concerns quickly and to your satisfaction. Please contact Give
          Corporation Support with any dispute. If we cannot resolve your
          concerns, we agree to an informal and inexpensive dispute resolution
          process requiring individual arbitration.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          If a dispute of any kind arises, we do our best to address your
          concerns quickly. If we cannot resolve your issue, we agree to binding
          individual arbitration.
        </Paragraph>
        <Heading>38. Binding Individual Arbitration</Heading>
        <Paragraph>
          You and Give Corporation agree to arbitrate all &quot;Disputes,&quot;
          defined as any claim, controversy or dispute (whether involving
          contract, tort, equitable, statutory or any other legal theory)
          between you and Give Corporation, including but not limited to any
          claims relating in any way to this Agreement (including its breach,
          termination and interpretation), any other aspect of our relationship,
          Give Corporation advertising, and any use of Give Corporation software
          or services. &quot;Disputes&quot; also include any claims that arose
          before this Agreement and that may arise after termination of this
          Agreement. Notwithstanding the foregoing, you or Give Corporation may
          choose to pursue a claim in court and not by arbitration if you fail
          to timely pay amounts due. Give Corporation may assign your account
          for collection, and the collection agency may pursue in any court of
          competent jurisdiction any claim that is strictly limited to the
          collection of past due amounts and any interest or cost of collection
          permitted by law or this Agreement.
        </Paragraph>
        <Paragraph>
          In the event that you or Give Corporation are not able to resolve a
          Dispute with American Express, or a claim against Give Corporation or
          any other entity that American Express has a right to join, Exhibit C
          will apply. Arbitration is more informal than a lawsuit in court and
          seeks to resolve disputes more quickly. Instead of a judge or a jury,
          the case will be decided by a neutral arbitrator who has the power to
          award the same damages and relief that a court can. ANY ARBITRATION
          UNDER THIS AGREEMENT WILL BE ONLY BE ON A INDIVIDUAL BASIS; CLASS
          ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND
          CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT PERMITTED, AND YOU ARE
          WAIVING YOUR RIGHTS TO HAVE YOUR CASE DECIDED BY A JURY AND TO
          PARTICIPATE IN A CLASS ACTION AGAINST Give Corporation. If any
          provision of this arbitration agreement is found unenforceable, the
          unenforceable provision shall be severed, and the remaining
          arbitration terms shall be enforced (but in no case shall there be a
          class arbitration). All Disputes shall be resolved finally and
          exclusively by binding individual arbitration with a single arbitrator
          administered by the American Arbitration Association (www.adr.org)
          (&quot;AAA&quot;) according to this provision and the applicable
          arbitration rules. A form for initiating arbitration proceedings is
          available on the AAA&#39;s website at www.adr.org. Consumer claimants
          (individuals whose transaction is intended for personal, family, or
          household use) may elect to pursue their claims in their local small-
          claims court rather than through arbitration. The Federal Arbitration
          Act, 9 U.S.C. &sect;&sect; 1-16, fully applies. If you are a consumer
          bringing a claim relating to personal, household, or family use, any
          arbitration hearing will occur within the county or parish where you
          reside or at another mutually agreed location. Otherwise, any
          arbitration hearing will occur in Santa Clara, California. You or Give
          Corporation may elect to have the arbitration conducted by telephone
          or based solely on written submissions, which election shall be
          binding on you and Give Corporation subject to the arbitrator&#39;s
          discretion to require an in-person hearing, if the circumstances
          warrant. Attendance at an in-person hearing may be made by telephone
          by you or by Give Corporation, unless the arbitrator requires
          otherwise. The arbitrator&#39;s award shall be binding on the parties
          and may be entered as a judgment in any court of competent
          jurisdiction. Payment of all filing, administration, and arbitrator
          fees will be governed by the AAA&#39;s rules, but if you are unable to
          pay any of them, Give Corporation will pay them for you. In addition,
          Give Corporation will reimburse all such fees and costs for claims
          totaling less than $75,000 unless the arbitrator determines the claims
          are frivolous. Likewise, Give Corporation will not seek its
          attorneys&#39; fees or costs in arbitration unless the arbitrator
          determines your claims or defenses are frivolous. In the event the
          arbitrator determines the claims you assert in the arbitration are
          frivolous, you agree to reimburse Give Corporation for all fees
          associated with the arbitration paid by Give Corporation on your
          behalf that you otherwise would be obligated to pay under the
          AAA&#39;s rules. For purposes of this arbitration provision,
          references to you and Give Corporation also include respective
          subsidiaries, affiliates, agents, employees, predecessors, successors
          and assigns as well as authorized users or beneficiaries of the
          Service. Subject to and without waiver of the arbitration provisions
          above, you agree that any judicial proceedings (other than small
          claims actions in consumer cases) will be brought in and you hereby
          consent to the exclusive jurisdiction and venue in the state courts in
          the County of Los Angeles, California, or federal court for the
          Southern District of California.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You and Give Corporation agree to arbitrate all Disputes rather than
          resolve them in court.
        </Paragraph>
        <Heading>39. Governing Law</Heading>
        <Paragraph>
          This Agreement and any Dispute will be governed by California law
          and/or applicable federal law (including the Federal Arbitration Act)
          as applied to agreements entered into and to be performed entirely
          within California, without regard to its choice of law or conflicts of
          law principles that would require application of law of a different
          jurisdiction.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          This Agreement and any Dispute will be governed by California law
          and/or applicable federal law.
        </Paragraph>
        <Heading>40. Limitation on Time to Initiate a Dispute</Heading>
        <Paragraph>
          Unless otherwise required by law, an action or proceeding by you
          relating to any Dispute must commence within one year after the cause
          of action accrues.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You&#39;ve got a full year to commence a dispute.
        </Paragraph>
        <Heading>41. Assignment</Heading>
        <Paragraph>
          This Agreement, and any rights and licenses granted hereunder, may not
          be transferred or assigned by you, but may be assigned by Give
          Corporation without restriction.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          This Agreement may only be transferred by Give Corporation, not you.
        </Paragraph>
        <Heading>42. Third Party Services and Links to Other Web Sites</Heading>
        <Paragraph>
          You may be offered services, products and promotions provided by third
          parties and not by Give Corporation. If you decide to use these third
          party services, you will be responsible for reviewing and
          understanding the terms and conditions associated with them. You agree
          that Give Corporation is not responsible for the performance of these
          services. The Website may contain links to third party websites. The
          inclusion of any website link does not imply an approval, endorsement,
          or recommendation by Give Corporation. You agree that you access any
          such website at your own risk, and that the site is not governed by
          the terms and conditions contained in this Agreement. Give Corporation
          expressly disclaims any liability for these websites. Please remember
          that when you use a link to go from our Website to another website,
          our Privacy Policy is no longer in effect. Your browsing and
          interaction on any other website, including those that have a link on
          our Website, is subject to that website&#39;s own rules and policies.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You agree that Give Corporation is not responsible for services or
          websites provided by others.
        </Paragraph>
        <Heading>43. Amendment of Agreement</Heading>
        <Paragraph>
          We have the right to change or add to the terms of this Agreement at
          any time, and to change, delete, discontinue, or impose conditions on
          any feature or aspect of the software or Service with notice that we
          in our sole discretion deem to be reasonable in the circumstances,
          including notice on our Website or any other website maintained or
          owned by us and identified to you. Any use of our software or Service
          after our publication of any such changes shall constitute your
          acceptance of this Agreement as modified. No modification or amendment
          to this Agreement shall be binding upon Give Corporation unless in a
          written instrument signed by a duly authorized representative of Give
          Corporation. For the purposes of this Section 43, a written instrument
          shall expressly exclude electronic communications such as email and
          electronic notices but shall include facsimiles.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Give Corporation&nbsp;has the right to change this Agreement or the
          Service at any time.
        </Paragraph>
        <Heading>44. Other Provisions</Heading>
        <Paragraph>
          Except as expressly provided in this Agreement, these terms are a
          complete statement of the agreement between you and Give Corporation,
          and they describe the entire liability of Give Corporation and its
          vendors and suppliers (including processors) and your exclusive remedy
          with respect to your access and use of the Service. In the event of a
          conflict between this Agreement and any other Give Corporation
          agreement or policy, this Agreement shall prevail on the subject
          matter of this Agreement. If any provision of this Agreement is
          invalid or unenforceable under applicable law, then it shall be
          changed and interpreted to accomplish the objectives of such provision
          to the greatest extent possible under applicable law, and the
          remaining provisions will continue in full force and effect. Headings
          and summaries are included for convenience only, and shall not be
          considered in interpreting this Agreement. The Agreement does not
          limit any rights that Give Corporation may have under trade secret,
          copyright, patent or other laws. Give Corporation&rsquo;s failure to
          assert any right or provision under this Agreement shall not
          constitute a waiver of such right or provision. No waiver of any term
          of this Agreement shall be deemed a further or continuing waiver of
          such term or any other term.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          This Agreement is a complete statement of the agreement between you
          and Give Corporation.
        </Paragraph>
        <Heading>45. Survival</Heading>
        <Paragraph>
          Any provision that is reasonably necessary to accomplish or enforce
          the purpose of this Agreement shall survive and remain in effect in
          accordance with its terms upon the termination of this Agreement.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Upon termination of this Agreement, certain sections of it will remain
          in effect.
        </Paragraph>
        <Heading>46. Merchant Processing Responsibility</Heading>
        <Paragraph>
          When applying for a merchant account, you are assuming the risk and
          responsibility of managing the funds you receive from processing
          credit cards, debit cards, checks, cryptocurrency and any other
          financial transaction, as well as maintaining a reasonable reserve to
          cover the possibilities of chargebacks, refunds, and/or returns.
          Government agencies deem handling funds a privilege and will not
          hesitate to prosecute you should you or your purchasers commit
          fraudulent activity.
          <br />
          <br />
          Give Corporation is a technology provider; you are the Merchant on
          record and ultimately responsible for any activity involving your
          merchant account. Abuse of your account could have lifetime
          ramifications.
          <br />
          <br />
          To protect the organizations and businesses that rely on Give
          Corporation, any violations of these terms and services will be
          prosecuted to the fullest extent of the law.
        </Paragraph>

        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You agree to follow all policies laid before you in these Terms and
          Services. You are agreeing that violating any terms will result in
          actionable punishments.
        </Paragraph>

        <Heading>47. Beneficial Ownership Attestation</Heading>
        <Paragraph>
          By approving this application as the individual who is opening an
          account on behalf of a business/organization I hereby attest the
          ownership and controlling information provided is complete and
          accurate to the best of my knowledge. The authorized individual(s) or
          the business/organization agrees to notify GiveCorporation of any
          changes to the information.
        </Paragraph>
        <Heading>48. PCI Compliance</Heading>
        <Paragraph>
          By checking the checkbox, you confirm that your business adheres to
          the Payment Card Industry Data Security Standard (PCI DSS)
          requirements and is PCI compliant. If you are unsure, complete the
          Self-Assessment Questionnaire A and Attestation of Compliance at
          https://listings.pcisecuritystandards.org/documents/SAQ_A_v3.pdf to
          ensure your business meets the PCI DSS standards.
        </Paragraph>
        <Heading>Exhibit A: Merchant Services Agreement</Heading>
        <Paragraph>
          This Merchant Services Agreement for Sub-Merchants (&quot;Merchant
          Agreement&quot;) is made among Sponsors, their designated Sponsor
          Banks (collectively &quot;Acquirer&quot;) and the &quot;User&quot; as
          defined in, and in connection with, the Terms of Service between Give
          Corporation, Inc. and the User (herein referred to as
          &quot;Sub-merchant&quot;). Capitalized terms not otherwise defined
          herein have the respective meanings given them in the Give Corporation
          Terms of Service. Acquirer will provide Sub- merchant with certain
          payment processing (the &quot;Services&quot;) in accordance with the
          terms of this Merchant Agreement. In consideration of
          Sub-merchant&#39;s receipt of credit or debit card funded payments,
          and participation in programs affiliated with Visa, MasterCard,
          Discover, and certain similar entities (collectively,
          &quot;Associations&quot;), Sub-merchant is required to comply with the
          Operating Regulations as they pertain to applicable credit and debit
          card payments. In addition, if Sub-merchant meets certain requirements
          under the Operation Regulations or an Association or the Operating
          Regulations otherwise require, Sub-merchant may be required to enter
          into a direct relationship with an entity that is a member of the
          Associations. By agreeing to the Give Corporation Terms of Service to
          which this Merchant Agreement is an exhibit (by &quot;click
          through&quot; agreement or otherwise), Sub-merchant has fulfilled this
          requirement, if and when applicable. However, Acquirer understands
          that Sub-merchant may have contracted with Give Corporation to obtain
          certain processing services and that Give Corporation may have agreed
          to be responsible to Sub-merchant for all or part of
          Sub-merchant&#39;s obligations contained herein. NOW, THEREFORE, in
          consideration of the foregoing recitals and of the mutual promises
          contained herein, the parties agree as follows:
        </Paragraph>
        <Paragraph>
          1. Certain Sub-merchant Responsibilities. Sub-merchant agrees to
          comply, and to cause third parties acting as Sub- merchant&#39;s agent
          (&quot;Agents&quot;) to comply, with the Associations&#39; and other
          payment networks&#39; by-laws, operating regulations and/or all other
          rules, policies and procedures, including but not limited to the
          Payment Card Industry Data Security Standard, the VISA Cardholder
          Information Security Program, the MasterCard Site Data Protection
          Program, and any other program or requirement that may be published
          and/or mandated by the Associations or payment networks (collectively,
          &quot;Operating Regulations&quot;). Sub-merchant may review the VISA,
          MasterCard, and Discover websites for a copy of the Visa, MasterCard
          and Discover regulations. The websites are:
          https://usa.visa.com/support/consumer/visa- rules.html; and
          http://www.mastercard.com/us/merchant/ and
          https://www.discovernetwork.com/merchants/. Without limiting the
          foregoing, Sub-merchant agrees that it will fully comply with any and
          all anti-money laundering laws and regulations, including but not
          limited to the Bank Secrecy Act, the US Treasury&#39;s Office of
          Foreign Assets Control (OFAC) and the Federal Trade Commission. For
          purposes of this section, Agents include, but are not limited to, Sub-
          merchant&#39;s software providers and/or equipment providers. If so
          indicated in the Give Corporation Terms of Service, Sub- merchant may
          be a limited-acceptance merchant, which means that Sub-merchant has
          elected to accept only certain Visa and MasterCard card types (i.e.,
          consumer credit, consumer debit, and commercial cards) and must
          display appropriate signage to indicated the same. Acquirer has no
          obligation other than those expressly provided under the Operating
          Regulations and applicable law as they may relate to limited
          acceptance. Sub- merchant, and not Acquirer, will be solely
          responsible for the implementation of its decision for limited
          acceptance, including but not limited to policing the card type(s)
          accepted at the point of sale.
        </Paragraph>
        <Paragraph>
          Sub-merchant shall only complete sales transactions produced as the
          direct result of bona fide sales made by Sub-merchant to cardholders,
          and is expressly prohibited from presenting sales transactions which
          are produced as a result of sales made by any person or entity other
          than Sub-merchant, or for any purposes related to any illegal or
          prohibited activity, including but not limited to money- laundering or
          financing of terrorist activities.
        </Paragraph>
        <Paragraph>
          Sub-merchant may set a minimum transaction amount to accept a card
          that provides access to a credit account, under the following
          conditions: i) the minimum transaction amount does not differentiate
          between card issuers; ii) the minimum transaction amount does not
          differentiate between MasterCard, Visa, or any other acceptance brand;
          and iii) the minimum transaction amount does not exceed ten dollars
          (or any higher amount established by the Federal Reserve).
          Sub-merchant may set a maximum transaction amount to accept a card
          that provides access to a credit account, under the following
          conditions: Sub- merchant is a i) department, agency or
          instrumentality of the U.S. government; ii) corporation owned or
          controlled by the U.S. government; or iii) Sub-merchant whose primary
          business is reflected by one of the following MCCs: 8220, 8244, 8249 -
          Schools, Trade or Vocational; and the maximum transaction amount does
          not differentiate between MasterCard, Visa, or any other acceptance
          brand.
        </Paragraph>
        <Paragraph>
          2. Sub-merchant Prohibitions. Sub-merchant must not i) require a
          cardholder to complete a postcard or similar device that includes the
          cardholder&#39;s account number, card expiration date, signature, or
          any other card account data in plain view when mailed, ii) add any tax
          to transactions, unless applicable law expressly requires that a
          Sub-merchant impose a tax (any tax amount, if allowed, must be
          included in the transaction amount and not collected separately), iii)
          request or use an account number for any purpose other than as payment
          for its goods or services, iv) disburse funds in the form of travelers
          checks if the sole purpose is to allow the cardholder to make a cash
          purchase of goods or services from Sub-merchant, v) disburse funds in
          the form of cash unless Sub-merchant is dispensing funds in the form
          of travelers checks, TravelMoney cards, or foreign currency (in such
          case, the transaction amount is limited to the value of the travelers
          checks, TravelMoney cards, or foreign currency, plus any commission or
          fee charged by the Sub-merchant), or Sub-merchant is participating in
          a cash back service, vi) submit any transaction receipt for a
          transaction that was previously charged back to the Acquirer and
          subsequently returned to Sub-merchant, irrespective of cardholder
          approval, vii) accept a Visa consumer credit card or commercial Visa
          product issued by a U.S. issuer to collect or refinance an existing
          debt, viii) accept a card to collect or refinance an existing debt
          that has been deemed uncollectible, or ix) submit a transaction that
          represents collection of a dishonored check. Sub-merchant further
          agrees that, under no circumstance, will Sub-merchant store cardholder
          data in violation of the Laws or the Operating Regulations including
          but not limited to the storage of track-2 data. Neither Sub-merchant
          nor its Agent shall retain or store magnetic-stripe data subsequent to
          the authorization of a sales transaction.
        </Paragraph>
        <Paragraph>
          3. Settlement. Upon receipt of Sub-merchant&#39;s sales data for card
          transactions, Acquirer will process Sub-merchant&#39;s sales data to
          facilitate the funds transfer between the various Associations and
          Sub-merchant. After Acquirer receives credit for such sales data,
          subject to the terms set forth herein, Acquirer will fund Sub-merchant
          directly to Sub-merchant&#39;s designated demand deposit account
          (&quot;Sub-merchant-Owned Designated Account&quot;) or, at Sub-
          merchant&#39;s request, to a third-party check writer&#39;s account..
          Any dispute regarding amount of settlement shall be between Give
          Corporation and Sub-merchant. Any dispute regarding the receipt of
          settlement shall be between Acquirer and Sub-merchant. Acquirer will
          debit the Give Corporation Reserve Account for funds owed to Acquirer
          as a result of the Services provided hereunder, provided that Acquirer
          may also debit the Sub-merchant-Owned Designated Account for funds
          owed to Acquirer as a result of the Services provided hereunder.
          Further, if a cardholder disputes a transaction, if a transaction is
          charged back for any reason, or if Acquirer reasonably believes a
          transaction is unauthorized or otherwise unacceptable, the amount of
          such transaction may be charged back and debited from Sub-merchant or
          the Give Corporation reserve account.
        </Paragraph>
        <Paragraph>
          4. Term and Termination. This Agreement shall be binding upon
          Sub-merchant upon Sub-merchant&#39;s acceptance (by &quot;click
          through&quot; agreement or otherwise). The term of this Agreement
          shall begin, and the terms of the Agreement shall be deemed accepted
          and binding upon Acquirer, on the date Acquirer accepts this Agreement
          by issuing a merchant identification number and shall be coterminous
          with Give Corporation&#39;s Terms of Service with Sub-merchant.
          Notwithstanding the foregoing, Acquirer may immediately cease
          providing Services and/or terminate this Agreement without notice if
          (i) Sub-merchant or Give Corporation fails to pay any amount to
          Acquirer when due, (ii) in Acquirer&#39;s opinion, provision of a
          service to Sub-merchant or Give Corporation may be a violation of the
          Operating Regulations or any Laws, (iii) Acquirer believes that
          Sub-merchant has violated or is likely to violate the Operating
          Regulations or the Laws, (iv) Acquirer determines Sub-merchant poses a
          financial or regulatory risk to Acquirer or an Association, (v)
          Acquirer&#39;s agreement with Give Corporation terminates, (vi) any
          Association deregisters Give Corporation, (vii) Acquirer ceases to be
          a member of the Associations or fails to have the required licenses,
          or (viii) Acquirer is required to do so by any of the Associations.
        </Paragraph>
        <Paragraph>
          5. Limits of Liability. Sub-merchant agrees to provide Acquirer, via a
          communication with Give Corporation, with written notice of any
          alleged beach by Acquirer of this Agreement, which notice will
          specifically detail such alleged breach, within thirty (30) days of
          the date on which the alleged breach first occurred. Failure to so
          provide notice shall be deemed an acceptance by Sub-merchant and a
          waiver of any and all rights to dispute such breach.
        </Paragraph>
        <Paragraph>
          EXCEPT FOR THOSE EXPRESS WARRANTIES MADE IN THIS AGREEMENT, ACQUIRER
          DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY EXPRESS
          OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE. Sub-merchant&#39;s sole and exclusive remedy for any and all
          claims against Acquirer arising out of or in any way related to the
          transactions contemplated herein shall be termination of this
          Agreement. In the event that Sub- merchant has any claim arising in
          connection with the Services, rights, and/or obligations defined in
          this Agreement, Sub-merchant shall proceed against Give Corporation
          and not Acquirer, unless otherwise specifically set forth in the
          Operating Regulations. In no event shall Acquirer have any liability
          to Sub-merchant with respect to this Agreement or the Services.
          Sub-merchant acknowledges Acquirer is only providing this Agreement to
          assist in Give Corporation&#39;s processing relationship with
          Sub-merchant, that Acquirer is not liable for any action or failure to
          act by Give Corporation, and that Acquirer shall have no liability
          whatsoever in connection with any products or services provided to
          Sub-merchant by Give Corporation. If Give Corporation is unable to
          provide its services to Sub-merchant in connection with this Agreement
          and Acquirer elects to provide those services, directly, Sub-merchant
          acknowledges and agrees that the provisions of this Agreement will no
          longer apply and the terms of Acquirer&#39;s then current Bank Card
          Merchant Agreement, which would be provided to Sub-merchant, will
          govern Acquirer&#39;s relationship with Sub-merchant. If Give
          Corporation subsequently provides its services to Sub-merchant in
          connection with this Agreement, Acquirer will cease to provide such
          services after receipt of notice from Give Corporation and this
          Agreement will govern Acquirer&#39;s relationship with Sub- merchant.
        </Paragraph>
        <Paragraph>
          6. Processing and Distributing Money
          <br />
          <br />
          a. We may post information about Participating Nonprofits
          (NPOs)&mdash;which may include NPOs Name, Logo and Mission
          Statement&mdash;on the Give Corporation.com, all Give Corporation
          related social media and on other websites and services with which we
          may partner for purposes of soliciting money for your NPO. Give
          Corporation Users and users of other affiliated websites and services
          will have access that information through our Services as well as
          through the websites and services with which we partner.
          <br />
          <br />
          b. You acknowledge that we make no promise that you will receive any
          money as a result of your status as a Participating Nonprofit.
          <br />
          <br />
          c. Money processed and distributed made under this Agreement will be
          made into your Give Corporation Confirmed Nonprofit Merchant Account
          identified in your Nonprofit Payment Profile. You agree to take any
          actions necessary to authorize us to make such transfers to you, and
          you will not hold us liable if you provide us with inaccurate or
          incomplete information about your bank account. Any distribution we
          may make to you is subject to our obligations to Customers, Donors,
          and our Money Delivery Policy regarding timing of delivery and
          expenses.
          <br />
          <br />
          d. Customers and Donors make their donations to through Give
          Corporation, which we then distribute to Participating Nonprofits. We
          make money distributions to approved Participating Nonprofits that are
          selected by Customers and Donors. As a result, any money your
          organization receives pursuant to this Agreement are ultimately made
          by Give Corporation not by Customers and Donors. Give Corporation
          retains exclusive legal control over all monies received by it from
          Customers and Donors.
          <br />
          <br />
          e. Pursuant to our Privacy Policy, Give Corporation may disclose to
          you information about Customers and Donors, including name and contact
          information. You agree that Give Corporation discloses this
          information to you only for the purpose of enabling you to acknowledge
          sales and donations, to resolve any issues with sales and donations,
          and to provide a reasonable amount of information about your
          charitable work. You become the data controller of the Customer and
          Donor&rsquo;s personal information that we provide to you for this
          limited purpose. You must not use the Customer and Donor&rsquo;s
          personal information for any other purpose without the Customer and
          Donor&rsquo;s further consent, including but not limited to for
          marketing purposes. You agree you will obtain consent from Customer
          and Donors to use the information we provide to you for any purpose
          beyond what is contained in this clause.
          <br />
          <br />
          f. We reserve the right not to distribute a donation to you (even if
          you are selected by a Customer or Donor for a particular donation),
          if: (i) we are unable to deliver it (e.g., if the Give Corporation
          account and/or contact information in your Nonprofit Profile is
          invalid); (ii) at any time you are in breach of this Agreement; (iii)
          you cease to have status as a Tax-Deductible Organization, Charitable
          Organization, Private Foundation, or Public Charity; (iv) doing so
          would cause us to be out of compliance with applicable laws; (v) doing
          so would bring us into disrepute; or (vi) you otherwise cease to
          qualify as a Participating Nonprofit in good standing; (vii) you do
          not request a WITHDRAW, BILL PAY or MONEY TRANSFER within a 9 Month
          period in which case monies will be either a) refunded to the customer
          or donor and/or b) redistributed to another a Tax-Deductible
          Organization, Charitable Organization, Private Foundation, or Public
          Charity at the discretion of Give Corporation. In any such case, we
          reserve the right to redirect any donation to another entity, or to
          use or dispense with the donation in any manner consistent with our
          policies and charitable purposes.
        </Paragraph>
        <Paragraph>
          7. Miscellaneous. This Agreement is entered into, governed by, and
          construed pursuant to the laws of the State of Ohio without regard to
          conflicts of law provisions. This Agreement may not be assigned by
          Sub-merchant without the prior written consent of Acquirer. This
          Agreement shall be binding upon and inure to the benefit of the
          parties hereto and their respective successors, transferees and
          assignees. This Agreement is for the benefit of, and may be enforced
          only by, Acquirer and Sub- merchant and is not for the benefit of, and
          may not be enforced by, any other party. Acquirer may amend this
          Agreement upon notice to Sub-merchant in accordance with
          Acquirer&#39;s standard operating procedure. If any provision of this
          Agreement is determined to be illegal or invalid, such illegality or
          invalidity of that provision will not affect any of the remaining
          provisions and this Agreement will be construed as if such provision
          is not contained in the Agreement. &quot;Sponsor Bank&quot; as used in
          this Agreement shall mean a member of VISA, MasterCard and/or
          Discover, as applicable, that provides sponsorship services in
          connection with this Agreement. The Sponsor Bank is a party to this
          Agreement. The Sponsor Bank may be changed, and its rights and
          obligations assigned to another party by Acquirer at any time without
          notice to Sub-merchant.
        </Paragraph>
        <Heading>
          Exhibit B: American Express Card Acceptance and Brand Requirements
        </Heading>
        <Paragraph>
          1. Defined Terms. As used in this Exhibit B, the following capitalized
          terms have the following respective meanings:
        </Paragraph>
        <UList>
          <ListElement>
            &quot;Cardmember&quot; means an individual or entity that has
            entered into an agreement establishing an American Express Card
            account, or whose name appears on the American Express Card.
          </ListElement>
          <ListElement>
            &quot;Cardmember Information&quot; means any information about
            Cardmembers and transactions, including, but not limited to,
            transaction data, and Cardmember name, addresses, American Express
            Card numbers, and American Express Card Identification Numbers.
          </ListElement>
          <ListElement>
            &quot;Establishments&quot; means any or all of your and your
            affiliates&#39; locations, outlets, websites, online networks, and
            all other methods for selling goods and services including methods
            that you adopt in the future.
          </ListElement>
          <ListElement>
            &quot;Marks&quot; means names, logos service marks, trademarks,
            trade names, taglines, or other proprietary designs or designations.
          </ListElement>
          <ListElement>
            &quot;Other Payment Products&quot; means any charge, credit, debit,
            stored value, prepaid, or smart cards, account access devices, or
            other payment cards, services, or products other than the American
            Express Card.
          </ListElement>
        </UList>
        <Paragraph>
          American Express Card Acceptance. Merchant must accept the American
          Express Card as payment for goods and services (other than those goods
          and services prohibited under Section 7 of the Agreement) sold, or (if
          applicable) for charitable contributions made, at all of
          Merchant&#39;s Establishments, except as expressly permitted by state
          statute. Merchant is jointly and severally liable for the obligations
          of Merchant&#39;s Establishments under this Agreement.
        </Paragraph>
        <Paragraph>
          Treatment of the American Express Brand. Except as expressly permitted
          by applicable law, Merchant must not:
        </Paragraph>
        <UList>
          <ListElement>
            Indicate or imply that Merchant prefers, directly or indirectly, any
            Other Payment Products over the American Express Card,
          </ListElement>
          <ListElement>
            Try to dissuade Cardmembers from using the American Express Card,
          </ListElement>
          <ListElement>
            Criticize or mischaracterize the American Express Card or any
            American Express services or programs,
          </ListElement>
          <ListElement>
            Try to persuade or prompt Cardmembers to use any Other Payment
            Products or any other method of payment (e.g., payment by check),
          </ListElement>
          <ListElement>
            Impose any restrictions, conditions, disadvantages or fees when the
            American Express Card is accepted that are not imposed equally on
            all Other Payment Products, except for electronic funds transfer, or
            cash and check,
          </ListElement>
          <ListElement>
            Suggest or require Cardmembers to waive their right to dispute any
            transaction,
          </ListElement>
          <ListElement>
            Engage in activities that harm our business or the American Express
            brand (or both),
          </ListElement>
          <ListElement>
            Promote any Other Payment Products (except Merchant&#39;s own
            private label card that Merchant issues for use solely at
            Merchant&#39;s Establishments) more actively than Merchant promotes
            the American Express Card, or
          </ListElement>
          <ListElement>
            Convert the currency of the original sale transaction to another
            currency when requesting authorization or submitting transactions
            (or both).
          </ListElement>
        </UList>
        <Paragraph>
          4. Treatment of the American Express Brand (US). Merchant may offer
          discounts or in-kind incentives from Merchant&#39;s regular prices for
          payments in cash, ACH funds transfer, check, debit card or
          credit/charge card, provided that (to the extent required by
          applicable law): (i) Merchant clearly and conspicuously discloses the
          terms of the discount or in-kind incentive to Merchant&#39;s
          customers, (ii) the discount or in-kind incentive is offered to all of
          Merchant&#39;s prospective customers, and (iii) the discount or
          in-kind incentive does not differentiate on the basis of the issuer
          or, except as expressly permitted by applicable state statute,
          Network. The offering of discounts or in-kind incentives in compliance
          with the terms of this paragraph will not constitute a violation of
          the provisions set forth above in the paragraph titled,
          &quot;Treatment of the American Express Brand&quot;.
        </Paragraph>
        <Paragraph>
          5. Treatment of the American Express Marks. Whenever payment methods
          are communicated to customers, or when customers ask what payments are
          accepted, Merchant must indicate Merchant&#39;s acceptance of the
          American Express Card and display the American Express Marks as
          prominently and in the same manner as any Other Payment Products.
          Merchant must not use the American Express Marks in any way that
          injures or diminishes the goodwill associated with the Mark, nor in
          any way (without the prior written consent of American Express)
          indicate that American Express endorses Merchant&#39;s goods or
          services. Merchant shall only use the American Express Marks as
          permitted by the Agreement and shall cease using the American Express
          Marks upon termination of the Agreement.
        </Paragraph>
        <Paragraph>
          6. Treatment of American Express Cardmember Information. Any and all
          Cardmember Information is confidential and the sole property of the
          American Express Card issuer, American Express or its affiliates.
          Except as otherwise specified, Merchant must not disclose Cardmember
          Information, nor use nor store it, other than to facilitate
          transactions at Merchant&#39;s Establishments in accordance with the
          Agreement.
        </Paragraph>
        <Paragraph>
          7. In addition to the above Sub Merchant agrees to the following:
        </Paragraph>
        <UList>
          <ListElement>
            Sub-merchant must comply with, and accept Cards in accordance with,
            the terms of its Provider Merchant Agreement and the American
            Express Merchant Operating Guide, as such terms may be amended from
            time to time.
          </ListElement>
          <ListElement>
            Acknowledgment that the American Express Merchant Operating Guide is
            incorporated by reference into the Provider Merchant Agreement.
            (available here:
            https://icm.aexp-static.com/content/dam/gms/en_us/optblue/us-mog.pdf).
          </ListElement>
          <ListElement>
            Sub-merchant expressly authorizes payment facilitator to submit
            transactions to, and receive settlement from, American Express on
            behalf of the Sub-merchant.
          </ListElement>
          <ListElement>
            Express disclosures and consents necessary for (i) Payment
            facilitator to collect and disclose Transaction Data, Sub- merchant
            Data, and other information about the Sub-merchant to American
            Express; and (ii) American Express to use such information to
            perform its responsibilities in connection with the Program, promote
            the American Express Network, perform analytics and create reports,
            and for any other lawful business purposes, including commercial
            marketing communication purposes within the parameters of the
            Program Agreement, and important transactional or relationship
            communications from American Express.
          </ListElement>
          <ListElement>
            You acknowledge as Sub-merchant that it may be converted from the
            Program to a direct Card acceptance relationship with American
            Express if and when it becomes a High CV Merchant in accordance with
            Section 10.5, &quot;High CV Merchant Conversions&quot;.
            <br />
            Upon conversion, (i) you as the Sub-merchant will be bound by
            American Express&#39; then-current Card Acceptance Agreement; and
            (ii) American Express will set pricing and other fees payable by the
            Sub-merchant for Card acceptance.
          </ListElement>
          <ListElement>
            American Express may use the information obtained in the
            Sub-merchant application at the time of setup to screen,
            communicate, and/or monitor Sub-merchant in connection with Card
            marketing and administrative purposes.
          </ListElement>
          <ListElement>
            Sub-merchant shall not assign to any third party any payments due to
            it under their respective Provider Merchant Agreement, and all
            indebtedness arising from Charges will be for bona fide sales of
            goods and services (or both) at its Establishments and free of
            liens, claims, and encumbrances other than ordinary sales taxes;
            provided, however, that the Sub-merchant may sell and assign future
            Transaction receivables to payment facilitator, its affiliated
            entities and/ or any other cash advance funding source that partners
            with payment facilitator or its affiliated entities, without consent
            of American Express.
          </ListElement>
          <ListElement>
            A third-party beneficiary provision, conferring on American Express
            third-party beneficiary rights, but not obligations, to the Provider
            Merchant Agreement that will fully provide American Express with the
            ability to enforce the terms of the Provider Merchant Agreement
            against the Sub-merchant.
          </ListElement>
          <ListElement>
            Sub-merchant to opt out of accepting Cards at any time without
            directly or indirectly affecting its rights to accept Other Payment
            Products.
          </ListElement>
          <ListElement>
            Give Corporation as a payment facilitator has the right to terminate
            Sub-merchant&#39;s right to accept Cards if it breaches any of the
            provisions in this Section or the American Express Merchant
            Operating Guide.
          </ListElement>
          <ListElement>
            Give Corporation as a payment facilitator has the right to
            immediately terminate Sub-merchant for cause or fraudulent or other
            activity, or upon American Express&#39; request.
          </ListElement>
          <ListElement>
            Sub-merchant&#39;s refund policies for purchases on the Card must be
            at least as favorable as its refund policy for purchases on any
            Other Payment Products, and the refund policy be disclosed to
            Cardmembers at the time of purchase and in compliance with
            Applicable Law.
          </ListElement>
          <ListElement>
            Sub-Merchant prohibition against billing or collecting from any
            Cardmember for any purchase or payment on the Card unless Chargeback
            has been exercised, the Sub-merchant has fully paid for such Charge,
            and it otherwise has the right to do so.
          </ListElement>
          <ListElement>
            Sub-merchants are responsible for being aware of and adhering to
            privacy and data protection laws and provide specific and adequate
            disclosures to Cardmembers of collection, use, and processing of
            personal data.
          </ListElement>
        </UList>
        <Paragraph>Sub Merchant has the following requirements:</Paragraph>
        <UList>
          <ListElement>
            Comply with all Applicable Laws, rules and regulations relating to
            the conduct of the Sub-merchant&#39;s business.
          </ListElement>
          <ListElement>
            Comply with the DSR and PCI DSS, each as described in Chapter 15,
            &quot;Data Security&quot;.
          </ListElement>
          <ListElement>
            Must report all instances of a Data Incident immediately to the
            payment facilitator after discovery of the incident.
          </ListElement>
          <ListElement>
            Cease all use of, and remove American Express Licensed Marks from
            the Sub-merchant&#39;s website and wherever else they are displayed
            upon termination of the Provider Merchant Agreement or a
            Sub-merchant&#39;s participation in the Program.
          </ListElement>
          <ListElement>
            Ensure data quality and that Transaction Data and customer
            information is processed promptly, accurately and completely, and
            complies with the American Express Technical Specifications.
          </ListElement>
        </UList>
        {!isGeneric && (
          <Paragraph>
            <RHFCheckbox
              disabledStyles={{
                stroke: palette.neutral[40],
                "stroke-width": 1.6,
              }}
              name={"amexCheckbox"}
              dataTestId="amex-checkbox-tos"
              label="By checking this box, you are opting out of receiving future commercial marketing communications from American Express."
              disabled={true}
            />
          </Paragraph>
        )}
        <Paragraph>
          Note that you may continue to receive marketing communications while
          American Express updates its records to reflect your choice. Opting
          out of commercial marketing communications will not preclude you from
          receiving important transactional or relationship messages from
          American Express.
        </Paragraph>
        <Heading>
          Exhibit C: Arbitration Agreement (as to Disputes involving American
          Express) (U.S.)
        </Heading>
        <Paragraph>
          In the event that Merchant or Give Corporation is not able to resolve
          a &quot;Claim&quot; (as defined in Section 11 below) against American
          Express, or a claim against Give Corporation or any other entity that
          American Express has a right to join, this Exhibit C (this
          &quot;Arbitration Agreement&quot;) explains how Claims may be resolved
          through arbitration. Merchant or American Express may elect to resolve
          any Claim by binding individual arbitration. Claims will be decided by
          a neutral arbitrator. If arbitration is elected by any party, neither
          Merchant nor Give Corporation nor American Express will have the right
          to litigate or have a jury trial on that Claim in court. Further,
          Merchant, Give Corporation, and American Express will not have the
          right to participate in a class action or in a representative capacity
          or in a group of persons alleged to be similarly situated pertaining
          to any Claim subject to arbitration under this Arbitration Agreement.
          Arbitration procedures are generally simpler than the rules in court.
          An arbitrator&#39;s decisions are final and binding, and the
          arbitrator&#39;s final decision on a Claim generally is enforceable as
          a court order with very limited review by a court. Other rights
          Merchant, Give Corporation, or American Express would have in court
          may also not be available in arbitration.
        </Paragraph>
        <Paragraph>
          1. Initiation of Arbitration. Claims may be referred to either JAMS or
          AAA, as selected by the party electing arbitration. Claims will be
          resolved pursuant to this Arbitration Agreement and the selected
          organization&#39;s rules in effect when the Claim is filed, except
          where those rules conflict with this Arbitration Agreement. Contact
          JAMS or AAA to begin an arbitration or for other information. Claims
          may be referred to another arbitration organization if all parties
          agree in writing, if American Express selects the organization and
          Merchant selects the other within 30 days thereafter or if an
          arbitrator is appointed pursuant to section 5 of the Federal
          Arbitration Act, 9 U.S.C. 1-16 (FAA). Any arbitration hearing will
          take place in the federal judicial district where Merchant&#39;s
          headquarters is located or New York, NY, at Merchant&#39;s election.
        </Paragraph>
        <Paragraph>
          2. Limitations on Arbitration. If any party elects to resolve a Claim
          by arbitration, that Claim will be arbitrated on an individual basis.
          No Claim is to be arbitrated on a class or purported representative
          basis or on behalf of the general public or other persons allegedly
          similarly situated. The arbitrator&#39;s authority is limited to
          Claims between Merchant, Give Corporation, and American Express. An
          arbitration award and any judgment confirming it will apply only to
          the specific case brought by Merchant, Give Corporation or American
          Express and cannot be used in any other case except to enforce the
          award as between Merchant, Give Corporation and American Express. This
          prohibition is intended to, and does, preclude Merchant from
          participating in any action by any trade association or other
          organization against American Express. Notwithstanding any other
          provision in this Arbitration Agreement, if any portion of these
          Limitations on Arbitration is found invalid or unenforceable, then the
          entire Arbitration Agreement (other than this sentence) will not
          apply, except that Merchant, Give Corporation, and American Express do
          not waive the right to appeal that decision.
        </Paragraph>
        <Paragraph>
          3. Previously Filed Claims/No Waiver. Merchant, Give Corporation, or
          American Express may elect to arbitrate any Claim that has been filed
          in court at any time before trial has begun or final judgment has been
          entered on the Claim. Merchant, Give Corporation, or American Express
          may choose to delay enforcing or to not exercise rights under this
          Arbitration Agreement, including the right to elect to arbitrate a
          claim, without waiving the right to exercise or enforce those rights
          on any other occasion. For the avoidance of any confusion, and not to
          limit its scope, this section applies to any class-action lawsuit
          relating to the &quot;Honor All Cards,&quot;
          &quot;non-discrimination,&quot; or &quot;no steering&quot; provisions
          of the American Express Merchant Regulations, or any similar
          provisions of any prior American Express Card acceptance agreement,
          that was filed against American Express prior to the Effective Date of
          the Arbitration Agreement to the extent that such claims are not
          already subject to arbitration pursuant to a prior agreement between
          Merchant and American Express.
        </Paragraph>
        <Paragraph>
          4. Arbitrator&#39;s Authority. The arbitrator will have the power and
          authority to award any relief that would have been available in court
          and that is authorized under this Arbitration Agreement. The
          arbitrator has no power or authority to alter the Agreement or any of
          its separate provisions, including this Arbitration Agreement.
        </Paragraph>
        <Paragraph>
          5. Split Proceedings for Equitable Relief. Merchant, Give Corporation,
          or American Express may seek equitable relief in aid of arbitration
          prior to arbitration on the merits if necessary to preserve the status
          quo pending completion of the arbitration. This section shall be
          enforced by any court of competent jurisdiction, and the party seeking
          enforcement is entitled to seek an award of reasonable attorneys&#39;
          fees and costs to be paid by the party against whom enforcement is
          ordered.
        </Paragraph>
        <Paragraph>
          6. Small Claims. American Express will not elect arbitration for any
          Claim Merchant properly files in a small claims court so long as the
          Claim seeks individual relief only and is pending only in that court.
        </Paragraph>
        <Paragraph>
          7. Governing Law/Arbitration Procedures/Entry of Judgment. This
          Arbitration Agreement is made pursuant to a transaction involving
          interstate commerce and is governed by the FAA. The arbitrator shall
          apply New York law and applicable statutes of limitations and honor
          claims of privilege recognized by law. The arbitrator shall apply the
          rules of the arbitration organization selected, as applicable to
          matters relating to evidence and discovery, not federal or any state
          rules of procedure or evidence, provided that any party may ask the
          arbitrator to expand discovery by making a written request, to which
          the other parties will have 15 days to respond before the arbitrator
          rules on the request. If Merchant&#39;s Claim is for $10,000 or less,
          Merchant may choose whether the arbitration will be conducted solely
          based on documents submitted to the arbitrator, through a telephonic
          hearing, or by an in-person hearing under the rules of the selected
          arbitration organization. At the timely request of a party, the
          arbitrator will provide a written opinion explaining his/her award.
          The arbitrator&#39;s decision will be final and binding, except for
          any rights of appeal provided by the FAA. Judgment on an award
          rendered by the arbitrator may be entered in any state or federal
          court in the federal judicial district where Merchant&#39;s
          headquarters or Merchant&#39;s assets are located.
        </Paragraph>
        <Paragraph>
          8. Confidentiality. The arbitration proceeding and all information
          submitted, relating to or presented in connection with or during the
          proceeding, shall be deemed confidential information not to be
          disclosed to any person not a party to the arbitration. All
          communications, whether written or oral, made in the course of or in
          connection with the Claim and its resolution, by or on behalf of any
          party or by the arbitrator or a mediator, including any arbitration
          award or judgment related thereto, are confidential and inadmissible
          for any purpose, including impeachment or estoppel, in any other
          litigation or proceeding; provided, however, that evidence shall not
          be rendered inadmissible or non-discoverable solely as a result of its
          use in the arbitration.
        </Paragraph>
        <Paragraph>
          9. Costs of Arbitration Proceedings. Merchant will be responsible for
          paying Merchant&#39;s share of any arbitration fees (including filing,
          administrative, hearing or other fees), but only up to the amount of
          the filing fees Merchant would have incurred if Merchant had brought a
          claim in court. American Express will be responsible for any
          additional arbitration fees. At Merchant&#39;s written request,
          American Express will consider in good faith making a temporary
          advance of Merchant&#39;s share of any arbitration fees, or paying for
          the reasonable fees of an expert appointed by the arbitrator for good
          cause.
        </Paragraph>
        <Paragraph>
          10. Additional Arbitration Awards. If the arbitrator rules in
          Merchant&#39;s favor against American Express for an amount greater
          than any final settlement offer American Express made before
          arbitration, the arbitrator&#39;s award will include: (1) any money to
          which Merchant is entitled as determined by the arbitrator, but in no
          case less than $5,000; and (2) any reasonable attorneys&#39; fees,
          costs and expert and other witness fees incurred by Merchant.
        </Paragraph>
        <Paragraph>
          11. Definitions. For purposes of this Exhibit C, &quot;Arbitration
          Agreement (as to Claims involving American Express) (U.S.)&quot; only,
          (i) American Express includes its affiliates, licensees, predecessors,
          successors, or assigns, any purchasers of any receivables, and all
          agents, directors, and representatives of any of the foregoing, (ii)
          Merchant includes Merchant&#39;s affiliates, licensees, predecessors,
          successors, or assigns, any purchasers of any receivables and all
          agents, directors, and representatives of any of the foregoing, and
          (iii) Claim means any allegation of an entitlement to relief, whether
          damages, injunctive or any other form of relief, against American
          Express or against Give Corporation or any other entity that American
          Express has the right to join, including, a transaction using an
          American Express product or network or regarding an American Express
          policy or procedure.
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          Merchants using Give Corporation must agree to the Sub-Merchant terms
          listed here in the Sub-merchant Agreement.
        </Paragraph>
        <Heading>
          Exhibit D: American Express Merchant Agreement Requirements
        </Heading>
        <SubHeading>Merchant Agreement Requirements</SubHeading>
        <Paragraph>
          This Agreement (hereinafter referred to as the &quot;Agreement&quot;)
          is entered into by and between RS2 Software INC. (hereinafter referred
          to as &quot;Participant&quot;), Give Corporation (hereinafter referred
          to as &quot;PayFac&quot;), and each individual Program Merchant
          (hereinafter collectively referred to as &quot;Program Merchants&quot;
          or individually as &quot;Program Merchant&quot;).
        </Paragraph>
        <Paragraph>
          A. Participant and its PayFac must enter into a Merchant Agreement
          directly with each of its Program Merchants and must ensure each
          Program Merchant&apos;s compliance with the provisions of the Merchant
          Agreements, including suspending, canceling, or disentitling a Program
          Merchant of Card acceptance if it has breached any of those provisions
          therein. Each Merchant Agreement must meet the requirements and must
          be executed prior to the provision of payment services to a Program
          Merchant.
        </Paragraph>
        <Paragraph>
          B. The form, content, and appearance of the Merchant Agreement are at
          the discretion of the Participant, except as specified below. Each
          Merchant Agreement must (i) be duly executed by an authorized
          representative who has authority to bind the Program Merchant, (ii) be
          consistent with the American Express Brand and comply with all
          Applicable Laws, and (iii) include the following provisions:
        </Paragraph>

        <Paragraph>
          1. A legally enforceable agreement from the Program Merchant to comply
          with, and accept Cards in accordance with, the terms of its Merchant
          Agreement and the American Express Merchant Operating Guide, as such
          terms may be amended from time to time.
        </Paragraph>

        <Paragraph>
          2. An explanation of and a link to the American Express Merchant
          Operating Guide confirming that the American Express Merchant
          Operating Guide is incorporated by reference into the Merchant
          Agreement.
        </Paragraph>

        <Paragraph>
          3. The Participant, Give Corporation (PayFac), and each Program
          Merchant authorize Chesapeake Bank to receive and transmit Settlement
          Funds on their behalf. Chesapeake Bank shall receive settlement funds
          from American Express via wire transfer to its clearing account and
          subsequently move the funds intrabank to the FBO settlement account.
          This process follows the settlement and transaction processing steps
          as outlined in this Agreement.
        </Paragraph>

        <Paragraph>
          4. Express disclosures and consents necessary for (i) Participant to
          collect and disclose Transaction Data, Merchant Data, and other
          information about the Program Merchant to American Express; and (ii)
          American Express to use such information to perform its
          responsibilities in connection with the Program, promote the American
          Express Network, perform analytics and create reports, and for any
          other lawful business purposes, including commercial marketing
          communications purposes within the parameters of the Program
          Agreement, and important transactional or relationship communications
          from American Express
        </Paragraph>
        <Paragraph>
          5. A provision to ensure data quality and that Transaction Data and
          customer information is processed promptly, accurately and completely,
          and complies with the American Express Technical Specifications.
        </Paragraph>

        <Paragraph>
          6. A marketing opt-out mechanism and/or process for Program. A
          marketing opt-out mechanism should contain a clear disclosure to
          Program Merchants that opting-out of marketing messages will not
          preclude them from receiving important transactional or relationship
          communications from American Express. As guidance, Participant may
          refer to the sample language provided below for an illustrative
          example of acceptable formatting for a marketing opt-out mechanism.
        </Paragraph>

        {!isGeneric && (
          <Paragraph>
            <RHFCheckbox
              disabledStyles={{
                stroke: palette.neutral[40],
                "stroke-width": 1.6,
              }}
              name={"expressCheckbox"}
              dataTestId="express-checkbox-tos"
              label="By checking this box, you are opting out of receiving future
          commercial marketing communications from Express."
              disabled={true}
            />
          </Paragraph>
        )}

        <Paragraph>
          Note that you may continue to receive marketing communications while
          American Express updates its records to reflect your choice. Opting
          out of commercial marketing communications will not preclude you from
          receiving important transactional or relationship messages from
          American Express.
        </Paragraph>

        <Paragraph>
          7. An express disclosure to Program Merchant that American Express may
          use the information obtained in the Program Merchant application at
          the time of setup to screen and/or monitor Program Merchant in
          connection with Card marketing and administrative purposes.
        </Paragraph>
        <Paragraph>
          8. An acknowledgment from the Program Merchant that it may be
          converted from the Program to a direct Card acceptance relationship
          with American Express if and when it becomes a High CV Merchant in
          accordance. This acknowledgment must include express agreement that,
          upon conversion, (i) the Program Merchant will be bound by American
          Express&apos; then-current Card Acceptance Agreement; and (ii)
          American Express will set pricing and other fees payable by the
          Program Merchant for Card acceptance.
        </Paragraph>
        <Paragraph>
          9. An express agreement that the Program Merchant shall not assign to
          any third party any payments due to it under their respective Merchant
          Agreements, and all indebtedness arising from Charges will be for bona
          fide sales of goods and services (or both) at its Establishments and
          free of liens, claims, and encumbrances other than ordinary sales
          taxes; provided, however, that the Program Merchant may sell and
          assign future Transaction receivables to Participant, its affiliated
          entities and/or any other cash advance funding source that partners
          with Participant or its affiliated entities, without consent of
          American Express.
        </Paragraph>
        <Paragraph>
          10. A third-party beneficiary provision, conferring on American
          Express third-party beneficiary rights, but not obligations, to the
          Merchant Agreement that will fully provide American Express with the
          ability to enforce the terms of the Merchant Agreement against the
          Program Merchant.
        </Paragraph>
        <Paragraph>
          11. An express option for the Program Merchant to opt out of accepting
          Cards at any time without penalty and without directly or indirectly
          affecting its rights to accept Other Payment Products.
        </Paragraph>
        <Paragraph>
          12. A right for Participant to terminate the Program Merchant&apos;s
          right to accept Cards if it breaches any of the provisions or the
          American Express Merchant Operating Guide.
        </Paragraph>
        <Paragraph>
          13. A requirement that Participant has the right to immediately
          terminate a Program Merchant for cause or fraudulent or other
          activity, or upon American Express&apos; request.
        </Paragraph>
        <Paragraph>
          14. A requirement that Program Merchant&apos;s refund policies for
          purchases on the Card must be at least as favorable as its refund
          policy for purchases on any Other Payment Products, and the refund
          policy be disclosed to Cardmembers at the time of purchase and in
          compliance with Applicable Law.
        </Paragraph>
        <Paragraph>
          15. A prohibition against billing or collecting from any Cardmember
          for any purchase or payment on the Card unless Chargeback has been
          exercised, the Program Merchant has fully paid for such Charge, and it
          otherwise has the right to do so.
        </Paragraph>
        <Paragraph>
          16. A requirement to comply with all Applicable Laws, rules and
          regulations relating to the conduct of the Program Merchant&apos;s
          business.
        </Paragraph>
        <Paragraph>
          17. A requirement that Program Merchant must comply with the American
          Express Data Security Requirements (DSR) and Payment Card Industry
          Data Security Standard (PCI DSS).
        </Paragraph>
        <Paragraph>
          18. A requirement that Program Merchant must report all instances of a
          Data Incident immediately to Participant after discovery of the
          incident.
        </Paragraph>
        <Paragraph>
          19. A requirement to cease all use of, and remove American Express
          Licensed Marks from the Program Merchant&apos;s website and wherever
          else they are displayed upon termination of the Merchant Agreement or
          a Program Merchant&apos;s participation in the Program.
        </Paragraph>
        <Paragraph>
          20. A requirement that Program Merchants are responsible for being
          aware of and adhering to privacy and data protection laws and provide
          specific and adequate disclosures to Cardmembers of collection, use,
          and processing of personal data.
        </Paragraph>
        <Paragraph>
          ii. In addition to providing American Express with copies of
          Participant&apos;s standard Merchant Agreement in accordance with
          these Operating Regulations, Participant must keep on file and provide
          to American Express, upon request, copies of its executed Merchant
          Agreements with Program Merchants in accordance with the records
          retention requirements.
        </Paragraph>
        <Paragraph>b. American Express Merchant Operating Guide</Paragraph>
        <Paragraph>
          i. The American Express Merchant Operating Guide sets forth the
          policies and procedures governing Program Merchants&apos; acceptance
          of the Card. Participant shall ensure that Participant&apos;s
          personnel interacting with Program Merchants are fully familiar with
          the American Express Merchant Operating Guide and that the American
          Express Merchant Operating Guide is incorporated by reference into the
          Merchant Agreement.
        </Paragraph>
        <Paragraph>
          ii. Participant is responsible for its Program Merchants&apos; and
          their respective Establishments&apos; compliance with and performance
          of all provisions of the American Express Merchant Operating Guide,
          available from American Express online at
          www.americanexpress.com/merchantopguide.
        </Paragraph>
        <Paragraph>
          iii. Participant must provide Program Merchants with the
          aforementioned URL in order for Program Merchants to access the
          American Express Merchant Operating Guide. The method of disclosing
          the URL to Program Merchants must be in the same manner as provided
          with the Other Payment Products (e.g., URL embedded in the Merchant
          Agreement). Participant is jointly and severally liable with each
          Program Merchant for any Program Merchant&apos;s non- compliance or
          non-performance of the provisions of the American Express Merchant
          Operating Guide.
        </Paragraph>
        <Paragraph>
          iv. American Express reserves the right to make changes to the
          American Express Merchant Operating Guide in scheduled changes and at
          any time in unscheduled changes. The American Express Merchant
          Operating Guide and releases of scheduled changes (sometimes called
          &quot;Notification of Changes&quot; in American Express materials)
          therein are made available only in electronic form, existing at the
          website specified in the definition of &quot;Merchant Operating
          Guide&quot; in the American Express Merchant Operating Guide or its
          successor website.
        </Paragraph>
        <SubHeading>c. Card Acceptance Verification</SubHeading>
        <Paragraph>
          i. Participant must ensure proof of Card acceptance from each Program
          Merchant Prospect by obtaining a complete and executed Merchant
          Agreement (in paper form) or, through any of the following, as
          applicable: (i) an archived voice recording, (ii) evidence of
          acceptance by obtaining Program Merchant&apos;s IP Address, timestamp
          and acknowledgment for online web- based Program applications, or
          (iii) other formats approved in writing by American Express (e.g.,
          including the first time a Card is accepted at an Establishment). In
          addition, Participant shall provide each new Program Merchant with a
          form of confirmation acknowledging that they have agreed to accept the
          Card prior to the date the Program Merchant begins accepting the Card.
        </Paragraph>
        <Paragraph>d. Electronic Signatures (E-Signatures)</Paragraph>
        <Paragraph>
          i. When implementing an online merchant application process,
          Participant shall obtain a valid Electronic Signature from Program
          Merchant, including compliance with the following minimum
          requirements:
        </Paragraph>
        <Paragraph>
          1. Proper presentation of appropriate electronic contracting and
          Electronic Signature disclosures and ability to capture Program
          Merchant&apos;s consent to contract electronically with Participant
          and to Electronically Sign the application;
        </Paragraph>
        <Paragraph>
          2. The online application should be an electronic version of the
          offline application with addition of a Program Merchant signature line
          that enables the authorized signer to Electronically Sign the
          application;
        </Paragraph>
        <Paragraph>
          3. Enabling a process for the Program Merchant to review, fill in any
          required fields, correct any errors and provide its Electronic
          Signature on the application; and
        </Paragraph>
        <Paragraph>
          4. Presentation of a confirmation that the Program Merchant has
          completed the process and is ready to submit the Electronically Signed
          application.
        </Paragraph>
        <Paragraph>
          ii. In the event Participant utilizes a third party to implement
          and/or provide Participant&apos;s online Program Merchant application
          process, such third party shall be considered Participant&apos;s
          subcontractor for which the Participant is liable under the Program
          Agreement.
        </Paragraph>
        <Paragraph>e. Merchant Cancellation or Disentitlement</Paragraph>
        <Paragraph>i. Cancellation Notification and Reporting</Paragraph>
        <Paragraph>
          It may be necessary to suspend, cancel, or disentitle a Program
          Merchant of Card acceptance for a number of reasons, including for
          cause (which may include circumstances that create harm or loss of
          goodwill to the Participant and/or American Express), or fraudulent or
          other activities in compliance with these Operating Regulations and/or
          the American Express Merchant Operating Guide, or upon American
          Express&apos; request.
        </Paragraph>
        <Paragraph>
          Participant will implement suspension, cancellation, or disentitlement
          of a Program Merchant of Card Acceptance as follows:
        </Paragraph>
        <Paragraph>
          1. within two (2) Business Days, following (i) American Express&apos;
          request to suspend, cancel, or disentitle a Program Merchant, (ii)
          receipt of the Seller Canceled Derogatory Notification (SCDNO) File or
          (iii) any Program Merchant identified as a High CV Merchant and
          American Express has provided notice requiring Participant to convert
          the Program Merchant to a direct Card acceptance relationship with
          American Express; and
        </Paragraph>
        <Paragraph>
          2. immediately, upon determination by Participant that a Program
          Merchant is to be terminated for any other reason, including cause, or
          fraudulent or other activities in compliance with these Operating
          Regulations and/or the American Express Merchant Operating Guide.
        </Paragraph>
        <Paragraph>
          ii. Participant must provide American Express with confirmation of
          Program Merchant cancellations by indicating the cancellation through
          the Sponsored Merchant Information Interface. Refer to the American
          Express Technical Specifications for further information
        </Paragraph>
        <Paragraph>
          Participant must report applicable Program Merchants to the
          Mastercard® MATCH or Terminated Merchant File (TMF) Report database
          through the standard reporting process as soon as possible following
          the cancellation or disentitlement of Card acceptance for derogatory
          reasons, but no later than close of business on the day following the
          date such Program Merchant is notified of the intent to terminate its
          Merchant Agreement.
        </Paragraph>
        <SubHeading>Merchant Marketing Opt-Outs</SubHeading>
        <Paragraph>
          A. Participant must ensure that Program Merchants agree when providing
          their contact information to Participant that, they consent to
          receiving commercial marketing messages from American Express,
          including important information about American Express products,
          services, and resources available to its business. These messages may
          be sent to the mailing address, phone numbers, email addresses or fax
          numbers Program Merchant provides. If a Program Merchant provides a
          wireless phone number, Participant must ensure that the Program
          Merchant consents that it may be contacted at that number and the
          communications sent may include autodialed short message service (SMS
          or &quot;text&quot;) messages or automated or prerecorded calls. If a
          Program Merchant provides a fax number, Participant must ensure that
          the Program Merchant agrees that it may be sent fax communications.
          American Express may otherwise use and share Program Merchant
          information for business purposes and as permitted by Applicable Law.
          American Express uses reasonable administrative, technical and
          physical security measures to protect Program Merchant information
          consistent with the sensitivity of the information.
        </Paragraph>
        <Paragraph>
          B. Participant must provide Program Merchants a choice to opt-out of
          receiving American Express commercial marketing communications about
          products and services. Participant may collect this choice by using an
          &quot;opt-out checkbox&quot; or other acceptable means such as
          directing Program Merchants who wish to opt-out to contact Participant
          directly by inbound telephone, email, website and any other means
          identified by Participant. If a Program Merchant has opted-out,
          Participant must disclose to Program Merchant that it may continue to
          receive important transactional or relationship communications from
          American Express. Participant must identify Program Merchants through
          the Sponsored Merchant Information Interface that opt-out in order for
          American Express to update its records accordingly.
        </Paragraph>
        <Paragraph>
          C. If a Program Merchant opts out from receiving American Express
          related marketing, Participant shall not, and shall ensure that its
          Participant Sales Entities do not, send such Program Merchant further
          marketing that appears to have originated or involved American
          Express. Participant shall disclose to Program Merchant that it may
          continue to receive marketing communications from American Express
          while American Express updates its records to reflect the
          Merchant&apos;s opt-out choice.
        </Paragraph>
        <Heading>
          Exhibit E: If Not Stated Above Merchant (Sub-Merchant) Agrees to the
          Following
        </Heading>
        <Paragraph>
          Sub-merchant acknowledges and agrees it is responsible for its
          employees&rsquo; actions, and that Sub-merchant will comply with all
          applicable laws and regulations and all applicable parts of the
          Operating Regulations, including the ownership and use of Association
          Marks. In the event of any conflict between the Provider Merchant
          Agreement, and the Operating Regulations, the Operating Regulations
          will control.
        </Paragraph>
        <Paragraph>
          Sub-merchant to notify payment facilitator of any 3rd party that will
          have access to cardholder data, and must require Sub-merchant and its
          suppliers and agents to comply with the provisions of the Cardholder
          Information Security Program (CISP) and PCI DSS, or other security
          program as required by an Association, and have the ability to
          demonstrate this compliance.
        </Paragraph>
        <Paragraph>
          An authorized Association to audit or direct the audit of a Sub-
          merchant at any time, and the right of an Association to limit or
          terminate an agreement with a Sub-merchant.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not submit any transaction that was previously
          charged back to the Acquirer and subsequently returned to the
          Sub-merchant, irrespective of Cardholder approval. Sub-merchant may
          pursue payment from the customer outside the system.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not knowingly submit, and payment facilitator must
          not knowingly accept from a Sub- merchant for submission into the
          payment system, any transaction that is illegal or that the
          Sub-merchant should have known was illegal. Such transaction must be
          legal in both Cardholder&rsquo;s and Sub-merchant&rsquo;s
          jurisdiction.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not submit a transaction that it knows, or should
          have known is either fraudulent or not authorized by the Cardholder.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not require a Cardholder to complete a postcard or
          similar device that includes the Cardholder&rsquo;s account number,
          Card expiration date, signature, or any other Card account data in
          plain view when mailed.
        </Paragraph>
        <Paragraph>
          Sub-merchant may not requesta Card Verification
          Value2(&ldquo;CVV2&rdquo;) for a card-present transaction, nor retain
          or store any portion of the magnetic-stripe data subsequent to the
          authorization of a sales transaction, nor any other data prohibited by
          the Operating Regulations and/or the Payment Facilitator Agreement
          between Processor and Provider, including CVV2.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not add a surcharge to transactions, except as
          expressly permitted by, and in full compliance with, the Operating
          Regulations.
        </Paragraph>
        <Paragraph>
          Sub-merchant may set a minimum transaction amount to accept a Card
          that provides access to a credit account, under the following
          conditions:
        </Paragraph>
        <UList>
          <ListElement>
            The minimum transaction amount does not differentiate between
            Issuers; and
          </ListElement>
          <ListElement>
            The minimum transaction amount does not differentiate between
            Mastercard and another acceptance brand; and
          </ListElement>
          <ListElement>
            The minimum transaction amount does not exceed USD10 (or any higher
            amount established by the Federal Reserve by regulation)
          </ListElement>
        </UList>
        <Paragraph>
          Sub-merchant may set a maximum transaction amount to accept a Card
          that provides access to a credit account, under the following
          conditions:
        </Paragraph>
        <UList>
          <ListElement>
            The Merchant is a department, agency or instrumentality of the U.S.
            Government; or
          </ListElement>
          <ListElement>
            The Merchant is a corporation owned or controlled by the U.S.
            Government; or
          </ListElement>
          <ListElement>
            The Merchant whose primary business is reflected by one of the
            following MCCs:
          </ListElement>
        </UList>
        <UList>
          <ListElement>
            MCC 8220 &ndash; Colleges, Universities, Professional Schools,
            Junior Colleges; or
          </ListElement>
          <ListElement>
            MCC 8244 &ndash; Schools, Business and Secretarial; or
          </ListElement>
          <ListElement>
            MCC 8249 &ndash; Schools, Trade and Vocational; and
          </ListElement>
        </UList>
        <UList>
          <ListElement>
            The maximum transaction amount does not differentiate between
            Issuers; and
          </ListElement>
          <ListElement>
            The maximum Transaction amount does not differentiate between
            Mastercard and another acceptance brand.
          </ListElement>
        </UList>
        <Paragraph>
          Sub-merchant must not disburse funds in the form of cash unless:
        </Paragraph>
        <UList>
          <ListElement>
            Sub-merchant is participating in full compliance with a program
            supported by an Association for such cash disbursements.
          </ListElement>
          <ListElement>
            Sub-merchant is dispensing funds in the form of travelers cheques,
            TravelMoney, or similarly authorized cards, or foreign currency. In
            this case, the transaction amount is limited to the value of the
            travelers cheques, TravelMoney, or similarly authorized Card, or
            foreign currency, plus any commission or fee properly charged by
            Sub-merchant.
          </ListElement>
        </UList>
        <Paragraph>
          Sub-merchant must not disburse funds in the form of travelers cheques
          if the sole purpose is to allow the Cardholder to make a cash purchase
          of goods or services from that Sub-merchant.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not submit a transaction that does not result from
          an act between the Cardholder and the Sub-merchant.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not accept a Card issued by a U.S. Issuer, to
          collect or refinance an existing debt, unless expressly authorized by,
          and in compliance with, Operating Regulations.
        </Paragraph>
        <UList>
          <ListElement>
            A Sub-merchant must not accept a Card to collect or refinance an
            existing debit that has been deemed uncollectable by the
            Sub-merchant providing the associated goods or services.
          </ListElement>
          <ListElement>
            Sub-merchant must not submit a transaction that represents
            collection of a previous Card charge.
          </ListElement>
          <ListElement>
            Sub-merchant must not submit a transaction that represents
            collection of a dishonored check.
          </ListElement>
        </UList>
        <Paragraph>
          Sub-merchant must not request or use a Card account number for any
          purpose other than as payment for its goods or services.
        </Paragraph>
        <Paragraph>
          Sub-merchant must not add any tax to transactions, unless applicable
          law expressly requires that a Sub- merchant be permitted to impose a
          tax. Any tax amount, if allowed, must be included in the transaction
          amount and not collected separately.
        </Paragraph>
        <Heading>Contacting Give Corporation</Heading>
        <Paragraph>
          If you have questions or concerns regarding this policy, you may email
          us at{" "}
          <a href="mailto:legal@givecorporation.com">
            legal@givecorporation.com
          </a>
          .
        </Paragraph>
        <Paragraph>You can also write to us at:</Paragraph>
        <Paragraph>
          Give Corporation
          <br />
          Privacy Policy
          <br />
          <AddressSpan>4343 N Scottsdale Road Suite &#35;150</AddressSpan>
          <br />
          <AddressSpan>Scottsdale, AZ 85251</AddressSpan>
          <br />
        </Paragraph>
        <Paragraph>
          <strong>The Gist,</strong>
          <br />
          You can reach us through email or postal mail regarding the Privacy
          policy.
        </Paragraph>
      </main>
      <Footer>© {new Date().getFullYear()} Give Corporation, Inc.</Footer>
    </TosWrapper>
  );
};

const Table = styled.table`
  font-size: 12px;
  width: 100%;
`;

const TData = styled.td`
  padding-bottom: 10px;
`;

const AddressSpan = styled.span`
  color: #1a1a1a;
`;

const Header = styled.header`
  height: 50px;
  text-align: center;
`;

const Footer = styled.footer`
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  background: #fafafa;
  color: #767f84;
  font-size: 14px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 10px 0 10px 0;
  word-break: break-word;
`;

const Title = styled.h2`
  text-align: center;
  font-weight: 500;
  font-size: 26px;
`;

const Heading = styled.h3`
  font-weight: 500;
  font-size: 20px;
  padding: 20px 0 0 0;
  margin: 0;
`;

const SubHeading = styled.h4`
  font-weight: 500;
  font-size: 16px;
  padding: 20px 0 0 0;
  margin: 0;
`;

const Version = styled.h6`
  color: ${palette.neutral[70]};
  font-weight: 350;
  font-size: 12px;
  margin: 0 0 4px 0;
  padding: 0;
`;

const UList = styled.ul`
  margin: 0px 20px 0px 20px;
  padding: 0px;
  padding-left: 20px;
`;

const OList = styled.ol`
  margin: 0px 20px 0px 20px;
  padding: 0px;
  padding-left: 20px;
`;

const ListElement = styled.li`
  font-size: 14px;
  margin: 0;
  padding: 5px;
`;

const SubTitle = styled.p`
  color: black;
  font-weight: 500;
  margin: 2px;
  font-size: 14px;
`;

export default memo(TermsOfService);
