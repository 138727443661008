import { SvgIconProps } from "@mui/material";

const ConversationsIcon = ({
  height = 20,
  width = 17,
  fill = "#575353",
}: SvgIconProps) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ChatsCircle">
        <path
          id="Vector"
          d="M27.1632 22.8761C27.8243 21.5321 28.1539 20.0492 28.1241 18.5517C28.0944 17.0541 27.7063 15.5855 26.9923 14.2688C26.2783 12.9521 25.2591 11.8256 24.0203 10.9838C22.7814 10.1419 21.3589 9.6091 19.8718 9.43004C19.3786 8.28034 18.6603 7.24098 17.7592 6.37319C16.8581 5.5054 15.7924 4.82674 14.6249 4.37719C13.4575 3.92764 12.2118 3.7163 10.9614 3.75561C9.71105 3.79492 8.48116 4.08409 7.34425 4.60609C6.20734 5.12808 5.1864 5.87234 4.34159 6.79502C3.49677 7.7177 2.84517 8.80013 2.42517 9.97854C2.00517 11.1569 1.82527 12.4075 1.89606 13.6565C1.96686 14.9055 2.28692 16.1277 2.83739 17.2511L1.93973 20.3941C1.85963 20.6753 1.85612 20.9728 1.92957 21.2557C2.00301 21.5387 2.15075 21.797 2.35748 22.0037C2.56422 22.2104 2.82244 22.3582 3.10543 22.4316C3.38842 22.5051 3.68589 22.5015 3.96707 22.4214L7.11004 21.5238C8.05845 21.9894 9.07863 22.2917 10.1276 22.4179C10.6277 23.5894 11.3612 24.6468 12.2834 25.5254C13.2057 26.404 14.2973 27.0855 15.4916 27.5282C16.686 27.9709 17.958 28.1656 19.2301 28.1004C20.5022 28.0351 21.7477 27.7113 22.8905 27.1488L26.0335 28.0464C26.3147 28.1265 26.6121 28.1301 26.8951 28.0566C27.1781 27.9832 27.4363 27.8354 27.6431 27.6287C27.8498 27.422 27.9975 27.1637 28.071 26.8807C28.1444 26.5978 28.1409 26.3003 28.0608 26.0191L27.1632 22.8761ZM25.2577 23.0332L26.1425 26.1281L23.0475 25.2433C22.8118 25.177 22.5594 25.2056 22.3444 25.323C20.6162 26.2632 18.5881 26.4874 16.6963 25.9474C14.8044 25.4074 13.2002 24.1463 12.2288 22.4355C13.5125 22.3018 14.7548 21.9043 15.8776 21.2678C17.0004 20.6313 17.9796 19.7697 18.7537 18.7369C19.5278 17.7041 20.0801 16.5225 20.3759 15.2662C20.6718 14.0099 20.7048 12.706 20.4729 11.4363C21.5963 11.701 22.6434 12.2215 23.5328 12.9571C24.4221 13.6927 25.1297 14.6237 25.6004 15.6775C26.0711 16.7313 26.2923 17.8795 26.2467 19.0328C26.201 20.186 25.8899 21.3132 25.3374 22.3265C25.2191 22.5425 25.1904 22.7963 25.2577 23.0332Z"
          fill="#575353"
        />
      </g>
    </svg>
  );
};

export default ConversationsIcon;
