import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import DescriptionText from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/DescriptionText";
import EnabledBy from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/EnabledBy";
import { Text } from "@common/Text";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";

export interface TriggerHistoryData {
  firstName: string;
  lastName: string;
  imageURL: string;
  reason: string;
  updatedAt: number;
}

interface IComponentProps extends TriggerHistoryData {
  isCurrentlyEnabled: boolean;
}

const TriggerHistoryCard = ({
  isCurrentlyEnabled,
  firstName,
  lastName,
  imageURL,
  reason,
  updatedAt,
}: IComponentProps) => {
  return (
    <RootStack mb={2} data-testid="trigger-history-card">
      <Stack direction="row" justifyContent="space-between">
        <EnabledBy
          withTooltip={false}
          isEnabled={isCurrentlyEnabled}
          firstName={firstName}
          lastName={lastName}
          imageURL={imageURL}
        />
        <Text fontSize={12} fontWeight="book" color={palette.neutral[70]}>
          {formatDistanceToNowStrict(fromUnixTime(updatedAt), {
            addSuffix: true,
          })}
        </Text>
      </Stack>
      <DescriptionText value={reason} />
    </RootStack>
  );
};

const RootStack = styled(Stack)(({ theme }) => ({
  backgroundColor: palette.neutral.white,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  gap: theme.spacing(2),
  boxShadow: theme.custom.shadows[1],
}));

export default TriggerHistoryCard;
