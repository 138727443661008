import { useCallback, useMemo, useState } from "react";
import { ZoomHandler } from "../types";
import { throttle } from "lodash";
import { normalizeBetweenTwoRanges } from "@utils/index";

type TZoomParams = {
  step?: number;
  minZoom?: number;
  maxZoom?: number;
  defaultValue?: number;
  maxZoomEqualsDeviceSize?: boolean;
  isPdf?: boolean;
};

const DEFAULT_ZOOM_VALUE = 1;
const STEP = 0.1;
const MIN_ZOOM = 0.2;
const MAX_ZOOM = 2;

const DEVICE_WIDTH = window.innerWidth;
const DEVICE_HEIGHT = window.innerHeight;

const useZoom = (params?: TZoomParams) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM_VALUE);
  const [originalSize, setOriginalSize] = useState({
    width: params?.isPdf ? 595 : 0,
    height: 0,
  }); //value is fixed for pdf

  const isImageBiggerThanScreen =
    !params?.isPdf &&
    (originalSize.width > DEVICE_WIDTH || originalSize.height > DEVICE_HEIGHT);

  const maxZoomUsed = useMemo(() => {
    if (!params?.maxZoomEqualsDeviceSize) return params?.maxZoom;
    const { width, height } = originalSize;
    if (isImageBiggerThanScreen) {
      if (width - DEVICE_WIDTH > height - DEVICE_HEIGHT) {
        const max = DEVICE_WIDTH / width;
        return Math.ceil(max * 10) / 10;
      } else {
        const max = DEVICE_HEIGHT / height;
        return Math.ceil(max * 10) / 10;
      }
    }
    if (DEVICE_HEIGHT - height > DEVICE_WIDTH - width || params.isPdf) {
      return calculateMaxZoom(width, DEVICE_WIDTH);
    } else return calculateMaxZoom(height, DEVICE_HEIGHT);
  }, [originalSize, params?.maxZoomEqualsDeviceSize, isImageBiggerThanScreen]);

  const step = sanitizedValue(STEP, params?.step);
  const minZoom = sanitizedValue(MIN_ZOOM, params?.minZoom);
  const maxZoom = sanitizedValue(MAX_ZOOM, maxZoomUsed);
  const defaultValue = sanitizedValue(DEFAULT_ZOOM_VALUE, params?.defaultValue);

  const onZoom: ZoomHandler = useCallback(
    throttle((zoomFactor) => {
      if (typeof zoomFactor === "number") {
        const pinchMinZoom = 0.2;
        const pinchMaxZoom = 5.0;
        const normalizedScale = normalizeBetweenTwoRanges(
          zoomFactor > 2 ? 2 : zoomFactor,
          [0.0, 2.0],
          [pinchMinZoom, pinchMaxZoom],
        );

        const roundedValue = roundValue(normalizedScale);
        if (roundedValue > pinchMaxZoom || roundedValue < pinchMinZoom) return;
        setZoom(roundedValue);
      }
      const direction = zoomFactor;
      setZoom((prev) => roundValue(prev + (direction === "in" ? step : -step)));
    }, 200),
    [minZoom, maxZoom],
  );

  const resetZoom = () => setZoom(defaultValue);
  const resetOriginalSize = () => setOriginalSize({ width: 0, height: 0 });
  const resetLoading = () => setIsLoading(true);

  const onLoadImageSetSize = (
    e: any,
    customSize?: { width: number; height: number },
  ) => {
    setOriginalSize({
      width: e?.target?.width || customSize?.width || 595, //fix number is used for PDF because we always render same size
      height: e?.target?.height || customSize?.height || e?.clientHeight,
    });
    setIsLoading(false);
  };

  const disabled = {
    in: roundValue(zoom + step) > maxZoom,
    out: zoom - step < minZoom,
  };

  return {
    zoom,
    resetZoom,
    onZoom,
    disabled,
    onLoadImageSetSize,
    resetOriginalSize,
    isImageBiggerThanScreen,
    isLoading,
    resetLoading,
  };
};

const sanitizedValue = (defaultValue: number, value?: number) => {
  if (typeof value !== "number") return defaultValue;
  return value;
};

const roundValue = (value: number) => {
  return Math.round((value + Number.EPSILON) * 10) / 10;
};

const calculateMaxZoom = (value: number, maxValue: number) => {
  const originalSizePercantage = (value * 100) / maxValue; //to understand if the file is closer to the maxScreenWidth or maxScreenHeight
  const maxZoomPercentage = 100 / originalSizePercantage; //get the original file-size's percentage as decimal compared to device size
  const max = Math.ceil(DEFAULT_ZOOM_VALUE * maxZoomPercentage * 10) / 10; //multiply the deafult and round it to the nearest one decimal place to receive the max
  return max;
};

export default useZoom;
