import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { Text } from "@common/Text";
import { SectionContent } from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/Form";
import {
  feeScheduleSection,
  merchantApplicationSection,
} from "@components/Merchants/CreateMerchantPanel/modals/components/MerchantAgreement/merchantAgreement.const";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useState } from "react";
import { calculateFeePerTransaction } from "../../components/MerchantFees";
import { IParsedData, TFeeObject } from "../../data.types";
import AgreementSnapshotSkeleton from "./AgreementSnapshotSkeleton";
import SnapshotAgreementHeader from "./Header";
import useAgreementSnapshot from "./hooks/useAgreementSnapshot";
import AgreementSnapshotTabs, { TAgreementSnapshotTabs } from "./SnapshotTabs";

type Props = {
  onClose: () => void;
  handleManageMerchantAgreement: () => void;
  merchantId: number;
  data: IParsedData;
};

const snapshotSelectedPage = {
  "Merchant Application": ({ data }: { data: IParsedData }) => (
    <SectionContent section={merchantApplicationSection(data)} />
  ),
  "Fees Schedule": ({ data }: { data: IParsedData }) => {
    const fees = [
      {
        label: "Credit Card Fee",
        fee: data?.fees?.creditCardFee.value,
        feePerTransaction: calculateFeePerTransaction(
          data?.fees?.creditCardFee as TFeeObject,
        ),
      },
      {
        label: "Debit Card Fee",
        fee: data?.fees?.debitCardFee.value,
        feePerTransaction: calculateFeePerTransaction(
          data?.fees?.debitCardFee as TFeeObject,
        ),
      },
      {
        label: "AMEX Credit Card Fee",
        fee: data?.fees?.amexCreditCardFee.value,
        feePerTransaction: calculateFeePerTransaction(
          data?.fees?.amexCreditCardFee as TFeeObject,
        ),
      },
    ];

    return <SectionContent section={feeScheduleSection(fees)} />;
  },
  "Refund Policy": ({ data }: { data: IParsedData }) => (
    <Text fontWeight="book" color={palette.neutral[70]}>
      {data.merchantAgreement.msaRefundPolicy}
    </Text>
  ),
};

const AgreementSnapshot = ({
  handleManageMerchantAgreement,
  onClose,
  merchantId,
  data,
}: Props) => {
  const { data: snapshot, isLoading } = useAgreementSnapshot({
    merchantId,
    enabled: data?.status?.statusName === "approved",
  });

  const [selectedTab, setSelectedTab] = useState<TAgreementSnapshotTabs>(
    "Merchant Application",
  );

  const SnapshotPage = snapshotSelectedPage[selectedTab];

  const snapshotData =
    data?.status?.statusName === "approved" ? snapshot : data;

  return (
    <Stack
      bgcolor="#FAFAFA"
      width={CAMPAIGN_PANEL_WIDTH - 30 + "px"}
      height="100%"
      borderRadius="16px"
      gap={2}
      padding="12px 16px"
      boxShadow="0px 4px 15px 0px #0000000D"
      sx={{
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none" /* IE and Edge */,
        scrollbarWidth: "none" /* Firefox */,
      }}
    >
      <SnapshotAgreementHeader
        handleManageMerchantAgreement={handleManageMerchantAgreement}
        onClose={onClose}
      />
      <AgreementSnapshotTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <SnapshotPagesContainer>
        {isLoading ? (
          <AgreementSnapshotSkeleton />
        ) : (
          <SnapshotPage data={snapshotData as IParsedData} />
        )}
      </SnapshotPagesContainer>
    </Stack>
  );
};

const SnapshotPagesContainer = styled(Box)(() => ({
  "& h5": {
    fontSize: 18,
    fontWeight: "400",
    color: palette.neutral[70],
    lineHeight: "120%",
    height: "30px",
  },
  "& > div:not(:last-child)": {
    paddingBottom: "12px",
    borderBottom: `1px solid ${palette.neutral[10]}`,
    marginBottom: "16px",
  },
}));

export default AgreementSnapshot;
