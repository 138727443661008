import { useEffect, useState } from "react";
import useGetNotificationsList from "./useGetNoticationsList";
import { TNotificationsFilter } from "../types";

const useNotificationsCenter = (open: boolean) => {
  const [selectedFilter, setSelectedFilter] =
    useState<TNotificationsFilter>("unread");

  const { listState } = useGetNotificationsList(!open);
  const {
    data,
    isLoading,
    isEmpty,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    totalPages,
  } = listState[selectedFilter];

  useEffect(() => {
    if (!open) setSelectedFilter("unread");
  }, [open]);

  const filters = [
    {
      label: "Unread",
      onClick: () => setSelectedFilter("unread"),
      selected: selectedFilter === "unread",
      badge: {
        display: !listState.unread.isLoading,
        text: `${listState.unread.total}`,
        variant: listState.unread.hasUnreadMandatory ? "orange" : "default",
      },
    },
    {
      label: "All notifications",
      onClick: () => setSelectedFilter("all"),
      selected: selectedFilter === "all",
      badge: null,
    },
  ];

  return {
    isLoading,
    data,
    isEmpty,
    filters,
    showMarkAllAsRead: listState.unread.total > 0,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    totalPages,
    selectedFilter,
  };
};

export default useNotificationsCenter;
