import { ColumnBaseType, TableColumnType } from "@common/Table/helpers";
import { DateColumn } from "./DateColumn";
import { DescriptionColumn } from "./DescriptionColumn";
import { MerchantColumn } from "./MerchantColumn";
import { TypeColumn } from "./TypeColumn";
import BalanceCell from "@components/ManageMoney/TransactionTable/components/BalanceCell";
import { StatusColumn } from "./StatusColumn";
import { useMemo } from "react";
import { selectQueryFilters } from "@redux/slices/transactions";
import { useAppSelector } from "@redux/hooks";
import { AlertComp } from "./AlertDotColumn";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

export const columnTestIDs = {
  firstCheckedAt: "sorting-column-firstCheckedAt",
  "settledAt,-createdAt": "sorting-column-createdAt",
  description: "sorting-column-description",
  merchantName: "sorting-column-merchantName",
  displayType: "sorting-column-displayType",
  balanceAdjustment: "sorting-column-balanceAdjustment",
  displayStatus: "sorting-column-displayStatus",
} as const;

export const useGetColumns = (isMerchant = false) => {
  const queryFilters = useAppSelector(selectQueryFilters);
  const { isMediumView, isLargeView } = useCustomTheme();

  const {
    createdAtWidth,
    balanceAdjustmentWidth,
    descriptionWidth,
    displayStatusWidth,
    displayTypeWidth,
    merchantNameWidth,
  } = getColumnsWidth(isMerchant, isLargeView, isMediumView);

  const columns = useMemo(
    () => [
      {
        key: "firstCheckedAt",
        testid: columnTestIDs["firstCheckedAt"],
        sortable: false,
        columnWidth: 0.1 / 6, //takes 1/6 width with the createdAt column
        title: "",
        renderColumn: AlertComp,
      },
      {
        key: "settledAt,-createdAt",
        testid: columnTestIDs["settledAt,-createdAt"],
        columnWidth: createdAtWidth,
        sortable: true,
        title: "Date",
        renderColumn: DateColumn,
      },
      {
        key: "description",
        testid: columnTestIDs["description"],
        columnWidth: descriptionWidth,
        title: "Description",
        renderColumn: DescriptionColumn,
      },
      {
        key: "merchantName",
        testid: columnTestIDs["merchantName"],
        sortable: true,
        title: "Merchant",
        columnWidth: merchantNameWidth,
        type: "processing-merchant" as ColumnBaseType,
        renderColumn: MerchantColumn,
        hideColumn: !isLargeView || isMerchant,
      },
      {
        key: "displayType",
        testid: columnTestIDs["displayType"],
        title: "Type",
        columnWidth: displayTypeWidth,
        renderColumn: TypeColumn,
        hideColumn: isMediumView,
      },
      {
        key: "balanceAdjustment",
        testid: columnTestIDs["balanceAdjustment"],
        sortable: true,
        title: "In / Out (USD)",
        columnWidth: balanceAdjustmentWidth,
        type: "in-out" as ColumnBaseType,
        headerPosition: "right" as TableColumnType["headerPosition"],
        renderColumn: BalanceCell,
        hideColumn: isMediumView,
      },
      {
        key: "displayStatus",
        testid: columnTestIDs["displayStatus"],
        headerPosition: "center" as TableColumnType["headerPosition"],
        sortable: false,
        title: "Status",
        columnWidth: displayStatusWidth,
        type: "status" as ColumnBaseType,
        renderColumn: StatusColumn,
      },
    ],
    [queryFilters.blocked, isLargeView, isMediumView, isMerchant],
  );

  return columns;
};

const getColumnsWidth = (
  isMerchant: boolean,
  isLargeView: boolean,
  isMediumView: boolean,
) => {
  if (isMerchant)
    return {
      createdAtWidth: !isMediumView ? 1 / 5 : 1 / 3,
      descriptionWidth: !isMediumView ? 1.35 / 5 : 1.4 / 3,
      merchantNameWidth: 0,
      displayTypeWidth: !isMediumView ? 1 / 5 : 0,
      balanceAdjustmentWidth: !isMediumView ? 0.75 / 5 : 0,
      displayStatusWidth: !isMediumView ? 0.8 / 5 : 0.4 / 3,
    };

  return {
    createdAtWidth: isLargeView ? 1 / 6 : !isMediumView ? 1 / 5 : 1 / 3,
    descriptionWidth: isLargeView
      ? 1.35 / 6
      : !isMediumView
      ? 1.25 / 5
      : 1.4 / 3,
    merchantNameWidth: isLargeView ? 1 / 6 : 0,
    displayTypeWidth: isLargeView ? 1 / 6 : !isMediumView ? 1 / 5 : 0,
    balanceAdjustmentWidth: isLargeView
      ? 0.75 / 6
      : !isMediumView
      ? 0.75 / 5
      : 0,
    displayStatusWidth: isLargeView
      ? 0.75 / 6
      : !isMediumView
      ? 0.75 / 5
      : 0.4 / 3,
  };
};

export const columns = [
  {
    key: "settledAt,-createdAt",
    columnWidth: 1.5 / 6,
    sortable: true,
    title: "Date",
    renderColumn: DateColumn,
  },
  {
    key: "description",
    columnWidth: 2 / 6,
    title: "Description",
    renderColumn: DescriptionColumn,
  },
  {
    key: "merchant",
    sortable: true,
    title: "Merchant",
    columnWidth: 1 / 6,
    type: "processing-merchant" as ColumnBaseType,
    renderColumn: MerchantColumn,
  },
  {
    key: "displayType",
    title: "Type",
    columnWidth: 0.5 / 6,
    renderColumn: TypeColumn,
  },
  {
    key: "balanceAdjustment",
    sortable: true,
    title: "In / Out (USD)",
    columnWidth: 1.25 / 6,
    type: "in-out" as ColumnBaseType,
    headerPosition: "right" as TableColumnType["headerPosition"],
    renderColumn: BalanceCell,
  },
  {
    key: "displayStatus",
    sortable: false,
    title: "Status",
    columnWidth: 0.75 / 6,
    type: "status" as ColumnBaseType,
    renderColumn: StatusColumn,
  },
];
