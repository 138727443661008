import { Text } from "@common/Text";
import { palette } from "@palette";
import { Stack, styled } from "@mui/material";
import { memo } from "react";
import { CheckCircle, Prohibit, WarningCircle } from "@phosphor-icons/react";

export type TRiskLevelName = "normal" | "high" | "restricted";

type Props = {
  riskLevelName?: string | null;
};

const RiskStatusChip = ({ riskLevelName }: Props) => {
  if (!isValidRiskLevelName(riskLevelName))
    return <StyledText>Not Assigned</StyledText>;

  const { color, label, icon } =
    merchantRiskStatus[riskLevelName as TRiskLevelName];

  return (
    <Stack direction="row" alignItems="center" gap="4px">
      {icon}
      <StyledText color={color}>{label}</StyledText>
    </Stack>
  );
};

type StatusObject = { label: string; color: string; icon: JSX.Element };

const colors = {
  green: palette.tag.success.text,
  orange: palette.tag.warning.hover,
  red: palette.error.hover,
};

const merchantRiskStatus: Record<TRiskLevelName, StatusObject> = {
  normal: {
    label: "Normal",
    color: colors.green,
    icon: <CheckCircle weight="duotone" size={18} color={colors.green} />,
  },
  high: {
    label: "High",
    color: colors.orange,
    icon: <WarningCircle weight="duotone" size={18} color={colors.orange} />,
  },
  restricted: {
    label: "Restricted",
    color: colors.red,
    icon: <Prohibit weight="duotone" size={18} color={colors.red} />,
  },
};

const StyledText = styled(Text)<{ color?: string }>(({ color }) => ({
  fontSize: "12px",
  lineHeight: "14.4px",
  color: color || palette.neutral[80],
}));

export default memo(RiskStatusChip);

export const isValidRiskLevelName = (value: any) => {
  return Object.keys(merchantRiskStatus).includes(value);
};
