import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
  stroke?: string;
}

const DisputesIcon = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();

  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="disputes-icon"
      >
        <path
          d="M12 5.90625V18.0938"
          stroke="url(#paint0_linear_3038_3236)"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2812 9.65625C15.2813 9.34846 15.2206 9.04369 15.1028 8.75934C14.9851 8.47498 14.8124 8.21661 14.5948 7.99897C14.3771 7.78133 14.1188 7.60869 13.8344 7.49091C13.5501 7.37312 13.2453 7.3125 12.9375 7.3125H11.0625C10.4409 7.3125 9.84476 7.55943 9.40522 7.99897C8.96568 8.43851 8.71875 9.03465 8.71875 9.65625C8.71875 10.2779 8.96568 10.874 9.40522 11.3135C9.84476 11.7531 10.4409 12 11.0625 12H13.4062C14.0279 12 14.624 12.2469 15.0635 12.6865C15.5031 13.126 15.75 13.7221 15.75 14.3438C15.75 14.9654 15.5031 15.5615 15.0635 16.001C14.624 16.4406 14.0279 16.6875 13.4062 16.6875H10.5938C9.97215 16.6875 9.37601 16.4406 8.93647 16.001C8.49693 15.5615 8.25 14.9654 8.25 14.3438"
          stroke="url(#paint1_linear_3038_3236)"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.75 12L21 14.25L23.25 12"
          stroke="url(#paint2_linear_3038_3236)"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 12L3 9.75L5.25 12"
          stroke="url(#paint3_linear_3038_3236)"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9.75V12C2.99929 13.9055 3.60337 15.762 4.72524 17.3022C5.84712 18.8424 7.42888 19.9868 9.24273 20.5706C11.0566 21.1543 13.0089 21.1474 14.8185 20.5506C16.6282 19.9539 18.2017 18.7982 19.3125 17.25"
          stroke="url(#paint4_linear_3038_3236)"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 14.25V12C21.0007 10.0945 20.3966 8.23799 19.2748 6.69778C18.1529 5.15758 16.5711 4.01318 14.7573 3.42941C12.9434 2.84564 10.9911 2.85263 9.18147 3.44937C7.37185 4.04611 5.79832 5.2018 4.6875 6.74999"
          stroke="url(#paint5_linear_3038_3236)"
          strokeWidth="1.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3038_3236"
            x1="13"
            y1="5.90625"
            x2="10.9637"
            y2="6.08192"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3038_3236"
            x1="15.75"
            y1="7.3125"
            x2="6.73938"
            y2="14.8915"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3038_3236"
            x1="23.25"
            y1="12"
            x2="21.5474"
            y2="15.5803"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3038_3236"
            x1="5.25"
            y1="9.75"
            x2="3.54737"
            y2="13.3303"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint4_linear_3038_3236"
            x1="19.3125"
            y1="9.75"
            x2="9.24171"
            y2="25.0987"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
          <linearGradient
            id="paint5_linear_3038_3236"
            x1="21"
            y1="2.99664"
            x2="10.9292"
            y2="18.3454"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="disputes-icon"
    >
      <path
        d="M12 5.90625V18.0938"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2812 9.65625C15.2813 9.34846 15.2206 9.04369 15.1028 8.75934C14.9851 8.47498 14.8124 8.21661 14.5948 7.99897C14.3771 7.78133 14.1188 7.60869 13.8344 7.49091C13.5501 7.37312 13.2453 7.3125 12.9375 7.3125H11.0625C10.4409 7.3125 9.84476 7.55943 9.40522 7.99897C8.96568 8.43851 8.71875 9.03465 8.71875 9.65625C8.71875 10.2779 8.96568 10.874 9.40522 11.3135C9.84476 11.7531 10.4409 12 11.0625 12H13.4062C14.0279 12 14.624 12.2469 15.0635 12.6865C15.5031 13.126 15.75 13.7221 15.75 14.3438C15.75 14.9654 15.5031 15.5615 15.0635 16.001C14.624 16.4406 14.0279 16.6875 13.4062 16.6875H10.5938C9.97215 16.6875 9.37601 16.4406 8.93647 16.001C8.49693 15.5615 8.25 14.9654 8.25 14.3438"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 12L21 14.25L23.25 12"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 12L3 9.75L5.25 12"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.75V12C2.99929 13.9055 3.60337 15.762 4.72524 17.3022C5.84712 18.8424 7.42888 19.9868 9.24273 20.5706C11.0566 21.1543 13.0089 21.1474 14.8185 20.5506C16.6282 19.9539 18.2017 18.7982 19.3125 17.25"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14.25V12C21.0007 10.0945 20.3966 8.23799 19.2748 6.69778C18.1529 5.15758 16.5711 4.01318 14.7573 3.42941C12.9434 2.84564 10.9911 2.85263 9.18147 3.44937C7.37185 4.04611 5.79832 5.2018 4.6875 6.74999"
        stroke={stroke}
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DisputesIcon;
