import RefundPolicyInput from "./RefundPolicyInput";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import { useEffect } from "react";

const MAX_TEXT_LENGTH = "256";

type FormType = {
  description: string;
};

type Props = {
  id: number;
  description: string;
  isHelper?: boolean;
  disableInput?: boolean;
};

const RefundPolicyForm = ({
  id,
  description,
  isHelper,
  disableInput = false,
}: Props) => {
  const { handleSubmit } = useUpdateMerchantInfo(id);

  const defaultValues = {
    description: description || "30 Days of Purchase",
  };

  const schema = Yup.object().shape({
    description: Yup.string().required(),
  });

  const methods = useForm<FormType>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset, watch } = methods;
  const values = watch();

  const onBlur: SubmitHandler<FormType> = (data) => {
    if (data.description === defaultValues.description) return;
    handleSubmit("merchant_agreement", { msaRefundPolicy: data.description });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [description]);

  return (
    <FormProvider {...methods}>
      <RefundPolicyInput
        name="description"
        label="Refund policy"
        placeholder=""
        multiline
        rows={3}
        fullWidth
        inputProps={{
          maxLength: MAX_TEXT_LENGTH,
          disabled: disableInput,
        }}
        helperText={
          isHelper
            ? `${values.description?.length || 0}/${MAX_TEXT_LENGTH} Characters`
            : null
        }
        onBlur={methods.handleSubmit(onBlur)}
      />
    </FormProvider>
  );
};

export default RefundPolicyForm;
