import { FileUploadIcon } from "@assets/icons";
import { TrashIcon } from "@assets/icons/RebrandedIcons/TrashIcon";
import { Eye } from "@phosphor-icons/react";
import { Text, TruncateText } from "@common/Text";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { bytesToSize } from "@utils/index";
import { useState } from "react";
import { isFunction } from "lodash";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";

type TDocumentListItem = {
  document?: TMerchantDocument;
  fileName: string;
  fileSize: number;
  onDelete?: (fileName: string) => void;
  onPreview?: (document: TMerchantDocument) => void;
  backgroundColor?: string;
  hoverColor?: string;
};
export const DocumentListItem = ({
  document,
  fileName,
  fileSize,
  onDelete,
  onPreview,
  backgroundColor = palette.neutral.white,
  hoverColor = palette.neutral[5],
}: TDocumentListItem) => {
  const { sizeString } = bytesToSize(fileSize);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const triggerOver = () => setIsHovered(true);
  const disableOver = () => setIsHovered(false);
  return (
    <DocumentBase
      backgroundColor={backgroundColor}
      hoverColor={hoverColor}
      onMouseOver={triggerOver}
      onMouseLeave={disableOver}
      data-testid="document-item"
    >
      <FileUploadIcon />
      <Stack direction="column" flex={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TruncateText width="75%" color="neutral.80" lineClamp={1}>
            {fileName}
          </TruncateText>
          {!isHovered && (
            <Text color="gray.300" variant="caption" justifySelf="flex-end">
              {sizeString}
            </Text>
          )}
        </Stack>
      </Stack>
      {isHovered && (
        <Stack>
          {isFunction(onDelete) && (
            <Box onClick={() => onDelete(fileName)} sx={{ cursor: "pointer" }}>
              <TrashIcon width={24} height={24} />
            </Box>
          )}
          {isFunction(onPreview) && document && (
            <Box
              data-testid="preview-icon"
              onClick={() => {
                onPreview(document);
              }}
              sx={{ cursor: "pointer" }}
            >
              <Eye size={20} color={palette.gray[300]} weight="regular" />
            </Box>
          )}
        </Stack>
      )}
    </DocumentBase>
  );
};

const DocumentBase = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<BoxProps & { backgroundColor?: string; hoverColor?: string }>(
  ({ backgroundColor, hoverColor }) => ({
    flexDirection: "row",
    gap: "8px",
    height: "48px",
    alignItems: "center",
    borderRadius: "8px",
    padding: "12px 16px 12px 16px",
    backgroundColor: backgroundColor,
    cursor: "default",
    "&:hover": {
      backgroundColor: hoverColor,
    },
  }),
);
