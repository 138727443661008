import { Button } from "@common/Button";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { TPrimaryAction } from "./types";

export type ModalDrawerActionsProps = {
  onModalClose?: () => void;
  primaryAction: TPrimaryAction;
  handleBack: () => void;
  handleSubmitStep: () => void;
  isBack?: boolean;
  steps?: boolean;
  isPreBuildFormBuilder?: boolean;
  stepIndex?: number;
};

const ModalDrawerActions = ({
  onModalClose,
  primaryAction,
  handleBack,
  handleSubmitStep,
  steps,
  stepIndex,
  isPreBuildFormBuilder,
}: ModalDrawerActionsProps) => {
  const { isDesktopView } = useCustomTheme();

  return (
    <DrawerActions issteps={Boolean(steps) || !!isPreBuildFormBuilder}>
      {isDesktopView && onModalClose && (
        <Button
          background="text"
          size="medium"
          style={{
            visibility: !isPreBuildFormBuilder ? "visible" : "hidden",
          }}
          onClick={onModalClose}
          data-testid="modal-cancel-button"
        >
          Cancel
        </Button>
      )}
      <Stack
        direction={isDesktopView ? "row" : "column"}
        alignItems={isDesktopView ? "center" : "stretch"}
        gap={isDesktopView ? 2 : 1}
        sx={{ ...(!isDesktopView && { flexGrow: 1 }) }}
      >
        {steps && stepIndex !== 0 && (
          <Button
            background="text"
            size="medium"
            onClick={handleBack}
            data-testid="modal-back-button"
          >
            Back
          </Button>
        )}
        <Button
          {...primaryAction}
          onClick={
            primaryAction.type !== "submit" ? handleSubmitStep : undefined
          }
          variant="primary"
          size="medium"
          fullWidth={!isDesktopView}
          data-testid="modal-next-button"
        >
          {primaryAction.label}
        </Button>
      </Stack>
    </DrawerActions>
  );
};

const DrawerActions = styled(Box, {
  shouldForwardProp: (prop) => prop !== "issteps",
})<BoxProps & { issteps?: boolean }>(({ issteps, theme }) => ({
  display: "flex",
  alignItems: "center",
  ...(issteps && {
    justifyContent: "space-between",
    marginTop: "50px",
    padding: "0px 16px 16px 16px",
  }),
  [theme.breakpoints.down("sm")]: {
    ...(!issteps && {
      position: "sticky",
      bottom: 0,
      zIndex: 99,
      padding: "0px 16px 16px 16px",
    }),
  },
  ...(issteps && {
    gap: "24px",
  }),
}));

export default ModalDrawerActions;
