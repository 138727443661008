import { palette } from "@palette";
import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Text } from "@common/Text";
import { isFunction } from "lodash";
import { ITextProps } from "@common/Text/Text";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

export type TagType =
  | "pending"
  | "action required"
  | "under review"
  | "won"
  | "lost"
  | "escalated"
  | "closed";

const getColorsMap = (type: TagType) => {
  const colorsMap = {
    pending: palette.tag.blue.text,
    "action required": palette.filled.orange,
    "under review": palette.filled.orange,
    won: palette.tag.success.text,
    lost: palette.tag.error.hover,
    escalated: palette.neutral[80],
    closed: palette.neutral[80],
  };

  return colorsMap[type];
};

interface DisputesTableTagProps extends BoxProps {
  type: TagType;
  renderComponent?: (color: string) => JSX.Element;
  isTextCenter?: boolean;
  active?: boolean;
  isOriginal?: boolean;
  textProps?: ITextProps;
  textColor?: string;
  bgColor?: string;
}

export const DisputesTableTag = ({
  type,
  renderComponent,
  isTextCenter = true,
  sx,
  active = true,
  isOriginal = false,
  textProps,
  textColor,
  bgColor,
  ...rest
}: DisputesTableTagProps) => {
  const color = getColorsMap(type);
  const { isMobileView } = useCustomTheme();
  const classes = useStyles({ isMobileView });

  return (
    <Box
      className={`${classes.wrapper} `}
      sx={{
        backgroundColor: active ? (bgColor ? bgColor : bgMap[type]) : "#EBEBEB",
        cursor: active ? "default" : "pointer",
        transition: "all 1s ease-in-out",
        ...sx,
      }}
      {...rest}
    >
      <Text
        color={active ? (textColor ? textColor : color) : palette.black[100]}
        variant="body"
        textTransform="capitalize"
        width="100%"
        textAlign={isTextCenter ? "center" : "left"}
        fontSize="12px"
        lineHeight="14.4px"
        {...textProps}
        data-testid={`transaction-tab-${type}`}
      >
        {type}
        {isOriginal ? " (Original Transaction)" : ""}
      </Text>
      {isFunction(renderComponent) &&
        renderComponent(
          active ? (textColor ? textColor : color) : palette.black[100],
        )}
    </Box>
  );
};

const useStyles = ({ isMobileView }: { isMobileView: boolean }) => {
  return makeStyles(() => ({
    wrapper: {
      gap: "2px",
      display: "flex",
      borderRadius: "100px",
      alignItems: "center",
      padding: "4px 8px",
      userSelect: "none",
      ...(isMobileView ? { maxWidth: "160px" } : { minWidth: "160px" }),
    },
  }))();
};

const backgrounds = {
  green: palette.tag.success.bg,
  orange: palette.tag.warning.bg,
  red: palette.tag.error.bg,
  gray: "#EBEBEB",
  blue: palette.tag.blue.bg,
};
const { green, orange, red, gray, blue } = backgrounds;

const bgMap: Record<TagType, string> = {
  pending: blue,
  "action required": orange,
  "under review": orange,
  won: green,
  lost: red,
  escalated: gray,
  closed: gray,
};
