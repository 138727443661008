import { RHFInput } from "@common/Input";
import { memo } from "react";
import { FormProvider } from "react-hook-form";
import BottomButtons from "./BottomButtons";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { RHFSwitch } from "@common/Switch";
import { palette } from "@palette";
import { RHFCheckbox } from "@common/Checkbox";
import useCampaignDetails from "../hooks/useCampaignDetails";
import ImageGallery from "features/Minibuilders/ImageGallery";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import CustomDatePicker from "@common/DatePickers/CustomDatePicker";

interface Props {
  handleBack: () => void;
  handleNext: () => void;
  handleUpdateStatusValue: (value: number) => void;
  disabled: boolean;
}
function CampaignDetails({
  handleNext,
  handleBack,
  handleUpdateStatusValue,
  disabled,
}: Props) {
  const { watch, setValue, methods, saveValues, loading, isSweepstakeForm } =
    useCampaignDetails({
      handleUpdateStatusValue,
      disabled,
    });

  const handleSelect = (item: any) => {
    const isSelected = item?.URL === watch("image")?.URL;
    setValue("image", isSelected ? null : item, { shouldDirty: true });
  };

  const values = watch();

  return (
    <FormProvider {...methods}>
      <Stack spacing={1} width="100%">
        <Stack direction="row" gap={1}>
          <RHFInput
            name="title"
            fullWidth
            type="text"
            label="Title"
            error={false}
          />
          {isSweepstakeForm && <CustomDatePicker name="endDate" />}
        </Stack>
        <RHFInput
          name="description"
          label="Description (recommended)"
          multiline
          rows={8}
          fullWidth
          inputProps={{
            maxLength: `${MAX_TEXT_LENGTH}`,
          }}
          helperText={`${MAX_TEXT_LENGTH - watch("description").length}`}
        />
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyItems: "center",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
        margin="10px 0"
      >
        <RHFSwitch name="featuredImage" />
        <Text
          marginTop="7px"
          color={palette.neutral[80]}
          fontSize={14}
          fontWeight={"medium"}
          align="left"
        >
          Featured Image
        </Text>
        <Text
          fontSize={14}
          color={palette.neutral[70]}
          fontWeight={"medium"}
          marginTop="3px"
          align="left"
        >
          This image will be displayed at the top of the payment form so it
          should be representative of your Payment form. Minimum size
          recommended 2000 &times; 2000{" "}
        </Text>
      </Box>
      {values.featuredImage && (
        <>
          <Box display="flex" alignItems="flex-start">
            <RHFCheckbox name="isBackgroud" />
            <Box>
              <Text fontSize={14} color="#575353" align="left">
                Use as background image
              </Text>
              <Text mt={0.5} fontSize={14} color="#8F8F8F" align="left">
                If checked this image will be integrated in the background of
                the form, can be later changed{" "}
              </Text>
            </Box>
          </Box>
          <ImageGallery
            handleSelect={handleSelect}
            selectedImage={watch("image")}
          />
        </>
      )}
      <Divider sx={{ width: "100%", margin: "3px 0" }} />
      <Grid container alignItems="center" width="100%">
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <RHFCheckbox
              name="browse_more"
              label="Browse more Payment Forms"
              disabled={true}
              labelProps={{ color: palette.neutral[70], fontSize: 14 }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Text
            width="auto"
            maxWidth="70%"
            bgcolor={palette.info.light}
            padding="5px 12px"
            color={palette.info.main}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontSize: "auto",
              maxWidth: "100%",
            }}
          >
            Coming soon
          </Text>
        </Grid>
        <Text
          color={palette.neutral[70]}
          align="left"
          fontSize={14}
          marginTop={1}
        >
          Allow users to explore more of your published Payment forms.
        </Text>
      </Grid>

      <BottomButtons
        goBack={() => saveValues(handleBack)}
        goNext={() => saveValues(handleNext)}
        disabled={disabled || loading}
      />
    </FormProvider>
  );
}

export default memo(CampaignDetails);
