import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
  path_fill?: string;
};

const GreyHourGlassIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5374 5.8075V3.75H15.1855C15.3574 3.75 15.5223 3.68415 15.6438 3.56694C15.7654 3.44973 15.8337 3.29076 15.8337 3.125C15.8337 2.95924 15.7654 2.80027 15.6438 2.68306C15.5223 2.56585 15.3574 2.5 15.1855 2.5H4.81514C4.64324 2.5 4.47838 2.56585 4.35683 2.68306C4.23528 2.80027 4.16699 2.95924 4.16699 3.125C4.16699 3.29076 4.23528 3.44973 4.35683 3.56694C4.47838 3.68415 4.64324 3.75 4.81514 3.75H5.46329V5.8075C5.46054 6.62059 5.69408 7.41815 6.13742 8.10978C6.58076 8.80142 7.21617 9.35945 7.97162 9.72063L8.55107 10L7.97162 10.2794C7.21617 10.6405 6.58076 11.1986 6.13742 11.8902C5.69408 12.5818 5.46054 13.3794 5.46329 14.1925V16.25H4.81514C4.64324 16.25 4.47838 16.3158 4.35683 16.4331C4.23528 16.5503 4.16699 16.7092 4.16699 16.875C4.16699 17.0408 4.23528 17.1997 4.35683 17.3169C4.47838 17.4342 4.64324 17.5 4.81514 17.5H15.1855C15.3574 17.5 15.5223 17.4342 15.6438 17.3169C15.7654 17.1997 15.8337 17.0408 15.8337 16.875C15.8337 16.7092 15.7654 16.5503 15.6438 16.4331C15.5223 16.3158 15.3574 16.25 15.1855 16.25H14.5374V14.1925C14.5401 13.3794 14.3066 12.5818 13.8632 11.8902C13.4199 11.1986 12.7845 10.6405 12.029 10.2794L11.4496 10L12.029 9.72063C12.7845 9.35945 13.4199 8.80142 13.8632 8.10978C14.3066 7.41815 14.5401 6.62059 14.5374 5.8075ZM6.75958 5.8075V3.75H13.2411V5.8075C13.2431 6.38827 13.0764 6.95797 12.7598 7.452C12.4431 7.94602 11.9892 8.34459 11.4496 8.6025L10.0003 9.30125L8.55107 8.6025C8.01143 8.34459 7.55755 7.94602 7.2409 7.452C6.92425 6.95797 6.75751 6.38827 6.75958 5.8075ZM13.2411 14.1925V16.25H6.75958V14.1925C6.75751 13.6117 6.92425 13.042 7.2409 12.548C7.55755 12.054 8.01143 11.6554 8.55107 11.3975L10.0003 10.6987L11.4496 11.3975C11.9892 11.6554 12.4431 12.054 12.7598 12.548C13.0764 13.042 13.2431 13.6117 13.2411 14.1925Z"
        fill={fill ? fill : "#87858D"}
      ></path>
    </svg>
  );
};
export default GreyHourGlassIcon;
