import { customInstance } from "../index";
import { useQuery } from "react-query";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { ProductParams, useQueryFactory } from "./queryFactory";
import { buildMerchantEndpoints } from "../utils.api";

export const addFundraiser = (query: any) => {
  return customInstance({
    url: "merchants/2/customers",
    method: "POST",
    data: query,
  });
};

export const getFundraisers = ({
  queryString,
  page,
  sorting,
  searchQuery,
}: ProductParams) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  return customInstance({
    url: buildMerchantEndpoints(
      `products?filter=typeName:"fundraiser"${queryString}&sort=${
        sorting ?? "-id"
      }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    ),
    method: "GET",
  });
};

export const useGetFundraisers = useQueryFactory("fundraiser");

export const getFundraiserById = (id: string) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}?filter=typeName:"fundraiser"`),
    method: "GET",
  });
};

export const useGetFundraiserById = (id: string) => {
  return useQuery(
    ["get-fundraiser-by-id", id],
    async () => {
      const data = await getFundraiserById(id);
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false },
  );
};
