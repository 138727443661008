type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const RiskProfileShield = ({
  width = 21,
  height = 20,
  stroke = "#FF5924",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M17.0498 4.375V8.96641C17.0498 15.543 11.4787 17.7219 10.367 18.0914C10.2425 18.1344 10.1071 18.1344 9.98262 18.0914C8.8709 17.7234 3.2998 15.5469 3.2998 8.96797V4.375C3.2998 4.20924 3.36565 4.05027 3.48286 3.93306C3.60007 3.81585 3.75904 3.75 3.9248 3.75H16.4248C16.5906 3.75 16.7495 3.81585 16.8667 3.93306C16.984 4.05027 17.0498 4.20924 17.0498 4.375Z"
        fill={stroke}
      />
      <path
        d="M16.4248 3.125H3.9248C3.59328 3.125 3.27534 3.2567 3.04092 3.49112C2.8065 3.72554 2.6748 4.04348 2.6748 4.375V8.96641C2.6748 15.9672 8.59824 18.2898 9.78418 18.6844C10.0375 18.7705 10.3121 18.7705 10.5654 18.6844C11.7529 18.2898 17.6748 15.9672 17.6748 8.96641V4.375C17.6748 4.04348 17.5431 3.72554 17.3087 3.49112C17.0743 3.2567 16.7563 3.125 16.4248 3.125ZM16.4248 8.96797C16.4248 15.0945 11.2412 17.1414 10.1748 17.4977C9.11777 17.1453 3.9248 15.1 3.9248 8.96797V4.375H16.4248V8.96797Z"
        fill={stroke}
      />
    </svg>
  );
};

export default RiskProfileShield;
