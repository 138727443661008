import { useAppDispatch } from "@redux/hooks";
import { setHasUnseenBlockedTransactions } from "@redux/slices/transactions";
import { getLastBlockedTransaction } from "@services/api/products/transactions";

const useCheckLastUnseen = (merchantId: number) => {
  const dispatch = useAppDispatch();

  const setUnseenTransactions = async () => {
    if (!merchantId) return;
    const data = await getLastBlockedTransaction();
    const hasNewThx =
      data?.total > 0 &&
      data?.data?.[0] &&
      data.data[0].firstCheckedAt === null &&
      data.data[0].isBlocked;
    dispatch(setHasUnseenBlockedTransactions(hasNewThx));
  };

  return { setUnseenTransactions };
};

export default useCheckLastUnseen;
