import * as React from "react";
// mui
import {
  styled,
  useTheme,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  SxProps,
} from "@mui/material";
// import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
// form
import { useFormContext, Controller } from "react-hook-form";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { isEmpty, isFunction } from "lodash";
import { FontWeight } from "@common/Text/Text";

interface CheckboxProps extends MuiCheckboxProps {
  label?: React.ReactNode;
  labelProps?: {
    color?: string;
    fontSize?: number;
    textAlign?: "left" | "right" | "center" | "justify";
    fontWeight?: FontWeight;
    marginTop?: string;
  };
  helperText?: React.ReactNode;
  size?: "small" | "medium";
  dataTestId?: string;
  disabledStyles?: any;
  formSx?: SxProps;
}
interface HelperTextProps extends FormHelperTextProps {
  size?: string;
}

const HelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== "size",
})<HelperTextProps>(() => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 300,
  textTransform: "none",
  color: "#8F8F8F", // neutral[70]
}));

const Checkbox = ({
  label,
  helperText,
  size,
  name,
  sx,
  disabled,
  ...rest
}: CheckboxProps) => {
  // const theme = useTheme();

  return (
    <Box
      sx={{
        display: helperText ? "inline-block" : "inline",
        ...sx,
      }}
    >
      <FormControlLabel
        label={
          <>
            {label}
            {helperText && typeof helperText === "string" ? (
              <HelperText size={size}>{helperText}</HelperText>
            ) : (
              <>{helperText}</>
            )}
          </>
        }
        control={
          <MuiCheckbox
            name={name}
            size={size}
            disabled={disabled}
            {...rest}
            sx={{
              width: "42px",
              height: "42px",
              input: {
                visibility: "hidden",
              },
            }}
          />
        }
        sx={{
          color: "#403D3D", // neutral[90]
          ...(!label && {
            marginRight: 1,
          }),
          ...(disabled && {
            cursor: "default",
          }),
          "input:checked + svg": {
            borderRadius: "6px",
            backgroundColor: palette.neutral[950],
          },
        }}
      />
    </Box>
  );
};

export default Checkbox;

// ------------------------------------------------------------------------------------------------------------

export const RHFCheckbox = ({
  label,
  helperText,
  size,
  name,
  sx,
  labelProps,
  disabled,
  dataTestId,
  disabledStyles,
  formSx,
  ...props
}: CheckboxProps & {
  name: string;
}) => {
  const theme = useTheme();
  const context = useFormContext();
  const { control } = context;

  return (
    <Box
      sx={{
        display: helperText ? "inline-block" : "inline",
        ...sx,
      }}
    >
      <FormControlLabel
        label={
          <>
            <Text
              fontSize={labelProps?.fontSize}
              color={labelProps?.color ?? palette.neutral[800]}
              textAlign={labelProps?.textAlign}
              fontWeight={labelProps?.fontWeight}
              marginTop={labelProps?.marginTop}
            >
              {label}
            </Text>
            {helperText && typeof helperText === "string" ? (
              <HelperText size={size}>{helperText}</HelperText>
            ) : (
              <>{helperText}</>
            )}
          </>
        }
        sx={{
          color: theme.palette.neutral[900],
          ...(!label && {
            marginRight: 1,
          }),
          ...(disabled && {
            cursor: "default",
          }),
          ...formSx,
        }}
        control={
          <Controller
            name={name}
            control={control}
            render={({
              field: { ref, value = false, ...rest },
              fieldState: { error },
            }) => (
              <MuiCheckbox
                inputProps={
                  {
                    role: "checkbox",
                    "data-testid": dataTestId,
                  } as React.InputHTMLAttributes<HTMLInputElement> & {
                    "data-testid": string;
                  }
                }
                inputRef={ref}
                {...rest}
                checked={value}
                name={name}
                size={size}
                color="secondary"
                disabled={disabled}
                {...props}
                onChange={(e) => {
                  isFunction(rest?.onChange) && rest.onChange(e);
                  isFunction(props?.onChange) && props?.onChange(e, value);
                }}
                sx={{
                  width: "42px",
                  height: "42px",
                  ...(!!error && {
                    "input + svg > rect": {
                      stroke: palette.filled.red,
                    },
                  }),
                  input: {
                    visibility: "hidden",
                  },
                  "input:checked + svg": {
                    borderRadius: "6px",
                    backgroundColor: palette.neutral[950],
                    ...(disabled && !isEmpty(disabledStyles) && disabledStyles),
                  },
                }}
              />
            )}
          />
        }
      />
    </Box>
  );
};
