interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
  opacity?: number;
}

const CancelIcon = ({
  width = 24,
  height = 24,
  fill = "none",
  path_fill = "#8F8F8F",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 15L5 5M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
        stroke={path_fill}
        strokeWidth="1.5"
      />
    </svg>
  );
};
export default CancelIcon;
