import { useStatusReview } from "../hooks/useStatusReview";
import { Text } from "@common/Text";
import ApproveDeclineButtons from "../sections/StatusReview/components/ApproveDeclineButtons";
import { statusOverviewList } from "../sections/StatusReview/utils/status";
import { useMemo } from "react";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export const UnderwritingActions = ({
  isEnterprise,
  status,
  underwritingScorePercentage,
  name,
}: any) => {
  const { handleInitialApproval, handleDecline, handleApprove } =
    useStatusReview({
      data: status,
      name,
      isEnterprise,
    });
  const { isSponsorApprovalEnabled } = useGetFeatureFlagValues();

  const isStatusNameCanonic = ["declined", "suspended", "approved"].includes(
    status.statusName,
  );

  const statusListItems = statusOverviewList(
    {
      primaryAccount: status.approvedOwner,
      businessProfile: status.hasApprovedLegalEntity,
      bankAccount: status.hasApprovedBankAccount,
      merchantAgreement: status.approvedAgreement,
      score: underwritingScorePercentage || 0,
    },
    isEnterprise,
  );

  const notApprovedList = useMemo(() => {
    const notApprovedList: string[] = [];

    statusListItems.forEach((status) => {
      if (!status.approved) {
        notApprovedList.push(status.label);
      }
    });

    return notApprovedList;
  }, [statusListItems]);

  return (
    <>
      <Text>
        All of the above criteria must be met before approving this account.
      </Text>
      <ApproveDeclineButtons
        handleApprove={
          isSponsorApprovalEnabled ? handleInitialApproval : handleApprove
        }
        handleDecline={handleDecline}
        isEnterprise={isEnterprise}
        isReadyToApprove={notApprovedList.length < 1}
        isStatusNameCanonic={isStatusNameCanonic}
        statusName={status.statusName}
        notApprovedList={notApprovedList}
      />
    </>
  );
};
