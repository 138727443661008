import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const localCartItems = localStorage.getItem("cartItems");

const cartItemsData = localCartItems !== null ? JSON.parse(localCartItems) : [];

export type CartItemType = {
  name?: string;
  productVariantID: number;
  quantity: number;
  price: number;
  recurringInterval?: string;
  recurringFrequency?: number;
  recurringMax?: number | null;
};

export interface CartState {
  cartItems: CartItemType[];
  isCartLoading: boolean;
}

const initialState: CartState = {
  cartItems: cartItemsData,
  isCartLoading: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItems: (state: CartState, action: PayloadAction<CartItemType[]>) => {
      state.cartItems = action.payload;
      // localStorage.setItem("cartItems", JSON.stringify(action.payload))
    },
    setCartItem: (state: CartState, action: PayloadAction<CartItemType>) => {
      const existingItem = state.cartItems.find(
        (item) => item.productVariantID === action.payload.productVariantID,
      );

      if (existingItem && action.payload.quantity > 0) {
        const updatedItems = state.cartItems.map((item) => {
          if (existingItem.productVariantID === item.productVariantID)
            return action.payload;
          return item;
        });

        state.cartItems = updatedItems;
      } else if (existingItem && action.payload.quantity === 0) {
        state.cartItems = state.cartItems.filter(
          (item) => item.productVariantID !== action.payload.productVariantID,
        );
      } else {
        state.cartItems.push(action.payload);
      }

      // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    setCartLoading: (state: CartState, action: PayloadAction<boolean>) => {
      state.isCartLoading = action.payload;
    },
  },
});

export const { setCartItems, setCartItem, setCartLoading } = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart;

export const findCartItemQuantity = (id: number) => (state: RootState) =>
  state.cart.cartItems.find((item) => item.productVariantID === id)?.quantity ||
  0;

export const findCartItem = (id: number) => (state: RootState) =>
  state.cart.cartItems.find((item) => item.productVariantID === id);

export const selectCartTotal = (state: RootState) =>
  state.cart.cartItems.reduce(
    (sum: number, item: CartItemType) => sum + item.price * item.quantity,
    0,
  );

export default cartSlice.reducer;
