import { Components } from "@mui/material";
import { text } from "./text";
import { button, buttonBase } from "./button";
import { checkbox } from "./checkbox";
import { textField } from "./textField";
import { gSwitch } from "./switch";
import { cssBaseline } from "./cssBaseline";
import { iconButton } from "./iconButton";
import { formControlLabel } from "./formControlLabel";
import { menu } from "./menu";
import { dialog } from "./dialog";
import { backdrop } from "./backdrop";
import { select } from "./select";
import { tab, tabs } from "./tabs";

export const components: Components = {
  MuiTypography: text,
  MuiButton: button,
  MuiCheckbox: checkbox,
  MuiTextField: textField,
  MuiSwitch: gSwitch,
  MuiCssBaseline: cssBaseline,
  MuiIconButton: iconButton,
  MuiButtonBase: buttonBase,
  MuiFormControlLabel: formControlLabel,
  MuiMenu: menu,
  MuiDialog: dialog,
  MuiBackdrop: backdrop,
  MuiTab: tab,
  MuiTabs: tabs,
  MuiSelect: select,
};
