type Props = {
  width?: number;
  height?: number;
  fill?: string;
  rotate?: number;
};

const CaretRight = ({
  width = 32,
  height = 32,
  fill = "#575353",
  rotate = 0,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M22.7076 16.7077L12.7076 26.7077C12.6147 26.8006 12.5044 26.8743 12.383 26.9246C12.2616 26.9749 12.1315 27.0007 12.0001 27.0007C11.8687 27.0007 11.7386 26.9749 11.6172 26.9246C11.4958 26.8743 11.3855 26.8006 11.2926 26.7077C11.1997 26.6148 11.126 26.5045 11.0757 26.3831C11.0254 26.2617 10.9995 26.1316 10.9995 26.0002C10.9995 25.8688 11.0254 25.7387 11.0757 25.6173C11.126 25.4959 11.1997 25.3856 11.2926 25.2927L20.5863 16.0002L11.2926 6.70769C11.1049 6.52005 10.9995 6.26555 10.9995 6.00019C10.9995 5.73483 11.1049 5.48033 11.2926 5.29269C11.4802 5.10505 11.7347 4.99963 12.0001 4.99963C12.2654 4.99963 12.5199 5.10505 12.7076 5.29269L22.7076 15.2927C22.8005 15.3856 22.8743 15.4959 22.9246 15.6172C22.975 15.7386 23.0009 15.8688 23.0009 16.0002C23.0009 16.1316 22.975 16.2617 22.9246 16.3831C22.8743 16.5045 22.8005 16.6148 22.7076 16.7077Z"
        fill={fill}
      />
    </svg>
  );
};

export default CaretRight;
