import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ModalSectionTitle from "./atoms";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { RHFCheckbox } from "@common/Checkbox";
import ImageGallery from "./ImageGallery";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type FundraisersAboutProps = {
  title: string;
  titleSize?: "large" | "medium";
  isEdit?: boolean;
};

const StyleSection = ({
  title,
  titleSize = "large",
  isEdit = false,
}: FundraisersAboutProps) => {
  const { setValue, watch } = useFormContext();
  const values = watch();

  const handleSelect = (item: any) => {
    const isSelected = item?.URL === watch("style.image")?.URL;

    setValue("style.image", isSelected ? null : item, { shouldDirty: true });
  };

  useEffect(() => {
    if (!watch("style.image") && watch("style.useAsBackground"))
      setValue("style.useAsBackground", false, { shouldDirty: true });
  }, [watch("style.image")]);

  return (
    <Stack direction="column" gap={isEdit ? 2 : 4}>
      <FadeUpWrapper delay={isEdit ? 300 : 100}>
        <ModalSectionTitle title={title} titleSize={titleSize} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={isEdit ? 400 : 200}>
        <Stack direction="column" gap={isEdit ? 1 : 2}>
          <Box>
            <Text
              color={palette.black[100]}
              variant="body"
              lineHeight="16.8px"
              fontWeight="book"
              mb="4px"
            >
              Featured image
            </Text>
            <Text
              color={palette.gray[300]}
              variant="body"
              fontWeight="book"
              lineHeight="16.8px"
            >
              This image will be displayed at the top of the payment form so it
              should be representative. Minimum size recommended 2000x2000
            </Text>
          </Box>

          <ImageGallery
            handleSelect={handleSelect}
            selectedImage={values.style.image}
          />
          <RHFCheckbox
            name="style.useAsBackground"
            label="Use as background image"
            helperText="If checked this image will be integrated in the background of the form, can be later changed"
            disabled={!watch("style.image")}
          />
        </Stack>
      </FadeUpWrapper>
    </Stack>
  );
};

export default StyleSection;
