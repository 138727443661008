import { Grid, GridProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import React from "react";

interface Props extends GridProps {
  onClick: any;
  children: React.ReactElement | React.ReactElement[];
}

export const MobileTransactionCardWrapper = ({
  children,
  onClick,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.card} onClick={onClick} {...rest}>
      {children}
    </Grid>
  );
};

const useStyles = makeStyles({
  card: {
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
  },
});
