import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import ModalTitle from "./ModalTitle";
import BaseModal, { BaseModalProps } from "./BaseModal";

import { Tooltip } from "@common/Tooltip";
import { IconButton } from "@common/IconButton";
// icon
import { CloseIcon } from "@assets/icons";
import { Box } from "@mui/material";
import { Text } from "@common/Text";

export type DialogProps = BaseModalProps & {
  title?: string;
  titleComponent?: React.ReactElement;
  titleIcon?: JSX.Element;
  onClose: any;
  actions?: React.ReactNode;
  backgroundColor?: string;
  headerComponent?: React.ReactNode;
  sortIconButton?: React.ReactNode;
  modalDialogRef?: React.RefObject<HTMLElement>;
  shouldShowProgressBar?: boolean;
  progressValues?: Record<string, number>;
  handleScrollDialogContent?: (event: React.UIEvent<HTMLElement>) => void;
  modalType?: "default" | "builder" | "popUp" | "selection";
  shouldShowTitle?: boolean;
  wrapper?: (element: JSX.Element) => JSX.Element;
  shouldBlur?: boolean;
};

const Modal = ({
  title,
  titleIcon,
  onClose,
  actions,
  headerComponent,
  backgroundColor,
  sortIconButton,
  titleComponent,
  modalDialogRef,
  shouldShowProgressBar,
  handleScrollDialogContent,
  modalType = "default",
  shouldShowTitle = true,
  progressValues,
  wrapper = (element: any) => element,
  ...props
}: DialogProps) => {
  return (
    <BaseModal {...props}>
      {headerComponent ||
        (modalType === "popUp" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "4px 8px",
            }}
          >
            <Tooltip title="Close">
              <IconButton aria-label="close" onClick={onClose} size="small">
                <CloseIcon width={20} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : modalType === "selection" || !shouldShowTitle ? null : (
          <ModalTitle
            title={title}
            titleComponent={titleComponent}
            titleIcon={titleIcon}
            onClose={onClose}
            sortIconButton={sortIconButton}
            modalType={modalType}
            shouldShowProgressBar={shouldShowProgressBar}
            progressValues={progressValues}
          />
        ))}

      {wrapper(
        <>
          <DialogContent
            sx={{
              border: "none !important",
              borderTop: "none !important",
              backgroundColor: backgroundColor
                ? backgroundColor
                : "transparent",
            }}
            ref={modalDialogRef}
            onScroll={handleScrollDialogContent}
          >
            {props.children}
          </DialogContent>
          {actions && <DialogActions>{actions}</DialogActions>}
        </>,
      )}
    </BaseModal>
  );
};

export default Modal;
