interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const RightArrowBigger = ({
  width = 21,
  height = 20,
  fill = "none",
  stroke = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9912 5L16.9912 10L11.9912 15M4.49121 10C8.44737 10 11.3554 10 16.1579 10"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightArrowBigger;
