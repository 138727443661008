import { Stack } from "@mui/material";
import { palette } from "@palette";
import { Text, TruncateText } from "@common/Text";

type PurchaseItemCardHeaderProps = {
  name?: string;
  transactionID: string;
  revenue: string;
  price: number;
  recurringInterval: string;
  onClick: () => void;
};

const PurchaseItemCardHeader = ({
  name,
  transactionID,
  revenue,
  price,
  recurringInterval,
  onClick,
}: PurchaseItemCardHeaderProps) => {
  const [dollars, cents] = `${price.toFixed(2)}`.split("."); 
  return (
    <Stack
      onClick={onClick}
      padding="12px 8px"
      direction="column"
      gap={1}
    >
      <TruncateText
        color={palette.black[100]}
        letterSpacing="-0.01em"
        fontWeight="light"
        variant="h6"
        lineHeight="22px"
        lineClamp={1}
      >
        {name}
      </TruncateText>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
        <Stack direction="column">
          <TruncateText
            color={palette.black[100]}
            fontWeight="book"
            variant="caption"
            lineHeight="14px"
            lineClamp={1}
          >
            {transactionID}
          </TruncateText>
          <Text
            color={palette.black[100]}
            fontWeight="book"
            variant="body"
            lineHeight="17px"
            sx={{
              wordBreak: "break-all",
              overflow: "hidden",
            }}
          >
            {revenue}
          </Text>
        </Stack>
        <Stack direction="column">
          <TruncateText
            color={palette.black[100]}
            fontWeight="book"
            variant="h6"
            lineHeight="22px"
            lineClamp={1}
            textAlign="right"
            sx={{
              "& > sup": {
                fontSize: "12px",
                lineHeight: "15px",
              },
            }}
          >
            {dollars}<sup>.{cents}</sup>
          </TruncateText>
          <Text
            color={palette.black[100]}
            fontWeight="light"
            variant="body"
            lineHeight="17px"
            textAlign="right"
            sx={{
              wordBreak: "break-all",
              overflow: "hidden",
            }}
          >
            {recurringInterval}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default PurchaseItemCardHeader;
