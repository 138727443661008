import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { TTransactionStatus } from "features/TransactionsRiskProfile/data.types";
import {
  ShieldWarningIcon,
  ProhibitIcon,
  Checks,
} from "@assets/transactionsRiskProfileIcons";

interface StatusBaseProps {
  color: string;
  label: string;
  Icon: React.ReactNode;
  isQuarantine?: boolean;
  background?: string;
  padding?: string;
}

const StatusBase = ({
  color,
  label,
  Icon,
  isQuarantine = false,
  background,
  padding,
}: StatusBaseProps) => {
  return (
    <Container
      isQuarantine={isQuarantine}
      background={background}
      padding={padding}
    >
      {Icon}
      <Text color={color} fontSize="12px" lineHeight="14.4px" fontWeight="book">
        {label}
      </Text>
    </Container>
  );
};

const TransactionStatusBase: Record<TTransactionStatus, React.ReactNode> = {
  blocked: (
    <StatusBase
      color={palette.filled.red}
      label="Fraud Detected"
      Icon={<ShieldWarningIcon />}
      padding="4px 0"
    />
  ),
  quarantined: (
    <StatusBase
      color={palette.black[100]}
      label="Quarantined"
      Icon=""
      isQuarantine
    />
  ),
  voided: <StatusBase color="#941A1A" label="Voided" Icon={<ProhibitIcon />} />,
  false_positive: (
    <StatusBase
      color="#13A75A"
      label="False Positive"
      Icon={<Checks />}
      padding="4px 0"
    />
  ),
  approved: (
    <StatusBase
      color="#13A75A"
      label="Settled"
      Icon=""
      background="rgba(19, 167, 90, 0.10)"
    />
  ),
  authorized: (
    <StatusBase
      color={palette.black[100]}
      label="Authorized"
      Icon=""
      background={palette.liftedWhite[100]}
    />
  ),
  pending: (
    <StatusBase
      color={palette.black[100]}
      label="Pending"
      Icon=""
      background={palette.liftedWhite[100]}
    />
  ),
  declined: (
    <StatusBase
      color={palette.filled.red}
      label="Declined"
      Icon=""
      background={palette.tag.error.bg}
    />
  ),
  failed: (
    <StatusBase
      color={palette.filled.red}
      label="Error"
      Icon=""
      background={palette.tag.error.bg}
    />
  ),
};

export const TransactionDisplayStatus = ({ status }: { status: string }) => {
  const isSettled = status === "Settled";
  const color = isSettled ? "#13A75A" : palette.black[100];
  const background = isSettled
    ? "rgba(19, 167, 90, 0.10)"
    : palette.liftedWhite[100];
  return (
    <StatusBase color={color} label={status} Icon="" background={background} />
  );
};

const Container = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isQuarantine" && prop !== "background" && prop !== "padding",
})<{ isQuarantine: boolean; background?: string; padding?: string }>(
  ({ isQuarantine, background, padding }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    padding: padding || "4px 16px",
    borderRadius: "100px",
    background,
    ...(isQuarantine && {
      border: `1px dashed ${palette.liftedWhite[300]}`,
    }),
  }),
);

export default TransactionStatusBase;
