import { CaretLeft } from "@assets/icons";
import { ButtonBase } from "@mui/material";
import { palette } from "@palette";

const BackButton = ({ handleGoBack }: { handleGoBack: () => void }) => {
  return (
    <ButtonBase disableRipple onClick={handleGoBack}>
      <CaretLeft width={24} height={24} fill={palette.black[100]} />
    </ButtonBase>
  );
};

export default BackButton;
