import { Text, TruncateText } from "@common/Text";
import { Box, BoxProps, Stack, styled, SxProps } from "@mui/material";
import { palette } from "@palette";
import { CloseIcon } from "@assets/rebrandIcons";

type Props = {
  name: string;
  image?: string;
  selected?: boolean;
  handleDeselect?: () => void;
  role?: string;
  customStyle?: SxProps;
  testId?: string;
  isCurrentUser?: boolean;
  isBigView?: boolean;
};

const AssignmentMenuItem = ({
  name,
  image,
  selected,
  handleDeselect,
  role,
  customStyle,
  testId,
  isCurrentUser = false,
  isBigView = false,
}: Props) => {
  const isBig = !!role || isBigView;
  return (
    <StyledMenuItem selected={selected} data-testid={testId}>
      <Stack
        alignItems="center"
        gap={isBig ? "8px" : 0}
        direction="row"
        spacing={0.5}
        minHeight="40px"
      >
        <StyledImage
          isBig={isBig}
          src={image}
          alt="name"
          data-testid="assignee-avatar"
        />
        <Box>
          <Stack direction="row" spacing={1} height="100%" alignItems="center">
            <TruncateText
              lineClamp={1}
              fontWeight="regular"
              variant="headline"
              color={palette.neutral[80]}
              {...(isBig && {
                color: "#575353",
                fontSize: "14px",
              })}
              sx={customStyle}
            >
              {name}
            </TruncateText>
            {isCurrentUser && (
              <Text
                fontWeight="regular"
                variant="headline"
                color={palette.neutral[70]}
                fontSize="14px"
                sx={customStyle}
              >
                {" (You)"}
              </Text>
            )}
          </Stack>
          {role && (
            <TruncateText
              fontSize="12px"
              lineClamp={1}
              fontWeight="book"
              color="#8F8F8F"
              sx={customStyle}
            >
              {role}
            </TruncateText>
          )}
        </Box>
      </Stack>
      {selected && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            if (handleDeselect) handleDeselect();
          }}
          display="inline-flex"
          data-testid="deselect-assignee"
        >
          <CloseIcon height={20} width={20} />
        </Box>
      )}
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(Box)<BoxProps & { selected?: boolean }>(
  ({ selected }) => ({
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "0 4px",
    borderRadius: "4px",
    ...(selected && {
      background: palette.neutral[10],
    }),
  }),
);

const StyledImage = styled("img")(({ isBig }: { isBig: boolean }) => ({
  height: isBig ? 32 : 24,
  width: isBig ? 32 : 24,
  borderRadius: "100%",
  objectFit: "cover",
}));

export default AssignmentMenuItem;
