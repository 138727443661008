import { palette } from "@palette";

export const getDefaults = () => {
  return {
    fill: palette.primary.main,
    stroke: palette.primary.main,
    size: "24px",
  };
};

interface handleFractionProps {
  digits: number | string | undefined;
}

export const handleFraction = ({ digits }: handleFractionProps) => {
  if (typeof digits === "number" || typeof digits === "string") {
    if (typeof digits === "string") {
      return digits;
    }
    // to not show fractions if number is large
    const digitsString = digits.toString();
    if (digitsString.includes(".")) {
      // find length before dot
      const lengthBeforeDot = digitsString.split(".")[0].length;

      // if length is 4 or more, then we don't need to show all fraction
      if (lengthBeforeDot >= 4) {
        return digits?.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
      } else {
        return digits?.toLocaleString("en-US", {
          maximumFractionDigits: 4,
        });
      }
    } else {
      return digitsString;
    }
  } else {
    return "";
  }
};
