import { TruncateText } from "@common/Text";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import { textColor } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { palette } from "@palette";
import React from "react";

export const DateColumn = ({ row }: { row: TransactionTableRow }) => (
  <TruncateText
    fontWeight="regular"
    color={textColor(row, palette.neutral[800])}
    lineClamp={1}
    maxWidth="2OOpx"
  >
    {row.time}
  </TruncateText>
);
