import { Box, Popover, styled } from "@mui/material";
import ColorPicker from "react-best-gradient-color-picker";
import { isMobileSafari } from "@utils/helpers";
import { debounce } from "lodash";
import { MouseEvent } from "react";

type Props = {
  anchorEl: Element | null;
  handleClose: () => void;
  value?: string;
  onChange: (value: string) => void;
};

const GradientPickerPopover = ({
  anchorEl,
  handleClose,
  onChange,
  value,
}: Props) => {
  return (
    <Popover
      id="colorPicker"
      open={Boolean(anchorEl)}
      //Set to null to fix safari mobile input issue
      anchorEl={isMobileSafari() ? null : anchorEl}
      onClose={handleClose}
    >
      <Box
        borderRadius="4px"
        bgcolor="#ffffff"
        overflow="hidden"
        p="12px 8px"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <StyledColorPicker
          value={value}
          onChange={onChange}
          hideOpacity
          hidePresets
          hideAdvancedSliders
          hideGradientType
          hideGradientControls
          hideGradientStop
        />
      </Box>
    </Popover>
  );
};

const StyledColorPicker = styled(ColorPicker)(({ theme }) => ({
  "& #rbgcp-input": {
    backgroundColor: "transparent",
    border: "1px solid #EEEEEE !important",
    height: "25px !important",
    color: "#292727 !important",
    fontSize: "12px !important",
    fontWeight: "350 !important",
  },

  "& #rbgcp-hex-input ": {
    backgroundColor: "transparent",
    border: "1px solid #EEEEEE !important",
    height: "25px !important",
    color: "#292727 !important",
    fontSize: "12px !important",
    fontWeight: "350 !important",
  },

  "& .rbgcpControlBtnWrapper": {
    backgroundColor: "transparent",
  },
  "& .rbgcpDegreeInputWrap": {
    marginRight: 8,
  },
  "& .rbgcpEyedropperBtn": {
    height: 28,
  },
  "& .rbgcpDegreeInputWrap,.rbgcpEyedropperBtn": {
    backgroundColor: "#ECECE9",
  },
  "& .rbgcpControlBtn": {
    backgroundColor: "transparent",
    color: "#8F8F8F",
    fontWeight: 350,
  },
  "& .rbgcpControlBtnSelected": {
    backgroundColor: "#FFFFFF",
    color: "#292727",
  },
  "& svg,polyline": {
    stroke: "#323136",
  },
  "& .rbgcpInputLabel": {
    color: "#403D3D",
    fontWeight: 350,
  },
  "&  #rbgcp-degree-input": {
    color: "#212121",
  },
  "& .jsb": {
    "& >div:first-child": {
      backgroundColor: "#ECECE9",
    },
  },
  "& #rbgcp-inputs-wrap": {
    "& >div:first-child": {
      width: "35% !important",
    },
    "& >div": {
      width: "18% !important",
    },
  },
}));

export default GradientPickerPopover;
