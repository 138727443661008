import { Button } from "@common/Button";
import { PaymentInformationCard } from "@components/PaymentForm";
import { Box } from "@mui/material";
import { SetStateAction } from "react";

const CheckoutWrapper = ({
  isDesktop,
  authorInfos,
  isPeekMode,
  setDrawerOpen,
  formOf,
}: {
  formOf: keyof typeof buttonLabelsMap;
  isDesktop: boolean;
  authorInfos: Record<string, any>;
  isPeekMode?: boolean;
  setDrawerOpen?: (value: SetStateAction<boolean>) => void;
}) => {
  return (
    <>
      {isDesktop ? (
        <PaymentInformationCard
          destinationAccountMerchantName={authorInfos.name}
          isPeekMode={isPeekMode}
          setDrawerOpen={setDrawerOpen}
        />
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              backgroundColor: "#fff",
              padding: "16px 20px",
              zIndex: 2,
              bottom: 0,
            }}
          >
            <Button
              fullWidth
              onClick={() => setDrawerOpen && setDrawerOpen(true)}
              data-testid="choose-your-contribution-button"
            >
              {formOf ? buttonLabelsMap[formOf] : "Choose your ticket"}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export const buttonLabelsMap = {
  membership: "Choose your membership",
  event: "Choose your ticket",
  invoice: "Choose your product",
  fundraiser: "Choose your contribution",
  sweepstake: "Choose your ticket",
  standard: "Choose your product",
};

export default CheckoutWrapper;
