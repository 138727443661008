// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const SheetIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.4001 17.3034C4.4001 18.572 5.42849 19.6004 6.69707 19.6004L17.3031 19.6004C18.5717 19.6004 19.6001 18.572 19.6001 17.3034L19.6001 6.69735C19.6001 5.42877 18.5717 4.40038 17.3031 4.40038L6.69707 4.40037C5.42849 4.40037 4.4001 5.42877 4.4001 6.69735L4.4001 8.86996C4.4001 9.20133 4.66873 9.46996 5.0001 9.46996C5.33147 9.46996 5.6001 9.20133 5.6001 8.86996L5.6001 6.69735C5.6001 6.09151 6.09123 5.60037 6.69707 5.60037L17.3031 5.60038C17.909 5.60038 18.4001 6.09151 18.4001 6.69735L18.4001 17.3034C18.4001 17.9092 17.909 18.4004 17.3031 18.4004L6.69707 18.4004C6.09123 18.4004 5.6001 17.9092 5.6001 17.3034L5.6001 12.1936C5.6001 11.8622 5.33147 11.5936 5.0001 11.5936C4.66873 11.5936 4.4001 11.8622 4.4001 12.1936L4.4001 17.3034ZM15.4863 12.6004C15.8176 12.6004 16.0863 12.3318 16.0863 12.0004C16.0863 11.669 15.8176 11.4004 15.4863 11.4004L8.5145 11.4004C8.18313 11.4004 7.9145 11.669 7.9145 12.0004C7.9145 12.3318 8.18313 12.6004 8.5145 12.6004L15.4863 12.6004ZM13.0113 9.10041C13.3427 9.10041 13.6113 8.83179 13.6113 8.50041C13.6113 8.16904 13.3427 7.90041 13.0113 7.90041L8.50018 7.90041C8.16881 7.90041 7.90018 8.16904 7.90018 8.50041C7.90018 8.83179 8.16881 9.10041 8.50018 9.10041L13.0113 9.10041ZM11.3852 16.1004C11.7166 16.1004 11.9852 15.8318 11.9852 15.5004C11.9852 15.169 11.7166 14.9004 11.3852 14.9004L8.5145 14.9004C8.18313 14.9004 7.9145 15.169 7.9145 15.5004C7.9145 15.8318 8.18313 16.1004 8.5145 16.1004L11.3852 16.1004Z"
          fill={`url(#paint0_linear_976_6936${width})`}
        />
        <defs>
          <linearGradient
            id={`paint0_linear_976_6936${width}`}
            x1="19.6001"
            y1="19.6004"
            x2="4.02899"
            y2="4.79057"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4001 17.3034C4.4001 18.572 5.42849 19.6004 6.69707 19.6004L17.3031 19.6004C18.5717 19.6004 19.6001 18.572 19.6001 17.3034L19.6001 6.69735C19.6001 5.42877 18.5717 4.40038 17.3031 4.40038L6.69707 4.40037C5.42849 4.40037 4.4001 5.42877 4.4001 6.69735L4.4001 8.86996C4.4001 9.20133 4.66873 9.46996 5.0001 9.46996C5.33147 9.46996 5.6001 9.20133 5.6001 8.86996L5.6001 6.69735C5.6001 6.09151 6.09123 5.60037 6.69707 5.60037L17.3031 5.60038C17.909 5.60038 18.4001 6.09151 18.4001 6.69735L18.4001 17.3034C18.4001 17.9092 17.909 18.4004 17.3031 18.4004L6.69707 18.4004C6.09123 18.4004 5.6001 17.9092 5.6001 17.3034L5.6001 12.1936C5.6001 11.8622 5.33147 11.5936 5.0001 11.5936C4.66873 11.5936 4.4001 11.8622 4.4001 12.1936L4.4001 17.3034ZM15.4863 12.6004C15.8176 12.6004 16.0863 12.3318 16.0863 12.0004C16.0863 11.669 15.8176 11.4004 15.4863 11.4004L8.5145 11.4004C8.18313 11.4004 7.9145 11.669 7.9145 12.0004C7.9145 12.3318 8.18313 12.6004 8.5145 12.6004L15.4863 12.6004ZM13.0113 9.10041C13.3427 9.10041 13.6113 8.83179 13.6113 8.50041C13.6113 8.16904 13.3427 7.90041 13.0113 7.90041L8.50018 7.90041C8.16881 7.90041 7.90018 8.16904 7.90018 8.50041C7.90018 8.83179 8.16881 9.10041 8.50018 9.10041L13.0113 9.10041ZM11.3852 16.1004C11.7166 16.1004 11.9852 15.8318 11.9852 15.5004C11.9852 15.169 11.7166 14.9004 11.3852 14.9004L8.5145 14.9004C8.18313 14.9004 7.9145 15.169 7.9145 15.5004C7.9145 15.8318 8.18313 16.1004 8.5145 16.1004L11.3852 16.1004Z"
        fill={fill}
      />
    </svg>
  );
};

export default SheetIcon;
