import { QKEY_CHECK_NEW_NOTIFICATIONS } from "@constants/queryKeys";
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectUser } from "@redux/slices/auth/auth";
import { getHasNewNotifications } from "@services/api/notifications/user";
import { useQuery } from "react-query";

const useCheckNewNotifications = () => {
  const selectedUser = useAppSelector(selectSelectedAccount);
  const user = useAppSelector(selectUser);
  const merchantId = selectedUser?.id || 0;
  const userId = user?.userAccID || 0;

  const { data } = useQuery(
    [QKEY_CHECK_NEW_NOTIFICATIONS, merchantId, userId],
    async () => {
      const allNotifications = await getHasNewNotifications(merchantId, userId);
      const mandatoryNotifications = await getHasNewNotifications(
        merchantId,
        userId,
        true,
      );

      return {
        hasNewAlerts: allNotifications?.data?.length > 0,
        hasMandatoryAlerts: mandatoryNotifications?.data?.length > 0,
      };
    },
    {
      enabled: !!userId && !!merchantId,
      refetchOnWindowFocus: true,
    },
  );

  return {
    hasMandatoryAlerts: data?.hasMandatoryAlerts ?? false,
    hasNewAlerts: data?.hasNewAlerts ?? false,
  };
};

export default useCheckNewNotifications;
