import React from "react";

type Props = {
  color?: string;
  width?: number | string;
  height?: number | string;
};

const LongArrowLeft = ({
  color = "#8F8F8F",
  height = 24,
  width = 24,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88049 17.513C10.1638 17.2108 10.1485 16.7361 9.84629 16.4528L5.89659 12.75L20 12.75C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25L5.89659 11.25L9.84629 7.54715C10.1485 7.26385 10.1638 6.78923 9.88049 6.48704C9.59719 6.18486 9.12256 6.16955 8.82038 6.45285L3.48704 11.4528C3.33581 11.5946 3.25 11.7927 3.25 12C3.25 12.2073 3.33581 12.4054 3.48704 12.5472L8.82038 17.5472C9.12256 17.8304 9.59719 17.8151 9.88049 17.513Z"
        fill={color}
      />
    </svg>
  );
};

export default LongArrowLeft;
