import { Text } from "@common/Text";
import { palette } from "@palette";

type Props = {
  isConfirmedMatch: boolean;
};

const SuspendedOFACWarning = (props: Props) => {
  const { isConfirmedMatch } = props;
  if (isConfirmedMatch) {
    return (
      <Text color={palette.warning.text}>
        Suspended due to a confirmed OFAC match!
      </Text>
    );
  } else return null;
};

export default SuspendedOFACWarning;
