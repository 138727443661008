import { Button } from "@common/Button";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import NiceModal from "@ebay/nice-modal-react";
import { Box, BoxProps, styled } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { ChevronRight } from "@assets/icons/RebrandedIcons";
import { palette } from "@palette";
import { SIGN_AGREEMENT_MODAL } from "modals/modal_names";
import { useEffect } from "react";
import { NameInput } from "@common/BusinessProfileInputs";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { Text } from "@common/Text";
import { dataURLtoFile } from "@utils/assets";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useAsyncAction } from "@hooks/customReactCore";

type FormInputs = {
  name: string;
  suggestion?: number | null;
};

type Props = {
  addSignature: (file: File) => Promise<void>;
};

const GenerateSignatureModal = NiceModal.create(({ addSignature }: Props) => {
  const { open, onClose } = useNiceModal();
  const [isLoading, triggerAction] = useAsyncAction();

  const { globalName } = useUserReducer();

  const defaultValues = {
    name:
      globalName?.firstName || globalName?.lastName
        ? `${globalName?.firstName} ${globalName?.lastName}`
        : "",
    suggestion: null,
  };

  const methods = useForm<FormInputs>({
    defaultValues,
  });

  const { watch, reset, setValue } = methods;
  const values = watch();

  const handleBack = () => {
    NiceModal.show(SIGN_AGREEMENT_MODAL, { addSignature });
    onClose();
  };

  const generateSignature = async () => {
    const font = fontList.find((f) => f.id === values.suggestion)?.name;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
      onClose();
      return;
    }

    canvas.width = 400;
    canvas.height = 200;

    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, canvas.width, canvas.height);

    const text = values.name;
    const fontSize = 40;
    const fontColor = palette.neutral[80];

    context.fillStyle = fontColor;
    context.font = `${fontSize}px ${font}`;

    const textWidth = context.measureText(text).width;
    const x = (canvas.width - textWidth) / 2;
    const y = canvas.height / 2;

    context.fillText(text, x, y);

    const dataUrl = canvas.toDataURL("image/png");
    const file = await dataURLtoFile(dataUrl, "signature.png");

    await addSignature(file);
    onClose();
  };

  const handleSign = () => triggerAction(generateSignature);

  useEffect(() => {
    if (open) reset(defaultValues);
  }, [open]);

  return (
    <EditMerchantBaseModal
      title="Generate"
      description="Craft your signature effortlessly using your name for a personalized and distinctive touch"
      open={open}
      width="730px"
      handleCancel={onClose}
      icon={
        <Box
          height="24px"
          sx={{ transform: "rotate(180deg)", cursor: "pointer" }}
          onClick={handleBack}
        >
          <ChevronRight height={24} width={24} stroke={palette.neutral[80]} />
        </Box>
      }
      sx={{
        "& .MuiPaper-root": {
          width: "730px !important",
          maxWidth: "730px !important",
        },
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
      actions={
        <>
          <Button size="medium" background="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            background="primary"
            data-testid="submit-generated-signature"
            disabled={!values.name || !values.suggestion || isLoading}
            sx={{ padding: "8px 24px" }}
            onClick={handleSign}
          >
            Sign
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <NameInput
          label="Full Name"
          name="name"
          placeholder="Enter your name"
          isMerchantName
          maxLength="70"
        />
        <SuggestionsContainer>
          {fontList.map((font) => (
            <Suggestion
              key={font.id}
              sx={{
                fontFamily: font.name,
              }}
              onClick={() => setValue("suggestion", font.id)}
              selected={font.id === values.suggestion}
              data-testid={`generate-suggestion-${font.name}`}
            >
              <Text
                fontSize={22}
                color={palette.neutral[80]}
                sx={{ userSelect: "none", wordBreak: "break-all" }}
              >
                {values.name}
              </Text>
            </Suggestion>
          ))}
        </SuggestionsContainer>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

const fontList = [
  { id: 1, name: "Allison" },
  { id: 2, name: "bedtime-stories" },
  { id: 3, name: "Fuggles" },
  { id: 4, name: "SCRIPTIN" },
];

const SuggestionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "16px",
  marginTop: "16px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const Suggestion = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<BoxProps & { selected: boolean }>(({ selected }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px",
  borderRadius: "6px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.neutral[5],
  minHeight: "108px",
  flex: 1,
  cursor: "pointer",
  ...(selected && {
    border: `2px solid ${palette.accent[3]}`,
  }),
}));

export default GenerateSignatureModal;
