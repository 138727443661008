import { EmptyState } from "@common/EmptyState";
import { UploadSimple } from "@phosphor-icons/react";
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { addGradientToIcon } from "@utils/theme";

const BulkInviteEmptyState = ({
  handleUpload,
}: {
  handleUpload: VoidFunction;
}) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [col1, col2] = parseLinearGradientColor();
  return (
    <FadeUpWrapper delay={100} containerProps={{ margin: "auto 0" }}>
      <EmptyState
        Icon={addGradientToIcon(
          "custom-theme-gradient",
          col1,
          col2,
          <UploadSimple data-testid="uploadSimple-icon" size={48} />,
        )}
        title={{
          main: "Import or add invitations",
        }}
        action={{
          label: "Upload File",
          onClick: handleUpload,
          sx: {
            fontWeight: 400,
          },
        }}
        customSize={{
          main: {
            fontSize: "18px",
            lineHeight: "21.6px",
            fontWeight: "light",
            letterSpacing: "-0.01em",
          },
          secondary: {},
        }}
      />
    </FadeUpWrapper>
  );
};

export default BulkInviteEmptyState;
