import { SubPathNameType } from "@utils/routing";
import { Text } from "@common/Text";

import { palette } from "@palette";

const BreadcrumbsTextItem = ({ pageName }: SubPathNameType) => {
  return (
    <Text fontSize="14px" color={palette.neutral["70"]}>
      {pageName}
    </Text>
  );
};

export default BreadcrumbsTextItem;
