import * as React from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { showMessage } from "@common/Toast/ShowToast";
// assets
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";
import { useRefund } from "@services/api/products/transactions";
import { useQueryClient } from "react-query";
import {
  QKEY_DONATION_TRANSACTIONS,
  QKEY_EVENT_TRANSACTIONS,
  QKEY_INVOICE_TRANSACTIONS,
  QKEY_MEMBERSHIP_TRANSACTIONS,
  QKEY_SWEEPSTAKE_TRANSACTIONS,
} from "@constants/queryKeys";
import { checkPortals } from "@utils/routing";
import { processTransactionData } from "../TransactionInfoModal/utils";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";

type RefundProps = {
  purchase?: boolean;
  ids: {
    transactionIDs?: string[];
    transactionItemIDs?: string[];
  };
  sourceAccount?: any;
  isFirst?: boolean;
  isLast?: boolean;
  setSelectedRow?: React.Dispatch<any>;
};

export const fakeSourceAccountData = {
  fullName: " FULL name",
  email: "Email@mail.com",
  card: {
    brand: "Amex",
    numberLast4: 1234,
  },
};

const Refund = NiceModal.create(
  ({
    ids,
    purchase,
    sourceAccount = fakeSourceAccountData,
    isFirst,
    isLast,
    setSelectedRow,
  }: RefundProps) => {
    const modal = useModal();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const {
      currentPortal,
      isEvents,
      isFundraisers,
      isInvoice,
      isMembership,
      isSweepStakes,
    } = checkPortals();
    const message = purchase
      ? "purchase have been successfully refunded"
      : "All purchases have been successfully refunded";

    const { mutateAsync, isLoading } = useRefund();

    const client = useQueryClient();
    const refetcher = (keys: string[]) =>
      keys.forEach((k) => client.refetchQueries(k));

    const handleSend = async () => {
      await mutateAsync(ids, {
        onSuccess: (transactionData) => {
          showMessage("Success", message, isDesktop);
          const parsedData = transactionData?.data?.[0]
            ? processTransactionData(transactionData?.data?.[0])
            : null;

          if (currentPortal === "acquirer") {
            refetcher(queriesToRefetch.acquirer);
          } else if (currentPortal === "provider") {
            refetcher(queriesToRefetch.enterprise);
          } else if (isFundraisers) {
            refetcher(queriesToRefetch.fundraisers);
          } else if (isEvents) {
            refetcher(queriesToRefetch.events);
          } else if (isInvoice) {
            refetcher(queriesToRefetch.invoices);
          } else if (isMembership) {
            refetcher(queriesToRefetch.memberships);
          } else if (isSweepStakes) {
            refetcher(queriesToRefetch.sweepstakes);
          } else {
            refetcher(queriesToRefetch.merchant);
          }

          if (parsedData?.displayStatus === "Refunded") {
            NiceModal.show(TRANSACTION_INFO_MODAL, {
              data: { ...parsedData, transactionID: parsedData?.id },
              isFirst: setSelectedRow ? isFirst : true,
              isLast: setSelectedRow ? isLast : true,
              setSelectedRow,
            });
          } else {
            client.refetchQueries(["get-transaction-history", parsedData?.id]);
          }

          modal.hide();
        },
      }).catch((err: any) => {
        modal.hide();
        showMessage(
          "Error",
          "Only purchases can be refunded or reversed",
          isDesktop,
          "",
          false,
        );
      });
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={purchase ? "Refund Purchase" : "Refund Transaction"}
        onCancel={() => modal.hide()}
        onSubmit={handleSend}
        actionLabel="Issue Refund"
        isLoading={isLoading}
        isSubmitDisabled={isLoading}
        data-testid="refund-modal"
      >
        Please Confirm This Refund to {sourceAccount?.fullName?.trim()}
        {sourceAccount?.email && `, ${sourceAccount?.email}`}
        {sourceAccount?.card?.brand &&
          `, ${sourceAccount?.card.brand?.toUpperCase()}`}
        {sourceAccount?.card?.numberLast4 &&
          `, ${sourceAccount?.card?.numberLast4}`}
      </Popup>
    );
  },
);

export default Refund;

const queriesToRefetch = {
  fundraisers: ["get-fundraiser-by-id", QKEY_DONATION_TRANSACTIONS],
  events: ["get-event-by-id", QKEY_EVENT_TRANSACTIONS],
  invoices: ["get-invoice-by-id", QKEY_INVOICE_TRANSACTIONS],
  memberships: ["get-membership-by-id", QKEY_MEMBERSHIP_TRANSACTIONS],
  sweepstakes: ["get-sweepstake-by-id", QKEY_SWEEPSTAKE_TRANSACTIONS],
  acquirer: [
    "get-transaction-stats",
    "get-all-transactions",
    "acquirer-processing-transactions",
  ],
  enterprise: [
    "get-transaction-stats",
    "get-all-transactions",
    "enterprise-processing-transactions",
  ],
  merchant: [
    "get-transaction-stats",
    "get-all-transactions",
    "manage-money-transactions",
  ],
};
