import { deleteFile } from "@services/api/upload/uploadEndpoints";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteFile = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      accountToRequest,
      identifier,
    }: {
      accountToRequest: string;
      identifier: string;
    }) => {
      await deleteFile({
        accountToRequest,
        identifier,
      });
    },
    onSuccess: () => {
      queryClient.refetchQueries("get-all-media-items");
    },
  });
  return mutation;
};
