import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFCustomEditor } from "@common/CustomEditor";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box, Grid, Stack } from "@mui/material";
import { palette } from "@palette";
import {
  ActionsContainer,
  ContentContainer,
  TitleContainer,
} from "features/Minibuilders/modals/CreateAmount";
import { Wrapper } from "features/Minibuilders/modals/utils";
import { useEffect, useState } from "react";
import { EditorState } from "react-draft-wysiwyg";
import { IDropzoneProps } from "react-dropzone-uploader";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ContentState } from "draft-js";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFSwitch } from "@common/Switch";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectGlobalTicketID,
  setIncrementGlobalTicketId,
} from "@redux/slices/sweepstakes";
import { showMessage } from "@common/Toast";
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { TrashBin } from "@assets/icons/RebrandedIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

interface CreateAmountProps {
  item?: any;
  onClose: (item?: any) => void;
  onDelete?: (id: string) => void;
  hasUniqueTitle: (name: string, id?: string) => boolean;
}

function CreateAmount({
  item,
  onClose,
  onDelete,
  hasUniqueTitle,
}: CreateAmountProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isDesktopView } = useCustomTheme();
  const modal = useModal();
  const dispatch = useAppDispatch();
  const amountID = useAppSelector(selectGlobalTicketID);
  const open = modal.visible;
  const { handlePostImage } = useUploadVariantImage();

  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const isUpdateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const canEdit = (!!item && isUpdateAllowed) || !item;

  const isDeleteAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.DELETE,
  });

  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const {
    setValue,
    reset,
    watch,
    formState: { isDirty, dirtyFields, isValid },
  } = methods;
  const values = watch();

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        thumbnail: item?.thumbnail || "",
        amount: item.amount,
        name: item.name,
        in_stock: item.in_stock,
        display_tickets: item.display_tickets ?? false,
        description: {
          ...item.description,
          text:
            typeof item.description === "string"
              ? EditorState.createWithContent(
                  ContentState.createFromText(item.description?.text),
                )
              : item.description?.text,
        },
      });
    }
    if (!item) reset(defaultValues);
  }, [item]);

  const hideModal = () => {
    modal.hide();
    reset();
    setTimeout(() => {
      modal.remove();
    }, 150);
  };
  const handleCancel = () => {
    hideModal();
    onClose();
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setValue("description.text", editorState, { shouldDirty: true });
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
    if (file) {
      setValue(
        "thumbnail",
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        { shouldDirty: true },
      );
    }
  };
  const handleDeleteItem = () => {
    hideModal();
    if (item?.id && onDelete) onDelete(item.id);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (!hasUniqueTitle(data.name, item?.id)) {
      showMessage("Error", "An element with the same name already exists");
      return;
    }
    setIsLoading(true);
    let url;
    try {
      if (typeof data.thumbnail === "string" || !data.thumbnail) {
        url = data.thumbnail;
      } else {
        url = (await handlePostImage(data.thumbnail)) || null;
      }
    } catch (error) {
      url = null;
    }

    const amount: any = {
      ...data,
      id: item ? item.id : amountID,
      active: item ? item.active : true,
      name: dirtyFields.name || !item ? data.name : item.name,
      amount: dirtyFields.amount || !item ? data.amount : item.amount,
      in_stock: dirtyFields.in_stock || !item ? data.in_stock : item.in_stock,
      display_tickets:
        dirtyFields.display_tickets || !item
          ? data.display_tickets
          : item.display_tickets,
      description: {
        ...data.description,
        text:
          dirtyFields.description?.text || !item
            ? data.description?.text
              ? data.description?.text
              : ""
            : item.description?.text,
      },
      thumbnail: dirtyFields.thumbnail || !item ? url : item.thumbnail,
    };
    setIsLoading(false);
    if (!item) dispatch(setIncrementGlobalTicketId());
    hideModal();
    onClose(amount);
  };

  return (
    <Wrapper onClose={handleCancel} open={open}>
      <FormProvider {...methods}>
        <Box
          component="form"
          id="create-amount"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <TitleContainer>
            <Text
              color={palette.black[100]}
              variant="headline"
              fontSize="24px"
              lineHeight="32.4px"
              fontWeight="book"
              sx={{ letterSpacing: "-0.24px" }}
            >
              {item ? `Edit ${item.name}` : "Add Ticket"}
            </Text>
            {item && onDelete && !isDesktopView && (
              <Button
                background="text"
                size="medium"
                disabled={!isDeleteAllowed}
                onClick={handleDeleteItem}
                tooltipProps={{
                  show: !isDeleteAllowed,
                  message: DELETE_DENY_MESSAGE,
                }}
              >
                <TrashBin width={32} height={32} fill={palette.error.hover} />
              </Button>
            )}
          </TitleContainer>

          <ContentContainer>
            <FadeUpWrapper delay={100}>
              <WithTooltipWrapper
                hasTooltip={!isAddImageAllowed}
                tooltipProps={{
                  show: !isAddImageAllowed,
                  message: UPLOAD_DENY_MESSAGE,
                }}
              >
                <RHFUploadAvatar
                  avatarType="thumbnail"
                  name="thumbnail"
                  onChangeStatus={handleChangeStatus}
                  disabled={!canEdit || !isAddImageAllowed}
                  isProductVariant
                  height="78px"
                  width="78px"
                  key={Date.now()} // Needs to be investigated a bit better
                />
              </WithTooltipWrapper>
            </FadeUpWrapper>
            <Stack direction="column" gap={1}>
              <FadeUpWrapper delay={150}>
                <RHFInput
                  name="name"
                  label="Title"
                  fullWidth
                  disabled={!canEdit}
                />
              </FadeUpWrapper>
              <FadeUpWrapper delay={200}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RHFCustomAmountInput
                      name="amount"
                      currency="usd"
                      initialValue={item?.amount}
                      disabled={!canEdit}
                      isDirty={dirtyFields.amount}
                      value={values.amount}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFCustomIntegerInput
                      name="in_stock"
                      label="Stock"
                      disabled={!canEdit}
                    />
                  </Grid>
                </Grid>
              </FadeUpWrapper>
            </Stack>
            <FadeUpWrapper delay={250}>
              <RHFCustomEditor
                name="description.text"
                onEditorStateChange={onEditorStateChange}
                placeholder={"Description (optional)"}
                readOnly={!canEdit}
              />
            </FadeUpWrapper>

            <FadeUpWrapper delay={300}>
              <Box mt={3}>
                <RHFSwitch
                  name="display_tickets"
                  checked={values?.display_tickets}
                  disabled={!canEdit}
                  label={
                    <Text
                      ml={2}
                      fontSize={16}
                      variant="headline"
                      fontWeight="book"
                      color={palette.neutral[800]}
                    >
                      Display tickets available
                    </Text>
                  }
                />
              </Box>
            </FadeUpWrapper>
          </ContentContainer>

          <ActionsContainer isEdit={Boolean(item)}>
            <Box>
              {item && onDelete && isDesktopView && (
                <Button
                  background="text"
                  size="medium"
                  sx={{ color: palette.error.hover }}
                  onClick={handleDeleteItem}
                  disabled={!isDeleteAllowed}
                  data-testid="sweepstake-ticket-delete-button"
                  tooltipProps={{
                    show: !isDeleteAllowed,
                    message: DELETE_DENY_MESSAGE,
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Stack direction="row" gap={2} alignItems="center">
              <Button
                background="text"
                size="medium"
                onClick={handleCancel}
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    flexGrow: 1,
                  },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: "auto",
                  },
                })}
              >
                Cancel
              </Button>
              <Button
                background="primary"
                size="medium"
                disabled={!isDirty || !isValid || isLoading}
                form="create-amount"
                type="submit"
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    flexGrow: 3,
                  },
                })}
                data-testid={`sweepstake-${item ? "save" : "add"}-button`}
              >
                {item ? "Save" : "Add Ticket"}
              </Button>
            </Stack>
          </ActionsContainer>
        </Box>
      </FormProvider>
    </Wrapper>
  );
}

const CreateSweepstackesAmount = NiceModal.create(
  (props: CreateAmountProps) => {
    return <CreateAmount {...props} />;
  },
);

export default CreateSweepstackesAmount;

const schema = Yup.object().shape({
  name: Yup.string().trim().required("This field is required"),
  amount: Yup.string()
    .required("This field is required")
    .test("Must be above 1 USD", (value) => {
      if (!value) return false;
      const valueToNumber = parseFloat(value);
      if (Number.isNaN(valueToNumber)) return false;
      return valueToNumber >= 1;
    }),
  thumbnail: Yup.mixed<File | string>(),
  in_stock: Yup.number()
    .transform((value, originalValue) => {
      return originalValue === "" ? undefined : value;
    })
    .min(1)
    .required("Stock is required"),

  description: Yup.object().shape({
    text: Yup.mixed<EditorState>().nullable(),
    enabled: Yup.boolean(),
  }),
  display_tickets: Yup.boolean(),
});

const defaultValues = {
  name: "",
  amount: null,
  thumbnail: null,
  description: {
    enabled: true,
    text: "",
  },
  in_stock: null,
  display_tickets: false,
};
