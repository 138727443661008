import { Stack } from "@mui/material";
import { Text } from "@common/Text";
import RHFAsyncAutoComplete from "@common/AsyncAutoComplete/RHFAsyncAutoComplete";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import Checkicon from "@assets/icons/CheckIcon";
import { useListLEsInfinitely } from "@hooks/enterprise-api/merchants/useGetLEsInfinitely";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

export const getFormattedTaxID = (taxID: string) => {
  return taxID.slice(0, 2) + "-" + taxID.slice(2, 9);
};

type Props = {
  selectedProfile?: any;
  merchantLoading: boolean;
  onNewItemSelected(val: any): any;
};

const MerchantExistingProfiles = ({
  merchantLoading,
  selectedProfile,
  onNewItemSelected,
}: Props) => {
  const [debouncedText, setDebouncedText] = useState("");
  const debouncedSearch = useCallback(
    debounce((text) => {
      setDebouncedText(text);
    }, 400),
    [],
  );

  useEffect(() => {
    if (selectedProfile?.legalName)
      setDebouncedText(selectedProfile?.legalName);
  }, [selectedProfile]);

  const { allRows, setPage, page, loadingRef, isFetching, totalRows } =
    useListLEsInfinitely({
      q: debouncedText?.replaceAll("-", ""),
    });

  const onClear = () => {
    onNewItemSelected(null);
    setDebouncedText("");
  };

  useEffect(() => {
    return () => {
      onClear();
    };
  }, []);

  const targetRef = useTargetRef({ setPage, page, totalRows, loadingRef });

  return (
    <RHFAsyncAutoComplete
      name="linkedBusinessProfile"
      targetRef={targetRef}
      renderOption={renderOption}
      placeholder="Search Business Name or Tax ID"
      options={allRows}
      optionsLoading={merchantLoading || isFetching}
      value={debouncedText}
      onTextChange={debouncedSearch}
      selectedItemId={selectedProfile.id}
      onNewItemSelected={onNewItemSelected}
      onClear={onClear}
    />
  );
};

export type BusinessSelectOptionProps = {
  name: string;
  taxIDNumber: string;
  selected?: boolean;
};

const renderOption = ({
  name,
  taxIDNumber,
  selected,
}: BusinessSelectOptionProps) => {
  const formattedID = taxIDNumber ? getFormattedTaxID(taxIDNumber) : "";
  return (
    <Stack
      width="100%"
      gap={0.25}
      direction="row"
      sx={{ opacity: selected ? 0.3 : 1 }}
      data-testid={`${taxIDNumber}-${selected ? "option-selected" : "option"}`}
    >
      <Stack width="100%" gap={0.25}>
        <Text color="#575353">{name}</Text>
        <Text color="#8F8F8F">{formattedID}</Text>
      </Stack>
      {selected && <Checkicon color="#343330" height={20} width={20} />}
    </Stack>
  );
};

export default MerchantExistingProfiles;
