export const defaultWeights = {
  thin: {
    value: 100,
    label: "Thin",
  },
  extraLight: {
    value: 200,
    label: "ExtraLight",
  },
  light: {
    value: 300,
    label: "Light",
  },
  regular: {
    value: 400,
    label: "Regular",
  },
  medium: {
    value: 500,
    label: "Medium",
  },
  semiBold: {
    value: 600,
    label: "SemiBold",
  },
  bold: {
    value: 700,
    label: "Bold",
  },
  extraBold: {
    value: 800,
    label: "ExtraBold",
  },
  black: {
    value: 900,
    label: "Black",
  },
};

export const weights = {
  Roboto: [
    defaultWeights.thin,
    defaultWeights.light,
    defaultWeights.regular,
    defaultWeights.medium,
    defaultWeights.bold,
    defaultWeights.black,
  ],
  "Open Sans": [
    defaultWeights.light,
    defaultWeights.regular,
    defaultWeights.medium,
    defaultWeights.bold,
    defaultWeights.extraBold,
  ],
  Montserrat: [
    defaultWeights.thin,
    defaultWeights.light,
    defaultWeights.regular,
    defaultWeights.medium,
    defaultWeights.bold,
    defaultWeights.extraBold,
    defaultWeights.black,
  ],
  Lato: [
    defaultWeights.thin,
    defaultWeights.light,
    defaultWeights.regular,
    defaultWeights.bold,
    defaultWeights.black,
  ],
  Poppins: Object.values(defaultWeights),
  "Source Sans Pro": [
    defaultWeights.extraLight,
    defaultWeights.light,
    defaultWeights.regular,
    defaultWeights.medium,
    defaultWeights.semiBold,
    defaultWeights.bold,
    defaultWeights.extraBold,
    defaultWeights.black,
  ],
  Raleway: Object.values(defaultWeights),
  "Noto Sans": Object.values(defaultWeights),
  "Roboto Slab": Object.values(defaultWeights),
  Inter: Object.values(defaultWeights),
  Merriweather: [
    defaultWeights.light,
    defaultWeights.regular,
    defaultWeights.bold,
    defaultWeights.black,
  ],
  "Playfair Display": [
    defaultWeights.regular,
    defaultWeights.medium,
    defaultWeights.semiBold,
    defaultWeights.bold,
    defaultWeights.extraBold,
    defaultWeights.black,
  ],
};

export const defaultWeight = {
  Roboto: defaultWeights.regular,
  "Open Sans": defaultWeights.regular,
  Montserrat: defaultWeights.regular,
  Lato: defaultWeights.regular,
  Poppins: defaultWeights.regular,
  "Source Sans Pro": defaultWeights.regular,
  Raleway: defaultWeights.regular,
  "Noto Sans": defaultWeights.regular,
  "Roboto Slab": defaultWeights.regular,
  Inter: defaultWeights.regular,
  Merriweather: defaultWeights.regular,
  "Playfair Display": defaultWeights.regular,
};
