import React, { useEffect } from "react";
import { Dialog, DialogProps, Stack, SxProps, Theme } from "@mui/material";
import { NavigationType, useNavigationType } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { palette } from "@palette";

export type BaseModalProps = DialogProps & {
  width?: string | number;
  scroll?: DialogProps["scroll"];
  shouldBlur?: boolean;
  slotProps?: any;
  paperStyle?: React.CSSProperties;
  contentContainerSx?: SxProps<Theme>;
};

const Wrapper = animated(Dialog);

const AnimatedDialog: React.FC<BaseModalProps> = ({
  sx,
  children,
  width = "640px",
  open,
  scroll = "paper",
  shouldBlur = true,
  slotProps,
  paperStyle,
  PaperProps = {},
  contentContainerSx = {},
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const navType: NavigationType = useNavigationType();

  const transitions = useTransition(isOpen, {
    from: { transform: "translateY(25px)" },
    enter: { transform: "translateY(0px)" },
    leave: { transform: "translateY(50px)" },
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (navType === "POP" && isOpen) setIsOpen(false);
  }, [navType]);

  useEffect(() => {
    const handlePopstate = () => setIsOpen(false);
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return transitions((style, itemVisible) => (
    <Wrapper
      open={!isOpen ? isOpen : itemVisible}
      style={style}
      scroll={scroll}
      PaperProps={{
        ...PaperProps,
        style: {
          borderRadius: "12px",
          width,
          maxWidth: width,
          background: palette.background.dimmedWhite,
          top: "50%",
          transform: "translate(0, -50%)",
          ...paperStyle,
          ...PaperProps?.style,
        },
      }}
      slotProps={{
        ...slotProps,
        backdrop: {
          ...slotProps?.backdrop,
          sx: {
            ...(shouldBlur && { backdropFilter: "blur(3px)" }),
            background: "rgba(53, 53, 53, 0.15)",
            ...slotProps?.backdrop?.sx,
          },
        },
      }}
      sx={{
        overscrollBehavior: "contain",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&::-webkit-scrollbar-track": {
          display: "none",
        },
        ...sx,
      }}
      {...rest}
    >
      <Stack
        display="column"
        alignItems="stretch"
        justifyContent="flex-start"
        overflow="hidden"
        sx={contentContainerSx}
      >
        {children}
      </Stack>
    </Wrapper>
  ));
};

export default AnimatedDialog;
