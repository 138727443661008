import React from "react";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { getUSNames } from "@utils/country_dial_codes";

type ProvinceInputProps = {
  isUS: boolean;
  isRequired?: boolean;
};

const ProvinceInput = ({ isUS, isRequired }: ProvinceInputProps) => {
  if (isUS)
    return (
      <RHFSelect
        name="province"
        label="State"
        options={getUSNames().map((state) => ({
          value: state,
          label: state,
        }))}
      />
    );

  return (
    <RHFInput
      name="province"
      label="Province"
      fullWidth
      placeholder="Type in your Province"
    />
  );
};

export default ProvinceInput;
