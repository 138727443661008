import { Button } from "@common/Button";
import { palette } from "@palette";
import { styled } from "@mui/material";
import { ExportIcon } from "@assets/rebrandIcons";
import React, { useEffect, useState } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { EXPORT_VIEW_PANEL } from "modals/modal_names";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import { useQueryClient } from "react-query";
import { isEmpty } from "lodash";
import { countTopLevelDifferences } from "@utils/helpers";
import { useAppSelector } from "@redux/hooks";
import { selectFiltersObject } from "@redux/slices/dynamicFilterSlice";
import { defaultValuesRiskMonitorTrans } from "./modal/useTransactionFilter";

function ExportBtn({
  merchantId,
  formattedParams,
}: {
  merchantId?: number;
  formattedParams?: string;
}) {
  const queryClient = useQueryClient();
  const [cachedData, setCachedData] = useState<any>(null);
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_RISK_MONITOR_TRANSACTIONS),
  );
  useEffect(() => {
    const data = queryClient.getQueryData(QKEY_RISK_MONITOR_TRANSACTIONS);
    setCachedData(data);

    const unsubscribe = queryClient.getQueryCache().subscribe((event: any) => {
      if (event.query.queryKey === QKEY_RISK_MONITOR_TRANSACTIONS) {
        setCachedData(event.query.state.data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient]);
  const filtersAmount = isEmpty(filterObject)
    ? null
    : countTopLevelDifferences(filterObject, defaultValuesRiskMonitorTrans);
  const handleOpenPanel = () => {
    NiceModal.show(EXPORT_VIEW_PANEL, {
      isFilter: !!filtersAmount,
      totalRows: cachedData?.pages?.[0]?.total || 0,
      EXPORT_FORM_NAME: "GIVE_TRANSACTIONS_",
      column_type: "risk_monitor_transactions",
      queryKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      merchantId,
      sortReduxKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      searchKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      formattedFilterParams: formattedParams,
    });
  };
  return (
    <StyledButton
      background="secondary"
      startIcon={<ExportIcon />}
      onClick={handleOpenPanel}
      disabled={isEmpty(cachedData)}
    >
      Export
    </StyledButton>
  );
}

export default ExportBtn;

const StyledButton = styled(Button)(() => ({
  padding: "0 12px",
  border: `1px solid ${palette.neutral[10]}`,
  background: palette.background.dimmedWhite,
  color: palette.neutral[70],
  fontWeight: 350,
  height: "32px",
  lineHeight: "16.8px",
  gap: "4px",
  "&:hover": {
    background: palette.background.dimmedWhite,
  },
}));
