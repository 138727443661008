import { Text } from "@common/Text";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { palette } from "@palette";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { BtnBGTypes } from "@common/Button/Button_V2";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type TConfirmationPopUp = {
  variant: TVariant;
  onClick: () => void;
  isEnterprise: boolean;
};

const ConfirmationPopUp = NiceModal.create(
  ({ variant, onClick, isEnterprise = false }: TConfirmationPopUp) => {
    const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
    const { isMobileView } = useCustomTheme();
    const modalValues = getModalValue({ variant, isEnterprise });

    const onClickHandler = () => {
      onClick && onClick();
      onClose();
    };

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          width: "395px",
          SlideProps,
          DialogProps: {
            PaperProps: {
              sx: {
                padding: "24px 24px 16px",
              },
            },
            TransitionProps,
          },
        }}
      >
        <ModalTitle
          title={modalValues?.title}
          closeButtonSize={22}
          onClose={onClose}
        />
        <FadeUpWrapper delay={80}>
          <StyledText>{modalValues?.description}</StyledText>
        </FadeUpWrapper>
        <ModalActions
          animationDelay={100}
          containerProps={{
            sx: {
              mt: "20px",
              gap: "16px",
              ...(isMobileView && {
                flexDirection: "column-reverse",
              }),
            },
          }}
          primaryAction={{
            label: modalValues?.button.text,
            background: modalValues?.button.background as any,
            type: "button",
            onClick: onClickHandler,
          }}
          secondaryAction={{
            label: "No",
            onClick: onClose,
          }}
        />
      </ModalFactory>
    );
  },
);

export default ConfirmationPopUp;

const StyledText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontFamily: "Give Whyte",
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: 0,
  marginTop: "8px",
  marginBottom: "0px",
}));

type TVariant = "open_conversation" | "approve";
type TModalValue = {
  variant: TVariant;
  isEnterprise: boolean;
};
type TValuesObject = {
  title: string;
  description: string;
  button: { text: string; background: BtnBGTypes };
};

const getModalValue = ({ variant, isEnterprise }: TModalValue) => {
  const modalValues: Record<TVariant, TValuesObject> = {
    open_conversation: {
      title: "Open a thread in conversation",
      description:
        "Do you want to add this thread as a reminder in the conversation?",
      button: {
        text: "Yes",
        background: "primary",
      },
    },
    approve: {
      title: `Approve ${isEnterprise ? "Provider" : "Merchant"}`,
      description: `Are you sure you want to approve this ${
        isEnterprise ? "provider" : "merchant"
      }?`,
      button: {
        text: "Yes, approve",
        background: "success",
      },
    },
  };
  return modalValues[variant];
};
