import { Box, Skeleton, Stack } from "@mui/material";
import { GraphSkeleton } from "../../CampaignSidePanel/components/AnalyticsSkeleton";
import MapsLoading from "@assets/rebrandIcons/MapsLoading";
import { shouldBeHidden } from "@constants/constants";

const LoadingSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={26}
        width="30%"
        sx={{ borderRadius: "8px" }}
      />
      <Stack direction="row" alignItems="flex-start" gap={1}>
        <Skeleton
          variant="rectangular"
          height={67}
          width="50%"
          sx={{ borderRadius: "8px" }}
        />
        <Skeleton
          variant="rectangular"
          height={67}
          width="50%"
          sx={{ borderRadius: "8px" }}
        />
      </Stack>
      <GraphSkeleton />
      {!shouldBeHidden.customersLocations && (
        <>
          <Box width="100%" height="424px">
            <MapsLoading />
          </Box>
          {Array.from({ length: 10 }, (_, i) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={i}
            >
              <Skeleton variant="rounded" height={9} width="25%" />
              <Skeleton variant="rounded" height={9} width={32} />
            </Stack>
          ))}
        </>
      )}
    </>
  );
};

export default LoadingSkeleton;
