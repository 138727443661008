import { Checkbox } from "@common/Checkbox";
import { TertiaryButton, WhiteContainer } from "./styles";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { ButtonProps } from "@common/Button/Button";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type TAction = ButtonProps & {
  label: string;
};

export const CheckboxContainer = ({
  title,
  action,
  children,
}: {
  title: string;
  action: TAction;
  children: React.ReactNode;
}) => {
  const { label, ...rest } = action;
  return (
    <WhiteContainer data-testid={`export-col-group-${title}`}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Text
          color={palette.black[100]}
          fontSize="18px"
          lineHeight="21.6px"
          fontWeight="book"
          letterSpacing="-0.18px"
        >
          {title}
        </Text>
        <TertiaryButton background="tertiary" {...rest}>
          {label}
        </TertiaryButton>
      </Stack>
      <Stack direction="column" gap="12px">
        {children}
      </Stack>
    </WhiteContainer>
  );
};

export const CustomCheckbox = ({
  label,
  checked,
  onChange,
  disabled = false,
}: {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Checkbox
      label={label}
      checked={checked || false}
      onChange={onChange}
      disabled={disabled}
      size="small"
      sx={{
        "& .MuiButtonBase-root.MuiCheckbox-root": {
          height: "fit-content",
          "& > svg": {
            width: 18,
            height: 18,
          },
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px !important",
          lineHeight: "16.8px !important",
          fontWeight: 350,
          color: palette.black[100],
        },
      }}
    />
  );
};

export const SettingsSectionTitle = ({
  showDescription,
}: {
  showDescription: boolean;
}) => {
  return (
    <FadeUpWrapper delay={40}>
      <Stack direction="column" gap="4px" alignItems="stretch">
        <Text fontWeight="book" lineHeight="16.8px">
          Select columns to be exported
        </Text>
        {showDescription && (
          <Text
            fontWeight="book"
            fontSize="12px"
            lineHeight="14.4px"
            color={palette.gray[300]}
          >
            Minimum of 1 column needs to be selected
          </Text>
        )}
      </Stack>
    </FadeUpWrapper>
  );
};
