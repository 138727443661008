import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalContent from "@components/DeveloperApi/components/GeneralModalContent";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NiceModal from "@ebay/nice-modal-react";
import { ReactNode } from "react";
import { useChallengeActions } from "../../hooks/useChallengeActions";

type Props = {
  title: string;
  body: string | ReactNode;
  primaryActionLabel: string;
  merchantData: any;
  challengeId: number;
  isEnterprise: boolean;
  keyId: string;
  challengeTypeName?: string;
  shouldDisplaySecondaryButton?: boolean;
};

const ChallengesGeneralModal = NiceModal.create(
  ({
    body,
    title,
    primaryActionLabel,
    challengeId,
    isEnterprise,
    merchantData,
    challengeTypeName,
    shouldDisplaySecondaryButton = true,
  }: Props) => {
    const { TransitionProps, open, onClose } = useNiceModal();
    const { confirmAction, rejectAction, notifyMerchant, isDoingActions } =
      useChallengeActions({
        onClose: onClose,
        challengeId,
        merchantId: merchantData.merchantInfo.merchantID,
        merchantName: merchantData.merchantInfo.merchantName,
        shouldDisplayNotifyModal: Boolean(
          merchantData.primaryAccountHolder.email,
        ),
        isEnterprise,
        challengeTypeName,
      });

    return (
      <ModalFactory
        variant="popup"
        modalProps={{
          open,
          onClose,
          PopupProps: {
            width: "576px",
            TransitionProps,
            sx: {
              padding: "24px",
            },
          },
        }}
      >
        <FadeUpWrapper delay={150}>
          <ModalContent
            body={body}
            title={title}
            actionId={challengeId + ""}
            isLoading={isDoingActions}
            primaryActionLabel={primaryActionLabel}
            onClose={onClose}
            fireAction={
              primaryActionLabel.toLowerCase() === "confirm"
                ? confirmAction
                : rejectAction
            }
            shouldDisplaySecondaryButton={shouldDisplaySecondaryButton}
          />
        </FadeUpWrapper>
      </ModalFactory>
    );
  },
);

export default ChallengesGeneralModal;
