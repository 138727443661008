import { TruncateText } from "@common/Text";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import { textColor } from "@components/ManageMoney/TransactionTable/transactions.helpers";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { capitalizeCardType } from "@utils/index";
import React from "react";

export const DescriptionColumn = ({ row }: { row: TransactionTableRow }) => (
  <Stack direction="row">
    <TruncateText
      fontWeight="regular"
      color={textColor(row, palette.neutral[800])}
      lineClamp={1}
      maxWidth="auto"
    >
      {capitalizeCardType(row.detailedDescription?.[0])},&nbsp;
    </TruncateText>
    <TruncateText
      fontWeight="regular"
      color={textColor(row, palette.neutral[70])}
      lineClamp={1}
      maxWidth="auto"
    >
      {capitalizeCardType(row.detailedDescription?.[1])}
    </TruncateText>
  </Stack>
);
