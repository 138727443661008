import { UseQueryOptions, useQuery } from "react-query";
import { customInstance } from ".";
import { SelectOptionProps } from "@common/Select/types";
import { MemberRoleName } from "@customTypes/team.member";
import { useGetCurrentMerchantId } from "@hooks/common";

const getAccountOwner = ({ id }: any) => {
  return customInstance({
    url: `/accounts/${id}/owner`,
    method: "GET",
  });
};

export const useGetAccountOwner = (
  id: number,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const { data, error, isLoading } = useQuery(
    ["get-account-owner", id],
    async () => {
      const data = await getAccountOwner({ id });
      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      ...options,
    },
  );

  return { data, error, isLoading };
};

const getRoles = () => {
  return customInstance({
    url: `/member-roles`,
    method: "GET",
  });
};

export const useRoles = () => {
  const { merchantId } = useGetCurrentMerchantId();

  const response = useQuery(
    ["get-users_role", merchantId],
    async () => {
      const roles = await getRoles();

      const data: RolesData = {
        ...roles,
        data: ParseData(roles?.data),
      };
      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const getDefaultRole = (role: string) => {
    return (
      response?.data?.data?.find(
        (_element) => _element?.value === role?.toLowerCase(),
      )?.value || "member"
    );
  };
  return { ...response, getDefaultRole };
};

const ParseData = (data: any[]): ParsedRole[] =>
  data?.map((item) => ({
    ID: item?.ID,
    label: item?.DisplayName,
    value: item?.Name,
  })) || [];

export interface ParsedRole extends SelectOptionProps {
  label: string;
  value: MemberRoleName;
  ID: number | string;
}

interface RolesData {
  total: number;
  data: ParsedRole[];
}
