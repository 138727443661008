import React from "react";
import EmptyState from "./EmptyState";
import { SearchIcon } from "@assets/emptyStatesIcons";
import { SxProps } from "@mui/material";

const NoResultsState = ({
  searchQuery,
  sx,
}: {
  searchQuery?: string;
  sx?: SxProps;
}) => {
  return (
    <EmptyState
      Icon={<SearchIcon />}
      title={{
        main: searchQuery
          ? `No results for "${searchQuery}"`
          : `No results found`,
        secondary: "Please try a different search term",
      }}
      sx={{ padding: "50px", ...sx }}
    />
  );
};

export default React.memo(NoResultsState);
