import { Stack } from "@mui/material";
import ModalSectionTitle from "./atoms";
import { RHFCheckbox } from "@common/Checkbox";
import { ComingSoonTag } from "@common/Tag";
import { Text } from "@common/Text";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type ConfigurationSectionProps = {
  title: string;
  titleSize?: "large" | "medium";
  isEdit?: boolean;
  isPaymentFormBuilder?: boolean;
};

const ConfigurationSection = ({
  title,
  titleSize = "large",
  isEdit = false,
  isPaymentFormBuilder,
}: ConfigurationSectionProps) => {
  return (
    <Stack direction="column" gap={isEdit ? 2 : 4} flexGrow={1}>
      <FadeUpWrapper delay={100}>
        <ModalSectionTitle title={title} titleSize={titleSize} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Stack direction="column" gap="24px">
          <RHFCheckbox
            name="configuration.customer_pays_fees"
            label="Customer pays the credit card fee"
            sx={{
              "& .MuiButtonBase-root.MuiCheckbox-root": {
                height: 24,
              },
            }}
          />
          <Stack direction="column">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <RHFCheckbox
                disabled
                name="configuration.browse_more"
                label="Browse more Payment forms"
                sx={{
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    height: 24,
                  },
                }}
              />
              <ComingSoonTag />
            </Stack>
            <Text
              color={palette.gray[300]}
              fontSize="16px"
              fontWeight="book"
              lineHeight="19.2px"
              ml={4}
            >
              {isPaymentFormBuilder
                ? "Allow customers to explore more of your published Payment forms."
                : "Allow users to explore more of your published Payment forms."}
            </Text>
          </Stack>
        </Stack>
      </FadeUpWrapper>
    </Stack>
  );
};

export default ConfigurationSection;
