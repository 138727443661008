import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { Box, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { ArrowDownChevron } from "@assets/icons";
import { palette } from "@palette";
import CheckIcon from "@assets/icons/RebrandedIcons/CheckIcon";
import { isFunction } from "lodash";

export type ItemType = {
  value: string;
  title: string | undefined;
};

function FilterPermissionList({
  filterData,
  defaultValue,
  onHandleSelect,
}: {
  defaultValue?: ItemType;
  filterData?: ItemType[];
  onHandleSelect?: (e: ItemType) => void;
}) {
  const [permission, setPermission] = useState<ItemType | undefined>(
    defaultValue,
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (e: { value: string; title: string | undefined }) => {
    setPermission(e);
    isFunction(onHandleSelect) && onHandleSelect(e);
    handleClose();
  };
  useEffect(() => {
    defaultValue
      ? setPermission(defaultValue)
      : filterData && setPermission(filterData[0]);
  }, []);

  const open = Boolean(anchorEl);

  const renderMenuItem = (state: ItemType) => {
    const isSelected = permission?.value === state.value;
    return (
      <ItemWrapper onClick={() => handleSelect(state)} key={state.value}>
        <Text fontSize="14px" fontWeight="book" color="#575353">
          {state.title}
        </Text>
        {isSelected && (
          <CheckIcon width={20} height={20} fill={palette.black[300]} />
        )}
      </ItemWrapper>
    );
  };
  return (
    <Box>
      <CustomButton onClick={handleClick}>
        <Text fontWeight="regular" fontSize="16px" color="#403D3D">
          {permission?.title}
        </Text>
        <Box
          sx={{
            transform: `rotate(${open ? "180deg" : "0deg"})`,
            transition: " transform 0.3s ease-in-out",
          }}
        >
          <ArrowDownChevron color="#8F8F8F" height={7} width={11.5} />{" "}
        </Box>
      </CustomButton>

      <Popover
        id="permission-permissions"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0px 8px 25px 0px #00000026",
            padding: "4px",
          },
        }}
      >
        <Box width="244px">{filterData?.map(renderMenuItem)}</Box>
      </Popover>
    </Box>
  );
}

export default FilterPermissionList;

const ItemWrapper = styled(Stack)(() => ({
  height: "40px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "4px 8px",
  width: "100%",
  cursor: "pointer",
  borderRadius: "4px",
  userSelect: "none",
  "&:hover": {
    backgroundColor: "#ECECE9",
  },
}));

const CustomButton = styled("button")(() => ({
  all: "unset",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "12px",
}));
