import * as React from "react";
import { palette } from "@palette";
import { TextFieldProps } from "@mui/material/TextField";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// assets
import { CalendarIcon } from "@assets/rebrandIcons";
import { debounce, isDate } from "lodash";

export type DatePickerProps = {
  width?: number;
  value: any;
  time?: boolean;
  disabled?: boolean;
  inputFormat?: string;
  inputFormatTime?: string;
  size?: "small" | "medium";
  label?: React.ReactNode;
  minDate?: any;
  maxDate?: any;
  openTo?: "day" | "month" | "year";
  adornmentPosition?: "start" | "end";
  inputRef?: React.Ref<HTMLInputElement>;
  disablePast?: boolean;
  renderInput: (props: TextFieldProps) => React.ReactElement;
  onChange: (value: any, keyboardInputValue?: string | undefined) => void;
  openPickerOnFocus?: boolean;
};

const paperStyle = {
  "& .PrivatePickersYear-yearButton.Mui-selected": {
    backgroundColor: `${palette.black.main} !important`,
    "&:hover": {
      backgroundColor: palette.black.main,
      opacity: 0.8,
    },
  },
  "& .MuiPickersDay-root.Mui-selected": {
    backgroundColor: `${palette.black.main} !important`,
    "&:hover": {
      backgroundColor: palette.black.main,
      opacity: 0.8,
    },
  },
  "& .Mui-disabled.Mui-selected": {
    backgroundColor: "transparent !important",
  },
};

const iconsSx = {
  "& .MuiIconButton-root": {
    height: "auto",
    border: "none",
    boxShadow: "none",
    padding: 0,
    marginLeft: "1px",
    marginRight: "1px",

    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
    "&:active, &:focus": {
      border: "none",
      boxShadow: "none",
    },
  },
  ...paperStyle,
};

const inputSx = {
  ...iconsSx,
};

const dialogStyle = {
  "& .MuiDialog-container": {
    alignItems: "center",
  },
  "& .MuiDialogContent-root": {
    padding: 0,
    borderBottom: "none",

    ".MuiPickersToolbar-root.MuiDatePickerToolbar-root > .MuiGrid-root.MuiGrid-container":
      {
        alignItems: "flex-start",
      },
  },
  "& .MuiDialog-paper": {
    background: "#FFFFFF",

    ...iconsSx,

    "@media (max-width: 600px)": {
      top: "initial",
      height: "auto",
      borderRadius: "4.5px",
      maxHeight: "calc(100% - 64px)",
    },
  },
  "& .MuiDialogActions-root": {
    backgroundColor: "#FFFFFF",

    "& .MuiButton-root.MuiButton-primary": {
      border: "none",
      color: "#1976d2",
      boxShadow: "none",
      textTransform: "uppercase",
      backgroundColor: "transparent",

      "&:hover": {
        boxShadow: "none",
        background: "none",
        backgroundColor: "none",
      },
    },

    "@media (max-width: 600px)": {
      flexDirection: "row",
      justifyContent: "flex-end",

      "& > :not(:first-of-type)": {
        marginLeft: 0,
      },

      "& .MuiButton-root": {
        width: "auto",
      },
    },
  },
  ...paperStyle,
};

export default function DatePicker({
  inputRef,
  minDate,
  maxDate,
  renderInput,
  inputFormat = "MM/dd/yyyy",
  adornmentPosition = "start",
  inputFormatTime = "MM/dd/yyyy 'at' hh:mm a",
  disablePast,
  openTo = "day",
  openPickerOnFocus = false,
  ...props
}: DatePickerProps) {
  const keyboardInputRef = React.useRef<string>("");
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const anchorRef = React.useRef<HTMLInputElement>(null);

  const handleChange = (value: any, keyboardInputValue?: string) => {
    props.onChange(validatedDate(value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    const regex = getValidationRegex(inputFormat);

    if (
      !regex.test(keyboardInputRef.current + key) &&
      !navigationKeys.some((item) => item === key)
    ) {
      event.preventDefault();
    }
  };

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
    setIsOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) return; //to avoid glitches if picker is already open
    setAnchorEl(event.currentTarget);
  };

  //debounce is needed to avoid glitchy behavour
  const handleOpenPicker = debounce(() => {
    if (anchorRef.current && !anchorEl) {
      setAnchorEl(anchorRef.current);
      setIsOpen(true);
      anchorRef.current.focus();
    }
  }, 200);

  const handleRenderInput = React.useCallback(
    (props: TextFieldProps) => {
      if (openPickerOnFocus)
        return renderInput({ ...props, onFocus: () => handleOpenPicker() });
      else return renderInput(props);
    },
    [renderInput, openPickerOnFocus],
  );

  React.useEffect(() => {
    //on first render make sure date is formatted - otherwise on save it will change to wrong date
    if (props.value) {
      props.onChange(validatedDate(props.value, true));
    }
  }, []);

  return (
    <>
      {props.time ? (
        <MuiDateTimePicker
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          InputProps={{
            sx: inputSx,
          }}
          PopperProps={{
            sx: iconsSx,
            placement: "bottom",
            anchorEl,
            open: Boolean(anchorEl),
          }}
          DialogProps={{
            sx: dialogStyle,
          }}
          InputAdornmentProps={{
            position: adornmentPosition,
            onClick: handleClick,
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={handleClose}
          minDate={minDate}
          maxDate={maxDate}
          inputRef={inputRef}
          inputFormat={inputFormatTime}
          value={props.value}
          onChange={props.onChange}
          renderInput={renderInput}
          disabled={props.disabled}
          disableMaskedInput
        />
      ) : (
        <MuiDatePicker
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          InputProps={{
            sx: inputSx,
            onKeyDown: handleKeyDown,
          }}
          PaperProps={{
            sx: paperStyle,
          }}
          PopperProps={{
            sx: iconsSx,
            placement: "bottom",
            anchorEl,
            open: Boolean(anchorEl),
          }}
          DialogProps={{
            sx: dialogStyle,
          }}
          InputAdornmentProps={{
            position: adornmentPosition,
            onClick: handleClick,
          }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={handleClose}
          minDate={minDate}
          maxDate={maxDate}
          inputFormat={inputFormat}
          value={props.value}
          onChange={handleChange}
          renderInput={handleRenderInput}
          disabled={props.disabled}
          disablePast={disablePast}
          // disableMaskedInput
          openTo={openTo}
          inputRef={anchorRef}
        />
      )}
    </>
  );
}

const navigationKeys = [
  "ArrowDown",
  "ArrowUp",
  "ArrowLeft",
  "ArrowRight",
  "Enter",
  "Escape",
  "Backspace",
  "Delete",
  "Tab",
];

const getValidationRegex = (inputFormat: string): RegExp => {
  switch (inputFormat) {
    case "dd/MM/yyyy":
      return /^0?$|^(?:0?[1-9]|1[012]?)(?:\/(?:(?:0$|0?[1-9]|[12]\d|3[01])(?:\/\d{0,4})?)?)?$/;
    case "MM/dd/yyyy":
      return /^0?$|^(?:0?[1-9]|1[012]?)(?:\/(?:(?:0$|0?[1-9]|[12]\d|3[01])(?:\/\d{0,4})?)?)?$/;
    case "dd-MM-yyyy":
      return /^0?$|^(?:0?[1-9]|[12]\d|3[01])(?:-(?:(?:0$|0?[1-9]|1[012]?)(?:-\d{0,4})?)?)?$/;
    case "MM-dd-yyyy":
      return /^0?$|^(?:0?[1-9]|1[012]?)(?:-(?:(?:0$|0?[1-9]|[12]\d|3[01])(?:-\d{0,4})?)?)?$/;
    default:
      return /^0?$|^(?:0?[1-9]|1[012]?)(?:\/(?:(?:0$|0?[1-9]|[12]\d|3[01])(?:\/\d{0,4})?)?)?$/;
  }
};

const validatedDate: any = (value: any, render = false) => {
  // Check if the provided value is a valid date, if yes we format it
  if (isDate(value) && !isNaN(value.getTime())) {
    // Set the hour, minute, and second to the one the dateinput is using by default
    //we need to add this otherwise the manually inputed date will have the hour as 00.00.00 which will be a problem later when we send it to BE
    //it is better to handle this here, instead of where we create a payload, to avoid issues in all places
    value.setHours(1);
    value.setMinutes(44);
    value.setSeconds(26);
    //set timezone to GMT0200
    value.setTime(
      value.getTime() -
        value.getTimezoneOffset() * 60 * 1000 +
        2 * 60 * 60 * 1000,
    );
  } else if (value && render) {
    const newValue = new Date(value);
    return validatedDate(newValue);
  }

  return value;
};
