const NotificationIcon = ({ width = 64, height = 65, fill = "#B8B8B8" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          opacity="0.2"
          d="M51.9997 48.1445H11.9997C11.6496 48.1424 11.3063 48.0485 11.0039 47.8721C10.7015 47.6957 10.4508 47.443 10.2767 47.1393C10.1025 46.8357 10.0112 46.4916 10.0117 46.1415C10.0123 45.7915 10.1047 45.4477 10.2797 45.1445C11.9272 42.2945 13.9997 35.097 13.9997 26.1445C13.9997 21.3706 15.8961 16.7923 19.2718 13.4166C22.6474 10.041 27.2258 8.14453 31.9997 8.14453C36.7736 8.14453 41.352 10.041 44.7276 13.4166C48.1033 16.7923 49.9997 21.3706 49.9997 26.1445C49.9997 35.0995 52.0747 42.2945 53.7247 45.1445C53.8999 45.4481 53.9923 45.7923 53.9926 46.1428C53.993 46.4933 53.9012 46.8377 53.7265 47.1415C53.5518 47.4453 53.3003 47.6979 52.9972 47.8739C52.6942 48.05 52.3502 48.1433 51.9997 48.1445Z"
          fill={fill}
        />
        <path
          d="M42.0005 56.1445C42.0005 56.675 41.7898 57.1837 41.4147 57.5587C41.0396 57.9338 40.5309 58.1445 40.0005 58.1445H24.0005C23.47 58.1445 22.9613 57.9338 22.5863 57.5587C22.2112 57.1837 22.0005 56.675 22.0005 56.1445C22.0005 55.6141 22.2112 55.1054 22.5863 54.7303C22.9613 54.3552 23.47 54.1445 24.0005 54.1445H40.0005C40.5309 54.1445 41.0396 54.3552 41.4147 54.7303C41.7898 55.1054 42.0005 55.6141 42.0005 56.1445ZM55.463 48.1445C55.116 48.7558 54.6121 49.2635 54.0035 49.615C53.3948 49.9666 52.7034 50.1494 52.0005 50.1445H12.0005C11.2973 50.1436 10.6068 49.9573 9.9986 49.6044C9.39039 49.2516 8.88593 48.7446 8.53609 48.1347C8.18624 47.5247 8.00336 46.8333 8.00589 46.1302C8.00841 45.427 8.19625 44.737 8.55046 44.1295C9.93796 41.7395 12.0005 34.9795 12.0005 26.1445C12.0005 20.8402 14.1076 15.7531 17.8583 12.0024C21.6091 8.25167 26.6961 6.14453 32.0005 6.14453C37.3048 6.14453 42.3919 8.25167 46.1426 12.0024C49.8933 15.7531 52.0005 20.8402 52.0005 26.1445C52.0005 34.977 54.0655 41.7395 55.453 44.1295C55.8107 44.7378 56 45.4304 56.0013 46.136C56.0026 46.8417 55.8159 47.5349 55.4605 48.1445H55.463ZM52.0005 46.1445C50.068 42.827 48.0005 35.157 48.0005 26.1445C48.0005 21.9011 46.3148 17.8314 43.3142 14.8308C40.3136 11.8302 36.2439 10.1445 32.0005 10.1445C27.757 10.1445 23.6873 11.8302 20.6868 14.8308C17.6862 17.8314 16.0005 21.9011 16.0005 26.1445C16.0005 35.1595 13.9305 42.8295 12.0005 46.1445H52.0005Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default NotificationIcon;
