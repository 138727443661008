import { customInstance } from "..";
import { TQueryParams, queryGenerator } from "@utils/generators/queryGenerator";

export const getHasNewNotifications = (
  merchantId: number,
  userId: number,
  filterByMandatory = false,
) => {
  return getUserNotifications(merchantId, userId, {
    filter: {
      readAt: null,
      ...(filterByMandatory && {
        alertType: `"mandatory"`,
      }),
    },
    max: 1,
  });
};

export const getUserNotifications = (
  merchantId: number,
  userId: number,
  params?: TQueryParams,
) => {
  const baseURL = `/accounts/${merchantId}/members/${userId}/push-notifications`;
  const url = queryGenerator(baseURL, params);
  return customInstance({
    url,
    method: "GET",
  });
};

type AllMark = "*";
export type TNotificationIds = Array<number | AllMark>;

export const markAsRead = (
  merchantId: number,
  userId: number,
  notificationsIds: TNotificationIds,
) => {
  return customInstance({
    url: `/accounts/${merchantId}/members/${userId}/push-notifications/read`,
    method: "PUT",
    data: {
      pushNotificationIDs: notificationsIds,
    },
  });
};
