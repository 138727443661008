import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import HeaderTitleComponent from "@components/AvatarUpload/modal/components/HeaderTitleComponent";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack, SxProps } from "@mui/material";
import PreviewImagesBody from "../PreviewImagesBody";
import { TOwnerFile } from "./types";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { CloseIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { ReactNode } from "react";

export type TPreviewModalProps = {
  filesData: {
    fileName: string | undefined;
    src: string | undefined;
    type: TOwnerFile;
    icon: (fill?: string) => React.ReactNode;
    title: string;
  }[];
};

const PAHUploaderPreviewModal = NiceModal.create(
  ({ filesData }: TPreviewModalProps) => {
    const { open, onClose, TransitionProps } = useNiceModal();
    const { isMobileView } = useCustomTheme();

    const shouldShowBoth = filesData.every((files) => !!files.src);

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open,
          onClose,
          DialogProps: {
            width: !shouldShowBoth ? "513px" : "928px",
            TransitionProps,
          },

          ...(isMobileView && {
            sx: {
              top: 1,
            },
            DrawerProps: {
              PaperProps: {
                sx: {
                  height: "100%",
                  background: "#292727",
                  borderRadius: 0,
                  paddingRight: "16px",
                  paddingLeft: "16px",
                  "& > .MuiStack-root": {
                    padding: 0,
                  },
                },
              },
            },
          }),
        }}
      >
        <Stack
          padding="24px"
          direction="column"
          alignItems="stretch"
          width="100%"
        >
          <HeaderTitleComponent
            title={
              isMobileView ? <MobileHeader onClose={onClose} /> : "Preview"
            }
            onClick={onClose}
            isMobile={isMobileView}
            description={
              !isMobileView
                ? "You can click the image to zoom in for a closer look"
                : ""
            }
          />
          <PreviewImagesBody
            filesData={filesData}
            isMobileView={isMobileView}
          />
        </Stack>
      </ModalFactory>
    );
  },
);

export const MobileHeader = ({
  onClose,
  icon = <CloseIcon />,
  text = "Preview",
  sx,
}: {
  onClose: () => void;
  icon?: ReactNode;
  text?: string;
  sx?: SxProps;
}) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
        width="100%"
        sx={{
          ...sx,
        }}
      >
        <Box width="100%" onClick={onClose}>
          {icon}
        </Box>
        <Box width="100%">
          <Text color="#fff" textAlign="center">
            {text}
          </Text>
        </Box>
        <Box width="100%"> </Box>
      </Stack>
    </>
  );
};

export default PAHUploaderPreviewModal;
