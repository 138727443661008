import { merchantAgreementParser } from "@components/Merchants/MerchantPreview/helpers/parsers";
import { removeSpecialChars } from "@utils/slug";
import { TParsedMerchantInfo } from "../types";
import { TBusinessOwner } from "@components/Merchants/MerchantPreview/data.types";
import { parsePhoneNumber } from "@components/Merchants/MerchantPreview/helpers/parsers";

export const merchantInfoParser = (data: any): TParsedMerchantInfo => {
  return {
    name: data?.name,
    description: data?.description,
    imageURL: data?.imageURL,
    accID: data?.accID,
    category: data?.categoryCodeID,
    merchantSlug: data?.slug
      ? removeSpecialChars(data?.slug.replace("https://", ""))
      : "",
    parentSlug: data?.parentSlug
      ? removeSpecialChars(data?.parentSlug.replace("https://", ""))
      : "",
    billingDescriptor: data?.billingDescriptor,
    customerServicePhone: data?.servicePhoneNumber,
    websiteURL: data?.websiteURL?.replace("https://", "") || "",
    statusDisplayName: data?.statusDisplayName?.toLowerCase(),
    status: data?.statusName,
    statusUpdatedAt: data?.statusUpdatedAt,
    statusUpdatedByName: data?.statusUpdatedByName,
    statusUpdatedByEmail: data?.statusUpdatedByEmail,
    estimatedAnnualRevenue: (
      data?.annualCreditCardSalesVolume / 100
    ).toString(),
    high_ticket_amount: (data?.highTicketAmount / 100).toString(),
    average_ticket_amount: (data?.averageTicketAmount / 100).toString(),
    is_outside_usa: data?.serviceCountriesOutUSCanada || false,
    countriesOutside: data?.countriesServicedOutside,
    msaRefundPolicy: data?.msaRefundPolicy,
    owner: {
      firstName: data?.owner?.firstName,
      lastName: data?.owner?.lastName,
      email: data?.owner?.email,
      phoneNumber: data?.owner?.phoneNumber,
      accID: data?.owner?.accID,
      isNotUSResident: !!data?.owner?.citizenship,
      citizenship: data?.owner?.citizenship,
      isNotResidentInCitizenshipCountry: !!data?.owner?.countryOfResidence,
      countryOfResidence: data?.owner?.countryOfResidence,
      ofac: {
        statusName: data?.ownerOfac?.lastCheckStatusName,
      },
      dateOfBirth: data?.owner?.dateOfBirth,
    },
    merchantAgreement: merchantAgreementParser(data),
    classification: merchantClassificationParser(data),
    classCustomDescription: data?.classCustomDescription,
  };
};

export const merchantClassificationParser = (data: any) => {
  return {
    id: data?.class?.id || 0,
    typeName: data?.class?.typeName || "",
    name: data?.class?.name || "",
    typeDisplayName: data?.class?.typeDisplayName || "",
    displayName: data?.class?.displayName || "",
    description: data?.class?.description || "",
    isHidden: data?.class?.isHidden || false,
  };
};

//parser for pah data when its used as default value for BO when is sole proprietor
export const businessOwnerParser = (data: any): Partial<TBusinessOwner> => {
  return {
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    businessAddress: data?.address || "",
    dob: data?.dateOfBirth || "",
    phone: `+${parsePhoneNumber(data?.phoneNumber)}` || "",
    email: data?.email || "",
    citizenship: data?.citizenship || "",
    countryOfResidence: data?.countryOfResidence || "",
  };
};
