import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  icon: ReactNode;
  message: string;
  isThread?: boolean;
};

const ConversationsEmptyState = ({ icon, message, isThread }: Props) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: isThread ? "calc(100% - 32px)" : "100%" }}
    >
      {icon}
      <Text color="#8F8F8F">{message}</Text>
    </Stack>
  );
};

export default ConversationsEmptyState;
