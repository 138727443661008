import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { updateEnterpriseConfiguration } from "@redux/slices/enterprise/enterpriseSettings";
import { checkPortals } from "@utils/routing";
import { useGetEnterpriseConfigurationById } from "./useGetEnterpriseConfigurationById";

export const useGetEnterpriseConfiguration = () => {
  const dispatch = useAppDispatch();
  const { merchantId } = useGetCurrentMerchantId();
  const auth = useAppSelector(selectUser);
  const { isEnterprisePortal } = checkPortals();
  const { isMasqueradeMode } = useMasqueradeReducer();
  const isMasqueradeEnterprise =
    isMasqueradeMode && auth?.role === "acquirer" && isEnterprisePortal;

  const query = useGetEnterpriseConfigurationById(merchantId, {
    onSuccess: (data) => {
      if (data?.data) {
        const permissionsOfEnterprise = data?.data?.reduce(
          (acc: any, curr: any) => {
            return {
              ...acc,
              [curr.name]: curr.isActive,
            };
          },
          {},
        );
        dispatch(updateEnterpriseConfiguration(permissionsOfEnterprise));
      }
    },
    // TODO: check useless api call of acquirer
    enabled:
      (!!merchantId && auth.role === "provider") || isMasqueradeEnterprise,
  });

  return query;
};
