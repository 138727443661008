import { TickIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { useCallback } from "react";

type Props = {
  title?: string;
  progressValues?: Record<string, number>;
};

const formatKey = (key: string) => {
  return key.replace(/([A-Z])/g, " $1").trim();
};

const Progress = ({ title, progressValues }: Props) => {
  const renderProgressText = useCallback(() => {
    if (!progressValues) return <></>;
    return Object.keys(progressValues).map((key) => {
      const currentKey = progressValues[key];
      const color =
        currentKey === 100 ? palette.filled.green : palette.filled.grey;

      return (
        <Text key={key} sx={{ color }} py={0.5}>
          {
            <TickIcon
              stroke={currentKey === 100 ? color : palette.filled.lightGrey}
            />
          }{" "}
          {formatKey(key)}: {currentKey}%
        </Text>
      );
    });
  }, [progressValues]);

  return <Box textAlign="left">{renderProgressText()}</Box>;
};

export default Progress;
