// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { useFormData } from "./SignupFormProvider";
import SignupFormContainer from "./SignupFormContainer";
import React from "react";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { cloneDeep, isEqual } from "lodash";
import PersonalDetails from "./components/PersonalDetails";

type IFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type Props = {
  handleUpdateStatusValue: (value: number) => void;
  personalDetailsStatusBar: number;
  handleNext: () => void;
  handleBack: () => void;
  isFirstPage: boolean;
};

const SignupPersonalDetails = ({
  handleUpdateStatusValue,
  personalDetailsStatusBar,
  handleNext,
  handleBack,
  isFirstPage,
}: Props) => {
  const { formData, setFormValues } = useFormData();

  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const saveOnUnmount = React.useRef<any>();

  const schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required").email("Invalid format"),
  });

  const defaultValues = formData.personalDetails;

  const methods = useForm<IFormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    formState: { isValid, dirtyFields },
  } = methods;

  const values = watch();

  const goBackHandler = () => {
    setFormValues("organizationDetails", values);
    handleBack();
  };

  React.useEffect(() => {
    (async () => {
      const condition =
        Object.keys(dirtyFields).length > 0 &&
        !isEqual(values, saveOnUnmount.current);
      if (condition) {
        const value = await calculatePercentageNested(schema, values);
        handleUpdateStatusValue(value);
      }
      saveOnUnmount.current = cloneDeep(values);
    })();
  }, [values, dirtyFields]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setFormValues("personalDetails", data);
    handleNext();
  };

  React.useEffect(() => {
    return () => {
      setFormValues("personalDetails", saveOnUnmount.current);
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <SignupFormContainer
        title={"Personal details"}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <PersonalDetails
          handleBack={goBackHandler}
          disabled={!isValid || personalDetailsStatusBar < 100}
          isFirstPage={isFirstPage}
        />
      </SignupFormContainer>
    </FormProvider>
  );
};

export default SignupPersonalDetails;
