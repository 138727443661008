import {
  IParsedData,
  TMerchantInfo,
} from "@components/Merchants/MerchantPreview/data.types";
import { palette } from "@palette";

export const parseTaxID = (id: string) => {
  if (id.length > 2) return id.slice(0, 2) + "-" + id.slice(2);
  return id;
};

export const parsePhoneNumber = (number = "") => {
  if (!number) return "";
  if (number.includes("+")) return number;
  return `+${number}`;
};

const useGetSectionItems = (data: IParsedData) => {
  const { merchantInfo } = data;

  const merchantInfoItems = geEnterpriseInfoItems(merchantInfo);

  return {
    merchantInfoItems,
  };
};

const geEnterpriseInfoItems = (data: TMerchantInfo) => {
  const websiteUrl =
    data.websiteURL && data.websiteURL.includes("://")
      ? data.websiteURL.substring(data.websiteURL.indexOf("://") + 3)
      : data.websiteURL || "";
  return [
    {
      title: "Signup date",
      value: data?.createdAt,
      size: 12,
    },
    {
      title: "Provider Name",
      value: data.merchantName,
      size: 6,
    },
    {
      title: "Classification",
      value: data?.classification?.displayName,
      size: 6,
    },
    {
      title: "Merchant Service Phone Number",
      value: parsePhoneNumber(data.servicePhoneNumber),
      size: 6,
      isPhoneNumber: true,
    },
    {
      title: "Permalink",
      value: (
        <>
          {data.merchantSlug}
          <span style={{ color: palette.neutral[60] }}>.givepayments.com</span>
        </>
      ),
      size: 12,
      prefix: "https://",
      canBeCopied: true,
      link: `https://${data.merchantSlug}.givepayments.com`,
    },
    {
      title: "Website URL",
      value: websiteUrl,
      size: 12,
      prefix: "https://",
      canBeCopied: true,
      link: `https://${websiteUrl}`,
    },
    {
      title: "Countries Serviced Outside the USA/Canada",
      value: data?.serviceCountriesOutUSCanada
        ? data?.countriesServicedOutside
        : "None",
      size: 12,
    },
    {
      title: `Purpose or Mission of Provider`,
      value: (
        <span style={{ wordBreak: "break-word" }}>
          {data.businessPurpose || data?.description}
        </span>
      ),
      size: 12,
      clampText: false,
      split: true,
    },
  ];
};

export default useGetSectionItems;
