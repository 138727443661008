import { builder, generateUniqueID } from "@pages/NewAdvancedBuilder/utils";
import { ROOT_NODE_ID } from "../values";
import { cloneDeep } from "lodash";
import {
  DeviceType,
  selectedDeviceType,
  selectedItem,
} from "@pages/NewAdvancedBuilder/signals";
import { getDeviceTypeAttributesToUpdate } from "@pages/NewAdvancedBuilder/utils/helpers";

export function traverseAndProcessNodes(
  node: any,
  cb: any,
  parentID: number,
): any {
  // Create a deep copy of the node
  let newNode = cloneDeep({
    ...node,
    childrens: node.childrens
      ? node.childrens.map((child: any) => ({ ...child }))
      : [],
  });

  // Apply the callback to the new node if it has a tag
  if (newNode.tag) {
    newNode = cb(newNode, parentID);
  }

  // Recursively process the children and update them in the new node
  if (newNode.childrens) {
    newNode.childrens = newNode.childrens.map((child: any) =>
      traverseAndProcessNodes(child, cb, newNode.key),
    );
  }

  return newNode;
}

export const widgetFactory = (widget: any, selectedItemState?: any) => {
  const {
    cssProperties: rootCSSProperties,
    mobileCssProperties,
    state,
    tagName,
    styleParser,
    id: widgetTypeId,
    metaAttributes,
  } = widget;

  const obj = {
    ...widget,
    buildStyleObject: (type: DeviceType, isClone?: boolean) => {
      if (!styleParser) {
        return rootCSSProperties;
      }

      const cssProps =
        type === DeviceType.DESKTOP
          ? rootCSSProperties
          : mobileCssProperties || rootCSSProperties;

      const isButtonWidget = widget?.id === "button";

      return styleParser({
        ...cssProps,
        ...(type === DeviceType.MOBILE &&
          isButtonWidget &&
          !isClone && { alignSelf: "auto" }),
      });
    },
    createNode: function (headNodeId = null, newNode?: any) {
      const binders: any = [];

      const tree = traverseAndProcessNodes(
        newNode ?? tagName.getTree(),
        (node: any, parentID: number) => {
          //id is the id of the node blueprint.We do not need it for the nodes
          //parentId is the id of the parent of the node blueprint.We do not need it for the nodes
          const {
            isHead,
            styleParser,
            stateParser,
            bindings,
            content,
            id,
            ...restNode
          } = node;

          restNode.parentID = parentID;

          if (id) {
            restNode.blueprintID = id;
          }

          if (stateParser) {
            if (restNode.cssProperties) {
              Object.assign(
                restNode,
                stateParser({
                  state: selectedItemState ?? state,
                  cssProperties: restNode.cssProperties,
                }),
              );
            } else {
              Object.assign(
                restNode,
                stateParser({ state: selectedItemState ?? state }),
              );
            }
          } else if (styleParser) {
            const styles = styleParser(
              restNode?.cssProperties ?? selectedItemState ?? state,
            );
            restNode.style = {
              ...styles,
            };
            restNode.tabletStyle = {
              ...styles,
            };
            restNode.mobileStyle = {
              ...styles,
            };
          } else {
            console.error(
              node,
              "One Between StateParser and StyleParser is required",
            );
          }

          if (isHead) {
            //this is root node of the widget
            restNode.key = headNodeId;
            restNode.widgetType = widgetTypeId;
          } else {
            restNode.key = generateUniqueID();
            restNode.head = headNodeId;
          }

          if (bindings) {
            binders.push([
              restNode.key,
              applyBindingsFactory(restNode, bindings, stateParser),
            ]);
          }

          return restNode;
        },
        ROOT_NODE_ID,
      );

      if (newNode) {
        subs.subscribe("push", binders);
      } else {
        subs.subscribe("new", binders);
      }

      return { tree, binders };
    },
  };

  return {
    ...obj,
    createNode: typeof tagName !== "string" ? obj.createNode.bind(obj) : null,
  };
};

export const subs = subscription();

export function subscription() {
  let currentSubscription: any = null;
  let bindersCache: any = [];

  function makeSubscribers(signalValue: any) {
    return bindersCache.map(async (binder: any) => {
      if (signalValue) {
        binder[1](signalValue);
      }
    });
  }

  return {
    subscribe(event: "new" | "push", binders?: any[]) {
      if (binders?.length) {
        if (event === "new") {
          this.unsubscribe();
          bindersCache = binders;
        } else if (event === "push") {
          bindersCache.push(...binders);
        }
      }

      if (!currentSubscription) {
        currentSubscription = selectedItem.subscribe((signalValues) => {
          const subscriptions = makeSubscribers(signalValues);

          Promise.all(subscriptions).then(() => {
            builder.react("new");
          });
        });
      } else {
        const currentSignalValues = selectedItem.value;
        const subscriptions = makeSubscribers(currentSignalValues);

        Promise.all(subscriptions).then(() => {
          builder.react("updated");
        });
      }
    },
    unsubscribe() {
      if (currentSubscription) {
        currentSubscription();
        currentSubscription = null;
        bindersCache.splice(0);
      }
    },
  };
}

const getAttributesToUpdate = (obj: any) => {
  const uniqueKeys = new Set();

  Object.keys(obj).forEach((key) => {
    const baseKey = key.replace(/^(mobile|tablet)/, "");
    const lowerCasedBaseKey =
      baseKey.charAt(0).toLowerCase() + baseKey.slice(1);
    uniqueKeys.add(lowerCasedBaseKey);
  });

  return Array.from(uniqueKeys).reduce((acc: any, curr: any) => {
    return [
      ...acc,
      ...getDeviceTypeAttributesToUpdate(curr)[selectedDeviceType.value],
    ];
  }, []);
};

export function applyBindingsFactory(
  restNode: any,
  bindings: any,
  stateParser: any,
) {
  return (signalValues: any) => {
    if (!signalValues?.state) return;
    if (!signalValues.updates) return;
    if (!bindings) return;
    const bindedParser = stateParser.bind(
      null,
      restNode.cssProperties
        ? {
            state: signalValues.state,
            cssProperties: restNode.cssProperties,
            tabletCssProperties: restNode?.tabletCssProperties,
            mobileCssProperties: restNode?.mobileCssProperties,
          }
        : { state: signalValues.state },
    );

    if (
      typeof signalValues.updates === "object" &&
      signalValues.updates !== null &&
      !Array.isArray(signalValues.updates)
    ) {
      const v = signalValues.updates;

      if (typeof v === "object") {
        /**
         * Some compound widget's (image gallery for example) items (images) that we update don't need to have separate
         * parent nodes, in that case we check with key instead of parentID
         */
        const isNodeToUpdate = v.id
          ? v.id === restNode.key
          : restNode.parentID === v.parentID;
        if (isNodeToUpdate && v.blueprintID === restNode.blueprintID) {
          for (const k in restNode.cssProperties) {
            if (signalValues.state?.cssProperties?.[k] !== undefined) {
              getDeviceTypeAttributesToUpdate("cssProperties")[
                selectedDeviceType.value
              ].forEach((item: string) => {
                restNode[item][k] = signalValues.state.cssProperties[k];
              });
            }
          }

          updateAtribute(restNode, bindedParser());
        }
        return;
      }
    }

    const updaters: any = Object.entries(bindings).filter(([k, v]: any) => {
      return signalValues.updates.find((x: string) => v.startsWith(x));
    });

    if (!updaters.length) return;

    updateAtribute(restNode, bindedParser());
  };
}

function updateAtribute(node: any, newData: any) {
  const attributesToUpdate = getAttributesToUpdate(newData) as string[];
  for (const k in newData) {
    // if one of the property doesn't need to be updated because of selected device type, we skip iteration
    if (!attributesToUpdate.includes(k)) continue;
    const obj = newData[k];
    if (typeof obj === "object" && obj !== null) {
      for (const m in obj) {
        if (node[k][m] !== obj[m]) {
          node[k][m] = obj[m];
        }
      }
    } else {
      node[k] = obj;
    }
  }
}
