import React from "react";
//mui
import { Box, keyframes, Stack, styled } from "@mui/material";
import Logo from "@assets/icons/RebrandedIcons/Logo";
import Footer from "@pages/Login/Footer";
import { MainLogo } from "@hooks/common/useEnterpriseLogo";

type Props = {
  children: React.ReactNode;
  hideFooterLogo?: boolean;
};

const LoginContainer = ({ children, hideFooterLogo }: Props) => {
  return (
    <StyledRoot>
      <MainLogo position="center" defaultLogo={<Logo />} />
      <StyledStack gap={3}>{children}</StyledStack>
      <Footer hideLogo={hideFooterLogo} />
    </StyledRoot>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "41px 32px 24px 32px",
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px",
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  width: 448,
  padding: "24px 12px",
  borderRadius: 16,
  border: "2px solid #FFFFFF",
  backdropFilter: "blur(20px)",
  background: "rgba(255, 255, 255, 0.7)",
  boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
  animation: `${fadeIn} 250ms ease-in-out`,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default LoginContainer;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
