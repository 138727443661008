import { userVerificationSteps } from "@constants/constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Keys = keyof typeof userVerificationSteps;
type VerificationStep = typeof userVerificationSteps[Keys];
type VerificationMethodSelected = "email" | "text" | null; //null means i already have a code

export interface VerificationState {
  currentVerificationStep: VerificationStep;
  methodSelected: VerificationMethodSelected;

  // snackBarOpen: boolean;
}

const initialState: VerificationState = {
  currentVerificationStep: userVerificationSteps.idle,
  methodSelected: "email",
};

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    setVerificationStep: (state, action: PayloadAction<VerificationStep>) => {
      state.currentVerificationStep = action.payload;
    },
    setMethodSelected: (
      state,
      action: PayloadAction<VerificationMethodSelected>,
    ) => {
      state.methodSelected = action.payload;
    },
    setVerificationState: (state, action: PayloadAction<VerificationState>) => {
      state.currentVerificationStep = action.payload.currentVerificationStep;
      state.methodSelected = action.payload.methodSelected;
    },
  },
});

// const transferMoneySlice = createSlice({
//   name: "moneyTransfer",
//   initialState,
//   reducers: {
//     setValidationStep: (state, action: PayloadAction<VerificationStep>) => {
//       state.currentVerificationStep = action.payload;
//     },
//   }
// })

export const { setVerificationStep, setMethodSelected, setVerificationState } =
  verificationSlice.actions;
export default verificationSlice.reducer;
