interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const CardMoneyIcon = ({
  width = 22,
  height = 20,
  fill = "none",
  path_fill = "#727179",
}: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.55"
        d="M18 12.5L8.5 14.5L6.5 15L6 14L5 5.5L20.5 6L19.5 10L18 12.5Z"
        fill="#8F8F8F"
      />
      <path
        d="M20.9105 5.43793C20.8173 5.32378 20.6931 5.25855 20.5379 5.25855H5.40111C4.98193 3.25272 3.81757 2.16011 3.77099 2.11119C3.56917 1.94811 3.2742 1.96442 3.11895 2.16011C2.94817 2.37211 2.9637 2.68195 3.15 2.84503C3.16552 2.86134 4.26779 3.90502 4.54724 5.81301L5.8979 15.1899C6.05315 16.2335 6.8915 17 7.90061 17H17.1224C17.3863 17 17.5881 16.788 17.5881 16.5108C17.5881 16.2335 17.3863 16.0215 17.1224 16.0215H7.90061C7.35724 16.0215 6.90702 15.6139 6.8294 15.0431L5.55635 6.237H19.9479L19.0164 10.3302C18.768 11.4391 17.9142 12.2708 16.8274 12.4339C16.8274 12.4339 6.7484 13.9674 6.5 14C6.2516 14.0326 6.45343 14.2391 6.5 14.5C6.53105 14.7446 6.26713 15 6.5 15L8.49056 14.7169L16.9672 13.4123C18.4265 13.184 19.5753 12.0751 19.9169 10.5748L20.9881 5.87824C21.0192 5.71516 20.9881 5.56839 20.9105 5.43793Z"
        fill="#8F8F8F"
      />
      <path
        opacity="0.55"
        d="M9.07246 22C7.92464 22 7 21.1077 7 20C7 18.8923 7.92464 18 9.07246 18C10.2203 18 11.1449 18.8923 11.1449 20C11.1449 21.1077 10.2203 22 9.07246 22ZM9.07246 18.9231C8.45072 18.9231 7.95652 19.4 7.95652 20C7.95652 20.6 8.45072 21.0769 9.07246 21.0769C9.6942 21.0769 10.1884 20.6 10.1884 20C10.1884 19.4 9.6942 18.9231 9.07246 18.9231ZM15.9275 22C14.7797 22 13.8551 21.1077 13.8551 20C13.8551 18.8923 14.7797 18 15.9275 18C17.0754 18 18 18.8923 18 20C18 21.1077 17.0754 22 15.9275 22ZM15.9275 18.9231C15.3058 18.9231 14.8116 19.4 14.8116 20C14.8116 20.6 15.3058 21.0769 15.9275 21.0769C16.5493 21.0769 17.0435 20.6 17.0435 20C17.0435 19.4 16.5493 18.9231 15.9275 18.9231Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default CardMoneyIcon;
