import { ConversationsCaretLeft } from "@assets/icons";
import { Text, TruncateText } from "@common/Text";
import { Stack } from "@mui/material";
import { Breadcrumbs } from "@mui/material";
import ImagesStacker from "../../utils/ImagesStacker";
import { useAppDispatch } from "@redux/hooks";
import { resetHiddenInputs } from "@redux/slices/conversations";
import { isEmpty } from "lodash";
import { truncateString } from "@utils/helpers";
import { TQueryObject } from "../../types";

interface Props {
  backButtonEnabled: boolean;
  handleBreadCrumbClick: () => void;
  queryObject?: TQueryObject;
}

export default function ConversationsBreadcrumbs({
  backButtonEnabled,
  handleBreadCrumbClick,
  queryObject,
}: Props) {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(resetHiddenInputs());
    !backButtonEnabled && handleBreadCrumbClick();
  };
  const conversationThreadPathObj = queryObject?.paths?.find(
    (item) => item.isConversation,
  );
  const isConversation = !isEmpty(conversationThreadPathObj);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      data-testid="conversation-breadcrumb-container"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        sx={{ cursor: "pointer" }}
        onClick={handleClick}
        data-testid="conversation-topic-container"
      >
        <ConversationsCaretLeft width={9} height={16.5} />
        <TruncateText
          lineClamp={1}
          maxWidth={isConversation ? "auto" : "100px"}
          color="#8F8F8F"
          data-testid="conversation-topic-title"
        >
          {isConversation
            ? conversationThreadPathObj?.pathName
            : queryObject?.name}
        </TruncateText>
      </Stack>

      {!isConversation &&
        queryObject?.paths.map(({ avatars, pathName }, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            sx={{ cursor: "default" }}
            gap={1}
            onClick={() => void 0}
          >
            <Text fontWeight="regular" color="#292727">
              {truncateString(pathName, 15)}
            </Text>
            <ImagesStacker data={avatars} />
          </Stack>
        ))}
    </Breadcrumbs>
  );
}
