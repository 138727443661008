import { memo } from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// components
import { CloseIcon } from "@assets/icons";
//assets
import Popup from "@common/Popup";
import { CustomTheme } from "@theme/v2/palette.interface";
import useSavePaymentForm from "@pages/NewAdvancedBuilder/hooks/useSavePaymentForm";

type Props = {
  title: string;
  description?: string;
  submitButtonTitle?: string;
  imageURL?: string;
  theme: CustomTheme;
  productId: number;
  productTitle: string;
};

const AdvancedBuilderSaveDraftModal = NiceModal.create(
  ({
    title,
    description,
    submitButtonTitle,
    theme,
    productId,
    productTitle,
  }: Props) => {
    const modal = useModal();
    const { isLoading, handleSaveClick } = useSavePaymentForm({
      modal,
      productId,
      productTitle,
      theme,
    });

    return (
      <Popup
        {...muiDialogV5(modal)}
        customContainerStyle={{
          backgroundColor: theme.palette.primitive?.neutral[10],
          border: "none",
        }}
        title={title}
        titleSx={{
          fontSize: "24px",
          color: theme.palette.primitive?.neutral[100],
          lineHeight: "28px",
        }}
        textProps={{
          color: theme.palette.primitive?.neutral[70],
          fontSize: "14px",
          fontWeight: "regular",
          lineHeight: "20px",
          marginTop: "8px",
        }}
        isClose={true}
        CustomCloseIcon={
          <CloseIcon stroke={theme.palette.primitive?.neutral[90]} />
        }
        isCloseIconDisable={isLoading}
        cancelLabel="Cancel"
        cancelButtonProps={{
          size: "medium",
          disabled: isLoading,
        }}
        cancelButtonSx={{
          color: theme.palette.primitive?.neutral[100],
        }}
        onCancel={() => modal.hide()}
        actionLabel={submitButtonTitle || "Save"}
        submitButtonProps={{
          size: "medium",
        }}
        submitButtonSx={{
          color: theme.palette.primitive?.neutral[10],
          bgcolor: theme.palette.primitive?.neutral[100],
        }}
        isShowTextWhileLoading={true}
        onSubmit={() => handleSaveClick()}
        isLoading={isLoading}
        isSubmitDisabled={isLoading}
      >
        {description}
      </Popup>
    );
  },
);

export default memo(AdvancedBuilderSaveDraftModal);
