import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { TableColumnType, TableData, TableOrder } from "@common/Table/helpers";
import {
  setTableSortingOrder,
  disableTableSortingOrder,
  setTableSortingOrderProperty,
  disableTableSortingOrderProperty,
} from "@redux/slices/app";
import { updateSorting } from "@redux/slices/fundraisers";

type Props = {
  small?: boolean;
  setSorting?: Dispatch<SetStateAction<string>>;
  initialSortingProperty?: string;
};

export const useTableSort = ({
  small,
  setSorting,
  initialSortingProperty = "id",
}: Props) => {
  const dispatch = useAppDispatch();

  const [sortKey, setSortKey] = React.useState<null | keyof TableData>(null);

  const [order, setOrder] = React.useState<TableOrder>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof TableData>(
    initialSortingProperty,
  );

  const getNewOrder = (columnKey: keyof TableData) => {
    let newOrder: TableOrder = "asc";

    if (sortKey === null) {
      newOrder = "asc";
    } else {
      if (sortKey !== columnKey) {
        newOrder = "asc";
      } else {
        newOrder = order === "asc" ? "desc" : "asc";
      }
    }

    return newOrder;
  };

  useEffect(() => {
    setSortKey("createdAt");
    setOrder("desc");
    setOrderBy("createdAt");
  }, []);

  const handleOpenMenu =
    (column: TableColumnType) => (event: React.MouseEvent<HTMLElement>) => {
      setSortKey(column.key);
      setOrderBy(column.key);

      const newOrder = getNewOrder(column.key);
      setOrder(newOrder);

      const newSorting = newOrder === "asc" ? column.key : `-${column.key}`;
      if (setSorting) {
        setSorting(newSorting);
        return;
      }
      if (small) {
        dispatch(setTableSortingOrderProperty(column.key));
        dispatch(setTableSortingOrder(newSorting));
        return;
      }

      dispatch(updateSorting(newSorting));
    };

  React.useEffect(() => {
    return () => {
      dispatch(disableTableSortingOrder());
      dispatch(disableTableSortingOrderProperty());
      dispatch(updateSorting("-createdAt")); //set back to initial value
    };
  }, []);

  return { handleOpenMenu, orderBy, sortKey, order };
};
