import * as React from "react";
import { palette } from "@palette";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { showMessage } from "@common/Toast/ShowToast";
// assets
import { RefreshIcon } from "@assets/icons";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";

type IFormInputs = {
  recurrence: string;
  maxNumber: string;
};

const MakeRecuring = NiceModal.create(() => {
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const schema = Yup.object({
    recurrence: Yup.string().required("This field is required"),
  });

  const defaultValues = {
    maxNumber: "",
    recurrence: "",
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (isDesktop) {
      showMessage("Success", "Purchase is now recurring");
    } else {
      showMessage("Success", "Purchase is now recurring", false);
    }
    modal.hide();
  };

  return (
    <Modal
      {...muiDialogV5(modal)}
      title="Make Recuring Transaction"
      onClose={() => modal.hide()}
      titleIcon={<RefreshIcon />}
      closeAfterTransition={true}
      actions={
        <>
          <Button variant="text" onClick={() => modal.hide()}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" form="make-recurring">
            Confirm
          </Button>
        </>
      }
    >
      <Stack
        mt={1}
        gap={2}
        direction={isDesktop ? "row" : "column"}
        alignItems={isDesktop ? "center" : "flex-start"}
      >
        <Box
          component="img"
          src="/settings-recurringmodify.png"
          alt="settings-recurringmodify"
        />
        <Box>
          <Text variant="h5" fontWeight="semibold" color={palette.neutral[800]}>
            Antonio Silvestre, antonio@gmail.com, VISA 1111
          </Text>
          <Text color={palette.neutral[600]}>
            Fill the fields below and click “Confirm”
          </Text>
        </Box>
      </Stack>
      <FormProvider {...methods}>
        <Grid
          mt={2}
          mb={1}
          container
          spacing={2}
          component="form"
          id="make-recurring"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid item xs={12} sm={6}>
            <RHFSelect
              name="recurrence"
              placeholder="Select an option"
              label="Recurrence"
              options={[
                {
                  value: "Daily",
                  label: "Daily",
                },
                {
                  value: "Monthly",
                  label: "Monthly",
                },
                {
                  value: "Quarterly",
                  label: "Quarterly",
                },
                {
                  value: "Yearly",
                  label: "Yearly",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHFInput
              name="maxNumber"
              fullWidth
              placeholder="Enter a number"
              type="number"
              label="Max # number of payments"
              helperText="Leave blank to have no limit"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
});

export default MakeRecuring;
