import { memo } from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
//assets
import Popup from "@common/Popup";
import { CustomTheme } from "@theme/v2/palette.interface";
import { X } from "@phosphor-icons/react";
import { Box } from "@mui/material";
import useSavePaymentForm from "@pages/NewAdvancedBuilder/hooks/useSavePaymentForm";
import useCloseBuilder from "@pages/NewAdvancedBuilder/hooks/useCloseBuilder";

type Props = {
  theme: CustomTheme;
  productId: number;
  productTitle: string;
};

const AdvancedBuilderExitModal = NiceModal.create(
  ({ theme, productId, productTitle }: Props) => {
    const modal = useModal();
    const { handleClose } = useCloseBuilder();

    const { handleSaveClick, isLoading } = useSavePaymentForm({
      modal,
      productId,
      productTitle,
      theme,
    });

    const onClose = () => {
      modal.hide();
      handleClose();
    };

    const handleSaveDraft = () => {
      handleSaveClick(false, handleClose);
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        customContainerStyle={{
          backgroundColor: theme.palette.primitive?.neutral[5],
          border: "none",
        }}
        title="Exit without saving?"
        titleSx={{
          fontSize: "24px",
          color: theme.palette.primitive?.neutral[100],
          lineHeight: "28px",
        }}
        textProps={{
          color: theme.palette.primitive?.neutral[70],
          fontSize: "14px",
          fontWeight: "regular",
          lineHeight: "20px",
          marginTop: "8px",
        }}
        cancelLabel="Exit anyway"
        cancelButtonProps={{
          size: "medium",
        }}
        cancelButtonSx={{
          color: theme.palette.primitive?.neutral[100],
          fontWeight: "regular",
          padding: "11px 20px",
          "&:hover": {
            backgroundColor: theme.palette.primitive?.transparent["darken-5"],
            textDecoration: "none",
          },
        }}
        onCancel={onClose}
        actionLabel="Save draft &amp; Exit"
        submitButtonProps={{
          size: "medium",
        }}
        submitButtonSx={{
          color: theme.palette.common.black,
          bgcolor: theme.palette.primitive?.neutral[100],
          fontWeight: "regular",
          padding: "11px 20px",
          "&:hover": {
            backgroundColor: theme.palette.buttons?.["default-hover"],
          },
        }}
        isClose
        CustomCloseIcon={
          <Box
            sx={{
              padding: "6px",
              width: "32px",
              height: "32px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor:
                  theme.palette.primitive?.transparent["darken-5"],
              },
            }}
          >
            <X size={24} color={theme.palette.buttons?.["default-hover"]} />
          </Box>
        }
        onCustomCloseIconClick={() => modal.hide()}
        onSubmit={handleSaveDraft}
        isLoading={isLoading}
        isSubmitDisabled={isLoading}
        isShowTextWhileLoading={true}
        overrideClose
      >
        {`Are you sure you want to exit? Any unsaved changes will be lost.`}
      </Popup>
    );
  },
);

export default memo(AdvancedBuilderExitModal);
