import { Box, Grid } from "@mui/material";
import React from "react";

type Statuses = {
  pah: boolean;
  business_details: boolean;
  bank_account: boolean;
  merchant_agreement: boolean;
};

export const Pils = ({
  statuses,
  paddingTop,
}: {
  statuses: Statuses;
  paddingTop?: number;
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      paddingTop={paddingTop ?? 3}
    >
      <Pill testId="pah-pill" status={statuses.pah} />
      <Pill testId="business-details-pill" status={statuses.business_details} />
      <Pill testId="bank-account-pill" status={statuses.bank_account} />
      <Pill
        testId="merchant-agreement-pill"
        status={statuses.merchant_agreement}
      />
    </Grid>
  );
};

const Pill = ({ status, testId }: { status: boolean; testId?: string }) => {
  return (
    <Box
      width="22%"
      data-testid={testId}
      height="8px"
      sx={{
        background: status
          ? pillVariant?.completed?.backgroundColor
          : pillVariant?.incompleted?.backgroundColor,
        borderRadius: 100,
      }}
    />
  );
};

/*
incomplete -> either data not inserted or not approved
completed -> is approved
*/
const pillVariant = {
  incompleted: {
    backgroundColor: "rgba(109, 156, 248, 0.50)",
  },
  completed: {
    backgroundColor: "#6D9CF8",
  },
};
