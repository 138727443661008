import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
// components
import { Text } from "@common/Text";
import { KeyVal } from "@common/KeyVal";

type PaymentDetailsProps = {
  Type: string;
  Recurrence: string;
  Donated?: string;
};

const Progress = styled(LinearProgress)(({ theme }) => ({
  width: 120,
  height: "8px",
  borderRadius: "32px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: palette.neutral[20],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "32px",
    backgroundColor: palette.neutral[100],
  },
}));

function MobileTransactionExtraPurchaseInfo({ Type, Recurrence }: PaymentDetailsProps) {
  return (
    <Grid
      container
      sx={{
        padding: "0px",
        "& > :nth-of-type(odd)": {
          backgroundColor: "#F6FBFF",
        },
      }}
    >
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Type"
          value={<Text fontWeight="medium">{Type}</Text>}
          mobileView={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" p="12px 8px" justifyContent="space-between">
          <Text color={palette.neutral[600]}>Recurrence</Text>
          <Box>
            <Text
              textAlign="right"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {Recurrence}
            </Text>
            {Recurrence !== "One Time" && (
              <Stack mt={1} gap="4px" direction="row" alignItems="center">
                <Text>3 out of 6</Text>
                <Box>
                  <Progress variant="determinate" value={50} />
                </Box>
              </Stack>
            )}
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <KeyVal
          sx={{ color: palette.neutral[600] }}
          keyName="Donated"
          value={
            <Text fontWeight="medium">Enter any amount xQty@64.00USD each</Text>
          }
          mobileView={true}
        />
      </Grid>
    </Grid>
  );
}

export default MobileTransactionExtraPurchaseInfo;
