import React from "react";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { VirtualListLayoutWrapper } from "../wrappers/VlLayoutWrapper";
import { GridProps } from "@mui/material";

// Define the props for VirtualList
interface VirtualListProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  gridProps?: GridProps;
}

export const VirtualList = React.forwardRef<any, VirtualListProps>(
  ({ style, children, gridProps }, listRef) => {
    return (
      <ErrorCatcher errorID="UnfoldListItems">
        <div
          style={{
            overflow: "hidden",
            padding: 0,
            ...style,
          }}
          ref={listRef}
        >
          <VirtualListLayoutWrapper gridProps={gridProps} show>
            {children}
          </VirtualListLayoutWrapper>
        </div>
      </ErrorCatcher>
    );
  },
);

VirtualList.displayName = "VirtualList";
