import { CaretDown, CaretUp } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import { useDropdownMenu } from "@common/Menu/hooks/useDropdownMenu";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { Dispatch, useMemo } from "react";
import { ExtendedTChallengeStatus } from "./ChallengeLayout";

type Props = {
  totalChallenges: number;
  totalReadyForConfirmation: number;
  totalOpen: number;
  totalCompleted: number;
  challengeFilter: string;
  setChallengeFilter: Dispatch<React.SetStateAction<ExtendedTChallengeStatus>>;
};

const ChallengeDropdown = ({
  totalChallenges,
  totalCompleted,
  totalOpen,
  totalReadyForConfirmation,
  challengeFilter,
  setChallengeFilter,
}: Props) => {
  const { DropdownMenu, openMenu, anchorEl } = useDropdownMenu();

  const labelMapper: Record<string, string> = useMemo(
    () => ({
      all: "All Challenges",
      ready_for_verification: "Ready for Confirmation",
      open: "Open",
      closed: "Completed",
    }),
    [],
  );

  const actions = useMemo(
    () => [
      {
        label: `All Challenges (${totalChallenges})`,
        onSelect: () => setChallengeFilter("all"),
        selected: challengeFilter === "all",
      },
      {
        label: `Ready for Confirmation (${totalReadyForConfirmation})`,
        onSelect: () => setChallengeFilter("ready_for_verification"),
        selected: challengeFilter === "ready_for_verification",
        disabled: !totalReadyForConfirmation,
      },
      {
        label: `Open (${totalOpen})`,
        onSelect: () => setChallengeFilter("open"),
        selected: challengeFilter === "open",
        disabled: !totalOpen,
      },
      {
        label: `Completed (${totalCompleted})`,
        onSelect: () => setChallengeFilter("closed"),
        selected: challengeFilter === "closed",
        disabled: !totalCompleted,
      },
    ],
    [
      totalChallenges,
      totalCompleted,
      totalOpen,
      totalReadyForConfirmation,
      challengeFilter,
    ],
  );

  return (
    <Stack my={1} width="fit-content">
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        onClick={openMenu}
        width="auto"
        sx={{ cursor: "pointer" }}
        data-testid="challenge-filter-button"
      >
        <Text fontSize={16} color="#575353">
          {labelMapper[challengeFilter]}
        </Text>
        {!anchorEl ? (
          <CaretDown height={16} width={16} stroke="#575353" fill="#575353" />
        ) : (
          <CaretUp height={16} width={16} stroke="#575353" fill="#575353" />
        )}
      </Stack>
      <DropdownMenu
        testId="challenge-filter"
        MenuOptions={{
          sx: {
            "& p": {
              lineHeight: "16.8px !important",
              padding: "4px 0px",
            },
          },
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "center",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        items={actions}
        selectionBehaviour
      />
    </Stack>
  );
};

export default ChallengeDropdown;
