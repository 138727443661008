import { useRef, useState, useMemo, useEffect } from "react";
import { styled, useTheme, useMediaQuery, Box, BoxProps } from "@mui/material";
import { palette } from "@palette";
import ModalDrawerHeader from "./ModalDrawerHeader";
import KotoStepper from "@common/SignUp/KotoStepper";
import { Text } from "@common/Text";
import ModalDrawerActions from "./ModalDrawerActions";
import { ModalDrawerProps } from "./types";

const ModalDrawerBody = ({
  HeaderProps,
  onModalClose,
  steps,
  children,
  currentStep,
  setCurrentStep,
  primaryAction,
  isPreBuildFormBuilder,
  isBody,
}: ModalDrawerProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const stepperRef = useRef<any>(null);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const stepIndex = useMemo(() => {
    return steps?.findIndex((step) => step.label === currentStep);
  }, [steps, currentStep]);

  useEffect(() => {
    if (!steps) setIsLoaded(true);
  }, []);

  const handleNext = () => (stepperRef.current as any).handleNext();
  const handleBack = () => (stepperRef.current as any).handleBack();

  const handleSubmitStep = () => {
    if (primaryAction.onClick) primaryAction.onClick();
    if (steps && stepIndex !== steps.length - 1) handleNext();
  };

  const actionProps = {
    steps: Boolean(steps),
    stepIndex: stepIndex,
    onModalClose: onModalClose,
    primaryAction: primaryAction,
    handleBack,
    handleSubmitStep,
    isPreBuildFormBuilder,
  };

  return (
    <BodyContainer height={isBody ? "96vh" : "100vh"}>
      {!isDesktop && (
        <ModalDrawerHeader {...HeaderProps} onModalClose={onModalClose} />
      )}
      {steps && (
        <KotoStepper
          sx={{
            ...(isDesktop && {
              borderBottom: `1px solid ${palette.liftedWhite[100]}`,
              background: "rgba(251, 251, 251, 0.50)",
              boxShadow: "0px 4px 8px 0px rgba(175, 175, 175, 0.05)",
              padding: "6px 8px",
            }),
          }}
          setStep={setCurrentStep}
          defaultStep={steps[0].label}
          customSteps={steps}
          ref={stepperRef}
          setIsLoaded={setIsLoaded}
          svgProps={{
            height: 8,
            width: 11,
          }}
          LinearProgressProps={{
            fontSize: "14px",
            lineHeight: "16.2px",
            mt: "12px",
          }}
        />
      )}
      {!steps && isDesktop && !isPreBuildFormBuilder && (
        <EditDesktopHeader>
          <Text
            color={palette.black[100]}
            variant="headline"
            lineHeight="21.6px"
          >
            {HeaderProps?.title}
          </Text>
          <ModalDrawerActions {...actionProps} />
        </EditDesktopHeader>
      )}
      {isLoaded && (
        <DrawerContent issteps={Boolean(steps)}>{children}</DrawerContent>
      )}
      {(steps || !isDesktop || isPreBuildFormBuilder) && (
        <>
          <Box flexGrow={1} />
          <ModalDrawerActions {...actionProps} />
        </>
      )}
    </BodyContainer>
  );
};

const BodyContainer = styled(Box)(() => ({
  width: "100%",
  overflowY: "auto",
  overflowX: 'hidden',
  display: "flex",
  flexDirection: "column",
  position: 'relative'
}));

const EditDesktopHeader = styled(Box)(() => ({
  borderBottom: `1px solid ${palette.neutral[10]}`,
  background: palette.background.dimmedWhite,
  boxShadow: "0px 4px 8px 0px rgba(175, 175, 175, 0.05)",
  padding: "12px 16px",
  backdropFilter: "blur(25px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "sticky",
  top: 0,
  zIndex: 99,
}));

const DrawerContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "issteps",
})<BoxProps & { issteps: boolean }>(
  ({ theme, issteps }) => ({
    paddingInline: "16px",
    flexGrow: 1,
    marginTop: "32px",
    ...(!issteps && {
      marginBottom: "16px",
    }),

    [theme.breakpoints.down("sm")]: {
      paddingInline: "20px",
      marginTop: "24px",
    },
  }),
);

export default ModalDrawerBody;
