// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const FileLightIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.60002 4.60036C7.08051 4.60036 6.60002 5.05774 6.60002 5.69733V17.3034C6.60002 17.943 7.08051 18.4004 7.60002 18.4004H16.4C16.9195 18.4004 17.4 17.943 17.4 17.3034L17.4 16.4549C17.4 16.1235 17.6686 15.8549 18 15.8549C18.3314 15.8549 18.6 16.1235 18.6 16.4549L18.6 17.3034C18.6 18.5382 17.6478 19.6004 16.4 19.6004H7.60002C6.35223 19.6004 5.40002 18.5382 5.40002 17.3034V5.69733C5.40002 4.4625 6.35223 3.40036 7.60002 3.40036H14C14.1653 3.40036 14.3232 3.46852 14.4366 3.58875L18.4366 7.83118C18.5416 7.94252 18.6 8.08976 18.6 8.24279L18.6 12.061C18.6 12.3923 18.3314 12.661 18 12.661C17.6686 12.661 17.4 12.3923 17.4 12.061L17.4 8.84278H14C13.6687 8.84278 13.4 8.57416 13.4 8.24278V4.60036H7.60002ZM14.6 5.51134L16.6097 7.64278H14.6V5.51134Z"
          fill="url(#paint0_linear_304_1618)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_304_1618"
            x1="14.8149"
            y1="4.99057"
            x2="-2.31948"
            y2="13.8389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60002 4.60036C7.08051 4.60036 6.60002 5.05774 6.60002 5.69733V17.3034C6.60002 17.943 7.08051 18.4004 7.60002 18.4004H16.4C16.9195 18.4004 17.4 17.943 17.4 17.3034L17.4 16.4549C17.4 16.1235 17.6686 15.8549 18 15.8549C18.3314 15.8549 18.6 16.1235 18.6 16.4549L18.6 17.3034C18.6 18.5382 17.6478 19.6004 16.4 19.6004H7.60002C6.35223 19.6004 5.40002 18.5382 5.40002 17.3034V5.69733C5.40002 4.4625 6.35223 3.40036 7.60002 3.40036H14C14.1653 3.40036 14.3232 3.46852 14.4366 3.58875L18.4366 7.83118C18.5416 7.94252 18.6 8.08976 18.6 8.24279L18.6 12.061C18.6 12.3923 18.3314 12.661 18 12.661C17.6686 12.661 17.4 12.3923 17.4 12.061L17.4 8.84278H14C13.6687 8.84278 13.4 8.57416 13.4 8.24278V4.60036H7.60002ZM14.6 5.51134L16.6097 7.64278H14.6V5.51134Z"
        fill={fill}
      />
    </svg>
  );
};

export default FileLightIcon;
