import DownloadCloudIcon from "@assets/icons/RebrandedIcons/DownloadCloudIcon";
import { StyledIconButton } from "@common/IconButton";

export const StyledDownloadButton = () => {
  return (
    <StyledIconButton
      sx={{
        height: "48px !important",
        width: "48px !important",
        padding: "0 !important",
        "&:hover path": {
          fill: "#6D9CF8",
        },
      }}
    >
      <DownloadCloudIcon width={24} height={24} fill="#8F8F8F" />
    </StyledIconButton>
  );
};
