import { Text } from "@common/Text";
import { Skeleton, Stack, styled } from "@mui/material";
import { Box } from "@mui/material";
import { CaretRight, ChatsCircle } from "@phosphor-icons/react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  setConversationTopic,
} from "@redux/slices/conversations";

import { isEmpty } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import UnderwritingThreads from "./UnderwritingThreads";
import CreateTopicButton from "./Sections/CreateTopicButton";
import useGetActivityList from "../hooks/useGetActivityList";
import { Thread } from "./types";

function ActivityList({
  merchantId,
  isEnterprise,
  topicData,
  handleOpenConversationActivityThread,
  merchantName,
  handleOpenManageUnderwriting,
}: {
  merchantId: number;
  isEnterprise: boolean;
  handleOpenConversationActivityThread: () => void;
  topicData: any;
  merchantName?: string;
  handleOpenManageUnderwriting?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { isOpen, numberOfUnreadMessages, queryObject, tabs } = useAppSelector(
    selectConversationTopic,
  );

  const handleOnClick = (item: Thread) => {
    const currentPath = queryObject?.paths;

    const paths = [
      ...currentPath,
      {
        pathName: item?.title,
        pathID: item?.id,
        avatars: [],
        isConversation: true,
      },
    ];

    dispatch(
      setConversationTopic({
        isOpen,

        isOpenedFromSidePanel: false,
        numberOfUnreadMessages,
        isOnlyPathName: true,
        queryObject: {
          ...queryObject,
          paths,
        },
      }),
    );
  };
  const startConversation = (
    <CreateTopicButton
      isEnterprise={isEnterprise}
      handleOpenTopicModal={handleOpenConversationActivityThread}
    />
  );

  const { isLoading, data, selectedThread, selectedThreadPathConversation } =
    useGetActivityList({
      merchantId,
    });

  if (!isEmpty(selectedThreadPathConversation))
    return (
      <UnderwritingThreads
        isEnterprise={isEnterprise}
        merchantID={merchantId}
        thread={selectedThread}
        topicData={topicData}
        handleOpenManageUnderwriting={handleOpenManageUnderwriting}
        merchantName={merchantName}
      />
    );

  if (isLoading)
    return (
      <Stack px="16px">
        {Array(5)
          .fill(null)
          .map((_, i) => (
            <Skeleton
              key={i}
              sx={{
                borderRadius: "8px",
                marginBottom: "12px",
              }}
              width="100%"
              height="55px"
              variant="rectangular"
            />
          ))}
      </Stack>
    );

  if (isEmpty(data?.data))
    return (
      <>
        <Stack
          alignItems="center"
          justifyItems="center"
          justifyContent="center"
          height="70%"
          width="100%"
        >
          <ChatsCircle color="#B8B8B8" size={128} />
          <Text color="#8F8F8F" fontWeight="book" fontSize="18px">
            No topics yet...
          </Text>
        </Stack>
        {startConversation}
      </>
    );
  return (
    <Box
      sx={{
        cursor: "pointer",
        overflowY: "auto",
        maxHeight: "100%",
        px: "16px",
        pt: "8px",
      }}
    >
      {data?.data?.map((item) => (
        <SingleItem
          handleOnClick={() => handleOnClick(item)}
          key={item?.id}
          item={item}
        />
      ))}

      {startConversation}
    </Box>
  );
}

export default ActivityList;

const config = { mass: 1, tension: 400, friction: 30 };
const reset = true;
const SingleItem = ({
  item,
  handleOnClick,
}: {
  handleOnClick: () => void;
  item: Thread;
}) => {
  const [isMouseOn, setIsMouseOn] = useState(false);

  const iconAnimation = useSpring({
    from: { transform: "translateX(100px)" },
    to: { transform: "translateX(0px)" },
    config,
    reset,
  });
  const [props, set] = useSpring(() => ({
    boxShadow: `0px 0px 16px 0px rgba(0, 0, 0, 0.12)`,
    from: {
      boxShadow: `0px 0px 0px 0px rgba(0, 0, 0, 0.12)`,
    },
    config,
    reset,
  }));
  const updateHover = (hovering: boolean) => ({
    boxShadow: `0px ${
      hovering ? "0px 16px" : "0px 0px"
    } 0px rgba(0, 0, 0, 0.30)`,
  });

  const onMouseEnter = () => {
    setIsMouseOn(true);
    set(updateHover(true));
  };
  const onMouseLeave = () => {
    setIsMouseOn(false);
    set(updateHover(false));
  };
  const hasResponse = item?.didSubjectAccReply || item?.hasUnreadMessages;
  return (
    <Container
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={props}
    >
      <Box>
        <Text mb="8px" color="#292727" fontWeight="regular" fontSize="14px">
          {item?.title || item?.topicName || ""}
        </Text>
        <Text fontWeight="book" fontSize="12px" color="#8F8F8F">
          {moment.unix(item?.createdAt).format("DD MMM YYYY, HH:mm")}{" "}
          {!hasResponse && "- Waiting for a response"}
        </Text>
      </Box>

      {isMouseOn && !hasResponse && (
        <animated.span
          color="#575353"
          style={{ ...iconAnimation, marginTop: "10px" }}
        >
          <CaretRight size={24} />
        </animated.span>
      )}

      {hasResponse && (
        <Box borderRadius="50%" height="12px" width="12px" bgcolor="#FF8124" />
      )}
    </Container>
  );
};

const Container = styled(animated.div)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  borderRadius: "8px",
  padding: "8px",
  marginBottom: "8px",
  backgroundColor: "#FFFFFF",
  overflow: "hidden",
  cursor: "default",
}));
