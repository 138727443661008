import { PaymentInformationCard } from "@components/PaymentForm";
import PublicFormFooter from "@components/PaymentForm/PublicFormFooter";
import InvoicesDetailsForm from "./InvoicesDetailsForm";
import PublicForm from "@common/PublicForm/PublicForm";
import { useLocation, useParams } from "react-router-dom";
import { useFindInvoiceById } from "@hooks/merchant-api/invoices";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { NotFoundResourcePage } from "@common/404";
import { useAppDispatch } from "@redux/hooks";
import { setCurrentCampaign } from "@redux/slices/checkout";
import { useEffect, useState } from "react";
import { PublicPeekMode } from "@common/PeekMode/types";
import { MOBILE_PUBLIC_FORM_TICKETS } from "@constants/componentRegistryConstants";
import CheckoutWrapper from "@common/PublicForm/CheckoutWrapper";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";
import { InvoiceType } from "./types";

const InvoicesPublicForm = ({
  previewId,
  title,
  isPeekMode,
}: PublicPeekMode) => {
  const { id } = useParams();
  const { isDesktop } = getPublishedFormDeviceType();
  const { data, isLoading, redirect } = useFindInvoiceById(
    previewId || id,
    true,
  );
  const { pathname } = useLocation();
  const currentProduct = title || pathname.split("/")[2];
  const formattedProductName =
    currentProduct.charAt(0).toUpperCase() + currentProduct.slice(1);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data) dispatch(setCurrentCampaign({ title: data.general.title }));
  }, [data]);

  if (isLoading) return <LoadingSpinner />;

  if (redirect) {
    return <NotFoundResourcePage />;
  }

  const authorInfos = {
    name: data?.general.creatorName,
    avatar: data?.general.creatorImage,
    description: data?.general.creatorDescription,
  };

  return (
    <>
      <PublicForm
        type="Invoice"
        useAsBackground={data?.general.featuredImage.useAsBackground || false}
        bannerImage={
          data?.general.featuredImage.image
            ? data?.general.featuredImage.image + "/original"
            : ""
        }
        formattedProductName={formattedProductName}
        campaignTitle={data?.general.title}
        campaignDescription={data?.general.description}
        authorInfos={authorInfos}
        accID={data?.accID}
        productId={id || previewId}
        renderSidebar={() => (
          <>
            <InvoicesDetailsForm
              data={data as InvoiceType}
              isLoading={isLoading}
              drawerOpen={{
                ...(!isDesktop && {
                  ID: MOBILE_PUBLIC_FORM_TICKETS,
                  open: drawerOpen,
                  setOpen: setDrawerOpen,
                  renderCheckout: () => (
                    <PaymentInformationCard
                      destinationAccountMerchantName={authorInfos.name}
                      isPeekMode={isPeekMode}
                      setDrawerOpen={setDrawerOpen}
                    />
                  ),
                }),
              }}
            />
            <CheckoutWrapper
              authorInfos={authorInfos}
              isDesktop={isDesktop}
              isPeekMode={isPeekMode}
              setDrawerOpen={setDrawerOpen}
              formOf="invoice"
            />
          </>
        )}
        isPeekMode={isPeekMode}
        renderFooter={() => <PublicFormFooter isPeekMode={isPeekMode} />}
      />
    </>
  );
};

export default InvoicesPublicForm;
