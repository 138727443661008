import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

type Props = {
  handleNotify: (message: string) => void;
  name: string;
  isEnterprise: boolean;
};

const NotifyMerchantModal = NiceModal.create(
  ({ handleNotify, name, isEnterprise }: Props) => {
    const modal = useModal();

    const handleCancel = () => {
      modal.hide();
    };

    const methods = useForm();
    const { watch, reset } = methods;
    const values = watch();

    const onSubmit = () => {
      handleNotify(values?.message || "");
      handleCancel();
    };

    const actionStyle = {
      padding: "8px 24px",
    };

    useEffect(() => {
      if (modal.visible) {
        reset({ message: "" });
      }
    }, [modal.visible]);

    return (
      <EditMerchantBaseModal
        title={`Notify ${name}`}
        description={`Optionally, attach a note for the members of this ${
          isEnterprise ? "provider" : "merchant"
        } to read`}
        open={modal.visible}
        handleCancel={handleCancel}
        actions={
          <Button
            size="medium"
            background="primary"
            sx={actionStyle}
            form="notify-merchant-form"
            type="submit"
          >
            Notify
          </Button>
        }
        sx={{
          "& .MuiPaper-root": {
            width: "600px !important",
            maxWidth: "600px !important",
          },
          "& .MuiDialogTitle-root + .MuiDialogContent-root": {
            padding: "0 16px 16px 16px !important",
          },
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <FormProvider {...methods}>
          <Stack
            spacing={2}
            component="form"
            id="notify-merchant-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <RHFInput
              name="message"
              fullWidth
              placeholder="Message for merchant members"
              label="Message"
            />
          </Stack>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

export default NotifyMerchantModal;
