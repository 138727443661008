type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const Checks = ({ width = 19, height = 19, stroke = "#13A75A" }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M17.7012 5.45886V14.4589C17.7012 14.7572 17.5826 15.0434 17.3717 15.2544C17.1607 15.4653 16.8745 15.5839 16.5762 15.5839H3.07617C2.7778 15.5839 2.49166 15.4653 2.28068 15.2544C2.0697 15.0434 1.95117 14.7572 1.95117 14.4589V5.45886C1.95117 5.16049 2.0697 4.87435 2.28068 4.66337C2.49166 4.45239 2.7778 4.33386 3.07617 4.33386H16.5762C16.8745 4.33386 17.1607 4.45239 17.3717 4.66337C17.5826 4.87435 17.7012 5.16049 17.7012 5.45886Z"
        fill={stroke}
      />
      <path
        d="M10.787 8.10679L6.84945 12.0443C6.79721 12.0966 6.73517 12.1381 6.66689 12.1664C6.5986 12.1947 6.52541 12.2093 6.45148 12.2093C6.37756 12.2093 6.30437 12.1947 6.23608 12.1664C6.16779 12.1381 6.10576 12.0966 6.05352 12.0443L4.36602 10.3568C4.26047 10.2512 4.20117 10.1081 4.20117 9.95882C4.20117 9.80955 4.26047 9.6664 4.36602 9.56085C4.47156 9.4553 4.61472 9.396 4.76398 9.396C4.91325 9.396 5.05641 9.4553 5.16195 9.56085L6.45148 10.8511L9.99102 7.31085C10.0433 7.25859 10.1053 7.21713 10.1736 7.18885C10.2419 7.16056 10.3151 7.146 10.389 7.146C10.4629 7.146 10.5361 7.16056 10.6044 7.18885C10.6726 7.21713 10.7347 7.25859 10.787 7.31085C10.8392 7.36311 10.8807 7.42515 10.909 7.49344C10.9372 7.56172 10.9518 7.63491 10.9518 7.70882C10.9518 7.78273 10.9372 7.85591 10.909 7.9242C10.8807 7.99248 10.8392 8.05452 10.787 8.10679ZM15.8495 7.31085C15.7972 7.25855 15.7352 7.21706 15.6669 7.18875C15.5986 7.16044 15.5254 7.14587 15.4515 7.14587C15.3776 7.14587 15.3044 7.16044 15.2361 7.18875C15.1678 7.21706 15.1058 7.25855 15.0535 7.31085L11.514 10.8511L10.787 10.1233C10.6814 10.0178 10.5383 9.9585 10.389 9.9585C10.2397 9.9585 10.0966 10.0178 9.99102 10.1233C9.88547 10.2289 9.82617 10.372 9.82617 10.5213C9.82617 10.6706 9.88547 10.8137 9.99102 10.9193L11.116 12.0443C11.1683 12.0966 11.2303 12.1381 11.2986 12.1664C11.3669 12.1947 11.4401 12.2093 11.514 12.2093C11.5879 12.2093 11.6611 12.1947 11.7294 12.1664C11.7977 12.1381 11.8597 12.0966 11.912 12.0443L15.8495 8.10679C15.9018 8.05454 15.9432 7.99251 15.9715 7.92422C15.9999 7.85593 16.0144 7.78274 16.0144 7.70882C16.0144 7.6349 15.9999 7.5617 15.9715 7.49341C15.9432 7.42513 15.9018 7.36309 15.8495 7.31085Z"
        fill={stroke}
      />
    </svg>
  );
};

export default Checks;
