import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";

const EditorContainer = styled(Box)(() => ({
  padding: "8px",
  overflow: "hidden",
  borderRadius: "8px",
  height: "100%",
  position: "relative",
  backgroundColor: palette.black[300],
}));

const ErrorMessage = ({ error }: { error: string }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: "8px",
        left: "8px",
        right: "8px",
        borderRadius: "6px",
        backgroundColor: palette.error.light,
        padding: "8px",
        zIndex: 10,
      }}
    >
      <Text
        color={palette.filled.red}
        variant="caption"
        lineHeight="14.4px"
        fontFamily="Oxygen Mono"
      >
        {error}
      </Text>
    </Box>
  );
};

export { EditorContainer, ErrorMessage };
