import React from "react";
import { Box } from "@mui/material";
import TabBarItem, { TabListItem } from "./TabBarItem";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type TabBarProps<T> = {
  selectedTab: T;
  setTab: React.Dispatch<React.SetStateAction<T>>;
  tabList: TabListItem<T>[];
  disabled?: boolean;
};

const TabBar = <T extends string>({
  selectedTab,
  setTab,
  tabList,
  disabled,
}: TabBarProps<T>) => {
  const { isMobileView } = useCustomTheme();

  const handleClickItem = (tab: TabListItem<T>) => {
    if (tab.disabled) return;
    setTab(tab.name);
  };

  return (
    <Box sx={{ ...containerStyle, overflow: isMobileView ? "auto" : "hidden" }}>
      {tabList.map((tab: TabListItem<T>, index: number) => (
        <TabBarItem
          disabled={disabled || tab.disabled}
          key={index}
          active={tab.name === selectedTab}
          onClick={() => handleClickItem(tab)}
          {...tab}
        />
      ))}
    </Box>
  );
};

const containerStyle = {
  display: "flex",
  border: "4px solid #ECECE9",
  borderRadius: "12px",
  marginInline: "16px",
};

export default TabBar;
