import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { DropdownItem } from "@common/Select";
import { Text } from "@common/Text";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Menu, MenuProps } from "@mui/material";

type Props = {
  anchorEl: any;
  actions: any[];
  onClose: () => void;
};

export const ActionsMenu = ({
  actions,
  anchorEl,
  onClose,
  ...props
}: Props & Omit<MenuProps, "open">) => {
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      data-testid={`actions-menu`}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: 159,
        },
        ...(props.sx && props.sx),
      }}
      {...props}
    >
      {actions?.map((action) => {
        return (
          <HiddenComponent hidden={action.hidden} key={action.title}>
            <WithTooltipWrapper
              hasTooltip={!!action?.tooltipProps}
              tooltipProps={action?.tooltipProps}
            >
              <DropdownItem
                warn={action.warn}
                disabled={action.disabled}
                onClick={() => {
                  action?.onClick();
                  onClose();
                }}
                data-testid={`${action.title}-action-item`}
              >
                <Text sx={action?.sx}>{action.title}</Text>
              </DropdownItem>
            </WithTooltipWrapper>
          </HiddenComponent>
        );
      })}
    </Menu>
  );
};
