import { Box, styled } from "@mui/material";
import { NotificationType } from "../types";
import { palette } from "@palette";
import { AlertDot } from "@components/ManageMoney/TransactionTable/TransactionTable.atoms";
import { HiddenComponent } from "@containers/HiddenComponent";
import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";
import { ConversationsChallengesIcon, EnvelopeSimpleIcon } from "@assets/icons";

const svgElements: Record<NotificationType, any> = {
  underwriting: WarningIcon,
  conversation: ConversationsChallengesIcon,
  generic: EnvelopeSimpleIcon,
};

const colors = {
  mandatory: {
    icon: palette.filled.orange,
    background: palette.tag.warning.bg,
    border: palette.filled.orange,
  },
  generic: {
    icon: palette.black[100],
    background: palette.liftedWhite[100],
    border: palette.liftedWhite[200],
  },
};

const NotificationImage = ({
  variant,
  isUnread,
  isMandatory,
}: {
  variant: NotificationType;
  isUnread: boolean;
  isMandatory: boolean;
}) => {
  const Icon = svgElements[isMandatory ? "underwriting" : variant];
  const { icon, background, border } =
    colors[isMandatory ? "mandatory" : "generic"];
  return (
    <CustomImage backgroundColor={background} borderColor={border}>
      <Icon width={20} height={20} stroke={icon} />
      <HiddenComponent hidden={!isUnread}>
        <CustomAlertDot isMandatory={isMandatory} />
      </HiddenComponent>
    </CustomImage>
  );
};

export default NotificationImage;

const CustomImage = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "backgroundColor" && prop !== "borderColor",
})<{ backgroundColor: string; borderColor: string }>(
  ({ backgroundColor, borderColor }) => ({
    width: "36px",
    minWidth: "36px",
    height: "36px",
    borderRadius: "5px",
    position: "relative",
    border: `1px solid ${borderColor}`,
    backgroundColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);

const CustomAlertDot = styled(AlertDot, {
  shouldForwardProp: (prop) => prop !== "isMandatory",
})<{ isMandatory: boolean }>(({ isMandatory }) => ({
  width: "12px",
  height: "12px",
  position: "absolute",
  left: 0,
  top: 0,
  transform: "translate(-6px, -6px)",
  zIndex: 2,
  ...(isMandatory && {
    background: palette.accent[4],
  }),
}));
