import { Box, styled, Stack } from "@mui/material";
import { Stat } from "@common/NewStats";
import { parseAmount } from "@utils/index";
import { useGetCurrentMerchantId } from "@hooks/common";
import useFindMerchantStatsById from "@hooks/acquirer-api/merchants/stats/useFindMerchantStatsById";
import { DrawerAccountSelect } from "./SelectAccountType/DrawerAccountSelect";
import { palette } from "@palette";
import { memo, useMemo, useRef } from "react";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";

type Props = {
  drawerOpen: boolean;
};

const DrawerListHeader = ({ drawerOpen }: Props) => {
  const dispatch = useAppDispatch();

  const refValue = useRef(0);
  const { merchantId } = useGetCurrentMerchantId();
  const { data: stats } = useFindMerchantStatsById(merchantId, {
    enabled: !!merchantId,
    retry: 2,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError(err: any) {
      if (err.not_authorized) {
        dispatch(
          updatePermissions({
            merchant_stats: true,
          }),
        );
      }
    },
  });

  const value = useMemo(() => {
    let val = refValue.current;
    if (stats) {
      refValue.current = stats.availableBalance / 100;
      val = stats.availableBalance / 100;
    }
    return parseAmount(val);
  }, [stats]);

  return (
    <Stack
      sx={{
        px: drawerOpen ? "16px" : 0,
        height: "150px",
      }}
    >
      <DrawerAccountSelect drawerOpen={drawerOpen} />
      <BalanceContainer>
        {drawerOpen && (
          <Stat
            isAmount
            title="Available Balance"
            value={value}
            valueProps={{
              color: palette.black[100],
              sup: true,
              fontSize: 28,
            }}
          />
        )}
      </BalanceContainer>
    </Stack>
  );
};

const BalanceContainer = styled(Box)(({ theme }) => ({
  margin: "24px 0 16px 0",
  height: "65px",
  sup: {
    ml: 0.5,
    fontSize: 14,
    lineHeight: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    margin: "8px 0 16px 0",
  },
}));

export default memo(DrawerListHeader);
