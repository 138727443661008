import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { NameInput } from "@common/BusinessProfileInputs";
import { RHFInput } from "@common/Input";
import { Button } from "@common/Button";
import { Box } from "@mui/material";
import { KotoBackArrow } from "@assets/icons";
import { palette } from "@palette";

interface Props {
  disabled: boolean;
  handleBack: () => void;
  isFirstPage: boolean;
}

function PersonalDetails({ disabled, handleBack, isFirstPage }: Props) {
  return (
    <>
      <Stack spacing="20px" alignItems="flex-end">
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={6}>
            <NameInput
              name="firstName"
              label="First name"
              placeholder="First name"
              autoComplete
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NameInput
              name="lastName"
              label="Last name"
              placeholder="Last name"
              autoComplete
            />
          </Grid>
          <Grid item xs={12}>
            <RHFInput
              name="email"
              label="Email address"
              placeholder="Email address"
              fullWidth
            />
          </Grid>
        </Grid>
      </Stack>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!isFirstPage ? (
          <Button
            background="tertiary"
            size="small"
            startIcon={<KotoBackArrow stroke={palette.neutral[80]} />}
            onClick={handleBack}
          >
            Previous page
          </Button>
        ) : (
          <div></div>
        )}
        <Button
          background="primary"
          size="medium"
          type="submit"
          disabled={disabled}
        >
          Next Step
        </Button>
      </Box>
    </>
  );
}

export default PersonalDetails;
