interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const ErrorIcon = ({ 
  width = 32,
  height = 32,
  fill = "#D92D20",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle 
        cx="16"
        cy="16.2393"
        r="12"
        stroke={fill}
        strokeWidth="2.3"
      />
      <path 
        d="M24 24.2393L8 8.23926"
        stroke={fill}
        strokeWidth="2.3"
      />
    </svg>
  );
};

export default ErrorIcon;
