export enum personalInfoEnum {
  PERSONAL_INFORMATION = "Personal Information",
  UPLOAD_YOUR_ID = "Upload your ID",
  TAKE_A_SELFIE = "Take a selfie",
}

export type PersonalInfoTabDefaultData = {
  firstName: string;
  lastName: string;
  DOB: Date | null;
  phoneNumber: string;
  isOwner: boolean;
  hasManagerialAuthority?: boolean;
  country: string;
  city: string;
  street: string;
  state: string;
  zip: string;
  tinType?: "ssn" | "ein";
  ownership: string;
  taxID?: string;
  ssn?: string;
};
