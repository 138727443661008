import { CaretDown } from "@assets/icons";
import { RHFInput } from "@common/Input";
import Selector from "@common/TableFilters/Selector";
import { Text, TruncateText } from "@common/Text";
import { InputAdornment } from "@mui/material";
import { palette } from "@palette";
import { getFullTimeZonesMomentAll } from "@utils/timezones";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

type TTimeZoneSelector = {
  width?: number;
  fullWidth?: boolean;
};
const TimeZoneSelector = ({ width, fullWidth = false }: TTimeZoneSelector) => {
  const timezones = useMemo(() => getFullTimeZonesMomentAll(), []);
  const { setValue, watch } = useFormContext();
  const value = watch("timezone");
  const [searchValue, setSearchValue] = useState<string>("");

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const timeZoneOptions = useMemo(
    () =>
      timezones.map((item, key) => ({
        label: `GMT${item.gmt} ${item.timezone}`,
        id: key,
        value: item.timezone.toLowerCase(),
      })),
    [],
  );

  const filteredOptions = useMemo(
    () =>
      timeZoneOptions.filter((item) =>
        item?.label?.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue],
  );

  const usedValue = useMemo(
    () =>
      timeZoneOptions.find(
        (timezone) => timezone.value.toLowerCase() === value.toLowerCase(),
      ),
    [timeZoneOptions, value],
  );
  const onChange = (value: any) => {
    setValue("timezone", value.value, {
      shouldDirty: true,
    });
  };
  return (
    <Selector
      options={filteredOptions}
      value={searchValue}
      handleChange={(val) => onChange(val)}
      handleSearch={setSearchValue}
      width={width}
      isOpen={setIsOpen}
      multiSelect
      closeOnClick
      showApplyAction={false}
      fullWidth={fullWidth}
      renderCustomElement={(option) => {
        const {
          item: { label },
        } = option;
        return (
          <TruncateText flex={1} height="40px" lineClamp={1} padding="4px 10px">
            {label}
          </TruncateText>
        );
      }}
      renderCustomParent={({ onClick }) => (
        <RHFInput
          value={usedValue?.label}
          onClick={onClick}
          placeholder="Select a timezone"
          name="timezone"
          label="Timezone"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment sx={{ cursor: "pointer" }} position="end">
                <CaretDown rotate={isOpen ? 180 : 0} />
              </InputAdornment>
            ),
          }}
        />
      )}
      searchBarProps={{
        placeholder: "Search",
        customDeleteIcon: <CustomDeleteIcon />,
      }}
    />
  );
};

export default TimeZoneSelector;

const CustomDeleteIcon = () => {
  return (
    <Text
      sx={{
        color: palette.accent[3],
        marginRight: "15px",
        fontFamily: "Give Whyte",
        fontSize: "14px",
        fontWeight: 350,
        lineHeight: "16.8px",
      }}
    >
      Clear
    </Text>
  );
};
