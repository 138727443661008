import {
  PercentageStepEmptyIcon,
  PercentageStepFilledIcon,
} from "@assets/icons";
import { Text } from "@common/Text";
import { Box, Rating, Stack, SvgIcon, styled } from "@mui/material";
import { palette } from "@palette";
import { useMemo } from "react";

const MAX = 10;

type Props = {
  percentageValue: number;
  isApproved?: boolean;
};

export const PercentageSep = ({
  filled,
  value,
}: {
  filled?: boolean;
  value?: number;
}) => {
  return (
    <SvgIcon fontSize="inherit" width={45} height={9} viewBox="0 0 45 9">
      {filled ? (
        <PercentageStepFilledIcon
          data-testid="Filed-Icon"
          fill={value && value >= 80 ? palette.filled.success : "#8F8F8F"}
        />
      ) : (
        <PercentageStepEmptyIcon
          data-testid="Empty-Icon"
          fill={value && value >= 80 ? "#85CFA8" : "#C1C1C1"}
        />
      )}
    </SvgIcon>
  );
};

const DottedPercentageBar = ({ percentageValue, isApproved }: Props) => {
  const value = useMemo(() => {
    return percentageValue ? (percentageValue * MAX) / 100 : 0;
  }, [percentageValue]);

  return (
    <Stack direction="row" alignItems="baseline" gap={1.5}>
      <Box height={9}>
        <StyledRating
          name="customized-color"
          value={value}
          precision={0.1}
          max={MAX}
          emptyIcon={
            <PercentageSep value={isApproved ? 80 : percentageValue} />
          }
          icon={
            <PercentageSep filled value={isApproved ? 80 : percentageValue} />
          }
          data-testid="dotted-percentage-bar"
          readOnly
        />
      </Box>
      <Text
        fontSize="18px"
        color={
          percentageValue >= 80 || isApproved
            ? palette.filled.success
            : "#8F8F8F"
        }
      >
        {percentageValue ? Math.round(percentageValue) : 0}%
      </Text>
    </Stack>
  );
};

const StyledRating = styled(Rating)({
  gap: "4px",
  "& .MuiSvgIcon-root": {
    width: "45px",
    height: "9px",
  },
});

export default DottedPercentageBar;
