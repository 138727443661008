import { SvgIconProps } from "@mui/material";

const CheckedFilledCircleIcon = ({
  height = 25,
  width = 25,
  fill = "#13A75A",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      data-testid="closed-challenge"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8164 2.25C10.888 2.25 9.00298 2.82183 7.3996 3.89317C5.79622 4.96451 4.54654 6.48726 3.80859 8.26884C3.07063 10.0504 2.87755 12.0108 3.25375 13.9021C3.62996 15.7934 4.55856 17.5307 5.92212 18.8943C7.28568 20.2579 9.02297 21.1865 10.9143 21.5627C12.8056 21.9389 14.766 21.7458 16.5476 21.0078C18.3292 20.2699 19.8519 19.0202 20.9232 17.4168C21.9946 15.8134 22.5664 13.9284 22.5664 12C22.5637 9.41498 21.5356 6.93661 19.7077 5.10872C17.8798 3.28084 15.4014 2.25273 12.8164 2.25ZM17.097 10.2806L11.847 15.5306C11.7774 15.6004 11.6947 15.6557 11.6036 15.6934C11.5126 15.7312 11.415 15.7506 11.3164 15.7506C11.2178 15.7506 11.1203 15.7312 11.0292 15.6934C10.9382 15.6557 10.8554 15.6004 10.7858 15.5306L8.53579 13.2806C8.39505 13.1399 8.31599 12.949 8.31599 12.75C8.31599 12.551 8.39505 12.3601 8.53579 12.2194C8.67652 12.0786 8.86739 11.9996 9.06641 11.9996C9.26543 11.9996 9.45631 12.0786 9.59704 12.2194L11.3164 13.9397L16.0358 9.21937C16.1055 9.14969 16.1882 9.09442 16.2792 9.0567C16.3703 9.01899 16.4679 8.99958 16.5664 8.99958C16.665 8.99958 16.7625 9.01899 16.8536 9.0567C16.9446 9.09442 17.0274 9.14969 17.097 9.21937C17.1667 9.28906 17.222 9.37178 17.2597 9.46283C17.2974 9.55387 17.3168 9.65145 17.3168 9.75C17.3168 9.84855 17.2974 9.94613 17.2597 10.0372C17.222 10.1282 17.1667 10.2109 17.097 10.2806Z"
        fill="#13A75A"
      />
    </svg>
  );
};

export default CheckedFilledCircleIcon;
