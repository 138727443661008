import { Text } from "@common/Text";
import { Menu, MenuItem, styled } from "@mui/material";
import { palette } from "@palette";
import { PEPStatusType, UpdtateStatusOptions } from "../types";
import { PEP_CONFIRM_MATCH_MODAL } from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";

const defaultStatusOptions: UpdtateStatusOptions[] = [
  "clear",
  "confirmed_match",
];

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  onUpdate: (newStatus: UpdtateStatusOptions) => void;
  selectedOwnerName?: string;
  manualPepStatus?: PEPStatusType;
}

function CustomMenu({
  anchorEl,
  handleClose,
  onUpdate,
  selectedOwnerName,
  manualPepStatus,
}: Props) {
  const handleOpenModal = () => {
    NiceModal.show(PEP_CONFIRM_MATCH_MODAL, {
      name: selectedOwnerName,
      onAccept: () => onUpdate("confirmed_match"),
    });
  };

  return (
    <Container
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "custom-button",
      }}
    >
      {defaultStatusOptions.map((state) => {
        const statusOBJ = generateStyleText(state);
        const hidden =
          (manualPepStatus === "confirmed_match" &&
            state === "confirmed_match") ||
          (manualPepStatus === "clear" && state === "clear");

        return (
          <MenuItem
            sx={{
              padding: "10px 12px",
              margin: 0,
              ...(hidden && {
                display: "none",
              }),
            }}
            key={state}
            onClick={() =>
              state === "confirmed_match" ? handleOpenModal() : onUpdate(state)
            }
          >
            <Text
              sx={{
                cursor: "pointer",
              }}
              color={statusOBJ.color}
              textAlign="left"
              fontWeight="book"
              fontSize="14px"
            >
              {statusOBJ.modal_Text}
            </Text>
          </MenuItem>
        );
      })}
    </Container>
  );
}

export default CustomMenu;

export const generateStyleText = (status: PEPStatusType) => {
  switch (status) {
    case "possible_match":
      return {
        text: "Possible Match",
        color: palette.filled.orange,
        bgColor: palette.tag.warning.bg,
        modal_Text: "Change to possible Match",
      };
    case "clear":
      return {
        text: "Clear Match",
        color: palette.filled.success,
        bgColor: palette.tag.success.bg,
        modal_Text: "Change to Clear Match",
      };
    case "confirmed_match":
      return {
        text: "Confirm Match",
        color: palette.filled.red,
        bgColor: palette.tag.error.bg,
        modal_Text: "Change to Confirmed Match",
      };
    case "pending":
    default:
      return {
        text: "Pending",
        color: palette.neutral[80],
        bgColor: palette.neutral[10],
        modal_Text: "Change to Pending",
      };
  }
};

const Container = styled(Menu)(() => ({
  ".MuiPaper-root": {
    backgroundColor: palette.background.bgWhite,
    boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.15)",
  },
  ".MuiPaper-rounded": {
    borderRadius: "8px",
  },
}));
