// mui
// import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FilterWithDate, ClearFiltersButton } from "@common/Filter";
// redux
import { useAppSelector } from "@redux/hooks";

// components

import {
  addDateFilter,
  clearFilters,
  disableDateFilter,
  selectFilters,
  selectQueryFilters,
} from "@redux/slices/fundraisers";

export const listCreatedFilter = [
  { value: "in the last", label: "in the last" },
  { value: "is between", label: "between" },
  { value: "is on or before", label: "on or before" },
  { value: "is on or after", label: "on or after" },
];

const PaymentFormsListFilters = () => {
  const filters = useAppSelector(selectFilters);

  return (
    <Stack py={1} direction="row" flexWrap="wrap">
      <FilterWithDate
        title="created"
        options={listCreatedFilter}
        filters={filters}
        apply={addDateFilter}
        disable={disableDateFilter}
      />

      <ClearFiltersButton clear={clearFilters} selector={selectQueryFilters} />
    </Stack>
  );
};

export default PaymentFormsListFilters;
