import { Switch_V2 } from "@common/Switch";
import { Text } from "@common/Text";
import { Skeleton, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { setConversationsFilter } from "@redux/slices/conversations";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ChangeEvent } from "react";

const ConversationsModalSubHeader = ({
  filterString,
  isDataEmpty,
  isFetching,
  queryString,
}: {
  isFetching: boolean;
  isDataEmpty: boolean;
  filterString: string;
  queryString: string;
}) => {
  const dispatch = useAppDispatch();
  const { globalName, userAccID } = useAppSelector(selectUser);
  const { isMobileView } = useCustomTheme();
  const builder = `@[__${globalName.firstName} ${globalName.lastName}__](user:__${userAccID}__)`;

  const handleCheck = (e: ChangeEvent<HTMLInputElement>, c: boolean) => {
    dispatch(setConversationsFilter(c ? `${builder}` : ""));
  };

  if (queryString && !filterString && isDataEmpty && !isFetching) return <></>;

  return (
    <Stack
      justifyContent={isMobileView ? "flex-end" : "flex-start"}
      direction="row"
      alignItems="center"
      mb={isMobileView ? "18px" : 0}
      gap={1}
    >
      {isFetching && queryString ? (
        <Skeleton variant="rectangular" width={100} height={12} />
      ) : (
        <>
          <Text fontSize="14px" color="#575353">
            @Mentions
          </Text>
          <Switch_V2 size="small" onChange={handleCheck} />
        </>
      )}
    </Stack>
  );
};

export default ConversationsModalSubHeader;
