import { Skeleton as MuiSkeleton, SkeletonProps, Stack } from "@mui/material";

type Props = {
  borderRadius?: number | string;
} & SkeletonProps;

const Skeleton = ({ sx, borderRadius, ...props }: Props) => {
  return (
    <MuiSkeleton
      sx={{
        borderRadius: borderRadius || "40px",
        ...(sx && sx),
      }}
      variant="rounded"
      {...props}
    />
  );
};

type DoubledSkeletonProps = {
  spacing?: number;
  direction?: "row" | "column";
  frequency?: number;
} & Props;

export const DoubledSkeleton = ({
  sx,
  spacing,
  direction,
  borderRadius,
  frequency,
  ...props
}: DoubledSkeletonProps) => (
  <Stack spacing={spacing || 1} direction={direction || "column"}>
    {Array(frequency || 2)
      .fill(0)
      .map((_, index) => (
        <MuiSkeleton
          key={index}
          sx={{
            borderRadius: borderRadius || "40px",
            ...(sx && sx),
          }}
          variant="rounded"
          {...props}
        />
      ))}
  </Stack>
);

export default Skeleton;
