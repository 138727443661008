import { CertificateIcon } from "@assets/icons";
import { Button } from "@common/Button";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box, Divider } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { BankDisclosure } from "./components/BankDisclosure";
import { MerchantAgreementForm } from "./components/MerchantAgreement/Form";
import { FormProvider, useWatch } from "react-hook-form";
import {
  TMerchantAgreementPayload,
  useAddMerchantAgreement,
} from "../hooks/useAddMerchantAgreement";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { Disclaimer } from "./components/MerchantAgreement/Disclaimer";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { TOSWrapper } from "@components/ProfilePage/EnterpriseAgreement/SignTOS";
import { TermsOfService } from "@pages/TermsOfService";
import { useAsyncAction } from "@hooks/customReactCore";
import { useScrollCheck } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useScrollCheck";
import { useEffect } from "react";
import MerchantAgreementSignSection from "@components/Merchants/MerchantPreview/MerchantAgreement/MerchantAgreementSignSection";

interface ModalProps {
  onClose: (data: TMerchantAgreementPayload) => void;
  data?: TMerchantAgreementPayload & {
    isEnterPrise?: boolean;
  };
  merchantId?: any;
  merchantData?: Partial<IParsedData>;
}

const CreateMerchantAgreementModal = NiceModal.create(
  ({ onClose, data, merchantId, merchantData }: ModalProps) => {
    const modal = useModal();
    const open = modal.visible;
    const { isMobileView } = useCustomTheme();
    const [isLoading, triggerAction] = useAsyncAction();
    const isEnterprise = data?.isEnterPrise || false;
    const {
      methods,
      isSubmitButtonDisabled,
      handleSubmit,
      getDefaultValuesFromData,
    } = useAddMerchantAgreement(onClose, data, merchantId);

    const { CheckboxWithTooltip, handleScroll } = useScrollCheck(
      Boolean(data?.msaHadAgreed),
    );

    const { data: APIData } = useGetMerchantById();
    const { globalName } = useAppSelector(selectUser);

    const handleCancel = () => {
      modal.hide();
    };

    const addSignature = (file: File) =>
      methods?.setValue("signatureFile", file);

    const [formSignatureFile, formMsaHadAgreed] = useWatch({
      control: methods.control,
      name: ["signatureFile", "msaHadAgreed"],
    });

    const signatureFile = formSignatureFile || data?.signatureFile;

    const handleSubmitCall = () => triggerAction(handleSubmit);

    const isSaveDisabled =
      isSubmitButtonDisabled || (isEnterprise && !formMsaHadAgreed);

    useEffect(() => {
      if (open && data) methods.reset(getDefaultValuesFromData(data));
    }, [open]);

    return (
      <FormProvider {...methods}>
        <EditMerchantBaseModal
          title={isEnterprise ? "Provider Agreement" : "Merchant Agreement"}
          description={`Agreement to the ${
            isEnterprise ? "Provider" : "Merchant"
          } Services Terms is required`}
          icon={<CertificateIcon />}
          open={open}
          handleCancel={handleCancel}
          actions={
            <Box margin={isMobileView ? "0 auto" : "initial"}>
              <Button
                disabled={isSaveDisabled || isLoading}
                onClick={handleSubmitCall}
                size="medium"
                background="primary"
                type="submit"
                data-testid="create-merchant-save"
                form="create-merchant-agreement"
                sx={{
                  ...(isMobileView && {
                    width: "100%",
                  }),
                }}
              >
                {isMobileView ? "Save changes" : "Save"}
              </Button>
            </Box>
          }
        >
          <Disclaimer
            isEnterPrise={isEnterprise}
            status={signatureFile ? "signed" : "not_signed"}
            {...(signatureFile && {
              lastVersion: APIData?.msaVersion,
              version: APIData?.msaVersion,
              accountHolderNameFullName: `${globalName?.firstName} ${globalName?.lastName}`,
            })}
          />
          {!isEnterprise ? (
            <>
              <BankDisclosure />
              <Divider
                sx={{ my: 1, marginBottom: "16px", marginTop: "16px" }}
              />
              <Box sx={{ "& hr": { display: "none" } }}>
                <MerchantAgreementForm data={merchantData} />
              </Box>
            </>
          ) : (
            <>
              <TOSWrapper
                maxHeight="374px"
                onScroll={handleScroll}
                data-testid="ToS-modal"
              >
                <TermsOfService
                  merchantAgreementVersion={{
                    version: APIData?.msaVersion || "",
                    lastUpdated: "June 10,2023",
                  }}
                  isEnterPrise={isEnterprise}
                />
              </TOSWrapper>
            </>
          )}

          <FadeUpWrapper delay={150}>
            {isEnterprise && (
              <Box py={2}>
                <CheckboxWithTooltip name="msaHadAgreed" />
              </Box>
            )}
            <MerchantAgreementSignSection
              addSignature={addSignature}
              status={signatureFile ? "signed" : "not_signed"}
              file={signatureFile}
              merchantId={merchantId}
              isEnterprise={isEnterprise}
              parentCheckBoxFieldKey={
                isEnterprise ? "msaHadAgreed" : "msaPCICompliant"
              }
            />
          </FadeUpWrapper>
        </EditMerchantBaseModal>
      </FormProvider>
    );
  },
);

export default CreateMerchantAgreementModal;
