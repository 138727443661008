import React, { JSXElementConstructor, memo, ReactElement } from "react";
import { FeatureFlagContext } from "./FeatureFlagProvider";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

interface FlaggedWrapperProps {
  name: string;
  ActiveComponent:
    | React.FC
    | ReactElement<any, string | JSXElementConstructor<any>>;
  FallbackComponent?:
    | React.FC
    | ReactElement<any, string | JSXElementConstructor<any>>;
}

const FlaggedWrapper: React.FC<FlaggedWrapperProps> = ({
  name,
  ActiveComponent,
  FallbackComponent,
}) => {
  return (
    <FeatureFlagContext.Consumer>
      {({ flags }) => {
        const isEnabled = flags.get(name) === true;

        if (isEnabled) {
          if (React.isValidElement(ActiveComponent)) {
            return ActiveComponent;
          } else {
            const Component = ActiveComponent as React.FC;
            return <Component />;
          }
        }
        if (FallbackComponent) {
          if (React.isValidElement(FallbackComponent)) {
            return FallbackComponent;
          } else {
            const Fallback = FallbackComponent as React.FC;
            return <Fallback />;
          }
        }
        return null;
      }}
    </FeatureFlagContext.Consumer>
  );
};

export default memo(FlaggedWrapper);
