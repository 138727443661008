import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appReducer from "./slices/app";
import verificationReducer from "./slices/auth/verification";
import transactionsReducer from "./slices/transactions";
import customersReducer from "./slices/customers";
import fundraisersReducer from "./slices/fundraisers";
import sweepstakesReducer from "./slices/sweepstakes";
import merchantsReducer from "./slices/enterprise/merchants";
import enterprisesSlice from "./slices/acquirer/enterprises";
import acquirerSettingsReducer from "./slices/acquirer/acquirerSettings";
import cartReducer from "./slices/cart";
import AuthReducer from "./slices/auth/auth";
import enterpriseSettingsReducer from "./slices/enterprise/enterpriseSettings";
import accountsReducer from "./slices/auth/accounts";
import searchReducer from "./slices/search";
import checkoutReducer from "./slices/checkout";
import NiceModal from "@ebay/nice-modal-react";
import productReducer from "./slices/products";
import analyticsReducer from "./slices/analytics";
import transactionRiskProfile from "./slices/acquirer/transactionRiskProfile";
import conversationsReducer from "./slices/conversations";
import accessControlReducer from "./slices/accessControl/accessControl";
import merchantPreviewReducer from "./slices/merchantPreview";
import unreadConversationSignReducer from "./slices/unreadConversationSign";
import tableFiltersReducer from "./slices/tableFilters";
import merchantFiltersReducer from "./slices/merchantFilters";
import changelogReducer from "./slices/changelog";
import filterReducer from "./slices/dynamicFilterSlice";
import uploadProgressSlice from "./slices/uploadProgressSlice";
import sortReducer from "./slices/sort";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const combinedReducers = combineReducers({
  app: appReducer,
  verification: verificationReducer,
  transactions: transactionsReducer,
  customers: customersReducer,
  fundraisers: fundraisersReducer,
  sweepstakes: sweepstakesReducer,
  merchants: merchantsReducer,
  enterprises: enterprisesSlice,
  auth: AuthReducer,
  acquirerSettings: acquirerSettingsReducer,
  enterpriseSettings: enterpriseSettingsReducer,
  accounts: accountsReducer,
  cart: cartReducer,
  search: searchReducer,
  sort: sortReducer,
  filter: filterReducer,
  checkout: checkoutReducer,
  product: productReducer,
  modals: NiceModal.reducer as any,
  analytics: analyticsReducer,
  transactionRiskProfile: transactionRiskProfile,
  conversations: conversationsReducer,
  accessControl: accessControlReducer,
  merchantPreview: merchantPreviewReducer,
  unreadConversationSign: unreadConversationSignReducer,
  tableFilters: tableFiltersReducer,
  merchantFilters: merchantFiltersReducer,
  changelog: changelogReducer,
  uploadProgress: uploadProgressSlice,
});

export const rootReducer: typeof combinedReducers = (state, action) => {
  if (action.type === "LOGOUT") return combinedReducers(undefined, action);
  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: ENVIRONMENT !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducers>;
