import Papa from "papaparse";
import { MERCHANT_NAME_REGEX } from "@common/BusinessProfileInputs/NameInput";
import * as Yup from "yup";
import { TImportedRow } from "../types";
import { COLUMN_NAMES } from "../constants";

const emailValidator = Yup.string().email();

export const parseCsv = (
  file: File,
  onError: VoidFunction,
  onStep: (el: TImportedRow) => void,
) =>
  Papa.parse(file, {
    skipEmptyLines: true,
    header: true,
    dynamicTyping: true,
    transform: (value, field) => {
      if (field === COLUMN_NAMES[0]) {
        return validateWithExceptions(value, validators[COLUMN_NAMES[0]]);
      } else if (field === COLUMN_NAMES[1]) {
        return validateWithExceptions(value, validators[COLUMN_NAMES[1]]);
      } else {
        return value;
      }
    },
    error: (error) => {
      if (error) onError();
    },
    step: ({ data, errors }) => {
      const merchantName = (data as any)?.[COLUMN_NAMES[1]] || "";
      const pahEmail = (data as any)?.[COLUMN_NAMES[0]] || "";

      if (errors.length === 0 && (merchantName || pahEmail)) {
        onStep({ merchantName, pahEmail });
      }
    },
  });

type Validator = (value: string) => void;
type FieldValidators = Record<(typeof COLUMN_NAMES)[number], Validator>;

const validators: FieldValidators = {
  [COLUMN_NAMES[0]]: (value) => {
    const val = value.trim().toLowerCase();
    emailValidator.validateSync(val);
    return val;
  },
  [COLUMN_NAMES[1]]: (value) => {
    return value.trim().replace(MERCHANT_NAME_REGEX, "").replace(/\s+/g, " ");
  },
};

const validateWithExceptions = (value: string, validator: Validator) => {
  try {
    return validator(value);
  } catch (err) {
    return "";
  }
};
