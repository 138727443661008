import * as Yup from "yup";

interface SourceObject {
  [key: string]: boolean | SourceObject;
}
const extractValues = (
  source: SourceObject,
  target: SourceObject,
): SourceObject => {
  const result: SourceObject = {};

  const traverse = (
    source: SourceObject,
    target: SourceObject,
    currentPath: string,
  ) => {
    for (const key in source) {
      if (Object.hasOwn(source, key) && source[key] === true) {
        const targetValue = getObjectValueByKey(target, currentPath + key);
        if (targetValue !== undefined) {
          result[key] = targetValue;
        }
      } else if (typeof source[key] === "object") {
        traverse(source[key] as SourceObject, target, currentPath + key + ".");
      }
    }
  };

  traverse(source, target, "");

  return result;
};

const getObjectValueByKey = (obj: SourceObject, key: string): any => {
  const keys = key.split(".");
  let value = obj;

  for (const k of keys) {
    if (
      typeof value === "object" &&
      value !== null &&
      Object.hasOwn(value, k)
    ) {
      value = value[k] as any;
    } else {
      return undefined;
    }
  }

  return value;
};

const countNullishValues = (obj: any): number => {
  let count = 0;

  const countNullishRecursive = (data: any) => {
    if (!!data === false) {
      count++;
    } else if (typeof data === "object") {
      for (const key in data) {
        if (Object.hasOwn(data, key)) {
          countNullishRecursive(data[key]);
        }
      }
    }
  };

  countNullishRecursive(obj);
  return count;
};

export const useCalculatePercentage = ({ isEdit }: { isEdit: boolean }) => {
  const calculatePercentage = ({
    action,
    schema,
    dirtyFields,
    values,
  }: {
    action?: (value: React.SetStateAction<any>) => void;
    schema: Yup.ObjectSchema<any>;
    dirtyFields: Record<string, any>;
    defaultValues: any;
    values: any;
  }) => {
    const testableFields = extractTestableFields(schema.fields);

    if (isEdit) {
      const extractedValues = extractValues(dirtyFields, values);
      const countEdited = countNullishValues(extractedValues);

      const percentage = Math.floor(
        ((testableFields.length - countEdited) / testableFields.length) * 100,
      );

      action && action(percentage);
      return percentage;
    }

    const areTestableFieldsDirty = testableFields.map((tf) => {
      const fieldPath = tf[0];
      const fieldPathParts = fieldPath.split(".");
      let currentDirtyFields: any = dirtyFields;

      for (const part of fieldPathParts) {
        if (currentDirtyFields[part]) {
          currentDirtyFields = currentDirtyFields[part];
        } else {
          currentDirtyFields = null;
          break;
        }
      }

      return isEdit ? !!values[fieldPath] : !!currentDirtyFields;
    });

    const numberOfFieldsToComplete = areTestableFieldsDirty.filter(
      (tf) => !tf,
    ).length;

    const percentage = Math.floor(
      ((testableFields.length - numberOfFieldsToComplete) * 100) /
        testableFields.length,
    );

    action && action(percentage);
    return percentage;
  };

  async function calculatePercentageNested(
    schema: Yup.ObjectSchema<any>,
    values: object,
    nestKey?: string,
    ignoreNoRequiredField?: boolean,
  ) {
    try {
      await schema.validate(values, { abortEarly: false });
      return 100;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const invalidFields: string[] = [];
        err.inner.forEach((error) => {
          if (
            error.path &&
            (!nestKey || error.path.startsWith(nestKey)) &&
            invalidFields.findIndex((fieldName) => fieldName === error.path) ===
              -1
          ) {
            invalidFields.push(error.path);
          }
        });
        const numInvalidFields = invalidFields.length;
        const numFields = countFields(schema, nestKey, ignoreNoRequiredField);
        const numValidFields = numFields - numInvalidFields;
        const percentComplete = (numValidFields / numFields) * 100;

        return percentComplete;
      } else {
        throw err;
      }
    }
  }

  return {
    calculatePercentage,
    calculatePercentageNested,
  };
};

// Helper function to extract nested testable fields
const extractTestableFields = (
  fields: any,
  path: string[] = [],
): [string, any][] => {
  return Object.entries(fields).flatMap(([key, value]) => {
    const _fields =
      (value as any).conditions.length > 0
        ? (value as any).conditions[0].fn()
        : {};

    if (_fields?.fields && Object.keys(_fields.fields).length > 0) {
      return Object.entries(_fields.fields).flatMap(([_key, _value]) => {
        const val = _value as any;
        const _currentPath = path.concat(key, _key);

        if (
          val.exclusiveTests?.required ||
          val.exclusiveTests[_key] === false
        ) {
          return [[_currentPath.join("."), val]];
        }
        return [];
      }) as [string, any][];
    }

    const currentPath = path.concat(key);
    if ((value as any).tests && (value as any).tests.length > 0) {
      return [[currentPath.join("."), value]];
    } else if ((value as any).type === "object") {
      return extractTestableFields((value as any).fields, currentPath);
    } else {
      return [];
    }
  });
};

function countFields(
  schema: Yup.ObjectSchema<any>,
  nestKey?: string,
  ignoreNoRequiredField?: boolean,
): number {
  let count = 0;
  for (const key in schema.fields) {
    const field = schema.fields[key];
    if (!nestKey || key === nestKey) {
      if (field instanceof Yup.object) {
        count += countFields(
          field as Yup.ObjectSchema<any>,
          "",
          ignoreNoRequiredField,
        );
      } else {
        const isNotRequiredAndIgnored =
          ignoreNoRequiredField && !field.exclusiveTests?.required;
        if (!isNotRequiredAndIgnored) {
          count++;
        }
      }
    }
  }
  return count;
}
