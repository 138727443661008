import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type IconProps = SvgIconProps & {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
};

const SubtractIcon = ({
  width = 18,
  height = 18,
  fill = "none",
  path_fill = "#575353",
  ...rest
}: IconProps) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={fill}
      fontSize="inherit"
      htmlColor="transparent"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.57574 13.4243C4.34142 13.1899 4.34142 12.8101 4.57574 12.5757L8.15147 9L4.57574 5.42426C4.34142 5.18995 4.34142 4.81005 4.57574 4.57574C4.81005 4.34142 5.18995 4.34142 5.42426 4.57574L9 8.15147L12.5757 4.57574C12.8101 4.34142 13.1899 4.34142 13.4243 4.57574C13.6586 4.81005 13.6586 5.18995 13.4243 5.42426L9.84853 9L13.4243 12.5757C13.6586 12.8101 13.6586 13.1899 13.4243 13.4243C13.1899 13.6586 12.8101 13.6586 12.5757 13.4243L9 9.84853L5.42426 13.4243C5.18995 13.6586 4.81005 13.6586 4.57574 13.4243Z"
        fill={path_fill}
      />
    </SvgIcon>
  );
};

export default SubtractIcon;
