import Cookies from "js-cookie";
import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom";
import { AUTH_PATHS } from "./paths";

const PublicRoutes = () => {
  const user = Cookies.get("user");
  const match = useMatch(AUTH_PATHS.VALIDATE_ACCOUNT);
  const isValidationPath = !!match;
  const { search } = useLocation()

  return user && !isValidationPath ? (
    <Navigate to={`${JSON.parse(user).role}${search}`} replace />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
