import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { SidePanel } from "@containers/SidePanel";
import { Stack, styled } from "@mui/material";
import useSidePanel from "./useSidePanel";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { palette } from "@palette";
import ActionsHeader from "./ActionsHeader";

type PanelBodyProps = {
  data: any;
  isExpanded: boolean;
  isEmpty: boolean;
};

type TPanelBodyComponent = (props: PanelBodyProps) => JSX.Element;
type TLoadingPlaceholderComponent = ({
  isExpanded,
}: {
  isExpanded: boolean;
}) => JSX.Element;

interface SidePanelWrapperProps {
  data: any;
  isLoading: boolean;
  PanelBody: TPanelBodyComponent;
  LoadingPlaceholder: TLoadingPlaceholderComponent;
  isEmpty: boolean;
}

const SidePanelWrapper = ({
  isLoading,
  data,
  PanelBody,
  LoadingPlaceholder,
  isEmpty,
}: SidePanelWrapperProps) => {
  const { isExpanded, canBeExpanded, modal, handleClose, handleExpandPanel } =
    useSidePanel();
  const { isMobileView } = useCustomTheme();

  const panelBodyProps: PanelBodyProps = {
    isExpanded,
    data,
    isEmpty,
  };

  if (isMobileView) {
    return (
      <SwipeableDrawerMobile
        anchor="bottom"
        onOpen={() => undefined}
        open={modal.visible}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            background: palette.neutral[5],
            top: "96px",
          },
        }}
      >
        <ContentWrapper>
          {isLoading ? (
            <LoadingPlaceholder isExpanded={isExpanded} />
          ) : (
            <PanelBody {...panelBodyProps} />
          )}
        </ContentWrapper>
      </SwipeableDrawerMobile>
    );
  }

  return (
    <SidePanel
      modal={modal}
      onCloseDrawer={handleClose}
      PaperProps={{
        sx: {
          background: palette.liftedWhite.main,
          boxShadow: "0px 10px 25px 0px rgba(103, 103, 103, 0.15)",
          width: canBeExpanded && isExpanded
            ? CAMPAIGN_PANEL_WIDTH * 2
            : CAMPAIGN_PANEL_WIDTH
        },
      }}
    >
      <ContentWrapper>
        <ActionsHeader
          handleClose={handleClose}
          handleExpandPanel={handleExpandPanel}
          isExpanded={isExpanded}
          isEmpty={isEmpty || isLoading}
        />
        {isLoading ? (
          <LoadingPlaceholder isExpanded={isExpanded} />
        ) : (
          <PanelBody {...panelBodyProps} />
        )}
      </ContentWrapper>
    </SidePanel>
  );
};

const ContentWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: "column",
  flexGrow: 1,
  gap: "32px",
  alignItems: "stretch",
  overflowY: "auto",

  [theme.breakpoints.up("sm")]: {
    padding: "24px 16px",
    scrollbarGutter: "stable both-edges",
  },
}));

export default SidePanelWrapper;
