import { Button } from "@common/Button";
import { palette } from "@palette";
import XCircle from "@assets/icons/RebrandedIcons/XCircle";
import NiceModal from "@ebay/nice-modal-react";
import { styled } from "@mui/material";
import { FilterFunnelIcon } from "@assets/rebrandIcons";
import { RISK_MONITOR_FILTERS_MODAL } from "modals/modal_names";
import { defaultValuesRiskMonitorTrans } from "./modal/useTransactionFilter";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";
import {
  resetFilterByKey,
  selectFiltersObject,
} from "@redux/slices/dynamicFilterSlice";
import { forEach, isEmpty, isEqual, keys, union } from "lodash";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { countTopLevelDifferences } from "@utils/helpers";

function FilterBtn() {
  const queryClient = useQueryClient();
  const handleOpenModal = () => NiceModal.show(RISK_MONITOR_FILTERS_MODAL);
  const dispatch = useAppDispatch();
  const filterObject = useAppSelector((state) =>
    selectFiltersObject(state, QKEY_RISK_MONITOR_TRANSACTIONS),
  );

  const [cachedData, setCachedData] = useState<any>(null);

  useEffect(() => {
    const data = queryClient.getQueryData(QKEY_RISK_MONITOR_TRANSACTIONS);
    setCachedData(data);

    const unsubscribe = queryClient.getQueryCache().subscribe((event: any) => {
      if (event.query.queryKey === QKEY_RISK_MONITOR_TRANSACTIONS) {
        setCachedData(event.query.state.data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient]);
  const handleResetFilters = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(
      resetFilterByKey({
        filterKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      }),
    );
  };
  const filtersAmount = isEmpty(filterObject)
    ? null
    : countTopLevelDifferences(filterObject, defaultValuesRiskMonitorTrans);

  return (
    <StyledButton
      background="secondary"
      disabled={isEmpty(cachedData)}
      startIcon={
        <FilterFunnelIcon
          fill={filtersAmount ? palette.accent[3] : palette.neutral[60]}
        />
      }
      hasFilters={!!filtersAmount}
      onClick={handleOpenModal}
    >
      Filter {filtersAmount ? `(${filtersAmount})` : ""}
      {!!filtersAmount && (
        <XCircle
          fill={palette.neutral[80]}
          bordered={false}
          onClick={handleResetFilters}
        />
      )}
    </StyledButton>
  );
}

export default FilterBtn;

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "hasFilters",
})<{ hasFilters?: boolean }>(({ hasFilters }) => ({
  padding: "0 12px",
  border: hasFilters
    ? `1px solid ${palette.accent[3]}`
    : `1px solid ${palette.neutral[10]}`,
  background: hasFilters ? palette.info.light : palette.background.dimmedWhite,
  color: hasFilters ? palette.accent[3] : palette.neutral[70],
  fontWeight: 350,
  height: "32px",
  lineHeight: "16.8px",
  gap: "4px",
  "&:hover": {
    background: palette.background.dimmedWhite,
  },
}));
