import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import { useAppDispatch } from "@redux/hooks";
import { setSelectedVariant } from "@redux/slices/checkout";
import { buildMerchantEndpoints } from "@services/api/utils.api";

export const useFindInvoiceById = (id?: string, publicResource?: boolean) => {
  if (!id) return { data: undefined };

  const getInvoice = (id: string) => {
    const publicUrl = `products/${id}?filter=typeName:"invoice"`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const getInvoiceVariants = (id: string) => {
    const publicUrl = `products/${id}/variants?&sort=displayOrder`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const { data, error, isLoading } = useQuery(
    ["find-invoice-by-id", id],
    async () => {
      const invoice = await getInvoice(id);
      const variants = await getInvoiceVariants(id);
      return { invoice, variants };
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const dispatch = useAppDispatch();

  if (!data)
    return {
      error,
      isLoading,
      redirect: (error as any)?.response?.status === 404 ? true : false,
    };

  const customAmounts = data.variants?.data.map((amount: any) => {
    if ((amount.minPrice && amount.maxPrice) || amount.name === "Any Amount") {
      const minPrice = (amount.minPrice / 100).toFixed(2);
      const maxPrice = (amount.maxPrice / 100).toFixed(2);
      dispatch(
        setSelectedVariant({
          type: "invoice",
          min: minPrice,
          max: maxPrice,
        }),
      );

      return {
        isDefault: true,
        id: amount.id,
        amount: (amount.price / 100).toFixed(2),
        title: amount.name,
        description: amount.description,
        min_max: {
          enabled: !amount.allowCustomPrice,
          min: (amount.minPrice / 100).toFixed(2),
          max: (amount.maxPrice / 100).toFixed(2),
        },
        thumbnail: amount.imageURL ? amount.imageURL : "",
        active: amount.isEnabled,
        bundle: 1,
      };
    }
    return {
      id: amount.id,
      title: amount.name,
      amount: (amount.price / 100).toFixed(2),
      description: {
        enabled: Boolean(amount.description),
        text: stringToEditorState(amount.description),
      },
      thumbnail: amount.imageURL ? amount.imageURL : "",
      active: amount.isEnabled,
      bundle: 1,
    };
  });

  const customPaymentTypes: any = {};

  // "default" should be the first property in customPaymentTypes object.
  customPaymentTypes["default"] =
    data?.invoice.defaultRecurringIntervalName === "once"
      ? "one_time"
      : data?.invoice.defaultRecurringIntervalName;

  data?.invoice?.recurringIntervals?.forEach((item: string) => {
    if (item === "once") {
      customPaymentTypes["one_time"] = true;
    } else {
      customPaymentTypes[item] = true;
    }
  });

  ["one_time", "monthly", "quarterly", "yearly"].forEach((item) => {
    if (!customPaymentTypes[item]) customPaymentTypes[item] = false;
  });

  const customData = {
    general: {
      title: data?.invoice?.name || "",
      description: data?.invoice?.description || "",
      browseMore: data?.invoice?.canBrowseCampaigns || false,
      creatorName: data?.invoice?.merchantName,
      creatorImage: data?.invoice?.merchantImageURL,
      creatorDescription: data?.invoice?.merchantDescription,
      featuredImage: {
        image: data?.invoice?.imageURL || "",
        active: Boolean(data?.invoice?.imageURL),
        useAsBackground: data?.invoice?.usesBackgroundImage || false,
      },
      typeName: data?.invoice?.typeName,
    },
    payment: {
      payment_types: customPaymentTypes,
      customer_pays_credit: {
        active: data?.invoice?.needsTax || false,
        optional: data?.invoice?.allowFeeChoice || false,
      },
      amountsList: customAmounts || [],
    },
    accID: data?.invoice?.accID,
  };

  return {
    data: customData,
    isLoading: isLoading,
    redirect: (error as any)?.response?.status === 404 ? true : false,
  };
};
