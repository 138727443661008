import { Box } from "@mui/material";
import React from "react";

type RotateZProps = {
  by: string;
  children: React.ReactNode;
};

export const RotateZ: React.FC<RotateZProps> = ({ by, children }) => {
  return (
    <Box
      sx={{
        transform: `rotateZ(${by})`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
