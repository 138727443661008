type IconProps = {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  path_fill?: string;
};

const ShareIconUpV2 = ({
  width = 16,
  height = 16,
  fill = "#8F8F8F",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 9.87549V14.2505C15.5 14.582 15.3683 14.9 15.1339 15.1344C14.8995 15.3688 14.5815 15.5005 14.25 15.5005H1.75C1.41848 15.5005 1.10054 15.3688 0.866116 15.1344C0.631696 14.9 0.5 14.582 0.5 14.2505V9.87549C0.5 9.70973 0.565848 9.55076 0.683058 9.43355C0.800269 9.31634 0.95924 9.25049 1.125 9.25049C1.29076 9.25049 1.44973 9.31634 1.56694 9.43355C1.68415 9.55076 1.75 9.70973 1.75 9.87549V14.2505H14.25V9.87549C14.25 9.70973 14.3158 9.55076 14.4331 9.43355C14.5503 9.31634 14.7092 9.25049 14.875 9.25049C15.0408 9.25049 15.1997 9.31634 15.3169 9.43355C15.4342 9.55076 15.5 9.70973 15.5 9.87549ZM5.31719 4.69268L7.375 2.63409V9.87549C7.375 10.0413 7.44085 10.2002 7.55806 10.3174C7.67527 10.4346 7.83424 10.5005 8 10.5005C8.16576 10.5005 8.32473 10.4346 8.44194 10.3174C8.55915 10.2002 8.625 10.0413 8.625 9.87549V2.63409L10.6828 4.69268C10.8001 4.80996 10.9591 4.87584 11.125 4.87584C11.2909 4.87584 11.4499 4.80996 11.5672 4.69268C11.6845 4.5754 11.7503 4.41634 11.7503 4.25049C11.7503 4.08464 11.6845 3.92558 11.5672 3.8083L8.44219 0.683304C8.38414 0.625194 8.31521 0.579095 8.23934 0.547642C8.16346 0.516189 8.08213 0.5 8 0.5C7.91787 0.5 7.83654 0.516189 7.76066 0.547642C7.68479 0.579095 7.61586 0.625194 7.55781 0.683304L4.43281 3.8083C4.31554 3.92558 4.24965 4.08464 4.24965 4.25049C4.24965 4.41634 4.31554 4.5754 4.43281 4.69268C4.55009 4.80996 4.70915 4.87584 4.875 4.87584C5.04085 4.87584 5.19991 4.80996 5.31719 4.69268Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShareIconUpV2;
