import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import Dropzone from "react-dropzone-uploader";
import { UploaderState } from "./PAHUploaderMachine";
import { PAHUploaderInput, PAHUploaderLayout } from "./PAHUploaderComponents";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { TMerchantDocument } from "../../data.types";
import { FileUploadSnackbarContainer } from "@components/UploadFile/FileUploadSnackbarContainer";
import { TOwnerFile } from "./types";
import { usePAHUploader } from "../../hooks/usePAHUploader";
import PAHUploaderActions from "./PAHUploaderActions";
import { UPLOAD_DENY_MESSAGE } from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

type Props = {
  type: TOwnerFile;
  merchantId: number;
  docUrl: string;
  isMobile?: boolean;
  disabled?: boolean;
  downloadHandler: (
    documentData?: TMerchantDocument | undefined,
  ) => Promise<void>;
  fileId: number | undefined;
  previewDocument: ({
    tmpUrl,
    type,
    fileName,
  }: {
    tmpUrl?: string | undefined;
    type?: TOwnerFile | undefined;
    fileName?: string;
  }) => void;
  isUploadAllowed: boolean;
  isDeleteAllowed: boolean;
};

export const defaultMaxFileSize = 5000000;

const PAHUploader = ({
  type,
  merchantId,
  docUrl,
  downloadHandler,
  isMobile,
  previewDocument,
  fileId,
  isDeleteAllowed,
  isUploadAllowed,
  disabled = false,
}: Props) => {
  // Logic of upload, snackbars and state dispatch
  const {
    fileUrl,
    state,
    fileName,
    handleChangeStatus,
    _deleteDocument,
    isUploadable,
  } = usePAHUploader({ docUrl, merchantId, type, fileId });

  const dropZoneStyles = useDropZoneStyles({
    border: state === UploaderState.ON_UPLOADED ? "none" : "dashed",
  });

  return (
    <>
      <FileUploadSnackbarContainer />
      <Dropzone
        disabled={!isUploadable || disabled}
        classNames={{ ...dropZoneStyles }}
        styles={{
          dropzone: {
            height: 118,
            ...(!isMobile && {
              flex: 1,
            }),
            borderRadius: 12,
            margin: 0,
            justifyContent: "center",
            backgroundColor: palette.neutral[5],
            position: "relative",
          },
          dropzoneDisabled: {
            opacity: 1,
          },
        }}
        onChangeStatus={handleChangeStatus}
        multiple={false}
        canCancel={false}
        accept={ACCEPTED_IMAGE_FORMATS}
        LayoutComponent={(props) => (
          <PAHUploaderLayout {...props} fileUrl={fileUrl} state={state} />
        )}
        maxSizeBytes={defaultMaxFileSize}
        InputComponent={(props) => (
          <WithTooltipWrapper
            hasTooltip={!isUploadAllowed && !fileUrl}
            tooltipProps={{
              show: !isUploadAllowed,
              message: UPLOAD_DENY_MESSAGE,
            }}
          >
            <PAHUploaderInput
              {...props}
              isMobile={isMobile}
              fileName={fileName}
              type={type}
              state={state}
              fileUrl={fileUrl}
              previewDocument={previewDocument}
              isUploadable={isUploadable}
              disabled={disabled}
              actions={
                state === UploaderState.ON_UPLOADED ? (
                  <PAHUploaderActions
                    type={type}
                    downloadHandler={downloadHandler}
                    deleteDocument={_deleteDocument}
                    previewDocument={() =>
                      previewDocument({ tmpUrl: fileUrl, type, fileName })
                    }
                    disabled={{
                      delete: !isDeleteAllowed,
                    }}
                  />
                ) : undefined
              }
            />
          </WithTooltipWrapper>
        )}
      />
    </>
  );
};

export const useDropZoneStyles = makeStyles(() => ({
  dropzone: {
    border: (props: { border: string }) =>
      `2px ${props.border} ${palette.neutral[60]}`,
    "&:hover": {
      border: (props: { border: string }) =>
        props.border === "none"
          ? props.border
          : `2px solid ${palette.neutral[60]}`,
      backgroundColor: "#F8F8F6",
    },
    "&.dzu-dropzoneActive": {
      border: `2px solid ${palette.neutral[60]}`,
      "& > div": {
        backgroundColor: "#F8F8F6",
      },
    },
  },
}));

export default PAHUploader;
