import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useAppDispatch } from "@redux/hooks";
import { applyDateFilter } from "@redux/slices/analytics";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { addDays, subDays, subMonths } from "date-fns";
import { Button } from "@common/Button";
import { Stack } from "@mui/material";
import RHFDateInput from "./RHFDateInput";
import { useEffect } from "react";
import RangePicker from "./RangePicker";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { toUnixDateFormat } from "@utils/date.helpers";

type FormInputs = {
  startDate: Date | null;
  endDate: Date | null;
};

const DateRangePickerModal = ({
  queryKey,
  onClose,
  startDate,
  endDate,
}: {
  queryKey: string;
  onClose: () => void;
  startDate?: number;
  endDate?: number;
}) => {
  const { isMobileView } = useCustomTheme();
  const dispatch = useAppDispatch();
  const schema = Yup.object().shape({
    startDate: Yup.date()
      .typeError("Please provide a valid date")
      .nullable()
      .required("A starting date is required")
      .max(subDays(new Date(), 1), "A starting date must be in the past"),
    endDate: Yup.date()
      .typeError("Please provide a valid date")
      .nullable()
      .min(Yup.ref("startDate"), "End date cannot be before start date"),
  });

  const defaultValues = {
    startDate: subDays(new Date(), 1),
    endDate: null,
  };

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { reset, setValue, watch } = methods;
  const values = watch();

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined)
      reset({
        startDate: new Date(startDate * 1000),
        endDate: new Date(endDate * 1000),
      });
  }, []);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    dispatch(
      applyDateFilter({
        queryKey,
        filter: {
          type: "custom",
          amount: 0,
          startDate: data?.startDate ? data.startDate : subDays(new Date(), 1),
          endDate: data?.endDate ? data.endDate : new Date(),
        },
      }),
    );
    onClose();
  };

  const quickSelectionButtons = [
    {
      label: "Last 30 days",
      onClick: () =>
        reset({ startDate: subDays(new Date(), 30), endDate: new Date() }),
    },
    {
      label: "Last 3 months",
      onClick: () =>
        reset({ startDate: subMonths(new Date(), 3), endDate: new Date() }),
    },
    {
      label: "Last 12 months",
      onClick: () =>
        reset({ startDate: subMonths(new Date(), 12), endDate: new Date() }),
    },
  ];

  const onSelectionChange = (startDate: Date, endDate: Date) => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          gap: "24px",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Stack
          direction={isMobileView ? "column" : "row"}
          gap={2}
          alignItems={isMobileView ? "stretch" : "center"}
        >
          <Button
            background="tertiary"
            onClick={() => reset(defaultValues)}
            sx={{
              marginRight: "32px",
            }}
          >
            Reset
          </Button>
          <RHFDateInput
            name="startDate"
            label="Start date"
            maxDate={subDays(new Date(), 1)}
          />
          <RHFDateInput
            name="endDate"
            label="End date"
            maxDate={new Date()}
            minDate={
              values?.startDate ? addDays(values?.startDate, 1) : undefined
            }
          />
        </Stack>
        <RangePicker
          onSelectionChange={onSelectionChange}
          initialRange={{
            startDate: startDate || toUnixDateFormat(values.startDate || new Date()),
            endDate: endDate || toUnixDateFormat(values.endDate || new Date()),
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap="12px" alignItems="center">
            {quickSelectionButtons.map(({ label, onClick }) => (
              <Button
                background="tertiary"
                onClick={onClick}
                key={label}
                sx={{ padding: 0 }}
              >
                {label}
              </Button>
            ))}
          </Stack>
          <Button
            background="primary"
            type="submit"
            sx={{ fontSize: "18px", padding: "12px 24px" }}
          >
            Done
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
};

export default DateRangePickerModal;
