import { Avatar, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Selector from "@common/TableFilters/Selector";
import React, { useState } from "react";
import { SelectorOption } from "@common/TableFilters/utils";
import SelectedTickIcon from "./SelectedTickIcon";
import FilterItem from "./FilterItem";
import useListUsers from "../hooks/useListUsers";
import { useFormContext, useWatch } from "react-hook-form";
import { FilterValuesType } from "../hooks/useChangelogFilters";
import ApplyButton from "./ApplyButton";

interface IUserFilterProps {
  merchantId: number;
}

const UserFilter = ({ merchantId }: IUserFilterProps) => {
  const { setValue } = useFormContext<FilterValuesType>();
  const savedValues: SelectorOption[] = useWatch({ name: "user" });
  const [values, setValues] = useState<SelectorOption[]>(savedValues);
  const title =
    savedValues.length > 0 ? `User (${savedValues.length})` : "User";

  const { data, isLoading, handleSearch, handleScroll } = useListUsers({
    merchantId,
  });

  const handleChange = (val: any) => {
    const selectedValue = val as SelectorOption;
    const valueExists = values.find((curr) => curr.id === selectedValue.id);
    setValues(
      valueExists
        ? values.filter((curr) => curr.id !== selectedValue.id)
        : [...values, selectedValue],
    );
  };

  const handleReset = (event: React.MouseEvent<any, MouseEvent>) => {
    event.stopPropagation();
    setValues([]);
    setValue("user", []);
  };

  return (
    <Selector
      handleChange={handleChange}
      handleSearch={handleSearch}
      onScroll={handleScroll}
      loading={isLoading}
      options={data}
      value={values}
      multiSelect
      renderCustomElement={({ selected, item }) => {
        return (
          <Stack direction="row" gap={1} padding={1} alignItems="center">
            <StyledAvatar src={item.imageURL} />

            <Text
              fontWeight="book"
              color={palette.black[100]}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {item.label}
            </Text>
            {selected && <SelectedTickIcon />}
          </Stack>
        );
      }}
      renderCustomParent={({ onClick }) => (
        <FilterItem
          isSelected={savedValues.length > 0}
          onCancel={handleReset}
          title={title}
          onClick={onClick}
        />
      )}
      renderFooter={({ onClose }) => (
        <ApplyButton onClose={onClose} filterType="user" values={values} />
      )}
      ignoreMobileView
    />
  );
};

const StyledAvatar = styled(Avatar)({ width: "32px", height: "32px" });

export default UserFilter;
