import { customInstance } from "..";
import { buildMerchantEndpoints } from "../utils.api";

interface IParams {
  filter?: string;
}

export const getCustomersAnalytics = (params?: IParams) => {
  const baseURL = "customers/stats";
  let URL = baseURL;
  if (params?.filter) URL += `?${params.filter}`;

  return customInstance({
    url: buildMerchantEndpoints(URL),
    method: "GET",
  });
};
