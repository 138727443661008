import CopyButton from "@common/CopyButton";
import { RHFInput } from "@common/Input";
import { RHFInputProps } from "@common/Input/Input";
import { useFormContext } from "react-hook-form";

type Props = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
} & RHFInputProps;

const WebsiteInput = ({
  name,
  disabled = false,
  placeholder,
  ...props
}: Props) => {
  const { watch } = useFormContext();

  const prefix = "https://";

  return (
    <RHFInput
      name={name}
      label={props.label}
      placeholder={placeholder || "example.com"}
      fullWidth
      disabled={disabled}
      inputPrefix={prefix}
      endIcon={
        <CopyButton
          hidden={!watch(name)}
          text={prefix + watch(name) + (props?.inputSuffix || "")}
          testId={`copy-button-${
            typeof props.label === "string" ? props.label : name
          }`}
        />
      }
      {...props}
    />
  );
};

export default WebsiteInput;
