import * as React from "react";
import { palette } from "@palette";
// mui
import { Box } from "@mui/material";
// components
import { Text } from "@common/Text";
import OTPInput from "./sections/OTPInput";
// sections
import { useFormData } from "../TransferMoneyFormProvider";
import { useVerificationCode } from "@hooks/common/useVerification";
import { partiallyMaskString } from "@utils/index";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const getContent = (
  method: "email" | "sms",
  phone: string,
  email: string,
  subject: string,
) => {
  return (
    <Box>
      <Text
        textAlign="center"
        sx={{ fontWeight: 350 }}
        fontSize={16}
        color={palette.neutral[80]}
        data-testid="code-was-sent-to-text"
      >
        Code was sent to{" "}
        <Box component="span" sx={{ fontWeight: 350 }}>
          {method === "sms"
            ? `+${phone}`
            : partiallyMaskString(email, 4, 8, "x")}
        </Box>
      </Text>
    </Box>
  );
};

export const CodeInput = ({ setStep, setDisabled }: Props) => {
  const { formData } = useFormData();
  const { method, subject } = formData.identityVerification;
  const { handleCodeSubmit, isLoading } = useVerificationCode({
    action: setStep,
  });

  const user = useAppSelector(selectUser);
  const {
    email,
    globalName: { phoneNumber },
  } = user;

  const [error, setError] = React.useState(false);
  const [otpValue, setOtpValue] = React.useState("");

  React.useEffect(() => {
    if (error && otpValue.length < 6) {
      setError(false);
    }
    if (!error && otpValue.length === 6) {
      handleCodeSubmit({
        code: otpValue,
      });
    }
  }, [otpValue]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleCodeSubmit({
      code: otpValue,
    });
  };

  const onOTPChange = (value: string) => {
    setOtpValue(value);
  };

  React.useEffect(() => {
    setDisabled(isLoading);
  }, [isLoading]);

  React.useEffect(() => {
    const _val = Boolean(otpValue);
    setDisabled(!_val);
  }, [otpValue]);

  return (
    <Box component="form" id="transfer-money-form" onSubmit={onSubmit}>
      <FadeUpWrapper delay={100}>
        <Text
          fontSize={20}
          textAlign="center"
          color={palette.neutral.black}
          sx={{
            fontWeight: 350,
          }}
        >
          Enter verification code
        </Text>
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Box
          mt={4}
          mb={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <OTPInput
            OTPValue={otpValue}
            onOTPValueChange={onOTPChange}
            length={6}
            placeholder="0"
            error={error}
          />
        </Box>
      </FadeUpWrapper>
      <FadeUpWrapper delay={300}>
        {getContent(method, phoneNumber, email, subject)}
      </FadeUpWrapper>
    </Box>
  );
};
