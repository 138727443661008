import { useMemo } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { DisputesRowType } from "@components/Disputes/RespondToDispute/types";
import { DISPUTE_FRAUD_MODAL } from "modals/modal_names";
import { MobileActionItemProps } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import { palette } from "@palette";

const useExtraActions = (data: DisputesRowType) => {
  const actions = useMemo(
    () => [
      {
        title: "Mark as fraud",
        hidden: data?.markedAsFraud,
        onClick: () => {
          NiceModal.show(DISPUTE_FRAUD_MODAL, {
            variant: "mark_as_fraud",
            disputeId: data?.id,
          });
        },
        sx: { fontWeight: 350, color: palette.neutral[80] }
      },
      {
        title: "Unmark as fraud",
        hidden: !data?.markedAsFraud,
        onClick: () => {
          NiceModal.show(DISPUTE_FRAUD_MODAL, {
            variant: "unmark_as_fraud",
            disputeId: data?.id,
          });
        },
        sx: { fontWeight: 350, color: palette.neutral[80] }
      },
    ],
    [data],
  );

  const mobileActions: MobileActionItemProps[] = actions.map(
    ({ title, hidden, onClick }) => ({
      label: title,
      onSelect: onClick,
      hidden: hidden,
    }),
  );

  return { actions, mobileActions };
};

export default useExtraActions;
