import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";

const NotAbleProcessPaymentFormModal = NiceModal.create(() => {
  const modal = useModal();

  const handleModalClose = () => {
    modal.hide();
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      title="Please try again later"
      onSubmit={handleModalClose}
      actionLabel="Okay"
    >
      Currently we cannot accept any payments but we’re working on it
    </Popup>
  );
});

export default NotAbleProcessPaymentFormModal;
