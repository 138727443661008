import { Box, styled } from "@mui/material";
import { TMerchantRiskProfile } from "../../types";
import { palette } from "@palette";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

export const riskLevels: any = {
  low: {
    level: 0,
    color: "#13A75A",
    background: "#EAFEF3",
    gradient:
      "radial-gradient(50% 50% at 50% 50%, #EAFEF3 0%, rgba(234, 254, 243, 0.00) 100%)",
  },
  medium: {
    level: 1,
    color: "#D98200",
    background: "#FFF6E9",
    gradient:
      "radial-gradient(50% 50% at 50% 50%, #FCF2E4 0%, rgba(255, 215, 186, 0.00) 100%, rgba(255, 204, 186, 0.00) 100%, rgba(252, 241, 227, 0.00) 100%)",
  },
  high: {
    level: 2,
    color: palette.warning.text,
    background: "#FFD7BA",
    gradient:
      "radial-gradient(50% 50% at 50% 50%, #FFCCBA 0%, rgba(255, 215, 186, 0.00) 100%, rgba(255, 204, 186, 0.00) 100%)",
  },
  critical: {
    level: 3,
    color: "#941A1A",
    background: "#FFCABA",
    gradient:
      "radial-gradient(50% 50% at 50% 50%, #FFCCBA 0%, rgba(255, 215, 186, 0.00) 100%, rgba(255, 204, 186, 0.00) 100%)",
  },
};

export function getRiskLevel(level: number) {
  for (const key of Object.keys(riskLevels) as Array<keyof typeof riskLevels>) {
    if (riskLevels[key].level === level) {
      return riskLevels[key];
    }
  }
  return riskLevels?.low;
}

type Props = {
  data?: TMerchantRiskProfile;
};

const RiskProfileActivityHeader = ({ data }: Props) => {
  const risk = data?.riskLevel || "low";

  return (
    <>
      <FadeUpWrapper delay={80}>
        <Box
          sx={{
            position: "absolute",
            background: riskLevels[risk].gradient,
            width: "100%",
            height: "60px",
            top: "-15px",
          }}
        />
      </FadeUpWrapper>
      <FadeUpWrapper delay={120}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="12px"
          mt="32px"
        >
          <Level
            sx={{
              color: riskLevels[risk].color,
              backgroundColor: riskLevels[risk].background,
              border: `1px solid ${riskLevels[risk].color}`,
              textTransform: "capitalize",
            }}
          >
            {risk}
          </Level>
          <Text color={riskLevels[risk].color}>
            {data?.riskPoints || 0} Points
          </Text>
        </Box>
      </FadeUpWrapper>
    </>
  );
};

const Level = styled(Box)(() => ({
  padding: "4px 16px",
  borderRadius: "100px",
  width: "132px",
  fontSize: 14,
  lineHeight: "120%",
  fontWeight: 400,
  display: "flex",
  justifyContent: "center",
}));

export default RiskProfileActivityHeader;
