import { useAppSelector, useAppDispatch } from "@redux/hooks";
import {
  resetChangelogState,
  selectChangelog,
  setModalOpenChangelog,
} from "@redux/slices/changelog";

export const useChangelogModal = () => {
  const {
    modal: { isOpen },
  } = useAppSelector(selectChangelog);

  const dispatch = useAppDispatch();

  const handleOpenChangelogModal = (open?: boolean) => {
    dispatch(setModalOpenChangelog(!!open));
    if (!open) {
      dispatch(resetChangelogState());
    }
  };

  return {
    handleOpenChangelogModal,
    isOpen,
  };
};
