import React, { memo } from "react";
import { QKEY_LIST_UNDERWRITERS } from "@constants/queryKeys";

import {
  AssigneesType,
  useGetUnderwriters,
} from "@components/Merchants/MerchantPreview/hooks/useGetTeamMembers";
import AssignMenu from "@common/AssignMenu/AssignMenu";

export type MenuItem = {
  disabled?: boolean;
  onSelect: (e: any) => void;
  label: string | React.ReactNode;
  roleName: string;
};

interface ICustomAssignMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  statusName?: string;
  handleChange: (value: string | null) => void;
  underwriterID: number;
  merchantId: number;
  type?: AssigneesType;
}

function CustomAssignMenu({
  anchorEl,
  handleClose,
  statusName,
  handleChange,
  merchantId,
  underwriterID,
  type,
}: ICustomAssignMenuProps) {
  const membersData = useGetUnderwriters({
    merchantId,
    queryOptions: {
      enabled: !!merchantId && !!anchorEl,
    },
    type,
  });

  return (
    <AssignMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      statusName={statusName}
      handleChange={handleChange}
      assigneeId={underwriterID}
      emptyStateTitle="No underwriters found"
      emptyStateSubtitle="Invite team members with underwriter role"
      serachQueryKey={QKEY_LIST_UNDERWRITERS}
      {...membersData}
    />
  );
}

export default memo(CustomAssignMenu);
