interface Props {
  width?: number | string;
  height?: number | string;
  fill?: string;
}
export default function BoldPlusIcon({
  width = 14,
  height = 14,
  fill = "#8F8F8F",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6956 6.9999C13.6956 7.21302 13.6109 7.41741 13.4602 7.56811C13.3095 7.71881 13.1051 7.80347 12.892 7.80347L7.80273 7.80347L7.80273 12.8928C7.80273 13.1059 7.71807 13.3103 7.56737 13.461C7.41668 13.6117 7.21228 13.6963 6.99916 13.6963C6.78604 13.6963 6.58165 13.6117 6.43095 13.461C6.28025 13.3103 6.19559 13.1059 6.19559 12.8928L6.19559 7.80347L1.10631 7.80347C0.893185 7.80347 0.688794 7.71881 0.538095 7.56811C0.387396 7.41741 0.302734 7.21302 0.302734 6.9999C0.302734 6.78678 0.387396 6.58238 0.538095 6.43168C0.688794 6.28099 0.893185 6.19632 1.10631 6.19632L6.19559 6.19632L6.19559 1.10704C6.19559 0.893918 6.28025 0.689526 6.43095 0.538827C6.58165 0.388129 6.78604 0.303467 6.99916 0.303467C7.21228 0.303467 7.41668 0.388129 7.56737 0.538827C7.71807 0.689526 7.80273 0.893918 7.80273 1.10704L7.80273 6.19632L12.892 6.19632C13.1051 6.19632 13.3095 6.28099 13.4602 6.43168C13.6109 6.58238 13.6956 6.78678 13.6956 6.9999Z"
        fill={fill}
      />
    </svg>
  );
}
