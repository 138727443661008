import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import { Box, Stack } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TFundraiserModalInputs } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FundraiserModalSchema as schema,
  FundraiserModalDefaults as defaultValues,
} from "./utils";
import { useFindMembershipById } from "@hooks/merchant-api/memberships";
import FundraisersAbout from "../FundraisersAbout";
import { useEffect } from "react";
import StyleSection from "../StyleSection";
import { useCreateProduct } from "../hooks";
import MembershipsConfiguration from "./MembershipsConfiguration";
import MembershipsPayment from "./MembershipsPayment";
import { useQueryClient } from "react-query";
import EditProductSkeleton from "../EditProductSkeleton";

type EditMembershipsModalProps = {
  id?: string;
  handleClose?: () => void;
  noModal?: boolean;
};

export const EditMembership = ({
  id,
  handleClose,
  noModal,
}: EditMembershipsModalProps) => {
  const { data, isLoading } = useFindMembershipById(id);
  const { submitProduct, isDisabled } = useCreateProduct("membership", {
    id,
    noModal,
  });
  const queryClient = useQueryClient();

  const methods = useForm<TFundraiserModalInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (data && !isLoading) {
      const formattedList = data.subscriptions.list.map((item: any) => {
        return {
          ...item,
          description: item.description?.text || "",
          title: item.name,
        };
      });
      reset({
        about: {
          title: data.general.title,
          description: data.general.description,
        },
        style: {
          image: data.general.featuredImage.image,
          useAsBackground: data.general.featuredImage.useAsBackground,
        },
        payment_set_up: {
          subscriptionsList: formattedList,
        },
        configuration: {
          customer_pays_fees: data.subscriptions.customer_pays_credit.active,
          max_subscriptions:
            data.subscriptions.customer_pays_credit.max_subscriptions,
          browse_more: data.general.browseMore,
        },
      });
    }
  }, [isLoading]);

  const onClose = () => {
    reset();
    if (handleClose) {
      handleClose();
      queryClient.removeQueries("find-membership-by-id");
    }
  };

  const onSubmit: SubmitHandler<TFundraiserModalInputs> = async (data) => {
    submitProduct(data as any, onClose);
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      HeaderProps={{
        title: "Edit",
      }}
      primaryAction={{
        label: "Save changes",
        type: "submit",
        disabled: !isDirty || isDisabled,
        form: "edit-membership",
      }}
      isBody={noModal}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          display="flex"
          id="edit-membership"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <EditProductSkeleton />
          ) : (
            <Stack direction="column" gap={4} flexGrow={1}>
              <FundraisersAbout
                placeHolderText="What’s the purpose of this membership?"
                title="About"
                titleSize="medium"
                isEdit
              />
              <StyleSection title="Customize" titleSize="medium" isEdit />
              <MembershipsPayment
                title="Subscriptions"
                titleSize="medium"
                isEdit
              />
              <MembershipsConfiguration
                title="Configuration"
                titleSize="medium"
                isEdit
              />
            </Stack>
          )}
        </Box>
      </FormProvider>
    </ModalDrawer>
  );
};

const EditMembershipsModal = NiceModal.create(
  ({ id }: EditMembershipsModalProps) => {
    const modal = useModal();

    const handleClose = () => {
      modal.hide();
    };

    return <EditMembership id={id} handleClose={handleClose} />;
  },
);

export default EditMembershipsModal;
