interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  background?: string;
}

const DividerIcon = ({
  width = 33,
  height = 32,
  background = "#F4F3F7",
  fill = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="32" height="32" rx="4" fill={background} />
      <rect
        opacity="0.75"
        x="4.81116"
        y="5.49249"
        width="23.8776"
        height="8.42643"
        rx="1"
        fill={fill}
      />
      <rect
        opacity="0.75"
        x="4.81116"
        y="18.0814"
        width="23.8776"
        height="8.42643"
        rx="1"
        fill={fill}
      />
      <path
        d="M8.08594 16H24.2643"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DividerIcon;
