import { customInstance } from "..";

export type GenericNotificationPayload = {
  subject: string;
  message: string;
};

export const notifyMerchant = (
  merchantId: number,
  data: GenericNotificationPayload,
) =>
  customInstance({
    url: `/merchants/${merchantId}/notify`,
    method: "POST",
    data,
  });
