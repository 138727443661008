import { palette } from "@palette";
import Dropzone from "react-dropzone-uploader";
import { UploaderState } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderMachine";
import { PAHUploaderLayout } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderComponents";
import { ACCEPTED_IMAGE_FORMATS } from "@constants/constants";
import { useBusinessOwnerIDUploader } from "@components/Merchants/MerchantPreview/hooks/useBusinessOwnerIDUploader";
import { BusinessOwnerIDInput } from "./BusinessOwnerIDInput";
import { useDropZoneStyles } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploader";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Controller, useFormContext } from "react-hook-form";
import { Stack } from "@mui/material";
import { Text } from "../Text";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useIsValidFile } from "@hooks/upload-api/useIsValidFile";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

type Props = {
  merchantId: number;
  idFile?: TMerchantDocument;
  disabled?: boolean;
};

export const defaultMaxFileSize = 5000000;

const BusinessOwnerIDUploader = ({ merchantId, idFile, disabled }: Props) => {
  const { deleteID, idUrl, downloadFile, previewDocument } =
    useBusinessOwnerIDUploader({
      merchantId,
      idFile,
    });

  const { isMobileView } = useCustomTheme();

  const isFileUploaded = !!idUrl;
  const dropZoneStyles = useDropZoneStyles({
    border: isFileUploaded ? "none" : "dashed",
  });

  const { control } = useFormContext();
  const { isDropzoneImageFileValid } = useIsValidFile();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  return (
    <Controller
      name={"files"}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => {
        const errorMessage = (error as any)?.allFiles?.message;
        return (
          <Stack gap={1.5} minHeight="104px">
            <Dropzone
              disabled={disabled}
              classNames={{ ...dropZoneStyles }}
              styles={{
                dropzone: {
                  height: "104px",
                  borderRadius: 12,
                  justifyContent: "center",
                  backgroundColor: palette.neutral[5],
                  position: "relative",
                  borderColor: errorMessage ? palette.filled.red : undefined,
                },
                dropzoneDisabled: {
                  opacity: 1,
                },
              }}
              onChangeStatus={(fileWithMeta, status, allFiles) => {
                if (
                  isFileUploadRefactorEnabled &&
                  !isDropzoneImageFileValid(fileWithMeta, status)
                ) {
                  return;
                }
                onChange(
                  {
                    fileWithMeta,
                    status,
                    allFiles,
                  },
                  { shouldDirty: true },
                );
              }}
              multiple={false}
              canCancel={false}
              accept={ACCEPTED_IMAGE_FORMATS}
              LayoutComponent={(props) => (
                <PAHUploaderLayout
                  {...props}
                  disabled={!!disabled}
                  fileUrl={idUrl}
                  state={
                    isFileUploaded
                      ? UploaderState.ON_UPLOADED
                      : UploaderState.ON_INITIAL
                  }
                  sx={{
                    "&:hover": null,
                    background: `linear-gradient(180deg, rgba(12, 12, 12, 0.24) 0%, rgba(12, 12, 12, 0.82) 70%), url(${idUrl}) lightgray 50% / cover no-repeat`,
                  }}
                />
              )}
              maxSizeBytes={defaultMaxFileSize}
              InputComponent={(props) => (
                <BusinessOwnerIDInput
                  {...props}
                  isMobile={isMobileView}
                  fileName={idUrl}
                  state={
                    isFileUploaded
                      ? UploaderState.ON_UPLOADED
                      : UploaderState.ON_INITIAL
                  }
                  fileUrl={idUrl}
                  downloadHandler={downloadFile}
                  deleteDocument={deleteID}
                  disabled={!!disabled}
                  previewDocument={previewDocument}
                />
              )}
            />
            {errorMessage && (
              <Text color={palette.neutral[70]}>{errorMessage}</Text>
            )}
          </Stack>
        );
      }}
    />
  );
};

export default BusinessOwnerIDUploader;
