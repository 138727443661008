import { Stack, styled, SxProps } from "@mui/material";
import { Checkbox } from "@common/Checkbox";
import React from "react";

type Slots = "container" | "checkbox" | "textWrapper";
type SlotsSx = Record<Slots, SxProps>;

type LayoutProps = {
  checked: boolean;
  disabled?: boolean;
  name: string;
  onSelect: VoidFunction;
  onClick?: VoidFunction;
  isMobileLayout?: boolean;
  sx?: Partial<SlotsSx>;
  children: React.ReactNode;
  dataTestId?: string;
};

const InviteListItemLayout = ({
  checked,
  disabled = false,
  name,
  onSelect,
  onClick,
  isMobileLayout = false,
  sx,
  children,
  dataTestId,
}: LayoutProps) => {
  return (
    <Container onClick={onClick} sx={sx?.container} data-testid={dataTestId}>
      <Checkbox
        data-testid={`${name}-checkbox`}
        name={name}
        checked={checked}
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
        onChange={onSelect}
        sx={{
          width: "20px",
          height: "20px",
          padding: isMobileLayout ? "6px 0" : "4px 0",
          boxSizing: "content-box",

          "& .MuiButtonBase-root.MuiCheckbox-root": {
            width: "20px",
            height: "20px",
            padding: 0,

            "& > svg": {
              width: 20,
              height: 20,
            },
          },
          "& .MuiFormControlLabel-root": {
            margin: "0 !important",
            verticalAlign: "baseline",
          },
          ...sx?.checkbox,
        }}
      />
      <Stack
        flexDirection={isMobileLayout ? "column" : "row"}
        alignItems={isMobileLayout ? "flex-start" : "center"}
        flexGrow={1}
        gap="8px"
        overflow="hidden"
        sx={sx?.textWrapper}
      >
        {children}
      </Stack>
    </Container>
  );
};

export default InviteListItemLayout;

const Container = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  padding: "8px",
  backgroundColor: "inherit",
  cursor: "default",
}));
