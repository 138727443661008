import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

type FormInputs = {
  amexCheckbox: boolean;
  expressCheckbox: boolean;
};

export const TosWrapper = ({ children }: { children: React.ReactNode }) => {
  const methods = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      amexCheckbox: true,
      expressCheckbox: true,
    },
  });

  // TODO: handle api when needed, for now checkboxes are implemented in FE

  return (
    <Wrapper>
      <Box component="form">
        <FormProvider {...methods}>{children}</FormProvider>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #fff;
  font-family: Circular, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: #253655;
  padding: 20px;
  min-height: 100%;
  border-radius: 20px;
  text-align: justify;
`;
