import { customInstance } from "@services/api";

const useSaveJson = (accountId: number, memberId: number) => {
  const handleSendJson = (value: string) => {
    return customInstance({
      url: `/accounts/${accountId}/members/${memberId}/access-policies`,
      method: "POST",
      data: JSON.parse(value),
    });
  };

  return { handleSendJson };
};

export default useSaveJson;
