import React from "react";
import FloatingToolbarWrapper from "./FloatingToolbarWrapper";
import { palette } from "@palette";

const SelectedNodeContainer = ({
  hideToolbar,
  children,
}: {
  hideToolbar: boolean;
  children: React.ReactElement;
}) => {
  return hideToolbar ? (
    <>{children}</>
  ) : (
    <FloatingToolbarWrapper>{children}</FloatingToolbarWrapper>
  );
};

export const WithSelectedNode = ({
  children,
  isSelected,
  hideToolbar,
}: any) => {
  if (isSelected) {
    return (
      <SelectedNodeContainer hideToolbar={hideToolbar}>
        <div
          id="selected_node_item_wrapper"
          style={{
            boxShadow: `0 0 0 3px ${palette.givebox[600]}`,
            display: "flex",
          }}
        >
          {children}
        </div>
      </SelectedNodeContainer>
    );
  }

  return children;
};
