import { StyledIconButton } from "@common/IconButton";
import { styled } from "@mui/material";

export const CloseButton = styled(StyledIconButton, {
  shouldForwardProp: (prop) => prop !== "closeButtonSize",
})<{ closeButtonSize: number }>(({ closeButtonSize }) => ({
  maxWidth: `${closeButtonSize}px`,
  maxHeight: `${closeButtonSize}px`,
  borderRadius: "4px",
  padding: "0 !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
