import React from "react";

const ExternalLinkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5229 4.75017C12.5229 4.47403 12.7468 4.25017 13.0229 4.25017H15.2502C15.5263 4.25017 15.7502 4.47403 15.7502 4.75017V6.97745C15.7502 7.25359 15.5263 7.47745 15.2502 7.47745C14.974 7.47745 14.7502 7.25359 14.7502 6.97745V5.95728L10.9901 9.71738C10.7948 9.91264 10.4782 9.91264 10.283 9.71737C10.0877 9.52211 10.0877 9.20553 10.283 9.01026L14.0431 5.25017H13.0229C12.7468 5.25017 12.5229 5.02631 12.5229 4.75017ZM4.25009 6.65927C4.25009 5.68021 5.04378 4.88653 6.02283 4.88653H9.36377C9.63992 4.88653 9.86377 5.11039 9.86377 5.38654C9.86377 5.66268 9.63992 5.88654 9.36377 5.88654H6.02283C5.59606 5.88654 5.25009 6.2325 5.25009 6.65927C5.25009 6.93541 5.02624 7.15927 4.75009 7.15927C4.47395 7.15927 4.25009 6.93541 4.25009 6.65927ZM4.75009 9.69715C5.02624 9.69715 5.25009 9.92101 5.25009 10.1972V13.9775C5.25009 14.4042 5.59606 14.7502 6.02283 14.7502H13.3411C13.7679 14.7502 14.1138 14.4042 14.1138 13.9775V10.6366C14.1138 10.3604 14.3377 10.1365 14.6138 10.1365C14.89 10.1365 15.1138 10.3604 15.1138 10.6366V13.9775C15.1138 14.9565 14.3201 15.7502 13.3411 15.7502H6.02283C5.04378 15.7502 4.25009 14.9565 4.25009 13.9775V10.1972C4.25009 9.92101 4.47395 9.69715 4.75009 9.69715Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
