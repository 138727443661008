import React from "react";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { getUSNames } from "@utils/country_dial_codes";

type ProvinceInputProps = {
  id?: string;
  isUS: boolean | null;
  name?: string;
  disabled?: boolean;
  focusViewColor?: string;
};

const ProvinceInput = ({
  isUS,
  name,
  disabled,
  ...rest
}: ProvinceInputProps) => {
  const isMount = React.useRef(true);

  React.useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
      return;
    }
  }, [isUS]);

  if (isUS)
    return (
      <RHFSelect
        name={name || "businessAddress.province"}
        label="State"
        placeholder="Select State"
        disabled={disabled}
        options={getUSNames().map((state) => ({
          value: state,
          label: state,
        }))}
        {...rest}
      />
    );

  return (
    <RHFInput
      name={name || "businessAddress.province"}
      label="Province"
      fullWidth
      placeholder="Type in your Province"
      disabled={disabled}
      {...rest}
    />
  );
};

export default ProvinceInput;
