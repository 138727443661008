import { RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { MerchantSectionContainer } from "@components/Merchants/MerchantPreview/components/atoms";
import NiceModal from "@ebay/nice-modal-react";
import { Grid } from "@mui/material";
import { palette } from "@palette";
import { CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL } from "modals/modal_names";
import { useFormContext } from "react-hook-form";
import { TCreatePrimaryAccountHolder } from "../types";
import { AssignToMe } from "../modals/components/AssignToMe";
import PreviewInvitationButton from "@components/Merchants/Common/PreviewInvitationButton/PreviewInvitationButton";
import { useInfiniteEnterprises } from "@common/BusinessProfileInputs/EnterprisesSelect";
import { useMemo } from "react";
import { checkPortals } from "@utils/routing";
import { useGetCurrentMerchantId } from "@hooks/common";

export const CreatePrimaryAccountHolder = () => {
  const { watch, setValue } = useFormContext();
  const values = watch();

  const handleClosePrimaryAccountHolder = (
    data: TCreatePrimaryAccountHolder,
  ) => {
    setValue("primaryAccountHolder", data);
  };

  const editHandler = () => {
    NiceModal.show(CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL, {
      onClose: handleClosePrimaryAccountHolder,
      data: values.primaryAccountHolder,
    });
  };

  const { email, assignToMe } = values.primaryAccountHolder || {};
  if (!email && !assignToMe) return null;
  const { merchantName, enterpriseID } = values.merchantInfo || {};
  const { data } = useInfiniteEnterprises();
  const { isEnterprisePortal } = checkPortals();
  const { name, img } = useGetCurrentMerchantId();

  const provider = useMemo(() => {
    return data?.find((item: any) => {
      if (enterpriseID === item?.accID) {
        return item;
      }
    });
  }, [data, enterpriseID]);

  const correctProvider = isEnterprisePortal
    ? {
        name: name,
        imageURL: img,
      }
    : provider;

  return (
    <MerchantSectionContainer
      sectionTitle="Primary Account Holder"
      editHandler={editHandler}
    >
      {assignToMe ? (
        <AssignToMe showCheckbox={false} />
      ) : (
        <Grid container rowSpacing={0.5} columnSpacing={2}>
          <Grid item xs={12} sm={4.5}>
            <Text fontWeight="light" color={palette.neutral[80]}>
              Email
            </Text>
          </Grid>
          <Grid item xs={12} sm={7.5}>
            <Text fontWeight="book" color={palette.neutral[80]}>
              {values.primaryAccountHolder?.email}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <RHFCheckbox
              name="primaryAccountHolder.invite"
              label="Invite upon creation"
            />
          </Grid>
          <Grid item xs={12} display="flex">
            <PreviewInvitationButton
              title="Preview Invitation"
              receiverName={merchantName}
              senderName={correctProvider?.name}
              senderLogo={correctProvider?.imageURL}
              isSendingToProvider={false}
            />
          </Grid>
        </Grid>
      )}
    </MerchantSectionContainer>
  );
};

export default CreatePrimaryAccountHolder;
