import { Box, Stack, styled } from "@mui/material";
import { BankIconV2, HandCoinsIcon } from "@assets/icons/RebrandedIcons";
import { palette } from "@theme/colors";
import { Text } from "../Text";
import { useFormContext } from "react-hook-form";
import { isBoolean } from "lodash";
import { Button } from "@common/Button";

export const MerchantConditions = () => {
  const methods = useFormContext();
  const values = methods.watch();
  const switches = [
    {
      title: "Able to transfer",
      name: "canTransferMoney",
      description: "See merchants that can transfer funds",
      icon: <BankIconV2 height={24} width={24} color={palette.neutral[80]} />,
    },

    {
      title: "Able to process",
      name: "canProcessMoney",
      description: "See merchants that accept payments",
      icon: (
        <HandCoinsIcon height={24} width={24} color={palette.neutral[80]} />
      ),
    },
  ];

  return (
    <Stack gap="16px" pt="16px">
      {switches.map(({ title, name, description, icon }) => {
        return (
          <Stack
            key={description}
            direction="row"
            justifyContent="space-between"
            gap={2}
          >
            <Stack direction="row" gap={1}>
              <Box width={"24px"}>{icon}</Box>
              <Box>
                <Text color={palette.neutral[80]}>{title}</Text>
                <Text color={palette.neutral[70]}>{description}</Text>
              </Box>
            </Stack>
            <ToggleButton
              onYes={() => {
                methods.setValue(name, values[name] ? undefined : true);
              }}
              onNo={() => {
                methods.setValue(
                  name,
                  values[name] === false ? undefined : false,
                );
              }}
              value={values[name]}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

type ToggleButtonProps = {
  onYes(): void;
  onNo(): void;
  value: boolean | undefined;
};
const ToggleButton = ({ onYes, onNo, value }: ToggleButtonProps) => {
  const isYes = value === true;
  const isNo = value === false;
  return (
    <Stack direction="row" sx={{ borderCollapse: "collapse", width: "94px" }}>
      <BtnBox selected={isYes} onClick={onYes}>
        <Text {...(isYes && { color: "#fff" })}>Yes</Text>
      </BtnBox>
      <BtnBox selected={isNo} onClick={onNo}>
        <Text {...(isNo && { color: "#fff" })}>No</Text>
      </BtnBox>
    </Stack>
  );
};

export const MerchantConditionsEndElement = () => {
  const methods = useFormContext();
  const values = methods.watch();
  const { canProcessMoney, canTransferMoney } = values;
  const areButtonsUsed =
    isBoolean(canProcessMoney) || isBoolean(canTransferMoney);
  return (
    <Box height="20px">
      {areButtonsUsed && (
        <TertiaryButton
          onClick={() => {
            methods.setValue("canTransferMoney", undefined);
            methods.setValue("canProcessMoney", undefined);
          }}
        >
          <Text>Clear</Text>
        </TertiaryButton>
      )}
    </Box>
  );
};

const BtnBox = styled(Box)(({ selected }: { selected: boolean }) => ({
  height: "45px",
  verticalAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: "1px",
  borderStyle: "solid",
  boxSizing: "border-box",

  "&:nth-child(2)": {
    borderRadius: "0 8px 8px 0",
  },
  "&:nth-child(1)": {
    borderRadius: "8px 0 0 8px",
  },
  width: "50%",
  backgroundColor: selected ? palette.neutral[100] : palette.neutral[5],
  borderColor: selected ? "transparent" : palette.neutral[10],
  cursor: "pointer",
  "&:hover": {
    borderColor: selected ? "transparent" : palette.neutral[30],
  },
}));

export const TertiaryButton = styled(Button)(() => ({
  backgroundColor: "transparent",
  color: palette.neutral[70],
  fontWeight: 400,
  height: "fit-content",
  lineHeight: "16.8px",
  padding: "4px 8px",
  borderRadius: "8px",
  "&:hover": {
    textDecoration: "none",
    backgroundColor: palette.neutral[10],
  },
}));
