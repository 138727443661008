import { CustomIntegerInput } from "@common/CustomIntegerInput";
import { Text } from "@common/Text";
import { Stack, Box, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "@utils/index";
import useInputFieldFocusListener from "@common/FilePreview/hooks/useInputFieldFocusListener";

const desktopStyle = {
  color: {
    primary: palette.black[100],
    secondary: palette.gray[300],
  },
  lineHeight: "14px",
  fontSize: "14px",
};

const mobileStyle = {
  color: {
    primary: palette.neutral.white,
    secondary: palette.gray[100],
  },
  lineHeight: "16px",
  fontSize: "16px",
};

const getStyle = (isDesktop: boolean) =>
  isDesktop ? desktopStyle : mobileStyle;
type TStyle = ReturnType<typeof getStyle>;

const PaginationBar = (props: Omit<PageInputProps, "style">) => {
  const { isDesktopView } = useCustomTheme();
  const { numPages } = props;

  const style = getStyle(isDesktopView);
  const { color, ...rest } = style;

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      paddingBlock="12px"
      data-testid="preview-paginator"
    >
      <Text color={color["primary"]} {...rest}>
        Page
      </Text>
      <Box sx={{ display: "inline-flex", alignItems: "center", gap: "4px" }}>
        <PageInput {...props} style={style} />
        <Text color={color["primary"]} {...rest}>
          /
        </Text>
        <Text color={color["secondary"]} {...rest} paddingInline="6px">
          {numPages || "-"}
        </Text>
      </Box>
    </Stack>
  );
};

type PageInputProps = {
  page: number;
  onChange: (page: number) => void;
  onFocus?: (isFocused: boolean) => void;
  numPages: number;
  style: TStyle;
};

const PageInput = ({
  page,
  onChange,
  numPages,
  style,
  onFocus,
}: PageInputProps) => {
  const [value, setValue] = useState<string>("1");
  const inputRef = useRef<HTMLInputElement>(null);

  useInputFieldFocusListener({ inputRef, onFocus });

  useEffect(() => {
    setValue(page.toString());
  }, [page]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (isMobile && !!event.target.value)
      handleSubmit(event, event.target.value);
  };

  const handleSubmit = (
    event: React.FormEvent<HTMLDivElement>,
    mobileValue?: string,
  ) => {
    event.preventDefault();
    const sanitizedInput = parseInt(mobileValue || value);
    if (inputRef.current && !isMobile) {
      inputRef.current.blur();
    }
    if (Number.isNaN(sanitizedInput)) {
      onChange(1);
    } else if (sanitizedInput <= numPages) {
      onChange(sanitizedInput);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <StyledInput
        value={value}
        onChange={handleChange}
        inputRef={inputRef}
        min={1}
        max={numPages}
        data-testid="pagination-input"
        disabled={numPages === 1}
        style={style}
        inputProps={{
          inputMode: "numeric",
        }}
        containerProps={{
          sx: {
            width: "30px",
          },
        }}
      />
    </Box>
  );
};

const StyledInput = styled(CustomIntegerInput, {
  shouldForwardProp: (prop) => prop !== "style",
})<{ style: TStyle }>(({ theme, style: { color, ...rest } }) => ({
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    padding: "2px 6px",
    border: "none",
    backgroundColor: "inherit",
    borderRadius: "2px",

    "& input": {
      marginTop: 0,
      color: color["primary"],
      ...rest,
    },
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused": {
    backgroundColor: palette.liftedWhite.main,
  },

  [theme.breakpoints.down("sm")]: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: palette.gray[300],
    },
  },
}));

export default PaginationBar;
