import { shouldBeHidden } from "@constants/constants";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { selectEnterpriseConfiguration } from "@redux/slices/enterprise/enterpriseSettings";
import { checkPortals } from "@utils/routing";

export const useEnterprisePermissions = () => {
  const auth = useAppSelector(selectUser);
  const configuration = useAppSelector(selectEnterpriseConfiguration);
  const { enterpriseConfiguration } = shouldBeHidden;
  const { isMasqueradeMode } = useMasqueradeReducer();
  const { isEnterprisePortal } = checkPortals();

  const isMasqueradeEnterprise =
    isMasqueradeMode && auth?.role === "acquirer" && isEnterprisePortal;

  const canAccess =
    auth?.role === "provider" || isMasqueradeEnterprise
      ? {
          ...configuration,
          modify_merchant: enterpriseConfiguration
            ? true
            : configuration.modify_merchant,
          merchant_underwriting: enterpriseConfiguration
            ? false
            : configuration.merchant_underwriting,
          merchant_underwriting_statuses: enterpriseConfiguration
            ? false
            : configuration.merchant_underwriting_statuses,
          agreement_signing: enterpriseConfiguration
            ? true
            : configuration.agreement_signing,
          control_mode: enterpriseConfiguration
            ? true
            : configuration.control_mode,
          b_profile_linking: enterpriseConfiguration
            ? true
            : configuration.b_profile_linking,
          bank_account_linking: enterpriseConfiguration
            ? true
            : configuration.bank_account_linking,
          manage_bank_account: enterpriseConfiguration
            ? true
            : configuration.manage_bank_account,
          money_transfers: enterpriseConfiguration
            ? true
            : configuration.money_transfers,
          risk_monitoring: enterpriseConfiguration
            ? false
            : configuration.risk_monitoring,
          merchant_triggers: enterpriseConfiguration
            ? false
            : configuration.merchant_triggers,
          risk_activity: enterpriseConfiguration
            ? false
            : configuration.risk_activity,
        }
      : {
          modify_merchant: true,
          merchant_underwriting: true,
          merchant_underwriting_statuses: true,
          agreement_signing: true,
          control_mode: true,
          b_profile_linking: true,
          bank_account_linking: true,
          manage_bank_account: true,
          money_transfers: true,
          risk_monitoring: true,
          merchant_triggers: true,
          risk_activity: true,
        };

  return canAccess;
};
