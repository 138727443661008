// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";

import Popup from "@common/Popup";

const UnapprouvedBankAccountMessageModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <Popup
      {...muiDialogV5(modal)}
      sx={{
        "& .MuiDialogContent-root": {
          padding: "20px",
        },
        "& .MuiDialogActions-root": {
          padding: "15px",
          background: "transparent",
        },
      }}
      title={"No approved accounts"}
      onSubmit={() => modal.hide()}
      actionLabel="Okay"
      titleSx={{
        fontSize: "24px",
      }}
      submitButtonProps={{
        size: "medium",
        sx: {
          paddingX: "25px",
        },
      }}
      textContainerProps={{
        gap: "20px !important",
      }}
    >
      In order to send money, make sure this merchant has at least one approved
      bank account
    </Popup>
  );
});

export default UnapprouvedBankAccountMessageModal;
