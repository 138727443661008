import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { ReactNode } from "react";

const ComponentWithAnimation = ({
  children,
  shouldAnimate,
  index,
  id,
}: {
  children: ReactNode;
  shouldAnimate: boolean;
  index: number;
  id: number;
}) => {
  return shouldAnimate ? (
    <FadeUpWrapper delay={100 + 100 * (index + 1)} key={id}>
      {children}
    </FadeUpWrapper>
  ) : (
    <>{children}</>
  );
};

export default ComponentWithAnimation;
