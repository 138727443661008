import { TRenderer } from "../types";
import { showMessage } from "@common/Toast";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { MouseEventHandler, Suspense, useEffect } from "react";
import CustomImage from "../components/ImagePreviewer/CustomImage";
import { ZoomControls } from "../layout/Toolbar.atoms";
import useZoom from "./useZoom";
import { DotsMenuButton } from "../components/DotsMenuButton";

const useImagePreviewer: TRenderer = ({ file, onClose, handleOpenDrawer }) => {
  const { isMobileView } = useCustomTheme();
  const {
    zoom,
    onZoom,
    resetZoom,
    resetOriginalSize,
    disabled,
    onLoadImageSetSize,
    isImageBiggerThanScreen,
    isLoading,
    resetLoading,
  } = useZoom({
    minZoom: 1,
    maxZoomEqualsDeviceSize: true,
  });

  useEffect(() => {
    resetZoom();
    resetOriginalSize();
    resetLoading();
  }, [file]);

  const handleImageError = () => {
    showMessage("Error", "An error occurred while rendering the image");
    if (onClose) onClose();
  };

  const handleClickOnImage: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const toolbar = {
    primary: {
      content: <ZoomControls onZoom={onZoom} disabled={disabled} />,
      hidden: isMobileView,
    },
  };

  const slots = {
    ...(isMobileView && {
      Container: {
        sx: {
          paddingInline: 0,
        },
      },
    }),
    ContentWrapper: {
      sx: {
        overflowY: "hidden",
        display: "flex",
        width: "100%",
        height: "100%",
      },
    },
  };

  return {
    toolbar,
    onZoom,
    slots,
    ...(isMobileView && {
      headerActions: <DotsMenuButton onClick={handleOpenDrawer} />,
    }),

    Previewer: (
      <Suspense
        fallback={
          <LoadingSpinner sx={{ height: "50vh", marginInline: "auto" }} />
        }
      >
        {isLoading && (
          <LoadingSpinner sx={{ height: "50vh", marginInline: "auto" }} />
        )}
        <CustomImage
          zoom={zoom}
          file={file}
          onError={handleImageError}
          onLoad={onLoadImageSetSize}
          maxed={isImageBiggerThanScreen}
          onClick={handleClickOnImage}
          isLoading={isLoading}
        />
      </Suspense>
    ),
  };
};

export default useImagePreviewer;
