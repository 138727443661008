interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const RightIconButton = ({
  width = 24,
  height = 24,
  fill = "none",
}: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.5L20 12.5L14 18.5M5 12.5C9.74739 12.5 13.237 12.5 19 12.5"
        stroke="#727179"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightIconButton;
