import {
  TImageSize,
  addSizeToImage,
} from "@components/UploadAvatar/UploadAvatar";
import placeholder from "@assets/images/Placeholder.png";
import { useCallback } from "react";

type IMG_Handler_Type = {
  imageURL: string | undefined;
  size: TImageSize;
  fallbackSize?: TImageSize;
};

export const useImageSizeHandler = ({
  imageURL,
  size,
  fallbackSize = "original",
}: IMG_Handler_Type) => {
  // const mainImage = imageURL ? addSizeToImage(imageURL, size) : placeholder;
  // const originalImage = imageURL
  //   ? addSizeToImage(imageURL, fallbackSize)
  //   : null;

  const handleImageError = useCallback(
    ({ currentTarget }: { currentTarget: EventTarget & HTMLImageElement }) => {
      currentTarget.onerror = null;
      currentTarget.src = placeholder;
    },
    [],
  );

  return {
    mainImage: imageURL + "/" + size,
    handleImageError,
  };
};
