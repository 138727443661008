import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { RadioFormSection } from "./atoms";
import { formatFromUnixWithFormat } from "@utils/index";
import { toUnixDateFormat } from "@utils/date.helpers";
import {
  getLastMonthDetails,
  getLastWeekDetails,
  getMonthRange,
  getYesterdayDetails,
} from "../utils";
import { useFormContext } from "react-hook-form";
import { MouseEventHandler, useState } from "react";
import { ExportDateRangePickerPopUp } from "./ExportDateRangePickerPopUp";
import { Colum_types, CustomDateRange } from "../types";

const yesterdayTimestamp = toUnixDateFormat(new Date());
const { lastMonthName, lastDayNumber, firstDayNumber, prevMonth } =
  getLastMonthDetails();
const { yesterday, endOfYesterday, startOfYesterday } = getYesterdayDetails();
const {
  lastDayNumber: lastWeekDayNumber,
  firstDayNumber: firstWeekDayNumber,
  firstDayMonth,
  lastDayMonth,
  firstDayUnix,
  lastDayUnix,
} = getLastWeekDetails();
const ExportDateRangeSection = ({
  column_type,
  initialTimezone,
}: {
  column_type: Colum_types;
  initialTimezone: string;
}) => {
  const { setValue, watch } = useFormContext();
  const values = watch();
  const customStartDate = values.customDateRange.startDate;
  const customEndDate = values.customDateRange.endDate;

  const setCustomRange = ({
    timezone,
    startDate,
    endDate,
  }: CustomDateRange) => {
    setValue("customDateRange", { timezone, startDate, endDate });
  };

  const [rangePickerAnchorEl, setRangePickerAnchorEl] =
    useState<EventTarget | null>(null);

  const showCustomHelper =
    customStartDate &&
    customEndDate &&
    values.dateRange === "custom" &&
    !rangePickerAnchorEl;

  const handleCustomRange: MouseEventHandler<HTMLButtonElement> = (e) => {
    setRangePickerAnchorEl(e.target);
  };
  const isProcessingMonthly = values.columnsIncluded === "processing_monthly";

  const { startDate: currentMonthStartDate, endDate: currentMonthEndDate } =
    getMonthRange("current");
  const { startDate: prevMonthStartDate, endDate: prevMonthEndDate } =
    getMonthRange("prev");
  const isSettlement = column_type === "settlement";
  const types = [
    {
      title: "Current month",
      helper: `until ${formatFromUnixWithFormat(
        yesterdayTimestamp,
        "Do MMMM",
      )}`,
      value: "current_month",
      disabled: !isProcessingMonthly,
      onChange: () => {
        setCustomRange({
          timezone: initialTimezone,
          startDate: currentMonthStartDate,
          endDate: currentMonthEndDate,
        });
      },
      hidden: isSettlement,
    },
    {
      title: "Last month",
      helper: `${firstDayNumber} ${prevMonth} - ${lastDayNumber} ${lastMonthName}`,
      value: "last_month",
      disabled: !isProcessingMonthly,
      onChange: () =>
        setCustomRange({
          timezone: initialTimezone,
          startDate: prevMonthStartDate,
          endDate: prevMonthEndDate,
        }),
      hidden: isSettlement,
    },
    {
      title: "Yesterday",
      helper: formatFromUnixWithFormat(yesterday.unix(), "Do MMMM"),
      value: "yesterday",
      hidden: !isSettlement,
      onChange: () =>
        setCustomRange({
          timezone: initialTimezone,
          startDate: startOfYesterday.unix(),
          endDate: endOfYesterday.unix(),
        }),
    },
    {
      title: "Last week",
      helper: `${firstWeekDayNumber} ${firstDayMonth} - ${lastWeekDayNumber} ${lastDayMonth}`,
      value: "last_week",
      hidden: !isSettlement,
      onChange: () =>
        setCustomRange({
          timezone: initialTimezone,
          startDate: firstDayUnix,
          endDate: lastDayUnix,
        }),
    },
    {
      title: "Custom",
      helper: showCustomHelper
        ? `${formatFromUnixWithFormat(
            customStartDate,
            "Do MMMM YYYY",
          )} - ${formatFromUnixWithFormat(customEndDate, "Do MMMM YYYY")}`
        : "",
      value: "custom",
      onChange: handleCustomRange,
      disabled: !isProcessingMonthly,
    },
  ];

  return (
    <>
      <FadeUpWrapper delay={100}>
        <RadioFormSection
          title="Date Range"
          name="dateRange"
          radios={types.filter((item) => !item.hidden)}
        />
      </FadeUpWrapper>
      <ExportDateRangePickerPopUp
        setCustomRange={setCustomRange}
        anchorEl={rangePickerAnchorEl as HTMLInputElement}
        setAnchorEl={setRangePickerAnchorEl}
        initialTimezone={initialTimezone}
      />
    </>
  );
};

export default ExportDateRangeSection;
