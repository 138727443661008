import { useCallback, useEffect, useMemo } from "react";
import { MentionProps } from "react-mentions";
import ConversationsMentionsInput from "./MentionInput";
import { useMentions } from "../hooks/useMentions";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";
import { Box, Stack, styled } from "@mui/material";
import { Markup } from "interweave";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { ConversationFormInput } from "../hooks/useCreateMessage";
import { useUndeletableWord } from "../hooks/useUndeletableWord";
import { TMentionedUser } from "../utils/functions";

const HASHTAG_DETECTION_REGEX = /#[A-Za-z0-9.,%_-]+/g;

const replace = (value: string | undefined) => {
  if (!value) return "";

  const result = value.replace(
    /@\[([^[\]]{1,100})\]\(user:([^()]{1,50})\)/g,
    (match: any, display: string) => {
      return "@" + display.replace(/^__|__$/g, "");
    },
  );

  return result;
};

const ChatEditor: React.FC<
  Partial<MentionProps> & {
    mentionedUser?: TMentionedUser;
    name: string;
    onSubmit: SubmitHandler<ConversationFormInput>;
    defaultMessage?: string;
  }
> = ({ mentionedUser, name, onSubmit, defaultMessage }) => {
  const { control, setValue, watch } = useFormContext();
  const el = document.getElementById("mention-input") as HTMLTextAreaElement;

  const { keyDownExtender, autoFocus } = useUndeletableWord(
    el,
    replace(defaultMessage),
  );

  useEffect(() => {
    setValue(name, defaultMessage);
  }, [defaultMessage]);

  const builder = mentionedUser
    ? `@[__${mentionedUser.user}__](user:__${mentionedUser.authorAccID}__)`
    : "";

  const tagBuilder: undefined | string = builder;

  const { data, onSourceAdd } = useMentions(tagBuilder);

  const computedMentions = useMemo(() => {
    return data?.data.map((obj: any) => {
      const { firstName, lastName, accID, imageURL, email } = obj.user;
      const nameBuilder = `${firstName} ${lastName}`;
      return {
        id: `${accID}`,
        display: firstName ? nameBuilder : email,
        image: imageURL ? addSizeToImage(imageURL, "small") : AvatarPlaceholder,
      };
    });
  }, [data?.data]);

  const handleKeyUp = (event: any) => {
    const conversationInput = watch(name);
    if (event.key === "Enter" && !event.shiftKey && conversationInput) {
      event.preventDefault();
      onSubmit({ conversationInput });
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
    }

    if (defaultMessage) keyDownExtender(event);
  };

  const marksFormatter = useCallback((value: string) => {
    return replace(value).replace(HASHTAG_DETECTION_REGEX, "<mark>$&</mark>");
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onChange, ref: inputRef, ...rest } }) => {
        const result = marksFormatter(rest.value);
        return (
          <Stack direction="row" position={"relative"} flex={1}>
            <BackdropContainer>
              <Markup className="conversations-mark" content={result} />
            </BackdropContainer>
            <ConversationsMentionsInput
              onChange={(e) => {
                return onChange(e);
              }}
              inputRef={inputRef}
              shouldAutoFocus={autoFocus}
              data={computedMentions}
              onAdd={onSourceAdd}
              handleKeyUp={handleKeyUp}
              disabled={!computedMentions}
              handleKeyDown={handleKeyDown}
              {...rest}
            />
          </Stack>
        );
      }}
    />
  );
};

const BackdropContainer = styled(Box)(() => ({
  position: "absolute",
  width: "100%",
  top: 0,
  bottom: 0,
  zIndex: 1,
  overflow: "visible",
  pointerEvents: "none",
  transition: "transform 1s",

  fontSize: "14px",
  whiteSpace: "pre-wrap",
  color: "transparent",
  padding: "8px",
  fontWeight: 350,
  "& > *": {
    width: "100%",
    display: "block",
    wordBreak: "break-word",
    zIndex: 1,
    fontWeight: 350,
  },
  "& > span > mark": {
    backgroundColor: "transparent",
    zIndex: 1,
    fontWeight: 350,
    color: "#FF8124",
    textShadow: `0 0 1px ${"#FF8124"}`,
  },
}));

export default ChatEditor;
