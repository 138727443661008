import * as React from "react";
import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Select } from "@common/Select";
import CustomPagination from "./pagination/pagination";
import { Trailing } from "./trailing-element/trailing";
// types
import { Options } from "types/data.types";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
interface IPaginationprops {
  records?: number;
  centered?: boolean;
  totalRecords: number;
  noTrailing?: boolean;
  noDescription?: boolean;
  shortDescription?: boolean;
  onChange?: any;
  page?: number;
  currentRowsPerPage?: number;
  numberOfPages?: number;
  sumPageAmount?: number;
  totalAmount?: number;
  emptyBox?: boolean;
}

const Pagination: React.FC<IPaginationprops> = ({
  noTrailing,
  totalRecords,
  noDescription,
  shortDescription,
  onChange,
  centered = false,
  currentRowsPerPage,
  page,
  numberOfPages = 1,
  records = 1,
  sumPageAmount = 0,
  totalAmount = 0,
  emptyBox = true,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(namespaces.pages.manageMoney);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const mobileOptions = Array.from({ length: numberOfPages }, (_, i) => i + 1);
  const options = mobileOptions.map((page) => ({
    value: page,
    label: `${page}`,
  }));

  // for (let i = 0; i < numberOfPages; i++) {
  //   options.push({
  //     label: `${i + 1}`,
  //     value: `${i + 1}`,
  //   });
  // }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "auto",

        ...(centered && {
          flexDirection: "column-reverse",
        }),
      }}
    >
      {!noDescription && (
        <Box mt={centered ? 1 : 0}>
          <Text sx={{ fontSize: "14px" }} color={palette.neutral[800]}>
            {!shortDescription && `${t("showing")} `}
            <Box component="span">{currentRowsPerPage} </Box>

            {shortDescription ? "of " : `${t("records_of")} `}
            <Box component="span">{totalRecords}</Box>
          </Text>
        </Box>
      )}

      {isDesktop ? (
        <>
          <CustomPagination
            page={page}
            onChange={onChange}
            pages={numberOfPages}
          />

          {!centered ? (
            noTrailing ? (
              emptyBox ? (
                <Box width={200} />
              ) : null
            ) : (
              <Trailing pageTotal={sumPageAmount} total={totalAmount} />
            )
          ) : (
            <></>
          )}
        </>
      ) : (
        <Box ml="auto" gap={1} display="flex" alignItems="center">
          <Text fontWeight="medium">Page</Text>
          <Select
            name="pagination"
            width={74}
            value={page}
            size="small"
            options={options}
            onChange={(e) => onChange(e, e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default Pagination;
