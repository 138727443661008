import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import NiceModal from "@ebay/nice-modal-react";
import { useTransferActions } from "./hooks/useTransferActions";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";

type Props = {
  transactionId: string;
  customerID: number;
};

const ExpediteTransferModal = NiceModal.create(
  ({ transactionId, customerID }: Props) => {
    const { open, onClose, TransitionProps } = useNiceModal();
    const { handleExpediteTransfer, isLoading } =
      useTransferActions({
        customerID,
        transactionId,
      });

    return (
      <ModalFactory
        variant="popup"
        modalProps={{
          open,
          onClose,
          PopupProps: {
            width: "576px",
            paperStyle: { padding: "24px 24px 16px " },
            TransitionProps,
          },
        }}
      >
        <ModalTitle
          title="Confirm Transfer Expedite"
          closeButtonSize={22}
          onClose={onClose}
        />
        <FadeUpWrapper delay={100}>
          <StyledText>
            Are you sure you want to expedite this transfer? Expedited transfers
            are processed faster by being included in the next daily ACH file.
            This action cannot be undone.
          </StyledText>
        </FadeUpWrapper>
        <ModalActions
          containerProps={{ sx: { mt: "20px", gap: "16px" } }}
          primaryAction={{
            label: "Confirm",
            type: "button",
            onClick: handleExpediteTransfer,
            disabled: isLoading
          }}
          secondaryAction={{
            label: "Cancel",
            onClick: onClose,
            disabled: isLoading
          }}
        />
      </ModalFactory>
    );
  },
);

const StyledText = styled(Text)(() => ({
  color: palette.neutral[70],
  fontFamily: "Give Whyte",
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: 0,
  marginTop: "8px",
  marginBottom: "0px",
}));

export default ExpediteTransferModal;
