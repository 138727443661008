import useDrawerReducer from "@hooks/Reducers/useDrawerReducer";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

export const useDrawer = () => {
  const { isMobileView } = useCustomTheme();
  const { setDrawerOpenState, setMobileDrawerOpenState, drawerOpen } =
    useDrawerReducer();

  const toggleDrawer = () => {
    if (isMobileView) setMobileDrawerOpenState();
    else setDrawerOpenState();
  };

  return {
    toggleDrawer,
    drawerOpen,
  };
};
