import NiceModal from "@ebay/nice-modal-react";
import { useCheckEnterprises } from "@hooks/acquirer-api/enteprises/useCheckEnterprises";
import {
  CREATE_MERCHANT_PANEL_MODAL,
  ENTERPRISE_NOT_AVAILABLE_MODAL,
} from "modals/modal_names";
import { useLocation } from "react-router-dom";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

export function useCreateMerchantActions() {
  const { pathname } = useLocation();
  const isAcquirer = pathname.includes("acquirer");
  const {
    total: enterprisesTotal,
    isError,
    isLoading,
    isFetching,
  } = useCheckEnterprises();

  const isCreateMerchantAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.CREATE,
  });

  const handleClickCreateMerchant = () => {
    if (!isAcquirer) {
      NiceModal.show(CREATE_MERCHANT_PANEL_MODAL);
      return;
    }
    if (isError) return;

    if (enterprisesTotal >= 1) {
      NiceModal.show(CREATE_MERCHANT_PANEL_MODAL);
    } else {
      NiceModal.show(ENTERPRISE_NOT_AVAILABLE_MODAL);
    }
  };

  return {
    handleClickCreateMerchant,
    isLoading,
    isAcquirer,
    isFetching,
    isCreateMerchantAllowed,
  };
}
