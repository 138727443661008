import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/styles";
import { palette } from "@palette";
import { IInputProps } from "react-dropzone-uploader";
import { IdentificationCard } from "@assets/icons/RebrandedIcons";
import { UploaderState } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderMachine";
import PAHUploaderActions from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderActions";
import { showMessage } from "@common/Toast";

type Props = IInputProps & {
  state: UploaderState;
  fileUrl: string;
  fileName?: string;
  isMobile?: boolean;
  downloadHandler: () => Promise<void>;
  deleteDocument: () => void;
  previewDocument: () => void;
  disabled?: boolean;
};

export const BusinessOwnerIDInput = ({
  accept,
  fileUrl,
  isMobile,
  getFilesFromEvent,
  state,
  disabled,
  onFiles,
  downloadHandler,
  deleteDocument,
  previewDocument,
}: Props) => {
  const onClick = () => {
    if (disabled) return;
    return previewDocument();
  };

  return (
    <Stack
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      onClick={state === UploaderState.ON_UPLOADED ? onClick : undefined}
      sx={{
        height: "100%",
        borderRadius: "12px",
        pointerEvents: "initial",
        ".pah-uploader-actions": {
          opacity: 1,
        },
      }}
    >
      <Stack
        height="100%"
        width="100%"
        component="label"
        alignItems="center"
        justifyContent="center"
        sx={{
          cursor: "pointer",
        }}
      >
        {state === UploaderState.ON_UPLOADED && (
          <PAHUploaderActions
            disabled={{
              delete: disabled,
              preview: disabled,
              download: disabled,
            }}
            type={"businessOwnerID"}
            downloadHandler={downloadHandler}
            deleteDocument={deleteDocument}
            previewDocument={() => previewDocument()}
          />
        )}
        <StyledContainer>
          <Stack spacing={0.5} justifyContent="center" alignItems="center">
            {state !== UploaderState.ON_UPLOADED && (
              <>
                <IdentificationCard fill={palette.neutral[70]} />
                <Text color={palette.neutral[70]}>Business Owner ID </Text>
              </>
            )}
            {state === UploaderState.ON_INITIAL && (
              <Stack direction="row" gap="4px">
                {!isMobile && (
                  <Text color={palette.neutral[70]} fontSize="12px">
                    Drag and drop your files or
                  </Text>
                )}
                <Text color="accent.3" fontSize="12px">
                  {!isMobile ? "Click to browse" : "Tap to browse"}
                </Text>
              </Stack>
            )}
          </Stack>
        </StyledContainer>
        {state !== UploaderState.ON_UPLOADED && (
          <input
            disabled={
              Boolean(fileUrl) || state !== UploaderState.ON_INITIAL || disabled
            }
            type="file"
            id="upload"
            accept={accept}
            style={{ display: "none" }}
            onChange={async (e) => {
              const chosenFiles = await getFilesFromEvent(e);
              const validatedFiles = chosenFiles.filter(
                (file) => file.name.length < 254,
              );
              if (validatedFiles.length < chosenFiles.length) {
                showMessage("Error", "File name too long");
              }
              if (validatedFiles.length > 0) {
                onFiles(validatedFiles);
              }
              (e.target as any).value = null;
            }}
            data-testid="bo-id-upload-input"
          />
        )}
      </Stack>
    </Stack>
  );
};

const StyledContainer = styled(Box)({
  textAlign: "center",
});
