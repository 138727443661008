import { SearchIcon } from "@assets/rebrandIcons";
import { Input } from "@mui/material";
import { styled } from "@mui/material";
import { palette } from "@palette";

type MCCSearchInputProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const MCCSearchInput = ({ value, onChange }: MCCSearchInputProps) => {
  return (
    <StyledInput
      disableUnderline
      startAdornment={
        <SearchIcon width={24} height={24} stroke={palette.gray[200]} />
      }
      onChange={onChange}
      value={value}
    />
  );
};

export default MCCSearchInput;

const StyledInput = styled(Input)(({ theme }) => ({
  background: palette.background.dimmedWhite,
  border: `1px solid ${palette.neutral[30]}`,
  borderRadius: "90px",
  width: "250px",
  padding: "4px 12px",

  "& input": {
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 350,
    color: palette.black[300],
    padding: 0,
    height: "24px",
    marginInline: "4px",
  },
  "& input::-webkit-input-placeholder": {
    color: palette.gray[100],
  },
  "& input:placeholder-shown ~ div": {
    display: "none",
  },
  "&:has(input:focus)": {
    boxSizing: "border-box",
    outline: `none`,
  },
  "&:has(.Mui-disabled)": {
    background: palette.neutral[10],
    border: "none",
    outline: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "& input": {
      fontSize: "16px",
      lineHeight: "19.2px",
      height: "32px",
    },
  },
}));
