type ArrowDownSquareProps = {
  height?: number;
  width?: number;
  fill?: string;
  className?: string;
};

const ArrowDownSquare = ({
  fill = "#6D9CF8",
  width = 19,
  height = 19,
  className,
}: ArrowDownSquareProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0.591797H2C1.60218 0.591797 1.22064 0.749832 0.93934 1.03114C0.658035 1.31244 0.5 1.69397 0.5 2.0918V17.0918C0.5 17.4896 0.658035 17.8712 0.93934 18.1525C1.22064 18.4338 1.60218 18.5918 2 18.5918H17C17.3978 18.5918 17.7794 18.4338 18.0607 18.1525C18.342 17.8712 18.5 17.4896 18.5 17.0918V2.0918C18.5 1.69397 18.342 1.31244 18.0607 1.03114C17.7794 0.749832 17.3978 0.591797 17 0.591797ZM13.0306 10.8724L10.0306 13.8724C9.96097 13.9422 9.87825 13.9975 9.7872 14.0352C9.69616 14.073 9.59856 14.0924 9.5 14.0924C9.40144 14.0924 9.30384 14.073 9.2128 14.0352C9.12175 13.9975 9.03903 13.9422 8.96937 13.8724L5.96937 10.8724C5.82864 10.7317 5.74958 10.5408 5.74958 10.3418C5.74958 10.1428 5.82864 9.9519 5.96937 9.81117C6.11011 9.67044 6.30098 9.59138 6.5 9.59138C6.69902 9.59138 6.88989 9.67044 7.03063 9.81117L8.75 11.5315V5.8418C8.75 5.64288 8.82902 5.45212 8.96967 5.31147C9.11032 5.17081 9.30109 5.0918 9.5 5.0918C9.69891 5.0918 9.88968 5.17081 10.0303 5.31147C10.171 5.45212 10.25 5.64288 10.25 5.8418V11.5315L11.9694 9.81117C12.1101 9.67044 12.301 9.59138 12.5 9.59138C12.699 9.59138 12.8899 9.67044 13.0306 9.81117C13.1714 9.9519 13.2504 10.1428 13.2504 10.3418C13.2504 10.5408 13.1714 10.7317 13.0306 10.8724Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowDownSquare;
