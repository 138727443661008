import { QKEY_ALERTS_LIST } from "@constants/queryKeys";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { UseQueryOptions, useMutation, useQuery } from "react-query";
import { useModal } from "@ebay/nice-modal-react";
import { groupAlerts } from "../utils/groupAlerts";

const useGetAlerts = (
  queryOptions: Omit<
    UseQueryOptions<any, unknown, any, any>,
    "queryKey" | "queryFn"
  >,
) => {
  const { selectedUser } = useGetCurrentMerchantId();
  const user = useUserReducer();
  const modal = useModal();

  const merchantId = selectedUser?.id;
  const userId = user?.userAccID;

  const { data, isLoading } = useQuery(
    [QKEY_ALERTS_LIST, merchantId, userId],
    async () => {
      const res = await customInstance({
        url: `/accounts/${merchantId}/members/${userId}/alerts`,
      });
      const data = groupAlerts(res?.data || []);
      return data;
    },
    {
      enabled: !!merchantId && !!userId && modal.visible,
      ...queryOptions,
    },
  );

  const alertsMutation = useMutation((data: any) => {
    return customInstance({
      url: `/accounts/${merchantId}/members/${userId}/alerts`,
      method: "PATCH",
      data,
    });
  });

  return {
    data,
    isLoading,
    alertsMutation,
  };
};

export default useGetAlerts;
