import { Box, SxProps } from "@mui/material";
import React from "react";
import { palette } from "@palette";
import { Text } from "../Text";

type KeyValProps = {
  keyName: React.ReactNode;
  value: React.ReactNode;
  keyProps?: any;
  valProps?: any;
  textVariant?: boolean;
  isAmount?: boolean;
  sx?: SxProps;
  fontWeight400?: boolean;
  mobileView?: boolean;
  vertical?: boolean;
  align?: "right" | "left";
};

export const KeyVal: React.FC<KeyValProps> = ({
  keyName,
  value,
  keyProps,
  valProps,
  textVariant,
  isAmount,
  mobileView,
  align,
  vertical,
  sx,
}) => {
  return (
    <Box
      className="keyVal-container"
      sx={{
        textAlign: align,
        flexDirection: vertical ? "column" : "row",
        ...(mobileView && {
          display: "flex",
          justifyContent: "space-between",
          padding: "12px 8px",
          width: "100%",
        }),
        ...(sx && sx),
      }}
    >
      {typeof keyName === "string" || typeof keyName === "number" ? (
        <Text
          className="keyVal-key"
          fontWeight="light"
          fontSize="12px"
          color={palette.neutral[70]}
          {...keyProps}
        >
          {keyName}
        </Text>
      ) : (
        <>{keyName}</>
      )}

      {typeof value === "string" || typeof value === "number" ? (
        <Text
          className="keyVal-value"
          variant="body"
          fontSize="14px"
          minHeight="19.6px"
          fontWeight="book"
          textTransform={keyName !== "Email" ? "capitalize" : undefined}
          color={textVariant && palette.neutral[800]}
          {...valProps}
        >
          {value} {isAmount && "USD"}
        </Text>
      ) : (
        <>{value}</>
      )}
    </Box>
  );
};
