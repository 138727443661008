import { useEffect } from "react";
import { InfiniteData, useQueryClient } from "react-query";
import { useGetCurrentMerchantId } from "@hooks/common";
import { QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";

export const useUpdateSponsorTable = ({
  unreadNotifications,
}: {
  unreadNotifications?: InfiniteData<any>;
}) => {
  const bulkStatusUpdateNotificationNames = [
    "bulk_sponsor_approve",
    "error_bulk_sponsor_decline",
    "error_bulk_sponsor_approve",
    "bulk_sponsor_decline",
  ];
  const queryClient = useQueryClient();

  const { merchantId } = useGetCurrentMerchantId();

  useEffect(() => {
    if (!unreadNotifications) return;
    const list =
      unreadNotifications?.pages?.map((row: any) => row.data).flat() ?? [];
    const bulkUpdateNotificationsExists = list.some((item) =>
      bulkStatusUpdateNotificationNames.includes(item.alertName),
    );
    const cachedQueryData: any = queryClient
      .getQueryCache()
      .find(["get-last-background-task", merchantId])?.state?.data;

    const shouldRefetchMerchants =
      cachedQueryData?.total > 0 && bulkUpdateNotificationsExists;
    if (shouldRefetchMerchants) {
      // merchants and stats are refetched when FE receives notifications of bulk status update
      queryClient.refetchQueries("list-acquirer-merchants");
      queryClient.removeQueries("get-last-background-task");
      queryClient.refetchQueries(QKEY_LIST_MERCHANT_STATS);
    }
  }, [unreadNotifications]);
};
