import RHFSelect from "@common/Select/RHFSelect";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, Grid } from "@mui/material";

import TimeZoneSelector from "@common/TimeZoneSelector/TimeZoneSelector";
function AccessibilityTab() {
  const nodeList = [
    {
      node: (
        <RHFSelect
          name="currency"
          label={"Currency"}
          options={[
            {
              value: "usd",
              label: "US Dollar (USD)",
            },
          ]}
        />
      ),
      sm: 12,
      md: 12,
    },
    {
      node: (
        <RHFSelect
          name="language"
          label={"Language"}
          options={[
            {
              value: "eng",
              label: "English",
            },
          ]}
        />
      ),
      sm: 12,
      md: 12,
    },
    {
      node: <TimeZoneSelector fullWidth={true} />,
      sm: 12,
      md: 12,
    },
  ];
  return (
    <Box>
      <Grid mt={3} container rowSpacing={2} columnSpacing={3}>
        {nodeList.map(({ node, md, sm }, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={sm || 6}
            md={md || sm || 6}
            lg={md || sm || 6}
          >
            <FadeUpWrapper delay={100 + 50 * (index + 1)}>{node}</FadeUpWrapper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default AccessibilityTab;
