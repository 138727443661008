export const borderStyles = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "solid",
    label: "Solid",
  },
  {
    value: "dotted",
    label: "Dot",
  },
  {
    value: "dashed",
    label: "Dash",
  },
  {
    value: "double",
    label: "Double",
  },
];

export const imageTypes = [
  {
    value: "fill",
    label: "Fill",
  },
  {
    value: "fit",
    label: "Fit",
  },
  {
    value: "cover",
    label: "Cover",
  },
];

export const alignments = {
  topLeft: {
    value: "topLeft",
    label: "Top Left",
  },
  topCenter: {
    value: "topCenter",
    label: "Top Center",
  },
  topRight: {
    value: "topRight",
    label: "Top Right",
  },
  left: {
    value: "left",
    label: "Left",
  },
  center: {
    value: "center",
    label: "Center",
  },
  right: {
    value: "right",
    label: "Right",
  },
  bottomLeft: {
    value: "bottomLeft",
    label: "Bottom Left",
  },
  bottomCenter: {
    value: "bottomCenter",
    label: "Bottom Center",
  },
  bottomRight: {
    value: "bottomRight",
    label: "Bottom Right",
  },
};

export const mapValueToNameKey = {
  solid: "Solid",
  dotted: "Dot",
  dashed: "Dash",
  double: "Double",
  none: "None",
};

export const alignementsArr = Object.values(alignments);
