import { SweepstakeData } from "@components/Sweepstakes/MiniBuilder/BuilderFormProvider";
import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import { useMemo } from "react";
import { useQuery } from "react-query";

const getSweepstakeVariants = (id?: string, publicResource?: boolean) => {
  if (!id) return;
  const publicUrl = `products/${id}/variants?&sort=displayOrder`;
  return customInstance({
    url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
    method: "GET",
  });
};

const getSweepstake = (id?: string, publicResource?: boolean) => {
  if (!id) return;
  const publicUrl = `products/${id}?filter=typeName:"sweepstake"`;
  return customInstance({
    url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
    method: "GET",
  });
};

export const useFindSweepstakeById = (
  id?: string,
  publicResource?: boolean,
) => {
  const { data, error, isLoading } = useQuery(
    ["find-sweepstake-by-id", id],
    async () => {
      const sweepstake = await getSweepstake(id, publicResource);
      const variants = await getSweepstakeVariants(id, publicResource);
      return { sweepstake, variants };
    },
    { cacheTime: 0 },
  );

  const customTickets = useMemo(() => {
    if (!data?.variants) return [];
    return (
      data.variants.data?.map((ticket: any) => ({
        id: ticket.id,
        name: ticket.name,
        amount: (ticket.price / 100).toFixed(2),
        description: {
          enabled: Boolean(ticket.description),
          text: stringToEditorState(ticket.description),
        },
        thumbnail: ticket.imageURL ? ticket.imageURL : "",
        active: ticket.isEnabled,
        in_stock: ticket.inventory,
        display_tickets: ticket.showAvailableVariants ?? false,
        bundle: 1,
      })) ?? []
    );
  }, [data?.variants?.data]);

  const customData: SweepstakeData = useMemo(() => {
    return {
      general: {
        title: data?.sweepstake?.name || "",
        description: data?.sweepstake?.description || "",
        browseMore: data?.sweepstake?.canBrowseCampaigns || false,
        date: data?.sweepstake?.endsAt
          ? new Date(data?.sweepstake?.endsAt * 1000)
          : new Date(),
        creatorName: data?.sweepstake?.merchantName,
        creatorImage: data?.sweepstake?.merchantImageURL,
        creatorDescription: data?.sweepstake?.merchantDescription,
        featuredImage: {
          image: data?.sweepstake?.imageURL || "",
          active: Boolean(data?.sweepstake?.imageURL),
          useAsBackground: data?.sweepstake?.usesBackgroundImage || false,
        },
      },
      tickets: {
        customer_pays_credit: {
          active: data?.sweepstake?.needsTax || false,
          optional: data?.sweepstake?.allowFeeChoice || false,
        },
        multiple_winners: data?.sweepstake?.allowMultipleWinners || false,
        set_entries_limit: {
          active: Boolean(data?.sweepstake?.maxEntriesPerPrize) || false,
          max_entries: data?.sweepstake?.maxEntriesPerPrize || 0,
        },
        max_tickets: parseInt(data?.sweepstake?.maxPurchaseQuantity || 5),
        list: customTickets || [],
      },
      accID: data?.sweepstake?.accID,
    };
  }, [data?.sweepstake]);

  if (!id) return { data: undefined };
  return {
    data: customData,
    isLoading,
    redirect: (error as any)?.response?.status === 404 ? true : false,
  };
};
