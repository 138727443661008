import React from "react";

type UserFillIconProps = {
  height?: string | number;
  width?: string | number;
  color?: string;
};

const UserFillIcon = ({
  color = "#9C9AA3",
  height = 24,
  width = 25,
}: UserFillIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0837 18.8876C18.5939 18.7933 18.8912 18.2533 18.5939 17.828C18.1044 17.1277 17.382 16.5118 16.4781 16.0332C15.2124 15.3632 13.6617 15 12.0664 15C10.4711 15 8.92039 15.3632 7.65475 16.0332C6.75086 16.5118 6.02845 17.1277 5.53893 17.828C5.24164 18.2533 5.53889 18.7933 6.04915 18.8876C10.027 19.623 14.1059 19.623 18.0837 18.8876Z"
        fill={color}
      />
      <circle cx="12.0664" cy="9" r="4" fill={color} />
    </svg>
  );
};

export default UserFillIcon;
