import { Box, Stack, useTheme } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { useMediaQuery } from "@mui/material";
import { LoginExternalLink } from "@components/Login";
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "@constants/constants";
import useCheckEnvironment from "@hooks/common/useCheckEnvironment";

const CUSTOM_BREAKPOINT = 1290;

const PublicFormFooter = ({
  isPeekMode,
  customStyle,
  isDisabled,
  isDesktopDeviceType,
}: {
  isPeekMode?: boolean;
  customStyle?: any;
  isDisabled?: boolean | undefined;
  isDesktopDeviceType?: boolean;
}) => {
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up("sm"));
  const isMaxedWidth = useMediaQuery(theme.breakpoints.up(CUSTOM_BREAKPOINT));
  const isDesktop = isDesktopDeviceType ?? isDesktopView;
  const { platformBaseUrl } = useCheckEnvironment();

  return (
    <Box
      sx={{
        backgroundColor: customStyle?.bgColor || "#F8F8F6",
        width: "100%",
        maxWidth: customStyle?.maxWidth || "1232px",
        margin: "0 auto",
        padding: customStyle?.padding || "8px 16px",
      }}
    >
      <Stack
        sx={{
          maxWidth: "1280px",
          margin: "0 auto",
          textAlign: "center",
          padding: 2,
          ...(isMaxedWidth &&
            !isPeekMode && {
              paddingLeft: 0,
              paddingRight: 0,
            }),
          // width: "100%",
          pointerEvents: "auto",
          ...(isPeekMode && { zIndex: 1 }),
        }}
        direction={isDesktop ? "row" : "column-reverse"}
        justifyContent={isDesktop ? "space-between" : "center"}
        alignItems="center"
        gap={2}
      >
        <Text
          color={palette.neutral[600]}
          sx={{
            fontSize: customStyle?.fontSize || undefined,
          }}
        >
          All rights reserved ©Give Corporation {new Date().getFullYear()}
        </Text>
        <Stack direction="row" justifyContent={"space-evenly"} gap={2}>
          <LoginExternalLink
            underline={palette.neutral[600]}
            href={PRIVACY_POLICY_URL}
            isDisabled={isDisabled}
          >
            <Text
              color={palette.neutral[600]}
              sx={{
                fontSize: customStyle?.fontSize || undefined,
              }}
            >
              Privacy Policy
            </Text>
          </LoginExternalLink>
          <LoginExternalLink
            underline={palette.neutral[600]}
            href={`${platformBaseUrl}${TERMS_OF_SERVICE_URL}`}
            isDisabled={isDisabled}
          >
            <Text
              color={palette.neutral[600]}
              sx={{
                fontSize: customStyle?.fontSize || undefined,
              }}
            >
              Terms & Conditions
            </Text>
          </LoginExternalLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PublicFormFooter;
