import { capitalizeFirstLetter } from "@common/Table/helpers";
import { calculateFeePerTransaction } from "@components/Merchants/MerchantPreview/components/MerchantFees";
import {
  IMerchantBankAccount,
  IParsedData,
  TBusinessAddressInfo,
  TBusinessOwner,
} from "@components/Merchants/MerchantPreview/data.types";
import {
  parsePhoneNumber,
  parseTaxID,
} from "@components/Merchants/MerchantPreview/hooks/useGetSectionItems";
import { formatAddress } from "@utils/helpers";
import { parseAmount } from "@utils/index";
import moment from "moment";

export const useGetPdfSections = (data: IParsedData) => {
  const businessOwnersTotal = data?.businessOwnersList?.length;
  const approvedBankAccounts = data?.bankAccountList?.filter(
    (account) => account.status === "approved",
  );
  const totalApprovedBankAccount = approvedBankAccounts?.length;

  const approvedBankAccountsData = getPdfBankAccountsData(approvedBankAccounts);

  const businessOwnersData = getPdfBusinessOwners(
    data?.businessOwnersList,
    data?.businessAddress,
  );

  const merchantInfoSection = [
    {
      title: "merchant info",
      details: [
        {
          label: "Merchant Name",
          value: data?.merchantInfo?.merchantName,
          width: "50%",
        },
        {
          label: "Merchant Category",
          value: `${data?.merchantInfo?.category} - ${data?.merchantInfo?.categoryCodeName}`,
          width: "50%",
        },
        {
          label: "Billing Descriptor",
          value: data?.merchantInfo?.billingDescriptor,
          width: "50%",
        },
        {
          label: "Website URL",
          value:
            data?.merchantInfo && `https://${data?.merchantInfo?.websiteURL}`,
          width: "50%",
        },
        {
          label: "Estimated Annual Revenue",
          value:
            (data?.merchantInfo?.estimatedAnnualRevenue
              ? (+data?.merchantInfo?.estimatedAnnualRevenue).toLocaleString()
              : "0") + " USD",
          width: "50%",
        },
        {
          label: "High Ticket Amount",
          value:
            data?.merchantInfo?.highTicketAmount &&
            parseAmount(+data?.merchantInfo?.highTicketAmount) + " USD",
          width: "50%",
        },
        {
          label: "Average Ticket Amount",
          value:
            data?.merchantInfo?.averageTicketAmount &&
            parseAmount(+data?.merchantInfo?.averageTicketAmount) + " USD",
          width: "50%",
        },
        {
          label: "Customer Service",
          value: parsePhoneNumber(data?.merchantInfo?.servicePhoneNumber),
          width: "50%",
        },
        {
          label: "Countries Serviced Outside the USA/Canada",
          value: data?.merchantInfo?.countriesServicedOutside
            ? data?.merchantInfo?.countriesServicedOutside
            : "None",
          width: "100%",
        },
        {
          label: "Purpose or Mission of Merchant",
          value:
            data?.merchantInfo?.businessPurpose ||
            data?.merchantInfo?.description,
          width: "100%",
        },
      ],
    },
  ];

  const acceptanceSection = [
    {
      title: "acceptance",
      details: [
        {
          label: "Bank Disclosure",
          value: [
            "RS2 Software INC.\n4643 S Ulster St Ste 1285 Denver,\nCO,80237-3090 United States\nPhone: 866-791-5950\nEmail: rs2usa-ops@rs2.com",
            "Chesapeake Bank\n97 N. Main Street\nKilmarnock, VA 22482\nPhone: 866-941-9640\nEmail: ISO@ches.bank",
          ],
          width: "50%",
        },
        {
          label: "Card Type",
          value: "Visa, MasterCard, Discover, AMEX",
          width: "50%",
        },
        {
          label: "Refund Policy",
          value: data?.merchantAgreement?.msaRefundPolicy,
          width: "100%",
        },
        {
          label: "PCI Compliance",
          value:
            "By checking this checkbox, you confirm that your business adheres to the Payment Card Industry Data Security Standard (PCI DSS) requirements and is PCI compliant. If you are unsure, complete the Self-Assessment Questionnaire A and Attestation of Compliance at https://listings.pcisecuritystandards.org/documents/SAQ_A_v3.pdf to ensure your business meets the PCI DSS standards.",
          width: "100%",
          hasCheckbox: true,
          checked: data?.merchantAgreement?.msaPCICompliant,
        },
        {
          label: "Previous Termination Disclosure",
          value:
            "I hereby disclose that my previous merchant account(s) have been terminated or are currently subject to termination proceedings. I understand that providing false information or failing to disclose a prior account termination can result in the rejection of this application or subsequent termination of any account granted based on this application.",
          width: "100%",
          hasCheckbox: true,
          checked: data?.merchantAgreement?.msaPreviousTermination,
        },
      ],
    },
  ];

  const businessProfileSection = [
    {
      title: "Business profile",
      details: [
        {
          label: "Business Legal Name",
          value: data?.businessProfile?.legalName,
          width: "100%",
        },
        {
          label: "DBA (Doing Business As)",
          value: data?.businessProfile?.dba,
          width: "100%",
        },
        {
          label: "Business Type",
          value: capitalizeFirstLetter(
            data?.businessProfile?.businessType as string,
          ),
          width: "50%",
        },
        {
          label: "Business Ownership Type",
          value: capitalizeFirstLetter(
            data?.businessProfile?.ownershipType as string,
          ),
          width: "50%",
        },
        {
          label: "Tax ID",
          value: parseTaxID(data?.businessProfile?.taxID as string),
          width: "100%",
          direction: "row",
        },
        {
          label: "Contact Phone",
          value: parsePhoneNumber(data?.businessProfile?.contactPhone),
          width: "100%",
          direction: "row",
        },
        {
          label: "Business Address",
          value: formatAddress(data?.businessAddress),
          width: "100%",
        },
      ],
    },
  ];

  const PAHSection = [
    {
      title: "Primary Account Holder",
      details: [
        {
          label: "Full name",
          value:
            data?.primaryAccountHolder &&
            `${data?.primaryAccountHolder?.firstName || ""} ${
              data?.primaryAccountHolder?.lastName || ""
            }`,
          width: "50%",
          direction: "row",
        },
        {
          label: "Email",
          value: data?.primaryAccountHolder?.email,
          width: "50%",
          direction: "row",
        },
        {
          label: "Ownership",
          value: "0%",
          width: "50%",
          direction: "row",
        },
        {
          label: "Tax ID",
          value: data?.primaryAccountHolder?.ssnLast4
            ? `xxxxx${data?.primaryAccountHolder?.ssnLast4}`
            : "",
          width: "50%",
          direction: "row",
        },
        {
          label: "Date of Birth",
          value: data?.primaryAccountHolder?.dateOfBirth
            ? moment
                .unix(data?.primaryAccountHolder?.dateOfBirth?.toString())
                .format("MMMM DD YYYY")
            : "",
          width: "50%",
          direction: "row",
        },
        {
          label: "Contact Phone",
          value: data?.primaryAccountHolder?.phoneNumber,
          width: "50%",
          direction: "row",
        },
        {
          label: "Address",
          value: formatAddress(data?.primaryAccountHolder?.address),
          width: "50%",
        },
      ],
    },
  ];

  const fees = [
    {
      subTitle: "Transaction Fees",
      data: [
        {
          label: "Credit Card Transaction",
          value: `${calculateFeePerTransaction(
            data?.fees?.creditCardFee,
          )} per transaction`,
          width: "50%",
        },
        {
          label: "Debit Card Transaction",
          value: `${calculateFeePerTransaction(
            data?.fees?.debitCardFee,
          )} per transaction`,
          width: "50%",
        },
        {
          label: "AMEX Transaction",
          value: `${calculateFeePerTransaction(
            data?.fees?.amexCreditCardFee,
          )} per transaction`,
          width: "100%",
          direction: "row",
        },
      ],
    },
    {
      subTitle: "Monthly Account Fees",
      data: [
        {
          label: "Monthly Account",
          value: "Monthly Account Fees",
          width: "100%",
          direction: "row",
        },
      ],
    },
    {
      subTitle: "Gateway Access Fees",
      data: [
        {
          label: "Gateway Access",
          value: "$0.00 per month",
          width: "50%",
        },
        {
          label: "Per Transaction Gateway",
          value: "$0.00 per transaction",
          width: "50%",
        },
      ],
    },
  ];

  const pdfChargebackFees = [
    {
      subTitle: "Chargeback Fees",
      data: [
        {
          label: "Chargeback",
          value: "$15.00 per chargeback",
          width: "100%",
          direction: "row",
        },
      ],
    },
    {
      subTitle: "Miscellaneous Fees",
      data: [
        {
          label: "Setup",
          value: "$0.00",
          width: "100%",
          direction: "row",
        },
        {
          label: "PCI Compliance",
          value: "$0.00 per year",
          width: "100%",
          direction: "row",
        },
        {
          label: "Early Termination",
          value: "$0.00",
          width: "100%",
          direction: "row",
        },
      ],
    },
  ];

  const businessOwnerSection =
    businessOwnersTotal > 1
      ? [
          {
            title: "business Owner(s)",
            details: businessOwnersData?.map((owner, index) => ({
              subTitle: `Business Owner ${index + 1}`,
              data: owner,
            })),
          },
        ]
      : [
          {
            title: "business Owner(s)",
            details: getPdfBusinessOwner(
              data?.businessOwnersList?.[0],
              data?.businessAddress,
            ),
          },
        ];

  const feeSection = [
    {
      title: "fee schedule",
      details:
        businessOwnersTotal > 1 || totalApprovedBankAccount > 1
          ? [...fees, ...pdfChargebackFees]
          : pdfChargebackFees,
    },
  ];

  const settlementAccountsSection =
    totalApprovedBankAccount > 1
      ? [
          {
            title: "settlement account",
            details: approvedBankAccountsData?.map((acc, index) => ({
              subTitle: `Bank Account ${index + 1}`,
              data: acc,
            })),
          },
        ]
      : [
          {
            title: "settlement account",
            details:
              approvedBankAccounts && approvedBankAccounts?.length > 0
                ? getPdfBankAccountData(approvedBankAccounts[0])
                : [],
          },
        ];

  const firstPage = [
    ...merchantInfoSection,
    ...acceptanceSection,
    ...(totalApprovedBankAccount === 1 ? settlementAccountsSection : []),
  ];

  const secondPage = [
    //if we have more bank account we start the bank accounts in a new page to not break the table
    ...(totalApprovedBankAccount > 1 ? settlementAccountsSection : []),
    ...businessProfileSection,
    ...PAHSection,
    ...(totalApprovedBankAccount > 1 ? [] : businessOwnerSection),
    ...(businessOwnersTotal > 1 || totalApprovedBankAccount > 1
      ? []
      : [
          {
            title: "fee schedule",
            details: fees,
          },
        ]),
  ];

  const thirdPage = [
    //if we have more bank account we start the business owner in a new page to not break the table
    ...(totalApprovedBankAccount > 1 ? businessOwnerSection : []),
    ...feeSection,
  ];

  return { firstPage, secondPage, thirdPage };
};

const getPdfBusinessOwners = (
  data: TBusinessOwner[],
  address: TBusinessAddressInfo,
) => {
  return data?.map((owner) => getPdfBusinessOwner(owner, address));
};

const getPdfBusinessOwner = (
  owner: TBusinessOwner,
  address: TBusinessAddressInfo,
) => {
  return [
    {
      label: "Full name",
      value: owner && `${owner?.firstName} ${owner?.lastName}`,
      width: "50%",
      direction: "row",
    },
    {
      label: "Email",
      value: owner?.email,
      width: "50%",
      direction: "row",
    },
    {
      label: "Ownership",
      value: (owner?.ownership || 0) + "%",
      width: "50%",
      direction: "row",
    },
    {
      label: "Tax ID",
      value: owner?.ssn,
      width: "50%",
      direction: "row",
    },
    {
      label: "Date of Birth",
      value: owner && moment(owner?.dob).format("MM/DD/YYYY"),
      width: "50%",
      direction: "row",
    },
    {
      label: "Contact",
      value: owner?.phone,
      width: "50%",
      direction: "row",
    },
    {
      label: "Address",
      value: owner?.address
        ? formatAddress(owner?.address)
        : owner?.isBusinessAddress
        ? formatAddress(address)
        : "",
      width: "100%",
    },
  ];
};

const getPdfBankAccountsData = (bankAccounts: IMerchantBankAccount[]) => {
  if (!bankAccounts?.length && bankAccounts?.length === 0) return [];
  return bankAccounts?.map((bancAccount) => getPdfBankAccountData(bancAccount));
};

const getPdfBankAccountData = (bankAccount: IMerchantBankAccount) => {
  return [
    {
      label: "Bank Name",
      value: bankAccount?.bankName,
      width: "50%",
    },
    {
      label: "Account Type",
      value: bankAccount?.type,
      width: "50%",
    },
    {
      label: "Account Name",
      value: bankAccount?.name,
      width: "100%",
    },
    {
      label: "Account Number",
      value: bankAccount?.accountNumber,
      width: "50%",
      direction: "row",
    },
    {
      label: "Routing Number",
      value: bankAccount?.routingNumber
        ? `•••• ${bankAccount.routingNumber.slice(-4)}`
        : "",
      width: "50%",
      direction: "row",
    },
  ];
};
