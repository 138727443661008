import { palette } from "@palette";
import { Text } from "@common/Text";
import { CustomerPeopleIcon } from "@assets/rebrandIcons";
import { Stack } from "@mui/material";

const TopLocationsEmptyState = () => {
  return (
    <Stack
      direction="column"
      gap={1}
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      minHeight={300}
      sx={{ userSelect: "none" }}
    >
      <CustomerPeopleIcon gradient width={32} height={32} />
      <Text
        fontSize="12px"
        fontWeight="book"
        lineHeight="14.4px"
        gradient={palette.gradient[10]}
      >
        You don’t have any customers yet
      </Text>
    </Stack>
  );
};

export default TopLocationsEmptyState;
