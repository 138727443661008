import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { LockIcon } from "@assets/icons/RebrandedIcons";
import { Tooltip } from "@common/Tooltip";

export const STATUS_ENUM = {
  INCOMPLETE: "INCOMPLETE",
  APPROVED: "APPROVED",
  UNDER_VERIFICATION: "UNDER_VERIFICATION",
} as const;

export type HomepageCardStatus = (typeof STATUS_ENUM)[keyof typeof STATUS_ENUM];

type TStepsRecapOptions = {
  title: string;
  description: JSX.Element | string;
  onClick?: () => void;
  hidden?: boolean;
  isLocked?: boolean;
  datatestID?: string;
  status?: HomepageCardStatus;
  isUnderVerification?: boolean;
  isSuspended?: boolean;
};

const HomepageCard = ({
  title,
  description,
  onClick,
  hidden,
  isLocked,
  datatestID,
  status,
  isSuspended,
}: TStepsRecapOptions) => {
  const isApproved = STATUS_ENUM.APPROVED === status;
  const isUnderVerification = STATUS_ENUM.UNDER_VERIFICATION === status;
  const interactable = !isLocked && !isSuspended;

  const isAgreementCard = title === "Sign Agreement";
  const approvedLabel = isAgreementCard ? "Signed" : "Approved";

  return (
    <Stack
      direction="row"
      alignItems="center"
      data-testid={datatestID}
      gap="8px"
      sx={{
        padding: "16px",
        border: `1px solid ${palette.liftedWhite[200]}`,
        borderRadius: "8px",
        position: "relative",
        cursor: "default",
        alignItems: "center",
        ...(interactable && {
          cursor: "pointer",
        }),
        ...(hidden && {
          display: "none",
        }),
        "&:hover": !isLocked
          ? {
              backgroundColor: "#fff",
              boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
            }
          : null,
      }}
      onClick={() => {
        if (interactable && onClick) onClick();
      }}
    >
      <Stack spacing={0.5} flexGrow={1}>
        <Text
          variant="body"
          color={palette.black[100]}
          align="left"
          sx={{ opacity: interactable ? 1 : 0.5 }}
          fontSize="18px"
        >
          {title}
        </Text>
        <Text
          variant="caption"
          fontWeight="book"
          color={palette.black[100]}
          align="left"
          sx={{ opacity: interactable ? 1 : 0.5 }}
        >
          {description}
        </Text>
      </Stack>
      <Box>
        {isApproved && !isSuspended && <Tag label={approvedLabel} />}
        {isUnderVerification && !isSuspended && (
          <Tag label="Under verification" />
        )}
        {/* {isLocked && isIncomplete && !isSuspended && (
          <LockIcon datatestID="lock_icon_test_id" />
        )} */}

        {isSuspended && (
          <Tooltip
            title="Suspended due to a confirmed OFAC match"
            placement="top"
          >
            <Tag label="Suspended" />
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
};

type TagProps = {
  label:
    | "Approved"
    | "Completed"
    | "Suspended"
    | "Under verification"
    | "Signed";
};

const Tag = ({ label }: TagProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "2px 24px",
        alignItems: "center",
        borderRadius: "100px",
        background: tagMap[label].background,
        userSelect: "none",
      }}
    >
      <Text
        color={tagMap[label].color}
        variant="body"
        fontWeight="book"
        textTransform="capitalize"
      >
        {label}
      </Text>
    </Box>
  );
};

const tagMap = {
  Approved: {
    background: "#E6F3EC",
    color: palette.filled.success,
  },
  Signed: {
    background: "#E6F3EC",
    color: palette.filled.success,
  },
  Completed: {
    background: "#E6F3EC",
    color: palette.filled.success,
  },
  Suspended: {
    background: palette.tag.warning.bg,
    color: palette.tag.warning.hover,
  },
  "Under verification": {
    background: "#E6EAF2",
    color: palette.filled.blue,
  },
};

export default HomepageCard;
