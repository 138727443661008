import {
  CellSignalFullIcon,
  CellSignalLowIcon,
  CellSignalMediumIcon,
} from "@assets/transactionsRiskProfileIcons";

export const riskProfileLevelsSettings = {
  0: {
    color: "#13A75A",
    border: "",
    badge: "",
    badgeAnimation: "",
    tagBackground: "#EAFEF3",
    gradient:
      "radial-gradient(50% 50% at 50% 50%, #EAFEF3 0%, rgba(234, 254, 243, 0.00) 100%)",
    escalationText: "",
    escalationIcon: <></>,
  },
  1: {
    color: "#FF9C08",
    border: "rgba(255, 167, 36, 0.20)",
    badge: "",
    badgeAnimation: "",
    tagBackground: "rgba(255, 167, 36, 0.10)",
    gradient: "",
    escalationText: "#FF9C08",
    escalationIcon: <CellSignalLowIcon />,
  },
  2: {
    color: "#FF8124",
    border: "rgba(255, 129, 36, 0.25)",
    badge: "#FF8124",
    badgeAnimation: {
      "@keyframes pulsing": {
        from: {
          boxShadow: `0 0 0 0 #FF8124`,
        },
        to: {
          boxShadow: `0 0 0 2px transparent`,
        },
      },
    },
    tagBackground: "#FFF2E9",
    gradient:
      "radial-gradient(50% 50% at 50% 50%, #FAD8A5 0%, rgba(250, 233, 207, 0.00) 100%)",
    escalationText: "#C3641D",
    escalationIcon: <CellSignalMediumIcon />,
  },
  3: {
    color: "#FF5924",
    border: "rgba(255, 89, 36, 0.10)",
    badge: "#FF5924",
    badgeAnimation: {
      "@keyframes pulsing": {
        from: {
          boxShadow: `0 0 0 0 #FF5924`,
        },
        to: {
          boxShadow: `0 0 0 2px transparent`,
        },
      },
    },
    tagBackground: "rgba(255, 89, 36, 0.10)",
    gradient: "",
    escalationText: "#FF5924",
    escalationIcon: <CellSignalFullIcon />,
  },
  default: {
    color: "#000",
    border: "",
    badge: "",
    badgeAnimation: "",
    tagBackground: "",
    gradient: "",
    escalationText: "#000",
    escalationIcon: <></>,
  },
};

export const getRiskColor = (level: number) => {
  switch (level) {
    case 0:
      return riskProfileLevelsSettings[0];
    case 1:
      return riskProfileLevelsSettings[1];
    case 2:
      return riskProfileLevelsSettings[2];
    case 3:
      return riskProfileLevelsSettings[3];
    default:
      return riskProfileLevelsSettings.default;
  }
};
