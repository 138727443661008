import { useState, useEffect } from "react";
import { getRiskColor } from "../utils";
import { Box, styled } from "@mui/material";
import { Text } from "@common/Text";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { fromUnixTime } from "date-fns";
import moment from "moment";

const baseTooltipMsg = `Next de-escalation happens in `;

const RiskModalLevel = ({
  level,
  deEscalationTime,
}: {
  level: number;
  deEscalationTime: number | null | undefined;
}) => {
  const isShowTooltip = Boolean(deEscalationTime && deEscalationTime > 0);
  const [tooltipMsg, setTooltipMsg] = useState(baseTooltipMsg);
  let remainingSeconds: number;

  const { color, tagBackground } = getRiskColor(level);
  let intervalId: string | number | NodeJS.Timer | undefined;

  const calculateRemainingTime = () => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    remainingSeconds =
      deEscalationTime && deEscalationTime > currentTime
        ? deEscalationTime - currentTime
        : -1;
  };

  const startCountdown = () => {
    intervalId = setInterval(() => {
      if (remainingSeconds <= 0) {
        clearInterval(intervalId);
        setTooltipMsg(() => `${baseTooltipMsg} 00:00:00`);
        return;
      }

      const formattedTime = moment(fromUnixTime(remainingSeconds)).format(
        "hh:mm:ss",
      );

      setTooltipMsg(() => `${baseTooltipMsg} ${formattedTime}`);
      remainingSeconds--;
    }, 1000);
  };

  useEffect(() => {
    if (isShowTooltip) {
      calculateRemainingTime();
      startCountdown();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [deEscalationTime]);

  return (
    <Box textAlign="center">
      <CustomToolTip
        showToolTip={isShowTooltip}
        message={tooltipMsg}
        customStyle={{
          textAlign: "center",
          display: "inline",
        }}
      >
        <Tag color={color} background={tagBackground}>
          Level {level}
        </Tag>
      </CustomToolTip>
    </Box>
  );
};

const Tag = styled(Text, {
  shouldForwardProp: (prop) => prop !== "background",
})<{ background: string }>(({ color, background }) => ({
  border: `1px solid ${color}`,
  background,
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "21.6px",
  letterSpacing: "-0.18px",
  borderRadius: "4px",
  padding: "4px 16px",
  userSelect: "none",
  display: "inline",
}));

export default RiskModalLevel;
