import {
  ArrowVertical,
  CloseConversationIcon,
  SearchConversationIcon,
} from "@assets/icons";
import { Image } from "@common/StyledImage/Image";
import { Text } from "@common/Text";
import { Box, SvgIconProps, SxProps } from "@mui/material";
import { Stack } from "@mui/material";
import React, { RefObject } from "react";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import Placeholder from "@assets/images/Placeholder.png";

type HeaderIconType = {
  Component: React.ElementType<SvgIconProps>;
  action: () => unknown;
  name: string;
};

export type HeaderProps = {
  SearchElement?: React.ReactElement;
  isMobileView?: boolean;
  handleExpandModal: () => void;
  searchIconAction?: () => void;
  handleOpenModal: (open?: boolean) => void;
  ref?: RefObject<HTMLDivElement>;
  backButtonEnabled?: boolean;
  handleBackClick?: () => void;
  imageSx?: SxProps;
  image?: string;
  showImage?: boolean;
  headerTestId?: string;
  title: string;
  Breadcrumbs?: React.ReactElement;
  testIdBase?: string;
  hideTitle?: boolean;
};

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      SearchElement,
      isMobileView,
      handleExpandModal,
      searchIconAction,
      handleOpenModal,
      backButtonEnabled,
      handleBackClick,
      imageSx,
      image,
      showImage,
      headerTestId,
      title,
      Breadcrumbs,
      testIdBase,
      hideTitle,
    },
    ref,
  ) => {
    const sizedImage = image ? addSizeToImage(image, "small") : Placeholder;

    const headerIconMapper: Array<HeaderIconType> = [
      ...(searchIconAction
        ? [
            {
              Component: SearchConversationIcon,
              action: searchIconAction,
              name: "search",
            },
          ]
        : []),
      ...(!isMobileView
        ? [
            {
              Component: ArrowVertical,
              action: () => handleExpandModal(),
              name: "expand",
            },
            {
              Component: CloseConversationIcon,
              action: () => handleOpenModal(false),
              name: "close",
            },
          ]
        : []),
    ];

    return (
      <Box>
        <Stack
          ref={ref}
          direction="row"
          sx={{
            padding: "12px",
            cursor: "grab",
            transition: "box-shadow 150ms ease-in",
            "&.conversationsBarActive": {
              borderBottom: "1px solid var(--Neutral-White, #FFF)",
              background: "rgba(251, 251, 251, 0.90)",
              boxShadow: "0px -2px 10px 0px rgba(76, 76, 76, 0.10)",
              backdropFilter: "blur(10px)",
            },
          }}
          justifyContent="space-between"
          alignItems={"center"}
          id="handle"
        >
          <Stack
            direction={"row"}
            alignItems="center"
            gap={1}
            id="imageSection"
            onClick={(e) => {
              e.stopPropagation();
              backButtonEnabled && handleBackClick && handleBackClick();
            }}
            sx={imageSx}
          >
            {!hideTitle ? (
              <>
                {showImage && (
                  <Image
                    draggable={false}
                    src={sizedImage}
                    width={24}
                    height={24}
                  />
                )}
                <Text
                  fontSize="14px"
                  sx={{ fontWeight: 400 }}
                  data-testid={headerTestId}
                >
                  {title}
                </Text>
              </>
            ) : (
              <>{Breadcrumbs}</>
            )}
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            id="actionSection"
          >
            {[...headerIconMapper].map(
              ({ Component, action, name }: HeaderIconType, index) => {
                return (
                  <Box
                    key={index}
                    onClick={action}
                    sx={{
                      cursor: "pointer",
                      ":hover svg path": {
                        fill: "#6D9CF8",
                      },
                    }}
                    data-testid={`${testIdBase}-header-${name}-action`}
                  >
                    <Component onMouseEnter={(e) => undefined} />
                  </Box>
                );
              },
            )}
          </Stack>
        </Stack>
        {SearchElement}
      </Box>
    );
  },
);
Header.displayName = "Header";

export default Header;
