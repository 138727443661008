type FacebookIconProps = {
  width?: number | string;
  height?: number | string;
  stroke?: string;
};

const Xicon = ({
  height = "14",
  width = "14",
  stroke = "#8F8F8F",
}: FacebookIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Xicon;
