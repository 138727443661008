import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format, sub, subDays } from "date-fns";
import { Customer } from "@customTypes/customer.types";
import { replaceValues, withAppendedKey } from "@services/filtering";
import { toUnixDateFormat } from "@utils/date.helpers";
import { AmountKeys } from "@services/filtering/filtering.types";
import { CustomersQueryType } from "@redux/types/customers";

export interface CustomersState {
  sorting: string;
  query: CustomersQueryType;
  names: {
    amount: (string | number)[];
    recurrence: string[];
    joined: (string | number)[];
  };
  filteredTableValues: Customer[];
}

export type CustomerSliceState = {
  data: Customer;
  loading: boolean;
  error: any;
};

const initialState: CustomersState = {
  sorting: "",
  query: {
    joined: "",
    recurrence: "",
    amount: "",
  },
  names: {
    amount: [],
    recurrence: [],
    joined: [],
  },
  filteredTableValues: [],
};

type Date = {
  modifier: string;
  count: number;
  dayMonthYear: string;
  startDate: any;
  endDate: any;
  date: any;
};

type Amount = {
  title: "amount";
  values: {
    modifier: string;
    amount: number;
    amountOne: number;
    amountTwo: number;
  };
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    updateSorting: (state: CustomersState, action: PayloadAction<string>) => {
      state.sorting = action.payload;
    },
    addDateFilter: (state: CustomersState, action: PayloadAction<Date>) => {
      const { modifier, date, count, dayMonthYear, startDate, endDate } =
        action.payload;

      if (modifier === "is on or after" || modifier === "is on or before") {
        state.names.joined = [modifier, format(date, "dd/MM/yy")];
        state.query.joined = replaceValues(
          withAppendedKey.joined[modifier],
          toUnixDateFormat(new Date(date)),
        );
      } else if (modifier === "is between") {
        state.names.joined = [
          format(startDate, "dd/MM/yy"),
          "-",
          format(endDate, "dd/MM/yy"),
        ];
        state.query.joined = replaceValues(
          withAppendedKey.joined[modifier],
          toUnixDateFormat(new Date(startDate)),
          toUnixDateFormat(new Date(endDate)),
        );
      } else if (modifier === "in the last") {
        state.names.joined = ["last", count, dayMonthYear];
        state.query.joined = replaceValues(
          withAppendedKey.joined[modifier],
          toUnixDateFormat(sub(new Date(), { [dayMonthYear]: count })),
        );
      }
    },
    disableDateFilter: (state: CustomersState) => {
      state.names.joined = [];
      state.query.joined = "";
    },
    addAmountFilter: (state: CustomersState, action: PayloadAction<Amount>) => {
      const { modifier, amount, amountOne, amountTwo } = action.payload.values;
      state.names[action.payload.title] = [amountOne, amountTwo];

      if (modifier.includes("greater than") || modifier.includes("less than")) {
        state.names[action.payload.title] = [modifier, amount];
        state.query[action.payload.title] = replaceValues(
          withAppendedKey.amount[modifier as AmountKeys],
          amount,
        );
      } else {
        state.names[action.payload.title] = [amountOne, amountTwo];
        state.query[action.payload.title] = replaceValues(
          withAppendedKey.amount[modifier as AmountKeys],
          amountOne,
          amountTwo,
        );
      }
    },
    disableAmountFilter: (
      state: CustomersState,
      action: PayloadAction<"amount">,
    ) => {
      state.names[action.payload] = [];
      state.query.amount = "";
    },
    addRecurrenceFilter: (state: CustomersState) => {
      state.names.recurrence = ["true"];
      state.query.recurrence = replaceValues(
        withAppendedKey.recurrence.default,
        "true",
      );
    },
    disableRecurrenceFilter: (state: CustomersState) => {
      state.names.recurrence = [];
      state.query.recurrence = "";
    },
    clearFilters: (state: CustomersState) => {
      state.query = initialState.query;
      state.names = initialState.names;
    },
  },
});

export const {
  addDateFilter,
  disableDateFilter,
  addAmountFilter,
  disableAmountFilter,
  updateSorting,
  clearFilters,
  addRecurrenceFilter,
  disableRecurrenceFilter,
} = customersSlice.actions;

export const selectFilters = (state: RootState) => state.customers.names;
export const selectRecurrenceFilter = (state: RootState) =>
  state.customers.names.recurrence;
export const selectQueryFilters = (state: RootState) => state.customers.query;
export const selectTableValues = (state: RootState) =>
  state.customers.filteredTableValues;
export const sortingCustomers = (state: RootState) => state.customers.sorting;

export default customersSlice.reducer;
