import { Button } from "@common/Button";
import { Text } from "@common/Text";
import useCheckPAH from "@hooks/common/useCheckPAH";
import NiceModal from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { checkPortals } from "@utils/routing";
import { MERCHANT_AGREEMENT_UPDATE_MODAL } from "modals/modal_names";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useMemo } from "react";
import { merchantAgreementParser } from "@components/Merchants/MerchantPreview/helpers/parsers";
import FadeInWrapper from "@components/animation/FadeInWrapper";

type Props = {
  drawerOpen: boolean;
};

const PendingMerchantApplication = ({ drawerOpen }: Props) => {
  const { isMasqueradeMode } = useMasqueradeReducer();
  const { isMerchantPortal } = checkPortals();
  const { selectedUser, merchantId } = useGetCurrentMerchantId();
  const user: any = selectedUser;
  const enabledEndpoint =
    drawerOpen &&
    !isMasqueradeMode &&
    isMerchantPortal &&
    user.role === "merchant";

  const { data, isLoading } = useGetMerchantById({
    enabled: enabledEndpoint && !!merchantId,
  });

  const merchantAgreement = useMemo(
    () => merchantAgreementParser(data),
    [data],
  );

  const { isLoggedInPAH } = useCheckPAH(enabledEndpoint);
  const isPending =
    merchantAgreement.msaHadAgreed &&
    merchantAgreement.msaLastAcceptedVersion !== merchantAgreement.tcVersion;

  const handleCheckUpdate = () => {
    NiceModal.show(MERCHANT_AGREEMENT_UPDATE_MODAL, {
      version: merchantAgreement.tcVersion,
      merchantId,
      signatureURL: data?.msaLastAcceptedBySignatureURL,
    });
  };

  if (
    !isMerchantPortal ||
    isMasqueradeMode ||
    !drawerOpen ||
    isLoading ||
    !isPending ||
    !isLoggedInPAH ||
    !merchantAgreement?.approvedPAH
  )
    return null;

  return (
    <FadeInWrapper delay={100}>
      <Container>
        <PendingTag>Pending</PendingTag>
        <Stack spacing={1}>
          <Text color={palette.neutral[80]}>Merchant Application Update</Text>
          <Text color={palette.neutral[70]} fontWeight="book">
            Updates have been made to the merchant agreement. Please read
            through the revised terms for the latest information
          </Text>
        </Stack>
        <Button
          background="primary"
          size="medium"
          sx={{ fontWeight: 400 }}
          onClick={handleCheckUpdate}
        >
          Read Agreement
        </Button>
      </Container>
    </FadeInWrapper>
  );
};

const Container = styled(Box)(() => ({
  margin: "8px",
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  gap: "16px",
  borderRadius: "12px",
  background:
    "linear-gradient(180deg, #F8F8F6 52.08%, rgba(255, 255, 255, 0.00) 100%)",
}));

const PendingTag = styled(Text)(() => ({
  padding: "2px 16px",
  borderRadius: "16px",
  color: palette.tag.warning.hover,
  background: palette.tag.warning.bg,
  width: "85px",
}));

export default PendingMerchantApplication;
