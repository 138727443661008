import { namespaces } from "../../../../resources/i18n.constants";

export const membershipsMinibuilder = {
  [namespaces.pages.membershipsMinibuilder]: {
    create_membership: "Crear membresía",
    edit_membership: "Editar membresía",
    // Stepper
    general: "General",
    subscriptions: "Suscripciones",
    // Step 1
    title: "Título",
    title_required: "Tu campaña necesita un título",
    "Please enter a suitable title": "Por favor ingrese un título adecuado",
    "Your campaign needs a title": "Tu campaña necesita un título",
    description: "Descripción",
    optional_but_recommended: "(Opcional pero recomendado)",
    "What’s the purpose of this membership":
      "¿Cuál es el propósito de esta membresía?",
    characters_remaining: "Caracteres restantes",
    browse_more_campaigns: "Explorar más campañas",
    "If enabled, users will be able to explore other published campaigns of this Nonprofit.":
      "Si está habilitado, los usuarios podrán explorar otras campañas publicadas de esta organización sin fines de lucro.",
    featured_image: "Foto principal",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000":
      "Esta imagen debe ser representativa de la campaña y se mostrará en la parte superior del formulario de pago, se puede reposicionar posteriormente. Tamaño mínimo recomendado 2000x2000",
    use_as_background_image: "Usar como imagen de fondo",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "Si está marcada, esta imagen se integrará en el fondo del formulario, se puede cambiar más adelante",
    media_library: "Mediateca",
    add_new_image: "Agregar nueva imagen",
    no_images: "Sin imágenes",
    open_adv_builder: "Abra el Generador avanzado",
    save_changes: "Guardar Cambios",
    // Subscriptions ------------------------------------------------------------------ --------------
    thumbnail: "Miniatura",
    display_subscriptions_available: "Mostrar suscripciones disponibles",
    add_subscription: "Agregar suscripción",
    not_enough_subscriptions: "No hay suficientes suscripciones",
    at_least_one_subscription:
      "Necesita al menos una suscripción para crear una Membresía",
    changes_saved: "Cambios guardados",
    successfully_saved:
      "Cambios en “Nueva membresía”. han sido guardados exitosamente",
    subscription_name: "Nombre de suscripción",
    enter_a_suitable_name: "Introduzca un nombre adecuado",
    // ticket_name_is_mandatory: "Ticket name is mandatory",
    amount: "Cantidad",
    must_be_above_one_dollar: "Debe estar por encima de 1USD",
    between: "Entre",
    and: "y",
    in_stock: "En stock",
    at_least_one: "al menos 1",
    should_be_at_least: "debe ser al menos 1",
    "Give some insight about this Subscription":
      "Proporcione información sobre esta suscripción",
    create_option: "Crear opción",
    cancel: "Cancelar",
    remove: "Eliminar",
    payment_types: "Formas de pago",
    "Choose the payment types available to your customers.":
      "Elija los tipos de pago disponibles para sus clientes.",
    "If defaulted,": "Si está por defecto,",
    "the option will be selected at first when users visit the campaign.":
      "la opción se seleccionará en un primer momento cuando los usuarios visiten la campaña.",
    "One-Time": "Una vez",
    Monthly: "Mensual",
    Quarterly: "Trimestral",
    Yearly: "Anual",
    "Make default": "Hacer por defecto",
    available: "Disponible",

    properties: "Propiedades",
    customer_pays_the_credit_card_fee:
      "El cliente paga la tarifa de la tarjeta de crédito",
    "If toggled off, the Nonprofit covers the credit card fee.":
      "Si se desactiva, la organización sin fines de lucro cubre la tarifa de la tarjeta de crédito.",
    make_it_optional: "Hazlo opcional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "Si está marcado, los clientes tendrán la opción de no cubrir la tarifa de la tarjeta de crédito",
    max_subscriptions_that_can_be_purchased_at_once:
      "Suscripciones máximas que se pueden comprar a la vez",
    // Membership Successful
    membership_successfully_created: "Membresía creada con éxito 🎉",
    "If you’re on a desktop or a laptop":
      "Si está en un escritorio o una computadora portátil, puede continuar editando en <span>el Builder avanzado</span>. El Builder Advanced ofrece potentes herramientas de personalización para ayudarlo a llevar su campaña al siguiente nivel y, en última instancia, aumentar la conversión.",
    open_the_advanced_builder: "Abra el Generador avanzado",
    done: "Hecho",
    membership_created: "Membresía creada",
    "Your new Membership “New membership” has been successfully created and it’s ready to sell subscriptions.": `Su nueva membresía “Nueva membresía” se ha creado con éxito y está lista para vender suscripciones.`,
  },
};
