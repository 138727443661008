import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import Cookies from "js-cookie";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import useGetCurrentMerchantId from "./useGetCurrentMerchantId";

export const useCreateVerificationToken = ({
  action,
}: {
  action: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tfaVerification = useMutation((data: Record<string, any>) => {
    return customInstance({
      url: `/tfa-verifications`,
      method: "POST",
      data,
    });
  });

  const handleMethodSubmit: SubmitHandler<Record<string, any>> = (data) => {
    setIsLoading(true);

    tfaVerification.mutate(data, {
      onSuccess: () => {
        showMessage(
          "Success",
          `Verification code sent! Check your ${
            data.method === "email" ? "email" : "SMS"
          }.`,
        );
        setIsLoading(false);
        action(2);
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          "The provided password reset request is invalid and/or expired",
        );
        setIsLoading(false);
      },
    });
  };
  return {
    handleMethodSubmit,
    isLoading,
  };
};

export const useVerificationCode = ({
  action,
}: {
  action: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { merchantId } = useGetCurrentMerchantId();

  const codeVerification = useMutation((code: string) => {
    return customInstance({
      url: `/tfa-verifications/${code}`,
      method: "POST",
    });
  });

  const handleCodeSubmit: SubmitHandler<Record<string, any>> = (data) => {
    setIsLoading(true);
    codeVerification.mutate(data.code, {
      onSuccess: () => {
        showMessage("Success", "Verification successful");
        Cookies.set(
          "transfer-verification",
          JSON.stringify({ date: new Date().toISOString(), id: merchantId }),
        );
        setIsLoading(false);
        action(3);
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          "Code is not valid. It may be expired, you requested another code, or you made a typo. Double check and try again",
        );
        setIsLoading(false);
      },
    });
  };

  return {
    handleCodeSubmit,
    isLoading,
  };
};
