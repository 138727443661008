import { RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import ImageGallery from "features/Minibuilders/ImageGallery";

import React, { memo } from "react";
import { useFormContext } from "react-hook-form";
import TitleComponent from "../TitleComponent";
import { isEmpty } from "lodash";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

function SweepStackStylePage({ title }: { title: string }) {
  const { setValue, watch } = useFormContext();
  const values = watch();

  const handleSelect = (item: any) => {
    const isSelected = item?.URL === watch("Style.image")?.URL;
    setValue("Style.image", isSelected ? {} : item, { shouldDirty: true });
    isSelected && setValue("Style.useAsBackground", false);
  };

  return (
    <>
      <FadeUpWrapper delay={100}>
        <TitleComponent title={title} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Stack direction="column" gap={3} flexGrow={1}>
          <Stack direction="column" gap={3}>
            <Box>
              <Text
                color={palette.black[100]}
                variant="body"
                lineHeight="16.8px"
                fontWeight="book"
                mb="4px"
              >
                Featured image
              </Text>
              <Text
                color={palette.gray[300]}
                variant="body"
                fontWeight="book"
                lineHeight="16.8px"
              >
                This image will be displayed at the top of the payment form so
                it should be representative. Minimum size recommended 2000x2000
              </Text>
            </Box>

            <ImageGallery
              handleSelect={handleSelect}
              selectedImage={values?.Style?.image?.URL}
            />
            <RHFCheckbox
              disabled={isEmpty(values?.Style?.image)}
              name="Style.useAsBackground"
              label="Use as background image"
              helperText="If checked this image will be integrated in the background of the form, can be later changed"
            />
          </Stack>
        </Stack>
      </FadeUpWrapper>
    </>
  );
}

export default memo(SweepStackStylePage);
