import React, { memo } from "react";
import { palette } from "@palette";
import {
  AmountsListType,
  AnyAmountType,
  AmountType,
} from "@redux/slices/fundraisers";
// mui
import { Box, Stack } from "@mui/material";
// components
import { Text, TruncateText } from "@common/Text";
import CreateAmount from "./CreateAmount";
import EditAnyAmount from "./EditAnyAmount";
// dnd
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
// assets
import { EditIcon, TrashIcon } from "@assets/icons";
import { VerticalDragIcon } from "@assets/builderIcons";
// redux
import { useAppSelector } from "@redux/hooks";
import { selectAmounts } from "@redux/slices/fundraisers";
import { Switch_V2 } from "@common/Switch/Switch_V2";
import { toEnFormatWithDecimal } from "@utils/index";
import { isEmpty, isFunction } from "lodash";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";

type AmountItemProps = {
  amountItem: AmountsListType;
  showAmountItem: (id: string) => void;
  removeAmountItem: (id: string) => void;
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  lastActive: string;
  handleEditing?: (id: string) => void;
  isAnyAmount?: boolean;
};

const AmountItem = ({
  amountItem,
  showAmountItem,
  removeAmountItem,
  lastActive,
  setIsSubmitDisabled,
  handleEditing,
  isAnyAmount,
}: AmountItemProps) => {
  const [isEdit, setIsEdit] = React.useState(false);
  const {
    id,
    title,
    amount,
    active,
    description,
    isDefault: fetchedIsDefault,
    thumbnail,
  } = amountItem as AmountType;
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const isDefault = fetchedIsDefault;
  const amountsList = useAppSelector(selectAmounts);

  const style = {
    zIndex: +isDragging,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const formattedAmount = amount.includes(",")
    ? amount
    : toEnFormatWithDecimal(amount);

  const isDisabled = amountsList.length === 1 || lastActive === id;

  const removeHandler = (id: string) => {
    if (isDisabled) return;
    removeAmountItem(id);
  };

  const showImage = !!thumbnail;
  const imageSRC = addSizeToImage(thumbnail, "thumb");

  return (
    <div ref={setNodeRef} style={style}>
      {!isEdit && (
        <Box sx={containerStyle}>
          <Stack
            {...(showImage && {
              display: "flex",
              alignItems: "center",
            })}
            direction="row"
            spacing={2}
          >
            <Switch_V2
              checked={amountsList.length === 1 && !active ? true : active}
              disabled={isDisabled}
              onChange={() => showAmountItem(id)}
            />
            {showImage && <CustomImage src={imageSRC} />}

            <div>
              <Text
                variant="h6"
                fontWeight="regular"
                color={palette.neutral[70]}
                align="left"
                sx={{
                  lineHeight: "24px",
                  wordBreak: "break-all",
                  marginBottom: "8px",
                  sup: {
                    fontSize: "12px",
                    lineHeight: "12px",
                  },
                }}
              >
                {isDefault
                  ? title
                  : isAnyAmount
                  ? "Any amount"
                  : formattedAmount}{" "}
                {!isDefault && !isAnyAmount && "USD"}
                {/* {!isDefault && !showImage && <sup>USD</sup>} */}
              </Text>
              <TruncateText
                lineClamp={2}
                color={palette.neutral[80]}
                variant="body"
                align="left"
                sx={{ wordBreak: "break-all", lineHeight: "17px" }}
              >
                {isDefault && typeof description === "string"
                  ? description
                  : title}
              </TruncateText>
            </div>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            {!isDefault && !isAnyAmount && (
              <Box
                className="amount-item-actions"
                sx={{
                  cursor: isDisabled || isAnyAmount ? "default" : "pointer",
                  mr: 1,
                  opacity: isDisabled || isAnyAmount ? 0.5 : 1,
                }}
                onClick={() => {
                  !isAnyAmount && removeHandler(id);
                }}
              >
                <TrashIcon stroke={palette.filled.red} />
              </Box>
            )}
            <Box
              className="amount-item-actions"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                isFunction(handleEditing) ? handleEditing(id) : setIsEdit(true);
              }}
            >
              <EditIcon stroke={palette.black[100]} />
            </Box>

            <Box
              style={{ cursor: isDragging ? "grabbing" : "grab" }}
              {...attributes}
              {...listeners}
            >
              <VerticalDragIcon fill={palette.gray.main} />
            </Box>
          </Stack>
        </Box>
      )}
      {isEdit && isDefault && (
        <EditAnyAmount
          setIsEditAnyAmount={setIsEdit}
          amountData={amountItem as AnyAmountType}
          setIsSubmitDisabled={setIsSubmitDisabled}
        />
      )}
      {isEdit && !isDefault && (
        <CreateAmount
          setIsCreateAmount={setIsEdit}
          initialObject={amountItem as AmountType}
          setIsSubmitDisabled={setIsSubmitDisabled}
          lastActive={isDisabled}
        />
      )}
    </div>
  );
};

const containerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 8px",
  width: "100%",
  borderRadius: "8px",
  cursor: "default",
  background: palette.neutral.white,
  border: `1px solid ${palette.liftedWhite[200]}`,
  backgroundColor: "transparent",

  "&:hover": {
    boxShadow: "0px 3px 15px rgba(2, 2, 2, 0.15)",
  },

  ".amount-item-actions": {
    visibility: "hidden",
  },

  "&:hover .amount-item-actions": {
    visibility: "visible",
  },
};

export default memo(AmountItem);

export const CustomImage = ({ src }: { src: string }) => {
  return (
    <Box
      bgcolor="white"
      boxShadow={2}
      borderRadius={0.5}
      overflow="hidden"
      width="32px"
      height="32px"
    >
      <img src={src} style={{ width: "100%", height: "100%" }} />
    </Box>
  );
};
