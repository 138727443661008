import { Radio } from "@common/Radio";
import { Text } from "@common/Text";
import { Grid, Stack, styled, Box } from "@mui/material";
import { isFunction } from "lodash";
import { memo, useEffect, useRef } from "react";
import CustomGradientPicker from "./CustomGradientPicker";
import { baseColor, defaultGradients } from "../hooks/useThemeManager";
import { useThemeContext } from "../Provider/CustomThemeProvider";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

interface Props {
  title: string;
  isSelected?: boolean;
  subTitle?: string;
  isCustom?: boolean;
  linearColor: {
    light: string;
    dark: string;
    id: number;
    name: string;
  };
  onSelectGradientColor?: (c: {
    dark: string;
    light: string;
    id: number;
    name: string;
  }) => void;
}

function BrandingCard({
  title,
  isSelected = false,
  subTitle,
  linearColor,
  isCustom,
  onSelectGradientColor,
}: Props) {
  const mountRef = useRef(false);
  const isDefault = defaultGradients.some(
    (col) => col === linearColor?.dark || col === linearColor?.light,
  );
  const { apiDefaultColor } = useThemeContext();

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.ENTERPRISE,
    operation: OPERATIONS.UPDATE,
  });

  const handleClick = () => {
    if (!isFunction(onSelectGradientColor) || !isEditAllowed) return;

    let params = {
      dark: linearColor?.dark,
      light: linearColor?.light,
      id: linearColor?.id,
      name: linearColor?.name,
    };

    if (isCustom && !isDefault && !mountRef?.current) {
      mountRef.current = true;
      const dark = apiDefaultColor?.id > 3 ? apiDefaultColor?.dark : baseColor;
      const light =
        apiDefaultColor?.id > 3 ? apiDefaultColor?.light : baseColor;

      params = {
        dark,
        light,
        id: 4,
        name: "Custom",
      };
    }

    onSelectGradientColor(params);
  };

  useEffect(() => {
    if (isCustom && !isSelected) mountRef.current = false;
  }, [isSelected, isCustom]);

  return (
    <Grid item xs={6} lg={3}>
      <WithTooltipWrapper
        hasTooltip
        tooltipProps={{ show: !isEditAllowed, message: EDIT_DENY_MESSAGE }}
      >
        <CardGrid
          onClick={handleClick}
          disabled={!isEditAllowed}
          isSelected={isSelected}
        >
          <Title
            title={title}
            isSelected={isSelected}
            disabled={!isEditAllowed}
            subTitle={subTitle}
          />
          {!isCustom ? (
            <Box
              width="100%"
              minHeight="32px"
              borderRadius="5.96px"
              sx={{
                backgroundImage: linearColor?.light,
              }}
            />
          ) : (
            <Stack
              justifyContent="space-around"
              px="2px"
              gap="4px"
              flexDirection="row"
            >
              <CustomGradientPicker isSelected={isSelected} label="Light" />
              <CustomGradientPicker isSelected={isSelected} label="Dark" />
            </Stack>
          )}
        </CardGrid>
      </WithTooltipWrapper>
    </Grid>
  );
}

export default memo(BrandingCard);

const Title = ({
  title,
  isSelected,
  subTitle,
  disabled = false,
}: {
  title: string;
  isSelected: boolean;
  subTitle?: string;
  disabled?: boolean;
}) => {
  return (
    <Box flexGrow={1}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        mb="12px"
      >
        <Text fontSize="14px" fontWeight="book" color="#575353">
          {title}
        </Text>
        <Radio checked={isSelected} disabled={disabled} />
      </Stack>
      <Text fontWeight="book" color=" #8F8F8F" fontSize="13.92px">
        {subTitle}
      </Text>
    </Box>
  );
};

const CardGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled" && prop !== "isSelected",
})<{ disabled: boolean; isSelected: boolean }>(
  ({ theme, disabled, isSelected }) => ({
    cursor: disabled ? "default" : "pointer",
    padding: "16px 12px",
    borderRadius: "8px",
    height: "192.88px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    backgroundColor: "#FFFFFF",
    ...(isSelected && {
      border: "1px solid #D1D1D0",
    }),

    ...(disabled && {
      pointerEvents: "none",
    }),

    [theme.breakpoints.up("md")]: {
      height: "175.88px",
    },
  }),
);
