import { createTheme } from "@mui/material";
import { getJsonValue } from "./utils";
import { CustomTheme } from "./palette.interface";
import {
  getLargeFilledVariants,
  getLargeOutlinedVariants,
  getLargeGhostVariants,
  getSmallFilledVariants,
  getSmallmallOutlinedVariants,
  getSmallGhostVariants,
} from "./customButtonStyle";
import { getCustomBaseInputStyle } from "./customBaseInputStyle";
import { typography } from "./typography";
import { getCustomTypographyStyle } from "./customTypographyStyle";

const makePalette = (mode: "light" | "dark") => ({
  givebox: {
    "600": "#326ec5ff",
    blue: "#6d9cf8ff",
  },
  primitive: {
    blue: {
      "10": getJsonValue(`tokens.${mode}.primitive.blue.10`),
      "25": getJsonValue(`tokens.${mode}.primitive.blue.25`),
      "50": getJsonValue(`tokens.${mode}.primitive.blue.50`),
      "100": getJsonValue(`tokens.${mode}.primitive.blue.100`),
    },
    "citrus-peel": {
      "100": getJsonValue(`tokens.${mode}.primitive.citrus-peel.100`),
    },
    error: {
      "25": getJsonValue(`tokens.${mode}.primitive.error.25`),
      "50": getJsonValue(`tokens.${mode}.primitive.error.50`),
      "100": getJsonValue(`tokens.${mode}.primitive.error.100`),
    },
    "moon-purple": {
      "100": getJsonValue(`tokens.${mode}.primitive.moon-purple.100`),
    },
    neutral: {
      "0": getJsonValue(`tokens.${mode}.primitive.neutral.0`),
      "5": getJsonValue(`tokens.${mode}.primitive.neutral.5`),
      "10": getJsonValue(`tokens.${mode}.primitive.neutral.10`),
      "20": getJsonValue(`tokens.${mode}.primitive.neutral.20`),
      "30": getJsonValue(`tokens.${mode}.primitive.neutral.30`),
      "40": getJsonValue(`tokens.${mode}.primitive.neutral.40`),
      "50": getJsonValue(`tokens.${mode}.primitive.neutral.50`),
      "60": getJsonValue(`tokens.${mode}.primitive.neutral.60`),
      "70": getJsonValue(`tokens.${mode}.primitive.neutral.70`),
      "80": getJsonValue(`tokens.${mode}.primitive.neutral.80`),
      "90": getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      "95": getJsonValue(`tokens.${mode}.primitive.neutral.95`),
      "100": getJsonValue(`tokens.${mode}.primitive.neutral.100`),
    },
    success: {
      "25": getJsonValue(`tokens.${mode}.primitive.success.25`),
      "50": getJsonValue(`tokens.${mode}.primitive.success.50`),
      "100": getJsonValue(`tokens.${mode}.primitive.success.100`),
    },
    transparent: {
      "darken-10": getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-10`,
      ),
      "darken-5": getJsonValue(`tokens.${mode}.primitive.transparent.darken-5`),
    },
    warning: {
      "25": getJsonValue(`tokens.${mode}.primitive.warning.25`),
      "50": getJsonValue(`tokens.${mode}.primitive.warning.50`),
      "100": getJsonValue(`tokens.${mode}.primitive.warning.100`),
    },
  },
  gradient: {
    "ocean-blue": {
      default: `linear-gradient(224deg, ${getJsonValue(
        `tokens.${mode}.primitive.gradients.ocean-blue.start`,
      )} 0%, ${getJsonValue(
        `tokens.${mode}.primitive.gradients.ocean-blue.end`,
      )} 100%)`,
      highlight: `linear-gradient(85.95deg, ${getJsonValue(
        `tokens.${
          mode === "light" ? "dark" : "light"
        }.primitive.gradients.ocean-blue.end`,
      )} 0%, ${getJsonValue(
        `tokens.${
          mode === "light" ? "dark" : "light"
        }.primitive.gradients.ocean-blue.start`,
      )} 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));`,
      disabled: `linear-gradient(85.95deg, rgba(79, 134, 213, 0.5) 0%, rgba(97, 199, 232, 0.5) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));`,
      border: `linear-gradient(white, white) padding-box, linear-gradient(223.56deg, #28C0D9 0%, #4D449E 100%) border-box`,
    },
    "citrus-peel": {
      default: `linear-gradient(224deg, ${getJsonValue(
        `tokens.${mode}.primitive.gradients.citrus-peel.start`,
      )} 0%, ${getJsonValue(
        `tokens.${mode}.primitive.gradients.citrus-peel.end`,
      )} 100%));`,
      highlight: `linear-gradient(224deg, rgba(255, 170, 108, 0.20) 0%, rgba(224, 77, 121, 0.20) 100%)`,
    },
    "moon-purple": {
      default: `linear-gradient(224deg, ${getJsonValue(
        `tokens.${mode}.primitive.gradients.moon-purple.start`,
      )} 0%, ${getJsonValue(
        `tokens.${mode}.primitive.gradients.moon-purple.end`,
      )} 100%)`,
      highlight: `linear-gradient(224deg, rgba(137, 164, 234, 0.20) 0%, rgba(116, 89, 233, 0.20) 100%)`,
    },
  },
});

const makeCtx = (mode: "light" | "dark") => ({
  text: {
    primary: getJsonValue(`tokens.${mode}.colour.text.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.text.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
  },
  surface: {
    primary: getJsonValue(`tokens.${mode}.colour.surface.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.surface.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
    "primary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.primary-transparent`,
    ),
    "secondary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.secondary-transparent`,
    ),
    overlay: getJsonValue(`tokens.${mode}.colour.surface.overlay`),
    "tertiary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.tertiary-transparent`,
    ),
  },
  border: {
    primary: getJsonValue(`tokens.${mode}.colour.border.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.border.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.border.tertiary`),
  },
  buttons: {
    default: getJsonValue(`tokens.${mode}.colour.buttons.default`),
    "default-hover": getJsonValue(
      `tokens.${mode}.colour.buttons.default-hover`,
    ),
  },
  analytics: {
    blue: getJsonValue(`tokens.${mode}.colour.analytics.blue`),
    red: getJsonValue(`tokens.${mode}.colour.analytics.red`),
    purple: getJsonValue(`tokens.${mode}.colour.analytics.purple`),
    yellow: getJsonValue(`tokens.${mode}.colour.analytics.yellow`),
  },
});

export const createThemeBuilder = (mode: "light" | "dark"): CustomTheme => {
  const basePallete = makePalette(mode);
  const palette = makeCtx(mode);

  return createTheme({
    typography: typography,
    customs: {
      spacing: {
        grid: {
          "margin-page-desktop": 64,
          "margin-page-mobile": 20,
          "margin-panel-desktop": 20,
          "margin-panel-mobile": 20,
        },
      },
      radius: {
        small: 12,
        medium: 20,
        large: 32,
      },
    },
    palette: {
      mode,
      ...basePallete,
      ...palette,
    },
    components: {
      MuiButton: {
        variants: [
          ...getLargeFilledVariants(mode, basePallete),
          ...getLargeOutlinedVariants(mode, basePallete),
          ...getLargeGhostVariants(mode),
          ...getSmallFilledVariants(mode, basePallete),
          ...getSmallmallOutlinedVariants(mode, basePallete),
          ...getSmallGhostVariants(mode),
        ],
      },
      MuiInput: {
        ...getCustomBaseInputStyle(mode, basePallete),
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: getJsonValue(
              `tokens.${
                mode === "light" ? "dark" : "light"
              }.colour.surface.primary`,
            ),
          },
        },
        variants: [...getCustomTypographyStyle(typography)],
      },
    },
  } as any);
};
