import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TAdditionalDetails } from "../data.types";
import { BaseModal } from "@common/Modal";
import { Grid, Link, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { CloseIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { ArrowSquareIn } from "@assets/transactionsRiskProfileIcons";

const AdditionalInfoModal = NiceModal.create(
  ({ data }: { data: TAdditionalDetails }) => {
    const modal = useModal();

    const handleClose = () => modal.hide();

    return (
      <BaseModal
        data-testid="additional-info-modal"
        open={modal.visible}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "480px",
            backgroundColor: "rgba(255, 255, 255, 0.80)",
            boxShadow: "0px 60px 180px 0px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(20px)",
            overflow: "hidden",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(53, 53, 53, 0.15)",
            backdropFilter: "blur(3px)",
          },
        }}
      >
        <Stack
          direction="column"
          gap="8px"
          alignItems="stretch"
          padding="16px"
          sx={{
            overflowY: "scroll",
            "::-webkit-scrollbar-track": {
              marginBlock: "16px",
            },
          }}
        >
          <ModalTitle onClose={handleClose} />
          <Section items={data.slice(0, 8)} />
          <Section items={data.slice(8, 20)} />
          <Section items={data.slice(20, data.length)} />
        </Stack>
      </BaseModal>
    );
  },
);

type StatType = {
  label: string;
  value: number | string;
  isLink?: boolean;
};

const Section = ({ items }: { items: StatType[] }) => {
  return (
    <Grid container rowSpacing="8px" columnSpacing="10px" padding="8px 4px">
      {items.map(({ value, label, isLink }) => (
        <>
          <Grid item xs={6} key={label}>
            <Text
              color={palette.gray[300]}
              fontWeight="book"
              lineHeight="16.8px"
              flexGrow={1}
            >
              {label}
            </Text>
          </Grid>
          <Grid item xs={6} key={`${label} - ${value}`}>
            {isLink && typeof value === "string" ? (
              <CustomLink href={value} target="_blank">
                Open <ArrowSquareIn />
              </CustomLink>
            ) : (
              <Text color={palette.black[100]} lineHeight="16.8px" flexGrow={1}>
                {value}
              </Text>
            )}
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const CustomLink = styled(Link)(() => ({
  lineHeight: "16.8px",
  flexGrow: 1,
  fontSize: "14px",
  fontWeight: 400,
  color: "#6D9CF8",
}));

const ModalTitle = ({ onClose }: { onClose: () => void }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Text
        color={palette.black[100]}
        fontSize="18px"
        lineHeight="21.6px"
        letterSpacing="-0.18px"
      >
        Additional details
      </Text>
      <Button onClick={onClose} background="tertiary" sx={{ padding: "8px" }}>
        <CloseIcon stroke={palette.gray[300]} />
      </Button>
    </Stack>
  );
};

export default AdditionalInfoModal;
