import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { palette } from "@palette";
import ModalContent from "./ModalContent";
import { FormProvider } from "react-hook-form";
import useFilterSections from "./hooks/useFilterSections";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

interface ITableFilterModal {
  tab: "portfolio" | "underwriting" | "risk";
}

const TableFilterModal = NiceModal.create(({ tab }: ITableFilterModal) => {
  const { isMobileView } = useCustomTheme();

  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  const {
    sections,
    methods,
    onSubmit,
    handleReset,
    areFiltersSelected,
    clearAllEnabled,
  } = useFilterSections({
    type: "merchant",
    tab,
    onClose,
  });

  const actions = {
    primaryAction: {
      label: "Apply filters",
      disabled: !areFiltersSelected,
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
      },
    },
    secondaryAction: {
      label: "Clear all filters",
      onClick: () => {
        handleReset();
      },
      sx: {
        fontSize: "18px",
        lineHeight: "18px",
        padding: "12px 24px",
        color: palette.filled.red,
      },
      hidden: !clearAllEnabled,
    },
  };
  return (
    <ModalFactory
      variant="sidepanel"
      modalProps={{
        open,
        onClose: onClose,
        SlideProps,
        SidePanelProps: {
          width: "500px",
          PaperProps: {
            TransitionProps,
          },
        },
        DrawerProps: {
          PaperProps: {
            TransitionProps,
          },
        },
      }}
    >
      <FormProvider {...methods}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          sx={{
            padding: "16px",
            gap: "24px",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
            ...(isMobileView && {
              paddingX: "8px",
              paddingY: 0,
              gap: "16px",
            }),
          }}
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ModalTitle
            title="Filter by"
            textStyles={{
              title: {
                fontSize: "18px",
                lineHeight: "21.6px",
                letterSpacing: "-0.18px",
              },
            }}
            closeButtonSize={24}
            onClose={onClose}
          />
          <ModalContent sections={sections} />
          <ModalActions
            animationDelay={250}
            {...actions}
            {...(isMobileView && {
              containerProps: {
                sx: {
                  flexDirection: "column-reverse",
                  gap: "8px",
                  padding: "8px",
                },
              },
            })}
          />
        </Box>
      </FormProvider>
    </ModalFactory>
  );
});

export default TableFilterModal;
