import React, { memo, useEffect } from "react";
import { Box, SxProps, styled } from "@mui/material";
import Input, { InputProps } from "@mui/material/Input";
import { StyledIconButton } from "@common/IconButton";
import { CloseIcon, SearchIcon } from "@assets/rebrandIcons";
import { palette } from "@palette";
import { useAppDispatch } from "@redux/hooks";
import { updateSearchQuery } from "@redux/slices/fundraisers";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  resetSearchQueryByKey,
  setSearchQueryByKey,
} from "@redux/slices/search";
import _ from "lodash";

type SearchBarProps = {
  onSubmit?: () => void;
  productType?: string;
  category?: string;
  formSx?: SxProps;
  subCategory?: string;
  customId?: string | number;
  sortBy?: string;
  otherParams?: Record<string, any>;
  onBlur?:
    | React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  handleChange?: (value: string) => void;
  stateAction?: ActionCreatorWithPayload<string, any>;
  queryKey?: string;
  transparent?: boolean;
  collapse?: () => void;
  IOCsetQueryString?:
    | React.Dispatch<React.SetStateAction<string>>
    | ((value: string) => void);
  IOCqueryString?: string;
  clearOnUnmount?: boolean;
  isExpandedByDefault?: boolean;
  initialValue?: string;
  inputSx?: SxProps;
  isDebounceSearch?: boolean;
  searchOnEnter?: boolean;
  customDeleteIcon?: React.ReactElement;
};

const SearchBar: React.FC<SearchBarProps & InputProps> = ({
  formSx,
  onBlur,
  stateAction,
  handleChange,
  queryKey,
  transparent,
  collapse,
  IOCsetQueryString,
  IOCqueryString,
  clearOnUnmount = true,
  isExpandedByDefault,
  initialValue,
  inputSx,
  isDebounceSearch = false,
  searchOnEnter = false,
  customDeleteIcon,
  ...props
}) => {
  const [queryString, setQueryString] = React.useState<string>("");
  const dispatch = useAppDispatch();

  const searchQueryDispatcher = (value: string) => {
    if (queryKey) {
      dispatch(
        setSearchQueryByKey({
          queryKey,
          params: {
            value,
          },
        }),
      );
    } else if (stateAction) {
      // if we have a particular state for the search query
      dispatch(stateAction(value));
    } else {
      // else we store the search query in the default state
      // (in the future we need to change the default state.
      // now it's in the fundraiser state should be in more generic state)
      dispatch(updateSearchQuery(value));
    }
  };

  const startSearch = (searchValue: string) => {
    if (handleChange) {
      handleChange(searchValue);
      return;
    }
    searchQueryDispatcher(searchValue);
  };
  const debounceSearch = React.useMemo(
    () =>
      _.debounce((searchValue: string) => {
        startSearch(searchValue);
      }, 500),
    [],
  );

  useEffect(() => {
    if (initialValue) setQueryString(initialValue);

    return () => {
      if (clearOnUnmount && queryKey) dispatch(resetSearchQueryByKey(queryKey));
      isDebounceSearch && debounceSearch.cancel(); // Cancel the debounce function if the component is unmounting
    };
  }, [isDebounceSearch]);

  useEffect(() => {
    // Call the debounce function whenever queryString changes
    isDebounceSearch && debounceSearch(IOCqueryString ?? queryString);

    // Make sure to cancel the debounce on cleanup
    return () => {
      isDebounceSearch && debounceSearch.cancel();
    };
  }, [queryString, IOCqueryString, isDebounceSearch]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    startSearch(IOCqueryString ?? queryString);
  };

  const handleDeleteText = () => {
    if (IOCsetQueryString) {
      IOCsetQueryString("");
    } else {
      setQueryString("");
    }

    startSearch("");
    if (collapse) {
      collapse();
    }
  };

  const showEraseIcon = !!IOCqueryString || !!queryString;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={formSx}>
      <StyledInput
        transparent={transparent}
        placeholder="Search..."
        disableUnderline
        sx={inputSx}
        isExpandedByDefault={isExpandedByDefault}
        endAdornment={
          showEraseIcon ? (
            <StyledIconButton
              onClick={handleDeleteText}
              sx={{
                maxHeight: "18px",
                maxWidth: "18px",
              }}
              data-testid="search-input-clear-button"
            >
              {customDeleteIcon ? (
                customDeleteIcon
              ) : (
                <CloseIcon width={18} height={18} color={palette.gray[300]} />
              )}
            </StyledIconButton>
          ) : (
            <Box width={18} height={18} />
          )
        }
        startAdornment={
          <SearchIcon width={24} height={24} stroke={palette.gray[200]} />
        }
        onChange={(e) => {
          const v = e.target.value.replace(/[!()]+/g, "");
          if (IOCsetQueryString) {
            IOCsetQueryString(v);
          } else {
            setQueryString(v);
          }
        }}
        onKeyDown={(e) => {
          if (searchOnEnter && ["Enter", "NumpadEnter"].includes(e.code))
            handleSubmit(e as any);
        }}
        value={IOCqueryString ?? queryString}
        onBlur={onBlur}
        {...props}
      />
    </Box>
  );
};

type StyledInputProps = {
  transparent?: boolean;
  isExpandedByDefault?: boolean;
};

const StyledInput = styled(Input, {
  shouldForwardProp: (prop) =>
    prop !== "transparent" && prop !== "isExpandedByDefault",
})<StyledInputProps>(({ theme, transparent, isExpandedByDefault }) => ({
  background: transparent ? "transparent" : palette.common.white,
  border: `1px solid ${palette.neutral[10]}`,
  borderRadius: "90px",
  width: isExpandedByDefault ? "100%" : "350px",
  padding: "4px 12px",
  height: "32px",

  "& input": {
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 350,
    color: palette.black[300],
    padding: 0,
    height: "24px",
  },
  "& input::-webkit-input-placeholder": {
    color: palette.gray[100],
  },
  "& input:placeholder-shown ~ div": {
    display: "none",
  },
  "&:has(input:focus)": {
    boxSizing: "border-box",
    outline: `none`,
  },
  "&:has(.Mui-disabled)": {
    background: palette.neutral[10],
    border: "none",
    outline: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "& input": {
      fontSize: "16px",
      lineHeight: "19.2px",
    },
  },
}));

export default memo(SearchBar);
