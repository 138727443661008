import React from "react";
import { Grid } from "@mui/material";
import { RHFInput } from "@common/Input";
import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import { RHFSelect } from "@common/Select";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { palette } from "@palette";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { useFormContext } from "react-hook-form";

export function OwnerInformations({ disabled }: { disabled?: boolean }) {
  const { selectCountriesList } = useCountryCodes();

  const inputList = [
    {
      name: "whenIsOwner.address.country",
      input: (
        <RHFSelect
          name="whenIsOwner.address.country"
          label="Country"
          placeholder="Country"
          disabled
          value="US"
          options={selectCountriesList}
        />
      ),
    },
    {
      name: "whenIsOwner.address.line1",
      input: (
        <RHFStreetAddress
          name="whenIsOwner.address.line1"
          label="Street address"
          placeholder="Enter Street Address..."
          fullWidth
          disabled={disabled}
        />
      ),
    },
    {
      name: "whenIsOwner.address.city",
      input: (
        <RHFInput
          name="whenIsOwner.address.city"
          label="City"
          placeholder="Enter city..."
          fullWidth
          disabled={disabled}
        />
      ),
    },
    {
      name: "whenIsOwner.address.state",
      input: (
        <ProvinceInput
          name="whenIsOwner.address.state"
          isUS={true}
          disabled={disabled}
        />
      ),
    },
    {
      name: "whenIsOwner.address.zip",
      input: (
        <ZipCodeInput
          name="whenIsOwner.address.zip"
          label="ZIP"
          placeholder="Enter Zip Code"
          letterSpacing="4px"
          disabled={disabled}
        />
      ),
    },
  ];

  return (
    <Grid container rowSpacing={1} columnSpacing={2} sx={containerStyle}>
      {inputList.map(({ input }, index) => (
        <Grid key={index} item xs={12}>
          <FadeUpWrapper delay={100 * (index + 1)}>{input}</FadeUpWrapper>
        </Grid>
      ))}
    </Grid>
  );
}

const containerStyle = {
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: palette.liftedWhite[200],
    boxShadow: "none",
    border: "none",

    "& input": {
      marginTop: "18px",
      WebkitTextFillColor: palette.black[100],
      color: palette.black[100],
    },
    "& .MuiSelect-select div": {
      WebkitTextFillColor: palette.black[100],
    },
  },
};
