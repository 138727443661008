import { Text, TruncateText } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import ProfilePicture from "@components/CustomBreadcrumbsPage/ components/ProfilePicture";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { TProfileTab } from "./types";

type Props = {
  sidebarList: {
    tab: TProfileTab;
    active: boolean;
  }[];
  handleSelectTab: (tab: TProfileTab) => void;
};

const ProfileModalSidebar = ({ sidebarList, handleSelectTab }: Props) => {
  const { globalName, email } = useUserReducer();

  return (
    <Container>
      <Stack direction="row" p="8px 0px" spacing={1} mb={2}>
        <ProfilePicture
          sx={{
            cursor: "default",
            "&:hover": {
              borderColor: "transparent",
              "& * > path": {
                fill: palette.neutral.white,
              },
            },
          }}
        />
        <Stack spacing={0.5}>
          <TruncateText
            lineClamp={1}
            color={palette.neutral[80]}
            fontWeight="book"
            lineHeight="16.8px"
          >
            {globalName?.firstName} {globalName?.lastName}
          </TruncateText>
          <TruncateText
            lineClamp={1}
            fontSize={12}
            color={palette.neutral[70]}
            fontWeight="book"
            lineHeight="16.8px"
            sx={{
              wordBreak: "break-all",
            }}
          >
            {email}
          </TruncateText>
        </Stack>
      </Stack>
      <Stack spacing={1.5}>
        {sidebarList.map((item) => (
          <Tab
            key={item.tab}
            active={item.active}
            onClick={() => handleSelectTab(item.tab)}
          >
            {item.tab}
          </Tab>
        ))}
      </Stack>
    </Container>
  );
};

const Tab = styled(Text, {
  shouldForwardProp: (prop) => prop !== "active",
})<ITextProps & { active: boolean }>(({ active }) => ({
  color: palette.neutral[70],
  fontWeight: 350,
  cursor: "pointer",
  ...(active && {
    color: palette.neutral[100],
    borderRight: `4px solid ${palette.neutral[100]}`,
  }),
}));

const Container = styled(Box)(() => ({
  padding: "24px 0px 24px 16px",
  borderRight: `1px solid ${palette.neutral[10]}`,
  background: "transparent",

  height: "100%",
}));

export default ProfileModalSidebar;
