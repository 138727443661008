import { Components } from "@mui/material";
// import { palette } from "@palette";
import {
  CheckboxIcon,
  CheckboxCheckedIcon,
  CheckboxIndeterminateIcon,
} from "@assets/rebrandIcons";

export const checkbox: Components["MuiCheckbox"] = {
  defaultProps: {
    disableRipple: true,
    icon: <CheckboxIcon />,
    checkedIcon: <CheckboxCheckedIcon />,
    indeterminateIcon: <CheckboxIndeterminateIcon />,
  },

  styleOverrides: {
    root: {
      padding: "0 9px 0 9px",
      // Show arrow on hover
      ":not(&.Mui-checked):not(&.MuiCheckbox-indeterminate):not(&.Mui-disabled) input:hover + svg > g > path":
        {
          stroke: "#E1E1DE",
        },
      // fill when disabled
      ":not(&.Mui-checked):not(&.MuiCheckbox-indeterminate) input:disabled + svg":
        {
          fill: "#B8B8B8",
        },

      "&.Mui-checked, &.MuiCheckbox-indeterminate": {
        "input:hover + svg": {
          borderRadius: 6,
          boxShadow: "0px 0px 2px #8F8F8F, 0px 0px 0px 2px #8F8F8F",
        },

        "input:disabled + svg": {
          boxShadow: "none",
          backgroundColor: "#A8A8A8",
        },
      },

      "input:focus + svg": {
        borderRadius: 6,
        boxShadow: "0px 0px 2px #8F8F8F, 0px 0px 0px 2px #8F8F8F",
      },

      "& .MuiSvgIcon-fontSizeSmall": {
        width: 18,
        height: 18,
      },
    },
  },
};
