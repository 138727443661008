import { palette } from "@palette";
import { Stack } from "@mui/material";
import React from "react";
import { TickIcon } from "@assets/icons";

interface ISelectedTickIconProps {
  width?: number;
  height?: number;
}

const SelectedTickIcon = ({
  width = 32,
  height = 32,
}: ISelectedTickIconProps) => {
  return (
    <Stack
      flex={1}
      width={width}
      height={height}
      alignItems="flex-end"
      justifyContent="center"
    >
      <TickIcon stroke={palette.black[100]} width={15} height={15} />
    </Stack>
  );
};

export default SelectedTickIcon;
