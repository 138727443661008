import {
  parseButtonPositionStyle,
  parseImageAlignment,
  parseImageCssPropToStyleAttr,
} from "../../components/SidePanelContent/SingleImageWidget/helpers";
import {
  selectedItem,
  globalButtonStyles,
  DEFAULT_BORDER_COLOR,
} from "../../signals";
import { headingsSignal, mobileHeadingsSignal, t } from "../headings";
import { icons } from "../icons";
import pl from "@assets/images/widgetImagePlaceholder.png";
import { treeBuilder } from "../widgets/widgetNodeBuilder";
import { widgetFactory } from "../widgets/widgetFactory";
import {
  cardItemsContainerBlueprint,
  imageCardHeadingBlueprint,
  imageCardImageBlueprint,
  imageCardItemWrapper,
  imageDescriptionBlueprint,
} from "./helpers/imageCard";
import {
  imageWithTextContentWrapperBlueprint,
  imageWithTextDescriptionBlueprint,
  imageWithTextHeadingBlueprint,
  imageWithTextImageBlueprint,
  imageWithTextImageContainerBlueprint,
  imageWithTextRootBlueprint,
} from "./helpers/imageWithText";
import {
  IMAGE_CARD_CARD_IMAGE_CONTAINER,
  IMAGE_CARD_CARD_ITEM_BLUEPRINT,
  IMAGE_CARD_DESCRIPTION_BLUEPRINT,
  IMAGE_CARD_HEADING_BLUEPRINT,
  IMAGE_CARD_IMAGE_BLUEPRINT,
  IMAGE_GALLERY_ROOT_BLUEPRINT,
  IMAGE_GALLERY_IMAGE_BLUEPRINT,
  IMAGE_WITH_TEXT_CONTENT_WRAPPER_BLUEPRINT,
  IMAGE_WITH_TEXT_DESCRIPTION_BLUEPRINT,
  IMAGE_WITH_TEXT_HEADING_BLUEPRINT,
  IMAGE_WITH_TEXT_IMAGE_BLUEPRINT,
  IMAGE_WITH_TEXT_IMAGE_CONTAINER_BLUEPRINT,
  IMAGE_WITH_TEXT_ROOT,
} from "./helpers";
import { getDeviceSpecificKey } from "@pages/NewAdvancedBuilder/utils/helpers";
import {
  imageGalleryImageBlueprint,
  imageGalleryRootBlueprint,
} from "./helpers/imageGallery";

export const imageWithTextNodeBlueprint = treeBuilder()
  .setRoot(imageWithTextRootBlueprint())
  .addNode(2, imageWithTextImageContainerBlueprint())
  .addNode(100, imageWithTextImageBlueprint())
  .addNode(2, imageWithTextContentWrapperBlueprint())
  .addNode(11, imageWithTextHeadingBlueprint())
  .addNode(11, imageWithTextDescriptionBlueprint());

const imageGalleryNodeBlueprint = treeBuilder()
  .setRoot({
    tag: "div",
    isHead: true,
    metaAttributes: {},
    styleParser: () => ({
      margin: "0",
      display: "flex",
      width: "100%",
      paddingLeft: "0",
      overflow: "hidden",
    }),
  })
  .addNode(2, imageGalleryRootBlueprint());

const imageCardNodeBlueprint = treeBuilder()
  .setRoot({
    tag: "div",
    isHead: true,
    metaAttributes: {},
    styleParser: () => ({
      margin: "0",
      display: "flex",
      width: "100%",
      paddingLeft: "0",
      flexDirection: "column",
      overflow: "hidden",
    }),
  })
  .addNode(2, imageCardHeadingBlueprint())
  .addNode(2, imageDescriptionBlueprint())
  .addNode(2, {
    ...cardItemsContainerBlueprint(),
    childrens: [
      imageCardItemWrapper(1, [
        imageCardImageBlueprint({ imageSrc: `url("${pl}")` }),
        imageCardHeadingBlueprint({
          headingContent: "Add heading text...",
        }),
        imageDescriptionBlueprint({
          descriptionContent: "Add description text...",
        }),
      ]),
      imageCardItemWrapper(2, [
        imageCardImageBlueprint({ imageSrc: `url("${pl}")` }),
        imageCardHeadingBlueprint({
          headingContent: "Add heading text...",
        }),
        imageDescriptionBlueprint({
          descriptionContent: "Add description text...",
        }),
      ]),
      imageCardItemWrapper(3, [
        imageCardImageBlueprint({ imageSrc: `url("${pl}")` }),
        imageCardHeadingBlueprint({
          headingContent: "Add heading text...",
        }),
        imageDescriptionBlueprint({
          descriptionContent: "Add description text...",
        }),
      ]),
    ],
  });

export const widgets = {
  image_text: Object.freeze({
    id: "image_text",
    tabs: 2,
    title: "Image + Text",
    icon: icons.image_text,
    isEnabled: true,
    tagName: imageWithTextNodeBlueprint,
    state: Object.freeze({
      backgroundSize: {
        value: "fill",
        name: "Fill",
      },
      imageSrc: `url("${pl}")`,
      imagePosition: 1, //1-left;2-right
      imageSize: "40", // 40 | 50 | 60
      yAlignment: "center", //top | center  | bottom
      headingTextAlign: "start", //start | center  | end
      descriptionContentTextAlign: "start", //start | center  | end,
      headingContent: "Add text here...",
      descriptionContent: "Add description text...",
      dataHidden: "false",
    }),
    blueprints: {
      [IMAGE_WITH_TEXT_ROOT]: imageWithTextRootBlueprint,
      [IMAGE_WITH_TEXT_IMAGE_CONTAINER_BLUEPRINT]:
        imageWithTextImageContainerBlueprint,
      [IMAGE_WITH_TEXT_IMAGE_BLUEPRINT]: imageWithTextImageBlueprint,
      [IMAGE_WITH_TEXT_CONTENT_WRAPPER_BLUEPRINT]:
        imageWithTextContentWrapperBlueprint,
      [IMAGE_WITH_TEXT_HEADING_BLUEPRINT]: imageWithTextHeadingBlueprint,
      [IMAGE_WITH_TEXT_DESCRIPTION_BLUEPRINT]:
        imageWithTextDescriptionBlueprint,
    },
  }),
  divider: {
    id: "divider",
    tabs: 1,
    title: "Divider",
    isEnabled: true,
    tagName: "div",
    icon: icons.divider,
    metaAttributes: {},
    tabletMetaAttributes: {},
    mobileMetaAttributes: {},
    cssProperties: {
      thickness: 1,
      type: {
        value: "solid",
      },
      bgColor: {
        color: "#292727",
        name: "Paragraph",
      },
      marginTop: {
        value: 6,
        name: "Small",
      },
    },
    styleParser: (props: any) => {
      return {
        borderTop: `${props.thickness}px ${props.type.value} ${props.bgColor.color}`,
        paddingBottom: `${props.marginTop.value}px`,
        display: props.display,
        marginTop: `${props.marginTop.value}px`,
      };
    },
  },
  heading: {
    id: "heading",
    tabs: 2,
    title: "Heading",
    isEnabled: true,
    tagName: "h2",
    content: "Add your text here",
    icon: icons.heading,
    cssProperties: {
      fontFamily: {
        name: "Roboto",
        value: "Roboto",
      },
      fontWeight: {
        name: "Regular",
        value: "400",
      },
      textColor: null,
      fontSize: 34,
      lineHeight: 100,
      letterSpacing: 0,
      textAlign: "left",
    },
    styleParser: (props: any) => {
      const colorProps = !props.textColor?.color
        ? {}
        : props.textColor.color.includes("linear")
        ? {
            backgroundImage: props.textColor.color,
            backgroundClip: "text",
            webkitTextFillColor: "transparent",
          }
        : {
            color: props.textColor.color,
            webkitTextFillColor: "initial",
          };

      return {
        fontFamily: props.fontFamily.value,
        color: props.textColor?.color ?? "#000",
        fontSize: `${props.fontSize}px`,
        lineHeight: `${props.lineHeight}%`,
        letterSpacing: `${props.letterSpacing}px`,
        fontWeight: `${props.fontWeight.value}`,
        margin: "0",
        maxWidth: "100%",
        wordWrap: "break-word",
        wordBreak: "break-word",
        textAlign: props.textAlign,
        display: props.display,
        whiteSpace: "pre-wrap",
        ...colorProps,
      };
    },
  },

  single_image: {
    id: "single_image",
    tabs: 2,
    title: "Single Image",
    isEnabled: true,
    tagName: "img",
    icon: icons.single_image,
    cssProperties: {
      height: 300,
      imgSrc: "",
      imageType: {
        value: "fill",
        name: "Fill",
      },
      alignment: {
        value: "center",
        name: "Center",
      },
      borderStyle: {
        value: "none",
        label: "None",
      },
      borderColor: DEFAULT_BORDER_COLOR,
      opacity: 100,
      borderWidth: 0,
      radius: 0,
    },
    styleParser: (props: any) => {
      return {
        borderColor: props.borderColor?.color ?? props.borderColor.color,
        borderStyle: props.borderStyle?.value ?? "none",
        borderRadius: props.radius ? `${props.radius}px` : "0px",
        borderWidth: props.borderWidth ? `${props.borderWidth}px` : "0px",
        opacity: props.opacity ? `${props.opacity / 100}` : "1",
        margin: "0",
        width: props.width ? `${props.width}%` : "100%",
        height: props.height ? `${props.height}px` : "300px",
        maxHeight: props.maxHeight
          ? `${(props.maxHeight * 300) / 100}px`
          : "300px",
        content: props.imgSrc ? `url(${props.imgSrc}/large)` : `url(${pl})`,
        display: props.display,
        ...parseImageCssPropToStyleAttr(props.imageType),
        ...parseImageAlignment(props.alignment?.value),
      };
    },
  },
  button: {
    id: "button",
    tabs: 2,
    title: "Button",
    isEnabled: true,
    tagName: "a",
    icon: icons.button,
    content: "New Button",
    cssProperties: {
      padding: "8px 16px",
      fontSize: "14px",
      borderRadius: "40px",
      border: "none",
      textAlign: "center",
      bgColor: null,
      textColor: null,
      textDecoration: "none",
      alignSelf: "center",
      display: "block",
    },
    styleParser: (props: any) => {
      const bgValue = props?.bgColor?.color;
      const defaultBg = bgValue ? "initial" : "#6F68DE";
      const textColor = props?.textColor?.color;
      delete props.bgColor;
      delete props.textColor;
      return {
        ...props,
        width: props.alignSelf === "auto" ? "100%" : "auto",
        ...(bgValue &&
          !bgValue.startsWith("#") && {
            backgroundImage: bgValue,
          }),

        backgroundColor:
          bgValue && bgValue.startsWith("#") ? bgValue : defaultBg,
        color: textColor || "#FFFFFF",
        ...parseButtonPositionStyle(props.alignSelf),
      };
    },
  },

  image_card: Object.freeze({
    id: "image_card",
    tabs: 2,
    title: "Image Card",
    icon: icons.image_card,
    isEnabled: true,
    tagName: imageCardNodeBlueprint,
    blueprints: {
      [IMAGE_CARD_IMAGE_BLUEPRINT]: imageCardImageBlueprint,
      [IMAGE_CARD_HEADING_BLUEPRINT]: imageCardHeadingBlueprint,
      [IMAGE_CARD_DESCRIPTION_BLUEPRINT]: imageDescriptionBlueprint,
      [IMAGE_CARD_CARD_ITEM_BLUEPRINT]: imageCardItemWrapper,
      [IMAGE_CARD_CARD_IMAGE_CONTAINER]: cardItemsContainerBlueprint,
    },
    props: {
      [IMAGE_CARD_IMAGE_BLUEPRINT]: (props: any, parent: any, type: any) => ({
        imageSrc: props?.[getDeviceSpecificKey("style", type)]?.content,
      }),
      [IMAGE_CARD_HEADING_BLUEPRINT]: (props: any, parent: any, type: any) =>
        parent.blueprintID !== 2
          ? {
              headingContent: props?.[getDeviceSpecificKey("content", type)],
            }
          : null,
      [IMAGE_CARD_DESCRIPTION_BLUEPRINT]: (
        props: any,
        parent: any,
        type: any,
      ) =>
        parent.blueprintID !== 2
          ? {
              descriptionContent:
                props?.[getDeviceSpecificKey("content", type)],
            }
          : null,
    },
    state: Object.freeze({
      heading: {
        content: "Your subtitle here",
        fontFamily: {
          name: "Roboto",
          value: "Roboto",
        },
        fontWeight: {
          name: "Medium",
          value: "500",
        },
        textColor: {
          name: "Heading",
          color: "#111111",
        },
        fontSize: "24",
        lineHeight: "100",
        letterSpacing: "0",
      },
      description: {
        content: "Your description here",
        fontFamily: {
          name: "Roboto",
          value: "Roboto",
        },
        fontWeight: {
          name: "Regular",
          value: "400",
        },
        textColor: {
          name: "Paragraph",
          color: "#292727",
        },
        fontSize: "16",
        lineHeight: "100",
        letterSpacing: "0",
      },
      gridTemplateColumns: "", // 3 | 2 | 1
      imageBorderRadius: "0",
      imageBorderWidth: "0",
      imageBorderStyle: {
        value: "none",
        label: "None",
      },
      imageBorderColor: DEFAULT_BORDER_COLOR,
    }),
  }),
  intro: {
    id: "intro",
    title: "Intro",
    icon: icons.intro,
    isEnabled: false,
  },
  rich_text: {
    id: "rich_text",
    title: "Rich Text",
    icon: icons.rich_text,
    isEnabled: false,
  },
  image_gallery: Object.freeze({
    id: "image_gallery",
    tabs: 2,
    title: "Image Gallery",
    icon: icons.image_gallery,
    isEnabled: true,
    tagName: imageGalleryNodeBlueprint,
    blueprints: {
      [IMAGE_GALLERY_ROOT_BLUEPRINT]: imageGalleryRootBlueprint,
      [IMAGE_GALLERY_IMAGE_BLUEPRINT]: imageGalleryImageBlueprint,
    },
    props: {
      [IMAGE_GALLERY_IMAGE_BLUEPRINT]: (
        props: any,
        parent: any,
        type: any,
      ) => ({
        imageSrc: props?.[getDeviceSpecificKey("style", type)]?.content,
      }),
    },
    state: Object.freeze({
      aspectRatio: "1/1",
      gap: "0",
      gridTemplateColumns: "",
      imageBorderRadius: "0",
      imageBorderWidth: "0",
      imageBorderStyle: {
        value: "none",
        label: "None",
      },
      imageBorderColor: DEFAULT_BORDER_COLOR,
      /* By default we don't have images in image gallery widget.
         In default case (no images) we display background image and height is fixed. This state value helps tracking which behaviour to use. 
       */
      hasImage: "false",
    }),
  }),
  single_video: {
    id: "single_video",
    title: "Video",
    icon: icons.single_video,
    isEnabled: false,
  },
  social: {
    id: "social",
    title: "Social",
    icon: icons.social,
    isEnabled: false,
  },
  goal: {
    id: "goal",
    title: "Goal",
    icon: icons.goal,
    isEnabled: false,
  },
};

export const layoutItems = [
  widgetFactory(widgets.image_text),
  widgetFactory(widgets.divider),
  widgetFactory(widgets.image_card),
];

const withGlobalStyle = (inj: any) => {
  const v: t = (selectedItem.value?.tagName as t) ?? "h2";

  const tagName: t = selectedItem.value?.tagName ?? (v === "body" ? "p" : v);

  return widgetFactory(inj(v, tagName));
};

export const elementItems = () => {
  return [
    widgetFactory(widgets.intro),
    withGlobalStyle(
      (v: t, tagName: "h2" | "h1" | "h3" | "h4" | "h5" | "h6" | "body") => {
        return {
          ...widgets.heading,
          tagName: tagName,
          tabletTagName: tagName,
          mobileTagName: tagName,
          metaAttributes: {
            "data-fontWeight": headingsSignal[v].fontWeight?.value?.name,
            "data-textColorName": headingsSignal[v].textColor?.value?.name,
            "data-fontFamily": headingsSignal[v].fontFamily.value?.name,
          },
          tabletMetaAttributes: {
            "data-fontWeight": mobileHeadingsSignal[v].fontWeight?.value?.name,
            "data-textColorName":
              mobileHeadingsSignal[v].textColor?.value?.name,
            "data-fontFamily": mobileHeadingsSignal[v].fontFamily.value?.name,
          },
          mobileMetaAttributes: {
            "data-fontWeight": mobileHeadingsSignal[v].fontWeight?.value?.name,
            "data-textColorName":
              mobileHeadingsSignal[v].textColor?.value?.name,
            "data-fontFamily": mobileHeadingsSignal[v].fontFamily.value?.name,
          },
          cssProperties: {
            ...widgets.heading.cssProperties,
            fontFamily:
              headingsSignal[v].fontFamily.value ??
              widgets.heading.cssProperties.fontFamily,
            fontWeight:
              headingsSignal[v].fontWeight.value ??
              widgets.heading.cssProperties.fontWeight,
            fontSize:
              headingsSignal[v].fontSize.value ??
              widgets.heading.cssProperties.fontSize,
            lineHeight:
              headingsSignal[v].lineHeight.value ??
              widgets.heading.cssProperties.lineHeight,
            letterSpacing:
              headingsSignal[v].letterSpacing.value ??
              widgets.heading.cssProperties.letterSpacing,
            textColor:
              headingsSignal[v].textColor?.value ??
              widgets.heading.cssProperties.textColor,
          },
          mobileCssProperties: {
            ...widgets.heading.cssProperties,
            fontFamily:
              mobileHeadingsSignal[v].fontFamily.value ??
              widgets.heading.cssProperties.fontFamily,
            fontWeight:
              mobileHeadingsSignal[v].fontWeight.value ??
              widgets.heading.cssProperties.fontWeight,
            fontSize:
              mobileHeadingsSignal[v].fontSize.value ??
              widgets.heading.cssProperties.fontSize,
            lineHeight:
              mobileHeadingsSignal[v].lineHeight.value ??
              widgets.heading.cssProperties.lineHeight,
            letterSpacing:
              mobileHeadingsSignal[v].letterSpacing.value ??
              widgets.heading.cssProperties.letterSpacing,
            textColor:
              mobileHeadingsSignal[v].textColor?.value ??
              widgets.heading.cssProperties.textColor,
          },
        };
      },
    ),
    widgetFactory(widgets.rich_text),
    widgetFactory(widgets.single_image),
    withGlobalStyle(
      (v: t, tagName: "h2" | "h1" | "h3" | "h4" | "h5" | "h6" | "body") => {
        return {
          ...widgets.button,
          metaAttributes: {
            "data-bgColorName": globalButtonStyles.backgroundColor.peek().name,
            "data-textColorName": globalButtonStyles.textColor.peek().name,
          },
          tabletMetaAttributes: {
            "data-bgColorName": globalButtonStyles.backgroundColor.peek().name,
            "data-textColorName": globalButtonStyles.textColor.peek().name,
          },
          mobileMetaAttributes: {
            "data-bgColorName": globalButtonStyles.backgroundColor.peek().name,
            "data-textColorName": globalButtonStyles.textColor.peek().name,
          },
          cssProperties: {
            ...widgets.button.cssProperties,
            padding: globalButtonStyles.padding.peek(),
            fontSize: globalButtonStyles.fontSize.peek(),
            borderRadius: globalButtonStyles.borderRadius.peek(),
            bgColor: globalButtonStyles.backgroundColor.peek(),
            textColor: globalButtonStyles.textColor.peek(),
          },
        };
      },
    ),
    widgetFactory(widgets.image_gallery),
    widgetFactory(widgets.single_video),
    widgetFactory(widgets.social),
    widgetFactory(widgets.goal),
  ];
};
