import { DeleteIconV2 } from "@assets/icons/RebrandedIcons";
import { ButtonBase } from "@mui/material";
import {
  lastItemSelected,
  selectedItem,
} from "@pages/NewAdvancedBuilder/signals";
import { builder } from "@pages/NewAdvancedBuilder/utils";
import { SyntheticEvent, useState } from "react";

const DeleteIconButton = () => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    builder.removeNode(selectedItem.value.id);
    lastItemSelected.value = selectedItem.value.key;
    selectedItem.value = null;
  };

  return (
    <ButtonBase
      disableRipple
      onClick={handleClick}
      onMouseEnter={() => setBackgroundColor("#0000000D")}
      onMouseLeave={() => setBackgroundColor("transparent")}
      sx={{
        backgroundColor: backgroundColor,
        padding: "8px",
        borderRadius: "8px",
        transition: "background-color 0.2s",
      }}
    >
      <DeleteIconV2 />
    </ButtonBase>
  );
};

export default DeleteIconButton;
