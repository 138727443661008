import { isEnterpriseWebsite } from "@hooks/onboarding/utils";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

export type FormInput = {
  [key: string]: any;
};

export type organizationType =
  | "manage_submerchants"
  | "accept_payments"
  | undefined;
export type MerchantDataType = {
  personalDetails: {
    firstName: string;
    lastName: string;
    email: string;
  };
  organizationDetails: {
    name: string;
    taxId: string;
    termsConditions: boolean;
    logo?: string | File;
    businessType?: string;
    ssn?: string;
    businessPurpose?: string;
  };
  organizationType?: organizationType;
  classification?: {
    name?: string;
    description?: string;
  };
};

export type Title =
  | "personalDetails"
  | "organizationDetails"
  | "organizationType"
  | "classification";

export type FormContextType = {
  formData: FormInput;
  setFormValues: (
    name: Title,
    values: FormInput | organizationType | string,
  ) => void;
  resetHandler: () => void;
};

export const FormContext = React.createContext<FormContextType | null>(null);

const SignupFormProvider = ({
  children,
  data,
}: {
  data?: MerchantDataType;
  children: React.ReactNode;
}) => {
  const isEnterprise = isEnterpriseWebsite();
  //if its givepaid, givecause, givecrowd,enterprise-redirected- from signup flow, we get the classification from the params
  const [searchParams] = useSearchParams();
  const classificationParam = searchParams.get("classification");
  const initialValue: MerchantDataType = {
    personalDetails: data
      ? data.personalDetails
      : {
          firstName: "",
          lastName: "",
          email: "",
        },

    organizationType: isEnterprise
      ? ("accept_payments" as organizationType)
      : undefined,
    classification: {
      name: classificationParam ? classificationParam : undefined,
      description: undefined,
    },
    organizationDetails: data
      ? data.organizationDetails
      : {
          name: "",
          taxId: "",
          termsConditions: false,
          logo: "",
          businessType: "",
          ssn: "",
          businessPurpose: "",
        },
  };
  const [formData, setFormData] =
    React.useState<MerchantDataType>(initialValue);
  const resetHandler = () => {
    setFormData(initialValue);
  };

  const setFormValues = (
    name: Title,
    values: FormInput | organizationType | string,
  ) => {
    const val = typeof values === "string" ? values : { ...values };
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: val,
    }));
  };

  return (
    <FormContext.Provider value={{ formData, setFormValues, resetHandler }}>
      {children}
    </FormContext.Provider>
  );
};

export default SignupFormProvider;

export const useFormData = () =>
  React.useContext(FormContext) as FormContextType;
