import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export const Cell = ({ columnWidth, renderColumn, isBorderBottom, hideColumn }: any) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.tableCell}
      style={{
        width: `${columnWidth * 100}%`,
        ...(isBorderBottom && {
          borderBottom: "1px solid #E1E1DE",
        }),
        ...(hideColumn && {
          display: 'none'
        })
      }}
    >
      {renderColumn}
    </Grid>
  );
};

const useStyles = makeStyles({
  tableCell: {
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },
});
