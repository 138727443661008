import { Stack, styled } from "@mui/material";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Text } from "@common/Text";
import FullWindowModal from "./components/FullWindowModal";
import { Box } from "@mui/material";
import RiskModalHeader from "./components/RiskModalHeader";

import DetailsModal from "./components/DetailsModal";
import { useRef, useState } from "react";
import { Button } from "@common/Button";
import { DotsButtonIcon } from "@assets/rebrandIcons";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { palette } from "@palette";
import MainDataSkeleton from "./components/MainDataSkeleton";
import IPAddressLocationMap from "./components/IPAddressLocationMap";
import TransactionsList from "./components/TransactionsList/TransactionsList";
import { useAppDispatch } from "@redux/hooks";
import { reset } from "@redux/slices/acquirer/transactionRiskProfile";
import DetailsPanelBody from "./components/DetailsPanelBody";
import { IP_PROBE_ADDITIONAL_INFO_MODAL } from "modals/modal_names";
import { ButtonProps } from "@common/Button/Button";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { WithAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import RiskModalLevel from "./components/RiskModalLevel";
import useGetRiskProfile from "features/TransactionsRiskProfile/hooks/useGetRiskProfile";

const TransactionRiskModal = NiceModal.create(
  ({ ipProfileID }: { ipProfileID: string }) => {
    const modal = useModal();
    const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
    const { data, isLoading, hasMore, isListIPProfileAllowed } =
      useGetRiskProfile(ipProfileID);

    const dispatch = useAppDispatch();

    const toggleDetailsModal = () => {
      if (isLoading) return;
      setIsDetailsOpen((prev) => !prev);
    };

    const closeMainModal = () => {
      dispatch(reset());
      modal.hide();
    };

    const showAdditionalInformations = () =>
      NiceModal.show(IP_PROBE_ADDITIONAL_INFO_MODAL, {
        data: data.additionalDetails,
      });

    const closeCallback = (secondaryAction?: () => void) => {
      toggleDetailsModal();
      if (secondaryAction) secondaryAction();
    };

    return (
      <FullWindowModal
        onClose={closeMainModal}
        data-testid="transaction-risk-modal"
      >
        {isLoading ? (
          <MainDataSkeleton />
        ) : (
          <ErrorCatcher errorID="IP Profile data">
            <Stack direction="column" gap="32px" alignItems="stretch">
              <WithAccessControl
                resource={RESOURCE_BASE.IPPROFILE}
                operation={OPERATIONS.READ}
                withPortal
              >
                <FadeUpWrapper delay={150}>
                  <RiskModalHeader data={data} onClose={closeMainModal} />
                </FadeUpWrapper>
                <FadeUpWrapper delay={250}>
                  <IPAddressLocationMap data={data.geomap} level={data.level} />
                </FadeUpWrapper>
                <FadeUpWrapper delay={200}>
                  <RiskModalLevel
                    level={data.level}
                    deEscalationTime={data?.nextDeescalationAt}
                  />
                </FadeUpWrapper>
              </WithAccessControl>
              <FadeUpWrapper delay={300}>
                <TransactionsList hasMore={hasMore} list={data.transactions} />
              </FadeUpWrapper>
            </Stack>
          </ErrorCatcher>
        )}
        <ErrorCatcher errorID="IP Profile details">
          <FadeUpWrapper delay={600}>
            <DetailsModal
              open={modal.visible}
              onClose={toggleDetailsModal}
              closeCallback={closeCallback}
              onOpen={() => undefined}
              isExpanded={isDetailsOpen}
            >
              {isDetailsOpen ? (
                <Stack
                  direction="column"
                  gap="16px"
                  alignItems="stretch"
                  paddingBottom="32px"
                >
                  <DetailsButton
                    onClick={toggleDetailsModal}
                    disableHoverEffect
                    className="handle-details-modal-drag"
                  />
                  <DetailsPanelBody data={data.detailsPanel} />
                  {data.general.hasIPProbeData && (
                    <Button
                      background="tertiary"
                      onClick={showAdditionalInformations}
                      data-testid="additional-info-button"
                    >
                      See more
                    </Button>
                  )}
                </Stack>
              ) : (
                <DetailsButton
                  onClick={toggleDetailsModal}
                  disabled={!isListIPProfileAllowed}
                />
              )}
            </DetailsModal>
          </FadeUpWrapper>
        </ErrorCatcher>
      </FullWindowModal>
    );
  },
);

const DetailsButton = ({
  onClick,
  disableHoverEffect = false,
  ...props
}: ButtonProps & {
  onClick: () => void;
  disableHoverEffect?: boolean;
}) => {
  const isClickEvent = useRef<boolean>(false);

  const onMouseDown = () => (isClickEvent.current = true);
  const onMouseMove = () => {
    if (isClickEvent.current) isClickEvent.current = false;
  };
  const onMouseUp = () => {
    if (isClickEvent.current) {
      onClick();
      isClickEvent.current = false;
    }
  };

  return (
    <DetailsButtonBase
      background="tertiary"
      disableHoverEffect={disableHoverEffect}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      data-testid="details-button"
      {...props}
    >
      <DotsButtonIcon />
      <Text
        color={palette.gray[300]}
        fontSize="14px"
        fontWeight="medium"
        lineHeight="16.8px"
      >
        Details
      </Text>
    </DetailsButtonBase>
  );
};

const DetailsButtonBase = styled(Button, {
  shouldForwardProp: (prop) => prop !== "disableHoverEffect",
})<{ disableHoverEffect: boolean }>(({ disableHoverEffect }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "min-content",
  padding: 0,
  ...(!disableHoverEffect && {
    "&:hover": {
      paddingBottom: "10px",
    },
  }),
}));

export default TransactionRiskModal;
