import React, { useEffect, useState } from "react";
import { styled, Box, Stack, Grid } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";
import { Text, TruncateText } from "@common/Text";
import { TickIcon } from "@assets/icons";
import { Switch_V2 } from "@common/Switch";
import { VerticalDragIcon } from "@assets/builderIcons";
import { toEnFormatWithDecimal } from "@utils/index";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Avatar } from "@mui/material";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { Button } from "@mui/material";
import { EditorState } from "draft-js";

// Recurrence card
type RecurrenceCardProps = {
  name: string;
  keyName: string;
  isDisabled: boolean;
  isDefault: boolean;
  label: string;
};

export const RecurrenceCard = ({
  name,
  isDefault,
  label,
  isDisabled,
  keyName,
}: RecurrenceCardProps) => {
  const { watch, setValue } = useFormContext();
  const values = watch();

  const getNextIndex = (paymentTypes: [string, any][], startIndex: number) => {
    let nextIndex = null;
    for (let index = startIndex; index < paymentTypes.length; index++) {
      if (paymentTypes[index][1] === true) {
        nextIndex = index;
        break;
      }
    }

    return nextIndex;
  };

  const isSelected = values.payment_set_up.payment_types[keyName];

  const handleClick = () => {
    if (isDisabled) return;
    setValue(name, !isSelected, { shouldDirty: true });
    const trueCount = Object.values(values.payment_set_up.payment_types).filter(
      Boolean,
    ).length;
    if (isDefault) {
      const paymentTypes = Object.entries(values.payment_set_up.payment_types);

      const typeIndex = paymentTypes.findIndex((type) => type[0] === keyName);

      let nextDefaultIndex = getNextIndex(paymentTypes, typeIndex + 1);
      if (nextDefaultIndex === null) {
        nextDefaultIndex = getNextIndex(paymentTypes, 1);
      }

      setValue(
        "payment_set_up.payment_types.default",
        paymentTypes[nextDefaultIndex || 1][0],
        { shouldDirty: true },
      );

      setValue(name, false, { shouldDirty: true });
    } else if (isSelected && trueCount >= 2) {
      setValue("payment_set_up.payment_types.default", keyName, {
        shouldDirty: true,
      });
      setValue(name, true, { shouldDirty: true });
    } else {
      setValue(name, true, { shouldDirty: true });
    }
  };

  return (
    <RecurrenceCardBase
      isDisabled={isDisabled}
      isSelected={isSelected}
      handleClick={handleClick}
      isDefault={isDefault}
      label={label}
    />
  );
};

export const NewRecurrenceCard = (props: RecurrenceCardBaseProps) => (
  <RecurrenceCardBase {...props} />
);

type RecurrenceCardBaseProps = {
  isDisabled: boolean;
  isSelected: boolean;
  isDefault: boolean;
  label: string;
  handleClick: () => void;
};

const RecurrenceCardBase = ({
  isDisabled,
  isSelected,
  isDefault,
  label,
  handleClick,
}: RecurrenceCardBaseProps) => {
  let cardLabel = label;
  if (label === "One-Time") cardLabel = "One-time";
  return (
    <RecurrenceCardContainer
      isDisabled={isDisabled}
      onClick={handleClick}
      isSelected={isSelected}
      data-testid={`recurrence-option-${cardLabel}`}
    >
      <Text
        color={palette.black[100]}
        variant="body"
        lineHeight="16.8px"
        fontWeight="book"
      >
        {cardLabel}
      </Text>
      {isDefault && (
        <Text
          color={palette.gray[300]}
          variant="caption"
          lineHeight="14.4px"
          fontWeight="book"
        >
          Default
        </Text>
      )}
      {isSelected && (
        <Tick data-testid={`recurrence-option-${cardLabel}-icon`}>
          <TickIcon width={12} height={12} stroke={palette.neutral.white} />
        </Tick>
      )}
    </RecurrenceCardContainer>
  );
};

const Tick = styled(Box)(() => ({
  position: "absolute",
  right: "6px",
  top: "5px",
  width: 18,
  height: 18,
  background: palette.black.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
}));

const RecurrenceCardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isDisabled",
})<{
  isSelected?: boolean;
  isDisabled?: boolean;
}>(({ isSelected, isDisabled }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
  flex: "1 0 0",

  height: "56px",
  padding: "24px 12px",
  borderRadius: "8px",
  border: "1px solid transparent",
  background: "#FBFBFB",
  boxShadow: "none",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",

  ...(isSelected && {
    borderColor: palette.liftedWhite[100],
    background: palette.neutral.white,
    boxShadow: `1px 1px 15px 0px rgba(161, 175, 180, 0.10)`,
  }),

  ...(isDisabled && {
    cursor: "default",
  }),
}));

// Payment section wrapper
type SectionProps = {
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
};

export const PaymentStepSection = ({
  title,
  description,
  children,
}: SectionProps) => {
  return (
    <Stack direction="column" gap={2}>
      <Box>
        <Text
          color={palette.black[100]}
          variant="body"
          lineHeight="16.8px"
          fontWeight="book"
          mb="4px"
        >
          {title}
        </Text>
        <Text
          color={palette.gray[300]}
          variant="body"
          lineHeight="16.8px"
          fontWeight="light"
        >
          {description}
        </Text>
      </Box>
      {children}
    </Stack>
  );
};

// Amount item
type AmountItemProps<T> = {
  amountItem: T;
  showAmountItem: (id: string) => void;
  isDisabled: boolean;
  checked: boolean;
  isDraggable: boolean;
  onClick: () => void;
  showCount?: boolean;
  isSweepstake?: boolean;
  isPaymentFormBuilder?: boolean;
};

export const AmountItem = <
  T extends {
    id: string;
    title: string;
    active: boolean;
    amount: string;
    display?: boolean;
    description?: EditorState | string;
    thumbnail?: string;
    name?: string;
    min_max?: {
      enabled: boolean;
      min: string;
      max: string;
    };
    in_stock?: number | string | null;
    isDefault?: boolean;
  },
>({
  amountItem,
  showAmountItem,
  isDisabled,
  isPaymentFormBuilder,
  checked,
  isDraggable,
  onClick,
  showCount = false,
  isSweepstake,
}: AmountItemProps<T>) => {
  const { id, title, amount, isDefault, thumbnail, in_stock, display, name } =
    amountItem;
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    zIndex: +isDragging,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const formattedAmount = amount?.includes(",")
    ? amount
    : toEnFormatWithDecimal(amount);

  const stopClickPropagation = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.stopPropagation();

  return (
    <div ref={setNodeRef} style={style}>
      <AmountContainer
        data-testid={`amount-item-${amountItem.title || amountItem.name}`}
        isDisabled={isDisabled}
        onClick={onClick}
      >
        {isSweepstake ? (
          <Grid container alignItems="center" sx={{ flexWrap: "nowrap" }}>
            <Grid item xs={2}>
              <Switch_V2
                checked={checked}
                disabled={isDisabled}
                onChange={() => showAmountItem(id)}
                onClick={stopClickPropagation}
                sx={{ ...(isDisabled && switchStyle) }}
                size="small"
              />
            </Grid>

            {thumbnail ? (
              <Grid item xs={2}>
                <Thumbnail
                  src={addSizeToImage(thumbnail, "small")}
                  imgProps={{
                    onError: ({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = addSizeToImage(thumbnail, "thumb");
                    },
                  }}
                />
              </Grid>
            ) : null}

            <Grid xs={thumbnail ? 7 : 9} item>
              <Stack
                alignItems="center"
                color="#8F8F8F"
                direction="row"
                gap="6px"
              >
                <Text
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  fontWeight="regular"
                  fontSize="16px"
                >
                  {formattedAmount} USD
                </Text>
                <Stack direction="row" gap="6px" alignItems="center">
                  <Box
                    height={7}
                    width={7}
                    borderRadius="50%"
                    bgcolor="#8F8F8F"
                  />
                  <Text
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    fontWeight="regular"
                    fontSize="16px"
                  >
                    {in_stock} Available
                  </Text>
                </Stack>
              </Stack>
              <Text fontWeight="book" fontSize="16px">
                {name}
              </Text>
            </Grid>

            {isDraggable ? (
              <Grid xs={1} item>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  style={{ cursor: isDragging ? "grabbing" : "grab" }}
                  {...attributes}
                  {...listeners}
                >
                  <VerticalDragIcon
                    fill={palette.gray[100]}
                    width={24}
                    height={24}
                  />
                </Stack>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <>
            <Switch_V2
              checked={checked}
              disabled={isDisabled}
              onChange={() => showAmountItem(id)}
              onClick={stopClickPropagation}
              sx={{ ...(isDisabled && switchStyle) }}
              size="small"
            />
            {thumbnail ? (
              <Thumbnail
                src={addSizeToImage(thumbnail, "small")}
                imgProps={{
                  onError: ({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = addSizeToImage(thumbnail, "thumb");
                  },
                }}
              />
            ) : null}

            <Stack
              direction="column"
              gap="2px"
              justifyContent="center"
              flexGrow={1}
            >
              {!isDefault ? (
                <Box display="flex">
                  <TruncateText
                    lineClamp={1}
                    color={palette.gray[300]}
                    variant="body"
                    lineHeight="16.8px"
                    sx={{ userSelect: "none" }}
                  >
                    {formattedAmount} USD
                  </TruncateText>
                  {(isPaymentFormBuilder && display) ||
                  (in_stock && !isPaymentFormBuilder) ? (
                    <>
                      <Text
                        color={palette.gray[200]}
                        lineHeight="5px"
                        alignSelf="center"
                        marginTop="-2px"
                        marginX="5px"
                      >
                        {`\u25CF`}
                      </Text>
                      <TruncateText
                        lineClamp={1}
                        color={palette.gray[300]}
                        variant="body"
                        lineHeight="16.8px"
                        sx={{ userSelect: "none" }}
                      >
                        {in_stock} Available
                      </TruncateText>
                    </>
                  ) : null}
                </Box>
              ) : null}
              <TruncateText
                lineClamp={1}
                color={isDefault ? palette.gray[300] : palette.black[100]}
                fontWeight={isDefault ? "regular" : "book"}
                variant="body"
                lineHeight="16.8px"
                sx={{ wordBreak: "break-all", userSelect: "none" }}
              >
                {title}
              </TruncateText>
            </Stack>

            {isDraggable ? (
              <Stack
                alignItems="center"
                justifyContent="center"
                style={{ cursor: isDragging ? "grabbing" : "grab" }}
                {...attributes}
                {...listeners}
              >
                <VerticalDragIcon
                  fill={palette.gray[100]}
                  width={24}
                  height={24}
                />
              </Stack>
            ) : null}
          </>
        )}
      </AmountContainer>
    </div>
  );
};

const Thumbnail = styled(Avatar)(() => ({
  width: "32px",
  height: "32px",
  borderRadius: "2px",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.05)",
  userSelect: "none",
}));

const AmountContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<{
  isDisabled?: boolean;
}>(({ isDisabled }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  padding: "16px",
  height: "50px",
  width: "100%",
  borderRadius: "8px",
  cursor: "pointer",
  background: "#FBFBFB",
  overflow: "hidden",
  border: `1px solid ${palette.liftedWhite[200]}`,
  "&:hover": {
    boxShadow: "0px 3px 15px rgba(2, 2, 2, 0.15)",
  },
  justifyContent: "space-between",
}));

export const AddAmountButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "textColor",
})<{
  textColor?: string;
}>(({ textColor }) => ({
  padding: "16px",
  height: "48px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  overflow: "hidden",
  border: `1px dashed ${palette.gray[100]}`,
  background: "inherit",
  boxShadow: "none",

  color: textColor ?? palette.gray[300],
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
}));

const switchStyle = {
  "& .MuiSwitch-track": {
    background: `${palette.black.main} !important`,
    opacity: "0.5 !important",
  },
};
