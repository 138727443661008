import { OFACCheckStatusType } from "../OFAC/hooks/useOFAC";

export const clamper = (fileName?: string) => {
  if (!fileName) return "";
  const [name, extension] = fileName.split(".");
  const clampedName =
    name.length > 8 ? `${name.substring(0, 7)}...${extension}` : fileName;

  return clampedName;
};

export const isOFACClear = (status?: OFACCheckStatusType) => {
  return status && ["clear", "manually_cleared"].includes(status);
};
