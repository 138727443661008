type IconProps = {
  width?: number, 
  height?: number,
  fill?: string,
}

const GoodClockIcon = ({
  width = 20,
  height = 20,
  fill = "#9C9AA3",
}: IconProps) => {
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M10.0007 3.33325C8.68211 3.33325 7.39318 3.72425 6.29685 4.45679C5.20052 5.18933 4.34604 6.23052 3.84146 7.4487C3.33687 8.66687 3.20485 10.0073 3.46209 11.3005C3.71932 12.5937 4.35426 13.7816 5.28661 14.714C6.21896 15.6463 7.40684 16.2812 8.70005 16.5385C9.99326 16.7957 11.3337 16.6637 12.5519 16.1591C13.77 15.6545 14.8112 14.8 15.5438 13.7037C16.2763 12.6074 16.6673 11.3185 16.6673 9.99992C16.6654 8.23239 15.9624 6.53781 14.7126 5.28798C13.4628 4.03816 11.7682 3.33516 10.0007 3.33325ZM10.0007 15.5555C8.90187 15.5555 7.82776 15.2296 6.91415 14.6192C6.00055 14.0087 5.28848 13.1411 4.86799 12.1259C4.4475 11.1108 4.33748 9.99375 4.55185 8.91608C4.76621 7.83841 5.29532 6.84851 6.07228 6.07155C6.84924 5.29459 7.83915 4.76547 8.91682 4.55111C9.99449 4.33675 11.1115 4.44677 12.1267 4.86725C13.1418 5.28774 14.0095 5.99981 14.6199 6.91342C15.2304 7.82702 15.5562 8.90113 15.5562 9.99992C15.5546 11.4728 14.9688 12.885 13.9272 13.9265C12.8857 14.968 11.4736 15.5539 10.0007 15.5555Z" 
        fill={fill} 
      />
      <path 
        d="M13.0354 11.3698L10.3539 9.72935V6.4388C10.3539 6.2782 10.2913 6.12418 10.1799 6.01061C10.0685 5.89705 9.91746 5.83325 9.75994 5.83325C9.60242 5.83325 9.45135 5.89705 9.33997 6.01061C9.22859 6.12418 9.16602 6.2782 9.16602 6.4388V10.0721C9.17001 10.1757 9.19823 10.2769 9.24833 10.3672C9.29844 10.4574 9.36898 10.5342 9.45407 10.591L12.4237 12.4077C12.4906 12.4504 12.5651 12.479 12.643 12.492C12.7209 12.505 12.8005 12.502 12.8772 12.4832C12.9539 12.4645 13.0262 12.4303 13.0899 12.3828C13.1535 12.3352 13.2072 12.2752 13.2478 12.2063C13.2885 12.1373 13.3152 12.0608 13.3265 11.9812C13.3379 11.9016 13.3335 11.8205 13.3137 11.7426C13.294 11.6647 13.2592 11.5917 13.2114 11.5276C13.1636 11.4636 13.1038 11.41 13.0354 11.3698Z" 
        fill={fill} 
      />
    </svg>
  )
}

export default GoodClockIcon;
