import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TAccounts } from "@customTypes/accounts.types";

type TSelectedAccount = {
  id: number | null;
  userAccID: number | null;
  userRole: string;
  name: string;
  userEmail: string;
  merchType: string;
  img: string;
};

interface AccountsState {
  data: TAccounts[];
  total: number;
  selectedAccount: TSelectedAccount | null;
}

const initialSelectedAccount: TSelectedAccount = {
  id: null,
  userAccID: null,
  userRole: "owner",
  name: "",
  userEmail: "",
  merchType: "",
  img: "",
};

const initialState: AccountsState = {
  total: 0,
  data: [],
  selectedAccount: initialSelectedAccount,
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    addAccounts: (
      state: AccountsState,
      action: PayloadAction<{
        total: number;
        data: TAccounts[];
      }>,
    ) => {
      state.total = action.payload.total;
      state.data = action.payload.data;
    },
    setSelectedAccount: (
      state: AccountsState,
      action: PayloadAction<TSelectedAccount>,
    ) => {
      state.selectedAccount = action.payload;
      localStorage.setItem("selected-account", String(action.payload.id));
    },
    updatePartialAccount: (
      state: AccountsState,
      action: PayloadAction<any>,
    ) => {
      state.selectedAccount = {
        ...state.selectedAccount,
        ...action.payload,
      };
    },
  },
});

export const selectSelectedAccount = (state: RootState) =>
  state.accounts.selectedAccount;
export const { addAccounts, setSelectedAccount, updatePartialAccount } =
  accountsSlice.actions;

export default accountsSlice.reducer;

export const generateMockedSliceState = (
  customState?: Partial<AccountsState>,
) => {
  return {
    ...initialState,
    ...customState,
    selectedAccount: {
      ...initialState.selectedAccount,
      ...customState?.selectedAccount,
    },
  };
};
