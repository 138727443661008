export const parseCssPropToStyleAttr = (prop: any) => {
  switch (prop.value) {
    case "fill":
      return {
        backgroundSize: "inherit",
      };
    case "cover":
      return { backgroundSize: "cover" };
    case "fit":
      return {
        backgroundSize: "contain",
      };
    default:
      return {};
  }
};

export const parseStyleAttrToCssProp = (styleAttr: any) => {
  switch (styleAttr) {
    case "inherit":
      return {
        value: "fill",
        name: "Fill",
      };
    case "cover":
      return { value: "cover", name: "Cover" };
    case "contain":
      return {
        value: "fit",
        name: "Fit",
      };
    case "row":
      return 1;
    case "row-reverse":
      return 2;
    case "column":
      return 1;
    case "column-reverse":
      return 2;
    case "span 40":
      return "40";
    case "span 50":
      return "50";
    case "span 60":
      return "60";
    case "1":
      return 1;
    case "2":
      return 2;
    default:
      return styleAttr;
  }
};

export const parseImageStyleAttrToCssProp = (styleAttr: any) => {
  switch (styleAttr) {
    case "cover":
      return {
        value: "fill",
        name: "Fill",
      };
    case "fill":
      return { value: "cover", name: "Cover" };
    case "contain":
      return {
        value: "fit",
        name: "Fit",
      };

    default:
      return {};
  }
};

export const parseImageCssPropToStyleAttr = (prop: any) => {
  switch (prop.value) {
    case "fill":
      return {
        objectFit: "cover",
      };
    case "cover":
      return { objectFit: "fill" };
    case "fit":
      return {
        objectFit: "contain",
      };
    default:
      return {};
  }
};

export const parseAlignmentPropToStyleAtrr = (value: any) => {
  switch (value) {
    case "center":
      return {
        backgroundPosition: "center",
      };
    case "topCenter":
      return { backgroundPosition: "top center" };
    case "topLeft":
      return { backgroundPosition: "top left" };
    case "topRight":
      return { backgroundPosition: "top right" };
    case "left":
      return { backgroundPosition: "left center" };
    case "right":
      return { backgroundPosition: "right center" };
    case "bottomCenter":
      return { backgroundPosition: "bottom center" };
    case "bottomLeft":
      return { backgroundPosition: "bottom left" };
    case "bottomRight":
      return { backgroundPosition: "bottom right" };
    default:
      return {};
  }
};

export const parseImageAlignment = (value: any) => {
  switch (value) {
    case "center":
      return {
        objectPosition: "center center",
      };
    case "topCenter":
      return {
        objectPosition: "center top",
      };
    case "topLeft":
      return {
        objectPosition: "left top",
      };
    case "topRight":
      return {
        objectPosition: "right top",
      };
    case "left":
      return {
        objectPosition: "left",
      };
    case "right":
      return {
        objectPosition: "right",
      };
    case "bottomCenter":
      return {
        objectPosition: "center bottom",
      };
    case "bottomLeft":
      return {
        objectPosition: "left bottom",
      };
    case "bottomRight":
      return {
        objectPosition: "right bottom",
      };
    default:
      return {};
  }
};

export const getImageTypeDesc = (type: any) => {
  switch (type) {
    case "fill":
      return "Image will fill the container";
    case "fit":
      return "Image will preserve the true aspect ratio";
    case "cover":
      return "Image will stretch-fill the container";
    default:
      return "Image will fill the container";
  }
};

export const parseButtonPositionStyle = (
  alignment: "flex-start" | "center" | "end" | "auto",
) => {
  switch (alignment) {
    case "flex-start":
      return {
        marginRight: "0",
        marginLeft: "0",
      };
    case "center":
      return {
        marginRight: "auto",
        marginLeft: "auto",
      };
    case "end":
      return {
        marginRight: "0",
        marginLeft: "auto",
      };
    default:
      return {
        marginRight: "0",
        marginLeft: "0",
      };
  }
};
