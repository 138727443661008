import { Grid, GridProps } from "@mui/material";
import React from "react";

export const VirtualListLayoutWrapper = ({
  children,
  show,
  gridProps,
  isMobileView,
  mobileViewProps,
}: {
  children: any;
  show: boolean;
  gridProps?: GridProps;
  isMobileView?: boolean;
  mobileViewProps?: any;
}) => {
  if (!show) return <>{children}</>;

  return (
    <Grid
      maxWidth="1440px"
      width="100%"
      marginX="auto"
      padding="0 58px"
      data-testid="virtualized-list"
      {...gridProps}
      {...(isMobileView && mobileViewProps)}
    >
      {children}
    </Grid>
  );
};
