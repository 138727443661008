import React from "react";
import { Text } from "@common/Text";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import { SortComponent } from "@common/Table/components/SortComponent";

interface Props {
  columns: any;
  setSort: (col: any) => any;
  sortKey: string;
  order: "desc" | "asc";
}

export const ListHeader = ({ columns, setSort, sortKey, order }: Props) => {
  const classes = useStyles();

  return (
    <Grid container>
      {columns.map((column: any) => {
        return (
          <Grid
            key={`head-${column.key}`}
            style={{
              padding: "8px",
              height: "40px",
              width: `${column.columnWidth * 100}%`,
              ...(column?.hideColumn && {
                display: "none",
              }),
            }}
          >
            <Box
              data-testid={column.testid}
              onClick={column?.sortable ? setSort(column) : undefined}
              className={`${classes.tableHead} ${
                column.sortable ? classes.tableHeadSortable : ""
              }`}
              sx={{
                justifyContent:
                  column?.headerPosition === "right"
                    ? "flex-end"
                    : column?.headerPosition === "center"
                    ? "center"
                    : "flex-start",
              }}
            >
              <Text color={palette.neutral.black}>{column.title}</Text>
              {column.sortable && (
                <>
                  <SortComponent
                    sortedCol={sortKey === column.key}
                    order={order}
                  />
                </>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

const useStyles = makeStyles({
  tableBody: {
    position: "relative",
    zIndex: "1",
  },
  tableHead: {
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
    userSelect: "none",
    width: "100%",
  },
  tableHeadSortable: {
    "&:hover": {
      cursor: "pointer",
      borderRadius: "32px",
    },
  },
});
