import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TQueryObject,
  Screen,
  Tabs_Types,
  Replies,
  ConversationsTopics,
  TComment,
  TThreadInfo,
} from "features/Minibuilders/Conversations/types";

type TGeneralSearch = {
  queryKey: Screen;
  queryString: string;
};

interface Conversation {
  modal: {
    isOpen: boolean;
    isExpanded: boolean;
  };
  generalSearch: TGeneralSearch;
  generalFilter: {
    filterString: string;
  };
  topic: {
    numberOfUnreadMessages: number;
    isOpen: boolean;
    isOpenedFromSidePanel?: boolean;
    disableGlobalLauncher?: boolean;
    queryString: string;
    queryObject: TQueryObject;
    tabs: Tabs_Types;
    hideInputs: string[];
  };
  threads: TThreadInfo[];
  comment: Replies;
}

const initialState: Conversation = {
  modal: {
    isOpen: false,
    isExpanded: false,
  },
  generalSearch: {
    queryKey: "",
    queryString: "",
  },
  generalFilter: {
    filterString: "",
  },
  topic: {
    numberOfUnreadMessages: 0,
    isOpen: false,
    queryString: "",
    isOpenedFromSidePanel: undefined,
    queryObject: {
      id: undefined,
      name: "",
      paths: [],
      defaultMessage: "",
      challengeId: undefined,
      escalationIds: undefined,
      factorId: undefined,
      merchantId: undefined,
      shouldHideMentionIcon: true,
      tabs: "Internal",
    },
    tabs: "Internal",
    hideInputs: [],
  },
  comment: {
    isRepliesOpen: false,
    threadId: undefined,
    commentId: undefined,
    index: undefined,
  },
  threads: [],
};

const ConversationsSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setModalOpenConversation: (
      state: Conversation,
      action: PayloadAction<boolean>,
    ) => {
      state.modal.isOpen = action.payload;
    },
    setActiveTabs: (
      state: Conversation,
      action: PayloadAction<{ tabs: Tabs_Types }>,
    ) => {
      state.topic.queryObject.tabs = action.payload.tabs;
    },
    setConversationsGeneralSearchQueryString: (
      state: Conversation,
      action: PayloadAction<{ queryKey: Screen; queryString: string }>,
    ) => {
      state.generalSearch = {
        ...state.generalSearch,
        ...action.payload,
      };
    },
    setConversationsFilter: (
      state: Conversation,
      action: PayloadAction<string>,
    ) => {
      state.generalFilter.filterString = action.payload;
    },
    setConversationTopic: (
      state: Conversation,
      action: PayloadAction<Partial<ConversationsTopics>>,
    ) => {
      state.topic = {
        ...state.topic,
        ...action.payload,
        queryObject: {
          ...state.topic?.queryObject,
          ...action.payload.queryObject,
        },
      };
    },
    setTopicName: (state: Conversation, action: PayloadAction<string>) => {
      state.topic = {
        ...state.topic,
        queryObject: {
          ...state.topic.queryObject,
          name: action.payload,
        },
      };
    },
    setExpandConversationsModal: (
      state: Conversation,
      action: PayloadAction<boolean>,
    ) => {
      state.modal.isExpanded = action.payload;
    },
    setReplies: (
      state: Conversation,
      action: PayloadAction<Partial<Replies>>,
    ) => {
      state.comment = { ...state.comment, ...action.payload };
    },

    setThreads: (
      state: Conversation,
      action: PayloadAction<Array<TThreadInfo>>,
    ) => {
      (state.threads = []),
        (state.threads = [...state.threads, ...action.payload]);
    },

    resetThreads: (state: Conversation) => {
      state.threads = [];
    },

    setTemporaryThreads: (
      state: Conversation,
      action: PayloadAction<Array<TThreadInfo>>,
    ) => {
      state.threads = [...state.threads, ...action.payload];
    },

    setThreadError: (state: Conversation) => {
      state.threads[state.threads.length - 1]["isError"] = true;
    },

    setTmpReplyMessages: (
      state: Conversation,
      action: PayloadAction<{ threadIndex: number; message: TComment }>,
    ) => {
      state.threads[action.payload.threadIndex].messages = [
        ...state.threads[action.payload.threadIndex].messages,
        action.payload.message,
      ];
    },
    resetHiddenInputs: (state: Conversation) => {
      state.topic = {
        ...state.topic,
        hideInputs: [],
      };
    },
    resetConversationsState: (state: Conversation) => {
      state.modal = {
        isOpen: false,
        isExpanded: false,
      };
      state.generalSearch = {
        queryKey: "",
        queryString: "",
      };
      state.generalFilter = {
        filterString: "",
      };
      state.topic = {
        numberOfUnreadMessages: state.topic.numberOfUnreadMessages,
        isOpen: false,
        queryString: "",
        queryObject: {
          id: undefined,
          name: "",
          paths: [],
          shouldHideMentionIcon: true,
          tabs: "Internal",
        },
        tabs: "Internal",
        hideInputs: [],
      };
      state.comment = {
        isRepliesOpen: false,
        threadId: undefined,
        commentId: undefined,
        index: undefined,
      };
      state.threads = [];
    },
  },
});

export const {
  setModalOpenConversation,
  setConversationsGeneralSearchQueryString,
  setConversationTopic,
  setReplies,
  setTemporaryThreads,
  setConversationsFilter,
  setThreads,
  resetThreads,
  setThreadError,
  setTmpReplyMessages,
  resetConversationsState,
  resetHiddenInputs,
  setTopicName,
  setActiveTabs,
} = ConversationsSlice.actions;
export const selectConversation = (state: RootState) => state.conversations;
export const selectConversationTopic = (state: RootState) =>
  state.conversations.topic;
export const selectThreads = (state: RootState) => state.conversations.threads;
export const selectReplies = (state: RootState) => state.conversations.comment;
export const selectConversationsGeneralSearch = (state: RootState) =>
  state.conversations.generalSearch;

export const selectConversationsFilter = (state: RootState) =>
  state.conversations.generalFilter;
export default ConversationsSlice.reducer;
