import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetMarkedMerchants,
  selectMarkedMerchants,
} from "@redux/slices/enterprise/merchants";
import { useMutation } from "react-query";
import { customInstance } from "@services/api";
import { useGetCurrentMerchantId } from "@hooks/common";
import { showMessage } from "@common/Toast/ShowToast";
import {
  getLastBulkApprovalTask,
  useGetBackgroundTaskStatus,
} from "./useGetBackgroundTaskStatus";

export const useUpdateBulkStatus = (merchantId: number) => {
  return useMutation((payload: any) => {
    return customInstance({
      url: `/merchants/${merchantId}/sponsor-statuses`,
      method: "PATCH",
      data: payload,
    });
  });
};

export const useBulkUpdateSponsorStatus = () => {
  const { all, include, exclude } = useAppSelector(selectMarkedMerchants) || [];
  const { merchantId } = useGetCurrentMerchantId();
  const dispatch = useAppDispatch();
  const statusMutation = useUpdateBulkStatus(merchantId);
  const { refetch } = useGetBackgroundTaskStatus({ enabled: false });

  const bulkUpdateSponsorStatus = async (action: string) => {
    const payload = {
      sponsorStatus: action === "approve" ? "approved" : "declined",
      updateAll: all,
      merchIDs: include,
      excludedMerchIDs: exclude,
    };
    try {
      const tasks = await getLastBulkApprovalTask({ accountId: merchantId });
      if (tasks?.total != 0) {
        showMessage(
          "Error",
          `${
            action === "approve" ? "Approval" : "Decline"
          } process is already running in background. Please try again later`,
        );
        return;
      }
      await statusMutation.mutateAsync(payload, {
        onSuccess: () => {
          dispatch(resetMarkedMerchants());
          refetch();
        },
      });
    } catch (error: any) {
      showMessage(
        "Error",
        error?.message || "Whoops.. an error occured. Please try again",
      );
    }
  };

  return {
    bulkUpdateSponsorStatus,
    isUpdating: statusMutation.isLoading,
  };
};
