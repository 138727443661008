import { Stack } from "@mui/material";
import StatusReviewAssignmetComponent from "./components/StatusReviewAssignmetComponent";
import { TStatusReview } from "../../data.types";

type Props = {
  status: TStatusReview;
  handleManageRiskMonitor: () => void;
};

const RiskSummaryActions = ({ status, handleManageRiskMonitor }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <StatusReviewAssignmetComponent
        isRefactored
        data={status}
        handleManageRiskMonitor={handleManageRiskMonitor}
      />
    </Stack>
  );
};

export default RiskSummaryActions;
