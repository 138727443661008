import React, { Dispatch, SetStateAction } from "react";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { M_PublicFormType } from "@common/PublicForm/types";
import MembershipPaymentCard from "@components/Memberships/PublicPaymentForm/MembershipPaymentCard";
import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import { selectCart } from "@redux/slices/cart";
import { useAppSelector } from "@redux/hooks";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";
import { EditorState } from "draft-js";

type PaymentType = {
  title: string;
  checked: boolean;
  isDefault: boolean;
};

type SubscriptionType = {
  id: string;
  name: string;
  amount: string;
  in_stock: string;
  active: boolean;
  description: {
    enabled: boolean;
    text: EditorState;
  };
  display: boolean;
  paymentTypes: PaymentType[];
  thumbnail: string | File;
};

const MembershipCardWrapper = ({
  data,
  activeCards,
  setActiveCards,
  isDesktop,
}: {
  data: M_PublicFormType;
  activeCards: number[];
  setActiveCards: React.Dispatch<React.SetStateAction<number[]>>;
  isDesktop: boolean;
}) => {
  return (
    <Stack
      padding="8px"
      direction="column"
      gap={2}
      alignItems="center"
      width="100%"
      sx={{
        ...(!isDesktop && {
          minHeight: "100%",
        }),
      }}
    >
      <Stack
        direction="column"
        gap={2}
        alignItems="stretch"
        sx={{
          width: "100%",
        }}
      >
        <FadeUpWrapper delay={100}>
          <Text
            variant="h6"
            fontWeight="medium"
            lineHeight="22px"
            color="#292727"
          >
            Choose your membership
          </Text>
        </FadeUpWrapper>
        <Stack gap={2} direction="column" width="100%">
          {data?.subscriptions.list
            .filter((item: SubscriptionType) => item.active === true)
            .map((subscription: any, index: number) => (
              <FadeUpWrapper
                key={subscription.id}
                delay={100 + (index + 1) * 50}
              >
                <MembershipPaymentCard
                  data={data}
                  activeCards={activeCards}
                  setActiveCards={setActiveCards}
                  subscription={subscription}
                />
              </FadeUpWrapper>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const MembershipDetailsForm = ({
  data,
  drawerOpen,
}: {
  data: M_PublicFormType;
  drawerOpen?: Partial<{
    ID: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    renderCheckout: () => React.ReactNode;
  }>;
}) => {
  const [activeCards, setActiveCards] = React.useState<number[]>([]);
  const { cartItems } = useAppSelector(selectCart);

  const { isDesktop } = getPublishedFormDeviceType();

  const reset = () => {
    setActiveCards([]);
  };

  // on success payment, cartitems is set empty, so resets the selected ticket item also
  React.useEffect(() => {
    if (cartItems.length === 0) {
      reset();
    }
  }, [cartItems]);

  return (
    <>
      {!isDesktop ? (
        drawerOpen &&
        ComponentRegistry.show(drawerOpen.ID || "", {
          open: !!drawerOpen.open,
          setOpen: drawerOpen.setOpen,
          _renderComponent: () => (
            <>
              <MembershipCardWrapper
                data={data}
                activeCards={activeCards}
                setActiveCards={setActiveCards}
                isDesktop={isDesktop}
              />
            </>
          ),
          _renderActions: () =>
            drawerOpen.renderCheckout && drawerOpen.renderCheckout(),
        })
      ) : (
        <MembershipCardWrapper
          data={data}
          activeCards={activeCards}
          setActiveCards={setActiveCards}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
};

const Image = styled("img")({
  borderRadius: "5px",
  height: 241,
  width: 342,
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default MembershipDetailsForm;
