// form
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";
// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { BirthDatePicker } from "@common/DatePickers";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import { ZipCodeInput } from "@common/AddressInputs";
import { NameInput } from "@common/BusinessProfileInputs";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { CustomerModalDivider, EmailInfoMessage } from "./AddCustomer";
import { useFormData } from "@hooks/merchant-api/customers/CustomerFormProvider";
import React from "react";
import { CustomerData } from "@hooks/merchant-api/customers/useCreateCustomer";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

type Props = {
  methods: UseFormReturn<CustomerData, any>;
  isDesktop: boolean;
  handleChangeStatus: (
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[],
  ) => void | {
    meta: {
      [name: string]: any;
    };
  };
  onSubmit: SubmitHandler<CustomerData>;
};

const EditCustomer = ({ ...props }: Props) => {
  const { t } = useTranslation(namespaces.pages.customers);
  const { reset, watch } = props.methods;
  const values = watch();
  const { formData } = useFormData();

  React.useEffect(() => {
    if (formData) {
      reset(formData);
    }
  }, [formData]);

  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  return (
    <FormProvider {...props.methods}>
      <Box
        id="newCustomer"
        component="form"
        onSubmit={props.methods.handleSubmit(props.onSubmit)}
      >
        <FadeUpWrapper delay={50}>
          <EmailInfoMessage />
        </FadeUpWrapper>

        <Stack gap={3} direction={props.isDesktop ? "row" : "column"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FadeUpWrapper
                delay={100}
                containerProps={{ sx: { width: "max-content" } }}
              >
                <WithTooltipWrapper
                  hasTooltip={!isAddImageAllowed}
                  tooltipProps={{
                    show: !isAddImageAllowed,
                    message: UPLOAD_DENY_MESSAGE,
                  }}
                >
                  <RHFUploadAvatar
                    name="avatarURL"
                    onChangeStatus={props.handleChangeStatus}
                    disabled={!isAddImageAllowed}
                    width="96px"
                    height="96px"
                    marginBottom={2}
                    marginTop={4}
                  />
                </WithTooltipWrapper>
              </FadeUpWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={150}>
                <NameInput
                  name="firstName"
                  placeholder={t("firstName")}
                  label={t("firstName")}
                />
              </FadeUpWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={150}>
                <NameInput
                  name="lastName"
                  placeholder={t("lastName")}
                  label={t("lastName")}
                />
              </FadeUpWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={200}>
                <RHFInput
                  name="email"
                  placeholder="example@gmail.com"
                  fullWidth
                  label={t("emailRequired")}
                />
              </FadeUpWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={200}>
                <BirthDatePicker
                  name="dateOfBirth"
                  placeholder="MM/DD/YYYY"
                  fullWidth
                  label={"Date Of Birth (Required)"}
                />
              </FadeUpWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeUpWrapper delay={300}>
                <RHFTelInput fullWidth name="phone" label={t("phoneNumber")} />
              </FadeUpWrapper>
            </Grid>
          </Grid>
        </Stack>
        <FadeUpWrapper delay={325}>
          <CustomerModalDivider />
        </FadeUpWrapper>
        <Grid
          mt={1}
          container
          spacing={2}
          direction="row"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6}>
            <FadeUpWrapper delay={350}>
              <RHFInput
                name="occupation"
                fullWidth
                placeholder={t("enterOccupation")}
                type="text"
                label={t("Occupation")}
              />
            </FadeUpWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FadeUpWrapper delay={300}>
              <RHFInput
                name="employer"
                fullWidth
                placeholder={t("enterEmployer")}
                label={t("Employer")}
              />
            </FadeUpWrapper>
          </Grid>
          <Grid item xs={12}>
            <FadeUpWrapper delay={350}>
              <RHFInput
                name="address.line1"
                fullWidth
                placeholder="St. Avenue 16"
                type="text"
                label={t("street_address")}
              />
            </FadeUpWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FadeUpWrapper delay={350}>
              <RHFInput
                name="address.city"
                fullWidth
                placeholder="Albany"
                type="text"
                label={t("city")}
              />
            </FadeUpWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FadeUpWrapper delay={400}>
              <RHFInput
                name="address.state"
                fullWidth
                placeholder="New York"
                type="text"
                label={t("state")}
              />
            </FadeUpWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FadeUpWrapper delay={450}>
              <ZipCodeInput
                name="address.zip"
                placeholder="0001"
                label={t("zip")}
              />
            </FadeUpWrapper>
          </Grid>
          <Grid item xs={12}>
            <FadeUpWrapper delay={500}>
              <RHFInput
                name="notes"
                fullWidth
                placeholder={t("Write something...")}
                type="text"
                label="Notes"
                multiline
                rows={5}
                inputProps={{
                  maxLength: "200",
                }}
                helperText={`${200 - values.notes.length} Characters remaining`}
              />
            </FadeUpWrapper>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default EditCustomer;
