import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { MemberData } from "@customTypes/team.member";
import { useState } from "react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useQueryClient } from "react-query";

const useResendInvite = () => {
  const queryClient = useQueryClient();
  const sendInvite = (merchantId: number, inviteId: number) => {
    customInstance({
      url: `/accounts/${merchantId}/invites/${inviteId}/resend`,
      method: "POST",
    })
      .then((res) => {
        queryClient.invalidateQueries("get-merchant-preview");
        queryClient.invalidateQueries("get-merchant-msp-status");

        showMessage("Info", "Invitation resent");
      })
      .catch((err) => {
        showMessage("Error", err?.response?.data?.message);
      });
  };

  return { sendInvite };
};

export const useSendInvitationAction = () => {
  const [resentInvitationsIDs, setResentInvitationsIDs] = useState<number[]>(
    [],
  );
  const { sendInvite } = useResendInvite();
  const { merchantId } = useGetCurrentMerchantId();

  const handleResendInvitation = (row?: MemberData) => {
    if (!row || !row.invite?.id) return;
    sendInvite(merchantId, row.invite.id);
    setResentInvitationsIDs((prev) => [...prev, row.user.accID]);
  };

  const checkIsInvitationSent = (selectedRow?: MemberData) =>
    resentInvitationsIDs.some((id) => id === selectedRow?.user.accID);

  return {
    resentInvitationsIDs,
    handleResendInvitation,
    checkIsInvitationSent,
  };
};

export default useResendInvite;
