import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { checkPortals } from "@utils/routing";
import { useState } from "react";

type TabNames = "acquirer" | "enterprise" | "submerchant";
export type TabsArrayItem = {
  name: string;
  value: TabNames;
  hidden?: boolean;
};

const useAlertsTabs = () => {
  const { isMasqueradeMode, origin } = useMasqueradeReducer();
  const { isEnterprisePortal, isMerchantPortal } = checkPortals();

  const isOriginAcquirer = origin?.startsWith("/acquirer");
  const isOriginEnterPrice = origin?.startsWith("/enterprise");

  const tabsMap: Record<TabNames, TabsArrayItem> = {
    acquirer: {
      name: tabsNames.acquirer,
      value: "acquirer",
      hidden: isMasqueradeMode
        ? !isOriginAcquirer
        : isEnterprisePortal || isMerchantPortal,
    },
    enterprise: {
      name: tabsNames.enterprise,
      value: "enterprise",
      hidden: isMasqueradeMode
        ? isOriginAcquirer || isOriginEnterPrice
          ? false
          : true
        : isMerchantPortal,
    },
    submerchant: {
      name: tabsNames.submerchant,
      value: "submerchant",
      hidden: false,
    },
  };

  const [tab, setTab] = useState<TabsArrayItem>();

  const setActiveTab = (tabName: string) => {
    let t: TabNames = "submerchant";
    if (tabName === "acquirer") {
      t = "acquirer";
    } else if (tabName === "enterprise") {
      t = "enterprise";
    }
    setTab(tabsMap[t]);
  };

  return {
    activeTab: tab,
    setActiveTab,
    tabs: Object.values(tabsMap),
  };
};

export default useAlertsTabs;

const tabsNames: Record<TabNames, string> = {
  submerchant: "Merchants",
  enterprise: "Provider",
  acquirer: "Acquirer",
};
