interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const PlaidIcon = ({
  width = 20,
  height = 20,
  fill = "#4C4A52",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45752 2.55273L3.84858 3.74804L2.5784 8.33757L4.16699 9.95264L2.55273 11.541L3.74814 16.1507L8.33713 17.4203L9.9517 15.8316L11.5403 17.4464L16.1492 16.251L17.4191 11.6612L15.8308 10.0468L17.4451 8.45835L16.2497 3.84843L11.6601 2.57877L10.0461 4.16718L8.45752 2.55273ZM5.62917 4.63087L8.05705 4.0009L9.11875 5.08013L7.5704 6.60359L5.62917 4.63087ZM10.9589 5.09456L12.0377 4.03322L14.4551 4.70209L12.4822 6.64312L10.9589 5.09456ZM4.03297 7.96078L4.7018 5.5435L6.6424 7.51623L5.09436 9.03969L4.03297 7.96078ZM13.3944 7.57082L15.3673 5.62915L15.9965 8.05741L14.918 9.11906L13.3944 7.57082ZM8.48287 7.53097L10.0312 6.00751L11.5542 7.55607L10.0062 9.07954L8.48287 7.53097ZM6.00715 9.96707L7.55519 8.44361L9.07915 9.99217L7.53047 11.5156L6.00715 9.96707ZM10.919 10.0069L12.467 8.48345L13.9904 10.032L12.442 11.5555L10.919 10.0069ZM4.00065 11.9417L5.07978 10.8797L6.60279 12.4286L4.63052 14.3693L4.00065 11.9417ZM8.44295 12.443L9.99131 10.9196L11.5146 12.4681L9.96659 13.9916L8.44295 12.443ZM13.3545 12.4832L14.9028 10.9597L15.9645 12.0386L15.296 14.4559L13.3545 12.4832ZM5.54267 15.2973L7.51527 13.3556L9.03923 14.9042L7.9601 15.9662L5.54267 15.2973ZM10.8791 14.919L12.4271 13.3955L14.368 15.3685L11.9405 15.9982L10.8791 14.919Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlaidIcon;
