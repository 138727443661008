import * as React from "react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
// form
import { useFormContext, Controller } from "react-hook-form";
// components
import { RHFInput, Input } from "@common/Input";
import { Padding } from "@common/Padding";
import { RHFSelect } from "@common/Select";
import { DatePicker } from "@common/DatePickers";

export const ConditionallyRender: React.FC<{
  value: string;
}> = ({ value }) => {
  const { control } = useFormContext();

  if (value === "is on or before" || value === "is on or after") {
    return (
      <Padding top="8px" right="8px">
        <Controller
          name="date"
          control={control}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <DatePicker
              {...rest}
              renderInput={(params) => (
                <Input
                  inputRef={ref}
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </Padding>
    );
  } else if (value === "is between") {
    return (
      <Stack mt={1} alignItems="center">
        <Controller
          name="startDate"
          control={control}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <DatePicker
              {...rest}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => (
                <Input
                  inputRef={ref}
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />

        <Box
          height={2}
          width={130}
          border="none"
          component="hr"
          bgcolor={`#E1E1DE`}
        />

        <Controller
          name="endDate"
          control={control}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <DatePicker
              {...rest}
              inputFormat="MM/dd/yyyy"
              renderInput={(params) => (
                <Input
                  inputRef={ref}
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </Stack>
    );
  } else {
    return (
      <Stack mt={1} gap={1.5} direction="row" alignItems="stretch">
        <RHFInput name="count" placeholder="7" type="number" fullWidth />
        <RHFSelect
          fullWidth
          name="dayMonthYear"
          options={[
            { value: "days", label: "days" },
            { value: "months", label: "months" },
          ]}
        />
      </Stack>
    );
  }
};
