interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const LockIcon = ({
  width = 18,
  height = 19,
  fill = "#727179",
  path_fill = "#5F5D65",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M12.75 7.25V5.75C12.75 3.65 11.1 2 9 2C6.9 2 5.25 3.65 5.25 5.75V7.25C3.975 7.25 3 8.225 3 9.5V14.75C3 16.025 3.975 17 5.25 17H12.75C14.025 17 15 16.025 15 14.75V9.5C15 8.225 14.025 7.25 12.75 7.25ZM6.75 5.75C6.75 4.475 7.725 3.5 9 3.5C10.275 3.5 11.25 4.475 11.25 5.75V7.25H6.75V5.75ZM9.825 12.125L9.75 12.2V13.25C9.75 13.7 9.45 14 9 14C8.55 14 8.25 13.7 8.25 13.25V12.2C7.8 11.75 7.725 11.075 8.175 10.625C8.625 10.175 9.3 10.1 9.75 10.55C10.2 10.925 10.275 11.675 9.825 12.125Z"
          fill="#088745"
        />
      </g>
    </svg>
  );
};

export default LockIcon;
