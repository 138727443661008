import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { cloneDeep, isEqual } from "lodash";
import { useEffect, useRef } from "react";

const usePercentageUpdate = <TFormInputs extends object>(
  values: any,
  dirtyFields: any,
  schema: any,
  updateStatusBar: (v: number) => void,
) => {
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const saveOnUnmount = useRef<TFormInputs>();

  const calcPercentage = async () => {
    saveOnUnmount.current = cloneDeep(values);
    const value = await calculatePercentageNested(schema, values);
    updateStatusBar(value);
  };

  useEffect(() => {
    if (!isEqual(values, saveOnUnmount.current)) {
      calcPercentage();
    }
  }, [values, dirtyFields]);

  useEffect(() => {
    calcPercentage();
  }, []);
};

export default usePercentageUpdate;