import React from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";
import { isEmpty } from "lodash";

type OwnershipInputProps = {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
};

const OwnershipInput = ({
  name,
  label,
  placeholder,
  disabled = false,
}: OwnershipInputProps) => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (isEmpty(value)) return value;
    const currentValue = value?.replace(/[^\d.]/g, "");
    if (parseFloat(currentValue) > 100) return "100";

    return currentValue;
  };

  React.useEffect(() => {
    setValue(name, normalizeInput(watch(name)));
  }, [watch(name)]);

  return (
    <RHFInput
      name={name}
      label={label}
      placeholder={placeholder}
      fullWidth
      disabled={disabled}
      // inputProps={{
      //   maxLength: "3",
      // }}
      endIcon="%"
    />
  );
};

export default OwnershipInput;
