import * as React from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
// components
import { Text } from "@common/Text";
// localization
import { namespaces } from "localization/resources/i18n.constants";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { SearchBar } from "@common/SearchBar_V2";
import { getGivecashToWebData } from "@utils/queryString";

export const TransactionTableSearch = ({ queryKey }: { queryKey?: string }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation(namespaces.pages.manageMoney);
  const { merchantName } = getGivecashToWebData();

  return (
    <Stack
      direction={isDesktop ? "row" : "column"}
      alignItems={isDesktop ? "center" : "flex-start"}
      justifyContent="space-between"
    >
      <SearchBar
        onMouseLeave
        onMouseEnter
        queryKey={queryKey}
        initialValue={merchantName || undefined}
      />
    </Stack>
  );
};
