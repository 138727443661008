// import * as React from "react";
import { palette } from "@palette";
// @mui
import Box from "@mui/material/Box";
// components
import { Text } from "@common/Text";
import { ExpandedRow } from "./ExpandedRow";
import { Table_V2 as Table, TableColumnType } from "@common/Table";
import CustomPagination from "@common/pagination/pagination/pagination";
// data
import { tableData, RevenuesData } from "./data";
// utils
import { toEnFormat } from "@utils/index";

type RevenuesProps = {
  type: string;
  VisitorsViews: boolean;
  Visitors: boolean;
};

export const RevenuesTable = ({
  type,
  VisitorsViews,
  Visitors,
}: RevenuesProps) => {
  const columns: TableColumnType[] = [
    {
      key: "Source",
      sortable: true,
      title: "Source",
      hasExpandButton: true,
      renderColumn: (row: RevenuesData) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {type === "Membership" ? "General Membership" : row.source}
          </Text>
          <Text color={palette.neutral[800]}>{row.sourceDescription}</Text>
        </Box>
      ),
    },
    {
      key: "Amount",
      sortable: true,
      title: "Amount",
      renderColumn: (row: RevenuesData) => (
        <Text fontWeight="bold" color={palette.neutral[800]}>
          {toEnFormat(row.amount)}
          <sup>
            <Box
              component="span"
              color={palette.neutral[800]}
              fontWeight="medium"
            >
              USD
            </Box>{" "}
          </sup>
        </Text>
      ),
    },
    {
      key: "Average",
      sortable: true,
      title: "Average",
      renderColumn: (row: RevenuesData) => (
        <Text fontWeight="bold" color={palette.neutral[800]}>
          {toEnFormat(row.average)}
          <sup>
            <Box
              component="span"
              color={palette.neutral[800]}
              fontWeight="medium"
            >
              USD
            </Box>{" "}
          </sup>
        </Text>
      ),
    },
  ];

  return (
    <Box sx={tableWrapper}>
      <Table
        small
        expandable
        data={tableData}
        columns={columns}
        renderExpandedRow={(row: RevenuesData) => (
          <ExpandedRow
            rowData={row}
            type={type}
            VisitorsViews={VisitorsViews}
            Visitors={Visitors}
          />
        )}
      />
      <CustomPagination pages={1} />
    </Box>
  );
};

const tableWrapper = {
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};
