import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TPEPOwner } from "../PEP/types";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import PoliticallyExposed from "../PEP";

interface IProps {
  selectedOwner: TPEPOwner;
  merchantId: number;
  legalEntityID: number;
  onCloseParentModal: () => void;
}

const PEPHistoryModal = NiceModal.create(
  ({
    selectedOwner,
    merchantId,
    legalEntityID,
    onCloseParentModal,
  }: IProps) => {
    const modal = useModal();
    const { isMobileView } = useCustomTheme();

    const handleCancel = () => {
      modal.hide();
    };

    const handleCloseAll = () => {
      onCloseParentModal();
      setTimeout(() => {
        handleCancel();
      }, 1000); //Wait for Parent modal to completely close for better experience
    };

    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 570,
          DialogProps: {
            PaperProps: {
              sx: {
                height: "90%",
                paddingBottom: "8px",
              },
            },
          },
        }}
      >
        <PoliticallyExposed
          selectedOwner={selectedOwner}
          merchantId={merchantId}
          legalEntityID={legalEntityID}
          onClose={handleCloseAll}
          onBackPress={handleCancel}
          containerSx={{
            width: "100%",
            borderRadius: "initial",
            boxShadow: "none",
          }}
        />
      </ModalFactory>
    );
  },
);

export default PEPHistoryModal;
