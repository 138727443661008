type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const WarningDiamondIcon = ({
  width = 20,
  height = 20,
  fill = "#8F8F8F",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9999 7.00006C14.2651 7.00006 14.5195 7.10542 14.707 7.29296C14.8945 7.48049 14.9999 7.73485 14.9999 8.00006V15.0001C14.9999 15.2653 14.8945 15.5196 14.707 15.7072C14.5195 15.8947 14.2651 16.0001 13.9999 16.0001C13.7347 16.0001 13.4803 15.8947 13.2928 15.7072C13.1052 15.5196 12.9999 15.2653 12.9999 15.0001V8.00006C12.9999 7.73485 13.1052 7.48049 13.2928 7.29296C13.4803 7.10542 13.7347 7.00006 13.9999 7.00006ZM12.4999 19.5001C12.4999 19.7967 12.5879 20.0867 12.7527 20.3334C12.9175 20.5801 13.1518 20.7723 13.4259 20.8859C13.7 20.9994 14.0015 21.0291 14.2925 20.9712C14.5835 20.9134 14.8508 20.7705 15.0605 20.5607C15.2703 20.3509 15.4132 20.0837 15.4711 19.7927C15.5289 19.5017 15.4992 19.2001 15.3857 18.926C15.2722 18.6519 15.0799 18.4177 14.8332 18.2529C14.5866 18.088 14.2966 18.0001 13.9999 18.0001C13.6021 18.0001 13.2205 18.1581 12.9392 18.4394C12.6579 18.7207 12.4999 19.1022 12.4999 19.5001ZM27.9999 14.0001C28.0006 14.262 27.9494 14.5215 27.8492 14.7635C27.749 15.0055 27.6018 15.2253 27.4161 15.4101L15.4099 27.4176C15.0352 27.7901 14.5283 27.9991 13.9999 27.9991C13.4715 27.9991 12.9646 27.7901 12.5899 27.4176L0.589886 15.4101C0.217384 15.0353 0.00830078 14.5284 0.00830078 14.0001C0.00830078 13.4717 0.217384 12.9648 0.589886 12.5901L12.5961 0.582562C12.9709 0.21006 13.4778 0.000976563 14.0061 0.000976562C14.5345 0.000976563 15.0414 0.21006 15.4161 0.582562L27.4224 12.5901C27.6069 12.7753 27.753 12.9953 27.8521 13.2373C27.9512 13.4793 28.0014 13.7386 27.9999 14.0001ZM25.9999 14.0001L13.9999 2.00006L1.99989 14.0001L13.9999 26.0001L25.9999 14.0001Z"
        fill={fill}
      />
    </svg>
  );
};

export default WarningDiamondIcon;
