type IconProps = {
  height?: number;
  width?: number;
  stroke?: string;
};

const AddButtonIcon = ({
  width = 49,
  height = 48,
  stroke = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1001 6.19924C13.1001 5.86787 12.8315 5.59924 12.5001 5.59924C12.1687 5.59924 11.9001 5.86787 11.9001 6.19924V11.8994H8.90029V13.0994H11.9001V18.7992C11.9001 19.1306 12.1687 19.3992 12.5001 19.3992C12.8315 19.3992 13.1001 19.1306 13.1001 18.7992V13.0994H18.8001C19.1315 13.0994 19.4001 12.8308 19.4001 12.4994C19.4001 12.168 19.1315 11.8994 18.8001 11.8994H13.1001V6.19924ZM7.70029 13.0994V11.8994H6.2001C5.86873 11.8994 5.6001 12.168 5.6001 12.4994C5.6001 12.8308 5.86873 13.0994 6.2001 13.0994H7.70029Z"
        fill={stroke}
      />
    </svg>
  );
};

export default AddButtonIcon;
