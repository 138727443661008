import React from "react";

function BriefCaseOutlinedIcon({ width = "27", height = "24" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2656 4H19.2656V3C19.2656 2.20435 18.9496 1.44129 18.3869 0.87868C17.8243 0.316071 17.0613 0 16.2656 0H10.2656C9.46998 0 8.70691 0.316071 8.1443 0.87868C7.5817 1.44129 7.26562 2.20435 7.26562 3V4H2.26562C1.73519 4 1.22648 4.21071 0.851411 4.58579C0.476339 4.96086 0.265625 5.46957 0.265625 6V22C0.265625 22.5304 0.476339 23.0391 0.851411 23.4142C1.22648 23.7893 1.73519 24 2.26562 24H24.2656C24.7961 24 25.3048 23.7893 25.6798 23.4142C26.0549 23.0391 26.2656 22.5304 26.2656 22V6C26.2656 5.46957 26.0549 4.96086 25.6798 4.58579C25.3048 4.21071 24.7961 4 24.2656 4ZM9.26562 3C9.26562 2.73478 9.37098 2.48043 9.55852 2.29289C9.74605 2.10536 10.0004 2 10.2656 2H16.2656C16.5308 2 16.7852 2.10536 16.9727 2.29289C17.1603 2.48043 17.2656 2.73478 17.2656 3V4H9.26562V3ZM24.2656 6V11.2013C20.8903 13.0385 17.1085 14.0007 13.2656 14C9.42288 14.0007 5.64115 13.0389 2.26562 11.2025V6H24.2656ZM24.2656 22H2.26562V13.455C5.69053 15.1302 9.45296 16.0007 13.2656 16C17.0784 16.0001 20.8407 15.1292 24.2656 13.4538V22ZM10.2656 11C10.2656 10.7348 10.371 10.4804 10.5585 10.2929C10.7461 10.1054 11.0004 10 11.2656 10H15.2656C15.5308 10 15.7852 10.1054 15.9727 10.2929C16.1603 10.4804 16.2656 10.7348 16.2656 11C16.2656 11.2652 16.1603 11.5196 15.9727 11.7071C15.7852 11.8946 15.5308 12 15.2656 12H11.2656C11.0004 12 10.7461 11.8946 10.5585 11.7071C10.371 11.5196 10.2656 11.2652 10.2656 11Z"
        fill="url(#paint0_linear_806_14698)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_806_14698"
          x1="26.2656"
          y1="0"
          x2="3.0493"
          y2="26.4439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#72ECF0" />
          <stop offset="1" stopColor="#528DDF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BriefCaseOutlinedIcon;
