import { usePersonalInformationProvider } from "./Provider/PersonalInformationProvider";
import PersonalInfoTab from "./components/PersonalInfoTab";
import { personalInfoEnum } from "./types";
import UploadIdTab from "./components/UploadIdTab";
import TakeSelfieTab from "./components/TakeSelfieTab";

function PersonalInformation() {
  const { step } = usePersonalInformationProvider();

  const pages = {
    [personalInfoEnum.PERSONAL_INFORMATION]: <PersonalInfoTab />,
    [personalInfoEnum.UPLOAD_YOUR_ID]: <UploadIdTab />,
    [personalInfoEnum.TAKE_A_SELFIE]: <TakeSelfieTab />,
  };
  return pages[step];
}

export default PersonalInformation;
