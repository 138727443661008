type Props = {
  width?: number;
  height?: number;
  isHovered?: boolean;
};

const ArrowRightIcon = ({
  width = 20,
  height = 21,
  isHovered = true,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={isHovered ? "0.6" : 1}>
        <path
          d="M14.192 10.9425L7.94205 17.1925C7.88398 17.2506 7.81504 17.2967 7.73917 17.3281C7.6633 17.3595 7.58198 17.3757 7.49986 17.3757C7.41774 17.3757 7.33642 17.3595 7.26055 17.3281C7.18468 17.2967 7.11574 17.2506 7.05767 17.1925C6.9996 17.1345 6.95354 17.0655 6.92211 16.9897C6.89069 16.9138 6.87451 16.8325 6.87451 16.7503C6.87451 16.6682 6.89069 16.5869 6.92211 16.511C6.95354 16.4352 6.9996 16.3662 7.05767 16.3082L12.8663 10.5003L7.05767 4.69253C6.9404 4.57526 6.87451 4.4162 6.87451 4.25035C6.87451 4.0845 6.9404 3.92544 7.05767 3.80816C7.17495 3.69088 7.33401 3.625 7.49986 3.625C7.66571 3.625 7.82477 3.69088 7.94205 3.80816L14.192 10.0582C14.2502 10.1162 14.2963 10.1851 14.3277 10.261C14.3592 10.3369 14.3754 10.4182 14.3754 10.5003C14.3754 10.5825 14.3592 10.6638 14.3277 10.7397C14.2963 10.8156 14.2502 10.8845 14.192 10.9425Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ArrowRightIcon;
