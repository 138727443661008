import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { Box, Stack } from "@mui/material";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { Input } from "@common/Input";
import { useState } from "react";
import { palette } from "@palette";
import { Button } from "@common/Button";

interface IDisputeAddANoteModal {
  fileName: string;
  defaultNote?: string;
  fileIndex: number;
  onSave: (note: string, fileIndex: number) => void;
}

const DisputeAddANoteModal = NiceModal.create(
  ({
    fileName,
    defaultNote = "",
    fileIndex,
    onSave,
  }: IDisputeAddANoteModal) => {
    const [currentNote, setCurrentNote] = useState(defaultNote);
    const modal = useModal();

    const handleCancel = () => modal.remove();

    const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setCurrentNote(e.target.value);
    };

    const handleSave = (value: string) => {
      onSave(value, fileIndex);
      handleCancel();
    };

    return (
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 618,
        }}
      >
        <Stack gap={3} p={3}>
          <ModalTitle
            title={`Add a Note to ${fileName}`}
            description="This note will be added to the document as information for the bank."
            onClose={handleCancel}
            textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
            />
          <Input
            label="Document Notes"
            fullWidth
            rows={6}
            multiline
            value={currentNote}
            onChange={handleInputChange}
            InputProps={{
              sx: { borderWidth: "1px !important" },
            }}
          />
          <Stack direction="row" pt={3} justifyContent="space-between">
            {defaultNote && (
              <Button
                size="medium"
                background="tertiary"
                sx={{
                  fontWeight: 400,
                  height: "fit-content",
                  fontSize: "18px",
                  color: palette.filled.red,
                }}
                onClick={() => handleSave("")}
              >
                Remove Note
              </Button>
            )}
            <Box width={defaultNote ? "fit-content" : "100%"}>
              <ModalActions
                primaryAction={{
                  label: "Save",
                  sx: { fontSize: "18px" },
                  disabled: !currentNote || currentNote === defaultNote,
                  isLoading: false,
                  onClick: () => handleSave(currentNote),
                }}
                secondaryAction={{
                  label: "Cancel",
                  sx: { fontSize: "18px" },
                  onClick: handleCancel,
                }}
                animationDelay={300}
              />
            </Box>
          </Stack>
        </Stack>
      </ModalFactory>
    );
  },
);

export default DisputeAddANoteModal;
