import theme from "@theme/index";
import Box from "@mui/material/Box";
import { Text } from "@common/Text";
import { palette } from "@theme/colors";
import { toEnFormat, truncateString } from "utils";

// interface
interface TickerProps {
  coast: number;
  fullName: string;
  days: number;
}

function TickerItem({ coast, fullName, days }: TickerProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80px",
        width: "180px",
        padding: "0 8px 0 8px",
        transition: "0.1s",
        

        "&:hover": {
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
        },

        [theme.breakpoints.down("sm")]: {
          width: "286px",
          maxHeight: "48px",
          flexDirection: "row",
          alignItems: "center",
        },
      }}
    >
      <Text
        mr={2}
        variant="h6"
        fontWeight="book"
        color={palette.neutral[90]}
      >
        {truncateString(toEnFormat(coast))}
        <Box
          ml={0.5}
          component="sup"
          fontSize="14px"
          color={palette.neutral[70]}
        >
          USD
        </Box>
      </Text>
      <Box>
        <Text
          variant="h6"
          fontWeight="light"
          color={palette.neutral[90]}
          sx={{ 
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {fullName}
        </Text>
        <Text
          variant="body"
          fontWeight="light"
          color={palette.neutral[80]}
        >
          {days} Days Ago
        </Text>
      </Box>
    </Box>
  );
}

export default TickerItem;
