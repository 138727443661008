import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  stroke?: string;
}

const TransfersIcon = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7499 11.062C18.8989 11.062 19.0419 11.0028 19.1474 10.8975C19.2527 10.792 19.3119 10.6491 19.3119 10.5C19.3119 10.3509 19.2527 10.208 19.1474 10.1025L12.3974 3.35249C12.2919 3.24716 12.1489 3.18799 11.9999 3.18799C11.8508 3.18799 11.7079 3.24716 11.6024 3.35249L4.85238 10.1025C4.75302 10.2091 4.69893 10.3502 4.7015 10.4959C4.70407 10.6416 4.76311 10.7806 4.86617 10.8837C4.96923 10.9868 5.10827 11.0458 5.25399 11.0484C5.39972 11.0509 5.54075 10.9969 5.64739 10.8975L11.4374 5.10843V14.4375C11.4374 14.7482 11.6892 15 11.9999 15C12.3105 15 12.5624 14.7482 12.5624 14.4375V5.10843L18.3524 10.8975C18.4579 11.0028 18.6008 11.062 18.7499 11.062ZM11.9999 17C11.6892 17 11.4374 17.2518 11.4374 17.5625V20.25C11.4374 20.3992 11.4966 20.5422 11.6021 20.6477C11.7076 20.7532 11.8507 20.8125 11.9999 20.8125C12.1491 20.8125 12.2921 20.7532 12.3976 20.6477C12.5031 20.5422 12.5624 20.3992 12.5624 20.25V17.5625C12.5624 17.2518 12.3105 17 11.9999 17Z"
          fill="url(#paint0_linear_627_2329)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_627_2329"
            x1="19.3119"
            y1="3.18799"
            x2="2.27932"
            y2="18.0336"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7499 11.062C18.8989 11.062 19.0419 11.0028 19.1474 10.8975C19.2527 10.792 19.3119 10.6491 19.3119 10.5C19.3119 10.3509 19.2527 10.208 19.1474 10.1025L12.3974 3.35249C12.2919 3.24716 12.1489 3.18799 11.9999 3.18799C11.8508 3.18799 11.7079 3.24716 11.6024 3.35249L4.85238 10.1025C4.75302 10.2091 4.69893 10.3502 4.7015 10.4959C4.70407 10.6416 4.76311 10.7806 4.86617 10.8837C4.96923 10.9868 5.10827 11.0458 5.25399 11.0484C5.39972 11.0509 5.54075 10.9969 5.64739 10.8975L11.4374 5.10843V14.4375C11.4374 14.7482 11.6892 15 11.9999 15C12.3105 15 12.5624 14.7482 12.5624 14.4375V5.10843L18.3524 10.8975C18.4579 11.0028 18.6008 11.062 18.7499 11.062ZM11.9999 17C11.6892 17 11.4374 17.2518 11.4374 17.5625V20.25C11.4374 20.3992 11.4966 20.5422 11.6021 20.6477C11.7076 20.7532 11.8507 20.8125 11.9999 20.8125C12.1491 20.8125 12.2921 20.7532 12.3976 20.6477C12.5031 20.5422 12.5624 20.3992 12.5624 20.25V17.5625C12.5624 17.2518 12.3105 17 11.9999 17Z"
        fill="url(#paint0_linear_627_2329)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_627_2329"
          x1="19.3119"
          y1="3.18799"
          x2="2.27932"
          y2="18.0336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={stroke} />
          <stop offset="1" stopColor={stroke} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TransfersIcon;
