import { capitalizeFirstLetter } from "@common/Table/helpers";
import { AccountData } from "@customTypes/data.types";
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectCurrentCampaign } from "@redux/slices/checkout";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const AppTitle = () => {
  const [title, setTitle] = useState<string>("");
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const { title: campaignTitle } = useAppSelector(selectCurrentCampaign);
  const location = useLocation();
  const isPublicForm = location.pathname.match(/^\/[0-9]*$/);
  const url = window.location.hostname;

  const urlPattern = /^(?!.*:3000)(?!.*portal)(.*?)(\.\w+)\.givepayments\.com$/;
  const isNotLocal = url.match(urlPattern);

  useEffect(() => {
    if (!isNotLocal) {
      const _selectedAccount =
        selectedAccount as AccountData["data"][number] & { role: string };

      const title = !isPublicForm
        ? _selectedAccount?.role
          ? `GIVE ${capitalizeFirstLetter(_selectedAccount.role)} Portal`
          : "GIVE Portal"
        : campaignTitle;
      setTitle(title);
    }
  }, [selectedAccount, campaignTitle, isNotLocal]);

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {isPublicForm ? (
        <meta
          name="description"
          content={campaignTitle}
          data-react-helmet="true"
        />
      ) : (
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      )}
    </Helmet>
  );
};

export default AppTitle;
