import { customInstance } from "@services/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { RiskProfileTabs, RiskProfileTabsType } from "../components/Header";
import { TMerchantRiskProfile } from "../types";

type Props = {
  profileId: number;
  merchantId: number;
};

const getMerchantRiskProfile = (id: number, merchantId: number) => {
  return customInstance({
    url: `/merchants/${merchantId}/risk/merchant-profiles/${id}`,
    method: "GET",
  });
};

export const useRiskProfile = ({ profileId, merchantId }: Props) => {
  const { data, isLoading } = useQuery(
    ["get-merchant-risk-profile", profileId, merchantId],
    async () => {
      const riskProfile = await getMerchantRiskProfile(profileId, merchantId);
      return riskProfile as TMerchantRiskProfile;
    },
    {
      refetchOnWindowFocus: false,
      enabled: profileId !== undefined && !!profileId && !!merchantId,
    },
  );

  const [activeTab, setActiveTab] = useState<RiskProfileTabsType>(
    RiskProfileTabs.ACTIVITY,
  );

  return {
    data,
    isLoading,
    activeTab,
    setActiveTab,
  };
};
