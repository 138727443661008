import { SvgIconProps } from "@mui/material/SvgIcon";

type IProps = SvgIconProps & {
  width?: number | string;
  height?: number | string;
  stroke?: string;
};

const CloseIcon = ({
  width = 24,
  height = 24,
  stroke = "#B1AFB8",
  ...rest
}: IProps) => {
  return (
    <svg
      data-testid="close-icon"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M18 6L6 18"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
