import { useQuery } from "react-query";
import { customInstance } from "@services/api";
import { useGetCurrentMerchantId } from "@hooks/common";

export const useGetBOFiles = ({ enabled }: { enabled?: boolean }) => {
  const { merchantId } = useGetCurrentMerchantId();
  return useQuery(
    ["get-bo-files", merchantId],
    async () => {
      return await customInstance({
        url: `/accounts/${merchantId}/files?filter=%3BisUploaded%3Atrue%3BattTypeName%3A%legal_principal%22%&sort=-updatedAt`,
      });
    },
    {
      enabled: enabled,
    },
  );
};
