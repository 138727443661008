import { Text } from "@common/Text";
import { Button, Stack, styled } from "@mui/material";
import { selectedDeviceType, selectedItem } from "../signals";
import { useAppTheme } from "@theme/v2/Provider";
import { builder } from "../utils";
import { updateNodeDeviceTypeProperties } from "../NodeDeviceTypePropertiesMap";
import {
  getDeviceTypePropertyName,
  updateSelectedItem,
} from "../utils/helpers";

const Yes = "Yes";
const No = "No";

export const changeNodeVisibility = (
  updateValue: string,
  isCompoundWidget?: boolean,
) => {
  builder.updateNodeVisibility(selectedItem.value.id, updateValue);
  if (isCompoundWidget) {
    updateSelectedItem({
      dataHidden: updateValue,
    });
  } else {
    selectedItem.value = {
      ...selectedItem.value,
      metaAttributes: {
        ...selectedItem.value.metaAttributes,
        "data-hidden": updateValue,
      },
    };

    updateNodeDeviceTypeProperties(
      "metaAttributes",
      {
        "data-hidden": updateValue,
      },
      false,
    );
  }
};

const HideOnDeviceSwitch = ({
  isCompoundWidget,
}: {
  isCompoundWidget?: boolean;
}) => {
  const selectedDevice = selectedDeviceType.value;
  const theme = useAppTheme();

  const metaAttributes = isCompoundWidget
    ? builder.findNodeByKey(selectedItem.value.id)?.[
        getDeviceTypePropertyName("metaAttributes")
      ]
    : selectedItem.value.metaAttributes;
  const selectedValue = metaAttributes?.["data-hidden"] === "true" ? Yes : No;

  const onNodeVisibilityChange = (value: string) => {
    const updateValue = String(value === "Yes");
    changeNodeVisibility(updateValue, isCompoundWidget);
  };

  return (
    <Stack spacing={1.5} mb={3}>
      <Text color={theme.palette.text.primary} fontSize="16px">
        Visibility
      </Text>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Text color={theme.palette.text.primary} fontSize="14px">
          Hide on {selectedDevice}
        </Text>
        <Stack direction="row" spacing={1}>
          <StyledButton
            theme={theme}
            isSelected={selectedValue === Yes}
            onClick={() => {
              onNodeVisibilityChange(Yes);
            }}
            disableRipple
          >
            {Yes}
          </StyledButton>

          <StyledButton
            theme={theme}
            isSelected={selectedValue === No}
            onClick={() => {
              onNodeVisibilityChange(No);
            }}
            disableRipple
          >
            {No}
          </StyledButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HideOnDeviceSwitch;

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ isSelected, theme }: { isSelected: boolean; theme: any }) => {
  const color = isSelected
    ? theme.palette.common.black
    : theme.palette.primitive?.neutral[70];
  const backgroundColor = isSelected
    ? theme.palette.text.primary
    : "transparent";
  return {
    width: "55px",
    height: "34px",
    color,
    backgroundColor,
    borderRadius: "40px",
    "&:hover": {
      backgroundColor: isSelected
        ? backgroundColor
        : theme.palette.primitive?.neutral[10],
    },
  };
});
