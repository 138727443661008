import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { addSizeToImage } from "@utils/image.helpers";
import { TAccounts } from "@customTypes/accounts.types";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";
import { addAccounts, setSelectedAccount } from "@redux/slices/auth/accounts";
import {
  login,
  Role,
  selectUser,
  updatePartialUser,
} from "@redux/slices/auth/auth";
import {
  useGetAccountsWithUser,
  usePutAccount,
} from "@services/api/onboarding/user";
import { isDefined } from "@utils/helpers";
import { getGivecashToWebData } from "@utils/queryString";
import Cookies from "js-cookie";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "@hooks/auth-api/useLogin";

export type TParsedAccount = {
  id: number;
  userAccID: number;
  userRole: string;
  name: string;
  userEmail: string;
  merchType: Role;
  img: string;
  selected: boolean;
};

type Props = {
  userAccID: number;
};

export const useAccountSelectionModal = ({ userAccID }: Props) => {
  const { modal, onClose: closeModal } = useNiceModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mutate } = usePutAccount();

  const { email, userAccID: userId } = useAppSelector(selectUser);
  const [currentAccount, setCurrentAccount] = useState<
    TParsedAccount | undefined
  >(undefined);
  const [accountList, setAccountList] = useState<TParsedAccount[]>([]);

  const { data, isLoading } = useGetAccountsWithUser({
    onSuccess: (res) => {
      if (!res) return res;
      const { user, accounts } = res;

      const newAccountList = accountsParser(accounts.data, user?.email);
      setAccountList(newAccountList);
    },
    enabled: isDefined(userId),
  });

  const continueHandler = () => {
    if (!currentAccount) return;
    const { selected, ...selectedAccount } = currentAccount;

    const role = getUserRole(selectedAccount.merchType);
    const user = {
      userAccID,
      id: selectedAccount?.id,
      img: data?.user?.imageURL,
      name: selectedAccount.name,
      role,
      email: data?.user?.email || email,
      globalName: {
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
        phoneNumber: data?.user?.phoneNumber,
      },
      currency: data?.user.currency,
      language: data?.user.language,
      timezone: data?.user.timezone,
    };

    Cookies.set("user", JSON.stringify(user), { expires: 30 });
    if (data?.accounts) dispatch(addAccounts(data.accounts));

    const { toPath, fromMobile } = getGivecashToWebData();

    if (selectedAccount.id) {
      mutate(selectedAccount.id, {
        onSuccess: () => {
          dispatch(setSelectedAccount(selectedAccount));
          dispatch(login(user));
          fromMobile && toPath ? navigate(toPath) : navigate(`/${role}`);
          closeModal();
        },
      });
    }
    // selected account is set to initial state when page refreshes
  };

  const chooseAccount = (index: number) => {
    const updatedAccounts = accountList?.map((account, i) => {
      const selected = i === index;
      if (selected) setCurrentAccount(account);
      return { ...account, selected };
    });

    setAccountList(updatedAccounts);
  };

  return {
    modal,
    accountList,
    isLoadingAccounts: isLoading,
    handleModalClose: closeModal,
    continueHandler,
    chooseAccount,
    selectedAccountId: currentAccount?.id,
  };
};

export const useAccountSelection = () => {
  const [accountList, setAccountList] = useState<TParsedAccount[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, email, userAccID } = useAppSelector(selectUser);

  const { data, isLoading } = useGetAccountsWithUser({
    onSuccess: (res) => {
      if (!res) return res;
      const { user, accounts } = res;
      dispatch(
        updatePartialUser({
          img: user.imageURL ? addSizeToImage(user.imageURL, "thumb") : "",
          globalName: {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
          },
        }),
      );
      const newAccountList = accountsParser(accounts.data, user?.email);
      setAccountList(newAccountList);
    },
    enabled: isDefined(userAccID),
  });

  const { mutate } = usePutAccount();
  const client = useQueryClient();

  const continueHandler = (userParam: TParsedAccount) => {
    const userAccID = data?.accounts?.data?.find(
      (account) => account.type === "user",
    )?.id;

    const user = {
      userAccID,
      id: userParam.id,
      img: userParam.img,
      name: userParam.name,
      role: userParam.merchType,
      email,
      globalName: {
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
        phoneNumber: data?.user?.phoneNumber,
      },
    };

    Cookies.set(
      "user",
      JSON.stringify({ ...user, img: data?.user?.imageUrl }),
      {
        expires: 30,
      },
    );

    mutate(user.id, {
      onSuccess: () => {
        client.clear();
        if (data?.accounts) dispatch(addAccounts(data?.accounts));
        const { selected, ...currentAccount } = userParam;
        dispatch(setSelectedAccount(currentAccount));
        dispatch(updatePartialUser(user));

        navigate(`/${user.role}`);
      },
    });
  };

  const chooseAccount = (index: number) => {
    const updatedAccounts = accountList?.map((account, i) => {
      const selected = i === index;
      return { ...account, selected };
    });

    setAccountList(updatedAccounts);
    continueHandler(updatedAccounts[index]);

    dispatch(updatePermissions({ reset: true }));
  };

  return {
    name,
    email,
    accountList,
    isLoading,
    continueHandler,
    chooseAccount,
  };
};

type SwitchAccountParams = {
  userParam: TAccounts<"merchant">;
  onSuccess: () => void;
};
export const useAutoSwitchAccount = () => {
  const user = Cookies.get("user");
  const parsedUser = user && JSON.parse(user);
  const dispatch = useAppDispatch();
  const { email } = useAppSelector(selectUser);

  const { mutateAsync, isLoading: isSwitching } = usePutAccount();

  const client = useQueryClient();

  const switchAccount = async ({
    userParam,
    onSuccess,
  }: SwitchAccountParams) => {
    const userAccID = parsedUser?.userAccID;

    const currentAccount = {
      id: userParam.id,
      userAccID,
      userRole: userParam.userRole,
      name: userParam.name,
      userEmail: parsedUser?.email,
      img: userParam.avatarURL,
      merchType:
        !userParam.merchType || userParam.merchType === "submerchant"
          ? "merchant"
          : userParam.merchType,
    };

    const user = {
      userAccID,
      id: userParam.id,
      img: userParam.avatarURL,
      name: userParam.name,
      role: getUserRole(userParam.merchType),
      email,
      globalName: {
        firstName: parsedUser?.firstName,
        lastName: parsedUser?.lastName,
        phoneNumber: parsedUser?.phoneNumber,
      },
    };

    Cookies.set(
      "user",
      JSON.stringify({ ...user, img: parsedUser?.imageUrl }),
      {
        expires: 30,
      },
    );
    await mutateAsync(user.id, {
      onSuccess: () => {
        onSuccess();
        dispatch(setSelectedAccount(currentAccount));
        dispatch(updatePartialUser(user));
        client.clear();
      },
    });
  };

  return {
    switchAccount,
    isSwitching,
  };
};

type AccountParser = (data: TAccounts[], userEmail: string) => TParsedAccount[];

export const accountsParser: AccountParser = (data, userEmail) => {
  const merchantAccounts = data.filter(isMerchantRaw);

  return merchantAccounts.map((account) => {
    return {
      id: account.id,
      userAccID: account.userAccID,
      userRole: account.userRole,
      name: account.name,
      userEmail,
      merchType: getUserRole(account.merchType),
      img: account.avatarURL,
      selected: false,
    };
  });
};

const isMerchantRaw = (
  account: TAccounts,
): account is TAccounts<"merchant"> => {
  return account.type === "merchant";
};
