import { namespaces } from "../../../../resources/i18n.constants";

export const eventsMinibuilder = {
  [namespaces.pages.eventsMinibuilder]: {
    create_events: "Crear Eventos",
    general: "General",
    date_and_location: "Fecha & Ubicación",
    tickets: "Entradas",
    // General --------------------------------------------------------------------------------
    title: "Título",
    "Please enter a suitable title": "Por favor ingrese un título adecuado",
    description: "Descripción",
    optional_but_recommended: "(Opcional pero recomendado)",
    "What’s the purpose of this Event": "¿Cuál es el propósito de esta Evento?",
    browse_more_campaigns: "Explorar más campañas",
    "If enabled, users will be able to explore other available campaigns of this Nonprofit":
      "Si está habilitado, los usuarios podrán explorar otras campañas disponibles de esta organización sin fines de lucro",
    featured_image: "Foto principal",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Recommended minimum size 2000x2000":
      "Esta imagen debe ser representativa de la campaña y se mostrará en la parte superior del formulario de pago, se puede reposicionar posteriormente. Tamaño mínimo recomendado 2000x2000",
    use_as_background_image: "Usar como imagen de fondo",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "Si está marcada, esta imagen se integrará en el fondo del formulario, se puede cambiar más tarde",
    media_library: "Mediateca",
    add_new_image: "Agregar nueva imagen",
    no_images: "Sin imágenes",
    // Date & Location --------------------------------------------------------------------------------
    date: "Fecha",
    one_day: "un día",
    period: "período",
    when_is_the_event: "Cuando es el evento",
    choose_a_date: "Elige una fecha",
    when_the_event_starts: "¿Cuándo comienza el evento?",
    when_the_event_ends: "¿Cuándo termina el evento?",
    select_a_date: "Seleccione una fecha",
    include_time: "Incluir tiempo",
    location: "Ubicación",
    in_person: "en persona",
    online: "en línea",
    where_is_the_event: "¿Dónde es el evento?",
    type_a_location: "Escriba una ubicación",
    enter_a_url: "Introduce una URL",
    "show user a link to view map":
      "Mostrar al usuario un enlace para ver el mapa",
    "if enabled, users will be able to see a link to view map":
      "Si está habilitado, los usuarios podrán ver un enlace para ver el mapa.",
    enter_address_manually: "Introducir dirección manualmente",
    address: "DIRECCIÓN",
    type_address: "Escriba la dirección",
    city: "Ciudad",
    type_city: "Tipo Ciudad",
    state: "Estado",
    select_state: "Seleccione estado",
    zip: "Código postal",
    type_zip: "Escriba el código postal",
    previous_step: "Paso Anterior",
    // Tickets --------------------------------------------------------------------------------
    ticket: "entrada",
    thumbnail: "Miniatura",
    add_ticket: "Agregar Entrada",
    ticket_name: "Nombre del entrada",
    enter_a_suitable_name: "Introduzca un nombre adecuado",
    ticket_name_is_mandatory: "El nombre del entrada es obligatorio",
    amount: "Cantidad",
    must_be_above_one_dollar: "Debe estar por encima de 1USD",
    "between 1.00 and 25,000.00": "Entre 1,00 y 25.000,00",
    in_stock: "En stock",
    at_least_one: "Al menos 1",
    should_be_at_least: "Debe ser al menos 1",
    display_tickets_available: "Mostrar boletos disponibles",
    give_some_insight_about_this_ticket: "Da una idea de este ticket",
    create_option: "Crear opción",
    save_changes: "Guardar cambios",
    cancel: "Cancelar",
    remove: "Eliminar",

    properties: "Propiedades",
    customer_pays_the_credit_card_fee:
      "El cliente paga la tarifa de la tarjeta de crédito",
    "If toggled off, the Nonprofit covers the credit card fee.":
      "Si se desactiva, la organización sin fines de lucro cubre la tarifa de la tarjeta de crédito.",
    "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee":
      "El cliente cubre el costo de la tarifa de la tarjeta de crédito a menos que se proporcione la opción que elija. Si se desactiva, la organización sin fines de lucro paga la tarifa de la tarjeta de crédito.",
    make_it_optional: "Hazlo opcional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "Si está marcado, los clientes tendrán la opción de no cubrir la tarifa de la tarjeta de crédito",
    max_tickets_that_can_be_purchased_at_once:
      "Entradas máximas que se pueden comprar a la vez",
    create_event: "Crear Evento",
    // Event Successful
    event_successfully_created: "Evento creado con éxito 🎉",
    "If you’re on a desktop or a laptop":
      "Si está en un escritorio o una computadora portátil, puede continuar editando en <span>el Builder avanzado</span>. El Builder Advanced ofrece potentes herramientas de personalización para ayudarlo a llevar su campaña al siguiente nivel y, en última instancia, aumentar la conversión.",
    open_the_advanced_builder: "Abra el Generador avanzado",
    edit_in_advanced_builder: "Editar en Advanced Builder",
    done: "Hecho",
  },
};
