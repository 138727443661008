import NiceModal from "@ebay/nice-modal-react";
import { TEAM_MEMBER_PANEL_MODAL } from "modals/modal_names";
import { MemberData } from "@customTypes/team.member";

type Props = {
  setPageDispatcher: React.Dispatch<React.SetStateAction<number>>;
  page: number;
};

export const useOpenTeamMemberPanel = ({ setPageDispatcher, page }: Props) => {
  const onOpenSidePanel = ({
    id,
    setSelectedRow,
    rowData,
  }: {
    id: number;
    setSelectedRow: (
      setPage: any,
      currentPage: number,
    ) => (newIdx: string | number) => void;
    rowData: MemberData;
  }) => {
    if (id && rowData.memberStatus === "joined") {
      NiceModal.show(TEAM_MEMBER_PANEL_MODAL, {
        rowData,
        setSelectedRow: setSelectedRow(setPageDispatcher, page),
      });
    }
  };

  return {
    onOpenSidePanel,
  };
};
