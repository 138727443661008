import { useFormContext, Controller } from "react-hook-form";
import UploadAvatar_V2, {
  UploadAvatarProps,
} from "@common/Avatar/UploadAvatar_V2";

export default function RHFUploadAvatar({
  name,
  file,
  ...other
}: UploadAvatarProps & {
  name: string;
  defaultImage?: string;
  width?: string | number;
  height?: string | number;
  uploadStatus?: "pending" | "success" | "error" | "idle";
  margin?: string | number;
  hiddenWhenEmpty?: boolean;
  borderRadius?: string | number;
  actionToDispatch?: (args: any) => {
    type: any;
    payload: any;
  };
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <UploadAvatar_V2 file={field.value} {...other} />;
      }}
    />
  );
}
