import React, { Dispatch, RefObject, SetStateAction } from "react";
import {
  TableContainer,
  Table,
  Stack,
  SxProps,
  TableCell,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationsFilter,
  selectConversationsGeneralSearch,
  setConversationTopic,
} from "@redux/slices/conversations";
import { TConversationTopic } from "../../hooks/useListTopics";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import TableRowSkeleton from "@common/Skeleton/TableRowSkeleton";
import { animated } from "react-spring";
import ConversationsTableBody from "./Table/ConversationsTableBody";
import ConversationsTableHead from "./Table/ConversationsTableHead";
import ConversationsEmptyState from "../ConversationsEmptyState";
import { SearchEmptyStateIcon } from "@assets/icons";
import { Theme } from "@mui/material";
import ConversationsModalSubHeader from "./ConversationsModalSubHeader";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { TableRow } from "@mui/material";
import { getGlobalTopicLabel } from "../../hooks/useConversationsModal";
import { Tabs_Types } from "../../types";

interface ConversationsModalMainContentProps {
  data: TConversationTopic[];
  isLoading: boolean;
  isFetching: boolean;
  parsedString: string;
  conversationsBarRef: RefObject<HTMLTableSectionElement>;
  setShowSearchInput: Dispatch<SetStateAction<boolean>>;
  handleScroll: (e: any) => void;
  merchantName?: string;
  isInternal?: boolean;
  tabs?: Tabs_Types;
  hasUnreadActivity?: boolean;
}

const EmptyState = ({ parsedString }: { parsedString: string }) => {
  return (
    <TableRow>
      <TableCell
        sx={{ height: "100%", borderBottom: "none" }}
        rowSpan={4}
        colSpan={4}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Stack>
            <ConversationsEmptyState
              icon={<SearchEmptyStateIcon />}
              message={`No results for ${parsedString}`}
            />
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const ConversationsModalMainContent: React.FC<
  ConversationsModalMainContentProps
> = ({
  data,
  isLoading,
  isFetching,
  parsedString,
  conversationsBarRef,
  setShowSearchInput,
  handleScroll,
  isInternal,
  tabs,
  merchantName,
  hasUnreadActivity,
}) => {
  const skeletonItems = Array.from({ length: 20 }, (_, i) => ({
    id: `skeleton-${i}`,
  }));
  const itemHeight = 45;
  const { queryString } = useAppSelector(selectConversationsGeneralSearch);
  const { filterString } = useAppSelector(selectConversationsFilter);
  const dispatch = useAppDispatch();
  const { isMobileView } = useCustomTheme();

  const filterComponent = isInternal ? (
    <ConversationsModalSubHeader
      filterString={filterString || ""}
      queryString={queryString || ""}
      isDataEmpty={!data || data.length === 0}
      isFetching={isLoading || isFetching}
    />
  ) : (
    ""
  );
  const columns: Array<{
    name: string | any;
    id: number;
    key: string;
    sx?: SxProps<Theme> | undefined;
  }> = [
    {
      name: filterComponent,
      id: 0,
      key: "",
      sx: {
        textAlign: "left",
      },
    },
    { name: "", id: 1, key: "" },
    {
      name: "Comment",
      key: "comment",
      id: 2,
    },
    {
      name: "Activity",
      key: "activity",
      id: 3,
    },
  ];

  const handleClick = (item: TConversationTopic) => {
    const isActivityTab = tabs === "Activity";
    const itemDisplayName =
      getGlobalTopicLabel(item?.name) || item?.displayName;

    dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        numberOfUnreadMessages: item.totalUnread,
        queryObject: {
          id: item.id,
          name: isActivityTab
            ? merchantName || item?.subject || itemDisplayName
            : item?.subject || itemDisplayName,
          paths: isActivityTab
            ? [
                {
                  pathID: item.id,
                  pathName: item?.displayName,
                  avatars: item?.userAvatars || [],
                },
              ]
            : [],
        },
      }),
    );
    setShowSearchInput(false);
  };
  const isEmptyState =
    parsedString && (!data || data?.length === 0) && !isFetching;

  return (
    <>
      {isMobileView && filterComponent}
      <TableContainer
        onScroll={handleScroll}
        sx={{
          maxHeight: "100%",
          paddingBottom: "50px",
          position: "relative",
        }}
      >
        <Table stickyHeader>
          {!isMobileView && (
            <ConversationsTableHead
              columns={columns}
              conversationsBarRef={conversationsBarRef}
            />
          )}
          {isEmptyState ? (
            <EmptyState parsedString={parsedString} />
          ) : (
            <ConversationsTableBody
              data={data}
              isInternal={isInternal}
              handleClick={handleClick}
              hasUnreadActivity={hasUnreadActivity}
              isMobileView={isMobileView}
              condition={
                isLoading || (isFetching && (!data || data?.length === 0))
              }
              renderOnCondition={() => (
                <UnfoldListItems
                  items={skeletonItems}
                  renderKey={(row) => row.id}
                  height={itemHeight}
                  Wrapper={animated.tbody}
                  ItemComponent={(row, idx, height) => (
                    <TableRowSkeleton
                      key={row.id}
                      height={height}
                      rowHeight={itemHeight}
                      columns={columns}
                    />
                  )}
                />
              )}
            />
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default ConversationsModalMainContent;
