import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { isMobile } from "@utils/index";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { memo, useRef, useState } from "react";
import { useThemeContext } from "../Provider/CustomThemeProvider";
import { baseColor, defaultGradients } from "../hooks/useThemeManager";
import GradientPickerPopover from "./GradientPickerPopover";
import GradientPickerPopoverNew from "./GradientPickerPopoverNew";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

interface Props {
  label: "Dark" | "Light";
  isSelected?: boolean;
}

function CustomGradientPicker({ label, isSelected }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMounted = useRef(false);
  const {
    onSelectGradientColor,
    activeGradientColor,
    apiDefaultColor,
    isEqualDefault,
  } = useThemeContext();

  const handleClick = (event: any) => {
    if (apiDefaultColor.id <= 3 && activeGradientColor?.dark === baseColor) {
      isMounted.current = true;
    }

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    isMounted.current = false;
    setAnchorEl(null);
  };

  const handleSetColor = (col: string) => {
    const isDefaultDark = defaultGradients.includes(activeGradientColor?.dark);
    const isDefaultLight = defaultGradients.includes(
      activeGradientColor?.light,
    );
    const dark = isDefaultDark ? baseColor : activeGradientColor?.dark;
    const light = isDefaultLight ? baseColor : activeGradientColor?.light;

    onSelectGradientColor({
      dark:
        label === "Dark"
          ? col
          : (dark === baseColor && !isEqualDefault) || isMounted.current
          ? col
          : dark,
      light:
        label === "Light"
          ? col
          : (light === baseColor && !isEqualDefault) || isMounted.current
          ? col
          : light,
      id: 4,
      name: "Custom",
    });
  };
  const apiDefault =
    label === "Dark" ? apiDefaultColor?.dark : apiDefaultColor?.light;
  const custDefault = apiDefaultColor?.id > 3 ? apiDefault : defaultColor;
  const color = !isSelected
    ? custDefault
    : label === "Dark"
    ? activeGradientColor?.dark
    : label === "Light"
    ? activeGradientColor?.light
    : custDefault;

  return (
    <>
      <Box>
        <Text
          color="#8F8F8F"
          fontSize="12px"
          textAlign="center"
          fontWeight="book"
        >
          {label}
        </Text>
        <Box
          onClick={handleClick}
          mt="8px"
          height="32px"
          width={isMobile ? "66px" : "82px"}
          borderRadius="5.96px"
          bgcolor={color}
          sx={{
            backgroundImage: color,
          }}
        />
      </Box>
      <FlaggedWrapper
        ActiveComponent={
          <GradientPickerPopoverNew
            anchorEl={anchorEl}
            onChange={handleSetColor}
            handleClose={handleClose}
            value={color === defaultColor ? baseColor : color}
          />
        }
        FallbackComponent={
          <GradientPickerPopover
            anchorEl={anchorEl}
            onChange={handleSetColor}
            handleClose={handleClose}
            value={color === defaultColor ? baseColor : color}
          />
        }
        name={FeatureFlagKeys.NEW_COLOR_PICKER_IMPLEMENTATION_FLAG_KEY}
      />
    </>
  );
}

export default memo(CustomGradientPicker);

const defaultColor = `linear-gradient(0deg, #D1D1D0, #D1D1D0),
           linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))`;
