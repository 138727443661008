import CopyButton from "@common/CopyButton";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ReactNode } from "react";
import { useActions } from "../hooks/useMerchantsApiKeysActions";
import { StyledAPIKey } from "./DAPanelContent";
import ModalContent from "./GeneralModalContent";
import GeneralModalMobileWrapper from "./GeneralModalMobileWrapper";

export const RolledKeyModalBody = ({ res }: { res: any }) => {
  return (
    <Box>
      <Text color="#8F8F8F" fontSize="14px">
        Your API Key has been successfully rolled
      </Text>
      <StyledAPIKey sx={{ mt: "16px", minHeight: "fit-content" }}>
        <Text fontSize="14px" fontFamily="Oxygen Mono">
          {res.apiKey}
        </Text>
        <CopyButton
          text={res.apiKey}
          sx={{ height: "25px" }}
          label="Key copied!"
          customCopySx={{
            position: "initial",
            paddingY: "initial",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        />
      </StyledAPIKey>
      <Text sx={{ fontSize: "12px", color: "#8F8F8F", mt: "4px" }}>
        For further guidance on how to get started, check out our{" "}
        <span
          style={{
            textDecoration: "underline",
            color: "rgba(109, 156, 248, 1)",
          }}
        >
          Documentation
        </span>
      </Text>
    </Box>
  );
};

type Props = {
  title: string;
  body: string | ReactNode;
  primaryActionLabel: string;
  actionId: string;
  keyId: string;
  shouldDisplaySecondaryButton?: boolean;
};

const GeneralModal = NiceModal.create(
  ({
    body,
    title,
    primaryActionLabel,
    actionId,
    keyId,
    shouldDisplaySecondaryButton = true,
  }: Props) => {
    const { isMobileView } = useCustomTheme();
    const { TransitionProps, open, onClose } = useNiceModal();
    const { fireAction, isLoading, nextActionIsClose } = useActions({
      keyId,
      onClose,
    });

    if (isMobileView) {
      return (
        <GeneralModalMobileWrapper open={open} onClose={onClose}>
          <FadeUpWrapper delay={150}>
            <ModalContent
              body={body}
              title={title}
              actionId={actionId}
              isLoading={isLoading}
              primaryActionLabel={primaryActionLabel}
              nextActionIsClose={nextActionIsClose}
              isMobileView
              onClose={onClose}
              fireAction={fireAction}
              shouldDisplaySecondaryButton={shouldDisplaySecondaryButton}
            />
          </FadeUpWrapper>
        </GeneralModalMobileWrapper>
      );
    }

    return (
      <ModalFactory
        variant="popup"
        modalProps={{
          open,
          onClose,
          PopupProps: {
            width: "576px",
            TransitionProps,
            sx: {
              padding: "24px",
            },
          },
        }}
      >
        <FadeUpWrapper delay={150}>
          <ModalContent
            body={body}
            title={title}
            actionId={actionId}
            isLoading={isLoading}
            primaryActionLabel={primaryActionLabel}
            nextActionIsClose={nextActionIsClose}
            onClose={onClose}
            fireAction={fireAction}
            shouldDisplaySecondaryButton={shouldDisplaySecondaryButton}
          />
        </FadeUpWrapper>
      </ModalFactory>
    );
  },
);

export default GeneralModal;
