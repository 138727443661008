import { customInstance } from "..";

export const getRiskProfile = (id: string) => {
  return customInstance({
    url: `/risk/ips/${id}`,
    method: "GET",
  });
};

type TGroupBy = "minute" | "hour" | "day" | "week" | "month";

type Options = Partial<{
  filter: string;
  groupBy?: TGroupBy;
}>;

export const getRiskProfileTransactions = (id: string, options?: Options) => {
  let URL = `/risk/ips/${id}/transactions?sort=-createdAt`;
  if (options) {
    Object.keys(options).forEach(
      (option) => (URL += `&${option}=${options[option as keyof Options]}`),
    );
  }

  return customInstance({
    url: URL,
    method: "GET",
  });
};

export const getRiskProfileExcalations = (id: string) =>
  customInstance({
    url: `/risk/ips/${id}/escalations`,
    method: "GET",
  });

export const getRiskProfileExcalationByID = (
  riskProfileID: string,
  id: number,
) =>
  customInstance({
    url: `/risk/ips/${riskProfileID}/escalations/${id}`,
    method: "GET",
  });
