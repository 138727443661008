import { common } from "./common";
import { analytics } from "./pages/analytics";
import { customers } from "./pages/customers";
import { hello } from "./pages/hello";
import { sweepstakes } from "./pages/sweepstakes";
import { settings } from "./pages/settings";
import { manage_money } from "./pages/manage_money";
import { fundraisers } from "./pages/fundraisers";
import { events } from "./pages/events";
import { eventsMinibuilder } from "./pages/miniBuilder/events";
import { membershipsMinibuilder } from "./pages/miniBuilder/memberships";
import { invoiceMinibuilder } from "./pages/miniBuilder/invoice";
import { fundraiserMinibuilder } from "./pages/miniBuilder/fundraiser";
import { sweepstakesMinibuilder } from "./pages/miniBuilder/sweepstakes";
import { createMerchant } from "./pages/enterprise/create_merchant";
import { manageUnderwriting } from "./pages/enterprise/manage_underwriting";
import { processing } from "./pages/enterprise/processing";
import {paymentForms} from "./pages/payment_forms";
import {paymentFormMinibuilder} from "./pages/miniBuilder/payment_form";
export const es = {
  ...common,
  ...analytics,
  ...sweepstakes,
  ...manage_money,
  ...settings,
  ...hello,
  ...customers,
  ...events,
  ...fundraisers,
  ...fundraiserMinibuilder,
  ...paymentFormMinibuilder,
  ...eventsMinibuilder,
  ...membershipsMinibuilder,
  ...invoiceMinibuilder,
  ...sweepstakesMinibuilder,
  ...paymentForms,
  ...{
    ...createMerchant,
    ...manageUnderwriting,
    ...processing,
  },
};
