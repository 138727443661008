type TOptions = {
  reset?: boolean;
  backgroundColor?: string;
};

export const highlightBackground = (el?: HTMLElement, options?: TOptions) => {
  if (!el) return;

  const newBackground = options?.reset
    ? "transparent"
    : options?.backgroundColor || "rgba(255, 248, 73, 0.50)";

  el.style.backgroundColor = newBackground;
};
