// import { MenuUniqueIds } from "@utils/index";

interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const BankIcon = ({ width = 14, height = 14, fill = "#8F8F8F" }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93446 1.72961L1.78094 3.79101C1.77029 3.79527 1.73881 3.81509 1.70842 3.86017C1.67792 3.90543 1.67148 3.94207 1.67148 3.95237V5.85429C1.67148 5.94845 1.75018 6.02716 1.84434 6.02716H12.1559C12.2501 6.02716 12.3288 5.94845 12.3288 5.85429V3.95237C12.3288 3.94207 12.3223 3.90543 12.2918 3.86017C12.2615 3.81509 12.23 3.79527 12.2193 3.79101L7.0658 1.72961C7.06454 1.72927 7.05978 1.72799 7.05104 1.72661C7.03734 1.72444 7.0197 1.72294 7.00013 1.72294C6.98056 1.72294 6.96292 1.72444 6.94922 1.72661C6.94048 1.72799 6.93572 1.72927 6.93446 1.72961ZM7.06353 1.72871C7.06349 1.72869 7.06358 1.72873 7.06353 1.72871V1.72871ZM7.00013 0.922943C7.10881 0.922943 7.24084 0.938002 7.36064 0.985924L12.5164 3.04822C12.7063 3.12417 12.8552 3.26474 12.9552 3.413C13.055 3.56108 13.1288 3.75072 13.1288 3.95237V5.85429C13.1288 6.39028 12.6919 6.82716 12.1559 6.82716H1.84434C1.30835 6.82716 0.87148 6.39028 0.87148 5.85429V3.95237C0.87148 3.75072 0.945247 3.56108 1.04507 3.413C1.14501 3.26474 1.29398 3.12417 1.48383 3.04822L6.63962 0.985924C6.75942 0.938002 6.89145 0.922943 7.00013 0.922943Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.84434 10.8372C1.75018 10.8372 1.67148 10.9159 1.67148 11.01V12.3286H12.3288V11.01C12.3288 10.9159 12.2501 10.8372 12.1559 10.8372H1.84434ZM0.87148 11.01C0.87148 10.474 1.30836 10.0372 1.84434 10.0372H12.1559C12.6919 10.0372 13.1288 10.474 13.1288 11.01V12.7286C13.1288 12.9495 12.9497 13.1286 12.7288 13.1286H1.27148C1.05057 13.1286 0.87148 12.9495 0.87148 12.7286V11.01Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41775 6.02721C2.63867 6.02721 2.81775 6.2063 2.81775 6.42721V10.4373C2.81775 10.6582 2.63867 10.8373 2.41775 10.8373C2.19684 10.8373 2.01775 10.6582 2.01775 10.4373V6.42721C2.01775 6.2063 2.19684 6.02721 2.41775 6.02721Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70884 6.02721C4.92976 6.02721 5.10884 6.2063 5.10884 6.42721V10.4373C5.10884 10.6582 4.92976 10.8373 4.70884 10.8373C4.48793 10.8373 4.30884 10.6582 4.30884 10.4373V6.42721C4.30884 6.2063 4.48793 6.02721 4.70884 6.02721Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00066 6.02721C7.22157 6.02721 7.40066 6.2063 7.40066 6.42721V10.4373C7.40066 10.6582 7.22157 10.8373 7.00066 10.8373C6.77975 10.8373 6.60066 10.6582 6.60066 10.4373V6.42721C6.60066 6.2063 6.77975 6.02721 7.00066 6.02721Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29175 6.02721C9.51266 6.02721 9.69175 6.2063 9.69175 6.42721V10.4373C9.69175 10.6582 9.51266 10.8373 9.29175 10.8373C9.07083 10.8373 8.89175 10.6582 8.89175 10.4373V6.42721C8.89175 6.2063 9.07083 6.02721 9.29175 6.02721Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5836 6.02721C11.8045 6.02721 11.9836 6.2063 11.9836 6.42721V10.4373C11.9836 10.6582 11.8045 10.8373 11.5836 10.8373C11.3627 10.8373 11.1836 10.6582 11.1836 10.4373V6.42721C11.1836 6.2063 11.3627 6.02721 11.5836 6.02721Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.299072 12.7286C0.299072 12.5077 0.478158 12.3286 0.699072 12.3286H13.3021C13.523 12.3286 13.7021 12.5077 13.7021 12.7286C13.7021 12.9495 13.523 13.1286 13.3021 13.1286H0.699072C0.478158 13.1286 0.299072 12.9495 0.299072 12.7286Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00025 3.67642C6.74659 3.67642 6.54095 3.88206 6.54095 4.13572C6.54095 4.38938 6.74659 4.59502 7.00025 4.59502C7.25391 4.59502 7.45955 4.38938 7.45955 4.13572C7.45955 3.88206 7.25391 3.67642 7.00025 3.67642ZM5.74095 4.13572C5.74095 3.44023 6.30476 2.87642 7.00025 2.87642C7.69574 2.87642 8.25955 3.44023 8.25955 4.13572C8.25955 4.83121 7.69574 5.39502 7.00025 5.39502C6.30476 5.39502 5.74095 4.83121 5.74095 4.13572Z"
        fill={fill}
      />
    </svg>
  );
};

export default BankIcon;
