import React from "react";
import BackgroundImageContainer, {
  BackgroundImageContainerProps,
} from "@common/BackgroundImageContainer";
import { Stack, styled } from "@mui/material";
import Footer from "@pages/Login/Footer";
import { Image } from "@common/StyledImage/Image";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
interface PageContainerProps extends BackgroundImageContainerProps {
  imgSrc: string;
  title: string;
  description?: ITextProps["children"];
  actions?: JSX.Element;
  hideSupport?: boolean;
}

const PageContainer = styled<React.FC<PageContainerProps>>(
  ({ title, description, imgSrc, actions, hideSupport, ...rest }) => {
    return (
      <BackgroundImageContainer {...rest}>
        <Wrapper>
          <ContentContainer>
            <Image src={imgSrc} />
            <Text
              color={({ palette }) => palette.neutral["90"]}
              fontSize="24px"
              fontWeight="medium"
              textAlign="center"
              lineHeight="32px"
            >
              {title}
            </Text>
            {description && (
              <Text
                color={({ palette }) => palette.neutral["80"]}
                fontSize="16px"
                fontWeight="regular"
                textAlign="center"
              >
                {description}
              </Text>
            )}
            {actions}
          </ContentContainer>
        </Wrapper>
        <Footer
          linkGoupeWrapperStyle={(theme) => ({
            [theme.breakpoints.down("new_sm")]: {
              width: "100%",
              justifyContent: "space-between",
            },
          })}
          sx={(theme) => ({
            ".MuiTypography-root": {
              fontWeight: 400,
            },
            [theme.breakpoints.down("new_sm")]: {
              flexDirection: "column-reverse",
              gap: "8px",
            },
          })}
        />
      </BackgroundImageContainer>
    );
  },
)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  objectFit: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "90% 100px",
  [theme.breakpoints.down("new_sm")]: {
    backgroundPositionY: "-50px",
  },
}));

const Wrapper = styled(Stack)(() => ({
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  width: "50%",
  gap: "30px",

  [theme.breakpoints.down("new_sm_md")]: {
    width: "100%",
    padding: "0 25px",
  },
}));

export default PageContainer;
