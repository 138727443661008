import { Stack, SxProps, keyframes, styled } from "@mui/material";
import { palette } from "@palette";
import { PaperPlaneTilt } from "@phosphor-icons/react";

type Props = {
  hidden?: boolean;
  sx?: SxProps;
  customCopySx?: SxProps;
  testId?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit" | undefined;
  label?: string;
  showIcon?: boolean;
};

const FloatingSendButton = ({
  hidden = true,
  sx,
  testId,
  onClick,
  disabled = false,
  type = "button",
  label = "Send",
  showIcon = true,
}: Props) => {
  return (
    <Button
      sx={{ ...sx }}
      type={type}
      data-testid={testId}
      hidden={hidden}
      onClick={onClick}
      disabled={disabled}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          whiteSpace: "nowrap",
          justifyContent: "space-between",
          gap: "8px",
        }}
      >
        {label}
        {showIcon && <PaperPlaneTilt size={20} />}
      </Stack>
    </Button>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    bottom: 10px;
  }
  to {
    opacity: 1;
    bottom: 20px;
  }
`;

const Button = styled("button")<{ hidden: boolean }>(({ hidden }) => ({
  cursor: "pointer",
  background: palette.primary.main,
  color: palette.neutral.white,
  borderRadius: "32px",
  width: "auto",
  hight: "46px",
  fontSize: "18px",
  lineHeight: "21.6px",
  padding: "12px 24px",
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 9,
  transition: "none",
  display: hidden ? "none" : "block",
  animation: hidden ? "none" : `${fadeIn} 0.5s ease-out`,
}));

export default FloatingSendButton;
