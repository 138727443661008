import EscalationWrapper, {
  DayGroupWrapper,
} from "features/TransactionsRiskProfile/utils/getTransactionWrappers";
import {
  ParsedTransaction,
  FormattedListItem,
} from "features/TransactionsRiskProfile/data.types";
import TransactionListItem, {
  TransactionItemCell,
} from "./TransactionListItem";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import { TTransactionStatus } from "features/TransactionsRiskProfile/data.types";
import { useState } from "react";
import { Stack } from "@mui/material";

const ListGroup = ({ currentGroup }: { currentGroup: FormattedListItem }) => {
  const { label, list } = currentGroup;
  return (
    <DayGroupWrapper label={label}>
      {list.map((stack) => {
        return (
          <EscalationWrapper key={stack[0].id} stack={stack}>
            <StackItem transactions={stack} />
          </EscalationWrapper>
        );
      })}
    </DayGroupWrapper>
  );
};

const StackItem = ({ transactions }: { transactions: ParsedTransaction[] }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  if (transactions.length === 1) {
    return renderListItem(transactions[0]);
  }

  if (isCollapsed) {
    return renderListItem(transactions[0], {
      isStack: true,
      onClick: () => setIsCollapsed(false),
    });
  }

  return (
    <Stack direction="column" gap="12px" alignItems="stretch">
      <UnfoldListItems
        items={transactions}
        renderKey={(row, idx) => `${row.email}/${row.date} - ${idx}`}
        animationName="waterfall"
        ItemComponent={(row) => renderListItem(row)}
      />
    </Stack>
  );
};

type RenderOptions = Partial<{
  isStack: boolean;
  onClick: () => void;
}>;

const renderListItem = (row: ParsedTransaction, options?: RenderOptions) => {
  const isDeEscalation = row?.escalation?.triggerType === "deescalation";

  const cells: TransactionItemCell[] = isDeEscalation
    ? [
        {
          name: "date",
          data: new Date(row.date * 1000),
          customStyle: {
            color: "#326EC5",
          },
        },
        {
          name: "riskDeEscalation",
          data: {
            triggerReason: row?.escalation?.triggerReason,
            risklevel: row?.escalation?.riskLevel,
          },
        },
      ]
    : [
        {
          name: "date",
          data: new Date(row.date * 1000),
        },
        {
          name: "cardAndEmail",
          data: {
            card: row.card,
            email: row.email,
          },
        },
        {
          name: "merchantName",
          data: row.merchantName,
        },
        {
          name: "charged",
          data: row.charged,
        },
        {
          name: "status",
          data: {
            status: row.status as TTransactionStatus,
            displayStatus: row.displayStatus,
          },
          customStyle: {
            justifyContent: "flex-end",
          },
        },
      ];
  return (
    <TransactionListItem
      id={row.id}
      merchantId={row.merchantId}
      cells={cells}
      isStack={options?.isStack}
      onClick={options?.onClick}
      isDeEscalation={isDeEscalation}
    />
  );
};

export default ListGroup;
