function EditIconNew({ fill = "#8F8F8F", width = "17", height = "17" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7586 4.73201L12.268 1.24061C12.1519 1.1245 12.0141 1.0324 11.8624 0.969568C11.7107 0.906731 11.5482 0.87439 11.384 0.87439C11.2198 0.87439 11.0572 0.906731 10.9056 0.969568C10.7539 1.0324 10.6161 1.1245 10.5 1.24061L0.866412 10.875C0.749834 10.9906 0.657407 11.1283 0.594506 11.28C0.531604 11.4317 0.499482 11.5944 0.500006 11.7586L0.500006 15.25C0.500006 15.5815 0.631702 15.8994 0.866123 16.1339C1.10054 16.3683 1.41849 16.5 1.75001 16.5H5.24141C5.40563 16.5005 5.5683 16.4684 5.71999 16.4055C5.87168 16.3426 6.00935 16.2502 6.12501 16.1336L15.7586 6.49998C15.8747 6.3839 15.9668 6.24609 16.0296 6.09442C16.0925 5.94274 16.1248 5.78017 16.1248 5.616C16.1248 5.45182 16.0925 5.28925 16.0296 5.13758C15.9668 4.9859 15.8747 4.84809 15.7586 4.73201ZM5.24141 15.25H1.75001L1.75001 11.7586L8.62501 4.88358L12.1164 8.37498L5.24141 15.25ZM13 7.49061L9.5086 3.99998L11.3836 2.12498L14.875 5.61561L13 7.49061Z"
        fill={fill}
      />
    </svg>
  );
}

export default EditIconNew;
