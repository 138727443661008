import { Information } from "./Information";
import Selfie from "./Selfie";
import { VerifyUpload } from "./VerifyUpload";

export const Tabs = {
  information: {
    id: "information",
    label: "Personal Information",
    message: "Let us know more about yourself",
    Render: Information,
    next: "upload",
    prev: null,
    hideActionsInMobileView: false,
  },
  upload: {
    id: "upload",
    label: "Upload your ID",
    message:
      "Upload an identification document such as a driver license, passport, or government issued ID card.",
    Render: VerifyUpload,
    next: "selfie",
    prev: "information",
    hideActionsInMobileView: false,
  },
  selfie: {
    id: "selfie",
    label: "Take a selfie",
    message: "A selfie is needed to confirm your ID",
    Render: Selfie,
    next: null,
    prev: "upload",
    hideActionsInMobileView: true,
  },
} as const;
