import { SvgIconProps } from "@mui/material";

function CaretUp({ width = 24, height = 24, fill = "#8F8F8F" }: SvgIconProps) {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.969444 7.96938L8.46945 0.469384C8.5391 0.399652 8.62182 0.344332 8.71287 0.306589C8.80392 0.268846 8.90151 0.24942 9.00007 0.24942C9.09863 0.24942 9.19623 0.268846 9.28728 0.306589C9.37833 0.344332 9.46104 0.399652 9.5307 0.469384L17.0307 7.96938C17.1714 8.11012 17.2505 8.30099 17.2505 8.50001C17.2505 8.69903 17.1714 8.88991 17.0307 9.03064C16.89 9.17137 16.6991 9.25043 16.5001 9.25043C16.301 9.25043 16.1102 9.17137 15.9694 9.03064L9.00007 2.06032L2.0307 9.03063C1.96101 9.10032 1.87829 9.15559 1.78724 9.1933C1.6962 9.23102 1.59862 9.25043 1.50007 9.25043C1.40152 9.25043 1.30394 9.23102 1.2129 9.1933C1.12185 9.15559 1.03913 9.10032 0.969444 9.03063C0.899763 8.96095 0.844488 8.87823 0.806776 8.78718C0.769064 8.69614 0.749653 8.59855 0.749653 8.50001C0.749653 8.40146 0.769064 8.30388 0.806776 8.21284C0.844488 8.12179 0.899763 8.03907 0.969444 7.96938Z"
        fill={fill}
      />
    </svg>
  );
}

export default CaretUp;
