import _ from "lodash";
import { MissingPermissionContent } from "../PopUp/MissingPermissionContent";
import useAccessControl, { TUseAccessControl } from "./useAccessControl";
import {
  NotAuthorizedBanner,
  NotAuthorizedMessage,
} from "@containers/NotAuthorizedWrapper";

type TRenderType = "page-with-banner" | "message" | "banner";

interface IWithAccessControl extends TUseAccessControl {
  children: React.ReactNode;
  render?: TRenderType | (() => React.ReactNode);
}

const WithAccessControl = ({
  children,
  render = "message",
  ...useAccessControlProps
}: IWithAccessControl) => {
  const isAllowed = useAccessControl(useAccessControlProps);

  if (isAllowed) return <>{children}</>;

  if (_.isFunction(render)) return <>{render()}</>;

  switch (render) {
    case "page-with-banner":
      return <NotAuthorizedMessage />;
    case "banner":
      return <NotAuthorizedBanner />;
    default:
      return <MissingPermissionContent />;
  }
};

export default WithAccessControl;
