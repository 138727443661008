import { Input } from "@common/Input";
import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { useAppDispatch } from "@redux/hooks";
import { setConversationsGeneralSearchQueryString } from "@redux/slices/conversations";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

type TScreen = "Topics" | "Threads" | "Replies";

const ConversationsGeneralSearchInput = ({
  defaultValue = "",
  screen,
}: {
  defaultValue?: string;
  screen: TScreen;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>(defaultValue);

  const dispatch = useAppDispatch();

  useEffect(() => {
    ref.current?.focus();
  }, []);

  const handleClick = () => {
    setValue("");
    debouncedSearch("");
  };

  const debouncedSearch = useCallback(
    debounce((text) => {
      dispatch(
        setConversationsGeneralSearchQueryString({
          queryKey: screen,
          queryString: text,
        }),
      );
    }, 300),
    [screen],
  );

  const handleSearch = (text: string) => {
    debouncedSearch(text);
    setValue(text);
  };

  return (
    <>
      <Box>
        <Input
          inputRef={ref}
          fullWidth
          value={value}
          onChange={(v) => handleSearch(v.target.value)}
          placeholder="Search"
          InputLabelProps={{
            style: {
              color: "#575353 !important",
            },
          }}
          endIcon={
            <Box
              onClick={handleClick}
              display={value.length > 0 ? "initial" : "none"}
              sx={{ cursor: "pointer" }}
            >
              <Text color="#6D9CF8">Clear</Text>
            </Box>
          }
          sx={{
            "& .MuiInputBase-root": {
              border: "none",
              borderTop: "1px solid #ECECE9",
              borderBottom: "1px solid #ECECE9",
              borderRadius: 0,
              backgroundColor: "transparent",
              ":hover": {
                borderTop: "1px solid #ECECE9",
                borderBottom: "1px solid #ECECE9",
              },
              padding: "14px 12px",
              "&.Mui-focused": {
                borderTop: "1px solid #ECECE9",
                borderBottom: "1px solid #ECECE9",
                backgroundColor: "transparent",
              },
            },
          }}
        />
      </Box>
    </>
  );
};

export default ConversationsGeneralSearchInput;
