import { Text } from "@common/Text";
import { Box, SxProps } from "@mui/material";
import { palette } from "@palette";

type PurchaseBoxProps = {
  title: string | React.ReactNode;
  value: string | number;
  flexEnd?: boolean;
  isAmount?: boolean;
  reverse?: boolean;
  titleSx?: SxProps;
};

const PurchaseBox = ({
  title,
  value,
  flexEnd,
  isAmount,
  reverse,
  titleSx,
}: PurchaseBoxProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={flexEnd ? "flex-end" : "flex-start"}
    >
      {!reverse ? (
        <>
          <Text sx={{ ...titleStyle, ...titleSx }}>{title}</Text>
          <Text sx={valueStyle}>
            {value} {isAmount && <sup>USD</sup>}
          </Text>
        </>
      ) : (
        <>
          <Text sx={valueStyle}>
            {value} {isAmount && <sup>USD</sup>}
          </Text>
          <Text sx={{ ...titleStyle, ...titleSx }}>{title}</Text>
        </>
      )}
    </Box>
  );
};

const titleStyle = {
  color: palette.neutral[800],
  fontWeight: "500",
  fontSize: "16px",
};

const valueStyle = {
  color: palette.neutral[600],
  fontWeight: "400",
};

export default PurchaseBox;
