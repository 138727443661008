import * as React from "react";
import DrawerList from "./DrawerList";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectDrawerHidden, setMobileDrawerOpen } from "@redux/slices/app";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import useDrawerReducer from "@hooks/Reducers/useDrawerReducer";
import { HiddenComponent } from "@containers/HiddenComponent";
import { DesktopDrawer, DrawerContent, MobileDrawer } from "./Drawer.styles";
import DrawerListHeader from "./DrawerListHeader";

export default function Drawer() {
  const drawerHidden = useAppSelector(selectDrawerHidden);
  const { isMasqueradeMode } = useMasqueradeReducer();
  const { isLargeView } = useCustomTheme();

  const { drawerOpen: desktopOpen, mobileDrawerOpen: mobileOpen } =
    useDrawerReducer();
  const drawerOpen = isLargeView ? desktopOpen : mobileOpen;

  return (
    <HiddenComponent hidden={drawerHidden}>
      <Wrapper drawerOpen={drawerOpen}>
        <DrawerContent isMasqueradeMode={isMasqueradeMode}>
          <DrawerListHeader drawerOpen={drawerOpen} />
          <DrawerList drawerOpen={drawerOpen} />
        </DrawerContent>
      </Wrapper>
    </HiddenComponent>
  );
}

const Wrapper = ({
  drawerOpen,
  children,
}: {
  drawerOpen: boolean;
  children: React.ReactNode;
}) => {
  const { isDesktopView, isLargeView } = useCustomTheme();
  const { isMasqueradeMode } = useMasqueradeReducer();
  const dispatch = useAppDispatch();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      dispatch(setMobileDrawerOpen(open));
    };

  if (isDesktopView) {
    return (
      <DesktopDrawer
        variant="permanent"
        open={isLargeView ? drawerOpen : false}
        isMasqueradeMode={isMasqueradeMode}
      >
        {children}
      </DesktopDrawer>
    );
  }
  return (
    <MobileDrawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      ModalProps={{
        BackdropProps: {
          sx: {
            backdropFilter: "blur(4px)",
            backgroundColor: "rgba(53, 53, 53, 0.15)",
          },
        },
      }}
    >
      {children}
    </MobileDrawer>
  );
};
