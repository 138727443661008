import * as React from "react";
import { palette } from "@palette";
import { useNavigate } from "react-router-dom";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { showMessage } from "@common/Toast/ShowToast";
// localization
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import Popup from "@common/Popup";
import { TEventModalInputs } from "../EventsMinibuilder/types";
import { TFundraiserModalInputs } from "./types";

type FundraiserSuccessModalProps = {
  data: TFundraiserModalInputs | TEventModalInputs;
};

const FundraiserSuccessModal = NiceModal.create(
  ({ data }: FundraiserSuccessModalProps) => {
    const modal = useModal();
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const { t } = useTranslation(namespaces.pages.fundraiserMinibuilder);

    const handleClose = () => {
      modal.hide();
    };

    const openFundraisersHandler = () => {
      handleClose();
      navigate("merchant/fundraisers/advanced-builder");
    };

    const handleDone = () => {
      handleClose();
      showMessage(
        "Success",
        <>
          Your new Fundraiser{" "}
          <span style={{ fontWeight: "600" }}>“{data.about.title}”</span> has
          been successfully created and it’s ready to accept payments
        </>,
        isDesktop,
        "Fundraiser Created",
      );
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={t("fundraiser_successfully_created")}
        onSubmit={handleDone}
        actionLabel={t("done")}
        data-testid="fundraiser_success_modal"
      >
        <Markup content={t("fundraiser_create_success_description")} />
      </Popup>
    );
  },
);

export default FundraiserSuccessModal;
