import { MinusIcon, PlusIcon, SearchIconFilled } from "@assets/icons";
import { StyledIconButton } from "@common/IconButton";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { ZoomDirection, ZoomHandler } from "../types";

export const ZoomControls = ({
  onZoom,
  disabled,
}: {
  onZoom: ZoomHandler;
  disabled: Record<ZoomDirection, boolean>;
}) => {
  const icon: Record<ZoomDirection, JSX.Element> = {
    out: <MinusIcon width={22} stroke={palette.gray[300]} />,
    in: <PlusIcon width={22} height={22} fill={palette.gray[300]} />,
  };
  return (
    <Stack direction="row" gap={1} alignItems="center" paddingBlock="12px">
      <IconButton
        data-testid="minus-icon"
        disabled={disabled["out"]}
        onClick={() => onZoom("out")}
      >
        {icon["out"]}
      </IconButton>
      <SearchIconFilled />
      <IconButton
        data-testid="plus-icon"
        disabled={disabled["in"]}
        onClick={() => onZoom("in")}
      >
        {icon["in"]}
      </IconButton>
    </Stack>
  );
};

const IconButton = styled(StyledIconButton)(({ disabled }) => ({
  width: "24px !important",
  height: "24px !important",
  padding: "0 !important",
  borderRadius: "100px",
  background: "inherit",
  userSelect: "none",

  "&:hover": {
    backgroundColor: palette.liftedWhite[200],
    ...(disabled && {
      cursor: "default",
    }),
  },
}));
