import { SvgIconProps } from "@mui/material";

const PercentageStepEmptyIcon = ({
  width = 45,
  height = 9,
  fill = "#C1C1C1",
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox="0 0 45 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.000976562"
        y="0.618408"
        width="44.8"
        height="8"
        rx="4"
        fill={fill}
      />
    </svg>
  );
};

export default PercentageStepEmptyIcon;
