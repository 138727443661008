import React from "react";
import { ParsedTransaction, TRiskEscalation } from "../data.types";
import { getRiskColor } from ".";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { formatTransactionDate } from "../components/TransactionsList/atoms";

const EscalationWrapper = ({
  stack,
  children,
}: {
  stack: ParsedTransaction[];
  children: React.ReactNode;
}) => {
  const { escalation } = stack[0];
  const isEscalation = escalation.triggerPoints > 0;
  const newRiskLevel = escalation.riskLevel + escalation.triggerPoints;
  const { color, escalationText, tagBackground, escalationIcon } = getRiskColor(
    newRiskLevel > 3 ? 3 : newRiskLevel,
  );

  return (
    <ListItem
      isEscalation={isEscalation}
      color={color}
      background={tagBackground}
      data-testid="escalation-list-item"
    >
      {isEscalation && (
        <EscalationInfo
          {...escalation}
          riskLevel={newRiskLevel > 3 ? 3 : newRiskLevel}
          color={escalationText}
          Icon={escalationIcon}
        />
      )}
      {children}
    </ListItem>
  );
};

export default EscalationWrapper;

const ListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isEscalation",
})<{
  isEscalation: boolean;
  color: string;
  background: string;
}>(({ isEscalation, color, background }) => ({
  ...(isEscalation && {
    padding: "16px 8px",
    border: `1px solid ${color}`,
    background,
    borderRadius: "8px",
  }),
  ...(!isEscalation && {
    paddingInline: "8px",
  }),
}));

type EscalationInfoProps = TRiskEscalation & {
  color: string;
  Icon: React.ReactNode;
};

const EscalationInfo = ({
  createdAt,
  riskLevel,
  triggerReason,
  color,
  Icon,
  triggerPoints,
}: EscalationInfoProps) => {
  return (
    <Stack
      direction="row"
      gap={4}
      alignItems="center"
      justifyContent="space-between"
      padding="8px 16px"
    >
      <Stack direction="row" gap={4} alignItems="center">
        <Text color={color} fontWeight="book" lineHeight="16.8px">
          {formatTransactionDate(new Date(createdAt * 1000))}
        </Text>
        <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
          {Icon}
          <Stack direction="column">
            <Text
              color={color}
              fontSize="18px"
              fontWeight="book"
              lineHeight="21.6px"
              letterSpacing="-0.18px"
            >
              Risk escalation Level{" "}
              <Box component="span" sx={{ fontWeight: 400 }}>
                {riskLevel}{" "}
              </Box>
              <Box component="span" sx={{ fontWeight: 400 }}>
                (+{triggerPoints} Points)
              </Box>
            </Text>
            <Text color={color} fontWeight="book" lineHeight="16.8px">
              {triggerReason}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

type DayGroupWrapperProps = {
  label: string;
  children: React.ReactNode;
};

export const DayGroupWrapper = ({ label, children }: DayGroupWrapperProps) => (
  <Stack direction="column" gap="12px" alignItems="stretch">
    {label && <DayLabel>{label}</DayLabel>}
    {children}
  </Stack>
);

const DayLabel = styled(Text)(() => ({
  padding: "2px 24px",
  color: palette.black[100],
  borderRadius: "100px",
  background: palette.liftedWhite[100],
  lineHeight: "16.8px",
  marginInline: "auto",
  textAlign: "center",
}));
