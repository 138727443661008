import { updateUserPermissions } from "@services/api/accessControl/accessControl";
import { useMutation } from "react-query";

const useDeletePermission = (accountId: number, memberId: number) => {
  const request = async (permissionID: string) => {
    return updateUserPermissions(accountId, memberId, {
      detach: [permissionID],
    })
  };
  return useMutation(request);
}

export default useDeletePermission;