import { getTransaction } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/utils";
import NiceModal from "@ebay/nice-modal-react";
import { useUser } from "@hooks/common/useUser";
import { useGetUser } from "@services/api/onboarding/user";
import { TRANSACTION_NOT_FOUND } from "modals/modal_names";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useRedirectTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantData } = useGetUser();
  const { isAcquirer, isMerchant } = useUser();

  useEffect(() => {
    if (merchantData) {
      const path = isMerchant
        ? "/merchant/manage-money"
        : isAcquirer
        ? "/acquirer/processing"
        : "/provider/processing";

      const checkAccessToTransaction = async (transactionId: string) => {
        try {
          const transactionData = await getTransaction(transactionId);
          navigate(path, { state: { transactionData } });
        } catch (error) {
          navigate(path);
          NiceModal.show(TRANSACTION_NOT_FOUND);
        }
      };

      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("transactionId")) {
        const transactionId = searchParams.get("transactionId") || "";
        checkAccessToTransaction(transactionId);
      }
    }
  }, [merchantData, location.search]);
};
