import { Title, Wrapper } from "./PermissionsPanel.styles";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import PermissionsPanelSkeleton from "./PermissionsPanelSkeleton";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import ListWrapperWithStates from "@containers/ListWrapperWithStates";
import PermissionTabs from "../Tabs/PermissionTabs";
import PermissionsList from "./PermissionsList";
import { Stack, styled } from "@mui/material";
import PermissionsFilterBar from "./PermissionsFilterBar";
import AddPermissionModal from "../AddPermissionModal/AddPermissionModal";
import LatestAdded from "./LatestAdded";
import { Button } from "@common/Button";
import usePermissionsPanel from "../../hooks/usePermissionsPanel";
import React, { useEffect } from "react";

const PermissionsPanel = ({
  memberId,
  setIsLoading,
}: {
  memberId: number;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    dataIsLoading,
    dataIsFetching,
    permissionsData,
    latestAddedData,
    listMutated,
    listWrapperProps,
    sectionsRef,
    tabs,
    searchQuery,
    handleSearch,
    handleSetFilter,
    handleAddPermission,
    toggleModal,
    showLatest,
    setPermissionStatus,
    onAddCustomPermission,
    open,
    handleClose,
    onDelete,
    handleDiscard,
    onSave,
    totalItems,
  } = usePermissionsPanel(memberId);

  const isLoading = dataIsLoading && !searchQuery;
  const isFetching =
    (totalItems === 0 || !permissionsData.data) &&
    dataIsFetching &&
    !searchQuery;
  const isListLoading = isLoading || isFetching;

  useEffect(() => {
    setIsLoading(isListLoading);
  }, [isListLoading]);

  return (
    <ErrorCatcher errorID="Permissions panel">
      <Wrapper>
        {isListLoading ? (
          <PermissionsPanelSkeleton />
        ) : (
          <>
            <FadeUpWrapper delay={250}>
              <Title>Permissions</Title>
            </FadeUpWrapper>
            <ListWrapperWithStates {...listWrapperProps}>
              <FadeUpWrapper delay={300}>
                <PermissionTabs tabs={tabs} sectionsRef={sectionsRef} />
              </FadeUpWrapper>
              {showLatest && <LatestAdded />}
              <FadeUpWrapper delay={350}>
                <PermissionsFilterBar
                  searchQuery={searchQuery}
                  handleSearch={handleSearch}
                  handleAdd={toggleModal}
                  filters={filters}
                  setFilter={handleSetFilter}
                />
              </FadeUpWrapper>
              <PermissionsList
                data={showLatest ? latestAddedData : permissionsData}
                sectionsRef={sectionsRef}
                setPermissionStatus={setPermissionStatus}
                handleDelete={onDelete}
                searchQuery={searchQuery}
                isLoading={
                  dataIsLoading ||
                  ((totalItems === 0 || !permissionsData.data) &&
                    dataIsFetching)
                }
              />
            </ListWrapperWithStates>
          </>
        )}
        {listMutated && (
          <FloatingStack direction="row" alignItems="center" gap={1}>
            <ActionButton
              sx={{ backgroundColor: "#ECECE9" }}
              background="secondary"
              size="medium"
              onClick={handleDiscard}
            >
              Discard
            </ActionButton>
            <ActionButton size="medium" onClick={onSave}>
              Save changes
            </ActionButton>
          </FloatingStack>
        )}
      </Wrapper>
      <AddPermissionModal
        open={open}
        onClose={handleClose}
        onAdd={handleAddPermission}
        onAddCustom={onAddCustomPermission}
        memberId={memberId}
      />
    </ErrorCatcher>
  );
};

const filters = [
  {
    value: "all",
    title: "All permissions",
  },
  {
    value: "allow",
    title: "Allow permissions",
  },
  {
    value: "deny",
    title: "Deny permissions",
  },
];

export default PermissionsPanel;

const FloatingStack = styled(Stack)(() => ({
  left: "50%",
  transform: "translateX(-50%)",
  position: "absolute",
  bottom: "10px",
  zIndex: 99,
}));

const ActionButton = styled(Button)(() => ({
  paddingY: "2px",
  fontWeight: 400,
  fontSize: "14px",
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
}));
