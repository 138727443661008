import React from "react";
import { Modal } from "@common/Modal";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import {
  Box,
  DialogActions,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { palette } from "@palette";
import TransferMoneyFormProvider from "./TransferMoneyFormProvider";
import { Button } from "@common/Button";
import { ChooseMethod } from "./Verification/ChooseMethod";
import { CodeInput } from "./Verification/CodeInput";
import MoneyTransferDetails from "./Transfer/MoneyTransferDetails";
import MoneyTransferSuccess from "./Transfer/MoneyTransferSuccess";
import { selectUser } from "@redux/slices/auth/auth";
import { useAppSelector } from "@redux/hooks";
import Cookies from "js-cookie";
import { addHours } from "date-fns";
import { useGetCurrentMerchantId } from "@hooks/common";

const getModalTexts = (step: number) => {
  switch (step) {
    case 1:
      return {
        title: "Identity verification",
        tertiaryBtn: "I already have a code",
        primaryBtn: "Send verification code",
      };
    case 2:
      return {
        title: "Identity verification",
        tertiaryBtn: "Cancel",
        primaryBtn: "Verify code",
      };
    case 3:
      return {
        title: "Send Money",
        tertiaryBtn: "Cancel",
        primaryBtn: "Send Money",
      };
    case 4:
      return { title: "Transfer Money", secondaryBtn: "", primaryBtn: "Close" };
    default:
      return {
        title: "Identity verification",
        secondaryBtn: "I already have a code",
        primaryBtn: "Send verification code",
      };
  }
};

type Props = {
  balance: number;
};

const TransferMoneyModal = NiceModal.create(({ balance }: Props) => {
  const modal = useModal();
  const [step, setStep] = React.useState<number>(1);
  const [disabled, setDisabled] = React.useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { merchantId } = useGetCurrentMerchantId();

  const handleModalClose = () => {
    modal.hide();
  };

  React.useEffect(() => {
    const transfer = Cookies.get("transfer-verification");
    if (!transfer) return;
    const transferData = JSON.parse(transfer);
    if (transferData.date && transferData.id) {
      if (
        new Date() < addHours(new Date(transferData.date), 1) &&
        transferData.id === merchantId
      ) {
        setStep(3);
      } else {
        Cookies.remove("transfer-verification");
      }
    }
  }, []);

  const handleCancel = () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      default:
        handleModalClose();
        break;
    }
  };
  const isMobileSuccessful = !isDesktop && step === 4;

  return (
    <StyledModal
      {...muiDialogV5(modal)}
      title={getModalTexts(step).title}
      onBackdropClick={() => modal.hide()}
      sx={{
        ...modalStyles,
        ...(!isDesktop && {
          "& .MuiDialog-paper": {
            top: "50%",
            transform: "translateY(-50%)",
            height: isMobileSuccessful ? "100%" : "auto",
            borderRadius: isMobileSuccessful ? 0 : "16px",
            ...(isMobileSuccessful ? { margin: 0 } : { margin: "0 16px" }),
          },
        }),
        "& .MuiDialogActions-root": {
          backgroundColor: "transparent",
        },
      }}
      onClose={handleModalClose}
      shouldShowTitle={false}
      actions={
        <DialogActions sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "16px",
              ...(step === 2 && !isDesktop && { width: "100%" }),
              ...(step !== 2 && {
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "stretch",
                  width: "100%",
                  gap: "8px",
                },
              }),
            }}
          >
            {step !== 4 && (
              <Button
                background="tertiary"
                size="medium"
                onClick={handleCancel}
                fullWidth={!isDesktop}
                data-testid={`transfer-money-form-${
                  getModalTexts(step).tertiaryBtn
                }`}
              >
                {getModalTexts(step).tertiaryBtn}
              </Button>
            )}
            <Button
              variant="primary"
              size="medium"
              form="transfer-money-form"
              type="submit"
              disabled={disabled}
              fullWidth={!isDesktop}
              data-testid={`transfer-money-form-${
                getModalTexts(step).primaryBtn
              }`}
            >
              {getModalTexts(step).primaryBtn}
            </Button>
          </Box>
        </DialogActions>
      }
      data-testid="transfer-money-modal"
    >
      <TransferMoneyFormProvider>
        <Box sx={modalContainerStyle}>
          {step === 1 && (
            <ChooseMethod setStep={setStep} setDisabled={setDisabled} />
          )}
          {step === 2 && (
            <CodeInput setStep={setStep} setDisabled={setDisabled} />
          )}
          {step === 3 && (
            <MoneyTransferDetails
              setStep={setStep}
              setDisabled={setDisabled}
              balance={balance}
            />
          )}
          {step === 4 && <MoneyTransferSuccess PreviousAmount={balance} />}
        </Box>
      </TransferMoneyFormProvider>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "0",
  },
  "& .MuiDialogTitle-root + .MuiDialogContent-root": {
    paddingTop: "0 !important",
  },
  [theme.breakpoints.down(950)]: {
    "& .MuiDialog-paper": {
      margin: "0 16px",
    },
  },
}));

const modalStyles = {
  "& .MuiDialog-paper": {
    width: "560px",
    boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
    backdropFilter: "blur(20px)",
    background: palette.background.dimmedWhite,
  },
  "& .MuiDialogTitle-root": {
    background: palette.liftedWhite.main,
    "& > button": {
      background: palette.liftedWhite.main,
    },
  },
};

const modalContainerStyle = {
  padding: "16px",
  overflow: "hidden",
};

export default TransferMoneyModal;
