import { Box, Stack } from "@mui/material";
import PageTitle from "./PageTitle";
import HomepageCard, { HomepageCardStatus } from "./HomepageCard";
import { THomepageOption } from "../ProfilePageContainer";
import { Text } from "@common/Text";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
import { VERIFY_ACCOUNT_HOLDER_V2 } from "modals/modal_names";
import useCheckPAH from "@hooks/common/useCheckPAH";
import { useGetCurrentMerchantId } from "@hooks/common";
import useFindBusinessProfileById from "@hooks/enterprise-api/merchants/useFindBusinessProfileById";
import { checkPortals } from "@utils/routing";
import { useTriggerPathEffect } from "@hooks/common/router/useRedirect";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";

export type ProfileSetupHomeProps = {
  taxID?: string;
  chooseSection: (section: THomepageOption) => void;
  isAnimation: boolean;
  backToWelcomePage: () => void;
  hasPrincipal: boolean;
  pahStatus: HomepageCardStatus;
  bankAccountStatus: HomepageCardStatus;
  businessDetailsStatus: HomepageCardStatus;
  msaStatus: HomepageCardStatus;
};

const ProfileSetupHome = ({
  chooseSection,
  isAnimation,
  backToWelcomePage,
  pahStatus,
  bankAccountStatus,
  businessDetailsStatus,
  msaStatus,
}: ProfileSetupHomeProps) => {
  const { isLoggedInPAH } = useCheckPAH();
  const { merchantId } = useGetCurrentMerchantId();
  const { isMerchantPortal } = checkPortals();

  useTriggerPathEffect(
    () => {
      isMerchantPortal
        ? NiceModal.show(VERIFY_ACCOUNT_HOLDER_V2, {
            isController: merchant?.legalEntityID
              ? merchant?.isController
              : true,
            merchant,
          })
        : chooseSection("enterprise_verify_identity");
    },
    { delay: 100 },
  );

  const { data: merchant } = useGetMerchantById({
    merchantId: merchantId,
    enabled: !!merchantId,
  });

  const { data: legalEntity } = useFindBusinessProfileById({
    id: merchant?.legalEntityID,
    merchantId,
  });

  const isPahUnderVerification =
    merchant?.owner?.statusName === "ready_for_verification";

  const identityDescription =
    isMerchantPortal && isLoggedInPAH
      ? "We need to confirm your identity"
      : "ID verification step is only available for Primary Account Holder";

  const options = [
    {
      title: "Verify your Identity",
      description: identityDescription,
      status: pahStatus,
      isSuspended:
        merchant?.ownerOfac?.lastCheckStatusName === "confirmed_match",
      isUnderVerification: isPahUnderVerification,
      isLocked: !isLoggedInPAH,
      onClick: isMerchantPortal
        ? () =>
            NiceModal.show(VERIFY_ACCOUNT_HOLDER_V2, {
              isController: merchant?.legalEntityID
                ? merchant?.isController
                : true,
              merchant,
            })
        : () => chooseSection("enterprise_verify_identity"),
      datatestID: "verify_identity",
    },
    {
      title: "Business Details",
      description: "We need some basic details about your business",
      isSuspended: legalEntity?.ofac?.lastCheckStatusName === "confirmed_match",
      status: businessDetailsStatus,
      onClick: () => chooseSection("business_details"),
      datatestID: "business_details",
    },
    {
      title: "Connect a bank account",
      description: "Connect your bank account to get paid easily",
      status: bankAccountStatus,
      onClick: () => chooseSection("bank_account"),
      datatestID: "connect_your_bank_account",
    },
    {
      title: "Sign Agreement",
      description: "Review and agree to our terms to get started",
      status: msaStatus,
      isLocked: false,
      onClick: () => chooseSection("merchant_agreement"),
      datatestID: "sign_agreement",
    },
  ];

  return (
    <Stack
      direction="column"
      flexGrow={1}
      gap={4}
      sx={{
        ...animations,
        ...(isAnimation && {
          animation: "fadeOut 1s ease-out",
        }),
      }}
    >
      <PageTitle />
      <Stack direction="column" gap={1.5}>
        {options.map((option) => (
          <HomepageCard key={option.title} {...option} />
        ))}
      </Stack>
      <Box
        alignSelf="center"
        onClick={backToWelcomePage}
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        <Text
          variant="link"
          lineHeight="16.8px"
          fontWeight="book"
          color={palette.gray[300]}
        >
          I&apos;ll do it later
        </Text>
      </Box>
    </Stack>
  );
};

const animations = {
  "@keyframes fadeOut": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
};

export default ProfileSetupHome;
