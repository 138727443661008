import { useConversationsModal } from "./hooks/useConversationsModal";
import ConversationModal from "./Modal/ConversationsModal_V2";
import ConversationsButton from "./ConversationsButton";
import { MerchantType } from "@customTypes/merchant.preview.types";
import { TConversationTopic, useListTopics } from "./hooks/useListTopics";
import { calculateTotalUnreadSum } from "./utils/functions";
import { HiddenComponent } from "@containers/HiddenComponent";

type ConversationsProps = MerchantType & {
  isLoading: boolean;
  isLoadingChangelog?: boolean;
  handleOpenManageUnderwriting?: () => void;
  shouldShowChangelog?: boolean;
};

const Conversations = ({
  merchant,
  isLoading,
  isLoadingChangelog,
  handleOpenManageUnderwriting,
  shouldShowChangelog,
}: ConversationsProps) => {
  const { isOpen, handleOpenConversationsModal } = useConversationsModal();

  const {
    data,
    isLoading: isTopicDataLoading,
    isFetching,
  } = useListTopics({ merchantId: merchant?.accID });

  const { totalUnread, hasUnreadActivity } = calculateTotalUnreadSum(
    data?.data,
  );

  return (
    <>
      <ConversationsButton
        isLoading={isLoading || isTopicDataLoading}
        isOpen={isOpen}
        handleOpenConversationsModal={handleOpenConversationsModal}
        totalUnread={totalUnread}
        isReplyFromMerchant={hasUnreadActivity}
      />
      <HiddenComponent hidden={!isOpen}>
        <ConversationModal
          merchant={merchant}
          hasUnreadActivity={hasUnreadActivity}
          isLoading={isLoading}
          totalUnread={totalUnread}
          handleOpenManageUnderwriting={handleOpenManageUnderwriting}
          isFetching={isFetching}
          data={data?.data as TConversationTopic[]}
          isLoadingChangelog={isLoadingChangelog}
          shouldShowChangelog={shouldShowChangelog}
        />
      </HiddenComponent>
    </>
  );
};

export default Conversations;
