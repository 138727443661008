import React from "react";
import Box from "@mui/material/Box";
import { Tooltip } from "@common/Tooltip";
import { IconButton } from "@common/IconButton";
import { ButtonProps } from "@common/Button/Button";
import { TooltipProps } from "@common/Tooltip/Tooltip";

interface Props {
  icon: () => React.ReactElement;
  title: string;
  tootipProps?: Omit<TooltipProps, "title" | "children">;
}

const TooltipWrapper = ({
  title,
  ...props
}: {
  title: string;
  tootipProps?: Omit<TooltipProps, "title" | "children">;
} & ButtonProps) => {
  if (props.disabled) return <>{props.children}</>;
  return (
    <Tooltip title={title} {...props.tootipProps}>
      <Box>{props.children}</Box>
    </Tooltip>
  );
};

export function ActionsComponent({
  icon,
  title,
  tootipProps,
  ...props
}: Props & ButtonProps) {
  const { disabled } = props;
  return (
    <TooltipWrapper disabled={disabled} title={title} tootipProps={tootipProps}>
      <IconButton
        size="small"
        style={{
          border: "none",
          boxShadow: "none",
          background: "inherit",
        }}
        data-testid="table-actions-button"
        {...props}
      >
        {icon()}
      </IconButton>
    </TooltipWrapper>
  );
}
