// import * as React from "react";
// import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
// components
import { Text } from "@common/Text";
// localization
// import { useTranslation } from "react-i18next";
// import { namespaces } from "localization/resources/i18n.constants";
// utils
import { parseAmount, toEnFormat } from "@utils/index";

const backgroundGradient = {
  position: "absolute",
  borderRadius: "8px",
  height: 324,
  width: 225,
  top: -10,
  left: "calc(50% - 225px/2)",
  transform: "rotate(90deg)",
  transition: "top 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  background:
    "linear-gradient(104.33deg, #D0B2DD 25.61%, #b3a1bb 54.5%, #E6B96F 82.41%)",
};

const mainContent = {
  gap: "16px",
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  backgroundColor: "rgba(246, 250, 255, 0.8)",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  backdropFilter: "blur(32px)",
  position: "relative",
  borderRadius: "12px",
  width: "100%",

  "& .MuiTypography-root > sup": {
    fontSize: "9px",
    lineHeight: "9px",
  },
};

const data = [
  {
    amount: 13527,
    type: "Processed",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 13527,
    type: "Approved Total",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 0,
    type: "Refund Total",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 0,
    type: "Chargeback Total",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 7,
    type: "Transactions",
    isCurrency: false,
    isPercentage: false,
  },
  {
    amount: 0,
    type: "Approved",
    isCurrency: false,
    isPercentage: true,
  },
  {
    amount: 0,
    type: "Approved",
    isCurrency: false,
    isPercentage: true,
  },
  {
    amount: 0,
    type: "Chargebacks",
    isCurrency: false,
    isPercentage: true,
  },
];

const ViewTransactionBannerMobile = () => {
  const theme = useTheme();
  // const { t } = useTranslation(namespaces.pages.manageMoney);

  return (
    <Box mb={2} width={358} position="relative">
      {/* ------ Background gradient ------- */}
      <Box sx={backgroundGradient} />
      <Box sx={mainContent}>
        <Box display="flex" justifyContent="space-between">
          <Grid container spacing={2}>
            {data.map((item, index) => (
              <Grid item xs={6} sm={6} key={index}>
                <Box display="flex" flexDirection="column">
                  {item.isCurrency && (
                    <Text
                      variant="h6"
                      fontWeight="medium"
                      color={theme.palette.info.main}
                    >
                      {parseAmount(item.amount)}
                    </Text>
                  )}

                  {!item.isCurrency && !item.isPercentage && (
                    <Text
                      variant="h6"
                      fontWeight="medium"
                      color={theme.palette.info.main}
                    >
                      {item.amount}
                    </Text>
                  )}
                  {!item.isCurrency && item.isPercentage && (
                    <Text
                      variant="h6"
                      fontWeight="medium"
                      color={theme.palette.info.main}
                    >
                      {parseAmount(item.amount)}
                    </Text>
                  )}
                  <Text
                    variant="caption"
                    fontWeight="medium"
                    color={theme.palette.neutral[600]}
                  >
                    {item.type}
                  </Text>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewTransactionBannerMobile;
