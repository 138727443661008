import React from "react";
import { useFormContext } from "react-hook-form";
import { getCountryDialCode } from "@utils/country_dial_codes";
import PatternFormat from "react-number-format";
import { TextField } from "@mui/material";

type PhoneInputProps = {
  country?: string;
};

const PhoneInput = ({ country }: PhoneInputProps) => {
  const { setValue, watch } = useFormContext();
  const value = watch("phone");
  const isUS = country === "United States";

  React.useEffect(() => {
    if (isUS) {
      setValue("phone", "");
    } else {
      setValue("phone", `+${getCountryDialCode(country || "")}`);
    }
  }, [country]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue("phone", newValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    const rawValue = value.replace(/\s/g, "");
    const isMaxLength = isUS ? rawValue.length === 12 : rawValue.length === 15;

    if (!navigationKeys.some((item) => item === key) && isMaxLength) {
      event.preventDefault();
    }
  };

  const inputFormat = isUS
    ? value.length > 3
      ? "(###) ### ####"
      : undefined
    : "+##############";

  return (
    <PatternFormat
      label="Phone number"
      placeholder="Enter your phone number"
      fullWidth
      format={inputFormat}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      customInput={TextField}
      type="tel"
    />
  );
};

const navigationKeys = [
  "ArrowDown",
  "ArrowUp",
  "ArrowLeft",
  "ArrowRight",
  "Enter",
  "Escape",
  "Backspace",
  "Delete",
  "Tab",
];

export default PhoneInput;
