import React from "react";
import { ID, Component } from "./types";

class ComponentRegistry {
  private static forms: Record<ID, () => Component> = {} as Record<
    ID,
    () => Component
  >;

  static registerLazy(id: ID, componentLoader: () => Component): void {
    this.forms[id] = componentLoader;
  }

  static show(id: ID, props?: Record<string, any>): React.ReactElement | null {
    const componentLoader = this.forms[id];

    if (componentLoader !== undefined) {
      const Component = componentLoader();
      const element = React.createElement(Component, props);

      return element;
    }

    return null;
  }
}

export default ComponentRegistry;
