import { Box, BoxProps, styled } from "@mui/material";
import { palette } from "@palette";

type Props = {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<TAgreementSnapshotTabs>>;
};

const AgreementSnapshotTabs = ({ selectedTab, setSelectedTab }: Props) => {
  return (
    <Box
      display="flex"
      sx={{ borderBottom: `1px solid ${palette.neutral[20]}` }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab}
          onClick={() => setSelectedTab(tab)}
          selected={selectedTab === tab}
          data-testid={`agreement-snapshot-${tab}-tab`}
        >
          {tab}
        </Tab>
      ))}
    </Box>
  );
};

const Tab = styled(Box)<BoxProps & { selected: boolean }>(({ selected }) => ({
  padding: "4px 16px",
  color: palette.neutral[70],
  fontSize: "14px",
  fontWeight: "350",
  lineHeight: "120%",
  cursor: "pointer",
  ...(selected && {
    fontWeight: "400",
    color: palette.neutral[100],
    borderBottom: `2px solid ${palette.neutral[100]}`,
  }),
}));

const tabs = [
  "Merchant Application",
  "Fees Schedule",
  "Refund Policy",
] as TAgreementSnapshotTabs[];

export type TAgreementSnapshotTabs =
  | "Merchant Application"
  | "Fees Schedule"
  | "Refund Policy";

export default AgreementSnapshotTabs;
