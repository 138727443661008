type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const DoneCheckIcon = ({
  width = 18,
  height = 18,
  stroke = "#13A75A",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={width}
        height={height}
        rx="9"
        fill="#D6FCDA"
        fillOpacity="0.5"
      />
      <path
        d="M7.23214 13.5L3.75 9.65M15 5.25C12.5612 7.84015 10.9838 9.51546 8.88281 11.7469"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoneCheckIcon;
