import React, { memo } from "react";
import { Box, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Figure from "@common/Figure";
import { ITextProps } from "@common/Text/Text";

export type valuePropsType = ITextProps & {
  sup?: boolean;
  color?: string;
  fontSize?: number;
};

export type StatProps = {
  title: string | React.ReactNode;
  value: string | number;
  isAmount?: boolean;
  percent?: boolean;
  valueProps?: valuePropsType;
  isTotal?: boolean;
  ratio?: number | string | undefined;
};

const Stat = ({
  title,
  value,
  isAmount,
  percent,
  valueProps,
  ratio,
}: StatProps) => {
  const { fontSize = 32, color, ...otherValueProps } = valueProps || {};

  return (
    <StyledRoot>
      <Figure
        value={value}
        percent={percent}
        fontSize={fontSize}
        color={color}
        lineHeight={"43.2px"}
        isAmount={isAmount}
        ratio={ratio}
        {...otherValueProps}
      />
      <TitleText variant="h3">
        {title}
        {isAmount && " (USD)"}
        {percent && " (%)"}
      </TitleText>
    </StyledRoot>
  );
};

const StyledRoot = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  overflow: "hidden",
}));

const TitleText = styled(Text)(() => ({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "16px",
  color: palette.gray[300],
  flexGrow: 0,
  whiteSpace: "nowrap",
}));

export default memo(Stat);
