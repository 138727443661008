import { customInstance } from "@services/api";
import { UseQueryOptions, useQuery } from "react-query";
import { isDraftCreated, isPublished } from "../signals";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export const savePaymentForm = (
  data: any,
  merchantId: number,
  productId: number,
) => {
  const apiMethod =
    !isDraftCreated.value && !isPublished.value ? "POST" : "PATCH";
  return customInstance({
    url: `/merchants/${merchantId}/products/${productId}/payment-form`,
    method: apiMethod,
    data,
  });
};

const getBuilderData = (merchantId: number, productId: number) => {
  return customInstance({
    url: `/merchants/${merchantId}/products/${productId}/payment-form`,
    method: "GET",
  });
};

export const useGetBuilderData = (
  merchantId: number,
  productId: number,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
  onSuccess?: any,
  onError?: any,
) => {
  const { isADBEnabled } = useGetFeatureFlagValues();
  const { data, isLoading, isError } = useQuery(
    ["get-builder-data", merchantId, productId],
    async () => {
      const data = await getBuilderData(merchantId, productId);
      return data;
    },
    {
      enabled: isADBEnabled,
      refetchOnWindowFocus: false,
      retry: 1,
      ...options,
      ...onSuccess,
      ...onError,
    },
  );

  return { data, isLoading, isError };
};
