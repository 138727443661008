import React, { useEffect } from "react";
import { palette } from "@palette";
import { useForm } from "react-hook-form";
// mui
import { Box } from "@mui/material";
// components
import { Text } from "@common/Text";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { useFormData } from "../TransferMoneyFormProvider";
import { useCreateVerificationToken } from "@hooks/common/useVerification";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop?: boolean;
};

export const ChooseMethod = ({ setStep, setDisabled, isDesktop }: Props) => {
  const { formData, setFormValues } = useFormData();

  const { handleMethodSubmit, isLoading } = useCreateVerificationToken({
    action: setStep,
  });

  const { handleSubmit } = useForm({
    defaultValues: {
      method: "email",
    },
  });
  const { t } = useTranslation(namespaces.pages.manageMoney);

  const onSubmit = (data: any) => {
    handleMethodSubmit({ method: data.method });
    setFormValues("identityVerification", {
      ...formData.identityVerification,
      method: data.method,
    });
  };

  useEffect(() => {
    setDisabled(isLoading);
  }, [isLoading]);

  return (
    <Box>
      <FadeUpWrapper delay={100}>
        <Text
          fontSize={24}
          textAlign="center"
          color={palette.neutral.black}
          fontWeight="book"
          mb={2}
          sx={{
            fontWeight: 350,
            ...(!isDesktop && { lineHeight: "initial" }),
          }}
        >
          Please verify your identity to send money
        </Text>
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Text
          textAlign="center"
          whiteSpace="pre-line"
          sx={{
            fontWeight: 350,
          }}
          color={palette.neutral[80]}
        >
          {t("YourGivebox_account_is_protected_with_two_factor_authentication")}
        </Text>
      </FadeUpWrapper>
      <form id="transfer-money-form" onSubmit={handleSubmit(onSubmit)}></form>
    </Box>
  );
};
