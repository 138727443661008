import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";

const useUserReducer = () => {
  const user = useAppSelector(selectUser);

  return {
    ...user,
  };
};

export default useUserReducer;
