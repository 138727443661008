import { StyledIconButton } from "@common/IconButton";
import { Box } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ClockCounterwiseIcon } from "@assets/icons/RebrandedIcons";

type Props = {
  isLoading?: boolean;
  handleOpenChangelogModal: (open?: boolean | undefined) => void;
  isOpen?: boolean;
  isOpenConversations?: boolean;
};

const ChangelogButton = ({
  isLoading = false,
  handleOpenChangelogModal,
  isOpen = false,
  isOpenConversations,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const styles = useStyles({
    isOpen: Boolean(isOpen || isOpenConversations),
    isMobileView,
  });

  return (
    <StyledIconButton
      id="changelogButton"
      className={styles.buttonStyles}
      onClick={() => handleOpenChangelogModal(!isMobileView ? true : !isOpen)}
      disabled={isLoading}
      sx={{
        position: "relative",
        borderRadius: "50%",
      }}
      data-testid="changelog-button"
    >
      <Box className={styles.containerStyles}>
        <ClockCounterwiseIcon opacity="0" />
      </Box>
    </StyledIconButton>
  );
};

export default ChangelogButton;

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyles: ({
    isMobileView,
    isOpen,
  }: {
    isMobileView: boolean;
    isOpen: boolean;
  }) => {
    return {
      position: isMobileView ? (isOpen ? "relative" : "fixed") : "absolute",
      bottom: isMobileView ? (isOpen ? "initial" : "90px") : "90px",
      left: isMobileView ? (isOpen ? "0px" : "initial") : "-70px",
      right: isMobileView ? "20px" : "70px",
      ...(isMobileView && { top: isOpen ? "0px" : "initial" }),
      boxShadow: isMobileView
        ? "0px 2.625px 13.125px 0px rgba(2, 2, 2, 0.15)"
        : "initial",
      backgroundColor: theme.palette.neutral.white,
    };
  },

  containerStyles: {
    borderRadius: "50%",
    padding: "10px 10px 4px 10px",
    backgroundColor: theme.palette.neutral.white,
  },
}));
