import React from "react";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { Stack, StackProps } from "@mui/material";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import { isMobile } from "@utils/index";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NoResultsState from "@common/EmptyState/NoResultsState";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";

type MobileListProps<T> = {
  page: number;
  totalRows: number;
  setPage: (...arg: any) => void;
  allRows: T[];
  loadingRef: React.MutableRefObject<boolean>;
  isLoading?: boolean;
  CardComponent: any;
  CardComponentProps?: { [key: string]: any };
  redirectBaseHREF: string;
  containerProps?: StackProps;
  rowRenderingKey?: keyof T;
  redirectKey?: keyof T;
  LoadingTableSkeleton?: () => JSX.Element;
  queryKey?: string;
};

const MobileList = <T extends object>({
  CardComponent,
  page,
  totalRows,
  setPage,
  allRows,
  loadingRef,
  isLoading,
  containerProps,
  redirectBaseHREF,
  rowRenderingKey,
  redirectKey,
  CardComponentProps,
  LoadingTableSkeleton,
  queryKey,
}: MobileListProps<T>) => {
  const targetRef = useTargetRef({ page, totalRows, setPage, loadingRef });
  const isCustomLoader = !!LoadingTableSkeleton;
  const searchQuery = useAppSelector((state) =>
    queryKey ? selectQueryString(state, queryKey) : undefined,
  );

  if (!isLoading && !totalRows)
    return <NoResultsState searchQuery={searchQuery} />;

  return (
    <>
      {isLoading && !loadingRef.current ? (
        <>
          {isCustomLoader ? (
            Array(7)
              .fill(null)
              .map((c, idx) => <LoadingTableSkeleton key={idx} />)
          ) : (
            <LoadingSpinner />
          )}{" "}
        </>
      ) : (
        <Stack direction="column" gap={2} mt={2} {...containerProps}>
          {allRows.map((data: T, index: number) => {
            const key: any = rowRenderingKey ? data[rowRenderingKey] : index;
            const pathEnd = redirectKey ? "/" + data[redirectKey] : "";
            const href = redirectBaseHREF + pathEnd;

            return (
              <CardComponent
                key={index}
                data={data}
                href={href}
                index={index}
                {...CardComponentProps}
              />
            );
          })}
        </Stack>
      )}

      {allRows?.length && isMobile ? (
        <div
          ref={targetRef}
          style={{
            width: 1,
            height: 1,
            paddingBottom: 5,
            visibility: "hidden",
          }}
        ></div>
      ) : null}
    </>
  );
};

export default MobileList;
