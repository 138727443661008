import { isFunction } from "lodash";
import DialogVariant, { DialogVariantProps } from "./variants/ModalVariant";
import SidePanelVariant, {
  SidePanelVariantProps,
} from "./variants/SidePanelVariant";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import PopupVariant, { PopupVariantProps } from "./variants/PopupVariant";

export type TModalVariants = "dialog" | "popup" | "sidepanel";

type ModalPropsByVariant = {
  dialog: DialogVariantProps;
  popup: PopupVariantProps;
  sidepanel: SidePanelVariantProps;
};

interface IModalFactory<T extends TModalVariants> {
  renderMobile?: boolean | ((isMobileView: boolean) => boolean);
  variant: T;
  modalProps: Omit<ModalPropsByVariant[T], "children">;
  children: React.ReactNode;
}

const ModalFactory = <T extends TModalVariants>({
  renderMobile = (isMobile) => isMobile,
  variant,
  modalProps,
  children,
}: IModalFactory<T>) => {
  const { isMobileView } = useCustomTheme();
  const showMobileDrawer = isFunction(renderMobile)
    ? renderMobile(isMobileView)
    : renderMobile;

  const ModalComponent = Modal[variant];

  let refinedModalProps: any;

  switch (variant) {
    case "dialog":
      refinedModalProps = modalProps as DialogVariantProps;
      break;
    case "popup":
      refinedModalProps = modalProps as PopupVariantProps;
      break;
    case "sidepanel":
      refinedModalProps = modalProps as SidePanelVariantProps;
      break;
    default:
      throw new Error("Invalid variant");
  }
  return (
    <ModalComponent {...refinedModalProps} showMobileView={showMobileDrawer}>
      {children}
    </ModalComponent>
  );
};

const Modal = {
  dialog: DialogVariant,
  popup: PopupVariant,
  sidepanel: SidePanelVariant,
};

export default ModalFactory;
