import { DefaultValuesType } from "@components/Merchants/MerchantPreview/RiskProfile/components/Transactions/modal/useTransactionFilter";
import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DynamicFilterState = {
  filters: Record<string, DefaultValuesType>;
};

interface SetFilterByKeyPayload {
  filterKey: string;
  params: DefaultValuesType;
}

const initialState: DynamicFilterState = {
  filters: {},
};

const dynamicFilterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterByKey: (
      state: DynamicFilterState,
      action: PayloadAction<SetFilterByKeyPayload>,
    ) => {
      const { filterKey, params } = action.payload;

      state.filters[filterKey] = params;
    },

    resetFilterByKey: (
      state: DynamicFilterState,
      action: PayloadAction<{ filterKey: string }>,
    ) => {
      const { filterKey } = action.payload;
      if (state.filters[filterKey]) {
        state.filters[filterKey] = {} as DefaultValuesType;
      }
    },
    resetAllFilters: (state) => {
      state.filters = {};
    },
  },
});

export const { setFilterByKey, resetFilterByKey, resetAllFilters } =
  dynamicFilterSlice.actions;

export const selectFiltersObject = (state: RootState, filterKey: string) =>
  state.filter.filters?.[filterKey] || initialState?.filters || {};

export default dynamicFilterSlice.reducer;
