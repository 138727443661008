import {
  useAccessPlaidLinkToken,
  useCreateBankAccountWithPlaid,
  useRequestPlaidToken,
} from "@services/api/products/manage-money";
import { useRef } from "react";
import { PlaidHandler } from "react-plaid-link";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { checkPortals } from "utils/routing";

export function usePlaidService() {
  const { isEnterprisePortal } = checkPortals();
  const canCreateBA = useAccessControl({
    resource: composePermission(
      isEnterprisePortal ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.BANK_ACCOUNT,
    ),
    operation: OPERATIONS.CREATE,
  });

  const isEnabledPlaid = useAccessControl({
    resource: RESOURCE_BASE.PLAID,
    operation: OPERATIONS.READ,
    withPortal: true,
  });

  const isEnableLinkPlaid = useAccessControl({
    resource: RESOURCE_BASE.PLAID,
    operation: OPERATIONS.LINK,
    withPortal: true,
  });

  const { refetch } = useRequestPlaidToken(
    canCreateBA && isEnabledPlaid && isEnableLinkPlaid,
  );

  useAccessPlaidLinkToken();

  /*
    1. get link token
    2.the iframe Plaid starts
    3. Onsuccess public token plus account it to post bank-accounts
  */

  const Plaid = useRef<PlaidHandler | undefined>();

  const mutation = useCreateBankAccountWithPlaid();
  const { mutate: mutateBakAccountsList, data } = mutation;

  return {
    isCreatingAccount: mutation.isLoading,
    isEnableLinkPlaid,
    open: () => {
      refetch()
        .then((res) => {
          Plaid.current = window.Plaid.create({
            token: (res.data as any).link_token,
            onSuccess: async (publicToken, metadata) => {
              mutateBakAccountsList({
                plaid: {
                  publicToken: publicToken,
                  accountID: metadata.accounts[0].id,
                },
              });
            },
            // onEvent: (eventName, metadata) => {},
            // onExit: (error, metadata) => {},
          });

          if (Plaid.current) {
            Plaid.current?.open();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    data,
  };
}
