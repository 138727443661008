import BriefCaseOutlinedIcon from "@assets/icons/RebrandedIcons/BriefCaseOutlinedIcon";
import HeartIcon from "@assets/icons/RebrandedIcons/HeartIcon";
import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import React from "react";
import { organizationType, useFormData } from "../SignupFormProvider";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import SignupFormContainer from "../SignupFormContainer";
import { yupResolver } from "@hookform/resolvers/yup";

function BusinessType({ setIsStart }: { setIsStart: () => void }) {
  const { setFormValues } = useFormData();
  const onClick = (type?: organizationType) => {
    setIsStart();
    setFormValues("organizationType", type);
  };

  const CardData = [
    {
      title: "I manage a merchant portfolio.",
      ICON: <BriefCaseOutlinedIcon />,
      onClick: () => onClick("manage_submerchants"),
    },
    {
      title: "I accept payments or donations",
      ICON: <HeartIcon />,
      onClick: () => onClick("accept_payments"),
    },
  ];
  return (
    <SignupFormContainer
      title="How do you classify your business?"
      component="form"
    >
      <Box
        width="100%"
        gap="12px"
        flexDirection={{ xs: "column", md: "row" }}
        display="flex"
      >
        {CardData?.map((data) => (
          <Card {...data} key={data?.title} />
        ))}
      </Box>
    </SignupFormContainer>
  );
}

export default BusinessType;

const Card = ({
  ICON,
  title,
  onClick,
}: {
  ICON: JSX.Element;
  title: string;
  onClick: () => void;
}) => {
  return (
    <CardContainer onClick={onClick}>
      <Box mb="13px" justifyContent="center" alignItems="center" display="flex">
        {ICON}
      </Box>
      <Text
        textAlign="center"
        fontWeight="book"
        color="#575353"
        fontSize="14px"
      >
        {title}
      </Text>
    </CardContainer>
  );
};

const CardContainer = styled(Box)(({ theme }) => ({
  borderRadius: "12px",
  border: "2px solid  #ECECE9",
  background: ` #F8F8F6`,
  padding: "16px",
  flex: 1,
  cursor: "pointer",
  width: "270px",
  minWidth: "auto",
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
  },
}));
