import { Box } from "@mui/material";
import { Skeleton, Stack } from "@mui/material";
import { palette } from "@palette";
import React from "react";

const MainDataSkeleton = () => {
  return (
    <Stack
      data-testid="skeleton-container"
      direction="column"
      gap="32px"
      alignItems="center"
      padding="8px 55px"
    >
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center">
          {Array.from({ length: 2 }, (_, i) => (
            <Tag key={i} />
          ))}
        </Stack>
        <Skeleton
          width={150}
          height={17}
          sx={{ borderRadius: "50px", position: "absolute", right: "55px" }}
        />
      </Stack>
      <Skeleton
        variant="rounded"
        width="calc(100% - 24px)"
        height="362px"
        sx={{ borderRadius: "8px" }}
      />
      <Stack
        direction="column"
        alignItems="stretch"
        gap="24px"
        alignSelf="stretch"
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Skeleton width={100} height={50} sx={{ borderRadius: "8px" }} />
        </Box>
        <TableHeader />
        <GroupedRows>
          <TableRow />
          <TableRow dimmed />
        </GroupedRows>
        <GroupedRows>
          <Skeleton
            width={115}
            height={21}
            sx={{ alignSelf: "center", borderRadius: "17.5px" }}
          />
          {Array.from({ length: 4 }, (_, i) => (
            <TableRow key={i} />
          ))}
        </GroupedRows>
      </Stack>
    </Stack>
  );
};

const GroupedRows = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack gap="12px" direction="column" alignItems="stretch">
      {children}
    </Stack>
  );
};

const TableCell = ({
  width,
  type,
}: {
  width: string | number;
  type?: "circle" | "rect";
}) => {
  const variants = {
    circle: "circular",
    rect: "rectangular",
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="stretch"
      gap="12px"
      width={width}
    >
      {type && <Skeleton variant={variants[type] as any} width="18px" />}
      <Skeleton sx={{ flexGrow: 1, borderRadius: "17.5px" }} />
    </Stack>
  );
};

const TableRow = ({ dimmed = false }: { dimmed?: boolean }) => {
  const sizes = ["5%", "5%", "7%", "7%", "7%", "10%", "10%"];
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: dimmed ? "#FBFBFB" : palette.neutral.white,
        borderRadius: "4px",
        padding: "19px 16px 24px",
      }}
    >
      {sizes.map((size, i) => (
        <TableCell
          width={size}
          key={i}
          type={i === 2 ? "circle" : i === 3 ? "rect" : undefined}
        />
      ))}
    </Stack>
  );
};

const TableHeader = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      padding="0 16px"
    >
      <Stack direction="row" alignItems="center" gap="33px">
        <Skeleton
          variant="rounded"
          width="70px"
          sx={{ borderRadius: "50px" }}
        />
        <Skeleton
          variant="rounded"
          width="60px"
          sx={{ borderRadius: "50px" }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" gap="39px">
        <Skeleton
          variant="rounded"
          width="50px"
          sx={{ borderRadius: "50px" }}
        />
        <Skeleton
          variant="rounded"
          width="60px"
          sx={{ borderRadius: "50px" }}
        />
      </Stack>
    </Stack>
  );
};

const Tag = () => (
  <Box sx={{ padding: "3px 40px" }}>
    <Skeleton width={150} height={17} sx={{ borderRadius: "50px" }} />
  </Box>
);

export default MainDataSkeleton;
