import { Text } from "@common/Text";
import { getCardBrandIcon } from "@sections/Checkout/Payment/inputs/CardNumberInput";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { parseAmount } from "@utils/index";

export type CardListItemProps = {
  cardBrand: string;
  last4: string;
  charged: number;
  isDebit: boolean;
};

const CardListItem = ({
  cardBrand,
  last4,
  charged,
  isDebit,
}: CardListItemProps) => {
  const CardBrand = getCardBrandIcon(cardBrand.toUpperCase());
  return (
    <Wrapper>
      {CardBrand}
      <Text color={palette.black[100]} fontWeight="book" lineHeight="16.8px">
        {`•••• ${last4}`}
      </Text>
      <Text
        color="#106D3D"
        lineHeight="16.8px"
        display="inline-flex"
        gap="4px"
        alignItems="center"
      >
        {parseAmount(charged)}
        <CurrencyTag>USD</CurrencyTag>
      </Text>
      <Text
        color={palette.gray[300]}
        lineHeight="16.8px"
        flexGrow={1}
        align="right"
      >
        {isDebit ? "Debit" : "Credit"} Card
      </Text>
    </Wrapper>
  );
};

const CurrencyTag = styled((props: BoxProps) => (
  <Box component="span" {...props} />
))(() => ({
  padding: "2px 8px",
  borderRadius: "100px",
  background: "rgba(19, 167, 90, 0.10)",
  color: "#106D3D",
}));

const Wrapper = styled(Stack)(() => ({
  padding: "24px 16px",
  borderRadius: "4px",
  background: "#FBFBFB",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
  justifyContent: "stretch",
}));

export default CardListItem;
