import { customInstance } from "@services/api";
import { useQuery } from "react-query";


export const useGetChallengesStats = ({merchantId}: {merchantId: number}) => {
    // /merchants/${merchant_id}/underwriting-challenges/stats

    return useQuery({
        queryKey: ["get-challenges-stats", merchantId],
        queryFn: async ({ queryKey }) =>
          await customInstance({
            url: `/merchants/${merchantId}/underwriting-challenges/stats`,
            method: "GET",
          }),
      });

}