import React from "react";
import { getDefaults } from "./utils";

type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const TickIcon = ({
  size,
  width = 15,
  height = 11,
  stroke,
  fill,
}: Props) => {
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      width={width || "15"}
      height={height || "11"}
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.03572 9.99999L1.16667 5.72222M13.6667 0.833328C10.9569 3.71128 9.20425 5.57272 6.86979 8.05208"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TickIcon;
