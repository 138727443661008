import { Box, Divider, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import UploadButton from "./UploadButton";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type TAvatarImagePreview = {
  image: string | undefined;
  rounded: boolean;
  subTitle: string;
  hasPickedAFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete?: () => void;
  canUpload?: boolean;
  canDelete?: boolean;
};

const AvatarImagePreview = ({
  image,
  rounded,
  subTitle,
  hasPickedAFile,
  handleDelete,
  canUpload,
  canDelete,
}: TAvatarImagePreview) => {
  const { isMobileView } = useCustomTheme();
  return (
    <>
      {!isMobileView && (
        <Stack>
          <ImageContainer>
            <Image src={image} isRounded={rounded} alt="image" />
          </ImageContainer>
          <CustomTextName>{subTitle}</CustomTextName>
          <Divider />
        </Stack>
      )}

      <ButtonContainers isMobile={isMobileView}>
        <RemoveButton
          background="tertiary"
          disabled={!canDelete || !handleDelete}
          onClick={handleDelete}
        >
          Remove image
        </RemoveButton>
        <UploadButton
          onChange={hasPickedAFile}
          disabled={!canUpload || !hasPickedAFile}
        />
      </ButtonContainers>
    </>
  );
};

interface ImageProps {
  src?: string;
  alt?: string;
  isRounded?: boolean;
}

const Image = styled("img", {
  shouldForwardProp: (prop) => prop !== "isRounded",
})<ImageProps>(({ isRounded }) => ({
  height: "124px",
  width: "124px",
  display: "block",
  borderRadius: isRounded ? "50%" : "8px",
}));

const CustomTextName = styled(Text)(() => ({
  fontWeight: "book",
  fontSize: "18px",
  color: palette.neutral[80],
  marginBottom: "24px",
  textAlign: "center",
  marginTop: "16px",
}));

const ImageContainer = styled(Box)(() => ({
  marginTop: "44px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ButtonContainers = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean }) =>
  props.isMobile
    ? {
        display: "flex",
        alignItems: "center",
        flexDirection: "column-reverse",
        gap: 20,
        marginTop: 20,
      }
    : {
        marginBottom: 0,
        marginTop: "16px",
        gap: "16px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-evenly",
      },
);

const RemoveButton = styled(Button)(() => ({
  fontSize: "18px",
  color: palette.filled.red,
  "&:hover": {
    color: palette.tag.error.text,
    textDecoration: "none",
  },
}));

export default AvatarImagePreview;
