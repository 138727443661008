import { useCallback, useEffect, useRef, useState } from "react";
import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ModalSectionTitle, { ModalSectionTitleProps } from "./atoms";
import {
  RecurrenceCard,
  PaymentStepSection,
  AmountItem,
  AddAmountButton,
} from "./recurrence.atoms";
import {
  DndContext,
  closestCorners,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { PlusIcon } from "@assets/icons/RebrandedIcons";
import { palette } from "@palette";
import {
  DELETE_CONFIRMATION_MODAL,
  MINIBUILDER_CREATE_AMOUNT,
  MINIBUILDER_EDIT_ANY_AMOUNT,
} from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { AmountType } from "./FundraisersMinibuilder/types";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

type FundraisersAboutProps = ModalSectionTitleProps & {
  isEdit?: boolean;
  campaignType?: string;
};

const recurrenceCards = [
  {
    label: "One-time",
    key: "one_time",
  },
  {
    label: "Monthly",
    key: "monthly",
  },
  {
    label: "Quarterly",
    key: "quarterly",
  },
  {
    label: "Yearly",
    key: "yearly",
  },
];

const FundraisersPayment = ({
  title,
  titleSize = "large",
  isEdit = false,
  campaignType,
}: FundraisersAboutProps) => {
  const { watch } = useFormContext();
  const values = watch();

  const amountsList = values.payment_set_up.amountsList;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [disabledCard, setDisabledCard] = useState("");

  // Disable last active recurrence
  useEffect(() => {
    const { default: _, ...rest } = values.payment_set_up.payment_types;

    const recurringKeys = Object.keys(rest);
    const recurring = Object.values(rest).filter((v) => v === true);

    if (recurring.length === 1) {
      const activeIndex = Object.values(rest).findIndex((v) => v === true);
      setDisabledCard(recurringKeys[activeIndex]);
    } else {
      setDisabledCard("");
    }
  }, [
    values.payment_set_up.payment_types.monthly,
    values.payment_set_up.payment_types.one_time,
    values.payment_set_up.payment_types.quarterly,
    values.payment_set_up.payment_types.yearly,
  ]);

  const renderRecurrenceCards = ({
    label,
    key,
  }: (typeof recurrenceCards)[0]) => {
    return (
      <RecurrenceCard
        key={key}
        keyName={key}
        name={`payment_set_up.payment_types.${key}`}
        label={label}
        isDisabled={disabledCard === key}
        isDefault={values.payment_set_up.payment_types.default === key}
      />
    );
  };

  return (
    <Stack direction="column" gap={isEdit ? 2 : 3} flexGrow={1}>
      <FadeUpWrapper delay={100}>
        <ModalSectionTitle title={title} titleSize={titleSize} />
      </FadeUpWrapper>
      {(!campaignType || campaignType !== "standard") && (
        <FadeUpWrapper delay={200}>
          <PaymentStepSection
            title="Recurrence"
            description="Choose the payment types available to your customers"
          >
            <Stack direction={isDesktop ? "row" : "column"} gap="4px">
              <Box
                gap="4px"
                display={isDesktop ? "inline-flex" : "flex"}
                flexGrow={1}
              >
                {recurrenceCards.slice(0, 2).map(renderRecurrenceCards)}
              </Box>
              <Box
                gap="4px"
                display={isDesktop ? "inline-flex" : "flex"}
                flexGrow={1}
              >
                {recurrenceCards.slice(2, 4).map(renderRecurrenceCards)}
              </Box>
            </Stack>
          </PaymentStepSection>
        </FadeUpWrapper>
      )}
      <FadeUpWrapper delay={300}>
        <PaymentStepSection
          title="Payment amounts"
          description={
            "Provide preset amounts for customers to pick. Users can also enter a custom amount."
          }
        >
          <AmountList list={amountsList} />
        </PaymentStepSection>
      </FadeUpWrapper>
    </Stack>
  );
};

const AmountList = ({ list }: { list: AmountType[] }) => {
  const { setValue } = useFormContext();

  const [activeId, setActiveId] = useState<any>(null);
  const [lastActive, setLastActive] = useState<string>("");

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  useEffect(() => {
    const activeList = list.filter((v: any) => v.active);

    if (activeList.length === 1) {
      setLastActive(activeList[0].id);
    } else {
      setLastActive("");
    }
  }, [list]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = list.findIndex((x: any) => x.id === active.id);
      const newIndex = list.findIndex((x: any) => x.id === over?.id);

      const newList = arrayMove(list, oldIndex, newIndex);

      setValue("payment_set_up.amountsList", newList, { shouldDirty: true });
    }
    setActiveId(null);
  };

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  // changes the amount item status to active
  const handleShowAmountItem = (id: string) => {
    const newList = list.map((element: AmountType) =>
      element.id === id
        ? Object.assign({}, element, { active: !element.active })
        : element,
    );
    setValue("payment_set_up.amountsList", newList, { shouldDirty: true });
  };

  const onCloseModal = (item?: AmountType) => {
    if (!item) return;

    const amount = list.findIndex((element) => element.id === item.id);
    let newList = list;
    if (amount !== -1) {
      newList = list.map((element: AmountType) =>
        element.id === item.id ? item : element,
      );
    } else {
      newList.push(item);
    }
    setValue("payment_set_up.amountsList", newList, { shouldDirty: true });
  };

  const handleRemoveAmountItem = (id: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "amount",
      deleteHandler: () => {
        const newList = list.filter((item) => item.id !== id);
        setValue("payment_set_up.amountsList", newList, { shouldDirty: true });
      },
    });
  };

  const hasUniqueTitle = (title: string, id?: string) => {
    const isNotUnique = list.some(
      (amount) => id !== amount.id && amount.title === title,
    );
    return !isNotUnique;
  };

  const createAmount = () => {
    NiceModal.show(MINIBUILDER_CREATE_AMOUNT, {
      onClose: onCloseModal,
      onDelete: handleRemoveAmountItem,
      hasUniqueTitle,
    });
  };

  const editAmount = (id: string, isLastActive: boolean) => {
    const amount = list.find((element) => element.id === id);
    if (!amount) return;

    if (amount.isDefault) {
      NiceModal.show(MINIBUILDER_EDIT_ANY_AMOUNT, {
        onClose: onCloseModal,
        item: amount,
      });
    } else {
      NiceModal.show(MINIBUILDER_CREATE_AMOUNT, {
        onClose: onCloseModal,
        item: amount,
        ...(!isLastActive && {
          onDelete: handleRemoveAmountItem,
        }),
        hasUniqueTitle,
      });
    }
  };

  const isCreateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.CREATE,
  });

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={list} strategy={verticalListSortingStrategy}>
          <Stack gap={1}>
            {list.map((item: AmountType) => {
              const { id, active } = item;
              const isDisabled = list.length === 1 || lastActive === id;

              return (
                <AmountItem
                  key={id}
                  amountItem={item}
                  showAmountItem={handleShowAmountItem}
                  isDisabled={isDisabled}
                  checked={list.length === 1 && !active ? true : active}
                  isDraggable={list.length > 1}
                  onClick={() => editAmount(id, isDisabled)}
                />
              );
            })}
            <CustomToolTip
              showToolTip={!isCreateAllowed}
              message={CREATE_DENY_MESSAGE}
            >
              <AddAmountButton
                onClick={createAmount}
                disabled={!isCreateAllowed}
                data-testid="minibuilder-add-amount"
              >
                Add amount{" "}
                <PlusIcon stroke={palette.gray[300]} height={20} width={20} />
              </AddAmountButton>
            </CustomToolTip>
          </Stack>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default FundraisersPayment;
