import * as Yup from "yup";
import { SubscriptionType } from "./types";

export const FundraiserModalSchema = Yup.object().shape({
  about: Yup.object().shape({
    title: Yup.string().trim().required("This field is required"),
    description: Yup.string(),
  }),
  style: Yup.object().shape({
    image: Yup.mixed().nullable(),
    useAsBackground: Yup.boolean(),
  }),
  payment_set_up: Yup.object().shape({
    subscriptionsList: Yup.array<SubscriptionType>().min(1),
  }),
  configuration: Yup.object().shape({
    customer_pays_fees: Yup.boolean(),
    browse_more: Yup.boolean(),
    max_subscriptions: Yup.string(),
  }),
});

export const FundraiserModalDefaults = {
  about: {
    title: "",
    description: "",
  },
  style: {
    image: null,
    useAsBackground: false,
  },
  payment_set_up: {
    subscriptionsList: [],
  },
  configuration: {
    customer_pays_fees: false,
    browse_more: false,
    max_subscriptions: "5",
  },
};
