import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const SortUpIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill={`${fill}`}
      height={`${height}`}
      viewBox="0 0 9 6"
      width={`${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.39451 0.359815L8.25947 3.79777C8.80225 4.44909 8.33909 5.43795 7.49125 5.43795L1.76134 5.43795C0.913498 5.43795 0.450341 4.44909 0.993113 3.79777L3.85807 0.359816C4.25787 -0.119938 4.99472 -0.119939 5.39451 0.359815Z"
        fill={`${fill}`}
        stroke={`${stroke}`}
      ></path>
    </svg>
  );
};
export default SortUpIcon;
