import { ArrowDownChevron } from "@assets/icons";
import { RHFSelect } from "@common/Select";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import { useGetLegalEntitiesTypes } from "@services/api/merchants";

type BusinessTypeSelectProps = {
  name: string;
  label: string;
  disabled?: boolean;
  hasArrowDownIcon?: boolean;
};

const BusinessTypeSelect = ({
  name,
  label,
  disabled,
  hasArrowDownIcon = false,
}: BusinessTypeSelectProps) => {
  const { data, isFetching, isError } = useGetLegalEntitiesTypes();
  const hasNotLETypesPermission = useAppSelector(
    (state) => state.app.permissions?.get_legal_entities_types,
  );

  return (
    <RHFSelect
      isFetchindData={isFetching}
      isError={isError}
      errorsMessage={
        hasNotLETypesPermission ? "Legal Entities types hidden" : undefined
      }
      name={name}
      label={label}
      disabled={disabled}
      DropIcon={
        hasArrowDownIcon ? (
          <ArrowDownChevron width={16} height={9} color={palette.gray[300]} />
        ) : undefined
      }
      options={
        data?.data?.map((item) => ({
          value: item.name,
          label: businessTypes[item.name as BusinessUnionTypes] as string,
        })) || []
      }
    />
  );
};

export const businessTypes = {
  general_partnership: "General Partnership",
  individual_sole_proprietorship: "Sole Proprietor",
  corporation: "Corporation",
  limited_liability_company: "LLC",
  limited_partnership: "Limited Partnership",
  partnership: "Partnership",
  tax_exempt_organization: "Tax Exempt Organization",
  government_agency: "Government Agency",
};

export type BusinessUnionTypes = keyof typeof businessTypes;

export default BusinessTypeSelect;
