import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SECTIONS } from "@components/Merchants/MerchantPreview/hooks/useSectionFocusView";

export interface MerchantPreviewState {
  sectionFocus: {
    section: SECTIONS | "";
    field?: string;
  };
  openingOptions: {
    underwriter?: boolean;
    riskprofile?: boolean;
  };
}

const initialState: MerchantPreviewState = {
  sectionFocus: {
    section: "",
    field: "",
  },
  openingOptions: {
    underwriter: false,
    riskprofile: false,
  },
};

const merchantPreviewSlice = createSlice({
  name: "merchantPreview",
  initialState,
  reducers: {
    setMerchantSectionFocus: (
      state: MerchantPreviewState,
      action: PayloadAction<MerchantPreviewState["sectionFocus"]>,
    ) => {
      state.sectionFocus = { ...state.sectionFocus, ...action.payload };
    },

    resetMerchantSectionFocus: (state: MerchantPreviewState) => {
      state.sectionFocus = initialState.sectionFocus;
    },

    setOpeningOptions: (
      state: MerchantPreviewState,
      action: PayloadAction<MerchantPreviewState["openingOptions"]>,
    ) => {
      state.openingOptions = { ...state.openingOptions, ...action.payload };
    },
  },
});

export const {
  setMerchantSectionFocus,
  resetMerchantSectionFocus,
  setOpeningOptions,
} = merchantPreviewSlice.actions;

export const selectMerchantPreview = (state: RootState) =>
  state.merchantPreview;

export default merchantPreviewSlice.reducer;
