import { useEffect, useState } from "react";

export const ROWS_PER_PAGE = 20;

export const usePagination = (dataLen: number, filter: string) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  return { page, setPage };
};
