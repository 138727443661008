import { Text } from "@common/Text";
import { Box, Divider, Stack } from "@mui/material";
import { styled } from "@mui/styles";
import { palette } from "@palette";
import { useRiskActivity } from "../../RiskProfile/hooks/useRiskActivity";
import { Tooltip } from "@common/Tooltip";
import React from "react";
import { TRiskActivity } from "../../RiskProfile/types";
import { Pulse } from "@phosphor-icons/react";

type Props = {
  profileId: number;
  merchantId: number;
  riskLabel: string;
};

const RiskActivity = ({ profileId, merchantId, riskLabel }: Props) => {
  const { data } = useRiskActivity(profileId, merchantId);
  const hasRiskActivity = data.length !== 0;

  const activityTitle = hasRiskActivity ? data[0]?.merchantFactorName : "None";
  const createdAt = hasRiskActivity ? data[0]?.createdAt : "";

  return (
    <Tooltip
      data-testid="tooltip-risk-level"
      title={<RiskActivityHoverPopover data={data[0]} riskLabel={riskLabel} />}
      bgColor={palette.neutral.white}
      disableFocusListener={!hasRiskActivity}
      disableHoverListener={!hasRiskActivity}
      disableTouchListener={!hasRiskActivity}
      maxWidth="355px"
    >
      <RiskActivityContainer>
        <Pulse />
        <Stack flexGrow={1} gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <Text color={palette.neutral[80]}>Risk Activity</Text>
            <Text color={palette.neutral[70]}>{createdAt}</Text>
          </Stack>
          <Text color={palette.neutral[80]}>{activityTitle}</Text>
        </Stack>
      </RiskActivityContainer>
    </Tooltip>
  );
};

const RiskActivityContainer = styled(Stack)(() => ({
  padding: "12px 16px",
  borderRadius: "16px",
  border: `1px solid ${palette.neutral[20]}`,
  backgroundColor: palette.background.newWhite,
  flexDirection: "row",
  gap: "8px",
  width: "100%",
  cursor: "pointer",
}));

export default RiskActivity;

const RiskActivityHoverPopover = ({
  data,
  riskLabel,
}: {
  data: TRiskActivity;
  riskLabel: string;
}) => {
  const items = [
    {
      label: data?.merchantFactorName,
      value: data?.merchantFactorDescription,
      showBorder: true,
      hidden: riskLabel === "low",
    },
    {
      label: "Last Escalation Date",
      value: data?.createdAt,
      showBorder: false,
      hidden: false,
    },
  ];
  return (
    <ItemsContainer data-testid="risk-activity-tooltip-popover">
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => (
          <React.Fragment key={index}>
            <ItemBox>
              <Header>{item.label}</Header>
              <Header sx={{ color: `${palette.neutral[70]} !important` }}>
                {item.value}
              </Header>
            </ItemBox>
            {item.showBorder && <Divider />}
          </React.Fragment>
        ))}
    </ItemsContainer>
  );
};

const Header = styled(Text)(() => ({
  color: palette.neutral[100],
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
  textAlign: "start",
}));

const ItemBox = styled(Box)(() => ({
  width: "100%",
  gap: "4px",
  paddingBottom: "12px",
  paddingTop: "12px",
}));

const ItemsContainer = styled(Box)(() => ({
  width: "355px",
  padding: "0px 16px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
}));
