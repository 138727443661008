import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "./components/pageContainer";
import { HandShakeIcon, NewSigninIcon } from "@assets/icons";
import EmailConfirmedBackground from "@assets/images/confirmed-email-page.png";
import EmailExpiredBackground from "@assets/images/expired-email-page.png";
import EmailConfirmedIcon from "@assets/images/confirmed-image-icon.png";
import EmailExpiredIcon from "@assets/images/email-expired-icon.png";

import ActionButton from "./components/ActionButton";
import { useValidateAccount } from "@services/api/onboarding/user";
import { Box, CircularProgress } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

function EmailConfirmation() {
  const { isMediumSmallView } = useCustomTheme();
  const { token } = useParams();

  const {
    mutateAsync: validateAccount,
    isLoading,
    isSuccess,
  } = useValidateAccount();

  useEffect(() => {
    const handleValidation = async () => {
      try {
        await validateAccount(token as string);
      } catch (error) {
        return;
      }
    };
    handleValidation();
  }, []);

  if (isLoading)
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (isSuccess)
    return (
      <PageContainer
        title={"Your account has been successfully validated!"}
        imgSrc={EmailConfirmedIcon}
        imageUri={EmailConfirmedBackground}
        actions={
          <ActionButton
            link="/login"
            icon={<NewSigninIcon height={20} width={20} />}
            label="Log in"
          />
        }
      ></PageContainer>
    );
  else
    return (
      <PageContainer
        imgSrc={EmailExpiredIcon}
        title="Confirmation link has expired"
        description={
          <>
            It looks like you are too late to activate your account or it
            has been activated before. {!isMediumSmallView && <br />} Do not
            worry, you can generate a new link or contact support if the issue
            persists.
          </>
        }
        imageUri={EmailExpiredBackground}
        hideSupport
        actions={
          <ActionButton
            link="mailto:support@givecorporation.com"
            icon={<HandShakeIcon height={20} width={20} />}
            label="Support"
          />
        }
      ></PageContainer>
    );
}

export default EmailConfirmation;
