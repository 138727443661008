import { useAppSelector, useAppDispatch } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { TChallengeActions, useChallengeContext } from "../ChallengeContext";
import { objectSlicer } from "features/Minibuilders/Conversations/utils/functions";
import { setUnreadTrigger } from "@redux/slices/unreadConversationSign";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { TChallengeTypeName } from "../../ChallengeTypeChip";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import {
  setConversationTopic,
  setModalOpenConversation,
} from "@redux/slices/conversations";

type TReadMessage = {
  threadId: number;
};

type TChallengeToOpen = {
  challengeId: number | undefined;
  threadId: number;
  challengeTypeName: TChallengeTypeName;
};

export const getUnderwritingTopicID = (
  challengeTypeName: TChallengeTypeName,
  data: any,
) => {
  const activityTopic = data.find((item: any) => item.Type === "activity");
  const internalTopic = data.find((item: any) => item.Type === "internal");
  switch (challengeTypeName) {
    case "customer_due_diligence":
      return internalTopic.ID;
    case "enhanced_due_diligence":
      return activityTopic.ID;
    default:
      data[0].ID;
  }
};

export const useOpenChallengeConversation = ({
  challengeId,
  threadId,
  challengeTypeName,
}: TChallengeToOpen) => {
  const { readMessage } = useReadMessage();
  const dispatch = useAppDispatch();
  const { isEnabledConversationActivity } = useGetFeatureFlagValues();
  const {
    openConversationHandler,
    updateThreads,
    merchantData: {
      merchantInfo: { merchantID },
    },
  } = useChallengeContext() as TChallengeActions;

  const handleSubmit = async ({
    hasUnreadMessages,
    displayName,
  }: {
    hasUnreadMessages: boolean;
    displayName: string;
  }) => {
    if (
      challengeTypeName === "enhanced_due_diligence" &&
      isEnabledConversationActivity
    ) {
      const { data: topic } = await getGlobalTopic({
        topicName: "underwriting",
      });
      const activityTopic = topic?.find(
        (item: any) =>
          [item?.name, item?.Name]?.includes("underwriting") &&
          [item?.typeName, item?.Type]?.includes("activity"),
      );
      dispatch(setModalOpenConversation(true));
      return dispatch(
        setConversationTopic({
          isOpen: true,
          isOpenedFromSidePanel: false,
          numberOfUnreadMessages: 0,
          queryObject: {
            id: activityTopic?.id || activityTopic?.ID,
            name: "sds",
            tabs: "Activity",

            paths: [
              {
                avatars: [],
                isConversation: true,
                pathID: threadId,
                pathName: displayName,
                hideInputs: ["module", "subject"],
              },
            ],
          },
        }),
      );
    }
    const { data } = await getGlobalTopic({ topicName: "underwriting" });
    const underwritingTopicID = getUnderwritingTopicID(challengeTypeName, data);

    if (!underwritingTopicID) return;

    const generateConversationObj = {
      id: underwritingTopicID,
      name: "Underwriting",
      paths: [],
      defaultMessage: "",
      challengeId: challengeId,
      forceTopicOpen: true,
      openThread: {
        isRepliesOpen: false,
        threadId: undefined,
        commentId: undefined,
        index: undefined,
      },
      disableGlobalLauncher: true,
    };

    if (threadId !== 0) {
      const { data: _threads } = await customInstance({
        url: `/merchants/${merchantID}/topics/${underwritingTopicID}/threads?filter=id:${threadId}`,
        method: "GET",
      });

      if (_threads) {
        updateThreads(_threads);

        const userAvatars = objectSlicer(
          _threads[0]?.messages,
          1,
          4,
          "authorAvatarImageURL",
        ) as string[];

        const name = `${_threads[0]?.authorFirstName} ${_threads[0]?.authorLastName}`;
        openConversationHandler({
          ...generateConversationObj,
          paths: [
            {
              avatars: userAvatars ? Array.from(new Set(userAvatars)) : [],
              pathName: name,
            },
          ],
          ...(threadId && {
            openThread: {
              commentId: _threads[0]?.messages[0]?.id,
              index: 0,
              isRepliesOpen: true,
              threadId: _threads[0]?.id,
            },
          }),
        });

        if (hasUnreadMessages) {
          readMessage({ threadId: threadId });
        }

        return;
      }
    } else {
      openConversationHandler({
        ...generateConversationObj,
        defaultMessage: `#${displayName.replace(/\s+/g, "")} `,
      });
    }
  };

  return {
    handleSubmit,
  };
};

export const useReadMessage = () => {
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutate: readMessage } = useMutation({
    mutationFn: async ({ threadId }: TReadMessage) => {
      await customInstance({
        url: `/merchants/${globalMerchantId}/messages/read`,
        method: "POST",
        data: {
          threadID: threadId,
        },
      });
    },
    onSuccess: () => {
      // Improvement check if we can refetch by filtering
      queryClient.refetchQueries("get-challenges");
      dispatch(setUnreadTrigger(true));
    },
  });

  return {
    readMessage,
  };
};
