import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { useQuery } from "react-query";

type StatsType = {
  avgPerCustomer: number;
  recurringCustomers: number;
  repeatedCustomers: number;
  totalCustomers: number;
};

const useGetCustomersStats = () => {
  const { merchantId } = useGetCurrentMerchantId();

  const getStats = (id: number) => {
    return customInstance({
      url: `/merchants/${id}/customers/stats`,
      method: "GET",
    });
  };

  return useQuery<StatsType>(
    ["get-customers-stats", merchantId],
    async () => {
      const data = await getStats(merchantId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useGetCustomersStats;
