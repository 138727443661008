import { Button } from "@common/Button";
import { BaseLink, BaseLinkProps } from "@common/Campaigns/BaseLink";
import { palette } from "@palette";

interface Props extends BaseLinkProps {
  icon: JSX.Element;
  label: string;
}

function ActionButton({ label, icon, ...rest }: Props) {
  return (
    <BaseLink
      sx={{
        borderBottom: "none",
        "&:hover p": {
          color: palette.neutral[100],
        },
      }}
      {...rest}
    >
      <Button
        sx={({ palette }) => ({
          "&:hover ": {
            color: palette.neutral[100],
          },
          "&:hover path ": {
            fill: palette.neutral[100],
          },
        })}
        background="tertiary"
        endIcon={icon}
      >
        {label}
      </Button>
    </BaseLink>
  );
}

export default ActionButton;
