import { Divider, Grid, Stack } from "@mui/material";
import CustomDropZone from "./components/CustomDropZone";
import BrandingCard from "./components/BrandingCard";
import { useThemeContext } from "./Provider/CustomThemeProvider";
import SampleCards from "./components/SampleCards";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { memo } from "react";
import { palette } from "@palette";
import { BrandingHeading, FormActions } from "./components/atoms";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { HiddenComponent } from "@containers/HiddenComponent";
import EmbedCodeSection from "./components/EmbeddedCode";

function Branding() {
  const {
    activeGradientColor,
    onSelectGradientColor,
    colorChoices,
    isEqualDefault,
    resetValues,
    inViewRef,
    handleSaveTheme,
  } = useThemeContext();
  const { isMobileView } = useCustomTheme();

  const renderChoices = (c: (typeof colorChoices)[number], index: number) => {
    const isSelected =
      c.linearColor?.light === activeGradientColor?.light &&
      c.linearColor?.dark === activeGradientColor?.dark;
    return (
      <BrandingCard
        key={c.title + index}
        {...c}
        onSelectGradientColor={onSelectGradientColor}
        isSelected={isSelected}
      />
    );
  };

  const sections = [
    {
      node: <LogoSection />,
      withDivider: true,
    },
    {
      node: (
        <Stack direction="column" gap="16px" alignItems="stretch">
          <BrandingHeading {...brandingHeadings["cards"]} />

          <Grid container columnSpacing="16px" rowSpacing={2}>
            {colorChoices?.map(renderChoices)}
          </Grid>
        </Stack>
      ),
      withDivider: isMobileView,
    },
    {
      node: (
        <Grid container columnSpacing="16px" rowSpacing={2}>
          <SampleCards />
          <SampleCards isDarkMode />
        </Grid>
      ),
      hidden: isMobileView,
      withDivider: true,
    },
    {
      node: <EmbedCodeSection />,
      withDivider: false,
    },
  ];

  return (
    <ErrorCatcher errorID="theme-and-branding">
      <Stack
        ref={inViewRef}
        direction="column"
        gap="50px"
        paddingBottom="16px"
        alignItems="stretch"
        overflow="hidden"
      >
        <Stack direction="column" gap="24px" alignItems="stretch" flexGrow={1}>
          {sections.map(({ node, hidden, withDivider }, index) => (
            <HiddenComponent key={index} hidden={hidden || false}>
              <>
                <FadeUpWrapper delay={50 + 70 * index}>{node}</FadeUpWrapper>
                {withDivider && (
                  <FadeUpWrapper delay={50 + 100 * index}>
                    <Divider color={palette.liftedWhite[100]} />
                  </FadeUpWrapper>
                )}
              </>
            </HiddenComponent>
          ))}
        </Stack>
        <FormActions
          primaryAction={{
            onClick: handleSaveTheme,
            disabled: isEqualDefault,
          }}
          secondaryAction={{
            onClick: resetValues,
            disabled: isEqualDefault,
          }}
        />
      </Stack>
    </ErrorCatcher>
  );
}

export default memo(Branding);

const LogoSection = () => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack direction={isMobileView ? "column" : "row"} gap="24px" flexGrow={1}>
      <BrandingHeading {...brandingHeadings["logo"]} />
      <Grid container columnSpacing="16px">
        <Grid item xs={7}>
          <CustomDropZone type="landscape" textLabel="200&times;48" />
        </Grid>
        <Grid item xs={5}>
          <CustomDropZone type="square" textLabel="48&times;48" />
        </Grid>
      </Grid>
    </Stack>
  );
};

export const brandingHeadings = {
  logo: {
    title: "Portal logo",
    description:
      "These images will be utilized when inviting merchants and will be featured in various sections on the platform, effectively extending your provider's branding to your merchant partners",
  },
  cards: {
    title: "Theme colors",
    description:
      "This color palette will be inherited by all merchants operating under your provider",
  },
  iframe: {
    title: "Embed Code for Registration",
    description:
      "A snippet of code that allows you to integrate a Sign Up form directly into your website.",
  },
};
