import React from "react";
import Skeleton from "@components/animation/Skeleton";
import { Divider, Grid } from "@mui/material";

const MyAccountLoadingSkeleton = () => {
  return (
    <div>
      <Skeleton borderRadius="10px" width="200px" height="20px" />
      <Skeleton
        borderRadius="10px"
        width="72px"
        height="72px"
        sx={{ mt: 3, mb: 2 }}
      />
      <Grid container spacing={2}>
        {Array(8)
          .fill(0)
          .map((_, index) => (
            <Grid item key={index} xs={12} sm={12}>
              <Skeleton borderRadius="10px" width="100%" height="50px" />
            </Grid>
          ))}
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Skeleton borderRadius="10px" width="200px" height="20px" />
      <Skeleton
        borderRadius="10px"
        width="250px"
        height="20px"
        sx={{ my: 2 }}
      />
      <Skeleton borderRadius="10px" width="40vw" height="70px" />

      <Divider sx={{ my: 3 }} />

      <Skeleton
        borderRadius="10px"
        width="200px"
        height="20px"
        sx={{ mb: 2 }}
      />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Grid item key={index} xs={12} sm={12}>
              <Skeleton borderRadius="10px" width="100%" height="50px" />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default MyAccountLoadingSkeleton;
