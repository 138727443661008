import { Skeleton, Stack } from "@mui/material";

export const TextSkeleton = ({
  height = 18,
  width,
}: {
  height?: number | string;
  width?: number | string;
}) => {
  return (
    <Skeleton
      variant="text"
      height={height}
      width={width}
      sx={{ flexGrow: 1 }}
    />
  );
};

export const MerchantFirstCell = () => {
  return (
    <>
      <Skeleton variant="circular" width={20} height={20} />
      <Skeleton variant="rounded" width={40} height={40} />
      <TextSkeleton />
    </>
  );
};

export const AssigneeSkeleton = () => {
  return (
    <>
      <Skeleton variant="circular" width={24} height={24} />
      <TextSkeleton />
    </>
  );
};

export const PhaseSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rounded"
        width={61}
        height={22}
        sx={{ borderRadius: "47px" }}
      />
      <Skeleton
        variant="rounded"
        width={30}
        height={10}
        sx={{ borderRadius: "15px" }}
      />
      <TextSkeleton height={6} />
    </>
  );
};

export const ListItemTitle = () => {
  return (
    <>
      <Skeleton variant="rounded" width={64} height={64} />
      <Stack
        direction="column"
        gap="6px"
        alignItems="flex-start"
        flexGrow={1}
        justifyContent="center"
      >
        <TextSkeleton width="90%" />
        <TextSkeleton width="90%" />
      </Stack>
    </>
  );
};
export const InOutSkeleton = () => {
  return (
    <Stack
      direction="column"
      gap="4px"
      width="67px"
      flexGrow={1}
      alignItems="flex-end"
      justifyContent="center"
    >
      <TextSkeleton width={67} />
      <TextSkeleton width={50} height={16} />
    </Stack>
  );
};

export const ProcessingMerchantSkeleton = () => {
  return (
    <Stack direction="column" gap="4px" flexGrow={1} justifyContent="center">
      <TextSkeleton width="90%" />
      <TextSkeleton />
    </Stack>
  );
};

export const TagSkeleton = () => {
  return <Skeleton variant="rectangular" height={22} width="100%" />;
};

export const TagSkeletonRound = () => {
  return (
    <Skeleton
      variant="rectangular"
      height={22}
      width="100%"
      sx={{
        borderRadius: "100px",
      }}
    />
  );
};

export const MemberSkeleton = () => {
  return (
    <>
      <Skeleton variant="circular" width={32} height={32} />
      <ProcessingMerchantSkeleton />
    </>
  );
};

export const ActionsSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={32}
      height={24}
      sx={{ borderRadius: "4px" }}
    />
  );
};
