import DeleteIconButton from "../components/IconButtons/DeleteIconButton";
import CopyIconButton from "../components/IconButtons/CopyIconButton";
import ArrowDownIconButton from "../components/IconButtons/ArrowDownIconButton";
import ArrowUpIconButton from "../components/IconButtons/ArrowUpIconButton";
import ShowHideButton from "../components/IconButtons/ShowHideIconButton";
import { styled } from "@mui/material";
import { palette } from "@palette";

const FloatingToolbarWrapper = ({ children }: any) => {
  return (
    <div style={{ position: "relative" }}>
      <Container>
        <DeleteIconButton />
        <ShowHideButton />
        <CopyIconButton />
        <ArrowDownIconButton />
        <ArrowUpIconButton />
      </Container>
      {children}
    </div>
  );
};

export default FloatingToolbarWrapper;

const Container = styled("div")(() => ({
  position: "absolute",
  top: "4px",
  right: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",
  backgroundColor: palette.neutral.white,
  borderRadius: "8px",
  padding: "4px",
  boxShadow: "0px 4px 8px 0px #0000001A",
  zIndex: 9,
}));
