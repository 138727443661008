import { SvgIconProps } from "@mui/material";

const ArrowUpIconV2 = ({
  width = 21,
  height = 20,
  fill = "#292928",
  opacity = 1,
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5675 9.19268C16.5095 9.25079 16.4406 9.29689 16.3647 9.32834C16.2888 9.35979 16.2075 9.37598 16.1253 9.37598C16.0432 9.37598 15.9619 9.35979 15.886 9.32834C15.8101 9.29689 15.7412 9.25079 15.6832 9.19268L11.1253 4.63409V16.8755C11.1253 17.0413 11.0595 17.2002 10.9423 17.3174C10.8251 17.4346 10.6661 17.5005 10.5003 17.5005C10.3346 17.5005 10.1756 17.4346 10.0584 17.3174C9.9412 17.2002 9.87535 17.0413 9.87535 16.8755V4.63409L5.31753 9.19268C5.20026 9.30996 5.0412 9.37584 4.87535 9.37584C4.7095 9.37584 4.55044 9.30996 4.43316 9.19268C4.31588 9.0754 4.25 8.91634 4.25 8.75049C4.25 8.58464 4.31588 8.42558 4.43316 8.3083L10.0582 2.6833C10.1162 2.62519 10.1851 2.57909 10.261 2.54764C10.3369 2.51619 10.4182 2.5 10.5003 2.5C10.5825 2.5 10.6638 2.51619 10.7397 2.54764C10.8156 2.57909 10.8845 2.62519 10.9425 2.6833L16.5675 8.3083C16.6256 8.36635 16.6717 8.43528 16.7032 8.51115C16.7347 8.58703 16.7508 8.66836 16.7508 8.75049C16.7508 8.83263 16.7347 8.91396 16.7032 8.98983C16.6717 9.0657 16.6256 9.13463 16.5675 9.19268Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

export default ArrowUpIconV2;
