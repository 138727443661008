import { useMemo, useState } from "react";
import { ItemType } from "../components/FilterPermissionList";
import { useQueryClient } from "react-query";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import usePermissionData from "./usePermissionData";
import { IUpdatedData } from "features/Permissions/Modal/api/types";
import { useDebouncedCallback } from "use-debounce";

const QUERY_KEY = "add-permission-modal-list";

const useAddPermissionsModal = (memberId: number) => {
  const {
    dataIsFetching,
    dataIsLoading,
    setSearchQuery,
    isMutated: listMutated,
    permissionsData,
    clearChanges,
    setPermissionStatus,
    logs,
  } = usePermissionData(memberId, {
    withPagination: false,
    queryKey: QUERY_KEY,
  });

  const [searchString, setSearchString] = useState<string>("");

  const setAPISearchQuery = useDebouncedCallback(
    (value) => setSearchQuery(value),
    200,
  );

  const handleSearch = (value: string) => {
    setSearchString(value);
    setAPISearchQuery(value);
  };

  const [selectedGroup, setSelectedGroup] = useState<ItemType>(defaultAll);
  const { value: filterKey } = selectedGroup;

  const filteredGroups =
    filterKey && filterKey !== defaultAll.value
      ? Object.entries(permissionsData.groups).reduce(
          (acc: typeof permissionsData.groups, [key, hashes]) => {
            if (key !== filterKey) return acc;
            return {
              ...acc,
              [key]: hashes,
            };
          },
          {},
        )
      : permissionsData.groups;

  const orderedKeys = Object.keys(filteredGroups).sort((a, b) =>
    a.localeCompare(b),
  );

  const filters = useMemo(() => {
    const arr = Object.keys(permissionsData?.groups).map((item) => {
      const title =
        capitalizeFirstLetter(item === "enterprise" ? "provider" : item) +
        " " +
        "permissions";
      return {
        value: item,
        title,
      };
    });
    arr.sort((a, b) => a.value.localeCompare(b.value));
    arr.unshift(defaultAll);
    return arr;
  }, [permissionsData.groups, orderedKeys, filteredGroups]);

  const handleSelect = (e: ItemType) => setSelectedGroup(e);

  return {
    listMutated,
    clearChanges,
    logs,
    dataIsLoading: dataIsLoading || (dataIsFetching && !orderedKeys.length),
    isSearching: dataIsFetching && !!searchString,
    searchString,
    handleSearch,
    selectedGroup,
    filters,
    handleSelect,
    orderedKeys,
    permissionsData,
    setPermissionStatus,
    filteredGroups,
  };
};

const defaultAll = {
  value: "All",
  title: "All permissions",
};

export const useAddPermissionModalCache = () => {
  const queryClient = useQueryClient();

  const updateCache = (key: string, values: IUpdatedData) => {
    queryClient.setQueriesData(QUERY_KEY, (oldData: any) => {
      if (!oldData?.data) return oldData;
      return {
        ...oldData,
        data: {
          ...oldData.data,
          [key]: {
            ...values,
          },
        },
      };
    });
  };

  return { updateCache, queryKey: QUERY_KEY };
};

export default useAddPermissionsModal;
