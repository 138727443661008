import { TooltipProps } from "@common/Tooltip/Tooltip";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import { palette } from "@palette";
import React from "react";

export const CustomStyleTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 197,
    padding: "4px 16px",
    background: palette.neutral[80],
    borderRadius: "8px",
  },
});
