import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import { checkPortals } from "@utils/routing";

const PageTitle = () => {
  const { gradient } = useThemeContext();
  const { isEnterprisePortal } = checkPortals();

  return (
    <Stack direction="column" gap={2} alignItems="flex-start">
      <Text
        variant="h1"
        fontSize="56px"
        lineHeight="67.2px"
        color={palette.black[200]}
        fontWeight="book"
        align="left"
        sx={{ letterSpacing: "-1.68px" }}
      >
        Complete your{" "}
        <Box
          component="span"
          sx={{
            background: gradient,
            backgroundClip: "text",
            color: "transparent",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "tranparent",
          }}
        >
          profile
        </Box>
      </Text>
      <Text
        color={palette.gray[300]}
        variant="h6"
        lineHeight="24.3px"
        fontWeight="book"
        align="left"
        sx={{ letterSpacing: "-0.18px" }}
      >
        These steps are essential for us to provide you with our tailored
        {isEnterprisePortal ? " provider" : " merchant"} services.
      </Text>
    </Stack>
  );
};

export default PageTitle;
