import React from "react";
import { palette } from "@palette";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { Tooltip } from "@common/Tooltip";
import { DropdownItem } from "@common/Select";
import { IconButton } from "@common/IconButton";
import { BaseModal, ModalTitle } from "@common/Modal";
import ViewTransactionBanner from "./ViewTransactionBanner";
import { Table, TableColumnType } from "@common/Table";
import { ExpandedRow } from "./ExpandedRow";
import TableCard from "./Mobile/TableCard";
// assets
import {
  TransferIcon,
  DownloadIcon,
  RefundTopIcon,
  PaperLightIcon,
  MoreIcon,
} from "@assets/icons";
// data
import { TransactionData, tableData } from "./data";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
// utils
import { parseAmount, toEnFormat } from "@utils/index";
import {
  DOWNLOAD_REPORT_MODAL,
  MOVE_TRANSACTION_MODAL,
  PROCESSING_REFUND_TRANSACTION_MODAL,
  PROCESSING_SEND_RECEIPT_MODAL,
  PROCESSING_VIEW_CUSTOMER_MODAL,
} from "modals/modal_names";
import { getSourceAccount, getType } from "@components/ManageMoney/TransactionTable/transactions.helpers";

type ViewTransactionProps = {
  data: {
    [x: string]: any;
  };
};

const ViewTransaction = NiceModal.create(({ data }: ViewTransactionProps) => {
  const theme = useTheme();
  const modal = useModal();
  const { t } = useTranslation(namespaces.common);
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [isSelected, setIsSelected] = React.useState(false);

  const handleClose = () => {
    modal.hide();
  };

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    NiceModal.show(DOWNLOAD_REPORT_MODAL, {
      title: t("transactions", { ns: namespaces.common }),
    });
  };

  const refundHandler = (row: TransactionData) => {
    NiceModal.show(PROCESSING_REFUND_TRANSACTION_MODAL, {});
  };

  const sendReceiptHandler = (row?: Record<string, any>) => {
    NiceModal.show(PROCESSING_SEND_RECEIPT_MODAL, {
      transactionID: row?.id,
      emails: [row?.sourceAccount.user.email],
      sourceAccount: getSourceAccount(row as any),
    });
  };

  const viewCustomerHandler = (row: TransactionData) => {
    NiceModal.show(PROCESSING_VIEW_CUSTOMER_MODAL, {
      data: row,
    });
  };

  const moveTransactionHandler = (data: { [x: string]: any }) => {
    NiceModal.show(MOVE_TRANSACTION_MODAL, {
      isSelected: data.isSelected,
      setIsSelected: data.setIsSelected,
      resetSelected: data.setIsSelected,
    });
  };

  const columns: TableColumnType[] = [
    {
      key: "title",
      sortable: true,
      title: t("transactions", { ns: namespaces.common }),
      hasExpandButton: true,
      renderColumn: (row: TransactionData) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {row.title}
          </Text>
          <Text color={palette.neutral[800]}>{row.time}</Text>
        </Box>
      ),
    },
    {
      key: "type",
      sortable: true,
      title: t("filters.type", { ns: namespaces.common }),
      renderColumn: (row: TransactionData) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {getType(row)}
          </Text>
        </Box>
      ),
    },
    {
      key: "amount",
      sortable: true,
      title: t("filters.amount", { ns: namespaces.common }),
      renderColumn: (row: TransactionData) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {parseAmount(row.amount)}
          </Text>
          <Text color={palette.neutral[800]}>
            {parseAmount(row.balance)}
          </Text>
        </Box>
      ),
    },
    {
      key: "actions",
      renderColumn: (row: TransactionData) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
          null,
        );
        const open = Boolean(anchorEl);

        const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        };

        return (
          <Box display={"flex"} gap="12px" sx={{ ml: "auto" }}>
            <Tooltip title="Refund">
              <Box>
                <IconButton onClick={() => refundHandler(row)} size="small">
                  <RefundTopIcon size="20px" />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title="Send Receipt">
              <Box>
                <IconButton
                  onClick={() => sendReceiptHandler(row)}
                  size="small"
                >
                  <PaperLightIcon size="20px" />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title="More">
              <Box>
                <IconButton size="small" onClick={handleOpenMenu}>
                  <MoreIcon size="20px" />
                </IconButton>
              </Box>
            </Tooltip>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  width: "max-content",
                },
              }}
            >
              <DropdownItem onClick={() => sendReceiptHandler(row)}>
                <Text>Send Receipt</Text>
              </DropdownItem>
              <DropdownItem onClick={() => refundHandler(row)}>
                <Text>Refund Transaction</Text>
              </DropdownItem>
              <DropdownItem onClick={() => viewCustomerHandler(row)}>
                <Text>View Customer</Text>
              </DropdownItem>
              {/* <DropdownItem
                onClick={() =>
                  moveTransactionHandler({
                    isSelected: isSelected,
                    setIsSelected: setIsSelected,
                    resetSelected: setIsSelected,
                  })
                }
              >
                <Text>Move Transaction</Text>
              </DropdownItem> */}
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <BaseModal
      width={"896px"}
      {...muiDialogV5(modal)}
      sx={{
        ...(isSmall && {
          "& .MuiDialogContent-root": {
            padding: "24px 8px",
          },
        }),
      }}
    >
      <ModalTitle
        titleComponent={
          <Text
            fontWeight="medium"
            variant={isDesktop ? "h5" : "h6"}
            color={theme.palette.neutral[800]}
          >
            {data.title ? `${data.title}'s` : `${data.name}'s`} Transactions
          </Text>
        }
        titleIcon={<TransferIcon />}
        onClose={handleClose}
      />

      <DialogContent>
        <Stack
          mb={1.5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ViewTransactionBanner />
        </Stack>

        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            variant="h5"
            fontWeight="medium"
            color={theme.palette.neutral[800]}
          >
            Transactions
          </Text>
          {/* {isDesktop ? (
            <Button startIcon={<DownloadIcon />} onClick={handleDownload}>
              Download Report
            </Button>
          ) : (
            <IconButton onClick={handleDownload}>
              <DownloadIcon />
            </IconButton>
          )} */}
        </Stack>
        {isDesktop ? (
          <>
            <Table
              expandable
              data={tableData}
              columns={columns}
              renderExpandedRow={ExpandedRow}
              totalRecords={493}
              withPagination={false}
              pinkBorder={true}
            />
          </>
        ) : (
          <Box mt="10px" gap="10px" display="flex" flexDirection="column">
            {tableData.map((item) => (
              <TableCard
                key={item.id}
                rowData={item}
                closeModal={handleClose}
              />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BaseModal>
  );
});

export default ViewTransaction;
