import useProductReducer from "@hooks/Reducers/useProductReducer";
import { SelectedProduct } from "@redux/slices/products";
import { useEffect } from "react";

export const useBreadcrumbs = (selectedProduct?: SelectedProduct) => {
  const { setSelectedProduct, product, resetSelectedProduct } =
    useProductReducer();

  useEffect(() => {
    selectedProduct?.product_id && setSelectedProduct(selectedProduct);
    return () => resetSelectedProduct();
  }, [selectedProduct?.imgSrc, selectedProduct?.title]);

  return {
    ...product,
  };
};
