import MCCSection from "@components/AcquirerEnterprises/EnterprisePreview/components/MCCSection";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useListEnterpriseCategoryCodes } from "@hooks/enterprise-api/merchants/useListEnterpriseCategoryCodes";
import { useFormContext } from "react-hook-form";

function CreateEnterpriseMCCSection({
  handleCloseMCC,
}: {
  handleCloseMCC: (data: any) => void;
}) {
  const { merchantId } = useGetCurrentMerchantId();
  const { categories } = useListEnterpriseCategoryCodes(merchantId);
  const { watch } = useFormContext();
  const values = watch();

  return (
    <MCCSection
      id={merchantId}
      data={values.categories}
      parentCategories={categories}
      handleCloseMCC={handleCloseMCC}
    />
  );
}

export default CreateEnterpriseMCCSection;
