import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";

const useProductPermission = () => {
  const isAddProductAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.PRODUCT),
    operation: OPERATIONS.CREATE,
  });

  const isEditProductAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.PRODUCT),
    operation: OPERATIONS.UPDATE,
  });

  const isDeleteProductAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.PRODUCT),
    operation: OPERATIONS.DELETE,
  });

  return { isAddProductAllowed, isEditProductAllowed, isDeleteProductAllowed };
};

export default useProductPermission;
