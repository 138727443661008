import { SvgIconProps } from "@mui/material";

const NewMobileDownloadIcon = ({
  height = 32,
  width = 32,
  stroke = "#8F8F8F",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.3333L15.8114 21.1447C15.9156 21.2489 16.0844 21.2489 16.1886 21.1447L24 13.3333M16 20.6666C16 15.4597 16 5.33336 16 5.33336M8 28L24 28"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default NewMobileDownloadIcon;
