// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const HeartLoveIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: // isMenu,
IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8887 5.12484C13.8959 5.24993 12.8448 5.8637 12.0099 7.20945C11.9004 7.38585 11.7076 7.49315 11.5 7.49315C11.2924 7.49315 11.0996 7.38585 10.9902 7.20946C10.1553 5.86374 9.10415 5.24997 8.11131 5.12487C7.10921 4.9986 6.08533 5.35911 5.28702 6.11816C3.70408 7.6368 3.70436 10.1942 5.28786 11.7125L11.5 17.6688L12.7817 16.4399C13.0209 16.2105 13.4007 16.2185 13.6301 16.4577C13.8594 16.6969 13.8514 17.0767 13.6122 17.306L11.9153 18.9331C11.6832 19.1556 11.3169 19.1556 11.0848 18.9331L4.45736 12.5787C2.38091 10.5878 2.38091 7.24209 4.45736 5.25118L4.45904 5.24957L4.45904 5.24957C5.49072 4.26806 6.86512 3.75835 8.26132 3.93428C9.43953 4.08274 10.5768 4.7117 11.5 5.85583C12.4233 4.71167 13.5605 4.0827 14.7387 3.93425C16.1349 3.75833 17.5093 4.26806 18.541 5.24957L18.5427 5.25118C20.6191 7.24209 20.6191 10.5878 18.5427 12.5787L17.2901 13.7797C17.0509 14.0091 16.671 14.0011 16.4417 13.7619C16.2124 13.5227 16.2204 13.1429 16.4595 12.9135L17.7122 11.7125C19.2957 10.1942 19.296 7.6368 17.713 6.11817C16.9147 5.35911 15.8908 4.99858 14.8887 5.12484Z"
          fill="url(#paint0_linear_304_1620)"
        />
        <defs>
          <linearGradient
            id={`paint0_linear_304_1620`}
            x1="19.792"
            y1="4.54711"
            x2="1.13149"
            y2="18.6226"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8887 5.12484C13.8959 5.24993 12.8448 5.8637 12.0099 7.20945C11.9004 7.38585 11.7076 7.49315 11.5 7.49315C11.2924 7.49315 11.0996 7.38585 10.9902 7.20946C10.1553 5.86374 9.10415 5.24997 8.11131 5.12487C7.10921 4.9986 6.08533 5.35911 5.28702 6.11816C3.70408 7.6368 3.70436 10.1942 5.28786 11.7125L11.5 17.6688L12.7817 16.4399C13.0209 16.2105 13.4007 16.2185 13.6301 16.4577C13.8594 16.6969 13.8514 17.0767 13.6122 17.306L11.9153 18.9331C11.6832 19.1556 11.3169 19.1556 11.0848 18.9331L4.45736 12.5787C2.38091 10.5878 2.38091 7.24209 4.45736 5.25118L4.45904 5.24957L4.45904 5.24957C5.49072 4.26806 6.86512 3.75835 8.26132 3.93428C9.43953 4.08274 10.5768 4.7117 11.5 5.85583C12.4233 4.71167 13.5605 4.0827 14.7387 3.93425C16.1349 3.75833 17.5093 4.26806 18.541 5.24957L18.5427 5.25118C20.6191 7.24209 20.6191 10.5878 18.5427 12.5787L17.2901 13.7797C17.0509 14.0091 16.671 14.0011 16.4417 13.7619C16.2124 13.5227 16.2204 13.1429 16.4595 12.9135L17.7122 11.7125C19.2957 10.1942 19.296 7.6368 17.713 6.11817C16.9147 5.35911 15.8908 4.99858 14.8887 5.12484Z"
        fill={fill}
      />
    </svg>
  );
};

export default HeartLoveIcon;
