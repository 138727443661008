import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { Box } from "@mui/material";
import options from "./utils/options";
import { parseAmount } from "@utils/index";
import { AMOUNTS_REGION } from "@constants/constants";
import { useCallback, useMemo, useState } from "react";
import useTimeConfig from "../LineCharts/hooks/useTimeConfig";
import generateOptions from "./utils/options";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

const dataConfig = {
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: "#66C7EA",
      barThickness: 8,
      borderRadius: 8,
    },
    {
      label: "",
      data: [],
      backgroundColor: "transparent",
      hidden: true,
    },
    {
      label: "",
      data: [],
      backgroundColor: "transparent",
      hidden: true,
    },
    {
      label: "",
      data: [],
      backgroundColor: "transparent",
      hidden: true,
    },
  ],
};

export type TVerticalBarChartData = {
  label: string;
  entries: { x: Date; y: number }[];
  increment?: number[];
};

export type CustomTooltipData = {
  top: number;
  left: number;
  increments?: (number | undefined)[];
  title: string;
  labels: string[];
};

const VerticalBarChart = ({
  data,
  height,
  CustomTooltip,
  isCustomer,
}: {
  data: TVerticalBarChartData[];
  height?: number;
  CustomTooltip?: (props: Partial<CustomTooltipData>) => JSX.Element;
  isCustomer?: boolean;
}) => {
  const [tooltipData, setTooltipData] = useState<CustomTooltipData | null>(
    null,
  );
  const customData = useMemo(() => {
    return {
      datasets: dataConfig.datasets.map((dataset, index) => {
        return {
          ...dataset,
          label: data[index].label,
          data: data[index].entries,
          increment: data[index].increment,
        };
      }),
    };
  }, [data]);

  const { timeConfig } = useTimeConfig(data);

  const getLabel = useCallback(
    (index: number) => {
      return customData.datasets.map((dataset) => {
        const value = dataset.label.includes("USD")
          ? parseAmount(dataset.data[index].y)
          : dataset.data[index].y.toLocaleString(AMOUNTS_REGION);
        return dataset.label + ": " + value;
      });
    },
    [customData],
  );

  const getIncrement = useCallback(
    (index: number) => {
      if (
        customData.datasets.some((dataset) => dataset.increment === undefined)
      )
        return undefined;
      return customData.datasets.map((dataset) => {
        return dataset?.increment?.[index];
      });
    },
    [customData],
  );
  const options = generateOptions(isCustomer);
  return (
    <Box
      height="max-content"
      width="100%"
      maxHeight={height}
      position="relative"
    >
      <Bar
        height={height}
        options={{
          ...options,
          plugins: {
            ...(options?.plugins && options.plugins),
            tooltip: {
              ...(options?.plugins?.tooltip && options.plugins.tooltip),
              callbacks: {
                ...(options?.plugins?.tooltip?.callbacks &&
                  options.plugins.tooltip.callbacks),
                label: (context: any) => {
                  const index = context.dataIndex;
                  return getLabel(index);
                },
              },
              ...(Boolean(CustomTooltip) && {
                enabled: false,
                external: (context: any) => {
                  const { chart, tooltip } = context;
                  if (tooltip.opacity == 0) {
                    setTooltipData(null);
                    return;
                  }
                  const canvas = chart.canvas;

                  if (canvas) {
                    const left = tooltip.x;
                    const top = tooltip.y;
                    const index = tooltip.dataPoints[0].dataIndex;

                    setTooltipData({
                      top,
                      left,
                      increments: getIncrement(index),
                      title: tooltip.title[0],
                      labels: getLabel(index),
                    });
                  }
                },
              }),
            } as any,
          },
          scales: {
            ...(options?.scales && options.scales),
            x: {
              ...(options?.scales?.x && options.scales.x),
              time: {
                ...timeConfig,
                unit: "month",
              },
            },
          },
        }}
        data={customData}
      />
      {CustomTooltip && (
        <Box
          sx={{
            opacity: tooltipData ? 1 : 0,
            top: tooltipData?.top,
            left: tooltipData?.left,
            position: "absolute",
          }}
        >
          <CustomTooltip
            title={tooltipData?.title}
            labels={tooltipData?.labels}
            increments={tooltipData?.increments}
          />
        </Box>
      )}
    </Box>
  );
};

export default VerticalBarChart;
