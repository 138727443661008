import { useState } from "react";
import { CustomModal } from "./AddPermissionModal.styles";
import JSONModalContent from "./JSONModalContent";
import PermissionListContent, {
  IPermissionListProps,
} from "./PermissionListContent";
import ErrorCatcher from "@common/Error/ErrorCatcher";

interface AddPermissionModalProps extends IPermissionListProps {
  open: boolean;
  onAddCustom: (permissionIds: string[], cb?: () => void) => void;
}

const AddPermissionModal = ({
  open,
  onClose,
  onAddCustom,
  ...rest
}: AddPermissionModalProps) => {
  const [isCodeEditor, setIsCodeEditor] = useState<boolean>(false);

  const onCancelEditor = () => setIsCodeEditor(false);

  const handleAddCustom = (permissionIds: string[]) => {
    onAddCustom(permissionIds, onCancelEditor);
  };

  const handleClose = () => {
    onCancelEditor();
    if (!isCodeEditor) onClose();
  };

  const modalSize = {
    width: isCodeEditor ? "787px" : "573px",
    height: isCodeEditor ? "617px" : "550px",
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      width={modalSize.width}
      height={modalSize.height}
    >
      <ErrorCatcher errorID="add-permission-modal">
        {isCodeEditor ? (
          <JSONModalContent
            memberId={rest.memberId}
            onClose={handleClose}
            onCancel={onCancelEditor}
            onAdd={handleAddCustom}
          />
        ) : (
          <PermissionListContent
            {...rest}
            onClose={handleClose}
            setIsCodeEditor={setIsCodeEditor}
          />
        )}
      </ErrorCatcher>
    </CustomModal>
  );
};

export default AddPermissionModal;
