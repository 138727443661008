import { memo } from "react";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";
import { CustomTheme } from "@theme/v2/palette.interface";
import { RoundedCloseIcon } from "@assets/icons/RebrandedIcons";

type Props = {
  theme: CustomTheme;
};

const AdvancedBuilderScreenSizeWarningModal = NiceModal.create(
  ({ theme }: Props) => {
    const modal = useModal();

    return (
      <Popup
        {...muiDialogV5(modal)}
        customContainerStyle={{
          backgroundColor: theme.palette?.primitive?.neutral?.[10],
          border: "none",
        }}
        title="Screen resolution not supported!"
        titleSx={{
          fontSize: "24px",
          color: theme.palette?.primitive?.neutral?.[100],
          lineHeight: "28px",
        }}
        textProps={{
          color: theme.palette?.primitive?.neutral?.[70],
          fontSize: "14px",
          fontWeight: "regular",
          lineHeight: "20px",
          marginTop: "8px",
        }}
        HeaderIcon={<RoundedCloseIcon />}
        hasCustomPadding
      >
        {`The advanced builder isn't supported at your current screen resolution,
        please resize your window to a supported resolution to access the full
        functionality.`}
      </Popup>
    );
  },
);

export default memo(AdvancedBuilderScreenSizeWarningModal);
