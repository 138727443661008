import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import { Skeleton, Stack, alpha } from "@mui/material";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { getRandomNumber } from "@utils/helpers";
import React, { memo } from "react";

function LoadingState() {
  return (
    <Box p="24px 16px" height="100%" bgcolor="#F8F8F6" width="100%">
      <Box
        height="100%"
        width="100%"
        borderRadius="16px"
        p="16px"
        bgcolor="rgba(251, 251, 251, 0.75)"
        boxShadow="0px 4px 15px 0px rgba(0, 0, 0, 0.05)"
        overflow="hidden"
      >
        <Skeleton
          sx={{
            borderRadius: "15px",
            marginBottom: "13.75px",
          }}
          variant="rounded"
          height="10.5px"
          width="205px"
        />
        <Skeleton
          sx={{
            borderRadius: "8px",
          }}
          variant="rounded"
          height="50px"
          width="155px"
        />
        <Box
          mt="20px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" gap="16px" alignItems="center">
            <Skeleton
              sx={{
                borderRadius: "30px",
              }}
              variant="rounded"
              height="24px"
              width="153px"
            />
            <Skeleton
              sx={{
                borderRadius: "30px",
              }}
              variant="rounded"
              height="24px"
              width="125px"
            />
            <Skeleton
              sx={{
                borderRadius: "30px",
              }}
              variant="rounded"
              height="24px"
              width="125px"
            />
          </Box>
          <HorizontalMoreIcon
            width={20}
            height={20}
            fill={palette.black[100]}
          />
        </Box>
        <Box
          mt="16px"
          bgcolor={alpha("#ECECE9", 0.5)}
          p="16px"
          borderRadius="12px"
        >
          <Skeleton
            sx={{
              borderRadius: "30px",
            }}
            variant="rounded"
            height="21px"
            width="343px"
          />
          <Stack my="16px">
            {Array(7)
              .fill(null)
              .map((c, idx) => (
                <Box mb="16px" key={idx} display="flex">
                  <Box flex={1}>
                    <Skeleton
                      sx={{
                        borderRadius: "17px",
                      }}
                      variant="rounded"
                      height="12.25px"
                      width={`${getRandomNumber(37, 95)}px`}
                    />
                  </Box>
                  <Box flex={1}>
                    <Skeleton
                      sx={{
                        borderRadius: "17px",
                      }}
                      variant="rounded"
                      height="12.25px"
                      width={`${getRandomNumber(70, 224)}px`}
                    />
                  </Box>
                </Box>
              ))}
          </Stack>

          <Stack mb="33px">
            {Array(3)
              .fill(null)
              .map((c, idx) => (
                <Box mb="16px" display="flex" key={idx}>
                  <Box flex={1}>
                    <Skeleton
                      sx={{
                        borderRadius: "17px",
                      }}
                      variant="rounded"
                      height="12.25px"
                      width={`${getRandomNumber(72, 183)}px`}
                    />
                  </Box>
                  <Box flex={1}>
                    <Skeleton
                      sx={{
                        borderRadius: "17px",
                      }}
                      variant="rounded"
                      height="12.25px"
                      width={`${getRandomNumber(38, 49)}px`}
                    />
                  </Box>
                </Box>
              ))}
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Skeleton
                sx={{
                  borderRadius: "40px",
                  marginBottom: "8px",
                }}
                variant="rounded"
                height="28px"
                width="127px"
              />
              <Skeleton
                sx={{
                  borderRadius: "17px",
                }}
                variant="rounded"
                height="12px"
                width="155px"
              />
            </div>
            <div>
              <Skeleton
                sx={{
                  borderRadius: "22px",
                  marginBottom: "8px",
                }}
                variant="rounded"
                height="15.25px"
                width="114px"
              />
              <Skeleton
                sx={{
                  borderRadius: "17px",
                }}
                variant="rounded"
                height="12.25px"
                width="142px"
              />
            </div>
          </Box>
        </Box>
        <Skeleton
          sx={{
            borderRadius: "30px",
            marginTop: "8px",
          }}
          variant="rounded"
          height="21.25px"
          width="110px"
        />
        <Box my="19px">
          {Array(5)
            .fill(null)
            .map((c, idx) => (
              <Box
                key={idx}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb="22px"
              >
                <Box>
                  <Skeleton
                    sx={{
                      borderRadius: "17px",
                      marginBottom: "8px",
                    }}
                    variant="rounded"
                    height="12.25px"
                    width="127px"
                  />{" "}
                  <Skeleton
                    sx={{
                      borderRadius: "15px",
                    }}
                    variant="rounded"
                    height="10.25px"
                    width="262px"
                  />{" "}
                </Box>
                <Box display="flex" alignItems="center" gap="8px">
                  <Skeleton
                    sx={{
                      borderRadius: "17px",
                    }}
                    variant="rounded"
                    height="12.25px"
                    width="80px"
                  />{" "}
                  <Skeleton
                    sx={{
                      borderRadius: "50%",
                    }}
                    variant="rounded"
                    height="13px"
                    width="13px"
                  />{" "}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(LoadingState);
