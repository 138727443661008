import { useAppSelector, useAppDispatch } from "@redux/hooks";
import {
  AppState,
  setDrawerOpen,
  setMobileDrawerOpen,
} from "@redux/slices/app";
import { RootState } from "@redux/store";

const useDrawerReducer = () => {
  const dispatch = useAppDispatch();
  const drawerState: AppState = useAppSelector((state: RootState) => state.app);

  const setDrawerOpenState = (open?: boolean) => {
    dispatch(setDrawerOpen(open));
  };

  const setMobileDrawerOpenState = (open?: boolean) => {
    dispatch(setMobileDrawerOpen(open));
  };

  return {
    ...drawerState,
    setDrawerOpenState,
    setMobileDrawerOpenState,
  };
};

export default useDrawerReducer;
