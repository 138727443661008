// import PurchaseBox from "@components/Memberships/MobileMembershipReport/PurchaseBox";
import PurchaseBox from "@components/Memberships/MembershipsReport/Table/Mobile/PurchaseBox";
import { Paper } from "@mui/material";
import { RevenuesData } from "./data";

type MobileRevenueCardProps = {
  type: string;
  data: RevenuesData;
};

const MobileRevenueCard = ({ type, data }: MobileRevenueCardProps) => {
  return (
    <Paper elevation={2} sx={cardStyle}>
      <PurchaseBox
        title={
          type === "Membership" ? "General Membership" : "New Donation Form"
        }
        value={data.details.revenueSource}
        titleSx={{ marginTop: "3px" }}
        reverse
      />
      <PurchaseBox
        title={
          <>
            {data.amount} <sup>USD</sup>
          </>
        }
        value={`Avg: ${data.average}`}
        isAmount
        flexEnd
      />
    </Paper>
  );
};

const cardStyle = {
  p: "12px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow:
    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  borderRadius: "8px",
};

export default MobileRevenueCard;
