import { Text, TruncateText } from "@common/Text";
import { styled } from "@mui/material";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { GoogleMaps } from "@services/google-api";
import { TGeoMap } from "../data.types";
import useLoadGoogleScript from "@hooks/google-api/useLoadGoogleScript";
import { getRiskColor } from "../utils";

const IPAddressLocationMap = ({
  data,
  level,
}: {
  data: TGeoMap;
  level: number;
}) => {
  const { isLoaded } = useLoadGoogleScript();
  const { gradient } = getRiskColor(level);

  const mapCoordinates =
    data.latitude && data.longitude
      ? {
          lat: data.latitude,
          lng: data.longitude,
        }
      : undefined;

  const overlayItems = [
    {
      label: "Longitude",
      value: `${data.longitude}\u00B0 W`,
      width: 120,
    },
    {
      label: "Latitude",
      value: `${data.latitude}\u00B0 N`,
      width: 120,
    },
    {
      label: "Timezone",
      value: data.timezone,
      width: 240,
    },
  ];
  return (
    <Box position="relative">
      <GoogleMaps
        coordinates={mapCoordinates}
        isLoaded={isLoaded}
        viewMarker={false}
        containerStyle={{
          width: "calc(100% - 64px)",
          height: "362px",
          boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
          marginInline: "auto",
        }}
        customOptions={{
          disableDefaultUI: true,
        }}
      />
      {mapCoordinates && (
        <OverlayContainer>
          {overlayItems.map((item) => (
            <OverlayItem key={item.label} {...item} />
          ))}
        </OverlayContainer>
      )}
      <ModalGradient background={gradient} />
    </Box>
  );
};

const OverlayItem = ({
  label,
  value = "",
  width,
}: {
  label: string;
  value?: string | number;
  width: number;
}) => {
  return (
    <Stack direction="column" gap="4px" padding="4px 0px" width={`${width}px`}>
      <Text
        color={palette.gray[300]}
        fontSize="12px"
        fontWeight="book"
        lineHeight="14.4px"
      >
        {label}
      </Text>
      <TruncateText
        lineClamp={1}
        color={palette.black[100]}
        fontSize="12px"
        fontWeight="book"
        lineHeight="14.4px"
        minHeight="14.4px"
      >
        {value}
      </TruncateText>
    </Stack>
  );
};

const OverlayContainer = styled(Stack)(() => ({
  borderRadius: "6px",
  background: "rgba(255, 255, 255, 0.80)",
  boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(20px)",
  padding: "4px 8px",
  flexDirection: "row",
  gap: "4px",
  alignItems: "center",
  position: "absolute",
  bottom: 4,
  right: 0,
  left: 0,
  marginInline: "auto",
  width: "min-content",
}));

export default IPAddressLocationMap;

const ModalGradient = styled(Box, {
  shouldForwardProp: (prop) => prop !== "background",
})<{ background: string }>(({ background }) => ({
  background,
  height: "164px",
  width: "100%",
  position: "absolute",
  top: "250px",
  zIndex: "-1",
}));
