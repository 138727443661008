import {
  BulkInviteActions,
  BulkInviteContainer,
  BulkUploadHeader,
  InviteListContainer,
  TextItem,
  InvitesLoadingState,
} from "./panel.atoms";
import useBulkInvite from "../hooks/useBulkInvite";
import NiceModal from "@ebay/nice-modal-react";
import { BULK_IMPORT_MODAL } from "modals/modal_names";
import BulkInviteEmptyState from "./BulkInviteEmptyState";
import InvitesListItem from "./InvitesListItem";
import { Virtuoso } from "react-virtuoso";
import InviteListItemLayout from "./InviteListItemLayout";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { COLUMN_NAMES } from "../constants";
import { checkPortals } from "@utils/routing";
import ProviderSelect from "./ProviderSelect";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useGetCurrentMerchantId } from "@hooks/common";
import { SortingOrder } from "@redux/types/sort";
import SortingButton from "@common/Button/SortingButton";

const BulkInviteContent = () => {
  const { isEnterprisePortal } = checkPortals();
  const { merchantId } = useGetCurrentMerchantId();
  const initialProviderId = isEnterprisePortal ? merchantId : 0;

  const {
    data,
    isLoading,
    areAllSelected,
    actions,
    importedRowsInit,
    toggleSelectItem,
    toggleSelectAll,
    addInvite,
    editInvite,
    entryIsUnique,
    providerId,
    setProviderId,
    sorting,
  } = useBulkInvite(initialProviderId);

  const showUploadModal = () =>
    NiceModal.show(BULK_IMPORT_MODAL, {
      handleImport: importedRowsInit,
    });

  const isEmpty = data.length === 0;
  return (
    <BulkInviteContainer paddingReduction={isEnterprisePortal}>
      {!isEnterprisePortal && (
        <FadeUpWrapper
          delay={50}
          containerProps={{ sx: { marginBottom: "20px" } }}
        >
          <ProviderSelect
            providerId={providerId}
            setProviderId={setProviderId}
          />
        </FadeUpWrapper>
      )}
      <BulkUploadHeader total={data.length} handleUpload={showUploadModal} />
      {!isEmpty && (
        <InvitesListHeader
          checked={areAllSelected}
          onSelect={toggleSelectAll}
          disabled={isLoading}
          sorting={sorting}
        />
      )}

      <InviteListContainer data-testid="invite-list-container">
        {isEmpty && !isLoading && (
          <BulkInviteEmptyState handleUpload={showUploadModal} />
        )}
        {isLoading && <InvitesLoadingState />}
        {!isEmpty && !isLoading && (
          <Virtuoso
            style={{
              height: "100%",
              overflowX: "hidden",
              overflowY: "scroll",
            }}
            data={data}
            initialItemCount={data.length}
            totalCount={data.length}
            itemContent={(index, element) => {
              if (!Array.isArray(element)) return null;
              const [key, value] = element;
              const { merchantName } = value;
              const isUnique = entryIsUnique(merchantName);
              return (
                <InvitesListItem
                  itemKey={key}
                  {...value}
                  isUnique={isUnique}
                  onSelect={() => toggleSelectItem(key)}
                  onClick={() => editInvite(key, value)}
                />
              );
            }}
          />
        )}
      </InviteListContainer>

      <BulkInviteActions actions={actions} />
    </BulkInviteContainer>
  );
};

export default BulkInviteContent;

const InvitesListHeader = ({
  checked,
  disabled,
  onSelect,
  sorting,
}: {
  checked: boolean;
  disabled: boolean;
  onSelect: VoidFunction;
  sorting: {
    attribute: string;
    order: SortingOrder;
    toggleSorting: (attribute?: string) => void;
  };
}) => {
  const { isMobileView } = useCustomTheme();
  const { attribute, order, toggleSorting } = sorting;

  return (
    <InviteListItemLayout
      checked={checked}
      disabled={disabled}
      name="select-all"
      onSelect={onSelect}
      dataTestId="bulk-invite-sortable-header"
      sx={{
        container: {
          // adjust width to align items and compensate the scrollbar
          marginRight: "10px",
        },
      }}
    >
      {isMobileView ? (
        <TextItem>{COLUMN_NAMES.join(" / ")}</TextItem>
      ) : (
        COLUMN_NAMES.map((name) => (
          <TextItem
            key={name}
            gap="8px !important"
            sx={{ userSelect: "none", flexGrow: 1, flexBasis: 0 }}
          >
            {name}{" "}
            <SortingButton
              active={attribute === name}
              order={order}
              onClick={() => toggleSorting(name)}
            />
          </TextItem>
        ))
      )}
    </InviteListItemLayout>
  );
};
