import { showMessage } from "@common/Toast";
import {
  blockTransaction,
  unblockTransaction,
  voidTransaction,
} from "@services/api/riskProfile/riskProfileActions";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useQueryClient } from "react-query";

export type TRiskAction = "blocked" | "voided" | "false_positive";
type TUpdateAction = (id: string, newStatus: TRiskAction) => Promise<void>;

const useUpdateStatus = (queryKey: string, merchantId: number) => {
  const { updateData, revertChange } = useUpdateValue(queryKey);
  const queryClient = useQueryClient();
  const { isDesktopView } = useCustomTheme();

  const updateStatusAction: TUpdateAction = async (id, newStatus) => {
    try {
      await queryClient.cancelQueries(queryKey);

      switch (newStatus) {
        case "blocked":
          updateData(id, { key: "isBlocked", value: true });
          await blockTransaction({ merchantId: merchantId, id: id });
          break;
        case "voided":
          updateData(id, { key: "processingStateName", value: "voided" });
          await voidTransaction(id);
          break;
        case "false_positive":
          updateData(id, { key: "isFalsePositive", value: true });
          await unblockTransaction(merchantId, id);
          break;
        default:
          break;
      }
      queryClient.refetchQueries("get-transactions-risk-profile");
      queryClient.refetchQueries("get-risk-transactions-list");
      queryClient.refetchQueries("has-more-transactions");
      queryClient.refetchQueries("acquirer-processing-transactions");

      showMessage(
        "Success",
        "Transaction status updated successfully",
        isDesktopView,
        "Action completed",
      );
    } catch (error) {
      revertChange();
      showMessage("Error", "Whoops.. an error occured. Please try again");
    }
  };

  return { updateStatusAction };
};

type TKeyValue = { key: string; value: any };

export const useUpdateValue = (queryKey: string) => {
  const queryClient = useQueryClient();
  const previousData = queryClient.getQueriesData(queryKey as string);

  const updateData = (transactionID: string, { key, value }: TKeyValue) => {
    const updateHandler = (oldData: any) => {
      if (!oldData?.data || !Array.isArray(oldData.data)) return oldData;

      const updatedData = oldData.data.map((transactionsGroup: any) => {
        return {
          ...transactionsGroup,
          transactions:
            transactionsGroup?.transactions?.map((thx: any) => {
              if (thx?.id !== transactionID) return thx;
              return {
                ...thx,
                [key]: value,
              };
            }) || [],
        };
      });
      return {
        ...oldData,
        data: updatedData,
      };
    };

    queryClient.setQueriesData(queryKey, updateHandler);
  };

  const revertChange = () => {
    previousData.forEach(([queryKey, queryData]) =>
      queryClient.setQueriesData(queryKey as string, queryData),
    );
  };

  return { updateData, revertChange };
};

export default useUpdateStatus;
