interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const CheckMarkIcon = ({
  width = 16,
  height = 13,
  fill = "#039855",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.8571 16.0002L1.66663 9.15572M21.6666 1.3335C17.331 5.93821 14.5268 8.91653 10.7916 12.8835"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMarkIcon;
