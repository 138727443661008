import { TThreadInfo, Replies } from "features/Minibuilders/Conversations/types";
import React, { ReactNode, createContext, useContext } from "react";
import { IParsedData } from "../../data.types";
import { FocuseOnSectionParams } from "../../hooks/useSectionFocusView";

export type TActionDelayer = {
  challengeId: number;
  primaryActionLabel: string;
  title: string;
  body: string;
  fireAction: () => void;
  challengeTypeName?: string;
};
export type TChallengeActions = {
  updateThreads: (threads: Array<TThreadInfo>) => void;
  openConversationHandler: (params: {
    name: string;
    paths: { avatars: string[]; pathName: string }[];
    id?: number;
    defaultMessage?: string;
    challengeId?: number;
    openThread?: Replies;
    forceTopicOpen?: boolean;
    disableGlobalLauncher?: boolean;
  }) => void;
  focusOnSection: (_: FocuseOnSectionParams) => void;
  merchantData: IParsedData;
  isAcquirerPortal: boolean;
  isEnterprise?: boolean;
  actionsDelayer: (props: TActionDelayer) => void;
};

type ChallengeContextType = TChallengeActions | undefined;

const ChallengeContext = createContext<ChallengeContextType>(undefined);

export const useChallengeContext = (): ChallengeContextType =>
  useContext(ChallengeContext);

export const ChallengeProvider: React.FC<{
  actions: TChallengeActions;
  children: ReactNode;
}> = ({ children, actions }) => (
  <ChallengeContext.Provider value={actions}>
    {children}
  </ChallengeContext.Provider>
);
