import { palette } from "@palette";
import OtpInput from "react-otp-input";
import { Box } from "@mui/material";

type OTPInputProps = {
  OTPValue: string;
  onOTPValueChange: (value: string) => void;
  length: number;
  placeholder: string;
  error?: boolean;
  onComplete?: (value: string) => void;
};

const OTPInput = ({ OTPValue, onOTPValueChange, length }: OTPInputProps) => {

  return (
    <Box sx={OTPStyle}>
      <OtpInput
        value={OTPValue}
        onChange={onOTPValueChange}
        numInputs={length}
        renderInput={(props, index) => (
          <input
            {...props}
            inputMode="numeric"
            autoFocus={index === 0}
            type="number"
            data-testid={`otp-input-${index}`}
          />
        )}
      />
    </Box>
  );
};

const OTPStyle = {
  "& div": {
    gap: "8px",
  },
  "& input": {
    marginTop: 0,
    borderRadius: "8px",
    border: `solid 2px ${palette.neutral[20]}`,
    backgroundColor: "#FDFDFD",
    minWidth: "44px",
    height: "45px",
  },
};

export default OTPInput;
