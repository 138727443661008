import { CopyIcon } from "@assets/icons/RebrandedIcons";
import { Box, SxProps, styled } from "@mui/material";
import { palette } from "@palette";
import { useEffect, useState } from "react";
import { Text } from "./Text";

type Props = {
  text: string;
  hidden?: boolean;
  sx?: SxProps;
  label?: string;
  customCopySx?: SxProps;
  testId?: string;
};

const CopyButton = ({
  text,
  hidden,
  sx,
  label,
  customCopySx,
  testId,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigator?.clipboard?.writeText(text);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (isCopied)
    return (
      <Box
        sx={{
          backgroundColor: "#fff",
          right: 0,
          paddingRight: "8px",
          paddingLeft: "8px",
          paddingY: "12px",
          ...customCopySx,
        }}
      >
        <Text
          color={palette.neutral[80]}
          fontWeight="book"
          sx={{ userSelect: "none" }}
        >
          {label || "Link copied!"}
        </Text>
      </Box>
    );

  return (
    <Button
      sx={{ ...sx }}
      type="button"
      data-testid={testId}
      hidden={hidden || !text}
      onClick={handleCopy}
    >
      <CopyIcon />
    </Button>
  );
};

const Button = styled("button")<{ hidden: boolean }>(({ hidden }) => ({
  cursor: "pointer",
  background: "transparent",
  outline: "none",
  border: "none",
  "&:hover rect": {
    fill: "#ECECE9",
  },
  ...(hidden && {
    display: "none",
  }),
}));

export default CopyButton;
