import moment from "moment";

export const getLastMonthDetails = () => {
  const currentDate = new Date();

  currentDate.setDate(1);
  currentDate.setDate(currentDate.getDate() - 1);

  const lastDayNumber = currentDate.getDate();
  currentDate.setDate(1);

  const prevMonth = moment().subtract(1, 'months').format("MMMM");

  return {
    lastMonthName: moment().month(currentDate.getMonth()).format("MMMM"),
    firstDayNumber: 1,
    lastDayNumber: lastDayNumber,
    prevMonth:prevMonth
  };
};

export const getLastWeekDetails = () => {
  const currentDate = new Date();
  const lastDay = new Date(currentDate);
  lastDay.setDate(currentDate.getDate() - 1);

  const firstDay = new Date(lastDay);
  firstDay.setDate(lastDay.getDate() - 6);

  return {
    firstDayNumber: firstDay.getDate(),
    firstDayMonth: moment().month(firstDay.getMonth()).format("MMMM"),
    lastDayMonth: moment().month(lastDay.getMonth()).format("MMMM"),
    lastDayNumber: lastDay.getDate(),
    firstDayUnix: moment(firstDay).unix(),
    lastDayUnix: moment(lastDay).unix(),
  };
};

export const getYesterdayDetails = () => {
  const yesterday = moment().subtract(1, "days");

  const startOfYesterday = yesterday.startOf("day");
  const endOfYesterday = yesterday.endOf("day");

  return {
    yesterday,
    startOfYesterday,
    endOfYesterday,
  };
};

export const getMonthRange = (month: "current" | "prev") => {
  let startDate;
  let endDate;
  if (month === "current") {
    startDate = moment().startOf("month").unix();
    endDate = moment().unix();
  } else {
    startDate = moment().subtract(1, "months").startOf("month").unix();
    endDate = moment().startOf("month").unix() - 1;
  }
  return { startDate, endDate };
};

export const convertToTimezone= (date: number | string, timzone: string) => {
  //convert the unix date and format it 
  const timeInZone = moment.unix(date as number).tz(timzone);
  const formattedDate =new Date(timeInZone.format("YYYY-MM-DD HH:mm:ss"))
  //return the converted date in unix format
  return moment(formattedDate).unix();
}