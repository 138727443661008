// Merchant Portal Modals
export const NEW_ACCOUNT_MODAL = "new-account";
export const CREATE_EVENT_MODAL = "create-event";
export const ACCOUNT_SELECTION_MODAL = "account-selection";
export const FUNDRAISER_SUCCESS_MODAL = "fundraiser-success";
export const PAYMENT_FORM_SUCCESS_MODAL = "payment-form-success";
export const CREATE_FUNDRAISER_MODAL = "create-fundraiser";
export const CREATE_PRE_BUILD_FORM_MODAL = "create-pre-build-forms";
export const CREATE_PAYMENT_FORM_MODAL = "create-payment-forms";
export const CAMPAIGN_DETAILS_MODAL = "campaign details modal";
export const EDIT_FUNDRAISER_MODAL = "edit-fundraiser";
export const EDIT_PAYMENT_FORM_MODAL = "edit-payment-form";
export const EDIT_EVENT_MODAL = "edit-event";
export const CREATE_SWEEPSTAKES_MODAL = "create-sweepstakes";
export const CREATE_MEMBERSHIP_MODAL = "create-membership";
export const EDIT_MEMBERSHIP_MODAL = "edit-membership";
export const TERMS_OF_SERVICE_MODAL = "terms-of-service";
export const CATEGORIES_DETAILS_MODAL = "categories-details";
export const CREATE_MEMBERSHIP_SUCCESS_MODAL = "create-membership-success";
export const SWEEPSTAKE_SUCCESS_MODAL = "sweepstake-success";
export const CREATE_EVENT_SUCCESS_MODAL = "create-event-success";
export const CREATE_INVOICE_MODAL = "create-invoice";
export const EDIT_INVOICE_MODAL = "edit-invoice";
export const CREATE_INVOICE_SUCCESS_MODAL = "create-invoice-success";
export const CHECKOUT_MODAL = "checkout";
export const TRANSACTION_PROCESSED_SUCCESSFULLY_MODAL =
  "transaction-processed-successfully";
export const THANK_YOU_MODAL = "thank-you";
export const MERCHANT_VIEW_CUSTOMER = "merchant-portal-view-customer";
export const MERCHANT_PORTAL_REFUND_MODAL = "merchant-portal-refund";
export const MERCHANT_PORTAL_CANCEL_MODAL = "merchant-portal-cancel";
export const MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL =
  "merchant-portal-mark-as-fraud";
export const MERCHANT_PORTAL_SEND_RECEIPT_MODAL =
  "merchant-portal-send-receipt";
export const MERCHANT_PORTAL_MODIFY_RECURRING_MODAL =
  "merchant-portal-modify-recurring";
export const MERCHANT_PORTAL_MAKE_RECURRING_MODAL =
  "merchant-portal-make-recurring";
export const MERCHANT_PORTAL_VIEW_TRANSACTION_MODAL =
  "merchant-portal-view-transaction";
export const MERCHANT_PORTAL_SHARE_MODAL = "merchant-portal-share";
export const LOGOUT_MODAL = "logout-modal";
export const REVENUES_PRODUCT_MODAL = "revenues-product-modal";
export const PUBLIC_FORM_PRODUCT_INFO_MODAL = "public-form-product-info";
export const CHANGE_EMAIL_MODAL = "change-email";
export const MERCHANT_EDIT_CUSTOMER = "merchant-edit-customer";
export const MERCHANT_ADD_CUSTOMER = "merchant-add-customer";
export const EXPIRED_PAYMENT_FORM_MODAL = "expired-payment-form";
export const NOT_ABLE_PROCESS_PAYMENT_FORM_MODAL =
  "not-able-process-payment-form";
export const MINIBUILDER_CREATE_AMOUNT = "minibuilder-create-amount";
export const NOTIFICATION_SIDE_PANEL = "notification-side-panel";
export const MINIBUILDER_EDIT_ANY_AMOUNT = "minibuilder-edit-any-amount";
export const MINIBUILDER_CREATE_SUBSCRIPTION =
  "minibuilder-create-subscription";
export const MINIBUILDER_CREATE_SWEEPSTAKE_AMOUNT =
  "minibuilder-create-sweepstake_amount";
export const PEP_HISTORY_MODAL = "pep-history-modal";
export const OFAC_CHECK_MODAL = "ofac-check-modal";

export const NO_ACCOUNT_MODAL = "no-account";
export const TRANSFER_FILTER_PANEL = "tranfer-filter-panel";
export const RISK_MONITOR_MARK_ALL_AS_OK_MODAL = "risk-mark-all-as-ok-modal";
export const RISK_MONITOR_TOGGLE_TRIGGER_MODAL = "risk-toggle-trigger-modal";
export const MANAGE_RISK_TRIGGER_MODAL = "manage-risk-trigger-modal";

// Provider Portal Modals
export const MERCHANT_PREVIEW_PANEL_MODAL = "merchant-preview-panel";
export const BANK_ACCOUNT_STATUS_APPROVAL_MODAL =
  "bank-account-status-approval";
export const BANK_ACCOUNT_STATUS_DECLINE_MODAL = "bank-account-status-decline";
export const MERCHANT_VIEW_TRANSACTION_MODAL =
  "merchant-view-transaction-portal";
export const REMOVE_FROM_WATCHLIST_MODAL = "remove-from-watchlist";
export const UPLOAD_STATEMENT_MODAL = "upload-statement";
export const PROCESSING_REFUND_TRANSACTION_MODAL =
  "processing-refund-transaction";
export const PROCESSING_CANCEL_TRANSACTION_MODAL =
  "processing-cancel-transaction";
export const EXPEDITE_TRANSFER_MODAL = "expedite-transfer-modal";
export const MASQUERADE_MODAL = "masquerade";
export const TRANSFER_MONEY_MODAL = "transfer-money";
export const PROCESSING_SEND_RECEIPT_MODAL = "processing-send-receipt";
export const PROCESSING_MAKE_RECURRING_MODAL = "processing-make-recurring";
export const PROCESSING_MODIFY_RECURRING_MODAL = "processing-modify-recurring";
export const PROCESSING_VIEW_CUSTOMER_MODAL = "processing-view-customer";
export const MOVE_TRANSACTION_MODAL = "move-transaction";
export const DOWNLOAD_REPORT_MODAL = "download-report";
export const ENTERPRISE_BETA_BANNER = "enterprise-beta-banner";

// Acquirer Portal Modals
export const ACQUIRER_VIEW_TRANSACTION_MODAL =
  "acquirer-merchant-view-transactions";
export const ENTERPRISE_NOT_AVAILABLE_MODAL =
  "acquirer-enterprise-not-available";
export const EDIT_MERCHANT_INFO_MODAL = "edit-merchant-info-modal";
export const EDIT_PRIMARY_ACCOUNT_HOLDER = "edit-primary-account-holder";
export const UPDATE_PRIMARY_ACCOUNT_HOLDER_EMAIL =
  "update-primary-account-holder-email";

export const MANAGE_BANK_ACCOUNTS_MODAL = "manage-bank-accounts";
export const MANAGE_BUSINESS_OWNERS_MODAL = "manage-business-owners";
export const EDIT_BUSINESS_OWNER_MODAL = "edit-business-owner";
export const EDIT_MERCHANT_FEES_MODAL = "edit-merchant-fees-modal";
export const IP_PROBE_ADDITIONAL_INFO_MODAL = "ip-probe-additional-info-modal";

export const BANK_ACCOUNTS_MODAL = "bank-accounts-modal";
export const EDIT_BANK_ACCOUNT_MODAL = "edit-bank-account";

export const APPROVE_MERCHANT_MODAL = "approve-merchant-modal";
export const MERCHANT_INITIAL_APPROVAL_MODAL =
  "merchant-initial-approval-modal";
export const DECLINE_MERCHANT_MODAL = "decline-merchant-modal";
export const SPONSOR_DECLINE_MERCHANT_MODAL = "sponsor-decline-merchant-modal";
export const MARK_MERCHANT_AS_PENDING_MODAL = "mark-merchant-as-pending-modal";
export const SUSPEND_MERCHANT_MODAL = "suspend-merchant-modal";
export const PEEK_MODE_MODAL = "peak-mode-modal";
export const PASSWORD_EXPIRED_MODAL = "password-expired-modal";
export const TRANSACTION_INFO_MODAL = "transaction_info_modal";
export const DOWNLOAD_REPORT_MODAL_MOBILE = "download_report_modal";
export const MISSING_PERMISSION_POP_UP = "missing_permission_pop_up";
export const CONNECTION_ERROR_MODAL = "connection-error-modal";

export const CREATE_MERCHANT_PANEL_MODAL = "create-merchant-panel";
export const CREATE_ENTERPRISE_PANEL_MODAL = "create-enterprise-panel";
export const CAMPAIGN_ANALYTICS_PANEL = "campaign-analytics-panel";
export const CUSTOMERS_ANALYTICS_PANEL = "customers-analytics-panel";
export const CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL =
  "create-primary-account-holder";
export const CREATE_BUSINESS_PROFILE_MODAL = "create-business-profile";
export const CREATE_MERCHANT_AGREEMENT_MODAL =
  "create-merchant-agreement-modal";
export const MERCHANT_AGREEMENT_UPDATE_MODAL =
  "merchant-agreement-update-modal";
export const UNAPPROUVED_BANK_ACCOUNT_MESSAGE_MODAL =
  "Unapprouved-Bank-Account-Message-Modal";
export const TRANSACTION_RISK_MODAL = "transaction-risk-profile-modal";

export const ENTERPRISE_PREVIEW_PANEL = "enterprise-preview-panel";
export const EDIT_ENTERPRISE_INFO_MODAL = "edit-enterprise-info-modal";
export const BUSINESS_PROFILE_MISSING_FIELDS_MODAL =
  "business-profile-missing-fields-modal";

export const PROFILE_MODAL = "profile-modal";
export const EDIT_ENTERPRISE_FEES_MODAL = "edit-enterprise-fees-modal";
export const EDIT_ENTERPRISE_MCC_MODAL = "edit-enterprise-mcc-modal";
export const ENTERPRISE_CREATED = "enterprise-created";
export const DISCARD_CHANGE_MODAL = "discard-modal-change";

export const TEAM_MEMBER_PANEL_MODAL = "team-member-panel-modal";

export const MERCHANT_AGREEMENT_PANEL = "merchant-agreement-panel";
export const SIGN_AGREEMENT_MODAL = "sign-agreement-modal";
export const UPLOAD_SIGNATURE_MODAL = "upload-signature-modal";
export const MANUAL_SIGN_AGREEMENT_MODAL = "manual-sign-agreement-modal";
export const GENERATE_SIGNATURE_MODAL = "generate-signature-modal";
export const VERIFY_ACCOUNT_HOLDER_V2 = "verify-account-holder_v2";
export const PEP_CONFIRM_MATCH_MODAL = "pep-confirm-match-modal";
export const NOTIFY_MERCHANT_MODAL = "notify-merchant-modal";
export const OFAC_CONFIRM_MATCH_MODAL = "ofac-confirm-match-modal";
export const FILE_PREVIEW_MODAL = "file-preview-modal";
export const ADD_TEAM_MEMBER_MODAL = "add-team-member-modal";
export const CHANGE_PROFILE_STATUS_MODAL = "change-profile-status-modal";
export const CONFIRMATION_POP_UP = "confirmation-pop-up";
export const NOTIFY_MERCHANT_V2 = "notify-merchant-modal-v2";
export const EXPORT_VIEW_PANEL = "export-view-panel";
export const EDIT_AVATAR_MODAL = "edit-avatar-modal";
export const PAH_UPLOADER_PREVIEW_MODAL = "pah-uploader-preview-modal";
export const ENTERPRISE_CONFIGURATION_MODAL = "enterprise-configuration-modal";
export const INTRODUCE_ADVANCED_FORM_BUILDER_MODAL =
  "introduce-advanced-form-builder-modal";
export const TABLE_FILTERS_MODAL = "table-filters-modal";
export const RISK_MONITOR_FILTERS_MODAL = "risk-monitor-filters-modal";
export const ADVANCED_BUILDER_SAVE_DRAFT_MODAL =
  "advanced-builder-save-draft-modal";
export const ADVANCED_BUILDER_ERROR_MODAL = "advanced-builder-error-modal";
export const ADVANCED_BUILDER_DELETE_CUSTOM_COLOR_MODAL =
  "advanced-builder-delete-custom-color-modal";
export const ADVANCED_BUILDER_PUBLISH_MODAL = "advanced-builder-publish-modal";
export const ADVANCED_BUILDER_SCREEN_SIZE_WARNING_MODAL =
  "advanced-builder-screen-size-warning-modal";
export const DELETE_CONFIRMATION_MODAL = "delete-confirmation-modal";
export const ADVANCED_BUILDER_EXIT_MODAL = "advanced-builder-exit-modal";

export const GENERATE_API_KEY_MODAL = "generate-api-key-modal";
export const API_KEY_SUCCESS_MODAL = "api-key-success-modal";
export const DEVELOPER_API_PANEL = "developer-api-panel";

export const TRANSACTION_NOT_FOUND = "transaction-not-found";
export const MERCHANT_BULK_ACTIONS_MODAL = "merchant-bulk-actions-modal";
export const MERCHANT_BULK_INVITE = "merchant-bulk-invite";
export const BULK_IMPORT_MODAL = "csv-bulk-import";
export const DISPUTE_PREVIEW_PANEL = "dispute-preview-panel";

export const PREVIEW_INVITATION_MODAL = "preview-invitation-modal";
export const RESPOND_TO_DISPUTE_MODAL = "respond-to-dispute-modal";
export const EDIT_INVITE_MODAL = "edit-invite-modal";
export const DISPUTE_ADD_A_NOTE_MODAL = "dispute-add-a-note-modal";
export const DISPUTE_CONFIRM_SUBMISSION_POPUP =
  "dispute-confirm-submission-popup";
export const DISPUTE_FRAUD_MODAL = "dispute-fraud-modal";
export const SEND_INVITATIONS_MODAL = "send-invitations-modal";
export const CHALLENGES_GENERAL_MODAL = "challenges_general_modal"
