import NiceModal from "@ebay/nice-modal-react";
import { NOTIFICATION_SIDE_PANEL } from "modals/modal_names";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useManageQueryRedirect() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const notificationMessageID = queryParams.get("notificationMessageID");

  useEffect(() => {
    if (notificationMessageID) {
      NiceModal.show(NOTIFICATION_SIDE_PANEL, { notificationMessageID });
      queryParams.delete("notificationMessageID");
      navigate({ search: queryParams.toString() }, { replace: true });
    }
  }, [notificationMessageID]);
  return;
}
