import { BaseModal } from "@common/Modal";
import { DialogProps } from "@mui/material";
import { SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";

export const Wrapper = ({
  open,
  children,
  onClose,
  scroll,
  ...props
}: {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  scroll?: DialogProps["scroll"];
  sx?: any;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  if (!isDesktop) {
    return (
      <SwipeableDrawer
        open={open}
        anchor="bottom"
        onClose={onClose}
        onOpen={() => undefined}
        sx={{ zIndex: 1301 }}
        PaperProps={{
          sx: {
            borderRadius: "24px 24px 0 0",
            pt: "8px",
            maxHeight: "90%",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&::-webkit-scrollbar-track": {
              display: "none",
            },
          },
        }}
      >
        {children}
      </SwipeableDrawer>
    );
  }

  return (
    <BaseModal
      open={open}
      scroll={scroll}
      PaperProps={{
        style: {
          background: "#FBFBFB",
          borderRadius: "12px",
          width: "90%",
          maxWidth: "700px",
        },
        sx: {
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            display: "none",
          },
        },
      }}
      sx={{
        ...props.sx,
        ".MuiDialogActions-root": {
          backgroundColor: "#FBFBFB",
        },
      }}
    >
      {children}
    </BaseModal>
  );
};
