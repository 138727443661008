import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";

const Title = styled(Text)(() => ({
  padding: "12px 16px",
  color: palette.black[300],
  fontWeight: 350,
  lineHeight: "16.8px",
}));

const Wrapper = styled(Stack)(() => ({
  position: "relative",
  borderRadius: "16px",
  background: "rgba(251, 251, 251, 0.75)",
  boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
  backdropFilter: "blur(50px)",
  flexDirection: "column",
  flexGrow: 1,
  width: "615px",
  alignItems: "stretch",
}));

export { Title, Wrapper };
