import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useGetPdfSections } from "./hooks/useGetPdfSections";
import PdfApplicationSection, { PdfSectionType } from "./PdfApplicationSection";
import DocumentStaticPart from "./PdfStaticPart";
import PdfSignature from "./PdfSignature";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import { format } from "date-fns";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import Logo from "@assets/images/GiveLogo.png";
import GiveWhyteRegular from "@assets/fonts/GiveWhyte/ttf/GiveWhyte-Regular.ttf";
import GiveWhyteMedium from "@assets/fonts/GiveWhyte/ttf/GiveWhyte-Medium.ttf";
import GiveWhyteBold from "@assets/fonts/GiveWhyte/ttf/GiveWhyte-Bold.ttf";
import { palette } from "@palette";

Font.register({
  family: "Give Whyte",
  fonts: [
    {
      src: GiveWhyteRegular,
      fontWeight: "normal",
    },
    {
      src: GiveWhyteMedium,
      fontWeight: "medium",
    },
    {
      src: GiveWhyteBold,
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    fontFamily: "Give Whyte",
  },
  page: {
    flexDirection: "column",
    padding: "20px",
  },
  pageRest: {
    flexDirection: "column",
    padding: "20px",
    paddingBottom: "40px",
  },
  agreementDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginBottom: 16,
  },
  merchantApplicationAgreementStyle: {
    color: palette.neutral[100],
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "120%",
    letterSpacing: "-0.18px",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Give Whyte",
  },
  logo: {
    width: 60,
    height: 22,
    margin: "0 auto",
    objectFit: "cover",
  },
  pageNumber: {
    color: palette.neutral[100],
    fontSize: "12px",
    fontWeight: "normal",
    margin: "0 auto",
  },
});

type Props = {
  data: IParsedData;
};

const MerchantAgreementPDF = ({ data }: Props) => {
  const { firstPage, secondPage, thirdPage } = useGetPdfSections(data);

  return (
    <Document style={styles.body}>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.agreementDetailsContainer}>
          <Image src={Logo} style={styles.logo} />
          <Text style={styles.merchantApplicationAgreementStyle}>
            Merchant Application and Agreement
          </Text>
        </View>
        <ApplicationSections
          sections={firstPage as PdfSectionType[]}
          page={1}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <ApplicationSections
          sections={secondPage as PdfSectionType[]}
          page={2}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <ApplicationSections
          sections={thirdPage as PdfSectionType[]}
          page={3}
        />
      </Page>
      <Page size="A4" style={styles.pageRest} break>
        <DocumentStaticPart version={data?.merchantAgreement?.tcVersion} />
        <PdfSignature
          signatureURL={
            data?.merchantAgreement?.signatureURL
              ? addSizeToImage(data?.merchantAgreement?.signatureURL, "medium")
              : ""
          }
          signedBy={data?.merchantAgreement?.signedBy as string}
          signedAt={
            data?.merchantAgreement?.msaLastAcceptedAt
              ? format(
                  new Date(data?.merchantAgreement?.msaLastAcceptedAt * 1000),
                  "MM/dd/yyyy",
                )
              : ""
          }
        />
        <View
          fixed
          style={{
            bottom: "-20px",
          }}
        >
          <Text
            style={styles.pageNumber}
            render={({ pageNumber }) => `${pageNumber}`}
          />
        </View>
      </Page>
    </Document>
  );
};

type ApplicationSectionsProps = {
  sections: PdfSectionType[];
  page: number;
};

const ApplicationSections = ({ sections, page }: ApplicationSectionsProps) => {
  return (
    <>
      <View style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {sections.map((section, index) => (
          <View key={index}>
            <PdfApplicationSection section={section as PdfSectionType} />
          </View>
        ))}
      </View>
      <View style={{ flexGrow: 1 }} />
      <Text style={styles.pageNumber}>{page}</Text>
    </>
  );
};

export default MerchantAgreementPDF;
