import * as React from "react";
import { useLocation } from "react-router-dom";
// @mui
import Box from "@mui/material/Box";
import { keyframes, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import DrawerIcon from "./DrawerListIcon";
import DrawerListItemLink from "./DrawerListItemLink";
// types
import { MenuListItem } from "types/data.types";
// utils
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import { palette } from "@palette";
import { getActive } from "utils";

const DrawerListItem = ({
  item,
  onClick,
  drawerOpen,
  small,
  isLogout,
}: {
  small?: boolean;
  item: MenuListItem;
  drawerOpen: boolean;
  isLogout?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    item?: MenuListItem,
  ) => void;
}) => {
  const { name, path, icon, index, isPreBuildFormsBuilder, itemCount } =
    item || {};
  const theme = useTheme();
  const { pathname } = useLocation();
  const isActive = getActive(path, pathname);
  const [hover, setHover] = React.useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const { isLinearGradient, gradient } = useThemeContext();

  const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 27px;
  }
  to {
    opacity: 1;
    height: 32px;
  }
`;

  const ListItemStyle = {
    mx: 1.5,
    minHeight: 27,
    maxHeight: 32,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    color: palette.neutral[80],
    borderRadius: 1,
    mb: "12px",
    animation: `${fadeIn} 250ms ease-in-out`,

    "&:hover": {
      background: palette.liftedWhite,
      width: "auto",
    },

    ...(small && {
      pr: !drawerOpen ? "auto" : isLogout && isDesktop ? "27px" : 0,
      width: isLogout ? "32px" : "auto",
      mb: "32px",
    }),

    ...(isActive &&
      !isPreBuildFormsBuilder && {
        ...(isLinearGradient
          ? {
              background: gradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            }
          : {
              color: gradient,
            }),

        "&:hover": {
          ...(isLinearGradient
            ? {
                background: gradient,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundCip: "text",
                textFillColor: "transparent",
              }
            : {
                color: gradient,
              }),
        },
      }),
  };

  return (
    <DrawerListItemLink
      to={isLogout ? pathname : path}
      active={isActive && !isPreBuildFormsBuilder}
      key={name}
      sx={{
        ...ListItemStyle,
        py: 0.5,
        ...(drawerOpen && {
          px: 1,
        }),
        cursor: isActive && !isPreBuildFormsBuilder ? "default" : "pointer",
        width: isLogout && drawerOpen ? "24px" : "auto",
        ...(itemCount && {
          overflow: "visible",
        }),
      }}
      index={index}
      onClick={(e) => onClick && onClick(e, item)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tooltip
        title={name}
        placement="right"
        disableHoverListener={drawerOpen && !isDesktop && true}
      >
        <Box
          height={isDesktop ? "24px" : "32px"}
          width={isDesktop ? "24px" : "32px"}
          mr={isLogout || !drawerOpen ? 0 : "4px"}
          component="section"
          overflow="hidden"
        >
          <DrawerIcon
            component={icon}
            active={isActive}
            open={drawerOpen}
            hover={hover}
          />
          {!drawerOpen && itemCount && (
            <Counter isFloating>{itemCount}</Counter>
          )}
        </Box>
      </Tooltip>
      {drawerOpen && !isLogout && (
        <>
          <Text
            textTransform="capitalize"
            sx={{
              minWidth: 120,
              fontWeight: 400,
              lineHeight: "100%",
              fontSize: isDesktop ? "14px" : "18px",

              "&:hover": {
                color: palette.neutral[90],
              },
            }}
          >
            {name} {itemCount && <Counter>{itemCount}</Counter>}
          </Text>
        </>
      )}
    </DrawerListItemLink>
  );
};

export default DrawerListItem;

export const Counter = ({ children, isFloating = false }: any) => {
  return (
    <span
      style={{
        backgroundColor: palette.accent[3],
        borderRadius: "20px",
        padding: "1px 8px 1px 8px",
        ...(isFloating && {
          position: "absolute",
          left: "32px",
          bottom: "4px",
          zIndex: "100",
          padding: "1px 6px 1px 6px",
        }),
      }}
    >
      <Text
        sx={{
          backgroundColor: palette.neutral.white,
          fontSize: "12px",
          fontWeight: "357",
          lineHeight: "14.4px",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: palette.neutral.white,
          ...(!isFloating && {
            display: "inline-block",
          }),
        }}
      >
        {children}
      </Text>
    </span>
  );
};
