import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { Text } from "@common/Text";
import { yupResolver } from "@hookform/resolvers/yup";
import { followLink } from "@hooks/common/documents/utils";
import useCheckEnvironment from "@hooks/common/useCheckEnvironment";
import { isEnterpriseWebsite } from "@hooks/onboarding/utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WestIcon from "@mui/icons-material/West";
import { Box, Skeleton, Stack, styled } from "@mui/material";
import { cloneDeep, isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useBusinessClassification } from "../hooks/useBusinessClassification";
import SignupFormContainer from "../SignupFormContainer";
import { useFormData } from "../SignupFormProvider";

type TMerchantClassification =
  | "profit"
  | "non_profit"
  | "individual"
  | "crowdfunding";

type Props = {
  handleUpdateStatusValue: (value: number) => void;
  handleNext: any;
  setIsStart: () => void;
  handleResetStatusbars: () => void;
  isFirstPage: boolean;
};

type TInputName = TMerchantClassification | "";

type IFormInputs = {
  name: TInputName;
  id: string;
};

const schema = Yup.object().shape({
  name: Yup.mixed<TInputName>().required(
    "Business classification selection required.",
  ),
});

function BusinessClassifications({
  handleNext,
  handleUpdateStatusValue,
  setIsStart,
  handleResetStatusbars,
  isFirstPage,
}: Props) {
  const { formData, setFormValues, resetHandler } = useFormData();
  const { data, isLoading } = useBusinessClassification(
    formData.organizationType,
  );
  const defaultValues = formData.classification;
  const methods = useForm<IFormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const saveOnUnmount = useRef<any>();
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const { watch } = methods;
  const values = watch();

  const handleSelect = ({ name }: { name?: string }) => {
    if (!name) return;
    const newValue = (values.name === name ? "" : name) as TInputName;
    methods.setValue("name", newValue);
  };
  const isEnterprise =
    isEnterpriseWebsite() ||
    formData.organizationType === "manage_submerchants";

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    setFormValues("classification", data);

    if (data.name && !isEnterprise) {
      const URL = getURL(data.name);
      !!URL && followLink(URL);
      return;
    }
    handleNext();
  };

  const handleGoBack = () => {
    setIsStart();
    resetHandler();
    methods.reset();
    handleResetStatusbars();
  };

  useEffect(() => {
    (async () => {
      const condition = !isEqual(values, saveOnUnmount.current);
      if (condition) {
        const value = await calculatePercentageNested(schema, values);
        handleUpdateStatusValue(value);
      }
      saveOnUnmount.current = cloneDeep(values);
    })();
  }, [values]);

  return (
    <FormProvider {...methods}>
      <SignupFormContainer
        title="Business classification"
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Box overflow="hidden">
          {isLoading ? (
            <CustomSkeleton />
          ) : (
            <CardContainer>
              {data?.data?.map((data: any) => (
                <Card
                  key={data?.id}
                  name={data?.name}
                  handleSelect={handleSelect}
                  title={data?.displayName}
                  description={data.description}
                  isOther={data.name === "other"}
                  isSelected={values.name === data.name}
                />
              ))}
            </CardContainer>
          )}
          <Box
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            display="flex"
            mt="20px"
          >
            {!isFirstPage ? (
              <Button
                data-testid="previous-page-button"
                background="text"
                onClick={handleGoBack}
              >
                <WestIcon /> <span> previous page</span>
              </Button>
            ) : (
              <div></div>
            )}
            <Button type="submit" disabled={!values.name}>
              Next
            </Button>
          </Box>
        </Box>
      </SignupFormContainer>
    </FormProvider>
  );
}

export default BusinessClassifications;

const Card = ({
  title,
  description,
  handleSelect,
  name,
  isOther,
  isSelected,
}: {
  description: string;
  title: string;
  name: string;
  handleSelect: (data: { name: string | undefined }) => void;
  isOther: boolean;
  isSelected: boolean;
}) => {
  return (
    <Box
      position="relative"
      borderRadius="12px"
      border="2px solid var(--neutral-20-lifted-white-200, #E1E1DE)"
      p="16px"
      bgcolor="#FFFFFF"
      sx={{
        cursor: "pointer",
      }}
      data-testid="business_classifications_card"
      onClick={() =>
        handleSelect({
          name,
        })
      }
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        mb="8px"
      >
        <Text fontSize="14px" fontWeight="book" color="#575353">
          {title}
        </Text>
        {isSelected && (
          <Box
            sx={{
              position: "absolute",
              zIndex: "1",
              right: "10px",
              top: "10px",
            }}
          >
            <CheckCircleIcon />
          </Box>
        )}
      </Stack>
      <Text fontSize="12px" color="#8F8F8F" fontWeight="book">
        {description}
      </Text>
      {isOther && (
        <Box borderRadius="12px" bgcolor="#FFFFFF">
          <RHFInput
            name="description"
            placeholder="Describe your business"
            multiline
            rows={6}
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};

const CardContainer = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  maxHeight: "400px",
  gap: "12px",
  flexDirection: "column",
  display: "flex",
  scrollbarWidth: "none", // For Firefox
  "&::-webkit-scrollbar": {
    display: "none", // For Chrome, Safari, and Opera
  },
}));

const enterpriseName: Record<TMerchantClassification, string> = {
  //TODO change the providers when it will be created in production, right now we have created just givecause
  // profit: "givepaid",
  // non_profit: "givecause",
  // individual: "givecrowd",
  // crowdfunding: "givecrowd",
  profit: "givecause",
  non_profit: "givecause",
  individual: "givecause",
  crowdfunding: "givecause",
};

const getURL = (classification: TInputName) => {
  const { getEnvironmentDomain, isProduction } = useCheckEnvironment();
  const enviroment = getEnvironmentDomain();
  if (!classification || !enterpriseName[classification]) return;
  return isProduction
    ? `https://${enterpriseName[classification]}.givepayments.com/signup?classification=${classification}`
    : `https://${enterpriseName[classification]}.${enviroment}.givepayments.com/signup?classification=${classification}`;
};

const CustomSkeleton = () => {
  return (
    <>
      <CardContainer>
        {Array(5)
          .fill(null)
          .map((i, idx) => (
            <CustomSkeletonComponent key={idx} />
          ))}
      </CardContainer>
    </>
  );
};

const CustomSkeletonComponent = styled(Skeleton)(({ theme }) => ({
  margin: "0px",
  borderRadius: "12px",
  border: "2px solid var(--neutral-20-lifted-white-200, #E1E1DE)",
  bgcolor: "#FFFFFF",
  height: "200px",
  width: "550px",
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));
