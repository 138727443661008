interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const CheckboxMark = ({
  width = 18,
  height = 18,
  fill = "#D6FCDA",
  stroke = "#039855",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="9" fill={fill} />
      <path
        d="M4.5 10.5L6.73309 12.1748C7.16178 12.4963 7.76772 12.4228 8.10705 12.0081L14.25 4.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CheckboxMark;
