import { CopyIconV2 } from "@assets/icons/RebrandedIcons";
import { ButtonBase } from "@mui/material";
import {
  selectedItem,
  structuredTreeSignal,
} from "@pages/NewAdvancedBuilder/signals";
import {
  builder,
  findNodeByKey,
  generateUniqueID,
} from "@pages/NewAdvancedBuilder/utils";
import { SyntheticEvent, useState } from "react";
import { onClickWidget } from "@pages/NewAdvancedBuilder/consts/widgets/actions";
import {
  applyBindingsFactory,
  subs,
  traverseAndProcessNodes,
} from "@pages/NewAdvancedBuilder/consts/widgets/widgetFactory";
import { ROOT_NODE_ID } from "@pages/NewAdvancedBuilder/consts/values";
import {
  encodedBlueprintId,
  encodedBlueprintIdType,
  IMAGE_GALLERY_IMAGE_BLUEPRINT,
} from "@pages/NewAdvancedBuilder/consts/widgets/helpers";
import { saveCompoundNodeDeviceTypeState } from "@pages/NewAdvancedBuilder/NodeDeviceTypePropertiesMap";
import { getDeviceTypePropertyName } from "@pages/NewAdvancedBuilder/utils/helpers";

const CopyIconButton = () => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    const newNode = builder.clonedNode(selectedItem.value.widgetType);

    if (["image_card", "image_gallery"].includes(newNode.id)) {
      const binders: any = [];
      const headNodeId = generateUniqueID();

      const cardItems = findNodeByKey(
        structuredTreeSignal.value,
        selectedItem.value.id,
      );

      const n = traverseAndProcessNodes(
        cardItems,
        (node: any, parentID: any) => {
          const decodedBlueprintId =
            encodedBlueprintId[node.blueprintID as encodedBlueprintIdType];
          const ps = newNode.blueprints[decodedBlueprintId];

          if (node.parentID === ROOT_NODE_ID) {
            //root node
            node.key = headNodeId;
          } else {
            const newKeyId = generateUniqueID();
            node.key = newKeyId;
            node.parentID = parentID;
            node.head = headNodeId;
            if (ps) {
              const isImageGalleryImageItem =
                encodedBlueprintId?.[
                  node.blueprintID as encodedBlueprintIdType
                ] === IMAGE_GALLERY_IMAGE_BLUEPRINT;
              // TODO: This was done for image gallery to persist title of image items during clone, check for more scalable solution
              const value = isImageGalleryImageItem
                ? node?.[getDeviceTypePropertyName("metaAttributes")]?.[
                    "data-imageTitle"
                  ]
                : undefined;

              const blueprint = ps(node.cssProperties, value);
              if (blueprint.bindings) {
                binders.push([
                  newKeyId,
                  applyBindingsFactory(
                    node,
                    blueprint.bindings,
                    blueprint.stateParser,
                  ),
                ]);
              }
            }
          }

          return node;
        },
        ROOT_NODE_ID,
      );

      builder.addNode(
        ROOT_NODE_ID,
        {
          ...n,
          key: headNodeId,
        },
        selectedItem.value.id,
      );

      const {
        id,
        icon: Icon,
        tabs,
        title,
        metaAttributes,
        cssProperties,
        state,
      } = newNode;
      saveCompoundNodeDeviceTypeState(Number(headNodeId), state);

      selectedItem.value = {
        id: headNodeId,
        widgetType: id,
        icon: Icon,
        tabs,
        title,
        metaAttributes,
        cssProperties,
        state,
      };

      subs.subscribe("new", binders);
    } else {
      onClickWidget(newNode, selectedItem.value.id);
    }
  };

  return (
    <ButtonBase
      disableRipple
      onClick={handleClick}
      onMouseEnter={() => setBackgroundColor("#0000000D")}
      onMouseLeave={() => setBackgroundColor("transparent")}
      sx={{
        backgroundColor: backgroundColor,
        padding: "8px",
        borderRadius: "8px",
        transition: "background-color 0.2s",
      }}
    >
      <CopyIconV2 />
    </ButtonBase>
  );
};

export default CopyIconButton;
