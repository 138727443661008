import {
  ACQUIRER_PATHS,
  ENTERPRISE_PATHS,
  MERCHANT_PATHS,
} from "@routes/paths";

// the name of route for each path
export const MappedPathName = {
  acquirer: new Map<string, string>([
    [ACQUIRER_PATHS.PROCESSING, "Processing"],
    [ACQUIRER_PATHS.PROVIDERS, "Providers"],
    [ACQUIRER_PATHS.MERCHANTS, "Merchants"],
    [ACQUIRER_PATHS.SETTINGS, "Settings"],
    [ACQUIRER_PATHS.BUSINESS_DETAILS, "Business Details"],
    [ACQUIRER_PATHS.MANAGE_TEAM, "Team"],
    [ACQUIRER_PATHS.SETTLEMENT, "Settlement"],
    [ACQUIRER_PATHS.DISPUTES, "Disputes"],
    [ACQUIRER_PATHS.TRANSFERS, "Transfers"],
    ["bank-account", "Bank Account "],
  ]),
  provider: new Map<string, string>([
    [ENTERPRISE_PATHS.TRANSACTIONS, "Transactions"],
    [ENTERPRISE_PATHS.MERCHANTS, "Merchants"],
    [ENTERPRISE_PATHS.PROCESSING, "Processing"],
    [ENTERPRISE_PATHS.SETTINGS, "Settings"],
    [ENTERPRISE_PATHS.BUSINESS_DETAILS, "Business Details"],
    [ENTERPRISE_PATHS.MANAGE_TEAM, "Team"],
    ["bank-account", "Bank Account"],
    [ENTERPRISE_PATHS.THEME_BRANDING, "Theme & Branding"],
    [ENTERPRISE_PATHS.DEVELOPER_API, "Developer API"],
    [ENTERPRISE_PATHS.TRANSFERS, "Transfers"],
  ]),
  merchant: new Map<string, string>([
    [MERCHANT_PATHS.DASHBOARD, "Dashboard"],
    [MERCHANT_PATHS.PAYMENT_FORMS, "Payment Forms"],
    [MERCHANT_PATHS.FUNDRAISERS, "Fundraisers"],
    [MERCHANT_PATHS.EVENTS, "Events"],
    [MERCHANT_PATHS.INVOICES, "Invoices"],
    [MERCHANT_PATHS.MEMBERSHIP, "Memberships"],
    [MERCHANT_PATHS.SWEEPSTAKES, "Sweepstakes"],
    [MERCHANT_PATHS.MANAGE_MONEY, "Manage Money"],
    [MERCHANT_PATHS.CUSTOMERS, "Customers"],
    [MERCHANT_PATHS.SETTINGS, "Settings"],
    [MERCHANT_PATHS.BUSINESS, "Business Details"],
    [MERCHANT_PATHS.MEDIA_LIBRARY, "Media Library"],
    [MERCHANT_PATHS.MANAGE_TEAM, "Team"],
    ["bank-account", "Bank Account"],
  ]),
};
