import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectTimeFilter,
  selectTypeFilter,
  setTimeFilter,
  setTypeFilter,
} from "@redux/slices/acquirer/transactionRiskProfile";

const TransactionListHeader = () => {
  const typeFilter = useAppSelector(selectTypeFilter);
  const timeFilter = useAppSelector(selectTimeFilter);
  const dispatch = useAppDispatch();

  const typeFilters = [
    {
      label: "All activity",
      selected: typeFilter === "all",
      onClick: () => dispatch(setTypeFilter("all")),
    },
    {
      label: "Escalations",
      selected: typeFilter === "escalations",
      onClick: () => dispatch(setTypeFilter("escalations")),
    },
  ];

  const timeFilters = [
    {
      label: "Last 24h",
      selected: timeFilter === "day",
      onClick: () => dispatch(setTimeFilter("day")),
    },
    {
      label: "Last 30d",
      selected: timeFilter === "month",
      onClick: () => dispatch(setTimeFilter("month")),
    },
  ];

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <FilterWrapper filters={typeFilters} />
      <FilterWrapper filters={timeFilters} />
    </Stack>
  );
};

type TFilterItem = {
  label: string;
  onClick: () => void;
  selected: boolean;
};

const FilterWrapper = ({ filters }: { filters: TFilterItem[] }) => {
  return (
    <Stack direction="row" gap="8px" alignItems="center" padding="0 8px">
      {filters.map(({ label, onClick, selected }) => (
        <FilterItem key={label} selected={selected} onClick={onClick}>
          {label}
        </FilterItem>
      ))}
    </Stack>
  );
};

const FilterItem = styled(Text)<{ selected: boolean }>(({ selected }) => ({
  color: selected ? palette.black[100] : palette.gray[300],
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16.8px",
  padding: "8px 16px",
  borderRadius: "100px",
  userSelect: "none",
  cursor: "pointer",

  ...(selected && {
    backgroundColor: palette.liftedWhite[100],
    cursor: "default",
  }),
}));

export default TransactionListHeader;
