import React from "react";

const getWindowArray = () => {
  const storage = localStorage.getItem("window-refID");
  return storage ? JSON.parse(storage) : [];
}

const setWindowArray = (data: string[]) => {
  localStorage.setItem("window-refID", JSON.stringify(data));
}

const removeWindow = (windowID: string) => {
  const array = getWindowArray();
  const newArray = array.filter((winID: string) => winID !== windowID);
  setWindowArray(newArray);
}

const useWindowCounter = () => {
  const [isMainWindow, setIsMainWindow] = React.useState<boolean>(true);
  const windowRefID = React.useRef<string>("");

  React.useEffect(() => {
    const newID = Date.now().toString();
    windowRefID.current = newID;
    const array = getWindowArray();

    if (array.length > 0) {
      setIsMainWindow(false);
    }
    setWindowArray([...array, newID]);

    return () => removeWindow(newID);
  }, []);

  React.useEffect(() => {
    const deleteHandler = () => removeWindow(windowRefID.current);

    window.addEventListener("beforeunload", deleteHandler);
    window.addEventListener("unload", deleteHandler);

    () => {
      window.removeEventListener("beforeunload", deleteHandler);
      window.removeEventListener("unload", deleteHandler);
    };
  }, []);

  return { isMainWindow };
}

export default useWindowCounter;
