import * as React from "react";
import { palette } from "@palette";
import Box from "@mui/material/Box";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";

interface ITrailingProps {
  pageTotal: number;
  total: number;
  totalAmount?: boolean;
}

const style = {
  display: "flex",
  flexDirection: "column",
  px: "16px",
  py: "2px",
  backgroundColor: "transparent",
};

const rowStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
};

export const Trailing: React.FC<ITrailingProps> = ({
  pageTotal,
  total = 0,
}) => {
  return (
    <Box sx={style}>
      <Box sx={rowStyle}>
        <BottomText fontSize={14} text="Page Total" paddingRight={4} />
        <BottomText fontSize={14} text={`${pageTotal.toLocaleString("en-US")} USD`} />
      </Box>
    </Box>
  );
};

function BottomText({
  children,
  text,
  ...rest
}: ITextProps & { text: string }) {
  return (
    <Text
      fontSize="12px"
      fontWeight="regular"
      color={palette.neutral[80]}
      {...rest}
    >
      {text}
      {children}
    </Text>
  );
}
