import * as React from "react";
import { palette } from "@palette";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Radio } from "@common/Radio";
import { Table, TableColumnType } from "@common/Table";
import MoveTransactionCard from "./MoveTransactionCard";
// data
import { tableData, TransactionData } from "../../data";

interface IProps {
  selectedRow?: string;
  setSelectedRow: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const MoveTransactionTable = ({
  setSelectedRow,
  selectedRow,
}: IProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const columns: TableColumnType[] = [
    {
      key: "actions",
      renderColumn: (row: TransactionData) => {
        return (
          <Box>
            <Radio
              checked={row.id === selectedRow}
              onChange={() => setSelectedRow(row.id)}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 20,
                  height: 20,
                },

                "&.Mui-checked": {
                  color: palette.primary.main,
                  "& .MuiTableRow-root": {
                    backgroundColor: palette.primary[50],
                  },
                },
              }}
            />
          </Box>
        );
      },
    },
    {
      key: "name",
      sortable: false,
      title: "Name",
      hasExpandButton: false,
      renderColumn: (row: TransactionData) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {row.title.split(",")[0]}
          </Text>
          <Text color={palette.neutral[800]}>{row.email}</Text>
        </Box>
      ),
    },
    {
      key: "date",
      sortable: false,
      title: "Customer since",
      renderColumn: (row: TransactionData) => (
        <Text fontWeight="medium" color={palette.neutral[800]}>
          {`${row.time.split(" ")[0]} ${row.time.split(" ")[1]}, ${
            row.time.split(" ")[2]
          }`}
        </Text>
      ),
    },
  ];

  return (
    <Box sx={tableWrapper}>
      {isDesktop ? (
        <Table small data={tableData} columns={columns} selectable={true} />
      ) : (
        <Stack gap={1} sx={{ width: "100%" }}>
          {tableData.map((row, index) => (
            <MoveTransactionCard
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              key={index}
              id={row.id}
              email={row.email}
              Name={row.title.split(",")[0]}
              date={`${row.time.split(" ")[0]} ${row.time.split(" ")[1]}, ${
                row.time.split(" ")[2]
              }`}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

const tableWrapper = {
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};
