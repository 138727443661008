import { Text } from "@common/Text";
import { InputSelector } from "./SidePanelContent/InputSelector";
import { useAppTheme } from "@theme/v2/Provider";

export type FontKeys =
  | "Roboto"
  | "Open Sans"
  | "Montserrat"
  | "Lato"
  | "Poppins"
  | "Source Sans Pro"
  | "Raleway"
  | "Noto Sans"
  | "Roboto Slab"
  | "Inter"
  | "Merriweather"
  | "Playfair Display";

export const optionsMap: { [K in FontKeys]: string } = {
  Roboto: `"Roboto", sans-serif`,
  "Open Sans": `"Open Sans", sans-serif`,
  Montserrat: `"Montserrat", sans-serif`,
  Lato: `"Lato", sans-serif`,
  Poppins: `"Poppins", sans-serif`,
  "Source Sans Pro": `"Source Sans 3", sans-serif`,
  Raleway: `"Raleway", sans-serif`,
  "Noto Sans": `"Noto Sans", sans-serif`,
  "Roboto Slab": `"Roboto Slab", serif`,
  Inter: `"Inter", sans-serif`,
  Merriweather: `"Merriweather", serif`,
  "Playfair Display": `"Playfair Display", serif`,
};

function isFontKey(key: any): key is FontKeys {
  return [
    "Roboto",
    "Open Sans",
    "Montserrat",
    "Lato",
    "Poppins",
    "Source Sans Pro",
    "Raleway",
    "Noto Sans",
    "Roboto Slab",
    "Inter",
    "Merriweather",
    "Playfair Display",
  ].includes(key);
}

export type optionType = {
  value: FontKeys;
  label: string;
};

export const options: optionType[] = Object.entries(optionsMap).reduce(
  (acc, [key, value]) => {
    if (isFontKey(key)) {
      acc.push({ value: value, label: key } as optionType);
    }
    return acc;
  },
  [] as optionType[],
);

export const FontFamilySelector = ({
  selectedValue,
  onSelectItem,
  defaultValue,
}: any) => {
  const theme = useAppTheme();
  return (
    <InputSelector
      title="Font Family"
      onSelectItem={onSelectItem}
      defaultValue={
        typeof defaultValue === "string" ? defaultValue : defaultValue?.name
      }
      options={options}
      selectedValue={selectedValue}
      listItemContent={(option: optionType) => {
        return (
          <Text
            sx={{
              fontFamily: option.value,
              color: theme.palette.text.primary,
            }}
          >
            {option.label}
          </Text>
        );
      }}
    />
  );
};
