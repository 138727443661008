import { useMemo } from "react";
import { TTimeConfig, TTimeUnits } from "@components/LineGraph/types";
import { TimeLineChartData } from "../types";
import { intervalToDuration } from "date-fns";

type KeyType = "months" | "days";
type ArrayType = Record<KeyType, number | undefined>;

const getMaxValue = (key: KeyType, array: ArrayType[]) => {
  return array.reduce((acc, v) => {
    const value = v[key];
    if (value && value > acc) return value;
    return acc;
  }, 0);
};

const useTimeConfig = (data: TimeLineChartData[]) => {
  const timeUnit: TTimeUnits = useMemo(() => {
    const datasetMaxPeriod = data.map((dataset) => {
      if (dataset.entries.length === 0) {
        return { months: undefined, years: undefined, days: 1 };
      }

      const startDate = dataset.entries[0].x;
      const endDate = dataset.entries[dataset.entries.length - 1].x;
      const duration = intervalToDuration({
        start: new Date(startDate),
        end: new Date(endDate),
      });
      const { months, days } = duration;
      return { months, days };
    });

    const maxMonthsPeriod = getMaxValue("months", datasetMaxPeriod);
    const maxDaysPeriod = getMaxValue("days", datasetMaxPeriod);

    if (maxMonthsPeriod > 12) return "year";
    if (maxDaysPeriod > 31) return "month";
    return "day";
  }, [data]);

  const timeConfig: TTimeConfig = {
    unit: timeUnit,
    minUnit: "day",
    displayFormats: {
      day: "MMM DD",
      month: "MMM",
      year: "YYYY",
    },
  };

  return { timeConfig };
};

export default useTimeConfig;
