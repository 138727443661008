import { useGetCurrentMerchantId } from "@hooks/common";
import { getTransactionChart } from "@services/api/analytics/transactions";
import moment from "moment";
import { useQuery } from "react-query";

export const useGetSettledPayments = (isModalVisible: boolean) => {
  const { merchantId } = useGetCurrentMerchantId();

  const { data } = useQuery(
    ["get-settled-payments", merchantId],
    async () => {
      const currentPostedChart = await getTransactionChart({
        filter: getSettledPaymentsFilter(new Date().getFullYear()),
        interval: "month",
      });

      const previousPostedChart = await getTransactionChart({
        filter: getSettledPaymentsFilter(prevYear),
        interval: "month",
      });

      return {
        currentPostedChart: currentPostedChart?.data || [],
        previousPostedChart: previousPostedChart?.data || [],
      };
    },
    { refetchOnWindowFocus: false, enabled: !!isModalVisible },
  );

  return { data };
};

export const prevYear = moment(new Date()).subtract(1, "years").year();

const getSettledPaymentsFilter = (year: number) => {
  return `start_date=${year}-01-01&end_date=${year + 1}-01-01`;
};
