import Skeleton, { DoubledSkeleton } from "@components/animation/Skeleton";
import { Box, Stack } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const EditProductSkeleton = () => {
  const { isMobileView } = useCustomTheme();

  return (
    <Box width="100%">
      <Skeleton width={61} height={17} borderRadius={25} />
      <Skeleton height={48} borderRadius={8} sx={{ mt: "10px", mb: 1 }} />
      <Skeleton height={200} borderRadius={8} />
      <Skeleton
        height={17}
        width={61}
        borderRadius={25}
        sx={{ mt: "10px", mb: "26px" }}
      />
      <Skeleton height={14} width={117} borderRadius={20} />
      <Skeleton height={14} borderRadius={25} sx={{ mt: "9px", mb: "6px" }} />
      <Skeleton height={14} width={303} borderRadius={20} sx={{ mb: "20px" }} />
      <Stack spacing={1} sx={{ ml: isMobileView ? 0 : "48px", mb: 3 }}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <DoubledSkeleton
              key={index}
              height={119}
              width={119}
              frequency={4}
              direction="row"
              spacing={1.2}
              borderRadius="8px"
            />
          ))}
      </Stack>
      <Skeleton
        height={12}
        width={332}
        borderRadius={8}
        sx={{ margin: "0 auto" }}
      />
      <Stack direction="row" spacing={1.5} sx={{ mt: 4 }}>
        <Skeleton height={20} width={20} borderRadius={6} />
        <Stack spacing={0.5}>
          <Skeleton height={12.25} width={168} borderRadius={17.5} />
          <Skeleton height={12.25} width={410} borderRadius={17.5} />
          <Skeleton height={12.25} width={351} borderRadius={17.5} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditProductSkeleton;
