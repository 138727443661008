import React, { memo } from "react";
import TitleComponent from "../TitleComponent";
import { Text } from "@common/Text";
import { RHFCheckbox } from "@common/Checkbox";
import { Box, Stack } from "@mui/material";
import { Input } from "@common/Input";
import { useFormContext } from "react-hook-form";
import { ComingSoonTag } from "@common/Tag";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

const valueKey = "Configuration";

function SweepStackConfiguration({ title }: { title: string }) {
  const { setValue, watch } = useFormContext();
  const values = watch()[valueKey];

  return (
    <Stack direction="column" gap={4} flexGrow={1}>
      <FadeUpWrapper delay={100}>
        <TitleComponent title={title} mb={0} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Stack direction="column" gap={2}>
          <Text fontWeight="book" fontSize="18px">
            Payment Form
          </Text>

          <RHFCheckbox
            name={`${valueKey}.multiple_winners`}
            label="Multiple winners (each ticket has a price)"
            helperText=" if ON, each ticket will have a winner. Otherwise only one winner will be selected per Sweepstake"
          />
          <Box>
            <RHFCheckbox
              name={`${valueKey}.set_entries_limit.active`}
              label="Set a limit for the number of entries that can be purchased per prize"
            />
            <TextInputBox
              title="Max entries per prize"
              isFaded={!values.set_entries_limit.active}
              value={values?.set_entries_limit?.max_entries}
              handleChange={(e: string) =>
                setValue(`${valueKey}.set_entries_limit.max_entries`, e, { shouldDirty: true })
              }
            />
          </Box>
          <TextInputBox
            title="Total tickets that can be purchased"
            value={values?.max_tickets}
            handleChange={(e: string) =>
              setValue(`${valueKey}.max_tickets`, e, { shouldDirty: true })
            }
          />
        </Stack>
      </FadeUpWrapper>
      <FadeUpWrapper delay={300}>
        <Stack direction="column" gap={2}>
          <Text fontWeight="book" fontSize="18px">
            Customer
          </Text>

          <RHFCheckbox
            name="Configuration.customer_pays_credit"
            label="Customer pays the credit card fee"
            sx={{
              "& .MuiButtonBase-root.MuiCheckbox-root": {
                height: 24,
              },
            }}
          />
          <Stack direction="column">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <RHFCheckbox
                disabled
                name="Configuration.browse_more"
                label="Browse more Payment forms"
                sx={{
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    height: 24,
                  },
                }}
              />
              <ComingSoonTag />
            </Stack>
            <Text
              color={palette.gray[300]}
              fontSize="16px"
              fontWeight="book"
              lineHeight="19.2px"
              ml={4}
            >
              Allow users to explore more of your published Payment forms.
            </Text>
          </Stack>
        </Stack>
      </FadeUpWrapper>
    </Stack>
  );
}

export default memo(SweepStackConfiguration);

const TextInputBox = ({
  title,
  isFaded,
  value,
  handleChange,
}: {
  title: string;
  isFaded?: boolean;
  value: number;
  handleChange: (val: string) => void;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      px={2}
      py={2}
      bgcolor="#ECECE9"
      borderRadius="8px"
      sx={{
        opacity: isFaded ? 0.5 : 1,
      }}
    >
      <Text fontSize="18px" color="#575353">
        {title}
      </Text>
      <Box width="82px" height="33px">
        <Input
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          value={value}
          disabled={isFaded}
          inputProps={{
            style: {
              height: "auto",
              padding: 0,
              margin: 1,
            },
          }}
          sx={{
            "& .MuiInputBase-root.Mui-disabled": {
              backgroundColor: "white",
              boxShadow: "none",
              border: "none",
            },
          }}
          fullWidth
          type="number"
        />
      </Box>
    </Box>
  );
};
