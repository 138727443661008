import React from "react";
import { palette } from "@palette";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { DropdownItem } from "@common/Select";
import { BaseModal, ModalTitle } from "@common/Modal";
import ViewTransactionBanner from "./ViewTransactionBanner";
import { Table_V2 as Table, TableColumnType } from "@common/Table";

import TableCard from "./Mobile/TableCard";
// assets
import { RefundTopIcon, PaperLightIcon, MoreIcon } from "@assets/icons";
// data
import { tableData } from "./data";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
// utils
import { parseAmount, toEnFormat } from "@utils/index";
import {
  DOWNLOAD_REPORT_MODAL,
  MOVE_TRANSACTION_MODAL,
  PROCESSING_REFUND_TRANSACTION_MODAL,
  PROCESSING_SEND_RECEIPT_MODAL,
  PROCESSING_VIEW_CUSTOMER_MODAL,
} from "modals/modal_names";
import { ActionsComponent } from "@common/Table/components/ActionsComponent";
import { HiddenComponent } from "containers/HiddenComponent";
import { shouldBeHidden } from "@constants/constants";
import { useTransactionsIOC } from "@components/ManageMoney/TransactionTable/hooks";
import { useGetTransactionsByMerchant } from "@services/api/products/transactions";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { TransactionTableRow } from "@components/ManageMoney/TransactionTable/TransactionTable";
import { TransactionTableExpandedRow } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import {
  formatDate,
  getSourceAccount,
  getType,
  mapCardType,
} from "@components/ManageMoney/TransactionTable/transactions.helpers";

type ViewTransactionProps = {
  data: {
    [x: string]: any;
  };
};

const ViewTransaction = NiceModal.create(({ data }: ViewTransactionProps) => {
  const theme = useTheme();
  const modal = useModal();
  const { t } = useTranslation(namespaces.common);
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    allRows,
    totalRows,
    page,
    handlePageChange,
    isFetching,
    invalidateCache,
  } = useTransactionsIOC(
    "get-transactions-by-merchant",
    useGetTransactionsByMerchant("get-transactions-by-merchant"),
    `filter=destinationAccount.id:${data.accID}`,
    {},
    "",
  );

  const rows = React.useMemo(() => {
    return allRows.map((x: TransactionTableRow) => {
      const factor =
        x.type === "chargeback" || x.type === "refund" || x.type === "transfer"
          ? -1
          : 1;

      return {
        ...x,
        amount: (x.charged / 100) * factor,
        fees: x.fees / 100,
        cost: x.cost / 100,
        charged: x.charged / 100,
        sourceAccount: x.sourceAccount,
        sourceMethod: x.sourceMethod,
        id: x.id,
        title: `${capitalizeFirstLetter(
          x.sourceAccount.user.firstName,
        )} ${capitalizeFirstLetter(x.sourceAccount.user.lastName)}`,
        date: x.createdAt,
        time: formatDate(x.createdAt * 1000),
        type: mapCardType(
          x.sourceMethod.type,
          x.sourceMethod.method?.isDebit ?? false,
        ),
        customerName: `${capitalizeFirstLetter(
          x.customer?.firstName,
        )} ${capitalizeFirstLetter(x.customer?.lastName)}`,
        status: x.displayStatus,
        paymentDetails: {
          nameOnCard: `${capitalizeFirstLetter(
            x.sourceAccount.user.firstName,
          )} ${capitalizeFirstLetter(x.sourceAccount.user.lastName)}`,
          merchantTaxId: x.id,
        },
      };
    });
  }, [allRows]);

  React.useEffect(() => {
    return () => {
      invalidateCache();
    };
  }, []);

  const handleClose = () => {
    modal.hide();
  };

  const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    NiceModal.show(DOWNLOAD_REPORT_MODAL, {
      title: t("transactions", { ns: namespaces.common }),
    });
  };

  const refundHandler = (row: TransactionTableRow) => {
    NiceModal.show(PROCESSING_REFUND_TRANSACTION_MODAL, {
      sourceAccount: getSourceAccount(row),
      ids: {
        transactionIDs: [row?.id],
      },
    });
  };

  const sendReceiptHandler = (row?: Record<string, any>) => {
    NiceModal.show(PROCESSING_SEND_RECEIPT_MODAL, {
      transactionID: row?.id,
      emails: [row?.sourceAccount.user.email],
      sourceAccount: getSourceAccount(row as any),
    });
  };

  const viewCustomerHandler = (row: TransactionTableRow) => {
    NiceModal.show(PROCESSING_VIEW_CUSTOMER_MODAL, {
      data: row,
    });
  };

  const moveTransactionHandler = (data: { [x: string]: any }) => {
    NiceModal.show(MOVE_TRANSACTION_MODAL, {
      isSelected: data.isSelected,
      setIsSelected: data.setIsSelected,
      resetSelected: data.setIsSelected,
    });
  };

  const columns: TableColumnType[] = [
    {
      key: "description",
      sortable: true,
      title: "Description",
      hasExpandButton: true,
      renderColumn: (row: TransactionTableRow) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {row.description}
          </Text>
          <Text color={palette.neutral[800]}>
            {formatDate(row.createdAt * 1000)}
          </Text>
        </Box>
      ),
    },
    {
      key: "displayType",
      sortable: true,
      title: t("filters.type", { ns: namespaces.common }),
      renderColumn: (row: TransactionTableRow) => (
        <Box>
          <Text
            fontWeight="medium"
            color={palette.neutral[800]}
            textTransform="capitalize"
          >
            {getType(row)}
          </Text>
        </Box>
      ),
    },
    {
      key: "cost",
      sortable: true,
      title: t("filters.amount", { ns: namespaces.common }),
      renderColumn: (row: TransactionTableRow) => (
        <Box>
          <Text fontWeight="medium" color={palette.neutral[800]}>
            {parseAmount(row.charged)}
          </Text>
          {/* <Text color={palette.neutral[800]}>
            {toEnFormat(row.balance)}
            <sup>USD</sup>
          </Text> */}
        </Box>
      ),
    },
    {
      key: "actions",
      renderColumn: (row: TransactionTableRow) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
          null,
        );
        const open = Boolean(anchorEl);

        const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        };

        return (
          <>
            <HiddenComponent hidden={shouldBeHidden.refund}>
              <ActionsComponent
                title="Refund"
                disabled={row.hideRefund}
                onClick={() => refundHandler(row)}
                icon={() => <RefundTopIcon size="20px" />}
              />
            </HiddenComponent>
            <ActionsComponent
              title="Send Receipt"
              onClick={() => sendReceiptHandler(row)}
              icon={() => <PaperLightIcon size="20px" />}
            />
            <ActionsComponent
              title="More"
              onClick={handleOpenMenu}
              icon={() => <MoreIcon size="20px" />}
            />
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPaper-root": {
                  width: "max-content",
                },
              }}
            >
              <DropdownItem onClick={() => sendReceiptHandler(row)}>
                <Text>Send Receipt</Text>
              </DropdownItem>
              <HiddenComponent hidden={shouldBeHidden.refund || row.hideRefund}>
                <DropdownItem onClick={() => refundHandler(row)}>
                  <Text>Refund Transaction</Text>
                </DropdownItem>
              </HiddenComponent>
              <DropdownItem onClick={() => viewCustomerHandler(row)}>
                <Text>View Customer</Text>
              </DropdownItem>
              {/* <DropdownItem
                onClick={() =>
                  moveTransactionHandler({
                    isSelected: isSelected,
                    setIsSelected: setIsSelected,
                    resetSelected: setIsSelected,
                  })
                }
              >
                <Text>Move Transaction</Text>
              </DropdownItem> */}
            </Menu>
          </>
        );
      },
    },
  ];

  return (
    <BaseModal
      width={"1120px"}
      {...muiDialogV5(modal)}
      sx={{
        "& .MuiDialogTitle-root": {
          height: "auto",
          paddingBottom: 0,
          alignItems: "flex-start",
        },
        ...(isSmall && {
          "& .MuiDialogContent-root": {
            padding: "24px 8px",
          },
        }),
      }}
    >
      <ModalTitle
        titleComponent={
          <Box>
            <Text
              fontSize={38}
              lineHeight="45.6px"
              style={{ fontWeight: 350 }}
              variant={isDesktop ? "h4" : "h6"}
              color={palette.neutral[80]}
            >
              {data.title || data.name}
            </Text>
            {data && data.email && (
              <Text
                fontSize={16}
                lineHeight="19.2px"
                style={{ fontWeight: 350 }}
                color={palette.neutral[60]}
              >
                {data.email}
              </Text>
            )}
          </Box>
        }
        onClose={handleClose}
      />

      <DialogContent>
        <Stack
          mb={1.5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ViewTransactionBanner merchId={data.accID} />
        </Stack>

        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isDesktop && (
            <Text
              variant="h4"
              fontSize={"28px"}
              style={{ fontWeight: 350 }}
              lineHeight="33.6px"
              color={palette.neutral[70]}
              textTransform="capitalize"
            >
              Transactions
            </Text>
          )}
          {/* {isDesktop ? (
            <Button startIcon={<DownloadIcon />} onClick={handleDownload}>
              Download Report
            </Button>
          ) : (
            <IconButton onClick={handleDownload}>
              <DownloadIcon />
            </IconButton>
          )} */}
        </Stack>
        {isDesktop ? (
          <>
            <Table
              expandable
              data={rows}
              columns={columns}
              renderExpandedRow={TransactionTableExpandedRow}
              totalRecords={totalRows}
              withPagination={totalRows > ROWS_PER_PAGE}
              page={page}
              handlePageChange={handlePageChange}
              isLoading={isFetching}
              tableTitle="Transactions"
            />
          </>
        ) : (
          <Box mt="10px" gap="10px" display="flex" flexDirection="column">
            {tableData.map((item) => (
              <TableCard
                key={item.id}
                rowData={item}
                closeModal={handleClose}
              />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </BaseModal>
  );
});

export default ViewTransaction;
