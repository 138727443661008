import * as React from "react";
import {
  Box,
  TextField,
  Autocomplete,
  debounce,
  styled,
  Paper,
  TextFieldProps,
  SxProps,
} from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { PinIcon } from "@assets/icons";
import parse from "autosuggest-highlight/parse";
import ErrorCatcher from "@common/Error/ErrorCatcher";

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

type TGooglePlacesAutocompleteProps = {
  disabled?: boolean;
  label?: string;
  sx?: SxProps;
  initialValue?: string;
  reset?: boolean;
  onValueChange: (value: string) => void;
  inputProps?: TextFieldProps;
  isLoaded: boolean;
};

function GooglePlacesAutocomplete({
  disabled,
  label,
  sx,
  inputProps,
  reset,
  initialValue,
  onValueChange,
  isLoaded,
}: TGooglePlacesAutocompleteProps) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const isMounted = React.useRef<boolean>(false);
  const autocompleteService = React.useRef<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  const setInitialValue = (initialValue: string) => {
    setInputValue(initialValue);
    setValue({
      description: initialValue,
      structured_formatting: {
        main_text: initialValue,
        secondary_text: initialValue,
      },
    });
  };

  React.useEffect(() => {
    if (!initialValue && reset) {
      setValue(null);
      setInputValue("");
    }
    if (reset) {
      isMounted.current = false;
    }
  }, [reset]);

  React.useEffect(() => {
    if (!isMounted.current && initialValue) {
      setInitialValue(initialValue);
      isMounted.current = true;
    }
  }, [initialValue]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!isLoaded) return;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <ErrorCatcher errorID="Autocomplete">
      <Autocomplete
        sx={sx}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        forcePopupIcon={false}
        autoComplete
        includeInputInList
        filterSelectedOptions
        autoHighlight
        noOptionsText="Start typing to see options"
        disabled={disabled}
        openOnFocus={false}
        PaperComponent={OptionsContainer}
        value={value}
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          onValueChange(newValue?.description || "");
        }}
        onInputChange={(event: React.SyntheticEvent, newInputValue: string) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            sx={{
              "& .MuiInputBase-root": {
                "& input": {
                  padding: "0 !important",
                },
                "& > .MuiAutocomplete-endAdornment": {
                  display: "none !important",
                },

                "& .Mui-disabled": {
                  "& input": {
                    marginTop: "14px",
                  },
                },
              },
              "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                top: "15px !important",
              },
            }}
            onKeyDown={(e) => e.stopPropagation()}
            {...inputProps}
          />
        )}
        renderOption={(props, option: any) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ]),
          );

          return (
            <li {...props}>
              <Box
                minWidth={20}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <PinIcon width={20} height={20} fill={palette.neutral[400]} />
              </Box>
              <Text
                variant="body"
                color={palette.neutral[600]}
                marginLeft="8px"
              >
                {parts.map((part: any, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{
                      ...(part.highlight && {
                        color: palette.neutral[800],
                        fontWeight: 500,
                      }),
                    }}
                  >
                    {part.text}
                  </Box>
                ))}
                {", "}
                {option.structured_formatting.secondary_text}
              </Text>
            </li>
          );
        }}
      />
    </ErrorCatcher>
  );
}

const highlightedOptionStyle = {
  backgroundColor: "#F1F5F9",
  borderColor: "#DDDCE5",

  "& > svg > path": {
    fill: palette.primary.main,
  },
};

const OptionsContainer = styled(Paper)(({ theme }) => ({
  "& .MuiAutocomplete-listbox": {
    padding: "4px",
  },
  "& .MuiAutocomplete-noOptions": {
    padding: "12px 16px",
    fontSize: "14px",
    lineHeight: "20px",
    color: palette.neutral[600],
  },
  "& .MuiAutocomplete-listbox > .MuiAutocomplete-option": {
    padding: "4px 8px",
    borderRadius: "4px",
    border: "1px solid transparent",
  },
  "& .MuiAutocomplete-listbox > .MuiAutocomplete-option.Mui-focused": {
    ...highlightedOptionStyle,
  },
  "& .MuiAutocomplete-listbox > .MuiAutocomplete-option.Mui-selected.Mui-focused":
    {
      ...highlightedOptionStyle,
    },
}));

export default GooglePlacesAutocomplete;
