import React from "react";

const VisaIcon = ({width, height}: {width?: number | string, height?: number | string}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25.7759" height="17.931" rx="3.36207" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03153 11.8481H6.49264L5.33866 7.31615C5.28389 7.10768 5.16759 6.92338 4.99652 6.83652C4.56959 6.61823 4.09915 6.4445 3.58594 6.35689V6.1824H6.06496C6.4071 6.1824 6.66371 6.4445 6.70648 6.7489L7.30523 10.018L8.84336 6.1824H10.3395L8.03153 11.8481ZM11.1943 11.8481H9.74097L10.9377 6.1824H12.3911L11.1943 11.8481ZM14.2725 7.752C14.3152 7.44685 14.5718 7.27237 14.8712 7.27237C15.3417 7.22856 15.8541 7.31618 16.2818 7.53371L16.5384 6.31386C16.1107 6.13938 15.6403 6.05176 15.2133 6.05176C13.8028 6.05176 12.7763 6.83654 12.7763 7.92573C12.7763 8.75433 13.5034 9.18939 14.0166 9.45149C14.5718 9.71284 14.7857 9.88732 14.7429 10.1487C14.7429 10.5407 14.3152 10.7152 13.8883 10.7152C13.3751 10.7152 12.8619 10.5845 12.3922 10.3662L12.1356 11.5868C12.6488 11.8043 13.204 11.892 13.7172 11.892C15.2989 11.935 16.2818 11.151 16.2818 9.97418C16.2818 8.49223 14.2725 8.40536 14.2725 7.752ZM21.3666 11.8481L20.2126 6.1824H18.9731C18.7165 6.1824 18.4599 6.35689 18.3744 6.61823L16.2375 11.8481H17.7336L18.0322 11.0203H19.8705L20.0415 11.8481H21.3666ZM19.1874 7.70817L19.6143 9.84348H18.4176L19.1874 7.70817Z"
        fill="#172B85"
      />
    </svg>
  );
};

export default VisaIcon;
