import { DeviceType, selectedItem } from "@pages/NewAdvancedBuilder/signals";
import { builder, generateUniqueID } from "@pages/NewAdvancedBuilder/utils";
import { ROOT_NODE_ID } from "../values";
import {
  saveCompoundNodeDeviceTypeState,
  saveNodeDeviceTypeProperties,
} from "@pages/NewAdvancedBuilder/NodeDeviceTypePropertiesMap";

export const onClickWidget = (
  widgetData: any,
  afterAdjacentNode?: number | string | null,
) => {
  const {
    isEnabled,
    id,
    icon: Icon,
    tabs,
    title,
    tagName,
    metaAttributes,
    tabletMetaAttributes,
    mobileMetaAttributes,
    cssProperties,
    createNode,
    content,
    buildStyleObject,
    state,
    isClone,
  } = widgetData;

  if (isEnabled) {
    //we define the id of the first nodein the widget here
    //so we can pass it down to childrens as parentID easily

    const newItemUUID = generateUniqueID();

    if (createNode) {
      const { tree } = createNode(newItemUUID);

      selectedItem.value = {
        id: newItemUUID,
        widgetType: id,
        icon: Icon,
        tabs,
        title,
        metaAttributes,
        cssProperties,
        state,
      };

      saveCompoundNodeDeviceTypeState(tree.key, state);

      builder.addNode(
        ROOT_NODE_ID,
        {
          ...tree,
          key: newItemUUID,
        },
        afterAdjacentNode,
      );
    } else {
      saveNodeDeviceTypeProperties(
        newItemUUID,
        cssProperties,
        metaAttributes,
        tagName,
        content,
        tabletMetaAttributes,
        mobileMetaAttributes,
      );
      builder.addNode(
        ROOT_NODE_ID,
        {
          key: newItemUUID,
          widgetType: id,
          tag: tagName,
          tabletTag: tagName,
          mobileTag: tagName,
          content: content,
          tabletContent: content,
          mobileContent: content,
          metaAttributes,
          tabletMetaAttributes: tabletMetaAttributes,
          mobileMetaAttributes: mobileMetaAttributes,
          style: buildStyleObject(DeviceType.DESKTOP),
          tabletStyle: buildStyleObject(DeviceType.TABLET),
          mobileStyle: buildStyleObject(DeviceType.MOBILE, isClone),
        },
        afterAdjacentNode,
      );
      selectedItem.value = {
        id: newItemUUID,
        widgetType: id,
        icon: Icon,
        tabs,
        title,
        tagName,
        metaAttributes,
        cssProperties,
      };
    }
  }
};
