import { TypographyOptions } from "@mui/material/styles/createTypography";

const FONT_PRIMARY = "Give Whyte, sans-serif";

export const typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  h1: {
    fontSize: "48px",
    lineHeight: "72px",
  },
  h2: {
    fontSize: "36px",
    lineHeight: "54px",
  },
  h3: {
    fontSize: "30px",
    lineHeight: "45px",
  },
  h4: {
    fontSize: "24px",
    lineHeight: "36px",
  },
  h5: {
    fontSize: "20px",
    lineHeight: "30px",
  },
  h6: {
    fontSize: "18px",
    lineHeight: "27px",
  },
  button: {
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "none",
  },
  buttonSmall: {
    fontSize: "12px",
    lineHeight: "20px",
    textTransform: "none",
  },
  body: {
    fontSize: "14px",
    lineHeight: "19.6px",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  captionSmall: {
    fontSize: "10px",
    lineHeight: "14px",
    textTransform: "none",
  },
  smallest: {
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "none",
  },
  headline: {
    fontSize: "16px",
    lineHeight: "22.4px",
  },
  link: {
    fontSize: "14px",
    lineHeight: "21px",
    justifyItems: "baseline",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};
