import { Switch_V2 as Switch } from "@common/Switch";
import { Text } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { Stack, tooltipClasses } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";
import { EnterpriseConfigurationList } from "../constants/EnterpriseConfiguration.constants";
import { TEnterpriseConfiguration } from "../hooks/useEnterpriseConfiguration";

type Props = {
  item: TEnterpriseConfiguration;
};

const EnterpriseConfigurationSwitch = ({ item }: Props) => {
  const { setValue, watch } = useFormContext();
  const values = watch();

  const tooltip = checkDisabledConfiguration(item.name, values);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setValue(item.name, value);

    if (item.name === "modify_merchant") {
      [
        "merchant_underwriting",
        "merchant_underwriting_statuses",
        "agreement_signing",
        "control_mode",
      ].forEach((permission) => {
        setValue(permission, false);
      });
    }

    if (item.name === "merchant_underwriting") {
      setValue("merchant_underwriting_statuses", false);
    }

    if (item.name === "agreement_signing") {
      setValue("control_mode", false);
    }

    if (item.name === "bank_account_linking") {
      ["manage_bank_account", "money_transfers"].forEach((permission) => {
        setValue(permission, false);
      });
    }

    if (item.name === "manage_bank_account") {
      setValue("money_transfers", false);
    }

    if (item.name === "risk_monitoring") {
      ["merchant_triggers", "risk_activity"].forEach((permission) => {
        setValue(permission, false);
      });
    }
  };

  return (
    <Stack alignItems="flex-start" direction="row" spacing={1.5}>
      <Tooltip
        disableFocusListener={!tooltip}
        disableHoverListener={!tooltip}
        disableTouchListener={!tooltip}
        title={tooltip}
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 446,
            padding: "4px 16px",
            background: palette.neutral[80],
          },
        }}
      >
        <Switch
          size="small"
          name={item.name}
          data-testid={`ent-config-switch-${item.name}`}
          checked={values[item.name]}
          onChange={onChange}
          disabled={Boolean(tooltip)}
        />
      </Tooltip>
      <Stack spacing={0.5} sx={{ opacity: tooltip ? 0.5 : 1 }}>
        <Text color={palette.neutral[80]}>{item.displayName}</Text>
        <Text color={palette.neutral[70]}>{item.description}</Text>
      </Stack>
    </Stack>
  );
};

const checkDisabledConfiguration = (
  name: string,
  values: { [key: string]: boolean },
) => {
  // Permissions Management
  const isModifyMerchant =
    [
      "merchant_underwriting",
      "merchant_underwriting_statuses",
      "agreement_signing",
      "control_mode",
    ].includes(name) && !values.modify_merchant;

  if (isModifyMerchant) return getConfigurationTooltip("modify_merchant");

  const isMerchantUnderwriting =
    name === "merchant_underwriting_statuses" && !values.merchant_underwriting;

  if (isMerchantUnderwriting)
    return getConfigurationTooltip("merchant_underwriting");

  const isAgreementSigningDependant =
    name === "control_mode" && !values.agreement_signing;

  if (isAgreementSigningDependant)
    return getConfigurationTooltip("agreement_signing");
  // Merchant Integration
  const isBankAccountLinking =
    ["manage_bank_account", "money_transfers"].includes(name) &&
    !values.bank_account_linking;

  if (isBankAccountLinking)
    return getConfigurationTooltip("bank_account_linking");

  const isMoneyTransfer =
    name === "money_transfers" && !values.manage_bank_account;

  if (isMoneyTransfer) return getConfigurationTooltip("manage_bank_account");

  // Risk Management
  const isRiskMonitoring =
    ["merchant_triggers", "risk_activity"].includes(name) &&
    !values.risk_monitoring;

  if (isRiskMonitoring) return getConfigurationTooltip("risk_monitoring");

  return "";
};

const getConfigurationTooltip = (name: string) => {
  const disaplyeName = EnterpriseConfigurationList.find(
    (item) => item.name === name,
  )?.displayName;

  return `"${disaplyeName}" must be enabled`;
};

export default EnterpriseConfigurationSwitch;
