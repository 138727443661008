import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { QKEY_GET_CHANGELOG_USERS } from "@constants/queryKeys";
import { useMemo, useState } from "react";
import Placeholder from "@assets/images/avatar-placeholder.png";

type UserType = {
  userAccID: number;
  accID: number;
  email: string;
  firstName: string;
  lastName: string;
  imageURL: string;
};

type QueryReturnType = {
  total: number;
  data: UserType[] | null;
};

const BOTTOM_OFFSET_PX = 8;

const useListUsers = ({ merchantId }: { merchantId: number }) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalData, setTotalData] = useState<UserType[]>([]);

  const getChangelogUsers = () => {
    const searchQuery = searchValue ? `&q="${searchValue}"` : "";
    return customInstance({
      url: `/merchants/${merchantId}/changelogs/users?page=${page}${searchQuery}`,
      method: "GET",
    });
  };

  const handleSaveNewData = (data: QueryReturnType) => {
    const newData = data?.data;
    if (newData) {
      setTotalData((prev) => [...prev, ...newData]);
    }
  };

  const { data, isLoading } = useQuery<QueryReturnType>(
    [QKEY_GET_CHANGELOG_USERS, page, searchValue],
    async () => {
      return await getChangelogUsers();
    },
    {
      enabled: !!merchantId,
      onSuccess: handleSaveNewData,
      refetchOnWindowFocus: false,
    },
  );

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isLoading) return;
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    const isNearBottom =
      scrollHeight - (scrollTop + clientHeight) <= BOTTOM_OFFSET_PX;
    if (isNearBottom && data && data?.total > totalData.length) {
      setPage((prev) => prev + 1);
    }
  };

  const handleSearch = (value: string) => {
    if (searchValue === value) return;
    setTotalData([]);
    setSearchValue(value);
  };

  const formattedData = useMemo(() => {
    return (
      totalData?.map((category) => ({
        label: `${category.firstName} ${category.lastName}`,
        value: category.userAccID,
        id: category.userAccID,
        imageURL: category.imageURL
          ? `${category.imageURL}/thumb`
          : Placeholder,
      })) ?? []
    );
  }, [totalData]);

  return { data: formattedData, handleScroll, handleSearch, isLoading };
};

export default useListUsers;
