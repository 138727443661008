type IconProps = {
  height?: number;
  width?: number;
  fill?: string;
};

const MoreIconHorizontal = ({
  height = 28,
  width = 28,
  fill = "#8F8F8F",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0415 13.7083C9.0415 12.7418 8.258 11.9583 7.2915 11.9583C6.32501 11.9583 5.5415 12.7418 5.5415 13.7083C5.5415 14.6748 6.32501 15.4583 7.2915 15.4583C8.258 15.4583 9.0415 14.6748 9.0415 13.7083Z"
        fill={fill}
      />
      <path
        d="M16.0415 13.7083C16.0415 12.7418 15.258 11.9583 14.2915 11.9583C13.325 11.9583 12.5415 12.7418 12.5415 13.7083C12.5415 14.6748 13.325 15.4583 14.2915 15.4583C15.258 15.4583 16.0415 14.6748 16.0415 13.7083Z"
        fill={fill}
      />
      <path
        d="M22.4582 13.7083C22.4582 12.7418 21.6747 11.9583 20.7082 11.9583C19.7417 11.9583 18.9582 12.7418 18.9582 13.7083C18.9582 14.6748 19.7417 15.4583 20.7082 15.4583C21.6747 15.4583 22.4582 14.6748 22.4582 13.7083Z"
        fill={fill}
      />
    </svg>
  );
};

export default MoreIconHorizontal;
