import { TickIcon } from "@assets/icons";
import { Image } from "@common/StyledImage/Image";
import { TruncateText } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import React from "react";

type SelectorOptionProps = {
  name: string;
  image: string;
  selected: boolean;
  handleDeselect: () => void;
  onClick?: () => void;
};

const SelectorOption = ({
  name,
  image,
  selected,
  handleDeselect,
  onClick,
}: SelectorOptionProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (selected) {
      handleDeselect();
    } else {
      onClick && onClick();
    }
  };

  return (
    <Container onClick={handleClick}>
      <Image
        width={32}
        height={32}
        src={image}
        alt={name}
        sx={{ borderRadius: "2px" }}
      />
      <TruncateText
        lineClamp={1}
        color={palette.black[100]}
        lineHeight="16.8px"
        fontWeight="book"
        flexGrow={1}
      >
        {name}
      </TruncateText>
      {selected && (
        <Stack width={32} height={32} alignSelf="flex-end" alignItems="center">
          <TickIcon stroke={palette.black[100]} width={15} height={15} />
        </Stack>
      )}
    </Container>
  );
};

const Container = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "stretch",
  alignItems: "center",
  gap: "8px",
  padding: "4px 8px",
  borderRadius: "4px",
  background: "inherit",

  "&:active": {
    background: palette.liftedWhite[100],
  },
  "&:hover": {
    background: palette.liftedWhite[100],
    cursor: "pointer",
  },
}));

export default SelectorOption;
