type Props = {
  width?: number;
  height?: number;
  isHovered?: boolean;
};

const TypographyIcon = ({
  width = 24,
  height = 25,
  isHovered = false,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={isHovered ? "0.6" : "1"}>
        <path
          opacity="0.2"
          d="M21.7498 15.875C21.7498 17.3244 20.4063 18.5 18.7498 18.5C17.0932 18.5 15.7498 17.3244 15.7498 15.875C15.7498 14.4256 17.0932 13.25 18.7498 13.25C20.4063 13.25 21.7498 14.4256 21.7498 15.875ZM3.26416 14.75H11.7354L7.49979 5.75L3.26416 14.75Z"
          fill="#FFFFFD"
        />
        <path
          d="M8.17882 5.43094C8.11825 5.30209 8.02226 5.19315 7.90207 5.11684C7.78187 5.04052 7.64244 5 7.50007 5C7.3577 5 7.21826 5.04052 7.09807 5.11684C6.97788 5.19315 6.88189 5.30209 6.82132 5.43094L0.821318 18.1809C0.779398 18.2701 0.755444 18.3666 0.750825 18.465C0.746206 18.5634 0.761012 18.6617 0.794398 18.7544C0.861823 18.9415 1.00083 19.0942 1.18085 19.1789C1.36087 19.2636 1.56714 19.2733 1.7543 19.2058C1.94145 19.1384 2.09416 18.9994 2.17882 18.8194L3.74069 15.5006H11.2594L12.8213 18.8194C12.8632 18.9085 12.9223 18.9885 12.9951 19.0548C13.068 19.1211 13.1532 19.1724 13.2458 19.2058C13.3385 19.2392 13.4369 19.254 13.5352 19.2494C13.6336 19.2448 13.7302 19.2208 13.8193 19.1789C13.9084 19.137 13.9884 19.0779 14.0547 19.0051C14.121 18.9322 14.1724 18.8471 14.2057 18.7544C14.2391 18.6617 14.2539 18.5634 14.2493 18.465C14.2447 18.3666 14.2207 18.2701 14.1788 18.1809L8.17882 5.43094ZM4.44663 14.0006L7.50007 7.51219L10.5535 14.0006H4.44663ZM18.7501 9.50063C17.5538 9.50063 16.6191 9.82594 15.9723 10.4681C15.8368 10.6093 15.7619 10.7978 15.7635 10.9935C15.7651 11.1891 15.8431 11.3763 15.9808 11.5153C16.1186 11.6542 16.3051 11.7339 16.5007 11.7372C16.6963 11.7405 16.8855 11.6673 17.0279 11.5331C17.3841 11.1797 17.9654 11.0006 18.7501 11.0006C19.9904 11.0006 21.0001 11.8444 21.0001 12.8756V13.1775C20.3345 12.7322 19.5508 12.4965 18.7501 12.5006C16.6819 12.5006 15.0001 14.0147 15.0001 15.8756C15.0001 17.7366 16.6819 19.2506 18.7501 19.2506C19.5512 19.2541 20.3349 19.0174 21.0001 18.5709C21.0094 18.7699 21.0974 18.9569 21.2446 19.091C21.3918 19.225 21.5863 19.2951 21.7852 19.2858C21.9841 19.2765 22.1712 19.1885 22.3053 19.0413C22.4393 18.894 22.5094 18.6995 22.5001 18.5006V12.8756C22.5001 11.0147 20.8182 9.50063 18.7501 9.50063ZM18.7501 17.7506C17.5098 17.7506 16.5001 16.9069 16.5001 15.8756C16.5001 14.8444 17.5098 14.0006 18.7501 14.0006C19.9904 14.0006 21.0001 14.8444 21.0001 15.8756C21.0001 16.9069 19.9904 17.7506 18.7501 17.7506Z"
          fill="#FFFFFD"
        />
      </g>
    </svg>
  );
};

export default TypographyIcon;
