import { getJsonValue } from "./utils";

export const getCustomBaseInputStyle = (
  mode: "light" | "dark",
  basePallete: any,
) => ({
  styleOverrides: {
    root: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "10px",
      borderRadius: "8px",
      border:
        "1px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.20`),
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:hover:not(.Mui-disabled, .Mui-error)": {
        border:
          "1px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      },
      "&:active:not(.Mui-disabled, .Mui-error)": {
        background: basePallete.gradient["ocean-blue"].border,
        border: "1.5px solid transparent",
      },
      "&:hover:not(.Mui-disabled, .Mui-error)&:before": {
        borderBottom: "none",
      },
      "&.Mui-disabled:before": {
        borderBottomStyle: "none",
      },
      "&.Mui-error": {
        border:
          "1px solid " +
          getJsonValue(`tokens.${mode}.primitive.citrus-peel.100`),
      },
    },
  },
});
