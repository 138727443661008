import { Box } from "@mui/material";
import { palette } from "@palette";

const StickyWrapper = ({
  children,
  isSticky,
}: {
  children: React.ReactNode;
  isSticky?: boolean;
}) => {
  if (!isSticky) return <>{children}</>;
  return (
    <Box
      sx={{
        position: "sticky",
        top: -16, //To offset parent top padding
        zIndex: 1000,
        background: palette.primary.background,
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default StickyWrapper;
