import React from "react";
import { Image } from "@react-pdf/renderer";
import CheckedCheckbox from "@assets/images/pdf_checked_checkbox.png";
import UncheckedCheckbox from "@assets/images/pdf_unchecked_checkbox.png";

const styles = { height: 14, width: 14, marginBottom: 2 };

export const PdfCheckboxChecked = (
  <Image src={CheckedCheckbox} style={styles} />
);

export const PdfCheckboxUnchecked = (
  <Image src={UncheckedCheckbox} style={styles} />
);
