import { palette } from "@palette";

type TVariant = "onboarding" | "default";

export const getToolbarOptionsStyle = ({
  variant = "default",
}: {
  variant?: TVariant;
}) => {
  switch(variant) {
    case "onboarding":
      return onboardingStyle;
    default:
      return defaultStyle;
  }
}

const defaultStyle = {
  "& .rdw-inline-wrapper": {
    order: "1",
    flexWrap: "nowrap",
    gap: "4px",
  },
  "& .rdw-list-wrapper": {
    order: "3",
  },
  "& .rdw-text-align-wrapper": {
    order: "4",
    flexWrap: "nowrap",
    gap: "6px",
  },
  "& .rdw-link-wrapper": {
    order: "2",
  },
  "& .rdw-emoji-wrapper": {
    order: "6",
  },
  "& .rdw-option-wrapper": {
    borderRadius: "8px",
    height: "12px",
    background: "inherit",
    border: "none",
    padding: "6px 0px",
    margin: 0,

    "& img": {
      width: "12px",
      height: "12px",
    },
  },
  "& .rdw-image-wrapper": {
    order: "7",
  },
}

const onboardingStyle = {
  "& .rdw-inline-wrapper": {
    order: "1",
    flexWrap: "nowrap",
    gap: "4px",
    paddingRight: "8px",
    borderRight: `1px solid ${palette.gray[300]}`,
    marginLeft: "auto",
    marginTop: "6px",
  },
  "& .rdw-list-wrapper": {
    order: "3",
    paddingInline: "8px 6px",
    marginTop: "6px",
  },
  "& .rdw-text-align-wrapper": {
    order: "4",
    flexWrap: "nowrap",
    gap: "6px",
    paddingRight: "8px",
    borderRight: `1px solid ${palette.gray[300]}`,
    marginTop: "6px",
  },
  "& .rdw-link-wrapper": {
    order: "2",
    paddingInline: "8px",
    borderRight: `1px solid ${palette.gray[300]}`,
    marginTop: "6px",
  },
  "& .rdw-emoji-wrapper": {
    paddingInline: "8px 6px",
    marginTop: "6px",
    order: "6",
  },
  "& .rdw-option-wrapper": {
    borderRadius: "8px",
    height: "12px",
    background: "inherit",
    border: "none",
    margin: 0,
    padding: 0,
    minWidth: "12px",

    "& img": {
      width: "12px",
      height: "12px",
    },
  },
  "& .rdw-image-wrapper": {
    order: "7",
    paddingRight: "8px",
    marginTop: "6px",
  },
}