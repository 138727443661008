import { SortDownIcon, SortUpIcon } from "@assets/icons";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { SortingOrder } from "@redux/types/sort";

type TStatus = "idle" | "active";
type TColorsRange = Record<TStatus, string>;

const defaultColors: TColorsRange = {
  idle: palette.liftedWhite[300],
  active: palette.neutral[90],
};

interface Props {
  active: boolean;
  order: SortingOrder;
  onClick: VoidFunction;
  size?: number;
  colors?: TColorsRange;
}

const SortingButton = ({
  active,
  order,
  onClick,
  size = 24,
  colors = defaultColors,
}: Props) => {
  const sortUpColor = active && order === "desc" ? colors.active : colors.idle;
  const sortDownColor = active && order === "asc" ? colors.active : colors.idle;
  const iconSize = Math.floor(size / 3);
  return (
    <Button
      component="span"
      data-testid={`sorting-button`}
      onClick={onClick}
      onDoubleClick={(e) => e.preventDefault()}
      size={size}
    >
      <SortUpIcon fill={sortUpColor} stroke={sortUpColor} size={iconSize} />
      <SortDownIcon
        fill={sortDownColor}
        stroke={sortDownColor}
        size={iconSize}
      />
    </Button>
  );
};

export default SortingButton;

const Button = styled(Box, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "&:hover": {
    cursor: "pointer",
    opacity: 0.98,
  },
}));
