import { Components } from "@mui/material";
import { palette } from "@palette";

export const textField: Components["MuiTextField"] = {
  defaultProps: {
    InputLabelProps: {
      variant: "filled",
    },
  },
  styleOverrides: {
    root: {
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        padding: "7.5px 12px",
        fontSize: "14px",
        boxShadow: "none",
        backgroundColor: palette.neutral.white,
        border: `2px solid ${palette.liftedWhite[200]}`,

        "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
          marginRight: "4px",
        },

        "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
          display: "flex",
          gap: "4px",
          flexDirection: "row",
          alignItems: "center",
        },

        "& fieldset": {
          border: "none",
          borderRadius: 0,
        },

        "& input": {
          padding: 0,
          fontFamily: "Give Whyte",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
          color: palette.black[100],
          marginTop: "15px",
          
          "&::-ms-reveal": {
            display: "none",
          },
          "&::-ms-clear": {
            display: "none",
          },

          "&::placeholder": {
            fontFamily: "Give Whyte",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "17px",
            color: palette.gray[300],
          },

          ":-webkit-autofill, :-webkit-autofill:focus": {
            transition: "background-color 600000s 0s",
            backgroundColor: palette.neutral.white,
            animationName: "onAutoFillStart",
          },
          ":not(:-webkit-autofill)": {
            animationName: "onAutoFillCancel",
          },
          "@keyframes onAutoFillStart": {},
          "@keyframes onAutoFillCancel": {},
        },

        "& textarea": {
          borderRadius: 0,
          fontFamily: "Give Whyte",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
          color: palette.black[100],
          marginTop: "18px",
        },

        "&:hover": {
          borderColor: palette.gray.main,
        },
      },

      "& .MuiInputBase-root.Mui-focused": {
        backgroundColor: palette.neutral.white,
        borderColor: palette.gray[300],

        "& .MuiInputBase-currencyIcon": {
          color: palette.black[200],
        },
      },
      "& .MuiInputBase-root.Mui-error": {
        backgroundColor: palette.neutral.white,
        borderColor: "#D80D0D",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: palette.liftedWhite[200],
        boxShadow: "none",
        border: "none",

        "& input": {
          marginTop: "18px",
        },
      },

      "& .MuiInputLabel-root": {
        color: palette.gray.main,
        fontSize: "18px",
        letterSpacing: "-0.01em",
        lineHeight: "22px",
        left: "1px",
      },
      "& .MuiInputLabel-root.Mui-error": {
        color: palette.gray.main,
      },
      "& .MuiInputLabel-root.MuiInputLabel-shrink": {
        color: palette.gray[300],
        letterSpacing: "0px",
        scale: 0.78,
      },
      "& .MuiInputLabel-root.Mui-focused, .MuiInputLabel-root.Mui-disabled": {
        color: palette.gray[300],
      },

      "& .MuiFormHelperText-root": {
        margin: 0,
        textTransform: "none",
        paddingInline: "12px",
        fontSize: "12px",
        marginTop: "4px",
        color: palette.gray[300],
        lineHeight: "14px",
        fontFamily: "Give Whyte",
        fontWeight: 400,
        fontStyle: "normal",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: palette.gray[300],
      },
    },
  },
};
