import RESOURCE_BASE from "@constants/permissions";
import { useAppSelector } from "@redux/hooks";
import { selectIsAllowedByResource } from "@redux/slices/accessControl/accessControl";
import { composePermission } from "features/Permissions/AccessControl";

const useFilePermissions = (isEnterprise = false) => {
  const portal = isEnterprise
    ? RESOURCE_BASE.ENTERPRISE
    : RESOURCE_BASE.MERCHANT;
  const permission = composePermission(portal, RESOURCE_BASE.FILES);

  const filesPermissions = useAppSelector((state) =>
    selectIsAllowedByResource(state, permission),
  );

  return {
    isListAllowed: filesPermissions.read === "allow",
    isAddAllowed: filesPermissions.create === "allow",
    isUpdateAllowed: filesPermissions.update === "allow",
    isDeleteAllowed: filesPermissions.delete === "allow",
  };
};

export default useFilePermissions;
