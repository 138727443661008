import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Box, InputAdornment, Stack } from "@mui/material";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Text } from "@common/Text";
import * as Yup from "yup";
import { RHFInput } from "@common/Input";
import Popup from "@common/Popup";
import { useModal, muiDialogV5 } from "@ebay/nice-modal-react";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { showMessage } from "@common/Toast";
import { useMutation } from "react-query";
import { customInstance } from "@services/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { HideEye_V2, ShowEye_V2 } from "@assets/rebrandIcons";
import { TypedFunction } from "@customTypes/utils.types";
import MobileWrapper from "./MobileWrapper";

type ICheckPasswordForm = {
  password: string;
};

type CheckPasswordProps = {
  setChecked: TypedFunction<[boolean]>;
};

function CheckPassword({ setChecked }: CheckPasswordProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const modal = useModal();

  const { isMobileView } = useCustomTheme();

  const schema = Yup.object({
    password: Yup.string().min(8).required("Password is required"),
  });
  const defaultValues = {
    password: "",
  };

  const methods = useForm<ICheckPasswordForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const { setError } = useForm();
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutateAsync: checkPasswordAsync, isLoading } = useMutation(
    (data: ICheckPasswordForm) => {
      return customInstance({
        url: `/users/password-check`,
        method: "POST",
        data,
      });
    },
  );

  const onSubmit: SubmitHandler<ICheckPasswordForm> = async (data) => {
    try {
      await checkPasswordAsync(data);
      setChecked?.(true);
    } catch (err: any) {
      const errMessage = err.response?.data?.message;
      setError("password", { message: errMessage });
      showMessage("Error", errMessage);
    }
  };
  if (isMobileView)
    return (
      <MobileWrapper label="Enter Password">
        <Box sx={styles.container}>
          <FormProvider {...methods}>
            <FadeUpWrapper delay={50}>
              <Text sx={styles.infoText}>
                For security reasons we’ll need to confirm it’s you
              </Text>
            </FadeUpWrapper>

            <FadeUpWrapper delay={100}>
              <RHFInput
                name="password"
                fullWidth
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter your password"
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => setIsPasswordVisible((prev) => !prev)}
                      position="end"
                      sx={{ cursor: "pointer" }}
                    >
                      {isPasswordVisible ? <HideEye_V2 /> : <ShowEye_V2 />}
                    </InputAdornment>
                  ),
                }}
              />
            </FadeUpWrapper>
          </FormProvider>
        </Box>
        <Box flexGrow={1} />
        <Stack mt={2} direction="row">
          <Button
            onClick={() => modal.hide()}
            fullWidth
            size="medium"
            background="tertiary"
          >
            Cancel
          </Button>
          <Button
            size="medium"
            background="primary"
            type="submit"
            fullWidth
            form="profile-form"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isLoading}
          >
            Send
          </Button>
        </Stack>
      </MobileWrapper>
    );
  return (
    <Popup
      {...muiDialogV5(modal)}
      width="470px"
      title="Enter password"
      onCancel={() => modal.hide()}
      actionLabel="Continue"
      cancelLabel="Cancel"
      displayCloseIcon
      titleSx={{
        fontSize: "20px",
        color: "#575353",
        mb: 1,
      }}
      submitButtonProps={{
        size: "medium",
        disabled: !isValid || isLoading,
        onClick: handleSubmit(onSubmit),
        sx: { padding: "5px 20px" },
      }}
      cancelButtonProps={{
        size: "medium",
      }}
    >
      <Box sx={styles.container}>
        <FormProvider {...methods}>
          <FadeUpWrapper delay={50}>
            <Text sx={styles.infoText}>
              For security reasons we’ll need to confirm it’s you
            </Text>
          </FadeUpWrapper>

          <FadeUpWrapper delay={100}>
            <RHFInput
              name="password"
              fullWidth
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Enter your password"
              label="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => setIsPasswordVisible((prev) => !prev)}
                    position="end"
                    sx={{ cursor: "pointer" }}
                  >
                    {isPasswordVisible ? <ShowEye_V2 /> : <HideEye_V2 />}
                  </InputAdornment>
                ),
              }}
            />
          </FadeUpWrapper>
        </FormProvider>
      </Box>
    </Popup>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  infoBox: {
    display: "flex",
    gap: 2,
  },
  infoText: {
    color: "#8F8F8F",
    mb: 2,
  },
  labelText: {
    variant: "body",
    color: palette.neutral[800],
    fontWeight: "medium",
    mb: 1,
  },
};

export default CheckPassword;
