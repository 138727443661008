import { CloseIcon } from "@assets/icons";
import { Button } from "@common/Button";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { Text } from "@common/Text";
import { Box, Stack, TextField } from "@mui/material";
import { ButtonBase, styled } from "@mui/material";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import { customTotalProcessing } from "@redux/slices/tableFilters";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useState } from "react";

const NUMBERS_ONLY_REGEX = /^-?[0-9]+$/;

interface ICustomVolumeModal {
  handleClose: () => void;
  handleSave: (param?: { from?: number; to?: number }) => void;
}

type TContextValue = "from" | "to";
const contextValues: TContextValue[] = ["from", "to"];

const isValid = (
  fromValue: number | undefined,
  toValue: number | undefined,
) => {
  if (fromValue && !toValue) {
    return fromValue >= 0;
  }

  if (!fromValue && toValue) {
    return toValue >= 0;
  }

  if (fromValue && toValue) {
    return toValue >= fromValue && fromValue >= 0 && toValue >= 0;
  }

  return false;
};

const CustomVolumePopup = ({ handleClose, handleSave }: ICustomVolumeModal) => {
  const contextCustomTotalProcessing = useAppSelector(customTotalProcessing);

  const [fromValue, setFromValue] = useState<string>(
    `${contextCustomTotalProcessing?.from || ""}`,
  );
  const [toValue, setToValue] = useState<string>(
    `${contextCustomTotalProcessing?.to || ""}`,
  );

  const { isMobileView } = useCustomTheme();

  const isApplyBtnDisabled = !isValid(parseInt(fromValue), parseInt(toValue));

  const handleInputValueChange = (
    value: string,
    contextValue: TContextValue,
  ) => {
    if (NUMBERS_ONLY_REGEX.test(value) || value === "") {
      const setState = {
        from: setFromValue,
        to: setToValue,
      }[contextValue];
      setState(value);
    }
  };

  const handleApplyBtnClick = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const parsedFromValue = parseInt(fromValue);
    const parsedToValue = parseInt(toValue);
    handleSave({
      from: !isNaN(parsedFromValue) ? parsedFromValue : undefined,
      to: !isNaN(parsedToValue) ? parsedToValue : undefined,
    });
    handleClose();
  };

  return (
    <StyledBox isMobile={isMobileView}>
      <form onSubmit={handleApplyBtnClick} style={{ height: "100%" }}>
        <Stack gap={1.5}>
          <StyledButtonBase disableRipple onClick={handleClose}>
            <CloseIcon stroke={palette.black[100]} />
          </StyledButtonBase>

          <Stack direction="row" gap={1}>
            {contextValues.map((contextValue) => (
              <TextField
                name={contextValue}
                key={contextValue}
                placeholder={capitalizeFirstLetter(contextValue)}
                value={contextValue === "from" ? fromValue : toValue}
                onChange={(e) =>
                  handleInputValueChange(e.target.value, contextValue)
                }
                type="number"
                InputProps={{ endAdornment: USDText }}
                inputProps={{ style: inputStyle }}
              />
            ))}
          </Stack>
          <Box width="100%">
            <StyledButton
              type="submit"
              fullWidth={false}
              disabled={isApplyBtnDisabled}
              background="primary"
            >
              Apply
            </StyledButton>
          </Box>
        </Stack>
      </form>
    </StyledBox>
  );
};

const inputStyle = { marginTop: 0 };

const StyledBox = styled(Box)<{ isMobile: boolean }>(({ isMobile, theme }) => ({
  ...(!isMobile && {
    padding: "12px",
    borderRadius: theme.spacing(1),
    width: isMobile ? "90vw" : 460,
    backgroundColor: palette.background.bgWhite,
    boxShadow: "0px 8px 25px 0px #00000026",
    margin: 8,
  }),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "fit-content",
  boxShadow: "none",
  alignSelf: "center",
  borderRadius: theme.spacing(4),
  fontSize: 18,
  margin: "0 auto",
}));

const StyledButtonBase = styled(ButtonBase)({ alignSelf: "flex-end" });

const StyledText = styled(Text)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

const USDText = <StyledText color={palette.neutral[40]}>USD</StyledText>;

export default CustomVolumePopup;
