import { FormInputs } from "../form/form.type";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { isEmpty, isFunction } from "lodash";
import { toUnixDateFormat } from "@utils/date.helpers";
import { selectUser } from "@redux/slices/auth/auth";
import { useSelector } from "react-redux";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";

export default function useManageApi(merchantId: number) {
  const queryClient = useQueryClient();
  const auth = useSelector(selectUser);
  const { mutate, isLoading } = useMutation((data: any) => {
    return customInstance({
      url: `/merchants/${merchantId}`,
      method: "PATCH",
      data,
    });
  });

  const { mutateAsync: mutateAsyncPrincipal, isLoading: isLoadingPrincipal } =
    useMutation(({ data, principalId, legalEntityId }: any) => {
      return customInstance({
        url: `/merchants/${merchantId}/legal-entities/${legalEntityId}/principals/${principalId}`,
        method: "PATCH",
        data,
      });
    });

  const onSubmitInformation = async (
    data: FormInputs & { principalId?: number; legalEntityId?: number },
    onSuccess?: (data: FormInputs) => void,
  ) => {

    const payload = {
      owner: {
        firstName: data?.base?.firstName,
        lastName: data?.base?.lastName,

        ...(data?.base?.phoneNumber && {
          phoneNumber: data?.base?.phoneNumber
            ? data?.base?.phoneNumber?.slice(1).replaceAll(" ", "")
            : null,
        }),
        citizenship: data?.base?.citizenship,
        countryOfResidence: data?.base?.countryOfResidence,
      },
      ownerIsManagerialAuthority: data.base?.isManager,
    } as any;

    if (data?.base?.dob) {
      payload.owner.dateOfBirth = toUnixDateFormat(data.base?.dob as Date);
    }

    if (data.isOwner) {
      let ownerLegalEntity = {};

      ownerLegalEntity = {
        address: data?.whenIsOwner?.address,
        ownershipPercentage: Number(data.whenIsOwner.ownership),
        firstName: data?.base?.firstName,
        lastName: data?.base?.lastName,
        dateOfBirth: toUnixDateFormat(data.base?.dob as Date),
        email: auth.email,
      };

      if (!isEmpty(data.whenIsOwner.ein)) {
        ownerLegalEntity = {
          ...ownerLegalEntity,
          ein: data.whenIsOwner.ein,
        };
      } else if (!isEmpty(data.whenIsOwner.ssn)) {
        ownerLegalEntity = {
          ...ownerLegalEntity,
          taxIDNumber: data.whenIsOwner.ssn,
        };
      }

      if (data?.principalId) {
        await mutateAsyncPrincipal({
          data: ownerLegalEntity,
          legalEntityId: data?.legalEntityId,
          principalId: data?.principalId,
        });
      } else {
        payload.ownerLegalEntity = ownerLegalEntity;
      }
    }

    mutate(payload, {
      onSuccess() {
        queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
        queryClient.refetchQueries("find-profile-by-id");
        isFunction(onSuccess) && onSuccess(data);
      },
      onError(error: any, variables, context) {
        showMessage(
          "Error",
          error?.response?.data?.message ||
            "Something went wrong while saving informations.",
        );
      },
    });
  };
  return {
    onSubmitInformation,
    informationsAreLoading: isLoading || isLoadingPrincipal,
  };
}
