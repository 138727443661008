import { RefObject, useEffect } from "react";

const useInputFieldFocusListener = ({
  inputRef,
  onFocus,
  autofocus,
}: {
  inputRef: RefObject<HTMLInputElement>;
  onFocus?: (isFocused: boolean) => void;
  autofocus?: boolean;
}) => {
  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement && onFocus) {
      const focusCb = () => onFocus(true);
      const blurCb = () => onFocus(false);

      inputElement.addEventListener("focus", focusCb);
      inputElement.addEventListener("blur", blurCb);

      if (autofocus) focusCb();

      return () => {
        inputElement.removeEventListener("focus", focusCb);
        inputElement.removeEventListener("blur", blurCb);
      };
    }
  }, []);
};

export default useInputFieldFocusListener;
