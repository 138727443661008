import { Box, BoxProps } from "@mui/material";

type Props = {
  status: string;
  text?: string;
} & BoxProps;

const DeveloperApiStatus = ({ status, text, ...props }: Props) => {
  return (
    <Box
      {...props}
      sx={{
        width: "fit-content",
        padding: "4px 8px",
        borderRadius: "4px",
        fontSize: "12px",
        ...(status && statusMapper[status.toLocaleLowerCase()]),
        ...(props?.sx && props?.sx),
      }}
    >
      {text || status}
    </Box>
  );
};

const statusMapper: Record<string, { backgroundColor: string; color: string }> =
  {
    active: { backgroundColor: "#E6F3EC", color: "#108548" },
    revoked: { backgroundColor: "#F5E0DF", color: "#BB1111" },
    rolled: { backgroundColor: "#ECECE9", color: "#575353" },
  };

export default DeveloperApiStatus;
