import React from "react";
import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";

function DonationSkeletons() {
  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      display="flex"
      my="20px"
      px={1}
    >
      <Box>
        <Skeleton
          width="145px"
          sx={{
            borderRadius: "3px",
            marginBottom: "8px",
          }}
          variant="rounded"
          height="20px"
        />
        <Skeleton
          width="90px"
          sx={{
            borderRadius: "2px",
          }}
          variant="rounded"
          height="15px"
        />
      </Box>
      <Skeleton
        width="55px"
        sx={{
          borderRadius: "2px",
        }}
        variant="rounded"
        height="22px"
      />
    </Box>
  );
}

export default DonationSkeletons;
