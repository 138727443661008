import * as React from "react";
import NiceModal from "@ebay/nice-modal-react";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// component
import { Text } from "@common/Text";
import { KeyVal } from "@common/KeyVal";
import { Tooltip } from "@common/Tooltip";
import { DropdownItem } from "@common/Select";
import { IconButton } from "@common/IconButton";
import {
  ModifyRecurring,
  MakeRecurring,
  Refund,
  SendReceipt,
} from "@components/ManageMoney/TransactionTable/TableActions";
// assets
import { MoreIcon, RefundTopIcon, PaperLightIcon } from "@assets/icons";
// utils
import { truncateStringLength } from "utils";
// types
import { CustomersData } from "@components/Customers/Table/data";
import {
  MERCHANT_PORTAL_MAKE_RECURRING_MODAL,
  MERCHANT_PORTAL_MODIFY_RECURRING_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  PROCESSING_MAKE_RECURRING_MODAL,
  PROCESSING_MODIFY_RECURRING_MODAL,
  PROCESSING_REFUND_TRANSACTION_MODAL,
  PROCESSING_SEND_RECEIPT_MODAL,
} from "modals/modal_names";
import { palette } from "@palette";

type Data = {
  title: string;
  description: string;
  type: string;
  recurrence: string;
  nextTransaction: string;
  details: {
    donation: number;
    giveboxFee: number;
    visaFee: number;
    charged: number;
  };
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  fontSize: 14,
  backgroundColor: "inherit",
  border: "none",
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.neutral[100]}`,
  },
  "&:before": {
    display: "none",
  },
  "& sup": {
    fontSize: 9,
    lineHeight: "9px",
    marginLeft: "2px",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  padding: "0 0 0 4px",
  backgroundColor: "inherit",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2.5),
  },
  "& .MuiButton-root.CustomIconButton": {
    border: "none",
    boxShadow: "none",
    backgroundColor: "inherit",

    "&:active": {
      boxShadow:
        "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 4px 4px 14px #C1D5EE",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0, 1, 4.5),
  backgroundColor: "inherit",
}));

const Progress = styled(LinearProgress)(({ theme }) => ({
  width: 120,
  height: "8px",
  borderRadius: "32px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: palette.neutral[20],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "32px",
    backgroundColor: palette.neutral[100],
  },
}));

export default function SubExpandedRow({ data }: { data: Data }) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const refundHandler = () => {
    setAnchorEl(null);
    NiceModal.show(PROCESSING_REFUND_TRANSACTION_MODAL, {});
  };

  const sendReceiptHandler = (row: Record<string, any>) => {
    setAnchorEl(null);
    NiceModal.show(PROCESSING_SEND_RECEIPT_MODAL, {
      id: row.id,
      transactionID: row.id,
      emails: [row.sourceAccount?.user.email]
    });
  };

  const makeRecurringHandler = () => {
    setAnchorEl(null);
    NiceModal.show(PROCESSING_MAKE_RECURRING_MODAL, {});
  };

  const modifyingRecurringHandler = () => {
    setAnchorEl(null);
    NiceModal.show(PROCESSING_MODIFY_RECURRING_MODAL, {
      ...data,
      orderRecurringItemID: "",
      sourceAccount: {},
    });
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem" }}
            onClick={() => setExpanded((prevState) => !prevState)}
          />
        }
        aria-controls="sub-expanded-row-content"
        id={data.title}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={4}
            onClick={() => setExpanded((prevState) => !prevState)}
          >
            <Text
              color={expanded ? "#734089" : "#4C4A52"}
              fontWeight="semibold"
            >
              {data.title}
            </Text>
            <Box>
              {expanded
                ? `${data.description}`
                : truncateStringLength(`${data.description}`, 35)}
            </Box>
          </Grid>
          <Grid item xs={2} visibility={expanded ? "hidden" : "visible"}>
            <Text>{data.title}</Text>
          </Grid>
          <Grid item xs={2} visibility={expanded ? "hidden" : "visible"}>
            <Text textTransform="capitalize">{data.recurrence}</Text>
          </Grid>
          <Grid item xs={2} visibility={expanded ? "hidden" : "visible"}>
            <Text>
              {data.details.charged}
              <sup>USD</sup>
            </Text>
          </Grid>

          <Grid item xs={2}>
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Tooltip title="Refund">
                <IconButton size="small" onClick={refundHandler}>
                  <RefundTopIcon size="20px" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Send Receipt">
                <IconButton size="small" onClick={sendReceiptHandler}>
                  <PaperLightIcon size="20px" />
                </IconButton>
              </Tooltip>
              <Tooltip title="More">
                <IconButton size="small" onClick={handleOpenMenu}>
                  <MoreIcon size="20px" />
                </IconButton>
              </Tooltip>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    width: 159,
                  },
                }}
              >
                <DropdownItem onClick={refundHandler}>
                  <Text>Refund Purchase</Text>
                </DropdownItem>
                <DropdownItem onClick={sendReceiptHandler}>
                  <Text>Send Receipt</Text>
                </DropdownItem>

                <DropdownItem onClick={modifyingRecurringHandler}>
                  <Text>Modify Recurring</Text>
                </DropdownItem>
              </Menu>
            </Stack>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        {/* ---------- TYPE - RECURRENCE - NEXT TRANSACTION  ----------*/}
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <KeyVal keyName="Type" value="Charitable donation" />
          </Grid>

          <Grid
            item
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <KeyVal
              keyName="Recurrence"
              value={
                <Stack gap={1.5} direction="row" alignItems="center">
                  <Text textTransform="capitalize">{data.recurrence}</Text>
                  {data.recurrence.toLowerCase() !== "one time" && (
                    <>
                      <Box>
                        <Progress variant="determinate" value={30} />
                      </Box>
                      <Text>3 out of 6</Text>
                    </>
                  )}
                </Stack>
              }
            />
          </Grid>
        </Grid>

        <Grid mt={1.5} container rowSpacing={1} alignItems="center">
          {/* ---------- DONATED  ----------*/}
          <Grid item xs={6}>
            <KeyVal
              keyName="Donated"
              value={
                <Text>
                  Enter any amount x Qty @ 64.00<sup>USD</sup> each
                </Text>
              }
            />
          </Grid>

          {/* ---------- DONATION - GIVEBOX FEE - VISA FEE - CHARGED  ----------*/}
          <Grid
            item
            container
            xs={6}
            sx={{
              padding: "4px 8px",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              boxShadow:
                "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
            }}
          >
            <Grid item xs={3}>
              <KeyVal
                textVariant
                keyName="Donation"
                value={
                  <Text>
                    {data.details.donation}
                    <sup>USD</sup>
                  </Text>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <KeyVal
                textVariant
                keyName="Givebox Fee"
                value={
                  <Text>
                    {data.details.giveboxFee}
                    <sup>USD</sup>
                  </Text>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <KeyVal
                keyName="Visa Fee"
                value={
                  <Text>
                    {data.details.visaFee}
                    <sup>USD</sup>
                  </Text>
                }
              />
            </Grid>
            <Grid item xs={3}>
              <KeyVal
                keyName="Charged"
                value={
                  <Text>
                    {data.details.charged}
                    <sup>USD</sup>
                  </Text>
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Text fontSize={12} color={theme.palette.neutral[600]}>
              *Customer paid visa fee
            </Text>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
