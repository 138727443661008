import { customInstance } from "..";

type CartItemPayload = {
  price: number;
  recurringFrequency: number;
  recurringInterval: string;
  recurringMax: number | null;
  name?: string | undefined;
  productVariantID: number;
  quantity: number;
};

export const addCartItem = (item: CartItemPayload) =>
  customInstance({
    url: "cart/items",
    method: "POST",
    data: item,
  });

export const deleteCardItem = (itemId: number) =>
  customInstance({
    url: `cart/items/${itemId}`,
    method: "DELETE",
  });
