import { IconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import { styled } from "@mui/material";
import { palette } from "@palette";

export const StyledInfoText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "backgroundColor",
})<{ color?: string; backgroundColor?: string }>(
  ({ color, backgroundColor }) => ({
    borderRadius: "8px",
    padding: "12px 16px",
    width: "100%",
    backgroundColor: backgroundColor ? backgroundColor : "#FDFDFD",
    color: color ? color : "#575353",
    fontSize: "14px",
    fontWeight: "350",
    lineHeight: "16.8px",
    fontFamily: "Give Whyte",
    opacity: "0.8",
  }),
);

export const StyledIconButton = styled(IconButton)(() => ({
  boxShadow: "none",
  border: "none",
  background: "none",
  transform: "rotate(90deg)",
  visibility: "hidden",
}));

export const MovePendingTitle = () => {
  return (
    <Text
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        alignItems: "flex-start",
        color: palette.filled.orange,
        "& span": {
          color: palette.neutral[70],
          fontSize: "12px",
          lineHeight: "14.4px",
          textWrap: "wrap",
        },
      }}
    >
      Move Back to Pending
      <span>Ask the user to update or add more information</span>
    </Text>
  );
};
