import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { PEPResourcesIDs, THistoryCheck } from "../types";
import { showMessage } from "@common/Toast";
import {
  checkPEPMutation,
  getCheckHistory,
} from "@services/api/politicallyExposed/politicallyExposedActions";
import { getListDummyData, parser } from "../utils";

export default function useManagePepData({
  merchantId,
  legalEntityID,
  selectedOwner,
}: PEPResourcesIDs) {
  const [selectedCheck, setSelectedCheck] = useState<THistoryCheck | undefined>(
    undefined,
  );
  const queryClient = useQueryClient();

  const handleClickCheck = (check: THistoryCheck) => {
    setSelectedCheck(check);
  };

  const removeSelectedPEP = () => setSelectedCheck(undefined);

  const { data, isLoading, refetch, isFetching } = useQuery(
    ["pep-checks-history", selectedOwner, merchantId, legalEntityID],
    async () => {
      const list = await getCheckHistory({
        selectedOwner,
        merchantId,
        legalEntityID,
      });

      const parsedData = list?.data.map(parser);

      return {
        data: parsedData || [],
        total: list.total || 0,
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedOwner?.id,
      onSuccess: async (data) => {
        setSelectedCheck((prev) => {
          if (!prev) return undefined;
          const obj = data?.data?.find(
            (item: any) => item?.checkID === prev.checkID,
          );
          return obj;
        });
      },
      retry: 1,
    },
  );

  const { mutate, isLoading: isRunningCheck } = useMutation(
    () =>
      checkPEPMutation({
        merchantId,
        selectedOwner,
        legalEntityID,
      }),
    {
      onSuccess() {
        showMessage("Success", "PEP Check is completed sucessfully", false);
        queryClient.invalidateQueries("get-merchant-preview");
        refetch();
      },
      onError: () => {
        showMessage(
          "Warning",
          "PEP Check is not successfully completed, the request is in queue",
          false,
        );
      },
    },
  );

  const checkPEP = () => selectedOwner?.id && mutate();

  return {
    data: data?.data ? data?.data : getListDummyData(selectedOwner?.createdAt),
    handleClickCheck,
    selectedCheck,
    removeSelectedPEP,
    isLoading: isFetching || isLoading,
    checkPEP,
    isRunningCheck,
  };
}
