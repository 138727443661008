import React from "react";

const CalendarDuotoneIcon2 = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08334 4.12741C7.08333 4.00416 7.08333 3.94254 7.04403 3.90553C7.00472 3.86851 6.94378 3.87218 6.82189 3.87952C5.46292 3.96131 4.5987 4.18693 3.97631 4.80932C3 5.78563 3 7.35698 3 10.4997C3 13.6424 3 15.2137 3.97631 16.19C4.95262 17.1663 6.52397 17.1663 9.66667 17.1663H11.3333C14.476 17.1663 16.0474 17.1663 17.0237 16.19C18 15.2137 18 13.6424 18 10.4997C18 7.35698 18 5.78563 17.0237 4.80932C16.4013 4.18693 15.5371 3.96131 14.1781 3.87952C14.0562 3.87218 13.9953 3.86851 13.956 3.90553C13.9167 3.94254 13.9167 4.00416 13.9167 4.12741L13.9167 5.91634C13.9167 6.19248 13.6928 6.41634 13.4167 6.41634C13.1405 6.41634 12.9167 6.19248 12.9167 5.91634L12.9167 4.08595C12.9167 3.96938 12.9167 3.91109 12.8804 3.87454C12.8442 3.838 12.7861 3.8375 12.6699 3.83651C12.2589 3.83301 11.8145 3.83301 11.3333 3.83301H9.66667C9.18548 3.83301 8.74113 3.83301 8.33012 3.83651C8.21391 3.8375 8.15581 3.838 8.11957 3.87454C8.08333 3.91109 8.08333 3.96938 8.08333 4.08595L8.08334 5.91634C8.08334 6.19248 7.85948 6.41634 7.58334 6.41634C7.3072 6.41634 7.08334 6.19248 7.08334 5.91634L7.08334 4.12741Z"
        fill="#727179"
        fillOpacity="0.5"
      />
      <path
        d="M7.58332 2.58301L7.58333 5.91634"
        stroke="#727179"
        strokeLinecap="round"
      />
      <path
        d="M13.4167 2.58301L13.4167 5.91634"
        stroke="#727179"
        strokeLinecap="round"
      />
      <circle cx="6.74999" cy="9.24967" r="0.416667" fill="#727179" />
      <circle cx="9.25" cy="9.24967" r="0.416667" fill="#727179" />
      <circle cx="11.75" cy="9.24967" r="0.416667" fill="#727179" />
      <circle cx="14.25" cy="9.24967" r="0.416667" fill="#727179" />
      <circle cx="6.74999" cy="11.7497" r="0.416667" fill="#727179" />
      <circle cx="9.25" cy="11.7497" r="0.416667" fill="#727179" />
      <circle cx="11.75" cy="11.7497" r="0.416667" fill="#727179" />
      <circle cx="14.25" cy="11.7497" r="0.416667" fill="#727179" />
      <circle cx="6.74999" cy="14.2497" r="0.416667" fill="#727179" />
      <circle cx="9.25" cy="14.2497" r="0.416667" fill="#727179" />
      <circle cx="11.75" cy="14.2497" r="0.416667" fill="#727179" />
      <circle cx="14.25" cy="14.2497" r="0.416667" fill="#727179" />
    </svg>
  );
};

export default CalendarDuotoneIcon2;
