import React, { PropsWithChildren, useMemo } from "react";
import { Text } from "@common/Text";
import { palette } from "@palette";
import {
  Menu,
  MenuItemProps,
  MenuProps,
  PopoverOrigin,
  SwipeableDrawerProps,
  styled,
} from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { SelectItem } from "@common/Select/SelectItem";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { ITextProps } from "@common/Text/Text";
import { CheckMarkIcon } from "@assets/icons";
import { Stack } from "@mui/material";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

export type MenuItem = {
  disabled?: boolean;
  onSelect: (e: any) => void | null;
  label: string | React.ReactNode;
  icon?: JSX.Element;
  closeOnSelect?: boolean;
  labelProps?: ITextProps;
  selected?: boolean;
  customRender?: (element: JSX.Element) => JSX.Element;
  endAdornment?: React.ReactNode;
  tooltipProps?: { show: boolean; message: string };
};

export type MenuItems = {
  items: MenuItem[];
  anchorEl: HTMLElement | null;
  setAnchorEl: (el: HTMLElement | null) => void;
};

export interface NewDropdownMenuProps extends PropsWithChildren {
  items: MenuItem[];
  anchorEl: HTMLElement | null;
  setAnchorEl: (el: HTMLElement | null) => void;
  isSwipeableDrawerMobileView?: boolean;
  SwipeableOptions?: SwipeableDrawerProps;
  MenuOptions?: Omit<MenuProps, "open">;
  selectionBehaviour?: boolean;
  header?: JSX.Element;
  footer?: JSX.Element;
  customWidth?: string;
  padding?: string;
  sxSelectItem?: MenuItemProps["sx"];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  testId?: string;
}

const NewDropdownMenu = ({
  items,
  anchorEl,
  setAnchorEl,
  isSwipeableDrawerMobileView,
  SwipeableOptions,
  MenuOptions,
  children,
  selectionBehaviour,
  sxSelectItem,
  customWidth,
  padding,
  header,
  footer,
  anchorOrigin,
  transformOrigin,
  ...rest
}: NewDropdownMenuProps) => {
  const { isMobileView } = useCustomTheme();
  const shoudlRenderSwipeableDrawer =
    isMobileView && isSwipeableDrawerMobileView;

  const renderedContent = useMemo(() => {
    return (
      children ??
      items.map(
        (
          {
            label,
            icon,
            onSelect,
            disabled,
            closeOnSelect = true,
            selected,
            labelProps,
            customRender,
            endAdornment,
            tooltipProps,
          },
          index,
        ) => {
          const selectComponent = (
            <>
              {icon}{" "}
              <Text
                fontSize={14}
                lineHeight={"21.6px"}
                data-testid={
                  rest.testId ? `${rest.testId}-${index}` : undefined
                }
                color={
                  disabled
                    ? palette.neutral[70]
                    : labelProps?.color ?? palette.neutral[80]
                }
              >
                {label}
              </Text>
              <Stack direction="row" spacing={1} alignItems="center">
                {endAdornment && endAdornment}
                {selectionBehaviour && selected && (
                  <CheckMarkIcon fill={palette.neutral[80]} />
                )}
              </Stack>
            </>
          );
          return (
            <WithTooltipWrapper
              key={index}
              hasTooltip={!!tooltipProps}
              tooltipProps={tooltipProps}
            >
              <SelectItem
                sx={itemStyles(selectionBehaviour, disabled, sxSelectItem)}
                key={index}
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  if (!disabled) {
                    onSelect(e);
                    closeOnSelect && setAnchorEl(null);
                  }
                }}
              >
                {customRender ? customRender(selectComponent) : selectComponent}
              </SelectItem>
            </WithTooltipWrapper>
          );
        },
      )
    );
  }, [items, setAnchorEl]);

  if (shoudlRenderSwipeableDrawer)
    return (
      <SwipeableDrawerMobile
        open={Boolean(anchorEl)}
        anchor={"bottom"}
        onClose={() => setAnchorEl(null)}
        onOpen={() => undefined}
        disableRestoreFocus
        {...SwipeableOptions}
      >
        {header}
        {renderedContent}
        {footer}
      </SwipeableDrawerMobile>
    );
  else
    return (
      <StyledMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        selectionBehaviour={selectionBehaviour}
        anchorOrigin={
          anchorOrigin || {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: "top",
            horizontal: "right",
          }
        }
        customWidth={customWidth}
        padding={padding}
        {...MenuOptions}
      >
        {header}
        {renderedContent}
        {footer}
      </StyledMenu>
    );
};

interface StyledMenuProps extends MenuProps {
  selectionBehaviour?: boolean;
  customWidth?: string;
  padding?: string;
}
const StyledMenu = styled<React.FC<StyledMenuProps>>(Menu, {
  shouldForwardProp: (prop) =>
    prop !== "selectionBehaviour" && prop !== "customWidth",
})(({ selectionBehaviour, theme, customWidth, padding }) => ({
  "& .MuiPaper-root": {
    minWidth: selectionBehaviour ? "240px" : "auto",
    backgroundColor: selectionBehaviour
      ? theme.palette.neutral.white
      : theme.palette.background.dimmedWhite,
    width: customWidth || "auto",
    padding,
  },
}));

const itemStyles = (
  selectionBehaviour?: boolean,
  disabled?: boolean,
  customSx?: any,
) => {
  return {
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-between",

    ...(disabled && {
      cursor: "default",
      "&:hover": { backgroundColor: "#8F8F8F" },
    }),
    ...(selectionBehaviour && {
      "&:hover": { backgroundColor: palette.neutral[10] },
    }),
    ...customSx,
  };
};

export const WithTooltipWrapper = ({
  hasTooltip,
  children,
  tooltipProps,
  fullWidth,
}: {
  hasTooltip: boolean;
  children: any;
  tooltipProps?: {
    show: boolean;
    message: string;
    placement?: "bottom" | "left" | "top" | "right";
  };
  fullWidth?: boolean;
}) => {
  if (!hasTooltip || !tooltipProps) return <>{children}</>;

  const { show, ...rest } = tooltipProps;

  return (
    <CustomToolTip showToolTip={show} {...rest} fullWidth={fullWidth}>
      {children}
    </CustomToolTip>
  );
};

export default NewDropdownMenu;
