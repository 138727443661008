import React from "react";
import { RHFSelect } from "@common/Select";
import { useFormContext } from "react-hook-form";
import { ArrowDownChevron } from "@assets/icons";
import { palette } from "@palette";

type OwnershipTypeSelectProps = {
  name: string;
  businessTypeName?: string;
  label: string;
  disabled?: boolean;
  initialValue?: "private" | "public";
  hasArrowDownIcon?: boolean;
};

const OwnershipTypeSelect = ({
  name,
  label,
  disabled,
  businessTypeName = "businessDetails.businessType",
  initialValue,
  hasArrowDownIcon = false,
}: OwnershipTypeSelectProps) => {
  const [allowedOwnershipTypes, setAllowedOwnershipTypes] = React.useState<
    string[]
  >(["Private", "Public"]);
  const { watch, setValue } = useFormContext();
  const isMount = React.useRef(true)

  React.useEffect(() => {
    switch (watch(businessTypeName)) {
      case "individual_sole_proprietorship":
        setAllowedOwnershipTypes(["Private"]);
        break;
      case "tax_exempt_organization":
      case "government_agency":
        setAllowedOwnershipTypes(["Public"]);
        break;
      default:
        setAllowedOwnershipTypes(["Public", "Private"]);
    }
    if(isMount.current) {
      isMount.current = false
      return;
    }
    switch (watch(businessTypeName)) {
      case "individual_sole_proprietorship":
        setValue(name, "private");
        break;
      case "tax_exempt_organization":
      case "government_agency":
        setValue(name, "public");
        break;
      default:
        setValue(name, "private");
    }
  }, [watch(businessTypeName)]);

  return (
    <RHFSelect
      name={name}
      label={label}
      disabled={disabled || allowedOwnershipTypes.length === 1}
      options={allowedOwnershipTypes.map((item) => ({
        value: item.toLowerCase(),
        label: item,
      }))}
      SelectProps={{
        ...(hasArrowDownIcon && {
          IconComponent: () => (
            <ArrowDownChevron
              width={16}
              height={9}
              color={palette.gray[300]}
            />
          ),
        }),
      }}
    />
  );
};

export default OwnershipTypeSelect;
