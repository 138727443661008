import { palette } from "@palette";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import CheckedCheckbox from "@assets/images/pdf_checked_checkbox.png";
import UncheckedCheckbox from "@assets/images/pdf_unchecked_checkbox.png";

// Create styles
const styles = StyleSheet.create({
  header: {
    padding: "6px 8px",
    border: `1px solid ${palette.neutral[80]}`,
    backgroundColor: palette.neutral[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerText: {
    color: "#FFF",
    letterSpacing: "-0.14px",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Give Whyte",
  },
  detailsBody: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRight: `1px solid ${palette.gray[100]}`,
    fontFamily: "Give Whyte",
  },
  detailsCell: {
    display: "flex",
    padding: "4px 8px",
    flexDirection: "column",
    gap: "4px",
    flexGrow: 1,
    borderBottom: `1px solid ${palette.gray[100]}`,
    borderLeft: `1px solid ${palette.gray[100]}`,
  },
  detailsCellLabel: {
    color: palette.neutral[70],
    fontSize: "10px",
    fontFamily: "Give Whyte",
    lineHeight: "120%",
    letterSpacing: "-0.1px",
  },
  detailsCellValue: {
    color: palette.neutral[100],
    fontWeight: "normal",
    fontSize: "10px",
    fontFamily: "Give Whyte",
    lineHeight: "120%" /* 9.6px */,
    minHeight: "9.6px",
    letterSpacing: "-0.1px",
  },
  detailsCellSubtitleContainer: {
    padding: "8px",
    border: `1px solid ${palette.gray[100]}`,
    borderTop: "none",
    backgroundColor: palette.liftedWhite[100],
    width: "100%",
  },
  detailsCellSubtitleText: {
    color: palette.neutral[100],
    fontSize: "10px",
    fontFamily: "Give Whyte",
    lineHeight: "120%",
    letterSpacing: "-0.1px",
  },
  subCellText: { fontSize: "8px", flex: 1 },
});

export type PdfSectionType = {
  title: string;
  details: (DetailsItemType | SubDetailsItemType)[];
};

const PdfApplicationSection = ({ section }: { section: PdfSectionType }) => {
  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.headerText}>{section.title}</Text>
      </View>
      <View style={styles.detailsBody}>
        {section.details
          .filter((item) => "label" in item)
          .map((item, index) => (
            <DetailsContainer key={index} item={item as DetailsItemType} />
          ))}
        {section.details
          .filter((item) => "subTitle" in item)
          .map((item, index) => (
            <SubDetailsContainer
              key={index}
              item={item as SubDetailsItemType}
            />
          ))}
      </View>
    </View>
  );
};

type DetailsItemType = {
  label: string;
  value: string;
  width: string;
  direction?: "column" | "row" | "row-reverse" | "column-reverse";
  hasCheckbox?: boolean;
  checked?: boolean;
};

const DetailsContainer = ({ item }: { item: DetailsItemType }) => {
  return (
    <View
      key={item.label}
      style={{
        ...styles.detailsCell,
        width: item.width || "100%",
        flexDirection: item.direction || "column",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={styles.detailsCellLabel}>{item.label}:</Text>
        {item.hasCheckbox && (
          <Image
            src={item.checked ? CheckedCheckbox : UncheckedCheckbox}
            style={{ height: 14, width: 14 }}
          />
        )}
      </View>
      {Array.isArray(item.value) ? (
        <MultipleValues values={item.value} />
      ) : (
        <Text style={styles.detailsCellValue}>{item.value}</Text>
      )}
    </View>
  );
};

type MultipleValuesProps = { values: string[] };

const MultipleValues = ({ values }: MultipleValuesProps) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "8px",
      }}
    >
      {values.map((val) => (
        <Text key={val} style={[styles.detailsCellValue, styles.subCellText]}>
          {val}
        </Text>
      ))}
    </View>
  );
};

type SubDetailsItemType = {
  subTitle: string;
  data: DetailsItemType[];
};

const SubDetailsContainer = ({ item }: { item: SubDetailsItemType }) => {
  return (
    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <View style={styles.detailsCellSubtitleContainer}>
        <Text style={styles.detailsCellSubtitleText}>{item.subTitle}</Text>
      </View>
      {item.data?.map((subItem, index) => (
        <DetailsContainer key={index} item={subItem} />
      ))}
    </View>
  );
};

export default PdfApplicationSection;
