import { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { Drawer, DrawerProps } from "@mui/material";
import { palette } from "@palette";

const FullWindowModal = ({
  children,
  ...props
}: { children: React.ReactNode } & DrawerProps) => {
  const modal = useModal();
  return (
    <Drawer
      {...muiDialogV5(modal)}
      PaperProps={{
        style: {
          width: "100%",
          top: "80px",
          padding: "16px",
          background: palette.liftedWhite.main,
          boxShadow: "-8px 0px 50px 0px rgba(0, 0, 0, 0.15)",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: "rgba(53, 53, 53, 0.10)",
          },
        },
      }}
      anchor="bottom"
      {...props}
    >
      <Stack
        direction="column"
        alignItems="stretch"
        position="relative"
      >
        {children}
      </Stack>
    </Drawer>
  );
};

export default FullWindowModal;
