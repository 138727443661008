import React from "react";

type Props = {
  height?: string | number;
  width?: string | number;
  color?: string;
};

const PenSimpleIcon = ({
  color = "#8F8F8F",
  height = 41,
  width = 41,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M35.1344 14.6156L30.5 19.25L21.75 10.5L26.3844 5.86558C26.6188 5.63134 26.9366 5.49976 27.268 5.49976C27.5993 5.49976 27.9172 5.63134 28.1516 5.86558L35.1344 12.8437C35.2511 12.9599 35.3437 13.0979 35.4068 13.2499C35.47 13.402 35.5026 13.565 35.5026 13.7296C35.5026 13.8943 35.47 14.0573 35.4068 14.2093C35.3437 14.3614 35.2511 14.4994 35.1344 14.6156Z"
        fill={color}
      />
      <path
        d="M36.0172 11.964L29.0359 4.98121C28.8038 4.74901 28.5282 4.56481 28.2248 4.43914C27.9215 4.31346 27.5963 4.24878 27.268 4.24878C26.9396 4.24878 26.6145 4.31346 26.3111 4.43914C26.0078 4.56481 25.7322 4.74901 25.5 4.98121L6.23282 24.25C5.99967 24.4813 5.81481 24.7566 5.68901 25.06C5.56321 25.3634 5.49896 25.6887 5.50001 26.0172V33C5.50001 33.663 5.7634 34.2989 6.23225 34.7677C6.70109 35.2366 7.33697 35.5 8.00001 35.5H14.9828C15.3113 35.501 15.6366 35.4368 15.94 35.311C16.2434 35.1852 16.5187 35.0003 16.75 34.7672L36.0172 15.5C36.2494 15.2678 36.4336 14.9922 36.5593 14.6888C36.685 14.3855 36.7496 14.0603 36.7496 13.732C36.7496 13.4036 36.685 13.0785 36.5593 12.7752C36.4336 12.4718 36.2494 12.1962 36.0172 11.964ZM14.9828 33H8.00001V26.0172L21.75 12.2672L28.7328 19.25L14.9828 33ZM30.5 17.4812L23.5172 10.5L27.2672 6.74996L34.25 13.7312L30.5 17.4812Z"
        fill={color}
      />
    </svg>
  );
};

export default PenSimpleIcon;
