const codeValidator = (
  language: string,
  value: any,
  onError?: (error?: Error) => void,
) => {
  return JSONValidator(value, onError);
};

export type TValidator = (value: any, onError?: (error?: Error) => void) => boolean;

const JSONValidator: TValidator = (value, onError) => {
  try {
    JSON.parse(value);
    if (onError) onError(undefined);
    return true;
  } catch (error) {
    if (onError) onError(error as Error);
    return false;
  }
};

export default codeValidator;
