import { Stack } from "@mui/material";
import ModalSectionTitle from "../atoms";
import { RHFCheckbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { ComingSoonTag } from "@common/Tag";
import { styled } from "@mui/material";
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type ConfigurationSectionProps = {
  title: string;
  titleSize?: "large" | "medium";
  isEdit?: boolean;
};

const MembershipsConfiguration = ({
  title,
  titleSize = "large",
  isEdit = false,
}: ConfigurationSectionProps) => {
  return (
    <Stack direction="column" gap={isEdit ? 2 : 4} flexGrow={1}>
      <FadeUpWrapper delay={100}>
        <ModalSectionTitle title={title} titleSize={titleSize} />
      </FadeUpWrapper>
      <FadeUpWrapper delay={200}>
        <Stack direction="column" gap={2}>
          <Text
            color={palette.black[100]}
            variant="headline"
            fontWeight="book"
            fontSize="18px"
            lineHeight="21.6px"
            sx={{ letterSpacing: "-0.18" }}
          >
            Payment form
          </Text>
          <MaxSubscriptionsInput />
        </Stack>
      </FadeUpWrapper>
      <FadeUpWrapper delay={300}>
        <Stack direction="column" gap={2}>
          <Text
            color={palette.black[100]}
            variant="headline"
            fontWeight="book"
            fontSize="18px"
            lineHeight="21.6px"
            sx={{ letterSpacing: "-0.18" }}
          >
            Customer
          </Text>
          <RHFCheckbox
            name="configuration.customer_pays_fees"
            label="Customer pays the credit card fee"
            sx={{
              "& .MuiButtonBase-root.MuiCheckbox-root": {
                height: 24,
              },
            }}
          />
          <Stack direction="column">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <RHFCheckbox
                disabled
                name="configuration.browse_more"
                label="Browse more Payment forms"
                sx={{
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    height: 24,
                  },
                }}
              />
              <ComingSoonTag />
            </Stack>
            <Text
              color={palette.gray[300]}
              fontSize="16px"
              fontWeight="book"
              lineHeight="19.2px"
              ml={4}
            >
              Allow users to explore more of your published Payment forms.
            </Text>
          </Stack>
        </Stack>
      </FadeUpWrapper>
    </Stack>
  );
};

const MaxSubscriptionsInput = () => {
  return (
    <SubscriptionsInput>
      <Text
        variant="body"
        color={palette.black[100]}
        fontSize="16px"
        lineHeight="19.2px"
        fontWeight="book"
      >
        Max subscriptions that can be purchased at once
      </Text>
      <RHFCustomIntegerInput
        name="configuration.max_subscriptions"
        width="82px"
      />
    </SubscriptionsInput>
  );
};

const SubscriptionsInput = styled(Stack)(({ theme }) => ({
  padding: "8px",
  gap: "8px",
  borderRadius: "8px",
  background: palette.liftedWhite[100],

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default MembershipsConfiguration;
