import React, { Dispatch, SetStateAction } from "react";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { QuantityPaymentCard } from "@components/PaymentForm/PaymentCard";
import CountdownContainer from "./CoundownContainer";
import { palette } from "@palette";
import { AlertIcon, TrophyLightIcon } from "@assets/icons";
import { TicketType } from "@redux/slices/sweepstakes";
import { SweepstakeData } from "../MiniBuilder/BuilderFormProvider";
import { useAppSelector } from "@redux/hooks";
import { selectCart, setCartItem } from "@redux/slices/cart";
import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useDispatch } from "react-redux";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { useCheckTicketsThreshold } from "@common/PublicForm/hooks/useCheckTicketsThreshold";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";

const SweepstakeWrapper = ({
  sweepstakesEnded,
  winnerName,
  isDesktop,
  setSweepstakesEnded,
  data,
  handleChange,
  activeCards,
  setActiveCards,
}: any) => {
  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        ...(!isDesktop && {
          height: "calc(100% - 64px)",
        }),
      }}
      alignItems="center"
      width="100%"
    >
      <Stack
        direction="column"
        gap={2}
        alignItems="stretch"
        sx={{
          background: "transparent",
          width: "100%",
        }}
      >
        {winnerName ? (
          <FadeUpWrapper delay={100}>
            <WinnerBox winnerName={winnerName} />
          </FadeUpWrapper>
        ) : (
          <>
            {sweepstakesEnded && (
              <FadeUpWrapper delay={100}>
                <Text variant="h6" fontWeight="medium" color="#292727">
                  Choose your ticket
                </Text>
              </FadeUpWrapper>
            )}
            <Stack
              direction={isDesktop ? "row" : "column"}
              justifyContent="space-between"
              alignItems={isDesktop ? "center" : "start"}
            >
              {sweepstakesEnded ? (
                <>
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    gap={2}
                    width="100%"
                  >
                    <FadeUpWrapper delay={150}>
                      <CountdownContainer
                        sweepstakesEnded={sweepstakesEnded}
                        setSweepstakesEnded={setSweepstakesEnded}
                        isDesktop={isDesktop}
                        fontSize="18px"
                        endDate={
                          typeof data?.general.date === "string"
                            ? new Date(data.general.date)
                            : data?.general.date
                        }
                      />
                    </FadeUpWrapper>
                    <FadeUpWrapper delay={200}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        width="100%"
                        sx={{
                          boxShadow:
                            "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                        }}
                        gap={2}
                      >
                        <AlertIcon />
                        <Text>The sweepstake has ended</Text>
                      </Stack>
                    </FadeUpWrapper>
                  </Stack>
                </>
              ) : (
                <Stack direction="column" alignItems="flex-start" flexGrow={1}>
                  <FadeUpWrapper delay={150}>
                    <Text variant="h6" fontWeight="medium" color="#292727">
                      Choose your ticket
                    </Text>
                  </FadeUpWrapper>
                  <FadeUpWrapper delay={200}>
                    <CountdownContainer
                      sweepstakesEnded={sweepstakesEnded}
                      setSweepstakesEnded={setSweepstakesEnded}
                      isDesktop={isDesktop}
                      endDate={
                        typeof data?.general.date === "string"
                          ? new Date(data.general.date)
                          : data?.general.date
                      }
                    />
                  </FadeUpWrapper>
                </Stack>
              )}
            </Stack>

            <Stack gap={1} direction="column" width="100%">
              {data?.tickets.list
                .filter((item: TicketType) => item.active === true)
                .map((ticket: any, index: number) => {
                  return (
                    <FadeUpWrapper
                      key={ticket.id}
                      delay={200 + (index + 1) * 50}
                    >
                      <ErrorCatcher errorID="QuantityPaymentCard">
                        <QuantityPaymentCard
                          imageURL={ticket.thumbnail}
                          title={ticket.name}
                          description={ticket.description.text}
                          price={parseFloat(ticket.amount)}
                          quantity={ticket.in_stock}
                          id={ticket.id}
                          disabled={sweepstakesEnded}
                          category="sweepstakes"
                          maxPurchase={data.tickets.max_tickets}
                          onChange={handleChange}
                          name={ticket.name}
                          selected={activeCards.includes(ticket.id)}
                          shouldDisplayLeftTickets={ticket.display_tickets}
                          setActiveCards={setActiveCards}
                        />
                      </ErrorCatcher>
                    </FadeUpWrapper>
                  );
                })}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const SweepstakesDetailsForm = ({
  data,
  drawerOpen,
}: {
  data: SweepstakeData;
  isLoading: boolean;
  drawerOpen?: Partial<{
    ID: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    renderCheckout: () => React.ReactNode;
  }>;
}) => {
  const { cartItems } = useAppSelector(selectCart);
  const dispatch = useDispatch();
  const [sweepstakesEnded, setSweepstakesEnded] = React.useState(false);
  const { isDesktop } = getPublishedFormDeviceType();
  const winnerName = "";
  const [activeCards, setActiveCards] = React.useState<number[]>([]);
  const { isTicketsThresholdReached } = useCheckTicketsThreshold(cartItems);

  const handleChange = (id: number) => {
    if (isTicketsThresholdReached(data.tickets.max_tickets)) return;
    const selectedItemIds = cartItems.map((item) => item.productVariantID);
    const ticket = data?.tickets.list.find((s: any) => s.id === id);
    dispatch(
      setCartItem({
        name: ticket?.name,
        productVariantID: id,
        price: Number(ticket?.amount) || 0,
        recurringMax: null,
        quantity: 1,
      }),
    );
    setActiveCards((prev) => {
      const filteredPreviousList = prev.filter((id) =>
        selectedItemIds.includes(id),
      );
      return [...filteredPreviousList, id];
    });
  };

  const reset = () => {
    setActiveCards([]);
  };

  // on success payment, cartitems is set empty, so resets the selected ticket item also
  React.useEffect(() => {
    if (cartItems.length === 0) {
      reset();
    }
  }, [cartItems]);

  return (
    <>
      {!isDesktop ? (
        drawerOpen &&
        ComponentRegistry.show(drawerOpen.ID || "", {
          open: !!drawerOpen.open,
          setOpen: drawerOpen.setOpen,
          _renderComponent: () => (
            <>
              <SweepstakeWrapper
                sweepstakesEnded={sweepstakesEnded}
                winnerName={winnerName}
                isDesktop={isDesktop}
                setSweepstakesEnded={setSweepstakesEnded}
                data={data}
                handleChange={handleChange}
                activeCards={activeCards}
                setActiveCards={setActiveCards}
              />
            </>
          ),
          _renderActions: () =>
            drawerOpen.renderCheckout && drawerOpen.renderCheckout(),
        })
      ) : (
        <SweepstakeWrapper
          sweepstakesEnded={sweepstakesEnded}
          winnerName={winnerName}
          isDesktop={isDesktop}
          setSweepstakesEnded={setSweepstakesEnded}
          data={data}
          handleChange={handleChange}
          activeCards={activeCards}
          setActiveCards={setActiveCards}
        />
      )}
    </>
  );
};

const WinnerBox = ({ winnerName }: { winnerName: string }) => {
  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <Text variant="headline" color={palette.neutral[600]}>
        Sweepstakes has ended and winner has been chosen
      </Text>
      <Stack
        direction="column"
        gap={0.5}
        sx={winnerBoxStyle}
        alignItems="center"
      >
        <TrophyLightIcon />
        <Text variant="headline" color={palette.neutral[600]}>
          The winner of this sweepstake is
        </Text>
        <Text
          variant="h3"
          fontStyle="italic"
          color={palette.primary.main}
          fontWeight="medium"
          fontSize="32px"
        >
          {winnerName}
        </Text>
      </Stack>
    </Stack>
  );
};

const Image = styled("img")({
  borderRadius: "5px",
  height: 241,
  width: 342,
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const winnerBoxStyle = {
  padding: "16px 48px",
  width: "100%",
  background: "rgba(115, 64, 137, 0.1)",
  borderRadius: "12px",
};

export default SweepstakesDetailsForm;
