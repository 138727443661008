type Props = {
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
};

const BriefcaseBrandedIcon = ({
  width = 20,
  height = 19,
  fill = "#575353",
  stroke = "#575353",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2187 11.3125C13.1114 11.313 15.958 10.5887 18.4986 9.20583L18.5312 9.18808V9.15094V5.25V5.1875H18.4688H1.96875H1.90625V5.25V9.15188V9.18902L1.93888 9.20678C4.4797 10.5891 7.32624 11.313 10.2187 11.3125ZM10.2187 11.3125L10.2188 11.25M10.2187 11.3125L10.2188 11.25M10.2188 11.25C7.33669 11.2505 4.5004 10.5292 1.96875 9.15188V5.3125V5.25H2.03125H18.4062H18.4688V5.3125V9.15094C15.9373 10.5289 13.1009 11.2505 10.2188 11.25ZM5.71875 3.8125H5.78125V3.75V3C5.78125 2.41984 6.01172 1.86344 6.42195 1.4532C6.83219 1.04297 7.38859 0.8125 7.96875 0.8125H12.4688C13.0489 0.8125 13.6053 1.04297 14.0155 1.4532C14.4258 1.86344 14.6562 2.41984 14.6562 3V3.75V3.8125H14.7188H18.4688C18.85 3.8125 19.2156 3.96395 19.4852 4.23353C19.7548 4.50312 19.9062 4.86875 19.9062 5.25V17.25C19.9062 17.6312 19.7548 17.9969 19.4852 18.2665C19.2156 18.536 18.85 18.6875 18.4688 18.6875H1.96875C1.5875 18.6875 1.22187 18.536 0.952284 18.2665C0.6827 17.9969 0.53125 17.6312 0.53125 17.25V5.25C0.53125 4.86875 0.6827 4.50312 0.952284 4.23353C1.22187 3.96395 1.5875 3.8125 1.96875 3.8125H5.71875ZM7.15625 3.75V3.8125H7.21875H13.2188H13.2812V3.75V3C13.2812 2.78451 13.1956 2.57785 13.0433 2.42548C12.8909 2.2731 12.6842 2.1875 12.4688 2.1875H7.96875C7.75326 2.1875 7.5466 2.2731 7.39423 2.42548C7.24185 2.57785 7.15625 2.78451 7.15625 3V3.75ZM18.4688 17.3125H18.5312V17.25V10.8403V10.7402L18.4413 10.7842C15.8812 12.0366 13.0688 12.6876 10.2188 12.6875C7.36876 12.688 4.55634 12.0374 1.99621 10.7851L1.90625 10.7411V10.8412V17.25V17.3125H1.96875H18.4688ZM8.03125 9C8.03125 8.81766 8.10368 8.6428 8.23261 8.51386C8.36155 8.38493 8.53641 8.3125 8.71875 8.3125H11.7188C11.9011 8.3125 12.076 8.38493 12.2049 8.51386C12.3338 8.6428 12.4062 8.81766 12.4062 9C12.4062 9.18234 12.3338 9.35721 12.2049 9.48614C12.076 9.61507 11.9011 9.6875 11.7188 9.6875H8.71875C8.53641 9.6875 8.36155 9.61507 8.23261 9.48614C8.10368 9.3572 8.03125 9.18234 8.03125 9Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.125"
      />
    </svg>
  );
};

export default BriefcaseBrandedIcon;
