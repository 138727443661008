import { useCallback, useState } from "react";
import { ErrorEmitter, GiveErrorEmitter } from "./ErrorEmitter";

type AllFunctionsCallerType<T extends any[] = any[]> = (...args: T) => void;

export type CallAll = <T extends any[]>(
  ...fns: Array<AllFunctionsCallerType<Array<T>> | null | undefined>
) => void;

type ComponentsTree = {
  componentName: string;
  child?: ComponentsTree | null;
};

interface ErrorInfo {
  getComponentNameFromErrorStack: (
    stack: string | undefined | null,
  ) => string[];
  callAll: CallAll;
  logError: (error: Error, info: React.ErrorInfo) => void;
  ErrorEmitter: GiveErrorEmitter;
}

const parseErrorStack = (errorStack: string): ComponentsTree | null => {
  const stackLines = errorStack.split("\n");

  const componentRegex = /\sat\s(\S+)\s\((\S+\.js|\.tsx|\.ts):(\d+):(\d+)\)/;

  let currentComponent: ComponentsTree | null = null;

  for (const line of stackLines) {
    const match = componentRegex.exec(line);

    if (match) {
      if (match[1] === "RenderedRoute") break;

      const componentName = match[1];
      const componentNode: ComponentsTree = {
        componentName,
        child: currentComponent,
      };

      currentComponent = componentNode;
    }
  }

  return currentComponent;
};

export const useErrorUtilities = (): ErrorInfo => {
  const [componentStack, setComponentStack] = useState<
    string | null | undefined
  >();

  const callAll: CallAll = (...fns) =>
    ((...args) => {
      fns.forEach((fn) => fn && fn(...args));
    })();

  const logError = (error: Error, info: React.ErrorInfo): void => {
    setComponentStack(info.componentStack);
    console.error("Error occurred:", error);

    console.log("Error:", { err: info.componentStack });

    console.groupCollapsed("Error details:");
    console.log("Name:", error.name);
    console.log("Message:", error.message);
    getComponentNameFromErrorStack(info.componentStack);

    console.groupEnd();
  };

  const getComponentNameFromErrorStack = useCallback(
    (stack: string | undefined | null): string[] => {
      if (!stack) return [];
      const componentsTree = parseErrorStack(stack);

      if (componentsTree) {
        console.log("Feature Component:", componentsTree.componentName);

        let component = componentsTree.child;
        while (component?.child) {
          component = component.child;
        }
        console.log("Crashing Component", component?.componentName);
      }
      return [];
    },
    [componentStack],
  );

  return {
    getComponentNameFromErrorStack,
    callAll,
    logError,
    ErrorEmitter: ErrorEmitter,
  };
};
