import { businessTypes } from "@common/BusinessProfileInputs/BusinessTypeSelect";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import RiskStatusChip from "@common/Tag/RiskStatusChip";
import { toCountryName } from "@hooks/helpers";
import { getCountryNameFromCode } from "@utils/country_dial_codes";
import { format } from "date-fns";
import {
  IParsedData,
  TBusinessProfileInfo,
  TMerchantInfo,
  TPrimaryAccountHolderAdress,
} from "../data.types";

export const parseTaxID = (id: string) => {
  if (!id) return id;
  if (id?.length > 2) return id.slice(0, 2) + "-" + id.slice(2);
  return id;
};

// 999999999 to 99-9999999
export const formatEIN = (id: string) => {
  if (!id) return id;
  if (id?.length > 2) return id.replace(/^(\d{2})(\d{7})$/, "$1-$2");
  return id;
};

// 999999999 to 999-99-9999
export const formatSSN = (id: string) => {
  if (!id) return id;
  if (id?.length > 2)
    return id.replace(/\D/g, "").replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3");
  return id;
};

export const betterPhoneNumber = (phoneNumber: string): string => {
  // Check if the phoneNumber has a valid length
  const isValidLength = phoneNumber.length === 11;

  if (!isValidLength) {
    return phoneNumber;
  }

  const formatted = `+${phoneNumber.substring(0, 1)} (${phoneNumber.substring(
    1,
    4,
  )}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7)}`;

  return formatted;
};

export const parsePhoneNumber = (number = "") => {
  if (!number) return "";
  if (number.includes("+")) return number;
  return `+${number}`;
};

export const formatPrimaryAccountHolderAddress = (
  address: TPrimaryAccountHolderAdress,
) => {
  if (!address || !address.line1) return "";
  const { line1, city, state, country, zip } = address;
  return `${zip} ${line1}, ${city}, ${state}, ${getCountryNameFromCode(
    country,
  )}`;
};

const useGetSectionItems = (data: IParsedData, isEnterprise?: boolean) => {
  const {
    businessProfile,
    businessAddress,
    primaryAccountHolder,
    merchantInfo,
  } = data;
  const businessProfileItems = [
    {
      title: "Federal Tax ID",
      value: parseTaxID(businessProfile.taxID),
      size: 6,
    },
    {
      title: "Legal Name",
      value: businessProfile.legalName,
      size: 6,
    },
    {
      title: "Business Type",
      value:
        businessTypes[
          businessProfile.businessType as keyof typeof businessTypes
        ],
      size: 6,
    },
    {
      title: "Business Ownership Type",
      value: capitalizeFirstLetter(businessProfile.ownershipType),
      size: 6,
    },
    {
      title: "Doing Business As",
      value: businessProfile.dba,
      size: 6,
    },
    {
      title: "Date business opened",
      value: businessProfile.businessOpenedAt,
      size: 6,
    },
    {
      title: "Contact Phone",
      value: parsePhoneNumber(businessProfile.contactPhone),
      size: 6,
      isPhoneNumber: true,
    },
    {
      title: "Age of Business",
      value: businessProfile.ageOfBusiness,
      size: 6,
      isValue: true,
      suf: Number(businessProfile.ageOfBusiness) > 1 ? "years" : "year",
    },
  ];

  const businessAddressItems = [
    {
      title: "Country",
      value: getCountryNameFromCode(businessAddress.country),
      size: 6,
    },
    {
      title: "Street Address",
      value: businessAddress.address,
      size: 6,
    },
    {
      title: "City",
      value: businessAddress.city,
      size: 6,
    },
    {
      title: "State",
      value: businessAddress.state,
      size: 6,
    },
    {
      title: "Zip Code",
      value: businessAddress.zip,
      isValue: true,
      size: 6,
    },
  ];

  const primaryAccountHolderItems = [
    {
      title: "Full name",
      value:
        primaryAccountHolder.lastName || primaryAccountHolder.firstName
          ? `${primaryAccountHolder.lastName} ${primaryAccountHolder.firstName}`
          : "",
      size: 6,
    },
    {
      title: "Email",
      value: primaryAccountHolder.email,
      size: 6,
    },
    {
      title: "Date of Birth",
      value: primaryAccountHolder.dateOfBirth
        ? format(
            new Date(primaryAccountHolder.dateOfBirth * 1000),
            "MM/dd/yyyy",
          )
        : "",
      size: 6,
    },
    {
      title: "SSN",
      value: primaryAccountHolder.ssnLast4
        ? `xxxxx ${primaryAccountHolder.ssnLast4}`
        : "",
      size: 6,
      hidden: true,
    },
    {
      title: "Contact number",
      value: parsePhoneNumber(primaryAccountHolder.phoneNumber),
      size: 6,
      isPhoneNumber: true,
    },
    {
      title: "Country of Citizenship",
      value: toCountryName(primaryAccountHolder.citizenship),
      size: 6,
    },
    {
      title: "Country of Residence",
      value: toCountryName(primaryAccountHolder.countryOfResidence),
      size: 6,
    },
    {
      title: "Address",
      size: 12,
      value: formatPrimaryAccountHolderAddress(primaryAccountHolder.address),
      hidden: true,
    },
  ];

  const merchantInfoItems = getMerchantInfoItems({
    merchantInfo,
    businessProfile,
  });

  return {
    businessAddressItems,
    businessProfileItems,
    primaryAccountHolderItems,
    merchantInfoItems,
  };
};

/*
  Put split:true, not at the end where the split shoudl be done,
  but at the first element where we shoudl start a new chunk
*/
const getMerchantInfoItems = (data: {
  merchantInfo: TMerchantInfo;
  businessProfile: TBusinessProfileInfo;
}) => {
  return [
    {
      title: "Signup Date",
      value: data.merchantInfo.createdAt,
      size: 6,
    },
    {
      title: "Signup type",
      value: data.merchantInfo.signupTypeDisplayName,
      size: 6,
    },
    {
      title: "Merchant Name",
      value: data.merchantInfo.merchantName,
      size: 6,
    },
    {
      title: "Provider",
      value: data.merchantInfo.enterprise,
      size: 6,
    },
    {
      title: "Merchant Category Code (MCC)",
      value:
        data.merchantInfo.category && data.merchantInfo.categoryCodeName
          ? `${data.merchantInfo.category || ""} - ${
              data.merchantInfo.categoryCodeName || ""
            }`
          : "Not assigned",
      size: 6,
    },
    {
      title: "Merchant Risk Category",
      value: data.merchantInfo.merchantRiskStatus ? (
        <RiskStatusChip riskLevelName={data.merchantInfo.merchantRiskStatus} />
      ) : (
        "Not assigned"
      ),
      size: 6,
    },
    {
      title: "Estimated Annual Revenue (USD)",
      value: +data.merchantInfo.estimatedAnnualRevenue,
      size: 12,
      isValue: true,
      isAmount: true,
    },
    {
      title: `Billing Descriptor (${data.merchantInfo.billingDescriptorPrefix})`,
      value: data.merchantInfo.billingDescriptor,
      size: 6,
    },
    {
      title: "Customer Service Phone Number",
      value: betterPhoneNumber(data.merchantInfo.servicePhoneNumber),
      size: 6,
      isPhoneNumber: true,
    },
    {
      title: "Website URL",
      value: data.merchantInfo.websiteURL || "",
      size: 12,
      prefix: "https://",
      link: `https://${data.merchantInfo.websiteURL}`,
      canBeCopied: true,
    },
    {
      title: "Permalink",
      value: data.merchantInfo.merchantSlug,
      size: 12,
      prefix: `https://${data.merchantInfo.parentSlug}.givepayments.com/`,
      link: `https://${data.merchantInfo.parentSlug}.givepayments.com/${data.merchantInfo.merchantSlug}`,
      canBeCopied: true,
    },
    {
      title: "Countries Serviced Outside the USA/Canada",
      value: data.merchantInfo?.serviceCountriesOutUSCanada
        ? data.merchantInfo?.countriesServicedOutside
        : "None",
      size: 12,
    },
    {
      title: "Average Ticket Amount",
      value: data.merchantInfo.averageTicketAmount ?? 0,
      isValue: true,
      isAmount: true,
      size: 6,
      split: true,
      suf: "USD",
    },
    {
      title: "High Ticket Amount",
      value: data.merchantInfo.highTicketAmount ?? 0,
      isValue: true,
      isAmount: true,
      size: 6,
      suf: "USD",
    },
    {
      title: `Purpose or Mission of Merchant`,
      value: (
        <span style={{ wordBreak: "break-word" }}>
          {data.merchantInfo.businessPurpose || data.merchantInfo?.description}
        </span>
      ),
      size: 12,
      clampText: false,
      split: true,
    },
  ];
};

export default useGetSectionItems;
