import * as React from "react";
import { useCheckDuplicateTeamMember } from "@hooks/merchant-api/manage-team";
import { Customer } from "@customTypes/customer.types";
import { fromUnixTime } from "date-fns";

type FormInput = {
  [key: string]: any;
};

export const FormContext = React.createContext<FormContextType | null>(null);

export type FormContextType = {
  formData: FormInput;
  setFormValues: (values: FormInput) => void;
};

export default function CustomerFormProvider({
  children,
  data,
}: {
  data?: Customer;
  children: React.ReactNode;
}) {

  const [formData, setFormData] = React.useState({
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    email: data?.email ? data.email : "",
    phone: data?.phoneNumber ? `+${data?.phoneNumber || 1}` : "",
    dateOfBirth: data?.dateOfBirth
      ? typeof data?.dateOfBirth === "number"
        ? fromUnixTime(data?.dateOfBirth)
        : null
      : null,
    employer: data?.employer || "",
    occupation: data?.occupation || "",
    avatarURL: data?.avatarURL,
    address: data?.address
      ? {
          country: data?.address?.country,
          city: data?.address?.city,
          state: data?.address?.state,
          zip: data?.address?.zip,
          line1: data?.address?.line1,
        }
      : null,
    notes: data?.notes || "",
  });

  const setFormValues = (values: FormInput) => {
    setFormData((prevValues) => ({
      ...prevValues,
      ...values,
    }));
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormValues,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export const useFormData = () =>
  React.useContext(FormContext) as FormContextType;
