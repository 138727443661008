import { BtnBGTypes } from "@common/Button/Button_V2";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { Stack } from "@mui/material";
import { ActionButton, getTooltipProps } from "./ChallengeActions";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { checkPortals } from "@utils/routing";

type Props = {
  needsVerification?: boolean;
  isDoingActions?: boolean;
  confirmAction: () => void;
  rejectAction: () => void;
};

const RejectApprove = ({
  needsVerification = false,
  isDoingActions = false,
  confirmAction,
  rejectAction,
}: Props) => {
  const { merchant_underwriting } = useEnterprisePermissions();
  const { isAcquirerEnterprises } = checkPortals();

  const isEditChallengeAllowed = useAccessControl({
    resource: composePermission(
      isAcquirerEnterprises ? RESOURCE_BASE.ENTERPRISE : RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.CHALLENGE,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const tooltipProps = getTooltipProps(
    needsVerification,
    isEditChallengeAllowed,
  );

  const actionsMap = [
    {
      label: "Confirm",
      onClick: confirmAction,
      sx: {
        backgroundColor: "#13A75A",
        ":disabled": {
          pointerEvents: "auto",
          cursor: "pointer",
          ":hover": { backgroundColor: "#D1D1D0" },
        },
      },
      background: "primary",
    },
    {
      label: "Reject",
      onClick: rejectAction,
      background: "tertiary",
      sx: {
        textDecoration: "none",
        ":disabled": {
          color: "#D1D1D0",
          pointerEvents: "auto",
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
  ];

  if (!merchant_underwriting) return null;

  return (
    <Stack direction="row-reverse" alignItems="center">
      {actionsMap.map((action, key) => {
        const disabled =
          needsVerification || isDoingActions || !isEditChallengeAllowed;
        return (
          <ActionButton
            key={key}
            testId={`${action.label}-button`}
            label={action.label}
            onClick={action.onClick}
            background={action.background as BtnBGTypes}
            tooltipProps={tooltipProps}
            sx={action.sx}
            disabled={disabled}
          />
        );
      })}
    </Stack>
  );
};

export default RejectApprove;
