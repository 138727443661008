/*
    If yuo ever feel unsure in case of hidding a component from the UI if
    the best is to remove the instantion of the component on render, then use this
    wrapper
*/

import React from "react";

interface Props {
  hidden?: boolean;
  children: React.ReactElement;
}

export const HiddenComponent = ({ hidden = true, children }: Props) => {
  //Normally I would return a div with display:none, but then we add a new HTML Node
  if (hidden) return null;

  return children;
};
