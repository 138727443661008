import { CREATE_FUNDRAISER_MODAL } from "../../modals/modal_names";
import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";

import { NewProductBanner } from "@components/Product/NewProductBanner/NewProductBanner";

const NewFundraisersBanner = ({ backgroundUrl }: { backgroundUrl: string }) => {
  const { t } = useTranslation(namespaces.pages.fundraisers);

  return (
    <NewProductBanner
      title={t("Fundraisers")}
      createModal={CREATE_FUNDRAISER_MODAL}
      analyticsType="fundraisers"
      background={backgroundUrl}
      createCampaignTitle={t("createFundraiser")}
    />
  );
};

export default NewFundraisersBanner;
