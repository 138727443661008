import React from "react";
import { useCustomTheme } from "../../theme/hooks/useCustomTheme";
import FileUploadMobileView from "./FileUploadMobileView";
import FileUploadDesktopView from "./FileUploadDesktopView";

export const FileUploadSnackbarContainer = () => {
  const { isMobileView } = useCustomTheme();

  return isMobileView ? <FileUploadMobileView /> : <FileUploadDesktopView />;
};
