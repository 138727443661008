import { SvgIconProps } from "@mui/material";

const GlobeIconV2 = ({
  height = 20,
  width = 20,
  fill = "#E6E6E3",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 0.375C6.64303 0.375 5.07214 0.851523 3.736 1.74431C2.39985 2.6371 1.35844 3.90605 0.743482 5.3907C0.12852 6.87535 -0.0323817 8.50901 0.281123 10.0851C0.594628 11.6612 1.36846 13.1089 2.50476 14.2452C3.64106 15.3815 5.0888 16.1554 6.6649 16.4689C8.24099 16.7824 9.87466 16.6215 11.3593 16.0065C12.844 15.3916 14.1129 14.3502 15.0057 13.014C15.8985 11.6779 16.375 10.107 16.375 8.5C16.3727 6.34581 15.516 4.28051 13.9927 2.75727C12.4695 1.23403 10.4042 0.377275 8.25 0.375ZM15.0961 7.875H11.9844C11.8742 5.65156 11.182 3.54141 10.0219 1.85938C11.3806 2.22417 12.5952 2.99613 13.5023 4.07152C14.4094 5.14691 14.9656 6.47419 15.0961 7.875ZM8.25 15.3664C6.78672 13.7828 5.89922 11.5383 5.76797 9.125H10.732C10.6008 11.5367 9.71328 13.7828 8.25 15.3664ZM5.76797 7.875C5.89922 5.46328 6.7836 3.21719 8.25 1.63359C9.71328 3.21719 10.6008 5.46172 10.732 7.875H5.76797ZM6.47813 1.85938C5.31797 3.54141 4.62578 5.65156 4.51563 7.875H1.40391C1.53444 6.47419 2.09063 5.14691 2.99773 4.07152C3.90483 2.99613 5.11937 2.22417 6.47813 1.85938ZM1.40391 9.125H4.51563C4.62578 11.3484 5.31797 13.4586 6.47813 15.1406C5.11937 14.7758 3.90483 14.0039 2.99773 12.9285C2.09063 11.8531 1.53444 10.5258 1.40391 9.125ZM10.0219 15.1406C11.182 13.4563 11.8742 11.3461 11.9844 9.125H15.0961C14.9656 10.5258 14.4094 11.8531 13.5023 12.9285C12.5952 14.0039 11.3806 14.7758 10.0219 15.1406Z"
        fill={fill}
      />
    </svg>
  );
};

export default GlobeIconV2;
