import Skeleton, { DoubledSkeleton } from "@components/animation/Skeleton";
import { Box, Divider, Stack } from "@mui/material";

const TransferMoneySkeleton = () => {
  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <Skeleton width={170} height={30} />
        <Skeleton width={197} height={20} />
      </Stack>
      <DoubledSkeleton
        width={300}
        height={50}
        borderRadius="10px"
        direction="row"
      />
      <Stack
        sx={{ borderRadius: "8px", background: "#FDFDFD", padding: "16px" }}
        spacing="11.5px"
      >
        <Box display="flex" justifyContent="space-between">
          <Skeleton width={112} height={12.25} />
          <Skeleton width={60} height={12.25} />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Skeleton width={64} height={12.25} />
          <Skeleton width={30} height={12.25} />
        </Box>
        <Skeleton width={225} height={12.25} />
        <Box display="flex" justifyContent="space-between">
          <Skeleton width={281} height={12.25} />
          <Skeleton width={58} height={12.25} />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Skeleton width={215} height={12.25} />
          <Skeleton width={70} height={12.25} />
        </Box>
      </Stack>
      <Stack spacing={1.5} alignItems="center" direction="row">
        <Skeleton height="24px" width="24px" borderRadius="4px" />
        <Skeleton height="12.25px" width="235px" />
      </Stack>
    </Stack>
  );
};

export default TransferMoneySkeleton;
