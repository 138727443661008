interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  background?: string;
}

const VideoIcon = ({
  width = 20,
  height = 20,
  fill = "none",
  stroke = "#727179",
  background = "#F4F3F7",
}: IconProps) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="32" height="32" rx="4" fill={background} />
      <path
        opacity="0.75"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 28C23.3774 28 28.75 22.6274 28.75 16C28.75 9.37258 23.3774 4 16.75 4C10.1226 4 4.75 9.37258 4.75 16C4.75 22.6274 10.1226 28 16.75 28ZM13.7322 10.4913L22.3649 14.8076C23.3476 15.299 23.3476 16.7014 22.3649 17.1928L13.7322 21.5091C12.6684 22.041 11.4167 21.2674 11.4167 20.078V11.9224C11.4167 10.733 12.6684 9.95937 13.7322 10.4913Z"
        fill={stroke}
      />
      <path
        d="M22.9611 15.1056L13.1533 10.2016C12.3554 9.8027 11.4167 10.3829 11.4167 11.275V20.725C11.4167 21.6171 12.3554 22.1973 13.1533 21.7983L22.9611 16.8944C23.6982 16.5259 23.6982 15.4741 22.9611 15.1056Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
