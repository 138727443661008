import Figure from "@common/Figure";
import {
  CustomAmountInputType,
  CardTitleProps,
} from "@common/PublicForm/types";
import { Text } from "@common/Text";
import CustomDonationInput from "@components/Fundraisers/PublicPaymentForm/CustomDonationInput";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { useState } from "react";

const PaymentCardTitle = ({
  title,
  price,
  style,
  isDefault,
  min,
  max,
  handleAdd,
  handleSubtract,
  handleSetValue,
  customDonation,
  selected,
  imageURL,
  quantityLeftComponent,
  customStyle,
}: CardTitleProps & Partial<CustomAmountInputType>) => {
  const [wasModified, setWasModified] = useState<boolean>(false);
  const isCustomInput =
    (!price || isDefault) && typeof customDonation !== undefined;

  return (
    <Stack
      direction="column"
      justifyContent={customStyle?.titleSectionAlignment || undefined}
      style={{ ...style }}
    >
      <Stack
        direction="row"
        gap={quantityLeftComponent ? 1.5 : 0}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Text
          fontSize={customStyle?.titleFontSize || undefined}
          color={customStyle?.titleFontColor || undefined}
          flexGrow={1}
        >
          {title}
        </Text>
        {quantityLeftComponent}
      </Stack>
      <Stack
        alignItems="baseline"
        direction="row"
        gap={1}
        mt={isDefault || !imageURL ? 2 : 0}
      >
        <Text
          variant="h3"
          fontSize={isCustomInput ? "32px" : "28px"}
          lineHeight={isCustomInput ? "29px" : "33px"}
          fontWeight="book"
          color={
            isCustomInput
              ? !wasModified
                ? "#B8B8B8"
                : "neutral.black"
              : palette.neutral[750]
          }
        >
          &#36;
        </Text>
        {isCustomInput ? (
          <CustomDonationInput
            min={min}
            max={max}
            handleAdd={handleAdd!}
            handleSubtract={handleSubtract!}
            handleSetValue={handleSetValue!}
            value={customDonation!}
            selected={selected}
            wasModified={wasModified}
            setWasModified={setWasModified}
          />
        ) : (
          <Box>
            <Figure
              isAmount
              value={price!}
              fontSize={28}
              sx={{ lineHeight: "normal" }}
            />
          </Box>
        )}
        <Text
          variant="h3"
          fontSize={isCustomInput ? "32px" : "27px"}
          lineHeight={isCustomInput ? "29px" : "33px"}
          fontWeight="book"
          color={
            isCustomInput
              ? !wasModified
                ? "#B8B8B8"
                : "neutral.black"
              : palette.neutral[750]
          }
        >
          USD
        </Text>
      </Stack>
    </Stack>
  );
};

export default PaymentCardTitle;
