import { useEffect, useState } from "react";
import { TMerchantDoublePanel } from "./useMerchantPreview";

export const usePreviewPanel = ({
  doublePanel,
}: {
  doublePanel: TMerchantDoublePanel;
}) => {
  const [panel1Collapsed, setPanel1Collapsed] = useState<boolean>(false);

  useEffect(() => {
    if (panel1Collapsed && !doublePanel) {
      setPanel1Collapsed(false);
    }
  }, [doublePanel]);
  return { panel1Collapsed, setPanel1Collapsed };
};
