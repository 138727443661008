import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalTitle } from "@common/Modal";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { IdentificationCard } from "@assets/icons/RebrandedIcons";
import KotoStepper from "@common/SignUp/KotoStepper";
import { Grid, Stack } from "@mui/material";
import { Button } from "@common/Button";

import { useGetIdentificationFiles } from "@sections/VerifyAccountHolder_v2/hooks/useCamera";
import { ConfirmationScreen } from "./ConfirmationScreen";
import { Tabs } from "./veryfyAccountHoldder.consts";
import { FormInputs } from "./form/form.type";
import useManageApi from "./hooks/useManageApi";
import { isEmpty } from "lodash";
import { useGetCurrentMerchantId } from "@hooks/common";
import { deleteDocumentAPI } from "@components/Settings/Business/Documents/utils";
import { useQueryClient } from "react-query";
import { IFileWithMeta, StatusValue } from "react-dropzone-uploader";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { mime } from "@components/UploadFile/Rebranded/UploadFile";
import useFindBusinessProfileById from "@hooks/enterprise-api/merchants/useFindBusinessProfileById";
import { challengeSlugs } from "@constants/challengeSlugs";
import { NonPahDisclaimer } from "./components/NonPahDisclaimer";
import useCheckPAH from "@hooks/common/useCheckPAH";
import { SelfieProps } from "./components/SelfieComponent";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ArrowLeft } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { CommonProps, InformationProps, UploadFilesProps } from "./types";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";

const stepperInit = {
  information: {
    label: Tabs.information.label,
    barValue: 0,
  },
  upload: {
    label: Tabs.upload.label,
    barValue: 0,
  },
  selfie: {
    label: Tabs.selfie.label,
    barValue: 0,
  },
};

export const VerifyAccountHolder = NiceModal.create(
  ({ merchant, isController }: any) => {
    const navigate = useNavigate();
    const modal = useModal();
    const stepperRef = useRef(null);
    const { data: files } = useGetIdentificationFiles();
    const queryClient = useQueryClient();
    const [completed, setCompleted] = useState(false);
    const { isMobileView } = useCustomTheme();
    const [customStep, setCustomStep] = React.useState(stepperInit);

    const [step, setStep] = React.useState<keyof typeof Tabs>(
      Tabs.information.id,
    );
    const { merchantId } = useGetCurrentMerchantId();
    const { data: legalEntity } = useFindBusinessProfileById({
      id: merchant?.legalEntityID,
      merchantId,
    });

    const hasOwner = merchant?.owner !== null;
    const hasLE = merchant?.legalEntityID !== 0;
    //this logic applies only if the legal entity is `individual_sole_proprietorship`
    const fullOwnershipReleased = legalEntity?.principals?.reduce(
      (acc: number, v: any) => acc + v.ownershipPercentage,
      0,
    );

    const leType = legalEntity?.type?.name;
    const leApproved = legalEntity?.statusName === "approved";
    useEffect(() => {
      const isPeronalInfoCompleted =
        merchant?.owner?.firstName &&
        merchant?.owner?.lastName &&
        merchant?.owner?.isManagerialAuthority;

      const isDocumentUploaded = merchant?.owner?.IDProofUploaded;
      const isFullyCompleted = merchant?.owner?.selfieUploaded;

      if (isFullyCompleted) {
        setStep(Tabs.selfie.id);
        setCustomStep((prev) => ({
          ...prev,
          information: {
            ...prev.information,
            barValue: 100,
          },
          upload: {
            ...prev.upload,
            barValue: 100,
          },
          selfie: {
            ...prev.selfie,
            barValue: 100,
          },
        }));
      } else if (isDocumentUploaded) {
        setStep(Tabs.selfie.id);
        setCustomStep((prev) => ({
          ...prev,
          information: {
            ...prev?.information,
            barValue: 100,
          },
          upload: {
            ...prev.upload,
            barValue: 100,
          },
          selfie: {
            ...prev.selfie,
            barValue: 0,
          },
        }));
      } else if (isPeronalInfoCompleted) {
        setStep(Tabs.upload.id);
        setCustomStep((prev) => ({
          ...prev,
          information: {
            ...prev?.information,
            barValue: 100,
          },
          upload: {
            ...prev.upload,
            barValue: 0,
          },
        }));
      } else {
        setStep(Tabs.information.id);
        setCustomStep((prev) => ({
          ...prev,
          information: {
            ...prev?.information,
            barValue: 0,
          },
        }));
      }
    }, [merchant?.owner]);

    const message = Tabs[step].message;

    const ActiveTab = useMemo(() => Tabs[step].Render, [step]);

    const [isValid, setIsvalid] = useState(false);

    const checkValidity = (val: boolean) => {
      setIsvalid((current) => {
        if (current !== val) {
          return val;
        }
        return current;
      });
    };

    const [data, setData] = useState<{
      information: any;
      upload: {
        allFiles: IFileWithMeta[] | File[] | null;
        status: StatusValue;
      };
      selfie: any;
    }>({
      information: null,
      upload: {
        allFiles: [],
        status: "preparing" as const,
      },
      selfie: null,
    });

    const saveDataLocaly = (data: any) => {
      setData((current) => {
        return {
          ...current,
          [step]: data,
        };
      });
    };

    const updateProgressBar = (v: number) => {
      setCustomStep((current) => ({
        ...current,
        [step]: {
          label: stepperInit[step].label,
          barValue: v,
        },
      }));
    };

    const commonProps: CommonProps<any> = {
      data: data[step],
      submitData: saveDataLocaly,
      updateProgressBar,
    };

    const { onSubmitInformation, informationsAreLoading } = useManageApi(
      merchant?.accID || merchantId,
    );

    const ownerPrincipal =
      legalEntity?.principals &&
      legalEntity?.principals?.find((principal: any) => !!principal.ownerID);

    const informationProps: InformationProps = {
      ...commonProps,
      isController,
      leType,
      leApproved,
      hasOwner,
      hasLE,
      owner: merchant?.owner,
      principal: ownerPrincipal,
      fullOwnershipReleased,
      checkValidity: checkValidity,
      onSubmit: (data: FormInputs) => {
        const usedData = {
          ...data,
          isOwner: !isEmpty(ownerPrincipal) ? false : data.isOwner,
        };
        onSubmitInformation(usedData, () => {
          const nextTabId = Tabs[step].next;
          if (nextTabId) {
            setStep(Tabs[nextTabId].id);
          }
          updateProgressBar(100);
          saveDataLocaly(data);
          queryClient.invalidateQueries("find-profile-by-id");
          queryClient.invalidateQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
        });
      },
    };
    const handleReset = () => {
      onCloseModal();
    };
    const {
      populateSnackbarFiles,
      isUploadAllowed,
      isLoading: uploadLoading,
    } = useFileUploadContext();
    const { handleUpload } = useUploadFiles();
    const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
    const uploadProps: UploadFilesProps = {
      ...commonProps,
    };
    const { isLoggedInPAH } = useCheckPAH();
    const isSelfieStepActive = step === "selfie";
    const goBack = () => {
      const prevTabId = Tabs[step].prev;
      if (prevTabId) {
        setStep(Tabs[prevTabId].id);
      } else {
        onCloseModal();
      }
    };

    const onCloseModal = () => {
      modal.hide();
      const currentPath = window.location.pathname;
      navigate(currentPath);
    };

    const selfieProps: SelfieProps & UploadFilesProps = {
      ...commonProps,
      isSelfieStepActive,
      completed,
      setCompleted,
      merchant,
      isLoggedInPAH,
      handleReset,
      onBack: goBack,
    };

    const props = {
      information: informationProps,
      upload: uploadProps,
      selfie: selfieProps,
    };

    const submitBtn = {
      information: {
        type: "submit" as "submit" | "button" | "reset" | undefined,
        form: "confirm-primary-account-holder",
        disabled: informationsAreLoading,
      },
      upload: {
        disabled: customStep?.upload?.barValue < 99 || uploadLoading,
        onClick: async () => {
          const onSuccess = () => {
            setData((prev) => ({
              ...prev,
              upload: { allFiles: [], status: "done" },
            }));
            const nextTabId = Tabs[step].next;
            if (nextTabId) {
              setStep(Tabs[nextTabId].id);
            }
            updateProgressBar(100);
            queryClient.invalidateQueries("find-profile-by-id");
            queryClient.invalidateQueries([
              QKEY_GET_MERCHANT_BY_ID,
              merchantId,
            ]);
          };

          if (
            customStep?.upload?.barValue === 100 &&
            !data?.upload?.allFiles?.length
          ) {
            return onSuccess();
          }
          if (
            data?.upload?.allFiles?.length === 0 ||
            !data?.upload?.allFiles?.length
          )
            return;
          const fileArr = files?.data?.filter(
            (doc: any) => doc?.attTypeName === "account_owner",
          );

          !isEmpty(fileArr) &&
            !isEmpty(merchant) &&
            (await deleteDocumentsInLoop(fileArr, merchant?.accID));

          if (isFileUploadRefactorEnabled) {
            const filesToUpload = data?.upload?.allFiles.map((file) => ({
              file: file as File,
            }));
            const uploadRes = await handleUpload(
              {
                list: filesToUpload,
                merchantId,
                resourceID: merchantId,
                attachmentType: "account_owner",
                label: "",
                tag: "Acquirer document",
              },
              challengeSlugs.PRIMARY_5,
            );
            if (uploadRes === "upload_failed") return;
            onSuccess();
          } else {
            const uploadRes = await populateSnackbarFiles(
              {
                fileWithMeta: data?.upload?.allFiles[0] as IFileWithMeta,
                status: data.upload?.status,
                allFiles: data?.upload?.allFiles as IFileWithMeta[],
                attachmentType: "account_owner",
                label: "",
                tag: "Acquirer document",
                merchantId,
                resourceID: merchantId,
              },
              mime.image,
              challengeSlugs.PRIMARY_5,
            );
            if (uploadRes === "upload_failed") return;
            onSuccess();
          }
        },
      },
      selfie: {
        disabled: informationsAreLoading,
      },
    };

    const goNext = () => {
      const nextTabId = Tabs[step].next;
      (submitBtn[step] as any)?.onClick && (submitBtn[step] as any).onClick();
      if (nextTabId) {
        setStep(Tabs[nextTabId].id);
      } else {
        onCloseModal();
      }
    };

    return (
      <Modal
        width="568px"
        maxWidth="xl"
        {...muiDialogV5(modal)}
        headerComponent={
          <ModalTitle
            backgroundColor={palette.liftedWhite.main}
            title="Identity Verification"
            titleIcon={<IdentificationCard />}
            onClose={onCloseModal}
            headerContainerProps={{
              justifyContent: isMobileView ? "center" : "left",
            }}
          />
        }
        {...(completed && {
          PaperProps: {
            style: {
              transformOrigin: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
        })}
        sx={{
          "& .MuiDialogContent-root": {
            padding: "16px",
          },
        }}
        onClose={onCloseModal}
      >
        <Stack
          {...(isMobileView && {
            display: "flex",
            flexDirection: "column",
            sx: { height: "100%" },
          })}
        >
          <Grid container spacing={completed ? 0 : 4}>
            {completed ? (
              <ConfirmationScreen />
            ) : (
              <>
                {" "}
                <Grid item xs={12}>
                  <Text
                    paddingLeft={1}
                    color={palette.neutral[70]}
                    fontWeight="book"
                    lineHeight="120%"
                  >
                    We need to confirm your identity
                  </Text>
                </Grid>
                <Grid item xs={12}>
                  <KotoStepper
                    overriderActiveStep={Object.values(customStep).findIndex(
                      (x) => x.label === stepperInit[step].label,
                    )}
                    sx={{ width: "100%" }}
                    setStep={(label: string) => {
                      type _k = keyof typeof stepperInit;
                      const k = (Object.keys(stepperInit) as _k[]).find(
                        (k) => stepperInit[k].label === label,
                      );
                      if (k) {
                        setStep(k);
                      }
                    }}
                    isClickable
                    defaultStep={step}
                    customSteps={Object.values(customStep)}
                    ref={stepperRef}
                    setIsLoaded={() => {
                      return;
                    }}
                  />
                  <NonPahDisclaimer step={step} />
                </Grid>
                <Grid item xs={12} marginX={1}>
                  <Text
                    fontSize={18}
                    color={palette.neutral[80]}
                    lineHeight="120%"
                    fontWeight="regular"
                    paddingBottom={4}
                  >
                    {message}
                  </Text>
                  <ActiveTab {...(props[step] as any)} />
                </Grid>
              </>
            )}
          </Grid>
          {!isLoggedInPAH && !isMobileView ? (
            <Button
              size="medium"
              background="primary"
              disabled={submitBtn[step].disabled}
              sx={{
                padding: "12px 32px",
                margin: "auto",
                marginTop: "16px",
              }}
              onClick={goNext}
            >
              {Tabs[step].next ? "Next" : "Close"}
            </Button>
          ) : (
            <Stack
              {...(isMobileView && {
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                mb: "2px",
              })}
            >
              {completed && (
                <Button
                  size="medium"
                  background="secondary"
                  sx={{
                    padding: "12px 32px",
                    ...(!isMobileView && { margin: "auto" }),
                    marginTop: "16px",
                  }}
                  onClick={onCloseModal}
                >
                  Done
                </Button>
              )}

              {isMobileView &&
                Tabs[step].next &&
                !completed &&
                !Tabs[step]?.hideActionsInMobileView && (
                  <Button
                    size="medium"
                    background="primary"
                    sx={{
                      padding: "12px 32px",
                    }}
                    {...submitBtn[step]}
                    {...(step !== "information" && { onClick: goNext })}
                  >
                    Next
                  </Button>
                )}

              {isMobileView &&
                Tabs[step].prev &&
                !Tabs[step]?.hideActionsInMobileView && (
                  <Button
                    size="medium"
                    background="tertiary"
                    sx={{
                      padding: "12px 32px",
                      marginTop: "16px",
                    }}
                    onClick={goBack}
                    startIcon={<ArrowLeft />}
                  >
                    Back
                  </Button>
                )}

              {!isSelfieStepActive && !completed && !isMobileView && (
                <Button
                  size="medium"
                  background="primary"
                  sx={{
                    padding: "12px 32px",
                    margin: "auto",
                    marginTop: "16px",
                  }}
                  {...submitBtn[step]}
                >
                  Continue
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Modal>
    );
  },
);

export async function deleteDocumentsInLoop(
  documents: any[],
  merchantID: number,
) {
  for (const file of documents) {
    try {
      await deleteDocumentAPI(merchantID, file?.id);
    } catch (error) {
      //
    }
  }
}
