import * as React from "react";
import { palette } from "@palette";
import { useNavigate } from "react-router";
import NiceModal from "@ebay/nice-modal-react";
// mui
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
// components
import { Tag } from "@common/Tag";
import { Text, TruncateText } from "@common/Text";
import { DropdownItem } from "@common/Select";
import { IconButton } from "@common/IconButton";
// utils
import { toEnFormat } from "utils";
// assets
import { MoreIcon } from "@assets/icons";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import {
  MERCHANT_PORTAL_CANCEL_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  MERCHANT_VIEW_CUSTOMER,
  MOVE_TRANSACTION_MODAL,
  TRANSACTION_INFO_MODAL,
} from "modals/modal_names";
import { TransactionTableRow } from "../TransactionTable";
import { Grid, SxProps } from "@mui/material";
import { TransactionTableTag } from "@common/Tag/TransactionTableTag";
import { BankAccount } from "@hooks/merchant-api/manage-money/useListBankAccounts";
import { Card } from "../transactions.types";
import { HiddenComponent } from "containers/HiddenComponent";
import { MobileTransactionCardWrapper } from "@containers/MobileTransactionCardWrapper";
import {
  getSourceAccount,
  getStatus,
  textColor,
} from "../transactions.helpers";

type TableCardProps = {
  isSelected: boolean;
  setIsSelected: () => void;
  // resetSelected: any;
  rowData: TransactionTableRow;
};

const PaymentMethodContent = ({
  isCard,
  method,
  rowData,
}: {
  isCard?: boolean;
  method: BankAccount & Card;
  rowData: TransactionTableRow;
}) => {
  return (
    <Stack direction="row">
      <TruncateText
        variant="body"
        lineClamp={1}
        color={textColor(rowData, palette.neutral[80])}
        data-testid="table-card-description"
      >
        {rowData?.description}
      </TruncateText>
    </Stack>
  );
};

export const TableCard_V2 = ({
  rowData,
  containerSx,
  tagSx,
}: {
  rowData: TransactionTableRow;
  containerSx?: SxProps;
  tagSx?: SxProps;
}) => {
  const isPending = rowData.displayStatus === "Pending";

  return (
    <MobileTransactionCardWrapper
      onClick={() => {
        NiceModal.show(TRANSACTION_INFO_MODAL, {
          data: rowData,
          compaignType: "",
        });
      }}
      data-testid={`mobile-transaction-table-item`}
      sx={containerSx}
    >
      <Grid sx={{ opacity: isPending ? 0.5 : 1, flex: 1, minWidth: "150px" }}>
        <Text
          variant="body"
          color={textColor(rowData, palette.neutral[80])}
          paddingBottom={1}
          data-testid="mobile-table-card-date"
        >
          {rowData.time}
        </Text>
        <PaymentMethodContent
          rowData={rowData}
          method={rowData.sourceMethod?.method as BankAccount & Card}
        />
      </Grid>
      <TransactionTableTag
        type={getStatus(rowData)?.displayStatus.toLocaleLowerCase() as any}
        sx={{
          maxWidth: "250px !important",
          minWidth: "100px",
          flex: 1,
          ...tagSx,
        }}
      />
    </MobileTransactionCardWrapper>
  );
};

const TableCard: React.FC<TableCardProps> = ({
  isSelected,
  setIsSelected,
  rowData,
  // resetSelected,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation(namespaces.common);

  const handleOpenActionModal = (modalName: string, params?: any) => {
    NiceModal.show(modalName, params);
    if (anchorEl) setAnchorEl(null);
  };

  const handleViewCustomer = () => {
    if (anchorEl) setAnchorEl(null);
    const customerId = rowData?.customer.id;
    if (customerId) NiceModal.show(MERCHANT_VIEW_CUSTOMER, { id: customerId });
  };
  const isPendingTransfer =
    rowData &&
    rowData.displayType === "Money Transfer" &&
    rowData.displayStatus === "Requested";

  return (
    <Box
      sx={Container}
      onClick={() =>
        navigate(`/merchant/manage-money/${rowData.id}`, {
          state: { rowData },
        })
      }
    >
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box>
          <Text variant="body" color={palette.neutral[600]}>
            {rowData.time}
          </Text>
          <Text
            variant="headline"
            color={palette.neutral[800]}
            fontWeight="medium"
          >
            {rowData.title}
          </Text>
        </Box>
        {rowData.status !== "approved" && (
          <IconButton variant="text" size="small" onClick={handleOpenMenu}>
            <MoreIcon size="20px" />
          </IconButton>
        )}

        {/* ------------- MENU ------------- */}
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: 159,
            },
          }}
        >
          <HiddenComponent hidden={isPendingTransfer}>
            <DropdownItem
              onClick={() =>
                handleOpenActionModal(MERCHANT_PORTAL_SEND_RECEIPT_MODAL, {
                  transactionID: rowData.id,
                  emails: [rowData.sourceAccount.user.email],
                })
              }
            >
              <Text>{t("transactionTable.SendReceipt")} </Text>
            </DropdownItem>
          </HiddenComponent>
          <HiddenComponent hidden={isPendingTransfer}>
            <DropdownItem
              onClick={() =>
                handleOpenActionModal(MERCHANT_PORTAL_REFUND_MODAL)
              }
            >
              <Text> {t("transactionTable.Refund")}</Text>
            </DropdownItem>
          </HiddenComponent>
          <HiddenComponent hidden={!isPendingTransfer}>
            <DropdownItem
              onClick={() => {
                handleOpenActionModal(MERCHANT_PORTAL_CANCEL_MODAL, {
                  transactionId: rowData?.id,
                  cancel: true,
                  sourceAccount: getSourceAccount(rowData),
                  customerID: rowData?.sourceAccount.id,
                });
              }}
            >
              <Text>Cancel Transfer</Text>
            </DropdownItem>
          </HiddenComponent>
          <HiddenComponent hidden={rowData.displayType === "Money Transfer"}>
            <DropdownItem onClick={handleViewCustomer}>
              <Text>{t("transactionTable.ViewCustomer")}</Text>
            </DropdownItem>
          </HiddenComponent>
          {/* <DropdownItem
            onClick={() => {
              NiceModal.show(MOVE_TRANSACTION_MODAL, {
                isSelected: isSelected,
                setIsSelected: setIsSelected,
              });
              if (anchorEl) setAnchorEl(null);
            }}
          >
            <Text>{t("transactionTable.MoveTransaction")}</Text>
          </DropdownItem> */}
        </Menu>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        onClick={() =>
          navigate(`/manage-money/${rowData.id}`, {
            state: { rowData },
          })
        }
      >
        <Tag type={rowData.status as any} shouldShowIcon />
        <Stack>
          <Text
            variant="headline"
            color={palette.neutral[800]}
            fontWeight="medium"
          >
            {toEnFormat(rowData.amount)}
            <sup>USD</sup>
          </Text>

          {/* <Text variant="body" color={palette.neutral[600]}>
            {toEnFormat(rowData.balance / 100)}
            <sup>USD</sup>
          </Text> */}
        </Stack>
      </Box>
    </Box>
  );
};

const Container = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "12px 8px",
  gap: "8px",
  width: "100%",
  height: "122px",
};

export default TableCard;
