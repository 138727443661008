import { Button } from "@common/Button";
import { BaseModal } from "@common/Modal";
import { Skeleton } from "@mui/material";
import { styled, Stack } from "@mui/material";

const OpenCodeEditorButton = styled(Button)(() => ({
  fontSize: "14px",
  color: "#6D9CF8",
  textDecorationColor: "#6D9CF8",
  lineHeight: "16.8px",
  fontWeight: 350,
  padding: "2px",
}));

const ActionButton = styled(Button)(() => ({
  padding: "8px 24px",
  borderRadius: "32px",
  fontSize: "14px",
  fontWeight: 400,
}));

const ActionsContainer = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
  justifyContent: "flex-end",
  padding: "12px 16px",
}));

const CustomModal = styled(BaseModal, {
  shouldForwardProp: (prop) => prop !== "height",
})<{ height: string }>(({ height }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "8px",
    background: "#FBFBFB",
    boxShadow: "0px 8px 25px 0px rgba(5, 4, 4, 0.15)",
    overflow: "hidden",
    height,
  },
}));

const ListSkeleton = () => (
  <Stack direction="column" alignItems="stretch" gap="8px" paddingInline="8px">
    <Skeleton width="40%" height={30} sx={{ borderRadius: "4px" }} />
    {Array.from({ length: 5 }).map((_, i) => (
      <Skeleton key={i} height={60} sx={{ borderRadius: "4px" }} />
    ))}
  </Stack>
);

export {
  OpenCodeEditorButton,
  ActionButton,
  ActionsContainer,
  CustomModal,
  ListSkeleton,
};
