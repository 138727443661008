import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";
import { BANK_DETAILS } from "./MerchantAgreement/merchantAgreement.const";

const grayColor = palette.gray[300];

export const BankDisclosure = () => {
  return (
    <Stack spacing={1.5}>
      <Text fontSize="18px" color={palette.black[100]}>
        Bank Disclosure
      </Text>
      <Stack gap="8px">
        {BANK_DETAILS.map((bank) => {
          return (
            <Box key={bank.name}>
              <Text color={palette.black[100]}>{bank.name}</Text>
              <Text color={grayColor}>{bank.addr1}</Text>
              <Text color={grayColor}>{bank.addr2}</Text>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
};
