import UserIconV3 from "@assets/icons/UserIconV3";
import { BankIcon, BusinessProfileIcon } from "@assets/icons/RebrandedIcons";
import ScoreIcon from "@assets/icons/RebrandedIcons/ScoreIcon";
import { MerchantAgreementIcon } from "@assets/icons";
import { palette } from "@palette";

export const renderStatusListIcon = (iconId: string, color: string) => {
  switch (iconId) {
    case "UserIConV3":
      return <UserIconV3 width={24} height={24} color={color} />;
    case "BusinessProfileIcon":
      return <BusinessProfileIcon width={24} height={24} fill={color} />;
    case "ScoreIcon":
      return <ScoreIcon width={24} height={24} fill={color} />;
    case "BankIcon":
      return <BankIcon width={24} height={24} fill={color} />;
    case "MerchantAgreementIcon":
      return <MerchantAgreementIcon width={24} height={24} fill={color} />;
    default:
      return <UserIconV3 width={24} height={24} color={color} />;
  }
};

type StatusOverviewListProps = {
  primaryAccount?: boolean;
  businessProfile: boolean;
  bankAccount: boolean;
  merchantAgreement: boolean;
  score?: number;
};

export const statusOverviewList = (
  {
    bankAccount,
    businessProfile,
    primaryAccount,
    merchantAgreement,
    score,
  }: StatusOverviewListProps,
  isEnterprise?: boolean,
) => [
  {
    label: "Score",
    value: (
      <>
        Score is <span style={{ fontWeight: 700 }}>{score}%</span>
      </>
    ),
    approved: score && score >= 80,
    icon: "ScoreIcon",
  },
  {
    label: "Primary Account Holder ID",
    value: getOverviewStatusItem("Identity", primaryAccount),
    approved: primaryAccount,
    icon: "BusinessProfileIcon",
  },
  {
    label: "Business Profile",
    value: getOverviewStatusItem("Business profile", businessProfile),
    approved: businessProfile,
    icon: "UserIConV3",
  },
  {
    label: "Bank Account",
    value: getOverviewStatusItem("Bank account", bankAccount),
    approved: bankAccount,
    icon: "BankIcon",
  },
  {
    label: isEnterprise ? "Provider Agreement" : "Merchant Agreement",
    value: getOverviewStatusItem("Agreement", merchantAgreement, "signed"),
    approved: merchantAgreement,
    icon: "MerchantAgreementIcon",
  },
];

export const getOverviewStatusItem = (
  label: string,
  approved?: boolean,
  extension?: string,
) => {
  if (approved)
    return (
      <>
        {label}{" "}
        <span style={{ fontWeight: 700 }}>
          {extension ? `${extension}!` : "approved!"}
        </span>
      </>
    );
  if (label === "Bank account")
    return (
      <>
        <span style={{ fontWeight: 700 }}>No</span> bank accounts approved. At
        least one bank account must be approved
      </>
    );
  return (
    <>
      {label} <span style={{ fontWeight: 700 }}>not</span> yet{" "}
      {extension || "approved"}
    </>
  );
};

export const getAccountStatus = (
  isReadyToApprove: boolean,
  statusName: string,
) => {
  const isRefused = statusName === "preapproved_mid_issue";
  const isIncomplete =
    !["declined", "suspended"].includes(statusName) && !isReadyToApprove;
  const isReadyToBeApproved =
    isReadyToApprove &&
    ["has_mid_ready", "preapproved_no_tx", "has_mid_not_ready"].includes(
      statusName,
    );

  if (isRefused) {
    return {
      label: "Refused by MSP",
      color: palette.filled.orange,
      bg_color: "#FFF2E9",
    };
  }
  if (isIncomplete)
    return {
      label: "Incomplete",
      color: palette.neutral[80],
      bg_color: "#ECECE9",
    };

  if (isReadyToBeApproved) {
    return {
      label: "Ready to be Approved",
      color: "#326EC5",
      bg_color: "#9ec8ff4d",
    };
  }

  return accountStatusMap[statusName as "approved" | "declined" | "suspended"];
};

const accountStatusMap = {
  approved: {
    label: "Approved",
    color: palette.filled.success,
    bg_color: "#E6F3EC",
  },
  declined: {
    label: "Declined",
    color: palette.filled.red,
    bg_color: "#F5E0DF",
  },
  suspended: {
    label: "Suspended",
    color: palette.tag.warning.hover,
    bg_color: "#FFF2E9",
  },
};
