import { ArrowUpChevron } from "@assets/icons";
import { ArrowDownChevronV2 } from "@assets/icons/RebrandedIcons";
import CheckIcon from "@assets/icons/RebrandedIcons/CheckIcon";
import { Text } from "@common/Text";
import { Box, Menu, MenuItem } from "@mui/material";
import { useAppTheme } from "@theme/v2/Provider";
import React, { useEffect } from "react";

interface Props {
  listItemContent: any;
  onSelectItem: any;
  options: any;
  selectedValue: any;
  title?: any;
  defaultValue?: any;
  InputWrapperProps?: any;
  isDisabled?: boolean;
  startAdornment?: any;
  useFullWidthForItems?: boolean;
  bottomMessage?: any;
}

/*
  the default value is whatever value selectedItem has when a ndoe is selected
  used to set the index of the selectem item in the selector
*/
export const InputSelector = ({
  listItemContent,
  onSelectItem,
  options,
  selectedValue,
  title,
  defaultValue,
  InputWrapperProps = {
    style: {},
  },
  isDisabled,
  startAdornment,
  useFullWidthForItems = false,
  bottomMessage,
}: Props) => {
  const theme = useAppTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    return Math.max(
      0,
      options?.findIndex(
        (it: any) => selectedValue && it.label === selectedValue(0),
      ),
    );
  });

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (defaultValue) {
      setSelectedIndex(
        options?.findIndex((it: any) => it.label === defaultValue),
      );
    }
  }, [defaultValue]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    onSelectItem(index, options[index]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {title && (
        <Text
          fontSize="14px"
          color={theme.palette.text.primary}
          marginBottom="6px"
        >
          {title}
        </Text>
      )}
      <>
        <Selector
          setAnchorEl={setAnchorEl}
          selectedValue={selectedValue(selectedIndex)}
          menuIsOpened={open}
          InputWrapperProps={InputWrapperProps}
          isDisabled={isDisabled}
          startAdornment={startAdornment}
        />
        {bottomMessage && (
          <Text
            fontSize="12px"
            lineHeight="14px"
            color={theme.palette.primitive?.neutral["70"]}
          >
            {bottomMessage}
          </Text>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            role: "listbox",
            sx: {
              padding: "8px",
            },
          }}
          slotProps={{
            paper: {
              style: {
                width: useFullWidthForItems
                  ? "auto"
                  : anchorEl
                  ? anchorEl.offsetWidth
                  : undefined,
                borderRadius: "12px",
                maxHeight: "360px",
              },
            },
          }}
        >
          {options?.map((option: any, index: number) => (
            <ListItem
              key={index}
              index={index}
              option={option}
              isSelected={index === selectedIndex}
              handleMenuItemClick={handleMenuItemClick}
              listItemContent={listItemContent}
            />
          ))}
        </Menu>
      </>
    </div>
  );
};

export function Selector({
  children,
  setAnchorEl,
  selectedValue,
  menuIsOpened,
  InputWrapperProps,
  isDisabled,
  startAdornment,
}: any) {
  const theme = useAppTheme();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div
      style={{
        paddingBottom: "8px",
        cursor: "pointer",
      }}
      onClick={isDisabled ? undefined : handleClickListItem}
    >
      {children ?? (
        <Box
          sx={{
            border: `1px solid ${theme.palette.border?.secondary}`,
            borderRadius: "12px",
            padding: "12px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: startAdornment ? "start" : "space-between",
            background: isDisabled ? theme.palette.surface?.secondary : "none",
            opacity: isDisabled ? 0.4 : 1,
            height: "48px",
            ...InputWrapperProps.style,
            "&:hover": {
              border: !isDisabled
                ? `1px solid ${theme.palette.buttons?.default}`
                : "none",
            },
          }}
        >
          {startAdornment && (
            <div
              style={{
                paddingRight: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {startAdornment}
            </div>
          )}
          <Text fontSize="14px" color={theme.palette.text.primary}>
            {selectedValue}
          </Text>
          <div
            style={{
              marginLeft: startAdornment ? "auto" : 0,
            }}
          >
            {menuIsOpened ? (
              <ArrowUpChevron
                width={13}
                height={12}
                fill={theme.palette.primitive?.neutral[100]}
              />
            ) : (
              <ArrowDownChevronV2
                width={13}
                height={12}
                color={theme.palette.primitive?.neutral[100]}
              />
            )}
          </div>
        </Box>
      )}
    </div>
  );
}

function ListItem({
  option,
  isSelected,
  handleMenuItemClick,
  listItemContent,
  index,
}: any) {
  const theme = useAppTheme();

  return (
    <MenuItem
      disableRipple
      disableTouchRipple
      key={option.label}
      selected={isSelected}
      onClick={() => handleMenuItemClick(index)}
      sx={{
        display: "flex",
        alignItems: "center",
        "&.Mui-selected": {
          backgroundColor: "transparent",
          borderRadius: "8px",
        },
        "&:hover": {
          borderRadius: "8px",
        },
      }}
    >
      {listItemContent(option)}
      {isSelected && (
        <div style={{ marginLeft: "auto" }}>
          <CheckIcon fill={theme.palette.primitive?.neutral[100]} />
        </div>
      )}
    </MenuItem>
  );
}
