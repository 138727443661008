import { Box } from "@mui/material";
import { WarningPlaceholderBase } from "./WarningPlaceholdersSection";
import { palette } from "@palette";
import { OFACCheckStatusType } from "../OFAC/hooks/useOFAC";

type OFACCheckStatusWithResource = {
  label?: string;
  status?: OFACCheckStatusType;
};

type Props = {
  BP: OFACCheckStatusWithResource;
  PAH: OFACCheckStatusWithResource;
  BO: OFACCheckStatusWithResource;
};

const MerchantOFACWarning = (props: Props) => {
  const allItems = Object.values(props);
  const confirmedItems = allItems
    .filter((item) => item.status === "confirmed_match")
    .map((item) => item.label);
  const possibleItems = allItems
    .filter((item) => item.status === "possible_match")
    .map((item) => item.label);

  const confirmedMessage =
    confirmedItems.length > 0
      ? `Confirmed match in OFAC: ${confirmedItems.join(", ")}`
      : "";
  const possibleMessage =
    possibleItems.length > 0
      ? `Possible match in OFAC: ${possibleItems.join(", ")}`
      : "";

  const fullMessage = (
    <>
      {confirmedMessage && confirmedMessage}
      {confirmedMessage && <br />}
      {possibleMessage}
    </>
  );
  return (
    <Box
      sx={{ display: confirmedMessage || possibleMessage ? "block" : "none" }}
    >
      <WarningPlaceholderBase
        title="Attention"
        message={fullMessage}
        messageColor={palette.warning.text}
      />
    </Box>
  );
};

export default MerchantOFACWarning;
