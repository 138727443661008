import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import MerchantBankAccountItem from "@components/Merchants/MerchantPreview/components/BankAccounts/MerchantBankAccountItem";
import {
  DrawerBottomActions,
  MobileActionItemProps,
} from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import { palette } from "@palette";
import { Stack, styled } from "@mui/material";
import { Box } from "@mui/material";
import { Text } from "@common/Text";
import { useState } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import BankAccountDetailsModal from "./modals/BankAccountDetailsModal";

type TLabel = {
  title: string;
  description: string;
  Icon: JSX.Element;
};

type MobileActions = {
  onClick: () => void;
  hidden?: boolean;
  disabled?: boolean;
  label: TLabel;
  tooltipProps?: {
    show: boolean;
    message: string;
  };
};

interface IMobileBankAccountList {
  page: number;
  totalRows: number;
  setPage?: () => void;
  loadingRef: React.MutableRefObject<boolean>;
  rows: IMerchantBankAccount[];
  mobileActions?: MobileActions[];
  isLoading: boolean;
}

const MobileBankAccountsList = ({
  page,
  totalRows,
  setPage,
  loadingRef,
  rows,
  isLoading,
  mobileActions = [],
}: IMobileBankAccountList) => {
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  const [selectedAccount, setSelectedAccount] = useState<
    IMerchantBankAccount | undefined
  >(undefined);

  const targetRef = useTargetRef({ page, totalRows, setPage, loadingRef });

  const actionItems = mobileActions.map(
    ({ onClick, hidden, disabled, label }): MobileActionItemProps => ({
      onSelect: onClick,
      hidden: hidden || false,
      disabled: disabled || false,
      label: <ItemLabel {...label} />,
      labelProps: {
        sx: {
          padding: "16px",
        },
        labelTextProps: {
          component: "div",
          sx: {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            userSelect: "none",
          },
        },
      },
    }),
  );
  return (
    <ModalFactory
      variant="dialog"
      renderMobile
      modalProps={{
        open,
        onClose,
        DrawerProps: {
          SlideProps,
        },
        DialogProps: {
          TransitionProps,
        },
      }}
    >
      <Stack
        direction="column"
        gap={2}
        alignItems="stretch"
        minHeight="280px"
      >
        <ModalTitle title="Bank Accounts" />

        <Stack gap={1} direction="column" alignItems="stretch">
          {totalRows === 0 && !isLoading && (
            <FadeUpWrapper delay={50}>
              <EmptyState>
                <Text fontSize={16} color={palette.neutral[400]}>
                  No accounts linked
                </Text>
              </EmptyState>
            </FadeUpWrapper>
          )}

          {rows?.map((data: any, index: number) => (
            <FadeUpWrapper key={data.id} delay={50 + index * 50}>
              <MerchantBankAccountItem
                handleCheckAccount={(id) => {
                  const account = rows.find((b) => b.id === id);
                  setSelectedAccount(account);
                }}
                {...data}
              />
            </FadeUpWrapper>
          ))}

          {rows?.length ? (
            <div
              ref={targetRef}
              style={{
                width: 1,
                height: 1,
                paddingBottom: 5,
                visibility: "hidden",
              }}
            ></div>
          ) : null}
        </Stack>

        <Box height="100px" />
      </Stack>
      <DrawerBottomActions
        sx={{
          "& .MuiPaper-root": {
            padding: 0,
            bottom: "100px",
          },
        }}
        label="Add bank accounts"
        items={actionItems}
        buttonSx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
      <BankAccountDetailsModal
        selectedAccount={selectedAccount}
        open={!!selectedAccount}
        onClose={() => setSelectedAccount(undefined)}
      />
    </ModalFactory>
  );
};

export default MobileBankAccountsList;

const ItemLabel = ({
  title,
  description,
  Icon,
}: {
  title: string;
  description: string;
  Icon: JSX.Element;
}) => {
  return (
    <>
      <Stack direction="column" gap="4px" flexGrow={1} alignItems="stretch">
        <Text
          fontSize="16px"
          lineHeight="16px"
          fontWeight="book"
          color={palette.black[100]}
          align="left"
        >
          {title}
        </Text>
        <Text
          fontSize="12px"
          lineHeight="14.4px"
          fontWeight="book"
          color={palette.gray[300]}
          align="left"
        >
          {description}
        </Text>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        {Icon}
      </Stack>
    </>
  );
};

const EmptyState = styled(Stack)(() => ({
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  padding: "16px 0px",
  borderRadius: "8px",
}));
