import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetBuilderData } from "../api/builderApi";
import useSavePaymentForm from "./useSavePaymentForm";
import {
  builder,
  createGradient,
  decodeSpecialCharacters,
  generateUniqueID,
} from "../utils";
import {
  globalColors,
  isBuilderError,
  isDraftCreated,
  isPublished,
  globalButtonStyles,
  isDraftEnabled,
  isPublishEnabled,
  formUpdatedAt,
} from "../signals";
import { getColorsMap, useColors } from "../api";
import { CustomTheme } from "@theme/v2/palette.interface";
import NiceModal from "@ebay/nice-modal-react";
import { ADVANCED_BUILDER_ERROR_MODAL } from "modals/modal_names";
import {
  defaultDescriptionMetaAttributes,
  defaultDescriptionStyle,
  defaultDescriptionTag,
  defaultHeadingMetaAttributes,
  defaultHeadingStyle,
  defaultHeadingTag,
  headingKeys,
  headingsSignal,
  mobileHeadingsSignal,
} from "../consts/headings";
import { defaultWeights } from "../consts/fontWeights";
import { optionsMap } from "../components/FontFamilySelector";
import { colors as defaulGlobalColors } from "../api/test";
import { batch } from "@preact/signals-react";
import { ROOT_NODE_ID, rootNodeStyles } from "../consts/values";
import {
  savePublishedNodeDeviceTypeProperties,
  saveNodeDeviceTypeProperties,
} from "../NodeDeviceTypePropertiesMap";
import {
  deviceSpecificWidgets,
  getButtonWidgetDeviceTypeCssProperties,
  getDividerDeviceTypeCssProperties,
  getHeadingDeviceTypeCssProperties,
  getSingleImageDeviceTypeCssProperties,
} from "../utils/helpers";

type Props = {
  productId: number;
  title: string;
  description: string;
  theme: CustomTheme;
};

export const build = (
  nodeList: any[],
  title: string | undefined,
  description: string | undefined,
) => {
  const hasNodeList = nodeList && nodeList.length > 0;
  const titleId = generateUniqueID();
  const descriptionId = generateUniqueID();
  // Add title element to node map
  !hasNodeList &&
    saveNodeDeviceTypeProperties(
      titleId,
      getHeadingDeviceTypeCssProperties(
        defaultHeadingTag,
        defaultHeadingStyle,
        defaultHeadingMetaAttributes,
      ),
      defaultHeadingMetaAttributes,
      defaultHeadingTag,
      title || "",
    );

  !hasNodeList &&
    description &&
    saveNodeDeviceTypeProperties(
      descriptionId,
      getHeadingDeviceTypeCssProperties(
        defaultDescriptionTag,
        defaultDescriptionStyle,
        defaultDescriptionMetaAttributes,
      ),
      defaultDescriptionMetaAttributes,
      defaultDescriptionTag,
      description || "",
    );

  hasNodeList && populateNodeDeviceMapWithPublishedElements(nodeList);

  builder.buildTree([
    {
      key: ROOT_NODE_ID,
      tag: "div",
      parentID: null,
      widgetType: "root",
      style: rootNodeStyles,
      tabletStyle: rootNodeStyles,
      mobileStyle: rootNodeStyles,
    },
    ...(!hasNodeList
      ? [
          {
            key: titleId,
            tag: defaultHeadingTag,
            tabletTag: defaultHeadingTag,
            mobileTag: defaultHeadingTag,
            parentID: ROOT_NODE_ID,
            widgetType: "heading",
            content: title,
            tabletContent: title,
            mobileContent: title,
            metaAttributes: defaultHeadingMetaAttributes,
            tabletMetaAttributes: defaultHeadingMetaAttributes,
            mobileMetaAttributes: defaultHeadingMetaAttributes,
            style: defaultHeadingStyle,
            tabletStyle: defaultHeadingStyle,
            mobileStyle: defaultHeadingStyle,
          },
          ...(description
            ? [
                {
                  key: descriptionId,
                  tag: defaultDescriptionTag,
                  tabletTag: defaultDescriptionTag,
                  mobileTag: defaultDescriptionTag,
                  parentID: ROOT_NODE_ID,
                  widgetType: "heading",
                  content: description,
                  tabletContent: description,
                  mobileContent: description,
                  metaAttributes: defaultDescriptionMetaAttributes,
                  tabletMetaAttributes: defaultDescriptionMetaAttributes,
                  mobileMetaAttributes: defaultDescriptionMetaAttributes,
                  style: defaultDescriptionStyle,
                  tabletStyle: defaultDescriptionStyle,
                  mobileStyle: defaultDescriptionStyle,
                },
              ]
            : []),
        ]
      : nodeList),
  ]);
};

const useGetBuilder = ({ productId, title, description, theme }: Props) => {
  const { merchantId } = useGetCurrentMerchantId();

  const { handleRefetch } = useSavePaymentForm({
    productId,
    productTitle: title,
  });

  useGetBuilderData(merchantId, productId, {
    onSuccess(data) {
      if (data?.HTML) {
        batch(() => {
          isBuilderError.value = false;

          build(data.HTML, title, description);

          isDraftCreated.value =
            !data?.Snapshot || data?.UpdatedAt > data?.Snapshot?.UpdatedAt;
          isPublished.value = Boolean(data?.Snapshot);

          formUpdatedAt.value = data?.UpdatedAt || 0;

          isDraftEnabled.value = !isDraftCreated.value;
          isPublishEnabled.value = isDraftCreated.value || !isPublished.value;

          globalColors.general.value = {
            ...globalColors.general.value,
            ...getColorsMap(data?.GlobalColors),
          };

          globalColors.custom.value = {
            ...globalColors.custom.value,
            ...getColorsMap(data?.CustomColors),
          };

          setHeadingStyle(data, headingsSignal, "");
          setHeadingStyle(data, mobileHeadingsSignal, "Mobile");
          setButtonStyle(data);
        });
      }
    },
    onError(err) {
      if (err && err?.response?.status === 404) {
        batch(() => {
          globalColors.general.value = getColorsMap(
            defaulGlobalColors.GeneralColors,
          );
          globalColors.custom.value = getColorsMap(
            defaulGlobalColors.CustomColors,
          );
          build([], title, description);
        });
        isBuilderError.value = false;
      } else {
        NiceModal.show(ADVANCED_BUILDER_ERROR_MODAL, {
          theme,
          handleRefetch,
        });

        isBuilderError.value = true;
      }
    },
  });
};

const setButtonStyle = (data: any) => {
  //TODO: research for a scalable solution
  const { colors } = useColors({ withGradients: true });

  batch(() => {
    if (data?.ButtonDefaultStyles?.borderRadius) {
      globalButtonStyles.borderRadius.value =
        data?.ButtonDefaultStyles?.borderRadius;
    }
    if (data?.ButtonDefaultStyles?.padding) {
      globalButtonStyles.padding.value = data?.ButtonDefaultStyles?.padding;
    }
    if (data?.ButtonDefaultStyles?.fontSize) {
      globalButtonStyles.fontSize.value = data?.ButtonDefaultStyles?.fontSize;
    }
    if (data?.ButtonDefaultStyles?.backgroundColor) {
      const bgColorName = data?.ButtonDefaultStyles?.backgroundColor;
      let bgColor;

      if (colors?.[bgColorName]?.isGradient) {
        bgColor = createGradient(colors?.[bgColorName]);
      } else {
        bgColor = colors?.[bgColorName]?.hexes[0]?.hexValue;
      }

      globalButtonStyles.backgroundColor.value = {
        name: bgColorName,
        color: bgColor,
      };
    }
    if (data?.ButtonDefaultStyles?.color) {
      globalButtonStyles.textColor.value = {
        name: data?.ButtonDefaultStyles?.color,
        color: colors?.[data?.ButtonDefaultStyles?.color]?.hexes[0]?.hexValue,
      };
    }
  });
};

const setHeadingStyle = (data: any, signal: any, mobileKey: string) => {
  //TODO: research for a scalable solution
  const { colors } = useColors({ withGradients: true });

  batch(() => {
    for (const key of headingKeys) {
      const headingItem =
        key === "body"
          ? `PDefault${mobileKey}Styles`
          : key.toUpperCase() + `Default${mobileKey}Styles`;

      const itemData = data?.[headingItem];

      if (itemData?.fontFamily) {
        signal[key].fontFamily.value = {
          name: itemData?.fontFamily,
          value: optionsMap?.[itemData?.fontFamily as keyof typeof optionsMap],
        };
      }

      if (itemData?.fontWeight) {
        signal[key].fontWeight.value = {
          name:
            itemData.fontWeight.charAt(0).toUpperCase() +
            itemData.fontWeight.slice(1),
          value:
            defaultWeights?.[
              itemData?.fontWeight as keyof typeof defaultWeights
            ]?.value || 400,
        };
      }

      const colorName = itemData?.color;
      if (colors[colorName]) {
        signal[key].textColor.value = {
          name: colorName,
          color: colors[colorName]?.isGradient
            ? createGradient(colors[colorName])
            : colors[colorName].hexes[0].hexValue,
        };
      }

      if (itemData?.fontSize) {
        signal[key].fontSize.value = Number(itemData?.fontSize);
      }
      if (itemData?.lineHeight) {
        signal[key].lineHeight.value = Number(itemData?.lineHeight);
      }
      if (itemData?.letterSpacing) {
        signal[key].letterSpacing.value = Number(itemData?.letterSpacing);
      }
    }
  });
};

const getCssProperties = (
  widgetType: any,
  style: any,
  metaAttributes: any,
  tag: any,
) => {
  switch (widgetType) {
    case "heading":
      return getHeadingDeviceTypeCssProperties(tag, style, metaAttributes);
    case "divider":
      return getDividerDeviceTypeCssProperties(style, metaAttributes);
    case "button":
      return getButtonWidgetDeviceTypeCssProperties(style, metaAttributes);
    case "single_image":
      return getSingleImageDeviceTypeCssProperties(style, metaAttributes);
    default:
      return {};
  }
};

const populateNodeDeviceMapWithPublishedElements = (nodeList: any) => {
  nodeList.forEach((node: any) => {
    const {
      key,
      widgetType,
      style,
      tabletStyle,
      mobileStyle,
      tag,
      tabletTag,
      mobileTag,
      content,
      tabletContent,
      mobileContent,
      metaAttributes,
      tabletMetaAttributes,
      mobileMetaAttributes,
      href,
      tabletHref,
      mobileHref,
    } = node;
    // This is done temporarily so it only works for basic widgets
    if (!deviceSpecificWidgets.has(widgetType)) return;

    const cssProperties = getCssProperties(
      widgetType,
      style,
      metaAttributes,
      tag,
    );
    const tabletCssProperties = getCssProperties(
      widgetType,
      tabletStyle,
      tabletMetaAttributes,
      tabletTag,
    );
    const mobileCssProperties = getCssProperties(
      widgetType,
      mobileStyle,
      mobileMetaAttributes,
      mobileTag,
    );

    savePublishedNodeDeviceTypeProperties(
      key,
      cssProperties,
      tabletCssProperties,
      mobileCssProperties,
      metaAttributes,
      tabletMetaAttributes,
      mobileMetaAttributes,
      tag,
      tabletTag,
      mobileTag,
      decodeSpecialCharacters(content),
      decodeSpecialCharacters(tabletContent),
      decodeSpecialCharacters(mobileContent),
      href,
      tabletHref,
      mobileHref,
    );
  });
};

export default useGetBuilder;
