import {
  getRiskProfile,
  getRiskProfileTransactions,
} from "@services/api/riskProfile/transactionsRiskProfile";
import { useQuery } from "react-query";
import useDataParser from "../helpers/parsers";
import { useAppSelector } from "@redux/hooks";
import {
  selectTimeFilterQuery,
  selectTypeFilter,
} from "@redux/slices/acquirer/transactionRiskProfile";
import { useModal } from "@ebay/nice-modal-react";
import { QKEY_LIST_RISK_PROFILE_TRANSACTIONS } from "@constants/queryKeys";
import moment from "moment";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";

const last24Hours = moment(new Date()).subtract(24, "hours").unix();

const useGetRiskProfile = (id: string) => {
  const modal = useModal();
  const typeFilter = useAppSelector(selectTypeFilter);

  const isListIPProfileAllowed = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const isListTransactionAllowed = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE_TRANSACTION,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const isListEscalationAllowed = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE_ESCALATION,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const isTransactionQueryEnabled = {
    all: isListTransactionAllowed,
    escalations: isListEscalationAllowed,
  }[typeFilter];

  const { data, isLoading } = useQuery(
    ["get-transactions-risk-profile", id],
    async () => await getRiskProfile(id),
    {
      refetchOnWindowFocus: false,
      enabled: modal.visible && isListIPProfileAllowed,
    },
  );

  const timeFilter = useAppSelector(selectTimeFilterQuery);
  const { data: transactions, isLoading: isLoadingTransactions } = useQuery(
    [QKEY_LIST_RISK_PROFILE_TRANSACTIONS, id, timeFilter],
    async () =>
      await getRiskProfileTransactions(id, {
        filter: timeFilter,
        groupBy: "day",
      }),
    {
      refetchOnWindowFocus: false,
      enabled: modal.visible && isTransactionQueryEnabled,
    },
  );
  const { data: hasMore } = useQuery(
    ["has-more-transactions", id],
    async () => {
      const hasMore = await getRiskProfileTransactions(id, {
        filter: `createdAt:<d${last24Hours}&max=1`,
      });
      return hasMore?.total;
    },
    {
      refetchOnWindowFocus: false,
      enabled: modal.visible && isListTransactionAllowed,
    },
  );

  const customData = useDataParser({
    data,
    transactions,
  });

  return {
    data: customData,
    isLoading: isLoading || isLoadingTransactions,
    hasMore: hasMore > 0,
    isListIPProfileAllowed,
  };
};

export default useGetRiskProfile;
