interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

export const SwitchAccountToastIcon = ({
  width = 24,
  height = 24,
  fill = "#915CA7",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6665 21.3334L27.1968 21.8637L27.7272 21.3334L27.1968 20.8031L26.6665 21.3334ZM6.08317 18.6668C6.08317 18.2525 5.74738 17.9167 5.33317 17.9167C4.91896 17.9167 4.58317 18.2525 4.58317 18.6668L6.08317 18.6668ZM21.8635 27.1971L27.1968 21.8637L26.1362 20.8031L20.8028 26.1364L21.8635 27.1971ZM27.1968 20.8031L21.8635 15.4698L20.8028 16.5304L26.1362 21.8637L27.1968 20.8031ZM26.6665 20.5834L7.33317 20.5834L7.33317 22.0834L26.6665 22.0834L26.6665 20.5834ZM6.08317 19.3334L6.08317 18.6668L4.58317 18.6668L4.58317 19.3334L6.08317 19.3334ZM7.33317 20.5834C6.64281 20.5834 6.08317 20.0238 6.08317 19.3334L4.58317 19.3334C4.58317 20.8522 5.81439 22.0834 7.33317 22.0834L7.33317 20.5834Z"
        fill={fill}
      />
      <path
        d="M5.3335 10.6667L4.80317 11.197L4.27284 10.6667L4.80316 10.1363L5.3335 10.6667ZM26.0002 10.6667L26.0002 11.4167L26.0002 10.6667ZM28.7502 13.3333C28.7502 13.7475 28.4144 14.0833 28.0002 14.0833C27.5859 14.0833 27.2502 13.7475 27.2502 13.3333L28.7502 13.3333ZM10.1365 16.5303L4.80317 11.197L5.86383 10.1363L11.1972 15.4697L10.1365 16.5303ZM4.80316 10.1363L10.1365 4.803L11.1972 5.86366L5.86383 11.197L4.80316 10.1363ZM5.3335 9.91667L26.0002 9.91666L26.0002 11.4167L5.3335 11.4167L5.3335 9.91667ZM28.7502 12.6667L28.7502 13.3333L27.2502 13.3333L27.2502 12.6667L28.7502 12.6667ZM26.0002 9.91666C27.5189 9.91666 28.7502 11.1479 28.7502 12.6667L27.2502 12.6667C27.2502 11.9763 26.6905 11.4167 26.0002 11.4167L26.0002 9.91666Z"
        fill={fill}
      />
    </svg>
  );
};
