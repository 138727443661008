import { Text } from "@common/Text";
import { palette } from "@palette";
import { PEPStatusType } from "../types";
import { styled } from "@mui/material";
import React, { memo, useMemo } from "react";

type Props = {
  ownerPepStatus?: PEPStatusType;
  EndIcon?: JSX.Element;
  pepStatusSetByUnderwriter?: boolean;
  manualPepStatus?: PEPStatusType;
  isSidePanel?: boolean;
  withPEP?: boolean;
};

const PepStatusChip = ({
  ownerPepStatus = "pending",
  EndIcon,
  manualPepStatus,
  pepStatusSetByUnderwriter,
  withPEP,
}: Props) => {
  const status = useMemo(() => {
    if (
      (ownerPepStatus === "clear" || manualPepStatus === "clear") &&
      pepStatusSetByUnderwriter
    )
      return "clear_manually";

    const statusManually =
      ownerPepStatus === "clear" || manualPepStatus === "clear"
        ? "clear_manually"
        : "confirmed_match";

    return manualPepStatus ? statusManually : ownerPepStatus;
  }, [ownerPepStatus, manualPepStatus, pepStatusSetByUnderwriter]);

  const { color, backgroundColor, label } = PEPStatus[status];

  return (
    <StyledText color={color} backgroundColor={backgroundColor}>
      {withPEP ? `PEP ${label}` : label}
      {EndIcon &&
        React.cloneElement(EndIcon, {
          fill: color,
        })}
    </StyledText>
  );
};

type StatusObject = { label: string; color: string; backgroundColor: string };
type ExtendedPEPStatusType = PEPStatusType | "disabled_pending";

const PEPStatus: Record<ExtendedPEPStatusType, StatusObject> = {
  pending: {
    label: "Pending",
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  possible_match: {
    label: "Possible Match",
    color: palette.filled["orange"],
    backgroundColor: palette.tag.warning.bg,
  },
  clear: {
    label: "Clear",
    color: palette.filled["success"],
    backgroundColor: palette.tag.success.bg,
  },
  clear_manually: {
    label: "Manually Cleared",
    color: palette.filled["success"],
    backgroundColor: palette.tag.success.bg,
  },
  confirmed_match: {
    label: "Confirmed Match",
    color: palette.filled["red"],
    backgroundColor: palette.tag.error.bg,
  },
  disabled_pending: {
    label: "Pending",
    color: palette.neutral[60],
    backgroundColor: palette.neutral[20],
  },
};

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
  fontSize: "14px",
  padding: "2px 16px",
  borderRadius: "16px",
  backgroundColor,
  display: "inline-flex",
  gap: "4px",
  alignItems: "center",
  width: "fit-content",
}));

export default memo(PepStatusChip);
