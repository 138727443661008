import { authorizations } from "@redux/slices/accessControl/initialState";
import { TUserPermissions } from "@redux/slices/accessControl/types";
import { store } from "@redux/store";
import { getAccessOperation } from "./utils";

const formatPermissions = (data?: any[]): TUserPermissions => {
  if (!data) return authorizations;

  return data.reduce((acc: TUserPermissions, v: any) => {
    const name = v?.resourceName?.replace("gs:rn:", "");
    const operation = getAccessOperation(v?.actionName);
    const action = v?.isDeny ? "deny" : "allow";

    if (!name || !operation || !action) return acc;

    if (!acc[name]) {
      return {
        ...acc,
        [name]: {
          [operation]: action,
        },
      };
    }
    if (acc[name][operation] === "deny") return acc;
    const {
      auth: {
        user: { role },
      },
    } = store.getState();
    if (
      (name in acc &&
        operation in acc[name] &&
        role.toLocaleLowerCase() !== v.categoryName?.toLowerCase()) ||
      ""
    )
      return acc;

    return {
      ...acc,
      [name]: {
        ...acc[name],
        [operation]: action,
      },
    };
  }, {});
};

export default formatPermissions