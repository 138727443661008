import { useEffect, useState } from "react";
import { Button } from "@common/Button";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFCustomEditor } from "@common/CustomEditor";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { RHFSwitch } from "@common/Switch";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { palette } from "@palette";
import { EditorState } from "draft-js";
import { IDropzoneProps } from "react-dropzone-uploader";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";
import { Wrapper } from "./utils";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { PaymentStepSection, NewRecurrenceCard } from "../recurrence.atoms";
import {
  SubscriptionPaymentType,
  SubscriptionType,
} from "../MembershipsMinibuilder/types";
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import { showMessage } from "@common/Toast";
import { convertMinibuilderDescription } from "@utils/draft.editor.helpers";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { TitleContainer } from "./CreateAmount";
import { TrashBin } from "@assets/icons/RebrandedIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { useAppSelector } from "@redux/hooks";
import { selectIsAllowedByResource } from "@redux/slices/accessControl/accessControl";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

const recurrenceCards = [
  {
    title: "One-Time",
    checked: true,
    isDefault: true,
  },
  {
    title: "Monthly",
    checked: false,
    isDefault: false,
  },
  {
    title: "Quarterly",
    checked: false,
    isDefault: false,
  },
  {
    title: "Yearly",
    checked: false,
    isDefault: false,
  },
];

interface CreateAmountProps {
  item?: SubscriptionType;
  onClose: (item?: SubscriptionType) => void;
  onDelete?: (id: string) => void;
  hasUniqueTitle: (title: string, id?: string) => boolean;
}

type FormInputs = {
  thumbnail: string | File;
  amount: string;
  title: string;
  description: EditorState | null;
  displaySubscriptions: boolean;
  paymentTypes: SubscriptionPaymentType[];
  stock: string;
};

const schema = Yup.object().shape({
  title: Yup.string().trim().required("This field is required"),
  amount: Yup.string()
    .required("This field is required")
    .test("Must be above 1 USD", (value) => {
      if (!value) return false;
      const valueToNumber = parseFloat(value);
      if (Number.isNaN(valueToNumber)) return false;
      return valueToNumber >= 1;
    }),
  thumbnail: Yup.mixed<File | string>(),
  description: Yup.mixed<EditorState>().nullable(),
  displaySubscriptions: Yup.boolean(),
  paymentTypes: Yup.array<SubscriptionPaymentType>(),
  stock: Yup.string().required("This field is required"),
});

const defaultValues = {
  thumbnail: "",
  amount: "",
  title: "",
  description: null,
  displaySubscriptions: false,
  paymentTypes: recurrenceCards,
  stock: "",
};

const CreateSubscription = ({
  item,
  onClose,
  onDelete,
  hasUniqueTitle,
}: CreateAmountProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handlePostImage } = useUploadVariantImage();
  const { isDesktopView } = useCustomTheme();
  const modal = useModal();
  const open = modal.visible;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const methods = useForm<FormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    reset,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const values = watch();

  const authorizations = useAppSelector((state) =>
    selectIsAllowedByResource(
      state,
      composePermission(
        RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.PRODUCT,
        RESOURCE_BASE.AMOUNT,
      ),
    ),
  );

  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isDeleteAllowed = authorizations[OPERATIONS.DELETE] === "allow";
  const isUpdateAllowed = authorizations[OPERATIONS.UPDATE] === "allow";
  const canEdit = (!!item && isUpdateAllowed) || !item;

  const handleClickCard = (label: string) => {
    if (!canEdit) return;

    let defaultValue = "";
    const selected = values.paymentTypes.reduce((acc, current) => {
      current.isDefault && (defaultValue = current.title);
      if (current.checked) {
        return acc + 1;
      }
      return acc;
    }, 0);

    const updatedArray = values.paymentTypes
      .map((recurrency) => {
        if (recurrency.title !== label) return recurrency;

        const isChecked = recurrency.checked;
        const isDefault = recurrency.isDefault;

        if (selected > 1) {
          if (isChecked && !isDefault) {
            defaultValue = recurrency.title;
            return {
              ...recurrency,
              isDefault: true,
            };
          }
          if (isChecked && isDefault) {
            defaultValue = "";
            return {
              ...recurrency,
              checked: false,
              isDefault: false,
            };
          }
          return {
            ...recurrency,
            checked: !isChecked,
          };
        } else {
          return {
            ...recurrency,
            checked: true,
          };
        }
      })
      .map((item) => {
        if (!defaultValue && item.checked) {
          defaultValue = item.title;
          return {
            ...item,
            isDefault: true,
          };
        }
        if (!item.isDefault) return item;
        if (item.isDefault && item.title !== defaultValue) {
          return {
            ...item,
            isDefault: false,
          };
        }
        return item;
      });
    setValue("paymentTypes", updatedArray, { shouldDirty: true });
  };

  useEffect(() => {
    if (item) {
      const typeSafeDescription = convertMinibuilderDescription(
        item.description,
      );
      reset({
        thumbnail: item?.thumbnail || "",
        amount: item.amount,
        title: item.title,
        description: typeSafeDescription as any,
        displaySubscriptions: item.display,
        stock: item.in_stock,
        paymentTypes: item.paymentTypes,
      });
    }
    if (!item) reset(defaultValues);
  }, [item]);

  const onEditorStateChange = (editorState: EditorState) => {
    setValue("description", editorState, { shouldDirty: true });
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
    if (file) {
      setValue(
        "thumbnail",
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        { shouldDirty: true },
      );
    }
  };

  const hideModal = () => {
    reset();
    modal.hide();
  };

  const handleDeleteItem = () => {
    hideModal();
    if (item?.id && onDelete) onDelete(item.id);
  };

  const handleCancel = () => {
    hideModal();
    onClose();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (!hasUniqueTitle(data.title, item?.id)) {
      showMessage("Error", "An element with the same name already exists");
      return;
    }
    setIsLoading(true);
    let url;
    try {
      if (typeof data.thumbnail === "string" || !data.thumbnail) {
        url = data.thumbnail;
      } else {
        url = (await handlePostImage(data.thumbnail)) || null;
      }
    } catch (error) {
      url = null;
    }

    const itemObject: SubscriptionType = {
      id: item ? item.id : Math.random().toString(36).substring(2, 9),
      active: item ? item.active : true,
      title: dirtyFields.title || !item ? data.title : item.title,
      amount: dirtyFields.amount || !item ? data.amount : item.amount,
      description: () =>
        dirtyFields.description || !item
          ? data.description
            ? data.description
            : ""
          : item.description,
      thumbnail: dirtyFields.thumbnail || !item ? url : item.thumbnail,
      in_stock: data.stock,
      paymentTypes: data.paymentTypes,
      display: data.displaySubscriptions,
    };
    setIsLoading(false);
    hideModal();
    onClose(itemObject);
  };

  const renderRecurrenceCards = ({
    title,
    checked,
    isDefault,
  }: (typeof recurrenceCards)[0]) => {
    const enabledCards = values.paymentTypes.filter((item) => item.checked);
    return (
      <NewRecurrenceCard
        key={title}
        label={title}
        isSelected={checked}
        isDefault={isDefault}
        handleClick={() => handleClickCard(title)}
        isDisabled={enabledCards.length < 2 || !canEdit}
      />
    );
  };

  return (
    <Wrapper open={open} onClose={handleCancel}>
      <FormProvider {...methods}>
        <Box
          component="form"
          id="create-subscription"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <TitleContainer>
            <Text
              color={palette.black[100]}
              variant="headline"
              fontSize="24px"
              lineHeight="32.4px"
              fontWeight="book"
              sx={{ letterSpacing: "-0.24px" }}
            >
              {item ? `Edit ${item.title}` : "Create Subscription"}
            </Text>
            {item && onDelete && !isDesktopView && (
              <Button
                background="text"
                size="medium"
                onClick={handleDeleteItem}
                disabled={!isDeleteAllowed}
                tooltipProps={{
                  show: !isDeleteAllowed,
                  message: DELETE_DENY_MESSAGE,
                }}
              >
                <TrashBin width={32} height={32} fill={palette.error.hover} />
              </Button>
            )}
          </TitleContainer>
          <ContentContainer>
            <FadeUpWrapper delay={50}>
              <WithTooltipWrapper
                hasTooltip={!isAddImageAllowed}
                tooltipProps={{
                  show: !isAddImageAllowed,
                  message: UPLOAD_DENY_MESSAGE,
                }}
              >
                <RHFUploadAvatar
                  avatarType="thumbnail"
                  name="thumbnail"
                  onChangeStatus={handleChangeStatus}
                  isProductVariant
                  disabled={!canEdit || !isAddImageAllowed}
                  height="78px"
                  width="78px"
                  key={Date.now()} // Needs to be investigated a bit better
                />
              </WithTooltipWrapper>
            </FadeUpWrapper>
            <Stack direction="column" gap={1}>
              <FadeUpWrapper delay={150}>
                <RHFInput
                  name="title"
                  label="Title"
                  fullWidth
                  disabled={!canEdit}
                />
              </FadeUpWrapper>
              <FadeUpWrapper delay={200}>
                <Stack direction="row" spacing={1}>
                  <Box width="50%">
                    <RHFCustomAmountInput
                      name="amount"
                      currency="usd"
                      initialValue={item?.amount}
                      disabled={!canEdit}
                    />
                  </Box>
                  <RHFCustomIntegerInput
                    name="stock"
                    label="Stock"
                    width="50%"
                    disabled={!canEdit}
                  />
                </Stack>
              </FadeUpWrapper>
            </Stack>
            <FadeUpWrapper delay={200}>
              <PaymentStepSection
                title="Recurrence"
                description="Choose the payment types available to your customers"
              >
                <Stack direction={isDesktop ? "row" : "column"} gap="4px">
                  <Box
                    gap="4px"
                    display={isDesktop ? "inline-flex" : "flex"}
                    flexGrow={1}
                  >
                    {values.paymentTypes.slice(0, 2).map(renderRecurrenceCards)}
                  </Box>
                  <Box
                    gap="4px"
                    display={isDesktop ? "inline-flex" : "flex"}
                    flexGrow={1}
                  >
                    {values.paymentTypes.slice(2, 4).map(renderRecurrenceCards)}
                  </Box>
                </Stack>
              </PaymentStepSection>
            </FadeUpWrapper>
            <FadeUpWrapper delay={250}>
              <RHFCustomEditor
                name="description"
                readOnly={!canEdit}
                onEditorStateChange={onEditorStateChange}
                placeholder={"Description (optional)"}
              />
              <Box
                sx={{ marginLeft: "5px" }}
                display="flex"
                alignItems="center"
              >
                <RHFSwitch
                  name="displaySubscriptions"
                  disabled={isLoading || !canEdit}
                  size="small"
                  label="Display subscriptions available"
                />
              </Box>
            </FadeUpWrapper>
          </ContentContainer>

          <ActionsContainer isEdit={Boolean(item)}>
            <Box>
              {item && onDelete && isDesktopView && (
                <Button
                  background="text"
                  size="medium"
                  sx={{ color: palette.error.hover }}
                  onClick={handleDeleteItem}
                  disabled={!isDeleteAllowed}
                  data-testid="delete-subscription-button"
                  tooltipProps={{
                    show: !isDeleteAllowed,
                    message: DELETE_DENY_MESSAGE,
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Stack direction="row" gap={2} alignItems="center">
              <Button
                background="text"
                size="medium"
                onClick={handleCancel}
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    flexGrow: 1,
                  },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: "auto",
                  },
                })}
              >
                Cancel
              </Button>
              <Button
                background="primary"
                size="medium"
                disabled={!isValid || !isDirty || isLoading}
                form="create-subscription"
                type="submit"
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    flexGrow: 3,
                  },
                })}
                data-testid={`${item ? "save" : "create"}-subscription-button`}
              >
                {item ? "Save" : "Create"}
              </Button>
            </Stack>
          </ActionsContainer>
        </Box>
      </FormProvider>
    </Wrapper>
  );
};

export const CreateSubscriptionModal = NiceModal.create(
  (props: CreateAmountProps) => {
    return <CreateSubscription {...props} />;
  },
);

const ContentContainer = styled(DialogContent)(() => ({
  padding: "24px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

const ActionsContainer = styled(DialogActions, {
  shouldForwardProp: (prop) => prop !== "isEdit",
})<{ isEdit?: boolean }>(({ theme, isEdit }) => ({
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: isEdit ? "space-between" : "flex-end",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-end",
    gap: "8px",
  },
}));

export default CreateSubscription;
