import { isEmpty, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { showMessage } from "@common/Toast";
import { checkPortals } from "@utils/routing";
import { stopEventPropagation } from "@utils/helpers";
import useGetAlerts from "./useGetAlerts";
import { QKEY_ALERTS_LIST } from "@constants/queryKeys";
import { TAlertMethods, TGroupedAlerts } from "../types";
import useAlertsTabs from "./useAlertsTabs";
import useAlertsLogs from "./useAlertsLogs";

type TUseNotificationTabProps = {
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function useNotificationTab({
  setIsDisabled,
}: TUseNotificationTabProps) {
  const { currentPortal } = checkPortals();
  const queryClient = useQueryClient();

  const { activeTab, setActiveTab, tabs } = useAlertsTabs();
  const [data, setData] = useState<TGroupedAlerts | undefined>(undefined);
  const { resetLogs, updateLog, generatePayload, logsBulkUpdate } =
    useAlertsLogs();

  const {
    data: defaultData,
    isLoading,
    alertsMutation,
  } = useGetAlerts({
    onSuccess: (data: TGroupedAlerts) => {
      setData(data);
      if (!isEmpty(activeTab)) return;
      setActiveTab(currentPortal === "provider" ? "enterprise" : currentPortal);
    },
  });

  const customData = useMemo(() => {
    const hashedData = data && activeTab?.value ? data[activeTab.value] : {};
    const alerts = Object.values(hashedData);

    return {
      alerts: alerts.sort((a, b) => sorter(a.title, b.title)),
      totalAssigned: {
        emailCount: alerts.reduce((acc, a) => acc + +a.isAssigned.email, 0),
        pushCount: alerts.reduce((acc, a) => acc + +a.isAssigned.push, 0),
      },
    };
  }, [data, activeTab]);

  const addIdToLog = (id: string, method: TAlertMethods, newValue: boolean) => {
    if (!activeTab?.value || !defaultData) return;
    const originalValue = defaultData[activeTab.value][id].isAssigned[method];
    updateLog(id, method, newValue, originalValue);
  };

  const handleChange = useCallback(
    (id: string, method: TAlertMethods) => {
      setData((prevData) => {
        if (!prevData) return prevData;
        let groupedData = prevData;

        Object.keys(groupedData).forEach((key) => {
          const tabName = key as keyof typeof groupedData;
          const currentElement = groupedData[tabName][id];

          if (currentElement) {
            const assigned = !currentElement.isAssigned[method];

            if (tabName === activeTab?.value) {
              addIdToLog(id, method, assigned);
            }

            groupedData = {
              ...groupedData,
              [tabName]: {
                ...groupedData[tabName],
                [id]: {
                  ...currentElement,
                  isAssigned: {
                    ...currentElement.isAssigned,
                    [method]: assigned,
                  },
                },
              },
            };
          }
        });
        return groupedData;
      });
    },
    [defaultData, activeTab],
  );

  const handleCheckAll = (
    method: TAlertMethods,
    operation: "add" | "remove",
  ) => {
    if (!activeTab?.value) return;
    const idsToAttach: string[] = [];
    const idsToDetach: string[] = [];

    setData((prevData) => {
      if (!prevData) return prevData;

      const obj = prevData[activeTab.value];
      const groupedData = Object.entries(obj).reduce((acc, [key, value]) => {
        if (defaultData[activeTab.value][key].disabled[method]) {
          return {
            ...acc,
            [key]: value,
          };
        }
        const assigned = operation === "add" ? true : false;
        const originalValue =
          defaultData[activeTab.value][key].isAssigned[method];

        if (!assigned && originalValue) {
          idsToDetach.push(key);
        } else if (!originalValue && assigned) {
          idsToAttach.push(key);
        }

        return {
          ...acc,
          [key]: {
            ...value,
            isAssigned: {
              ...value.isAssigned,
              [method]: assigned,
            },
          },
        };
      }, {} as TGroupedAlerts);

      return {
        ...prevData,
        [activeTab.value]: groupedData,
      };
    });

    logsBulkUpdate(method, "subscribeList", idsToAttach);
    logsBulkUpdate(method, "unsubscribeList", idsToDetach);
  };

  const handleSubmit = useCallback(
    stopEventPropagation((e: any) => {
      if (!data || !defaultData) return;
      const payload = generatePayload();

      alertsMutation.mutate(payload, {
        onError: (error: any) => {
          if (error?.response?.data?.message) {
            showMessage("Error", error?.response?.data?.message, true);
          }
        },
        onSuccess: () => {
          queryClient.setQueriesData(QKEY_ALERTS_LIST, data);
          resetLogs();
          setIsDisabled(true);
          alertsMutation.reset();
        },
      });
    }),
    [data, defaultData],
  );

  const isDirty = !isEqual(data, defaultData);

  useEffect(() => {
    if (!isLoading && !alertsMutation.isLoading && data) {
      setIsDisabled(!isDirty);
    } else {
      setIsDisabled(true);
    }
  }, [isDirty, isLoading, alertsMutation.isLoading]);

  return {
    activeTab,
    setActiveTab,
    tabs,
    customData,
    isLoading,
    handleChange,
    handleCheckAll,
    handleSubmit,
  };
}

const sorter = (a: string, b: string) => {
  const titleA = a.toUpperCase();
  const titleB = b.toUpperCase();

  if (titleA < titleB) {
    return -1;
  } else if (titleA > titleB) {
    return 1;
  } else {
    return 0;
  }
};
