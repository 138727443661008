const AlertIcon = ({
  width = 24,
  height = 24,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3045 4.86838C14.0729 4.45283 13.7375 4.10732 13.3325 3.86696C12.9276 3.62661 12.4675 3.5 11.999 3.5C11.5306 3.5 11.0705 3.62661 10.6655 3.86696C10.2606 4.10732 9.92519 4.45283 9.69352 4.86838L2.71127 17.5646C2.48955 17.963 2.37493 18.4141 2.37901 18.8723C2.38309 19.3305 2.50574 19.7794 2.73452 20.1737C2.96666 20.5804 3.30001 20.9173 3.7008 21.1502C4.1016 21.3831 4.55559 21.5037 5.01677 21.4999H18.9813C19.4425 21.5037 19.8965 21.3831 20.2972 21.1502C20.698 20.9173 21.0314 20.5804 21.2635 20.1737C21.4923 19.7794 21.615 19.3305 21.619 18.8723C21.6231 18.4141 21.5085 17.963 21.2868 17.5646L14.3045 4.86838ZM11.999 18.2273C11.8405 18.2273 11.6855 18.1793 11.5537 18.0894C11.4218 17.9995 11.3191 17.8717 11.2584 17.7222C11.1977 17.5727 11.1819 17.4082 11.2128 17.2495C11.2437 17.0908 11.3201 16.945 11.4322 16.8306C11.5443 16.7162 11.6871 16.6382 11.8426 16.6067C11.9981 16.5751 12.1593 16.5913 12.3058 16.6532C12.4523 16.7152 12.5775 16.82 12.6656 16.9546C12.7536 17.0891 12.8007 17.2473 12.8007 17.4091C12.8007 17.6261 12.7162 17.8342 12.5659 17.9876C12.4155 18.1411 12.2116 18.2273 11.999 18.2273ZM12.8007 14.1365C12.8007 14.3535 12.7162 14.5616 12.5659 14.715C12.4155 14.8684 12.2116 14.9546 11.999 14.9546C11.7864 14.9546 11.5825 14.8684 11.4322 14.715C11.2818 14.5616 11.1974 14.3535 11.1974 14.1365V9.22753C11.1974 9.01054 11.2818 8.80244 11.4322 8.649C11.5825 8.49557 11.7864 8.40937 11.999 8.40937C12.2116 8.40937 12.4155 8.49557 12.5659 8.649C12.7162 8.80244 12.8007 9.01054 12.8007 9.22753V14.1365Z"
        fill="#FF8124"
      />
    </svg>
  );
};

export default AlertIcon;
