import React from "react";

interface IconProps {
  height?: number;
  width?: number;
  color?: string;
}

const Icon = ({ color = "#A9AFBD", height = 24, width = 24 }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M12.343 2.495a.5.5 0 00-.57 0L4.703 7.4a2.731 2.731 0 00-1.172 2.245v9.41a2.72 2.72 0 002.714 2.728H17.87a2.72 2.72 0 002.714-2.728v-3.639a.5.5 0 10-1 0v3.64a1.72 1.72 0 01-1.714 1.727H15.05v-6c0-.339-.107-.662-.348-.901-.24-.238-.56-.338-.884-.338h-3.518c-.347 0-.691.087-.937.355-.238.26-.295.594-.295.883v6H6.245a1.72 1.72 0 01-1.714-1.727v-9.41c0-.57.278-1.101.742-1.423l6.785-4.707 6.784 4.707c.464.322.742.853.742 1.423v1.459a.5.5 0 101 0v-1.46c0-.895-.437-1.734-1.172-2.244l-7.07-4.905zm-2.276 18.288h3.982v-6c0-.13-.038-.177-.052-.19-.014-.015-.06-.05-.18-.05h-3.518c-.137 0-.187.025-.2.033-.005.008-.032.057-.032.206v6zm.029-6.205l.002-.002a.01.01 0 01-.002.002z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Icon;
