import { namespaces } from "../../../../resources/i18n.constants";

export const createMerchant = {
  [namespaces.pages.enterprise.createMerchant]: {
    title: "Create Merchant",
    merchant_information: "Merchant Information",
    merchant_name: "Merchant Name",
    merchant_name_placeholder: "Enter Merchant Name",
    merchant_name_error: "Merchant should have a name",
    taxID: "Federal Tax ID",
    taxID_placeholder: "XX-XXXXXXX",
    taxID_error: "Federal Tax ID is required",
    category: "Category",
    merchant_category_code: "Merchant Category Code",
    estimated_annual_revenue: "Estimated Annual Revenue",
    average_ticket_amount: "Average Ticket Amount (USD)",
    high_ticket_amount: "High Ticket Amount (USD)",
    category_placeholder: "Choose Merchant Category",
    "Nonprofit Purpose and Countries Serviced": "Nonprofit Purpose and Countries Serviced",
    "Enter a short description about the purpose or mission of your Nonprofit and countries serviced.": "Enter a short description about the purpose or mission of your Nonprofit and countries serviced.",
    "Countries Serviced Outside USA/Canada": "Countries Serviced Outside USA/Canada",
    "Please enter the countries Nonprofit services that are outside the USA/Canada.": "Please enter the countries Nonprofit services that are outside the USA/Canada.",
    owner_information: "Owner Information",
    firstName: "First Name",
    firstName_placeholder: "Enter First Name",
    firstName_error: "Owner First Name is required",
    lastName: "Last Name",
    lastName_placeholder: "Enter Last Name",
    lastName_error: "Owner Last Name is required",
    email: "Email",
    email_placeholder: "email@example.com",
    email_error: "Owner Email is required",
    email_error2: "Not a valid format",
    password: "Password",
    password_placeholder: "Add password",
    enterprise_information: "Provider Information",
    enterprise_name_placeholder: "Enter Provider Name",
    enterprise_name: "Provider Name",
    permalink:"Permalink"
  },
};
