// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  APPROVE_MERCHANT_MODAL,
  DECLINE_MERCHANT_MODAL,
  MARK_MERCHANT_AS_PENDING_MODAL,
  SPONSOR_DECLINE_MERCHANT_MODAL,
  SUSPEND_MERCHANT_MODAL,
  MERCHANT_INITIAL_APPROVAL_MODAL,
} from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";
import { TStatusReview } from "../data.types";
import { showMessage } from "@common/Toast";
import { useEffect, useState } from "react";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import useGetGlobalTopic from "features/Minibuilders/Conversations/hooks/useGetGlobalTopic";
import { useMutation } from "react-query";
import { createThread } from "@components/Merchants/MerchantPreview/hooks/useRiskMarkAllAsOk";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { TThreadInfo } from "features/Minibuilders/Conversations/types";
import { useUpdateUnderwriting } from "./useUnderwritingUpdate";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";

type IFormInputs = {
  process: boolean;
  transfer: boolean;
};

type Props = {
  data: TStatusReview;
  name?: string;
  isEnterprise?: boolean;
};

export const useStatusReview = ({ data, name, isEnterprise }: Props) => {
  const queryClient = useQueryClient();
  const { openConversationHandler } = useConversationsModal();
  const isMerchantApproved = data.sponsorStatusName === "approved";
  const [isSupspenLoading, setIsSupspenLoading] = useState(false);
  const { topic } = useGetGlobalTopic("sponsor");
  const globalTopicId = topic ? topic.ID : null;
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const { isSponsorApprovalEnabled } = useGetFeatureFlagValues();

  const conversationMutation = useMutation(
    async (data: any) => await createThread(globalMerchantId, data),
  );

  const schema = Yup.object().shape({
    transfer: Yup.boolean(),
    process: Yup.boolean(),
  });

  const defaultValues = {
    process: data.process || false,
    transfer: data.transfer || false,
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setValue, reset } = methods;

  const { updateUnderwriting } = useUpdateUnderwriting(data.accID);

  const handleChangeProcessing = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setValue("process", value);
    updateUnderwriting.mutate(
      { processingEnabled: value },
      {
        onError: (err: any) => {
          setValue("process", data.process);
          showMessage("Error", err?.response?.data?.message);
        },
      },
    );
  };

  const handleChangeTransfer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setValue("transfer", value);
    updateUnderwriting.mutate(
      { transfersEnabled: value },
      {
        onError: (err: any) => {
          setValue("transfer", data.transfer);
          showMessage("Error", err?.response?.data?.message);
        },
      },
    );
  };

  const handleChangeStatus = (value: boolean) => {
    queryClient.invalidateQueries("get-merchant-msp-status");
    queryClient.refetchQueries(["get-merchant-preview", data?.accID]);
    queryClient.invalidateQueries(QKEY_LIST_MERCHANT_STATS);
    setValue("process", value);
    setValue("transfer", value);
  };

  const handleOpenConversation = async (
    message: string,
    merchantId: number,
  ) => {
    if (!globalTopicId) return;
    await conversationMutation.mutateAsync(
      {
        TopicDisplayName: "Sponsor",
        subjectAccID: merchantId,
        topicID: globalTopicId,
        message: {
          body: message,
          mentions: [],
        },
      },
      {
        onSuccess: async (data: TThreadInfo) => {
          queryClient.refetchQueries({
            queryKey: ["get-conversation-topics", merchantId],
          });
          queryClient.refetchQueries([
            "get-conversation-messages",
            data.topicID,
          ]);
          openConversationHandler({
            id: data.topicID,
            name: "Sponsor",
            paths: [],
            forceTopicOpen: true,
          });
          queryClient.refetchQueries(["get-merchant-preview", merchantId]);
        },
      },
    );
  };

  const handleInitialApproval = () => {
    // for enterprise we are keeping the old flow of approval
    if (isEnterprise) {
      NiceModal.show(APPROVE_MERCHANT_MODAL, {
        statusName: data.statusName,
        name: name,
        handleApprove: () =>
          updateUnderwriting.mutate(
            { status: "approved" },
            {
              onSuccess: () => {
                handleChangeStatus(true);
              },
              onError: () => {
                queryClient.refetchQueries("get-merchant-preview");
                showMessage(
                  "Error",
                  "Merchant account approval failed. Ensure all required information is accurate and approved",
                );
              },
            },
          ),
      });
    } else {
      NiceModal.show(MERCHANT_INITIAL_APPROVAL_MODAL, {
        merchantId: data.accID,
        defaultStatus:
          data?.merchantRiskStatus &&
          ["high", "restricted"].includes(data?.merchantRiskStatus)
            ? "pre-approval"
            : "auto-approval",
      });
    }
  };

  const handleApprove = () => {
    const payload = isSponsorApprovalEnabled
      ? { sponsorStatus: "approved", status: "approved" }
      : { status: "approved" };

    NiceModal.show(APPROVE_MERCHANT_MODAL, {
      statusName: data.statusName,
      name: name,
      handleApprove: () =>
        updateUnderwriting.mutate(payload, {
          onSuccess: () => {
            handleChangeStatus(true);
            queryClient.refetchQueries(["get-merchant-preview", data.accID]);
          },
          onError: () => {
            showMessage(
              "Error",
              "Merchant account approval failed. Ensure all required information is accurate and approved",
            );
          },
        }),
    });
  };

  const handleDecline = (isSponsor?: boolean) => {
    const modal = isSponsor
      ? SPONSOR_DECLINE_MERCHANT_MODAL
      : DECLINE_MERCHANT_MODAL;

    const payload = isSponsor
      ? { sponsorStatus: "declined", status: "declined" }
      : { status: "declined" };

    NiceModal.show(modal, {
      name: name,
      handleDecline: (reason?: string) =>
        updateUnderwriting.mutate(payload, {
          onSuccess: () => {
            handleChangeStatus(false);
            if (isSponsor) {
              handleOpenConversation(
                `I declined this merchant\nReason: ${reason}`,
                data?.accID,
              );
            }
          },
          onError: (err: any) => {
            showMessage("Error", err?.response?.data?.message);
          },
        }),
    });
  };

  const handleMarkAsPending = () => {
    NiceModal.show(MARK_MERCHANT_AS_PENDING_MODAL, {
      name: name,
      handleMarkAsPending: (reason?: string) =>
        updateUnderwriting.mutate(
          { sponsorStatus: "pending", reason },
          {
            onSuccess: () => {
              handleChangeStatus(false);
              handleOpenConversation(
                `I marked this merchant as Pending\nReason: ${reason}`,
                data?.accID,
              );
            },
            onError: (err: any) => {
              showMessage("Error", err?.response?.data?.message);
            },
          },
        ),
    });
  };

  const handleSuspend = () => {
    if (data.statusName === "suspended") {
      setIsSupspenLoading(true);
      updateUnderwriting.mutate(
        { status: "approved" },
        {
          onSuccess: () => {
            handleChangeStatus(true);
            setIsSupspenLoading(false);
          },
          onError: (err: any) => {
            showMessage("Error", err?.response?.data?.message);
          },
        },
      );
      return;
    }

    NiceModal.show(SUSPEND_MERCHANT_MODAL, {
      handleSuspend: () => {
        setIsSupspenLoading(true);
        updateUnderwriting.mutate(
          { status: "suspended" },
          {
            onSuccess: () => {
              handleChangeStatus(false);
              setIsSupspenLoading(false);
            },
            onError: (err: any) => {
              showMessage("Error", err?.response?.data?.message);
            },
          },
        );
      },
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [data]);

  return {
    methods,
    isMerchantApproved,
    handleChangeProcessing,
    handleChangeTransfer,
    handleApprove,
    handleInitialApproval,
    handleDecline,
    handleSuspend,
    handleMarkAsPending,
    isSupspenLoading,
  };
};
