import * as React from "react";
import { useTheme } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import { palette } from "@palette";
import { useMediaQuery } from "@mui/material";

interface DrawerIconProps {
  component: JSX.Element;
  active?: boolean;
  open?: boolean;
  hover?: boolean;
}

const DrawerListIcon = ({
  component,
  active,
  open,
  hover,
}: DrawerIconProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const drawerIcon = React.cloneElement(component, {
    gradient: active,
    fill: hover ? palette.neutral[90] : palette.neutral[80],
    stroke: hover ? palette.neutral[90] : palette.neutral[80],
    height: isDesktop ? 24 : 32,
    width: isDesktop ? 24 : 32,
  });

  const ListItemIconStyle = {
    minWidth: 0,
    color: "transparent",
    justifyContent: "center",
    mr: open ? theme.spacing(1) : 0,
    transition: "margin ease .5s",
  };

  return <ListItemIcon sx={ListItemIconStyle}>{drawerIcon}</ListItemIcon>;
};

export default DrawerListIcon;
