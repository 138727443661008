import { Box } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import CustomDonationDigitInput from "@components/Fundraisers/PublicPaymentForm/CustomDonationDigitInput";
import numeral from "numeral";
import { parseAmount } from "@utils/index";

type CustomDonationInputProps = {
  min?: number;
  max?: number;
  handleAdd: () => void;
  handleSubtract: () => void;
  handleSetValue: (value: number) => void;
  value: number;
  selected?: boolean;
  wasModified?: boolean;
  setWasModified?: Dispatch<SetStateAction<boolean>>
};

const CustomDonationInput = ({
  min,
  max = 25000,
  handleSetValue,
  selected,
  value: amount,
  wasModified,
  setWasModified,
}: CustomDonationInputProps) => {
  const [isInput, setIsInput] = useState<boolean>(true);
  const [value, setValue] = useState<number | string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!wasModified && setWasModified) setWasModified(true);
    
    const val = event?.target?.value;
    const newValue = val.replace(/(\..*)\./g, "$1");
    if (newValue.endsWith(".")) return setValue(newValue);

    let amount = numeral(newValue).value() || 0;
    amount = Math.min(amount, max);

    setValue(amount.toLocaleString("en-US", { maximumFractionDigits: 2 }));
  };

  const toggleInput = useCallback(
    () => setIsInput((prev) => !prev),
    [setIsInput],
  );

  useEffect(() => {
    if (!selected && min && max) {
      handleSetValue(numeral(value).value() || 0);
    }

    setIsInput(!!selected);
  }, [selected]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const newValue = numeral(value).value();
      if (newValue) {
        handleSetValue(newValue);
      } else if (setWasModified) {
        setWasModified(true);
        handleSetValue(0);
      }
      toggleInput();
    }
  };
  
  const handleBlur = () => {
    const newValue = numeral(value).value();
    if (newValue) {
      handleSetValue(newValue);
    } else if (setWasModified) {
      setWasModified(true);
      handleSetValue(0);
    }
    toggleInput();
  }

  return (
    <Box sx={{ userSelect: "none", height: "29px" }}>
      {isInput ? (
        <CustomDonationDigitInput
          min={min}
          max={max}
          amount={value}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          handleBlur={handleBlur}
        />
      ) : (
        <Text
          sx={{
            cursor: "text",
            fontWeight: 350,
            ...(!wasModified && {color: "#B8B8B8"}),
          }}
          onClick={toggleInput}
          color={palette.neutral[80]}
          variant="h3"
          lineHeight="29px"
          borderRadius="90px"
          fontSize="32px"
        >
          {parseAmount(amount)}
        </Text>
      )}
    </Box>
  );
};

export default CustomDonationInput;
