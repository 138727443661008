import { Switch_V2 as Switch } from "@common/Switch";
import { Text } from "@common/Text";
import EnabledBy from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/EnabledBy";
import TriggersConversationButton from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/TriggersConversationButton";
import { RiskTriggerTitles } from "@components/Merchants/MerchantPreview/RiskProfile/constants";
import { useOpenRiskConversation } from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useOpenRiskConversation";
import { TRiskTFactor } from "@components/Merchants/MerchantPreview/RiskProfile/types";
import NiceModal from "@ebay/nice-modal-react";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { checkPortals } from "@utils/routing";
import { RISK_MONITOR_TOGGLE_TRIGGER_MODAL } from "modals/modal_names";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { CustomToolTip as PermissionTooltip } from "@common/BusinessOwners/CustomToolTip";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

interface IComponentProps extends TRiskTFactor {
  profileId: number;
  merchantId: number;
}

const TriggerCard = ({
  isEnabled,
  description,
  factorID,
  riskPoints,
  name,
  profileId,
  merchantId,
  enabledByUserFirstName,
  enabledByUserAvatarImageURL,
  enabledByUserLastName,
  customReason,
  updatedAt,
  hasUnreadMessages,
  threadID,
  disabledByUserFirstName,
  disabledByUserLastName,
  disabledByUserAvatarImageURL,
}: IComponentProps) => {
  const triggerTitle = RiskTriggerTitles[name] || name;
  const { isAcquirerPortal } = checkPortals();
  const firstName = isEnabled
    ? enabledByUserFirstName
    : disabledByUserFirstName;
  const lastName = isEnabled ? enabledByUserLastName : disabledByUserLastName;
  const imageURL = isEnabled
    ? enabledByUserAvatarImageURL
    : disabledByUserAvatarImageURL;

  const canUpdateTrigger = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.RISK_PROFILE_FACTOR,
    ),
    operation: OPERATIONS.UPDATE,
  });
  const handleOpenModal = () => {
    NiceModal.show(RISK_MONITOR_TOGGLE_TRIGGER_MODAL, {
      isEnabled,
      factorID,
      profileId,
      merchantId,
      triggerTitle,
      triggerDescription: description,
      history: {
        firstName,
        lastName,
        imageURL,
        reason: customReason,
        updatedAt,
      },
    });
  };

  const { handleOpenRiskConversation } = useOpenRiskConversation({
    threadID: threadID,
    merchantId,
    defaultMessage: `#${triggerTitle?.replaceAll(":", "").replace(/\s/g, "")} `,
    hasUnreadMessages: hasUnreadMessages,
    factorId: factorID,
  });

  return (
    <StyledContainer data-testid="risk-trigger-card">
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color={palette.neutral[100]}>{triggerTitle}</Text>
        <PermissionTooltip
          showToolTip={!canUpdateTrigger}
          message={EDIT_DENY_MESSAGE}
        >
          <Switch
            size="small"
            checked={isEnabled}
            onChange={handleOpenModal}
            name={`trigger-${factorID}`}
            data-testid={`trigger-toggle-${factorID}`}
            disabled={!canUpdateTrigger}
          />
        </PermissionTooltip>
      </Stack>

      <DescriptionText color={palette.neutral[80]}>
        {description}
      </DescriptionText>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <PointsTag>
            {riskPoints} {riskPoints > 1 ? "points" : "point"}
          </PointsTag>
          <EnabledBy
            isEnabled={isEnabled}
            firstName={firstName}
            lastName={lastName}
            imageURL={imageURL}
            reason={customReason}
          />
        </Stack>
        {isAcquirerPortal && (
          <TriggersConversationButton
            hasUnreadMessages={hasUnreadMessages}
            onClick={handleOpenRiskConversation}
          />
        )}
      </Stack>
    </StyledContainer>
  );
};

const StyledContainer = styled(Stack)(({ theme }) => ({
  minHeight: "54px",
  gap: "12px",
  borderRadius: theme.spacing(2),
  background: palette.neutral.white,
  padding: theme.spacing(2),
  boxShadow: theme.custom.shadows[1],
}));

const DescriptionText = styled(Text)({
  padding: "12px 16px",
  backgroundColor: palette.primary.background,
  borderRadius: "8px",
});

const PointsTag = styled(Box)(() => ({
  display: "inline-block",
  padding: "4px 6px",
  borderRadius: "100px",
  border: `1px solid ${palette.accent[3]}`,
  background: palette.info.light,
  fontSize: 12,
  fontWeight: 350,
  color: palette.givebox[600],
}));

export default TriggerCard;
