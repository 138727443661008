import { Grid } from "@mui/material";
import { KeyVal } from "@common/KeyVal";
import {
  PurchaseProductType,
  PurchasesListItem,
} from "@customTypes/customer.types";

type functionArguments = {
  purchasesList?: PurchasesListItem[];
  xs: number;
  color?: string;
};

const renderPurchasesStatistics = ({
  purchasesList,
  xs,
  color,
}: functionArguments): JSX.Element[] => {
  return ["fundraiser", "event", "invoice", "membership", "sweepstake"].map(
    (product) => {
      const currentPurchase = purchasesList?.filter(
        (purchase: PurchasesListItem) => {
          if (product === "fundraiser" && purchase.name === "generic")
            return true;
          return purchase.name === product;
        },
      );

      const value =
        currentPurchase && currentPurchase.length > 0
          ? (currentPurchase[0].total / 100).toFixed(2)
          : "0";

      const statName = getProductType(product as PurchaseProductType, true);

      return (
        <Grid item xs={xs} key={product}>
          <KeyVal
            keyName={statName}
            value={value}
            isAmount
            sx={{ color: color }}
          />
        </Grid>
      );
    },
  );
};

export const getProductType = (
  productType: PurchaseProductType,
  isPlural = false,
) => {
  if (productType === "standard") {
    return isPlural ? "Standard Forms" : "Standard Form";
  }
  if (productType === "fundraiser" || productType === "generic") {
    return isPlural ? "Donations" : "Donation";
  }

  const plural = isPlural ? "s" : "";
  return productType.charAt(0).toUpperCase() + productType.slice(1) + plural;
};

export default renderPurchasesStatistics;
