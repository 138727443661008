import React from "react";

type IconProps = {
  width?: number;
  height?: number;
  stroke?: string;
  isGradient?: boolean;
};

const SearchIcon = ({
  width = 24,
  height = 24,
  stroke = "#A8A8A8",
  isGradient = false,
}: IconProps) => {
  if (isGradient)
    return (
      <svg
        key={stroke}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.3874 15.3939L20 20M4.76773 13.6644C5.87756 15.7054 8.04271 17.0909 10.5319 17.0909C14.1521 17.0909 17.0868 14.1604 17.0868 10.5455C17.0868 6.9305 14.1521 4 10.5319 4C7.09756 4 4.28008 6.6375 4 9.99473"
          stroke="url(#searchIconGradient)"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="searchIconGradient"
            x1="3"
            y1="3"
            x2="21"
            y2="21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#72ECF0" />
            <stop offset="1" stopColor="#528DDF" />
          </linearGradient>
        </defs>
      </svg>
    );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3874 15.3939L20 20M4.76773 13.6644C5.87756 15.7054 8.04271 17.0909 10.5319 17.0909C14.1521 17.0909 17.0868 14.1604 17.0868 10.5455C17.0868 6.9305 14.1521 4 10.5319 4C7.09756 4 4.28008 6.6375 4 9.99473"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
