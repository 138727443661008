import { Button } from "@common/Button";
import { Box, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageEditorToolbar from "./ImageEditorToolbar";
import useImageCropper from "../hooks/useImageCropper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import MobileEditor from "./MobileEditor";

const MIN_ZOOM = 0;
const MAX_ZOOM = 100;
const STEP = 10;

type ImageSectionProps = {
  image?: string;
  handleResetImage: () => void;
  handleUpdateImage: (url: HTMLCanvasElement) => void;
  handleDelete: () => void;
  canDelete: boolean;
};

function ImageSection({
  image,
  handleResetImage,
  handleUpdateImage,
  handleDelete,
  canDelete,
}: ImageSectionProps) {
  const { isMobileView } = useCustomTheme();
  const {
    cropperRef,
    rotation,
    zoomValue,
    rotate,
    handleUpdateSlider,
    handleZoom,
    getCropData,
  } = useImageCropper(
    { minZoom: MIN_ZOOM, maxZoom: MAX_ZOOM, step: STEP },
    handleUpdateImage,
  );

  if (isMobileView) {
    return (
      <MobileEditor
        onCancel={handleResetImage}
        onSave={getCropData}
        onDelete={handleDelete}
        canDelete={canDelete}
      >
        <Cropper
          src={image || ""}
          style={{ height: 300 }}
          dragMode="move"
          toggleDragModeOnDblclick={false}
          ref={cropperRef}
          viewMode={2}
          guides={false}
          rotateTo={rotation}
          center={false}
          zoomOnWheel={false}
          autoCropArea={0.3}
          minCropBoxHeight={80}
          minCropBoxWidth={80}
          aspectRatio={1 / 1}
        />
      </MobileEditor>
    );
  }

  return (
    <Box paddingTop={"24px"} data-testid="edit-uploaded-image-section">
      <Cropper
        src={image || ""}
        style={{ height: 300 }}
        dragMode="move"
        toggleDragModeOnDblclick={false}
        ref={cropperRef}
        viewMode={2}
        guides={false}
        rotateTo={rotation}
        center={false}
        zoomOnWheel={false}
        autoCropArea={0.3}
        minCropBoxHeight={80}
        minCropBoxWidth={80}
        aspectRatio={1 / 1}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        my="24px"
      >
        <ImageEditorToolbar
          zoom={zoomValue}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          handleRotate={rotate}
          handleZoom={handleZoom}
          handleChangeSlider={handleUpdateSlider}
          handleDelete={handleDelete}
          canDelete={canDelete}
        />
      </Stack>
      <ActionButtonsContainer>
        <Button
          sx={{
            color: palette.filled.grey,
            fontSize: "18px",
          }}
          background="tertiary"
          onClick={handleResetImage}
        >
          Cancel
        </Button>
        <Button onClick={getCropData}>
          <Text fontSize={"18px"}>Save</Text>
        </Button>
      </ActionButtonsContainer>
    </Box>
  );
}

export default ImageSection;

const ActionButtonsContainer = styled(Stack)(() => ({
  alignSelf: "end",
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
  mt: "20px",
  justifyContent: "flex-end",
}));
