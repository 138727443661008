import React, { createContext, useContext, useState } from "react";

interface ResetActionContextProps {
  registerResetAction: (id: string, action: () => void) => void;
  getResetAction: (id: string) => (() => void) | null;
}

const ResetActionContext = createContext<ResetActionContextProps | undefined>(
  undefined,
);

export const ResetActionProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [resetActions, setResetActions] = useState<{
    [id: string]: () => void;
  }>({});

  const registerResetAction = (id: string, action: () => void) => {
    setResetActions((prevActions) => ({
      ...prevActions,
      [id]: action,
    }));
  };

  const getResetAction = (id: string) => resetActions[id] || null;

  return (
    <ResetActionContext.Provider
      value={{ registerResetAction, getResetAction }}
    >
      {children}
    </ResetActionContext.Provider>
  );
};

export const useResetAction = (): ResetActionContextProps => {
  const context = useContext(ResetActionContext);

  if (!context) {
    throw new Error("useResetAction must be used within a ResetActionProvider");
  }

  return context;
};
