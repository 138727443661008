import * as React from "react";
import { palette } from "@palette";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// form
import { FormProvider } from "react-hook-form";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
// components
import { Text, TruncateText } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
import { RHFInput, RHFTelInput } from "@common/Input";
import { BirthDatePicker } from "@common/DatePickers";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import Banner from "./Banner";
// assets
import { useViewCustomer } from "@hooks/merchant-api/customers";
import { ZipCodeInput } from "@common/AddressInputs";
import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import { useAppSelector } from "@redux/hooks";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

type ViewCustomerProps = {
  id: number;
  merchantId?: number;
  closeAction?: () => void;
};

const ViewCustomer = NiceModal.create(
  ({ id, closeAction, merchantId }: ViewCustomerProps) => {
    const modal = useModal();

    const isAllowedUpdate = useAccessControl({
      resource: composePermission(
        RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.CUSTOMER,
      ),
      operation: OPERATIONS.UPDATE,
    });

    const {
      handleChangeStatus,
      onSubmit,
      handleClose,
      methods,
      isDisabled,
      edit,
      setEdit,
      resetToDefault,
      data,
      customerDataIsLoading,
    } = useViewCustomer(id, merchantId);

    const {
      watch,
      formState: { isDirty },
    } = methods;
    const values = watch();

    const hasNotViewCustomerPermission = useAppSelector(
      (state) => state.app.permissions?.view_customer,
    );

    const isAddImageAllowed = useAccessControl({
      resource: RESOURCE_BASE.MEDIA_ITEM,
      operation: OPERATIONS.CREATE,
      withPortal: true,
    });

    const areActionsDisabled = isDisabled || !isDirty;
    const formGrid = React.useMemo(() => {
      const arr = [
        {
          sm: 6,
          component: (
            <RHFInput
              name="firstName"
              fullWidth
              placeholder="First Name"
              label="First Name"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFInput
              name="lastName"
              fullWidth
              placeholder="Last Name"
              label="Last Name"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFInput
              name="email"
              placeholder="example@gmail.com"
              fullWidth
              label="Email"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <BirthDatePicker
              name="dateOfBirth"
              placeholder="MM/DD/YYYY"
              fullWidth
              label="Date of Birth"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFTelInput
              name="phone"
              fullWidth
              label="Phone Number"
              disabled={!edit}
            />
          ),
        },
      ];
      return arr;
    }, [edit]);
    const additionalDetailsGrid = React.useMemo(() => {
      const arr = [
        {
          sm: 6,
          component: (
            <RHFInput
              name="occupation"
              fullWidth
              placeholder="Enter Occupation"
              type="text"
              label="Occupation"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFInput
              name="employer"
              fullWidth
              placeholder="Enter Employer"
              label="Employer"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFInput
              name="address.city"
              fullWidth
              placeholder="Albany"
              type="text"
              label="City"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFInput
              name="address.state"
              fullWidth
              placeholder="New York"
              type="text"
              label="State"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <ZipCodeInput
              name="address.zip"
              placeholder="Enter ZIP Code"
              label="ZIP"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 6,
          component: (
            <RHFInput
              name="address.line1"
              fullWidth
              placeholder="Full Address"
              type="text"
              label="Address"
              disabled={!edit}
            />
          ),
        },
        {
          sm: 12,
          component: (
            <RHFInput
              name="notes"
              fullWidth
              placeholder="Useful notes..."
              type="text"
              label="Notes"
              disabled={!edit}
              multiline
              rows={5}
              inputProps={{
                maxLength: "200",
              }}
              helperText={`${200 - values.notes?.length} Characters remaining`}
            />
          ),
        },
      ];
      return arr;
    }, [edit]);
    return (
      <Modal
        {...muiDialogV5(modal)}
        data-testid="view-customer-modal"
        sx={{
          "& .MuiPaper-root": {
            width: "720px",
          },
          "& .MuiDialogTitle-root": {
            background: palette.liftedWhite.main,
            padding: "12px 16px 0",
            "& > button": {
              background: palette.liftedWhite.main,
            },
          },
          "@media (max-width: 600px)": {
            "& .MuiPaper-root": {
              maxWidth: "100%",
            },
            "& .MuiDialogActions-root": {
              flexDirection: "column",
            },
          },
        }}
        titleComponent={
          <Box sx={{ maxWidth: "100%" }}>
            <TruncateText
              lineClamp={1}
              variant="h3"
              fontSize={24}
              lineHeight="38px"
              color={palette.gray[300]}
              sx={{ letterSpacing: "-0.01em" }}
            >
              {values.email}
            </TruncateText>
            <TruncateText
              lineClamp={1}
              variant="h6"
              lineHeight="22px"
              color={palette.black[100]}
              sx={{ letterSpacing: "-0.01em" }}
            >
              {values.firstName} {values.lastName}
            </TruncateText>
          </Box>
        }
        onClose={handleClose}
        actions={
          edit ? (
            <>
              <Button
                background="secondary"
                size="medium"
                onClick={resetToDefault}
                disabled={areActionsDisabled}
              >
                Discard Changes
              </Button>
              <Button
                background="primary"
                size="medium"
                type="submit"
                disabled={areActionsDisabled}
                form="view-customer"
              >
                Save
              </Button>
            </>
          ) : hasNotViewCustomerPermission ? null : (
            <Button
              background="primary"
              onClick={() => setEdit(true)}
              size="medium"
              disabled={!isAllowedUpdate}
              tooltipProps={{
                message: EDIT_DENY_MESSAGE,
                show: !isAllowedUpdate,
              }}
            >
              Edit Customer
            </Button>
          )
        }
      >
        <WithMissingPermissionModule
          isLoading={customerDataIsLoading}
          notAuthorized={hasNotViewCustomerPermission}
          message="Customer View hidden"
          wrapperProps={{
            marginTop: 0,
            paddingBottom: "44px",
          }}
        >
          <Banner data={data} />
          <FormProvider {...methods}>
            <Box
              mb={1}
              id="view-customer"
              component="form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Stack mt={3} direction={"column"}>
                <FadeUpWrapper
                  delay={250}
                  containerProps={{ sx: { width: "max-content" } }}
                >
                  <WithTooltipWrapper
                    hasTooltip={!isAddImageAllowed}
                    tooltipProps={{
                      show: !isAddImageAllowed,
                      message: UPLOAD_DENY_MESSAGE,
                    }}
                  >
                    <RHFUploadAvatar
                      name="avatarURL"
                      disabled={!edit || !isAddImageAllowed}
                      onChangeStatus={handleChangeStatus}
                      width="96px"
                      height="96px"
                      marginBottom="24px"
                    />
                  </WithTooltipWrapper>
                </FadeUpWrapper>

                <Grid container spacing={2}>
                  {formGrid.map((c, idx) => (
                    <Grid key={idx} item xs={12} sm={c.sm}>
                      <FadeUpWrapper delay={250 + idx * 50}>
                        {c.component}
                      </FadeUpWrapper>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
              <Box mt={3}>
                <FadeUpWrapper delay={400}>
                  <Text
                    variant="h4"
                    fontSize="32px"
                    style={{ fontWeight: 350 }}
                    lineHeight="38px"
                    color={palette.gray[300]}
                  >
                    Additional Details
                  </Text>
                </FadeUpWrapper>

                <Grid
                  mt={1}
                  container
                  spacing={2}
                  direction="row"
                  alignItems="flex-start"
                >
                  {additionalDetailsGrid.map((c, idx) => (
                    <Grid key={idx} item xs={12} sm={c.sm}>
                      <FadeUpWrapper delay={300 + idx * 50}>
                        {c.component}
                      </FadeUpWrapper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </FormProvider>
        </WithMissingPermissionModule>
      </Modal>
    );
  },
);

export default ViewCustomer;
