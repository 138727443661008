import React from "react";
import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
// utils
import { parseAmount } from "utils";
// assets
import { InfoIcon } from "@assets/icons";
import { BankFeesInfo } from "./BankFeesInfo";
import { useModal } from "@ebay/nice-modal-react";
import { displayAmount } from "./MoneyTransferDetails";
import { useFormData } from "../TransferMoneyFormProvider";
import TransferModalHeader from "../TransferModalHeader";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type TransferSucessProps = {
  PreviousAmount: number;
};

const paper = {
  padding: "16px 0",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",

  "& sup": {
    fontSize: 9,
    lineHeight: "9px",
  },
};

const MoneyTransferSuccess: React.FC<TransferSucessProps> = ({
  PreviousAmount,
}) => {
  const { formData } = useFormData();
  const {
    account: { name, bank },
    amount,
    fees,
  } = formData.transferMoney;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const modal = useModal();
  const [infoOpen, setInfoOpen] = React.useState(false);

  const value = parseAmount(amount / 100);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    modal.hide();
  };

  const detailsList = [
    {
      item: (
        <Stack
          mb={1}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
          justifyContent={isDesktop ? "space-between" : "flex-start"}
        >
          <Text
            variant="headline"
            fontWeight="light"
            color={palette.black[100]}
          >
            Previous Balance (USD)
          </Text>
          {displayAmount(parseAmount(PreviousAmount))}
        </Stack>
      ),
    },
    {
      item: (
        <Stack
          mb={1}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
          justifyContent={isDesktop ? "space-between" : "flex-start"}
        >
          <Text
            variant="headline"
            fontWeight="light"
            color={palette.black[100]}
          >
            Amount to be sent (USD)
          </Text>
          {displayAmount(parseAmount(amount / 100))}
        </Stack>
      ),
    },
    {
      item: (
        <Stack
          mb={1}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
          justifyContent={isDesktop ? "space-between" : "flex-start"}
        >
          <Stack gap={1} direction="row" alignItems="center">
            <Text
              variant="headline"
              fontWeight="light"
              color={palette.black[100]}
            >
              Bank Fee (USD)
            </Text>
            <Stack
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => setInfoOpen(true)}
            >
              <InfoIcon width={18} height={18} path_fill={palette.gray[100]} />
            </Stack>
          </Stack>
          {displayAmount(parseAmount(fees / 100))}
        </Stack>
      ),
    },
    {
      item: (
        <Stack
          mb={2}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
          justifyContent={isDesktop ? "space-between" : "flex-start"}
        >
          <Text
            variant="headline"
            color={palette.black[100]}
            fontWeight="light"
          >
            Recipient
          </Text>
          <Stack gap="4px" direction="row" alignItems="center">
            <Text
              variant="headline"
              color={palette.neutral[80]}
              fontWeight="regular"
              fontSize={18}
            >
              &lsquo;{name}&rsquo;
            </Text>
            {bank && (
              <Text
                variant="caption"
                color={palette.neutral[80]}
                fontWeight="light"
              >
                {bank}
              </Text>
            )}
          </Stack>
        </Stack>
      ),
    },
    {
      item: (
        <>
          <Divider />

          <Stack
            mt={2}
            direction={isDesktop ? "row" : "column"}
            alignItems={isDesktop ? "center" : "flex-start"}
            justifyContent={isDesktop ? "space-between" : "flex-start"}
          >
            <Text
              variant="headline"
              fontWeight="light"
              color={palette.black[100]}
            >
              New balance (USD)
            </Text>
            {displayAmount(parseAmount(PreviousAmount - (amount + fees) / 100))}
          </Stack>
        </>
      ),
    },
  ];

  return (
    <Box component="form" id="transfer-money-form" onSubmit={onSubmit}>
      <TransferModalHeader
        title="Send Money"
        mb={4}
        onClose={() => modal.hide()}
      />
      <Stack mb={0} gap={2} direction="column" alignItems="flex-start">
        <FadeUpWrapper delay={100}>
          <Text
            fontSize={24}
            color={palette.filled.green}
            variant="h4"
            fontWeight="regular"
          >
            Initiated
          </Text>
        </FadeUpWrapper>
        <FadeUpWrapper delay={100}>
          <Text
            variant="h6"
            color={palette.black[100]}
            fontWeight="light"
            fontSize={16}
          >
            <Box
              component="span"
              color={palette.black.main}
              fontSize="18px"
              sx={{
                "& > sup": {
                  fontSize: "10px",
                  verticalAlign: "4px",
                },
              }}
            >
              {value} USD
            </Box>{" "}
            will be sent to{" "}
            <Box component="span" color={palette.black.main} fontSize="18px">
              &lsquo;{name}&rsquo;
            </Box>
          </Text>
        </FadeUpWrapper>
      </Stack>
      {/* -------------- Details -------------- */}
      <Box sx={paper}>
        {detailsList.map(({ item }, index) => (
          <FadeUpWrapper key={index} delay={100 + 100 * (index + 1)}>
            {item}
          </FadeUpWrapper>
        ))}
      </Box>
      <BankFeesInfo
        open={infoOpen}
        setOpen={setInfoOpen}
        isDesktop={isDesktop}
      />
    </Box>
  );
};

export default MoneyTransferSuccess;
