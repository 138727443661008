import { LabelValueArray, THistoryCheck } from "../types";

export function createArrayFromObject(
  obj: Record<string, string[] | null>,
): LabelValueArray[] {
  const result: LabelValueArray[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const values = obj[key];

      if (values !== null) {
        values.forEach((value) => {
          result.push({ label: key, value: value });
        });
      } else {
        result.push({ label: key, value: "None" });
      }
    }
  }

  return result;
}

export const parser = (check: any): THistoryCheck => {
  return {
    date: check.createdAt,
    status: check.pepStatus,
    checkID: check.id,
    manualPepStatus: check.manualPEPStatus,
    manualStatusAt: check.manualStatusAt,
  };
};

export const getListDummyData = (date?: number): THistoryCheck[] => {
  const isDate = date && !isNaN(date);

  return [
    {
      date: isDate ? date : Math.floor(new Date().getTime() / 1000),
      status: "pending",
      checkID: 1,
      manualPepStatus: "" as any,
    },
  ];
};
export const dummyObjects = {
  id: 649,
  principalId: 1192,
  pepStatus: "possible_match",
  displayName: "Possible match",
  manualPEPStatus: "",
  manualDisplayName: "",
  createdAt: 1709133350,
  pepResult: [
    {
      id: "evpo-22c89d85-48ec-4fe3-a66c-84d3c81ed7aa",
      match: false,
      score: 0.53261197,
      schema: "Person",
      target: true,
      datasets: ["everypolitician"],
      features: {
        name_literal_match: 0,
        person_name_jaro_winkler: 0.6657649,
        person_name_phonetic_match: 0.5,
      },
      last_seen: "2019-05-21T00:00:00",
      referents: [],
      first_seen: "2019-05-21T00:00:00",
      properties: {
        name: null,
        alias: null,
        notes: null,
        gender: null,
        status: null,
        topics: null,
        address: null,
        country: null,
        innCode: null,
        website: null,
        keywords: null,
        lastName: null,
        position: null,
        religion: null,
        birthDate: null,
        createdAt: null,
        education: null,
        ethnicity: null,
        firstName: null,
        sourceUrl: null,
        weakAlias: null,
        birthPlace: null,
        fatherName: null,
        modifiedAt: null,
        secondName: null,
        wikidataId: null,
        nationality: null,
        birthCountry: null,
        addressEntity: null,
      },
      last_change: "2019-05-21T00:00:00",
    },
  ],
  pepTotal: {
    value: 149,
    relation: "eq",
  },
};
