import { TColumnNames, TColumnTranslations } from "../types";

type TSectionNames =
  | "merchantInfo"
  | "transactionInfo"
  | "visaInfo"
  | "mastercardInfo"
  | "discoverInfo"
  | "amexInfo"
  | "otherCreditCard";

export const SETTLEMENT_COLUMNS: TColumnNames<TSectionNames> = {
  titles: {
    merchantInfo: "Merchant Info",
    transactionInfo: "Transaction Info",
    visaInfo: "Visa Info",
    mastercardInfo: "MasterCard Info",
    discoverInfo: "Discover Info",
    amexInfo: "AMEX Info",
    otherCreditCard: "Other Credit Card ",
  },
  visible: [],
  merchantInfo: ["Merchant ID", "Merchant Name", "Merchant Category Code"],
  transactionInfo: [
    "Settlement Date",
    "Settled Amount",
    "Total Purchases Cleared",
    "Total Fees Collected",
    "Fees Paid",
    "Total Purchase Amount",
    "# of Purchases",
    "Total Refunds",
    "# of Refunds",
    "Total Chargebacks",
    "# of Chargebacks",
  ],
  visaInfo: [
    "Visa # of Purchase Cleared",
    "Visa Purchases Cleared Amount",
    "Visa # of Refunds Cleared",
    "Visa Refund Cleared Amount",
    "Visa # of Chargebacks",
    "Visa Chargeback Amount",
    "Visa Fees Paid Amount",
  ],
  mastercardInfo: [
    "MasterCard # of Purchase Cleared",
    "MasterCard Purchases Cleared Amount",
    "MasterCard # of Refunds Cleared",
    "MasterCard Refund Cleared Amount",
    "MasterCard # of Chargebacks",
    "MasterCard Chargeback Amount",
    "MasterCard Fees Paid Amount",
  ],
  discoverInfo: [
    "Discover # of Purchase Cleared",
    "Discover Purchases Cleared Amount",
    "Discover # of Refunds Cleared",
    "Discover Refund Cleared Amount",
    "Discover # of Chargebacks",
    "Discover Chargeback Amount",
    "Discover Fees Paid Amount",
  ],
  amexInfo: [
    "AMEX # of Purchase Cleared",
    "AMEX Purchases Cleared Amount",
    "AMEX # of Refunds Cleared",
    "AMEX Refund Cleared Amount",
    "AMEX # of Chargebacks",
    "AMEX Chargeback Amount",
    "AMEX Fees Paid Amount",
  ],
  otherCreditCard: [
    "Other  # of Purchase Cleared",
    "Other Purchases Cleared Amount",
    "Other # of Refunds Cleared",
    "Other Refund Cleared Amount",
    "Other # of Chargebacks",
    "Other Chargeback Amount",
    "Other Fees Paid Amount",
    "Currency",
  ],
};

export const SETTLEMENT_TRANSLATIONS: TColumnTranslations = {};
