import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@common/DatePickers";
import { TextField } from "@mui/material";

interface Props {
  name: string;
}

function CustomDatePicker({ name }: Props) {
  const { watch, control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        return (
          <DatePicker
            {...rest}
            minDate={new Date().setDate(new Date().getDate() + 1)}
            inputRef={ref}
            inputFormat="MM/dd/yyyy"
            adornmentPosition="end"
            renderInput={({ inputProps, ...params }) => {
              return (
                <TextField
                  inputRef={ref}
                  {...params}
                  error={!!error}
                  label="End date"
                  helperText={error?.message}
                  inputProps={{
                    ...inputProps,
                    placeholder: "Choose date",
                  }}
                  style={
                    {
                      //  minWidth: "100%",
                    }
                  }
                />
              );
            }}
          />
        );
      }}
    />
  );
}

export default CustomDatePicker;
