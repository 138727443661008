import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Button } from "@common/Button";
import { BankIcon, CogSettingsIcon } from "@assets/rebrandIcons";
import {
  IMerchantBankAccount,
  TMerchantBankAccountSettings,
} from "../../data.types";
import MerchantBankAccountItem from "./MerchantBankAccountItem";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { useMerchantBankAccounts } from "../../hooks/useMerchantBankAccounts";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { styled } from "@mui/material";
import { EditButton } from "../atoms";
import { ConversationsIconNotFilled } from "@assets/icons";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { CREATE_DENY_MESSAGE } from "@constants/permissions";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";

type Props = {
  data: IMerchantBankAccount[];
  merchantId?: number;
  parentAccID?: number;
  isEnterprise?: boolean;
  isAcquirer?: boolean;
  bankAccountSettings?: TMerchantBankAccountSettings;
  onCloseModal?: (data?: IMerchantBankAccount, id?: number) => void;
};

const MerchantBankAccounts = ({
  data,
  merchantId,
  parentAccID,
  bankAccountSettings,
  onCloseModal,
  isEnterprise,
  isAcquirer,
}: Props) => {
  const {
    anchorEl,
    onOpenMenu,
    onCloseMenu,
    actions,
    manageBankAccountsHandler,
    handleAddBankAccount,
    isAddBankAccountAllowed,
  } = useMerchantBankAccounts(
    data,
    merchantId,
    parentAccID,
    bankAccountSettings,
    onCloseModal,
    isEnterprise,
  );
  const { isMobileView } = useCustomTheme();
  const { openConversationHandler } = useConversationsModal();
  const {
    bank_account_linking,
    manage_bank_account,
    money_transfers,
    merchant_underwriting,
    modify_merchant,
  } = useEnterprisePermissions();

  const onClick = () =>
    openConversationHandler({
      id: 0,
      name: "Bank Accounts",
      paths: [],
    });

  const hasBankPermissions =
    bank_account_linking || manage_bank_account || money_transfers;

  return (
    <Stack
      spacing={2}
      width="100%"
      sx={{
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontSize={18} color={palette.neutral[70]}>
          Bank Accounts
        </Text>
        <Stack
          direction="row"
          spacing={isMobileView ? 2 : 1}
          alignItems="center"
        >
          {merchant_underwriting && isAcquirer && (
            <EditButton onClick={onClick}>
              <ConversationsIconNotFilled section="bank-accounts" />
            </EditButton>
          )}
          {isMobileView ? (
            <Box onClick={handleAddBankAccount} mt={1}>
              <BankIcon height={20} width={20} fill={palette.black[100]} />
            </Box>
          ) : (
            <>
              {modify_merchant && (
                <StyledButton
                  size="small"
                  background="tertiary"
                  startIcon={
                    <BankIcon
                      height={20}
                      width={20}
                      fill={palette.black[100]}
                    />
                  }
                  onClick={handleAddBankAccount}
                  disabled={!isAddBankAccountAllowed}
                  tooltipProps={{
                    show: !isAddBankAccountAllowed,
                    message: CREATE_DENY_MESSAGE,
                  }}
                >
                  <Text color={palette.black[100]} lineHeight="20px">
                    Add bank account
                  </Text>
                </StyledButton>
              )}
            </>
          )}
          {merchantId && !isEnterprise && hasBankPermissions && (
            <Box
              display="inline-flex"
              sx={{ cursor: "pointer" }}
              onClick={manageBankAccountsHandler}
              data-testid="link-bank-account-icon"
            >
              <CogSettingsIcon stroke={palette.black[100]} />
            </Box>
          )}
        </Stack>
      </Box>
      {data && data.length !== 0 ? (
        <Stack spacing={1}>
          {data?.map((bankAccount) => {
            return (
              <MerchantBankAccountItem
                key={bankAccount.id}
                onOpenMenu={onOpenMenu}
                isEnterprise={isEnterprise}
                modify_merchant={modify_merchant}
                {...bankAccount}
              />
            );
          })}
        </Stack>
      ) : null}

      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
        sx={{
          "& .MuiPaper-root": {
            width: 236,
          },
        }}
        {...(isMobileView && mobileStyle)}
      />
    </Stack>
  );
};

const StyledButton = styled(Button)(() => ({
  borderRadius: "32px",
  boxShadow: "none",
  display: "inline-flex",
  alignItems: "center",
}));

type THorizontal = number | "left" | "right" | "center";
type TVertical = number | "bottom" | "top" | "center";

const mobileStyle = {
  sx: {
    "& .MuiPaper-root": {
      borderRadius: "16px",
      background: `${palette.background.dimmedWhite} !important`,
      boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
      width: "350px",
    },
  },
  anchorOrigin: {
    vertical: "top" as TVertical,
    horizontal: "center" as THorizontal,
  },
  transformOrigin: {
    vertical: "top" as TVertical,
    horizontal: "center" as THorizontal,
  },
  MenuListProps: {
    sx: {
      "& li": {
        height: "48px",
        textAlign: "center",
        position: "relative",
      },
      "& li:not(:last-child)": {
        borderBottom: `1px solid ${palette.neutral[10]}`,
      },
    },
  },
  PaperProps: {
    style: {
      top: "50px",
    },
  },
};

export default MerchantBankAccounts;
