import NiceModal from "@ebay/nice-modal-react";
import { EffectCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type TPageRedirectOptions = {
  subpath?: string;
  sectionElId?: string;
  scrollIntoView?: boolean;
};

const useRedirect = () => {
  const navigate = useNavigate();

  const redirectToPage = (path: string, options?: TPageRedirectOptions) => {
    const url = options?.subpath ? `${path}/?path=${options.subpath}` : path;
    navigate(url);

    if (options?.sectionElId) {
      const section = document.getElementById(options?.sectionElId);
      if (options?.scrollIntoView) section?.scrollIntoView();
    }
  };

  const redirectToModal = (modalName: string, modalProps?: any) =>
    NiceModal.show(modalName, modalProps);

  return {
    redirectToPage,
    redirectToModal,
  };
};

export default useRedirect;

type TEffectOptions = {
  delay?: number;
};

export const useTriggerPathEffect = (
  effect: EffectCallback,
  options?: TEffectOptions,
) => {
  const [params] = useSearchParams();
  const path = params.get("path");

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const triggerEffect = () => {
      if (options?.delay) {
        timeout = setTimeout(() => {
          effect();
        }, options.delay);
      } else {
        effect();
      }
    };

    if (path) triggerEffect();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [path]);
};
