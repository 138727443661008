import { Controller, useFormContext } from "react-hook-form";
import DatePicker, { DatePickerProps } from "./DatePicker";
import { InputProps } from "@common/Input/Input";
import { subYears } from "date-fns";
import {
  MAXIMUM_REQUIRED_AGE,
  MINIMUM_REQUIRED_AGE,
} from "@constants/constants";
import { Input } from "@common/Input";
import { memo } from "react";

type BirthDatePickerProps = InputProps & {
  name: string;
  placeholder?: string;
  label?: string;
  datePickerProps?: DatePickerProps;
  maxDate?: any;
  minDate?: any;
  openPickerOnFocus?: boolean;
  adornmentPosition?: "start" | "end";
};

const BirthDatePicker = ({
  name,
  label,
  placeholder,
  datePickerProps,

  ...props
}: BirthDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, value, onChange, onBlur, ...rest },
        fieldState: { error },
      }) => (
        <DatePicker
          {...rest}
          inputFormat="MM/dd/yyyy"
          inputRef={ref}
          value={value}
          onChange={onChange}
          maxDate={subYears(new Date(), MINIMUM_REQUIRED_AGE)}
          minDate={subYears(new Date(), MAXIMUM_REQUIRED_AGE)}
          openTo="year"
          {...datePickerProps}
          {...props}
          renderInput={({ inputProps, ...params }) => (
            <Input
              name="date"
              inputRef={ref}
              {...params}
              fullWidth
              autoComplete="off"
              error={!!error}
              onBlur={props.onBlur ?? onBlur}
              helperText={error?.message}
              label={label}
              value={value}
              inputProps={{
                ...inputProps,
                placeholder,
              }}
              sx={{
                "@media (min-width: 600px)": {
                  "& .MuiInputLabel-root": {
                    left: props.adornmentPosition === "end" ? "0px" : "28px",
                  },
                  "& .MuiIconButton-root": {
                    marginRight: "2px !important",
                  },
                },
                "@media (max-width: 600px)": {
                  "& .MuiInputAdornment-positionStart": {
                    display: "none",
                  },
                },
              }}
            />
          )}
        />
      )}
    />
  );
};

export default memo(BirthDatePicker);
