type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

export const CodeIcon = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id=" Code">
        <path
          id="Vector"
          d="M16.0001 7L19.5004 11.3753C19.7926 11.7405 19.7926 12.2595 19.5004 12.6247L16.0001 17M8.00014 7L4.49989 11.3753C4.20772 11.7405 4.20772 12.2595 4.49989 12.6247L8.00014 17"
          stroke={stroke}
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
