import { MERCHANT_PATHS } from "@routes/paths";
import { useNavigate } from "react-router-dom";

const useCloseBuilder = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(`/merchant/${MERCHANT_PATHS.PAYMENT_FORMS}`, { replace: true });
    }
  };

  return {
    handleClose,
  };
};

export default useCloseBuilder;
