type IconProps = {
  width?: number,
  height?: number,
  stroke?: string,
}

const TrophyLightIcon = ({
  width = 32,
  height = 32,
  stroke = "#734089",
}: IconProps) => {
  return (
    <svg 
      width={width}
      height={height}
      viewBox="0 0 32 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M22 27.3333H10" 
        stroke={stroke} 
        strokeWidth="1.5" 
        strokeLinecap="round"
      />
      <path 
        d="M16.75 24.6667C16.75 25.0809 16.4142 25.4167 16 25.4167C15.5858 25.4167 15.25 25.0809 15.25 24.6667H16.75ZM15.25 24.6667V21.3334H16.75V24.6667H15.25Z" 
        fill={stroke} 
      />
      <path 
        d="M14 12.6667H18" 
        stroke={stroke}  
        strokeWidth="1.5" 
        strokeLinecap="round"
      />
      <path 
        d="M7.33268 19.3334C7.33268 19.3334 4.66602 17.3334 4.66602 14C4.66602 12.9795 4.66602 12.084 4.66602 11.3326C4.66602 10.7131 4.66602 10.4034 4.73416 10.149C4.9191 9.45886 5.45819 8.91977 6.14838 8.73484C6.40271 8.66669 6.7127 8.66669 7.33268 8.66669V8.66669C7.95266 8.66669 8.26265 8.66669 8.51699 8.73484C9.20717 8.91977 9.74627 9.45886 9.9312 10.149C9.99935 10.4034 9.99935 10.7134 9.99935 11.3334V12.6667" 
        stroke={stroke}
        strokeWidth="1.5" 
        strokeLinecap="round"
      />
      <path 
        d="M24.6673 19.3334C24.6673 19.3334 27.334 17.3334 27.334 14C27.334 12.9795 27.334 12.084 27.334 11.3326C27.334 10.7131 27.334 10.4034 27.2658 10.149C27.0809 9.45886 26.5418 8.91977 25.8516 8.73484C25.5973 8.66669 25.2873 8.66669 24.6673 8.66669V8.66669C24.0473 8.66669 23.7373 8.66669 23.483 8.73484C22.7928 8.91977 22.2537 9.45886 22.0688 10.149C22.0007 10.4034 22.0007 10.7134 22.0007 11.3334V12.6667" 
        stroke={stroke}
        strokeWidth="1.5" 
        strokeLinecap="round"
      />
      <path 
        d="M22 15.8592V9.33331C22 8.22874 21.1046 7.33331 20 7.33331H12C10.8954 7.33331 10 8.22875 10 9.33331V15.8592C10 17.1967 10.6684 18.4456 11.7812 19.1874L15.4453 21.6302C15.7812 21.8541 16.2188 21.8541 16.5547 21.6302L20.2188 19.1874C21.3316 18.4456 22 17.1967 22 15.8592Z" 
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default TrophyLightIcon;
