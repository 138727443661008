import { CreateMemberTypes, createTeamMember } from "@services/api/customer";
import { MemberRoleName } from "@customTypes/team.member";
import { useMutation, useQueryClient } from "react-query";
import { showMessage, clearToasts } from "@common/Toast/ShowToast";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useGetCurrentMerchantId } from "@hooks/common";

type Props = {
  handleClose?: () => void;
};

export const useInviteTeamMember = ({ handleClose }: Props) => {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();

  const { mutate, isLoading } = useMutation((data: CreateMemberTypes) =>
    createTeamMember(data),
  );
  const { isMobileView } = useCustomTheme();

  const handleInvite =
    (value: MemberRoleName) => (data: any, cb?: () => void) => {
      const emailsArr: string[] = data?.emailList;
      mutate(
        {
          email: emailsArr[0],
          memberRole: value as any,
          merchantId: merchantId,
        },
        {
          onSuccess: () => {
            showMessage(
              "Success",
              `An invitation has been sent to the team ${
                emailsArr?.length > 1 ? "members" : "member"
              }`,
            );
            queryClient.refetchQueries("get-all-team-members");
          },
          onError: (err: any) => {
            const inputError = err?.response?.data?.input;
            const message =
              inputError?.[0]?.message || err?.response?.data?.message;

            showMessage(
              "Error",
              message || "Error occurred while trying to invite users",
              !isMobileView,
              "Error Inviting Team Member",
            );
          },

          onSettled: () => {
            if (cb) cb();
            if (handleClose) handleClose();
            clearToasts();
          },
        },
      );
    };

  return { handleInvite, isLoading };
};
