import { useAppSelector } from "@redux/hooks";
import { MasqueradeModeType } from "@redux/slices/app";
import { RootState } from "@redux/store";

const useMasqueradeReducer = () => {
  const masqueradeMode: MasqueradeModeType = useAppSelector(
    (state: RootState) => state.app.masqueradeMode,
  );
  return {
    ...masqueradeMode,
    isMasqueradeMode: !!masqueradeMode.name,
  };
};

export default useMasqueradeReducer;
