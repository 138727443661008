import { Bank, Certificate, SuitcaseSimple, User } from "@phosphor-icons/react";
import { Box, styled } from "@mui/material";
import { BusinessProfileStatusType } from "@common/Tag/BusinessProfileTag";
import { palette } from "@palette";
import { Grid } from "@mui/material";
import { Text } from "@common/Text";

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.white,
  ...theme.typography.body2,
  padding: "12px 8px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  height: 50,
  gap: "8px",
}));

export const UnderwritingOverview = ({
  isApprovedOwner,
  isApprovedBankAccount,
  isApprovedBusinessProfile,
  isAgreementSigned,
}: {
  isApprovedOwner: boolean;
  isApprovedBankAccount: boolean;
  isApprovedBusinessProfile: boolean;
  isAgreementSigned?: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Item>
          <User size={20} weight="duotone" />
          <Text data-testid="account-holder-title">Account holder</Text>
          <StatusChip status={isApprovedOwner} />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <SuitcaseSimple size={20} weight="duotone" />
          <Text data-testid="business-profile-title">Business Profile</Text>
          <StatusChip status={isApprovedBusinessProfile} />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Bank size={20} weight="duotone" />
          <Text data-testid="bank-account-title">Bank Account</Text>
          <StatusChip status={isApprovedBankAccount} />
        </Item>
      </Grid>
      <Grid item xs={6}>
        <Item>
          <Certificate size={20} weight="duotone" />
          <Text data-testid="agreement-title">Agreement</Text>
          <StatusChip
            status={isAgreementSigned}
            approvedLabel="Signed"
            notApprovedLabel="Not signed"
          />
        </Item>
      </Grid>
    </Grid>
  );
};

function StatusChip({
  status,
  approvedLabel = "Approved",
  notApprovedLabel = "Not approved",
}: any) {
  return (
    <Box marginLeft="auto">
      <Text
        fontSize="14px"
        fontWeight="book"
        bgcolor={status ? palette.tag.success.bg : palette.neutral[10]}
        color={status ? palette.success.main : palette.neutral[80]}
        borderRadius="16px"
        p="2px 8px"
      >
        {status ? approvedLabel : notApprovedLabel}
      </Text>
    </Box>
  );
}
