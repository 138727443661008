import * as React from "react";
import { palette } from "@palette";
import { useFormContext, Controller } from "react-hook-form";
import { TextFieldProps, TextField, Box, InputAdornment } from "@mui/material";
import { Text } from "@common/Text";
import { VisaLogo } from "@assets/logos";
import { Tooltip, TooltipProps } from "@common/Tooltip/Tooltip";
import { capitalizeEachWord } from "@utils/index";

export type InputProps = TextFieldProps & {
  label?: string | React.ReactNode;
  size?: "small" | "medium";
  error?: boolean;
  inputPrefix?: string | JSX.Element;
  inputSuffix?: string | React.ReactNode;
  paymentTag?: "visa";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  currency?: "usd";
  tooltipProps?: TooltipProps;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  focusViewColor?: string;
};

export const Input: React.FC<InputProps> = ({
  label,
  size = "medium",
  error,
  inputPrefix,
  inputSuffix,
  paymentTag,
  startIcon,
  endIcon,
  currency,
  tooltipProps,
  fullWidth,
  onKeyDown,
  focusViewColor,
  ...rest
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<string>("");
  const [isAutofill, setIsAutofill] = React.useState<boolean>(false);
  const elementRef = React.useRef<HTMLInputElement>(null);

  const shrinkLabel =
    isFocused || Boolean(currentValue) || Boolean(rest.value) || isAutofill;

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    setIsFocused(true);
    if (rest.onFocus) rest.onFocus(event);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    setIsFocused(false);
    if (rest.onBlur) rest.onBlur(event);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width={fullWidth ? "100%" : "auto"}
      gap="4px"
    >
      <TextField
        label={label}
        error={error}
        size={size}
        value={currentValue}
        onAnimationStartCapture={(e) => {
          if (e.animationName === "onAutoFillStart") {
            setIsAutofill(true);
          }
        }}
        onAnimationEndCapture={() => {
          setIsAutofill(false);
        }}
        onChange={(e) => setCurrentValue(e.target.value)}
        InputProps={{
          ...((startIcon || inputPrefix) && {
            startAdornment: (
              <InputAdornment position="start">
                {startIcon}
                {shrinkLabel && inputPrefix && (
                  <Text
                    color={palette.neutral[400]}
                    sx={{
                      mr: "0 !important",
                      mt: "16px",
                    }}
                  >
                    {inputPrefix}
                  </Text>
                )}
              </InputAdornment>
            ),
          }),
          ...((paymentTag || currency || endIcon || inputSuffix) && {
            endAdornment: (
              <InputAdornment position="end">
                {shrinkLabel && inputSuffix && (
                  <Text
                    color={palette.neutral[400]}
                    sx={{
                      mr: "2px !important",
                      mt: "16px",
                    }}
                  >
                    {inputSuffix}
                  </Text>
                )}
                {paymentTag === "visa" && <VisaLogo />}
                <Text
                  fontWeight="medium"
                  fontSize="14px"
                  lineHeight="17px"
                  color={palette.gray.main}
                  sx={{ textTransform: "uppercase" }}
                  className="MuiInputBase-currencyIcon"
                >
                  {currency}
                </Text>
                {typeof endIcon === "string" ? (
                  <Text
                    fontWeight="medium"
                    fontSize="14px"
                    lineHeight="17px"
                    color={isFocused ? palette.black[200] : palette.gray.main}
                  >
                    {endIcon}
                  </Text>
                ) : (
                  endIcon
                )}
              </InputAdornment>
            ),
          }),
          ...rest.InputProps,
        }}
        inputProps={{
          "data-testid": `custom-${label}-input`,
        }}
        {...rest}
        helperText={
          rest?.helperText == null ? null : rest?.helperText?.toString()
        }
        inputRef={rest.inputRef ? rest.inputRef : elementRef}
        fullWidth={fullWidth}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (onKeyDown) onKeyDown(e);
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        InputLabelProps={{
          variant: "filled",
          shrink: shrinkLabel,
          sx: {
            ...(startIcon && {
              left: "30px",
            }),
          },
        }}
        sx={{
          ...(focusViewColor && {
            "& .MuiInputBase-root": {
              border: `2px solid ${focusViewColor} !important`,
              transition: "border 250ms ease",
            },
          }),
          ...(!label && {
            "& .MuiInputBase-root textarea": {
              marginTop: 0,
            },
            "& .MuiInputBase-input": {
              marginTop: "0 !important",
            },
          }),
          ...rest.sx,
        }}
      />
      {tooltipProps?.title && tooltipProps?.children && (
        <Box
          sx={{
            ...(error && { paddingBottom: "14px" }),
          }}
        >
          <Tooltip {...tooltipProps} />
        </Box>
      )}
    </Box>
  );
};
type NormalizeInputFunction = (VAL: string) => string;
export type RHFInputProps = InputProps & {
  name: string;
  helper?: string | JSX.Element | any;
  shouldCapitalizeEachWord?: boolean;
  focusViewColor?: string;
  handleNormalizeInput?: NormalizeInputFunction;
};

export const RHFInput: React.FC<RHFInputProps> = ({
  name,
  helper,
  shouldCapitalizeEachWord,
  handleNormalizeInput,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...rest }, fieldState: { error } }) => {
        return (
          <Input
            inputRef={ref}
            {...rest}
            error={!!error}
            value={shouldCapitalizeEachWord ? capitalizeEachWord(value) : value}
            helperText={helper || error?.message}
            onChange={(e) => {
              rest?.onChange({
                ...e,
                target: {
                  ...e.target,
                  value: handleNormalizeInput
                    ? handleNormalizeInput(e?.target?.value)
                    : e?.target?.value,
                },
              });
            }}
            {...props}
          />
        );
      }}
    />
  );
};
