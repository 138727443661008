import { CloseIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { ClickAwayListener, Popper } from "@mui/material";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import { palette } from "@palette";
import { MouseEventHandler, useState } from "react";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { SxProps } from "@mui/system";

export type ChipOption = {
  label?: string;
  value?: string | number;
  StartIcon?: () => JSX.Element;
  activeColor?: string;
  activeBackgroundColor?: string;
};

type PopupComponentProps = {
  handleClose: () => void;
  handleSave: (param?: any) => void;
};

interface IChipProps extends Omit<BoxProps, "onSelect"> {
  item: ChipOption;
  onSelect?: (val: string | number | undefined | ChipOption) => void;
  selected: boolean;
  popupCallback?: (params?: any) => void;
  PopupComponent?: (_: PopupComponentProps) => JSX.Element;
  deleteCallback?: () => void;
  allowMultiSelect?: boolean;
}

export default function Chip({
  item,
  onSelect,
  selected,
  PopupComponent,
  popupCallback = () => null,
  deleteCallback,
  allowMultiSelect = false,
  ...rest
}: IChipProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (PopupComponent || anchorEl) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      return;
    }
    if (allowMultiSelect) {
      onSelect && onSelect(item);
      return;
    }
    onSelect && onSelect(selected ? undefined : item.value);
  };

  const handleDelete: MouseEventHandler = (e) => {
    e.stopPropagation();
    const value = allowMultiSelect ? item : undefined;
    onSelect && onSelect(value);
    deleteCallback && deleteCallback();
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };
  const { isMobileView } = useCustomTheme();
  const bgColor = selected
    ? item?.activeBackgroundColor || palette.info.light
    : palette.neutral[10];

  const color = selected
    ? item?.activeColor || palette.info.main
    : palette.neutral[80];
  return (
    <ChipContainer
      bgcolor={bgColor}
      onClick={handleClick}
      selected={selected}
      {...rest}
    >
      {item?.StartIcon && item.StartIcon()}
      <ChipLabel color={color} selected={selected}>
        {item.label}
      </ChipLabel>
      {selected && (
        <CloseIcon
          onClick={handleDelete}
          stroke={item?.activeColor || palette.info.hard}
          width={18}
          height={18}
        />
      )}
      {isMobileView ? (
        <>
          <SwipeableDrawerMobile
            anchor="bottom"
            onOpen={() => undefined}
            open={open}
            onClose={() => {
              handleClosePopup();
            }}
            sx={{
              "& .MuiPaper-root.MuiDrawer-paper": {
                background: palette.neutral[5],
                padding: "16px 12px",
              },
            }}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {PopupComponent && (
                <PopupComponent
                  handleClose={handleClosePopup}
                  handleSave={popupCallback}
                />
              )}
            </Box>
          </SwipeableDrawerMobile>
        </>
      ) : (
        <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
          <ClickAwayListener onClickAway={handleClosePopup}>
            <div onClick={(e) => e.stopPropagation()}>
              {PopupComponent && (
                <PopupComponent
                  handleClose={handleClosePopup}
                  handleSave={popupCallback}
                />
              )}
            </div>
          </ClickAwayListener>
        </Popper>
      )}
    </ChipContainer>
  );
}

const ChipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ selected }) => ({
  gap: "2px",
  padding: "2px 8px",
  borderRadius: "100px",
  width: "fit-content",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "22px",
  cursor: "pointer",
  ...(!selected && {
    "&:hover": {
      background: palette.neutral[20],
    },
  }),
})) as React.ComponentType<BoxProps & { selected: boolean; sx?: SxProps<any> }>;

const ChipLabel = styled(Text, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ selected }) => ({
  fontSize: "12px",
  lineHeight: "14.4px",
}));
