import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { ITextProps } from "@common/Text/Text";
import { memo, useEffect, useState } from "react";
import { parseAmount } from "@utils/index";

export const DELAYED_RENDER = 1;

export const Delayed = ({ children, waitBeforeShow = DELAYED_RENDER }: any) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

export type FigureProps = ITextProps & {
  sup?: boolean;
  value: number | string;
  fontSize?: number | string;
  color?: string;
  isAmount?: boolean;
  percent?: boolean;
  isText?: boolean;
  ratio?: number | string | undefined;
  ratioStyle?: any;
};
export const DECIMAL_SIZE_RATIO = 0.75;

const Figure = ({
  sup,
  value,
  fontSize = 32,
  isAmount,
  color = palette.neutral[750],
  percent,
  fontWeight = "light",
  ratio,
  ratioStyle,
  ...props
}: FigureProps) => {
  return (
    <Stack direction="row" alignItems="flex-end" gap={1}>
      <ValueText
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        {...props}
      >
        {isAmount || percent ? parseAmount(value) : value}
      </ValueText>
      {ratio && (
        <RatioText
          color={color}
          paddingBottom="4px"
          fontSize={ratioStyle ? ratioStyle.fontSize : "18px"}
          fontWeight={ratioStyle ? ratioStyle.fontWeight : 400}
          lineHeight={ratioStyle ? ratioStyle.lineHeight : "100%"}
        >
          {`(${ratio}%)`}
        </RatioText>
      )}
    </Stack>
  );
};

const ValueText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "color",
})<ITextProps & { fontSize: number | string; color: string }>(
  ({ fontSize, color }) => ({
    flexGrow: 1,
    fontSize,
    background: color,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    lineHeight: "100%",
  }),
);

const RatioText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "color",
})<
  ITextProps & {
    color: string;
    fontSize: string | number;
    fontWeight: number | string;
    lineHeight: string;
  }
>(({ color, fontSize, fontWeight, lineHeight }) => ({
  flexGrow: 1,
  fontSize: fontSize,
  fontWeight: fontWeight,
  lineHeight: lineHeight,
  background: color,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

export default memo(Figure);
