import { QKEY_USER_PERMISSIONS } from "@constants/queryKeys";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { initAccessControl } from "@redux/slices/accessControl/accessControl";
import { getUserPermissions } from "@services/api/accessControl/accessControl";
import { useQuery } from "react-query";
import formatPermissions from "./formatPermissions";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { authorizations } from "@redux/slices/accessControl/initialState";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";

const useGetUserPermissions = () => {
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const dispatch = useAppDispatch();
  const { id: masqueradeId } = useMasqueradeReducer();

  const merchantId = selectedAccount?.id || 0;
  const accountId = selectedAccount?.userAccID || 0;

  const { data, isLoading } = useQuery(
    [QKEY_USER_PERMISSIONS, accountId, merchantId, masqueradeId],
    async () => {
      dispatch(initAccessControl(authorizations));
      const data = await getUserPermissions();
      return data;
    },
    {
      enabled: !!accountId,
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        const formattedData = formatPermissions(data?.data);
        dispatch(initAccessControl(formattedData));
      },
    },
  );

  return { data, isLoading };
};

export default useGetUserPermissions;
