import { namespaces } from "../../../../resources/i18n.constants";

export const manageUnderwriting = {
  [namespaces.pages.enterprise.manageUnderwriting]: {
    // COMMENTS
    comments: "Comentarios",
    comment: "Comentario",
    add_comment: "Agregar Comentario",
    save_changes: "Guardar Cambios",
    write_a_comment: "Escribir un comentario...",
    cancel: "Cancelar",
    comment_deleted: "Comentario Borrado",
  },
};
