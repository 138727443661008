import { Box, Collapse, Stack, styled } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { checkPortals } from "@utils/routing";
import { IParsedData } from "./data.types";
import StatsSummary from "./components/StatsSummary";
import ExpandedPanelStats from "./components/ExpandedPanelStats";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { TimeLineChart } from "@components/charts";
import { CheckMarkIcon } from "@assets/icons";
import TimeLineChartFilters from "./components/TimeLineChartFilters";
import { QKEY_ANALYTICS_CAMPAIGNS } from "@constants/queryKeys";
import EmptyChart from "@components/charts/EmptyStates/EmptyChart";
import { useAppSelector } from "@redux/hooks";
import { selectDateFilter } from "@redux/slices/analytics";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

interface SidePanelBodyProps {
  isExpanded: boolean;
  data: IParsedData;
  isEmpty: boolean;
}

const SidePanelBody = ({ isExpanded, data, isEmpty }: SidePanelBodyProps) => {
  const { isDesktopView } = useCustomTheme();
  const { isManageMoney } = checkPortals();
  const { generalData, ...rest } = data.chartsStats;

  const expandedPanelProps = {
    chartsOverview: data.chartsOverview,
    ...rest,
  };

  const dateFilter = useAppSelector((state) =>
    selectDateFilter(state, QKEY_ANALYTICS_CAMPAIGNS),
  );

  return (
    <>
      <FadeUpWrapper delay={100}>
        <Text
          color={palette.black[100]}
          fontSize="32px"
          fontWeight="book"
          lineHeight="38px"
          letterSpacing="-0.32px"
        >
          Analytics
        </Text>
      </FadeUpWrapper>
      <FadeUpWrapper delay={150}>
        {isEmpty ? (
          <EmptyChart />
        ) : (
          <Stack direction="column" gap={2} alignItems="stretch">
            <TimeLineChartFilters
              queryKey={QKEY_ANALYTICS_CAMPAIGNS}
              type="radio"
            />
            <TimeLineChart
              data={generalData}
              height={isDesktopView ? 302 : 240}
              customTimeConfig={{
                displayFormats: {
                  day: dateFilter?.type === "days" ? "ddd" : "MMM DD",
                  month: "MMM",
                  year: "YYYY",
                },
              }}
              renderDatasets={(datasets, toggleDataset) => (
                <TogglesContainer>
                  {datasets.map((dataset, idx) => (
                    <CustomCheckbox
                      key={idx}
                      label={dataset?.overRideLabel ?? dataset.label}
                      hidden={dataset.hidden}
                      color={
                        (dataset?.borderColor ?? dataset?.backgroundColor) || ""
                      }
                      onClick={() => toggleDataset(idx)}
                    />
                  ))}
                </TogglesContainer>
              )}
            />
          </Stack>
        )}
      </FadeUpWrapper>

      <FadeUpWrapper delay={200}>
        <StatsSummary
          data={{ summary: data.summary, trends: data.trends }}
          isExpanded={isExpanded}
          isEmpty={data.trends.transactionsAverage.value === 0}
        />
      </FadeUpWrapper>
      {isDesktopView
        ? isManageMoney && (
            <Collapse in={isExpanded} orientation="vertical" unmountOnExit>
              <ExpandedPanelStats {...expandedPanelProps} />
            </Collapse>
          )
        : isManageMoney && <ExpandedPanelStats {...expandedPanelProps} />}
    </>
  );
};

export const CustomCheckbox = ({
  onClick,
  hidden,
  color,
  label,
}: {
  onClick: () => void;
  hidden: boolean;
  label: string;
  color: string;
}) => {
  const { isMobileView } = useCustomTheme();
  return (
    <ToggleWrapper onClick={onClick}>
      <Toggle hidden={hidden} color={color}>
        {!hidden && <CheckMarkIcon fill={palette.neutral.white} />}
      </Toggle>
      <Text
        color={palette.black[100]}
        lineHeight={isMobileView ? "14.4px" : "16.8px"}
        fontSize={isMobileView ? "12px" : "14px"}
        fontWeight="book"
        width="max-content"
      >
        {label}
      </Text>
    </ToggleWrapper>
  );
};

export const TogglesContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  gap: "16px",
  overflowX: "auto",
  whiteSpace: "nowrap",
  minHeight: "30px",

  "&::-webkit-scrollbar-track": {
    background: "inherit",
  },
  "&::-webkit-scrollbar-thumb": {
    display: "none",
    width: "1px",
  },
  [theme.breakpoints.down("sm")]: {
    WebkitOverflowScrolling: "touch",
    gap: "12px",
  },
}));

const ToggleWrapper = styled(Box)(({ theme }) => ({
  gap: "12px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
  [theme.breakpoints.down("sm")]: {
    gap: "4px",
  },
}));

const Toggle = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hidden" && prop !== "color",
})<{ hidden: boolean; color: string }>(({ hidden, color }) => ({
  borderRadius: "6px",
  height: "18px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  padding: "2px",
  ...(hidden && {
    border: `2px solid ${palette.liftedWhite[200]}`,
  }),
  backgroundColor: hidden ? "transparent" : color,
}));

export default SidePanelBody;
