type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const ViewCustomerIcon = ({
  width = 20,
  height = 20,
  fill = "#8F8F8F",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99923 4.66699C9.00623 4.66699 8.20125 5.47198 8.20125 6.46497C8.20125 7.45797 9.00623 8.26295 9.99923 8.26295C10.9922 8.26295 11.7972 7.45797 11.7972 6.46497C11.7972 5.47198 10.9922 4.66699 9.99923 4.66699ZM7.20125 6.46497C7.20125 4.91969 8.45395 3.66699 9.99923 3.66699C11.5445 3.66699 12.7972 4.91969 12.7972 6.46497C12.7972 8.01025 11.5445 9.26295 9.99923 9.26295C8.45395 9.26295 7.20125 8.01025 7.20125 6.46497ZM9.76438 11.2377C9.76438 10.9616 9.98824 10.7377 10.2644 10.7377C12.4442 10.7377 14.2114 12.5048 14.2114 14.6847C14.2114 15.5954 13.4731 16.3337 12.5624 16.3337H7.4361C6.52539 16.3337 5.78711 15.5954 5.78711 14.6847C5.78711 13.5416 6.27366 12.5114 7.04948 11.7913C7.25187 11.6034 7.56823 11.6152 7.7561 11.8176C7.94396 12.02 7.93218 12.3364 7.72979 12.5242C7.14928 13.0631 6.78711 13.8312 6.78711 14.6847C6.78711 15.0431 7.07767 15.3337 7.4361 15.3337H12.5624C12.9208 15.3337 13.2114 15.0431 13.2114 14.6847C13.2114 13.0571 11.8919 11.7377 10.2644 11.7377C9.98824 11.7377 9.76438 11.5138 9.76438 11.2377Z"
        fill={fill}
      />
    </svg>
  )
}

export default ViewCustomerIcon;
