import { Button, Box, ImageListItem, Theme, styled } from "@mui/material";
import { TickIcon } from "@assets/icons";
import { palette } from "@palette";
import React from "react";

type ImgItemProps = {
  img: string;
  title: string;
  selected?: boolean;
  handleSelectImage: () => void;
};

const ImageItem = ({
  img,
  title,
  selected,
  handleSelectImage,
}: ImgItemProps) => {
  return (
    <Item
      selected={selected}
      onDragStart={(e) => e.preventDefault()}
      sx={{ maxWidth: "115px", maxHeight: "115px" }}
    >
      {selected && (
        <Tick data-testid={`selectable-image-${title}-icon`}>
          <TickIcon width={15} height={13} stroke={palette.black[300]} />
        </Tick>
      )}
      <img
        data-testid={`selectable-image-${title}`}
        src={img}
        alt={title}
        loading="lazy"
        onClick={handleSelectImage}
      />
    </Item>
  );
};

const Tick = styled(Box)(() => ({
  borderRadius: "1.809px",
  background: "rgba(255, 255, 255, 0.75)",
  position: "absolute",
  top: 3.6,
  right: 3.6,
  padding: "1.55px",
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ImageUpload = styled(Button)(({ theme }: { theme: Theme }) => ({
  width: 115,
  height: 115,
  position: "relative",
  background: "inherit",
  boxShadow: "none",
  borderRadius: "2px",
  overflow: "hidden",
  cursor: "pointer",
  border: `2px dashed ${palette.gray[300]}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.down("sm")]: {
    width: 103,
    height: 103,
    borderWidth: "2.5pxc",
  },
}));

const Item = styled(ImageListItem, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{
  selected?: boolean;
}>(({ theme, selected }: { theme: Theme; selected?: boolean }) => ({
  width: 120,
  height: 120,
  borderRadius: "2px",
  overflow: "hidden",
  cursor: "pointer",
  opacity: 0.6,
  position: "relative",

  ...(selected && {
    opacity: 1,
  }),

  "&:hover": {
    opacity: 1,
  },

  [theme.breakpoints.down("sm")]: {
    width: 103,
    height: 103,
    borderWidth: "2.5pxc",
  },
}));

type TAlign = "left" | "right";

export const ArrowButton = ({
  children,
  align = "right",
  onClick,
  isDisabled,
}: {
  children: React.ReactNode;
  align?: TAlign;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    isDisabled ? null : onClick(e);
  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100%",
        width: "32px",
      }}
    >
      <PageChangeButton
        isDisabled={isDisabled}
        align={align}
        onClick={handleClick}
      >
        {children}
      </PageChangeButton>
    </Box>
  );
};

type PageChangeButton = { align: TAlign; isDisabled?: boolean };

const PageChangeButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "align",
})<PageChangeButton>(({ align, isDisabled }: PageChangeButton) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 32,
  width: 32,
  height: 64,
  padding: 0,
  background: "inherit",
  border: "none",
  boxShadow: "none",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  cursor: isDisabled ? "not-allowed" : "pointer",

  ...(align === "right" && {
    left: "6px",
  }),
  ...(align === "left" && {
    right: "6px",
  }),
}));

export default ImageItem;
