import { Text } from "@common/Text";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";

const LatestAdded = () => (
  <Wrapper>
    <Overlay />
    <CustomText>Latest added</CustomText>
  </Wrapper>
);

const Overlay = styled(Box)(() => ({
  background:
    "radial-gradient(50% 50% at 50% 50%, rgba(109, 156, 248, 0.50) 0%, rgba(109, 156, 248, 0.00) 100%)",
  height: "164px",
  top: "-112px",
  right: 0,
  left: 0,
  position: "absolute",
}));

const CustomText = styled(Text)(() => ({
  color: palette.neutral.white,
  lineHeight: "16.8px",
  backgroundColor: "#6D9CF8",
  borderRadius: "100px",
  padding: "6px 24px",
  zIndex: 5,
  userSelect: "none",
}));

const Wrapper = styled(Stack)(() => ({
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "16px",
  paddingBottom: "8px",
  position: "relative",
  overflow: "hidden",
  minHeight: "54px",
}));

export default LatestAdded;
