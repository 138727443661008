import { Box, BoxProps } from "@mui/material";
import React, { memo } from "react";
import { animated, useInView, useSpring } from "react-spring";

const FadeInWrapper = ({
  children,
  delay = 1000,
  containerProps,
  dataTestId
}: {
  children: React.ReactNode;
  delay?: number;
  containerProps?: BoxProps;
  dataTestId?: string;
}) => {
  const [ref, inView] = useInView({ once: true });

  const styles = useSpring({
    opacity: inView ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 500, easing: (t: any) => t * (2 - t) },
    delay,
  });

  return (
    <Box ref={ref} data-testid={dataTestId} {...containerProps}>
      <animated.div style={styles}>{children}</animated.div>
    </Box>
  );
};

export default memo(FadeInWrapper);
