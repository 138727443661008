import { Text } from "@common/Text";
import SectionPlaceholders from "@components/Merchants/CreateMerchantPanel/components/AddSections/SectionPlaceholders";
import { Box, Stack, styled } from "@mui/material";
import { IParsedData } from "../data.types";
import { palette } from "@palette";
import WarningIcon from "@assets/icons/RebrandedIcons/WarningIcon";
import useMerchantPlaceholders from "../hooks/useMerchantPlaceholders";
import { Button } from "@common/Button";
import { useResyncMSP } from "../hooks/useResyncMSP";
import { EditMerchantHeaderHandlers } from "../helpers/types";

type Props = {
  data: IParsedData;
  isWarnings?: boolean;
  handlers?: EditMerchantHeaderHandlers;
};

const WarningPlaceholders = ({ data, isWarnings = false, handlers }: Props) => {
  const { placeholdersList, isCompletedMerchant } = useMerchantPlaceholders(
    data,
    handlers,
  );
  const { isLoading, handleResyncMSP } = useResyncMSP(
    data.merchantInfo.merchantID,
    data.businessProfile.id,
  );

  if (isCompletedMerchant) return null;

  const placeholdersText = placeholdersList
    .filter((placeholder) => !placeholder.hidden || placeholder.warning)
    .map((placeholder) => placeholder.title)
    .join(", ");

  return (
    <Stack spacing={4}>
      {isWarnings && (
        <WarningPlaceholderBase
          message={`Merchant needs to provide this information:`}
          messageColor={palette.warning.text}
        >
          <Text
            fontSize="14px"
            lineHeight="16.8px"
            color={palette.warning.text}
            fontWeight="bold"
          >
            {placeholdersText}
          </Text>
          {data.businessProfile.statusName === "pending_review" && (
            <Button
              background="tertiary"
              onClick={handleResyncMSP}
              disabled={isLoading}
              sx={{
                maxWidth: "120px",
                maxHeight: "20px",
                padding: "0 8px",
                marginTop: 0,
                color: palette.warning.text,
                textDecoration: "underline",
                fontWeight: 400,
              }}
            >
              Resubmit MSP
            </Button>
          )}
        </WarningPlaceholderBase>
      )}
      {!isWarnings && (
        <SectionPlaceholders placeholdersList={placeholdersList} />
      )}
    </Stack>
  );
};

type WarningPlaceholderBaseProps = {
  title?: string;
  message: string | React.ReactNode;
  messageColor?: string;
  titleColor?: string;
  children?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  icon?: React.ReactNode;
  titleStyle?: React.CSSProperties;
};

export const WarningPlaceholderBase = ({
  title = "Attention",
  message,
  messageColor = palette.warning.text,
  titleColor = palette.tag.warning.hover,
  children,
  containerStyle,
  icon,
  titleStyle = {
    fontSize: 14,
    lineHeight: "16.8px",
  },
}: WarningPlaceholderBaseProps) => {
  return (
    <WarningContainer sx={containerStyle} data-testid="warning-container">
      <Stack direction="row" spacing="8px" alignItems="center">
        {icon || <WarningIcon height={20} width={20} />}
        <Text sx={titleStyle} color={titleColor}>
          {title}
        </Text>
      </Stack>
      <Stack spacing={2}>
        <Text
          fontSize="14px"
          lineHeight="16.8px"
          color={messageColor}
          fontWeight="book"
          sx={{ wordBreak: "break-word" }}
        >
          {message}
        </Text>
        {children}
      </Stack>
    </WarningContainer>
  );
};

const WarningContainer = styled(Box)(() => ({
  display: "flex",
  padding: " 12px 12px",
  flexDirection: "column",
  gap: "12px",
  borderRadius: "8px",
  border: "2px solid rgba(255, 129, 36, 0.50)",
  background: "#FFE7D6",

  "& ul": {
    paddingLeft: "25px",
  },
  "& li::marker": {
    color: palette.warning.text,
    paddingLeft: "8px",
  },
}));

export default WarningPlaceholders;
