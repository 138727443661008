import React, { useEffect, useState } from "react";
// mui
import { Box, TableCell, TableRow as MuiTableRow, Theme } from "@mui/material";
// components
import { Text } from "@common/Text";
import { RotateZ } from "@common/RotateZ";
import { ExpandedRowContainer } from "./ExpandedRowContainer";
// type
import { RenderExpandedRow, TableColumnTypeCustom } from "./helpers";
import { palette } from "@palette";
import { makeStyles } from "@mui/styles";
import KotoChevronDown from "@assets/icons/RebrandedIcons/KotoChevronDown";

type TableRowProps = {
  rowData: any;
  small?: boolean;
  expandable?: boolean | ((row: any) => boolean);
  height?: string | number;
  columns: TableColumnTypeCustom[];
  renderExpandedRow?: RenderExpandedRow;
  pinkBorder?: boolean;
  pinkRowBorder?: string;
  iconButton?: boolean;
  selectable?: boolean;
  isPending?: boolean;
  isSelected?: boolean;
  onOpenSidePanel?: () => void;
  sx?: React.CSSProperties;
};

export const TableRow: React.FC<TableRowProps> = ({
  expandable = false,
  rowData,
  columns,
  height,
  small,
  renderExpandedRow,
  pinkRowBorder,
  iconButton,
  selectable,
  isPending,
  onOpenSidePanel,
  isSelected,
  sx,
}) => {
  const classes = useStyles({
    small,
    isSelected,
    expandable: Boolean(expandable),
    height: height || "60px",
    sx,
  });

  const [open, setOpen] = useState(false);
  const ExpandedRowComponent =
    renderExpandedRow && renderExpandedRow({ rowData, open });
  const mount = React.useRef(false);

  React.useEffect(() => {
    if (!mount.current) {
      mount.current = true;
      return;
    }

    const hiddenElements = document.querySelectorAll(
      `.hide-when-expand-${rowData.id}`,
    );
    if (open) {
      hiddenElements.forEach((item) => {
        (item as HTMLElement).style.display = "none";
      });
    } else {
      hiddenElements.forEach((item) => {
        (item as HTMLElement).style.display = "block";
      });
    }
  }, [open]);

  return (
    <>
      {selectable && onOpenSidePanel && (
        <tr
          onClick={(e) => onOpenSidePanel()}
          className={`${classes.divOverlayRow}`}
          style={{
            left: 0,
          }}
        />
      )}
      <tr
        onClick={() => onOpenSidePanel?.()}
        className={`${classes.tableRow}`}
        data-testid="table-clickable-row"
      >
        {columns.map((column, idx) => {
          if (column?.hideColumn) return null;
          const isActionsColumn = column.key === "actions";
          return (
            <TableCell
              key={`col-${column.key}--row${rowData.id}-${idx}`}
              className={`${classes.tableCell}`}
              sx={{
                borderBottom: pinkRowBorder || "none",
                ...(open && {
                  borderBottom: "none",
                }),
                ...column.sx,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  justifyContent: isActionsColumn ? "flex-end" : "flex-start",
                  ...(!isActionsColumn && {
                    px: idx === 0 && !small ? "0" : "8px",
                  }),
                }}
              >
                {expandable && column.hasExpandButton && (
                  <Box
                    onClick={() => setOpen(!open)}
                    className={`${classes.arrowButton}`}
                    data-testid={`expandable-item-${rowData.name}`}
                  >
                    {!iconButton ? (
                      <RotateZ by={open ? "180deg" : "0deg"}>
                        <KotoChevronDown fill="#6f6f6f" />
                      </RotateZ>
                    ) : (
                      <RotateZ by={open ? "180deg" : "-180deg"}>
                        <KotoChevronDown fill="#6f6f6f" />
                      </RotateZ>
                    )}
                  </Box>
                )}

                {column.renderColumn ? (
                  column.renderColumn(rowData, open)
                ) : (
                  <Text
                    color={
                      isPending ? palette.neutral[60] : palette.neutral[800]
                    }
                  >
                    {rowData[column.key]}
                  </Text>
                )}
              </Box>
            </TableCell>
          );
        })}
      </tr>
      {expandable && open && (
        <MuiTableRow className={`${classes.expandedRow}`}>
          <TableCell colSpan={columns.length}>
            <ExpandedRowContainer>{ExpandedRowComponent}</ExpandedRowContainer>
          </TableCell>
        </MuiTableRow>
      )}
    </>
  );
};

type Props = {
  expandable?: boolean;
  isSelected?: boolean;
  small?: boolean;
  height?: string | number;
  sx?: React.CSSProperties;
};

const useStyles = makeStyles<Theme, Props>((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover ": {
      backgroundColor: palette.neutral.white,
    },
  },
  arrowButton: {
    cursor: "pointer",
    width: "16px",
    marginRight: "4px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
  tableCell: ({ small, height, isSelected, sx }) => ({
    padding: "18px 8px",
    height,
    ...(small && {
      height: 53,
    }),
    borderBottom: !small ? "1px solid #E1E1DE" : "none",
    ...(isSelected && {
      backgroundColor: palette.neutral.white,
    }),
    ...sx,
  }),
  expandedRow: {
    borderTop: "none !important",
    "& td": {
      padding: 0,
      borderBottom: "none",
      backgroundColor: "white",
    },
  },
  divOverlayRow: ({ height }) => ({
    position: "absolute",
    width: "100%",
    cursor: "pointer",
    border: "none",
    zIndex: "-1",
    height,
  }),
}));
