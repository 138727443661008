import { useQuery } from "react-query";
import { isDefined } from "@utils/helpers";
import { isNaN } from "lodash";
import { getMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";

export type EnterpriseData = {
  accID: number;
  accTypeID: number;
  allowAddingBankAccounts: boolean;
  allowP2P: boolean;
  allowVolunteers: boolean;
  allowedCategoryCodes: null | string[];
  amexCreditCardFees: number;
  billingDescriptor: string;
  canProcessMoney: boolean;
  canTransferMoney: boolean;
  categoryCode: null | string;
  createdAt: number;
  creditCardFees: number;
  debitCardFees: number;
  deletedAt: null | number;
  description: string;
  hasProcessedMoney: boolean;
  imageURL: string;
  isController: boolean;
  name: string;
  ownershipType: string;
  parentAccID: null | number;
  phoneNumber: string;
  servicePhoneNumber: string;
  slug: string;
  termsAcceptedAt: null | number;
  termsAcceptedLastAt: null | number;
  type: string;
  updatedAt: number;
  websiteURL: string;
};

export const useGetEnterpriseById = (
  id: string | number,
  customProps?: any,
) => {
  const merchantID = Number(id);
  return useQuery(
    ["get-enterpriseId-by-id", merchantID],
    async () => {
      const provider = await getMerchantById(merchantID);
      return provider;
    },
    {
      refetchOnWindowFocus: false,
      enabled: isDefined(merchantID) && !isNaN(merchantID),
      ...customProps,
    },
  );
};
