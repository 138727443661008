import { DragEndEvent, DragOverEvent } from "@dnd-kit/core";
import { batch, signal } from "@preact/signals-react";
import { onClickWidget } from "@pages/NewAdvancedBuilder/consts/widgets/actions";
import { ROOT_NODE_ID } from "@pages/NewAdvancedBuilder/consts/values";
import {
  capturedDraggablePosition,
  intersectionRatio,
  isDraggablePositionCaptured,
  isWidgetDragging,
  scrollableContainerRect,
  structuredTreeSignal,
} from "@pages/NewAdvancedBuilder/signals";

export const onDragOverSignal = signal(null);

export const onDragOver = (e: DragOverEvent) => {
  batch(() => {
    if (e.over) {
      //if the draggable item is over a child of a compound node, the overlay should be setted under the node that is the head of this kind of component
      if (
        e.over.data.current?.head &&
        e.over.data.current?.parentID !== ROOT_NODE_ID
      ) {
        // As the draggable area is bigger than the scrollable area,
        // that's why, we can get the reference of the element in the onDragOver function
        // which is inside the the draggable area but outside the scrollable area.
        // And this behaviour creating flickering effect when going outside of the scrollable area.
        // That's why we are checking if the element is visible or not. If not, then we are not updating anything.
        if (!isElementVisible(e.over.data.current?.head)) {
          onDragOverSignal.value = null;
          return;
        }
        onDragOverSignal.value = e.over.data.current?.head;
      } else {
        if (e.over.data.current?.nodeId !== null) {
          if (e.over.data.current?.nodeId === ROOT_NODE_ID) {
            const length = structuredTreeSignal.value?.childrens?.length;
            onDragOverSignal.value =
              structuredTreeSignal.value?.childrens?.[length - 1]?.key;
            return;
          }
          if (!isElementVisible(e.over.data.current?.nodeId)) {
            onDragOverSignal.value = null;
            return;
          }
          onDragOverSignal.value = e.over.data.current?.nodeId;
        }
      }
    } else {
      onDragOverSignal.value = null;
    }
  });
};

export const onDragEnd = (e: DragEndEvent) => {
  const p = document.getElementById("layout_root_node");
  if (p) {
    p.style.overflow = "auto";
  }

  const { active, over } = e;

  if (active?.data?.current && over && onDragOverSignal.value) {
    onClickWidget(
      active.data.current,
      over.data.current?.nodeId || over.data.current?.nodeId !== ROOT_NODE_ID
        ? onDragOverSignal.value
        : null,
    );
  }
  onDragOverSignal.value = null;
  isWidgetDragging.value = false;
  isDraggablePositionCaptured.value = false;
  capturedDraggablePosition.value = { x: 0, y: 0 };
  intersectionRatio.value = 1;
};

// This function is checking if a given element is outside of the scrollable area on both top and bottom side.
function isElementVisible(id: any) {
  const element = document.getElementById(id);
  if (!element) return false;
  const elementRect = element.getBoundingClientRect();

  if (
    elementRect.bottom < scrollableContainerRect.value.top ||
    elementRect.top > scrollableContainerRect.value.bottom
  ) {
    return false;
  }

  return true;
}
