import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalDrawer from "@common/Modal/ModalDrawer/ModalDrawer";
import { Box, Stack } from "@mui/material";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TEventModalInputs } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EventModalSchema as schema,
  EventModalDefaults as defaultValues,
  validateURL,
} from "./utils";
import FundraisersAbout from "../FundraisersAbout";
import { useEffect, useRef } from "react";
import StyleSection from "../StyleSection";
import { useCreateProduct } from "../hooks";
import { useFindEventById } from "@hooks/merchant-api/events";
import EventDateLocation from "./EventDateLocation";
import EventPayment from "./EventPayment";
import EventConfiguration from "./EventConfiguration";
import { useQueryClient } from "react-query";
import EditProductSkeleton from "../EditProductSkeleton";

type EditEventModalProps = {
  id?: string;
  handleClose?: () => void;
  noModal?: boolean;
};

export const EditEvent = ({
  id,
  handleClose,
  noModal,
}: EditEventModalProps) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useFindEventById(id);
  const { submitProduct, isDisabled } = useCreateProduct("event", {
    id,
    noModal,
  });
  const isMounted = useRef<boolean>(false);

  const methods = useForm<TEventModalInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (data && !isLoading) {
      const formattedList = data.tickets.list.map((ticket: any) => {
        return {
          ...ticket,
          title: ticket.name,
          display: Boolean(ticket.display),
          description: ticket.description?.text || "",
        };
      });

      reset({
        about: {
          title: data.general.title,
          description: data.general.description,
        },
        style: {
          image: data.general.featuredImage.image,
          useAsBackground: data.general.featuredImage.useAsBackground,
        },
        date_and_location: data.date_and_location,
        payment_set_up: {
          payment_types: {
            default: "one_time",
            one_time: true,
          },
          amountsList: formattedList,
        },
        configuration: {
          customer_pays_fees: data.tickets.customer_pays_credit.active,
          maxTickets: data.tickets.customer_pays_credit.maxTickets,
        },
      });
      isMounted.current = true;
    }
  }, [isLoading]);

  const onClose = () => {
    reset();
    if (handleClose) {
      handleClose();
      queryClient.removeQueries("find-event-by-id");
    }
    isMounted.current = false;
    isMounted.current = false;
  };

  const onSubmit: SubmitHandler<TEventModalInputs> = async (data) => {
    const formattedData = {
      ...data,
      date_and_location: {
        ...data.date_and_location,
        location: {
          ...data.date_and_location.location,
          event_url: data.date_and_location.location.event_url
            ? validateURL(data.date_and_location.location.event_url)
            : "",
        },
      },
    };
    submitProduct(formattedData, onClose);
  };

  return (
    <ModalDrawer
      onModalClose={onClose}
      HeaderProps={{
        title: "Edit",
      }}
      primaryAction={{
        label: "Save changes",
        type: "submit",
        disabled: !isDirty || isDisabled,
        form: "edit-event",
      }}
      isBody={noModal}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          flexGrow={1}
          display="flex"
          id="edit-event"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {isLoading ? (
            <EditProductSkeleton />
          ) : (
            <Stack direction="column" gap={4} flexGrow={1}>
              <FundraisersAbout title="About" titleSize="medium" isEdit />
              <StyleSection title="Customize" titleSize="medium" isEdit />
              <EventDateLocation isModalMounted={isMounted.current} />
              <EventPayment title="Set up a range of payment choices" />
              <EventConfiguration title="Configuration" />
            </Stack>
          )}
        </Box>
      </FormProvider>
    </ModalDrawer>
  );
};

const EditEventModal = NiceModal.create(({ id }: EditEventModalProps) => {
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
  };

  return <EditEvent id={id} handleClose={handleClose} />;
});

export default EditEventModal;
