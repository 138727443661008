import React from "react";

const PreviousIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10L3.57574 10.4243L3.15147 10L3.57574 9.57574L4 10ZM20.6 18C20.6 18.3314 20.3314 18.6 20 18.6C19.6686 18.6 19.4 18.3314 19.4 18L20.6 18ZM8.57574 15.4243L3.57574 10.4243L4.42426 9.57574L9.42426 14.5757L8.57574 15.4243ZM3.57574 9.57574L8.57574 4.57574L9.42426 5.42426L4.42426 10.4243L3.57574 9.57574ZM4 9.4L14 9.4L14 10.6L4 10.6L4 9.4ZM20.6 16L20.6 18L19.4 18L19.4 16L20.6 16ZM14 9.4C17.6451 9.4 20.6 12.3549 20.6 16L19.4 16C19.4 13.0177 16.9823 10.6 14 10.6L14 9.4Z"
        fill="#727179"
      />
    </svg>
  );
};

export default PreviousIcon;
