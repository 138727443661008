import { getJsonValue } from "./utils";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    filled: true;
    outline: true;
    ghost: true;
  }

  interface ButtonPropsColorOverrides {
    light: true;
    blue: true;
    destructive: true;
  }
}

export const getLargeFilledVariants = (
  mode: "light" | "dark",
  basePallete: any,
) => [
  {
    props: (props: any) => props.size === "large" && props.variant === "filled",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
      color: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.buttons.default-hover`,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
      color:
        getJsonValue(`tokens.${mode}.colour.surface.primary`) + " !important",
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.color === "light",
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken 5`,
      ),
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken 10`,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.color === "light" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken 5`,
      ),
      color: getJsonValue(`tokens.${mode}.colour.text.primary`) + " !important",
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      opacity: 0.5,
      cursor: "default",
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.color === "blue",
    style: {
      background: basePallete.gradient["ocean-blue"].default,
      color: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        background: basePallete.gradient["ocean-blue"].highlight,
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.color === "blue" &&
      props.disabled === true,
    style: {
      background: basePallete.gradient["ocean-blue"].disabled,
      color:
        getJsonValue(`tokens.${mode}.colour.surface.primary`) + " !important",
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.color === "destructive",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.50`),
      color: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.100`),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "filled" &&
      props.color === "destructive" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.50`),
      color:
        getJsonValue(`tokens.${mode}.colour.surface.primary`) + " !important",
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
];

export const getLargeOutlinedVariants = (
  mode: "light" | "dark",
  basePallete: any,
) => [
  {
    props: (props: any) =>
      props.size === "large" && props.variant === "outline",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.95`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.95`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        color: getJsonValue(`tokens.${mode}.primitive.neutral.50`),
        border:
          "1px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.50`),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "outline" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.40`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.40`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "outline" &&
      props.color === "blue",
    style: {
      background: basePallete.gradient["ocean-blue"].border,
      border: "1.5px solid transparent",
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        border:
          "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.blue.100`),
        color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
        opacity: 0.8,
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "outline" &&
      props.color === "blue" &&
      props.disabled === true,
    style: {
      background: basePallete.gradient["ocean-blue"].border,
      border: "1.5px solid transparent",
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`) + " !important",
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      opacity: 0.4,
      cursor: "default",
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "outline" &&
      props.color === "destructive",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.error.50`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        border:
          "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.error.100`),
        color: getJsonValue(`tokens.${mode}.primitive.error.100`),
        opacity: 0.8,
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "outline" &&
      props.color === "destructive" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`) + " !important",
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.error.50`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
];

export const getLargeGhostVariants = (mode: "light" | "dark") => [
  {
    props: (props: any) => props.size === "large" && props.variant === "ghost",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        background: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken 5`,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "ghost" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.5,
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "ghost" &&
      props.color === "destructive",
    style: {
      background: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`),
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      "&:hover": {
        background: getJsonValue(`tokens.${mode}.primitive.error.25`),
        color: getJsonValue(`tokens.${mode}.primitive.error.100`),
        opacity: 0.8,
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "large" &&
      props.variant === "ghost" &&
      props.color === "destructive" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.error.100`) + " !important",
      padding: "8px 24px",
      borderRadius: "48px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.5,
    },
  },
];

export const getSmallFilledVariants = (
  mode: "light" | "dark",
  basePallete: any,
) => [
  {
    props: (props: any) => props.size === "small" && props.variant === "filled",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.neutral.95`),
      color: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: getJsonValue(`tokens.${mode}.primitive.neutral.70`),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "filled" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.neutral.30`),
      color:
        getJsonValue(`tokens.${mode}.colour.surface.primary`) + " !important",
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "filled" &&
      props.color === "light",
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken 5`,
      ),
      color: getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken 10`,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "filled" &&
      props.color === "light" &&
      props.disabled === true,
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken 5`,
      ),
      color:
        getJsonValue(`tokens.${mode}.primitive.transparent.darken 5`) +
        " !important",
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      opacity: 0.4,
      cursor: "default",
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "filled" &&
      props.color === "blue",
    style: {
      background: basePallete.gradient["ocean-blue"].default,
      color: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        background: basePallete.gradient["ocean-blue"].highlight,
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "filled" &&
      props.color === "blue" &&
      props.disabled === true,
    style: {
      background: basePallete.gradient["ocean-blue"].disabled,
      color:
        getJsonValue(`tokens.${mode}.colour.surface.primary`) + " !important",
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
];

export const getSmallmallOutlinedVariants = (
  mode: "light" | "dark",
  basePallete: any,
) => [
  {
    props: (props: any) =>
      props.size === "small" && props.variant === "outline",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.95`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.95`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        color: getJsonValue(`tokens.${mode}.primitive.neutral.50`),
        border:
          "1px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.50`),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "outline" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.40`),
      border:
        "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.neutral.40`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.4,
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "outline" &&
      props.color === "blue",
    style: {
      background: basePallete.gradient["ocean-blue"].border,
      border: "1.5px solid transparent",
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        border:
          "1.5px solid " + getJsonValue(`tokens.${mode}.primitive.blue.100`),
        color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
        opacity: 0.8,
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "outline" &&
      props.color === "blue" &&
      props.disabled === true,
    style: {
      background: basePallete.gradient["ocean-blue"].border,
      border: "1.5px solid transparent",
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`) + " !important",
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      opacity: 0.4,
      cursor: "default",
    },
  },
];

export const getSmallGhostVariants = (mode: "light" | "dark") => [
  {
    props: (props: any) => props.size === "small" && props.variant === "ghost",
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      "&:hover": {
        background: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken 5`,
        ),
      },
    },
  },
  {
    props: (props: any) =>
      props.size === "small" &&
      props.variant === "ghost" &&
      props.disabled === true,
    style: {
      backgroundColor: "transparent",
      color: getJsonValue(`tokens.${mode}.primitive.neutral.90`),
      padding: "7px 12px",
      borderRadius: "8px",
      textTransform: "none",
      cursor: "default",
      opacity: 0.5,
    },
  },
];
