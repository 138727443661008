import React, { memo } from "react";
import {
  ActionButton,
  ActionsContainer,
  ListSkeleton,
  OpenCodeEditorButton,
} from "./AddPermissionModal.styles";
import { Stack } from "@mui/material";
import { GroupStack, PermissionSearchBar } from "./AddPermissionModal.atoms";
import { CodeIconWithBar } from "@assets/icons";
import FilterPermissionList from "../FilterPermissionList";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import NoResultsState from "@common/EmptyState/NoResultsState";
import useAddPermissionsModal from "../../hooks/useAddPermissionsModal";
import { TPermissionsData } from "../../types";
import { shouldBeHidden } from "@constants/constants";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

export interface IPermissionListProps {
  onClose: () => void;
  onAdd: (logs: TPermissionsData) => void;
  memberId: number;
}

const PermissionListContent = ({
  onClose,
  onAdd,
  setIsCodeEditor,
  memberId,
}: IPermissionListProps & {
  setIsCodeEditor: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    listMutated,
    clearChanges,
    logs,
    searchString,
    handleSearch,
    selectedGroup,
    filters,
    orderedKeys,
    handleSelect,
    isSearching,
    dataIsLoading,
    permissionsData,
    setPermissionStatus,
    filteredGroups,
  } = useAddPermissionsModal(memberId);

  const isAddAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MEMBER,
      RESOURCE_BASE.ACCESS_POLICIES,
    ),
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const onCancel = () => {
    clearChanges();
    onClose();
  };

  const onSubmit = () => onAdd(logs);

  return (
    <>
      <PermissionSearchBar value={searchString} onChange={handleSearch} />
      <Stack
        padding="16px 12px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <FilterPermissionList
          defaultValue={selectedGroup}
          filterData={filters}
          onHandleSelect={handleSelect}
        />
        {!shouldBeHidden.customPermission && (
          <OpenCodeEditorButton
            background="tertiary"
            onClick={() => setIsCodeEditor(true)}
            disabled={!isAddAllowed}
            tooltipProps={{
              show: !isAddAllowed,
              message: CREATE_DENY_MESSAGE,
            }}
          >
            <CodeIconWithBar fill="#6D9CF8" /> Add Custom Permission
          </OpenCodeEditorButton>
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="stretch"
        flexGrow={1}
        sx={{ overflowY: "scroll" }}
      >
        {dataIsLoading || isSearching ? (
          <ListSkeleton />
        ) : !orderedKeys.length ? (
          <NoResultsState searchQuery={searchString} />
        ) : (
          orderedKeys.map((x, index) => (
            <GroupStack
              key={x}
              groupName={x}
              isLast={index === orderedKeys.length - 1}
              uniqueGroups={filteredGroups}
              hashes={permissionsData.data}
              onClick={setPermissionStatus}
            />
          ))
        )}
      </Stack>
      {listMutated && (
        <FadeUpWrapper delay={200}>
          <ActionsContainer>
            <ActionButton background="tertiary" size="small" onClick={onCancel}>
              Cancel
            </ActionButton>
            <ActionButton background="primary" size="small" onClick={onSubmit}>
              Save
            </ActionButton>
          </ActionsContainer>
        </FadeUpWrapper>
      )}
    </>
  );
};

export default PermissionListContent;
