import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";

type TConversationTopicDataSubmit = {
  subjectAccID: number;
  TopicDisplayName: string;
};

export const useCreateTopic = ({ merchantId }: { merchantId: number }) => {
  const { id: globalMerchantId } = useAppSelector(selectUser);
  const queryClient = useQueryClient();

  const createConversationTopicMutation = useMutation(
    (data: TConversationTopicDataSubmit) => {
      return customInstance({
        url: `/merchants/${globalMerchantId}/threads`,
        method: "POST",
        data,
      });
    },
  );

  const handleSubmit = (topicName: string) => {
    const customData: TConversationTopicDataSubmit = {
      subjectAccID: merchantId,
      TopicDisplayName: topicName,
    };
    createConversationTopicMutation.mutate(customData, {
      onError: console.log,
      onSuccess: (e) => {
        queryClient.refetchQueries({
          queryKey: ["get-conversation-topics", merchantId],
          // exact: true,
        });
      },
    });
  };

  return {
    handleSubmit,
    isLoading: createConversationTopicMutation.isLoading,
    isError: createConversationTopicMutation.isError,
    isSuccess: createConversationTopicMutation.isSuccess,
  };
};
