/* eslint-disable react/no-unescaped-entities */
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { Stack } from "@mui/material";
import { StackProps } from "@mui/material/Stack";
import { palette } from "@palette";
import { isMobile } from "@utils/index";
import React from "react";

type InfoProps = {
  open: boolean;
  isDesktop?: boolean;
  setOpen: (open: boolean) => void;
};

export const BankFeesInfo: React.FC<InfoProps> = ({ open, setOpen }) => {
  return (
    <Popup
      open={open}
      width="600px"
      title="Fees breakdown"
      onSubmit={() => setOpen(false)}
      actionLabel="Understood"
    >
      <Stack gap={isMobile ? 2 : 3}>
        {/* <SectionContainer>
          <SectionTitle>Money Transfer Fees</SectionTitle>
          <SectionBody>
            Banks charge money to transfer money. From small withdrawals to
            large ACH direct deposits, there are fees. To aid nonprofits,
            GivePayments utilizes the most efficient transfer procedure so that we
            can help reduce your overall costs.
          </SectionBody>
        </SectionContainer> */}
        <SectionContainer>
          <SectionTitle>Money Transfers of $999.99 or less</SectionTitle>
          <SectionBody>
            For all transfers of $999.99 or less you will be charged $0.99 per
            transfer.
            <br />
            <i>
              For example, if you transfer $500 it will cost you only $0.99 to
              make the transfer
            </i>
          </SectionBody>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>
            Money Transfers between $1,000.00 and $19,999.99
          </SectionTitle>
          <SectionBody>
            Any money transfers between $1,000.00 and $19,999.99 the bank
            charges you 0.1% of the amount.
            <i>
              {" "}
              For example, if you transfer $5000 it will cost you only $5 to
              make the transfer which is a 0.1% transfer fee.
            </i>
          </SectionBody>
        </SectionContainer>
        <SectionContainer>
          <SectionTitle>Money Transfers of $20,000.00 or more</SectionTitle>
          <SectionBody>
            Any transfer of $20,000.00 or more you have a fixed rate of $20.00
            per transfer. The bank has capped the rate for transferring large
            amounts at $20.00. This is less than 0.1% for any amount larger than
            $20,000.00.
            <i>
              {" "}
              For example, if you transfer $200,000.00 you pay only $20.00 which
              is a 0.01% transfer fee.
            </i>
          </SectionBody>
        </SectionContainer>
      </Stack>
    </Popup>
  );
};

const SectionTitle = ({ ...props }: ITextProps) => {
  return (
    <Text
      fontSize="18px"
      variant={isMobile ? "headline" : "h6"}
      fontWeight={"book"}
      lineHeight="21.6px"
      color={palette.neutral[90]}
      {...props}
    />
  );
};

const SectionBody = ({ ...props }: ITextProps) => {
  return (
    <Text
      fontSize="16px"
      variant="body"
      fontWeight="light"
      lineHeight={"19.2px"}
      color={palette.neutral[80]}
      {...props}
    />
  );
};
const SectionContainer = ({ ...props }: StackProps) => {
  return <Stack gap={0.5} {...props} />;
};
