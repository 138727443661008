import { DocumentationIcon } from "@assets/icons";
import placeholder from "@assets/images/Placeholder.png";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import CopyButton from "@common/CopyButton";
import { Image } from "@common/StyledImage/Image";
import { Text, TruncateText } from "@common/Text";
import { Tooltip } from "@common/Tooltip";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { Box, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { format } from "date-fns";
import DeveloperApiStatus from "./DeveloperApiStatus";

const DAPanelContent = ({ rowData }: { rowData: any }) => {
  const BASE_URL = rowData.isSandbox
    ? process.env.REACT_APP_SANDBOX_API_URL + "/api"
    : process.env.REACT_APP_DEVELOPER_API_URL;

  const PORTAL_URL = process.env.REACT_APP_SANDBOX_PORTAL_URL;

  const GridKeyValues = [
    {
      id: 0,
      key: "Environment",
      value: rowData.isSandbox ? "Sandbox" : "Production",
    },
    {
      id: 1,
      key: "Status",
      value: (
        <DeveloperApiStatus
          status={rowData.statusName}
          text={
            rowData.statusDisplayName === "Rolled"
              ? "Suspended"
              : rowData.statusDisplayName
          }
        />
      ),
    },
    {
      id: 2,
      key: "Creation Date",
      value: format(rowData.createdAt * 1000, "MMM dd, yyyy, p"),
    },
    {
      id: 3,
      key: "Created By",
      value: (
        <CustomToolTip
          showToolTip={true}
          message={rowData.createdBy}
          placement="top"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Image
              sx={{ width: "17px", height: "17px", borderRadius: "50%" }}
              src={
                rowData.createdByAvatarURL
                  ? addSizeToImage(rowData.createdByAvatarURL, "thumb")
                  : placeholder
              }
            />

            <TruncateText lineClamp={1}>{rowData.createdBy}</TruncateText>
            <Text color="#8F8F8F">
              &#40;
              {rowData.createdByRole.charAt(0).toUpperCase() +
                rowData.createdByRole.slice(1)}
              &#41;
            </Text>
          </Stack>
        </CustomToolTip>
      ),
    },
    {
      id: 4,
      key: "Last Used",
      value: rowData.lastUsedAt
        ? format(rowData.lastUsedAt * 1000, "MMM d, yyyy, p")
        : "-",
    },
    { id: 5, key: "Rate Limit", value: rowData.rateLimit || "1000 / Day" },
    {
      id: 6,
      key: "Allowed Origin",
      value: rowData.allowedOrigins.length
        ? rowData.allowedOrigins
            .map((item: string) => (item === "*" ? "All" : item))
            .join(", ")
        : "-",
    },
  ];

  const inputs = [
    { title: "Key", value: rowData.apiKey, label: "Key copied!" },
    { title: "Base URL", value: BASE_URL },
    ...(rowData.isSandbox ? [{ title: "Portal URL", value: PORTAL_URL }] : []),
  ];
  return (
    <Stack
      gap={2}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.1)",
        padding: "16px",
      }}
    >
      <Tooltip
        title={
          <Text
            lineHeight="120%"
            fontSize="12px"
            fontWeight="book"
            textAlign="center"
            color={palette.neutral[5]}
          >
            {rowData.name}
          </Text>
        }
        placement="top"
        bgColor={palette.neutral[80]}
        parentStyle={{
          display: "block",
        }}
      >
        <TruncateText
          lineClamp={1}
          data-testid={`api-key-${rowData.name}-summary`}
          sx={{
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "unset",
            letterSpacing: "-0.18px",
            wordBreak: "break-all",
          }}
        >
          {rowData.name}
        </TruncateText>
      </Tooltip>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          backgroundColor: "#ECECE9",
          padding: "4px 8px",
          width: "fit-content",
          borderRadius: "4px",
        }}
      >
        <Text>{rowData.isSandbox ? "Sandbox " : ""}Documentation</Text>
        <DocumentationIcon />
      </Stack>

      <Text color="#8F8F8F" fontSize="14px">
        {rowData.description}
      </Text>

      {inputs.map(({ title, value, label }, index) => {
        return (
          <StyledAPIKey key={index}>
            <Stack>
              <Text fontSize="12px" color="#8F8F8F">
                {title}
              </Text>
              <Text fontSize="14px" fontFamily="Oxygen Mono">
                {value}
              </Text>
            </Stack>
            <CopyButton text={value} label={label} testId={`api-key-panel-copy-${value}`} />
          </StyledAPIKey>
        );
      })}

      {rowData.isSandbox && (
        <Box
          sx={{
            backgroundColor: "#E6EAF2",
            padding: "12px 16px",
            borderRadius: "12px",
          }}
        >
          <Text color="#153263" fontSize="14px" sx={{ fontWeight: 357 }}>
            The credentials for accessing the sandbox environment are the same
            as the username and password of the creator’s account
          </Text>
        </Box>
      )}
      <Box>
        <Grid container rowSpacing="24px" columnSpacing="12px">
          {GridKeyValues.map(({ key, value, id }) => (
            <Grid item xs={6} sm={6} key={id}>
              <Stack gap={1}>
                <Text color="#8F8F8F" fontSize="14px" sx={{ fontWeight: 271 }}>
                  {key}
                </Text>
                {typeof value === "string" && (
                  <Text fontSize="14px">{value}</Text>
                )}
                {typeof value !== "string" && value}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export const StyledAPIKey = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  padding: "8px 16px",
  backgroundColor: "#ECECE9",
  borderRadius: "8px",
  "& div": {
    right: "8px",
    background: "inherit",
  },
}));

export default DAPanelContent;
