import { Image } from "@common/StyledImage/Image";
import { usePreviewImageContext } from "./PreviewImagesBody";
import { Box } from "@mui/material";
import { useZoomImage } from "@hooks/common/images/useZoomImage";

const PreviewedImageDesktop = () => {
  const { src, setIsZooming } = usePreviewImageContext();
  const {
    handleMouseLeave,
    handleMouseMove,
    zoomedElStyleProps,
    parentElZoomStyleProps,
    // To prevent zoom on hover
    handleClick,
  } = useZoomImage(setIsZooming, { safeElements: "preview-image-caption" });

  return (
    <Box
      flex={1}
      sx={{
        width: "100%",
        height: "520px",
        borderRadius: "8px",

        ...parentElZoomStyleProps,
      }}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <Image
        id={src}
        src={src}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          ...zoomedElStyleProps,
        }}
      />
    </Box>
  );
};

export default PreviewedImageDesktop;
