import { Text } from "@common/Text";
import { Box, BoxProps, Stack, styled } from "@mui/material";
import { palette } from "@palette";

type Props = {
  children: React.ReactNode;
  title: string;
  avatar?: React.ReactNode;
};

const SignupFormContainer = ({
  children,
  title,
  avatar,
  ...props
}: Props & BoxProps) => {
  return (
    <Box mt="20px" justifyContent="center" width="100%" display="flex">
      <CardContainer {...props}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Stack spacing={1}>
            <Text color={palette.black[100]}>Create account</Text>
            <Text component="h2" sx={titleStyle}>
              {title}
            </Text>
          </Stack>
          {avatar}
        </Box>
        {children}
      </CardContainer>
    </Box>
  );
};

const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "24px",
  borderRadius: "16px",
  background: "var(#E1E1DE, rgba(255, 255, 255, 0.20))",
  boxShadow: "0px 60px 180px 0px rgba(0, 0, 0, 0.10)",
  backdropFilter: "blur(20px)",
  border: "1px solid #FFF",
  maxWidth: "600px",

  my: 5,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "96%",
  },
}));

const titleStyle = {
  color: palette.black[100],
  fontSize: "28px",
  fontWeight: 300,
  "@media(max-width: 600px)": {
    fontSize: "24px",
  },
};

export default SignupFormContainer;
