import { namespaces } from "../../../resources/i18n.constants";

export const sweepstakes = {
  [namespaces.pages.sweepstakes]: {
    sweepstake: "sweepstake",
    sweepstakesTotal: "Sweepstakes Total",
    sweepstakes: "Sweepstakes",
    visitors: "Visitors",
    conversion: "Conversion",
    average: "Average",
    sweepstakesList: "Sweepstakes list",
    createSweepstake: "Create a Sweepstake",
    closedOn: "Closed on",
    amount: "Amount",
    conversionCapital: "Conversion",
    averageCapital: "Average",
    transactions: "Transactions",
    entriesSold: "Entries Sold",
    visitorsCapital: "Visitors",
    tripToZoo: "Trip to Zoo",
    winTripToLondon: "Win trip to London",
    runInThePark: "Run in the park",
    revenueSource: "Revenue Source",
    purchases: "Purchases",
    preview: "Preview",
    edit: "Edit",
    downloadReport: "Download Report",
    Type: "Type",
    Amount: "Amount",
    qty: "Qty",
    winnerSelected: "Winner Selected",
    sweepstakesCapital: "Sweepstakes",
    share: "Share",
    sweepstakeCapital: "Sweepstake",
    delete: "Delete",
    drawWinner: "Draw Winner",
    noEntriesSold: "No Entries Sold",
    viewWinner: "View Winner",
    developerApi: "Developer API",
    deleteSweepstake: "Delete Sweepstake",
    shareSweepstake: "Share Sweepstake",
    search: "Search...",
    type: "Type",
    totalEntries: "Total Entries",
    refund: "Refund",
    sendReceipt: "Send Receipt",
    more: "More",
    alreadyRefunded: "Already refunded",
    sendRefund: "Refund",
    modifyRecurring: "Modify Recurring",
    viewTransaction: "View Transaction",
    viewCustomer: "View Customer",
    winTrip: "Enter to win a trip to Paris!",
    fullName: "Full Name",
    email: "Email",
    transactionID: "Transaction ID",
    processed: "Processed",
    nameOnCard: "Name on Card",
    recurrence: "Recurrence",
    donated: "Donated",
    giveboxFee: "Givebox Fee",
    visaFee: "Visa Fee",
    charged: "Charged",
    customerPaidFee: "*Customer paid MASTERCARD fee",
  },
};
