import { parseAmount } from "@components/ManageMoney/TransactionTable/ExpandedRow_V2/PurchasesGrid";
import { palette } from "@palette";
import { TooltipLabelStyle } from "chart.js";
import moment from "moment";

const generalFont = {
  size: 12,
  weight: "normal",
  family: "Give Whyte, sans-serif",
  lineHeight: "14.4px",
};

export const customizeLabel = (label: string, value: string | number) => {
  const val = Number(value);
  if (isNaN(val)) {
    return value;
  }

  switch (label) {
    case "Volume":
    case "Average transaction":
    case "Chargebacks":
    case "Avg transactions (USD)":
      return parseAmount(val);
    case "Transactions":
    default:
      return value;
  }
};

export const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      boxWidth: 7,
      boxHeight: 7,
      boxPadding: 0,
      borderWidth: 0,
      position: "nearest" as const,
      xAlign: "left" as const,
      yAlign: "center" as const,
      padding: {
        left: 12,
        right: 12,
        top: 9,
        bottom: 9,
      },
      titleColor(ctx: any, options: any) {
        return palette.gray[300];
      },
      backgroundColor: palette.black[300],
      titleFont: generalFont,
      bodyFont: generalFont,
      cornerRadius: 6,
      caretSize: 0,
      callbacks: {
        title(tooltipItem: any) {
          const label = tooltipItem[0]?.label;
          const title = moment(new Date(label)).format("MMM DD");
          return title;
        },
        labelPointStyle(context: any) {
          return { pointStyle: "circle" as const, rotation: 0 };
        },
        labelTextColor(tooltipItem: any) {
          return palette.liftedWhite[300];
        },
        label(tooltipItem: any) {
          const label = tooltipItem?.dataset?.label;
          const value = tooltipItem?.parsed?.y || 0;
          return ` ${label}: ${customizeLabel(label, value)}`;
        },
        labelColor: function (context: any): TooltipLabelStyle {
          return {
            borderColor:
              context.dataset.borderColor ?? context.dataset.backgroundColor,
            backgroundColor:
              context.dataset.borderColor ?? context.dataset.backgroundColor,
            borderWidth: 1,
          };
        },
      },
    },
  },
  scales: {
    y: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      ticks: {
        crossAlign: "far" as const,
        color: "#8F8F8F" as const,
        font: generalFont,
      },
      grid: {
        drawTicks: false,
      },
    },
    x: {
      beginAtZero: true,
      type: "time" as const,
      position: "bottom" as const,
      ticks: {
        color: "#8F8F8F" as const,
        font: generalFont,
        maxTicksLimit: 12,
        beginAtZero: true,
      },
      grid: {
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
  },
};
