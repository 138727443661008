import React from "react";
import { Text } from "@common/Text";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import { Cell } from "./Cell";

export const Row = ({
  item,
  isSelected,
  columns,
  isScrolling,
  isBorderBottom,
}: any) => {
  const classes = useStyles({
    isSelected: isSelected,
  });

  return (
    <Grid
      container
      className={`${classes.tableRow} ${isScrolling ? "" : classes.hover}`}
    >
      {columns.map((column: any) => (
        <Cell
          isBorderBottom={isBorderBottom}
          key={`row-${column.key}`}
          renderColumn={
            column.renderColumn ? (
              column.renderColumn({ row: item })
            ) : (
              <Text color={palette.neutral[800]}>{item[column.key]}</Text>
            )
          }
          columnWidth={column.columnWidth}
          hideColumn={column?.hideColumn}
        />
      ))}
    </Grid>
  );
};

const useStyles = makeStyles({
  tableRow: ({ isSelected }: any) => ({
    ...(isSelected && {
      backgroundColor: palette.neutral.white,
    }),
    height: 60,
  }),
  hover: {
    cursor: "pointer",
    "&:hover ": {
      backgroundColor: palette.neutral.white,
    },
  },
});
