import { palette } from "@palette";
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { SUPPORTED_EXTENSIONS_EXPORT } from "../hooks/useExportTable";
import { ArrowDownChevron } from "@assets/icons";
import { HeaderContainer } from "./styles";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  handleClose: () => void;
};

const ExportViewHeader = ({ handleClose }: Props) => {
  const { isDesktopView } = useCustomTheme();
  return (
    <HeaderContainer>
      <ModalTitle
        title="Export Table"
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
            letterSpacing: "-0.18px",
          },
        }}
        closeButtonSize={24}
        onClose={isDesktopView ? handleClose : undefined}
      />
      <FadeUpWrapper delay={40}>
        <RHFInput
          label="File Name"
          name="fileName"
          placeholder="File Name"
          fullWidth
        />
      </FadeUpWrapper>
      <FadeUpWrapper delay={60}>
        <RHFSelect
          label="File Type"
          name="fileType"
          placeholder="File Type"
          fullWidth
          options={SUPPORTED_EXTENSIONS_EXPORT}
          DropIcon={
            <ArrowDownChevron
              height={20}
              width={20}
              color={palette.neutral[70]}
            />
          }
        />
      </FadeUpWrapper>
    </HeaderContainer>
  );
};

export default ExportViewHeader;
