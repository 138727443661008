import React from "react";

import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from "@mui/material";
import { Text } from "@common/Text";

import { palette } from "@palette";

// FormControlLabel

export type FormControlLabelProps = MuiFormControlLabelProps & {
  size?: "small" | "medium";
  helperText?: React.ReactNode;
};

export const FormControlLabel: React.FC<FormControlLabelProps> = ({
  size = "medium",
  className,
  label,
  helperText,
  ...rest
}) => {
  const classes = useStyles();

  const LabelComponent = (
    <Box>
      {typeof label === "string" ? (
        <Text variant={size === "small" ? "body" : "headline"}>{label}</Text>
      ) : (
        label
      )}

      {helperText && (
        <Text
          variant={size === "small" ? "caption" : "body"}
          color={palette.neutral[500]}
        >
          {helperText}
        </Text>
      )}
    </Box>
  );

  return (
    <MuiFormControlLabel
      className={`${classes.root} ${className}`}
      {...rest}
      label={LabelComponent}
    />
  );
};

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start !important",
    marginLeft: 0,
  },
});
