type Props = {
  width?: number;
  height?: number;
  stroke?: string;
  fill?: string;
};

const ShieldIcon = ({
  width = 20,
  height = 20,
  stroke = "#8F8F8F",
  fill = "none",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3881 4.15627L10.6306 2.11734C10.2281 1.94484 9.77253 1.94485 9.37005 2.11734L4.61253 4.15627C3.9646 4.43396 3.57474 5.10298 3.65259 5.8036L4.13727 10.1657C4.32553 11.8601 5.16207 13.4158 6.47169 14.5072L8.97603 16.5941C9.56938 17.0886 10.4313 17.0886 11.0246 16.5941L13.529 14.5072C14.8386 13.4158 15.6751 11.8601 15.8634 10.1657L16.3481 5.8036C16.4259 5.10298 16.0361 4.43396 15.3881 4.15627Z"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ShieldIcon;
