import React from "react";
import { Button } from "@common/Button";
import { ButtonProps } from "@common/Button/Button";
import { Text } from "@common/Text";
import { Box, Stack, SxProps, useMediaQuery, useTheme } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ArrowLeft } from "@assets/icons";
import { HiddenComponent } from "@containers/HiddenComponent";

export const ProfileSetupFormContainer = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      direction="column"
      flexGrow={1}
      gap={2}
      justifyContent="space-between"
      sx={{
        overflowY: "visible",
        overflowX: "hidden",
        flexGrow: 1,
        paddingTop: isDesktop ? "50px" : "20px",
        paddingBottom: "16px",
        paddingInline: "8px",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export const ProfileSetupFormTitle = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}) => {
  return (
    <Stack direction="column" gap={1} alignItems="flex-start">
      <Text
        color={palette.black[200]}
        variant="h1"
        lineHeight="67.2px"
        fontSize={{ xs: "32px", md: "56px" }}
        fontWeight="book"
        align="left"
        sx={{ letterSpacing: "-1.68px" }}
      >
        {title}
      </Text>
      <HiddenComponent hidden={!description}>
        <Text
          color={palette.gray[300]}
          fontWeight="regular"
          variant="body"
          fontSize={{ xs: "14px", md: "18px" }}
          lineHeight="24.3px"
          align="left"
        >
          {description}
        </Text>
      </HiddenComponent>
    </Stack>
  );
};

export const ProfileSetupFormActions = ({
  secondaryAction,
  primaryAction,
}: {
  secondaryAction?: ButtonProps;
  primaryAction: ButtonProps;
}) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Button
        {...secondaryAction}
        background="tertiary"
        size="medium"
        sx={{ minWidth: "32px", fontWeight: 400 }}
      >
        {secondaryAction?.children ? (
          secondaryAction.children
        ) : (
          <Box
            component="span"
            color={palette.black[100]}
            display="inline-flex"
            alignItems="center"
            gap="4px"
          >
            <ArrowLeft height={20} width={20} fill={palette.black[100]} /> Back
          </Box>
        )}
      </Button>

      <Button
        background="primary"
        size="medium"
        form="business-profile-form"
        type="submit"
        fullWidth={isMobileView}
        data-testid="primary-button-id"
        {...primaryAction}
        sx={{
          minWidth: "32px",
          fontWeight: 400,
          ...(primaryAction?.sx && primaryAction.sx),
        }}
      />
    </Stack>
  );
};
