import { BellRingingIcon } from "@assets/icons/RebrandedIcons";
import { Button } from "@common/Button";
import { ButtonProps } from "@common/Button/Button";
import { palette } from "@palette";

export const HeaderNotifyButton = ({ isEnterprise, ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      background="tertiary"
      size="medium"
      sx={{
        color: palette.givebox[600],
        fontWeight: 400,
        lineHeight: "16.8px",
        borderRadius: "8px",
        "&:hover": {
          textDecoration: "none",
          backgroundColor: palette.neutral[10],
        },
      }}
      data-testid="challenges-header-notify-button"
    >
      <BellRingingIcon height={20} width={20} color={palette.givebox[600]} />{" "}
      Notify {isEnterprise ? "Provider" : "Merchant"}
    </Button>
  );
};
