import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { stringToEditorState } from "@utils/draft.editor.helpers";
import { buildMerchantEndpoints } from "@services/api/utils.api";

export const useFindMembershipById = (
  id?: string,
  publicResource?: boolean,
) => {
  if (!id) return { data: undefined };

  const getMembership = (id: string) => {
    const publicUrl = `products/${id}?filter=typeName:"membership"`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const getMembershipVariants = (id: string) => {
    const publicUrl = `products/${id}/variants?&sort=displayOrder`;
    return customInstance({
      url: publicResource ? publicUrl : buildMerchantEndpoints(publicUrl),
      method: "GET",
    });
  };

  const { data, error, isLoading } = useQuery(
    "find-membership-by-id",
    async () => {
      const membership = await getMembership(id);
      const variants = await getMembershipVariants(id);
      return { membership, variants };
    },
    { cacheTime: 0 },
  );

  if (!data)
    return {
      error,
      isLoading,
      redirect: (error as any)?.response?.status === 404 ? true : false,
    };

  const allIntervals = ["once", "monthly", "quarterly", "yearly"];

  function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  const getRecurringIntervals = (subscription: any) => {
    return allIntervals.map((t) => {
      if (subscription.recurringIntervals?.includes(t)) {
        return {
          title: t === "once" ? "One-Time" : capitalizeFirstLetter(t),
          checked: true,
          isDefault: subscription.defaultRecurringIntervalName === t,
        };
      }
      return {
        title: t === "once" ? "One-Time" : capitalizeFirstLetter(t),
        checked: false,
        isDefault: false,
      };
    });
  };

  const customSubscriptionsList = data.variants?.data.map(
    (subscription: any) => {
      return {
        id: subscription.id,
        name: subscription.name,
        amount: (subscription.price / 100).toFixed(2),
        description: {
          enabled: Boolean(subscription.description),
          text: stringToEditorState(subscription.description),
        },
        thumbnail: subscription.imageURL ? subscription.imageURL : "",
        active: subscription.isEnabled,
        display: subscription.showAvailableVariants,
        in_stock: subscription.inventory?.toString(),
        paymentTypes: getRecurringIntervals(subscription),
        bundle: 1,
      };
    },
  );

  const customData = {
    general: {
      title: data?.membership?.name || "",
      description: data?.membership?.description || "",
      browseMore: data?.membership?.canBrowseCampaigns || false,
      creatorName: data?.membership?.merchantName,
      creatorImage: data?.membership?.merchantImageURL,
      creatorDescription: data?.membership?.merchantDescription,
      featuredImage: {
        image: data?.membership?.imageURL || "",
        active: Boolean(data?.membership?.imageURL),
        useAsBackground: data?.membership?.usesBackgroundImage || false,
      },
    },
    subscriptions: {
      customer_pays_credit: {
        active: data?.membership?.needsTax || false,
        optional: data?.membership?.allowFeeChoice || false,
        max_subscriptions:
          data?.membership?.maxPurchaseQuantity?.toString() || "5",
      },
      list: customSubscriptionsList,
    },
    accID: data?.membership?.accID,
  };

  return {
    data: customData,
    isLoading: isLoading,
    redirect: (error as any)?.response?.status === 404 ? true : false,
  };
};
