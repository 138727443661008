import React from "react";

type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
};

export const NewRefundIcon = ({
  stroke = "#8F8F8F",
  width = 20,
  height = 20,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.5 10L11.3216 12.5M9.5 15L11.3216 12.5M16.4952 10.625C17.3423 9.89138 17.6677 9.03596 17.4183 8.19807C17.169 7.36018 16.3595 6.58918 15.1219 6.01067C13.8843 5.43215 12.2914 5.0802 10.6026 5.01213C8.91388 4.94406 7.22879 5.16389 5.82181 5.63581C4.41483 6.10774 3.36887 6.80394 2.8543 7.61104C2.33972 8.41814 2.38684 9.28858 2.98799 10.0806C3.58914 10.8726 4.70889 11.5395 6.16487 11.9726C7.78871 12.4558 9.63078 12.5 11.3216 12.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
