type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const EditIconV2 = ({ width = 17, height = 17, fill = "#575353" }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7586 4.73262L12.268 1.24122C12.1519 1.12511 12.0141 1.03301 11.8624 0.970178C11.7107 0.907342 11.5482 0.875 11.384 0.875C11.2198 0.875 11.0572 0.907342 10.9056 0.970178C10.7539 1.03301 10.6161 1.12511 10.5 1.24122L0.866412 10.8756C0.749834 10.9912 0.657407 11.1289 0.594506 11.2806C0.531604 11.4323 0.499482 11.595 0.500006 11.7592V15.2506C0.500006 15.5821 0.631702 15.9001 0.866123 16.1345C1.10054 16.3689 1.41849 16.5006 1.75001 16.5006H5.24141C5.40563 16.5011 5.5683 16.469 5.71999 16.4061C5.87168 16.3432 6.00935 16.2508 6.12501 16.1342L15.7586 6.50059C15.8747 6.38452 15.9668 6.2467 16.0296 6.09503C16.0925 5.94335 16.1248 5.78078 16.1248 5.61661C16.1248 5.45243 16.0925 5.28986 16.0296 5.13819C15.9668 4.98651 15.8747 4.8487 15.7586 4.73262ZM5.24141 15.2506H1.75001V11.7592L8.62501 4.88419L12.1164 8.37559L5.24141 15.2506ZM13 7.49122L9.5086 4.00059L11.3836 2.12559L14.875 5.61622L13 7.49122Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIconV2;
