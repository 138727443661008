import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";

import Popup from "@common/Popup";
import { palette } from "@palette";

type Props = {
  handleClose: () => void;
};

const EnterpiseCreated = NiceModal.create(
  ({ handleClose }: Props): JSX.Element => {
    const modal = useModal();

    const handleModalClose = () => {
      modal.hide();
      handleClose();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        onSubmit={handleModalClose}
        actionLabel="Done"
        isLoading={false}
        submitButtonProps={{
          size: "medium",
        }}
        textProps={{
          fontSize: "20px",
          color: palette.neutral[80],
          fontWeight: "book",
        }}
      >
        Provider has been successfully created
      </Popup>
    );
  },
);

export default EnterpiseCreated;
