import { SvgIconProps } from "@mui/material";

const NewStarIcon = ({
  height = 16,
  width = 16,
  fill = "#403D3D",
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10862 1.37719C7.99511 1.31865 7.8387 1.35548 7.76493 1.51168L6.19597 4.8338C5.98797 5.27422 5.57881 5.59231 5.09066 5.66644L1.58236 6.19917C1.53048 6.20704 1.47797 6.23318 1.43483 6.27944C1.3192 6.4034 1.32237 6.6114 1.4399 6.73113L3.97854 9.31704C4.32022 9.66508 4.47027 10.1586 4.39182 10.6365L3.79253 14.2879C3.78066 14.3603 3.79231 14.4338 3.82356 14.4959C3.90038 14.6486 4.05663 14.6821 4.16961 14.62L7.30753 12.8961C7.74005 12.6585 8.25978 12.6585 8.6923 12.8961L11.8302 14.62C11.8798 14.6473 11.934 14.6556 11.9855 14.6464C12.1448 14.6178 12.2664 14.4482 12.2416 14.2898L11.9255 12.4676C11.8689 12.1411 12.0877 11.8305 12.4142 11.7739C12.7407 11.7173 13.0513 11.936 13.1079 12.2625L13.4255 14.0936C13.5613 14.9211 12.9812 15.687 12.1972 15.8275C11.8729 15.8857 11.5404 15.83 11.2524 15.6718L8.11449 13.9478C8.04181 13.9079 7.95802 13.9079 7.88534 13.9478L4.74742 15.6718C4.01268 16.0754 3.12043 15.7684 2.75158 15.0352C2.60537 14.7446 2.55578 14.414 2.60837 14.0936L3.20766 10.4422C3.2257 10.3323 3.18925 10.226 3.12222 10.1577L0.583584 7.57179C0.015304 6.99293 0.00444219 6.05363 0.557318 5.46091C0.780378 5.22178 1.07667 5.0622 1.4022 5.01277L4.91051 4.48004C4.98719 4.46839 5.0663 4.41577 5.11089 4.32135L6.67985 0.999222C7.02994 0.257944 7.91335 -0.0737083 8.65867 0.310679C8.95023 0.461051 9.18092 0.704789 9.31998 0.999222L10.8889 4.32135C10.9335 4.41577 11.0126 4.46839 11.0893 4.48004L14.5976 5.01277C15.4191 5.13751 15.946 5.91847 15.8352 6.71555C15.7905 7.03728 15.645 7.33877 15.4162 7.57179L13.9417 9.07377C13.7096 9.31024 13.3297 9.31374 13.0932 9.0816C12.8568 8.84946 12.8533 8.46957 13.0854 8.23311L14.5599 6.73113C14.6043 6.68596 14.6365 6.62295 14.6466 6.55028C14.6735 6.35694 14.5437 6.21834 14.4175 6.19917L10.9092 5.66644C10.421 5.59231 10.0119 5.27422 9.80386 4.8338L8.2349 1.51168C8.20517 1.44873 8.15905 1.4032 8.10862 1.37719Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewStarIcon;
