import { Eye, Note, Trash, Image } from "@phosphor-icons/react";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { ButtonBase, Stack, styled } from "@mui/material";
import { useFormContext } from "react-hook-form";
import NiceModal from "@ebay/nice-modal-react";
import {
  PAH_UPLOADER_PREVIEW_MODAL,
  DISPUTE_ADD_A_NOTE_MODAL,
} from "modals/modal_names";
import { TPreviewModalProps } from "@components/Merchants/MerchantPreview/components/PrimaryAccountHolder/PAHUploaderPreviewModal";
import { useMemo } from "react";
import { RespondToDisputeFormType, CaseFileType } from "../types";

const KILOBYTE = 1000;
const CHAR_LIMIT = 20;

interface IUploadedFileCard extends CaseFileType {
  index: number;
}

const UploadedFileCard = ({ file, note, index }: IUploadedFileCard) => {
  const { setValue, getValues } = useFormContext<RespondToDisputeFormType>();

  const shortenedFileName = useMemo(() => {
    const nameWithoutExtension = file.name.slice(0, file.name.lastIndexOf("."));
    const extension = file.name.slice(file.name.lastIndexOf("."));
    if (nameWithoutExtension.length <= CHAR_LIMIT) return file.name;
    return `${nameWithoutExtension.slice(0, CHAR_LIMIT)}...${extension}`;
  }, [file]);

  const handleDelete = () => {
    const uploadedFiles = [...getValues("caseFiles")];
    uploadedFiles.splice(index, 1);
    setValue("caseFiles", uploadedFiles);
  };

  const handlePreview = () => {
    const filesData = [
      {
        fileName: file.name,
        src: URL.createObjectURL(file),
        type: "",
        icon: (fill?: string) => <Image color={fill} />,
        title: "File",
      },
    ];

    NiceModal.show(PAH_UPLOADER_PREVIEW_MODAL, {
      filesData,
    } as TPreviewModalProps);
  };

  const handleSaveNote = (note: string, fileIndex: number) => {
    const updatedFiles = getValues("caseFiles").map((file, index) =>
      index === fileIndex
        ? {
            ...file,
            note: note,
          }
        : file,
    );
    setValue("caseFiles", updatedFiles);
  };

  const handleManageNote = () => {
    NiceModal.show(DISPUTE_ADD_A_NOTE_MODAL, {
      fileName: shortenedFileName,
      fileIndex: index,
      defaultNote: note,
      onSave: handleSaveNote,
    });
  };

  const actionIcons = [
    {
      Icon: Eye,
      onClick: handlePreview,
      hidden: !file.type.startsWith("image"),
    },
    {
      Icon: Note,
      onClick: handleManageNote,
    },
    {
      Icon: Trash,
      onClick: handleDelete,
    },
  ];

  return (
    <Container>
      <Text color={palette.neutral[80]} fontWeight="book" lineHeight="16.8px">
        {shortenedFileName}
      </Text>
      <Text
        fontSize={12}
        color={palette.neutral[70]}
        fontWeight="book"
        lineHeight="14.4px"
      >
        {convertFileSize(file.size)}
      </Text>

      {note && (
        <NoteContainer onClick={handleManageNote}>
          <Text
            color={palette.neutral[70]}
            fontWeight="light"
            lineHeight="16.8px"
          >
            Note
          </Text>
          <Text
            color={palette.neutral[80]}
            fontWeight="book"
            lineHeight="16.8px"
          >
            {note}
          </Text>
        </NoteContainer>
      )}

      <ActionIconsContainer id="file-card-icons-container">
        {actionIcons
          .filter(({ hidden }) => !hidden)
          .map(({ Icon, onClick }, index) => (
            <ActionIconButton key={`action-icon-${index}`} onClick={onClick}>
              <Icon size={18} color={palette.neutral[70]} />
            </ActionIconButton>
          ))}
      </ActionIconsContainer>
    </Container>
  );
};

const convertFileSize = (bytes: number) => {
  const units = ["bytes", "kb", "mb"];
  const index =
    bytes < KILOBYTE ? 0 : Math.floor(Math.log(bytes) / Math.log(KILOBYTE));
  const convertedSize = (bytes / Math.pow(KILOBYTE, index)).toFixed(1);
  return `${convertedSize} ${units[index]}`;
};

const Container = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  gap: theme.spacing(0.5),
  position: "relative",
  "&:hover": {
    backgroundColor: palette.neutral.white,
    "#file-card-icons-container": {
      opacity: 1,
      transition: "opacity 0.3s linear",
    },
  },
}));

const NoteContainer = styled(Stack)(({ theme }) => ({
  border: `1px solid ${palette.neutral[20]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  gap: theme.spacing(0.5),
  cursor: "pointer",
}));

const ActionIconsContainer = styled(Stack)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  borderRadius: theme.spacing(1),
  backgroundColor: palette.neutral.white,
  flexDirection: "row",
  gap: theme.spacing(1.5),
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  boxShadow: "0px 4px 8px 0px #00000014",
  opacity: "0",
}));

const ActionIconButton = styled(ButtonBase)({
  "&:hover": {
    backgroundColor: palette.gray[50],
    borderRadius: "4px",
  },
});

export default UploadedFileCard;
