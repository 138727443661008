import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { Box, Stack } from "@mui/material";
import { ReactNode } from "react";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";

const ImagesStacker = ({
  data,
  hasBorderedImages,
  borderColor = "white",
  condition,
  renderAdditional,
  imageWidth = 20,
}: {
  data: string[];
  hasBorderedImages?: boolean;
  borderColor?: string;
  condition?: boolean;
  renderAdditional?: () => ReactNode;
  imageWidth?: number;
}) => {
  return (
    <Stack
      direction="row"
      spacing={-1.5}
      sx={{ overflow: "hidden" }}
      data-testid="Image-Stacker"
    >
      {data?.map((avatar, avatarIndex) => {
        return (
          <Box
            key={avatarIndex}
            sx={{
              width: `${imageWidth}px`,
              height: `${imageWidth}px`,
              borderRadius: "50%",
              overflow: "hidden",
              border: hasBorderedImages ? `2px solid ${borderColor}` : "none",
            }}
          >
            <img
              src={avatar ? addSizeToImage(avatar, "small") : AvatarPlaceholder}
              alt={`Avatar ${avatarIndex + 1}`}
              width={imageWidth}
              height={imageWidth}
              style={{
                maxWidth: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        );
      })}

      {condition && renderAdditional && renderAdditional()}
    </Stack>
  );
};

export default ImagesStacker;
