import { showMessage } from "@common/Toast";
import useCheckLastUnseen from "@components/VirtualList/hooks/useBlockedTransactions";
import { useGetCurrentMerchantId } from "@hooks/common";
import {
  TBlockTransaction,
  blockTransaction,
} from "@services/api/riskProfile/riskProfileActions";
import { checkPortals } from "@utils/routing";
import { useMutation, useQueryClient } from "react-query";

export const useMarkAsFraudActions = () => {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { setUnseenTransactions } = useCheckLastUnseen(merchantId);
  const { isAcquirerPortal } = checkPortals();

  const markAsFraudMutation = useMutation(
    (data: TBlockTransaction) => {
      return blockTransaction({
        merchantId: data.merchantId,
        id: data.id,
        reason: data.reason,
        canNotifyMerchant: data.canNotifyMerchant,
        skipRefund: data.skipRefund,
      });
    },
    {
      onSuccess: () => {
        if (isAcquirerPortal) setUnseenTransactions();
        queryClient.refetchQueries("get-transactions-risk-profile");
        queryClient.refetchQueries("get-risk-transactions-list");
        queryClient.refetchQueries("has-more-transactions");
        queryClient.refetchQueries("acquirer-processing-transactions");
        queryClient.refetchQueries("get-transaction-history");
        showMessage(
          "Success",
          "purchase have been successfully Marked as Fraud",
        );
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.message || "Whoops.. an error occured. Please try again",
        );
      },
    },
  );

  return { markAsFraudMutation };
};
