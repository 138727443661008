import { CornersBorderIcon } from "@assets/analyticsIcons";
import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import { IconButton } from "@common/IconButton";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { checkPortals } from "@utils/routing";

interface ActionsHeaderProps {
  handleClose?: () => void;
  handleExpandPanel?: () => void;
  isExpanded: boolean;
  isEmpty: boolean;
}

const ActionsHeader = ({
  handleClose,
  handleExpandPanel,
  isExpanded,
  isEmpty,
}: ActionsHeaderProps) => {
  const { isManageMoney } = checkPortals();
  return (
    <ActionsContainer>
      <StyledButton onClick={handleClose} size="small">
        <CustomArrowRight stroke={palette.black[100]} />
      </StyledButton>
      {isManageMoney && handleExpandPanel && !isEmpty && (
        <StyledButton onClick={handleExpandPanel}>
          {isExpanded ? "Reduce View" : "Expand View"} <CornersBorderIcon />
        </StyledButton>
      )}
    </ActionsContainer>
  );
};

const ActionsContainer = styled(Stack)(() => ({
  flexDirection: "row",
  padding: "8px 0px",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledButton = styled(IconButton)(() => ({
  border: "none",
  boxShadow: "none",
  background: "inherit",
  width: "fit-content !important",
  color: palette.black[100],
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "16.8px",
  padding: "2px 8px !important",
  height: "24px !important",

  "& > span": {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  "&:hover": {
    background: palette.neutral[20],
    boxShadow: "none",
  },
  "&:disabled": {
    background: "transparent",
    boxShadow: "none",
  },
}));

export default ActionsHeader;
