import { useCallback, useEffect, useState } from "react";
import { CustomTextRenderer } from "react-pdf/dist/cjs/shared/types";

const highlightPattern = (text: string, pattern: string) => {
  const regEx = new RegExp(pattern, "ig");
  return text.replace(regEx, (value) => `<mark>${value}</mark>`);
};

const useTextSearch = () => {
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const toggleSearch = (open: boolean) => {
    if (!open) setSearchText("");
    setIsSearchActive(open);
  };

  const textRenderer: CustomTextRenderer = useCallback(
    (textItem) => highlightPattern(textItem.str, searchText),
    [searchText],
  );

  const onSearch = (newValue: string) => setSearchText(newValue);

  useEffect(() => {
    return () => {
      setIsSearchActive(false);
      setSearchText("");
    };
  }, []);

  return {
    isSearchActive,
    toggleSearch,
    textRenderer,
    searchText,
    onSearch,
  };
};

export default useTextSearch;
