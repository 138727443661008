import { capitalizeFirstLetter } from "@common/Table/helpers";
import { Section } from "@components/Merchants/CreateMerchantPanel/types";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import {
  parsePhoneNumber,
  parseTaxID,
} from "@components/Merchants/MerchantPreview/hooks/useGetSectionItems";
import { formatAddress } from "@utils/helpers";
import { parseAmount } from "@utils/index";

import moment from "moment";

export const BANK_DETAILS = [
  {
    name: "RS2 Software INC",
    addr1: "4643 S Ulster St Ste 1285 Denver,",
    addr2: "CO, 80237-3090 United States",
  },
  {
    name: "Chesapeake Bank",
    addr1: "97 N. Main Street",
    addr2: "Kilmarnock, VA 22482",
  },
];

export const merchantApplicationSection = (
  data?: Partial<IParsedData>,
): Section[] => {
  const lastBankAccount =
    data?.bankAccountList?.[data?.bankAccountList?.length - 1];

  return [
    {
      "Merchant Info": {
        "Merchant Name": {
          value: data?.merchantInfo?.merchantName,
          size: 6,
        },
        "Merchant Category": {
          value:
            data?.merchantInfo?.categoryCodeName &&
            `${data?.merchantInfo?.category} - ${data?.merchantInfo?.categoryCodeName}`,
          size: 6,
        },
        "Billing Descriptor": {
          value: data?.merchantInfo?.billingDescriptor,
          size: 6,
        },
        "Website URL": {
          value:
            data?.merchantInfo && `https://${data?.merchantInfo?.websiteURL}`,
          size: 6,
        },
        "Estimated Annual Revenue": {
          value:
            (data?.merchantInfo?.estimatedAnnualRevenue
              ? (+data?.merchantInfo?.estimatedAnnualRevenue).toLocaleString()
              : "0") + " USD",
          size: 6,
        },
        "Average Ticket Amount": {
          value:
            data?.merchantInfo?.averageTicketAmount &&
            parseAmount(+data?.merchantInfo?.averageTicketAmount) + " USD",
          size: 6,
        },
        "High Ticket Amount": {
          value:
            data?.merchantInfo?.highTicketAmount &&
            parseAmount(+data?.merchantInfo?.highTicketAmount) + " USD",
          size: 6,
        },
        "Customer Service": {
          value: parsePhoneNumber(data?.merchantInfo?.servicePhoneNumber),
          size: 6,
        },
        "Countries Serviced Outside the USA/Canada": {
          value: data?.merchantInfo?.countriesServicedOutside
            ? data?.merchantInfo?.countriesServicedOutside
            : "None",
          size: 6,
        },
        "Purpose or Mission of Merchant": {
          value:
            data?.merchantInfo?.businessPurpose ||
            data?.merchantInfo?.description,
          size: 12,
        },
      },
    },
    {
      "Business Profile": {
        "Business Legal Name": {
          value: data?.businessProfile?.legalName,
          size: 6,
        },
        "DBA (Doing Business As)": {
          value: data?.businessProfile?.dba,
          size: 6,
        },
        "Business Type": {
          value: capitalizeFirstLetter(
            data?.businessProfile?.businessType as string,
          ),
          size: 6,
        },
        "Tax ID": {
          value: parseTaxID(data?.businessProfile?.taxID as string),
          size: 6,
        },
        "Contact Phone": {
          value: parsePhoneNumber(data?.businessProfile?.contactPhone),
          size: 6,
        },
        "Age of Merchant": {
          value: data?.businessProfile?.ageOfBusiness
            ? `${data?.businessProfile?.ageOfBusiness} year${
                (+data?.businessProfile?.ageOfBusiness || 0) > 1 ? "s" : ""
              }`
            : "",
          size: 6,
        },
        "Business Address": {
          value: formatAddress(data?.businessAddress),
          size: 12,
        },
      },
    },
    {
      "Bank Account": {
        "Bank Name": { value: lastBankAccount?.bankName, size: 6 },
        "Account Type": { value: lastBankAccount?.type, size: 6 },
        "Account Name": { value: lastBankAccount?.name, size: 6 },
        "Account Number": { value: lastBankAccount?.accountNumber, size: 6 },
        "Routing Number": { value: lastBankAccount?.routingNumber, size: 6 },
      },
    },
    {
      "Primary Account Holder": {
        "Full name": {
          value:
            data?.primaryAccountHolder &&
            `${data?.primaryAccountHolder?.firstName || ""} ${
              data?.primaryAccountHolder?.lastName || ""
            }`,
          size: 6,
        },
        Email: { value: data?.primaryAccountHolder?.email, size: 6 },
        "Date of Birth": {
          value: data?.primaryAccountHolder?.dateOfBirth
            ? moment
                .unix(data?.primaryAccountHolder?.dateOfBirth?.toString())
                .format("MMMM DD YYYY")
            : "",
          size: 6,
        },
        "Phone number": {
          value: data?.primaryAccountHolder?.phoneNumber,
          size: 6,
        },
      },
    },
    {
      "Business Owners": !data?.businessOwnersList?.length
      ?
      [
        {
          "Full name": {
            value: '',
            size: 6,
          },
          Email: { value: '', size: 6 },
          Ownership: {
            value: '',
            size: 6,
          },
          "Tax ID": {
            value: "",
            size: 6,
          },
          "Date of Birth": {
            value: "",
            size: 6,
          },
          "Phone number": { value: "", size: 6 },
          Address: {
            value: "",
            size: 12,
          },
      }]
      :
      data?.businessOwnersList?.map((owner) => (
        {
          "Full name": {
            value:
              owner &&
              `${owner?.firstName} ${owner?.lastName}`,
            size: 6,
          },
          Email: { value: owner?.email, size: 6 },
          Ownership: {
            value: owner?.ownership
              ? owner?.ownership + "%"
              : "",
            size: 6,
          },
          "Tax ID": {
            value:
              owner?.tinType === "ssn"
                ? owner.ssn
                : owner?.ein,
  
            size: 6,
          },
          "Date of Birth": {
            value:
              owner &&
              moment(owner?.dob).format("MM/DD/YYYY"),
            size: 6,
          },
          "Phone number": { value: owner?.phone, size: 6 },
          Address: {
            value: owner?.isBusinessAddress
              ? formatAddress(data?.businessAddress)
              : formatAddress(owner?.address),
            size: 12,
          },
        }
      ))
    },
  ];
};
type FeeScheduleSectionProps = {
  label: string;
  fee?: number;
  feePerTransaction: string;
};

export const feeScheduleSection = (
  data?: FeeScheduleSectionProps[],
): Section[] => [
  {
    "Transaction Fees": {
      "Credit Card Transaction": {
        value: data?.[0]?.feePerTransaction
          ? `${data?.[0]?.feePerTransaction} per transaction`
          : "",
        size: 6,
      },
      "Debit Card Transaction": {
        value: data?.[1]?.feePerTransaction
          ? `${data?.[1]?.feePerTransaction} per transaction`
          : "",
        size: 6,
      },
      "AMEX Transaction": {
        value: data?.[2]?.feePerTransaction
          ? `${data?.[2]?.feePerTransaction} per transaction `
          : "",
        size: 6,
      },
    },
  },
  {
    "Monthly Account Fees": {
      "Monthly Account ": { value: "Monthly Account Fees", size: 6 },
    },
  },
  {
    "Gateway Access Fees": {
      "Gateway Access Fees": { value: "$0.00 per month", size: 6 },
      "Per Transaction Gateway": { value: "$0.00 per transaction", size: 6 },
    },
  },
  {
    "Chargeback Fees": {
      Chargeback: { value: "$15.00 per chargeback", size: 6 },
    },
  },
  {
    "Miscellaneous Fees": {
      Setup: { value: "$0.00", size: 6 },
      "PCI Compliance": { value: "$0.00 per year", size: 6 },
      "Early Termination": { value: "$0.00", size: 6 },
    },
  },
];

export const MAX_TEXT_AREA_LENGTH = 256;
