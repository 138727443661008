import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { useAddSignature as useAddSignatureOld } from "@components/ProfilePage/MerchantAgreementSetup/hooks/useUpdateUser";

import { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddSignature as useAddSignatureNew } from "@hooks/upload-api/useAddSignature";
import { customInstance } from "@services/api";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";

export type TMerchantAgreementPayload = {
  msaRefundPolicy: string;
  msaPCICompliant: boolean;
  msaPreviousTermination: boolean;
  msaPreviousTerminationProcessorName: string;
  msaPreviousTerminationReason: string;
  msaPreviousTerminationAt: number | Date | null;
  msaHadAgreed: boolean;
  msaLastAcceptedVersion?: string;
  msaVersion?: string;
  msaLastAcceptedAt?: number;
  tcVersion?: string;
  signatureURL?: string;
  approvedPAH?: boolean;
  signatureFile?: File | any;
  signartureUrl?: string;
  signedBy?: string;
};

export const yupRequiredOnCondition = (msg: string) => {
  return Yup.string().when("msaPreviousTermination", {
    is: (value: any) => value,
    then: Yup.string().required(msg),
    otherwise: Yup.string(),
  });
};

export const useAddMerchantAgreement = (
  onClose?: (data: TMerchantAgreementPayload) => void,
  data?: TMerchantAgreementPayload,
  merchantId?: number,
) => {
  const { hide } = useModal();
  const queryClient = useQueryClient();

  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();

  const { handleUploadSignature: handleUploadSignatureNew } =
    useAddSignatureNew();
  const { handleUploadSignature: handleUploadSignatureOld } =
    useAddSignatureOld();
  const handleUploadSignature = isFileUploadRefactorEnabled
    ? handleUploadSignatureNew
    : handleUploadSignatureOld;
  const { agreement_signing } = useEnterprisePermissions();

  const defaultMsaHadAgreed =
    data?.msaHadAgreed && data.tcVersion === data?.msaLastAcceptedVersion;

  const defaultMsaPCICompliant =
    data?.msaPCICompliant && data.tcVersion === data?.msaLastAcceptedVersion;

  const getDefaultValuesFromData = (data: TMerchantAgreementPayload) => {
    return {
      ...data,
      msaHadAgreed: defaultMsaHadAgreed,
      msaPCICompliant: defaultMsaPCICompliant,
      msaPreviousTerminationAt:
        typeof data.msaPreviousTerminationAt === "number"
          ? new Date(data.msaPreviousTerminationAt * 1000)
          : data.msaPreviousTerminationAt || null,
    };
  };

  const defaultValues = data
    ? getDefaultValuesFromData(data)
    : merchantAgreementDefaultValues;
  const methods = useForm<TMerchantAgreementPayload>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const updateMerchantInfoMutation = useMutation({
    mutationFn: (data: any) => {
      return customInstance({
        url: `/merchants/${merchantId}`,
        method: "PATCH",
        data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["get-merchant-preview", merchantId]);
    },
  });

  const {
    msaPCICompliant,
    msaRefundPolicy,
    msaPreviousTermination,
    msaPreviousTerminationProcessorName,
    msaPreviousTerminationReason,
    msaPreviousTerminationAt,
    signatureFile,
  } = methods.watch();

  const handleSubmit = async () => {
    const payload = {
      msaPCICompliant,
      msaRefundPolicy,
      msaPreviousTermination,
      signatureFile,
    } as any;

    if (msaPreviousTermination) {
      payload.msaPreviousTerminationProcessorName =
        msaPreviousTerminationProcessorName;
      payload.msaPreviousTerminationReason = msaPreviousTerminationReason;
      payload.msaPreviousTerminationAt = msaPreviousTerminationAt;
    }
    let signatureURL = null;
    if (signatureFile && merchantId) {
      signatureURL = await handleUploadSignature(
        merchantId,
        signatureFile,
        false,
      );
    }
    const { signatureFile: signature, ...payloadWithoutSignatureFile } =
      payload;
    const msaHadAgreed = Boolean(signatureFile);
    if (merchantId) {
      await updateMerchantInfoMutation.mutateAsync({
        ...payloadWithoutSignatureFile,
        msaHadAgreed,
        msaPreviousTerminationAt: msaPreviousTerminationAt
          ? toUnixDateFormat(new Date(payload.msaPreviousTerminationAt))
          : null,
        signatureURL,
      });
      queryClient.invalidateQueries("get-merchant-msp-status");
    }
    onClose?.({ ...payload, msaHadAgreed });
    hide();
  };

  const test = {
    msaPCICompliant: msaPCICompliant === true,
    msaRefundPolicy: msaRefundPolicy?.length > 0,
    msaPreviousTerminationProcessorName: msaPreviousTermination
      ? Boolean(msaPreviousTerminationProcessorName)
      : true,
    msaPreviousTerminationReason: msaPreviousTermination
      ? Boolean(msaPreviousTerminationReason)
      : true,
    msaPreviousTerminationAt: msaPreviousTermination
      ? Boolean(msaPreviousTerminationAt)
      : true,
  };

  const isFirstSigningValid = !data?.msaHadAgreed && !!signatureFile; // if signing for first time-> signature is mandatory

  return {
    methods,
    handleSubmit,
    getDefaultValuesFromData,
    isSubmitButtonDisabled:
      !Object.values(test).every(Boolean) ||
      !agreement_signing ||
      !isFirstSigningValid,
  };
};

export const merchantAgreementDefaultValues = {
  msaRefundPolicy: "30 Days of Purchase",
  msaPCICompliant: true,
  msaPreviousTermination: false,
  msaPreviousTerminationProcessorName: "",
  msaPreviousTerminationReason: "",
  msaPreviousTerminationAt: null,
  msaHadAgreed: false,
};

export const schema = Yup.object().shape({
  msaRefundPolicy: Yup.string().required("RefundPolicy is required"),
  msaPCICompliant: Yup.boolean(),
  msaPreviousTermination: Yup.boolean(),
  msaPreviousTerminationProcessorName: yupRequiredOnCondition(
    "Previous termination processor name is required",
  ),
  msaPreviousTerminationReason: yupRequiredOnCondition(
    "Reason for Termination is required",
  ),
  msaPreviousTerminationAt: Yup.date().when("msaPreviousTermination", {
    is: (value: any) => value,
    then: Yup.date().required("is required"),
    otherwise: Yup.date(),
  }),
  msaHadAgreed: Yup.boolean().oneOf([true], "You must agree to the terms"),
  signatureFile: Yup.mixed()
    .notRequired()
    .test(
      "is-file",
      "The file is required",
      (value) => !value || value instanceof File,
    ),
});
