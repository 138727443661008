import { ArrowDownSquare } from "@assets/icons";
import { Text } from "@common/Text";
import { getCardBrandIcon } from "@sections/Checkout/Payment/inputs/CardNumberInput"; 
import { shouldBeHidden } from "@constants/constants";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Box, Stack, styled } from "@mui/material";

import { palette } from "@palette";
import { parseAmount } from "@utils/index";
import moment from "moment";

export const ChargedAmountCell = ({
  data,
  width,
}: {
  data: number;
  width: number;
}) => {
  return (
    <Text
      color={palette.black[100]}
      lineHeight="16.8px"
      width={`${width}%`}
      align="right"
    >
      {parseAmount(data)} USD
    </Text>
  );
};

export type TCardInformationProps = {
  cardBrand: string;
  last4: string;
  cardHolder: string;
};

export const CardInformation = ({ data }: { data: TCardInformationProps }) => {
  const { cardBrand, last4, cardHolder } = data;
  const Icon = getCardBrandIcon(cardBrand.toUpperCase(), false);
  return (
    <Stack direction="row" alignItems="center" gap="8px">
      {Icon}
      <Text color={palette.black[100]} lineHeight="16.8px">
        {`•••• ${last4}`}
      </Text>
      <Text color={palette.black[100]} lineHeight="16.8px">
        {cardHolder}
      </Text>
    </Stack>
  );
};

export type TEmailInformationProps = {
  isValid: boolean;
  email: string;
};

export const EmailInformation = ({
  data,
}: {
  data: TEmailInformationProps;
}) => {
  const { isValid, email } = data;
  return (
    <Text color={palette.black[100]} lineHeight="16.8px" fontWeight="book">
      {email} {isValid && <ValidEmailTag>Valid</ValidEmailTag>}
    </Text>
  );
};

const ValidEmailTag = styled("span")(() => ({
  padding: "2px 12px",
  borderRadius: "100px",
  backgroundColor: "rgba(19, 167, 90, 0.10)",
  color: "#13A75A",
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
}));

type CardAndEmailInformationType = {
  data: {
    card: TCardInformationProps;
    email: TEmailInformationProps;
  };
  width: number;
};

export const CardAndEmailInformation = ({
  data,
  width,
}: CardAndEmailInformationType) => {
  return (
    <Stack direction="column" width={`${width}%`} alignItems="flex-start">
      <CardInformation data={data?.card} />
      <EmailInformation data={data?.email} />
    </Stack>
  );
};

export const formatTransactionDate = (date: Date) =>
  moment(date).format("MMM. DD YYYY, HH:mm");

export const TransactionDate = ({
  data,
  width,
  customStyle,
}: {
  data: Date;
  width: number;
  customStyle: any;
}) => {
  const formattedDate = formatTransactionDate(data);
  return (
    <Text
      color={palette.gray[300]}
      lineHeight="16.8px"
      width={`${width}%`}
      align="left"
      {...customStyle}
    >
      {formattedDate}
    </Text>
  );
};

export const MerchantName = ({
  data: merchantName,
  width,
}: {
  data: string;
  width: number;
}) => {
  return (
    <Text
      color={palette.black[100]}
      lineHeight="16.8px"
      fontWeight="book"
      width={`${width}%`}
      align="left"
    >
      {merchantName}
    </Text>
  );
};

export const RiskDeEscalation = ({
  data: { triggerReason, risklevel },
  width,
}: {
  data: any;
  width: number;
}) => {
  return (
    <Stack direction="row" alignItems="center" width={`${width}%`} gap="12px">
      <ArrowDownSquare />
      <Stack direction="column">
        <Text
          color="#326EC5"
          lineHeight="16.8px"
          fontWeight="book"
          fontSize="18px"
        >
          Risk de-escalation{" "}
          <HiddenComponent hidden={shouldBeHidden.deescalationLevel}>
            <Box
              display="inline-block"
              fontWeight="regular"
              fontSize="18px"
              component="span"
            >
              Level {risklevel}
            </Box>
          </HiddenComponent>
        </Text>
        <Text color="#326EC5" lineHeight="16.8px" fontWeight="book">
          {triggerReason}
        </Text>
      </Stack>
    </Stack>
  );
};
