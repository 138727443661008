import { Axios, AxiosInstance, AxiosRequestConfig } from "axios";
import { axiosInstance } from "../index";
import { customInstance } from "../index";
import { useQuery } from "react-query";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { ProductParams, useQueryFactory } from "./queryFactory";
import { buildMerchantEndpoints } from "../utils.api";

export const getInvoices = ({
  queryString,
  page,
  sorting,
  searchQuery,
}: ProductParams) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  return customInstance({
    url: buildMerchantEndpoints(
      `products?filter=typeName:"invoice"${queryString}&sort=${
        sorting ?? "-id"
      }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    ),
    method: "GET",
  });
};

export const useGetInvoices = useQueryFactory("invoice");

export const getProductById = (id: string) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}?filter=typeName:"invoice"`),
    method: "GET",
  });
};

export const getInvoicesVariants = (id: number) => {
  return customInstance({
    url: buildMerchantEndpoints(`products/${id}/variants`),
    method: "GET",
  });
};

export const useGetEventVariants = (id: number) => {
  return useQuery("event-variants", async () => {
    const data = await getInvoicesVariants(id);
    return data;
  });
};

export const useGetInvoiceById = (id: string) => {
  return useQuery(
    ["get-invoice-by-id", id],
    async () => {
      const data = await getProductById(id);
      return data;
    },
    { cacheTime: 0, refetchOnWindowFocus: false },
  );
};
