import { Image } from "@common/StyledImage/Image";
import { Box } from "@mui/material";
import { GiveCorporationLogo } from "@assets/icons/RebrandedIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

type Props = {
  senderName?: string;
  senderLogo?: string;
  isSendingToProvider: boolean;
};
const PreviewInvitationModalHeader = ({
  senderName = "",
  senderLogo,
  isSendingToProvider,
}: Props) => {
  const { isMobileView } = useCustomTheme();
  const imgSrc =
    senderLogo?.slice(-5).toLowerCase() === "thumb"
      ? senderLogo
      : `${senderLogo}/original`;
  return (
    <Box marginTop={isMobileView ? "60px" : "0"}>
      {senderLogo && !isSendingToProvider ? (
        <Image alt={senderName} src={imgSrc} width="94px" height="94px" />
      ) : (
        <Box>
          <GiveCorporationLogo />
        </Box>
      )}
    </Box>
  );
};

export default PreviewInvitationModalHeader;
