import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";
import { selectMasqueradeMode } from "@redux/slices/app";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";

type ReturnedType = {
  merchantId: number;
  name?: string;
  img?: string;
  createdAt?: Date | number;
  isControlMode: boolean;
  selectedUser: ReturnType<typeof selectSelectedAccount>;
  masqFirstName?: string;
};

const useGetCurrentMerchantId = (): ReturnedType => {
  const selectedUser = useAppSelector(selectSelectedAccount);
  const {
    id: masqID,
    name: masqName,
    imgSrc: masqImg,
    firstName: masqFirstName,
  } = useAppSelector(selectMasqueradeMode);
  let merchantId = selectedUser?.id || 0;

  if (masqName && typeof masqID === "number") {
    merchantId = masqID;
  }

  const name = masqName ? masqName : selectedUser?.name;
  const { img } = selectedUser || {};
  const blobImg = img && img?.includes("blob") ? img : "";
  const imgAvatar = masqImg || blobImg;

  return {
    merchantId,
    name,
    img:
      imgAvatar && imgAvatar?.includes("blob")
        ? imgAvatar
        : imgAvatar && imgAvatar?.includes("https")
        ? addSizeToImage(imgAvatar, "thumb")
        : undefined,
    selectedUser,
    isControlMode: Boolean(masqName && masqID),
    masqFirstName,
  };
};

export default useGetCurrentMerchantId;
