import { customInstance } from "..";
import { buildMerchantEndpoints } from "../utils.api";
import { productTypeIDs } from "./campaigns";

interface IParams {
  filter?: string;
}

export const getTransactionStats = (params?: IParams) => {
  const baseURL = "transactions/stats";
  let URL = baseURL;
  if (params?.filter) URL += "?";
  if (params?.filter) URL += params.filter;

  return customInstance({
    url: buildMerchantEndpoints(URL),
    method: "GET",
  });
};

interface ChartParams {
  filter?: string;
  productId?: number;
  productType?: string;
  interval?: string;
  chartType?: string;
}

export const getTransactionChart = (params?: ChartParams) => {
  const productTypeId = params?.productType
    ? productTypeIDs[params.productType as keyof typeof productTypeIDs]
    : "";
  const chartInterval = params?.interval || "day";
  const baseURL = `transactions/chart?interval=${chartInterval}`;
  let URL = baseURL;
  if (params?.filter) URL += `&${params.filter}`;
  if (params?.productId) URL += `&product_id=${params.productId}`;
  if (productTypeId) URL += `&product_type_id=${productTypeId}`;
  if (params?.chartType) URL += `&chart=${params.chartType}`;

  return customInstance({
    url: buildMerchantEndpoints(URL),
    method: "GET",
  });
};

type TData = {
  accID: number;
  date: Date;
  transactionItemCount: number;
  sumPurchases: number;
  averagePurchases: number;
  totalChargebacks: number;
  settledTransactionItemCount: number;
};
type Values = keyof Omit<
  TData,
  "date" | " accID" | "settledTransactionItemCount"
>;

const mainChartKeys: Record<string, string> = {
  Transactions: "transactionItemCount",
  Volume: "sumPurchases",
  Chargebacks: "totalChargebacks",
  "Avg transactions (USD)": "averagePurchases",
};

const labels = [
  "Transactions",
  "Volume",
  "Chargebacks",
  "Avg transactions (USD)",
];

export const transactionsChartNormalizer = (trim: boolean, data: TData[]) => {
  if (!data) return labels.map((label) => ({ label, entries: [] }));

  let index = 0;

  if (trim) {
    index = data.findIndex((el) => {
      const keys = Object.values(mainChartKeys);
      const emptyValues = keys.filter((v) => el[v as Values] === 0);
      return keys.length !== emptyValues.length;
    });
  }

  const trimmedData = index > 0 ? data?.slice(index, data.length - 1) : data;

  return labels.map((label) => ({
    label,
    entries: trimmedData.map((chartData: TData) => {
      const value = chartData[mainChartKeys[label] as Values];
      return {
        x: chartData.date,
        y:
          label === "Volume" || label === "Avg transactions (USD)"
            ? value / 100
            : value,
      };
    }),
  }));
};

export const settledTransactionsNormalizer = (
  data: TData[],
  year: number,
  changeDate = false,
) => {
  const label = `Payments year ${year}`;
  if (!data) return { label, entries: [] };

  const entries = data.map((entry) => ({
    x: changeDate ? convertYear(entry.date) : entry.date,
    y: entry.settledTransactionItemCount,
  }));
  return { label, entries };
};

const convertYear = (date: any) => {
  const d = new Date(date);
  return new Date(d.setFullYear(new Date().getFullYear()));
};
