import { Text } from "@common/Text";
import { TStatusReview } from "@components/Merchants/MerchantPreview/data.types";
import { Box, Stack } from "@mui/material";
import UnderwritingAssignment from "../UnderwritingAssignment";
import { palette } from "@palette";
import { capitalize } from "lodash";

// creating separate component for now, so it can easily becontrolled with ff
export const EnterpriseActionsHistory = ({ data }: { data: TStatusReview }) => {
  const isStatusNameCanonic = ["declined", "suspended", "approved"].includes(
    data.statusName,
  );

  if (isStatusNameCanonic) {
    return (
      <Box
        paddingY={2}
        sx={{
          background: palette.neutral.white2,
          padding: "16px 24px",
          borderRadius: "16px",
        }}
      >
        <Stack spacing={2} sx={{ pointerEvents: "none" }}>
          <ActionHistoryItem
            data={data}
            updatedAt={data.statusUpdatedAt}
            status={data.statusName}
            underwriterID={data.statusUpdatedByID}
          />
          {data.statusName === "suspended" && data.approvedAt && (
            <ActionHistoryItem
              data={data}
              updatedAt={data.approvedAt}
              status="approved"
              underwriterID={data.approvedBy}
            />
          )}
        </Stack>
      </Box>
    );
  } else return null;
};

export const MerchantActionsHistory = ({ data }: { data: TStatusReview }) => {
  const isSponsorStatusCanonic =
    (data.sponsorStatusName &&
      ["declined", "approved"].includes(data.sponsorStatusName)) ||
    false;

  const isStatusNameCanonic =
    ["declined", "suspended"].includes(data.statusName) ||
    isSponsorStatusCanonic;

  const status = isSponsorStatusCanonic
    ? data.sponsorStatusName
    : data.statusName;

  const updatedAt = isSponsorStatusCanonic
    ? data.sponsorStatusLastUpdatedAt
    : data.statusUpdatedAt;
  const underwriterID = isSponsorStatusCanonic
    ? data.sponsorStatusLastUpdatedById
    : data.statusUpdatedByID;

  if (isStatusNameCanonic) {
    return (
      <Box
        paddingY={2}
        sx={{
          background: palette.neutral.white2,
          padding: "16px 24px",
          borderRadius: "16px",
        }}
      >
        <Stack spacing={2} sx={{ pointerEvents: "none" }}>
          <ActionHistoryItem
            data={data}
            updatedAt={updatedAt as string}
            status={status as string}
            underwriterID={underwriterID as number}
            isSponsorStatusCanonic={isSponsorStatusCanonic}
          />
          {status === "suspended" && data.approvedAt && (
            <ActionHistoryItem
              data={data}
              updatedAt={data.approvedAt}
              status="approved"
              underwriterID={data.approvedBy}
            />
          )}
        </Stack>
      </Box>
    );
  } else return null;
};

type ActionHistoryItemProps = {
  data: TStatusReview;
  status: string;
  underwriterID: number;
  updatedAt: string;
  isSponsorStatusCanonic?: boolean;
};

const ActionHistoryItem = ({
  data,
  status,
  underwriterID,
  updatedAt,
  isSponsorStatusCanonic,
}: ActionHistoryItemProps) => {
  const isApproved = status === "approved";
  const underwriterName = isSponsorStatusCanonic
    ? data?.sponsorStatusLastUpdatedByName ||
      data?.sponsorStatusLastUpdatedByEmail
    : data?.statusUpdatedByName || data?.statusUpdatedByEmail;

  const underwriterImageURL = isSponsorStatusCanonic
    ? data?.sponsorStatusLastUpdatedByImageURL
    : data?.statusUpdatedByImageURL;

  return (
    <Stack direction="row" alignItems="flex-start">
      <Stack
        direction="column"
        justifyContent="space-between"
        flex={1}
        width="100%"
        gap={1}
      >
        <Text
          color={palette.neutral[80]}
          width="95px"
          data-testid="action-history-item-text"
        >
          <span style={{ fontWeight: 700 }}>{capitalize(status)}</span> by
        </Text>
        <UnderwritingAssignment
          data={{
            accID: data.accID,
            parentAccID: data.parentAccID,
            underwriterID: underwriterID,
            underwriterSetByAcquirer: data.underwriterSetByAcquirer,
            statusName: status,
            underwriterName: isApproved
              ? data?.approvedByName
              : underwriterName,
            underwriterImageURL: isApproved
              ? data?.approvedByImageUrl
              : underwriterImageURL || data?.underwriterImageURL,
          }}
        />
      </Stack>
      <Box flexGrow={1} />

      <Text alignSelf="center" color={palette.neutral[70]} fontWeight="book">
        {updatedAt}
      </Text>
    </Stack>
  );
};
