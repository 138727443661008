import { parseCssPropToStyleAttr } from "@pages/NewAdvancedBuilder/components/SidePanelContent/SingleImageWidget/helpers";
import { getDeviceTypePropertyName } from "@pages/NewAdvancedBuilder/utils/helpers";

const imageWithTextRootStyles = {
  margin: "0",
  display: "grid",
  width: "100%",
  paddingLeft: "0",
};

const imageWithTextImageStyles = (state: any) => {
  return {
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: state.imageSrc,
    paddingLeft: "0",
    ...parseCssPropToStyleAttr(state.backgroundSize),
  };
};

const imageWithTextContentWrapperStyles = (state: any) => {
  return {
    display: "flex",
    justifyContent: state.yAlignment,
    alignItems: "center",
    flexDirection: "column",
  };
};

export const imageWithTextRootBlueprint = () => ({
  id: 2,
  tag: "div",
  tabletTag: "div",
  mobileTag: "div",
  bindings: {
    "metaAttributes.data-hidden": "state.dataHidden",
  },
  childrens: [],
  stateParser: ({ state }: any) => ({
    [getDeviceTypePropertyName("metaAttributes")]: {
      "data-hidden": state.dataHidden,
    },
    style: {
      ...imageWithTextRootStyles,
      display: "grid",
      gridTemplateColumns: "repeat(100, 1fr)",
    },
    tabletStyle: {
      ...imageWithTextRootStyles,
      minHeight: "300px",
      display: "grid",
      gridTemplateRows: "repeat(100, 1fr)",
    },
    mobileStyle: {
      ...imageWithTextRootStyles,
      minHeight: "300px",
      display: "grid",
      gridTemplateRows: "repeat(100, 1fr)",
    },
  }),
});

export const imageWithTextImageContainerBlueprint = () => ({
  id: 100,
  tag: "div",
  tabletTag: "div",
  mobileTag: "div",
  bindings: {
    "style.gridRow": "state.imageSize",
    "style.gridColumn": "state.imageSize",
    "style.backgroundSize": "state.backgroundSize",
    "style.order": "state.imagePosition",
  },
  stateParser: ({ state }: any) => ({
    style: {
      width: "100%",
      paddingLeft: "0",
      height: "300px",
      gridColumn: `span ${state.imageSize}`,
      order: state.imagePosition == 1 ? "1" : "2",
    },
    tabletStyle: {
      width: "100%",
      paddingLeft: "0",
      order: state.imagePosition == 1 ? "1" : "2",
      gridRow: `span ${state.imageSize}`,
    },
    mobileStyle: {
      width: "100%",
      paddingLeft: "0",
      order: state.imagePosition == 1 ? "1" : "2",
      gridRow: `span ${state.imageSize}`,
    },
  }),
});

export const imageWithTextImageBlueprint = () => ({
  id: 10,
  tag: "div",
  tabletTag: "div",
  mobileTag: "div",
  bindings: {
    "style.backgroundImage": "state.imageSrc",
    "style.backgroundSize": "state.backgroundSize",
  },
  stateParser: ({ state }: any) => ({
    style: {
      ...imageWithTextImageStyles(state),
      height: "100%",
    },
    tabletStyle: {
      ...imageWithTextImageStyles(state),
      height: "100%",
    },
    mobileStyle: {
      ...imageWithTextImageStyles(state),
      height: "100%",
    },
  }),
});

export const imageWithTextContentWrapperBlueprint = () => ({
  id: 11,
  tag: "div",
  tabletTag: "div",
  mobileTag: "div",
  /*
     To manage style values derived from the state value, we should prepend the path with $derived. 
     This ensures that when a node is clicked and we set selectedItem, 
     we correctly update the state value rather than the derived value, 
     which does not represent the accurate state. 
     This issue arises because nodes with derived values are processed later during node iteration. 
     However, we must also observe changes made through the side panel, which is why we don't remove these bindings.

    Important Note: 
      This approach is necessary when multiple nodes have style values dependent on a single state value. 
      If only one node is involved, we don't encounter the issue of values being overwritten.
    */
  bindings: {
    "style.flex": "state.textContainerSize",
    "style.justifyContent": "state.yAlignment",
    "$derived.style.gridRow": "state.imageSize",
    "$derived.style.gridColumn": "state.imageSize",
    "$derived.style.order": "state.imagePosition",
  },
  stateParser: ({ state }: any) => ({
    style: {
      ...imageWithTextContentWrapperStyles(state),
      overflow: "hidden",
      order: state.imagePosition == 1 ? "2" : "1",
      gridColumn: `span ${100 - Number(state.imageSize)}`,
      paddingLeft: state.imagePosition == 1 ? "24px" : "",
      paddingRight: state.imagePosition == 1 ? "" : "24px",
    },
    tabletStyle: {
      ...imageWithTextContentWrapperStyles(state),
      order: state.imagePosition == 1 ? "2" : "1",
      overflow: "hidden",
      gridRow: `span ${100 - Number(state.imageSize)}`,
      paddingTop: state.imagePosition == 1 ? "24px" : "",
      paddingBottom: state.imagePosition == 1 ? "" : "24px",
    },
    mobileStyle: {
      ...imageWithTextContentWrapperStyles(state),
      order: state.imagePosition == 1 ? "2" : "1",
      overflow: "hidden",
      gridRow: `span ${100 - Number(state.imageSize)}`,
      paddingTop: state.imagePosition == 1 ? "24px" : "",
      paddingBottom: state.imagePosition == 1 ? "" : "24px",
    },
  }),
});

export const imageWithTextHeadingBlueprint = () => ({
  id: 12,
  tag: "h2",
  tabletTag: "h2",
  mobileTag: "h2",
  stateParser: ({ state }: any) => ({
    style: {
      textAlign: state.headingTextAlign,
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    tabletStyle: {
      textAlign: state.headingTextAlign,
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    mobileStyle: {
      textAlign: state.headingTextAlign,
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    content: state.headingContent,
    tabletContent: state.headingContent,
    mobileContent: state.headingContent,
  }),
  bindings: {
    content: "state.headingContent",
    "style.textAlign": "state.headingTextAlign",
  },
});

export const imageWithTextDescriptionBlueprint = () => ({
  id: 13,
  tag: "p",
  tabletTag: "p",
  mobileTag: "p",
  stateParser: ({ state }: any) => ({
    style: {
      textAlign: state.descriptionContentTextAlign,
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    tabletStyle: {
      textAlign: state.descriptionContentTextAlign,
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    mobileStyle: {
      textAlign: state.descriptionContentTextAlign,
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    content: state.descriptionContent,
    tabletContent: state.descriptionContent,
    mobileContent: state.descriptionContent,
  }),

  bindings: {
    content: "state.descriptionContent",
    "style.textAlign": "state.descriptionContentTextAlign",
  },
});
