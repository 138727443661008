import { ChevronRight } from "@assets/icons/RebrandedIcons";
import { TDocument } from "@common/FilePreview/types";
import { StyledIconButton } from "@common/IconButton";
import { RHFInput } from "@common/Input";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { Text } from "@common/Text";
import { Tag } from "@components/Merchants/MerchantPreview/components/BusinessOwners/BusinessOwnerUploadList";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { useFilePermissions } from "@hooks/common/documents";
import { updateFile } from "@hooks/common/documents/utils";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { TToolbarAction } from "../layout/types";

type FormInputs = {
  tag: string;
};

type DrawerProps = {
  open: boolean;
  handleClose: () => void;
  file: TDocument;
  actions: TToolbarAction[];
  merchantID: number;
  merchantType: "acquirer" | "enterprise" | "merchant";
};

const DocumentMobileDrawer = ({
  handleClose,
  open,
  file,
  actions,
  merchantID,
  merchantType,
}: DrawerProps) => {
  const [tab, setTab] = useState<"" | "edit">("");
  const { tag, id, name } = file;
  const allActions = useRef<TToolbarAction[]>([]);
  const currentTag = useRef<string>(tag || "");
  const queryClient = useQueryClient();
  const refetcher = () => {
    queryClient.refetchQueries("get-merchant-preview");
    queryClient.refetchQueries("get identification files");
  };

  const { isUpdateAllowed } = useFilePermissions(
    merchantType === "merchant" ? false : true,
  );

  const defaultValues = {
    tag: "",
  };
  const methods = useForm<FormInputs>({
    defaultValues,
  });
  const { reset, getValues } = methods;

  useEffect(() => {
    if (tag) reset({ tag });
  }, []);

  const editAction = {
    label: "edit tag",
    onClick: () => setTab("edit"),
    content: <></>,
    disabled: !isUpdateAllowed,
  };

  useEffect(() => {
    if (actions.length > 0) {
      allActions.current = [
        actions[0],
        editAction,
        ...actions.slice(1, actions.length),
      ];
    } else {
      allActions.current = [editAction];
    }
  }, [actions]);

  const submitHandler: SubmitHandler<FormInputs> = async (data) => {
    await updateFile(merchantID, id, { tag: data.tag });
    currentTag.current = getValues("tag");
    refetcher();
  };

  const extensionRegex = /\.[^/.]+$/;
  const extension = name.match(extensionRegex);
  const fileName = extension ? name.replace(extension[0], "") : name;

  return (
    <SwipeableDrawerMobile
      anchor="bottom"
      onOpen={() => undefined}
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 2000 }}
      PaperProps={{
        sx: {
          background: palette.neutral[5],
          top: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "32px 16px",
        },
      }}
    >
      {!tab && (
        <>
          {tag && <Tag data-testid={"preview-drawer-tag"} width="fit-content">{currentTag.current}</Tag>}
          <Text
            color={palette.black[100]}
            variant="h6"
            lineHeight="21.6px"
            fontWeight="book"
            letterSpacing="-0.18px"
            marginTop="16px"
            marginBottom="24px"
            sx={{ wordBreak: "break-all" }}
            data-testid="preview-drawer-filename"
          >
            {fileName}
            {extension}
          </Text>
          {allActions.current.map((action: TToolbarAction) => {
            const { label, disabled, onClick, hidden } = action;
            if (hidden) return;
            return (
              <WithTooltipWrapper
                key={label}
                hasTooltip={!!action.tooltipProps}
                tooltipProps={action.tooltipProps}
                fullWidth
              >
                <MobileAction
                  isDelete={label === "delete"}
                  onClick={disabled ? undefined : onClick}
                  data-testid={`preview-drawer-${label.replace(/\s+/, "-")}`}
                >
                  {capitalizeFirstLetter(label)}
                </MobileAction>
              </WithTooltipWrapper>
            );
          })}
        </>
      )}
      {tab === "edit" && (
        <>
          <MobileHeader label="Edit tag" handleBack={() => setTab("")} />
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={methods.handleSubmit(submitHandler)}
              sx={{ mt: "24px" }}
            >
              <RHFInput
                name="tag"
                label="Tag"
                placeholder="Enter the tag"
                fullWidth
                autoFocus
                onBlur={() => methods.handleSubmit(submitHandler)()}
              />
            </Box>
          </FormProvider>
        </>
      )}
    </SwipeableDrawerMobile>
  );
};

const MobileAction = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDelete",
})<{ isDelete?: boolean }>(({ isDelete }) => ({
  padding: "4px 8px",
  borderRadius: "4px",
  color: isDelete ? palette.filled.red : palette.black[100],
  fontSize: "14px",
  fontWeight: 350,
  lineHeight: "32px",
  width: "100%",
  marginBottom: "12px",
  backgroundColor: palette.neutral["white"],
  "&:active": {
    backgroundColor: palette.liftedWhite[100],
  },
}));

interface IMobileHeader {
  handleBack: () => void;
  label: string;
}

export const MobileHeader = ({ handleBack, label }: IMobileHeader) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <StyledIconButton
        data-testid="edit-back-button"
        onClick={handleBack}
        sx={{
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "4px",
          padding: "0 !important",
        }}
      >
        <ChevronRight
          rotate={180}
          width={28}
          height={28}
          stroke={palette.gray[300]}
        />
      </StyledIconButton>
      <Text
        color={palette.black[100]}
        variant="h6"
        lineHeight="21.6px"
        fontWeight="book"
        letterSpacing="-0.18px"
      >
        {label}
      </Text>
    </Stack>
  );
};

export default DocumentMobileDrawer;
