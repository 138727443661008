import useUserReducer from "@hooks/Reducers/useUserReducer";

import { Avatar, styled } from "@mui/material";
import { StyledIconButton } from "@common/IconButton";
import { IStyleIconButtonProps } from "@common/IconButton/IconButton";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";

function ProfilePicture(
  props: Omit<IStyleIconButtonProps, "children"> & {
    pointerEventsNone?: boolean | undefined;
  },
) {
  const { img } = useUserReducer();
  return (
    <StyledProfileButton
      {...props}
      pointerEventsNone={Boolean(props?.pointerEventsNone)}
    >
      <Avatar
        src={addSizeToImage(img, "thumb")}
        sx={{ width: "32px", height: "32px" }}
      />
    </StyledProfileButton>
  );
}

export default ProfilePicture;

const StyledProfileButton = styled(StyledIconButton)(
  ({ pointerEventsNone = false }: { pointerEventsNone: boolean }) => ({
    borderRadius: "50%",
    pointerEvents: pointerEventsNone ? "none" : "auto",
    border: "3px solid transparent",
    padding: "0 !important",
    height: "fit-content !important",
    width: "fit-content !important",
    "&:hover": {
      borderColor: `#B8B8B8`,
    },
  }),
);
