import { Select } from "@common/Select";
import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { KotoChevronDown } from "@assets/icons";

type Props = {
  handleChangeDuration: any;
  handleChangeSubDuration: any;
  duration: any;
  subDuration: any;
  isDesktop: boolean;
  arr: any;
  column: string;
  addToCartHandler: any;
  formattedDate: any;
};

const CardOptions = ({
  handleChangeDuration,
  duration,
  subDuration,
  isDesktop,
  column,
  addToCartHandler,
  handleChangeSubDuration,
  arr,
}: Props) => {
  const options = arr.map((type: any) => {
    return {
      value: type.title || type,
      label: type.title || type,
    };
  });

  return (
    <>
      {isDesktop ? (
        <Select
          shouldDisplayIcon={arr.length > 1}
          DropIcon={<KotoChevronDown />}
          onChange={(e) => {
            e.preventDefault();
            column !== "Duration"
              ? handleChangeDuration(e.target.value, addToCartHandler)
              : handleChangeSubDuration(e.target.value, addToCartHandler);
          }}
          value={column !== "Duration" ? duration : subDuration}
          fullWidth
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "90px",
              border: "none",
              color: palette.neutral.black,
              width: "100%",
              minWidth: "100%",
              paddingLeft: 0,
              paddingRight: 0,
              cursor: "default",
            },
            "& .MuiSelect-select": {
              padding: "0 !important",
              flexGrow: 1,
            },
          }}
          options={options}
          isOptionNonClickable={options?.length < 2}
        />
      ) : (
        <>
          {arr.map((type: any, key: number) => {
            const title = type.title || type;
            const isDurationColumn = column === "Duration";
            const isActive = title === duration || title === subDuration;

            const sanitize = (str: string) => {
              return str.replace(/[A-Za-z]/g, "");
            };

            return (
              <Box
                key={key}
                my="8px"
                py="8px"
                px={
                  !isDurationColumn || key === arr.length - 1 ? "8px" : "12px"
                }
                onClick={(e: any) => {
                  !isDurationColumn
                    ? handleChangeDuration(title, addToCartHandler)
                    : handleChangeSubDuration(title, addToCartHandler);
                }}
                sx={{
                  ...(isActive && {
                    outline: `1px solid ${palette.neutral[30]}`,
                    borderRadius: "4px",
                    backgroundColor: "#FBFBFB",
                  }),
                }}
              >
                <Text width="100%" fontSize={12} align="center">
                  {isDurationColumn && key !== arr.length - 1
                    ? sanitize(title)
                    : title}
                </Text>
              </Box>
            );
          })}
        </>
      )}
    </>
  );
};

export default CardOptions;
