import React, { useEffect } from "react";
import CardHeader, { column } from "./CardHeader";
import { TableData } from "@common/Table/helpers";
import { TableOrder } from "@common/Table/helpers";
import { useAppDispatch } from "@redux/hooks";
import { updateSorting } from "@redux/slices/fundraisers";

const useSorting = () => {
  const dispatch = useAppDispatch();

  const [sortKey, setSortKey] = React.useState<null | string>(null);
  const [order, setOrder] = React.useState<TableOrder>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof TableData>("id");

  const createSortHandler = (property: any, order: TableOrder) => {
    setOrder(order);
    setOrderBy(property);
  };

  const onSort = (column: any) => {
    let newOrder: TableOrder = "asc";

    if (sortKey === null) {
      newOrder = "asc";
    } else {
      if (sortKey !== column) {
        newOrder = "asc";
      } else {
        newOrder = order === "asc" ? "desc" : "asc";
      }
    }

    setSortKey(column);
    createSortHandler(sortKey, newOrder);

    dispatch(updateSorting(newOrder === "asc" ? column : `-${column}`));
  };

  useEffect(() => {
    setSortKey("createdAt");
    setOrder("desc");
    setOrderBy("createdAt");
    dispatch(updateSorting("-createdAt"));
  }, []);

  return {
    order,
    orderBy,
    onSort,
    sortKey,
  };
};

export const CardHeaderWithSort = ({ columns }: { columns: column[] }) => {
  const { onSort, order, sortKey } = useSorting();

  return (
    <CardHeader
      columns={columns}
      handleOpenMenu={onSort}
      order={order}
      sortKey={sortKey as string}
    />
  );
};
