import { ArrowUpIconV2 } from "@assets/icons/RebrandedIcons";
import { ButtonBase } from "@mui/material";
import useNodeRearrange from "@pages/NewAdvancedBuilder/hooks/useNodeRearrange";
import { useState } from "react";

const ArrowUpIconButton = () => {
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const { isDisabled, handleClick } = useNodeRearrange({ actionType: "Up" });

  return (
    <ButtonBase
      disableRipple
      onClick={handleClick}
      onMouseEnter={() => setBackgroundColor("#0000000D")}
      onMouseLeave={() => setBackgroundColor("transparent")}
      sx={{
        backgroundColor: isDisabled ? "transparent" : backgroundColor,
        padding: "8px",
        borderRadius: "8px",
        transition: "background-color 0.2s",
      }}
    >
      <ArrowUpIconV2 opacity={isDisabled ? 0.5 : 1} />
    </ButtonBase>
  );
};

export default ArrowUpIconButton;
