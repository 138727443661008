import { namespaces } from "../../../resources/i18n.constants";

export const fundraisers = {
  [namespaces.pages.fundraisers]: {
    fundraisersTotal: "Fundraisers total",
    events: "Events",
    visitors: "Visitors",
    conversion: "Conversion",
    Average: "Average",
    createFundraiser: "Create Fundraiser",
    search: "Search...",
    fundraiser: "Fundraiser",
    fundraisers: "Fundraisers",
    amount: "Amount",
    average: "Average",
    transactions: "Transactions",
    ticketsSold: "Tickets Sold",
  },
};
