import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { Stack } from "@mui/material";
import MarkAllAsOkModalContent from "@components/Merchants/MerchantPreview/components/MarkAllAsOkModalContent";
import { FormProvider, useForm } from "react-hook-form";
import MarkAllAsOkModalFooter from "@components/Merchants/MerchantPreview/components/MarkAllAsOkModalFooter";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const DEFAULT_VALUES = {
  reason: "",
  description: "",
};

const REQUIRED_TEXT = "Required";

const schema = Yup.object().shape({
  reason: Yup.string()
    .required(REQUIRED_TEXT)
    .test("reason", REQUIRED_TEXT, (value) => !!value?.trim()),
  description: Yup.string()
    .required(REQUIRED_TEXT)
    .test("description", REQUIRED_TEXT, (value) => !!value?.trim()),
});

export interface MarkAllAsOkModalProps {
  merchantId: number;
  profileId: number;
  escalationIds: number[];
}

const MarkAllAsOkModal = NiceModal.create(
  ({ merchantId, profileId, escalationIds }: MarkAllAsOkModalProps) => {
    const modal = useModal();
    const { isMobileView } = useCustomTheme();
    const form = useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      defaultValues: DEFAULT_VALUES,
      resolver: yupResolver(schema),
    });

    const handleCancel = () => modal.hide();
    return (
      <ModalFactory
        variant="dialog"
        renderMobile={isMobileView}
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 541,
        }}
      >
        <FormProvider {...form}>
          <Stack p={3} gap="20px">
            <ModalTitle
              title="Mark all triggers as OK"
              description="You are about to mark all triggers related to this merchant as OK. Please provide a reason and description for this action. This action will be logged in the conversation history and the triggers can be reinstated individually if needed."
              onClose={handleCancel}
            />
            <MarkAllAsOkModalContent />
            <MarkAllAsOkModalFooter
              onCancel={handleCancel}
              merchantId={merchantId}
              profileId={profileId}
              escalationIds={escalationIds}
            />
          </Stack>
        </FormProvider>
      </ModalFactory>
    );
  },
);

export default MarkAllAsOkModal;
