import { memo } from "react";
import BottomButtons from "./BottomButtons";
import { Box, Grid, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import PaymentTypeCard, { CardType } from "./PaymentTypeCard";
import usePaymentTypes from "../hooks/usePaymentTypes";
import { Switch_V2 } from "@common/Switch";
import { Checkbox } from "@mui/material";
import { TPaymentTypes } from "../types";

interface Props {
  handleBack: () => void;
  backToWelcomePage: () => void;
  handleUpdateStatusValue: (value: number) => void;
  disabled: boolean;
}

function PaymentTypes({
  handleBack,
  disabled,
  handleUpdateStatusValue,
  backToWelcomePage,
}: Props) {
  const {
    selectedArray,
    handleSelect,
    handleNext,
    toggleOptional,
    toggleIsCreditCardFee,
    isCreditCardFee,
    isOptional,
    isLoading,
  } = usePaymentTypes({
    handleUpdateStatusValue,
    backToWelcomePage,
  });

  return (
    <>
      <Box width="100%">
        <Text color={palette.neutral[80]} align="left" fontSize={14}>
          Payment types
        </Text>
        <Text color={palette.neutral[70]} align="left" fontSize={14}>
          Choose the payment types available to your customers
        </Text>

        <Grid mt={4} container spacing={1.2}>
          {paymentTypesArray.map((type) => (
            <PaymentTypeCard
              selected={selectedArray.some(
                (data: CardType) => data.name === type.name,
              )}
              key={type.name}
              type={type}
              handleSelectType={handleSelect}
            />
          ))}
        </Grid>
        <Stack
          boxShadow={1}
          bgcolor="#FBFBFB"
          mt={3}
          width="100%"
          flexDirection="row"
          padding="10px 20px"
          borderRadius={2}
          justifyContent="space-between"
        >
          <Stack>
            <Switch_V2
              checked={isCreditCardFee}
              disabled={false}
              onChange={toggleIsCreditCardFee}
            />
            <Text fontSize={14} color="#575353" mt={2}>
              Customer pays the credit card fee
            </Text>
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            borderLeft="0.5px solid #575353"
            paddingLeft={2}
            sx={{ opacity: !isCreditCardFee ? 0.5 : 1 }}
          >
            <Checkbox
              checked={isOptional}
              name="isOptional"
              color="secondary"
              disabled={!isCreditCardFee}
              onChange={toggleOptional}
              onClick={toggleOptional}
              sx={{
                width: "42px",
                height: "42px",
                input: {
                  visibility: "hidden",
                },
                "input:checked + svg": {
                  borderRadius: "6px",
                  backgroundColor: palette.neutral[950],
                },
                // cursor: "not-allowed",
                "& .MuiSvgIcon-root .innerBox": {
                  backgroundColor: "transparent",
                },
              }}
            />
            <Text fontSize="14px" color="#575353">
              Make it optional
            </Text>
          </Box>
        </Stack>
      </Box>
      <BottomButtons
        nextText="Finish"
        goBack={() => {
          handleBack();
        }}
        goNext={handleNext}
        isLoading={isLoading}
        disabled={disabled}
      />
    </>
  );
}

export default memo(PaymentTypes);

export const paymentTypesArray: {
  name: TPaymentTypes;
  description: string;
  BE_label: string;
}[] = [
  {
    name: "One-time",
    description: "A one-time payment that never repeats",
    BE_label: "once",
  },
  {
    name: "Monthly",
    description: "A payment that repeats every month on the same date",
    BE_label: "monthly",
  },
  {
    name: "Quarterly",
    description: "A payment that repeats every 3 months on the same date",
    BE_label: "quarterly",
  },
  {
    name: "Yearly",
    description: "A payment that repeats every year on the same date",
    BE_label: "yearly",
  },
];
