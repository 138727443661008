import { Text } from "./Text";
import { styled } from "@mui/material";
import { palette } from "@palette";

export const StyledMainLinearColorText = styled(Text)(() => ({
  background: palette.gradient[10],
  fontWeight: "light",
  width: "fit-content",
  backgroundClip: "text",
  textFillColor: "transparent",
  WebkitBackgroundClip: "text",
}));
