import { SvgIconProps } from "@mui/material";

const NewBusinessOwnershipIcon = ({
  height = 20,
  width = 17,
  fill = "#575353",
}: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M22.5 10.5L19.125 14.25H3.75C3.55109 14.25 3.36032 14.171 3.21967 14.0303C3.07902 13.8897 3 13.6989 3 13.5V7.5C3 7.30109 3.07902 7.11032 3.21967 6.96967C3.36032 6.82902 3.55109 6.75 3.75 6.75H19.125L22.5 10.5Z"
        fill="#575353"
      />
      <path
        d="M23.0625 9.99844L19.6875 6.24844C19.6166 6.16963 19.5298 6.10674 19.4329 6.06392C19.3359 6.0211 19.231 5.99931 19.125 6H12.75V3C12.75 2.80109 12.671 2.61032 12.5303 2.46967C12.3897 2.32902 12.1989 2.25 12 2.25C11.8011 2.25 11.6103 2.32902 11.4697 2.46967C11.329 2.61032 11.25 2.80109 11.25 3V6H3.75C3.35218 6 2.97064 6.15804 2.68934 6.43934C2.40804 6.72064 2.25 7.10218 2.25 7.5V13.5C2.25 13.8978 2.40804 14.2794 2.68934 14.5607C2.97064 14.842 3.35218 15 3.75 15H11.25V21C11.25 21.1989 11.329 21.3897 11.4697 21.5303C11.6103 21.671 11.8011 21.75 12 21.75C12.1989 21.75 12.3897 21.671 12.5303 21.5303C12.671 21.3897 12.75 21.1989 12.75 21V15H19.125C19.2302 15 19.3342 14.9779 19.4303 14.9351C19.5264 14.8923 19.6125 14.8298 19.6828 14.7516L23.0578 11.0016C23.1823 10.8644 23.2517 10.6861 23.2525 10.5009C23.2534 10.3157 23.1857 10.1367 23.0625 9.99844ZM18.7913 13.5H3.75V7.5H18.7913L21.4913 10.5L18.7913 13.5Z"
        fill="#575353"
      />
    </svg>
  );
};

export default NewBusinessOwnershipIcon;
