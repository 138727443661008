import { Text } from "@common/Text";
import { palette } from "@palette";
import { isMobile } from "@utils/index";

export type ModalSectionTitleProps = {
  title: string;
  titleSize?: "large" | "medium";
  fontSize?: number | string;
  mobileFontSize?: number | string;
};

const ModalSectionTitle = ({
  title,
  titleSize,
  fontSize,
  mobileFontSize,
}: ModalSectionTitleProps) => {
  if (titleSize === "large") {
    return <Text sx={lgStyle}>{title}</Text>;
  }

  return <Text sx={mediumStyle}>{title}</Text>;
};

const lgStyle = {
  color: palette.black[100],
  fontSize: 28,
  lineHeight: "120%",
  letterSpacing: "-0.24px",
  fontWeight: "350",
  textAlign: "left",
  "@media(max-width:600px)": {
    fontSize: 24,
    lineHeight: "135%",
  },
};

const mediumStyle = {
  color: palette.black[100],
  fontSize: 20,
  lineHeight: "135%",
  letterSpacing: "-0.24px",
  fontWeight: "350",
  textAlign: "left",
  "@media(max-width:600px)": {
    fontSize: 24,
  },
};

export default ModalSectionTitle;
