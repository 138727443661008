interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
}

const StarIcon = ({
  width = 19,
  height = 18,
  fill = "none",
  stroke = "#B1AFB8",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64386 3.62753C8.43046 1.66653 8.82377 0.686035 9.50009 0.686035C10.1764 0.686035 10.5697 1.66653 11.3563 3.62753L11.393 3.71885C11.8373 4.82672 12.0595 5.38065 12.5124 5.71734C12.9652 6.05403 13.5597 6.10727 14.7486 6.21374L14.9636 6.23299C16.9094 6.40726 17.8823 6.49439 18.0905 7.11337C18.2986 7.73235 17.5761 8.3897 16.1311 9.70438L15.6488 10.1432C14.9173 10.8087 14.5515 11.1414 14.3811 11.5776C14.3493 11.6589 14.3228 11.7423 14.3019 11.8271C14.1899 12.2817 14.297 12.7645 14.5112 13.7299L14.5778 14.0305C14.9715 15.8048 15.1684 16.692 14.8247 17.0746C14.6963 17.2176 14.5293 17.3206 14.3439 17.3712C13.8477 17.5066 13.1433 16.9325 11.7343 15.7844C10.8091 15.0305 10.3465 14.6536 9.81544 14.5688C9.60653 14.5354 9.39365 14.5354 9.18475 14.5688C8.65365 14.6536 8.19106 15.0305 7.26589 15.7844C5.85694 16.9325 5.15246 17.5066 4.65626 17.3712C4.47084 17.3206 4.30393 17.2176 4.17551 17.0746C3.83183 16.692 4.02866 15.8048 4.42234 14.0305L4.48902 13.7299C4.70322 12.7645 4.81033 12.2817 4.69827 11.8271C4.67737 11.7423 4.65093 11.6589 4.61913 11.5776C4.44866 11.1414 4.08291 10.8087 3.3514 10.1432L2.86912 9.70439C1.42408 8.3897 0.701554 7.73235 0.909732 7.11337C1.11791 6.49439 2.09082 6.40726 4.03663 6.23299L4.25157 6.21374C5.44049 6.10727 6.03494 6.05403 6.48779 5.71734C6.94064 5.38065 7.16284 4.82672 7.60723 3.71885L7.64386 3.62753Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default StarIcon;
