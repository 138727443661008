import React from "react";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { palette } from "@palette";
import NotificationGridLayout, {
  TNotificationCheckbox,
} from "./NotificationGridLayout";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { TAlertMethods, TParsedAlert } from "../modals/types";
import { Bell, At } from "@phosphor-icons/react";

interface Props {
  item: TParsedAlert;
  isLast?: boolean;
  handleChange: (id: string, method: TAlertMethods) => void;
  delay?: number;
}
function NotificationCheckBox({ item, isLast, handleChange, delay }: Props) {
  const { isMobileView } = useCustomTheme();

  const checkboxes: TNotificationCheckbox[] = [
    {
      channel: "email",
      ...(isMobileView && {
        label: (
          <>
            <At weight="regular" size={16} color={palette.gray[300]} />
            Email
          </>
        ),
      }),
      dataTestId: `email-checkbox-${item?.name}`,
      hidden: item.hidden.email,
      disabled: item.disabled.email,
      value: item.isAssigned.email,
      handleChange: () => handleChange(item.id, "email"),
    },
    {
      channel: "push",
      ...(isMobileView && {
        label: (
          <>
            <Bell weight="regular" size={16} color={palette.gray[300]} /> Push
          </>
        ),
      }),
      dataTestId: `push-checkbox-${item?.name}`,
      hidden: item.hidden.push,
      disabled: item.disabled.push,
      value: item.isAssigned.push,
      handleChange: () => handleChange(item.id, "push"),
    },
  ];

  return (
    <NotificationGridLayout
      checkboxes={checkboxes}
      showBorder={!isLast}
      delay={delay}
      mainContent={
        <NotificationDescription
          title={
            <>
              {item?.title.replace(item?.highlight || "", "")}{" "}
              <span>{item?.highlight}</span>
            </>
          }
          description={item?.subTitle}
          isDisabled={item.disabled.email && item.disabled.push}
        />
      }
    />
  );
}

export default NotificationCheckBox;

const NotificationDescription = ({
  title,
  description = "",
  isDisabled,
}: {
  title: React.ReactNode;
  description?: string;
  isDisabled: boolean;
}) => {
  const { isMobileView } = useCustomTheme();
  return (
    <Stack direction="column" gap={isMobileView ? 2 : 1} alignItems="stretch">
      <CustomText isDisabled={isDisabled} color={palette.black[100]}>
        {title}
      </CustomText>
      <CustomText isDisabled={isDisabled} color={palette.gray[300]}>
        {description}
      </CustomText>
    </Stack>
  );
};

interface CustomTextProps extends ITextProps {
  isDisabled?: boolean;
}

const CustomText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isDisabled",
})<CustomTextProps>(({ isDisabled }) => ({
  opacity: isDisabled ? 0.5 : 1,
  fontSize: "14px",
  lineHeight: "16.8px",
  fontWeight: 350,
  "& span": {
    fontWeight: 500,
  },
}));
