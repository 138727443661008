import { Box, Divider, styled } from "@mui/material";
import { riskLevels } from "@components/Merchants/MerchantPreview/RiskProfile/components/RiskProfileActivity/RiskProfileActivityHeader";
import { useDispatch } from "react-redux";
import { setOpeningOptions } from "@redux/slices/merchantPreview";
import { Tooltip } from "@common/Tooltip";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { palette } from "@palette";
import { Text } from "@common/Text";
import React from "react";
import { replaceKeywords } from "@components/Merchants/MerchantPreview/RiskProfile/helpers/parsers";
import { startCase } from "lodash";
import { RiskLevelTag } from "./RiskLevelTag";
import RiskStatusIconText from "./RiskStatusIconText";

export default function RiskLevelColItem({ row }: { row: MerchantData }) {
  const {
    statusName,
    riskLevelLabel,
    riskStatusDisplayName,
    lastRiskActivityDescription,
    lastRiskActivityName,
  } = row || {};

  const dispatch = useDispatch();
  const isSuspended = statusName === "suspended";
  const risk = riskLevelLabel || "low";
  const riskStatusName = riskStatusDisplayName || "routine_monitoring";
  const levelColor = riskLevels[risk];

  return (
    <Container
      data-testid="risk-level"
      onClick={() => dispatch(setOpeningOptions({ riskprofile: true }))}
    >
      <Tooltip
        data-testid="tooltip-risk-level"
        title={<HoverPopover data={row} />}
        bgColor={palette.neutral.white}
        disableFocusListener={
          !lastRiskActivityDescription || !lastRiskActivityName || isSuspended
        }
        disableHoverListener={
          !lastRiskActivityDescription || !lastRiskActivityName || isSuspended
        }
        disableTouchListener={
          !lastRiskActivityDescription || !lastRiskActivityName || isSuspended
        }
        maxWidth="355px"
      >
        <RiskLevelTag
          testId={`risk-level-text-${row?.accID}`}
          isSuspended={isSuspended}
          levelColor={levelColor}
          statusName={statusName}
          riskLevelLabel={risk}
        />
      </Tooltip>
      {!isSuspended && (
        <Tooltip
          data-testid="tooltip-risk-level"
          title={RiskStatusName[riskStatusName]}
          placement="bottom-start"
        >
          <RiskStatusIconText riskStatusName={riskStatusName} />
        </Tooltip>
      )}
    </Container>
  );
}

const Container = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
}));

export const RiskStatusName: any = {
  routine_monitoring: "Routine Monitoring",
  alerted: "Alerted",
  active_monitoring: "Active Monitoring",
};

const HoverPopover = ({ data }: { data?: MerchantData }) => {
  const items = [
    {
      label: "Last Risk Activity",
      value: calculateDaysPassed("No activity", data?.lastRiskActivityAt),
      showBorder: true,
      hidden: true,
    },
    {
      label: replaceKeywords(startCase(data?.lastRiskActivityName)),
      value: data?.lastRiskActivityDescription,
      showBorder: false,
      hidden: !data?.lastRiskActivityDescription || !data?.lastRiskActivityName,
    },
    {
      label: "Last Escalation Date",
      value: calculateDaysPassed("No escalations", data?.lastEscalationAt),
      showBorder: false,
      hidden: true,
    },
  ];
  return (
    <ItemsContainer>
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => (
          <React.Fragment key={index}>
            <ItemBox>
              <Header>{item.label}</Header>
              <Header sx={{ color: palette.neutral[70] }}>{item.value}</Header>
            </ItemBox>
            {item.showBorder && <Divider />}
          </React.Fragment>
        ))}
    </ItemsContainer>
  );
};

const Header = styled(Text)(() => ({
  color: palette.neutral[100],
  fontSize: "12px",
  fontWeight: 350,
  lineHeight: "14.4px",
  textAlign: "start",
}));

const ItemBox = styled(Box)(() => ({
  width: "100%",
  gap: "4px",
  paddingBottom: "12px",
  paddingTop: "12px",
}));

const ItemsContainer = styled(Box)(() => ({
  width: "355px",
  padding: "0px 16px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
}));

const calculateDaysPassed = (fallBackText: string, timestamp?: number) => {
  if (!timestamp) return fallBackText;
  const currentTime = new Date().getTime() / 1000;
  const secondsPassed = currentTime - timestamp;
  const daysPassed = Math.floor(secondsPassed / (24 * 60 * 60));
  return daysPassed > 1 ? `${daysPassed} days ago` : `${daysPassed} day ago`;
};
