import { SvgIconProps } from "@mui/material";

const NewPendingIcon = ({
  height = 20,
  width = 20,
  fill = "#8F8F8F",
}: SvgIconProps) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0833 6.09894V4.24719H13.6667C13.8214 4.24719 13.9697 4.18793 14.0791 4.08244C14.1885 3.97695 14.25 3.83388 14.25 3.68469C14.25 3.53551 14.1885 3.39243 14.0791 3.28694C13.9697 3.18146 13.8214 3.12219 13.6667 3.12219H4.33333C4.17862 3.12219 4.03025 3.18146 3.92085 3.28694C3.81146 3.39243 3.75 3.53551 3.75 3.68469C3.75 3.83388 3.81146 3.97695 3.92085 4.08244C4.03025 4.18793 4.17862 4.24719 4.33333 4.24719H4.91667V6.09894C4.9142 6.83072 5.12438 7.54853 5.52338 8.171C5.92239 8.79347 6.49426 9.2957 7.17417 9.62076L7.69567 9.87219L7.17417 10.1236C6.49426 10.4487 5.92239 10.9509 5.52338 11.5734C5.12438 12.1959 4.9142 12.9137 4.91667 13.6454V15.4972H4.33333C4.17862 15.4972 4.03025 15.5565 3.92085 15.6619C3.81146 15.7674 3.75 15.9105 3.75 16.0597C3.75 16.2089 3.81146 16.352 3.92085 16.4574C4.03025 16.5629 4.17862 16.6222 4.33333 16.6222H13.6667C13.8214 16.6222 13.9697 16.5629 14.0791 16.4574C14.1885 16.352 14.25 16.2089 14.25 16.0597C14.25 15.9105 14.1885 15.7674 14.0791 15.6619C13.9697 15.5565 13.8214 15.4972 13.6667 15.4972H13.0833V13.6454C13.0858 12.9137 12.8756 12.1959 12.4766 11.5734C12.0776 10.9509 11.5057 10.4487 10.8258 10.1236L10.3043 9.87219L10.8258 9.62076C11.5057 9.2957 12.0776 8.79347 12.4766 8.171C12.8756 7.54853 13.0858 6.83072 13.0833 6.09894ZM6.08333 6.09894V4.24719H11.9167V6.09894C11.9185 6.62163 11.7685 7.13437 11.4835 7.57899C11.1985 8.02361 10.79 8.38232 10.3043 8.61444L9 9.24332L7.69567 8.61444C7.21 8.38232 6.8015 8.02361 6.51652 7.57899C6.23153 7.13437 6.08146 6.62163 6.08333 6.09894ZM11.9167 13.6454V15.4972H6.08333V13.6454C6.08146 13.1228 6.23153 12.61 6.51652 12.1654C6.8015 11.7208 7.21 11.3621 7.69567 11.1299L9 10.5011L10.3043 11.1299C10.79 11.3621 11.1985 11.7208 11.4835 12.1654C11.7685 12.61 11.9185 13.1228 11.9167 13.6454Z"
        fill="#575353"
      />
    </svg>
  );
};

export default NewPendingIcon;
