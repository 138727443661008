import NiceModal from "@ebay/nice-modal-react";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import DisputePreviewHeader, {
  IDisputePreviewHeaderProps,
} from "@components/Disputes/DisputePreview/components/DisputePreviewHeader";
import DisputePreviewBody from "@components/Disputes/DisputePreview/components/DisputePreviewBody";
import { SidePanel } from "@containers/SidePanel";
import LoadingState from "@components/ManageMoney/TransactionTable/TransactionInfoModal/LoadingState";
import { Box } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import DisputePreviewMobilePanel from "./Mobile/DisputePreviewMobilePanel";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import DisputePreviewMobileBody from "./Mobile/DisputePreviewMobileBody";

interface IComponentProps extends IDisputePreviewHeaderProps {
  isLoading?: boolean;
}

const DisputePreviewPanel = NiceModal.create(
  ({ isFirst, isLast, setSelectedRow, isLoading }: IComponentProps) => {
    const { modal, onClose, open: isModalVisible, SlideProps } = useNiceModal();
    const { isMobileView } = useCustomTheme();

    const handleClose = () => {
      setSelectedRow(-1);
      onClose();
    };

    if (isMobileView)
      return (
        <DisputePreviewMobilePanel
          onCloseDrawer={handleClose}
          isModalVisble={isModalVisible}
          SlideProps={SlideProps}
        >
          {isLoading ? <LoadingSpinner /> : <DisputePreviewMobileBody />}
        </DisputePreviewMobilePanel>
      );

    return (
      <SidePanel
        modal={modal}
        onCloseDrawer={handleClose}
        paperStyle={{ width: CAMPAIGN_PANEL_WIDTH }}
      >
        <Box overflow="auto" padding="8px 24px">
          {isLoading ? (
            //Todo: Design real loading state for panel
            <LoadingState />
          ) : (
            <>
              <DisputePreviewHeader
                isFirst={isFirst}
                isLast={isLast}
                setSelectedRow={setSelectedRow}
              />
              <DisputePreviewBody />
            </>
          )}
        </Box>
      </SidePanel>
    );
  },
);

export default DisputePreviewPanel;
