import { SvgIconProps } from "@mui/material/SvgIcon";

const DotsMenuIcon = ({
  width = 20,
  height = 20,
  fill = "#B8B8B8",
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2 14C0.9 14 0 14.9 0 16C0 17.1 0.9 18 2 18C3.1 18 4 17.1 4 16C4 14.9 3.1 14 2 14ZM9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4ZM2 7C0.9 7 0 7.9 0 9C0 10.1 0.9 11 2 11C3.1 11 4 10.1 4 9C4 7.9 3.1 7 2 7ZM9 7C7.9 7 7 7.9 7 9C7 10.1 7.9 11 9 11C10.1 11 11 10.1 11 9C11 7.9 10.1 7 9 7ZM9 14C7.9 14 7 14.9 7 16C7 17.1 7.9 18 9 18C10.1 18 11 17.1 11 16C11 14.9 10.1 14 9 14ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0Z"
        fill={fill}
      />
      <path
        d="M15.5 14C14.4 14 13.5 14.9 13.5 16C13.5 17.1 14.4 18 15.5 18C16.6 18 17.5 17.1 17.5 16C17.5 14.9 16.6 14 15.5 14ZM15.5 7C14.4 7 13.5 7.9 13.5 9C13.5 10.1 14.4 11 15.5 11C16.6 11 17.5 10.1 17.5 9C17.5 7.9 16.6 7 15.5 7ZM15.5 0C14.4 0 13.5 0.9 13.5 2C13.5 3.1 14.4 4 15.5 4C16.6 4 17.5 3.1 17.5 2C17.5 0.9 16.6 0 15.5 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default DotsMenuIcon;
