import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import RespondToDisputeModalBody, {
  TEST_CASE_ACTION_LISTS,
} from "@components/Disputes/RespondToDispute/components/RespondToDisputeModalBody";
import { FormProvider, useForm } from "react-hook-form";
import { DISPUTE_CONFIRM_SUBMISSION_POPUP } from "modals/modal_names";
import { RespondToDisputeFormType } from "./types";

const FORM_DEFAULT_VALUES = {
  caseAction: TEST_CASE_ACTION_LISTS[0].value,
  caseNotes: "",
  caseFiles: [],
};

const RespondToDisputeModal = NiceModal.create(() => {
  const form = useForm<RespondToDisputeFormType>({
    mode: "onChange",
    defaultValues: FORM_DEFAULT_VALUES,
  });
  const values = form.watch();

  const isValid = values.caseAction && values.caseNotes;

  const modal = useModal();
  const handleCancel = () => modal.remove();

  const handleSubmitEvidence = () => null;

  const handleShowConfirmationPopup = () => {
    NiceModal.show(DISPUTE_CONFIRM_SUBMISSION_POPUP, {
      onSubmit: handleSubmitEvidence,
    });
  };

  const handleSaveDraft = () => handleCancel();

  return (
    <FormProvider {...form}>
      <ModalFactory
        variant="dialog"
        modalProps={{
          open: modal.visible,
          onClose: handleCancel,
          width: 648,
          DialogProps: {
            contentContainerSx: {
              overflowY: "auto",
            },
          },
        }}
      >
        <ModalTitle
          title="Respond to Chargeback Dispute"
          description="Please review the details of the dispute and submit the required actions or evidence to contest the chargeback. Ensure all information is accurate and complete to expedite the dispute resolution process"
          padding="24px"
          onClose={handleCancel}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
        />

        <RespondToDisputeModalBody />
        <ModalActions
          padding="0px 24px 16px"
          primaryAction={{
            label: "Submit Evidence",
            sx: { fontSize: "18px" },
            onClick: handleShowConfirmationPopup,
            disabled: !isValid,
          }}
          secondaryAction={{
            label: "Save Draft and Close",
            sx: { fontSize: "18px" },
            onClick: handleSaveDraft,
          }}
          animationDelay={300}
        />
      </ModalFactory>
    </FormProvider>
  );
});

export default RespondToDisputeModal;
