import { Tooltip } from "@common/Tooltip";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import React, { memo } from "react";

function CustomToolTip({ title, image }: { title?: string; image?: string }) {
  return (
    <Tooltip
      placement="top"
      titleSx={{
        textTransform: "none",
        fontSize: "12px",
        fontWeight: 400,
        color: "#F8F8F6",
      }}
      sx={{
        "& .MuiTooltip-tooltip": {
          maxWidth: "350px",
        },
      }}
      title={title}
    >
      <StyledImage src={image} alt={title} />
    </Tooltip>
  );
}

const StyledImage = styled("img")(() => ({
  height: 24,
  width: 24,
  borderRadius: "50%",
  border: `1px solid ${palette.neutral[5]}`,
  objectFit: "cover",
}));

export default memo(CustomToolTip);
