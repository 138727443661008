
const NewMessageIcon = () => {
    return (
<svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.634277" width="8" height="8" rx="4" fill="#6D9CF8"/>
</svg>
    );
  };
  
  export default NewMessageIcon;
  