import { useState } from "react";
import { TDocument, TFileNavigation } from "./types";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { BaseModal } from "@common/Modal";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import DocumentMobileDrawer from "./components/DocumentMobileDrawer";
import { TToolbarAction } from "./layout/types";
import Previewer from "./components/Previewer";
import useDocumentList from "./hooks/useDocumentList";
import useBackListener from "@hooks/common/router/useBackListener";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.REACT_APP_ASSETS_CDN_HOST}/unpkg.com_pdfjs-dist@4.4.168_build_pdf.worker.min.mjs`;

export type FilePreviewActions = Omit<TToolbarAction, "onClick"> & {
  onClick: (
    file: TDocument | null,
    handleCloseDrawer?: () => void,
    navigation?: TFileNavigation,
  ) => void;
};

export interface IFilePreviewProps {
  file?: TDocument;
  onFileChange?: (file: TDocument) => void;
  list: TDocument[];
  handleClose?: () => void;
  toolbarActions: FilePreviewActions[];
  headerActions: TToolbarAction[];
  merchantID: number;
  merchantType: "acquirer" | "enterprise" | "merchant";
  disableBackdropClick?: boolean;
}

const FilePreview = ({
  file,
  list,
  handleClose,
  toolbarActions,
  merchantID,
  merchantType,
  headerActions,
  disableBackdropClick,
}: IFilePreviewProps) => {
  const { currentFile, currentIndex, navigation } = useDocumentList(
    list,
    file,
    handleClose,
  );
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false);

  useBackListener(() => handleClose?.());

  const toggleDrawer = (open: boolean) => setIsMobileDrawerOpen(open);

  const handleOpenDrawer = () => toggleDrawer(true);
  const handleCloseDrawer = () => toggleDrawer(false);

  const mappedActions = toolbarActions.map(({ onClick, label, ...rest }) => ({
    ...rest,
    label: label,
    onClick: () => {
      label === "delete"
        ? onClick(currentFile, handleCloseDrawer, navigation)
        : onClick(currentFile);
    },
  }));

  if (!currentFile) return <></>;

  return (
    <>
      <Previewer
        navigation={navigation}
        file={currentFile}
        onClose={handleClose}
        headerActions={headerActions}
        handleOpenDrawer={handleOpenDrawer}
        toolbarActions={mappedActions}
        count={list.length}
        index={currentIndex}
        disableBackdropClick={disableBackdropClick}
      />
      <DocumentMobileDrawer
        open={isMobileDrawerOpen}
        merchantType={merchantType}
        handleClose={handleCloseDrawer}
        file={currentFile}
        actions={mappedActions}
        merchantID={merchantID}
      />
    </>
  );
};

const FilePreviewModal = NiceModal.create(
  (props: Omit<IFilePreviewProps, "handleClose">) => {
    const modal = useModal();
    const handleClose = () => modal.remove();

    return (
      <BaseModal open={modal.visible} onClose={handleClose}>
        <ErrorCatcher errorID="file-preview-modal">
          <FilePreview {...props} handleClose={handleClose} />
        </ErrorCatcher>
      </BaseModal>
    );
  },
);

export default FilePreviewModal;
