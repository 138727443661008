import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { showMessage } from "@common/Toast";
import { QKEY_GET_TRANSFER_STATS } from "@constants/queryKeys";
import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useMutation, useQueryClient } from "react-query";

type Props = {
  transactionId: string;
  customerID: number;
};

export const useTransferActions = ({ customerID, transactionId }: Props) => {
  const queryClient = useQueryClient();
  const { onClose } = useNiceModal();

  const transactionQueries = [
    "get-transactions-risk-profile",
    "get-risk-transactions-list",
    "has-more-transactions",
    "acquirer-processing-transactions",
    ["get-transaction-history", transactionId],
    "get-all-transaction",
    "enterprise-processing-transactions",
    "get-transaction-stats",
    "manage-money-transactions",
    "get-transfers-list",
    QKEY_GET_TRANSFER_STATS,
  ];

  const refetchQueries = () => {
    transactionQueries.forEach((query) => {
      queryClient.refetchQueries(query);
    });
  };

  const cancelTransferMutation = useMutation(
    ({ reason }: any) => {
      return customInstance({
        url: buildMerchantEndpoints(`transfers/${transactionId}`, customerID),
        method: "PATCH",
        data: { cancel: true, canceledReason: reason },
      });
    },
    {
      onSuccess: () => {
        refetchQueries();
        onClose();
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.message || "Whoops.. an error occurred. Please try again",
        );
      },
    },
  );

  const expediteTransferMutation = useMutation(
    () => {
      return customInstance({
        url: buildMerchantEndpoints(
          `transactions/${transactionId}/expedite`,
          customerID,
        ),
        method: "PATCH",
        data: {},
      });
    },
    {
      onSuccess: () => {
        refetchQueries();
        onClose();
      },
      onError: (error: any) => {
        showMessage(
          "Error",
          error?.message || "Whoops.. an error occurred. Please try again",
        );
      },
    },
  );

  const handleCancelTransfer = (reason: string) => {
    cancelTransferMutation.mutate({ reason });
  };

  const handleExpediteTransfer = () => {
    expediteTransferMutation.mutate();
  };

  return {
    handleCancelTransfer,
    handleExpediteTransfer,
    isLoading:
      cancelTransferMutation.isLoading || expediteTransferMutation.isLoading,
  };
};
