import { Button } from "@common/Button";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import NiceModal from "@ebay/nice-modal-react";
import { Box } from "@mui/material";
import { ChevronRight } from "@assets/icons/RebrandedIcons";
import { palette } from "@palette";
import { SIGN_AGREEMENT_MODAL } from "modals/modal_names";
import SignatureCanvas from "../SignatureCanvas";
import { useEffect, useState } from "react";
import { dataURLtoFile } from "@utils/assets";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useAsyncAction } from "@hooks/customReactCore";

type Props = {
  addSignature: (file: File) => Promise<void>;
};

const ManualSignAgreementModal = NiceModal.create(({ addSignature }: Props) => {
  const [dataURL, setDataURL] = useState("");
  const [isLoading, triggerAction] = useAsyncAction();

  const { isMobileView } = useCustomTheme();
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();

  const handleBack = () => {
    NiceModal.show(SIGN_AGREEMENT_MODAL, { addSignature });
    onClose();
  };

  const handleSign = async () => {
    if (!dataURL) return;
    triggerAction(async () => {
      const file = await dataURLtoFile(dataURL, "signature.png");
      await addSignature(file);
      onClose();
    });
  };

  useEffect(() => {
    if (open) setDataURL("");
  }, [open]);

  return (
    <EditMerchantBaseModal
      title="Sign Agreement"
      description="Easily endorse agreements by simply using your mouse or trackpad to sign"
      open={open}
      width="730px"
      handleCancel={onClose}
      icon={
        <Box
          height="24px"
          sx={{ transform: "rotate(180deg)", cursor: "pointer" }}
          onClick={handleBack}
        >
          <ChevronRight height={24} width={24} stroke={palette.neutral[80]} />
        </Box>
      }
      sx={{
        "& .MuiPaper-root": {
          width: "730px !important",
          maxWidth: "730px !important",
        },
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
      DrawerProps={{
        SlideProps,
      }}
      mobileDrawerSx={{
        "& .MuiDialogContent-root": {
          overflowX: "hidden !important",
        },
      }}
      TransitionProps={TransitionProps}
      actions={
        <>
          <Button size="medium" background="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="medium"
            background="primary"
            disabled={!dataURL || isLoading}
            sx={{ padding: "8px 24px" }}
            onClick={handleSign}
          >
            Sign
          </Button>
        </>
      }
    >
      <SignatureCanvas
        setDataURL={setDataURL}
        height={184}
        width={isMobileView ? 320 : 683}
      />
    </EditMerchantBaseModal>
  );
});

export default ManualSignAgreementModal;
