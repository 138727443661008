import { Box, CircularProgress, Collapse } from "@mui/material";
import { palette } from "@palette";
import { RebrandedButtonProps } from "@common/Button/Button_V2";
import { Button } from "@common/Button";
import { styled } from "@mui/styles";

export type LoadingButtonProps = RebrandedButtonProps & {
  isLoading?: boolean;
  isShowTextWhileLoading?: boolean;
};
const LoadingButton = ({
  isLoading,
  isShowTextWhileLoading = false,
  children,
  ...props
}: LoadingButtonProps) => {
  return (
    <Button {...props} data-testid="popup-submit-button">
      <StyledContainer isShowTextWhileLoading={isShowTextWhileLoading}>
        <Collapse
          component={StyledBox}
          orientation="horizontal"
          in={isLoading}
          unmountOnExit
          sx={{
            "& .MuiCollapse-wrapper": {
              height: "auto",
            },
          }}
        >
          <LoadingIcon size={isShowTextWhileLoading ? 16 : 24} />
        </Collapse>
        <Collapse
          component={StyledBox}
          orientation="horizontal"
          in={isShowTextWhileLoading || !isLoading}
          unmountOnExit
        >
          <Box sx={{ whiteSpace: "nowrap" }}>{children}</Box>
        </Collapse>
      </StyledContainer>
    </Button>
  );
};

const StyledContainer = styled(Box)(
  ({
    theme,
    isShowTextWhileLoading,
  }: {
    theme: any;
    isShowTextWhileLoading: boolean;
  }) => ({
    display: "flex",
    flexDirection: isShowTextWhileLoading ? "row-reverse" : "row",
    gap: isShowTextWhileLoading ? 8 : 0,
  }),
);

const StyledBox = styled(Box)({
  gridArea: "1/1",
  display: "flex",
  alignItems: "center",
});

const LoadingIcon = ({ size = 24 }: { size?: number }) => {
  return (
    <Box
      sx={{
        maxHeight: `${size}px`,
      }}
    >
      <CircularProgress
        size={size}
        sx={{
          color: palette.neutral.black,
        }}
      />
    </Box>
  );
};

export default LoadingButton;
