import { customInstance } from "@services/api";

export const getMerchantErrorParam = (param: string) => {
  switch (param) {
    case "owner.phoneNumber":
      return "Primary Account Holder Contact phone";
    case "slug":
      return "Permalink";
    case "imageURL":
      return "Avatar image";
    case "billingDescriptor":
      return "Billing Descriptor";
    case "legalEntity.taxIDNumber":
      return "Federal Tax ID";
    case "legalEntity.principals.taxIDNumber":
      return "Business Owner Tax ID";
    default:
      return "Input";
  }
};

export const getMerchantErrorMessage = (
  param: string,
  code: string,
  message: string,
) => {
  if (code === "duplicate" && param === "slug")
    return `The provided "permalink" value is already taken.`;

  return message;
};

export const getLegalEntityPrincipals = (
  merchantId: number,
  legalEntityID: number,
) => {
  return customInstance({
    url: `/merchants/${merchantId}/legal-entities/${legalEntityID}`,
    method: "GET",
  });
};
