type IconProps = {
  width?: number;
  height?: number;
  stroke?: string;
}

const DocumentIconV2 = ({
  width = 24,
  height = 24,
  stroke = "#8F8F8F",
}: IconProps) => {
  return (
    <svg 
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd" 
        d="M7.87885 4.5C7.08389 4.5 6.43945 5.14444 6.43945 5.93939V18.0606C6.43945 18.8556 7.08389 19.5 7.87885 19.5H16.1213C16.9162 19.5 17.5607 18.8556 17.5607 18.0606L17.5606 17.0909C17.5606 16.8148 17.7845 16.5909 18.0606 16.5909C18.3368 16.5909 18.5606 16.8147 18.5606 17.0909L18.5607 18.0606C18.5607 19.4078 17.4685 20.5 16.1213 20.5H7.87885C6.53161 20.5 5.43945 19.4078 5.43945 18.0606V5.93939C5.43945 4.59215 6.53161 3.5 7.87885 3.5H13.2122C13.3448 3.5 13.472 3.55268 13.5657 3.64645L18.4142 8.49493C18.508 8.5887 18.5607 8.71588 18.5607 8.84849L18.5606 13.2121C18.5606 13.4883 18.3368 13.7121 18.0606 13.7121C17.7845 13.7121 17.5606 13.4882 17.5606 13.2121L17.5607 9.34848H13.2122C12.936 9.34848 12.7122 9.12463 12.7122 8.84848V4.5H7.87885ZM13.7122 5.20711L16.8536 8.34848H13.7122V5.20711Z" 
        fill={stroke}
      />
    </svg>
  )
}

export default DocumentIconV2;
