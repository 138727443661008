import { Stack, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

const fieldSx = { borderWidth: "1px !important" };

const MarkAllAsOkModalContent = () => {
  const { control } = useFormContext();
  return (
    <FadeUpWrapper delay={200}>
      <Stack gap="12px">
        <Controller
          name="reason"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Reason"
              fullWidth
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{ sx: fieldSx }}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label="Description"
              fullWidth
              rows={6}
              multiline
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              InputProps={{ sx: fieldSx }}
            />
          )}
        />
      </Stack>
    </FadeUpWrapper>
  );
};

export default MarkAllAsOkModalContent;
