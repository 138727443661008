import { Button } from "@common/Button";
import { ButtonProps } from "@common/Button/Button";
import { BtnBGTypes } from "@common/Button/Button_V2";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Stack, styled, StackProps, CircularProgress } from "@mui/material";
import { palette } from "@palette";

type ActionProps = ButtonProps & {
  label?: string;
  hidden?: boolean;
  isLoading?: boolean;
  dataTestId?: string;
};

type DialogActionsProps = {
  containerProps?: StackProps;
  padding?: string;
  primaryAction?: ActionProps;
  secondaryAction?: ActionProps;
  animationDelay?: number;
  fullWidth?: boolean;
};

export const ModalActions = ({
  padding,
  containerProps,
  primaryAction,
  secondaryAction,
  animationDelay = 200,
  fullWidth = false,
}: DialogActionsProps) => {
  const buttons: (ActionProps & { background: BtnBGTypes })[] = [
    {
      background: "tertiary",
      label: "Discard",
      fullWidth,
      ...secondaryAction,
    },
    {
      background: "primary",
      label: "Save",
      type: "submit",
      fullWidth,
      ...primaryAction,
    },
  ];
  return (
    <FadeUpWrapper delay={animationDelay}>
      <Container
        {...containerProps}
        fullWidth={fullWidth}
        sx={{ ...containerProps?.sx, padding }}
      >
        {buttons.map(
          ({ label, hidden, isLoading, dataTestId, sx, ...rest }) => (
            <HiddenComponent key={label} hidden={hidden || false}>
              <Button
                size="medium"
                sx={{
                  fontWeight: 400,
                  height: "fit-content",
                  ...sx,
                }}
                {...rest}
                data-testid={dataTestId}
                endIcon={
                  isLoading ? (
                    <CircularProgress
                      size="20px"
                      sx={{
                        color: palette.neutral.black,
                        opacity: "1 !important",
                      }}
                    />
                  ) : undefined
                }
              >
                {label}
              </Button>
            </HiddenComponent>
          ),
        )}
      </Container>
    </FadeUpWrapper>
  );
};

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})<{ fullWidth: boolean }>(({ fullWidth }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "16px",

  ...(fullWidth && {
    flexDirection: "column-reverse",
    alignItems: "stretch",
  }),
}));
