import { useEffect, useState } from "react";
import { Button } from "@common/Button";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFCustomEditor } from "@common/CustomEditor";
import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
} from "@mui/material";
import { palette } from "@palette";
import { EditorState } from "draft-js";
import { IDropzoneProps } from "react-dropzone-uploader";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useUploadVariantImage } from "@hooks/merchant-api/fundraisers";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectGlobalFundraiserAmountID,
  setIncrementGlobalFundraiserAmountId,
} from "@redux/slices/fundraisers";
import { AmountType } from "../FundraisersMinibuilder/types";
import { Wrapper } from "./utils";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { RHFSwitch } from "@common/Switch";
import { MAX_TEXT_LENGTH } from "@constants/constants";
import { showMessage } from "@common/Toast";
import { convertMinibuilderDescription } from "@utils/draft.editor.helpers";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { TrashBin } from "@assets/icons/RebrandedIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  OPERATIONS,
  UPLOAD_DENY_MESSAGE,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";

interface CreateAmountProps {
  item?: AmountType;
  onClose: (item?: AmountType) => void;
  onDelete?: (id: string) => void;
  variant?: "amount" | "ticket" | "subscription";
  hasUniqueTitle: (title: string, id?: string) => boolean;
  isPaymentFormBuilder: boolean;
}

type FormInputs = {
  thumbnail: string | File;
  amount: string;
  title: string;
  in_stock?: number | null;
  display?: boolean;
  description: EditorState | null;
};

const CreateAmount = ({
  item,
  onClose,
  onDelete,
  variant = "amount",
  isPaymentFormBuilder,
  hasUniqueTitle,
}: CreateAmountProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isDesktopView } = useCustomTheme();
  const { handlePostImage } = useUploadVariantImage();
  const dispatch = useAppDispatch();
  const amountID = useAppSelector(selectGlobalFundraiserAmountID);
  const modal = useModal();
  const open = modal.visible;

  const isUpdateAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const canEdit = (!!item && isUpdateAllowed) || !item;

  const isDeleteAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.PRODUCT,
      RESOURCE_BASE.AMOUNT,
    ),
    operation: OPERATIONS.DELETE,
  });

  const isAddImageAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEDIA_ITEM,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const defaultValues = {
    thumbnail: "",
    amount: "",
    title: "",
    description: null,
    in_stock: null,
    display: false,
  };

  const schema = Yup.object().shape({
    title: Yup.string().trim().required("This field is required"),
    amount: Yup.string()
      .required("This field is required")
      .test("Must be above 1 USD", (value) => {
        if (!value) return false;
        const valueToNumber = parseFloat(value);
        if (Number.isNaN(valueToNumber)) return false;
        return valueToNumber >= 1;
      }),
    in_stock: Yup.number()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .when({
        is: () => variant === "ticket",
        then: Yup.number().nullable().required("Stock is required").min(1),
        otherwise: Yup.number().nullable(),
      }),
    display: Yup.boolean(),
    thumbnail: Yup.mixed<File | string>(),
    description: Yup.mixed<EditorState>().nullable(),
  });

  const methods = useForm<FormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    watch,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;
  useEffect(() => {
    if (item) {
      const typeSafeDescription = convertMinibuilderDescription(
        item.description,
      );
      reset({
        thumbnail: item?.thumbnail || "",
        amount: item.amount,
        in_stock:
          item.inventory ?? item.in_stock ?? (!isPaymentFormBuilder ? 1 : null),
        display: item.display ?? false,
        title: item.title,
        description: typeSafeDescription as any,
      });
    }

    if (!item) {
      reset(defaultValues);
    }
  }, [item]);

  const onEditorStateChange = (editorState: EditorState) => {
    if (editorState.getCurrentContent().getPlainText().length > MAX_TEXT_LENGTH)
      return;
    setValue("description", editorState, { shouldDirty: true });
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
    if (file) {
      setValue(
        "thumbnail",
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
        { shouldDirty: true },
      );
    }
  };

  const hideModal = () => {
    reset();
    modal.hide();
    setTimeout(() => {
      modal.remove();
    }, 150);
  };

  const handleDeleteItem = () => {
    hideModal();
    if (item?.id && onDelete) onDelete(item.id);
  };

  const handleCancel = () => {
    hideModal();
    onClose();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (!hasUniqueTitle(data.title, item?.id)) {
      showMessage(
        "Error",
        "An element with the same name already exists",
        true,
        "",
        false,
      );
      return;
    }
    setIsLoading(true);
    let url;
    try {
      if (typeof data.thumbnail === "string" || !data.thumbnail) {
        url = data.thumbnail;
      } else {
        url = (await handlePostImage(data.thumbnail)) || null;
      }
    } catch (error) {
      url = null;
    }

    const amount: AmountType = {
      id: item ? item.id : amountID,
      active: item ? item.active : true,
      title: dirtyFields.title || !item ? data.title : item.title,
      amount: dirtyFields.amount || !item ? data.amount : item.amount,
      description: () =>
        dirtyFields.description || !item
          ? data.description
            ? data.description
            : ""
          : item.description,
      thumbnail: dirtyFields.thumbnail || !item ? url : item.thumbnail,

      ...((variant === "ticket" || isPaymentFormBuilder) && {
        in_stock:
          dirtyFields.in_stock || item?.inventory || !item
            ? data.in_stock || 0
            : item.in_stock || 0,
        display: watch().display || data.display,
      }),
    };

    setIsLoading(false);
    if (!item) dispatch(setIncrementGlobalFundraiserAmountId());
    hideModal();
    onClose(amount);
  };

  const dynamicTitle = item
    ? `Edit ${item.title}`
    : isPaymentFormBuilder
    ? `Add ${variant}`
    : `Create ${variant}`;

  const buttonText = item
    ? "Save"
    : isPaymentFormBuilder
    ? "Create option"
    : "Create";

  return (
    <Wrapper
      scroll="body"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          top: 0,
        },
      }}
      onClose={handleCancel}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          id="create-amount"
          onSubmit={methods.handleSubmit(onSubmit)}
          pb={4}
        >
          <TitleContainer>
            <Text
              color={palette.black[100]}
              variant="headline"
              fontSize="24px"
              lineHeight="32.4px"
              fontWeight="book"
              sx={{ letterSpacing: "-0.24px" }}
            >
              {dynamicTitle}
            </Text>
            {item && onDelete && !isDesktopView && (
              <Button
                background="text"
                size="medium"
                onClick={handleDeleteItem}
                disabled={!isDeleteAllowed}
                tooltipProps={{
                  show: !isDeleteAllowed,
                  message: DELETE_DENY_MESSAGE,
                }}
              >
                <TrashBin width={32} height={32} fill={palette.error.hover} />
              </Button>
            )}
          </TitleContainer>
          <ContentContainer>
            <FadeUpWrapper delay={50}>
              <WithTooltipWrapper
                hasTooltip={!isAddImageAllowed}
                tooltipProps={{
                  show: !isAddImageAllowed,
                  message: UPLOAD_DENY_MESSAGE,
                }}
              >
                <RHFUploadAvatar
                  avatarType="thumbnail"
                  name="thumbnail"
                  onChangeStatus={handleChangeStatus}
                  disabled={!canEdit || !isAddImageAllowed}
                  isProductVariant
                  height="78px"
                  width="78px"
                  key={Date.now()} // Needs to be investigated a bit better
                />
              </WithTooltipWrapper>
            </FadeUpWrapper>
            <Stack
              direction={variant === "ticket" ? "column-reverse" : "column"}
              gap={1}
            >
              {isPaymentFormBuilder && (
                <FadeUpWrapper delay={200}>
                  <RHFInput
                    name="title"
                    label="Title"
                    fullWidth
                    disabled={!canEdit}
                  />
                </FadeUpWrapper>
              )}
              <Stack direction="row" spacing={1}>
                <FadeUpWrapper delay={100}>
                  <Box
                    width={
                      variant === "ticket" || !isDesktopView ? "100%" : "300px"
                    }
                  >
                    <RHFCustomAmountInput
                      name="amount"
                      currency="usd"
                      disabled={!canEdit}
                      initialValue={item?.amount}
                    />
                  </Box>
                </FadeUpWrapper>
                {(variant === "ticket" || isPaymentFormBuilder) && (
                  <FadeUpWrapper delay={150}>
                    <RHFCustomIntegerInput
                      name="in_stock"
                      label="Stock"
                      disabled={!canEdit}
                    />
                  </FadeUpWrapper>
                )}
              </Stack>
              {isPaymentFormBuilder && (
                <FadeUpWrapper delay={300}>
                  <RHFSwitch
                    size="small"
                    name="display"
                    label="Display items available"
                    disabled={!canEdit}
                  />
                </FadeUpWrapper>
              )}
              {!isPaymentFormBuilder && (
                <FadeUpWrapper delay={200}>
                  <RHFInput
                    name="title"
                    label="Title"
                    fullWidth
                    disabled={!canEdit}
                  />
                </FadeUpWrapper>
              )}
            </Stack>
            <FadeUpWrapper delay={250}>
              <RHFCustomEditor
                name="description"
                onEditorStateChange={onEditorStateChange}
                placeholder={"Description (optional)"}
                readOnly={!canEdit}
              />
            </FadeUpWrapper>
            {variant === "ticket" && !isPaymentFormBuilder && (
              <FadeUpWrapper delay={300}>
                <RHFSwitch
                  name="display"
                  label="Display tickets available"
                  disabled={!canEdit}
                />
              </FadeUpWrapper>
            )}
          </ContentContainer>
          <ActionsContainer isEdit={Boolean(item)}>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="stretch"
              width="100%"
            >
              <Box>
                {item && onDelete && isDesktopView && (
                  <Button
                    background="text"
                    size="medium"
                    sx={{ color: palette.error.hover }}
                    onClick={handleDeleteItem}
                    disabled={!isDeleteAllowed}
                    data-testid="delete-amount-button"
                    tooltipProps={{
                      show: !isDeleteAllowed,
                      message: DELETE_DENY_MESSAGE,
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Button
                background="text"
                size="medium"
                onClick={handleCancel}
                sx={(theme) => ({
                  [theme.breakpoints.down("sm")]: {
                    flexGrow: 1,
                  },
                  [theme.breakpoints.up("sm")]: {
                    marginLeft: "auto",
                  },
                })}
              >
                Cancel
              </Button>
              <Button
                background="primary"
                size="medium"
                disabled={
                  item
                    ? !isDirty || !isValid || isLoading
                    : !isValid || isLoading
                }
                form="create-amount"
                type="submit"
                sx={(theme) => ({
                  fontWeight: "normal",
                  [theme.breakpoints.down("sm")]: {
                    flexGrow: 3,
                  },
                })}
                data-testid={`${buttonText}-amount-button`}
              >
                {buttonText}
              </Button>
            </Stack>
          </ActionsContainer>
        </Box>
      </FormProvider>
    </Wrapper>
  );
};

export const CreateAmountModal = NiceModal.create(
  (props: CreateAmountProps) => {
    return <CreateAmount {...props} />;
  },
);

export const TitleContainer = styled(DialogTitle)(() => ({
  padding: "16px 16px 8px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "4px",
}));

export const ContentContainer = styled(DialogContent)(() => ({
  padding: "24px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export const ActionsContainer = styled(DialogActions, {
  shouldForwardProp: (prop) => prop !== "isEdit",
})<{ isEdit?: boolean }>(({ theme, isEdit }) => ({
  padding: "12px 16px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: isEdit ? "space-between" : "flex-end",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-end",
    gap: "8px",
  },
}));

export default CreateAmount;
