import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { format } from "date-fns";
import { useEffect, useMemo, useRef } from "react";
import { useModal } from "@ebay/nice-modal-react";
import {
  TExportHandlerArgs,
  useDownloadExportedTable,
} from "./useDownloadExportedTable";
import useQueryString from "@hooks/useQueryString";
import {
  Colum_types,
  ColumnExport,
  CustomDateRange,
  DateRange,
  ExportType,
  TExportTable,
} from "../types";
import { getSessionTimezone } from "@utils/timezones";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { convertToTimezone } from "../utils";

type Props = {
  isFilter: boolean;
  allColumns: Record<string, string[]>;
  visibleColumns: string[];
  monthlyColumns: string[];
  EXPORT_FORM_NAME?: string;
  closeModal(): void;
  column_type: Colum_types;
  merchantId?: number;
  sortReduxKey?: string;
  searchKey?: string;
  formattedFilterParams?: string;
  salesInfoColumns: string[];
};

export const SUPPORTED_EXTENSIONS_EXPORT = [
  // { label: "PDF (.pdf)", value: "pdf" },
  { label: "CSV (.csv)", value: "csv" },
];

export const useExportTable = ({
  isFilter,
  allColumns,
  visibleColumns,
  monthlyColumns,
  EXPORT_FORM_NAME,
  closeModal,
  column_type = "merchant",
  merchantId,
  sortReduxKey,
  searchKey,
  formattedFilterParams,
  salesInfoColumns,
}: Props) => {
  const modal = useModal();
  const userData = useAppSelector(selectUser);
  const initialTimezone = userData.timezone || getSessionTimezone();
  const defaultValues: TExportTable = {
    fileName: generateFileName(EXPORT_FORM_NAME),
    fileType: "csv",
    exportType: isFilter ? "filtered" : "all",
    dateRange: "current_month",
    customDateRange: {
      timezone: initialTimezone,
      startDate: 0,
      endDate: 0,
    },
    columnsIncluded: column_type === "settlement" ? "all" : "visible",
    selectedColumns:
      column_type === "settlement"
        ? Object.values(allColumns).flat()
        : visibleColumns,
  };

  const methods = useForm<TExportTable>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { setValue, getValues } = methods;
  const { exportType = "all", selectedColumns = [] } = useWatch({
    control: methods.control,
  });
  const applyFilters = isFilter && exportType !== "all";
  const filterQuery = useQueryString({
    isMerchant: true,
    applyAdvancedQuery: applyFilters,
  });

  // have to send OwnerInviteStatus in /submerchants endpoint but ownerInviteStatus for /submerchants.csv endpoint
  const updatedFilterQuery = filterQuery?.includes("OwnerInviteStatus")
    ? filterQuery.replaceAll("OwnerInviteStatus", "ownerInviteStatus")
    : filterQuery;

  // if we have salesInfo columns included we have to call the new endpoint => `/submerchants-monthly-processing.csv`
  const includesSalesColumn = useMemo(() => {
    return selectedColumns.some((col) => salesInfoColumns.includes(col));
  }, [selectedColumns]);

  const { handleExport, isLoading } = useDownloadExportedTable({
    withFilters: applyFilters,
    filterQuery: updatedFilterQuery,
    closeModal,
    column_type,
    merchantId,
    sortReduxKey,
    searchKey,
    formattedFilterParams,
    includesSalesColumn,
  });

  //set the fileName and dateRange select when processing monthly is selected
  const handleChangeFileName = (type: string) => {
    if (type === "processing_monthly") {
      setValue(
        "fileName",
        generateFileName("GIVE_MONTHLY_MERCHANT_PROCESSING_"),
      );
      setValue("dateRange", "current_month");
    } else if (
      getValues("fileName").includes("GIVE_MONTHLY_MERCHANT_PROCESSING_")
    ) {
      setValue("fileName", generateFileName("GIVE_MERCHANTS_"));
      setValue("dateRange", "all_time");
    }
  };

  const onSubmit: SubmitHandler<TExportTable> = async (data) => {
    //convert the date by timeZone, if its the same as selected returns the same value
    const convertedStartDate = convertToTimezone(
      data.customDateRange.startDate,
      data.customDateRange.timezone,
    );
    const convertedEndtDate = convertToTimezone(
      data.customDateRange.endDate,
      data.customDateRange.timezone,
    );
    let payload = null;
    const hasSelectedDate =
      !!data.customDateRange.startDate && !!data.customDateRange.endDate;
    switch (data.columnsIncluded) {
      case "custom":
        payload = {
          includeAllColumns: false,
          columns: data.selectedColumns,
          ...(column_type === "settlement" &&
            hasSelectedDate && {
              dateRange: {
                startDate: data.customDateRange.startDate,
                endDate: data.customDateRange.endDate,
              },
            }),
        };
        break;
      case "visible":
        payload = {
          includeAllColumns: false,
          columns: visibleColumns,
        };
        break;
      case "processing_monthly":
        payload = {
          includeAllColumns: false,
          columns: monthlyColumns,
          fileNamePrefix: "GIVE_MONTHLY_MERCHANT_PROCESSING_",
          dateRange: {
            startDate: convertedStartDate,
            endDate: convertedEndtDate,
          },
        };
        break;
      // case "maintenance_monthly": // TODO add when implemented
      case "all":
      default:
        payload = {
          includeAllColumns: true,
          ...(column_type === "settlement" &&
            hasSelectedDate && {
              dateRange: {
                startDate: data.customDateRange.startDate,
                endDate: data.customDateRange.endDate,
              },
            }),
        };
        break;
    }

    handleExport({ data, payload } as TExportHandlerArgs);
  };

  useEffect(() => {
    if (modal.visible) {
      methods.reset(defaultValues);
    }
  }, [modal.visible]);

  return {
    methods,
    onSubmit,
    isLoading,
    handleChangeFileName,
    initialTimezone,
  };
};

const schema = Yup.object({
  fileName: Yup.string().required("Required"),
  fileType: Yup.string().required("Required"),
  exportType: Yup.mixed<ExportType>(),
  dateRange: Yup.mixed<DateRange>(),
  customDateRange: Yup.object({
    startDate: Yup.number(),
    endDate: Yup.number(),
  }),
  columnsIncluded: Yup.mixed<ColumnExport>(),
  selectedColumns: Yup.array(Yup.string()),
});

export const getDefaultSelectedColumns = (
  columns: Record<string, string[]>,
) => {
  let newArray: string[] = [];
  Object.values(columns).forEach((array) => {
    newArray = [...newArray, ...array];
  });
  return newArray;
};

export const generateFileName = (name = "GIVE_MERCHANTS_") =>
  name + format(new Date(), "yyyyMMdd_HHmmss");
