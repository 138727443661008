interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const ArrowCounterClockwiseIcon = ({
  width = 14,
  height = 14,
  fill = "#9C9AA3",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 16.0001C28.0003 19.1549 26.7582 22.183 24.5425 24.4288C22.3268 26.6747 19.3158 27.9577 16.1612 28.0001H16C12.9353 28.0077 9.98535 26.8349 7.7625 24.7251C7.66705 24.6348 7.59031 24.5266 7.53667 24.4066C7.48303 24.2867 7.45354 24.1574 7.44989 24.026C7.44623 23.8947 7.46848 23.7639 7.51536 23.6412C7.56224 23.5184 7.63284 23.4061 7.72312 23.3107C7.81341 23.2152 7.92161 23.1385 8.04155 23.0849C8.16149 23.0312 8.29082 23.0017 8.42216 22.9981C8.55349 22.9944 8.68427 23.0167 8.807 23.0635C8.92974 23.1104 9.04205 23.181 9.1375 23.2713C10.5672 24.6197 12.3626 25.5169 14.2993 25.8508C16.236 26.1847 18.2281 25.9406 20.0269 25.1489C21.8256 24.3572 23.3512 23.0531 24.4131 21.3994C25.475 19.7457 26.026 17.8158 25.9974 15.8507C25.9688 13.8857 25.3619 11.9726 24.2523 10.3505C23.1428 8.72841 21.5799 7.4692 19.7589 6.73018C17.9379 5.99116 15.9395 5.8051 14.0133 6.19524C12.0872 6.58538 10.3187 7.53442 8.92875 8.92381C8.91854 8.93485 8.90769 8.94529 8.89625 8.95506L5.57375 12.0001H9C9.26522 12.0001 9.51957 12.1054 9.70711 12.2929C9.89464 12.4805 10 12.7348 10 13.0001C10 13.2653 9.89464 13.5196 9.70711 13.7072C9.51957 13.8947 9.26522 14.0001 9 14.0001H3C2.73478 14.0001 2.48043 13.8947 2.29289 13.7072C2.10536 13.5196 2 13.2653 2 13.0001V7.00006C2 6.73484 2.10536 6.48049 2.29289 6.29295C2.48043 6.10541 2.73478 6.00006 3 6.00006C3.26522 6.00006 3.51957 6.10541 3.70711 6.29295C3.89464 6.48049 4 6.73484 4 7.00006V10.7251L7.53125 7.50006C9.21123 5.82661 11.3493 4.68839 13.6757 4.22909C16.002 3.7698 18.4122 4.01001 20.6022 4.91941C22.7921 5.82882 24.6635 7.36664 25.9801 9.33874C27.2968 11.3108 27.9996 13.6288 28 16.0001Z"
        fill="#343330"
      />
    </svg>
  );
};

export default ArrowCounterClockwiseIcon;
