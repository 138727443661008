const DoubleChevron = () => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.35366 9.64628C7.40015 9.69271 7.43703 9.74786 7.46219 9.80856C7.48736 9.86926 7.50031 9.93432 7.50031 10C7.50031 10.0657 7.48736 10.1308 7.46219 10.1915C7.43703 10.2522 7.40015 10.3073 7.35366 10.3538L4.35366 13.3538C4.30723 13.4003 4.25208 13.4371 4.19138 13.4623C4.13068 13.4875 4.06562 13.5004 3.99991 13.5004C3.9342 13.5004 3.86914 13.4875 3.80844 13.4623C3.74774 13.4371 3.6926 13.4003 3.64616 13.3538L0.646162 10.3538C0.552341 10.26 0.499634 10.1327 0.499634 10C0.499634 9.86734 0.552341 9.7401 0.646162 9.64628C0.739982 9.55246 0.86723 9.49975 0.999912 9.49975C1.13259 9.49975 1.25984 9.55246 1.35366 9.64628L3.99991 12.2932L6.64616 9.64628C6.6926 9.59979 6.74774 9.56291 6.80844 9.53775C6.86914 9.51258 6.9342 9.49963 6.99991 9.49963C7.06562 9.49963 7.13068 9.51258 7.19138 9.53775C7.25208 9.56291 7.30723 9.59979 7.35366 9.64628ZM1.35366 4.35378L3.99991 1.7069L6.64616 4.35378C6.73998 4.4476 6.86723 4.50031 6.99991 4.50031C7.13259 4.50031 7.25984 4.4476 7.35366 4.35378C7.44748 4.25996 7.50019 4.13271 7.50019 4.00003C7.50019 3.86735 7.44748 3.7401 7.35366 3.64628L4.35366 0.646277C4.30723 0.599789 4.25208 0.562909 4.19138 0.537747C4.13068 0.512585 4.06562 0.499634 3.99991 0.499634C3.9342 0.499634 3.86914 0.512585 3.80844 0.537747C3.74774 0.562909 3.6926 0.599789 3.64616 0.646277L0.646162 3.64628C0.552341 3.7401 0.499634 3.86735 0.499634 4.00003C0.499634 4.13271 0.552342 4.25996 0.646162 4.35378C0.739982 4.4476 0.86723 4.50031 0.999912 4.50031C1.13259 4.50031 1.25984 4.4476 1.35366 4.35378Z"
        fill="#575353"
      />
    </svg>
  );
};

export default DoubleChevron