import { CloseIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { styled } from "@mui/material";
import { Image } from "@common/StyledImage/Image";
import Placeholder from "assets/images/Placeholder.png";
import { palette } from "@palette";
import { SelectorOption } from "./utils";

export default function ListItem({
  item,
  onDelete,
  noImage,
}: {
  item: SelectorOption;
  onDelete: (val: SelectorOption) => void;
  noImage?: boolean;
}) {
  return (
    <ListContainer direction="row">
      {!noImage && (
        <Image
          width={24}
          height={24}
          src={item.imageURL ? `${item.imageURL}/thumb` : Placeholder}
          sx={{ borderRadius: "1.34px" }}
        />
      )}
      <ListLabel>{item.label}</ListLabel>
      <CloseIcon
        onClick={() => onDelete(item)}
        stroke={palette.info.hard}
        width={18}
        height={18}
      />
    </ListContainer>
  );
}

const ListContainer = styled(Stack)(() => ({
  gap: "8px",
  padding: "4px 8px",
  backgroundColor: palette.info.light,
  borderRadius: "4px",
  alignItems: "center",
  ":hover": {
    cursor: "pointer",
  },
}));

const ListLabel = styled(Text)(() => ({
  fontSize: "12px",
  color: palette.info.main,
  flex: 1,
}));
