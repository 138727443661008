import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { customInstance } from "..";
import { UseQueryOptions, useQuery } from "react-query";
import { buildMerchantEndpoints } from "../utils.api";
import { updatePermissions } from "@redux/slices/app";
import { useAppDispatch } from "@redux/hooks";
import { useGetCurrentMerchantId } from "@hooks/common";
import { checkPortals } from "@utils/routing";

export type ProductParams = {
  queryString?: string;
  page?: number;
  sorting?: string;
  searchQuery?: string;
  filter?: string;
  memberStatus?: string;
};

export const mapTypeToQueryKey = {
  event: "list-all-events",
  fundraiser: "list-all-fundraisers",
  invoice: "list-all-invoices",
  membership: "list-all-memberships",
  sweepstake: "list-all-sweepstakes",
  "payment-form": "list-all-payment-forms",
};

const getData = (
  type: string,
  { queryString, page, sorting, searchQuery }: ProductParams,
) => {
  const search = searchQuery ? `&q="${searchQuery}"` : "";
  const dynamicType = type !== "payment-form" ? `typeName:"${type}"` : "";
  return customInstance({
    url: buildMerchantEndpoints(
      `products?filter=${dynamicType}${queryString}&sort=${
        sorting || "-id"
      }${search}&page=${page}&max=${ROWS_PER_PAGE}`,
    ),
    method: "GET",
  });
};

export const useQueryFactory =
  (type: keyof typeof mapTypeToQueryKey) =>
  (
    params: ProductParams,
    options: Omit<
      UseQueryOptions<any, any, any, any>,
      "queryKey" | "queryFn"
    > = {},
  ) => {
    const dispatch = useAppDispatch();
    return useQuery(
      [
        mapTypeToQueryKey[type],
        params.queryString,
        params.page,
        params.sorting,
        params.searchQuery,
      ],
      async () => {
        const data = await getData(type, params);
        return data;
      },
      {
        retry: 1,
        ...options,
        onError(err) {
          if (err.not_authorized) {
            dispatch(
              updatePermissions({
                [`campaign_${type}`]: true,
              }),
            );
          } else {
            if (options.onError) {
              options.onError(err);
            }
          }
        },
      },
    );
  };

export const useGetProductsStatsFactory = (id?: number, getAll?: boolean) => {
  const dispatch = useAppDispatch();
  const { merchantId } = useGetCurrentMerchantId();
  const { isMerchantPortal } = checkPortals();

  return useQuery(
    [`get-campaign-stats`, id, isMerchantPortal],
    async () => {
      const data = await customInstance({
        url: buildMerchantEndpoints(
          !getAll ? `product-types/${id}/stats` : `product-types/stats`,
          merchantId,
        ),
        method: "GET",
      });
      return data;
    },
    {
      enabled: (!!id || !!getAll) && Boolean(merchantId) && isMerchantPortal,
      refetchOnWindowFocus: false,
      retry: 1,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              product_stats: true,
            }),
          );
        }
      },
    },
  );
};

export const useGetTransactionsFactory =
  (path: string, queryKey: string) =>
  <T>(
    {
      page,
      sorting,
      queryString,
      searchQuery,
      filter,
      ...rest
    }: ProductParams & T,
    options: Omit<
      UseQueryOptions<any, any, any, any>,
      "queryKey" | "queryFn"
    > = {},
  ) => {
    const searchParams = new URLSearchParams(document.location.search);
    let merchantId: any = searchParams.get("merchant");
    merchantId = merchantId ? parseInt(merchantId) : null;
    return useQuery(
      [
        queryKey,
        page,
        sorting,
        queryString,
        searchQuery,
        filter,
        ...Object.values(rest),
      ],
      async () => {
        const sortQuery = sorting ? `sort=${sorting}` : "";
        let basePath = `${path}?${sortQuery}&page=${page}&max=${ROWS_PER_PAGE}`;

        if (queryString) {
          basePath += `&filter=${queryString}`;
        }

        if (searchQuery) {
          basePath += `&q="${searchQuery}"`;
        }
        if (filter) {
          basePath += `&filter=${filter}`;
        }

        const data = await customInstance({
          url: buildMerchantEndpoints(basePath, merchantId),
          method: "GET",
        });
        return data;
      },
      options,
    );
  };
