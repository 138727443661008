import * as React from "react";
import { palette } from "@palette";
import { IDropzoneProps } from "react-dropzone-uploader";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import InputAdornment from "@mui/material/InputAdornment";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
import { RHFInput, RHFTelInput } from "@common/Input";
import { BirthDatePicker } from "@common/DatePickers";
import { RHFUploadAvatar } from "@components/UploadAvatar";
import { ZipCodeInput } from "@common/AddressInputs";
import { showMessage } from "@common/Toast";
import Banner from "@components/ManageMoney/TransactionTable/TableActions/ViewCustomer/Banner";
import { PurchaseProductType } from "types/customer.types";

type ViewCustomerProps = {
  data?: {
    [key: string]: any;
  };
  closeAction?: () => void;
};

type IFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: number;
  address: {
    country: string;
    city: string;
    state: string;
    zip: string;
    line1: string;
  };
  occupation: string;
  employer: string;
  notes: string;
  avatarUrl: string | File;
};

const ViewCustomer = NiceModal.create(
  ({ data, closeAction }: ViewCustomerProps) => {
    const modal = useModal();
    const theme = useTheme();
    const [edit, setEdit] = React.useState(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const schema = Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("This field is required")
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          "Please enter a valid email",
        ),
      phone: Yup.string().matches(
        /^[0-9]{11,13}$/,
        "Please enter a valid phone number",
      ),
      address: Yup.object().shape({
        country: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip: Yup.string(),
        line1: Yup.string(),
      }),
      occupation: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "Please enter a valid occupation",
      ),
      employer: Yup.string().matches(
        /^[a-zA-Z\s]*$/,
        "Please enter a valid employer",
      ),
    });

    const name = data ? data.title.split(" ") : "";

    const defaultValues = {
      lastName: data ? name[1] : name,
      firstName: data ? name[0] : name,
      email: data ? data.email : "",
      phone: "44490233451",
      address: {
        country: "US",
        city: "",
        state: "",
        zip: "",
        line1: "",
      },
      dateOfBirth: 1000000,
      notes:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis dignissimos ullam accusantium magni, aut blanditiis quisquam soluta fugiat sequi debitis voluptate laboriosam minima",
      employer: "NASDAQ",
      occupation: "",
      avatarUrl: "",
    };

    const methods = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });
    const { control, watch, setValue, reset } = methods;
    const values = watch();

    const handleChangeStatus: IDropzoneProps["onChangeStatus"] = ({ file }) => {
      if (file) {
        setValue(
          "avatarUrl",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      }
    };

    const handleClose = () => {
      setEdit(false);
      modal.hide();
      if (closeAction) closeAction();
    };

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
      showMessage("Success", "Account has been successfully edited");
      reset(defaultValues);
      handleClose();
    };

    const mockedCustomerData = {
      createdAt: 2345678654,
      id: 0,
      email: "thomasalbertini@lastingdynamics.com",
      merchAccID: 263,
      purchases: [
        {
          name: "fundraiser" as PurchaseProductType,
          total: 1343,
          totalCount: 27,
          customerID: 408,
          count: 27,
        },
        {
          name: "event" as PurchaseProductType,
          total: 135,
          totalCount: 12,
          customerID: 408,
          count: 12,
        },
      ],
      recurringCustomer: false,
      totalPurchased: 1478,
      totalPurchasedCount: 0,
      updatedAt: 1685376888,
    };

    return (
      <Modal
        width="720px"
        {...muiDialogV5(modal)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(20px)",
            background: palette.liftedWhite.main,
          },
          "& .MuiDialogTitle-root": {
            background: palette.liftedWhite.main,
            padding: "12px 16px 0",
            "& > button": {
              background: palette.liftedWhite.main,
            },
          },
          "& .MuiDialogActions-root": {
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          },
        }}
        titleComponent={
          <Box>
            <Text
              variant="h3"
              fontSize={32}
              lineHeight="38px"
              color={palette.gray[300]}
              sx={{ letterSpacing: "-0.01em" }}
            >
              {values.email}
            </Text>
            <Text
              variant="h6"
              lineHeight="22px"
              color={palette.black[100]}
              sx={{ letterSpacing: "-0.01em" }}
            >
              {values.firstName} {values.lastName}
            </Text>
          </Box>
        }
        onClose={handleClose}
        actions={
          edit ? (
            <>
              <Button
                background="secondary"
                size="medium"
                onClick={() => reset()}
                disabled={!edit}
              >
                Discard Changes
              </Button>
              <Button
                background="primary"
                size="medium"
                type="submit"
                disabled={!edit}
                form="view-customer"
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              background="primary"
              onClick={() => setEdit(true)}
              size="medium"
            >
              Edit Customer
            </Button>
          )
        }
      >
        <Banner data={mockedCustomerData} />
        <FormProvider {...methods}>
          <Box
            mb={1}
            id="view-customer"
            component="form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Stack mt={3} direction={"column"}>
              <RHFUploadAvatar
                name="avatarURL"
                disabled={!edit}
                onChangeStatus={handleChangeStatus}
                width="96px"
                height="96px"
                marginBottom="24px"
              />

              {/** --------- FIRST NAME - LAST NAME - EMAIL - PHONE - DATE OF TRANSACTION ------- */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="firstName"
                    fullWidth
                    placeholder="First Name"
                    label="First Name"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="lastName"
                    fullWidth
                    placeholder="Last Name"
                    label="Last Name"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="email"
                    placeholder="example@gmail.com"
                    fullWidth
                    label="Email"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BirthDatePicker
                    name="dateOfBirth"
                    placeholder="MM/DD/YYYY"
                    fullWidth
                    label="Date of Birth"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFTelInput
                    name="phone"
                    fullWidth
                    label="Phone Number"
                    disabled={!edit}
                  />
                </Grid>
              </Grid>
            </Stack>
            {/** ------------------ ADDITIONAL DETAILS ------------------ */}
            <Box mt={3}>
              <Text
                variant="h4"
                fontSize="32px"
                style={{ fontWeight: 350 }}
                lineHeight="38px"
                color={palette.gray[300]}
              >
                Additional Details
              </Text>
              <Grid
                mt={1}
                container
                spacing={2}
                direction="row"
                alignItems="flex-start"
              >
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="occupation"
                    fullWidth
                    placeholder="Enter Occupation"
                    type="text"
                    label="Occupation"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="employer"
                    fullWidth
                    placeholder="Enter Employer"
                    label="Employer"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="address.city"
                    fullWidth
                    placeholder="Albany"
                    type="text"
                    label="City"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="address.state"
                    fullWidth
                    placeholder="New York"
                    type="text"
                    label="State"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ZipCodeInput
                    name="address.zip"
                    placeholder="Enter ZIP Code"
                    label="ZIP"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RHFInput
                    name="address.line1"
                    fullWidth
                    placeholder="Full Address"
                    type="text"
                    label="Address"
                    disabled={!edit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RHFInput
                    name="notes"
                    fullWidth
                    placeholder="Useful notes..."
                    type="text"
                    label="Notes"
                    disabled={!edit}
                    multiline
                    rows={5}
                    inputProps={{
                      maxLength: "200",
                    }}
                    helperText={`${
                      200 - values.notes?.length
                    } Characters remaining`}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </FormProvider>
      </Modal>
    );
  },
);

export default ViewCustomer;
