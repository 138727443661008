import React, { useMemo } from "react";
import NiceModal, { useModal, muiDialogV5 } from "@ebay/nice-modal-react";
import { DialogContent, Stack } from "@mui/material";
import { palette } from "@palette";
import { Text } from "@common/Text";
import { EditorState } from "draft-js";
import { ReadOnlyEditor } from "@components/PaymentForm";
import { BaseModal, ModalTitle } from "@common/Modal";
import { Image } from "@common/StyledImage/Image";
import PaymentCardTitle from "@components/PaymentForm/PaymentCard/PaymentCardTitle";

type ProductInfoModalProps = {
  title: string;
  renderDescription: () => string | EditorState;
  imageURL?: string;
  price: number;
};

type TitleComponentProps = Partial<ProductInfoModalProps>;
const TitleComponent = ({
  imageURL,
  title,
  price,
  ...props
}: TitleComponentProps) => {
  return (
    <Stack
      direction="row"
      gap={2}
      justifyContent="space-between"
      alignItems="start"
      width="100%"
    >
      <Stack pt={0.5} direction="row" gap={2} flexGrow={1} alignItems="start">
        {imageURL && (
          <Image
            width="102px"
            height="102px"
            alt={title}
            src={`${imageURL}/original`}
          />
        )}

        <Stack
          direction="column"
          pt={1}
          gap={0.5}
          flexGrow={1}
          justifyContent="start"
          alignItems="start"
          sx={{ overflow: "hidden" }}
        >
          <PaymentCardTitle
            title={title}
            quantity={10}
            price={price}
            style={{
              paddingTop: 0,
              color: palette.neutral[70],
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const ProductInfoModal = NiceModal.create(
  ({ title, renderDescription, imageURL, price }: ProductInfoModalProps) => {
    const [dialogScrollTop, setDialogScrollTop] = React.useState<number>(0);

    const description = useMemo(() => {
      return renderDescription();
    }, [renderDescription]);
    const isDescriptionValid = React.useRef(
      (description as EditorState).getCurrentContent().getPlainText("\u0001")
        .length,
    );

    const modal = useModal();

    // const handleModalClose = () => {
    //   modal.hide();
    // };

    // const handleModalContentScroll = (
    //   event: React.UIEvent<HTMLElement>,
    // ): void => {
    //   let newScrollValue = event.currentTarget.scrollTop;
    //   if (!imageURL) newScrollValue += 114;
    //   setDialogScrollTop(newScrollValue);
    // };

    return (
      <BaseModal
        width="720px"
        {...muiDialogV5(modal)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "720px",
            maxWidth: "720px",
            background: "#F8F8F6",
            "@media (max-width: 600px)": {
              height: "auto",
              maxHeight: "auto",
              alignSelf: "center",
              margin: 2.5,
            },
          },
          "& .MuiDialogTitle-root + .MuiDialogContent-root": {
            paddingTop: "0px !important",
          },
          "& .MuiDialogActions-root": {
            "@media (max-width: 600px)": {
              flexDirection: "column",
            },
          },
        }}
      >
        {" "}
        <ModalTitle
          titleComponent={
            <TitleComponent title={title} imageURL={imageURL} price={price} />
          }
          onClose={() => modal.hide()}
          backgroundColor="#FFF"
        />
        {isDescriptionValid.current > 0 && (
          <DialogContent
            sx={{
              backgroundColor: "neutral.white",
              padding: 0,
            }}
          >
            <Stack direction="column" gap="12px" position="relative">
              {typeof description === "string" ? (
                <Text
                  color={palette.neutral[600]}
                  variant="headline"
                  sx={{
                    wordBreak: "break-word",
                    position: "relative",

                    "&::before": {
                      ...overlayStyle,
                      display: dialogScrollTop >= 160 ? "block" : "none",
                      top: dialogScrollTop - 184,
                    },
                  }}
                >
                  {description}
                </Text>
              ) : (
                <ReadOnlyEditor
                  editorState={description}
                  scrollTop={dialogScrollTop}
                />
              )}
            </Stack>
          </DialogContent>
        )}
      </BaseModal>
    );
  },
);

const overlayStyle = {
  content: '""',
  width: "100%",
  height: "77px",
  position: "absolute",
  left: 0,
  zIndex: 10,
  background:
    "linear-gradient(180deg, #FFFFFF 19.27%, rgba(255, 255, 255, 0) 100%)",
};

export default ProductInfoModal;
