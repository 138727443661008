import { IInputProps } from "react-dropzone-uploader";
import { palette } from "@palette";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Text } from "@common/Text";
import { styled } from "@mui/styles";
import { UploadIcon } from "@assets/rebrandIcons";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import React from "react";
import { showMessage } from "@common/Toast";
import { DropzoneInputProps } from "react-dropzone";

const InputNew = ({
  customText,
  ...props
}: DropzoneInputProps & { customText?: string }) => {
  const { isDesktopView } = useCustomTheme();

  return (
    <Stack
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100%",
        borderRadius: 12,
      }}
    >
      <Stack
        height="100%"
        width="100%"
        component="label"
        alignItems="center"
        justifyContent="center"
        sx={{
          cursor: "pointer",
        }}
      >
        <StyledContainer>
          <UploadIcon />
          {isDesktopView ? (
            <Stack direction="row" spacing={0.5} mt={1} justifyContent="center">
              <StyledText color={palette.neutral[80]}>
                Drag and drop your files or
              </StyledText>
              <StyledText color="accent.3">click to browse</StyledText>
            </Stack>
          ) : (
            <Text color="accent.3" textAlign="center">
              Tap to browse
            </Text>
          )}
          <Text textAlign="center" color="neutral.70" variant="caption" mt={1}>
            {customText}
          </Text>
        </StyledContainer>
        <input data-testid="file-upload-input" id="upload" {...props} />
      </Stack>
    </Stack>
  );
};

const StyledContainer = styled(Box)({
  textAlign: "center",
});

const StyledText = styled(Text)({
  height: "100%",
});

export default React.memo(InputNew);
