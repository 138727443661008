// import * as React from "react";
// import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import ViewTransactionBannerMobile from "./ViewTransactionBannerMobile";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
// utils
import { parseAmount, toEnFormat } from "@utils/index";

const backgroundGradient = {
  position: "absolute",
  background:
    "linear-gradient(104.33deg, rgba(129, 68, 154, 0) 25.61%, #81449A 54.5%, #E6B96F 82.41%)",
  borderRadius: "12px",
  top: "10px",
  height: "calc( 100% - 20px )",
  width: "calc(100% + 6px)",
};

const mainContent = {
  gap: "16px",
  display: "flex",
  padding: "16px 24px",
  flexDirection: "column",
  backgroundColor: "rgba(246, 250, 255, 0.8)",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  backdropFilter: "blur( 32px )",
  position: "relative",
  borderRadius: "12px",
  width: "100%",

  "& .MuiTypography-root > sup": {
    fontSize: "14px",
    lineHeight: "14px",
  },
};

const data = [
  {
    amount: 13527,
    type: "Processed",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 13527,
    type: "Approved Total",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 0,
    type: "Refund Total",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 0,
    type: "Chargeback Total",
    isCurrency: true,
    isPercentage: false,
  },
  {
    amount: 7,
    type: "Transactions",
    isCurrency: false,
    isPercentage: false,
  },
  {
    amount: 0,
    type: "Approved",
    isCurrency: false,
    isPercentage: true,
  },
  {
    amount: 0,
    type: "Refunds",
    isCurrency: false,
    isPercentage: true,
  },
  {
    amount: 0,
    type: "Chargebacks",
    isCurrency: false,
    isPercentage: true,
  },
];

const ViewTransactionBanner = () => {
  const theme = useTheme();
  const { t } = useTranslation(namespaces.pages.manageMoney);
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  if (isDesktop) {
    return (
      <Box position="relative">
        {/* Background gradient */}
        <Box sx={backgroundGradient} />
        <Box sx={mainContent}>
          <Box display="flex" justifyContent="space-between">
            <Grid container spacing={2}>
              {data.map((item, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <Box display="flex" flexDirection="column" width="207px">
                    {item.isCurrency && (
                      <Text
                        variant="h4"
                        fontWeight="medium"
                        color={theme.palette.info.main}
                      >
                        {parseAmount(item.amount)}
                      </Text>
                    )}

                    {!item.isCurrency && !item.isPercentage && (
                      <Text
                        variant="h4"
                        fontWeight="medium"
                        color={theme.palette.info.main}
                      >
                        {item.amount}
                      </Text>
                    )}
                    {!item.isCurrency && item.isPercentage && (
                      <Text
                        variant="h4"
                        fontWeight="medium"
                        color={theme.palette.info.main}
                      >
                        {parseAmount(item.amount)}
                      </Text>
                    )}
                    <Text
                      variant="caption"
                      fontWeight="medium"
                      color={theme.palette.neutral[600]}
                    >
                      {item.type}
                    </Text>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }

  return <ViewTransactionBannerMobile />;
};

export default ViewTransactionBanner;
