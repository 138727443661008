import { FiltersSettingsIcon } from "@assets/analyticsIcons";
import { Radio } from "@common/Radio";
import { Text } from "@common/Text";
import { Menu, styled } from "@mui/material";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import React, { useState } from "react";
import { Filter } from "./TimeLineChartFilters";

interface DateFilterProps {
  filters: Filter[];
  setFilter: (index: number) => void;
  selectedFilter: number;
}

export const RadioDateFilters = ({
  filters,
  setFilter,
  selectedFilter,
}: DateFilterProps) => {
  return (
    <RadiosContainer>
      {filters.map(({ title, onClick }, idx) => (
        <CustomRadio
          key={idx}
          title={title}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setFilter(idx);
            if (onClick) onClick(event);
          }}
          checked={selectedFilter === idx}
          titleProps={{
            fontSize: "14px",
            lineHeight: "16.8px",
            fontWeight: "book",
            color: palette.black[100],
          }}
        />
      ))}
    </RadiosContainer>
  );
};

const RadiosContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "24px",
  alignItems: "center",
  overflowX: "auto",
  whiteSpace: "nowrap",
  minHeight: "30px",

  "&::-webkit-scrollbar-track": {
    background: "inherit",
  },
  "&::-webkit-scrollbar-thumb": {
    display: "none",
    width: "1px",
  },
  [theme.breakpoints.down("sm")]: {
    WebkitOverflowScrolling: "touch",
  },
}));

const CustomRadio = styled(Radio)(() => ({
  "& .RadioIcon": {
    width: "20px",
    height: "20px",
    backgroundColor: "inherit",

    "&:before": {
      width: "10px",
      height: "10px",
    },
  },
}));

export const ButtonDateFilter = (props: DateFilterProps) => {
  const { filters, selectedFilter } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton onClick={handleClick}>
        <Text color={palette.black[300]} fontWeight="book" lineHeight="16.8px">
          {filters[selectedFilter].title}
        </Text>
        <FiltersSettingsIcon />
      </CustomButton>
      <CustomMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <RadioDateFilters {...props} />
      </CustomMenu>
    </>
  );
};

const CustomMenu = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    padding: "16px",
    backgroundColor: palette.neutral.white,
  },
}));

const CustomButton = styled(Box)(() => ({
  display: "flex",
  padding: "4px 8px",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  borderRadius: "4px",
  backgroundColor: palette.liftedWhite[100],
  cursor: "pointer",
  userSelect: "none",
}));
