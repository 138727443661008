import { Text } from "@common/Text"
import { Box, styled } from "@mui/material"
import { palette } from "@palette"

const ComingSoonTag = () => {
  return (
    <TagContainer>
      <Text
        color={palette.filled.blue}
        variant="caption"
        lineHeight="14.4px"
      >
        Coming&nbsp;soon
      </Text>
    </TagContainer>
  )
}

const TagContainer = styled(Box)(() => ({
  borderRadius: "4px",
  background: palette.info.light,
  height: "22px",
  padding: "2px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

export default ComingSoonTag;
