import { useModal } from "@ebay/nice-modal-react";

const useNiceModal = () => {
  const modal = useModal();

  const onClose = () => {
    modal.hide();
  };

  const TransitionProps = {
    onExited: () => {
      modal.resolveHide();
      !modal.keepMounted && modal.remove();
    },
  };

  const SlideProps = {
    onExit: () => {
      setTimeout(() => {
        modal.remove();
      }, 90);
    },
  };

  return {
    modal,
    open: modal.visible,
    onClose,
    TransitionProps: TransitionProps,
    SlideProps,
  };
};

export default useNiceModal;
