import React from "react";
import { palette } from "@palette";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { Button } from "@common/Button";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { RHFCheckbox } from "@common/Checkbox";
import { showMessage } from "@common/Toast/ShowToast";
import { DatePicker } from "@common/DatePickers";
// assets
import { CheckIcon, RefreshIcon } from "@assets/icons";
import { useModiFyRecurring } from "@services/api/products/transactions";
import { toUnixDateFormat } from "@utils/date.helpers";
import { useQueryClient } from "react-query";

type Data = {
  title: string;
  description: string;
  type: string;
  recurrence: string;
  nextTransaction: string;
  orderRecurringItemID: string;
  details: {
    donation: number;
    giveboxFee: number;
    visaFee: number;
    charged: number;
  };
  [x: string]: any;
};

type ModifyRecurringProps = {
  data: Data;
  orderRecurringItemID: string;
};

type IFormInputs = {
  reccurence: string;
  date: number | Date;
  numberOfPayments: number;
  cancelRecurring: boolean;
};

const ProcessingModifyRecurring = NiceModal.create(
  ({ data, orderRecurringItemID }: ModifyRecurringProps) => {
    const min = 1;
    const max = 999;
    const theme = useTheme();
    const modal = useModal();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const [confirm, setConfirm] = React.useState<boolean>(false);
    const [hasChanges, setHasChanges] = React.useState<boolean>(false);

    const { mutate, isLoading } = useModiFyRecurring(orderRecurringItemID);

    const handleClose = () => {
      modal.hide();
    };

    const schema = Yup.object({
      reccurence: Yup.string().required("This field is required"),
      date: Yup.date().required("This field is required"),
      numberOfPayments: Yup.number(),
      cancelRecurring: Yup.bool(),
    });

    const defaultValues = {
      recurrence: "Monthly",
      date: new Date("08-17-2021"),
      numberOfPayments: 13,
      cancelRecurring: false,
    };

    const methods = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const { watch, control, setValue } = methods;
    const cancelRecurring = watch("cancelRecurring");

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = parseInt(e.target.value, 10);

      if (value > max) value = max;
      if (value < min) value = min;

      setValue("numberOfPayments", value);
    };

    React.useEffect(() => {
      const subscription = watch(() => setHasChanges(true));
      return () => subscription.unsubscribe();
    }, [watch]);

    const client = useQueryClient();

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {

      if (isDesktop) {
        setConfirm(false);

        if (cancelRecurring) {
          showMessage(
            "Info",
            "The recurrence of this order has been cancelled",
          );
        } else if (!hasChanges) {
          handleClose();
        } else if (hasChanges) {
          showMessage("Success", "Changes succesfully applied");
          showMessage("Info", "Recurrence changed");
        }
      }
      handleClose();

      const obj = {
        nextAt: toUnixDateFormat(new Date(methods.watch("date"))),
        recurringMax: methods.watch("numberOfPayments"),
      };

      const maxNumberOfPayments = methods.watch("numberOfPayments");
      const isCanceled = methods.watch("cancelRecurring");

      if (isCanceled) {
        (obj as any).isCancelled = isCanceled;
      }

      if (!Number.isNaN(Number(maxNumberOfPayments))) {
        (obj as any).recurringMax = Number(maxNumberOfPayments);
      }

      mutate(obj, {
        onSuccess: () => {
          const queryKeysToInvalidate = client
            .getQueryCache()
            .findAll("get-all-transactions")
            .map((query) => query.queryKey);

          queryKeysToInvalidate.forEach((x) => client.refetchQueries(x));
        },
      });
    };

    return (
      <Modal
        width="751px"
        {...muiDialogV5(modal)}
        title="Modify Recurring Purchase"
        titleIcon={
          <RefreshIcon width={36} height={37} fill="#A9AFBD" stroke="#A9AFBD" />
        }
        onClose={handleClose}
        closeAfterTransition
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            {confirm ? (
              <Button
                variant="primary"
                onClick={() => setConfirm(false)}
                endIcon={
                  <CheckIcon
                    fill={palette.success.main}
                    height={18}
                    width={18}
                  />
                }
              >
                Confirm
              </Button>
            ) : (
              <Button
                variant="primary"
                form="modify-recurring-form"
                type="submit"
                onClick={() => setConfirm(true)}
                disabled={isLoading}
              >
                Update
              </Button>
            )}
          </>
        }
      >
        <Stack
          gap={2}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
        >
          <Box
            component="img"
            src="/settings-recurringmodify.png"
            alt="modify recurring settings"
          />
          <Stack gap={1}>
            <Text
              variant="h6"
              fontWeight="semibold"
              color={palette.neutral[800]}
            >
              {`${data.fullName}, ${data.email}, ${data.card.brand} ${data.card.numberLast4}`}
            </Text>
            <Text
              variant="body"
              fontWeight="regular"
              color={palette.neutral[600]}
            >
              Fill the fields below and click “update”
            </Text>
          </Stack>
        </Stack>

        <FormProvider {...methods}>
          <Grid
            mt={3}
            container
            spacing={2}
            component="form"
            id="modify-recurring-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Grid item xs={12} sm={6}>
              <RHFSelect
                name="reccurence"
                label="Reccurence"
                options={[
                  {
                    value: "daily",
                    label: "Daily",
                  },
                  {
                    value: "monthly",
                    label: "Monthly",
                  },
                  {
                    value: "quarterly",
                    label: "Quarterly",
                  },
                  {
                    value: "yearly",
                    label: "Yearly",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="date"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <DatePicker
                    {...rest}
                    inputFormat="MM/dd/yyyy"
                    label="Date of Next Transaction"
                    renderInput={(params: any) => (
                      <TextField
                        inputRef={ref}
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFInput
                name="numberOfPayments"
                placeholder="Leave blank for unlimited payments"
                label="Number of Payments"
                type="number"
                fullWidth
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFCheckbox
                name="cancelRecurring"
                label="Cancel this Recurring Order"
                helperText="If checked the next transaction will be cancelled"
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Modal>
    );
  },
);

export default ProcessingModifyRecurring;
