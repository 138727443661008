import React from "react";
import { Stack, Box, useTheme, useMediaQuery } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { intervalToDuration, isBefore } from "date-fns";

type CountdownContainerProps = {
  endDate: Date | undefined;
  sweepstakesEnded: boolean;
  setSweepstakesEnded: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop: boolean;
  fontSize?: string;
};

type TimeLeft = {
  days: number;
  hrs: number;
  min: number;
  sec: number;
};

const CountdownContainer = ({
  sweepstakesEnded,
  setSweepstakesEnded,
  endDate,
  isDesktop,
  fontSize = "12px",
}: CountdownContainerProps) => {
  const [timeLeft, setTimeLeft] = React.useState<TimeLeft>({
    days: 0,
    hrs: 0,
    min: 0,
    sec: 0,
  });
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (endDate) {
      if (!isBefore(endDate, new Date())) {
        const interval = setInterval(() => {
          try {
            const dateNow = new Date();
            const duration = intervalToDuration({
              start: dateNow,
              end: endDate,
            });
            const { days, hours, minutes, seconds } = duration;
            let totalDays = days || 0;
            if (duration.months) totalDays += duration.months * 30;
            if (duration.years) totalDays += duration.years * 365;

            if (
              totalDays === 0 &&
              hours === 0 &&
              minutes === 0 &&
              seconds === 0
            ) {
              handleSweepstakesEnd();
              clearInterval(timerRef.current as NodeJS.Timeout);
              return;
            }
            setTimeLeft({
              days: totalDays || 0,
              hrs: hours || 0,
              min: minutes || 0,
              sec: seconds || 0,
            });
          } catch (err) {
            handleSweepstakesEnd();
            clearInterval(timerRef.current as NodeJS.Timeout);
          }
        }, 1000);

        timerRef.current = interval;
      } else {
        handleSweepstakesEnd();
      }
    }
  }, [endDate]);

  const handleSweepstakesEnd = () => {
    setTimeLeft({
      days: 0,
      hrs: 0,
      min: 0,
      sec: 0,
    });
    setSweepstakesEnded(true);
  };

  React.useEffect(() => {
    return () => clearInterval(timerRef.current as NodeJS.Timeout);
  }, []);

  if (!endDate) return <></>;
  const backgroundColor = React.useMemo(() => {
    switch (true) {
      case sweepstakesEnded:
        return "#B8B8B8";
      case timeLeft.hrs < 24 && timeLeft.days === 0:
        return "#FF8124";
      case Object.values(timeLeft).every((key) => key === 0):
        return "#B8B8B8";
      default:
        return "#088745";
    }
  }, [timeLeft]);

  return (
    <Stack direction="row" gap={isDesktop ? 2 : "4px"} alignItems="center">
      <Box
        sx={{
          backgroundColor,
          width: "14px",
          height: "14px",
          borderRadius: "50%",
        }}
      ></Box>
      <NumberIndicator
        label={isDesktop ? "Days" : "days"}
        disabled={sweepstakesEnded}
        value={timeLeft.days}
        fontSize={!isDesktop ? fontSize : "12px"}
      />
      {!isDesktop && <Text>:</Text>}
      <NumberIndicator
        label={timeLeft.hrs === 1 ? "h" : "hrs"}
        disabled={sweepstakesEnded}
        value={timeLeft.hrs}
        fontSize={!isDesktop ? fontSize : "12px"}
      />
      {!isDesktop && <Text>:</Text>}

      <NumberIndicator
        label={"min"}
        disabled={sweepstakesEnded}
        value={timeLeft.min}
        fontSize={!isDesktop ? fontSize : "12px"}
      />
      {!isDesktop && <Text>:</Text>}
      <NumberIndicator
        label={"sec"}
        disabled={sweepstakesEnded}
        value={timeLeft.sec}
        fontSize={!isDesktop ? fontSize : "12px"}
      />
      {/* <Text color={palette.neutral[600]} variant="headline" fontStyle="italic">
        {sweepstakesEnded
          ? "Sweepstakes has ended, the winner will be chosen soon"
          : "Until Sweepstakes Ends"}
      </Text> */}
    </Stack>
  );
};

type NumberIndicatorProps = {
  label: string;
  value: number;
  disabled: boolean;
  fontSize: string;
};

const NumberIndicator = ({
  label,
  value,
  disabled,
  fontSize,
}: NumberIndicatorProps) => {
  const mainColor = React.useMemo(() => {
    return disabled ? palette.neutral[200] : palette.primary.main;
  }, [disabled]);

  const borderColor = React.useMemo(() => {
    return disabled ? palette.neutral[200] : palette.primary[600];
  }, [disabled]);

  const backgroundColor = React.useMemo(() => {
    return disabled ? "rgba(115, 64, 137, 0.05)" : "rgba(115, 64, 137, 0.1)";
  }, [disabled]);

  return (
    <Text
      lineHeight="36px"
      sx={{
        color: "neutral.black",
        fontSize: fontSize || "18px",
      }}
    >
      {value} {label}
    </Text>
  );
};

const timeIndicatorStyle = {
  borderRadius: "50%",
  width: "92px",
  height: "92px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "4px",
};

export default CountdownContainer;
