import { CSSProperties } from "react";

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
  strokeWidth?: number;
  style?: CSSProperties;
};

const MinusIcon = ({
  width = 17,
  height = 2,
  stroke = "#9C9AA3",
  strokeWidth = 2,
  style,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.5 1H15.5"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MinusIcon;
