import React, { Dispatch, SetStateAction, useState } from "react";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectCart, setCartItem, setCartItems } from "@redux/slices/cart";
import { InvoiceType } from "./types";
import { selectVariant, setIsPaymentRecurring } from "@redux/slices/checkout";
import { sanitizer } from "@utils/index";
import RadioPaymentCardWrapper from "@common/PublicForm/RadioCardWrapper";
import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import { usePaymentFormUtils } from "@common/PublicForm/utils";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { getPublishedFormDeviceType } from "@pages/NewAdvancedBuilder/utils/helpers";

const InvoicesDetailsForm = ({
  data,
  isLoading,
  drawerOpen,
}: {
  data: InvoiceType;
  isLoading?: boolean;
  drawerOpen?: Partial<{
    ID: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    renderCheckout: () => React.ReactNode;
  }>;
}) => {
  const dispatch = useAppDispatch();
  const { cartItems } = useAppSelector(selectCart);
  const [checkedVariant, setCheckedVariant] = React.useState<string>("");
  const [currentVariantPrice, setCurrentVariantPrice] = React.useState(0);
  const { isDesktop } = getPublishedFormDeviceType();
  const { invoice } = useAppSelector(selectVariant);

  const [customDonation, setCustomDonation] = React.useState<number>(
    Number(+invoice.min),
  );

  const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(
    data?.payment.payment_types.default !== "one_time",
  );

  const addToCartHandler = (item: {
    recurringInterval: string;
    recurringMax: number | null;
  }) => {
    if (!checkedVariant) return;
    const existingCartItem = cartItems.find(
      (item) => item.productVariantID === +checkedVariant,
    );
    if (existingCartItem) {
      dispatch(
        setCartItem({
          ...existingCartItem,
          ...item,
        }),
      );
    }
  };

  const {
    duration,
    subDuration,
    handleChangeDuration,
    getRecurringMax,
    renderOptionsHelper,
    handleChangeSubDuration,
  } = usePaymentFormUtils({ defaultMonthlyRecurringMax: null });

  React.useEffect(() => {
    dispatch(setIsPaymentRecurring(isRecurringChecked));
  }, [isRecurringChecked]);

  const handleCheckboxChange = (id: string) => {
    if (checkedVariant && checkedVariant === id) {
      return;
    }
    setCheckedVariant(id);

    data?.payment?.amountsList?.forEach((amount: any) => {
      const subscription = amount.id === +id && amount;

      if (subscription) {
        const defaultPayment = sanitizer(data.payment.payment_types.default);
        const item = {
          productVariantID: subscription.id,
          price: subscription.amount,
          recurringInterval: defaultPayment,
          recurringMax: getRecurringMax() || null,
          quantity: 1,
        };
        dispatch(setCartItems([{ ...item }]));
      }
    });
  };

  React.useEffect(() => {
    const currentVariantData = data?.payment.amountsList.filter(
      (variant: any) => variant.id.toString() === checkedVariant,
    );

    if (currentVariantData && currentVariantData.length > 0) {
      const numberPrice = parseInt(currentVariantData[0].amount);
      if (!Number.isNaN(numberPrice)) {
        setCurrentVariantPrice(numberPrice);
      } else {
        setCurrentVariantPrice(0);
      }
    }
  }, [checkedVariant]);

  const availablePaymentMethods = React.useMemo(() => {
    if (!data || !data.payment.payment_types) return [];

    const paymentTypes = data.payment.payment_types;
    const defaultType = paymentTypes.default;
    const paymentTypeKeys = Object.keys(paymentTypes).filter((key) => {
      const paymentTypeObject = paymentTypes as Record<string, boolean>;
      return key !== "once" && key !== "default" && paymentTypeObject[key];
    });

    const sortedPaymentTypes = paymentTypeKeys.sort((a, b) => {
      if (a === defaultType) return -1;
      if (b === defaultType) return 1;
      return 0;
    });

    return sortedPaymentTypes;
  }, [data]);

  const defaultRecurrence = sanitizer(availablePaymentMethods[0]);

  React.useEffect(() => {
    if (availablePaymentMethods.length > 0) {
      handleChangeDuration(defaultRecurrence, addToCartHandler);
    }
  }, []);

  const reset = () => {
    setCheckedVariant("");
    setCustomDonation(Number(+invoice.min));
    handleChangeDuration(defaultRecurrence, addToCartHandler);
  };

  // on success payment, cartitems is set empty, so resets the selected contribution item also
  React.useEffect(() => {
    if (cartItems.length === 0) {
      reset();
    }
  }, [cartItems]);

  const handleCustomDonation = (newValue: number) => {
    setCustomDonation(newValue);
    if (!checkedVariant) return;

    const existingCartItem = cartItems.find(
      (item) => item.productVariantID === +checkedVariant,
    );
    if (existingCartItem) {
      dispatch(
        setCartItem({
          ...existingCartItem,
          price: currentVariantPrice === 0 ? newValue : currentVariantPrice,
        }),
      );
    }
  };

  const handleSetValue = (value: number) => {
    if (value > invoice.max) return handleCustomDonation(invoice.max);
    if (value < invoice.min) return handleCustomDonation(invoice.min);
    if (value === 0) return handleCustomDonation(invoice.min || 1);
    handleCustomDonation(value);
  };

  return (
    <>
      {!isDesktop ? (
        drawerOpen &&
        ComponentRegistry.show(drawerOpen.ID || "", {
          open: !!drawerOpen.open,
          setOpen: drawerOpen.setOpen,
          _renderComponent: () => (
            <ErrorCatcher errorID="RadioPaymentCardWrapper">
              <RadioPaymentCardWrapper
                data={data}
                checkedVariant={checkedVariant}
                duration={duration}
                subDuration={subDuration}
                campaign={invoice}
                handleCheckboxChange={handleCheckboxChange}
                handleSetValue={handleSetValue}
                customDonation={customDonation}
                availablePaymentMethods={availablePaymentMethods}
                palette={palette}
                renderOptionsHelper={renderOptionsHelper}
                handleChangeDuration={handleChangeDuration}
                handleChangeSubDuration={handleChangeSubDuration}
                isDesktop={isDesktop}
                addToCartHandler={addToCartHandler}
              />
            </ErrorCatcher>
          ),
          _renderActions: () =>
            drawerOpen.renderCheckout && drawerOpen.renderCheckout(),
        })
      ) : (
        <RadioPaymentCardWrapper
          data={data}
          checkedVariant={checkedVariant}
          duration={duration}
          subDuration={subDuration}
          campaign={invoice}
          handleCheckboxChange={handleCheckboxChange}
          handleSetValue={handleSetValue}
          customDonation={customDonation}
          availablePaymentMethods={availablePaymentMethods}
          palette={palette}
          renderOptionsHelper={renderOptionsHelper}
          handleChangeDuration={handleChangeDuration}
          handleChangeSubDuration={handleChangeSubDuration}
          isDesktop={isDesktop}
          addToCartHandler={addToCartHandler}
        />
      )}
    </>
  );
};

const donationListContainer = {
  width: "100%",
};

export default InvoicesDetailsForm;
