import { FileUploadIcon } from "@assets/icons";

import { CircularProgress, Stack } from "@mui/material";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { palette } from "@palette";
import { Text, TruncateText } from "@common/Text";
import {
  ProgressItem,
  useGetUploadProgress,
  useUploadProgress,
} from "@redux/slices/uploadProgressSlice";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useDeleteFile } from "@hooks/upload-api/useDeleteFile";
import { bytesToSize } from "@utils/index";
import UploadIconThick from "@assets/rebrandIcons/UploadIconThick";
import { styled } from "@mui/material";
import { Button } from "@common/Button";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import {
  FileStatus,
  getFileStatus,
  getRightSideStatus,
  Right,
  UploadSnackbarLinearProgress,
} from "@components/UploadFile/NotificationStack";
import { useDebouncedCallback } from "use-debounce";

const useMatchQueriesProgress = () => {
  const { mutateAsync: deleteFile, isLoading: isLoadingDelete } =
    useDeleteFile();
  const { merchantId } = useGetCurrentMerchantId();
  const progress = useGetUploadProgress();
  const { deleteUploadProgress, hideUploadProgress, setUploadProgress } =
    useUploadProgress();
  return {
    progress,
    deleteUploadProgress,
    hideUploadProgress,
    setUploadProgress,
    merchantId,
    deleteFile,
    isLoadingDelete,
  };
};

const NotificationStackMobile = () => {
  const { progress, hideUploadProgress } = useMatchQueriesProgress();
  //

  const [openModal, setOpenModal] = useState(false);

  const items = progress;
  //
  const isUploadFailed = false;
  const progressKeys = Object.keys(progress);

  const fileUploadAverageProgress = useMemo(() => {
    let totalProgress = 0;
    let totalSize = 0;

    progressKeys.forEach((key) => {
      if (
        progress &&
        progress[key].progress &&
        progress[key].size &&
        !progress[key].tooLarge
      ) {
        totalProgress =
          totalProgress +
          (progress?.[key]?.progress || 0) * (progress?.[key]?.size || 0);
        totalSize = totalSize + (progress?.[key]?.size || 0);
      }
    });
    if (totalSize === 0) {
      return 0;
    }
    return totalProgress / totalSize || 0;
  }, [progress]);

  const hideSnackbar = () => {
    if (openModal) return;
    Object.keys(items).forEach((key) => {
      hideUploadProgress(key);
    });
  };

  const hide = useDebouncedCallback(hideSnackbar, 2500);

  useEffect(() => {
    const noCurrentUploading =
      Object.values(items).length >= 1 &&
      Object.values(items).every(
        (item) =>
          item.success ||
          item.failed ||
          item.canceled ||
          item.tooLarge ||
          item.unsuported ||
          item.tooManyFiles,
      );

    if (noCurrentUploading && !openModal) {
      hide();
    } else {
      hide.cancel();
    }
  }, [progress, hideUploadProgress, openModal, items]);
  if (progressKeys.length < 1) return null;

  return (
    <>
      {!openModal && (
        <UploadStatusButton
          onClick={() => setOpenModal(true)}
          isUploadFailed={isUploadFailed}
          fileUploadAverageProgress={fileUploadAverageProgress}
        />
      )}
      <ModalFactory
        variant="dialog"
        renderMobile
        modalProps={{
          sx: { zIndex: 1300 },
          open: openModal,
          onClose: () => setOpenModal(false),
          DrawerProps: {
            dataTestId: "uploading-files-drawer",
          },
        }}
      >
        <Puller />
        <Text
          color="#575353"
          textAlign="center"
          fontWeight={"book"}
          fontSize="18px"
          pb={"20px"}
        >
          Uploads
        </Text>
        <Stack
          flex={1}
          gap={2}
          sx={{ overflowX: "scroll", px: "12px", pb: "90px", pt: "8px" }}
        >
          {Object.keys(items).map((key) => {
            return <IndividualCard key={key} id={key} item={items[key]} />;
          })}
        </Stack>
        <Stack bottom="20px" position="absolute" pt="20px" width="100%">
          <Button
            onClick={() => setOpenModal(false)}
            sx={{ width: "170px", margin: "auto" }}
          >
            Close
          </Button>
        </Stack>
      </ModalFactory>
    </>
  );
};

const IndividualCard = ({ item, id }: { item: ProgressItem; id: string }) => {
  const { sizeString } = bytesToSize(item.size);

  return (
    <Box
      sx={{
        height: "60px",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: palette.neutral.white,
        boxShadow: "0px 3px 15px 0px rgba(2, 2, 2, 0.15)",
        padding: "4px 16px",
      }}
    >
      <Stack direction="column" height="100%" width="100%" alignItems="center">
        <Stack direction="row" height="100%" width="100%" alignItems="center">
          <Box sx={{ mr: "12px" }}>
            <FileUploadIcon />
          </Box>
          <Stack
            direction="column"
            height="100%"
            justifyContent="center"
            width="100%"
            flex={1}
          >
            <TruncateText lineClamp={1}>{item.fileName}</TruncateText>
            <FileStatus status={getFileStatus(item)} />
          </Stack>
          <Right
            type={getRightSideStatus(item, false).type}
            size={sizeString}
          />
        </Stack>
        <UploadSnackbarLinearProgress item={item} />
      </Stack>
    </Box>
  );
};

export default NotificationStackMobile;

const UploadStatusButton = ({
  onClick,
  isUploadFailed,
  fileUploadAverageProgress,
}: {
  onClick: () => void;
  isUploadFailed: boolean;
  fileUploadAverageProgress: number;
}) => {
  return (
    <Box
      onClick={onClick}
      position="fixed"
      bottom="60px"
      right="20px"
      zIndex={1301}
      data-testid="file-upload-floating-button"
    >
      <Box
        data-testid="file-upload-floating-button-data"
        sx={{
          height: "52px",
          width: "52px",
          backgroundColor: isUploadFailed ? "#F5E0DF" : "#E6EAF2",
          borderRadius: "26px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ position: "absolute", top: "-5px" }}>
          <CircularProgress
            size="62px"
            variant="determinate"
            value={isUploadFailed ? 0 : fileUploadAverageProgress}
            sx={{ color: "#6D9CF8", strokeLinecap: "round" }}
            data-testid="file-upload-floating-button-progress"
          />
        </Box>

        <UploadIconThick fill={isUploadFailed ? "#D80D0D" : "#6D9CF8"} />
      </Box>
    </Box>
  );
};

const Puller = styled(Box)(() => ({
  width: 52,
  height: 4,
  backgroundColor: "#FDFDFD",
  borderRadius: 3,
  position: "absolute",
  top: "-12px",
  zIndex: 1500,
  left: "calc(50% - 26px)",
}));
