import { useFormContext } from "react-hook-form";
import { IDropzoneProps, IFileWithMeta } from "react-dropzone-uploader";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { UploadFile } from "@components/UploadFile";
import { palette } from "@palette";
import { useGetCurrentMerchantId } from "@hooks/common";
import { DocumentItem } from "@components/Merchants/MerchantPreview/components/DocumentItem";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useMemo } from "react";
import {
  TBankFormInputsNew,
  TBankFormInputsOld,
} from "../../../hooks/onboarding/useAddOnboardingBankAccounts";
import mime from "mime";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { toUnixDateFormat } from "@utils/date.helpers";
import { showMessage } from "@common/Toast";
import { FileUploadStatus } from "@components/UploadFile/types";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { AcceptAllowedGeneralDocumentsTypes } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

type Props = {
  firstAccount?: any;
  bankFiles?: { data: TMerchantDocument[]; total: number };
  bankFilesLoading?: boolean;
};

export const INVALID_STATUSES = ["error_file_size", "preparing", "removed"];

const OnboardingBankStatement = ({ bankFiles }: Props) => {
  const { setValue, watch } = useFormContext<
    TBankFormInputsNew | TBankFormInputsOld
  >();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { isDesktopView } = useCustomTheme();
  const values = watch();
  const { merchantId } = useGetCurrentMerchantId();

  const showErroMessage = () => {
    showMessage(
      "Error",
      FileUploadStatus.FILE_TOO_LARGE,
      isDesktopView,
      undefined,
      2000,
    );
  };
  const selectFiles = (file: File) => {
    setValue(
      "files",
      {
        allFiles: values?.files?.allFiles
          ? [
              ...(values?.files
                ?.allFiles as TBankFormInputsNew["files"]["allFiles"]),
              { file, id: Date.now() },
            ]
          : [{ file, id: Date.now() }],
      },
      { shouldDirty: true },
    );
  };
  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
    allFiles,
  ) => {
    if (INVALID_STATUSES.includes(status)) {
      const isArray = Array.isArray(allFiles);
      if (status === "error_file_size" && isArray) {
        allFiles[allFiles.length - 1]?.remove();
        showErroMessage();
      }
      return;
    }
    setValue(
      "files",
      {
        fileWithMeta,
        status,
        allFiles,
      } as any,
      { shouldDirty: true },
    );
  };

  const deleteFileHandlerOld = (id: string) => {
    const newIndex = values?.files?.allFiles.findIndex(
      (item: IFileWithMeta) => item.meta.id === id,
    );
    values.files.allFiles[newIndex]?.remove();
    setValue("files", {
      ...values.files,
      allFiles: values?.files?.allFiles.filter(
        (item: any) => item.meta.id !== id,
      ),
    } as any);
  };

  const deleteFileHandlerNew = (index: number) => {
    setValue("files", {
      ...values.files,
      allFiles: values?.files?.allFiles.filter((_, i) => i !== index),
    } as any);
  };

  const user = useAppSelector(selectUser);
  const userFullName =
    user.globalName.firstName + " " + user.globalName.lastName;

  const joinedList = useMemo(() => {
    const localList = values?.files?.allFiles.map((file, index) =>
      parseDocument(file, index, userFullName, isFileUploadRefactorEnabled),
    );
    return [...localList, ...(bankFiles?.data || [])];
  }, [values?.files?.allFiles, bankFiles?.data]);

  return (
    <Stack direction="column" gap={1} alignItems="stretch">
      <DocumentUploadDisclaimer />

      <FlaggedWrapper
        ActiveComponent={
          <UploadFileNew
            uploadFunction={selectFiles}
            accept={AcceptAllowedGeneralDocumentsTypes}
          />
        }
        FallbackComponent={
          <UploadFile
            backgroundColor="white"
            onChangeStatus={handleChangeStatus}
            width="100%"
          />
        }
        name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
      />
      <Stack direction="column" gap={0.5}>
        {isFileUploadRefactorEnabled
          ? values?.files?.allFiles?.map(
              (
                file: {
                  file: File;
                  id: number;
                },
                index: number,
              ) => {
                const parsedDocument = parseDocument(
                  file,
                  index,
                  userFullName,
                  isFileUploadRefactorEnabled,
                );
                return (
                  <DocumentItem
                    key={index}
                    merchantID={merchantId}
                    list={joinedList}
                    localDeleteHandler={() => deleteFileHandlerNew(index)}
                    hiddenActionProps={{
                      hidden: false,
                    }}
                    {...parsedDocument}
                  />
                );
              },
            )
          : (values as TBankFormInputsOld)?.files?.allFiles?.map(
              (file: IFileWithMeta, index: number) => {
                const parsedDocument = parseDocument(
                  file,
                  index,
                  userFullName,
                  isFileUploadRefactorEnabled,
                );
                return (
                  <DocumentItem
                    key={file.meta.id}
                    merchantID={merchantId}
                    list={joinedList}
                    localDeleteHandler={() =>
                      deleteFileHandlerOld(file.meta.id)
                    }
                    hiddenActionProps={{
                      hidden: false,
                    }}
                    {...parsedDocument}
                  />
                );
              },
            )}
        {bankFiles?.data?.map((file) => {
          return (
            <DocumentItem
              key={file.id}
              merchantID={merchantId}
              list={joinedList}
              refetcher="get-bank-files"
              hiddenActionProps={{
                hidden: false,
              }}
              {...file}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default OnboardingBankStatement;

const parseDocument = (
  localUpload:
    | {
        file: File;
        id: number;
      }
    | IFileWithMeta,
  index: number,
  userFullName: string,
  isFileUploadRefactorEnabled?: boolean,
): TMerchantDocument => {
  const data = {
    id: index,
    attTypeID: 0,
    attTypeName: "",
    accID: 0,
    fileMetadata: null,
    s3ObjectKey: "",
    label: "",
    notes: "",
    tag: "",
    isUploaded: false,
    bankAccountID: 0,
    legalPrincipalID: 0,
    accMemberUserID: 0,
    underwritingProfileID: 0,
    userFullName,
  } as TMerchantDocument;

  let additionalData = {};
  if (isFileUploadRefactorEnabled) {
    const { file, id } = localUpload as {
      file: File;
      id: number;
    };
    const fileType = mime.getExtension(file.type) || "";
    const uploadedAt = toUnixDateFormat(new Date(id));
    additionalData = {
      fileURL: URL.createObjectURL(file),
      fileName: file.name,
      fileType,
      createdAt: uploadedAt,
      updatedAt: uploadedAt,
      userFullName,
    };
  } else {
    const { meta } = localUpload as IFileWithMeta;
    const fileType = mime.getExtension(meta.type) || "";
    const uploadedAt = toUnixDateFormat(new Date(meta.uploadedDate));
    additionalData = {
      fileURL: meta.previewUrl || "",
      fileName: meta.name,
      fileType,
      createdAt: uploadedAt,
      updatedAt: uploadedAt,
      userFullName,
    };
  }
  return { ...data, ...additionalData };
};

const DocumentUploadDisclaimer = () => {
  return (
    <>
      <Text
        fontSize={18}
        variant="headline"
        fontWeight="book"
        lineHeight="21.6px"
        color={palette.neutral[80]}
      >
        Bank account documents
      </Text>
      <Text
        fontSize={14}
        variant="headline"
        fontWeight="book"
        lineHeight="16.8px"
        color={palette.neutral[70]}
        data-testid="link-bankaccount-tutorial"
      >
        Upload a non blurry photo of the latest bank statement of your bank
        account. The name on the account, account number and address must be
        clearly displayed and match the information provided. Scanned copies
        will not be accepted.
      </Text>
    </>
  );
};
