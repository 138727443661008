import { Text } from "@common/Text";
import { CaretDown } from "@assets/icons";
import XCircle from "@assets/icons/RebrandedIcons/XCircle";
import { Box, styled, ButtonBase } from "@mui/material";
import { palette } from "@palette";

const FilterItem = ({
  title,
  isSelected,
  onCancel,
  onClick,
}: {
  title: string;
  isSelected: boolean;
  onCancel?: (event: React.MouseEvent<any>) => void;
  onClick: (event: React.MouseEvent<any>) => void;
}) => {
  return (
    <StyledItemBox selected={isSelected} onClick={onClick}>
      <Text
        fontWeight="book"
        color={isSelected ? palette.givebox["600"] : palette.black[100]}
        style={{
          textWrap: "nowrap",
        }}
      >
        {title}
      </Text>

      {isSelected ? (
        <ButtonBase
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            onCancel && onCancel(e);
          }}
        >
          <XCircle {...iconProps} bordered={false} />
        </ButtonBase>
      ) : (
        <CaretDown {...iconProps} />
      )}
    </StyledItemBox>
  );
};

export default FilterItem;

const iconProps = {
  width: 18,
  height: 18,
  fill: palette.black[100],
};

const StyledItemBox = styled(Box)<{ selected: boolean }>(({ selected }) => ({
  display: "flex",
  height: "32px",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 12px",
  gap: "4px",
  backgroundColor: selected
    ? palette.info.light
    : palette.background.dimmedWhite,
  border: `1px solid ${selected ? palette.accent["3"] : palette.neutral["10"]}`,
  borderRadius: "100px",
  cursor: "pointer",
  "&:hover": {
    ...(!selected ? { backgroundColor: palette.neutral["10"] } : {}),
  },
}));
