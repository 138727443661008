import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { composePermission, useAccessControl } from "features/Permissions/AccessControl";

const useTeamPermissions = () => {
  const isListAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEMBER,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const isAddAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEMBER,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEMBER,
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const isDeleteAllowed = useAccessControl({
    resource: RESOURCE_BASE.MEMBER,
    operation: OPERATIONS.DELETE,
    withPortal: true,
  });

  const isInviteAllowed = useAccessControl({
    resource: RESOURCE_BASE.INVITE,
    operation: OPERATIONS.CREATE,
    withPortal: true,
  });

  const isListAccessPoliciesAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MEMBER,
      RESOURCE_BASE.ACCESS_POLICIES,
    ),
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  return {
    isAddAllowed,
    isEditAllowed,
    isDeleteAllowed,
    isInviteAllowed,
    isListAllowed,
    isListAccessPoliciesAllowed
  };
};

export default useTeamPermissions;
