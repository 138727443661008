interface IProps {
  height?: number;
  width?: number;
  fill?: string;
  ellips_fill?: string;
  fillOpacity?: string;
  opacity?: number;
}

const DeleteLightIcon = ({
  width = 36,
  height = 37,
  fill = "#4C4A52",
  opacity,
}: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        opacity: opacity,
        position: "absolute",
        top: "80%",
        left: "85%",
        transform: "translateX(-50%) translateY(-50%)",
      }}
    >
      <g opacity="0.75">
        <rect
          y="0.140625"
          width="36"
          height="36"
          rx="18"
          fill="white"
          fillOpacity="0.75"
        />
        <path
          d="M8.66626 13.0296C8.66626 12.6991 8.66626 12.5338 8.76439 12.4276C8.77106 12.4203 8.77801 12.4134 8.78522 12.4067C8.8915 12.3086 9.05677 12.3086 9.3873 12.3086H26.6119C26.9424 12.3086 27.1077 12.3086 27.214 12.4067C27.2212 12.4134 27.2281 12.4203 27.2348 12.4276C27.3329 12.5338 27.3329 12.6991 27.3329 13.0296V13.1854C27.3329 13.2446 27.3329 13.2741 27.3289 13.3013C27.3139 13.4015 27.2585 13.4912 27.1755 13.5494C27.153 13.5652 27.1266 13.5784 27.0737 13.6049C26.6504 13.8165 26.4387 13.9224 26.2588 14.0487C25.5953 14.5143 25.1519 15.2318 25.0321 16.0335C24.9996 16.2509 24.9996 16.4876 24.9996 16.9608V21.5569C24.9996 24.3468 24.9996 25.7418 24.1329 26.6085C23.2661 27.4753 21.8712 27.4753 19.0812 27.4753H16.918C14.128 27.4753 12.733 27.4753 11.8663 26.6085C10.9996 25.7418 10.9996 24.3468 10.9996 21.5569V16.9608C10.9996 16.4876 10.9996 16.2509 10.9671 16.0335C10.8473 15.2318 10.4039 14.5143 9.7404 14.0487C9.56044 13.9224 9.3488 13.8165 8.92552 13.6049C8.87261 13.5784 8.84615 13.5652 8.82366 13.5494C8.74072 13.4912 8.68529 13.4015 8.67032 13.3013C8.66626 13.2741 8.66626 13.2446 8.66626 13.1854V13.0296Z"
          fill={fill}
          fillOpacity="0.5"
        />
        <path
          d="M15.7461 9.24095C15.879 9.11691 16.172 9.00731 16.5795 8.92914C16.987 8.85097 17.4863 8.80859 17.9999 8.80859C18.5136 8.80859 19.0129 8.85097 19.4204 8.92914C19.8279 9.00731 20.1208 9.11691 20.2537 9.24095"
          stroke={fill}
          strokeWidth="1.77551"
          strokeLinecap="round"
        />
        <rect
          x="20.3333"
          y="16.9727"
          width="1.16667"
          height="7"
          rx="0.583333"
          fill={fill}
        />
        <rect
          x="14.5002"
          y="16.9727"
          width="1.16667"
          height="7"
          rx="0.583333"
          fill={fill}
        />
      </g>
    </svg>
  );
};
export default DeleteLightIcon;
