import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export const useGetProductFeePayment = () => {
  const { pathname } = useLocation();
  const id = pathname.split("s/").pop();
  const productId = id || '0';

  const getProduct = (id: number | string) => {
    return customInstance({
      url: `products/${id}`,
      method: "GET",
    });
  };

  const { data } = useQuery(
    ["find-product-payment-by-id", productId],
    async () => {
      const product = await getProduct(productId);
      return product;
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(productId),
    },
  );

  return {
    needsTax: data?.needsTax || false,
    optional: data?.needsTax ? data?.allowFeeChoice : false,
  };
};
