import { customInstance } from "@services/api";
import { useMutation } from "react-query";

export const useCreateCustomerMutation = (merchantId: number) => {
  return useMutation((data: any) => {
    return customInstance({
      url: `merchants/${merchantId}/customers`,
      method: "POST",
      data,
    });
  });
};
