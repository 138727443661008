import Box, { BoxProps } from "@mui/material/Box";

type IconProps = BoxProps & {
  height?: number;
  width?: number;
  stroke?: string;
};

const MenuIcon2 = ({
  width = 21,
  height = 20,
  stroke = "#575353",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55948 6.04962L16.7062 6.05319M9.55948 10.0008L16.7062 10.0043M9.55948 13.9503H16.7062M4.29346 6.04962L6.17418 6.05089M4.29346 10.0008L6.17418 10.002M4.29346 13.9503H6.17418"
        stroke={stroke}
        strokeWidth="1.1075"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuIcon2;
