const VisualIndicator = ({width, height}: {width?: number | string, height?: number | string}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#131212" />
      <path
        d="M9.94295 15.8891L6.6001 12.2891M17.4001 8.1748C15.0589 10.5968 13.5446 12.1633 11.5276 14.2498"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default VisualIndicator;
