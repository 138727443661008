import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";
import {
  QKEY_LIST_EVENTS,
  QKEY_LIST_FUNDRAISERS,
  QKEY_LIST_INVOICES,
  QKEY_LIST_MEMBERSHIPS,
  QKEY_LIST_PAYMENT_FORMS,
  QKEY_LIST_SWEEPSTAKES,
} from "@constants/queryKeys";
import { replace, startCase, toLower } from "lodash";

function appendLetter(str: string, letter: string) {
  if (!str) return "";
  return `${str}${letter}`;
}

export const parsedNavigationKey = (productVariant?: string) => {
  // ensure the navigation key is always in plural form (ends with 's')
  if (!productVariant) return "";
  if (productVariant[productVariant.length - 1] === "s") return productVariant;
  else return appendLetter(productVariant, "s");
};

export function productVarinatParser(value: string) {
  return startCase(toLower(replace(value, /-/g, " ")));
}

export const getProductRefetchQuery = (variant: CampaignType) => {
  const productRefetchQuery: Record<CampaignType, string> = {
    fundraiser: QKEY_LIST_FUNDRAISERS,
    event: QKEY_LIST_EVENTS,
    invoice: QKEY_LIST_INVOICES,
    membership: QKEY_LIST_MEMBERSHIPS,
    sweepstake: QKEY_LIST_SWEEPSTAKES,
    "payment-form": QKEY_LIST_PAYMENT_FORMS,
  };

  return productRefetchQuery[variant];
};
