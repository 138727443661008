import Xicon from "@assets/icons/Xicon";
import { Stack } from "@mui/material";
import { Text } from "@common/Text";
import { StyledIconButton } from "@common/IconButton";

function MerchantAgreementHeader({
  onClose,
  isEnterPrise,
}: {
  onClose: () => void;
  isEnterPrise?: boolean;
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <Text
        color={({ palette }) => palette.neutral[100]}
        data-testid="merchant-agreement-header-title"
        fontSize="18px"
      >
        {isEnterPrise ? "Provider" : "Merchant"} Agreement
      </Text>
      <StyledIconButton
        onClick={onClose}
        data-testid="merchant-agreement-header-close-button"
      >
        <Xicon />
      </StyledIconButton>
    </Stack>
  );
}

export default MerchantAgreementHeader;
