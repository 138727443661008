import { customInstance } from "@services/api";
import { useMutation } from "react-query";

export const useUpdateUnderwriting = (merchantId: number) => {
  const updateUnderwriting = useMutation((payload: any) => {
    return customInstance({
      url: `/merchants/${merchantId}/underwriting`,
      method: "PATCH",
      data: payload,
    });
  });

  return { updateUnderwriting };
};
