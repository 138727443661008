import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SearchQueryParams = {
  value: string;
};

type SetSearchQueryPayload = {
  queryKey: string;
  params: SearchQueryParams;
};

type SearchKeyObject = {
  value: string;
};

interface SearchEntries {
  [key: string]: SearchKeyObject;
}

interface SearchSliceState {
  queries: SearchEntries;
}

const initialState: SearchSliceState = {
  queries: {},
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchQueryByKey: (
      state: SearchSliceState,
      action: PayloadAction<SetSearchQueryPayload>,
    ) => {
      const { queryKey, params } = action.payload;
      state.queries[queryKey] = params;
    },
    resetSearchQueryByKey: (
      state: SearchSliceState,
      action: PayloadAction<string>,
    ) => {
      const queryKey = action.payload;
      if (state.queries[queryKey]) {
        state.queries[queryKey].value = "";
      }
    },
  },
});

export const { setSearchQueryByKey, resetSearchQueryByKey } =
  searchSlice.actions;

export const selectQueryString = (state: RootState, queryKey: string) =>
  state.search.queries[queryKey]?.value || "";

export default searchSlice.reducer;
