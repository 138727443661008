import { SvgIconProps } from "@mui/material";

const SearchEmptyStateIcon = ({
  width = 130,
  height = 129,
  fill = "#B8B8B8",
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 130 129"
      fill="none"
    >
      <g opacity="0.5">
        <path
          opacity="0.2"
          d="M97.0392 56.1792C97.0392 64.1034 94.6894 71.8497 90.2869 78.4384C85.8845 85.0272 79.6271 90.1625 72.3061 93.1949C64.9851 96.2274 56.9292 97.0208 49.1573 95.4749C41.3854 93.9289 34.2464 90.1131 28.6431 84.5098C23.0399 78.9066 19.224 71.7676 17.6781 63.9956C16.1321 56.2237 16.9256 48.1679 19.958 40.8468C22.9905 33.5258 28.1258 27.2685 34.7145 22.866C41.3032 18.4636 49.0495 16.1138 56.9737 16.1138C62.2352 16.1138 67.4451 17.1501 72.3061 19.1636C77.167 21.1771 81.5838 24.1283 85.3043 27.8487C89.0247 31.5691 91.9759 35.9859 93.9894 40.8468C96.0028 45.7078 97.0392 50.9178 97.0392 56.1792Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.125"
        />
        <path
          d="M115.993 109.519L90.8825 84.4092C98.1747 75.6634 101.814 64.443 101.044 53.082C100.274 41.721 95.1537 31.0943 86.748 23.4126C78.3423 15.7309 67.2986 11.5856 55.9144 11.8391C44.5302 12.0926 33.682 16.7254 25.6265 24.7737C17.5711 32.822 12.9288 43.6661 12.6652 55.0501C12.4016 66.4341 16.5371 77.4814 24.2114 85.8939C31.8857 94.3064 42.5079 99.4363 53.8682 100.216C65.2285 100.996 76.4522 97.3668 85.2044 90.0823L110.314 115.197C110.687 115.57 111.13 115.866 111.617 116.068C112.104 116.27 112.626 116.373 113.153 116.373C113.681 116.373 114.203 116.27 114.69 116.068C115.177 115.866 115.62 115.57 115.993 115.197C116.365 114.825 116.661 114.382 116.863 113.895C117.065 113.408 117.169 112.886 117.169 112.358C117.169 111.831 117.065 111.309 116.863 110.822C116.661 110.335 116.365 109.892 115.993 109.519ZM20.8592 56.1792C20.8592 49.0363 22.9773 42.0538 26.9457 36.1147C30.9141 30.1756 36.5545 25.5466 43.1537 22.8131C49.7528 20.0796 57.0144 19.3644 64.0201 20.758C71.0257 22.1515 77.4608 25.5911 82.5116 30.6419C87.5624 35.6927 91.0021 42.1278 92.3956 49.1335C93.7891 56.1391 93.0739 63.4007 90.3404 69.9999C87.6069 76.5991 82.978 82.2395 77.0389 86.2079C71.0997 90.1762 64.1172 92.2944 56.9743 92.2944C47.3993 92.2837 38.2194 88.4753 31.4488 81.7047C24.6782 74.9341 20.8698 65.7543 20.8592 56.1792Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default SearchEmptyStateIcon;
