import { GraphIcon } from "@assets/analyticsIcons";
import { Button } from "@common/Button";
import NewStat from "@common/NewStats/NewStat";
import {
  StyledContainer,
  ViewAnalyticsLink,
} from "@common/StatCard/NewStatCard";
import { Text } from "@common/Text";
import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo } from "react";
import { useThemeContext } from "../Provider/CustomThemeProvider";

import BackgroundVideoWrapper from "@common/BackgroundVideoWrapper";
import NewStatsTitle from "@common/NewStats/NewStatsTitle";
import { BACKGROUND_VIDEO_PRIMARY } from "@constants/constants";
import { generateColors } from "../hooks/useThemeManager";

interface Props {
  isDarkMode?: boolean;
}
function SampleCards({ isDarkMode = false }: Props) {
  const { activeGradientColor } = useThemeContext();
  const classes = useStyles({ isDarkMode });
  const color = isDarkMode ? `#ECECE9` : "#575353";
  const gradientColor = isDarkMode
    ? activeGradientColor?.dark
    : activeGradientColor?.light;
  const [color1, color2] = generateColors(gradientColor);

  return (
    <Grid xs={12} xl={6} item>
      <BackgroundVideoWrapper
        videoSrc={BACKGROUND_VIDEO_PRIMARY}
        borderRadius="13.5px"
        overflow="hidden"
        width="100%"
      >
        <Box p="18px" className={classes.container}>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text className={classes.title}>Events</Text>
            <Button className={classes.button} size="small">
              Create Event
            </Button>
          </Stack>

          <StyledContainer
            mt="32px"
            flexDirection={{
              xs: "column",
              md: "row",
            }}
            alignItems="self-end"
          >
            <NewStatsTitle
              isAmount
              label="Events Total"
              value="1,000.98"
              labelStyle={{
                fontSize: "14px",
                color,
                textAlign: "left",
              }}
              color={gradientColor}
              figureStyle={{
                fontSize: "32px",
              }}
              isMainTitle
            />
            <Box display="flex" gap="9px" height="100%" alignItems="center">
              {stats.map(({ isAmount, title, value, percent }, index) => (
                <NewStat
                  key={index}
                  isAmount={isAmount}
                  textProps={{
                    variant: "captionSmall",
                    whiteSpace: "nowrap",
                    color,
                  }}
                  valueProps={{
                    fontSize: "20px",
                    fontWeight: "book",
                    color,
                  }}
                  title={
                    title
                      ? title?.toString().charAt(0).toUpperCase() +
                        title?.toString().slice(1)
                      : ""
                  }
                  value={value}
                  percent={percent}
                />
              ))}

              <ViewAnalyticsLink
                Icon={
                  <GraphIcon
                    color1={color1}
                    color2={color2}
                    isDarkMode={isDarkMode}
                  />
                }
                stroke={color}
                labelProps={{
                  sx: {
                    fontSize: "14px",
                    color,
                  },
                }}
              />
            </Box>
          </StyledContainer>
        </Box>
      </BackgroundVideoWrapper>
    </Grid>
  );
}

export default memo(SampleCards);

interface StyledProps {
  isDarkMode?: boolean;
}

const useStyles = makeStyles({
  container: ({ isDarkMode }: StyledProps) => ({
    backgroundColor: isDarkMode
      ? "rgba(65, 64, 64, 0.8)"
      : "rgba(255, 255, 255, 0.6)",

    boxShadow: " 0px 11.25px 22.5px 0px #0000000D",
  }),
  title: ({ isDarkMode }: StyledProps) => ({
    color: isDarkMode ? "#ECECE9" : " #575353",
    fontSize: "21.38px",
    fontWeight: 350,
  }),
  button: ({ isDarkMode }: StyledProps) => ({
    backgroundColor: isDarkMode ? "#FFFFFF" : " #292727",
    color: isDarkMode ? "#292727" : "#FFFFFF",
    "&:hover": {
      backgroundColor: isDarkMode ? "#FFFFFF" : " #292727",
    },
    cursor: "default",
  }),
});

const stats = [
  {
    title: "Events",
    value: 9,
  },
  { title: "Visitors", value: 7 },
  {
    title: "Conversion",
    value: 57,
    percent: true,
  },
  {
    isAmount: true,
    title: "Average",
    value: 10.94,
  },
];
