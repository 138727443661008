import { Text } from "@common/Text";
import { palette } from "@palette";
import React, { memo } from "react";

function TitleComponent({
  title,
  mb = 4
}: {
  title: string,
  mb?: number
}) {
  return (
    <Text
      align="left"
      fontWeight="book"
      fontSize="28px"
      color={palette.neutral[80]}
      lineHeight="33px"
      mb={mb}
    >
      {title}
    </Text>
  );
}

export default memo(TitleComponent);
