interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const ArrowBackIcon = ({
  width = 14,
  height = 14,
  fill = "#9C9AA3",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999939 4.96609L0.575675 4.54183L0.151411 4.96609L0.575675 5.39036L0.999939 4.96609ZM12.9999 5.56609C13.3313 5.56609 13.5999 5.29747 13.5999 4.96609C13.5999 4.63472 13.3313 4.3661 12.9999 4.3661V5.56609ZM4.57567 0.541831L0.575675 4.54183L1.4242 5.39036L5.4242 1.39036L4.57567 0.541831ZM0.575675 5.39036L4.57567 9.39036L5.4242 8.54183L1.4242 4.54183L0.575675 5.39036ZM0.999939 5.56609H12.9999V4.3661H0.999939V5.56609Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowBackIcon;
