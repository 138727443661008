import { SearchBar } from "@common/SearchBar_V2";
import { Stack } from "@mui/material";
import ExportBtn from "./ExportBtn";
import FilterBtn from "./FilterBtn";
import { QKEY_RISK_MONITOR_TRANSACTIONS } from "@constants/queryKeys";

function TransactionHeader({
  merchantId,
  formattedParams,
}: {
  merchantId?: number;
  formattedParams?: string;
}) {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      pt="24px"
      px="16px"
    >
      <SearchBar queryKey={QKEY_RISK_MONITOR_TRANSACTIONS} />

      <Stack gap="12px" alignItems="center" flexDirection="row">
        <ExportBtn formattedParams={formattedParams} merchantId={merchantId} />
        <FilterBtn />
      </Stack>
    </Stack>
  );
}

export default TransactionHeader;
