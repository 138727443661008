import React from "react";

const ArrowForwardUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4L11.4697 3.46967L12 2.93934L12.5303 3.46967L12 4ZM12.75 19C12.75 19.4142 12.4142 19.75 12 19.75C11.5858 19.75 11.25 19.4142 11.25 19L12.75 19ZM5.46967 9.46967L11.4697 3.46967L12.5303 4.53033L6.53033 10.5303L5.46967 9.46967ZM12.5303 3.46967L18.5303 9.46967L17.4697 10.5303L11.4697 4.53033L12.5303 3.46967ZM12.75 4L12.75 19L11.25 19L11.25 4L12.75 4Z"
        fill="#4C4A52"
      />
    </svg>
  );
};

export default ArrowForwardUp;
