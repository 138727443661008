import React from "react";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Tag } from "@common/Tag";
import { Text } from "@common/Text";
import SubExpandedRow from "../SubExpandedRow";
// assets
import { ServerIcon, CartIcon } from "@assets/icons";
// data
import { donationsData } from "../../data";
import DashedLine from "@assets/icons/DashedLine";
import { useLocation } from "react-router-dom";
import { palette } from "@palette";
import { KeyVal } from "@common/KeyVal";
import { tableData } from "@components/ManageMoney/TransactionTable/data";
import MobileTransactionPurchaseCard from "./MobileTransactionPurchaseCard";
import MobileTransactionPersonalInfo from "./MobileTransactionPersonalInfo";
import MobileTransactionAmountsCard from "./MobileTransactionAmountsCard";
import { Menu } from "@mui/material";
import { DropdownItem } from "@common/Select";
import {
  MakeRecurring,
  ModifyRecurring,
  Refund,
  SendReceipt,
} from "@components/ManageMoney/TransactionTable/TableActions";
import {
  MERCHANT_PORTAL_MAKE_RECURRING_MODAL,
  MERCHANT_PORTAL_MODIFY_RECURRING_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
} from "modals/modal_names";
import NiceModal from "@ebay/nice-modal-react";

const MobileTransaction = () => {
  const rowData = tableData[0];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [recurrence, setRecurrence] = React.useState("");
  const open = Boolean(anchorEl);

  const actionsHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    recurrence: string,
  ) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);

    setRecurrence(recurrence);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ mb: "8px" }}>
        <ServerIcon fill="transparent" />
        <Text
          variant="headline"
          fontWeight="medium"
          color={palette.primary.main}
        >
          Payment Details
        </Text>
        <Box sx={{ marginLeft: "auto" }}>
          <Tag type="approved" />
        </Box>
      </Stack>
      <MobileTransactionPersonalInfo rowData={rowData} />
      <MobileTransactionAmountsCard />
      <Text fontSize={12} color={palette.neutral[600]} sx={{ mb: "20px" }}>
        *Visa fee deducted from donation
      </Text>
      {/* Purchases */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Stack gap={1} direction="row" alignItems="center">
          <CartIcon />
          <Text
            variant="headline"
            fontWeight="medium"
            color={palette.primary.main}
          >
            Purchases
          </Text>
        </Stack>
        {/* ----------- Purchases items ----------- */}
        {rowData.recurringPurchases && (
          <Box display="flex" flexDirection="column" gap="10px">
            {rowData.recurringPurchases.map((purchase) => (
              <MobileTransactionPurchaseCard
                key={purchase.id}
                description={purchase.description}
                title={purchase.title}
                type={purchase.type}
                recurrence={purchase.recurrence}
                donationAmount={purchase.details.donation}
                actionsHandler={actionsHandler}
              />
            ))}
          </Box>
        )}
      </Box>

      {/* ---------- Menu ---------- */}
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: 274,
          },
        }}
      >
        <DropdownItem
          onClick={() => NiceModal.show(MERCHANT_PORTAL_SEND_RECEIPT_MODAL)}
        >
          <Text>Send Receipt</Text>
        </DropdownItem>
        <DropdownItem
          onClick={() => NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL)}
        >
          <Text>Refund</Text>
        </DropdownItem>
        {rowData.recurringPurchases[1].type.toLowerCase() === "one time" ? (
          <DropdownItem
            onClick={() => NiceModal.show(MERCHANT_PORTAL_MAKE_RECURRING_MODAL)}
          >
            <Text>Modify Recurring</Text>
          </DropdownItem>
        ) : (
          <DropdownItem
            onClick={() =>
              NiceModal.show(MERCHANT_PORTAL_MODIFY_RECURRING_MODAL, {
                sourceAccount: { card: {} },
              })
            }
          >
            <Text>Modify Recurring</Text>
          </DropdownItem>
        )}
      </Menu>
    </>
  );
};

export default MobileTransaction;
