import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getSubPathsRoute } from "@utils/routing";
import { Role, useUser } from "./useUser";
import { MappedPathName } from "@constants/routing";
import { ACQUIRER_PATHS } from "@routes/paths";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";

export const useRouting = () => {
  const { pathname } = useLocation();
  const { userRole } = useUser();
  const { isMasqueradeMode, masqueradeUserRole } = useMasqueradeReducer();

  const _useRole = isMasqueradeMode ? masqueradeUserRole : userRole;

  const subPathRoutes = useMemo(
    () => getSubPathsRoute(pathname, MappedPathName[_useRole as Role]),
    [pathname, userRole],
  );

  return {
    ...subPathRoutes,
    isSettingsPage: subPathRoutes.pathArray[1] === ACQUIRER_PATHS.SETTINGS,
    isBankAccountPage: subPathRoutes.pathArray[2] === "bank-account",
  };
};
