import EmptyChartIcon from "@assets/analyticsIcons/EmptyChartIcon";
import { Text } from "@common/Text";
import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import { Box, Stack } from "@mui/material";
import { palette } from "@palette";

const EmptyChart = ({ customText }: { customText?: string }) => {
  const { gradient } = useThemeContext();
  return (
    <Stack direction="column" alignItems="stretch" gap={4} position="relative">
      {Array.from({ length: 10 }, (_, i) => (
        <Box
          height={0}
          key={i}
          sx={{ borderTop: `1px solid ${palette.liftedWhite[200]}` }}
        />
      ))}
      <Box
        position="absolute"
        sx={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <EmptyChartIcon />
          <Text
            gradient={gradient}
            fontSize="12px"
            fontWeight="book"
            lineHeight="14.4px"
          >
            {customText ? customText : "No data for now"}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};

export default EmptyChart;
