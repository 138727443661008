
import { Reducer } from "react";

// on initial => the inital render (empty)
// on Uploading => image is uploading
// on uploaded => image was uploaded successfully
// on faulted => image was NOT uploaded successfully
export enum UploaderState {
  ON_INITIAL =  "onInitial",
  ON_UPLOADING =  "onUploading",
  ON_UPLOADED = "onUploaded",
  ON_FAULTED = "onFaulted",
}

export enum UploaderEvent {
  UPLOAD = "UPLOAD",
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  RESET = "RESET",
}

export const machine: Reducer<UploaderState, UploaderEvent> = (state, event) => {
  switch (state) {
    case "onInitial":
      if (event === UploaderEvent.UPLOAD) return UploaderState.ON_UPLOADING;
      break;
    case "onUploading":
      if (event === UploaderEvent.SUCCESS) return UploaderState.ON_UPLOADED;
      if (event === UploaderEvent.FAIL) return UploaderState.ON_FAULTED;
      break;
    case "onUploaded":
      if (event === UploaderEvent.UPLOAD) return UploaderState.ON_UPLOADING;
      if (event === UploaderEvent.RESET) return UploaderState.ON_INITIAL;
      break;
  }
  return state;
};
