import NiceModal from "@ebay/nice-modal-react";
import { EDIT_ENTERPRISE_MCC_MODAL } from "modals/modal_names";
import { TCategoryCode } from "@components/Merchants/MerchantPreview/data.types";
import { MerchantSectionContainer } from "@components/Merchants/MerchantPreview/components/atoms";
import { Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { ICreateEnterpriseSchema } from "@components/AcquirerEnterprises/CreateEnterprise/types";

type Props = {
  id: number;
  data: TCategoryCode[];
  parentCategories: TCategoryCode[];
  handleCloseMCC?: (data: ICreateEnterpriseSchema["categories"]) => void;
};

const MCCSection = ({ id, data, parentCategories, handleCloseMCC }: Props) => {
  const editHandler = () => {
    NiceModal.show(EDIT_ENTERPRISE_MCC_MODAL, {
      id,
      categories: data,
      parentCategories,
      onClose: handleCloseMCC,
    });
  };

  return (
    <MerchantSectionContainer
      sectionTitle="Merchant Category Codes (MCC)"
      editHandler={editHandler}
      containerSx={{
        borderBottom: "none",
      }}
    >
      {data?.map((category) => (
        <MerchantCategoryItem key={category.id} {...category} />
      ))}
    </MerchantSectionContainer>
  );
};

const MerchantCategoryItem = ({ code, title }: TCategoryCode) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      p="4px"
      data-testid={`mcc-added-${code}`}
    >
      <CategoryCode>{code}</CategoryCode>
      <Text fontWeight="book" color={palette.neutral[80]}>
        {title}
      </Text>
    </Stack>
  );
};

const CategoryCode = styled(Text)(() => ({
  padding: "4px",
  borderRadius: "4px",
  background: "#EBEBEB",
  fontSize: 12,
  fontWeight: 350,
  color: palette.neutral[80],
}));

export default MCCSection;
