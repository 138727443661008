import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import AmountItemMobileView from "../AmountItemMobileView";
import AmountItem from "../AmountItem";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { AmountType, AmountsListType } from "@redux/slices/fundraisers";
import NiceModal from "@ebay/nice-modal-react";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";
import { useTranslation } from "react-i18next";
import { namespaces } from "@localization/resources/i18n.constants";
import { isFunction } from "lodash";

interface Props {
  amountsList: AmountsListType[];
  setIsSubmitDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  onDragEnd: (newList: AmountType[]) => void;
  onShowAmountItem: (id: string) => void;
  deleteAction: (id: string) => void;
  showAmount?: (id: string) => any;
  handleEditing?: (id: string) => void;
}

function AmountListDnD({
  amountsList,
  setIsSubmitDisabled,
  onDragEnd,
  onShowAmountItem,
  deleteAction,

  showAmount,
  handleEditing,
}: Props) {
  const [activeId, setActiveId] = useState<any>(null);
  const [lastActive, setLastActive] = React.useState<string>("");
  const { t } = useTranslation(namespaces.pages.fundraiserMinibuilder);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = amountsList.findIndex((x) => x.id === active.id);
      const newIndex = amountsList.findIndex((x) => x.id === over?.id);
      onDragEnd(arrayMove(amountsList, oldIndex, newIndex) as AmountType[]);
    }
    setActiveId(null);
    isFunction(setIsSubmitDisabled) && setIsSubmitDisabled(false);
  };
  const handleDragCancel = React.useCallback(() => {
    setActiveId(null);
  }, []);

  const handleShowAmountItem = (id: string) => {
    onShowAmountItem(id);
    isFunction(setIsSubmitDisabled) && setIsSubmitDisabled(false);
  };
  const handleRemoveAmountItem = (id: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "amount",
      deleteHandler: () => deleteAction(id),
    });
  };

  React.useEffect(() => {
    const activeList = amountsList.filter((v) => v.active);

    if (activeList.length === 1) {
      setLastActive(activeList[0].id);
    } else {
      setLastActive("");
    }

    if (
      amountsList.length === 1 &&
      activeList.length >= 1 &&
      !amountsList[0].active
    ) {
      isFunction(showAmount) && showAmount(activeList[0].id);
    }
  }, [amountsList]);
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext
        items={amountsList}
        strategy={verticalListSortingStrategy}
      >
        <Stack mt={2} spacing={1}>
          {amountsList.map((item) => {
            const isAnyAmount = typeof item?.description === "string";
            if (!isDesktop) {
              return (
                <AmountItemMobileView
                  key={item.id}
                  amountItem={item as AmountType}
                  showAmountItem={handleShowAmountItem}
                  removeAmountItem={handleRemoveAmountItem}
                  lastActive={lastActive}
                  handleEditing={handleEditing}
                  setIsSubmitDisabled={setIsSubmitDisabled}
                  isAnyAmount={isAnyAmount}
                />
              );
            }
            return (
              <AmountItem
                isAnyAmount={isAnyAmount}
                key={item.id}
                amountItem={item as AmountType}
                showAmountItem={handleShowAmountItem}
                removeAmountItem={handleRemoveAmountItem}
                lastActive={lastActive}
                handleEditing={handleEditing}
                setIsSubmitDisabled={setIsSubmitDisabled}
              />
            );
          })}
        </Stack>
      </SortableContext>
    </DndContext>
  );
}

export default memo(AmountListDnD);
