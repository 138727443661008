import NiceModal from "@ebay/nice-modal-react";
import UpdateEmail from "./UpdateEmail";
import CheckPassword from "./CheckPassword";
import { useState } from "react";

const NewChangeEmailModal = NiceModal.create(() => {
  const [passwordChecked, setPasswordChecked] = useState<boolean>(false);

  return passwordChecked ? (
    <UpdateEmail setPasswordChecked={setPasswordChecked} />
  ) : (
    <CheckPassword setChecked={setPasswordChecked} />
  );
});

export default NewChangeEmailModal;
