import { TListWrapperSection } from "@containers/types";
import usePanelState from "./usePanelState";
import usePermissionData from "./usePermissionData";
import { useEffect, useMemo, useState } from "react";
import { generateDynamicTabs } from "../utils/permissionPanel.utils";
import { DELETE_CONFIRMATION_MODAL } from "modals/modal_names";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQueryClient } from "react-query";
import { useAddPermissionModalCache } from "./useAddPermissionsModal";
import { TPermissionsData } from "../types";
import { QKEY_LIST_TEAM_MEMBER_PERMISSIONS } from "@constants/queryKeys";
import { useDebouncedCallback } from "use-debounce";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

const usePermissionsPanel = (memberId: number) => {
  const queryClient = useQueryClient();
  const modal = useModal();
  const { updateCache, queryKey } = useAddPermissionModalCache();

  const { open, sectionsRef, closeModal, setOpen, toggleLatest, showLatest } =
    usePanelState();

  const {
    totalItems,
    setSearchQuery,
    dataIsLoading,
    dataIsFetching,
    permissionsData,
    latestAddedData,
    isMutated,
    handleSetFilter,
    onDelete,
    clearChanges,
    onSaveSidePanel,
    setPermissionStatus,
    updateLatestAdded,
    updateAllHashedData,
    hashedData,
    updateAllLogs,
    removeItemFromLogs,
  } = usePermissionData(memberId, {
    withPagination: false,
    assignedOnly: true,
    enabled: modal.visible,
  });

  const [searchString, setSearchString] = useState<string>("");

  const setAPISearchQuery = useDebouncedCallback(
    (value) => setSearchQuery(value),
    200,
  );

  const handleSearch = (value: string) => {
    setSearchString(value);
    if (value === "") {
      setSearchQuery("");
    } else {
      setAPISearchQuery(value);
    }
  };

  const toggleModal = () =>
    setOpen((prev) => {
      if (!prev) handleSearch("");
      return !prev;
    });

  const invalidateAllQueries = () =>
    [queryKey, QKEY_LIST_TEAM_MEMBER_PERMISSIONS].forEach((q) =>
      queryClient.invalidateQueries(q),
    );

  const handleDiscard = async () => {
    // on discard we delete the custom permissions that were added
    Object.values(latestAddedData.data).forEach(
      (permission: TPermissionsData[string]) => {
        if (permission?.custom) onDelete(permission.id);
      },
    );

    closeModal();
    clearChanges();
    invalidateAllQueries();
  };

  const tabs = useMemo(
    () =>
      generateDynamicTabs(
        showLatest,
        permissionsData.groups,
        latestAddedData.groups,
      ),
    [showLatest, latestAddedData.groups, permissionsData.groups],
  );

  const isAddAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MEMBER,
      RESOURCE_BASE.ACCESS_POLICIES,
    ),
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });

  const listWrapperProps = {
    section: "permissions" as TListWrapperSection,
    isEmpty:
      totalItems === 0 && !searchString && !dataIsLoading && !dataIsFetching,
    action: {
      label: "Add Permissions",
      onClick: toggleModal,
      disabled: !isAddAllowed,
      tooltipProps: {
        show: !isAddAllowed,
        message: CREATE_DENY_MESSAGE,
      },
    },
    sx: {
      overflowY: "hidden",
    },
  };

  const deleteHandler = (permissionKey: string) => {
    const permissionId = permissionsData.data?.[permissionKey]?.id;
    if (!permissionId) return;

    onDelete(permissionId, "Changes saved").then(() => {
      invalidateAllQueries();
    });
  };

  const latestAddedDeleteHandler = (key: string) => {
    updateLatestAdded((old) => {
      if (!old) return {};
      return deleteEntry(old, key);
    });
    updateAllHashedData((old) => {
      if (!old) return {};
      if (!old[key]) return old;

      return deleteEntry(old, key);
    });
    removeItemFromLogs(key);
    updateCache(key, {
      ...latestAddedData.data[key],
      isAttached: false,
    });
  };

  const deletePermission = (permissionKey: string, permissionName: string) => {
    const onSubmit = showLatest ? latestAddedDeleteHandler : deleteHandler;
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "permissions",
      itemName: permissionName,
      deleteHandler:() => onSubmit(permissionKey),
    })
  };

  const syncListWithNewAdded = (newAdded: TPermissionsData) => {
    Object.entries(newAdded).forEach(([key, values]) => {
      updateCache(key, values);
      updateLatestAdded((old) => ({
        ...old,
        [key]: {
          ...values,
          isDeny: false,
          isAttached: values.isAttached,
          isGlobal: false,
        },
      }));
      if (values.isDeny) {
        updateAllLogs((old) => ({
          ...old,
          [key]: {
            ...values,
            isDeny: false,
          },
        }));
      }
      updateAllHashedData((old) => {
        if (!old || !old[key] || values.isAttached) {
          return {
            ...old,
            [key]: {
              ...values,
              isDeny: false,
            },
          };
        }
        return deleteEntry(old, key);
      });
    });
  };

  const handleAddPermission = (newAdded: TPermissionsData) => {
    syncListWithNewAdded(newAdded);
    toggleModal();
  };

  const onAddCustomPermission = (permissionIds: string[], cb?: () => void) => {
    permissionIds.forEach((id) => {
      updateLatestAdded((old) => {
        if (!old || !hashedData || !hashedData[id]) return old;

        return {
          ...old,
          [id]: {
            ...hashedData[id],
            custom: true,
            isDeny: false,
          },
        };
      });
    });
    toggleModal();
    if (cb) cb();
  };

  useEffect(() => {
    const hasLatestAdded = Object.values(latestAddedData.data).some(
      (val) => val.isAttached,
    );
    toggleLatest(hasLatestAdded);
  }, [latestAddedData.data]);

  return {
    dataIsLoading,
    dataIsFetching,
    totalItems,
    searchQuery: searchString,
    listMutated: isMutated,
    permissionsData,
    latestAddedData,
    listWrapperProps,
    sectionsRef,
    showLatest,
    open,
    toggleModal,
    tabs,
    handleSetFilter,
    handleSearch,
    handleDiscard,
    deletePermission,
    onDelete: deletePermission,
    handleClose: closeModal,
    handleAddPermission,
    onAddCustomPermission,
    setPermissionStatus,
    onSave: () => {
      onSaveSidePanel();
      toggleLatest(false);
    },
  };
};

const deleteEntry = (data: TPermissionsData, key: string) => {
  const newData = data;
  delete newData[key];
  return newData;
};

export default usePermissionsPanel;
