import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";

export const editorStateToString = (description: EditorState): string => {
  if (!description) return "";

  const editorContent = description.getCurrentContent();
  const stringDescription = JSON.stringify(convertToRaw(editorContent));
  return stringDescription;
}

export const stringToEditorState = (description: string): EditorState => {
  if (!description) return EditorState.createEmpty();
  try {
    const editorContent = convertFromRaw(JSON.parse(description));
    const editorState = EditorState.createWithContent(editorContent);
    return editorState;
  } catch (error) {
    return EditorState.createWithContent(ContentState.createFromText(description));
  }
}

export const convertMinibuilderDescription = (
  description?: EditorState | string | (() => string | EditorState),
): string | EditorState => {
  if (!description) {
    return "";
  } else if (typeof description === "function") {
    const callbackDescription = description();
    return convertMinibuilderDescription(callbackDescription);
  } else {
    return description;
  }
};