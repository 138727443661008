import { CheckRequirementsIcon } from "@assets/icons";
import { Box } from "@mui/material";
import { Text } from "@common/Text";

type RequirementsProps = {
  requirement: string;
  color: string;
};

const RequirementBox = ({ requirement, color }: RequirementsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "4px",
      }}
    >
      <CheckRequirementsIcon fill={color} />
      <Text variant="body" color={color}>
        {requirement}
      </Text>
    </Box>
  );
};

export default RequirementBox;
