import { Grid } from "@mui/material";
import React from "react";
import { BaseText } from "../ExpandedRow_V2/atoms";
import { palette } from "@palette";
import { Separator } from "./Separator";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { TruncateText } from "@common/Text";

type TVerticalAlign = "flex-start" | "center" | "flex-end";

export const InformationsComponent = ({
  columnData,
  isMobile,
  isTransfer,
  resetAnimation,
}: {
  isMobile?: boolean;
  isTransfer?: boolean;
  resetAnimation?: boolean;
  columnData:
    | {
        [key: string]: {
          title: string;
          value: string | number | React.ReactElement;
          verticalAlign?: TVerticalAlign;
        };
      }
    | Record<string, any>;
}) => {
  const titleGridSizes = {
    xs: 12,
    md: isTransfer ? 5 : 4,
    lg: isTransfer ? 5 : 4,
  };

  const valueGridSizes = {
    xs: 12,
    md: isTransfer ? 7 : 8,
    lg: isTransfer ? 7 : 8,
  };
  return (
    <>
      {Object.values(columnData)
        .filter((x) => x.value !== undefined)
        .map((x, idx) => {
          if (x.value === "divider") {
            return <Separator key={idx} marginBottom={0} />;
          }

          return (
            <FadeUpWrapper
              key={x.title}
              delay={220 + idx * 20}
              reset={resetAnimation}
            >
              <Grid
                display="flex"
                alignItems={isMobile ? "baseline" : x.verticalAlign || "center"}
                flexDirection={isMobile ? "column" : "row"}
                item
                xs={12}
              >
                <Grid
                  item
                  {...titleGridSizes}
                  alignItems={x.verticalAlign || "center"}
                >
                  <BaseText value={x.title} />
                </Grid>
                <Grid item {...valueGridSizes}>
                  {typeof x.value === "object" && x.value !== null ? (
                    <TruncateText lineClamp={1} sx={{ wordBreak: "break-all" }}>
                      {x.value}
                    </TruncateText>
                  ) : (
                    <BaseText
                      sx={{
                        ...(x.title === "Transaction ID"
                          ? { wordBreak: "break-all" }
                          : {}),
                      }}
                      key={`${x.title}_value`}
                      value={x.value as string}
                      color={palette.neutral[800]}
                      data-testid={`${x.title}-value`}
                    />
                  )}
                </Grid>
              </Grid>
            </FadeUpWrapper>
          );
        })}
    </>
  );
};
