import { ActionButton } from "./ChallengeActions";

const ViewAction = ({
  viewAction,
  challengeId,
  shouldDisableViewButtonBusinessProfile,
  shouldDisableViewOFAC,
  shouldDisableBusinessProfileOFACMatch,
  isFileAttached,
}: {
  viewAction: () => void;
  challengeId: number;
  shouldDisableViewButtonBusinessProfile?: boolean;
  shouldDisableBusinessProfileOFACMatch?: boolean;
  shouldDisableViewOFAC?: boolean;
  isFileAttached?: boolean;
}) => {
  return (
    <ActionButton
      label="View"
      onClick={viewAction}
      testId={`view-challenge-button-${challengeId}`}
      disabled={
        shouldDisableViewButtonBusinessProfile ||
        shouldDisableViewOFAC ||
        shouldDisableBusinessProfileOFACMatch
      }
      tooltipProps={
        shouldDisableViewButtonBusinessProfile ||
        shouldDisableViewOFAC ||
        shouldDisableBusinessProfileOFACMatch
          ? {
              message: shouldDisableViewButtonBusinessProfile
                ? "Completing the business profile is required before adding a business owner"
                : shouldDisableBusinessProfileOFACMatch
                ? "This action is blocked by a confirmed match on OFAC"
                : "Completing business profile or primary account holder is required for running OFAC check",
              show: true,
              maxWidth: "310px",
            }
          : {
              message: "You will be redirected to the relevant information.",
              extraMessage: "\nPlease check the documents if needed.",
              show: !isFileAttached,
              maxWidth: "310px",
            }
      }
      sx={{
        ":disabled": {
          pointerEvents: "auto",
          cursor: "pointer",
          ":hover": { backgroundColor: "#D1D1D0" },
        },
      }}
    />
  );
};

export default ViewAction;
