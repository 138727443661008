import { CaretDown } from "@assets/icons";
import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";

type Props = {
  handleOpen: () => void;
};

const AgreementSnapshotCard = ({ handleOpen }: Props) => {
  return (
    <Container onClick={handleOpen} data-testid="snapshot-container">
      <Box display="flex" justifyContent="space-between">
        <Text color={palette.neutral[70]} fontSize={18}>
          Snapshot
        </Text>
        <div style={{ transform: "rotate(-90deg)" }}>
          <CaretDown height={20} width={20} />
        </div>
      </Box>
      <Text color={palette.neutral[70]} fontWeight="book" width="95%">
        The snapshot is taken at Underwriting Approval, serving as a permanent
        record of the original information provided by the Merchant during the
        approval process
      </Text>
    </Container>
  );
};

const Container = styled(Box)(() => ({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  gap: "4px",
  borderRadius: "12px",
  background: "#FFF",
  marginBottom: "24px",
  cursor: "pointer",
}));

export default AgreementSnapshotCard;
