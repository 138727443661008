import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import Webcam from "react-webcam";
import useCheckPAH from "@hooks/common/useCheckPAH";

export const useCamera = () => {
  const [isCameraAvailable, setIsCameraAvailable] = useState(false);
  const [cameraStatus, setCameraStatus] = useState<MediaStream | null>(null);

  const webcamRef = useRef<Webcam | null>(null);

  const checkCamera = () => {
    navigator.mediaDevices.enumerateDevices().then((res) => {
      const findCamera = res.find((x) => x.kind === "videoinput");
      if (findCamera) {
        //Camera is available
        setIsCameraAvailable(true);

        if (findCamera.deviceId) {
          //Camera was found and the permission is already granted
          //LOGIC TBD
          console.log("Camera permission granted already");
        } else {
          //Camera was found but the permission is not granted
          //LOGIC TBD
          console.log("The user denined the permission");
        }
      } else {
        //The camera is not available
        setIsCameraAvailable(false);
      }
    });
  };

  const destroyCamera = ({
    cameraStatus,
  }: {
    cameraStatus: MediaStream | null;
  }) => {
    if (cameraStatus) {
      const tracks = cameraStatus.getTracks();
      tracks[0].stop();
    }
  };

  const setCamera = (data?: any) => {
    if (data !== undefined) {
      setCameraStatus(null);

      return;
    }
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          height: { ideal: 3000 },
          width: { ideal: 3000 },
          frameRate: { ideal: 24 },
          facingMode: "user",
        },
      })
      .then((res) => {
        setCameraStatus(res);
      })
      .catch((err) => {
        console.log("CAMERA PERMISSION NOT GRANTED", err);
      });
  };

  return {
    isCameraAvailable,
    cameraStatus,
    webcamRef,
    setCamera,
    checkCamera,
    destroyCamera,
  };
};

export const useGetSelfieOTC = ({ enabled }: { enabled: boolean }) => {
  const { merchantId } = useGetCurrentMerchantId();

  return useQuery(
    ["get OTC", merchantId],
    async () => {
      return await customInstance({
        url: `/merchants/${merchantId}/ots`,
        method: "POST",
        data: {},
      });
    },
    {
      refetchOnMount: true,
      enabled,
    },
  );
};

export const useGetIdentificationFiles = () => {
  const { merchantId, selectedUser } = useGetCurrentMerchantId();
  const { isMasqueradeMode } = useMasqueradeReducer();

  const filesList = useQuery(
    ["get identification files", merchantId],
    async () => {
      return await customInstance({
        url: `/accounts/${
          isMasqueradeMode ? merchantId : selectedUser?.id
        }/files`,
      });
    },
    {
      refetchOnMount: true,
    },
  );

  return filesList;
};

export const useGetUploadedSelfie = () => {
  const { merchantId, selectedUser } = useGetCurrentMerchantId();
  const { isMasqueradeMode } = useMasqueradeReducer();

  const { data, ...rest } = useQuery(
    ["get-uploaded-selfie", merchantId],
    async () => {
      return await customInstance({
        url: `/accounts/${
          isMasqueradeMode ? merchantId : selectedUser?.id
        }/files?filter=%3BisUploaded%3Atrue%3BattTypeName%3A"account_owner_selfie"&sort=-updatedAt&max=1`,
      });
    },
    {
      refetchOnMount: true,
    },
  );

  return {
    uploadedSelfie:
      data?.data?.length > 0
        ? data?.data?.reduce(
            (latest: any, current: any) => {
              if (
                current.attTypeName === "account_owner_selfie" &&
                current.updatedAt > latest.updatedAt
              ) {
                return current;
              }
              return latest;
            },
            { updatedAt: 0 },
          )
        : undefined,
    ...rest,
  };
};
