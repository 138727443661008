import { useFormContext } from "react-hook-form";
import { ColumnExport, TExportTable } from "../types";
import { useCallback } from "react";
import { getDefaultSelectedColumns } from "./useExportTable";

export type TSelectedColumns = Record<string, string[]>;
export type TBulkSelectAction = "select" | "deselect";
type TBulkSelector = (action: TBulkSelectAction, section: string) => void;

const useColumnSelection = ({
  initialColumns,
  visibleColumns,
  monthlyColumns,
}: {
  initialColumns: TSelectedColumns;
  visibleColumns: string[];
  monthlyColumns: string[];
}) => {
  const { watch, setValue } = useFormContext<TExportTable>();
  const selectedColumns = watch("selectedColumns");
  const columnsFilter = watch("columnsIncluded");

  //set  columns included if custom items match
  const columnConditions: { key: ColumnExport; columns: string[] }[] = [
    { key: "visible", columns: visibleColumns },
    { key: "processing_monthly", columns: monthlyColumns },
    { key: "all", columns: getDefaultSelectedColumns(initialColumns) },
  ];
  const handleSetColumnsIncluded = (columns: string[]) => {
    for (const condition of columnConditions) {
      if (
        columns.every((col) => condition.columns.includes(col)) &&
        columns.length === condition.columns.length
      ) {
        setValue("columnsIncluded", condition.key);
        return;
      }
    }
    setValue("columnsIncluded", "custom");
  };

  const updateColumn = (name: string, checked: boolean) => {
    const index = selectedColumns?.findIndex((el) => el === name);
    let updatedArray = selectedColumns;

    if (checked && index === -1) {
      updatedArray = [...selectedColumns, name];
    } else if (!checked && index !== -1 && selectedColumns?.length > 1) {
      updatedArray?.splice(index, 1);
    } else {
      // invalid operation
      return;
    }
    setValue("selectedColumns", updatedArray);
    handleSetColumnsIncluded(updatedArray);
  };

  const bulkSelect: TBulkSelector = useCallback(
    (action, section) => {
      if (action === "deselect") {
        initialColumns[section].forEach((name) => updateColumn(name, false));
        return;
      }

      const columnsSet = new Set(selectedColumns);
      initialColumns[section].forEach((name) => columnsSet.add(name));
      setValue("selectedColumns", Array.from(columnsSet));
      handleSetColumnsIncluded(Array.from(columnsSet));
    },
    [selectedColumns],
  );

  const isSectionAllActive = useCallback(
    (section: string) => {
      return initialColumns?.[section]?.every(
        (entry) => selectedColumns?.findIndex((el) => el === entry) !== -1,
      );
    },
    [selectedColumns],
  );

  const getCheckboxAttributes = (name: string) => {
    const checked = selectedColumns?.some((entry) => entry == name);
    const disabled =
      selectedColumns?.length === 1 && selectedColumns?.[0] === name;
    return { checked, disabled };
  };

  return {
    selectedColumns,
    updateColumn,
    bulkSelect,
    isSectionAllActive,
    getCheckboxAttributes,
    columnsFilter,
    hasLastCheckbox: selectedColumns?.length === 1,
  };
};

export default useColumnSelection;
