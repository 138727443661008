import { RHFSwitch } from "@common/Switch";
import { Text } from "@common/Text";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import { Avatar, Stack } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";

type Props = {
  showCheckbox?: boolean;
};

export const AssignToMe = ({ showCheckbox = true }: Props) => {
  const { img } = useUserReducer();
  const { watch } = useFormContext();
  const values = watch();

  return (
    <Stack direction="row" spacing={1}>
      <Avatar src={img} sx={{ width: "32px", height: "32px" }} />
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontWeight="book" color={palette.neutral[80]}>
            Assign to me
          </Text>
          {showCheckbox && (
            <RHFSwitch name="assignToMe" size="small" disabled={values.email} />
          )}
        </Stack>
        <Text fontWeight="book" color={palette.neutral[70]}>
          The Primary Account Holder bears ultimate responsibility for all
          payment activity and account management.
        </Text>
      </Stack>
    </Stack>
  );
};
