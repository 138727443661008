import { Box, Stack, SxProps } from "@mui/material";
import { palette } from "@palette";
import { ReactNode, RefObject } from "react";

interface CommentLayoutProps {
  image: ReactNode;
  name: ReactNode;
  message: ReactNode | string;
  action: ReactNode;
  time?: ReactNode;
  scrollRef?: RefObject<any>;
  sx?: SxProps;
  isSending?: boolean;
}

const CommentLayout = ({
  image,
  name,
  time,
  message,
  action,
  scrollRef,
  isSending,
  sx,
}: CommentLayoutProps) => {
  return (
    <Stack
      direction="row"
      sx={{
        padding: "8px",
        "& .mention": {
          backgroundColor: "#E6EAF2",
          borderRadius: "90px",
          color: "#003182",
        },
        "& .mention-custom": {
          backgroundColor: "#f8f8f6",
          borderRadius: "90px",
          color: palette.givebox[600],
        },
        "& .comment-markup": {
          wordBreak: "break-word",
          whiteSpace: "pre-line",
          fontSize: "14px",
          ...(isSending && { opacity: 0.5 }),
        },
        "& .hashtag": {
          cursor: "pointer",
          color: "#FF8124",
          ":hover": {
            color: "black !important",
          },
        },
        ...sx,
      }}
      gap={1.5}
      ref={scrollRef}
    >
      <Box>{image}</Box>
      <Stack
        alignItems="flex-start"
        gap={1}
        sx={{
          maxWidth: "90%",
        }}
      >
        <Stack direction={"row"} gap={1}>
          {name}
          {time}
        </Stack>
        {message}
        {action}
      </Stack>
    </Stack>
  );
};

export default CommentLayout;
