import React, { useEffect, useRef, useState } from "react";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { UseQueryOptions, useQuery } from "react-query";
import { getLegalEntities } from "@services/api/businessProfile";
import { useAppSelector } from "@redux/hooks";
import { selectSelectedAccount } from "@redux/slices/auth/accounts";

export const useListLEsInfinitely = ({ q }: { q?: string }) => {
  const searchQuery = q;
  const loadingRef = useRef<boolean>(false);
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState<any>([]);
  const searchQueryRef = useRef(false);

  const selectedUser = useAppSelector(selectSelectedAccount);
  const merchantId = selectedUser?.id;

  const { data, isLoading, isError, isFetching } = useListLEs(
    {
      page,
      searchQuery: q,
    },
    {
      refetchOnWindowFocus: false,
      onSuccess() {
        loadingRef.current = false;
      },
    },
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  useEffect(() => {
    setPage(1);
    searchQueryRef.current = true;
  }, [searchQuery, merchantId]);

  useEffect(() => {
    if (searchQueryRef.current) {
      setAllData([...(data?.data || [])]);
    } else {
      setAllData([...allData, ...(data?.data || [])]);
    }

    searchQueryRef.current = false;
  }, [data]);

  return {
    isError,
    isFetching,
    page,
    rowsPerPage: ROWS_PER_PAGE,
    currentPageRows: allData,
    handlePageChange,
    totalRows: data?.total ?? 0,
    setPage: () => setPage((current) => current + 1),
    allRows: allData,
    loadingRef,
    isLoading,
    setPageDispatcher: setPage,
    setAllData,
    state: {
      isEmpty: !searchQuery && data?.total === 0,
      isError,
    },
  };
};

const useListLEs = (
  { page, searchQuery, merchantId }: any,
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const { data, isLoading, isError, isFetching } = useQuery(
    ["list-le-infinitely", page, searchQuery, merchantId],
    async () => {
      const data = await getLegalEntities(merchantId, {
        filter: `statusName:"approved"%3BallowMultipleMerchants:true&sort=name${
          searchQuery ? `&q="${searchQuery}"` : ""
        }&page=${page}&max=${ROWS_PER_PAGE}`,
      });
      return data;
    },
    {
      cacheTime: 0,
      ...options,
    },
  );

  return { data, isLoading, isError, isFetching };
};
