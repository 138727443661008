import SectionTitle, { SectionTitleProps } from "./SectionTitle";
import { Stack } from "@mui/material";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

const SubSectionLayout = ({
  delay,
  children,
  ...props
}: { children: React.ReactNode; delay: number } & SectionTitleProps) => {
  return (
    <FadeUpWrapper delay={delay}>
      <SectionTitle {...props} />
      <Stack direction="column" gap={1} alignItems="stretch">
        {children}
      </Stack>
    </FadeUpWrapper>
  );
};

export default SubSectionLayout;
