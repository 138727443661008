import React from "react";
import { palette } from "@palette";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Text } from "@common/Text";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getColorsMap, getIconMap } from "./colorsMap";

export type TagType =
  | "approved"
  | "pre approved"
  | "processing approved"
  | "active"
  | "expired"
  | "cancelled"
  | "declined"
  | "suspended"
  | "deactivated"
  | "used"
  | "refunded"
  | "chargeback"
  | "pending"
  | "pending review"
  | "declined"
  | "review"
  | "upload"
  | "upcoming"
  | "ended"
  | "test"
  | "completed"
  | "remaining"
  | "update_requested"
  | "Under Review"
  | "preapproved_mid_issue";

export type TagProps = {
  type: TagType;
  text?: string;
  filled?: boolean;
  shouldShowIcon?: boolean;
  shouldShowText?: boolean;
};

export type TransactionTableTagType = {
  filled?: boolean;
  type: TagType;
};

export const Tag: React.FC<TagProps> = ({
  type,
  filled = false,
  text,
  shouldShowIcon = false,
  shouldShowText = false,
}) => {
  const classes: any = getStyles(filled);
  const isDesktop = useMediaQuery("(min-width: 600px)");
  const Icon = getIconMap(type, filled);
  const color = getColorsMap(type, filled);

  return (
    <Box
      sx={{
        gap: "6px",
        display: "flex",
        borderRadius: 28,
        alignItems: "center",
        padding: isDesktop ? "4px 16px 4px 12px" : "4px 12px",
        border: `1px solid ${color}`,
      }}
      className={`${classes[type]}`}
    >
      {(!isDesktop || shouldShowIcon) && Icon}
      {(isDesktop || shouldShowText) && (
        <Text
          color={color}
          variant="body"
          fontWeight="book"
          textTransform="capitalize"
        >
          {text
            ? text
            : type === "upload"
            ? "upload bank statement"
            : type === "preapproved_mid_issue"
            ? "Pre Approved MID issue"
            : type}
        </Text>
      )}
    </Box>
  );
};

const getStyles = (filled: boolean) => {
  return filled ? useStylesFilled() : useStylesNeutral();
};

const useStylesNeutral = makeStyles({
  tag: {
    gap: "6px",
    display: "flex",
    borderRadius: 28,
    alignItems: "center",
    padding: "4px 16px 4px 12px",
    boxShadow:
      "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
  },
  approved: { backgroundColor: palette.success.light },
  "pre approved": { backgroundColor: palette.success.light },
  preapproved_mid_issue: { backgroundColor: palette.success.light },
  "processing approved": { backgroundColor: palette.success.light },
  active: { backgroundColor: palette.success.light },
  refunded: { backgroundColor: palette.warning.light },
  chargeback: { backgroundColor: palette.error.light },
  pending: {
    backgroundColor: palette.neutral[10],
    borderColor: palette.neutral[80],
  },
  "pending review": {
    backgroundColor: palette.neutral[10],
    borderColor: palette.neutral[80],
  },
  review: {
    backgroundColor: palette.neutral[10],
    borderColor: palette.neutral[80],
  },
  upload: { backgroundColor: palette.warning.light },
  upcoming: { backgroundColor: palette.success.light },
  ended: { backgroundColor: palette.info.soft },
  expired: { backgroundColor: palette.warning.light },
  used: { backgroundColor: "#F8EFFF" },
  cancelled: { backgroundColor: palette.error.light },
  declined: { backgroundColor: palette.error.light },
  test: { backgroundColor: palette.warning.light },
  remaining: { backgroundColor: palette.neutral[50] },
  completed: { backgroundColor: palette.success.light },
  suspended: { backgroundColor: palette.warning.light },
  deactivated: { backgroundColor: palette.gray[100] },
});

const useStylesFilled = makeStyles({
  tag: {
    gap: "6px",
    display: "flex",
    borderRadius: 28,
    alignItems: "center",
    padding: "4px 16px 4px 12px",
    boxShadow:
      "inset -4px -4px 9px rgba(255, 255, 255, 0.88), inset 0px 2px 14px rgba(193, 208, 238, 0.5)",
  },
  approved: { backgroundColor: palette.filled.green },
  "pre approved": { backgroundColor: palette.filled.green },
  preapproved_mid_issue: { backgroundColor: palette.filled.green },
  "processing approved": { backgroundColor: palette.filled.green },
  refunded: { backgroundColor: palette.filled.orange },
  chargeback: { backgroundColor: palette.filled.red },
  pending: { backgroundColor: palette.filled.grey },
  "pending review": { backgroundColor: palette.filled.grey },
  review: { backgroundColor: palette.filled.grey },
  ended: { backgroundColor: palette.filled.blue },
  "Under Review": {
    backgroundColor: "#FFF2E9",
  },
});
