import { palette } from "@palette";

export const Separator = ({ marginBottom = 10 }: { marginBottom?: number }) => {
  return (
    <div
      style={{
        height: 2,
        width: "100%",
        borderBottom: `2px solid ${palette.neutral[20]}`,
        marginBottom,
      }}
    />
  );
};
