import { RootState } from "@redux/store";
import { TableParams, SortSliceState, SortingOrder } from "@redux/types/sort";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const defaultParams: TableParams = {
  attribute: "",
  order: "asc",
};

const initialState: SortSliceState = {
  tables: {},
};

const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    setSorting: (
      state: SortSliceState,
      action: PayloadAction<{
        tableName: string;
        params: Partial<TableParams>;
      }>,
    ) => {
      const { tableName, params } = action.payload;
      const oldState = state.tables[tableName];
      const oldValues = {
        attribute: oldState?.attribute || defaultParams.attribute,
        order: oldState?.order || defaultParams.order,
      };

      state.tables[tableName] = {
        attribute: params?.attribute || oldValues.attribute,
        order: params?.order || oldValues.order,
      };
    },
    toggleOrder: (state: SortSliceState, action: PayloadAction<string>) => {
      const tableName = action.payload;
      const currentOrder = state.tables[tableName]?.order;
      const order = (currentOrder === "asc" ? "desc" : "asc") as SortingOrder;

      state.tables[tableName] = {
        ...state.tables[tableName],
        order,
      };
    },
    resetSorting: (state: SortSliceState, action: PayloadAction<string>) => {
      const tableName = action.payload;
      if (state.tables[tableName]) {
        state.tables[tableName] = defaultParams;
      }
    },
  },
});

export const { setSorting, toggleOrder, resetSorting } = sortSlice.actions;

export const selectSortAttribute = (state: RootState, tableName: string) =>
  state.sort.tables[tableName]?.attribute || "";
export const selectSortOrder = (state: RootState, tableName: string) =>
  state.sort.tables[tableName]?.order || "asc";
export const selectSorting = (state: RootState, tableName: string) =>
  state.sort.tables[tableName] || defaultParams;

export default sortSlice.reducer;
