import { SidePanel } from "@containers/SidePanel";
import NiceModal from "@ebay/nice-modal-react";
import { useTeamMember } from "../hooks";
import { MemberData } from "@customTypes/team.member";
import TeamPanelBody from "../components/TeamPanelBody";
import { useMediaQuery } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import { useTeamPermissions } from "features/Permissions/AccessControl/hooks";

type Props = {
  rowData: MemberData;
};

const TeamMemberPanel = NiceModal.create((props: Props) => {
  const isLargeDesktop = useMediaQuery("(min-width: 1068px)");
  const { modal, handleClose } = useTeamMember();
  const { isListAccessPoliciesAllowed } = useTeamPermissions();

  const { email } = useAppSelector(selectUser);
  const {
    rowData: { roleName, user },
  } = props;

  const showPermissions = roleName !== "owner" && user.email !== email;
  const modalWidth =
    !showPermissions || !isListAccessPoliciesAllowed
      ? "500px"
      : isLargeDesktop
      ? "1068px"
      : "100%";

  const bodyProps = {
    ...props,
    handleClose,
    showPermissions,
  };

  return (
    <SidePanel width={modalWidth} modal={modal} onCloseDrawer={handleClose}>
      <TeamPanelBody {...bodyProps} />
    </SidePanel>
  );
});

export default TeamMemberPanel;
