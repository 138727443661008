import { IconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import React, { useState } from "react";
import { palette } from "@palette";
import { styled, makeStyles } from "@mui/styles";
import { Stack } from "@mui/material";
import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import { DisputesRowType } from "@components/Disputes/RespondToDispute/types";
import { ActionsMenu } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/ActionsMenu";
import { MoreActionButton } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/MoreActionButton";
import useExtraActions from "../hooks/useExtraActions";

const data = {
  id: "1",
  markedAsFraud: true,
} as DisputesRowType;
export interface IDisputePreviewHeaderProps {
  isFirst: boolean;
  isLast: boolean;
  setSelectedRow: (value: string | number) => void;
}

const DisputePreviewHeader = ({
  isFirst,
  isLast,
  setSelectedRow,
}: IDisputePreviewHeaderProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { actions } = useExtraActions(data);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack direction="row" justifyContent="space-between" pb={3}>
      <Stack direction="row" gap={2}>
        <IconButton
          size="small"
          className={`${classes.iconBtn} ${classes.iconBtnText}`}
          onClick={() => {
            setSelectedRow("prev");
          }}
          disabled={isFirst}
        >
          <ArrowUp size={20} color={palette.neutral[80]} />
          <StyledText pl={0.5} fontWeight="book">
            Previous
          </StyledText>
        </IconButton>
        <IconButton
          size="small"
          className={`${classes.iconBtn} ${classes.iconBtnText}`}
          onClick={() => {
            setSelectedRow("next");
          }}
          disabled={isLast}
        >
          <StyledText pr={0.5} fontWeight="book">
            Next
          </StyledText>
          <ArrowDown size={20} color={palette.neutral[80]} />
        </IconButton>
      </Stack>
      {actions.filter((x) => !x.hidden).length ? (
        <MoreActionButton onClick={onOpenMenu} />
      ) : null}
      <ActionsMenu
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        actions={actions}
      />
    </Stack>
  );
};

const StyledText = styled(Text)({});

const useStyles = makeStyles(() => ({
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    "&:hover": {
      background: palette.neutral[20],
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
    },
  },
}));

export default DisputePreviewHeader;
