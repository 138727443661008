import { DropDownIcon } from "@assets/icons";
import { Text } from "@common/Text";
import { Box, Grid, Skeleton, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import CustomMenu from "./CustomMenu";
import useGetPEPDetails from "../hooks/useGetPEPDetails";
import { PEPStatusType, THistoryCheck, TPEPOwner } from "../types";
import PepStatusChip from "./PepStatusChip";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";

import CustomCollapsible from "./CustomCollapsible";
import { format } from "date-fns";
import { InfoIcon } from "@assets/rebrandIcons";

interface Props {
  check: THistoryCheck;
  merchantId: number;
  legalEntityID: number;
  selectedOwner: TPEPOwner;
  latestStatus: PEPStatusType;
}

function PepDetailedCard({
  merchantId,
  selectedOwner,
  legalEntityID,
  latestStatus,
  check,
}: Props) {
  const {
    data,
    isLoading,
    handleUpdateStatus,
    anchorEl,
    handleCloseMenu,
    handleOpenMenu,
  } = useGetPEPDetails({
    checkId: check.checkID,
    merchantId,
    selectedOwner,
    legalEntityID,
  });

  const enabled =
    (["possible_match", "confirmed_match"].includes(check?.status) &&
      ["possible_match", "confirmed_match"].includes(latestStatus)) ||
    check?.manualPepStatus === "clear";

  const EndIcon = enabled ? <DropDownIcon width={12} height={12} /> : undefined;

  const isEditStatusAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
      RESOURCE_BASE.PRINCIPAL,
      RESOURCE_BASE.PEP,
    ),
    operation: OPERATIONS.UPDATE,
  });

  return (
    <>
      <Box>
        <FadeUpWrapper delay={50}>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            margin="8px 0 16px 0"
          >
            <Text color={palette.neutral[80]}>{selectedOwner?.name}</Text>
            <CustomToolTip
              showToolTip={!isEditStatusAllowed && enabled}
              message={EDIT_DENY_MESSAGE}
            >
              <CustomButton
                disabled={!enabled || !isEditStatusAllowed}
                onClick={handleOpenMenu}
                data-testid="change-status-button"
              >
                <PepStatusChip
                  ownerPepStatus={check.status}
                  manualPepStatus={check.manualPepStatus}
                  EndIcon={EndIcon}
                />
              </CustomButton>
            </CustomToolTip>
          </Stack>
        </FadeUpWrapper>
        <Stack spacing={2}>
          {check?.manualPepStatus && (
            <FadeUpWrapper delay={100}>
              <Text
                fontWeight="book"
                color={palette.neutral[80]}
                textAlign="right"
              >
                {check?.manualPepStatus === "confirmed_match"
                  ? "Confirmed"
                  : "Manually Cleared"}{" "}
                on{" "}
                {check?.manualStatusAt &&
                  format(
                    new Date(check?.manualStatusAt * 1000),
                    "MMMM dd, yyyy, hh:mm:ss a 'UTC'",
                  )}{" "}
                {/* TODO: API integration */}
              </Text>
            </FadeUpWrapper>
          )}
          <FadeUpWrapper delay={125}>
            <PepInitialRunCard date={check.date} />
          </FadeUpWrapper>
        </Stack>
        {isLoading ? (
          <CustomSkeleton />
        ) : (
          <Box mt="16px">
            {data?.properties?.map((data, idx) => (
              <FadeUpWrapper key={idx} delay={120 + idx * 50}>
                <CustomCollapsible selectedOwner={selectedOwner} data={data} />
              </FadeUpWrapper>
            ))}
          </Box>
        )}
      </Box>
      <CustomMenu
        selectedOwnerName={selectedOwner?.name}
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        onUpdate={handleUpdateStatus}
        manualPepStatus={check?.manualPepStatus}
      />
    </>
  );
}

export default PepDetailedCard;

const CustomButton = styled("button")(() => ({
  all: "unset",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  "&:disabled": {
    cursor: "default",
  },
}));

const PepInitialRunCard = ({ date }: { date: number }) => {
  return (
    <Stack
      spacing={1}
      padding="12px 16px"
      sx={{ background: "rgba(230, 234, 242, 0.50)", borderRadius: "12px" }}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <InfoIcon />
        <Text fontSize={18} color={palette.info.main}>
          Info
        </Text>
      </Stack>
      <Text fontWeight="book" color={palette.info.main}>
        Initial check ran on{" "}
        {format(new Date(date * 1000), "MMMM dd, yyyy, hh:mm:ss a 'UTC'")}
      </Text>
    </Stack>
  );
};

const CustomSkeleton = () => {
  return (
    <>
      {Array(20)
        .fill(null)
        .map((i, idx) => (
          <Grid
            data-testid="pep_detailed_skeleton"
            my="8px"
            key={idx}
            container
          >
            <Skeleton
              sx={{
                borderRadius: "4px",
              }}
              width="100%"
              variant="rounded"
              height="48px"
            />
          </Grid>
        ))}
    </>
  );
};
