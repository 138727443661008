import { StyledIconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import { NewLogoutIcon } from "@assets/icons";
import { stopEventPropagation } from "@utils/helpers";
import NiceModal from "@ebay/nice-modal-react";
import { LOGOUT_MODAL } from "modals/modal_names";

function ProfileLogout() {
  const handleShowlogoutModal = stopEventPropagation(() => {
    NiceModal.show(LOGOUT_MODAL);
  });

  return (
    <StyledIconButton
      sx={{
        "&.CustomIconButton": {
          width: "100%",
          justifyContent: "flex-start",
          mt: 3,
          pl: "10px",
        },
        "&:hover path": {
          fill: "#BB1111",
        },
      }}
      startIcon={<NewLogoutIcon />}
      onClick={handleShowlogoutModal}
    >
      <Text color="#BB1111">Log out</Text>
    </StyledIconButton>
  );
}

export default ProfileLogout;
