import { customInstance } from "@services/api";
import { buildAccountsUrl } from "@services/api/utils.api";
import axios from "axios";

export const getS3UploadUrl = ({
  attachmentType,
  name,
}: {
  attachmentType: string;
  name: string;
}) =>
  customInstance({
    url: `s3/presign-url`,
    method: "POST",
    data: {
      attachmentType: attachmentType,
      fileName: name,
    },
  });

export const createAccountFiles = ({
  merchantId,
  customDocumentList,
  challengeSlugParam,
}: {
  merchantId: number;
  customDocumentList: any[];
  challengeSlugParam?: string;
}) =>
  customInstance({
    url: `accounts/${merchantId}/files`,
    method: "POST",
    data: { list: customDocumentList },
    params: { challenge_slug: challengeSlugParam },
  });

export const confirmAccountFiles = ({
  merchantId,
  confirmedUploadedList,
}: {
  merchantId: number;
  confirmedUploadedList: any[];
}) =>
  customInstance({
    url: `accounts/${merchantId}/files/confirm`,
    method: "POST",
    data: { list: confirmedUploadedList },
  });

export const updateMerchant = ({
  merchantId,
  signatureURL,
  ...rest
}: {
  merchantId: number;
  signatureURL: string;
  [key: string]: any;
}) =>
  customInstance({
    url: `/merchants/${merchantId}`,
    method: "PATCH",
    data: {
      signatureURL,
      ...rest,
    },
  });

export const createImage = (data: any) =>
  customInstance({
    url: buildAccountsUrl("media-items"),
    method: "POST",
    data,
  });

export const updateUser = ({ accID, data }: { accID: number; data: any }) =>
  customInstance({
    url: `/users/${accID}`,
    method: "PATCH",
    data,
  });

export const getS3UploadUrl2 = ({ formType }: { formType: string }) =>
  customInstance({
    url: "s3/upload-form",
    method: "POST",
    data: {
      formType,
    },
  });

export const axiosUpload = ({
  s3Response,
  file,
  signal,
  setUploadProgress,
}: {
  s3Response: any;
  file: any;
  signal: any;
  setUploadProgress: any;
}) =>
  axios.post(
    s3Response.action,
    {
      key: s3Response.key,
      acl: s3Response.acl,
      "x-amz-date": s3Response["x-amz-date"],
      "x-amz-credential": s3Response["x-amz-credential"],
      "x-amz-algorithm": s3Response["x-amz-algorithm"],
      policy: s3Response.policy,
      "Content-Type": `image/${file?.name?.split(".")?.pop()}`,
      "X-Amz-Signature": s3Response["x-amz-signature"],
      file,
    },
    {
      signal,
      onUploadProgress: (progressEvent) => {
        setUploadProgress({
          key: s3Response.key,
          data: {
            progress: progressEvent.total
              ? Math.floor((progressEvent.loaded * 100) / progressEvent.total)
              : 0,
          },
        });
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

export const deleteFile = ({
  accountToRequest,
  identifier,
}: {
  accountToRequest: string;
  identifier: string;
}) =>
  customInstance({
    url: `/accounts/${accountToRequest}/media-items/${identifier}`,
    method: "DELETE",
  });
