import React from "react";

function PEPIcon({ fill = "#292727" }) {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.5H1C0.734784 0.5 0.48043 0.605357 0.292893 0.792893C0.105357 0.98043 0 1.23478 0 1.5V5.17312C0 10.7738 4.73875 12.6319 5.6875 12.9475C5.89014 13.0164 6.10986 13.0164 6.3125 12.9475C7.2625 12.6319 12 10.7738 12 5.17312V1.5C12 1.23478 11.8946 0.98043 11.7071 0.792893C11.5196 0.605357 11.2652 0.5 11 0.5ZM11 5.17438C11 10.0756 6.85313 11.7131 6 11.9981C5.15438 11.7163 1 10.08 1 5.17438V1.5H11V5.17438Z"
        fill={fill}
      />
    </svg>
  );
}

export default PEPIcon;
