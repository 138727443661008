import { TagType } from "@common/Tag";
import {
  Method,
  SourceAccount,
} from "@components/ManageMoney/TransactionTable/transactions.types";

export type TransactionData = {
  id: string;
  title: string;
  OrganizationID: number;
  OrganizationName: string;
  date: number | Date | string;
  time: string;
  type: string;
  recurrence: string;
  amount: number;
  status: TagType | any;
  source: string;
  currency: string;
  balance: number;
  transaction: string;
  email: string;
  sourceAccount: SourceAccount;
  sourceMethod: Method;
  paymentDetails: {
    merchantTaxId: string;
    processedDate: string;
    nameOnCard: string;
    subtype: string;
    type: string;
    revenueSource: string;
    description: string;
    donation: string;
    giveboxFee: string;
    visaFee: string;
    charged: string;
    recurring: string;
  };
  recurringPurchases?: {
    id: number;
    title: string;
    description: string;
    type: string;
    recurrence: string;
    nextTransaction: string;
    details: {
      donation: number;
      giveboxFee: number;
      visaFee: number;
      charged: number;
    };
  }[];
};

export const tableData = [
  {
    sourceMethod: {
      id: 1,
      kind: "any_kind",
      type: "any_type",
      bankAccount: {},
      card: {
        brand: "Amex",
        cardholderName: "Test Name",
        numberLas4: 5903,
        expiryMonth: 5,
        expiryYear: 2028,
        isDebit: false,
      },
    },
    sourceAccount: {
      id: "1",
      type: "test",
      user: {
        email: "test12mail.com",
        firstName: "Test",
        lastName: "1",
        phoneNumber: "0047",
      },
    },
    id: "1",
    title: "Tatiana Saris",
    OrganizationID: 196,
    OrganizationName: "New Organization 2nd Pass",
    date: "Sep 02 2022",
    time: "Sep 02 2022 11:17PM UTC",
    type: "Credit Card",
    recurrence: "monthly",
    amount: 24000,
    status: "pending",
    source: "Website",
    currency: "USD",
    balance: 1212442.43,
    transaction: "givebox purchases",
    email: "tatianasaris@yahoo.com",
    paymentDetails: {
      merchantTaxId: "3272",
      processedDate: "06/27/2022 12:06PM UTC",
      nameOnCard: "Tatiana Saris",
      subtype: "Sales Transaction",
      revenueSource: "staging.givebox/12321312",
      type: "donation",
      description: "Provide foods and water for services of dogs",
      donation: "61,85",
      giveboxFee: "0,00",
      visaFee: "2.15",
      charged: "64.00",
      recurring: "One Time",
    },
    recurringPurchases: [
      {
        id: 1,
        title: "Invoice",
        description: "Provide food and water for services of dogs",
        type: "Sales",
        recurrence: "quarterly",
        nextTransaction: "10/26/2022",
        details: {
          donation: 103.95,
          giveboxFee: 1.11,
          visaFee: 1.15,
          charged: 106.21,
        },
      },
      {
        id: 2,
        title: "Donation",
        OrganizationID: 196,
        OrganizationName: "New Organization 2nd Pass",
        description: "Save one million lives in the middle east and africa",
        type: "Charitable donation",
        recurrence: "one time",
        nextTransaction: "12/20/2022",
        details: {
          donation: 2000.99,
          giveboxFee: 2.59,
          visaFee: 10.55,
          charged: 117.09,
        },
      },
    ],
  },
  {
    sourceMethod: {
      id: 1,
      kind: "any_kind",
      type: "any_type",
      bankAccount: {},
      card: {
        brand: "Amex",
        cardholderName: "Test Name",
        numberLas4: 5903,
        expiryMonth: 5,
        expiryYear: 2028,
        isDebit: false,
      },
    },
    sourceAccount: {
      id: "1",
      type: "test",
      user: {
        email: "test12mail.com",
        firstName: "Test",
        lastName: "1",
        phoneNumber: "0047",
      },
    },
    id: "2",
    title: "Leo Ekstrom Bothman",
    OrganizationID: 196,
    OrganizationName: "New Organization 2nd Pass",
    date: "Aug 31 2022",
    time: "Aug 31 2022 10:23PM UTC",
    type: "Events",
    recurrence: "yearly",
    amount: 50000,
    status: "chargeback",
    source: "Website",
    currency: "EUR",
    balance: 1233122.43,
    transaction: "Debits (Money Transferred)",
    email: "bothmanleo@gmail.com",
    paymentDetails: {
      merchantTaxId: "3272",
      processedDate: "06/27/2022 12:06PM UTC",
      nameOnCard: "Leo Ekstrom",
      subtype: "Sales Transaction",
      revenueSource: "staging.givebox/12321312",
      type: "donation",
      description: "Provide foods and water for services of dogs",
      donation: "61,85",
      giveboxFee: "0,00",
      visaFee: "2.15",
      charged: "64.00",
      recurring: "One Time",
    },
    recurringPurchases: [
      {
        id: 1,
        title: "Donation",
        description: "The Sokovia intervention and aid project",
        type: "Charitable donation",
        recurrence: "quarterly",
        nextTransaction: "9/04/2022",
        details: {
          donation: 3000.49,
          giveboxFee: 3.49,
          visaFee: 16.55,
          charged: 3200.52,
        },
      },
      {
        id: 2,
        title: "Donation",
        description: "Save one million lives in the middle east and africa",
        type: "Sales",
        recurrence: "monthly",
        nextTransaction: "9/30/2022",
        details: {
          donation: 4000.99,
          giveboxFee: 4.59,
          visaFee: 20.55,
          charged: 4026.13,
        },
      },
    ],
  },
  {
    sourceMethod: {
      id: 1,
      kind: "any_kind",
      type: "any_type",
      bankAccount: {},
      card: {
        brand: "Amex",
        cardholderName: "Test Name",
        numberLas4: 5903,
        expiryMonth: 5,
        expiryYear: 2028,
        isDebit: false,
      },
    },
    sourceAccount: {
      id: "1",
      type: "test",
      user: {
        email: "test12mail.com",
        firstName: "Test",
        lastName: "1",
        phoneNumber: "0047",
      },
    },
    id: "3",
    title: "Davis Gouse",
    OrganizationID: 196,
    OrganizationName: "New Organization 2nd Pass",
    date: "April 19 2020",
    time: "April 19 2020 11:23PM UTC",
    type: "Invoice",
    recurrence: "daily",
    amount: 10000,
    status: "refunded",
    currency: "AUD",
    source: "Website",
    balance: 1212122.43,
    transaction: "Credits (Money Received)",
    email: "davidgouse@gmail.com",
    paymentDetails: {
      merchantTaxId: "3272",
      processedDate: "06/27/2022 12:06PM UTC",
      nameOnCard: "Davis Gouse",
      subtype: "Sales Transaction",
      revenueSource: "Website",
      type: "donation",
      description: "Provide foods and water for services of dogs",
      donation: "61,85",
      giveboxFee: "0,00",
      visaFee: "2.15",
      charged: "64.00",
      recurring: "One Time",
    },
    recurringPurchases: [
      {
        id: 1,
        title: "Invoice",
        description: "Cancer prevention research and solutions",
        type: "Sales Transaction",
        recurrence: "one time",
        nextTransaction: "11/11/2022",
        details: {
          donation: 4000.99,
          giveboxFee: 4.59,
          visaFee: 20.55,
          charged: 4026.13,
        },
      },
      {
        id: 2,
        title: "Donation",
        description: "Save one million lives in the middle east and africa",
        type: "Charitable donation",
        recurrence: "daily",
        nextTransaction: "12/31/2022",
        details: {
          donation: 2000.99,
          giveboxFee: 2.59,
          visaFee: 10.55,
          charged: 2014.12,
        },
      },
    ],
  },
  {
    sourceMethod: {
      id: 1,
      kind: "any_kind",
      type: "any_type",
      bankAccount: {},
      card: {
        brand: "Amex",
        cardholderName: "Test Name",
        numberLas4: 5903,
        expiryMonth: 5,
        expiryYear: 2028,
        isDebit: false,
      },
    },
    sourceAccount: {
      id: "1",
      type: "test",
      user: {
        email: "test12mail.com",
        firstName: "Test",
        lastName: "1",
        phoneNumber: "0047",
      },
    },
    id: "4",
    title: "Jamila Passaquindici Arcand",
    OrganizationID: 196,
    OrganizationName: "New Organization 2nd Pass",
    date: "Mar 05 2021",
    time: "Mar 05 2021 10:23PM UTC",
    type: "Events",
    recurrence: "one-time",
    amount: 9000,
    status: "approved",
    currency: "USD",
    source: "Website",
    balance: 1214322.43,
    transaction: "Debits (Money Transferred)",
    email: "jamilarc@yahoo.com",
    paymentDetails: {
      merchantTaxId: "3272",
      processedDate: "06/27/2022 12:06PM UTC",
      nameOnCard: "Jamila Passaquindici Arcand",
      subtype: "Sales Transaction",
      revenueSource: "Website",
      type: "donation",
      description: "Provide foods and water for services of dogs",
      donation: "61,85",
      giveboxFee: "0,00",
      visaFee: "2.15",
      charged: "64.00",
      recurring: "One Time",
    },
    recurringPurchases: [
      {
        id: 1,
        title: "Donation",
        description: "The Sokovia intervention and aid project",
        type: "Charitable donation",
        recurrence: "quarterly",
        nextTransaction: "9/04/2022",
        details: {
          donation: 3000.75,
          giveboxFee: 3.59,
          visaFee: 16.55,
          charged: 3020.03,
        },
      },
      {
        id: 2,
        title: "Donation",
        description: "Save one million lives in the middle east and africa",
        type: "Charitable donation",
        recurrence: "monthly",
        nextTransaction: "9/30/2022",
        details: {
          donation: 4000.99,
          giveboxFee: 4.59,
          visaFee: 20.55,
          charged: 4026.13,
        },
      },
    ],
  },
  {
    sourceMethod: {
      id: 1,
      kind: "any_kind",
      type: "any_type",
      bankAccount: {},
      card: {
        brand: "Amex",
        cardholderName: "Test Name",
        numberLas4: 5903,
        expiryMonth: 5,
        expiryYear: 2028,
        isDebit: false,
      },
    },
    sourceAccount: {
      id: "1",
      type: "test",
      user: {
        email: "test12mail.com",
        firstName: "Test",
        lastName: "1",
        phoneNumber: "0047",
      },
    },
    id: "5",
    title: "Yor Zephyr",
    OrganizationID: 196,
    OrganizationName: "New Organization 2nd Pass",
    date: "Nov 14 2020",
    time: "Nov 14 2020 12:07PM UTC",
    type: "Sweepstakes",
    recurrence: "quarterly",
    amount: 12000,
    status: "pending",
    source: "Website",
    currency: "GBP",
    balance: 121782.43,
    transaction: "givebox purchases",
    email: "yorzephyrs@gmail.com",
    paymentDetails: {
      merchantTaxId: "3272",
      processedDate: "06/27/2022 12:06PM UTC",
      nameOnCard: "Yor Zephyr",
      subtype: "Sales Transaction",
      revenueSource: "Website",
      type: "donation",
      description: "Provide foods and water for services of dogs",
      donation: "61,85",
      giveboxFee: "0,00",
      visaFee: "2.15",
      charged: "64.00",
      recurring: "One Time",
    },
    recurringPurchases: [
      {
        id: 1,
        title: "Donation",
        description: "The Sokovia intervention and aid project",
        type: "Charitable donation",
        recurrence: "one time",
        nextTransaction: "10/10/2022",
        details: {
          donation: 3400.67,
          giveboxFee: 2.59,
          visaFee: 15.55,
          charged: 3419.14,
        },
      },
      {
        id: 2,
        title: "Donation",
        description: "Save one million lives in the middle east and africa",
        type: "Charitable donation",
        recurrence: "monthly",
        nextTransaction: "9/30/2022",
        details: {
          donation: 3000.15,
          giveboxFee: 3.79,
          visaFee: 16.55,
          charged: 3020.49,
        },
      },
    ],
  },
  {
    sourceMethod: {
      id: 1,
      kind: "any_kind",
      type: "any_type",
      bankAccount: {},
      card: {
        brand: "Amex",
        cardholderName: "Test Name",
        numberLas4: 5903,
        expiryMonth: 5,
        expiryYear: 2028,
        isDebit: false,
      },
    },
    sourceAccount: {
      id: "1",
      type: "test",
      user: {
        email: "test12mail.com",
        firstName: "Test",
        lastName: "1",
        phoneNumber: "0047",
      },
    },
    id: "6",
    title: "Zaire Lubin",
    OrganizationID: 196,
    OrganizationName: "New Organization 2nd Pass",
    date: "Jan 30 2021",
    time: "Jan 30 2021 12:43PM UTC",
    type: "Donations",
    recurrence: "monthly",
    amount: 15000,
    status: "pending",
    currency: "USD",
    balance: 121782.43,
    transaction: "Credits (Money Received)",
    email: "zeelube@yahoo.com",
    source: "Website",
    paymentDetails: {
      merchantTaxId: "3272",
      processedDate: "06/27/2022 12:06PM UTC",
      nameOnCard: "Zaire Lubin",
      subtype: "Sales Transaction",
      revenueSource: "Website",
      type: "donation",
      description: "Provide foods and water for services of dogs",
      donation: "61,85",
      giveboxFee: "0,00",
      visaFee: "2.15",
      charged: "64.00",
      recurring: "One Time",
    },
    recurringPurchases: [
      {
        id: 1,
        title: "Donation",
        description: "The Sokovia intervention and aid project",
        type: "Charitable donation",
        recurrence: "quarterly",
        nextTransaction: "10/10/2022",
        details: {
          donation: 3400.67,
          giveboxFee: 2.59,
          visaFee: 15.55,
          charged: 3419.14,
        },
      },
      {
        id: 2,
        title: "Donation",
        description: "Save one million lives in the middle east and africa",
        type: "Charitable donation",
        recurrence: "monthly",
        nextTransaction: "9/30/2022",
        details: {
          donation: 3000.15,
          giveboxFee: 3.79,
          visaFee: 16.55,
          charged: 3020.49,
        },
      },
    ],
  },
];
