import { namespaces } from "../../../../resources/i18n.constants";

export const manageUnderwriting = {
  [namespaces.pages.enterprise.manageUnderwriting]: {
    // COMMENTS
    comments: "Comments",
    comment: "Comment",
    add_comment: "Add Comment",
    save_changes: "Save Changes",
    write_a_comment: "Write a comment...",
    cancel: "Cancel",
    comment_deleted: "Comment Deleted",
  },
};
