export const colors = {
  GeneralColors: [
    {
      id: "1",
      name: "Primary",
      hexes: [{ hexValue: "#3D50FF", position: "100%" }],
      type: "global",
    },
    {
      id: "2",
      name: "Secondary",
      hexes: [{ hexValue: "#E57C1A", position: "100%" }],
      type: "global",
    },
    {
      id: "3",
      name: "Heading",
      hexes: [{ hexValue: "#111111", position: "100%" }],
      type: "global",
    },
    {
      id: "4",
      name: "Paragraph",
      hexes: [{ hexValue: "#292727", position: "100%" }],
      type: "global",
    },
    // {
    //   id: "5",
    //   name: "Background Page",
    //   hexes: [{ hexValue: "#F8F8F6", position: "100%" }],
    //   type: "global",
    // },
  ],
  CustomColors: [
    // {
    //   id: "1",
    //   name: "Ocean Breeze",
    //   color_usage: "secondary",
    //   hexes: [
    //     {
    //       hexValue: "#0077BE",
    //       position: "30%",
    //     },
    //     {
    //       hexValue: "#0096C7",
    //       position: "60%",
    //     },
    //     {
    //       hexValue: "#00B4D8",
    //       position: "90%",
    //     },
    //   ],
    //   angle: "90deg",
    //   isGradient: true,
    //   type: "custom",
    // },
    // {
    //   id: "2",
    //   name: "Misty Morning",
    //   color_usage: "tertiary",
    //   hexes: [
    //     {
    //       hexValue: "#E0E4CC",
    //       position: "100%",
    //     },
    //   ],
    //   angle: "0deg",
    //   isGradient: false,
    //   type: "custom",
    //   addedAt: 0,
    // },
    // {
    //   id: "3",
    //   name: "Autumn Fire",
    //   color_usage: "primary",
    //   hexes: [
    //     {
    //       hexValue: "#FF6107",
    //       position: "10%",
    //     },
    //     {
    //       hexValue: "#FF9505",
    //       position: "50%",
    //     },
    //     {
    //       hexValue: "#FFC12F",
    //       position: "100%",
    //     },
    //   ],
    //   angle: "120deg",
    //   isGradient: true,
    //   type: "custom",
    // },
  ],
};
