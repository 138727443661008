interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
}

const EyeIcon = ({
  width = 24,
  height = 24,
  fill = "none",
  path_fill = "#734089",
}: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.33594C6.55576 5.33594 3.53109 9.57019 2.45554 11.4523C2.23488 11.8385 2.12456 12.0315 2.1367 12.3195C2.14885 12.6076 2.27857 12.7957 2.53799 13.1721C3.8182 15.0295 7.29389 19.3359 12 19.3359C16.7061 19.3359 20.1818 15.0295 21.462 13.1721C21.7214 12.7957 21.8511 12.6076 21.8633 12.3195C21.8754 12.0315 21.7651 11.8385 21.5445 11.4523C20.4689 9.57019 17.4442 5.33594 12 5.33594Z"
        stroke={path_fill}
        strokeWidth="1.2"
      />
      <circle cx="12" cy="12.3359" r="3" stroke={path_fill} strokeWidth="1.2" />
    </svg>
  );
};

export default EyeIcon;
