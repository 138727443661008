import NoResultsState from "@common/EmptyState/NoResultsState";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import { Stack } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import React from "react";
import { CARD_CONTENT_HEIGHT } from "./BaseCampaignCard";
import ListItemSkeleton from "@common/Skeleton/ListItemSkeleton";
import { IColumn } from "./campaigns.types";

type CampaignListWrapperWithStatesProps = {
  queryKey: string;
  children: React.ReactNode;
  isLoading: boolean;
  length?: number;
  itemsPerPage: number;
  columns: IColumn[];
};

const CampaignListWrapperWithStates = ({
  queryKey,
  children,
  isLoading,
  length,
  itemsPerPage,
  columns,
}: CampaignListWrapperWithStatesProps) => {
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, queryKey)
  );
  const skeletonItems = Array.from(
    {length: itemsPerPage },
    (_, i) => ({ id: `skeleton-${i}`}),
  );
  const itemHeight = CARD_CONTENT_HEIGHT;

  if (!length && !isLoading) return <NoResultsState searchQuery={searchQuery} />;

  if (isLoading)
    return (
      <UnfoldListItems
        items={skeletonItems}
        renderKey={(row) => row.id}
        height={itemHeight}
        ItemComponent={(row, idx, height) => (
          <ListItemSkeleton
            key={row.id}
            height={height}
            rowHeight={itemHeight}
            columns={columns}
          />
        )}
      />
    )
    
  return <Stack flexGrow={1}>{children}</Stack>;
}

export default CampaignListWrapperWithStates;
