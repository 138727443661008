import MuiDrawer from "@mui/material/Drawer";
import {
  styled,
  DrawerProps,
  List as MuiList,
  ListProps,
  Box as MuiBox,
} from "@mui/material";
import {
  openedMixin,
  closedMixin,
  drawerOpenedMixin,
  drawerClosedMixin,
} from "./DrawerMixins";
import { MASQUERADE_SNACKBAR_HEIGHT } from "@components/Merchants/Masquerade/MasqueradeSnackbar";
import { CustomStyleProps } from "@customTypes/style.types";

interface StyledDrawerProps extends DrawerProps, CustomStyleProps {}

export const DesktopDrawer = styled<React.FC<StyledDrawerProps>>(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMasqueradeMode",
})(({ theme, open, isMasqueradeMode }) => ({
  flexShrink: 0,
  position: "relative",
  zIndex: "0 !important",
  boxSizing: "border-box",

  "& .MuiPaper-root": {
    //adding masqueradeMode condition for the laggy scroll on the left nav bar
    top: isMasqueradeMode ? `${MASQUERADE_SNACKBAR_HEIGHT}px` : 0,
    left: 0,
    Height: "100vh",
    overflow: "visible",
    borderRight: "none",
    backgroundColor: "inherit",
    paddingBottom: isMasqueradeMode ? `${MASQUERADE_SNACKBAR_HEIGHT}px` : 0,
  },

  [theme.breakpoints.up("xl")]: {
    overflow: "visible",
    position: "sticky",
    height: "100vh",
    top: isMasqueradeMode ? `${MASQUERADE_SNACKBAR_HEIGHT + 16}px` : "16px",
    left: 0,
  },

  ...(open && {
    ...drawerOpenedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .MuiList-root": openedMixin(theme),
  }),
  ...(!open && {
    ...drawerClosedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .MuiList-root": closedMixin(theme),
    overflow: "hidden",
  }),
}));

export const MobileDrawer = styled(MuiDrawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "283px",
    padding: theme.spacing(6, 0, 0),
    borderRadius: "0px 12px 12px 0px",

    [theme.breakpoints.down("xl")]: {
      padding: theme.spacing(0),
    },
  },

  "*": {
    "&::-webkit-scrollbar": {
      width: 3,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
      backgroundColor: theme.palette.neutral[100],
    },
  },
}));

interface StyledListProps extends ListProps, CustomStyleProps {}

export const DrawerContent = styled<React.FC<StyledListProps>>(MuiList, {
  shouldForwardProp: (prop) => prop !== "isMasqueradeMode",
})(({ theme, isMasqueradeMode }) => ({
  height: isMasqueradeMode ? "calc( 100vh - 36px )" : "100vh",
  display: "flex",
  justifyContent: "center",
  bottom: 0,
  paddingBottom: 0,
  background: "#FFFFFF",
  flexDirection: "column",
  borderRight: "1px solid #F8F8F6",
  zIndex: 2,
  "*": {
    "&::-webkit-scrollbar": {
      width: 3,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "inherit",
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
      backgroundColor: theme.palette.neutral[100],
    },
  },

  [theme.breakpoints.down("sm")]: {
    height: "100%",
    borderRadius: 0,
    maxHeight: "100%",
    boxShadow: "none",
  },
}));

export const DrawerMenu = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})<{ drawerOpen?: boolean; isDesktop?: boolean }>(({ drawerOpen }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  marginY: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: drawerOpen ? "stretch" : "center",
}));

export const DrawerFooterMenu = styled(MuiBox, {
  shouldForwardProp: (prop) => !["drawerOpen"].includes(prop as string),
})<{ drawerOpen?: boolean; isFloor?: boolean }>(({ drawerOpen }) => ({
  overflow: "visible",
  marginTop: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: 0,
  alignItems: drawerOpen ? "stretch" : "center",
}));
