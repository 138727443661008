import * as React from "react";
import { palette } from "@palette";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// mui
import {
  Box,
  Stack,
  useTheme,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
// components
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { BaseModal } from "@common/Modal";
// assets
import { CloseIcon } from "@assets/icons";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";

type IProps = {
  title: string;
  removeAction: () => void;
  children?: React.ReactNode;
};

const RemoveWatchlist = NiceModal.create(
  ({ title, children, removeAction }: IProps) => {
    const modal = useModal();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const { t } = useTranslation(namespaces.common);

    const handleClose = () => modal.hide();

    const handleRemoveWatchlist = () => {
      removeAction();
      handleClose();
    };

    return (
      <BaseModal
        {...muiDialogV5(modal)}
        sx={{
          "& .MuiDialog-paper": {
            width: 563,
            maxWidth: "none",

            "@media (max-width: 600px)": {
              top: "50px",
              height: "auto",
              borderRadius: "8px",
              maxHeight: "none",
            },
          },
        }}
      >
        <Stack
          gap={1}
          p="24px 16px"
          bgcolor="#FFFFFF"
          position="relative"
          textAlign={isDesktop ? "left" : "center"}
        >
          <Box
            onClick={handleClose}
            sx={{
              top: 8,
              right: 10,
              cursor: "pointer",
              position: "absolute",
            }}
          >
            <CloseIcon />
          </Box>

          {children ? (
            <>{children}</>
          ) : (
            <>
              <Text variant="h5" fontWeight="semibold">
                {/* {t("remove_from_watchlist", { ns: namespaces.common })} */}
                Remove from Watchlist
              </Text>
              <Text variant="headline" color={palette.neutral[600]}>
                {/* {t("delete.are_you_sure_remove", {
                  ns: namespaces.common,
                })}{" "} */}
                Are you sure that you want to remove “{title}” from the
                watchlist?
              </Text>
            </>
          )}
        </Stack>
        <DialogActions>
          <Button
            size="medium"
            background="tertiary"
            fullWidth={!isDesktop}
            onClick={handleClose}
          >
            {t("delete.cancel", { ns: namespaces.common })}
          </Button>
          <Button
            background="primary"
            size="medium"
            fullWidth={!isDesktop}
            onClick={handleRemoveWatchlist}
          >
            {/* {t("remove", { ns: namespaces.common })} */}
            Remove
          </Button>
        </DialogActions>
      </BaseModal>
    );
  },
);

export default RemoveWatchlist;
