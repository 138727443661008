interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
  className?: string;
}

const InfoIcon = ({
  width = 24,
  height = 24,
  fill = "none",
  path_fill = "#734089",
  className = "",
}: IconProps) => {
  return (
    <svg
      className={className}
      style={{ display: "flex", alignItems: "center", justifyItems: "center" }}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_1452_14104)">
        <path
          d="M16.2071 7.45711C16.5976 7.06658 16.5976 6.43342 16.2071 6.04289C15.8166 5.65237 15.1834 5.65237 14.7929 6.04289L16.2071 7.45711ZM10.2071 12.0429L10.9142 12.75L10.9142 12.75L10.2071 12.0429ZM10.2071 13.4571L10.9142 12.75L10.9142 12.75L10.2071 13.4571ZM14.7929 19.4571C15.1834 19.8476 15.8166 19.8476 16.2071 19.4571C16.5976 19.0666 16.5976 18.4334 16.2071 18.0429L14.7929 19.4571ZM14.7929 6.04289L9.5 11.3358L10.9142 12.75L16.2071 7.45711L14.7929 6.04289ZM9.5 14.1642L14.7929 19.4571L16.2071 18.0429L10.9142 12.75L9.5 14.1642ZM9.5 11.3358C8.71895 12.1168 8.71895 13.3832 9.5 14.1642L10.9142 12.75V12.75L9.5 11.3358Z"
          fill={className ? "" : "#A9AFBD"}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1452_14104"
          x="0.5"
          y="0.75"
          width="24"
          height="25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1452_14104"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default InfoIcon;
