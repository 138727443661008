interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  background?: string;
}

const ButtonIcon = ({
  width = 20,
  height = 20,
  fill = "none",
  stroke = "#727179",
  background = "#F4F3F7",
}: IconProps) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="32" height="32" rx="4" fill={background} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20837 8.69208C4.55152 8.69208 3.20837 10.0352 3.20837 11.6921V20.308C3.20837 21.9648 4.55152 23.308 6.20837 23.308H27.293C28.9499 23.308 30.293 21.9648 30.293 20.308V11.6921C30.293 10.0352 28.9499 8.69208 27.293 8.69208H6.20837ZM10.3554 14.3373C9.43714 14.3373 8.69275 15.0817 8.69275 15.9999C8.69275 16.9182 9.43714 17.6626 10.3554 17.6626H23.146C24.0642 17.6626 24.8086 16.9182 24.8086 15.9999C24.8086 15.0817 24.0642 14.3373 23.146 14.3373H10.3554Z"
        fill={stroke}
      />
    </svg>
  );
};

export default ButtonIcon;
