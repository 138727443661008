import { useMemo } from "react";
import { MenuListItem } from "types/data.types";
import { useGetDisputesStats } from "./useGetDisputesStats";

const useDisputeCount = (menu: MenuListItem[]) => {
  const { data } = useGetDisputesStats();

  const { openCount } = data || {};

  const modifiedMenu = useMemo(() => {
    return menu.map((item) => {
      if (item.name === "Disputes" && openCount !== 0) {
        return { ...item, itemCount: openCount };
      }
      return item;
    });
  }, [menu, data]);

  return modifiedMenu;
};

export default useDisputeCount;
