import { SvgIconProps } from "@mui/material";

type IProps = SvgIconProps & {
  width?: number | string;
  height?: number | string;
  stroke?: string;
};

const CaretLeft = ({
  width = 32,
  height = 32,
  fill = "white",
  ...rest
}: IProps) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7076 25.2931C20.8005 25.386 20.8742 25.4963 20.9244 25.6177C20.9747 25.7391 21.0006 25.8692 21.0006 26.0006C21.0006 26.132 20.9747 26.2621 20.9244 26.3835C20.8742 26.5048 20.8005 26.6151 20.7076 26.7081C20.6146 26.801 20.5043 26.8747 20.383 26.9249C20.2616 26.9752 20.1314 27.0011 20.0001 27.0011C19.8687 27.0011 19.7386 26.9752 19.6172 26.9249C19.4958 26.8747 19.3855 26.801 19.2926 26.7081L9.29255 16.7081C9.19958 16.6152 9.12582 16.5049 9.07549 16.3835C9.02517 16.2621 8.99927 16.132 8.99927 16.0006C8.99927 15.8691 9.02517 15.739 9.07549 15.6176C9.12582 15.4962 9.19958 15.3859 9.29255 15.2931L19.2926 5.29306C19.4802 5.10542 19.7347 5 20.0001 5C20.2654 5 20.5199 5.10542 20.7076 5.29306C20.8952 5.4807 21.0006 5.73519 21.0006 6.00056C21.0006 6.26592 20.8952 6.52042 20.7076 6.70806L11.4138 16.0006L20.7076 25.2931Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

export default CaretLeft;
