import { KotoBackArrow } from "@assets/icons";
import { Box } from "@mui/material";
import { Button } from "@common/Button";
import React, { memo } from "react";

interface props {
  goNext: () => void;
  goBack: () => void;
  disabled?: boolean;
  isStep1?: boolean;
  nextText?: string;
  isLoading?: boolean;
}

function BottomButtons({
  goNext,
  goBack,
  disabled,
  isStep1,
  nextText,
  isLoading,
}: props) {
  return (
    <>
      <Box flexGrow={1} />
      <Box
        my={3}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          background="tertiary"
          size="medium"
          startIcon={<KotoBackArrow />}
          onClick={goBack}
        >
          {isStep1 ? "Homepage" : "Previous Step"}
        </Button>
        <Button
          disabled={disabled || isLoading}
          background="primary"
          size="medium"
          onClick={goNext}
        >
          {nextText ? nextText : "Next step"}
        </Button>
      </Box>
    </>
  );
}

export default memo(BottomButtons);
