import { Stack } from "@mui/material";
import { Button } from "@common/Button";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

interface Props {
  nextPrevState: {
    isFirst: boolean;
    isLast: boolean;
  };
  handleNext: () => void;
  handlePrev: () => void;
}

const NextPrevButtons = ({ nextPrevState, handleNext, handlePrev }: Props) => {
  const { isDesktopView } = useCustomTheme();
  return (
    <Stack alignItems="center" flexDirection="row" gap={1.5}>
      <Button
        size="medium"
        background="tertiary"
        fullWidth={!isDesktopView}
        sx={buttonStyle}
        disabled={nextPrevState.isFirst}
        onClick={handlePrev}
        data-testid="provider-previous-button"
      >
        Previous
      </Button>
      <Button
        size="medium"
        background="tertiary"
        fullWidth={!isDesktopView}
        sx={buttonStyle}
        disabled={nextPrevState.isLast}
        onClick={handleNext}
        data-testid="provider-next-button"
      >
        Next
      </Button>
    </Stack>
  );
};

const buttonStyle = {
  whiteSpace: "nowrap",
  display: "inline-flex",
  alignItems: "center",
  color: palette.black[100],
  lineHeight: "21px",
  padding: "4px 8px",
  fontWeight: "400",
};

export default NextPrevButtons;
