interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const ArrowDropdownIcon = ({
  width = 24,
  height = 24,
  fill = "#727179",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1794 15.8293L6.54067 9.2025C5.89115 8.27461 6.55496 6.99965 7.68759 6.99965L16.3098 6.99965C17.4424 6.99965 18.1062 8.27461 17.4567 9.2025L12.8179 15.8293C12.4198 16.398 11.5775 16.398 11.1794 15.8293Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowDropdownIcon;
