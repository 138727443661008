import useInternetStatus from "@hooks/common/useInternetStatus";
import { PropsWithChildren, useEffect } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { CONNECTION_ERROR_MODAL } from "modals/modal_names";

interface InternetStatusWrapperProps extends PropsWithChildren {
  noInternetComponent?: JSX.Element;
  showModal?: boolean;
}

function InternetStatusWrapper({
  children,
  noInternetComponent,
  showModal = true,
}: InternetStatusWrapperProps) {
  const { isOnline } = useInternetStatus();

  useEffect(() => {
    if (!isOnline && showModal) NiceModal.show(CONNECTION_ERROR_MODAL);
    else if (isOnline) NiceModal.hide(CONNECTION_ERROR_MODAL);
  }, [isOnline]);

  if (!isOnline && noInternetComponent && !showModal)
    return noInternetComponent;
  else return <>{children}</>;
}

export default InternetStatusWrapper;
