import { saveAs } from "file-saver";
import { showMessage } from "@common/Toast";
import { useMutation } from "react-query";
import { customInstance } from "@services/api";
import { Colum_types, CustomDateRange, TExportTable } from "../types";
import { useAppSelector } from "@redux/hooks";
import {
  sortingKey,
  sorting as sortingReducer,
} from "@redux/slices/fundraisers";
import { useParams } from "react-router-dom";
import { checkPortals } from "@utils/routing";
import { selectQueryString } from "@redux/slices/search";
import { QKEY_LIST_ACQUIRER_MERCHANTS } from "@constants/queryKeys";

export type TExportHandlerArgs = {
  data: TExportTable;
  payload: {
    includeAllColumns: boolean;
    columns?: string[];
    prefixName?: "GIVE_MONTHLY_MERCHANT_PROCESSING_" | "GIVE_MERCHANTS_";
    dateRange?: CustomDateRange;
  };
};

export type TExportHandler = (args: TExportHandlerArgs) => void;

const DEFAULT_PARAMS = {
  withFilters: true,
  filterQuery: "",
};

type ParamType = {
  withFilters?: boolean;
  filterQuery?: string;
  closeModal?(): void;
  column_type?: Colum_types;
  merchantId?: number;
  sortReduxKey?: string;
  searchKey?: string;
  formattedFilterParams?: string;
  includesSalesColumn?: boolean;
};

export const useDownloadExportedTable = ({
  withFilters,
  filterQuery,
  closeModal,
  column_type,
  merchantId,
  sortReduxKey,
  searchKey,
  formattedFilterParams,
  includesSalesColumn = false,
}: ParamType = DEFAULT_PARAMS) => {
  const sorting = sortReduxKey
    ? useAppSelector((state) => sortingKey(state, sortReduxKey))
    : useAppSelector(sortingReducer);

  const { id: enterpriseId } = useParams();
  const { isAcquirerEnterprises } = checkPortals();
  const searchQuery = useAppSelector((state) =>
    selectQueryString(state, searchKey || QKEY_LIST_ACQUIRER_MERCHANTS),
  );
  const { isAcquirerPortal } = checkPortals()

  const search = searchQuery && withFilters ? `&q="${searchQuery}"` : "";

  const exportMutation = useMutation((data: any) => {
    const parentAccFilter = isAcquirerEnterprises
      ? "parentAccID:" + enterpriseId
      : "";

    let filter;
    switch (column_type) {
      case "risk_monitor_transactions":
        filter = formattedFilterParams;
        break;
      case "settlement":
        filter = filterQuery ? `filter=${filterQuery}&` : "";
        break;
      case "merchant":
        filter = `${filterQuery}${
          filterQuery && parentAccFilter
            ? "%3B" + parentAccFilter
            : parentAccFilter
        }`;
        break;
    }

    let URL;
    const merchantBaseURL = includesSalesColumn && isAcquirerPortal
      ? `/submerchants-monthly-processing.csv`
      : `/submerchants.csv`;
    switch (column_type) {
      case "risk_monitor_transactions":
        URL = `/merchants/${merchantId}/transactions-minimal.csv?${
          filter ? `filter=${filter}&` : ""
        }sort=${sorting || "-date"}${search}`;
        break;
      case "merchant":
        URL = `${merchantBaseURL}?filter=${filter}&sort=${
          sorting || "-accID"
        }${search}`;
        break;
      //TODO: RPT API integration - this endpoint might not be correct
      case "settlement":
        URL = `/merchants/${
          formattedFilterParams || merchantId
        }/transaction-settlements.csv?${filter}sort=-settlementDate`;
        break;
    }
    return customInstance({
      url: URL,
      method: "POST",
      data,
    });
  });

  const { isLoading } = exportMutation;

  const handleExport: TExportHandler = ({ data, payload }) => {
    showMessage(
      "Success",
      "The file will soon be available for download",
      true,
      "Export started",
      2000,
    );
    closeModal && closeModal();
    exportMutation.mutate(payload, {
      onError: () => {
        showMessage("Error", "Please try again later", true, "Export failed");
      },
      onSuccess: async (res) => {
        saveAs(new Blob([res]), `${data?.fileName}.${data.fileType}`);
      },
    });
  };

  return { handleExport, isLoading };
};
