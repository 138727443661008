import * as React from "react";
import { palette } from "@palette";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { Input } from "@common/Input";
import { Button } from "@common/Button";
import { IconButton } from "@common/IconButton";
import { MoveTransactionTable } from "./MoveTransactionTable";
// assets
import { PaperIcon, ZoomSearchIcon, CheckIcon } from "@assets/icons";
// redux
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import { showMessage } from "@common/Toast/ShowToast";

type MoveTransactionProps = {
  closeAction?: () => void;
};

const MoveTransaction = NiceModal.create(
  ({ closeAction }: MoveTransactionProps) => {
    const theme = useTheme();
    const modal = useModal();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const [selectedRow, setSelectedRow] = React.useState<string | undefined>(
      undefined,
    );
    const [confirm, setConfirm] = React.useState<boolean>(false);

    const handleClose = () => {
      modal.hide();
      if (closeAction) closeAction();
    };

    const handleSend = () => {
      handleClose();
      showMessage("Success", "Transaction successfully moved");
    };

    return (
      <Modal
        {...muiDialogV5(modal)}
        title="Move Transaction"
        onClose={handleClose}
        titleIcon={<PaperIcon stroke="#A9AFBD" width={36} height={36} />}
        width="730px"
        actions={
          <>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            {confirm ? (
              <Button
                variant="primary"
                onClick={handleSend}
                endIcon={
                  <CheckIcon 
                    fill={palette.success.main}
                    height={18}
                    width={18}
                  />
                }
              >
                Confirm
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => setConfirm(true)}
                disabled={!selectedRow}
              >
                Move Transaction
              </Button>
            )}
          </>
        }
      >
        <Stack mt={1} gap={2} sx={{ width: "100%" }}>
          <Box>
            <Text
              variant="h5"
              fontWeight="bold"
              color={palette.neutral[800]}
              gutterBottom
            >
              Antonio Silvestre, antonio@gmail.com, VISA 1111
            </Text>
            <Text color={palette.neutral[600]}>
              Please Select the Customer to Move this Transaction to
            </Text>
          </Box>

          <Stack
            component="form"
            direction="row"
            alignItems="center"
            gap={1}
            sx={{ width: "100%" }}
          >
            <Box width={isDesktop ? "auto" : "100%"}>
              <Input
                placeholder="Search..."
                // value={value}
                fullWidth={!isDesktop}
                sx={searchInputStyles}
                // onChange={onChange}
              />
            </Box>
            <IconButton>
              <ZoomSearchIcon />
            </IconButton>
          </Stack>

          <MoveTransactionTable
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Stack>
      </Modal>
    );
  },
);

export default MoveTransaction;

const searchInputStyles = {
  "@media (min-width: 600px)": {
    "& > .MuiInputBase-root": {
      height: "44px !important",
      width: 312,
    },
  },
};
