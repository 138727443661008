import { UseQueryOptions, useQuery } from "react-query";
import { customInstance } from "./index";
import { buildMerchantEndpoints } from "./utils.api";
import { useAppDispatch } from "@redux/hooks";
import { updatePermissions } from "@redux/slices/app";

export const getBusinessOwners = (id: number) => {
  return customInstance({
    url: buildMerchantEndpoints(`legal-entities/${id}/principals?sort=id`),
    method: "GET",
  });
};

type Options = Omit<
  UseQueryOptions<any, any, any, any>,
  "queryKey" | "queryFn"
> & {
  parser?: (response: any) => any;
};

export const useGetBusinessOwners = (id: number, options?: Options) => {
  const dispatch = useAppDispatch();

  return useQuery(
    ["list-business-owners", id],
    async () => {
      const data = await getBusinessOwners(id);

      if (options?.parser) {
        const parsedData = options.parser(data);
        return parsedData;
      }

      return data;
    },
    {
      ...options,
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError(err: any) {
        if (err.not_authorized) {
          dispatch(
            updatePermissions({
              "list-business-owners": true,
            }),
          );
        }
      },
    },
  );
};

export const createBusinessOwner = (
  legalEntityID: number,
  data: any,
  merchantId?: number,
) =>
  customInstance({
    url: buildMerchantEndpoints(
      `legal-entities/${legalEntityID}/principals`,
      merchantId,
    ),
    method: "POST",
    data,
  });

export const patchBusinessOwner = (
  legalEntityID: number,
  ownerID: number | string,
  data: any,
  merchantId?: number,
) =>
  customInstance({
    url: buildMerchantEndpoints(
      `legal-entities/${legalEntityID}/principals/${ownerID}`,
      merchantId,
    ),
    method: "PATCH",
    data,
  });
