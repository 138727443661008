import { memo, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useCalculatePercentage } from "@common/SignUp/useCalculatePercentage";
import { useFormData } from "../CreateCampaignFormProvider";
import BottomButtons from "../components/BottomButtons";
import { cloneDeep, isEqual } from "lodash";
import EventDateLocation from "features/Minibuilders/EventsMinibuilder/EventDateLocation";
import { FormProvider } from "react-hook-form";
import { EventModalSchema } from "features/Minibuilders/EventsMinibuilder/utils";

type Props = {
  handleBack: () => void;
  handleNext: () => void;
  handleUpdateStatusValue: (value: number) => void;
  disabled: boolean;
};

const AddDateAndLocation = ({
  handleNext,
  handleBack,
  handleUpdateStatusValue,
  disabled,
}: Props) => {
  const { calculatePercentageNested } = useCalculatePercentage({
    isEdit: false,
  });
  const { formData, setFormValues } = useFormData();
  const valueRef = useRef<any>({
    date_and_location: formData.date_and_location,
  });

  const defaultValues = formData.date_and_location;

  const methods = useForm<any>({
    resolver: yupResolver(EventModalSchema),
    defaultValues: { date_and_location: defaultValues },
  });

  const {
    watch,
    formState: { dirtyFields },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isEqual(valueRef.current, values)) {
      valueRef.current = cloneDeep(values);
      (async () => {
        const count = await calculatePercentageNested(
          EventModalSchema,
          values,
          "date_and_location",
        );
        handleUpdateStatusValue(count);
      })();
    }
  }, [values, dirtyFields]);

  const saveValues = async (cb?: () => void) => {
    setFormValues("date_and_location", values.date_and_location);
    cb?.();
  };

  return (
    <FormProvider {...methods}>
      <EventDateLocation />
      <BottomButtons
        goBack={() => saveValues(handleBack)}
        goNext={() => saveValues(handleNext)}
        disabled={disabled}
      />
    </FormProvider>
  );
};

export default memo(AddDateAndLocation);
