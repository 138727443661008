import React from "react";

type ArrowDownChevronProps = {
  height?: number;
  width?: number;
  color?: string;
  className?: string;
};

const ArrowDownChevronV2 = ({
  color = "#A9AFBD",
  width = 14,
  height = 9,
  className,
}: ArrowDownChevronProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6923 1.9422L7.44229 8.1922C7.38425 8.25031 7.31531 8.29641 7.23944 8.32786C7.16357 8.35931 7.08224 8.3755 7.0001 8.3755C6.91797 8.3755 6.83664 8.35931 6.76077 8.32786C6.68489 8.29641 6.61596 8.25031 6.55792 8.1922L0.307916 1.9422C0.19064 1.82492 0.124756 1.66586 0.124756 1.50001C0.124756 1.33416 0.19064 1.1751 0.307916 1.05782C0.425191 0.940549 0.584251 0.874664 0.750103 0.874664C0.915956 0.874664 1.07502 0.940549 1.19229 1.05782L7.0001 6.86642L12.8079 1.05782C12.866 0.999755 12.9349 0.953692 13.0108 0.922266C13.0867 0.890839 13.168 0.874664 13.2501 0.874664C13.3322 0.874664 13.4135 0.890839 13.4894 0.922266C13.5653 0.953692 13.6342 0.999755 13.6923 1.05782C13.7504 1.11589 13.7964 1.18483 13.8278 1.2607C13.8593 1.33657 13.8755 1.41789 13.8755 1.50001C13.8755 1.58213 13.8593 1.66345 13.8278 1.73932C13.7964 1.81519 13.7504 1.88413 13.6923 1.9422Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowDownChevronV2;
