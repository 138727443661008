import { TableColumnType } from "@common/Table";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setDynamicSortByKey, updateSorting } from "@redux/slices/fundraisers";
import { selectDateFilter } from "@redux/slices/merchantFilters";
import {
  clearFilters,
  resetStatusFilter,
  selectQueryFilters,
  selectTableFilter,
} from "@redux/slices/transactions";
import { encodedQueryFilterMap } from "@services/filtering";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

const useFilters = () => {
  const filters = useAppSelector(selectTableFilter);
  const formattedFilter = filters.length > 0 ? filters.map((item) => encodeURIComponent(`${item.type}:"${item.value}"`)).join(",") : "";

  return formattedFilter ? `(${formattedFilter})` : "";
};

export const useSettlementFiltersRepository = () => {
  const dispatch = useDispatch();
  const settlementDate = useAppSelector((state) =>
    selectDateFilter(state, 'settlement-date'),
  );
  const queryString = "";
  let filterString = "";

  if (settlementDate) {
    filterString = `?filter=settlementDate:"${settlementDate}"`;
  }

  useEffect(() => {
    return () => {
      dispatch(resetStatusFilter());
      dispatch(clearFilters());
    };
  }, []);

  return { filterString, queryString };
};

const useQuerySearchFilter = () => {
  const queryFilters = useAppSelector(selectQueryFilters);

  const queryFilter = useMemo(
    () => encodedQueryFilterMap(queryFilters),
    [queryFilters],
  );

  const queryString = queryFilter.transactions || "";

  return queryString;
};

export const useFiltersRepository = () => {
  const dispatch = useDispatch();

  const queryString = useQuerySearchFilter();
  const formattedFilter = useFilters();

  useEffect(() => {
    return () => {
      dispatch(resetStatusFilter());
      dispatch(clearFilters());
    };
  }, []);

  return { queryString, formattedFilter };
};

export const useSorting = (
  setSort: any,
  defaultSortKey = "createdAt",
  sortReduxKey = "",
) => {
  const [sortKey, setSortKey] = useState<any>(null);
  const [order, setOrder] = useState<any>("desc");

  const getNewOrder = (columnKey: any) => {
    let newOrder: any = "asc";

    if (sortKey === null) {
      newOrder = "asc";
    } else {
      if (sortKey !== columnKey) {
        newOrder = "asc";
      } else {
        newOrder = order === "asc" ? "desc" : "asc";
      }
    }

    return newOrder;
  };

  useEffect(() => {
    setSortKey(defaultSortKey);
    setOrder("desc");
  }, []);

  const dispatch = useAppDispatch();

  const onSort =
    (column: TableColumnType) => (_: React.MouseEvent<HTMLElement>) => {
      setSortKey(column.key);
      const newOrder = getNewOrder(column.key);
      const ascOrder = column.key?.replace("-", "")
      const newSorting = newOrder === "asc" ? ascOrder : `-${column.key}`;

      setOrder(newOrder);

      if (sortReduxKey) {
        dispatch(
          setDynamicSortByKey({ key: sortReduxKey, params: newSorting }),
        );
      } else {
        dispatch(updateSorting(newSorting));
      }
      setSort(newSorting);
    };

  return { sortKey, order, onSort };
};
