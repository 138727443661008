import React from "react";
import { CancelIcon, PaperLightIcon } from "@assets/icons";
import { NewRefundIcon } from "@assets/icons/RebrandedIcons/NewRefundIcon";
import { IconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import { HiddenComponent } from "@containers/HiddenComponent";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import {
  MERCHANT_PORTAL_CANCEL_MODAL,
  MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  MERCHANT_VIEW_CUSTOMER,
} from "modals/modal_names";
import { ViewCustomerIcon } from "@assets/icons/RebrandedIcons";
import { ButtonProps } from "@mui/material";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import WarningDiamondIcon from "@assets/icons/RebrandedIcons/WarningDiamondIcon";

type ActionsComponentProps = {
  actions: {
    [key: string]: Omit<IActionButtonProps, "icon">;
  };
};

export const ActionsComponent = ({ actions }: ActionsComponentProps) => {
  const buttons = [
    {
      ...actions[MERCHANT_PORTAL_REFUND_MODAL],
      icon: <NewRefundIcon />,
    },
    {
      ...actions[MERCHANT_PORTAL_SEND_RECEIPT_MODAL],
      icon: <PaperLightIcon width="20px" height="20px" />,
    },
    {
      ...actions[MERCHANT_PORTAL_CANCEL_MODAL],
      icon: <CancelIcon />,
    },
    {
      ...actions[MERCHANT_VIEW_CUSTOMER],
      icon: <ViewCustomerIcon width={20} height={20} />,
    },
    {
      ...actions[MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL],
      icon: <WarningDiamondIcon width={20} height={20} />,
    },
  ];

  return (
    <Stack direction="row" padding="8px 0px" gap={2}>
      {buttons.map((buttonProps, i) => (
        <ActionButton key={buttonProps.label || i} {...buttonProps} />
      ))}
    </Stack>
  );
};

interface IActionButtonProps extends ButtonProps {
  hidden: boolean;
  icon: JSX.Element;
  label: string;
  tooltipProps?: { show: boolean; message: string };
}

const ActionButton = ({
  hidden,
  label,
  icon,
  tooltipProps,
  ...rest
}: IActionButtonProps) => {
  const classes = useStyles();
  return (
    <HiddenComponent hidden={hidden}>
      <WithTooltipWrapper
        hasTooltip={!!tooltipProps}
        tooltipProps={tooltipProps}
      >
        <IconButton
          className={classes.iconBtn}
          sx={{
            paddingLeft: 0,
          }}
          {...rest}
          data-testid={`action-${label}-button`}
        >
          <Text
            sx={{ paddingRight: "4px" }}
            color={palette.gray[300]}
            variant="body"
            lineHeight="16.8px"
            fontWeight="book"
          >
            {label}
          </Text>
          {React.cloneElement(icon, {
            stroke: palette.gray[300],
            path_fill: palette.gray[300],
            fill: palette.gray[300],
          })}
        </IconButton>
      </WithTooltipWrapper>
    </HiddenComponent>
  );
};

const useStyles = makeStyles({
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    padding: "2px !important",
    borderRadius: "32px",
    maxHeight: "24px",
    textDecoration: "none",

    "&:disabled": {
      boxShadow: "none",
    },
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",

      "& > span": {
        "& > *": {
          color: palette.black[100],
        },
        "& > svg > path": {
          stroke: palette.black[100],
          pathFill: palette.black[100],
        },
      },
    },
  },
});
