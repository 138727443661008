import { customInstance } from "@services/api";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { parseTransactionData } from "../hooks";
import { parseRow, parseTransfer } from "../transactions.helpers";

const getTransactionData = (id: string) =>
  customInstance({
    url: buildMerchantEndpoints(`transactions/${id}`),
    method: "GET",
  });

const getTransferData = (id: string) =>
  customInstance({
    url: buildMerchantEndpoints(`transfers/${id}`),
    method: "GET",
  });

export const processTransactionData = (data: any) => {
  const formattedData = parseTransactionData({ data: [data] });
  return parseRow(formattedData.data[0]);
};

export const getTransaction = async (thxId: string, isTransfer?: boolean) => {
  if(isTransfer) {
    const data = await getTransferData(thxId);
    return parseTransfer(data)
  } else {
    const data = await getTransactionData(thxId);
    return processTransactionData(data);
  }
};

export const getStatus = (displayStatus: string, processingState: string) => {
  let status = displayStatus;
  if (displayStatus === "Money Transfer") {
    if (processingState === "approved") status = "Successful";

    if (processingState === "failed") status = "Failed";
  }

  return status.toLowerCase();
};
