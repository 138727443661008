import { namespaces } from "../../../../resources/i18n.constants";

export const processing = {
  [namespaces.pages.enterprise.processing]: {
    t_processing: "Processing",
    t_processed: "Processed",
    t_available_balance: "Available Balance",
    t_approved_transactions: "Approved Transactions",
    t_refunded_transactions: "Refunded Transactions",
    t_chargeback_transactions: "Chargeback Transactions",
    t_transfer_money: "Transfer Money",
    t_bank_accounts: "Bank Accounts",
  },
};
