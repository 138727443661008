import { styled } from "@mui/material";
import { Text, ITextProps } from "./Text";

export type TruncateTextProps = {
  lineClamp: number;
  link?: string;
  onHoverShowAll?: boolean;
} & ITextProps;

const TruncateText = styled(Text, {
  shouldForwardProp: (prop) =>
    prop !== "lineClamp" && prop !== "link" && prop !== "onHoverShowAll",
})(({ lineClamp, link, onHoverShowAll }: TruncateTextProps) => ({
  display: "-webkit-box",
  lineClamp,
  WebkitLineClamp: lineClamp,
  WebkitBoxOrient: "vertical",
  overflowWrap: "break-word",
  textOverflow: "ellipsis",
  overflow: "hidden",
  wordBreak: "break-word",
  ...(link && {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  }),
  ...(onHoverShowAll && {
    "&:hover": {
      display: "block",
      overflow: "visible",
      whiteSpace: "normal",
    },
  }),
}));

export default TruncateText;
