import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { Text } from "@common/Text";
import { MainLogo } from "@hooks/common/useEnterpriseLogo";
import Logo from "@assets/icons/RebrandedIcons/Logo";
import InfoFillIcon from "@assets/icons/InfoFillIcon";
import { Button } from "@components/common/Button";

const DifferentAccount = ({
  onSwitchAccount,
  onContinueAnyway,
}: {
  onSwitchAccount(): void;
  onContinueAnyway(): void;
}) => {
  return (
    <StyledRoot>
      <MainLogo position="center" defaultLogo={<Logo />} />
      <Content>
        <Info>
          <InfoFillIcon />
          <Text fontSize={16} color="#575353">
            The transactions you’re looking for are not available on this
            account.
          </Text>
        </Info>
        <Stack width="180px" gap={2}>
          <Button
            onClick={onContinueAnyway}
            size="medium"
            background="tertiary"
          >
            Continue anyway
          </Button>
          <Button onClick={onSwitchAccount}>Switch account</Button>
        </Stack>
      </Content>
    </StyledRoot>
  );
};

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "41px 32px 24px 32px",
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px",
  },
}));

const Info = styled(Box)(() => ({
  gap: 16,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
}));

const Content = styled(Box)({
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 180,
});

export default DifferentAccount;
