import React from "react";
import { getDefaults } from "./utils";
type Props = {
  size?: string | number;
  width?: string | number;
  height?: string | number;
  stroke?: string;
  fill?: string;
};

const OrangeRefundIcon = (props: Props) => {
  let { size, width, height, stroke, fill } = props;
  const defaults = getDefaults();

  if (!size && !width && !height) {
    size = defaults.size;
  }

  if (size) {
    height = size;
    width = size;
  }

  if (!fill) fill = defaults.fill;
  if (!stroke) stroke = defaults.stroke;
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.63666 10.5303C8.34377 10.2374 8.34377 9.76256 8.63666 9.46967C8.92956 9.17678 9.40443 9.17678 9.69732 9.46967L8.63666 10.5303ZM11.3134 12.1464L11.8438 11.6161H11.8438L11.3134 12.1464ZM11.3134 12.8536L11.8438 13.3839L11.8438 13.3839L11.3134 12.8536ZM9.69732 15.5303C9.40443 15.8232 8.92956 15.8232 8.63666 15.5303C8.34377 15.2374 8.34377 14.7626 8.63666 14.4697L9.69732 15.5303ZM9.69732 9.46967L11.8438 11.6161L10.7831 12.6768L8.63666 10.5303L9.69732 9.46967ZM11.8438 13.3839L9.69732 15.5303L8.63666 14.4697L10.7831 12.3232L11.8438 13.3839ZM11.8438 11.6161C12.3319 12.1043 12.3319 12.8957 11.8438 13.3839L10.7831 12.3232C10.6855 12.4209 10.6855 12.5791 10.7831 12.6768L11.8438 11.6161Z"
        stroke={stroke}
      ></path>
      <path
        d="M16.4952 10.625C17.3423 9.89138 17.6677 9.03595 17.4183 8.19806C17.169 7.36017 16.3595 6.58918 15.1219 6.01066C13.8843 5.43215 12.2914 5.08019 10.6026 5.01213C8.91388 4.94406 7.22879 5.16389 5.82181 5.63581C4.41483 6.10773 3.36887 6.80394 2.8543 7.61104C2.33972 8.41814 2.38684 9.28857 2.98799 10.0806C3.58914 10.8726 4.70889 11.5395 6.16487 11.9726C7.62084 12.4058 9.32726 12.5797 11.0062 12.4661"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
    </svg>
  );
};
export default OrangeRefundIcon;
