interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  path_fill?: string;
  onClick?: () => void;
}

const Paper = ({
  width = 24,
  height = 24,
  fill = "none",
  path_fill = "#734089",
  onClick,
}: IconProps) => {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9C6 7.11438 6 6.17157 6.58579 5.58579C7.17157 5 8.11438 5 10 5H26C27.8856 5 28.8284 5 29.4142 5.58579C30 6.17157 30 7.11438 30 9V26C30 28.8284 30 30.2426 29.1213 31.1213C28.2426 32 26.8284 32 24 32H12C9.17157 32 7.75736 32 6.87868 31.1213C6 30.2426 6 28.8284 6 26V9Z"
        stroke="#727179"
        strokeWidth="2"
      />
      <path
        d="M22.5 27.5L22.5 32M13.5 27.5L13.5 32"
        stroke="#727179"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.5 12.5L22.5 12.5"
        stroke="#727179"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.5 18.5L22.5 18.5"
        stroke="#727179"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Paper;
