interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const DownTrendIconV2 = ({
  width = 24,
  height = 24,
  fill = "#575353",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5004 12.7499V18.7499C22.5004 18.9488 22.4214 19.1396 22.2807 19.2803C22.1401 19.4209 21.9493 19.4999 21.7504 19.4999H15.7504C15.602 19.5 15.4569 19.4561 15.3334 19.3737C15.21 19.2913 15.1138 19.1741 15.057 19.037C15.0001 18.8999 14.9853 18.749 15.0143 18.6034C15.0433 18.4579 15.1148 18.3242 15.2198 18.2193L17.6901 15.7499L12.7504 10.8102L9.53104 14.0306C9.46139 14.1003 9.37867 14.1556 9.28762 14.1933C9.19657 14.2311 9.09898 14.2505 9.00042 14.2505C8.90186 14.2505 8.80426 14.2311 8.71321 14.1933C8.62216 14.1556 8.53945 14.1003 8.46979 14.0306L1.71979 7.28055C1.57906 7.13982 1.5 6.94895 1.5 6.74993C1.5 6.55091 1.57906 6.36003 1.71979 6.2193C1.86052 6.07857 2.05139 5.99951 2.25042 5.99951C2.44944 5.99951 2.64031 6.07857 2.78104 6.2193L9.00042 12.4396L12.2198 9.2193C12.2894 9.14957 12.3722 9.09425 12.4632 9.05651C12.5543 9.01877 12.6519 8.99934 12.7504 8.99934C12.849 8.99934 12.9466 9.01877 13.0376 9.05651C13.1287 9.09425 13.2114 9.14957 13.281 9.2193L18.7504 14.6896L21.2198 12.2193C21.3247 12.1143 21.4584 12.0428 21.6039 12.0138C21.7495 11.9848 21.9004 11.9996 22.0375 12.0565C22.1746 12.1133 22.2918 12.2095 22.3742 12.3329C22.4566 12.4564 22.5005 12.6015 22.5004 12.7499Z"
        fill={fill}
      />
    </svg>
  );
};

export default DownTrendIconV2;
