type TImageSize = "small" | "medium" | "large" | "thumb" | "original";

export function addSizeToImage(url: string, size: TImageSize) {
  if (!url) return "";
  const urlIncludesSize = [
    "/small",
    "/large",
    "/medium",
    "/original",
    "/thumb",
  ].some((substring) => url.includes(substring));

  if (urlIncludesSize) return url;

  return url + "/" + size;
}
