import { Button } from "@common/Button";
import { Text } from "@common/Text";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";
import { useEffect, useState } from "react";
import useDrawOnCanvas from "./hooks/useDrawOnCanvas";

type Props = {
  height: number;
  width: number;
  setDataURL: React.Dispatch<React.SetStateAction<string>>;
};

const SignatureCanvas = ({ height, width, setDataURL }: Props) => {
  const [isCleared, setIsCleared] = useState(false);

  const onSave = (canvas: HTMLCanvasElement) => {
    const dataURL = canvas.toDataURL("image/png");
    setDataURL(dataURL || "");
    if (isCleared) setIsCleared(false);
  };

  const { canvasRef, onDrawStart, onDraw, onDrawEnd, isDrawing, clear } =
    useDrawOnCanvas({ height, width, onSave });

  const clearCanvas = () => {
    setIsCleared(true);
    clear();
    setDataURL("");
  };

  useEffect(() => {
    const t = setTimeout(() => {
      setIsCleared(false);
    }, 50);

    return () => clearTimeout(t);
  });

  const isEmpty = isCanvasBlank(canvasRef.current);

  return (
    <Container
      sx={{
        height: height + 4, // 4 is for border left + border right width
        width: width + 4, // 4 is for border left + border right width
        border: `2px solid ${isDrawing ? palette.accent[3] : palette.neutral[10]
          }`,
      }}
    >
      <canvas
        onMouseDown={onDrawStart}
        onMouseUp={onDrawEnd}
        onMouseMove={onDraw}
        onTouchStart={onDrawStart}
        onTouchEnd={onDrawEnd}
        onTouchMove={onDraw}
        ref={canvasRef}
        data-testid="signature-canvas"
      />
      {!isDrawing && isEmpty && <BeginText>Click to begin</BeginText>}
      {!isDrawing && !isEmpty && !isCleared && (
        <ClearButton background="tertiary" onClick={clearCanvas}>
          Clear
        </ClearButton>
      )}
    </Container>
  );
};

const isCanvasBlank = (canvas?: HTMLCanvasElement | null) => {
  if (!canvas) return true;

  const blank = document.createElement("canvas");
  blank.width = canvas.width;
  blank.height = canvas.height;
  return canvas.toDataURL() === blank.toDataURL();
};

const Container = styled(Box)(() => ({
  position: "relative",
  borderRadius: "12px",
}));

const ClearButton = styled(Button)(() => ({
  position: "absolute",
  right: "16px",
  bottom: "16px",
  height: "25px",
  fontWeight: "400",
  color: palette.neutral[80],
  padding: "0 !important",
}));

const BeginText = styled(Text)(() => ({
  fontSize: 18,
  fontWeight: 350,
  color: palette.neutral[70],
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  pointerEvents: "none",
}));

export default SignatureCanvas;
