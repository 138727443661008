const CheckboxCheckedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6855 6.27194C21.0876 6.65054 21.1067 7.28342 20.7281 7.68552L12.5718 16.348C12.1932 16.7501 11.5603 16.7692 11.1582 16.3906C10.7561 16.012 10.7371 15.3791 11.1157 14.977L19.2719 6.31449C19.6505 5.91239 20.2834 5.89334 20.6855 6.27194ZM4.32922 12.125C4.73882 11.7546 5.37119 11.7863 5.74166 12.1959L10.3845 17.3292C10.755 17.7388 10.7233 18.3712 10.3137 18.7417C9.90405 19.1121 9.27168 19.0804 8.90121 18.6708L4.25836 13.5375C3.88789 13.1279 3.91962 12.4955 4.32922 12.125Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default CheckboxCheckedIcon;
