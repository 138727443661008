import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ILayoutProps } from "react-dropzone-uploader";

const PreviewWrapper = ({ children, ...props }: any) => {
  return props.howManyFiles > 5 || !props.isDesktop ? (
    <>
      <Box
        sx={{
          display: "block",
          overflowY: "hidden",
          overflowX: props.howManyFiles > 5 ? "scroll" : "hidden",
          overscrollBehavior: "contain",
          textAlign: "center",
          height: "max-content",
        }}
      >
        {children}
      </Box>
    </>
  ) : (
    <>
      <Stack
        mt={2}
        width="100%"
        alignItems="center"
        justifyContent="center"
        direction={props.isDesktop ? "row" : "column"}
      >
        {children}
      </Stack>
    </>
  );
};

const Layout = ({ input, previews, dropzoneProps, files }: ILayoutProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box sx={{ maxWidth: "100%" }} {...dropzoneProps}>
      {input}
      {files.length > 0 && (
        <PreviewWrapper howManyFiles={files.length} isDesktop={isDesktop}>
          {previews}
        </PreviewWrapper>
      )}
    </Box>
  );
};

export default Layout;
