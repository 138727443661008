import { Text } from "@common/Text";
import { findBankByRoutingNumber } from "@utils/bank_list";

type Props = {
  routingNumber: string;
};

export const BankProviderText = ({ routingNumber }: Props) => {
  return (
    <Text color="#8F8F8F" ml={1} mt={0.5}>
      {routingNumber.length === 9 && findBankByRoutingNumber(routingNumber)}
    </Text>
  );
};
