import { IdentificationCard } from "@assets/icons/RebrandedIcons";
import SelfieIcon from "@assets/icons/RebrandedIcons/SelfieIcon";

export const PAHMapper = {
  businessOwnerID: {
    icon: (fill?: string) => <IdentificationCard fill={fill} />,
    title: "Business Owner ID",
    attachmentType: "business_owner",
    deleteMessage: "Business Owner ID",
  },
  primaryAccountHolderID: {
    icon: (fill?: string) => <IdentificationCard fill={fill} />,
    title: "ID Primary Account Holder",
    attachmentType: "account_owner",
    deleteMessage: "Primary Account Holder ID",
  },
  primaryAccountHolderSelfie: {
    icon: (fill?: string) => <SelfieIcon fill={fill} />,
    title: "Selfie Primary Account Holder",
    attachmentType: "account_owner_selfie",
    deleteMessage: "Primary Account Holder Selfie",
  },
};
