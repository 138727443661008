export const RiskTriggerTitles: { [key: string]: string } = {
  "high month relative vol": "Monthly Volume Rapid Growth",
  "high month txn count": "Monthly Transactions Count Surge",
  "high month txn avg": "Monthly Average Transaction Surge",
  "high chargeback ratio1": "Chargeback Ratio Bigger or Equal than 0.1%",
  "high chargeback ratio2": "Chargeback Ratio Bigger or Equal than 1.0%",
  "high chargeback ratio3": "Chargeback Ratio Bigger or Equal than 5.0%",
  "high refund ratio1": "Refund Ratio 1.0% Threshold",
  "high refund ratio2": "Refund Ratio 5.0% Threshold",
  "high refund ratio3": "Refund Ratio 10.0% Threshold",
  "high prepaid ratio1": "Prepaid Ratio 1.0% Threshold",
  "high prepaid ratio2": "Prepaid Ratio 5.0% Threshold",
  "high prepaid ratio3": "Prepaid Ratio 10.0% Threshold",
  "high month vol1": "10,000 Processing Volume Threshold",
  "high month vol2": "50,000 Processing Volume Threshold",
  "high month vol3": "100,000 Processing Volume Threshold",
  "first chargeback": "More than 1 Chargeback",
  "inactive vol": "Revival of Dormant Account",
  "inactive txn": "Increased Transaction Count after Inactivity",
  "high decline count": "High Declines Volume: More than 10",
  "high decline ratio1": "Decline Rate Spike: More than 10%",
  "high decline ratio2": "Decline Rate Spike: More than 50%",
  "low txn amount count1":
    "Micro-Transactions of 5.00 or Less - More than 10 per Day",
  "low txn amount count2":
    "Micro-Transactions of 1.00 or Less - More than 10 per Day",
  "low txn amount ratio1":
    "Micro-Transactions of 5.00 or Less - More than 10% per Day",
  "low txn amount ratio2":
    "Micro-Transactions of 5.00 or Less - More than 50% per Day",
  "blocked txn ratio1": "Blocked Daily Transactions - 10%",
  "blocked txn ratio2": "Blocked Daily Transactions -  50%",
  "high txn1": "High-Value Transaction Alert: More than 1K",
  "high txn2": "High-Value Transaction Alert: More than 5K",
  "high txn3": "High-Value Transaction Alert: More than 10K",
  "default form txn1": "Single Payment Form and Incomplete Transaction",
  "default form txn2":
    "Single Payment Form and Incomplete Transaction without Image",
  "flagged words": "Flagged Content Detection",
  "explicit images": "Explicit Content Alert",
};
