interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const TransactionButton = ({
  width = 20,
  height = 20,
  fill = "#A9AFBD",
}: IconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.07178 16.8747C9.07178 12.228 9.07178 9.90467 10.5153 8.46112C11.9589 7.01758 14.2822 7.01758 18.9289 7.01758H32.0718C36.7185 7.01758 39.0418 7.01758 40.4854 8.46112C41.9289 9.90467 41.9289 12.228 41.9289 16.8747V29.1961C41.9289 36.1662 41.9289 39.6512 39.7636 41.8165C37.5983 43.9819 34.1133 43.9819 27.1432 43.9819H23.8575C16.8874 43.9819 13.4024 43.9819 11.2371 41.8165C9.07178 39.6512 9.07178 36.1662 9.07178 29.1962V16.8747Z"
        stroke="#A9AFBD"
        strokeWidth="2.95714"
      />
      <path
        d="M31.6606 37.8223L31.6606 43.983M19.3392 37.8223L19.3392 43.983"
        stroke="#A9AFBD"
        strokeWidth="2.95714"
        strokeLinecap="round"
      />
      <path
        d="M19.3394 17.2832L31.6608 17.2832"
        stroke="#A9AFBD"
        strokeWidth="3.69643"
        strokeLinecap="round"
      />
      <path
        d="M19.3394 25.5L31.6608 25.5"
        stroke="#A9AFBD"
        strokeWidth="3.69643"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TransactionButton;
