import { palette } from "@palette";
// mui
import Box from "@mui/material/Box";
// components
import { Text } from "@common/Text";
import { Radio } from "@common/Radio";

type MoveTransactionCardProps = {
  id: string;
  Name: string;
  date: string;
  email: string;
  selectedRow?: string;
  setSelectedRow: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const StyledCard = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "82px",
  background: "#FFFFFF",
  boxShadow:
    "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  borderRadius: "8px",
  padding: "8px 8px",
  alignItems: "center",
};

const StyledRadio = {
  "& .MuiSvgIcon-root": {
    width: 20,
    height: 20,
  },

  "&.Mui-checked": {
    color: palette.primary.main,
    "& .MuiTableRow-root": {
      backgroundColor: palette.primary[50],
    },
  },
};

function MoveTransactionCard({
  Name,
  date,
  email,
  id,
  selectedRow,
  setSelectedRow,
}: MoveTransactionCardProps) {
  return (
    <Box sx={StyledCard}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Radio
          checked={id === selectedRow}
          onChange={() => setSelectedRow(id)}
          sx={StyledRadio}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            variant="headline"
            fontWeight="medium"
            color={palette.neutral[800]}
          >
            {Name}
          </Text>
          <Text variant="body" color={palette.neutral[600]}>
            {email}
          </Text>
        </Box>
      </Box>

      <Text variant="body" color={palette.neutral[600]}>
        {date}
      </Text>
    </Box>
  );
}

export default MoveTransactionCard;
