import React from "react";
import { Button } from "@common/Button";
import { Stack, styled } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FilterValuesType } from "../hooks/useChangelogFilters";
import { SelectorOption } from "@common/TableFilters/utils";

interface IApplyButtonProps {
  onClose: () => void;
  filterType: "user" | "category";
  values: SelectorOption[];
}

const ApplyButton = ({ filterType, onClose, values }: IApplyButtonProps) => {
  const { setValue } = useFormContext<FilterValuesType>();

  const handleApply = () => {
    setValue(filterType, values);
    onClose();
  };

  return (
    <Stack justifyContent="flex-end" direction="row" pt="16px" pr="12px">
      <StyledButton disabled={!values.length} onClick={handleApply}>
        Apply
      </StyledButton>
    </Stack>
  );
};

const StyledButton = styled(Button)({
  width: "85px",
  paddingTop: "8px",
  paddingBottom: "8px",
  height: "32px",
});

export default ApplyButton;
