import { Text } from "@common/Text";
import { Stack, Box } from "@mui/material";
import { palette } from "@palette";
import {
  ErrorIcon,
  InfoIcon,
  CheckRoundIcon,
  WarningIcon,
} from "@assets/icons";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "@redux/hooks";
import { selectModals } from "@redux/slices/app";

export type TToastOptions = {
  titleClamp?: number;
  action?: any;
};

interface IToastMessageProps {
  message: string;
  type: string;
  description: string | React.ReactNode;
  isDesktop?: boolean;
  icon?: React.ReactNode;
  hasColorIndicator?: boolean;
  toastId?: string;
  options?: TToastOptions;
}

export const ToastMessage = ({
  type,
  message,
  description,
  isDesktop = true,
  icon,
  hasColorIndicator = true,
  toastId,
  options,
}: IToastMessageProps) => {
  const isModalOpen = useRef<boolean>(false);
  const modals = useAppSelector(selectModals) as Record<string, any>;

  const checkIfModalIsOpen = () =>
    Object.values(modals).some((modal) => modal.visible);

  useEffect(() => {
    isModalOpen.current = checkIfModalIsOpen();
  }, []);

  useEffect(() => {
    if (type === "Error" && isModalOpen.current) {
      const isOpen = checkIfModalIsOpen();
      isModalOpen.current = isOpen;
      if (!isOpen) toast.dismiss(toastId);
    }
  }, [modals]);
  const titleClamp = options?.titleClamp || 2;
  return (
    <Stack direction="row" gap={1} alignItems="stretch" height="100%">
      {hasColorIndicator && (
        <Box
          sx={{
            borderRadius: "4px",
            width: "8px",
            minWidth: "8px",
            backgroundColor: getIndicatorColor(type),
          }}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          marginInline: "4px 8px",
        }}
      >
        {icon ? icon : getToastIcon(type)}
      </Box>
      <Stack
        direction="column"
        gap="2px"
        justifyContent="center"
        sx={{
          paddingBlock: "5px",
        }}
      >
        <Text
          lineHeight="19px"
          fontSize="16px"
          fontFamily="Give Whyte"
          fontStyle="normal"
          color={palette.neutral.white}
          letterSpacing="-0.01em"
          fontWeight="regular"
          sx={{
            maxHeight: `${Math.max(titleClamp * 19, 57)}px`,
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: titleClamp,
            WebkitBoxOrient: "vertical",
          }}
        >
          {isDesktop ? message : description}
        </Text>
        {isDesktop && (
          <Text
            color={palette.gray.main}
            fontFamily="Give Whyte"
            fontStyle="normal"
            lineHeight="17px"
            fontSize="14px"
            fontWeight="light"
            sx={{
              maxHeight: 51,
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {description}
          </Text>
        )}
      </Stack>
      {options?.action ? options.action : null}
    </Stack>
  );
};

const getIndicatorColor = (type: string): string => {
  switch (type) {
    case "Success":
      return "#088745";
    case "Error":
      return "#D80D0D";
    case "Warning":
      return "#FF8124";
    default:
      return "#6D9CF8";
  }
};

const getToastIcon = (type: string): JSX.Element => {
  switch (type) {
    case "Success":
      return <CheckRoundIcon fill={palette.neutral.white} />;
    case "Error":
      return <ErrorIcon fill={palette.neutral.white} />;
    case "Warning":
      return <WarningIcon fill={palette.neutral.white} />;
    default:
      return <InfoIcon path_fill={palette.neutral.white} />;
  }
};

export default ToastMessage;
