import { Theme, useMediaQuery } from "@mui/material";
import { isMobile } from "@utils/index";

export function useCustomTheme() {
  const isExtraLargeView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("new_xl"),
  );
  const isLargeView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("new_lg"),
  );
  const isMediumView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("new_md"),
  );
  const isMediumSmallView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("new_sm_md"),
  );
  const isMobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("new_sm"),
  );
  const _isMobile = isMobileView || isMobile;

  return {
    isMobileView: _isMobile,
    isDesktopView: !_isMobile,
    isExtraLargeView,
    isMediumView,
    isMediumSmallView,
    isLargeView,
  };
}
