import { useMerchantDataPreview } from "@components/Merchants/MerchantPreview/hooks/useMerchantDataPreview";
import {
  MerchantPreviewHeaderAction,
  TMerchantDoublePanel,
} from "@components/Merchants/MerchantPreview/hooks/useMerchantPreview";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Role } from "@hooks/common/useUser";
import { useMasquerade, useWatchlist } from "@hooks/enterprise-api/merchants";
import { palette } from "@palette";
import {
  DELETE_CONFIRMATION_MODAL,
  ENTERPRISE_CONFIGURATION_MODAL,
} from "modals/modal_names";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  useAccessControl,
  composePermission,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { shouldBeHidden } from "@constants/constants";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { useState } from "react";
import { TPEPOwner } from "@components/Merchants/MerchantPreview/PEP/types";
import { useNavigate } from "react-router-dom";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { setFilters } from "@redux/slices/tableFilters";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@redux/hooks";
import {
  selectMerchantPreview,
  setOpeningOptions,
} from "@redux/slices/merchantPreview";
import { usePreviewPanel } from "@components/Merchants/MerchantPreview/hooks/usePreviewPanel";
import { OFACTabType } from "@components/Merchants/MerchantPreview/OFAC/helpers";

type Props = {
  id: number;
  setSelectedRow?: (newIdx: string | number) => void;
};

export type TSidePanelContent = Record<
  Exclude<TMerchantDoublePanel, "riskProfile" | "agreementSnapshot" | "">,
  any
>;

export type TPanel2 = Exclude<
  TMerchantDoublePanel,
  "riskProfile" | "agreementSnapshot"
>;

export const useEnterprisePreview = ({ id, setSelectedRow }: Props) => {
  const {
    openingOptions: { underwriter: openUnderwriting },
  } = useAppSelector(selectMerchantPreview);
  const navigate = useNavigate();
  const { isDesktopView } = useCustomTheme();
  const dispatch = useDispatch();
  const modal = useModal();
  const { open, onClose, TransitionProps, SlideProps } = useNiceModal();
  const { handleOpenConversationsModal } = useConversationsModal();
  const [doublePanel, setDoublePanel] = useState<TPanel2>(
    openUnderwriting ? "manageUnderwriting" : "",
  );
  const [OFACTab, setOFACTab] = useState<OFACTabType>("Business Profile");
  const [pepOwnerID, setPepOwnerID] = useState<TPEPOwner>(null);

  const { data, isLoading, merchant } = useMerchantDataPreview({
    id,
    isEnterprise: true,
    doublePanel,
  });
  const { panel1Collapsed, setPanel1Collapsed } = usePreviewPanel({
    doublePanel,
  });

  const { addMerchantToWatchlist, removeMerchantFromWatchlist } =
    useWatchlist(true);
  const { masqueradeHandler } = useMasquerade();
  const { isMasqueradeMode } = useMasqueradeReducer();
  const queryClient = useQueryClient();

  const isControlModeAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.ACQUIRER,
      RESOURCE_BASE.ACCESS_CONTROL,
    ),
    operation: OPERATIONS.UPDATE,
  });
  const isDeleteAllowed = useAccessControl({
    resource: RESOURCE_BASE.ENTERPRISE,
    operation: OPERATIONS.DELETE,
  });

  const closeFullPanel = () => {
    if (setSelectedRow) setSelectedRow(-1);
    setDoublePanel((prev) => {
      if (!prev) {
        // queryClient.removeQueries("get-merchant-preview");
        // queryClient.refetchQueries("list-acquirer-merchants");
      }
      handleOpenConversationsModal(false);
      if (onClose) onClose();
      return "";
    });
  };

  const handleClose = () => {
    if (doublePanel) {
      setPanel1Collapsed(true);
      return;
    }
    closeFullPanel();
  };

  const togglePanel2 = (open = true, panel2: TPanel2) => {
    // if panel1 is collapsed -> close whole panel on pressing x of panel2
    if (doublePanel && panel1Collapsed) {
      closeFullPanel();
    } else {
      setDoublePanel(open ? panel2 : "");
    }
  };

  const handleManageUnderwriting = (open?: boolean) => {
    togglePanel2(open, "manageUnderwriting");
  };

  const handleEnterpriseAgreement = (open?: boolean) => {
    togglePanel2(open, "MerchantAgreement");
  };

  const handleOpenOFACPanel = (open?: boolean, tab?: OFACTabType) => {
    togglePanel2(open, "ofac");
    if (tab) {
      setOFACTab(tab);
    }
  };

  const handleOpenPep = (open?: boolean) => {
    !open && setPepOwnerID(null);
    togglePanel2(open, "pep");
  };

  const selectPEPOwner = (data: TPEPOwner) => {
    setPepOwnerID(data);
    setDoublePanel("pep");
  };

  const handleControlMode = () => {
    masqueradeHandler(
      "acquirer",
      {
        accID: id,
        name: data.header.name,
        origin: "/acquirer/providers",
        role: Role.ENTERPRISE,
        nextRoute: "/provider",
        imageURL: data?.header?.imageURL,
      },
      handleClose,
    );
  };

  const handleEnterpriseConfiguration = () => {
    NiceModal.show(ENTERPRISE_CONFIGURATION_MODAL, { enterpriseId: id });
  };

  const handleAddToWatchList = () => {
    if (data.header.watchlist) {
      removeMerchantFromWatchlist(id, data.header.name);
    } else {
      addMerchantToWatchlist(id, data.header.name);
    }
  };

  const handleDeleteEnterprise = () => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "provider",
      itemName: data.header.name,
      url: `/merchants/${id}`,
      onSuccess: closeFullPanel,
    });
  };

  const handleNavigateToEntMerchants = () => {
    handleClose();
    dispatch(
      setFilters({
        queryParams: {
          parentName: [
            {
              label: data.header.name,
              id,
              imageURL: data.header.imageURL,
              value: data.header.name,
            },
          ],
        },
        queryString: `(parentName:"${data.header.name}")`,
      }),
    );
    navigate("/acquirer/merchants", { state: { filtersActive: true } });
  };

  const handleNext = () => {
    if (setSelectedRow) setSelectedRow("next");
  };

  const handlePrevious = () => {
    if (setSelectedRow) setSelectedRow("prev");
  };

  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();

  const actions: MerchantPreviewHeaderAction[] = [
    {
      title: "Configuration",
      hidden: shouldBeHidden.enterpriseConfiguration || isDesktopView,
      onClick: handleEnterpriseConfiguration,
    },
    {
      title: "Control mode",
      hidden: isDesktopView,
      onClick: handleControlMode, //INSIDE SIDE PANEL -> 3dots MENU ITEM
      disabled: !isControlModeAllowed,
      tooltipProps: {
        show: !isControlModeAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
    },
    {
      title: "View merchant list",
      onClick: handleNavigateToEntMerchants,
      hidden: false,
      sx: { paddingTop: "8px" },
    },
    {
      title: data.header.watchlist
        ? "Remove from watchlist"
        : "Add to watchlist",
      hidden: false,
      disabled: !isUpdateUnderwriterAllowed,
      onClick: handleAddToWatchList,
      tooltipProps: {
        show: !isUpdateUnderwriterAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
      sx: { paddingTop: "8px" },
    },
    {
      title: "Delete Provider",
      hidden: false,
      disabled: !isDeleteAllowed,
      onClick: handleDeleteEnterprise,
      sx: { color: palette.error.hover, padding: "8px 0px" },
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  useEffect(() => {
    if (id) {
      queryClient.refetchQueries(["get-merchant-preview", id]);
    }
  }, [modal.visible, isMasqueradeMode]);

  useEffect(() => {
    return () => {
      if (openUnderwriting) dispatch(setOpeningOptions({ underwriter: false }));
    };
  }, []);

  return {
    setDoublePanel,
    handleClose,
    modal,
    actions,
    data,
    merchant,
    isLoading,
    isControlModeAllowed,
    panel1Collapsed,
    setPanel1Collapsed,
    closeFullPanel,
    OFACTab,
    handlers: {
      handleControlMode,
      handleClose,
      handleOpenOFACPanel,
      handleManageUnderwriting,
      handleOpenPep,
      selectPEPOwner,
      handleEnterpriseConfiguration,
      handleEnterpriseAgreement,
      handleNext,
      handlePrevious,
    },
    open,
    doublePanel,
    SlideProps,
    TransitionProps,
    pepOwnerID,
  };
};
