import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
// components
import Stat from "@common/NewStats/Stat";
import { palette } from "@palette";
import { Customer, PurchaseProductType } from "@customTypes/customer.types";
import { getProductType } from "@components/Customers/Table/renderPurchasesStatistics";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";

type BannerProps = {
  data?: Customer;
};

const Banner = ({ data }: BannerProps) => {
  const purchases = data?.purchases || [];
  const totalPurchased = data?.totalPurchased
    ? (data?.totalPurchased / 100).toFixed(2)
    : 0;

  return (
    <FadeUpWrapper delay={50}>
      <Box sx={mainContent}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item md={4} xs={6}>
            <FadeUpWrapper delay={100}>
              <Stat value={totalPurchased} title="Sum Total" isAmount />
            </FadeUpWrapper>
          </Grid>
          {[
            "fundraiser",
            "event",
            "invoice",
            "membership",
            "sweepstake",
            "standard",
          ].map((item, idx) => {
            const current = purchases.filter(
              (purchase) => purchase.name === item,
            )[0];
            const label = getProductType(item as PurchaseProductType, true);

            return (
              <Grid item md={4} xs={6} key={item}>
                <FadeUpWrapper delay={100 + idx * 30}>
                  <Stat value={current?.totalCount || 0} title={label} />
                </FadeUpWrapper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </FadeUpWrapper>
  );
};

const mainContent = {
  gap: "16px",
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  borderRadius: "8px",
  backgroundColor: palette.neutral.white,
};

export default Banner;
