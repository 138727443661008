import { TAccounts } from "@customTypes/accounts.types";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectUser, updatePartialUser } from "@redux/slices/auth/auth";
import { isDefined } from "@utils/helpers";
import Cookies from "js-cookie";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "react-query";
import { customInstance } from "../index";

export const getUser = () => {
  return customInstance({
    url: `/me`,
    method: "GET",
  });
};

export const useGetUser = (props?: any) => {
  const { merchantId } = useGetCurrentMerchantId();
  const dispatch = useAppDispatch();
  const { email, globalName } = useAppSelector(selectUser);

  const { data: merchantData } = useGetMerchantById();

  const { data, ...rest } = useQuery(
    "user",
    async () => {
      const data = await getUser();
      return data;
    },
    {
      ...props,
      enabled: isDefined(merchantId),
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        props?.onSuccess?.(res);

        if (res?.email && res.email !== email) {
          dispatch(updatePartialUser({ email: res.email }));
          updateUserCookie({ email: res.email });
        }
        if (
          !isDefined(globalName.lastName) ||
          (res?.firstName && res.firstName !== globalName?.firstName) ||
          (res?.lastName && res.lastName !== globalName.lastName)
        ) {
          dispatch(
            updatePartialUser({
              globalName: {
                lastName: res?.lastName,
                firstName: res?.firstName,
                phoneNumber: res?.phoneNumber,
              },
            }),
          );
        }
      },
    },
  );

  return {
    data,
    merchantData,
    ...rest,
  };
};

const updateUserCookie = (fieldToUpdate: Record<string, any>) => {
  const user = Cookies.get("user");
  const parsedUser = user ? JSON.parse(user) : null;

  if (!parsedUser) return;

  Cookies.set("user", JSON.stringify({ ...parsedUser, ...fieldToUpdate }), {
    expires: 30,
  });
};

export const getAccounts = () => {
  return customInstance({
    url: `/accounts`,
    method: "GET",
  });
};

type QueryData = {
  total: number;
  data: TAccounts[];
};

export const useGetAccounts = (
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  return useQuery<QueryData>(
    "accounts",
    async () => {
      const data = await getAccounts();
      return data;
    },
    options,
  );
};

export const useGetAccountsWithUser = (
  options: Omit<
    UseQueryOptions<any, any, any, any>,
    "queryKey" | "queryFn"
  > = {},
) => {
  return useQuery<{ user: any; accounts: QueryData }>(
    "accounts-selection",
    async () => {
      const user = await getUser();
      const accounts = await getAccounts();
      return { user, accounts };
    },
    { ...options },
  );
};

export const validateAccount = async (token: string) => {
  return customInstance({
    url: `/email-verifications/${token}`,
    method: "POST",
  });
};

export const useValidateAccount = () => {
  return useMutation("post-validate-account", validateAccount);
};

export const usePutAccount = (
  options: Omit<UseMutationOptions<any, any, any, any>, "mutationFn"> = {},
) => {
  return useMutation(
    (id: string | number) =>
      customInstance({
        url: `/accounts/${id}`,
        method: "PUT",
      }),
    options,
  );
};
