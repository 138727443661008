import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Container, HeaderText } from "./components/StatusReviewAtoms";
import { Grid, Stack } from "@mui/material";
import { RiskLevelTag } from "@common/Table/RiskLevelTag";
import RiskStatusIconText from "@common/Table/RiskStatusIconText";
import { riskLevels } from "../../RiskProfile/components/RiskProfileActivity/RiskProfileActivityHeader";
import RiskActivity from "./RiskActivity";
import RiskSummaryActions from "./RiskSummaryActions";

type Prop = {
  riskProfile: any;
  statusName: any;
  merchantId: number;
  status: any;
  handleManageRiskProfile: () => void;
};
export const RiskSummary = ({
  riskProfile,
  statusName,
  merchantId,
  status,
  handleManageRiskProfile,
}: Prop) => {
  const {
    level,
    riskStatusName: _riskStatusName,
    profileID,
  } = riskProfile || {};
  const { isMobileView } = useCustomTheme();

  const isSuspended = statusName === "suspended";
  const riskLabel = level || "low";
  const riskStatusName = _riskStatusName || "routine_monitoring";
  const levelColor = riskLevels[riskLabel];

  return (
    <Container
      sx={{
        borderRadius: "12px",
        boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
      }}
      component="form"
      isMobile={isMobileView}
      padding="16px"
    >
      <Stack spacing={isMobileView ? 2 : 3}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          gap={isMobileView ? 2 : 0}
        >
          <HeaderText isMobile={isMobileView}>Risk Summary</HeaderText>
          <Stack direction="row" alignItems="center" gap={1}>
            <RiskLevelTag
              isSuspended={isSuspended}
              levelColor={levelColor}
              statusName={statusName}
              riskLevelLabel={riskLabel}
              hasBorder={false}
              customStyle={{
                maxHeight: "21px",
                alignItems: "center",
                padding: "2px 16px",
              }}
            />
            <RiskStatusIconText
              riskStatusName={riskStatusName}
              isOnlyIcon={false}
            />
          </Stack>
        </Grid>
        <RiskActivity
          profileId={profileID}
          merchantId={merchantId}
          riskLabel={riskLabel}
        />
        <RiskSummaryActions
          status={status}
          handleManageRiskMonitor={handleManageRiskProfile}
        />
      </Stack>
    </Container>
  );
};
