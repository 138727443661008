import NiceModal from "@ebay/nice-modal-react";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";

interface IComponentProps {
  onSubmit: () => void;
}

const DisputeConfirmSubmissionPopup = NiceModal.create(
  ({ onSubmit }: IComponentProps) => {
    const { open, onClose, TransitionProps } = useNiceModal();

    const handleSubmit = () => {
      onSubmit();
      onClose();
    };

    return (
      <ModalFactory
        variant="popup"
        modalProps={{
          open,
          onClose,
          PopupProps: {
            width: "408px",
            paperStyle: { padding: "24px 24px 16px " },
            TransitionProps,
          },
        }}
      >
        <ModalTitle
          title="Confirm Submission"
          description="Are you sure you want to submit the evidence? Once submitted, you won't be able to make further changes."
          onClose={onClose}
          textStyles={{ title: { fontSize: 18, lineHeight: "21.6px" } }}
        />
        <ModalActions
          containerProps={{ sx: { paddingTop: "24px" } }}
          primaryAction={{
            label: "Submit Evidence",
            sx: { fontSize: "18px" },
            onClick: handleSubmit,
          }}
          secondaryAction={{
            label: "Edit Evidence",
            sx: { fontSize: "18px" },
            onClick: onClose,
          }}
          animationDelay={300}
        />
      </ModalFactory>
    );
  },
);

export default DisputeConfirmSubmissionPopup;
