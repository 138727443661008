import cardImage from "@assets/images/fundraisers.png";

export const fundraisersData = [
  {
    id: 1,
    date: "Jun 30 2022",
    time: "Jun 30 2022 10:23PM UTC",
    title: "Paddington’s Postcards for Children",
    imageUrl: cardImage,
    type: "fundraiser",
    amount: 10238.33,
    conversion: "33",
    average: 163.11,
    transactions: 23,
    visitors: 2940,
    status: "Coming",
  },
  {
    id: 2,
    date: "Feb 06 2022",
    time: "Feb 06 2022 12:00PM UTC",
    title: "Captain Tom’s Walk for the NHS",
    imageUrl: cardImage,
    type: "peer-2-peer",
    amount: 98238.93,
    conversion: "35",
    average: 211.66,
    transactions: 51,
    visitors: 3004,
    status: "Coming",
  },
  {
    id: 3,
    date: "Dec 27 2021",
    time: "Dec 27 2021 9:00AM UTC",
    title: "Wings For Life – World Run",
    imageUrl: cardImage,
    type: "fundraiser",
    amount: 100560.77,
    conversion: "40",
    average: 344.11,
    transactions: 83,
    visitors: 6040,
    status: "Ended",
  },
  {
    id: 4,
    date: "Oct 31 2021",
    time: "Oct 31 2021 11:00PM UTC",
    title: "Movember",
    imageUrl: cardImage,
    type: "peer-2-peer",
    amount: 19129.67,
    conversion: "29",
    average: 173.03,
    transactions: 43,
    visitors: 2040,
    status: "Ended",
  },
];
