interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  background?: string;
}

const ImageGalleryIcon = ({
  width = 33,
  height = 32,
  fill = "none",
  stroke = "#727179",
  background = "#F4F3F7",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="32" height="32" rx="4" fill={background} />
      <rect
        opacity="0.4"
        x="4.92749"
        y="4.38464"
        width="10.6667"
        height="10.6667"
        rx="2"
        fill={stroke}
      />
      <rect
        opacity="0.8"
        x="4.92749"
        y="16.9487"
        width="10.6667"
        height="10.6667"
        rx="2"
        fill={stroke}
      />
      <rect
        opacity="0.8"
        x="17.9059"
        y="4.38464"
        width="10.6667"
        height="10.6667"
        rx="2"
        fill={stroke}
      />
      <rect
        x="17.9059"
        y="16.9487"
        width="10.6667"
        height="10.6667"
        rx="2"
        fill={stroke}
      />
    </svg>
  );
};

export default ImageGalleryIcon;
