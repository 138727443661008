import { useLocation } from "react-router-dom";

export type SubPathNameType = {
  pageName?: string;
  path: string;
};

//PathArray : table paths portions seperated by /
//subPaths  : an array of multiLevels of given Path ex: merchants/fundraisers/456 => ["merchants", "merchants/fundraisers", "merchants/fundraisers/456"]
export const getSubPathsRoute = (
  pathname: string,
  MapedPathName: Map<string, string>,
) => {
  const pathArray: string[] = pathname
    .split("/")
    .filter((sub_path) => sub_path !== "");

  const subPaths: SubPathNameType[] = pathArray.map((_, map_index: number) => {
    return {
      pageName: MapedPathName?.get(_),
      path: pathArray.slice(0, map_index + 1).join("/"),
    };
  });

  return {
    pathArray,
    subPaths,
  };
};

export const checkPortals = () => {
  const { pathname } = useLocation();

  const isEnterprisePortal = pathname.startsWith("/provider");
  const isAcquirerPortal = pathname.startsWith("/acquirer");
  const isMerchantPortal = pathname.startsWith("/merchant");

  const isNoSidebar = pathname.includes("advanced-builder");
  const isAcquirerEnterprises = pathname.startsWith("/acquirer/providers");
  const isManageMoney = pathname.includes("/manage-money");
  const isEvents = pathname.includes("/events");
  const isSweepStakes = pathname.includes("/sweepstakes");
  const isInvoice = pathname.includes("/invoices");
  const isFundraisers = pathname.includes("/fundraisers");
  const isMembership = pathname.includes("/memberships");
  const isDashBoard = pathname.includes("dashboard");
  const currentPortal = pathname.split("/")[1];
  const isTransfersPage = pathname.includes("/acquirer/transfers");

  return {
    isEnterprisePortal,
    isAcquirerPortal,
    isMerchantPortal,
    isAcquirerEnterprises,
    isManageMoney,
    isEvents,
    isSweepStakes,
    isInvoice,
    isMembership,
    isFundraisers,
    currentPortal,
    isNoSidebar,
    isDashBoard,
    isTransfersPage
  };
};
