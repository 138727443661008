import { namespaces } from "../../../../resources/i18n.constants";

export const invoiceMinibuilder = {
  [namespaces.pages.invoiceMinibuilder]: {
    create_invoice: "Create Invoice",
    general: "General",
    payment: "Payment",
    // General --------------------------------------------------------------------------------
    title: "Title",
    "Please enter a suitable title": "Please enter a suitable title",
    description: "Description",
    optional_but_recommended: "(Optional but recommended)",
    "What’s the purpose of this invoice": "What’s the purpose of this invoice?",
    browse_more_campaigns: "Browse More Campaigns",
    "If enabled, users will be able to explore other published campaigns of this Organization.":
      "If enabled, users will be able to explore other published campaigns of this Organization.",
    featured_image: "Featured Image",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000":
      "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000",
    use_as_background_image: "Use as Background Image",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "If checked this image will be integrated in the background of the form, can be later changed",
    media_library: "Media Library",
    add_new_image: "Add New Image",
    no_images: "No images",
    // Payment --------------------------------------------------------------------------------
    amount: "Amount",
    add_amount: "Add Amount",
    thumbnail: "Thumbnail",
    amount_title_is_mandatory: "Amount title is mandatory",
    must_be_above_one_dollar: "Must be above 1 USD",
    thank_you_for_your_payment: "Thank you for your payment.",
    "between 1.00 and 25,000.00": "between 1.00 and 25,000.00",
    should_be_at_least: "Should be at least 1",
    describe_this_payment_option: "Describe this payment option",
    create_option: "Create Option",
    save_changes: "Save Changes",
    cancel: "Cancel",
    remove: "Remove",

    set_min_max_value: "Set Min and Max value",
    minimum: "Minimum",
    min: "Min",
    max: "Max",
    maximum: "Maximum",
    set_min_max_value_text:
      "<span>Minimum</span> value can be helpful when dealing with additional costs of running the campaign, whereas a <span>maximum</span> value can be set for to political reasons.",

    payment_types: "Payment Types",
    "Choose the payment types available to your customers":
      "Choose the payment types available to your customers",
    one_time: "One-Time",
    "One-Time Payment, no recurring involved.":
      "One-Time Payment, no recurring involved.",
    monthly: "Monthly",
    "Payment will repeat every month from the date of payment":
      "Payment will repeat every month from the date of payment",
    quarterly: "Quarterly",
    "Payment will repeat every 3 months from the date of payment":
      "Payment will repeat every 3 months from the date of payment",
    yearly: "Yearly",
    "Payment will repeat every year from the date of payment":
      "Payment will repeat every year from the date of payment",
    default: "Default",

    if: "If",
    defaulted: "defaulted",
    this_type_will_be_selected_by_default:
      "this type will be selected by default",

    properties: "Properties",
    customer_pays_the_credit_card_fee: "Customer Pays the Credit Card Fee",
    "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee":
      "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee.",
    make_it_optional: "Make it Optional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "If checked customers will have the choice of not covering the Credit Card Fee",
    // Invoice Successful
    invoice_successfully_created: "Invoice successfully created",
    "If you’re on a desktop or a laptop":
      "If you’re on a desktop or a laptop, you can continue editing in <span>the Advanced Builder</span>. The Advanced Builder offers powerful customization tools to help you take your campaign to the next level and ultimately increase conversion.",
    invoice_create_success_description: "Congratulations, your payment form has been created successfully and it's ready to accept payments, you can check its details and share it everywhere or browse more payment forms",
    open_the_advanced_builder: "Open the Advanced Builder",
    edit_in_advanced_builder: "Edit in Advanced Builder",
    done: "Done",
  },
};
