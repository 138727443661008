import { Image } from "@common/StyledImage/Image";
import { TruncateText } from "@common/Text";
import { styled, Grid, Stack } from "@mui/material";
import { palette } from "@palette";
import placeholder from "@assets/images/AvatarReb.png";

export type UserListItemProps = {
  imageURL: string;
  userName: string;
  email: string;
};

const UserListItem = ({ imageURL, userName, email }: UserListItemProps) => {
  const profileImage = imageURL ? imageURL + "/thumb" : placeholder;
  return (
    <Wrapper>
      <Image
        width={40}
        height={40}
        alt="User image"
        sx={{ borderRadius: "50%" }}
        src={profileImage}
      />
      <Grid container columnSpacing="4px">
        <Grid item xs={6}>
          <TruncateText
            lineClamp={1}
            color={palette.black[100]}
            lineHeight="16.8px"
          >
            {userName}
          </TruncateText>
        </Grid>
        <Grid item xs={6}>
          <TruncateText
            lineClamp={1}
            color={palette.gray[300]}
            lineHeight="16.8px"
            fontWeight="book"
          >
            {email}
          </TruncateText>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(() => ({
  padding: "8px 16px",
  borderRadius: "4px",
  background: "#FBFBFB",
  flexDirection: "row",
  gap: "16px",
  alignItems: "center",
  justifyContent: "stretch",
}));

export default UserListItem;
