import {
  CalendarDuoToneIconV2,
  CheckIconInCircle,
  CheckMarkIcon,
  CrossInCircleIcon,
  GreyHourGlassIcon,
  OrangeRefundIcon,
  RedChargeBackIcon,
  TickIcon,
  UploadIcon,
} from "@assets/icons";
import { TagType } from "./Tag";
import { palette } from "@palette";

export const getIconMap = (type: TagType, filled: boolean) => {
  const iconsMap: { [key: string]: JSX.Element } = {
    approved: (
      <TickIcon
        stroke={filled ? palette.success.light : palette.success.main}
      />
    ),
    "pre approved": (
      <TickIcon
        stroke={filled ? palette.success.light : palette.success.main}
      />
    ),
    preapproved_mid_issue: (
      <TickIcon
        stroke={filled ? palette.success.light : palette.success.main}
      />
    ),
    "processing approved": (
      <TickIcon
        stroke={filled ? palette.success.light : palette.success.main}
      />
    ),
    active: (
      <GreyHourGlassIcon
        fill={filled ? palette.neutral.white : palette.success.main}
      />
    ),
    cancelled: <CrossInCircleIcon />,
    declined: <CrossInCircleIcon />,
    refunded: (
      <OrangeRefundIcon
        stroke={filled ? palette.warning.light : palette.filled.orange}
      />
    ),
    update_requested: (
      <OrangeRefundIcon
        stroke={filled ? palette.warning.light : palette.filled.orange}
      />
    ),
    expired: <CalendarDuoToneIconV2 />,
    chargeback: (
      <RedChargeBackIcon
        stroke={filled ? palette.error.light : palette.filled.red}
      />
    ),
    pending: (
      <GreyHourGlassIcon
        fill={filled ? palette.liftedWhite.main : palette.neutral["80"]}
      />
    ),
    "pending review": (
      <GreyHourGlassIcon
        fill={filled ? palette.liftedWhite.main : palette.neutral["80"]}
      />
    ),
    review: <GreyHourGlassIcon fill="#87858D" />,
    upload: <UploadIcon path_fill="#FF8124" />,
    upcoming: <CheckMarkIcon />,
    ended: <TickIcon stroke={filled ? palette.info.soft : palette.info.hard} />,
    used: <CheckIconInCircle />,
    test: <GreyHourGlassIcon fill="#FF9336" />,
    remaining: <GreyHourGlassIcon fill="#87858D" />,
    completed: <CheckMarkIcon />,
  };

  return (iconsMap as Record<TagType, JSX.Element>)[type];
};

export const getColorsMap = (type: TagType, filled: boolean) => {
  const colorsMap = {
    approved: filled ? palette.success.light : palette.success.main,
    "pre approved": filled ? palette.success.light : palette.success.main,
    preapproved_mid_issue: filled
      ? palette.success.light
      : palette.success.main,
    "processing approved": filled
      ? palette.success.light
      : palette.success.main,
    active: filled ? palette.neutral.white : palette.success.main,
    refunded: filled ? palette.warning.light : palette.filled.orange,
    update_requested: filled ? palette.warning.light : palette.filled.orange,
    expired: filled ? palette.neutral.white : palette.warning.main,
    chargeback: filled ? palette.error.light : palette.error.main,
    cancelled: filled ? palette.neutral.white : palette.error.main,
    declined: filled ? palette.neutral.white : palette.error.main,
    pending: filled ? palette.liftedWhite.main : palette.neutral[80],
    "pending review": filled ? palette.liftedWhite.main : palette.neutral[80],
    review: filled ? palette.neutral.white : palette.neutral[80],
    upload: filled ? palette.neutral.white : "#FF8124",
    upcoming: filled ? palette.neutral.white : palette.success.main,
    ended: filled ? palette.info.soft : palette.info.hard,
    used: filled ? palette.neutral.white : palette.info.main,
    test: filled ? palette.neutral.white : "#FF9336",
    remaining: filled ? palette.neutral.white : palette.neutral[500],
    completed: filled ? palette.neutral.white : palette.success.main,
    "Under Review": palette.tag.warning.hover,
    suspended: filled ? palette.warning.text : palette.warning.main,
    deactivated: filled ? palette.gray.main : palette.gray.main,
  };

  return colorsMap[type];
};
