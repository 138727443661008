import React from "react";

const ArrowElbowDownLeft = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9374 2.25V10.125C10.9374 10.241 10.8913 10.3523 10.8092 10.4344C10.7272 10.5164 10.6159 10.5625 10.4999 10.5625H3.68087L5.55939 12.4405C5.60004 12.4811 5.63228 12.5294 5.65428 12.5825C5.67628 12.6356 5.6876 12.6925 5.6876 12.75C5.6876 12.8075 5.67628 12.8644 5.65428 12.9175C5.63228 12.9706 5.60004 13.0189 5.55939 13.0595C5.51874 13.1002 5.47048 13.1324 5.41737 13.1544C5.36426 13.1764 5.30734 13.1877 5.24986 13.1877C5.19237 13.1877 5.13545 13.1764 5.08234 13.1544C5.02923 13.1324 4.98097 13.1002 4.94032 13.0595L2.31532 10.4345C2.27465 10.3939 2.24238 10.3456 2.22036 10.2925C2.19834 10.2394 2.18701 10.1825 2.18701 10.125C2.18701 10.0675 2.19834 10.0106 2.22036 9.95746C2.24238 9.90435 2.27465 9.8561 2.31532 9.81547L4.94032 7.19047C5.02242 7.10838 5.13376 7.06226 5.24986 7.06226C5.36595 7.06226 5.47729 7.10838 5.55939 7.19047C5.64148 7.27256 5.6876 7.3839 5.6876 7.5C5.6876 7.6161 5.64148 7.72744 5.55939 7.80953L3.68087 9.6875H10.0624V2.25C10.0624 2.13397 10.1084 2.02269 10.1905 1.94064C10.2725 1.85859 10.3838 1.8125 10.4999 1.8125C10.6159 1.8125 10.7272 1.85859 10.8092 1.94064C10.8913 2.02269 10.9374 2.13397 10.9374 2.25Z"
        fill="#343330"
      />
    </svg>
  );
};

export default ArrowElbowDownLeft;
