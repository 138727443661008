import { lazy } from "react";
import { Navigate } from "react-router-dom";

const AcquirerMerchantsPage = lazy(() =>
  import("@pages/AcquirerPortal/Merchants").then((module) => ({
    default: module.AcquirerMerchantsPage,
  })),
);
const AcquirerDisputesPage = lazy(() =>
  import("@pages/AcquirerPortal/Disputes").then((module) => ({
    default: module.AcquirerDisputesPage,
  })),
);
const AcquirerEnterprisesPage = lazy(() =>
  import("@pages/AcquirerPortal/Enterprises").then((module) => ({
    default: module.AcquirerEnterprisesPage,
  })),
);
const SettlementPage = lazy(() =>
  import("@pages/AcquirerPortal/Settlement").then((module) => ({
    default: module.Settlement,
  })),
);
const TransfersPage = lazy(() =>
  import("@pages/Transfers").then((module) => ({
    default: module.Transfers,
  })),
);
const SettlementReportPage = lazy(() =>
  import("@pages/AcquirerPortal/SettlementReport").then((module) => ({
    default: module.SettlementReport,
  })),
);
const AcquirerProcessingPage = lazy(() =>
  import("@pages/AcquirerPortal/Processing").then((module) => ({
    default: module.AcquirerProcessingPage,
  })),
);
const AcquirerSettingsPage = lazy(() =>
  import("@pages/AcquirerPortal/AcquirerSettings").then((module) => ({
    default: module.AcquirerSettingsPage,
  })),
);

const TransactionDetails = lazy(() =>
  import("@components/AcquirerMerchants/ViewTransaction/Mobile").then(
    (module) => ({
      default: module.TransactionDetails,
    }),
  ),
);

const ProcessingPaymentDetails = lazy(
  () =>
    import(
      "@components/Processing/TransactionsTable/Mobile/ProcessingPaymentDetails"
    ),
);

const FeatureFlagsPage = lazy(() =>
  import("@pages/AcquirerPortal/FeatureFlagTests").then((module) => ({
    default: module.FeatureFlagsTests,
  })),
);

// SETTINGS --------------------------------------------------------------------------------------------------

const BusinessDetails = lazy(
  () => import("@components/Settings/BusinessDetails/BusinessDetails"),
);

const ManageTeam = lazy(() =>
  import("@components/Settings").then((module) => ({
    default: module.MemberListTable,
  })),
);

// const AcquirerEnterprisePage = lazy(() =>
//   import("@pages/AcquirerPortal/Enterprise").then((module) => ({
//     default: module.AcquirerEnterprisePage,
//   })),
// );

import { shouldBeHidden } from "@constants/constants";
import { ACQUIRER_PATHS } from "./paths";

type Props = {
  queryParams: string;
  isTransfersPageEnabled: boolean;
  isDisputesPageEnabled: boolean;
};

export const getAcquirerRoutes = ({
  queryParams,
  isTransfersPageEnabled,
  isDisputesPageEnabled,
}: Props) => {
  return [
    {
      element: (
        <Navigate
          to={`/acquirer/${ACQUIRER_PATHS.PROVIDERS}${queryParams}`}
          replace
        />
      ),
      index: true,
    },
    {
      path: ACQUIRER_PATHS.PROCESSING,
      children: [
        { element: <AcquirerProcessingPage />, index: true },
        {
          path: ACQUIRER_PATHS.PROCESSING_ID,
          element: <ProcessingPaymentDetails />,
        },
      ],
    },
    ...(isTransfersPageEnabled
      ? [
          {
            path: ACQUIRER_PATHS.TRANSFERS,
            element: <TransfersPage />,
          },
        ]
      : []),
    {
      path: ACQUIRER_PATHS.PROVIDERS,
      children: [
        {
          element: <AcquirerEnterprisesPage />,
          index: true,
        },
        // {
        //   path: ACQUIRER_PATHS.ENTERPRISE,
        //   element: <AcquirerEnterprisePage />,
        // },
      ],
    },
    {
      path: ACQUIRER_PATHS.MERCHANTS,
      children: [
        { element: <AcquirerMerchantsPage />, index: true },
        {
          path: ACQUIRER_PATHS.TRANSACTIONS_ID,
          element: <TransactionDetails />,
        },
      ],
    },
    ...(shouldBeHidden.settlement
      ? []
      : [
          {
            path: ACQUIRER_PATHS.SETTLEMENT,
            children: [
              { element: <SettlementPage />, index: true },
              {
                path: ACQUIRER_PATHS.SETTLEMENT_ID,
                element: <SettlementReportPage />,
              },
            ],
          },
        ]),
    ...(isDisputesPageEnabled
      ? [
          {
            path: ACQUIRER_PATHS.DISPUTES,
            children: [{ element: <AcquirerDisputesPage />, index: true }],
          },
        ]
      : []),
    {
      path: ACQUIRER_PATHS.SETTINGS,
      element: <AcquirerSettingsPage />,
      children: [
        {
          element: (
            <Navigate
              to={`${ACQUIRER_PATHS.BUSINESS_DETAILS}${queryParams}`}
              replace
            />
          ),
          index: true,
        },
        {
          path: ACQUIRER_PATHS.BUSINESS_DETAILS,
          element: <BusinessDetails />,
        },
        {
          path: ACQUIRER_PATHS.MANAGE_TEAM,
          children: [
            {
              element: <ManageTeam />,
              index: true,
            },
          ],
        },
      ],
    },
    {
      path: ACQUIRER_PATHS.FEATURE_FLAGS,
      children: [{ element: <FeatureFlagsPage />, index: true }],
    },
  ];
};
