import FadeInWrapper from "@components/animation/FadeInWrapper";
import { SxProps } from "@mui/material";
import { SingleActionButton } from "./PreviewToolbar";

const ArrowButton = ({
  children,
  sx,
  onClick,
  disabled = false,
  ...props
}: {
  children: React.ReactNode;
  sx: SxProps;
  onClick: () => void;
  disabled?: boolean;
  "data-testid": string
}) => {
  return (
    <FadeInWrapper delay={100} dataTestId={props["data-testid"]} containerProps={{ zIndex: 1100 }}>
      <SingleActionButton
        data-testid="arrow-button"
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
        sx={{
          position: "fixed",
          top: "50%",
          trasform: "translateY(-50%)",
          "&:hover": {
            opacity: 0.8,
          },
          ...sx,
        }}
      >
        {children}
      </SingleActionButton>
    </FadeInWrapper>
  );
};

export default ArrowButton;
