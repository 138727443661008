import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { TConversationTopic } from "features/Minibuilders/Conversations/hooks/useListTopics";

const TotalUnread = ({
  item,
}: {
  item: TConversationTopic;
  hasUnreadActivity?: boolean;
}) => {
  return (
    <Box
      data-testid="unread-conversations-container"
      sx={{
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        overflow: "hidden",
        marginLeft: "-5px",
        display: "flex",
        alignItems: "center",
        padding: "3px 5px",
        justifyContent: "center",
        background: item?.typeName === "internal" ? "#6D9CF8" : "#FF8124",
        color: "#FFF",
      }}
    >
      <Text
        fontSize={8}
        sx={{
          lineHeight: 0,
        }}
      >
        {item.totalUnread}
      </Text>
    </Box>
  );
};

export default TotalUnread;
