import { showMessage } from "@common/Toast";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { customInstance } from "@services/api";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { TPosition } from "@common/Toast/ShowToast";

const downloadZipSingleImage = async (
  zipInstance: JSZip,
  item: TMerchantDocument,
) => {
  try {
    const res = await axios({
      url: item.fileURL,
      method: "GET",
      responseType: "blob",
    });
    return zipInstance.file(item.fileName, res.data);
  } catch (error) {
    showMessage("Error", "One or more files could not be downloaded");
  }
};

export const downloadAll = async (fileArr: TMerchantDocument[]) => {
  const zip = new JSZip();
  const fileArray = await Promise.all(
    fileArr.map(async (item) => await downloadZipSingleImage(zip, item)),
  );
  if (fileArray.every((item) => item === undefined)) return;

  zip.generateAsync({ type: "blob" }).then(function (blob) {
    saveAs(blob, "documents.zip");
  });
};

export const deleteDocumentAPI = async (
  id: number,
  fileID: number,
  onEnd?: () => void,
  isDesktop?: boolean,
  hideDeleteMessage?: boolean,
) => {
  const ToastPosition: TPosition | undefined = !isDesktop
    ? {
        position: "top-left",
        style: { top: "47px", left: "7px" },
      }
    : undefined;
  try {
    await customInstance({
      url: `/accounts/${id}/files/${fileID}`,
      method: "DELETE",
    });
    !hideDeleteMessage &&
      showMessage(
        "Success",
        "Your file is deleted successfully",
        false,
        "",
        undefined,
        ToastPosition,
      );
    if (onEnd) onEnd();
  } catch (error) {
    showMessage(
      "Warning",
      "Ups something went wrong",
      false,
      "",
      undefined,
      ToastPosition,
    );
  }
};

export async function deleteDocumentsInLoop(
  documents: any[],
  merchantID: number,
  hideDeleteMessage?: boolean,
) {
  for (const file of documents) {
    try {
      await deleteDocumentAPI(
        merchantID,
        file?.id,
        undefined,
        undefined,
        hideDeleteMessage,
      );
    } catch (error) {
      //
    }
  }
}
