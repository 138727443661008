import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import Popup from "@common/Popup";

const EnterpriseNotAvailableModal = NiceModal.create(() => {
  const modal = useModal();

  const handleClose = () => {
    modal.hide();
  };

  return (
    <Popup
      {...muiDialogV5(modal)}
      title="No Available Provider"
      onSubmit={handleClose}
      actionLabel="Understood"
    >
      There is no provider created for the moment. To be able to create a
      Merchant you need to have at least 1 provider created.
    </Popup>
  );
});

export default EnterpriseNotAvailableModal;
