import { SvgIconProps } from "@mui/material";

const NewTicketsIcon = ({
  height = 16,
  width = 16,
  fill = "#575353",
}: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M22.2806 14.3438L14.3437 22.2806C14.2031 22.4212 14.0124 22.5001 13.8136 22.5001C13.6148 22.5001 13.4241 22.4212 13.2834 22.2806L3.96937 12.9694C3.82899 12.8288 3.75009 12.6383 3.75 12.4397V3.75H12.4397C12.6383 3.75009 12.8288 3.82899 12.9694 3.96938L22.2806 13.2806C22.3506 13.3503 22.4062 13.4331 22.4441 13.5244C22.482 13.6156 22.5015 13.7134 22.5015 13.8122C22.5015 13.911 22.482 14.0088 22.4441 14.1C22.4062 14.1912 22.3506 14.2741 22.2806 14.3438Z"
        fill={fill}
      />
      <path
        d="M22.8103 12.75L13.5 3.43969C13.3612 3.2998 13.196 3.18889 13.014 3.11341C12.832 3.03792 12.6368 2.99938 12.4397 3.00001H3.75001C3.5511 3.00001 3.36033 3.07903 3.21968 3.21968C3.07903 3.36033 3.00001 3.5511 3.00001 3.75001V12.4397C2.99938 12.6368 3.03792 12.832 3.11341 13.014C3.18889 13.196 3.2998 13.3612 3.43969 13.5L12.75 22.8103C12.8893 22.9496 13.0547 23.0602 13.2367 23.1356C13.4187 23.211 13.6138 23.2498 13.8108 23.2498C14.0078 23.2498 14.2029 23.211 14.3849 23.1356C14.5669 23.0602 14.7323 22.9496 14.8716 22.8103L22.8103 14.8716C22.9496 14.7323 23.0602 14.5669 23.1356 14.3849C23.211 14.2029 23.2498 14.0078 23.2498 13.8108C23.2498 13.6138 23.211 13.4187 23.1356 13.2367C23.0602 13.0547 22.9496 12.8893 22.8103 12.75ZM13.8103 21.75L4.50001 12.4397V4.50001H12.4397L21.75 13.8103L13.8103 21.75ZM9.00001 7.87501C9.00001 8.09751 8.93403 8.31502 8.81041 8.50002C8.68679 8.68503 8.51109 8.82922 8.30553 8.91437C8.09996 8.99952 7.87376 9.0218 7.65553 8.97839C7.4373 8.93498 7.23685 8.82784 7.07951 8.6705C6.92218 8.51317 6.81503 8.31271 6.77162 8.09448C6.72822 7.87626 6.75049 7.65006 6.83564 7.44449C6.92079 7.23892 7.06499 7.06322 7.24999 6.9396C7.435 6.81599 7.6525 6.75001 7.87501 6.75001C8.17338 6.75001 8.45952 6.86853 8.6705 7.07951C8.88148 7.29049 9.00001 7.57664 9.00001 7.87501Z"
        fill={fill}
      />
    </svg>
  );
};

export default NewTicketsIcon;
