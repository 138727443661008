import { Text } from "@common/Text";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { ChatsCircle, TelegramLogo } from "@phosphor-icons/react";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { Tabs_Types } from "../types";

function Tabs({
  tabs,
  setTabs,
}: {
  tabs: Tabs_Types;
  setTabs: (data: Tabs_Types) => void;
}) {
  const { isEnabledConversationActivity } = useGetFeatureFlagValues();
  const filterTabs = [
    {
      label: "Internal",

      onClick: () => setTabs("Internal"),
      selected: tabs === "Internal",
      Icon: ChatsCircle,
    },
    {
      label: "Activity",
      Icon: TelegramLogo,
      onClick: () => setTabs("Activity"),
      selected: tabs === "Activity",
      hidden: !isEnabledConversationActivity,
    },
  ];
  return (
    <Container direction="row">
      {filterTabs?.map(({ label, selected, onClick, Icon, hidden }) => {
        if (hidden) return null;

        return (
          <SingleItemContainer
            isSelected={selected}
            onClick={onClick}
            key={label}
            data-testid={`tab-button-${label}`}
          >
            <Icon
              size={18}
              weight="fill"
              color={selected ? "#292727" : "#8F8F8F"}
            />
            <CustomText isSelected={selected}>{label}</CustomText>
          </SingleItemContainer>
        );
      })}
    </Container>
  );
}

export default Tabs;

const Container = styled(Stack)(() => ({
  width: "100%",
  borderBottom: "1px solid",
  borderBottomColor: palette.neutral[20],
}));

interface SingleItemContainerProps {
  isSelected?: boolean;
}

const SingleItemContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<SingleItemContainerProps>(({ isSelected }) => ({
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  padding: "8.5px 32px 8.5px 32px",
  borderBottom: "1px solid transparent",
  ...(isSelected && {
    borderBottom: "1px solid #292727",
  }),
}));

const CustomText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<SingleItemContainerProps>(({ isSelected }) => ({
  fontSize: "14px",
  fontWeight: "357",
  color: "#8F8F8F",
  ...(isSelected && {
    color: "#292727",
  }),
}));
