import AlertIcon from "@assets/icons/RebrandedIcons/AlertIcon";
import { Text } from "@common/Text";
import { Box, Popover, Stack, styled } from "@mui/material";
import React, { memo, useState } from "react";
import { TRiskActivity } from "../../types";
import NiceModal from "@ebay/nice-modal-react";
import { MANAGE_RISK_TRIGGER_MODAL } from "modals/modal_names";
import { addSizeToImage } from "@components/UploadAvatar/UploadAvatar";
import { format } from "date-fns";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { CustomToolTip as PermissionTooltip } from "@common/BusinessOwners/CustomToolTip";
import CustomToolTip from "./CustomToolTip";
import CheckFillIcon from "@assets/icons/RebrandedIcons/CheckFillIcon";
import { palette } from "@theme/colors";
import { Button } from "@common/Button";
import TriggersConversationButton from "@components/Merchants/MerchantPreview/RiskProfile/components/Triggers/TriggersConversationButton";
import { useOpenRiskConversation } from "@components/Merchants/MerchantPreview/RiskProfile/hooks/useOpenRiskConversation";
import { checkPortals } from "@utils/routing";

interface Props {
  data: TRiskActivity;
  profileId?: number;
  merchantId?: number;
  readOnly?: boolean;
}

function ActivityCard({ data, readOnly = false, merchantId }: Props) {
  const { isAcquirerPortal } = checkPortals();
  const { risk_activity } = useEnterprisePermissions();
  const allowedUpdateEscalation = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.RISK_PROFILE_ESCALATION,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const handleManageClick = () => {
    NiceModal.show(MANAGE_RISK_TRIGGER_MODAL, { merchantId, data });
  };

  const { handleOpenRiskConversation } = useOpenRiskConversation({
    threadID: data.threadID,
    merchantId,
    defaultMessage: `#${data.merchantFactorName?.replaceAll(" ", "")} `,
    hasUnreadMessages: data.hasUnreadMessages,
    escalationIds: [data.id],
  });

  return (
    <Box
      bgcolor="#FFFFFF"
      boxShadow="1px 1px 15px 0px #A1AFB41A"
      borderRadius="16px"
      p="12px"
      position="relative"
      my={readOnly ? 0 : "8px"}
      zIndex={2}
      data-testid="risk-profile-activity-card"
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Box gap="16px" alignItems="center" display="flex">
          {data.isIgnored ? (
            <CheckFillIcon fill={palette.filled["success"]} />
          ) : (
            <AlertIcon fill={palette.accent[4]} height={24} width={24} />
          )}

          <Text
            fontWeight="regular"
            fontSize="14px"
            color={palette.neutral[80]}
          >
            {data.merchantFactorName}
          </Text>
          {data.isIgnored && (
            <CustomToolTip
              title={`Marked as OK by ${data.ignoredByUserFirstName} ${
                data.ignoredByUserLastName
              } on ${format(
                new Date(data.updatedAt * 1000),
                "dd LLLL, HH:mm",
              )}`}
              image={
                addSizeToImage(data.ignoredByUserAvatarImageURL, "small") ||
                AvatarPlaceholder
              }
            />
          )}
          <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
            {data.createdAt}
          </Text>
        </Box>
        {!readOnly && isAcquirerPortal && (
          <TriggersConversationButton
            hasUnreadMessages={data.hasUnreadMessages}
            onClick={handleOpenRiskConversation}
          />
        )}
      </Stack>
      <StyledBox>
        <Box>
          <Text color={palette.neutral[80]} fontWeight="book" fontSize="14px">
            {data?.merchantFactorDescription}
          </Text>
        </Box>
      </StyledBox>

      <Box
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        mt="12px"
      >
        <Text
          color={palette.neutral[80]}
          fontSize="14px"
          borderRadius="100px"
          padding="2px 16px"
          fontWeight="book"
          bgcolor="#F8F8F6"
        >
          +{data.merchantFactorRiskPoints} Point
          {data.merchantFactorRiskPoints > 1 && "s"}
        </Text>

        <Box>
          {!readOnly && risk_activity && (
            <PermissionTooltip
              showToolTip={!allowedUpdateEscalation}
              message={EDIT_DENY_MESSAGE}
            >
              <Button
                sx={{ height: "34px" }}
                disabled={!allowedUpdateEscalation}
                onClick={handleManageClick}
                data-testid="manage-risk-activity-button"
              >
                Manage
              </Button>
            </PermissionTooltip>
          )}
        </Box>

        {/* <AddCommentsButton
            messageImages={hardCodedData?.messageImages}
            isNoIssue={data?.isIgnored}
          /> */}
      </Box>
      {/* {!data.isIgnored && (
          <LatestCommentComponent {...hardCodedData.latestComment} />
        )} */}

      {!readOnly && !data.isIgnored && (
        <Box
          boxShadow={`0px 0px 4px 0px ${palette.error[600]}`}
          position="absolute"
          width="16px"
          height="16px"
          borderRadius="100px"
          bgcolor={palette.error[500]}
          top={-6}
          left={-6}
        />
      )}
    </Box>
  );
}

export default memo(ActivityCard);

const StyledBox = styled("div")(() => ({
  margin: "12px 0",
  borderRadius: "8px",
  padding: "12px 16px",
  backgroundColor: palette.primary.background,
}));
