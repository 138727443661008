interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
}

const UpTrendIconV2 = ({
  width = 24,
  height = 24,
  fill = "#575353",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5004 5.25V11.25C22.5005 11.3984 22.4566 11.5435 22.3742 11.667C22.2918 11.7904 22.1746 11.8867 22.0375 11.9435C21.9004 12.0003 21.7495 12.0151 21.6039 11.9861C21.4584 11.9572 21.3247 11.8856 21.2198 11.7806L18.7504 9.31031L13.281 14.7806C13.2114 14.8504 13.1287 14.9057 13.0376 14.9434C12.9466 14.9812 12.849 15.0006 12.7504 15.0006C12.6519 15.0006 12.5543 14.9812 12.4632 14.9434C12.3722 14.9057 12.2894 14.8504 12.2198 14.7806L9.00042 11.5603L2.78104 17.7806C2.64031 17.9214 2.44944 18.0004 2.25042 18.0004C2.05139 18.0004 1.86052 17.9214 1.71979 17.7806C1.57906 17.6399 1.5 17.449 1.5 17.25C1.5 17.051 1.57906 16.8601 1.71979 16.7194L8.46979 9.96938C8.53945 9.89964 8.62216 9.84432 8.71321 9.80658C8.80426 9.76884 8.90186 9.74941 9.00042 9.74941C9.09898 9.74941 9.19657 9.76884 9.28762 9.80658C9.37867 9.84432 9.46139 9.89964 9.53104 9.96938L12.7504 13.1897L17.6901 8.25L15.2198 5.78063C15.1148 5.67573 15.0433 5.54204 15.0143 5.39648C14.9853 5.25092 15.0001 5.10002 15.057 4.96291C15.1138 4.82579 15.21 4.70861 15.3334 4.62621C15.4569 4.54381 15.602 4.49988 15.7504 4.5H21.7504C21.9493 4.5 22.1401 4.57902 22.2807 4.71967C22.4214 4.86032 22.5004 5.05109 22.5004 5.25Z"
        fill={fill}
      />
    </svg>
  );
};

export default UpTrendIconV2;
