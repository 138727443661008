interface IconProps {
  height?: number;
  width?: number;
  fill?: string;
  stroke?: string;
  background?: string;
}

const RichTextIcon = ({
  width = 33,
  height = 32,
  fill = "none",
  stroke = "#727179",
  background = "#F4F3F7",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.25" width="32" height="32" rx="4" fill={background} />
      <rect
        x="5.58337"
        y="9.33331"
        width="21.3333"
        height="2.66667"
        rx="1.33333"
        fill="#727179"
      />
      <rect
        x="5.58337"
        y="14.6666"
        width="21.3333"
        height="2.66667"
        rx="1.33333"
        fill="#727179"
      />
      <rect
        x="5.58337"
        y="20"
        width="13.5758"
        height="2.66667"
        rx="1.33333"
        fill="#727179"
      />
    </svg>
  );
};

export default RichTextIcon;
