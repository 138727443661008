import { RHFInput } from "@common/Input";
import { MAX_TEXT_AREA_LENGTH } from "./merchantAgreement.const";
import { RHFInputProps } from "@common/Input/Input";

const RefundPolicyInput = ({
  name,
  label,
  placeholder,
  rows,
  inputProps,
  helperText,
  ...rest
}: RHFInputProps) => {
  return (
    <>
      <RHFInput
        name={name}
        label={label}
        placeholder={placeholder}
        multiline
        rows={rows}
        fullWidth
        inputProps={{
          ...inputProps,
          maxLength: MAX_TEXT_AREA_LENGTH,
        }}
        helperText={helperText}
        {...rest}
      />
    </>
  );
};

export default RefundPolicyInput;
