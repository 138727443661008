import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import React, { useMemo } from "react";
import { Item } from "../transactions.types";
import { RecurringComponent_V2 } from "./RecurringComponent";
import { Text } from "@common/Text";
import { ActionsRow } from "../SubExpandedRow";
import { AMOUNTS_REGION } from "@constants/constants";

export type column = { title: string; key: string; size: number };

type SourceAccount = {
  fullName: string;
  email: any;
  card: {
    brand: any;
    numberLast4: any;
  };
};

interface Props {
  items: Item[];
  sourceAccount: SourceAccount;
  purchasesColumns?: column[];
  hideRefund?: boolean;
  transactionStatus: string;
}

type columns = "purchasedItems" | "type" | "recurringCount" | "item" | "price";

const columns = [
  { title: "Purchased items", key: "purchasedItems", size: 2 },
  { title: "Type", key: "type", size: 1.5 },
  { title: "Recurrence", key: "recurringCount", size: 3 },
  { title: "Item", key: "item", size: 3 },
  { title: "Amount (USD)", key: "price", size: 1.5 },
];

const sorts = {
  recurringCount: (a: Item, b: Item) => {
    return b.recurringCount - a.recurringCount;
  },
  purchasedItems: (a: Item, b: Item) => {
    return b.product.name.charCodeAt(0) - a.product.name.charCodeAt(0);
  },
  type: (a: Item, b: Item) => {
    return b.product.type.charCodeAt(0) - a.product.type.charCodeAt(0);
  },
  price: (a: Item, b: Item) => {
    return b.price - a.price;
  },
  item: (a: Item, b: Item) => {
    return b.unitPrice - a.unitPrice;
  },
};

export const PurchasesGrid = ({
  items,
  sourceAccount,
  purchasesColumns,
  hideRefund,
  transactionStatus,
}: Props) => {
  const [sorting, setSorting] = React.useState<columns | null>(null);

  const sortedItems = useMemo(() => {
    if (sorting === null) return items;
    if (sorting.startsWith("-")) {
      const key = sorting.replace("-", "") as columns;

      return items.sort((a: Item, b: Item) => sorts[key](b, a));
    } else {
      return items.sort((a: Item, b: Item) => sorts[sorting](a, b));
    }
  }, [sorting, items]);

  return (
    <Grid container marginTop={8} flexDirection="column">
      <Header purchasesColumns={purchasesColumns} />
      {sortedItems.map((item, index) => (
        <Row
          key={item.id}
          disallowReversalRequests={item.disallowReversalRequests}
          id={item.id}
          orderRecurringItemID={item.orderRecurringItemID}
          price={parseAmount(item.price / 100)}
          productName={item.product?.name}
          productType={item.product?.type}
          recurringCount={item.recurringCount}
          recurringInterval={item.recurringInterval}
          recurringMax={item.recurringMax}
          sourceAccount={sourceAccount}
          isLastRow={index === items.length - 1}
          unitPrice={parseAmount(item.unitPrice / 100)}
          quantity={item.quantity}
          purchasesColumns={purchasesColumns}
          hideRefund={Boolean(item.reversalTransactionType)}
          transactionStatus={transactionStatus}
        />
      ))}
    </Grid>
  );
};

const Header = ({ purchasesColumns }: { purchasesColumns?: column[] }) => {
  const classes = useStyles();

  // const [sortKey, setSortKey] = React.useState<any>(null);
  // const [order, setOrder] = React.useState<"asc" | "desc">("desc");

  // const getNewOrder = (columnKey: any) => {
  //   let newOrder: "asc" | "desc" = "asc";

  //   if (sortKey === null) {
  //     newOrder = "asc";
  //   } else {
  //     if (sortKey !== columnKey) {
  //       newOrder = "asc";
  //     } else {
  //       newOrder = order === "asc" ? "desc" : "asc";
  //     }
  //   }

  //   return newOrder;
  // };

  // const onSort = (columnKey: any) => {
  //   setSortKey(columnKey);
  //   const newOrder = getNewOrder(columnKey);
  //   setOrder(newOrder);
  //   const newSorting = newOrder === "asc" ? columnKey : `-${columnKey}`;

  //   setSorting(newSorting);
  // };

  const cols = purchasesColumns ?? columns;

  return (
    <Grid
      container
      style={{
        borderBottom: `2px solid ${palette.neutral[20]}`,
      }}
    >
      {cols.map((x, idx) => {
        return (
          <Grid
            textAlign={idx === cols.length - 1 ? "right" : "left"}
            key={x.key}
            item
            xs={x.size}
            padding={2}
          >
            <Text className={classes.sortBtn}>{x.title}</Text>
          </Grid>
        );
      })}
    </Grid>
  );
};

interface IRowProps {
  disallowReversalRequests: boolean;
  id: string;
  isLastRow: boolean;
  orderRecurringItemID: string;
  price: string;
  productName: string;
  productType: string;
  quantity: number;
  recurringCount: number;
  recurringInterval: string;
  recurringMax: number | null;
  sourceAccount: SourceAccount;
  unitPrice: string;
  purchasesColumns?: column[];
  hideRefund?: boolean;
  transactionStatus: string;
}

const Row = ({
  disallowReversalRequests,
  id,
  isLastRow,
  orderRecurringItemID,
  price,
  productName,
  productType,
  recurringCount,
  recurringInterval,
  recurringMax,
  sourceAccount,
  quantity,
  unitPrice,
  purchasesColumns,
  hideRefund,
  transactionStatus,
}: IRowProps) => {
  const data = [
    productName,
    productType,
    <RecurringComponent_V2
      key={id}
      recurringCount={recurringCount}
      recurringInterval={recurringInterval}
      recurringMax={recurringMax}
    />,
    `${unitPrice} USD x ${quantity}`,
    price,
  ];
  const cols = purchasesColumns ?? columns;
  const sizes = useMemo(() => cols.map((x) => x.size), []);

  return (
    <Grid
      key={id}
      container
      style={{
        borderBottom: !isLastRow ? `2px solid ${palette.neutral[20]}` : "none",
        padding: "0",
      }}
    >
      {data.map((x, i) => {
        const isLastGridCell = i === data.length - 1;

        return (
          <Grid
            key={`col=${i}`}
            item
            xs={sizes[i]}
            padding={2}
            style={{
              display: "flex",
              justifyContent: isLastGridCell ? "center" : "flex-start",
              alignItems: "center",
            }}
          >
            {typeof x === "object" ? (
              <Box
                style={{
                  paddingLeft: 0,
                  paddingRight: "8px",
                }}
              >
                {x}
              </Box>
            ) : (
              <Text
                textTransform="capitalize"
                textAlign={isLastGridCell ? "center" : "left"}
                key={x}
                sx={{ marginBlock: "auto" }}
              >
                {x}
              </Text>
            )}
          </Grid>
        );
      })}
      <Grid item xs={1} justifyContent="flex-end" padding={2}>
        <ActionsRow
          id={id}
          orderRecurringItemID={orderRecurringItemID}
          disallowReversalRequests={disallowReversalRequests}
          sourceAccount={sourceAccount}
          hideRefund={hideRefund}
          transactionStatus={transactionStatus}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  sortBtn: {
    display: "flex",
    alignItems: "center",
    background: "none",
    border: "none",
    color: palette.neutral[900],
    margin: 0,
    fontFamily: "Give Whyte,sans-serif",
    fontWeight: "400",
    width: "fit-content",
    padding: "4px 6px",
    paddingLeft: 0,
  },
});

export function parseAmount(amount: number) {
  return Math.fround(amount).toLocaleString(AMOUNTS_REGION, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
