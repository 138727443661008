import React from "react";
import { palette } from "@palette";
// mui
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import {
  Box,
  TableCell,
  Table as MuiTable,
  TableRow as MuiTableRow,
  TableHead,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import TableContainer from "@mui/material/TableContainer";
// components
import { Text } from "@common/Text";
import { TableRow } from "./TableRow";
import Pagination from "../pagination";
import { DropdownItem } from "@common/Select";
// assets
import { DownIcon, SortDownIcon, SortUpIcon, UpIcon } from "@assets/icons";
// helpers
import {
  TableColumnType,
  TableProps,
  TableData,
  TableOrder,
  stableSort,
  getComparator,
  SortLabelAsc,
  SortLabelDsc,
} from "./helpers";
// redux
import { useAppDispatch } from "@redux/hooks";
import {
  setTableSortingOrder,
  disableTableSortingOrder,
  setTableSortingOrderProperty,
  disableTableSortingOrderProperty,
} from "@redux/slices/app";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";

export const Table: React.FC<TableProps> = ({
  columns,
  expandable,
  small = false,
  page = 1,
  data,
  maxHeight,
  rowHeight,
  renderExpandedRow,
  iconButton,
  totalRecords,
  handlePageChange,
  hasPaginationWhenEmpty = true,
  withPagination,
  pinkBorder,
  pinkRowBorder,
  selectable,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sortKey, setSortKey] = React.useState<null | string>(null);
  const [columnLabel, setColumnLabel] = React.useState<undefined | string>();
  const open = Boolean(anchorEl);
  const [order, setOrder] = React.useState<TableOrder>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof TableData>("id");

  const handleOpenMenu =
    (column: TableColumnType) => (event: React.MouseEvent<HTMLElement>) => {
      setSortKey(column.key);
      setColumnLabel(column?.title?.toLowerCase());
      setAnchorEl(event.currentTarget.parentElement);
    };

  const createSortHandler =
    (property: any, order: TableOrder) =>
    (event: React.MouseEvent<HTMLElement>) => {
      setOrder(order);
      setOrderBy(property);
    };

  React.useEffect(() => {
    dispatch(setTableSortingOrder(order));
  }, [order]);

  React.useEffect(() => {
    dispatch(setTableSortingOrderProperty(orderBy));
  }, [orderBy]);

  React.useEffect(() => {
    return () => {
      dispatch(disableTableSortingOrder());
      dispatch(disableTableSortingOrderProperty());
    };
  }, []);

  const boxStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderImageSource:
      "linear-gradient(267.62deg, rgba(214, 217, 243, 0.46) 8.2%, rgba(255, 255, 255, 0.46) 82.68%)",
    "& th": {
      borderBottom: "none",
      //padding: "18px 16px",
    },
    boxShadow: withPagination
      ? "2px 2px 4px rgba(114, 142, 171, 0.1), -6px -6px 20px #FFFFFF, 4px 4px 20px rgba(200, 200, 200, 0.41)"
      : "none",
    background: withPagination
      ? "linear-gradient(308.48deg, #E3EDF7 2.36%, #FFFFFF 61.95%)"
      : "none",
    ...(!small && {
      padding: "0 8px 8px",
      borderRadius: "12px",
      "& th": {
        padding: "10px 16px",
      },
    }),
  };

  const containerStyle = {
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "::-webkit-scrollbar-track": {
      background: (theme: Theme) => theme.palette.common.white,
    },
    "&::-webkit-scrollbar-thumb": {
      border: "none",
    },
    ...(small && {
      mb: 2.5,
      padding: "0 4px 4px",
    }),
    ...(maxHeight && {
      maxHeight: maxHeight,
    }),

    ...(disabled && {
      position: "relative",

      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "#f8f8f8",
        zIndex: 1000,
        opacity: 0.5,
      },
    }),
  };

  return (
    <Box sx={boxStyle}>
      <TableContainer sx={containerStyle}>
        <MuiTable
          sx={{
            width: "100%",
            ...props.sx,
            borderCollapse: "separate",
            borderRadius: "8px",
          }}
          {...props}
        >
          <TableHead
            sx={{
              "& th": {
                borderBottom: "none",
                padding: "18px 16px",

                ...(small && {
                  padding: "8px 16px",
                }),
              },
            }}
          >
            <MuiTableRow>
              {columns.map((column) => {
                if (column.title) {
                  return (
                    <TableCell
                      component="th"
                      key={`head-${column.key}`}
                      sortDirection={orderBy === column.key ? order : false}
                    >
                      <Box className={classes.tableHead}>
                        <Text color={palette.neutral[600]}>{column.title}</Text>
                        {column.sortable && (
                          <>
                            <Box
                              className={classes.sortWrapper}
                              onClick={handleOpenMenu(column)}
                            >
                              <span>
                                <SortUpIcon
                                  fill={palette.neutral[400]}
                                  stroke={palette.neutral[400]}
                                  size={"8px"}
                                />
                              </span>
                              <span>
                                <SortDownIcon
                                  fill={palette.neutral[400]}
                                  stroke={palette.neutral[400]}
                                  size={"8px"}
                                />
                              </span>
                            </Box>

                            <Menu
                              open={open}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              sx={{
                                top: 20,
                                "& .MuiPaper-root": {
                                  width: "212px",
                                  height: "auto",
                                },
                              }}
                            >
                              <DropdownItem
                                onClick={createSortHandler(sortKey, "asc")}
                              >
                                <TableSortLabel hideSortIcon direction="asc">
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "flex",
                                      gap: "9px",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span style={{ marginRight: "2px" }}>
                                      <DownIcon color={palette.primary.main} />
                                    </span>

                                    <Text color={palette.neutral[800]}>
                                      {SortLabelAsc(columnLabel)}
                                    </Text>
                                  </Box>
                                </TableSortLabel>
                              </DropdownItem>
                              <DropdownItem
                                onClick={createSortHandler(sortKey, "desc")}
                              >
                                <TableSortLabel hideSortIcon direction="desc">
                                  <Box
                                    component="span"
                                    sx={{
                                      display: "flex",
                                      gap: "9px",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span style={{ marginRight: "2px" }}>
                                      <UpIcon color={palette.primary.main} />
                                    </span>
                                    <Text color={palette.neutral[800]}>
                                      {SortLabelDsc(columnLabel)}
                                    </Text>
                                  </Box>
                                </TableSortLabel>
                              </DropdownItem>
                            </Menu>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  );
                }
              })}
            </MuiTableRow>
          </TableHead>

          <TableBody
            className={
              pinkBorder
                ? classes.pinkTableBody
                : pinkRowBorder
                ? classes.pinkRowBorder
                : classes.tableBody
            }
          >
            {stableSort(data, getComparator(order, orderBy)).map((row, key) => (
              <TableRow
                height={rowHeight}
                expandable={expandable}
                columns={columns}
                iconButton={iconButton}
                rowData={row}
                key={`row-${key}`}
                renderExpandedRow={renderExpandedRow}
                pinkBorder={pinkBorder}
                pinkRowBorder={"1px solid #E1E1DE"}
                selectable={selectable}
              />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {withPagination && hasPaginationWhenEmpty && (
        <Pagination
          page={page}
          onChange={handlePageChange}
          numberOfPages={Math.ceil((totalRecords ?? 0) / ROWS_PER_PAGE)}
          totalRecords={totalRecords || data.length}
          currentRowsPerPage={totalRecords}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  tableBody: {
    position: "relative",

    zIndex: "1",

    "& > tr:not(:first-child):not(:last-child)": {
      borderTop: `1px solid ${palette.neutral[10]} !important`,
      borderLeft: `1px solid ${palette.neutral[10]} !important`,
      borderRight: `1px solid ${palette.neutral[10]} !important`,
      border: `1px solid ${palette.neutral[10]}`,
    },

    "& tr:first-child td:first-child": {
      borderTop: `1px solid ${palette.neutral[10]}`,
      borderLeft: `1px solid ${palette.neutral[10]}`,
      borderLeftTopRadius: "8px !important",
      borderRightRopRadius: "8px !important",
    },

    "& not(tr:first-child) td:last-child": {
      borderRight: `1px solid ${palette.neutral[10]} !important`,
    },
    "& tr:first-child td:last-child": {
      borderBottomRightRadius: "0px",
      borderLeftBottomRadius: "8px",
      borderRightTopRadius: "8px !important",
      borderTopRightRadius: "8px !important",
      borderTop: `1px solid ${palette.neutral[10]}`,
      borderRight: `1px solid ${palette.neutral[10]}`,
    },
    "& tr:last-child td:last-child": {
      borderBottomRightRadius: "8px",
      borderRightTopRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderRight: `1px solid ${palette.neutral[10]}`,
    },
    "& not:(tr:last-child)": {
      borderBottomRightRadius: "8px",
      borderRightTopRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderRight: `1px solid ${palette.neutral[10]} !important`,
    },
    "&  td:last-child": {
      borderRight: `1px solid ${palette.neutral[10]} !important`,
    },
    "& tr:first-child td": {
      borderTop: `1px solid ${palette.neutral[10]} !important`,
    },

    "& tr:first-child not:(td:last-child)": {
      borderBottomRightRadius: "0px",
      borderLeftBottomRadius: "8px",
      borderRightTopRadius: "0px !important",
      borderRight: `1px solid ${palette.neutral[10]}`,
    },
    "& tr:not(tr:last-child) td:first-child": {
      borderLeft: `1px solid ${palette.neutral[10]}`,
      borderTopLeftRadius: "8px",
    },
    "& tr:not(tr:first-child) td:first-child": {
      borderLeft: `1px solid ${palette.neutral[10]}`,
      borderTopLeftRadius: "0px",
    },
    "& tr:last-child td:first-child": {
      borderLeft: `1px solid ${palette.neutral[10]}`,
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "8px",
    },
    "& tr:not(tr:last-child)": {
      borderLeft: `1px solid ${palette.neutral[10]}`,
    },
    "& tr:not(tr:last-child) not:(td:last-child)": {
      borderRight: `1px solid ${palette.neutral[10]}`,
      borderTopLeftRadius: "0px",
    },

    // border Top for last child
    "& tr:last-child": {
      borderLeft: `1px solid ${palette.neutral[10]}`,
      borderRight: `1px solid ${palette.neutral[10]}`,
    },
  },

  tableHead: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
  },
  sortWrapper: {
    cursor: "pointer",
    "& span": {
      display: "flex",
      "& :hover": {
        opacity: 0.98,
      },
    },
  },

  pinkTableBody: {
    position: "relative",
    borderRadius: "8px",
    zIndex: "1",
    borderSize: "3px !important",
    "& tr:first-child td:first-child": {
      borderTop: "2px solid rgba(234, 221, 253, 1) !important",
      borderLeft: "2px solid rgba(234, 221, 253, 1) !important",
      borderTopLeftRadius: "0px",
      borderRightTopRadius: "0px !important",
    },
    "& tr:first-child td:last-child": {
      borderTop: "2px solid rgba(234, 221, 253, 1) !important",
      borderRight: "2px solid rgba(234, 221, 253, 1) !important",
      borderTopRightRadius: "8px",
    },
    "& tr:last-child td:first-child": {
      borderBottom: "2px solid rgba(234, 221, 253, 1) !important",
      borderLeft: "2px solid rgba(234, 221, 253, 1) !important",
      borderBottomLeftRadius: "8px",
      borderRightTopRadius: "0px !important",
    },
    "& tr:last-child td:last-child": {
      borderBottomRightRadius: "8px",
      borderBottom: "2px solid rgba(234, 221, 253, 1) !important",
      borderRight: "2px solid rgba(234, 221, 253, 1) !important",
    },
    "& > tr:not(:first-child):not(:last-child)": {
      borderTop: `1px solid ${palette.primary[100]} !important`,
      borderLeft: `1px solid ${palette.primary[100]} !important`,
      borderRight: `1px solid ${palette.primary[100]} !important`,
      border: `1px solid ${palette.primary[100]}`,
    },

    "& not(tr:first-child) td:last-child": {
      borderRight: "2px solid rgba(234, 221, 253, 1) !important",
      borderLeft: "2px solid rgba(234, 221, 253, 1) !important",
    },

    "& not:(tr:last-child)": {
      borderBottomRightRadius: "8px",
      borderRightTopRadius: "0px !important",
      borderTopRightRadius: "0px !important",
      borderRight: "2px solid rgba(234, 221, 253, 1) !important",
      borderLeft: "2px solid rgba(234, 221, 253, 1) !important",
    },
    "&  td:last-child": {
      borderRight: "2px solid rgba(234, 221, 253, 1) !important",
    },
    "&  td:first-child": {
      borderLeft: "2px solid rgba(234, 221, 253, 1) !important",
    },
    "& tr:first-child td": {
      borderTop: "2px solid rgba(234, 221, 253, 1) !important",
    },
    "& tr:last-child td": {
      borderBottom: "2px solid rgba(234, 221, 253, 1) !important",
    },

    "& tr:first-child not:(td:last-child)": {
      borderBottomRightRadius: "0px",
      borderLeftBottomRadius: "8px",
      borderRightTopRadius: "0px !important",
      borderRight: `1px solid ${palette.primary[100]}`,
    },
    "& tr:not(tr:last-child) td:first-child": {
      borderLeft: `1px solid ${palette.primary[100]}`,
      borderTopLeftRadius: "8px",
    },
    "& tr:not(tr:first-child) td:first-child": {
      borderLeft: `1px solid ${palette.primary[100]}`,
      borderTopLeftRadius: "0px",
    },
  },
  pinkRowBorder: {
    position: "relative",
    borderRadius: "8px",

    zIndex: "1",
    border: "2px solid rgba(234, 221, 253, 1)",

    "& tr:not(:first-child)": {
      borderTop: `1px solid ${palette.primary[100]}`,
      borderCollapse: "collapse",
    },
    "& tr:first-child td:first-child": {
      borderTopLeftRadius: "8px",
      borderRightTopRadius: "0px !important",

      borderTop: `1px solid ${palette.primary[100]}`,
      borderLeft: `1px solid ${palette.primary[100]}`,

      borderLeftTopRadius: "8px !important",
      borderRightRopRadius: "8px !important",
    },
    "& tr:first-child td:last-child": {
      borderTopRightRadius: "8px",
    },
    "& tr:last-child td:first-child": {
      borderBottomLeftRadius: "8px",
      borderRightTopRadius: "0px !important",
    },
    "& tr:last-child td:last-child": {
      borderBottomRightRadius: "8px",
      borderRightTopRadius: "0px !important",
    },
  },
});
