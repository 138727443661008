import { customInstance } from "@services/api";
import { useQuery } from "react-query";

export type MerchantStatusType = {
  displayName: string;
  id: number;
  name: string;
  commonName: string;
  isActive: boolean;
  isPending: boolean;
  sortOrder: number;
};

export const useGetMerchantStatuses = () => {
  const getMerchantStatuses = () => {
    return customInstance({
      method: "GET",
      url: `/merchant-statuses`,
    });
  };

  return useQuery("get-merchant-statuses", async () => {
    const data: { total: number; data: MerchantStatusType[] } =
      await getMerchantStatuses();

    return data;
  });
};
