import React, { useMemo } from "react";
import { Box, SxProps, FormControl, FormGroup } from "@mui/material";
import PaymentCard, { PaymentCardProps } from "./PaymentCard";
import { VisualIndicator } from "@assets/icons";
import { CustomAmountInputType } from "@common/PublicForm/types";
import { Text } from "@common/Text";
import { CartItemType, selectCart } from "@redux/slices/cart";
import { useAppSelector } from "@redux/hooks";
import { palette } from "@palette";
import { buttonLabelsMap } from "@common/PublicForm/CheckoutWrapper";

type RadioPaymentCardProps = PaymentCardProps & Partial<CustomAmountInputType> & {
  isChecked: boolean,
  onChange?: (id: string) => void,
  name?: string,
  radioSx?: SxProps,
  ticketId?: string,
  display?: boolean,
  disabled?: boolean,
  isDesktop?: boolean,
  numberID: number,
  quantity: number,
  typeName: keyof typeof buttonLabelsMap
}

const RadioPaymentCard = ({
  isChecked,
  onChange,
  name,
  radioSx,
  display,
  isDesktop,
  numberID,
  quantity,
  ...props
}: RadioPaymentCardProps) => {
  const { cartItems } = useAppSelector(selectCart);

  const currentAmount = useMemo(() => {
    return cartItems.find((item: CartItemType) => item.productVariantID === numberID)?.quantity || 0
  }, [cartItems])

  const backgroundColor = React.useMemo(() => {
    const qLeft = quantity - currentAmount;
    switch (true) {
      case qLeft < 21 && qLeft > 0:
        return {
          backgroundColor: "#FFE8DA80",
          color: "#FF8124",
        };
      case qLeft < 1:
        return {
          backgroundColor: "#E1E1DE",
          color: "#575353",
        };
      default:
        return {
          backgroundColor: palette.success.light,
          color: palette.success.main,
        };
    }
  }, [currentAmount]);

  return (
    <PaymentCard
      selected={isChecked}
      onChange={onChange}
      name={name}
      actions={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormControl
            fullWidth
            component="fieldset"
            onClick={
              (event: React.MouseEvent<HTMLElement>) => event.stopPropagation()
            }
          >
            <FormGroup>
              <VisualIndicator width={24} height={24} />
            </FormGroup>
          </FormControl>
        </Box>
      }
      quantityLeftComponent={
        display &&
        !props.disabled && (
          <Text
            sx={{
              textAlign: "center",
              color: backgroundColor.color,
              backgroundColor: backgroundColor.backgroundColor,
              borderRadius: "4px",
              paddingInline: "12px",
              minWidth: "80px",
              marginY: isDesktop ? "initial" : 0,
            }}
            my={2}
            data-testid={`ticket-${props.ticketId}-left-amount`}
          >
            {quantity - currentAmount > 0
              ? quantity - currentAmount + " left"
              : "Sold Out"}
          </Text>
        )
      }
      {...props}
    />

  )
}

export default RadioPaymentCard;
