import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TableOrder, TableData } from "@common/Table/helpers";
import { Role } from "@hooks/common/useUser";

export type MasqueradeModeType = {
  id: number | "";
  name: string;
  origin: string;
  imgSrc: string;
  type?: string;
  masqueradeUserRole: Role | "";
  historyControlMode?: any[];
  firstName?: string;
};

const localMasquerade = localStorage.getItem("masquerade-mode");
const initialMasqueradeState = localMasquerade
  ? JSON.parse(localMasquerade)
  : {
      id: "",
      name: "",
      origin: "",
      imgSrc: "",
      type: "",
      masqueradeUserRole: "",
      historyControlMode: [],
      firstName: "",
    };

export interface AppState {
  drawerOpen: boolean;
  mobileDrawerOpen: boolean;
  tableSelected: {
    [key: string]: readonly string[];
  };
  drawerHidden: boolean;
  tickerOn: boolean;
  masqueradeMode: MasqueradeModeType;
  tableSortingOrder: TableOrder;
  tableSortingOrderProperty: keyof TableData;
  permissions: any;
  ui: {
    onboarding: "" | "profile" | "campaign";
  };
  enabledVL: boolean;
  hideTableFilter: boolean;
}

const initialState: AppState = {
  drawerOpen: false,
  mobileDrawerOpen: false,
  tableSelected: {
    email: [],
  },
  drawerHidden: false,
  tickerOn: true,
  masqueradeMode: initialMasqueradeState,
  tableSortingOrder: "asc",
  tableSortingOrderProperty: "id",
  permissions: {},
  ui: {
    onboarding: "",
  },
  enabledVL: true,
  hideTableFilter: false,
};

type Selected = {
  name: string;
  values: readonly string[];
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDrawerOpen: (
      state: AppState,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.drawerOpen = action.payload ?? !state.drawerOpen;
    },
    setSelected: (state: AppState, action: PayloadAction<Selected>) => {
      state.tableSelected[action.payload.name] = action.payload.values;
    },
    hideDrawer: (state: AppState, action: PayloadAction<boolean>) => {
      state.drawerHidden = action.payload;
    },
    setMobileDrawerOpen: (
      state: AppState,
      action: PayloadAction<boolean | undefined>,
    ) => {
      state.mobileDrawerOpen = action.payload ?? !state.mobileDrawerOpen;
    },
    switchTicker: (state: AppState, action: PayloadAction<boolean>) => {
      state.tickerOn = action.payload;
    },
    setMasqueradeMode: (
      state: AppState,
      action: PayloadAction<MasqueradeModeType>,
    ) => {
      state.masqueradeMode = action.payload;
      localStorage.setItem("masquerade-mode", JSON.stringify(action.payload));
    },
    setMasqueradedName: (
      state: AppState,
      action: PayloadAction<string>,
    ) => {
      state.masqueradeMode = { ...state.masqueradeMode, name: action.payload }
      localStorage.setItem("masquerade-mode", JSON.stringify(state.masqueradeMode));
    },
    resetMasqueradeState: (state: AppState) => {
      state.masqueradeMode = initialMasqueradeState;
    },
    updateMasqueradeImage: (
      state: AppState,
      action: PayloadAction<{ img: string }>,
    ) => {
      state.masqueradeMode.imgSrc = action.payload.img;
      localStorage.setItem(
        "masquerade-mode",
        JSON.stringify({
          ...state.masqueradeMode,
          imgSrc: action.payload.img,
        }),
      );
    },
    setTableSortingOrder: (
      state: AppState,
      action: PayloadAction<TableOrder>,
    ) => {
      state.tableSortingOrder = action.payload;
    },
    disableTableSortingOrder: (state: AppState) => {
      state.tableSortingOrder = "asc";
    },
    setTableSortingOrderProperty: (
      state: AppState,
      action: PayloadAction<keyof TableData>,
    ) => {
      state.tableSortingOrderProperty = action.payload;
    },
    disableTableSortingOrderProperty: (state: AppState) => {
      state.tableSortingOrderProperty = "id";
    },
    updatePermissions: (state: AppState, action: PayloadAction<any>) => {
      if (action.payload.reset) {
        return { ...state, permissions: initialState.permissions };
      }
      state.permissions === Object.assign(state.permissions, action.payload);
    },
    setOnboardingScreen: (state: AppState, action: PayloadAction<any>) => {
      state.ui.onboarding = action.payload.screen;
    },
    toggleVirtualList: (state: AppState, action: PayloadAction<any>) => {
      state.enabledVL = action.payload;
    },
    toggleTableFilter: (state: AppState, action: PayloadAction<any>) => {
      state.hideTableFilter = action.payload;
    },
  },
});

export const {
  setDrawerOpen,
  setSelected,
  hideDrawer,
  setMobileDrawerOpen,
  switchTicker,
  setMasqueradeMode,
  setMasqueradedName,
  setTableSortingOrder,
  disableTableSortingOrder,
  setTableSortingOrderProperty,
  disableTableSortingOrderProperty,
  updateMasqueradeImage,
  updatePermissions,
  resetMasqueradeState,
  setOnboardingScreen,
  toggleVirtualList,
  toggleTableFilter,
} = appSlice.actions;

export const selectDrawerOpen = (state: RootState) => state.app.drawerOpen;
export const selectMobileDrawerOpen = (state: RootState) =>
  state.app.mobileDrawerOpen;
export const selectTableSelected = (state: RootState) =>
  state.app.tableSelected;
export const selectDrawerHidden = (state: RootState) => state.app.drawerHidden;
export const selectTicker = (state: RootState) => state.app.tickerOn;
export const selectMasqueradeMode = (state: RootState) =>
  state.app.masqueradeMode;

export const selectMasqueradeModeHistory = (state: RootState) =>
  state.app.masqueradeMode.historyControlMode;

export const selectTableSortingOrder = (state: RootState) =>
  state.app.tableSortingOrder;
export const selectSortingOrderProperty = (state: RootState) =>
  state.app.tableSortingOrderProperty;

export const selectModals = (state: RootState) => state.modals;

export default appSlice.reducer;

export const generateMockedAppState = (customState?: Partial<AppState>) => {
  return {
    ...initialState,
    ...customState,
    masqueradeMode: {
      ...initialState.masqueradeMode,
      ...customState?.masqueradeMode,
    },
  };
};
