type InfinityIconProps = {
  color?: string;
  width?: number;
  height?: number;
};

const InfinityIcon = ({
  color = "#9C9AA3",
  width = 24,
  height = 24,
}: InfinityIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8532 14.5138L9.58456 14.8284C9.04512 15.3878 8.35785 15.7688 7.60963 15.9231C6.86142 16.0775 6.08588 15.9983 5.38108 15.6955C4.67628 15.3928 4.07387 14.8801 3.65005 14.2223C3.22622 13.5645 3 12.7911 3 12C3 11.2089 3.22622 10.4355 3.65005 9.77772C4.07387 9.11992 4.67628 8.60723 5.38108 8.30448C6.08588 8.00173 6.86142 7.92252 7.60963 8.07686C8.35785 8.2312 9.04512 8.61216 9.58456 9.17157L14.4154 14.8284C14.9549 15.3878 15.6422 15.7688 16.3904 15.9231C17.1386 16.0775 17.9141 15.9983 18.6189 15.6955C19.3237 15.3928 19.9261 14.8801 20.35 14.2223C20.7738 13.5645 21 12.7911 21 12C21 11.2089 20.7738 10.4355 20.35 9.77772C19.9261 9.11992 19.3237 8.60723 18.6189 8.30448C17.9141 8.00173 17.1386 7.92252 16.3904 8.07686C15.6422 8.2312 14.9549 8.61216 14.4154 9.17157L14.1468 9.48616"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfinityIcon;
