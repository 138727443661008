import { customInstance } from ".";
import { buildMerchantEndpoints } from "./utils.api";

type TOptions = {
  filter: string;
};

export const getLegalEntityByID = (
  id: number,
  merchantId?: number,
  options?: Partial<TOptions>,
) => {
  const url = addOptionsToURL(`legal-entities/${id}`, options);

  return customInstance({
    url: buildMerchantEndpoints(url, merchantId),
    method: "GET",
  });
};

export const getLegalEntities = (merchantId?: number, options?: TOptions) => {
  const url = addOptionsToURL(`legal-entities`, options);

  return customInstance({
    url: buildMerchantEndpoints(url, merchantId),
    method: "GET",
  });
};

const addOptionsToURL = (baseURL: string, options?: Partial<TOptions>) => {
  let URL = baseURL;
  if (options) {
    URL += "?";
    Object.keys(options).forEach(
      (option) => (URL += `&${option}=${options[option as keyof TOptions]}`),
    );
  }
  return URL;
};

export const updateLegalEntity = (id: number, data: any) =>
  customInstance({
    url: buildMerchantEndpoints(`legal-entities/${id}`),
    method: "PATCH",
    data: data,
  });
