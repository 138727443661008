import { RHFInput } from "@common/Input";
import { RHFInputProps } from "@common/Input/Input";

const MultiLineInput = ({
  name,
  label,
  placeholder,
  rows,
  inputProps,
  helperText,
  ...rest
}: RHFInputProps) => {
  return (
    <>
      <RHFInput
        name={name}
        label={label}
        placeholder={placeholder}
        multiline
        rows={rows}
        fullWidth
        inputProps={{
          ...inputProps,
        }}
        helperText={helperText}
        {...rest}
      />
    </>
  );
};

export default MultiLineInput;
