import React, { useState, useEffect, useRef } from "react";
import { useFormData } from "./SignupFormProvider";
import { Text } from "@common/Text";
import { Box, Stack } from "@mui/material";
import { CustomProgressbar } from "@components/CustomProgressbar";

const getTextValues = (email: string) => [
  <>Initializing Secure Enrollment</>,
  <>Finalizing Account Setup</>,
  <>
    Account created <br /> <strong> {email} </strong> <br /> Please check your
    email, as well as spam and junk folders and click on the activation link
  </>,
];

const totalDuration = 5000;

const SignupWelcome = () => {
  const { formData } = useFormData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [show, setShow] = useState(true);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef<any>(null);
  const textValues = getTextValues(formData.personalDetails.email);
  const timePerText = totalDuration / textValues.length;
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setShow(false);
    }, timePerText);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [currentIndex]);

  useEffect(() => {
    if (currentIndex === textValues.length - 1) {
      clearInterval(intervalRef.current);
    } else {
      const fadeTimeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % textValues.length);
        setShow(true);
      }, timePerText);

      return () => {
        clearTimeout(fadeTimeout);
      };
    }
  }, [currentIndex]);

  useEffect(() => {
    let elapsedTime = 0;
    const total = (totalDuration / 4) * 2;
    const timer = setInterval(() => {
      elapsedTime += 100;
      const newProgress = (elapsedTime / total) * 100;

      setProgress(Math.min(newProgress, 100));

      if (elapsedTime >= total) {
        clearInterval(timer);
      }
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Text
        variant="h4"
        sx={{
          transition: "opacity 0.5s ease",
          opacity: show ? 1 : 0,
          textAlign: "center",
          whiteSpace: "pre-line",
          width: "100%",
          fontSize: {
            xs: "18px",
            sm: "22px",
            md: "28px",
          },
        }}
      >
        {textValues[currentIndex]}
      </Text>

      {currentIndex !== textValues.length - 1 && (
        <Stack mt="16px" alignItems="center" width="100dvw">
          <Box bgcolor="red" sx={{ width: "70%", maxWidth: "400px" }}>
            <CustomProgressbar targetProgress={progress} />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default SignupWelcome;
