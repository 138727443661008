import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

type IProps = SvgIconProps & {
  width?: number | string;
  height?: number | string;
  stroke?: string;
};

export const CheckIcon = ({
  width = 20,
  height = 20,
  fill = "#003182",
}: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.463 5.18914C16.7277 5.4475 16.7349 5.87361 16.479 6.14088L9.954 12.9558C9.6981 13.2231 9.27605 13.2303 9.01132 12.9719C8.7466 12.7136 8.73945 12.2874 8.99535 12.0202L15.5203 5.20527C15.7762 4.938 16.1983 4.93078 16.463 5.18914ZM3.54604 9.79523C3.81585 9.54229 4.23767 9.55806 4.48821 9.83046L8.20249 13.8689C8.45302 14.1413 8.4374 14.5672 8.16759 14.8201C7.89779 15.0731 7.47597 15.0573 7.22543 14.7849L3.51115 10.7465C3.26061 10.4741 3.27624 10.0482 3.54604 9.79523Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckIcon;
