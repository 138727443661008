import React, { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomAmountInput, { CustomAmountInputProps } from "./CustomAmountInput";

type RHFCustomAmountInputProps = CustomAmountInputProps & {
  name: string;
  width?: string | number;
  helper?: string | React.ReactNode;
  focusViewColor?: string;
  isDirty?: boolean;
  value?: string;
};

const RHFCustomAmountInput = ({
  name,
  width,
  helper,
  label,
  ...props
}: RHFCustomAmountInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        return (
          <CustomAmountInput
            label={label || "Amount"}
            error={!!error}
            helperText={helper ? helper : (error?.message as string)}
            {...rest}
            {...props}
          />
        );
      }}
    />
  );
};

export default memo(RHFCustomAmountInput);
