// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const CalendarIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.04548 3.40036C9.37685 3.40036 9.64548 3.66899 9.64548 4.00036V5.21854H14.3546V4.00036C14.3546 3.66899 14.6232 3.40036 14.9546 3.40036C15.2859 3.40036 15.5546 3.66899 15.5546 4.00036V5.21854H17.6818C19.0174 5.21854 20.1 6.3012 20.1 7.63672V17.1822C20.1 18.5177 19.0174 19.6004 17.6818 19.6004H6.31821C4.98268 19.6004 3.90002 18.5177 3.90002 17.1822V7.63672C3.90002 6.3012 4.98268 5.21854 6.31821 5.21854H8.44548V4.00036C8.44548 3.66899 8.71411 3.40036 9.04548 3.40036ZM8.44548 6.41854H6.31821C5.64542 6.41854 5.10002 6.96394 5.10002 7.63672V9.764L7.46293 9.764C7.7943 9.764 8.06293 10.0326 8.06293 10.364C8.06293 10.6954 7.7943 10.964 7.46293 10.964L5.10002 10.964V17.1822C5.10002 17.855 5.64542 18.4004 6.31821 18.4004H17.6818C18.3546 18.4004 18.9 17.855 18.9 17.1822V10.964L11.024 10.964C10.6926 10.964 10.424 10.6954 10.424 10.364C10.424 10.0326 10.6926 9.764 11.024 9.764L18.9 9.764V7.63672C18.9 6.96394 18.3546 6.41854 17.6818 6.41854H15.5546V7.18218C15.5546 7.51355 15.2859 7.78218 14.9546 7.78218C14.6232 7.78218 14.3546 7.51355 14.3546 7.18218V6.41854H9.64548V7.18218C9.64548 7.51355 9.37685 7.78218 9.04548 7.78218C8.71411 7.78218 8.44548 7.51355 8.44548 7.18218V6.41854Z"
          fill="url(#paint0_linear_304_1619)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_304_1619"
            x1="18.9235"
            y1="4.99057"
            x2="-0.419401"
            y2="17.7923"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04548 3.40036C9.37685 3.40036 9.64548 3.66899 9.64548 4.00036V5.21854H14.3546V4.00036C14.3546 3.66899 14.6232 3.40036 14.9546 3.40036C15.2859 3.40036 15.5546 3.66899 15.5546 4.00036V5.21854H17.6818C19.0174 5.21854 20.1 6.3012 20.1 7.63672V17.1822C20.1 18.5177 19.0174 19.6004 17.6818 19.6004H6.31821C4.98268 19.6004 3.90002 18.5177 3.90002 17.1822V7.63672C3.90002 6.3012 4.98268 5.21854 6.31821 5.21854H8.44548V4.00036C8.44548 3.66899 8.71411 3.40036 9.04548 3.40036ZM8.44548 6.41854H6.31821C5.64542 6.41854 5.10002 6.96394 5.10002 7.63672V9.764L7.46293 9.764C7.7943 9.764 8.06293 10.0326 8.06293 10.364C8.06293 10.6954 7.7943 10.964 7.46293 10.964L5.10002 10.964V17.1822C5.10002 17.855 5.64542 18.4004 6.31821 18.4004H17.6818C18.3546 18.4004 18.9 17.855 18.9 17.1822V10.964L11.024 10.964C10.6926 10.964 10.424 10.6954 10.424 10.364C10.424 10.0326 10.6926 9.764 11.024 9.764L18.9 9.764V7.63672C18.9 6.96394 18.3546 6.41854 17.6818 6.41854H15.5546V7.18218C15.5546 7.51355 15.2859 7.78218 14.9546 7.78218C14.6232 7.78218 14.3546 7.51355 14.3546 7.18218V6.41854H9.64548V7.18218C9.64548 7.51355 9.37685 7.78218 9.04548 7.78218C8.71411 7.78218 8.44548 7.51355 8.44548 7.18218V6.41854Z"
        fill={fill}
      />
    </svg>
  );
};

export default CalendarIcon;
