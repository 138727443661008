import * as React from "react";
// mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// components
import { FundraisersData } from "types/data.types";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useTargetRef } from "@hooks/common/useObserveHTMLNode";
import Pagination from "@common/pagination";
import { CardHeaderWithSort } from "@common/CampaignCard/CardHeaderWithSort";
import { CampaignSearch } from "@common/CampaignSearch";
import CampaignsKotoWrapper from "@common/CampaignsWrapper";
import { QKEY_LIST_PAYMENT_FORMS } from "@constants/queryKeys";
import { SearchBar } from "@common/SearchBar_V2";
import { BaseLink } from "@common/Campaigns/BaseLink";
import { BaseCampaignCard } from "@common/Campaigns/BaseCampaignCard";
import { useOpenCampaignPanel } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import { MobileListWithStates } from "@common/MobileList";
import CampaignListWrapperWithStates from "@common/Campaigns/CampaignListWrapperWithStates";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import UnfoldListItems from "@components/animation/UnfoldListItems";
import { IColumn } from "@common/Campaigns/campaigns.types";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import PaymentFormsListFilters from "./PaymentFormsListFilters";
import PaymentFormListItem from "./PaymentFormListItem";

const columns: IColumn[] = [
  {
    key: "name",
    title: "Title",
    sortable: true,
    size: 3,
    type: "title",
  },
  {
    key: "createdAt",
    title: "Created",
    sortable: true,
    size: 2,
  },
  {
    key: "sumTransactions",
    title: "Amount (USD)",
    sortable: true,
    size: 1,
  },
  {
    key: "conversionRate",
    title: "Conversion",
    sortable: true,
    size: 1,
  },
  {
    key: "averageTransactions",
    title: "Average (USD)",
    sortable: true,
    size: 1,
  },
  {
    key: "totalTransactions",
    title: "Transactions",
    sortable: true,
    size: 1,
  },
  {
    key: "visitors",
    title: "Visitors",
    sortable: true,
    size: 1,
  },
];

interface PaymentFormsListProps {
  currentPageRows: FundraisersData[];
  allRows: FundraisersData[];
  page: number;
  loadingRef: any;
  isLoading: boolean;
  totalRows: number;
  setPage: () => void;
  setPageDispatcher: React.Dispatch<React.SetStateAction<number>>;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  LoadingSkeleton?: React.ComponentType;
}

const PaymentFormsList = ({
  currentPageRows,
  handlePageChange,
  page,
  totalRows,
  setPage,
  allRows,
  loadingRef,
  setPageDispatcher,
  isLoading,
  LoadingSkeleton,
}: PaymentFormsListProps) => {
  const { openSidePanel, selectedRowIdx } = useOpenCampaignPanel({
    allRows,
    isLoading,
    page,
    setPage: setPageDispatcher,
    totalRows,
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const targetRef = useTargetRef({ page, totalRows, setPage, loadingRef });

  return (
    <CampaignsKotoWrapper>
      <CampaignSearch
        title="Payment Forms list"
        transparent={false}
        searchBar={
          <SearchBar
            queryKey={QKEY_LIST_PAYMENT_FORMS}
            onMouseLeave
            onMouseEnter
          />
        }
        filters={isDesktop && <PaymentFormsListFilters />}
      />
      {isDesktop ? (
        <Stack gap={2} flexGrow={1}>
          <CardHeaderWithSort columns={columns} />
          <ErrorCatcher errorID="CampaignListWrapperWithStates">
            <CampaignListWrapperWithStates
              queryKey={QKEY_LIST_PAYMENT_FORMS}
              isLoading={isLoading}
              length={currentPageRows?.length}
              itemsPerPage={ROWS_PER_PAGE}
              columns={columns}
            >
              <UnfoldListItems
                items={currentPageRows}
                renderKey={(data) => data.id}
                animationName="waterfall"
                ItemComponent={(data, index, height) => (
                  <PaymentFormListItem
                    data={data}
                    columns={columns}
                    openSidePanel={openSidePanel(data.id, index, data.name)}
                    isSelected={selectedRowIdx === index}
                    height={height}
                  />
                )}
              />
            </CampaignListWrapperWithStates>
          </ErrorCatcher>
          {totalRows > ROWS_PER_PAGE && (
            <Box paddingY="25px" paddingX={0} width="85%" margin="0 auto">
              <Pagination
                noTrailing
                page={page}
                currentRowsPerPage={currentPageRows?.length || 0}
                onChange={handlePageChange}
                totalRecords={totalRows}
                numberOfPages={Math.ceil(totalRows / ROWS_PER_PAGE)}
              />
            </Box>
          )}
        </Stack>
      ) : (
        <ErrorCatcher errorID="MobileListWithStates">
          <MobileListWithStates
            queryKey={QKEY_LIST_PAYMENT_FORMS}
            length={allRows?.length}
            targetRef={targetRef}
            isLoading={isLoading}
            sx={{ mt: "20px" }}
            LoadingSkeleton={LoadingSkeleton}
          >
            {allRows?.map((data: any, idx: number) => (
              <BaseLink
                key={data.id}
                link={`/merchant/payment-forms/${data.id}`}
              >
                <FadeUpWrapper delay={50 + idx * 50}>
                  <BaseCampaignCard name={data.name} imageURL={data.imageURL} />
                </FadeUpWrapper>
              </BaseLink>
            ))}
          </MobileListWithStates>
        </ErrorCatcher>
      )}
    </CampaignsKotoWrapper>
  );
};

export default PaymentFormsList;
