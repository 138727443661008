import { Radio } from "@common/Radio";
import { Text } from "@common/Text";
import { Box, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useFormContext } from "react-hook-form";

const ApiKeyEnvironmentInput = () => {
  const { watch, setValue } = useFormContext();

  const radios = [
    {
      title: "Sandbox",
      description: `Use this environment for testing and development purposes. Data in the sandbox environment is not live and won't affect your data`,
    },
    {
      title: "Production",
      description: `Choose this environment when you're ready to deploy your application or integrate with live data. This environment is suitable for your production-ready applications`,
    },
  ];

  return (
    <Stack spacing={1.5}>
      <Text color={palette.neutral[70]}>Environment</Text>
      <Stack spacing={1}>
        {radios.map((radio) => (
          <CustomRadio key={radio.title}>
            <Radio
              title={radio.title}
              data-testid={`api-key-${radio.title}-input`}
              helper={radio.description}
              checked={radio.title.toLowerCase() === watch("environment")}
              onChange={() =>
                setValue("environment", radio.title.toLowerCase())
              }
            />
          </CustomRadio>
        ))}
      </Stack>
    </Stack>
  );
};

const CustomRadio = styled(Box)(() => ({
  "& .MuiStack-root p:first-of-type": {
    color: palette.neutral[80],
    fontWeight: 350,
    fontSize: 14,
    lineHeight: "16.8px",
  },
  "& .MuiStack-root p:last-child": {
    color: palette.neutral[70],
    fontWeight: 350,
    fontSize: 12,
    lineHeight: "14.4px",
  },
}));

export default ApiKeyEnvironmentInput;
