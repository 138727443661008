import { rgbToHex } from "@mui/material";
import { Signal } from "@preact/signals-react";
import { cloneDeep, mergeWith } from "lodash";
import { nodeDeviceTypePropertiesMap } from "../NodeDeviceTypePropertiesMap";
import { headingsSignal, mobileHeadingsSignal } from "../consts/headings";
import { widgets } from "../consts/widgets";
import { widgetFactory } from "../consts/widgets/widgetFactory";
import {
  DeviceType,
  globalButtonStyles,
  globalColors,
  isDraftEnabled,
  isPublishEnabled,
  selectedDeviceType,
  selectedItem,
  structuredTreeSignal,
} from "../signals";
import { composeWidget, widgetsBuilderFromNode } from "./builders";
import {
  getDeviceSpecificKey,
  getDeviceTypeAttribute,
  getDeviceTypeAttributesToUpdate,
  getDeviceTypePropertyName,
} from "./helpers";

export function findNodeByKey(node: any, key: any): any {
  if (!node) return null;
  if (node.key === key) return node;
  if (node.childrens) {
    for (const child of node.childrens) {
      const result = findNodeByKey(child, key);
      if (result) return result;
    }
  }
  return null;
}

function swapNodePosition(node: any, currentIndex: any, targetIndex: any) {
  if (!node) return null;
  if (node.childrens) {
    const tempNode = node.childrens[currentIndex];
    node.childrens[currentIndex] = node.childrens[targetIndex];
    node.childrens[targetIndex] = tempNode;
  }

  return null;
}

function findAllNodesByTagName(
  node: any,
  tagName: string,
  newProperties: any,
  isMobileStyle?: boolean,
): any[] {
  const matchingNodes: any[] = [];
  if (!node) return matchingNodes;

  const tagKeys = ["tag", "tabletTag", "mobileTag"];
  const tagMatches = tagKeys.some(
    (key) => node?.[key]?.toLowerCase() === tagName.toLowerCase(),
  );

  if (tagMatches) {
    for (const key in newProperties) {
      if (key.toLowerCase().includes("metaattributes")) continue;
      const newStyles = Object.keys(newProperties[key]);

      // For typography mobile styles
      if (isMobileStyle) {
        const mobileStyleDeviceTypes = ["tablet", "mobile"];
        mobileStyleDeviceTypes.forEach((val: string) => {
          const metaAttr = `${val}MetaAttributes`;
          if (
            !node[metaAttr]?.["data-specificity"]?.length ||
            !JSON.parse(node[metaAttr]?.["data-specificity"])?.some(
              (item: string) => newStyles.includes(item),
            )
          ) {
            const existingNode = matchingNodes.find(
              (nodeItem) => nodeItem?.node?.key === node.key,
            );
            /* This variable is used to determine if node's tablet or mobile tag is updated from global styles
               and therefore should be added in allowed list
            */
            const shouldUpdateDeviceTypeTag = node[`${val}Tag`] === tagName;
            if (existingNode && shouldUpdateDeviceTypeTag) {
              existingNode.allowedList.push(val);
            } else {
              matchingNodes.push({
                node,
                allowedList: shouldUpdateDeviceTypeTag ? [val] : [],
              });
            }
          }
        });
      } else {
        if (
          !node.metaAttributes?.["data-specificity"]?.length ||
          !JSON.parse(node.metaAttributes?.["data-specificity"])?.some(
            (item: string) => newStyles.includes(item),
          )
        ) {
          matchingNodes.push({ node });
        }
      }
    }
  }

  if (node.childrens) {
    for (const child of node.childrens) {
      matchingNodes.push(
        ...findAllNodesByTagName(child, tagName, newProperties, isMobileStyle),
      );
    }
  }

  return matchingNodes;
}

function addNode(
  tree: any,
  parentNodeKey: any,
  newNode: any,
  afterAdjacentNode?: number | string | null,
) {
  if (parentNodeKey === null) {
    Object.assign(tree, newNode);

    return;
  }
  const parentNode = findNodeByKey(tree, parentNodeKey);
  if (parentNode) {
    const idx = parentNode.childrens.findIndex(
      (item: any) => item.key === afterAdjacentNode,
    );
    if (idx > -1) {
      parentNode.childrens = parentNode.childrens.toSpliced(idx + 1, 0, {
        ...newNode,
        parentID: parentNodeKey,
      });
    } else {
      parentNode.childrens.push({
        ...newNode,
        parentID: parentNodeKey,
      });
    }
  } else {
    console.error("Parent node not found");
  }
}

function insertStyleSpecificity(
  key: any,
  node: any,
  newProperties: any,
  options?: any,
) {
  if (key === "style" && options?.triggerSpecificity) {
    getDeviceTypeAttributesToUpdate("metaAttributes")[
      selectedDeviceType.value
    ].forEach((item) => {
      let currentSpecificity = node?.[item]["data-specificity"];

      if (!currentSpecificity) {
        currentSpecificity = [];
      } else {
        currentSpecificity = JSON.parse(currentSpecificity);
      }

      const styleAttributes = options?.excludes?.length
        ? Object.keys(newProperties[key]).filter(
            (item) => !options.excludes.includes(item),
          )
        : Object.keys(newProperties[key]);

      node[item]["data-specificity"] = JSON.stringify(
        currentSpecificity.concat(
          ...styleAttributes
            .filter((key) => !key.startsWith("&"))
            .filter((key) => !currentSpecificity.includes(key)),
        ),
      );
    });
  }
}

function updateNode(
  tree: any,
  nodeKey: any,
  newProperties: any,
  options?: any,
) {
  const node = findNodeByKey(tree, nodeKey);
  if (node) {
    // Merging existing properties with new ones

    for (const key in newProperties) {
      if (
        newProperties[key] instanceof Object &&
        !Array.isArray(newProperties[key]) &&
        !options?.override
      ) {
        getDeviceTypeAttributesToUpdate(key)[selectedDeviceType.value].forEach(
          (attr: any) => {
            node[attr] = removeEmptyAttr({
              ...node[attr],
              ...newProperties[key],
            });
          },
        );
      } else {
        getDeviceTypeAttributesToUpdate(key)[selectedDeviceType.value].forEach(
          (attr: any) => {
            node[attr] = newProperties[key];

            // TODO: REFACTOR
            if (options?.attributesToPreserve?.includes(attr)) {
              const attrValues = nodeDeviceTypePropertiesMap.get(node.key)[
                attr
              ];
              node[attr] = {
                ...node[attr],
                "data-hidden": attrValues["data-hidden"] || "false",
              };
            }
          },
        );
      }

      insertStyleSpecificity(key, node, newProperties, options);
    }
  } else {
    console.error("Node to update not found");
  }
}

function updateNodes(tree: any, tag: any, newProperties: any, options?: any) {
  const nodes = findAllNodesByTagName(
    tree,
    tag,
    newProperties,
    options?.isMobileStyle,
  );

  for (const nodeObj of nodes) {
    if (nodeObj) {
      const { node, allowedList } = nodeObj;
      for (const key in newProperties) {
        if (
          newProperties[key] instanceof Object &&
          !Array.isArray(newProperties[key]) &&
          !options?.override
        ) {
          if (
            !allowedList ||
            allowedList.find((item: any) => key.toLowerCase().includes(item))
          ) {
            node[key] = removeEmptyAttr({
              ...node[key],
              ...newProperties[key],
            });
          }
        } else {
          node[key] = newProperties[key];
        }
      }
    } else {
      console.error("Node to update not found");
    }
  }
}

function updateHeadingType(tree: any, nodeKey: any, newProperties: any) {
  const node = findNodeByKey(tree, nodeKey);

  if (node) {
    const keysToUpdate =
      getDeviceTypeAttributesToUpdate("style")[selectedDeviceType.value];
    for (const key in newProperties) {
      if (!keysToUpdate.includes(key)) continue;
      node[key] = removeEmptyAttr({
        ...node[key],
        ...newProperties[key],
      });
    }
  } else {
    console.error("Node to update not found");
  }
}

/* The goal of the function is to find all the nodes that are using general 
colors and update their values once respective general color has been changed */
function updateNodeGeneralColors(
  tree: any,
  changedGeneralColorName: string,
  newColor: string,
  isGradient?: boolean,
) {
  const updateColor = (node: any, attribute: string, styleKey: string) => {
    const deviceTypes = [
      DeviceType.DESKTOP,
      DeviceType.TABLET,
      DeviceType.MOBILE,
    ];
    deviceTypes.forEach((item) => {
      const deviceTypeMetaAttribute = getDeviceSpecificKey(
        "metaAttributes",
        item,
      );
      const deviceTypeStyleAttribute = getDeviceSpecificKey("style", item);

      if (
        node?.[deviceTypeMetaAttribute]?.[attribute] === changedGeneralColorName
      ) {
        switch (node.widgetType) {
          case "divider":
            node[deviceTypeStyleAttribute]["borderTop"] = node[
              deviceTypeStyleAttribute
            ]["borderTop"].replace(/#([0-9a-fA-F]{6})/g, newColor);
            break;
          case "single_image":
            node[deviceTypeStyleAttribute]["borderColor"] = newColor;
            break;
          default:
            // to reset heading after global gradient color value changes to solid color
            node[deviceTypeStyleAttribute]["webkitTextFillColor"] = isGradient
              ? "transparent"
              : "unset";
            node[deviceTypeStyleAttribute]["backgroundClip"] = isGradient
              ? "text"
              : "unset";

            // to reset after global gradient color value changes to solid color
            if (
              node[deviceTypeStyleAttribute]["backgroundImage"] &&
              !isGradient
            ) {
              node[deviceTypeStyleAttribute]["backgroundImage"] = "initial";
            } else {
              node[deviceTypeStyleAttribute]["backgroundColor"] = "initial";
            }
            node[deviceTypeStyleAttribute][styleKey] = newColor;
        }
      }
    });
  };

  const processNode = (node: any) => {
    if (node.metaAttributes) {
      updateColor(
        node,
        "data-bgColorName",
        isGradient ? "backgroundImage" : "backgroundColor",
      );
      updateColor(
        node,
        "data-textColorName",
        isGradient ? "backgroundImage" : "color",
      );
    }
    // compound widgets can have nested childrens, so this checks all of them recursively
    if (node.childrens && node.childrens.length > 0) {
      for (const child of node.childrens) {
        processNode(child);
      }
    }
  };

  processNode(tree);
}

function updateNodeCustomColorName(
  tree: any,
  oldName: string,
  newName: string,
) {
  const updateName = (node: any, attribute: string) => {
    const deviceTypes = [
      DeviceType.DESKTOP,
      DeviceType.TABLET,
      DeviceType.MOBILE,
    ];
    deviceTypes.forEach((item) => {
      const deviceTypeMetaAttribute = getDeviceSpecificKey(
        "metaAttributes",
        item,
      );

      if (node?.[deviceTypeMetaAttribute]?.[attribute] === oldName) {
        switch (node.widgetType) {
          case "divider":
            node[deviceTypeMetaAttribute]["data-bgColorName"] = newName;
            break;
          case "single_image":
            node[deviceTypeMetaAttribute]["data-bgColorName"] = newName;

            break;
          default:
            node[deviceTypeMetaAttribute][attribute] = newName;
        }
      }
    });
  };

  const processNode = (node: any) => {
    if (node.metaAttributes) {
      updateName(node, "data-bgColorName");
      updateName(node, "data-textColorName");
    }
    // compound widgets can have nested childrens, so this checks all of them recursively
    if (node.childrens && node.childrens.length > 0) {
      for (const child of node.childrens) {
        processNode(child);
      }
    }
  };

  processNode(tree);
}

function deleteCustomColorFromNodes(tree: any, removedColorName: string) {
  function updateNodeStyleAndMetaAttributes(
    node: any,
    deviceTypeStyleAttribute: string,
    deviceTypeMetaAttribute: any,
    attribute: string,
    styleKey: string,
    deviceType: DeviceType,
  ) {
    const deviceTypeTag =
      node[getDeviceSpecificKey("tag", deviceType)] || node.tag;
    const headingTag = deviceTypeTag === "p" ? "body" : deviceTypeTag;
    switch (node.widgetType) {
      case "divider":
        if (node[deviceTypeStyleAttribute]?.["borderTop"]) {
          node[deviceTypeStyleAttribute]["borderTop"] = node[
            deviceTypeStyleAttribute
          ]["borderTop"]?.replace(
            /#([0-9a-fA-F]{6})/g,
            globalColors.general.value.Paragraph?.hexes[0]?.hexValue,
          );
        }
        node[deviceTypeMetaAttribute][attribute] = "Paragraph";

        break;
      case "single_image":
        node[deviceTypeStyleAttribute]["borderColor"] =
          globalColors.general.value.Primary?.hexes[0]?.hexValue;
        node[deviceTypeMetaAttribute][attribute] = "Primary";
        break;
      case "button":
        if (node[deviceTypeStyleAttribute]["backgroundImage"]) {
          node[deviceTypeStyleAttribute]["backgroundImage"] = "initial";
        }
        node[deviceTypeStyleAttribute][styleKey] =
          attribute === "data-textColorName"
            ? globalButtonStyles.textColor.value.color || "#FFFFFF"
            : globalButtonStyles.backgroundColor.value.color;
        node[deviceTypeMetaAttribute][attribute] =
          attribute === "data-textColorName"
            ? globalButtonStyles.textColor.value.name
            : globalButtonStyles.backgroundColor.value.name;

        break;
      // for headings in each widget
      default:
        if (deviceType === DeviceType.DESKTOP) {
          if (node[deviceTypeStyleAttribute]["backgroundImage"]) {
            node[deviceTypeStyleAttribute]["backgroundImage"] = "initial";
            node[deviceTypeStyleAttribute]["webkitTextFillColor"] = "unset";
          }
          node[deviceTypeStyleAttribute]["color"] = (headingsSignal as any)[
            headingTag
          ].textColor.value.color;

          node[deviceTypeMetaAttribute]["data-textColorName"] = (
            headingsSignal as any
          )[headingTag].textColor.value.name;
        } else {
          node[deviceTypeStyleAttribute]["color"] = (
            mobileHeadingsSignal as any
          )[headingTag].textColor.value.color;

          node[deviceTypeMetaAttribute]["data-textColorName"] = (
            mobileHeadingsSignal as any
          )[headingTag].textColor.value.name;
        }
    }
  }

  function updateDataSpecificity(
    node: any,
    deviceTypeMetaAttribute: string,
    styleKey: string,
  ) {
    const regex = new RegExp(`"${styleKey}",?`);
    if (node[deviceTypeMetaAttribute]?.["data-specificity"]) {
      const updatedSpecificity = node[deviceTypeMetaAttribute]?.[
        "data-specificity"
      ]
        ?.replace(regex, "")
        .replace(/,\s*]/, "]") // We remove commas so BE doesn't throw parsing errors
        .replace(/\[\s*,/, "[");

      if (updatedSpecificity === "[]") {
        delete node[deviceTypeMetaAttribute]["data-specificity"];
      } else {
        node[deviceTypeMetaAttribute]["data-specificity"] = updatedSpecificity;
      }
    }
  }

  const revertColor = (node: any, attribute: string, styleKey: string) => {
    const deviceTypes = [
      DeviceType.DESKTOP,
      DeviceType.TABLET,
      DeviceType.MOBILE,
    ];
    deviceTypes.forEach((item) => {
      const deviceTypeMetaAttribute = getDeviceSpecificKey(
        "metaAttributes",
        item,
      );
      const deviceTypeStyleAttribute = getDeviceSpecificKey("style", item);

      if (node?.[deviceTypeMetaAttribute]?.[attribute] === removedColorName) {
        updateDataSpecificity(node, deviceTypeMetaAttribute, styleKey);

        updateNodeStyleAndMetaAttributes(
          node,
          deviceTypeStyleAttribute,
          deviceTypeMetaAttribute,
          attribute,
          styleKey,
          item,
        );
      }
    });
  };

  const processNode = (node: any) => {
    if (node.metaAttributes) {
      revertColor(node, "data-bgColorName", "backgroundColor");
      revertColor(node, "data-textColorName", "color");
    }
    // compound widgets can have nested childrens, so this checks all of them recursively
    if (node.childrens && node.childrens.length > 0) {
      for (const child of node.childrens) {
        processNode(child);
      }
    }
  };

  processNode(tree);
}

function removeNode(tree: any, nodeKey: any) {
  if (tree.key === nodeKey) {
    console.error("Cannot remove the root node");
    return;
  }

  if (tree.childrens) {
    for (const child of tree.childrens) {
      if (child.key === nodeKey) {
        const index = tree.childrens.indexOf(child);
        tree.childrens.splice(index, 1);
        nodeDeviceTypePropertiesMap.delete(nodeKey);
        return;
      } else {
        removeNode(child, nodeKey);
      }
    }
  }
}

function buildTree(nodes: any) {
  const nodeMap = new Map();
  let rootNode = null;

  if (!nodes.length) return { tag: "div" };

  // Map all nodes by their key and find the root node
  nodes.forEach((node: any) => {
    const nodeCopy = {
      ...node,
      childrens: [],
      ...(node?.content && { content: decodeSpecialCharacters(node?.content) }),
      ...(node?.tabletContent && {
        tabletContent: decodeSpecialCharacters(node?.tabletContent),
      }),
      ...(node?.mobileContent && {
        mobileContent: decodeSpecialCharacters(node?.mobileContent),
      }),
    };
    nodeMap.set(node.key, nodeCopy);
    if (node.parentID === null) {
      rootNode = nodeCopy;
    }
  });

  // Link children to their respective parent nodes
  nodes.forEach((node: any) => {
    if (node.parentID !== null) {
      const parentNode = nodeMap.get(node.parentID);
      if (parentNode) {
        parentNode.childrens.push(nodeMap.get(node.key));
      }
    }
  });
  return rootNode;
}

function removeEmptyAttr(obj: any) {
  const copy: any = {};
  for (const key in obj) {
    if (obj[key]?.length) {
      copy[key] = obj[key];
    }
  }

  return copy;
}

export function createGradient(data: any) {
  // Start building the gradient string
  let gradient = `linear-gradient(${data.angle}, `;

  // Add each color stop
  data.hexes.forEach((hex: any, index: number) => {
    gradient += `${hex.hexValue} ${hex.position}`;
    if (index < data.hexes.length - 1) {
      gradient += ", "; // Add a comma after each color stop except the last one
    }
  });

  // Close the gradient definition
  gradient += ")";

  return gradient;
}

export const gradientToArray = (gradient: string) => {
  const gradientRegex = /rgba?\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*\)?(,\s*(0|1|0?\.\d+)\s*\))? [0-9]*%/g;
  const angleRegex = /\b(\d+deg)\b/;

  const gradientMatch = gradient.match(gradientRegex);
  const angleMatch = gradient.match(angleRegex);
  if (!gradientMatch || !angleMatch) {
    throw new Error("Invalid gradient format");
  }
  const hexes: { hexValue: string; position: string }[] = [];
  const angle = angleMatch[0];
  gradientMatch.forEach((item) => {
    const splitIndex = item.lastIndexOf(" ");
    const color = item.slice(0, splitIndex);
    const position = item.slice(splitIndex + 1, item.length);
    const hexColor = rgbToHex(color);
    const sanitisedHexColor =
      hexColor.length > 7 ? hexColor.slice(0, 7) : hexColor;
    hexes.push({
      hexValue: sanitisedHexColor,
      position,
    });
  });

  return { angle, hexes };
};

const encodeSpecialCharacters = (str: any) => {
  return str
    ? str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/'/g, "&#39;")
        .replace(/"/g, "&#34;")
    : "";
};

export const decodeSpecialCharacters = (str: string) => {
  return str
    ? str
        .replace(/&#39;/g, "'")
        .replace(/&#34;/g, '"')
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/&amp;/g, "&")
    : "";
};

export const flattenNodeList = (tree: any, isSaveForm?: boolean): any[] => {
  const flattenedList: any[] = [];

  const traverse = (node: any) => {
    const nodeObj = {
      key: node?.key,
      tag: node?.tag,
      tabletTag: node?.tabletTag ?? "h1",
      mobileTag: node?.mobileTag ?? "h1",
      metaAttributes: node?.metaAttributes,
      tabletMetaAttributes: node?.tabletMetaAttributes,
      mobileMetaAttributes: node?.mobileMetaAttributes,
      parentID: node?.parentID,
      style: node?.style,
      tabletStyle: node?.tabletStyle,
      mobileStyle: node?.mobileStyle,
      content: isSaveForm
        ? encodeSpecialCharacters(node?.content)
        : node?.content,
      tabletContent: isSaveForm
        ? encodeSpecialCharacters(node?.tabletContent)
        : node?.tabletContent,
      mobileContent: isSaveForm
        ? encodeSpecialCharacters(node?.mobileContent)
        : node?.mobileContent,
      href: node?.href,
      tabletHref: node?.tabletHref,
      mobileHref: node?.mobileHref,
    } as any;

    if (node?.widgetType) {
      nodeObj.widgetType = node.widgetType;
    }
    if (node?.head) {
      nodeObj.head = node.head;
    }
    if (node?.blueprintID) {
      nodeObj.blueprintID = node.blueprintID;
    }

    flattenedList.push(nodeObj);

    if (node?.childrens && node?.childrens?.length > 0) {
      node.childrens.forEach(traverse);
    }
  };

  traverse(tree);
  return flattenedList.filter((item) => item.key !== 1);
};

export const generateUniqueID = () => {
  let seed = Date.now();
  seed ^= Math.random() * 0x7fffffff;
  return Math.abs(seed % Number.MAX_SAFE_INTEGER);
};

const updateNodeVisibility = (
  tree: any,
  nodeKey: number,
  newVisibility: string,
) => {
  const node = findNodeByKey(tree, nodeKey);
  const metaAttr = getDeviceTypePropertyName("metaAttributes");
  if (!node?.[metaAttr]) {
    node[metaAttr] = {};
  }
  node[metaAttr] = {
    ...node[metaAttr],
    "data-hidden": newVisibility,
  };
};

export const builder = (function (tree: Signal<any>) {
  let _tree: any = {};

  function react(message?: string) {
    setTimeout(() => {
      tree.value = cloneDeep(_tree);
    }, 10);
    isDraftEnabled.value = true;
    isPublishEnabled.value = true;
  }

  return {
    addNode: function (
      parentNodeKey: any,
      newNode: any,
      afterAdjacentNode?: number | string | null,
    ) {
      addNode(_tree, parentNodeKey, newNode, afterAdjacentNode);
      react();
    },
    updateNode: function (nodeKey: any, newProperties: any, options?: any) {
      updateNode(_tree, nodeKey, newProperties, options);
      react();
    },
    updateNodesByTag: function (tag: any, newProperties: any, options?: any) {
      updateNodes(_tree, tag, newProperties, options);
      react();
    },
    buildTree: function (nodes: any[]) {
      const _cleanCopy = buildTree(nodes);
      _tree = { ..._cleanCopy };
      setTimeout(() => {
        tree.value = { ..._tree };
      }, 10);
    },
    removeNode: function (nodeKey: any) {
      removeNode(_tree, nodeKey);
      react();
    },
    findNodeIndex: function (nodeKey: any) {
      return _tree.childrens?.findIndex((node: any) => node.key === nodeKey);
    },
    findNodeByKey: function (nodeKey: any) {
      return findNodeByKey(_tree, nodeKey);
    },
    getChildrenLength: function () {
      return _tree.childrens?.length;
    },
    swapNodes: function (currentIndex: any, targetIndex: any, node?: any) {
      swapNodePosition(node ?? _tree, currentIndex, targetIndex);
      if (node) {
        findNodeByKey(_tree, node.key).childrens = node.childrens;
      }
      react();
    },
    updateNodeGeneralColors: (
      changedGeneralColorName: string,
      newColor: string,
      isGradient?: boolean,
    ) => {
      updateNodeGeneralColors(
        _tree,
        changedGeneralColorName,
        newColor,
        isGradient,
      );
      react();
    },

    deleteCustomColorFromNodes: (removedColorName: string) => {
      deleteCustomColorFromNodes(_tree, removedColorName);
      react();
    },

    updateNodeCustomColorName: (oldName: string, newName: string) => {
      updateNodeCustomColorName(_tree, oldName, newName);
      react();
    },
    updateNodeVisibility(nodeKey: number, visibility: string) {
      updateNodeVisibility(_tree, nodeKey, visibility);
      react();
    },
    updateHeadingType(nodeKey: number, newValues: any) {
      updateHeadingType(_tree, nodeKey, newValues);
      react();
    },
    sortNodeChildrens: (sortedArray: any, nodeId: number) => {
      const node = findNodeByKey(_tree, nodeId);
      if (node && node?.childrens) {
        node.childrens.sort((a: any, b: any) => {
          const indexA = sortedArray.findIndex(
            (item: any) => item.key === a.key,
          );
          const indexB = sortedArray.findIndex(
            (item: any) => item.key === b.key,
          );
          return indexA - indexB;
        });
        react();
      }
    },
    react,
    clonedNode(
      widgetType:
        | "image_text"
        | "heading"
        | "button"
        | "divider"
        | "single_image",
    ) {
      const widget = widgets[widgetType as "image_text"];

      if (typeof widget.tagName !== "string") {
        return widgetFactory({
          ...widget,
          state: selectedItem.value.state,
        });
      } else {
        let props: any = {};
        const currentNode = this.findNodeByKey(selectedItem.value?.id);
        const {
          tag: Tag,
          tabletTag,
          mobileTag,
          style,
          tabletStyle,
          mobileStyle,
          metaAttributes,
          tabletMetaAttributes,
          mobileMetaAttributes,
          content,
          tabletContent,
          mobileContent,
          href,
        } = currentNode;

        const deviceTypeStyleAttribute = getDeviceTypeAttribute(
          style,
          tabletStyle,
          mobileStyle,
        );

        const deviceTypeMetaAttribute = getDeviceTypeAttribute(
          metaAttributes,
          tabletMetaAttributes,
          mobileMetaAttributes,
        );

        const deviceTypeContent = getDeviceTypeAttribute(
          content,
          tabletContent,
          mobileContent,
        );

        switch (widgetType) {
          case "heading":
            props = widgetsBuilderFromNode[widgetType as "heading"](
              getDeviceTypeAttribute(Tag, tabletTag, mobileTag),
              deviceTypeStyleAttribute,
              deviceTypeMetaAttribute,
              deviceTypeContent,
            );
            break;
          case "button":
            props = widgetsBuilderFromNode[widgetType as "button"](
              deviceTypeStyleAttribute,
              deviceTypeMetaAttribute,
              deviceTypeContent,
              href,
            );
            break;
          case "divider":
            props = widgetsBuilderFromNode[widgetType as "divider"](
              deviceTypeStyleAttribute,
              deviceTypeMetaAttribute,
            );
            break;
          case "single_image":
            props = widgetsBuilderFromNode[widgetType as "single_image"](
              deviceTypeStyleAttribute,
              deviceTypeMetaAttribute,
            );
            break;
          default:
            break;
        }
        const mobileCssProperties =
          widgetType === "heading"
            ? {
                ...widgetsBuilderFromNode[widgetType as "heading"](
                  mobileTag,
                  mobileStyle,
                  mobileMetaAttributes,
                  mobileContent,
                )?.cssProperties,
              }
            : undefined;

        return Object.assign(
          widgetFactory(
            composeWidget(widget, { ...props, mobileCssProperties }),
          ),
          {
            tagName: Tag,
            metaAttributes,
            tabletMetaAttributes,
            mobileMetaAttributes,
            // TODO: find better solution
            isClone: true,
          },
        );
      }
    },
  };
})(structuredTreeSignal);

export function customizer(objValue: any, srcValue: any, key: string) {
  // Define the keys you want to merge
  const keysToMerge = ["content", "style", "childrens"];

  if (keysToMerge.includes(key)) {
    if (key === "childrens") {
      // Deep merge for children array
      return srcValue.map((child: any, index: number) => {
        return mergeWith(objValue[index], child, customizer);
      });
    }

    if (key === "content" || key === "style") {
      // because style is an object we need to clone deep it, otherwise we just pass the reference of style form source value
      // and if we update any value on the new ndoe cloned, the one we cloned from will do to
      return cloneDeep(srcValue);
    }
  }

  // Return objValue for all other keys to prevent merging
  return objValue;
}
