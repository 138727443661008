import { ToastPosition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastMessage, { TToastOptions } from "./ToastMessage";
import React from "react";
import { store } from "../../../redux/store";
import _ from "lodash";

type Message = "Error" | "Info" | "Success" | "Warning" | "Default";
export type TPosition = {
  position?: ToastPosition;
  style?: React.CSSProperties;
};
const showMessage = (
  message: Message,
  description: string | React.ReactNode,
  isDesktop = true,
  title?: string,
  autoClose?: number | false,
  ToastPosition?: TPosition,
  options?: TToastOptions,
) => {
  const _modals = store.getState().modals as Record<string, any>;
  const isModalOpen = Object.values(_modals).some((modal) => modal.visible);
  const toastAutoclose =
    (isModalOpen && message === "Error") || message === "Default"
      ? 5000
      : autoClose;

  const toastified = toast(
    <ToastMessage
      message={title || message}
      type={message === "Default" ? "Warning" : message}
      description={description}
      isDesktop={isDesktop}
      toastId={message}
      options={options}
    />,
    {
      autoClose: toastAutoclose,
      toastId: message,
      position: ToastPosition && ToastPosition.position,
      style: ToastPosition && ToastPosition.style,
    },
  );
  return toastified;
};

const showDefault = (description: string) => {
  showMessage("Info", description);
};

const clearToasts = () => _.delay(() => toast.dismiss(), 2000);
export { showDefault, showMessage, clearToasts };
