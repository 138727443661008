// import { MenuUniqueIds } from "@utils/index";

import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";

interface IconProps {
  height?: number;
  width?: number;
  gradient?: boolean;
  isMenu?: boolean;
  fill?: string;
}

const HourGlassIcon = ({
  width = 24,
  height = 24,
  fill = "#8F8F8F",
  gradient,
}: IconProps) => {
  const { parseLinearGradientColor } = useThemeContext();
  const [color1, color2] = parseLinearGradientColor();
  if (gradient) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.40002 5.00036C5.40002 4.66899 5.66865 4.40036 6.00002 4.40036H18C18.3314 4.40036 18.6 4.66899 18.6 5.00036C18.6 5.33173 18.3314 5.60036 18 5.60036H16.68V7.09516C16.68 7.51013 16.5572 7.91286 16.3309 8.26286L14.8771 10.5115C14.6972 10.7898 14.3258 10.8695 14.0475 10.6896C13.7692 10.5097 13.6895 10.1382 13.8694 9.85995L15.3232 7.61133C15.4285 7.44851 15.48 7.27083 15.48 7.09516V5.60036H8.52002V7.09516C8.52002 7.27083 8.57159 7.44851 8.67686 7.61133L10.7596 10.8327C11.225 11.5526 11.225 12.4481 10.7596 13.1681L8.67686 16.3894C8.57159 16.5522 8.52002 16.7299 8.52002 16.9056V18.4004H15.48V16.9056C15.48 16.7299 15.4285 16.5522 15.3232 16.3894L13.8694 14.1408C13.6895 13.8625 13.7692 13.4911 14.0475 13.3111C14.3258 13.1312 14.6972 13.211 14.8771 13.4892L16.3309 15.7379C16.5572 16.0879 16.68 16.4906 16.68 16.9056V18.4004H18C18.3314 18.4004 18.6 18.669 18.6 19.0004C18.6 19.3317 18.3314 19.6004 18 19.6004H6.00002C5.66865 19.6004 5.40002 19.3317 5.40002 19.0004C5.40002 18.669 5.66865 18.4004 6.00002 18.4004H7.32002V16.9056C7.32002 16.4906 7.44285 16.0879 7.66913 15.7379L9.75183 12.5165C9.96094 12.1931 9.96094 11.8076 9.75183 11.4842L7.66913 8.26286C7.44285 7.91286 7.32002 7.51013 7.32002 7.09516V5.60036H6.00002C5.66865 5.60036 5.40002 5.33173 5.40002 5.00036Z"
          fill="url(#paint0_linear_304_18)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_304_18"
            x1="14.8149"
            y1="4.99057"
            x2="-2.31948"
            y2="13.8389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color1} />
            <stop offset="1" stopColor={color2} />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40002 5.00036C5.40002 4.66899 5.66865 4.40036 6.00002 4.40036H18C18.3314 4.40036 18.6 4.66899 18.6 5.00036C18.6 5.33173 18.3314 5.60036 18 5.60036H16.68V7.09516C16.68 7.51013 16.5572 7.91286 16.3309 8.26286L14.8771 10.5115C14.6972 10.7898 14.3258 10.8695 14.0475 10.6896C13.7692 10.5097 13.6895 10.1382 13.8694 9.85995L15.3232 7.61133C15.4285 7.44851 15.48 7.27083 15.48 7.09516V5.60036H8.52002V7.09516C8.52002 7.27083 8.57159 7.44851 8.67686 7.61133L10.7596 10.8327C11.225 11.5526 11.225 12.4481 10.7596 13.1681L8.67686 16.3894C8.57159 16.5522 8.52002 16.7299 8.52002 16.9056V18.4004H15.48V16.9056C15.48 16.7299 15.4285 16.5522 15.3232 16.3894L13.8694 14.1408C13.6895 13.8625 13.7692 13.4911 14.0475 13.3111C14.3258 13.1312 14.6972 13.211 14.8771 13.4892L16.3309 15.7379C16.5572 16.0879 16.68 16.4906 16.68 16.9056V18.4004H18C18.3314 18.4004 18.6 18.669 18.6 19.0004C18.6 19.3317 18.3314 19.6004 18 19.6004H6.00002C5.66865 19.6004 5.40002 19.3317 5.40002 19.0004C5.40002 18.669 5.66865 18.4004 6.00002 18.4004H7.32002V16.9056C7.32002 16.4906 7.44285 16.0879 7.66913 15.7379L9.75183 12.5165C9.96094 12.1931 9.96094 11.8076 9.75183 11.4842L7.66913 8.26286C7.44285 7.91286 7.32002 7.51013 7.32002 7.09516V5.60036H6.00002C5.66865 5.60036 5.40002 5.33173 5.40002 5.00036Z"
        fill={fill}
      />
    </svg>
  );
};

export default HourGlassIcon;
