import useUserReducer from "@hooks/Reducers/useUserReducer";
import { Avatar, Stack } from "@mui/material";
import { TruncateText } from "@common/Text";
import { Divider } from "@mui/material";

function ProfileDetails() {
  const { img, globalName, email } = useUserReducer();
  return (
    <Stack gap={1} mb={2}>
      <Stack direction="row" alignItems="flex-start" gap={2}>
        <Avatar
          src={img}
          sx={{
            width: "32px",
            height: "32px",
          }}
        />
        <Stack gap={0}>
          <TruncateText
            lineClamp={1}
            color={({ palette }) => palette.neutral[90]}
          >
            {globalName?.firstName} {globalName?.lastName}
          </TruncateText>
          <TruncateText
            lineClamp={1}
            color={({ palette }) => palette.neutral[70]}
            sx={{
              wordBreak: "break-all",
            }}
          >
            {email}
          </TruncateText>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
}

export default ProfileDetails;
