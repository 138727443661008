import * as React from "react";
// nice-modal
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
// components
import Popup from "@common/Popup";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";

type DeleteProps = {
  title: string;
  deleteAction: () => void;
  children?: React.ReactNode;
  actionLabel?: string;
};

const Delete = NiceModal.create(
  ({ title, children, deleteAction, actionLabel }: DeleteProps) => {
    const modal = useModal();
    const { t } = useTranslation(namespaces.common);

    const primaryActionLabel = actionLabel
      ? actionLabel
      : t("delete.delete", { ns: namespaces.common });

    const handleDelete = () => {
      deleteAction();
      modal.hide();
    };

    return (
      <Popup
        {...muiDialogV5(modal)}
        title={title}
        onCancel={() => modal.hide()}
        onSubmit={handleDelete}
        actionLabel={primaryActionLabel}
      />
    );
  },
);

export default Delete;
