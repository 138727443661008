import { useThemeContext } from "@components/EnterpriseSettings/Branding/Provider/CustomThemeProvider";
import { Box, styled } from "@mui/material";
import { palette } from "@palette";

interface GradientSpanProps {
  gradient: string;
  children: React.ReactNode;
}

const GradientSpan = ({ children }: GradientSpanProps) => {
  const { gradient } = useThemeContext();
  return (
    <StyledSpan component="span" gradient={gradient}>
      {children}
    </StyledSpan>
  );
};

const StyledSpan = styled(Box, {
  shouldForwardProp: (prop) => prop !== "gradient",
})<{
  gradient?: string;
}>(({ gradient }) => ({
  background: gradient,
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

export default GradientSpan;
