import { Divider, Stack } from "@mui/material";
import TitleSection from "@components/Disputes/DisputePreview/components/TitleSection";
import DisputeCaseSummary from "@components/Disputes/DisputePreview/components/DisputeCaseSummary";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import DisputeCaseHistory from "@components/Disputes/DisputePreview/components/DisputeCaseHistory";

const DisputePreviewBody = () => {
  return (
    <Stack gap={2}>
      <TitleSection />
      <Stack gap={3}>
        <DisputeCaseHistory />
        <Divider color={palette.neutral[20]} />
        <FadeUpWrapper delay={100}>
          <DisputeCaseSummary />
        </FadeUpWrapper>
      </Stack>
    </Stack>
  );
};

export default DisputePreviewBody;
