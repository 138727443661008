import { customInstance } from "@services/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { riskTriggersParser } from "../helpers/parsers";
import { TRiskTFactor } from "@components/Merchants/MerchantPreview/RiskProfile/types";

const getMerchantRiskProfile = (profileId: number, merchantId: number) => {
  return customInstance({
    url: `/merchants/${merchantId}/risk/merchant-profiles/${profileId}/factors`,
    method: "GET",
  });
};

export type RiskTriggerDataFormatted = {
  [category: string]: TRiskTFactor[];
};

export type RiskTriggerResponseData = { category: string } & TRiskTFactor;

type RiskTriggersResponse = {
  data: RiskTriggerResponseData[];
};

export const useRiskTriggersV2 = (profileId: number, merchantId: number) => {
  const [data, setData] = useState<RiskTriggersResponse>();
  const { data: defaultData, isLoading } = useQuery(
    ["risk-triggers", profileId, merchantId],
    async () => {
      return await getMerchantRiskProfile(profileId, merchantId);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setData(data);
      },
    },
  );

  const parsedTriggers = riskTriggersParser(
    data ? data?.data : defaultData?.data,
  );

  return {
    data: parsedTriggers,
    isLoading,
  };
};
