import React from "react";

function AlertIcon({
  width = 22,
  height = 22,
  fill = "#FF5924",
}: {
  width?: string | number;
  height?: string | number;
  fill?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0992 5.65166C11.2981 5.65166 11.4888 5.73068 11.6295 5.87133C11.7701 6.01198 11.8492 6.20274 11.8492 6.40166V11.6517C11.8492 11.8506 11.7701 12.0413 11.6295 12.182C11.4888 12.3226 11.2981 12.4017 11.0992 12.4017C10.9002 12.4017 10.7095 12.3226 10.5688 12.182C10.4282 12.0413 10.3492 11.8506 10.3492 11.6517V6.40166C10.3492 6.20274 10.4282 6.01198 10.5688 5.87133C10.7095 5.73068 10.9002 5.65166 11.0992 5.65166ZM9.97416 15.0267C9.97416 15.2492 10.0401 15.4667 10.1638 15.6517C10.2874 15.8367 10.4631 15.9809 10.6686 16.066C10.8742 16.1512 11.1004 16.1734 11.3186 16.13C11.5369 16.0866 11.7373 15.9795 11.8947 15.8222C12.052 15.6648 12.1591 15.4644 12.2025 15.2461C12.2459 15.0279 12.2237 14.8017 12.1385 14.5961C12.0534 14.3906 11.9092 14.2149 11.7242 14.0913C11.5392 13.9676 11.3217 13.9017 11.0992 13.9017C10.8008 13.9017 10.5146 14.0202 10.3037 14.2312C10.0927 14.4421 9.97416 14.7283 9.97416 15.0267ZM21.5992 10.9017C21.5997 11.0981 21.5613 11.2927 21.4862 11.4743C21.411 11.6558 21.3006 11.8206 21.1613 11.9592L12.1567 20.9648C11.8756 21.2442 11.4954 21.401 11.0992 21.401C10.7029 21.401 10.3227 21.2442 10.0417 20.9648L1.04166 11.9592C0.762281 11.6781 0.605469 11.2979 0.605469 10.9017C0.605469 10.5054 0.762281 10.1252 1.04166 9.84416L10.0463 0.838533C10.3274 0.559156 10.7076 0.402344 11.1038 0.402344C11.5001 0.402344 11.8803 0.559156 12.1613 0.838533L21.166 9.84416C21.3044 9.98312 21.414 10.1481 21.4883 10.3296C21.5627 10.5111 21.6003 10.7055 21.5992 10.9017ZM20.0992 10.9017L11.0992 1.90166L2.09916 10.9017L11.0992 19.9017L20.0992 10.9017Z"
        fill={fill}
      />
    </svg>
  );
}

export default AlertIcon;
