type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const TrashBin = ({ width = 18, height = 18, fill = "#8F8F8F" }: Props) => {
  return (
    <svg
      data-testid="trashbin-icon"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53257 5.02278C6.53257 4.08199 7.27932 3.30005 8.22396 3.30005H9.77568C10.7203 3.30005 11.4671 4.08199 11.4671 5.02278V5.20915H13.4998C13.7484 5.20915 13.9498 5.41062 13.9498 5.65915C13.9498 5.90768 13.7484 6.10915 13.4998 6.10915H13.3054L13.2071 7.9243C13.1936 8.17246 12.9815 8.36274 12.7334 8.34929C12.4852 8.33585 12.2949 8.12377 12.3084 7.8756L12.4041 6.10915H5.59554L5.97022 13.0236C5.99436 13.4691 6.35024 13.8001 6.76035 13.8001H11.2393C11.6494 13.8001 12.0053 13.4691 12.0294 13.0236L12.1816 10.216C12.195 9.96785 12.4071 9.77757 12.6552 9.79102C12.9034 9.80447 13.0937 10.0165 13.0802 10.2647L12.9281 13.0723C12.8792 13.9757 12.1469 14.7001 11.2393 14.7001H6.76035C5.85276 14.7001 5.12049 13.9757 5.07153 13.0723L4.69422 6.10915H4.49981C4.25128 6.10915 4.0498 5.90768 4.0498 5.65915C4.0498 5.41062 4.25128 5.20915 4.49981 5.20915H6.53257V5.02278ZM7.43257 5.20915H10.5671V5.02278C10.5671 4.55775 10.2022 4.20005 9.77568 4.20005H8.22396C7.7974 4.20005 7.43257 4.55776 7.43257 5.02278V5.20915ZM7.60326 7.75461C7.85179 7.75461 8.05327 7.95608 8.05327 8.20461V11.7046C8.05327 11.9531 7.85179 12.1546 7.60326 12.1546C7.35473 12.1546 7.15326 11.9531 7.15326 11.7046V8.20461C7.15326 7.95608 7.35473 7.75461 7.60326 7.75461ZM10.3964 7.75461C10.6449 7.75461 10.8464 7.95608 10.8464 8.20461V11.7046C10.8464 11.9531 10.6449 12.1546 10.3964 12.1546C10.1478 12.1546 9.94637 11.9531 9.94637 11.7046V8.20461C9.94637 7.95608 10.1478 7.75461 10.3964 7.75461Z"
        fill={fill}
      />
    </svg>
  );
};

export default TrashBin;
