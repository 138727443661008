import { toUTCDateFormat } from "@utils/index";
import { StatusTextProps } from "@components/Settings/Business/Status/StatusText";
import { useLocation } from "react-router-dom";
import { merchantDateFormatter } from "@components/Merchants/MerchantPreview/helpers/parsers";

export const getMerchantStatusMessage = ({ status }: StatusTextProps) => {
  switch (status) {
    case "preapproved_no_tx":
      return "Please complete your profile.";
    case "preapproved_with_tx":
      return "You have x days to complete your profile or the money you accepted will be refunded.";
    case "preapproved_with_tx_times_up":
      return "You must complete your profile to continue accepting payments.";
    case "preapproved_mid_issue":
      return "Your account is being reviewed.";
    case "preapproved_mid_issue_request":
      return "Your account is being reviewed and additional information is required. A customer support representative will contact you.";
    case "has_mid_not_ready":
      return "Congratulations, you are approved to accept payments. You still need to complete your profile for approval to transfer money.";
    case "has_mid_ready":
      return "You are approved to accept payments. Your account is under approval review to transfer money.";
    case "pending":
      return "Business profile is currently being reviewed, this shouldn’t take more than 24 hours.";
    case "pending_review":
    case "pending_review_issue":
      return "Business Profile is being reviewed.";
    case "pending_review_request":
      return "Business Profile is being reviewed and additional information is required. A customer support representative will contact you.";
    case "approved":
      return "Congratulations, your account has been approved.";
    case "declined":
      return "We apologize but your account could not be approved at this time.";
    case "suspended":
      return "Your account has been suspended due to suspicious activity.";
    case "deactivated":
      return "Your account has been deactivated.";
    default:
      return status;
  }
};

export const getAcqEntStatusMessage = ({
  status,
  updatedAt,
  updatedByEmail,
  updatedByName,
}: StatusTextProps) => {
  // const updatedAt =
  //   data?.merchant_info?.statusUpdatedAt ||
  //   data?.enterprise_info?.statusUpdatedAt;
  // const updatedByName =
  //   data?.merchant_info?.statusUpdatedByName ||
  //   data?.enterprise_info?.statusUpdatedByName;
  // const updatedByEmail =
  //   data?.merchant_info?.statusUpdatedByEmail ||
  //   data?.enterprise_info?.statusUpdatedByEmail;
  switch (status) {
    case "preapproved_no_tx":
      return "The Merchant needs to complete their profile.";
    case "preapproved_with_tx":
      return "The Merchant has received transactions and has x days to get a MID.";
    case "preapproved_with_tx_times_up":
      return "The Merchant needs to complete their profile to continue accepting payments.";
    case "preapproved_mid_issue":
      return "An issue occurred in getting the MID";
    case "preapproved_mid_issue_request":
      return "An issue occurred in getting the MID and an Update is Requested.";
    case "has_mid_not_ready":
      return "Has MID but needs to upload documents for underwriting.";
    case "has_mid_ready":
      return "Has MID and is ready for final underwriting.";
    case "pending":
      return "Business profile is currently being reviewed, this shouldn’t take more than 24 hours.";
    case "pending_review":
      return "Business Profile is being reviewed.";
    case "pending_review_issue":
      return "There is an issue with the Business Profile.";
    case "pending_review_request":
      return "There is an issue with the Business Profile and a update requested.";
    case "approved":
      return `Approved ${updatedAt ? `on ${toUTCDateFormat(updatedAt)}` : ""} ${
        updatedByName ? `by ${updatedByName} ${updatedByEmail}` : ""
      }`;
    case "declined":
      return `Declined ${updatedAt ? `on ${toUTCDateFormat(updatedAt)}` : ""} ${
        updatedByName ? `by ${updatedByName} ${updatedByEmail}` : ""
      }`;
    case "suspended":
      return `Suspended ${
        updatedAt ? `on ${toUTCDateFormat(updatedAt)}` : ""
      } ${updatedByName ? `by ${updatedByName} ${updatedByEmail}` : ""}`;
    case "deactivated":
      return `Deactivated ${
        updatedAt ? `on ${toUTCDateFormat(updatedAt)}` : ""
      } ${updatedByName ? `by ${updatedByName} ${updatedByEmail}` : ""}`;
    default:
      return status;
  }
};

export const getStatusMessage = (props: StatusTextProps) => {
  const { pathname } = useLocation();
  const role = pathname.split("/")[1];

  if (props?.isLegalEntityStatus) {
    switch (role) {
      case "merchant":
      case "submerchant":
        return getMerchantLEStatus(props);
      default:
        return getEnterpriseLEStatus(props);
    }
  }
  switch (role) {
    case "merchant":
    case "submerchant":
      return getMerchantStatusMessage(props);
    default:
      return getAcqEntStatusMessage(props);
  }
};

export const getMerchantLEStatus = ({ status }: StatusTextProps) => {
  switch (status) {
    case "pending":
      return "Please complete your Business Profile.";
    case "pending_review":
      return "Business Profile is being reviewed.";
    case "pending_review_issue":
      return "Business Profile is being reviewed.";
    case "pending_review_request":
      return "Business Profile is being reviewed and additional information is required. A customer support representative will contact you.";
    case "approved":
      return "Congratulations, the Business Profile is approved.";
    case "declined":
      return "We apologize but the Business Profile could not be approved at this time.";
    case "suspended":
      return "The Business Profile has been suspended due to suspicious activity.";
    case "ready_for_verification":
      return "Business Profile is being verified.";
    default:
      return status;
  }
};

export const getEnterpriseLEStatus = ({
  status,
  updatedAt,
  updatedByEmail,
  updatedByName,
  statusDisplayMessage,
  readyForVerificationAt,
}: StatusTextProps) => {
  switch (status) {
    case "pending":
      return "Merchant needs to complete the Business Profile.";
    case "pending_review":
      return "Business Profile is being reviewed.";
    case "pending_review_issue":
      return `There is an issue with the Business Profile: ${statusDisplayMessage}`;
    case "pending_review_request":
      return `There is an issue with the Business Profile and a update requested: ${statusDisplayMessage}`;
    case "approved":
      return updatedAt
        ? `Approved ${`on ${toUTCDateFormat(
            updatedAt,
          )}.`} by ${updatedByName} ${updatedByEmail}.`
        : "";
    case "declined":
      return updatedByName && updatedByEmail
        ? `Declined ${
            updatedAt ? `on ${toUTCDateFormat(updatedAt)}.` : ""
          } ${`by ${updatedByName} ${updatedByEmail}.`}`
        : "";
    case "suspended":
      return updatedAt && updatedByName
        ? `Suspended ${updatedAt ? `on ${toUTCDateFormat(updatedAt)}` : ""} ${
            updatedByName ? `by ${updatedByName} ${updatedByEmail}.` : ""
          }`
        : "";
    case "ready_for_verification":
      return `Ready for verification on ${merchantDateFormatter(
        "MM/dd/yyyy HH:mm:ss",
        readyForVerificationAt,
      )}`;
    default:
      return status;
  }
};
