import { RHFInput } from "@common/Input";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";

export const DeleteEnterpriseDescription = ({
  itemName,
}: {
  itemName?: string;
}) => {
  return (
    <Stack spacing={2} id="delete-enterprise-form">
      <Text fontWeight="book" color={palette.neutral[70]}>
        Are you sure you want to delete{" "}
        <span style={{ color: palette.neutral[100] }}>{itemName}</span>? All
        associated data, such as merchants, transactions, payment forms, etc.
        will be deleted.
      </Text>
      <Text fontWeight="book" color={palette.neutral[80]}>
        Please enter the provider name to confirm
      </Text>
      <RHFInput
        name="enterpriseName"
        fullWidth
        placeholder="Provider name"
        label="Provider name"
        onPaste={(e) => e.preventDefault()}
      />
      <Text fontWeight="book" color={palette.neutral[70]}>
        You have <span style={{ color: palette.neutral[100] }}>15 days</span> to
        restore this provider, by following instructions that will be sent to
        your email.
      </Text>
    </Stack>
  );
};
