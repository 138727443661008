import { CalendarIcon } from "@assets/rebrandIcons";
import { Text } from "@common/Text";
import { Box } from "@mui/material";
import { palette } from "@palette";
import { checkEnd, timestampToFormattedString } from "@utils/date.helpers";
import React from "react";

function CommonLabel({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      padding="8px 16px 8px 12px"
      bgcolor={palette.neutral[10]}
      borderRadius="6px"
      border={`1px solid ${palette.neutral[20]}`}
      width="fit-content"
      marginY="16px"
    >
      {children}
    </Box>
  );
}

export function EndsAt({ campaignEndsAt }: { campaignEndsAt: number }) {
  const label = ["Ends on", "Ended on"];

  const endOfEvent = checkEnd(0, campaignEndsAt) < Date.now();

  return (
    <CommonLabel>
      <div
        style={{
          width: "8px",
          height: "8px",
          background: endOfEvent
            ? palette.neutral[70]
            : palette.tag.success.hover,
          borderRadius: "32px",
          marginRight: 6,
        }}
      />
      <Text lineHeight="120%" color={palette.neutral[80]}>
        {label[Number(endOfEvent)]}{" "}
        {timestampToFormattedString(campaignEndsAt * 1000, {
          hour: true,
          timeZone: true,
          hours12Format: true,
        })}
      </Text>
    </CommonLabel>
  );
}

export function StartsAt({
  campaignStartsAt,
  campaignEndedAt,
}: {
  campaignStartsAt: number;
  campaignEndedAt?: number | null;
}) {
  const endOfEvent =
    checkEnd(campaignStartsAt, campaignEndedAt ?? 0) < Date.now();

  const label = ["Takes place on", "Has ended on"];

  const date =
    endOfEvent && campaignEndedAt ? campaignEndedAt : campaignStartsAt;
  return (
    <CommonLabel>
      <CalendarIcon />
      <Text lineHeight="120%" color={palette.neutral[80]} marginLeft="6px">
        {label[Number(endOfEvent)]}{" "}
        {timestampToFormattedString(date * 1000, {
          hour: true,
        })}
      </Text>
    </CommonLabel>
  );
}
