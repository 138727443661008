type Props = {
  width?: number;
  height?: number;
  fill?: string;
};

const MagnifyingGlass = ({
  width = 24,
  height = 25,
  fill = "#575353",
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M21.5308 21.3075L16.8368 16.6144C18.1973 14.981 18.8757 12.8859 18.7309 10.765C18.5861 8.64418 17.6293 6.66078 16.0593 5.22745C14.4894 3.79412 12.4274 3.02122 10.3021 3.06952C8.17687 3.11782 6.15205 3.98361 4.64888 5.48677C3.14571 6.98994 2.27993 9.01476 2.23163 11.14C2.18333 13.2653 2.95623 15.3273 4.38956 16.8972C5.82289 18.4671 7.80629 19.424 9.92715 19.5688C12.048 19.7136 14.1431 19.0352 15.7765 17.6747L20.4696 22.3687C20.5393 22.4384 20.622 22.4937 20.713 22.5314C20.8041 22.5691 20.9017 22.5885 21.0002 22.5885C21.0988 22.5885 21.1963 22.5691 21.2874 22.5314C21.3784 22.4937 21.4612 22.4384 21.5308 22.3687C21.6005 22.299 21.6558 22.2163 21.6935 22.1253C21.7312 22.0342 21.7506 21.9367 21.7506 21.8381C21.7506 21.7396 21.7312 21.642 21.6935 21.5509C21.6558 21.4599 21.6005 21.3772 21.5308 21.3075ZM3.75021 11.3381C3.75021 10.0031 4.14609 8.69804 4.88779 7.58801C5.62949 6.47797 6.6837 5.61281 7.9171 5.10192C9.1505 4.59103 10.5077 4.45735 11.8171 4.7178C13.1264 4.97825 14.3292 5.62113 15.2732 6.56513C16.2172 7.50914 16.8601 8.71187 17.1205 10.0212C17.381 11.3306 17.2473 12.6878 16.7364 13.9212C16.2255 15.1546 15.3603 16.2088 14.2503 16.9505C13.1403 17.6922 11.8352 18.0881 10.5002 18.0881C8.71061 18.0861 6.99488 17.3743 5.72944 16.1089C4.464 14.8434 3.7522 13.1277 3.75021 11.3381Z"
        fill={fill}
      />
    </svg>
  );
};

export default MagnifyingGlass;
