import Logo from "@assets/icons/RebrandedIcons/Logo";
import { Text } from "@common/Text";
import { ITextProps } from "@common/Text/Text";
import { LoginExternalLink } from "@components/Login";
import { Grid, Stack, StackProps, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

interface Props extends StackProps {
  color?: string;
  hideSupport?: boolean;
  hideLogo?: boolean;
  linkGoupeWrapperStyle?: StackProps["sx"];
}

const Footer = ({
  color = palette.black[100],
  linkGoupeWrapperStyle,
  hideSupport,
  hideLogo,
  ...rest
}: Props) => {
  const { isMobileView } = useCustomTheme();

  return (
    <Stack
      width="100%"
      flexDirection="row"
      alignItems="end"
      justifyContent="space-between"
      display="flex"
      padding="25px 16px"
    >
      <StyledContainer {...rest}>
        <StyledText color={color}>
          All rights reserved ©Give Corporation {new Date().getFullYear()}
        </StyledText>
        {isMobileView || hideLogo ? null : (
          <Grid paddingLeft="110px">
            <Text textAlign="center">Powered by</Text>
            <Logo height={14} />
          </Grid>
        )}
        <Stack
          gap={{
            xs: "8px",
            md: 3,
          }}
          direction={{
            xs: "column",
            md: "row",
          }}
          sx={linkGoupeWrapperStyle}
        >
          {!hideSupport && (
            <LoginExternalLink
              underline={palette.black[100]}
              href="mailto:support@givecorporation.com"
            >
              <StyledText color={color}>Support</StyledText>
            </LoginExternalLink>
          )}
          <LoginExternalLink underline={palette.black[100]} href="/privacy">
            <StyledText color={color}>Privacy Policy</StyledText>
          </LoginExternalLink>
          <LoginExternalLink
            underline={palette.black[100]}
            href="/terms_of_service"
          >
            <StyledText color={color}>Terms &amp; Conditions</StyledText>
          </LoginExternalLink>
        </Stack>
      </StyledContainer>
    </Stack>
  );
};

const StyledContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",

  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    gap: "10px",
    flexDirection: "column-reverse",
  },
}));

const StyledText = styled(Text)<ITextProps & { color?: string }>(
  ({ theme, color }) => ({
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "18.9px",
    color: color || palette.black[100],

    [theme.breakpoints.down("sm")]: {
      lineHeight: "16.8px",
      fontWeight: 350,
    },
  }),
);

export default Footer;
