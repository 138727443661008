import { Box, Divider, Grid, Stack } from "@mui/material";
import { useEffect } from "react";
import * as Yup from "yup";
import {
  ProfileSetupFormActions,
  ProfileSetupFormContainer,
  ProfileSetupFormTitle,
} from "../form.components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFCheckbox } from "@common/Checkbox";
import { matchIsValidTel } from "mui-tel-input";
import { urlSchema } from "@utils/validation.helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { MerchantSlugInput, NameInput } from "@common/BusinessProfileInputs";
import { removeSpecialChars } from "@utils/slug";
import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import { generateNameLabelPlaceholder } from "@components/Signup/Forms/SignupOrganizationDetails";
import usePercentageUpdate from "./hooks/usePercentageUpdate";
import { TBusinessStepsCommons } from "./BusinessProfileSetup";
import { DynamicReturnType } from "./helpers/refineData";
import placeholder from "@assets/images/avatar-placeholder.png";
import { VisaLogo } from "@assets/logos";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import {
  BillingDescriptorInput,
  StatementPreview,
} from "./BillingDescriptorSection";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { EDIT_DENY_MESSAGE, OPERATIONS } from "@constants/permissions";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";

interface IProps extends TBusinessStepsCommons {
  data: DynamicReturnType["merchantInfo"];
}

type FormInputs = {
  name: string;
  slug: string;
  servicePhoneNumber: string;
  websiteURL: string;
  annualCreditCardSalesVolume: string;
  averageTicketAmount: string;
  serviceCountriesOutUSCanada: boolean;
  highTicketAmount: string;
  countriesServicedOutside: string;
  billingDescriptor: string;
};

function MerchantInfo({
  submitHandler,
  handleBack,
  statusBar,
  updateStatusBar,
  data,
  isSubmitting,
}: IProps) {
  const isUpdateAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const methods = useForm<FormInputs>({
    resolver: yupResolver(merchantInfoSchema),
    defaultValues: data,
  });
  const {
    watch,
    handleSubmit,
    formState: { dirtyFields, isDirty },
  } = methods;
  const values = watch();

  usePercentageUpdate<FormInputs>(
    values,
    dirtyFields,
    merchantInfoSchema,
    updateStatusBar,
  );
  const { isMobileView } = useCustomTheme();
  const parsedSlug = removeSpecialChars(values.name);

  useEffect(() => {
    methods.setValue("slug", parsedSlug);
  }, [parsedSlug]);

  const displayedName = generateNameLabelPlaceholder(data.class.name);
  const avatarImage = data?.imageURL ? `${data.imageURL}/thumb` : placeholder;

  const nodeList: Array<{
    node: JSX.Element;
    sm: number;
    shouldAnimate?: boolean;
    hidden?: boolean;
    hideTooltip?: boolean,
  }> = [
    {
      node: (
        <NameInput
          name="name"
          label={`${displayedName} Name`}
          helper={
            values.name === ""
              ? `The saved merchant name "${data?.name}" is used when the field is blank`
              : ""
          }
          isMerchantName
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <MerchantSlugInput
          name="slug"
          parentName={data?.parentSlug}
          label="Permalink"
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <WebsiteInput
          name="websiteURL"
          label={"Website URL"}
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <RHFTelInput
          label={`Customer Service Phone Number`}
          name="servicePhoneNumber"
          fullWidth
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <RHFCustomAmountInput
          name="annualCreditCardSalesVolume"
          label="Estimated Annual Revenue"
          placeholder="0"
          bounded={false}
          initialValue={data?.annualCreditCardSalesVolume}
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <RHFCustomAmountInput
          name="highTicketAmount"
          label="High Ticket Amount (USD)"
          placeholder="0"
          bounded={false}
          initialValue={data?.highTicketAmount}
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <RHFCustomAmountInput
          name="averageTicketAmount"
          label="Average Ticket Amount (USD)"
          placeholder="0"
          bounded={false}
          initialValue={data?.averageTicketAmount}
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      sm: 12,
      node: (
        <RHFCheckbox
          name="serviceCountriesOutUSCanada"
          label="My Business services countries outside of the USA/Canada."
          disabled={!isUpdateAllowed}
        />
      ),
    },
    {
      node: (
        <RHFInput
          name="countriesServicedOutside"
          label="Countries Serviced Outside USA/Canada"
          placeholder="Please enter the countries serviced outside of USA/Canada."
          multiline
          rows={6}
          fullWidth
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
      shouldAnimate: false,
      hidden: !values?.serviceCountriesOutUSCanada,
    },
    {
      node: <Divider />,
      sm: 12,
      hidden: isMobileView,
      hideTooltip: true,
    },
    {
      node: (
        <BillingDescriptorInput
          billingDescriptor={values.billingDescriptor}
          prefix={data?.billingDescriptorPrefix}
          disabled={!isUpdateAllowed}
        />
      ),
      sm: 12,
    },
    {
      node: (
        <StatementPreview
          billingDescriptor={values.billingDescriptor}
          prefix={data?.billingDescriptorPrefix}
          avatarImage={avatarImage}
          issuer={<VisaLogo />}
          date=" 2 May 2024 14:05"
          amount="-12.99 USD"
          isMobileView={isMobileView}
        />
      ),
      sm: 12,
      hideTooltip: true,
    },
  ];

  const onSubmit: SubmitHandler<FormInputs> = (data) =>
    submitHandler("merchantInfo", data, {
      makeApiCall: isDirty,
      dirtyFields,
    });

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        flexGrow={1}
        id="merchant-info-form"
        display="flex"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ProfileSetupFormContainer>
          <Stack direction="column" gap={4} height="min-content">
            <ProfileSetupFormTitle title={`${displayedName} Info`} />
            <Grid container rowSpacing="12px">
              {gridItemsRenderer(nodeList, {
                show: !isUpdateAllowed,
                message: EDIT_DENY_MESSAGE,
              })}
            </Grid>
          </Stack>
          <ProfileSetupFormActions
            secondaryAction={{
              onClick: handleBack,
            }}
            primaryAction={{
              disabled: statusBar < 100 || isSubmitting,
              children: "Done",
              onClick: () => onSubmit(values),
            }}
          />
        </ProfileSetupFormContainer>
      </Box>
    </FormProvider>
  );
}

export default MerchantInfo;

export const billingDescriptorSchema = Yup.string()
  .required("Billing descriptor is required")
  .matches(
    /^.{3,24}$/,
    "Descriptor must match this format 'GBX*BILLINGDESCRIPTOR' and must be between 3 to 24 characters length",
  );

export const merchantInfoSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  slug: Yup.string(),
  servicePhoneNumber: Yup.string()
    .required("A phone is required")
    .when({
      is: (exists: string) => Boolean(exists) && exists !== "+1",
      then: (schema) =>
        schema.test(
          "is-valid-number",
          "Please enter a valid phone number",
          function (value) {
            const phoneNumber = value as string;
            const isPhoneValid = matchIsValidTel(phoneNumber);

            if (isPhoneValid) {
              this.createError({
                message: "Plese enter a valid phone Number",
                path: "servicePhoneNumber",
              });
            }
            return matchIsValidTel(phoneNumber);
          },
        ),
    })
    .when({
      is: (exists: string) => {
        return Boolean(exists) && exists === "+1";
      },
      then: (schema) =>
        schema.test(
          "is-empty-number",
          "Please enter a phone number",
          function (value, ctx) {
            const phoneNumber = value as string;

            return phoneNumber.length > 2 && matchIsValidTel(phoneNumber);
          },
        ),
    }),
  websiteURL: urlSchema({ isRequired: true }),
  annualCreditCardSalesVolume: Yup.string().required(
    "Estimated annual revenue is required",
  ),
  averageTicketAmount: Yup.string().required(
    "Average ticket amount is required",
  ),
  serviceCountriesOutUSCanada: Yup.boolean(),
  highTicketAmount: Yup.string().required("High ticket amount is required"),
  countriesServicedOutside: Yup.string(),
  billingDescriptor: billingDescriptorSchema,
});
