import { namespaces } from "../../../../resources/i18n.constants";

export const fundraiserMinibuilder = {
  [namespaces.pages.fundraiserMinibuilder]: {
    general: "General",
    payment: "Payment",
    create_fundraiser: "Create Fundraiser",
    edit_fundraiser: "Edit Fundraiser",
    amounts: "Amounts",
    "Any Amount": "Any Amount",
    "Any amount is highly appreciated, we are empowered by people like you.":
      "Any amount is highly appreciated. We are empowered by people like you.",
    amount: "Amount",
    title: "Title",
    description: "Description",
    optional_but_recommended: "(Optional but recommended)",
    please_enter_a_suitable_title: "Please enter a suitable title",
    "What’s the purpose of this fundraiser?":
      "What’s the purpose of this Fundraiser?",
    browse_more_campaigns: "Browse More Campaigns",
    "If enabled, users will be able to explore other published campaigns of this Organization.":
      "If enabled, users will be able to explore other published campaigns of this Organization.",
    featured_image: "Featured Image",
    "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000":
      "This image should be representative of the campaign and it will be displayed on top the payment form, can be later repositioned. Minimum size recommended 2000x2000",
    use_as_background_image: "Use as Background Image",
    "If checked this image will be integrated in the background of the form, can be later changed":
      "If checked this image will be integrated in the background of the form, can be later changed",
    media_library: "Media Library",
    add_new_image: "Add New Image",
    no_images: "No images",
    // Payment
    add_amount: "Add Amount",
    thumbnail: "Thumbnail",
    amount_title_is_mandatory: "El título de la cantidad es obligatorio",
    must_be_above_one_dollar: "Debe estar por encima de 1USD",
    thank_you_for_your_contribution: "Thank you for your contribution",
    "between 1.00 and 25,000.00": "Entre 1,00 y 25.000,00",
    should_be_at_least: "Debe ser al menos 1",
    give_some_insight_on_how_this_contribution_will_be_used:
      "Give some insight on how this contribution will be used",
    save_changes: "Save Changes",
    cancel: "Cancel",
    remove: "Remove",

    set_min_max_value: "Set Min and Max value",
    minimum: "Minimum",
    min: "Min",
    max: "Max",
    maximum: "Maximum",
    set_min_max_value_text:
      "<span>Minimum</span> value can be helpful when dealing with additional costs of running the campaign, whereas a <span>maximum</span> value can be set for to political reasons.",

    payment_types: "Payment Types",
    "Choose the payment types available to your customers":
      "Choose the payment types available to your customers",
    one_time: "One Time",
    "One-Time Payment, no recurring involved.":
      "One-Time Payment, no recurring involved.",
    monthly: "Monthly",
    "Payment will repeat every month from the date of payment":
      "Payment will repeat every month from the date of payment",
    quarterly: "Quarterly",
    "Payment will repeat every 3 months from the date of payment":
      "Payment will repeat every 3 months from the date of payment",
    yearly: "Yearly",
    "Payment will repeat every year from the date of payment":
      "Payment will repeat every year from the date of payment",
    default: "Default",

    if: "If",
    defaulted: "Defaulted",
    this_type_will_be_selected_by_default:
      "this type will be selected by default",

    properties: "Properties",
    customer_pays_the_credit_card_fee: "Customer Pays the Credit Card Fee",
    "If toggled off, the Nonprofit covers the credit card fee.":
      "The customer covers the cost of the credit card fee unless the option for them choose is provided. If this is toggled off the Nonprofit pays the credit card fee.",
    make_it_optional: "Make it Optional",
    "If checked customers will have the choice of not covering the Credit Card Fee":
      "If checked customers will have the choice of not covering the Credit Card Fee",
    // Fundraiser Successful
    fundraiser_successfully_created: "Fundraiser successfully created",
    "If you’re on a desktop or a laptop":
      "If you’re on a desktop or a laptop, you can continue editing in <span>the Advanced Builder</span>. The Advanced Builder offers powerful customization tools to help you take your campaign to the next level and ultimately increase conversion.",
    fundraiser_create_success_description:
      "Congratulations, your payment form has been created successfully and it's ready to accept payments, you can check its details and share it everywhere or browse more payment forms",
    open_the_advanced_builder: "Open the Advanced Builder",
    edit_in_advanced_builder: "Edit in Advanced Builder",
    done: "Done",
  },
};
