import React, { useRef, useState } from "react";
import { Menu, MenuProps, Box, Stack, styled, SxProps } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";

import useUpdateStatus, {
  TRiskAction,
} from "features/TransactionsRiskProfile/hooks/useUpdateStatus";
import { QKEY_LIST_RISK_PROFILE_TRANSACTIONS } from "@constants/queryKeys";
import { Button } from "@common/Button";
import TransactionStatusBase, {
  TransactionDisplayStatus,
} from "./TransactionStatusBase";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { TTransactionStatusData } from "features/TransactionsRiskProfile/data.types";

export const TransactionStatus = ({
  id,
  merchantId,
  data,
  width,
  customStyle,
}: {
  id: string;
  merchantId: number;
  data: TTransactionStatusData;
  width: number;
  customStyle?: any;
}) => {
  const { updateStatusAction } = useUpdateStatus(
    QKEY_LIST_RISK_PROFILE_TRANSACTIONS,
    merchantId,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLParagraphElement | null>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLParagraphElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const triggerAction = (action: TRiskAction) => {
    updateStatusAction(id, action);
    closeMenu();
  };

  const isBlockAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.TRANSACTION_BLOCK,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const isVoidedAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
    operation: OPERATIONS.CREATE,
  });

  const isUnblockAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.TRANSACTION_UNBLOCK,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const { risk_activity } = useEnterprisePermissions();

  const quarantinedThxActions: TAction[] = [
    {
      label: TransactionStatusBase["voided"],
      onClick: () => triggerAction("voided"),
      disabled: !isVoidedAllowed,
      tooltipProps: {
        show: !isVoidedAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
    },
    {
      label: TransactionStatusBase["blocked"],
      onClick: () => triggerAction("blocked"),
      disabled: !isBlockAllowed,
      tooltipProps: {
        show: !isBlockAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
      sx: {
        paddingLeft: "16px",
      },
    },
  ];

  const blockedThxActions: TAction[] = [
    {
      label: TransactionStatusBase["false_positive"],
      onClick: () => triggerAction("false_positive"),
      disabled: !isUnblockAllowed,
      tooltipProps: {
        show: !isUnblockAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
      sx: {
        paddingLeft: "16px",
      },
    },
  ];

  const status = data.status;
  const isBlocked = status === "blocked";
  const isQuarantined = status === "quarantined";
  const isFalsePositive = status === "false_positive";

  const onClick =
    (isQuarantined || isBlocked) && risk_activity ? openMenu : undefined;
  const actions = isBlocked ? blockedThxActions : quarantinedThxActions;

  return (
    <>
      <Box
        alignSelf="stretch"
        display="flex"
        alignItems="center"
        flexDirection="row"
        width={`${width}%`}
        {...customStyle}
      >
        <Box
          data-testid="transaction-status-button"
          onClick={onClick}
          sx={{
            "&:hover": {
              ...(status === "quarantined" && {
                cursor: "pointer",
              }),
            },
            ...((isFalsePositive || isBlocked) && {
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              alignItems: "center",
            }),
          }}
        >
          {TransactionStatusBase[status]}
          {(isFalsePositive || isBlocked) && (
            <TransactionDisplayStatus status={data.displayStatus} />
          )}
        </Box>
        <CustomMenu
          open={open}
          anchorEl={anchorEl}
          onClose={closeMenu}
          actions={actions}
          onCancel={closeMenu}
        />
      </Box>
    </>
  );
};

type TAction = {
  label: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
  tooltipProps?: { show: boolean; message: string };
};

const CustomMenu = ({
  actions,
  onCancel,
  ...props
}: {
  actions: TAction[];
  onCancel: () => void;
} & MenuProps) => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const actionCallback = useRef<(() => void) | undefined>(undefined);

  const resetState = () => {
    actionCallback.current = undefined;
    props?.onClose?.({}, "backdropClick");
    setIsConfirm(false);
  };

  const handleCancel = () => {
    resetState();
    onCancel();
  };

  const handleConfirm = () => {
    actionCallback.current?.();
    resetState();
  };

  return (
    <Menu
      {...props}
      onClose={resetState}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: 100,
        horizontal: "center",
      }}
      sx={{
        "& .MuiPaper-root": {
          width: 200,
          boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.15)",
          backgroundColor: "#FBFBFB",
          borderRadius: "16px",
        },
      }}
    >
      <Text
        color={palette.black[100]}
        fontWeight="book"
        lineHeight="16.8px"
        align="center"
        padding="8px"
      >
        {isConfirm ? "Confirm" : "Set new status"}
      </Text>
      <Divider />
      <Stack direction="column" alignItems="stretch" gap="2px" padding="8px">
        {isConfirm ? (
          <ConfirmationDialog
            onCancel={handleCancel}
            onConfirm={handleConfirm}
          />
        ) : (
          actions.map(
            ({ label, onClick, disabled, sx, tooltipProps }, index) => (
              <WithTooltipWrapper
                key={index}
                hasTooltip={!!tooltipProps}
                tooltipProps={tooltipProps}
              >
                <ActionItem
                  key={index}
                  onClick={() => {
                    if (disabled) return;
                    actionCallback.current = onClick;
                    setIsConfirm(true);
                  }}
                  disabled={disabled}
                  sx={sx}
                >
                  {label}
                </ActionItem>
              </WithTooltipWrapper>
            ),
          )
        )}
      </Stack>
    </Menu>
  );
};

const ConfirmationDialog = ({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm?: () => void;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" padding="0 8px">
      <Button background="tertiary" size="small" onClick={onCancel}>
        Cancel
      </Button>
      <Button background="primary" size="small" onClick={onConfirm}>
        Yes
      </Button>
    </Stack>
  );
};

const ActionItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled }) => ({
  cursor: "pointer",
  borderRadius: "4px",
  userSelect: "none",

  "&:hover": {
    backgroundColor: palette.liftedWhite[100],
  },
  ...(disabled && {
    cursor: "default",
    backgroundColor: "inherit !important",
    opacity: 0.8,
  }),
}));

const Divider = styled(Box)(() => ({
  height: "1px",
  backgroundColor: palette.liftedWhite[200],
  width: "100%",
}));
