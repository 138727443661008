import React from "react";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";

const ExpirationInput = () => {
  const { setValue, watch } = useFormContext();

  const normalizeInput = (value: string) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (cvLength < 3) return currentValue;
    if (cvLength < 6)
      return `${
        parseInt(currentValue.slice(0, 2)) > 12 ? 12 : currentValue.slice(0, 2)
      } / ${currentValue.slice(2, 5)}`;
  };

  React.useEffect(() => {
    setValue("expiration", normalizeInput(watch("expiration")));
  }, [watch("expiration")]);

  return (
    <RHFInput
      name="expiration"
      label="Expiration"
      fullWidth
      inputProps={{
        maxLength: "7",
      }}
      placeholder="MM / YY"
      sx={{
        letterSpacing: "6px",
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
      }}
    />
  );
};

export default ExpirationInput;
