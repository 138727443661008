import { isArray } from "lodash";
import { useRef, useState } from "react";
import { ReactCropperElement } from "react-cropper";

interface StateType {
  zoom: number;
  rotation: number;
  croppedAreaPixels: any;
  file: File | null;
}

type TZoomParams = {
  minZoom: number;
  maxZoom: number;
  step: number;
};

const initialState: StateType = {
  zoom: 0,
  rotation: 0,
  croppedAreaPixels: null,
  file: null,
};

const useImageCropper = (
  zoomParams: TZoomParams,
  handleUpdateImage: (url: HTMLCanvasElement) => void,
) => {
  const { minZoom, maxZoom, step } = zoomParams;

  const cropperRef = useRef<ReactCropperElement>(null);
  const [{ zoom, rotation }, setCustomize] = useState<StateType>(initialState);

  const zoomValue = Math.round((zoom / maxZoom) * 100);

  const handleZoomCanvas = (zoom: number) => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) return;

    const scale = normalizeScale(zoom);
    cropper.scale(scale);

    setCustomize((p) => ({ ...p, zoom }));
  };

  const getNewZoomValue = (value: number, isAdd: boolean) => {
    const addValue = value + step >= maxZoom ? maxZoom : value + step;
    const subtractValue = value - step <= minZoom ? minZoom : value - step;
    return isAdd ? addValue : subtractValue;
  };

  const handleZoom = (isAdd = false) => {
    const newValue = getNewZoomValue(zoom, isAdd);
    handleZoomCanvas(newValue);
  };

  const rotate = () => {
    const cropper = cropperRef.current?.cropper;
    cropper?.rotate(-90);
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      handleUpdateImage(cropperRef.current?.cropper.getCroppedCanvas());
    }
  };

  const handleUpdateSlider = (event: Event, zoom: number | number[]) => {
    const newZoomValue = isArray(zoom) ? zoom[0] : zoom;
    handleZoomCanvas(newZoomValue);
  };

  return {
    cropperRef,
    handleUpdateSlider,
    getCropData,
    rotate,
    handleZoom,
    zoomValue,
    rotation,
  };
};

export default useImageCropper;

const normalizeScale = (zoom: number) => {
  const newVal = Math.min(100, Math.max(0, zoom));
  return 1 + newVal / 100;
};
