export const EnterpriseConfigurationList = [
  {
    name: "modify_merchant",
    displayName: "Grant Full Permissions to Create and Modify Merchants",
    description:
      "If enabled, the Provider can add and edit all Merchant fields.",
    group: "Permissions Management",
    isActive: false,
    configurationID: 1,
  },
  {
    name: "merchant_underwriting",
    displayName: "Enable Merchant Underwriting",
    description:
      "If enabled, Providers have full underwriting access, including approvals, notifications, document management, and PEP/OFAC checks.",
    group: "Permissions Management",
    isActive: false,
    configurationID: 2,
  },
  {
    name: "merchant_underwriting_statuses",
    displayName: "Permit Underwriting Approvals",
    description: "If enabled, the Provider can approve Merchants.",
    group: "Permissions Management",
    isActive: false,
    configurationID: 3,
  },
  {
    name: "agreement_signing",
    displayName: "Enable Merchant Agreement Signing",
    description: "If enabled, the Provider can sign a Merchant Agreement.",
    group: "Permissions Management",
    isActive: false,
    configurationID: 4,
  },
  {
    name: "control_mode",
    displayName: "Permit Control Mode of a Merchant",
    description: "If enabled the Provider can view and go into control mode.",
    group: "Permissions Management",
    isActive: false,
    configurationID: 5,
  },
  {
    name: "b_profile_linking",
    displayName:
      "Authorize Merchants to Associate with a Provider Business Profile",
    description:
      "If enabled, the Provider can link their Business Profile to a Merchant.",
    group: "Merchant Integration",
    isActive: false,
    configurationID: 6,
  },
  {
    name: "bank_account_linking",
    displayName: "Authorize Merchants to Connect to a Provider Bank Account",
    description:
      "If enabled, the Provider can link their Bank Account to a Merchant.",
    group: "Merchant Integration",
    isActive: false,
    configurationID: 7,
  },
  {
    name: "manage_bank_account",
    displayName: "Prohibit Merchants from Creating Bank Accounts",
    description:
      "If enabled the Provider can prohibit the merchants to create or edit Bank Accounts.",
    group: "Merchant Integration",
    isActive: false,
    configurationID: 8,
  },
  {
    name: "money_transfers",
    displayName: "Prohibit Merchants from Conducting Money Transfers",
    description:
      "If enabled the Provider can prohibit the merchants to make money transfers.",
    group: "Merchant Integration",
    isActive: false,
    configurationID: 9,
  },
  {
    name: "risk_monitoring",
    displayName: "Enable Merchant and Transaction Risk Monitoring",
    description:
      "If enabled, the Provider can view Merchant and transaction risk monitoring.",
    group: "Risk Management",
    isActive: false,
    configurationID: 10,
  },
  {
    name: "merchant_triggers",
    displayName: "Permit Disabling Merchant Triggers",
    description:
      "If enabled, the Provider can disable Merchant specific risk triggers.",
    group: "Risk Management",
    isActive: false,
    configurationID: 11,
  },
  {
    name: "risk_activity",
    displayName: "Permit Managing Risk Activity",
    description:
      "If enabled, the Provider can set Merchant Risk Activity to OK and block transactions.",
    group: "Risk Management",
    isActive: false,
    configurationID: 12,
  },
];

export const EnterpriseConfigurationGroups = [
  "Permissions Management",
  "Merchant Integration",
  "Risk Management",
];
