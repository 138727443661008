import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { useSendReceipt } from "@hooks/common/useSendReceipt";
import Popup from "@common/Popup/Popup";
import { fakeSourceAccountData } from "./Refund";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { FormProvider } from "react-hook-form";
import { RadioGroup, Stack } from "@mui/material";
import { RHFInput } from "@common/Input";
import { FormControlLabel } from "@common/FormControlLabel";
import { Radio } from "@common/Radio";
import { ArrowElbowDownLeft, X } from "@phosphor-icons/react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import { Button } from "@common/Button";
import LoadingButton from "@common/Button/LoadingButton";

type Props = {
  transactionID: string;
  emails: string[];
  sourceAccount: any;
  customerID?: number;
};

const SendReceipt = NiceModal.create(
  ({
    transactionID,
    sourceAccount = fakeSourceAccountData,
    customerID,
  }: Props) => {
    const modal = useModal();
    const {
      isLoading,
      handleSubmit,
      methods,
      inputRef,
      handleAddEmails,
      handleChange,
      handleDeleteEmail,
      resetForm,
    } = useSendReceipt({
      transactionID,
      customerID,
    });
    const { isMobileView } = useCustomTheme();
    const { original, other, emails } = methods.watch();
    const ML = isMobileView ? "0px" : "30px";

    const handleSend = () => {
      handleSubmit({ recipients: original ? [] : [...emails] }, modal);
    };

    const content = (
      <Stack gap={2}>
        <Text fontSize="14px" color={palette.filled.grey} fontWeight="book">
          Please confirm if you would like to resend the email receipt to the
          original recipient or send it to a different email address.
        </Text>
        <FormProvider {...methods}>
          <RadioGroup sx={{ gap: 2 }}>
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  radioIconSize="20px"
                  data-testid="resend-reciept-original-option"
                />
              }
              label={<Label email={sourceAccount.email} />}
              checked={original}
              onChange={(e, checked) => handleChange("original", checked)}
            />
            <FormControlLabel
              control={
                <Radio
                  size="small"
                  radioIconSize="20px"
                  data-testid="resend-reciept-other-recipients-option"
                />
              }
              label="Send to other recipients"
              sx={labelProps}
              checked={other}
              onChange={(e, checked) => handleChange("other", checked)}
            />
          </RadioGroup>
          {other && (
            <RHFInput
              inputRef={inputRef}
              name="email"
              fullWidth
              placeholder="Enter email"
              endIcon={<EndIcon />}
              onKeyDown={handleAddEmails}
              sx={{
                ml: ML,
                "& .MuiInputBase-root": {
                  borderWidth: "1px",
                  pt: "12px",
                  pb: "12px",
                },
              }}
            />
          )}
        </FormProvider>
        {!original && (
          <Stack direction="row" gap="4px" ml={ML} flexWrap="wrap">
            {emails?.map((item, index) => (
              <EmailTag key={index} email={item} onDelete={handleDeleteEmail} />
            ))}
          </Stack>
        )}
      </Stack>
    );

    const onClose = () => {
      resetForm();
      modal.hide();
    };

    if (isMobileView) {
      return (
        <SwipeableDrawerMobile
          anchor="bottom"
          onOpen={() => undefined}
          open={modal.visible}
          onClose={onClose}
          sx={{
            "& .MuiPaper-root": {
              background: palette.background["dimmedWhite"],
            },
            zIndex: 1201,
          }}
        >
          <Text
            fontSize="18px"
            fontWeight="book"
            color={palette.black[100]}
            sx={{
              marginBottom: "8px",
            }}
          >
            Resend Receipt
          </Text>
          {content}
          <Stack gap="16px" padding="16px 0px" alignItems="center">
            <LoadingButton
              background="primary"
              size="medium"
              isLoading={isLoading}
              disabled={isLoading || (!original && emails.length === 0)}
              onClick={handleSend}
              sx={resendButtonSx}
            >
              Resend Receipt
            </LoadingButton>
            <Button
              size="medium"
              onClick={onClose}
              sx={cancelButtonSx}
              background="tertiary"
            >
              Cancel
            </Button>
          </Stack>
        </SwipeableDrawerMobile>
      );
    }

    return (
      <Popup
        {...muiDialogV5(modal)}
        isClose
        CustomCloseIcon={<X size={24} color={palette.black[100]} />}
        title="Resend Receipt"
        onCancel={onClose}
        onSubmit={handleSend}
        actionLabel="Resend Receipt"
        isSubmitDisabled={isLoading}
        isLoading={isLoading}
        submitButtonProps={{
          size: "medium",
          disabled: !original && emails.length === 0,
          sx: resendButtonSx,
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "720px",
          },
          "& .MuiDialogTitle-root": {
            background: palette.liftedWhite.main,
            padding: "12px 16px 0",
            "& > button": {
              background: palette.liftedWhite.main,
            },
          },
          "@media (max-width: 600px)": {
            "& .MuiPaper-root": {
              maxWidth: "100%",
            },
            "& .MuiDialogActions-root": {
              flexDirection: "column",
            },
          },
        }}
        titleSx={{
          fontSize: "18px",
        }}
        modalHeaderCustomStyles={{
          padding: "0px 24px",
        }}
        modalActionsCustomStyles={{
          padding: "20px 24px 16px 24px",
        }}
        cancelButtonSx={cancelButtonSx}
      >
        {content}
      </Popup>
    );
  },
);

export default SendReceipt;

const labelProps = {
  fontSize: 14,
  color: palette.neutral[80],
};

const resendButtonSx = {
  padding: "12px 24px",
  width: "176px",
  height: "46px",
  fontSize: "18px",
  borderRadius: "32px",
};

const cancelButtonSx = {
  padding: "4px 8px 4px 8px",
  fontSize: "18px",
  width: "74px",
  height: "30px",
  "&:hover": {
    textDecoration: "none",
  },
  alignSelf: "center",
};

function Label({ email }: { email: string }) {
  return (
    <Stack direction="row" gap="3px" flexWrap="wrap">
      <Text {...labelProps}>Original transaction recipient:</Text>
      <Text
        {...labelProps}
        fontWeight={700}
        sx={{ wordBreak: "break-all", flexBasis: "100%", minWidth: 0 }}
      >
        {email}
      </Text>
    </Stack>
  );
}

function EndIcon() {
  return (
    <Stack direction="row" gap="3px" alignItems="center">
      <Text color={palette.neutral[60]} fontSize="12px">
        Press
      </Text>
      <ArrowElbowDownLeft height="14px" color={palette.gray.icon} />
      <Text color={palette.neutral[60]} fontSize="12px">
        to Add
      </Text>
    </Stack>
  );
}

function EmailTag({
  email,
  onDelete,
}: {
  email: string;
  onDelete: (email: string) => void;
}) {
  return (
    <Stack
      direction="row"
      borderRadius="4px"
      gap="4px"
      alignItems="center"
      padding="2px 8px"
      sx={{ backgroundColor: palette.neutral[20] }}
    >
      <Text fontSize="12px" color={palette.neutral[80]}>
        {email}
      </Text>
      <X
        color={palette.gray.icon}
        onClick={() => onDelete(email)}
        data-testid="email-tag-delete-icon"
        cursor="pointer"
      />
    </Stack>
  );
}
