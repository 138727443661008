import * as React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, {
  IDropzoneProps,
  IStyleCustomization,
} from "react-dropzone-uploader";
import Input from "./Input";
import Layout from "./Layout";
import Preview from "./Preview";
import { palette } from "@palette";
import { makeStyles } from "@mui/styles";
import { bytesToSize } from "../../../utils";

const defaultSupportedFormatText = ".pdf, .png, .jpg, .jpeg, .webp";

type UploadFileProps = {
  border?: string;
  accept?: string;
  maxFiles?: number;
  multiple?: boolean;
  width?: string | number;
  backgroundColor?: string;
  styles?: IStyleCustomization<React.CSSProperties>;
  onSubmit?: IDropzoneProps["onSubmit"];
  onChangeStatus?: IDropzoneProps["onChangeStatus"];
  getUploadParams?: IDropzoneProps["getUploadParams"];
  getFilesFromEvent?: IDropzoneProps["getFilesFromEvent"];
  initialFiles?: File[];
  disabled?: boolean;
  supportedFormatText?: string;
  maxSizeInBytes?: number;
  onDrop?: (payload: number) => void;
  customText?: string;
};

// In the whole app, the max file size is 5mb => 5000000 bytes
const defaultMaxFileSize = 5000000;

export const UploadFile = React.memo(function UploadFile({
  styles = {
    dropzone: {},
  },
  maxFiles,
  multiple = false,
  onSubmit,
  border,
  onChangeStatus,
  getUploadParams,
  getFilesFromEvent,
  backgroundColor,
  width,
  accept,
  initialFiles,
  disabled = false,
  supportedFormatText = defaultSupportedFormatText,
  maxSizeInBytes = defaultMaxFileSize,
  onDrop,
  customText: customizedTextProp,
}: UploadFileProps) {
  const dropZoneStyles = useDropZoneStyles({
    border,
  });
  const { dropzone, ...restStyle } = styles;
  const dropzoneStyle = {
    dropzone: {
      height: 200,
      width: width || 332,
      minWidth: 330,
      borderRadius: 12,
      margin: 0,
      justifyContent: "center",
      backgroundColor: backgroundColor || palette.neutral[5],
      ...dropzone,
    },
    ...restStyle,
  };

  let customText = `Up to ${
    bytesToSize(maxSizeInBytes).sizeString
  } each (${supportedFormatText})`;

  if (maxFiles) {
    customText = `Max. ${maxFiles} file${maxFiles > 1 ? "s" : ""}, up to ${
      bytesToSize(maxSizeInBytes).sizeString
    } each (${supportedFormatText})`;
  }

  return (
    <Dropzone
      styles={dropzoneStyle}
      classNames={{ ...dropZoneStyles }}
      disabled={disabled}
      getUploadParams={getUploadParams}
      onChangeStatus={onChangeStatus}
      PreviewComponent={() => <></>}
      LayoutComponent={Layout}
      InputComponent={(prop) => (
        <Input
          {...prop}
          customText={customizedTextProp || customText || ""}
          onDrop={onDrop}
        />
      )}
      onSubmit={onSubmit}
      accept={accept}
      maxFiles={maxFiles}
      multiple={multiple}
      canRemove
      getFilesFromEvent={getFilesFromEvent}
      initialFiles={initialFiles}
      maxSizeBytes={maxSizeInBytes}
    />
  );
});

const useDropZoneStyles = makeStyles(() => ({
  dropzone: {
    border: (props: { border?: string }) =>
      props.border == "none" ? "none" : `2px dashed ${palette.neutral[60]}`,
    "&:hover": {
      border: `2px solid ${palette.neutral[60]}`,
      backgroundColor: "#F8F8F6",
    },
    "&.dzu-dropzoneActive": {
      border: `2px solid ${palette.neutral[60]}`,
      "& > div": {
        backgroundColor: "#F8F8F6",
      },
    },
  },
}));

export const mime = {
  image: "image/webp, image/jpeg, image/png",
  applications: "application/pdf",
  // image: "image/webp, image/jpeg, image/gif, image/png",
  // text: "text/plain, text/csv, text/tab-separated-values, .csv",
  // applications:
  //   "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.apple.keynote, application/vnd.apple.numbers, application/vnd.apple.pages, application/vnd.oasis.opendocument.text, application/rtf, application/vnd.ms-excel",
};
