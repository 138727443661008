import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";
import NiceModal from "@ebay/nice-modal-react";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";
import { useState } from "react";

export const useIterator = ({ dataLen }: { dataLen: number }) => {
  const [selectedRowIdx, setSelectedRowIdx] = useState<any>(-1);

  const onChangeSelectedRowItem = (
    newIdx: number | string,
    currentPage = 1,
    isOldList = false,
  ) => {
    setSelectedRowIdx((current: number) => {
      if (typeof newIdx === "number") {
        return newIdx;
      } else {
        const a = {
          next: 1,
          prev: -1,
        };

        const idx = current + a[newIdx as "next" | "prev"];

        if (isOldList) {
          if (newIdx === "prev") {
            if (currentPage > 1) {
              if (current === 0) {
                return ROWS_PER_PAGE - 1;
              }
            } else {
              /*
            If the current page is 1:
             - if is selected the first item of the list and prev is pressed, do nothing          
          */
              if (current === 0) {
                return 0;
              }
            }
          }

          if (idx >= ROWS_PER_PAGE) {
            return 0;
          }

          if (newIdx === "next") {
            //if there is not more data just do nothing
            if (idx > dataLen) {
              return current;
            }
          }
        }

        return idx;
      }
    });
  };

  const onIterator =
    (setPage: any, currentPage: number) => (newIdx: number | string) => {
      setPage((current: number) => {
        /*
              If the current page is greater than 1:
               - if is selected the first item of the list and prev is pressed, go to previous page and return the index of the last item on the new page          
        */
        if (current > 1 && selectedRowIdx === 0 && newIdx === "prev") {
          return current - 1;
        }

        if (selectedRowIdx + 1 >= ROWS_PER_PAGE && newIdx === "next") {
          return current + 1;
        }

        return current;
      });
      onChangeSelectedRowItem(newIdx, currentPage, true);
    };

  return {
    setSelectedRowIdx,
    onIterator,
    onChangeSelectedRowItem,
    selectedRowIdx,
  };
};

export const useOnOpenSidePanel = ({
  isLoading,
  setPageDispatcher,
  page,
  DMenu,
  compaignType,
  secondaryAction,
}: {
  isLoading: boolean;
  setPageDispatcher: any;
  page: number;
  DMenu: any;
  compaignType?: CampaignType;
  secondaryAction?: (rowData: any) => void;
}) => {
  const onOpenSidePanel = ({
    rowData,
    setSelectedRow,
    isFirst,
    isLast,
  }: {
    rowData: any;
    setSelectedRow: any;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    if (rowData) {
      NiceModal.show(TRANSACTION_INFO_MODAL, {
        data: rowData,
        DMenu: DMenu,
        setSelectedRow: setSelectedRow(setPageDispatcher, page),
        isFirst,
        isLast,
        compaignType,
      });
      secondaryAction && secondaryAction(rowData);
    }
  };

  return {
    onOpenSidePanel,
  };
};
