import React from "react";
import { Link, LinkProps as MuiLinkProps } from "@mui/material";
import { Link as RouterLink, LinkProps } from "react-router-dom";

const LoginCustomLink = ({
  underlineColor,
  children,
  to,
  ...rest
}: LinkProps & MuiLinkProps & { underlineColor?: string }) => {
  return (
    <Link
      component={RouterLink}
      underline="none"
      color="inherit"
      to={to}
      sx={{
        ...(underlineColor && {
          "&:hover": {
            textDecoration: "underline",
            color: underlineColor,
          },
        }),
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default LoginCustomLink;
