import { customInstance } from "@services/api";

export const getUserPermissions = () =>
  customInstance({
    url: `/permissions`,
    method: "GET",
  });

type TPermissionID = string;
type TAttachment = TPermissionID[] | ["*"];

export type TPatchPayload = Partial<{
  resetToDefault: boolean;
  detach: TAttachment;
  attach: TAttachment;
  deny: TPermissionID[];
  allow: TPermissionID[];
}>;

export const updateUserPermissions = (
  id: number,
  memberId: number,
  data: TPatchPayload,
) =>
  customInstance({
    url: `/accounts/${id}/members/${memberId}/permissions`,
    method: "PATCH",
    data,
  });
