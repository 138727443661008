import { createTheme } from "@mui/material/styles";
import { palette } from "./colors";
import { components } from "./components";
import { typography } from "./typography";
import { breakpoints } from "./breakpoints";
import { customShadows } from "@theme/shadows";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      shadows: { [key: string]: string };
    };
  }
  interface ThemeOptions {
    custom: {
      shadows: { [key: string]: string };
    };
  }
}

const theme = createTheme({
  palette: palette,
  components: components,
  typography: typography,
  breakpoints,
  custom: {
    shadows: customShadows,
  },
});

export default theme;
