import { showMessage } from "@common/Toast";
import { useFormData } from "@components/ManageMoney/TransferMoney/TransferMoneyFormProvider";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { SubmitHandler } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { useGetMerchantBankAccount } from "./useGetMerchantBankAccount";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { QKEY_LIST_MERCHANT_STATS } from "@constants/queryKeys";

export const useTransferMoney = ({
  action,
}: {
  action?: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const queryClient = useQueryClient();
  const { merchantId } = useGetCurrentMerchantId();
  const { setFormValues } = useFormData();

  const {
    data: bankAccounts,
    isError: areBankAccountsError,
    isLoading,
  } = useGetMerchantBankAccount(merchantId);

  const {
    data: { linkedBankAccount } = {},
    isLoading: merchantLoading,
    isError: merchantError,
  } = useGetMerchantById({ merchantId: merchantId });

  const transferMoneyMutation = useMutation((data: Record<string, any>) => {
    return customInstance({
      url: buildMerchantEndpoints(`transfers`),
      method: "POST",
      data,
    });
  });

  const handleTransferMoneySubmit: SubmitHandler<Record<string, any>> = (
    data,
  ) => {
    const customData = {
      ...data,
      ...(linkedBankAccount?.id === data.bankAccountID && {
        bankAccountID: null,
        linkedBankAccountID: linkedBankAccount?.id,
      }),
    };

    transferMoneyMutation.mutate(customData, {
      onSuccess: (res) => {
        const bankAccount = bankAccounts?.data?.find(
          (bankAccount: any) => bankAccount.id === data.bankAccountID,
        );

        setFormValues("transferMoney", {
          account: {
            id: data.bankAccountID,
            name: bankAccount?.name,
            bank: bankAccount?.bankName,
            ...(linkedBankAccount?.id === data.bankAccountID && {
              name: linkedBankAccount?.name,
              bank: linkedBankAccount?.bankName,
            }),
          },
          amount: data.amount,
          fees: res.fees,
        });

        showMessage("Success", "Transfer created successfully");
        queryClient.invalidateQueries("get-all-transactions");
        queryClient.invalidateQueries(QKEY_LIST_MERCHANT_STATS);
        queryClient.refetchQueries("get-transaction-stats");
        queryClient.invalidateQueries("manage-money-transactions");
        queryClient.invalidateQueries("acquirer-processing-transactions");

        action && action(4);
      },
      onError: (error: any) => {
        const errors = error.response.data.input || error.response.data;

        if (Array.isArray(errors)) {
          errors.forEach((_error: any) => showMessage("Error", _error.message));
        } else {
          showMessage("Error", errors.message);
        }
      },
    });
  };

  let bankAccountsList = [];
  if (linkedBankAccount && linkedBankAccount.status === "approved") {
    bankAccountsList.push(linkedBankAccount);
  }
  if (bankAccounts?.data) {
    bankAccountsList = bankAccountsList.concat(bankAccounts.data);
  }

  return {
    bankAccounts: bankAccountsList,
    isLoading: isLoading || merchantLoading,
    transferLoading: transferMoneyMutation.isLoading,
    areBankAccountsError: areBankAccountsError || merchantError,
    handleTransferMoneySubmit,
  };
};

export const useUpdateTransferMoney = () => {
  const updateTransferMoneyMutation = useMutation(
    ({
      data,
      transactionID,
    }: {
      data: Record<string, any>;
      transactionID: string;
    }) => {
      return customInstance({
        url: buildMerchantEndpoints(`transfers/${transactionID}`),
        method: "PATCH",
        data,
      });
    },
  );

  const handleUpdateTransferMoneySubmit = (
    data: Record<string, any>,
    transactionID: string,
  ) => {
    updateTransferMoneyMutation.mutate(
      { data, transactionID },
      {
        onSuccess: () => {
          showMessage("Success", "Transfer updated succesfully");
        },
        onError: (error: any) => {
          error.response.data.input.forEach((_error: any) =>
            showMessage("Error", _error.message),
          );
        },
      },
    );
  };
  return {
    handleUpdateTransferMoneySubmit,
    isLoading: updateTransferMoneyMutation.isLoading,
  };
};
