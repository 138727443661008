import { Text } from "@common/Text";
import { Stack, Box } from "@mui/material";
import LocalSeeOutlinedIcon from "@mui/icons-material/LocalSeeOutlined";

function SelfiePreview({
  selfieUrl,
  onClick,
}: {
  selfieUrl: string;
  onClick: () => void;
}) {
  return (
    <Stack direction="column" gap={4}>
      <Box
        width="100%"
        bgcolor="#ECECE9"
        src={`${selfieUrl}`}
        component="img"
        height="328px"
        sx={{
          objectFit: "contain",
        }}
      />
      <Stack alignItems="center">
        <Box
          sx={{
            cursor: "pointer",
          }}
          gap="4px"
          display="flex"
          alignItems="center"
          onClick={onClick}
        >
          <LocalSeeOutlinedIcon
            sx={{
              color: "#8F8F8F",
            }}
          />
          <Text fontWeight="regular" fontSize="14px" color="#8F8F8F">
            Retake selfie
          </Text>
        </Box>
      </Stack>
    </Stack>
  );
}

export default SelfiePreview;
