import { ButtonBase } from "@mui/material";
import { selectedItem } from "@pages/NewAdvancedBuilder/signals";
import { SyntheticEvent, useState } from "react";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import { useAppTheme } from "@theme/v2/Provider";
import { changeNodeVisibility } from "../HideOnDeviceSwitch";
import { getDeviceTypePropertyName } from "@pages/NewAdvancedBuilder/utils/helpers";
import { builder } from "@pages/NewAdvancedBuilder/utils";

const ShowHideButton = () => {
  const theme = useAppTheme();
  const iconProps = {
    color: theme.palette.primitive?.neutral[10],
    size: 20,
  };

  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const isCompoundWidget = Boolean(selectedItem.value?.state);
  const metaAttributes = isCompoundWidget
    ? builder.findNodeByKey(selectedItem.value.id)?.[
        getDeviceTypePropertyName("metaAttributes")
      ]
    : selectedItem.value.metaAttributes;
  const isHidden = metaAttributes?.["data-hidden"] === "true";

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    const newValue = isHidden ? "false" : "true";
    changeNodeVisibility(newValue, isCompoundWidget);
  };

  return (
    <ButtonBase
      disableRipple
      onClick={handleClick}
      onMouseEnter={() => setBackgroundColor("#0000000D")}
      onMouseLeave={() => setBackgroundColor("transparent")}
      sx={{
        backgroundColor: backgroundColor,
        padding: "8px",
        borderRadius: "8px",
        transition: "background-color 0.2s",
      }}
    >
      {!isHidden ? <Eye {...iconProps} /> : <EyeClosed {...iconProps} />}
    </ButtonBase>
  );
};

export default ShowHideButton;
