import { Box } from "@mui/material";
import React from "react";
type Props = {
  padding?: string;
  horizontal?: string;
  vertical?: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  children: React.ReactNode;
};
export const Padding: React.FC<Props> = ({
  padding,
  horizontal,
  vertical,
  left,
  right,
  top,
  bottom,
  children,
}) => {
  return (
    <Box
      sx={{
        padding: padding,
        px: horizontal,
        py: vertical,
        paddingLeft: left,
        paddingRight: right,
        paddingTop: top,
        paddingBottom: bottom,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
