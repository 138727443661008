type Props = {
  color?: string;
  width?: string | number;
  height?: string | number;
};

const DesktopDeviceIcon = ({
  color = "#B2B2B1",
  width = "21",
  height = "20",
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0026 1.66797H3.0026C2.08177 1.66797 1.33594 2.4138 1.33594 3.33464V13.3346C1.33594 14.2555 2.08177 15.0013 3.0026 15.0013H8.0026C8.46284 15.0013 8.83594 15.3744 8.83594 15.8346C8.83594 16.2949 8.46284 16.668 8.0026 16.668C7.54237 16.668 7.16927 17.0411 7.16927 17.5013C7.16927 17.9615 7.54237 18.3346 8.0026 18.3346H13.0026C13.4628 18.3346 13.8359 17.9615 13.8359 17.5013C13.8359 17.0411 13.4628 16.668 13.0026 16.668C12.5424 16.668 12.1693 16.2949 12.1693 15.8346C12.1693 15.3744 12.5424 15.0013 13.0026 15.0013H18.0026C18.9234 15.0013 19.6693 14.2555 19.6693 13.3346V3.33464C19.6693 2.4138 18.9234 1.66797 18.0026 1.66797ZM18.0026 12.3346C18.0026 12.8869 17.5549 13.3346 17.0026 13.3346H4.0026C3.45032 13.3346 3.0026 12.8869 3.0026 12.3346V4.33464C3.0026 3.78235 3.45032 3.33464 4.0026 3.33464H17.0026C17.5549 3.33464 18.0026 3.78235 18.0026 4.33464V12.3346Z"
        fill={color}
      />
    </svg>
  );
};

export default DesktopDeviceIcon;
