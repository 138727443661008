import { CaretRight, CloseIcon } from "@assets/icons";
import useHighlightWord from "@common/FilePreview/hooks/useHighlightWord";
import useInputFieldFocusListener from "@common/FilePreview/hooks/useInputFieldFocusListener";
import { Input } from "@common/Input";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  HighlightTracker,
  MobileInput,
  SearchUtilButton,
} from "./SearchSubHeader.styles";

type SearchSubHeader = {
  onClose: () => void;
  onSearch: (newValue: string) => void;
  highlights: NodeListOf<HTMLElement> | null;
  showControls?: boolean;
  onFocus: (isFocused: boolean) => void;
};

const SearchSubHeader = ({
  onClose,
  onSearch,
  highlights,
  showControls,
  onFocus,
}: SearchSubHeader) => {
  const [value, setValue] = useState<string>("");
  const { highlightIndex, onNext, onPrev } = useHighlightWord(highlights);
  const { isDesktopView } = useCustomTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearch = useRef(
    debounce((value: string) => onSearch(value), 200),
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedSearch.current.cancel();
    debouncedSearch.current(newValue);
  };

  useEffect(() => {
    if (!showControls) onClose();
  }, [showControls]);

  useInputFieldFocusListener({ inputRef, onFocus, autofocus: true });

  const highlightsTrackerProps = {
    visible: !!value,
    current: highlights?.length ? highlightIndex + 1 : 0,
    total: highlights?.length || 0,
  };
  return (
    <Container
      onClick={(event) => event.stopPropagation()}
      data-testid="search-container"
    >
      {isDesktopView ? (
        <Input
          label="Find in document"
          value={value}
          onChange={onChange}
          inputRef={inputRef}
          inputSuffix={<HighlightTracker {...highlightsTrackerProps} />}
          autoFocus
        />
      ) : (
        <Stack width="260px" direction="row" alignItems="center">
          <MobileInput
            inputProps={{
              ["data-testid"]: "file-preview-mobile-search-input",
            }}
            placeholder="Find in document"
            value={value}
            onChange={onChange}
            inputRef={inputRef}
            autoFocus
          />
          <HighlightTracker {...highlightsTrackerProps} />
        </Stack>
      )}
      <SearchArrows onPrev={onPrev} onNext={onNext} onFocus={onFocus} />
      {isDesktopView && (
        <SearchUtilButton onClick={onClose} hoverColor={palette.black[100]}>
          <CloseIcon width={22} height={22} stroke={palette.black[100]} />
        </SearchUtilButton>
      )}
    </Container>
  );
};

export default SearchSubHeader;

const SearchArrows = ({
  onPrev,
  onNext,
  onFocus,
}: {
  onPrev: () => void;
  onNext: () => void;
  onFocus: (isFocused: boolean) => void;
}) => {
  const { isDesktopView } = useCustomTheme();
  const actions = [
    {
      rotate: 90,
      onClick: onNext,
      testId: "next",
    },
    {
      rotate: -90,
      onClick: onPrev,
      testId: "prev",
    },
  ];
  const color = isDesktopView ? palette.black[100] : palette.neutral.white;
  return (
    <Stack direction="row" gap={2} alignItems="center">
      {actions.map(({ rotate, onClick, testId }, index) => (
        <SearchUtilButton
          onFocus={() => onFocus(true)}
          onBlur={() => onFocus(false)}
          key={index}
          onClick={onClick}
          hoverColor={color}
          data-testid={`search-navigation-${testId}`}
        >
          <CaretRight width={22} height={22} fill={color} rotate={rotate} />
        </SearchUtilButton>
      ))}
    </Stack>
  );
};
