import { PlusIcon } from "@assets/icons/RebrandedIcons";
import { Button } from "@common/Button";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import FilterPermissionList, { ItemType } from "../FilterPermissionList";
import ControlledSearchBar from "@common/SearchBar_V2/ControlledSearchBar";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  CREATE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";

const PermissionsFilterBar = ({
  searchQuery,
  handleSearch,
  handleAdd,
  filters,
  setFilter,
}: {
  searchQuery: string;
  handleSearch: (value: string) => void;
  handleAdd: () => void;
  filters: ItemType[];
  setFilter: (item: ItemType) => void;
}) => {
  const isEditAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MEMBER,
      RESOURCE_BASE.ACCESS_POLICIES,
    ),
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="16px"
      gap="8px"
    >
      <FilterPermissionList filterData={filters} onHandleSelect={setFilter} />
      <Stack direction="row" gap="8px" alignItems="center">
        <ControlledSearchBar
          onChange={handleSearch}
          value={searchQuery}
          formSx={{ "& > div": { width: "200px" } }}
        />
        <Button
          background="primary"
          onClick={handleAdd}
          size="small"
          disabled={!isEditAllowed}
          tooltipProps={{
            show: !isEditAllowed,
            message: CREATE_DENY_MESSAGE,
          }}
        >
          <PlusIcon width={18} height={18} stroke={palette.neutral.white} />{" "}
          Permission
        </Button>
      </Stack>
    </Stack>
  );
};

export default PermissionsFilterBar;
