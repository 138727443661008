import { Box, Collapse, Skeleton, Stack, styled } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";

const AnalyticsSkeleton = ({ isExpanded }: { isExpanded: boolean }) => {
  const { isMobileView } = useCustomTheme();
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={26}
        width="30%"
        sx={{ borderRadius: "8px", minHeight: "26px" }}
      />
      <Box>
        <Skeleton
          variant="rectangular"
          height={20}
          width="20%"
          sx={{ borderRadius: "8px" }}
        />
        <Stack direction="column" gap="36px" py="16px">
          {Array.from({ length: 10 }, (_, i) => (
            <Skeleton
              key={i}
              width="100%"
              height="0.5px"
              variant="rectangular"
            />
          ))}
        </Stack>
        <Skeleton
          variant="rectangular"
          height={20}
          width="20%"
          sx={{ borderRadius: "8px" }}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={235}
        sx={{
          borderRadius: "12px",
          minHeight: "235px",
        }}
      />
      <Collapse in={isExpanded || isMobileView} orientation="vertical">
        <Stack direction="column" alignItems="stretch" gap={2}>
          <StatsContainer>
            <GraphSkeleton />
            <StatsSkeleton lines={4} />
          </StatsContainer>
          <StatsContainer>
            <GraphSkeleton />
            <StatsSkeleton lines={3} />
          </StatsContainer>
        </Stack>
      </Collapse>
    </>
  );
};

export const GraphSkeleton = () => {
  return (
    <Box flexGrow={1}>
      <Skeleton
        variant="rectangular"
        height={16}
        width="30%"
        sx={{ borderRadius: "8px" }}
      />
      <Stack direction="column" gap="36px" py="16px">
        {Array.from({ length: 10 }, (_, i) => (
          <Skeleton key={i} width="100%" height="0.5px" variant="rectangular" />
        ))}
      </Stack>
    </Box>
  );
};

const StatsSkeleton = ({ lines }: { lines: number }) => {
  return (
    <Stack direction="column" gap={2} flexGrow={1} alignItems="stretch">
      <Skeleton
        variant="rectangular"
        height={27}
        width="100%"
        sx={{ borderRadius: "8px" }}
      />
      {Array.from({ length: lines }, (_, i) => (
        <StatsItem key={i} />
      ))}
    </Stack>
  );
};

const StatsItem = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={1} alignItems="center">
        <Skeleton
          variant="rectangular"
          width={20}
          height={20}
          sx={{ borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width={127}
          height={9}
          sx={{ borderRadius: "100px" }}
        />
      </Stack>
      <Skeleton
        variant="rectangular"
        width={39}
        height={9}
        sx={{ borderRadius: "100px" }}
      />
    </Stack>
  );
};

const StatsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  gap: "64px",
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "flex-start",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "32px",
    justifyContent: "flex-start",
    alignItems: "stretch",
    marginBottom: "32px",
  },
}));

export default AnalyticsSkeleton;
