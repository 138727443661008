import { DialogContent, styled } from "@mui/material";

const ModalContent = styled(DialogContent)({
  overflow: "auto",
  background:
    "linear-gradient(white, white),linear-gradient(white, white) 0 100%,linear-gradient(rgba(0, 0, 0, 0.07), white 70%)",
  backgroundRepeat: "no-repeat",
  backgroundColor: "white",
  backdropFilter: "blur(20px)",
  backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
  backgroundAttachment: "local, local, scroll, scroll",
});

export default ModalContent;
